import {
  EuiSearchBar,
  EuiSearchBarOnChangeArgs,
  EuiSearchBarProps,
  Query,
} from "@inscopix/ideas-eui";
import { useCallback, useState } from "react";
import { useUserContext } from "providers/UserProvider/UserProvider";

/**
 * Hook to manage EuiSearchBar state
 */
export const useEuiSearchBar = () => {
  const currentUser = useUserContext((s) => s.currentUser);

  const [error, setError] = useState<EuiSearchBarOnChangeArgs["error"]>(null);
  const [query, setQuery] = useState<Query>();

  const userFilter: NonNullable<EuiSearchBarProps["filters"]>[0] = {
    type: "field_value_toggle",
    field: "user",
    name: "Only Mine",
    value: currentUser.username,
  };

  const onChange = ({
    query,
    error: incomingError,
  }: EuiSearchBarOnChangeArgs) => {
    if (incomingError) {
      setError(error);
    } else {
      setError(null);
      if (query !== null) {
        setQuery(query);
      }
    }
  };

  const executeQuery = useCallback(
    <T>(...args: Parameters<typeof EuiSearchBar.Query.execute<T>>) =>
      EuiSearchBar.Query.execute(...args),
    [],
  );

  return {
    onChange,
    query,
    error,
    userFilter,
    executeQuery,
  };
};
