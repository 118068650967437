/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { CellValue } from "@inscopix/ideas-hyperformula";

const styles = {
  root: css`
    border: none;
    background-color: transparent;
    width: 100%;
    &:focus {
      outline: none;
    }
  `,
};

interface CellEditorFloatProps {
  initialValue: CellValue;
  onChange: (newFormula: string | null) => void;
  stopEditing: () => void;
}

/** Cell editor for cells in float columns */
export const CellEditorFloat = ({
  initialValue,
  onChange,
  stopEditing,
}: CellEditorFloatProps) => {
  const [value, setValue] = useState(() => {
    return typeof initialValue === "number" ? initialValue : undefined;
  });

  return (
    <input
      autoFocus
      type="number"
      css={styles.root}
      value={value}
      onBlur={stopEditing}
      onChange={(e) => {
        const newValue = parseFloat(e.target.value);
        if (!isNaN(newValue)) {
          setValue(newValue);
          onChange(newValue.toString());
        }
      }}
    />
  );
};
