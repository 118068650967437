import {
  EuiButton,
  EuiButtonEmpty,
  EuiForm,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@inscopix/ideas-eui";
import { useState } from "react";
import { useDataTableContext } from "../store/DataTableProvider";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { FieldTableName } from "./fields/FieldTableName";
import { FieldTableKey } from "./fields/FieldTableKey";
import { DataTableStoreState } from "../store/DataTableProvider.types";
import { FieldScratch } from "./fields/FieldScratch";
import { chain } from "lodash";

type Fields = {
  name: {
    value: string;
    isValid: boolean;
  };
  key: {
    value: string;
    isValid: boolean;
  };
  identifierColumnIds: {
    value: string[];
    isValid: boolean;
  };
};

interface ModalEditDataTableProps {
  table: DataTableStoreState["tables"][number];
  onClose: () => void;
}

/** Component that renders a modal for updating a data table */
export const ModalEditDataTable = ({
  table,
  onClose,
}: ModalEditDataTableProps) => {
  const updateDataTable = useDataTableContext((s) => s.updateDataTable);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState<Fields>({
    name: {
      value: table.name,
      isValid: true,
    },
    key: {
      value: table.key,
      isValid: true,
    },
    identifierColumnIds: {
      value: chain(table.columns)
        .filter((column) => column.identifier_position !== null)
        .sortBy((column) => column.identifier_position)
        .map((column) => column.id)
        .value(),
      isValid: true,
    },
  });

  const handleFieldChange = <T extends keyof Fields>(
    fieldName: T,
    field: Fields[T],
  ) => {
    setFields((prevFields) => ({
      ...prevFields,
      [fieldName]: field,
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const { error } = await updateDataTable({
      tableId: table.id,
      name: fields.name.value,
      key: fields.key.value,
      identifierColumnIds: fields.identifierColumnIds.value,
    });
    setIsLoading(false);

    if (error === undefined) {
      addUtilityToastSuccess("Data table updated");
    } else {
      addUtilityToastFailure("Failed to update data table");
    }

    onClose();
  };

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Edit Data Table</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiForm>
          <FieldTableName
            autoFocus
            value={fields.name.value}
            onChange={(field) => handleFieldChange("name", field)}
          />
          <FieldTableKey
            value={fields.key.value}
            onChange={(field) => handleFieldChange("key", field)}
          />
          <FieldScratch
            tableId={table.id}
            value={fields.identifierColumnIds.value}
            onChange={(field) =>
              handleFieldChange("identifierColumnIds", field)
            }
          />
        </EuiForm>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiButton
          isLoading={isLoading}
          fill
          onClick={() => void handleSubmit()}
          disabled={Object.values(fields).some((field) => !field.isValid)}
        >
          Update
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
