import { useDataTableContext } from "../store/DataTableProvider";
import { memo, useEffect } from "react";
import { isAnalysisTableRowExecuting } from "../layout/scratch";

/**
 * Component that triggers analysis table rows to refetch as long as they are
 * executing.
 *
 * The reason this is a component, and not a hook, is to minimize rerenders. I
 * couldn't find a logic parent component to consume this hook.
 */
export const DataTablePoller = memo(function DataTablePoller() {
  const refetchPendingAnalysisTableRows = useDataTableContext(
    (s) => s.refetchPendingAnalysisTableRows,
  );
  const isPendingRows = useDataTableContext((s) => {
    const allRows = s.tables.flatMap((table) => table.rows);
    return allRows.some(isAnalysisTableRowExecuting);
  });

  useEffect(() => {
    let timer: NodeJS.Timer;

    if (isPendingRows) {
      timer = setInterval(() => {
        void refetchPendingAnalysisTableRows();
      }, 5000);
    }

    return () => clearInterval(timer);
  }, [isPendingRows, refetchPendingAnalysisTableRows]);

  return null;
});
