import { TUserRole } from "components/ModalProjectSharing/ModalProjectSharing";
import { ApplicationUser } from "graphql/_Types";
import { isEqual } from "lodash";
import { TenantMembershipRole } from "types/TenantMembershipRole";
import {
  UserProjectAccess,
  UserPermission,
  USER_ACCESS_LEVELS,
  USER_PERMISSIONS,
  USER_ACCESS_LEVELS_BY_ID,
  UserAccessLevel,
  USER_ACCESS_LEVELS_BY_KEY,
} from "types/UserAccessLevels";

/**
 * Get the user role from the list of permissions
 * @param permissions List of permissions
 * @returns The user role
 * @example
 * const role = getRoleFromPermissions(["view", "edit"]);
 * // role === "editor"
 */
export const getRoleFromPermissions = (
  permissions: UserPermission[],
): TUserRole => {
  // Convert to a set for easier comparison
  const userPermissions = new Set(permissions);

  if (userPermissions.size === 0) {
    return "restricted";
  }

  /* Check if the user's permissions are identical to the permissions of a known
     access level */
  for (const accessLevel of Object.values(USER_ACCESS_LEVELS)) {
    const accessLevelPermissions = new Set(accessLevel.permissions);
    if (isEqual(userPermissions, accessLevelPermissions)) {
      return accessLevel.key;
    }
  }

  return "custom";
};

//
// Extract the list of permissions from the userProjectAccess object
//
/**
   * Extract the list of permissions from the userProjectAccess object
   * @param userProjectAccess The userProjectAccess object
   * @returns The list of permissions
   * @example
   * const permissions = extractPermissionsList({
   *    "id": "179ef2ce-e25f-4ebb-a7e3-f58c259901ea",
        "userId": "0f58e38b-6dd2-4d08-a6b9-d26ae015b8be",
        "managed": false,
        "view": true,
        "download": true,
        "copy": false,
        "edit": false,
        "execute": true,
        "grantAccess": false,
        "upload": false,
   * });
   * // permissions === ["view", "download"]
   */
export const extractPermissionsList = (
  userProjectAccess: Partial<UserProjectAccess>,
) => {
  return USER_PERMISSIONS.filter((permission) => userProjectAccess[permission]);
};

//
// Extract the Role of the user from the userProjectAccess object
//
/**
   * Extract the list of permissions from the userProjectAccess object
   * @param userProjectAccess The userProjectAccess object
   * @returns The list of permissions
   * @example
   * const permissions = getUserRole({
   *    "id": "179ef2ce-e25f-4ebb-a7e3-f58c259901ea",
        "userId": "0f58e38b-6dd2-4d08-a6b9-d26ae015b8be",
        "managed": false,
        "view": true,
        "download": true,
        "copy": true,
        "edit": true,
        "execute": true,
        "grantAccess": false,
        "upload": true,
   * }, 2, "0f58e38b-6dd2-4d08-a6b9-d26ae015b8be");
  * // role === "editor"
   */
export const getUserRole = (
  userProjectAccess: Partial<UserProjectAccess> & {
    userId: UserProjectAccess["userId"];
  },
  defaultAccessLevel: UserAccessLevel["id"],
  ownerId: ApplicationUser["id"],
  tenantRole?: TenantMembershipRole,
): TUserRole => {
  // Project Owner always has the highest access level
  if (isProjectOwner(userProjectAccess.userId, ownerId)) {
    return "owner";
  }

  const isOrganizationAdmin = tenantRole?.key === "admin";
  if (isOrganizationAdmin) {
    return USER_ACCESS_LEVELS_BY_KEY["admin"].key;
  }

  const role = getRoleFromPermissions(
    extractPermissionsList(userProjectAccess),
  );

  const individualAccessLevel = USER_ACCESS_LEVELS_BY_KEY[role]?.id;

  // If an user is a member of the tenant and the default access level is higher than the individual access level,
  // the user is always given the most permissive access level
  const isMemberOfTenant = tenantRole !== undefined;

  return individualAccessLevel !== undefined &&
    isMemberOfTenant &&
    defaultAccessLevel > individualAccessLevel
    ? USER_ACCESS_LEVELS_BY_ID[defaultAccessLevel].key
    : role;
};

/**
 * Determine if the user is the project owner
 *
 * @param currentUserID
 * @param projectCreatorID
 * @returns boolean
 */
export const isProjectOwner = (
  currentUserID: ApplicationUser["id"],
  projectCreatorID: ApplicationUser["id"],
) => {
  return currentUserID === projectCreatorID;
};
