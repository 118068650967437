/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { CellValue } from "@inscopix/ideas-hyperformula";
import {
  EuiListGroup,
  EuiListGroupItem,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { CellValueRendererBase } from "../cell-value-renderers/CellValueRendererBase";

const styles = {
  popover: css`
    & {
      height: 100%;
      width: 100%;
      .PopoverAnchor {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      }
    }
  `,
  panel: css`
    max-height: max(200px, 50vh);
    overflow-y: scroll;
  `,
};

interface CellEditorBooleanProps {
  tableId: string;
  columnId: string;
  rowId: string;
  initialValue: CellValue;
  onChange: (newFormula: string | null) => void;
  stopEditing: () => void;
}

/** Cell editor for cells in boolean columns */
export const CellEditorBoolean = ({
  tableId,
  columnId,
  rowId,
  initialValue,
  onChange,
  stopEditing,
}: CellEditorBooleanProps) => {
  const [newValue, setNewValue] = useState(initialValue);

  const items = [
    <EuiListGroupItem
      id="true"
      key="true"
      iconType={newValue === true ? "check" : "empty"}
      label="true"
      onClick={() => {
        if (newValue === true) {
          onChange("");
          setNewValue(null);
        } else {
          onChange("=TRUE()");
          setNewValue(true);
        }
      }}
    />,
    <EuiListGroupItem
      id="false"
      key="false"
      iconType={newValue === false ? "check" : "empty"}
      label="false"
      onClick={() => {
        if (newValue === false) {
          onChange("");
          setNewValue(null);
        } else {
          onChange("=FALSE()");
          setNewValue(false);
        }
      }}
    />,
  ];

  return (
    <EuiPopover
      anchorPosition="downCenter"
      button={
        <CellValueRendererBase
          tableId={tableId}
          columnId={columnId}
          rowId={rowId}
          value={newValue}
        />
      }
      className="Popover"
      anchorClassName="PopoverAnchor"
      isOpen
      panelPaddingSize="none"
      css={styles.popover}
      closePopover={stopEditing}
      display="block"
      repositionOnScroll={false}
    >
      <EuiListGroup css={styles.panel} maxWidth="100%">
        {items}
      </EuiListGroup>
    </EuiPopover>
  );
};
