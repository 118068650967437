/** @jsxImportSource @emotion/react */
import {
  EuiButton,
  EuiButtonIcon,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSkeletonText,
  EuiText,
} from "@inscopix/ideas-eui";
import { ReactFlowJsonObject } from "@xyflow/react";
import { Fragment, useEffect, useState } from "react";
import { getAllWorkflows } from "./ModalCreateWorkflow.helpers";
import { ModalCreateWorkflow } from "./ModalCreateWorkflow";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { css } from "@emotion/react";

interface ModalWorkflowsProps {
  onClose: () => void;
}

export const ModalWorkflows = ({ onClose }: ModalWorkflowsProps) => {
  const [workflows, setWorkflows] = useState<
    {
      id: string;
      name: string;
      description: string;
      layout: ReactFlowJsonObject;
    }[]
  >([]);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [createNew, setCreateNew] = useState(false);

  useEffect(() => {
    getAllWorkflows()
      .then(setWorkflows)
      .catch(() => undefined)
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <EuiModal
        css={css`
          min-width: 500px;
        `}
        onClose={onClose}
      >
        <EuiModalHeader>
          <EuiModalHeaderTitle>Workflows</EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiSkeletonText lines={3} />
        </EuiModalBody>
      </EuiModal>
    );
  }

  if (createNew) {
    return <ModalCreateWorkflow onClose={onClose} />;
  }

  if (selectedWorkflowId !== undefined) {
    const workflow = workflows.find(({ id }) => id === selectedWorkflowId);
    assert(isDefined(workflow));
    return (
      <ModalCreateWorkflow
        initialName={workflow.name}
        initialDescription={workflow.description}
        initialEdges={workflow.layout.edges}
        initialNodes={workflow.layout.nodes}
        initialViewport={workflow.layout.viewport}
        onClose={onClose}
      />
    );
  }

  return (
    <EuiModal
      css={css`
        min-width: 500px;
      `}
      onClose={onClose}
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle>Workflows</EuiModalHeaderTitle>
      </EuiModalHeader>

      {workflows.length === 0 ? (
        <EuiModalBody>
          <EuiEmptyPrompt
            iconType="branch"
            iconColor="default"
            title={<h2>Start your first workflow!</h2>}
            titleSize="xs"
            body={
              <p>
                Chain tools together by connecting their inputs and outputs.
              </p>
            }
            actions={
              <EuiButton
                color="primary"
                fill
                onClick={() => setCreateNew(true)}
              >
                Create new workflow
              </EuiButton>
            }
          />
        </EuiModalBody>
      ) : (
        <>
          <EuiModalBody>
            {workflows.map((workflow, idx, arr) => (
              <Fragment key={workflow.id}>
                <EuiFlexGroup alignItems="center">
                  <EuiFlexItem>
                    <b>{workflow.name}</b>
                    {workflow.description !== "" ? (
                      <p>{workflow.description}</p>
                    ) : (
                      <EuiText size="s" color="subdued">
                        <i>No description</i>
                      </EuiText>
                    )}
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButtonIcon
                      aria-label="View workflow"
                      onClick={() => setSelectedWorkflowId(workflow.id)}
                      iconType={"popout"}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
                {idx < arr.length - 1 && <EuiHorizontalRule margin="m" />}
              </Fragment>
            ))}
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton onClick={() => setCreateNew(true)}>
              Create new workflow
            </EuiButton>
          </EuiModalFooter>
        </>
      )}
    </EuiModal>
  );
};
