import { EuiBadge } from "@inscopix/ideas-eui";
import {
  ColorsByRunStatus,
  ColorsByTaskStatus,
} from "types/ColorsByTaskStatus";
import { RunStatus, TaskStatus } from "types/constants";

interface TaskStatusBadgeProps {
  taskStatus: TaskStatus;
}

export const TaskStatusBadge = ({ taskStatus }: TaskStatusBadgeProps) => {
  const color = ColorsByTaskStatus[taskStatus];
  const taskStatusName = TaskStatus[taskStatus];
  return <EuiBadge color={color}>{taskStatusName}</EuiBadge>;
};

interface RunStatusBadgeProps {
  runStatus: RunStatus;
}

export const RunStatusBadge = ({ runStatus }: RunStatusBadgeProps) => {
  const color = ColorsByRunStatus[runStatus];
  const taskStatusName = RunStatus[runStatus];
  return <EuiBadge color={color}>{taskStatusName}</EuiBadge>;
};
