import {
  DragDropContextProps,
  EuiButtonIcon,
  EuiDragDropContext,
  euiDragDropReorder,
  EuiDraggable,
  EuiDroppable,
  EuiFormRow,
  EuiIcon,
  EuiPanel,
  EuiSelect,
  EuiSpacer,
  EuiText,
} from "@inscopix/ideas-eui";
import { pull } from "lodash";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";

interface FieldScratchProps {
  autoFocus?: boolean;
  tableId: string;
  value: string[];
  onChange: (field: { value: string[]; isValid: boolean }) => void;
}

export const FieldScratch = ({
  autoFocus,
  tableId,
  value,
  onChange,
}: FieldScratchProps) => {
  const columns = useDataTableContext((s) => {
    const table = s.tables.find((table) => table.id === tableId);
    return table?.columns ?? [];
  });

  // Columns to display in the dropdown
  const unselectedColumns = columns
    .filter((column) => !value.includes(column.id))
    .sort((a, b) => a.order - b.order);

  /**
   * Reorders identifier columns after dragging has ended
   * @param result {@link DragDropContextProps}
   */
  const handleDragEnd: DragDropContextProps["onDragEnd"] = (result) => {
    const { source: src, destination: dst } = result;
    if (dst !== undefined && dst !== null) {
      onChange({
        value: euiDragDropReorder(value, src.index, dst.index),
        isValid: true,
      });
    }
  };

  /**
   * Adds a column as an identifier column
   * @param columnId
   */
  const selectIdentifierColumn = (columnId: string) => {
    onChange({
      value: [...value, columnId],
      isValid: true,
    });
  };

  /**
   * Removes a column as an identifier column
   * @param columnId
   */
  const deselectIdentifierColumn = (columnId: string) => {
    onChange({
      value: pull([...value], columnId),
      isValid: true,
    });
  };

  return (
    <EuiFormRow
      label="Columns included in row identifiers"
      helpText={
        <EuiText size="xs">
          <p>
            Cell values in these columns will appear appended to row
            identifiers. Drag the column names to change the order in which cell
            values will appear.
          </p>
        </EuiText>
      }
    >
      <EuiDragDropContext onDragEnd={handleDragEnd}>
        <EuiSelect
          autoFocus={autoFocus}
          onChange={(e) => selectIdentifierColumn(e.target.value)}
          options={
            unselectedColumns.length === 0
              ? [
                  {
                    value: "PLACEHOLDER_TEXT",
                    text: "Select to add columns",
                    disabled: true,
                    hidden: true,
                  },
                  {
                    value: "NO_ITEMS",
                    text: "No items found",
                    disabled: true,
                  },
                ]
              : [
                  {
                    value: "PLACEHOLDER_TEXT",
                    text: "Select to add columns",
                    disabled: true,
                    hidden: true,
                  },
                  ...unselectedColumns.map((column) => ({
                    value: column.id,
                    text: column.name,
                  })),
                ]
          }
          /* https://stackoverflow.com/a/5859221
               This is the defacto standard for displaying placeholder text in
               select elements. Might be worth it to make a wrapper component
               in the future. */
          value="PLACEHOLDER_TEXT"
        />
        <EuiSpacer size="s" />
        <EuiDroppable droppableId="droppable">
          {value.map((columnId, idx) => (
            <EuiDraggable
              key={columnId}
              index={idx}
              draggableId={columnId}
              hasInteractiveChildren
            >
              {(provided) => (
                <EuiPanel
                  {...provided.dragHandleProps}
                  color="transparent"
                  paddingSize="none"
                  style={{
                    width: "100%",
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  <EuiIcon type="grab" style={{ marginRight: 5 }} />
                  <EuiText size="s">
                    {columns.find(({ id }) => id === columnId)?.name}
                  </EuiText>
                  <EuiButtonIcon
                    color="danger"
                    iconType="minusInCircle"
                    onClick={() => deselectIdentifierColumn(columnId)}
                    style={{ marginLeft: "auto" }}
                  />
                </EuiPanel>
              )}
            </EuiDraggable>
          ))}
        </EuiDroppable>
      </EuiDragDropContext>
    </EuiFormRow>
  );
};
