/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const styles = {
  root: css`
    border: none;
    background-color: transparent;
    width: 100%;
    color: green;
    font-family: monospace;
    &:focus {
      outline: none;
    }
  `,
};

interface CellEditorFormulaProps {
  initialFormula: string | null;
  onChange: (newFormula: string | null) => void;
  stopEditing: () => void;
}

/**
 * Cell editor for cells in formula columns
 */
export const CellEditorFormula = ({
  initialFormula,
  onChange,
  stopEditing,
}: CellEditorFormulaProps) => {
  return (
    <input
      type="text"
      css={styles.root}
      value={initialFormula ?? ""}
      onChange={(e) => onChange(e.target.value)}
      onBlur={stopEditing}
      autoFocus
    />
  );
};
