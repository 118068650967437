import { EuiBadge, EuiBadgeGroup } from "@inscopix/ideas-eui";
import {
  CellValue,
  DetailedCellError,
  Group,
  IdeasFile,
  RoiFrame,
  RowIdentifier,
} from "@inscopix/ideas-hyperformula";
import { useDataTableContext } from "../store/DataTableProvider";
import { Tooltip } from "components/Tooltip/Tooltip";
import { chain } from "lodash";

const convertCellValueToString = (value: CellValue): string => {
  if (value instanceof DetailedCellError || value === null) {
    return "";
  }

  if (value instanceof IdeasFile) {
    return value.attrs.name;
  }

  if (value instanceof RoiFrame) {
    return "ROI Frame";
  }

  if (value instanceof RowIdentifier) {
    const { tableKey, rowIndex } = value;
    return `${tableKey}-${rowIndex}`;
  }

  if (value instanceof Group) {
    return value.cellValues.map(convertCellValueToString).join(" | ");
  }

  return value.toString();
};

interface CellValueRendererRowIdentifierProps {
  rowIdentifier: RowIdentifier;
}

/** Component that renders a `RowIdentifier` cell value` */
export const CellValueRendererRowIdentifier = ({
  rowIdentifier,
}: CellValueRendererRowIdentifierProps) => {
  const table = useDataTableContext((s) => {
    return s.tables.find((table) => table.key === rowIdentifier.tableKey);
  });
  const row = table?.rows.find((row) => row.index === rowIdentifier.rowIndex);

  const specialValues = chain(table?.columns ?? [])
    .map((column, idx) => ({
      name: column.name,
      pos: column.identifier_position,
      value: row?.cells[idx].value ?? null,
    }))
    .filter((column) => column.pos !== null)
    .sortBy((column) => column.pos)
    .value();

  const tooltipContent = specialValues.map(({ name, value }) => {
    return (
      <div key={name}>
        <strong>{name}</strong>: {convertCellValueToString(value)}
      </div>
    );
  });

  return (
    <EuiBadgeGroup>
      <EuiBadge
        color="#ddd"
        style={{ fontFamily: "monospace", border: "1px solid grey" }}
        title=""
      >
        {rowIdentifier.tableKey}-{rowIdentifier.rowIndex}
      </EuiBadge>
      {specialValues.length > 0 && (
        <Tooltip content={tooltipContent}>
          <EuiBadge color="hollow" style={{ fontFamily: "monospace" }} title="">
            {specialValues
              .map(({ value }) => convertCellValueToString(value))
              .join(" | ")}
          </EuiBadge>
        </Tooltip>
      )}
    </EuiBadgeGroup>
  );
};
