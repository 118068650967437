/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { EuiButtonIcon } from "@inscopix/ideas-eui";
import { useMouseOver } from "hooks/useMouseOver";
import { useState } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { selectSystemFiles } from "utils/selectSystemFiles";
import { useDataTableContext } from "../store/DataTableProvider";

const styles = {
  root: css`
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
  `,
};

interface CellRendererFileProps {
  eGridCell: HTMLElement;
  tableId: string;
  columnId: string;
  rowId: string;
}

export const CellRendererFile = ({
  tableId,
  columnId,
  rowId,
  eGridCell,
}: CellRendererFileProps) => {
  const { isMouseOver } = useMouseOver(eGridCell);
  const [isLoading, setIsLoading] = useState(false);
  const uploadFile = useDataTableContext((s) => s.uploadFile);

  if (!isMouseOver && !isLoading) {
    return null;
  }

  const handleClick = async () => {
    try {
      setIsLoading(true);
      const files = (await selectSystemFiles(false)) ?? [];

      if (files.length === 1) {
        await uploadFile({
          cellAddress: { tableId, columnId, rowId },
          file: files[0],
        });
      }

      addUtilityToastSuccess("File upload initiated");
    } catch (error) {
      addUtilityToastFailure("Failed to initiate file upload");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <span css={styles.root}>
      <EuiButtonIcon
        aria-label="Upload file"
        iconType="exportAction"
        onClick={() => void handleClick()}
        isLoading={isLoading}
      />
    </span>
  );
};
