import assert from "assert";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import { createContext, ReactNode, useContext, useState } from "react";
import { createStore, StoreApi, useStore } from "zustand";
import { capitalize } from "lodash";
import { Canvas } from "components/RoiEditor/RoiEditorCanvas";
import {
  BoundingBox,
  IsxShapeOptions,
  RoiShape,
  Shape,
} from "./RoiEditor.helpers";

export type ShapeState = {
  id: string;
  object: RoiShape;
  stroke: string;
  name: string;
};

export type RoiEditorContextValue = {
  boundingBox?: {
    id: string;
    object: BoundingBox;
    left: number;
    top: number;
    width: number;
    height: number;
    stroke: string;
    name: string;
    groupKey: string;
  };
  shapes: ShapeState[];
  applyCanvasListeners: (canvas: Canvas) => void;
  isDrawing: boolean;
  selectedShapeIds: Set<Shape["id"]>;
};

export const RoiEditorContext = createContext<
  StoreApi<RoiEditorContextValue> | undefined
>(undefined);

const createRoiEditorStore = () =>
  createStore<RoiEditorContextValue>((set, get) => ({
    isDrawing: false,
    shapes: [],
    selectedShapeIds: new Set(),
    applyCanvasListeners: (canvas) => {
      canvas.onBeginDrawing = () => {
        set({ isDrawing: true });
      };
      canvas.onEndDrawing = (shapeAdded) => {
        if (shapeAdded instanceof BoundingBox) {
          set({
            boundingBox: {
              id: shapeAdded.id,
              object: shapeAdded,
              left: shapeAdded.left,
              top: shapeAdded.top,
              height: shapeAdded.height - shapeAdded.strokeWidth,
              width: shapeAdded.width - shapeAdded.strokeWidth,
              stroke: shapeAdded.stroke,
              name: shapeAdded.name ?? "",
              groupKey: shapeAdded.groupKey ?? "",
            },
          });
        }

        const shapeCountInGroup = get()
          .shapes.filter(
            ({ object }) => object.groupKey === shapeAdded.groupKey,
          )
          .filter(
            ({ object }) => object.shapeType === shapeAdded.shapeType,
          ).length;

        if (shapeAdded.name === undefined) {
          shapeAdded.name = `${capitalize(shapeAdded.shapeType)} #${
            shapeCountInGroup + 1
          }`;
        }

        set({
          shapes: [
            ...get().shapes,
            {
              id: shapeAdded.id,
              stroke: shapeAdded.stroke,
              object: shapeAdded,
              name: shapeAdded.name,
            },
          ],
          isDrawing: false,
        });
      };

      /**
       * Remove from state when shape deleted from canvas
       */
      canvas.on("object:removed", (e) => {
        const shapes = get().shapes;
        const shapeRemoved = e.target as Shape;
        const shapeFoundIdx = shapes.findIndex(
          (shape) => shape.id === shapeRemoved.id,
        );
        if (shapeFoundIdx > -1) {
          shapes.splice(shapeFoundIdx, 1);
          set({ shapes: [...shapes] });
        }
      });

      /**
       * Manage Selection State
       */
      canvas.on("selection:cleared", () => {
        set({ selectedShapeIds: new Set() });
      });

      canvas.on("selection:updated", (e) => {
        const selectedShapeIds = get().selectedShapeIds;
        e.selected.forEach((selected) =>
          selectedShapeIds.add((selected as Shape).id),
        );
        e.deselected.forEach((selected) =>
          selectedShapeIds.delete((selected as Shape).id),
        );
        set({ selectedShapeIds: new Set(selectedShapeIds) });
      });

      canvas.on("selection:created", (e) => {
        const selectedShapeIds = get().selectedShapeIds;

        e.selected.forEach((selected) =>
          selectedShapeIds.add((selected as Shape).id),
        );

        set({ selectedShapeIds: new Set(selectedShapeIds) });
      });

      /**
       * Handler for bounding box state update
       */
      const updateBoundingBoxDimensions = (dimensions: {
        left: number;
        top: number;
        width: number;
        height: number;
      }) => {
        const boundingBox = get().boundingBox;

        if (boundingBox !== undefined) {
          set({
            boundingBox: {
              ...boundingBox,
              ...dimensions,
            },
          });
        }
      };

      /**
       * Watch proxy for property changes to keep state up to date
       */
      canvas.onObjectPropertyChanged = (object, property, value) => {
        /**
         * Update the state of bounding box when modified directly on canvas
         */

        if (object instanceof BoundingBox) {
          if (
            property === "top" ||
            property === "left" ||
            property === "width" ||
            property === "height" ||
            // for some reason the proxy does not detect updates
            // to height and width when scaling unless this property is watched.
            property === "cacheHeight" ||
            property === "cacheWidth"
          ) {
            updateBoundingBoxDimensions({
              height: object.height - object.strokeWidth,
              width: object.width - object.strokeWidth,
              left: object.left,
              top: object.top,
            });
          }
        }

        // shape color
        if (property === "stroke") {
          const shapes = get().shapes;
          const shapeChangedIdx = shapes.findIndex(
            (shape) => shape.id === object.id,
          );
          if (shapeChangedIdx > -1) {
            const shapeChanged = shapes[shapeChangedIdx];

            shapeChanged["stroke"] = value as string;

            shapes[shapeChangedIdx] = { ...shapeChanged };

            set({ shapes: [...shapes] });
          } else {
            // capture exception
          }
        }

        /**
         * Watch shape name for changes and update store
         */

        if ((property as keyof IsxShapeOptions) === "name") {
          const shapes = get().shapes;
          const shapeChangedIdx = shapes.findIndex(
            (shape) => shape.id === object.id,
          );

          if (shapeChangedIdx > -1) {
            const shapeChanged = shapes[shapeChangedIdx];

            shapeChanged["name"] = value as string;
            shapes[shapeChangedIdx] = { ...shapeChanged };

            set({ shapes: [...shapes] });
          }
        }
      };
    },
  }));

interface RoiEditorProviderProps {
  children: ReactNode;
}

export const RoiEditorProvider = ({ children }: RoiEditorProviderProps) => {
  const [store] = useState(createRoiEditorStore);
  return (
    <RoiEditorContext.Provider value={store}>
      {children}
    </RoiEditorContext.Provider>
  );
};

export const useRoiEditorContext = <T,>(
  selector: (state: RoiEditorContextValue) => T,
) => {
  const store = useContext(RoiEditorContext);
  assert(store !== undefined, new ContextOutOfBoundsError("RoiEditorContext"));
  return useStore(store, selector);
};
