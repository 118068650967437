import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiTitle,
} from "@inscopix/ideas-eui";
import { ProjectCards } from "components/ProjectsCards/ProjectCards";
import { ButtonCreateProject } from "../ButtonCreateProject/ButtonCreateProject";
import {
  GetAllProjectsQuery,
  GetAllProjectsQueryVariables,
  ProjectsOrderBy,
  Tenant,
  useGetAllProjectsQuery,
} from "graphql/_Types";
import { ProjectStatus } from "types/constants";
import { captureException } from "@sentry/react";
import { QueryHookOptions } from "@apollo/client";
import { isDefined } from "utils/isDefined";
import { isUndefined } from "lodash";
import { useGetExternalProjects } from "hooks/useGetExternalProjects";

export const projectCardsQueryOptions: QueryHookOptions<
  GetAllProjectsQuery,
  GetAllProjectsQueryVariables
> = {
  variables: {
    filter: {
      and: [
        {
          or: [
            {
              status: {
                in: [ProjectStatus["AVAILABLE"], ProjectStatus["CLONING"]],
              },
            },
          ],
        },
      ],
    },
    orderBy: [ProjectsOrderBy.DateCreatedDesc],
  },
};

interface PanelProjectsProps {
  panelTitle?: string;
  tenantId?: Tenant["id"];
  showCreateProject?: boolean;
}

export const PanelProjects = ({
  panelTitle = "Projects",
  tenantId,
  showCreateProject = true,
}: PanelProjectsProps) => {
  const {
    data: internalData,
    loading: internalLoading,
    error: internalError,
  } = useGetAllProjectsQuery({
    ...projectCardsQueryOptions,
    onError: (err) => captureException(err),
  });

  const { projects: externalProjectsData, loading: externalLoading } =
    useGetExternalProjects();

  const internalProjects =
    internalData?.projects?.nodes
      .map((project) => ({
        ...project,
        /* FIXME: Necessary property to enable filtering through the search bar
       downstream. */
        user: project.user?.username,
        tenantKey: project.tenantByTenantId?.key,
        tenantName: project.tenantByTenantId?.name,
      }))
      .filter((project) =>
        isDefined(tenantId) ? project.tenantId === tenantId : true,
      ) ?? [];

  const externalProjects =
    // don't show any external projects if a tenantId is defined
    // tenantId is defined when viewing an org page, which can only be internal to the region
    (isUndefined(tenantId)
      ? externalProjectsData
          .filter((project) => isDefined(project))
          .map((project) => ({
            ...project,
            /* FIXME: Necessary property to enable filtering through the search bar
       downstream. */
            user: project.user?.username,
            tenantKey: project.tenantByTenantId?.key,
            tenantName: project.tenantByTenantId?.name,
          }))
      : []) ?? [];

  // need to sort despite queries already being sorted because we are merging
  const projects = [...internalProjects, ...externalProjects].sort((a, b) => {
    if (a.dateCreated < b.dateCreated) {
      return 1;
    }
    if (a.dateCreated > b.dateCreated) {
      return -1;
    }
    return 0;
  });

  return (
    <div>
      <EuiFlexGroup responsive={false}>
        <EuiFlexItem>
          <EuiTitle>
            <h4>{panelTitle}</h4>
          </EuiTitle>
        </EuiFlexItem>
        {showCreateProject && (
          <EuiFlexItem grow={false}>
            <ButtonCreateProject tenantId={tenantId} />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      <EuiSpacer />
      <ProjectCards
        isError={
          isDefined(internalError) ||
          (!internalLoading && isUndefined(projects))
        }
        isLoading={internalLoading || externalLoading}
        projects={projects ?? []}
        tenantId={tenantId}
      />
    </div>
  );
};
