import { EuiFieldText } from "@inscopix/ideas-eui";
import { FieldDefaultFormula } from "./FieldDefaultFormula";

interface FieldDefaultFormulaTextProps {
  autoFocus?: boolean;
  value: string | undefined;
  onChange: (field: { value: string | undefined; isValid: boolean }) => void;
}

export const FieldDefaultFormulaText = ({
  autoFocus,
  value,
  onChange,
}: FieldDefaultFormulaTextProps) => {
  return (
    <FieldDefaultFormula>
      <EuiFieldText
        autoFocus={autoFocus}
        placeholder="None"
        value={value ?? ""}
        onChange={(e) => {
          onChange({
            value: e.target.value !== "" ? e.target.value : undefined,
            isValid: true,
          });
        }}
        maxLength={255}
      />
    </FieldDefaultFormula>
  );
};
