import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiSpacer,
  EuiText,
} from "@inscopix/ideas-eui";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { PanelTenantUsageContent } from "components/PanelTenantUsage/PanelTenantUsageContent";
import { useRoutes } from "hooks/useRoutes";

const PageOrganizationSidebarOrg = () => {
  const { routeMap } = useRoutes();
  const currentTenant = useTenantContext((s) => s.currentTenant);

  return (
    <EuiFlexGroup alignItems="center" gutterSize="xs">
      <EuiFlexItem>
        <EuiFlexGroup direction="column" gutterSize={"none"}>
          <EuiFlexItem>
            <EuiText title={`${currentTenant.name}`}>
              <h3>{currentTenant.name}</h3>
            </EuiText>
          </EuiFlexItem>
          <EuiSpacer size="xs" />
          <EuiFlexItem>
            <EuiLink
              to={
                routeMap["ORGANIZATION_MANAGE"].dynamicPath({
                  tenantKey: currentTenant.key,
                }).path
              }
            >
              Manage
            </EuiLink>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

const PageOrganizationSidebarPeople = () => {
  const tenantUsers = useTenantContext((s) => s.tenantUsers);

  return (
    <EuiFlexGroup direction="column" gutterSize={"s"}>
      <EuiFlexItem>
        <EuiText>
          <h4>People</h4>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize={"s"} wrap={true} responsive={false}>
          {tenantUsers
            .filter(({ internal, isActive }) => !internal && isActive)
            .map((user) => (
              <EuiFlexItem key={user.id} grow={false}>
                <UserAvatar userId={user.id} size="l" />
              </EuiFlexItem>
            ))}
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

const PageOrganizationSideBarUsage = () => {
  return (
    <EuiFlexGroup direction="column" gutterSize={"s"}>
      <EuiFlexItem>
        <EuiText>
          <h4>Usage</h4>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <PanelTenantUsageContent compact />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export const PageOrganizationSideBar = () => {
  return (
    <EuiFlexGroup direction="column" gutterSize="xl">
      <EuiFlexItem>
        <PageOrganizationSidebarOrg />
      </EuiFlexItem>
      <EuiFlexItem>
        <PageOrganizationSidebarPeople />
      </EuiFlexItem>
      <EuiFlexItem>
        <PageOrganizationSideBarUsage />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
