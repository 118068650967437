/** @jsxImportSource @emotion/react */
import { css, Interpolation, Theme } from "@emotion/react";
import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiIcon,
  EuiPopover,
  IconType,
  saturate,
  shade,
  tint,
  transparentize,
  useEuiTheme,
} from "@inscopix/ideas-eui";
import { Tooltip, TooltipProps } from "components/Tooltip/Tooltip";
import { CSSProperties, HTMLAttributes, MouseEvent, useState } from "react";
import { isDefined } from "utils/isDefined";
import { SetRequired } from "type-fest";

const styles = {
  root: (
    backgroundColor: string,
    hasContextMenu: boolean,
    hasOnClick: boolean,
  ) => [
    hasOnClick &&
      css`
        cursor: pointer;
      `,
    css`
      -webkit-text-decoration: none;
      background-color: ${backgroundColor};
      border-radius: 3px;
      border: 1px solid transparent;
      display: block;
      font-size: 0.8571rem;
      font-weight: 400;
      line-height: 18px;
      max-inline-size: 100%;
      padding-left: 8px;
      padding-right: ${hasContextMenu ? "0px" : "8px"};
      text-align: start;
      text-decoration: none;
      white-space: nowrap;
      color: #343741;
    `,
  ],
  paddingContainer: [
    css`
      padding: 4px 4px 4px 0px; // Allowing the box shadow and the outline for series and cell highlighting to have the necessary space
      display: inline-block;
      max-inline-size: 100%;
    `,
  ],
  content: css`
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    min-block-size: 24px;
    overflow: hidden;
  `,
  icon: (iconColor: CSSProperties["color"]) => css`
    -moz-transform: translate(0, 0);
    -ms-flex-negative: 0;
    -ms-transform: translate(0, 0);
    -webkit-flex-shrink: 0;
    -webkit-transform: translate(0, 0);
    block-size: 12px;
    color: ${iconColor};
    display: inline-block;
    fill: currentColor;
    flex-shrink: 0;
    inline-size: 12px;
    transform: translate(0, 0);
    vertical-align: middle;
  `,
  text: (hasContextMenu: boolean) => css`
    padding: 0px 4px;
    max-inline-size: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  `,
  contextMenu: css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  `,
  dashedBorder: (backgroundColor: string) => css`
    border: 1px dashed ${shade(backgroundColor, 0.25)};
  `,
  solidBorder: (backgroundColor: string) => css`
    border: 1px solid ${shade(backgroundColor, 0.25)};
  `,
  active: (color: string) => css`
    outline: 2px solid ${saturate(color, 0.5)};
    border: 1px solid white;
    box-shadow: 0px 0px 0px 4px ${tint(color, 0.75)};
  `,
  boxShadow: (backgroundColor: string) => css`
    box-shadow:
      ${backgroundColor} 1px 1px,
      ${transparentize(shade(backgroundColor, 0.25), 0.6)} 2px 2px,
      ${backgroundColor} 3px 3px;
  `,
};

type ContextMenuItem = {
  key: string;
  icon: IconType;
  onClick?: () => void;
  title: string;
};

export interface FileBadgeBaseProps
  extends Pick<HTMLAttributes<HTMLDivElement>, "onClick"> {
  backgroundColor: string;
  contextMenuItems?: ContextMenuItem[];
  icon: IconType;
  border?: "dashed" | "solid" | "transparent";
  isCompact?: boolean;
  name: string;
  hasContextMenu?: boolean;
  hasBoxShadow?: boolean;
  hasTooltip?: boolean;
  tooltipContent?: TooltipProps["content"];
  iconColor?: CSSProperties["color"];
  isActive?: boolean;
  cssOverrides?: Interpolation<Theme>;
}

export const FileBadgeBase = ({
  backgroundColor,
  contextMenuItems = [],
  icon,
  border,
  isCompact = false,
  name,
  hasContextMenu = true,
  hasBoxShadow = false,
  tooltipContent,
  hasTooltip = true,
  iconColor = "inherit",
  isActive = false,
  cssOverrides,
  ...htmlDivElementProps
}: FileBadgeBaseProps) => {
  const { euiTheme } = useEuiTheme();
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  // Avoid showing context menu items that do nothing when clicked
  const visibleContextMenuItems = contextMenuItems.filter((item) =>
    isDefined(item.onClick),
  ) as SetRequired<ContextMenuItem, "onClick">[];

  const shouldDisplayContextMenu =
    hasContextMenu && visibleContextMenuItems.length > 0;

  return (
    <Tooltip
      content={tooltipContent}
      // Hide tooltip when specified, leave it uncontrolled otherwise
      isOpen={!hasTooltip ? false : undefined}
    >
      <div css={styles.paddingContainer} {...htmlDivElementProps}>
        <span
          css={[
            ...styles.root(
              backgroundColor,
              shouldDisplayContextMenu,
              htmlDivElementProps.onClick !== undefined,
            ),
            hasBoxShadow && styles.boxShadow(backgroundColor),
            border === "solid" && styles.solidBorder(backgroundColor),
            border === "dashed" && styles.dashedBorder(backgroundColor),
            isActive && styles.active(euiTheme.colors.primary),
            cssOverrides,
          ]}
        >
          <span css={styles.content}>
            <EuiIcon css={styles.icon(iconColor)} type={icon} />
            {!isCompact && (
              <span css={styles.text(shouldDisplayContextMenu)}>{name}</span>
            )}
            {shouldDisplayContextMenu && (
              <EuiPopover
                button={
                  <EuiButtonIcon
                    iconType="boxesVertical"
                    aria-label="Menu"
                    color="text"
                    iconSize="s"
                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsContextMenuOpen((isOpen) => !isOpen);
                    }}
                  />
                }
                isOpen={isContextMenuOpen}
                closePopover={() => setIsContextMenuOpen(false)}
                anchorPosition="rightCenter"
                panelPaddingSize="none"
              >
                <div css={styles.contextMenu}>
                  {visibleContextMenuItems.map((item) => (
                    <EuiButtonEmpty
                      key={item.key}
                      onClick={(e: MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault();
                        e.stopPropagation();
                        item.onClick();
                        setIsContextMenuOpen(false);
                      }}
                      iconType={item.icon}
                      color="text"
                      size="s"
                      // TODO: Add back when project permissions are reimplemented
                      // requiredPermission="edit"
                    >
                      {item.title}
                    </EuiButtonEmpty>
                  ))}
                </div>
              </EuiPopover>
            )}
          </span>
        </span>
      </div>
    </Tooltip>
  );
};
