import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@inscopix/ideas-eui";
import { useState } from "react";
import { useDataTableContext } from "../store/DataTableProvider";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";

interface ModalDeleteTableProps {
  tableId: string;
  onClose: () => void;
}

export const ModalDeleteTable = ({
  tableId,
  onClose,
}: ModalDeleteTableProps) => {
  const deleteTable = useDataTableContext((s) => s.deleteTable);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    const { error } = await deleteTable({ tableId });
    setIsLoading(false);

    if (error === undefined) {
      addUtilityToastSuccess("Table deleted");
    } else {
      addUtilityToastFailure("Failed to delete table");
    }

    onClose();
  };

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle component="h3">Delete Table</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiCallOut
          title="Proceed with caution!"
          color="warning"
          iconType="warning"
        >
          <p>
            Cells in the deleted table may be referenced in other tables.
            Deleting this table might caused unwanted changes to your data.
          </p>
        </EuiCallOut>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiButton
          isLoading={isLoading}
          fill
          color="danger"
          onClick={() => void handleSubmit()}
        >
          Delete
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
