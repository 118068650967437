import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@inscopix/ideas-eui";
import { useState } from "react";
import { useDataTableContext } from "../store/DataTableProvider";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { isDefined } from "utils/isDefined";
import assert from "assert";

interface ModalDeleteColumnProps {
  columnId: string;
}

export const ModalDeleteColumn = ({ columnId }: ModalDeleteColumnProps) => {
  const closeModal = useDataTableContext((s) => () => s.setModal(null));
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const deleteDataTableColumn = useDataTableContext(
    (s) => s.deleteDataTableColumn,
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    assert(isDefined(selectedTableId));
    const { error } = await deleteDataTableColumn({
      tableId: selectedTableId,
      columnId,
    });
    setIsLoading(false);

    if (error === undefined) {
      addUtilityToastSuccess("Column deleted");
    } else {
      addUtilityToastFailure("Failed to delete column");
    }

    closeModal();
  };

  return (
    <EuiModal onClose={closeModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle component="h3">Remove Column</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiCallOut
          title="Proceed with caution!"
          color="warning"
          iconType="warning"
        >
          <p>
            Cells in the removed column may be referenced in other tables.
            Removing this column might caused unwanted changes to your data.
          </p>
        </EuiCallOut>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>
        <EuiButton
          isLoading={isLoading}
          fill
          color="danger"
          onClick={() => void handleSubmit()}
        >
          Remove
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
