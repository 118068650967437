import { useEffect, useState } from "react";
import { useDataTableContext } from "../store/DataTableProvider";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import WorkflowSelectionModal from "components/WorkflowSelectionModal/WorkflowSelectionModal";
import { getAllWorkflows } from "components/ModalCreateWorkflow/ModalCreateWorkflow.helpers";

interface ModalCreateAnalysisTableProps {
  onClose: () => void;
}

/** Component that renders a modal for create a new analysis table */
export const ModalCreateAnalysisTable = ({
  onClose,
}: ModalCreateAnalysisTableProps) => {
  const createAnalysisTable = useDataTableContext((s) => s.createAnalysisTable);
  const createWorkflowTable = useDataTableContext((s) => s.createWorkflowTable);
  const [isLoading, setIsLoading] = useState(false);
  const [workflows, setWorkflows] = useState<
    { id: string; name: string; description: string }[] | undefined
  >();

  useEffect(() => {
    void getAllWorkflows().then(setWorkflows);
  }, []);

  const handleSubmit = async (
    toolVersionId: number | string,
    name: string,
    key: string,
  ) => {
    if (typeof toolVersionId === "string") {
      setIsLoading(true);
      const { error } = await createWorkflowTable({
        workflowId: toolVersionId,
        name,
        key,
      });
      setIsLoading(false);

      if (error === undefined) {
        addUtilityToastSuccess("Analysis table created");
      } else {
        addUtilityToastFailure("Failed to create analysis table");
      }
    } else {
      setIsLoading(true);
      const { error } = await createAnalysisTable({
        toolVersionId,
        name,
        key,
      });
      setIsLoading(false);

      if (error === undefined) {
        addUtilityToastSuccess("Analysis table created");
      } else {
        addUtilityToastFailure("Failed to create analysis table");
      }
    }

    onClose();
  };

  return (
    <WorkflowSelectionModal
      isLoading={isLoading || workflows === undefined}
      onClose={onClose}
      onSubmit={(toolSpec, name, key) =>
        handleSubmit(
          typeof toolSpec === "string" ? toolSpec : toolSpec.toolVersionId,
          name,
          key,
        )
      }
      selectedToolSpecKey={undefined}
      workflows={workflows ?? []}
    />
  );
};
