import { EuiFieldNumber } from "@inscopix/ideas-eui";
import { FieldDefaultFormula } from "./FieldDefaultFormula";

interface FieldDefaultFormulaIntegerProps {
  autoFocus?: boolean;
  value: string | undefined;
  onChange: (field: { value: string | undefined; isValid: boolean }) => void;
}

export const FieldDefaultFormulaInteger = ({
  autoFocus,
  value,
  onChange,
}: FieldDefaultFormulaIntegerProps) => {
  return (
    <FieldDefaultFormula>
      <EuiFieldNumber
        autoFocus={autoFocus}
        placeholder="None"
        step={1}
        value={parseInt(value ?? "")}
        onChange={(e) => {
          const newValue = parseInt(e.target.value);
          onChange({
            value: isNaN(newValue) ? value : e.target.value.replace(".", ""),
            isValid: e.target.value === "" || !isNaN(newValue),
          });
        }}
      />
    </FieldDefaultFormula>
  );
};
