import {
  EuiBasicTable,
  EuiLoadingChart,
  EuiTableComputedColumnType,
} from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import { CallOutError } from "components/CallOutError/CallOutError";
import {
  Metadatum,
  MetadataValue,
  File as DrsFile,
  useGetFileMetadataByFileIdQuery,
} from "graphql/_Types";
import { merge } from "lodash";
import { JsonValue } from "type-fest";
import { FILE_TYPES_BY_ID } from "types/FileTypes";
import { metadataMap } from "types/MetadataMap";
import { isNonNull } from "utils/isNonNull";
import { isNonNullish } from "utils/isNonNullish";
import { stringifyMetadataValue } from "utils/stringifyMetadataValue";

type TMetadataDisplayKeyAndValues = {
  key: Metadatum["key"];
  displayName: string;
  value: MetadataValue["value"];
};

type FlyoutFileInfoBodyMetadataTableProps = {
  drsFile: Pick<DrsFile, "id" | "status" | "fileType">;
};

export const FlyoutFileInfoBodyMetadataTable = ({
  drsFile,
}: FlyoutFileInfoBodyMetadataTableProps) => {
  const { data, loading } = useGetFileMetadataByFileIdQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      drsFileId: drsFile.id,
      metadataFilter: {
        metadatumByMetadataId: {
          key: {
            in: [
              "isx.extraProperties.idps.cellset.method",
              "ideas.metrics.num_accepted_cells",
              "ideas.metrics.num_rejected_cells",
              "ideas.metrics.num_undecided_cells",
              "ideas.metrics.total_num_cells",
              "ideas.timingInfo.numTimes",
              "isx.extraProperties.microscope.fps.fps",
              "isx.extraProperties.processingInterface.recordingUUID",
              "isx.extraProperties.microscope.type",
              "isx.cameraName",
              "ideas.timingInfo.blank",
              "ideas.timingInfo.cropped",
              "ideas.timingInfo.dropped",
              "nvision.droppedFrames",
              "ideas.spacingInfo.numPixels.x",
              "ideas.spacingInfo.numPixels.y",
              "isx.extraProperties.idps.efocus",
              "isx.extraProperties.probe.name",
              "isx.extraProperties.microscope.serial",
              "dlc.model_neural_net_type",
              "dlc.body_parts",
              "ideas.dlc.task",
              "ideas.dlc.scorer",
              "ideas.dlc.date",
              "ideas.dlc.multi_animal_project",
              "ideas.dlc.model_neural_net_type",
              "ideas.dlc.body_parts",
              "ideas.dlc.snapshot",
              "isx.extraProperties.idps.channel",
              "event_type",
              "ideas.metrics.num_valid_events",
              "ideas.metrics.num_up_modulated_cells",
              "ideas.metrics.num_down_modulated_cells",
              "ideas.metrics.num_non_modulated_cells",
              "ideas.metrics.num_channels",
              "isx.channel list",
              "ideas.metrics.total_num_events",
              "ideas.metrics.num_event_types",
              "ideas.metrics.num_events_per_type.keys",
              "ideas.metrics.num_events_per_type",
              "ideas.timingInfo.numSamples",
              "ideas.timingInfo.numFrames",
              "ideas.metrics.num_rows",
              "ideas.metrics.num_columns",
              "ideas.timingInfo.sampling_rate",
              "ideas.dataset.states",
              "ideas.metrics.total_num_cells_group1",
              "ideas.metrics.total_num_cells_group2",
              "ideas.dataset.session_start_time",
              "ideas.dataset.dandi_compliant",
              "ideas.recording_uuid",
              "ideas.total_distance_traveled_px",
              "ideas.min_speed_px_s",
              "ideas.max_speed_px_s",
              "ideas.mean_speed_px_s",
              "ideas.median_speed_px_s",
              "ideas.std_speed_px_s",
              "ideas.total_distance_traveled_cm",
              "ideas.min_speed_cm_s",
              "ideas.max_speed_cm_s",
              "ideas.mean_speed_cm_s",
              "ideas.median_speed_cm_s",
              "ideas.std_speed_cm_s",
              "ideas.time_spent_resting",
              "ideas.time_spent_moving",
              "isx.extraProperties.microscope.widefield",
              "ideas.pixelsPerCm",
              "ideas.Cell Extraction Method",
              "ideas.Number of Frames",
              "ideas.Sampling Rate (Hz)",
              "ideas.Recording Duration (s)",
              "ideas.Number of Planes",
              "ideas.Number of Channels",
              "ideas.Frame Width (px)",
              "ideas.Frame Height (px)",
              "ideas.Number of Extracted ROIs",
              "ideas.Number of Accepted ROIs",
              "ideas.Average ROI Radius (px)",
              "ideas.Average trace amplitude",
              "ideas.Average event rate (Hz)",
              "ideas.Minimum event rate (Hz)",
              "ideas.Maximum event rate (Hz)",
            ],
          },
        },
      },
    },
    onError: (err) => captureException(err),
  });
  const columns: Array<
    EuiTableComputedColumnType<TMetadataDisplayKeyAndValues>
  > = [
    {
      render: (data) => data.displayName,
      name: "",
      width: "50%",
    },
    {
      render: (data) => data.value,
      name: "",
      align: "right",
    },
  ];

  const metadata = data?.drsFile?.metadata.nodes
    .map(({ metadatum }) => metadatum)
    .filter(isNonNull);

  const fileTypeKey = isNonNullish(drsFile.fileType)
    ? FILE_TYPES_BY_ID[drsFile.fileType]?.key
    : undefined;

  // get file metadata
  const metadataForFileType = isNonNullish(fileTypeKey)
    ? metadataMap[fileTypeKey]
    : undefined;

  if (
    // check for query error
    metadata === undefined ||
    // invalid data states where we don't have a file type or a metadata map entry
    // these are checked upstream so this should never occur
    fileTypeKey === undefined ||
    metadataForFileType === undefined
  ) {
    if (loading) {
      return <EuiLoadingChart />;
    }
    return <CallOutError />;
  }

  // series parent metadata if exists
  const seriesParentMetadataByKey =
    data?.drsFile?.seriesParentFile?.metadata.nodes
      .map(({ metadatum }) => metadatum)
      .filter(isNonNull)
      .reduce<Record<Metadatum["key"], JsonValue>>(
        (metadataByKey, { key, activeValue }) => {
          metadataByKey[key] = activeValue;
          return metadataByKey;
        },
        {},
      );

  // active file metadata
  const metadataByKey = metadata.reduce<Record<Metadatum["key"], JsonValue>>(
    (metadataByKey, { key, activeValue }) => {
      metadataByKey[key] = activeValue;
      return metadataByKey;
    },
    {},
  );

  // combined metadata
  // inherit series parent metadata and overwrite with child values if exist
  const combinedMetadataByKey = merge(seriesParentMetadataByKey, metadataByKey);

  const metadataDisplayKeyAndValues: TMetadataDisplayKeyAndValues[] = [];

  for (const [metadataMapKey, metadataMapDisplayName] of Object.entries(
    metadataForFileType,
  )) {
    const metadataValue = combinedMetadataByKey[metadataMapKey];

    if (
      isNonNullish(metadataValue) &&
      !(Array.isArray(metadataValue) && metadataValue.length === 0)
    ) {
      metadataDisplayKeyAndValues.push({
        key: metadataMapKey,
        displayName: metadataMapDisplayName,
        value: stringifyMetadataValue(metadataValue),
      });
    }
  }

  return (
    <EuiBasicTable
      responsive={false}
      items={metadataDisplayKeyAndValues}
      rowHeader="displayName"
      columns={columns}
      compressed
      tableLayout="auto"
    />
  );
};
