import { euiPaletteColorBlindBehindText } from "@inscopix/ideas-eui";
import { RunStatus, TaskStatus } from "./constants";

const colors = euiPaletteColorBlindBehindText({ sortBy: "natural" });

type TColorsByTaskStatus = {
  [status in TaskStatus]: string;
};

export const ColorsByTaskStatus: TColorsByTaskStatus = {
  [TaskStatus.NEW]: "hollow",
  [TaskStatus.QUEUED]: colors[8],
  [TaskStatus.INITIALIZING]: colors[9],
  [TaskStatus.RUNNING]: colors[1],
  [TaskStatus.PAUSED]: colors[2],
  [TaskStatus.COMPLETE]: colors[0],
  [TaskStatus.FAILED]: colors[5],
  [TaskStatus.ERROR]: colors[5],
  [TaskStatus.CANCELED]: colors[7],
} as const;

type TColorsByRunStatus = {
  [status in RunStatus]: string;
};

export const ColorsByRunStatus: TColorsByRunStatus = {
  [RunStatus.NEW]: "hollow",
  [RunStatus.RUNNING]: colors[1],
  [RunStatus.COMPLETE]: colors[0],
  [RunStatus.FAILED]: colors[5],
  [RunStatus.CANCELED]: colors[7],
} as const;
