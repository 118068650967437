import {
  EuiButton,
  EuiContextMenu,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiInputPopover,
  EuiToken,
} from "@inscopix/ideas-eui";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import { useEffect, useState } from "react";
import { COLUMN_KIND_MAP } from "../ModalCreateColumn/ModalCreateColumn";
import { isDefined } from "utils/isDefined";
import { DataTableColumnDefinition } from "pages/gdt/store/DataTableProvider.types";

interface FieldColumnProps {
  autoFocus?: boolean;
  disabled?: boolean;
  helpText?: string;
  filter?: {
    columnKind?: DataTableColumnDefinition["kind"];
    tableId?: string;
  };
  label?: string;
  value:
    | {
        id: string;
        order: number;
        kind: "data_table_column" | "gdt_analysis_table_column";
        table: { id: string; key: string };
      }
    | undefined;
  onChange: (field: {
    value:
      | {
          id: string;
          order: number;
          kind: "data_table_column" | "gdt_analysis_table_column";
          table: { id: string; key: string };
        }
      | undefined;
    isValid: boolean;
  }) => void;
}

export const FieldColumn = ({
  autoFocus,
  disabled = false,
  helpText,
  filter,
  label = "Column",
  value,
  onChange,
}: FieldColumnProps) => {
  const tables = useDataTableContext((s) => s.tables);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [tableId, setTableId] = useState(filter?.tableId);
  const column = useDataTableContext((s) => {
    const allColumns = s.tables.flatMap((table) => table.columns);
    return allColumns.find((column) => column.id === value?.id);
  });

  const togglePopover = () => setIsPopoverOpen((isOpen) => !isOpen);
  const closePopover = () => setIsPopoverOpen(false);

  useEffect(() => {
    setTableId(filter?.tableId);
  }, [filter?.tableId]);

  const panels = [
    {
      id: 0,
      title: "Tables",
      width: "100%",
      items: tables
        .filter((table) => {
          const tableIdFilter = filter?.tableId;
          return isDefined(tableIdFilter) ? table.id === tableIdFilter : true;
        })
        .map((table) => ({
          name: (
            <span>
              {table.name} <span style={{ color: "grey" }}>({table.key})</span>
            </span>
          ),
          icon:
            table.kind === "analysis" ? "indexMapping" : "tableDensityNormal",
          panel: table.id,
        })),
    },
    ...tables.map((table) => ({
      id: table.id,
      width: "100%",
      title: (
        <span>
          {table.name} <span style={{ color: "grey" }}>({table.key})</span>
        </span>
      ),
      items: table.columns
        .filter((column) => {
          const columnKindFilter = filter?.columnKind;
          return isDefined(columnKindFilter)
            ? column.definition.kind === columnKindFilter
            : true;
        })
        .map((column) => ({
          name: column.name,
          icon: (
            <EuiToken iconType={COLUMN_KIND_MAP[column.definition.kind].icon} />
          ),
          onClick: () => {
            setTableId(table.id);
            onChange({
              value: {
                id: column.id,
                order: column.order,
                kind:
                  table.kind === "data"
                    ? "data_table_column"
                    : "gdt_analysis_table_column",
                table: {
                  id: table.id,
                  key: table.key,
                },
              },
              isValid: true,
            });
            closePopover();
          },
        })),
    })),
  ];

  return (
    <EuiFormRow label={label} helpText={helpText}>
      <EuiInputPopover
        isOpen={isPopoverOpen}
        closePopover={closePopover}
        panelPaddingSize="none"
        anchorPosition="downLeft"
        autoFocus={autoFocus}
        input={
          <EuiButton
            iconSide="right"
            iconType="arrowDown"
            onClick={togglePopover}
            fullWidth
            color="text"
            style={{ backgroundColor: "#fbfcfd", border: "1px solid #e3e6f1" }}
            disabled={disabled}
          >
            {isDefined(column) && (
              <span
                style={{
                  fontWeight: 400,
                  marginRight: "auto",
                  fontFamily:
                    '"Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                <EuiFlexGroup
                  alignItems="center"
                  gutterSize="s"
                  responsive={false}
                >
                  <EuiFlexItem grow={false}>
                    <EuiToken
                      iconType={COLUMN_KIND_MAP[column.definition.kind].icon}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>{column.name}</EuiFlexItem>
                </EuiFlexGroup>
              </span>
            )}

            {value === undefined && (
              <span
                style={{
                  color: "#98A2B3",
                  fontWeight: 400,
                  marginRight: "auto",
                  fontFamily:
                    '"Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  textDecorationLine: "none !important",
                }}
              >
                Select a column...
              </span>
            )}
          </EuiButton>
        }
      >
        <EuiContextMenu initialPanelId={tableId ?? 0} panels={panels} />
      </EuiInputPopover>
    </EuiFormRow>
  );
};
