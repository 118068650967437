import { TenantProvider } from "providers/TenantProvider/TenantProvider";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { PageOrganization } from "pages/organization/PageOrganization";
import { ToolSpecProvider } from "providers/ToolSpecProvider/ToolSpecProvider";
import { PageOrganizationManage } from "pages/organization/PageOrganizationManage";
import { Page404 } from "pages/404/Page404";
import { useRoutes } from "hooks/useRoutes";
import { PageGenericDataTables } from "pages/gdt/PageGenericDataTables";

export const OrganizationRoutes = () => {
  const { routeMap } = useRoutes();
  const { path } = useRouteMatch();
  return (
    <TenantProvider>
      <ToolSpecProvider>
        <Switch>
          <Route exact path={path}>
            <PageOrganization />
          </Route>
          <Route path={routeMap["PROJECT"].parameterizedPath}>
            <PageGenericDataTables />
          </Route>
          <Route path={routeMap["ORGANIZATION_MANAGE"].parameterizedPath}>
            <PageOrganizationManage />
          </Route>
          <Route path="*">
            <Page404 />
          </Route>
        </Switch>
      </ToolSpecProvider>
    </TenantProvider>
  );
};
