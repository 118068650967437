import { EuiSideNav, Query } from "@inscopix/ideas-eui";
import { Dispatch, SetStateAction } from "react";
import { isDefined } from "utils/isDefined";

interface WorkflowSelectionModalToolkitSelectProps {
  toolkitNames: string[];
  query: Query | undefined;
  setQuery: Dispatch<SetStateAction<Query | undefined>>;
  toolkit: string | undefined;
  setToolkit: Dispatch<SetStateAction<string | undefined>>;
  setSelectedWorkflowId: Dispatch<SetStateAction<string | undefined>>;
}

export const WorkflowSelectionModalToolkitSelect = ({
  toolkitNames,
  query,
  setQuery,
  toolkit,
  setToolkit,
  setSelectedWorkflowId,
}: WorkflowSelectionModalToolkitSelectProps) => {
  const toolkits = [
    {
      id: "all",
      name: "All Toolkits",
      onClick: () => {
        setToolkit(undefined);
        setSelectedWorkflowId(undefined);
      },
      emphasize: toolkit === undefined,
    },
    {
      id: "workflows",
      name: "Workflows",
      onClick: () => setToolkit("workflows"),
      emphasize: toolkit === "workflows",
    },
    ...toolkitNames.filter(isDefined).map((toolkitName, index) => ({
      id: toolkitName,
      name: toolkitName,
      onClick: () => {
        setToolkit(toolkitName);
        setSelectedWorkflowId(undefined);
      },
      truncate: false,
      emphasize: toolkitName === toolkit,
    })),
  ];
  return (
    <EuiSideNav
      aria-label="Toolkits"
      style={{ width: 160 }}
      headingProps={{ screenReaderOnly: true }}
      items={toolkits}
      mobileBreakpoints={[]}
    />
  );
};
