import {
  EuiBadge,
  EuiSuperSelect,
  EuiSuperSelectOption,
} from "@inscopix/ideas-eui";
import { FieldDefaultFormula } from "./FieldDefaultFormula";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";

interface FieldDefaultFormulaIdentifierProps {
  autoFocus?: boolean;
  tableId: string | undefined;
  value: number | undefined;
  onChange: (field: { value: number | undefined; isValid: boolean }) => void;
}

export const FieldDefaultFormulaIdentifier = ({
  autoFocus,
  tableId,
  value,
  onChange,
}: FieldDefaultFormulaIdentifierProps) => {
  const table = useDataTableContext((s) =>
    s.tables.find((table) => table.id === tableId),
  );

  const options: EuiSuperSelectOption<string>[] = (table?.rows ?? []).map(
    (row) => ({
      value: row.index.toString(),
      inputDisplay: (
        <EuiBadge
          color="#ddd"
          style={{ fontFamily: "monospace", border: "1px solid grey" }}
        >
          {table?.key}-{row.index}
        </EuiBadge>
      ),
    }),
  );

  const handleChange = (newValue: string) => {
    const rowIndex = parseInt(newValue);
    if (rowIndex === value) {
      onChange({
        value: undefined,
        isValid: false,
      });
    } else {
      onChange({
        value: rowIndex,
        isValid: true,
      });
    }
  };

  return (
    <FieldDefaultFormula>
      <EuiSuperSelect
        autoFocus={autoFocus}
        options={options}
        valueOfSelected={value?.toString()}
        onChange={handleChange}
        placeholder="None"
        hasDividers
      />
    </FieldDefaultFormula>
  );
};
