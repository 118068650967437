/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ICellRendererParams } from "ag-grid-community";
import { isDefined } from "utils/isDefined";
import assert from "assert";
import { EuiText } from "@inscopix/ideas-eui";
import { DataTableRowData } from "../layout/DataTable";

const styles = {
  root: css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  `,
};

type CellRendererRowIndexProps = ICellRendererParams<DataTableRowData>;

/**
 * Cell renderer that renders the row number
 */
export const CellRendererRowIndex = ({
  data,
  node,
}: CellRendererRowIndexProps) => {
  assert(isDefined(data));
  return (
    <div css={styles.root}>
      <EuiText size="xs" color="subdued">
        {node.childIndex + 1}
      </EuiText>
    </div>
  );
};
