import { ReactFlowJsonObject } from "@xyflow/react";
import assert from "assert";
import axios from "axios";
import { getEnvVar } from "ideas.env";
import { chain } from "lodash";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { isDefined } from "utils/isDefined";

type GetToolVersionResult = {
  id: number;
  tool_sources: {
    id: number;
    key: string;
  }[];
  tool_results: {
    id: number;
    key: string;
  }[];
}[];

const getToolVersions = async () => {
  const url = getEnvVar("URL_TRS_TOOL_VERSION");
  const headers = await getRequestHeaders();
  const res = await axios.get<GetToolVersionResult>(url, { headers });
  return res.data;
};

type CreateWorkflowOptions = {
  name: string;
  description: string;
  layout: ReactFlowJsonObject;
};

type CreateWorkflowResult = {
  id: string;
  name: string;
  description: string;
  layout: ReactFlowJsonObject;
};

const createWorkflow = async ({
  name,
  description,
  layout,
}: CreateWorkflowOptions) => {
  const url = getEnvVar("URL_WRS_WORKFLOW");
  const headers = await getRequestHeaders();
  const body = { name, description, layout };
  const res = await axios.post<CreateWorkflowResult>(url, body, { headers });
  return res.data;
};

type CreateWorkflowStepOptions = {
  tool_version: number;
  workflow: string;
};

type CreateWorkflowStepResult = {
  id: string;
  tool_version: number;
};

const createWorkflowStep = async (body: CreateWorkflowStepOptions) => {
  const url = getEnvVar("URL_WRS_WORKFLOW_STEP");
  const headers = await getRequestHeaders();
  const res = await axios.post<CreateWorkflowStepResult>(url, body, {
    headers,
  });
  return res.data;
};

type CreateWorkflowStepDependencyOptions = {
  parent: string;
  child: string;
  tool_result: number;
  tool_source: number;
};

type CreateWorkflowStepDependencyResult = {
  id: string;
};

const createWorkflowStepDependency = async (
  body: CreateWorkflowStepDependencyOptions,
) => {
  const url = getEnvVar("URL_WRS_WORKFLOW_STEP_DEPENDENCY");
  const headers = await getRequestHeaders();
  const res = await axios.post<CreateWorkflowStepDependencyResult>(url, body, {
    headers,
  });
  return res.data;
};

type DoAllOptions = {
  name: string;
  description: string;
  layout: ReactFlowJsonObject;
  projectId: string;
  steps: {
    toolVersionId: number;
  }[];
  stepDependencies: {
    parentIdx: number;
    childIdx: number;
    toolResultKey: string;
    toolSourceKey: string;
  }[];
};

export const doAll = async ({
  name,
  description,
  layout,
  steps,
  stepDependencies,
}: DoAllOptions) => {
  const toolVersionMap = chain(await getToolVersions())
    .keyBy((toolVersion) => toolVersion.id)
    .value();

  const { id: workflowId } = await createWorkflow({
    name,
    description,
    layout,
  });

  const workflowSteps = await chain(steps)
    .map((step) => {
      return createWorkflowStep({
        tool_version: step.toolVersionId,
        workflow: workflowId,
      });
    })
    .thru((promises) => Promise.all(promises))
    .value();

  await chain(stepDependencies)
    .map((dep) => {
      const parentStep = workflowSteps[dep.parentIdx];
      const childStep = workflowSteps[dep.childIdx];
      const parentToolVersion = toolVersionMap[parentStep.tool_version];
      const childToolVersion = toolVersionMap[childStep.tool_version];
      const toolSource = childToolVersion.tool_sources.find(
        (source) => source.key === dep.toolSourceKey,
      );
      assert(isDefined(toolSource));
      const toolResult = parentToolVersion.tool_results.find(
        (result) => result.key === dep.toolResultKey,
      );
      assert(isDefined(toolResult));
      return createWorkflowStepDependency({
        parent: parentStep.id,
        child: childStep.id,
        tool_result: toolResult.id,
        tool_source: toolSource.id,
      });
    })
    .thru((promises) => Promise.all(promises))
    .value();
};

export const getAllWorkflows = async () => {
  const url = getEnvVar("URL_WRS_WORKFLOW");
  const headers = await getRequestHeaders();
  const res = await axios.get<CreateWorkflowResult[]>(url, { headers });
  return res.data;
};
