import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSearchBar,
  EuiSuperSelect,
  EuiSuperSelectOption,
  Query,
} from "@inscopix/ideas-eui";
import { Dispatch, SetStateAction } from "react";

interface WorkflowSelectionModalFilterProps {
  query: Query | undefined;
  setQuery: Dispatch<SetStateAction<Query | undefined>>;
  sortOptions: EuiSuperSelectOption<string>[];
  sortValue: string;
  setSortValue: Dispatch<SetStateAction<string>>;
}

export const WorkflowSelectionModalFilter = ({
  query,
  setQuery,
  sortOptions,
  sortValue,
  setSortValue,
}: WorkflowSelectionModalFilterProps) => {
  return (
    <EuiFlexGroup>
      <EuiFlexItem grow={true}>
        <EuiSearchBar
          aria-label="Restrict analysis options by name or description"
          onChange={({ query }) => setQuery(query ?? undefined)}
          query={query}
          box={{
            autoFocus: true,
            placeholder: "Filter analyses by name or description...",
            incremental: true,
          }}
        />
      </EuiFlexItem>
      <EuiFlexItem style={{ width: "240px" }} grow={false}>
        <EuiSuperSelect
          id={"sort-analyses"}
          options={sortOptions}
          aria-label="Sort analyses"
          onChange={(value) => {
            setSortValue(value);
          }}
          valueOfSelected={sortValue}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
