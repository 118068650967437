/** @jsxImportSource @emotion/react */
import {
  EuiBadge,
  EuiButton,
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
} from "@inscopix/ideas-eui";
import { MouseEvent } from "react";
import { ToolSpec } from "../ToolParamsGrid/ToolParamsGrid.types";
import { Maybe } from "graphql/_Types";
import { isNonNull } from "utils/isNonNull";
import moment from "moment";
import { css } from "@emotion/react";

const styles = {
  detailList: css`
    min-height: 0;
    overflow-y: scroll;
    flex: 1 1 0;
    padding: 8px;
  `,
  tableForm: css`
    padding: 8px;
    background-color: white;
    border-top: 1px solid #e5e5e5;
  `,
};

interface TempDetailProps {
  selectedToolSpecKey: string | undefined;
  toolSpecs: (Pick<
    ToolSpec,
    "key" | "name" | "help" | "version" | "toolkit" | "maturity"
  > & {
    credits: Maybe<number>;
    toolCreated: string;
    versionCreated: string;
    toolId: number;
  })[];
  isLoading: boolean;
  handleSubmit: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const TempDetail = ({
  selectedToolSpecKey,
  toolSpecs,
  isLoading,
  handleSubmit,
}: TempDetailProps) => {
  const selectedToolSpec = toolSpecs.find(
    (spec) => spec.key === selectedToolSpecKey,
  );

  if (selectedToolSpecKey === undefined) {
    return null;
  }

  if (selectedToolSpec === undefined) {
    return null;
  }

  const latestToolVersion = selectedToolSpec.version;
  const toolkit = selectedToolSpec.toolkit;
  const computeCredits = selectedToolSpec.credits;
  const toolVersionDate = moment(selectedToolSpec.versionCreated).format(
    "YYYY-MM-DD",
  );
  const toolDate = moment(selectedToolSpec.toolCreated).format("YYYY-MM-DD");

  return (
    <EuiFlexGroup direction={"column"}>
      <EuiFlexItem css={styles.detailList}>
        <EuiDescriptionList
          textStyle="reverse"
          compressed
          listItems={[
            {
              title: "Latest Version",
              description: latestToolVersion,
            },
            {
              title: "Date Latest Version Published",
              description: toolVersionDate,
            },
            {
              title: "Date First Published",
              description: toolDate,
            },
            {
              title: "Toolkit",
              description: <EuiBadge color={"hollow"}>{toolkit}</EuiBadge>,
            },
            {
              title: "Compute Credits",
              description: `${
                isNonNull(computeCredits) ? computeCredits : "N/A"
              } compute credits/hour`,
            },
          ]}
        />
      </EuiFlexItem>

      <EuiFlexItem grow={false} css={styles.tableForm}>
        <EuiButton fill fullWidth onClick={handleSubmit} isLoading={isLoading}>
          Add Step
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
