import {
  CellValue,
  IdeasFile,
  RoiFrame,
  RowIdentifier,
} from "@inscopix/ideas-hyperformula";
import { DataTableColumnDefinition } from "../store/DataTableProvider.types";

type GetValidationErrorsOptions = {
  column: { definition: DataTableColumnDefinition; required?: boolean };
  value: CellValue;
};

/**
 * Get an array of errors describing why a cell value is invalid in a specified
 * column.
 * @param options
 * @returns An array of validation errors.
 */
export const getValidationErrors = (options: GetValidationErrorsOptions) => {
  const { column, value } = options;
  const isEmptyValue = value === null || value === "";
  const errors: string[] = [];

  // Validate value is non-empty if column is required
  if (isEmptyValue && column.required) {
    errors.push("Value is required");
  }

  // Validate boolean columns
  if (column.definition.kind === "boolean" && !isEmptyValue) {
    if (typeof value !== "boolean") {
      errors.push('Value must be of type "boolean"');
    }
  }

  // Validate choice columns
  if (column.definition.kind === "choice" && !isEmptyValue) {
    const isValidValue = column.definition.choices.some(
      (choice) => choice.value === value,
    );
    if (!isValidValue) {
      const choices = column.definition.choices
        .map(({ value }) => value)
        .join(", ");
      errors.push(`Value must be one of: ${choices}`);
    }
  }

  // Validate file columns
  if (column.definition.kind === "file" && !isEmptyValue) {
    if (!(value instanceof IdeasFile)) {
      errors.push('Value must be of type "file"');
    }
  }

  // Validate float columns
  if (column.definition.kind === "float" && !isEmptyValue) {
    const { min, max } = column.definition;

    if (typeof value !== "number" || isNaN(parseFloat(value.toString()))) {
      errors.push('Value must be of type "float"');
    }

    if (min !== null && typeof value === "number" && value < min) {
      errors.push(`Value must be greater than or equal to ${min}`);
    }

    if (max !== null && typeof value === "number" && value > max) {
      errors.push(`Value must be less than or equal to ${max}`);
    }
  }

  // Validate identifier columns
  if (column.definition.kind === "identifier" && !isEmptyValue) {
    if (!(value instanceof RowIdentifier)) {
      errors.push('Value must be of type "row identifier"');
    }
  }

  // Validate integer columns
  if (column.definition.kind === "integer" && !isEmptyValue) {
    const { min, max } = column.definition;

    if (typeof value !== "number" || isNaN(parseInt(value.toString()))) {
      errors.push('Value must be of type "integer"');
    }

    if (min !== null && typeof value === "number" && value < min) {
      errors.push(`Value must be greater than or equal to ${min}`);
    }

    if (max !== null && typeof value === "number" && value > max) {
      errors.push(`Value must be less than or equal to ${max}`);
    }
  }

  // Validate ROI frame columns
  if (column.definition.kind === "roi_frame" && !isEmptyValue) {
    if (!(value instanceof RoiFrame)) {
      errors.push('Value must be of type "ROI frame"');
    }
  }

  // Validate text columns
  if (column.definition.kind === "text" && !isEmptyValue) {
    if (typeof value !== "string") {
      errors.push('Value must be of type "text"');
    }
  }

  return errors;
};
