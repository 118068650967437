import { EuiFlexGrid, EuiFlexItem } from "@inscopix/ideas-eui";
import { TaskCountBadge } from "components/AnalysisTableTaskCounts/TaskCountBadge";
import { TaskStatus } from "types/constants";
import { useDataTableContext } from "../store/DataTableProvider";
import { isNonNull } from "utils/isNonNull";
import { groupBy } from "lodash";

interface GdtAnalysisTableTaskCountsProps {
  tableId: string;
}

export const GdtAnalysisTableTaskCounts = ({
  tableId,
}: GdtAnalysisTableTaskCountsProps) => {
  const tasks = useDataTableContext((s) => {
    const table = s.tables.find((table) => table.id === tableId);
    return (table?.rows ?? []).map((row) => row.task).filter(isNonNull);
  });

  const numTasksByStatus = (() => {
    const tasksByStatus = groupBy(tasks, ({ status }) => status);
    const numTasksComplete = tasksByStatus[TaskStatus.COMPLETE]?.length ?? 0;
    const numTasksRunning = tasksByStatus[TaskStatus.RUNNING]?.length ?? 0;
    const numTasksQueued = tasksByStatus[TaskStatus.QUEUED]?.length ?? 0;
    const numTasksFailed = tasksByStatus[TaskStatus.FAILED]?.length ?? 0;
    const numTasksErrored = tasksByStatus[TaskStatus.ERROR]?.length ?? 0;

    return {
      [TaskStatus.COMPLETE]: numTasksComplete,
      [TaskStatus.RUNNING]: numTasksRunning,
      [TaskStatus.QUEUED]: numTasksQueued,
      [TaskStatus.FAILED]: numTasksFailed + numTasksErrored,
    };
  })();

  return (
    <EuiFlexGrid
      columns={numTasksByStatus[TaskStatus.FAILED] > 0 ? 4 : 3}
      gutterSize={"none"}
    >
      <EuiFlexItem>
        <TaskCountBadge
          isLoading={false}
          numTasksByStatus={numTasksByStatus}
          taskStatus={TaskStatus.COMPLETE}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <TaskCountBadge
          isLoading={false}
          numTasksByStatus={numTasksByStatus}
          taskStatus={TaskStatus.RUNNING}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <TaskCountBadge
          isLoading={false}
          numTasksByStatus={numTasksByStatus}
          marginLeft={1}
          taskStatus={TaskStatus.QUEUED}
        />
      </EuiFlexItem>
      {numTasksByStatus[TaskStatus.FAILED] > 0 && (
        <EuiFlexItem>
          <TaskCountBadge
            isLoading={false}
            marginLeft={1}
            numTasksByStatus={numTasksByStatus}
            taskStatus={TaskStatus.FAILED}
          />
        </EuiFlexItem>
      )}
    </EuiFlexGrid>
  );
};
