import {
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiSkeletonText,
  EuiPagination,
  EuiSearchBar,
  EuiSpacer,
  EuiText,
} from "@inscopix/ideas-eui";
import "./ProjectCards.scss";
import { isDefined } from "utils/isDefined";
import { useEuiSearchBar } from "hooks/useEuiSearchBar";
import {
  ProjectCard,
  ProjectCardProps,
} from "components/ProjectCard/ProjectCard";
import { ButtonCreateProjectFilled } from "../ButtonCreateProject/ButtonCreateProjectFilled";
import usePagination from "hooks/usePagination";
import { CallOutError } from "components/CallOutError/CallOutError";
import { Tenant } from "graphql/_Types";

interface ProjectCardsProps {
  isError: boolean;
  isLoading: boolean;
  projects: ProjectCardProps["project"][];
  tenantId?: Tenant["id"];
}

/**
 * Displays a table of projects, allows selection and provides context menu for edit/deletion
 */
export const ProjectCards = ({
  isError,
  isLoading,
  projects,
  tenantId,
}: ProjectCardsProps) => {
  const { query, executeQuery, onChange, userFilter } = useEuiSearchBar();

  const filteredProjects = isDefined(query)
    ? executeQuery(query, projects)
    : projects;

  const { activePage, activePageItems, onPageChange, pageCount } =
    usePagination({
      allItems: filteredProjects,
      itemsPerPage: 5,
    });

  if (isLoading) {
    return <EuiSkeletonText lines={5} />;
  }

  if (isError) {
    return <CallOutError />;
  }

  if (projects.length === 0) {
    return (
      <EuiEmptyPrompt
        title={<h2>Start your first project!</h2>}
        style={{ maxWidth: "100%" }}
        titleSize="xs"
        iconType="layers"
        body={
          <p>
            <EuiText>
              All data in IDEAS is contained within projects. Start your first
              project to begin uploading and analyzing data.
            </EuiText>
          </p>
        }
        actions={[
          <ButtonCreateProjectFilled
            key="create-project-button"
            tenantId={tenantId}
          />,
        ]}
      />
    );
  }

  return (
    <>
      <EuiSearchBar
        query={query}
        onChange={onChange}
        filters={[userFilter]}
        box={{ placeholder: "Find a project...", incremental: true }}
      />
      <EuiSpacer />
      <EuiFlexGroup direction="column">
        {activePageItems.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </EuiFlexGroup>
      <EuiSpacer />
      {pageCount > 1 && (
        <EuiPagination
          activePage={activePage}
          onPageClick={onPageChange}
          pageCount={pageCount}
        />
      )}
    </>
  );
};
