import assert from "assert";

const URL_DJANGO = process.env.REACT_APP_URL_DJANGO;
assert(URL_DJANGO !== undefined, "Env variable missing");

const URL_DOCS = process.env.REACT_APP_URL_DOCS;
assert(URL_DOCS !== undefined, "Env variable missing REACT_APP_URL_DOCS");

const ENV_MAP = {
  ENV: process.env.REACT_APP_ENV,
  REGION: process.env.REACT_APP_REGION,
  EXTERNAL_REGIONS: process.env.REACT_APP_EXTERNAL_REGIONS,
  // AWS
  AWS_AUTH_FLOW_TYPE: process.env.REACT_APP_AWS_AUTH_FLOW_TYPE,
  AWS_AUTH_REGION: process.env.REACT_APP_AWS_AUTH_REGION,
  AWS_AUTH_USER_POOL_ID: process.env.REACT_APP_AWS_AUTH_USER_POOL_ID,
  AWS_AUTH_USER_POOL_WEB_CLIENT_ID:
    process.env.REACT_APP_AWS_AUTH_USER_POOL_WEB_CLIENT_ID,
  AWS_AUTH_OAUTH_DOMAIN: process.env.REACT_APP_AWS_AUTH_OAUTH_DOMAIN,
  AWS_AUTH_COOKIE_DOMAIN: process.env.REACT_APP_AWS_AUTH_COOKIE_DOMAIN,
  AWS_AUTH_OAUTH_SCOPE: process.env.REACT_APP_AWS_AUTH_OAUTH_SCOPE,
  AWS_AUTH_OAUTH_REDIRECT_SIGNIN:
    process.env.REACT_APP_AWS_AUTH_OAUTH_REDIRECT_SIGNIN,
  AWS_AUTH_OAUTH_REDIRECT_SIGNOUT:
    process.env.REACT_APP_AWS_AUTH_OAUTH_REDIRECT_SIGNOUT,
  AWS_AUTH_OAUTH_RESPONSE_TYPE:
    process.env.REACT_APP_AWS_AUTH_OAUTH_RESPONSE_TYPE,
  // Graphql
  URL_GRAPHQL: process.env.REACT_APP_URL_GRAPHQL,
  // Django endpoints
  URL_DJANGO: process.env.REACT_APP_URL_DJANGO,
  URL_DRS_DATASET_CREATE: `${URL_DJANGO}/drs/dataset/create/`,
  URL_DRS_DATASET_RECORDINGS_TABLE_SET_COLUMN_ORDER: `${URL_DJANGO}/drs/dataset_recordings_table/set_column_order/`,
  URL_DRS_DATASET_RECORDINGS_TABLE_SET_IDENTIFIER_COLUMNS: `${URL_DJANGO}/drs/dataset_recordings_table/set_identifier_columns/`,
  URL_DRS_DATASET_RECORDINGS_TABLE_COLUMN_CREATE: `${URL_DJANGO}/drs/dataset_recordings_table_column/create/`,
  URL_DRS_DATASET_RECORDINGS_TABLE_COLUMN_END_ASSIGNMENT: `${URL_DJANGO}/drs/dataset_recordings_table_column/end_assignment/`,
  URL_DRS_DATASET_RECORDINGS_TABLE_COLUMN_PIN: `${URL_DJANGO}/drs/dataset_recordings_table_column/pin/`,
  URL_DRS_DATASET_RECORDINGS_TABLE_COLUMN_UNPIN: `${URL_DJANGO}/drs/dataset_recordings_table_column/unpin/`,
  URL_DRS_DATASET_RECORDINGS_TABLE_COLUMN_RESIZE: `${URL_DJANGO}/drs/dataset_recordings_table_column/resize/`,
  URL_DRS_DATASET_VERSION_CREATE: `${URL_DJANGO}/drs/dataset_version/create/`,
  URL_DRS_FILE_ARCHIVE: `${URL_DJANGO}/drs/file/archive/`,
  URL_DRS_FILE_CREATE: `${URL_DJANGO}/drs/file/`,
  URL_DRS_FILE_DOWNLOAD: `${URL_DJANGO}/drs/file/download/`,
  URL_DRS_FILE_DOWNLOAD_PREVIEW: `${URL_DJANGO}/drs/file/download/preview/`,
  URL_DRS_FILE_UPLOAD_URLS: `${URL_DJANGO}/drs/file/upload_urls/`,
  URL_DRS_FILE_COMPLETE_UPLOAD: `${URL_DJANGO}/drs/file/complete_upload/`,
  URL_DRS_FILE_SCHEDULE_DELETE: `${URL_DJANGO}/drs/file/schedule_delete/`,
  URL_DRS_FILE_UNARCHIVE: `${URL_DJANGO}/drs/file/unarchive/`,
  URL_DRS_FILE_SCHEDULE_DATA_DELETE: `${URL_DJANGO}/drs/file/schedule_data_delete/`,
  URL_METADATA_FILE_METADATA: `${URL_DJANGO.replace(
    "v0",
    "v1",
  )}/metadata/filemetadata/`,
  URL_METADATA_FILE_RECORDING_GROUP_METADATA_VALUE_CREATE_BULK: `${URL_DJANGO}/metadata/file_recording_group_metadata/create_bulk_metadata_value/`,
  URL_DRS_FILE_RECORDING_GROUP_CREATE_BULK: `${URL_DJANGO}/drs/file_recording_group/create/bulk/`,
  URL_DRS_FILE_RECORDING_GROUP_END_ASSIGNMENT: `${URL_DJANGO}/drs/file_recording_group/end_assignment/`,
  URL_DRS_FILE_SERIES_CREATE: `${URL_DJANGO}/drs/file/series/create/`,
  URL_DRS_FILE_SERIES_REVERT_CREATE: `${URL_DJANGO}/drs/file/series/revert_create/`,
  URL_DRS_FILE_UPDATE: `${URL_DJANGO}/drs/file/`,
  URL_DRS_RECORDING_GROUP_FILES_CREATE: `${URL_DJANGO}/drs/recording_group_files/create/`,
  URL_DRS_RECORDING_GROUP_FILES_END_ASSIGNMENT: `${URL_DJANGO}/drs/recording_group_files/end_assignment/`,
  URL_LIBRARY_PROJECT: `${URL_DJANGO}/library/project/`,
  URL_LIBRARY_PROJECT_CLONE: `${URL_DJANGO}/library/project/clone/`,
  URL_PROJECT_SCHEDULE_DELETE: `${URL_DJANGO}/library/project/schedule_delete/`,
  URL_LIBRARY_PROJECT_ACCESS: `${URL_DJANGO}/library/project_access/`,
  URL_LIBRARY_PROJECT_SHARE: `${URL_DJANGO}/library/project_share/`,
  URL_LIBRARY_DATA_TABLE: `${URL_DJANGO}/library/data_table/`,
  URL_LIBRARY_DATA_TABLE_CELL: `${URL_DJANGO}/library/data_table_cell/`,
  URL_LIBRARY_DATA_TABLE_COLUMN: `${URL_DJANGO}/library/data_table_column/`,
  URL_LIBRARY_DATA_TABLE_ROW: `${URL_DJANGO}/library/data_table_row/`,
  URL_LIBRARY_GDT_ANALYSIS_TABLE: `${URL_DJANGO}/library/gdt_analysis_table/`,
  URL_LIBRARY_GDT_ANALYSIS_TABLE_CELL: `${URL_DJANGO}/library/gdt_analysis_table_cell/`,
  URL_LIBRARY_GDT_ANALYSIS_TABLE_COLUMN: `${URL_DJANGO}/library/gdt_analysis_table_column/`,
  URL_LIBRARY_GDT_ANALYSIS_TABLE_ROW: `${URL_DJANGO}/library/gdt_analysis_table_row/`,
  URL_LIBRARY_ANALYSIS_TABLE_GROUP: `${URL_DJANGO}/library/analysis_table_group/`,
  URL_LIBRARY_ANALYSIS_TABLE_ROW: `${URL_DJANGO}/library/analysis_table_row/`,
  URL_NOTIFICATION_NOTIFICATION: `${URL_DJANGO}/notification/notification/`,
  URL_TENANT: `${URL_DJANGO}/tenant/`,
  URL_TENANT_CHECK_QUOTA: `${URL_DJANGO}/tenant/check_quota/`,
  URL_TENANT_CHECK_CONTRACT: `${URL_DJANGO}/tenant/check_active_contract/`,
  URL_TENANT_RESOURCE_SUMMARY: `${URL_DJANGO}/tenant/consumed_resources_summary/`,
  URL_TENANT_MEMBERSHIP: `${URL_DJANGO}/tenant/membership/`,
  URL_TENANT_USER_INVITE: `${URL_DJANGO}/tenant/user-invite/`,
  URL_TES: `${URL_DJANGO}/tes/`,
  URL_TES_CREATE_BULK: `${URL_DJANGO}/tes/task/create_bulk_analysis_table_row/`,
  URL_TES_TASK_CREATE: `${URL_DJANGO}/tes/task/`,
  URL_TES_DOWNLOAD_ERR: `${URL_DJANGO}/tes/task/download_error_log/`,
  URL_TES_DOWNLOAD_OUT: `${URL_DJANGO}/tes/task/download_output_log/`,
  URL_TRS_TOOL_VERSION: `${URL_DJANGO}/trs/tool/version/`,
  URL_SYSTEM_HEALTH: `${URL_DJANGO}/system/health/`,
  URL_TENANT_FIND_USER: `/tenant/users/find_user/`,
  URL_WES_RUN: `${URL_DJANGO}/wes/run/`,
  URL_WRS_WORKFLOW: `${URL_DJANGO}/wrs/workflow/`,
  URL_WRS_WORKFLOW_STEP: `${URL_DJANGO}/wrs/workflow_steps/`,
  URL_WRS_WORKFLOW_STEP_DEPENDENCY: `${URL_DJANGO}/wrs/workflow_step_dependencies/`,
  URL_WRS_WORKFLOW_TABLE: `${URL_DJANGO}/wrs/workflow/create_workflow_table/`,
  // other
  URL_DOCS,
  URL_DOCS_MAPPER: `${URL_DOCS}/mapper-v2.json`,
  // Other libraries
  AG_GRID_LICENSE: process.env.REACT_APP_AG_GRID_LICENSE,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
} as const;

export const AWS_CONFIG = {
  Auth: {
    region: ENV_MAP.AWS_AUTH_REGION,
    userPoolId: ENV_MAP.AWS_AUTH_USER_POOL_ID,
    userPoolWebClientId: ENV_MAP.AWS_AUTH_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: ENV_MAP.AWS_AUTH_FLOW_TYPE,
    cookieStorage: {
      domain: ENV_MAP.AWS_AUTH_COOKIE_DOMAIN,
      path: "/",
      expires: 30,
      sameSite: "strict",
      secure: ENV_MAP.AWS_AUTH_COOKIE_DOMAIN !== "localhost", //https://github.com/aws-amplify/amplify-js/issues/1736#issuecomment-452398549
    },
    oauth: {
      domain: ENV_MAP.AWS_AUTH_OAUTH_DOMAIN,
      scope: [ENV_MAP.AWS_AUTH_OAUTH_SCOPE],
      redirectSignIn: ENV_MAP.AWS_AUTH_OAUTH_REDIRECT_SIGNIN,
      redirectSignOut: ENV_MAP.AWS_AUTH_OAUTH_REDIRECT_SIGNOUT,
      responseType: ENV_MAP.AWS_AUTH_OAUTH_RESPONSE_TYPE,
    },
  },
} as const;

/**
 * Verify that all environment variables are set
 */
Object.entries(ENV_MAP).forEach(([key, val]) => {
  if (val === undefined) {
    throw new Error(`process.env.REACT_APP_${key} is not defined!`);
  }
});

/**
 * Gets the value of an environment variable
 * @param varName
 * @returns The value of an environment variable
 */
export function getEnvVar(varName: keyof typeof ENV_MAP) {
  const envVar = ENV_MAP[varName];

  if (envVar === undefined) {
    throw new Error(`${varName} is not defined!`);
  }

  return envVar;
}

/**
 * Gets the value of an AWS config variable
 * @param varName
 * @returns The value of an AWS config variable
 */
export function getAwsConfigVar(varName: keyof typeof AWS_CONFIG) {
  const configVar = AWS_CONFIG[varName];

  if (configVar === undefined) {
    throw new Error(`${varName} is not defined!`);
  }

  return configVar;
}
