/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useRef } from "react";
import { useDataTableContext } from "../store/DataTableProvider";

const styles = {
  root: css`
    background: none;
    border: none;
    cursor: default;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  `,
};

interface ColumnHeaderBaseNameProps {
  tableId: string;
  columnId: string;
  isRenaming: boolean;
  setIsRenaming: (isRenaming: boolean) => void;
  name: string;
}

export const ColumnHeaderBaseName = ({
  tableId,
  columnId,
  isRenaming,
  setIsRenaming,
  name,
}: ColumnHeaderBaseNameProps) => {
  const renameColumn = useDataTableContext((s) => s.renameColumn);
  const inputRef = useRef<HTMLInputElement>(null);

  if (!isRenaming) {
    return <span css={styles.root}>{name}</span>;
  }

  return (
    <input
      css={styles.root}
      ref={inputRef}
      autoFocus
      type="text"
      onChange={(e) => {
        const inputElement = inputRef.current;
        if (inputElement !== null) {
          inputElement.value = e.target.value;
        }
      }}
      onBlur={() => setIsRenaming(false)}
      onKeyDownCapture={(e) => {
        e.stopPropagation();
        const inputElement = inputRef.current;

        if (inputElement !== null) {
          if (e.key === "Enter") {
            if (inputElement.value !== "") {
              void renameColumn({
                tableId,
                columnId,
                newName: inputElement.value,
              });
            }
            inputElement.blur();
          }

          if (e.key === "Escape") {
            inputElement.blur();
          }
        }
      }}
      defaultValue={name}
    />
  );
};
