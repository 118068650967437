import {
  EuiBadge,
  EuiFieldText,
  EuiFormRow,
  EuiText,
} from "@inscopix/ideas-eui";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import { useState } from "react";

// Field validation constants
const TABLE_KEY_ILLEGAL_CHAR_REGEX = /[^A-Z0-9_]/g;
const TABLE_KEY_MAX_LENGTH = 10;

interface FieldTableKeyProps {
  autoFocus?: boolean;
  value: string;
  onChange: (field: { value: string; isValid: boolean }) => void;
}

export const FieldTableKey = ({
  autoFocus,
  value,
  onChange,
}: FieldTableKeyProps) => {
  const tables = useDataTableContext((s) => s.tables);
  const [initialValue] = useState(value);

  const isKeyUnique = (key: string) => {
    return initialValue === key || !tables.some((table) => table.key === key);
  };

  return (
    <EuiFormRow
      label="Key"
      helpText={
        <EuiText size={"s"}>
          <p>
            This key combines with a row&apos;s sequential number to create a
            unique ID for each row in the table.
          </p>
          <ul>
            <li>Maximum 10 characters.</li>
            <li>Uppercase letters, numbers, and underscores.</li>
            <li>Keys must be unique within this project.</li>
            <li>Can be edited after initial save.</li>
          </ul>
        </EuiText>
      }
      isInvalid={!isKeyUnique(value)}
      error={
        !isKeyUnique(value)
          ? `Your key must be unique to this project - ${value} is already in use.`
          : undefined
      }
    >
      <EuiFieldText
        placeholder="REC"
        name="prefix"
        append={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 5,
              paddingRight: 10,
            }}
          >
            <EuiText size="s">Example: </EuiText>
            <EuiBadge
              style={{
                fontFamily: "monospace",
                border: "1px solid grey",
              }}
            >
              {value === "" ? "REC" : value}-1
            </EuiBadge>
          </div>
        }
        value={value}
        onChange={(e) => {
          const newKey = e.target.value
            .toUpperCase()
            .replace(TABLE_KEY_ILLEGAL_CHAR_REGEX, "")
            .substring(0, TABLE_KEY_MAX_LENGTH);

          onChange({
            value: newKey,
            isValid: isKeyUnique(newKey),
          });
        }}
        maxLength={TABLE_KEY_MAX_LENGTH}
        isInvalid={false}
        required
        autoFocus={autoFocus}
      />
    </EuiFormRow>
  );
};
