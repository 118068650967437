import {
  EuiContextMenu,
  EuiContextMenuPanelDescriptor,
  EuiModal,
  EuiToken,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { DataTableColumnDefinition } from "pages/gdt/store/DataTableProvider.types";
import { PanelCreateColumnBoolean } from "./panels/PanelCreateColumnBoolean";
import { PanelCreateColumnFile } from "./panels/PanelCreateColumnFile";
import { PanelCreateColumnIdentifier } from "./panels/PanelCreateColumnIdentifier";
import { PanelCreateColumnJoin } from "./panels/PanelCreateColumnJoin";
import { PanelCreateColumnText } from "./panels/PanelCreateColumnText";
import { PanelCreateColumnFloat } from "./panels/PanelCreateColumnFloat";
import { PanelCreateColumnInteger } from "./panels/PanelCreateColumnInteger";
import { PanelCreateColumnFileMetadatum } from "./panels/PanelCreateColumnFileMetadatum";

export const COLUMN_KIND_MAP = {
  boolean: {
    name: "Boolean",
    icon: "tokenBoolean",
    description: "",
  },
  choice: {
    name: "Choice",
    icon: "tokenFlattened",
    description: "",
  },
  file: {
    name: "File",
    icon: "tokenFile",
    description: "",
  },
  metadatum: {
    name: "File Metadatum",
    icon: "tokenObject",
    description: "",
  },
  float: {
    name: "Float",
    icon: "tokenNumber",
    description: "",
  },
  identifier: {
    name: "Identifier",
    icon: "tokenAnnotation",
    description: "",
  },
  integer: {
    name: "Integer",
    icon: "tokenNumber",
    description: "",
  },
  join: {
    name: "Join",
    icon: "tokenJoin",
    description: "",
  },
  roi_frame: {
    name: "ROI Frame",
    icon: "tokenShape",
    description: "",
  },
  text: {
    name: "Text",
    icon: "tokenText",
    description: "",
  },
} as const satisfies Record<
  DataTableColumnDefinition["kind"],
  { name: string; icon: string; description: string }
>;

const PANEL_WIDTH = 400;

interface ModalCreateColumnProps {
  onClose: () => void;
}

/** Component that renders a modal for creating a new column */
export const ModalCreateColumn = ({ onClose }: ModalCreateColumnProps) => {
  const panels: EuiContextMenuPanelDescriptor[] = [
    {
      id: htmlIdGenerator()(),
      title: "Select column type",
      width: PANEL_WIDTH,
      initialFocusedItemIndex: 0,
      items: Object.entries(COLUMN_KIND_MAP)
        // TODO: Add interfaces for these column types when we have time
        .filter(([id]) => id !== "choice" && id !== "roi_frame")
        .map(([id, kind]) => ({
          name: kind.name,
          icon: <EuiToken iconType={kind.icon} />,
          toolTipContent: kind.description,
          toolTipPosition: "right",
          panel: id,
        })),
    },
    {
      id: "boolean",
      width: PANEL_WIDTH,
      title: "Insert boolean column",
      content: <PanelCreateColumnBoolean onClose={onClose} />,
    },
    {
      id: "file",
      width: PANEL_WIDTH,
      title: "Insert file column",
      content: <PanelCreateColumnFile onClose={onClose} />,
    },
    {
      id: "metadatum",
      width: PANEL_WIDTH,
      title: "Insert file metadatum column",
      content: <PanelCreateColumnFileMetadatum onClose={onClose} />,
    },
    {
      id: "float",
      width: PANEL_WIDTH,
      title: "Insert float column",
      content: <PanelCreateColumnFloat onClose={onClose} />,
    },
    {
      id: "identifier",
      width: PANEL_WIDTH,
      title: "Insert identifier column",
      content: <PanelCreateColumnIdentifier onClose={onClose} />,
    },
    {
      id: "integer",
      width: PANEL_WIDTH,
      title: "Insert integer column",
      content: <PanelCreateColumnInteger onClose={onClose} />,
    },
    {
      id: "join",
      width: PANEL_WIDTH,
      title: "Insert join column",
      content: <PanelCreateColumnJoin onClose={onClose} />,
    },
    {
      id: "text",
      width: PANEL_WIDTH,
      title: "Insert text column",
      content: <PanelCreateColumnText onClose={onClose} />,
    },
  ];

  return (
    <EuiModal onClose={onClose}>
      <EuiContextMenu initialPanelId={panels[0].id} panels={panels} />
    </EuiModal>
  );
};
