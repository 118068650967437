import { EuiSwitch } from "@inscopix/ideas-eui";
import { useDataTableContext } from "../store/DataTableProvider";
import { Tooltip } from "components/Tooltip/Tooltip";

export const DataTableAdvancedModeSwitch = () => {
  const isAdvancedMode = useDataTableContext((s) => s.isAdvancedMode);
  const setIsAdvancedMode = useDataTableContext((s) => s.setIsAdvancedMode);

  return (
    <Tooltip content="Edit cell values using formulas" place="bottom">
      <EuiSwitch
        label="Enable advanced mode"
        checked={isAdvancedMode}
        onChange={() => {
          if (isAdvancedMode) {
            setIsAdvancedMode(false);
          } else {
            setIsAdvancedMode(true);
          }
        }}
      />
    </Tooltip>
  );
};
