import {
  EuiButton,
  EuiButtonEmpty,
  EuiForm,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@inscopix/ideas-eui";
import { useState } from "react";
import { useDataTableContext } from "../store/DataTableProvider";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { FieldTableName } from "./fields/FieldTableName";
import { FieldTableKey } from "./fields/FieldTableKey";
import { DataTableStoreState } from "../store/DataTableProvider.types";

type Fields = {
  name: {
    value: string;
    isValid: boolean;
  };
  key: {
    value: string;
    isValid: boolean;
  };
};

interface ModalEditAnalysisTableProps {
  table: DataTableStoreState["tables"][number];
  onClose: () => void;
}

/** Component that renders a modal for updating an analysis table */
export const ModalEditAnalysisTable = ({
  table,
  onClose,
}: ModalEditAnalysisTableProps) => {
  const updateAnalysisTable = useDataTableContext((s) => s.updateAnalysisTable);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState<Fields>({
    name: {
      value: table.name,
      isValid: true,
    },
    key: {
      value: table.key,
      isValid: true,
    },
  });

  const handleFieldChange = <T extends keyof Fields>(
    fieldName: T,
    field: Fields[T],
  ) => {
    setFields((prevFields) => ({
      ...prevFields,
      [fieldName]: field,
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const { error } = await updateAnalysisTable({
      tableId: table.id,
      name: fields.name.value,
      key: fields.key.value,
    });
    setIsLoading(false);

    if (error === undefined) {
      addUtilityToastSuccess("Analysis table updated");
    } else {
      addUtilityToastFailure("Failed to update analysis table");
    }

    onClose();
  };

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Edit Analysis Table</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiForm>
          <FieldTableName
            autoFocus
            value={fields.name.value}
            onChange={(field) => handleFieldChange("name", field)}
          />
          <FieldTableKey
            value={fields.key.value}
            onChange={(field) => handleFieldChange("key", field)}
          />
        </EuiForm>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiButton
          isLoading={isLoading}
          fill
          onClick={() => void handleSubmit()}
          disabled={Object.values(fields).some((field) => !field.isValid)}
        >
          Update
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
