/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { CellValue, RowIdentifier } from "@inscopix/ideas-hyperformula";
import {
  EuiListGroup,
  EuiListGroupItem,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { CellValueRendererBase } from "../cell-value-renderers/CellValueRendererBase";
import { useDataTableContext } from "../store/DataTableProvider";

const styles = {
  popover: css`
    & {
      height: 100%;
      width: 100%;
      .PopoverAnchor {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      }
    }
  `,
  panel: css`
    max-height: max(200px, 50vh);
    overflow-y: scroll;
  `,
};

interface CellEditorIdentifierProps {
  tableId: string;
  columnId: string;
  rowId: string;
  filterTableId: string;
  initialValue: CellValue;
  onChange: (newFormula: string | null) => void;
  stopEditing: () => void;
}

/** Cell editor for cells in identifier columns */
export const CellEditorIdentifier = ({
  tableId,
  columnId,
  rowId,
  filterTableId,
  initialValue,
  onChange,
  stopEditing,
}: CellEditorIdentifierProps) => {
  const table = useDataTableContext((s) => {
    return s.tables.find((table) => table.id === filterTableId);
  });
  const [newValue, setNewValue] = useState(initialValue);

  const items = table?.rows.map((row) => {
    const isSelected =
      newValue instanceof RowIdentifier &&
      newValue.tableKey === table.key &&
      newValue.rowIndex === row.index;
    const value = new RowIdentifier(table.key, row.index);

    return (
      <EuiListGroupItem
        id={row.id}
        key={row.id}
        iconType={isSelected ? "check" : "empty"}
        label={
          <CellValueRendererBase
            tableId={tableId}
            columnId={columnId}
            rowId={rowId}
            value={value}
          />
        }
        onClick={() => {
          if (isSelected) {
            onChange("");
            setNewValue(null);
          } else {
            onChange(`=ROW_IDENTIFIER("${table.key}", ${row.index})`);
            setNewValue(value);
          }
        }}
      />
    );
  });

  return (
    <EuiPopover
      anchorPosition="downCenter"
      button={
        <CellValueRendererBase
          tableId={tableId}
          columnId={columnId}
          rowId={rowId}
          value={newValue}
        />
      }
      className="Popover"
      anchorClassName="PopoverAnchor"
      isOpen
      panelPaddingSize="none"
      css={styles.popover}
      closePopover={stopEditing}
      display="block"
      repositionOnScroll={true}
    >
      <EuiListGroup css={styles.panel} maxWidth="100%">
        {items}
      </EuiListGroup>
    </EuiPopover>
  );
};
