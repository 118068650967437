/** @jsxImportSource @emotion/react */
import {
  EuiAccordion,
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHorizontalRule,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTextArea,
} from "@inscopix/ideas-eui";
import { useCallback, useState } from "react";
import {
  addEdge,
  Edge,
  MarkerType,
  MiniMap,
  Node,
  ReactFlow,
  useEdgesState,
  useNodesState,
  Viewport,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { Temp } from "./Temp";
import { uuid } from "utils/uuid";
import { doAll } from "./ModalCreateWorkflow.helpers";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import { StepNode } from "./nodes/StepNode";
import { SourceResultNode } from "./nodes/StepResultNode";
import { ToolSpecAndToolVersionId } from "providers/ToolSpecProvider/ToolSpecProvider";

const nodeTypes = {
  step: StepNode,
  sourceResult: SourceResultNode,
};

interface ModalCreateWorkflowProps {
  initialName?: string;
  initialDescription?: string;
  initialNodes?: Node[];
  initialEdges?: Edge[];
  initialViewport?: Viewport;
  onClose: () => void;
}

export const ModalCreateWorkflow = ({
  initialName,
  initialDescription,
  initialNodes,
  initialEdges,
  initialViewport,
  onClose,
}: ModalCreateWorkflowProps) => {
  const projectId = useDataTableContext((s) => s.projectId);
  const [name, setName] = useState(initialName ?? "");
  const [description, setDescription] = useState(initialDescription ?? "");
  const [isStepModalOpen, setIsStepModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes ?? []);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges ?? []);
  const [viewport, setViewport] = useState(initialViewport);

  const onConnect = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges],
  );

  const removeStep = useCallback(
    (nodeId: string) => {
      setNodes((nodes) =>
        nodes.filter((node) => node.id !== nodeId && node.parentId !== nodeId),
      );
    },
    [setNodes],
  );

  const addStep = useCallback(
    (toolSpec: ToolSpecAndToolVersionId) => {
      const groupId = uuid();

      const sources = toolSpec.spec.params.filter(
        (param) => param.type.param_type === "ToolPathParam",
      );

      const results = toolSpec.spec.results[0].files;

      setNodes((nodes) => {
        const stepPosition = {
          x: ((sources.length + results.length) * 416 + 20) / -2,
          y: 256,
        };

        return [
          ...nodes,
          {
            id: groupId,
            type: "step",
            data: {
              title: toolSpec.spec.name,
              description: toolSpec.spec.help,
              toolVersion: toolSpec.spec.version,
              toolVersionId: toolSpec.toolVersionId,
              numChildren: sources.length + results.length,
            },
            position: stepPosition,
            zIndex: 1,
          },
          ...sources.map((param, idx) => ({
            id: uuid(),
            type: "sourceResult",
            position: {
              x: 18 + idx * 416,
              y: 68 + 18,
            },
            data: {
              title: param.name,
              description: param.help,
              type: "source" as const,
              toolSourceKey: param.key,
              isRequired: param.required,
            },
            parentId: groupId,
            extent: "parent" as const,
            zIndex: 1,
            draggable: false,
          })),
          ...results.map((result, idx) => ({
            id: uuid(),
            type: "sourceResult",
            position: {
              x: 18 + (idx + sources.length) * 416,
              y: 68 + 18,
            },
            data: {
              title: result.result_name,
              description: result.help,
              type: "result" as const,
              toolResultKey: result.result_key,
              isRequired: result.required,
            },
            parentId: groupId,
            extent: "parent" as const,
            zIndex: 1,
            draggable: false,
          })),
        ];
      });
    },
    [setNodes],
  );

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    const steps = nodes
      .filter((node) => node.type === "step")
      .map((node) => ({
        id: node.id,
        toolVersionId: node.data["toolVersionId"] as number,
      }));

    assert(isDefined(viewport));

    try {
      await doAll({
        name,
        description,
        layout: {
          edges,
          nodes,
          viewport: viewport,
        },
        projectId,
        steps,
        stepDependencies: edges.map((edge) => {
          const parentStepId = nodes.find((node) => node.id === edge.source)
            ?.parentId;
          assert(isDefined(parentStepId), "parentStepId is not defined");
          const parentIdx = steps.findIndex((step) => step.id === parentStepId);

          const childStepId = nodes.find((node) => node.id === edge.target)
            ?.parentId;
          assert(isDefined(childStepId), "childStepId is not defined");
          const childIdx = steps.findIndex((step) => step.id === childStepId);

          const toolSourceKey = nodes.find((node) => node.id === edge.target)
            ?.data["toolSourceKey"] as string | undefined;
          assert(isDefined(toolSourceKey), "toolSourceKey is not defined");

          const toolResultKey = nodes.find((node) => node.id === edge.source)
            ?.data["toolResultKey"] as string | undefined;
          assert(isDefined(toolResultKey), "toolResultKey is not defined");

          return {
            parentIdx,
            childIdx,
            toolSourceKey,
            toolResultKey,
          };
        }),
      });
      addUtilityToastSuccess("Workflow created");
      onClose();
    } catch (error) {
      addUtilityToastFailure("Failed to create workflow");
    }

    setIsLoading(false);
  }, [description, edges, name, nodes, onClose, projectId, viewport]);

  return (
    <EuiModal
      onClose={onClose}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "calc(100vw - 20px)",
        height: "calc(100vh - 20px)",
        maxWidth: "calc(100vw - 20px)",
        maxHeight: "calc(100vh - 20px)",
        margin: 10,
      }}
    >
      {isStepModalOpen && (
        <Temp
          isLoading={false}
          onClose={() => setIsStepModalOpen(false)}
          onSubmit={(toolSpec) => {
            setIsStepModalOpen(false);
            addStep(toolSpec);
          }}
        />
      )}

      <EuiModalHeader>
        <EuiModalHeaderTitle>Create Workflow</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiFlexGroup style={{ height: "100%" }}>
          <EuiFlexItem
            grow={false}
            style={{ height: "100%", width: 420, overflow: "hidden" }}
          >
            <EuiAccordion
              id="info"
              buttonContent={
                <EuiText size="xs">
                  <h3>Info</h3>
                </EuiText>
              }
              initialIsOpen
            >
              <EuiSpacer size="s" />
              <EuiFormRow label="Name">
                <EuiFieldText
                  placeholder="Untitled workflow"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  autoFocus
                />
              </EuiFormRow>
              <EuiFormRow label="Description">
                <EuiTextArea
                  placeholder="Describe your workflow here"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </EuiFormRow>
            </EuiAccordion>

            <EuiHorizontalRule />

            <EuiAccordion
              id="steps"
              buttonContent={
                <EuiText size="xs">
                  <h3>Steps</h3>
                </EuiText>
              }
              initialIsOpen
            >
              <EuiSpacer size="s" />

              {nodes.filter((node) => node.type === "step").length === 0 && (
                <EuiText color="subdued">
                  <p>
                    Your workflow is empty. Click the button below to add your
                    first step.
                  </p>
                </EuiText>
              )}

              <EuiFlexGroup direction="column" gutterSize="s">
                {nodes
                  .filter((node) => node.type === "step")
                  .map((node) => (
                    <EuiFlexItem key={node.id}>
                      <EuiFieldText
                        value={node.data.title as string}
                        onChange={() => undefined}
                        append={[
                          <EuiButtonIcon
                            aria-label="Remove step"
                            key="remove"
                            color="text"
                            iconType="trash"
                            onClick={() => removeStep(node.id)}
                          />,
                        ]}
                      />
                    </EuiFlexItem>
                  ))}

                <EuiSpacer size="xs" />

                <EuiFlexItem>
                  <EuiFlexGroup
                    alignItems="center"
                    justifyContent="flexStart"
                    wrap
                    gutterSize="s"
                    responsive={false}
                  >
                    <EuiFlexItem grow={false}>
                      <strong style={{ fontSize: 12 }}>Add</strong>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton
                        color="text"
                        size="s"
                        minWidth="auto"
                        onClick={() => setIsStepModalOpen(true)}
                      >
                        Step
                      </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton color="text" size="s" minWidth="auto" disabled>
                        Operator
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiAccordion>

            <EuiHorizontalRule />

            <EuiFlexGroup gutterSize="s" justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <EuiButton
                  disabled={name === ""}
                  fill
                  onClick={() => void handleSubmit()}
                  isLoading={isLoading}
                >
                  Create
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>

          <EuiFlexItem style={{ height: "100%", overflow: "hidden" }}>
            <EuiPanel hasBorder hasShadow={false} style={{ height: "100%" }}>
              <ReactFlow
                elevateNodesOnSelect={false}
                viewport={viewport}
                onViewportChange={setViewport}
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                defaultEdgeOptions={{
                  zIndex: 999,
                  markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 20,
                    height: 20,
                  },
                }}
                fitView
              >
                <MiniMap nodeStrokeWidth={3} />
              </ReactFlow>
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>
    </EuiModal>
  );
};
