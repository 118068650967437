import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldNumber,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@inscopix/ideas-eui";
import { useState } from "react";
import { useDataTableContext } from "../store/DataTableProvider";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { isDefined } from "utils/isDefined";
import assert from "assert";

interface ModalCreateRowsProps {
  onClose: () => void;
}

export const ModalCreateRows = ({ onClose }: ModalCreateRowsProps) => {
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const createRows = useDataTableContext((s) => s.createRows);
  const [numRows, setNumRows] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    assert(isDefined(selectedTableId));
    const { error } = await createRows({
      tableId: selectedTableId,
      numRows,
    });
    setIsLoading(false);

    if (error === undefined) {
      addUtilityToastSuccess("Rows inserted");
    } else {
      addUtilityToastFailure("Failed to insert rows");
    }

    onClose();
  };

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle component="h3">Insert Row(s)</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm>
          <EuiFormRow label="Number of rows">
            <EuiFieldNumber
              aria-label="Number of rows"
              value={numRows}
              min={1}
              max={100}
              onChange={(e) => setNumRows(Number(e.currentTarget.value))}
              autoFocus
            />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiButton
          isLoading={isLoading}
          fill
          onClick={() => void handleSubmit()}
          disabled={numRows < 1 || numRows > 100}
        >
          Insert
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
