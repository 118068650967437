/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { CellValue } from "@inscopix/ideas-hyperformula";
import { memo } from "react";
import { CellValueRendererBase } from "../cell-value-renderers/CellValueRendererBase";
import { useDataTableContext } from "../store/DataTableProvider";
import { isNullish } from "utils/isNullish";
import { CellRendererFile } from "./CellRendererFile";
import { Tooltip } from "components/Tooltip/Tooltip";
import { getValidationErrors } from "./CellRendererBase.helpers";

const styles = {
  root: css`
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0px 11px;
    background-color: inherit;
  `,
  error: css`
    background-color: #f8e9e9;
  `,
};

interface CellRendererBaseProps {
  tableId: string;
  tableKind: "data" | "analysis";
  columnId: string;
  rowId: string;
  eGridCell: HTMLElement;
  value: CellValue | undefined;
}

/**
 * Cell renderer that renders any cell value
 */
export const CellRendererBase = ({
  tableId,
  tableKind,
  columnId,
  rowId,
  eGridCell,
  value,
}: CellRendererBaseProps) => {
  const column = useDataTableContext((s) => {
    const table = s.tables.find((table) => table.id === s.selectedTableId);
    return table?.columns.find((column) => column.id === columnId);
  });

  if (value === undefined || column === undefined) {
    return null;
  }

  const validationErrors = getValidationErrors({ column, value });

  const tooltipContent =
    validationErrors.length > 1 ? (
      <ul>
        {validationErrors.map((error) => (
          <li key={error}>• {error}</li>
        ))}
      </ul>
    ) : (
      validationErrors[0]
    );

  if (
    (isNullish(value) || value === "") &&
    column?.definition.kind === "file" &&
    tableKind === "data"
  ) {
    return (
      <Tooltip content={tooltipContent}>
        <span
          css={[
            styles.root,
            ...(validationErrors.length > 0 ? [styles.error] : []),
          ]}
        >
          <CellRendererFile
            tableId={tableId}
            columnId={columnId}
            rowId={rowId}
            eGridCell={eGridCell}
          />
        </span>
      </Tooltip>
    );
  }

  return (
    <Tooltip content={tooltipContent}>
      <span
        css={[
          styles.root,
          ...(validationErrors.length > 0 ? [styles.error] : []),
        ]}
      >
        <CellValueRendererBase
          tableId={tableId}
          columnId={columnId}
          rowId={rowId}
          value={value}
        />
      </span>
    </Tooltip>
  );
};

export const CellRendererBaseMemo = memo(CellRendererBase);
