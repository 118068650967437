import { EuiFieldText, EuiFormRow } from "@inscopix/ideas-eui";

interface FieldColumnNameProps {
  autoFocus?: boolean;
  value: string;
  onChange: (field: { value: string; isValid: boolean }) => void;
}

export const FieldColumnName = ({
  autoFocus,
  value,
  onChange,
}: FieldColumnNameProps) => {
  return (
    <EuiFormRow label="Name">
      <EuiFieldText
        aria-label="Column name"
        placeholder="Untitled column"
        value={value}
        onChange={(e) => {
          const newName = e.target.value;
          onChange({
            value: newName,
            isValid: newName !== "",
          });
        }}
        icon="copyClipboard"
        maxLength={255}
        isInvalid={false}
        required
        autoFocus={autoFocus}
      />
    </EuiFormRow>
  );
};
