import {
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalProps,
  EuiText,
  EuiTitle,
} from "@inscopix/ideas-eui";

export interface ModalToolRoiFrameParamWrapperProps extends EuiModalProps {
  columnName: string;
  children: React.ReactNode;
  readOnly?: boolean;
  fullScreen?: boolean;
}

export const ModalToolRoiFrameParamWrapper = ({
  columnName,
  children,
  readOnly,
  fullScreen = false,
  ...props
}: ModalToolRoiFrameParamWrapperProps) => {
  return (
    <EuiModal
      {...props}
      style={
        fullScreen
          ? {
              position: "absolute",
              top: 0,
              left: 0,
              width: "calc(100vw - 20px)",
              height: "calc(100vh - 20px)",
              maxWidth: "calc(100vw - 20px)",
              maxHeight: "calc(100vh - 20px)",
              margin: 10,
            }
          : undefined
      }
    >
      <EuiModalHeader>
        <EuiTitle>
          <EuiText>
            {columnName} {readOnly && " (Read Only)"}
          </EuiText>
        </EuiTitle>
      </EuiModalHeader>
      <EuiModalBody>{children}</EuiModalBody>
    </EuiModal>
  );
};
