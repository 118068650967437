import { EuiBadge } from "@inscopix/ideas-eui";
import { Tooltip } from "components/Tooltip/Tooltip";
import { DetailedCellError } from "@inscopix/ideas-hyperformula";

interface CellValueRendererErrorProps {
  error: DetailedCellError;
}

/** Component that renders a `DetailedCellError` cell value` */
export const CellValueRendererError = ({
  error,
}: CellValueRendererErrorProps) => {
  const message = (() => {
    if (error.type === "REF") {
      return "Reference to a cell that does not exist";
    } else {
      return error.message;
    }
  })();

  return (
    <Tooltip content={message}>
      <EuiBadge color="warning" title="">
        ERROR
      </EuiBadge>
    </Tooltip>
  );
};
