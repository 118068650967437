import { Jsonifiable, JsonValue, PartialDeep } from "type-fest";
import { Ref } from "@graphql-tools/mock";
import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from "graphql";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) =>
  | Promise<PartialDeep<TResult, { recurseIntoArrays: true }>>
  | PartialDeep<TResult, { recurseIntoArrays: true }>
  | Ref;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: { input: string; output: string };
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: { input: string; output: string };
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: { input: number; output: number };
  /** The day, does not include a time. */
  Date: { input: string; output: string };
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: { input: string; output: string };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: Jsonifiable; output: JsonValue };
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: { input: string; output: string };
};

export type AnalysisTable = {
  __typename: "AnalysisTable";
  /** Reads a single `AnalysisTableGroup` that is related to this `AnalysisTable`. */
  analysisTableGroupByGroupId: Maybe<AnalysisTableGroup>;
  /** Reads and enables pagination through a set of `AnalysisTableRow`. */
  analysisTableRowsByTableId: AnalysisTableRowsConnection;
  /** Reads a single `ApplicationUser` that is related to this `AnalysisTable`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  dateCreated: Scalars["Datetime"]["output"];
  groupId: Scalars["BigInt"]["output"];
  id: Scalars["BigInt"]["output"];
  identifiers: Maybe<Scalars["JSON"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Project` that is related to this `AnalysisTable`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  /** Reads a single `Tenant` that is related to this `AnalysisTable`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  /** Reads a single `ToolVersion` that is related to this `AnalysisTable`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
  toolVersionId: Scalars["Int"]["output"];
  userId: Scalars["UUID"]["output"];
};

export type AnalysisTableAnalysisTableRowsByTableIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableRowCondition>;
  filter?: InputMaybe<AnalysisTableRowFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTableRowsOrderBy>>;
};

/** The fields on `analysisTable` to look up the row to connect. */
export type AnalysisTableAnalysisTablePkeyConnect = {
  id: Scalars["BigInt"]["input"];
};

/** The fields on `analysisTable` to look up the row to delete. */
export type AnalysisTableAnalysisTablePkeyDelete = {
  id: Scalars["BigInt"]["input"];
};

/**
 * A condition to be used against `AnalysisTable` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AnalysisTableCondition = {
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `identifiers` field. */
  identifiers?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `toolVersionId` field. */
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `AnalysisTable` object types. All fields are combined with a logical ‘and.’ */
export type AnalysisTableFilter = {
  /** Filter by the object’s `analysisTableGroupByGroupId` relation. */
  analysisTableGroupByGroupId?: InputMaybe<AnalysisTableGroupFilter>;
  /** Filter by the object’s `analysisTableRowsByTableId` relation. */
  analysisTableRowsByTableId?: InputMaybe<AnalysisTableToManyAnalysisTableRowFilter>;
  /** Some related `analysisTableRowsByTableId` exist. */
  analysisTableRowsByTableIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AnalysisTableFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `identifiers` field. */
  identifiers?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AnalysisTableFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AnalysisTableFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `toolVersionByToolVersionId` relation. */
  toolVersionByToolVersionId?: InputMaybe<ToolVersionFilter>;
  /** Filter by the object’s `toolVersionId` field. */
  toolVersionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

export type AnalysisTableGroup = {
  __typename: "AnalysisTableGroup";
  /** Reads and enables pagination through a set of `AnalysisTable`. */
  analysisTablesByGroupId: AnalysisTablesConnection;
  /** Reads a single `ApplicationUser` that is related to this `AnalysisTableGroup`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  dateCreated: Scalars["Datetime"]["output"];
  description: Scalars["String"]["output"];
  hidden: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["BigInt"]["output"];
  name: Scalars["String"]["output"];
  /** Reads a single `Project` that is related to this `AnalysisTableGroup`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  /** Reads a single `Tenant` that is related to this `AnalysisTableGroup`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  /** Reads a single `Tool` that is related to this `AnalysisTableGroup`. */
  toolByToolId: Maybe<Tool>;
  toolId: Scalars["Int"]["output"];
  userId: Scalars["UUID"]["output"];
};

export type AnalysisTableGroupAnalysisTablesByGroupIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableCondition>;
  filter?: InputMaybe<AnalysisTableFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTablesOrderBy>>;
};

/** The fields on `analysisTableGroup` to look up the row to connect. */
export type AnalysisTableGroupAnalysisTableGroupPkeyConnect = {
  id: Scalars["BigInt"]["input"];
};

/** The fields on `analysisTableGroup` to look up the row to delete. */
export type AnalysisTableGroupAnalysisTableGroupPkeyDelete = {
  id: Scalars["BigInt"]["input"];
};

/**
 * A condition to be used against `AnalysisTableGroup` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AnalysisTableGroupCondition = {
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `hidden` field. */
  hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `toolId` field. */
  toolId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `AnalysisTableGroup` object types. All fields are combined with a logical ‘and.’ */
export type AnalysisTableGroupFilter = {
  /** Filter by the object’s `analysisTablesByGroupId` relation. */
  analysisTablesByGroupId?: InputMaybe<AnalysisTableGroupToManyAnalysisTableFilter>;
  /** Some related `analysisTablesByGroupId` exist. */
  analysisTablesByGroupIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AnalysisTableGroupFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hidden` field. */
  hidden?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AnalysisTableGroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AnalysisTableGroupFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `toolByToolId` relation. */
  toolByToolId?: InputMaybe<ToolFilter>;
  /** Filter by the object’s `toolId` field. */
  toolId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** The `analysisTable` to be created by this mutation. */
export type AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdAnalysisTableCreateInput =
  {
    analysisTableGroupToGroupId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    identifiers?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** The `analysisTableGroup` to be created by this mutation. */
export type AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdAnalysisTableGroupCreateInput =
  {
    analysisTablesUsingId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description: Scalars["String"]["input"];
    hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    name: Scalars["String"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolToToolId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `analysisTableGroup` in the `AnalysisTableInput` mutation. */
export type AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput = {
  /** The primary key(s) for `analysisTableGroup` for the far side of the relationship. */
  connectById?: InputMaybe<AnalysisTableGroupAnalysisTableGroupPkeyConnect>;
  /** A `AnalysisTableGroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdAnalysisTableGroupCreateInput>;
  /** The primary key(s) for `analysisTableGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<AnalysisTableGroupAnalysisTableGroupPkeyDelete>;
  /** The primary key(s) and patch data for `analysisTableGroup` for the far side of the relationship. */
  updateById?: InputMaybe<AnalysisTableGroupOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdUsingAnalysisTableGroupPkeyUpdate>;
};

/** Input for the nested mutation of `analysisTable` in the `AnalysisTableGroupInput` mutation. */
export type AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInverseInput = {
  /** The primary key(s) for `analysisTable` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AnalysisTableAnalysisTablePkeyConnect>>;
  /** A `AnalysisTableInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdAnalysisTableCreateInput>
  >;
  /** The primary key(s) for `analysisTable` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<AnalysisTableAnalysisTablePkeyDelete>>;
  /** Flag indicating whether all other `analysisTable` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `analysisTable` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AnalysisTableOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdUsingAnalysisTablePkeyUpdate>
  >;
};

/** An input for mutations affecting `AnalysisTableGroup` */
export type AnalysisTableGroupInput = {
  analysisTablesUsingId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInverseInput>;
  applicationUserToUserId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  description: Scalars["String"]["input"];
  hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  name: Scalars["String"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInput>;
  toolId?: InputMaybe<Scalars["Int"]["input"]>;
  toolToToolId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `analysisTableGroup` to look up the row to update. */
export type AnalysisTableGroupOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdUsingAnalysisTableGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableGroup` being updated. */
    analysisTableGroupPatch: UpdateAnalysisTableGroupOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdPatch;
    id: Scalars["BigInt"]["input"];
  };

/** The fields on `analysisTableGroup` to look up the row to update. */
export type AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdUsingAnalysisTableGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableGroup` being updated. */
    analysisTableGroupPatch: UpdateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdPatch;
    id: Scalars["BigInt"]["input"];
  };

/** The fields on `analysisTableGroup` to look up the row to update. */
export type AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingAnalysisTableGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableGroup` being updated. */
    analysisTableGroupPatch: UpdateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdPatch;
    id: Scalars["BigInt"]["input"];
  };

/** The fields on `analysisTableGroup` to look up the row to update. */
export type AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdUsingAnalysisTableGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableGroup` being updated. */
    analysisTableGroupPatch: UpdateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdPatch;
    id: Scalars["BigInt"]["input"];
  };

/** The fields on `analysisTableGroup` to look up the row to update. */
export type AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingAnalysisTableGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableGroup` being updated. */
    analysisTableGroupPatch: UpdateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdPatch;
    id: Scalars["BigInt"]["input"];
  };

/** Represents an update to a `AnalysisTableGroup`. Fields that are set will be updated. */
export type AnalysisTableGroupPatch = {
  analysisTablesUsingId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInverseInput>;
  applicationUserToUserId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInput>;
  toolId?: InputMaybe<Scalars["Int"]["input"]>;
  toolToToolId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `analysisTableGroup` to be created by this mutation. */
export type AnalysisTableGroupProjectId870Aec99FkProjectIdAnalysisTableGroupCreateInput =
  {
    analysisTablesUsingId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description: Scalars["String"]["input"];
    hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    name: Scalars["String"]["input"];
    projectToProjectId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolToToolId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `project` in the `AnalysisTableGroupInput` mutation. */
export type AnalysisTableGroupProjectId870Aec99FkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `analysisTableGroup` in the `ProjectInput` mutation. */
export type AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput = {
  /** The primary key(s) for `analysisTableGroup` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AnalysisTableGroupAnalysisTableGroupPkeyConnect>
  >;
  /** A `AnalysisTableGroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AnalysisTableGroupProjectId870Aec99FkProjectIdAnalysisTableGroupCreateInput>
  >;
  /** The primary key(s) for `analysisTableGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<AnalysisTableGroupAnalysisTableGroupPkeyDelete>
  >;
  /** Flag indicating whether all other `analysisTableGroup` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `analysisTableGroup` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdUsingAnalysisTableGroupPkeyUpdate>
  >;
};

/** The `analysisTableGroup` to be created by this mutation. */
export type AnalysisTableGroupTenantId3786Ab7AFkTenantIdAnalysisTableGroupCreateInput =
  {
    analysisTablesUsingId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description: Scalars["String"]["input"];
    hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    name: Scalars["String"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInput>;
    tenantToTenantId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolToToolId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `tenant` in the `AnalysisTableGroupInput` mutation. */
export type AnalysisTableGroupTenantId3786Ab7AFkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `analysisTableGroup` in the `TenantInput` mutation. */
export type AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput = {
  /** The primary key(s) for `analysisTableGroup` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AnalysisTableGroupAnalysisTableGroupPkeyConnect>
  >;
  /** A `AnalysisTableGroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AnalysisTableGroupTenantId3786Ab7AFkTenantIdAnalysisTableGroupCreateInput>
  >;
  /** The primary key(s) for `analysisTableGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<AnalysisTableGroupAnalysisTableGroupPkeyDelete>
  >;
  /** Flag indicating whether all other `analysisTableGroup` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `analysisTableGroup` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingAnalysisTableGroupPkeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type AnalysisTableGroupTenantId3786Ab7AFkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** A filter to be used against many `AnalysisTable` object types. All fields are combined with a logical ‘and.’ */
export type AnalysisTableGroupToManyAnalysisTableFilter = {
  /** Every related `AnalysisTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AnalysisTableFilter>;
  /** No related `AnalysisTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AnalysisTableFilter>;
  /** Some related `AnalysisTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AnalysisTableFilter>;
};

/** The `analysisTableGroup` to be created by this mutation. */
export type AnalysisTableGroupToolIdB122F1CcFkToolIdAnalysisTableGroupCreateInput =
  {
    analysisTablesUsingId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description: Scalars["String"]["input"];
    hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    name: Scalars["String"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInput>;
    toolToToolId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `tool` in the `AnalysisTableGroupInput` mutation. */
export type AnalysisTableGroupToolIdB122F1CcFkToolIdInput = {
  /** The primary key(s) for `tool` for the far side of the relationship. */
  connectById?: InputMaybe<ToolToolPkeyConnect>;
  /** The primary key(s) for `tool` for the far side of the relationship. */
  connectByKey?: InputMaybe<ToolToolKeyKeyConnect>;
  /** A `ToolInput` object that will be created and connected to this object. */
  create?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdToolCreateInput>;
  /** The primary key(s) for `tool` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolToolPkeyDelete>;
  /** The primary key(s) for `tool` for the far side of the relationship. */
  deleteByKey?: InputMaybe<ToolToolKeyKeyDelete>;
  /** The primary key(s) and patch data for `tool` for the far side of the relationship. */
  updateById?: InputMaybe<ToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdUsingToolPkeyUpdate>;
  /** The primary key(s) and patch data for `tool` for the far side of the relationship. */
  updateByKey?: InputMaybe<ToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdUsingToolKeyKeyUpdate>;
};

/** Input for the nested mutation of `analysisTableGroup` in the `ToolInput` mutation. */
export type AnalysisTableGroupToolIdB122F1CcFkToolIdInverseInput = {
  /** The primary key(s) for `analysisTableGroup` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AnalysisTableGroupAnalysisTableGroupPkeyConnect>
  >;
  /** A `AnalysisTableGroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AnalysisTableGroupToolIdB122F1CcFkToolIdAnalysisTableGroupCreateInput>
  >;
  /** The primary key(s) for `analysisTableGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<AnalysisTableGroupAnalysisTableGroupPkeyDelete>
  >;
  /** Flag indicating whether all other `analysisTableGroup` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `analysisTableGroup` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdUsingAnalysisTableGroupPkeyUpdate>
  >;
};

/** The `tool` to be created by this mutation. */
export type AnalysisTableGroupToolIdB122F1CcFkToolIdToolCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  helpText: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  searchTerms?: InputMaybe<Scalars["JSON"]["input"]>;
  toolDisplayCategoriesUsingId?: InputMaybe<ToolDisplayCategoriesToolIdAdc582D3FkToolIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsToolIdF77Ef6F7FkToolIdInverseInput>;
  toolVersionsUsingId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInverseInput>;
};

/** The `analysisTableGroup` to be created by this mutation. */
export type AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdAnalysisTableGroupCreateInput =
  {
    analysisTablesUsingId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description: Scalars["String"]["input"];
    hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    name: Scalars["String"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolToToolId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInput>;
  };

/** The `applicationUser` to be created by this mutation. */
export type AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `AnalysisTableGroupInput` mutation. */
export type AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `analysisTableGroup` in the `ApplicationUserInput` mutation. */
export type AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput = {
  /** The primary key(s) for `analysisTableGroup` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AnalysisTableGroupAnalysisTableGroupPkeyConnect>
  >;
  /** A `AnalysisTableGroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdAnalysisTableGroupCreateInput>
  >;
  /** The primary key(s) for `analysisTableGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<AnalysisTableGroupAnalysisTableGroupPkeyDelete>
  >;
  /** Flag indicating whether all other `analysisTableGroup` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `analysisTableGroup` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingAnalysisTableGroupPkeyUpdate>
  >;
};

/** A connection to a list of `AnalysisTableGroup` values. */
export type AnalysisTableGroupsConnection = {
  __typename: "AnalysisTableGroupsConnection";
  /** A list of edges which contains the `AnalysisTableGroup` and cursor to aid in pagination. */
  edges: Array<AnalysisTableGroupsEdge>;
  /** A list of `AnalysisTableGroup` objects. */
  nodes: Array<AnalysisTableGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AnalysisTableGroup` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AnalysisTableGroup` edge in the connection. */
export type AnalysisTableGroupsEdge = {
  __typename: "AnalysisTableGroupsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AnalysisTableGroup` at the end of the edge. */
  node: AnalysisTableGroup;
};

/** Methods to use when ordering `AnalysisTableGroup`. */
export enum AnalysisTableGroupsOrderBy {
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  HiddenAsc = "HIDDEN_ASC",
  HiddenDesc = "HIDDEN_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  ToolIdAsc = "TOOL_ID_ASC",
  ToolIdDesc = "TOOL_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** An input for mutations affecting `AnalysisTable` */
export type AnalysisTableInput = {
  analysisTableGroupToGroupId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput>;
  applicationUserToUserId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  groupId?: InputMaybe<Scalars["BigInt"]["input"]>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  identifiers?: InputMaybe<Scalars["JSON"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInput>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `analysisTable` to look up the row to update. */
export type AnalysisTableOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdUsingAnalysisTablePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTable` being updated. */
    analysisTablePatch: UpdateAnalysisTableOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdPatch;
    id: Scalars["BigInt"]["input"];
  };

/** The fields on `analysisTable` to look up the row to update. */
export type AnalysisTableOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdUsingAnalysisTablePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTable` being updated. */
    analysisTablePatch: UpdateAnalysisTableOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdPatch;
    id: Scalars["BigInt"]["input"];
  };

/** The fields on `analysisTable` to look up the row to update. */
export type AnalysisTableOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingAnalysisTablePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTable` being updated. */
    analysisTablePatch: UpdateAnalysisTableOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdPatch;
    id: Scalars["BigInt"]["input"];
  };

/** The fields on `analysisTable` to look up the row to update. */
export type AnalysisTableOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdUsingAnalysisTablePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTable` being updated. */
    analysisTablePatch: UpdateAnalysisTableOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdPatch;
    id: Scalars["BigInt"]["input"];
  };

/** The fields on `analysisTable` to look up the row to update. */
export type AnalysisTableOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingAnalysisTablePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTable` being updated. */
    analysisTablePatch: UpdateAnalysisTableOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdPatch;
    id: Scalars["BigInt"]["input"];
  };

/** The fields on `analysisTable` to look up the row to update. */
export type AnalysisTableOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdUsingAnalysisTablePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTable` being updated. */
    analysisTablePatch: UpdateAnalysisTableOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdPatch;
    id: Scalars["BigInt"]["input"];
  };

/** Represents an update to a `AnalysisTable`. Fields that are set will be updated. */
export type AnalysisTablePatch = {
  analysisTableGroupToGroupId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput>;
  applicationUserToUserId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  groupId?: InputMaybe<Scalars["BigInt"]["input"]>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  identifiers?: InputMaybe<Scalars["JSON"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInput>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `analysisTable` to be created by this mutation. */
export type AnalysisTableProjectId709Aebc7FkProjectIdAnalysisTableCreateInput =
  {
    analysisTableGroupToGroupId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    groupId?: InputMaybe<Scalars["BigInt"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    identifiers?: InputMaybe<Scalars["JSON"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `project` in the `AnalysisTableInput` mutation. */
export type AnalysisTableProjectId709Aebc7FkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `analysisTable` in the `ProjectInput` mutation. */
export type AnalysisTableProjectId709Aebc7FkProjectIdInverseInput = {
  /** The primary key(s) for `analysisTable` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AnalysisTableAnalysisTablePkeyConnect>>;
  /** A `AnalysisTableInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AnalysisTableProjectId709Aebc7FkProjectIdAnalysisTableCreateInput>
  >;
  /** The primary key(s) for `analysisTable` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<AnalysisTableAnalysisTablePkeyDelete>>;
  /** Flag indicating whether all other `analysisTable` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `analysisTable` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AnalysisTableOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdUsingAnalysisTablePkeyUpdate>
  >;
};

export type AnalysisTableRow = {
  __typename: "AnalysisTableRow";
  activeAttachResults: Maybe<Scalars["Boolean"]["output"]>;
  /** Reads a single `AnalysisTable` that is related to this `AnalysisTableRow`. */
  analysisTableByTableId: Maybe<AnalysisTable>;
  /** Reads and enables pagination through a set of `AnalysisTableRowAttachResult`. */
  analysisTableRowAttachResultsByAnalysisTableRowId: AnalysisTableRowAttachResultsConnection;
  attachments: Maybe<Scalars["JSON"]["output"]>;
  autofillMap: Maybe<Scalars["JSON"]["output"]>;
  data: Maybe<Scalars["JSON"]["output"]>;
  dateCreated: Scalars["Datetime"]["output"];
  deleted: Maybe<Scalars["Boolean"]["output"]>;
  groups: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["UUID"]["output"];
  locked: Maybe<Scalars["Boolean"]["output"]>;
  metadatumReferences: Maybe<Scalars["JSON"]["output"]>;
  /** Reads a single `Project` that is related to this `AnalysisTableRow`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  selections: Maybe<Scalars["JSON"]["output"]>;
  tableId: Scalars["BigInt"]["output"];
  /** Reads a single `Task` that is related to this `AnalysisTableRow`. */
  taskByTaskId: Maybe<Task>;
  taskId: Maybe<Scalars["UUID"]["output"]>;
  /** Reads a single `Tenant` that is related to this `AnalysisTableRow`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  /** Reads a single `ToolVersion` that is related to this `AnalysisTableRow`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
  toolVersionId: Scalars["Int"]["output"];
};

export type AnalysisTableRowActiveAttachResultsArgs = {
  at?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type AnalysisTableRowAnalysisTableRowAttachResultsByAnalysisTableRowIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<AnalysisTableRowAttachResultCondition>;
    filter?: InputMaybe<AnalysisTableRowAttachResultFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<AnalysisTableRowAttachResultsOrderBy>>;
  };

/** The `analysisTableRowAttachResult` to be created by this mutation. */
export type AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisAnalysisTableRowAttachResultsCreateInput =
  {
    analysisTableRowToAnalysisTableRowId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInput>;
    attachResults: Scalars["Boolean"]["input"];
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInput>;
  };

/** The `analysisTableRow` to be created by this mutation. */
export type AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisAnalysisTableRowCreateInput =
  {
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput>;
    analysisTableToTableId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput>;
    attachments?: InputMaybe<Scalars["JSON"]["input"]>;
    autofillMap?: InputMaybe<Scalars["JSON"]["input"]>;
    data?: InputMaybe<Scalars["JSON"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    groups?: InputMaybe<Scalars["JSON"]["input"]>;
    id: Scalars["UUID"]["input"];
    metadatumReferences?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInput>;
    selections?: InputMaybe<Scalars["JSON"]["input"]>;
    tableId?: InputMaybe<Scalars["BigInt"]["input"]>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput>;
  };

/** Input for the nested mutation of `analysisTableRow` in the `AnalysisTableRowAttachResultInput` mutation. */
export type AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInput = {
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  connectById?: InputMaybe<AnalysisTableRowAnalysisTableRowPkeyConnect>;
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  connectByTaskId?: InputMaybe<AnalysisTableRowAnalysisTableRowTaskIdKeyConnect>;
  /** A `AnalysisTableRowInput` object that will be created and connected to this object. */
  create?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisAnalysisTableRowCreateInput>;
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  deleteById?: InputMaybe<AnalysisTableRowAnalysisTableRowPkeyDelete>;
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  deleteByTaskId?: InputMaybe<AnalysisTableRowAnalysisTableRowTaskIdKeyDelete>;
  /** The primary key(s) and patch data for `analysisTableRow` for the far side of the relationship. */
  updateById?: InputMaybe<AnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisUsingAnalysisTableRowPkeyUpdate>;
  /** The primary key(s) and patch data for `analysisTableRow` for the far side of the relationship. */
  updateByTaskId?: InputMaybe<AnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisUsingAnalysisTableRowTaskIdKeyUpdate>;
};

/** Input for the nested mutation of `analysisTableRowAttachResult` in the `AnalysisTableRowInput` mutation. */
export type AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput = {
  /** The primary key(s) for `analysisTableRowAttachResult` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AnalysisTableRowAttachResultAnalysisTableRowAttachResultsPkeyConnect>
  >;
  /** A `AnalysisTableRowAttachResultInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisAnalysisTableRowAttachResultsCreateInput>
  >;
  /** The primary key(s) for `analysisTableRowAttachResult` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<AnalysisTableRowAttachResultAnalysisTableRowAttachResultsPkeyDelete>
  >;
  /** Flag indicating whether all other `analysisTableRowAttachResult` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `analysisTableRowAttachResult` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisUsingAnalysisTableRowAttachResultsPkeyUpdate>
  >;
};

/** The `analysisTableRowAttachResult` to be created by this mutation. */
export type AnalysisTableRowAProjectIdE3E030E2FkProjectIAnalysisTableRowAttachResultsCreateInput =
  {
    analysisTableRowId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableRowToAnalysisTableRowId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInput>;
    attachResults: Scalars["Boolean"]["input"];
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInput>;
  };

/** Input for the nested mutation of `project` in the `AnalysisTableRowAttachResultInput` mutation. */
export type AnalysisTableRowAProjectIdE3E030E2FkProjectIInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `analysisTableRowAttachResult` in the `ProjectInput` mutation. */
export type AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput = {
  /** The primary key(s) for `analysisTableRowAttachResult` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AnalysisTableRowAttachResultAnalysisTableRowAttachResultsPkeyConnect>
  >;
  /** A `AnalysisTableRowAttachResultInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AnalysisTableRowAProjectIdE3E030E2FkProjectIAnalysisTableRowAttachResultsCreateInput>
  >;
  /** The primary key(s) for `analysisTableRowAttachResult` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<AnalysisTableRowAttachResultAnalysisTableRowAttachResultsPkeyDelete>
  >;
  /** Flag indicating whether all other `analysisTableRowAttachResult` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `analysisTableRowAttachResult` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIUsingAnalysisTableRowAttachResultsPkeyUpdate>
  >;
};

/** The fields on `analysisTableRow` to look up the row to connect. */
export type AnalysisTableRowAnalysisTableRowPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `analysisTableRow` to look up the row to delete. */
export type AnalysisTableRowAnalysisTableRowPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `analysisTableRow` to look up the row to connect. */
export type AnalysisTableRowAnalysisTableRowTaskIdKeyConnect = {
  taskId: Scalars["UUID"]["input"];
};

/** The fields on `analysisTableRow` to look up the row to delete. */
export type AnalysisTableRowAnalysisTableRowTaskIdKeyDelete = {
  taskId: Scalars["UUID"]["input"];
};

export type AnalysisTableRowAttachResult = {
  __typename: "AnalysisTableRowAttachResult";
  /** Reads a single `AnalysisTableRow` that is related to this `AnalysisTableRowAttachResult`. */
  analysisTableRowByAnalysisTableRowId: Maybe<AnalysisTableRow>;
  analysisTableRowId: Scalars["UUID"]["output"];
  attachResults: Scalars["Boolean"]["output"];
  dateCreated: Scalars["Datetime"]["output"];
  id: Scalars["BigInt"]["output"];
  /** Reads a single `Project` that is related to this `AnalysisTableRowAttachResult`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
};

/** The fields on `analysisTableRowAttachResult` to look up the row to connect. */
export type AnalysisTableRowAttachResultAnalysisTableRowAttachResultsPkeyConnect =
  {
    id: Scalars["BigInt"]["input"];
  };

/** The fields on `analysisTableRowAttachResult` to look up the row to delete. */
export type AnalysisTableRowAttachResultAnalysisTableRowAttachResultsPkeyDelete =
  {
    id: Scalars["BigInt"]["input"];
  };

/**
 * A condition to be used against `AnalysisTableRowAttachResult` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type AnalysisTableRowAttachResultCondition = {
  /** Checks for equality with the object’s `analysisTableRowId` field. */
  analysisTableRowId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `attachResults` field. */
  attachResults?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `AnalysisTableRowAttachResult` object types. All fields are combined with a logical ‘and.’ */
export type AnalysisTableRowAttachResultFilter = {
  /** Filter by the object’s `analysisTableRowByAnalysisTableRowId` relation. */
  analysisTableRowByAnalysisTableRowId?: InputMaybe<AnalysisTableRowFilter>;
  /** Filter by the object’s `analysisTableRowId` field. */
  analysisTableRowId?: InputMaybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AnalysisTableRowAttachResultFilter>>;
  /** Filter by the object’s `attachResults` field. */
  attachResults?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AnalysisTableRowAttachResultFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AnalysisTableRowAttachResultFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `AnalysisTableRowAttachResult` */
export type AnalysisTableRowAttachResultInput = {
  analysisTableRowId?: InputMaybe<Scalars["UUID"]["input"]>;
  analysisTableRowToAnalysisTableRowId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInput>;
  attachResults: Scalars["Boolean"]["input"];
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInput>;
};

/** The fields on `analysisTableRowAttachResult` to look up the row to update. */
export type AnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisUsingAnalysisTableRowAttachResultsPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableRowAttachResult` being updated. */
    analysisTableRowAttachResultPatch: UpdateAnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisPatch;
    id: Scalars["BigInt"]["input"];
  };

/** The fields on `analysisTableRowAttachResult` to look up the row to update. */
export type AnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIUsingAnalysisTableRowAttachResultsPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableRowAttachResult` being updated. */
    analysisTableRowAttachResultPatch: UpdateAnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIPatch;
    id: Scalars["BigInt"]["input"];
  };

/** Represents an update to a `AnalysisTableRowAttachResult`. Fields that are set will be updated. */
export type AnalysisTableRowAttachResultPatch = {
  analysisTableRowId?: InputMaybe<Scalars["UUID"]["input"]>;
  analysisTableRowToAnalysisTableRowId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInput>;
  attachResults?: InputMaybe<Scalars["Boolean"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInput>;
};

/** A connection to a list of `AnalysisTableRowAttachResult` values. */
export type AnalysisTableRowAttachResultsConnection = {
  __typename: "AnalysisTableRowAttachResultsConnection";
  /** A list of edges which contains the `AnalysisTableRowAttachResult` and cursor to aid in pagination. */
  edges: Array<AnalysisTableRowAttachResultsEdge>;
  /** A list of `AnalysisTableRowAttachResult` objects. */
  nodes: Array<AnalysisTableRowAttachResult>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AnalysisTableRowAttachResult` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AnalysisTableRowAttachResult` edge in the connection. */
export type AnalysisTableRowAttachResultsEdge = {
  __typename: "AnalysisTableRowAttachResultsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AnalysisTableRowAttachResult` at the end of the edge. */
  node: AnalysisTableRowAttachResult;
};

/** Methods to use when ordering `AnalysisTableRowAttachResult`. */
export enum AnalysisTableRowAttachResultsOrderBy {
  AnalysisTableRowIdAsc = "ANALYSIS_TABLE_ROW_ID_ASC",
  AnalysisTableRowIdDesc = "ANALYSIS_TABLE_ROW_ID_DESC",
  AttachResultsAsc = "ATTACH_RESULTS_ASC",
  AttachResultsDesc = "ATTACH_RESULTS_DESC",
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
}

/**
 * A condition to be used against `AnalysisTableRow` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AnalysisTableRowCondition = {
  /** Checks for equality with the object’s `attachments` field. */
  attachments?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `autofillMap` field. */
  autofillMap?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `data` field. */
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `groups` field. */
  groups?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `metadatumReferences` field. */
  metadatumReferences?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `selections` field. */
  selections?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `tableId` field. */
  tableId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `toolVersionId` field. */
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `AnalysisTableRow` object types. All fields are combined with a logical ‘and.’ */
export type AnalysisTableRowFilter = {
  /** Filter by the object’s `analysisTableByTableId` relation. */
  analysisTableByTableId?: InputMaybe<AnalysisTableFilter>;
  /** Filter by the object’s `analysisTableRowAttachResultsByAnalysisTableRowId` relation. */
  analysisTableRowAttachResultsByAnalysisTableRowId?: InputMaybe<AnalysisTableRowToManyAnalysisTableRowAttachResultFilter>;
  /** Some related `analysisTableRowAttachResultsByAnalysisTableRowId` exist. */
  analysisTableRowAttachResultsByAnalysisTableRowIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AnalysisTableRowFilter>>;
  /** Filter by the object’s `attachments` field. */
  attachments?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `autofillMap` field. */
  autofillMap?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `data` field. */
  data?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `groups` field. */
  groups?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `metadatumReferences` field. */
  metadatumReferences?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AnalysisTableRowFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AnalysisTableRowFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `selections` field. */
  selections?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `tableId` field. */
  tableId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `taskByTaskId` relation. */
  taskByTaskId?: InputMaybe<TaskFilter>;
  /** A related `taskByTaskId` exists. */
  taskByTaskIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `taskId` field. */
  taskId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `toolVersionByToolVersionId` relation. */
  toolVersionByToolVersionId?: InputMaybe<ToolVersionFilter>;
  /** Filter by the object’s `toolVersionId` field. */
  toolVersionId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `AnalysisTableRow` */
export type AnalysisTableRowInput = {
  analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput>;
  analysisTableToTableId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput>;
  attachments?: InputMaybe<Scalars["JSON"]["input"]>;
  autofillMap?: InputMaybe<Scalars["JSON"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  groups?: InputMaybe<Scalars["JSON"]["input"]>;
  id: Scalars["UUID"]["input"];
  metadatumReferences?: InputMaybe<Scalars["JSON"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInput>;
  selections?: InputMaybe<Scalars["JSON"]["input"]>;
  tableId?: InputMaybe<Scalars["BigInt"]["input"]>;
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  taskToTaskId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInput>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput>;
};

/** The fields on `analysisTableRow` to look up the row to update. */
export type AnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisUsingAnalysisTableRowPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableRow` being updated. */
    analysisTableRowPatch: UpdateAnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `analysisTableRow` to look up the row to update. */
export type AnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisUsingAnalysisTableRowTaskIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableRow` being updated. */
    analysisTableRowPatch: UpdateAnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisPatch;
    taskId: Scalars["UUID"]["input"];
  };

/** The fields on `analysisTableRow` to look up the row to update. */
export type AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingAnalysisTableRowPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableRow` being updated. */
    analysisTableRowPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `analysisTableRow` to look up the row to update. */
export type AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingAnalysisTableRowTaskIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableRow` being updated. */
    analysisTableRowPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdPatch;
    taskId: Scalars["UUID"]["input"];
  };

/** The fields on `analysisTableRow` to look up the row to update. */
export type AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdUsingAnalysisTableRowPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableRow` being updated. */
    analysisTableRowPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `analysisTableRow` to look up the row to update. */
export type AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdUsingAnalysisTableRowTaskIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableRow` being updated. */
    analysisTableRowPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdPatch;
    taskId: Scalars["UUID"]["input"];
  };

/** The fields on `analysisTableRow` to look up the row to update. */
export type AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingAnalysisTableRowPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableRow` being updated. */
    analysisTableRowPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `analysisTableRow` to look up the row to update. */
export type AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingAnalysisTableRowTaskIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableRow` being updated. */
    analysisTableRowPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdPatch;
    taskId: Scalars["UUID"]["input"];
  };

/** The fields on `analysisTableRow` to look up the row to update. */
export type AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingAnalysisTableRowPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableRow` being updated. */
    analysisTableRowPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `analysisTableRow` to look up the row to update. */
export type AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingAnalysisTableRowTaskIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableRow` being updated. */
    analysisTableRowPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdPatch;
    taskId: Scalars["UUID"]["input"];
  };

/** The fields on `analysisTableRow` to look up the row to update. */
export type AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingAnalysisTableRowPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableRow` being updated. */
    analysisTableRowPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `analysisTableRow` to look up the row to update. */
export type AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingAnalysisTableRowTaskIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `analysisTableRow` being updated. */
    analysisTableRowPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdPatch;
    taskId: Scalars["UUID"]["input"];
  };

/** Represents an update to a `AnalysisTableRow`. Fields that are set will be updated. */
export type AnalysisTableRowPatch = {
  analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput>;
  analysisTableToTableId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput>;
  attachments?: InputMaybe<Scalars["JSON"]["input"]>;
  autofillMap?: InputMaybe<Scalars["JSON"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  groups?: InputMaybe<Scalars["JSON"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  metadatumReferences?: InputMaybe<Scalars["JSON"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInput>;
  selections?: InputMaybe<Scalars["JSON"]["input"]>;
  tableId?: InputMaybe<Scalars["BigInt"]["input"]>;
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  taskToTaskId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInput>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput>;
};

/** The `analysisTableRow` to be created by this mutation. */
export type AnalysisTableRowProjectIdCd370B28FkProjectIdAnalysisTableRowCreateInput =
  {
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput>;
    analysisTableToTableId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput>;
    attachments?: InputMaybe<Scalars["JSON"]["input"]>;
    autofillMap?: InputMaybe<Scalars["JSON"]["input"]>;
    data?: InputMaybe<Scalars["JSON"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    groups?: InputMaybe<Scalars["JSON"]["input"]>;
    id: Scalars["UUID"]["input"];
    metadatumReferences?: InputMaybe<Scalars["JSON"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInput>;
    selections?: InputMaybe<Scalars["JSON"]["input"]>;
    tableId?: InputMaybe<Scalars["BigInt"]["input"]>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput>;
  };

/** Input for the nested mutation of `project` in the `AnalysisTableRowInput` mutation. */
export type AnalysisTableRowProjectIdCd370B28FkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `analysisTableRow` in the `ProjectInput` mutation. */
export type AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput = {
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AnalysisTableRowAnalysisTableRowPkeyConnect>>;
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  connectByTaskId?: InputMaybe<
    Array<AnalysisTableRowAnalysisTableRowTaskIdKeyConnect>
  >;
  /** A `AnalysisTableRowInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AnalysisTableRowProjectIdCd370B28FkProjectIdAnalysisTableRowCreateInput>
  >;
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<AnalysisTableRowAnalysisTableRowPkeyDelete>>;
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  deleteByTaskId?: InputMaybe<
    Array<AnalysisTableRowAnalysisTableRowTaskIdKeyDelete>
  >;
  /** Flag indicating whether all other `analysisTableRow` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `analysisTableRow` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingAnalysisTableRowPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `analysisTableRow` for the far side of the relationship. */
  updateByTaskId?: InputMaybe<
    Array<AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingAnalysisTableRowTaskIdKeyUpdate>
  >;
};

/** The `analysisTable` to be created by this mutation. */
export type AnalysisTableRowTableIdB1C24185FkAnalysisTableIdAnalysisTableCreateInput =
  {
    analysisTableGroupToGroupId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    groupId?: InputMaybe<Scalars["BigInt"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    identifiers?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** The `analysisTableRow` to be created by this mutation. */
export type AnalysisTableRowTableIdB1C24185FkAnalysisTableIdAnalysisTableRowCreateInput =
  {
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput>;
    analysisTableToTableId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput>;
    attachments?: InputMaybe<Scalars["JSON"]["input"]>;
    autofillMap?: InputMaybe<Scalars["JSON"]["input"]>;
    data?: InputMaybe<Scalars["JSON"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    groups?: InputMaybe<Scalars["JSON"]["input"]>;
    id: Scalars["UUID"]["input"];
    metadatumReferences?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInput>;
    selections?: InputMaybe<Scalars["JSON"]["input"]>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput>;
  };

/** Input for the nested mutation of `analysisTable` in the `AnalysisTableRowInput` mutation. */
export type AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput = {
  /** The primary key(s) for `analysisTable` for the far side of the relationship. */
  connectById?: InputMaybe<AnalysisTableAnalysisTablePkeyConnect>;
  /** A `AnalysisTableInput` object that will be created and connected to this object. */
  create?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdAnalysisTableCreateInput>;
  /** The primary key(s) for `analysisTable` for the far side of the relationship. */
  deleteById?: InputMaybe<AnalysisTableAnalysisTablePkeyDelete>;
  /** The primary key(s) and patch data for `analysisTable` for the far side of the relationship. */
  updateById?: InputMaybe<AnalysisTableOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdUsingAnalysisTablePkeyUpdate>;
};

/** Input for the nested mutation of `analysisTableRow` in the `AnalysisTableInput` mutation. */
export type AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput = {
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AnalysisTableRowAnalysisTableRowPkeyConnect>>;
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  connectByTaskId?: InputMaybe<
    Array<AnalysisTableRowAnalysisTableRowTaskIdKeyConnect>
  >;
  /** A `AnalysisTableRowInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdAnalysisTableRowCreateInput>
  >;
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<AnalysisTableRowAnalysisTableRowPkeyDelete>>;
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  deleteByTaskId?: InputMaybe<
    Array<AnalysisTableRowAnalysisTableRowTaskIdKeyDelete>
  >;
  /** Flag indicating whether all other `analysisTableRow` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `analysisTableRow` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdUsingAnalysisTableRowPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `analysisTableRow` for the far side of the relationship. */
  updateByTaskId?: InputMaybe<
    Array<AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdUsingAnalysisTableRowTaskIdKeyUpdate>
  >;
};

/** The `analysisTableRow` to be created by this mutation. */
export type AnalysisTableRowTaskId742Ff0EdFkTaskIdAnalysisTableRowCreateInput =
  {
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput>;
    analysisTableToTableId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput>;
    attachments?: InputMaybe<Scalars["JSON"]["input"]>;
    autofillMap?: InputMaybe<Scalars["JSON"]["input"]>;
    data?: InputMaybe<Scalars["JSON"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    groups?: InputMaybe<Scalars["JSON"]["input"]>;
    id: Scalars["UUID"]["input"];
    metadatumReferences?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInput>;
    selections?: InputMaybe<Scalars["JSON"]["input"]>;
    tableId?: InputMaybe<Scalars["BigInt"]["input"]>;
    taskToTaskId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput>;
  };

/** Input for the nested mutation of `task` in the `AnalysisTableRowInput` mutation. */
export type AnalysisTableRowTaskId742Ff0EdFkTaskIdInput = {
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectByExecutorId?: InputMaybe<TaskTaskExecutorIdKeyConnect>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectById?: InputMaybe<TaskTaskPkeyConnect>;
  /** A `TaskInput` object that will be created and connected to this object. */
  create?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdTaskCreateInput>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteByExecutorId?: InputMaybe<TaskTaskExecutorIdKeyDelete>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteById?: InputMaybe<TaskTaskPkeyDelete>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateByExecutorId?: InputMaybe<TaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingTaskExecutorIdKeyUpdate>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateById?: InputMaybe<TaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingTaskPkeyUpdate>;
};

/** Input for the nested mutation of `analysisTableRow` in the `TaskInput` mutation. */
export type AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput = {
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  connectById?: InputMaybe<AnalysisTableRowAnalysisTableRowPkeyConnect>;
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  connectByTaskId?: InputMaybe<AnalysisTableRowAnalysisTableRowTaskIdKeyConnect>;
  /** A `AnalysisTableRowInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AnalysisTableRowTaskId742Ff0EdFkTaskIdAnalysisTableRowCreateInput>
  >;
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  deleteById?: InputMaybe<AnalysisTableRowAnalysisTableRowPkeyDelete>;
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  deleteByTaskId?: InputMaybe<AnalysisTableRowAnalysisTableRowTaskIdKeyDelete>;
  /** Flag indicating whether all other `analysisTableRow` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `analysisTableRow` for the far side of the relationship. */
  updateById?: InputMaybe<AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingAnalysisTableRowPkeyUpdate>;
  /** The primary key(s) and patch data for `analysisTableRow` for the far side of the relationship. */
  updateByTaskId?: InputMaybe<AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingAnalysisTableRowTaskIdKeyUpdate>;
};

/** The `task` to be created by this mutation. */
export type AnalysisTableRowTaskId742Ff0EdFkTaskIdTaskCreateInput = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned: Scalars["Boolean"]["input"];
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id: Scalars["UUID"]["input"];
  inputsHash: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status: Scalars["Int"]["input"];
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `analysisTableRow` to be created by this mutation. */
export type AnalysisTableRowTenantIdDb778B87FkTenantIdAnalysisTableRowCreateInput =
  {
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput>;
    analysisTableToTableId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput>;
    attachments?: InputMaybe<Scalars["JSON"]["input"]>;
    autofillMap?: InputMaybe<Scalars["JSON"]["input"]>;
    data?: InputMaybe<Scalars["JSON"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    groups?: InputMaybe<Scalars["JSON"]["input"]>;
    id: Scalars["UUID"]["input"];
    metadatumReferences?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInput>;
    selections?: InputMaybe<Scalars["JSON"]["input"]>;
    tableId?: InputMaybe<Scalars["BigInt"]["input"]>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInput>;
    tenantToTenantId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput>;
  };

/** Input for the nested mutation of `tenant` in the `AnalysisTableRowInput` mutation. */
export type AnalysisTableRowTenantIdDb778B87FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `analysisTableRow` in the `TenantInput` mutation. */
export type AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput = {
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AnalysisTableRowAnalysisTableRowPkeyConnect>>;
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  connectByTaskId?: InputMaybe<
    Array<AnalysisTableRowAnalysisTableRowTaskIdKeyConnect>
  >;
  /** A `AnalysisTableRowInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AnalysisTableRowTenantIdDb778B87FkTenantIdAnalysisTableRowCreateInput>
  >;
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<AnalysisTableRowAnalysisTableRowPkeyDelete>>;
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  deleteByTaskId?: InputMaybe<
    Array<AnalysisTableRowAnalysisTableRowTaskIdKeyDelete>
  >;
  /** Flag indicating whether all other `analysisTableRow` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `analysisTableRow` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingAnalysisTableRowPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `analysisTableRow` for the far side of the relationship. */
  updateByTaskId?: InputMaybe<
    Array<AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingAnalysisTableRowTaskIdKeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type AnalysisTableRowTenantIdDb778B87FkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** A filter to be used against many `AnalysisTableRowAttachResult` object types. All fields are combined with a logical ‘and.’ */
export type AnalysisTableRowToManyAnalysisTableRowAttachResultFilter = {
  /** Every related `AnalysisTableRowAttachResult` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AnalysisTableRowAttachResultFilter>;
  /** No related `AnalysisTableRowAttachResult` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AnalysisTableRowAttachResultFilter>;
  /** Some related `AnalysisTableRowAttachResult` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AnalysisTableRowAttachResultFilter>;
};

/** The `analysisTableRow` to be created by this mutation. */
export type AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdAnalysisTableRowCreateInput =
  {
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput>;
    analysisTableToTableId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput>;
    attachments?: InputMaybe<Scalars["JSON"]["input"]>;
    autofillMap?: InputMaybe<Scalars["JSON"]["input"]>;
    data?: InputMaybe<Scalars["JSON"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    groups?: InputMaybe<Scalars["JSON"]["input"]>;
    id: Scalars["UUID"]["input"];
    metadatumReferences?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInput>;
    selections?: InputMaybe<Scalars["JSON"]["input"]>;
    tableId?: InputMaybe<Scalars["BigInt"]["input"]>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInput>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput>;
  };

/** Input for the nested mutation of `toolVersion` in the `AnalysisTableRowInput` mutation. */
export type AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput = {
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectById?: InputMaybe<ToolVersionToolVersionPkeyConnect>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueConnect>;
  /** A `ToolVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdToolVersionCreateInput>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolVersionToolVersionPkeyDelete>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueDelete>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateById?: InputMaybe<ToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingToolVersionPkeyUpdate>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateByToolIdAndVersion?: InputMaybe<ToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingToolversionToolVersionUniqueUpdate>;
};

/** Input for the nested mutation of `analysisTableRow` in the `ToolVersionInput` mutation. */
export type AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput = {
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AnalysisTableRowAnalysisTableRowPkeyConnect>>;
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  connectByTaskId?: InputMaybe<
    Array<AnalysisTableRowAnalysisTableRowTaskIdKeyConnect>
  >;
  /** A `AnalysisTableRowInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdAnalysisTableRowCreateInput>
  >;
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<AnalysisTableRowAnalysisTableRowPkeyDelete>>;
  /** The primary key(s) for `analysisTableRow` for the far side of the relationship. */
  deleteByTaskId?: InputMaybe<
    Array<AnalysisTableRowAnalysisTableRowTaskIdKeyDelete>
  >;
  /** Flag indicating whether all other `analysisTableRow` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `analysisTableRow` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingAnalysisTableRowPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `analysisTableRow` for the far side of the relationship. */
  updateByTaskId?: InputMaybe<
    Array<AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingAnalysisTableRowTaskIdKeyUpdate>
  >;
};

/** The `toolVersion` to be created by this mutation. */
export type AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdToolVersionCreateInput =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command: Scalars["String"]["input"];
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible: Scalars["Boolean"]["input"];
    isUpgradableToNextVersion: Scalars["Boolean"]["input"];
    maturity: Scalars["Int"]["input"];
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version: Scalars["String"]["input"];
    visibility: Scalars["Int"]["input"];
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** A connection to a list of `AnalysisTableRow` values. */
export type AnalysisTableRowsConnection = {
  __typename: "AnalysisTableRowsConnection";
  /** A list of edges which contains the `AnalysisTableRow` and cursor to aid in pagination. */
  edges: Array<AnalysisTableRowsEdge>;
  /** A list of `AnalysisTableRow` objects. */
  nodes: Array<AnalysisTableRow>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AnalysisTableRow` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AnalysisTableRow` edge in the connection. */
export type AnalysisTableRowsEdge = {
  __typename: "AnalysisTableRowsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AnalysisTableRow` at the end of the edge. */
  node: AnalysisTableRow;
};

/** Methods to use when ordering `AnalysisTableRow`. */
export enum AnalysisTableRowsOrderBy {
  AttachmentsAsc = "ATTACHMENTS_ASC",
  AttachmentsDesc = "ATTACHMENTS_DESC",
  AutofillMapAsc = "AUTOFILL_MAP_ASC",
  AutofillMapDesc = "AUTOFILL_MAP_DESC",
  DataAsc = "DATA_ASC",
  DataDesc = "DATA_DESC",
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  DeletedAsc = "DELETED_ASC",
  DeletedDesc = "DELETED_DESC",
  GroupsAsc = "GROUPS_ASC",
  GroupsDesc = "GROUPS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MetadatumReferencesAsc = "METADATUM_REFERENCES_ASC",
  MetadatumReferencesDesc = "METADATUM_REFERENCES_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  SelectionsAsc = "SELECTIONS_ASC",
  SelectionsDesc = "SELECTIONS_DESC",
  TableIdAsc = "TABLE_ID_ASC",
  TableIdDesc = "TABLE_ID_DESC",
  TaskIdAsc = "TASK_ID_ASC",
  TaskIdDesc = "TASK_ID_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  ToolVersionIdAsc = "TOOL_VERSION_ID_ASC",
  ToolVersionIdDesc = "TOOL_VERSION_ID_DESC",
}

/** The `analysisTable` to be created by this mutation. */
export type AnalysisTableTenantIdF297C671FkTenantIdAnalysisTableCreateInput = {
  analysisTableGroupToGroupId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput>;
  applicationUserToUserId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  groupId?: InputMaybe<Scalars["BigInt"]["input"]>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  identifiers?: InputMaybe<Scalars["JSON"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInput>;
  tenantToTenantId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInput>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `tenant` in the `AnalysisTableInput` mutation. */
export type AnalysisTableTenantIdF297C671FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `analysisTable` in the `TenantInput` mutation. */
export type AnalysisTableTenantIdF297C671FkTenantIdInverseInput = {
  /** The primary key(s) for `analysisTable` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AnalysisTableAnalysisTablePkeyConnect>>;
  /** A `AnalysisTableInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AnalysisTableTenantIdF297C671FkTenantIdAnalysisTableCreateInput>
  >;
  /** The primary key(s) for `analysisTable` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<AnalysisTableAnalysisTablePkeyDelete>>;
  /** Flag indicating whether all other `analysisTable` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `analysisTable` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AnalysisTableOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingAnalysisTablePkeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type AnalysisTableTenantIdF297C671FkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** A filter to be used against many `AnalysisTableRow` object types. All fields are combined with a logical ‘and.’ */
export type AnalysisTableToManyAnalysisTableRowFilter = {
  /** Every related `AnalysisTableRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AnalysisTableRowFilter>;
  /** No related `AnalysisTableRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AnalysisTableRowFilter>;
  /** Some related `AnalysisTableRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AnalysisTableRowFilter>;
};

/** The `analysisTable` to be created by this mutation. */
export type AnalysisTableToolVersionId8E4A2317FkToolVersionIdAnalysisTableCreateInput =
  {
    analysisTableGroupToGroupId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    groupId?: InputMaybe<Scalars["BigInt"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    identifiers?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInput>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `toolVersion` in the `AnalysisTableInput` mutation. */
export type AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput = {
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectById?: InputMaybe<ToolVersionToolVersionPkeyConnect>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueConnect>;
  /** A `ToolVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdToolVersionCreateInput>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolVersionToolVersionPkeyDelete>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueDelete>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateById?: InputMaybe<ToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdUsingToolVersionPkeyUpdate>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateByToolIdAndVersion?: InputMaybe<ToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdUsingToolversionToolVersionUniqueUpdate>;
};

/** Input for the nested mutation of `analysisTable` in the `ToolVersionInput` mutation. */
export type AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput = {
  /** The primary key(s) for `analysisTable` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AnalysisTableAnalysisTablePkeyConnect>>;
  /** A `AnalysisTableInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AnalysisTableToolVersionId8E4A2317FkToolVersionIdAnalysisTableCreateInput>
  >;
  /** The primary key(s) for `analysisTable` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<AnalysisTableAnalysisTablePkeyDelete>>;
  /** Flag indicating whether all other `analysisTable` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `analysisTable` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AnalysisTableOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdUsingAnalysisTablePkeyUpdate>
  >;
};

/** The `toolVersion` to be created by this mutation. */
export type AnalysisTableToolVersionId8E4A2317FkToolVersionIdToolVersionCreateInput =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command: Scalars["String"]["input"];
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible: Scalars["Boolean"]["input"];
    isUpgradableToNextVersion: Scalars["Boolean"]["input"];
    maturity: Scalars["Int"]["input"];
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version: Scalars["String"]["input"];
    visibility: Scalars["Int"]["input"];
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** The `analysisTable` to be created by this mutation. */
export type AnalysisTableUserIdD5D6De41FkApplicationUserIdAnalysisTableCreateInput =
  {
    analysisTableGroupToGroupId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    groupId?: InputMaybe<Scalars["BigInt"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    identifiers?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput>;
  };

/** The `applicationUser` to be created by this mutation. */
export type AnalysisTableUserIdD5D6De41FkApplicationUserIdApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `AnalysisTableInput` mutation. */
export type AnalysisTableUserIdD5D6De41FkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `analysisTable` in the `ApplicationUserInput` mutation. */
export type AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput = {
  /** The primary key(s) for `analysisTable` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AnalysisTableAnalysisTablePkeyConnect>>;
  /** A `AnalysisTableInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AnalysisTableUserIdD5D6De41FkApplicationUserIdAnalysisTableCreateInput>
  >;
  /** The primary key(s) for `analysisTable` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<AnalysisTableAnalysisTablePkeyDelete>>;
  /** Flag indicating whether all other `analysisTable` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `analysisTable` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AnalysisTableOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingAnalysisTablePkeyUpdate>
  >;
};

/** A connection to a list of `AnalysisTable` values. */
export type AnalysisTablesConnection = {
  __typename: "AnalysisTablesConnection";
  /** A list of edges which contains the `AnalysisTable` and cursor to aid in pagination. */
  edges: Array<AnalysisTablesEdge>;
  /** A list of `AnalysisTable` objects. */
  nodes: Array<AnalysisTable>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AnalysisTable` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AnalysisTable` edge in the connection. */
export type AnalysisTablesEdge = {
  __typename: "AnalysisTablesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AnalysisTable` at the end of the edge. */
  node: AnalysisTable;
};

/** Methods to use when ordering `AnalysisTable`. */
export enum AnalysisTablesOrderBy {
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  GroupIdAsc = "GROUP_ID_ASC",
  GroupIdDesc = "GROUP_ID_DESC",
  IdentifiersAsc = "IDENTIFIERS_ASC",
  IdentifiersDesc = "IDENTIFIERS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  ToolVersionIdAsc = "TOOL_VERSION_ID_ASC",
  ToolVersionIdDesc = "TOOL_VERSION_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type ApplicationUser = {
  __typename: "ApplicationUser";
  /** Reads and enables pagination through a set of `AnalysisTableGroup`. */
  analysisTableGroupsByUserId: AnalysisTableGroupsConnection;
  /** Reads and enables pagination through a set of `AnalysisTable`. */
  analysisTablesByUserId: AnalysisTablesConnection;
  /** Reads and enables pagination through a set of `ApplicationUserGroup`. */
  applicationUserGroupsByUserId: ApplicationUserGroupsConnection;
  /** Reads and enables pagination through a set of `ApplicationUserMembership`. */
  applicationUserMembershipsByUserId: ApplicationUserMembershipsConnection;
  /** Reads and enables pagination through a set of `ApplicationUserMetadatum`. */
  applicationUserMetadataByUserId: ApplicationUserMetadataConnection;
  /** Reads and enables pagination through a set of `ApplicationUserSession`. */
  applicationUserSessionsByUserId: ApplicationUserSessionsConnection;
  /** Reads and enables pagination through a set of `ApplicationUserUserPermission`. */
  applicationUserUserPermissionsByUserId: ApplicationUserUserPermissionsConnection;
  /** Reads a single `Avatar` that is related to this `ApplicationUser`. */
  avatarByUserId: Maybe<Avatar>;
  /**
   * Reads and enables pagination through a set of `Avatar`.
   * @deprecated Please use avatarByUserId instead
   */
  avatarsByUserId: AvatarsConnection;
  cognitoTenantKey: Maybe<Scalars["String"]["output"]>;
  created: Scalars["Datetime"]["output"];
  /** Reads and enables pagination through a set of `Dataset`. */
  datasetsByUserId: DatasetsConnection;
  /** Reads and enables pagination through a set of `DjangoAdminLog`. */
  djangoAdminLogsByUserId: DjangoAdminLogsConnection;
  email: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `EulaVersionUserAcceptance`. */
  eulaVersionUserAcceptancesByUserId: EulaVersionUserAcceptancesConnection;
  /** Reads and enables pagination through a set of `ExplorerQuery`. */
  explorerQueriesByCreatedByUserId: ExplorerQueriesConnection;
  /** Reads and enables pagination through a set of `ExplorerQueryfavorite`. */
  explorerQueryfavoritesByUserId: ExplorerQueryfavoritesConnection;
  /** Reads and enables pagination through a set of `ExplorerQuerylog`. */
  explorerQuerylogsByRunByUserId: ExplorerQuerylogsConnection;
  /** Reads and enables pagination through a set of `FileActivity`. */
  fileActivitiesByUserId: FileActivitiesConnection;
  /** Reads and enables pagination through a set of `FileOutputGroup`. */
  fileOutputGroupsByUserId: FileOutputGroupsConnection;
  /** Reads and enables pagination through a set of `FileProcessingNotification`. */
  fileProcessingNotificationsByUserId: FileProcessingNotificationsConnection;
  /** Reads and enables pagination through a set of `FileRecordingGroup`. */
  fileRecordingGroupsByUserId: FileRecordingGroupsConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByUserId: FilesConnection;
  firstName: Scalars["String"]["output"];
  globalUserId: Maybe<Scalars["UUID"]["output"]>;
  homeRegion: Maybe<Scalars["String"]["output"]>;
  id: Scalars["UUID"]["output"];
  internal: Scalars["Boolean"]["output"];
  isActive: Scalars["Boolean"]["output"];
  isStaff: Scalars["Boolean"]["output"];
  isSuperuser: Scalars["Boolean"]["output"];
  lastName: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `MetadataValue`. */
  metadataValuesByUserId: MetadataValuesConnection;
  /** Reads and enables pagination through a set of `Notification`. */
  notificationsByUserId: NotificationsConnection;
  /** Reads and enables pagination through a set of `ProjectClonedNotification`. */
  projectClonedNotificationsByUserId: ProjectClonedNotificationsConnection;
  /** Reads and enables pagination through a set of `ProjectSharedNotification`. */
  projectSharedNotificationsBySharerId: ProjectSharedNotificationsConnection;
  /** Reads and enables pagination through a set of `ProjectSharedNotification`. */
  projectSharedNotificationsByUserId: ProjectSharedNotificationsConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByUserId: ProjectsConnection;
  /** Reads and enables pagination through a set of `RecordingGroupFile`. */
  recordingGroupFilesByUserId: RecordingGroupFilesConnection;
  /** Reads and enables pagination through a set of `RoleChangeNotification`. */
  roleChangeNotificationsByUserId: RoleChangeNotificationsConnection;
  /** Reads and enables pagination through a set of `Tag`. */
  tagsByUserId: TagsConnection;
  /** Reads and enables pagination through a set of `Task`. */
  tasksByUserId: TasksConnection;
  /** Reads and enables pagination through a set of `TenantMonthlyConsumedResource`. */
  tenantMonthlyConsumedResourcesByUserId: TenantMonthlyConsumedResourcesConnection;
  /** Reads and enables pagination through a set of `TenantUserMembershipNotification`. */
  tenantUserMembershipNotificationsByInviterId: TenantUserMembershipNotificationsConnection;
  /** Reads and enables pagination through a set of `TenantUserMembershipNotification`. */
  tenantUserMembershipNotificationsByUserId: TenantUserMembershipNotificationsConnection;
  username: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `WorkflowVersion`. */
  workflowVersionsByUserId: WorkflowVersionsConnection;
  /** Reads and enables pagination through a set of `Workflow`. */
  workflowsByUserId: WorkflowsConnection;
};

export type ApplicationUserAnalysisTableGroupsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableGroupCondition>;
  filter?: InputMaybe<AnalysisTableGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTableGroupsOrderBy>>;
};

export type ApplicationUserAnalysisTablesByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableCondition>;
  filter?: InputMaybe<AnalysisTableFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTablesOrderBy>>;
};

export type ApplicationUserApplicationUserGroupsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserGroupCondition>;
  filter?: InputMaybe<ApplicationUserGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserGroupsOrderBy>>;
};

export type ApplicationUserApplicationUserMembershipsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserMembershipCondition>;
  filter?: InputMaybe<ApplicationUserMembershipFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserMembershipsOrderBy>>;
};

export type ApplicationUserApplicationUserMetadataByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserMetadatumCondition>;
  filter?: InputMaybe<ApplicationUserMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserMetadataOrderBy>>;
};

export type ApplicationUserApplicationUserSessionsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserSessionCondition>;
  filter?: InputMaybe<ApplicationUserSessionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserSessionsOrderBy>>;
};

export type ApplicationUserApplicationUserUserPermissionsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserUserPermissionCondition>;
  filter?: InputMaybe<ApplicationUserUserPermissionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserUserPermissionsOrderBy>>;
};

export type ApplicationUserAvatarsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AvatarCondition>;
  filter?: InputMaybe<AvatarFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AvatarsOrderBy>>;
};

export type ApplicationUserDatasetsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetCondition>;
  filter?: InputMaybe<DatasetFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetsOrderBy>>;
};

export type ApplicationUserDjangoAdminLogsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DjangoAdminLogCondition>;
  filter?: InputMaybe<DjangoAdminLogFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DjangoAdminLogsOrderBy>>;
};

export type ApplicationUserEulaVersionUserAcceptancesByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<EulaVersionUserAcceptanceCondition>;
  filter?: InputMaybe<EulaVersionUserAcceptanceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<EulaVersionUserAcceptancesOrderBy>>;
};

export type ApplicationUserExplorerQueriesByCreatedByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ExplorerQueryCondition>;
  filter?: InputMaybe<ExplorerQueryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExplorerQueriesOrderBy>>;
};

export type ApplicationUserExplorerQueryfavoritesByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ExplorerQueryfavoriteCondition>;
  filter?: InputMaybe<ExplorerQueryfavoriteFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExplorerQueryfavoritesOrderBy>>;
};

export type ApplicationUserExplorerQuerylogsByRunByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ExplorerQuerylogCondition>;
  filter?: InputMaybe<ExplorerQuerylogFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExplorerQuerylogsOrderBy>>;
};

export type ApplicationUserFileActivitiesByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileActivityCondition>;
  filter?: InputMaybe<FileActivityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileActivitiesOrderBy>>;
};

export type ApplicationUserFileOutputGroupsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileOutputGroupCondition>;
  filter?: InputMaybe<FileOutputGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileOutputGroupsOrderBy>>;
};

export type ApplicationUserFileProcessingNotificationsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileProcessingNotificationCondition>;
  filter?: InputMaybe<FileProcessingNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileProcessingNotificationsOrderBy>>;
};

export type ApplicationUserFileRecordingGroupsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileRecordingGroupCondition>;
  filter?: InputMaybe<FileRecordingGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileRecordingGroupsOrderBy>>;
};

export type ApplicationUserFilesByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileCondition>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type ApplicationUserMetadataValuesByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MetadataValueCondition>;
  filter?: InputMaybe<MetadataValueFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MetadataValuesOrderBy>>;
};

export type ApplicationUserNotificationsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NotificationCondition>;
  filter?: InputMaybe<NotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NotificationsOrderBy>>;
};

export type ApplicationUserProjectClonedNotificationsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProjectClonedNotificationCondition>;
  filter?: InputMaybe<ProjectClonedNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProjectClonedNotificationsOrderBy>>;
};

export type ApplicationUserProjectSharedNotificationsBySharerIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProjectSharedNotificationCondition>;
  filter?: InputMaybe<ProjectSharedNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProjectSharedNotificationsOrderBy>>;
};

export type ApplicationUserProjectSharedNotificationsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProjectSharedNotificationCondition>;
  filter?: InputMaybe<ProjectSharedNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProjectSharedNotificationsOrderBy>>;
};

export type ApplicationUserProjectsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

export type ApplicationUserRecordingGroupFilesByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<RecordingGroupFileCondition>;
  filter?: InputMaybe<RecordingGroupFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RecordingGroupFilesOrderBy>>;
};

export type ApplicationUserRoleChangeNotificationsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<RoleChangeNotificationCondition>;
  filter?: InputMaybe<RoleChangeNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RoleChangeNotificationsOrderBy>>;
};

export type ApplicationUserTagsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TagCondition>;
  filter?: InputMaybe<TagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
};

export type ApplicationUserTasksByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskCondition>;
  filter?: InputMaybe<TaskFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
};

export type ApplicationUserTenantMonthlyConsumedResourcesByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantMonthlyConsumedResourceCondition>;
  filter?: InputMaybe<TenantMonthlyConsumedResourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantMonthlyConsumedResourcesOrderBy>>;
};

export type ApplicationUserTenantUserMembershipNotificationsByInviterIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantUserMembershipNotificationCondition>;
  filter?: InputMaybe<TenantUserMembershipNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantUserMembershipNotificationsOrderBy>>;
};

export type ApplicationUserTenantUserMembershipNotificationsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantUserMembershipNotificationCondition>;
  filter?: InputMaybe<TenantUserMembershipNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantUserMembershipNotificationsOrderBy>>;
};

export type ApplicationUserWorkflowVersionsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowVersionCondition>;
  filter?: InputMaybe<WorkflowVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowVersionsOrderBy>>;
};

export type ApplicationUserWorkflowsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowCondition>;
  filter?: InputMaybe<WorkflowFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowsOrderBy>>;
};

/** The fields on `applicationUser` to look up the row to connect. */
export type ApplicationUserApplicationUserEmailKeyConnect = {
  email: Scalars["String"]["input"];
};

/** The fields on `applicationUser` to look up the row to delete. */
export type ApplicationUserApplicationUserEmailKeyDelete = {
  email: Scalars["String"]["input"];
};

/** The fields on `applicationUser` to look up the row to connect. */
export type ApplicationUserApplicationUserPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `applicationUser` to look up the row to delete. */
export type ApplicationUserApplicationUserPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `applicationUser` to look up the row to connect. */
export type ApplicationUserApplicationUserUsernameKeyConnect = {
  username: Scalars["String"]["input"];
};

/** The fields on `applicationUser` to look up the row to delete. */
export type ApplicationUserApplicationUserUsernameKeyDelete = {
  username: Scalars["String"]["input"];
};

/** Input for the nested mutation of `tenantUserexecutor` in the `ApplicationUserInput` mutation. */
export type ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput = {
  /** The primary key(s) for `tenantUserexecutor` for the far side of the relationship. */
  connectById?: InputMaybe<TenantUserexecutorTenantUserexecutorPkeyConnect>;
  /** The primary key(s) for `tenantUserexecutor` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantUserexecutorTenantUserexecutorPkeyDelete>;
};

/** Input for the nested mutation of `applicationUser` in the `TenantUserexecutorInput` mutation. */
export type ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInverseInput =
  {
    /** The primary key(s) for `applicationUser` for the far side of the relationship. */
    connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
    /** The primary key(s) for `applicationUser` for the far side of the relationship. */
    connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
    /** The primary key(s) for `applicationUser` for the far side of the relationship. */
    connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
    /** The primary key(s) for `applicationUser` for the far side of the relationship. */
    deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
    /** The primary key(s) for `applicationUser` for the far side of the relationship. */
    deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
    /** The primary key(s) for `applicationUser` for the far side of the relationship. */
    deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
    /** Flag indicating whether all other `applicationUser` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

/** A filter to be used against `ApplicationUser` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserFilter = {
  /** Filter by the object’s `analysisTableGroupsByUserId` relation. */
  analysisTableGroupsByUserId?: InputMaybe<ApplicationUserToManyAnalysisTableGroupFilter>;
  /** Some related `analysisTableGroupsByUserId` exist. */
  analysisTableGroupsByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `analysisTablesByUserId` relation. */
  analysisTablesByUserId?: InputMaybe<ApplicationUserToManyAnalysisTableFilter>;
  /** Some related `analysisTablesByUserId` exist. */
  analysisTablesByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ApplicationUserFilter>>;
  /** Filter by the object’s `applicationUserGroupsByUserId` relation. */
  applicationUserGroupsByUserId?: InputMaybe<ApplicationUserToManyApplicationUserGroupFilter>;
  /** Some related `applicationUserGroupsByUserId` exist. */
  applicationUserGroupsByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `applicationUserMembershipsByUserId` relation. */
  applicationUserMembershipsByUserId?: InputMaybe<ApplicationUserToManyApplicationUserMembershipFilter>;
  /** Some related `applicationUserMembershipsByUserId` exist. */
  applicationUserMembershipsByUserIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `applicationUserMetadataByUserId` relation. */
  applicationUserMetadataByUserId?: InputMaybe<ApplicationUserToManyApplicationUserMetadatumFilter>;
  /** Some related `applicationUserMetadataByUserId` exist. */
  applicationUserMetadataByUserIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `applicationUserSessionsByUserId` relation. */
  applicationUserSessionsByUserId?: InputMaybe<ApplicationUserToManyApplicationUserSessionFilter>;
  /** Some related `applicationUserSessionsByUserId` exist. */
  applicationUserSessionsByUserIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `applicationUserUserPermissionsByUserId` relation. */
  applicationUserUserPermissionsByUserId?: InputMaybe<ApplicationUserToManyApplicationUserUserPermissionFilter>;
  /** Some related `applicationUserUserPermissionsByUserId` exist. */
  applicationUserUserPermissionsByUserIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `avatarByUserId` relation. */
  avatarByUserId?: InputMaybe<AvatarFilter>;
  /** A related `avatarByUserId` exists. */
  avatarByUserIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `cognitoTenantKey` field. */
  cognitoTenantKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `created` field. */
  created?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `datasetsByUserId` relation. */
  datasetsByUserId?: InputMaybe<ApplicationUserToManyDatasetFilter>;
  /** Some related `datasetsByUserId` exist. */
  datasetsByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `djangoAdminLogsByUserId` relation. */
  djangoAdminLogsByUserId?: InputMaybe<ApplicationUserToManyDjangoAdminLogFilter>;
  /** Some related `djangoAdminLogsByUserId` exist. */
  djangoAdminLogsByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `eulaVersionUserAcceptancesByUserId` relation. */
  eulaVersionUserAcceptancesByUserId?: InputMaybe<ApplicationUserToManyEulaVersionUserAcceptanceFilter>;
  /** Some related `eulaVersionUserAcceptancesByUserId` exist. */
  eulaVersionUserAcceptancesByUserIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `explorerQueriesByCreatedByUserId` relation. */
  explorerQueriesByCreatedByUserId?: InputMaybe<ApplicationUserToManyExplorerQueryFilter>;
  /** Some related `explorerQueriesByCreatedByUserId` exist. */
  explorerQueriesByCreatedByUserIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `explorerQueryfavoritesByUserId` relation. */
  explorerQueryfavoritesByUserId?: InputMaybe<ApplicationUserToManyExplorerQueryfavoriteFilter>;
  /** Some related `explorerQueryfavoritesByUserId` exist. */
  explorerQueryfavoritesByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `explorerQuerylogsByRunByUserId` relation. */
  explorerQuerylogsByRunByUserId?: InputMaybe<ApplicationUserToManyExplorerQuerylogFilter>;
  /** Some related `explorerQuerylogsByRunByUserId` exist. */
  explorerQuerylogsByRunByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fileActivitiesByUserId` relation. */
  fileActivitiesByUserId?: InputMaybe<ApplicationUserToManyFileActivityFilter>;
  /** Some related `fileActivitiesByUserId` exist. */
  fileActivitiesByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fileOutputGroupsByUserId` relation. */
  fileOutputGroupsByUserId?: InputMaybe<ApplicationUserToManyFileOutputGroupFilter>;
  /** Some related `fileOutputGroupsByUserId` exist. */
  fileOutputGroupsByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fileProcessingNotificationsByUserId` relation. */
  fileProcessingNotificationsByUserId?: InputMaybe<ApplicationUserToManyFileProcessingNotificationFilter>;
  /** Some related `fileProcessingNotificationsByUserId` exist. */
  fileProcessingNotificationsByUserIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `fileRecordingGroupsByUserId` relation. */
  fileRecordingGroupsByUserId?: InputMaybe<ApplicationUserToManyFileRecordingGroupFilter>;
  /** Some related `fileRecordingGroupsByUserId` exist. */
  fileRecordingGroupsByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `filesByUserId` relation. */
  filesByUserId?: InputMaybe<ApplicationUserToManyFileFilter>;
  /** Some related `filesByUserId` exist. */
  filesByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `firstName` field. */
  firstName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `globalUserId` field. */
  globalUserId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `homeRegion` field. */
  homeRegion?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `internal` field. */
  internal?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isStaff` field. */
  isStaff?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSuperuser` field. */
  isSuperuser?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metadataValuesByUserId` relation. */
  metadataValuesByUserId?: InputMaybe<ApplicationUserToManyMetadataValueFilter>;
  /** Some related `metadataValuesByUserId` exist. */
  metadataValuesByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Negates the expression. */
  not?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `notificationsByUserId` relation. */
  notificationsByUserId?: InputMaybe<ApplicationUserToManyNotificationFilter>;
  /** Some related `notificationsByUserId` exist. */
  notificationsByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ApplicationUserFilter>>;
  /** Filter by the object’s `projectClonedNotificationsByUserId` relation. */
  projectClonedNotificationsByUserId?: InputMaybe<ApplicationUserToManyProjectClonedNotificationFilter>;
  /** Some related `projectClonedNotificationsByUserId` exist. */
  projectClonedNotificationsByUserIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `projectSharedNotificationsBySharerId` relation. */
  projectSharedNotificationsBySharerId?: InputMaybe<ApplicationUserToManyProjectSharedNotificationFilter>;
  /** Some related `projectSharedNotificationsBySharerId` exist. */
  projectSharedNotificationsBySharerIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `projectSharedNotificationsByUserId` relation. */
  projectSharedNotificationsByUserId?: InputMaybe<ApplicationUserToManyProjectSharedNotificationFilter>;
  /** Some related `projectSharedNotificationsByUserId` exist. */
  projectSharedNotificationsByUserIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `projectsByUserId` relation. */
  projectsByUserId?: InputMaybe<ApplicationUserToManyProjectFilter>;
  /** Some related `projectsByUserId` exist. */
  projectsByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `recordingGroupFilesByUserId` relation. */
  recordingGroupFilesByUserId?: InputMaybe<ApplicationUserToManyRecordingGroupFileFilter>;
  /** Some related `recordingGroupFilesByUserId` exist. */
  recordingGroupFilesByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `roleChangeNotificationsByUserId` relation. */
  roleChangeNotificationsByUserId?: InputMaybe<ApplicationUserToManyRoleChangeNotificationFilter>;
  /** Some related `roleChangeNotificationsByUserId` exist. */
  roleChangeNotificationsByUserIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `tagsByUserId` relation. */
  tagsByUserId?: InputMaybe<ApplicationUserToManyTagFilter>;
  /** Some related `tagsByUserId` exist. */
  tagsByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tasksByUserId` relation. */
  tasksByUserId?: InputMaybe<ApplicationUserToManyTaskFilter>;
  /** Some related `tasksByUserId` exist. */
  tasksByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tenantMonthlyConsumedResourcesByUserId` relation. */
  tenantMonthlyConsumedResourcesByUserId?: InputMaybe<ApplicationUserToManyTenantMonthlyConsumedResourceFilter>;
  /** Some related `tenantMonthlyConsumedResourcesByUserId` exist. */
  tenantMonthlyConsumedResourcesByUserIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `tenantUserMembershipNotificationsByInviterId` relation. */
  tenantUserMembershipNotificationsByInviterId?: InputMaybe<ApplicationUserToManyTenantUserMembershipNotificationFilter>;
  /** Some related `tenantUserMembershipNotificationsByInviterId` exist. */
  tenantUserMembershipNotificationsByInviterIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `tenantUserMembershipNotificationsByUserId` relation. */
  tenantUserMembershipNotificationsByUserId?: InputMaybe<ApplicationUserToManyTenantUserMembershipNotificationFilter>;
  /** Some related `tenantUserMembershipNotificationsByUserId` exist. */
  tenantUserMembershipNotificationsByUserIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
  /** Filter by the object’s `workflowVersionsByUserId` relation. */
  workflowVersionsByUserId?: InputMaybe<ApplicationUserToManyWorkflowVersionFilter>;
  /** Some related `workflowVersionsByUserId` exist. */
  workflowVersionsByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `workflowsByUserId` relation. */
  workflowsByUserId?: InputMaybe<ApplicationUserToManyWorkflowFilter>;
  /** Some related `workflowsByUserId` exist. */
  workflowsByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ApplicationUserGroup = {
  __typename: "ApplicationUserGroup";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserGroup`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** Reads a single `AuthGroup` that is related to this `ApplicationUserGroup`. */
  authGroupByGroupId: Maybe<AuthGroup>;
  groupId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  userId: Scalars["UUID"]["output"];
};

/** The fields on `applicationUserGroup` to look up the row to connect. */
export type ApplicationUserGroupApplicationUserGroupsPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `applicationUserGroup` to look up the row to delete. */
export type ApplicationUserGroupApplicationUserGroupsPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** The fields on `applicationUserGroup` to look up the row to connect. */
export type ApplicationUserGroupApplicationUserGroupsUserIdGroupId3A20Ace4UniqConnect =
  {
    groupId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUserGroup` to look up the row to delete. */
export type ApplicationUserGroupApplicationUserGroupsUserIdGroupId3A20Ace4UniqDelete =
  {
    groupId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/**
 * A condition to be used against `ApplicationUserGroup` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ApplicationUserGroupCondition = {
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `ApplicationUserGroup` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserGroupFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ApplicationUserGroupFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `authGroupByGroupId` relation. */
  authGroupByGroupId?: InputMaybe<AuthGroupFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ApplicationUserGroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ApplicationUserGroupFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `ApplicationUserGroup` */
export type ApplicationUserGroupInput = {
  applicationUserToUserId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInput>;
  authGroupToGroupId?: InputMaybe<ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInput>;
  groupId?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `applicationUserGroup` to look up the row to update. */
export type ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingApplicationUserGroupsPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUserGroup` being updated. */
    applicationUserGroupPatch: UpdateApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `applicationUserGroup` to look up the row to update. */
export type ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingApplicationUserGroupsUserIdGroupId3A20Ace4UniqUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUserGroup` being updated. */
    applicationUserGroupPatch: UpdateApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdPatch;
    groupId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUserGroup` to look up the row to update. */
export type ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserGroupsPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUserGroup` being updated. */
    applicationUserGroupPatch: UpdateApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `applicationUserGroup` to look up the row to update. */
export type ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserGroupsUserIdGroupId3A20Ace4UniqUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUserGroup` being updated. */
    applicationUserGroupPatch: UpdateApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdPatch;
    groupId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/** Represents an update to a `ApplicationUserGroup`. Fields that are set will be updated. */
export type ApplicationUserGroupPatch = {
  applicationUserToUserId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInput>;
  authGroupToGroupId?: InputMaybe<ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInput>;
  groupId?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A connection to a list of `ApplicationUserGroup` values. */
export type ApplicationUserGroupsConnection = {
  __typename: "ApplicationUserGroupsConnection";
  /** A list of edges which contains the `ApplicationUserGroup` and cursor to aid in pagination. */
  edges: Array<ApplicationUserGroupsEdge>;
  /** A list of `ApplicationUserGroup` objects. */
  nodes: Array<ApplicationUserGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ApplicationUserGroup` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ApplicationUserGroup` edge in the connection. */
export type ApplicationUserGroupsEdge = {
  __typename: "ApplicationUserGroupsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ApplicationUserGroup` at the end of the edge. */
  node: ApplicationUserGroup;
};

/** The `applicationUserGroup` to be created by this mutation. */
export type ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdApplicationUserGroupsCreateInput =
  {
    applicationUserToUserId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInput>;
    authGroupToGroupId?: InputMaybe<ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** The `authGroup` to be created by this mutation. */
export type ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdAuthGroupCreateInput =
  {
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInverseInput>;
    authGroupPermissionsUsingId?: InputMaybe<AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name: Scalars["String"]["input"];
  };

/** Input for the nested mutation of `authGroup` in the `ApplicationUserGroupInput` mutation. */
export type ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInput = {
  /** The primary key(s) for `authGroup` for the far side of the relationship. */
  connectById?: InputMaybe<AuthGroupAuthGroupPkeyConnect>;
  /** The primary key(s) for `authGroup` for the far side of the relationship. */
  connectByName?: InputMaybe<AuthGroupAuthGroupNameKeyConnect>;
  /** A `AuthGroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdAuthGroupCreateInput>;
  /** The primary key(s) for `authGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<AuthGroupAuthGroupPkeyDelete>;
  /** The primary key(s) for `authGroup` for the far side of the relationship. */
  deleteByName?: InputMaybe<AuthGroupAuthGroupNameKeyDelete>;
  /** The primary key(s) and patch data for `authGroup` for the far side of the relationship. */
  updateById?: InputMaybe<AuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingAuthGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `authGroup` for the far side of the relationship. */
  updateByName?: InputMaybe<AuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingAuthGroupNameKeyUpdate>;
};

/** Input for the nested mutation of `applicationUserGroup` in the `AuthGroupInput` mutation. */
export type ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInverseInput = {
  /** The primary key(s) for `applicationUserGroup` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ApplicationUserGroupApplicationUserGroupsPkeyConnect>
  >;
  /** The primary key(s) for `applicationUserGroup` for the far side of the relationship. */
  connectByUserIdAndGroupId?: InputMaybe<
    Array<ApplicationUserGroupApplicationUserGroupsUserIdGroupId3A20Ace4UniqConnect>
  >;
  /** A `ApplicationUserGroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdApplicationUserGroupsCreateInput>
  >;
  /** The primary key(s) for `applicationUserGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<ApplicationUserGroupApplicationUserGroupsPkeyDelete>
  >;
  /** The primary key(s) for `applicationUserGroup` for the far side of the relationship. */
  deleteByUserIdAndGroupId?: InputMaybe<
    Array<ApplicationUserGroupApplicationUserGroupsUserIdGroupId3A20Ace4UniqDelete>
  >;
  /** Flag indicating whether all other `applicationUserGroup` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `applicationUserGroup` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingApplicationUserGroupsPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `applicationUserGroup` for the far side of the relationship. */
  updateByUserIdAndGroupId?: InputMaybe<
    Array<ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingApplicationUserGroupsUserIdGroupId3A20Ace4UniqUpdate>
  >;
};

/** Methods to use when ordering `ApplicationUserGroup`. */
export enum ApplicationUserGroupsOrderBy {
  GroupIdAsc = "GROUP_ID_ASC",
  GroupIdDesc = "GROUP_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** The `applicationUser` to be created by this mutation. */
export type ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** The `applicationUserGroup` to be created by this mutation. */
export type ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdApplicationUserGroupsCreateInput =
  {
    applicationUserToUserId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInput>;
    authGroupToGroupId?: InputMaybe<ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInput>;
    groupId?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** Input for the nested mutation of `applicationUser` in the `ApplicationUserGroupInput` mutation. */
export type ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `applicationUserGroup` in the `ApplicationUserInput` mutation. */
export type ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput =
  {
    /** The primary key(s) for `applicationUserGroup` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<ApplicationUserGroupApplicationUserGroupsPkeyConnect>
    >;
    /** The primary key(s) for `applicationUserGroup` for the far side of the relationship. */
    connectByUserIdAndGroupId?: InputMaybe<
      Array<ApplicationUserGroupApplicationUserGroupsUserIdGroupId3A20Ace4UniqConnect>
    >;
    /** A `ApplicationUserGroupInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdApplicationUserGroupsCreateInput>
    >;
    /** The primary key(s) for `applicationUserGroup` for the far side of the relationship. */
    deleteById?: InputMaybe<
      Array<ApplicationUserGroupApplicationUserGroupsPkeyDelete>
    >;
    /** The primary key(s) for `applicationUserGroup` for the far side of the relationship. */
    deleteByUserIdAndGroupId?: InputMaybe<
      Array<ApplicationUserGroupApplicationUserGroupsUserIdGroupId3A20Ace4UniqDelete>
    >;
    /** Flag indicating whether all other `applicationUserGroup` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `applicationUserGroup` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserGroupsPkeyUpdate>
    >;
    /** The primary key(s) and patch data for `applicationUserGroup` for the far side of the relationship. */
    updateByUserIdAndGroupId?: InputMaybe<
      Array<ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserGroupsUserIdGroupId3A20Ace4UniqUpdate>
    >;
  };

/** An input for mutations affecting `ApplicationUser` */
export type ApplicationUserInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
  applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
  applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
  applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
  avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
  cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
  djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
  email: Scalars["String"]["input"];
  eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
  executorId?: InputMaybe<Scalars["Int"]["input"]>;
  explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
  explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
  explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
  filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
  firstName: Scalars["String"]["input"];
  globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  homeRegion?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  internal: Scalars["Boolean"]["input"];
  isActive: Scalars["Boolean"]["input"];
  isStaff: Scalars["Boolean"]["input"];
  isSuperuser: Scalars["Boolean"]["input"];
  lastName: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
  notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
  projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
  projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
  projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
  projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
  tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
  tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
  tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
  tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
  username: Scalars["String"]["input"];
  workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
};

/** The `applicationUser` to be created by this mutation. */
export type ApplicationUserMemUserId631Fddc5FkApplicatiApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** The `applicationUserMembership` to be created by this mutation. */
export type ApplicationUserMemUserId631Fddc5FkApplicatiApplicationUserMembershipCreateInput =
  {
    applicationUserToUserId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    role: Scalars["Int"]["input"];
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `ApplicationUserMembershipInput` mutation. */
export type ApplicationUserMemUserId631Fddc5FkApplicatiInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `applicationUserMembership` in the `ApplicationUserInput` mutation. */
export type ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput = {
  /** The primary key(s) for `applicationUserMembership` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ApplicationUserMembershipApplicationUserMembershipPkeyConnect>
  >;
  /** The primary key(s) for `applicationUserMembership` for the far side of the relationship. */
  connectByUserIdAndTenantId?: InputMaybe<
    Array<ApplicationUserMembershipUserTenantUniqueConnect>
  >;
  /** A `ApplicationUserMembershipInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ApplicationUserMemUserId631Fddc5FkApplicatiApplicationUserMembershipCreateInput>
  >;
  /** The primary key(s) for `applicationUserMembership` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<ApplicationUserMembershipApplicationUserMembershipPkeyDelete>
  >;
  /** The primary key(s) for `applicationUserMembership` for the far side of the relationship. */
  deleteByUserIdAndTenantId?: InputMaybe<
    Array<ApplicationUserMembershipUserTenantUniqueDelete>
  >;
  /** Flag indicating whether all other `applicationUserMembership` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `applicationUserMembership` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserMembershipPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `applicationUserMembership` for the far side of the relationship. */
  updateByUserIdAndTenantId?: InputMaybe<
    Array<ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingUserTenantUniqueUpdate>
  >;
};

export type ApplicationUserMembership = {
  __typename: "ApplicationUserMembership";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserMembership`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  id: Scalars["Int"]["output"];
  role: Scalars["Int"]["output"];
  /** Reads a single `Tenant` that is related to this `ApplicationUserMembership`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  userId: Scalars["UUID"]["output"];
};

/** The fields on `applicationUserMembership` to look up the row to connect. */
export type ApplicationUserMembershipApplicationUserMembershipPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `applicationUserMembership` to look up the row to delete. */
export type ApplicationUserMembershipApplicationUserMembershipPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/**
 * A condition to be used against `ApplicationUserMembership` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ApplicationUserMembershipCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `role` field. */
  role?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `ApplicationUserMembership` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserMembershipFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ApplicationUserMembershipFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ApplicationUserMembershipFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ApplicationUserMembershipFilter>>;
  /** Filter by the object’s `role` field. */
  role?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `ApplicationUserMembership` */
export type ApplicationUserMembershipInput = {
  applicationUserToUserId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  role: Scalars["Int"]["input"];
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `applicationUserMembership` to look up the row to update. */
export type ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserMembershipPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUserMembership` being updated. */
    applicationUserMembershipPatch: UpdateApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `applicationUserMembership` to look up the row to update. */
export type ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingUserTenantUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUserMembership` being updated. */
    applicationUserMembershipPatch: UpdateApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiPatch;
    tenantId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUserMembership` to look up the row to update. */
export type ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingApplicationUserMembershipPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUserMembership` being updated. */
    applicationUserMembershipPatch: UpdateApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `applicationUserMembership` to look up the row to update. */
export type ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingUserTenantUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUserMembership` being updated. */
    applicationUserMembershipPatch: UpdateApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdPatch;
    tenantId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/** Represents an update to a `ApplicationUserMembership`. Fields that are set will be updated. */
export type ApplicationUserMembershipPatch = {
  applicationUserToUserId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  role?: InputMaybe<Scalars["Int"]["input"]>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `applicationUserMembership` to be created by this mutation. */
export type ApplicationUserMembershipTenantId5E54Da7AFkTenantIdApplicationUserMembershipCreateInput =
  {
    applicationUserToUserId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    role: Scalars["Int"]["input"];
    tenantToTenantId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `tenant` in the `ApplicationUserMembershipInput` mutation. */
export type ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `applicationUserMembership` in the `TenantInput` mutation. */
export type ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput = {
  /** The primary key(s) for `applicationUserMembership` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ApplicationUserMembershipApplicationUserMembershipPkeyConnect>
  >;
  /** The primary key(s) for `applicationUserMembership` for the far side of the relationship. */
  connectByUserIdAndTenantId?: InputMaybe<
    Array<ApplicationUserMembershipUserTenantUniqueConnect>
  >;
  /** A `ApplicationUserMembershipInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdApplicationUserMembershipCreateInput>
  >;
  /** The primary key(s) for `applicationUserMembership` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<ApplicationUserMembershipApplicationUserMembershipPkeyDelete>
  >;
  /** The primary key(s) for `applicationUserMembership` for the far side of the relationship. */
  deleteByUserIdAndTenantId?: InputMaybe<
    Array<ApplicationUserMembershipUserTenantUniqueDelete>
  >;
  /** Flag indicating whether all other `applicationUserMembership` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `applicationUserMembership` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingApplicationUserMembershipPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `applicationUserMembership` for the far side of the relationship. */
  updateByUserIdAndTenantId?: InputMaybe<
    Array<ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingUserTenantUniqueUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type ApplicationUserMembershipTenantId5E54Da7AFkTenantIdTenantCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal: Scalars["Boolean"]["input"];
    key: Scalars["String"]["input"];
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name: Scalars["String"]["input"];
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status: Scalars["Int"]["input"];
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType: Scalars["Int"]["input"];
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** The fields on `applicationUserMembership` to look up the row to connect. */
export type ApplicationUserMembershipUserTenantUniqueConnect = {
  tenantId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The fields on `applicationUserMembership` to look up the row to delete. */
export type ApplicationUserMembershipUserTenantUniqueDelete = {
  tenantId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** A connection to a list of `ApplicationUserMembership` values. */
export type ApplicationUserMembershipsConnection = {
  __typename: "ApplicationUserMembershipsConnection";
  /** A list of edges which contains the `ApplicationUserMembership` and cursor to aid in pagination. */
  edges: Array<ApplicationUserMembershipsEdge>;
  /** A list of `ApplicationUserMembership` objects. */
  nodes: Array<ApplicationUserMembership>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ApplicationUserMembership` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ApplicationUserMembership` edge in the connection. */
export type ApplicationUserMembershipsEdge = {
  __typename: "ApplicationUserMembershipsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ApplicationUserMembership` at the end of the edge. */
  node: ApplicationUserMembership;
};

/** Methods to use when ordering `ApplicationUserMembership`. */
export enum ApplicationUserMembershipsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RoleAsc = "ROLE_ASC",
  RoleDesc = "ROLE_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** The `applicationUser` to be created by this mutation. */
export type ApplicationUserMetUserId897Fbde1FkApplicatiApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** The `applicationUserMetadatum` to be created by this mutation. */
export type ApplicationUserMetUserId897Fbde1FkApplicatiApplicationUserMetadataCreateInput =
  {
    applicationUserToUserId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    key: Scalars["String"]["input"];
    value: Scalars["String"]["input"];
  };

/** Input for the nested mutation of `applicationUser` in the `ApplicationUserMetadatumInput` mutation. */
export type ApplicationUserMetUserId897Fbde1FkApplicatiInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `applicationUserMetadatum` in the `ApplicationUserInput` mutation. */
export type ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput = {
  /** The primary key(s) for `applicationUserMetadatum` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ApplicationUserMetadatumApplicationUserMetadataPkeyConnect>
  >;
  /** The primary key(s) for `applicationUserMetadatum` for the far side of the relationship. */
  connectByUserIdAndKey?: InputMaybe<
    Array<ApplicationUserMetadatumUserKeyConnect>
  >;
  /** A `ApplicationUserMetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ApplicationUserMetUserId897Fbde1FkApplicatiApplicationUserMetadataCreateInput>
  >;
  /** The primary key(s) for `applicationUserMetadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<ApplicationUserMetadatumApplicationUserMetadataPkeyDelete>
  >;
  /** The primary key(s) for `applicationUserMetadatum` for the far side of the relationship. */
  deleteByUserIdAndKey?: InputMaybe<
    Array<ApplicationUserMetadatumUserKeyDelete>
  >;
  /** Flag indicating whether all other `applicationUserMetadatum` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `applicationUserMetadatum` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserMetadataPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `applicationUserMetadatum` for the far side of the relationship. */
  updateByUserIdAndKey?: InputMaybe<
    Array<ApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingUserKeyUpdate>
  >;
};

/** A connection to a list of `ApplicationUserMetadatum` values. */
export type ApplicationUserMetadataConnection = {
  __typename: "ApplicationUserMetadataConnection";
  /** A list of edges which contains the `ApplicationUserMetadatum` and cursor to aid in pagination. */
  edges: Array<ApplicationUserMetadataEdge>;
  /** A list of `ApplicationUserMetadatum` objects. */
  nodes: Array<ApplicationUserMetadatum>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ApplicationUserMetadatum` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ApplicationUserMetadatum` edge in the connection. */
export type ApplicationUserMetadataEdge = {
  __typename: "ApplicationUserMetadataEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ApplicationUserMetadatum` at the end of the edge. */
  node: ApplicationUserMetadatum;
};

/** Methods to use when ordering `ApplicationUserMetadatum`. */
export enum ApplicationUserMetadataOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  KeyAsc = "KEY_ASC",
  KeyDesc = "KEY_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
  ValueAsc = "VALUE_ASC",
  ValueDesc = "VALUE_DESC",
}

export type ApplicationUserMetadatum = {
  __typename: "ApplicationUserMetadatum";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserMetadatum`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  id: Scalars["Int"]["output"];
  key: Scalars["String"]["output"];
  userId: Scalars["UUID"]["output"];
  value: Scalars["String"]["output"];
};

/** The fields on `applicationUserMetadatum` to look up the row to connect. */
export type ApplicationUserMetadatumApplicationUserMetadataPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `applicationUserMetadatum` to look up the row to delete. */
export type ApplicationUserMetadatumApplicationUserMetadataPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/**
 * A condition to be used against `ApplicationUserMetadatum` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ApplicationUserMetadatumCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `ApplicationUserMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserMetadatumFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ApplicationUserMetadatumFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ApplicationUserMetadatumFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ApplicationUserMetadatumFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `ApplicationUserMetadatum` */
export type ApplicationUserMetadatumInput = {
  applicationUserToUserId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key: Scalars["String"]["input"];
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  value: Scalars["String"]["input"];
};

/** The fields on `applicationUserMetadatum` to look up the row to update. */
export type ApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserMetadataPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUserMetadatum` being updated. */
    applicationUserMetadatumPatch: UpdateApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `applicationUserMetadatum` to look up the row to update. */
export type ApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingUserKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUserMetadatum` being updated. */
    applicationUserMetadatumPatch: UpdateApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiPatch;
    key: Scalars["String"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/** Represents an update to a `ApplicationUserMetadatum`. Fields that are set will be updated. */
export type ApplicationUserMetadatumPatch = {
  applicationUserToUserId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/** The fields on `applicationUserMetadatum` to look up the row to connect. */
export type ApplicationUserMetadatumUserKeyConnect = {
  key: Scalars["String"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The fields on `applicationUserMetadatum` to look up the row to delete. */
export type ApplicationUserMetadatumUserKeyDelete = {
  key: Scalars["String"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingApplicationUserEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdPatch;
    email: Scalars["String"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingApplicationUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUser` to look up the row to update. */
export type ApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUser` being updated. */
    applicationUserPatch: UpdateApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdPatch;
    username: Scalars["String"]["input"];
  };

/** Represents an update to a `ApplicationUser`. Fields that are set will be updated. */
export type ApplicationUserPatch = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
  applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
  applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
  applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
  avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
  cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
  djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
  executorId?: InputMaybe<Scalars["Int"]["input"]>;
  explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
  explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
  explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
  filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  homeRegion?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  internal?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
  isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
  notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
  projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
  projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
  projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
  projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
  tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
  tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
  tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
  tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
  username?: InputMaybe<Scalars["String"]["input"]>;
  workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
};

/** The `applicationUser` to be created by this mutation. */
export type ApplicationUserSesUserId2Fb30D0CFkApplicatiApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** The `applicationUserSession` to be created by this mutation. */
export type ApplicationUserSesUserId2Fb30D0CFkApplicatiApplicationUserSessionCreateInput =
  {
    active: Scalars["Boolean"]["input"];
    applicationUserToUserId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInput>;
    datetimeLastActive?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    lastPath?: InputMaybe<Scalars["String"]["input"]>;
    state?: InputMaybe<Scalars["JSON"]["input"]>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `ApplicationUserSessionInput` mutation. */
export type ApplicationUserSesUserId2Fb30D0CFkApplicatiInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `applicationUserSession` in the `ApplicationUserInput` mutation. */
export type ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput = {
  /** The primary key(s) for `applicationUserSession` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ApplicationUserSessionApplicationUserSessionPkeyConnect>
  >;
  /** A `ApplicationUserSessionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ApplicationUserSesUserId2Fb30D0CFkApplicatiApplicationUserSessionCreateInput>
  >;
  /** The primary key(s) for `applicationUserSession` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<ApplicationUserSessionApplicationUserSessionPkeyDelete>
  >;
  /** Flag indicating whether all other `applicationUserSession` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `applicationUserSession` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ApplicationUserSessionOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserSessionPkeyUpdate>
  >;
};

export type ApplicationUserSession = {
  __typename: "ApplicationUserSession";
  active: Scalars["Boolean"]["output"];
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserSession`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  datetimeLastActive: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  lastPath: Maybe<Scalars["String"]["output"]>;
  state: Maybe<Scalars["JSON"]["output"]>;
  /** Reads a single `Tenant` that is related to this `ApplicationUserSession`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Maybe<Scalars["Int"]["output"]>;
  userId: Scalars["UUID"]["output"];
};

/** The fields on `applicationUserSession` to look up the row to connect. */
export type ApplicationUserSessionApplicationUserSessionPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `applicationUserSession` to look up the row to delete. */
export type ApplicationUserSessionApplicationUserSessionPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/**
 * A condition to be used against `ApplicationUserSession` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ApplicationUserSessionCondition = {
  /** Checks for equality with the object’s `active` field. */
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `datetimeLastActive` field. */
  datetimeLastActive?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `lastPath` field. */
  lastPath?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `ApplicationUserSession` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserSessionFilter = {
  /** Filter by the object’s `active` field. */
  active?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ApplicationUserSessionFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `datetimeLastActive` field. */
  datetimeLastActive?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lastPath` field. */
  lastPath?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ApplicationUserSessionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ApplicationUserSessionFilter>>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** A related `tenantByTenantId` exists. */
  tenantByTenantIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `ApplicationUserSession` */
export type ApplicationUserSessionInput = {
  active: Scalars["Boolean"]["input"];
  applicationUserToUserId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInput>;
  datetimeLastActive?: InputMaybe<Scalars["Datetime"]["input"]>;
  id: Scalars["UUID"]["input"];
  lastPath?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["JSON"]["input"]>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `applicationUserSession` to look up the row to update. */
export type ApplicationUserSessionOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserSessionPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUserSession` being updated. */
    applicationUserSessionPatch: UpdateApplicationUserSessionOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUserSession` to look up the row to update. */
export type ApplicationUserSessionOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingApplicationUserSessionPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUserSession` being updated. */
    applicationUserSessionPatch: UpdateApplicationUserSessionOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** Represents an update to a `ApplicationUserSession`. Fields that are set will be updated. */
export type ApplicationUserSessionPatch = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  applicationUserToUserId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInput>;
  datetimeLastActive?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  lastPath?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["JSON"]["input"]>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `applicationUserSession` to be created by this mutation. */
export type ApplicationUserSessionTenantId00A81Cc5FkTenantIdApplicationUserSessionCreateInput =
  {
    active: Scalars["Boolean"]["input"];
    applicationUserToUserId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInput>;
    datetimeLastActive?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    lastPath?: InputMaybe<Scalars["String"]["input"]>;
    state?: InputMaybe<Scalars["JSON"]["input"]>;
    tenantToTenantId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `tenant` in the `ApplicationUserSessionInput` mutation. */
export type ApplicationUserSessionTenantId00A81Cc5FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `applicationUserSession` in the `TenantInput` mutation. */
export type ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput = {
  /** The primary key(s) for `applicationUserSession` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ApplicationUserSessionApplicationUserSessionPkeyConnect>
  >;
  /** A `ApplicationUserSessionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ApplicationUserSessionTenantId00A81Cc5FkTenantIdApplicationUserSessionCreateInput>
  >;
  /** The primary key(s) for `applicationUserSession` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<ApplicationUserSessionApplicationUserSessionPkeyDelete>
  >;
  /** Flag indicating whether all other `applicationUserSession` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `applicationUserSession` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ApplicationUserSessionOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingApplicationUserSessionPkeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type ApplicationUserSessionTenantId00A81Cc5FkTenantIdTenantCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal: Scalars["Boolean"]["input"];
    key: Scalars["String"]["input"];
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name: Scalars["String"]["input"];
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status: Scalars["Int"]["input"];
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType: Scalars["Int"]["input"];
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** A connection to a list of `ApplicationUserSession` values. */
export type ApplicationUserSessionsConnection = {
  __typename: "ApplicationUserSessionsConnection";
  /** A list of edges which contains the `ApplicationUserSession` and cursor to aid in pagination. */
  edges: Array<ApplicationUserSessionsEdge>;
  /** A list of `ApplicationUserSession` objects. */
  nodes: Array<ApplicationUserSession>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ApplicationUserSession` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ApplicationUserSession` edge in the connection. */
export type ApplicationUserSessionsEdge = {
  __typename: "ApplicationUserSessionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ApplicationUserSession` at the end of the edge. */
  node: ApplicationUserSession;
};

/** Methods to use when ordering `ApplicationUserSession`. */
export enum ApplicationUserSessionsOrderBy {
  ActiveAsc = "ACTIVE_ASC",
  ActiveDesc = "ACTIVE_DESC",
  DatetimeLastActiveAsc = "DATETIME_LAST_ACTIVE_ASC",
  DatetimeLastActiveDesc = "DATETIME_LAST_ACTIVE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastPathAsc = "LAST_PATH_ASC",
  LastPathDesc = "LAST_PATH_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StateAsc = "STATE_ASC",
  StateDesc = "STATE_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** A filter to be used against many `AnalysisTable` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyAnalysisTableFilter = {
  /** Every related `AnalysisTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AnalysisTableFilter>;
  /** No related `AnalysisTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AnalysisTableFilter>;
  /** Some related `AnalysisTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AnalysisTableFilter>;
};

/** A filter to be used against many `AnalysisTableGroup` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyAnalysisTableGroupFilter = {
  /** Every related `AnalysisTableGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AnalysisTableGroupFilter>;
  /** No related `AnalysisTableGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AnalysisTableGroupFilter>;
  /** Some related `AnalysisTableGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AnalysisTableGroupFilter>;
};

/** A filter to be used against many `ApplicationUserGroup` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyApplicationUserGroupFilter = {
  /** Every related `ApplicationUserGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ApplicationUserGroupFilter>;
  /** No related `ApplicationUserGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ApplicationUserGroupFilter>;
  /** Some related `ApplicationUserGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ApplicationUserGroupFilter>;
};

/** A filter to be used against many `ApplicationUserMembership` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyApplicationUserMembershipFilter = {
  /** Every related `ApplicationUserMembership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ApplicationUserMembershipFilter>;
  /** No related `ApplicationUserMembership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ApplicationUserMembershipFilter>;
  /** Some related `ApplicationUserMembership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ApplicationUserMembershipFilter>;
};

/** A filter to be used against many `ApplicationUserMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyApplicationUserMetadatumFilter = {
  /** Every related `ApplicationUserMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ApplicationUserMetadatumFilter>;
  /** No related `ApplicationUserMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ApplicationUserMetadatumFilter>;
  /** Some related `ApplicationUserMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ApplicationUserMetadatumFilter>;
};

/** A filter to be used against many `ApplicationUserSession` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyApplicationUserSessionFilter = {
  /** Every related `ApplicationUserSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ApplicationUserSessionFilter>;
  /** No related `ApplicationUserSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ApplicationUserSessionFilter>;
  /** Some related `ApplicationUserSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ApplicationUserSessionFilter>;
};

/** A filter to be used against many `ApplicationUserUserPermission` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyApplicationUserUserPermissionFilter = {
  /** Every related `ApplicationUserUserPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ApplicationUserUserPermissionFilter>;
  /** No related `ApplicationUserUserPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ApplicationUserUserPermissionFilter>;
  /** Some related `ApplicationUserUserPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ApplicationUserUserPermissionFilter>;
};

/** A filter to be used against many `Dataset` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyDatasetFilter = {
  /** Every related `Dataset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetFilter>;
  /** No related `Dataset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetFilter>;
  /** Some related `Dataset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetFilter>;
};

/** A filter to be used against many `DjangoAdminLog` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyDjangoAdminLogFilter = {
  /** Every related `DjangoAdminLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DjangoAdminLogFilter>;
  /** No related `DjangoAdminLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DjangoAdminLogFilter>;
  /** Some related `DjangoAdminLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DjangoAdminLogFilter>;
};

/** A filter to be used against many `EulaVersionUserAcceptance` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyEulaVersionUserAcceptanceFilter = {
  /** Every related `EulaVersionUserAcceptance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<EulaVersionUserAcceptanceFilter>;
  /** No related `EulaVersionUserAcceptance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<EulaVersionUserAcceptanceFilter>;
  /** Some related `EulaVersionUserAcceptance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<EulaVersionUserAcceptanceFilter>;
};

/** A filter to be used against many `ExplorerQuery` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyExplorerQueryFilter = {
  /** Every related `ExplorerQuery` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ExplorerQueryFilter>;
  /** No related `ExplorerQuery` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ExplorerQueryFilter>;
  /** Some related `ExplorerQuery` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ExplorerQueryFilter>;
};

/** A filter to be used against many `ExplorerQueryfavorite` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyExplorerQueryfavoriteFilter = {
  /** Every related `ExplorerQueryfavorite` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ExplorerQueryfavoriteFilter>;
  /** No related `ExplorerQueryfavorite` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ExplorerQueryfavoriteFilter>;
  /** Some related `ExplorerQueryfavorite` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ExplorerQueryfavoriteFilter>;
};

/** A filter to be used against many `ExplorerQuerylog` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyExplorerQuerylogFilter = {
  /** Every related `ExplorerQuerylog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ExplorerQuerylogFilter>;
  /** No related `ExplorerQuerylog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ExplorerQuerylogFilter>;
  /** Some related `ExplorerQuerylog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ExplorerQuerylogFilter>;
};

/** A filter to be used against many `FileActivity` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyFileActivityFilter = {
  /** Every related `FileActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileActivityFilter>;
  /** No related `FileActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileActivityFilter>;
  /** Some related `FileActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileActivityFilter>;
};

/** A filter to be used against many `File` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyFileFilter = {
  /** Every related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileFilter>;
  /** No related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileFilter>;
  /** Some related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileFilter>;
};

/** A filter to be used against many `FileOutputGroup` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyFileOutputGroupFilter = {
  /** Every related `FileOutputGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileOutputGroupFilter>;
  /** No related `FileOutputGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileOutputGroupFilter>;
  /** Some related `FileOutputGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileOutputGroupFilter>;
};

/** A filter to be used against many `FileProcessingNotification` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyFileProcessingNotificationFilter = {
  /** Every related `FileProcessingNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileProcessingNotificationFilter>;
  /** No related `FileProcessingNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileProcessingNotificationFilter>;
  /** Some related `FileProcessingNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileProcessingNotificationFilter>;
};

/** A filter to be used against many `FileRecordingGroup` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyFileRecordingGroupFilter = {
  /** Every related `FileRecordingGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileRecordingGroupFilter>;
  /** No related `FileRecordingGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileRecordingGroupFilter>;
  /** Some related `FileRecordingGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileRecordingGroupFilter>;
};

/** A filter to be used against many `MetadataValue` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyMetadataValueFilter = {
  /** Every related `MetadataValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MetadataValueFilter>;
  /** No related `MetadataValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MetadataValueFilter>;
  /** Some related `MetadataValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MetadataValueFilter>;
};

/** A filter to be used against many `Notification` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyNotificationFilter = {
  /** Every related `Notification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NotificationFilter>;
  /** No related `Notification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NotificationFilter>;
  /** Some related `Notification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NotificationFilter>;
};

/** A filter to be used against many `ProjectClonedNotification` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyProjectClonedNotificationFilter = {
  /** Every related `ProjectClonedNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectClonedNotificationFilter>;
  /** No related `ProjectClonedNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectClonedNotificationFilter>;
  /** Some related `ProjectClonedNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectClonedNotificationFilter>;
};

/** A filter to be used against many `Project` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyProjectFilter = {
  /** Every related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectFilter>;
  /** No related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectFilter>;
  /** Some related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectFilter>;
};

/** A filter to be used against many `ProjectSharedNotification` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyProjectSharedNotificationFilter = {
  /** Every related `ProjectSharedNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectSharedNotificationFilter>;
  /** No related `ProjectSharedNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectSharedNotificationFilter>;
  /** Some related `ProjectSharedNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectSharedNotificationFilter>;
};

/** A filter to be used against many `RecordingGroupFile` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyRecordingGroupFileFilter = {
  /** Every related `RecordingGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<RecordingGroupFileFilter>;
  /** No related `RecordingGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<RecordingGroupFileFilter>;
  /** Some related `RecordingGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<RecordingGroupFileFilter>;
};

/** A filter to be used against many `RoleChangeNotification` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyRoleChangeNotificationFilter = {
  /** Every related `RoleChangeNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<RoleChangeNotificationFilter>;
  /** No related `RoleChangeNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<RoleChangeNotificationFilter>;
  /** Some related `RoleChangeNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<RoleChangeNotificationFilter>;
};

/** A filter to be used against many `Tag` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyTagFilter = {
  /** Every related `Tag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TagFilter>;
  /** No related `Tag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TagFilter>;
  /** Some related `Tag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TagFilter>;
};

/** A filter to be used against many `Task` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyTaskFilter = {
  /** Every related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TaskFilter>;
  /** No related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TaskFilter>;
  /** Some related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TaskFilter>;
};

/** A filter to be used against many `TenantMonthlyConsumedResource` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyTenantMonthlyConsumedResourceFilter = {
  /** Every related `TenantMonthlyConsumedResource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TenantMonthlyConsumedResourceFilter>;
  /** No related `TenantMonthlyConsumedResource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TenantMonthlyConsumedResourceFilter>;
  /** Some related `TenantMonthlyConsumedResource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TenantMonthlyConsumedResourceFilter>;
};

/** A filter to be used against many `TenantUserMembershipNotification` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyTenantUserMembershipNotificationFilter = {
  /** Every related `TenantUserMembershipNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TenantUserMembershipNotificationFilter>;
  /** No related `TenantUserMembershipNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TenantUserMembershipNotificationFilter>;
  /** Some related `TenantUserMembershipNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TenantUserMembershipNotificationFilter>;
};

/** A filter to be used against many `Workflow` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyWorkflowFilter = {
  /** Every related `Workflow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowFilter>;
  /** No related `Workflow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowFilter>;
  /** Some related `Workflow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowFilter>;
};

/** A filter to be used against many `WorkflowVersion` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserToManyWorkflowVersionFilter = {
  /** Every related `WorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowVersionFilter>;
  /** No related `WorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowVersionFilter>;
  /** Some related `WorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowVersionFilter>;
};

/** The `applicationUserUserPermission` to be created by this mutation. */
export type ApplicationUserUsePermissionIdDbf9693DFkAuthPermApplicationUserUserPermissionsCreateInput =
  {
    applicationUserToUserId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInput>;
    authPermissionToPermissionId?: InputMaybe<ApplicationUserUsePermissionIdDbf9693DFkAuthPermInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** The `authPermission` to be created by this mutation. */
export type ApplicationUserUsePermissionIdDbf9693DFkAuthPermAuthPermissionCreateInput =
  {
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUsePermissionIdDbf9693DFkAuthPermInverseInput>;
    authGroupPermissionsUsingId?: InputMaybe<AuthGroupPermissioPermissionId84C5C92EFkAuthPermInverseInput>;
    codename: Scalars["String"]["input"];
    contentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
    djangoContentTypeToContentTypeId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name: Scalars["String"]["input"];
  };

/** Input for the nested mutation of `authPermission` in the `ApplicationUserUserPermissionInput` mutation. */
export type ApplicationUserUsePermissionIdDbf9693DFkAuthPermInput = {
  /** The primary key(s) for `authPermission` for the far side of the relationship. */
  connectByContentTypeIdAndCodename?: InputMaybe<AuthPermissionAuthPermissionContentTypeIdCodename01Ab375AUniqConnect>;
  /** The primary key(s) for `authPermission` for the far side of the relationship. */
  connectById?: InputMaybe<AuthPermissionAuthPermissionPkeyConnect>;
  /** A `AuthPermissionInput` object that will be created and connected to this object. */
  create?: InputMaybe<ApplicationUserUsePermissionIdDbf9693DFkAuthPermAuthPermissionCreateInput>;
  /** The primary key(s) for `authPermission` for the far side of the relationship. */
  deleteByContentTypeIdAndCodename?: InputMaybe<AuthPermissionAuthPermissionContentTypeIdCodename01Ab375AUniqDelete>;
  /** The primary key(s) for `authPermission` for the far side of the relationship. */
  deleteById?: InputMaybe<AuthPermissionAuthPermissionPkeyDelete>;
  /** The primary key(s) and patch data for `authPermission` for the far side of the relationship. */
  updateByContentTypeIdAndCodename?: InputMaybe<AuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingAuthPermissionContentTypeIdCodename01Ab375AUniqUpdate>;
  /** The primary key(s) and patch data for `authPermission` for the far side of the relationship. */
  updateById?: InputMaybe<AuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingAuthPermissionPkeyUpdate>;
};

/** Input for the nested mutation of `applicationUserUserPermission` in the `AuthPermissionInput` mutation. */
export type ApplicationUserUsePermissionIdDbf9693DFkAuthPermInverseInput = {
  /** The primary key(s) for `applicationUserUserPermission` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ApplicationUserUserPermissionApplicationUserUserPermissionsPkeyConnect>
  >;
  /** The primary key(s) for `applicationUserUserPermission` for the far side of the relationship. */
  connectByUserIdAndPermissionId?: InputMaybe<
    Array<ApplicationUserUserPermissionApplicationUserUserPeUserIdPermissionId52D58A70UniqConnect>
  >;
  /** A `ApplicationUserUserPermissionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ApplicationUserUsePermissionIdDbf9693DFkAuthPermApplicationUserUserPermissionsCreateInput>
  >;
  /** The primary key(s) for `applicationUserUserPermission` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<ApplicationUserUserPermissionApplicationUserUserPermissionsPkeyDelete>
  >;
  /** The primary key(s) for `applicationUserUserPermission` for the far side of the relationship. */
  deleteByUserIdAndPermissionId?: InputMaybe<
    Array<ApplicationUserUserPermissionApplicationUserUserPeUserIdPermissionId52D58A70UniqDelete>
  >;
  /** Flag indicating whether all other `applicationUserUserPermission` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `applicationUserUserPermission` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingApplicationUserUserPermissionsPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `applicationUserUserPermission` for the far side of the relationship. */
  updateByUserIdAndPermissionId?: InputMaybe<
    Array<ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingApplicationUserUserPeUserIdPermissionId52D58A70UniqUpdate>
  >;
};

/** The `applicationUser` to be created by this mutation. */
export type ApplicationUserUseUserIdE643F548FkApplicatiApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** The `applicationUserUserPermission` to be created by this mutation. */
export type ApplicationUserUseUserIdE643F548FkApplicatiApplicationUserUserPermissionsCreateInput =
  {
    applicationUserToUserId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInput>;
    authPermissionToPermissionId?: InputMaybe<ApplicationUserUsePermissionIdDbf9693DFkAuthPermInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    permissionId?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** Input for the nested mutation of `applicationUser` in the `ApplicationUserUserPermissionInput` mutation. */
export type ApplicationUserUseUserIdE643F548FkApplicatiInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `applicationUserUserPermission` in the `ApplicationUserInput` mutation. */
export type ApplicationUserUseUserIdE643F548FkApplicatiInverseInput = {
  /** The primary key(s) for `applicationUserUserPermission` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ApplicationUserUserPermissionApplicationUserUserPermissionsPkeyConnect>
  >;
  /** The primary key(s) for `applicationUserUserPermission` for the far side of the relationship. */
  connectByUserIdAndPermissionId?: InputMaybe<
    Array<ApplicationUserUserPermissionApplicationUserUserPeUserIdPermissionId52D58A70UniqConnect>
  >;
  /** A `ApplicationUserUserPermissionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ApplicationUserUseUserIdE643F548FkApplicatiApplicationUserUserPermissionsCreateInput>
  >;
  /** The primary key(s) for `applicationUserUserPermission` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<ApplicationUserUserPermissionApplicationUserUserPermissionsPkeyDelete>
  >;
  /** The primary key(s) for `applicationUserUserPermission` for the far side of the relationship. */
  deleteByUserIdAndPermissionId?: InputMaybe<
    Array<ApplicationUserUserPermissionApplicationUserUserPeUserIdPermissionId52D58A70UniqDelete>
  >;
  /** Flag indicating whether all other `applicationUserUserPermission` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `applicationUserUserPermission` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserUserPermissionsPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `applicationUserUserPermission` for the far side of the relationship. */
  updateByUserIdAndPermissionId?: InputMaybe<
    Array<ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserUserPeUserIdPermissionId52D58A70UniqUpdate>
  >;
};

export type ApplicationUserUserPermission = {
  __typename: "ApplicationUserUserPermission";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserUserPermission`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** Reads a single `AuthPermission` that is related to this `ApplicationUserUserPermission`. */
  authPermissionByPermissionId: Maybe<AuthPermission>;
  id: Scalars["Int"]["output"];
  permissionId: Scalars["Int"]["output"];
  userId: Scalars["UUID"]["output"];
};

/** The fields on `applicationUserUserPermission` to look up the row to connect. */
export type ApplicationUserUserPermissionApplicationUserUserPeUserIdPermissionId52D58A70UniqConnect =
  {
    permissionId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUserUserPermission` to look up the row to delete. */
export type ApplicationUserUserPermissionApplicationUserUserPeUserIdPermissionId52D58A70UniqDelete =
  {
    permissionId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUserUserPermission` to look up the row to connect. */
export type ApplicationUserUserPermissionApplicationUserUserPermissionsPkeyConnect =
  {
    id: Scalars["Int"]["input"];
  };

/** The fields on `applicationUserUserPermission` to look up the row to delete. */
export type ApplicationUserUserPermissionApplicationUserUserPermissionsPkeyDelete =
  {
    id: Scalars["Int"]["input"];
  };

/**
 * A condition to be used against `ApplicationUserUserPermission` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ApplicationUserUserPermissionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `permissionId` field. */
  permissionId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `ApplicationUserUserPermission` object types. All fields are combined with a logical ‘and.’ */
export type ApplicationUserUserPermissionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ApplicationUserUserPermissionFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `authPermissionByPermissionId` relation. */
  authPermissionByPermissionId?: InputMaybe<AuthPermissionFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ApplicationUserUserPermissionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ApplicationUserUserPermissionFilter>>;
  /** Filter by the object’s `permissionId` field. */
  permissionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `ApplicationUserUserPermission` */
export type ApplicationUserUserPermissionInput = {
  applicationUserToUserId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInput>;
  authPermissionToPermissionId?: InputMaybe<ApplicationUserUsePermissionIdDbf9693DFkAuthPermInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  permissionId?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `applicationUserUserPermission` to look up the row to update. */
export type ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingApplicationUserUserPeUserIdPermissionId52D58A70UniqUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUserUserPermission` being updated. */
    applicationUserUserPermissionPatch: UpdateApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermPatch;
    permissionId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUserUserPermission` to look up the row to update. */
export type ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingApplicationUserUserPermissionsPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUserUserPermission` being updated. */
    applicationUserUserPermissionPatch: UpdateApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `applicationUserUserPermission` to look up the row to update. */
export type ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserUserPeUserIdPermissionId52D58A70UniqUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUserUserPermission` being updated. */
    applicationUserUserPermissionPatch: UpdateApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiPatch;
    permissionId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/** The fields on `applicationUserUserPermission` to look up the row to update. */
export type ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserUserPermissionsPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `applicationUserUserPermission` being updated. */
    applicationUserUserPermissionPatch: UpdateApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiPatch;
    id: Scalars["Int"]["input"];
  };

/** Represents an update to a `ApplicationUserUserPermission`. Fields that are set will be updated. */
export type ApplicationUserUserPermissionPatch = {
  applicationUserToUserId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInput>;
  authPermissionToPermissionId?: InputMaybe<ApplicationUserUsePermissionIdDbf9693DFkAuthPermInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  permissionId?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A connection to a list of `ApplicationUserUserPermission` values. */
export type ApplicationUserUserPermissionsConnection = {
  __typename: "ApplicationUserUserPermissionsConnection";
  /** A list of edges which contains the `ApplicationUserUserPermission` and cursor to aid in pagination. */
  edges: Array<ApplicationUserUserPermissionsEdge>;
  /** A list of `ApplicationUserUserPermission` objects. */
  nodes: Array<ApplicationUserUserPermission>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ApplicationUserUserPermission` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ApplicationUserUserPermission` edge in the connection. */
export type ApplicationUserUserPermissionsEdge = {
  __typename: "ApplicationUserUserPermissionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ApplicationUserUserPermission` at the end of the edge. */
  node: ApplicationUserUserPermission;
};

/** Methods to use when ordering `ApplicationUserUserPermission`. */
export enum ApplicationUserUserPermissionsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PermissionIdAsc = "PERMISSION_ID_ASC",
  PermissionIdDesc = "PERMISSION_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** A `ApplicationUser` edge in the connection. */
export type ApplicationUsersEdge = {
  __typename: "ApplicationUsersEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ApplicationUser` at the end of the edge. */
  node: ApplicationUser;
};

/** Methods to use when ordering `ApplicationUser`. */
export enum ApplicationUsersOrderBy {
  CognitoTenantKeyAsc = "COGNITO_TENANT_KEY_ASC",
  CognitoTenantKeyDesc = "COGNITO_TENANT_KEY_DESC",
  CreatedAsc = "CREATED_ASC",
  CreatedDesc = "CREATED_DESC",
  EmailAsc = "EMAIL_ASC",
  EmailDesc = "EMAIL_DESC",
  FirstNameAsc = "FIRST_NAME_ASC",
  FirstNameDesc = "FIRST_NAME_DESC",
  GlobalUserIdAsc = "GLOBAL_USER_ID_ASC",
  GlobalUserIdDesc = "GLOBAL_USER_ID_DESC",
  HomeRegionAsc = "HOME_REGION_ASC",
  HomeRegionDesc = "HOME_REGION_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InternalAsc = "INTERNAL_ASC",
  InternalDesc = "INTERNAL_DESC",
  IsActiveAsc = "IS_ACTIVE_ASC",
  IsActiveDesc = "IS_ACTIVE_DESC",
  IsStaffAsc = "IS_STAFF_ASC",
  IsStaffDesc = "IS_STAFF_DESC",
  IsSuperuserAsc = "IS_SUPERUSER_ASC",
  IsSuperuserDesc = "IS_SUPERUSER_DESC",
  LastNameAsc = "LAST_NAME_ASC",
  LastNameDesc = "LAST_NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UsernameAsc = "USERNAME_ASC",
  UsernameDesc = "USERNAME_DESC",
}

export type AuthGroup = {
  __typename: "AuthGroup";
  /** Reads and enables pagination through a set of `ApplicationUserGroup`. */
  applicationUserGroupsByGroupId: ApplicationUserGroupsConnection;
  /** Reads and enables pagination through a set of `AuthGroupPermission`. */
  authGroupPermissionsByGroupId: AuthGroupPermissionsConnection;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
};

export type AuthGroupApplicationUserGroupsByGroupIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserGroupCondition>;
  filter?: InputMaybe<ApplicationUserGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserGroupsOrderBy>>;
};

export type AuthGroupAuthGroupPermissionsByGroupIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuthGroupPermissionCondition>;
  filter?: InputMaybe<AuthGroupPermissionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuthGroupPermissionsOrderBy>>;
};

/** The fields on `authGroup` to look up the row to connect. */
export type AuthGroupAuthGroupNameKeyConnect = {
  name: Scalars["String"]["input"];
};

/** The fields on `authGroup` to look up the row to delete. */
export type AuthGroupAuthGroupNameKeyDelete = {
  name: Scalars["String"]["input"];
};

/** The fields on `authGroup` to look up the row to connect. */
export type AuthGroupAuthGroupPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `authGroup` to look up the row to delete. */
export type AuthGroupAuthGroupPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/**
 * A condition to be used against `AuthGroup` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AuthGroupCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `AuthGroup` object types. All fields are combined with a logical ‘and.’ */
export type AuthGroupFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AuthGroupFilter>>;
  /** Filter by the object’s `applicationUserGroupsByGroupId` relation. */
  applicationUserGroupsByGroupId?: InputMaybe<AuthGroupToManyApplicationUserGroupFilter>;
  /** Some related `applicationUserGroupsByGroupId` exist. */
  applicationUserGroupsByGroupIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authGroupPermissionsByGroupId` relation. */
  authGroupPermissionsByGroupId?: InputMaybe<AuthGroupToManyAuthGroupPermissionFilter>;
  /** Some related `authGroupPermissionsByGroupId` exist. */
  authGroupPermissionsByGroupIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AuthGroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AuthGroupFilter>>;
};

/** An input for mutations affecting `AuthGroup` */
export type AuthGroupInput = {
  applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInverseInput>;
  authGroupPermissionsUsingId?: InputMaybe<AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
};

/** The fields on `authGroup` to look up the row to update. */
export type AuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingAuthGroupNameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `authGroup` being updated. */
    authGroupPatch: UpdateAuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdPatch;
    name: Scalars["String"]["input"];
  };

/** The fields on `authGroup` to look up the row to update. */
export type AuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingAuthGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `authGroup` being updated. */
    authGroupPatch: UpdateAuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `authGroup` to look up the row to update. */
export type AuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupNameKeyUpdate =
  {
    /** An object where the defined keys will be set on the `authGroup` being updated. */
    authGroupPatch: UpdateAuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdPatch;
    name: Scalars["String"]["input"];
  };

/** The fields on `authGroup` to look up the row to update. */
export type AuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `authGroup` being updated. */
    authGroupPatch: UpdateAuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdPatch;
    id: Scalars["Int"]["input"];
  };

/** Represents an update to a `AuthGroup`. Fields that are set will be updated. */
export type AuthGroupPatch = {
  applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInverseInput>;
  authGroupPermissionsUsingId?: InputMaybe<AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** The `authGroupPermission` to be created by this mutation. */
export type AuthGroupPermissioPermissionId84C5C92EFkAuthPermAuthGroupPermissionsCreateInput =
  {
    authGroupToGroupId?: InputMaybe<AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInput>;
    authPermissionToPermissionId?: InputMaybe<AuthGroupPermissioPermissionId84C5C92EFkAuthPermInput>;
    groupId?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** The `authPermission` to be created by this mutation. */
export type AuthGroupPermissioPermissionId84C5C92EFkAuthPermAuthPermissionCreateInput =
  {
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUsePermissionIdDbf9693DFkAuthPermInverseInput>;
    authGroupPermissionsUsingId?: InputMaybe<AuthGroupPermissioPermissionId84C5C92EFkAuthPermInverseInput>;
    codename: Scalars["String"]["input"];
    contentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
    djangoContentTypeToContentTypeId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name: Scalars["String"]["input"];
  };

/** Input for the nested mutation of `authPermission` in the `AuthGroupPermissionInput` mutation. */
export type AuthGroupPermissioPermissionId84C5C92EFkAuthPermInput = {
  /** The primary key(s) for `authPermission` for the far side of the relationship. */
  connectByContentTypeIdAndCodename?: InputMaybe<AuthPermissionAuthPermissionContentTypeIdCodename01Ab375AUniqConnect>;
  /** The primary key(s) for `authPermission` for the far side of the relationship. */
  connectById?: InputMaybe<AuthPermissionAuthPermissionPkeyConnect>;
  /** A `AuthPermissionInput` object that will be created and connected to this object. */
  create?: InputMaybe<AuthGroupPermissioPermissionId84C5C92EFkAuthPermAuthPermissionCreateInput>;
  /** The primary key(s) for `authPermission` for the far side of the relationship. */
  deleteByContentTypeIdAndCodename?: InputMaybe<AuthPermissionAuthPermissionContentTypeIdCodename01Ab375AUniqDelete>;
  /** The primary key(s) for `authPermission` for the far side of the relationship. */
  deleteById?: InputMaybe<AuthPermissionAuthPermissionPkeyDelete>;
  /** The primary key(s) and patch data for `authPermission` for the far side of the relationship. */
  updateByContentTypeIdAndCodename?: InputMaybe<AuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthPermissionContentTypeIdCodename01Ab375AUniqUpdate>;
  /** The primary key(s) and patch data for `authPermission` for the far side of the relationship. */
  updateById?: InputMaybe<AuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthPermissionPkeyUpdate>;
};

/** Input for the nested mutation of `authGroupPermission` in the `AuthPermissionInput` mutation. */
export type AuthGroupPermissioPermissionId84C5C92EFkAuthPermInverseInput = {
  /** The primary key(s) for `authGroupPermission` for the far side of the relationship. */
  connectByGroupIdAndPermissionId?: InputMaybe<
    Array<AuthGroupPermissionAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqConnect>
  >;
  /** The primary key(s) for `authGroupPermission` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AuthGroupPermissionAuthGroupPermissionsPkeyConnect>
  >;
  /** A `AuthGroupPermissionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuthGroupPermissioPermissionId84C5C92EFkAuthPermAuthGroupPermissionsCreateInput>
  >;
  /** The primary key(s) for `authGroupPermission` for the far side of the relationship. */
  deleteByGroupIdAndPermissionId?: InputMaybe<
    Array<AuthGroupPermissionAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqDelete>
  >;
  /** The primary key(s) for `authGroupPermission` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<AuthGroupPermissionAuthGroupPermissionsPkeyDelete>
  >;
  /** Flag indicating whether all other `authGroupPermission` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `authGroupPermission` for the far side of the relationship. */
  updateByGroupIdAndPermissionId?: InputMaybe<
    Array<AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqUpdate>
  >;
  /** The primary key(s) and patch data for `authGroupPermission` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthGroupPermissionsPkeyUpdate>
  >;
};

export type AuthGroupPermission = {
  __typename: "AuthGroupPermission";
  /** Reads a single `AuthGroup` that is related to this `AuthGroupPermission`. */
  authGroupByGroupId: Maybe<AuthGroup>;
  /** Reads a single `AuthPermission` that is related to this `AuthGroupPermission`. */
  authPermissionByPermissionId: Maybe<AuthPermission>;
  groupId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  permissionId: Scalars["Int"]["output"];
};

/** The fields on `authGroupPermission` to look up the row to connect. */
export type AuthGroupPermissionAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqConnect =
  {
    groupId: Scalars["Int"]["input"];
    permissionId: Scalars["Int"]["input"];
  };

/** The fields on `authGroupPermission` to look up the row to delete. */
export type AuthGroupPermissionAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqDelete =
  {
    groupId: Scalars["Int"]["input"];
    permissionId: Scalars["Int"]["input"];
  };

/** The fields on `authGroupPermission` to look up the row to connect. */
export type AuthGroupPermissionAuthGroupPermissionsPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `authGroupPermission` to look up the row to delete. */
export type AuthGroupPermissionAuthGroupPermissionsPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/**
 * A condition to be used against `AuthGroupPermission` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type AuthGroupPermissionCondition = {
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `permissionId` field. */
  permissionId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `AuthGroupPermission` object types. All fields are combined with a logical ‘and.’ */
export type AuthGroupPermissionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AuthGroupPermissionFilter>>;
  /** Filter by the object’s `authGroupByGroupId` relation. */
  authGroupByGroupId?: InputMaybe<AuthGroupFilter>;
  /** Filter by the object’s `authPermissionByPermissionId` relation. */
  authPermissionByPermissionId?: InputMaybe<AuthPermissionFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AuthGroupPermissionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AuthGroupPermissionFilter>>;
  /** Filter by the object’s `permissionId` field. */
  permissionId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `AuthGroupPermission` */
export type AuthGroupPermissionInput = {
  authGroupToGroupId?: InputMaybe<AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInput>;
  authPermissionToPermissionId?: InputMaybe<AuthGroupPermissioPermissionId84C5C92EFkAuthPermInput>;
  groupId?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  permissionId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `authGroupPermission` to look up the row to update. */
export type AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqUpdate =
  {
    /** An object where the defined keys will be set on the `authGroupPermission` being updated. */
    authGroupPermissionPatch: UpdateAuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermPatch;
    groupId: Scalars["Int"]["input"];
    permissionId: Scalars["Int"]["input"];
  };

/** The fields on `authGroupPermission` to look up the row to update. */
export type AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthGroupPermissionsPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `authGroupPermission` being updated. */
    authGroupPermissionPatch: UpdateAuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `authGroupPermission` to look up the row to update. */
export type AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqUpdate =
  {
    /** An object where the defined keys will be set on the `authGroupPermission` being updated. */
    authGroupPermissionPatch: UpdateAuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdPatch;
    groupId: Scalars["Int"]["input"];
    permissionId: Scalars["Int"]["input"];
  };

/** The fields on `authGroupPermission` to look up the row to update. */
export type AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupPermissionsPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `authGroupPermission` being updated. */
    authGroupPermissionPatch: UpdateAuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdPatch;
    id: Scalars["Int"]["input"];
  };

/** Represents an update to a `AuthGroupPermission`. Fields that are set will be updated. */
export type AuthGroupPermissionPatch = {
  authGroupToGroupId?: InputMaybe<AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInput>;
  authPermissionToPermissionId?: InputMaybe<AuthGroupPermissioPermissionId84C5C92EFkAuthPermInput>;
  groupId?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  permissionId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A connection to a list of `AuthGroupPermission` values. */
export type AuthGroupPermissionsConnection = {
  __typename: "AuthGroupPermissionsConnection";
  /** A list of edges which contains the `AuthGroupPermission` and cursor to aid in pagination. */
  edges: Array<AuthGroupPermissionsEdge>;
  /** A list of `AuthGroupPermission` objects. */
  nodes: Array<AuthGroupPermission>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuthGroupPermission` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AuthGroupPermission` edge in the connection. */
export type AuthGroupPermissionsEdge = {
  __typename: "AuthGroupPermissionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AuthGroupPermission` at the end of the edge. */
  node: AuthGroupPermission;
};

/** The `authGroup` to be created by this mutation. */
export type AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdAuthGroupCreateInput =
  {
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInverseInput>;
    authGroupPermissionsUsingId?: InputMaybe<AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name: Scalars["String"]["input"];
  };

/** The `authGroupPermission` to be created by this mutation. */
export type AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdAuthGroupPermissionsCreateInput =
  {
    authGroupToGroupId?: InputMaybe<AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInput>;
    authPermissionToPermissionId?: InputMaybe<AuthGroupPermissioPermissionId84C5C92EFkAuthPermInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    permissionId?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** Input for the nested mutation of `authGroup` in the `AuthGroupPermissionInput` mutation. */
export type AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInput = {
  /** The primary key(s) for `authGroup` for the far side of the relationship. */
  connectById?: InputMaybe<AuthGroupAuthGroupPkeyConnect>;
  /** The primary key(s) for `authGroup` for the far side of the relationship. */
  connectByName?: InputMaybe<AuthGroupAuthGroupNameKeyConnect>;
  /** A `AuthGroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdAuthGroupCreateInput>;
  /** The primary key(s) for `authGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<AuthGroupAuthGroupPkeyDelete>;
  /** The primary key(s) for `authGroup` for the far side of the relationship. */
  deleteByName?: InputMaybe<AuthGroupAuthGroupNameKeyDelete>;
  /** The primary key(s) and patch data for `authGroup` for the far side of the relationship. */
  updateById?: InputMaybe<AuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `authGroup` for the far side of the relationship. */
  updateByName?: InputMaybe<AuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupNameKeyUpdate>;
};

/** Input for the nested mutation of `authGroupPermission` in the `AuthGroupInput` mutation. */
export type AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInverseInput = {
  /** The primary key(s) for `authGroupPermission` for the far side of the relationship. */
  connectByGroupIdAndPermissionId?: InputMaybe<
    Array<AuthGroupPermissionAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqConnect>
  >;
  /** The primary key(s) for `authGroupPermission` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AuthGroupPermissionAuthGroupPermissionsPkeyConnect>
  >;
  /** A `AuthGroupPermissionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdAuthGroupPermissionsCreateInput>
  >;
  /** The primary key(s) for `authGroupPermission` for the far side of the relationship. */
  deleteByGroupIdAndPermissionId?: InputMaybe<
    Array<AuthGroupPermissionAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqDelete>
  >;
  /** The primary key(s) for `authGroupPermission` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<AuthGroupPermissionAuthGroupPermissionsPkeyDelete>
  >;
  /** Flag indicating whether all other `authGroupPermission` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `authGroupPermission` for the far side of the relationship. */
  updateByGroupIdAndPermissionId?: InputMaybe<
    Array<AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqUpdate>
  >;
  /** The primary key(s) and patch data for `authGroupPermission` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupPermissionsPkeyUpdate>
  >;
};

/** Methods to use when ordering `AuthGroupPermission`. */
export enum AuthGroupPermissionsOrderBy {
  GroupIdAsc = "GROUP_ID_ASC",
  GroupIdDesc = "GROUP_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PermissionIdAsc = "PERMISSION_ID_ASC",
  PermissionIdDesc = "PERMISSION_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

/** A filter to be used against many `ApplicationUserGroup` object types. All fields are combined with a logical ‘and.’ */
export type AuthGroupToManyApplicationUserGroupFilter = {
  /** Every related `ApplicationUserGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ApplicationUserGroupFilter>;
  /** No related `ApplicationUserGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ApplicationUserGroupFilter>;
  /** Some related `ApplicationUserGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ApplicationUserGroupFilter>;
};

/** A filter to be used against many `AuthGroupPermission` object types. All fields are combined with a logical ‘and.’ */
export type AuthGroupToManyAuthGroupPermissionFilter = {
  /** Every related `AuthGroupPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuthGroupPermissionFilter>;
  /** No related `AuthGroupPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuthGroupPermissionFilter>;
  /** Some related `AuthGroupPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuthGroupPermissionFilter>;
};

/** A connection to a list of `AuthGroup` values. */
export type AuthGroupsConnection = {
  __typename: "AuthGroupsConnection";
  /** A list of edges which contains the `AuthGroup` and cursor to aid in pagination. */
  edges: Array<AuthGroupsEdge>;
  /** A list of `AuthGroup` objects. */
  nodes: Array<AuthGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuthGroup` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AuthGroup` edge in the connection. */
export type AuthGroupsEdge = {
  __typename: "AuthGroupsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AuthGroup` at the end of the edge. */
  node: AuthGroup;
};

/** Methods to use when ordering `AuthGroup`. */
export enum AuthGroupsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type AuthPermission = {
  __typename: "AuthPermission";
  /** Reads and enables pagination through a set of `ApplicationUserUserPermission`. */
  applicationUserUserPermissionsByPermissionId: ApplicationUserUserPermissionsConnection;
  /** Reads and enables pagination through a set of `AuthGroupPermission`. */
  authGroupPermissionsByPermissionId: AuthGroupPermissionsConnection;
  codename: Scalars["String"]["output"];
  contentTypeId: Scalars["Int"]["output"];
  /** Reads a single `DjangoContentType` that is related to this `AuthPermission`. */
  djangoContentTypeByContentTypeId: Maybe<DjangoContentType>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
};

export type AuthPermissionApplicationUserUserPermissionsByPermissionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserUserPermissionCondition>;
  filter?: InputMaybe<ApplicationUserUserPermissionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserUserPermissionsOrderBy>>;
};

export type AuthPermissionAuthGroupPermissionsByPermissionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuthGroupPermissionCondition>;
  filter?: InputMaybe<AuthGroupPermissionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuthGroupPermissionsOrderBy>>;
};

/** The fields on `authPermission` to look up the row to connect. */
export type AuthPermissionAuthPermissionContentTypeIdCodename01Ab375AUniqConnect =
  {
    codename: Scalars["String"]["input"];
    contentTypeId: Scalars["Int"]["input"];
  };

/** The fields on `authPermission` to look up the row to delete. */
export type AuthPermissionAuthPermissionContentTypeIdCodename01Ab375AUniqDelete =
  {
    codename: Scalars["String"]["input"];
    contentTypeId: Scalars["Int"]["input"];
  };

/** The fields on `authPermission` to look up the row to connect. */
export type AuthPermissionAuthPermissionPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `authPermission` to look up the row to delete. */
export type AuthPermissionAuthPermissionPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/**
 * A condition to be used against `AuthPermission` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AuthPermissionCondition = {
  /** Checks for equality with the object’s `codename` field. */
  codename?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `contentTypeId` field. */
  contentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** The `authPermission` to be created by this mutation. */
export type AuthPermissionContentTypeId2F476E4BFkDjangoCoAuthPermissionCreateInput =
  {
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUsePermissionIdDbf9693DFkAuthPermInverseInput>;
    authGroupPermissionsUsingId?: InputMaybe<AuthGroupPermissioPermissionId84C5C92EFkAuthPermInverseInput>;
    codename: Scalars["String"]["input"];
    djangoContentTypeToContentTypeId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name: Scalars["String"]["input"];
  };

/** The `djangoContentType` to be created by this mutation. */
export type AuthPermissionContentTypeId2F476E4BFkDjangoCoDjangoContentTypeCreateInput =
  {
    appLabel: Scalars["String"]["input"];
    authPermissionsUsingId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput>;
    drsFileexecutorsUsingId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    model: Scalars["String"]["input"];
    tenantUserexecutorsUsingId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput>;
    tesContainerimageexecutorsUsingId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput>;
    tesTaskexecutorsUsingId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput>;
  };

/** Input for the nested mutation of `djangoContentType` in the `AuthPermissionInput` mutation. */
export type AuthPermissionContentTypeId2F476E4BFkDjangoCoInput = {
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  connectByAppLabelAndModel?: InputMaybe<DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqConnect>;
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  connectById?: InputMaybe<DjangoContentTypeDjangoContentTypePkeyConnect>;
  /** A `DjangoContentTypeInput` object that will be created and connected to this object. */
  create?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoDjangoContentTypeCreateInput>;
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  deleteByAppLabelAndModel?: InputMaybe<DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqDelete>;
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  deleteById?: InputMaybe<DjangoContentTypeDjangoContentTypePkeyDelete>;
  /** The primary key(s) and patch data for `djangoContentType` for the far side of the relationship. */
  updateByAppLabelAndModel?: InputMaybe<DjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate>;
  /** The primary key(s) and patch data for `djangoContentType` for the far side of the relationship. */
  updateById?: InputMaybe<DjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingDjangoContentTypePkeyUpdate>;
};

/** Input for the nested mutation of `authPermission` in the `DjangoContentTypeInput` mutation. */
export type AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput = {
  /** The primary key(s) for `authPermission` for the far side of the relationship. */
  connectByContentTypeIdAndCodename?: InputMaybe<
    Array<AuthPermissionAuthPermissionContentTypeIdCodename01Ab375AUniqConnect>
  >;
  /** The primary key(s) for `authPermission` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AuthPermissionAuthPermissionPkeyConnect>>;
  /** A `AuthPermissionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuthPermissionContentTypeId2F476E4BFkDjangoCoAuthPermissionCreateInput>
  >;
  /** The primary key(s) for `authPermission` for the far side of the relationship. */
  deleteByContentTypeIdAndCodename?: InputMaybe<
    Array<AuthPermissionAuthPermissionContentTypeIdCodename01Ab375AUniqDelete>
  >;
  /** The primary key(s) for `authPermission` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<AuthPermissionAuthPermissionPkeyDelete>>;
  /** Flag indicating whether all other `authPermission` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `authPermission` for the far side of the relationship. */
  updateByContentTypeIdAndCodename?: InputMaybe<
    Array<AuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingAuthPermissionContentTypeIdCodename01Ab375AUniqUpdate>
  >;
  /** The primary key(s) and patch data for `authPermission` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingAuthPermissionPkeyUpdate>
  >;
};

/** A filter to be used against `AuthPermission` object types. All fields are combined with a logical ‘and.’ */
export type AuthPermissionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AuthPermissionFilter>>;
  /** Filter by the object’s `applicationUserUserPermissionsByPermissionId` relation. */
  applicationUserUserPermissionsByPermissionId?: InputMaybe<AuthPermissionToManyApplicationUserUserPermissionFilter>;
  /** Some related `applicationUserUserPermissionsByPermissionId` exist. */
  applicationUserUserPermissionsByPermissionIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `authGroupPermissionsByPermissionId` relation. */
  authGroupPermissionsByPermissionId?: InputMaybe<AuthPermissionToManyAuthGroupPermissionFilter>;
  /** Some related `authGroupPermissionsByPermissionId` exist. */
  authGroupPermissionsByPermissionIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `codename` field. */
  codename?: InputMaybe<StringFilter>;
  /** Filter by the object’s `contentTypeId` field. */
  contentTypeId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `djangoContentTypeByContentTypeId` relation. */
  djangoContentTypeByContentTypeId?: InputMaybe<DjangoContentTypeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AuthPermissionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AuthPermissionFilter>>;
};

/** An input for mutations affecting `AuthPermission` */
export type AuthPermissionInput = {
  applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUsePermissionIdDbf9693DFkAuthPermInverseInput>;
  authGroupPermissionsUsingId?: InputMaybe<AuthGroupPermissioPermissionId84C5C92EFkAuthPermInverseInput>;
  codename: Scalars["String"]["input"];
  contentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  djangoContentTypeToContentTypeId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
};

/** The fields on `authPermission` to look up the row to update. */
export type AuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingAuthPermissionContentTypeIdCodename01Ab375AUniqUpdate =
  {
    /** An object where the defined keys will be set on the `authPermission` being updated. */
    authPermissionPatch: UpdateAuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermPatch;
    codename: Scalars["String"]["input"];
    contentTypeId: Scalars["Int"]["input"];
  };

/** The fields on `authPermission` to look up the row to update. */
export type AuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingAuthPermissionPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `authPermission` being updated. */
    authPermissionPatch: UpdateAuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `authPermission` to look up the row to update. */
export type AuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthPermissionContentTypeIdCodename01Ab375AUniqUpdate =
  {
    /** An object where the defined keys will be set on the `authPermission` being updated. */
    authPermissionPatch: UpdateAuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermPatch;
    codename: Scalars["String"]["input"];
    contentTypeId: Scalars["Int"]["input"];
  };

/** The fields on `authPermission` to look up the row to update. */
export type AuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthPermissionPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `authPermission` being updated. */
    authPermissionPatch: UpdateAuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `authPermission` to look up the row to update. */
export type AuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingAuthPermissionContentTypeIdCodename01Ab375AUniqUpdate =
  {
    /** An object where the defined keys will be set on the `authPermission` being updated. */
    authPermissionPatch: UpdateAuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoPatch;
    codename: Scalars["String"]["input"];
    contentTypeId: Scalars["Int"]["input"];
  };

/** The fields on `authPermission` to look up the row to update. */
export type AuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingAuthPermissionPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `authPermission` being updated. */
    authPermissionPatch: UpdateAuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoPatch;
    id: Scalars["Int"]["input"];
  };

/** Represents an update to a `AuthPermission`. Fields that are set will be updated. */
export type AuthPermissionPatch = {
  applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUsePermissionIdDbf9693DFkAuthPermInverseInput>;
  authGroupPermissionsUsingId?: InputMaybe<AuthGroupPermissioPermissionId84C5C92EFkAuthPermInverseInput>;
  codename?: InputMaybe<Scalars["String"]["input"]>;
  contentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  djangoContentTypeToContentTypeId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against many `ApplicationUserUserPermission` object types. All fields are combined with a logical ‘and.’ */
export type AuthPermissionToManyApplicationUserUserPermissionFilter = {
  /** Every related `ApplicationUserUserPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ApplicationUserUserPermissionFilter>;
  /** No related `ApplicationUserUserPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ApplicationUserUserPermissionFilter>;
  /** Some related `ApplicationUserUserPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ApplicationUserUserPermissionFilter>;
};

/** A filter to be used against many `AuthGroupPermission` object types. All fields are combined with a logical ‘and.’ */
export type AuthPermissionToManyAuthGroupPermissionFilter = {
  /** Every related `AuthGroupPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuthGroupPermissionFilter>;
  /** No related `AuthGroupPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuthGroupPermissionFilter>;
  /** Some related `AuthGroupPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuthGroupPermissionFilter>;
};

/** A connection to a list of `AuthPermission` values. */
export type AuthPermissionsConnection = {
  __typename: "AuthPermissionsConnection";
  /** A list of edges which contains the `AuthPermission` and cursor to aid in pagination. */
  edges: Array<AuthPermissionsEdge>;
  /** A list of `AuthPermission` objects. */
  nodes: Array<AuthPermission>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuthPermission` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AuthPermission` edge in the connection. */
export type AuthPermissionsEdge = {
  __typename: "AuthPermissionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AuthPermission` at the end of the edge. */
  node: AuthPermission;
};

/** Methods to use when ordering `AuthPermission`. */
export enum AuthPermissionsOrderBy {
  CodenameAsc = "CODENAME_ASC",
  CodenameDesc = "CODENAME_DESC",
  ContentTypeIdAsc = "CONTENT_TYPE_ID_ASC",
  ContentTypeIdDesc = "CONTENT_TYPE_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type AvailableNonRestrictedProject = {
  __typename: "AvailableNonRestrictedProject";
  defaultUserAccessLevel: Maybe<Scalars["Int"]["output"]>;
  id: Maybe<Scalars["UUID"]["output"]>;
  status: Maybe<Scalars["Int"]["output"]>;
  tenantId: Maybe<Scalars["Int"]["output"]>;
  userId: Maybe<Scalars["UUID"]["output"]>;
};

/**
 * A condition to be used against `AvailableNonRestrictedProject` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type AvailableNonRestrictedProjectCondition = {
  /** Checks for equality with the object’s `defaultUserAccessLevel` field. */
  defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `AvailableNonRestrictedProject` object types. All fields are combined with a logical ‘and.’ */
export type AvailableNonRestrictedProjectFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AvailableNonRestrictedProjectFilter>>;
  /** Filter by the object’s `defaultUserAccessLevel` field. */
  defaultUserAccessLevel?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AvailableNonRestrictedProjectFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AvailableNonRestrictedProjectFilter>>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `AvailableNonRestrictedProject` */
export type AvailableNonRestrictedProjectInput = {
  defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A connection to a list of `AvailableNonRestrictedProject` values. */
export type AvailableNonRestrictedProjectsConnection = {
  __typename: "AvailableNonRestrictedProjectsConnection";
  /** A list of edges which contains the `AvailableNonRestrictedProject` and cursor to aid in pagination. */
  edges: Array<AvailableNonRestrictedProjectsEdge>;
  /** A list of `AvailableNonRestrictedProject` objects. */
  nodes: Array<AvailableNonRestrictedProject>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AvailableNonRestrictedProject` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AvailableNonRestrictedProject` edge in the connection. */
export type AvailableNonRestrictedProjectsEdge = {
  __typename: "AvailableNonRestrictedProjectsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AvailableNonRestrictedProject` at the end of the edge. */
  node: AvailableNonRestrictedProject;
};

/** Methods to use when ordering `AvailableNonRestrictedProject`. */
export enum AvailableNonRestrictedProjectsOrderBy {
  DefaultUserAccessLevelAsc = "DEFAULT_USER_ACCESS_LEVEL_ASC",
  DefaultUserAccessLevelDesc = "DEFAULT_USER_ACCESS_LEVEL_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type Avatar = {
  __typename: "Avatar";
  /** Reads a single `ApplicationUser` that is related to this `Avatar`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  color: Scalars["String"]["output"];
  id: Scalars["BigInt"]["output"];
  imageBase64: Maybe<Scalars["String"]["output"]>;
  initials: Scalars["String"]["output"];
  useImage: Scalars["Boolean"]["output"];
  userId: Scalars["UUID"]["output"];
};

/** The fields on `avatar` to look up the row to connect. */
export type AvatarAvatarPkeyConnect = {
  id: Scalars["BigInt"]["input"];
};

/** The fields on `avatar` to look up the row to delete. */
export type AvatarAvatarPkeyDelete = {
  id: Scalars["BigInt"]["input"];
};

/** The fields on `avatar` to look up the row to connect. */
export type AvatarAvatarUserIdKeyConnect = {
  userId: Scalars["UUID"]["input"];
};

/** The fields on `avatar` to look up the row to delete. */
export type AvatarAvatarUserIdKeyDelete = {
  userId: Scalars["UUID"]["input"];
};

/** A condition to be used against `Avatar` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AvatarCondition = {
  /** Checks for equality with the object’s `color` field. */
  color?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `imageBase64` field. */
  imageBase64?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `initials` field. */
  initials?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `useImage` field. */
  useImage?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `Avatar` object types. All fields are combined with a logical ‘and.’ */
export type AvatarFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AvatarFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `color` field. */
  color?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `imageBase64` field. */
  imageBase64?: InputMaybe<StringFilter>;
  /** Filter by the object’s `initials` field. */
  initials?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AvatarFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AvatarFilter>>;
  /** Filter by the object’s `useImage` field. */
  useImage?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `Avatar` */
export type AvatarInput = {
  applicationUserToUserId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInput>;
  color: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  imageBase64?: InputMaybe<Scalars["String"]["input"]>;
  initials: Scalars["String"]["input"];
  useImage: Scalars["Boolean"]["input"];
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `avatar` to look up the row to update. */
export type AvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingAvatarPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `avatar` being updated. */
    avatarPatch: UpdateAvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdPatch;
    id: Scalars["BigInt"]["input"];
  };

/** The fields on `avatar` to look up the row to update. */
export type AvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingAvatarUserIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `avatar` being updated. */
    avatarPatch: UpdateAvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdPatch;
    userId: Scalars["UUID"]["input"];
  };

/** Represents an update to a `Avatar`. Fields that are set will be updated. */
export type AvatarPatch = {
  applicationUserToUserId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInput>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  imageBase64?: InputMaybe<Scalars["String"]["input"]>;
  initials?: InputMaybe<Scalars["String"]["input"]>;
  useImage?: InputMaybe<Scalars["Boolean"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `applicationUser` to be created by this mutation. */
export type AvatarUserId771332F2FkApplicationUserIdApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** The `avatar` to be created by this mutation. */
export type AvatarUserId771332F2FkApplicationUserIdAvatarCreateInput = {
  applicationUserToUserId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInput>;
  color: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  imageBase64?: InputMaybe<Scalars["String"]["input"]>;
  initials: Scalars["String"]["input"];
  useImage: Scalars["Boolean"]["input"];
};

/** Input for the nested mutation of `applicationUser` in the `AvatarInput` mutation. */
export type AvatarUserId771332F2FkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `avatar` in the `ApplicationUserInput` mutation. */
export type AvatarUserId771332F2FkApplicationUserIdInverseInput = {
  /** The primary key(s) for `avatar` for the far side of the relationship. */
  connectById?: InputMaybe<AvatarAvatarPkeyConnect>;
  /** The primary key(s) for `avatar` for the far side of the relationship. */
  connectByUserId?: InputMaybe<AvatarAvatarUserIdKeyConnect>;
  /** A `AvatarInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AvatarUserId771332F2FkApplicationUserIdAvatarCreateInput>
  >;
  /** The primary key(s) for `avatar` for the far side of the relationship. */
  deleteById?: InputMaybe<AvatarAvatarPkeyDelete>;
  /** The primary key(s) for `avatar` for the far side of the relationship. */
  deleteByUserId?: InputMaybe<AvatarAvatarUserIdKeyDelete>;
  /** Flag indicating whether all other `avatar` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `avatar` for the far side of the relationship. */
  updateById?: InputMaybe<AvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingAvatarPkeyUpdate>;
  /** The primary key(s) and patch data for `avatar` for the far side of the relationship. */
  updateByUserId?: InputMaybe<AvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingAvatarUserIdKeyUpdate>;
};

/** A connection to a list of `Avatar` values. */
export type AvatarsConnection = {
  __typename: "AvatarsConnection";
  /** A list of edges which contains the `Avatar` and cursor to aid in pagination. */
  edges: Array<AvatarsEdge>;
  /** A list of `Avatar` objects. */
  nodes: Array<Avatar>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Avatar` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Avatar` edge in the connection. */
export type AvatarsEdge = {
  __typename: "AvatarsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Avatar` at the end of the edge. */
  node: Avatar;
};

/** Methods to use when ordering `Avatar`. */
export enum AvatarsOrderBy {
  ColorAsc = "COLOR_ASC",
  ColorDesc = "COLOR_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ImageBase_64Asc = "IMAGE_BASE_64_ASC",
  ImageBase_64Desc = "IMAGE_BASE_64_DESC",
  InitialsAsc = "INITIALS_ASC",
  InitialsDesc = "INITIALS_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
  UseImageAsc = "USE_IMAGE_ASC",
  UseImageDesc = "USE_IMAGE_DESC",
}

export type AwsBatchTask = {
  __typename: "AwsBatchTask";
  awsDataBucketName: Maybe<Scalars["String"]["output"]>;
  awsInstanceType: Maybe<Scalars["String"]["output"]>;
  awsJobId: Maybe<Scalars["String"]["output"]>;
  awsJobQueue: Maybe<Scalars["String"]["output"]>;
  awsJobStatus: Maybe<Scalars["Int"]["output"]>;
  awsJobStatusReason: Scalars["String"]["output"];
  /** Reads a single `AwsMockBatchTask` that is related to this `AwsBatchTask`. */
  awsMockBatchTaskByAwstaskexecutorPtrId: Maybe<AwsMockBatchTask>;
  /**
   * Reads and enables pagination through a set of `AwsMockBatchTask`.
   * @deprecated Please use awsMockBatchTaskByAwstaskexecutorPtrId instead
   */
  awsMockBatchTasksByAwstaskexecutorPtrId: AwsMockBatchTasksConnection;
  awsRegion: Maybe<Scalars["String"]["output"]>;
  awsTaskPath: Maybe<Scalars["String"]["output"]>;
  exitcode: Maybe<Scalars["Int"]["output"]>;
  resultsProcessed: Scalars["Boolean"]["output"];
  taskexecutorPtrId: Scalars["UUID"]["output"];
  /** Reads a single `TesTaskexecutor` that is related to this `AwsBatchTask`. */
  tesTaskexecutorByTaskexecutorPtrId: Maybe<TesTaskexecutor>;
};

export type AwsBatchTaskAwsMockBatchTasksByAwstaskexecutorPtrIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsMockBatchTaskCondition>;
  filter?: InputMaybe<AwsMockBatchTaskFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsMockBatchTasksOrderBy>>;
};

/** The fields on `awsBatchTask` to look up the row to connect. */
export type AwsBatchTaskAwsBatchTaskPkeyConnect = {
  taskexecutorPtrId: Scalars["UUID"]["input"];
};

/** The fields on `awsBatchTask` to look up the row to delete. */
export type AwsBatchTaskAwsBatchTaskPkeyDelete = {
  taskexecutorPtrId: Scalars["UUID"]["input"];
};

/**
 * A condition to be used against `AwsBatchTask` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AwsBatchTaskCondition = {
  /** Checks for equality with the object’s `awsDataBucketName` field. */
  awsDataBucketName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `awsInstanceType` field. */
  awsInstanceType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `awsJobId` field. */
  awsJobId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `awsJobQueue` field. */
  awsJobQueue?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `awsJobStatus` field. */
  awsJobStatus?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `awsJobStatusReason` field. */
  awsJobStatusReason?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `awsRegion` field. */
  awsRegion?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `awsTaskPath` field. */
  awsTaskPath?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `exitcode` field. */
  exitcode?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `resultsProcessed` field. */
  resultsProcessed?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `taskexecutorPtrId` field. */
  taskexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `AwsBatchTask` object types. All fields are combined with a logical ‘and.’ */
export type AwsBatchTaskFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AwsBatchTaskFilter>>;
  /** Filter by the object’s `awsDataBucketName` field. */
  awsDataBucketName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `awsInstanceType` field. */
  awsInstanceType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `awsJobId` field. */
  awsJobId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `awsJobQueue` field. */
  awsJobQueue?: InputMaybe<StringFilter>;
  /** Filter by the object’s `awsJobStatus` field. */
  awsJobStatus?: InputMaybe<IntFilter>;
  /** Filter by the object’s `awsJobStatusReason` field. */
  awsJobStatusReason?: InputMaybe<StringFilter>;
  /** Filter by the object’s `awsMockBatchTaskByAwstaskexecutorPtrId` relation. */
  awsMockBatchTaskByAwstaskexecutorPtrId?: InputMaybe<AwsMockBatchTaskFilter>;
  /** A related `awsMockBatchTaskByAwstaskexecutorPtrId` exists. */
  awsMockBatchTaskByAwstaskexecutorPtrIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `awsRegion` field. */
  awsRegion?: InputMaybe<StringFilter>;
  /** Filter by the object’s `awsTaskPath` field. */
  awsTaskPath?: InputMaybe<StringFilter>;
  /** Filter by the object’s `exitcode` field. */
  exitcode?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AwsBatchTaskFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AwsBatchTaskFilter>>;
  /** Filter by the object’s `resultsProcessed` field. */
  resultsProcessed?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `taskexecutorPtrId` field. */
  taskexecutorPtrId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tesTaskexecutorByTaskexecutorPtrId` relation. */
  tesTaskexecutorByTaskexecutorPtrId?: InputMaybe<TesTaskexecutorFilter>;
};

/** An input for mutations affecting `AwsBatchTask` */
export type AwsBatchTaskInput = {
  awsDataBucketName?: InputMaybe<Scalars["String"]["input"]>;
  awsInstanceType?: InputMaybe<Scalars["String"]["input"]>;
  awsJobId?: InputMaybe<Scalars["String"]["input"]>;
  awsJobQueue?: InputMaybe<Scalars["String"]["input"]>;
  awsJobStatus?: InputMaybe<Scalars["Int"]["input"]>;
  awsJobStatusReason: Scalars["String"]["input"];
  awsMockBatchTaskUsingTaskexecutorPtrId?: InputMaybe<AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInverseInput>;
  awsRegion?: InputMaybe<Scalars["String"]["input"]>;
  awsTaskPath?: InputMaybe<Scalars["String"]["input"]>;
  exitcode?: InputMaybe<Scalars["Int"]["input"]>;
  resultsProcessed: Scalars["Boolean"]["input"];
  taskexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
  tesTaskexecutorToTaskexecutorPtrId?: InputMaybe<AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInput>;
};

/** The fields on `awsBatchTask` to look up the row to update. */
export type AwsBatchTaskOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeUsingAwsBatchTaskPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `awsBatchTask` being updated. */
    awsBatchTaskPatch: UpdateAwsBatchTaskOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskePatch;
    taskexecutorPtrId: Scalars["UUID"]["input"];
  };

/** The fields on `awsBatchTask` to look up the row to update. */
export type AwsBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchUsingAwsBatchTaskPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `awsBatchTask` being updated. */
    awsBatchTaskPatch: UpdateAwsBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchPatch;
    taskexecutorPtrId: Scalars["UUID"]["input"];
  };

/** Represents an update to a `AwsBatchTask`. Fields that are set will be updated. */
export type AwsBatchTaskPatch = {
  awsDataBucketName?: InputMaybe<Scalars["String"]["input"]>;
  awsInstanceType?: InputMaybe<Scalars["String"]["input"]>;
  awsJobId?: InputMaybe<Scalars["String"]["input"]>;
  awsJobQueue?: InputMaybe<Scalars["String"]["input"]>;
  awsJobStatus?: InputMaybe<Scalars["Int"]["input"]>;
  awsJobStatusReason?: InputMaybe<Scalars["String"]["input"]>;
  awsMockBatchTaskUsingTaskexecutorPtrId?: InputMaybe<AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInverseInput>;
  awsRegion?: InputMaybe<Scalars["String"]["input"]>;
  awsTaskPath?: InputMaybe<Scalars["String"]["input"]>;
  exitcode?: InputMaybe<Scalars["Int"]["input"]>;
  resultsProcessed?: InputMaybe<Scalars["Boolean"]["input"]>;
  taskexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
  tesTaskexecutorToTaskexecutorPtrId?: InputMaybe<AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInput>;
};

/** The `awsBatchTask` to be created by this mutation. */
export type AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeAwsBatchTaskCreateInput =
  {
    awsDataBucketName?: InputMaybe<Scalars["String"]["input"]>;
    awsInstanceType?: InputMaybe<Scalars["String"]["input"]>;
    awsJobId?: InputMaybe<Scalars["String"]["input"]>;
    awsJobQueue?: InputMaybe<Scalars["String"]["input"]>;
    awsJobStatus?: InputMaybe<Scalars["Int"]["input"]>;
    awsJobStatusReason: Scalars["String"]["input"];
    awsMockBatchTaskUsingTaskexecutorPtrId?: InputMaybe<AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInverseInput>;
    awsRegion?: InputMaybe<Scalars["String"]["input"]>;
    awsTaskPath?: InputMaybe<Scalars["String"]["input"]>;
    exitcode?: InputMaybe<Scalars["Int"]["input"]>;
    resultsProcessed: Scalars["Boolean"]["input"];
    tesTaskexecutorToTaskexecutorPtrId?: InputMaybe<AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInput>;
  };

/** Input for the nested mutation of `tesTaskexecutor` in the `AwsBatchTaskInput` mutation. */
export type AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInput = {
  /** The primary key(s) for `tesTaskexecutor` for the far side of the relationship. */
  connectById?: InputMaybe<TesTaskexecutorTesTaskexecutorPkeyConnect>;
  /** A `TesTaskexecutorInput` object that will be created and connected to this object. */
  create?: InputMaybe<AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeTesTaskexecutorCreateInput>;
  /** The primary key(s) for `tesTaskexecutor` for the far side of the relationship. */
  deleteById?: InputMaybe<TesTaskexecutorTesTaskexecutorPkeyDelete>;
  /** The primary key(s) and patch data for `tesTaskexecutor` for the far side of the relationship. */
  updateById?: InputMaybe<TesTaskexecutorOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeUsingTesTaskexecutorPkeyUpdate>;
};

/** Input for the nested mutation of `awsBatchTask` in the `TesTaskexecutorInput` mutation. */
export type AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInverseInput = {
  /** The primary key(s) for `awsBatchTask` for the far side of the relationship. */
  connectByTaskexecutorPtrId?: InputMaybe<AwsBatchTaskAwsBatchTaskPkeyConnect>;
  /** A `AwsBatchTaskInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeAwsBatchTaskCreateInput>
  >;
  /** The primary key(s) for `awsBatchTask` for the far side of the relationship. */
  deleteByTaskexecutorPtrId?: InputMaybe<AwsBatchTaskAwsBatchTaskPkeyDelete>;
  /** Flag indicating whether all other `awsBatchTask` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `awsBatchTask` for the far side of the relationship. */
  updateByTaskexecutorPtrId?: InputMaybe<AwsBatchTaskOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeUsingAwsBatchTaskPkeyUpdate>;
};

/** The `tesTaskexecutor` to be created by this mutation. */
export type AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeTesTaskexecutorCreateInput =
  {
    awsBatchTaskUsingId?: InputMaybe<AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInput>;
    id: Scalars["UUID"]["input"];
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
    taskUsingId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInverseInput>;
    tesMocktaskexecutorUsingId?: InputMaybe<TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInverseInput>;
  };

/** A connection to a list of `AwsBatchTask` values. */
export type AwsBatchTasksConnection = {
  __typename: "AwsBatchTasksConnection";
  /** A list of edges which contains the `AwsBatchTask` and cursor to aid in pagination. */
  edges: Array<AwsBatchTasksEdge>;
  /** A list of `AwsBatchTask` objects. */
  nodes: Array<AwsBatchTask>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AwsBatchTask` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AwsBatchTask` edge in the connection. */
export type AwsBatchTasksEdge = {
  __typename: "AwsBatchTasksEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AwsBatchTask` at the end of the edge. */
  node: AwsBatchTask;
};

/** Methods to use when ordering `AwsBatchTask`. */
export enum AwsBatchTasksOrderBy {
  AwsDataBucketNameAsc = "AWS_DATA_BUCKET_NAME_ASC",
  AwsDataBucketNameDesc = "AWS_DATA_BUCKET_NAME_DESC",
  AwsInstanceTypeAsc = "AWS_INSTANCE_TYPE_ASC",
  AwsInstanceTypeDesc = "AWS_INSTANCE_TYPE_DESC",
  AwsJobIdAsc = "AWS_JOB_ID_ASC",
  AwsJobIdDesc = "AWS_JOB_ID_DESC",
  AwsJobQueueAsc = "AWS_JOB_QUEUE_ASC",
  AwsJobQueueDesc = "AWS_JOB_QUEUE_DESC",
  AwsJobStatusAsc = "AWS_JOB_STATUS_ASC",
  AwsJobStatusDesc = "AWS_JOB_STATUS_DESC",
  AwsJobStatusReasonAsc = "AWS_JOB_STATUS_REASON_ASC",
  AwsJobStatusReasonDesc = "AWS_JOB_STATUS_REASON_DESC",
  AwsRegionAsc = "AWS_REGION_ASC",
  AwsRegionDesc = "AWS_REGION_DESC",
  AwsTaskPathAsc = "AWS_TASK_PATH_ASC",
  AwsTaskPathDesc = "AWS_TASK_PATH_DESC",
  ExitcodeAsc = "EXITCODE_ASC",
  ExitcodeDesc = "EXITCODE_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ResultsProcessedAsc = "RESULTS_PROCESSED_ASC",
  ResultsProcessedDesc = "RESULTS_PROCESSED_DESC",
  TaskexecutorPtrIdAsc = "TASKEXECUTOR_PTR_ID_ASC",
  TaskexecutorPtrIdDesc = "TASKEXECUTOR_PTR_ID_DESC",
}

export type AwsCognitoUser = {
  __typename: "AwsCognitoUser";
  /** Reads a single `TenantUserexecutor` that is related to this `AwsCognitoUser`. */
  tenantUserexecutorByUserexecutorPtrId: Maybe<TenantUserexecutor>;
  userexecutorPtrId: Scalars["Int"]["output"];
};

/** The fields on `awsCognitoUser` to look up the row to connect. */
export type AwsCognitoUserAwsCognitoUserPkeyConnect = {
  userexecutorPtrId: Scalars["Int"]["input"];
};

/** The fields on `awsCognitoUser` to look up the row to delete. */
export type AwsCognitoUserAwsCognitoUserPkeyDelete = {
  userexecutorPtrId: Scalars["Int"]["input"];
};

/**
 * A condition to be used against `AwsCognitoUser` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AwsCognitoUserCondition = {
  /** Checks for equality with the object’s `userexecutorPtrId` field. */
  userexecutorPtrId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `AwsCognitoUser` object types. All fields are combined with a logical ‘and.’ */
export type AwsCognitoUserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AwsCognitoUserFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<AwsCognitoUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AwsCognitoUserFilter>>;
  /** Filter by the object’s `tenantUserexecutorByUserexecutorPtrId` relation. */
  tenantUserexecutorByUserexecutorPtrId?: InputMaybe<TenantUserexecutorFilter>;
  /** Filter by the object’s `userexecutorPtrId` field. */
  userexecutorPtrId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `AwsCognitoUser` */
export type AwsCognitoUserInput = {
  tenantUserexecutorToUserexecutorPtrId?: InputMaybe<AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInput>;
  userexecutorPtrId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `awsCognitoUser` to look up the row to update. */
export type AwsCognitoUserOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsUsingAwsCognitoUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `awsCognitoUser` being updated. */
    awsCognitoUserPatch: UpdateAwsCognitoUserOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsPatch;
    userexecutorPtrId: Scalars["Int"]["input"];
  };

/** Represents an update to a `AwsCognitoUser`. Fields that are set will be updated. */
export type AwsCognitoUserPatch = {
  tenantUserexecutorToUserexecutorPtrId?: InputMaybe<AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInput>;
  userexecutorPtrId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The `awsCognitoUser` to be created by this mutation. */
export type AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsAwsCognitoUserCreateInput =
  {
    tenantUserexecutorToUserexecutorPtrId?: InputMaybe<AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInput>;
  };

/** Input for the nested mutation of `tenantUserexecutor` in the `AwsCognitoUserInput` mutation. */
export type AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInput = {
  /** The primary key(s) for `tenantUserexecutor` for the far side of the relationship. */
  connectById?: InputMaybe<TenantUserexecutorTenantUserexecutorPkeyConnect>;
  /** A `TenantUserexecutorInput` object that will be created and connected to this object. */
  create?: InputMaybe<AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsTenantUserexecutorCreateInput>;
  /** The primary key(s) for `tenantUserexecutor` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantUserexecutorTenantUserexecutorPkeyDelete>;
  /** The primary key(s) and patch data for `tenantUserexecutor` for the far side of the relationship. */
  updateById?: InputMaybe<TenantUserexecutorOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsUsingTenantUserexecutorPkeyUpdate>;
};

/** Input for the nested mutation of `awsCognitoUser` in the `TenantUserexecutorInput` mutation. */
export type AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInverseInput = {
  /** The primary key(s) for `awsCognitoUser` for the far side of the relationship. */
  connectByUserexecutorPtrId?: InputMaybe<AwsCognitoUserAwsCognitoUserPkeyConnect>;
  /** A `AwsCognitoUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsAwsCognitoUserCreateInput>
  >;
  /** The primary key(s) for `awsCognitoUser` for the far side of the relationship. */
  deleteByUserexecutorPtrId?: InputMaybe<AwsCognitoUserAwsCognitoUserPkeyDelete>;
  /** Flag indicating whether all other `awsCognitoUser` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `awsCognitoUser` for the far side of the relationship. */
  updateByUserexecutorPtrId?: InputMaybe<AwsCognitoUserOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsUsingAwsCognitoUserPkeyUpdate>;
};

/** The `tenantUserexecutor` to be created by this mutation. */
export type AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsTenantUserexecutorCreateInput =
  {
    applicationUserUsingId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInverseInput>;
    awsCognitoUserUsingId?: InputMaybe<AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantMockuserexecutorUsingId?: InputMaybe<TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInverseInput>;
  };

/** A connection to a list of `AwsCognitoUser` values. */
export type AwsCognitoUsersConnection = {
  __typename: "AwsCognitoUsersConnection";
  /** A list of edges which contains the `AwsCognitoUser` and cursor to aid in pagination. */
  edges: Array<AwsCognitoUsersEdge>;
  /** A list of `AwsCognitoUser` objects. */
  nodes: Array<AwsCognitoUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AwsCognitoUser` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AwsCognitoUser` edge in the connection. */
export type AwsCognitoUsersEdge = {
  __typename: "AwsCognitoUsersEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AwsCognitoUser` at the end of the edge. */
  node: AwsCognitoUser;
};

/** Methods to use when ordering `AwsCognitoUser`. */
export enum AwsCognitoUsersOrderBy {
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UserexecutorPtrIdAsc = "USEREXECUTOR_PTR_ID_ASC",
  UserexecutorPtrIdDesc = "USEREXECUTOR_PTR_ID_DESC",
}

export type AwsEcrImage = {
  __typename: "AwsEcrImage";
  /** Reads a single `AwsMockEcrImage` that is related to this `AwsEcrImage`. */
  awsMockEcrImageByAwscontainerimageexecutorPtrId: Maybe<AwsMockEcrImage>;
  /**
   * Reads and enables pagination through a set of `AwsMockEcrImage`.
   * @deprecated Please use awsMockEcrImageByAwscontainerimageexecutorPtrId instead
   */
  awsMockEcrImagesByAwscontainerimageexecutorPtrId: AwsMockEcrImagesConnection;
  containerimageexecutorPtrId: Scalars["Int"]["output"];
  jobDefinitionName: Maybe<Scalars["String"]["output"]>;
  jobDefinitionRevision: Maybe<Scalars["Int"]["output"]>;
  repositoryArn: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `TesContainerimageexecutor` that is related to this `AwsEcrImage`. */
  tesContainerimageexecutorByContainerimageexecutorPtrId: Maybe<TesContainerimageexecutor>;
};

export type AwsEcrImageAwsMockEcrImagesByAwscontainerimageexecutorPtrIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsMockEcrImageCondition>;
  filter?: InputMaybe<AwsMockEcrImageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsMockEcrImagesOrderBy>>;
};

/** The fields on `awsEcrImage` to look up the row to connect. */
export type AwsEcrImageAwsEcrImagePkeyConnect = {
  containerimageexecutorPtrId: Scalars["Int"]["input"];
};

/** The fields on `awsEcrImage` to look up the row to delete. */
export type AwsEcrImageAwsEcrImagePkeyDelete = {
  containerimageexecutorPtrId: Scalars["Int"]["input"];
};

/**
 * A condition to be used against `AwsEcrImage` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AwsEcrImageCondition = {
  /** Checks for equality with the object’s `containerimageexecutorPtrId` field. */
  containerimageexecutorPtrId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `jobDefinitionName` field. */
  jobDefinitionName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `jobDefinitionRevision` field. */
  jobDefinitionRevision?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `repositoryArn` field. */
  repositoryArn?: InputMaybe<Scalars["String"]["input"]>;
};

/** The `awsEcrImage` to be created by this mutation. */
export type AwsEcrImageContainerimageexecutAabe4FdbFkTesContaAwsEcrImageCreateInput =
  {
    awsMockEcrImageUsingContainerimageexecutorPtrId?: InputMaybe<AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInverseInput>;
    jobDefinitionName?: InputMaybe<Scalars["String"]["input"]>;
    jobDefinitionRevision?: InputMaybe<Scalars["Int"]["input"]>;
    repositoryArn?: InputMaybe<Scalars["String"]["input"]>;
    tesContainerimageexecutorToContainerimageexecutorPtrId?: InputMaybe<AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInput>;
  };

/** Input for the nested mutation of `tesContainerimageexecutor` in the `AwsEcrImageInput` mutation. */
export type AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInput = {
  /** The primary key(s) for `tesContainerimageexecutor` for the far side of the relationship. */
  connectById?: InputMaybe<TesContainerimageexecutorTesContainerimageexecutorPkeyConnect>;
  /** A `TesContainerimageexecutorInput` object that will be created and connected to this object. */
  create?: InputMaybe<AwsEcrImageContainerimageexecutAabe4FdbFkTesContaTesContainerimageexecutorCreateInput>;
  /** The primary key(s) for `tesContainerimageexecutor` for the far side of the relationship. */
  deleteById?: InputMaybe<TesContainerimageexecutorTesContainerimageexecutorPkeyDelete>;
  /** The primary key(s) and patch data for `tesContainerimageexecutor` for the far side of the relationship. */
  updateById?: InputMaybe<TesContainerimageexecutorOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaUsingTesContainerimageexecutorPkeyUpdate>;
};

/** Input for the nested mutation of `awsEcrImage` in the `TesContainerimageexecutorInput` mutation. */
export type AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInverseInput = {
  /** The primary key(s) for `awsEcrImage` for the far side of the relationship. */
  connectByContainerimageexecutorPtrId?: InputMaybe<AwsEcrImageAwsEcrImagePkeyConnect>;
  /** A `AwsEcrImageInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AwsEcrImageContainerimageexecutAabe4FdbFkTesContaAwsEcrImageCreateInput>
  >;
  /** The primary key(s) for `awsEcrImage` for the far side of the relationship. */
  deleteByContainerimageexecutorPtrId?: InputMaybe<AwsEcrImageAwsEcrImagePkeyDelete>;
  /** Flag indicating whether all other `awsEcrImage` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `awsEcrImage` for the far side of the relationship. */
  updateByContainerimageexecutorPtrId?: InputMaybe<AwsEcrImageOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaUsingAwsEcrImagePkeyUpdate>;
};

/** The `tesContainerimageexecutor` to be created by this mutation. */
export type AwsEcrImageContainerimageexecutAabe4FdbFkTesContaTesContainerimageexecutorCreateInput =
  {
    awsEcrImageUsingId?: InputMaybe<AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInverseInput>;
    containerImageUsingId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
    tesMockcontainerimageexecutorUsingId?: InputMaybe<TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInverseInput>;
  };

/** A filter to be used against `AwsEcrImage` object types. All fields are combined with a logical ‘and.’ */
export type AwsEcrImageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AwsEcrImageFilter>>;
  /** Filter by the object’s `awsMockEcrImageByAwscontainerimageexecutorPtrId` relation. */
  awsMockEcrImageByAwscontainerimageexecutorPtrId?: InputMaybe<AwsMockEcrImageFilter>;
  /** A related `awsMockEcrImageByAwscontainerimageexecutorPtrId` exists. */
  awsMockEcrImageByAwscontainerimageexecutorPtrIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `containerimageexecutorPtrId` field. */
  containerimageexecutorPtrId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `jobDefinitionName` field. */
  jobDefinitionName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `jobDefinitionRevision` field. */
  jobDefinitionRevision?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AwsEcrImageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AwsEcrImageFilter>>;
  /** Filter by the object’s `repositoryArn` field. */
  repositoryArn?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tesContainerimageexecutorByContainerimageexecutorPtrId` relation. */
  tesContainerimageexecutorByContainerimageexecutorPtrId?: InputMaybe<TesContainerimageexecutorFilter>;
};

/** An input for mutations affecting `AwsEcrImage` */
export type AwsEcrImageInput = {
  awsMockEcrImageUsingContainerimageexecutorPtrId?: InputMaybe<AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInverseInput>;
  containerimageexecutorPtrId?: InputMaybe<Scalars["Int"]["input"]>;
  jobDefinitionName?: InputMaybe<Scalars["String"]["input"]>;
  jobDefinitionRevision?: InputMaybe<Scalars["Int"]["input"]>;
  repositoryArn?: InputMaybe<Scalars["String"]["input"]>;
  tesContainerimageexecutorToContainerimageexecutorPtrId?: InputMaybe<AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInput>;
};

/** The fields on `awsEcrImage` to look up the row to update. */
export type AwsEcrImageOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaUsingAwsEcrImagePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `awsEcrImage` being updated. */
    awsEcrImagePatch: UpdateAwsEcrImageOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaPatch;
    containerimageexecutorPtrId: Scalars["Int"]["input"];
  };

/** The fields on `awsEcrImage` to look up the row to update. */
export type AwsEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIUsingAwsEcrImagePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `awsEcrImage` being updated. */
    awsEcrImagePatch: UpdateAwsEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIPatch;
    containerimageexecutorPtrId: Scalars["Int"]["input"];
  };

/** Represents an update to a `AwsEcrImage`. Fields that are set will be updated. */
export type AwsEcrImagePatch = {
  awsMockEcrImageUsingContainerimageexecutorPtrId?: InputMaybe<AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInverseInput>;
  containerimageexecutorPtrId?: InputMaybe<Scalars["Int"]["input"]>;
  jobDefinitionName?: InputMaybe<Scalars["String"]["input"]>;
  jobDefinitionRevision?: InputMaybe<Scalars["Int"]["input"]>;
  repositoryArn?: InputMaybe<Scalars["String"]["input"]>;
  tesContainerimageexecutorToContainerimageexecutorPtrId?: InputMaybe<AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInput>;
};

/** A connection to a list of `AwsEcrImage` values. */
export type AwsEcrImagesConnection = {
  __typename: "AwsEcrImagesConnection";
  /** A list of edges which contains the `AwsEcrImage` and cursor to aid in pagination. */
  edges: Array<AwsEcrImagesEdge>;
  /** A list of `AwsEcrImage` objects. */
  nodes: Array<AwsEcrImage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AwsEcrImage` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AwsEcrImage` edge in the connection. */
export type AwsEcrImagesEdge = {
  __typename: "AwsEcrImagesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AwsEcrImage` at the end of the edge. */
  node: AwsEcrImage;
};

/** Methods to use when ordering `AwsEcrImage`. */
export enum AwsEcrImagesOrderBy {
  ContainerimageexecutorPtrIdAsc = "CONTAINERIMAGEEXECUTOR_PTR_ID_ASC",
  ContainerimageexecutorPtrIdDesc = "CONTAINERIMAGEEXECUTOR_PTR_ID_DESC",
  JobDefinitionNameAsc = "JOB_DEFINITION_NAME_ASC",
  JobDefinitionNameDesc = "JOB_DEFINITION_NAME_DESC",
  JobDefinitionRevisionAsc = "JOB_DEFINITION_REVISION_ASC",
  JobDefinitionRevisionDesc = "JOB_DEFINITION_REVISION_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RepositoryArnAsc = "REPOSITORY_ARN_ASC",
  RepositoryArnDesc = "REPOSITORY_ARN_DESC",
}

export type AwsMockBatchTask = {
  __typename: "AwsMockBatchTask";
  /** Reads a single `AwsBatchTask` that is related to this `AwsMockBatchTask`. */
  awsBatchTaskByAwstaskexecutorPtrId: Maybe<AwsBatchTask>;
  awstaskexecutorPtrId: Scalars["UUID"]["output"];
  containerName: Maybe<Scalars["String"]["output"]>;
};

/** The fields on `awsMockBatchTask` to look up the row to connect. */
export type AwsMockBatchTaskAwsMockBatchTaskPkeyConnect = {
  awstaskexecutorPtrId: Scalars["UUID"]["input"];
};

/** The fields on `awsMockBatchTask` to look up the row to delete. */
export type AwsMockBatchTaskAwsMockBatchTaskPkeyDelete = {
  awstaskexecutorPtrId: Scalars["UUID"]["input"];
};

/** The `awsBatchTask` to be created by this mutation. */
export type AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchAwsBatchTaskCreateInput =
  {
    awsDataBucketName?: InputMaybe<Scalars["String"]["input"]>;
    awsInstanceType?: InputMaybe<Scalars["String"]["input"]>;
    awsJobId?: InputMaybe<Scalars["String"]["input"]>;
    awsJobQueue?: InputMaybe<Scalars["String"]["input"]>;
    awsJobStatus?: InputMaybe<Scalars["Int"]["input"]>;
    awsJobStatusReason: Scalars["String"]["input"];
    awsMockBatchTaskUsingTaskexecutorPtrId?: InputMaybe<AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInverseInput>;
    awsRegion?: InputMaybe<Scalars["String"]["input"]>;
    awsTaskPath?: InputMaybe<Scalars["String"]["input"]>;
    exitcode?: InputMaybe<Scalars["Int"]["input"]>;
    resultsProcessed: Scalars["Boolean"]["input"];
    taskexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
    tesTaskexecutorToTaskexecutorPtrId?: InputMaybe<AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInput>;
  };

/** The `awsMockBatchTask` to be created by this mutation. */
export type AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchAwsMockBatchTaskCreateInput =
  {
    awsBatchTaskToAwstaskexecutorPtrId?: InputMaybe<AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInput>;
    containerName?: InputMaybe<Scalars["String"]["input"]>;
  };

/** Input for the nested mutation of `awsBatchTask` in the `AwsMockBatchTaskInput` mutation. */
export type AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInput = {
  /** The primary key(s) for `awsBatchTask` for the far side of the relationship. */
  connectByTaskexecutorPtrId?: InputMaybe<AwsBatchTaskAwsBatchTaskPkeyConnect>;
  /** A `AwsBatchTaskInput` object that will be created and connected to this object. */
  create?: InputMaybe<AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchAwsBatchTaskCreateInput>;
  /** The primary key(s) for `awsBatchTask` for the far side of the relationship. */
  deleteByTaskexecutorPtrId?: InputMaybe<AwsBatchTaskAwsBatchTaskPkeyDelete>;
  /** The primary key(s) and patch data for `awsBatchTask` for the far side of the relationship. */
  updateByTaskexecutorPtrId?: InputMaybe<AwsBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchUsingAwsBatchTaskPkeyUpdate>;
};

/** Input for the nested mutation of `awsMockBatchTask` in the `AwsBatchTaskInput` mutation. */
export type AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInverseInput = {
  /** The primary key(s) for `awsMockBatchTask` for the far side of the relationship. */
  connectByAwstaskexecutorPtrId?: InputMaybe<AwsMockBatchTaskAwsMockBatchTaskPkeyConnect>;
  /** A `AwsMockBatchTaskInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchAwsMockBatchTaskCreateInput>
  >;
  /** The primary key(s) for `awsMockBatchTask` for the far side of the relationship. */
  deleteByAwstaskexecutorPtrId?: InputMaybe<AwsMockBatchTaskAwsMockBatchTaskPkeyDelete>;
  /** Flag indicating whether all other `awsMockBatchTask` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `awsMockBatchTask` for the far side of the relationship. */
  updateByAwstaskexecutorPtrId?: InputMaybe<AwsMockBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchUsingAwsMockBatchTaskPkeyUpdate>;
};

/**
 * A condition to be used against `AwsMockBatchTask` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AwsMockBatchTaskCondition = {
  /** Checks for equality with the object’s `awstaskexecutorPtrId` field. */
  awstaskexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `containerName` field. */
  containerName?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `AwsMockBatchTask` object types. All fields are combined with a logical ‘and.’ */
export type AwsMockBatchTaskFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AwsMockBatchTaskFilter>>;
  /** Filter by the object’s `awsBatchTaskByAwstaskexecutorPtrId` relation. */
  awsBatchTaskByAwstaskexecutorPtrId?: InputMaybe<AwsBatchTaskFilter>;
  /** Filter by the object’s `awstaskexecutorPtrId` field. */
  awstaskexecutorPtrId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `containerName` field. */
  containerName?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AwsMockBatchTaskFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AwsMockBatchTaskFilter>>;
};

/** An input for mutations affecting `AwsMockBatchTask` */
export type AwsMockBatchTaskInput = {
  awsBatchTaskToAwstaskexecutorPtrId?: InputMaybe<AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInput>;
  awstaskexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
  containerName?: InputMaybe<Scalars["String"]["input"]>;
};

/** The fields on `awsMockBatchTask` to look up the row to update. */
export type AwsMockBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchUsingAwsMockBatchTaskPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `awsMockBatchTask` being updated. */
    awsMockBatchTaskPatch: UpdateAwsMockBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchPatch;
    awstaskexecutorPtrId: Scalars["UUID"]["input"];
  };

/** Represents an update to a `AwsMockBatchTask`. Fields that are set will be updated. */
export type AwsMockBatchTaskPatch = {
  awsBatchTaskToAwstaskexecutorPtrId?: InputMaybe<AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInput>;
  awstaskexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
  containerName?: InputMaybe<Scalars["String"]["input"]>;
};

/** A connection to a list of `AwsMockBatchTask` values. */
export type AwsMockBatchTasksConnection = {
  __typename: "AwsMockBatchTasksConnection";
  /** A list of edges which contains the `AwsMockBatchTask` and cursor to aid in pagination. */
  edges: Array<AwsMockBatchTasksEdge>;
  /** A list of `AwsMockBatchTask` objects. */
  nodes: Array<AwsMockBatchTask>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AwsMockBatchTask` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AwsMockBatchTask` edge in the connection. */
export type AwsMockBatchTasksEdge = {
  __typename: "AwsMockBatchTasksEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AwsMockBatchTask` at the end of the edge. */
  node: AwsMockBatchTask;
};

/** Methods to use when ordering `AwsMockBatchTask`. */
export enum AwsMockBatchTasksOrderBy {
  AwstaskexecutorPtrIdAsc = "AWSTASKEXECUTOR_PTR_ID_ASC",
  AwstaskexecutorPtrIdDesc = "AWSTASKEXECUTOR_PTR_ID_DESC",
  ContainerNameAsc = "CONTAINER_NAME_ASC",
  ContainerNameDesc = "CONTAINER_NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type AwsMockEcrImage = {
  __typename: "AwsMockEcrImage";
  /** Reads a single `AwsEcrImage` that is related to this `AwsMockEcrImage`. */
  awsEcrImageByAwscontainerimageexecutorPtrId: Maybe<AwsEcrImage>;
  awscontainerimageexecutorPtrId: Scalars["Int"]["output"];
};

/** The fields on `awsMockEcrImage` to look up the row to connect. */
export type AwsMockEcrImageAwsMockEcrImagePkeyConnect = {
  awscontainerimageexecutorPtrId: Scalars["Int"]["input"];
};

/** The fields on `awsMockEcrImage` to look up the row to delete. */
export type AwsMockEcrImageAwsMockEcrImagePkeyDelete = {
  awscontainerimageexecutorPtrId: Scalars["Int"]["input"];
};

/** The `awsEcrImage` to be created by this mutation. */
export type AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIAwsEcrImageCreateInput =
  {
    awsMockEcrImageUsingContainerimageexecutorPtrId?: InputMaybe<AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInverseInput>;
    containerimageexecutorPtrId?: InputMaybe<Scalars["Int"]["input"]>;
    jobDefinitionName?: InputMaybe<Scalars["String"]["input"]>;
    jobDefinitionRevision?: InputMaybe<Scalars["Int"]["input"]>;
    repositoryArn?: InputMaybe<Scalars["String"]["input"]>;
    tesContainerimageexecutorToContainerimageexecutorPtrId?: InputMaybe<AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInput>;
  };

/** The `awsMockEcrImage` to be created by this mutation. */
export type AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIAwsMockEcrImageCreateInput =
  {
    awsEcrImageToAwscontainerimageexecutorPtrId?: InputMaybe<AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInput>;
  };

/** Input for the nested mutation of `awsEcrImage` in the `AwsMockEcrImageInput` mutation. */
export type AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInput = {
  /** The primary key(s) for `awsEcrImage` for the far side of the relationship. */
  connectByContainerimageexecutorPtrId?: InputMaybe<AwsEcrImageAwsEcrImagePkeyConnect>;
  /** A `AwsEcrImageInput` object that will be created and connected to this object. */
  create?: InputMaybe<AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIAwsEcrImageCreateInput>;
  /** The primary key(s) for `awsEcrImage` for the far side of the relationship. */
  deleteByContainerimageexecutorPtrId?: InputMaybe<AwsEcrImageAwsEcrImagePkeyDelete>;
  /** The primary key(s) and patch data for `awsEcrImage` for the far side of the relationship. */
  updateByContainerimageexecutorPtrId?: InputMaybe<AwsEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIUsingAwsEcrImagePkeyUpdate>;
};

/** Input for the nested mutation of `awsMockEcrImage` in the `AwsEcrImageInput` mutation. */
export type AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInverseInput = {
  /** The primary key(s) for `awsMockEcrImage` for the far side of the relationship. */
  connectByAwscontainerimageexecutorPtrId?: InputMaybe<AwsMockEcrImageAwsMockEcrImagePkeyConnect>;
  /** A `AwsMockEcrImageInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIAwsMockEcrImageCreateInput>
  >;
  /** The primary key(s) for `awsMockEcrImage` for the far side of the relationship. */
  deleteByAwscontainerimageexecutorPtrId?: InputMaybe<AwsMockEcrImageAwsMockEcrImagePkeyDelete>;
  /** Flag indicating whether all other `awsMockEcrImage` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `awsMockEcrImage` for the far side of the relationship. */
  updateByAwscontainerimageexecutorPtrId?: InputMaybe<AwsMockEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIUsingAwsMockEcrImagePkeyUpdate>;
};

/**
 * A condition to be used against `AwsMockEcrImage` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AwsMockEcrImageCondition = {
  /** Checks for equality with the object’s `awscontainerimageexecutorPtrId` field. */
  awscontainerimageexecutorPtrId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `AwsMockEcrImage` object types. All fields are combined with a logical ‘and.’ */
export type AwsMockEcrImageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AwsMockEcrImageFilter>>;
  /** Filter by the object’s `awsEcrImageByAwscontainerimageexecutorPtrId` relation. */
  awsEcrImageByAwscontainerimageexecutorPtrId?: InputMaybe<AwsEcrImageFilter>;
  /** Filter by the object’s `awscontainerimageexecutorPtrId` field. */
  awscontainerimageexecutorPtrId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AwsMockEcrImageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AwsMockEcrImageFilter>>;
};

/** An input for mutations affecting `AwsMockEcrImage` */
export type AwsMockEcrImageInput = {
  awsEcrImageToAwscontainerimageexecutorPtrId?: InputMaybe<AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInput>;
  awscontainerimageexecutorPtrId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `awsMockEcrImage` to look up the row to update. */
export type AwsMockEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIUsingAwsMockEcrImagePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `awsMockEcrImage` being updated. */
    awsMockEcrImagePatch: UpdateAwsMockEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIPatch;
    awscontainerimageexecutorPtrId: Scalars["Int"]["input"];
  };

/** Represents an update to a `AwsMockEcrImage`. Fields that are set will be updated. */
export type AwsMockEcrImagePatch = {
  awsEcrImageToAwscontainerimageexecutorPtrId?: InputMaybe<AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInput>;
  awscontainerimageexecutorPtrId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A connection to a list of `AwsMockEcrImage` values. */
export type AwsMockEcrImagesConnection = {
  __typename: "AwsMockEcrImagesConnection";
  /** A list of edges which contains the `AwsMockEcrImage` and cursor to aid in pagination. */
  edges: Array<AwsMockEcrImagesEdge>;
  /** A list of `AwsMockEcrImage` objects. */
  nodes: Array<AwsMockEcrImage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AwsMockEcrImage` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AwsMockEcrImage` edge in the connection. */
export type AwsMockEcrImagesEdge = {
  __typename: "AwsMockEcrImagesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AwsMockEcrImage` at the end of the edge. */
  node: AwsMockEcrImage;
};

/** Methods to use when ordering `AwsMockEcrImage`. */
export enum AwsMockEcrImagesOrderBy {
  AwscontainerimageexecutorPtrIdAsc = "AWSCONTAINERIMAGEEXECUTOR_PTR_ID_ASC",
  AwscontainerimageexecutorPtrIdDesc = "AWSCONTAINERIMAGEEXECUTOR_PTR_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type AwsMockS3File = {
  __typename: "AwsMockS3File";
  /** Reads a single `AwsS3File` that is related to this `AwsMockS3File`. */
  awsS3FileByAwsfileexecutorPtrId: Maybe<AwsS3File>;
  awsfileexecutorPtrId: Scalars["UUID"]["output"];
};

/** The fields on `awsMockS3File` to look up the row to connect. */
export type AwsMockS3FileAwsMockS3FilePkeyConnect = {
  awsfileexecutorPtrId: Scalars["UUID"]["input"];
};

/** The fields on `awsMockS3File` to look up the row to delete. */
export type AwsMockS3FileAwsMockS3FilePkeyDelete = {
  awsfileexecutorPtrId: Scalars["UUID"]["input"];
};

/** The `awsMockS3File` to be created by this mutation. */
export type AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiAwsMockS3FileCreateInput =
  {
    awsS3FileToAwsfileexecutorPtrId?: InputMaybe<AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInput>;
  };

/** The `awsS3File` to be created by this mutation. */
export type AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiAwsS3FileCreateInput =
  {
    awsMockS3FileUsingFileexecutorPtrId?: InputMaybe<AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInverseInput>;
    awsUploadPath: Scalars["String"]["input"];
    drsFileexecutorToFileexecutorPtrId?: InputMaybe<AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInput>;
    fileexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `awsS3File` in the `AwsMockS3FileInput` mutation. */
export type AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInput = {
  /** The primary key(s) for `awsS3File` for the far side of the relationship. */
  connectByFileexecutorPtrId?: InputMaybe<AwsS3FileAwsS3FilePkeyConnect>;
  /** A `AwsS3FileInput` object that will be created and connected to this object. */
  create?: InputMaybe<AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiAwsS3FileCreateInput>;
  /** The primary key(s) for `awsS3File` for the far side of the relationship. */
  deleteByFileexecutorPtrId?: InputMaybe<AwsS3FileAwsS3FilePkeyDelete>;
  /** The primary key(s) and patch data for `awsS3File` for the far side of the relationship. */
  updateByFileexecutorPtrId?: InputMaybe<AwsS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiUsingAwsS3FilePkeyUpdate>;
};

/** Input for the nested mutation of `awsMockS3File` in the `AwsS3FileInput` mutation. */
export type AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInverseInput = {
  /** The primary key(s) for `awsMockS3File` for the far side of the relationship. */
  connectByAwsfileexecutorPtrId?: InputMaybe<AwsMockS3FileAwsMockS3FilePkeyConnect>;
  /** A `AwsMockS3FileInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiAwsMockS3FileCreateInput>
  >;
  /** The primary key(s) for `awsMockS3File` for the far side of the relationship. */
  deleteByAwsfileexecutorPtrId?: InputMaybe<AwsMockS3FileAwsMockS3FilePkeyDelete>;
  /** Flag indicating whether all other `awsMockS3File` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `awsMockS3File` for the far side of the relationship. */
  updateByAwsfileexecutorPtrId?: InputMaybe<AwsMockS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiUsingAwsMockS3FilePkeyUpdate>;
};

/**
 * A condition to be used against `AwsMockS3File` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AwsMockS3FileCondition = {
  /** Checks for equality with the object’s `awsfileexecutorPtrId` field. */
  awsfileexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `AwsMockS3File` object types. All fields are combined with a logical ‘and.’ */
export type AwsMockS3FileFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AwsMockS3FileFilter>>;
  /** Filter by the object’s `awsS3FileByAwsfileexecutorPtrId` relation. */
  awsS3FileByAwsfileexecutorPtrId?: InputMaybe<AwsS3FileFilter>;
  /** Filter by the object’s `awsfileexecutorPtrId` field. */
  awsfileexecutorPtrId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AwsMockS3FileFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AwsMockS3FileFilter>>;
};

/** An input for mutations affecting `AwsMockS3File` */
export type AwsMockS3FileInput = {
  awsS3FileToAwsfileexecutorPtrId?: InputMaybe<AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInput>;
  awsfileexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `awsMockS3File` to look up the row to update. */
export type AwsMockS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiUsingAwsMockS3FilePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `awsMockS3File` being updated. */
    awsMockS3FilePatch: UpdateAwsMockS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiPatch;
    awsfileexecutorPtrId: Scalars["UUID"]["input"];
  };

/** Represents an update to a `AwsMockS3File`. Fields that are set will be updated. */
export type AwsMockS3FilePatch = {
  awsS3FileToAwsfileexecutorPtrId?: InputMaybe<AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInput>;
  awsfileexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A connection to a list of `AwsMockS3File` values. */
export type AwsMockS3FilesConnection = {
  __typename: "AwsMockS3FilesConnection";
  /** A list of edges which contains the `AwsMockS3File` and cursor to aid in pagination. */
  edges: Array<AwsMockS3FilesEdge>;
  /** A list of `AwsMockS3File` objects. */
  nodes: Array<AwsMockS3File>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AwsMockS3File` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AwsMockS3File` edge in the connection. */
export type AwsMockS3FilesEdge = {
  __typename: "AwsMockS3FilesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AwsMockS3File` at the end of the edge. */
  node: AwsMockS3File;
};

/** Methods to use when ordering `AwsMockS3File`. */
export enum AwsMockS3FilesOrderBy {
  AwsfileexecutorPtrIdAsc = "AWSFILEEXECUTOR_PTR_ID_ASC",
  AwsfileexecutorPtrIdDesc = "AWSFILEEXECUTOR_PTR_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type AwsS3File = {
  __typename: "AwsS3File";
  /** Reads a single `AwsMockS3File` that is related to this `AwsS3File`. */
  awsMockS3FileByAwsfileexecutorPtrId: Maybe<AwsMockS3File>;
  /**
   * Reads and enables pagination through a set of `AwsMockS3File`.
   * @deprecated Please use awsMockS3FileByAwsfileexecutorPtrId instead
   */
  awsMockS3FilesByAwsfileexecutorPtrId: AwsMockS3FilesConnection;
  awsUploadPath: Scalars["String"]["output"];
  /** Reads a single `DrsFileexecutor` that is related to this `AwsS3File`. */
  drsFileexecutorByFileexecutorPtrId: Maybe<DrsFileexecutor>;
  fileexecutorPtrId: Scalars["UUID"]["output"];
};

export type AwsS3FileAwsMockS3FilesByAwsfileexecutorPtrIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsMockS3FileCondition>;
  filter?: InputMaybe<AwsMockS3FileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsMockS3FilesOrderBy>>;
};

/** The fields on `awsS3File` to look up the row to connect. */
export type AwsS3FileAwsS3FilePkeyConnect = {
  fileexecutorPtrId: Scalars["UUID"]["input"];
};

/** The fields on `awsS3File` to look up the row to delete. */
export type AwsS3FileAwsS3FilePkeyDelete = {
  fileexecutorPtrId: Scalars["UUID"]["input"];
};

/**
 * A condition to be used against `AwsS3File` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AwsS3FileCondition = {
  /** Checks for equality with the object’s `awsUploadPath` field. */
  awsUploadPath?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `fileexecutorPtrId` field. */
  fileexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `awsS3File` to be created by this mutation. */
export type AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdAwsS3FileCreateInput =
  {
    awsMockS3FileUsingFileexecutorPtrId?: InputMaybe<AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInverseInput>;
    awsUploadPath: Scalars["String"]["input"];
    drsFileexecutorToFileexecutorPtrId?: InputMaybe<AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInput>;
  };

/** The `drsFileexecutor` to be created by this mutation. */
export type AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdDrsFileexecutorCreateInput =
  {
    awsS3FileUsingId?: InputMaybe<AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInput>;
    drsMockfileexecutorUsingId?: InputMaybe<DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInverseInput>;
    filesUsingId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInverseInput>;
    id: Scalars["UUID"]["input"];
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** Input for the nested mutation of `drsFileexecutor` in the `AwsS3FileInput` mutation. */
export type AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInput = {
  /** The primary key(s) for `drsFileexecutor` for the far side of the relationship. */
  connectById?: InputMaybe<DrsFileexecutorDrsFileexecutorPkeyConnect>;
  /** A `DrsFileexecutorInput` object that will be created and connected to this object. */
  create?: InputMaybe<AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdDrsFileexecutorCreateInput>;
  /** The primary key(s) for `drsFileexecutor` for the far side of the relationship. */
  deleteById?: InputMaybe<DrsFileexecutorDrsFileexecutorPkeyDelete>;
  /** The primary key(s) and patch data for `drsFileexecutor` for the far side of the relationship. */
  updateById?: InputMaybe<DrsFileexecutorOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdUsingDrsFileexecutorPkeyUpdate>;
};

/** Input for the nested mutation of `awsS3File` in the `DrsFileexecutorInput` mutation. */
export type AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInverseInput =
  {
    /** The primary key(s) for `awsS3File` for the far side of the relationship. */
    connectByFileexecutorPtrId?: InputMaybe<AwsS3FileAwsS3FilePkeyConnect>;
    /** A `AwsS3FileInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdAwsS3FileCreateInput>
    >;
    /** The primary key(s) for `awsS3File` for the far side of the relationship. */
    deleteByFileexecutorPtrId?: InputMaybe<AwsS3FileAwsS3FilePkeyDelete>;
    /** Flag indicating whether all other `awsS3File` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `awsS3File` for the far side of the relationship. */
    updateByFileexecutorPtrId?: InputMaybe<AwsS3FileOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdUsingAwsS3FilePkeyUpdate>;
  };

/** A filter to be used against `AwsS3File` object types. All fields are combined with a logical ‘and.’ */
export type AwsS3FileFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AwsS3FileFilter>>;
  /** Filter by the object’s `awsMockS3FileByAwsfileexecutorPtrId` relation. */
  awsMockS3FileByAwsfileexecutorPtrId?: InputMaybe<AwsMockS3FileFilter>;
  /** A related `awsMockS3FileByAwsfileexecutorPtrId` exists. */
  awsMockS3FileByAwsfileexecutorPtrIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `awsUploadPath` field. */
  awsUploadPath?: InputMaybe<StringFilter>;
  /** Filter by the object’s `drsFileexecutorByFileexecutorPtrId` relation. */
  drsFileexecutorByFileexecutorPtrId?: InputMaybe<DrsFileexecutorFilter>;
  /** Filter by the object’s `fileexecutorPtrId` field. */
  fileexecutorPtrId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AwsS3FileFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AwsS3FileFilter>>;
};

/** An input for mutations affecting `AwsS3File` */
export type AwsS3FileInput = {
  awsMockS3FileUsingFileexecutorPtrId?: InputMaybe<AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInverseInput>;
  awsUploadPath: Scalars["String"]["input"];
  drsFileexecutorToFileexecutorPtrId?: InputMaybe<AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInput>;
  fileexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `awsS3File` to look up the row to update. */
export type AwsS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiUsingAwsS3FilePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `awsS3File` being updated. */
    awsS3FilePatch: UpdateAwsS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiPatch;
    fileexecutorPtrId: Scalars["UUID"]["input"];
  };

/** The fields on `awsS3File` to look up the row to update. */
export type AwsS3FileOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdUsingAwsS3FilePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `awsS3File` being updated. */
    awsS3FilePatch: UpdateAwsS3FileOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdPatch;
    fileexecutorPtrId: Scalars["UUID"]["input"];
  };

/** Represents an update to a `AwsS3File`. Fields that are set will be updated. */
export type AwsS3FilePatch = {
  awsMockS3FileUsingFileexecutorPtrId?: InputMaybe<AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInverseInput>;
  awsUploadPath?: InputMaybe<Scalars["String"]["input"]>;
  drsFileexecutorToFileexecutorPtrId?: InputMaybe<AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInput>;
  fileexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A connection to a list of `AwsS3File` values. */
export type AwsS3FilesConnection = {
  __typename: "AwsS3FilesConnection";
  /** A list of edges which contains the `AwsS3File` and cursor to aid in pagination. */
  edges: Array<AwsS3FilesEdge>;
  /** A list of `AwsS3File` objects. */
  nodes: Array<AwsS3File>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AwsS3File` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `AwsS3File` edge in the connection. */
export type AwsS3FilesEdge = {
  __typename: "AwsS3FilesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AwsS3File` at the end of the edge. */
  node: AwsS3File;
};

/** Methods to use when ordering `AwsS3File`. */
export enum AwsS3FilesOrderBy {
  AwsUploadPathAsc = "AWS_UPLOAD_PATH_ASC",
  AwsUploadPathDesc = "AWS_UPLOAD_PATH_DESC",
  FileexecutorPtrIdAsc = "FILEEXECUTOR_PTR_ID_ASC",
  FileexecutorPtrIdDesc = "FILEEXECUTOR_PTR_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["BigFloat"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["BigFloat"]["input"]>>;
};

/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
};

/** All input for the `changeUserSession` mutation. */
export type ChangeUserSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  userid: Scalars["UUID"]["input"];
};

/** The output of our `changeUserSession` mutation. */
export type ChangeUserSessionPayload = {
  __typename: "ChangeUserSessionPayload";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserSession`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  applicationUserSession: Maybe<ApplicationUserSession>;
  /** An edge for our `ApplicationUserSession`. May be used by Relay 1. */
  applicationUserSessionEdge: Maybe<ApplicationUserSessionsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `ApplicationUserSession`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our `changeUserSession` mutation. */
export type ChangeUserSessionPayloadApplicationUserSessionEdgeArgs = {
  orderBy?: InputMaybe<Array<ApplicationUserSessionsOrderBy>>;
};

export type ContainerImage = {
  __typename: "ContainerImage";
  created: Scalars["Datetime"]["output"];
  executorId: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  repository: Scalars["String"]["output"];
  tag: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `Task`. */
  tasksByContainerImageId: TasksConnection;
  /** Reads a single `TesContainerimageexecutor` that is related to this `ContainerImage`. */
  tesContainerimageexecutorByExecutorId: Maybe<TesContainerimageexecutor>;
  /** Reads a single `ToolboxVersion` that is related to this `ContainerImage`. */
  toolboxVersionByToolboxVersionId: Maybe<ToolboxVersion>;
  toolboxVersionId: Scalars["Int"]["output"];
  /** Reads and enables pagination through a set of `Workflow`. */
  workflowsByContainerId: WorkflowsConnection;
};

export type ContainerImageTasksByContainerImageIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskCondition>;
  filter?: InputMaybe<TaskFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
};

export type ContainerImageWorkflowsByContainerIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowCondition>;
  filter?: InputMaybe<WorkflowFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowsOrderBy>>;
};

/**
 * A condition to be used against `ContainerImage` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ContainerImageCondition = {
  /** Checks for equality with the object’s `created` field. */
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `executorId` field. */
  executorId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `repository` field. */
  repository?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `tag` field. */
  tag?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `toolboxVersionId` field. */
  toolboxVersionId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `containerImage` to look up the row to connect. */
export type ContainerImageContainerImageExecutorIdKeyConnect = {
  executorId: Scalars["Int"]["input"];
};

/** The fields on `containerImage` to look up the row to delete. */
export type ContainerImageContainerImageExecutorIdKeyDelete = {
  executorId: Scalars["Int"]["input"];
};

/** The fields on `containerImage` to look up the row to connect. */
export type ContainerImageContainerImageNameTagUniqueConnect = {
  name: Scalars["String"]["input"];
  tag: Scalars["String"]["input"];
};

/** The fields on `containerImage` to look up the row to delete. */
export type ContainerImageContainerImageNameTagUniqueDelete = {
  name: Scalars["String"]["input"];
  tag: Scalars["String"]["input"];
};

/** The fields on `containerImage` to look up the row to connect. */
export type ContainerImageContainerImagePkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `containerImage` to look up the row to delete. */
export type ContainerImageContainerImagePkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** The fields on `containerImage` to look up the row to connect. */
export type ContainerImageContainerImageRepositoryTagUniqueConnect = {
  repository: Scalars["String"]["input"];
  tag: Scalars["String"]["input"];
};

/** The fields on `containerImage` to look up the row to delete. */
export type ContainerImageContainerImageRepositoryTagUniqueDelete = {
  repository: Scalars["String"]["input"];
  tag: Scalars["String"]["input"];
};

/** The fields on `containerImage` to look up the row to connect. */
export type ContainerImageContainerImageToolboxVersionIdE9Dede5FUniqConnect = {
  toolboxVersionId: Scalars["Int"]["input"];
};

/** The fields on `containerImage` to look up the row to delete. */
export type ContainerImageContainerImageToolboxVersionIdE9Dede5FUniqDelete = {
  toolboxVersionId: Scalars["Int"]["input"];
};

/** The `containerImage` to be created by this mutation. */
export type ContainerImageExecutorId68979Fb6FkTesContaContainerImageCreateInput =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name: Scalars["String"]["input"];
    repository: Scalars["String"]["input"];
    tag: Scalars["String"]["input"];
    tasksUsingId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInverseInput>;
    tesContainerimageexecutorToExecutorId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInput>;
    toolboxVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxVersionToToolboxVersionId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInput>;
    workflowsUsingId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInverseInput>;
  };

/** Input for the nested mutation of `tesContainerimageexecutor` in the `ContainerImageInput` mutation. */
export type ContainerImageExecutorId68979Fb6FkTesContaInput = {
  /** The primary key(s) for `tesContainerimageexecutor` for the far side of the relationship. */
  connectById?: InputMaybe<TesContainerimageexecutorTesContainerimageexecutorPkeyConnect>;
  /** A `TesContainerimageexecutorInput` object that will be created and connected to this object. */
  create?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaTesContainerimageexecutorCreateInput>;
  /** The primary key(s) for `tesContainerimageexecutor` for the far side of the relationship. */
  deleteById?: InputMaybe<TesContainerimageexecutorTesContainerimageexecutorPkeyDelete>;
  /** The primary key(s) and patch data for `tesContainerimageexecutor` for the far side of the relationship. */
  updateById?: InputMaybe<TesContainerimageexecutorOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingTesContainerimageexecutorPkeyUpdate>;
};

/** Input for the nested mutation of `containerImage` in the `TesContainerimageexecutorInput` mutation. */
export type ContainerImageExecutorId68979Fb6FkTesContaInverseInput = {
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectByExecutorId?: InputMaybe<ContainerImageContainerImageExecutorIdKeyConnect>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectById?: InputMaybe<ContainerImageContainerImagePkeyConnect>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectByNameAndTag?: InputMaybe<ContainerImageContainerImageNameTagUniqueConnect>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectByRepositoryAndTag?: InputMaybe<ContainerImageContainerImageRepositoryTagUniqueConnect>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectByToolboxVersionId?: InputMaybe<ContainerImageContainerImageToolboxVersionIdE9Dede5FUniqConnect>;
  /** A `ContainerImageInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ContainerImageExecutorId68979Fb6FkTesContaContainerImageCreateInput>
  >;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteByExecutorId?: InputMaybe<ContainerImageContainerImageExecutorIdKeyDelete>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteById?: InputMaybe<ContainerImageContainerImagePkeyDelete>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteByNameAndTag?: InputMaybe<ContainerImageContainerImageNameTagUniqueDelete>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteByRepositoryAndTag?: InputMaybe<ContainerImageContainerImageRepositoryTagUniqueDelete>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteByToolboxVersionId?: InputMaybe<ContainerImageContainerImageToolboxVersionIdE9Dede5FUniqDelete>;
  /** Flag indicating whether all other `containerImage` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateByExecutorId?: InputMaybe<ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageExecutorIdKeyUpdate>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateById?: InputMaybe<ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImagePkeyUpdate>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateByNameAndTag?: InputMaybe<ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageNameTagUniqueUpdate>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateByRepositoryAndTag?: InputMaybe<ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageRepositoryTagUniqueUpdate>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateByToolboxVersionId?: InputMaybe<ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate>;
};

/** The `tesContainerimageexecutor` to be created by this mutation. */
export type ContainerImageExecutorId68979Fb6FkTesContaTesContainerimageexecutorCreateInput =
  {
    awsEcrImageUsingId?: InputMaybe<AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInverseInput>;
    containerImageUsingId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
    tesMockcontainerimageexecutorUsingId?: InputMaybe<TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInverseInput>;
  };

/** A filter to be used against `ContainerImage` object types. All fields are combined with a logical ‘and.’ */
export type ContainerImageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ContainerImageFilter>>;
  /** Filter by the object’s `created` field. */
  created?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `executorId` field. */
  executorId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ContainerImageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ContainerImageFilter>>;
  /** Filter by the object’s `repository` field. */
  repository?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tag` field. */
  tag?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tasksByContainerImageId` relation. */
  tasksByContainerImageId?: InputMaybe<ContainerImageToManyTaskFilter>;
  /** Some related `tasksByContainerImageId` exist. */
  tasksByContainerImageIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tesContainerimageexecutorByExecutorId` relation. */
  tesContainerimageexecutorByExecutorId?: InputMaybe<TesContainerimageexecutorFilter>;
  /** A related `tesContainerimageexecutorByExecutorId` exists. */
  tesContainerimageexecutorByExecutorIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `toolboxVersionByToolboxVersionId` relation. */
  toolboxVersionByToolboxVersionId?: InputMaybe<ToolboxVersionFilter>;
  /** Filter by the object’s `toolboxVersionId` field. */
  toolboxVersionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `workflowsByContainerId` relation. */
  workflowsByContainerId?: InputMaybe<ContainerImageToManyWorkflowFilter>;
  /** Some related `workflowsByContainerId` exist. */
  workflowsByContainerIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** An input for mutations affecting `ContainerImage` */
export type ContainerImageInput = {
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  executorId?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  repository: Scalars["String"]["input"];
  tag: Scalars["String"]["input"];
  tasksUsingId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInverseInput>;
  tesContainerimageexecutorToExecutorId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInput>;
  toolboxVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolboxVersionToToolboxVersionId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInput>;
  workflowsUsingId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInverseInput>;
};

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageExecutorIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaPatch;
    executorId: Scalars["Int"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageNameTagUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaPatch;
    name: Scalars["String"]["input"];
    tag: Scalars["String"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImagePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageRepositoryTagUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaPatch;
    repository: Scalars["String"]["input"];
    tag: Scalars["String"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaPatch;
    toolboxVersionId: Scalars["Int"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageExecutorIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVPatch;
    executorId: Scalars["Int"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageNameTagUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVPatch;
    name: Scalars["String"]["input"];
    tag: Scalars["String"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImagePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageRepositoryTagUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVPatch;
    repository: Scalars["String"]["input"];
    tag: Scalars["String"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVPatch;
    toolboxVersionId: Scalars["Int"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageExecutorIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdPatch;
    executorId: Scalars["Int"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageNameTagUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdPatch;
    name: Scalars["String"]["input"];
    tag: Scalars["String"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImagePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageRepositoryTagUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdPatch;
    repository: Scalars["String"]["input"];
    tag: Scalars["String"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdPatch;
    toolboxVersionId: Scalars["Int"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageExecutorIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdPatch;
    executorId: Scalars["Int"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageNameTagUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdPatch;
    name: Scalars["String"]["input"];
    tag: Scalars["String"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImagePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageRepositoryTagUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdPatch;
    repository: Scalars["String"]["input"];
    tag: Scalars["String"]["input"];
  };

/** The fields on `containerImage` to look up the row to update. */
export type ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate =
  {
    /** An object where the defined keys will be set on the `containerImage` being updated. */
    containerImagePatch: UpdateContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdPatch;
    toolboxVersionId: Scalars["Int"]["input"];
  };

/** Represents an update to a `ContainerImage`. Fields that are set will be updated. */
export type ContainerImagePatch = {
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  executorId?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  repository?: InputMaybe<Scalars["String"]["input"]>;
  tag?: InputMaybe<Scalars["String"]["input"]>;
  tasksUsingId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInverseInput>;
  tesContainerimageexecutorToExecutorId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInput>;
  toolboxVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolboxVersionToToolboxVersionId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInput>;
  workflowsUsingId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInverseInput>;
};

/** A filter to be used against many `Task` object types. All fields are combined with a logical ‘and.’ */
export type ContainerImageToManyTaskFilter = {
  /** Every related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TaskFilter>;
  /** No related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TaskFilter>;
  /** Some related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TaskFilter>;
};

/** A filter to be used against many `Workflow` object types. All fields are combined with a logical ‘and.’ */
export type ContainerImageToManyWorkflowFilter = {
  /** Every related `Workflow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowFilter>;
  /** No related `Workflow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowFilter>;
  /** Some related `Workflow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowFilter>;
};

/** The `containerImage` to be created by this mutation. */
export type ContainerImageToolboxVersionIdE9Dede5FFkToolboxVContainerImageCreateInput =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name: Scalars["String"]["input"];
    repository: Scalars["String"]["input"];
    tag: Scalars["String"]["input"];
    tasksUsingId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInverseInput>;
    tesContainerimageexecutorToExecutorId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInput>;
    toolboxVersionToToolboxVersionId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInput>;
    workflowsUsingId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInverseInput>;
  };

/** Input for the nested mutation of `toolboxVersion` in the `ContainerImageInput` mutation. */
export type ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInput = {
  /** The primary key(s) for `toolboxVersion` for the far side of the relationship. */
  connectById?: InputMaybe<ToolboxVersionToolboxVersionPkeyConnect>;
  /** The primary key(s) for `toolboxVersion` for the far side of the relationship. */
  connectByToolboxIdAndVersion?: InputMaybe<ToolboxVersionToolboxversionToolboxVersionUniqueConnect>;
  /** A `ToolboxVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVToolboxVersionCreateInput>;
  /** The primary key(s) for `toolboxVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolboxVersionToolboxVersionPkeyDelete>;
  /** The primary key(s) for `toolboxVersion` for the far side of the relationship. */
  deleteByToolboxIdAndVersion?: InputMaybe<ToolboxVersionToolboxversionToolboxVersionUniqueDelete>;
  /** The primary key(s) and patch data for `toolboxVersion` for the far side of the relationship. */
  updateById?: InputMaybe<ToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingToolboxVersionPkeyUpdate>;
  /** The primary key(s) and patch data for `toolboxVersion` for the far side of the relationship. */
  updateByToolboxIdAndVersion?: InputMaybe<ToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingToolboxversionToolboxVersionUniqueUpdate>;
};

/** Input for the nested mutation of `containerImage` in the `ToolboxVersionInput` mutation. */
export type ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInverseInput = {
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectByExecutorId?: InputMaybe<ContainerImageContainerImageExecutorIdKeyConnect>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectById?: InputMaybe<ContainerImageContainerImagePkeyConnect>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectByNameAndTag?: InputMaybe<ContainerImageContainerImageNameTagUniqueConnect>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectByRepositoryAndTag?: InputMaybe<ContainerImageContainerImageRepositoryTagUniqueConnect>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectByToolboxVersionId?: InputMaybe<ContainerImageContainerImageToolboxVersionIdE9Dede5FUniqConnect>;
  /** A `ContainerImageInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVContainerImageCreateInput>
  >;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteByExecutorId?: InputMaybe<ContainerImageContainerImageExecutorIdKeyDelete>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteById?: InputMaybe<ContainerImageContainerImagePkeyDelete>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteByNameAndTag?: InputMaybe<ContainerImageContainerImageNameTagUniqueDelete>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteByRepositoryAndTag?: InputMaybe<ContainerImageContainerImageRepositoryTagUniqueDelete>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteByToolboxVersionId?: InputMaybe<ContainerImageContainerImageToolboxVersionIdE9Dede5FUniqDelete>;
  /** Flag indicating whether all other `containerImage` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateByExecutorId?: InputMaybe<ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageExecutorIdKeyUpdate>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateById?: InputMaybe<ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImagePkeyUpdate>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateByNameAndTag?: InputMaybe<ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageNameTagUniqueUpdate>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateByRepositoryAndTag?: InputMaybe<ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageRepositoryTagUniqueUpdate>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateByToolboxVersionId?: InputMaybe<ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate>;
};

/** The `toolboxVersion` to be created by this mutation. */
export type ContainerImageToolboxVersionIdE9Dede5FFkToolboxVToolboxVersionCreateInput =
  {
    containerImageUsingId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    maturity: Scalars["Int"]["input"];
    toolboxId?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolboxSpecV1?: InputMaybe<Scalars["JSON"]["input"]>;
    toolboxToToolboxId?: InputMaybe<ToolboxVersionToolboxId5Cc86066FkToolboxIdInput>;
    toolboxVersionExitcodesUsingId?: InputMaybe<ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInverseInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInverseInput>;
    version: Scalars["String"]["input"];
  };

/** A connection to a list of `ContainerImage` values. */
export type ContainerImagesConnection = {
  __typename: "ContainerImagesConnection";
  /** A list of edges which contains the `ContainerImage` and cursor to aid in pagination. */
  edges: Array<ContainerImagesEdge>;
  /** A list of `ContainerImage` objects. */
  nodes: Array<ContainerImage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ContainerImage` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ContainerImage` edge in the connection. */
export type ContainerImagesEdge = {
  __typename: "ContainerImagesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ContainerImage` at the end of the edge. */
  node: ContainerImage;
};

/** Methods to use when ordering `ContainerImage`. */
export enum ContainerImagesOrderBy {
  CreatedAsc = "CREATED_ASC",
  CreatedDesc = "CREATED_DESC",
  ExecutorIdAsc = "EXECUTOR_ID_ASC",
  ExecutorIdDesc = "EXECUTOR_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RepositoryAsc = "REPOSITORY_ASC",
  RepositoryDesc = "REPOSITORY_DESC",
  TagAsc = "TAG_ASC",
  TagDesc = "TAG_DESC",
  ToolboxVersionIdAsc = "TOOLBOX_VERSION_ID_ASC",
  ToolboxVersionIdDesc = "TOOLBOX_VERSION_ID_DESC",
}

export type Cost = {
  __typename: "Cost";
  costType: Scalars["Int"]["output"];
  currency: Scalars["Int"]["output"];
  date: Scalars["Datetime"]["output"];
  /** Reads and enables pagination through a set of `FileActivity`. */
  fileActivitiesByCostId: FileActivitiesConnection;
  id: Scalars["BigInt"]["output"];
  platform: Maybe<Scalars["Int"]["output"]>;
  rateCode: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `TaskActivity`. */
  taskActivitiesByCostId: TaskActivitiesConnection;
  /** Reads a single `Tenant` that is related to this `Cost`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  value: Scalars["BigFloat"]["output"];
};

export type CostFileActivitiesByCostIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileActivityCondition>;
  filter?: InputMaybe<FileActivityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileActivitiesOrderBy>>;
};

export type CostTaskActivitiesByCostIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskActivityCondition>;
  filter?: InputMaybe<TaskActivityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TaskActivitiesOrderBy>>;
};

/** A condition to be used against `Cost` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CostCondition = {
  /** Checks for equality with the object’s `costType` field. */
  costType?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `date` field. */
  date?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `platform` field. */
  platform?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `rateCode` field. */
  rateCode?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars["BigFloat"]["input"]>;
};

/** The fields on `cost` to look up the row to connect. */
export type CostCostPkeyConnect = {
  id: Scalars["BigInt"]["input"];
};

/** The fields on `cost` to look up the row to delete. */
export type CostCostPkeyDelete = {
  id: Scalars["BigInt"]["input"];
};

/** A filter to be used against `Cost` object types. All fields are combined with a logical ‘and.’ */
export type CostFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CostFilter>>;
  /** Filter by the object’s `costType` field. */
  costType?: InputMaybe<IntFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: InputMaybe<IntFilter>;
  /** Filter by the object’s `date` field. */
  date?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fileActivitiesByCostId` relation. */
  fileActivitiesByCostId?: InputMaybe<CostToManyFileActivityFilter>;
  /** Some related `fileActivitiesByCostId` exist. */
  fileActivitiesByCostIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CostFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CostFilter>>;
  /** Filter by the object’s `platform` field. */
  platform?: InputMaybe<IntFilter>;
  /** Filter by the object’s `rateCode` field. */
  rateCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `taskActivitiesByCostId` relation. */
  taskActivitiesByCostId?: InputMaybe<CostToManyTaskActivityFilter>;
  /** Some related `taskActivitiesByCostId` exist. */
  taskActivitiesByCostIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<BigFloatFilter>;
};

/** An input for mutations affecting `Cost` */
export type CostInput = {
  costType: Scalars["Int"]["input"];
  currency: Scalars["Int"]["input"];
  date?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInverseInput>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  platform?: InputMaybe<Scalars["Int"]["input"]>;
  rateCode?: InputMaybe<Scalars["String"]["input"]>;
  taskActivitiesUsingId?: InputMaybe<TaskActivityCostId49E66058FkCostIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInput>;
  value: Scalars["BigFloat"]["input"];
};

/** The fields on `cost` to look up the row to update. */
export type CostOnCostForCostTenantId7Dcd58A7FkTenantIdUsingCostPkeyUpdate = {
  /** An object where the defined keys will be set on the `cost` being updated. */
  costPatch: UpdateCostOnCostForCostTenantId7Dcd58A7FkTenantIdPatch;
  id: Scalars["BigInt"]["input"];
};

/** The fields on `cost` to look up the row to update. */
export type CostOnFileActivityForFileActivityCostId2A88D90AFkCostIdUsingCostPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `cost` being updated. */
    costPatch: UpdateCostOnFileActivityForFileActivityCostId2A88D90AFkCostIdPatch;
    id: Scalars["BigInt"]["input"];
  };

/** The fields on `cost` to look up the row to update. */
export type CostOnTaskActivityForTaskActivityCostId49E66058FkCostIdUsingCostPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `cost` being updated. */
    costPatch: UpdateCostOnTaskActivityForTaskActivityCostId49E66058FkCostIdPatch;
    id: Scalars["BigInt"]["input"];
  };

/** Represents an update to a `Cost`. Fields that are set will be updated. */
export type CostPatch = {
  costType?: InputMaybe<Scalars["Int"]["input"]>;
  currency?: InputMaybe<Scalars["Int"]["input"]>;
  date?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInverseInput>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  platform?: InputMaybe<Scalars["Int"]["input"]>;
  rateCode?: InputMaybe<Scalars["String"]["input"]>;
  taskActivitiesUsingId?: InputMaybe<TaskActivityCostId49E66058FkCostIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInput>;
  value?: InputMaybe<Scalars["BigFloat"]["input"]>;
};

/** The `cost` to be created by this mutation. */
export type CostTenantId7Dcd58A7FkTenantIdCostCreateInput = {
  costType: Scalars["Int"]["input"];
  currency: Scalars["Int"]["input"];
  date?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInverseInput>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  platform?: InputMaybe<Scalars["Int"]["input"]>;
  rateCode?: InputMaybe<Scalars["String"]["input"]>;
  taskActivitiesUsingId?: InputMaybe<TaskActivityCostId49E66058FkCostIdInverseInput>;
  tenantToTenantId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInput>;
  value: Scalars["BigFloat"]["input"];
};

/** Input for the nested mutation of `tenant` in the `CostInput` mutation. */
export type CostTenantId7Dcd58A7FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnCostForCostTenantId7Dcd58A7FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnCostForCostTenantId7Dcd58A7FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnCostForCostTenantId7Dcd58A7FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `cost` in the `TenantInput` mutation. */
export type CostTenantId7Dcd58A7FkTenantIdInverseInput = {
  /** The primary key(s) for `cost` for the far side of the relationship. */
  connectById?: InputMaybe<Array<CostCostPkeyConnect>>;
  /** A `CostInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<CostTenantId7Dcd58A7FkTenantIdCostCreateInput>>;
  /** The primary key(s) for `cost` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<CostCostPkeyDelete>>;
  /** Flag indicating whether all other `cost` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `cost` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<CostOnCostForCostTenantId7Dcd58A7FkTenantIdUsingCostPkeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type CostTenantId7Dcd58A7FkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** A filter to be used against many `FileActivity` object types. All fields are combined with a logical ‘and.’ */
export type CostToManyFileActivityFilter = {
  /** Every related `FileActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileActivityFilter>;
  /** No related `FileActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileActivityFilter>;
  /** Some related `FileActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileActivityFilter>;
};

/** A filter to be used against many `TaskActivity` object types. All fields are combined with a logical ‘and.’ */
export type CostToManyTaskActivityFilter = {
  /** Every related `TaskActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TaskActivityFilter>;
  /** No related `TaskActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TaskActivityFilter>;
  /** Some related `TaskActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TaskActivityFilter>;
};

/** A connection to a list of `Cost` values. */
export type CostsConnection = {
  __typename: "CostsConnection";
  /** A list of edges which contains the `Cost` and cursor to aid in pagination. */
  edges: Array<CostsEdge>;
  /** A list of `Cost` objects. */
  nodes: Array<Cost>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Cost` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Cost` edge in the connection. */
export type CostsEdge = {
  __typename: "CostsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Cost` at the end of the edge. */
  node: Cost;
};

/** Methods to use when ordering `Cost`. */
export enum CostsOrderBy {
  CostTypeAsc = "COST_TYPE_ASC",
  CostTypeDesc = "COST_TYPE_DESC",
  CurrencyAsc = "CURRENCY_ASC",
  CurrencyDesc = "CURRENCY_DESC",
  DateAsc = "DATE_ASC",
  DateDesc = "DATE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PlatformAsc = "PLATFORM_ASC",
  PlatformDesc = "PLATFORM_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RateCodeAsc = "RATE_CODE_ASC",
  RateCodeDesc = "RATE_CODE_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  ValueAsc = "VALUE_ASC",
  ValueDesc = "VALUE_DESC",
}

/** All input for the create `AnalysisTableGroup` mutation. */
export type CreateAnalysisTableGroupInput = {
  /** The `AnalysisTableGroup` to be created by this mutation. */
  analysisTableGroup: AnalysisTableGroupInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AnalysisTableGroup` mutation. */
export type CreateAnalysisTableGroupPayload = {
  __typename: "CreateAnalysisTableGroupPayload";
  /** The `AnalysisTableGroup` that was created by this mutation. */
  analysisTableGroup: Maybe<AnalysisTableGroup>;
  /** An edge for our `AnalysisTableGroup`. May be used by Relay 1. */
  analysisTableGroupEdge: Maybe<AnalysisTableGroupsEdge>;
  /** Reads a single `ApplicationUser` that is related to this `AnalysisTableGroup`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Project` that is related to this `AnalysisTableGroup`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `AnalysisTableGroup`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `Tool` that is related to this `AnalysisTableGroup`. */
  toolByToolId: Maybe<Tool>;
};

/** The output of our create `AnalysisTableGroup` mutation. */
export type CreateAnalysisTableGroupPayloadAnalysisTableGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<AnalysisTableGroupsOrderBy>>;
};

/** All input for the create `AnalysisTable` mutation. */
export type CreateAnalysisTableInput = {
  /** The `AnalysisTable` to be created by this mutation. */
  analysisTable: AnalysisTableInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AnalysisTable` mutation. */
export type CreateAnalysisTablePayload = {
  __typename: "CreateAnalysisTablePayload";
  /** The `AnalysisTable` that was created by this mutation. */
  analysisTable: Maybe<AnalysisTable>;
  /** An edge for our `AnalysisTable`. May be used by Relay 1. */
  analysisTableEdge: Maybe<AnalysisTablesEdge>;
  /** Reads a single `AnalysisTableGroup` that is related to this `AnalysisTable`. */
  analysisTableGroupByGroupId: Maybe<AnalysisTableGroup>;
  /** Reads a single `ApplicationUser` that is related to this `AnalysisTable`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Project` that is related to this `AnalysisTable`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `AnalysisTable`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `ToolVersion` that is related to this `AnalysisTable`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our create `AnalysisTable` mutation. */
export type CreateAnalysisTablePayloadAnalysisTableEdgeArgs = {
  orderBy?: InputMaybe<Array<AnalysisTablesOrderBy>>;
};

/** All input for the create `AnalysisTableRowAttachResult` mutation. */
export type CreateAnalysisTableRowAttachResultInput = {
  /** The `AnalysisTableRowAttachResult` to be created by this mutation. */
  analysisTableRowAttachResult: AnalysisTableRowAttachResultInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AnalysisTableRowAttachResult` mutation. */
export type CreateAnalysisTableRowAttachResultPayload = {
  __typename: "CreateAnalysisTableRowAttachResultPayload";
  /** The `AnalysisTableRowAttachResult` that was created by this mutation. */
  analysisTableRowAttachResult: Maybe<AnalysisTableRowAttachResult>;
  /** An edge for our `AnalysisTableRowAttachResult`. May be used by Relay 1. */
  analysisTableRowAttachResultEdge: Maybe<AnalysisTableRowAttachResultsEdge>;
  /** Reads a single `AnalysisTableRow` that is related to this `AnalysisTableRowAttachResult`. */
  analysisTableRowByAnalysisTableRowId: Maybe<AnalysisTableRow>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Project` that is related to this `AnalysisTableRowAttachResult`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `AnalysisTableRowAttachResult` mutation. */
export type CreateAnalysisTableRowAttachResultPayloadAnalysisTableRowAttachResultEdgeArgs =
  {
    orderBy?: InputMaybe<Array<AnalysisTableRowAttachResultsOrderBy>>;
  };

/** All input for the create `AnalysisTableRow` mutation. */
export type CreateAnalysisTableRowInput = {
  /** The `AnalysisTableRow` to be created by this mutation. */
  analysisTableRow: AnalysisTableRowInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AnalysisTableRow` mutation. */
export type CreateAnalysisTableRowPayload = {
  __typename: "CreateAnalysisTableRowPayload";
  /** Reads a single `AnalysisTable` that is related to this `AnalysisTableRow`. */
  analysisTableByTableId: Maybe<AnalysisTable>;
  /** The `AnalysisTableRow` that was created by this mutation. */
  analysisTableRow: Maybe<AnalysisTableRow>;
  /** An edge for our `AnalysisTableRow`. May be used by Relay 1. */
  analysisTableRowEdge: Maybe<AnalysisTableRowsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Project` that is related to this `AnalysisTableRow`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Task` that is related to this `AnalysisTableRow`. */
  taskByTaskId: Maybe<Task>;
  /** Reads a single `Tenant` that is related to this `AnalysisTableRow`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `ToolVersion` that is related to this `AnalysisTableRow`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our create `AnalysisTableRow` mutation. */
export type CreateAnalysisTableRowPayloadAnalysisTableRowEdgeArgs = {
  orderBy?: InputMaybe<Array<AnalysisTableRowsOrderBy>>;
};

/** All input for the create `ApplicationUserGroup` mutation. */
export type CreateApplicationUserGroupInput = {
  /** The `ApplicationUserGroup` to be created by this mutation. */
  applicationUserGroup: ApplicationUserGroupInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `ApplicationUserGroup` mutation. */
export type CreateApplicationUserGroupPayload = {
  __typename: "CreateApplicationUserGroupPayload";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserGroup`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** The `ApplicationUserGroup` that was created by this mutation. */
  applicationUserGroup: Maybe<ApplicationUserGroup>;
  /** An edge for our `ApplicationUserGroup`. May be used by Relay 1. */
  applicationUserGroupEdge: Maybe<ApplicationUserGroupsEdge>;
  /** Reads a single `AuthGroup` that is related to this `ApplicationUserGroup`. */
  authGroupByGroupId: Maybe<AuthGroup>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `ApplicationUserGroup` mutation. */
export type CreateApplicationUserGroupPayloadApplicationUserGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<ApplicationUserGroupsOrderBy>>;
};

/** All input for the create `ApplicationUser` mutation. */
export type CreateApplicationUserInput = {
  /** The `ApplicationUser` to be created by this mutation. */
  applicationUser: ApplicationUserInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** All input for the create `ApplicationUserMembership` mutation. */
export type CreateApplicationUserMembershipInput = {
  /** The `ApplicationUserMembership` to be created by this mutation. */
  applicationUserMembership: ApplicationUserMembershipInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `ApplicationUserMembership` mutation. */
export type CreateApplicationUserMembershipPayload = {
  __typename: "CreateApplicationUserMembershipPayload";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserMembership`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** The `ApplicationUserMembership` that was created by this mutation. */
  applicationUserMembership: Maybe<ApplicationUserMembership>;
  /** An edge for our `ApplicationUserMembership`. May be used by Relay 1. */
  applicationUserMembershipEdge: Maybe<ApplicationUserMembershipsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `ApplicationUserMembership`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `ApplicationUserMembership` mutation. */
export type CreateApplicationUserMembershipPayloadApplicationUserMembershipEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ApplicationUserMembershipsOrderBy>>;
  };

/** All input for the create `ApplicationUserMetadatum` mutation. */
export type CreateApplicationUserMetadatumInput = {
  /** The `ApplicationUserMetadatum` to be created by this mutation. */
  applicationUserMetadatum: ApplicationUserMetadatumInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `ApplicationUserMetadatum` mutation. */
export type CreateApplicationUserMetadatumPayload = {
  __typename: "CreateApplicationUserMetadatumPayload";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserMetadatum`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** The `ApplicationUserMetadatum` that was created by this mutation. */
  applicationUserMetadatum: Maybe<ApplicationUserMetadatum>;
  /** An edge for our `ApplicationUserMetadatum`. May be used by Relay 1. */
  applicationUserMetadatumEdge: Maybe<ApplicationUserMetadataEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `ApplicationUserMetadatum` mutation. */
export type CreateApplicationUserMetadatumPayloadApplicationUserMetadatumEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ApplicationUserMetadataOrderBy>>;
  };

/** The output of our create `ApplicationUser` mutation. */
export type CreateApplicationUserPayload = {
  __typename: "CreateApplicationUserPayload";
  /** The `ApplicationUser` that was created by this mutation. */
  applicationUser: Maybe<ApplicationUser>;
  /** An edge for our `ApplicationUser`. May be used by Relay 1. */
  applicationUserEdge: Maybe<ApplicationUsersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `ApplicationUser` mutation. */
export type CreateApplicationUserPayloadApplicationUserEdgeArgs = {
  orderBy?: InputMaybe<Array<ApplicationUsersOrderBy>>;
};

/** All input for the create `ApplicationUserSession` mutation. */
export type CreateApplicationUserSessionInput = {
  /** The `ApplicationUserSession` to be created by this mutation. */
  applicationUserSession: ApplicationUserSessionInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `ApplicationUserSession` mutation. */
export type CreateApplicationUserSessionPayload = {
  __typename: "CreateApplicationUserSessionPayload";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserSession`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** The `ApplicationUserSession` that was created by this mutation. */
  applicationUserSession: Maybe<ApplicationUserSession>;
  /** An edge for our `ApplicationUserSession`. May be used by Relay 1. */
  applicationUserSessionEdge: Maybe<ApplicationUserSessionsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `ApplicationUserSession`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `ApplicationUserSession` mutation. */
export type CreateApplicationUserSessionPayloadApplicationUserSessionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ApplicationUserSessionsOrderBy>>;
  };

/** All input for the create `ApplicationUserUserPermission` mutation. */
export type CreateApplicationUserUserPermissionInput = {
  /** The `ApplicationUserUserPermission` to be created by this mutation. */
  applicationUserUserPermission: ApplicationUserUserPermissionInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `ApplicationUserUserPermission` mutation. */
export type CreateApplicationUserUserPermissionPayload = {
  __typename: "CreateApplicationUserUserPermissionPayload";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserUserPermission`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** The `ApplicationUserUserPermission` that was created by this mutation. */
  applicationUserUserPermission: Maybe<ApplicationUserUserPermission>;
  /** An edge for our `ApplicationUserUserPermission`. May be used by Relay 1. */
  applicationUserUserPermissionEdge: Maybe<ApplicationUserUserPermissionsEdge>;
  /** Reads a single `AuthPermission` that is related to this `ApplicationUserUserPermission`. */
  authPermissionByPermissionId: Maybe<AuthPermission>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `ApplicationUserUserPermission` mutation. */
export type CreateApplicationUserUserPermissionPayloadApplicationUserUserPermissionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ApplicationUserUserPermissionsOrderBy>>;
  };

/** All input for the create `AuthGroup` mutation. */
export type CreateAuthGroupInput = {
  /** The `AuthGroup` to be created by this mutation. */
  authGroup: AuthGroupInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AuthGroup` mutation. */
export type CreateAuthGroupPayload = {
  __typename: "CreateAuthGroupPayload";
  /** The `AuthGroup` that was created by this mutation. */
  authGroup: Maybe<AuthGroup>;
  /** An edge for our `AuthGroup`. May be used by Relay 1. */
  authGroupEdge: Maybe<AuthGroupsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `AuthGroup` mutation. */
export type CreateAuthGroupPayloadAuthGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<AuthGroupsOrderBy>>;
};

/** All input for the create `AuthGroupPermission` mutation. */
export type CreateAuthGroupPermissionInput = {
  /** The `AuthGroupPermission` to be created by this mutation. */
  authGroupPermission: AuthGroupPermissionInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AuthGroupPermission` mutation. */
export type CreateAuthGroupPermissionPayload = {
  __typename: "CreateAuthGroupPermissionPayload";
  /** Reads a single `AuthGroup` that is related to this `AuthGroupPermission`. */
  authGroupByGroupId: Maybe<AuthGroup>;
  /** The `AuthGroupPermission` that was created by this mutation. */
  authGroupPermission: Maybe<AuthGroupPermission>;
  /** An edge for our `AuthGroupPermission`. May be used by Relay 1. */
  authGroupPermissionEdge: Maybe<AuthGroupPermissionsEdge>;
  /** Reads a single `AuthPermission` that is related to this `AuthGroupPermission`. */
  authPermissionByPermissionId: Maybe<AuthPermission>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `AuthGroupPermission` mutation. */
export type CreateAuthGroupPermissionPayloadAuthGroupPermissionEdgeArgs = {
  orderBy?: InputMaybe<Array<AuthGroupPermissionsOrderBy>>;
};

/** All input for the create `AuthPermission` mutation. */
export type CreateAuthPermissionInput = {
  /** The `AuthPermission` to be created by this mutation. */
  authPermission: AuthPermissionInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AuthPermission` mutation. */
export type CreateAuthPermissionPayload = {
  __typename: "CreateAuthPermissionPayload";
  /** The `AuthPermission` that was created by this mutation. */
  authPermission: Maybe<AuthPermission>;
  /** An edge for our `AuthPermission`. May be used by Relay 1. */
  authPermissionEdge: Maybe<AuthPermissionsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DjangoContentType` that is related to this `AuthPermission`. */
  djangoContentTypeByContentTypeId: Maybe<DjangoContentType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `AuthPermission` mutation. */
export type CreateAuthPermissionPayloadAuthPermissionEdgeArgs = {
  orderBy?: InputMaybe<Array<AuthPermissionsOrderBy>>;
};

/** All input for the create `AvailableNonRestrictedProject` mutation. */
export type CreateAvailableNonRestrictedProjectInput = {
  /** The `AvailableNonRestrictedProject` to be created by this mutation. */
  availableNonRestrictedProject: AvailableNonRestrictedProjectInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AvailableNonRestrictedProject` mutation. */
export type CreateAvailableNonRestrictedProjectPayload = {
  __typename: "CreateAvailableNonRestrictedProjectPayload";
  /** The `AvailableNonRestrictedProject` that was created by this mutation. */
  availableNonRestrictedProject: Maybe<AvailableNonRestrictedProject>;
  /** An edge for our `AvailableNonRestrictedProject`. May be used by Relay 1. */
  availableNonRestrictedProjectEdge: Maybe<AvailableNonRestrictedProjectsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `AvailableNonRestrictedProject` mutation. */
export type CreateAvailableNonRestrictedProjectPayloadAvailableNonRestrictedProjectEdgeArgs =
  {
    orderBy?: InputMaybe<Array<AvailableNonRestrictedProjectsOrderBy>>;
  };

/** All input for the create `Avatar` mutation. */
export type CreateAvatarInput = {
  /** The `Avatar` to be created by this mutation. */
  avatar: AvatarInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `Avatar` mutation. */
export type CreateAvatarPayload = {
  __typename: "CreateAvatarPayload";
  /** Reads a single `ApplicationUser` that is related to this `Avatar`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** The `Avatar` that was created by this mutation. */
  avatar: Maybe<Avatar>;
  /** An edge for our `Avatar`. May be used by Relay 1. */
  avatarEdge: Maybe<AvatarsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `Avatar` mutation. */
export type CreateAvatarPayloadAvatarEdgeArgs = {
  orderBy?: InputMaybe<Array<AvatarsOrderBy>>;
};

/** All input for the create `AwsBatchTask` mutation. */
export type CreateAwsBatchTaskInput = {
  /** The `AwsBatchTask` to be created by this mutation. */
  awsBatchTask: AwsBatchTaskInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AwsBatchTask` mutation. */
export type CreateAwsBatchTaskPayload = {
  __typename: "CreateAwsBatchTaskPayload";
  /** The `AwsBatchTask` that was created by this mutation. */
  awsBatchTask: Maybe<AwsBatchTask>;
  /** An edge for our `AwsBatchTask`. May be used by Relay 1. */
  awsBatchTaskEdge: Maybe<AwsBatchTasksEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `TesTaskexecutor` that is related to this `AwsBatchTask`. */
  tesTaskexecutorByTaskexecutorPtrId: Maybe<TesTaskexecutor>;
};

/** The output of our create `AwsBatchTask` mutation. */
export type CreateAwsBatchTaskPayloadAwsBatchTaskEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsBatchTasksOrderBy>>;
};

/** All input for the create `AwsCognitoUser` mutation. */
export type CreateAwsCognitoUserInput = {
  /** The `AwsCognitoUser` to be created by this mutation. */
  awsCognitoUser: AwsCognitoUserInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AwsCognitoUser` mutation. */
export type CreateAwsCognitoUserPayload = {
  __typename: "CreateAwsCognitoUserPayload";
  /** The `AwsCognitoUser` that was created by this mutation. */
  awsCognitoUser: Maybe<AwsCognitoUser>;
  /** An edge for our `AwsCognitoUser`. May be used by Relay 1. */
  awsCognitoUserEdge: Maybe<AwsCognitoUsersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `TenantUserexecutor` that is related to this `AwsCognitoUser`. */
  tenantUserexecutorByUserexecutorPtrId: Maybe<TenantUserexecutor>;
};

/** The output of our create `AwsCognitoUser` mutation. */
export type CreateAwsCognitoUserPayloadAwsCognitoUserEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsCognitoUsersOrderBy>>;
};

/** All input for the create `AwsEcrImage` mutation. */
export type CreateAwsEcrImageInput = {
  /** The `AwsEcrImage` to be created by this mutation. */
  awsEcrImage: AwsEcrImageInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AwsEcrImage` mutation. */
export type CreateAwsEcrImagePayload = {
  __typename: "CreateAwsEcrImagePayload";
  /** The `AwsEcrImage` that was created by this mutation. */
  awsEcrImage: Maybe<AwsEcrImage>;
  /** An edge for our `AwsEcrImage`. May be used by Relay 1. */
  awsEcrImageEdge: Maybe<AwsEcrImagesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `TesContainerimageexecutor` that is related to this `AwsEcrImage`. */
  tesContainerimageexecutorByContainerimageexecutorPtrId: Maybe<TesContainerimageexecutor>;
};

/** The output of our create `AwsEcrImage` mutation. */
export type CreateAwsEcrImagePayloadAwsEcrImageEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsEcrImagesOrderBy>>;
};

/** All input for the create `AwsMockBatchTask` mutation. */
export type CreateAwsMockBatchTaskInput = {
  /** The `AwsMockBatchTask` to be created by this mutation. */
  awsMockBatchTask: AwsMockBatchTaskInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AwsMockBatchTask` mutation. */
export type CreateAwsMockBatchTaskPayload = {
  __typename: "CreateAwsMockBatchTaskPayload";
  /** Reads a single `AwsBatchTask` that is related to this `AwsMockBatchTask`. */
  awsBatchTaskByAwstaskexecutorPtrId: Maybe<AwsBatchTask>;
  /** The `AwsMockBatchTask` that was created by this mutation. */
  awsMockBatchTask: Maybe<AwsMockBatchTask>;
  /** An edge for our `AwsMockBatchTask`. May be used by Relay 1. */
  awsMockBatchTaskEdge: Maybe<AwsMockBatchTasksEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `AwsMockBatchTask` mutation. */
export type CreateAwsMockBatchTaskPayloadAwsMockBatchTaskEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsMockBatchTasksOrderBy>>;
};

/** All input for the create `AwsMockEcrImage` mutation. */
export type CreateAwsMockEcrImageInput = {
  /** The `AwsMockEcrImage` to be created by this mutation. */
  awsMockEcrImage: AwsMockEcrImageInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AwsMockEcrImage` mutation. */
export type CreateAwsMockEcrImagePayload = {
  __typename: "CreateAwsMockEcrImagePayload";
  /** Reads a single `AwsEcrImage` that is related to this `AwsMockEcrImage`. */
  awsEcrImageByAwscontainerimageexecutorPtrId: Maybe<AwsEcrImage>;
  /** The `AwsMockEcrImage` that was created by this mutation. */
  awsMockEcrImage: Maybe<AwsMockEcrImage>;
  /** An edge for our `AwsMockEcrImage`. May be used by Relay 1. */
  awsMockEcrImageEdge: Maybe<AwsMockEcrImagesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `AwsMockEcrImage` mutation. */
export type CreateAwsMockEcrImagePayloadAwsMockEcrImageEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsMockEcrImagesOrderBy>>;
};

/** All input for the create `AwsMockS3File` mutation. */
export type CreateAwsMockS3FileInput = {
  /** The `AwsMockS3File` to be created by this mutation. */
  awsMockS3File: AwsMockS3FileInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AwsMockS3File` mutation. */
export type CreateAwsMockS3FilePayload = {
  __typename: "CreateAwsMockS3FilePayload";
  /** The `AwsMockS3File` that was created by this mutation. */
  awsMockS3File: Maybe<AwsMockS3File>;
  /** An edge for our `AwsMockS3File`. May be used by Relay 1. */
  awsMockS3FileEdge: Maybe<AwsMockS3FilesEdge>;
  /** Reads a single `AwsS3File` that is related to this `AwsMockS3File`. */
  awsS3FileByAwsfileexecutorPtrId: Maybe<AwsS3File>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `AwsMockS3File` mutation. */
export type CreateAwsMockS3FilePayloadAwsMockS3FileEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsMockS3FilesOrderBy>>;
};

/** All input for the create `AwsS3File` mutation. */
export type CreateAwsS3FileInput = {
  /** The `AwsS3File` to be created by this mutation. */
  awsS3File: AwsS3FileInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AwsS3File` mutation. */
export type CreateAwsS3FilePayload = {
  __typename: "CreateAwsS3FilePayload";
  /** The `AwsS3File` that was created by this mutation. */
  awsS3File: Maybe<AwsS3File>;
  /** An edge for our `AwsS3File`. May be used by Relay 1. */
  awsS3FileEdge: Maybe<AwsS3FilesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DrsFileexecutor` that is related to this `AwsS3File`. */
  drsFileexecutorByFileexecutorPtrId: Maybe<DrsFileexecutor>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `AwsS3File` mutation. */
export type CreateAwsS3FilePayloadAwsS3FileEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsS3FilesOrderBy>>;
};

/** All input for the create `ContainerImage` mutation. */
export type CreateContainerImageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ContainerImage` to be created by this mutation. */
  containerImage: ContainerImageInput;
};

/** The output of our create `ContainerImage` mutation. */
export type CreateContainerImagePayload = {
  __typename: "CreateContainerImagePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `ContainerImage` that was created by this mutation. */
  containerImage: Maybe<ContainerImage>;
  /** An edge for our `ContainerImage`. May be used by Relay 1. */
  containerImageEdge: Maybe<ContainerImagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `TesContainerimageexecutor` that is related to this `ContainerImage`. */
  tesContainerimageexecutorByExecutorId: Maybe<TesContainerimageexecutor>;
  /** Reads a single `ToolboxVersion` that is related to this `ContainerImage`. */
  toolboxVersionByToolboxVersionId: Maybe<ToolboxVersion>;
};

/** The output of our create `ContainerImage` mutation. */
export type CreateContainerImagePayloadContainerImageEdgeArgs = {
  orderBy?: InputMaybe<Array<ContainerImagesOrderBy>>;
};

/** All input for the create `Cost` mutation. */
export type CreateCostInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `Cost` to be created by this mutation. */
  cost: CostInput;
};

/** The output of our create `Cost` mutation. */
export type CreateCostPayload = {
  __typename: "CreateCostPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Cost` that was created by this mutation. */
  cost: Maybe<Cost>;
  /** An edge for our `Cost`. May be used by Relay 1. */
  costEdge: Maybe<CostsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Cost`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `Cost` mutation. */
export type CreateCostPayloadCostEdgeArgs = {
  orderBy?: InputMaybe<Array<CostsOrderBy>>;
};

/** All input for the create `CurrentApplicationUser` mutation. */
export type CreateCurrentApplicationUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `CurrentApplicationUser` to be created by this mutation. */
  currentApplicationUser: CurrentApplicationUserInput;
};

/** The output of our create `CurrentApplicationUser` mutation. */
export type CreateCurrentApplicationUserPayload = {
  __typename: "CreateCurrentApplicationUserPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `CurrentApplicationUser` that was created by this mutation. */
  currentApplicationUser: Maybe<CurrentApplicationUser>;
  /** An edge for our `CurrentApplicationUser`. May be used by Relay 1. */
  currentApplicationUserEdge: Maybe<CurrentApplicationUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `CurrentApplicationUser` mutation. */
export type CreateCurrentApplicationUserPayloadCurrentApplicationUserEdgeArgs =
  {
    orderBy?: InputMaybe<Array<CurrentApplicationUsersOrderBy>>;
  };

/** All input for the create `CurrentUserMembership` mutation. */
export type CreateCurrentUserMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `CurrentUserMembership` to be created by this mutation. */
  currentUserMembership: CurrentUserMembershipInput;
};

/** The output of our create `CurrentUserMembership` mutation. */
export type CreateCurrentUserMembershipPayload = {
  __typename: "CreateCurrentUserMembershipPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `CurrentUserMembership` that was created by this mutation. */
  currentUserMembership: Maybe<CurrentUserMembership>;
  /** An edge for our `CurrentUserMembership`. May be used by Relay 1. */
  currentUserMembershipEdge: Maybe<CurrentUserMembershipsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `CurrentUserMembership` mutation. */
export type CreateCurrentUserMembershipPayloadCurrentUserMembershipEdgeArgs = {
  orderBy?: InputMaybe<Array<CurrentUserMembershipsOrderBy>>;
};

/** All input for the create `Dataset` mutation. */
export type CreateDatasetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `Dataset` to be created by this mutation. */
  dataset: DatasetInput;
};

/** All input for the create `DatasetMetadatum` mutation. */
export type CreateDatasetMetadatumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `DatasetMetadatum` to be created by this mutation. */
  datasetMetadatum: DatasetMetadatumInput;
};

/** The output of our create `DatasetMetadatum` mutation. */
export type CreateDatasetMetadatumPayload = {
  __typename: "CreateDatasetMetadatumPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Dataset` that is related to this `DatasetMetadatum`. */
  datasetByDatasetId: Maybe<Dataset>;
  /** The `DatasetMetadatum` that was created by this mutation. */
  datasetMetadatum: Maybe<DatasetMetadatum>;
  /** An edge for our `DatasetMetadatum`. May be used by Relay 1. */
  datasetMetadatumEdge: Maybe<DatasetMetadataEdge>;
  /** Reads a single `Metadatum` that is related to this `DatasetMetadatum`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `DatasetMetadatum`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `DatasetMetadatum`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `DatasetMetadatum` mutation. */
export type CreateDatasetMetadatumPayloadDatasetMetadatumEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetMetadataOrderBy>>;
};

/** The output of our create `Dataset` mutation. */
export type CreateDatasetPayload = {
  __typename: "CreateDatasetPayload";
  /** Reads a single `ApplicationUser` that is related to this `Dataset`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Dataset` that was created by this mutation. */
  dataset: Maybe<Dataset>;
  /** An edge for our `Dataset`. May be used by Relay 1. */
  datasetEdge: Maybe<DatasetsEdge>;
  /** Reads a single `DatasetRecordingsTable` that is related to this `Dataset`. */
  datasetRecordingsTableByRecordingsTableId: Maybe<DatasetRecordingsTable>;
  /** Reads a single `Metadatum` that is related to this `Dataset`. */
  metadatumByDescriptionId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `Dataset`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Dataset`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `Dataset` mutation. */
export type CreateDatasetPayloadDatasetEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetsOrderBy>>;
};

/** All input for the create `DatasetRecordingsTableColumn` mutation. */
export type CreateDatasetRecordingsTableColumnInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `DatasetRecordingsTableColumn` to be created by this mutation. */
  datasetRecordingsTableColumn: DatasetRecordingsTableColumnInput;
};

/** All input for the create `DatasetRecordingsTableColumnOrder` mutation. */
export type CreateDatasetRecordingsTableColumnOrderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `DatasetRecordingsTableColumnOrder` to be created by this mutation. */
  datasetRecordingsTableColumnOrder: DatasetRecordingsTableColumnOrderInput;
};

/** The output of our create `DatasetRecordingsTableColumnOrder` mutation. */
export type CreateDatasetRecordingsTableColumnOrderPayload = {
  __typename: "CreateDatasetRecordingsTableColumnOrderPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DatasetRecordingsTableColumn` that is related to this `DatasetRecordingsTableColumnOrder`. */
  datasetRecordingsTableColumnByDatasetRecordingsTableColumnId: Maybe<DatasetRecordingsTableColumn>;
  /** The `DatasetRecordingsTableColumnOrder` that was created by this mutation. */
  datasetRecordingsTableColumnOrder: Maybe<DatasetRecordingsTableColumnOrder>;
  /** An edge for our `DatasetRecordingsTableColumnOrder`. May be used by Relay 1. */
  datasetRecordingsTableColumnOrderEdge: Maybe<DatasetRecordingsTableColumnOrdersEdge>;
  /** Reads a single `Project` that is related to this `DatasetRecordingsTableColumnOrder`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `DatasetRecordingsTableColumnOrder`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `DatasetRecordingsTableColumnOrder` mutation. */
export type CreateDatasetRecordingsTableColumnOrderPayloadDatasetRecordingsTableColumnOrderEdgeArgs =
  {
    orderBy?: InputMaybe<Array<DatasetRecordingsTableColumnOrdersOrderBy>>;
  };

/** The output of our create `DatasetRecordingsTableColumn` mutation. */
export type CreateDatasetRecordingsTableColumnPayload = {
  __typename: "CreateDatasetRecordingsTableColumnPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DatasetRecordingsTable` that is related to this `DatasetRecordingsTableColumn`. */
  datasetRecordingsTableByDatasetRecordingsTableId: Maybe<DatasetRecordingsTable>;
  /** The `DatasetRecordingsTableColumn` that was created by this mutation. */
  datasetRecordingsTableColumn: Maybe<DatasetRecordingsTableColumn>;
  /** An edge for our `DatasetRecordingsTableColumn`. May be used by Relay 1. */
  datasetRecordingsTableColumnEdge: Maybe<DatasetRecordingsTableColumnsEdge>;
  /** Reads a single `Metadatum` that is related to this `DatasetRecordingsTableColumn`. */
  metadatumByColDefId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `DatasetRecordingsTableColumn`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `DatasetRecordingsTableColumn`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `DatasetRecordingsTableColumn` mutation. */
export type CreateDatasetRecordingsTableColumnPayloadDatasetRecordingsTableColumnEdgeArgs =
  {
    orderBy?: InputMaybe<Array<DatasetRecordingsTableColumnsOrderBy>>;
  };

/** All input for the create `DatasetRecordingsTable` mutation. */
export type CreateDatasetRecordingsTableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `DatasetRecordingsTable` to be created by this mutation. */
  datasetRecordingsTable: DatasetRecordingsTableInput;
};

/** The output of our create `DatasetRecordingsTable` mutation. */
export type CreateDatasetRecordingsTablePayload = {
  __typename: "CreateDatasetRecordingsTablePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `DatasetRecordingsTable` that was created by this mutation. */
  datasetRecordingsTable: Maybe<DatasetRecordingsTable>;
  /** An edge for our `DatasetRecordingsTable`. May be used by Relay 1. */
  datasetRecordingsTableEdge: Maybe<DatasetRecordingsTablesEdge>;
  /** Reads a single `Project` that is related to this `DatasetRecordingsTable`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `DatasetRecordingsTable`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `DatasetRecordingsTable` mutation. */
export type CreateDatasetRecordingsTablePayloadDatasetRecordingsTableEdgeArgs =
  {
    orderBy?: InputMaybe<Array<DatasetRecordingsTablesOrderBy>>;
  };

/** All input for the create `DatasetVersion` mutation. */
export type CreateDatasetVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `DatasetVersion` to be created by this mutation. */
  datasetVersion: DatasetVersionInput;
};

/** The output of our create `DatasetVersion` mutation. */
export type CreateDatasetVersionPayload = {
  __typename: "CreateDatasetVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Dataset` that is related to this `DatasetVersion`. */
  datasetByDatasetId: Maybe<Dataset>;
  /** The `DatasetVersion` that was created by this mutation. */
  datasetVersion: Maybe<DatasetVersion>;
  /** An edge for our `DatasetVersion`. May be used by Relay 1. */
  datasetVersionEdge: Maybe<DatasetVersionsEdge>;
  /** Reads a single `Project` that is related to this `DatasetVersion`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `DatasetVersion`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `DatasetVersion` mutation. */
export type CreateDatasetVersionPayloadDatasetVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetVersionsOrderBy>>;
};

/** All input for the create `DjangoAdminLog` mutation. */
export type CreateDjangoAdminLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `DjangoAdminLog` to be created by this mutation. */
  djangoAdminLog: DjangoAdminLogInput;
};

/** The output of our create `DjangoAdminLog` mutation. */
export type CreateDjangoAdminLogPayload = {
  __typename: "CreateDjangoAdminLogPayload";
  /** Reads a single `ApplicationUser` that is related to this `DjangoAdminLog`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `DjangoAdminLog` that was created by this mutation. */
  djangoAdminLog: Maybe<DjangoAdminLog>;
  /** An edge for our `DjangoAdminLog`. May be used by Relay 1. */
  djangoAdminLogEdge: Maybe<DjangoAdminLogsEdge>;
  /** Reads a single `DjangoContentType` that is related to this `DjangoAdminLog`. */
  djangoContentTypeByContentTypeId: Maybe<DjangoContentType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `DjangoAdminLog` mutation. */
export type CreateDjangoAdminLogPayloadDjangoAdminLogEdgeArgs = {
  orderBy?: InputMaybe<Array<DjangoAdminLogsOrderBy>>;
};

/** All input for the create `DjangoContentType` mutation. */
export type CreateDjangoContentTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `DjangoContentType` to be created by this mutation. */
  djangoContentType: DjangoContentTypeInput;
};

/** The output of our create `DjangoContentType` mutation. */
export type CreateDjangoContentTypePayload = {
  __typename: "CreateDjangoContentTypePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `DjangoContentType` that was created by this mutation. */
  djangoContentType: Maybe<DjangoContentType>;
  /** An edge for our `DjangoContentType`. May be used by Relay 1. */
  djangoContentTypeEdge: Maybe<DjangoContentTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `DjangoContentType` mutation. */
export type CreateDjangoContentTypePayloadDjangoContentTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<DjangoContentTypesOrderBy>>;
};

/** All input for the create `DjangoMigration` mutation. */
export type CreateDjangoMigrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `DjangoMigration` to be created by this mutation. */
  djangoMigration: DjangoMigrationInput;
};

/** The output of our create `DjangoMigration` mutation. */
export type CreateDjangoMigrationPayload = {
  __typename: "CreateDjangoMigrationPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `DjangoMigration` that was created by this mutation. */
  djangoMigration: Maybe<DjangoMigration>;
  /** An edge for our `DjangoMigration`. May be used by Relay 1. */
  djangoMigrationEdge: Maybe<DjangoMigrationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `DjangoMigration` mutation. */
export type CreateDjangoMigrationPayloadDjangoMigrationEdgeArgs = {
  orderBy?: InputMaybe<Array<DjangoMigrationsOrderBy>>;
};

/** All input for the create `DjangoSesSesstat` mutation. */
export type CreateDjangoSesSesstatInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `DjangoSesSesstat` to be created by this mutation. */
  djangoSesSesstat: DjangoSesSesstatInput;
};

/** The output of our create `DjangoSesSesstat` mutation. */
export type CreateDjangoSesSesstatPayload = {
  __typename: "CreateDjangoSesSesstatPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `DjangoSesSesstat` that was created by this mutation. */
  djangoSesSesstat: Maybe<DjangoSesSesstat>;
  /** An edge for our `DjangoSesSesstat`. May be used by Relay 1. */
  djangoSesSesstatEdge: Maybe<DjangoSesSesstatsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `DjangoSesSesstat` mutation. */
export type CreateDjangoSesSesstatPayloadDjangoSesSesstatEdgeArgs = {
  orderBy?: InputMaybe<Array<DjangoSesSesstatsOrderBy>>;
};

/** All input for the create `DjangoSession` mutation. */
export type CreateDjangoSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `DjangoSession` to be created by this mutation. */
  djangoSession: DjangoSessionInput;
};

/** The output of our create `DjangoSession` mutation. */
export type CreateDjangoSessionPayload = {
  __typename: "CreateDjangoSessionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `DjangoSession` that was created by this mutation. */
  djangoSession: Maybe<DjangoSession>;
  /** An edge for our `DjangoSession`. May be used by Relay 1. */
  djangoSessionEdge: Maybe<DjangoSessionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `DjangoSession` mutation. */
export type CreateDjangoSessionPayloadDjangoSessionEdgeArgs = {
  orderBy?: InputMaybe<Array<DjangoSessionsOrderBy>>;
};

/** All input for the create `DrsFileexecutor` mutation. */
export type CreateDrsFileexecutorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `DrsFileexecutor` to be created by this mutation. */
  drsFileexecutor: DrsFileexecutorInput;
};

/** The output of our create `DrsFileexecutor` mutation. */
export type CreateDrsFileexecutorPayload = {
  __typename: "CreateDrsFileexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DjangoContentType` that is related to this `DrsFileexecutor`. */
  djangoContentTypeByPolymorphicCtypeId: Maybe<DjangoContentType>;
  /** The `DrsFileexecutor` that was created by this mutation. */
  drsFileexecutor: Maybe<DrsFileexecutor>;
  /** An edge for our `DrsFileexecutor`. May be used by Relay 1. */
  drsFileexecutorEdge: Maybe<DrsFileexecutorsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `DrsFileexecutor` mutation. */
export type CreateDrsFileexecutorPayloadDrsFileexecutorEdgeArgs = {
  orderBy?: InputMaybe<Array<DrsFileexecutorsOrderBy>>;
};

/** All input for the create `DrsMockfileexecutor` mutation. */
export type CreateDrsMockfileexecutorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `DrsMockfileexecutor` to be created by this mutation. */
  drsMockfileexecutor: DrsMockfileexecutorInput;
};

/** The output of our create `DrsMockfileexecutor` mutation. */
export type CreateDrsMockfileexecutorPayload = {
  __typename: "CreateDrsMockfileexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DrsFileexecutor` that is related to this `DrsMockfileexecutor`. */
  drsFileexecutorByFileexecutorPtrId: Maybe<DrsFileexecutor>;
  /** The `DrsMockfileexecutor` that was created by this mutation. */
  drsMockfileexecutor: Maybe<DrsMockfileexecutor>;
  /** An edge for our `DrsMockfileexecutor`. May be used by Relay 1. */
  drsMockfileexecutorEdge: Maybe<DrsMockfileexecutorsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `DrsMockfileexecutor` mutation. */
export type CreateDrsMockfileexecutorPayloadDrsMockfileexecutorEdgeArgs = {
  orderBy?: InputMaybe<Array<DrsMockfileexecutorsOrderBy>>;
};

/** All input for the create `EulaVersion` mutation. */
export type CreateEulaVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `EulaVersion` to be created by this mutation. */
  eulaVersion: EulaVersionInput;
};

/** The output of our create `EulaVersion` mutation. */
export type CreateEulaVersionPayload = {
  __typename: "CreateEulaVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `EulaVersion` that was created by this mutation. */
  eulaVersion: Maybe<EulaVersion>;
  /** An edge for our `EulaVersion`. May be used by Relay 1. */
  eulaVersionEdge: Maybe<EulaVersionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `EulaVersion` mutation. */
export type CreateEulaVersionPayloadEulaVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<EulaVersionsOrderBy>>;
};

/** All input for the create `EulaVersionUserAcceptance` mutation. */
export type CreateEulaVersionUserAcceptanceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `EulaVersionUserAcceptance` to be created by this mutation. */
  eulaVersionUserAcceptance: EulaVersionUserAcceptanceInput;
};

/** The output of our create `EulaVersionUserAcceptance` mutation. */
export type CreateEulaVersionUserAcceptancePayload = {
  __typename: "CreateEulaVersionUserAcceptancePayload";
  /** Reads a single `ApplicationUser` that is related to this `EulaVersionUserAcceptance`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `EulaVersion` that is related to this `EulaVersionUserAcceptance`. */
  eulaVersionByEulaVersionId: Maybe<EulaVersion>;
  /** The `EulaVersionUserAcceptance` that was created by this mutation. */
  eulaVersionUserAcceptance: Maybe<EulaVersionUserAcceptance>;
  /** An edge for our `EulaVersionUserAcceptance`. May be used by Relay 1. */
  eulaVersionUserAcceptanceEdge: Maybe<EulaVersionUserAcceptancesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `EulaVersionUserAcceptance` mutation. */
export type CreateEulaVersionUserAcceptancePayloadEulaVersionUserAcceptanceEdgeArgs =
  {
    orderBy?: InputMaybe<Array<EulaVersionUserAcceptancesOrderBy>>;
  };

/** All input for the create `ExplorerQuery` mutation. */
export type CreateExplorerQueryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ExplorerQuery` to be created by this mutation. */
  explorerQuery: ExplorerQueryInput;
};

/** The output of our create `ExplorerQuery` mutation. */
export type CreateExplorerQueryPayload = {
  __typename: "CreateExplorerQueryPayload";
  /** Reads a single `ApplicationUser` that is related to this `ExplorerQuery`. */
  applicationUserByCreatedByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `ExplorerQuery` that was created by this mutation. */
  explorerQuery: Maybe<ExplorerQuery>;
  /** An edge for our `ExplorerQuery`. May be used by Relay 1. */
  explorerQueryEdge: Maybe<ExplorerQueriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `ExplorerQuery` mutation. */
export type CreateExplorerQueryPayloadExplorerQueryEdgeArgs = {
  orderBy?: InputMaybe<Array<ExplorerQueriesOrderBy>>;
};

/** All input for the create `ExplorerQueryfavorite` mutation. */
export type CreateExplorerQueryfavoriteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ExplorerQueryfavorite` to be created by this mutation. */
  explorerQueryfavorite: ExplorerQueryfavoriteInput;
};

/** The output of our create `ExplorerQueryfavorite` mutation. */
export type CreateExplorerQueryfavoritePayload = {
  __typename: "CreateExplorerQueryfavoritePayload";
  /** Reads a single `ApplicationUser` that is related to this `ExplorerQueryfavorite`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `ExplorerQuery` that is related to this `ExplorerQueryfavorite`. */
  explorerQueryByQueryId: Maybe<ExplorerQuery>;
  /** The `ExplorerQueryfavorite` that was created by this mutation. */
  explorerQueryfavorite: Maybe<ExplorerQueryfavorite>;
  /** An edge for our `ExplorerQueryfavorite`. May be used by Relay 1. */
  explorerQueryfavoriteEdge: Maybe<ExplorerQueryfavoritesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `ExplorerQueryfavorite` mutation. */
export type CreateExplorerQueryfavoritePayloadExplorerQueryfavoriteEdgeArgs = {
  orderBy?: InputMaybe<Array<ExplorerQueryfavoritesOrderBy>>;
};

/** All input for the create `ExplorerQuerylog` mutation. */
export type CreateExplorerQuerylogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ExplorerQuerylog` to be created by this mutation. */
  explorerQuerylog: ExplorerQuerylogInput;
};

/** The output of our create `ExplorerQuerylog` mutation. */
export type CreateExplorerQuerylogPayload = {
  __typename: "CreateExplorerQuerylogPayload";
  /** Reads a single `ApplicationUser` that is related to this `ExplorerQuerylog`. */
  applicationUserByRunByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `ExplorerQuery` that is related to this `ExplorerQuerylog`. */
  explorerQueryByQueryId: Maybe<ExplorerQuery>;
  /** The `ExplorerQuerylog` that was created by this mutation. */
  explorerQuerylog: Maybe<ExplorerQuerylog>;
  /** An edge for our `ExplorerQuerylog`. May be used by Relay 1. */
  explorerQuerylogEdge: Maybe<ExplorerQuerylogsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `ExplorerQuerylog` mutation. */
export type CreateExplorerQuerylogPayloadExplorerQuerylogEdgeArgs = {
  orderBy?: InputMaybe<Array<ExplorerQuerylogsOrderBy>>;
};

/** All input for the create `FileActivity` mutation. */
export type CreateFileActivityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `FileActivity` to be created by this mutation. */
  fileActivity: FileActivityInput;
};

/** The output of our create `FileActivity` mutation. */
export type CreateFileActivityPayload = {
  __typename: "CreateFileActivityPayload";
  /** Reads a single `ApplicationUser` that is related to this `FileActivity`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Cost` that is related to this `FileActivity`. */
  costByCostId: Maybe<Cost>;
  /** The `FileActivity` that was created by this mutation. */
  fileActivity: Maybe<FileActivity>;
  /** An edge for our `FileActivity`. May be used by Relay 1. */
  fileActivityEdge: Maybe<FileActivitiesEdge>;
  /** Reads a single `File` that is related to this `FileActivity`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `Project` that is related to this `FileActivity`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `FileActivity`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `FileActivity` mutation. */
export type CreateFileActivityPayloadFileActivityEdgeArgs = {
  orderBy?: InputMaybe<Array<FileActivitiesOrderBy>>;
};

/** All input for the create `File` mutation. */
export type CreateFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `File` to be created by this mutation. */
  file: FileInput;
};

/** All input for the create `FileMetadatum` mutation. */
export type CreateFileMetadatumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `FileMetadatum` to be created by this mutation. */
  fileMetadatum: FileMetadatumInput;
};

/** The output of our create `FileMetadatum` mutation. */
export type CreateFileMetadatumPayload = {
  __typename: "CreateFileMetadatumPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `FileMetadatum`. */
  fileByFileId: Maybe<File>;
  /** The `FileMetadatum` that was created by this mutation. */
  fileMetadatum: Maybe<FileMetadatum>;
  /** An edge for our `FileMetadatum`. May be used by Relay 1. */
  fileMetadatumEdge: Maybe<FileMetadataEdge>;
  /** Reads a single `Metadatum` that is related to this `FileMetadatum`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `FileMetadatum`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `FileMetadatum`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `FileMetadatum` mutation. */
export type CreateFileMetadatumPayloadFileMetadatumEdgeArgs = {
  orderBy?: InputMaybe<Array<FileMetadataOrderBy>>;
};

/** All input for the create `FileOutputGroupMetadatum` mutation. */
export type CreateFileOutputGroupMetadatumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `FileOutputGroupMetadatum` to be created by this mutation. */
  fileOutputGroupMetadatum: FileOutputGroupMetadatumInput;
};

/** The output of our create `FileOutputGroupMetadatum` mutation. */
export type CreateFileOutputGroupMetadatumPayload = {
  __typename: "CreateFileOutputGroupMetadatumPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `FileOutputGroup` that is related to this `FileOutputGroupMetadatum`. */
  fileOutputGroupByFileOutputGroupId: Maybe<FileOutputGroup>;
  /** The `FileOutputGroupMetadatum` that was created by this mutation. */
  fileOutputGroupMetadatum: Maybe<FileOutputGroupMetadatum>;
  /** An edge for our `FileOutputGroupMetadatum`. May be used by Relay 1. */
  fileOutputGroupMetadatumEdge: Maybe<FileOutputGroupMetadataEdge>;
  /** Reads a single `Metadatum` that is related to this `FileOutputGroupMetadatum`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `FileOutputGroupMetadatum`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `FileOutputGroupMetadatum`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `FileOutputGroupMetadatum` mutation. */
export type CreateFileOutputGroupMetadatumPayloadFileOutputGroupMetadatumEdgeArgs =
  {
    orderBy?: InputMaybe<Array<FileOutputGroupMetadataOrderBy>>;
  };

/** The output of our create `File` mutation. */
export type CreateFilePayload = {
  __typename: "CreateFilePayload";
  /** Reads a single `ApplicationUser` that is related to this `File`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Dataset` that is related to this `File`. */
  datasetByDatasetId: Maybe<Dataset>;
  /** The `File` that was created by this mutation. */
  file: Maybe<File>;
  /** Reads a single `File` that is related to this `File`. */
  fileBySeriesParentId: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge: Maybe<FilesEdge>;
  /** Reads a single `Project` that is related to this `File`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `File`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `File` mutation. */
export type CreateFilePayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the create `FileRecordingGroup` mutation. */
export type CreateFileRecordingGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `FileRecordingGroup` to be created by this mutation. */
  fileRecordingGroup: FileRecordingGroupInput;
};

/** All input for the create `FileRecordingGroupMetadatum` mutation. */
export type CreateFileRecordingGroupMetadatumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `FileRecordingGroupMetadatum` to be created by this mutation. */
  fileRecordingGroupMetadatum: FileRecordingGroupMetadatumInput;
};

/** The output of our create `FileRecordingGroupMetadatum` mutation. */
export type CreateFileRecordingGroupMetadatumPayload = {
  __typename: "CreateFileRecordingGroupMetadatumPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `FileRecordingGroup` that is related to this `FileRecordingGroupMetadatum`. */
  fileRecordingGroupByFileRecordingGroupId: Maybe<FileRecordingGroup>;
  /** The `FileRecordingGroupMetadatum` that was created by this mutation. */
  fileRecordingGroupMetadatum: Maybe<FileRecordingGroupMetadatum>;
  /** An edge for our `FileRecordingGroupMetadatum`. May be used by Relay 1. */
  fileRecordingGroupMetadatumEdge: Maybe<FileRecordingGroupMetadataEdge>;
  /** Reads a single `Metadatum` that is related to this `FileRecordingGroupMetadatum`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `FileRecordingGroupMetadatum`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `FileRecordingGroupMetadatum`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `FileRecordingGroupMetadatum` mutation. */
export type CreateFileRecordingGroupMetadatumPayloadFileRecordingGroupMetadatumEdgeArgs =
  {
    orderBy?: InputMaybe<Array<FileRecordingGroupMetadataOrderBy>>;
  };

/** The output of our create `FileRecordingGroup` mutation. */
export type CreateFileRecordingGroupPayload = {
  __typename: "CreateFileRecordingGroupPayload";
  /** Reads a single `ApplicationUser` that is related to this `FileRecordingGroup`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DatasetRecordingsTable` that is related to this `FileRecordingGroup`. */
  datasetRecordingsTableByDatasetRecordingsTableId: Maybe<DatasetRecordingsTable>;
  /** The `FileRecordingGroup` that was created by this mutation. */
  fileRecordingGroup: Maybe<FileRecordingGroup>;
  /** An edge for our `FileRecordingGroup`. May be used by Relay 1. */
  fileRecordingGroupEdge: Maybe<FileRecordingGroupsEdge>;
  /** Reads a single `Project` that is related to this `FileRecordingGroup`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `FileRecordingGroup`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `FileRecordingGroup` mutation. */
export type CreateFileRecordingGroupPayloadFileRecordingGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<FileRecordingGroupsOrderBy>>;
};

/** All input for the create `MetadataValue` mutation. */
export type CreateMetadataValueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `MetadataValue` to be created by this mutation. */
  metadataValue: MetadataValueInput;
};

/** The output of our create `MetadataValue` mutation. */
export type CreateMetadataValuePayload = {
  __typename: "CreateMetadataValuePayload";
  /** Reads a single `ApplicationUser` that is related to this `MetadataValue`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `MetadataValue` that was created by this mutation. */
  metadataValue: Maybe<MetadataValue>;
  /** An edge for our `MetadataValue`. May be used by Relay 1. */
  metadataValueEdge: Maybe<MetadataValuesEdge>;
  /** Reads a single `Metadatum` that is related to this `MetadataValue`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `MetadataValue`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Task` that is related to this `MetadataValue`. */
  taskByTaskId: Maybe<Task>;
  /** Reads a single `Tenant` that is related to this `MetadataValue`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `MetadataValue` mutation. */
export type CreateMetadataValuePayloadMetadataValueEdgeArgs = {
  orderBy?: InputMaybe<Array<MetadataValuesOrderBy>>;
};

/** All input for the create `Metadatum` mutation. */
export type CreateMetadatumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `Metadatum` to be created by this mutation. */
  metadatum: MetadatumInput;
};

/** The output of our create `Metadatum` mutation. */
export type CreateMetadatumPayload = {
  __typename: "CreateMetadatumPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Metadatum` that was created by this mutation. */
  metadatum: Maybe<Metadatum>;
  /** An edge for our `Metadatum`. May be used by Relay 1. */
  metadatumEdge: Maybe<MetadataEdge>;
  /** Reads a single `Project` that is related to this `Metadatum`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Metadatum`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `Metadatum` mutation. */
export type CreateMetadatumPayloadMetadatumEdgeArgs = {
  orderBy?: InputMaybe<Array<MetadataOrderBy>>;
};

/** All input for the create `OutputGroupFile` mutation. */
export type CreateOutputGroupFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `OutputGroupFile` to be created by this mutation. */
  outputGroupFile: OutputGroupFileInput;
};

/** The output of our create `OutputGroupFile` mutation. */
export type CreateOutputGroupFilePayload = {
  __typename: "CreateOutputGroupFilePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `OutputGroupFile`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `FileOutputGroup` that is related to this `OutputGroupFile`. */
  fileOutputGroupByFileOutputGroupId: Maybe<FileOutputGroup>;
  /** The `OutputGroupFile` that was created by this mutation. */
  outputGroupFile: Maybe<OutputGroupFile>;
  /** An edge for our `OutputGroupFile`. May be used by Relay 1. */
  outputGroupFileEdge: Maybe<OutputGroupFilesEdge>;
  /** Reads a single `Project` that is related to this `OutputGroupFile`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `OutputGroupFile` mutation. */
export type CreateOutputGroupFilePayloadOutputGroupFileEdgeArgs = {
  orderBy?: InputMaybe<Array<OutputGroupFilesOrderBy>>;
};

/** All input for the create `RecordingGroupFile` mutation. */
export type CreateRecordingGroupFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `RecordingGroupFile` to be created by this mutation. */
  recordingGroupFile: RecordingGroupFileInput;
};

/** The output of our create `RecordingGroupFile` mutation. */
export type CreateRecordingGroupFilePayload = {
  __typename: "CreateRecordingGroupFilePayload";
  /** Reads a single `ApplicationUser` that is related to this `RecordingGroupFile`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DatasetRecordingsTableColumn` that is related to this `RecordingGroupFile`. */
  datasetRecordingsTableColumnByDatasetRecordingsTableColumnId: Maybe<DatasetRecordingsTableColumn>;
  /** Reads a single `File` that is related to this `RecordingGroupFile`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `FileRecordingGroup` that is related to this `RecordingGroupFile`. */
  fileRecordingGroupByFileRecordingGroupId: Maybe<FileRecordingGroup>;
  /** Reads a single `Project` that is related to this `RecordingGroupFile`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `RecordingGroupFile` that was created by this mutation. */
  recordingGroupFile: Maybe<RecordingGroupFile>;
  /** An edge for our `RecordingGroupFile`. May be used by Relay 1. */
  recordingGroupFileEdge: Maybe<RecordingGroupFilesEdge>;
  /** Reads a single `Tenant` that is related to this `RecordingGroupFile`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `RecordingGroupFile` mutation. */
export type CreateRecordingGroupFilePayloadRecordingGroupFileEdgeArgs = {
  orderBy?: InputMaybe<Array<RecordingGroupFilesOrderBy>>;
};

/** All input for the `createRole` mutation. */
export type CreateRoleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  inpt?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our `createRole` mutation. */
export type CreateRolePayload = {
  __typename: "CreateRolePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the create `Run` mutation. */
export type CreateRunInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `Run` to be created by this mutation. */
  run: RunInput;
};

/** The output of our create `Run` mutation. */
export type CreateRunPayload = {
  __typename: "CreateRunPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `Run` that was created by this mutation. */
  run: Maybe<Run>;
  /** An edge for our `Run`. May be used by Relay 1. */
  runEdge: Maybe<RunsEdge>;
  /** Reads a single `Tenant` that is related to this `Run`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `Run` mutation. */
export type CreateRunPayloadRunEdgeArgs = {
  orderBy?: InputMaybe<Array<RunsOrderBy>>;
};

/** All input for the `createSession` mutation. */
export type CreateSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  tenant?: InputMaybe<Scalars["Int"]["input"]>;
  userid?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The output of our `createSession` mutation. */
export type CreateSessionPayload = {
  __typename: "CreateSessionPayload";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserSession`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  applicationUserSession: Maybe<ApplicationUserSession>;
  /** An edge for our `ApplicationUserSession`. May be used by Relay 1. */
  applicationUserSessionEdge: Maybe<ApplicationUserSessionsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `ApplicationUserSession`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our `createSession` mutation. */
export type CreateSessionPayloadApplicationUserSessionEdgeArgs = {
  orderBy?: InputMaybe<Array<ApplicationUserSessionsOrderBy>>;
};

/** All input for the create `Tag` mutation. */
export type CreateTagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `Tag` to be created by this mutation. */
  tag: TagInput;
};

/** The output of our create `Tag` mutation. */
export type CreateTagPayload = {
  __typename: "CreateTagPayload";
  /** Reads a single `ApplicationUser` that is related to this `Tag`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `Tag` that was created by this mutation. */
  tag: Maybe<Tag>;
  /** Reads a single `Tag` that is related to this `Tag`. */
  tagByParentId: Maybe<Tag>;
  /** An edge for our `Tag`. May be used by Relay 1. */
  tagEdge: Maybe<TagsEdge>;
  /** Reads a single `Tenant` that is related to this `Tag`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our create `Tag` mutation. */
export type CreateTagPayloadTagEdgeArgs = {
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
};

/** All input for the create `TaskActivity` mutation. */
export type CreateTaskActivityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `TaskActivity` to be created by this mutation. */
  taskActivity: TaskActivityInput;
};

/** The output of our create `TaskActivity` mutation. */
export type CreateTaskActivityPayload = {
  __typename: "CreateTaskActivityPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Cost` that is related to this `TaskActivity`. */
  costByCostId: Maybe<Cost>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TaskActivity` that was created by this mutation. */
  taskActivity: Maybe<TaskActivity>;
  /** An edge for our `TaskActivity`. May be used by Relay 1. */
  taskActivityEdge: Maybe<TaskActivitiesEdge>;
  /** Reads a single `Task` that is related to this `TaskActivity`. */
  taskByTaskId: Maybe<Task>;
};

/** The output of our create `TaskActivity` mutation. */
export type CreateTaskActivityPayloadTaskActivityEdgeArgs = {
  orderBy?: InputMaybe<Array<TaskActivitiesOrderBy>>;
};

/** All input for the create `Task` mutation. */
export type CreateTaskInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `Task` to be created by this mutation. */
  task: TaskInput;
};

/** The output of our create `Task` mutation. */
export type CreateTaskPayload = {
  __typename: "CreateTaskPayload";
  /** Reads a single `ApplicationUser` that is related to this `Task`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `ContainerImage` that is related to this `Task`. */
  containerImageByContainerImageId: Maybe<ContainerImage>;
  /** Reads a single `Dataset` that is related to this `Task`. */
  datasetByDatasetId: Maybe<Dataset>;
  /** Reads a single `Project` that is related to this `Task`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `Task` that was created by this mutation. */
  task: Maybe<Task>;
  /** An edge for our `Task`. May be used by Relay 1. */
  taskEdge: Maybe<TasksEdge>;
  /** Reads a single `Tenant` that is related to this `Task`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `TesTaskexecutor` that is related to this `Task`. */
  tesTaskexecutorByExecutorId: Maybe<TesTaskexecutor>;
  /** Reads a single `ToolVersion` that is related to this `Task`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our create `Task` mutation. */
export type CreateTaskPayloadTaskEdgeArgs = {
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
};

/** All input for the create `TaskResource` mutation. */
export type CreateTaskResourceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `TaskResource` to be created by this mutation. */
  taskResource: TaskResourceInput;
};

/** The output of our create `TaskResource` mutation. */
export type CreateTaskResourcePayload = {
  __typename: "CreateTaskResourcePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Task` that is related to this `TaskResource`. */
  taskByTaskId: Maybe<Task>;
  /** The `TaskResource` that was created by this mutation. */
  taskResource: Maybe<TaskResource>;
  /** An edge for our `TaskResource`. May be used by Relay 1. */
  taskResourceEdge: Maybe<TaskResourcesEdge>;
};

/** The output of our create `TaskResource` mutation. */
export type CreateTaskResourcePayloadTaskResourceEdgeArgs = {
  orderBy?: InputMaybe<Array<TaskResourcesOrderBy>>;
};

/** All input for the create `TaskSource` mutation. */
export type CreateTaskSourceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `TaskSource` to be created by this mutation. */
  taskSource: TaskSourceInput;
};

/** The output of our create `TaskSource` mutation. */
export type CreateTaskSourcePayload = {
  __typename: "CreateTaskSourcePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `TaskSource`. */
  fileByFileId: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Task` that is related to this `TaskSource`. */
  taskByTaskId: Maybe<Task>;
  /** The `TaskSource` that was created by this mutation. */
  taskSource: Maybe<TaskSource>;
  /** An edge for our `TaskSource`. May be used by Relay 1. */
  taskSourceEdge: Maybe<TaskSourcesEdge>;
  /** Reads a single `ToolSource` that is related to this `TaskSource`. */
  toolSourceByToolSourceId: Maybe<ToolSource>;
};

/** The output of our create `TaskSource` mutation. */
export type CreateTaskSourcePayloadTaskSourceEdgeArgs = {
  orderBy?: InputMaybe<Array<TaskSourcesOrderBy>>;
};

/** All input for the create `TaskStatusLog` mutation. */
export type CreateTaskStatusLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `TaskStatusLog` to be created by this mutation. */
  taskStatusLog: TaskStatusLogInput;
};

/** The output of our create `TaskStatusLog` mutation. */
export type CreateTaskStatusLogPayload = {
  __typename: "CreateTaskStatusLogPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Task` that is related to this `TaskStatusLog`. */
  taskByTaskId: Maybe<Task>;
  /** The `TaskStatusLog` that was created by this mutation. */
  taskStatusLog: Maybe<TaskStatusLog>;
  /** An edge for our `TaskStatusLog`. May be used by Relay 1. */
  taskStatusLogEdge: Maybe<TaskStatusLogsEdge>;
};

/** The output of our create `TaskStatusLog` mutation. */
export type CreateTaskStatusLogPayloadTaskStatusLogEdgeArgs = {
  orderBy?: InputMaybe<Array<TaskStatusLogsOrderBy>>;
};

/** All input for the create `TenantContractAllowance` mutation. */
export type CreateTenantContractAllowanceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `TenantContractAllowance` to be created by this mutation. */
  tenantContractAllowance: TenantContractAllowanceInput;
};

/** The output of our create `TenantContractAllowance` mutation. */
export type CreateTenantContractAllowancePayload = {
  __typename: "CreateTenantContractAllowancePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TenantContractAllowance` that was created by this mutation. */
  tenantContractAllowance: Maybe<TenantContractAllowance>;
  /** An edge for our `TenantContractAllowance`. May be used by Relay 1. */
  tenantContractAllowanceEdge: Maybe<TenantContractAllowancesEdge>;
  /** Reads a single `TenantContractTransaction` that is related to this `TenantContractAllowance`. */
  tenantContractTransactionByTenantContractTransactionId: Maybe<TenantContractTransaction>;
};

/** The output of our create `TenantContractAllowance` mutation. */
export type CreateTenantContractAllowancePayloadTenantContractAllowanceEdgeArgs =
  {
    orderBy?: InputMaybe<Array<TenantContractAllowancesOrderBy>>;
  };

/** All input for the create `TenantContract` mutation. */
export type CreateTenantContractInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `TenantContract` to be created by this mutation. */
  tenantContract: TenantContractInput;
};

/** The output of our create `TenantContract` mutation. */
export type CreateTenantContractPayload = {
  __typename: "CreateTenantContractPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `TenantContract`. */
  tenantByTenantId: Maybe<Tenant>;
  /** The `TenantContract` that was created by this mutation. */
  tenantContract: Maybe<TenantContract>;
  /** An edge for our `TenantContract`. May be used by Relay 1. */
  tenantContractEdge: Maybe<TenantContractsEdge>;
};

/** The output of our create `TenantContract` mutation. */
export type CreateTenantContractPayloadTenantContractEdgeArgs = {
  orderBy?: InputMaybe<Array<TenantContractsOrderBy>>;
};

/** All input for the create `TenantContractTransaction` mutation. */
export type CreateTenantContractTransactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `TenantContractTransaction` to be created by this mutation. */
  tenantContractTransaction: TenantContractTransactionInput;
};

/** The output of our create `TenantContractTransaction` mutation. */
export type CreateTenantContractTransactionPayload = {
  __typename: "CreateTenantContractTransactionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `TenantContract` that is related to this `TenantContractTransaction`. */
  tenantContractByTenantContractId: Maybe<TenantContract>;
  /** The `TenantContractTransaction` that was created by this mutation. */
  tenantContractTransaction: Maybe<TenantContractTransaction>;
  /** An edge for our `TenantContractTransaction`. May be used by Relay 1. */
  tenantContractTransactionEdge: Maybe<TenantContractTransactionsEdge>;
};

/** The output of our create `TenantContractTransaction` mutation. */
export type CreateTenantContractTransactionPayloadTenantContractTransactionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<TenantContractTransactionsOrderBy>>;
  };

/** All input for the create `Tenant` mutation. */
export type CreateTenantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `Tenant` to be created by this mutation. */
  tenant: TenantInput;
};

/** All input for the create `TenantMockuserexecutor` mutation. */
export type CreateTenantMockuserexecutorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `TenantMockuserexecutor` to be created by this mutation. */
  tenantMockuserexecutor: TenantMockuserexecutorInput;
};

/** The output of our create `TenantMockuserexecutor` mutation. */
export type CreateTenantMockuserexecutorPayload = {
  __typename: "CreateTenantMockuserexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TenantMockuserexecutor` that was created by this mutation. */
  tenantMockuserexecutor: Maybe<TenantMockuserexecutor>;
  /** An edge for our `TenantMockuserexecutor`. May be used by Relay 1. */
  tenantMockuserexecutorEdge: Maybe<TenantMockuserexecutorsEdge>;
  /** Reads a single `TenantUserexecutor` that is related to this `TenantMockuserexecutor`. */
  tenantUserexecutorByUserexecutorPtrId: Maybe<TenantUserexecutor>;
};

/** The output of our create `TenantMockuserexecutor` mutation. */
export type CreateTenantMockuserexecutorPayloadTenantMockuserexecutorEdgeArgs =
  {
    orderBy?: InputMaybe<Array<TenantMockuserexecutorsOrderBy>>;
  };

/** All input for the create `TenantMonthlyConsumedResource` mutation. */
export type CreateTenantMonthlyConsumedResourceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `TenantMonthlyConsumedResource` to be created by this mutation. */
  tenantMonthlyConsumedResource: TenantMonthlyConsumedResourceInput;
};

/** The output of our create `TenantMonthlyConsumedResource` mutation. */
export type CreateTenantMonthlyConsumedResourcePayload = {
  __typename: "CreateTenantMonthlyConsumedResourcePayload";
  /** Reads a single `ApplicationUser` that is related to this `TenantMonthlyConsumedResource`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `TenantMonthlyConsumedResource`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `TenantContract` that is related to this `TenantMonthlyConsumedResource`. */
  tenantContractByTenantContractId: Maybe<TenantContract>;
  /** The `TenantMonthlyConsumedResource` that was created by this mutation. */
  tenantMonthlyConsumedResource: Maybe<TenantMonthlyConsumedResource>;
  /** An edge for our `TenantMonthlyConsumedResource`. May be used by Relay 1. */
  tenantMonthlyConsumedResourceEdge: Maybe<TenantMonthlyConsumedResourcesEdge>;
};

/** The output of our create `TenantMonthlyConsumedResource` mutation. */
export type CreateTenantMonthlyConsumedResourcePayloadTenantMonthlyConsumedResourceEdgeArgs =
  {
    orderBy?: InputMaybe<Array<TenantMonthlyConsumedResourcesOrderBy>>;
  };

/** The output of our create `Tenant` mutation. */
export type CreateTenantPayload = {
  __typename: "CreateTenantPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `Tenant` that was created by this mutation. */
  tenant: Maybe<Tenant>;
  /** An edge for our `Tenant`. May be used by Relay 1. */
  tenantEdge: Maybe<TenantsEdge>;
};

/** The output of our create `Tenant` mutation. */
export type CreateTenantPayloadTenantEdgeArgs = {
  orderBy?: InputMaybe<Array<TenantsOrderBy>>;
};

/** All input for the create `TenantUserexecutor` mutation. */
export type CreateTenantUserexecutorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `TenantUserexecutor` to be created by this mutation. */
  tenantUserexecutor: TenantUserexecutorInput;
};

/** The output of our create `TenantUserexecutor` mutation. */
export type CreateTenantUserexecutorPayload = {
  __typename: "CreateTenantUserexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DjangoContentType` that is related to this `TenantUserexecutor`. */
  djangoContentTypeByPolymorphicCtypeId: Maybe<DjangoContentType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TenantUserexecutor` that was created by this mutation. */
  tenantUserexecutor: Maybe<TenantUserexecutor>;
  /** An edge for our `TenantUserexecutor`. May be used by Relay 1. */
  tenantUserexecutorEdge: Maybe<TenantUserexecutorsEdge>;
};

/** The output of our create `TenantUserexecutor` mutation. */
export type CreateTenantUserexecutorPayloadTenantUserexecutorEdgeArgs = {
  orderBy?: InputMaybe<Array<TenantUserexecutorsOrderBy>>;
};

/** All input for the create `TesContainerimageexecutor` mutation. */
export type CreateTesContainerimageexecutorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `TesContainerimageexecutor` to be created by this mutation. */
  tesContainerimageexecutor: TesContainerimageexecutorInput;
};

/** The output of our create `TesContainerimageexecutor` mutation. */
export type CreateTesContainerimageexecutorPayload = {
  __typename: "CreateTesContainerimageexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DjangoContentType` that is related to this `TesContainerimageexecutor`. */
  djangoContentTypeByPolymorphicCtypeId: Maybe<DjangoContentType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TesContainerimageexecutor` that was created by this mutation. */
  tesContainerimageexecutor: Maybe<TesContainerimageexecutor>;
  /** An edge for our `TesContainerimageexecutor`. May be used by Relay 1. */
  tesContainerimageexecutorEdge: Maybe<TesContainerimageexecutorsEdge>;
};

/** The output of our create `TesContainerimageexecutor` mutation. */
export type CreateTesContainerimageexecutorPayloadTesContainerimageexecutorEdgeArgs =
  {
    orderBy?: InputMaybe<Array<TesContainerimageexecutorsOrderBy>>;
  };

/** All input for the create `TesMockcontainerimageexecutor` mutation. */
export type CreateTesMockcontainerimageexecutorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `TesMockcontainerimageexecutor` to be created by this mutation. */
  tesMockcontainerimageexecutor: TesMockcontainerimageexecutorInput;
};

/** The output of our create `TesMockcontainerimageexecutor` mutation. */
export type CreateTesMockcontainerimageexecutorPayload = {
  __typename: "CreateTesMockcontainerimageexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `TesContainerimageexecutor` that is related to this `TesMockcontainerimageexecutor`. */
  tesContainerimageexecutorByContainerimageexecutorPtrId: Maybe<TesContainerimageexecutor>;
  /** The `TesMockcontainerimageexecutor` that was created by this mutation. */
  tesMockcontainerimageexecutor: Maybe<TesMockcontainerimageexecutor>;
  /** An edge for our `TesMockcontainerimageexecutor`. May be used by Relay 1. */
  tesMockcontainerimageexecutorEdge: Maybe<TesMockcontainerimageexecutorsEdge>;
};

/** The output of our create `TesMockcontainerimageexecutor` mutation. */
export type CreateTesMockcontainerimageexecutorPayloadTesMockcontainerimageexecutorEdgeArgs =
  {
    orderBy?: InputMaybe<Array<TesMockcontainerimageexecutorsOrderBy>>;
  };

/** All input for the create `TesMocktaskexecutor` mutation. */
export type CreateTesMocktaskexecutorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `TesMocktaskexecutor` to be created by this mutation. */
  tesMocktaskexecutor: TesMocktaskexecutorInput;
};

/** The output of our create `TesMocktaskexecutor` mutation. */
export type CreateTesMocktaskexecutorPayload = {
  __typename: "CreateTesMocktaskexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TesMocktaskexecutor` that was created by this mutation. */
  tesMocktaskexecutor: Maybe<TesMocktaskexecutor>;
  /** An edge for our `TesMocktaskexecutor`. May be used by Relay 1. */
  tesMocktaskexecutorEdge: Maybe<TesMocktaskexecutorsEdge>;
  /** Reads a single `TesTaskexecutor` that is related to this `TesMocktaskexecutor`. */
  tesTaskexecutorByTaskexecutorPtrId: Maybe<TesTaskexecutor>;
};

/** The output of our create `TesMocktaskexecutor` mutation. */
export type CreateTesMocktaskexecutorPayloadTesMocktaskexecutorEdgeArgs = {
  orderBy?: InputMaybe<Array<TesMocktaskexecutorsOrderBy>>;
};

/** All input for the create `TesTaskexecutor` mutation. */
export type CreateTesTaskexecutorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `TesTaskexecutor` to be created by this mutation. */
  tesTaskexecutor: TesTaskexecutorInput;
};

/** The output of our create `TesTaskexecutor` mutation. */
export type CreateTesTaskexecutorPayload = {
  __typename: "CreateTesTaskexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DjangoContentType` that is related to this `TesTaskexecutor`. */
  djangoContentTypeByPolymorphicCtypeId: Maybe<DjangoContentType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TesTaskexecutor` that was created by this mutation. */
  tesTaskexecutor: Maybe<TesTaskexecutor>;
  /** An edge for our `TesTaskexecutor`. May be used by Relay 1. */
  tesTaskexecutorEdge: Maybe<TesTaskexecutorsEdge>;
};

/** The output of our create `TesTaskexecutor` mutation. */
export type CreateTesTaskexecutorPayloadTesTaskexecutorEdgeArgs = {
  orderBy?: InputMaybe<Array<TesTaskexecutorsOrderBy>>;
};

/** All input for the create `ToolCategory` mutation. */
export type CreateToolCategoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ToolCategory` to be created by this mutation. */
  toolCategory: ToolCategoryInput;
};

/** The output of our create `ToolCategory` mutation. */
export type CreateToolCategoryPayload = {
  __typename: "CreateToolCategoryPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolCategory` that was created by this mutation. */
  toolCategory: Maybe<ToolCategory>;
  /** An edge for our `ToolCategory`. May be used by Relay 1. */
  toolCategoryEdge: Maybe<ToolCategoriesEdge>;
};

/** The output of our create `ToolCategory` mutation. */
export type CreateToolCategoryPayloadToolCategoryEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolCategoriesOrderBy>>;
};

/** All input for the create `ToolDisplayCategory` mutation. */
export type CreateToolDisplayCategoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ToolDisplayCategory` to be created by this mutation. */
  toolDisplayCategory: ToolDisplayCategoryInput;
};

/** The output of our create `ToolDisplayCategory` mutation. */
export type CreateToolDisplayCategoryPayload = {
  __typename: "CreateToolDisplayCategoryPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tool` that is related to this `ToolDisplayCategory`. */
  toolByToolId: Maybe<Tool>;
  /** Reads a single `ToolCategory` that is related to this `ToolDisplayCategory`. */
  toolCategoryByToolcategoryId: Maybe<ToolCategory>;
  /** The `ToolDisplayCategory` that was created by this mutation. */
  toolDisplayCategory: Maybe<ToolDisplayCategory>;
  /** An edge for our `ToolDisplayCategory`. May be used by Relay 1. */
  toolDisplayCategoryEdge: Maybe<ToolDisplayCategoriesEdge>;
};

/** The output of our create `ToolDisplayCategory` mutation. */
export type CreateToolDisplayCategoryPayloadToolDisplayCategoryEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolDisplayCategoriesOrderBy>>;
};

/** All input for the create `Tool` mutation. */
export type CreateToolInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `Tool` to be created by this mutation. */
  tool: ToolInput;
};

/** All input for the create `ToolParameter` mutation. */
export type CreateToolParameterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ToolParameter` to be created by this mutation. */
  toolParameter: ToolParameterInput;
};

/** The output of our create `ToolParameter` mutation. */
export type CreateToolParameterPayload = {
  __typename: "CreateToolParameterPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolParameter` that was created by this mutation. */
  toolParameter: Maybe<ToolParameter>;
  /** An edge for our `ToolParameter`. May be used by Relay 1. */
  toolParameterEdge: Maybe<ToolParametersEdge>;
  /** Reads a single `ToolVersion` that is related to this `ToolParameter`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our create `ToolParameter` mutation. */
export type CreateToolParameterPayloadToolParameterEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolParametersOrderBy>>;
};

/** The output of our create `Tool` mutation. */
export type CreateToolPayload = {
  __typename: "CreateToolPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `Tool` that was created by this mutation. */
  tool: Maybe<Tool>;
  /** An edge for our `Tool`. May be used by Relay 1. */
  toolEdge: Maybe<ToolsEdge>;
};

/** The output of our create `Tool` mutation. */
export type CreateToolPayloadToolEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolsOrderBy>>;
};

/** All input for the create `ToolResource` mutation. */
export type CreateToolResourceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ToolResource` to be created by this mutation. */
  toolResource: ToolResourceInput;
};

/** The output of our create `ToolResource` mutation. */
export type CreateToolResourcePayload = {
  __typename: "CreateToolResourcePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolResource` that was created by this mutation. */
  toolResource: Maybe<ToolResource>;
  /** An edge for our `ToolResource`. May be used by Relay 1. */
  toolResourceEdge: Maybe<ToolResourcesEdge>;
  /** Reads a single `ToolVersion` that is related to this `ToolResource`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our create `ToolResource` mutation. */
export type CreateToolResourcePayloadToolResourceEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolResourcesOrderBy>>;
};

/** All input for the create `ToolResult` mutation. */
export type CreateToolResultInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ToolResult` to be created by this mutation. */
  toolResult: ToolResultInput;
};

/** The output of our create `ToolResult` mutation. */
export type CreateToolResultPayload = {
  __typename: "CreateToolResultPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolResult` that was created by this mutation. */
  toolResult: Maybe<ToolResult>;
  /** An edge for our `ToolResult`. May be used by Relay 1. */
  toolResultEdge: Maybe<ToolResultsEdge>;
  /** Reads a single `ToolVersion` that is related to this `ToolResult`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our create `ToolResult` mutation. */
export type CreateToolResultPayloadToolResultEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolResultsOrderBy>>;
};

/** All input for the create `ToolSecret` mutation. */
export type CreateToolSecretInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ToolSecret` to be created by this mutation. */
  toolSecret: ToolSecretInput;
};

/** The output of our create `ToolSecret` mutation. */
export type CreateToolSecretPayload = {
  __typename: "CreateToolSecretPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolSecret` that was created by this mutation. */
  toolSecret: Maybe<ToolSecret>;
  /** An edge for our `ToolSecret`. May be used by Relay 1. */
  toolSecretEdge: Maybe<ToolSecretsEdge>;
  /** Reads a single `ToolVersion` that is related to this `ToolSecret`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our create `ToolSecret` mutation. */
export type CreateToolSecretPayloadToolSecretEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolSecretsOrderBy>>;
};

/** All input for the create `ToolSource` mutation. */
export type CreateToolSourceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ToolSource` to be created by this mutation. */
  toolSource: ToolSourceInput;
};

/** The output of our create `ToolSource` mutation. */
export type CreateToolSourcePayload = {
  __typename: "CreateToolSourcePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolSource` that was created by this mutation. */
  toolSource: Maybe<ToolSource>;
  /** An edge for our `ToolSource`. May be used by Relay 1. */
  toolSourceEdge: Maybe<ToolSourcesEdge>;
  /** Reads a single `ToolVersion` that is related to this `ToolSource`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our create `ToolSource` mutation. */
export type CreateToolSourcePayloadToolSourceEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolSourcesOrderBy>>;
};

/** All input for the create `ToolTenant` mutation. */
export type CreateToolTenantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ToolTenant` to be created by this mutation. */
  toolTenant: ToolTenantInput;
};

/** The output of our create `ToolTenant` mutation. */
export type CreateToolTenantPayload = {
  __typename: "CreateToolTenantPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `ToolTenant`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `Tool` that is related to this `ToolTenant`. */
  toolByToolId: Maybe<Tool>;
  /** The `ToolTenant` that was created by this mutation. */
  toolTenant: Maybe<ToolTenant>;
  /** An edge for our `ToolTenant`. May be used by Relay 1. */
  toolTenantEdge: Maybe<ToolTenantsEdge>;
};

/** The output of our create `ToolTenant` mutation. */
export type CreateToolTenantPayloadToolTenantEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolTenantsOrderBy>>;
};

/** All input for the create `ToolVersion` mutation. */
export type CreateToolVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ToolVersion` to be created by this mutation. */
  toolVersion: ToolVersionInput;
};

/** The output of our create `ToolVersion` mutation. */
export type CreateToolVersionPayload = {
  __typename: "CreateToolVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tool` that is related to this `ToolVersion`. */
  toolByToolId: Maybe<Tool>;
  /** The `ToolVersion` that was created by this mutation. */
  toolVersion: Maybe<ToolVersion>;
  /** An edge for our `ToolVersion`. May be used by Relay 1. */
  toolVersionEdge: Maybe<ToolVersionsEdge>;
};

/** The output of our create `ToolVersion` mutation. */
export type CreateToolVersionPayloadToolVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolVersionsOrderBy>>;
};

/** All input for the create `ToolboxExitcode` mutation. */
export type CreateToolboxExitcodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ToolboxExitcode` to be created by this mutation. */
  toolboxExitcode: ToolboxExitcodeInput;
};

/** The output of our create `ToolboxExitcode` mutation. */
export type CreateToolboxExitcodePayload = {
  __typename: "CreateToolboxExitcodePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolboxExitcode` that was created by this mutation. */
  toolboxExitcode: Maybe<ToolboxExitcode>;
  /** An edge for our `ToolboxExitcode`. May be used by Relay 1. */
  toolboxExitcodeEdge: Maybe<ToolboxExitcodesEdge>;
};

/** The output of our create `ToolboxExitcode` mutation. */
export type CreateToolboxExitcodePayloadToolboxExitcodeEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolboxExitcodesOrderBy>>;
};

/** All input for the create `Toolbox` mutation. */
export type CreateToolboxInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `Toolbox` to be created by this mutation. */
  toolbox: ToolboxInput;
};

/** The output of our create `Toolbox` mutation. */
export type CreateToolboxPayload = {
  __typename: "CreateToolboxPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `Toolbox` that was created by this mutation. */
  toolbox: Maybe<Toolbox>;
  /** An edge for our `Toolbox`. May be used by Relay 1. */
  toolboxEdge: Maybe<ToolboxesEdge>;
};

/** The output of our create `Toolbox` mutation. */
export type CreateToolboxPayloadToolboxEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolboxesOrderBy>>;
};

/** All input for the create `ToolboxTenant` mutation. */
export type CreateToolboxTenantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ToolboxTenant` to be created by this mutation. */
  toolboxTenant: ToolboxTenantInput;
};

/** The output of our create `ToolboxTenant` mutation. */
export type CreateToolboxTenantPayload = {
  __typename: "CreateToolboxTenantPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `ToolboxTenant`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `Toolbox` that is related to this `ToolboxTenant`. */
  toolboxByToolboxId: Maybe<Toolbox>;
  /** The `ToolboxTenant` that was created by this mutation. */
  toolboxTenant: Maybe<ToolboxTenant>;
  /** An edge for our `ToolboxTenant`. May be used by Relay 1. */
  toolboxTenantEdge: Maybe<ToolboxTenantsEdge>;
};

/** The output of our create `ToolboxTenant` mutation. */
export type CreateToolboxTenantPayloadToolboxTenantEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolboxTenantsOrderBy>>;
};

/** All input for the create `ToolboxVersionExitcode` mutation. */
export type CreateToolboxVersionExitcodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ToolboxVersionExitcode` to be created by this mutation. */
  toolboxVersionExitcode: ToolboxVersionExitcodeInput;
};

/** The output of our create `ToolboxVersionExitcode` mutation. */
export type CreateToolboxVersionExitcodePayload = {
  __typename: "CreateToolboxVersionExitcodePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ToolboxExitcode` that is related to this `ToolboxVersionExitcode`. */
  toolboxExitcodeByToolboxexitcodeId: Maybe<ToolboxExitcode>;
  /** Reads a single `ToolboxVersion` that is related to this `ToolboxVersionExitcode`. */
  toolboxVersionByToolboxversionId: Maybe<ToolboxVersion>;
  /** The `ToolboxVersionExitcode` that was created by this mutation. */
  toolboxVersionExitcode: Maybe<ToolboxVersionExitcode>;
  /** An edge for our `ToolboxVersionExitcode`. May be used by Relay 1. */
  toolboxVersionExitcodeEdge: Maybe<ToolboxVersionExitcodesEdge>;
};

/** The output of our create `ToolboxVersionExitcode` mutation. */
export type CreateToolboxVersionExitcodePayloadToolboxVersionExitcodeEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ToolboxVersionExitcodesOrderBy>>;
  };

/** All input for the create `ToolboxVersion` mutation. */
export type CreateToolboxVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ToolboxVersion` to be created by this mutation. */
  toolboxVersion: ToolboxVersionInput;
};

/** The output of our create `ToolboxVersion` mutation. */
export type CreateToolboxVersionPayload = {
  __typename: "CreateToolboxVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Toolbox` that is related to this `ToolboxVersion`. */
  toolboxByToolboxId: Maybe<Toolbox>;
  /** The `ToolboxVersion` that was created by this mutation. */
  toolboxVersion: Maybe<ToolboxVersion>;
  /** An edge for our `ToolboxVersion`. May be used by Relay 1. */
  toolboxVersionEdge: Maybe<ToolboxVersionsEdge>;
};

/** The output of our create `ToolboxVersion` mutation. */
export type CreateToolboxVersionPayloadToolboxVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolboxVersionsOrderBy>>;
};

/** All input for the create `ToolboxVersionToolVersion` mutation. */
export type CreateToolboxVersionToolVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ToolboxVersionToolVersion` to be created by this mutation. */
  toolboxVersionToolVersion: ToolboxVersionToolVersionInput;
};

/** The output of our create `ToolboxVersionToolVersion` mutation. */
export type CreateToolboxVersionToolVersionPayload = {
  __typename: "CreateToolboxVersionToolVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ToolVersion` that is related to this `ToolboxVersionToolVersion`. */
  toolVersionByToolversionId: Maybe<ToolVersion>;
  /** Reads a single `ToolboxVersion` that is related to this `ToolboxVersionToolVersion`. */
  toolboxVersionByToolboxversionId: Maybe<ToolboxVersion>;
  /** The `ToolboxVersionToolVersion` that was created by this mutation. */
  toolboxVersionToolVersion: Maybe<ToolboxVersionToolVersion>;
  /** An edge for our `ToolboxVersionToolVersion`. May be used by Relay 1. */
  toolboxVersionToolVersionEdge: Maybe<ToolboxVersionToolVersionsEdge>;
};

/** The output of our create `ToolboxVersionToolVersion` mutation. */
export type CreateToolboxVersionToolVersionPayloadToolboxVersionToolVersionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ToolboxVersionToolVersionsOrderBy>>;
  };

/** All input for the create `WorkflowDatum` mutation. */
export type CreateWorkflowDatumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `WorkflowDatum` to be created by this mutation. */
  workflowDatum: WorkflowDatumInput;
};

/** The output of our create `WorkflowDatum` mutation. */
export type CreateWorkflowDatumPayload = {
  __typename: "CreateWorkflowDatumPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `WorkflowDatum` that was created by this mutation. */
  workflowDatum: Maybe<WorkflowDatum>;
  /** An edge for our `WorkflowDatum`. May be used by Relay 1. */
  workflowDatumEdge: Maybe<WorkflowDataEdge>;
};

/** The output of our create `WorkflowDatum` mutation. */
export type CreateWorkflowDatumPayloadWorkflowDatumEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowDataOrderBy>>;
};

/** All input for the create `Workflow` mutation. */
export type CreateWorkflowInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `Workflow` to be created by this mutation. */
  workflow: WorkflowInput;
};

/** The output of our create `Workflow` mutation. */
export type CreateWorkflowPayload = {
  __typename: "CreateWorkflowPayload";
  /** Reads a single `ApplicationUser` that is related to this `Workflow`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `ContainerImage` that is related to this `Workflow`. */
  containerImageByContainerId: Maybe<ContainerImage>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Workflow`. */
  tenantByTenantId: Maybe<Tenant>;
  /** The `Workflow` that was created by this mutation. */
  workflow: Maybe<Workflow>;
  /** An edge for our `Workflow`. May be used by Relay 1. */
  workflowEdge: Maybe<WorkflowsEdge>;
};

/** The output of our create `Workflow` mutation. */
export type CreateWorkflowPayloadWorkflowEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowsOrderBy>>;
};

/** All input for the create `WorkflowResult` mutation. */
export type CreateWorkflowResultInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `WorkflowResult` to be created by this mutation. */
  workflowResult: WorkflowResultInput;
};

/** The output of our create `WorkflowResult` mutation. */
export type CreateWorkflowResultPayload = {
  __typename: "CreateWorkflowResultPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `WorkflowDatum` that is related to this `WorkflowResult`. */
  workflowDatumByObjectId: Maybe<WorkflowDatum>;
  /** The `WorkflowResult` that was created by this mutation. */
  workflowResult: Maybe<WorkflowResult>;
  /** An edge for our `WorkflowResult`. May be used by Relay 1. */
  workflowResultEdge: Maybe<WorkflowResultsEdge>;
};

/** The output of our create `WorkflowResult` mutation. */
export type CreateWorkflowResultPayloadWorkflowResultEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowResultsOrderBy>>;
};

/** All input for the create `WorkflowResultTag` mutation. */
export type CreateWorkflowResultTagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `WorkflowResultTag` to be created by this mutation. */
  workflowResultTag: WorkflowResultTagInput;
};

/** The output of our create `WorkflowResultTag` mutation. */
export type CreateWorkflowResultTagPayload = {
  __typename: "CreateWorkflowResultTagPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tag` that is related to this `WorkflowResultTag`. */
  tagByTagId: Maybe<Tag>;
  /** Reads a single `WorkflowResult` that is related to this `WorkflowResultTag`. */
  workflowResultByResultId: Maybe<WorkflowResult>;
  /** The `WorkflowResultTag` that was created by this mutation. */
  workflowResultTag: Maybe<WorkflowResultTag>;
  /** An edge for our `WorkflowResultTag`. May be used by Relay 1. */
  workflowResultTagEdge: Maybe<WorkflowResultTagsEdge>;
};

/** The output of our create `WorkflowResultTag` mutation. */
export type CreateWorkflowResultTagPayloadWorkflowResultTagEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowResultTagsOrderBy>>;
};

/** All input for the create `WorkflowResultWorkflowVersion` mutation. */
export type CreateWorkflowResultWorkflowVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `WorkflowResultWorkflowVersion` to be created by this mutation. */
  workflowResultWorkflowVersion: WorkflowResultWorkflowVersionInput;
};

/** The output of our create `WorkflowResultWorkflowVersion` mutation. */
export type CreateWorkflowResultWorkflowVersionPayload = {
  __typename: "CreateWorkflowResultWorkflowVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `WorkflowResult` that is related to this `WorkflowResultWorkflowVersion`. */
  workflowResultByResultId: Maybe<WorkflowResult>;
  /** The `WorkflowResultWorkflowVersion` that was created by this mutation. */
  workflowResultWorkflowVersion: Maybe<WorkflowResultWorkflowVersion>;
  /** An edge for our `WorkflowResultWorkflowVersion`. May be used by Relay 1. */
  workflowResultWorkflowVersionEdge: Maybe<WorkflowResultWorkflowVersionsEdge>;
  /** Reads a single `WorkflowVersion` that is related to this `WorkflowResultWorkflowVersion`. */
  workflowVersionByWorkflowversionId: Maybe<WorkflowVersion>;
};

/** The output of our create `WorkflowResultWorkflowVersion` mutation. */
export type CreateWorkflowResultWorkflowVersionPayloadWorkflowResultWorkflowVersionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<WorkflowResultWorkflowVersionsOrderBy>>;
  };

/** All input for the create `WorkflowSectionBlock` mutation. */
export type CreateWorkflowSectionBlockInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `WorkflowSectionBlock` to be created by this mutation. */
  workflowSectionBlock: WorkflowSectionBlockInput;
};

/** The output of our create `WorkflowSectionBlock` mutation. */
export type CreateWorkflowSectionBlockPayload = {
  __typename: "CreateWorkflowSectionBlockPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ToolVersion` that is related to this `WorkflowSectionBlock`. */
  toolVersionByToolId: Maybe<ToolVersion>;
  /** Reads a single `WorkflowDatum` that is related to this `WorkflowSectionBlock`. */
  workflowDatumByObjectId: Maybe<WorkflowDatum>;
  /** The `WorkflowSectionBlock` that was created by this mutation. */
  workflowSectionBlock: Maybe<WorkflowSectionBlock>;
  /** An edge for our `WorkflowSectionBlock`. May be used by Relay 1. */
  workflowSectionBlockEdge: Maybe<WorkflowSectionBlocksEdge>;
};

/** The output of our create `WorkflowSectionBlock` mutation. */
export type CreateWorkflowSectionBlockPayloadWorkflowSectionBlockEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowSectionBlocksOrderBy>>;
};

/** All input for the create `WorkflowSectionBlockSection` mutation. */
export type CreateWorkflowSectionBlockSectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `WorkflowSectionBlockSection` to be created by this mutation. */
  workflowSectionBlockSection: WorkflowSectionBlockSectionInput;
};

/** The output of our create `WorkflowSectionBlockSection` mutation. */
export type CreateWorkflowSectionBlockSectionPayload = {
  __typename: "CreateWorkflowSectionBlockSectionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `WorkflowSectionBlock` that is related to this `WorkflowSectionBlockSection`. */
  workflowSectionBlockByBlockId: Maybe<WorkflowSectionBlock>;
  /** The `WorkflowSectionBlockSection` that was created by this mutation. */
  workflowSectionBlockSection: Maybe<WorkflowSectionBlockSection>;
  /** An edge for our `WorkflowSectionBlockSection`. May be used by Relay 1. */
  workflowSectionBlockSectionEdge: Maybe<WorkflowSectionBlockSectionsEdge>;
  /** Reads a single `WorkflowSection` that is related to this `WorkflowSectionBlockSection`. */
  workflowSectionBySectionId: Maybe<WorkflowSection>;
};

/** The output of our create `WorkflowSectionBlockSection` mutation. */
export type CreateWorkflowSectionBlockSectionPayloadWorkflowSectionBlockSectionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<WorkflowSectionBlockSectionsOrderBy>>;
  };

/** All input for the create `WorkflowSection` mutation. */
export type CreateWorkflowSectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `WorkflowSection` to be created by this mutation. */
  workflowSection: WorkflowSectionInput;
};

/** The output of our create `WorkflowSection` mutation. */
export type CreateWorkflowSectionPayload = {
  __typename: "CreateWorkflowSectionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `WorkflowSection` that was created by this mutation. */
  workflowSection: Maybe<WorkflowSection>;
  /** An edge for our `WorkflowSection`. May be used by Relay 1. */
  workflowSectionEdge: Maybe<WorkflowSectionsEdge>;
};

/** The output of our create `WorkflowSection` mutation. */
export type CreateWorkflowSectionPayloadWorkflowSectionEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowSectionsOrderBy>>;
};

/** All input for the create `WorkflowSectionWorkflowVersion` mutation. */
export type CreateWorkflowSectionWorkflowVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `WorkflowSectionWorkflowVersion` to be created by this mutation. */
  workflowSectionWorkflowVersion: WorkflowSectionWorkflowVersionInput;
};

/** The output of our create `WorkflowSectionWorkflowVersion` mutation. */
export type CreateWorkflowSectionWorkflowVersionPayload = {
  __typename: "CreateWorkflowSectionWorkflowVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `WorkflowSection` that is related to this `WorkflowSectionWorkflowVersion`. */
  workflowSectionBySectionId: Maybe<WorkflowSection>;
  /** The `WorkflowSectionWorkflowVersion` that was created by this mutation. */
  workflowSectionWorkflowVersion: Maybe<WorkflowSectionWorkflowVersion>;
  /** An edge for our `WorkflowSectionWorkflowVersion`. May be used by Relay 1. */
  workflowSectionWorkflowVersionEdge: Maybe<WorkflowSectionWorkflowVersionsEdge>;
  /** Reads a single `WorkflowVersion` that is related to this `WorkflowSectionWorkflowVersion`. */
  workflowVersionByWorkflowversionId: Maybe<WorkflowVersion>;
};

/** The output of our create `WorkflowSectionWorkflowVersion` mutation. */
export type CreateWorkflowSectionWorkflowVersionPayloadWorkflowSectionWorkflowVersionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<WorkflowSectionWorkflowVersionsOrderBy>>;
  };

/** All input for the create `WorkflowSource` mutation. */
export type CreateWorkflowSourceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `WorkflowSource` to be created by this mutation. */
  workflowSource: WorkflowSourceInput;
};

/** The output of our create `WorkflowSource` mutation. */
export type CreateWorkflowSourcePayload = {
  __typename: "CreateWorkflowSourcePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `WorkflowSource` that was created by this mutation. */
  workflowSource: Maybe<WorkflowSource>;
  /** An edge for our `WorkflowSource`. May be used by Relay 1. */
  workflowSourceEdge: Maybe<WorkflowSourcesEdge>;
};

/** The output of our create `WorkflowSource` mutation. */
export type CreateWorkflowSourcePayloadWorkflowSourceEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowSourcesOrderBy>>;
};

/** All input for the create `WorkflowSourceTag` mutation. */
export type CreateWorkflowSourceTagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `WorkflowSourceTag` to be created by this mutation. */
  workflowSourceTag: WorkflowSourceTagInput;
};

/** The output of our create `WorkflowSourceTag` mutation. */
export type CreateWorkflowSourceTagPayload = {
  __typename: "CreateWorkflowSourceTagPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tag` that is related to this `WorkflowSourceTag`. */
  tagByTagId: Maybe<Tag>;
  /** Reads a single `WorkflowSource` that is related to this `WorkflowSourceTag`. */
  workflowSourceBySourceId: Maybe<WorkflowSource>;
  /** The `WorkflowSourceTag` that was created by this mutation. */
  workflowSourceTag: Maybe<WorkflowSourceTag>;
  /** An edge for our `WorkflowSourceTag`. May be used by Relay 1. */
  workflowSourceTagEdge: Maybe<WorkflowSourceTagsEdge>;
};

/** The output of our create `WorkflowSourceTag` mutation. */
export type CreateWorkflowSourceTagPayloadWorkflowSourceTagEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowSourceTagsOrderBy>>;
};

/** All input for the create `WorkflowSourceWorkflowVersion` mutation. */
export type CreateWorkflowSourceWorkflowVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `WorkflowSourceWorkflowVersion` to be created by this mutation. */
  workflowSourceWorkflowVersion: WorkflowSourceWorkflowVersionInput;
};

/** The output of our create `WorkflowSourceWorkflowVersion` mutation. */
export type CreateWorkflowSourceWorkflowVersionPayload = {
  __typename: "CreateWorkflowSourceWorkflowVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `WorkflowSource` that is related to this `WorkflowSourceWorkflowVersion`. */
  workflowSourceBySourceId: Maybe<WorkflowSource>;
  /** The `WorkflowSourceWorkflowVersion` that was created by this mutation. */
  workflowSourceWorkflowVersion: Maybe<WorkflowSourceWorkflowVersion>;
  /** An edge for our `WorkflowSourceWorkflowVersion`. May be used by Relay 1. */
  workflowSourceWorkflowVersionEdge: Maybe<WorkflowSourceWorkflowVersionsEdge>;
  /** Reads a single `WorkflowVersion` that is related to this `WorkflowSourceWorkflowVersion`. */
  workflowVersionByWorkflowversionId: Maybe<WorkflowVersion>;
};

/** The output of our create `WorkflowSourceWorkflowVersion` mutation. */
export type CreateWorkflowSourceWorkflowVersionPayloadWorkflowSourceWorkflowVersionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<WorkflowSourceWorkflowVersionsOrderBy>>;
  };

/** All input for the create `WorkflowVersion` mutation. */
export type CreateWorkflowVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `WorkflowVersion` to be created by this mutation. */
  workflowVersion: WorkflowVersionInput;
};

/** The output of our create `WorkflowVersion` mutation. */
export type CreateWorkflowVersionPayload = {
  __typename: "CreateWorkflowVersionPayload";
  /** Reads a single `ApplicationUser` that is related to this `WorkflowVersion`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Workflow` that is related to this `WorkflowVersion`. */
  workflowByWorkflowId: Maybe<Workflow>;
  /** The `WorkflowVersion` that was created by this mutation. */
  workflowVersion: Maybe<WorkflowVersion>;
  /** An edge for our `WorkflowVersion`. May be used by Relay 1. */
  workflowVersionEdge: Maybe<WorkflowVersionsEdge>;
};

/** The output of our create `WorkflowVersion` mutation. */
export type CreateWorkflowVersionPayloadWorkflowVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowVersionsOrderBy>>;
};

export type CurrentApplicationUser = {
  __typename: "CurrentApplicationUser";
  email: Maybe<Scalars["String"]["output"]>;
  globalUserId: Maybe<Scalars["UUID"]["output"]>;
  id: Maybe<Scalars["UUID"]["output"]>;
  isActive: Maybe<Scalars["Boolean"]["output"]>;
  username: Maybe<Scalars["String"]["output"]>;
};

/**
 * A condition to be used against `CurrentApplicationUser` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CurrentApplicationUserCondition = {
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `globalUserId` field. */
  globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `CurrentApplicationUser` object types. All fields are combined with a logical ‘and.’ */
export type CurrentApplicationUserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CurrentApplicationUserFilter>>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `globalUserId` field. */
  globalUserId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CurrentApplicationUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CurrentApplicationUserFilter>>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `CurrentApplicationUser` */
export type CurrentApplicationUserInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

/** A connection to a list of `CurrentApplicationUser` values. */
export type CurrentApplicationUsersConnection = {
  __typename: "CurrentApplicationUsersConnection";
  /** A list of edges which contains the `CurrentApplicationUser` and cursor to aid in pagination. */
  edges: Array<CurrentApplicationUsersEdge>;
  /** A list of `CurrentApplicationUser` objects. */
  nodes: Array<CurrentApplicationUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CurrentApplicationUser` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `CurrentApplicationUser` edge in the connection. */
export type CurrentApplicationUsersEdge = {
  __typename: "CurrentApplicationUsersEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `CurrentApplicationUser` at the end of the edge. */
  node: CurrentApplicationUser;
};

/** Methods to use when ordering `CurrentApplicationUser`. */
export enum CurrentApplicationUsersOrderBy {
  EmailAsc = "EMAIL_ASC",
  EmailDesc = "EMAIL_DESC",
  GlobalUserIdAsc = "GLOBAL_USER_ID_ASC",
  GlobalUserIdDesc = "GLOBAL_USER_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsActiveAsc = "IS_ACTIVE_ASC",
  IsActiveDesc = "IS_ACTIVE_DESC",
  Natural = "NATURAL",
  UsernameAsc = "USERNAME_ASC",
  UsernameDesc = "USERNAME_DESC",
}

export type CurrentUserMembership = {
  __typename: "CurrentUserMembership";
  role: Maybe<Scalars["Int"]["output"]>;
  tenantId: Maybe<Scalars["Int"]["output"]>;
  userId: Maybe<Scalars["UUID"]["output"]>;
};

/**
 * A condition to be used against `CurrentUserMembership` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CurrentUserMembershipCondition = {
  /** Checks for equality with the object’s `role` field. */
  role?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `CurrentUserMembership` object types. All fields are combined with a logical ‘and.’ */
export type CurrentUserMembershipFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CurrentUserMembershipFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<CurrentUserMembershipFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CurrentUserMembershipFilter>>;
  /** Filter by the object’s `role` field. */
  role?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `CurrentUserMembership` */
export type CurrentUserMembershipInput = {
  role?: InputMaybe<Scalars["Int"]["input"]>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A connection to a list of `CurrentUserMembership` values. */
export type CurrentUserMembershipsConnection = {
  __typename: "CurrentUserMembershipsConnection";
  /** A list of edges which contains the `CurrentUserMembership` and cursor to aid in pagination. */
  edges: Array<CurrentUserMembershipsEdge>;
  /** A list of `CurrentUserMembership` objects. */
  nodes: Array<CurrentUserMembership>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CurrentUserMembership` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `CurrentUserMembership` edge in the connection. */
export type CurrentUserMembershipsEdge = {
  __typename: "CurrentUserMembershipsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `CurrentUserMembership` at the end of the edge. */
  node: CurrentUserMembership;
};

/** Methods to use when ordering `CurrentUserMembership`. */
export enum CurrentUserMembershipsOrderBy {
  Natural = "NATURAL",
  RoleAsc = "ROLE_ASC",
  RoleDesc = "ROLE_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type Dataset = {
  __typename: "Dataset";
  activeDescription: Maybe<Scalars["JSON"]["output"]>;
  /** Reads a single `ApplicationUser` that is related to this `Dataset`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** Reads and enables pagination through a set of `DatasetMetadatum`. */
  datasetMetadataByDatasetId: DatasetMetadataConnection;
  /** Reads a single `DatasetRecordingsTable` that is related to this `Dataset`. */
  datasetRecordingsTableByRecordingsTableId: Maybe<DatasetRecordingsTable>;
  /** Reads and enables pagination through a set of `DatasetVersion`. */
  datasetVersionsByDatasetId: DatasetVersionsConnection;
  dateArchived: Maybe<Scalars["Datetime"]["output"]>;
  dateCreated: Scalars["Datetime"]["output"];
  dateDataDeleted: Maybe<Scalars["Datetime"]["output"]>;
  dateDeleted: Maybe<Scalars["Datetime"]["output"]>;
  descriptionId: Maybe<Scalars["UUID"]["output"]>;
  /** Reads and enables pagination through a set of `File`. */
  filesByDatasetId: FilesConnection;
  id: Scalars["UUID"]["output"];
  key: Scalars["String"]["output"];
  /** Reads a single `Metadatum` that is related to this `Dataset`. */
  metadatumByDescriptionId: Maybe<Metadatum>;
  name: Scalars["String"]["output"];
  prefix: Scalars["String"]["output"];
  /** Reads a single `Project` that is related to this `Dataset`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  recordingsTableId: Scalars["UUID"]["output"];
  status: Scalars["Int"]["output"];
  /** Reads and enables pagination through a set of `Task`. */
  tasksByDatasetId: TasksConnection;
  /** Reads a single `Tenant` that is related to this `Dataset`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  userId: Scalars["UUID"]["output"];
};

export type DatasetActiveDescriptionArgs = {
  at?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type DatasetDatasetMetadataByDatasetIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetMetadatumCondition>;
  filter?: InputMaybe<DatasetMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetMetadataOrderBy>>;
};

export type DatasetDatasetVersionsByDatasetIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetVersionCondition>;
  filter?: InputMaybe<DatasetVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetVersionsOrderBy>>;
};

export type DatasetFilesByDatasetIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileCondition>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type DatasetTasksByDatasetIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskCondition>;
  filter?: InputMaybe<TaskFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
};

/** A condition to be used against `Dataset` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type DatasetCondition = {
  /** Checks for equality with the object’s `dateArchived` field. */
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateDataDeleted` field. */
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateDeleted` field. */
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `descriptionId` field. */
  descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `prefix` field. */
  prefix?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `recordingsTableId` field. */
  recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `dataset` to look up the row to connect. */
export type DatasetDatasetKeyTenantUniqueConnect = {
  key: Scalars["String"]["input"];
  tenantId: Scalars["Int"]["input"];
};

/** The fields on `dataset` to look up the row to delete. */
export type DatasetDatasetKeyTenantUniqueDelete = {
  key: Scalars["String"]["input"];
  tenantId: Scalars["Int"]["input"];
};

/** The fields on `dataset` to look up the row to connect. */
export type DatasetDatasetPrefixProjectUniqueConnect = {
  prefix: Scalars["String"]["input"];
  projectId: Scalars["UUID"]["input"];
};

/** The fields on `dataset` to look up the row to delete. */
export type DatasetDatasetPrefixProjectUniqueDelete = {
  prefix: Scalars["String"]["input"];
  projectId: Scalars["UUID"]["input"];
};

/** The fields on `dataset` to look up the row to connect. */
export type DatasetDatasetV4PkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `dataset` to look up the row to delete. */
export type DatasetDatasetV4PkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `dataset` to look up the row to connect. */
export type DatasetDatasetV4RecordingsTableIdKeyConnect = {
  recordingsTableId: Scalars["UUID"]["input"];
};

/** The fields on `dataset` to look up the row to delete. */
export type DatasetDatasetV4RecordingsTableIdKeyDelete = {
  recordingsTableId: Scalars["UUID"]["input"];
};

/** The `dataset` to be created by this mutation. */
export type DatasetDescriptionId4043Bd78FkMetadataV4IdDatasetCreateInput = {
  applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
  datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
  id: Scalars["UUID"]["input"];
  key: Scalars["String"]["input"];
  metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
  name: Scalars["String"]["input"];
  prefix: Scalars["String"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
  recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  status: Scalars["Int"]["input"];
  tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `metadatum` in the `DatasetInput` mutation. */
export type DatasetDescriptionId4043Bd78FkMetadataV4IdInput = {
  /** The primary key(s) for `metadatum` for the far side of the relationship. */
  connectById?: InputMaybe<MetadatumMetadataV4PkeyConnect>;
  /** A `MetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdMetadataCreateInput>;
  /** The primary key(s) for `metadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<MetadatumMetadataV4PkeyDelete>;
  /** The primary key(s) and patch data for `metadatum` for the far side of the relationship. */
  updateById?: InputMaybe<MetadatumOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingMetadataV4PkeyUpdate>;
};

/** Input for the nested mutation of `dataset` in the `MetadatumInput` mutation. */
export type DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput = {
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DatasetDatasetV4PkeyConnect>>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByProjectIdAndPrefix?: InputMaybe<
    Array<DatasetDatasetPrefixProjectUniqueConnect>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByRecordingsTableId?: InputMaybe<
    Array<DatasetDatasetV4RecordingsTableIdKeyConnect>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByTenantIdAndKey?: InputMaybe<
    Array<DatasetDatasetKeyTenantUniqueConnect>
  >;
  /** A `DatasetInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DatasetDescriptionId4043Bd78FkMetadataV4IdDatasetCreateInput>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DatasetDatasetV4PkeyDelete>>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByProjectIdAndPrefix?: InputMaybe<
    Array<DatasetDatasetPrefixProjectUniqueDelete>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByRecordingsTableId?: InputMaybe<
    Array<DatasetDatasetV4RecordingsTableIdKeyDelete>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByTenantIdAndKey?: InputMaybe<
    Array<DatasetDatasetKeyTenantUniqueDelete>
  >;
  /** Flag indicating whether all other `dataset` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetV4PkeyUpdate>
  >;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByProjectIdAndPrefix?: InputMaybe<
    Array<DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetPrefixProjectUniqueUpdate>
  >;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByRecordingsTableId?: InputMaybe<
    Array<DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetV4RecordingsTableIdKeyUpdate>
  >;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByTenantIdAndKey?: InputMaybe<
    Array<DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetKeyTenantUniqueUpdate>
  >;
};

/** The `metadatum` to be created by this mutation. */
export type DatasetDescriptionId4043Bd78FkMetadataV4IdMetadataCreateInput = {
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
  displayName: Scalars["String"]["input"];
  fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
  id: Scalars["UUID"]["input"];
  key: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
};

/** A filter to be used against `Dataset` object types. All fields are combined with a logical ‘and.’ */
export type DatasetFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DatasetFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `datasetMetadataByDatasetId` relation. */
  datasetMetadataByDatasetId?: InputMaybe<DatasetToManyDatasetMetadatumFilter>;
  /** Some related `datasetMetadataByDatasetId` exist. */
  datasetMetadataByDatasetIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `datasetRecordingsTableByRecordingsTableId` relation. */
  datasetRecordingsTableByRecordingsTableId?: InputMaybe<DatasetRecordingsTableFilter>;
  /** Filter by the object’s `datasetVersionsByDatasetId` relation. */
  datasetVersionsByDatasetId?: InputMaybe<DatasetToManyDatasetVersionFilter>;
  /** Some related `datasetVersionsByDatasetId` exist. */
  datasetVersionsByDatasetIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `dateArchived` field. */
  dateArchived?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateDataDeleted` field. */
  dateDataDeleted?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateDeleted` field. */
  dateDeleted?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `descriptionId` field. */
  descriptionId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `filesByDatasetId` relation. */
  filesByDatasetId?: InputMaybe<DatasetToManyFileFilter>;
  /** Some related `filesByDatasetId` exist. */
  filesByDatasetIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metadatumByDescriptionId` relation. */
  metadatumByDescriptionId?: InputMaybe<MetadatumFilter>;
  /** A related `metadatumByDescriptionId` exists. */
  metadatumByDescriptionIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DatasetFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DatasetFilter>>;
  /** Filter by the object’s `prefix` field. */
  prefix?: InputMaybe<StringFilter>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `recordingsTableId` field. */
  recordingsTableId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tasksByDatasetId` relation. */
  tasksByDatasetId?: InputMaybe<DatasetToManyTaskFilter>;
  /** Some related `tasksByDatasetId` exist. */
  tasksByDatasetIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `Dataset` */
export type DatasetInput = {
  applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
  datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
  filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
  id: Scalars["UUID"]["input"];
  key: Scalars["String"]["input"];
  metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
  name: Scalars["String"]["input"];
  prefix: Scalars["String"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
  recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  status: Scalars["Int"]["input"];
  tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A connection to a list of `DatasetMetadatum` values. */
export type DatasetMetadataConnection = {
  __typename: "DatasetMetadataConnection";
  /** A list of edges which contains the `DatasetMetadatum` and cursor to aid in pagination. */
  edges: Array<DatasetMetadataEdge>;
  /** A list of `DatasetMetadatum` objects. */
  nodes: Array<DatasetMetadatum>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DatasetMetadatum` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** The `dataset` to be created by this mutation. */
export type DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdDatasetCreateInput = {
  applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
  datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
  filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
  id: Scalars["UUID"]["input"];
  key: Scalars["String"]["input"];
  metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
  name: Scalars["String"]["input"];
  prefix: Scalars["String"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
  recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  status: Scalars["Int"]["input"];
  tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `datasetMetadatum` to be created by this mutation. */
export type DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdDatasetMetadataCreateInput =
  {
    datasetToDatasetId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInput>;
  };

/** Input for the nested mutation of `dataset` in the `DatasetMetadatumInput` mutation. */
export type DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInput = {
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectById?: InputMaybe<DatasetDatasetV4PkeyConnect>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByProjectIdAndPrefix?: InputMaybe<DatasetDatasetPrefixProjectUniqueConnect>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByRecordingsTableId?: InputMaybe<DatasetDatasetV4RecordingsTableIdKeyConnect>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByTenantIdAndKey?: InputMaybe<DatasetDatasetKeyTenantUniqueConnect>;
  /** A `DatasetInput` object that will be created and connected to this object. */
  create?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdDatasetCreateInput>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteById?: InputMaybe<DatasetDatasetV4PkeyDelete>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByProjectIdAndPrefix?: InputMaybe<DatasetDatasetPrefixProjectUniqueDelete>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByRecordingsTableId?: InputMaybe<DatasetDatasetV4RecordingsTableIdKeyDelete>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByTenantIdAndKey?: InputMaybe<DatasetDatasetKeyTenantUniqueDelete>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateById?: InputMaybe<DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetV4PkeyUpdate>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByProjectIdAndPrefix?: InputMaybe<DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetPrefixProjectUniqueUpdate>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByRecordingsTableId?: InputMaybe<DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetV4RecordingsTableIdKeyUpdate>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByTenantIdAndKey?: InputMaybe<DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `datasetMetadatum` in the `DatasetInput` mutation. */
export type DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput = {
  /** The primary key(s) for `datasetMetadatum` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DatasetMetadatumDatasetMetadataPkeyConnect>>;
  /** A `DatasetMetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdDatasetMetadataCreateInput>
  >;
  /** The primary key(s) for `datasetMetadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DatasetMetadatumDatasetMetadataPkeyDelete>>;
  /** Flag indicating whether all other `datasetMetadatum` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `datasetMetadatum` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetMetadatumOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetMetadataPkeyUpdate>
  >;
};

/** A `DatasetMetadatum` edge in the connection. */
export type DatasetMetadataEdge = {
  __typename: "DatasetMetadataEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DatasetMetadatum` at the end of the edge. */
  node: DatasetMetadatum;
};

/** The `datasetMetadatum` to be created by this mutation. */
export type DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdDatasetMetadataCreateInput =
  {
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    metadatumToMetadataId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInput>;
  };

/** Input for the nested mutation of `metadatum` in the `DatasetMetadatumInput` mutation. */
export type DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInput = {
  /** The primary key(s) for `metadatum` for the far side of the relationship. */
  connectById?: InputMaybe<MetadatumMetadataV4PkeyConnect>;
  /** A `MetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdMetadataCreateInput>;
  /** The primary key(s) for `metadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<MetadatumMetadataV4PkeyDelete>;
  /** The primary key(s) and patch data for `metadatum` for the far side of the relationship. */
  updateById?: InputMaybe<MetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdUsingMetadataV4PkeyUpdate>;
};

/** Input for the nested mutation of `datasetMetadatum` in the `MetadatumInput` mutation. */
export type DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput = {
  /** The primary key(s) for `datasetMetadatum` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DatasetMetadatumDatasetMetadataPkeyConnect>>;
  /** A `DatasetMetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdDatasetMetadataCreateInput>
  >;
  /** The primary key(s) for `datasetMetadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DatasetMetadatumDatasetMetadataPkeyDelete>>;
  /** Flag indicating whether all other `datasetMetadatum` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `datasetMetadatum` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetMetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdUsingDatasetMetadataPkeyUpdate>
  >;
};

/** The `metadatum` to be created by this mutation. */
export type DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdMetadataCreateInput =
  {
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
    displayName: Scalars["String"]["input"];
    fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
    id: Scalars["UUID"]["input"];
    key: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
  };

/** Methods to use when ordering `DatasetMetadatum`. */
export enum DatasetMetadataOrderBy {
  DatasetIdAsc = "DATASET_ID_ASC",
  DatasetIdDesc = "DATASET_ID_DESC",
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MetadataIdAsc = "METADATA_ID_ASC",
  MetadataIdDesc = "METADATA_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
}

/** The `datasetMetadatum` to be created by this mutation. */
export type DatasetMetadataProjectIdB921B40CFkProjectIdDatasetMetadataCreateInput =
  {
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInput>;
    projectToProjectId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInput>;
  };

/** Input for the nested mutation of `project` in the `DatasetMetadatumInput` mutation. */
export type DatasetMetadataProjectIdB921B40CFkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `datasetMetadatum` in the `ProjectInput` mutation. */
export type DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput = {
  /** The primary key(s) for `datasetMetadatum` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DatasetMetadatumDatasetMetadataPkeyConnect>>;
  /** A `DatasetMetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DatasetMetadataProjectIdB921B40CFkProjectIdDatasetMetadataCreateInput>
  >;
  /** The primary key(s) for `datasetMetadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DatasetMetadatumDatasetMetadataPkeyDelete>>;
  /** Flag indicating whether all other `datasetMetadatum` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `datasetMetadatum` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetMetadatumOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdUsingDatasetMetadataPkeyUpdate>
  >;
};

/** The `datasetMetadatum` to be created by this mutation. */
export type DatasetMetadataTenantIdD74Eb48BFkTenantIdDatasetMetadataCreateInput =
  {
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInput>;
    tenantToTenantId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInput>;
  };

/** Input for the nested mutation of `tenant` in the `DatasetMetadatumInput` mutation. */
export type DatasetMetadataTenantIdD74Eb48BFkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `datasetMetadatum` in the `TenantInput` mutation. */
export type DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput = {
  /** The primary key(s) for `datasetMetadatum` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DatasetMetadatumDatasetMetadataPkeyConnect>>;
  /** A `DatasetMetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DatasetMetadataTenantIdD74Eb48BFkTenantIdDatasetMetadataCreateInput>
  >;
  /** The primary key(s) for `datasetMetadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DatasetMetadatumDatasetMetadataPkeyDelete>>;
  /** Flag indicating whether all other `datasetMetadatum` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `datasetMetadatum` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetMetadatumOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingDatasetMetadataPkeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type DatasetMetadataTenantIdD74Eb48BFkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

export type DatasetMetadatum = {
  __typename: "DatasetMetadatum";
  /** Reads a single `Dataset` that is related to this `DatasetMetadatum`. */
  datasetByDatasetId: Maybe<Dataset>;
  datasetId: Maybe<Scalars["UUID"]["output"]>;
  dateCreated: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  metadataId: Scalars["UUID"]["output"];
  /** Reads a single `Metadatum` that is related to this `DatasetMetadatum`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `DatasetMetadatum`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  /** Reads a single `Tenant` that is related to this `DatasetMetadatum`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
};

/**
 * A condition to be used against `DatasetMetadatum` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DatasetMetadatumCondition = {
  /** Checks for equality with the object’s `datasetId` field. */
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `metadataId` field. */
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `datasetMetadatum` to look up the row to connect. */
export type DatasetMetadatumDatasetMetadataPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `datasetMetadatum` to look up the row to delete. */
export type DatasetMetadatumDatasetMetadataPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** A filter to be used against `DatasetMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type DatasetMetadatumFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DatasetMetadatumFilter>>;
  /** Filter by the object’s `datasetByDatasetId` relation. */
  datasetByDatasetId?: InputMaybe<DatasetFilter>;
  /** A related `datasetByDatasetId` exists. */
  datasetByDatasetIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `datasetId` field. */
  datasetId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `metadataId` field. */
  metadataId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `metadatumByMetadataId` relation. */
  metadatumByMetadataId?: InputMaybe<MetadatumFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DatasetMetadatumFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DatasetMetadatumFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `DatasetMetadatum` */
export type DatasetMetadatumInput = {
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id: Scalars["UUID"]["input"];
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  metadatumToMetadataId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInput>;
};

/** The fields on `datasetMetadatum` to look up the row to update. */
export type DatasetMetadatumOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetMetadataPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetMetadatum` being updated. */
    datasetMetadatumPatch: UpdateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `datasetMetadatum` to look up the row to update. */
export type DatasetMetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdUsingDatasetMetadataPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetMetadatum` being updated. */
    datasetMetadatumPatch: UpdateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `datasetMetadatum` to look up the row to update. */
export type DatasetMetadatumOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdUsingDatasetMetadataPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetMetadatum` being updated. */
    datasetMetadatumPatch: UpdateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `datasetMetadatum` to look up the row to update. */
export type DatasetMetadatumOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingDatasetMetadataPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetMetadatum` being updated. */
    datasetMetadatumPatch: UpdateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** Represents an update to a `DatasetMetadatum`. Fields that are set will be updated. */
export type DatasetMetadatumPatch = {
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  metadatumToMetadataId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInput>;
};

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetKeyTenantUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdPatch;
    key: Scalars["String"]["input"];
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetPrefixProjectUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdPatch;
    prefix: Scalars["String"]["input"];
    projectId: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetV4PkeyUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetV4RecordingsTableIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdPatch;
    recordingsTableId: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetKeyTenantUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdPatch;
    key: Scalars["String"]["input"];
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetPrefixProjectUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdPatch;
    prefix: Scalars["String"]["input"];
    projectId: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetV4PkeyUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetV4RecordingsTableIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdPatch;
    recordingsTableId: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetKeyTenantUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRPatch;
    key: Scalars["String"]["input"];
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetPrefixProjectUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRPatch;
    prefix: Scalars["String"]["input"];
    projectId: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetV4PkeyUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetV4RecordingsTableIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRPatch;
    recordingsTableId: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetKeyTenantUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdPatch;
    key: Scalars["String"]["input"];
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetPrefixProjectUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdPatch;
    prefix: Scalars["String"]["input"];
    projectId: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetV4PkeyUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetV4RecordingsTableIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdPatch;
    recordingsTableId: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetKeyTenantUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdPatch;
    key: Scalars["String"]["input"];
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetPrefixProjectUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdPatch;
    prefix: Scalars["String"]["input"];
    projectId: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetV4PkeyUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetV4RecordingsTableIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdPatch;
    recordingsTableId: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetKeyTenantUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdPatch;
    key: Scalars["String"]["input"];
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetPrefixProjectUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdPatch;
    prefix: Scalars["String"]["input"];
    projectId: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetV4PkeyUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetV4RecordingsTableIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdPatch;
    recordingsTableId: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetKeyTenantUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdPatch;
    key: Scalars["String"]["input"];
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetPrefixProjectUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdPatch;
    prefix: Scalars["String"]["input"];
    projectId: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetV4PkeyUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetV4RecordingsTableIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdPatch;
    recordingsTableId: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetKeyTenantUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdPatch;
    key: Scalars["String"]["input"];
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetPrefixProjectUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdPatch;
    prefix: Scalars["String"]["input"];
    projectId: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetV4PkeyUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetV4RecordingsTableIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdPatch;
    recordingsTableId: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetKeyTenantUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdPatch;
    key: Scalars["String"]["input"];
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetPrefixProjectUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdPatch;
    prefix: Scalars["String"]["input"];
    projectId: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetV4PkeyUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `dataset` to look up the row to update. */
export type DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetV4RecordingsTableIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `dataset` being updated. */
    datasetPatch: UpdateDatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdPatch;
    recordingsTableId: Scalars["UUID"]["input"];
  };

/** Represents an update to a `Dataset`. Fields that are set will be updated. */
export type DatasetPatch = {
  applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
  datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
  filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  prefix?: InputMaybe<Scalars["String"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
  recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `datasetRecordingsTableColumn` to be created by this mutation. */
export type DatasetRecordingsTColDefId837663C9FkMetadataDatasetRecordingsTableColumnCreateInput =
  {
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    identifierPosition?: InputMaybe<Scalars["Int"]["input"]>;
    metadatumToColDefId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInput>;
    pinned: Scalars["Boolean"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput>;
    width: Scalars["Int"]["input"];
  };

/** Input for the nested mutation of `metadatum` in the `DatasetRecordingsTableColumnInput` mutation. */
export type DatasetRecordingsTColDefId837663C9FkMetadataInput = {
  /** The primary key(s) for `metadatum` for the far side of the relationship. */
  connectById?: InputMaybe<MetadatumMetadataV4PkeyConnect>;
  /** A `MetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataMetadataCreateInput>;
  /** The primary key(s) for `metadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<MetadatumMetadataV4PkeyDelete>;
  /** The primary key(s) and patch data for `metadatum` for the far side of the relationship. */
  updateById?: InputMaybe<MetadatumOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataUsingMetadataV4PkeyUpdate>;
};

/** Input for the nested mutation of `datasetRecordingsTableColumn` in the `MetadatumInput` mutation. */
export type DatasetRecordingsTColDefId837663C9FkMetadataInverseInput = {
  /** The primary key(s) for `datasetRecordingsTableColumn` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyConnect>
  >;
  /** A `DatasetRecordingsTableColumnInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DatasetRecordingsTColDefId837663C9FkMetadataDatasetRecordingsTableColumnCreateInput>
  >;
  /** The primary key(s) for `datasetRecordingsTableColumn` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyDelete>
  >;
  /** Flag indicating whether all other `datasetRecordingsTableColumn` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `datasetRecordingsTableColumn` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataUsingDatasetRecordingsTableColumnPkeyUpdate>
  >;
};

/** The `metadatum` to be created by this mutation. */
export type DatasetRecordingsTColDefId837663C9FkMetadataMetadataCreateInput = {
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
  displayName: Scalars["String"]["input"];
  fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
  id: Scalars["UUID"]["input"];
  key: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
};

/** The `datasetRecordingsTableColumn` to be created by this mutation. */
export type DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRDatasetRecordingsTableColumnCreateInput =
  {
    colDefId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    identifierPosition?: InputMaybe<Scalars["Int"]["input"]>;
    metadatumToColDefId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInput>;
    pinned: Scalars["Boolean"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput>;
    width: Scalars["Int"]["input"];
  };

/** The `datasetRecordingsTableColumnOrder` to be created by this mutation. */
export type DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRDatasetRecordingsTableColumnOrderCreateInput =
  {
    datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInput>;
    value: Scalars["Int"]["input"];
  };

/** Input for the nested mutation of `datasetRecordingsTableColumn` in the `DatasetRecordingsTableColumnOrderInput` mutation. */
export type DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInput = {
  /** The primary key(s) for `datasetRecordingsTableColumn` for the far side of the relationship. */
  connectById?: InputMaybe<DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyConnect>;
  /** A `DatasetRecordingsTableColumnInput` object that will be created and connected to this object. */
  create?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRDatasetRecordingsTableColumnCreateInput>;
  /** The primary key(s) for `datasetRecordingsTableColumn` for the far side of the relationship. */
  deleteById?: InputMaybe<DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyDelete>;
  /** The primary key(s) and patch data for `datasetRecordingsTableColumn` for the far side of the relationship. */
  updateById?: InputMaybe<DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRUsingDatasetRecordingsTableColumnPkeyUpdate>;
};

/** Input for the nested mutation of `datasetRecordingsTableColumnOrder` in the `DatasetRecordingsTableColumnInput` mutation. */
export type DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput =
  {
    /** The primary key(s) for `datasetRecordingsTableColumnOrder` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<DatasetRecordingsTableColumnOrderDatasetRecordingsTableColumnOrderPkeyConnect>
    >;
    /** A `DatasetRecordingsTableColumnOrderInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRDatasetRecordingsTableColumnOrderCreateInput>
    >;
    /** The primary key(s) for `datasetRecordingsTableColumnOrder` for the far side of the relationship. */
    deleteById?: InputMaybe<
      Array<DatasetRecordingsTableColumnOrderDatasetRecordingsTableColumnOrderPkeyDelete>
    >;
    /** Flag indicating whether all other `datasetRecordingsTableColumnOrder` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `datasetRecordingsTableColumnOrder` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<DatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRUsingDatasetRecordingsTableColumnOrderPkeyUpdate>
    >;
  };

/** The `datasetRecordingsTableColumn` to be created by this mutation. */
export type DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRDatasetRecordingsTableColumnCreateInput =
  {
    colDefId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    identifierPosition?: InputMaybe<Scalars["Int"]["input"]>;
    metadatumToColDefId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInput>;
    pinned: Scalars["Boolean"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput>;
    width: Scalars["Int"]["input"];
  };

/** The `datasetRecordingsTable` to be created by this mutation. */
export type DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRDatasetRecordingsTableCreateInput =
  {
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInverseInput>;
    datasetUsingId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInverseInput>;
    id: Scalars["UUID"]["input"];
    name: Scalars["String"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInput>;
    views?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** Input for the nested mutation of `datasetRecordingsTable` in the `DatasetRecordingsTableColumnInput` mutation. */
export type DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput = {
  /** The primary key(s) for `datasetRecordingsTable` for the far side of the relationship. */
  connectById?: InputMaybe<DatasetRecordingsTableDatasetRecordingsTablePkeyConnect>;
  /** A `DatasetRecordingsTableInput` object that will be created and connected to this object. */
  create?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRDatasetRecordingsTableCreateInput>;
  /** The primary key(s) for `datasetRecordingsTable` for the far side of the relationship. */
  deleteById?: InputMaybe<DatasetRecordingsTableDatasetRecordingsTablePkeyDelete>;
  /** The primary key(s) and patch data for `datasetRecordingsTable` for the far side of the relationship. */
  updateById?: InputMaybe<DatasetRecordingsTableOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRUsingDatasetRecordingsTablePkeyUpdate>;
};

/** Input for the nested mutation of `datasetRecordingsTableColumn` in the `DatasetRecordingsTableInput` mutation. */
export type DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInverseInput =
  {
    /** The primary key(s) for `datasetRecordingsTableColumn` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyConnect>
    >;
    /** A `DatasetRecordingsTableColumnInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRDatasetRecordingsTableColumnCreateInput>
    >;
    /** The primary key(s) for `datasetRecordingsTableColumn` for the far side of the relationship. */
    deleteById?: InputMaybe<
      Array<DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyDelete>
    >;
    /** Flag indicating whether all other `datasetRecordingsTableColumn` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `datasetRecordingsTableColumn` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRUsingDatasetRecordingsTableColumnPkeyUpdate>
    >;
  };

/** The `datasetRecordingsTableColumnOrder` to be created by this mutation. */
export type DatasetRecordingsTProjectId8E1D93E1FkProjectIDatasetRecordingsTableColumnOrderCreateInput =
  {
    datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInput>;
    value: Scalars["Int"]["input"];
  };

/** Input for the nested mutation of `project` in the `DatasetRecordingsTableColumnOrderInput` mutation. */
export type DatasetRecordingsTProjectId8E1D93E1FkProjectIInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `datasetRecordingsTableColumnOrder` in the `ProjectInput` mutation. */
export type DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput = {
  /** The primary key(s) for `datasetRecordingsTableColumnOrder` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<DatasetRecordingsTableColumnOrderDatasetRecordingsTableColumnOrderPkeyConnect>
  >;
  /** A `DatasetRecordingsTableColumnOrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DatasetRecordingsTProjectId8E1D93E1FkProjectIDatasetRecordingsTableColumnOrderCreateInput>
  >;
  /** The primary key(s) for `datasetRecordingsTableColumnOrder` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<DatasetRecordingsTableColumnOrderDatasetRecordingsTableColumnOrderPkeyDelete>
  >;
  /** Flag indicating whether all other `datasetRecordingsTableColumnOrder` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `datasetRecordingsTableColumnOrder` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIUsingDatasetRecordingsTableColumnOrderPkeyUpdate>
  >;
};

/** The `datasetRecordingsTableColumn` to be created by this mutation. */
export type DatasetRecordingsTProjectId40Dba6D7FkProjectIDatasetRecordingsTableColumnCreateInput =
  {
    colDefId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    identifierPosition?: InputMaybe<Scalars["Int"]["input"]>;
    metadatumToColDefId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInput>;
    pinned: Scalars["Boolean"]["input"];
    projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput>;
    width: Scalars["Int"]["input"];
  };

/** Input for the nested mutation of `project` in the `DatasetRecordingsTableColumnInput` mutation. */
export type DatasetRecordingsTProjectId40Dba6D7FkProjectIInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `datasetRecordingsTableColumn` in the `ProjectInput` mutation. */
export type DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput = {
  /** The primary key(s) for `datasetRecordingsTableColumn` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyConnect>
  >;
  /** A `DatasetRecordingsTableColumnInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DatasetRecordingsTProjectId40Dba6D7FkProjectIDatasetRecordingsTableColumnCreateInput>
  >;
  /** The primary key(s) for `datasetRecordingsTableColumn` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyDelete>
  >;
  /** Flag indicating whether all other `datasetRecordingsTableColumn` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `datasetRecordingsTableColumn` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIUsingDatasetRecordingsTableColumnPkeyUpdate>
  >;
};

/** The `datasetRecordingsTableColumnOrder` to be created by this mutation. */
export type DatasetRecordingsTTenantId43Dd9FaaFkTenantIdDatasetRecordingsTableColumnOrderCreateInput =
  {
    datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInput>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInput>;
    value: Scalars["Int"]["input"];
  };

/** Input for the nested mutation of `tenant` in the `DatasetRecordingsTableColumnOrderInput` mutation. */
export type DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `datasetRecordingsTableColumnOrder` in the `TenantInput` mutation. */
export type DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput = {
  /** The primary key(s) for `datasetRecordingsTableColumnOrder` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<DatasetRecordingsTableColumnOrderDatasetRecordingsTableColumnOrderPkeyConnect>
  >;
  /** A `DatasetRecordingsTableColumnOrderInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdDatasetRecordingsTableColumnOrderCreateInput>
  >;
  /** The primary key(s) for `datasetRecordingsTableColumnOrder` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<DatasetRecordingsTableColumnOrderDatasetRecordingsTableColumnOrderPkeyDelete>
  >;
  /** Flag indicating whether all other `datasetRecordingsTableColumnOrder` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `datasetRecordingsTableColumnOrder` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingDatasetRecordingsTableColumnOrderPkeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type DatasetRecordingsTTenantId43Dd9FaaFkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

export type DatasetRecordingsTable = {
  __typename: "DatasetRecordingsTable";
  /** Reads and enables pagination through a set of `DatasetRecordingsTableColumn`. */
  activeColumns: DatasetRecordingsTableColumnsConnection;
  /** Reads a single `Dataset` that is related to this `DatasetRecordingsTable`. */
  datasetByRecordingsTableId: Maybe<Dataset>;
  /** Reads and enables pagination through a set of `DatasetRecordingsTableColumn`. */
  datasetRecordingsTableColumnsByDatasetRecordingsTableId: DatasetRecordingsTableColumnsConnection;
  /**
   * Reads and enables pagination through a set of `Dataset`.
   * @deprecated Please use datasetByRecordingsTableId instead
   */
  datasetsByRecordingsTableId: DatasetsConnection;
  /** Reads and enables pagination through a set of `FileRecordingGroup`. */
  fileRecordingGroupsByDatasetRecordingsTableId: FileRecordingGroupsConnection;
  id: Scalars["UUID"]["output"];
  name: Scalars["String"]["output"];
  /** Reads a single `Project` that is related to this `DatasetRecordingsTable`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  /** Reads a single `Tenant` that is related to this `DatasetRecordingsTable`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  views: Maybe<Scalars["JSON"]["output"]>;
};

export type DatasetRecordingsTableActiveColumnsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  at?: InputMaybe<Scalars["Datetime"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DatasetRecordingsTableDatasetRecordingsTableColumnsByDatasetRecordingsTableIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<DatasetRecordingsTableColumnCondition>;
    filter?: InputMaybe<DatasetRecordingsTableColumnFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<DatasetRecordingsTableColumnsOrderBy>>;
  };

export type DatasetRecordingsTableDatasetsByRecordingsTableIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetCondition>;
  filter?: InputMaybe<DatasetFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetsOrderBy>>;
};

export type DatasetRecordingsTableFileRecordingGroupsByDatasetRecordingsTableIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<FileRecordingGroupCondition>;
    filter?: InputMaybe<FileRecordingGroupFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<FileRecordingGroupsOrderBy>>;
  };

export type DatasetRecordingsTableColumn = {
  __typename: "DatasetRecordingsTableColumn";
  activeColDef: Maybe<Scalars["JSON"]["output"]>;
  activeOrder: Maybe<Scalars["Int"]["output"]>;
  colDefId: Scalars["UUID"]["output"];
  /** Reads a single `DatasetRecordingsTable` that is related to this `DatasetRecordingsTableColumn`. */
  datasetRecordingsTableByDatasetRecordingsTableId: Maybe<DatasetRecordingsTable>;
  /** Reads and enables pagination through a set of `DatasetRecordingsTableColumnOrder`. */
  datasetRecordingsTableColumnOrdersByDatasetRecordingsTableColumnId: DatasetRecordingsTableColumnOrdersConnection;
  datasetRecordingsTableId: Scalars["UUID"]["output"];
  dateAssignmentEnded: Maybe<Scalars["Datetime"]["output"]>;
  dateCreated: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  identifierPosition: Maybe<Scalars["Int"]["output"]>;
  /** Reads a single `Metadatum` that is related to this `DatasetRecordingsTableColumn`. */
  metadatumByColDefId: Maybe<Metadatum>;
  pinned: Scalars["Boolean"]["output"];
  /** Reads a single `Project` that is related to this `DatasetRecordingsTableColumn`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  /** Reads and enables pagination through a set of `RecordingGroupFile`. */
  recordingGroupFilesByDatasetRecordingsTableColumnId: RecordingGroupFilesConnection;
  /** Reads a single `Tenant` that is related to this `DatasetRecordingsTableColumn`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  width: Scalars["Int"]["output"];
};

export type DatasetRecordingsTableColumnActiveColDefArgs = {
  at?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type DatasetRecordingsTableColumnActiveOrderArgs = {
  at?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type DatasetRecordingsTableColumnDatasetRecordingsTableColumnOrdersByDatasetRecordingsTableColumnIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<DatasetRecordingsTableColumnOrderCondition>;
    filter?: InputMaybe<DatasetRecordingsTableColumnOrderFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<DatasetRecordingsTableColumnOrdersOrderBy>>;
  };

export type DatasetRecordingsTableColumnRecordingGroupFilesByDatasetRecordingsTableColumnIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<RecordingGroupFileCondition>;
    filter?: InputMaybe<RecordingGroupFileFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<RecordingGroupFilesOrderBy>>;
  };

/**
 * A condition to be used against `DatasetRecordingsTableColumn` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type DatasetRecordingsTableColumnCondition = {
  /** Checks for equality with the object’s `colDefId` field. */
  colDefId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `datasetRecordingsTableId` field. */
  datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `dateAssignmentEnded` field. */
  dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `identifierPosition` field. */
  identifierPosition?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `pinned` field. */
  pinned?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `width` field. */
  width?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `datasetRecordingsTableColumn` to look up the row to connect. */
export type DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyConnect =
  {
    id: Scalars["UUID"]["input"];
  };

/** The fields on `datasetRecordingsTableColumn` to look up the row to delete. */
export type DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyDelete =
  {
    id: Scalars["UUID"]["input"];
  };

/** A filter to be used against `DatasetRecordingsTableColumn` object types. All fields are combined with a logical ‘and.’ */
export type DatasetRecordingsTableColumnFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DatasetRecordingsTableColumnFilter>>;
  /** Filter by the object’s `colDefId` field. */
  colDefId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `datasetRecordingsTableByDatasetRecordingsTableId` relation. */
  datasetRecordingsTableByDatasetRecordingsTableId?: InputMaybe<DatasetRecordingsTableFilter>;
  /** Filter by the object’s `datasetRecordingsTableColumnOrdersByDatasetRecordingsTableColumnId` relation. */
  datasetRecordingsTableColumnOrdersByDatasetRecordingsTableColumnId?: InputMaybe<DatasetRecordingsTableColumnToManyDatasetRecordingsTableColumnOrderFilter>;
  /** Some related `datasetRecordingsTableColumnOrdersByDatasetRecordingsTableColumnId` exist. */
  datasetRecordingsTableColumnOrdersByDatasetRecordingsTableColumnIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `datasetRecordingsTableId` field. */
  datasetRecordingsTableId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `dateAssignmentEnded` field. */
  dateAssignmentEnded?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `identifierPosition` field. */
  identifierPosition?: InputMaybe<IntFilter>;
  /** Filter by the object’s `metadatumByColDefId` relation. */
  metadatumByColDefId?: InputMaybe<MetadatumFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DatasetRecordingsTableColumnFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DatasetRecordingsTableColumnFilter>>;
  /** Filter by the object’s `pinned` field. */
  pinned?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `recordingGroupFilesByDatasetRecordingsTableColumnId` relation. */
  recordingGroupFilesByDatasetRecordingsTableColumnId?: InputMaybe<DatasetRecordingsTableColumnToManyRecordingGroupFileFilter>;
  /** Some related `recordingGroupFilesByDatasetRecordingsTableColumnId` exist. */
  recordingGroupFilesByDatasetRecordingsTableColumnIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `width` field. */
  width?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `DatasetRecordingsTableColumn` */
export type DatasetRecordingsTableColumnInput = {
  colDefId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput>;
  datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput>;
  dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id: Scalars["UUID"]["input"];
  identifierPosition?: InputMaybe<Scalars["Int"]["input"]>;
  metadatumToColDefId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInput>;
  pinned: Scalars["Boolean"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput>;
  width: Scalars["Int"]["input"];
};

/** The fields on `datasetRecordingsTableColumn` to look up the row to update. */
export type DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataUsingDatasetRecordingsTableColumnPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetRecordingsTableColumn` being updated. */
    datasetRecordingsTableColumnPatch: UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `datasetRecordingsTableColumn` to look up the row to update. */
export type DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRUsingDatasetRecordingsTableColumnPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetRecordingsTableColumn` being updated. */
    datasetRecordingsTableColumnPatch: UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `datasetRecordingsTableColumn` to look up the row to update. */
export type DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIUsingDatasetRecordingsTableColumnPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetRecordingsTableColumn` being updated. */
    datasetRecordingsTableColumnPatch: UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `datasetRecordingsTableColumn` to look up the row to update. */
export type DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingDatasetRecordingsTableColumnPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetRecordingsTableColumn` being updated. */
    datasetRecordingsTableColumnPatch: UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `datasetRecordingsTableColumn` to look up the row to update. */
export type DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRUsingDatasetRecordingsTableColumnPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetRecordingsTableColumn` being updated. */
    datasetRecordingsTableColumnPatch: UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `datasetRecordingsTableColumn` to look up the row to update. */
export type DatasetRecordingsTableColumnOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRUsingDatasetRecordingsTableColumnPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetRecordingsTableColumn` being updated. */
    datasetRecordingsTableColumnPatch: UpdateDatasetRecordingsTableColumnOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRPatch;
    id: Scalars["UUID"]["input"];
  };

export type DatasetRecordingsTableColumnOrder = {
  __typename: "DatasetRecordingsTableColumnOrder";
  /** Reads a single `DatasetRecordingsTableColumn` that is related to this `DatasetRecordingsTableColumnOrder`. */
  datasetRecordingsTableColumnByDatasetRecordingsTableColumnId: Maybe<DatasetRecordingsTableColumn>;
  datasetRecordingsTableColumnId: Scalars["UUID"]["output"];
  dateCreated: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads a single `Project` that is related to this `DatasetRecordingsTableColumnOrder`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  /** Reads a single `Tenant` that is related to this `DatasetRecordingsTableColumnOrder`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  value: Scalars["Int"]["output"];
};

/**
 * A condition to be used against `DatasetRecordingsTableColumnOrder` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type DatasetRecordingsTableColumnOrderCondition = {
  /** Checks for equality with the object’s `datasetRecordingsTableColumnId` field. */
  datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `datasetRecordingsTableColumnOrder` to look up the row to connect. */
export type DatasetRecordingsTableColumnOrderDatasetRecordingsTableColumnOrderPkeyConnect =
  {
    id: Scalars["UUID"]["input"];
  };

/** The fields on `datasetRecordingsTableColumnOrder` to look up the row to delete. */
export type DatasetRecordingsTableColumnOrderDatasetRecordingsTableColumnOrderPkeyDelete =
  {
    id: Scalars["UUID"]["input"];
  };

/** A filter to be used against `DatasetRecordingsTableColumnOrder` object types. All fields are combined with a logical ‘and.’ */
export type DatasetRecordingsTableColumnOrderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DatasetRecordingsTableColumnOrderFilter>>;
  /** Filter by the object’s `datasetRecordingsTableColumnByDatasetRecordingsTableColumnId` relation. */
  datasetRecordingsTableColumnByDatasetRecordingsTableColumnId?: InputMaybe<DatasetRecordingsTableColumnFilter>;
  /** Filter by the object’s `datasetRecordingsTableColumnId` field. */
  datasetRecordingsTableColumnId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DatasetRecordingsTableColumnOrderFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DatasetRecordingsTableColumnOrderFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `DatasetRecordingsTableColumnOrder` */
export type DatasetRecordingsTableColumnOrderInput = {
  datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id: Scalars["UUID"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInput>;
  value: Scalars["Int"]["input"];
};

/** The fields on `datasetRecordingsTableColumnOrder` to look up the row to update. */
export type DatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRUsingDatasetRecordingsTableColumnOrderPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetRecordingsTableColumnOrder` being updated. */
    datasetRecordingsTableColumnOrderPatch: UpdateDatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `datasetRecordingsTableColumnOrder` to look up the row to update. */
export type DatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIUsingDatasetRecordingsTableColumnOrderPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetRecordingsTableColumnOrder` being updated. */
    datasetRecordingsTableColumnOrderPatch: UpdateDatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `datasetRecordingsTableColumnOrder` to look up the row to update. */
export type DatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingDatasetRecordingsTableColumnOrderPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetRecordingsTableColumnOrder` being updated. */
    datasetRecordingsTableColumnOrderPatch: UpdateDatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** Represents an update to a `DatasetRecordingsTableColumnOrder`. Fields that are set will be updated. */
export type DatasetRecordingsTableColumnOrderPatch = {
  datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInput>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A connection to a list of `DatasetRecordingsTableColumnOrder` values. */
export type DatasetRecordingsTableColumnOrdersConnection = {
  __typename: "DatasetRecordingsTableColumnOrdersConnection";
  /** A list of edges which contains the `DatasetRecordingsTableColumnOrder` and cursor to aid in pagination. */
  edges: Array<DatasetRecordingsTableColumnOrdersEdge>;
  /** A list of `DatasetRecordingsTableColumnOrder` objects. */
  nodes: Array<DatasetRecordingsTableColumnOrder>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DatasetRecordingsTableColumnOrder` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DatasetRecordingsTableColumnOrder` edge in the connection. */
export type DatasetRecordingsTableColumnOrdersEdge = {
  __typename: "DatasetRecordingsTableColumnOrdersEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DatasetRecordingsTableColumnOrder` at the end of the edge. */
  node: DatasetRecordingsTableColumnOrder;
};

/** Methods to use when ordering `DatasetRecordingsTableColumnOrder`. */
export enum DatasetRecordingsTableColumnOrdersOrderBy {
  DatasetRecordingsTableColumnIdAsc = "DATASET_RECORDINGS_TABLE_COLUMN_ID_ASC",
  DatasetRecordingsTableColumnIdDesc = "DATASET_RECORDINGS_TABLE_COLUMN_ID_DESC",
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  ValueAsc = "VALUE_ASC",
  ValueDesc = "VALUE_DESC",
}

/** Represents an update to a `DatasetRecordingsTableColumn`. Fields that are set will be updated. */
export type DatasetRecordingsTableColumnPatch = {
  colDefId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput>;
  datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput>;
  dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  identifierPosition?: InputMaybe<Scalars["Int"]["input"]>;
  metadatumToColDefId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInput>;
  pinned?: InputMaybe<Scalars["Boolean"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput>;
  width?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The `datasetRecordingsTableColumn` to be created by this mutation. */
export type DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdDatasetRecordingsTableColumnCreateInput =
  {
    colDefId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    identifierPosition?: InputMaybe<Scalars["Int"]["input"]>;
    metadatumToColDefId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInput>;
    pinned: Scalars["Boolean"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput>;
    width: Scalars["Int"]["input"];
  };

/** Input for the nested mutation of `tenant` in the `DatasetRecordingsTableColumnInput` mutation. */
export type DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `datasetRecordingsTableColumn` in the `TenantInput` mutation. */
export type DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput =
  {
    /** The primary key(s) for `datasetRecordingsTableColumn` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyConnect>
    >;
    /** A `DatasetRecordingsTableColumnInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdDatasetRecordingsTableColumnCreateInput>
    >;
    /** The primary key(s) for `datasetRecordingsTableColumn` for the far side of the relationship. */
    deleteById?: InputMaybe<
      Array<DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyDelete>
    >;
    /** Flag indicating whether all other `datasetRecordingsTableColumn` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `datasetRecordingsTableColumn` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingDatasetRecordingsTableColumnPkeyUpdate>
    >;
  };

/** The `tenant` to be created by this mutation. */
export type DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdTenantCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal: Scalars["Boolean"]["input"];
    key: Scalars["String"]["input"];
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name: Scalars["String"]["input"];
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status: Scalars["Int"]["input"];
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType: Scalars["Int"]["input"];
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** A filter to be used against many `DatasetRecordingsTableColumnOrder` object types. All fields are combined with a logical ‘and.’ */
export type DatasetRecordingsTableColumnToManyDatasetRecordingsTableColumnOrderFilter =
  {
    /** Every related `DatasetRecordingsTableColumnOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
    every?: InputMaybe<DatasetRecordingsTableColumnOrderFilter>;
    /** No related `DatasetRecordingsTableColumnOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
    none?: InputMaybe<DatasetRecordingsTableColumnOrderFilter>;
    /** Some related `DatasetRecordingsTableColumnOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
    some?: InputMaybe<DatasetRecordingsTableColumnOrderFilter>;
  };

/** A filter to be used against many `RecordingGroupFile` object types. All fields are combined with a logical ‘and.’ */
export type DatasetRecordingsTableColumnToManyRecordingGroupFileFilter = {
  /** Every related `RecordingGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<RecordingGroupFileFilter>;
  /** No related `RecordingGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<RecordingGroupFileFilter>;
  /** Some related `RecordingGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<RecordingGroupFileFilter>;
};

/** A connection to a list of `DatasetRecordingsTableColumn` values. */
export type DatasetRecordingsTableColumnsConnection = {
  __typename: "DatasetRecordingsTableColumnsConnection";
  /** A list of edges which contains the `DatasetRecordingsTableColumn` and cursor to aid in pagination. */
  edges: Array<DatasetRecordingsTableColumnsEdge>;
  /** A list of `DatasetRecordingsTableColumn` objects. */
  nodes: Array<DatasetRecordingsTableColumn>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DatasetRecordingsTableColumn` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DatasetRecordingsTableColumn` edge in the connection. */
export type DatasetRecordingsTableColumnsEdge = {
  __typename: "DatasetRecordingsTableColumnsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DatasetRecordingsTableColumn` at the end of the edge. */
  node: DatasetRecordingsTableColumn;
};

/** Methods to use when ordering `DatasetRecordingsTableColumn`. */
export enum DatasetRecordingsTableColumnsOrderBy {
  ColDefIdAsc = "COL_DEF_ID_ASC",
  ColDefIdDesc = "COL_DEF_ID_DESC",
  DatasetRecordingsTableIdAsc = "DATASET_RECORDINGS_TABLE_ID_ASC",
  DatasetRecordingsTableIdDesc = "DATASET_RECORDINGS_TABLE_ID_DESC",
  DateAssignmentEndedAsc = "DATE_ASSIGNMENT_ENDED_ASC",
  DateAssignmentEndedDesc = "DATE_ASSIGNMENT_ENDED_DESC",
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  IdentifierPositionAsc = "IDENTIFIER_POSITION_ASC",
  IdentifierPositionDesc = "IDENTIFIER_POSITION_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PinnedAsc = "PINNED_ASC",
  PinnedDesc = "PINNED_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  WidthAsc = "WIDTH_ASC",
  WidthDesc = "WIDTH_DESC",
}

/**
 * A condition to be used against `DatasetRecordingsTable` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type DatasetRecordingsTableCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `views` field. */
  views?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** The fields on `datasetRecordingsTable` to look up the row to connect. */
export type DatasetRecordingsTableDatasetRecordingsTablePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `datasetRecordingsTable` to look up the row to delete. */
export type DatasetRecordingsTableDatasetRecordingsTablePkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** A filter to be used against `DatasetRecordingsTable` object types. All fields are combined with a logical ‘and.’ */
export type DatasetRecordingsTableFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DatasetRecordingsTableFilter>>;
  /** Filter by the object’s `datasetByRecordingsTableId` relation. */
  datasetByRecordingsTableId?: InputMaybe<DatasetFilter>;
  /** A related `datasetByRecordingsTableId` exists. */
  datasetByRecordingsTableIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `datasetRecordingsTableColumnsByDatasetRecordingsTableId` relation. */
  datasetRecordingsTableColumnsByDatasetRecordingsTableId?: InputMaybe<DatasetRecordingsTableToManyDatasetRecordingsTableColumnFilter>;
  /** Some related `datasetRecordingsTableColumnsByDatasetRecordingsTableId` exist. */
  datasetRecordingsTableColumnsByDatasetRecordingsTableIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `fileRecordingGroupsByDatasetRecordingsTableId` relation. */
  fileRecordingGroupsByDatasetRecordingsTableId?: InputMaybe<DatasetRecordingsTableToManyFileRecordingGroupFilter>;
  /** Some related `fileRecordingGroupsByDatasetRecordingsTableId` exist. */
  fileRecordingGroupsByDatasetRecordingsTableIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DatasetRecordingsTableFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DatasetRecordingsTableFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `views` field. */
  views?: InputMaybe<JsonFilter>;
};

/** An input for mutations affecting `DatasetRecordingsTable` */
export type DatasetRecordingsTableInput = {
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInverseInput>;
  datasetUsingId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInverseInput>;
  id: Scalars["UUID"]["input"];
  name: Scalars["String"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInput>;
  views?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** The fields on `datasetRecordingsTable` to look up the row to update. */
export type DatasetRecordingsTableOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetRecordingsTablePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetRecordingsTable` being updated. */
    datasetRecordingsTablePatch: UpdateDatasetRecordingsTableOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `datasetRecordingsTable` to look up the row to update. */
export type DatasetRecordingsTableOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRUsingDatasetRecordingsTablePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetRecordingsTable` being updated. */
    datasetRecordingsTablePatch: UpdateDatasetRecordingsTableOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `datasetRecordingsTable` to look up the row to update. */
export type DatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdUsingDatasetRecordingsTablePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetRecordingsTable` being updated. */
    datasetRecordingsTablePatch: UpdateDatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `datasetRecordingsTable` to look up the row to update. */
export type DatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingDatasetRecordingsTablePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetRecordingsTable` being updated. */
    datasetRecordingsTablePatch: UpdateDatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `datasetRecordingsTable` to look up the row to update. */
export type DatasetRecordingsTableOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRUsingDatasetRecordingsTablePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetRecordingsTable` being updated. */
    datasetRecordingsTablePatch: UpdateDatasetRecordingsTableOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRPatch;
    id: Scalars["UUID"]["input"];
  };

/** Represents an update to a `DatasetRecordingsTable`. Fields that are set will be updated. */
export type DatasetRecordingsTablePatch = {
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInverseInput>;
  datasetUsingId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInput>;
  views?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** The `datasetRecordingsTable` to be created by this mutation. */
export type DatasetRecordingsTableProjectId710D9Eb2FkProjectIdDatasetRecordingsTableCreateInput =
  {
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInverseInput>;
    datasetUsingId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInverseInput>;
    id: Scalars["UUID"]["input"];
    name: Scalars["String"]["input"];
    projectToProjectId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInput>;
    views?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** Input for the nested mutation of `project` in the `DatasetRecordingsTableInput` mutation. */
export type DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `datasetRecordingsTable` in the `ProjectInput` mutation. */
export type DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput = {
  /** The primary key(s) for `datasetRecordingsTable` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<DatasetRecordingsTableDatasetRecordingsTablePkeyConnect>
  >;
  /** A `DatasetRecordingsTableInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdDatasetRecordingsTableCreateInput>
  >;
  /** The primary key(s) for `datasetRecordingsTable` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<DatasetRecordingsTableDatasetRecordingsTablePkeyDelete>
  >;
  /** Flag indicating whether all other `datasetRecordingsTable` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `datasetRecordingsTable` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdUsingDatasetRecordingsTablePkeyUpdate>
  >;
};

/** The `datasetRecordingsTable` to be created by this mutation. */
export type DatasetRecordingsTableTenantId94Ac7342FkTenantIdDatasetRecordingsTableCreateInput =
  {
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInverseInput>;
    datasetUsingId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInverseInput>;
    id: Scalars["UUID"]["input"];
    name: Scalars["String"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInput>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInput>;
    views?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** Input for the nested mutation of `tenant` in the `DatasetRecordingsTableInput` mutation. */
export type DatasetRecordingsTableTenantId94Ac7342FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `datasetRecordingsTable` in the `TenantInput` mutation. */
export type DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput = {
  /** The primary key(s) for `datasetRecordingsTable` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<DatasetRecordingsTableDatasetRecordingsTablePkeyConnect>
  >;
  /** A `DatasetRecordingsTableInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DatasetRecordingsTableTenantId94Ac7342FkTenantIdDatasetRecordingsTableCreateInput>
  >;
  /** The primary key(s) for `datasetRecordingsTable` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<DatasetRecordingsTableDatasetRecordingsTablePkeyDelete>
  >;
  /** Flag indicating whether all other `datasetRecordingsTable` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `datasetRecordingsTable` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingDatasetRecordingsTablePkeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type DatasetRecordingsTableTenantId94Ac7342FkTenantIdTenantCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal: Scalars["Boolean"]["input"];
    key: Scalars["String"]["input"];
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name: Scalars["String"]["input"];
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status: Scalars["Int"]["input"];
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType: Scalars["Int"]["input"];
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** A filter to be used against many `DatasetRecordingsTableColumn` object types. All fields are combined with a logical ‘and.’ */
export type DatasetRecordingsTableToManyDatasetRecordingsTableColumnFilter = {
  /** Every related `DatasetRecordingsTableColumn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetRecordingsTableColumnFilter>;
  /** No related `DatasetRecordingsTableColumn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetRecordingsTableColumnFilter>;
  /** Some related `DatasetRecordingsTableColumn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetRecordingsTableColumnFilter>;
};

/** A filter to be used against many `FileRecordingGroup` object types. All fields are combined with a logical ‘and.’ */
export type DatasetRecordingsTableToManyFileRecordingGroupFilter = {
  /** Every related `FileRecordingGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileRecordingGroupFilter>;
  /** No related `FileRecordingGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileRecordingGroupFilter>;
  /** Some related `FileRecordingGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileRecordingGroupFilter>;
};

/** A connection to a list of `DatasetRecordingsTable` values. */
export type DatasetRecordingsTablesConnection = {
  __typename: "DatasetRecordingsTablesConnection";
  /** A list of edges which contains the `DatasetRecordingsTable` and cursor to aid in pagination. */
  edges: Array<DatasetRecordingsTablesEdge>;
  /** A list of `DatasetRecordingsTable` objects. */
  nodes: Array<DatasetRecordingsTable>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DatasetRecordingsTable` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DatasetRecordingsTable` edge in the connection. */
export type DatasetRecordingsTablesEdge = {
  __typename: "DatasetRecordingsTablesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DatasetRecordingsTable` at the end of the edge. */
  node: DatasetRecordingsTable;
};

/** Methods to use when ordering `DatasetRecordingsTable`. */
export enum DatasetRecordingsTablesOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  ViewsAsc = "VIEWS_ASC",
  ViewsDesc = "VIEWS_DESC",
}

/** A filter to be used against many `DatasetMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type DatasetToManyDatasetMetadatumFilter = {
  /** Every related `DatasetMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetMetadatumFilter>;
  /** No related `DatasetMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetMetadatumFilter>;
  /** Some related `DatasetMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetMetadatumFilter>;
};

/** A filter to be used against many `DatasetVersion` object types. All fields are combined with a logical ‘and.’ */
export type DatasetToManyDatasetVersionFilter = {
  /** Every related `DatasetVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetVersionFilter>;
  /** No related `DatasetVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetVersionFilter>;
  /** Some related `DatasetVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetVersionFilter>;
};

/** A filter to be used against many `File` object types. All fields are combined with a logical ‘and.’ */
export type DatasetToManyFileFilter = {
  /** Every related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileFilter>;
  /** No related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileFilter>;
  /** Some related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileFilter>;
};

/** A filter to be used against many `Task` object types. All fields are combined with a logical ‘and.’ */
export type DatasetToManyTaskFilter = {
  /** Every related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TaskFilter>;
  /** No related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TaskFilter>;
  /** Some related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TaskFilter>;
};

/** The `dataset` to be created by this mutation. */
export type DatasetV4ProjectId98907276FkProjectIdDatasetCreateInput = {
  applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
  datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
  filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
  id: Scalars["UUID"]["input"];
  key: Scalars["String"]["input"];
  metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
  name: Scalars["String"]["input"];
  prefix: Scalars["String"]["input"];
  projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
  recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  status: Scalars["Int"]["input"];
  tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `project` in the `DatasetInput` mutation. */
export type DatasetV4ProjectId98907276FkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `dataset` in the `ProjectInput` mutation. */
export type DatasetV4ProjectId98907276FkProjectIdInverseInput = {
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DatasetDatasetV4PkeyConnect>>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByProjectIdAndPrefix?: InputMaybe<
    Array<DatasetDatasetPrefixProjectUniqueConnect>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByRecordingsTableId?: InputMaybe<
    Array<DatasetDatasetV4RecordingsTableIdKeyConnect>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByTenantIdAndKey?: InputMaybe<
    Array<DatasetDatasetKeyTenantUniqueConnect>
  >;
  /** A `DatasetInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DatasetV4ProjectId98907276FkProjectIdDatasetCreateInput>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DatasetDatasetV4PkeyDelete>>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByProjectIdAndPrefix?: InputMaybe<
    Array<DatasetDatasetPrefixProjectUniqueDelete>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByRecordingsTableId?: InputMaybe<
    Array<DatasetDatasetV4RecordingsTableIdKeyDelete>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByTenantIdAndKey?: InputMaybe<
    Array<DatasetDatasetKeyTenantUniqueDelete>
  >;
  /** Flag indicating whether all other `dataset` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetV4PkeyUpdate>
  >;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByProjectIdAndPrefix?: InputMaybe<
    Array<DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetPrefixProjectUniqueUpdate>
  >;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByRecordingsTableId?: InputMaybe<
    Array<DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetV4RecordingsTableIdKeyUpdate>
  >;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByTenantIdAndKey?: InputMaybe<
    Array<DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetKeyTenantUniqueUpdate>
  >;
};

/** The `dataset` to be created by this mutation. */
export type DatasetV4RecordingsTableIdD72E44AeFkDatasetRDatasetCreateInput = {
  applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
  datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
  filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
  id: Scalars["UUID"]["input"];
  key: Scalars["String"]["input"];
  metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
  name: Scalars["String"]["input"];
  prefix: Scalars["String"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
  status: Scalars["Int"]["input"];
  tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `datasetRecordingsTable` to be created by this mutation. */
export type DatasetV4RecordingsTableIdD72E44AeFkDatasetRDatasetRecordingsTableCreateInput =
  {
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInverseInput>;
    datasetUsingId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInverseInput>;
    id: Scalars["UUID"]["input"];
    name: Scalars["String"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInput>;
    views?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** Input for the nested mutation of `datasetRecordingsTable` in the `DatasetInput` mutation. */
export type DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput = {
  /** The primary key(s) for `datasetRecordingsTable` for the far side of the relationship. */
  connectById?: InputMaybe<DatasetRecordingsTableDatasetRecordingsTablePkeyConnect>;
  /** A `DatasetRecordingsTableInput` object that will be created and connected to this object. */
  create?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRDatasetRecordingsTableCreateInput>;
  /** The primary key(s) for `datasetRecordingsTable` for the far side of the relationship. */
  deleteById?: InputMaybe<DatasetRecordingsTableDatasetRecordingsTablePkeyDelete>;
  /** The primary key(s) and patch data for `datasetRecordingsTable` for the far side of the relationship. */
  updateById?: InputMaybe<DatasetRecordingsTableOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetRecordingsTablePkeyUpdate>;
};

/** Input for the nested mutation of `dataset` in the `DatasetRecordingsTableInput` mutation. */
export type DatasetV4RecordingsTableIdD72E44AeFkDatasetRInverseInput = {
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectById?: InputMaybe<DatasetDatasetV4PkeyConnect>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByProjectIdAndPrefix?: InputMaybe<DatasetDatasetPrefixProjectUniqueConnect>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByRecordingsTableId?: InputMaybe<DatasetDatasetV4RecordingsTableIdKeyConnect>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByTenantIdAndKey?: InputMaybe<DatasetDatasetKeyTenantUniqueConnect>;
  /** A `DatasetInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DatasetV4RecordingsTableIdD72E44AeFkDatasetRDatasetCreateInput>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteById?: InputMaybe<DatasetDatasetV4PkeyDelete>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByProjectIdAndPrefix?: InputMaybe<DatasetDatasetPrefixProjectUniqueDelete>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByRecordingsTableId?: InputMaybe<DatasetDatasetV4RecordingsTableIdKeyDelete>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByTenantIdAndKey?: InputMaybe<DatasetDatasetKeyTenantUniqueDelete>;
  /** Flag indicating whether all other `dataset` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateById?: InputMaybe<DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetV4PkeyUpdate>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByProjectIdAndPrefix?: InputMaybe<DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetPrefixProjectUniqueUpdate>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByRecordingsTableId?: InputMaybe<DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetV4RecordingsTableIdKeyUpdate>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByTenantIdAndKey?: InputMaybe<DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetKeyTenantUniqueUpdate>;
};

/** The `dataset` to be created by this mutation. */
export type DatasetV4TenantId7055D1F4FkTenantIdDatasetCreateInput = {
  applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
  datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
  filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
  id: Scalars["UUID"]["input"];
  key: Scalars["String"]["input"];
  metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
  name: Scalars["String"]["input"];
  prefix: Scalars["String"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
  recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  status: Scalars["Int"]["input"];
  tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
  tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `tenant` in the `DatasetInput` mutation. */
export type DatasetV4TenantId7055D1F4FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `dataset` in the `TenantInput` mutation. */
export type DatasetV4TenantId7055D1F4FkTenantIdInverseInput = {
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DatasetDatasetV4PkeyConnect>>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByProjectIdAndPrefix?: InputMaybe<
    Array<DatasetDatasetPrefixProjectUniqueConnect>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByRecordingsTableId?: InputMaybe<
    Array<DatasetDatasetV4RecordingsTableIdKeyConnect>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByTenantIdAndKey?: InputMaybe<
    Array<DatasetDatasetKeyTenantUniqueConnect>
  >;
  /** A `DatasetInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DatasetV4TenantId7055D1F4FkTenantIdDatasetCreateInput>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DatasetDatasetV4PkeyDelete>>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByProjectIdAndPrefix?: InputMaybe<
    Array<DatasetDatasetPrefixProjectUniqueDelete>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByRecordingsTableId?: InputMaybe<
    Array<DatasetDatasetV4RecordingsTableIdKeyDelete>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByTenantIdAndKey?: InputMaybe<
    Array<DatasetDatasetKeyTenantUniqueDelete>
  >;
  /** Flag indicating whether all other `dataset` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetV4PkeyUpdate>
  >;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByProjectIdAndPrefix?: InputMaybe<
    Array<DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetPrefixProjectUniqueUpdate>
  >;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByRecordingsTableId?: InputMaybe<
    Array<DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetV4RecordingsTableIdKeyUpdate>
  >;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByTenantIdAndKey?: InputMaybe<
    Array<DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetKeyTenantUniqueUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type DatasetV4TenantId7055D1F4FkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** The `applicationUser` to be created by this mutation. */
export type DatasetV4UserId5B9A2303FkApplicationUserIdApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** The `dataset` to be created by this mutation. */
export type DatasetV4UserId5B9A2303FkApplicationUserIdDatasetCreateInput = {
  applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
  datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
  filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
  id: Scalars["UUID"]["input"];
  key: Scalars["String"]["input"];
  metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
  name: Scalars["String"]["input"];
  prefix: Scalars["String"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
  recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  status: Scalars["Int"]["input"];
  tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
};

/** Input for the nested mutation of `applicationUser` in the `DatasetInput` mutation. */
export type DatasetV4UserId5B9A2303FkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `dataset` in the `ApplicationUserInput` mutation. */
export type DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput = {
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DatasetDatasetV4PkeyConnect>>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByProjectIdAndPrefix?: InputMaybe<
    Array<DatasetDatasetPrefixProjectUniqueConnect>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByRecordingsTableId?: InputMaybe<
    Array<DatasetDatasetV4RecordingsTableIdKeyConnect>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByTenantIdAndKey?: InputMaybe<
    Array<DatasetDatasetKeyTenantUniqueConnect>
  >;
  /** A `DatasetInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DatasetV4UserId5B9A2303FkApplicationUserIdDatasetCreateInput>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DatasetDatasetV4PkeyDelete>>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByProjectIdAndPrefix?: InputMaybe<
    Array<DatasetDatasetPrefixProjectUniqueDelete>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByRecordingsTableId?: InputMaybe<
    Array<DatasetDatasetV4RecordingsTableIdKeyDelete>
  >;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByTenantIdAndKey?: InputMaybe<
    Array<DatasetDatasetKeyTenantUniqueDelete>
  >;
  /** Flag indicating whether all other `dataset` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetV4PkeyUpdate>
  >;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByProjectIdAndPrefix?: InputMaybe<
    Array<DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetPrefixProjectUniqueUpdate>
  >;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByRecordingsTableId?: InputMaybe<
    Array<DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetV4RecordingsTableIdKeyUpdate>
  >;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByTenantIdAndKey?: InputMaybe<
    Array<DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetKeyTenantUniqueUpdate>
  >;
};

export type DatasetVersion = {
  __typename: "DatasetVersion";
  /** Reads a single `Dataset` that is related to this `DatasetVersion`. */
  datasetByDatasetId: Maybe<Dataset>;
  datasetId: Scalars["UUID"]["output"];
  dateCreated: Scalars["Datetime"]["output"];
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["UUID"]["output"];
  name: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Project` that is related to this `DatasetVersion`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  /** Reads a single `Tenant` that is related to this `DatasetVersion`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  validationState: Scalars["JSON"]["output"];
  views: Maybe<Scalars["JSON"]["output"]>;
};

/**
 * A condition to be used against `DatasetVersion` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DatasetVersionCondition = {
  /** Checks for equality with the object’s `datasetId` field. */
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `validationState` field. */
  validationState?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `views` field. */
  views?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** The `dataset` to be created by this mutation. */
export type DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdDatasetCreateInput = {
  applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
  datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
  filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
  id: Scalars["UUID"]["input"];
  key: Scalars["String"]["input"];
  metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
  name: Scalars["String"]["input"];
  prefix: Scalars["String"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
  recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  status: Scalars["Int"]["input"];
  tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `datasetVersion` to be created by this mutation. */
export type DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdDatasetVersionCreateInput =
  {
    datasetToDatasetId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInput>;
    validationState: Scalars["JSON"]["input"];
    views?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** Input for the nested mutation of `dataset` in the `DatasetVersionInput` mutation. */
export type DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInput = {
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectById?: InputMaybe<DatasetDatasetV4PkeyConnect>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByProjectIdAndPrefix?: InputMaybe<DatasetDatasetPrefixProjectUniqueConnect>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByRecordingsTableId?: InputMaybe<DatasetDatasetV4RecordingsTableIdKeyConnect>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByTenantIdAndKey?: InputMaybe<DatasetDatasetKeyTenantUniqueConnect>;
  /** A `DatasetInput` object that will be created and connected to this object. */
  create?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdDatasetCreateInput>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteById?: InputMaybe<DatasetDatasetV4PkeyDelete>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByProjectIdAndPrefix?: InputMaybe<DatasetDatasetPrefixProjectUniqueDelete>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByRecordingsTableId?: InputMaybe<DatasetDatasetV4RecordingsTableIdKeyDelete>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByTenantIdAndKey?: InputMaybe<DatasetDatasetKeyTenantUniqueDelete>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateById?: InputMaybe<DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetV4PkeyUpdate>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByProjectIdAndPrefix?: InputMaybe<DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetPrefixProjectUniqueUpdate>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByRecordingsTableId?: InputMaybe<DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetV4RecordingsTableIdKeyUpdate>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByTenantIdAndKey?: InputMaybe<DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `datasetVersion` in the `DatasetInput` mutation. */
export type DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput = {
  /** The primary key(s) for `datasetVersion` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DatasetVersionDatasetVersionPkeyConnect>>;
  /** A `DatasetVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdDatasetVersionCreateInput>
  >;
  /** The primary key(s) for `datasetVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DatasetVersionDatasetVersionPkeyDelete>>;
  /** Flag indicating whether all other `datasetVersion` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `datasetVersion` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetVersionOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetVersionPkeyUpdate>
  >;
};

/** The fields on `datasetVersion` to look up the row to connect. */
export type DatasetVersionDatasetVersionPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `datasetVersion` to look up the row to delete. */
export type DatasetVersionDatasetVersionPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** A filter to be used against `DatasetVersion` object types. All fields are combined with a logical ‘and.’ */
export type DatasetVersionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DatasetVersionFilter>>;
  /** Filter by the object’s `datasetByDatasetId` relation. */
  datasetByDatasetId?: InputMaybe<DatasetFilter>;
  /** Filter by the object’s `datasetId` field. */
  datasetId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DatasetVersionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DatasetVersionFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `validationState` field. */
  validationState?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `views` field. */
  views?: InputMaybe<JsonFilter>;
};

/** An input for mutations affecting `DatasetVersion` */
export type DatasetVersionInput = {
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInput>;
  validationState: Scalars["JSON"]["input"];
  views?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** The fields on `datasetVersion` to look up the row to update. */
export type DatasetVersionOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetVersionPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetVersion` being updated. */
    datasetVersionPatch: UpdateDatasetVersionOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `datasetVersion` to look up the row to update. */
export type DatasetVersionOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdUsingDatasetVersionPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetVersion` being updated. */
    datasetVersionPatch: UpdateDatasetVersionOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `datasetVersion` to look up the row to update. */
export type DatasetVersionOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingDatasetVersionPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `datasetVersion` being updated. */
    datasetVersionPatch: UpdateDatasetVersionOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** Represents an update to a `DatasetVersion`. Fields that are set will be updated. */
export type DatasetVersionPatch = {
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInput>;
  validationState?: InputMaybe<Scalars["JSON"]["input"]>;
  views?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** The `datasetVersion` to be created by this mutation. */
export type DatasetVersionProjectId9E468C2EFkProjectIdDatasetVersionCreateInput =
  {
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInput>;
    validationState: Scalars["JSON"]["input"];
    views?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** Input for the nested mutation of `project` in the `DatasetVersionInput` mutation. */
export type DatasetVersionProjectId9E468C2EFkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `datasetVersion` in the `ProjectInput` mutation. */
export type DatasetVersionProjectId9E468C2EFkProjectIdInverseInput = {
  /** The primary key(s) for `datasetVersion` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DatasetVersionDatasetVersionPkeyConnect>>;
  /** A `DatasetVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DatasetVersionProjectId9E468C2EFkProjectIdDatasetVersionCreateInput>
  >;
  /** The primary key(s) for `datasetVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DatasetVersionDatasetVersionPkeyDelete>>;
  /** Flag indicating whether all other `datasetVersion` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `datasetVersion` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetVersionOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdUsingDatasetVersionPkeyUpdate>
  >;
};

/** The `datasetVersion` to be created by this mutation. */
export type DatasetVersionTenantId7Eb1908AFkTenantIdDatasetVersionCreateInput =
  {
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInput>;
    tenantToTenantId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInput>;
    validationState: Scalars["JSON"]["input"];
    views?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** Input for the nested mutation of `tenant` in the `DatasetVersionInput` mutation. */
export type DatasetVersionTenantId7Eb1908AFkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `datasetVersion` in the `TenantInput` mutation. */
export type DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput = {
  /** The primary key(s) for `datasetVersion` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DatasetVersionDatasetVersionPkeyConnect>>;
  /** A `DatasetVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DatasetVersionTenantId7Eb1908AFkTenantIdDatasetVersionCreateInput>
  >;
  /** The primary key(s) for `datasetVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DatasetVersionDatasetVersionPkeyDelete>>;
  /** Flag indicating whether all other `datasetVersion` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `datasetVersion` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetVersionOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingDatasetVersionPkeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type DatasetVersionTenantId7Eb1908AFkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** A connection to a list of `DatasetVersion` values. */
export type DatasetVersionsConnection = {
  __typename: "DatasetVersionsConnection";
  /** A list of edges which contains the `DatasetVersion` and cursor to aid in pagination. */
  edges: Array<DatasetVersionsEdge>;
  /** A list of `DatasetVersion` objects. */
  nodes: Array<DatasetVersion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DatasetVersion` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DatasetVersion` edge in the connection. */
export type DatasetVersionsEdge = {
  __typename: "DatasetVersionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DatasetVersion` at the end of the edge. */
  node: DatasetVersion;
};

/** Methods to use when ordering `DatasetVersion`. */
export enum DatasetVersionsOrderBy {
  DatasetIdAsc = "DATASET_ID_ASC",
  DatasetIdDesc = "DATASET_ID_DESC",
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  ValidationStateAsc = "VALIDATION_STATE_ASC",
  ValidationStateDesc = "VALIDATION_STATE_DESC",
  ViewsAsc = "VIEWS_ASC",
  ViewsDesc = "VIEWS_DESC",
}

/** A connection to a list of `Dataset` values. */
export type DatasetsConnection = {
  __typename: "DatasetsConnection";
  /** A list of edges which contains the `Dataset` and cursor to aid in pagination. */
  edges: Array<DatasetsEdge>;
  /** A list of `Dataset` objects. */
  nodes: Array<Dataset>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Dataset` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Dataset` edge in the connection. */
export type DatasetsEdge = {
  __typename: "DatasetsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Dataset` at the end of the edge. */
  node: Dataset;
};

/** Methods to use when ordering `Dataset`. */
export enum DatasetsOrderBy {
  DateArchivedAsc = "DATE_ARCHIVED_ASC",
  DateArchivedDesc = "DATE_ARCHIVED_DESC",
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  DateDataDeletedAsc = "DATE_DATA_DELETED_ASC",
  DateDataDeletedDesc = "DATE_DATA_DELETED_DESC",
  DateDeletedAsc = "DATE_DELETED_ASC",
  DateDeletedDesc = "DATE_DELETED_DESC",
  DescriptionIdAsc = "DESCRIPTION_ID_ASC",
  DescriptionIdDesc = "DESCRIPTION_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  KeyAsc = "KEY_ASC",
  KeyDesc = "KEY_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrefixAsc = "PREFIX_ASC",
  PrefixDesc = "PREFIX_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  RecordingsTableIdAsc = "RECORDINGS_TABLE_ID_ASC",
  RecordingsTableIdDesc = "RECORDINGS_TABLE_ID_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Date"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Date"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Date"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Date"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Date"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Date"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Date"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Date"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Date"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Date"]["input"]>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Datetime"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Datetime"]["input"]>>;
};

/** All input for the `deleteAnalysisTableById` mutation. */
export type DeleteAnalysisTableByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `deleteAnalysisTableGroupById` mutation. */
export type DeleteAnalysisTableGroupByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** The output of our delete `AnalysisTableGroup` mutation. */
export type DeleteAnalysisTableGroupPayload = {
  __typename: "DeleteAnalysisTableGroupPayload";
  /** The `AnalysisTableGroup` that was deleted by this mutation. */
  analysisTableGroup: Maybe<AnalysisTableGroup>;
  /** An edge for our `AnalysisTableGroup`. May be used by Relay 1. */
  analysisTableGroupEdge: Maybe<AnalysisTableGroupsEdge>;
  /** Reads a single `ApplicationUser` that is related to this `AnalysisTableGroup`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedAnalysisTableGroupId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `Project` that is related to this `AnalysisTableGroup`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `AnalysisTableGroup`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `Tool` that is related to this `AnalysisTableGroup`. */
  toolByToolId: Maybe<Tool>;
};

/** The output of our delete `AnalysisTableGroup` mutation. */
export type DeleteAnalysisTableGroupPayloadAnalysisTableGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<AnalysisTableGroupsOrderBy>>;
};

/** The output of our delete `AnalysisTable` mutation. */
export type DeleteAnalysisTablePayload = {
  __typename: "DeleteAnalysisTablePayload";
  /** The `AnalysisTable` that was deleted by this mutation. */
  analysisTable: Maybe<AnalysisTable>;
  /** An edge for our `AnalysisTable`. May be used by Relay 1. */
  analysisTableEdge: Maybe<AnalysisTablesEdge>;
  /** Reads a single `AnalysisTableGroup` that is related to this `AnalysisTable`. */
  analysisTableGroupByGroupId: Maybe<AnalysisTableGroup>;
  /** Reads a single `ApplicationUser` that is related to this `AnalysisTable`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedAnalysisTableId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `Project` that is related to this `AnalysisTable`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `AnalysisTable`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `ToolVersion` that is related to this `AnalysisTable`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our delete `AnalysisTable` mutation. */
export type DeleteAnalysisTablePayloadAnalysisTableEdgeArgs = {
  orderBy?: InputMaybe<Array<AnalysisTablesOrderBy>>;
};

/** All input for the `deleteAnalysisTableRowAttachResultById` mutation. */
export type DeleteAnalysisTableRowAttachResultByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** The output of our delete `AnalysisTableRowAttachResult` mutation. */
export type DeleteAnalysisTableRowAttachResultPayload = {
  __typename: "DeleteAnalysisTableRowAttachResultPayload";
  /** The `AnalysisTableRowAttachResult` that was deleted by this mutation. */
  analysisTableRowAttachResult: Maybe<AnalysisTableRowAttachResult>;
  /** An edge for our `AnalysisTableRowAttachResult`. May be used by Relay 1. */
  analysisTableRowAttachResultEdge: Maybe<AnalysisTableRowAttachResultsEdge>;
  /** Reads a single `AnalysisTableRow` that is related to this `AnalysisTableRowAttachResult`. */
  analysisTableRowByAnalysisTableRowId: Maybe<AnalysisTableRow>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedAnalysisTableRowAttachResultId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `Project` that is related to this `AnalysisTableRowAttachResult`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `AnalysisTableRowAttachResult` mutation. */
export type DeleteAnalysisTableRowAttachResultPayloadAnalysisTableRowAttachResultEdgeArgs =
  {
    orderBy?: InputMaybe<Array<AnalysisTableRowAttachResultsOrderBy>>;
  };

/** All input for the `deleteAnalysisTableRowById` mutation. */
export type DeleteAnalysisTableRowByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** All input for the `deleteAnalysisTableRowByTaskId` mutation. */
export type DeleteAnalysisTableRowByTaskIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  taskId: Scalars["UUID"]["input"];
};

/** The output of our delete `AnalysisTableRow` mutation. */
export type DeleteAnalysisTableRowPayload = {
  __typename: "DeleteAnalysisTableRowPayload";
  /** Reads a single `AnalysisTable` that is related to this `AnalysisTableRow`. */
  analysisTableByTableId: Maybe<AnalysisTable>;
  /** The `AnalysisTableRow` that was deleted by this mutation. */
  analysisTableRow: Maybe<AnalysisTableRow>;
  /** An edge for our `AnalysisTableRow`. May be used by Relay 1. */
  analysisTableRowEdge: Maybe<AnalysisTableRowsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedAnalysisTableRowId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `Project` that is related to this `AnalysisTableRow`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Task` that is related to this `AnalysisTableRow`. */
  taskByTaskId: Maybe<Task>;
  /** Reads a single `Tenant` that is related to this `AnalysisTableRow`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `ToolVersion` that is related to this `AnalysisTableRow`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our delete `AnalysisTableRow` mutation. */
export type DeleteAnalysisTableRowPayloadAnalysisTableRowEdgeArgs = {
  orderBy?: InputMaybe<Array<AnalysisTableRowsOrderBy>>;
};

/** All input for the `deleteApplicationUserByEmail` mutation. */
export type DeleteApplicationUserByEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
};

/** All input for the `deleteApplicationUserById` mutation. */
export type DeleteApplicationUserByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** All input for the `deleteApplicationUserByUsername` mutation. */
export type DeleteApplicationUserByUsernameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  username: Scalars["String"]["input"];
};

/** All input for the `deleteApplicationUserGroupById` mutation. */
export type DeleteApplicationUserGroupByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteApplicationUserGroupByUserIdAndGroupId` mutation. */
export type DeleteApplicationUserGroupByUserIdAndGroupIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  groupId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The output of our delete `ApplicationUserGroup` mutation. */
export type DeleteApplicationUserGroupPayload = {
  __typename: "DeleteApplicationUserGroupPayload";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserGroup`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** The `ApplicationUserGroup` that was deleted by this mutation. */
  applicationUserGroup: Maybe<ApplicationUserGroup>;
  /** An edge for our `ApplicationUserGroup`. May be used by Relay 1. */
  applicationUserGroupEdge: Maybe<ApplicationUserGroupsEdge>;
  /** Reads a single `AuthGroup` that is related to this `ApplicationUserGroup`. */
  authGroupByGroupId: Maybe<AuthGroup>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedApplicationUserGroupId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `ApplicationUserGroup` mutation. */
export type DeleteApplicationUserGroupPayloadApplicationUserGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<ApplicationUserGroupsOrderBy>>;
};

/** All input for the `deleteApplicationUserMembershipById` mutation. */
export type DeleteApplicationUserMembershipByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteApplicationUserMembershipByUserIdAndTenantId` mutation. */
export type DeleteApplicationUserMembershipByUserIdAndTenantIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  tenantId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The output of our delete `ApplicationUserMembership` mutation. */
export type DeleteApplicationUserMembershipPayload = {
  __typename: "DeleteApplicationUserMembershipPayload";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserMembership`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** The `ApplicationUserMembership` that was deleted by this mutation. */
  applicationUserMembership: Maybe<ApplicationUserMembership>;
  /** An edge for our `ApplicationUserMembership`. May be used by Relay 1. */
  applicationUserMembershipEdge: Maybe<ApplicationUserMembershipsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedApplicationUserMembershipId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `ApplicationUserMembership`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `ApplicationUserMembership` mutation. */
export type DeleteApplicationUserMembershipPayloadApplicationUserMembershipEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ApplicationUserMembershipsOrderBy>>;
  };

/** All input for the `deleteApplicationUserMetadatumById` mutation. */
export type DeleteApplicationUserMetadatumByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteApplicationUserMetadatumByUserIdAndKey` mutation. */
export type DeleteApplicationUserMetadatumByUserIdAndKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The output of our delete `ApplicationUserMetadatum` mutation. */
export type DeleteApplicationUserMetadatumPayload = {
  __typename: "DeleteApplicationUserMetadatumPayload";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserMetadatum`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** The `ApplicationUserMetadatum` that was deleted by this mutation. */
  applicationUserMetadatum: Maybe<ApplicationUserMetadatum>;
  /** An edge for our `ApplicationUserMetadatum`. May be used by Relay 1. */
  applicationUserMetadatumEdge: Maybe<ApplicationUserMetadataEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedApplicationUserMetadatumId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `ApplicationUserMetadatum` mutation. */
export type DeleteApplicationUserMetadatumPayloadApplicationUserMetadatumEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ApplicationUserMetadataOrderBy>>;
  };

/** The output of our delete `ApplicationUser` mutation. */
export type DeleteApplicationUserPayload = {
  __typename: "DeleteApplicationUserPayload";
  /** The `ApplicationUser` that was deleted by this mutation. */
  applicationUser: Maybe<ApplicationUser>;
  /** An edge for our `ApplicationUser`. May be used by Relay 1. */
  applicationUserEdge: Maybe<ApplicationUsersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedApplicationUserId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `ApplicationUser` mutation. */
export type DeleteApplicationUserPayloadApplicationUserEdgeArgs = {
  orderBy?: InputMaybe<Array<ApplicationUsersOrderBy>>;
};

/** All input for the `deleteApplicationUserSessionById` mutation. */
export type DeleteApplicationUserSessionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `ApplicationUserSession` mutation. */
export type DeleteApplicationUserSessionPayload = {
  __typename: "DeleteApplicationUserSessionPayload";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserSession`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** The `ApplicationUserSession` that was deleted by this mutation. */
  applicationUserSession: Maybe<ApplicationUserSession>;
  /** An edge for our `ApplicationUserSession`. May be used by Relay 1. */
  applicationUserSessionEdge: Maybe<ApplicationUserSessionsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedApplicationUserSessionId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `ApplicationUserSession`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `ApplicationUserSession` mutation. */
export type DeleteApplicationUserSessionPayloadApplicationUserSessionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ApplicationUserSessionsOrderBy>>;
  };

/** All input for the `deleteApplicationUserUserPermissionById` mutation. */
export type DeleteApplicationUserUserPermissionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteApplicationUserUserPermissionByUserIdAndPermissionId` mutation. */
export type DeleteApplicationUserUserPermissionByUserIdAndPermissionIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  permissionId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The output of our delete `ApplicationUserUserPermission` mutation. */
export type DeleteApplicationUserUserPermissionPayload = {
  __typename: "DeleteApplicationUserUserPermissionPayload";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserUserPermission`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** The `ApplicationUserUserPermission` that was deleted by this mutation. */
  applicationUserUserPermission: Maybe<ApplicationUserUserPermission>;
  /** An edge for our `ApplicationUserUserPermission`. May be used by Relay 1. */
  applicationUserUserPermissionEdge: Maybe<ApplicationUserUserPermissionsEdge>;
  /** Reads a single `AuthPermission` that is related to this `ApplicationUserUserPermission`. */
  authPermissionByPermissionId: Maybe<AuthPermission>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedApplicationUserUserPermissionId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `ApplicationUserUserPermission` mutation. */
export type DeleteApplicationUserUserPermissionPayloadApplicationUserUserPermissionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ApplicationUserUserPermissionsOrderBy>>;
  };

/** All input for the `deleteAuthGroupById` mutation. */
export type DeleteAuthGroupByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteAuthGroupByName` mutation. */
export type DeleteAuthGroupByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

/** The output of our delete `AuthGroup` mutation. */
export type DeleteAuthGroupPayload = {
  __typename: "DeleteAuthGroupPayload";
  /** The `AuthGroup` that was deleted by this mutation. */
  authGroup: Maybe<AuthGroup>;
  /** An edge for our `AuthGroup`. May be used by Relay 1. */
  authGroupEdge: Maybe<AuthGroupsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedAuthGroupId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `AuthGroup` mutation. */
export type DeleteAuthGroupPayloadAuthGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<AuthGroupsOrderBy>>;
};

/** All input for the `deleteAuthGroupPermissionByGroupIdAndPermissionId` mutation. */
export type DeleteAuthGroupPermissionByGroupIdAndPermissionIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  groupId: Scalars["Int"]["input"];
  permissionId: Scalars["Int"]["input"];
};

/** All input for the `deleteAuthGroupPermissionById` mutation. */
export type DeleteAuthGroupPermissionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `AuthGroupPermission` mutation. */
export type DeleteAuthGroupPermissionPayload = {
  __typename: "DeleteAuthGroupPermissionPayload";
  /** Reads a single `AuthGroup` that is related to this `AuthGroupPermission`. */
  authGroupByGroupId: Maybe<AuthGroup>;
  /** The `AuthGroupPermission` that was deleted by this mutation. */
  authGroupPermission: Maybe<AuthGroupPermission>;
  /** An edge for our `AuthGroupPermission`. May be used by Relay 1. */
  authGroupPermissionEdge: Maybe<AuthGroupPermissionsEdge>;
  /** Reads a single `AuthPermission` that is related to this `AuthGroupPermission`. */
  authPermissionByPermissionId: Maybe<AuthPermission>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedAuthGroupPermissionId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `AuthGroupPermission` mutation. */
export type DeleteAuthGroupPermissionPayloadAuthGroupPermissionEdgeArgs = {
  orderBy?: InputMaybe<Array<AuthGroupPermissionsOrderBy>>;
};

/** All input for the `deleteAuthPermissionByContentTypeIdAndCodename` mutation. */
export type DeleteAuthPermissionByContentTypeIdAndCodenameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  codename: Scalars["String"]["input"];
  contentTypeId: Scalars["Int"]["input"];
};

/** All input for the `deleteAuthPermissionById` mutation. */
export type DeleteAuthPermissionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `AuthPermission` mutation. */
export type DeleteAuthPermissionPayload = {
  __typename: "DeleteAuthPermissionPayload";
  /** The `AuthPermission` that was deleted by this mutation. */
  authPermission: Maybe<AuthPermission>;
  /** An edge for our `AuthPermission`. May be used by Relay 1. */
  authPermissionEdge: Maybe<AuthPermissionsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedAuthPermissionId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `DjangoContentType` that is related to this `AuthPermission`. */
  djangoContentTypeByContentTypeId: Maybe<DjangoContentType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `AuthPermission` mutation. */
export type DeleteAuthPermissionPayloadAuthPermissionEdgeArgs = {
  orderBy?: InputMaybe<Array<AuthPermissionsOrderBy>>;
};

/** All input for the `deleteAvatarById` mutation. */
export type DeleteAvatarByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `deleteAvatarByUserId` mutation. */
export type DeleteAvatarByUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["UUID"]["input"];
};

/** The output of our delete `Avatar` mutation. */
export type DeleteAvatarPayload = {
  __typename: "DeleteAvatarPayload";
  /** Reads a single `ApplicationUser` that is related to this `Avatar`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** The `Avatar` that was deleted by this mutation. */
  avatar: Maybe<Avatar>;
  /** An edge for our `Avatar`. May be used by Relay 1. */
  avatarEdge: Maybe<AvatarsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedAvatarId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `Avatar` mutation. */
export type DeleteAvatarPayloadAvatarEdgeArgs = {
  orderBy?: InputMaybe<Array<AvatarsOrderBy>>;
};

/** All input for the `deleteAwsBatchTaskByTaskexecutorPtrId` mutation. */
export type DeleteAwsBatchTaskByTaskexecutorPtrIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  taskexecutorPtrId: Scalars["UUID"]["input"];
};

/** The output of our delete `AwsBatchTask` mutation. */
export type DeleteAwsBatchTaskPayload = {
  __typename: "DeleteAwsBatchTaskPayload";
  /** The `AwsBatchTask` that was deleted by this mutation. */
  awsBatchTask: Maybe<AwsBatchTask>;
  /** An edge for our `AwsBatchTask`. May be used by Relay 1. */
  awsBatchTaskEdge: Maybe<AwsBatchTasksEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedAwsBatchTaskId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `TesTaskexecutor` that is related to this `AwsBatchTask`. */
  tesTaskexecutorByTaskexecutorPtrId: Maybe<TesTaskexecutor>;
};

/** The output of our delete `AwsBatchTask` mutation. */
export type DeleteAwsBatchTaskPayloadAwsBatchTaskEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsBatchTasksOrderBy>>;
};

/** All input for the `deleteAwsCognitoUserByUserexecutorPtrId` mutation. */
export type DeleteAwsCognitoUserByUserexecutorPtrIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  userexecutorPtrId: Scalars["Int"]["input"];
};

/** The output of our delete `AwsCognitoUser` mutation. */
export type DeleteAwsCognitoUserPayload = {
  __typename: "DeleteAwsCognitoUserPayload";
  /** The `AwsCognitoUser` that was deleted by this mutation. */
  awsCognitoUser: Maybe<AwsCognitoUser>;
  /** An edge for our `AwsCognitoUser`. May be used by Relay 1. */
  awsCognitoUserEdge: Maybe<AwsCognitoUsersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedAwsCognitoUserId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `TenantUserexecutor` that is related to this `AwsCognitoUser`. */
  tenantUserexecutorByUserexecutorPtrId: Maybe<TenantUserexecutor>;
};

/** The output of our delete `AwsCognitoUser` mutation. */
export type DeleteAwsCognitoUserPayloadAwsCognitoUserEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsCognitoUsersOrderBy>>;
};

/** All input for the `deleteAwsEcrImageByContainerimageexecutorPtrId` mutation. */
export type DeleteAwsEcrImageByContainerimageexecutorPtrIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  containerimageexecutorPtrId: Scalars["Int"]["input"];
};

/** The output of our delete `AwsEcrImage` mutation. */
export type DeleteAwsEcrImagePayload = {
  __typename: "DeleteAwsEcrImagePayload";
  /** The `AwsEcrImage` that was deleted by this mutation. */
  awsEcrImage: Maybe<AwsEcrImage>;
  /** An edge for our `AwsEcrImage`. May be used by Relay 1. */
  awsEcrImageEdge: Maybe<AwsEcrImagesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedAwsEcrImageId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `TesContainerimageexecutor` that is related to this `AwsEcrImage`. */
  tesContainerimageexecutorByContainerimageexecutorPtrId: Maybe<TesContainerimageexecutor>;
};

/** The output of our delete `AwsEcrImage` mutation. */
export type DeleteAwsEcrImagePayloadAwsEcrImageEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsEcrImagesOrderBy>>;
};

/** All input for the `deleteAwsMockBatchTaskByAwstaskexecutorPtrId` mutation. */
export type DeleteAwsMockBatchTaskByAwstaskexecutorPtrIdInput = {
  awstaskexecutorPtrId: Scalars["UUID"]["input"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our delete `AwsMockBatchTask` mutation. */
export type DeleteAwsMockBatchTaskPayload = {
  __typename: "DeleteAwsMockBatchTaskPayload";
  /** Reads a single `AwsBatchTask` that is related to this `AwsMockBatchTask`. */
  awsBatchTaskByAwstaskexecutorPtrId: Maybe<AwsBatchTask>;
  /** The `AwsMockBatchTask` that was deleted by this mutation. */
  awsMockBatchTask: Maybe<AwsMockBatchTask>;
  /** An edge for our `AwsMockBatchTask`. May be used by Relay 1. */
  awsMockBatchTaskEdge: Maybe<AwsMockBatchTasksEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedAwsMockBatchTaskId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `AwsMockBatchTask` mutation. */
export type DeleteAwsMockBatchTaskPayloadAwsMockBatchTaskEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsMockBatchTasksOrderBy>>;
};

/** All input for the `deleteAwsMockEcrImageByAwscontainerimageexecutorPtrId` mutation. */
export type DeleteAwsMockEcrImageByAwscontainerimageexecutorPtrIdInput = {
  awscontainerimageexecutorPtrId: Scalars["Int"]["input"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our delete `AwsMockEcrImage` mutation. */
export type DeleteAwsMockEcrImagePayload = {
  __typename: "DeleteAwsMockEcrImagePayload";
  /** Reads a single `AwsEcrImage` that is related to this `AwsMockEcrImage`. */
  awsEcrImageByAwscontainerimageexecutorPtrId: Maybe<AwsEcrImage>;
  /** The `AwsMockEcrImage` that was deleted by this mutation. */
  awsMockEcrImage: Maybe<AwsMockEcrImage>;
  /** An edge for our `AwsMockEcrImage`. May be used by Relay 1. */
  awsMockEcrImageEdge: Maybe<AwsMockEcrImagesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedAwsMockEcrImageId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `AwsMockEcrImage` mutation. */
export type DeleteAwsMockEcrImagePayloadAwsMockEcrImageEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsMockEcrImagesOrderBy>>;
};

/** All input for the `deleteAwsMockS3FileByAwsfileexecutorPtrId` mutation. */
export type DeleteAwsMockS3FileByAwsfileexecutorPtrIdInput = {
  awsfileexecutorPtrId: Scalars["UUID"]["input"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our delete `AwsMockS3File` mutation. */
export type DeleteAwsMockS3FilePayload = {
  __typename: "DeleteAwsMockS3FilePayload";
  /** The `AwsMockS3File` that was deleted by this mutation. */
  awsMockS3File: Maybe<AwsMockS3File>;
  /** An edge for our `AwsMockS3File`. May be used by Relay 1. */
  awsMockS3FileEdge: Maybe<AwsMockS3FilesEdge>;
  /** Reads a single `AwsS3File` that is related to this `AwsMockS3File`. */
  awsS3FileByAwsfileexecutorPtrId: Maybe<AwsS3File>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedAwsMockS3FileId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `AwsMockS3File` mutation. */
export type DeleteAwsMockS3FilePayloadAwsMockS3FileEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsMockS3FilesOrderBy>>;
};

/** All input for the `deleteAwsS3FileByFileexecutorPtrId` mutation. */
export type DeleteAwsS3FileByFileexecutorPtrIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  fileexecutorPtrId: Scalars["UUID"]["input"];
};

/** The output of our delete `AwsS3File` mutation. */
export type DeleteAwsS3FilePayload = {
  __typename: "DeleteAwsS3FilePayload";
  /** The `AwsS3File` that was deleted by this mutation. */
  awsS3File: Maybe<AwsS3File>;
  /** An edge for our `AwsS3File`. May be used by Relay 1. */
  awsS3FileEdge: Maybe<AwsS3FilesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedAwsS3FileId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `DrsFileexecutor` that is related to this `AwsS3File`. */
  drsFileexecutorByFileexecutorPtrId: Maybe<DrsFileexecutor>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `AwsS3File` mutation. */
export type DeleteAwsS3FilePayloadAwsS3FileEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsS3FilesOrderBy>>;
};

/** All input for the `deleteContainerImageByExecutorId` mutation. */
export type DeleteContainerImageByExecutorIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  executorId: Scalars["Int"]["input"];
};

/** All input for the `deleteContainerImageById` mutation. */
export type DeleteContainerImageByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteContainerImageByNameAndTag` mutation. */
export type DeleteContainerImageByNameAndTagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  tag: Scalars["String"]["input"];
};

/** All input for the `deleteContainerImageByRepositoryAndTag` mutation. */
export type DeleteContainerImageByRepositoryAndTagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  repository: Scalars["String"]["input"];
  tag: Scalars["String"]["input"];
};

/** All input for the `deleteContainerImageByToolboxVersionId` mutation. */
export type DeleteContainerImageByToolboxVersionIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  toolboxVersionId: Scalars["Int"]["input"];
};

/** The output of our delete `ContainerImage` mutation. */
export type DeleteContainerImagePayload = {
  __typename: "DeleteContainerImagePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `ContainerImage` that was deleted by this mutation. */
  containerImage: Maybe<ContainerImage>;
  /** An edge for our `ContainerImage`. May be used by Relay 1. */
  containerImageEdge: Maybe<ContainerImagesEdge>;
  deletedContainerImageId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `TesContainerimageexecutor` that is related to this `ContainerImage`. */
  tesContainerimageexecutorByExecutorId: Maybe<TesContainerimageexecutor>;
  /** Reads a single `ToolboxVersion` that is related to this `ContainerImage`. */
  toolboxVersionByToolboxVersionId: Maybe<ToolboxVersion>;
};

/** The output of our delete `ContainerImage` mutation. */
export type DeleteContainerImagePayloadContainerImageEdgeArgs = {
  orderBy?: InputMaybe<Array<ContainerImagesOrderBy>>;
};

/** All input for the `deleteCostById` mutation. */
export type DeleteCostByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** The output of our delete `Cost` mutation. */
export type DeleteCostPayload = {
  __typename: "DeleteCostPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Cost` that was deleted by this mutation. */
  cost: Maybe<Cost>;
  /** An edge for our `Cost`. May be used by Relay 1. */
  costEdge: Maybe<CostsEdge>;
  deletedCostId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Cost`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `Cost` mutation. */
export type DeleteCostPayloadCostEdgeArgs = {
  orderBy?: InputMaybe<Array<CostsOrderBy>>;
};

/** All input for the `deleteDatasetById` mutation. */
export type DeleteDatasetByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** All input for the `deleteDatasetByProjectIdAndPrefix` mutation. */
export type DeleteDatasetByProjectIdAndPrefixInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  prefix: Scalars["String"]["input"];
  projectId: Scalars["UUID"]["input"];
};

/** All input for the `deleteDatasetByRecordingsTableId` mutation. */
export type DeleteDatasetByRecordingsTableIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  recordingsTableId: Scalars["UUID"]["input"];
};

/** All input for the `deleteDatasetByTenantIdAndKey` mutation. */
export type DeleteDatasetByTenantIdAndKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
  tenantId: Scalars["Int"]["input"];
};

/** All input for the `deleteDatasetMetadatumById` mutation. */
export type DeleteDatasetMetadatumByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `DatasetMetadatum` mutation. */
export type DeleteDatasetMetadatumPayload = {
  __typename: "DeleteDatasetMetadatumPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Dataset` that is related to this `DatasetMetadatum`. */
  datasetByDatasetId: Maybe<Dataset>;
  /** The `DatasetMetadatum` that was deleted by this mutation. */
  datasetMetadatum: Maybe<DatasetMetadatum>;
  /** An edge for our `DatasetMetadatum`. May be used by Relay 1. */
  datasetMetadatumEdge: Maybe<DatasetMetadataEdge>;
  deletedDatasetMetadatumId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `Metadatum` that is related to this `DatasetMetadatum`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `DatasetMetadatum`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `DatasetMetadatum`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `DatasetMetadatum` mutation. */
export type DeleteDatasetMetadatumPayloadDatasetMetadatumEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetMetadataOrderBy>>;
};

/** The output of our delete `Dataset` mutation. */
export type DeleteDatasetPayload = {
  __typename: "DeleteDatasetPayload";
  /** Reads a single `ApplicationUser` that is related to this `Dataset`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Dataset` that was deleted by this mutation. */
  dataset: Maybe<Dataset>;
  /** An edge for our `Dataset`. May be used by Relay 1. */
  datasetEdge: Maybe<DatasetsEdge>;
  /** Reads a single `DatasetRecordingsTable` that is related to this `Dataset`. */
  datasetRecordingsTableByRecordingsTableId: Maybe<DatasetRecordingsTable>;
  deletedDatasetId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `Metadatum` that is related to this `Dataset`. */
  metadatumByDescriptionId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `Dataset`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Dataset`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `Dataset` mutation. */
export type DeleteDatasetPayloadDatasetEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetsOrderBy>>;
};

/** All input for the `deleteDatasetRecordingsTableById` mutation. */
export type DeleteDatasetRecordingsTableByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** All input for the `deleteDatasetRecordingsTableColumnById` mutation. */
export type DeleteDatasetRecordingsTableColumnByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** All input for the `deleteDatasetRecordingsTableColumnOrderById` mutation. */
export type DeleteDatasetRecordingsTableColumnOrderByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `DatasetRecordingsTableColumnOrder` mutation. */
export type DeleteDatasetRecordingsTableColumnOrderPayload = {
  __typename: "DeleteDatasetRecordingsTableColumnOrderPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DatasetRecordingsTableColumn` that is related to this `DatasetRecordingsTableColumnOrder`. */
  datasetRecordingsTableColumnByDatasetRecordingsTableColumnId: Maybe<DatasetRecordingsTableColumn>;
  /** The `DatasetRecordingsTableColumnOrder` that was deleted by this mutation. */
  datasetRecordingsTableColumnOrder: Maybe<DatasetRecordingsTableColumnOrder>;
  /** An edge for our `DatasetRecordingsTableColumnOrder`. May be used by Relay 1. */
  datasetRecordingsTableColumnOrderEdge: Maybe<DatasetRecordingsTableColumnOrdersEdge>;
  deletedDatasetRecordingsTableColumnOrderId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `Project` that is related to this `DatasetRecordingsTableColumnOrder`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `DatasetRecordingsTableColumnOrder`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `DatasetRecordingsTableColumnOrder` mutation. */
export type DeleteDatasetRecordingsTableColumnOrderPayloadDatasetRecordingsTableColumnOrderEdgeArgs =
  {
    orderBy?: InputMaybe<Array<DatasetRecordingsTableColumnOrdersOrderBy>>;
  };

/** The output of our delete `DatasetRecordingsTableColumn` mutation. */
export type DeleteDatasetRecordingsTableColumnPayload = {
  __typename: "DeleteDatasetRecordingsTableColumnPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DatasetRecordingsTable` that is related to this `DatasetRecordingsTableColumn`. */
  datasetRecordingsTableByDatasetRecordingsTableId: Maybe<DatasetRecordingsTable>;
  /** The `DatasetRecordingsTableColumn` that was deleted by this mutation. */
  datasetRecordingsTableColumn: Maybe<DatasetRecordingsTableColumn>;
  /** An edge for our `DatasetRecordingsTableColumn`. May be used by Relay 1. */
  datasetRecordingsTableColumnEdge: Maybe<DatasetRecordingsTableColumnsEdge>;
  deletedDatasetRecordingsTableColumnId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `Metadatum` that is related to this `DatasetRecordingsTableColumn`. */
  metadatumByColDefId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `DatasetRecordingsTableColumn`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `DatasetRecordingsTableColumn`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `DatasetRecordingsTableColumn` mutation. */
export type DeleteDatasetRecordingsTableColumnPayloadDatasetRecordingsTableColumnEdgeArgs =
  {
    orderBy?: InputMaybe<Array<DatasetRecordingsTableColumnsOrderBy>>;
  };

/** The output of our delete `DatasetRecordingsTable` mutation. */
export type DeleteDatasetRecordingsTablePayload = {
  __typename: "DeleteDatasetRecordingsTablePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `DatasetRecordingsTable` that was deleted by this mutation. */
  datasetRecordingsTable: Maybe<DatasetRecordingsTable>;
  /** An edge for our `DatasetRecordingsTable`. May be used by Relay 1. */
  datasetRecordingsTableEdge: Maybe<DatasetRecordingsTablesEdge>;
  deletedDatasetRecordingsTableId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `Project` that is related to this `DatasetRecordingsTable`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `DatasetRecordingsTable`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `DatasetRecordingsTable` mutation. */
export type DeleteDatasetRecordingsTablePayloadDatasetRecordingsTableEdgeArgs =
  {
    orderBy?: InputMaybe<Array<DatasetRecordingsTablesOrderBy>>;
  };

/** All input for the `deleteDatasetVersionById` mutation. */
export type DeleteDatasetVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `DatasetVersion` mutation. */
export type DeleteDatasetVersionPayload = {
  __typename: "DeleteDatasetVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Dataset` that is related to this `DatasetVersion`. */
  datasetByDatasetId: Maybe<Dataset>;
  /** The `DatasetVersion` that was deleted by this mutation. */
  datasetVersion: Maybe<DatasetVersion>;
  /** An edge for our `DatasetVersion`. May be used by Relay 1. */
  datasetVersionEdge: Maybe<DatasetVersionsEdge>;
  deletedDatasetVersionId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `Project` that is related to this `DatasetVersion`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `DatasetVersion`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `DatasetVersion` mutation. */
export type DeleteDatasetVersionPayloadDatasetVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetVersionsOrderBy>>;
};

/** All input for the `deleteDjangoAdminLogById` mutation. */
export type DeleteDjangoAdminLogByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `DjangoAdminLog` mutation. */
export type DeleteDjangoAdminLogPayload = {
  __typename: "DeleteDjangoAdminLogPayload";
  /** Reads a single `ApplicationUser` that is related to this `DjangoAdminLog`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedDjangoAdminLogId: Maybe<Scalars["ID"]["output"]>;
  /** The `DjangoAdminLog` that was deleted by this mutation. */
  djangoAdminLog: Maybe<DjangoAdminLog>;
  /** An edge for our `DjangoAdminLog`. May be used by Relay 1. */
  djangoAdminLogEdge: Maybe<DjangoAdminLogsEdge>;
  /** Reads a single `DjangoContentType` that is related to this `DjangoAdminLog`. */
  djangoContentTypeByContentTypeId: Maybe<DjangoContentType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `DjangoAdminLog` mutation. */
export type DeleteDjangoAdminLogPayloadDjangoAdminLogEdgeArgs = {
  orderBy?: InputMaybe<Array<DjangoAdminLogsOrderBy>>;
};

/** All input for the `deleteDjangoContentTypeByAppLabelAndModel` mutation. */
export type DeleteDjangoContentTypeByAppLabelAndModelInput = {
  appLabel: Scalars["String"]["input"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  model: Scalars["String"]["input"];
};

/** All input for the `deleteDjangoContentTypeById` mutation. */
export type DeleteDjangoContentTypeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `DjangoContentType` mutation. */
export type DeleteDjangoContentTypePayload = {
  __typename: "DeleteDjangoContentTypePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedDjangoContentTypeId: Maybe<Scalars["ID"]["output"]>;
  /** The `DjangoContentType` that was deleted by this mutation. */
  djangoContentType: Maybe<DjangoContentType>;
  /** An edge for our `DjangoContentType`. May be used by Relay 1. */
  djangoContentTypeEdge: Maybe<DjangoContentTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `DjangoContentType` mutation. */
export type DeleteDjangoContentTypePayloadDjangoContentTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<DjangoContentTypesOrderBy>>;
};

/** All input for the `deleteDjangoMigrationById` mutation. */
export type DeleteDjangoMigrationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `DjangoMigration` mutation. */
export type DeleteDjangoMigrationPayload = {
  __typename: "DeleteDjangoMigrationPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedDjangoMigrationId: Maybe<Scalars["ID"]["output"]>;
  /** The `DjangoMigration` that was deleted by this mutation. */
  djangoMigration: Maybe<DjangoMigration>;
  /** An edge for our `DjangoMigration`. May be used by Relay 1. */
  djangoMigrationEdge: Maybe<DjangoMigrationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `DjangoMigration` mutation. */
export type DeleteDjangoMigrationPayloadDjangoMigrationEdgeArgs = {
  orderBy?: InputMaybe<Array<DjangoMigrationsOrderBy>>;
};

/** All input for the `deleteDjangoSesSesstatByDate` mutation. */
export type DeleteDjangoSesSesstatByDateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  date: Scalars["Date"]["input"];
};

/** All input for the `deleteDjangoSesSesstatById` mutation. */
export type DeleteDjangoSesSesstatByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `DjangoSesSesstat` mutation. */
export type DeleteDjangoSesSesstatPayload = {
  __typename: "DeleteDjangoSesSesstatPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedDjangoSesSesstatId: Maybe<Scalars["ID"]["output"]>;
  /** The `DjangoSesSesstat` that was deleted by this mutation. */
  djangoSesSesstat: Maybe<DjangoSesSesstat>;
  /** An edge for our `DjangoSesSesstat`. May be used by Relay 1. */
  djangoSesSesstatEdge: Maybe<DjangoSesSesstatsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `DjangoSesSesstat` mutation. */
export type DeleteDjangoSesSesstatPayloadDjangoSesSesstatEdgeArgs = {
  orderBy?: InputMaybe<Array<DjangoSesSesstatsOrderBy>>;
};

/** All input for the `deleteDjangoSessionBySessionKey` mutation. */
export type DeleteDjangoSessionBySessionKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  sessionKey: Scalars["String"]["input"];
};

/** The output of our delete `DjangoSession` mutation. */
export type DeleteDjangoSessionPayload = {
  __typename: "DeleteDjangoSessionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedDjangoSessionId: Maybe<Scalars["ID"]["output"]>;
  /** The `DjangoSession` that was deleted by this mutation. */
  djangoSession: Maybe<DjangoSession>;
  /** An edge for our `DjangoSession`. May be used by Relay 1. */
  djangoSessionEdge: Maybe<DjangoSessionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `DjangoSession` mutation. */
export type DeleteDjangoSessionPayloadDjangoSessionEdgeArgs = {
  orderBy?: InputMaybe<Array<DjangoSessionsOrderBy>>;
};

/** All input for the `deleteDrsFileexecutorById` mutation. */
export type DeleteDrsFileexecutorByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `DrsFileexecutor` mutation. */
export type DeleteDrsFileexecutorPayload = {
  __typename: "DeleteDrsFileexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedDrsFileexecutorId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `DjangoContentType` that is related to this `DrsFileexecutor`. */
  djangoContentTypeByPolymorphicCtypeId: Maybe<DjangoContentType>;
  /** The `DrsFileexecutor` that was deleted by this mutation. */
  drsFileexecutor: Maybe<DrsFileexecutor>;
  /** An edge for our `DrsFileexecutor`. May be used by Relay 1. */
  drsFileexecutorEdge: Maybe<DrsFileexecutorsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `DrsFileexecutor` mutation. */
export type DeleteDrsFileexecutorPayloadDrsFileexecutorEdgeArgs = {
  orderBy?: InputMaybe<Array<DrsFileexecutorsOrderBy>>;
};

/** All input for the `deleteDrsMockfileexecutorByFileexecutorPtrId` mutation. */
export type DeleteDrsMockfileexecutorByFileexecutorPtrIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  fileexecutorPtrId: Scalars["UUID"]["input"];
};

/** The output of our delete `DrsMockfileexecutor` mutation. */
export type DeleteDrsMockfileexecutorPayload = {
  __typename: "DeleteDrsMockfileexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedDrsMockfileexecutorId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `DrsFileexecutor` that is related to this `DrsMockfileexecutor`. */
  drsFileexecutorByFileexecutorPtrId: Maybe<DrsFileexecutor>;
  /** The `DrsMockfileexecutor` that was deleted by this mutation. */
  drsMockfileexecutor: Maybe<DrsMockfileexecutor>;
  /** An edge for our `DrsMockfileexecutor`. May be used by Relay 1. */
  drsMockfileexecutorEdge: Maybe<DrsMockfileexecutorsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `DrsMockfileexecutor` mutation. */
export type DeleteDrsMockfileexecutorPayloadDrsMockfileexecutorEdgeArgs = {
  orderBy?: InputMaybe<Array<DrsMockfileexecutorsOrderBy>>;
};

/** All input for the `deleteEulaVersionById` mutation. */
export type DeleteEulaVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteEulaVersionByVersion` mutation. */
export type DeleteEulaVersionByVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  version: Scalars["String"]["input"];
};

/** The output of our delete `EulaVersion` mutation. */
export type DeleteEulaVersionPayload = {
  __typename: "DeleteEulaVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedEulaVersionId: Maybe<Scalars["ID"]["output"]>;
  /** The `EulaVersion` that was deleted by this mutation. */
  eulaVersion: Maybe<EulaVersion>;
  /** An edge for our `EulaVersion`. May be used by Relay 1. */
  eulaVersionEdge: Maybe<EulaVersionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `EulaVersion` mutation. */
export type DeleteEulaVersionPayloadEulaVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<EulaVersionsOrderBy>>;
};

/** All input for the `deleteEulaVersionUserAcceptanceById` mutation. */
export type DeleteEulaVersionUserAcceptanceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** All input for the `deleteEulaVersionUserAcceptanceByUserIdAndEulaVersionId` mutation. */
export type DeleteEulaVersionUserAcceptanceByUserIdAndEulaVersionIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  eulaVersionId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The output of our delete `EulaVersionUserAcceptance` mutation. */
export type DeleteEulaVersionUserAcceptancePayload = {
  __typename: "DeleteEulaVersionUserAcceptancePayload";
  /** Reads a single `ApplicationUser` that is related to this `EulaVersionUserAcceptance`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedEulaVersionUserAcceptanceId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `EulaVersion` that is related to this `EulaVersionUserAcceptance`. */
  eulaVersionByEulaVersionId: Maybe<EulaVersion>;
  /** The `EulaVersionUserAcceptance` that was deleted by this mutation. */
  eulaVersionUserAcceptance: Maybe<EulaVersionUserAcceptance>;
  /** An edge for our `EulaVersionUserAcceptance`. May be used by Relay 1. */
  eulaVersionUserAcceptanceEdge: Maybe<EulaVersionUserAcceptancesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `EulaVersionUserAcceptance` mutation. */
export type DeleteEulaVersionUserAcceptancePayloadEulaVersionUserAcceptanceEdgeArgs =
  {
    orderBy?: InputMaybe<Array<EulaVersionUserAcceptancesOrderBy>>;
  };

/** All input for the `deleteExplorerQueryById` mutation. */
export type DeleteExplorerQueryByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `ExplorerQuery` mutation. */
export type DeleteExplorerQueryPayload = {
  __typename: "DeleteExplorerQueryPayload";
  /** Reads a single `ApplicationUser` that is related to this `ExplorerQuery`. */
  applicationUserByCreatedByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedExplorerQueryId: Maybe<Scalars["ID"]["output"]>;
  /** The `ExplorerQuery` that was deleted by this mutation. */
  explorerQuery: Maybe<ExplorerQuery>;
  /** An edge for our `ExplorerQuery`. May be used by Relay 1. */
  explorerQueryEdge: Maybe<ExplorerQueriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `ExplorerQuery` mutation. */
export type DeleteExplorerQueryPayloadExplorerQueryEdgeArgs = {
  orderBy?: InputMaybe<Array<ExplorerQueriesOrderBy>>;
};

/** All input for the `deleteExplorerQueryfavoriteById` mutation. */
export type DeleteExplorerQueryfavoriteByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteExplorerQueryfavoriteByQueryIdAndUserId` mutation. */
export type DeleteExplorerQueryfavoriteByQueryIdAndUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  queryId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The output of our delete `ExplorerQueryfavorite` mutation. */
export type DeleteExplorerQueryfavoritePayload = {
  __typename: "DeleteExplorerQueryfavoritePayload";
  /** Reads a single `ApplicationUser` that is related to this `ExplorerQueryfavorite`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedExplorerQueryfavoriteId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `ExplorerQuery` that is related to this `ExplorerQueryfavorite`. */
  explorerQueryByQueryId: Maybe<ExplorerQuery>;
  /** The `ExplorerQueryfavorite` that was deleted by this mutation. */
  explorerQueryfavorite: Maybe<ExplorerQueryfavorite>;
  /** An edge for our `ExplorerQueryfavorite`. May be used by Relay 1. */
  explorerQueryfavoriteEdge: Maybe<ExplorerQueryfavoritesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `ExplorerQueryfavorite` mutation. */
export type DeleteExplorerQueryfavoritePayloadExplorerQueryfavoriteEdgeArgs = {
  orderBy?: InputMaybe<Array<ExplorerQueryfavoritesOrderBy>>;
};

/** All input for the `deleteExplorerQuerylogById` mutation. */
export type DeleteExplorerQuerylogByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `ExplorerQuerylog` mutation. */
export type DeleteExplorerQuerylogPayload = {
  __typename: "DeleteExplorerQuerylogPayload";
  /** Reads a single `ApplicationUser` that is related to this `ExplorerQuerylog`. */
  applicationUserByRunByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedExplorerQuerylogId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `ExplorerQuery` that is related to this `ExplorerQuerylog`. */
  explorerQueryByQueryId: Maybe<ExplorerQuery>;
  /** The `ExplorerQuerylog` that was deleted by this mutation. */
  explorerQuerylog: Maybe<ExplorerQuerylog>;
  /** An edge for our `ExplorerQuerylog`. May be used by Relay 1. */
  explorerQuerylogEdge: Maybe<ExplorerQuerylogsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `ExplorerQuerylog` mutation. */
export type DeleteExplorerQuerylogPayloadExplorerQuerylogEdgeArgs = {
  orderBy?: InputMaybe<Array<ExplorerQuerylogsOrderBy>>;
};

/** All input for the `deleteFileActivityById` mutation. */
export type DeleteFileActivityByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `FileActivity` mutation. */
export type DeleteFileActivityPayload = {
  __typename: "DeleteFileActivityPayload";
  /** Reads a single `ApplicationUser` that is related to this `FileActivity`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Cost` that is related to this `FileActivity`. */
  costByCostId: Maybe<Cost>;
  deletedFileActivityId: Maybe<Scalars["ID"]["output"]>;
  /** The `FileActivity` that was deleted by this mutation. */
  fileActivity: Maybe<FileActivity>;
  /** An edge for our `FileActivity`. May be used by Relay 1. */
  fileActivityEdge: Maybe<FileActivitiesEdge>;
  /** Reads a single `File` that is related to this `FileActivity`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `Project` that is related to this `FileActivity`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `FileActivity`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `FileActivity` mutation. */
export type DeleteFileActivityPayloadFileActivityEdgeArgs = {
  orderBy?: InputMaybe<Array<FileActivitiesOrderBy>>;
};

/** All input for the `deleteFileById` mutation. */
export type DeleteFileByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** All input for the `deleteFileMetadatumById` mutation. */
export type DeleteFileMetadatumByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `FileMetadatum` mutation. */
export type DeleteFileMetadatumPayload = {
  __typename: "DeleteFileMetadatumPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedFileMetadatumId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `File` that is related to this `FileMetadatum`. */
  fileByFileId: Maybe<File>;
  /** The `FileMetadatum` that was deleted by this mutation. */
  fileMetadatum: Maybe<FileMetadatum>;
  /** An edge for our `FileMetadatum`. May be used by Relay 1. */
  fileMetadatumEdge: Maybe<FileMetadataEdge>;
  /** Reads a single `Metadatum` that is related to this `FileMetadatum`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `FileMetadatum`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `FileMetadatum`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `FileMetadatum` mutation. */
export type DeleteFileMetadatumPayloadFileMetadatumEdgeArgs = {
  orderBy?: InputMaybe<Array<FileMetadataOrderBy>>;
};

/** All input for the `deleteFileOutputGroupById` mutation. */
export type DeleteFileOutputGroupByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** All input for the `deleteFileOutputGroupByTaskId` mutation. */
export type DeleteFileOutputGroupByTaskIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  taskId: Scalars["UUID"]["input"];
};

/** All input for the `deleteFileOutputGroupMetadatumById` mutation. */
export type DeleteFileOutputGroupMetadatumByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `FileOutputGroupMetadatum` mutation. */
export type DeleteFileOutputGroupMetadatumPayload = {
  __typename: "DeleteFileOutputGroupMetadatumPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedFileOutputGroupMetadatumId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `FileOutputGroup` that is related to this `FileOutputGroupMetadatum`. */
  fileOutputGroupByFileOutputGroupId: Maybe<FileOutputGroup>;
  /** The `FileOutputGroupMetadatum` that was deleted by this mutation. */
  fileOutputGroupMetadatum: Maybe<FileOutputGroupMetadatum>;
  /** An edge for our `FileOutputGroupMetadatum`. May be used by Relay 1. */
  fileOutputGroupMetadatumEdge: Maybe<FileOutputGroupMetadataEdge>;
  /** Reads a single `Metadatum` that is related to this `FileOutputGroupMetadatum`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `FileOutputGroupMetadatum`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `FileOutputGroupMetadatum`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `FileOutputGroupMetadatum` mutation. */
export type DeleteFileOutputGroupMetadatumPayloadFileOutputGroupMetadatumEdgeArgs =
  {
    orderBy?: InputMaybe<Array<FileOutputGroupMetadataOrderBy>>;
  };

/** The output of our delete `FileOutputGroup` mutation. */
export type DeleteFileOutputGroupPayload = {
  __typename: "DeleteFileOutputGroupPayload";
  /** Reads a single `ApplicationUser` that is related to this `FileOutputGroup`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedFileOutputGroupId: Maybe<Scalars["ID"]["output"]>;
  /** The `FileOutputGroup` that was deleted by this mutation. */
  fileOutputGroup: Maybe<FileOutputGroup>;
  /** An edge for our `FileOutputGroup`. May be used by Relay 1. */
  fileOutputGroupEdge: Maybe<FileOutputGroupsEdge>;
  /** Reads a single `Project` that is related to this `FileOutputGroup`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Task` that is related to this `FileOutputGroup`. */
  taskByTaskId: Maybe<Task>;
  /** Reads a single `Tenant` that is related to this `FileOutputGroup`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `FileOutputGroup` mutation. */
export type DeleteFileOutputGroupPayloadFileOutputGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<FileOutputGroupsOrderBy>>;
};

/** The output of our delete `File` mutation. */
export type DeleteFilePayload = {
  __typename: "DeleteFilePayload";
  /** Reads a single `ApplicationUser` that is related to this `File`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Dataset` that is related to this `File`. */
  datasetByDatasetId: Maybe<Dataset>;
  deletedFileId: Maybe<Scalars["ID"]["output"]>;
  /** The `File` that was deleted by this mutation. */
  file: Maybe<File>;
  /** Reads a single `File` that is related to this `File`. */
  fileBySeriesParentId: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge: Maybe<FilesEdge>;
  /** Reads a single `Project` that is related to this `File`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `File`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `File` mutation. */
export type DeleteFilePayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the `deleteFileRecordingGroupById` mutation. */
export type DeleteFileRecordingGroupByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** All input for the `deleteFileRecordingGroupMetadatumById` mutation. */
export type DeleteFileRecordingGroupMetadatumByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `FileRecordingGroupMetadatum` mutation. */
export type DeleteFileRecordingGroupMetadatumPayload = {
  __typename: "DeleteFileRecordingGroupMetadatumPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedFileRecordingGroupMetadatumId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `FileRecordingGroup` that is related to this `FileRecordingGroupMetadatum`. */
  fileRecordingGroupByFileRecordingGroupId: Maybe<FileRecordingGroup>;
  /** The `FileRecordingGroupMetadatum` that was deleted by this mutation. */
  fileRecordingGroupMetadatum: Maybe<FileRecordingGroupMetadatum>;
  /** An edge for our `FileRecordingGroupMetadatum`. May be used by Relay 1. */
  fileRecordingGroupMetadatumEdge: Maybe<FileRecordingGroupMetadataEdge>;
  /** Reads a single `Metadatum` that is related to this `FileRecordingGroupMetadatum`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `FileRecordingGroupMetadatum`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `FileRecordingGroupMetadatum`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `FileRecordingGroupMetadatum` mutation. */
export type DeleteFileRecordingGroupMetadatumPayloadFileRecordingGroupMetadatumEdgeArgs =
  {
    orderBy?: InputMaybe<Array<FileRecordingGroupMetadataOrderBy>>;
  };

/** The output of our delete `FileRecordingGroup` mutation. */
export type DeleteFileRecordingGroupPayload = {
  __typename: "DeleteFileRecordingGroupPayload";
  /** Reads a single `ApplicationUser` that is related to this `FileRecordingGroup`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DatasetRecordingsTable` that is related to this `FileRecordingGroup`. */
  datasetRecordingsTableByDatasetRecordingsTableId: Maybe<DatasetRecordingsTable>;
  deletedFileRecordingGroupId: Maybe<Scalars["ID"]["output"]>;
  /** The `FileRecordingGroup` that was deleted by this mutation. */
  fileRecordingGroup: Maybe<FileRecordingGroup>;
  /** An edge for our `FileRecordingGroup`. May be used by Relay 1. */
  fileRecordingGroupEdge: Maybe<FileRecordingGroupsEdge>;
  /** Reads a single `Project` that is related to this `FileRecordingGroup`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `FileRecordingGroup`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `FileRecordingGroup` mutation. */
export type DeleteFileRecordingGroupPayloadFileRecordingGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<FileRecordingGroupsOrderBy>>;
};

/** All input for the `deleteMetadataValueById` mutation. */
export type DeleteMetadataValueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `MetadataValue` mutation. */
export type DeleteMetadataValuePayload = {
  __typename: "DeleteMetadataValuePayload";
  /** Reads a single `ApplicationUser` that is related to this `MetadataValue`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedMetadataValueId: Maybe<Scalars["ID"]["output"]>;
  /** The `MetadataValue` that was deleted by this mutation. */
  metadataValue: Maybe<MetadataValue>;
  /** An edge for our `MetadataValue`. May be used by Relay 1. */
  metadataValueEdge: Maybe<MetadataValuesEdge>;
  /** Reads a single `Metadatum` that is related to this `MetadataValue`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `MetadataValue`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Task` that is related to this `MetadataValue`. */
  taskByTaskId: Maybe<Task>;
  /** Reads a single `Tenant` that is related to this `MetadataValue`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `MetadataValue` mutation. */
export type DeleteMetadataValuePayloadMetadataValueEdgeArgs = {
  orderBy?: InputMaybe<Array<MetadataValuesOrderBy>>;
};

/** All input for the `deleteMetadatumById` mutation. */
export type DeleteMetadatumByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `Metadatum` mutation. */
export type DeleteMetadatumPayload = {
  __typename: "DeleteMetadatumPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedMetadatumId: Maybe<Scalars["ID"]["output"]>;
  /** The `Metadatum` that was deleted by this mutation. */
  metadatum: Maybe<Metadatum>;
  /** An edge for our `Metadatum`. May be used by Relay 1. */
  metadatumEdge: Maybe<MetadataEdge>;
  /** Reads a single `Project` that is related to this `Metadatum`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Metadatum`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `Metadatum` mutation. */
export type DeleteMetadatumPayloadMetadatumEdgeArgs = {
  orderBy?: InputMaybe<Array<MetadataOrderBy>>;
};

/** All input for the `deleteOutputGroupFileById` mutation. */
export type DeleteOutputGroupFileByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `OutputGroupFile` mutation. */
export type DeleteOutputGroupFilePayload = {
  __typename: "DeleteOutputGroupFilePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedOutputGroupFileId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `File` that is related to this `OutputGroupFile`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `FileOutputGroup` that is related to this `OutputGroupFile`. */
  fileOutputGroupByFileOutputGroupId: Maybe<FileOutputGroup>;
  /** The `OutputGroupFile` that was deleted by this mutation. */
  outputGroupFile: Maybe<OutputGroupFile>;
  /** An edge for our `OutputGroupFile`. May be used by Relay 1. */
  outputGroupFileEdge: Maybe<OutputGroupFilesEdge>;
  /** Reads a single `Project` that is related to this `OutputGroupFile`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our delete `OutputGroupFile` mutation. */
export type DeleteOutputGroupFilePayloadOutputGroupFileEdgeArgs = {
  orderBy?: InputMaybe<Array<OutputGroupFilesOrderBy>>;
};

/** All input for the `deleteRecordingGroupFileById` mutation. */
export type DeleteRecordingGroupFileByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `RecordingGroupFile` mutation. */
export type DeleteRecordingGroupFilePayload = {
  __typename: "DeleteRecordingGroupFilePayload";
  /** Reads a single `ApplicationUser` that is related to this `RecordingGroupFile`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DatasetRecordingsTableColumn` that is related to this `RecordingGroupFile`. */
  datasetRecordingsTableColumnByDatasetRecordingsTableColumnId: Maybe<DatasetRecordingsTableColumn>;
  deletedRecordingGroupFileId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `File` that is related to this `RecordingGroupFile`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `FileRecordingGroup` that is related to this `RecordingGroupFile`. */
  fileRecordingGroupByFileRecordingGroupId: Maybe<FileRecordingGroup>;
  /** Reads a single `Project` that is related to this `RecordingGroupFile`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `RecordingGroupFile` that was deleted by this mutation. */
  recordingGroupFile: Maybe<RecordingGroupFile>;
  /** An edge for our `RecordingGroupFile`. May be used by Relay 1. */
  recordingGroupFileEdge: Maybe<RecordingGroupFilesEdge>;
  /** Reads a single `Tenant` that is related to this `RecordingGroupFile`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `RecordingGroupFile` mutation. */
export type DeleteRecordingGroupFilePayloadRecordingGroupFileEdgeArgs = {
  orderBy?: InputMaybe<Array<RecordingGroupFilesOrderBy>>;
};

/** All input for the `deleteRunById` mutation. */
export type DeleteRunByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `Run` mutation. */
export type DeleteRunPayload = {
  __typename: "DeleteRunPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedRunId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `Run` that was deleted by this mutation. */
  run: Maybe<Run>;
  /** An edge for our `Run`. May be used by Relay 1. */
  runEdge: Maybe<RunsEdge>;
  /** Reads a single `Tenant` that is related to this `Run`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `Run` mutation. */
export type DeleteRunPayloadRunEdgeArgs = {
  orderBy?: InputMaybe<Array<RunsOrderBy>>;
};

/** All input for the `deleteSession` mutation. */
export type DeleteSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The output of our `deleteSession` mutation. */
export type DeleteSessionPayload = {
  __typename: "DeleteSessionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `deleteTagById` mutation. */
export type DeleteTagByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** The output of our delete `Tag` mutation. */
export type DeleteTagPayload = {
  __typename: "DeleteTagPayload";
  /** Reads a single `ApplicationUser` that is related to this `Tag`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedTagId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `Tag` that was deleted by this mutation. */
  tag: Maybe<Tag>;
  /** Reads a single `Tag` that is related to this `Tag`. */
  tagByParentId: Maybe<Tag>;
  /** An edge for our `Tag`. May be used by Relay 1. */
  tagEdge: Maybe<TagsEdge>;
  /** Reads a single `Tenant` that is related to this `Tag`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our delete `Tag` mutation. */
export type DeleteTagPayloadTagEdgeArgs = {
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
};

/** All input for the `deleteTaskActivityById` mutation. */
export type DeleteTaskActivityByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteTaskActivityByTaskId` mutation. */
export type DeleteTaskActivityByTaskIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  taskId: Scalars["UUID"]["input"];
};

/** The output of our delete `TaskActivity` mutation. */
export type DeleteTaskActivityPayload = {
  __typename: "DeleteTaskActivityPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Cost` that is related to this `TaskActivity`. */
  costByCostId: Maybe<Cost>;
  deletedTaskActivityId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TaskActivity` that was deleted by this mutation. */
  taskActivity: Maybe<TaskActivity>;
  /** An edge for our `TaskActivity`. May be used by Relay 1. */
  taskActivityEdge: Maybe<TaskActivitiesEdge>;
  /** Reads a single `Task` that is related to this `TaskActivity`. */
  taskByTaskId: Maybe<Task>;
};

/** The output of our delete `TaskActivity` mutation. */
export type DeleteTaskActivityPayloadTaskActivityEdgeArgs = {
  orderBy?: InputMaybe<Array<TaskActivitiesOrderBy>>;
};

/** All input for the `deleteTaskByExecutorId` mutation. */
export type DeleteTaskByExecutorIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  executorId: Scalars["UUID"]["input"];
};

/** All input for the `deleteTaskById` mutation. */
export type DeleteTaskByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `Task` mutation. */
export type DeleteTaskPayload = {
  __typename: "DeleteTaskPayload";
  /** Reads a single `ApplicationUser` that is related to this `Task`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `ContainerImage` that is related to this `Task`. */
  containerImageByContainerImageId: Maybe<ContainerImage>;
  /** Reads a single `Dataset` that is related to this `Task`. */
  datasetByDatasetId: Maybe<Dataset>;
  deletedTaskId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `Project` that is related to this `Task`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `Task` that was deleted by this mutation. */
  task: Maybe<Task>;
  /** An edge for our `Task`. May be used by Relay 1. */
  taskEdge: Maybe<TasksEdge>;
  /** Reads a single `Tenant` that is related to this `Task`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `TesTaskexecutor` that is related to this `Task`. */
  tesTaskexecutorByExecutorId: Maybe<TesTaskexecutor>;
  /** Reads a single `ToolVersion` that is related to this `Task`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our delete `Task` mutation. */
export type DeleteTaskPayloadTaskEdgeArgs = {
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
};

/** All input for the `deleteTaskResourceById` mutation. */
export type DeleteTaskResourceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `TaskResource` mutation. */
export type DeleteTaskResourcePayload = {
  __typename: "DeleteTaskResourcePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedTaskResourceId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Task` that is related to this `TaskResource`. */
  taskByTaskId: Maybe<Task>;
  /** The `TaskResource` that was deleted by this mutation. */
  taskResource: Maybe<TaskResource>;
  /** An edge for our `TaskResource`. May be used by Relay 1. */
  taskResourceEdge: Maybe<TaskResourcesEdge>;
};

/** The output of our delete `TaskResource` mutation. */
export type DeleteTaskResourcePayloadTaskResourceEdgeArgs = {
  orderBy?: InputMaybe<Array<TaskResourcesOrderBy>>;
};

/** All input for the `deleteTaskSourceById` mutation. */
export type DeleteTaskSourceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `TaskSource` mutation. */
export type DeleteTaskSourcePayload = {
  __typename: "DeleteTaskSourcePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedTaskSourceId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `File` that is related to this `TaskSource`. */
  fileByFileId: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Task` that is related to this `TaskSource`. */
  taskByTaskId: Maybe<Task>;
  /** The `TaskSource` that was deleted by this mutation. */
  taskSource: Maybe<TaskSource>;
  /** An edge for our `TaskSource`. May be used by Relay 1. */
  taskSourceEdge: Maybe<TaskSourcesEdge>;
  /** Reads a single `ToolSource` that is related to this `TaskSource`. */
  toolSourceByToolSourceId: Maybe<ToolSource>;
};

/** The output of our delete `TaskSource` mutation. */
export type DeleteTaskSourcePayloadTaskSourceEdgeArgs = {
  orderBy?: InputMaybe<Array<TaskSourcesOrderBy>>;
};

/** All input for the `deleteTaskStatusLogById` mutation. */
export type DeleteTaskStatusLogByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteTaskStatusLogByTaskIdAndStatus` mutation. */
export type DeleteTaskStatusLogByTaskIdAndStatusInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  status: Scalars["Int"]["input"];
  taskId: Scalars["UUID"]["input"];
};

/** The output of our delete `TaskStatusLog` mutation. */
export type DeleteTaskStatusLogPayload = {
  __typename: "DeleteTaskStatusLogPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedTaskStatusLogId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Task` that is related to this `TaskStatusLog`. */
  taskByTaskId: Maybe<Task>;
  /** The `TaskStatusLog` that was deleted by this mutation. */
  taskStatusLog: Maybe<TaskStatusLog>;
  /** An edge for our `TaskStatusLog`. May be used by Relay 1. */
  taskStatusLogEdge: Maybe<TaskStatusLogsEdge>;
};

/** The output of our delete `TaskStatusLog` mutation. */
export type DeleteTaskStatusLogPayloadTaskStatusLogEdgeArgs = {
  orderBy?: InputMaybe<Array<TaskStatusLogsOrderBy>>;
};

/** All input for the `deleteTenantById` mutation. */
export type DeleteTenantByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteTenantByKey` mutation. */
export type DeleteTenantByKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
};

/** All input for the `deleteTenantByName` mutation. */
export type DeleteTenantByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

/** All input for the `deleteTenantContractAllowanceById` mutation. */
export type DeleteTenantContractAllowanceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteTenantContractAllowanceByTenantContractTransactionIdAndQuotaType` mutation. */
export type DeleteTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
    quotaType: Scalars["Int"]["input"];
    tenantContractTransactionId: Scalars["Int"]["input"];
  };

/** The output of our delete `TenantContractAllowance` mutation. */
export type DeleteTenantContractAllowancePayload = {
  __typename: "DeleteTenantContractAllowancePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedTenantContractAllowanceId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TenantContractAllowance` that was deleted by this mutation. */
  tenantContractAllowance: Maybe<TenantContractAllowance>;
  /** An edge for our `TenantContractAllowance`. May be used by Relay 1. */
  tenantContractAllowanceEdge: Maybe<TenantContractAllowancesEdge>;
  /** Reads a single `TenantContractTransaction` that is related to this `TenantContractAllowance`. */
  tenantContractTransactionByTenantContractTransactionId: Maybe<TenantContractTransaction>;
};

/** The output of our delete `TenantContractAllowance` mutation. */
export type DeleteTenantContractAllowancePayloadTenantContractAllowanceEdgeArgs =
  {
    orderBy?: InputMaybe<Array<TenantContractAllowancesOrderBy>>;
  };

/** All input for the `deleteTenantContractById` mutation. */
export type DeleteTenantContractByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteTenantContractByName` mutation. */
export type DeleteTenantContractByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

/** The output of our delete `TenantContract` mutation. */
export type DeleteTenantContractPayload = {
  __typename: "DeleteTenantContractPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedTenantContractId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `TenantContract`. */
  tenantByTenantId: Maybe<Tenant>;
  /** The `TenantContract` that was deleted by this mutation. */
  tenantContract: Maybe<TenantContract>;
  /** An edge for our `TenantContract`. May be used by Relay 1. */
  tenantContractEdge: Maybe<TenantContractsEdge>;
};

/** The output of our delete `TenantContract` mutation. */
export type DeleteTenantContractPayloadTenantContractEdgeArgs = {
  orderBy?: InputMaybe<Array<TenantContractsOrderBy>>;
};

/** All input for the `deleteTenantContractTransactionById` mutation. */
export type DeleteTenantContractTransactionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `TenantContractTransaction` mutation. */
export type DeleteTenantContractTransactionPayload = {
  __typename: "DeleteTenantContractTransactionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedTenantContractTransactionId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `TenantContract` that is related to this `TenantContractTransaction`. */
  tenantContractByTenantContractId: Maybe<TenantContract>;
  /** The `TenantContractTransaction` that was deleted by this mutation. */
  tenantContractTransaction: Maybe<TenantContractTransaction>;
  /** An edge for our `TenantContractTransaction`. May be used by Relay 1. */
  tenantContractTransactionEdge: Maybe<TenantContractTransactionsEdge>;
};

/** The output of our delete `TenantContractTransaction` mutation. */
export type DeleteTenantContractTransactionPayloadTenantContractTransactionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<TenantContractTransactionsOrderBy>>;
  };

/** All input for the `deleteTenantMockuserexecutorByUserexecutorPtrId` mutation. */
export type DeleteTenantMockuserexecutorByUserexecutorPtrIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  userexecutorPtrId: Scalars["Int"]["input"];
};

/** The output of our delete `TenantMockuserexecutor` mutation. */
export type DeleteTenantMockuserexecutorPayload = {
  __typename: "DeleteTenantMockuserexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedTenantMockuserexecutorId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TenantMockuserexecutor` that was deleted by this mutation. */
  tenantMockuserexecutor: Maybe<TenantMockuserexecutor>;
  /** An edge for our `TenantMockuserexecutor`. May be used by Relay 1. */
  tenantMockuserexecutorEdge: Maybe<TenantMockuserexecutorsEdge>;
  /** Reads a single `TenantUserexecutor` that is related to this `TenantMockuserexecutor`. */
  tenantUserexecutorByUserexecutorPtrId: Maybe<TenantUserexecutor>;
};

/** The output of our delete `TenantMockuserexecutor` mutation. */
export type DeleteTenantMockuserexecutorPayloadTenantMockuserexecutorEdgeArgs =
  {
    orderBy?: InputMaybe<Array<TenantMockuserexecutorsOrderBy>>;
  };

/** All input for the `deleteTenantMonthlyConsumedResourceById` mutation. */
export type DeleteTenantMonthlyConsumedResourceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract` mutation. */
export type DeleteTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
    month: Scalars["Date"]["input"];
    monthInActiveContract: Scalars["Int"]["input"];
    quotaType: Scalars["Int"]["input"];
    tenantContractId: Scalars["Int"]["input"];
    tenantId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/** The output of our delete `TenantMonthlyConsumedResource` mutation. */
export type DeleteTenantMonthlyConsumedResourcePayload = {
  __typename: "DeleteTenantMonthlyConsumedResourcePayload";
  /** Reads a single `ApplicationUser` that is related to this `TenantMonthlyConsumedResource`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedTenantMonthlyConsumedResourceId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `TenantMonthlyConsumedResource`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `TenantContract` that is related to this `TenantMonthlyConsumedResource`. */
  tenantContractByTenantContractId: Maybe<TenantContract>;
  /** The `TenantMonthlyConsumedResource` that was deleted by this mutation. */
  tenantMonthlyConsumedResource: Maybe<TenantMonthlyConsumedResource>;
  /** An edge for our `TenantMonthlyConsumedResource`. May be used by Relay 1. */
  tenantMonthlyConsumedResourceEdge: Maybe<TenantMonthlyConsumedResourcesEdge>;
};

/** The output of our delete `TenantMonthlyConsumedResource` mutation. */
export type DeleteTenantMonthlyConsumedResourcePayloadTenantMonthlyConsumedResourceEdgeArgs =
  {
    orderBy?: InputMaybe<Array<TenantMonthlyConsumedResourcesOrderBy>>;
  };

/** The output of our delete `Tenant` mutation. */
export type DeleteTenantPayload = {
  __typename: "DeleteTenantPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedTenantId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `Tenant` that was deleted by this mutation. */
  tenant: Maybe<Tenant>;
  /** An edge for our `Tenant`. May be used by Relay 1. */
  tenantEdge: Maybe<TenantsEdge>;
};

/** The output of our delete `Tenant` mutation. */
export type DeleteTenantPayloadTenantEdgeArgs = {
  orderBy?: InputMaybe<Array<TenantsOrderBy>>;
};

/** All input for the `deleteTenantUserexecutorById` mutation. */
export type DeleteTenantUserexecutorByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `TenantUserexecutor` mutation. */
export type DeleteTenantUserexecutorPayload = {
  __typename: "DeleteTenantUserexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedTenantUserexecutorId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `DjangoContentType` that is related to this `TenantUserexecutor`. */
  djangoContentTypeByPolymorphicCtypeId: Maybe<DjangoContentType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TenantUserexecutor` that was deleted by this mutation. */
  tenantUserexecutor: Maybe<TenantUserexecutor>;
  /** An edge for our `TenantUserexecutor`. May be used by Relay 1. */
  tenantUserexecutorEdge: Maybe<TenantUserexecutorsEdge>;
};

/** The output of our delete `TenantUserexecutor` mutation. */
export type DeleteTenantUserexecutorPayloadTenantUserexecutorEdgeArgs = {
  orderBy?: InputMaybe<Array<TenantUserexecutorsOrderBy>>;
};

/** All input for the `deleteTesContainerimageexecutorById` mutation. */
export type DeleteTesContainerimageexecutorByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `TesContainerimageexecutor` mutation. */
export type DeleteTesContainerimageexecutorPayload = {
  __typename: "DeleteTesContainerimageexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedTesContainerimageexecutorId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `DjangoContentType` that is related to this `TesContainerimageexecutor`. */
  djangoContentTypeByPolymorphicCtypeId: Maybe<DjangoContentType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TesContainerimageexecutor` that was deleted by this mutation. */
  tesContainerimageexecutor: Maybe<TesContainerimageexecutor>;
  /** An edge for our `TesContainerimageexecutor`. May be used by Relay 1. */
  tesContainerimageexecutorEdge: Maybe<TesContainerimageexecutorsEdge>;
};

/** The output of our delete `TesContainerimageexecutor` mutation. */
export type DeleteTesContainerimageexecutorPayloadTesContainerimageexecutorEdgeArgs =
  {
    orderBy?: InputMaybe<Array<TesContainerimageexecutorsOrderBy>>;
  };

/** All input for the `deleteTesMockcontainerimageexecutorByContainerimageexecutorPtrId` mutation. */
export type DeleteTesMockcontainerimageexecutorByContainerimageexecutorPtrIdInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
    containerimageexecutorPtrId: Scalars["Int"]["input"];
  };

/** The output of our delete `TesMockcontainerimageexecutor` mutation. */
export type DeleteTesMockcontainerimageexecutorPayload = {
  __typename: "DeleteTesMockcontainerimageexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedTesMockcontainerimageexecutorId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `TesContainerimageexecutor` that is related to this `TesMockcontainerimageexecutor`. */
  tesContainerimageexecutorByContainerimageexecutorPtrId: Maybe<TesContainerimageexecutor>;
  /** The `TesMockcontainerimageexecutor` that was deleted by this mutation. */
  tesMockcontainerimageexecutor: Maybe<TesMockcontainerimageexecutor>;
  /** An edge for our `TesMockcontainerimageexecutor`. May be used by Relay 1. */
  tesMockcontainerimageexecutorEdge: Maybe<TesMockcontainerimageexecutorsEdge>;
};

/** The output of our delete `TesMockcontainerimageexecutor` mutation. */
export type DeleteTesMockcontainerimageexecutorPayloadTesMockcontainerimageexecutorEdgeArgs =
  {
    orderBy?: InputMaybe<Array<TesMockcontainerimageexecutorsOrderBy>>;
  };

/** All input for the `deleteTesMocktaskexecutorByTaskexecutorPtrId` mutation. */
export type DeleteTesMocktaskexecutorByTaskexecutorPtrIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  taskexecutorPtrId: Scalars["UUID"]["input"];
};

/** The output of our delete `TesMocktaskexecutor` mutation. */
export type DeleteTesMocktaskexecutorPayload = {
  __typename: "DeleteTesMocktaskexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedTesMocktaskexecutorId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TesMocktaskexecutor` that was deleted by this mutation. */
  tesMocktaskexecutor: Maybe<TesMocktaskexecutor>;
  /** An edge for our `TesMocktaskexecutor`. May be used by Relay 1. */
  tesMocktaskexecutorEdge: Maybe<TesMocktaskexecutorsEdge>;
  /** Reads a single `TesTaskexecutor` that is related to this `TesMocktaskexecutor`. */
  tesTaskexecutorByTaskexecutorPtrId: Maybe<TesTaskexecutor>;
};

/** The output of our delete `TesMocktaskexecutor` mutation. */
export type DeleteTesMocktaskexecutorPayloadTesMocktaskexecutorEdgeArgs = {
  orderBy?: InputMaybe<Array<TesMocktaskexecutorsOrderBy>>;
};

/** All input for the `deleteTesTaskexecutorById` mutation. */
export type DeleteTesTaskexecutorByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `TesTaskexecutor` mutation. */
export type DeleteTesTaskexecutorPayload = {
  __typename: "DeleteTesTaskexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedTesTaskexecutorId: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `DjangoContentType` that is related to this `TesTaskexecutor`. */
  djangoContentTypeByPolymorphicCtypeId: Maybe<DjangoContentType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TesTaskexecutor` that was deleted by this mutation. */
  tesTaskexecutor: Maybe<TesTaskexecutor>;
  /** An edge for our `TesTaskexecutor`. May be used by Relay 1. */
  tesTaskexecutorEdge: Maybe<TesTaskexecutorsEdge>;
};

/** The output of our delete `TesTaskexecutor` mutation. */
export type DeleteTesTaskexecutorPayloadTesTaskexecutorEdgeArgs = {
  orderBy?: InputMaybe<Array<TesTaskexecutorsOrderBy>>;
};

/** All input for the `deleteToolById` mutation. */
export type DeleteToolByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteToolByKey` mutation. */
export type DeleteToolByKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
};

/** All input for the `deleteToolCategoryById` mutation. */
export type DeleteToolCategoryByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteToolCategoryByKey` mutation. */
export type DeleteToolCategoryByKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
};

/** The output of our delete `ToolCategory` mutation. */
export type DeleteToolCategoryPayload = {
  __typename: "DeleteToolCategoryPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedToolCategoryId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolCategory` that was deleted by this mutation. */
  toolCategory: Maybe<ToolCategory>;
  /** An edge for our `ToolCategory`. May be used by Relay 1. */
  toolCategoryEdge: Maybe<ToolCategoriesEdge>;
};

/** The output of our delete `ToolCategory` mutation. */
export type DeleteToolCategoryPayloadToolCategoryEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolCategoriesOrderBy>>;
};

/** All input for the `deleteToolDisplayCategoryById` mutation. */
export type DeleteToolDisplayCategoryByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteToolDisplayCategoryByToolIdAndToolcategoryId` mutation. */
export type DeleteToolDisplayCategoryByToolIdAndToolcategoryIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  toolId: Scalars["Int"]["input"];
  toolcategoryId: Scalars["Int"]["input"];
};

/** The output of our delete `ToolDisplayCategory` mutation. */
export type DeleteToolDisplayCategoryPayload = {
  __typename: "DeleteToolDisplayCategoryPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedToolDisplayCategoryId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tool` that is related to this `ToolDisplayCategory`. */
  toolByToolId: Maybe<Tool>;
  /** Reads a single `ToolCategory` that is related to this `ToolDisplayCategory`. */
  toolCategoryByToolcategoryId: Maybe<ToolCategory>;
  /** The `ToolDisplayCategory` that was deleted by this mutation. */
  toolDisplayCategory: Maybe<ToolDisplayCategory>;
  /** An edge for our `ToolDisplayCategory`. May be used by Relay 1. */
  toolDisplayCategoryEdge: Maybe<ToolDisplayCategoriesEdge>;
};

/** The output of our delete `ToolDisplayCategory` mutation. */
export type DeleteToolDisplayCategoryPayloadToolDisplayCategoryEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolDisplayCategoriesOrderBy>>;
};

/** All input for the `deleteToolParameterById` mutation. */
export type DeleteToolParameterByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `ToolParameter` mutation. */
export type DeleteToolParameterPayload = {
  __typename: "DeleteToolParameterPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedToolParameterId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolParameter` that was deleted by this mutation. */
  toolParameter: Maybe<ToolParameter>;
  /** An edge for our `ToolParameter`. May be used by Relay 1. */
  toolParameterEdge: Maybe<ToolParametersEdge>;
  /** Reads a single `ToolVersion` that is related to this `ToolParameter`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our delete `ToolParameter` mutation. */
export type DeleteToolParameterPayloadToolParameterEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolParametersOrderBy>>;
};

/** The output of our delete `Tool` mutation. */
export type DeleteToolPayload = {
  __typename: "DeleteToolPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedToolId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `Tool` that was deleted by this mutation. */
  tool: Maybe<Tool>;
  /** An edge for our `Tool`. May be used by Relay 1. */
  toolEdge: Maybe<ToolsEdge>;
};

/** The output of our delete `Tool` mutation. */
export type DeleteToolPayloadToolEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolsOrderBy>>;
};

/** All input for the `deleteToolResourceById` mutation. */
export type DeleteToolResourceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteToolResourceByTypeAndToolVersionId` mutation. */
export type DeleteToolResourceByTypeAndToolVersionIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  toolVersionId: Scalars["Int"]["input"];
  type: Scalars["Int"]["input"];
};

/** The output of our delete `ToolResource` mutation. */
export type DeleteToolResourcePayload = {
  __typename: "DeleteToolResourcePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedToolResourceId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolResource` that was deleted by this mutation. */
  toolResource: Maybe<ToolResource>;
  /** An edge for our `ToolResource`. May be used by Relay 1. */
  toolResourceEdge: Maybe<ToolResourcesEdge>;
  /** Reads a single `ToolVersion` that is related to this `ToolResource`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our delete `ToolResource` mutation. */
export type DeleteToolResourcePayloadToolResourceEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolResourcesOrderBy>>;
};

/** All input for the `deleteToolResultById` mutation. */
export type DeleteToolResultByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `ToolResult` mutation. */
export type DeleteToolResultPayload = {
  __typename: "DeleteToolResultPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedToolResultId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolResult` that was deleted by this mutation. */
  toolResult: Maybe<ToolResult>;
  /** An edge for our `ToolResult`. May be used by Relay 1. */
  toolResultEdge: Maybe<ToolResultsEdge>;
  /** Reads a single `ToolVersion` that is related to this `ToolResult`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our delete `ToolResult` mutation. */
export type DeleteToolResultPayloadToolResultEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolResultsOrderBy>>;
};

/** All input for the `deleteToolSecretById` mutation. */
export type DeleteToolSecretByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `ToolSecret` mutation. */
export type DeleteToolSecretPayload = {
  __typename: "DeleteToolSecretPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedToolSecretId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolSecret` that was deleted by this mutation. */
  toolSecret: Maybe<ToolSecret>;
  /** An edge for our `ToolSecret`. May be used by Relay 1. */
  toolSecretEdge: Maybe<ToolSecretsEdge>;
  /** Reads a single `ToolVersion` that is related to this `ToolSecret`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our delete `ToolSecret` mutation. */
export type DeleteToolSecretPayloadToolSecretEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolSecretsOrderBy>>;
};

/** All input for the `deleteToolSourceById` mutation. */
export type DeleteToolSourceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `ToolSource` mutation. */
export type DeleteToolSourcePayload = {
  __typename: "DeleteToolSourcePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedToolSourceId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolSource` that was deleted by this mutation. */
  toolSource: Maybe<ToolSource>;
  /** An edge for our `ToolSource`. May be used by Relay 1. */
  toolSourceEdge: Maybe<ToolSourcesEdge>;
  /** Reads a single `ToolVersion` that is related to this `ToolSource`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our delete `ToolSource` mutation. */
export type DeleteToolSourcePayloadToolSourceEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolSourcesOrderBy>>;
};

/** All input for the `deleteToolTenantById` mutation. */
export type DeleteToolTenantByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteToolTenantByToolIdAndTenantId` mutation. */
export type DeleteToolTenantByToolIdAndTenantIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  tenantId: Scalars["Int"]["input"];
  toolId: Scalars["Int"]["input"];
};

/** The output of our delete `ToolTenant` mutation. */
export type DeleteToolTenantPayload = {
  __typename: "DeleteToolTenantPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedToolTenantId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `ToolTenant`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `Tool` that is related to this `ToolTenant`. */
  toolByToolId: Maybe<Tool>;
  /** The `ToolTenant` that was deleted by this mutation. */
  toolTenant: Maybe<ToolTenant>;
  /** An edge for our `ToolTenant`. May be used by Relay 1. */
  toolTenantEdge: Maybe<ToolTenantsEdge>;
};

/** The output of our delete `ToolTenant` mutation. */
export type DeleteToolTenantPayloadToolTenantEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolTenantsOrderBy>>;
};

/** All input for the `deleteToolVersionById` mutation. */
export type DeleteToolVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteToolVersionByToolIdAndVersion` mutation. */
export type DeleteToolVersionByToolIdAndVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  toolId: Scalars["Int"]["input"];
  version: Scalars["String"]["input"];
};

/** The output of our delete `ToolVersion` mutation. */
export type DeleteToolVersionPayload = {
  __typename: "DeleteToolVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedToolVersionId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tool` that is related to this `ToolVersion`. */
  toolByToolId: Maybe<Tool>;
  /** The `ToolVersion` that was deleted by this mutation. */
  toolVersion: Maybe<ToolVersion>;
  /** An edge for our `ToolVersion`. May be used by Relay 1. */
  toolVersionEdge: Maybe<ToolVersionsEdge>;
};

/** The output of our delete `ToolVersion` mutation. */
export type DeleteToolVersionPayloadToolVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolVersionsOrderBy>>;
};

/** All input for the `deleteToolboxById` mutation. */
export type DeleteToolboxByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteToolboxByKey` mutation. */
export type DeleteToolboxByKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
};

/** All input for the `deleteToolboxExitcodeByCode` mutation. */
export type DeleteToolboxExitcodeByCodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
};

/** All input for the `deleteToolboxExitcodeById` mutation. */
export type DeleteToolboxExitcodeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `ToolboxExitcode` mutation. */
export type DeleteToolboxExitcodePayload = {
  __typename: "DeleteToolboxExitcodePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedToolboxExitcodeId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolboxExitcode` that was deleted by this mutation. */
  toolboxExitcode: Maybe<ToolboxExitcode>;
  /** An edge for our `ToolboxExitcode`. May be used by Relay 1. */
  toolboxExitcodeEdge: Maybe<ToolboxExitcodesEdge>;
};

/** The output of our delete `ToolboxExitcode` mutation. */
export type DeleteToolboxExitcodePayloadToolboxExitcodeEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolboxExitcodesOrderBy>>;
};

/** The output of our delete `Toolbox` mutation. */
export type DeleteToolboxPayload = {
  __typename: "DeleteToolboxPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedToolboxId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `Toolbox` that was deleted by this mutation. */
  toolbox: Maybe<Toolbox>;
  /** An edge for our `Toolbox`. May be used by Relay 1. */
  toolboxEdge: Maybe<ToolboxesEdge>;
};

/** The output of our delete `Toolbox` mutation. */
export type DeleteToolboxPayloadToolboxEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolboxesOrderBy>>;
};

/** All input for the `deleteToolboxTenantById` mutation. */
export type DeleteToolboxTenantByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteToolboxTenantByToolboxIdAndTenantId` mutation. */
export type DeleteToolboxTenantByToolboxIdAndTenantIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  tenantId: Scalars["Int"]["input"];
  toolboxId: Scalars["Int"]["input"];
};

/** The output of our delete `ToolboxTenant` mutation. */
export type DeleteToolboxTenantPayload = {
  __typename: "DeleteToolboxTenantPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedToolboxTenantId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `ToolboxTenant`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `Toolbox` that is related to this `ToolboxTenant`. */
  toolboxByToolboxId: Maybe<Toolbox>;
  /** The `ToolboxTenant` that was deleted by this mutation. */
  toolboxTenant: Maybe<ToolboxTenant>;
  /** An edge for our `ToolboxTenant`. May be used by Relay 1. */
  toolboxTenantEdge: Maybe<ToolboxTenantsEdge>;
};

/** The output of our delete `ToolboxTenant` mutation. */
export type DeleteToolboxTenantPayloadToolboxTenantEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolboxTenantsOrderBy>>;
};

/** All input for the `deleteToolboxVersionById` mutation. */
export type DeleteToolboxVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteToolboxVersionByToolboxIdAndVersion` mutation. */
export type DeleteToolboxVersionByToolboxIdAndVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  toolboxId: Scalars["Int"]["input"];
  version: Scalars["String"]["input"];
};

/** All input for the `deleteToolboxVersionExitcodeById` mutation. */
export type DeleteToolboxVersionExitcodeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeId` mutation. */
export type DeleteToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
    toolboxexitcodeId: Scalars["Int"]["input"];
    toolboxversionId: Scalars["Int"]["input"];
  };

/** The output of our delete `ToolboxVersionExitcode` mutation. */
export type DeleteToolboxVersionExitcodePayload = {
  __typename: "DeleteToolboxVersionExitcodePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedToolboxVersionExitcodeId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ToolboxExitcode` that is related to this `ToolboxVersionExitcode`. */
  toolboxExitcodeByToolboxexitcodeId: Maybe<ToolboxExitcode>;
  /** Reads a single `ToolboxVersion` that is related to this `ToolboxVersionExitcode`. */
  toolboxVersionByToolboxversionId: Maybe<ToolboxVersion>;
  /** The `ToolboxVersionExitcode` that was deleted by this mutation. */
  toolboxVersionExitcode: Maybe<ToolboxVersionExitcode>;
  /** An edge for our `ToolboxVersionExitcode`. May be used by Relay 1. */
  toolboxVersionExitcodeEdge: Maybe<ToolboxVersionExitcodesEdge>;
};

/** The output of our delete `ToolboxVersionExitcode` mutation. */
export type DeleteToolboxVersionExitcodePayloadToolboxVersionExitcodeEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ToolboxVersionExitcodesOrderBy>>;
  };

/** The output of our delete `ToolboxVersion` mutation. */
export type DeleteToolboxVersionPayload = {
  __typename: "DeleteToolboxVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedToolboxVersionId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Toolbox` that is related to this `ToolboxVersion`. */
  toolboxByToolboxId: Maybe<Toolbox>;
  /** The `ToolboxVersion` that was deleted by this mutation. */
  toolboxVersion: Maybe<ToolboxVersion>;
  /** An edge for our `ToolboxVersion`. May be used by Relay 1. */
  toolboxVersionEdge: Maybe<ToolboxVersionsEdge>;
};

/** The output of our delete `ToolboxVersion` mutation. */
export type DeleteToolboxVersionPayloadToolboxVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolboxVersionsOrderBy>>;
};

/** All input for the `deleteToolboxVersionToolVersionById` mutation. */
export type DeleteToolboxVersionToolVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteToolboxVersionToolVersionByToolboxversionIdAndToolversionId` mutation. */
export type DeleteToolboxVersionToolVersionByToolboxversionIdAndToolversionIdInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
    toolboxversionId: Scalars["Int"]["input"];
    toolversionId: Scalars["Int"]["input"];
  };

/** The output of our delete `ToolboxVersionToolVersion` mutation. */
export type DeleteToolboxVersionToolVersionPayload = {
  __typename: "DeleteToolboxVersionToolVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedToolboxVersionToolVersionId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ToolVersion` that is related to this `ToolboxVersionToolVersion`. */
  toolVersionByToolversionId: Maybe<ToolVersion>;
  /** Reads a single `ToolboxVersion` that is related to this `ToolboxVersionToolVersion`. */
  toolboxVersionByToolboxversionId: Maybe<ToolboxVersion>;
  /** The `ToolboxVersionToolVersion` that was deleted by this mutation. */
  toolboxVersionToolVersion: Maybe<ToolboxVersionToolVersion>;
  /** An edge for our `ToolboxVersionToolVersion`. May be used by Relay 1. */
  toolboxVersionToolVersionEdge: Maybe<ToolboxVersionToolVersionsEdge>;
};

/** The output of our delete `ToolboxVersionToolVersion` mutation. */
export type DeleteToolboxVersionToolVersionPayloadToolboxVersionToolVersionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ToolboxVersionToolVersionsOrderBy>>;
  };

/** All input for the `deleteWorkflowById` mutation. */
export type DeleteWorkflowByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** All input for the `deleteWorkflowDatumById` mutation. */
export type DeleteWorkflowDatumByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `WorkflowDatum` mutation. */
export type DeleteWorkflowDatumPayload = {
  __typename: "DeleteWorkflowDatumPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedWorkflowDatumId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `WorkflowDatum` that was deleted by this mutation. */
  workflowDatum: Maybe<WorkflowDatum>;
  /** An edge for our `WorkflowDatum`. May be used by Relay 1. */
  workflowDatumEdge: Maybe<WorkflowDataEdge>;
};

/** The output of our delete `WorkflowDatum` mutation. */
export type DeleteWorkflowDatumPayloadWorkflowDatumEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowDataOrderBy>>;
};

/** The output of our delete `Workflow` mutation. */
export type DeleteWorkflowPayload = {
  __typename: "DeleteWorkflowPayload";
  /** Reads a single `ApplicationUser` that is related to this `Workflow`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `ContainerImage` that is related to this `Workflow`. */
  containerImageByContainerId: Maybe<ContainerImage>;
  deletedWorkflowId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Workflow`. */
  tenantByTenantId: Maybe<Tenant>;
  /** The `Workflow` that was deleted by this mutation. */
  workflow: Maybe<Workflow>;
  /** An edge for our `Workflow`. May be used by Relay 1. */
  workflowEdge: Maybe<WorkflowsEdge>;
};

/** The output of our delete `Workflow` mutation. */
export type DeleteWorkflowPayloadWorkflowEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowsOrderBy>>;
};

/** All input for the `deleteWorkflowResultById` mutation. */
export type DeleteWorkflowResultByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `WorkflowResult` mutation. */
export type DeleteWorkflowResultPayload = {
  __typename: "DeleteWorkflowResultPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedWorkflowResultId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `WorkflowDatum` that is related to this `WorkflowResult`. */
  workflowDatumByObjectId: Maybe<WorkflowDatum>;
  /** The `WorkflowResult` that was deleted by this mutation. */
  workflowResult: Maybe<WorkflowResult>;
  /** An edge for our `WorkflowResult`. May be used by Relay 1. */
  workflowResultEdge: Maybe<WorkflowResultsEdge>;
};

/** The output of our delete `WorkflowResult` mutation. */
export type DeleteWorkflowResultPayloadWorkflowResultEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowResultsOrderBy>>;
};

/** All input for the `deleteWorkflowResultTagById` mutation. */
export type DeleteWorkflowResultTagByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteWorkflowResultTagByResultIdAndTagId` mutation. */
export type DeleteWorkflowResultTagByResultIdAndTagIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  resultId: Scalars["UUID"]["input"];
  tagId: Scalars["BigInt"]["input"];
};

/** The output of our delete `WorkflowResultTag` mutation. */
export type DeleteWorkflowResultTagPayload = {
  __typename: "DeleteWorkflowResultTagPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedWorkflowResultTagId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tag` that is related to this `WorkflowResultTag`. */
  tagByTagId: Maybe<Tag>;
  /** Reads a single `WorkflowResult` that is related to this `WorkflowResultTag`. */
  workflowResultByResultId: Maybe<WorkflowResult>;
  /** The `WorkflowResultTag` that was deleted by this mutation. */
  workflowResultTag: Maybe<WorkflowResultTag>;
  /** An edge for our `WorkflowResultTag`. May be used by Relay 1. */
  workflowResultTagEdge: Maybe<WorkflowResultTagsEdge>;
};

/** The output of our delete `WorkflowResultTag` mutation. */
export type DeleteWorkflowResultTagPayloadWorkflowResultTagEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowResultTagsOrderBy>>;
};

/** All input for the `deleteWorkflowResultWorkflowVersionById` mutation. */
export type DeleteWorkflowResultWorkflowVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteWorkflowResultWorkflowVersionByResultIdAndWorkflowversionId` mutation. */
export type DeleteWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
    resultId: Scalars["UUID"]["input"];
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The output of our delete `WorkflowResultWorkflowVersion` mutation. */
export type DeleteWorkflowResultWorkflowVersionPayload = {
  __typename: "DeleteWorkflowResultWorkflowVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedWorkflowResultWorkflowVersionId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `WorkflowResult` that is related to this `WorkflowResultWorkflowVersion`. */
  workflowResultByResultId: Maybe<WorkflowResult>;
  /** The `WorkflowResultWorkflowVersion` that was deleted by this mutation. */
  workflowResultWorkflowVersion: Maybe<WorkflowResultWorkflowVersion>;
  /** An edge for our `WorkflowResultWorkflowVersion`. May be used by Relay 1. */
  workflowResultWorkflowVersionEdge: Maybe<WorkflowResultWorkflowVersionsEdge>;
  /** Reads a single `WorkflowVersion` that is related to this `WorkflowResultWorkflowVersion`. */
  workflowVersionByWorkflowversionId: Maybe<WorkflowVersion>;
};

/** The output of our delete `WorkflowResultWorkflowVersion` mutation. */
export type DeleteWorkflowResultWorkflowVersionPayloadWorkflowResultWorkflowVersionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<WorkflowResultWorkflowVersionsOrderBy>>;
  };

/** All input for the `deleteWorkflowSectionBlockById` mutation. */
export type DeleteWorkflowSectionBlockByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `WorkflowSectionBlock` mutation. */
export type DeleteWorkflowSectionBlockPayload = {
  __typename: "DeleteWorkflowSectionBlockPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedWorkflowSectionBlockId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ToolVersion` that is related to this `WorkflowSectionBlock`. */
  toolVersionByToolId: Maybe<ToolVersion>;
  /** Reads a single `WorkflowDatum` that is related to this `WorkflowSectionBlock`. */
  workflowDatumByObjectId: Maybe<WorkflowDatum>;
  /** The `WorkflowSectionBlock` that was deleted by this mutation. */
  workflowSectionBlock: Maybe<WorkflowSectionBlock>;
  /** An edge for our `WorkflowSectionBlock`. May be used by Relay 1. */
  workflowSectionBlockEdge: Maybe<WorkflowSectionBlocksEdge>;
};

/** The output of our delete `WorkflowSectionBlock` mutation. */
export type DeleteWorkflowSectionBlockPayloadWorkflowSectionBlockEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowSectionBlocksOrderBy>>;
};

/** All input for the `deleteWorkflowSectionBlockSectionByBlockIdAndSectionId` mutation. */
export type DeleteWorkflowSectionBlockSectionByBlockIdAndSectionIdInput = {
  blockId: Scalars["UUID"]["input"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  sectionId: Scalars["UUID"]["input"];
};

/** All input for the `deleteWorkflowSectionBlockSectionById` mutation. */
export type DeleteWorkflowSectionBlockSectionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our delete `WorkflowSectionBlockSection` mutation. */
export type DeleteWorkflowSectionBlockSectionPayload = {
  __typename: "DeleteWorkflowSectionBlockSectionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedWorkflowSectionBlockSectionId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `WorkflowSectionBlock` that is related to this `WorkflowSectionBlockSection`. */
  workflowSectionBlockByBlockId: Maybe<WorkflowSectionBlock>;
  /** The `WorkflowSectionBlockSection` that was deleted by this mutation. */
  workflowSectionBlockSection: Maybe<WorkflowSectionBlockSection>;
  /** An edge for our `WorkflowSectionBlockSection`. May be used by Relay 1. */
  workflowSectionBlockSectionEdge: Maybe<WorkflowSectionBlockSectionsEdge>;
  /** Reads a single `WorkflowSection` that is related to this `WorkflowSectionBlockSection`. */
  workflowSectionBySectionId: Maybe<WorkflowSection>;
};

/** The output of our delete `WorkflowSectionBlockSection` mutation. */
export type DeleteWorkflowSectionBlockSectionPayloadWorkflowSectionBlockSectionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<WorkflowSectionBlockSectionsOrderBy>>;
  };

/** All input for the `deleteWorkflowSectionById` mutation. */
export type DeleteWorkflowSectionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `WorkflowSection` mutation. */
export type DeleteWorkflowSectionPayload = {
  __typename: "DeleteWorkflowSectionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedWorkflowSectionId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `WorkflowSection` that was deleted by this mutation. */
  workflowSection: Maybe<WorkflowSection>;
  /** An edge for our `WorkflowSection`. May be used by Relay 1. */
  workflowSectionEdge: Maybe<WorkflowSectionsEdge>;
};

/** The output of our delete `WorkflowSection` mutation. */
export type DeleteWorkflowSectionPayloadWorkflowSectionEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowSectionsOrderBy>>;
};

/** All input for the `deleteWorkflowSectionWorkflowVersionById` mutation. */
export type DeleteWorkflowSectionWorkflowVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionId` mutation. */
export type DeleteWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
    sectionId: Scalars["UUID"]["input"];
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The output of our delete `WorkflowSectionWorkflowVersion` mutation. */
export type DeleteWorkflowSectionWorkflowVersionPayload = {
  __typename: "DeleteWorkflowSectionWorkflowVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedWorkflowSectionWorkflowVersionId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `WorkflowSection` that is related to this `WorkflowSectionWorkflowVersion`. */
  workflowSectionBySectionId: Maybe<WorkflowSection>;
  /** The `WorkflowSectionWorkflowVersion` that was deleted by this mutation. */
  workflowSectionWorkflowVersion: Maybe<WorkflowSectionWorkflowVersion>;
  /** An edge for our `WorkflowSectionWorkflowVersion`. May be used by Relay 1. */
  workflowSectionWorkflowVersionEdge: Maybe<WorkflowSectionWorkflowVersionsEdge>;
  /** Reads a single `WorkflowVersion` that is related to this `WorkflowSectionWorkflowVersion`. */
  workflowVersionByWorkflowversionId: Maybe<WorkflowVersion>;
};

/** The output of our delete `WorkflowSectionWorkflowVersion` mutation. */
export type DeleteWorkflowSectionWorkflowVersionPayloadWorkflowSectionWorkflowVersionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<WorkflowSectionWorkflowVersionsOrderBy>>;
  };

/** All input for the `deleteWorkflowSourceById` mutation. */
export type DeleteWorkflowSourceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `WorkflowSource` mutation. */
export type DeleteWorkflowSourcePayload = {
  __typename: "DeleteWorkflowSourcePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedWorkflowSourceId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `WorkflowSource` that was deleted by this mutation. */
  workflowSource: Maybe<WorkflowSource>;
  /** An edge for our `WorkflowSource`. May be used by Relay 1. */
  workflowSourceEdge: Maybe<WorkflowSourcesEdge>;
};

/** The output of our delete `WorkflowSource` mutation. */
export type DeleteWorkflowSourcePayloadWorkflowSourceEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowSourcesOrderBy>>;
};

/** All input for the `deleteWorkflowSourceTagById` mutation. */
export type DeleteWorkflowSourceTagByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteWorkflowSourceTagBySourceIdAndTagId` mutation. */
export type DeleteWorkflowSourceTagBySourceIdAndTagIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  sourceId: Scalars["UUID"]["input"];
  tagId: Scalars["BigInt"]["input"];
};

/** The output of our delete `WorkflowSourceTag` mutation. */
export type DeleteWorkflowSourceTagPayload = {
  __typename: "DeleteWorkflowSourceTagPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedWorkflowSourceTagId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tag` that is related to this `WorkflowSourceTag`. */
  tagByTagId: Maybe<Tag>;
  /** Reads a single `WorkflowSource` that is related to this `WorkflowSourceTag`. */
  workflowSourceBySourceId: Maybe<WorkflowSource>;
  /** The `WorkflowSourceTag` that was deleted by this mutation. */
  workflowSourceTag: Maybe<WorkflowSourceTag>;
  /** An edge for our `WorkflowSourceTag`. May be used by Relay 1. */
  workflowSourceTagEdge: Maybe<WorkflowSourceTagsEdge>;
};

/** The output of our delete `WorkflowSourceTag` mutation. */
export type DeleteWorkflowSourceTagPayloadWorkflowSourceTagEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowSourceTagsOrderBy>>;
};

/** All input for the `deleteWorkflowSourceWorkflowVersionById` mutation. */
export type DeleteWorkflowSourceWorkflowVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `deleteWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionId` mutation. */
export type DeleteWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
    sourceId: Scalars["UUID"]["input"];
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The output of our delete `WorkflowSourceWorkflowVersion` mutation. */
export type DeleteWorkflowSourceWorkflowVersionPayload = {
  __typename: "DeleteWorkflowSourceWorkflowVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedWorkflowSourceWorkflowVersionId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `WorkflowSource` that is related to this `WorkflowSourceWorkflowVersion`. */
  workflowSourceBySourceId: Maybe<WorkflowSource>;
  /** The `WorkflowSourceWorkflowVersion` that was deleted by this mutation. */
  workflowSourceWorkflowVersion: Maybe<WorkflowSourceWorkflowVersion>;
  /** An edge for our `WorkflowSourceWorkflowVersion`. May be used by Relay 1. */
  workflowSourceWorkflowVersionEdge: Maybe<WorkflowSourceWorkflowVersionsEdge>;
  /** Reads a single `WorkflowVersion` that is related to this `WorkflowSourceWorkflowVersion`. */
  workflowVersionByWorkflowversionId: Maybe<WorkflowVersion>;
};

/** The output of our delete `WorkflowSourceWorkflowVersion` mutation. */
export type DeleteWorkflowSourceWorkflowVersionPayloadWorkflowSourceWorkflowVersionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<WorkflowSourceWorkflowVersionsOrderBy>>;
  };

/** All input for the `deleteWorkflowVersionById` mutation. */
export type DeleteWorkflowVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `WorkflowVersion` mutation. */
export type DeleteWorkflowVersionPayload = {
  __typename: "DeleteWorkflowVersionPayload";
  /** Reads a single `ApplicationUser` that is related to this `WorkflowVersion`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  deletedWorkflowVersionId: Maybe<Scalars["ID"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Workflow` that is related to this `WorkflowVersion`. */
  workflowByWorkflowId: Maybe<Workflow>;
  /** The `WorkflowVersion` that was deleted by this mutation. */
  workflowVersion: Maybe<WorkflowVersion>;
  /** An edge for our `WorkflowVersion`. May be used by Relay 1. */
  workflowVersionEdge: Maybe<WorkflowVersionsEdge>;
};

/** The output of our delete `WorkflowVersion` mutation. */
export type DeleteWorkflowVersionPayloadWorkflowVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowVersionsOrderBy>>;
};

export type DjangoAdminLog = {
  __typename: "DjangoAdminLog";
  actionFlag: Scalars["Int"]["output"];
  actionTime: Scalars["Datetime"]["output"];
  /** Reads a single `ApplicationUser` that is related to this `DjangoAdminLog`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  changeMessage: Scalars["String"]["output"];
  contentTypeId: Maybe<Scalars["Int"]["output"]>;
  /** Reads a single `DjangoContentType` that is related to this `DjangoAdminLog`. */
  djangoContentTypeByContentTypeId: Maybe<DjangoContentType>;
  id: Scalars["Int"]["output"];
  objectId: Maybe<Scalars["String"]["output"]>;
  objectRepr: Scalars["String"]["output"];
  userId: Scalars["UUID"]["output"];
};

/**
 * A condition to be used against `DjangoAdminLog` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DjangoAdminLogCondition = {
  /** Checks for equality with the object’s `actionFlag` field. */
  actionFlag?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `actionTime` field. */
  actionTime?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `changeMessage` field. */
  changeMessage?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `contentTypeId` field. */
  contentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `objectId` field. */
  objectId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `objectRepr` field. */
  objectRepr?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `djangoAdminLog` to be created by this mutation. */
export type DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoDjangoAdminLogCreateInput =
  {
    actionFlag: Scalars["Int"]["input"];
    actionTime: Scalars["Datetime"]["input"];
    applicationUserToUserId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInput>;
    changeMessage: Scalars["String"]["input"];
    djangoContentTypeToContentTypeId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    objectId?: InputMaybe<Scalars["String"]["input"]>;
    objectRepr: Scalars["String"]["input"];
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** The `djangoContentType` to be created by this mutation. */
export type DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoDjangoContentTypeCreateInput =
  {
    appLabel: Scalars["String"]["input"];
    authPermissionsUsingId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput>;
    drsFileexecutorsUsingId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    model: Scalars["String"]["input"];
    tenantUserexecutorsUsingId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput>;
    tesContainerimageexecutorsUsingId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput>;
    tesTaskexecutorsUsingId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput>;
  };

/** Input for the nested mutation of `djangoContentType` in the `DjangoAdminLogInput` mutation. */
export type DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInput = {
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  connectByAppLabelAndModel?: InputMaybe<DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqConnect>;
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  connectById?: InputMaybe<DjangoContentTypeDjangoContentTypePkeyConnect>;
  /** A `DjangoContentTypeInput` object that will be created and connected to this object. */
  create?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoDjangoContentTypeCreateInput>;
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  deleteByAppLabelAndModel?: InputMaybe<DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqDelete>;
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  deleteById?: InputMaybe<DjangoContentTypeDjangoContentTypePkeyDelete>;
  /** The primary key(s) and patch data for `djangoContentType` for the far side of the relationship. */
  updateByAppLabelAndModel?: InputMaybe<DjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate>;
  /** The primary key(s) and patch data for `djangoContentType` for the far side of the relationship. */
  updateById?: InputMaybe<DjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoUsingDjangoContentTypePkeyUpdate>;
};

/** Input for the nested mutation of `djangoAdminLog` in the `DjangoContentTypeInput` mutation. */
export type DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput = {
  /** The primary key(s) for `djangoAdminLog` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DjangoAdminLogDjangoAdminLogPkeyConnect>>;
  /** A `DjangoAdminLogInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoDjangoAdminLogCreateInput>
  >;
  /** The primary key(s) for `djangoAdminLog` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DjangoAdminLogDjangoAdminLogPkeyDelete>>;
  /** Flag indicating whether all other `djangoAdminLog` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `djangoAdminLog` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DjangoAdminLogOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoUsingDjangoAdminLogPkeyUpdate>
  >;
};

/** The fields on `djangoAdminLog` to look up the row to connect. */
export type DjangoAdminLogDjangoAdminLogPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `djangoAdminLog` to look up the row to delete. */
export type DjangoAdminLogDjangoAdminLogPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** A filter to be used against `DjangoAdminLog` object types. All fields are combined with a logical ‘and.’ */
export type DjangoAdminLogFilter = {
  /** Filter by the object’s `actionFlag` field. */
  actionFlag?: InputMaybe<IntFilter>;
  /** Filter by the object’s `actionTime` field. */
  actionTime?: InputMaybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DjangoAdminLogFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `changeMessage` field. */
  changeMessage?: InputMaybe<StringFilter>;
  /** Filter by the object’s `contentTypeId` field. */
  contentTypeId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `djangoContentTypeByContentTypeId` relation. */
  djangoContentTypeByContentTypeId?: InputMaybe<DjangoContentTypeFilter>;
  /** A related `djangoContentTypeByContentTypeId` exists. */
  djangoContentTypeByContentTypeIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DjangoAdminLogFilter>;
  /** Filter by the object’s `objectId` field. */
  objectId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `objectRepr` field. */
  objectRepr?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DjangoAdminLogFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `DjangoAdminLog` */
export type DjangoAdminLogInput = {
  actionFlag: Scalars["Int"]["input"];
  actionTime: Scalars["Datetime"]["input"];
  applicationUserToUserId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInput>;
  changeMessage: Scalars["String"]["input"];
  contentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  djangoContentTypeToContentTypeId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  objectId?: InputMaybe<Scalars["String"]["input"]>;
  objectRepr: Scalars["String"]["input"];
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `djangoAdminLog` to look up the row to update. */
export type DjangoAdminLogOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoUsingDjangoAdminLogPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `djangoAdminLog` being updated. */
    djangoAdminLogPatch: UpdateDjangoAdminLogOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `djangoAdminLog` to look up the row to update. */
export type DjangoAdminLogOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingDjangoAdminLogPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `djangoAdminLog` being updated. */
    djangoAdminLogPatch: UpdateDjangoAdminLogOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdPatch;
    id: Scalars["Int"]["input"];
  };

/** Represents an update to a `DjangoAdminLog`. Fields that are set will be updated. */
export type DjangoAdminLogPatch = {
  actionFlag?: InputMaybe<Scalars["Int"]["input"]>;
  actionTime?: InputMaybe<Scalars["Datetime"]["input"]>;
  applicationUserToUserId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInput>;
  changeMessage?: InputMaybe<Scalars["String"]["input"]>;
  contentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  djangoContentTypeToContentTypeId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  objectId?: InputMaybe<Scalars["String"]["input"]>;
  objectRepr?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `applicationUser` to be created by this mutation. */
export type DjangoAdminLogUserIdC564Eba6FkApplicationUserIdApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** The `djangoAdminLog` to be created by this mutation. */
export type DjangoAdminLogUserIdC564Eba6FkApplicationUserIdDjangoAdminLogCreateInput =
  {
    actionFlag: Scalars["Int"]["input"];
    actionTime: Scalars["Datetime"]["input"];
    applicationUserToUserId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInput>;
    changeMessage: Scalars["String"]["input"];
    contentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
    djangoContentTypeToContentTypeId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    objectId?: InputMaybe<Scalars["String"]["input"]>;
    objectRepr: Scalars["String"]["input"];
  };

/** Input for the nested mutation of `applicationUser` in the `DjangoAdminLogInput` mutation. */
export type DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `djangoAdminLog` in the `ApplicationUserInput` mutation. */
export type DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput = {
  /** The primary key(s) for `djangoAdminLog` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DjangoAdminLogDjangoAdminLogPkeyConnect>>;
  /** A `DjangoAdminLogInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdDjangoAdminLogCreateInput>
  >;
  /** The primary key(s) for `djangoAdminLog` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DjangoAdminLogDjangoAdminLogPkeyDelete>>;
  /** Flag indicating whether all other `djangoAdminLog` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `djangoAdminLog` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DjangoAdminLogOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingDjangoAdminLogPkeyUpdate>
  >;
};

/** A connection to a list of `DjangoAdminLog` values. */
export type DjangoAdminLogsConnection = {
  __typename: "DjangoAdminLogsConnection";
  /** A list of edges which contains the `DjangoAdminLog` and cursor to aid in pagination. */
  edges: Array<DjangoAdminLogsEdge>;
  /** A list of `DjangoAdminLog` objects. */
  nodes: Array<DjangoAdminLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DjangoAdminLog` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DjangoAdminLog` edge in the connection. */
export type DjangoAdminLogsEdge = {
  __typename: "DjangoAdminLogsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DjangoAdminLog` at the end of the edge. */
  node: DjangoAdminLog;
};

/** Methods to use when ordering `DjangoAdminLog`. */
export enum DjangoAdminLogsOrderBy {
  ActionFlagAsc = "ACTION_FLAG_ASC",
  ActionFlagDesc = "ACTION_FLAG_DESC",
  ActionTimeAsc = "ACTION_TIME_ASC",
  ActionTimeDesc = "ACTION_TIME_DESC",
  ChangeMessageAsc = "CHANGE_MESSAGE_ASC",
  ChangeMessageDesc = "CHANGE_MESSAGE_DESC",
  ContentTypeIdAsc = "CONTENT_TYPE_ID_ASC",
  ContentTypeIdDesc = "CONTENT_TYPE_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  ObjectIdAsc = "OBJECT_ID_ASC",
  ObjectIdDesc = "OBJECT_ID_DESC",
  ObjectReprAsc = "OBJECT_REPR_ASC",
  ObjectReprDesc = "OBJECT_REPR_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type DjangoContentType = {
  __typename: "DjangoContentType";
  appLabel: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `AuthPermission`. */
  authPermissionsByContentTypeId: AuthPermissionsConnection;
  /** Reads and enables pagination through a set of `DjangoAdminLog`. */
  djangoAdminLogsByContentTypeId: DjangoAdminLogsConnection;
  /** Reads and enables pagination through a set of `DrsFileexecutor`. */
  drsFileexecutorsByPolymorphicCtypeId: DrsFileexecutorsConnection;
  id: Scalars["Int"]["output"];
  model: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `TenantUserexecutor`. */
  tenantUserexecutorsByPolymorphicCtypeId: TenantUserexecutorsConnection;
  /** Reads and enables pagination through a set of `TesContainerimageexecutor`. */
  tesContainerimageexecutorsByPolymorphicCtypeId: TesContainerimageexecutorsConnection;
  /** Reads and enables pagination through a set of `TesTaskexecutor`. */
  tesTaskexecutorsByPolymorphicCtypeId: TesTaskexecutorsConnection;
};

export type DjangoContentTypeAuthPermissionsByContentTypeIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuthPermissionCondition>;
  filter?: InputMaybe<AuthPermissionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuthPermissionsOrderBy>>;
};

export type DjangoContentTypeDjangoAdminLogsByContentTypeIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DjangoAdminLogCondition>;
  filter?: InputMaybe<DjangoAdminLogFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DjangoAdminLogsOrderBy>>;
};

export type DjangoContentTypeDrsFileexecutorsByPolymorphicCtypeIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DrsFileexecutorCondition>;
  filter?: InputMaybe<DrsFileexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DrsFileexecutorsOrderBy>>;
};

export type DjangoContentTypeTenantUserexecutorsByPolymorphicCtypeIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantUserexecutorCondition>;
  filter?: InputMaybe<TenantUserexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantUserexecutorsOrderBy>>;
};

export type DjangoContentTypeTesContainerimageexecutorsByPolymorphicCtypeIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<TesContainerimageexecutorCondition>;
    filter?: InputMaybe<TesContainerimageexecutorFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<TesContainerimageexecutorsOrderBy>>;
  };

export type DjangoContentTypeTesTaskexecutorsByPolymorphicCtypeIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TesTaskexecutorCondition>;
  filter?: InputMaybe<TesTaskexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TesTaskexecutorsOrderBy>>;
};

/**
 * A condition to be used against `DjangoContentType` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DjangoContentTypeCondition = {
  /** Checks for equality with the object’s `appLabel` field. */
  appLabel?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `model` field. */
  model?: InputMaybe<Scalars["String"]["input"]>;
};

/** The fields on `djangoContentType` to look up the row to connect. */
export type DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqConnect =
  {
    appLabel: Scalars["String"]["input"];
    model: Scalars["String"]["input"];
  };

/** The fields on `djangoContentType` to look up the row to delete. */
export type DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqDelete =
  {
    appLabel: Scalars["String"]["input"];
    model: Scalars["String"]["input"];
  };

/** The fields on `djangoContentType` to look up the row to connect. */
export type DjangoContentTypeDjangoContentTypePkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `djangoContentType` to look up the row to delete. */
export type DjangoContentTypeDjangoContentTypePkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** A filter to be used against `DjangoContentType` object types. All fields are combined with a logical ‘and.’ */
export type DjangoContentTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DjangoContentTypeFilter>>;
  /** Filter by the object’s `appLabel` field. */
  appLabel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `authPermissionsByContentTypeId` relation. */
  authPermissionsByContentTypeId?: InputMaybe<DjangoContentTypeToManyAuthPermissionFilter>;
  /** Some related `authPermissionsByContentTypeId` exist. */
  authPermissionsByContentTypeIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `djangoAdminLogsByContentTypeId` relation. */
  djangoAdminLogsByContentTypeId?: InputMaybe<DjangoContentTypeToManyDjangoAdminLogFilter>;
  /** Some related `djangoAdminLogsByContentTypeId` exist. */
  djangoAdminLogsByContentTypeIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `drsFileexecutorsByPolymorphicCtypeId` relation. */
  drsFileexecutorsByPolymorphicCtypeId?: InputMaybe<DjangoContentTypeToManyDrsFileexecutorFilter>;
  /** Some related `drsFileexecutorsByPolymorphicCtypeId` exist. */
  drsFileexecutorsByPolymorphicCtypeIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `model` field. */
  model?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DjangoContentTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DjangoContentTypeFilter>>;
  /** Filter by the object’s `tenantUserexecutorsByPolymorphicCtypeId` relation. */
  tenantUserexecutorsByPolymorphicCtypeId?: InputMaybe<DjangoContentTypeToManyTenantUserexecutorFilter>;
  /** Some related `tenantUserexecutorsByPolymorphicCtypeId` exist. */
  tenantUserexecutorsByPolymorphicCtypeIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `tesContainerimageexecutorsByPolymorphicCtypeId` relation. */
  tesContainerimageexecutorsByPolymorphicCtypeId?: InputMaybe<DjangoContentTypeToManyTesContainerimageexecutorFilter>;
  /** Some related `tesContainerimageexecutorsByPolymorphicCtypeId` exist. */
  tesContainerimageexecutorsByPolymorphicCtypeIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `tesTaskexecutorsByPolymorphicCtypeId` relation. */
  tesTaskexecutorsByPolymorphicCtypeId?: InputMaybe<DjangoContentTypeToManyTesTaskexecutorFilter>;
  /** Some related `tesTaskexecutorsByPolymorphicCtypeId` exist. */
  tesTaskexecutorsByPolymorphicCtypeIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
};

/** An input for mutations affecting `DjangoContentType` */
export type DjangoContentTypeInput = {
  appLabel: Scalars["String"]["input"];
  authPermissionsUsingId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput>;
  djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput>;
  drsFileexecutorsUsingId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  model: Scalars["String"]["input"];
  tenantUserexecutorsUsingId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput>;
  tesContainerimageexecutorsUsingId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput>;
  tesTaskexecutorsUsingId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput>;
};

/** The fields on `djangoContentType` to look up the row to update. */
export type DjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate =
  {
    appLabel: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `djangoContentType` being updated. */
    djangoContentTypePatch: UpdateDjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoPatch;
    model: Scalars["String"]["input"];
  };

/** The fields on `djangoContentType` to look up the row to update. */
export type DjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingDjangoContentTypePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `djangoContentType` being updated. */
    djangoContentTypePatch: UpdateDjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `djangoContentType` to look up the row to update. */
export type DjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate =
  {
    appLabel: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `djangoContentType` being updated. */
    djangoContentTypePatch: UpdateDjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoPatch;
    model: Scalars["String"]["input"];
  };

/** The fields on `djangoContentType` to look up the row to update. */
export type DjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoUsingDjangoContentTypePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `djangoContentType` being updated. */
    djangoContentTypePatch: UpdateDjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `djangoContentType` to look up the row to update. */
export type DjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate =
  {
    appLabel: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `djangoContentType` being updated. */
    djangoContentTypePatch: UpdateDjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoPatch;
    model: Scalars["String"]["input"];
  };

/** The fields on `djangoContentType` to look up the row to update. */
export type DjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoUsingDjangoContentTypePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `djangoContentType` being updated. */
    djangoContentTypePatch: UpdateDjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `djangoContentType` to look up the row to update. */
export type DjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate =
  {
    appLabel: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `djangoContentType` being updated. */
    djangoContentTypePatch: UpdateDjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoPatch;
    model: Scalars["String"]["input"];
  };

/** The fields on `djangoContentType` to look up the row to update. */
export type DjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoUsingDjangoContentTypePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `djangoContentType` being updated. */
    djangoContentTypePatch: UpdateDjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `djangoContentType` to look up the row to update. */
export type DjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate =
  {
    appLabel: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `djangoContentType` being updated. */
    djangoContentTypePatch: UpdateDjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoPatch;
    model: Scalars["String"]["input"];
  };

/** The fields on `djangoContentType` to look up the row to update. */
export type DjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoUsingDjangoContentTypePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `djangoContentType` being updated. */
    djangoContentTypePatch: UpdateDjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `djangoContentType` to look up the row to update. */
export type DjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate =
  {
    appLabel: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `djangoContentType` being updated. */
    djangoContentTypePatch: UpdateDjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoPatch;
    model: Scalars["String"]["input"];
  };

/** The fields on `djangoContentType` to look up the row to update. */
export type DjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoUsingDjangoContentTypePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `djangoContentType` being updated. */
    djangoContentTypePatch: UpdateDjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoPatch;
    id: Scalars["Int"]["input"];
  };

/** Represents an update to a `DjangoContentType`. Fields that are set will be updated. */
export type DjangoContentTypePatch = {
  appLabel?: InputMaybe<Scalars["String"]["input"]>;
  authPermissionsUsingId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput>;
  djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput>;
  drsFileexecutorsUsingId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  tenantUserexecutorsUsingId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput>;
  tesContainerimageexecutorsUsingId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput>;
  tesTaskexecutorsUsingId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput>;
};

/** A filter to be used against many `AuthPermission` object types. All fields are combined with a logical ‘and.’ */
export type DjangoContentTypeToManyAuthPermissionFilter = {
  /** Every related `AuthPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuthPermissionFilter>;
  /** No related `AuthPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuthPermissionFilter>;
  /** Some related `AuthPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuthPermissionFilter>;
};

/** A filter to be used against many `DjangoAdminLog` object types. All fields are combined with a logical ‘and.’ */
export type DjangoContentTypeToManyDjangoAdminLogFilter = {
  /** Every related `DjangoAdminLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DjangoAdminLogFilter>;
  /** No related `DjangoAdminLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DjangoAdminLogFilter>;
  /** Some related `DjangoAdminLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DjangoAdminLogFilter>;
};

/** A filter to be used against many `DrsFileexecutor` object types. All fields are combined with a logical ‘and.’ */
export type DjangoContentTypeToManyDrsFileexecutorFilter = {
  /** Every related `DrsFileexecutor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DrsFileexecutorFilter>;
  /** No related `DrsFileexecutor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DrsFileexecutorFilter>;
  /** Some related `DrsFileexecutor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DrsFileexecutorFilter>;
};

/** A filter to be used against many `TenantUserexecutor` object types. All fields are combined with a logical ‘and.’ */
export type DjangoContentTypeToManyTenantUserexecutorFilter = {
  /** Every related `TenantUserexecutor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TenantUserexecutorFilter>;
  /** No related `TenantUserexecutor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TenantUserexecutorFilter>;
  /** Some related `TenantUserexecutor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TenantUserexecutorFilter>;
};

/** A filter to be used against many `TesContainerimageexecutor` object types. All fields are combined with a logical ‘and.’ */
export type DjangoContentTypeToManyTesContainerimageexecutorFilter = {
  /** Every related `TesContainerimageexecutor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TesContainerimageexecutorFilter>;
  /** No related `TesContainerimageexecutor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TesContainerimageexecutorFilter>;
  /** Some related `TesContainerimageexecutor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TesContainerimageexecutorFilter>;
};

/** A filter to be used against many `TesTaskexecutor` object types. All fields are combined with a logical ‘and.’ */
export type DjangoContentTypeToManyTesTaskexecutorFilter = {
  /** Every related `TesTaskexecutor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TesTaskexecutorFilter>;
  /** No related `TesTaskexecutor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TesTaskexecutorFilter>;
  /** Some related `TesTaskexecutor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TesTaskexecutorFilter>;
};

/** A connection to a list of `DjangoContentType` values. */
export type DjangoContentTypesConnection = {
  __typename: "DjangoContentTypesConnection";
  /** A list of edges which contains the `DjangoContentType` and cursor to aid in pagination. */
  edges: Array<DjangoContentTypesEdge>;
  /** A list of `DjangoContentType` objects. */
  nodes: Array<DjangoContentType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DjangoContentType` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DjangoContentType` edge in the connection. */
export type DjangoContentTypesEdge = {
  __typename: "DjangoContentTypesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DjangoContentType` at the end of the edge. */
  node: DjangoContentType;
};

/** Methods to use when ordering `DjangoContentType`. */
export enum DjangoContentTypesOrderBy {
  AppLabelAsc = "APP_LABEL_ASC",
  AppLabelDesc = "APP_LABEL_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ModelAsc = "MODEL_ASC",
  ModelDesc = "MODEL_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type DjangoMigration = {
  __typename: "DjangoMigration";
  app: Scalars["String"]["output"];
  applied: Scalars["Datetime"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
};

/**
 * A condition to be used against `DjangoMigration` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DjangoMigrationCondition = {
  /** Checks for equality with the object’s `app` field. */
  app?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `applied` field. */
  applied?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `DjangoMigration` object types. All fields are combined with a logical ‘and.’ */
export type DjangoMigrationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DjangoMigrationFilter>>;
  /** Filter by the object’s `app` field. */
  app?: InputMaybe<StringFilter>;
  /** Filter by the object’s `applied` field. */
  applied?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DjangoMigrationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DjangoMigrationFilter>>;
};

/** An input for mutations affecting `DjangoMigration` */
export type DjangoMigrationInput = {
  app: Scalars["String"]["input"];
  applied: Scalars["Datetime"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
};

/** Represents an update to a `DjangoMigration`. Fields that are set will be updated. */
export type DjangoMigrationPatch = {
  app?: InputMaybe<Scalars["String"]["input"]>;
  applied?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** A connection to a list of `DjangoMigration` values. */
export type DjangoMigrationsConnection = {
  __typename: "DjangoMigrationsConnection";
  /** A list of edges which contains the `DjangoMigration` and cursor to aid in pagination. */
  edges: Array<DjangoMigrationsEdge>;
  /** A list of `DjangoMigration` objects. */
  nodes: Array<DjangoMigration>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DjangoMigration` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DjangoMigration` edge in the connection. */
export type DjangoMigrationsEdge = {
  __typename: "DjangoMigrationsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DjangoMigration` at the end of the edge. */
  node: DjangoMigration;
};

/** Methods to use when ordering `DjangoMigration`. */
export enum DjangoMigrationsOrderBy {
  AppliedAsc = "APPLIED_ASC",
  AppliedDesc = "APPLIED_DESC",
  AppAsc = "APP_ASC",
  AppDesc = "APP_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type DjangoSesSesstat = {
  __typename: "DjangoSesSesstat";
  bounces: Scalars["Int"]["output"];
  complaints: Scalars["Int"]["output"];
  date: Scalars["Date"]["output"];
  deliveryAttempts: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  rejects: Scalars["Int"]["output"];
};

/**
 * A condition to be used against `DjangoSesSesstat` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DjangoSesSesstatCondition = {
  /** Checks for equality with the object’s `bounces` field. */
  bounces?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `complaints` field. */
  complaints?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `date` field. */
  date?: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `deliveryAttempts` field. */
  deliveryAttempts?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `rejects` field. */
  rejects?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `DjangoSesSesstat` object types. All fields are combined with a logical ‘and.’ */
export type DjangoSesSesstatFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DjangoSesSesstatFilter>>;
  /** Filter by the object’s `bounces` field. */
  bounces?: InputMaybe<IntFilter>;
  /** Filter by the object’s `complaints` field. */
  complaints?: InputMaybe<IntFilter>;
  /** Filter by the object’s `date` field. */
  date?: InputMaybe<DateFilter>;
  /** Filter by the object’s `deliveryAttempts` field. */
  deliveryAttempts?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DjangoSesSesstatFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DjangoSesSesstatFilter>>;
  /** Filter by the object’s `rejects` field. */
  rejects?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `DjangoSesSesstat` */
export type DjangoSesSesstatInput = {
  bounces: Scalars["Int"]["input"];
  complaints: Scalars["Int"]["input"];
  date: Scalars["Date"]["input"];
  deliveryAttempts: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  rejects: Scalars["Int"]["input"];
};

/** Represents an update to a `DjangoSesSesstat`. Fields that are set will be updated. */
export type DjangoSesSesstatPatch = {
  bounces?: InputMaybe<Scalars["Int"]["input"]>;
  complaints?: InputMaybe<Scalars["Int"]["input"]>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  deliveryAttempts?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  rejects?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A connection to a list of `DjangoSesSesstat` values. */
export type DjangoSesSesstatsConnection = {
  __typename: "DjangoSesSesstatsConnection";
  /** A list of edges which contains the `DjangoSesSesstat` and cursor to aid in pagination. */
  edges: Array<DjangoSesSesstatsEdge>;
  /** A list of `DjangoSesSesstat` objects. */
  nodes: Array<DjangoSesSesstat>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DjangoSesSesstat` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DjangoSesSesstat` edge in the connection. */
export type DjangoSesSesstatsEdge = {
  __typename: "DjangoSesSesstatsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DjangoSesSesstat` at the end of the edge. */
  node: DjangoSesSesstat;
};

/** Methods to use when ordering `DjangoSesSesstat`. */
export enum DjangoSesSesstatsOrderBy {
  BouncesAsc = "BOUNCES_ASC",
  BouncesDesc = "BOUNCES_DESC",
  ComplaintsAsc = "COMPLAINTS_ASC",
  ComplaintsDesc = "COMPLAINTS_DESC",
  DateAsc = "DATE_ASC",
  DateDesc = "DATE_DESC",
  DeliveryAttemptsAsc = "DELIVERY_ATTEMPTS_ASC",
  DeliveryAttemptsDesc = "DELIVERY_ATTEMPTS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RejectsAsc = "REJECTS_ASC",
  RejectsDesc = "REJECTS_DESC",
}

export type DjangoSession = {
  __typename: "DjangoSession";
  expireDate: Scalars["Datetime"]["output"];
  sessionData: Scalars["String"]["output"];
  sessionKey: Scalars["String"]["output"];
};

/**
 * A condition to be used against `DjangoSession` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DjangoSessionCondition = {
  /** Checks for equality with the object’s `expireDate` field. */
  expireDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `sessionData` field. */
  sessionData?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `sessionKey` field. */
  sessionKey?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `DjangoSession` object types. All fields are combined with a logical ‘and.’ */
export type DjangoSessionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DjangoSessionFilter>>;
  /** Filter by the object’s `expireDate` field. */
  expireDate?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DjangoSessionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DjangoSessionFilter>>;
  /** Filter by the object’s `sessionData` field. */
  sessionData?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sessionKey` field. */
  sessionKey?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `DjangoSession` */
export type DjangoSessionInput = {
  expireDate: Scalars["Datetime"]["input"];
  sessionData: Scalars["String"]["input"];
  sessionKey: Scalars["String"]["input"];
};

/** Represents an update to a `DjangoSession`. Fields that are set will be updated. */
export type DjangoSessionPatch = {
  expireDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  sessionData?: InputMaybe<Scalars["String"]["input"]>;
  sessionKey?: InputMaybe<Scalars["String"]["input"]>;
};

/** A connection to a list of `DjangoSession` values. */
export type DjangoSessionsConnection = {
  __typename: "DjangoSessionsConnection";
  /** A list of edges which contains the `DjangoSession` and cursor to aid in pagination. */
  edges: Array<DjangoSessionsEdge>;
  /** A list of `DjangoSession` objects. */
  nodes: Array<DjangoSession>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DjangoSession` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DjangoSession` edge in the connection. */
export type DjangoSessionsEdge = {
  __typename: "DjangoSessionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DjangoSession` at the end of the edge. */
  node: DjangoSession;
};

/** Methods to use when ordering `DjangoSession`. */
export enum DjangoSessionsOrderBy {
  ExpireDateAsc = "EXPIRE_DATE_ASC",
  ExpireDateDesc = "EXPIRE_DATE_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SessionDataAsc = "SESSION_DATA_ASC",
  SessionDataDesc = "SESSION_DATA_DESC",
  SessionKeyAsc = "SESSION_KEY_ASC",
  SessionKeyDesc = "SESSION_KEY_DESC",
}

export type DrsFileexecutor = {
  __typename: "DrsFileexecutor";
  /** Reads a single `AwsS3File` that is related to this `DrsFileexecutor`. */
  awsS3FileByFileexecutorPtrId: Maybe<AwsS3File>;
  /**
   * Reads and enables pagination through a set of `AwsS3File`.
   * @deprecated Please use awsS3FileByFileexecutorPtrId instead
   */
  awsS3FilesByFileexecutorPtrId: AwsS3FilesConnection;
  /** Reads a single `DjangoContentType` that is related to this `DrsFileexecutor`. */
  djangoContentTypeByPolymorphicCtypeId: Maybe<DjangoContentType>;
  /** Reads a single `DrsMockfileexecutor` that is related to this `DrsFileexecutor`. */
  drsMockfileexecutorByFileexecutorPtrId: Maybe<DrsMockfileexecutor>;
  /**
   * Reads and enables pagination through a set of `DrsMockfileexecutor`.
   * @deprecated Please use drsMockfileexecutorByFileexecutorPtrId instead
   */
  drsMockfileexecutorsByFileexecutorPtrId: DrsMockfileexecutorsConnection;
  id: Scalars["UUID"]["output"];
  polymorphicCtypeId: Maybe<Scalars["Int"]["output"]>;
};

export type DrsFileexecutorAwsS3FilesByFileexecutorPtrIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsS3FileCondition>;
  filter?: InputMaybe<AwsS3FileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsS3FilesOrderBy>>;
};

export type DrsFileexecutorDrsMockfileexecutorsByFileexecutorPtrIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DrsMockfileexecutorCondition>;
  filter?: InputMaybe<DrsMockfileexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DrsMockfileexecutorsOrderBy>>;
};

/**
 * A condition to be used against `DrsFileexecutor` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DrsFileexecutorCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `polymorphicCtypeId` field. */
  polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `drsFileexecutor` to look up the row to connect. */
export type DrsFileexecutorDrsFileexecutorPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `drsFileexecutor` to look up the row to delete. */
export type DrsFileexecutorDrsFileexecutorPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** A filter to be used against `DrsFileexecutor` object types. All fields are combined with a logical ‘and.’ */
export type DrsFileexecutorFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DrsFileexecutorFilter>>;
  /** Filter by the object’s `awsS3FileByFileexecutorPtrId` relation. */
  awsS3FileByFileexecutorPtrId?: InputMaybe<AwsS3FileFilter>;
  /** A related `awsS3FileByFileexecutorPtrId` exists. */
  awsS3FileByFileexecutorPtrIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `djangoContentTypeByPolymorphicCtypeId` relation. */
  djangoContentTypeByPolymorphicCtypeId?: InputMaybe<DjangoContentTypeFilter>;
  /** A related `djangoContentTypeByPolymorphicCtypeId` exists. */
  djangoContentTypeByPolymorphicCtypeIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `drsMockfileexecutorByFileexecutorPtrId` relation. */
  drsMockfileexecutorByFileexecutorPtrId?: InputMaybe<DrsMockfileexecutorFilter>;
  /** A related `drsMockfileexecutorByFileexecutorPtrId` exists. */
  drsMockfileexecutorByFileexecutorPtrIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DrsFileexecutorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DrsFileexecutorFilter>>;
  /** Filter by the object’s `polymorphicCtypeId` field. */
  polymorphicCtypeId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `DrsFileexecutor` */
export type DrsFileexecutorInput = {
  awsS3FileUsingId?: InputMaybe<AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInverseInput>;
  djangoContentTypeToPolymorphicCtypeId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInput>;
  drsMockfileexecutorUsingId?: InputMaybe<DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInverseInput>;
  filesUsingId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInverseInput>;
  id: Scalars["UUID"]["input"];
  polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `drsFileexecutor` to look up the row to update. */
export type DrsFileexecutorOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdUsingDrsFileexecutorPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `drsFileexecutor` being updated. */
    drsFileexecutorPatch: UpdateDrsFileexecutorOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `drsFileexecutor` to look up the row to update. */
export type DrsFileexecutorOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoUsingDrsFileexecutorPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `drsFileexecutor` being updated. */
    drsFileexecutorPatch: UpdateDrsFileexecutorOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `drsFileexecutor` to look up the row to update. */
export type DrsFileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeUsingDrsFileexecutorPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `drsFileexecutor` being updated. */
    drsFileexecutorPatch: UpdateDrsFileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileePatch;
    id: Scalars["UUID"]["input"];
  };

/** Represents an update to a `DrsFileexecutor`. Fields that are set will be updated. */
export type DrsFileexecutorPatch = {
  awsS3FileUsingId?: InputMaybe<AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInverseInput>;
  djangoContentTypeToPolymorphicCtypeId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInput>;
  drsMockfileexecutorUsingId?: InputMaybe<DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInverseInput>;
  filesUsingId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The `djangoContentType` to be created by this mutation. */
export type DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoDjangoContentTypeCreateInput =
  {
    appLabel: Scalars["String"]["input"];
    authPermissionsUsingId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput>;
    drsFileexecutorsUsingId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    model: Scalars["String"]["input"];
    tenantUserexecutorsUsingId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput>;
    tesContainerimageexecutorsUsingId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput>;
    tesTaskexecutorsUsingId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput>;
  };

/** The `drsFileexecutor` to be created by this mutation. */
export type DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoDrsFileexecutorCreateInput =
  {
    awsS3FileUsingId?: InputMaybe<AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInput>;
    drsMockfileexecutorUsingId?: InputMaybe<DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInverseInput>;
    filesUsingId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInverseInput>;
    id: Scalars["UUID"]["input"];
  };

/** Input for the nested mutation of `djangoContentType` in the `DrsFileexecutorInput` mutation. */
export type DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInput = {
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  connectByAppLabelAndModel?: InputMaybe<DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqConnect>;
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  connectById?: InputMaybe<DjangoContentTypeDjangoContentTypePkeyConnect>;
  /** A `DjangoContentTypeInput` object that will be created and connected to this object. */
  create?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoDjangoContentTypeCreateInput>;
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  deleteByAppLabelAndModel?: InputMaybe<DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqDelete>;
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  deleteById?: InputMaybe<DjangoContentTypeDjangoContentTypePkeyDelete>;
  /** The primary key(s) and patch data for `djangoContentType` for the far side of the relationship. */
  updateByAppLabelAndModel?: InputMaybe<DjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate>;
  /** The primary key(s) and patch data for `djangoContentType` for the far side of the relationship. */
  updateById?: InputMaybe<DjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoUsingDjangoContentTypePkeyUpdate>;
};

/** Input for the nested mutation of `drsFileexecutor` in the `DjangoContentTypeInput` mutation. */
export type DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput = {
  /** The primary key(s) for `drsFileexecutor` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DrsFileexecutorDrsFileexecutorPkeyConnect>>;
  /** A `DrsFileexecutorInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoDrsFileexecutorCreateInput>
  >;
  /** The primary key(s) for `drsFileexecutor` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DrsFileexecutorDrsFileexecutorPkeyDelete>>;
  /** Flag indicating whether all other `drsFileexecutor` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `drsFileexecutor` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DrsFileexecutorOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoUsingDrsFileexecutorPkeyUpdate>
  >;
};

/** A connection to a list of `DrsFileexecutor` values. */
export type DrsFileexecutorsConnection = {
  __typename: "DrsFileexecutorsConnection";
  /** A list of edges which contains the `DrsFileexecutor` and cursor to aid in pagination. */
  edges: Array<DrsFileexecutorsEdge>;
  /** A list of `DrsFileexecutor` objects. */
  nodes: Array<DrsFileexecutor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DrsFileexecutor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DrsFileexecutor` edge in the connection. */
export type DrsFileexecutorsEdge = {
  __typename: "DrsFileexecutorsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DrsFileexecutor` at the end of the edge. */
  node: DrsFileexecutor;
};

/** Methods to use when ordering `DrsFileexecutor`. */
export enum DrsFileexecutorsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PolymorphicCtypeIdAsc = "POLYMORPHIC_CTYPE_ID_ASC",
  PolymorphicCtypeIdDesc = "POLYMORPHIC_CTYPE_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type DrsMockfileexecutor = {
  __typename: "DrsMockfileexecutor";
  /** Reads a single `DrsFileexecutor` that is related to this `DrsMockfileexecutor`. */
  drsFileexecutorByFileexecutorPtrId: Maybe<DrsFileexecutor>;
  fileexecutorPtrId: Scalars["UUID"]["output"];
};

/**
 * A condition to be used against `DrsMockfileexecutor` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type DrsMockfileexecutorCondition = {
  /** Checks for equality with the object’s `fileexecutorPtrId` field. */
  fileexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `drsMockfileexecutor` to look up the row to connect. */
export type DrsMockfileexecutorDrsMockfileexecutorPkeyConnect = {
  fileexecutorPtrId: Scalars["UUID"]["input"];
};

/** The fields on `drsMockfileexecutor` to look up the row to delete. */
export type DrsMockfileexecutorDrsMockfileexecutorPkeyDelete = {
  fileexecutorPtrId: Scalars["UUID"]["input"];
};

/** The `drsFileexecutor` to be created by this mutation. */
export type DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeDrsFileexecutorCreateInput =
  {
    awsS3FileUsingId?: InputMaybe<AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInput>;
    drsMockfileexecutorUsingId?: InputMaybe<DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInverseInput>;
    filesUsingId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInverseInput>;
    id: Scalars["UUID"]["input"];
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** The `drsMockfileexecutor` to be created by this mutation. */
export type DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeDrsMockfileexecutorCreateInput =
  {
    drsFileexecutorToFileexecutorPtrId?: InputMaybe<DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInput>;
  };

/** Input for the nested mutation of `drsFileexecutor` in the `DrsMockfileexecutorInput` mutation. */
export type DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInput = {
  /** The primary key(s) for `drsFileexecutor` for the far side of the relationship. */
  connectById?: InputMaybe<DrsFileexecutorDrsFileexecutorPkeyConnect>;
  /** A `DrsFileexecutorInput` object that will be created and connected to this object. */
  create?: InputMaybe<DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeDrsFileexecutorCreateInput>;
  /** The primary key(s) for `drsFileexecutor` for the far side of the relationship. */
  deleteById?: InputMaybe<DrsFileexecutorDrsFileexecutorPkeyDelete>;
  /** The primary key(s) and patch data for `drsFileexecutor` for the far side of the relationship. */
  updateById?: InputMaybe<DrsFileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeUsingDrsFileexecutorPkeyUpdate>;
};

/** Input for the nested mutation of `drsMockfileexecutor` in the `DrsFileexecutorInput` mutation. */
export type DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInverseInput =
  {
    /** The primary key(s) for `drsMockfileexecutor` for the far side of the relationship. */
    connectByFileexecutorPtrId?: InputMaybe<DrsMockfileexecutorDrsMockfileexecutorPkeyConnect>;
    /** A `DrsMockfileexecutorInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeDrsMockfileexecutorCreateInput>
    >;
    /** The primary key(s) for `drsMockfileexecutor` for the far side of the relationship. */
    deleteByFileexecutorPtrId?: InputMaybe<DrsMockfileexecutorDrsMockfileexecutorPkeyDelete>;
    /** Flag indicating whether all other `drsMockfileexecutor` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `drsMockfileexecutor` for the far side of the relationship. */
    updateByFileexecutorPtrId?: InputMaybe<DrsMockfileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeUsingDrsMockfileexecutorPkeyUpdate>;
  };

/** A filter to be used against `DrsMockfileexecutor` object types. All fields are combined with a logical ‘and.’ */
export type DrsMockfileexecutorFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DrsMockfileexecutorFilter>>;
  /** Filter by the object’s `drsFileexecutorByFileexecutorPtrId` relation. */
  drsFileexecutorByFileexecutorPtrId?: InputMaybe<DrsFileexecutorFilter>;
  /** Filter by the object’s `fileexecutorPtrId` field. */
  fileexecutorPtrId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DrsMockfileexecutorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DrsMockfileexecutorFilter>>;
};

/** An input for mutations affecting `DrsMockfileexecutor` */
export type DrsMockfileexecutorInput = {
  drsFileexecutorToFileexecutorPtrId?: InputMaybe<DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInput>;
  fileexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `drsMockfileexecutor` to look up the row to update. */
export type DrsMockfileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeUsingDrsMockfileexecutorPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `drsMockfileexecutor` being updated. */
    drsMockfileexecutorPatch: UpdateDrsMockfileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileePatch;
    fileexecutorPtrId: Scalars["UUID"]["input"];
  };

/** Represents an update to a `DrsMockfileexecutor`. Fields that are set will be updated. */
export type DrsMockfileexecutorPatch = {
  drsFileexecutorToFileexecutorPtrId?: InputMaybe<DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInput>;
  fileexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A connection to a list of `DrsMockfileexecutor` values. */
export type DrsMockfileexecutorsConnection = {
  __typename: "DrsMockfileexecutorsConnection";
  /** A list of edges which contains the `DrsMockfileexecutor` and cursor to aid in pagination. */
  edges: Array<DrsMockfileexecutorsEdge>;
  /** A list of `DrsMockfileexecutor` objects. */
  nodes: Array<DrsMockfileexecutor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DrsMockfileexecutor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `DrsMockfileexecutor` edge in the connection. */
export type DrsMockfileexecutorsEdge = {
  __typename: "DrsMockfileexecutorsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DrsMockfileexecutor` at the end of the edge. */
  node: DrsMockfileexecutor;
};

/** Methods to use when ordering `DrsMockfileexecutor`. */
export enum DrsMockfileexecutorsOrderBy {
  FileexecutorPtrIdAsc = "FILEEXECUTOR_PTR_ID_ASC",
  FileexecutorPtrIdDesc = "FILEEXECUTOR_PTR_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type EulaVersion = {
  __typename: "EulaVersion";
  content: Scalars["String"]["output"];
  dateCreated: Scalars["Datetime"]["output"];
  dateVersion: Scalars["Datetime"]["output"];
  /** Reads and enables pagination through a set of `EulaVersionUserAcceptance`. */
  eulaVersionUserAcceptancesByEulaVersionId: EulaVersionUserAcceptancesConnection;
  id: Scalars["Int"]["output"];
  version: Scalars["String"]["output"];
};

export type EulaVersionEulaVersionUserAcceptancesByEulaVersionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<EulaVersionUserAcceptanceCondition>;
  filter?: InputMaybe<EulaVersionUserAcceptanceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<EulaVersionUserAcceptancesOrderBy>>;
};

/**
 * A condition to be used against `EulaVersion` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type EulaVersionCondition = {
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateVersion` field. */
  dateVersion?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars["String"]["input"]>;
};

/** The fields on `eulaVersion` to look up the row to connect. */
export type EulaVersionEulaVersionPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `eulaVersion` to look up the row to delete. */
export type EulaVersionEulaVersionPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** The fields on `eulaVersion` to look up the row to connect. */
export type EulaVersionEulaVersionVersionKeyConnect = {
  version: Scalars["String"]["input"];
};

/** The fields on `eulaVersion` to look up the row to delete. */
export type EulaVersionEulaVersionVersionKeyDelete = {
  version: Scalars["String"]["input"];
};

/** A filter to be used against `EulaVersion` object types. All fields are combined with a logical ‘and.’ */
export type EulaVersionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EulaVersionFilter>>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateVersion` field. */
  dateVersion?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `eulaVersionUserAcceptancesByEulaVersionId` relation. */
  eulaVersionUserAcceptancesByEulaVersionId?: InputMaybe<EulaVersionToManyEulaVersionUserAcceptanceFilter>;
  /** Some related `eulaVersionUserAcceptancesByEulaVersionId` exist. */
  eulaVersionUserAcceptancesByEulaVersionIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EulaVersionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EulaVersionFilter>>;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `EulaVersion` */
export type EulaVersionInput = {
  content: Scalars["String"]["input"];
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateVersion?: InputMaybe<Scalars["Datetime"]["input"]>;
  eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  version: Scalars["String"]["input"];
};

/** The fields on `eulaVersion` to look up the row to update. */
export type EulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `eulaVersion` being updated. */
    eulaVersionPatch: UpdateEulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `eulaVersion` to look up the row to update. */
export type EulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionVersionKeyUpdate =
  {
    /** An object where the defined keys will be set on the `eulaVersion` being updated. */
    eulaVersionPatch: UpdateEulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersPatch;
    version: Scalars["String"]["input"];
  };

/** Represents an update to a `EulaVersion`. Fields that are set will be updated. */
export type EulaVersionPatch = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateVersion?: InputMaybe<Scalars["Datetime"]["input"]>;
  eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against many `EulaVersionUserAcceptance` object types. All fields are combined with a logical ‘and.’ */
export type EulaVersionToManyEulaVersionUserAcceptanceFilter = {
  /** Every related `EulaVersionUserAcceptance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<EulaVersionUserAcceptanceFilter>;
  /** No related `EulaVersionUserAcceptance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<EulaVersionUserAcceptanceFilter>;
  /** Some related `EulaVersionUserAcceptance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<EulaVersionUserAcceptanceFilter>;
};

/** The `eulaVersion` to be created by this mutation. */
export type EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersEulaVersionCreateInput =
  {
    content: Scalars["String"]["input"];
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateVersion?: InputMaybe<Scalars["Datetime"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    version: Scalars["String"]["input"];
  };

/** The `eulaVersionUserAcceptance` to be created by this mutation. */
export type EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersEulaVersionUserAcceptanceCreateInput =
  {
    accepted: Scalars["Boolean"]["input"];
    applicationUserToUserId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInput>;
    dateReviewed?: InputMaybe<Scalars["Datetime"]["input"]>;
    eulaVersionToEulaVersionId?: InputMaybe<EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInput>;
    id: Scalars["UUID"]["input"];
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `eulaVersion` in the `EulaVersionUserAcceptanceInput` mutation. */
export type EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInput = {
  /** The primary key(s) for `eulaVersion` for the far side of the relationship. */
  connectById?: InputMaybe<EulaVersionEulaVersionPkeyConnect>;
  /** The primary key(s) for `eulaVersion` for the far side of the relationship. */
  connectByVersion?: InputMaybe<EulaVersionEulaVersionVersionKeyConnect>;
  /** A `EulaVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersEulaVersionCreateInput>;
  /** The primary key(s) for `eulaVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<EulaVersionEulaVersionPkeyDelete>;
  /** The primary key(s) for `eulaVersion` for the far side of the relationship. */
  deleteByVersion?: InputMaybe<EulaVersionEulaVersionVersionKeyDelete>;
  /** The primary key(s) and patch data for `eulaVersion` for the far side of the relationship. */
  updateById?: InputMaybe<EulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionPkeyUpdate>;
  /** The primary key(s) and patch data for `eulaVersion` for the far side of the relationship. */
  updateByVersion?: InputMaybe<EulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionVersionKeyUpdate>;
};

/** Input for the nested mutation of `eulaVersionUserAcceptance` in the `EulaVersionInput` mutation. */
export type EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInverseInput = {
  /** The primary key(s) for `eulaVersionUserAcceptance` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<EulaVersionUserAcceptanceEulaVersionUserAcceptancePkeyConnect>
  >;
  /** The primary key(s) for `eulaVersionUserAcceptance` for the far side of the relationship. */
  connectByUserIdAndEulaVersionId?: InputMaybe<
    Array<EulaVersionUserAcceptanceEulaVersionUserAcceptanceUniqueConnect>
  >;
  /** A `EulaVersionUserAcceptanceInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersEulaVersionUserAcceptanceCreateInput>
  >;
  /** The primary key(s) for `eulaVersionUserAcceptance` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<EulaVersionUserAcceptanceEulaVersionUserAcceptancePkeyDelete>
  >;
  /** The primary key(s) for `eulaVersionUserAcceptance` for the far side of the relationship. */
  deleteByUserIdAndEulaVersionId?: InputMaybe<
    Array<EulaVersionUserAcceptanceEulaVersionUserAcceptanceUniqueDelete>
  >;
  /** Flag indicating whether all other `eulaVersionUserAcceptance` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `eulaVersionUserAcceptance` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionUserAcceptancePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `eulaVersionUserAcceptance` for the far side of the relationship. */
  updateByUserIdAndEulaVersionId?: InputMaybe<
    Array<EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionUserAcceptanceUniqueUpdate>
  >;
};

/** The `applicationUser` to be created by this mutation. */
export type EulaVersionUserAcUserIdB72Ed9C5FkApplicatiApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** The `eulaVersionUserAcceptance` to be created by this mutation. */
export type EulaVersionUserAcUserIdB72Ed9C5FkApplicatiEulaVersionUserAcceptanceCreateInput =
  {
    accepted: Scalars["Boolean"]["input"];
    applicationUserToUserId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInput>;
    dateReviewed?: InputMaybe<Scalars["Datetime"]["input"]>;
    eulaVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    eulaVersionToEulaVersionId?: InputMaybe<EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInput>;
    id: Scalars["UUID"]["input"];
  };

/** Input for the nested mutation of `applicationUser` in the `EulaVersionUserAcceptanceInput` mutation. */
export type EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `eulaVersionUserAcceptance` in the `ApplicationUserInput` mutation. */
export type EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput = {
  /** The primary key(s) for `eulaVersionUserAcceptance` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<EulaVersionUserAcceptanceEulaVersionUserAcceptancePkeyConnect>
  >;
  /** The primary key(s) for `eulaVersionUserAcceptance` for the far side of the relationship. */
  connectByUserIdAndEulaVersionId?: InputMaybe<
    Array<EulaVersionUserAcceptanceEulaVersionUserAcceptanceUniqueConnect>
  >;
  /** A `EulaVersionUserAcceptanceInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiEulaVersionUserAcceptanceCreateInput>
  >;
  /** The primary key(s) for `eulaVersionUserAcceptance` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<EulaVersionUserAcceptanceEulaVersionUserAcceptancePkeyDelete>
  >;
  /** The primary key(s) for `eulaVersionUserAcceptance` for the far side of the relationship. */
  deleteByUserIdAndEulaVersionId?: InputMaybe<
    Array<EulaVersionUserAcceptanceEulaVersionUserAcceptanceUniqueDelete>
  >;
  /** Flag indicating whether all other `eulaVersionUserAcceptance` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `eulaVersionUserAcceptance` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingEulaVersionUserAcceptancePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `eulaVersionUserAcceptance` for the far side of the relationship. */
  updateByUserIdAndEulaVersionId?: InputMaybe<
    Array<EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingEulaVersionUserAcceptanceUniqueUpdate>
  >;
};

export type EulaVersionUserAcceptance = {
  __typename: "EulaVersionUserAcceptance";
  accepted: Scalars["Boolean"]["output"];
  /** Reads a single `ApplicationUser` that is related to this `EulaVersionUserAcceptance`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  dateReviewed: Scalars["Datetime"]["output"];
  /** Reads a single `EulaVersion` that is related to this `EulaVersionUserAcceptance`. */
  eulaVersionByEulaVersionId: Maybe<EulaVersion>;
  eulaVersionId: Scalars["Int"]["output"];
  id: Scalars["UUID"]["output"];
  userId: Scalars["UUID"]["output"];
};

/**
 * A condition to be used against `EulaVersionUserAcceptance` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type EulaVersionUserAcceptanceCondition = {
  /** Checks for equality with the object’s `accepted` field. */
  accepted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `dateReviewed` field. */
  dateReviewed?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `eulaVersionId` field. */
  eulaVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `eulaVersionUserAcceptance` to look up the row to connect. */
export type EulaVersionUserAcceptanceEulaVersionUserAcceptancePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `eulaVersionUserAcceptance` to look up the row to delete. */
export type EulaVersionUserAcceptanceEulaVersionUserAcceptancePkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `eulaVersionUserAcceptance` to look up the row to connect. */
export type EulaVersionUserAcceptanceEulaVersionUserAcceptanceUniqueConnect = {
  eulaVersionId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The fields on `eulaVersionUserAcceptance` to look up the row to delete. */
export type EulaVersionUserAcceptanceEulaVersionUserAcceptanceUniqueDelete = {
  eulaVersionId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** A filter to be used against `EulaVersionUserAcceptance` object types. All fields are combined with a logical ‘and.’ */
export type EulaVersionUserAcceptanceFilter = {
  /** Filter by the object’s `accepted` field. */
  accepted?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EulaVersionUserAcceptanceFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `dateReviewed` field. */
  dateReviewed?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `eulaVersionByEulaVersionId` relation. */
  eulaVersionByEulaVersionId?: InputMaybe<EulaVersionFilter>;
  /** Filter by the object’s `eulaVersionId` field. */
  eulaVersionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EulaVersionUserAcceptanceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EulaVersionUserAcceptanceFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `EulaVersionUserAcceptance` */
export type EulaVersionUserAcceptanceInput = {
  accepted: Scalars["Boolean"]["input"];
  applicationUserToUserId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInput>;
  dateReviewed?: InputMaybe<Scalars["Datetime"]["input"]>;
  eulaVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  eulaVersionToEulaVersionId?: InputMaybe<EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInput>;
  id: Scalars["UUID"]["input"];
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `eulaVersionUserAcceptance` to look up the row to update. */
export type EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionUserAcceptancePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `eulaVersionUserAcceptance` being updated. */
    eulaVersionUserAcceptancePatch: UpdateEulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `eulaVersionUserAcceptance` to look up the row to update. */
export type EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionUserAcceptanceUniqueUpdate =
  {
    eulaVersionId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `eulaVersionUserAcceptance` being updated. */
    eulaVersionUserAcceptancePatch: UpdateEulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersPatch;
    userId: Scalars["UUID"]["input"];
  };

/** The fields on `eulaVersionUserAcceptance` to look up the row to update. */
export type EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingEulaVersionUserAcceptancePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `eulaVersionUserAcceptance` being updated. */
    eulaVersionUserAcceptancePatch: UpdateEulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `eulaVersionUserAcceptance` to look up the row to update. */
export type EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingEulaVersionUserAcceptanceUniqueUpdate =
  {
    eulaVersionId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `eulaVersionUserAcceptance` being updated. */
    eulaVersionUserAcceptancePatch: UpdateEulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiPatch;
    userId: Scalars["UUID"]["input"];
  };

/** Represents an update to a `EulaVersionUserAcceptance`. Fields that are set will be updated. */
export type EulaVersionUserAcceptancePatch = {
  accepted?: InputMaybe<Scalars["Boolean"]["input"]>;
  applicationUserToUserId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInput>;
  dateReviewed?: InputMaybe<Scalars["Datetime"]["input"]>;
  eulaVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  eulaVersionToEulaVersionId?: InputMaybe<EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A connection to a list of `EulaVersionUserAcceptance` values. */
export type EulaVersionUserAcceptancesConnection = {
  __typename: "EulaVersionUserAcceptancesConnection";
  /** A list of edges which contains the `EulaVersionUserAcceptance` and cursor to aid in pagination. */
  edges: Array<EulaVersionUserAcceptancesEdge>;
  /** A list of `EulaVersionUserAcceptance` objects. */
  nodes: Array<EulaVersionUserAcceptance>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EulaVersionUserAcceptance` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `EulaVersionUserAcceptance` edge in the connection. */
export type EulaVersionUserAcceptancesEdge = {
  __typename: "EulaVersionUserAcceptancesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `EulaVersionUserAcceptance` at the end of the edge. */
  node: EulaVersionUserAcceptance;
};

/** Methods to use when ordering `EulaVersionUserAcceptance`. */
export enum EulaVersionUserAcceptancesOrderBy {
  AcceptedAsc = "ACCEPTED_ASC",
  AcceptedDesc = "ACCEPTED_DESC",
  DateReviewedAsc = "DATE_REVIEWED_ASC",
  DateReviewedDesc = "DATE_REVIEWED_DESC",
  EulaVersionIdAsc = "EULA_VERSION_ID_ASC",
  EulaVersionIdDesc = "EULA_VERSION_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** A connection to a list of `EulaVersion` values. */
export type EulaVersionsConnection = {
  __typename: "EulaVersionsConnection";
  /** A list of edges which contains the `EulaVersion` and cursor to aid in pagination. */
  edges: Array<EulaVersionsEdge>;
  /** A list of `EulaVersion` objects. */
  nodes: Array<EulaVersion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EulaVersion` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `EulaVersion` edge in the connection. */
export type EulaVersionsEdge = {
  __typename: "EulaVersionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `EulaVersion` at the end of the edge. */
  node: EulaVersion;
};

/** Methods to use when ordering `EulaVersion`. */
export enum EulaVersionsOrderBy {
  ContentAsc = "CONTENT_ASC",
  ContentDesc = "CONTENT_DESC",
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  DateVersionAsc = "DATE_VERSION_ASC",
  DateVersionDesc = "DATE_VERSION_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  VersionAsc = "VERSION_ASC",
  VersionDesc = "VERSION_DESC",
}

/** A connection to a list of `ExplorerQuery` values. */
export type ExplorerQueriesConnection = {
  __typename: "ExplorerQueriesConnection";
  /** A list of edges which contains the `ExplorerQuery` and cursor to aid in pagination. */
  edges: Array<ExplorerQueriesEdge>;
  /** A list of `ExplorerQuery` objects. */
  nodes: Array<ExplorerQuery>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ExplorerQuery` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ExplorerQuery` edge in the connection. */
export type ExplorerQueriesEdge = {
  __typename: "ExplorerQueriesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ExplorerQuery` at the end of the edge. */
  node: ExplorerQuery;
};

/** Methods to use when ordering `ExplorerQuery`. */
export enum ExplorerQueriesOrderBy {
  ConnectionAsc = "CONNECTION_ASC",
  ConnectionDesc = "CONNECTION_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByUserIdAsc = "CREATED_BY_USER_ID_ASC",
  CreatedByUserIdDesc = "CREATED_BY_USER_ID_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastRunDateAsc = "LAST_RUN_DATE_ASC",
  LastRunDateDesc = "LAST_RUN_DATE_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SnapshotAsc = "SNAPSHOT_ASC",
  SnapshotDesc = "SNAPSHOT_DESC",
  SqlAsc = "SQL_ASC",
  SqlDesc = "SQL_DESC",
  TitleAsc = "TITLE_ASC",
  TitleDesc = "TITLE_DESC",
}

export type ExplorerQuery = {
  __typename: "ExplorerQuery";
  /** Reads a single `ApplicationUser` that is related to this `ExplorerQuery`. */
  applicationUserByCreatedByUserId: Maybe<ApplicationUser>;
  connection: Scalars["String"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  createdByUserId: Maybe<Scalars["UUID"]["output"]>;
  description: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `ExplorerQueryfavorite`. */
  explorerQueryfavoritesByQueryId: ExplorerQueryfavoritesConnection;
  /** Reads and enables pagination through a set of `ExplorerQuerylog`. */
  explorerQuerylogsByQueryId: ExplorerQuerylogsConnection;
  id: Scalars["Int"]["output"];
  lastRunDate: Scalars["Datetime"]["output"];
  snapshot: Scalars["Boolean"]["output"];
  sql: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type ExplorerQueryExplorerQueryfavoritesByQueryIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ExplorerQueryfavoriteCondition>;
  filter?: InputMaybe<ExplorerQueryfavoriteFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExplorerQueryfavoritesOrderBy>>;
};

export type ExplorerQueryExplorerQuerylogsByQueryIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ExplorerQuerylogCondition>;
  filter?: InputMaybe<ExplorerQuerylogFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExplorerQuerylogsOrderBy>>;
};

/**
 * A condition to be used against `ExplorerQuery` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ExplorerQueryCondition = {
  /** Checks for equality with the object’s `connection` field. */
  connection?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `createdByUserId` field. */
  createdByUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `lastRunDate` field. */
  lastRunDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `snapshot` field. */
  snapshot?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `sql` field. */
  sql?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars["String"]["input"]>;
};

/** The `applicationUser` to be created by this mutation. */
export type ExplorerQueryCreatedByUserId182Dd868FkApplicatiApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** The `explorerQuery` to be created by this mutation. */
export type ExplorerQueryCreatedByUserId182Dd868FkApplicatiExplorerQueryCreateInput =
  {
    applicationUserToCreatedByUserId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInput>;
    connection: Scalars["String"]["input"];
    createdAt: Scalars["Datetime"]["input"];
    description: Scalars["String"]["input"];
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    lastRunDate: Scalars["Datetime"]["input"];
    snapshot: Scalars["Boolean"]["input"];
    sql: Scalars["String"]["input"];
    title: Scalars["String"]["input"];
  };

/** Input for the nested mutation of `applicationUser` in the `ExplorerQueryInput` mutation. */
export type ExplorerQueryCreatedByUserId182Dd868FkApplicatiInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `explorerQuery` in the `ApplicationUserInput` mutation. */
export type ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput = {
  /** The primary key(s) for `explorerQuery` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ExplorerQueryExplorerQueryPkeyConnect>>;
  /** A `ExplorerQueryInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ExplorerQueryCreatedByUserId182Dd868FkApplicatiExplorerQueryCreateInput>
  >;
  /** The primary key(s) for `explorerQuery` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ExplorerQueryExplorerQueryPkeyDelete>>;
  /** Flag indicating whether all other `explorerQuery` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `explorerQuery` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ExplorerQueryOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingExplorerQueryPkeyUpdate>
  >;
};

/** The fields on `explorerQuery` to look up the row to connect. */
export type ExplorerQueryExplorerQueryPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `explorerQuery` to look up the row to delete. */
export type ExplorerQueryExplorerQueryPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** A filter to be used against `ExplorerQuery` object types. All fields are combined with a logical ‘and.’ */
export type ExplorerQueryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ExplorerQueryFilter>>;
  /** Filter by the object’s `applicationUserByCreatedByUserId` relation. */
  applicationUserByCreatedByUserId?: InputMaybe<ApplicationUserFilter>;
  /** A related `applicationUserByCreatedByUserId` exists. */
  applicationUserByCreatedByUserIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `connection` field. */
  connection?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByUserId` field. */
  createdByUserId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `explorerQueryfavoritesByQueryId` relation. */
  explorerQueryfavoritesByQueryId?: InputMaybe<ExplorerQueryToManyExplorerQueryfavoriteFilter>;
  /** Some related `explorerQueryfavoritesByQueryId` exist. */
  explorerQueryfavoritesByQueryIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `explorerQuerylogsByQueryId` relation. */
  explorerQuerylogsByQueryId?: InputMaybe<ExplorerQueryToManyExplorerQuerylogFilter>;
  /** Some related `explorerQuerylogsByQueryId` exist. */
  explorerQuerylogsByQueryIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lastRunDate` field. */
  lastRunDate?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ExplorerQueryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ExplorerQueryFilter>>;
  /** Filter by the object’s `snapshot` field. */
  snapshot?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `sql` field. */
  sql?: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `ExplorerQuery` */
export type ExplorerQueryInput = {
  applicationUserToCreatedByUserId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInput>;
  connection: Scalars["String"]["input"];
  createdAt: Scalars["Datetime"]["input"];
  createdByUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  description: Scalars["String"]["input"];
  explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInverseInput>;
  explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  lastRunDate: Scalars["Datetime"]["input"];
  snapshot: Scalars["Boolean"]["input"];
  sql: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

/** The fields on `explorerQuery` to look up the row to update. */
export type ExplorerQueryOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingExplorerQueryPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `explorerQuery` being updated. */
    explorerQueryPatch: UpdateExplorerQueryOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `explorerQuery` to look up the row to update. */
export type ExplorerQueryOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdUsingExplorerQueryPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `explorerQuery` being updated. */
    explorerQueryPatch: UpdateExplorerQueryOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `explorerQuery` to look up the row to update. */
export type ExplorerQueryOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdUsingExplorerQueryPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `explorerQuery` being updated. */
    explorerQueryPatch: UpdateExplorerQueryOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdPatch;
    id: Scalars["Int"]["input"];
  };

/** Represents an update to a `ExplorerQuery`. Fields that are set will be updated. */
export type ExplorerQueryPatch = {
  applicationUserToCreatedByUserId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInput>;
  connection?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  createdByUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInverseInput>;
  explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  lastRunDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  snapshot?: InputMaybe<Scalars["Boolean"]["input"]>;
  sql?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against many `ExplorerQueryfavorite` object types. All fields are combined with a logical ‘and.’ */
export type ExplorerQueryToManyExplorerQueryfavoriteFilter = {
  /** Every related `ExplorerQueryfavorite` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ExplorerQueryfavoriteFilter>;
  /** No related `ExplorerQueryfavorite` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ExplorerQueryfavoriteFilter>;
  /** Some related `ExplorerQueryfavorite` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ExplorerQueryfavoriteFilter>;
};

/** A filter to be used against many `ExplorerQuerylog` object types. All fields are combined with a logical ‘and.’ */
export type ExplorerQueryToManyExplorerQuerylogFilter = {
  /** Every related `ExplorerQuerylog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ExplorerQuerylogFilter>;
  /** No related `ExplorerQuerylog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ExplorerQuerylogFilter>;
  /** Some related `ExplorerQuerylog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ExplorerQuerylogFilter>;
};

export type ExplorerQueryfavorite = {
  __typename: "ExplorerQueryfavorite";
  /** Reads a single `ApplicationUser` that is related to this `ExplorerQueryfavorite`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** Reads a single `ExplorerQuery` that is related to this `ExplorerQueryfavorite`. */
  explorerQueryByQueryId: Maybe<ExplorerQuery>;
  id: Scalars["Int"]["output"];
  queryId: Scalars["Int"]["output"];
  userId: Scalars["UUID"]["output"];
};

/**
 * A condition to be used against `ExplorerQueryfavorite` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ExplorerQueryfavoriteCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `queryId` field. */
  queryId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `explorerQueryfavorite` to look up the row to connect. */
export type ExplorerQueryfavoriteExplorerQueryfavoritePkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `explorerQueryfavorite` to look up the row to delete. */
export type ExplorerQueryfavoriteExplorerQueryfavoritePkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** The fields on `explorerQueryfavorite` to look up the row to connect. */
export type ExplorerQueryfavoriteExplorerQueryfavoriteQueryIdUserId602B82DeUniqConnect =
  {
    queryId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/** The fields on `explorerQueryfavorite` to look up the row to delete. */
export type ExplorerQueryfavoriteExplorerQueryfavoriteQueryIdUserId602B82DeUniqDelete =
  {
    queryId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/** A filter to be used against `ExplorerQueryfavorite` object types. All fields are combined with a logical ‘and.’ */
export type ExplorerQueryfavoriteFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ExplorerQueryfavoriteFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `explorerQueryByQueryId` relation. */
  explorerQueryByQueryId?: InputMaybe<ExplorerQueryFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ExplorerQueryfavoriteFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ExplorerQueryfavoriteFilter>>;
  /** Filter by the object’s `queryId` field. */
  queryId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `ExplorerQueryfavorite` */
export type ExplorerQueryfavoriteInput = {
  applicationUserToUserId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInput>;
  explorerQueryToQueryId?: InputMaybe<ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  queryId?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `explorerQueryfavorite` to look up the row to update. */
export type ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdUsingExplorerQueryfavoritePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `explorerQueryfavorite` being updated. */
    explorerQueryfavoritePatch: UpdateExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `explorerQueryfavorite` to look up the row to update. */
export type ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdUsingExplorerQueryfavoriteQueryIdUserId602B82DeUniqUpdate =
  {
    /** An object where the defined keys will be set on the `explorerQueryfavorite` being updated. */
    explorerQueryfavoritePatch: UpdateExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdPatch;
    queryId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/** The fields on `explorerQueryfavorite` to look up the row to update. */
export type ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingExplorerQueryfavoritePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `explorerQueryfavorite` being updated. */
    explorerQueryfavoritePatch: UpdateExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `explorerQueryfavorite` to look up the row to update. */
export type ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingExplorerQueryfavoriteQueryIdUserId602B82DeUniqUpdate =
  {
    /** An object where the defined keys will be set on the `explorerQueryfavorite` being updated. */
    explorerQueryfavoritePatch: UpdateExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdPatch;
    queryId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/** Represents an update to a `ExplorerQueryfavorite`. Fields that are set will be updated. */
export type ExplorerQueryfavoritePatch = {
  applicationUserToUserId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInput>;
  explorerQueryToQueryId?: InputMaybe<ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  queryId?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `explorerQuery` to be created by this mutation. */
export type ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdExplorerQueryCreateInput =
  {
    applicationUserToCreatedByUserId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInput>;
    connection: Scalars["String"]["input"];
    createdAt: Scalars["Datetime"]["input"];
    createdByUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    description: Scalars["String"]["input"];
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    lastRunDate: Scalars["Datetime"]["input"];
    snapshot: Scalars["Boolean"]["input"];
    sql: Scalars["String"]["input"];
    title: Scalars["String"]["input"];
  };

/** The `explorerQueryfavorite` to be created by this mutation. */
export type ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdExplorerQueryfavoriteCreateInput =
  {
    applicationUserToUserId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInput>;
    explorerQueryToQueryId?: InputMaybe<ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `explorerQuery` in the `ExplorerQueryfavoriteInput` mutation. */
export type ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInput = {
  /** The primary key(s) for `explorerQuery` for the far side of the relationship. */
  connectById?: InputMaybe<ExplorerQueryExplorerQueryPkeyConnect>;
  /** A `ExplorerQueryInput` object that will be created and connected to this object. */
  create?: InputMaybe<ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdExplorerQueryCreateInput>;
  /** The primary key(s) for `explorerQuery` for the far side of the relationship. */
  deleteById?: InputMaybe<ExplorerQueryExplorerQueryPkeyDelete>;
  /** The primary key(s) and patch data for `explorerQuery` for the far side of the relationship. */
  updateById?: InputMaybe<ExplorerQueryOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdUsingExplorerQueryPkeyUpdate>;
};

/** Input for the nested mutation of `explorerQueryfavorite` in the `ExplorerQueryInput` mutation. */
export type ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInverseInput =
  {
    /** The primary key(s) for `explorerQueryfavorite` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<ExplorerQueryfavoriteExplorerQueryfavoritePkeyConnect>
    >;
    /** The primary key(s) for `explorerQueryfavorite` for the far side of the relationship. */
    connectByQueryIdAndUserId?: InputMaybe<
      Array<ExplorerQueryfavoriteExplorerQueryfavoriteQueryIdUserId602B82DeUniqConnect>
    >;
    /** A `ExplorerQueryfavoriteInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdExplorerQueryfavoriteCreateInput>
    >;
    /** The primary key(s) for `explorerQueryfavorite` for the far side of the relationship. */
    deleteById?: InputMaybe<
      Array<ExplorerQueryfavoriteExplorerQueryfavoritePkeyDelete>
    >;
    /** The primary key(s) for `explorerQueryfavorite` for the far side of the relationship. */
    deleteByQueryIdAndUserId?: InputMaybe<
      Array<ExplorerQueryfavoriteExplorerQueryfavoriteQueryIdUserId602B82DeUniqDelete>
    >;
    /** Flag indicating whether all other `explorerQueryfavorite` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `explorerQueryfavorite` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdUsingExplorerQueryfavoritePkeyUpdate>
    >;
    /** The primary key(s) and patch data for `explorerQueryfavorite` for the far side of the relationship. */
    updateByQueryIdAndUserId?: InputMaybe<
      Array<ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdUsingExplorerQueryfavoriteQueryIdUserId602B82DeUniqUpdate>
    >;
  };

/** The `applicationUser` to be created by this mutation. */
export type ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** The `explorerQueryfavorite` to be created by this mutation. */
export type ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdExplorerQueryfavoriteCreateInput =
  {
    applicationUserToUserId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInput>;
    explorerQueryToQueryId?: InputMaybe<ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    queryId?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** Input for the nested mutation of `applicationUser` in the `ExplorerQueryfavoriteInput` mutation. */
export type ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `explorerQueryfavorite` in the `ApplicationUserInput` mutation. */
export type ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput =
  {
    /** The primary key(s) for `explorerQueryfavorite` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<ExplorerQueryfavoriteExplorerQueryfavoritePkeyConnect>
    >;
    /** The primary key(s) for `explorerQueryfavorite` for the far side of the relationship. */
    connectByQueryIdAndUserId?: InputMaybe<
      Array<ExplorerQueryfavoriteExplorerQueryfavoriteQueryIdUserId602B82DeUniqConnect>
    >;
    /** A `ExplorerQueryfavoriteInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdExplorerQueryfavoriteCreateInput>
    >;
    /** The primary key(s) for `explorerQueryfavorite` for the far side of the relationship. */
    deleteById?: InputMaybe<
      Array<ExplorerQueryfavoriteExplorerQueryfavoritePkeyDelete>
    >;
    /** The primary key(s) for `explorerQueryfavorite` for the far side of the relationship. */
    deleteByQueryIdAndUserId?: InputMaybe<
      Array<ExplorerQueryfavoriteExplorerQueryfavoriteQueryIdUserId602B82DeUniqDelete>
    >;
    /** Flag indicating whether all other `explorerQueryfavorite` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `explorerQueryfavorite` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingExplorerQueryfavoritePkeyUpdate>
    >;
    /** The primary key(s) and patch data for `explorerQueryfavorite` for the far side of the relationship. */
    updateByQueryIdAndUserId?: InputMaybe<
      Array<ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingExplorerQueryfavoriteQueryIdUserId602B82DeUniqUpdate>
    >;
  };

/** A connection to a list of `ExplorerQueryfavorite` values. */
export type ExplorerQueryfavoritesConnection = {
  __typename: "ExplorerQueryfavoritesConnection";
  /** A list of edges which contains the `ExplorerQueryfavorite` and cursor to aid in pagination. */
  edges: Array<ExplorerQueryfavoritesEdge>;
  /** A list of `ExplorerQueryfavorite` objects. */
  nodes: Array<ExplorerQueryfavorite>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ExplorerQueryfavorite` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ExplorerQueryfavorite` edge in the connection. */
export type ExplorerQueryfavoritesEdge = {
  __typename: "ExplorerQueryfavoritesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ExplorerQueryfavorite` at the end of the edge. */
  node: ExplorerQueryfavorite;
};

/** Methods to use when ordering `ExplorerQueryfavorite`. */
export enum ExplorerQueryfavoritesOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  QueryIdAsc = "QUERY_ID_ASC",
  QueryIdDesc = "QUERY_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type ExplorerQuerylog = {
  __typename: "ExplorerQuerylog";
  /** Reads a single `ApplicationUser` that is related to this `ExplorerQuerylog`. */
  applicationUserByRunByUserId: Maybe<ApplicationUser>;
  connection: Scalars["String"]["output"];
  duration: Maybe<Scalars["Float"]["output"]>;
  /** Reads a single `ExplorerQuery` that is related to this `ExplorerQuerylog`. */
  explorerQueryByQueryId: Maybe<ExplorerQuery>;
  id: Scalars["Int"]["output"];
  queryId: Maybe<Scalars["Int"]["output"]>;
  runAt: Scalars["Datetime"]["output"];
  runByUserId: Maybe<Scalars["UUID"]["output"]>;
  sql: Scalars["String"]["output"];
};

/**
 * A condition to be used against `ExplorerQuerylog` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ExplorerQuerylogCondition = {
  /** Checks for equality with the object’s `connection` field. */
  connection?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `duration` field. */
  duration?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `queryId` field. */
  queryId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `runAt` field. */
  runAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `runByUserId` field. */
  runByUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `sql` field. */
  sql?: InputMaybe<Scalars["String"]["input"]>;
};

/** The fields on `explorerQuerylog` to look up the row to connect. */
export type ExplorerQuerylogExplorerQuerylogPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `explorerQuerylog` to look up the row to delete. */
export type ExplorerQuerylogExplorerQuerylogPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** A filter to be used against `ExplorerQuerylog` object types. All fields are combined with a logical ‘and.’ */
export type ExplorerQuerylogFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ExplorerQuerylogFilter>>;
  /** Filter by the object’s `applicationUserByRunByUserId` relation. */
  applicationUserByRunByUserId?: InputMaybe<ApplicationUserFilter>;
  /** A related `applicationUserByRunByUserId` exists. */
  applicationUserByRunByUserIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `connection` field. */
  connection?: InputMaybe<StringFilter>;
  /** Filter by the object’s `duration` field. */
  duration?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `explorerQueryByQueryId` relation. */
  explorerQueryByQueryId?: InputMaybe<ExplorerQueryFilter>;
  /** A related `explorerQueryByQueryId` exists. */
  explorerQueryByQueryIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ExplorerQuerylogFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ExplorerQuerylogFilter>>;
  /** Filter by the object’s `queryId` field. */
  queryId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `runAt` field. */
  runAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `runByUserId` field. */
  runByUserId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `sql` field. */
  sql?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `ExplorerQuerylog` */
export type ExplorerQuerylogInput = {
  applicationUserToRunByUserId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInput>;
  connection: Scalars["String"]["input"];
  duration?: InputMaybe<Scalars["Float"]["input"]>;
  explorerQueryToQueryId?: InputMaybe<ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  queryId?: InputMaybe<Scalars["Int"]["input"]>;
  runAt: Scalars["Datetime"]["input"];
  runByUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  sql: Scalars["String"]["input"];
};

/** The fields on `explorerQuerylog` to look up the row to update. */
export type ExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdUsingExplorerQuerylogPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `explorerQuerylog` being updated. */
    explorerQuerylogPatch: UpdateExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `explorerQuerylog` to look up the row to update. */
export type ExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingExplorerQuerylogPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `explorerQuerylog` being updated. */
    explorerQuerylogPatch: UpdateExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiPatch;
    id: Scalars["Int"]["input"];
  };

/** Represents an update to a `ExplorerQuerylog`. Fields that are set will be updated. */
export type ExplorerQuerylogPatch = {
  applicationUserToRunByUserId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInput>;
  connection?: InputMaybe<Scalars["String"]["input"]>;
  duration?: InputMaybe<Scalars["Float"]["input"]>;
  explorerQueryToQueryId?: InputMaybe<ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  queryId?: InputMaybe<Scalars["Int"]["input"]>;
  runAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  runByUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  sql?: InputMaybe<Scalars["String"]["input"]>;
};

/** The `explorerQuery` to be created by this mutation. */
export type ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdExplorerQueryCreateInput =
  {
    applicationUserToCreatedByUserId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInput>;
    connection: Scalars["String"]["input"];
    createdAt: Scalars["Datetime"]["input"];
    createdByUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    description: Scalars["String"]["input"];
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    lastRunDate: Scalars["Datetime"]["input"];
    snapshot: Scalars["Boolean"]["input"];
    sql: Scalars["String"]["input"];
    title: Scalars["String"]["input"];
  };

/** The `explorerQuerylog` to be created by this mutation. */
export type ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdExplorerQuerylogCreateInput =
  {
    applicationUserToRunByUserId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInput>;
    connection: Scalars["String"]["input"];
    duration?: InputMaybe<Scalars["Float"]["input"]>;
    explorerQueryToQueryId?: InputMaybe<ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    runAt: Scalars["Datetime"]["input"];
    runByUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    sql: Scalars["String"]["input"];
  };

/** Input for the nested mutation of `explorerQuery` in the `ExplorerQuerylogInput` mutation. */
export type ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInput = {
  /** The primary key(s) for `explorerQuery` for the far side of the relationship. */
  connectById?: InputMaybe<ExplorerQueryExplorerQueryPkeyConnect>;
  /** A `ExplorerQueryInput` object that will be created and connected to this object. */
  create?: InputMaybe<ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdExplorerQueryCreateInput>;
  /** The primary key(s) for `explorerQuery` for the far side of the relationship. */
  deleteById?: InputMaybe<ExplorerQueryExplorerQueryPkeyDelete>;
  /** The primary key(s) and patch data for `explorerQuery` for the far side of the relationship. */
  updateById?: InputMaybe<ExplorerQueryOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdUsingExplorerQueryPkeyUpdate>;
};

/** Input for the nested mutation of `explorerQuerylog` in the `ExplorerQueryInput` mutation. */
export type ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInverseInput = {
  /** The primary key(s) for `explorerQuerylog` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ExplorerQuerylogExplorerQuerylogPkeyConnect>>;
  /** A `ExplorerQuerylogInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdExplorerQuerylogCreateInput>
  >;
  /** The primary key(s) for `explorerQuerylog` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ExplorerQuerylogExplorerQuerylogPkeyDelete>>;
  /** Flag indicating whether all other `explorerQuerylog` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `explorerQuerylog` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdUsingExplorerQuerylogPkeyUpdate>
  >;
};

/** The `applicationUser` to be created by this mutation. */
export type ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** The `explorerQuerylog` to be created by this mutation. */
export type ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiExplorerQuerylogCreateInput =
  {
    applicationUserToRunByUserId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInput>;
    connection: Scalars["String"]["input"];
    duration?: InputMaybe<Scalars["Float"]["input"]>;
    explorerQueryToQueryId?: InputMaybe<ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    queryId?: InputMaybe<Scalars["Int"]["input"]>;
    runAt: Scalars["Datetime"]["input"];
    sql: Scalars["String"]["input"];
  };

/** Input for the nested mutation of `applicationUser` in the `ExplorerQuerylogInput` mutation. */
export type ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `explorerQuerylog` in the `ApplicationUserInput` mutation. */
export type ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput = {
  /** The primary key(s) for `explorerQuerylog` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ExplorerQuerylogExplorerQuerylogPkeyConnect>>;
  /** A `ExplorerQuerylogInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiExplorerQuerylogCreateInput>
  >;
  /** The primary key(s) for `explorerQuerylog` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ExplorerQuerylogExplorerQuerylogPkeyDelete>>;
  /** Flag indicating whether all other `explorerQuerylog` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `explorerQuerylog` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingExplorerQuerylogPkeyUpdate>
  >;
};

/** A connection to a list of `ExplorerQuerylog` values. */
export type ExplorerQuerylogsConnection = {
  __typename: "ExplorerQuerylogsConnection";
  /** A list of edges which contains the `ExplorerQuerylog` and cursor to aid in pagination. */
  edges: Array<ExplorerQuerylogsEdge>;
  /** A list of `ExplorerQuerylog` objects. */
  nodes: Array<ExplorerQuerylog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ExplorerQuerylog` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ExplorerQuerylog` edge in the connection. */
export type ExplorerQuerylogsEdge = {
  __typename: "ExplorerQuerylogsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ExplorerQuerylog` at the end of the edge. */
  node: ExplorerQuerylog;
};

/** Methods to use when ordering `ExplorerQuerylog`. */
export enum ExplorerQuerylogsOrderBy {
  ConnectionAsc = "CONNECTION_ASC",
  ConnectionDesc = "CONNECTION_DESC",
  DurationAsc = "DURATION_ASC",
  DurationDesc = "DURATION_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  QueryIdAsc = "QUERY_ID_ASC",
  QueryIdDesc = "QUERY_ID_DESC",
  RunAtAsc = "RUN_AT_ASC",
  RunAtDesc = "RUN_AT_DESC",
  RunByUserIdAsc = "RUN_BY_USER_ID_ASC",
  RunByUserIdDesc = "RUN_BY_USER_ID_DESC",
  SqlAsc = "SQL_ASC",
  SqlDesc = "SQL_DESC",
}

export type File = {
  __typename: "File";
  activeAssignment: Maybe<RecordingGroupFile>;
  /** Reads a single `ApplicationUser` that is related to this `File`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** Reads a single `Dataset` that is related to this `File`. */
  datasetByDatasetId: Maybe<Dataset>;
  datasetId: Maybe<Scalars["UUID"]["output"]>;
  dateArchived: Maybe<Scalars["Datetime"]["output"]>;
  dateCreated: Scalars["Datetime"]["output"];
  dateDataDeleted: Maybe<Scalars["Datetime"]["output"]>;
  dateDeleted: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads and enables pagination through a set of `FileActivity`. */
  fileActivitiesByFileId: FileActivitiesConnection;
  /** Reads a single `File` that is related to this `File`. */
  fileBySeriesParentId: Maybe<File>;
  fileFormat: Maybe<Scalars["Int"]["output"]>;
  /** Reads and enables pagination through a set of `FileMetadatum`. */
  fileMetadataByFileId: FileMetadataConnection;
  /** Reads and enables pagination through a set of `FileProcessingNotification`. */
  fileProcessingNotificationsByFileId: FileProcessingNotificationsConnection;
  fileStructure: Maybe<Scalars["Int"]["output"]>;
  fileType: Maybe<Scalars["Int"]["output"]>;
  /** Reads and enables pagination through a set of `File`. */
  filesBySeriesParentId: FilesConnection;
  id: Scalars["UUID"]["output"];
  isSeries: Scalars["Boolean"]["output"];
  key: Maybe<Scalars["String"]["output"]>;
  location: Maybe<Scalars["String"]["output"]>;
  multipartUploadId: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  originalName: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `OutputGroupFile`. */
  outputGroupFilesByFileId: OutputGroupFilesConnection;
  parentIds: Maybe<Scalars["JSON"]["output"]>;
  partSize: Maybe<Scalars["Int"]["output"]>;
  preview: Maybe<Scalars["JSON"]["output"]>;
  processingStatus: Scalars["Int"]["output"];
  /** Reads a single `Project` that is related to this `File`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  /** Reads and enables pagination through a set of `RecordingGroupFile`. */
  recordingGroupFilesByFileId: RecordingGroupFilesConnection;
  seriesOrder: Maybe<Scalars["Int"]["output"]>;
  seriesParentId: Maybe<Scalars["UUID"]["output"]>;
  signal: Maybe<Scalars["Int"]["output"]>;
  size: Maybe<Scalars["BigInt"]["output"]>;
  source: Maybe<FileSource>;
  status: Scalars["Int"]["output"];
  /** Reads and enables pagination through a set of `TaskSource`. */
  taskSourcesByFileId: TaskSourcesConnection;
  /** Reads a single `Tenant` that is related to this `File`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  uploadStatus: Maybe<Scalars["Int"]["output"]>;
  userId: Scalars["UUID"]["output"];
};

export type FileActiveAssignmentArgs = {
  at?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type FileFileActivitiesByFileIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileActivityCondition>;
  filter?: InputMaybe<FileActivityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileActivitiesOrderBy>>;
};

export type FileFileMetadataByFileIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileMetadatumCondition>;
  filter?: InputMaybe<FileMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileMetadataOrderBy>>;
};

export type FileFileProcessingNotificationsByFileIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileProcessingNotificationCondition>;
  filter?: InputMaybe<FileProcessingNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileProcessingNotificationsOrderBy>>;
};

export type FileFilesBySeriesParentIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileCondition>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type FileOutputGroupFilesByFileIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OutputGroupFileCondition>;
  filter?: InputMaybe<OutputGroupFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OutputGroupFilesOrderBy>>;
};

export type FileRecordingGroupFilesByFileIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<RecordingGroupFileCondition>;
  filter?: InputMaybe<RecordingGroupFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RecordingGroupFilesOrderBy>>;
};

export type FileTaskSourcesByFileIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskSourceCondition>;
  filter?: InputMaybe<TaskSourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TaskSourcesOrderBy>>;
};

/** A connection to a list of `FileActivity` values. */
export type FileActivitiesConnection = {
  __typename: "FileActivitiesConnection";
  /** A list of edges which contains the `FileActivity` and cursor to aid in pagination. */
  edges: Array<FileActivitiesEdge>;
  /** A list of `FileActivity` objects. */
  nodes: Array<FileActivity>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FileActivity` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `FileActivity` edge in the connection. */
export type FileActivitiesEdge = {
  __typename: "FileActivitiesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `FileActivity` at the end of the edge. */
  node: FileActivity;
};

/** Methods to use when ordering `FileActivity`. */
export enum FileActivitiesOrderBy {
  ActivityTypeAsc = "ACTIVITY_TYPE_ASC",
  ActivityTypeDesc = "ACTIVITY_TYPE_DESC",
  BytesAsc = "BYTES_ASC",
  BytesDesc = "BYTES_DESC",
  CommentAsc = "COMMENT_ASC",
  CommentDesc = "COMMENT_DESC",
  CostIdAsc = "COST_ID_ASC",
  CostIdDesc = "COST_ID_DESC",
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type FileActivity = {
  __typename: "FileActivity";
  activityType: Scalars["Int"]["output"];
  /** Reads a single `ApplicationUser` that is related to this `FileActivity`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  bytes: Maybe<Scalars["BigInt"]["output"]>;
  comment: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Cost` that is related to this `FileActivity`. */
  costByCostId: Maybe<Cost>;
  costId: Maybe<Scalars["BigInt"]["output"]>;
  dateCreated: Scalars["Datetime"]["output"];
  /** Reads a single `File` that is related to this `FileActivity`. */
  fileByFileId: Maybe<File>;
  fileId: Scalars["UUID"]["output"];
  id: Scalars["Int"]["output"];
  /** Reads a single `Project` that is related to this `FileActivity`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  /** Reads a single `Tenant` that is related to this `FileActivity`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Maybe<Scalars["Int"]["output"]>;
  userId: Maybe<Scalars["UUID"]["output"]>;
};

/**
 * A condition to be used against `FileActivity` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FileActivityCondition = {
  /** Checks for equality with the object’s `activityType` field. */
  activityType?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `bytes` field. */
  bytes?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `comment` field. */
  comment?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `costId` field. */
  costId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `cost` to be created by this mutation. */
export type FileActivityCostId2A88D90AFkCostIdCostCreateInput = {
  costType: Scalars["Int"]["input"];
  currency: Scalars["Int"]["input"];
  date?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInverseInput>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  platform?: InputMaybe<Scalars["Int"]["input"]>;
  rateCode?: InputMaybe<Scalars["String"]["input"]>;
  taskActivitiesUsingId?: InputMaybe<TaskActivityCostId49E66058FkCostIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInput>;
  value: Scalars["BigFloat"]["input"];
};

/** The `fileActivity` to be created by this mutation. */
export type FileActivityCostId2A88D90AFkCostIdFileActivityCreateInput = {
  activityType: Scalars["Int"]["input"];
  applicationUserToUserId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInput>;
  bytes?: InputMaybe<Scalars["BigInt"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  costToCostId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileToFileId?: InputMaybe<FileActivityFileId015763A9FkFileIdInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `cost` in the `FileActivityInput` mutation. */
export type FileActivityCostId2A88D90AFkCostIdInput = {
  /** The primary key(s) for `cost` for the far side of the relationship. */
  connectById?: InputMaybe<CostCostPkeyConnect>;
  /** A `CostInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileActivityCostId2A88D90AFkCostIdCostCreateInput>;
  /** The primary key(s) for `cost` for the far side of the relationship. */
  deleteById?: InputMaybe<CostCostPkeyDelete>;
  /** The primary key(s) and patch data for `cost` for the far side of the relationship. */
  updateById?: InputMaybe<CostOnFileActivityForFileActivityCostId2A88D90AFkCostIdUsingCostPkeyUpdate>;
};

/** Input for the nested mutation of `fileActivity` in the `CostInput` mutation. */
export type FileActivityCostId2A88D90AFkCostIdInverseInput = {
  /** The primary key(s) for `fileActivity` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FileActivityFileActivityPkeyConnect>>;
  /** A `FileActivityInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileActivityCostId2A88D90AFkCostIdFileActivityCreateInput>
  >;
  /** The primary key(s) for `fileActivity` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FileActivityFileActivityPkeyDelete>>;
  /** Flag indicating whether all other `fileActivity` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileActivity` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileActivityOnFileActivityForFileActivityCostId2A88D90AFkCostIdUsingFileActivityPkeyUpdate>
  >;
};

/** The fields on `fileActivity` to look up the row to connect. */
export type FileActivityFileActivityPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `fileActivity` to look up the row to delete. */
export type FileActivityFileActivityPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** The `fileActivity` to be created by this mutation. */
export type FileActivityFileId015763A9FkFileIdFileActivityCreateInput = {
  activityType: Scalars["Int"]["input"];
  applicationUserToUserId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInput>;
  bytes?: InputMaybe<Scalars["BigInt"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  costId?: InputMaybe<Scalars["BigInt"]["input"]>;
  costToCostId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileToFileId?: InputMaybe<FileActivityFileId015763A9FkFileIdInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `file` to be created by this mutation. */
export type FileActivityFileId015763A9FkFileIdFileCreateInput = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["UUID"]["input"];
  isSeries: Scalars["Boolean"]["input"];
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  originalName: Scalars["String"]["input"];
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus: Scalars["Int"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status: Scalars["Int"]["input"];
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `file` in the `FileActivityInput` mutation. */
export type FileActivityFileId015763A9FkFileIdInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: InputMaybe<FileFilePkeyConnect>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileActivityFileId015763A9FkFileIdFileCreateInput>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: InputMaybe<FileFilePkeyDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: InputMaybe<FileOnFileActivityForFileActivityFileId015763A9FkFileIdUsingFilePkeyUpdate>;
};

/** Input for the nested mutation of `fileActivity` in the `FileInput` mutation. */
export type FileActivityFileId015763A9FkFileIdInverseInput = {
  /** The primary key(s) for `fileActivity` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FileActivityFileActivityPkeyConnect>>;
  /** A `FileActivityInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileActivityFileId015763A9FkFileIdFileActivityCreateInput>
  >;
  /** The primary key(s) for `fileActivity` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FileActivityFileActivityPkeyDelete>>;
  /** Flag indicating whether all other `fileActivity` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileActivity` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileActivityOnFileActivityForFileActivityFileId015763A9FkFileIdUsingFileActivityPkeyUpdate>
  >;
};

/** A filter to be used against `FileActivity` object types. All fields are combined with a logical ‘and.’ */
export type FileActivityFilter = {
  /** Filter by the object’s `activityType` field. */
  activityType?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FileActivityFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** A related `applicationUserByUserId` exists. */
  applicationUserByUserIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `bytes` field. */
  bytes?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `comment` field. */
  comment?: InputMaybe<StringFilter>;
  /** Filter by the object’s `costByCostId` relation. */
  costByCostId?: InputMaybe<CostFilter>;
  /** A related `costByCostId` exists. */
  costByCostIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `costId` field. */
  costId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fileByFileId` relation. */
  fileByFileId?: InputMaybe<FileFilter>;
  /** Filter by the object’s `fileId` field. */
  fileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FileActivityFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FileActivityFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** A related `tenantByTenantId` exists. */
  tenantByTenantIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `FileActivity` */
export type FileActivityInput = {
  activityType: Scalars["Int"]["input"];
  applicationUserToUserId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInput>;
  bytes?: InputMaybe<Scalars["BigInt"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  costId?: InputMaybe<Scalars["BigInt"]["input"]>;
  costToCostId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileToFileId?: InputMaybe<FileActivityFileId015763A9FkFileIdInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `fileActivity` to look up the row to update. */
export type FileActivityOnFileActivityForFileActivityCostId2A88D90AFkCostIdUsingFileActivityPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileActivity` being updated. */
    fileActivityPatch: UpdateFileActivityOnFileActivityForFileActivityCostId2A88D90AFkCostIdPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `fileActivity` to look up the row to update. */
export type FileActivityOnFileActivityForFileActivityFileId015763A9FkFileIdUsingFileActivityPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileActivity` being updated. */
    fileActivityPatch: UpdateFileActivityOnFileActivityForFileActivityFileId015763A9FkFileIdPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `fileActivity` to look up the row to update. */
export type FileActivityOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdUsingFileActivityPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileActivity` being updated. */
    fileActivityPatch: UpdateFileActivityOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `fileActivity` to look up the row to update. */
export type FileActivityOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingFileActivityPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileActivity` being updated. */
    fileActivityPatch: UpdateFileActivityOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdPatch;
    id: Scalars["Int"]["input"];
  };

/** The fields on `fileActivity` to look up the row to update. */
export type FileActivityOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingFileActivityPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileActivity` being updated. */
    fileActivityPatch: UpdateFileActivityOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdPatch;
    id: Scalars["Int"]["input"];
  };

/** Represents an update to a `FileActivity`. Fields that are set will be updated. */
export type FileActivityPatch = {
  activityType?: InputMaybe<Scalars["Int"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInput>;
  bytes?: InputMaybe<Scalars["BigInt"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  costId?: InputMaybe<Scalars["BigInt"]["input"]>;
  costToCostId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileToFileId?: InputMaybe<FileActivityFileId015763A9FkFileIdInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `fileActivity` to be created by this mutation. */
export type FileActivityProjectId6F35B8CcFkProjectIdFileActivityCreateInput = {
  activityType: Scalars["Int"]["input"];
  applicationUserToUserId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInput>;
  bytes?: InputMaybe<Scalars["BigInt"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  costId?: InputMaybe<Scalars["BigInt"]["input"]>;
  costToCostId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileToFileId?: InputMaybe<FileActivityFileId015763A9FkFileIdInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  projectToProjectId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `project` in the `FileActivityInput` mutation. */
export type FileActivityProjectId6F35B8CcFkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `fileActivity` in the `ProjectInput` mutation. */
export type FileActivityProjectId6F35B8CcFkProjectIdInverseInput = {
  /** The primary key(s) for `fileActivity` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FileActivityFileActivityPkeyConnect>>;
  /** A `FileActivityInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileActivityProjectId6F35B8CcFkProjectIdFileActivityCreateInput>
  >;
  /** The primary key(s) for `fileActivity` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FileActivityFileActivityPkeyDelete>>;
  /** Flag indicating whether all other `fileActivity` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileActivity` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileActivityOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdUsingFileActivityPkeyUpdate>
  >;
};

/** The `fileActivity` to be created by this mutation. */
export type FileActivityTenantIdC46A4F01FkTenantIdFileActivityCreateInput = {
  activityType: Scalars["Int"]["input"];
  applicationUserToUserId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInput>;
  bytes?: InputMaybe<Scalars["BigInt"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  costId?: InputMaybe<Scalars["BigInt"]["input"]>;
  costToCostId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileToFileId?: InputMaybe<FileActivityFileId015763A9FkFileIdInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInput>;
  tenantToTenantId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `tenant` in the `FileActivityInput` mutation. */
export type FileActivityTenantIdC46A4F01FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `fileActivity` in the `TenantInput` mutation. */
export type FileActivityTenantIdC46A4F01FkTenantIdInverseInput = {
  /** The primary key(s) for `fileActivity` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FileActivityFileActivityPkeyConnect>>;
  /** A `FileActivityInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileActivityTenantIdC46A4F01FkTenantIdFileActivityCreateInput>
  >;
  /** The primary key(s) for `fileActivity` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FileActivityFileActivityPkeyDelete>>;
  /** Flag indicating whether all other `fileActivity` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileActivity` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileActivityOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingFileActivityPkeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type FileActivityTenantIdC46A4F01FkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** The `applicationUser` to be created by this mutation. */
export type FileActivityUserId868Ad8A9FkApplicationUserIdApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** The `fileActivity` to be created by this mutation. */
export type FileActivityUserId868Ad8A9FkApplicationUserIdFileActivityCreateInput =
  {
    activityType: Scalars["Int"]["input"];
    applicationUserToUserId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInput>;
    bytes?: InputMaybe<Scalars["BigInt"]["input"]>;
    comment?: InputMaybe<Scalars["String"]["input"]>;
    costId?: InputMaybe<Scalars["BigInt"]["input"]>;
    costToCostId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileToFileId?: InputMaybe<FileActivityFileId015763A9FkFileIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `FileActivityInput` mutation. */
export type FileActivityUserId868Ad8A9FkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `fileActivity` in the `ApplicationUserInput` mutation. */
export type FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput = {
  /** The primary key(s) for `fileActivity` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FileActivityFileActivityPkeyConnect>>;
  /** A `FileActivityInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileActivityUserId868Ad8A9FkApplicationUserIdFileActivityCreateInput>
  >;
  /** The primary key(s) for `fileActivity` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FileActivityFileActivityPkeyDelete>>;
  /** Flag indicating whether all other `fileActivity` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileActivity` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileActivityOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingFileActivityPkeyUpdate>
  >;
};

/** Input for the nested mutation of `file` in the `FileInput` mutation. */
export type FileCloneSourceIdBef488AeFkFileIdInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: InputMaybe<FileFilePkeyConnect>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: InputMaybe<FileFilePkeyDelete>;
};

/** A condition to be used against `File` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FileCondition = {
  /** Checks for equality with the object’s `datasetId` field. */
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `dateArchived` field. */
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateDataDeleted` field. */
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateDeleted` field. */
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `fileFormat` field. */
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `fileStructure` field. */
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `fileType` field. */
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `isSeries` field. */
  isSeries?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `location` field. */
  location?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `multipartUploadId` field. */
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `originalName` field. */
  originalName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `parentIds` field. */
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `partSize` field. */
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `preview` field. */
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `processingStatus` field. */
  processingStatus?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `seriesOrder` field. */
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `seriesParentId` field. */
  seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `signal` field. */
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `size` field. */
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `uploadStatus` field. */
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `dataset` to be created by this mutation. */
export type FileDatasetIdC0A4045AFkDatasetV4IdDatasetCreateInput = {
  applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
  datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
  filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
  id: Scalars["UUID"]["input"];
  key: Scalars["String"]["input"];
  metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
  name: Scalars["String"]["input"];
  prefix: Scalars["String"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
  recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  status: Scalars["Int"]["input"];
  tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `file` to be created by this mutation. */
export type FileDatasetIdC0A4045AFkDatasetV4IdFileCreateInput = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["UUID"]["input"];
  isSeries: Scalars["Boolean"]["input"];
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  originalName: Scalars["String"]["input"];
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus: Scalars["Int"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status: Scalars["Int"]["input"];
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `dataset` in the `FileInput` mutation. */
export type FileDatasetIdC0A4045AFkDatasetV4IdInput = {
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectById?: InputMaybe<DatasetDatasetV4PkeyConnect>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByProjectIdAndPrefix?: InputMaybe<DatasetDatasetPrefixProjectUniqueConnect>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByRecordingsTableId?: InputMaybe<DatasetDatasetV4RecordingsTableIdKeyConnect>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByTenantIdAndKey?: InputMaybe<DatasetDatasetKeyTenantUniqueConnect>;
  /** A `DatasetInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdDatasetCreateInput>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteById?: InputMaybe<DatasetDatasetV4PkeyDelete>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByProjectIdAndPrefix?: InputMaybe<DatasetDatasetPrefixProjectUniqueDelete>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByRecordingsTableId?: InputMaybe<DatasetDatasetV4RecordingsTableIdKeyDelete>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByTenantIdAndKey?: InputMaybe<DatasetDatasetKeyTenantUniqueDelete>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateById?: InputMaybe<DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetV4PkeyUpdate>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByProjectIdAndPrefix?: InputMaybe<DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetPrefixProjectUniqueUpdate>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByRecordingsTableId?: InputMaybe<DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetV4RecordingsTableIdKeyUpdate>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByTenantIdAndKey?: InputMaybe<DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `file` in the `DatasetInput` mutation. */
export type FileDatasetIdC0A4045AFkDatasetV4IdInverseInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FileFilePkeyConnect>>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FileDatasetIdC0A4045AFkDatasetV4IdFileCreateInput>>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FileFilePkeyDelete>>;
  /** Flag indicating whether all other `file` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingFilePkeyUpdate>
  >;
};

/** Input for the nested mutation of `drsFileexecutor` in the `FileInput` mutation. */
export type FileExecutorId819Da590FkDrsFileexecutorIdInput = {
  /** The primary key(s) for `drsFileexecutor` for the far side of the relationship. */
  connectById?: InputMaybe<DrsFileexecutorDrsFileexecutorPkeyConnect>;
  /** The primary key(s) for `drsFileexecutor` for the far side of the relationship. */
  deleteById?: InputMaybe<DrsFileexecutorDrsFileexecutorPkeyDelete>;
};

/** Input for the nested mutation of `file` in the `DrsFileexecutorInput` mutation. */
export type FileExecutorId819Da590FkDrsFileexecutorIdInverseInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FileFilePkeyConnect>>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FileFilePkeyDelete>>;
  /** Flag indicating whether all other `file` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The fields on `file` to look up the row to connect. */
export type FileFilePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `file` to look up the row to delete. */
export type FileFilePkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** A filter to be used against `File` object types. All fields are combined with a logical ‘and.’ */
export type FileFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FileFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `datasetByDatasetId` relation. */
  datasetByDatasetId?: InputMaybe<DatasetFilter>;
  /** A related `datasetByDatasetId` exists. */
  datasetByDatasetIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `datasetId` field. */
  datasetId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `dateArchived` field. */
  dateArchived?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateDataDeleted` field. */
  dateDataDeleted?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateDeleted` field. */
  dateDeleted?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fileActivitiesByFileId` relation. */
  fileActivitiesByFileId?: InputMaybe<FileToManyFileActivityFilter>;
  /** Some related `fileActivitiesByFileId` exist. */
  fileActivitiesByFileIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fileBySeriesParentId` relation. */
  fileBySeriesParentId?: InputMaybe<FileFilter>;
  /** A related `fileBySeriesParentId` exists. */
  fileBySeriesParentIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fileFormat` field. */
  fileFormat?: InputMaybe<IntFilter>;
  /** Filter by the object’s `fileMetadataByFileId` relation. */
  fileMetadataByFileId?: InputMaybe<FileToManyFileMetadatumFilter>;
  /** Some related `fileMetadataByFileId` exist. */
  fileMetadataByFileIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fileProcessingNotificationsByFileId` relation. */
  fileProcessingNotificationsByFileId?: InputMaybe<FileToManyFileProcessingNotificationFilter>;
  /** Some related `fileProcessingNotificationsByFileId` exist. */
  fileProcessingNotificationsByFileIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `fileStructure` field. */
  fileStructure?: InputMaybe<IntFilter>;
  /** Filter by the object’s `fileType` field. */
  fileType?: InputMaybe<IntFilter>;
  /** Filter by the object’s `filesBySeriesParentId` relation. */
  filesBySeriesParentId?: InputMaybe<FileToManyFileFilter>;
  /** Some related `filesBySeriesParentId` exist. */
  filesBySeriesParentIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isSeries` field. */
  isSeries?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `location` field. */
  location?: InputMaybe<StringFilter>;
  /** Filter by the object’s `multipartUploadId` field. */
  multipartUploadId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FileFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FileFilter>>;
  /** Filter by the object’s `originalName` field. */
  originalName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `outputGroupFilesByFileId` relation. */
  outputGroupFilesByFileId?: InputMaybe<FileToManyOutputGroupFileFilter>;
  /** Some related `outputGroupFilesByFileId` exist. */
  outputGroupFilesByFileIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `parentIds` field. */
  parentIds?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `partSize` field. */
  partSize?: InputMaybe<IntFilter>;
  /** Filter by the object’s `preview` field. */
  preview?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `processingStatus` field. */
  processingStatus?: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `recordingGroupFilesByFileId` relation. */
  recordingGroupFilesByFileId?: InputMaybe<FileToManyRecordingGroupFileFilter>;
  /** Some related `recordingGroupFilesByFileId` exist. */
  recordingGroupFilesByFileIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `seriesOrder` field. */
  seriesOrder?: InputMaybe<IntFilter>;
  /** Filter by the object’s `seriesParentId` field. */
  seriesParentId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `signal` field. */
  signal?: InputMaybe<IntFilter>;
  /** Filter by the object’s `size` field. */
  size?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<IntFilter>;
  /** Filter by the object’s `taskSourcesByFileId` relation. */
  taskSourcesByFileId?: InputMaybe<FileToManyTaskSourceFilter>;
  /** Some related `taskSourcesByFileId` exist. */
  taskSourcesByFileIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `uploadStatus` field. */
  uploadStatus?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `File` */
export type FileInput = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["UUID"]["input"];
  isSeries: Scalars["Boolean"]["input"];
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  originalName: Scalars["String"]["input"];
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus: Scalars["Int"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status: Scalars["Int"]["input"];
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A connection to a list of `FileMetadatum` values. */
export type FileMetadataConnection = {
  __typename: "FileMetadataConnection";
  /** A list of edges which contains the `FileMetadatum` and cursor to aid in pagination. */
  edges: Array<FileMetadataEdge>;
  /** A list of `FileMetadatum` objects. */
  nodes: Array<FileMetadatum>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FileMetadatum` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `FileMetadatum` edge in the connection. */
export type FileMetadataEdge = {
  __typename: "FileMetadataEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `FileMetadatum` at the end of the edge. */
  node: FileMetadatum;
};

/** The `file` to be created by this mutation. */
export type FileMetadataFileId78256936FkFileIdFileCreateInput = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["UUID"]["input"];
  isSeries: Scalars["Boolean"]["input"];
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  originalName: Scalars["String"]["input"];
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus: Scalars["Int"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status: Scalars["Int"]["input"];
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `fileMetadatum` to be created by this mutation. */
export type FileMetadataFileId78256936FkFileIdFileMetadataCreateInput = {
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileToFileId?: InputMaybe<FileMetadataFileId78256936FkFileIdInput>;
  id: Scalars["UUID"]["input"];
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  metadatumToMetadataId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInput>;
};

/** Input for the nested mutation of `file` in the `FileMetadatumInput` mutation. */
export type FileMetadataFileId78256936FkFileIdInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: InputMaybe<FileFilePkeyConnect>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileMetadataFileId78256936FkFileIdFileCreateInput>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: InputMaybe<FileFilePkeyDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: InputMaybe<FileOnFileMetadatumForFileMetadataFileId78256936FkFileIdUsingFilePkeyUpdate>;
};

/** Input for the nested mutation of `fileMetadatum` in the `FileInput` mutation. */
export type FileMetadataFileId78256936FkFileIdInverseInput = {
  /** The primary key(s) for `fileMetadatum` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FileMetadatumFileMetadataPkeyConnect>>;
  /** A `FileMetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileMetadataFileId78256936FkFileIdFileMetadataCreateInput>
  >;
  /** The primary key(s) for `fileMetadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FileMetadatumFileMetadataPkeyDelete>>;
  /** Flag indicating whether all other `fileMetadatum` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileMetadatum` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileMetadatumOnFileMetadatumForFileMetadataFileId78256936FkFileIdUsingFileMetadataPkeyUpdate>
  >;
};

/** The `fileMetadatum` to be created by this mutation. */
export type FileMetadataMetadataIdFefc0061FkMetadataV4IdFileMetadataCreateInput =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileToFileId?: InputMaybe<FileMetadataFileId78256936FkFileIdInput>;
    id: Scalars["UUID"]["input"];
    metadatumToMetadataId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInput>;
  };

/** Input for the nested mutation of `metadatum` in the `FileMetadatumInput` mutation. */
export type FileMetadataMetadataIdFefc0061FkMetadataV4IdInput = {
  /** The primary key(s) for `metadatum` for the far side of the relationship. */
  connectById?: InputMaybe<MetadatumMetadataV4PkeyConnect>;
  /** A `MetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdMetadataCreateInput>;
  /** The primary key(s) for `metadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<MetadatumMetadataV4PkeyDelete>;
  /** The primary key(s) and patch data for `metadatum` for the far side of the relationship. */
  updateById?: InputMaybe<MetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdUsingMetadataV4PkeyUpdate>;
};

/** Input for the nested mutation of `fileMetadatum` in the `MetadatumInput` mutation. */
export type FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput = {
  /** The primary key(s) for `fileMetadatum` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FileMetadatumFileMetadataPkeyConnect>>;
  /** A `FileMetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileMetadataMetadataIdFefc0061FkMetadataV4IdFileMetadataCreateInput>
  >;
  /** The primary key(s) for `fileMetadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FileMetadatumFileMetadataPkeyDelete>>;
  /** Flag indicating whether all other `fileMetadatum` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileMetadatum` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileMetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdUsingFileMetadataPkeyUpdate>
  >;
};

/** The `metadatum` to be created by this mutation. */
export type FileMetadataMetadataIdFefc0061FkMetadataV4IdMetadataCreateInput = {
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
  displayName: Scalars["String"]["input"];
  fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
  id: Scalars["UUID"]["input"];
  key: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
};

/** Methods to use when ordering `FileMetadatum`. */
export enum FileMetadataOrderBy {
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MetadataIdAsc = "METADATA_ID_ASC",
  MetadataIdDesc = "METADATA_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
}

/** The `fileMetadatum` to be created by this mutation. */
export type FileMetadataProjectId3474994EFkProjectIdFileMetadataCreateInput = {
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileToFileId?: InputMaybe<FileMetadataFileId78256936FkFileIdInput>;
  id: Scalars["UUID"]["input"];
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  metadatumToMetadataId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInput>;
  projectToProjectId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInput>;
};

/** Input for the nested mutation of `project` in the `FileMetadatumInput` mutation. */
export type FileMetadataProjectId3474994EFkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `fileMetadatum` in the `ProjectInput` mutation. */
export type FileMetadataProjectId3474994EFkProjectIdInverseInput = {
  /** The primary key(s) for `fileMetadatum` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FileMetadatumFileMetadataPkeyConnect>>;
  /** A `FileMetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileMetadataProjectId3474994EFkProjectIdFileMetadataCreateInput>
  >;
  /** The primary key(s) for `fileMetadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FileMetadatumFileMetadataPkeyDelete>>;
  /** Flag indicating whether all other `fileMetadatum` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileMetadatum` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileMetadatumOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdUsingFileMetadataPkeyUpdate>
  >;
};

/** The `fileMetadatum` to be created by this mutation. */
export type FileMetadataTenantId79Fe438BFkTenantIdFileMetadataCreateInput = {
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileToFileId?: InputMaybe<FileMetadataFileId78256936FkFileIdInput>;
  id: Scalars["UUID"]["input"];
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  metadatumToMetadataId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInput>;
  tenantToTenantId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInput>;
};

/** Input for the nested mutation of `tenant` in the `FileMetadatumInput` mutation. */
export type FileMetadataTenantId79Fe438BFkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `fileMetadatum` in the `TenantInput` mutation. */
export type FileMetadataTenantId79Fe438BFkTenantIdInverseInput = {
  /** The primary key(s) for `fileMetadatum` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FileMetadatumFileMetadataPkeyConnect>>;
  /** A `FileMetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileMetadataTenantId79Fe438BFkTenantIdFileMetadataCreateInput>
  >;
  /** The primary key(s) for `fileMetadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FileMetadatumFileMetadataPkeyDelete>>;
  /** Flag indicating whether all other `fileMetadatum` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileMetadatum` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileMetadatumOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingFileMetadataPkeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type FileMetadataTenantId79Fe438BFkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

export type FileMetadatum = {
  __typename: "FileMetadatum";
  dateCreated: Scalars["Datetime"]["output"];
  /** Reads a single `File` that is related to this `FileMetadatum`. */
  fileByFileId: Maybe<File>;
  fileId: Maybe<Scalars["UUID"]["output"]>;
  id: Scalars["UUID"]["output"];
  metadataId: Scalars["UUID"]["output"];
  /** Reads a single `Metadatum` that is related to this `FileMetadatum`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `FileMetadatum`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  /** Reads a single `Tenant` that is related to this `FileMetadatum`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
};

/**
 * A condition to be used against `FileMetadatum` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FileMetadatumCondition = {
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `metadataId` field. */
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `fileMetadatum` to look up the row to connect. */
export type FileMetadatumFileMetadataPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `fileMetadatum` to look up the row to delete. */
export type FileMetadatumFileMetadataPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** A filter to be used against `FileMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type FileMetadatumFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FileMetadatumFilter>>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fileByFileId` relation. */
  fileByFileId?: InputMaybe<FileFilter>;
  /** A related `fileByFileId` exists. */
  fileByFileIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fileId` field. */
  fileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `metadataId` field. */
  metadataId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `metadatumByMetadataId` relation. */
  metadatumByMetadataId?: InputMaybe<MetadatumFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FileMetadatumFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FileMetadatumFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `FileMetadatum` */
export type FileMetadatumInput = {
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileToFileId?: InputMaybe<FileMetadataFileId78256936FkFileIdInput>;
  id: Scalars["UUID"]["input"];
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  metadatumToMetadataId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInput>;
};

/** The fields on `fileMetadatum` to look up the row to update. */
export type FileMetadatumOnFileMetadatumForFileMetadataFileId78256936FkFileIdUsingFileMetadataPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileMetadatum` being updated. */
    fileMetadatumPatch: UpdateFileMetadatumOnFileMetadatumForFileMetadataFileId78256936FkFileIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileMetadatum` to look up the row to update. */
export type FileMetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdUsingFileMetadataPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileMetadatum` being updated. */
    fileMetadatumPatch: UpdateFileMetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileMetadatum` to look up the row to update. */
export type FileMetadatumOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdUsingFileMetadataPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileMetadatum` being updated. */
    fileMetadatumPatch: UpdateFileMetadatumOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileMetadatum` to look up the row to update. */
export type FileMetadatumOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingFileMetadataPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileMetadatum` being updated. */
    fileMetadatumPatch: UpdateFileMetadatumOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** Represents an update to a `FileMetadatum`. Fields that are set will be updated. */
export type FileMetadatumPatch = {
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileToFileId?: InputMaybe<FileMetadataFileId78256936FkFileIdInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  metadatumToMetadataId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInput>;
};

/** The fields on `file` to look up the row to update. */
export type FileOnFileActivityForFileActivityFileId015763A9FkFileIdUsingFilePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `file` being updated. */
    filePatch: UpdateFileOnFileActivityForFileActivityFileId015763A9FkFileIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `file` to look up the row to update. */
export type FileOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingFilePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `file` being updated. */
    filePatch: UpdateFileOnFileForFileDatasetIdC0A4045AFkDatasetV4IdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `file` to look up the row to update. */
export type FileOnFileForFileProjectId3Ecca975FkProjectIdUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  filePatch: UpdateFileOnFileForFileProjectId3Ecca975FkProjectIdPatch;
  id: Scalars["UUID"]["input"];
};

/** The fields on `file` to look up the row to update. */
export type FileOnFileForFileSeriesParentIdF7A07E20FkFileIdUsingFilePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `file` being updated. */
    filePatch: UpdateFileOnFileForFileSeriesParentIdF7A07E20FkFileIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `file` to look up the row to update. */
export type FileOnFileForFileTenantId61625016FkTenantIdUsingFilePkeyUpdate = {
  /** An object where the defined keys will be set on the `file` being updated. */
  filePatch: UpdateFileOnFileForFileTenantId61625016FkTenantIdPatch;
  id: Scalars["UUID"]["input"];
};

/** The fields on `file` to look up the row to update. */
export type FileOnFileForFileUserId01D90B53FkApplicationUserIdUsingFilePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `file` being updated. */
    filePatch: UpdateFileOnFileForFileUserId01D90B53FkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `file` to look up the row to update. */
export type FileOnFileMetadatumForFileMetadataFileId78256936FkFileIdUsingFilePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `file` being updated. */
    filePatch: UpdateFileOnFileMetadatumForFileMetadataFileId78256936FkFileIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `file` to look up the row to update. */
export type FileOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdUsingFilePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `file` being updated. */
    filePatch: UpdateFileOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `file` to look up the row to update. */
export type FileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdUsingFilePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `file` being updated. */
    filePatch: UpdateFileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `file` to look up the row to update. */
export type FileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdUsingFilePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `file` being updated. */
    filePatch: UpdateFileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `file` to look up the row to update. */
export type FileOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdUsingFilePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `file` being updated. */
    filePatch: UpdateFileOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdPatch;
    id: Scalars["UUID"]["input"];
  };

export type FileOutputGroup = {
  __typename: "FileOutputGroup";
  /** Reads a single `ApplicationUser` that is related to this `FileOutputGroup`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  dateCreated: Scalars["Datetime"]["output"];
  /** Reads and enables pagination through a set of `FileOutputGroupMetadatum`. */
  fileOutputGroupMetadataByFileOutputGroupId: FileOutputGroupMetadataConnection;
  groupType: Scalars["Int"]["output"];
  id: Scalars["UUID"]["output"];
  key: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `OutputGroupFile`. */
  outputGroupFilesByFileOutputGroupId: OutputGroupFilesConnection;
  /** Reads a single `Project` that is related to this `FileOutputGroup`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  /** Reads a single `Task` that is related to this `FileOutputGroup`. */
  taskByTaskId: Maybe<Task>;
  taskId: Scalars["UUID"]["output"];
  /** Reads a single `Tenant` that is related to this `FileOutputGroup`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  userId: Scalars["UUID"]["output"];
};

export type FileOutputGroupFileOutputGroupMetadataByFileOutputGroupIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileOutputGroupMetadatumCondition>;
  filter?: InputMaybe<FileOutputGroupMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileOutputGroupMetadataOrderBy>>;
};

export type FileOutputGroupOutputGroupFilesByFileOutputGroupIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OutputGroupFileCondition>;
  filter?: InputMaybe<OutputGroupFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OutputGroupFilesOrderBy>>;
};

/**
 * A condition to be used against `FileOutputGroup` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FileOutputGroupCondition = {
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `groupType` field. */
  groupType?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `fileOutputGroup` to look up the row to connect. */
export type FileOutputGroupFileOutputGroupPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `fileOutputGroup` to look up the row to delete. */
export type FileOutputGroupFileOutputGroupPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `fileOutputGroup` to look up the row to connect. */
export type FileOutputGroupFileOutputGroupTaskIdKeyConnect = {
  taskId: Scalars["UUID"]["input"];
};

/** The fields on `fileOutputGroup` to look up the row to delete. */
export type FileOutputGroupFileOutputGroupTaskIdKeyDelete = {
  taskId: Scalars["UUID"]["input"];
};

/** A filter to be used against `FileOutputGroup` object types. All fields are combined with a logical ‘and.’ */
export type FileOutputGroupFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FileOutputGroupFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fileOutputGroupMetadataByFileOutputGroupId` relation. */
  fileOutputGroupMetadataByFileOutputGroupId?: InputMaybe<FileOutputGroupToManyFileOutputGroupMetadatumFilter>;
  /** Some related `fileOutputGroupMetadataByFileOutputGroupId` exist. */
  fileOutputGroupMetadataByFileOutputGroupIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `groupType` field. */
  groupType?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FileOutputGroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FileOutputGroupFilter>>;
  /** Filter by the object’s `outputGroupFilesByFileOutputGroupId` relation. */
  outputGroupFilesByFileOutputGroupId?: InputMaybe<FileOutputGroupToManyOutputGroupFileFilter>;
  /** Some related `outputGroupFilesByFileOutputGroupId` exist. */
  outputGroupFilesByFileOutputGroupIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `taskByTaskId` relation. */
  taskByTaskId?: InputMaybe<TaskFilter>;
  /** Filter by the object’s `taskId` field. */
  taskId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** The `fileOutputGroupMetadatum` to be created by this mutation. */
export type FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpFileOutputGroupMetadataCreateInput =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileOutputGroupToFileOutputGroupId?: InputMaybe<FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInput>;
    id: Scalars["UUID"]["input"];
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInput>;
  };

/** Input for the nested mutation of `fileOutputGroup` in the `FileOutputGroupMetadatumInput` mutation. */
export type FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInput = {
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  connectById?: InputMaybe<FileOutputGroupFileOutputGroupPkeyConnect>;
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  connectByTaskId?: InputMaybe<FileOutputGroupFileOutputGroupTaskIdKeyConnect>;
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<FileOutputGroupFileOutputGroupPkeyDelete>;
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  deleteByTaskId?: InputMaybe<FileOutputGroupFileOutputGroupTaskIdKeyDelete>;
  /** The primary key(s) and patch data for `fileOutputGroup` for the far side of the relationship. */
  updateById?: InputMaybe<FileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpUsingFileOutputGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `fileOutputGroup` for the far side of the relationship. */
  updateByTaskId?: InputMaybe<FileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpUsingFileOutputGroupTaskIdKeyUpdate>;
};

/** Input for the nested mutation of `fileOutputGroupMetadatum` in the `FileOutputGroupInput` mutation. */
export type FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInverseInput = {
  /** The primary key(s) for `fileOutputGroupMetadatum` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<FileOutputGroupMetadatumFileOutputGroupMetadataPkeyConnect>
  >;
  /** A `FileOutputGroupMetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpFileOutputGroupMetadataCreateInput>
  >;
  /** The primary key(s) for `fileOutputGroupMetadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<FileOutputGroupMetadatumFileOutputGroupMetadataPkeyDelete>
  >;
  /** Flag indicating whether all other `fileOutputGroupMetadatum` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileOutputGroupMetadatum` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpUsingFileOutputGroupMetadataPkeyUpdate>
  >;
};

/** The `fileOutputGroupMetadatum` to be created by this mutation. */
export type FileOutputGroupMeMetadataId3A08Fd87FkMetadataFileOutputGroupMetadataCreateInput =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileOutputGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupToFileOutputGroupId?: InputMaybe<FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInput>;
    id: Scalars["UUID"]["input"];
    metadatumToMetadataId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInput>;
  };

/** Input for the nested mutation of `metadatum` in the `FileOutputGroupMetadatumInput` mutation. */
export type FileOutputGroupMeMetadataId3A08Fd87FkMetadataInput = {
  /** The primary key(s) for `metadatum` for the far side of the relationship. */
  connectById?: InputMaybe<MetadatumMetadataV4PkeyConnect>;
  /** A `MetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataMetadataCreateInput>;
  /** The primary key(s) for `metadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<MetadatumMetadataV4PkeyDelete>;
  /** The primary key(s) and patch data for `metadatum` for the far side of the relationship. */
  updateById?: InputMaybe<MetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataUsingMetadataV4PkeyUpdate>;
};

/** Input for the nested mutation of `fileOutputGroupMetadatum` in the `MetadatumInput` mutation. */
export type FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput = {
  /** The primary key(s) for `fileOutputGroupMetadatum` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<FileOutputGroupMetadatumFileOutputGroupMetadataPkeyConnect>
  >;
  /** A `FileOutputGroupMetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileOutputGroupMeMetadataId3A08Fd87FkMetadataFileOutputGroupMetadataCreateInput>
  >;
  /** The primary key(s) for `fileOutputGroupMetadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<FileOutputGroupMetadatumFileOutputGroupMetadataPkeyDelete>
  >;
  /** Flag indicating whether all other `fileOutputGroupMetadatum` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileOutputGroupMetadatum` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataUsingFileOutputGroupMetadataPkeyUpdate>
  >;
};

/** The `metadatum` to be created by this mutation. */
export type FileOutputGroupMeMetadataId3A08Fd87FkMetadataMetadataCreateInput = {
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
  displayName: Scalars["String"]["input"];
  fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
  id: Scalars["UUID"]["input"];
  key: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
};

/** A connection to a list of `FileOutputGroupMetadatum` values. */
export type FileOutputGroupMetadataConnection = {
  __typename: "FileOutputGroupMetadataConnection";
  /** A list of edges which contains the `FileOutputGroupMetadatum` and cursor to aid in pagination. */
  edges: Array<FileOutputGroupMetadataEdge>;
  /** A list of `FileOutputGroupMetadatum` objects. */
  nodes: Array<FileOutputGroupMetadatum>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FileOutputGroupMetadatum` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `FileOutputGroupMetadatum` edge in the connection. */
export type FileOutputGroupMetadataEdge = {
  __typename: "FileOutputGroupMetadataEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `FileOutputGroupMetadatum` at the end of the edge. */
  node: FileOutputGroupMetadatum;
};

/** Methods to use when ordering `FileOutputGroupMetadatum`. */
export enum FileOutputGroupMetadataOrderBy {
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  FileOutputGroupIdAsc = "FILE_OUTPUT_GROUP_ID_ASC",
  FileOutputGroupIdDesc = "FILE_OUTPUT_GROUP_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MetadataIdAsc = "METADATA_ID_ASC",
  MetadataIdDesc = "METADATA_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
}

/** The `fileOutputGroupMetadatum` to be created by this mutation. */
export type FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdFileOutputGroupMetadataCreateInput =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileOutputGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupToFileOutputGroupId?: InputMaybe<FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInput>;
    id: Scalars["UUID"]["input"];
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInput>;
    projectToProjectId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInput>;
  };

/** Input for the nested mutation of `project` in the `FileOutputGroupMetadatumInput` mutation. */
export type FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `fileOutputGroupMetadatum` in the `ProjectInput` mutation. */
export type FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput = {
  /** The primary key(s) for `fileOutputGroupMetadatum` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<FileOutputGroupMetadatumFileOutputGroupMetadataPkeyConnect>
  >;
  /** A `FileOutputGroupMetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdFileOutputGroupMetadataCreateInput>
  >;
  /** The primary key(s) for `fileOutputGroupMetadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<FileOutputGroupMetadatumFileOutputGroupMetadataPkeyDelete>
  >;
  /** Flag indicating whether all other `fileOutputGroupMetadatum` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileOutputGroupMetadatum` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdUsingFileOutputGroupMetadataPkeyUpdate>
  >;
};

/** The `fileOutputGroupMetadatum` to be created by this mutation. */
export type FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdFileOutputGroupMetadataCreateInput =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileOutputGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupToFileOutputGroupId?: InputMaybe<FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInput>;
    id: Scalars["UUID"]["input"];
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInput>;
    tenantToTenantId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInput>;
  };

/** Input for the nested mutation of `tenant` in the `FileOutputGroupMetadatumInput` mutation. */
export type FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `fileOutputGroupMetadatum` in the `TenantInput` mutation. */
export type FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput = {
  /** The primary key(s) for `fileOutputGroupMetadatum` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<FileOutputGroupMetadatumFileOutputGroupMetadataPkeyConnect>
  >;
  /** A `FileOutputGroupMetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdFileOutputGroupMetadataCreateInput>
  >;
  /** The primary key(s) for `fileOutputGroupMetadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<FileOutputGroupMetadatumFileOutputGroupMetadataPkeyDelete>
  >;
  /** Flag indicating whether all other `fileOutputGroupMetadatum` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileOutputGroupMetadatum` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingFileOutputGroupMetadataPkeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdTenantCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal: Scalars["Boolean"]["input"];
    key: Scalars["String"]["input"];
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name: Scalars["String"]["input"];
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status: Scalars["Int"]["input"];
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType: Scalars["Int"]["input"];
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

export type FileOutputGroupMetadatum = {
  __typename: "FileOutputGroupMetadatum";
  dateCreated: Scalars["Datetime"]["output"];
  /** Reads a single `FileOutputGroup` that is related to this `FileOutputGroupMetadatum`. */
  fileOutputGroupByFileOutputGroupId: Maybe<FileOutputGroup>;
  fileOutputGroupId: Maybe<Scalars["UUID"]["output"]>;
  id: Scalars["UUID"]["output"];
  metadataId: Scalars["UUID"]["output"];
  /** Reads a single `Metadatum` that is related to this `FileOutputGroupMetadatum`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `FileOutputGroupMetadatum`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  /** Reads a single `Tenant` that is related to this `FileOutputGroupMetadatum`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
};

/**
 * A condition to be used against `FileOutputGroupMetadatum` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FileOutputGroupMetadatumCondition = {
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `fileOutputGroupId` field. */
  fileOutputGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `metadataId` field. */
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `fileOutputGroupMetadatum` to look up the row to connect. */
export type FileOutputGroupMetadatumFileOutputGroupMetadataPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `fileOutputGroupMetadatum` to look up the row to delete. */
export type FileOutputGroupMetadatumFileOutputGroupMetadataPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** A filter to be used against `FileOutputGroupMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type FileOutputGroupMetadatumFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FileOutputGroupMetadatumFilter>>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fileOutputGroupByFileOutputGroupId` relation. */
  fileOutputGroupByFileOutputGroupId?: InputMaybe<FileOutputGroupFilter>;
  /** A related `fileOutputGroupByFileOutputGroupId` exists. */
  fileOutputGroupByFileOutputGroupIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `fileOutputGroupId` field. */
  fileOutputGroupId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `metadataId` field. */
  metadataId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `metadatumByMetadataId` relation. */
  metadatumByMetadataId?: InputMaybe<MetadatumFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FileOutputGroupMetadatumFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FileOutputGroupMetadatumFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `FileOutputGroupMetadatum` */
export type FileOutputGroupMetadatumInput = {
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileOutputGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupToFileOutputGroupId?: InputMaybe<FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInput>;
  id: Scalars["UUID"]["input"];
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  metadatumToMetadataId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInput>;
};

/** The fields on `fileOutputGroupMetadatum` to look up the row to update. */
export type FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpUsingFileOutputGroupMetadataPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileOutputGroupMetadatum` being updated. */
    fileOutputGroupMetadatumPatch: UpdateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileOutputGroupMetadatum` to look up the row to update. */
export type FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataUsingFileOutputGroupMetadataPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileOutputGroupMetadatum` being updated. */
    fileOutputGroupMetadatumPatch: UpdateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileOutputGroupMetadatum` to look up the row to update. */
export type FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdUsingFileOutputGroupMetadataPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileOutputGroupMetadatum` being updated. */
    fileOutputGroupMetadatumPatch: UpdateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileOutputGroupMetadatum` to look up the row to update. */
export type FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingFileOutputGroupMetadataPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileOutputGroupMetadatum` being updated. */
    fileOutputGroupMetadatumPatch: UpdateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** Represents an update to a `FileOutputGroupMetadatum`. Fields that are set will be updated. */
export type FileOutputGroupMetadatumPatch = {
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileOutputGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupToFileOutputGroupId?: InputMaybe<FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  metadatumToMetadataId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInput>;
};

/** The fields on `fileOutputGroup` to look up the row to update. */
export type FileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingFileOutputGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileOutputGroup` being updated. */
    fileOutputGroupPatch: UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileOutputGroup` to look up the row to update. */
export type FileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingFileOutputGroupTaskIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileOutputGroup` being updated. */
    fileOutputGroupPatch: UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdPatch;
    taskId: Scalars["UUID"]["input"];
  };

/** The fields on `fileOutputGroup` to look up the row to update. */
export type FileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingFileOutputGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileOutputGroup` being updated. */
    fileOutputGroupPatch: UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileOutputGroup` to look up the row to update. */
export type FileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingFileOutputGroupTaskIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileOutputGroup` being updated. */
    fileOutputGroupPatch: UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdPatch;
    taskId: Scalars["UUID"]["input"];
  };

/** The fields on `fileOutputGroup` to look up the row to update. */
export type FileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingFileOutputGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileOutputGroup` being updated. */
    fileOutputGroupPatch: UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileOutputGroup` to look up the row to update. */
export type FileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingFileOutputGroupTaskIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileOutputGroup` being updated. */
    fileOutputGroupPatch: UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdPatch;
    taskId: Scalars["UUID"]["input"];
  };

/** The fields on `fileOutputGroup` to look up the row to update. */
export type FileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingFileOutputGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileOutputGroup` being updated. */
    fileOutputGroupPatch: UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileOutputGroup` to look up the row to update. */
export type FileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingFileOutputGroupTaskIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileOutputGroup` being updated. */
    fileOutputGroupPatch: UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdPatch;
    taskId: Scalars["UUID"]["input"];
  };

/** The fields on `fileOutputGroup` to look up the row to update. */
export type FileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpUsingFileOutputGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileOutputGroup` being updated. */
    fileOutputGroupPatch: UpdateFileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileOutputGroup` to look up the row to update. */
export type FileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpUsingFileOutputGroupTaskIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileOutputGroup` being updated. */
    fileOutputGroupPatch: UpdateFileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpPatch;
    taskId: Scalars["UUID"]["input"];
  };

/** The fields on `fileOutputGroup` to look up the row to update. */
export type FileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpUsingFileOutputGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileOutputGroup` being updated. */
    fileOutputGroupPatch: UpdateFileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileOutputGroup` to look up the row to update. */
export type FileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpUsingFileOutputGroupTaskIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileOutputGroup` being updated. */
    fileOutputGroupPatch: UpdateFileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpPatch;
    taskId: Scalars["UUID"]["input"];
  };

/** Represents an update to a `FileOutputGroup`. Fields that are set will be updated. */
export type FileOutputGroupPatch = {
  applicationUserToUserId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInverseInput>;
  groupType?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInput>;
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  taskToTaskId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `project` in the `FileOutputGroupInput` mutation. */
export type FileOutputGroupProjectId908Fb9FfFkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `fileOutputGroup` in the `ProjectInput` mutation. */
export type FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput = {
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FileOutputGroupFileOutputGroupPkeyConnect>>;
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  connectByTaskId?: InputMaybe<
    Array<FileOutputGroupFileOutputGroupTaskIdKeyConnect>
  >;
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FileOutputGroupFileOutputGroupPkeyDelete>>;
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  deleteByTaskId?: InputMaybe<
    Array<FileOutputGroupFileOutputGroupTaskIdKeyDelete>
  >;
  /** Flag indicating whether all other `fileOutputGroup` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileOutputGroup` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingFileOutputGroupPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `fileOutputGroup` for the far side of the relationship. */
  updateByTaskId?: InputMaybe<
    Array<FileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingFileOutputGroupTaskIdKeyUpdate>
  >;
};

/** Input for the nested mutation of `task` in the `FileOutputGroupInput` mutation. */
export type FileOutputGroupTaskId2B1Df6A1FkTaskIdInput = {
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectByExecutorId?: InputMaybe<TaskTaskExecutorIdKeyConnect>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectById?: InputMaybe<TaskTaskPkeyConnect>;
  /** A `TaskInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdTaskCreateInput>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteByExecutorId?: InputMaybe<TaskTaskExecutorIdKeyDelete>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteById?: InputMaybe<TaskTaskPkeyDelete>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateByExecutorId?: InputMaybe<TaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingTaskExecutorIdKeyUpdate>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateById?: InputMaybe<TaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingTaskPkeyUpdate>;
};

/** Input for the nested mutation of `fileOutputGroup` in the `TaskInput` mutation. */
export type FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput = {
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  connectById?: InputMaybe<FileOutputGroupFileOutputGroupPkeyConnect>;
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  connectByTaskId?: InputMaybe<FileOutputGroupFileOutputGroupTaskIdKeyConnect>;
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<FileOutputGroupFileOutputGroupPkeyDelete>;
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  deleteByTaskId?: InputMaybe<FileOutputGroupFileOutputGroupTaskIdKeyDelete>;
  /** Flag indicating whether all other `fileOutputGroup` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileOutputGroup` for the far side of the relationship. */
  updateById?: InputMaybe<FileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingFileOutputGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `fileOutputGroup` for the far side of the relationship. */
  updateByTaskId?: InputMaybe<FileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingFileOutputGroupTaskIdKeyUpdate>;
};

/** The `task` to be created by this mutation. */
export type FileOutputGroupTaskId2B1Df6A1FkTaskIdTaskCreateInput = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned: Scalars["Boolean"]["input"];
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id: Scalars["UUID"]["input"];
  inputsHash: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status: Scalars["Int"]["input"];
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `tenant` in the `FileOutputGroupInput` mutation. */
export type FileOutputGroupTenantId3Fa46D44FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `fileOutputGroup` in the `TenantInput` mutation. */
export type FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput = {
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FileOutputGroupFileOutputGroupPkeyConnect>>;
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  connectByTaskId?: InputMaybe<
    Array<FileOutputGroupFileOutputGroupTaskIdKeyConnect>
  >;
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FileOutputGroupFileOutputGroupPkeyDelete>>;
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  deleteByTaskId?: InputMaybe<
    Array<FileOutputGroupFileOutputGroupTaskIdKeyDelete>
  >;
  /** Flag indicating whether all other `fileOutputGroup` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileOutputGroup` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingFileOutputGroupPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `fileOutputGroup` for the far side of the relationship. */
  updateByTaskId?: InputMaybe<
    Array<FileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingFileOutputGroupTaskIdKeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type FileOutputGroupTenantId3Fa46D44FkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** A filter to be used against many `FileOutputGroupMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type FileOutputGroupToManyFileOutputGroupMetadatumFilter = {
  /** Every related `FileOutputGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileOutputGroupMetadatumFilter>;
  /** No related `FileOutputGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileOutputGroupMetadatumFilter>;
  /** Some related `FileOutputGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileOutputGroupMetadatumFilter>;
};

/** A filter to be used against many `OutputGroupFile` object types. All fields are combined with a logical ‘and.’ */
export type FileOutputGroupToManyOutputGroupFileFilter = {
  /** Every related `OutputGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<OutputGroupFileFilter>;
  /** No related `OutputGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<OutputGroupFileFilter>;
  /** Some related `OutputGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<OutputGroupFileFilter>;
};

/** The `applicationUser` to be created by this mutation. */
export type FileOutputGroupUserIdB22Ec436FkApplicationUserIdApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `FileOutputGroupInput` mutation. */
export type FileOutputGroupUserIdB22Ec436FkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `fileOutputGroup` in the `ApplicationUserInput` mutation. */
export type FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput = {
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FileOutputGroupFileOutputGroupPkeyConnect>>;
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  connectByTaskId?: InputMaybe<
    Array<FileOutputGroupFileOutputGroupTaskIdKeyConnect>
  >;
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FileOutputGroupFileOutputGroupPkeyDelete>>;
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  deleteByTaskId?: InputMaybe<
    Array<FileOutputGroupFileOutputGroupTaskIdKeyDelete>
  >;
  /** Flag indicating whether all other `fileOutputGroup` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileOutputGroup` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingFileOutputGroupPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `fileOutputGroup` for the far side of the relationship. */
  updateByTaskId?: InputMaybe<
    Array<FileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingFileOutputGroupTaskIdKeyUpdate>
  >;
};

/** A connection to a list of `FileOutputGroup` values. */
export type FileOutputGroupsConnection = {
  __typename: "FileOutputGroupsConnection";
  /** A list of edges which contains the `FileOutputGroup` and cursor to aid in pagination. */
  edges: Array<FileOutputGroupsEdge>;
  /** A list of `FileOutputGroup` objects. */
  nodes: Array<FileOutputGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FileOutputGroup` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `FileOutputGroup` edge in the connection. */
export type FileOutputGroupsEdge = {
  __typename: "FileOutputGroupsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `FileOutputGroup` at the end of the edge. */
  node: FileOutputGroup;
};

/** Methods to use when ordering `FileOutputGroup`. */
export enum FileOutputGroupsOrderBy {
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  GroupTypeAsc = "GROUP_TYPE_ASC",
  GroupTypeDesc = "GROUP_TYPE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  KeyAsc = "KEY_ASC",
  KeyDesc = "KEY_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  TaskIdAsc = "TASK_ID_ASC",
  TaskIdDesc = "TASK_ID_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** Represents an update to a `File`. Fields that are set will be updated. */
export type FilePatch = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  isSeries?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  originalName?: InputMaybe<Scalars["String"]["input"]>;
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus?: InputMaybe<Scalars["Int"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `applicationUser` to be created by this mutation. */
export type FileProcessingNotiUserId08265156FkApplicatiApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `FileProcessingNotificationInput` mutation. */
export type FileProcessingNotiUserId08265156FkApplicatiInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `fileProcessingNotification` in the `ApplicationUserInput` mutation. */
export type FileProcessingNotiUserId08265156FkApplicatiInverseInput = {
  /** The primary key(s) for `fileProcessingNotification` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<FileProcessingNotificationFileProcessingNotificationPkeyConnect>
  >;
  /** The primary key(s) and patch data for `fileProcessingNotification` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingFileProcessingNotificationPkeyUpdate>
  >;
};

export type FileProcessingNotification = {
  __typename: "FileProcessingNotification";
  /** Reads a single `ApplicationUser` that is related to this `FileProcessingNotification`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** Reads a single `File` that is related to this `FileProcessingNotification`. */
  fileByFileId: Maybe<File>;
  fileId: Scalars["UUID"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads a single `Notification` that is related to this `FileProcessingNotification`. */
  notificationById: Maybe<Notification>;
  status: Scalars["Int"]["output"];
  userId: Scalars["UUID"]["output"];
};

/**
 * A condition to be used against `FileProcessingNotification` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FileProcessingNotificationCondition = {
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `file` to be created by this mutation. */
export type FileProcessingNotificationFileId717B514EFkFileIdFileCreateInput = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["UUID"]["input"];
  isSeries: Scalars["Boolean"]["input"];
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  originalName: Scalars["String"]["input"];
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus: Scalars["Int"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status: Scalars["Int"]["input"];
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `file` in the `FileProcessingNotificationInput` mutation. */
export type FileProcessingNotificationFileId717B514EFkFileIdInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: InputMaybe<FileFilePkeyConnect>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdFileCreateInput>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: InputMaybe<FileFilePkeyDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: InputMaybe<FileOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdUsingFilePkeyUpdate>;
};

/** Input for the nested mutation of `fileProcessingNotification` in the `FileInput` mutation. */
export type FileProcessingNotificationFileId717B514EFkFileIdInverseInput = {
  /** The primary key(s) for `fileProcessingNotification` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<FileProcessingNotificationFileProcessingNotificationPkeyConnect>
  >;
  /** The primary key(s) and patch data for `fileProcessingNotification` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdUsingFileProcessingNotificationPkeyUpdate>
  >;
};

/** The fields on `fileProcessingNotification` to look up the row to connect. */
export type FileProcessingNotificationFileProcessingNotificationPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** A filter to be used against `FileProcessingNotification` object types. All fields are combined with a logical ‘and.’ */
export type FileProcessingNotificationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FileProcessingNotificationFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `fileByFileId` relation. */
  fileByFileId?: InputMaybe<FileFilter>;
  /** Filter by the object’s `fileId` field. */
  fileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FileProcessingNotificationFilter>;
  /** Filter by the object’s `notificationById` relation. */
  notificationById?: InputMaybe<NotificationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FileProcessingNotificationFilter>>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** Input for the nested mutation of `notification` in the `FileProcessingNotificationInput` mutation. */
export type FileProcessingNotificationId630E6495FkNotificationIdInput = {
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectById?: InputMaybe<NotificationNotificationPkeyConnect>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateById?: InputMaybe<NotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdUsingNotificationPkeyUpdate>;
};

/** Input for the nested mutation of `fileProcessingNotification` in the `NotificationInput` mutation. */
export type FileProcessingNotificationId630E6495FkNotificationIdInverseInput = {
  /** The primary key(s) for `fileProcessingNotification` for the far side of the relationship. */
  connectById?: InputMaybe<FileProcessingNotificationFileProcessingNotificationPkeyConnect>;
  /** The primary key(s) and patch data for `fileProcessingNotification` for the far side of the relationship. */
  updateById?: InputMaybe<FileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdUsingFileProcessingNotificationPkeyUpdate>;
};

/** The fields on `fileProcessingNotification` to look up the row to update. */
export type FileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingFileProcessingNotificationPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileProcessingNotification` being updated. */
    fileProcessingNotificationPatch: UpdateFileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileProcessingNotification` to look up the row to update. */
export type FileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdUsingFileProcessingNotificationPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileProcessingNotification` being updated. */
    fileProcessingNotificationPatch: UpdateFileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileProcessingNotification` to look up the row to update. */
export type FileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdUsingFileProcessingNotificationPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileProcessingNotification` being updated. */
    fileProcessingNotificationPatch: UpdateFileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** A connection to a list of `FileProcessingNotification` values. */
export type FileProcessingNotificationsConnection = {
  __typename: "FileProcessingNotificationsConnection";
  /** A list of edges which contains the `FileProcessingNotification` and cursor to aid in pagination. */
  edges: Array<FileProcessingNotificationsEdge>;
  /** A list of `FileProcessingNotification` objects. */
  nodes: Array<FileProcessingNotification>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FileProcessingNotification` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `FileProcessingNotification` edge in the connection. */
export type FileProcessingNotificationsEdge = {
  __typename: "FileProcessingNotificationsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `FileProcessingNotification` at the end of the edge. */
  node: FileProcessingNotification;
};

/** Methods to use when ordering `FileProcessingNotification`. */
export enum FileProcessingNotificationsOrderBy {
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** The `file` to be created by this mutation. */
export type FileProjectId3Ecca975FkProjectIdFileCreateInput = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["UUID"]["input"];
  isSeries: Scalars["Boolean"]["input"];
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  originalName: Scalars["String"]["input"];
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus: Scalars["Int"]["input"];
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status: Scalars["Int"]["input"];
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `project` in the `FileInput` mutation. */
export type FileProjectId3Ecca975FkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnFileForFileProjectId3Ecca975FkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnFileForFileProjectId3Ecca975FkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `file` in the `ProjectInput` mutation. */
export type FileProjectId3Ecca975FkProjectIdInverseInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FileFilePkeyConnect>>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FileProjectId3Ecca975FkProjectIdFileCreateInput>>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FileFilePkeyDelete>>;
  /** Flag indicating whether all other `file` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileOnFileForFileProjectId3Ecca975FkProjectIdUsingFilePkeyUpdate>
  >;
};

export type FileRecordingGroup = {
  __typename: "FileRecordingGroup";
  /** Reads a single `ApplicationUser` that is related to this `FileRecordingGroup`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** Reads a single `DatasetRecordingsTable` that is related to this `FileRecordingGroup`. */
  datasetRecordingsTableByDatasetRecordingsTableId: Maybe<DatasetRecordingsTable>;
  datasetRecordingsTableId: Scalars["UUID"]["output"];
  dateAssignmentEnded: Maybe<Scalars["Datetime"]["output"]>;
  dateCreated: Scalars["Datetime"]["output"];
  /** Reads and enables pagination through a set of `FileRecordingGroupMetadatum`. */
  fileRecordingGroupMetadataByFileRecordingGroupId: FileRecordingGroupMetadataConnection;
  id: Scalars["UUID"]["output"];
  number: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads a single `Project` that is related to this `FileRecordingGroup`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  /** Reads and enables pagination through a set of `RecordingGroupFile`. */
  recordingGroupFilesByFileRecordingGroupId: RecordingGroupFilesConnection;
  /** Reads a single `Tenant` that is related to this `FileRecordingGroup`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  userId: Scalars["UUID"]["output"];
};

export type FileRecordingGroupFileRecordingGroupMetadataByFileRecordingGroupIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<FileRecordingGroupMetadatumCondition>;
    filter?: InputMaybe<FileRecordingGroupMetadatumFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<FileRecordingGroupMetadataOrderBy>>;
  };

export type FileRecordingGroupRecordingGroupFilesByFileRecordingGroupIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<RecordingGroupFileCondition>;
  filter?: InputMaybe<RecordingGroupFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RecordingGroupFilesOrderBy>>;
};

/**
 * A condition to be used against `FileRecordingGroup` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FileRecordingGroupCondition = {
  /** Checks for equality with the object’s `datasetRecordingsTableId` field. */
  datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `dateAssignmentEnded` field. */
  dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `datasetRecordingsTable` to be created by this mutation. */
export type FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRDatasetRecordingsTableCreateInput =
  {
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInverseInput>;
    datasetUsingId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInverseInput>;
    id: Scalars["UUID"]["input"];
    name: Scalars["String"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInput>;
    views?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** The `fileRecordingGroup` to be created by this mutation. */
export type FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRFileRecordingGroupCreateInput =
  {
    applicationUserToUserId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInput>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput>;
    id: Scalars["UUID"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `datasetRecordingsTable` in the `FileRecordingGroupInput` mutation. */
export type FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInput = {
  /** The primary key(s) for `datasetRecordingsTable` for the far side of the relationship. */
  connectById?: InputMaybe<DatasetRecordingsTableDatasetRecordingsTablePkeyConnect>;
  /** A `DatasetRecordingsTableInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRDatasetRecordingsTableCreateInput>;
  /** The primary key(s) for `datasetRecordingsTable` for the far side of the relationship. */
  deleteById?: InputMaybe<DatasetRecordingsTableDatasetRecordingsTablePkeyDelete>;
  /** The primary key(s) and patch data for `datasetRecordingsTable` for the far side of the relationship. */
  updateById?: InputMaybe<DatasetRecordingsTableOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRUsingDatasetRecordingsTablePkeyUpdate>;
};

/** Input for the nested mutation of `fileRecordingGroup` in the `DatasetRecordingsTableInput` mutation. */
export type FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInverseInput =
  {
    /** The primary key(s) for `fileRecordingGroup` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<FileRecordingGroupFileRecordingGroupPkeyConnect>
    >;
    /** A `FileRecordingGroupInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRFileRecordingGroupCreateInput>
    >;
    /** The primary key(s) for `fileRecordingGroup` for the far side of the relationship. */
    deleteById?: InputMaybe<
      Array<FileRecordingGroupFileRecordingGroupPkeyDelete>
    >;
    /** Flag indicating whether all other `fileRecordingGroup` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `fileRecordingGroup` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRUsingFileRecordingGroupPkeyUpdate>
    >;
  };

/** The `fileRecordingGroup` to be created by this mutation. */
export type FileRecordingGroupFileRecordingGroup60583376FkFileRecoFileRecordingGroupCreateInput =
  {
    applicationUserToUserId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput>;
    id: Scalars["UUID"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** The `fileRecordingGroupMetadatum` to be created by this mutation. */
export type FileRecordingGroupFileRecordingGroup60583376FkFileRecoFileRecordingGroupMetadataCreateInput =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInput>;
    id: Scalars["UUID"]["input"];
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInput>;
  };

/** Input for the nested mutation of `fileRecordingGroup` in the `FileRecordingGroupMetadatumInput` mutation. */
export type FileRecordingGroupFileRecordingGroup60583376FkFileRecoInput = {
  /** The primary key(s) for `fileRecordingGroup` for the far side of the relationship. */
  connectById?: InputMaybe<FileRecordingGroupFileRecordingGroupPkeyConnect>;
  /** A `FileRecordingGroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoFileRecordingGroupCreateInput>;
  /** The primary key(s) for `fileRecordingGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<FileRecordingGroupFileRecordingGroupPkeyDelete>;
  /** The primary key(s) and patch data for `fileRecordingGroup` for the far side of the relationship. */
  updateById?: InputMaybe<FileRecordingGroupOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoUsingFileRecordingGroupPkeyUpdate>;
};

/** Input for the nested mutation of `fileRecordingGroupMetadatum` in the `FileRecordingGroupInput` mutation. */
export type FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput =
  {
    /** The primary key(s) for `fileRecordingGroupMetadatum` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<FileRecordingGroupMetadatumFileRecordingGroupMetadataPkeyConnect>
    >;
    /** A `FileRecordingGroupMetadatumInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<FileRecordingGroupFileRecordingGroup60583376FkFileRecoFileRecordingGroupMetadataCreateInput>
    >;
    /** The primary key(s) for `fileRecordingGroupMetadatum` for the far side of the relationship. */
    deleteById?: InputMaybe<
      Array<FileRecordingGroupMetadatumFileRecordingGroupMetadataPkeyDelete>
    >;
    /** Flag indicating whether all other `fileRecordingGroupMetadatum` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `fileRecordingGroupMetadatum` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoUsingFileRecordingGroupMetadataPkeyUpdate>
    >;
  };

/** The fields on `fileRecordingGroup` to look up the row to connect. */
export type FileRecordingGroupFileRecordingGroupPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `fileRecordingGroup` to look up the row to delete. */
export type FileRecordingGroupFileRecordingGroupPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** A filter to be used against `FileRecordingGroup` object types. All fields are combined with a logical ‘and.’ */
export type FileRecordingGroupFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FileRecordingGroupFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `datasetRecordingsTableByDatasetRecordingsTableId` relation. */
  datasetRecordingsTableByDatasetRecordingsTableId?: InputMaybe<DatasetRecordingsTableFilter>;
  /** Filter by the object’s `datasetRecordingsTableId` field. */
  datasetRecordingsTableId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `dateAssignmentEnded` field. */
  dateAssignmentEnded?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fileRecordingGroupMetadataByFileRecordingGroupId` relation. */
  fileRecordingGroupMetadataByFileRecordingGroupId?: InputMaybe<FileRecordingGroupToManyFileRecordingGroupMetadatumFilter>;
  /** Some related `fileRecordingGroupMetadataByFileRecordingGroupId` exist. */
  fileRecordingGroupMetadataByFileRecordingGroupIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FileRecordingGroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FileRecordingGroupFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `recordingGroupFilesByFileRecordingGroupId` relation. */
  recordingGroupFilesByFileRecordingGroupId?: InputMaybe<FileRecordingGroupToManyRecordingGroupFileFilter>;
  /** Some related `recordingGroupFilesByFileRecordingGroupId` exist. */
  recordingGroupFilesByFileRecordingGroupIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `FileRecordingGroup` */
export type FileRecordingGroupInput = {
  applicationUserToUserId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInput>;
  datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInput>;
  dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput>;
  id: Scalars["UUID"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A connection to a list of `FileRecordingGroupMetadatum` values. */
export type FileRecordingGroupMetadataConnection = {
  __typename: "FileRecordingGroupMetadataConnection";
  /** A list of edges which contains the `FileRecordingGroupMetadatum` and cursor to aid in pagination. */
  edges: Array<FileRecordingGroupMetadataEdge>;
  /** A list of `FileRecordingGroupMetadatum` objects. */
  nodes: Array<FileRecordingGroupMetadatum>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FileRecordingGroupMetadatum` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `FileRecordingGroupMetadatum` edge in the connection. */
export type FileRecordingGroupMetadataEdge = {
  __typename: "FileRecordingGroupMetadataEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `FileRecordingGroupMetadatum` at the end of the edge. */
  node: FileRecordingGroupMetadatum;
};

/** The `fileRecordingGroupMetadatum` to be created by this mutation. */
export type FileRecordingGroupMetadataIdF054E41AFkMetadataFileRecordingGroupMetadataCreateInput =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInput>;
    id: Scalars["UUID"]["input"];
    metadatumToMetadataId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInput>;
  };

/** Input for the nested mutation of `metadatum` in the `FileRecordingGroupMetadatumInput` mutation. */
export type FileRecordingGroupMetadataIdF054E41AFkMetadataInput = {
  /** The primary key(s) for `metadatum` for the far side of the relationship. */
  connectById?: InputMaybe<MetadatumMetadataV4PkeyConnect>;
  /** A `MetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataMetadataCreateInput>;
  /** The primary key(s) for `metadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<MetadatumMetadataV4PkeyDelete>;
  /** The primary key(s) and patch data for `metadatum` for the far side of the relationship. */
  updateById?: InputMaybe<MetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataUsingMetadataV4PkeyUpdate>;
};

/** Input for the nested mutation of `fileRecordingGroupMetadatum` in the `MetadatumInput` mutation. */
export type FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput = {
  /** The primary key(s) for `fileRecordingGroupMetadatum` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<FileRecordingGroupMetadatumFileRecordingGroupMetadataPkeyConnect>
  >;
  /** A `FileRecordingGroupMetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileRecordingGroupMetadataIdF054E41AFkMetadataFileRecordingGroupMetadataCreateInput>
  >;
  /** The primary key(s) for `fileRecordingGroupMetadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<FileRecordingGroupMetadatumFileRecordingGroupMetadataPkeyDelete>
  >;
  /** Flag indicating whether all other `fileRecordingGroupMetadatum` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileRecordingGroupMetadatum` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataUsingFileRecordingGroupMetadataPkeyUpdate>
  >;
};

/** The `metadatum` to be created by this mutation. */
export type FileRecordingGroupMetadataIdF054E41AFkMetadataMetadataCreateInput =
  {
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
    displayName: Scalars["String"]["input"];
    fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
    id: Scalars["UUID"]["input"];
    key: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
  };

/** Methods to use when ordering `FileRecordingGroupMetadatum`. */
export enum FileRecordingGroupMetadataOrderBy {
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  FileRecordingGroupIdAsc = "FILE_RECORDING_GROUP_ID_ASC",
  FileRecordingGroupIdDesc = "FILE_RECORDING_GROUP_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MetadataIdAsc = "METADATA_ID_ASC",
  MetadataIdDesc = "METADATA_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
}

/** The `fileRecordingGroupMetadatum` to be created by this mutation. */
export type FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdFileRecordingGroupMetadataCreateInput =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInput>;
    id: Scalars["UUID"]["input"];
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInput>;
    projectToProjectId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInput>;
  };

/** Input for the nested mutation of `project` in the `FileRecordingGroupMetadatumInput` mutation. */
export type FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `fileRecordingGroupMetadatum` in the `ProjectInput` mutation. */
export type FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput =
  {
    /** The primary key(s) for `fileRecordingGroupMetadatum` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<FileRecordingGroupMetadatumFileRecordingGroupMetadataPkeyConnect>
    >;
    /** A `FileRecordingGroupMetadatumInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdFileRecordingGroupMetadataCreateInput>
    >;
    /** The primary key(s) for `fileRecordingGroupMetadatum` for the far side of the relationship. */
    deleteById?: InputMaybe<
      Array<FileRecordingGroupMetadatumFileRecordingGroupMetadataPkeyDelete>
    >;
    /** Flag indicating whether all other `fileRecordingGroupMetadatum` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `fileRecordingGroupMetadatum` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdUsingFileRecordingGroupMetadataPkeyUpdate>
    >;
  };

/** The `fileRecordingGroupMetadatum` to be created by this mutation. */
export type FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdFileRecordingGroupMetadataCreateInput =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInput>;
    id: Scalars["UUID"]["input"];
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInput>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInput>;
  };

/** Input for the nested mutation of `tenant` in the `FileRecordingGroupMetadatumInput` mutation. */
export type FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `fileRecordingGroupMetadatum` in the `TenantInput` mutation. */
export type FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput = {
  /** The primary key(s) for `fileRecordingGroupMetadatum` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<FileRecordingGroupMetadatumFileRecordingGroupMetadataPkeyConnect>
  >;
  /** A `FileRecordingGroupMetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdFileRecordingGroupMetadataCreateInput>
  >;
  /** The primary key(s) for `fileRecordingGroupMetadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<FileRecordingGroupMetadatumFileRecordingGroupMetadataPkeyDelete>
  >;
  /** Flag indicating whether all other `fileRecordingGroupMetadatum` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileRecordingGroupMetadatum` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingFileRecordingGroupMetadataPkeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdTenantCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal: Scalars["Boolean"]["input"];
    key: Scalars["String"]["input"];
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name: Scalars["String"]["input"];
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status: Scalars["Int"]["input"];
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType: Scalars["Int"]["input"];
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

export type FileRecordingGroupMetadatum = {
  __typename: "FileRecordingGroupMetadatum";
  dateCreated: Scalars["Datetime"]["output"];
  /** Reads a single `FileRecordingGroup` that is related to this `FileRecordingGroupMetadatum`. */
  fileRecordingGroupByFileRecordingGroupId: Maybe<FileRecordingGroup>;
  fileRecordingGroupId: Maybe<Scalars["UUID"]["output"]>;
  id: Scalars["UUID"]["output"];
  metadataId: Scalars["UUID"]["output"];
  /** Reads a single `Metadatum` that is related to this `FileRecordingGroupMetadatum`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `FileRecordingGroupMetadatum`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  /** Reads a single `Tenant` that is related to this `FileRecordingGroupMetadatum`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
};

/**
 * A condition to be used against `FileRecordingGroupMetadatum` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FileRecordingGroupMetadatumCondition = {
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `fileRecordingGroupId` field. */
  fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `metadataId` field. */
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `fileRecordingGroupMetadatum` to look up the row to connect. */
export type FileRecordingGroupMetadatumFileRecordingGroupMetadataPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `fileRecordingGroupMetadatum` to look up the row to delete. */
export type FileRecordingGroupMetadatumFileRecordingGroupMetadataPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** A filter to be used against `FileRecordingGroupMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type FileRecordingGroupMetadatumFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FileRecordingGroupMetadatumFilter>>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fileRecordingGroupByFileRecordingGroupId` relation. */
  fileRecordingGroupByFileRecordingGroupId?: InputMaybe<FileRecordingGroupFilter>;
  /** A related `fileRecordingGroupByFileRecordingGroupId` exists. */
  fileRecordingGroupByFileRecordingGroupIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `fileRecordingGroupId` field. */
  fileRecordingGroupId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `metadataId` field. */
  metadataId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `metadatumByMetadataId` relation. */
  metadatumByMetadataId?: InputMaybe<MetadatumFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FileRecordingGroupMetadatumFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FileRecordingGroupMetadatumFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `FileRecordingGroupMetadatum` */
export type FileRecordingGroupMetadatumInput = {
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileRecordingGroupToFileRecordingGroupId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInput>;
  id: Scalars["UUID"]["input"];
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  metadatumToMetadataId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInput>;
};

/** The fields on `fileRecordingGroupMetadatum` to look up the row to update. */
export type FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoUsingFileRecordingGroupMetadataPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileRecordingGroupMetadatum` being updated. */
    fileRecordingGroupMetadatumPatch: UpdateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileRecordingGroupMetadatum` to look up the row to update. */
export type FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataUsingFileRecordingGroupMetadataPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileRecordingGroupMetadatum` being updated. */
    fileRecordingGroupMetadatumPatch: UpdateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileRecordingGroupMetadatum` to look up the row to update. */
export type FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdUsingFileRecordingGroupMetadataPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileRecordingGroupMetadatum` being updated. */
    fileRecordingGroupMetadatumPatch: UpdateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileRecordingGroupMetadatum` to look up the row to update. */
export type FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingFileRecordingGroupMetadataPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileRecordingGroupMetadatum` being updated. */
    fileRecordingGroupMetadatumPatch: UpdateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** Represents an update to a `FileRecordingGroupMetadatum`. Fields that are set will be updated. */
export type FileRecordingGroupMetadatumPatch = {
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileRecordingGroupToFileRecordingGroupId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  metadatumToMetadataId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInput>;
};

/** The fields on `fileRecordingGroup` to look up the row to update. */
export type FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRUsingFileRecordingGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileRecordingGroup` being updated. */
    fileRecordingGroupPatch: UpdateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileRecordingGroup` to look up the row to update. */
export type FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdUsingFileRecordingGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileRecordingGroup` being updated. */
    fileRecordingGroupPatch: UpdateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileRecordingGroup` to look up the row to update. */
export type FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingFileRecordingGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileRecordingGroup` being updated. */
    fileRecordingGroupPatch: UpdateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileRecordingGroup` to look up the row to update. */
export type FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingFileRecordingGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileRecordingGroup` being updated. */
    fileRecordingGroupPatch: UpdateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileRecordingGroup` to look up the row to update. */
export type FileRecordingGroupOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoUsingFileRecordingGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileRecordingGroup` being updated. */
    fileRecordingGroupPatch: UpdateFileRecordingGroupOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoPatch;
    id: Scalars["UUID"]["input"];
  };

/** The fields on `fileRecordingGroup` to look up the row to update. */
export type FileRecordingGroupOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoUsingFileRecordingGroupPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `fileRecordingGroup` being updated. */
    fileRecordingGroupPatch: UpdateFileRecordingGroupOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoPatch;
    id: Scalars["UUID"]["input"];
  };

/** Represents an update to a `FileRecordingGroup`. Fields that are set will be updated. */
export type FileRecordingGroupPatch = {
  applicationUserToUserId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInput>;
  datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInput>;
  dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `fileRecordingGroup` to be created by this mutation. */
export type FileRecordingGroupProjectIdB14Ba577FkProjectIdFileRecordingGroupCreateInput =
  {
    applicationUserToUserId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput>;
    id: Scalars["UUID"]["input"];
    projectToProjectId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `project` in the `FileRecordingGroupInput` mutation. */
export type FileRecordingGroupProjectIdB14Ba577FkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `fileRecordingGroup` in the `ProjectInput` mutation. */
export type FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput = {
  /** The primary key(s) for `fileRecordingGroup` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<FileRecordingGroupFileRecordingGroupPkeyConnect>
  >;
  /** A `FileRecordingGroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileRecordingGroupProjectIdB14Ba577FkProjectIdFileRecordingGroupCreateInput>
  >;
  /** The primary key(s) for `fileRecordingGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<FileRecordingGroupFileRecordingGroupPkeyDelete>
  >;
  /** Flag indicating whether all other `fileRecordingGroup` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileRecordingGroup` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdUsingFileRecordingGroupPkeyUpdate>
  >;
};

/** The `fileRecordingGroup` to be created by this mutation. */
export type FileRecordingGroupTenantIdCbdec6BcFkTenantIdFileRecordingGroupCreateInput =
  {
    applicationUserToUserId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput>;
    id: Scalars["UUID"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `tenant` in the `FileRecordingGroupInput` mutation. */
export type FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `fileRecordingGroup` in the `TenantInput` mutation. */
export type FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput = {
  /** The primary key(s) for `fileRecordingGroup` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<FileRecordingGroupFileRecordingGroupPkeyConnect>
  >;
  /** A `FileRecordingGroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileRecordingGroupTenantIdCbdec6BcFkTenantIdFileRecordingGroupCreateInput>
  >;
  /** The primary key(s) for `fileRecordingGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<FileRecordingGroupFileRecordingGroupPkeyDelete>
  >;
  /** Flag indicating whether all other `fileRecordingGroup` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileRecordingGroup` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingFileRecordingGroupPkeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type FileRecordingGroupTenantIdCbdec6BcFkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** A filter to be used against many `FileRecordingGroupMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type FileRecordingGroupToManyFileRecordingGroupMetadatumFilter = {
  /** Every related `FileRecordingGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileRecordingGroupMetadatumFilter>;
  /** No related `FileRecordingGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileRecordingGroupMetadatumFilter>;
  /** Some related `FileRecordingGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileRecordingGroupMetadatumFilter>;
};

/** A filter to be used against many `RecordingGroupFile` object types. All fields are combined with a logical ‘and.’ */
export type FileRecordingGroupToManyRecordingGroupFileFilter = {
  /** Every related `RecordingGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<RecordingGroupFileFilter>;
  /** No related `RecordingGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<RecordingGroupFileFilter>;
  /** Some related `RecordingGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<RecordingGroupFileFilter>;
};

/** The `applicationUser` to be created by this mutation. */
export type FileRecordingGroupUserId421A5CddFkApplicationUserIdApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** The `fileRecordingGroup` to be created by this mutation. */
export type FileRecordingGroupUserId421A5CddFkApplicationUserIdFileRecordingGroupCreateInput =
  {
    applicationUserToUserId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput>;
    id: Scalars["UUID"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `FileRecordingGroupInput` mutation. */
export type FileRecordingGroupUserId421A5CddFkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `fileRecordingGroup` in the `ApplicationUserInput` mutation. */
export type FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput = {
  /** The primary key(s) for `fileRecordingGroup` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<FileRecordingGroupFileRecordingGroupPkeyConnect>
  >;
  /** A `FileRecordingGroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileRecordingGroupUserId421A5CddFkApplicationUserIdFileRecordingGroupCreateInput>
  >;
  /** The primary key(s) for `fileRecordingGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<FileRecordingGroupFileRecordingGroupPkeyDelete>
  >;
  /** Flag indicating whether all other `fileRecordingGroup` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `fileRecordingGroup` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingFileRecordingGroupPkeyUpdate>
  >;
};

/** A connection to a list of `FileRecordingGroup` values. */
export type FileRecordingGroupsConnection = {
  __typename: "FileRecordingGroupsConnection";
  /** A list of edges which contains the `FileRecordingGroup` and cursor to aid in pagination. */
  edges: Array<FileRecordingGroupsEdge>;
  /** A list of `FileRecordingGroup` objects. */
  nodes: Array<FileRecordingGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FileRecordingGroup` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `FileRecordingGroup` edge in the connection. */
export type FileRecordingGroupsEdge = {
  __typename: "FileRecordingGroupsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `FileRecordingGroup` at the end of the edge. */
  node: FileRecordingGroup;
};

/** Methods to use when ordering `FileRecordingGroup`. */
export enum FileRecordingGroupsOrderBy {
  DatasetRecordingsTableIdAsc = "DATASET_RECORDINGS_TABLE_ID_ASC",
  DatasetRecordingsTableIdDesc = "DATASET_RECORDINGS_TABLE_ID_DESC",
  DateAssignmentEndedAsc = "DATE_ASSIGNMENT_ENDED_ASC",
  DateAssignmentEndedDesc = "DATE_ASSIGNMENT_ENDED_DESC",
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** The `file` to be created by this mutation. */
export type FileSeriesParentIdF7A07E20FkFileIdFileCreateInput = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["UUID"]["input"];
  isSeries: Scalars["Boolean"]["input"];
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  originalName: Scalars["String"]["input"];
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus: Scalars["Int"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status: Scalars["Int"]["input"];
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `file` in the `FileInput` mutation. */
export type FileSeriesParentIdF7A07E20FkFileIdInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: InputMaybe<FileFilePkeyConnect>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdFileCreateInput>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: InputMaybe<FileFilePkeyDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: InputMaybe<FileOnFileForFileSeriesParentIdF7A07E20FkFileIdUsingFilePkeyUpdate>;
};

export enum FileSource {
  AnalysisResult = "ANALYSIS_RESULT",
  Unknown = "UNKNOWN",
  Uploaded = "UPLOADED",
}

/** The `file` to be created by this mutation. */
export type FileTenantId61625016FkTenantIdFileCreateInput = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["UUID"]["input"];
  isSeries: Scalars["Boolean"]["input"];
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  originalName: Scalars["String"]["input"];
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus: Scalars["Int"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status: Scalars["Int"]["input"];
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `tenant` in the `FileInput` mutation. */
export type FileTenantId61625016FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileTenantId61625016FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnFileForFileTenantId61625016FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnFileForFileTenantId61625016FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnFileForFileTenantId61625016FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `file` in the `TenantInput` mutation. */
export type FileTenantId61625016FkTenantIdInverseInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FileFilePkeyConnect>>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FileTenantId61625016FkTenantIdFileCreateInput>>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FileFilePkeyDelete>>;
  /** Flag indicating whether all other `file` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileOnFileForFileTenantId61625016FkTenantIdUsingFilePkeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type FileTenantId61625016FkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** A filter to be used against many `FileActivity` object types. All fields are combined with a logical ‘and.’ */
export type FileToManyFileActivityFilter = {
  /** Every related `FileActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileActivityFilter>;
  /** No related `FileActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileActivityFilter>;
  /** Some related `FileActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileActivityFilter>;
};

/** A filter to be used against many `File` object types. All fields are combined with a logical ‘and.’ */
export type FileToManyFileFilter = {
  /** Every related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileFilter>;
  /** No related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileFilter>;
  /** Some related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileFilter>;
};

/** A filter to be used against many `FileMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type FileToManyFileMetadatumFilter = {
  /** Every related `FileMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileMetadatumFilter>;
  /** No related `FileMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileMetadatumFilter>;
  /** Some related `FileMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileMetadatumFilter>;
};

/** A filter to be used against many `FileProcessingNotification` object types. All fields are combined with a logical ‘and.’ */
export type FileToManyFileProcessingNotificationFilter = {
  /** Every related `FileProcessingNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileProcessingNotificationFilter>;
  /** No related `FileProcessingNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileProcessingNotificationFilter>;
  /** Some related `FileProcessingNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileProcessingNotificationFilter>;
};

/** A filter to be used against many `OutputGroupFile` object types. All fields are combined with a logical ‘and.’ */
export type FileToManyOutputGroupFileFilter = {
  /** Every related `OutputGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<OutputGroupFileFilter>;
  /** No related `OutputGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<OutputGroupFileFilter>;
  /** Some related `OutputGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<OutputGroupFileFilter>;
};

/** A filter to be used against many `RecordingGroupFile` object types. All fields are combined with a logical ‘and.’ */
export type FileToManyRecordingGroupFileFilter = {
  /** Every related `RecordingGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<RecordingGroupFileFilter>;
  /** No related `RecordingGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<RecordingGroupFileFilter>;
  /** Some related `RecordingGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<RecordingGroupFileFilter>;
};

/** A filter to be used against many `TaskSource` object types. All fields are combined with a logical ‘and.’ */
export type FileToManyTaskSourceFilter = {
  /** Every related `TaskSource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TaskSourceFilter>;
  /** No related `TaskSource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TaskSourceFilter>;
  /** Some related `TaskSource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TaskSourceFilter>;
};

/** The `applicationUser` to be created by this mutation. */
export type FileUserId01D90B53FkApplicationUserIdApplicationUserCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
  applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
  applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
  applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
  avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
  cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
  djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
  email: Scalars["String"]["input"];
  eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
  executorId?: InputMaybe<Scalars["Int"]["input"]>;
  explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
  explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
  explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
  filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
  firstName: Scalars["String"]["input"];
  globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  homeRegion?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  internal: Scalars["Boolean"]["input"];
  isActive: Scalars["Boolean"]["input"];
  isStaff: Scalars["Boolean"]["input"];
  isSuperuser: Scalars["Boolean"]["input"];
  lastName: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
  notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
  projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
  projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
  projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
  projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
  tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
  tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
  tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
  tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
  username: Scalars["String"]["input"];
  workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
};

/** The `file` to be created by this mutation. */
export type FileUserId01D90B53FkApplicationUserIdFileCreateInput = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["UUID"]["input"];
  isSeries: Scalars["Boolean"]["input"];
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  originalName: Scalars["String"]["input"];
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus: Scalars["Int"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status: Scalars["Int"]["input"];
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Input for the nested mutation of `applicationUser` in the `FileInput` mutation. */
export type FileUserId01D90B53FkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<FileUserId01D90B53FkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `file` in the `ApplicationUserInput` mutation. */
export type FileUserId01D90B53FkApplicationUserIdInverseInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FileFilePkeyConnect>>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FileUserId01D90B53FkApplicationUserIdFileCreateInput>
  >;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<FileFilePkeyDelete>>;
  /** Flag indicating whether all other `file` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<FileOnFileForFileUserId01D90B53FkApplicationUserIdUsingFilePkeyUpdate>
  >;
};

/** A connection to a list of `File` values. */
export type FilesConnection = {
  __typename: "FilesConnection";
  /** A list of edges which contains the `File` and cursor to aid in pagination. */
  edges: Array<FilesEdge>;
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `File` edge in the connection. */
export type FilesEdge = {
  __typename: "FilesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `File` at the end of the edge. */
  node: File;
};

/** Methods to use when ordering `File`. */
export enum FilesOrderBy {
  DatasetIdAsc = "DATASET_ID_ASC",
  DatasetIdDesc = "DATASET_ID_DESC",
  DateArchivedAsc = "DATE_ARCHIVED_ASC",
  DateArchivedDesc = "DATE_ARCHIVED_DESC",
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  DateDataDeletedAsc = "DATE_DATA_DELETED_ASC",
  DateDataDeletedDesc = "DATE_DATA_DELETED_DESC",
  DateDeletedAsc = "DATE_DELETED_ASC",
  DateDeletedDesc = "DATE_DELETED_DESC",
  FileFormatAsc = "FILE_FORMAT_ASC",
  FileFormatDesc = "FILE_FORMAT_DESC",
  FileStructureAsc = "FILE_STRUCTURE_ASC",
  FileStructureDesc = "FILE_STRUCTURE_DESC",
  FileTypeAsc = "FILE_TYPE_ASC",
  FileTypeDesc = "FILE_TYPE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsSeriesAsc = "IS_SERIES_ASC",
  IsSeriesDesc = "IS_SERIES_DESC",
  KeyAsc = "KEY_ASC",
  KeyDesc = "KEY_DESC",
  LocationAsc = "LOCATION_ASC",
  LocationDesc = "LOCATION_DESC",
  MultipartUploadIdAsc = "MULTIPART_UPLOAD_ID_ASC",
  MultipartUploadIdDesc = "MULTIPART_UPLOAD_ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OriginalNameAsc = "ORIGINAL_NAME_ASC",
  OriginalNameDesc = "ORIGINAL_NAME_DESC",
  ParentIdsAsc = "PARENT_IDS_ASC",
  ParentIdsDesc = "PARENT_IDS_DESC",
  PartSizeAsc = "PART_SIZE_ASC",
  PartSizeDesc = "PART_SIZE_DESC",
  PreviewAsc = "PREVIEW_ASC",
  PreviewDesc = "PREVIEW_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProcessingStatusAsc = "PROCESSING_STATUS_ASC",
  ProcessingStatusDesc = "PROCESSING_STATUS_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  SeriesOrderAsc = "SERIES_ORDER_ASC",
  SeriesOrderDesc = "SERIES_ORDER_DESC",
  SeriesParentIdAsc = "SERIES_PARENT_ID_ASC",
  SeriesParentIdDesc = "SERIES_PARENT_ID_DESC",
  SignalAsc = "SIGNAL_ASC",
  SignalDesc = "SIGNAL_DESC",
  SizeAsc = "SIZE_ASC",
  SizeDesc = "SIZE_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  UploadStatusAsc = "UPLOAD_STATUS_ASC",
  UploadStatusDesc = "UPLOAD_STATUS_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** A filter to be used against Float fields. All fields are combined with a logical ‘and.’ */
export type FloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Float"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Float"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Float"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Float"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Float"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Float"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Float"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Float"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Float"]["input"]>>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Int"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Int"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Int"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Int"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Int"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Int"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Int"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Int"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

/** An interval of time that has passed where the smallest distinct unit is a second. */
export type Interval = {
  __typename: "Interval";
  /** A quantity of days. */
  days: Maybe<Scalars["Int"]["output"]>;
  /** A quantity of hours. */
  hours: Maybe<Scalars["Int"]["output"]>;
  /** A quantity of minutes. */
  minutes: Maybe<Scalars["Int"]["output"]>;
  /** A quantity of months. */
  months: Maybe<Scalars["Int"]["output"]>;
  /**
   * A quantity of seconds. This is the only non-integer field, as all the other
   * fields will dump their overflow into a smaller unit of time. Intervals don’t
   * have a smaller unit than seconds.
   */
  seconds: Maybe<Scalars["Float"]["output"]>;
  /** A quantity of years. */
  years: Maybe<Scalars["Int"]["output"]>;
};

/** A filter to be used against Interval fields. All fields are combined with a logical ‘and.’ */
export type IntervalFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<IntervalInput>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<IntervalInput>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<IntervalInput>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<IntervalInput>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<IntervalInput>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<IntervalInput>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<IntervalInput>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<IntervalInput>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<IntervalInput>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<IntervalInput>>;
};

/** An interval of time that has passed where the smallest distinct unit is a second. */
export type IntervalInput = {
  /** A quantity of days. */
  days?: InputMaybe<Scalars["Int"]["input"]>;
  /** A quantity of hours. */
  hours?: InputMaybe<Scalars["Int"]["input"]>;
  /** A quantity of minutes. */
  minutes?: InputMaybe<Scalars["Int"]["input"]>;
  /** A quantity of months. */
  months?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   * A quantity of seconds. This is the only non-integer field, as all the other
   * fields will dump their overflow into a smaller unit of time. Intervals don’t
   * have a smaller unit than seconds.
   */
  seconds?: InputMaybe<Scalars["Float"]["input"]>;
  /** A quantity of years. */
  years?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  containedBy?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Contains the specified JSON. */
  contains?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Contains all of the specified keys. */
  containsAllKeys?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Contains the specified key. */
  containsKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["JSON"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["JSON"]["input"]>>;
};

/** A connection to a list of `Metadatum` values. */
export type MetadataConnection = {
  __typename: "MetadataConnection";
  /** A list of edges which contains the `Metadatum` and cursor to aid in pagination. */
  edges: Array<MetadataEdge>;
  /** A list of `Metadatum` objects. */
  nodes: Array<Metadatum>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Metadatum` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Metadatum` edge in the connection. */
export type MetadataEdge = {
  __typename: "MetadataEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Metadatum` at the end of the edge. */
  node: Metadatum;
};

/** Methods to use when ordering `Metadatum`. */
export enum MetadataOrderBy {
  DisplayNameAsc = "DISPLAY_NAME_ASC",
  DisplayNameDesc = "DISPLAY_NAME_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  KeyAsc = "KEY_ASC",
  KeyDesc = "KEY_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
}

/** Input for the nested mutation of `project` in the `MetadatumInput` mutation. */
export type MetadataProjectId3333C2AdFkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `metadatum` in the `ProjectInput` mutation. */
export type MetadataProjectId3333C2AdFkProjectIdInverseInput = {
  /** The primary key(s) for `metadatum` for the far side of the relationship. */
  connectById?: InputMaybe<Array<MetadatumMetadataV4PkeyConnect>>;
  /** A `MetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<MetadataProjectId3333C2AdFkProjectIdMetadataCreateInput>
  >;
  /** The primary key(s) for `metadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<MetadatumMetadataV4PkeyDelete>>;
  /** Flag indicating whether all other `metadatum` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `metadatum` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<MetadatumOnMetadatumForMetadataProjectId3333C2AdFkProjectIdUsingMetadataV4PkeyUpdate>
  >;
};

/** The `metadatum` to be created by this mutation. */
export type MetadataProjectId3333C2AdFkProjectIdMetadataCreateInput = {
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
  displayName: Scalars["String"]["input"];
  fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
  id: Scalars["UUID"]["input"];
  key: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
  projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
};

/** Input for the nested mutation of `tenant` in the `MetadatumInput` mutation. */
export type MetadataV4TenantId163B6569FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `metadatum` in the `TenantInput` mutation. */
export type MetadataV4TenantId163B6569FkTenantIdInverseInput = {
  /** The primary key(s) for `metadatum` for the far side of the relationship. */
  connectById?: InputMaybe<Array<MetadatumMetadataV4PkeyConnect>>;
  /** A `MetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<MetadataV4TenantId163B6569FkTenantIdMetadataCreateInput>
  >;
  /** The primary key(s) for `metadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<MetadatumMetadataV4PkeyDelete>>;
  /** Flag indicating whether all other `metadatum` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `metadatum` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<MetadatumOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingMetadataV4PkeyUpdate>
  >;
};

/** The `metadatum` to be created by this mutation. */
export type MetadataV4TenantId163B6569FkTenantIdMetadataCreateInput = {
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
  displayName: Scalars["String"]["input"];
  fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
  id: Scalars["UUID"]["input"];
  key: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
  tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
};

/** The `tenant` to be created by this mutation. */
export type MetadataV4TenantId163B6569FkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

export type MetadataValue = {
  __typename: "MetadataValue";
  /** Reads a single `ApplicationUser` that is related to this `MetadataValue`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  dateCreated: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  metadataId: Scalars["UUID"]["output"];
  /** Reads a single `Metadatum` that is related to this `MetadataValue`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `MetadataValue`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  /** Reads a single `Task` that is related to this `MetadataValue`. */
  taskByTaskId: Maybe<Task>;
  taskId: Maybe<Scalars["UUID"]["output"]>;
  /** Reads a single `Tenant` that is related to this `MetadataValue`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  userId: Maybe<Scalars["UUID"]["output"]>;
  value: Maybe<Scalars["JSON"]["output"]>;
};

/**
 * A condition to be used against `MetadataValue` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MetadataValueCondition = {
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `metadataId` field. */
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** A filter to be used against `MetadataValue` object types. All fields are combined with a logical ‘and.’ */
export type MetadataValueFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MetadataValueFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** A related `applicationUserByUserId` exists. */
  applicationUserByUserIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `metadataId` field. */
  metadataId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `metadatumByMetadataId` relation. */
  metadatumByMetadataId?: InputMaybe<MetadatumFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MetadataValueFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MetadataValueFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `taskByTaskId` relation. */
  taskByTaskId?: InputMaybe<TaskFilter>;
  /** A related `taskByTaskId` exists. */
  taskByTaskIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `taskId` field. */
  taskId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<JsonFilter>;
};

/** An input for mutations affecting `MetadataValue` */
export type MetadataValueInput = {
  applicationUserToUserId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id: Scalars["UUID"]["input"];
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  metadatumToMetadataId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInput>;
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  taskToTaskId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  value?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** Input for the nested mutation of `metadatum` in the `MetadataValueInput` mutation. */
export type MetadataValueMetadataId063A53B6FkMetadataV4IdInput = {
  /** The primary key(s) for `metadatum` for the far side of the relationship. */
  connectById?: InputMaybe<MetadatumMetadataV4PkeyConnect>;
  /** A `MetadatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdMetadataCreateInput>;
  /** The primary key(s) for `metadatum` for the far side of the relationship. */
  deleteById?: InputMaybe<MetadatumMetadataV4PkeyDelete>;
  /** The primary key(s) and patch data for `metadatum` for the far side of the relationship. */
  updateById?: InputMaybe<MetadatumOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdUsingMetadataV4PkeyUpdate>;
};

/** Input for the nested mutation of `metadataValue` in the `MetadatumInput` mutation. */
export type MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput = {
  /** The primary key(s) for `metadataValue` for the far side of the relationship. */
  connectById?: InputMaybe<Array<MetadataValueMetadataValuePkeyConnect>>;
  /** A `MetadataValueInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<MetadataValueMetadataId063A53B6FkMetadataV4IdMetadataValueCreateInput>
  >;
  /** The primary key(s) for `metadataValue` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<MetadataValueMetadataValuePkeyDelete>>;
  /** Flag indicating whether all other `metadataValue` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `metadataValue` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<MetadataValueOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdUsingMetadataValuePkeyUpdate>
  >;
};

/** The `metadatum` to be created by this mutation. */
export type MetadataValueMetadataId063A53B6FkMetadataV4IdMetadataCreateInput = {
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
  displayName: Scalars["String"]["input"];
  fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
  id: Scalars["UUID"]["input"];
  key: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
};

/** The `metadataValue` to be created by this mutation. */
export type MetadataValueMetadataId063A53B6FkMetadataV4IdMetadataValueCreateInput =
  {
    applicationUserToUserId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    metadatumToMetadataId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInput>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    value?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** The fields on `metadataValue` to look up the row to connect. */
export type MetadataValueMetadataValuePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `metadataValue` to look up the row to delete. */
export type MetadataValueMetadataValuePkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `metadataValue` to look up the row to update. */
export type MetadataValueOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdUsingMetadataValuePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `metadataValue` being updated. */
    metadataValuePatch: UpdateMetadataValueOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdPatch;
  };

/** The fields on `metadataValue` to look up the row to update. */
export type MetadataValueOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdUsingMetadataValuePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `metadataValue` being updated. */
    metadataValuePatch: UpdateMetadataValueOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdPatch;
  };

/** The fields on `metadataValue` to look up the row to update. */
export type MetadataValueOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdUsingMetadataValuePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `metadataValue` being updated. */
    metadataValuePatch: UpdateMetadataValueOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdPatch;
  };

/** The fields on `metadataValue` to look up the row to update. */
export type MetadataValueOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingMetadataValuePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `metadataValue` being updated. */
    metadataValuePatch: UpdateMetadataValueOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdPatch;
  };

/** The fields on `metadataValue` to look up the row to update. */
export type MetadataValueOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingMetadataValuePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `metadataValue` being updated. */
    metadataValuePatch: UpdateMetadataValueOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdPatch;
  };

/** Represents an update to a `MetadataValue`. Fields that are set will be updated. */
export type MetadataValuePatch = {
  applicationUserToUserId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  metadatumToMetadataId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInput>;
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  taskToTaskId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  value?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** Input for the nested mutation of `project` in the `MetadataValueInput` mutation. */
export type MetadataValueProjectId87227D96FkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `metadataValue` in the `ProjectInput` mutation. */
export type MetadataValueProjectId87227D96FkProjectIdInverseInput = {
  /** The primary key(s) for `metadataValue` for the far side of the relationship. */
  connectById?: InputMaybe<Array<MetadataValueMetadataValuePkeyConnect>>;
  /** A `MetadataValueInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<MetadataValueProjectId87227D96FkProjectIdMetadataValueCreateInput>
  >;
  /** The primary key(s) for `metadataValue` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<MetadataValueMetadataValuePkeyDelete>>;
  /** Flag indicating whether all other `metadataValue` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `metadataValue` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<MetadataValueOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdUsingMetadataValuePkeyUpdate>
  >;
};

/** The `metadataValue` to be created by this mutation. */
export type MetadataValueProjectId87227D96FkProjectIdMetadataValueCreateInput =
  {
    applicationUserToUserId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInput>;
    projectToProjectId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInput>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    value?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** Input for the nested mutation of `task` in the `MetadataValueInput` mutation. */
export type MetadataValueTaskId3D37D135FkTaskIdInput = {
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectByExecutorId?: InputMaybe<TaskTaskExecutorIdKeyConnect>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectById?: InputMaybe<TaskTaskPkeyConnect>;
  /** A `TaskInput` object that will be created and connected to this object. */
  create?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdTaskCreateInput>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteByExecutorId?: InputMaybe<TaskTaskExecutorIdKeyDelete>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteById?: InputMaybe<TaskTaskPkeyDelete>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateByExecutorId?: InputMaybe<TaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdUsingTaskExecutorIdKeyUpdate>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateById?: InputMaybe<TaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdUsingTaskPkeyUpdate>;
};

/** Input for the nested mutation of `metadataValue` in the `TaskInput` mutation. */
export type MetadataValueTaskId3D37D135FkTaskIdInverseInput = {
  /** The primary key(s) for `metadataValue` for the far side of the relationship. */
  connectById?: InputMaybe<Array<MetadataValueMetadataValuePkeyConnect>>;
  /** A `MetadataValueInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<MetadataValueTaskId3D37D135FkTaskIdMetadataValueCreateInput>
  >;
  /** The primary key(s) for `metadataValue` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<MetadataValueMetadataValuePkeyDelete>>;
  /** Flag indicating whether all other `metadataValue` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `metadataValue` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<MetadataValueOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdUsingMetadataValuePkeyUpdate>
  >;
};

/** The `metadataValue` to be created by this mutation. */
export type MetadataValueTaskId3D37D135FkTaskIdMetadataValueCreateInput = {
  applicationUserToUserId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id: Scalars["UUID"]["input"];
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  metadatumToMetadataId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInput>;
  taskToTaskId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  value?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** The `task` to be created by this mutation. */
export type MetadataValueTaskId3D37D135FkTaskIdTaskCreateInput = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned: Scalars["Boolean"]["input"];
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id: Scalars["UUID"]["input"];
  inputsHash: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status: Scalars["Int"]["input"];
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `tenant` in the `MetadataValueInput` mutation. */
export type MetadataValueTenantIdB4C2D5D6FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `metadataValue` in the `TenantInput` mutation. */
export type MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput = {
  /** The primary key(s) for `metadataValue` for the far side of the relationship. */
  connectById?: InputMaybe<Array<MetadataValueMetadataValuePkeyConnect>>;
  /** A `MetadataValueInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<MetadataValueTenantIdB4C2D5D6FkTenantIdMetadataValueCreateInput>
  >;
  /** The primary key(s) for `metadataValue` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<MetadataValueMetadataValuePkeyDelete>>;
  /** Flag indicating whether all other `metadataValue` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `metadataValue` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<MetadataValueOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingMetadataValuePkeyUpdate>
  >;
};

/** The `metadataValue` to be created by this mutation. */
export type MetadataValueTenantIdB4C2D5D6FkTenantIdMetadataValueCreateInput = {
  applicationUserToUserId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id: Scalars["UUID"]["input"];
  metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
  metadatumToMetadataId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInput>;
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  taskToTaskId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInput>;
  tenantToTenantId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  value?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** The `tenant` to be created by this mutation. */
export type MetadataValueTenantIdB4C2D5D6FkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** The `applicationUser` to be created by this mutation. */
export type MetadataValueUserIdB2Bb45AeFkApplicationUserIdApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `MetadataValueInput` mutation. */
export type MetadataValueUserIdB2Bb45AeFkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `metadataValue` in the `ApplicationUserInput` mutation. */
export type MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput = {
  /** The primary key(s) for `metadataValue` for the far side of the relationship. */
  connectById?: InputMaybe<Array<MetadataValueMetadataValuePkeyConnect>>;
  /** A `MetadataValueInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<MetadataValueUserIdB2Bb45AeFkApplicationUserIdMetadataValueCreateInput>
  >;
  /** The primary key(s) for `metadataValue` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<MetadataValueMetadataValuePkeyDelete>>;
  /** Flag indicating whether all other `metadataValue` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `metadataValue` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<MetadataValueOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingMetadataValuePkeyUpdate>
  >;
};

/** The `metadataValue` to be created by this mutation. */
export type MetadataValueUserIdB2Bb45AeFkApplicationUserIdMetadataValueCreateInput =
  {
    applicationUserToUserId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInput>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInput>;
    value?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** A connection to a list of `MetadataValue` values. */
export type MetadataValuesConnection = {
  __typename: "MetadataValuesConnection";
  /** A list of edges which contains the `MetadataValue` and cursor to aid in pagination. */
  edges: Array<MetadataValuesEdge>;
  /** A list of `MetadataValue` objects. */
  nodes: Array<MetadataValue>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MetadataValue` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `MetadataValue` edge in the connection. */
export type MetadataValuesEdge = {
  __typename: "MetadataValuesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `MetadataValue` at the end of the edge. */
  node: MetadataValue;
};

/** Methods to use when ordering `MetadataValue`. */
export enum MetadataValuesOrderBy {
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MetadataIdAsc = "METADATA_ID_ASC",
  MetadataIdDesc = "METADATA_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  TaskIdAsc = "TASK_ID_ASC",
  TaskIdDesc = "TASK_ID_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
  ValueAsc = "VALUE_ASC",
  ValueDesc = "VALUE_DESC",
}

export type Metadatum = {
  __typename: "Metadatum";
  activeValue: Maybe<Scalars["JSON"]["output"]>;
  /** Reads and enables pagination through a set of `DatasetMetadatum`. */
  datasetMetadataByMetadataId: DatasetMetadataConnection;
  /** Reads and enables pagination through a set of `DatasetRecordingsTableColumn`. */
  datasetRecordingsTableColumnsByColDefId: DatasetRecordingsTableColumnsConnection;
  /** Reads and enables pagination through a set of `Dataset`. */
  datasetsByDescriptionId: DatasetsConnection;
  displayName: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `FileMetadatum`. */
  fileMetadataByMetadataId: FileMetadataConnection;
  /** Reads and enables pagination through a set of `FileOutputGroupMetadatum`. */
  fileOutputGroupMetadataByMetadataId: FileOutputGroupMetadataConnection;
  /** Reads and enables pagination through a set of `FileRecordingGroupMetadatum`. */
  fileRecordingGroupMetadataByMetadataId: FileRecordingGroupMetadataConnection;
  id: Scalars["UUID"]["output"];
  key: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `MetadataValue`. */
  metadataValuesByMetadataId: MetadataValuesConnection;
  /** Reads a single `Project` that is related to this `Metadatum`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  /** Reads a single `Tenant` that is related to this `Metadatum`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
};

export type MetadatumActiveValueArgs = {
  at?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type MetadatumDatasetMetadataByMetadataIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetMetadatumCondition>;
  filter?: InputMaybe<DatasetMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetMetadataOrderBy>>;
};

export type MetadatumDatasetRecordingsTableColumnsByColDefIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetRecordingsTableColumnCondition>;
  filter?: InputMaybe<DatasetRecordingsTableColumnFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetRecordingsTableColumnsOrderBy>>;
};

export type MetadatumDatasetsByDescriptionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetCondition>;
  filter?: InputMaybe<DatasetFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetsOrderBy>>;
};

export type MetadatumFileMetadataByMetadataIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileMetadatumCondition>;
  filter?: InputMaybe<FileMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileMetadataOrderBy>>;
};

export type MetadatumFileOutputGroupMetadataByMetadataIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileOutputGroupMetadatumCondition>;
  filter?: InputMaybe<FileOutputGroupMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileOutputGroupMetadataOrderBy>>;
};

export type MetadatumFileRecordingGroupMetadataByMetadataIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileRecordingGroupMetadatumCondition>;
  filter?: InputMaybe<FileRecordingGroupMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileRecordingGroupMetadataOrderBy>>;
};

export type MetadatumMetadataValuesByMetadataIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MetadataValueCondition>;
  filter?: InputMaybe<MetadataValueFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MetadataValuesOrderBy>>;
};

/**
 * A condition to be used against `Metadatum` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MetadatumCondition = {
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `Metadatum` object types. All fields are combined with a logical ‘and.’ */
export type MetadatumFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MetadatumFilter>>;
  /** Filter by the object’s `datasetMetadataByMetadataId` relation. */
  datasetMetadataByMetadataId?: InputMaybe<MetadatumToManyDatasetMetadatumFilter>;
  /** Some related `datasetMetadataByMetadataId` exist. */
  datasetMetadataByMetadataIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `datasetRecordingsTableColumnsByColDefId` relation. */
  datasetRecordingsTableColumnsByColDefId?: InputMaybe<MetadatumToManyDatasetRecordingsTableColumnFilter>;
  /** Some related `datasetRecordingsTableColumnsByColDefId` exist. */
  datasetRecordingsTableColumnsByColDefIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `datasetsByDescriptionId` relation. */
  datasetsByDescriptionId?: InputMaybe<MetadatumToManyDatasetFilter>;
  /** Some related `datasetsByDescriptionId` exist. */
  datasetsByDescriptionIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `displayName` field. */
  displayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fileMetadataByMetadataId` relation. */
  fileMetadataByMetadataId?: InputMaybe<MetadatumToManyFileMetadatumFilter>;
  /** Some related `fileMetadataByMetadataId` exist. */
  fileMetadataByMetadataIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fileOutputGroupMetadataByMetadataId` relation. */
  fileOutputGroupMetadataByMetadataId?: InputMaybe<MetadatumToManyFileOutputGroupMetadatumFilter>;
  /** Some related `fileOutputGroupMetadataByMetadataId` exist. */
  fileOutputGroupMetadataByMetadataIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `fileRecordingGroupMetadataByMetadataId` relation. */
  fileRecordingGroupMetadataByMetadataId?: InputMaybe<MetadatumToManyFileRecordingGroupMetadatumFilter>;
  /** Some related `fileRecordingGroupMetadataByMetadataId` exist. */
  fileRecordingGroupMetadataByMetadataIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metadataValuesByMetadataId` relation. */
  metadataValuesByMetadataId?: InputMaybe<MetadatumToManyMetadataValueFilter>;
  /** Some related `metadataValuesByMetadataId` exist. */
  metadataValuesByMetadataIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Negates the expression. */
  not?: InputMaybe<MetadatumFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MetadatumFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Metadatum` */
export type MetadatumInput = {
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
  displayName: Scalars["String"]["input"];
  fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
  id: Scalars["UUID"]["input"];
  key: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
};

/** The fields on `metadatum` to look up the row to connect. */
export type MetadatumMetadataV4PkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `metadatum` to look up the row to delete. */
export type MetadatumMetadataV4PkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `metadatum` to look up the row to update. */
export type MetadatumOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingMetadataV4PkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `metadatum` being updated. */
    metadatumPatch: UpdateMetadatumOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdPatch;
  };

/** The fields on `metadatum` to look up the row to update. */
export type MetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdUsingMetadataV4PkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `metadatum` being updated. */
    metadatumPatch: UpdateMetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdPatch;
  };

/** The fields on `metadatum` to look up the row to update. */
export type MetadatumOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataUsingMetadataV4PkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `metadatum` being updated. */
    metadatumPatch: UpdateMetadatumOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataPatch;
  };

/** The fields on `metadatum` to look up the row to update. */
export type MetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdUsingMetadataV4PkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `metadatum` being updated. */
    metadatumPatch: UpdateMetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdPatch;
  };

/** The fields on `metadatum` to look up the row to update. */
export type MetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataUsingMetadataV4PkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `metadatum` being updated. */
    metadatumPatch: UpdateMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataPatch;
  };

/** The fields on `metadatum` to look up the row to update. */
export type MetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataUsingMetadataV4PkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `metadatum` being updated. */
    metadatumPatch: UpdateMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataPatch;
  };

/** The fields on `metadatum` to look up the row to update. */
export type MetadatumOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdUsingMetadataV4PkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `metadatum` being updated. */
    metadatumPatch: UpdateMetadatumOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdPatch;
  };

/** The fields on `metadatum` to look up the row to update. */
export type MetadatumOnMetadatumForMetadataProjectId3333C2AdFkProjectIdUsingMetadataV4PkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `metadatum` being updated. */
    metadatumPatch: UpdateMetadatumOnMetadatumForMetadataProjectId3333C2AdFkProjectIdPatch;
  };

/** The fields on `metadatum` to look up the row to update. */
export type MetadatumOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingMetadataV4PkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `metadatum` being updated. */
    metadatumPatch: UpdateMetadatumOnMetadatumForMetadataV4TenantId163B6569FkTenantIdPatch;
  };

/** Represents an update to a `Metadatum`. Fields that are set will be updated. */
export type MetadatumPatch = {
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
};

/** A filter to be used against many `Dataset` object types. All fields are combined with a logical ‘and.’ */
export type MetadatumToManyDatasetFilter = {
  /** Every related `Dataset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetFilter>;
  /** No related `Dataset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetFilter>;
  /** Some related `Dataset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetFilter>;
};

/** A filter to be used against many `DatasetMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type MetadatumToManyDatasetMetadatumFilter = {
  /** Every related `DatasetMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetMetadatumFilter>;
  /** No related `DatasetMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetMetadatumFilter>;
  /** Some related `DatasetMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetMetadatumFilter>;
};

/** A filter to be used against many `DatasetRecordingsTableColumn` object types. All fields are combined with a logical ‘and.’ */
export type MetadatumToManyDatasetRecordingsTableColumnFilter = {
  /** Every related `DatasetRecordingsTableColumn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetRecordingsTableColumnFilter>;
  /** No related `DatasetRecordingsTableColumn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetRecordingsTableColumnFilter>;
  /** Some related `DatasetRecordingsTableColumn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetRecordingsTableColumnFilter>;
};

/** A filter to be used against many `FileMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type MetadatumToManyFileMetadatumFilter = {
  /** Every related `FileMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileMetadatumFilter>;
  /** No related `FileMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileMetadatumFilter>;
  /** Some related `FileMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileMetadatumFilter>;
};

/** A filter to be used against many `FileOutputGroupMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type MetadatumToManyFileOutputGroupMetadatumFilter = {
  /** Every related `FileOutputGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileOutputGroupMetadatumFilter>;
  /** No related `FileOutputGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileOutputGroupMetadatumFilter>;
  /** Some related `FileOutputGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileOutputGroupMetadatumFilter>;
};

/** A filter to be used against many `FileRecordingGroupMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type MetadatumToManyFileRecordingGroupMetadatumFilter = {
  /** Every related `FileRecordingGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileRecordingGroupMetadatumFilter>;
  /** No related `FileRecordingGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileRecordingGroupMetadatumFilter>;
  /** Some related `FileRecordingGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileRecordingGroupMetadatumFilter>;
};

/** A filter to be used against many `MetadataValue` object types. All fields are combined with a logical ‘and.’ */
export type MetadatumToManyMetadataValueFilter = {
  /** Every related `MetadataValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MetadataValueFilter>;
  /** No related `MetadataValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MetadataValueFilter>;
  /** Some related `MetadataValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MetadataValueFilter>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename: "Mutation";
  changeUserSession: Maybe<ChangeUserSessionPayload>;
  /** Creates a single `AnalysisTable`. */
  createAnalysisTable: Maybe<CreateAnalysisTablePayload>;
  /** Creates a single `AnalysisTableGroup`. */
  createAnalysisTableGroup: Maybe<CreateAnalysisTableGroupPayload>;
  /** Creates a single `AnalysisTableRow`. */
  createAnalysisTableRow: Maybe<CreateAnalysisTableRowPayload>;
  /** Creates a single `AnalysisTableRowAttachResult`. */
  createAnalysisTableRowAttachResult: Maybe<CreateAnalysisTableRowAttachResultPayload>;
  /** Creates a single `ApplicationUser`. */
  createApplicationUser: Maybe<CreateApplicationUserPayload>;
  /** Creates a single `ApplicationUserGroup`. */
  createApplicationUserGroup: Maybe<CreateApplicationUserGroupPayload>;
  /** Creates a single `ApplicationUserMembership`. */
  createApplicationUserMembership: Maybe<CreateApplicationUserMembershipPayload>;
  /** Creates a single `ApplicationUserMetadatum`. */
  createApplicationUserMetadatum: Maybe<CreateApplicationUserMetadatumPayload>;
  /** Creates a single `ApplicationUserSession`. */
  createApplicationUserSession: Maybe<CreateApplicationUserSessionPayload>;
  /** Creates a single `ApplicationUserUserPermission`. */
  createApplicationUserUserPermission: Maybe<CreateApplicationUserUserPermissionPayload>;
  /** Creates a single `AuthGroup`. */
  createAuthGroup: Maybe<CreateAuthGroupPayload>;
  /** Creates a single `AuthGroupPermission`. */
  createAuthGroupPermission: Maybe<CreateAuthGroupPermissionPayload>;
  /** Creates a single `AuthPermission`. */
  createAuthPermission: Maybe<CreateAuthPermissionPayload>;
  /** Creates a single `AvailableNonRestrictedProject`. */
  createAvailableNonRestrictedProject: Maybe<CreateAvailableNonRestrictedProjectPayload>;
  /** Creates a single `Avatar`. */
  createAvatar: Maybe<CreateAvatarPayload>;
  /** Creates a single `AwsBatchTask`. */
  createAwsBatchTask: Maybe<CreateAwsBatchTaskPayload>;
  /** Creates a single `AwsCognitoUser`. */
  createAwsCognitoUser: Maybe<CreateAwsCognitoUserPayload>;
  /** Creates a single `AwsEcrImage`. */
  createAwsEcrImage: Maybe<CreateAwsEcrImagePayload>;
  /** Creates a single `AwsMockBatchTask`. */
  createAwsMockBatchTask: Maybe<CreateAwsMockBatchTaskPayload>;
  /** Creates a single `AwsMockEcrImage`. */
  createAwsMockEcrImage: Maybe<CreateAwsMockEcrImagePayload>;
  /** Creates a single `AwsMockS3File`. */
  createAwsMockS3File: Maybe<CreateAwsMockS3FilePayload>;
  /** Creates a single `AwsS3File`. */
  createAwsS3File: Maybe<CreateAwsS3FilePayload>;
  /** Creates a single `ContainerImage`. */
  createContainerImage: Maybe<CreateContainerImagePayload>;
  /** Creates a single `Cost`. */
  createCost: Maybe<CreateCostPayload>;
  /** Creates a single `CurrentApplicationUser`. */
  createCurrentApplicationUser: Maybe<CreateCurrentApplicationUserPayload>;
  /** Creates a single `CurrentUserMembership`. */
  createCurrentUserMembership: Maybe<CreateCurrentUserMembershipPayload>;
  /** Creates a single `Dataset`. */
  createDataset: Maybe<CreateDatasetPayload>;
  /** Creates a single `DatasetMetadatum`. */
  createDatasetMetadatum: Maybe<CreateDatasetMetadatumPayload>;
  /** Creates a single `DatasetRecordingsTable`. */
  createDatasetRecordingsTable: Maybe<CreateDatasetRecordingsTablePayload>;
  /** Creates a single `DatasetRecordingsTableColumn`. */
  createDatasetRecordingsTableColumn: Maybe<CreateDatasetRecordingsTableColumnPayload>;
  /** Creates a single `DatasetRecordingsTableColumnOrder`. */
  createDatasetRecordingsTableColumnOrder: Maybe<CreateDatasetRecordingsTableColumnOrderPayload>;
  /** Creates a single `DatasetVersion`. */
  createDatasetVersion: Maybe<CreateDatasetVersionPayload>;
  /** Creates a single `DjangoAdminLog`. */
  createDjangoAdminLog: Maybe<CreateDjangoAdminLogPayload>;
  /** Creates a single `DjangoContentType`. */
  createDjangoContentType: Maybe<CreateDjangoContentTypePayload>;
  /** Creates a single `DjangoMigration`. */
  createDjangoMigration: Maybe<CreateDjangoMigrationPayload>;
  /** Creates a single `DjangoSesSesstat`. */
  createDjangoSesSesstat: Maybe<CreateDjangoSesSesstatPayload>;
  /** Creates a single `DjangoSession`. */
  createDjangoSession: Maybe<CreateDjangoSessionPayload>;
  /** Creates a single `DrsFileexecutor`. */
  createDrsFileexecutor: Maybe<CreateDrsFileexecutorPayload>;
  /** Creates a single `DrsMockfileexecutor`. */
  createDrsMockfileexecutor: Maybe<CreateDrsMockfileexecutorPayload>;
  /** Creates a single `EulaVersion`. */
  createEulaVersion: Maybe<CreateEulaVersionPayload>;
  /** Creates a single `EulaVersionUserAcceptance`. */
  createEulaVersionUserAcceptance: Maybe<CreateEulaVersionUserAcceptancePayload>;
  /** Creates a single `ExplorerQuery`. */
  createExplorerQuery: Maybe<CreateExplorerQueryPayload>;
  /** Creates a single `ExplorerQueryfavorite`. */
  createExplorerQueryfavorite: Maybe<CreateExplorerQueryfavoritePayload>;
  /** Creates a single `ExplorerQuerylog`. */
  createExplorerQuerylog: Maybe<CreateExplorerQuerylogPayload>;
  /** Creates a single `File`. */
  createFile: Maybe<CreateFilePayload>;
  /** Creates a single `FileActivity`. */
  createFileActivity: Maybe<CreateFileActivityPayload>;
  /** Creates a single `FileMetadatum`. */
  createFileMetadatum: Maybe<CreateFileMetadatumPayload>;
  /** Creates a single `FileOutputGroupMetadatum`. */
  createFileOutputGroupMetadatum: Maybe<CreateFileOutputGroupMetadatumPayload>;
  /** Creates a single `FileRecordingGroup`. */
  createFileRecordingGroup: Maybe<CreateFileRecordingGroupPayload>;
  /** Creates a single `FileRecordingGroupMetadatum`. */
  createFileRecordingGroupMetadatum: Maybe<CreateFileRecordingGroupMetadatumPayload>;
  /** Creates a single `MetadataValue`. */
  createMetadataValue: Maybe<CreateMetadataValuePayload>;
  /** Creates a single `Metadatum`. */
  createMetadatum: Maybe<CreateMetadatumPayload>;
  /** Creates a single `OutputGroupFile`. */
  createOutputGroupFile: Maybe<CreateOutputGroupFilePayload>;
  /** Creates a single `RecordingGroupFile`. */
  createRecordingGroupFile: Maybe<CreateRecordingGroupFilePayload>;
  createRole: Maybe<CreateRolePayload>;
  /** Creates a single `Run`. */
  createRun: Maybe<CreateRunPayload>;
  createSession: Maybe<CreateSessionPayload>;
  /** Creates a single `Tag`. */
  createTag: Maybe<CreateTagPayload>;
  /** Creates a single `Task`. */
  createTask: Maybe<CreateTaskPayload>;
  /** Creates a single `TaskActivity`. */
  createTaskActivity: Maybe<CreateTaskActivityPayload>;
  /** Creates a single `TaskResource`. */
  createTaskResource: Maybe<CreateTaskResourcePayload>;
  /** Creates a single `TaskSource`. */
  createTaskSource: Maybe<CreateTaskSourcePayload>;
  /** Creates a single `TaskStatusLog`. */
  createTaskStatusLog: Maybe<CreateTaskStatusLogPayload>;
  /** Creates a single `Tenant`. */
  createTenant: Maybe<CreateTenantPayload>;
  /** Creates a single `TenantContract`. */
  createTenantContract: Maybe<CreateTenantContractPayload>;
  /** Creates a single `TenantContractAllowance`. */
  createTenantContractAllowance: Maybe<CreateTenantContractAllowancePayload>;
  /** Creates a single `TenantContractTransaction`. */
  createTenantContractTransaction: Maybe<CreateTenantContractTransactionPayload>;
  /** Creates a single `TenantMockuserexecutor`. */
  createTenantMockuserexecutor: Maybe<CreateTenantMockuserexecutorPayload>;
  /** Creates a single `TenantMonthlyConsumedResource`. */
  createTenantMonthlyConsumedResource: Maybe<CreateTenantMonthlyConsumedResourcePayload>;
  /** Creates a single `TenantUserexecutor`. */
  createTenantUserexecutor: Maybe<CreateTenantUserexecutorPayload>;
  /** Creates a single `TesContainerimageexecutor`. */
  createTesContainerimageexecutor: Maybe<CreateTesContainerimageexecutorPayload>;
  /** Creates a single `TesMockcontainerimageexecutor`. */
  createTesMockcontainerimageexecutor: Maybe<CreateTesMockcontainerimageexecutorPayload>;
  /** Creates a single `TesMocktaskexecutor`. */
  createTesMocktaskexecutor: Maybe<CreateTesMocktaskexecutorPayload>;
  /** Creates a single `TesTaskexecutor`. */
  createTesTaskexecutor: Maybe<CreateTesTaskexecutorPayload>;
  /** Creates a single `Tool`. */
  createTool: Maybe<CreateToolPayload>;
  /** Creates a single `ToolCategory`. */
  createToolCategory: Maybe<CreateToolCategoryPayload>;
  /** Creates a single `ToolDisplayCategory`. */
  createToolDisplayCategory: Maybe<CreateToolDisplayCategoryPayload>;
  /** Creates a single `ToolParameter`. */
  createToolParameter: Maybe<CreateToolParameterPayload>;
  /** Creates a single `ToolResource`. */
  createToolResource: Maybe<CreateToolResourcePayload>;
  /** Creates a single `ToolResult`. */
  createToolResult: Maybe<CreateToolResultPayload>;
  /** Creates a single `ToolSecret`. */
  createToolSecret: Maybe<CreateToolSecretPayload>;
  /** Creates a single `ToolSource`. */
  createToolSource: Maybe<CreateToolSourcePayload>;
  /** Creates a single `ToolTenant`. */
  createToolTenant: Maybe<CreateToolTenantPayload>;
  /** Creates a single `ToolVersion`. */
  createToolVersion: Maybe<CreateToolVersionPayload>;
  /** Creates a single `Toolbox`. */
  createToolbox: Maybe<CreateToolboxPayload>;
  /** Creates a single `ToolboxExitcode`. */
  createToolboxExitcode: Maybe<CreateToolboxExitcodePayload>;
  /** Creates a single `ToolboxTenant`. */
  createToolboxTenant: Maybe<CreateToolboxTenantPayload>;
  /** Creates a single `ToolboxVersion`. */
  createToolboxVersion: Maybe<CreateToolboxVersionPayload>;
  /** Creates a single `ToolboxVersionExitcode`. */
  createToolboxVersionExitcode: Maybe<CreateToolboxVersionExitcodePayload>;
  /** Creates a single `ToolboxVersionToolVersion`. */
  createToolboxVersionToolVersion: Maybe<CreateToolboxVersionToolVersionPayload>;
  /** Creates a single `Workflow`. */
  createWorkflow: Maybe<CreateWorkflowPayload>;
  /** Creates a single `WorkflowDatum`. */
  createWorkflowDatum: Maybe<CreateWorkflowDatumPayload>;
  /** Creates a single `WorkflowResult`. */
  createWorkflowResult: Maybe<CreateWorkflowResultPayload>;
  /** Creates a single `WorkflowResultTag`. */
  createWorkflowResultTag: Maybe<CreateWorkflowResultTagPayload>;
  /** Creates a single `WorkflowResultWorkflowVersion`. */
  createWorkflowResultWorkflowVersion: Maybe<CreateWorkflowResultWorkflowVersionPayload>;
  /** Creates a single `WorkflowSection`. */
  createWorkflowSection: Maybe<CreateWorkflowSectionPayload>;
  /** Creates a single `WorkflowSectionBlock`. */
  createWorkflowSectionBlock: Maybe<CreateWorkflowSectionBlockPayload>;
  /** Creates a single `WorkflowSectionBlockSection`. */
  createWorkflowSectionBlockSection: Maybe<CreateWorkflowSectionBlockSectionPayload>;
  /** Creates a single `WorkflowSectionWorkflowVersion`. */
  createWorkflowSectionWorkflowVersion: Maybe<CreateWorkflowSectionWorkflowVersionPayload>;
  /** Creates a single `WorkflowSource`. */
  createWorkflowSource: Maybe<CreateWorkflowSourcePayload>;
  /** Creates a single `WorkflowSourceTag`. */
  createWorkflowSourceTag: Maybe<CreateWorkflowSourceTagPayload>;
  /** Creates a single `WorkflowSourceWorkflowVersion`. */
  createWorkflowSourceWorkflowVersion: Maybe<CreateWorkflowSourceWorkflowVersionPayload>;
  /** Creates a single `WorkflowVersion`. */
  createWorkflowVersion: Maybe<CreateWorkflowVersionPayload>;
  /** Deletes a single `AnalysisTable` using a unique key. */
  deleteAnalysisTableById: Maybe<DeleteAnalysisTablePayload>;
  /** Deletes a single `AnalysisTableGroup` using a unique key. */
  deleteAnalysisTableGroupById: Maybe<DeleteAnalysisTableGroupPayload>;
  /** Deletes a single `AnalysisTableRowAttachResult` using a unique key. */
  deleteAnalysisTableRowAttachResultById: Maybe<DeleteAnalysisTableRowAttachResultPayload>;
  /** Deletes a single `AnalysisTableRow` using a unique key. */
  deleteAnalysisTableRowById: Maybe<DeleteAnalysisTableRowPayload>;
  /** Deletes a single `AnalysisTableRow` using a unique key. */
  deleteAnalysisTableRowByTaskId: Maybe<DeleteAnalysisTableRowPayload>;
  /** Deletes a single `ApplicationUser` using a unique key. */
  deleteApplicationUserByEmail: Maybe<DeleteApplicationUserPayload>;
  /** Deletes a single `ApplicationUser` using a unique key. */
  deleteApplicationUserById: Maybe<DeleteApplicationUserPayload>;
  /** Deletes a single `ApplicationUser` using a unique key. */
  deleteApplicationUserByUsername: Maybe<DeleteApplicationUserPayload>;
  /** Deletes a single `ApplicationUserGroup` using a unique key. */
  deleteApplicationUserGroupById: Maybe<DeleteApplicationUserGroupPayload>;
  /** Deletes a single `ApplicationUserGroup` using a unique key. */
  deleteApplicationUserGroupByUserIdAndGroupId: Maybe<DeleteApplicationUserGroupPayload>;
  /** Deletes a single `ApplicationUserMembership` using a unique key. */
  deleteApplicationUserMembershipById: Maybe<DeleteApplicationUserMembershipPayload>;
  /** Deletes a single `ApplicationUserMembership` using a unique key. */
  deleteApplicationUserMembershipByUserIdAndTenantId: Maybe<DeleteApplicationUserMembershipPayload>;
  /** Deletes a single `ApplicationUserMetadatum` using a unique key. */
  deleteApplicationUserMetadatumById: Maybe<DeleteApplicationUserMetadatumPayload>;
  /** Deletes a single `ApplicationUserMetadatum` using a unique key. */
  deleteApplicationUserMetadatumByUserIdAndKey: Maybe<DeleteApplicationUserMetadatumPayload>;
  /** Deletes a single `ApplicationUserSession` using a unique key. */
  deleteApplicationUserSessionById: Maybe<DeleteApplicationUserSessionPayload>;
  /** Deletes a single `ApplicationUserUserPermission` using a unique key. */
  deleteApplicationUserUserPermissionById: Maybe<DeleteApplicationUserUserPermissionPayload>;
  /** Deletes a single `ApplicationUserUserPermission` using a unique key. */
  deleteApplicationUserUserPermissionByUserIdAndPermissionId: Maybe<DeleteApplicationUserUserPermissionPayload>;
  /** Deletes a single `AuthGroup` using a unique key. */
  deleteAuthGroupById: Maybe<DeleteAuthGroupPayload>;
  /** Deletes a single `AuthGroup` using a unique key. */
  deleteAuthGroupByName: Maybe<DeleteAuthGroupPayload>;
  /** Deletes a single `AuthGroupPermission` using a unique key. */
  deleteAuthGroupPermissionByGroupIdAndPermissionId: Maybe<DeleteAuthGroupPermissionPayload>;
  /** Deletes a single `AuthGroupPermission` using a unique key. */
  deleteAuthGroupPermissionById: Maybe<DeleteAuthGroupPermissionPayload>;
  /** Deletes a single `AuthPermission` using a unique key. */
  deleteAuthPermissionByContentTypeIdAndCodename: Maybe<DeleteAuthPermissionPayload>;
  /** Deletes a single `AuthPermission` using a unique key. */
  deleteAuthPermissionById: Maybe<DeleteAuthPermissionPayload>;
  /** Deletes a single `Avatar` using a unique key. */
  deleteAvatarById: Maybe<DeleteAvatarPayload>;
  /** Deletes a single `Avatar` using a unique key. */
  deleteAvatarByUserId: Maybe<DeleteAvatarPayload>;
  /** Deletes a single `AwsBatchTask` using a unique key. */
  deleteAwsBatchTaskByTaskexecutorPtrId: Maybe<DeleteAwsBatchTaskPayload>;
  /** Deletes a single `AwsCognitoUser` using a unique key. */
  deleteAwsCognitoUserByUserexecutorPtrId: Maybe<DeleteAwsCognitoUserPayload>;
  /** Deletes a single `AwsEcrImage` using a unique key. */
  deleteAwsEcrImageByContainerimageexecutorPtrId: Maybe<DeleteAwsEcrImagePayload>;
  /** Deletes a single `AwsMockBatchTask` using a unique key. */
  deleteAwsMockBatchTaskByAwstaskexecutorPtrId: Maybe<DeleteAwsMockBatchTaskPayload>;
  /** Deletes a single `AwsMockEcrImage` using a unique key. */
  deleteAwsMockEcrImageByAwscontainerimageexecutorPtrId: Maybe<DeleteAwsMockEcrImagePayload>;
  /** Deletes a single `AwsMockS3File` using a unique key. */
  deleteAwsMockS3FileByAwsfileexecutorPtrId: Maybe<DeleteAwsMockS3FilePayload>;
  /** Deletes a single `AwsS3File` using a unique key. */
  deleteAwsS3FileByFileexecutorPtrId: Maybe<DeleteAwsS3FilePayload>;
  /** Deletes a single `ContainerImage` using a unique key. */
  deleteContainerImageByExecutorId: Maybe<DeleteContainerImagePayload>;
  /** Deletes a single `ContainerImage` using a unique key. */
  deleteContainerImageById: Maybe<DeleteContainerImagePayload>;
  /** Deletes a single `ContainerImage` using a unique key. */
  deleteContainerImageByNameAndTag: Maybe<DeleteContainerImagePayload>;
  /** Deletes a single `ContainerImage` using a unique key. */
  deleteContainerImageByRepositoryAndTag: Maybe<DeleteContainerImagePayload>;
  /** Deletes a single `ContainerImage` using a unique key. */
  deleteContainerImageByToolboxVersionId: Maybe<DeleteContainerImagePayload>;
  /** Deletes a single `Cost` using a unique key. */
  deleteCostById: Maybe<DeleteCostPayload>;
  /** Deletes a single `Dataset` using a unique key. */
  deleteDatasetById: Maybe<DeleteDatasetPayload>;
  /** Deletes a single `Dataset` using a unique key. */
  deleteDatasetByProjectIdAndPrefix: Maybe<DeleteDatasetPayload>;
  /** Deletes a single `Dataset` using a unique key. */
  deleteDatasetByRecordingsTableId: Maybe<DeleteDatasetPayload>;
  /** Deletes a single `Dataset` using a unique key. */
  deleteDatasetByTenantIdAndKey: Maybe<DeleteDatasetPayload>;
  /** Deletes a single `DatasetMetadatum` using a unique key. */
  deleteDatasetMetadatumById: Maybe<DeleteDatasetMetadatumPayload>;
  /** Deletes a single `DatasetRecordingsTable` using a unique key. */
  deleteDatasetRecordingsTableById: Maybe<DeleteDatasetRecordingsTablePayload>;
  /** Deletes a single `DatasetRecordingsTableColumn` using a unique key. */
  deleteDatasetRecordingsTableColumnById: Maybe<DeleteDatasetRecordingsTableColumnPayload>;
  /** Deletes a single `DatasetRecordingsTableColumnOrder` using a unique key. */
  deleteDatasetRecordingsTableColumnOrderById: Maybe<DeleteDatasetRecordingsTableColumnOrderPayload>;
  /** Deletes a single `DatasetVersion` using a unique key. */
  deleteDatasetVersionById: Maybe<DeleteDatasetVersionPayload>;
  /** Deletes a single `DjangoAdminLog` using a unique key. */
  deleteDjangoAdminLogById: Maybe<DeleteDjangoAdminLogPayload>;
  /** Deletes a single `DjangoContentType` using a unique key. */
  deleteDjangoContentTypeByAppLabelAndModel: Maybe<DeleteDjangoContentTypePayload>;
  /** Deletes a single `DjangoContentType` using a unique key. */
  deleteDjangoContentTypeById: Maybe<DeleteDjangoContentTypePayload>;
  /** Deletes a single `DjangoMigration` using a unique key. */
  deleteDjangoMigrationById: Maybe<DeleteDjangoMigrationPayload>;
  /** Deletes a single `DjangoSesSesstat` using a unique key. */
  deleteDjangoSesSesstatByDate: Maybe<DeleteDjangoSesSesstatPayload>;
  /** Deletes a single `DjangoSesSesstat` using a unique key. */
  deleteDjangoSesSesstatById: Maybe<DeleteDjangoSesSesstatPayload>;
  /** Deletes a single `DjangoSession` using a unique key. */
  deleteDjangoSessionBySessionKey: Maybe<DeleteDjangoSessionPayload>;
  /** Deletes a single `DrsFileexecutor` using a unique key. */
  deleteDrsFileexecutorById: Maybe<DeleteDrsFileexecutorPayload>;
  /** Deletes a single `DrsMockfileexecutor` using a unique key. */
  deleteDrsMockfileexecutorByFileexecutorPtrId: Maybe<DeleteDrsMockfileexecutorPayload>;
  /** Deletes a single `EulaVersion` using a unique key. */
  deleteEulaVersionById: Maybe<DeleteEulaVersionPayload>;
  /** Deletes a single `EulaVersion` using a unique key. */
  deleteEulaVersionByVersion: Maybe<DeleteEulaVersionPayload>;
  /** Deletes a single `EulaVersionUserAcceptance` using a unique key. */
  deleteEulaVersionUserAcceptanceById: Maybe<DeleteEulaVersionUserAcceptancePayload>;
  /** Deletes a single `EulaVersionUserAcceptance` using a unique key. */
  deleteEulaVersionUserAcceptanceByUserIdAndEulaVersionId: Maybe<DeleteEulaVersionUserAcceptancePayload>;
  /** Deletes a single `ExplorerQuery` using a unique key. */
  deleteExplorerQueryById: Maybe<DeleteExplorerQueryPayload>;
  /** Deletes a single `ExplorerQueryfavorite` using a unique key. */
  deleteExplorerQueryfavoriteById: Maybe<DeleteExplorerQueryfavoritePayload>;
  /** Deletes a single `ExplorerQueryfavorite` using a unique key. */
  deleteExplorerQueryfavoriteByQueryIdAndUserId: Maybe<DeleteExplorerQueryfavoritePayload>;
  /** Deletes a single `ExplorerQuerylog` using a unique key. */
  deleteExplorerQuerylogById: Maybe<DeleteExplorerQuerylogPayload>;
  /** Deletes a single `FileActivity` using a unique key. */
  deleteFileActivityById: Maybe<DeleteFileActivityPayload>;
  /** Deletes a single `File` using a unique key. */
  deleteFileById: Maybe<DeleteFilePayload>;
  /** Deletes a single `FileMetadatum` using a unique key. */
  deleteFileMetadatumById: Maybe<DeleteFileMetadatumPayload>;
  /** Deletes a single `FileOutputGroup` using a unique key. */
  deleteFileOutputGroupById: Maybe<DeleteFileOutputGroupPayload>;
  /** Deletes a single `FileOutputGroup` using a unique key. */
  deleteFileOutputGroupByTaskId: Maybe<DeleteFileOutputGroupPayload>;
  /** Deletes a single `FileOutputGroupMetadatum` using a unique key. */
  deleteFileOutputGroupMetadatumById: Maybe<DeleteFileOutputGroupMetadatumPayload>;
  /** Deletes a single `FileRecordingGroup` using a unique key. */
  deleteFileRecordingGroupById: Maybe<DeleteFileRecordingGroupPayload>;
  /** Deletes a single `FileRecordingGroupMetadatum` using a unique key. */
  deleteFileRecordingGroupMetadatumById: Maybe<DeleteFileRecordingGroupMetadatumPayload>;
  /** Deletes a single `MetadataValue` using a unique key. */
  deleteMetadataValueById: Maybe<DeleteMetadataValuePayload>;
  /** Deletes a single `Metadatum` using a unique key. */
  deleteMetadatumById: Maybe<DeleteMetadatumPayload>;
  /** Deletes a single `OutputGroupFile` using a unique key. */
  deleteOutputGroupFileById: Maybe<DeleteOutputGroupFilePayload>;
  /** Deletes a single `RecordingGroupFile` using a unique key. */
  deleteRecordingGroupFileById: Maybe<DeleteRecordingGroupFilePayload>;
  /** Deletes a single `Run` using a unique key. */
  deleteRunById: Maybe<DeleteRunPayload>;
  deleteSession: Maybe<DeleteSessionPayload>;
  /** Deletes a single `Tag` using a unique key. */
  deleteTagById: Maybe<DeleteTagPayload>;
  /** Deletes a single `TaskActivity` using a unique key. */
  deleteTaskActivityById: Maybe<DeleteTaskActivityPayload>;
  /** Deletes a single `TaskActivity` using a unique key. */
  deleteTaskActivityByTaskId: Maybe<DeleteTaskActivityPayload>;
  /** Deletes a single `Task` using a unique key. */
  deleteTaskByExecutorId: Maybe<DeleteTaskPayload>;
  /** Deletes a single `Task` using a unique key. */
  deleteTaskById: Maybe<DeleteTaskPayload>;
  /** Deletes a single `TaskResource` using a unique key. */
  deleteTaskResourceById: Maybe<DeleteTaskResourcePayload>;
  /** Deletes a single `TaskSource` using a unique key. */
  deleteTaskSourceById: Maybe<DeleteTaskSourcePayload>;
  /** Deletes a single `TaskStatusLog` using a unique key. */
  deleteTaskStatusLogById: Maybe<DeleteTaskStatusLogPayload>;
  /** Deletes a single `TaskStatusLog` using a unique key. */
  deleteTaskStatusLogByTaskIdAndStatus: Maybe<DeleteTaskStatusLogPayload>;
  /** Deletes a single `Tenant` using a unique key. */
  deleteTenantById: Maybe<DeleteTenantPayload>;
  /** Deletes a single `Tenant` using a unique key. */
  deleteTenantByKey: Maybe<DeleteTenantPayload>;
  /** Deletes a single `Tenant` using a unique key. */
  deleteTenantByName: Maybe<DeleteTenantPayload>;
  /** Deletes a single `TenantContractAllowance` using a unique key. */
  deleteTenantContractAllowanceById: Maybe<DeleteTenantContractAllowancePayload>;
  /** Deletes a single `TenantContractAllowance` using a unique key. */
  deleteTenantContractAllowanceByTenantContractTransactionIdAndQuotaType: Maybe<DeleteTenantContractAllowancePayload>;
  /** Deletes a single `TenantContract` using a unique key. */
  deleteTenantContractById: Maybe<DeleteTenantContractPayload>;
  /** Deletes a single `TenantContract` using a unique key. */
  deleteTenantContractByName: Maybe<DeleteTenantContractPayload>;
  /** Deletes a single `TenantContractTransaction` using a unique key. */
  deleteTenantContractTransactionById: Maybe<DeleteTenantContractTransactionPayload>;
  /** Deletes a single `TenantMockuserexecutor` using a unique key. */
  deleteTenantMockuserexecutorByUserexecutorPtrId: Maybe<DeleteTenantMockuserexecutorPayload>;
  /** Deletes a single `TenantMonthlyConsumedResource` using a unique key. */
  deleteTenantMonthlyConsumedResourceById: Maybe<DeleteTenantMonthlyConsumedResourcePayload>;
  /** Deletes a single `TenantMonthlyConsumedResource` using a unique key. */
  deleteTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract: Maybe<DeleteTenantMonthlyConsumedResourcePayload>;
  /** Deletes a single `TenantUserexecutor` using a unique key. */
  deleteTenantUserexecutorById: Maybe<DeleteTenantUserexecutorPayload>;
  /** Deletes a single `TesContainerimageexecutor` using a unique key. */
  deleteTesContainerimageexecutorById: Maybe<DeleteTesContainerimageexecutorPayload>;
  /** Deletes a single `TesMockcontainerimageexecutor` using a unique key. */
  deleteTesMockcontainerimageexecutorByContainerimageexecutorPtrId: Maybe<DeleteTesMockcontainerimageexecutorPayload>;
  /** Deletes a single `TesMocktaskexecutor` using a unique key. */
  deleteTesMocktaskexecutorByTaskexecutorPtrId: Maybe<DeleteTesMocktaskexecutorPayload>;
  /** Deletes a single `TesTaskexecutor` using a unique key. */
  deleteTesTaskexecutorById: Maybe<DeleteTesTaskexecutorPayload>;
  /** Deletes a single `Tool` using a unique key. */
  deleteToolById: Maybe<DeleteToolPayload>;
  /** Deletes a single `Tool` using a unique key. */
  deleteToolByKey: Maybe<DeleteToolPayload>;
  /** Deletes a single `ToolCategory` using a unique key. */
  deleteToolCategoryById: Maybe<DeleteToolCategoryPayload>;
  /** Deletes a single `ToolCategory` using a unique key. */
  deleteToolCategoryByKey: Maybe<DeleteToolCategoryPayload>;
  /** Deletes a single `ToolDisplayCategory` using a unique key. */
  deleteToolDisplayCategoryById: Maybe<DeleteToolDisplayCategoryPayload>;
  /** Deletes a single `ToolDisplayCategory` using a unique key. */
  deleteToolDisplayCategoryByToolIdAndToolcategoryId: Maybe<DeleteToolDisplayCategoryPayload>;
  /** Deletes a single `ToolParameter` using a unique key. */
  deleteToolParameterById: Maybe<DeleteToolParameterPayload>;
  /** Deletes a single `ToolResource` using a unique key. */
  deleteToolResourceById: Maybe<DeleteToolResourcePayload>;
  /** Deletes a single `ToolResource` using a unique key. */
  deleteToolResourceByTypeAndToolVersionId: Maybe<DeleteToolResourcePayload>;
  /** Deletes a single `ToolResult` using a unique key. */
  deleteToolResultById: Maybe<DeleteToolResultPayload>;
  /** Deletes a single `ToolSecret` using a unique key. */
  deleteToolSecretById: Maybe<DeleteToolSecretPayload>;
  /** Deletes a single `ToolSource` using a unique key. */
  deleteToolSourceById: Maybe<DeleteToolSourcePayload>;
  /** Deletes a single `ToolTenant` using a unique key. */
  deleteToolTenantById: Maybe<DeleteToolTenantPayload>;
  /** Deletes a single `ToolTenant` using a unique key. */
  deleteToolTenantByToolIdAndTenantId: Maybe<DeleteToolTenantPayload>;
  /** Deletes a single `ToolVersion` using a unique key. */
  deleteToolVersionById: Maybe<DeleteToolVersionPayload>;
  /** Deletes a single `ToolVersion` using a unique key. */
  deleteToolVersionByToolIdAndVersion: Maybe<DeleteToolVersionPayload>;
  /** Deletes a single `Toolbox` using a unique key. */
  deleteToolboxById: Maybe<DeleteToolboxPayload>;
  /** Deletes a single `Toolbox` using a unique key. */
  deleteToolboxByKey: Maybe<DeleteToolboxPayload>;
  /** Deletes a single `ToolboxExitcode` using a unique key. */
  deleteToolboxExitcodeByCode: Maybe<DeleteToolboxExitcodePayload>;
  /** Deletes a single `ToolboxExitcode` using a unique key. */
  deleteToolboxExitcodeById: Maybe<DeleteToolboxExitcodePayload>;
  /** Deletes a single `ToolboxTenant` using a unique key. */
  deleteToolboxTenantById: Maybe<DeleteToolboxTenantPayload>;
  /** Deletes a single `ToolboxTenant` using a unique key. */
  deleteToolboxTenantByToolboxIdAndTenantId: Maybe<DeleteToolboxTenantPayload>;
  /** Deletes a single `ToolboxVersion` using a unique key. */
  deleteToolboxVersionById: Maybe<DeleteToolboxVersionPayload>;
  /** Deletes a single `ToolboxVersion` using a unique key. */
  deleteToolboxVersionByToolboxIdAndVersion: Maybe<DeleteToolboxVersionPayload>;
  /** Deletes a single `ToolboxVersionExitcode` using a unique key. */
  deleteToolboxVersionExitcodeById: Maybe<DeleteToolboxVersionExitcodePayload>;
  /** Deletes a single `ToolboxVersionExitcode` using a unique key. */
  deleteToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeId: Maybe<DeleteToolboxVersionExitcodePayload>;
  /** Deletes a single `ToolboxVersionToolVersion` using a unique key. */
  deleteToolboxVersionToolVersionById: Maybe<DeleteToolboxVersionToolVersionPayload>;
  /** Deletes a single `ToolboxVersionToolVersion` using a unique key. */
  deleteToolboxVersionToolVersionByToolboxversionIdAndToolversionId: Maybe<DeleteToolboxVersionToolVersionPayload>;
  /** Deletes a single `Workflow` using a unique key. */
  deleteWorkflowById: Maybe<DeleteWorkflowPayload>;
  /** Deletes a single `WorkflowDatum` using a unique key. */
  deleteWorkflowDatumById: Maybe<DeleteWorkflowDatumPayload>;
  /** Deletes a single `WorkflowResult` using a unique key. */
  deleteWorkflowResultById: Maybe<DeleteWorkflowResultPayload>;
  /** Deletes a single `WorkflowResultTag` using a unique key. */
  deleteWorkflowResultTagById: Maybe<DeleteWorkflowResultTagPayload>;
  /** Deletes a single `WorkflowResultTag` using a unique key. */
  deleteWorkflowResultTagByResultIdAndTagId: Maybe<DeleteWorkflowResultTagPayload>;
  /** Deletes a single `WorkflowResultWorkflowVersion` using a unique key. */
  deleteWorkflowResultWorkflowVersionById: Maybe<DeleteWorkflowResultWorkflowVersionPayload>;
  /** Deletes a single `WorkflowResultWorkflowVersion` using a unique key. */
  deleteWorkflowResultWorkflowVersionByResultIdAndWorkflowversionId: Maybe<DeleteWorkflowResultWorkflowVersionPayload>;
  /** Deletes a single `WorkflowSectionBlock` using a unique key. */
  deleteWorkflowSectionBlockById: Maybe<DeleteWorkflowSectionBlockPayload>;
  /** Deletes a single `WorkflowSectionBlockSection` using a unique key. */
  deleteWorkflowSectionBlockSectionByBlockIdAndSectionId: Maybe<DeleteWorkflowSectionBlockSectionPayload>;
  /** Deletes a single `WorkflowSectionBlockSection` using a unique key. */
  deleteWorkflowSectionBlockSectionById: Maybe<DeleteWorkflowSectionBlockSectionPayload>;
  /** Deletes a single `WorkflowSection` using a unique key. */
  deleteWorkflowSectionById: Maybe<DeleteWorkflowSectionPayload>;
  /** Deletes a single `WorkflowSectionWorkflowVersion` using a unique key. */
  deleteWorkflowSectionWorkflowVersionById: Maybe<DeleteWorkflowSectionWorkflowVersionPayload>;
  /** Deletes a single `WorkflowSectionWorkflowVersion` using a unique key. */
  deleteWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionId: Maybe<DeleteWorkflowSectionWorkflowVersionPayload>;
  /** Deletes a single `WorkflowSource` using a unique key. */
  deleteWorkflowSourceById: Maybe<DeleteWorkflowSourcePayload>;
  /** Deletes a single `WorkflowSourceTag` using a unique key. */
  deleteWorkflowSourceTagById: Maybe<DeleteWorkflowSourceTagPayload>;
  /** Deletes a single `WorkflowSourceTag` using a unique key. */
  deleteWorkflowSourceTagBySourceIdAndTagId: Maybe<DeleteWorkflowSourceTagPayload>;
  /** Deletes a single `WorkflowSourceWorkflowVersion` using a unique key. */
  deleteWorkflowSourceWorkflowVersionById: Maybe<DeleteWorkflowSourceWorkflowVersionPayload>;
  /** Deletes a single `WorkflowSourceWorkflowVersion` using a unique key. */
  deleteWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionId: Maybe<DeleteWorkflowSourceWorkflowVersionPayload>;
  /** Deletes a single `WorkflowVersion` using a unique key. */
  deleteWorkflowVersionById: Maybe<DeleteWorkflowVersionPayload>;
  /** Updates a single `AnalysisTable` using a unique key and a patch. */
  updateAnalysisTableById: Maybe<UpdateAnalysisTablePayload>;
  /** Updates a single `AnalysisTableGroup` using a unique key and a patch. */
  updateAnalysisTableGroupById: Maybe<UpdateAnalysisTableGroupPayload>;
  /** Updates a single `AnalysisTableRowAttachResult` using a unique key and a patch. */
  updateAnalysisTableRowAttachResultById: Maybe<UpdateAnalysisTableRowAttachResultPayload>;
  /** Updates a single `AnalysisTableRow` using a unique key and a patch. */
  updateAnalysisTableRowById: Maybe<UpdateAnalysisTableRowPayload>;
  /** Updates a single `AnalysisTableRow` using a unique key and a patch. */
  updateAnalysisTableRowByTaskId: Maybe<UpdateAnalysisTableRowPayload>;
  /** Updates a single `ApplicationUser` using a unique key and a patch. */
  updateApplicationUserByEmail: Maybe<UpdateApplicationUserPayload>;
  /** Updates a single `ApplicationUser` using a unique key and a patch. */
  updateApplicationUserById: Maybe<UpdateApplicationUserPayload>;
  /** Updates a single `ApplicationUser` using a unique key and a patch. */
  updateApplicationUserByUsername: Maybe<UpdateApplicationUserPayload>;
  /** Updates a single `ApplicationUserGroup` using a unique key and a patch. */
  updateApplicationUserGroupById: Maybe<UpdateApplicationUserGroupPayload>;
  /** Updates a single `ApplicationUserGroup` using a unique key and a patch. */
  updateApplicationUserGroupByUserIdAndGroupId: Maybe<UpdateApplicationUserGroupPayload>;
  /** Updates a single `ApplicationUserMembership` using a unique key and a patch. */
  updateApplicationUserMembershipById: Maybe<UpdateApplicationUserMembershipPayload>;
  /** Updates a single `ApplicationUserMembership` using a unique key and a patch. */
  updateApplicationUserMembershipByUserIdAndTenantId: Maybe<UpdateApplicationUserMembershipPayload>;
  /** Updates a single `ApplicationUserMetadatum` using a unique key and a patch. */
  updateApplicationUserMetadatumById: Maybe<UpdateApplicationUserMetadatumPayload>;
  /** Updates a single `ApplicationUserMetadatum` using a unique key and a patch. */
  updateApplicationUserMetadatumByUserIdAndKey: Maybe<UpdateApplicationUserMetadatumPayload>;
  /** Updates a single `ApplicationUserSession` using a unique key and a patch. */
  updateApplicationUserSessionById: Maybe<UpdateApplicationUserSessionPayload>;
  /** Updates a single `ApplicationUserUserPermission` using a unique key and a patch. */
  updateApplicationUserUserPermissionById: Maybe<UpdateApplicationUserUserPermissionPayload>;
  /** Updates a single `ApplicationUserUserPermission` using a unique key and a patch. */
  updateApplicationUserUserPermissionByUserIdAndPermissionId: Maybe<UpdateApplicationUserUserPermissionPayload>;
  /** Updates a single `AuthGroup` using a unique key and a patch. */
  updateAuthGroupById: Maybe<UpdateAuthGroupPayload>;
  /** Updates a single `AuthGroup` using a unique key and a patch. */
  updateAuthGroupByName: Maybe<UpdateAuthGroupPayload>;
  /** Updates a single `AuthGroupPermission` using a unique key and a patch. */
  updateAuthGroupPermissionByGroupIdAndPermissionId: Maybe<UpdateAuthGroupPermissionPayload>;
  /** Updates a single `AuthGroupPermission` using a unique key and a patch. */
  updateAuthGroupPermissionById: Maybe<UpdateAuthGroupPermissionPayload>;
  /** Updates a single `AuthPermission` using a unique key and a patch. */
  updateAuthPermissionByContentTypeIdAndCodename: Maybe<UpdateAuthPermissionPayload>;
  /** Updates a single `AuthPermission` using a unique key and a patch. */
  updateAuthPermissionById: Maybe<UpdateAuthPermissionPayload>;
  /** Updates a single `Avatar` using a unique key and a patch. */
  updateAvatarById: Maybe<UpdateAvatarPayload>;
  /** Updates a single `Avatar` using a unique key and a patch. */
  updateAvatarByUserId: Maybe<UpdateAvatarPayload>;
  /** Updates a single `AwsBatchTask` using a unique key and a patch. */
  updateAwsBatchTaskByTaskexecutorPtrId: Maybe<UpdateAwsBatchTaskPayload>;
  /** Updates a single `AwsCognitoUser` using a unique key and a patch. */
  updateAwsCognitoUserByUserexecutorPtrId: Maybe<UpdateAwsCognitoUserPayload>;
  /** Updates a single `AwsEcrImage` using a unique key and a patch. */
  updateAwsEcrImageByContainerimageexecutorPtrId: Maybe<UpdateAwsEcrImagePayload>;
  /** Updates a single `AwsMockBatchTask` using a unique key and a patch. */
  updateAwsMockBatchTaskByAwstaskexecutorPtrId: Maybe<UpdateAwsMockBatchTaskPayload>;
  /** Updates a single `AwsMockEcrImage` using a unique key and a patch. */
  updateAwsMockEcrImageByAwscontainerimageexecutorPtrId: Maybe<UpdateAwsMockEcrImagePayload>;
  /** Updates a single `AwsMockS3File` using a unique key and a patch. */
  updateAwsMockS3FileByAwsfileexecutorPtrId: Maybe<UpdateAwsMockS3FilePayload>;
  /** Updates a single `AwsS3File` using a unique key and a patch. */
  updateAwsS3FileByFileexecutorPtrId: Maybe<UpdateAwsS3FilePayload>;
  /** Updates a single `ContainerImage` using a unique key and a patch. */
  updateContainerImageByExecutorId: Maybe<UpdateContainerImagePayload>;
  /** Updates a single `ContainerImage` using a unique key and a patch. */
  updateContainerImageById: Maybe<UpdateContainerImagePayload>;
  /** Updates a single `ContainerImage` using a unique key and a patch. */
  updateContainerImageByNameAndTag: Maybe<UpdateContainerImagePayload>;
  /** Updates a single `ContainerImage` using a unique key and a patch. */
  updateContainerImageByRepositoryAndTag: Maybe<UpdateContainerImagePayload>;
  /** Updates a single `ContainerImage` using a unique key and a patch. */
  updateContainerImageByToolboxVersionId: Maybe<UpdateContainerImagePayload>;
  /** Updates a single `Cost` using a unique key and a patch. */
  updateCostById: Maybe<UpdateCostPayload>;
  /** Updates a single `Dataset` using a unique key and a patch. */
  updateDatasetById: Maybe<UpdateDatasetPayload>;
  /** Updates a single `Dataset` using a unique key and a patch. */
  updateDatasetByProjectIdAndPrefix: Maybe<UpdateDatasetPayload>;
  /** Updates a single `Dataset` using a unique key and a patch. */
  updateDatasetByRecordingsTableId: Maybe<UpdateDatasetPayload>;
  /** Updates a single `Dataset` using a unique key and a patch. */
  updateDatasetByTenantIdAndKey: Maybe<UpdateDatasetPayload>;
  /** Updates a single `DatasetMetadatum` using a unique key and a patch. */
  updateDatasetMetadatumById: Maybe<UpdateDatasetMetadatumPayload>;
  /** Updates a single `DatasetRecordingsTable` using a unique key and a patch. */
  updateDatasetRecordingsTableById: Maybe<UpdateDatasetRecordingsTablePayload>;
  /** Updates a single `DatasetRecordingsTableColumn` using a unique key and a patch. */
  updateDatasetRecordingsTableColumnById: Maybe<UpdateDatasetRecordingsTableColumnPayload>;
  /** Updates a single `DatasetRecordingsTableColumnOrder` using a unique key and a patch. */
  updateDatasetRecordingsTableColumnOrderById: Maybe<UpdateDatasetRecordingsTableColumnOrderPayload>;
  /** Updates a single `DatasetVersion` using a unique key and a patch. */
  updateDatasetVersionById: Maybe<UpdateDatasetVersionPayload>;
  /** Updates a single `DjangoAdminLog` using a unique key and a patch. */
  updateDjangoAdminLogById: Maybe<UpdateDjangoAdminLogPayload>;
  /** Updates a single `DjangoContentType` using a unique key and a patch. */
  updateDjangoContentTypeByAppLabelAndModel: Maybe<UpdateDjangoContentTypePayload>;
  /** Updates a single `DjangoContentType` using a unique key and a patch. */
  updateDjangoContentTypeById: Maybe<UpdateDjangoContentTypePayload>;
  /** Updates a single `DjangoMigration` using a unique key and a patch. */
  updateDjangoMigrationById: Maybe<UpdateDjangoMigrationPayload>;
  /** Updates a single `DjangoSesSesstat` using a unique key and a patch. */
  updateDjangoSesSesstatByDate: Maybe<UpdateDjangoSesSesstatPayload>;
  /** Updates a single `DjangoSesSesstat` using a unique key and a patch. */
  updateDjangoSesSesstatById: Maybe<UpdateDjangoSesSesstatPayload>;
  /** Updates a single `DjangoSession` using a unique key and a patch. */
  updateDjangoSessionBySessionKey: Maybe<UpdateDjangoSessionPayload>;
  /** Updates a single `DrsFileexecutor` using a unique key and a patch. */
  updateDrsFileexecutorById: Maybe<UpdateDrsFileexecutorPayload>;
  /** Updates a single `DrsMockfileexecutor` using a unique key and a patch. */
  updateDrsMockfileexecutorByFileexecutorPtrId: Maybe<UpdateDrsMockfileexecutorPayload>;
  /** Updates a single `EulaVersion` using a unique key and a patch. */
  updateEulaVersionById: Maybe<UpdateEulaVersionPayload>;
  /** Updates a single `EulaVersion` using a unique key and a patch. */
  updateEulaVersionByVersion: Maybe<UpdateEulaVersionPayload>;
  /** Updates a single `EulaVersionUserAcceptance` using a unique key and a patch. */
  updateEulaVersionUserAcceptanceById: Maybe<UpdateEulaVersionUserAcceptancePayload>;
  /** Updates a single `EulaVersionUserAcceptance` using a unique key and a patch. */
  updateEulaVersionUserAcceptanceByUserIdAndEulaVersionId: Maybe<UpdateEulaVersionUserAcceptancePayload>;
  /** Updates a single `ExplorerQuery` using a unique key and a patch. */
  updateExplorerQueryById: Maybe<UpdateExplorerQueryPayload>;
  /** Updates a single `ExplorerQueryfavorite` using a unique key and a patch. */
  updateExplorerQueryfavoriteById: Maybe<UpdateExplorerQueryfavoritePayload>;
  /** Updates a single `ExplorerQueryfavorite` using a unique key and a patch. */
  updateExplorerQueryfavoriteByQueryIdAndUserId: Maybe<UpdateExplorerQueryfavoritePayload>;
  /** Updates a single `ExplorerQuerylog` using a unique key and a patch. */
  updateExplorerQuerylogById: Maybe<UpdateExplorerQuerylogPayload>;
  /** Updates a single `FileActivity` using a unique key and a patch. */
  updateFileActivityById: Maybe<UpdateFileActivityPayload>;
  /** Updates a single `File` using a unique key and a patch. */
  updateFileById: Maybe<UpdateFilePayload>;
  /** Updates a single `FileMetadatum` using a unique key and a patch. */
  updateFileMetadatumById: Maybe<UpdateFileMetadatumPayload>;
  /** Updates a single `FileOutputGroup` using a unique key and a patch. */
  updateFileOutputGroupById: Maybe<UpdateFileOutputGroupPayload>;
  /** Updates a single `FileOutputGroup` using a unique key and a patch. */
  updateFileOutputGroupByTaskId: Maybe<UpdateFileOutputGroupPayload>;
  /** Updates a single `FileOutputGroupMetadatum` using a unique key and a patch. */
  updateFileOutputGroupMetadatumById: Maybe<UpdateFileOutputGroupMetadatumPayload>;
  /** Updates a single `FileRecordingGroup` using a unique key and a patch. */
  updateFileRecordingGroupById: Maybe<UpdateFileRecordingGroupPayload>;
  /** Updates a single `FileRecordingGroupMetadatum` using a unique key and a patch. */
  updateFileRecordingGroupMetadatumById: Maybe<UpdateFileRecordingGroupMetadatumPayload>;
  /** Updates a single `MetadataValue` using a unique key and a patch. */
  updateMetadataValueById: Maybe<UpdateMetadataValuePayload>;
  /** Updates a single `Metadatum` using a unique key and a patch. */
  updateMetadatumById: Maybe<UpdateMetadatumPayload>;
  /** Updates a single `Notification` using a unique key and a patch. */
  updateNotificationById: Maybe<UpdateNotificationPayload>;
  /** Updates a single `OutputGroupFile` using a unique key and a patch. */
  updateOutputGroupFileById: Maybe<UpdateOutputGroupFilePayload>;
  /** Updates a single `RecordingGroupFile` using a unique key and a patch. */
  updateRecordingGroupFileById: Maybe<UpdateRecordingGroupFilePayload>;
  /** Updates a single `Run` using a unique key and a patch. */
  updateRunById: Maybe<UpdateRunPayload>;
  /** Updates a single `Tag` using a unique key and a patch. */
  updateTagById: Maybe<UpdateTagPayload>;
  /** Updates a single `TaskActivity` using a unique key and a patch. */
  updateTaskActivityById: Maybe<UpdateTaskActivityPayload>;
  /** Updates a single `TaskActivity` using a unique key and a patch. */
  updateTaskActivityByTaskId: Maybe<UpdateTaskActivityPayload>;
  /** Updates a single `Task` using a unique key and a patch. */
  updateTaskByExecutorId: Maybe<UpdateTaskPayload>;
  /** Updates a single `Task` using a unique key and a patch. */
  updateTaskById: Maybe<UpdateTaskPayload>;
  /** Updates a single `TaskResource` using a unique key and a patch. */
  updateTaskResourceById: Maybe<UpdateTaskResourcePayload>;
  /** Updates a single `TaskSource` using a unique key and a patch. */
  updateTaskSourceById: Maybe<UpdateTaskSourcePayload>;
  /** Updates a single `TaskStatusLog` using a unique key and a patch. */
  updateTaskStatusLogById: Maybe<UpdateTaskStatusLogPayload>;
  /** Updates a single `TaskStatusLog` using a unique key and a patch. */
  updateTaskStatusLogByTaskIdAndStatus: Maybe<UpdateTaskStatusLogPayload>;
  /** Updates a single `Tenant` using a unique key and a patch. */
  updateTenantById: Maybe<UpdateTenantPayload>;
  /** Updates a single `Tenant` using a unique key and a patch. */
  updateTenantByKey: Maybe<UpdateTenantPayload>;
  /** Updates a single `Tenant` using a unique key and a patch. */
  updateTenantByName: Maybe<UpdateTenantPayload>;
  /** Updates a single `TenantContractAllowance` using a unique key and a patch. */
  updateTenantContractAllowanceById: Maybe<UpdateTenantContractAllowancePayload>;
  /** Updates a single `TenantContractAllowance` using a unique key and a patch. */
  updateTenantContractAllowanceByTenantContractTransactionIdAndQuotaType: Maybe<UpdateTenantContractAllowancePayload>;
  /** Updates a single `TenantContract` using a unique key and a patch. */
  updateTenantContractById: Maybe<UpdateTenantContractPayload>;
  /** Updates a single `TenantContract` using a unique key and a patch. */
  updateTenantContractByName: Maybe<UpdateTenantContractPayload>;
  /** Updates a single `TenantContractTransaction` using a unique key and a patch. */
  updateTenantContractTransactionById: Maybe<UpdateTenantContractTransactionPayload>;
  /** Updates a single `TenantMockuserexecutor` using a unique key and a patch. */
  updateTenantMockuserexecutorByUserexecutorPtrId: Maybe<UpdateTenantMockuserexecutorPayload>;
  /** Updates a single `TenantMonthlyConsumedResource` using a unique key and a patch. */
  updateTenantMonthlyConsumedResourceById: Maybe<UpdateTenantMonthlyConsumedResourcePayload>;
  /** Updates a single `TenantMonthlyConsumedResource` using a unique key and a patch. */
  updateTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract: Maybe<UpdateTenantMonthlyConsumedResourcePayload>;
  /** Updates a single `TenantUserexecutor` using a unique key and a patch. */
  updateTenantUserexecutorById: Maybe<UpdateTenantUserexecutorPayload>;
  /** Updates a single `TesContainerimageexecutor` using a unique key and a patch. */
  updateTesContainerimageexecutorById: Maybe<UpdateTesContainerimageexecutorPayload>;
  /** Updates a single `TesMockcontainerimageexecutor` using a unique key and a patch. */
  updateTesMockcontainerimageexecutorByContainerimageexecutorPtrId: Maybe<UpdateTesMockcontainerimageexecutorPayload>;
  /** Updates a single `TesMocktaskexecutor` using a unique key and a patch. */
  updateTesMocktaskexecutorByTaskexecutorPtrId: Maybe<UpdateTesMocktaskexecutorPayload>;
  /** Updates a single `TesTaskexecutor` using a unique key and a patch. */
  updateTesTaskexecutorById: Maybe<UpdateTesTaskexecutorPayload>;
  /** Updates a single `Tool` using a unique key and a patch. */
  updateToolById: Maybe<UpdateToolPayload>;
  /** Updates a single `Tool` using a unique key and a patch. */
  updateToolByKey: Maybe<UpdateToolPayload>;
  /** Updates a single `ToolCategory` using a unique key and a patch. */
  updateToolCategoryById: Maybe<UpdateToolCategoryPayload>;
  /** Updates a single `ToolCategory` using a unique key and a patch. */
  updateToolCategoryByKey: Maybe<UpdateToolCategoryPayload>;
  /** Updates a single `ToolDisplayCategory` using a unique key and a patch. */
  updateToolDisplayCategoryById: Maybe<UpdateToolDisplayCategoryPayload>;
  /** Updates a single `ToolDisplayCategory` using a unique key and a patch. */
  updateToolDisplayCategoryByToolIdAndToolcategoryId: Maybe<UpdateToolDisplayCategoryPayload>;
  /** Updates a single `ToolParameter` using a unique key and a patch. */
  updateToolParameterById: Maybe<UpdateToolParameterPayload>;
  /** Updates a single `ToolResource` using a unique key and a patch. */
  updateToolResourceById: Maybe<UpdateToolResourcePayload>;
  /** Updates a single `ToolResource` using a unique key and a patch. */
  updateToolResourceByTypeAndToolVersionId: Maybe<UpdateToolResourcePayload>;
  /** Updates a single `ToolResult` using a unique key and a patch. */
  updateToolResultById: Maybe<UpdateToolResultPayload>;
  /** Updates a single `ToolSecret` using a unique key and a patch. */
  updateToolSecretById: Maybe<UpdateToolSecretPayload>;
  /** Updates a single `ToolSource` using a unique key and a patch. */
  updateToolSourceById: Maybe<UpdateToolSourcePayload>;
  /** Updates a single `ToolTenant` using a unique key and a patch. */
  updateToolTenantById: Maybe<UpdateToolTenantPayload>;
  /** Updates a single `ToolTenant` using a unique key and a patch. */
  updateToolTenantByToolIdAndTenantId: Maybe<UpdateToolTenantPayload>;
  /** Updates a single `ToolVersion` using a unique key and a patch. */
  updateToolVersionById: Maybe<UpdateToolVersionPayload>;
  /** Updates a single `ToolVersion` using a unique key and a patch. */
  updateToolVersionByToolIdAndVersion: Maybe<UpdateToolVersionPayload>;
  /** Updates a single `Toolbox` using a unique key and a patch. */
  updateToolboxById: Maybe<UpdateToolboxPayload>;
  /** Updates a single `Toolbox` using a unique key and a patch. */
  updateToolboxByKey: Maybe<UpdateToolboxPayload>;
  /** Updates a single `ToolboxExitcode` using a unique key and a patch. */
  updateToolboxExitcodeByCode: Maybe<UpdateToolboxExitcodePayload>;
  /** Updates a single `ToolboxExitcode` using a unique key and a patch. */
  updateToolboxExitcodeById: Maybe<UpdateToolboxExitcodePayload>;
  /** Updates a single `ToolboxTenant` using a unique key and a patch. */
  updateToolboxTenantById: Maybe<UpdateToolboxTenantPayload>;
  /** Updates a single `ToolboxTenant` using a unique key and a patch. */
  updateToolboxTenantByToolboxIdAndTenantId: Maybe<UpdateToolboxTenantPayload>;
  /** Updates a single `ToolboxVersion` using a unique key and a patch. */
  updateToolboxVersionById: Maybe<UpdateToolboxVersionPayload>;
  /** Updates a single `ToolboxVersion` using a unique key and a patch. */
  updateToolboxVersionByToolboxIdAndVersion: Maybe<UpdateToolboxVersionPayload>;
  /** Updates a single `ToolboxVersionExitcode` using a unique key and a patch. */
  updateToolboxVersionExitcodeById: Maybe<UpdateToolboxVersionExitcodePayload>;
  /** Updates a single `ToolboxVersionExitcode` using a unique key and a patch. */
  updateToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeId: Maybe<UpdateToolboxVersionExitcodePayload>;
  /** Updates a single `ToolboxVersionToolVersion` using a unique key and a patch. */
  updateToolboxVersionToolVersionById: Maybe<UpdateToolboxVersionToolVersionPayload>;
  /** Updates a single `ToolboxVersionToolVersion` using a unique key and a patch. */
  updateToolboxVersionToolVersionByToolboxversionIdAndToolversionId: Maybe<UpdateToolboxVersionToolVersionPayload>;
  /** Updates a single `Workflow` using a unique key and a patch. */
  updateWorkflowById: Maybe<UpdateWorkflowPayload>;
  /** Updates a single `WorkflowDatum` using a unique key and a patch. */
  updateWorkflowDatumById: Maybe<UpdateWorkflowDatumPayload>;
  /** Updates a single `WorkflowResult` using a unique key and a patch. */
  updateWorkflowResultById: Maybe<UpdateWorkflowResultPayload>;
  /** Updates a single `WorkflowResultTag` using a unique key and a patch. */
  updateWorkflowResultTagById: Maybe<UpdateWorkflowResultTagPayload>;
  /** Updates a single `WorkflowResultTag` using a unique key and a patch. */
  updateWorkflowResultTagByResultIdAndTagId: Maybe<UpdateWorkflowResultTagPayload>;
  /** Updates a single `WorkflowResultWorkflowVersion` using a unique key and a patch. */
  updateWorkflowResultWorkflowVersionById: Maybe<UpdateWorkflowResultWorkflowVersionPayload>;
  /** Updates a single `WorkflowResultWorkflowVersion` using a unique key and a patch. */
  updateWorkflowResultWorkflowVersionByResultIdAndWorkflowversionId: Maybe<UpdateWorkflowResultWorkflowVersionPayload>;
  /** Updates a single `WorkflowSectionBlock` using a unique key and a patch. */
  updateWorkflowSectionBlockById: Maybe<UpdateWorkflowSectionBlockPayload>;
  /** Updates a single `WorkflowSectionBlockSection` using a unique key and a patch. */
  updateWorkflowSectionBlockSectionByBlockIdAndSectionId: Maybe<UpdateWorkflowSectionBlockSectionPayload>;
  /** Updates a single `WorkflowSectionBlockSection` using a unique key and a patch. */
  updateWorkflowSectionBlockSectionById: Maybe<UpdateWorkflowSectionBlockSectionPayload>;
  /** Updates a single `WorkflowSection` using a unique key and a patch. */
  updateWorkflowSectionById: Maybe<UpdateWorkflowSectionPayload>;
  /** Updates a single `WorkflowSectionWorkflowVersion` using a unique key and a patch. */
  updateWorkflowSectionWorkflowVersionById: Maybe<UpdateWorkflowSectionWorkflowVersionPayload>;
  /** Updates a single `WorkflowSectionWorkflowVersion` using a unique key and a patch. */
  updateWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionId: Maybe<UpdateWorkflowSectionWorkflowVersionPayload>;
  /** Updates a single `WorkflowSource` using a unique key and a patch. */
  updateWorkflowSourceById: Maybe<UpdateWorkflowSourcePayload>;
  /** Updates a single `WorkflowSourceTag` using a unique key and a patch. */
  updateWorkflowSourceTagById: Maybe<UpdateWorkflowSourceTagPayload>;
  /** Updates a single `WorkflowSourceTag` using a unique key and a patch. */
  updateWorkflowSourceTagBySourceIdAndTagId: Maybe<UpdateWorkflowSourceTagPayload>;
  /** Updates a single `WorkflowSourceWorkflowVersion` using a unique key and a patch. */
  updateWorkflowSourceWorkflowVersionById: Maybe<UpdateWorkflowSourceWorkflowVersionPayload>;
  /** Updates a single `WorkflowSourceWorkflowVersion` using a unique key and a patch. */
  updateWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionId: Maybe<UpdateWorkflowSourceWorkflowVersionPayload>;
  /** Updates a single `WorkflowVersion` using a unique key and a patch. */
  updateWorkflowVersionById: Maybe<UpdateWorkflowVersionPayload>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationChangeUserSessionArgs = {
  input: ChangeUserSessionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAnalysisTableArgs = {
  input: CreateAnalysisTableInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAnalysisTableGroupArgs = {
  input: CreateAnalysisTableGroupInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAnalysisTableRowArgs = {
  input: CreateAnalysisTableRowInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAnalysisTableRowAttachResultArgs = {
  input: CreateAnalysisTableRowAttachResultInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateApplicationUserArgs = {
  input: CreateApplicationUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateApplicationUserGroupArgs = {
  input: CreateApplicationUserGroupInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateApplicationUserMembershipArgs = {
  input: CreateApplicationUserMembershipInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateApplicationUserMetadatumArgs = {
  input: CreateApplicationUserMetadatumInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateApplicationUserSessionArgs = {
  input: CreateApplicationUserSessionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateApplicationUserUserPermissionArgs = {
  input: CreateApplicationUserUserPermissionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAuthGroupArgs = {
  input: CreateAuthGroupInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAuthGroupPermissionArgs = {
  input: CreateAuthGroupPermissionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAuthPermissionArgs = {
  input: CreateAuthPermissionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAvailableNonRestrictedProjectArgs = {
  input: CreateAvailableNonRestrictedProjectInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAvatarArgs = {
  input: CreateAvatarInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAwsBatchTaskArgs = {
  input: CreateAwsBatchTaskInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAwsCognitoUserArgs = {
  input: CreateAwsCognitoUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAwsEcrImageArgs = {
  input: CreateAwsEcrImageInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAwsMockBatchTaskArgs = {
  input: CreateAwsMockBatchTaskInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAwsMockEcrImageArgs = {
  input: CreateAwsMockEcrImageInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAwsMockS3FileArgs = {
  input: CreateAwsMockS3FileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAwsS3FileArgs = {
  input: CreateAwsS3FileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateContainerImageArgs = {
  input: CreateContainerImageInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCostArgs = {
  input: CreateCostInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCurrentApplicationUserArgs = {
  input: CreateCurrentApplicationUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCurrentUserMembershipArgs = {
  input: CreateCurrentUserMembershipInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDatasetArgs = {
  input: CreateDatasetInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDatasetMetadatumArgs = {
  input: CreateDatasetMetadatumInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDatasetRecordingsTableArgs = {
  input: CreateDatasetRecordingsTableInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDatasetRecordingsTableColumnArgs = {
  input: CreateDatasetRecordingsTableColumnInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDatasetRecordingsTableColumnOrderArgs = {
  input: CreateDatasetRecordingsTableColumnOrderInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDatasetVersionArgs = {
  input: CreateDatasetVersionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDjangoAdminLogArgs = {
  input: CreateDjangoAdminLogInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDjangoContentTypeArgs = {
  input: CreateDjangoContentTypeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDjangoMigrationArgs = {
  input: CreateDjangoMigrationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDjangoSesSesstatArgs = {
  input: CreateDjangoSesSesstatInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDjangoSessionArgs = {
  input: CreateDjangoSessionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDrsFileexecutorArgs = {
  input: CreateDrsFileexecutorInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDrsMockfileexecutorArgs = {
  input: CreateDrsMockfileexecutorInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEulaVersionArgs = {
  input: CreateEulaVersionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEulaVersionUserAcceptanceArgs = {
  input: CreateEulaVersionUserAcceptanceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateExplorerQueryArgs = {
  input: CreateExplorerQueryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateExplorerQueryfavoriteArgs = {
  input: CreateExplorerQueryfavoriteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateExplorerQuerylogArgs = {
  input: CreateExplorerQuerylogInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFileArgs = {
  input: CreateFileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFileActivityArgs = {
  input: CreateFileActivityInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFileMetadatumArgs = {
  input: CreateFileMetadatumInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFileOutputGroupMetadatumArgs = {
  input: CreateFileOutputGroupMetadatumInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFileRecordingGroupArgs = {
  input: CreateFileRecordingGroupInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFileRecordingGroupMetadatumArgs = {
  input: CreateFileRecordingGroupMetadatumInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMetadataValueArgs = {
  input: CreateMetadataValueInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMetadatumArgs = {
  input: CreateMetadatumInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOutputGroupFileArgs = {
  input: CreateOutputGroupFileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRecordingGroupFileArgs = {
  input: CreateRecordingGroupFileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRunArgs = {
  input: CreateRunInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTagArgs = {
  input: CreateTagInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTaskActivityArgs = {
  input: CreateTaskActivityInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTaskResourceArgs = {
  input: CreateTaskResourceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTaskSourceArgs = {
  input: CreateTaskSourceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTaskStatusLogArgs = {
  input: CreateTaskStatusLogInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTenantArgs = {
  input: CreateTenantInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTenantContractArgs = {
  input: CreateTenantContractInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTenantContractAllowanceArgs = {
  input: CreateTenantContractAllowanceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTenantContractTransactionArgs = {
  input: CreateTenantContractTransactionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTenantMockuserexecutorArgs = {
  input: CreateTenantMockuserexecutorInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTenantMonthlyConsumedResourceArgs = {
  input: CreateTenantMonthlyConsumedResourceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTenantUserexecutorArgs = {
  input: CreateTenantUserexecutorInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTesContainerimageexecutorArgs = {
  input: CreateTesContainerimageexecutorInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTesMockcontainerimageexecutorArgs = {
  input: CreateTesMockcontainerimageexecutorInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTesMocktaskexecutorArgs = {
  input: CreateTesMocktaskexecutorInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTesTaskexecutorArgs = {
  input: CreateTesTaskexecutorInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateToolArgs = {
  input: CreateToolInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateToolCategoryArgs = {
  input: CreateToolCategoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateToolDisplayCategoryArgs = {
  input: CreateToolDisplayCategoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateToolParameterArgs = {
  input: CreateToolParameterInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateToolResourceArgs = {
  input: CreateToolResourceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateToolResultArgs = {
  input: CreateToolResultInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateToolSecretArgs = {
  input: CreateToolSecretInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateToolSourceArgs = {
  input: CreateToolSourceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateToolTenantArgs = {
  input: CreateToolTenantInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateToolVersionArgs = {
  input: CreateToolVersionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateToolboxArgs = {
  input: CreateToolboxInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateToolboxExitcodeArgs = {
  input: CreateToolboxExitcodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateToolboxTenantArgs = {
  input: CreateToolboxTenantInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateToolboxVersionArgs = {
  input: CreateToolboxVersionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateToolboxVersionExitcodeArgs = {
  input: CreateToolboxVersionExitcodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateToolboxVersionToolVersionArgs = {
  input: CreateToolboxVersionToolVersionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWorkflowArgs = {
  input: CreateWorkflowInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWorkflowDatumArgs = {
  input: CreateWorkflowDatumInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWorkflowResultArgs = {
  input: CreateWorkflowResultInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWorkflowResultTagArgs = {
  input: CreateWorkflowResultTagInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWorkflowResultWorkflowVersionArgs = {
  input: CreateWorkflowResultWorkflowVersionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWorkflowSectionArgs = {
  input: CreateWorkflowSectionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWorkflowSectionBlockArgs = {
  input: CreateWorkflowSectionBlockInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWorkflowSectionBlockSectionArgs = {
  input: CreateWorkflowSectionBlockSectionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWorkflowSectionWorkflowVersionArgs = {
  input: CreateWorkflowSectionWorkflowVersionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWorkflowSourceArgs = {
  input: CreateWorkflowSourceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWorkflowSourceTagArgs = {
  input: CreateWorkflowSourceTagInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWorkflowSourceWorkflowVersionArgs = {
  input: CreateWorkflowSourceWorkflowVersionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWorkflowVersionArgs = {
  input: CreateWorkflowVersionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAnalysisTableByIdArgs = {
  input: DeleteAnalysisTableByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAnalysisTableGroupByIdArgs = {
  input: DeleteAnalysisTableGroupByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAnalysisTableRowAttachResultByIdArgs = {
  input: DeleteAnalysisTableRowAttachResultByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAnalysisTableRowByIdArgs = {
  input: DeleteAnalysisTableRowByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAnalysisTableRowByTaskIdArgs = {
  input: DeleteAnalysisTableRowByTaskIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteApplicationUserByEmailArgs = {
  input: DeleteApplicationUserByEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteApplicationUserByIdArgs = {
  input: DeleteApplicationUserByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteApplicationUserByUsernameArgs = {
  input: DeleteApplicationUserByUsernameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteApplicationUserGroupByIdArgs = {
  input: DeleteApplicationUserGroupByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteApplicationUserGroupByUserIdAndGroupIdArgs = {
  input: DeleteApplicationUserGroupByUserIdAndGroupIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteApplicationUserMembershipByIdArgs = {
  input: DeleteApplicationUserMembershipByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteApplicationUserMembershipByUserIdAndTenantIdArgs = {
  input: DeleteApplicationUserMembershipByUserIdAndTenantIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteApplicationUserMetadatumByIdArgs = {
  input: DeleteApplicationUserMetadatumByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteApplicationUserMetadatumByUserIdAndKeyArgs = {
  input: DeleteApplicationUserMetadatumByUserIdAndKeyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteApplicationUserSessionByIdArgs = {
  input: DeleteApplicationUserSessionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteApplicationUserUserPermissionByIdArgs = {
  input: DeleteApplicationUserUserPermissionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteApplicationUserUserPermissionByUserIdAndPermissionIdArgs =
  {
    input: DeleteApplicationUserUserPermissionByUserIdAndPermissionIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAuthGroupByIdArgs = {
  input: DeleteAuthGroupByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAuthGroupByNameArgs = {
  input: DeleteAuthGroupByNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAuthGroupPermissionByGroupIdAndPermissionIdArgs = {
  input: DeleteAuthGroupPermissionByGroupIdAndPermissionIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAuthGroupPermissionByIdArgs = {
  input: DeleteAuthGroupPermissionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAuthPermissionByContentTypeIdAndCodenameArgs = {
  input: DeleteAuthPermissionByContentTypeIdAndCodenameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAuthPermissionByIdArgs = {
  input: DeleteAuthPermissionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAvatarByIdArgs = {
  input: DeleteAvatarByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAvatarByUserIdArgs = {
  input: DeleteAvatarByUserIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAwsBatchTaskByTaskexecutorPtrIdArgs = {
  input: DeleteAwsBatchTaskByTaskexecutorPtrIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAwsCognitoUserByUserexecutorPtrIdArgs = {
  input: DeleteAwsCognitoUserByUserexecutorPtrIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAwsEcrImageByContainerimageexecutorPtrIdArgs = {
  input: DeleteAwsEcrImageByContainerimageexecutorPtrIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAwsMockBatchTaskByAwstaskexecutorPtrIdArgs = {
  input: DeleteAwsMockBatchTaskByAwstaskexecutorPtrIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAwsMockEcrImageByAwscontainerimageexecutorPtrIdArgs =
  {
    input: DeleteAwsMockEcrImageByAwscontainerimageexecutorPtrIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAwsMockS3FileByAwsfileexecutorPtrIdArgs = {
  input: DeleteAwsMockS3FileByAwsfileexecutorPtrIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAwsS3FileByFileexecutorPtrIdArgs = {
  input: DeleteAwsS3FileByFileexecutorPtrIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteContainerImageByExecutorIdArgs = {
  input: DeleteContainerImageByExecutorIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteContainerImageByIdArgs = {
  input: DeleteContainerImageByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteContainerImageByNameAndTagArgs = {
  input: DeleteContainerImageByNameAndTagInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteContainerImageByRepositoryAndTagArgs = {
  input: DeleteContainerImageByRepositoryAndTagInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteContainerImageByToolboxVersionIdArgs = {
  input: DeleteContainerImageByToolboxVersionIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCostByIdArgs = {
  input: DeleteCostByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDatasetByIdArgs = {
  input: DeleteDatasetByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDatasetByProjectIdAndPrefixArgs = {
  input: DeleteDatasetByProjectIdAndPrefixInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDatasetByRecordingsTableIdArgs = {
  input: DeleteDatasetByRecordingsTableIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDatasetByTenantIdAndKeyArgs = {
  input: DeleteDatasetByTenantIdAndKeyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDatasetMetadatumByIdArgs = {
  input: DeleteDatasetMetadatumByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDatasetRecordingsTableByIdArgs = {
  input: DeleteDatasetRecordingsTableByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDatasetRecordingsTableColumnByIdArgs = {
  input: DeleteDatasetRecordingsTableColumnByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDatasetRecordingsTableColumnOrderByIdArgs = {
  input: DeleteDatasetRecordingsTableColumnOrderByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDatasetVersionByIdArgs = {
  input: DeleteDatasetVersionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDjangoAdminLogByIdArgs = {
  input: DeleteDjangoAdminLogByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDjangoContentTypeByAppLabelAndModelArgs = {
  input: DeleteDjangoContentTypeByAppLabelAndModelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDjangoContentTypeByIdArgs = {
  input: DeleteDjangoContentTypeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDjangoMigrationByIdArgs = {
  input: DeleteDjangoMigrationByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDjangoSesSesstatByDateArgs = {
  input: DeleteDjangoSesSesstatByDateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDjangoSesSesstatByIdArgs = {
  input: DeleteDjangoSesSesstatByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDjangoSessionBySessionKeyArgs = {
  input: DeleteDjangoSessionBySessionKeyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDrsFileexecutorByIdArgs = {
  input: DeleteDrsFileexecutorByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDrsMockfileexecutorByFileexecutorPtrIdArgs = {
  input: DeleteDrsMockfileexecutorByFileexecutorPtrIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEulaVersionByIdArgs = {
  input: DeleteEulaVersionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEulaVersionByVersionArgs = {
  input: DeleteEulaVersionByVersionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEulaVersionUserAcceptanceByIdArgs = {
  input: DeleteEulaVersionUserAcceptanceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEulaVersionUserAcceptanceByUserIdAndEulaVersionIdArgs =
  {
    input: DeleteEulaVersionUserAcceptanceByUserIdAndEulaVersionIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExplorerQueryByIdArgs = {
  input: DeleteExplorerQueryByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExplorerQueryfavoriteByIdArgs = {
  input: DeleteExplorerQueryfavoriteByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExplorerQueryfavoriteByQueryIdAndUserIdArgs = {
  input: DeleteExplorerQueryfavoriteByQueryIdAndUserIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExplorerQuerylogByIdArgs = {
  input: DeleteExplorerQuerylogByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileActivityByIdArgs = {
  input: DeleteFileActivityByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileByIdArgs = {
  input: DeleteFileByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileMetadatumByIdArgs = {
  input: DeleteFileMetadatumByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileOutputGroupByIdArgs = {
  input: DeleteFileOutputGroupByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileOutputGroupByTaskIdArgs = {
  input: DeleteFileOutputGroupByTaskIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileOutputGroupMetadatumByIdArgs = {
  input: DeleteFileOutputGroupMetadatumByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileRecordingGroupByIdArgs = {
  input: DeleteFileRecordingGroupByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileRecordingGroupMetadatumByIdArgs = {
  input: DeleteFileRecordingGroupMetadatumByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMetadataValueByIdArgs = {
  input: DeleteMetadataValueByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMetadatumByIdArgs = {
  input: DeleteMetadatumByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOutputGroupFileByIdArgs = {
  input: DeleteOutputGroupFileByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRecordingGroupFileByIdArgs = {
  input: DeleteRecordingGroupFileByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRunByIdArgs = {
  input: DeleteRunByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSessionArgs = {
  input: DeleteSessionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTagByIdArgs = {
  input: DeleteTagByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTaskActivityByIdArgs = {
  input: DeleteTaskActivityByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTaskActivityByTaskIdArgs = {
  input: DeleteTaskActivityByTaskIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTaskByExecutorIdArgs = {
  input: DeleteTaskByExecutorIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTaskByIdArgs = {
  input: DeleteTaskByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTaskResourceByIdArgs = {
  input: DeleteTaskResourceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTaskSourceByIdArgs = {
  input: DeleteTaskSourceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTaskStatusLogByIdArgs = {
  input: DeleteTaskStatusLogByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTaskStatusLogByTaskIdAndStatusArgs = {
  input: DeleteTaskStatusLogByTaskIdAndStatusInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTenantByIdArgs = {
  input: DeleteTenantByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTenantByKeyArgs = {
  input: DeleteTenantByKeyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTenantByNameArgs = {
  input: DeleteTenantByNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTenantContractAllowanceByIdArgs = {
  input: DeleteTenantContractAllowanceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeArgs =
  {
    input: DeleteTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTenantContractByIdArgs = {
  input: DeleteTenantContractByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTenantContractByNameArgs = {
  input: DeleteTenantContractByNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTenantContractTransactionByIdArgs = {
  input: DeleteTenantContractTransactionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTenantMockuserexecutorByUserexecutorPtrIdArgs = {
  input: DeleteTenantMockuserexecutorByUserexecutorPtrIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTenantMonthlyConsumedResourceByIdArgs = {
  input: DeleteTenantMonthlyConsumedResourceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractArgs =
  {
    input: DeleteTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTenantUserexecutorByIdArgs = {
  input: DeleteTenantUserexecutorByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTesContainerimageexecutorByIdArgs = {
  input: DeleteTesContainerimageexecutorByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTesMockcontainerimageexecutorByContainerimageexecutorPtrIdArgs =
  {
    input: DeleteTesMockcontainerimageexecutorByContainerimageexecutorPtrIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTesMocktaskexecutorByTaskexecutorPtrIdArgs = {
  input: DeleteTesMocktaskexecutorByTaskexecutorPtrIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTesTaskexecutorByIdArgs = {
  input: DeleteTesTaskexecutorByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolByIdArgs = {
  input: DeleteToolByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolByKeyArgs = {
  input: DeleteToolByKeyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolCategoryByIdArgs = {
  input: DeleteToolCategoryByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolCategoryByKeyArgs = {
  input: DeleteToolCategoryByKeyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolDisplayCategoryByIdArgs = {
  input: DeleteToolDisplayCategoryByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolDisplayCategoryByToolIdAndToolcategoryIdArgs = {
  input: DeleteToolDisplayCategoryByToolIdAndToolcategoryIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolParameterByIdArgs = {
  input: DeleteToolParameterByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolResourceByIdArgs = {
  input: DeleteToolResourceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolResourceByTypeAndToolVersionIdArgs = {
  input: DeleteToolResourceByTypeAndToolVersionIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolResultByIdArgs = {
  input: DeleteToolResultByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolSecretByIdArgs = {
  input: DeleteToolSecretByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolSourceByIdArgs = {
  input: DeleteToolSourceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolTenantByIdArgs = {
  input: DeleteToolTenantByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolTenantByToolIdAndTenantIdArgs = {
  input: DeleteToolTenantByToolIdAndTenantIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolVersionByIdArgs = {
  input: DeleteToolVersionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolVersionByToolIdAndVersionArgs = {
  input: DeleteToolVersionByToolIdAndVersionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolboxByIdArgs = {
  input: DeleteToolboxByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolboxByKeyArgs = {
  input: DeleteToolboxByKeyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolboxExitcodeByCodeArgs = {
  input: DeleteToolboxExitcodeByCodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolboxExitcodeByIdArgs = {
  input: DeleteToolboxExitcodeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolboxTenantByIdArgs = {
  input: DeleteToolboxTenantByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolboxTenantByToolboxIdAndTenantIdArgs = {
  input: DeleteToolboxTenantByToolboxIdAndTenantIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolboxVersionByIdArgs = {
  input: DeleteToolboxVersionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolboxVersionByToolboxIdAndVersionArgs = {
  input: DeleteToolboxVersionByToolboxIdAndVersionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolboxVersionExitcodeByIdArgs = {
  input: DeleteToolboxVersionExitcodeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdArgs =
  {
    input: DeleteToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolboxVersionToolVersionByIdArgs = {
  input: DeleteToolboxVersionToolVersionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteToolboxVersionToolVersionByToolboxversionIdAndToolversionIdArgs =
  {
    input: DeleteToolboxVersionToolVersionByToolboxversionIdAndToolversionIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowByIdArgs = {
  input: DeleteWorkflowByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowDatumByIdArgs = {
  input: DeleteWorkflowDatumByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowResultByIdArgs = {
  input: DeleteWorkflowResultByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowResultTagByIdArgs = {
  input: DeleteWorkflowResultTagByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowResultTagByResultIdAndTagIdArgs = {
  input: DeleteWorkflowResultTagByResultIdAndTagIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowResultWorkflowVersionByIdArgs = {
  input: DeleteWorkflowResultWorkflowVersionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdArgs =
  {
    input: DeleteWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowSectionBlockByIdArgs = {
  input: DeleteWorkflowSectionBlockByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowSectionBlockSectionByBlockIdAndSectionIdArgs =
  {
    input: DeleteWorkflowSectionBlockSectionByBlockIdAndSectionIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowSectionBlockSectionByIdArgs = {
  input: DeleteWorkflowSectionBlockSectionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowSectionByIdArgs = {
  input: DeleteWorkflowSectionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowSectionWorkflowVersionByIdArgs = {
  input: DeleteWorkflowSectionWorkflowVersionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdArgs =
  {
    input: DeleteWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowSourceByIdArgs = {
  input: DeleteWorkflowSourceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowSourceTagByIdArgs = {
  input: DeleteWorkflowSourceTagByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowSourceTagBySourceIdAndTagIdArgs = {
  input: DeleteWorkflowSourceTagBySourceIdAndTagIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowSourceWorkflowVersionByIdArgs = {
  input: DeleteWorkflowSourceWorkflowVersionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdArgs =
  {
    input: DeleteWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkflowVersionByIdArgs = {
  input: DeleteWorkflowVersionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAnalysisTableByIdArgs = {
  input: UpdateAnalysisTableByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAnalysisTableGroupByIdArgs = {
  input: UpdateAnalysisTableGroupByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAnalysisTableRowAttachResultByIdArgs = {
  input: UpdateAnalysisTableRowAttachResultByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAnalysisTableRowByIdArgs = {
  input: UpdateAnalysisTableRowByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAnalysisTableRowByTaskIdArgs = {
  input: UpdateAnalysisTableRowByTaskIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateApplicationUserByEmailArgs = {
  input: UpdateApplicationUserByEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateApplicationUserByIdArgs = {
  input: UpdateApplicationUserByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateApplicationUserByUsernameArgs = {
  input: UpdateApplicationUserByUsernameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateApplicationUserGroupByIdArgs = {
  input: UpdateApplicationUserGroupByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateApplicationUserGroupByUserIdAndGroupIdArgs = {
  input: UpdateApplicationUserGroupByUserIdAndGroupIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateApplicationUserMembershipByIdArgs = {
  input: UpdateApplicationUserMembershipByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateApplicationUserMembershipByUserIdAndTenantIdArgs = {
  input: UpdateApplicationUserMembershipByUserIdAndTenantIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateApplicationUserMetadatumByIdArgs = {
  input: UpdateApplicationUserMetadatumByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateApplicationUserMetadatumByUserIdAndKeyArgs = {
  input: UpdateApplicationUserMetadatumByUserIdAndKeyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateApplicationUserSessionByIdArgs = {
  input: UpdateApplicationUserSessionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateApplicationUserUserPermissionByIdArgs = {
  input: UpdateApplicationUserUserPermissionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateApplicationUserUserPermissionByUserIdAndPermissionIdArgs =
  {
    input: UpdateApplicationUserUserPermissionByUserIdAndPermissionIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAuthGroupByIdArgs = {
  input: UpdateAuthGroupByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAuthGroupByNameArgs = {
  input: UpdateAuthGroupByNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAuthGroupPermissionByGroupIdAndPermissionIdArgs = {
  input: UpdateAuthGroupPermissionByGroupIdAndPermissionIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAuthGroupPermissionByIdArgs = {
  input: UpdateAuthGroupPermissionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAuthPermissionByContentTypeIdAndCodenameArgs = {
  input: UpdateAuthPermissionByContentTypeIdAndCodenameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAuthPermissionByIdArgs = {
  input: UpdateAuthPermissionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAvatarByIdArgs = {
  input: UpdateAvatarByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAvatarByUserIdArgs = {
  input: UpdateAvatarByUserIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAwsBatchTaskByTaskexecutorPtrIdArgs = {
  input: UpdateAwsBatchTaskByTaskexecutorPtrIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAwsCognitoUserByUserexecutorPtrIdArgs = {
  input: UpdateAwsCognitoUserByUserexecutorPtrIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAwsEcrImageByContainerimageexecutorPtrIdArgs = {
  input: UpdateAwsEcrImageByContainerimageexecutorPtrIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAwsMockBatchTaskByAwstaskexecutorPtrIdArgs = {
  input: UpdateAwsMockBatchTaskByAwstaskexecutorPtrIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAwsMockEcrImageByAwscontainerimageexecutorPtrIdArgs =
  {
    input: UpdateAwsMockEcrImageByAwscontainerimageexecutorPtrIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAwsMockS3FileByAwsfileexecutorPtrIdArgs = {
  input: UpdateAwsMockS3FileByAwsfileexecutorPtrIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAwsS3FileByFileexecutorPtrIdArgs = {
  input: UpdateAwsS3FileByFileexecutorPtrIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContainerImageByExecutorIdArgs = {
  input: UpdateContainerImageByExecutorIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContainerImageByIdArgs = {
  input: UpdateContainerImageByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContainerImageByNameAndTagArgs = {
  input: UpdateContainerImageByNameAndTagInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContainerImageByRepositoryAndTagArgs = {
  input: UpdateContainerImageByRepositoryAndTagInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContainerImageByToolboxVersionIdArgs = {
  input: UpdateContainerImageByToolboxVersionIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCostByIdArgs = {
  input: UpdateCostByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetByIdArgs = {
  input: UpdateDatasetByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetByProjectIdAndPrefixArgs = {
  input: UpdateDatasetByProjectIdAndPrefixInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetByRecordingsTableIdArgs = {
  input: UpdateDatasetByRecordingsTableIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetByTenantIdAndKeyArgs = {
  input: UpdateDatasetByTenantIdAndKeyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetMetadatumByIdArgs = {
  input: UpdateDatasetMetadatumByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetRecordingsTableByIdArgs = {
  input: UpdateDatasetRecordingsTableByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetRecordingsTableColumnByIdArgs = {
  input: UpdateDatasetRecordingsTableColumnByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetRecordingsTableColumnOrderByIdArgs = {
  input: UpdateDatasetRecordingsTableColumnOrderByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetVersionByIdArgs = {
  input: UpdateDatasetVersionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDjangoAdminLogByIdArgs = {
  input: UpdateDjangoAdminLogByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDjangoContentTypeByAppLabelAndModelArgs = {
  input: UpdateDjangoContentTypeByAppLabelAndModelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDjangoContentTypeByIdArgs = {
  input: UpdateDjangoContentTypeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDjangoMigrationByIdArgs = {
  input: UpdateDjangoMigrationByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDjangoSesSesstatByDateArgs = {
  input: UpdateDjangoSesSesstatByDateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDjangoSesSesstatByIdArgs = {
  input: UpdateDjangoSesSesstatByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDjangoSessionBySessionKeyArgs = {
  input: UpdateDjangoSessionBySessionKeyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDrsFileexecutorByIdArgs = {
  input: UpdateDrsFileexecutorByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDrsMockfileexecutorByFileexecutorPtrIdArgs = {
  input: UpdateDrsMockfileexecutorByFileexecutorPtrIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEulaVersionByIdArgs = {
  input: UpdateEulaVersionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEulaVersionByVersionArgs = {
  input: UpdateEulaVersionByVersionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEulaVersionUserAcceptanceByIdArgs = {
  input: UpdateEulaVersionUserAcceptanceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEulaVersionUserAcceptanceByUserIdAndEulaVersionIdArgs =
  {
    input: UpdateEulaVersionUserAcceptanceByUserIdAndEulaVersionIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExplorerQueryByIdArgs = {
  input: UpdateExplorerQueryByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExplorerQueryfavoriteByIdArgs = {
  input: UpdateExplorerQueryfavoriteByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExplorerQueryfavoriteByQueryIdAndUserIdArgs = {
  input: UpdateExplorerQueryfavoriteByQueryIdAndUserIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExplorerQuerylogByIdArgs = {
  input: UpdateExplorerQuerylogByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileActivityByIdArgs = {
  input: UpdateFileActivityByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileByIdArgs = {
  input: UpdateFileByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileMetadatumByIdArgs = {
  input: UpdateFileMetadatumByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileOutputGroupByIdArgs = {
  input: UpdateFileOutputGroupByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileOutputGroupByTaskIdArgs = {
  input: UpdateFileOutputGroupByTaskIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileOutputGroupMetadatumByIdArgs = {
  input: UpdateFileOutputGroupMetadatumByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileRecordingGroupByIdArgs = {
  input: UpdateFileRecordingGroupByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileRecordingGroupMetadatumByIdArgs = {
  input: UpdateFileRecordingGroupMetadatumByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMetadataValueByIdArgs = {
  input: UpdateMetadataValueByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMetadatumByIdArgs = {
  input: UpdateMetadatumByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationByIdArgs = {
  input: UpdateNotificationByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOutputGroupFileByIdArgs = {
  input: UpdateOutputGroupFileByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRecordingGroupFileByIdArgs = {
  input: UpdateRecordingGroupFileByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRunByIdArgs = {
  input: UpdateRunByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTagByIdArgs = {
  input: UpdateTagByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTaskActivityByIdArgs = {
  input: UpdateTaskActivityByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTaskActivityByTaskIdArgs = {
  input: UpdateTaskActivityByTaskIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTaskByExecutorIdArgs = {
  input: UpdateTaskByExecutorIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTaskByIdArgs = {
  input: UpdateTaskByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTaskResourceByIdArgs = {
  input: UpdateTaskResourceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTaskSourceByIdArgs = {
  input: UpdateTaskSourceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTaskStatusLogByIdArgs = {
  input: UpdateTaskStatusLogByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTaskStatusLogByTaskIdAndStatusArgs = {
  input: UpdateTaskStatusLogByTaskIdAndStatusInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTenantByIdArgs = {
  input: UpdateTenantByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTenantByKeyArgs = {
  input: UpdateTenantByKeyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTenantByNameArgs = {
  input: UpdateTenantByNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTenantContractAllowanceByIdArgs = {
  input: UpdateTenantContractAllowanceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeArgs =
  {
    input: UpdateTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTenantContractByIdArgs = {
  input: UpdateTenantContractByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTenantContractByNameArgs = {
  input: UpdateTenantContractByNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTenantContractTransactionByIdArgs = {
  input: UpdateTenantContractTransactionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTenantMockuserexecutorByUserexecutorPtrIdArgs = {
  input: UpdateTenantMockuserexecutorByUserexecutorPtrIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTenantMonthlyConsumedResourceByIdArgs = {
  input: UpdateTenantMonthlyConsumedResourceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractArgs =
  {
    input: UpdateTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTenantUserexecutorByIdArgs = {
  input: UpdateTenantUserexecutorByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTesContainerimageexecutorByIdArgs = {
  input: UpdateTesContainerimageexecutorByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTesMockcontainerimageexecutorByContainerimageexecutorPtrIdArgs =
  {
    input: UpdateTesMockcontainerimageexecutorByContainerimageexecutorPtrIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTesMocktaskexecutorByTaskexecutorPtrIdArgs = {
  input: UpdateTesMocktaskexecutorByTaskexecutorPtrIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTesTaskexecutorByIdArgs = {
  input: UpdateTesTaskexecutorByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolByIdArgs = {
  input: UpdateToolByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolByKeyArgs = {
  input: UpdateToolByKeyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolCategoryByIdArgs = {
  input: UpdateToolCategoryByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolCategoryByKeyArgs = {
  input: UpdateToolCategoryByKeyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolDisplayCategoryByIdArgs = {
  input: UpdateToolDisplayCategoryByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolDisplayCategoryByToolIdAndToolcategoryIdArgs = {
  input: UpdateToolDisplayCategoryByToolIdAndToolcategoryIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolParameterByIdArgs = {
  input: UpdateToolParameterByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolResourceByIdArgs = {
  input: UpdateToolResourceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolResourceByTypeAndToolVersionIdArgs = {
  input: UpdateToolResourceByTypeAndToolVersionIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolResultByIdArgs = {
  input: UpdateToolResultByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolSecretByIdArgs = {
  input: UpdateToolSecretByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolSourceByIdArgs = {
  input: UpdateToolSourceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolTenantByIdArgs = {
  input: UpdateToolTenantByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolTenantByToolIdAndTenantIdArgs = {
  input: UpdateToolTenantByToolIdAndTenantIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolVersionByIdArgs = {
  input: UpdateToolVersionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolVersionByToolIdAndVersionArgs = {
  input: UpdateToolVersionByToolIdAndVersionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolboxByIdArgs = {
  input: UpdateToolboxByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolboxByKeyArgs = {
  input: UpdateToolboxByKeyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolboxExitcodeByCodeArgs = {
  input: UpdateToolboxExitcodeByCodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolboxExitcodeByIdArgs = {
  input: UpdateToolboxExitcodeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolboxTenantByIdArgs = {
  input: UpdateToolboxTenantByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolboxTenantByToolboxIdAndTenantIdArgs = {
  input: UpdateToolboxTenantByToolboxIdAndTenantIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolboxVersionByIdArgs = {
  input: UpdateToolboxVersionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolboxVersionByToolboxIdAndVersionArgs = {
  input: UpdateToolboxVersionByToolboxIdAndVersionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolboxVersionExitcodeByIdArgs = {
  input: UpdateToolboxVersionExitcodeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdArgs =
  {
    input: UpdateToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolboxVersionToolVersionByIdArgs = {
  input: UpdateToolboxVersionToolVersionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateToolboxVersionToolVersionByToolboxversionIdAndToolversionIdArgs =
  {
    input: UpdateToolboxVersionToolVersionByToolboxversionIdAndToolversionIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowByIdArgs = {
  input: UpdateWorkflowByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowDatumByIdArgs = {
  input: UpdateWorkflowDatumByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowResultByIdArgs = {
  input: UpdateWorkflowResultByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowResultTagByIdArgs = {
  input: UpdateWorkflowResultTagByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowResultTagByResultIdAndTagIdArgs = {
  input: UpdateWorkflowResultTagByResultIdAndTagIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowResultWorkflowVersionByIdArgs = {
  input: UpdateWorkflowResultWorkflowVersionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdArgs =
  {
    input: UpdateWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowSectionBlockByIdArgs = {
  input: UpdateWorkflowSectionBlockByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowSectionBlockSectionByBlockIdAndSectionIdArgs =
  {
    input: UpdateWorkflowSectionBlockSectionByBlockIdAndSectionIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowSectionBlockSectionByIdArgs = {
  input: UpdateWorkflowSectionBlockSectionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowSectionByIdArgs = {
  input: UpdateWorkflowSectionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowSectionWorkflowVersionByIdArgs = {
  input: UpdateWorkflowSectionWorkflowVersionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdArgs =
  {
    input: UpdateWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowSourceByIdArgs = {
  input: UpdateWorkflowSourceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowSourceTagByIdArgs = {
  input: UpdateWorkflowSourceTagByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowSourceTagBySourceIdAndTagIdArgs = {
  input: UpdateWorkflowSourceTagBySourceIdAndTagIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowSourceWorkflowVersionByIdArgs = {
  input: UpdateWorkflowSourceWorkflowVersionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdArgs =
  {
    input: UpdateWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkflowVersionByIdArgs = {
  input: UpdateWorkflowVersionByIdInput;
};

export type Notification = {
  __typename: "Notification";
  /** Reads a single `ApplicationUser` that is related to this `Notification`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  dateCreated: Scalars["Datetime"]["output"];
  /** Reads a single `FileProcessingNotification` that is related to this `Notification`. */
  fileProcessingNotificationById: Maybe<FileProcessingNotification>;
  /**
   * Reads and enables pagination through a set of `FileProcessingNotification`.
   * @deprecated Please use fileProcessingNotificationById instead
   */
  fileProcessingNotificationsById: FileProcessingNotificationsConnection;
  hasSeen: Scalars["Boolean"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads a single `ProjectClonedNotification` that is related to this `Notification`. */
  projectClonedNotificationById: Maybe<ProjectClonedNotification>;
  /**
   * Reads and enables pagination through a set of `ProjectClonedNotification`.
   * @deprecated Please use projectClonedNotificationById instead
   */
  projectClonedNotificationsById: ProjectClonedNotificationsConnection;
  /** Reads a single `ProjectSharedNotification` that is related to this `Notification`. */
  projectSharedNotificationById: Maybe<ProjectSharedNotification>;
  /**
   * Reads and enables pagination through a set of `ProjectSharedNotification`.
   * @deprecated Please use projectSharedNotificationById instead
   */
  projectSharedNotificationsById: ProjectSharedNotificationsConnection;
  /** Reads a single `RoleChangeNotification` that is related to this `Notification`. */
  roleChangeNotificationById: Maybe<RoleChangeNotification>;
  /**
   * Reads and enables pagination through a set of `RoleChangeNotification`.
   * @deprecated Please use roleChangeNotificationById instead
   */
  roleChangeNotificationsById: RoleChangeNotificationsConnection;
  /** Reads a single `TenantUserMembershipNotification` that is related to this `Notification`. */
  tenantUserMembershipNotificationById: Maybe<TenantUserMembershipNotification>;
  /**
   * Reads and enables pagination through a set of `TenantUserMembershipNotification`.
   * @deprecated Please use tenantUserMembershipNotificationById instead
   */
  tenantUserMembershipNotificationsById: TenantUserMembershipNotificationsConnection;
  userId: Scalars["UUID"]["output"];
};

export type NotificationFileProcessingNotificationsByIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileProcessingNotificationCondition>;
  filter?: InputMaybe<FileProcessingNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileProcessingNotificationsOrderBy>>;
};

export type NotificationProjectClonedNotificationsByIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProjectClonedNotificationCondition>;
  filter?: InputMaybe<ProjectClonedNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProjectClonedNotificationsOrderBy>>;
};

export type NotificationProjectSharedNotificationsByIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProjectSharedNotificationCondition>;
  filter?: InputMaybe<ProjectSharedNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProjectSharedNotificationsOrderBy>>;
};

export type NotificationRoleChangeNotificationsByIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<RoleChangeNotificationCondition>;
  filter?: InputMaybe<RoleChangeNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RoleChangeNotificationsOrderBy>>;
};

export type NotificationTenantUserMembershipNotificationsByIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantUserMembershipNotificationCondition>;
  filter?: InputMaybe<TenantUserMembershipNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantUserMembershipNotificationsOrderBy>>;
};

/**
 * A condition to be used against `Notification` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type NotificationCondition = {
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `hasSeen` field. */
  hasSeen?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `Notification` object types. All fields are combined with a logical ‘and.’ */
export type NotificationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NotificationFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fileProcessingNotificationById` relation. */
  fileProcessingNotificationById?: InputMaybe<FileProcessingNotificationFilter>;
  /** A related `fileProcessingNotificationById` exists. */
  fileProcessingNotificationByIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `hasSeen` field. */
  hasSeen?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NotificationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NotificationFilter>>;
  /** Filter by the object’s `projectClonedNotificationById` relation. */
  projectClonedNotificationById?: InputMaybe<ProjectClonedNotificationFilter>;
  /** A related `projectClonedNotificationById` exists. */
  projectClonedNotificationByIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `projectSharedNotificationById` relation. */
  projectSharedNotificationById?: InputMaybe<ProjectSharedNotificationFilter>;
  /** A related `projectSharedNotificationById` exists. */
  projectSharedNotificationByIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `roleChangeNotificationById` relation. */
  roleChangeNotificationById?: InputMaybe<RoleChangeNotificationFilter>;
  /** A related `roleChangeNotificationById` exists. */
  roleChangeNotificationByIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tenantUserMembershipNotificationById` relation. */
  tenantUserMembershipNotificationById?: InputMaybe<TenantUserMembershipNotificationFilter>;
  /** A related `tenantUserMembershipNotificationById` exists. */
  tenantUserMembershipNotificationByIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** The fields on `notification` to look up the row to connect. */
export type NotificationNotificationPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `notification` to look up the row to update. */
export type NotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdUsingNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `notification` being updated. */
    notificationPatch: UpdateNotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdPatch;
  };

/** The fields on `notification` to look up the row to update. */
export type NotificationOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `notification` being updated. */
    notificationPatch: UpdateNotificationOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdPatch;
  };

/** The fields on `notification` to look up the row to update. */
export type NotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdUsingNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `notification` being updated. */
    notificationPatch: UpdateNotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdPatch;
  };

/** The fields on `notification` to look up the row to update. */
export type NotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdUsingNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `notification` being updated. */
    notificationPatch: UpdateNotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdPatch;
  };

/** The fields on `notification` to look up the row to update. */
export type NotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdUsingNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `notification` being updated. */
    notificationPatch: UpdateNotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdPatch;
  };

/** The fields on `notification` to look up the row to update. */
export type NotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatUsingNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `notification` being updated. */
    notificationPatch: UpdateNotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatPatch;
  };

/** Represents an update to a `Notification`. Fields that are set will be updated. */
export type NotificationPatch = {
  applicationUserToUserId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileProcessingNotificationUsingId?: InputMaybe<FileProcessingNotificationId630E6495FkNotificationIdInverseInput>;
  hasSeen?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  projectClonedNotificationUsingId?: InputMaybe<ProjectClonedNotificationIdD235A018FkNotificationIdInverseInput>;
  projectSharedNotificationUsingId?: InputMaybe<ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInverseInput>;
  roleChangeNotificationUsingId?: InputMaybe<RoleChangeNotificationIdD576A528FkNotificationIdInverseInput>;
  tenantUserMembershipNotificationUsingId?: InputMaybe<TenantUserMembershId10Affa8DFkNotificatInverseInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `applicationUser` to be created by this mutation. */
export type NotificationUserId1002Fc38FkApplicationUserIdApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `NotificationInput` mutation. */
export type NotificationUserId1002Fc38FkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `notification` in the `ApplicationUserInput` mutation. */
export type NotificationUserId1002Fc38FkApplicationUserIdInverseInput = {
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectById?: InputMaybe<Array<NotificationNotificationPkeyConnect>>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<NotificationOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingNotificationPkeyUpdate>
  >;
};

/** A connection to a list of `Notification` values. */
export type NotificationsConnection = {
  __typename: "NotificationsConnection";
  /** A list of edges which contains the `Notification` and cursor to aid in pagination. */
  edges: Array<NotificationsEdge>;
  /** A list of `Notification` objects. */
  nodes: Array<Notification>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Notification` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Notification` edge in the connection. */
export type NotificationsEdge = {
  __typename: "NotificationsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Notification` at the end of the edge. */
  node: Notification;
};

/** Methods to use when ordering `Notification`. */
export enum NotificationsOrderBy {
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  HasSeenAsc = "HAS_SEEN_ASC",
  HasSeenDesc = "HAS_SEEN_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type OutputGroupFile = {
  __typename: "OutputGroupFile";
  dateCreated: Scalars["Datetime"]["output"];
  /** Reads a single `File` that is related to this `OutputGroupFile`. */
  fileByFileId: Maybe<File>;
  fileId: Scalars["UUID"]["output"];
  /** Reads a single `FileOutputGroup` that is related to this `OutputGroupFile`. */
  fileOutputGroupByFileOutputGroupId: Maybe<FileOutputGroup>;
  fileOutputGroupId: Scalars["UUID"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads a single `Project` that is related to this `OutputGroupFile`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
};

/**
 * A condition to be used against `OutputGroupFile` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OutputGroupFileCondition = {
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `fileOutputGroupId` field. */
  fileOutputGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `OutputGroupFile` object types. All fields are combined with a logical ‘and.’ */
export type OutputGroupFileFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OutputGroupFileFilter>>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fileByFileId` relation. */
  fileByFileId?: InputMaybe<FileFilter>;
  /** Filter by the object’s `fileId` field. */
  fileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `fileOutputGroupByFileOutputGroupId` relation. */
  fileOutputGroupByFileOutputGroupId?: InputMaybe<FileOutputGroupFilter>;
  /** Filter by the object’s `fileOutputGroupId` field. */
  fileOutputGroupId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OutputGroupFileFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OutputGroupFileFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `OutputGroupFile` */
export type OutputGroupFileInput = {
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupToFileOutputGroupId?: InputMaybe<OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInput>;
  fileToFileId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInput>;
  id: Scalars["UUID"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInput>;
};

/** The fields on `outputGroupFile` to look up the row to update. */
export type OutputGroupFileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdUsingOutputGroupFilesPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `outputGroupFile` being updated. */
    outputGroupFilePatch: UpdateOutputGroupFileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdPatch;
  };

/** The fields on `outputGroupFile` to look up the row to update. */
export type OutputGroupFileOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpUsingOutputGroupFilesPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `outputGroupFile` being updated. */
    outputGroupFilePatch: UpdateOutputGroupFileOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpPatch;
  };

/** The fields on `outputGroupFile` to look up the row to update. */
export type OutputGroupFileOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdUsingOutputGroupFilesPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `outputGroupFile` being updated. */
    outputGroupFilePatch: UpdateOutputGroupFileOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdPatch;
  };

/** The fields on `outputGroupFile` to look up the row to connect. */
export type OutputGroupFileOutputGroupFilesPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `outputGroupFile` to look up the row to delete. */
export type OutputGroupFileOutputGroupFilesPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** Represents an update to a `OutputGroupFile`. Fields that are set will be updated. */
export type OutputGroupFilePatch = {
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupToFileOutputGroupId?: InputMaybe<OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInput>;
  fileToFileId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInput>;
};

/** A connection to a list of `OutputGroupFile` values. */
export type OutputGroupFilesConnection = {
  __typename: "OutputGroupFilesConnection";
  /** A list of edges which contains the `OutputGroupFile` and cursor to aid in pagination. */
  edges: Array<OutputGroupFilesEdge>;
  /** A list of `OutputGroupFile` objects. */
  nodes: Array<OutputGroupFile>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OutputGroupFile` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `OutputGroupFile` edge in the connection. */
export type OutputGroupFilesEdge = {
  __typename: "OutputGroupFilesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OutputGroupFile` at the end of the edge. */
  node: OutputGroupFile;
};

/** The `file` to be created by this mutation. */
export type OutputGroupFilesFileIdEdeabb23FkFileIdFileCreateInput = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["UUID"]["input"];
  isSeries: Scalars["Boolean"]["input"];
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  originalName: Scalars["String"]["input"];
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus: Scalars["Int"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status: Scalars["Int"]["input"];
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `file` in the `OutputGroupFileInput` mutation. */
export type OutputGroupFilesFileIdEdeabb23FkFileIdInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: InputMaybe<FileFilePkeyConnect>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdFileCreateInput>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: InputMaybe<FileFilePkeyDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: InputMaybe<FileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdUsingFilePkeyUpdate>;
};

/** Input for the nested mutation of `outputGroupFile` in the `FileInput` mutation. */
export type OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput = {
  /** The primary key(s) for `outputGroupFile` for the far side of the relationship. */
  connectById?: InputMaybe<Array<OutputGroupFileOutputGroupFilesPkeyConnect>>;
  /** A `OutputGroupFileInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<OutputGroupFilesFileIdEdeabb23FkFileIdOutputGroupFilesCreateInput>
  >;
  /** The primary key(s) for `outputGroupFile` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<OutputGroupFileOutputGroupFilesPkeyDelete>>;
  /** Flag indicating whether all other `outputGroupFile` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `outputGroupFile` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<OutputGroupFileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdUsingOutputGroupFilesPkeyUpdate>
  >;
};

/** The `outputGroupFile` to be created by this mutation. */
export type OutputGroupFilesFileIdEdeabb23FkFileIdOutputGroupFilesCreateInput =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileOutputGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupToFileOutputGroupId?: InputMaybe<OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInput>;
    fileToFileId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInput>;
    id: Scalars["UUID"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInput>;
  };

/** Input for the nested mutation of `fileOutputGroup` in the `OutputGroupFileInput` mutation. */
export type OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInput = {
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  connectById?: InputMaybe<FileOutputGroupFileOutputGroupPkeyConnect>;
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  connectByTaskId?: InputMaybe<FileOutputGroupFileOutputGroupTaskIdKeyConnect>;
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<FileOutputGroupFileOutputGroupPkeyDelete>;
  /** The primary key(s) for `fileOutputGroup` for the far side of the relationship. */
  deleteByTaskId?: InputMaybe<FileOutputGroupFileOutputGroupTaskIdKeyDelete>;
  /** The primary key(s) and patch data for `fileOutputGroup` for the far side of the relationship. */
  updateById?: InputMaybe<FileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpUsingFileOutputGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `fileOutputGroup` for the far side of the relationship. */
  updateByTaskId?: InputMaybe<FileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpUsingFileOutputGroupTaskIdKeyUpdate>;
};

/** Input for the nested mutation of `outputGroupFile` in the `FileOutputGroupInput` mutation. */
export type OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInverseInput = {
  /** The primary key(s) for `outputGroupFile` for the far side of the relationship. */
  connectById?: InputMaybe<Array<OutputGroupFileOutputGroupFilesPkeyConnect>>;
  /** A `OutputGroupFileInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpOutputGroupFilesCreateInput>
  >;
  /** The primary key(s) for `outputGroupFile` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<OutputGroupFileOutputGroupFilesPkeyDelete>>;
  /** Flag indicating whether all other `outputGroupFile` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `outputGroupFile` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<OutputGroupFileOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpUsingOutputGroupFilesPkeyUpdate>
  >;
};

/** The `outputGroupFile` to be created by this mutation. */
export type OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpOutputGroupFilesCreateInput =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupToFileOutputGroupId?: InputMaybe<OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInput>;
    fileToFileId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInput>;
    id: Scalars["UUID"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInput>;
  };

/** Methods to use when ordering `OutputGroupFile`. */
export enum OutputGroupFilesOrderBy {
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  FileOutputGroupIdAsc = "FILE_OUTPUT_GROUP_ID_ASC",
  FileOutputGroupIdDesc = "FILE_OUTPUT_GROUP_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
}

/** Input for the nested mutation of `project` in the `OutputGroupFileInput` mutation. */
export type OutputGroupFilesProjectId8709B561FkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `outputGroupFile` in the `ProjectInput` mutation. */
export type OutputGroupFilesProjectId8709B561FkProjectIdInverseInput = {
  /** The primary key(s) for `outputGroupFile` for the far side of the relationship. */
  connectById?: InputMaybe<Array<OutputGroupFileOutputGroupFilesPkeyConnect>>;
  /** A `OutputGroupFileInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<OutputGroupFilesProjectId8709B561FkProjectIdOutputGroupFilesCreateInput>
  >;
  /** The primary key(s) for `outputGroupFile` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<OutputGroupFileOutputGroupFilesPkeyDelete>>;
  /** Flag indicating whether all other `outputGroupFile` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `outputGroupFile` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<OutputGroupFileOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdUsingOutputGroupFilesPkeyUpdate>
  >;
};

/** The `outputGroupFile` to be created by this mutation. */
export type OutputGroupFilesProjectId8709B561FkProjectIdOutputGroupFilesCreateInput =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupToFileOutputGroupId?: InputMaybe<OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInput>;
    fileToFileId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInput>;
    id: Scalars["UUID"]["input"];
    projectToProjectId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInput>;
  };

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars["Cursor"]["output"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars["Cursor"]["output"]>;
};

export type Project = {
  __typename: "Project";
  /** Reads and enables pagination through a set of `File`. */
  activeFiles: FilesConnection;
  activeStorageSize: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads and enables pagination through a set of `AnalysisTableGroup`. */
  analysisTableGroupsByProjectId: AnalysisTableGroupsConnection;
  /** Reads and enables pagination through a set of `AnalysisTableRowAttachResult`. */
  analysisTableRowAttachResultsByProjectId: AnalysisTableRowAttachResultsConnection;
  /** Reads and enables pagination through a set of `AnalysisTableRow`. */
  analysisTableRowsByProjectId: AnalysisTableRowsConnection;
  /** Reads and enables pagination through a set of `AnalysisTable`. */
  analysisTablesByProjectId: AnalysisTablesConnection;
  /** Reads a single `ApplicationUser` that is related to this `Project`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  archivedStorageSize: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads and enables pagination through a set of `DatasetMetadatum`. */
  datasetMetadataByProjectId: DatasetMetadataConnection;
  /** Reads and enables pagination through a set of `DatasetRecordingsTableColumnOrder`. */
  datasetRecordingsTableColumnOrdersByProjectId: DatasetRecordingsTableColumnOrdersConnection;
  /** Reads and enables pagination through a set of `DatasetRecordingsTableColumn`. */
  datasetRecordingsTableColumnsByProjectId: DatasetRecordingsTableColumnsConnection;
  /** Reads and enables pagination through a set of `DatasetRecordingsTable`. */
  datasetRecordingsTablesByProjectId: DatasetRecordingsTablesConnection;
  /** Reads and enables pagination through a set of `DatasetVersion`. */
  datasetVersionsByProjectId: DatasetVersionsConnection;
  /** Reads and enables pagination through a set of `Dataset`. */
  datasetsByProjectId: DatasetsConnection;
  dateArchived: Maybe<Scalars["Datetime"]["output"]>;
  dateCreated: Scalars["Datetime"]["output"];
  dateDataDeleted: Maybe<Scalars["Datetime"]["output"]>;
  dateDeleted: Maybe<Scalars["Datetime"]["output"]>;
  defaultUserAccessLevel: Scalars["Int"]["output"];
  description: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `FileActivity`. */
  fileActivitiesByProjectId: FileActivitiesConnection;
  /** Reads and enables pagination through a set of `FileMetadatum`. */
  fileMetadataByProjectId: FileMetadataConnection;
  /** Reads and enables pagination through a set of `FileOutputGroupMetadatum`. */
  fileOutputGroupMetadataByProjectId: FileOutputGroupMetadataConnection;
  /** Reads and enables pagination through a set of `FileOutputGroup`. */
  fileOutputGroupsByProjectId: FileOutputGroupsConnection;
  /** Reads and enables pagination through a set of `FileRecordingGroupMetadatum`. */
  fileRecordingGroupMetadataByProjectId: FileRecordingGroupMetadataConnection;
  /** Reads and enables pagination through a set of `FileRecordingGroup`. */
  fileRecordingGroupsByProjectId: FileRecordingGroupsConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByProjectId: FilesConnection;
  icon: Maybe<Scalars["String"]["output"]>;
  iconColor: Scalars["String"]["output"];
  iconText: Maybe<Scalars["String"]["output"]>;
  id: Scalars["UUID"]["output"];
  key: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `Metadatum`. */
  metadataByProjectId: MetadataConnection;
  /** Reads and enables pagination through a set of `MetadataValue`. */
  metadataValuesByProjectId: MetadataValuesConnection;
  name: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `OutputGroupFile`. */
  outputGroupFilesByProjectId: OutputGroupFilesConnection;
  prefix: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `ProjectClonedNotification`. */
  projectClonedNotificationsByProjectId: ProjectClonedNotificationsConnection;
  /** Reads and enables pagination through a set of `ProjectSharedNotification`. */
  projectSharedNotificationsByProjectId: ProjectSharedNotificationsConnection;
  /** Reads and enables pagination through a set of `RecordingGroupFile`. */
  recordingGroupFilesByProjectId: RecordingGroupFilesConnection;
  shortDescription: Maybe<Scalars["String"]["output"]>;
  status: Scalars["Int"]["output"];
  /** Reads and enables pagination through a set of `Task`. */
  tasksByProjectId: TasksConnection;
  /** Reads a single `Tenant` that is related to this `Project`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  usedCredits: Maybe<Scalars["Float"]["output"]>;
  userId: Scalars["UUID"]["output"];
};

export type ProjectActiveFilesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  at?: InputMaybe<Scalars["Datetime"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ProjectAnalysisTableGroupsByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableGroupCondition>;
  filter?: InputMaybe<AnalysisTableGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTableGroupsOrderBy>>;
};

export type ProjectAnalysisTableRowAttachResultsByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableRowAttachResultCondition>;
  filter?: InputMaybe<AnalysisTableRowAttachResultFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTableRowAttachResultsOrderBy>>;
};

export type ProjectAnalysisTableRowsByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableRowCondition>;
  filter?: InputMaybe<AnalysisTableRowFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTableRowsOrderBy>>;
};

export type ProjectAnalysisTablesByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableCondition>;
  filter?: InputMaybe<AnalysisTableFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTablesOrderBy>>;
};

export type ProjectDatasetMetadataByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetMetadatumCondition>;
  filter?: InputMaybe<DatasetMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetMetadataOrderBy>>;
};

export type ProjectDatasetRecordingsTableColumnOrdersByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetRecordingsTableColumnOrderCondition>;
  filter?: InputMaybe<DatasetRecordingsTableColumnOrderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetRecordingsTableColumnOrdersOrderBy>>;
};

export type ProjectDatasetRecordingsTableColumnsByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetRecordingsTableColumnCondition>;
  filter?: InputMaybe<DatasetRecordingsTableColumnFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetRecordingsTableColumnsOrderBy>>;
};

export type ProjectDatasetRecordingsTablesByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetRecordingsTableCondition>;
  filter?: InputMaybe<DatasetRecordingsTableFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetRecordingsTablesOrderBy>>;
};

export type ProjectDatasetVersionsByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetVersionCondition>;
  filter?: InputMaybe<DatasetVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetVersionsOrderBy>>;
};

export type ProjectDatasetsByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetCondition>;
  filter?: InputMaybe<DatasetFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetsOrderBy>>;
};

export type ProjectFileActivitiesByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileActivityCondition>;
  filter?: InputMaybe<FileActivityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileActivitiesOrderBy>>;
};

export type ProjectFileMetadataByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileMetadatumCondition>;
  filter?: InputMaybe<FileMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileMetadataOrderBy>>;
};

export type ProjectFileOutputGroupMetadataByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileOutputGroupMetadatumCondition>;
  filter?: InputMaybe<FileOutputGroupMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileOutputGroupMetadataOrderBy>>;
};

export type ProjectFileOutputGroupsByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileOutputGroupCondition>;
  filter?: InputMaybe<FileOutputGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileOutputGroupsOrderBy>>;
};

export type ProjectFileRecordingGroupMetadataByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileRecordingGroupMetadatumCondition>;
  filter?: InputMaybe<FileRecordingGroupMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileRecordingGroupMetadataOrderBy>>;
};

export type ProjectFileRecordingGroupsByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileRecordingGroupCondition>;
  filter?: InputMaybe<FileRecordingGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileRecordingGroupsOrderBy>>;
};

export type ProjectFilesByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileCondition>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type ProjectMetadataByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MetadatumCondition>;
  filter?: InputMaybe<MetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MetadataOrderBy>>;
};

export type ProjectMetadataValuesByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MetadataValueCondition>;
  filter?: InputMaybe<MetadataValueFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MetadataValuesOrderBy>>;
};

export type ProjectOutputGroupFilesByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OutputGroupFileCondition>;
  filter?: InputMaybe<OutputGroupFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OutputGroupFilesOrderBy>>;
};

export type ProjectProjectClonedNotificationsByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProjectClonedNotificationCondition>;
  filter?: InputMaybe<ProjectClonedNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProjectClonedNotificationsOrderBy>>;
};

export type ProjectProjectSharedNotificationsByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProjectSharedNotificationCondition>;
  filter?: InputMaybe<ProjectSharedNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProjectSharedNotificationsOrderBy>>;
};

export type ProjectRecordingGroupFilesByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<RecordingGroupFileCondition>;
  filter?: InputMaybe<RecordingGroupFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RecordingGroupFilesOrderBy>>;
};

export type ProjectTasksByProjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskCondition>;
  filter?: InputMaybe<TaskFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
};

/** Input for the nested mutation of `project` in the `ProjectInput` mutation. */
export type ProjectCloneSourceId5F0B62C5FkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
};

/** The `applicationUser` to be created by this mutation. */
export type ProjectClonedNotifUserId4Fd693D7FkApplicatiApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `ProjectClonedNotificationInput` mutation. */
export type ProjectClonedNotifUserId4Fd693D7FkApplicatiInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `projectClonedNotification` in the `ApplicationUserInput` mutation. */
export type ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput = {
  /** The primary key(s) for `projectClonedNotification` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ProjectClonedNotificationProjectClonedNotificationPkeyConnect>
  >;
  /** The primary key(s) and patch data for `projectClonedNotification` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingProjectClonedNotificationPkeyUpdate>
  >;
};

export type ProjectClonedNotification = {
  __typename: "ProjectClonedNotification";
  /** Reads a single `ApplicationUser` that is related to this `ProjectClonedNotification`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  id: Scalars["UUID"]["output"];
  /** Reads a single `Notification` that is related to this `ProjectClonedNotification`. */
  notificationById: Maybe<Notification>;
  /** Reads a single `Project` that is related to this `ProjectClonedNotification`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  userId: Scalars["UUID"]["output"];
};

/**
 * A condition to be used against `ProjectClonedNotification` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ProjectClonedNotificationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `ProjectClonedNotification` object types. All fields are combined with a logical ‘and.’ */
export type ProjectClonedNotificationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectClonedNotificationFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectClonedNotificationFilter>;
  /** Filter by the object’s `notificationById` relation. */
  notificationById?: InputMaybe<NotificationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectClonedNotificationFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** Input for the nested mutation of `notification` in the `ProjectClonedNotificationInput` mutation. */
export type ProjectClonedNotificationIdD235A018FkNotificationIdInput = {
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectById?: InputMaybe<NotificationNotificationPkeyConnect>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateById?: InputMaybe<NotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdUsingNotificationPkeyUpdate>;
};

/** Input for the nested mutation of `projectClonedNotification` in the `NotificationInput` mutation. */
export type ProjectClonedNotificationIdD235A018FkNotificationIdInverseInput = {
  /** The primary key(s) for `projectClonedNotification` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectClonedNotificationProjectClonedNotificationPkeyConnect>;
  /** The primary key(s) and patch data for `projectClonedNotification` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdUsingProjectClonedNotificationPkeyUpdate>;
};

/** The fields on `projectClonedNotification` to look up the row to update. */
export type ProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingProjectClonedNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `projectClonedNotification` being updated. */
    projectClonedNotificationPatch: UpdateProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiPatch;
  };

/** The fields on `projectClonedNotification` to look up the row to update. */
export type ProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdUsingProjectClonedNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `projectClonedNotification` being updated. */
    projectClonedNotificationPatch: UpdateProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdPatch;
  };

/** The fields on `projectClonedNotification` to look up the row to update. */
export type ProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdUsingProjectClonedNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `projectClonedNotification` being updated. */
    projectClonedNotificationPatch: UpdateProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdPatch;
  };

/** The fields on `projectClonedNotification` to look up the row to connect. */
export type ProjectClonedNotificationProjectClonedNotificationPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** Input for the nested mutation of `project` in the `ProjectClonedNotificationInput` mutation. */
export type ProjectClonedNotificationProjectIdF6267374FkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `projectClonedNotification` in the `ProjectInput` mutation. */
export type ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput =
  {
    /** The primary key(s) for `projectClonedNotification` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<ProjectClonedNotificationProjectClonedNotificationPkeyConnect>
    >;
    /** The primary key(s) and patch data for `projectClonedNotification` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<ProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdUsingProjectClonedNotificationPkeyUpdate>
    >;
  };

/** A connection to a list of `ProjectClonedNotification` values. */
export type ProjectClonedNotificationsConnection = {
  __typename: "ProjectClonedNotificationsConnection";
  /** A list of edges which contains the `ProjectClonedNotification` and cursor to aid in pagination. */
  edges: Array<ProjectClonedNotificationsEdge>;
  /** A list of `ProjectClonedNotification` objects. */
  nodes: Array<ProjectClonedNotification>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectClonedNotification` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ProjectClonedNotification` edge in the connection. */
export type ProjectClonedNotificationsEdge = {
  __typename: "ProjectClonedNotificationsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ProjectClonedNotification` at the end of the edge. */
  node: ProjectClonedNotification;
};

/** Methods to use when ordering `ProjectClonedNotification`. */
export enum ProjectClonedNotificationsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** A condition to be used against `Project` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProjectCondition = {
  /** Checks for equality with the object’s `dateArchived` field. */
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateDataDeleted` field. */
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateDeleted` field. */
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `defaultUserAccessLevel` field. */
  defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `icon` field. */
  icon?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `iconColor` field. */
  iconColor?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `iconText` field. */
  iconText?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `prefix` field. */
  prefix?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `shortDescription` field. */
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `Project` object types. All fields are combined with a logical ‘and.’ */
export type ProjectFilter = {
  /** Filter by the object’s `analysisTableGroupsByProjectId` relation. */
  analysisTableGroupsByProjectId?: InputMaybe<ProjectToManyAnalysisTableGroupFilter>;
  /** Some related `analysisTableGroupsByProjectId` exist. */
  analysisTableGroupsByProjectIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `analysisTableRowAttachResultsByProjectId` relation. */
  analysisTableRowAttachResultsByProjectId?: InputMaybe<ProjectToManyAnalysisTableRowAttachResultFilter>;
  /** Some related `analysisTableRowAttachResultsByProjectId` exist. */
  analysisTableRowAttachResultsByProjectIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `analysisTableRowsByProjectId` relation. */
  analysisTableRowsByProjectId?: InputMaybe<ProjectToManyAnalysisTableRowFilter>;
  /** Some related `analysisTableRowsByProjectId` exist. */
  analysisTableRowsByProjectIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `analysisTablesByProjectId` relation. */
  analysisTablesByProjectId?: InputMaybe<ProjectToManyAnalysisTableFilter>;
  /** Some related `analysisTablesByProjectId` exist. */
  analysisTablesByProjectIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `datasetMetadataByProjectId` relation. */
  datasetMetadataByProjectId?: InputMaybe<ProjectToManyDatasetMetadatumFilter>;
  /** Some related `datasetMetadataByProjectId` exist. */
  datasetMetadataByProjectIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `datasetRecordingsTableColumnOrdersByProjectId` relation. */
  datasetRecordingsTableColumnOrdersByProjectId?: InputMaybe<ProjectToManyDatasetRecordingsTableColumnOrderFilter>;
  /** Some related `datasetRecordingsTableColumnOrdersByProjectId` exist. */
  datasetRecordingsTableColumnOrdersByProjectIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `datasetRecordingsTableColumnsByProjectId` relation. */
  datasetRecordingsTableColumnsByProjectId?: InputMaybe<ProjectToManyDatasetRecordingsTableColumnFilter>;
  /** Some related `datasetRecordingsTableColumnsByProjectId` exist. */
  datasetRecordingsTableColumnsByProjectIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `datasetRecordingsTablesByProjectId` relation. */
  datasetRecordingsTablesByProjectId?: InputMaybe<ProjectToManyDatasetRecordingsTableFilter>;
  /** Some related `datasetRecordingsTablesByProjectId` exist. */
  datasetRecordingsTablesByProjectIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `datasetVersionsByProjectId` relation. */
  datasetVersionsByProjectId?: InputMaybe<ProjectToManyDatasetVersionFilter>;
  /** Some related `datasetVersionsByProjectId` exist. */
  datasetVersionsByProjectIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `datasetsByProjectId` relation. */
  datasetsByProjectId?: InputMaybe<ProjectToManyDatasetFilter>;
  /** Some related `datasetsByProjectId` exist. */
  datasetsByProjectIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `dateArchived` field. */
  dateArchived?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateDataDeleted` field. */
  dateDataDeleted?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateDeleted` field. */
  dateDeleted?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `defaultUserAccessLevel` field. */
  defaultUserAccessLevel?: InputMaybe<IntFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fileActivitiesByProjectId` relation. */
  fileActivitiesByProjectId?: InputMaybe<ProjectToManyFileActivityFilter>;
  /** Some related `fileActivitiesByProjectId` exist. */
  fileActivitiesByProjectIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fileMetadataByProjectId` relation. */
  fileMetadataByProjectId?: InputMaybe<ProjectToManyFileMetadatumFilter>;
  /** Some related `fileMetadataByProjectId` exist. */
  fileMetadataByProjectIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fileOutputGroupMetadataByProjectId` relation. */
  fileOutputGroupMetadataByProjectId?: InputMaybe<ProjectToManyFileOutputGroupMetadatumFilter>;
  /** Some related `fileOutputGroupMetadataByProjectId` exist. */
  fileOutputGroupMetadataByProjectIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `fileOutputGroupsByProjectId` relation. */
  fileOutputGroupsByProjectId?: InputMaybe<ProjectToManyFileOutputGroupFilter>;
  /** Some related `fileOutputGroupsByProjectId` exist. */
  fileOutputGroupsByProjectIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fileRecordingGroupMetadataByProjectId` relation. */
  fileRecordingGroupMetadataByProjectId?: InputMaybe<ProjectToManyFileRecordingGroupMetadatumFilter>;
  /** Some related `fileRecordingGroupMetadataByProjectId` exist. */
  fileRecordingGroupMetadataByProjectIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `fileRecordingGroupsByProjectId` relation. */
  fileRecordingGroupsByProjectId?: InputMaybe<ProjectToManyFileRecordingGroupFilter>;
  /** Some related `fileRecordingGroupsByProjectId` exist. */
  fileRecordingGroupsByProjectIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `filesByProjectId` relation. */
  filesByProjectId?: InputMaybe<ProjectToManyFileFilter>;
  /** Some related `filesByProjectId` exist. */
  filesByProjectIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `icon` field. */
  icon?: InputMaybe<StringFilter>;
  /** Filter by the object’s `iconColor` field. */
  iconColor?: InputMaybe<StringFilter>;
  /** Filter by the object’s `iconText` field. */
  iconText?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metadataByProjectId` relation. */
  metadataByProjectId?: InputMaybe<ProjectToManyMetadatumFilter>;
  /** Some related `metadataByProjectId` exist. */
  metadataByProjectIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `metadataValuesByProjectId` relation. */
  metadataValuesByProjectId?: InputMaybe<ProjectToManyMetadataValueFilter>;
  /** Some related `metadataValuesByProjectId` exist. */
  metadataValuesByProjectIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectFilter>>;
  /** Filter by the object’s `outputGroupFilesByProjectId` relation. */
  outputGroupFilesByProjectId?: InputMaybe<ProjectToManyOutputGroupFileFilter>;
  /** Some related `outputGroupFilesByProjectId` exist. */
  outputGroupFilesByProjectIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `prefix` field. */
  prefix?: InputMaybe<StringFilter>;
  /** Filter by the object’s `projectClonedNotificationsByProjectId` relation. */
  projectClonedNotificationsByProjectId?: InputMaybe<ProjectToManyProjectClonedNotificationFilter>;
  /** Some related `projectClonedNotificationsByProjectId` exist. */
  projectClonedNotificationsByProjectIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `projectSharedNotificationsByProjectId` relation. */
  projectSharedNotificationsByProjectId?: InputMaybe<ProjectToManyProjectSharedNotificationFilter>;
  /** Some related `projectSharedNotificationsByProjectId` exist. */
  projectSharedNotificationsByProjectIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `recordingGroupFilesByProjectId` relation. */
  recordingGroupFilesByProjectId?: InputMaybe<ProjectToManyRecordingGroupFileFilter>;
  /** Some related `recordingGroupFilesByProjectId` exist. */
  recordingGroupFilesByProjectIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `shortDescription` field. */
  shortDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tasksByProjectId` relation. */
  tasksByProjectId?: InputMaybe<ProjectToManyTaskFilter>;
  /** Some related `tasksByProjectId` exist. */
  tasksByProjectIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnFileForFileProjectId3Ecca975FkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnFileForFileProjectId3Ecca975FkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnFileForFileProjectId3Ecca975FkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnFileForFileProjectId3Ecca975FkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdPatch;
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnTaskForTaskProjectId963D6354FkProjectIdUsingProjectKeyTenantUniqueUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnTaskForTaskProjectId963D6354FkProjectIdPatch;
    tenantId: Scalars["Int"]["input"];
  };

/** The fields on `project` to look up the row to update. */
export type ProjectOnTaskForTaskProjectId963D6354FkProjectIdUsingProjectPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `project` being updated. */
    projectPatch: UpdateProjectOnTaskForTaskProjectId963D6354FkProjectIdPatch;
  };

/** The fields on `project` to look up the row to connect. */
export type ProjectProjectKeyTenantUniqueConnect = {
  key: Scalars["String"]["input"];
  tenantId: Scalars["Int"]["input"];
};

/** The fields on `project` to look up the row to connect. */
export type ProjectProjectPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The `applicationUser` to be created by this mutation. */
export type ProjectSharedNotifSharerIdAbe16C5BFkApplicatiApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `ProjectSharedNotificationInput` mutation. */
export type ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `projectSharedNotification` in the `ApplicationUserInput` mutation. */
export type ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput = {
  /** The primary key(s) for `projectSharedNotification` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ProjectSharedNotificationProjectSharedNotificationPkeyConnect>
  >;
  /** The primary key(s) and patch data for `projectSharedNotification` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingProjectSharedNotificationPkeyUpdate>
  >;
};

/** The `applicationUser` to be created by this mutation. */
export type ProjectSharedNotifUserId9A19122FFkApplicatiApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `ProjectSharedNotificationInput` mutation. */
export type ProjectSharedNotifUserId9A19122FFkApplicatiInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `projectSharedNotification` in the `ApplicationUserInput` mutation. */
export type ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput = {
  /** The primary key(s) for `projectSharedNotification` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ProjectSharedNotificationProjectSharedNotificationPkeyConnect>
  >;
  /** The primary key(s) and patch data for `projectSharedNotification` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingProjectSharedNotificationPkeyUpdate>
  >;
};

export type ProjectSharedNotification = {
  __typename: "ProjectSharedNotification";
  accessLevel: Scalars["Int"]["output"];
  /** Reads a single `ApplicationUser` that is related to this `ProjectSharedNotification`. */
  applicationUserBySharerId: Maybe<ApplicationUser>;
  /** Reads a single `ApplicationUser` that is related to this `ProjectSharedNotification`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  id: Scalars["UUID"]["output"];
  /** Reads a single `Notification` that is related to this `ProjectSharedNotification`. */
  notificationById: Maybe<Notification>;
  /** Reads a single `Project` that is related to this `ProjectSharedNotification`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  sharerId: Scalars["UUID"]["output"];
  userId: Scalars["UUID"]["output"];
};

/**
 * A condition to be used against `ProjectSharedNotification` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ProjectSharedNotificationCondition = {
  /** Checks for equality with the object’s `accessLevel` field. */
  accessLevel?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `sharerId` field. */
  sharerId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `ProjectSharedNotification` object types. All fields are combined with a logical ‘and.’ */
export type ProjectSharedNotificationFilter = {
  /** Filter by the object’s `accessLevel` field. */
  accessLevel?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectSharedNotificationFilter>>;
  /** Filter by the object’s `applicationUserBySharerId` relation. */
  applicationUserBySharerId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectSharedNotificationFilter>;
  /** Filter by the object’s `notificationById` relation. */
  notificationById?: InputMaybe<NotificationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectSharedNotificationFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `sharerId` field. */
  sharerId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** Input for the nested mutation of `notification` in the `ProjectSharedNotificationInput` mutation. */
export type ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInput = {
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectById?: InputMaybe<NotificationNotificationPkeyConnect>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateById?: InputMaybe<NotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdUsingNotificationPkeyUpdate>;
};

/** Input for the nested mutation of `projectSharedNotification` in the `NotificationInput` mutation. */
export type ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInverseInput = {
  /** The primary key(s) for `projectSharedNotification` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectSharedNotificationProjectSharedNotificationPkeyConnect>;
  /** The primary key(s) and patch data for `projectSharedNotification` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdUsingProjectSharedNotificationPkeyUpdate>;
};

/** The fields on `projectSharedNotification` to look up the row to update. */
export type ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingProjectSharedNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `projectSharedNotification` being updated. */
    projectSharedNotificationPatch: UpdateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiPatch;
  };

/** The fields on `projectSharedNotification` to look up the row to update. */
export type ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingProjectSharedNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `projectSharedNotification` being updated. */
    projectSharedNotificationPatch: UpdateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiPatch;
  };

/** The fields on `projectSharedNotification` to look up the row to update. */
export type ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdUsingProjectSharedNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `projectSharedNotification` being updated. */
    projectSharedNotificationPatch: UpdateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdPatch;
  };

/** The fields on `projectSharedNotification` to look up the row to update. */
export type ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdUsingProjectSharedNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `projectSharedNotification` being updated. */
    projectSharedNotificationPatch: UpdateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdPatch;
  };

/** Input for the nested mutation of `project` in the `ProjectSharedNotificationInput` mutation. */
export type ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `projectSharedNotification` in the `ProjectInput` mutation. */
export type ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput =
  {
    /** The primary key(s) for `projectSharedNotification` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<ProjectSharedNotificationProjectSharedNotificationPkeyConnect>
    >;
    /** The primary key(s) and patch data for `projectSharedNotification` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdUsingProjectSharedNotificationPkeyUpdate>
    >;
  };

/** The fields on `projectSharedNotification` to look up the row to connect. */
export type ProjectSharedNotificationProjectSharedNotificationPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** A connection to a list of `ProjectSharedNotification` values. */
export type ProjectSharedNotificationsConnection = {
  __typename: "ProjectSharedNotificationsConnection";
  /** A list of edges which contains the `ProjectSharedNotification` and cursor to aid in pagination. */
  edges: Array<ProjectSharedNotificationsEdge>;
  /** A list of `ProjectSharedNotification` objects. */
  nodes: Array<ProjectSharedNotification>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectSharedNotification` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ProjectSharedNotification` edge in the connection. */
export type ProjectSharedNotificationsEdge = {
  __typename: "ProjectSharedNotificationsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ProjectSharedNotification` at the end of the edge. */
  node: ProjectSharedNotification;
};

/** Methods to use when ordering `ProjectSharedNotification`. */
export enum ProjectSharedNotificationsOrderBy {
  AccessLevelAsc = "ACCESS_LEVEL_ASC",
  AccessLevelDesc = "ACCESS_LEVEL_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  SharerIdAsc = "SHARER_ID_ASC",
  SharerIdDesc = "SHARER_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** Input for the nested mutation of `tenant` in the `ProjectInput` mutation. */
export type ProjectTenantId03F0Ca53FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `project` in the `TenantInput` mutation. */
export type ProjectTenantId03F0Ca53FkTenantIdInverseInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectProjectPkeyConnect>>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<
    Array<ProjectProjectKeyTenantUniqueConnect>
  >;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingProjectPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<
    Array<ProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingProjectKeyTenantUniqueUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type ProjectTenantId03F0Ca53FkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** A filter to be used against many `AnalysisTable` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyAnalysisTableFilter = {
  /** Every related `AnalysisTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AnalysisTableFilter>;
  /** No related `AnalysisTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AnalysisTableFilter>;
  /** Some related `AnalysisTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AnalysisTableFilter>;
};

/** A filter to be used against many `AnalysisTableGroup` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyAnalysisTableGroupFilter = {
  /** Every related `AnalysisTableGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AnalysisTableGroupFilter>;
  /** No related `AnalysisTableGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AnalysisTableGroupFilter>;
  /** Some related `AnalysisTableGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AnalysisTableGroupFilter>;
};

/** A filter to be used against many `AnalysisTableRowAttachResult` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyAnalysisTableRowAttachResultFilter = {
  /** Every related `AnalysisTableRowAttachResult` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AnalysisTableRowAttachResultFilter>;
  /** No related `AnalysisTableRowAttachResult` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AnalysisTableRowAttachResultFilter>;
  /** Some related `AnalysisTableRowAttachResult` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AnalysisTableRowAttachResultFilter>;
};

/** A filter to be used against many `AnalysisTableRow` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyAnalysisTableRowFilter = {
  /** Every related `AnalysisTableRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AnalysisTableRowFilter>;
  /** No related `AnalysisTableRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AnalysisTableRowFilter>;
  /** Some related `AnalysisTableRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AnalysisTableRowFilter>;
};

/** A filter to be used against many `Dataset` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyDatasetFilter = {
  /** Every related `Dataset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetFilter>;
  /** No related `Dataset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetFilter>;
  /** Some related `Dataset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetFilter>;
};

/** A filter to be used against many `DatasetMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyDatasetMetadatumFilter = {
  /** Every related `DatasetMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetMetadatumFilter>;
  /** No related `DatasetMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetMetadatumFilter>;
  /** Some related `DatasetMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetMetadatumFilter>;
};

/** A filter to be used against many `DatasetRecordingsTableColumn` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyDatasetRecordingsTableColumnFilter = {
  /** Every related `DatasetRecordingsTableColumn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetRecordingsTableColumnFilter>;
  /** No related `DatasetRecordingsTableColumn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetRecordingsTableColumnFilter>;
  /** Some related `DatasetRecordingsTableColumn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetRecordingsTableColumnFilter>;
};

/** A filter to be used against many `DatasetRecordingsTableColumnOrder` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyDatasetRecordingsTableColumnOrderFilter = {
  /** Every related `DatasetRecordingsTableColumnOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetRecordingsTableColumnOrderFilter>;
  /** No related `DatasetRecordingsTableColumnOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetRecordingsTableColumnOrderFilter>;
  /** Some related `DatasetRecordingsTableColumnOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetRecordingsTableColumnOrderFilter>;
};

/** A filter to be used against many `DatasetRecordingsTable` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyDatasetRecordingsTableFilter = {
  /** Every related `DatasetRecordingsTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetRecordingsTableFilter>;
  /** No related `DatasetRecordingsTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetRecordingsTableFilter>;
  /** Some related `DatasetRecordingsTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetRecordingsTableFilter>;
};

/** A filter to be used against many `DatasetVersion` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyDatasetVersionFilter = {
  /** Every related `DatasetVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetVersionFilter>;
  /** No related `DatasetVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetVersionFilter>;
  /** Some related `DatasetVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetVersionFilter>;
};

/** A filter to be used against many `FileActivity` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyFileActivityFilter = {
  /** Every related `FileActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileActivityFilter>;
  /** No related `FileActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileActivityFilter>;
  /** Some related `FileActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileActivityFilter>;
};

/** A filter to be used against many `File` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyFileFilter = {
  /** Every related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileFilter>;
  /** No related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileFilter>;
  /** Some related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileFilter>;
};

/** A filter to be used against many `FileMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyFileMetadatumFilter = {
  /** Every related `FileMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileMetadatumFilter>;
  /** No related `FileMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileMetadatumFilter>;
  /** Some related `FileMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileMetadatumFilter>;
};

/** A filter to be used against many `FileOutputGroup` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyFileOutputGroupFilter = {
  /** Every related `FileOutputGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileOutputGroupFilter>;
  /** No related `FileOutputGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileOutputGroupFilter>;
  /** Some related `FileOutputGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileOutputGroupFilter>;
};

/** A filter to be used against many `FileOutputGroupMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyFileOutputGroupMetadatumFilter = {
  /** Every related `FileOutputGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileOutputGroupMetadatumFilter>;
  /** No related `FileOutputGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileOutputGroupMetadatumFilter>;
  /** Some related `FileOutputGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileOutputGroupMetadatumFilter>;
};

/** A filter to be used against many `FileRecordingGroup` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyFileRecordingGroupFilter = {
  /** Every related `FileRecordingGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileRecordingGroupFilter>;
  /** No related `FileRecordingGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileRecordingGroupFilter>;
  /** Some related `FileRecordingGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileRecordingGroupFilter>;
};

/** A filter to be used against many `FileRecordingGroupMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyFileRecordingGroupMetadatumFilter = {
  /** Every related `FileRecordingGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileRecordingGroupMetadatumFilter>;
  /** No related `FileRecordingGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileRecordingGroupMetadatumFilter>;
  /** Some related `FileRecordingGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileRecordingGroupMetadatumFilter>;
};

/** A filter to be used against many `MetadataValue` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyMetadataValueFilter = {
  /** Every related `MetadataValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MetadataValueFilter>;
  /** No related `MetadataValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MetadataValueFilter>;
  /** Some related `MetadataValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MetadataValueFilter>;
};

/** A filter to be used against many `Metadatum` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyMetadatumFilter = {
  /** Every related `Metadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MetadatumFilter>;
  /** No related `Metadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MetadatumFilter>;
  /** Some related `Metadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MetadatumFilter>;
};

/** A filter to be used against many `OutputGroupFile` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyOutputGroupFileFilter = {
  /** Every related `OutputGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<OutputGroupFileFilter>;
  /** No related `OutputGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<OutputGroupFileFilter>;
  /** Some related `OutputGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<OutputGroupFileFilter>;
};

/** A filter to be used against many `ProjectClonedNotification` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectClonedNotificationFilter = {
  /** Every related `ProjectClonedNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectClonedNotificationFilter>;
  /** No related `ProjectClonedNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectClonedNotificationFilter>;
  /** Some related `ProjectClonedNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectClonedNotificationFilter>;
};

/** A filter to be used against many `ProjectSharedNotification` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectSharedNotificationFilter = {
  /** Every related `ProjectSharedNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectSharedNotificationFilter>;
  /** No related `ProjectSharedNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectSharedNotificationFilter>;
  /** Some related `ProjectSharedNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectSharedNotificationFilter>;
};

/** A filter to be used against many `RecordingGroupFile` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyRecordingGroupFileFilter = {
  /** Every related `RecordingGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<RecordingGroupFileFilter>;
  /** No related `RecordingGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<RecordingGroupFileFilter>;
  /** Some related `RecordingGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<RecordingGroupFileFilter>;
};

/** A filter to be used against many `Task` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyTaskFilter = {
  /** Every related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TaskFilter>;
  /** No related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TaskFilter>;
  /** Some related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TaskFilter>;
};

/** The `applicationUser` to be created by this mutation. */
export type ProjectUserIdB5Fbb914FkApplicationUserIdApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `ProjectInput` mutation. */
export type ProjectUserIdB5Fbb914FkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `project` in the `ApplicationUserInput` mutation. */
export type ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectProjectPkeyConnect>>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<
    Array<ProjectProjectKeyTenantUniqueConnect>
  >;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingProjectPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<
    Array<ProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingProjectKeyTenantUniqueUpdate>
  >;
};

/** A connection to a list of `Project` values. */
export type ProjectsConnection = {
  __typename: "ProjectsConnection";
  /** A list of edges which contains the `Project` and cursor to aid in pagination. */
  edges: Array<ProjectsEdge>;
  /** A list of `Project` objects. */
  nodes: Array<Project>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Project` edge in the connection. */
export type ProjectsEdge = {
  __typename: "ProjectsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Project` at the end of the edge. */
  node: Project;
};

/** Methods to use when ordering `Project`. */
export enum ProjectsOrderBy {
  DateArchivedAsc = "DATE_ARCHIVED_ASC",
  DateArchivedDesc = "DATE_ARCHIVED_DESC",
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  DateDataDeletedAsc = "DATE_DATA_DELETED_ASC",
  DateDataDeletedDesc = "DATE_DATA_DELETED_DESC",
  DateDeletedAsc = "DATE_DELETED_ASC",
  DateDeletedDesc = "DATE_DELETED_DESC",
  DefaultUserAccessLevelAsc = "DEFAULT_USER_ACCESS_LEVEL_ASC",
  DefaultUserAccessLevelDesc = "DEFAULT_USER_ACCESS_LEVEL_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  IconAsc = "ICON_ASC",
  IconColorAsc = "ICON_COLOR_ASC",
  IconColorDesc = "ICON_COLOR_DESC",
  IconDesc = "ICON_DESC",
  IconTextAsc = "ICON_TEXT_ASC",
  IconTextDesc = "ICON_TEXT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  KeyAsc = "KEY_ASC",
  KeyDesc = "KEY_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrefixAsc = "PREFIX_ASC",
  PrefixDesc = "PREFIX_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ShortDescriptionAsc = "SHORT_DESCRIPTION_ASC",
  ShortDescriptionDesc = "SHORT_DESCRIPTION_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** The root query type which gives access points into the data universe. */
export type Query = {
  __typename: "Query";
  /** Reads and enables pagination through a set of `AnalysisTableGroup`. */
  allAnalysisTableGroups: Maybe<AnalysisTableGroupsConnection>;
  /** Reads and enables pagination through a set of `AnalysisTableRowAttachResult`. */
  allAnalysisTableRowAttachResults: Maybe<AnalysisTableRowAttachResultsConnection>;
  /** Reads and enables pagination through a set of `AnalysisTableRow`. */
  allAnalysisTableRows: Maybe<AnalysisTableRowsConnection>;
  /** Reads and enables pagination through a set of `AnalysisTable`. */
  allAnalysisTables: Maybe<AnalysisTablesConnection>;
  /** Reads and enables pagination through a set of `ApplicationUserGroup`. */
  allApplicationUserGroups: Maybe<ApplicationUserGroupsConnection>;
  /** Reads and enables pagination through a set of `ApplicationUserMembership`. */
  allApplicationUserMemberships: Maybe<ApplicationUserMembershipsConnection>;
  /** Reads and enables pagination through a set of `ApplicationUserMetadatum`. */
  allApplicationUserMetadata: Maybe<ApplicationUserMetadataConnection>;
  /** Reads and enables pagination through a set of `ApplicationUserSession`. */
  allApplicationUserSessions: Maybe<ApplicationUserSessionsConnection>;
  /** Reads and enables pagination through a set of `ApplicationUserUserPermission`. */
  allApplicationUserUserPermissions: Maybe<ApplicationUserUserPermissionsConnection>;
  /** Reads and enables pagination through a set of `AuthGroupPermission`. */
  allAuthGroupPermissions: Maybe<AuthGroupPermissionsConnection>;
  /** Reads and enables pagination through a set of `AuthGroup`. */
  allAuthGroups: Maybe<AuthGroupsConnection>;
  /** Reads and enables pagination through a set of `AuthPermission`. */
  allAuthPermissions: Maybe<AuthPermissionsConnection>;
  /** Reads and enables pagination through a set of `AvailableNonRestrictedProject`. */
  allAvailableNonRestrictedProjects: Maybe<AvailableNonRestrictedProjectsConnection>;
  /** Reads and enables pagination through a set of `Avatar`. */
  allAvatars: Maybe<AvatarsConnection>;
  /** Reads and enables pagination through a set of `AwsBatchTask`. */
  allAwsBatchTasks: Maybe<AwsBatchTasksConnection>;
  /** Reads and enables pagination through a set of `AwsCognitoUser`. */
  allAwsCognitoUsers: Maybe<AwsCognitoUsersConnection>;
  /** Reads and enables pagination through a set of `AwsEcrImage`. */
  allAwsEcrImages: Maybe<AwsEcrImagesConnection>;
  /** Reads and enables pagination through a set of `AwsMockBatchTask`. */
  allAwsMockBatchTasks: Maybe<AwsMockBatchTasksConnection>;
  /** Reads and enables pagination through a set of `AwsMockEcrImage`. */
  allAwsMockEcrImages: Maybe<AwsMockEcrImagesConnection>;
  /** Reads and enables pagination through a set of `AwsMockS3File`. */
  allAwsMockS3Files: Maybe<AwsMockS3FilesConnection>;
  /** Reads and enables pagination through a set of `AwsS3File`. */
  allAwsS3Files: Maybe<AwsS3FilesConnection>;
  /** Reads and enables pagination through a set of `ContainerImage`. */
  allContainerImages: Maybe<ContainerImagesConnection>;
  /** Reads and enables pagination through a set of `Cost`. */
  allCosts: Maybe<CostsConnection>;
  /** Reads and enables pagination through a set of `CurrentApplicationUser`. */
  allCurrentApplicationUsers: Maybe<CurrentApplicationUsersConnection>;
  /** Reads and enables pagination through a set of `CurrentUserMembership`. */
  allCurrentUserMemberships: Maybe<CurrentUserMembershipsConnection>;
  /** Reads and enables pagination through a set of `DatasetMetadatum`. */
  allDatasetMetadata: Maybe<DatasetMetadataConnection>;
  /** Reads and enables pagination through a set of `DatasetRecordingsTableColumnOrder`. */
  allDatasetRecordingsTableColumnOrders: Maybe<DatasetRecordingsTableColumnOrdersConnection>;
  /** Reads and enables pagination through a set of `DatasetRecordingsTableColumn`. */
  allDatasetRecordingsTableColumns: Maybe<DatasetRecordingsTableColumnsConnection>;
  /** Reads and enables pagination through a set of `DatasetRecordingsTable`. */
  allDatasetRecordingsTables: Maybe<DatasetRecordingsTablesConnection>;
  /** Reads and enables pagination through a set of `DatasetVersion`. */
  allDatasetVersions: Maybe<DatasetVersionsConnection>;
  /** Reads and enables pagination through a set of `Dataset`. */
  allDatasets: Maybe<DatasetsConnection>;
  /** Reads and enables pagination through a set of `DjangoAdminLog`. */
  allDjangoAdminLogs: Maybe<DjangoAdminLogsConnection>;
  /** Reads and enables pagination through a set of `DjangoContentType`. */
  allDjangoContentTypes: Maybe<DjangoContentTypesConnection>;
  /** Reads and enables pagination through a set of `DjangoMigration`. */
  allDjangoMigrations: Maybe<DjangoMigrationsConnection>;
  /** Reads and enables pagination through a set of `DjangoSesSesstat`. */
  allDjangoSesSesstats: Maybe<DjangoSesSesstatsConnection>;
  /** Reads and enables pagination through a set of `DjangoSession`. */
  allDjangoSessions: Maybe<DjangoSessionsConnection>;
  /** Reads and enables pagination through a set of `DrsFileexecutor`. */
  allDrsFileexecutors: Maybe<DrsFileexecutorsConnection>;
  /** Reads and enables pagination through a set of `DrsMockfileexecutor`. */
  allDrsMockfileexecutors: Maybe<DrsMockfileexecutorsConnection>;
  /** Reads and enables pagination through a set of `EulaVersionUserAcceptance`. */
  allEulaVersionUserAcceptances: Maybe<EulaVersionUserAcceptancesConnection>;
  /** Reads and enables pagination through a set of `EulaVersion`. */
  allEulaVersions: Maybe<EulaVersionsConnection>;
  /** Reads and enables pagination through a set of `ExplorerQuery`. */
  allExplorerQueries: Maybe<ExplorerQueriesConnection>;
  /** Reads and enables pagination through a set of `ExplorerQueryfavorite`. */
  allExplorerQueryfavorites: Maybe<ExplorerQueryfavoritesConnection>;
  /** Reads and enables pagination through a set of `ExplorerQuerylog`. */
  allExplorerQuerylogs: Maybe<ExplorerQuerylogsConnection>;
  /** Reads and enables pagination through a set of `FileActivity`. */
  allFileActivities: Maybe<FileActivitiesConnection>;
  /** Reads and enables pagination through a set of `FileMetadatum`. */
  allFileMetadata: Maybe<FileMetadataConnection>;
  /** Reads and enables pagination through a set of `FileOutputGroupMetadatum`. */
  allFileOutputGroupMetadata: Maybe<FileOutputGroupMetadataConnection>;
  /** Reads and enables pagination through a set of `FileOutputGroup`. */
  allFileOutputGroups: Maybe<FileOutputGroupsConnection>;
  /** Reads and enables pagination through a set of `FileProcessingNotification`. */
  allFileProcessingNotifications: Maybe<FileProcessingNotificationsConnection>;
  /** Reads and enables pagination through a set of `FileRecordingGroupMetadatum`. */
  allFileRecordingGroupMetadata: Maybe<FileRecordingGroupMetadataConnection>;
  /** Reads and enables pagination through a set of `FileRecordingGroup`. */
  allFileRecordingGroups: Maybe<FileRecordingGroupsConnection>;
  /** Reads and enables pagination through a set of `File`. */
  allFiles: Maybe<FilesConnection>;
  /** Reads and enables pagination through a set of `Notification`. */
  allNotifications: Maybe<NotificationsConnection>;
  /** Reads and enables pagination through a set of `OutputGroupFile`. */
  allOutputGroupFiles: Maybe<OutputGroupFilesConnection>;
  /** Reads and enables pagination through a set of `ProjectClonedNotification`. */
  allProjectClonedNotifications: Maybe<ProjectClonedNotificationsConnection>;
  /** Reads and enables pagination through a set of `ProjectSharedNotification`. */
  allProjectSharedNotifications: Maybe<ProjectSharedNotificationsConnection>;
  /** Reads and enables pagination through a set of `Project`. */
  allProjects: Maybe<ProjectsConnection>;
  /** Reads and enables pagination through a set of `RecordingGroupFile`. */
  allRecordingGroupFiles: Maybe<RecordingGroupFilesConnection>;
  /** Reads and enables pagination through a set of `RoleChangeNotification`. */
  allRoleChangeNotifications: Maybe<RoleChangeNotificationsConnection>;
  /** Reads and enables pagination through a set of `Run`. */
  allRuns: Maybe<RunsConnection>;
  /** Reads and enables pagination through a set of `Tag`. */
  allTags: Maybe<TagsConnection>;
  /** Reads and enables pagination through a set of `TaskActivity`. */
  allTaskActivities: Maybe<TaskActivitiesConnection>;
  /** Reads and enables pagination through a set of `TaskResource`. */
  allTaskResources: Maybe<TaskResourcesConnection>;
  /** Reads and enables pagination through a set of `TaskSource`. */
  allTaskSources: Maybe<TaskSourcesConnection>;
  /** Reads and enables pagination through a set of `TaskStatusLog`. */
  allTaskStatusLogs: Maybe<TaskStatusLogsConnection>;
  /** Reads and enables pagination through a set of `Task`. */
  allTasks: Maybe<TasksConnection>;
  /** Reads and enables pagination through a set of `TenantContractAllowance`. */
  allTenantContractAllowances: Maybe<TenantContractAllowancesConnection>;
  /** Reads and enables pagination through a set of `TenantContractTransaction`. */
  allTenantContractTransactions: Maybe<TenantContractTransactionsConnection>;
  /** Reads and enables pagination through a set of `TenantContract`. */
  allTenantContracts: Maybe<TenantContractsConnection>;
  /** Reads and enables pagination through a set of `TenantMockuserexecutor`. */
  allTenantMockuserexecutors: Maybe<TenantMockuserexecutorsConnection>;
  /** Reads and enables pagination through a set of `TenantMonthlyConsumedResource`. */
  allTenantMonthlyConsumedResources: Maybe<TenantMonthlyConsumedResourcesConnection>;
  /** Reads and enables pagination through a set of `TenantUserMembershipNotification`. */
  allTenantUserMembershipNotifications: Maybe<TenantUserMembershipNotificationsConnection>;
  /** Reads and enables pagination through a set of `TenantUserexecutor`. */
  allTenantUserexecutors: Maybe<TenantUserexecutorsConnection>;
  /** Reads and enables pagination through a set of `TesContainerimageexecutor`. */
  allTesContainerimageexecutors: Maybe<TesContainerimageexecutorsConnection>;
  /** Reads and enables pagination through a set of `TesMockcontainerimageexecutor`. */
  allTesMockcontainerimageexecutors: Maybe<TesMockcontainerimageexecutorsConnection>;
  /** Reads and enables pagination through a set of `TesMocktaskexecutor`. */
  allTesMocktaskexecutors: Maybe<TesMocktaskexecutorsConnection>;
  /** Reads and enables pagination through a set of `TesTaskexecutor`. */
  allTesTaskexecutors: Maybe<TesTaskexecutorsConnection>;
  /** Reads and enables pagination through a set of `ToolCategory`. */
  allToolCategories: Maybe<ToolCategoriesConnection>;
  /** Reads and enables pagination through a set of `ToolDisplayCategory`. */
  allToolDisplayCategories: Maybe<ToolDisplayCategoriesConnection>;
  /** Reads and enables pagination through a set of `ToolParameter`. */
  allToolParameters: Maybe<ToolParametersConnection>;
  /** Reads and enables pagination through a set of `ToolResource`. */
  allToolResources: Maybe<ToolResourcesConnection>;
  /** Reads and enables pagination through a set of `ToolResult`. */
  allToolResults: Maybe<ToolResultsConnection>;
  /** Reads and enables pagination through a set of `ToolSecret`. */
  allToolSecrets: Maybe<ToolSecretsConnection>;
  /** Reads and enables pagination through a set of `ToolSource`. */
  allToolSources: Maybe<ToolSourcesConnection>;
  /** Reads and enables pagination through a set of `ToolTenant`. */
  allToolTenants: Maybe<ToolTenantsConnection>;
  /** Reads and enables pagination through a set of `ToolVersion`. */
  allToolVersions: Maybe<ToolVersionsConnection>;
  /** Reads and enables pagination through a set of `ToolboxExitcode`. */
  allToolboxExitcodes: Maybe<ToolboxExitcodesConnection>;
  /** Reads and enables pagination through a set of `ToolboxTenant`. */
  allToolboxTenants: Maybe<ToolboxTenantsConnection>;
  /** Reads and enables pagination through a set of `ToolboxVersionExitcode`. */
  allToolboxVersionExitcodes: Maybe<ToolboxVersionExitcodesConnection>;
  /** Reads and enables pagination through a set of `ToolboxVersionToolVersion`. */
  allToolboxVersionToolVersions: Maybe<ToolboxVersionToolVersionsConnection>;
  /** Reads and enables pagination through a set of `ToolboxVersion`. */
  allToolboxVersions: Maybe<ToolboxVersionsConnection>;
  /** Reads and enables pagination through a set of `Toolbox`. */
  allToolboxes: Maybe<ToolboxesConnection>;
  /** Reads and enables pagination through a set of `Tool`. */
  allTools: Maybe<ToolsConnection>;
  /** Reads and enables pagination through a set of `UserAccessToProject`. */
  allUserAccessToProjects: Maybe<UserAccessToProjectsConnection>;
  /** Reads and enables pagination through a set of `WorkflowDatum`. */
  allWorkflowData: Maybe<WorkflowDataConnection>;
  /** Reads and enables pagination through a set of `WorkflowResultTag`. */
  allWorkflowResultTags: Maybe<WorkflowResultTagsConnection>;
  /** Reads and enables pagination through a set of `WorkflowResultWorkflowVersion`. */
  allWorkflowResultWorkflowVersions: Maybe<WorkflowResultWorkflowVersionsConnection>;
  /** Reads and enables pagination through a set of `WorkflowResult`. */
  allWorkflowResults: Maybe<WorkflowResultsConnection>;
  /** Reads and enables pagination through a set of `WorkflowSectionBlockSection`. */
  allWorkflowSectionBlockSections: Maybe<WorkflowSectionBlockSectionsConnection>;
  /** Reads and enables pagination through a set of `WorkflowSectionBlock`. */
  allWorkflowSectionBlocks: Maybe<WorkflowSectionBlocksConnection>;
  /** Reads and enables pagination through a set of `WorkflowSectionWorkflowVersion`. */
  allWorkflowSectionWorkflowVersions: Maybe<WorkflowSectionWorkflowVersionsConnection>;
  /** Reads and enables pagination through a set of `WorkflowSection`. */
  allWorkflowSections: Maybe<WorkflowSectionsConnection>;
  /** Reads and enables pagination through a set of `WorkflowSourceTag`. */
  allWorkflowSourceTags: Maybe<WorkflowSourceTagsConnection>;
  /** Reads and enables pagination through a set of `WorkflowSourceWorkflowVersion`. */
  allWorkflowSourceWorkflowVersions: Maybe<WorkflowSourceWorkflowVersionsConnection>;
  /** Reads and enables pagination through a set of `WorkflowSource`. */
  allWorkflowSources: Maybe<WorkflowSourcesConnection>;
  /** Reads and enables pagination through a set of `WorkflowVersion`. */
  allWorkflowVersions: Maybe<WorkflowVersionsConnection>;
  /** Reads and enables pagination through a set of `Workflow`. */
  allWorkflows: Maybe<WorkflowsConnection>;
  analysisTableById: Maybe<AnalysisTable>;
  analysisTableGroupById: Maybe<AnalysisTableGroup>;
  analysisTableRowAttachResultById: Maybe<AnalysisTableRowAttachResult>;
  analysisTableRowById: Maybe<AnalysisTableRow>;
  analysisTableRowByTaskId: Maybe<AnalysisTableRow>;
  applicationUserByEmail: Maybe<ApplicationUser>;
  applicationUserById: Maybe<ApplicationUser>;
  applicationUserByUsername: Maybe<ApplicationUser>;
  applicationUserGroupById: Maybe<ApplicationUserGroup>;
  applicationUserGroupByUserIdAndGroupId: Maybe<ApplicationUserGroup>;
  applicationUserMembershipById: Maybe<ApplicationUserMembership>;
  applicationUserMembershipByUserIdAndTenantId: Maybe<ApplicationUserMembership>;
  applicationUserMetadatumById: Maybe<ApplicationUserMetadatum>;
  applicationUserMetadatumByUserIdAndKey: Maybe<ApplicationUserMetadatum>;
  applicationUserSessionById: Maybe<ApplicationUserSession>;
  applicationUserUserPermissionById: Maybe<ApplicationUserUserPermission>;
  applicationUserUserPermissionByUserIdAndPermissionId: Maybe<ApplicationUserUserPermission>;
  assertUserAcceptedEula: Maybe<Scalars["Boolean"]["output"]>;
  assertUserCanSeeTenantInfo: Maybe<Scalars["Boolean"]["output"]>;
  assertUserCanSeeUserInfo: Maybe<Scalars["Boolean"]["output"]>;
  assertUserHasProjectSharedByTenant: Maybe<Scalars["Boolean"]["output"]>;
  assertUserHasTenantOfId: Maybe<Scalars["Boolean"]["output"]>;
  assertUserIsInvitedToTenant: Maybe<Scalars["Boolean"]["output"]>;
  authGroupById: Maybe<AuthGroup>;
  authGroupByName: Maybe<AuthGroup>;
  authGroupPermissionByGroupIdAndPermissionId: Maybe<AuthGroupPermission>;
  authGroupPermissionById: Maybe<AuthGroupPermission>;
  authPermissionByContentTypeIdAndCodename: Maybe<AuthPermission>;
  authPermissionById: Maybe<AuthPermission>;
  avatarById: Maybe<Avatar>;
  avatarByUserId: Maybe<Avatar>;
  awsBatchTaskByTaskexecutorPtrId: Maybe<AwsBatchTask>;
  awsCognitoUserByUserexecutorPtrId: Maybe<AwsCognitoUser>;
  awsEcrImageByContainerimageexecutorPtrId: Maybe<AwsEcrImage>;
  awsMockBatchTaskByAwstaskexecutorPtrId: Maybe<AwsMockBatchTask>;
  awsMockEcrImageByAwscontainerimageexecutorPtrId: Maybe<AwsMockEcrImage>;
  awsMockS3FileByAwsfileexecutorPtrId: Maybe<AwsMockS3File>;
  awsS3FileByFileexecutorPtrId: Maybe<AwsS3File>;
  containerImageByExecutorId: Maybe<ContainerImage>;
  containerImageById: Maybe<ContainerImage>;
  containerImageByNameAndTag: Maybe<ContainerImage>;
  containerImageByRepositoryAndTag: Maybe<ContainerImage>;
  containerImageByToolboxVersionId: Maybe<ContainerImage>;
  costById: Maybe<Cost>;
  datasetById: Maybe<Dataset>;
  datasetByProjectIdAndPrefix: Maybe<Dataset>;
  datasetByRecordingsTableId: Maybe<Dataset>;
  datasetByTenantIdAndKey: Maybe<Dataset>;
  datasetMetadatumById: Maybe<DatasetMetadatum>;
  datasetRecordingsTableById: Maybe<DatasetRecordingsTable>;
  datasetRecordingsTableColumnById: Maybe<DatasetRecordingsTableColumn>;
  datasetRecordingsTableColumnOrderById: Maybe<DatasetRecordingsTableColumnOrder>;
  datasetVersionById: Maybe<DatasetVersion>;
  djangoAdminLogById: Maybe<DjangoAdminLog>;
  djangoContentTypeByAppLabelAndModel: Maybe<DjangoContentType>;
  djangoContentTypeById: Maybe<DjangoContentType>;
  djangoMigrationById: Maybe<DjangoMigration>;
  djangoSesSesstatByDate: Maybe<DjangoSesSesstat>;
  djangoSesSesstatById: Maybe<DjangoSesSesstat>;
  djangoSessionBySessionKey: Maybe<DjangoSession>;
  drsFileexecutorById: Maybe<DrsFileexecutor>;
  drsMockfileexecutorByFileexecutorPtrId: Maybe<DrsMockfileexecutor>;
  eulaVersionById: Maybe<EulaVersion>;
  eulaVersionByVersion: Maybe<EulaVersion>;
  eulaVersionUserAcceptanceById: Maybe<EulaVersionUserAcceptance>;
  eulaVersionUserAcceptanceByUserIdAndEulaVersionId: Maybe<EulaVersionUserAcceptance>;
  explorerQueryById: Maybe<ExplorerQuery>;
  explorerQueryfavoriteById: Maybe<ExplorerQueryfavorite>;
  explorerQueryfavoriteByQueryIdAndUserId: Maybe<ExplorerQueryfavorite>;
  explorerQuerylogById: Maybe<ExplorerQuerylog>;
  fileActivityById: Maybe<FileActivity>;
  fileById: Maybe<File>;
  fileMetadatumById: Maybe<FileMetadatum>;
  fileOutputGroupById: Maybe<FileOutputGroup>;
  fileOutputGroupByTaskId: Maybe<FileOutputGroup>;
  fileOutputGroupMetadatumById: Maybe<FileOutputGroupMetadatum>;
  fileProcessingNotificationById: Maybe<FileProcessingNotification>;
  fileRecordingGroupById: Maybe<FileRecordingGroup>;
  fileRecordingGroupMetadatumById: Maybe<FileRecordingGroupMetadatum>;
  getCurrentUser: Maybe<ApplicationUser>;
  getCurrentUserId: Maybe<Scalars["UUID"]["output"]>;
  getTenantFromApplicationUser: Maybe<Scalars["Int"]["output"]>;
  getUserActiveSession: Maybe<ApplicationUserSession>;
  metadataValueById: Maybe<MetadataValue>;
  metadatumById: Maybe<Metadatum>;
  notificationById: Maybe<Notification>;
  outputGroupFileById: Maybe<OutputGroupFile>;
  projectById: Maybe<Project>;
  projectByKeyAndTenantId: Maybe<Project>;
  projectClonedNotificationById: Maybe<ProjectClonedNotification>;
  projectSharedNotificationById: Maybe<ProjectSharedNotification>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  recordingGroupFileById: Maybe<RecordingGroupFile>;
  roleChangeNotificationById: Maybe<RoleChangeNotification>;
  runById: Maybe<Run>;
  sessionIsActive: Scalars["Boolean"]["output"];
  sessionIsActiveSession: Maybe<Scalars["Boolean"]["output"]>;
  tagById: Maybe<Tag>;
  taskActivityById: Maybe<TaskActivity>;
  taskActivityByTaskId: Maybe<TaskActivity>;
  taskByExecutorId: Maybe<Task>;
  taskById: Maybe<Task>;
  taskResourceById: Maybe<TaskResource>;
  taskSourceById: Maybe<TaskSource>;
  taskStatusLogById: Maybe<TaskStatusLog>;
  taskStatusLogByTaskIdAndStatus: Maybe<TaskStatusLog>;
  tenantById: Maybe<Tenant>;
  tenantByKey: Maybe<Tenant>;
  tenantByName: Maybe<Tenant>;
  tenantContractAllowanceById: Maybe<TenantContractAllowance>;
  tenantContractAllowanceByTenantContractTransactionIdAndQuotaType: Maybe<TenantContractAllowance>;
  tenantContractById: Maybe<TenantContract>;
  tenantContractByName: Maybe<TenantContract>;
  tenantContractTransactionById: Maybe<TenantContractTransaction>;
  tenantMockuserexecutorByUserexecutorPtrId: Maybe<TenantMockuserexecutor>;
  tenantMonthlyConsumedResourceById: Maybe<TenantMonthlyConsumedResource>;
  tenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract: Maybe<TenantMonthlyConsumedResource>;
  tenantUserMembershipNotificationById: Maybe<TenantUserMembershipNotification>;
  tenantUserexecutorById: Maybe<TenantUserexecutor>;
  tesContainerimageexecutorById: Maybe<TesContainerimageexecutor>;
  tesMockcontainerimageexecutorByContainerimageexecutorPtrId: Maybe<TesMockcontainerimageexecutor>;
  tesMocktaskexecutorByTaskexecutorPtrId: Maybe<TesMocktaskexecutor>;
  tesTaskexecutorById: Maybe<TesTaskexecutor>;
  toolById: Maybe<Tool>;
  toolByKey: Maybe<Tool>;
  toolCategoryById: Maybe<ToolCategory>;
  toolCategoryByKey: Maybe<ToolCategory>;
  toolDisplayCategoryById: Maybe<ToolDisplayCategory>;
  toolDisplayCategoryByToolIdAndToolcategoryId: Maybe<ToolDisplayCategory>;
  toolParameterById: Maybe<ToolParameter>;
  toolResourceById: Maybe<ToolResource>;
  toolResourceByTypeAndToolVersionId: Maybe<ToolResource>;
  toolResultById: Maybe<ToolResult>;
  toolSecretById: Maybe<ToolSecret>;
  toolSourceById: Maybe<ToolSource>;
  toolTenantById: Maybe<ToolTenant>;
  toolTenantByToolIdAndTenantId: Maybe<ToolTenant>;
  toolVersionById: Maybe<ToolVersion>;
  toolVersionByToolIdAndVersion: Maybe<ToolVersion>;
  toolboxById: Maybe<Toolbox>;
  toolboxByKey: Maybe<Toolbox>;
  toolboxExitcodeByCode: Maybe<ToolboxExitcode>;
  toolboxExitcodeById: Maybe<ToolboxExitcode>;
  toolboxTenantById: Maybe<ToolboxTenant>;
  toolboxTenantByToolboxIdAndTenantId: Maybe<ToolboxTenant>;
  toolboxVersionById: Maybe<ToolboxVersion>;
  toolboxVersionByToolKeyAndToolVersionVersion: Maybe<ToolboxVersion>;
  toolboxVersionByToolboxIdAndVersion: Maybe<ToolboxVersion>;
  toolboxVersionExitcodeById: Maybe<ToolboxVersionExitcode>;
  toolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeId: Maybe<ToolboxVersionExitcode>;
  toolboxVersionToolVersionById: Maybe<ToolboxVersionToolVersion>;
  toolboxVersionToolVersionByToolboxversionIdAndToolversionId: Maybe<ToolboxVersionToolVersion>;
  userExists: Maybe<Scalars["Boolean"]["output"]>;
  userHasActiveSession: Maybe<Scalars["Boolean"]["output"]>;
  userIdIsCurrentUser: Maybe<Scalars["Boolean"]["output"]>;
  userIdIsSettingsGlobalUserId: Maybe<Scalars["Boolean"]["output"]>;
  workflowById: Maybe<Workflow>;
  workflowDatumById: Maybe<WorkflowDatum>;
  workflowResultById: Maybe<WorkflowResult>;
  workflowResultTagById: Maybe<WorkflowResultTag>;
  workflowResultTagByResultIdAndTagId: Maybe<WorkflowResultTag>;
  workflowResultWorkflowVersionById: Maybe<WorkflowResultWorkflowVersion>;
  workflowResultWorkflowVersionByResultIdAndWorkflowversionId: Maybe<WorkflowResultWorkflowVersion>;
  workflowSectionBlockById: Maybe<WorkflowSectionBlock>;
  workflowSectionBlockSectionByBlockIdAndSectionId: Maybe<WorkflowSectionBlockSection>;
  workflowSectionBlockSectionById: Maybe<WorkflowSectionBlockSection>;
  workflowSectionById: Maybe<WorkflowSection>;
  workflowSectionWorkflowVersionById: Maybe<WorkflowSectionWorkflowVersion>;
  workflowSectionWorkflowVersionBySectionIdAndWorkflowversionId: Maybe<WorkflowSectionWorkflowVersion>;
  workflowSourceById: Maybe<WorkflowSource>;
  workflowSourceTagById: Maybe<WorkflowSourceTag>;
  workflowSourceTagBySourceIdAndTagId: Maybe<WorkflowSourceTag>;
  workflowSourceWorkflowVersionById: Maybe<WorkflowSourceWorkflowVersion>;
  workflowSourceWorkflowVersionBySourceIdAndWorkflowversionId: Maybe<WorkflowSourceWorkflowVersion>;
  workflowVersionById: Maybe<WorkflowVersion>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAnalysisTableGroupsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableGroupCondition>;
  filter?: InputMaybe<AnalysisTableGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTableGroupsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAnalysisTableRowAttachResultsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableRowAttachResultCondition>;
  filter?: InputMaybe<AnalysisTableRowAttachResultFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTableRowAttachResultsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAnalysisTableRowsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableRowCondition>;
  filter?: InputMaybe<AnalysisTableRowFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTableRowsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAnalysisTablesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableCondition>;
  filter?: InputMaybe<AnalysisTableFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTablesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllApplicationUserGroupsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserGroupCondition>;
  filter?: InputMaybe<ApplicationUserGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserGroupsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllApplicationUserMembershipsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserMembershipCondition>;
  filter?: InputMaybe<ApplicationUserMembershipFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserMembershipsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllApplicationUserMetadataArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserMetadatumCondition>;
  filter?: InputMaybe<ApplicationUserMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserMetadataOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllApplicationUserSessionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserSessionCondition>;
  filter?: InputMaybe<ApplicationUserSessionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserSessionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllApplicationUserUserPermissionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserUserPermissionCondition>;
  filter?: InputMaybe<ApplicationUserUserPermissionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserUserPermissionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAuthGroupPermissionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuthGroupPermissionCondition>;
  filter?: InputMaybe<AuthGroupPermissionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuthGroupPermissionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAuthGroupsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuthGroupCondition>;
  filter?: InputMaybe<AuthGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuthGroupsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAuthPermissionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuthPermissionCondition>;
  filter?: InputMaybe<AuthPermissionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuthPermissionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAvailableNonRestrictedProjectsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AvailableNonRestrictedProjectCondition>;
  filter?: InputMaybe<AvailableNonRestrictedProjectFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AvailableNonRestrictedProjectsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAvatarsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AvatarCondition>;
  filter?: InputMaybe<AvatarFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AvatarsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAwsBatchTasksArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsBatchTaskCondition>;
  filter?: InputMaybe<AwsBatchTaskFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsBatchTasksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAwsCognitoUsersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsCognitoUserCondition>;
  filter?: InputMaybe<AwsCognitoUserFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsCognitoUsersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAwsEcrImagesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsEcrImageCondition>;
  filter?: InputMaybe<AwsEcrImageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsEcrImagesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAwsMockBatchTasksArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsMockBatchTaskCondition>;
  filter?: InputMaybe<AwsMockBatchTaskFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsMockBatchTasksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAwsMockEcrImagesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsMockEcrImageCondition>;
  filter?: InputMaybe<AwsMockEcrImageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsMockEcrImagesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAwsMockS3FilesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsMockS3FileCondition>;
  filter?: InputMaybe<AwsMockS3FileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsMockS3FilesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAwsS3FilesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsS3FileCondition>;
  filter?: InputMaybe<AwsS3FileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsS3FilesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllContainerImagesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ContainerImageCondition>;
  filter?: InputMaybe<ContainerImageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ContainerImagesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCostsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<CostCondition>;
  filter?: InputMaybe<CostFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CostsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCurrentApplicationUsersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<CurrentApplicationUserCondition>;
  filter?: InputMaybe<CurrentApplicationUserFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CurrentApplicationUsersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCurrentUserMembershipsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<CurrentUserMembershipCondition>;
  filter?: InputMaybe<CurrentUserMembershipFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CurrentUserMembershipsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllDatasetMetadataArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetMetadatumCondition>;
  filter?: InputMaybe<DatasetMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetMetadataOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllDatasetRecordingsTableColumnOrdersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetRecordingsTableColumnOrderCondition>;
  filter?: InputMaybe<DatasetRecordingsTableColumnOrderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetRecordingsTableColumnOrdersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllDatasetRecordingsTableColumnsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetRecordingsTableColumnCondition>;
  filter?: InputMaybe<DatasetRecordingsTableColumnFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetRecordingsTableColumnsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllDatasetRecordingsTablesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetRecordingsTableCondition>;
  filter?: InputMaybe<DatasetRecordingsTableFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetRecordingsTablesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllDatasetVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetVersionCondition>;
  filter?: InputMaybe<DatasetVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetVersionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllDatasetsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetCondition>;
  filter?: InputMaybe<DatasetFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllDjangoAdminLogsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DjangoAdminLogCondition>;
  filter?: InputMaybe<DjangoAdminLogFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DjangoAdminLogsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllDjangoContentTypesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DjangoContentTypeCondition>;
  filter?: InputMaybe<DjangoContentTypeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DjangoContentTypesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllDjangoMigrationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DjangoMigrationCondition>;
  filter?: InputMaybe<DjangoMigrationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DjangoMigrationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllDjangoSesSesstatsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DjangoSesSesstatCondition>;
  filter?: InputMaybe<DjangoSesSesstatFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DjangoSesSesstatsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllDjangoSessionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DjangoSessionCondition>;
  filter?: InputMaybe<DjangoSessionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DjangoSessionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllDrsFileexecutorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DrsFileexecutorCondition>;
  filter?: InputMaybe<DrsFileexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DrsFileexecutorsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllDrsMockfileexecutorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DrsMockfileexecutorCondition>;
  filter?: InputMaybe<DrsMockfileexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DrsMockfileexecutorsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllEulaVersionUserAcceptancesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<EulaVersionUserAcceptanceCondition>;
  filter?: InputMaybe<EulaVersionUserAcceptanceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<EulaVersionUserAcceptancesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllEulaVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<EulaVersionCondition>;
  filter?: InputMaybe<EulaVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<EulaVersionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllExplorerQueriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ExplorerQueryCondition>;
  filter?: InputMaybe<ExplorerQueryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExplorerQueriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllExplorerQueryfavoritesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ExplorerQueryfavoriteCondition>;
  filter?: InputMaybe<ExplorerQueryfavoriteFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExplorerQueryfavoritesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllExplorerQuerylogsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ExplorerQuerylogCondition>;
  filter?: InputMaybe<ExplorerQuerylogFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExplorerQuerylogsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFileActivitiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileActivityCondition>;
  filter?: InputMaybe<FileActivityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileActivitiesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFileMetadataArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileMetadatumCondition>;
  filter?: InputMaybe<FileMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileMetadataOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFileOutputGroupMetadataArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileOutputGroupMetadatumCondition>;
  filter?: InputMaybe<FileOutputGroupMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileOutputGroupMetadataOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFileOutputGroupsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileOutputGroupCondition>;
  filter?: InputMaybe<FileOutputGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileOutputGroupsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFileProcessingNotificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileProcessingNotificationCondition>;
  filter?: InputMaybe<FileProcessingNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileProcessingNotificationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFileRecordingGroupMetadataArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileRecordingGroupMetadatumCondition>;
  filter?: InputMaybe<FileRecordingGroupMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileRecordingGroupMetadataOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFileRecordingGroupsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileRecordingGroupCondition>;
  filter?: InputMaybe<FileRecordingGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileRecordingGroupsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFilesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileCondition>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllNotificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NotificationCondition>;
  filter?: InputMaybe<NotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NotificationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllOutputGroupFilesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OutputGroupFileCondition>;
  filter?: InputMaybe<OutputGroupFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OutputGroupFilesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllProjectClonedNotificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProjectClonedNotificationCondition>;
  filter?: InputMaybe<ProjectClonedNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProjectClonedNotificationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllProjectSharedNotificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProjectSharedNotificationCondition>;
  filter?: InputMaybe<ProjectSharedNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProjectSharedNotificationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllProjectsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllRecordingGroupFilesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<RecordingGroupFileCondition>;
  filter?: InputMaybe<RecordingGroupFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RecordingGroupFilesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllRoleChangeNotificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<RoleChangeNotificationCondition>;
  filter?: InputMaybe<RoleChangeNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RoleChangeNotificationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllRunsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<RunCondition>;
  filter?: InputMaybe<RunFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RunsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTagsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TagCondition>;
  filter?: InputMaybe<TagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTaskActivitiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskActivityCondition>;
  filter?: InputMaybe<TaskActivityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TaskActivitiesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTaskResourcesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskResourceCondition>;
  filter?: InputMaybe<TaskResourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TaskResourcesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTaskSourcesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskSourceCondition>;
  filter?: InputMaybe<TaskSourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TaskSourcesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTaskStatusLogsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskStatusLogCondition>;
  filter?: InputMaybe<TaskStatusLogFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TaskStatusLogsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTasksArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskCondition>;
  filter?: InputMaybe<TaskFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTenantContractAllowancesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantContractAllowanceCondition>;
  filter?: InputMaybe<TenantContractAllowanceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantContractAllowancesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTenantContractTransactionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantContractTransactionCondition>;
  filter?: InputMaybe<TenantContractTransactionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantContractTransactionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTenantContractsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantContractCondition>;
  filter?: InputMaybe<TenantContractFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantContractsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTenantMockuserexecutorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantMockuserexecutorCondition>;
  filter?: InputMaybe<TenantMockuserexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantMockuserexecutorsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTenantMonthlyConsumedResourcesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantMonthlyConsumedResourceCondition>;
  filter?: InputMaybe<TenantMonthlyConsumedResourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantMonthlyConsumedResourcesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTenantUserMembershipNotificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantUserMembershipNotificationCondition>;
  filter?: InputMaybe<TenantUserMembershipNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantUserMembershipNotificationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTenantUserexecutorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantUserexecutorCondition>;
  filter?: InputMaybe<TenantUserexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantUserexecutorsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTesContainerimageexecutorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TesContainerimageexecutorCondition>;
  filter?: InputMaybe<TesContainerimageexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TesContainerimageexecutorsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTesMockcontainerimageexecutorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TesMockcontainerimageexecutorCondition>;
  filter?: InputMaybe<TesMockcontainerimageexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TesMockcontainerimageexecutorsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTesMocktaskexecutorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TesMocktaskexecutorCondition>;
  filter?: InputMaybe<TesMocktaskexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TesMocktaskexecutorsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTesTaskexecutorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TesTaskexecutorCondition>;
  filter?: InputMaybe<TesTaskexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TesTaskexecutorsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllToolCategoriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolCategoryCondition>;
  filter?: InputMaybe<ToolCategoryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolCategoriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllToolDisplayCategoriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolDisplayCategoryCondition>;
  filter?: InputMaybe<ToolDisplayCategoryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolDisplayCategoriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllToolParametersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolParameterCondition>;
  filter?: InputMaybe<ToolParameterFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolParametersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllToolResourcesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolResourceCondition>;
  filter?: InputMaybe<ToolResourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolResourcesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllToolResultsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolResultCondition>;
  filter?: InputMaybe<ToolResultFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolResultsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllToolSecretsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolSecretCondition>;
  filter?: InputMaybe<ToolSecretFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolSecretsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllToolSourcesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolSourceCondition>;
  filter?: InputMaybe<ToolSourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolSourcesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllToolTenantsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolTenantCondition>;
  filter?: InputMaybe<ToolTenantFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolTenantsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllToolVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolVersionCondition>;
  filter?: InputMaybe<ToolVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolVersionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllToolboxExitcodesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxExitcodeCondition>;
  filter?: InputMaybe<ToolboxExitcodeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxExitcodesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllToolboxTenantsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxTenantCondition>;
  filter?: InputMaybe<ToolboxTenantFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxTenantsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllToolboxVersionExitcodesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxVersionExitcodeCondition>;
  filter?: InputMaybe<ToolboxVersionExitcodeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxVersionExitcodesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllToolboxVersionToolVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxVersionToolVersionCondition>;
  filter?: InputMaybe<ToolboxVersionToolVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxVersionToolVersionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllToolboxVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxVersionCondition>;
  filter?: InputMaybe<ToolboxVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxVersionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllToolboxesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxCondition>;
  filter?: InputMaybe<ToolboxFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllToolsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolCondition>;
  filter?: InputMaybe<ToolFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllUserAccessToProjectsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<UserAccessToProjectCondition>;
  filter?: InputMaybe<UserAccessToProjectFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserAccessToProjectsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllWorkflowDataArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowDatumCondition>;
  filter?: InputMaybe<WorkflowDatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowDataOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllWorkflowResultTagsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowResultTagCondition>;
  filter?: InputMaybe<WorkflowResultTagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowResultTagsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllWorkflowResultWorkflowVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowResultWorkflowVersionCondition>;
  filter?: InputMaybe<WorkflowResultWorkflowVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowResultWorkflowVersionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllWorkflowResultsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowResultCondition>;
  filter?: InputMaybe<WorkflowResultFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowResultsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllWorkflowSectionBlockSectionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSectionBlockSectionCondition>;
  filter?: InputMaybe<WorkflowSectionBlockSectionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSectionBlockSectionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllWorkflowSectionBlocksArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSectionBlockCondition>;
  filter?: InputMaybe<WorkflowSectionBlockFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSectionBlocksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllWorkflowSectionWorkflowVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSectionWorkflowVersionCondition>;
  filter?: InputMaybe<WorkflowSectionWorkflowVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSectionWorkflowVersionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllWorkflowSectionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSectionCondition>;
  filter?: InputMaybe<WorkflowSectionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSectionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllWorkflowSourceTagsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSourceTagCondition>;
  filter?: InputMaybe<WorkflowSourceTagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSourceTagsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllWorkflowSourceWorkflowVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSourceWorkflowVersionCondition>;
  filter?: InputMaybe<WorkflowSourceWorkflowVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSourceWorkflowVersionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllWorkflowSourcesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSourceCondition>;
  filter?: InputMaybe<WorkflowSourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSourcesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllWorkflowVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowVersionCondition>;
  filter?: InputMaybe<WorkflowVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowVersionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllWorkflowsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowCondition>;
  filter?: InputMaybe<WorkflowFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAnalysisTableByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAnalysisTableGroupByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAnalysisTableRowAttachResultByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAnalysisTableRowByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAnalysisTableRowByTaskIdArgs = {
  taskId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryApplicationUserByEmailArgs = {
  email: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryApplicationUserByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryApplicationUserByUsernameArgs = {
  username: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryApplicationUserGroupByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryApplicationUserGroupByUserIdAndGroupIdArgs = {
  groupId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryApplicationUserMembershipByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryApplicationUserMembershipByUserIdAndTenantIdArgs = {
  tenantId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryApplicationUserMetadatumByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryApplicationUserMetadatumByUserIdAndKeyArgs = {
  key: Scalars["String"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryApplicationUserSessionByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryApplicationUserUserPermissionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryApplicationUserUserPermissionByUserIdAndPermissionIdArgs = {
  permissionId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAssertUserCanSeeTenantInfoArgs = {
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAssertUserCanSeeUserInfoArgs = {
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAssertUserHasProjectSharedByTenantArgs = {
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAssertUserHasTenantOfIdArgs = {
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAssertUserIsInvitedToTenantArgs = {
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAuthGroupByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAuthGroupByNameArgs = {
  name: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAuthGroupPermissionByGroupIdAndPermissionIdArgs = {
  groupId: Scalars["Int"]["input"];
  permissionId: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAuthGroupPermissionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAuthPermissionByContentTypeIdAndCodenameArgs = {
  codename: Scalars["String"]["input"];
  contentTypeId: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAuthPermissionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAvatarByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAvatarByUserIdArgs = {
  userId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAwsBatchTaskByTaskexecutorPtrIdArgs = {
  taskexecutorPtrId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAwsCognitoUserByUserexecutorPtrIdArgs = {
  userexecutorPtrId: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAwsEcrImageByContainerimageexecutorPtrIdArgs = {
  containerimageexecutorPtrId: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAwsMockBatchTaskByAwstaskexecutorPtrIdArgs = {
  awstaskexecutorPtrId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAwsMockEcrImageByAwscontainerimageexecutorPtrIdArgs = {
  awscontainerimageexecutorPtrId: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAwsMockS3FileByAwsfileexecutorPtrIdArgs = {
  awsfileexecutorPtrId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAwsS3FileByFileexecutorPtrIdArgs = {
  fileexecutorPtrId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryContainerImageByExecutorIdArgs = {
  executorId: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryContainerImageByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryContainerImageByNameAndTagArgs = {
  name: Scalars["String"]["input"];
  tag: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryContainerImageByRepositoryAndTagArgs = {
  repository: Scalars["String"]["input"];
  tag: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryContainerImageByToolboxVersionIdArgs = {
  toolboxVersionId: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCostByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetByProjectIdAndPrefixArgs = {
  prefix: Scalars["String"]["input"];
  projectId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetByRecordingsTableIdArgs = {
  recordingsTableId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetByTenantIdAndKeyArgs = {
  key: Scalars["String"]["input"];
  tenantId: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetMetadatumByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetRecordingsTableByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetRecordingsTableColumnByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetRecordingsTableColumnOrderByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetVersionByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDjangoAdminLogByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDjangoContentTypeByAppLabelAndModelArgs = {
  appLabel: Scalars["String"]["input"];
  model: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDjangoContentTypeByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDjangoMigrationByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDjangoSesSesstatByDateArgs = {
  date: Scalars["Date"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDjangoSesSesstatByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDjangoSessionBySessionKeyArgs = {
  sessionKey: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDrsFileexecutorByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDrsMockfileexecutorByFileexecutorPtrIdArgs = {
  fileexecutorPtrId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryEulaVersionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryEulaVersionByVersionArgs = {
  version: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryEulaVersionUserAcceptanceByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryEulaVersionUserAcceptanceByUserIdAndEulaVersionIdArgs = {
  eulaVersionId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryExplorerQueryByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryExplorerQueryfavoriteByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryExplorerQueryfavoriteByQueryIdAndUserIdArgs = {
  queryId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryExplorerQuerylogByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileActivityByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileMetadatumByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileOutputGroupByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileOutputGroupByTaskIdArgs = {
  taskId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileOutputGroupMetadatumByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileProcessingNotificationByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileRecordingGroupByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileRecordingGroupMetadatumByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryGetUserActiveSessionArgs = {
  userid: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryMetadataValueByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryMetadatumByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryNotificationByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOutputGroupFileByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectByKeyAndTenantIdArgs = {
  key: Scalars["String"]["input"];
  tenantId: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectClonedNotificationByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectSharedNotificationByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryRecordingGroupFileByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryRoleChangeNotificationByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryRunByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySessionIsActiveSessionArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTagByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTaskActivityByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTaskActivityByTaskIdArgs = {
  taskId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTaskByExecutorIdArgs = {
  executorId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTaskByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTaskResourceByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTaskSourceByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTaskStatusLogByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTaskStatusLogByTaskIdAndStatusArgs = {
  status: Scalars["Int"]["input"];
  taskId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTenantByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTenantByKeyArgs = {
  key: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTenantByNameArgs = {
  name: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTenantContractAllowanceByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeArgs =
  {
    quotaType: Scalars["Int"]["input"];
    tenantContractTransactionId: Scalars["Int"]["input"];
  };

/** The root query type which gives access points into the data universe. */
export type QueryTenantContractByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTenantContractByNameArgs = {
  name: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTenantContractTransactionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTenantMockuserexecutorByUserexecutorPtrIdArgs = {
  userexecutorPtrId: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTenantMonthlyConsumedResourceByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractArgs =
  {
    month: Scalars["Date"]["input"];
    monthInActiveContract: Scalars["Int"]["input"];
    quotaType: Scalars["Int"]["input"];
    tenantContractId: Scalars["Int"]["input"];
    tenantId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/** The root query type which gives access points into the data universe. */
export type QueryTenantUserMembershipNotificationByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTenantUserexecutorByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTesContainerimageexecutorByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTesMockcontainerimageexecutorByContainerimageexecutorPtrIdArgs =
  {
    containerimageexecutorPtrId: Scalars["Int"]["input"];
  };

/** The root query type which gives access points into the data universe. */
export type QueryTesMocktaskexecutorByTaskexecutorPtrIdArgs = {
  taskexecutorPtrId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTesTaskexecutorByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolByKeyArgs = {
  key: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolCategoryByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolCategoryByKeyArgs = {
  key: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolDisplayCategoryByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolDisplayCategoryByToolIdAndToolcategoryIdArgs = {
  toolId: Scalars["Int"]["input"];
  toolcategoryId: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolParameterByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolResourceByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolResourceByTypeAndToolVersionIdArgs = {
  toolVersionId: Scalars["Int"]["input"];
  type: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolResultByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolSecretByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolSourceByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolTenantByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolTenantByToolIdAndTenantIdArgs = {
  tenantId: Scalars["Int"]["input"];
  toolId: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolVersionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolVersionByToolIdAndVersionArgs = {
  toolId: Scalars["Int"]["input"];
  version: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolboxByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolboxByKeyArgs = {
  key: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolboxExitcodeByCodeArgs = {
  code: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolboxExitcodeByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolboxTenantByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolboxTenantByToolboxIdAndTenantIdArgs = {
  tenantId: Scalars["Int"]["input"];
  toolboxId: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolboxVersionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolboxVersionByToolKeyAndToolVersionVersionArgs = {
  toolKey?: InputMaybe<Scalars["String"]["input"]>;
  toolVersionVersion?: InputMaybe<Scalars["String"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryToolboxVersionByToolboxIdAndVersionArgs = {
  toolboxId: Scalars["Int"]["input"];
  version: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolboxVersionExitcodeByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdArgs =
  {
    toolboxexitcodeId: Scalars["Int"]["input"];
    toolboxversionId: Scalars["Int"]["input"];
  };

/** The root query type which gives access points into the data universe. */
export type QueryToolboxVersionToolVersionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryToolboxVersionToolVersionByToolboxversionIdAndToolversionIdArgs =
  {
    toolboxversionId: Scalars["Int"]["input"];
    toolversionId: Scalars["Int"]["input"];
  };

/** The root query type which gives access points into the data universe. */
export type QueryUserHasActiveSessionArgs = {
  userid: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserIdIsCurrentUserArgs = {
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUserIdIsSettingsGlobalUserIdArgs = {
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowDatumByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowResultByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowResultTagByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowResultTagByResultIdAndTagIdArgs = {
  resultId: Scalars["UUID"]["input"];
  tagId: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowResultWorkflowVersionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdArgs =
  {
    resultId: Scalars["UUID"]["input"];
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowSectionBlockByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowSectionBlockSectionByBlockIdAndSectionIdArgs = {
  blockId: Scalars["UUID"]["input"];
  sectionId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowSectionBlockSectionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowSectionByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowSectionWorkflowVersionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdArgs =
  {
    sectionId: Scalars["UUID"]["input"];
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowSourceByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowSourceTagByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowSourceTagBySourceIdAndTagIdArgs = {
  sourceId: Scalars["UUID"]["input"];
  tagId: Scalars["BigInt"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowSourceWorkflowVersionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdArgs =
  {
    sourceId: Scalars["UUID"]["input"];
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The root query type which gives access points into the data universe. */
export type QueryWorkflowVersionByIdArgs = {
  id: Scalars["UUID"]["input"];
};

export type RecordingGroupFile = {
  __typename: "RecordingGroupFile";
  /** Reads a single `ApplicationUser` that is related to this `RecordingGroupFile`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** Reads a single `DatasetRecordingsTableColumn` that is related to this `RecordingGroupFile`. */
  datasetRecordingsTableColumnByDatasetRecordingsTableColumnId: Maybe<DatasetRecordingsTableColumn>;
  datasetRecordingsTableColumnId: Scalars["UUID"]["output"];
  dateAssignmentEnded: Maybe<Scalars["Datetime"]["output"]>;
  dateCreated: Scalars["Datetime"]["output"];
  /** Reads a single `File` that is related to this `RecordingGroupFile`. */
  fileByFileId: Maybe<File>;
  fileId: Scalars["UUID"]["output"];
  /** Reads a single `FileRecordingGroup` that is related to this `RecordingGroupFile`. */
  fileRecordingGroupByFileRecordingGroupId: Maybe<FileRecordingGroup>;
  fileRecordingGroupId: Scalars["UUID"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads a single `Project` that is related to this `RecordingGroupFile`. */
  projectByProjectId: Maybe<Project>;
  projectId: Scalars["UUID"]["output"];
  /** Reads a single `Tenant` that is related to this `RecordingGroupFile`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  userId: Scalars["UUID"]["output"];
};

/**
 * A condition to be used against `RecordingGroupFile` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type RecordingGroupFileCondition = {
  /** Checks for equality with the object’s `datasetRecordingsTableColumnId` field. */
  datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `dateAssignmentEnded` field. */
  dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `fileRecordingGroupId` field. */
  fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `datasetRecordingsTableColumn` to be created by this mutation. */
export type RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRDatasetRecordingsTableColumnCreateInput =
  {
    colDefId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    identifierPosition?: InputMaybe<Scalars["Int"]["input"]>;
    metadatumToColDefId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInput>;
    pinned: Scalars["Boolean"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput>;
    width: Scalars["Int"]["input"];
  };

/** Input for the nested mutation of `datasetRecordingsTableColumn` in the `RecordingGroupFileInput` mutation. */
export type RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput = {
  /** The primary key(s) for `datasetRecordingsTableColumn` for the far side of the relationship. */
  connectById?: InputMaybe<DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyConnect>;
  /** A `DatasetRecordingsTableColumnInput` object that will be created and connected to this object. */
  create?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRDatasetRecordingsTableColumnCreateInput>;
  /** The primary key(s) for `datasetRecordingsTableColumn` for the far side of the relationship. */
  deleteById?: InputMaybe<DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyDelete>;
  /** The primary key(s) and patch data for `datasetRecordingsTableColumn` for the far side of the relationship. */
  updateById?: InputMaybe<DatasetRecordingsTableColumnOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRUsingDatasetRecordingsTableColumnPkeyUpdate>;
};

/** Input for the nested mutation of `recordingGroupFile` in the `DatasetRecordingsTableColumnInput` mutation. */
export type RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput =
  {
    /** The primary key(s) for `recordingGroupFile` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<RecordingGroupFileRecordingGroupFilesPkeyConnect>
    >;
    /** A `RecordingGroupFileInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRRecordingGroupFilesCreateInput>
    >;
    /** The primary key(s) for `recordingGroupFile` for the far side of the relationship. */
    deleteById?: InputMaybe<
      Array<RecordingGroupFileRecordingGroupFilesPkeyDelete>
    >;
    /** Flag indicating whether all other `recordingGroupFile` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `recordingGroupFile` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<RecordingGroupFileOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRUsingRecordingGroupFilesPkeyUpdate>
    >;
  };

/** The `recordingGroupFile` to be created by this mutation. */
export type RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRRecordingGroupFilesCreateInput =
  {
    applicationUserToUserId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInput>;
    datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput>;
    fileToFileId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInput>;
    id: Scalars["UUID"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** The `fileRecordingGroup` to be created by this mutation. */
export type RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoFileRecordingGroupCreateInput =
  {
    applicationUserToUserId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput>;
    id: Scalars["UUID"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `fileRecordingGroup` in the `RecordingGroupFileInput` mutation. */
export type RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput = {
  /** The primary key(s) for `fileRecordingGroup` for the far side of the relationship. */
  connectById?: InputMaybe<FileRecordingGroupFileRecordingGroupPkeyConnect>;
  /** A `FileRecordingGroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoFileRecordingGroupCreateInput>;
  /** The primary key(s) for `fileRecordingGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<FileRecordingGroupFileRecordingGroupPkeyDelete>;
  /** The primary key(s) and patch data for `fileRecordingGroup` for the far side of the relationship. */
  updateById?: InputMaybe<FileRecordingGroupOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoUsingFileRecordingGroupPkeyUpdate>;
};

/** Input for the nested mutation of `recordingGroupFile` in the `FileRecordingGroupInput` mutation. */
export type RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput =
  {
    /** The primary key(s) for `recordingGroupFile` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<RecordingGroupFileRecordingGroupFilesPkeyConnect>
    >;
    /** A `RecordingGroupFileInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoRecordingGroupFilesCreateInput>
    >;
    /** The primary key(s) for `recordingGroupFile` for the far side of the relationship. */
    deleteById?: InputMaybe<
      Array<RecordingGroupFileRecordingGroupFilesPkeyDelete>
    >;
    /** Flag indicating whether all other `recordingGroupFile` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `recordingGroupFile` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<RecordingGroupFileOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoUsingRecordingGroupFilesPkeyUpdate>
    >;
  };

/** The `recordingGroupFile` to be created by this mutation. */
export type RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoRecordingGroupFilesCreateInput =
  {
    applicationUserToUserId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInput>;
    datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput>;
    fileToFileId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInput>;
    id: Scalars["UUID"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** A filter to be used against `RecordingGroupFile` object types. All fields are combined with a logical ‘and.’ */
export type RecordingGroupFileFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RecordingGroupFileFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `datasetRecordingsTableColumnByDatasetRecordingsTableColumnId` relation. */
  datasetRecordingsTableColumnByDatasetRecordingsTableColumnId?: InputMaybe<DatasetRecordingsTableColumnFilter>;
  /** Filter by the object’s `datasetRecordingsTableColumnId` field. */
  datasetRecordingsTableColumnId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `dateAssignmentEnded` field. */
  dateAssignmentEnded?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fileByFileId` relation. */
  fileByFileId?: InputMaybe<FileFilter>;
  /** Filter by the object’s `fileId` field. */
  fileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `fileRecordingGroupByFileRecordingGroupId` relation. */
  fileRecordingGroupByFileRecordingGroupId?: InputMaybe<FileRecordingGroupFilter>;
  /** Filter by the object’s `fileRecordingGroupId` field. */
  fileRecordingGroupId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RecordingGroupFileFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RecordingGroupFileFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `RecordingGroupFile` */
export type RecordingGroupFileInput = {
  applicationUserToUserId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInput>;
  datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput>;
  dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileRecordingGroupToFileRecordingGroupId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput>;
  fileToFileId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInput>;
  id: Scalars["UUID"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `recordingGroupFile` to look up the row to update. */
export type RecordingGroupFileOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRUsingRecordingGroupFilesPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `recordingGroupFile` being updated. */
    recordingGroupFilePatch: UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRPatch;
  };

/** The fields on `recordingGroupFile` to look up the row to update. */
export type RecordingGroupFileOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoUsingRecordingGroupFilesPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `recordingGroupFile` being updated. */
    recordingGroupFilePatch: UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoPatch;
  };

/** The fields on `recordingGroupFile` to look up the row to update. */
export type RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdUsingRecordingGroupFilesPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `recordingGroupFile` being updated. */
    recordingGroupFilePatch: UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdPatch;
  };

/** The fields on `recordingGroupFile` to look up the row to update. */
export type RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdUsingRecordingGroupFilesPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `recordingGroupFile` being updated. */
    recordingGroupFilePatch: UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdPatch;
  };

/** The fields on `recordingGroupFile` to look up the row to update. */
export type RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingRecordingGroupFilesPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `recordingGroupFile` being updated. */
    recordingGroupFilePatch: UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdPatch;
  };

/** The fields on `recordingGroupFile` to look up the row to update. */
export type RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingRecordingGroupFilesPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `recordingGroupFile` being updated. */
    recordingGroupFilePatch: UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdPatch;
  };

/** Represents an update to a `RecordingGroupFile`. Fields that are set will be updated. */
export type RecordingGroupFilePatch = {
  applicationUserToUserId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInput>;
  datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput>;
  dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileRecordingGroupToFileRecordingGroupId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput>;
  fileToFileId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `recordingGroupFile` to look up the row to connect. */
export type RecordingGroupFileRecordingGroupFilesPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `recordingGroupFile` to look up the row to delete. */
export type RecordingGroupFileRecordingGroupFilesPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** A connection to a list of `RecordingGroupFile` values. */
export type RecordingGroupFilesConnection = {
  __typename: "RecordingGroupFilesConnection";
  /** A list of edges which contains the `RecordingGroupFile` and cursor to aid in pagination. */
  edges: Array<RecordingGroupFilesEdge>;
  /** A list of `RecordingGroupFile` objects. */
  nodes: Array<RecordingGroupFile>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RecordingGroupFile` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `RecordingGroupFile` edge in the connection. */
export type RecordingGroupFilesEdge = {
  __typename: "RecordingGroupFilesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `RecordingGroupFile` at the end of the edge. */
  node: RecordingGroupFile;
};

/** The `file` to be created by this mutation. */
export type RecordingGroupFilesFileIdD657E9EdFkFileIdFileCreateInput = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["UUID"]["input"];
  isSeries: Scalars["Boolean"]["input"];
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  originalName: Scalars["String"]["input"];
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus: Scalars["Int"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status: Scalars["Int"]["input"];
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `file` in the `RecordingGroupFileInput` mutation. */
export type RecordingGroupFilesFileIdD657E9EdFkFileIdInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: InputMaybe<FileFilePkeyConnect>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdFileCreateInput>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: InputMaybe<FileFilePkeyDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: InputMaybe<FileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdUsingFilePkeyUpdate>;
};

/** Input for the nested mutation of `recordingGroupFile` in the `FileInput` mutation. */
export type RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput = {
  /** The primary key(s) for `recordingGroupFile` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<RecordingGroupFileRecordingGroupFilesPkeyConnect>
  >;
  /** A `RecordingGroupFileInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<RecordingGroupFilesFileIdD657E9EdFkFileIdRecordingGroupFilesCreateInput>
  >;
  /** The primary key(s) for `recordingGroupFile` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<RecordingGroupFileRecordingGroupFilesPkeyDelete>
  >;
  /** Flag indicating whether all other `recordingGroupFile` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `recordingGroupFile` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdUsingRecordingGroupFilesPkeyUpdate>
  >;
};

/** The `recordingGroupFile` to be created by this mutation. */
export type RecordingGroupFilesFileIdD657E9EdFkFileIdRecordingGroupFilesCreateInput =
  {
    applicationUserToUserId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInput>;
    datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput>;
    fileToFileId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInput>;
    id: Scalars["UUID"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Methods to use when ordering `RecordingGroupFile`. */
export enum RecordingGroupFilesOrderBy {
  DatasetRecordingsTableColumnIdAsc = "DATASET_RECORDINGS_TABLE_COLUMN_ID_ASC",
  DatasetRecordingsTableColumnIdDesc = "DATASET_RECORDINGS_TABLE_COLUMN_ID_DESC",
  DateAssignmentEndedAsc = "DATE_ASSIGNMENT_ENDED_ASC",
  DateAssignmentEndedDesc = "DATE_ASSIGNMENT_ENDED_DESC",
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  FileRecordingGroupIdAsc = "FILE_RECORDING_GROUP_ID_ASC",
  FileRecordingGroupIdDesc = "FILE_RECORDING_GROUP_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** Input for the nested mutation of `project` in the `RecordingGroupFileInput` mutation. */
export type RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `recordingGroupFile` in the `ProjectInput` mutation. */
export type RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput = {
  /** The primary key(s) for `recordingGroupFile` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<RecordingGroupFileRecordingGroupFilesPkeyConnect>
  >;
  /** A `RecordingGroupFileInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<RecordingGroupFilesProjectId3B6B53B3FkProjectIdRecordingGroupFilesCreateInput>
  >;
  /** The primary key(s) for `recordingGroupFile` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<RecordingGroupFileRecordingGroupFilesPkeyDelete>
  >;
  /** Flag indicating whether all other `recordingGroupFile` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `recordingGroupFile` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdUsingRecordingGroupFilesPkeyUpdate>
  >;
};

/** The `recordingGroupFile` to be created by this mutation. */
export type RecordingGroupFilesProjectId3B6B53B3FkProjectIdRecordingGroupFilesCreateInput =
  {
    applicationUserToUserId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInput>;
    datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput>;
    fileToFileId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInput>;
    id: Scalars["UUID"]["input"];
    projectToProjectId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `tenant` in the `RecordingGroupFileInput` mutation. */
export type RecordingGroupFilesTenantId637C3013FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `recordingGroupFile` in the `TenantInput` mutation. */
export type RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput = {
  /** The primary key(s) for `recordingGroupFile` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<RecordingGroupFileRecordingGroupFilesPkeyConnect>
  >;
  /** A `RecordingGroupFileInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<RecordingGroupFilesTenantId637C3013FkTenantIdRecordingGroupFilesCreateInput>
  >;
  /** The primary key(s) for `recordingGroupFile` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<RecordingGroupFileRecordingGroupFilesPkeyDelete>
  >;
  /** Flag indicating whether all other `recordingGroupFile` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `recordingGroupFile` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingRecordingGroupFilesPkeyUpdate>
  >;
};

/** The `recordingGroupFile` to be created by this mutation. */
export type RecordingGroupFilesTenantId637C3013FkTenantIdRecordingGroupFilesCreateInput =
  {
    applicationUserToUserId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInput>;
    datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput>;
    fileToFileId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInput>;
    id: Scalars["UUID"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput>;
    tenantToTenantId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** The `tenant` to be created by this mutation. */
export type RecordingGroupFilesTenantId637C3013FkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** The `applicationUser` to be created by this mutation. */
export type RecordingGroupFilesUserId99A36997FkApplicationUserIdApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `RecordingGroupFileInput` mutation. */
export type RecordingGroupFilesUserId99A36997FkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `recordingGroupFile` in the `ApplicationUserInput` mutation. */
export type RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput = {
  /** The primary key(s) for `recordingGroupFile` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<RecordingGroupFileRecordingGroupFilesPkeyConnect>
  >;
  /** A `RecordingGroupFileInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<RecordingGroupFilesUserId99A36997FkApplicationUserIdRecordingGroupFilesCreateInput>
  >;
  /** The primary key(s) for `recordingGroupFile` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<RecordingGroupFileRecordingGroupFilesPkeyDelete>
  >;
  /** Flag indicating whether all other `recordingGroupFile` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `recordingGroupFile` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingRecordingGroupFilesPkeyUpdate>
  >;
};

/** The `recordingGroupFile` to be created by this mutation. */
export type RecordingGroupFilesUserId99A36997FkApplicationUserIdRecordingGroupFilesCreateInput =
  {
    applicationUserToUserId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInput>;
    datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput>;
    fileToFileId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInput>;
    id: Scalars["UUID"]["input"];
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInput>;
  };

/** The `applicationUser` to be created by this mutation. */
export type RoleChangeNotificaUserId879Bd49AFkApplicatiApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `RoleChangeNotificationInput` mutation. */
export type RoleChangeNotificaUserId879Bd49AFkApplicatiInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `roleChangeNotification` in the `ApplicationUserInput` mutation. */
export type RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput = {
  /** The primary key(s) for `roleChangeNotification` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<RoleChangeNotificationRoleChangeNotificationPkeyConnect>
  >;
  /** The primary key(s) and patch data for `roleChangeNotification` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<RoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingRoleChangeNotificationPkeyUpdate>
  >;
};

export type RoleChangeNotification = {
  __typename: "RoleChangeNotification";
  /** Reads a single `ApplicationUser` that is related to this `RoleChangeNotification`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  id: Scalars["UUID"]["output"];
  /** Reads a single `Notification` that is related to this `RoleChangeNotification`. */
  notificationById: Maybe<Notification>;
  role: Scalars["Int"]["output"];
  /** Reads a single `Tenant` that is related to this `RoleChangeNotification`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  userId: Scalars["UUID"]["output"];
};

/**
 * A condition to be used against `RoleChangeNotification` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type RoleChangeNotificationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `role` field. */
  role?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `RoleChangeNotification` object types. All fields are combined with a logical ‘and.’ */
export type RoleChangeNotificationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RoleChangeNotificationFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RoleChangeNotificationFilter>;
  /** Filter by the object’s `notificationById` relation. */
  notificationById?: InputMaybe<NotificationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RoleChangeNotificationFilter>>;
  /** Filter by the object’s `role` field. */
  role?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** Input for the nested mutation of `notification` in the `RoleChangeNotificationInput` mutation. */
export type RoleChangeNotificationIdD576A528FkNotificationIdInput = {
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectById?: InputMaybe<NotificationNotificationPkeyConnect>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateById?: InputMaybe<NotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdUsingNotificationPkeyUpdate>;
};

/** Input for the nested mutation of `roleChangeNotification` in the `NotificationInput` mutation. */
export type RoleChangeNotificationIdD576A528FkNotificationIdInverseInput = {
  /** The primary key(s) for `roleChangeNotification` for the far side of the relationship. */
  connectById?: InputMaybe<RoleChangeNotificationRoleChangeNotificationPkeyConnect>;
  /** The primary key(s) and patch data for `roleChangeNotification` for the far side of the relationship. */
  updateById?: InputMaybe<RoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdUsingRoleChangeNotificationPkeyUpdate>;
};

/** The fields on `roleChangeNotification` to look up the row to update. */
export type RoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingRoleChangeNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `roleChangeNotification` being updated. */
    roleChangeNotificationPatch: UpdateRoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiPatch;
  };

/** The fields on `roleChangeNotification` to look up the row to update. */
export type RoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdUsingRoleChangeNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `roleChangeNotification` being updated. */
    roleChangeNotificationPatch: UpdateRoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdPatch;
  };

/** The fields on `roleChangeNotification` to look up the row to update. */
export type RoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingRoleChangeNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `roleChangeNotification` being updated. */
    roleChangeNotificationPatch: UpdateRoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdPatch;
  };

/** The fields on `roleChangeNotification` to look up the row to connect. */
export type RoleChangeNotificationRoleChangeNotificationPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** Input for the nested mutation of `tenant` in the `RoleChangeNotificationInput` mutation. */
export type RoleChangeNotificationTenantId36852Ce9FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `roleChangeNotification` in the `TenantInput` mutation. */
export type RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput = {
  /** The primary key(s) for `roleChangeNotification` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<RoleChangeNotificationRoleChangeNotificationPkeyConnect>
  >;
  /** The primary key(s) and patch data for `roleChangeNotification` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<RoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingRoleChangeNotificationPkeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type RoleChangeNotificationTenantId36852Ce9FkTenantIdTenantCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal: Scalars["Boolean"]["input"];
    key: Scalars["String"]["input"];
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name: Scalars["String"]["input"];
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status: Scalars["Int"]["input"];
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType: Scalars["Int"]["input"];
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** A connection to a list of `RoleChangeNotification` values. */
export type RoleChangeNotificationsConnection = {
  __typename: "RoleChangeNotificationsConnection";
  /** A list of edges which contains the `RoleChangeNotification` and cursor to aid in pagination. */
  edges: Array<RoleChangeNotificationsEdge>;
  /** A list of `RoleChangeNotification` objects. */
  nodes: Array<RoleChangeNotification>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RoleChangeNotification` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `RoleChangeNotification` edge in the connection. */
export type RoleChangeNotificationsEdge = {
  __typename: "RoleChangeNotificationsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `RoleChangeNotification` at the end of the edge. */
  node: RoleChangeNotification;
};

/** Methods to use when ordering `RoleChangeNotification`. */
export enum RoleChangeNotificationsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RoleAsc = "ROLE_ASC",
  RoleDesc = "ROLE_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type Run = {
  __typename: "Run";
  created: Scalars["Datetime"]["output"];
  id: Scalars["Int"]["output"];
  /** Reads a single `Tenant` that is related to this `Run`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
};

/** A condition to be used against `Run` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type RunCondition = {
  /** Checks for equality with the object’s `created` field. */
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `Run` object types. All fields are combined with a logical ‘and.’ */
export type RunFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RunFilter>>;
  /** Filter by the object’s `created` field. */
  created?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RunFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RunFilter>>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Run` */
export type RunInput = {
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInput>;
};

/** The fields on `run` to look up the row to update. */
export type RunOnRunForRunTenantId73Fbd8D9FkTenantIdUsingRunPkeyUpdate = {
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `run` being updated. */
  runPatch: UpdateRunOnRunForRunTenantId73Fbd8D9FkTenantIdPatch;
};

/** Represents an update to a `Run`. Fields that are set will be updated. */
export type RunPatch = {
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInput>;
};

/** The fields on `run` to look up the row to connect. */
export type RunRunPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `run` to look up the row to delete. */
export type RunRunPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** Input for the nested mutation of `tenant` in the `RunInput` mutation. */
export type RunTenantId73Fbd8D9FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnRunForRunTenantId73Fbd8D9FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnRunForRunTenantId73Fbd8D9FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnRunForRunTenantId73Fbd8D9FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `run` in the `TenantInput` mutation. */
export type RunTenantId73Fbd8D9FkTenantIdInverseInput = {
  /** The primary key(s) for `run` for the far side of the relationship. */
  connectById?: InputMaybe<Array<RunRunPkeyConnect>>;
  /** A `RunInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<RunTenantId73Fbd8D9FkTenantIdRunCreateInput>>;
  /** The primary key(s) for `run` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<RunRunPkeyDelete>>;
  /** Flag indicating whether all other `run` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `run` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<RunOnRunForRunTenantId73Fbd8D9FkTenantIdUsingRunPkeyUpdate>
  >;
};

/** The `run` to be created by this mutation. */
export type RunTenantId73Fbd8D9FkTenantIdRunCreateInput = {
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInput>;
};

/** The `tenant` to be created by this mutation. */
export type RunTenantId73Fbd8D9FkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** A connection to a list of `Run` values. */
export type RunsConnection = {
  __typename: "RunsConnection";
  /** A list of edges which contains the `Run` and cursor to aid in pagination. */
  edges: Array<RunsEdge>;
  /** A list of `Run` objects. */
  nodes: Array<Run>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Run` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Run` edge in the connection. */
export type RunsEdge = {
  __typename: "RunsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Run` at the end of the edge. */
  node: Run;
};

/** Methods to use when ordering `Run`. */
export enum RunsOrderBy {
  CreatedAsc = "CREATED_ASC",
  CreatedDesc = "CREATED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
}

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["String"]["input"]>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["String"]["input"]>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars["String"]["input"]>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["String"]["input"]>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["String"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type Subscription = {
  __typename: "Subscription";
  /** Reads and enables pagination through a set of `AnalysisTableGroup`. (live) */
  allAnalysisTableGroups: Maybe<AnalysisTableGroupsConnection>;
  /** Reads and enables pagination through a set of `AnalysisTableRowAttachResult`. (live) */
  allAnalysisTableRowAttachResults: Maybe<AnalysisTableRowAttachResultsConnection>;
  /** Reads and enables pagination through a set of `AnalysisTableRow`. (live) */
  allAnalysisTableRows: Maybe<AnalysisTableRowsConnection>;
  /** Reads and enables pagination through a set of `AnalysisTable`. (live) */
  allAnalysisTables: Maybe<AnalysisTablesConnection>;
  /** Reads and enables pagination through a set of `ApplicationUserGroup`. (live) */
  allApplicationUserGroups: Maybe<ApplicationUserGroupsConnection>;
  /** Reads and enables pagination through a set of `ApplicationUserMembership`. (live) */
  allApplicationUserMemberships: Maybe<ApplicationUserMembershipsConnection>;
  /** Reads and enables pagination through a set of `ApplicationUserMetadatum`. (live) */
  allApplicationUserMetadata: Maybe<ApplicationUserMetadataConnection>;
  /** Reads and enables pagination through a set of `ApplicationUserSession`. (live) */
  allApplicationUserSessions: Maybe<ApplicationUserSessionsConnection>;
  /** Reads and enables pagination through a set of `ApplicationUserUserPermission`. (live) */
  allApplicationUserUserPermissions: Maybe<ApplicationUserUserPermissionsConnection>;
  /** Reads and enables pagination through a set of `AuthGroupPermission`. (live) */
  allAuthGroupPermissions: Maybe<AuthGroupPermissionsConnection>;
  /** Reads and enables pagination through a set of `AuthGroup`. (live) */
  allAuthGroups: Maybe<AuthGroupsConnection>;
  /** Reads and enables pagination through a set of `AuthPermission`. (live) */
  allAuthPermissions: Maybe<AuthPermissionsConnection>;
  /** Reads and enables pagination through a set of `AvailableNonRestrictedProject`. (live) */
  allAvailableNonRestrictedProjects: Maybe<AvailableNonRestrictedProjectsConnection>;
  /** Reads and enables pagination through a set of `Avatar`. (live) */
  allAvatars: Maybe<AvatarsConnection>;
  /** Reads and enables pagination through a set of `AwsBatchTask`. (live) */
  allAwsBatchTasks: Maybe<AwsBatchTasksConnection>;
  /** Reads and enables pagination through a set of `AwsCognitoUser`. (live) */
  allAwsCognitoUsers: Maybe<AwsCognitoUsersConnection>;
  /** Reads and enables pagination through a set of `AwsEcrImage`. (live) */
  allAwsEcrImages: Maybe<AwsEcrImagesConnection>;
  /** Reads and enables pagination through a set of `AwsMockBatchTask`. (live) */
  allAwsMockBatchTasks: Maybe<AwsMockBatchTasksConnection>;
  /** Reads and enables pagination through a set of `AwsMockEcrImage`. (live) */
  allAwsMockEcrImages: Maybe<AwsMockEcrImagesConnection>;
  /** Reads and enables pagination through a set of `AwsMockS3File`. (live) */
  allAwsMockS3Files: Maybe<AwsMockS3FilesConnection>;
  /** Reads and enables pagination through a set of `AwsS3File`. (live) */
  allAwsS3Files: Maybe<AwsS3FilesConnection>;
  /** Reads and enables pagination through a set of `ContainerImage`. (live) */
  allContainerImages: Maybe<ContainerImagesConnection>;
  /** Reads and enables pagination through a set of `Cost`. (live) */
  allCosts: Maybe<CostsConnection>;
  /** Reads and enables pagination through a set of `CurrentApplicationUser`. (live) */
  allCurrentApplicationUsers: Maybe<CurrentApplicationUsersConnection>;
  /** Reads and enables pagination through a set of `CurrentUserMembership`. (live) */
  allCurrentUserMemberships: Maybe<CurrentUserMembershipsConnection>;
  /** Reads and enables pagination through a set of `DatasetMetadatum`. (live) */
  allDatasetMetadata: Maybe<DatasetMetadataConnection>;
  /** Reads and enables pagination through a set of `DatasetRecordingsTableColumnOrder`. (live) */
  allDatasetRecordingsTableColumnOrders: Maybe<DatasetRecordingsTableColumnOrdersConnection>;
  /** Reads and enables pagination through a set of `DatasetRecordingsTableColumn`. (live) */
  allDatasetRecordingsTableColumns: Maybe<DatasetRecordingsTableColumnsConnection>;
  /** Reads and enables pagination through a set of `DatasetRecordingsTable`. (live) */
  allDatasetRecordingsTables: Maybe<DatasetRecordingsTablesConnection>;
  /** Reads and enables pagination through a set of `DatasetVersion`. (live) */
  allDatasetVersions: Maybe<DatasetVersionsConnection>;
  /** Reads and enables pagination through a set of `Dataset`. (live) */
  allDatasets: Maybe<DatasetsConnection>;
  /** Reads and enables pagination through a set of `DjangoAdminLog`. (live) */
  allDjangoAdminLogs: Maybe<DjangoAdminLogsConnection>;
  /** Reads and enables pagination through a set of `DjangoContentType`. (live) */
  allDjangoContentTypes: Maybe<DjangoContentTypesConnection>;
  /** Reads and enables pagination through a set of `DjangoMigration`. (live) */
  allDjangoMigrations: Maybe<DjangoMigrationsConnection>;
  /** Reads and enables pagination through a set of `DjangoSesSesstat`. (live) */
  allDjangoSesSesstats: Maybe<DjangoSesSesstatsConnection>;
  /** Reads and enables pagination through a set of `DjangoSession`. (live) */
  allDjangoSessions: Maybe<DjangoSessionsConnection>;
  /** Reads and enables pagination through a set of `DrsFileexecutor`. (live) */
  allDrsFileexecutors: Maybe<DrsFileexecutorsConnection>;
  /** Reads and enables pagination through a set of `DrsMockfileexecutor`. (live) */
  allDrsMockfileexecutors: Maybe<DrsMockfileexecutorsConnection>;
  /** Reads and enables pagination through a set of `EulaVersionUserAcceptance`. (live) */
  allEulaVersionUserAcceptances: Maybe<EulaVersionUserAcceptancesConnection>;
  /** Reads and enables pagination through a set of `EulaVersion`. (live) */
  allEulaVersions: Maybe<EulaVersionsConnection>;
  /** Reads and enables pagination through a set of `ExplorerQuery`. (live) */
  allExplorerQueries: Maybe<ExplorerQueriesConnection>;
  /** Reads and enables pagination through a set of `ExplorerQueryfavorite`. (live) */
  allExplorerQueryfavorites: Maybe<ExplorerQueryfavoritesConnection>;
  /** Reads and enables pagination through a set of `ExplorerQuerylog`. (live) */
  allExplorerQuerylogs: Maybe<ExplorerQuerylogsConnection>;
  /** Reads and enables pagination through a set of `FileActivity`. (live) */
  allFileActivities: Maybe<FileActivitiesConnection>;
  /** Reads and enables pagination through a set of `FileMetadatum`. (live) */
  allFileMetadata: Maybe<FileMetadataConnection>;
  /** Reads and enables pagination through a set of `FileOutputGroupMetadatum`. (live) */
  allFileOutputGroupMetadata: Maybe<FileOutputGroupMetadataConnection>;
  /** Reads and enables pagination through a set of `FileOutputGroup`. (live) */
  allFileOutputGroups: Maybe<FileOutputGroupsConnection>;
  /** Reads and enables pagination through a set of `FileProcessingNotification`. (live) */
  allFileProcessingNotifications: Maybe<FileProcessingNotificationsConnection>;
  /** Reads and enables pagination through a set of `FileRecordingGroupMetadatum`. (live) */
  allFileRecordingGroupMetadata: Maybe<FileRecordingGroupMetadataConnection>;
  /** Reads and enables pagination through a set of `FileRecordingGroup`. (live) */
  allFileRecordingGroups: Maybe<FileRecordingGroupsConnection>;
  /** Reads and enables pagination through a set of `File`. (live) */
  allFiles: Maybe<FilesConnection>;
  /** Reads and enables pagination through a set of `Notification`. (live) */
  allNotifications: Maybe<NotificationsConnection>;
  /** Reads and enables pagination through a set of `OutputGroupFile`. (live) */
  allOutputGroupFiles: Maybe<OutputGroupFilesConnection>;
  /** Reads and enables pagination through a set of `ProjectClonedNotification`. (live) */
  allProjectClonedNotifications: Maybe<ProjectClonedNotificationsConnection>;
  /** Reads and enables pagination through a set of `ProjectSharedNotification`. (live) */
  allProjectSharedNotifications: Maybe<ProjectSharedNotificationsConnection>;
  /** Reads and enables pagination through a set of `Project`. (live) */
  allProjects: Maybe<ProjectsConnection>;
  /** Reads and enables pagination through a set of `RecordingGroupFile`. (live) */
  allRecordingGroupFiles: Maybe<RecordingGroupFilesConnection>;
  /** Reads and enables pagination through a set of `RoleChangeNotification`. (live) */
  allRoleChangeNotifications: Maybe<RoleChangeNotificationsConnection>;
  /** Reads and enables pagination through a set of `Run`. (live) */
  allRuns: Maybe<RunsConnection>;
  /** Reads and enables pagination through a set of `Tag`. (live) */
  allTags: Maybe<TagsConnection>;
  /** Reads and enables pagination through a set of `TaskActivity`. (live) */
  allTaskActivities: Maybe<TaskActivitiesConnection>;
  /** Reads and enables pagination through a set of `TaskResource`. (live) */
  allTaskResources: Maybe<TaskResourcesConnection>;
  /** Reads and enables pagination through a set of `TaskSource`. (live) */
  allTaskSources: Maybe<TaskSourcesConnection>;
  /** Reads and enables pagination through a set of `TaskStatusLog`. (live) */
  allTaskStatusLogs: Maybe<TaskStatusLogsConnection>;
  /** Reads and enables pagination through a set of `Task`. (live) */
  allTasks: Maybe<TasksConnection>;
  /** Reads and enables pagination through a set of `TenantContractAllowance`. (live) */
  allTenantContractAllowances: Maybe<TenantContractAllowancesConnection>;
  /** Reads and enables pagination through a set of `TenantContractTransaction`. (live) */
  allTenantContractTransactions: Maybe<TenantContractTransactionsConnection>;
  /** Reads and enables pagination through a set of `TenantContract`. (live) */
  allTenantContracts: Maybe<TenantContractsConnection>;
  /** Reads and enables pagination through a set of `TenantMockuserexecutor`. (live) */
  allTenantMockuserexecutors: Maybe<TenantMockuserexecutorsConnection>;
  /** Reads and enables pagination through a set of `TenantMonthlyConsumedResource`. (live) */
  allTenantMonthlyConsumedResources: Maybe<TenantMonthlyConsumedResourcesConnection>;
  /** Reads and enables pagination through a set of `TenantUserMembershipNotification`. (live) */
  allTenantUserMembershipNotifications: Maybe<TenantUserMembershipNotificationsConnection>;
  /** Reads and enables pagination through a set of `TenantUserexecutor`. (live) */
  allTenantUserexecutors: Maybe<TenantUserexecutorsConnection>;
  /** Reads and enables pagination through a set of `TesContainerimageexecutor`. (live) */
  allTesContainerimageexecutors: Maybe<TesContainerimageexecutorsConnection>;
  /** Reads and enables pagination through a set of `TesMockcontainerimageexecutor`. (live) */
  allTesMockcontainerimageexecutors: Maybe<TesMockcontainerimageexecutorsConnection>;
  /** Reads and enables pagination through a set of `TesMocktaskexecutor`. (live) */
  allTesMocktaskexecutors: Maybe<TesMocktaskexecutorsConnection>;
  /** Reads and enables pagination through a set of `TesTaskexecutor`. (live) */
  allTesTaskexecutors: Maybe<TesTaskexecutorsConnection>;
  /** Reads and enables pagination through a set of `ToolCategory`. (live) */
  allToolCategories: Maybe<ToolCategoriesConnection>;
  /** Reads and enables pagination through a set of `ToolDisplayCategory`. (live) */
  allToolDisplayCategories: Maybe<ToolDisplayCategoriesConnection>;
  /** Reads and enables pagination through a set of `ToolParameter`. (live) */
  allToolParameters: Maybe<ToolParametersConnection>;
  /** Reads and enables pagination through a set of `ToolResource`. (live) */
  allToolResources: Maybe<ToolResourcesConnection>;
  /** Reads and enables pagination through a set of `ToolResult`. (live) */
  allToolResults: Maybe<ToolResultsConnection>;
  /** Reads and enables pagination through a set of `ToolSecret`. (live) */
  allToolSecrets: Maybe<ToolSecretsConnection>;
  /** Reads and enables pagination through a set of `ToolSource`. (live) */
  allToolSources: Maybe<ToolSourcesConnection>;
  /** Reads and enables pagination through a set of `ToolTenant`. (live) */
  allToolTenants: Maybe<ToolTenantsConnection>;
  /** Reads and enables pagination through a set of `ToolVersion`. (live) */
  allToolVersions: Maybe<ToolVersionsConnection>;
  /** Reads and enables pagination through a set of `ToolboxExitcode`. (live) */
  allToolboxExitcodes: Maybe<ToolboxExitcodesConnection>;
  /** Reads and enables pagination through a set of `ToolboxTenant`. (live) */
  allToolboxTenants: Maybe<ToolboxTenantsConnection>;
  /** Reads and enables pagination through a set of `ToolboxVersionExitcode`. (live) */
  allToolboxVersionExitcodes: Maybe<ToolboxVersionExitcodesConnection>;
  /** Reads and enables pagination through a set of `ToolboxVersionToolVersion`. (live) */
  allToolboxVersionToolVersions: Maybe<ToolboxVersionToolVersionsConnection>;
  /** Reads and enables pagination through a set of `ToolboxVersion`. (live) */
  allToolboxVersions: Maybe<ToolboxVersionsConnection>;
  /** Reads and enables pagination through a set of `Toolbox`. (live) */
  allToolboxes: Maybe<ToolboxesConnection>;
  /** Reads and enables pagination through a set of `Tool`. (live) */
  allTools: Maybe<ToolsConnection>;
  /** Reads and enables pagination through a set of `UserAccessToProject`. (live) */
  allUserAccessToProjects: Maybe<UserAccessToProjectsConnection>;
  /** Reads and enables pagination through a set of `WorkflowDatum`. (live) */
  allWorkflowData: Maybe<WorkflowDataConnection>;
  /** Reads and enables pagination through a set of `WorkflowResultTag`. (live) */
  allWorkflowResultTags: Maybe<WorkflowResultTagsConnection>;
  /** Reads and enables pagination through a set of `WorkflowResultWorkflowVersion`. (live) */
  allWorkflowResultWorkflowVersions: Maybe<WorkflowResultWorkflowVersionsConnection>;
  /** Reads and enables pagination through a set of `WorkflowResult`. (live) */
  allWorkflowResults: Maybe<WorkflowResultsConnection>;
  /** Reads and enables pagination through a set of `WorkflowSectionBlockSection`. (live) */
  allWorkflowSectionBlockSections: Maybe<WorkflowSectionBlockSectionsConnection>;
  /** Reads and enables pagination through a set of `WorkflowSectionBlock`. (live) */
  allWorkflowSectionBlocks: Maybe<WorkflowSectionBlocksConnection>;
  /** Reads and enables pagination through a set of `WorkflowSectionWorkflowVersion`. (live) */
  allWorkflowSectionWorkflowVersions: Maybe<WorkflowSectionWorkflowVersionsConnection>;
  /** Reads and enables pagination through a set of `WorkflowSection`. (live) */
  allWorkflowSections: Maybe<WorkflowSectionsConnection>;
  /** Reads and enables pagination through a set of `WorkflowSourceTag`. (live) */
  allWorkflowSourceTags: Maybe<WorkflowSourceTagsConnection>;
  /** Reads and enables pagination through a set of `WorkflowSourceWorkflowVersion`. (live) */
  allWorkflowSourceWorkflowVersions: Maybe<WorkflowSourceWorkflowVersionsConnection>;
  /** Reads and enables pagination through a set of `WorkflowSource`. (live) */
  allWorkflowSources: Maybe<WorkflowSourcesConnection>;
  /** Reads and enables pagination through a set of `WorkflowVersion`. (live) */
  allWorkflowVersions: Maybe<WorkflowVersionsConnection>;
  /** Reads and enables pagination through a set of `Workflow`. (live) */
  allWorkflows: Maybe<WorkflowsConnection>;
  /**  (live) */
  analysisTableById: Maybe<AnalysisTable>;
  /**  (live) */
  analysisTableGroupById: Maybe<AnalysisTableGroup>;
  /**  (live) */
  analysisTableRowAttachResultById: Maybe<AnalysisTableRowAttachResult>;
  /**  (live) */
  analysisTableRowById: Maybe<AnalysisTableRow>;
  /**  (live) */
  analysisTableRowByTaskId: Maybe<AnalysisTableRow>;
  /**  (live) */
  applicationUserByEmail: Maybe<ApplicationUser>;
  /**  (live) */
  applicationUserById: Maybe<ApplicationUser>;
  /**  (live) */
  applicationUserByUsername: Maybe<ApplicationUser>;
  /**  (live) */
  applicationUserGroupById: Maybe<ApplicationUserGroup>;
  /**  (live) */
  applicationUserGroupByUserIdAndGroupId: Maybe<ApplicationUserGroup>;
  /**  (live) */
  applicationUserMembershipById: Maybe<ApplicationUserMembership>;
  /**  (live) */
  applicationUserMembershipByUserIdAndTenantId: Maybe<ApplicationUserMembership>;
  /**  (live) */
  applicationUserMetadatumById: Maybe<ApplicationUserMetadatum>;
  /**  (live) */
  applicationUserMetadatumByUserIdAndKey: Maybe<ApplicationUserMetadatum>;
  /**  (live) */
  applicationUserSessionById: Maybe<ApplicationUserSession>;
  /**  (live) */
  applicationUserUserPermissionById: Maybe<ApplicationUserUserPermission>;
  /**  (live) */
  applicationUserUserPermissionByUserIdAndPermissionId: Maybe<ApplicationUserUserPermission>;
  /**  (live) */
  assertUserAcceptedEula: Maybe<Scalars["Boolean"]["output"]>;
  /**  (live) */
  assertUserCanSeeTenantInfo: Maybe<Scalars["Boolean"]["output"]>;
  /**  (live) */
  assertUserCanSeeUserInfo: Maybe<Scalars["Boolean"]["output"]>;
  /**  (live) */
  assertUserHasProjectSharedByTenant: Maybe<Scalars["Boolean"]["output"]>;
  /**  (live) */
  assertUserHasTenantOfId: Maybe<Scalars["Boolean"]["output"]>;
  /**  (live) */
  assertUserIsInvitedToTenant: Maybe<Scalars["Boolean"]["output"]>;
  /**  (live) */
  authGroupById: Maybe<AuthGroup>;
  /**  (live) */
  authGroupByName: Maybe<AuthGroup>;
  /**  (live) */
  authGroupPermissionByGroupIdAndPermissionId: Maybe<AuthGroupPermission>;
  /**  (live) */
  authGroupPermissionById: Maybe<AuthGroupPermission>;
  /**  (live) */
  authPermissionByContentTypeIdAndCodename: Maybe<AuthPermission>;
  /**  (live) */
  authPermissionById: Maybe<AuthPermission>;
  /**  (live) */
  avatarById: Maybe<Avatar>;
  /**  (live) */
  avatarByUserId: Maybe<Avatar>;
  /**  (live) */
  awsBatchTaskByTaskexecutorPtrId: Maybe<AwsBatchTask>;
  /**  (live) */
  awsCognitoUserByUserexecutorPtrId: Maybe<AwsCognitoUser>;
  /**  (live) */
  awsEcrImageByContainerimageexecutorPtrId: Maybe<AwsEcrImage>;
  /**  (live) */
  awsMockBatchTaskByAwstaskexecutorPtrId: Maybe<AwsMockBatchTask>;
  /**  (live) */
  awsMockEcrImageByAwscontainerimageexecutorPtrId: Maybe<AwsMockEcrImage>;
  /**  (live) */
  awsMockS3FileByAwsfileexecutorPtrId: Maybe<AwsMockS3File>;
  /**  (live) */
  awsS3FileByFileexecutorPtrId: Maybe<AwsS3File>;
  /**  (live) */
  containerImageByExecutorId: Maybe<ContainerImage>;
  /**  (live) */
  containerImageById: Maybe<ContainerImage>;
  /**  (live) */
  containerImageByNameAndTag: Maybe<ContainerImage>;
  /**  (live) */
  containerImageByRepositoryAndTag: Maybe<ContainerImage>;
  /**  (live) */
  containerImageByToolboxVersionId: Maybe<ContainerImage>;
  /**  (live) */
  costById: Maybe<Cost>;
  /**  (live) */
  datasetById: Maybe<Dataset>;
  /**  (live) */
  datasetByProjectIdAndPrefix: Maybe<Dataset>;
  /**  (live) */
  datasetByRecordingsTableId: Maybe<Dataset>;
  /**  (live) */
  datasetByTenantIdAndKey: Maybe<Dataset>;
  /**  (live) */
  datasetMetadatumById: Maybe<DatasetMetadatum>;
  /**  (live) */
  datasetRecordingsTableById: Maybe<DatasetRecordingsTable>;
  /**  (live) */
  datasetRecordingsTableColumnById: Maybe<DatasetRecordingsTableColumn>;
  /**  (live) */
  datasetRecordingsTableColumnOrderById: Maybe<DatasetRecordingsTableColumnOrder>;
  /**  (live) */
  datasetVersionById: Maybe<DatasetVersion>;
  /**  (live) */
  djangoAdminLogById: Maybe<DjangoAdminLog>;
  /**  (live) */
  djangoContentTypeByAppLabelAndModel: Maybe<DjangoContentType>;
  /**  (live) */
  djangoContentTypeById: Maybe<DjangoContentType>;
  /**  (live) */
  djangoMigrationById: Maybe<DjangoMigration>;
  /**  (live) */
  djangoSesSesstatByDate: Maybe<DjangoSesSesstat>;
  /**  (live) */
  djangoSesSesstatById: Maybe<DjangoSesSesstat>;
  /**  (live) */
  djangoSessionBySessionKey: Maybe<DjangoSession>;
  /**  (live) */
  drsFileexecutorById: Maybe<DrsFileexecutor>;
  /**  (live) */
  drsMockfileexecutorByFileexecutorPtrId: Maybe<DrsMockfileexecutor>;
  /**  (live) */
  eulaVersionById: Maybe<EulaVersion>;
  /**  (live) */
  eulaVersionByVersion: Maybe<EulaVersion>;
  /**  (live) */
  eulaVersionUserAcceptanceById: Maybe<EulaVersionUserAcceptance>;
  /**  (live) */
  eulaVersionUserAcceptanceByUserIdAndEulaVersionId: Maybe<EulaVersionUserAcceptance>;
  /**  (live) */
  explorerQueryById: Maybe<ExplorerQuery>;
  /**  (live) */
  explorerQueryfavoriteById: Maybe<ExplorerQueryfavorite>;
  /**  (live) */
  explorerQueryfavoriteByQueryIdAndUserId: Maybe<ExplorerQueryfavorite>;
  /**  (live) */
  explorerQuerylogById: Maybe<ExplorerQuerylog>;
  /**  (live) */
  fileActivityById: Maybe<FileActivity>;
  /**  (live) */
  fileById: Maybe<File>;
  /**  (live) */
  fileMetadatumById: Maybe<FileMetadatum>;
  /**  (live) */
  fileOutputGroupById: Maybe<FileOutputGroup>;
  /**  (live) */
  fileOutputGroupByTaskId: Maybe<FileOutputGroup>;
  /**  (live) */
  fileOutputGroupMetadatumById: Maybe<FileOutputGroupMetadatum>;
  /**  (live) */
  fileProcessingNotificationById: Maybe<FileProcessingNotification>;
  /**  (live) */
  fileRecordingGroupById: Maybe<FileRecordingGroup>;
  /**  (live) */
  fileRecordingGroupMetadatumById: Maybe<FileRecordingGroupMetadatum>;
  /**  (live) */
  getCurrentUser: Maybe<ApplicationUser>;
  /**  (live) */
  getCurrentUserId: Maybe<Scalars["UUID"]["output"]>;
  /**  (live) */
  getTenantFromApplicationUser: Maybe<Scalars["Int"]["output"]>;
  /**  (live) */
  getUserActiveSession: Maybe<ApplicationUserSession>;
  /**  (live) */
  metadataValueById: Maybe<MetadataValue>;
  /**  (live) */
  metadatumById: Maybe<Metadatum>;
  /**  (live) */
  notificationById: Maybe<Notification>;
  /**  (live) */
  outputGroupFileById: Maybe<OutputGroupFile>;
  /**  (live) */
  projectById: Maybe<Project>;
  /**  (live) */
  projectByKeyAndTenantId: Maybe<Project>;
  /**  (live) */
  projectClonedNotificationById: Maybe<ProjectClonedNotification>;
  /**  (live) */
  projectSharedNotificationById: Maybe<ProjectSharedNotification>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form. (live)
   */
  query: Query;
  /**  (live) */
  recordingGroupFileById: Maybe<RecordingGroupFile>;
  /**  (live) */
  roleChangeNotificationById: Maybe<RoleChangeNotification>;
  /**  (live) */
  runById: Maybe<Run>;
  /**  (live) */
  sessionIsActiveSession: Maybe<Scalars["Boolean"]["output"]>;
  /**  (live) */
  tagById: Maybe<Tag>;
  /**  (live) */
  taskActivityById: Maybe<TaskActivity>;
  /**  (live) */
  taskActivityByTaskId: Maybe<TaskActivity>;
  /**  (live) */
  taskByExecutorId: Maybe<Task>;
  /**  (live) */
  taskById: Maybe<Task>;
  /**  (live) */
  taskResourceById: Maybe<TaskResource>;
  /**  (live) */
  taskSourceById: Maybe<TaskSource>;
  /**  (live) */
  taskStatusLogById: Maybe<TaskStatusLog>;
  /**  (live) */
  taskStatusLogByTaskIdAndStatus: Maybe<TaskStatusLog>;
  /**  (live) */
  tenantById: Maybe<Tenant>;
  /**  (live) */
  tenantByKey: Maybe<Tenant>;
  /**  (live) */
  tenantByName: Maybe<Tenant>;
  /**  (live) */
  tenantContractAllowanceById: Maybe<TenantContractAllowance>;
  /**  (live) */
  tenantContractAllowanceByTenantContractTransactionIdAndQuotaType: Maybe<TenantContractAllowance>;
  /**  (live) */
  tenantContractById: Maybe<TenantContract>;
  /**  (live) */
  tenantContractByName: Maybe<TenantContract>;
  /**  (live) */
  tenantContractTransactionById: Maybe<TenantContractTransaction>;
  /**  (live) */
  tenantMockuserexecutorByUserexecutorPtrId: Maybe<TenantMockuserexecutor>;
  /**  (live) */
  tenantMonthlyConsumedResourceById: Maybe<TenantMonthlyConsumedResource>;
  /**  (live) */
  tenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract: Maybe<TenantMonthlyConsumedResource>;
  /**  (live) */
  tenantUserMembershipNotificationById: Maybe<TenantUserMembershipNotification>;
  /**  (live) */
  tenantUserexecutorById: Maybe<TenantUserexecutor>;
  /**  (live) */
  tesContainerimageexecutorById: Maybe<TesContainerimageexecutor>;
  /**  (live) */
  tesMockcontainerimageexecutorByContainerimageexecutorPtrId: Maybe<TesMockcontainerimageexecutor>;
  /**  (live) */
  tesMocktaskexecutorByTaskexecutorPtrId: Maybe<TesMocktaskexecutor>;
  /**  (live) */
  tesTaskexecutorById: Maybe<TesTaskexecutor>;
  /**  (live) */
  toolById: Maybe<Tool>;
  /**  (live) */
  toolByKey: Maybe<Tool>;
  /**  (live) */
  toolCategoryById: Maybe<ToolCategory>;
  /**  (live) */
  toolCategoryByKey: Maybe<ToolCategory>;
  /**  (live) */
  toolDisplayCategoryById: Maybe<ToolDisplayCategory>;
  /**  (live) */
  toolDisplayCategoryByToolIdAndToolcategoryId: Maybe<ToolDisplayCategory>;
  /**  (live) */
  toolParameterById: Maybe<ToolParameter>;
  /**  (live) */
  toolResourceById: Maybe<ToolResource>;
  /**  (live) */
  toolResourceByTypeAndToolVersionId: Maybe<ToolResource>;
  /**  (live) */
  toolResultById: Maybe<ToolResult>;
  /**  (live) */
  toolSecretById: Maybe<ToolSecret>;
  /**  (live) */
  toolSourceById: Maybe<ToolSource>;
  /**  (live) */
  toolTenantById: Maybe<ToolTenant>;
  /**  (live) */
  toolTenantByToolIdAndTenantId: Maybe<ToolTenant>;
  /**  (live) */
  toolVersionById: Maybe<ToolVersion>;
  /**  (live) */
  toolVersionByToolIdAndVersion: Maybe<ToolVersion>;
  /**  (live) */
  toolboxById: Maybe<Toolbox>;
  /**  (live) */
  toolboxByKey: Maybe<Toolbox>;
  /**  (live) */
  toolboxExitcodeByCode: Maybe<ToolboxExitcode>;
  /**  (live) */
  toolboxExitcodeById: Maybe<ToolboxExitcode>;
  /**  (live) */
  toolboxTenantById: Maybe<ToolboxTenant>;
  /**  (live) */
  toolboxTenantByToolboxIdAndTenantId: Maybe<ToolboxTenant>;
  /**  (live) */
  toolboxVersionById: Maybe<ToolboxVersion>;
  /**  (live) */
  toolboxVersionByToolKeyAndToolVersionVersion: Maybe<ToolboxVersion>;
  /**  (live) */
  toolboxVersionByToolboxIdAndVersion: Maybe<ToolboxVersion>;
  /**  (live) */
  toolboxVersionExitcodeById: Maybe<ToolboxVersionExitcode>;
  /**  (live) */
  toolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeId: Maybe<ToolboxVersionExitcode>;
  /**  (live) */
  toolboxVersionToolVersionById: Maybe<ToolboxVersionToolVersion>;
  /**  (live) */
  toolboxVersionToolVersionByToolboxversionIdAndToolversionId: Maybe<ToolboxVersionToolVersion>;
  /**  (live) */
  userExists: Maybe<Scalars["Boolean"]["output"]>;
  /**  (live) */
  userHasActiveSession: Maybe<Scalars["Boolean"]["output"]>;
  /**  (live) */
  userIdIsCurrentUser: Maybe<Scalars["Boolean"]["output"]>;
  /**  (live) */
  userIdIsSettingsGlobalUserId: Maybe<Scalars["Boolean"]["output"]>;
  /**  (live) */
  workflowById: Maybe<Workflow>;
  /**  (live) */
  workflowDatumById: Maybe<WorkflowDatum>;
  /**  (live) */
  workflowResultById: Maybe<WorkflowResult>;
  /**  (live) */
  workflowResultTagById: Maybe<WorkflowResultTag>;
  /**  (live) */
  workflowResultTagByResultIdAndTagId: Maybe<WorkflowResultTag>;
  /**  (live) */
  workflowResultWorkflowVersionById: Maybe<WorkflowResultWorkflowVersion>;
  /**  (live) */
  workflowResultWorkflowVersionByResultIdAndWorkflowversionId: Maybe<WorkflowResultWorkflowVersion>;
  /**  (live) */
  workflowSectionBlockById: Maybe<WorkflowSectionBlock>;
  /**  (live) */
  workflowSectionBlockSectionByBlockIdAndSectionId: Maybe<WorkflowSectionBlockSection>;
  /**  (live) */
  workflowSectionBlockSectionById: Maybe<WorkflowSectionBlockSection>;
  /**  (live) */
  workflowSectionById: Maybe<WorkflowSection>;
  /**  (live) */
  workflowSectionWorkflowVersionById: Maybe<WorkflowSectionWorkflowVersion>;
  /**  (live) */
  workflowSectionWorkflowVersionBySectionIdAndWorkflowversionId: Maybe<WorkflowSectionWorkflowVersion>;
  /**  (live) */
  workflowSourceById: Maybe<WorkflowSource>;
  /**  (live) */
  workflowSourceTagById: Maybe<WorkflowSourceTag>;
  /**  (live) */
  workflowSourceTagBySourceIdAndTagId: Maybe<WorkflowSourceTag>;
  /**  (live) */
  workflowSourceWorkflowVersionById: Maybe<WorkflowSourceWorkflowVersion>;
  /**  (live) */
  workflowSourceWorkflowVersionBySourceIdAndWorkflowversionId: Maybe<WorkflowSourceWorkflowVersion>;
  /**  (live) */
  workflowVersionById: Maybe<WorkflowVersion>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllAnalysisTableGroupsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableGroupCondition>;
  filter?: InputMaybe<AnalysisTableGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTableGroupsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllAnalysisTableRowAttachResultsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableRowAttachResultCondition>;
  filter?: InputMaybe<AnalysisTableRowAttachResultFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTableRowAttachResultsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllAnalysisTableRowsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableRowCondition>;
  filter?: InputMaybe<AnalysisTableRowFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTableRowsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllAnalysisTablesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableCondition>;
  filter?: InputMaybe<AnalysisTableFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTablesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllApplicationUserGroupsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserGroupCondition>;
  filter?: InputMaybe<ApplicationUserGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserGroupsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllApplicationUserMembershipsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserMembershipCondition>;
  filter?: InputMaybe<ApplicationUserMembershipFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserMembershipsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllApplicationUserMetadataArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserMetadatumCondition>;
  filter?: InputMaybe<ApplicationUserMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserMetadataOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllApplicationUserSessionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserSessionCondition>;
  filter?: InputMaybe<ApplicationUserSessionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserSessionsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllApplicationUserUserPermissionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserUserPermissionCondition>;
  filter?: InputMaybe<ApplicationUserUserPermissionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserUserPermissionsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllAuthGroupPermissionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuthGroupPermissionCondition>;
  filter?: InputMaybe<AuthGroupPermissionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuthGroupPermissionsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllAuthGroupsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuthGroupCondition>;
  filter?: InputMaybe<AuthGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuthGroupsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllAuthPermissionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuthPermissionCondition>;
  filter?: InputMaybe<AuthPermissionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuthPermissionsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllAvailableNonRestrictedProjectsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AvailableNonRestrictedProjectCondition>;
  filter?: InputMaybe<AvailableNonRestrictedProjectFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AvailableNonRestrictedProjectsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllAvatarsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AvatarCondition>;
  filter?: InputMaybe<AvatarFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AvatarsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllAwsBatchTasksArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsBatchTaskCondition>;
  filter?: InputMaybe<AwsBatchTaskFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsBatchTasksOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllAwsCognitoUsersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsCognitoUserCondition>;
  filter?: InputMaybe<AwsCognitoUserFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsCognitoUsersOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllAwsEcrImagesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsEcrImageCondition>;
  filter?: InputMaybe<AwsEcrImageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsEcrImagesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllAwsMockBatchTasksArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsMockBatchTaskCondition>;
  filter?: InputMaybe<AwsMockBatchTaskFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsMockBatchTasksOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllAwsMockEcrImagesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsMockEcrImageCondition>;
  filter?: InputMaybe<AwsMockEcrImageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsMockEcrImagesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllAwsMockS3FilesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsMockS3FileCondition>;
  filter?: InputMaybe<AwsMockS3FileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsMockS3FilesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllAwsS3FilesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsS3FileCondition>;
  filter?: InputMaybe<AwsS3FileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsS3FilesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllContainerImagesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ContainerImageCondition>;
  filter?: InputMaybe<ContainerImageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ContainerImagesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllCostsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<CostCondition>;
  filter?: InputMaybe<CostFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CostsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllCurrentApplicationUsersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<CurrentApplicationUserCondition>;
  filter?: InputMaybe<CurrentApplicationUserFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CurrentApplicationUsersOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllCurrentUserMembershipsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<CurrentUserMembershipCondition>;
  filter?: InputMaybe<CurrentUserMembershipFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CurrentUserMembershipsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllDatasetMetadataArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetMetadatumCondition>;
  filter?: InputMaybe<DatasetMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetMetadataOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllDatasetRecordingsTableColumnOrdersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetRecordingsTableColumnOrderCondition>;
  filter?: InputMaybe<DatasetRecordingsTableColumnOrderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetRecordingsTableColumnOrdersOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllDatasetRecordingsTableColumnsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetRecordingsTableColumnCondition>;
  filter?: InputMaybe<DatasetRecordingsTableColumnFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetRecordingsTableColumnsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllDatasetRecordingsTablesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetRecordingsTableCondition>;
  filter?: InputMaybe<DatasetRecordingsTableFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetRecordingsTablesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllDatasetVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetVersionCondition>;
  filter?: InputMaybe<DatasetVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetVersionsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllDatasetsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetCondition>;
  filter?: InputMaybe<DatasetFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllDjangoAdminLogsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DjangoAdminLogCondition>;
  filter?: InputMaybe<DjangoAdminLogFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DjangoAdminLogsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllDjangoContentTypesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DjangoContentTypeCondition>;
  filter?: InputMaybe<DjangoContentTypeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DjangoContentTypesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllDjangoMigrationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DjangoMigrationCondition>;
  filter?: InputMaybe<DjangoMigrationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DjangoMigrationsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllDjangoSesSesstatsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DjangoSesSesstatCondition>;
  filter?: InputMaybe<DjangoSesSesstatFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DjangoSesSesstatsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllDjangoSessionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DjangoSessionCondition>;
  filter?: InputMaybe<DjangoSessionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DjangoSessionsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllDrsFileexecutorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DrsFileexecutorCondition>;
  filter?: InputMaybe<DrsFileexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DrsFileexecutorsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllDrsMockfileexecutorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DrsMockfileexecutorCondition>;
  filter?: InputMaybe<DrsMockfileexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DrsMockfileexecutorsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllEulaVersionUserAcceptancesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<EulaVersionUserAcceptanceCondition>;
  filter?: InputMaybe<EulaVersionUserAcceptanceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<EulaVersionUserAcceptancesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllEulaVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<EulaVersionCondition>;
  filter?: InputMaybe<EulaVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<EulaVersionsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllExplorerQueriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ExplorerQueryCondition>;
  filter?: InputMaybe<ExplorerQueryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExplorerQueriesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllExplorerQueryfavoritesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ExplorerQueryfavoriteCondition>;
  filter?: InputMaybe<ExplorerQueryfavoriteFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExplorerQueryfavoritesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllExplorerQuerylogsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ExplorerQuerylogCondition>;
  filter?: InputMaybe<ExplorerQuerylogFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ExplorerQuerylogsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllFileActivitiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileActivityCondition>;
  filter?: InputMaybe<FileActivityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileActivitiesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllFileMetadataArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileMetadatumCondition>;
  filter?: InputMaybe<FileMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileMetadataOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllFileOutputGroupMetadataArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileOutputGroupMetadatumCondition>;
  filter?: InputMaybe<FileOutputGroupMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileOutputGroupMetadataOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllFileOutputGroupsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileOutputGroupCondition>;
  filter?: InputMaybe<FileOutputGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileOutputGroupsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllFileProcessingNotificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileProcessingNotificationCondition>;
  filter?: InputMaybe<FileProcessingNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileProcessingNotificationsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllFileRecordingGroupMetadataArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileRecordingGroupMetadatumCondition>;
  filter?: InputMaybe<FileRecordingGroupMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileRecordingGroupMetadataOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllFileRecordingGroupsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileRecordingGroupCondition>;
  filter?: InputMaybe<FileRecordingGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileRecordingGroupsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllFilesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileCondition>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllNotificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NotificationCondition>;
  filter?: InputMaybe<NotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NotificationsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllOutputGroupFilesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OutputGroupFileCondition>;
  filter?: InputMaybe<OutputGroupFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OutputGroupFilesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllProjectClonedNotificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProjectClonedNotificationCondition>;
  filter?: InputMaybe<ProjectClonedNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProjectClonedNotificationsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllProjectSharedNotificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProjectSharedNotificationCondition>;
  filter?: InputMaybe<ProjectSharedNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProjectSharedNotificationsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllProjectsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllRecordingGroupFilesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<RecordingGroupFileCondition>;
  filter?: InputMaybe<RecordingGroupFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RecordingGroupFilesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllRoleChangeNotificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<RoleChangeNotificationCondition>;
  filter?: InputMaybe<RoleChangeNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RoleChangeNotificationsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllRunsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<RunCondition>;
  filter?: InputMaybe<RunFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RunsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllTagsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TagCondition>;
  filter?: InputMaybe<TagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllTaskActivitiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskActivityCondition>;
  filter?: InputMaybe<TaskActivityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TaskActivitiesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllTaskResourcesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskResourceCondition>;
  filter?: InputMaybe<TaskResourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TaskResourcesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllTaskSourcesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskSourceCondition>;
  filter?: InputMaybe<TaskSourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TaskSourcesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllTaskStatusLogsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskStatusLogCondition>;
  filter?: InputMaybe<TaskStatusLogFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TaskStatusLogsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllTasksArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskCondition>;
  filter?: InputMaybe<TaskFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllTenantContractAllowancesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantContractAllowanceCondition>;
  filter?: InputMaybe<TenantContractAllowanceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantContractAllowancesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllTenantContractTransactionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantContractTransactionCondition>;
  filter?: InputMaybe<TenantContractTransactionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantContractTransactionsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllTenantContractsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantContractCondition>;
  filter?: InputMaybe<TenantContractFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantContractsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllTenantMockuserexecutorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantMockuserexecutorCondition>;
  filter?: InputMaybe<TenantMockuserexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantMockuserexecutorsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllTenantMonthlyConsumedResourcesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantMonthlyConsumedResourceCondition>;
  filter?: InputMaybe<TenantMonthlyConsumedResourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantMonthlyConsumedResourcesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllTenantUserMembershipNotificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantUserMembershipNotificationCondition>;
  filter?: InputMaybe<TenantUserMembershipNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantUserMembershipNotificationsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllTenantUserexecutorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantUserexecutorCondition>;
  filter?: InputMaybe<TenantUserexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantUserexecutorsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllTesContainerimageexecutorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TesContainerimageexecutorCondition>;
  filter?: InputMaybe<TesContainerimageexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TesContainerimageexecutorsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllTesMockcontainerimageexecutorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TesMockcontainerimageexecutorCondition>;
  filter?: InputMaybe<TesMockcontainerimageexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TesMockcontainerimageexecutorsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllTesMocktaskexecutorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TesMocktaskexecutorCondition>;
  filter?: InputMaybe<TesMocktaskexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TesMocktaskexecutorsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllTesTaskexecutorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TesTaskexecutorCondition>;
  filter?: InputMaybe<TesTaskexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TesTaskexecutorsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllToolCategoriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolCategoryCondition>;
  filter?: InputMaybe<ToolCategoryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolCategoriesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllToolDisplayCategoriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolDisplayCategoryCondition>;
  filter?: InputMaybe<ToolDisplayCategoryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolDisplayCategoriesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllToolParametersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolParameterCondition>;
  filter?: InputMaybe<ToolParameterFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolParametersOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllToolResourcesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolResourceCondition>;
  filter?: InputMaybe<ToolResourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolResourcesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllToolResultsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolResultCondition>;
  filter?: InputMaybe<ToolResultFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolResultsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllToolSecretsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolSecretCondition>;
  filter?: InputMaybe<ToolSecretFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolSecretsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllToolSourcesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolSourceCondition>;
  filter?: InputMaybe<ToolSourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolSourcesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllToolTenantsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolTenantCondition>;
  filter?: InputMaybe<ToolTenantFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolTenantsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllToolVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolVersionCondition>;
  filter?: InputMaybe<ToolVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolVersionsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllToolboxExitcodesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxExitcodeCondition>;
  filter?: InputMaybe<ToolboxExitcodeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxExitcodesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllToolboxTenantsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxTenantCondition>;
  filter?: InputMaybe<ToolboxTenantFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxTenantsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllToolboxVersionExitcodesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxVersionExitcodeCondition>;
  filter?: InputMaybe<ToolboxVersionExitcodeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxVersionExitcodesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllToolboxVersionToolVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxVersionToolVersionCondition>;
  filter?: InputMaybe<ToolboxVersionToolVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxVersionToolVersionsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllToolboxVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxVersionCondition>;
  filter?: InputMaybe<ToolboxVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxVersionsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllToolboxesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxCondition>;
  filter?: InputMaybe<ToolboxFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllToolsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolCondition>;
  filter?: InputMaybe<ToolFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllUserAccessToProjectsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<UserAccessToProjectCondition>;
  filter?: InputMaybe<UserAccessToProjectFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserAccessToProjectsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllWorkflowDataArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowDatumCondition>;
  filter?: InputMaybe<WorkflowDatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowDataOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllWorkflowResultTagsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowResultTagCondition>;
  filter?: InputMaybe<WorkflowResultTagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowResultTagsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllWorkflowResultWorkflowVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowResultWorkflowVersionCondition>;
  filter?: InputMaybe<WorkflowResultWorkflowVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowResultWorkflowVersionsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllWorkflowResultsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowResultCondition>;
  filter?: InputMaybe<WorkflowResultFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowResultsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllWorkflowSectionBlockSectionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSectionBlockSectionCondition>;
  filter?: InputMaybe<WorkflowSectionBlockSectionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSectionBlockSectionsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllWorkflowSectionBlocksArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSectionBlockCondition>;
  filter?: InputMaybe<WorkflowSectionBlockFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSectionBlocksOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllWorkflowSectionWorkflowVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSectionWorkflowVersionCondition>;
  filter?: InputMaybe<WorkflowSectionWorkflowVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSectionWorkflowVersionsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllWorkflowSectionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSectionCondition>;
  filter?: InputMaybe<WorkflowSectionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSectionsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllWorkflowSourceTagsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSourceTagCondition>;
  filter?: InputMaybe<WorkflowSourceTagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSourceTagsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllWorkflowSourceWorkflowVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSourceWorkflowVersionCondition>;
  filter?: InputMaybe<WorkflowSourceWorkflowVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSourceWorkflowVersionsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllWorkflowSourcesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSourceCondition>;
  filter?: InputMaybe<WorkflowSourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSourcesOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllWorkflowVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowVersionCondition>;
  filter?: InputMaybe<WorkflowVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowVersionsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAllWorkflowsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowCondition>;
  filter?: InputMaybe<WorkflowFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowsOrderBy>>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAnalysisTableByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAnalysisTableGroupByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAnalysisTableRowAttachResultByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAnalysisTableRowByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAnalysisTableRowByTaskIdArgs = {
  taskId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionApplicationUserByEmailArgs = {
  email: Scalars["String"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionApplicationUserByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionApplicationUserByUsernameArgs = {
  username: Scalars["String"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionApplicationUserGroupByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionApplicationUserGroupByUserIdAndGroupIdArgs = {
  groupId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionApplicationUserMembershipByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionApplicationUserMembershipByUserIdAndTenantIdArgs = {
  tenantId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionApplicationUserMetadatumByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionApplicationUserMetadatumByUserIdAndKeyArgs = {
  key: Scalars["String"]["input"];
  userId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionApplicationUserSessionByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionApplicationUserUserPermissionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionApplicationUserUserPermissionByUserIdAndPermissionIdArgs =
  {
    permissionId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAssertUserCanSeeTenantInfoArgs = {
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAssertUserCanSeeUserInfoArgs = {
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAssertUserHasProjectSharedByTenantArgs = {
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAssertUserHasTenantOfIdArgs = {
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAssertUserIsInvitedToTenantArgs = {
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAuthGroupByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAuthGroupByNameArgs = {
  name: Scalars["String"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAuthGroupPermissionByGroupIdAndPermissionIdArgs = {
  groupId: Scalars["Int"]["input"];
  permissionId: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAuthGroupPermissionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAuthPermissionByContentTypeIdAndCodenameArgs = {
  codename: Scalars["String"]["input"];
  contentTypeId: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAuthPermissionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAvatarByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAvatarByUserIdArgs = {
  userId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAwsBatchTaskByTaskexecutorPtrIdArgs = {
  taskexecutorPtrId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAwsCognitoUserByUserexecutorPtrIdArgs = {
  userexecutorPtrId: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAwsEcrImageByContainerimageexecutorPtrIdArgs = {
  containerimageexecutorPtrId: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAwsMockBatchTaskByAwstaskexecutorPtrIdArgs = {
  awstaskexecutorPtrId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAwsMockEcrImageByAwscontainerimageexecutorPtrIdArgs = {
  awscontainerimageexecutorPtrId: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAwsMockS3FileByAwsfileexecutorPtrIdArgs = {
  awsfileexecutorPtrId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAwsS3FileByFileexecutorPtrIdArgs = {
  fileexecutorPtrId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionContainerImageByExecutorIdArgs = {
  executorId: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionContainerImageByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionContainerImageByNameAndTagArgs = {
  name: Scalars["String"]["input"];
  tag: Scalars["String"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionContainerImageByRepositoryAndTagArgs = {
  repository: Scalars["String"]["input"];
  tag: Scalars["String"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionContainerImageByToolboxVersionIdArgs = {
  toolboxVersionId: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCostByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDatasetByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDatasetByProjectIdAndPrefixArgs = {
  prefix: Scalars["String"]["input"];
  projectId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDatasetByRecordingsTableIdArgs = {
  recordingsTableId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDatasetByTenantIdAndKeyArgs = {
  key: Scalars["String"]["input"];
  tenantId: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDatasetMetadatumByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDatasetRecordingsTableByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDatasetRecordingsTableColumnByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDatasetRecordingsTableColumnOrderByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDatasetVersionByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDjangoAdminLogByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDjangoContentTypeByAppLabelAndModelArgs = {
  appLabel: Scalars["String"]["input"];
  model: Scalars["String"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDjangoContentTypeByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDjangoMigrationByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDjangoSesSesstatByDateArgs = {
  date: Scalars["Date"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDjangoSesSesstatByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDjangoSessionBySessionKeyArgs = {
  sessionKey: Scalars["String"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDrsFileexecutorByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionDrsMockfileexecutorByFileexecutorPtrIdArgs = {
  fileexecutorPtrId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionEulaVersionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionEulaVersionByVersionArgs = {
  version: Scalars["String"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionEulaVersionUserAcceptanceByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionEulaVersionUserAcceptanceByUserIdAndEulaVersionIdArgs =
  {
    eulaVersionId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionExplorerQueryByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionExplorerQueryfavoriteByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionExplorerQueryfavoriteByQueryIdAndUserIdArgs = {
  queryId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionExplorerQuerylogByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFileActivityByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFileByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFileMetadatumByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFileOutputGroupByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFileOutputGroupByTaskIdArgs = {
  taskId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFileOutputGroupMetadatumByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFileProcessingNotificationByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFileRecordingGroupByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFileRecordingGroupMetadatumByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionGetUserActiveSessionArgs = {
  userid: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMetadataValueByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMetadatumByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionNotificationByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionOutputGroupFileByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionProjectByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionProjectByKeyAndTenantIdArgs = {
  key: Scalars["String"]["input"];
  tenantId: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionProjectClonedNotificationByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionProjectSharedNotificationByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionRecordingGroupFileByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionRoleChangeNotificationByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionRunByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionSessionIsActiveSessionArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTagByIdArgs = {
  id: Scalars["BigInt"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTaskActivityByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTaskActivityByTaskIdArgs = {
  taskId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTaskByExecutorIdArgs = {
  executorId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTaskByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTaskResourceByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTaskSourceByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTaskStatusLogByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTaskStatusLogByTaskIdAndStatusArgs = {
  status: Scalars["Int"]["input"];
  taskId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTenantByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTenantByKeyArgs = {
  key: Scalars["String"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTenantByNameArgs = {
  name: Scalars["String"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTenantContractAllowanceByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeArgs =
  {
    quotaType: Scalars["Int"]["input"];
    tenantContractTransactionId: Scalars["Int"]["input"];
  };

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTenantContractByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTenantContractByNameArgs = {
  name: Scalars["String"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTenantContractTransactionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTenantMockuserexecutorByUserexecutorPtrIdArgs = {
  userexecutorPtrId: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTenantMonthlyConsumedResourceByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractArgs =
  {
    month: Scalars["Date"]["input"];
    monthInActiveContract: Scalars["Int"]["input"];
    quotaType: Scalars["Int"]["input"];
    tenantContractId: Scalars["Int"]["input"];
    tenantId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTenantUserMembershipNotificationByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTenantUserexecutorByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTesContainerimageexecutorByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTesMockcontainerimageexecutorByContainerimageexecutorPtrIdArgs =
  {
    containerimageexecutorPtrId: Scalars["Int"]["input"];
  };

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTesMocktaskexecutorByTaskexecutorPtrIdArgs = {
  taskexecutorPtrId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTesTaskexecutorByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolByKeyArgs = {
  key: Scalars["String"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolCategoryByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolCategoryByKeyArgs = {
  key: Scalars["String"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolDisplayCategoryByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolDisplayCategoryByToolIdAndToolcategoryIdArgs = {
  toolId: Scalars["Int"]["input"];
  toolcategoryId: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolParameterByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolResourceByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolResourceByTypeAndToolVersionIdArgs = {
  toolVersionId: Scalars["Int"]["input"];
  type: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolResultByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolSecretByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolSourceByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolTenantByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolTenantByToolIdAndTenantIdArgs = {
  tenantId: Scalars["Int"]["input"];
  toolId: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolVersionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolVersionByToolIdAndVersionArgs = {
  toolId: Scalars["Int"]["input"];
  version: Scalars["String"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolboxByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolboxByKeyArgs = {
  key: Scalars["String"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolboxExitcodeByCodeArgs = {
  code: Scalars["String"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolboxExitcodeByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolboxTenantByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolboxTenantByToolboxIdAndTenantIdArgs = {
  tenantId: Scalars["Int"]["input"];
  toolboxId: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolboxVersionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolboxVersionByToolKeyAndToolVersionVersionArgs = {
  toolKey?: InputMaybe<Scalars["String"]["input"]>;
  toolVersionVersion?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolboxVersionByToolboxIdAndVersionArgs = {
  toolboxId: Scalars["Int"]["input"];
  version: Scalars["String"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolboxVersionExitcodeByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdArgs =
  {
    toolboxexitcodeId: Scalars["Int"]["input"];
    toolboxversionId: Scalars["Int"]["input"];
  };

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolboxVersionToolVersionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionToolboxVersionToolVersionByToolboxversionIdAndToolversionIdArgs =
  {
    toolboxversionId: Scalars["Int"]["input"];
    toolversionId: Scalars["Int"]["input"];
  };

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserHasActiveSessionArgs = {
  userid: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserIdIsCurrentUserArgs = {
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserIdIsSettingsGlobalUserIdArgs = {
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowDatumByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowResultByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowResultTagByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowResultTagByResultIdAndTagIdArgs = {
  resultId: Scalars["UUID"]["input"];
  tagId: Scalars["BigInt"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowResultWorkflowVersionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdArgs =
  {
    resultId: Scalars["UUID"]["input"];
    workflowversionId: Scalars["UUID"]["input"];
  };

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowSectionBlockByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowSectionBlockSectionByBlockIdAndSectionIdArgs = {
  blockId: Scalars["UUID"]["input"];
  sectionId: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowSectionBlockSectionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowSectionByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowSectionWorkflowVersionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdArgs =
  {
    sectionId: Scalars["UUID"]["input"];
    workflowversionId: Scalars["UUID"]["input"];
  };

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowSourceByIdArgs = {
  id: Scalars["UUID"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowSourceTagByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowSourceTagBySourceIdAndTagIdArgs = {
  sourceId: Scalars["UUID"]["input"];
  tagId: Scalars["BigInt"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowSourceWorkflowVersionByIdArgs = {
  id: Scalars["Int"]["input"];
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdArgs =
  {
    sourceId: Scalars["UUID"]["input"];
    workflowversionId: Scalars["UUID"]["input"];
  };

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWorkflowVersionByIdArgs = {
  id: Scalars["UUID"]["input"];
};

export type Tag = {
  __typename: "Tag";
  /** Reads a single `ApplicationUser` that is related to this `Tag`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  dateCreated: Scalars["Datetime"]["output"];
  id: Scalars["BigInt"]["output"];
  label: Scalars["String"]["output"];
  parameters: Maybe<Scalars["JSON"]["output"]>;
  parentId: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads a single `Tag` that is related to this `Tag`. */
  tagByParentId: Maybe<Tag>;
  /** Reads and enables pagination through a set of `Tag`. */
  tagsByParentId: TagsConnection;
  /** Reads a single `Tenant` that is related to this `Tag`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  userId: Maybe<Scalars["UUID"]["output"]>;
  /** Reads and enables pagination through a set of `WorkflowResultTag`. */
  workflowResultTagsByTagId: WorkflowResultTagsConnection;
  /** Reads and enables pagination through a set of `WorkflowSourceTag`. */
  workflowSourceTagsByTagId: WorkflowSourceTagsConnection;
};

export type TagTagsByParentIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TagCondition>;
  filter?: InputMaybe<TagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
};

export type TagWorkflowResultTagsByTagIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowResultTagCondition>;
  filter?: InputMaybe<WorkflowResultTagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowResultTagsOrderBy>>;
};

export type TagWorkflowSourceTagsByTagIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSourceTagCondition>;
  filter?: InputMaybe<WorkflowSourceTagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSourceTagsOrderBy>>;
};

/** A condition to be used against `Tag` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TagCondition = {
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `label` field. */
  label?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `parameters` field. */
  parameters?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `Tag` object types. All fields are combined with a logical ‘and.’ */
export type TagFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TagFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** A related `applicationUserByUserId` exists. */
  applicationUserByUserIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `label` field. */
  label?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TagFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TagFilter>>;
  /** Filter by the object’s `parameters` field. */
  parameters?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `parentId` field. */
  parentId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `tagByParentId` relation. */
  tagByParentId?: InputMaybe<TagFilter>;
  /** A related `tagByParentId` exists. */
  tagByParentIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tagsByParentId` relation. */
  tagsByParentId?: InputMaybe<TagToManyTagFilter>;
  /** Some related `tagsByParentId` exist. */
  tagsByParentIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `workflowResultTagsByTagId` relation. */
  workflowResultTagsByTagId?: InputMaybe<TagToManyWorkflowResultTagFilter>;
  /** Some related `workflowResultTagsByTagId` exist. */
  workflowResultTagsByTagIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `workflowSourceTagsByTagId` relation. */
  workflowSourceTagsByTagId?: InputMaybe<TagToManyWorkflowSourceTagFilter>;
  /** Some related `workflowSourceTagsByTagId` exist. */
  workflowSourceTagsByTagIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** An input for mutations affecting `Tag` */
export type TagInput = {
  applicationUserToUserId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  label: Scalars["String"]["input"];
  parameters?: InputMaybe<Scalars["JSON"]["input"]>;
  parentId?: InputMaybe<Scalars["BigInt"]["input"]>;
  tagToParentId?: InputMaybe<TagParentIdD2A3Ee5EFkTagIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TagTenantId8072B61FFkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdInverseInput>;
  workflowSourceTagsUsingId?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdInverseInput>;
};

/** The fields on `tag` to look up the row to update. */
export type TagOnTagForTagParentIdD2A3Ee5EFkTagIdUsingTagPkeyUpdate = {
  id: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `tag` being updated. */
  tagPatch: UpdateTagOnTagForTagParentIdD2A3Ee5EFkTagIdPatch;
};

/** The fields on `tag` to look up the row to update. */
export type TagOnTagForTagTenantId8072B61FFkTenantIdUsingTagPkeyUpdate = {
  id: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `tag` being updated. */
  tagPatch: UpdateTagOnTagForTagTenantId8072B61FFkTenantIdPatch;
};

/** The fields on `tag` to look up the row to update. */
export type TagOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingTagPkeyUpdate =
  {
    id: Scalars["BigInt"]["input"];
    /** An object where the defined keys will be set on the `tag` being updated. */
    tagPatch: UpdateTagOnTagForTagUserIdB3B7Fb50FkApplicationUserIdPatch;
  };

/** The fields on `tag` to look up the row to update. */
export type TagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdUsingTagPkeyUpdate =
  {
    id: Scalars["BigInt"]["input"];
    /** An object where the defined keys will be set on the `tag` being updated. */
    tagPatch: UpdateTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdPatch;
  };

/** The fields on `tag` to look up the row to update. */
export type TagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdUsingTagPkeyUpdate =
  {
    id: Scalars["BigInt"]["input"];
    /** An object where the defined keys will be set on the `tag` being updated. */
    tagPatch: UpdateTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdPatch;
  };

/** Input for the nested mutation of `tag` in the `TagInput` mutation. */
export type TagParentIdD2A3Ee5EFkTagIdInput = {
  /** The primary key(s) for `tag` for the far side of the relationship. */
  connectById?: InputMaybe<TagTagPkeyConnect>;
  /** A `TagInput` object that will be created and connected to this object. */
  create?: InputMaybe<TagParentIdD2A3Ee5EFkTagIdTagCreateInput>;
  /** The primary key(s) for `tag` for the far side of the relationship. */
  deleteById?: InputMaybe<TagTagPkeyDelete>;
  /** The primary key(s) and patch data for `tag` for the far side of the relationship. */
  updateById?: InputMaybe<TagOnTagForTagParentIdD2A3Ee5EFkTagIdUsingTagPkeyUpdate>;
};

/** The `tag` to be created by this mutation. */
export type TagParentIdD2A3Ee5EFkTagIdTagCreateInput = {
  applicationUserToUserId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  label: Scalars["String"]["input"];
  parameters?: InputMaybe<Scalars["JSON"]["input"]>;
  tagToParentId?: InputMaybe<TagParentIdD2A3Ee5EFkTagIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TagTenantId8072B61FFkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdInverseInput>;
  workflowSourceTagsUsingId?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdInverseInput>;
};

/** Represents an update to a `Tag`. Fields that are set will be updated. */
export type TagPatch = {
  applicationUserToUserId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  parameters?: InputMaybe<Scalars["JSON"]["input"]>;
  parentId?: InputMaybe<Scalars["BigInt"]["input"]>;
  tagToParentId?: InputMaybe<TagParentIdD2A3Ee5EFkTagIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TagTenantId8072B61FFkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdInverseInput>;
  workflowSourceTagsUsingId?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdInverseInput>;
};

/** The fields on `tag` to look up the row to connect. */
export type TagTagPkeyConnect = {
  id: Scalars["BigInt"]["input"];
};

/** The fields on `tag` to look up the row to delete. */
export type TagTagPkeyDelete = {
  id: Scalars["BigInt"]["input"];
};

/** Input for the nested mutation of `tenant` in the `TagInput` mutation. */
export type TagTenantId8072B61FFkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<TagTenantId8072B61FFkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnTagForTagTenantId8072B61FFkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnTagForTagTenantId8072B61FFkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnTagForTagTenantId8072B61FFkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `tag` in the `TenantInput` mutation. */
export type TagTenantId8072B61FFkTenantIdInverseInput = {
  /** The primary key(s) for `tag` for the far side of the relationship. */
  connectById?: InputMaybe<Array<TagTagPkeyConnect>>;
  /** A `TagInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<TagTenantId8072B61FFkTenantIdTagCreateInput>>;
  /** The primary key(s) for `tag` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<TagTagPkeyDelete>>;
  /** Flag indicating whether all other `tag` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `tag` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TagOnTagForTagTenantId8072B61FFkTenantIdUsingTagPkeyUpdate>
  >;
};

/** The `tag` to be created by this mutation. */
export type TagTenantId8072B61FFkTenantIdTagCreateInput = {
  applicationUserToUserId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  label: Scalars["String"]["input"];
  parameters?: InputMaybe<Scalars["JSON"]["input"]>;
  parentId?: InputMaybe<Scalars["BigInt"]["input"]>;
  tagToParentId?: InputMaybe<TagParentIdD2A3Ee5EFkTagIdInput>;
  tenantToTenantId?: InputMaybe<TagTenantId8072B61FFkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdInverseInput>;
  workflowSourceTagsUsingId?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdInverseInput>;
};

/** The `tenant` to be created by this mutation. */
export type TagTenantId8072B61FFkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** A filter to be used against many `Tag` object types. All fields are combined with a logical ‘and.’ */
export type TagToManyTagFilter = {
  /** Every related `Tag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TagFilter>;
  /** No related `Tag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TagFilter>;
  /** Some related `Tag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TagFilter>;
};

/** A filter to be used against many `WorkflowResultTag` object types. All fields are combined with a logical ‘and.’ */
export type TagToManyWorkflowResultTagFilter = {
  /** Every related `WorkflowResultTag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowResultTagFilter>;
  /** No related `WorkflowResultTag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowResultTagFilter>;
  /** Some related `WorkflowResultTag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowResultTagFilter>;
};

/** A filter to be used against many `WorkflowSourceTag` object types. All fields are combined with a logical ‘and.’ */
export type TagToManyWorkflowSourceTagFilter = {
  /** Every related `WorkflowSourceTag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowSourceTagFilter>;
  /** No related `WorkflowSourceTag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowSourceTagFilter>;
  /** Some related `WorkflowSourceTag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowSourceTagFilter>;
};

/** The `applicationUser` to be created by this mutation. */
export type TagUserIdB3B7Fb50FkApplicationUserIdApplicationUserCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
  applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
  applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
  applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
  avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
  cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
  djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
  email: Scalars["String"]["input"];
  eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
  executorId?: InputMaybe<Scalars["Int"]["input"]>;
  explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
  explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
  explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
  filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
  firstName: Scalars["String"]["input"];
  globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  homeRegion?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  internal: Scalars["Boolean"]["input"];
  isActive: Scalars["Boolean"]["input"];
  isStaff: Scalars["Boolean"]["input"];
  isSuperuser: Scalars["Boolean"]["input"];
  lastName: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
  notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
  projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
  projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
  projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
  projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
  tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
  tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
  tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
  tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
  username: Scalars["String"]["input"];
  workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
};

/** Input for the nested mutation of `applicationUser` in the `TagInput` mutation. */
export type TagUserIdB3B7Fb50FkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `tag` in the `ApplicationUserInput` mutation. */
export type TagUserIdB3B7Fb50FkApplicationUserIdInverseInput = {
  /** The primary key(s) for `tag` for the far side of the relationship. */
  connectById?: InputMaybe<Array<TagTagPkeyConnect>>;
  /** A `TagInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<TagUserIdB3B7Fb50FkApplicationUserIdTagCreateInput>
  >;
  /** The primary key(s) for `tag` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<TagTagPkeyDelete>>;
  /** Flag indicating whether all other `tag` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `tag` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TagOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingTagPkeyUpdate>
  >;
};

/** The `tag` to be created by this mutation. */
export type TagUserIdB3B7Fb50FkApplicationUserIdTagCreateInput = {
  applicationUserToUserId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  label: Scalars["String"]["input"];
  parameters?: InputMaybe<Scalars["JSON"]["input"]>;
  parentId?: InputMaybe<Scalars["BigInt"]["input"]>;
  tagToParentId?: InputMaybe<TagParentIdD2A3Ee5EFkTagIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TagTenantId8072B61FFkTenantIdInput>;
  workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdInverseInput>;
  workflowSourceTagsUsingId?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdInverseInput>;
};

/** A connection to a list of `Tag` values. */
export type TagsConnection = {
  __typename: "TagsConnection";
  /** A list of edges which contains the `Tag` and cursor to aid in pagination. */
  edges: Array<TagsEdge>;
  /** A list of `Tag` objects. */
  nodes: Array<Tag>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Tag` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Tag` edge in the connection. */
export type TagsEdge = {
  __typename: "TagsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Tag` at the end of the edge. */
  node: Tag;
};

/** Methods to use when ordering `Tag`. */
export enum TagsOrderBy {
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LabelAsc = "LABEL_ASC",
  LabelDesc = "LABEL_DESC",
  Natural = "NATURAL",
  ParametersAsc = "PARAMETERS_ASC",
  ParametersDesc = "PARAMETERS_DESC",
  ParentIdAsc = "PARENT_ID_ASC",
  ParentIdDesc = "PARENT_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type Task = {
  __typename: "Task";
  /** Reads a single `AnalysisTableRow` that is related to this `Task`. */
  analysisTableRowByTaskId: Maybe<AnalysisTableRow>;
  /**
   * Reads and enables pagination through a set of `AnalysisTableRow`.
   * @deprecated Please use analysisTableRowByTaskId instead
   */
  analysisTableRowsByTaskId: AnalysisTableRowsConnection;
  /** Reads a single `ApplicationUser` that is related to this `Task`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  cloned: Scalars["Boolean"]["output"];
  /** Reads a single `ContainerImage` that is related to this `Task`. */
  containerImageByContainerImageId: Maybe<ContainerImage>;
  containerImageId: Maybe<Scalars["Int"]["output"]>;
  created: Scalars["Datetime"]["output"];
  credits: Maybe<Scalars["Float"]["output"]>;
  /** Reads a single `Dataset` that is related to this `Task`. */
  datasetByDatasetId: Maybe<Dataset>;
  datasetId: Maybe<Scalars["UUID"]["output"]>;
  errorDescription: Maybe<Scalars["JSON"]["output"]>;
  executorId: Maybe<Scalars["UUID"]["output"]>;
  /** Reads a single `FileOutputGroup` that is related to this `Task`. */
  fileOutputGroupByTaskId: Maybe<FileOutputGroup>;
  /**
   * Reads and enables pagination through a set of `FileOutputGroup`.
   * @deprecated Please use fileOutputGroupByTaskId instead
   */
  fileOutputGroupsByTaskId: FileOutputGroupsConnection;
  id: Scalars["UUID"]["output"];
  inputsHash: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `MetadataValue`. */
  metadataValuesByTaskId: MetadataValuesConnection;
  /** Reads a single `Project` that is related to this `Task`. */
  projectByProjectId: Maybe<Project>;
  projectId: Maybe<Scalars["UUID"]["output"]>;
  status: Scalars["Int"]["output"];
  /**
   * Reads and enables pagination through a set of `TaskActivity`.
   * @deprecated Please use taskActivityByTaskId instead
   */
  taskActivitiesByTaskId: TaskActivitiesConnection;
  /** Reads a single `TaskActivity` that is related to this `Task`. */
  taskActivityByTaskId: Maybe<TaskActivity>;
  taskParameters: Maybe<Scalars["JSON"]["output"]>;
  /** Reads and enables pagination through a set of `TaskResource`. */
  taskResourcesByTaskId: TaskResourcesConnection;
  /** Reads and enables pagination through a set of `TaskSource`. */
  taskSourcesByTaskId: TaskSourcesConnection;
  /** Reads and enables pagination through a set of `TaskStatusLog`. */
  taskStatusLogsByTaskId: TaskStatusLogsConnection;
  /** Reads a single `Tenant` that is related to this `Task`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  /** Reads a single `TesTaskexecutor` that is related to this `Task`. */
  tesTaskexecutorByExecutorId: Maybe<TesTaskexecutor>;
  toolExitcode: Maybe<Scalars["String"]["output"]>;
  toolExitcodeDescription: Maybe<Scalars["JSON"]["output"]>;
  /** Reads a single `ToolVersion` that is related to this `Task`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
  toolVersionId: Scalars["Int"]["output"];
  userId: Scalars["UUID"]["output"];
};

export type TaskAnalysisTableRowsByTaskIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableRowCondition>;
  filter?: InputMaybe<AnalysisTableRowFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTableRowsOrderBy>>;
};

export type TaskFileOutputGroupsByTaskIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileOutputGroupCondition>;
  filter?: InputMaybe<FileOutputGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileOutputGroupsOrderBy>>;
};

export type TaskMetadataValuesByTaskIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MetadataValueCondition>;
  filter?: InputMaybe<MetadataValueFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MetadataValuesOrderBy>>;
};

export type TaskTaskActivitiesByTaskIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskActivityCondition>;
  filter?: InputMaybe<TaskActivityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TaskActivitiesOrderBy>>;
};

export type TaskTaskResourcesByTaskIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskResourceCondition>;
  filter?: InputMaybe<TaskResourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TaskResourcesOrderBy>>;
};

export type TaskTaskSourcesByTaskIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskSourceCondition>;
  filter?: InputMaybe<TaskSourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TaskSourcesOrderBy>>;
};

export type TaskTaskStatusLogsByTaskIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskStatusLogCondition>;
  filter?: InputMaybe<TaskStatusLogFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TaskStatusLogsOrderBy>>;
};

/** A connection to a list of `TaskActivity` values. */
export type TaskActivitiesConnection = {
  __typename: "TaskActivitiesConnection";
  /** A list of edges which contains the `TaskActivity` and cursor to aid in pagination. */
  edges: Array<TaskActivitiesEdge>;
  /** A list of `TaskActivity` objects. */
  nodes: Array<TaskActivity>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaskActivity` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `TaskActivity` edge in the connection. */
export type TaskActivitiesEdge = {
  __typename: "TaskActivitiesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TaskActivity` at the end of the edge. */
  node: TaskActivity;
};

/** Methods to use when ordering `TaskActivity`. */
export enum TaskActivitiesOrderBy {
  ClonedAsc = "CLONED_ASC",
  ClonedDesc = "CLONED_DESC",
  CostIdAsc = "COST_ID_ASC",
  CostIdDesc = "COST_ID_DESC",
  DateAsc = "DATE_ASC",
  DateDesc = "DATE_DESC",
  DurationAsc = "DURATION_ASC",
  DurationDesc = "DURATION_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InstanceTypeAsc = "INSTANCE_TYPE_ASC",
  InstanceTypeDesc = "INSTANCE_TYPE_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TaskIdAsc = "TASK_ID_ASC",
  TaskIdDesc = "TASK_ID_DESC",
}

export type TaskActivity = {
  __typename: "TaskActivity";
  cloned: Scalars["Boolean"]["output"];
  /** Reads a single `Cost` that is related to this `TaskActivity`. */
  costByCostId: Maybe<Cost>;
  costId: Maybe<Scalars["BigInt"]["output"]>;
  date: Scalars["Datetime"]["output"];
  duration: Interval;
  id: Scalars["Int"]["output"];
  instanceType: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Task` that is related to this `TaskActivity`. */
  taskByTaskId: Maybe<Task>;
  taskId: Scalars["UUID"]["output"];
};

/**
 * A condition to be used against `TaskActivity` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TaskActivityCondition = {
  /** Checks for equality with the object’s `cloned` field. */
  cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `costId` field. */
  costId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `date` field. */
  date?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `duration` field. */
  duration?: InputMaybe<IntervalInput>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `instanceType` field. */
  instanceType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `cost` to be created by this mutation. */
export type TaskActivityCostId49E66058FkCostIdCostCreateInput = {
  costType: Scalars["Int"]["input"];
  currency: Scalars["Int"]["input"];
  date?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInverseInput>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  platform?: InputMaybe<Scalars["Int"]["input"]>;
  rateCode?: InputMaybe<Scalars["String"]["input"]>;
  taskActivitiesUsingId?: InputMaybe<TaskActivityCostId49E66058FkCostIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInput>;
  value: Scalars["BigFloat"]["input"];
};

/** Input for the nested mutation of `cost` in the `TaskActivityInput` mutation. */
export type TaskActivityCostId49E66058FkCostIdInput = {
  /** The primary key(s) for `cost` for the far side of the relationship. */
  connectById?: InputMaybe<CostCostPkeyConnect>;
  /** A `CostInput` object that will be created and connected to this object. */
  create?: InputMaybe<TaskActivityCostId49E66058FkCostIdCostCreateInput>;
  /** The primary key(s) for `cost` for the far side of the relationship. */
  deleteById?: InputMaybe<CostCostPkeyDelete>;
  /** The primary key(s) and patch data for `cost` for the far side of the relationship. */
  updateById?: InputMaybe<CostOnTaskActivityForTaskActivityCostId49E66058FkCostIdUsingCostPkeyUpdate>;
};

/** Input for the nested mutation of `taskActivity` in the `CostInput` mutation. */
export type TaskActivityCostId49E66058FkCostIdInverseInput = {
  /** The primary key(s) for `taskActivity` for the far side of the relationship. */
  connectById?: InputMaybe<Array<TaskActivityTaskActivityPkeyConnect>>;
  /** The primary key(s) for `taskActivity` for the far side of the relationship. */
  connectByTaskId?: InputMaybe<Array<TaskActivityTaskActivityTaskIdKeyConnect>>;
  /** A `TaskActivityInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<TaskActivityCostId49E66058FkCostIdTaskActivityCreateInput>
  >;
  /** The primary key(s) for `taskActivity` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<TaskActivityTaskActivityPkeyDelete>>;
  /** The primary key(s) for `taskActivity` for the far side of the relationship. */
  deleteByTaskId?: InputMaybe<Array<TaskActivityTaskActivityTaskIdKeyDelete>>;
  /** Flag indicating whether all other `taskActivity` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `taskActivity` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdUsingTaskActivityPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `taskActivity` for the far side of the relationship. */
  updateByTaskId?: InputMaybe<
    Array<TaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdUsingTaskActivityTaskIdKeyUpdate>
  >;
};

/** The `taskActivity` to be created by this mutation. */
export type TaskActivityCostId49E66058FkCostIdTaskActivityCreateInput = {
  cloned: Scalars["Boolean"]["input"];
  costToCostId?: InputMaybe<TaskActivityCostId49E66058FkCostIdInput>;
  date?: InputMaybe<Scalars["Datetime"]["input"]>;
  duration: IntervalInput;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  instanceType?: InputMaybe<Scalars["String"]["input"]>;
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  taskToTaskId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInput>;
};

/** A filter to be used against `TaskActivity` object types. All fields are combined with a logical ‘and.’ */
export type TaskActivityFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TaskActivityFilter>>;
  /** Filter by the object’s `cloned` field. */
  cloned?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `costByCostId` relation. */
  costByCostId?: InputMaybe<CostFilter>;
  /** A related `costByCostId` exists. */
  costByCostIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `costId` field. */
  costId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `date` field. */
  date?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `duration` field. */
  duration?: InputMaybe<IntervalFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `instanceType` field. */
  instanceType?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TaskActivityFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TaskActivityFilter>>;
  /** Filter by the object’s `taskByTaskId` relation. */
  taskByTaskId?: InputMaybe<TaskFilter>;
  /** Filter by the object’s `taskId` field. */
  taskId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `TaskActivity` */
export type TaskActivityInput = {
  cloned: Scalars["Boolean"]["input"];
  costId?: InputMaybe<Scalars["BigInt"]["input"]>;
  costToCostId?: InputMaybe<TaskActivityCostId49E66058FkCostIdInput>;
  date?: InputMaybe<Scalars["Datetime"]["input"]>;
  duration: IntervalInput;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  instanceType?: InputMaybe<Scalars["String"]["input"]>;
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  taskToTaskId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInput>;
};

/** The fields on `taskActivity` to look up the row to update. */
export type TaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdUsingTaskActivityPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `taskActivity` being updated. */
    taskActivityPatch: UpdateTaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdPatch;
  };

/** The fields on `taskActivity` to look up the row to update. */
export type TaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdUsingTaskActivityTaskIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `taskActivity` being updated. */
    taskActivityPatch: UpdateTaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdPatch;
    taskId: Scalars["UUID"]["input"];
  };

/** The fields on `taskActivity` to look up the row to update. */
export type TaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskActivityPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `taskActivity` being updated. */
    taskActivityPatch: UpdateTaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdPatch;
  };

/** The fields on `taskActivity` to look up the row to update. */
export type TaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskActivityTaskIdKeyUpdate =
  {
    /** An object where the defined keys will be set on the `taskActivity` being updated. */
    taskActivityPatch: UpdateTaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdPatch;
    taskId: Scalars["UUID"]["input"];
  };

/** Represents an update to a `TaskActivity`. Fields that are set will be updated. */
export type TaskActivityPatch = {
  cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
  costId?: InputMaybe<Scalars["BigInt"]["input"]>;
  costToCostId?: InputMaybe<TaskActivityCostId49E66058FkCostIdInput>;
  date?: InputMaybe<Scalars["Datetime"]["input"]>;
  duration?: InputMaybe<IntervalInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  instanceType?: InputMaybe<Scalars["String"]["input"]>;
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  taskToTaskId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInput>;
};

/** The fields on `taskActivity` to look up the row to connect. */
export type TaskActivityTaskActivityPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `taskActivity` to look up the row to delete. */
export type TaskActivityTaskActivityPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** The fields on `taskActivity` to look up the row to connect. */
export type TaskActivityTaskActivityTaskIdKeyConnect = {
  taskId: Scalars["UUID"]["input"];
};

/** The fields on `taskActivity` to look up the row to delete. */
export type TaskActivityTaskActivityTaskIdKeyDelete = {
  taskId: Scalars["UUID"]["input"];
};

/** Input for the nested mutation of `task` in the `TaskActivityInput` mutation. */
export type TaskActivityTaskIdC451Ae92FkTaskIdInput = {
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectByExecutorId?: InputMaybe<TaskTaskExecutorIdKeyConnect>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectById?: InputMaybe<TaskTaskPkeyConnect>;
  /** A `TaskInput` object that will be created and connected to this object. */
  create?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdTaskCreateInput>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteByExecutorId?: InputMaybe<TaskTaskExecutorIdKeyDelete>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteById?: InputMaybe<TaskTaskPkeyDelete>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateByExecutorId?: InputMaybe<TaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskExecutorIdKeyUpdate>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateById?: InputMaybe<TaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskPkeyUpdate>;
};

/** Input for the nested mutation of `taskActivity` in the `TaskInput` mutation. */
export type TaskActivityTaskIdC451Ae92FkTaskIdInverseInput = {
  /** The primary key(s) for `taskActivity` for the far side of the relationship. */
  connectById?: InputMaybe<TaskActivityTaskActivityPkeyConnect>;
  /** The primary key(s) for `taskActivity` for the far side of the relationship. */
  connectByTaskId?: InputMaybe<TaskActivityTaskActivityTaskIdKeyConnect>;
  /** A `TaskActivityInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<TaskActivityTaskIdC451Ae92FkTaskIdTaskActivityCreateInput>
  >;
  /** The primary key(s) for `taskActivity` for the far side of the relationship. */
  deleteById?: InputMaybe<TaskActivityTaskActivityPkeyDelete>;
  /** The primary key(s) for `taskActivity` for the far side of the relationship. */
  deleteByTaskId?: InputMaybe<TaskActivityTaskActivityTaskIdKeyDelete>;
  /** Flag indicating whether all other `taskActivity` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `taskActivity` for the far side of the relationship. */
  updateById?: InputMaybe<TaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskActivityPkeyUpdate>;
  /** The primary key(s) and patch data for `taskActivity` for the far side of the relationship. */
  updateByTaskId?: InputMaybe<TaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskActivityTaskIdKeyUpdate>;
};

/** The `taskActivity` to be created by this mutation. */
export type TaskActivityTaskIdC451Ae92FkTaskIdTaskActivityCreateInput = {
  cloned: Scalars["Boolean"]["input"];
  costId?: InputMaybe<Scalars["BigInt"]["input"]>;
  costToCostId?: InputMaybe<TaskActivityCostId49E66058FkCostIdInput>;
  date?: InputMaybe<Scalars["Datetime"]["input"]>;
  duration: IntervalInput;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  instanceType?: InputMaybe<Scalars["String"]["input"]>;
  taskToTaskId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInput>;
};

/** The `task` to be created by this mutation. */
export type TaskActivityTaskIdC451Ae92FkTaskIdTaskCreateInput = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned: Scalars["Boolean"]["input"];
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id: Scalars["UUID"]["input"];
  inputsHash: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status: Scalars["Int"]["input"];
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A condition to be used against `Task` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TaskCondition = {
  /** Checks for equality with the object’s `cloned` field. */
  cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `containerImageId` field. */
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `created` field. */
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `credits` field. */
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `datasetId` field. */
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `errorDescription` field. */
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `executorId` field. */
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `inputsHash` field. */
  inputsHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `taskParameters` field. */
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `toolExitcode` field. */
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `toolExitcodeDescription` field. */
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `toolVersionId` field. */
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `containerImage` to be created by this mutation. */
export type TaskContainerImageId92Ed2DaeFkContainerImageIdContainerImageCreateInput =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name: Scalars["String"]["input"];
    repository: Scalars["String"]["input"];
    tag: Scalars["String"]["input"];
    tasksUsingId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInverseInput>;
    tesContainerimageexecutorToExecutorId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInput>;
    toolboxVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxVersionToToolboxVersionId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInput>;
    workflowsUsingId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInverseInput>;
  };

/** Input for the nested mutation of `containerImage` in the `TaskInput` mutation. */
export type TaskContainerImageId92Ed2DaeFkContainerImageIdInput = {
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectByExecutorId?: InputMaybe<ContainerImageContainerImageExecutorIdKeyConnect>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectById?: InputMaybe<ContainerImageContainerImagePkeyConnect>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectByNameAndTag?: InputMaybe<ContainerImageContainerImageNameTagUniqueConnect>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectByRepositoryAndTag?: InputMaybe<ContainerImageContainerImageRepositoryTagUniqueConnect>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectByToolboxVersionId?: InputMaybe<ContainerImageContainerImageToolboxVersionIdE9Dede5FUniqConnect>;
  /** A `ContainerImageInput` object that will be created and connected to this object. */
  create?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdContainerImageCreateInput>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteByExecutorId?: InputMaybe<ContainerImageContainerImageExecutorIdKeyDelete>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteById?: InputMaybe<ContainerImageContainerImagePkeyDelete>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteByNameAndTag?: InputMaybe<ContainerImageContainerImageNameTagUniqueDelete>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteByRepositoryAndTag?: InputMaybe<ContainerImageContainerImageRepositoryTagUniqueDelete>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteByToolboxVersionId?: InputMaybe<ContainerImageContainerImageToolboxVersionIdE9Dede5FUniqDelete>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateByExecutorId?: InputMaybe<ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageExecutorIdKeyUpdate>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateById?: InputMaybe<ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImagePkeyUpdate>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateByNameAndTag?: InputMaybe<ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageNameTagUniqueUpdate>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateByRepositoryAndTag?: InputMaybe<ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageRepositoryTagUniqueUpdate>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateByToolboxVersionId?: InputMaybe<ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate>;
};

/** Input for the nested mutation of `task` in the `ContainerImageInput` mutation. */
export type TaskContainerImageId92Ed2DaeFkContainerImageIdInverseInput = {
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectByExecutorId?: InputMaybe<Array<TaskTaskExecutorIdKeyConnect>>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectById?: InputMaybe<Array<TaskTaskPkeyConnect>>;
  /** A `TaskInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<TaskContainerImageId92Ed2DaeFkContainerImageIdTaskCreateInput>
  >;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteByExecutorId?: InputMaybe<Array<TaskTaskExecutorIdKeyDelete>>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<TaskTaskPkeyDelete>>;
  /** Flag indicating whether all other `task` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateByExecutorId?: InputMaybe<
    Array<TaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingTaskExecutorIdKeyUpdate>
  >;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingTaskPkeyUpdate>
  >;
};

/** The `task` to be created by this mutation. */
export type TaskContainerImageId92Ed2DaeFkContainerImageIdTaskCreateInput = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned: Scalars["Boolean"]["input"];
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id: Scalars["UUID"]["input"];
  inputsHash: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status: Scalars["Int"]["input"];
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `dataset` to be created by this mutation. */
export type TaskDatasetIdE15Afa5AFkDatasetIdDatasetCreateInput = {
  applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
  datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
  filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
  id: Scalars["UUID"]["input"];
  key: Scalars["String"]["input"];
  metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
  name: Scalars["String"]["input"];
  prefix: Scalars["String"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
  recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  status: Scalars["Int"]["input"];
  tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `dataset` in the `TaskInput` mutation. */
export type TaskDatasetIdE15Afa5AFkDatasetIdInput = {
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectById?: InputMaybe<DatasetDatasetV4PkeyConnect>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByProjectIdAndPrefix?: InputMaybe<DatasetDatasetPrefixProjectUniqueConnect>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByRecordingsTableId?: InputMaybe<DatasetDatasetV4RecordingsTableIdKeyConnect>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  connectByTenantIdAndKey?: InputMaybe<DatasetDatasetKeyTenantUniqueConnect>;
  /** A `DatasetInput` object that will be created and connected to this object. */
  create?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdDatasetCreateInput>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteById?: InputMaybe<DatasetDatasetV4PkeyDelete>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByProjectIdAndPrefix?: InputMaybe<DatasetDatasetPrefixProjectUniqueDelete>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByRecordingsTableId?: InputMaybe<DatasetDatasetV4RecordingsTableIdKeyDelete>;
  /** The primary key(s) for `dataset` for the far side of the relationship. */
  deleteByTenantIdAndKey?: InputMaybe<DatasetDatasetKeyTenantUniqueDelete>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateById?: InputMaybe<DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetV4PkeyUpdate>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByProjectIdAndPrefix?: InputMaybe<DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetPrefixProjectUniqueUpdate>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByRecordingsTableId?: InputMaybe<DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetV4RecordingsTableIdKeyUpdate>;
  /** The primary key(s) and patch data for `dataset` for the far side of the relationship. */
  updateByTenantIdAndKey?: InputMaybe<DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `task` in the `DatasetInput` mutation. */
export type TaskDatasetIdE15Afa5AFkDatasetIdInverseInput = {
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectByExecutorId?: InputMaybe<Array<TaskTaskExecutorIdKeyConnect>>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectById?: InputMaybe<Array<TaskTaskPkeyConnect>>;
  /** A `TaskInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<TaskDatasetIdE15Afa5AFkDatasetIdTaskCreateInput>>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteByExecutorId?: InputMaybe<Array<TaskTaskExecutorIdKeyDelete>>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<TaskTaskPkeyDelete>>;
  /** Flag indicating whether all other `task` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateByExecutorId?: InputMaybe<
    Array<TaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingTaskExecutorIdKeyUpdate>
  >;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingTaskPkeyUpdate>
  >;
};

/** The `task` to be created by this mutation. */
export type TaskDatasetIdE15Afa5AFkDatasetIdTaskCreateInput = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned: Scalars["Boolean"]["input"];
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id: Scalars["UUID"]["input"];
  inputsHash: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status: Scalars["Int"]["input"];
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `tesTaskexecutor` in the `TaskInput` mutation. */
export type TaskExecutorId24A67329FkTesTaskexecutorIdInput = {
  /** The primary key(s) for `tesTaskexecutor` for the far side of the relationship. */
  connectById?: InputMaybe<TesTaskexecutorTesTaskexecutorPkeyConnect>;
  /** A `TesTaskexecutorInput` object that will be created and connected to this object. */
  create?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdTesTaskexecutorCreateInput>;
  /** The primary key(s) for `tesTaskexecutor` for the far side of the relationship. */
  deleteById?: InputMaybe<TesTaskexecutorTesTaskexecutorPkeyDelete>;
  /** The primary key(s) and patch data for `tesTaskexecutor` for the far side of the relationship. */
  updateById?: InputMaybe<TesTaskexecutorOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdUsingTesTaskexecutorPkeyUpdate>;
};

/** Input for the nested mutation of `task` in the `TesTaskexecutorInput` mutation. */
export type TaskExecutorId24A67329FkTesTaskexecutorIdInverseInput = {
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectByExecutorId?: InputMaybe<TaskTaskExecutorIdKeyConnect>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectById?: InputMaybe<TaskTaskPkeyConnect>;
  /** A `TaskInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<TaskExecutorId24A67329FkTesTaskexecutorIdTaskCreateInput>
  >;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteByExecutorId?: InputMaybe<TaskTaskExecutorIdKeyDelete>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteById?: InputMaybe<TaskTaskPkeyDelete>;
  /** Flag indicating whether all other `task` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateByExecutorId?: InputMaybe<TaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdUsingTaskExecutorIdKeyUpdate>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateById?: InputMaybe<TaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdUsingTaskPkeyUpdate>;
};

/** The `task` to be created by this mutation. */
export type TaskExecutorId24A67329FkTesTaskexecutorIdTaskCreateInput = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned: Scalars["Boolean"]["input"];
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id: Scalars["UUID"]["input"];
  inputsHash: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status: Scalars["Int"]["input"];
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `tesTaskexecutor` to be created by this mutation. */
export type TaskExecutorId24A67329FkTesTaskexecutorIdTesTaskexecutorCreateInput =
  {
    awsBatchTaskUsingId?: InputMaybe<AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInput>;
    id: Scalars["UUID"]["input"];
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
    taskUsingId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInverseInput>;
    tesMocktaskexecutorUsingId?: InputMaybe<TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInverseInput>;
  };

/** A filter to be used against `Task` object types. All fields are combined with a logical ‘and.’ */
export type TaskFilter = {
  /** Filter by the object’s `analysisTableRowByTaskId` relation. */
  analysisTableRowByTaskId?: InputMaybe<AnalysisTableRowFilter>;
  /** A related `analysisTableRowByTaskId` exists. */
  analysisTableRowByTaskIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TaskFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `cloned` field. */
  cloned?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `containerImageByContainerImageId` relation. */
  containerImageByContainerImageId?: InputMaybe<ContainerImageFilter>;
  /** A related `containerImageByContainerImageId` exists. */
  containerImageByContainerImageIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `containerImageId` field. */
  containerImageId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `created` field. */
  created?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `credits` field. */
  credits?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `datasetByDatasetId` relation. */
  datasetByDatasetId?: InputMaybe<DatasetFilter>;
  /** A related `datasetByDatasetId` exists. */
  datasetByDatasetIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `datasetId` field. */
  datasetId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `errorDescription` field. */
  errorDescription?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `executorId` field. */
  executorId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `fileOutputGroupByTaskId` relation. */
  fileOutputGroupByTaskId?: InputMaybe<FileOutputGroupFilter>;
  /** A related `fileOutputGroupByTaskId` exists. */
  fileOutputGroupByTaskIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `inputsHash` field. */
  inputsHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metadataValuesByTaskId` relation. */
  metadataValuesByTaskId?: InputMaybe<TaskToManyMetadataValueFilter>;
  /** Some related `metadataValuesByTaskId` exist. */
  metadataValuesByTaskIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Negates the expression. */
  not?: InputMaybe<TaskFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TaskFilter>>;
  /** Filter by the object’s `projectByProjectId` relation. */
  projectByProjectId?: InputMaybe<ProjectFilter>;
  /** A related `projectByProjectId` exists. */
  projectByProjectIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<IntFilter>;
  /** Filter by the object’s `taskActivityByTaskId` relation. */
  taskActivityByTaskId?: InputMaybe<TaskActivityFilter>;
  /** A related `taskActivityByTaskId` exists. */
  taskActivityByTaskIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `taskParameters` field. */
  taskParameters?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `taskResourcesByTaskId` relation. */
  taskResourcesByTaskId?: InputMaybe<TaskToManyTaskResourceFilter>;
  /** Some related `taskResourcesByTaskId` exist. */
  taskResourcesByTaskIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `taskSourcesByTaskId` relation. */
  taskSourcesByTaskId?: InputMaybe<TaskToManyTaskSourceFilter>;
  /** Some related `taskSourcesByTaskId` exist. */
  taskSourcesByTaskIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `taskStatusLogsByTaskId` relation. */
  taskStatusLogsByTaskId?: InputMaybe<TaskToManyTaskStatusLogFilter>;
  /** Some related `taskStatusLogsByTaskId` exist. */
  taskStatusLogsByTaskIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tesTaskexecutorByExecutorId` relation. */
  tesTaskexecutorByExecutorId?: InputMaybe<TesTaskexecutorFilter>;
  /** A related `tesTaskexecutorByExecutorId` exists. */
  tesTaskexecutorByExecutorIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `toolExitcode` field. */
  toolExitcode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `toolExitcodeDescription` field. */
  toolExitcodeDescription?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `toolVersionByToolVersionId` relation. */
  toolVersionByToolVersionId?: InputMaybe<ToolVersionFilter>;
  /** Filter by the object’s `toolVersionId` field. */
  toolVersionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `Task` */
export type TaskInput = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned: Scalars["Boolean"]["input"];
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id: Scalars["UUID"]["input"];
  inputsHash: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status: Scalars["Int"]["input"];
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `task` to look up the row to update. */
export type TaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingTaskExecutorIdKeyUpdate =
  {
    executorId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingTaskPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingTaskExecutorIdKeyUpdate =
  {
    executorId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingTaskPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdUsingTaskExecutorIdKeyUpdate =
  {
    executorId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdUsingTaskPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskExecutorIdKeyUpdate =
  {
    executorId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingTaskExecutorIdKeyUpdate =
  {
    executorId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingTaskPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingTaskExecutorIdKeyUpdate =
  {
    executorId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingTaskPkeyUpdate = {
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `task` being updated. */
  taskPatch: UpdateTaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdPatch;
};

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdUsingTaskExecutorIdKeyUpdate =
  {
    executorId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdUsingTaskPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskForTaskProjectId963D6354FkProjectIdUsingTaskExecutorIdKeyUpdate =
  {
    executorId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskForTaskProjectId963D6354FkProjectIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskForTaskProjectId963D6354FkProjectIdUsingTaskPkeyUpdate = {
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `task` being updated. */
  taskPatch: UpdateTaskOnTaskForTaskProjectId963D6354FkProjectIdPatch;
};

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskForTaskTenantId148E90DaFkTenantIdUsingTaskExecutorIdKeyUpdate =
  {
    executorId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskForTaskTenantId148E90DaFkTenantIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskForTaskTenantId148E90DaFkTenantIdUsingTaskPkeyUpdate = {
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `task` being updated. */
  taskPatch: UpdateTaskOnTaskForTaskTenantId148E90DaFkTenantIdPatch;
};

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingTaskExecutorIdKeyUpdate =
  {
    executorId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskForTaskToolVersionId14506837FkToolVersionIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingTaskPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskForTaskToolVersionId14506837FkToolVersionIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingTaskExecutorIdKeyUpdate =
  {
    executorId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingTaskPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdUsingTaskExecutorIdKeyUpdate =
  {
    executorId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdUsingTaskPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdUsingTaskExecutorIdKeyUpdate =
  {
    executorId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdUsingTaskPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskExecutorIdKeyUpdate =
  {
    executorId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdPatch;
  };

/** The fields on `task` to look up the row to update. */
export type TaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `task` being updated. */
    taskPatch: UpdateTaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdPatch;
  };

/** Represents an update to a `Task`. Fields that are set will be updated. */
export type TaskPatch = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  inputsHash?: InputMaybe<Scalars["String"]["input"]>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `project` in the `TaskInput` mutation. */
export type TaskProjectId963D6354FkProjectIdInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByKeyAndTenantId?: InputMaybe<ProjectProjectKeyTenantUniqueConnect>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnTaskForTaskProjectId963D6354FkProjectIdUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByKeyAndTenantId?: InputMaybe<ProjectOnTaskForTaskProjectId963D6354FkProjectIdUsingProjectKeyTenantUniqueUpdate>;
};

/** Input for the nested mutation of `task` in the `ProjectInput` mutation. */
export type TaskProjectId963D6354FkProjectIdInverseInput = {
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectByExecutorId?: InputMaybe<Array<TaskTaskExecutorIdKeyConnect>>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectById?: InputMaybe<Array<TaskTaskPkeyConnect>>;
  /** A `TaskInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<TaskProjectId963D6354FkProjectIdTaskCreateInput>>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteByExecutorId?: InputMaybe<Array<TaskTaskExecutorIdKeyDelete>>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<TaskTaskPkeyDelete>>;
  /** Flag indicating whether all other `task` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateByExecutorId?: InputMaybe<
    Array<TaskOnTaskForTaskProjectId963D6354FkProjectIdUsingTaskExecutorIdKeyUpdate>
  >;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TaskOnTaskForTaskProjectId963D6354FkProjectIdUsingTaskPkeyUpdate>
  >;
};

/** The `task` to be created by this mutation. */
export type TaskProjectId963D6354FkProjectIdTaskCreateInput = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned: Scalars["Boolean"]["input"];
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id: Scalars["UUID"]["input"];
  inputsHash: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status: Scalars["Int"]["input"];
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type TaskResource = {
  __typename: "TaskResource";
  id: Scalars["Int"]["output"];
  /** Reads a single `Task` that is related to this `TaskResource`. */
  taskByTaskId: Maybe<Task>;
  taskId: Scalars["UUID"]["output"];
  type: Scalars["Int"]["output"];
  value: Scalars["Int"]["output"];
};

/**
 * A condition to be used against `TaskResource` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TaskResourceCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `TaskResource` object types. All fields are combined with a logical ‘and.’ */
export type TaskResourceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TaskResourceFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TaskResourceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TaskResourceFilter>>;
  /** Filter by the object’s `taskByTaskId` relation. */
  taskByTaskId?: InputMaybe<TaskFilter>;
  /** Filter by the object’s `taskId` field. */
  taskId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<IntFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `TaskResource` */
export type TaskResourceInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  taskToTaskId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInput>;
  type: Scalars["Int"]["input"];
  value: Scalars["Int"]["input"];
};

/** The fields on `taskResource` to look up the row to update. */
export type TaskResourceOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdUsingTaskResourcePkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `taskResource` being updated. */
    taskResourcePatch: UpdateTaskResourceOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdPatch;
  };

/** Represents an update to a `TaskResource`. Fields that are set will be updated. */
export type TaskResourcePatch = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  taskToTaskId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInput>;
  type?: InputMaybe<Scalars["Int"]["input"]>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Input for the nested mutation of `task` in the `TaskResourceInput` mutation. */
export type TaskResourceTaskId73Fcabc0FkTaskIdInput = {
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectByExecutorId?: InputMaybe<TaskTaskExecutorIdKeyConnect>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectById?: InputMaybe<TaskTaskPkeyConnect>;
  /** A `TaskInput` object that will be created and connected to this object. */
  create?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdTaskCreateInput>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteByExecutorId?: InputMaybe<TaskTaskExecutorIdKeyDelete>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteById?: InputMaybe<TaskTaskPkeyDelete>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateByExecutorId?: InputMaybe<TaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdUsingTaskExecutorIdKeyUpdate>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateById?: InputMaybe<TaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdUsingTaskPkeyUpdate>;
};

/** Input for the nested mutation of `taskResource` in the `TaskInput` mutation. */
export type TaskResourceTaskId73Fcabc0FkTaskIdInverseInput = {
  /** The primary key(s) for `taskResource` for the far side of the relationship. */
  connectById?: InputMaybe<Array<TaskResourceTaskResourcePkeyConnect>>;
  /** A `TaskResourceInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<TaskResourceTaskId73Fcabc0FkTaskIdTaskResourceCreateInput>
  >;
  /** The primary key(s) for `taskResource` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<TaskResourceTaskResourcePkeyDelete>>;
  /** Flag indicating whether all other `taskResource` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `taskResource` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TaskResourceOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdUsingTaskResourcePkeyUpdate>
  >;
};

/** The `task` to be created by this mutation. */
export type TaskResourceTaskId73Fcabc0FkTaskIdTaskCreateInput = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned: Scalars["Boolean"]["input"];
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id: Scalars["UUID"]["input"];
  inputsHash: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status: Scalars["Int"]["input"];
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `taskResource` to be created by this mutation. */
export type TaskResourceTaskId73Fcabc0FkTaskIdTaskResourceCreateInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  taskToTaskId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInput>;
  type: Scalars["Int"]["input"];
  value: Scalars["Int"]["input"];
};

/** The fields on `taskResource` to look up the row to connect. */
export type TaskResourceTaskResourcePkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `taskResource` to look up the row to delete. */
export type TaskResourceTaskResourcePkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** A connection to a list of `TaskResource` values. */
export type TaskResourcesConnection = {
  __typename: "TaskResourcesConnection";
  /** A list of edges which contains the `TaskResource` and cursor to aid in pagination. */
  edges: Array<TaskResourcesEdge>;
  /** A list of `TaskResource` objects. */
  nodes: Array<TaskResource>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaskResource` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `TaskResource` edge in the connection. */
export type TaskResourcesEdge = {
  __typename: "TaskResourcesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TaskResource` at the end of the edge. */
  node: TaskResource;
};

/** Methods to use when ordering `TaskResource`. */
export enum TaskResourcesOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TaskIdAsc = "TASK_ID_ASC",
  TaskIdDesc = "TASK_ID_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
  ValueAsc = "VALUE_ASC",
  ValueDesc = "VALUE_DESC",
}

export type TaskSource = {
  __typename: "TaskSource";
  /** Reads a single `File` that is related to this `TaskSource`. */
  fileByFileId: Maybe<File>;
  fileId: Maybe<Scalars["UUID"]["output"]>;
  id: Scalars["Int"]["output"];
  /** Reads a single `Task` that is related to this `TaskSource`. */
  taskByTaskId: Maybe<Task>;
  taskId: Scalars["UUID"]["output"];
  /** Reads a single `ToolSource` that is related to this `TaskSource`. */
  toolSourceByToolSourceId: Maybe<ToolSource>;
  toolSourceId: Maybe<Scalars["Int"]["output"]>;
};

/**
 * A condition to be used against `TaskSource` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TaskSourceCondition = {
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `toolSourceId` field. */
  toolSourceId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `TaskSource` object types. All fields are combined with a logical ‘and.’ */
export type TaskSourceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TaskSourceFilter>>;
  /** Filter by the object’s `fileByFileId` relation. */
  fileByFileId?: InputMaybe<FileFilter>;
  /** A related `fileByFileId` exists. */
  fileByFileIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fileId` field. */
  fileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TaskSourceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TaskSourceFilter>>;
  /** Filter by the object’s `taskByTaskId` relation. */
  taskByTaskId?: InputMaybe<TaskFilter>;
  /** Filter by the object’s `taskId` field. */
  taskId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `toolSourceByToolSourceId` relation. */
  toolSourceByToolSourceId?: InputMaybe<ToolSourceFilter>;
  /** A related `toolSourceByToolSourceId` exists. */
  toolSourceByToolSourceIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `toolSourceId` field. */
  toolSourceId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `TaskSource` */
export type TaskSourceInput = {
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileToFileId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  taskToTaskId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInput>;
  toolSourceId?: InputMaybe<Scalars["Int"]["input"]>;
  toolSourceToToolSourceId?: InputMaybe<TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInput>;
};

/** The fields on `taskSource` to look up the row to update. */
export type TaskSourceOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdUsingTaskSourceV4PkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `taskSource` being updated. */
    taskSourcePatch: UpdateTaskSourceOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdPatch;
  };

/** The fields on `taskSource` to look up the row to update. */
export type TaskSourceOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdUsingTaskSourceV4PkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `taskSource` being updated. */
    taskSourcePatch: UpdateTaskSourceOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdPatch;
  };

/** The fields on `taskSource` to look up the row to update. */
export type TaskSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdUsingTaskSourceV4PkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `taskSource` being updated. */
    taskSourcePatch: UpdateTaskSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdPatch;
  };

/** Represents an update to a `TaskSource`. Fields that are set will be updated. */
export type TaskSourcePatch = {
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileToFileId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  taskToTaskId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInput>;
  toolSourceId?: InputMaybe<Scalars["Int"]["input"]>;
  toolSourceToToolSourceId?: InputMaybe<TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInput>;
};

/** The fields on `taskSource` to look up the row to connect. */
export type TaskSourceTaskSourceV4PkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `taskSource` to look up the row to delete. */
export type TaskSourceTaskSourceV4PkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** The `file` to be created by this mutation. */
export type TaskSourceV4FileId3A4723EfFkFileIdFileCreateInput = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["UUID"]["input"];
  isSeries: Scalars["Boolean"]["input"];
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  originalName: Scalars["String"]["input"];
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus: Scalars["Int"]["input"];
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status: Scalars["Int"]["input"];
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `file` in the `TaskSourceInput` mutation. */
export type TaskSourceV4FileId3A4723EfFkFileIdInput = {
  /** The primary key(s) for `file` for the far side of the relationship. */
  connectById?: InputMaybe<FileFilePkeyConnect>;
  /** A `FileInput` object that will be created and connected to this object. */
  create?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdFileCreateInput>;
  /** The primary key(s) for `file` for the far side of the relationship. */
  deleteById?: InputMaybe<FileFilePkeyDelete>;
  /** The primary key(s) and patch data for `file` for the far side of the relationship. */
  updateById?: InputMaybe<FileOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdUsingFilePkeyUpdate>;
};

/** Input for the nested mutation of `taskSource` in the `FileInput` mutation. */
export type TaskSourceV4FileId3A4723EfFkFileIdInverseInput = {
  /** The primary key(s) for `taskSource` for the far side of the relationship. */
  connectById?: InputMaybe<Array<TaskSourceTaskSourceV4PkeyConnect>>;
  /** A `TaskSourceInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<TaskSourceV4FileId3A4723EfFkFileIdTaskSourceCreateInput>
  >;
  /** The primary key(s) for `taskSource` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<TaskSourceTaskSourceV4PkeyDelete>>;
  /** Flag indicating whether all other `taskSource` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `taskSource` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TaskSourceOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdUsingTaskSourceV4PkeyUpdate>
  >;
};

/** The `taskSource` to be created by this mutation. */
export type TaskSourceV4FileId3A4723EfFkFileIdTaskSourceCreateInput = {
  fileToFileId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  taskToTaskId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInput>;
  toolSourceId?: InputMaybe<Scalars["Int"]["input"]>;
  toolSourceToToolSourceId?: InputMaybe<TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInput>;
};

/** Input for the nested mutation of `task` in the `TaskSourceInput` mutation. */
export type TaskSourceV4TaskId059F1B6BFkTaskIdInput = {
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectByExecutorId?: InputMaybe<TaskTaskExecutorIdKeyConnect>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectById?: InputMaybe<TaskTaskPkeyConnect>;
  /** A `TaskInput` object that will be created and connected to this object. */
  create?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdTaskCreateInput>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteByExecutorId?: InputMaybe<TaskTaskExecutorIdKeyDelete>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteById?: InputMaybe<TaskTaskPkeyDelete>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateByExecutorId?: InputMaybe<TaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdUsingTaskExecutorIdKeyUpdate>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateById?: InputMaybe<TaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdUsingTaskPkeyUpdate>;
};

/** Input for the nested mutation of `taskSource` in the `TaskInput` mutation. */
export type TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput = {
  /** The primary key(s) for `taskSource` for the far side of the relationship. */
  connectById?: InputMaybe<Array<TaskSourceTaskSourceV4PkeyConnect>>;
  /** A `TaskSourceInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<TaskSourceV4TaskId059F1B6BFkTaskIdTaskSourceCreateInput>
  >;
  /** The primary key(s) for `taskSource` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<TaskSourceTaskSourceV4PkeyDelete>>;
  /** Flag indicating whether all other `taskSource` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `taskSource` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TaskSourceOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdUsingTaskSourceV4PkeyUpdate>
  >;
};

/** The `task` to be created by this mutation. */
export type TaskSourceV4TaskId059F1B6BFkTaskIdTaskCreateInput = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned: Scalars["Boolean"]["input"];
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id: Scalars["UUID"]["input"];
  inputsHash: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status: Scalars["Int"]["input"];
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `taskSource` to be created by this mutation. */
export type TaskSourceV4TaskId059F1B6BFkTaskIdTaskSourceCreateInput = {
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileToFileId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  taskToTaskId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInput>;
  toolSourceId?: InputMaybe<Scalars["Int"]["input"]>;
  toolSourceToToolSourceId?: InputMaybe<TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInput>;
};

/** Input for the nested mutation of `toolSource` in the `TaskSourceInput` mutation. */
export type TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInput = {
  /** The primary key(s) for `toolSource` for the far side of the relationship. */
  connectById?: InputMaybe<ToolSourceToolSourcePkeyConnect>;
  /** A `ToolSourceInput` object that will be created and connected to this object. */
  create?: InputMaybe<TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdToolSourceCreateInput>;
  /** The primary key(s) for `toolSource` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolSourceToolSourcePkeyDelete>;
  /** The primary key(s) and patch data for `toolSource` for the far side of the relationship. */
  updateById?: InputMaybe<ToolSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdUsingToolSourcePkeyUpdate>;
};

/** Input for the nested mutation of `taskSource` in the `ToolSourceInput` mutation. */
export type TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInverseInput = {
  /** The primary key(s) for `taskSource` for the far side of the relationship. */
  connectById?: InputMaybe<Array<TaskSourceTaskSourceV4PkeyConnect>>;
  /** A `TaskSourceInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdTaskSourceCreateInput>
  >;
  /** The primary key(s) for `taskSource` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<TaskSourceTaskSourceV4PkeyDelete>>;
  /** Flag indicating whether all other `taskSource` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `taskSource` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TaskSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdUsingTaskSourceV4PkeyUpdate>
  >;
};

/** The `taskSource` to be created by this mutation. */
export type TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdTaskSourceCreateInput =
  {
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileToFileId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInput>;
    toolSourceToToolSourceId?: InputMaybe<TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInput>;
  };

/** The `toolSource` to be created by this mutation. */
export type TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdToolSourceCreateInput =
  {
    fileFormats?: InputMaybe<Scalars["JSON"]["input"]>;
    helpText: Scalars["String"]["input"];
    id?: InputMaybe<Scalars["Int"]["input"]>;
    key: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    required: Scalars["Boolean"]["input"];
    taskSourcesUsingId?: InputMaybe<TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInverseInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInput>;
  };

/** A connection to a list of `TaskSource` values. */
export type TaskSourcesConnection = {
  __typename: "TaskSourcesConnection";
  /** A list of edges which contains the `TaskSource` and cursor to aid in pagination. */
  edges: Array<TaskSourcesEdge>;
  /** A list of `TaskSource` objects. */
  nodes: Array<TaskSource>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaskSource` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `TaskSource` edge in the connection. */
export type TaskSourcesEdge = {
  __typename: "TaskSourcesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TaskSource` at the end of the edge. */
  node: TaskSource;
};

/** Methods to use when ordering `TaskSource`. */
export enum TaskSourcesOrderBy {
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TaskIdAsc = "TASK_ID_ASC",
  TaskIdDesc = "TASK_ID_DESC",
  ToolSourceIdAsc = "TOOL_SOURCE_ID_ASC",
  ToolSourceIdDesc = "TOOL_SOURCE_ID_DESC",
}

export type TaskStatusLog = {
  __typename: "TaskStatusLog";
  date: Scalars["Datetime"]["output"];
  id: Scalars["Int"]["output"];
  status: Scalars["Int"]["output"];
  /** Reads a single `Task` that is related to this `TaskStatusLog`. */
  taskByTaskId: Maybe<Task>;
  taskId: Scalars["UUID"]["output"];
};

/**
 * A condition to be used against `TaskStatusLog` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TaskStatusLogCondition = {
  /** Checks for equality with the object’s `date` field. */
  date?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `TaskStatusLog` object types. All fields are combined with a logical ‘and.’ */
export type TaskStatusLogFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TaskStatusLogFilter>>;
  /** Filter by the object’s `date` field. */
  date?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TaskStatusLogFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TaskStatusLogFilter>>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<IntFilter>;
  /** Filter by the object’s `taskByTaskId` relation. */
  taskByTaskId?: InputMaybe<TaskFilter>;
  /** Filter by the object’s `taskId` field. */
  taskId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `TaskStatusLog` */
export type TaskStatusLogInput = {
  date?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  status: Scalars["Int"]["input"];
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  taskToTaskId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInput>;
};

/** The fields on `taskStatusLog` to look up the row to update. */
export type TaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskStatusLogPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `taskStatusLog` being updated. */
    taskStatusLogPatch: UpdateTaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdPatch;
  };

/** The fields on `taskStatusLog` to look up the row to update. */
export type TaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskStatusLogUniqueStatusPerTaskUpdate =
  {
    status: Scalars["Int"]["input"];
    taskId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `taskStatusLog` being updated. */
    taskStatusLogPatch: UpdateTaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdPatch;
  };

/** Represents an update to a `TaskStatusLog`. Fields that are set will be updated. */
export type TaskStatusLogPatch = {
  date?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  taskId?: InputMaybe<Scalars["UUID"]["input"]>;
  taskToTaskId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInput>;
};

/** Input for the nested mutation of `task` in the `TaskStatusLogInput` mutation. */
export type TaskStatusLogTaskIdFe1B9D0BFkTaskIdInput = {
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectByExecutorId?: InputMaybe<TaskTaskExecutorIdKeyConnect>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectById?: InputMaybe<TaskTaskPkeyConnect>;
  /** A `TaskInput` object that will be created and connected to this object. */
  create?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdTaskCreateInput>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteByExecutorId?: InputMaybe<TaskTaskExecutorIdKeyDelete>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteById?: InputMaybe<TaskTaskPkeyDelete>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateByExecutorId?: InputMaybe<TaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskExecutorIdKeyUpdate>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateById?: InputMaybe<TaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskPkeyUpdate>;
};

/** Input for the nested mutation of `taskStatusLog` in the `TaskInput` mutation. */
export type TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput = {
  /** The primary key(s) for `taskStatusLog` for the far side of the relationship. */
  connectById?: InputMaybe<Array<TaskStatusLogTaskStatusLogPkeyConnect>>;
  /** The primary key(s) for `taskStatusLog` for the far side of the relationship. */
  connectByTaskIdAndStatus?: InputMaybe<
    Array<TaskStatusLogTaskStatusLogUniqueStatusPerTaskConnect>
  >;
  /** A `TaskStatusLogInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<TaskStatusLogTaskIdFe1B9D0BFkTaskIdTaskStatusLogCreateInput>
  >;
  /** The primary key(s) for `taskStatusLog` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<TaskStatusLogTaskStatusLogPkeyDelete>>;
  /** The primary key(s) for `taskStatusLog` for the far side of the relationship. */
  deleteByTaskIdAndStatus?: InputMaybe<
    Array<TaskStatusLogTaskStatusLogUniqueStatusPerTaskDelete>
  >;
  /** Flag indicating whether all other `taskStatusLog` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `taskStatusLog` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskStatusLogPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `taskStatusLog` for the far side of the relationship. */
  updateByTaskIdAndStatus?: InputMaybe<
    Array<TaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskStatusLogUniqueStatusPerTaskUpdate>
  >;
};

/** The `task` to be created by this mutation. */
export type TaskStatusLogTaskIdFe1B9D0BFkTaskIdTaskCreateInput = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned: Scalars["Boolean"]["input"];
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id: Scalars["UUID"]["input"];
  inputsHash: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status: Scalars["Int"]["input"];
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `taskStatusLog` to be created by this mutation. */
export type TaskStatusLogTaskIdFe1B9D0BFkTaskIdTaskStatusLogCreateInput = {
  date?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  status: Scalars["Int"]["input"];
  taskToTaskId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInput>;
};

/** The fields on `taskStatusLog` to look up the row to connect. */
export type TaskStatusLogTaskStatusLogPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `taskStatusLog` to look up the row to delete. */
export type TaskStatusLogTaskStatusLogPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** The fields on `taskStatusLog` to look up the row to connect. */
export type TaskStatusLogTaskStatusLogUniqueStatusPerTaskConnect = {
  status: Scalars["Int"]["input"];
  taskId: Scalars["UUID"]["input"];
};

/** The fields on `taskStatusLog` to look up the row to delete. */
export type TaskStatusLogTaskStatusLogUniqueStatusPerTaskDelete = {
  status: Scalars["Int"]["input"];
  taskId: Scalars["UUID"]["input"];
};

/** A connection to a list of `TaskStatusLog` values. */
export type TaskStatusLogsConnection = {
  __typename: "TaskStatusLogsConnection";
  /** A list of edges which contains the `TaskStatusLog` and cursor to aid in pagination. */
  edges: Array<TaskStatusLogsEdge>;
  /** A list of `TaskStatusLog` objects. */
  nodes: Array<TaskStatusLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaskStatusLog` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `TaskStatusLog` edge in the connection. */
export type TaskStatusLogsEdge = {
  __typename: "TaskStatusLogsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TaskStatusLog` at the end of the edge. */
  node: TaskStatusLog;
};

/** Methods to use when ordering `TaskStatusLog`. */
export enum TaskStatusLogsOrderBy {
  DateAsc = "DATE_ASC",
  DateDesc = "DATE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TaskIdAsc = "TASK_ID_ASC",
  TaskIdDesc = "TASK_ID_DESC",
}

/** The fields on `task` to look up the row to connect. */
export type TaskTaskExecutorIdKeyConnect = {
  executorId: Scalars["UUID"]["input"];
};

/** The fields on `task` to look up the row to delete. */
export type TaskTaskExecutorIdKeyDelete = {
  executorId: Scalars["UUID"]["input"];
};

/** The fields on `task` to look up the row to connect. */
export type TaskTaskPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `task` to look up the row to delete. */
export type TaskTaskPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** Input for the nested mutation of `tenant` in the `TaskInput` mutation. */
export type TaskTenantId148E90DaFkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<TaskTenantId148E90DaFkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnTaskForTaskTenantId148E90DaFkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnTaskForTaskTenantId148E90DaFkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnTaskForTaskTenantId148E90DaFkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `task` in the `TenantInput` mutation. */
export type TaskTenantId148E90DaFkTenantIdInverseInput = {
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectByExecutorId?: InputMaybe<Array<TaskTaskExecutorIdKeyConnect>>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectById?: InputMaybe<Array<TaskTaskPkeyConnect>>;
  /** A `TaskInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<TaskTenantId148E90DaFkTenantIdTaskCreateInput>>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteByExecutorId?: InputMaybe<Array<TaskTaskExecutorIdKeyDelete>>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<TaskTaskPkeyDelete>>;
  /** Flag indicating whether all other `task` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateByExecutorId?: InputMaybe<
    Array<TaskOnTaskForTaskTenantId148E90DaFkTenantIdUsingTaskExecutorIdKeyUpdate>
  >;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TaskOnTaskForTaskTenantId148E90DaFkTenantIdUsingTaskPkeyUpdate>
  >;
};

/** The `task` to be created by this mutation. */
export type TaskTenantId148E90DaFkTenantIdTaskCreateInput = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned: Scalars["Boolean"]["input"];
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id: Scalars["UUID"]["input"];
  inputsHash: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status: Scalars["Int"]["input"];
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `tenant` to be created by this mutation. */
export type TaskTenantId148E90DaFkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** A filter to be used against many `MetadataValue` object types. All fields are combined with a logical ‘and.’ */
export type TaskToManyMetadataValueFilter = {
  /** Every related `MetadataValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MetadataValueFilter>;
  /** No related `MetadataValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MetadataValueFilter>;
  /** Some related `MetadataValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MetadataValueFilter>;
};

/** A filter to be used against many `TaskResource` object types. All fields are combined with a logical ‘and.’ */
export type TaskToManyTaskResourceFilter = {
  /** Every related `TaskResource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TaskResourceFilter>;
  /** No related `TaskResource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TaskResourceFilter>;
  /** Some related `TaskResource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TaskResourceFilter>;
};

/** A filter to be used against many `TaskSource` object types. All fields are combined with a logical ‘and.’ */
export type TaskToManyTaskSourceFilter = {
  /** Every related `TaskSource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TaskSourceFilter>;
  /** No related `TaskSource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TaskSourceFilter>;
  /** Some related `TaskSource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TaskSourceFilter>;
};

/** A filter to be used against many `TaskStatusLog` object types. All fields are combined with a logical ‘and.’ */
export type TaskToManyTaskStatusLogFilter = {
  /** Every related `TaskStatusLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TaskStatusLogFilter>;
  /** No related `TaskStatusLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TaskStatusLogFilter>;
  /** Some related `TaskStatusLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TaskStatusLogFilter>;
};

/** Input for the nested mutation of `toolVersion` in the `TaskInput` mutation. */
export type TaskToolVersionId14506837FkToolVersionIdInput = {
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectById?: InputMaybe<ToolVersionToolVersionPkeyConnect>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueConnect>;
  /** A `ToolVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdToolVersionCreateInput>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolVersionToolVersionPkeyDelete>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueDelete>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateById?: InputMaybe<ToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingToolVersionPkeyUpdate>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateByToolIdAndVersion?: InputMaybe<ToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingToolversionToolVersionUniqueUpdate>;
};

/** Input for the nested mutation of `task` in the `ToolVersionInput` mutation. */
export type TaskToolVersionId14506837FkToolVersionIdInverseInput = {
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectByExecutorId?: InputMaybe<Array<TaskTaskExecutorIdKeyConnect>>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectById?: InputMaybe<Array<TaskTaskPkeyConnect>>;
  /** A `TaskInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<TaskToolVersionId14506837FkToolVersionIdTaskCreateInput>
  >;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteByExecutorId?: InputMaybe<Array<TaskTaskExecutorIdKeyDelete>>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<TaskTaskPkeyDelete>>;
  /** Flag indicating whether all other `task` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateByExecutorId?: InputMaybe<
    Array<TaskOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingTaskExecutorIdKeyUpdate>
  >;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TaskOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingTaskPkeyUpdate>
  >;
};

/** The `task` to be created by this mutation. */
export type TaskToolVersionId14506837FkToolVersionIdTaskCreateInput = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned: Scalars["Boolean"]["input"];
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id: Scalars["UUID"]["input"];
  inputsHash: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status: Scalars["Int"]["input"];
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `toolVersion` to be created by this mutation. */
export type TaskToolVersionId14506837FkToolVersionIdToolVersionCreateInput = {
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
  command: Scalars["String"]["input"];
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  isToolSpecForwardsCompatible: Scalars["Boolean"]["input"];
  isUpgradableToNextVersion: Scalars["Boolean"]["input"];
  maturity: Scalars["Int"]["input"];
  tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
  toolId?: InputMaybe<Scalars["Int"]["input"]>;
  toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
  toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
  toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
  toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
  toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
  toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
  toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
  toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
  version: Scalars["String"]["input"];
  visibility: Scalars["Int"]["input"];
  workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
};

/** The `applicationUser` to be created by this mutation. */
export type TaskUserId270D0Bb2FkApplicationUserIdApplicationUserCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
  applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
  applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
  applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
  avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
  cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
  djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
  email: Scalars["String"]["input"];
  eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
  executorId?: InputMaybe<Scalars["Int"]["input"]>;
  explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
  explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
  explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
  filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
  firstName: Scalars["String"]["input"];
  globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  homeRegion?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  internal: Scalars["Boolean"]["input"];
  isActive: Scalars["Boolean"]["input"];
  isStaff: Scalars["Boolean"]["input"];
  isSuperuser: Scalars["Boolean"]["input"];
  lastName: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
  notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
  projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
  projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
  projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
  projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
  tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
  tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
  tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
  tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
  username: Scalars["String"]["input"];
  workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
};

/** Input for the nested mutation of `applicationUser` in the `TaskInput` mutation. */
export type TaskUserId270D0Bb2FkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `task` in the `ApplicationUserInput` mutation. */
export type TaskUserId270D0Bb2FkApplicationUserIdInverseInput = {
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectByExecutorId?: InputMaybe<Array<TaskTaskExecutorIdKeyConnect>>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  connectById?: InputMaybe<Array<TaskTaskPkeyConnect>>;
  /** A `TaskInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<TaskUserId270D0Bb2FkApplicationUserIdTaskCreateInput>
  >;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteByExecutorId?: InputMaybe<Array<TaskTaskExecutorIdKeyDelete>>;
  /** The primary key(s) for `task` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<TaskTaskPkeyDelete>>;
  /** Flag indicating whether all other `task` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateByExecutorId?: InputMaybe<
    Array<TaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingTaskExecutorIdKeyUpdate>
  >;
  /** The primary key(s) and patch data for `task` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingTaskPkeyUpdate>
  >;
};

/** The `task` to be created by this mutation. */
export type TaskUserId270D0Bb2FkApplicationUserIdTaskCreateInput = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned: Scalars["Boolean"]["input"];
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id: Scalars["UUID"]["input"];
  inputsHash: Scalars["String"]["input"];
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status: Scalars["Int"]["input"];
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
};

/** A connection to a list of `Task` values. */
export type TasksConnection = {
  __typename: "TasksConnection";
  /** A list of edges which contains the `Task` and cursor to aid in pagination. */
  edges: Array<TasksEdge>;
  /** A list of `Task` objects. */
  nodes: Array<Task>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Task` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Task` edge in the connection. */
export type TasksEdge = {
  __typename: "TasksEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Task` at the end of the edge. */
  node: Task;
};

/** Methods to use when ordering `Task`. */
export enum TasksOrderBy {
  ClonedAsc = "CLONED_ASC",
  ClonedDesc = "CLONED_DESC",
  ContainerImageIdAsc = "CONTAINER_IMAGE_ID_ASC",
  ContainerImageIdDesc = "CONTAINER_IMAGE_ID_DESC",
  CreatedAsc = "CREATED_ASC",
  CreatedDesc = "CREATED_DESC",
  CreditsAsc = "CREDITS_ASC",
  CreditsDesc = "CREDITS_DESC",
  DatasetIdAsc = "DATASET_ID_ASC",
  DatasetIdDesc = "DATASET_ID_DESC",
  ErrorDescriptionAsc = "ERROR_DESCRIPTION_ASC",
  ErrorDescriptionDesc = "ERROR_DESCRIPTION_DESC",
  ExecutorIdAsc = "EXECUTOR_ID_ASC",
  ExecutorIdDesc = "EXECUTOR_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InputsHashAsc = "INPUTS_HASH_ASC",
  InputsHashDesc = "INPUTS_HASH_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TaskParametersAsc = "TASK_PARAMETERS_ASC",
  TaskParametersDesc = "TASK_PARAMETERS_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  ToolExitcodeAsc = "TOOL_EXITCODE_ASC",
  ToolExitcodeDesc = "TOOL_EXITCODE_DESC",
  ToolExitcodeDescriptionAsc = "TOOL_EXITCODE_DESCRIPTION_ASC",
  ToolExitcodeDescriptionDesc = "TOOL_EXITCODE_DESCRIPTION_DESC",
  ToolVersionIdAsc = "TOOL_VERSION_ID_ASC",
  ToolVersionIdDesc = "TOOL_VERSION_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type Tenant = {
  __typename: "Tenant";
  activeStorageSize: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads and enables pagination through a set of `AnalysisTableGroup`. */
  analysisTableGroupsByTenantId: AnalysisTableGroupsConnection;
  /** Reads and enables pagination through a set of `AnalysisTableRow`. */
  analysisTableRowsByTenantId: AnalysisTableRowsConnection;
  /** Reads and enables pagination through a set of `AnalysisTable`. */
  analysisTablesByTenantId: AnalysisTablesConnection;
  /** Reads and enables pagination through a set of `ApplicationUserMembership`. */
  applicationUserMembershipsByTenantId: ApplicationUserMembershipsConnection;
  /** Reads and enables pagination through a set of `ApplicationUserSession`. */
  applicationUserSessionsByTenantId: ApplicationUserSessionsConnection;
  archivedStorageSize: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads and enables pagination through a set of `Cost`. */
  costsByTenantId: CostsConnection;
  created: Scalars["Datetime"]["output"];
  /** Reads and enables pagination through a set of `DatasetMetadatum`. */
  datasetMetadataByTenantId: DatasetMetadataConnection;
  /** Reads and enables pagination through a set of `DatasetRecordingsTableColumnOrder`. */
  datasetRecordingsTableColumnOrdersByTenantId: DatasetRecordingsTableColumnOrdersConnection;
  /** Reads and enables pagination through a set of `DatasetRecordingsTableColumn`. */
  datasetRecordingsTableColumnsByTenantId: DatasetRecordingsTableColumnsConnection;
  /** Reads and enables pagination through a set of `DatasetRecordingsTable`. */
  datasetRecordingsTablesByTenantId: DatasetRecordingsTablesConnection;
  /** Reads and enables pagination through a set of `DatasetVersion`. */
  datasetVersionsByTenantId: DatasetVersionsConnection;
  /** Reads and enables pagination through a set of `Dataset`. */
  datasetsByTenantId: DatasetsConnection;
  /** Reads and enables pagination through a set of `FileActivity`. */
  fileActivitiesByTenantId: FileActivitiesConnection;
  /** Reads and enables pagination through a set of `FileMetadatum`. */
  fileMetadataByTenantId: FileMetadataConnection;
  /** Reads and enables pagination through a set of `FileOutputGroupMetadatum`. */
  fileOutputGroupMetadataByTenantId: FileOutputGroupMetadataConnection;
  /** Reads and enables pagination through a set of `FileOutputGroup`. */
  fileOutputGroupsByTenantId: FileOutputGroupsConnection;
  /** Reads and enables pagination through a set of `FileRecordingGroupMetadatum`. */
  fileRecordingGroupMetadataByTenantId: FileRecordingGroupMetadataConnection;
  /** Reads and enables pagination through a set of `FileRecordingGroup`. */
  fileRecordingGroupsByTenantId: FileRecordingGroupsConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByTenantId: FilesConnection;
  id: Scalars["Int"]["output"];
  internal: Scalars["Boolean"]["output"];
  key: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `Metadatum`. */
  metadataByTenantId: MetadataConnection;
  /** Reads and enables pagination through a set of `MetadataValue`. */
  metadataValuesByTenantId: MetadataValuesConnection;
  name: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `Project`. */
  projectsByTenantId: ProjectsConnection;
  /** Reads and enables pagination through a set of `RecordingGroupFile`. */
  recordingGroupFilesByTenantId: RecordingGroupFilesConnection;
  /** Reads and enables pagination through a set of `RoleChangeNotification`. */
  roleChangeNotificationsByTenantId: RoleChangeNotificationsConnection;
  /** Reads and enables pagination through a set of `Run`. */
  runsByTenantId: RunsConnection;
  status: Scalars["Int"]["output"];
  /** Reads and enables pagination through a set of `Tag`. */
  tagsByTenantId: TagsConnection;
  /** Reads and enables pagination through a set of `Task`. */
  tasksByTenantId: TasksConnection;
  /** Reads and enables pagination through a set of `TenantContract`. */
  tenantContractsByTenantId: TenantContractsConnection;
  /** Reads and enables pagination through a set of `TenantMonthlyConsumedResource`. */
  tenantMonthlyConsumedResourcesByTenantId: TenantMonthlyConsumedResourcesConnection;
  tenantType: Scalars["Int"]["output"];
  /** Reads and enables pagination through a set of `TenantUserMembershipNotification`. */
  tenantUserMembershipNotificationsByTenantId: TenantUserMembershipNotificationsConnection;
  /** Reads and enables pagination through a set of `ToolTenant`. */
  toolTenantsByTenantId: ToolTenantsConnection;
  /** Reads and enables pagination through a set of `ToolboxTenant`. */
  toolboxTenantsByTenantId: ToolboxTenantsConnection;
  /** Reads and enables pagination through a set of `Workflow`. */
  workflowsByTenantId: WorkflowsConnection;
};

export type TenantAnalysisTableGroupsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableGroupCondition>;
  filter?: InputMaybe<AnalysisTableGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTableGroupsOrderBy>>;
};

export type TenantAnalysisTableRowsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableRowCondition>;
  filter?: InputMaybe<AnalysisTableRowFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTableRowsOrderBy>>;
};

export type TenantAnalysisTablesByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableCondition>;
  filter?: InputMaybe<AnalysisTableFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTablesOrderBy>>;
};

export type TenantApplicationUserMembershipsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserMembershipCondition>;
  filter?: InputMaybe<ApplicationUserMembershipFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserMembershipsOrderBy>>;
};

export type TenantApplicationUserSessionsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ApplicationUserSessionCondition>;
  filter?: InputMaybe<ApplicationUserSessionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ApplicationUserSessionsOrderBy>>;
};

export type TenantCostsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<CostCondition>;
  filter?: InputMaybe<CostFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CostsOrderBy>>;
};

export type TenantDatasetMetadataByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetMetadatumCondition>;
  filter?: InputMaybe<DatasetMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetMetadataOrderBy>>;
};

export type TenantDatasetRecordingsTableColumnOrdersByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetRecordingsTableColumnOrderCondition>;
  filter?: InputMaybe<DatasetRecordingsTableColumnOrderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetRecordingsTableColumnOrdersOrderBy>>;
};

export type TenantDatasetRecordingsTableColumnsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetRecordingsTableColumnCondition>;
  filter?: InputMaybe<DatasetRecordingsTableColumnFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetRecordingsTableColumnsOrderBy>>;
};

export type TenantDatasetRecordingsTablesByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetRecordingsTableCondition>;
  filter?: InputMaybe<DatasetRecordingsTableFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetRecordingsTablesOrderBy>>;
};

export type TenantDatasetVersionsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetVersionCondition>;
  filter?: InputMaybe<DatasetVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetVersionsOrderBy>>;
};

export type TenantDatasetsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DatasetCondition>;
  filter?: InputMaybe<DatasetFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DatasetsOrderBy>>;
};

export type TenantFileActivitiesByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileActivityCondition>;
  filter?: InputMaybe<FileActivityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileActivitiesOrderBy>>;
};

export type TenantFileMetadataByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileMetadatumCondition>;
  filter?: InputMaybe<FileMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileMetadataOrderBy>>;
};

export type TenantFileOutputGroupMetadataByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileOutputGroupMetadatumCondition>;
  filter?: InputMaybe<FileOutputGroupMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileOutputGroupMetadataOrderBy>>;
};

export type TenantFileOutputGroupsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileOutputGroupCondition>;
  filter?: InputMaybe<FileOutputGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileOutputGroupsOrderBy>>;
};

export type TenantFileRecordingGroupMetadataByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileRecordingGroupMetadatumCondition>;
  filter?: InputMaybe<FileRecordingGroupMetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileRecordingGroupMetadataOrderBy>>;
};

export type TenantFileRecordingGroupsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileRecordingGroupCondition>;
  filter?: InputMaybe<FileRecordingGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FileRecordingGroupsOrderBy>>;
};

export type TenantFilesByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<FileCondition>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type TenantMetadataByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MetadatumCondition>;
  filter?: InputMaybe<MetadatumFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MetadataOrderBy>>;
};

export type TenantMetadataValuesByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MetadataValueCondition>;
  filter?: InputMaybe<MetadataValueFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MetadataValuesOrderBy>>;
};

export type TenantProjectsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

export type TenantRecordingGroupFilesByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<RecordingGroupFileCondition>;
  filter?: InputMaybe<RecordingGroupFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RecordingGroupFilesOrderBy>>;
};

export type TenantRoleChangeNotificationsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<RoleChangeNotificationCondition>;
  filter?: InputMaybe<RoleChangeNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RoleChangeNotificationsOrderBy>>;
};

export type TenantRunsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<RunCondition>;
  filter?: InputMaybe<RunFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RunsOrderBy>>;
};

export type TenantTagsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TagCondition>;
  filter?: InputMaybe<TagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
};

export type TenantTasksByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskCondition>;
  filter?: InputMaybe<TaskFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
};

export type TenantTenantContractsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantContractCondition>;
  filter?: InputMaybe<TenantContractFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantContractsOrderBy>>;
};

export type TenantTenantMonthlyConsumedResourcesByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantMonthlyConsumedResourceCondition>;
  filter?: InputMaybe<TenantMonthlyConsumedResourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantMonthlyConsumedResourcesOrderBy>>;
};

export type TenantTenantUserMembershipNotificationsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantUserMembershipNotificationCondition>;
  filter?: InputMaybe<TenantUserMembershipNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantUserMembershipNotificationsOrderBy>>;
};

export type TenantToolTenantsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolTenantCondition>;
  filter?: InputMaybe<ToolTenantFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolTenantsOrderBy>>;
};

export type TenantToolboxTenantsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxTenantCondition>;
  filter?: InputMaybe<ToolboxTenantFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxTenantsOrderBy>>;
};

export type TenantWorkflowsByTenantIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowCondition>;
  filter?: InputMaybe<WorkflowFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowsOrderBy>>;
};

export type TenantContract = {
  __typename: "TenantContract";
  created: Scalars["Datetime"]["output"];
  endDate: Scalars["Datetime"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  startDate: Scalars["Datetime"]["output"];
  /** Reads a single `Tenant` that is related to this `TenantContract`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads and enables pagination through a set of `TenantContractTransaction`. */
  tenantContractTransactionsByTenantContractId: TenantContractTransactionsConnection;
  tenantId: Scalars["Int"]["output"];
  /** Reads and enables pagination through a set of `TenantMonthlyConsumedResource`. */
  tenantMonthlyConsumedResourcesByTenantContractId: TenantMonthlyConsumedResourcesConnection;
};

export type TenantContractTenantContractTransactionsByTenantContractIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantContractTransactionCondition>;
  filter?: InputMaybe<TenantContractTransactionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantContractTransactionsOrderBy>>;
};

export type TenantContractTenantMonthlyConsumedResourcesByTenantContractIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<TenantMonthlyConsumedResourceCondition>;
    filter?: InputMaybe<TenantMonthlyConsumedResourceFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<TenantMonthlyConsumedResourcesOrderBy>>;
  };

/** Input for the nested mutation of `tenantContractTransaction` in the `TenantContractAllowanceInput` mutation. */
export type TenantContractAlloTenantContractTran29C19B1CFkTenantCoInput = {
  /** The primary key(s) for `tenantContractTransaction` for the far side of the relationship. */
  connectById?: InputMaybe<TenantContractTransactionTenantContractTransactionPkeyConnect>;
  /** A `TenantContractTransactionInput` object that will be created and connected to this object. */
  create?: InputMaybe<TenantContractAlloTenantContractTran29C19B1CFkTenantCoTenantContractTransactionCreateInput>;
  /** The primary key(s) for `tenantContractTransaction` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantContractTransactionTenantContractTransactionPkeyDelete>;
  /** The primary key(s) and patch data for `tenantContractTransaction` for the far side of the relationship. */
  updateById?: InputMaybe<TenantContractTransactionOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoUsingTenantContractTransactionPkeyUpdate>;
};

/** Input for the nested mutation of `tenantContractAllowance` in the `TenantContractTransactionInput` mutation. */
export type TenantContractAlloTenantContractTran29C19B1CFkTenantCoInverseInput =
  {
    /** The primary key(s) for `tenantContractAllowance` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<TenantContractAllowanceTenantContractAllowancePkeyConnect>
    >;
    /** The primary key(s) for `tenantContractAllowance` for the far side of the relationship. */
    connectByTenantContractTransactionIdAndQuotaType?: InputMaybe<
      Array<TenantContractAllowanceTenantContractTransactionQuotaTypeUniqueConnect>
    >;
    /** A `TenantContractAllowanceInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<TenantContractAlloTenantContractTran29C19B1CFkTenantCoTenantContractAllowanceCreateInput>
    >;
    /** The primary key(s) for `tenantContractAllowance` for the far side of the relationship. */
    deleteById?: InputMaybe<
      Array<TenantContractAllowanceTenantContractAllowancePkeyDelete>
    >;
    /** The primary key(s) for `tenantContractAllowance` for the far side of the relationship. */
    deleteByTenantContractTransactionIdAndQuotaType?: InputMaybe<
      Array<TenantContractAllowanceTenantContractTransactionQuotaTypeUniqueDelete>
    >;
    /** Flag indicating whether all other `tenantContractAllowance` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `tenantContractAllowance` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<TenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoUsingTenantContractAllowancePkeyUpdate>
    >;
    /** The primary key(s) and patch data for `tenantContractAllowance` for the far side of the relationship. */
    updateByTenantContractTransactionIdAndQuotaType?: InputMaybe<
      Array<TenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoUsingTenantContractTransactionQuotaTypeUniqueUpdate>
    >;
  };

/** The `tenantContractAllowance` to be created by this mutation. */
export type TenantContractAlloTenantContractTran29C19B1CFkTenantCoTenantContractAllowanceCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    quotaType: Scalars["Int"]["input"];
    tenantContractTransactionToTenantContractTransactionId?: InputMaybe<TenantContractAlloTenantContractTran29C19B1CFkTenantCoInput>;
    value: Scalars["String"]["input"];
  };

/** The `tenantContractTransaction` to be created by this mutation. */
export type TenantContractAlloTenantContractTran29C19B1CFkTenantCoTenantContractTransactionCreateInput =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    currency: Scalars["Int"]["input"];
    id?: InputMaybe<Scalars["Int"]["input"]>;
    price: Scalars["BigFloat"]["input"];
    tenantContractAllowancesUsingId?: InputMaybe<TenantContractAlloTenantContractTran29C19B1CFkTenantCoInverseInput>;
    tenantContractId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantContractToTenantContractId?: InputMaybe<TenantContractTranTenantContractId1E374993FkTenantCoInput>;
  };

export type TenantContractAllowance = {
  __typename: "TenantContractAllowance";
  id: Scalars["Int"]["output"];
  quotaType: Scalars["Int"]["output"];
  /** Reads a single `TenantContractTransaction` that is related to this `TenantContractAllowance`. */
  tenantContractTransactionByTenantContractTransactionId: Maybe<TenantContractTransaction>;
  tenantContractTransactionId: Scalars["Int"]["output"];
  value: Scalars["String"]["output"];
};

/**
 * A condition to be used against `TenantContractAllowance` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type TenantContractAllowanceCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `quotaType` field. */
  quotaType?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `tenantContractTransactionId` field. */
  tenantContractTransactionId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `TenantContractAllowance` object types. All fields are combined with a logical ‘and.’ */
export type TenantContractAllowanceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TenantContractAllowanceFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TenantContractAllowanceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TenantContractAllowanceFilter>>;
  /** Filter by the object’s `quotaType` field. */
  quotaType?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tenantContractTransactionByTenantContractTransactionId` relation. */
  tenantContractTransactionByTenantContractTransactionId?: InputMaybe<TenantContractTransactionFilter>;
  /** Filter by the object’s `tenantContractTransactionId` field. */
  tenantContractTransactionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `TenantContractAllowance` */
export type TenantContractAllowanceInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  quotaType: Scalars["Int"]["input"];
  tenantContractTransactionId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantContractTransactionToTenantContractTransactionId?: InputMaybe<TenantContractAlloTenantContractTran29C19B1CFkTenantCoInput>;
  value: Scalars["String"]["input"];
};

/** The fields on `tenantContractAllowance` to look up the row to update. */
export type TenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoUsingTenantContractAllowancePkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenantContractAllowance` being updated. */
    tenantContractAllowancePatch: UpdateTenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoPatch;
  };

/** The fields on `tenantContractAllowance` to look up the row to update. */
export type TenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoUsingTenantContractTransactionQuotaTypeUniqueUpdate =
  {
    quotaType: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenantContractAllowance` being updated. */
    tenantContractAllowancePatch: UpdateTenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoPatch;
    tenantContractTransactionId: Scalars["Int"]["input"];
  };

/** Represents an update to a `TenantContractAllowance`. Fields that are set will be updated. */
export type TenantContractAllowancePatch = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  quotaType?: InputMaybe<Scalars["Int"]["input"]>;
  tenantContractTransactionId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantContractTransactionToTenantContractTransactionId?: InputMaybe<TenantContractAlloTenantContractTran29C19B1CFkTenantCoInput>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/** The fields on `tenantContractAllowance` to look up the row to connect. */
export type TenantContractAllowanceTenantContractAllowancePkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `tenantContractAllowance` to look up the row to delete. */
export type TenantContractAllowanceTenantContractAllowancePkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** The fields on `tenantContractAllowance` to look up the row to connect. */
export type TenantContractAllowanceTenantContractTransactionQuotaTypeUniqueConnect =
  {
    quotaType: Scalars["Int"]["input"];
    tenantContractTransactionId: Scalars["Int"]["input"];
  };

/** The fields on `tenantContractAllowance` to look up the row to delete. */
export type TenantContractAllowanceTenantContractTransactionQuotaTypeUniqueDelete =
  {
    quotaType: Scalars["Int"]["input"];
    tenantContractTransactionId: Scalars["Int"]["input"];
  };

/** A connection to a list of `TenantContractAllowance` values. */
export type TenantContractAllowancesConnection = {
  __typename: "TenantContractAllowancesConnection";
  /** A list of edges which contains the `TenantContractAllowance` and cursor to aid in pagination. */
  edges: Array<TenantContractAllowancesEdge>;
  /** A list of `TenantContractAllowance` objects. */
  nodes: Array<TenantContractAllowance>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TenantContractAllowance` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `TenantContractAllowance` edge in the connection. */
export type TenantContractAllowancesEdge = {
  __typename: "TenantContractAllowancesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TenantContractAllowance` at the end of the edge. */
  node: TenantContractAllowance;
};

/** Methods to use when ordering `TenantContractAllowance`. */
export enum TenantContractAllowancesOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  QuotaTypeAsc = "QUOTA_TYPE_ASC",
  QuotaTypeDesc = "QUOTA_TYPE_DESC",
  TenantContractTransactionIdAsc = "TENANT_CONTRACT_TRANSACTION_ID_ASC",
  TenantContractTransactionIdDesc = "TENANT_CONTRACT_TRANSACTION_ID_DESC",
  ValueAsc = "VALUE_ASC",
  ValueDesc = "VALUE_DESC",
}

/**
 * A condition to be used against `TenantContract` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TenantContractCondition = {
  /** Checks for equality with the object’s `created` field. */
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `endDate` field. */
  endDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `startDate` field. */
  startDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `TenantContract` object types. All fields are combined with a logical ‘and.’ */
export type TenantContractFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TenantContractFilter>>;
  /** Filter by the object’s `created` field. */
  created?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `endDate` field. */
  endDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TenantContractFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TenantContractFilter>>;
  /** Filter by the object’s `startDate` field. */
  startDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantContractTransactionsByTenantContractId` relation. */
  tenantContractTransactionsByTenantContractId?: InputMaybe<TenantContractToManyTenantContractTransactionFilter>;
  /** Some related `tenantContractTransactionsByTenantContractId` exist. */
  tenantContractTransactionsByTenantContractIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tenantMonthlyConsumedResourcesByTenantContractId` relation. */
  tenantMonthlyConsumedResourcesByTenantContractId?: InputMaybe<TenantContractToManyTenantMonthlyConsumedResourceFilter>;
  /** Some related `tenantMonthlyConsumedResourcesByTenantContractId` exist. */
  tenantMonthlyConsumedResourcesByTenantContractIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
};

/** An input for mutations affecting `TenantContract` */
export type TenantContractInput = {
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  endDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  startDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  tenantContractTransactionsUsingId?: InputMaybe<TenantContractTranTenantContractId1E374993FkTenantCoInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantContractId681267CeFkTenantCoInverseInput>;
  tenantToTenantId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInput>;
};

/** The fields on `tenantContract` to look up the row to update. */
export type TenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantContractNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenantContract` being updated. */
    tenantContractPatch: UpdateTenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdPatch;
  };

/** The fields on `tenantContract` to look up the row to update. */
export type TenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantContractPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenantContract` being updated. */
    tenantContractPatch: UpdateTenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdPatch;
  };

/** The fields on `tenantContract` to look up the row to update. */
export type TenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoUsingTenantContractNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenantContract` being updated. */
    tenantContractPatch: UpdateTenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoPatch;
  };

/** The fields on `tenantContract` to look up the row to update. */
export type TenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoUsingTenantContractPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenantContract` being updated. */
    tenantContractPatch: UpdateTenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoPatch;
  };

/** The fields on `tenantContract` to look up the row to update. */
export type TenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantContractNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenantContract` being updated. */
    tenantContractPatch: UpdateTenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoPatch;
  };

/** The fields on `tenantContract` to look up the row to update. */
export type TenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantContractPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenantContract` being updated. */
    tenantContractPatch: UpdateTenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoPatch;
  };

/** Represents an update to a `TenantContract`. Fields that are set will be updated. */
export type TenantContractPatch = {
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  endDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  tenantContractTransactionsUsingId?: InputMaybe<TenantContractTranTenantContractId1E374993FkTenantCoInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantContractId681267CeFkTenantCoInverseInput>;
  tenantToTenantId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInput>;
};

/** The fields on `tenantContract` to look up the row to connect. */
export type TenantContractTenantContractNameKeyConnect = {
  name: Scalars["String"]["input"];
};

/** The fields on `tenantContract` to look up the row to delete. */
export type TenantContractTenantContractNameKeyDelete = {
  name: Scalars["String"]["input"];
};

/** The fields on `tenantContract` to look up the row to connect. */
export type TenantContractTenantContractPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `tenantContract` to look up the row to delete. */
export type TenantContractTenantContractPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** Input for the nested mutation of `tenant` in the `TenantContractInput` mutation. */
export type TenantContractTenantIdBa8Ac167FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `tenantContract` in the `TenantInput` mutation. */
export type TenantContractTenantIdBa8Ac167FkTenantIdInverseInput = {
  /** The primary key(s) for `tenantContract` for the far side of the relationship. */
  connectById?: InputMaybe<Array<TenantContractTenantContractPkeyConnect>>;
  /** The primary key(s) for `tenantContract` for the far side of the relationship. */
  connectByName?: InputMaybe<Array<TenantContractTenantContractNameKeyConnect>>;
  /** A `TenantContractInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<TenantContractTenantIdBa8Ac167FkTenantIdTenantContractCreateInput>
  >;
  /** The primary key(s) for `tenantContract` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<TenantContractTenantContractPkeyDelete>>;
  /** The primary key(s) for `tenantContract` for the far side of the relationship. */
  deleteByName?: InputMaybe<Array<TenantContractTenantContractNameKeyDelete>>;
  /** Flag indicating whether all other `tenantContract` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `tenantContract` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantContractPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `tenantContract` for the far side of the relationship. */
  updateByName?: InputMaybe<
    Array<TenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantContractNameKeyUpdate>
  >;
};

/** The `tenantContract` to be created by this mutation. */
export type TenantContractTenantIdBa8Ac167FkTenantIdTenantContractCreateInput =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    endDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name: Scalars["String"]["input"];
    startDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    tenantContractTransactionsUsingId?: InputMaybe<TenantContractTranTenantContractId1E374993FkTenantCoInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantContractId681267CeFkTenantCoInverseInput>;
    tenantToTenantId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInput>;
  };

/** The `tenant` to be created by this mutation. */
export type TenantContractTenantIdBa8Ac167FkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** A filter to be used against many `TenantContractTransaction` object types. All fields are combined with a logical ‘and.’ */
export type TenantContractToManyTenantContractTransactionFilter = {
  /** Every related `TenantContractTransaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TenantContractTransactionFilter>;
  /** No related `TenantContractTransaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TenantContractTransactionFilter>;
  /** Some related `TenantContractTransaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TenantContractTransactionFilter>;
};

/** A filter to be used against many `TenantMonthlyConsumedResource` object types. All fields are combined with a logical ‘and.’ */
export type TenantContractToManyTenantMonthlyConsumedResourceFilter = {
  /** Every related `TenantMonthlyConsumedResource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TenantMonthlyConsumedResourceFilter>;
  /** No related `TenantMonthlyConsumedResource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TenantMonthlyConsumedResourceFilter>;
  /** Some related `TenantMonthlyConsumedResource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TenantMonthlyConsumedResourceFilter>;
};

/** Input for the nested mutation of `tenantContract` in the `TenantContractTransactionInput` mutation. */
export type TenantContractTranTenantContractId1E374993FkTenantCoInput = {
  /** The primary key(s) for `tenantContract` for the far side of the relationship. */
  connectById?: InputMaybe<TenantContractTenantContractPkeyConnect>;
  /** The primary key(s) for `tenantContract` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantContractTenantContractNameKeyConnect>;
  /** A `TenantContractInput` object that will be created and connected to this object. */
  create?: InputMaybe<TenantContractTranTenantContractId1E374993FkTenantCoTenantContractCreateInput>;
  /** The primary key(s) for `tenantContract` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantContractTenantContractPkeyDelete>;
  /** The primary key(s) for `tenantContract` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantContractTenantContractNameKeyDelete>;
  /** The primary key(s) and patch data for `tenantContract` for the far side of the relationship. */
  updateById?: InputMaybe<TenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoUsingTenantContractPkeyUpdate>;
  /** The primary key(s) and patch data for `tenantContract` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoUsingTenantContractNameKeyUpdate>;
};

/** Input for the nested mutation of `tenantContractTransaction` in the `TenantContractInput` mutation. */
export type TenantContractTranTenantContractId1E374993FkTenantCoInverseInput = {
  /** The primary key(s) for `tenantContractTransaction` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<TenantContractTransactionTenantContractTransactionPkeyConnect>
  >;
  /** A `TenantContractTransactionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<TenantContractTranTenantContractId1E374993FkTenantCoTenantContractTransactionCreateInput>
  >;
  /** The primary key(s) for `tenantContractTransaction` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<TenantContractTransactionTenantContractTransactionPkeyDelete>
  >;
  /** Flag indicating whether all other `tenantContractTransaction` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `tenantContractTransaction` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TenantContractTransactionOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoUsingTenantContractTransactionPkeyUpdate>
  >;
};

/** The `tenantContract` to be created by this mutation. */
export type TenantContractTranTenantContractId1E374993FkTenantCoTenantContractCreateInput =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    endDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name: Scalars["String"]["input"];
    startDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    tenantContractTransactionsUsingId?: InputMaybe<TenantContractTranTenantContractId1E374993FkTenantCoInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantContractId681267CeFkTenantCoInverseInput>;
    tenantToTenantId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInput>;
  };

/** The `tenantContractTransaction` to be created by this mutation. */
export type TenantContractTranTenantContractId1E374993FkTenantCoTenantContractTransactionCreateInput =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    currency: Scalars["Int"]["input"];
    id?: InputMaybe<Scalars["Int"]["input"]>;
    price: Scalars["BigFloat"]["input"];
    tenantContractAllowancesUsingId?: InputMaybe<TenantContractAlloTenantContractTran29C19B1CFkTenantCoInverseInput>;
    tenantContractToTenantContractId?: InputMaybe<TenantContractTranTenantContractId1E374993FkTenantCoInput>;
  };

export type TenantContractTransaction = {
  __typename: "TenantContractTransaction";
  created: Scalars["Datetime"]["output"];
  currency: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  price: Scalars["BigFloat"]["output"];
  /** Reads and enables pagination through a set of `TenantContractAllowance`. */
  tenantContractAllowancesByTenantContractTransactionId: TenantContractAllowancesConnection;
  /** Reads a single `TenantContract` that is related to this `TenantContractTransaction`. */
  tenantContractByTenantContractId: Maybe<TenantContract>;
  tenantContractId: Scalars["Int"]["output"];
};

export type TenantContractTransactionTenantContractAllowancesByTenantContractTransactionIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<TenantContractAllowanceCondition>;
    filter?: InputMaybe<TenantContractAllowanceFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<TenantContractAllowancesOrderBy>>;
  };

/**
 * A condition to be used against `TenantContractTransaction` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type TenantContractTransactionCondition = {
  /** Checks for equality with the object’s `created` field. */
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `price` field. */
  price?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `tenantContractId` field. */
  tenantContractId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `TenantContractTransaction` object types. All fields are combined with a logical ‘and.’ */
export type TenantContractTransactionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TenantContractTransactionFilter>>;
  /** Filter by the object’s `created` field. */
  created?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TenantContractTransactionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TenantContractTransactionFilter>>;
  /** Filter by the object’s `price` field. */
  price?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `tenantContractAllowancesByTenantContractTransactionId` relation. */
  tenantContractAllowancesByTenantContractTransactionId?: InputMaybe<TenantContractTransactionToManyTenantContractAllowanceFilter>;
  /** Some related `tenantContractAllowancesByTenantContractTransactionId` exist. */
  tenantContractAllowancesByTenantContractTransactionIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `tenantContractByTenantContractId` relation. */
  tenantContractByTenantContractId?: InputMaybe<TenantContractFilter>;
  /** Filter by the object’s `tenantContractId` field. */
  tenantContractId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `TenantContractTransaction` */
export type TenantContractTransactionInput = {
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  currency: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  price: Scalars["BigFloat"]["input"];
  tenantContractAllowancesUsingId?: InputMaybe<TenantContractAlloTenantContractTran29C19B1CFkTenantCoInverseInput>;
  tenantContractId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantContractToTenantContractId?: InputMaybe<TenantContractTranTenantContractId1E374993FkTenantCoInput>;
};

/** The fields on `tenantContractTransaction` to look up the row to update. */
export type TenantContractTransactionOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoUsingTenantContractTransactionPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenantContractTransaction` being updated. */
    tenantContractTransactionPatch: UpdateTenantContractTransactionOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoPatch;
  };

/** The fields on `tenantContractTransaction` to look up the row to update. */
export type TenantContractTransactionOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoUsingTenantContractTransactionPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenantContractTransaction` being updated. */
    tenantContractTransactionPatch: UpdateTenantContractTransactionOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoPatch;
  };

/** Represents an update to a `TenantContractTransaction`. Fields that are set will be updated. */
export type TenantContractTransactionPatch = {
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  currency?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  price?: InputMaybe<Scalars["BigFloat"]["input"]>;
  tenantContractAllowancesUsingId?: InputMaybe<TenantContractAlloTenantContractTran29C19B1CFkTenantCoInverseInput>;
  tenantContractId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantContractToTenantContractId?: InputMaybe<TenantContractTranTenantContractId1E374993FkTenantCoInput>;
};

/** The fields on `tenantContractTransaction` to look up the row to connect. */
export type TenantContractTransactionTenantContractTransactionPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `tenantContractTransaction` to look up the row to delete. */
export type TenantContractTransactionTenantContractTransactionPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** A filter to be used against many `TenantContractAllowance` object types. All fields are combined with a logical ‘and.’ */
export type TenantContractTransactionToManyTenantContractAllowanceFilter = {
  /** Every related `TenantContractAllowance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TenantContractAllowanceFilter>;
  /** No related `TenantContractAllowance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TenantContractAllowanceFilter>;
  /** Some related `TenantContractAllowance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TenantContractAllowanceFilter>;
};

/** A connection to a list of `TenantContractTransaction` values. */
export type TenantContractTransactionsConnection = {
  __typename: "TenantContractTransactionsConnection";
  /** A list of edges which contains the `TenantContractTransaction` and cursor to aid in pagination. */
  edges: Array<TenantContractTransactionsEdge>;
  /** A list of `TenantContractTransaction` objects. */
  nodes: Array<TenantContractTransaction>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TenantContractTransaction` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `TenantContractTransaction` edge in the connection. */
export type TenantContractTransactionsEdge = {
  __typename: "TenantContractTransactionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TenantContractTransaction` at the end of the edge. */
  node: TenantContractTransaction;
};

/** Methods to use when ordering `TenantContractTransaction`. */
export enum TenantContractTransactionsOrderBy {
  CreatedAsc = "CREATED_ASC",
  CreatedDesc = "CREATED_DESC",
  CurrencyAsc = "CURRENCY_ASC",
  CurrencyDesc = "CURRENCY_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PriceAsc = "PRICE_ASC",
  PriceDesc = "PRICE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TenantContractIdAsc = "TENANT_CONTRACT_ID_ASC",
  TenantContractIdDesc = "TENANT_CONTRACT_ID_DESC",
}

/** A connection to a list of `TenantContract` values. */
export type TenantContractsConnection = {
  __typename: "TenantContractsConnection";
  /** A list of edges which contains the `TenantContract` and cursor to aid in pagination. */
  edges: Array<TenantContractsEdge>;
  /** A list of `TenantContract` objects. */
  nodes: Array<TenantContract>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TenantContract` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `TenantContract` edge in the connection. */
export type TenantContractsEdge = {
  __typename: "TenantContractsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TenantContract` at the end of the edge. */
  node: TenantContract;
};

/** Methods to use when ordering `TenantContract`. */
export enum TenantContractsOrderBy {
  CreatedAsc = "CREATED_ASC",
  CreatedDesc = "CREATED_DESC",
  EndDateAsc = "END_DATE_ASC",
  EndDateDesc = "END_DATE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StartDateAsc = "START_DATE_ASC",
  StartDateDesc = "START_DATE_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
}

/** A filter to be used against `Tenant` object types. All fields are combined with a logical ‘and.’ */
export type TenantFilter = {
  /** Filter by the object’s `analysisTableGroupsByTenantId` relation. */
  analysisTableGroupsByTenantId?: InputMaybe<TenantToManyAnalysisTableGroupFilter>;
  /** Some related `analysisTableGroupsByTenantId` exist. */
  analysisTableGroupsByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `analysisTableRowsByTenantId` relation. */
  analysisTableRowsByTenantId?: InputMaybe<TenantToManyAnalysisTableRowFilter>;
  /** Some related `analysisTableRowsByTenantId` exist. */
  analysisTableRowsByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `analysisTablesByTenantId` relation. */
  analysisTablesByTenantId?: InputMaybe<TenantToManyAnalysisTableFilter>;
  /** Some related `analysisTablesByTenantId` exist. */
  analysisTablesByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TenantFilter>>;
  /** Filter by the object’s `applicationUserMembershipsByTenantId` relation. */
  applicationUserMembershipsByTenantId?: InputMaybe<TenantToManyApplicationUserMembershipFilter>;
  /** Some related `applicationUserMembershipsByTenantId` exist. */
  applicationUserMembershipsByTenantIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `applicationUserSessionsByTenantId` relation. */
  applicationUserSessionsByTenantId?: InputMaybe<TenantToManyApplicationUserSessionFilter>;
  /** Some related `applicationUserSessionsByTenantId` exist. */
  applicationUserSessionsByTenantIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `costsByTenantId` relation. */
  costsByTenantId?: InputMaybe<TenantToManyCostFilter>;
  /** Some related `costsByTenantId` exist. */
  costsByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `created` field. */
  created?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `datasetMetadataByTenantId` relation. */
  datasetMetadataByTenantId?: InputMaybe<TenantToManyDatasetMetadatumFilter>;
  /** Some related `datasetMetadataByTenantId` exist. */
  datasetMetadataByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `datasetRecordingsTableColumnOrdersByTenantId` relation. */
  datasetRecordingsTableColumnOrdersByTenantId?: InputMaybe<TenantToManyDatasetRecordingsTableColumnOrderFilter>;
  /** Some related `datasetRecordingsTableColumnOrdersByTenantId` exist. */
  datasetRecordingsTableColumnOrdersByTenantIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `datasetRecordingsTableColumnsByTenantId` relation. */
  datasetRecordingsTableColumnsByTenantId?: InputMaybe<TenantToManyDatasetRecordingsTableColumnFilter>;
  /** Some related `datasetRecordingsTableColumnsByTenantId` exist. */
  datasetRecordingsTableColumnsByTenantIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `datasetRecordingsTablesByTenantId` relation. */
  datasetRecordingsTablesByTenantId?: InputMaybe<TenantToManyDatasetRecordingsTableFilter>;
  /** Some related `datasetRecordingsTablesByTenantId` exist. */
  datasetRecordingsTablesByTenantIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `datasetVersionsByTenantId` relation. */
  datasetVersionsByTenantId?: InputMaybe<TenantToManyDatasetVersionFilter>;
  /** Some related `datasetVersionsByTenantId` exist. */
  datasetVersionsByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `datasetsByTenantId` relation. */
  datasetsByTenantId?: InputMaybe<TenantToManyDatasetFilter>;
  /** Some related `datasetsByTenantId` exist. */
  datasetsByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fileActivitiesByTenantId` relation. */
  fileActivitiesByTenantId?: InputMaybe<TenantToManyFileActivityFilter>;
  /** Some related `fileActivitiesByTenantId` exist. */
  fileActivitiesByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fileMetadataByTenantId` relation. */
  fileMetadataByTenantId?: InputMaybe<TenantToManyFileMetadatumFilter>;
  /** Some related `fileMetadataByTenantId` exist. */
  fileMetadataByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fileOutputGroupMetadataByTenantId` relation. */
  fileOutputGroupMetadataByTenantId?: InputMaybe<TenantToManyFileOutputGroupMetadatumFilter>;
  /** Some related `fileOutputGroupMetadataByTenantId` exist. */
  fileOutputGroupMetadataByTenantIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `fileOutputGroupsByTenantId` relation. */
  fileOutputGroupsByTenantId?: InputMaybe<TenantToManyFileOutputGroupFilter>;
  /** Some related `fileOutputGroupsByTenantId` exist. */
  fileOutputGroupsByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fileRecordingGroupMetadataByTenantId` relation. */
  fileRecordingGroupMetadataByTenantId?: InputMaybe<TenantToManyFileRecordingGroupMetadatumFilter>;
  /** Some related `fileRecordingGroupMetadataByTenantId` exist. */
  fileRecordingGroupMetadataByTenantIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `fileRecordingGroupsByTenantId` relation. */
  fileRecordingGroupsByTenantId?: InputMaybe<TenantToManyFileRecordingGroupFilter>;
  /** Some related `fileRecordingGroupsByTenantId` exist. */
  fileRecordingGroupsByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `filesByTenantId` relation. */
  filesByTenantId?: InputMaybe<TenantToManyFileFilter>;
  /** Some related `filesByTenantId` exist. */
  filesByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `internal` field. */
  internal?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metadataByTenantId` relation. */
  metadataByTenantId?: InputMaybe<TenantToManyMetadatumFilter>;
  /** Some related `metadataByTenantId` exist. */
  metadataByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `metadataValuesByTenantId` relation. */
  metadataValuesByTenantId?: InputMaybe<TenantToManyMetadataValueFilter>;
  /** Some related `metadataValuesByTenantId` exist. */
  metadataValuesByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TenantFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TenantFilter>>;
  /** Filter by the object’s `projectsByTenantId` relation. */
  projectsByTenantId?: InputMaybe<TenantToManyProjectFilter>;
  /** Some related `projectsByTenantId` exist. */
  projectsByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `recordingGroupFilesByTenantId` relation. */
  recordingGroupFilesByTenantId?: InputMaybe<TenantToManyRecordingGroupFileFilter>;
  /** Some related `recordingGroupFilesByTenantId` exist. */
  recordingGroupFilesByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `roleChangeNotificationsByTenantId` relation. */
  roleChangeNotificationsByTenantId?: InputMaybe<TenantToManyRoleChangeNotificationFilter>;
  /** Some related `roleChangeNotificationsByTenantId` exist. */
  roleChangeNotificationsByTenantIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `runsByTenantId` relation. */
  runsByTenantId?: InputMaybe<TenantToManyRunFilter>;
  /** Some related `runsByTenantId` exist. */
  runsByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tagsByTenantId` relation. */
  tagsByTenantId?: InputMaybe<TenantToManyTagFilter>;
  /** Some related `tagsByTenantId` exist. */
  tagsByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tasksByTenantId` relation. */
  tasksByTenantId?: InputMaybe<TenantToManyTaskFilter>;
  /** Some related `tasksByTenantId` exist. */
  tasksByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tenantContractsByTenantId` relation. */
  tenantContractsByTenantId?: InputMaybe<TenantToManyTenantContractFilter>;
  /** Some related `tenantContractsByTenantId` exist. */
  tenantContractsByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tenantMonthlyConsumedResourcesByTenantId` relation. */
  tenantMonthlyConsumedResourcesByTenantId?: InputMaybe<TenantToManyTenantMonthlyConsumedResourceFilter>;
  /** Some related `tenantMonthlyConsumedResourcesByTenantId` exist. */
  tenantMonthlyConsumedResourcesByTenantIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `tenantType` field. */
  tenantType?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tenantUserMembershipNotificationsByTenantId` relation. */
  tenantUserMembershipNotificationsByTenantId?: InputMaybe<TenantToManyTenantUserMembershipNotificationFilter>;
  /** Some related `tenantUserMembershipNotificationsByTenantId` exist. */
  tenantUserMembershipNotificationsByTenantIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `toolTenantsByTenantId` relation. */
  toolTenantsByTenantId?: InputMaybe<TenantToManyToolTenantFilter>;
  /** Some related `toolTenantsByTenantId` exist. */
  toolTenantsByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `toolboxTenantsByTenantId` relation. */
  toolboxTenantsByTenantId?: InputMaybe<TenantToManyToolboxTenantFilter>;
  /** Some related `toolboxTenantsByTenantId` exist. */
  toolboxTenantsByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `workflowsByTenantId` relation. */
  workflowsByTenantId?: InputMaybe<TenantToManyWorkflowFilter>;
  /** Some related `workflowsByTenantId` exist. */
  workflowsByTenantIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** An input for mutations affecting `Tenant` */
export type TenantInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** Input for the nested mutation of `tenantUserexecutor` in the `TenantMockuserexecutorInput` mutation. */
export type TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInput = {
  /** The primary key(s) for `tenantUserexecutor` for the far side of the relationship. */
  connectById?: InputMaybe<TenantUserexecutorTenantUserexecutorPkeyConnect>;
  /** A `TenantUserexecutorInput` object that will be created and connected to this object. */
  create?: InputMaybe<TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsTenantUserexecutorCreateInput>;
  /** The primary key(s) for `tenantUserexecutor` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantUserexecutorTenantUserexecutorPkeyDelete>;
  /** The primary key(s) and patch data for `tenantUserexecutor` for the far side of the relationship. */
  updateById?: InputMaybe<TenantUserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsUsingTenantUserexecutorPkeyUpdate>;
};

/** Input for the nested mutation of `tenantMockuserexecutor` in the `TenantUserexecutorInput` mutation. */
export type TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInverseInput =
  {
    /** The primary key(s) for `tenantMockuserexecutor` for the far side of the relationship. */
    connectByUserexecutorPtrId?: InputMaybe<TenantMockuserexecutorTenantMockuserexecutorPkeyConnect>;
    /** A `TenantMockuserexecutorInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsTenantMockuserexecutorCreateInput>
    >;
    /** The primary key(s) for `tenantMockuserexecutor` for the far side of the relationship. */
    deleteByUserexecutorPtrId?: InputMaybe<TenantMockuserexecutorTenantMockuserexecutorPkeyDelete>;
    /** Flag indicating whether all other `tenantMockuserexecutor` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `tenantMockuserexecutor` for the far side of the relationship. */
    updateByUserexecutorPtrId?: InputMaybe<TenantMockuserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsUsingTenantMockuserexecutorPkeyUpdate>;
  };

/** The `tenantMockuserexecutor` to be created by this mutation. */
export type TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsTenantMockuserexecutorCreateInput =
  {
    tenantUserexecutorToUserexecutorPtrId?: InputMaybe<TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInput>;
  };

/** The `tenantUserexecutor` to be created by this mutation. */
export type TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsTenantUserexecutorCreateInput =
  {
    applicationUserUsingId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInverseInput>;
    awsCognitoUserUsingId?: InputMaybe<AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantMockuserexecutorUsingId?: InputMaybe<TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInverseInput>;
  };

export type TenantMockuserexecutor = {
  __typename: "TenantMockuserexecutor";
  /** Reads a single `TenantUserexecutor` that is related to this `TenantMockuserexecutor`. */
  tenantUserexecutorByUserexecutorPtrId: Maybe<TenantUserexecutor>;
  userexecutorPtrId: Scalars["Int"]["output"];
};

/**
 * A condition to be used against `TenantMockuserexecutor` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type TenantMockuserexecutorCondition = {
  /** Checks for equality with the object’s `userexecutorPtrId` field. */
  userexecutorPtrId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `TenantMockuserexecutor` object types. All fields are combined with a logical ‘and.’ */
export type TenantMockuserexecutorFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TenantMockuserexecutorFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<TenantMockuserexecutorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TenantMockuserexecutorFilter>>;
  /** Filter by the object’s `tenantUserexecutorByUserexecutorPtrId` relation. */
  tenantUserexecutorByUserexecutorPtrId?: InputMaybe<TenantUserexecutorFilter>;
  /** Filter by the object’s `userexecutorPtrId` field. */
  userexecutorPtrId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `TenantMockuserexecutor` */
export type TenantMockuserexecutorInput = {
  tenantUserexecutorToUserexecutorPtrId?: InputMaybe<TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInput>;
  userexecutorPtrId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `tenantMockuserexecutor` to look up the row to update. */
export type TenantMockuserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsUsingTenantMockuserexecutorPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `tenantMockuserexecutor` being updated. */
    tenantMockuserexecutorPatch: UpdateTenantMockuserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsPatch;
    userexecutorPtrId: Scalars["Int"]["input"];
  };

/** Represents an update to a `TenantMockuserexecutor`. Fields that are set will be updated. */
export type TenantMockuserexecutorPatch = {
  tenantUserexecutorToUserexecutorPtrId?: InputMaybe<TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInput>;
  userexecutorPtrId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `tenantMockuserexecutor` to look up the row to connect. */
export type TenantMockuserexecutorTenantMockuserexecutorPkeyConnect = {
  userexecutorPtrId: Scalars["Int"]["input"];
};

/** The fields on `tenantMockuserexecutor` to look up the row to delete. */
export type TenantMockuserexecutorTenantMockuserexecutorPkeyDelete = {
  userexecutorPtrId: Scalars["Int"]["input"];
};

/** A connection to a list of `TenantMockuserexecutor` values. */
export type TenantMockuserexecutorsConnection = {
  __typename: "TenantMockuserexecutorsConnection";
  /** A list of edges which contains the `TenantMockuserexecutor` and cursor to aid in pagination. */
  edges: Array<TenantMockuserexecutorsEdge>;
  /** A list of `TenantMockuserexecutor` objects. */
  nodes: Array<TenantMockuserexecutor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TenantMockuserexecutor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `TenantMockuserexecutor` edge in the connection. */
export type TenantMockuserexecutorsEdge = {
  __typename: "TenantMockuserexecutorsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TenantMockuserexecutor` at the end of the edge. */
  node: TenantMockuserexecutor;
};

/** Methods to use when ordering `TenantMockuserexecutor`. */
export enum TenantMockuserexecutorsOrderBy {
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UserexecutorPtrIdAsc = "USEREXECUTOR_PTR_ID_ASC",
  UserexecutorPtrIdDesc = "USEREXECUTOR_PTR_ID_DESC",
}

/** Input for the nested mutation of `tenantContract` in the `TenantMonthlyConsumedResourceInput` mutation. */
export type TenantMonthlyConsuTenantContractId681267CeFkTenantCoInput = {
  /** The primary key(s) for `tenantContract` for the far side of the relationship. */
  connectById?: InputMaybe<TenantContractTenantContractPkeyConnect>;
  /** The primary key(s) for `tenantContract` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantContractTenantContractNameKeyConnect>;
  /** A `TenantContractInput` object that will be created and connected to this object. */
  create?: InputMaybe<TenantMonthlyConsuTenantContractId681267CeFkTenantCoTenantContractCreateInput>;
  /** The primary key(s) for `tenantContract` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantContractTenantContractPkeyDelete>;
  /** The primary key(s) for `tenantContract` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantContractTenantContractNameKeyDelete>;
  /** The primary key(s) and patch data for `tenantContract` for the far side of the relationship. */
  updateById?: InputMaybe<TenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantContractPkeyUpdate>;
  /** The primary key(s) and patch data for `tenantContract` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantContractNameKeyUpdate>;
};

/** Input for the nested mutation of `tenantMonthlyConsumedResource` in the `TenantContractInput` mutation. */
export type TenantMonthlyConsuTenantContractId681267CeFkTenantCoInverseInput = {
  /** The primary key(s) for `tenantMonthlyConsumedResource` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesPkeyConnect>
  >;
  /** The primary key(s) for `tenantMonthlyConsumedResource` for the far side of the relationship. */
  connectByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract?: InputMaybe<
    Array<TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesUniqueConnect>
  >;
  /** A `TenantMonthlyConsumedResourceInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<TenantMonthlyConsuTenantContractId681267CeFkTenantCoTenantMonthlyConsumedResourcesCreateInput>
  >;
  /** The primary key(s) for `tenantMonthlyConsumedResource` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesPkeyDelete>
  >;
  /** The primary key(s) for `tenantMonthlyConsumedResource` for the far side of the relationship. */
  deleteByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract?: InputMaybe<
    Array<TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesUniqueDelete>
  >;
  /** Flag indicating whether all other `tenantMonthlyConsumedResource` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `tenantMonthlyConsumedResource` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantMonthlyConsumedResourcesPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `tenantMonthlyConsumedResource` for the far side of the relationship. */
  updateByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract?: InputMaybe<
    Array<TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantMonthlyConsumedResourcesUniqueUpdate>
  >;
};

/** The `tenantContract` to be created by this mutation. */
export type TenantMonthlyConsuTenantContractId681267CeFkTenantCoTenantContractCreateInput =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    endDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name: Scalars["String"]["input"];
    startDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    tenantContractTransactionsUsingId?: InputMaybe<TenantContractTranTenantContractId1E374993FkTenantCoInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantContractId681267CeFkTenantCoInverseInput>;
    tenantToTenantId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInput>;
  };

/** The `tenantMonthlyConsumedResource` to be created by this mutation. */
export type TenantMonthlyConsuTenantContractId681267CeFkTenantCoTenantMonthlyConsumedResourcesCreateInput =
  {
    applicationUserToUserId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    month: Scalars["Date"]["input"];
    monthInActiveContract?: InputMaybe<Scalars["Int"]["input"]>;
    percentage?: InputMaybe<Scalars["Float"]["input"]>;
    quotaType: Scalars["Int"]["input"];
    tenantContractToTenantContractId?: InputMaybe<TenantMonthlyConsuTenantContractId681267CeFkTenantCoInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    value: Scalars["String"]["input"];
  };

/** Input for the nested mutation of `tenant` in the `TenantMonthlyConsumedResourceInput` mutation. */
export type TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `tenantMonthlyConsumedResource` in the `TenantInput` mutation. */
export type TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput = {
  /** The primary key(s) for `tenantMonthlyConsumedResource` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesPkeyConnect>
  >;
  /** The primary key(s) for `tenantMonthlyConsumedResource` for the far side of the relationship. */
  connectByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract?: InputMaybe<
    Array<TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesUniqueConnect>
  >;
  /** A `TenantMonthlyConsumedResourceInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdTenantMonthlyConsumedResourcesCreateInput>
  >;
  /** The primary key(s) for `tenantMonthlyConsumedResource` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesPkeyDelete>
  >;
  /** The primary key(s) for `tenantMonthlyConsumedResource` for the far side of the relationship. */
  deleteByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract?: InputMaybe<
    Array<TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesUniqueDelete>
  >;
  /** Flag indicating whether all other `tenantMonthlyConsumedResource` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `tenantMonthlyConsumedResource` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantMonthlyConsumedResourcesPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `tenantMonthlyConsumedResource` for the far side of the relationship. */
  updateByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract?: InputMaybe<
    Array<TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantMonthlyConsumedResourcesUniqueUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** The `tenantMonthlyConsumedResource` to be created by this mutation. */
export type TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdTenantMonthlyConsumedResourcesCreateInput =
  {
    applicationUserToUserId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    month: Scalars["Date"]["input"];
    monthInActiveContract?: InputMaybe<Scalars["Int"]["input"]>;
    percentage?: InputMaybe<Scalars["Float"]["input"]>;
    quotaType: Scalars["Int"]["input"];
    tenantContractId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantContractToTenantContractId?: InputMaybe<TenantMonthlyConsuTenantContractId681267CeFkTenantCoInput>;
    tenantToTenantId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    value: Scalars["String"]["input"];
  };

/** The `applicationUser` to be created by this mutation. */
export type TenantMonthlyConsuUserIdF7210F55FkApplicatiApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `TenantMonthlyConsumedResourceInput` mutation. */
export type TenantMonthlyConsuUserIdF7210F55FkApplicatiInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `tenantMonthlyConsumedResource` in the `ApplicationUserInput` mutation. */
export type TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput = {
  /** The primary key(s) for `tenantMonthlyConsumedResource` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesPkeyConnect>
  >;
  /** The primary key(s) for `tenantMonthlyConsumedResource` for the far side of the relationship. */
  connectByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract?: InputMaybe<
    Array<TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesUniqueConnect>
  >;
  /** A `TenantMonthlyConsumedResourceInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<TenantMonthlyConsuUserIdF7210F55FkApplicatiTenantMonthlyConsumedResourcesCreateInput>
  >;
  /** The primary key(s) for `tenantMonthlyConsumedResource` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesPkeyDelete>
  >;
  /** The primary key(s) for `tenantMonthlyConsumedResource` for the far side of the relationship. */
  deleteByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract?: InputMaybe<
    Array<TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesUniqueDelete>
  >;
  /** Flag indicating whether all other `tenantMonthlyConsumedResource` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `tenantMonthlyConsumedResource` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingTenantMonthlyConsumedResourcesPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `tenantMonthlyConsumedResource` for the far side of the relationship. */
  updateByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract?: InputMaybe<
    Array<TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingTenantMonthlyConsumedResourcesUniqueUpdate>
  >;
};

/** The `tenantMonthlyConsumedResource` to be created by this mutation. */
export type TenantMonthlyConsuUserIdF7210F55FkApplicatiTenantMonthlyConsumedResourcesCreateInput =
  {
    applicationUserToUserId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    month: Scalars["Date"]["input"];
    monthInActiveContract?: InputMaybe<Scalars["Int"]["input"]>;
    percentage?: InputMaybe<Scalars["Float"]["input"]>;
    quotaType: Scalars["Int"]["input"];
    tenantContractId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantContractToTenantContractId?: InputMaybe<TenantMonthlyConsuTenantContractId681267CeFkTenantCoInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInput>;
    value: Scalars["String"]["input"];
  };

export type TenantMonthlyConsumedResource = {
  __typename: "TenantMonthlyConsumedResource";
  /** Reads a single `ApplicationUser` that is related to this `TenantMonthlyConsumedResource`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  id: Scalars["Int"]["output"];
  month: Scalars["Date"]["output"];
  monthInActiveContract: Maybe<Scalars["Int"]["output"]>;
  percentage: Maybe<Scalars["Float"]["output"]>;
  quotaType: Scalars["Int"]["output"];
  /** Reads a single `Tenant` that is related to this `TenantMonthlyConsumedResource`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `TenantContract` that is related to this `TenantMonthlyConsumedResource`. */
  tenantContractByTenantContractId: Maybe<TenantContract>;
  tenantContractId: Maybe<Scalars["Int"]["output"]>;
  tenantId: Scalars["Int"]["output"];
  userId: Maybe<Scalars["UUID"]["output"]>;
  value: Scalars["String"]["output"];
};

/**
 * A condition to be used against `TenantMonthlyConsumedResource` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type TenantMonthlyConsumedResourceCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `month` field. */
  month?: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `monthInActiveContract` field. */
  monthInActiveContract?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `percentage` field. */
  percentage?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `quotaType` field. */
  quotaType?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `tenantContractId` field. */
  tenantContractId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `TenantMonthlyConsumedResource` object types. All fields are combined with a logical ‘and.’ */
export type TenantMonthlyConsumedResourceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TenantMonthlyConsumedResourceFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** A related `applicationUserByUserId` exists. */
  applicationUserByUserIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `month` field. */
  month?: InputMaybe<DateFilter>;
  /** Filter by the object’s `monthInActiveContract` field. */
  monthInActiveContract?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TenantMonthlyConsumedResourceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TenantMonthlyConsumedResourceFilter>>;
  /** Filter by the object’s `percentage` field. */
  percentage?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `quotaType` field. */
  quotaType?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantContractByTenantContractId` relation. */
  tenantContractByTenantContractId?: InputMaybe<TenantContractFilter>;
  /** A related `tenantContractByTenantContractId` exists. */
  tenantContractByTenantContractIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `tenantContractId` field. */
  tenantContractId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `TenantMonthlyConsumedResource` */
export type TenantMonthlyConsumedResourceInput = {
  applicationUserToUserId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  month: Scalars["Date"]["input"];
  monthInActiveContract?: InputMaybe<Scalars["Int"]["input"]>;
  percentage?: InputMaybe<Scalars["Float"]["input"]>;
  quotaType: Scalars["Int"]["input"];
  tenantContractId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantContractToTenantContractId?: InputMaybe<TenantMonthlyConsuTenantContractId681267CeFkTenantCoInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  value: Scalars["String"]["input"];
};

/** The fields on `tenantMonthlyConsumedResource` to look up the row to update. */
export type TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantMonthlyConsumedResourcesPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenantMonthlyConsumedResource` being updated. */
    tenantMonthlyConsumedResourcePatch: UpdateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoPatch;
  };

/** The fields on `tenantMonthlyConsumedResource` to look up the row to update. */
export type TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantMonthlyConsumedResourcesUniqueUpdate =
  {
    month: Scalars["Date"]["input"];
    monthInActiveContract: Scalars["Int"]["input"];
    quotaType: Scalars["Int"]["input"];
    tenantContractId: Scalars["Int"]["input"];
    tenantId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenantMonthlyConsumedResource` being updated. */
    tenantMonthlyConsumedResourcePatch: UpdateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoPatch;
    userId: Scalars["UUID"]["input"];
  };

/** The fields on `tenantMonthlyConsumedResource` to look up the row to update. */
export type TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantMonthlyConsumedResourcesPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenantMonthlyConsumedResource` being updated. */
    tenantMonthlyConsumedResourcePatch: UpdateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdPatch;
  };

/** The fields on `tenantMonthlyConsumedResource` to look up the row to update. */
export type TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantMonthlyConsumedResourcesUniqueUpdate =
  {
    month: Scalars["Date"]["input"];
    monthInActiveContract: Scalars["Int"]["input"];
    quotaType: Scalars["Int"]["input"];
    tenantContractId: Scalars["Int"]["input"];
    tenantId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenantMonthlyConsumedResource` being updated. */
    tenantMonthlyConsumedResourcePatch: UpdateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdPatch;
    userId: Scalars["UUID"]["input"];
  };

/** The fields on `tenantMonthlyConsumedResource` to look up the row to update. */
export type TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingTenantMonthlyConsumedResourcesPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenantMonthlyConsumedResource` being updated. */
    tenantMonthlyConsumedResourcePatch: UpdateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiPatch;
  };

/** The fields on `tenantMonthlyConsumedResource` to look up the row to update. */
export type TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingTenantMonthlyConsumedResourcesUniqueUpdate =
  {
    month: Scalars["Date"]["input"];
    monthInActiveContract: Scalars["Int"]["input"];
    quotaType: Scalars["Int"]["input"];
    tenantContractId: Scalars["Int"]["input"];
    tenantId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenantMonthlyConsumedResource` being updated. */
    tenantMonthlyConsumedResourcePatch: UpdateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiPatch;
    userId: Scalars["UUID"]["input"];
  };

/** Represents an update to a `TenantMonthlyConsumedResource`. Fields that are set will be updated. */
export type TenantMonthlyConsumedResourcePatch = {
  applicationUserToUserId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  month?: InputMaybe<Scalars["Date"]["input"]>;
  monthInActiveContract?: InputMaybe<Scalars["Int"]["input"]>;
  percentage?: InputMaybe<Scalars["Float"]["input"]>;
  quotaType?: InputMaybe<Scalars["Int"]["input"]>;
  tenantContractId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantContractToTenantContractId?: InputMaybe<TenantMonthlyConsuTenantContractId681267CeFkTenantCoInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/** The fields on `tenantMonthlyConsumedResource` to look up the row to connect. */
export type TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesPkeyConnect =
  {
    id: Scalars["Int"]["input"];
  };

/** The fields on `tenantMonthlyConsumedResource` to look up the row to delete. */
export type TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesPkeyDelete =
  {
    id: Scalars["Int"]["input"];
  };

/** The fields on `tenantMonthlyConsumedResource` to look up the row to connect. */
export type TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesUniqueConnect =
  {
    month: Scalars["Date"]["input"];
    monthInActiveContract: Scalars["Int"]["input"];
    quotaType: Scalars["Int"]["input"];
    tenantContractId: Scalars["Int"]["input"];
    tenantId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/** The fields on `tenantMonthlyConsumedResource` to look up the row to delete. */
export type TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesUniqueDelete =
  {
    month: Scalars["Date"]["input"];
    monthInActiveContract: Scalars["Int"]["input"];
    quotaType: Scalars["Int"]["input"];
    tenantContractId: Scalars["Int"]["input"];
    tenantId: Scalars["Int"]["input"];
    userId: Scalars["UUID"]["input"];
  };

/** A connection to a list of `TenantMonthlyConsumedResource` values. */
export type TenantMonthlyConsumedResourcesConnection = {
  __typename: "TenantMonthlyConsumedResourcesConnection";
  /** A list of edges which contains the `TenantMonthlyConsumedResource` and cursor to aid in pagination. */
  edges: Array<TenantMonthlyConsumedResourcesEdge>;
  /** A list of `TenantMonthlyConsumedResource` objects. */
  nodes: Array<TenantMonthlyConsumedResource>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TenantMonthlyConsumedResource` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `TenantMonthlyConsumedResource` edge in the connection. */
export type TenantMonthlyConsumedResourcesEdge = {
  __typename: "TenantMonthlyConsumedResourcesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TenantMonthlyConsumedResource` at the end of the edge. */
  node: TenantMonthlyConsumedResource;
};

/** Methods to use when ordering `TenantMonthlyConsumedResource`. */
export enum TenantMonthlyConsumedResourcesOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MonthAsc = "MONTH_ASC",
  MonthDesc = "MONTH_DESC",
  MonthInActiveContractAsc = "MONTH_IN_ACTIVE_CONTRACT_ASC",
  MonthInActiveContractDesc = "MONTH_IN_ACTIVE_CONTRACT_DESC",
  Natural = "NATURAL",
  PercentageAsc = "PERCENTAGE_ASC",
  PercentageDesc = "PERCENTAGE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  QuotaTypeAsc = "QUOTA_TYPE_ASC",
  QuotaTypeDesc = "QUOTA_TYPE_DESC",
  TenantContractIdAsc = "TENANT_CONTRACT_ID_ASC",
  TenantContractIdDesc = "TENANT_CONTRACT_ID_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
  ValueAsc = "VALUE_ASC",
  ValueDesc = "VALUE_DESC",
}

/** The fields on `tenant` to look up the row to update. */
export type TenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnCostForCostTenantId7Dcd58A7FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnCostForCostTenantId7Dcd58A7FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnCostForCostTenantId7Dcd58A7FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnCostForCostTenantId7Dcd58A7FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnCostForCostTenantId7Dcd58A7FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnCostForCostTenantId7Dcd58A7FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileForFileTenantId61625016FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileForFileTenantId61625016FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileForFileTenantId61625016FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileForFileTenantId61625016FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileForFileTenantId61625016FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileForFileTenantId61625016FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnProjectForProjectTenantId03F0Ca53FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnProjectForProjectTenantId03F0Ca53FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnProjectForProjectTenantId03F0Ca53FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnRunForRunTenantId73Fbd8D9FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnRunForRunTenantId73Fbd8D9FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnRunForRunTenantId73Fbd8D9FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnRunForRunTenantId73Fbd8D9FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnRunForRunTenantId73Fbd8D9FkTenantIdUsingTenantPkeyUpdate = {
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `tenant` being updated. */
  tenantPatch: UpdateTenantOnRunForRunTenantId73Fbd8D9FkTenantIdPatch;
};

/** The fields on `tenant` to look up the row to update. */
export type TenantOnTagForTagTenantId8072B61FFkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnTagForTagTenantId8072B61FFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnTagForTagTenantId8072B61FFkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnTagForTagTenantId8072B61FFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnTagForTagTenantId8072B61FFkTenantIdUsingTenantPkeyUpdate = {
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `tenant` being updated. */
  tenantPatch: UpdateTenantOnTagForTagTenantId8072B61FFkTenantIdPatch;
};

/** The fields on `tenant` to look up the row to update. */
export type TenantOnTaskForTaskTenantId148E90DaFkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnTaskForTaskTenantId148E90DaFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnTaskForTaskTenantId148E90DaFkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnTaskForTaskTenantId148E90DaFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnTaskForTaskTenantId148E90DaFkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnTaskForTaskTenantId148E90DaFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingTenantKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingTenantNameKeyUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdPatch;
  };

/** The fields on `tenant` to look up the row to update. */
export type TenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingTenantPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenant` being updated. */
    tenantPatch: UpdateTenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdPatch;
  };

/** Represents an update to a `Tenant`. Fields that are set will be updated. */
export type TenantPatch = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType?: InputMaybe<Scalars["Int"]["input"]>;
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** The fields on `tenant` to look up the row to connect. */
export type TenantTenantKeyKeyConnect = {
  key: Scalars["String"]["input"];
};

/** The fields on `tenant` to look up the row to delete. */
export type TenantTenantKeyKeyDelete = {
  key: Scalars["String"]["input"];
};

/** The fields on `tenant` to look up the row to connect. */
export type TenantTenantNameKeyConnect = {
  name: Scalars["String"]["input"];
};

/** The fields on `tenant` to look up the row to delete. */
export type TenantTenantNameKeyDelete = {
  name: Scalars["String"]["input"];
};

/** The fields on `tenant` to look up the row to connect. */
export type TenantTenantPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `tenant` to look up the row to delete. */
export type TenantTenantPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** A filter to be used against many `AnalysisTable` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyAnalysisTableFilter = {
  /** Every related `AnalysisTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AnalysisTableFilter>;
  /** No related `AnalysisTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AnalysisTableFilter>;
  /** Some related `AnalysisTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AnalysisTableFilter>;
};

/** A filter to be used against many `AnalysisTableGroup` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyAnalysisTableGroupFilter = {
  /** Every related `AnalysisTableGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AnalysisTableGroupFilter>;
  /** No related `AnalysisTableGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AnalysisTableGroupFilter>;
  /** Some related `AnalysisTableGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AnalysisTableGroupFilter>;
};

/** A filter to be used against many `AnalysisTableRow` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyAnalysisTableRowFilter = {
  /** Every related `AnalysisTableRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AnalysisTableRowFilter>;
  /** No related `AnalysisTableRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AnalysisTableRowFilter>;
  /** Some related `AnalysisTableRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AnalysisTableRowFilter>;
};

/** A filter to be used against many `ApplicationUserMembership` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyApplicationUserMembershipFilter = {
  /** Every related `ApplicationUserMembership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ApplicationUserMembershipFilter>;
  /** No related `ApplicationUserMembership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ApplicationUserMembershipFilter>;
  /** Some related `ApplicationUserMembership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ApplicationUserMembershipFilter>;
};

/** A filter to be used against many `ApplicationUserSession` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyApplicationUserSessionFilter = {
  /** Every related `ApplicationUserSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ApplicationUserSessionFilter>;
  /** No related `ApplicationUserSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ApplicationUserSessionFilter>;
  /** Some related `ApplicationUserSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ApplicationUserSessionFilter>;
};

/** A filter to be used against many `Cost` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyCostFilter = {
  /** Every related `Cost` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<CostFilter>;
  /** No related `Cost` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<CostFilter>;
  /** Some related `Cost` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<CostFilter>;
};

/** A filter to be used against many `Dataset` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyDatasetFilter = {
  /** Every related `Dataset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetFilter>;
  /** No related `Dataset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetFilter>;
  /** Some related `Dataset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetFilter>;
};

/** A filter to be used against many `DatasetMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyDatasetMetadatumFilter = {
  /** Every related `DatasetMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetMetadatumFilter>;
  /** No related `DatasetMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetMetadatumFilter>;
  /** Some related `DatasetMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetMetadatumFilter>;
};

/** A filter to be used against many `DatasetRecordingsTableColumn` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyDatasetRecordingsTableColumnFilter = {
  /** Every related `DatasetRecordingsTableColumn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetRecordingsTableColumnFilter>;
  /** No related `DatasetRecordingsTableColumn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetRecordingsTableColumnFilter>;
  /** Some related `DatasetRecordingsTableColumn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetRecordingsTableColumnFilter>;
};

/** A filter to be used against many `DatasetRecordingsTableColumnOrder` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyDatasetRecordingsTableColumnOrderFilter = {
  /** Every related `DatasetRecordingsTableColumnOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetRecordingsTableColumnOrderFilter>;
  /** No related `DatasetRecordingsTableColumnOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetRecordingsTableColumnOrderFilter>;
  /** Some related `DatasetRecordingsTableColumnOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetRecordingsTableColumnOrderFilter>;
};

/** A filter to be used against many `DatasetRecordingsTable` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyDatasetRecordingsTableFilter = {
  /** Every related `DatasetRecordingsTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetRecordingsTableFilter>;
  /** No related `DatasetRecordingsTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetRecordingsTableFilter>;
  /** Some related `DatasetRecordingsTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetRecordingsTableFilter>;
};

/** A filter to be used against many `DatasetVersion` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyDatasetVersionFilter = {
  /** Every related `DatasetVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetVersionFilter>;
  /** No related `DatasetVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetVersionFilter>;
  /** Some related `DatasetVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetVersionFilter>;
};

/** A filter to be used against many `FileActivity` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyFileActivityFilter = {
  /** Every related `FileActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileActivityFilter>;
  /** No related `FileActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileActivityFilter>;
  /** Some related `FileActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileActivityFilter>;
};

/** A filter to be used against many `File` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyFileFilter = {
  /** Every related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileFilter>;
  /** No related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileFilter>;
  /** Some related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileFilter>;
};

/** A filter to be used against many `FileMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyFileMetadatumFilter = {
  /** Every related `FileMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileMetadatumFilter>;
  /** No related `FileMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileMetadatumFilter>;
  /** Some related `FileMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileMetadatumFilter>;
};

/** A filter to be used against many `FileOutputGroup` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyFileOutputGroupFilter = {
  /** Every related `FileOutputGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileOutputGroupFilter>;
  /** No related `FileOutputGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileOutputGroupFilter>;
  /** Some related `FileOutputGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileOutputGroupFilter>;
};

/** A filter to be used against many `FileOutputGroupMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyFileOutputGroupMetadatumFilter = {
  /** Every related `FileOutputGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileOutputGroupMetadatumFilter>;
  /** No related `FileOutputGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileOutputGroupMetadatumFilter>;
  /** Some related `FileOutputGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileOutputGroupMetadatumFilter>;
};

/** A filter to be used against many `FileRecordingGroup` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyFileRecordingGroupFilter = {
  /** Every related `FileRecordingGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileRecordingGroupFilter>;
  /** No related `FileRecordingGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileRecordingGroupFilter>;
  /** Some related `FileRecordingGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileRecordingGroupFilter>;
};

/** A filter to be used against many `FileRecordingGroupMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyFileRecordingGroupMetadatumFilter = {
  /** Every related `FileRecordingGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FileRecordingGroupMetadatumFilter>;
  /** No related `FileRecordingGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FileRecordingGroupMetadatumFilter>;
  /** Some related `FileRecordingGroupMetadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FileRecordingGroupMetadatumFilter>;
};

/** A filter to be used against many `MetadataValue` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyMetadataValueFilter = {
  /** Every related `MetadataValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MetadataValueFilter>;
  /** No related `MetadataValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MetadataValueFilter>;
  /** Some related `MetadataValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MetadataValueFilter>;
};

/** A filter to be used against many `Metadatum` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyMetadatumFilter = {
  /** Every related `Metadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MetadatumFilter>;
  /** No related `Metadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MetadatumFilter>;
  /** Some related `Metadatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MetadatumFilter>;
};

/** A filter to be used against many `Project` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyProjectFilter = {
  /** Every related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectFilter>;
  /** No related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectFilter>;
  /** Some related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectFilter>;
};

/** A filter to be used against many `RecordingGroupFile` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyRecordingGroupFileFilter = {
  /** Every related `RecordingGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<RecordingGroupFileFilter>;
  /** No related `RecordingGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<RecordingGroupFileFilter>;
  /** Some related `RecordingGroupFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<RecordingGroupFileFilter>;
};

/** A filter to be used against many `RoleChangeNotification` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyRoleChangeNotificationFilter = {
  /** Every related `RoleChangeNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<RoleChangeNotificationFilter>;
  /** No related `RoleChangeNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<RoleChangeNotificationFilter>;
  /** Some related `RoleChangeNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<RoleChangeNotificationFilter>;
};

/** A filter to be used against many `Run` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyRunFilter = {
  /** Every related `Run` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<RunFilter>;
  /** No related `Run` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<RunFilter>;
  /** Some related `Run` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<RunFilter>;
};

/** A filter to be used against many `Tag` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyTagFilter = {
  /** Every related `Tag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TagFilter>;
  /** No related `Tag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TagFilter>;
  /** Some related `Tag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TagFilter>;
};

/** A filter to be used against many `Task` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyTaskFilter = {
  /** Every related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TaskFilter>;
  /** No related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TaskFilter>;
  /** Some related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TaskFilter>;
};

/** A filter to be used against many `TenantContract` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyTenantContractFilter = {
  /** Every related `TenantContract` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TenantContractFilter>;
  /** No related `TenantContract` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TenantContractFilter>;
  /** Some related `TenantContract` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TenantContractFilter>;
};

/** A filter to be used against many `TenantMonthlyConsumedResource` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyTenantMonthlyConsumedResourceFilter = {
  /** Every related `TenantMonthlyConsumedResource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TenantMonthlyConsumedResourceFilter>;
  /** No related `TenantMonthlyConsumedResource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TenantMonthlyConsumedResourceFilter>;
  /** Some related `TenantMonthlyConsumedResource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TenantMonthlyConsumedResourceFilter>;
};

/** A filter to be used against many `TenantUserMembershipNotification` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyTenantUserMembershipNotificationFilter = {
  /** Every related `TenantUserMembershipNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TenantUserMembershipNotificationFilter>;
  /** No related `TenantUserMembershipNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TenantUserMembershipNotificationFilter>;
  /** Some related `TenantUserMembershipNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TenantUserMembershipNotificationFilter>;
};

/** A filter to be used against many `ToolTenant` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyToolTenantFilter = {
  /** Every related `ToolTenant` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ToolTenantFilter>;
  /** No related `ToolTenant` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ToolTenantFilter>;
  /** Some related `ToolTenant` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ToolTenantFilter>;
};

/** A filter to be used against many `ToolboxTenant` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyToolboxTenantFilter = {
  /** Every related `ToolboxTenant` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ToolboxTenantFilter>;
  /** No related `ToolboxTenant` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ToolboxTenantFilter>;
  /** Some related `ToolboxTenant` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ToolboxTenantFilter>;
};

/** A filter to be used against many `Workflow` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyWorkflowFilter = {
  /** Every related `Workflow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowFilter>;
  /** No related `Workflow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowFilter>;
  /** Some related `Workflow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowFilter>;
};

/** Input for the nested mutation of `notification` in the `TenantUserMembershipNotificationInput` mutation. */
export type TenantUserMembershId10Affa8DFkNotificatInput = {
  /** The primary key(s) for `notification` for the far side of the relationship. */
  connectById?: InputMaybe<NotificationNotificationPkeyConnect>;
  /** The primary key(s) and patch data for `notification` for the far side of the relationship. */
  updateById?: InputMaybe<NotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatUsingNotificationPkeyUpdate>;
};

/** Input for the nested mutation of `tenantUserMembershipNotification` in the `NotificationInput` mutation. */
export type TenantUserMembershId10Affa8DFkNotificatInverseInput = {
  /** The primary key(s) for `tenantUserMembershipNotification` for the far side of the relationship. */
  connectById?: InputMaybe<TenantUserMembershipNotificationTenantUserMembershipNotificationPkeyConnect>;
  /** The primary key(s) and patch data for `tenantUserMembershipNotification` for the far side of the relationship. */
  updateById?: InputMaybe<TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatUsingTenantUserMembershipNotificationPkeyUpdate>;
};

/** The `applicationUser` to be created by this mutation. */
export type TenantUserMembershInviterId29476E5EFkApplicatiApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `TenantUserMembershipNotificationInput` mutation. */
export type TenantUserMembershInviterId29476E5EFkApplicatiInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `tenantUserMembershipNotification` in the `ApplicationUserInput` mutation. */
export type TenantUserMembershInviterId29476E5EFkApplicatiInverseInput = {
  /** The primary key(s) for `tenantUserMembershipNotification` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<TenantUserMembershipNotificationTenantUserMembershipNotificationPkeyConnect>
  >;
  /** The primary key(s) and patch data for `tenantUserMembershipNotification` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingTenantUserMembershipNotificationPkeyUpdate>
  >;
};

/** Input for the nested mutation of `tenant` in the `TenantUserMembershipNotificationInput` mutation. */
export type TenantUserMembershTenantIdC67818C5FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `tenantUserMembershipNotification` in the `TenantInput` mutation. */
export type TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput = {
  /** The primary key(s) for `tenantUserMembershipNotification` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<TenantUserMembershipNotificationTenantUserMembershipNotificationPkeyConnect>
  >;
  /** The primary key(s) and patch data for `tenantUserMembershipNotification` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantUserMembershipNotificationPkeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type TenantUserMembershTenantIdC67818C5FkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** The `applicationUser` to be created by this mutation. */
export type TenantUserMembershUserId2B78E8A0FkApplicatiApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `TenantUserMembershipNotificationInput` mutation. */
export type TenantUserMembershUserId2B78E8A0FkApplicatiInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `tenantUserMembershipNotification` in the `ApplicationUserInput` mutation. */
export type TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput = {
  /** The primary key(s) for `tenantUserMembershipNotification` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<TenantUserMembershipNotificationTenantUserMembershipNotificationPkeyConnect>
  >;
  /** The primary key(s) and patch data for `tenantUserMembershipNotification` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingTenantUserMembershipNotificationPkeyUpdate>
  >;
};

export type TenantUserMembershipNotification = {
  __typename: "TenantUserMembershipNotification";
  /** Reads a single `ApplicationUser` that is related to this `TenantUserMembershipNotification`. */
  applicationUserByInviterId: Maybe<ApplicationUser>;
  /** Reads a single `ApplicationUser` that is related to this `TenantUserMembershipNotification`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  id: Scalars["UUID"]["output"];
  inviterId: Scalars["UUID"]["output"];
  /** Reads a single `Notification` that is related to this `TenantUserMembershipNotification`. */
  notificationById: Maybe<Notification>;
  /** Reads a single `Tenant` that is related to this `TenantUserMembershipNotification`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  userId: Scalars["UUID"]["output"];
};

/**
 * A condition to be used against `TenantUserMembershipNotification` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type TenantUserMembershipNotificationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `inviterId` field. */
  inviterId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `TenantUserMembershipNotification` object types. All fields are combined with a logical ‘and.’ */
export type TenantUserMembershipNotificationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TenantUserMembershipNotificationFilter>>;
  /** Filter by the object’s `applicationUserByInviterId` relation. */
  applicationUserByInviterId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `inviterId` field. */
  inviterId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TenantUserMembershipNotificationFilter>;
  /** Filter by the object’s `notificationById` relation. */
  notificationById?: InputMaybe<NotificationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TenantUserMembershipNotificationFilter>>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** The fields on `tenantUserMembershipNotification` to look up the row to update. */
export type TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatUsingTenantUserMembershipNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `tenantUserMembershipNotification` being updated. */
    tenantUserMembershipNotificationPatch: UpdateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatPatch;
  };

/** The fields on `tenantUserMembershipNotification` to look up the row to update. */
export type TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingTenantUserMembershipNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `tenantUserMembershipNotification` being updated. */
    tenantUserMembershipNotificationPatch: UpdateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiPatch;
  };

/** The fields on `tenantUserMembershipNotification` to look up the row to update. */
export type TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantUserMembershipNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `tenantUserMembershipNotification` being updated. */
    tenantUserMembershipNotificationPatch: UpdateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdPatch;
  };

/** The fields on `tenantUserMembershipNotification` to look up the row to update. */
export type TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingTenantUserMembershipNotificationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `tenantUserMembershipNotification` being updated. */
    tenantUserMembershipNotificationPatch: UpdateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiPatch;
  };

/** The fields on `tenantUserMembershipNotification` to look up the row to connect. */
export type TenantUserMembershipNotificationTenantUserMembershipNotificationPkeyConnect =
  {
    id: Scalars["UUID"]["input"];
  };

/** A connection to a list of `TenantUserMembershipNotification` values. */
export type TenantUserMembershipNotificationsConnection = {
  __typename: "TenantUserMembershipNotificationsConnection";
  /** A list of edges which contains the `TenantUserMembershipNotification` and cursor to aid in pagination. */
  edges: Array<TenantUserMembershipNotificationsEdge>;
  /** A list of `TenantUserMembershipNotification` objects. */
  nodes: Array<TenantUserMembershipNotification>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TenantUserMembershipNotification` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `TenantUserMembershipNotification` edge in the connection. */
export type TenantUserMembershipNotificationsEdge = {
  __typename: "TenantUserMembershipNotificationsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TenantUserMembershipNotification` at the end of the edge. */
  node: TenantUserMembershipNotification;
};

/** Methods to use when ordering `TenantUserMembershipNotification`. */
export enum TenantUserMembershipNotificationsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InviterIdAsc = "INVITER_ID_ASC",
  InviterIdDesc = "INVITER_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type TenantUserexecutor = {
  __typename: "TenantUserexecutor";
  /** Reads a single `AwsCognitoUser` that is related to this `TenantUserexecutor`. */
  awsCognitoUserByUserexecutorPtrId: Maybe<AwsCognitoUser>;
  /**
   * Reads and enables pagination through a set of `AwsCognitoUser`.
   * @deprecated Please use awsCognitoUserByUserexecutorPtrId instead
   */
  awsCognitoUsersByUserexecutorPtrId: AwsCognitoUsersConnection;
  /** Reads a single `DjangoContentType` that is related to this `TenantUserexecutor`. */
  djangoContentTypeByPolymorphicCtypeId: Maybe<DjangoContentType>;
  id: Scalars["Int"]["output"];
  polymorphicCtypeId: Maybe<Scalars["Int"]["output"]>;
  /** Reads a single `TenantMockuserexecutor` that is related to this `TenantUserexecutor`. */
  tenantMockuserexecutorByUserexecutorPtrId: Maybe<TenantMockuserexecutor>;
  /**
   * Reads and enables pagination through a set of `TenantMockuserexecutor`.
   * @deprecated Please use tenantMockuserexecutorByUserexecutorPtrId instead
   */
  tenantMockuserexecutorsByUserexecutorPtrId: TenantMockuserexecutorsConnection;
};

export type TenantUserexecutorAwsCognitoUsersByUserexecutorPtrIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsCognitoUserCondition>;
  filter?: InputMaybe<AwsCognitoUserFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsCognitoUsersOrderBy>>;
};

export type TenantUserexecutorTenantMockuserexecutorsByUserexecutorPtrIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TenantMockuserexecutorCondition>;
  filter?: InputMaybe<TenantMockuserexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TenantMockuserexecutorsOrderBy>>;
};

/**
 * A condition to be used against `TenantUserexecutor` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TenantUserexecutorCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `polymorphicCtypeId` field. */
  polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `TenantUserexecutor` object types. All fields are combined with a logical ‘and.’ */
export type TenantUserexecutorFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TenantUserexecutorFilter>>;
  /** Filter by the object’s `awsCognitoUserByUserexecutorPtrId` relation. */
  awsCognitoUserByUserexecutorPtrId?: InputMaybe<AwsCognitoUserFilter>;
  /** A related `awsCognitoUserByUserexecutorPtrId` exists. */
  awsCognitoUserByUserexecutorPtrIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `djangoContentTypeByPolymorphicCtypeId` relation. */
  djangoContentTypeByPolymorphicCtypeId?: InputMaybe<DjangoContentTypeFilter>;
  /** A related `djangoContentTypeByPolymorphicCtypeId` exists. */
  djangoContentTypeByPolymorphicCtypeIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TenantUserexecutorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TenantUserexecutorFilter>>;
  /** Filter by the object’s `polymorphicCtypeId` field. */
  polymorphicCtypeId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tenantMockuserexecutorByUserexecutorPtrId` relation. */
  tenantMockuserexecutorByUserexecutorPtrId?: InputMaybe<TenantMockuserexecutorFilter>;
  /** A related `tenantMockuserexecutorByUserexecutorPtrId` exists. */
  tenantMockuserexecutorByUserexecutorPtrIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
};

/** An input for mutations affecting `TenantUserexecutor` */
export type TenantUserexecutorInput = {
  applicationUserUsingId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInverseInput>;
  awsCognitoUserUsingId?: InputMaybe<AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInverseInput>;
  djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantMockuserexecutorUsingId?: InputMaybe<TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInverseInput>;
};

/** The fields on `tenantUserexecutor` to look up the row to update. */
export type TenantUserexecutorOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsUsingTenantUserexecutorPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenantUserexecutor` being updated. */
    tenantUserexecutorPatch: UpdateTenantUserexecutorOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsPatch;
  };

/** The fields on `tenantUserexecutor` to look up the row to update. */
export type TenantUserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsUsingTenantUserexecutorPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenantUserexecutor` being updated. */
    tenantUserexecutorPatch: UpdateTenantUserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsPatch;
  };

/** The fields on `tenantUserexecutor` to look up the row to update. */
export type TenantUserexecutorOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoUsingTenantUserexecutorPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tenantUserexecutor` being updated. */
    tenantUserexecutorPatch: UpdateTenantUserexecutorOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoPatch;
  };

/** Represents an update to a `TenantUserexecutor`. Fields that are set will be updated. */
export type TenantUserexecutorPatch = {
  applicationUserUsingId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInverseInput>;
  awsCognitoUserUsingId?: InputMaybe<AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInverseInput>;
  djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantMockuserexecutorUsingId?: InputMaybe<TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInverseInput>;
};

/** The `djangoContentType` to be created by this mutation. */
export type TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoDjangoContentTypeCreateInput =
  {
    appLabel: Scalars["String"]["input"];
    authPermissionsUsingId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput>;
    drsFileexecutorsUsingId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    model: Scalars["String"]["input"];
    tenantUserexecutorsUsingId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput>;
    tesContainerimageexecutorsUsingId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput>;
    tesTaskexecutorsUsingId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput>;
  };

/** Input for the nested mutation of `djangoContentType` in the `TenantUserexecutorInput` mutation. */
export type TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInput = {
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  connectByAppLabelAndModel?: InputMaybe<DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqConnect>;
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  connectById?: InputMaybe<DjangoContentTypeDjangoContentTypePkeyConnect>;
  /** A `DjangoContentTypeInput` object that will be created and connected to this object. */
  create?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoDjangoContentTypeCreateInput>;
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  deleteByAppLabelAndModel?: InputMaybe<DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqDelete>;
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  deleteById?: InputMaybe<DjangoContentTypeDjangoContentTypePkeyDelete>;
  /** The primary key(s) and patch data for `djangoContentType` for the far side of the relationship. */
  updateByAppLabelAndModel?: InputMaybe<DjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate>;
  /** The primary key(s) and patch data for `djangoContentType` for the far side of the relationship. */
  updateById?: InputMaybe<DjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoUsingDjangoContentTypePkeyUpdate>;
};

/** Input for the nested mutation of `tenantUserexecutor` in the `DjangoContentTypeInput` mutation. */
export type TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput =
  {
    /** The primary key(s) for `tenantUserexecutor` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<TenantUserexecutorTenantUserexecutorPkeyConnect>
    >;
    /** A `TenantUserexecutorInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoTenantUserexecutorCreateInput>
    >;
    /** The primary key(s) for `tenantUserexecutor` for the far side of the relationship. */
    deleteById?: InputMaybe<
      Array<TenantUserexecutorTenantUserexecutorPkeyDelete>
    >;
    /** Flag indicating whether all other `tenantUserexecutor` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `tenantUserexecutor` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<TenantUserexecutorOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoUsingTenantUserexecutorPkeyUpdate>
    >;
  };

/** The `tenantUserexecutor` to be created by this mutation. */
export type TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoTenantUserexecutorCreateInput =
  {
    applicationUserUsingId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInverseInput>;
    awsCognitoUserUsingId?: InputMaybe<AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    tenantMockuserexecutorUsingId?: InputMaybe<TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInverseInput>;
  };

/** The fields on `tenantUserexecutor` to look up the row to connect. */
export type TenantUserexecutorTenantUserexecutorPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `tenantUserexecutor` to look up the row to delete. */
export type TenantUserexecutorTenantUserexecutorPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** A connection to a list of `TenantUserexecutor` values. */
export type TenantUserexecutorsConnection = {
  __typename: "TenantUserexecutorsConnection";
  /** A list of edges which contains the `TenantUserexecutor` and cursor to aid in pagination. */
  edges: Array<TenantUserexecutorsEdge>;
  /** A list of `TenantUserexecutor` objects. */
  nodes: Array<TenantUserexecutor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TenantUserexecutor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `TenantUserexecutor` edge in the connection. */
export type TenantUserexecutorsEdge = {
  __typename: "TenantUserexecutorsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TenantUserexecutor` at the end of the edge. */
  node: TenantUserexecutor;
};

/** Methods to use when ordering `TenantUserexecutor`. */
export enum TenantUserexecutorsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PolymorphicCtypeIdAsc = "POLYMORPHIC_CTYPE_ID_ASC",
  PolymorphicCtypeIdDesc = "POLYMORPHIC_CTYPE_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

/** A `Tenant` edge in the connection. */
export type TenantsEdge = {
  __typename: "TenantsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Tenant` at the end of the edge. */
  node: Tenant;
};

/** Methods to use when ordering `Tenant`. */
export enum TenantsOrderBy {
  CreatedAsc = "CREATED_ASC",
  CreatedDesc = "CREATED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InternalAsc = "INTERNAL_ASC",
  InternalDesc = "INTERNAL_DESC",
  KeyAsc = "KEY_ASC",
  KeyDesc = "KEY_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TenantTypeAsc = "TENANT_TYPE_ASC",
  TenantTypeDesc = "TENANT_TYPE_DESC",
}

/** The `djangoContentType` to be created by this mutation. */
export type TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoDjangoContentTypeCreateInput =
  {
    appLabel: Scalars["String"]["input"];
    authPermissionsUsingId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput>;
    drsFileexecutorsUsingId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    model: Scalars["String"]["input"];
    tenantUserexecutorsUsingId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput>;
    tesContainerimageexecutorsUsingId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput>;
    tesTaskexecutorsUsingId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput>;
  };

/** Input for the nested mutation of `djangoContentType` in the `TesContainerimageexecutorInput` mutation. */
export type TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInput = {
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  connectByAppLabelAndModel?: InputMaybe<DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqConnect>;
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  connectById?: InputMaybe<DjangoContentTypeDjangoContentTypePkeyConnect>;
  /** A `DjangoContentTypeInput` object that will be created and connected to this object. */
  create?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoDjangoContentTypeCreateInput>;
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  deleteByAppLabelAndModel?: InputMaybe<DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqDelete>;
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  deleteById?: InputMaybe<DjangoContentTypeDjangoContentTypePkeyDelete>;
  /** The primary key(s) and patch data for `djangoContentType` for the far side of the relationship. */
  updateByAppLabelAndModel?: InputMaybe<DjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate>;
  /** The primary key(s) and patch data for `djangoContentType` for the far side of the relationship. */
  updateById?: InputMaybe<DjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoUsingDjangoContentTypePkeyUpdate>;
};

/** Input for the nested mutation of `tesContainerimageexecutor` in the `DjangoContentTypeInput` mutation. */
export type TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput =
  {
    /** The primary key(s) for `tesContainerimageexecutor` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<TesContainerimageexecutorTesContainerimageexecutorPkeyConnect>
    >;
    /** A `TesContainerimageexecutorInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoTesContainerimageexecutorCreateInput>
    >;
    /** The primary key(s) for `tesContainerimageexecutor` for the far side of the relationship. */
    deleteById?: InputMaybe<
      Array<TesContainerimageexecutorTesContainerimageexecutorPkeyDelete>
    >;
    /** Flag indicating whether all other `tesContainerimageexecutor` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `tesContainerimageexecutor` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<TesContainerimageexecutorOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoUsingTesContainerimageexecutorPkeyUpdate>
    >;
  };

/** The `tesContainerimageexecutor` to be created by this mutation. */
export type TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoTesContainerimageexecutorCreateInput =
  {
    awsEcrImageUsingId?: InputMaybe<AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInverseInput>;
    containerImageUsingId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    tesMockcontainerimageexecutorUsingId?: InputMaybe<TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInverseInput>;
  };

export type TesContainerimageexecutor = {
  __typename: "TesContainerimageexecutor";
  /** Reads a single `AwsEcrImage` that is related to this `TesContainerimageexecutor`. */
  awsEcrImageByContainerimageexecutorPtrId: Maybe<AwsEcrImage>;
  /**
   * Reads and enables pagination through a set of `AwsEcrImage`.
   * @deprecated Please use awsEcrImageByContainerimageexecutorPtrId instead
   */
  awsEcrImagesByContainerimageexecutorPtrId: AwsEcrImagesConnection;
  /** Reads a single `ContainerImage` that is related to this `TesContainerimageexecutor`. */
  containerImageByExecutorId: Maybe<ContainerImage>;
  /**
   * Reads and enables pagination through a set of `ContainerImage`.
   * @deprecated Please use containerImageByExecutorId instead
   */
  containerImagesByExecutorId: ContainerImagesConnection;
  /** Reads a single `DjangoContentType` that is related to this `TesContainerimageexecutor`. */
  djangoContentTypeByPolymorphicCtypeId: Maybe<DjangoContentType>;
  id: Scalars["Int"]["output"];
  polymorphicCtypeId: Maybe<Scalars["Int"]["output"]>;
  /** Reads a single `TesMockcontainerimageexecutor` that is related to this `TesContainerimageexecutor`. */
  tesMockcontainerimageexecutorByContainerimageexecutorPtrId: Maybe<TesMockcontainerimageexecutor>;
  /**
   * Reads and enables pagination through a set of `TesMockcontainerimageexecutor`.
   * @deprecated Please use tesMockcontainerimageexecutorByContainerimageexecutorPtrId instead
   */
  tesMockcontainerimageexecutorsByContainerimageexecutorPtrId: TesMockcontainerimageexecutorsConnection;
};

export type TesContainerimageexecutorAwsEcrImagesByContainerimageexecutorPtrIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<AwsEcrImageCondition>;
    filter?: InputMaybe<AwsEcrImageFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<AwsEcrImagesOrderBy>>;
  };

export type TesContainerimageexecutorContainerImagesByExecutorIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ContainerImageCondition>;
  filter?: InputMaybe<ContainerImageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ContainerImagesOrderBy>>;
};

export type TesContainerimageexecutorTesMockcontainerimageexecutorsByContainerimageexecutorPtrIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<TesMockcontainerimageexecutorCondition>;
    filter?: InputMaybe<TesMockcontainerimageexecutorFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<TesMockcontainerimageexecutorsOrderBy>>;
  };

/**
 * A condition to be used against `TesContainerimageexecutor` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type TesContainerimageexecutorCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `polymorphicCtypeId` field. */
  polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `TesContainerimageexecutor` object types. All fields are combined with a logical ‘and.’ */
export type TesContainerimageexecutorFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TesContainerimageexecutorFilter>>;
  /** Filter by the object’s `awsEcrImageByContainerimageexecutorPtrId` relation. */
  awsEcrImageByContainerimageexecutorPtrId?: InputMaybe<AwsEcrImageFilter>;
  /** A related `awsEcrImageByContainerimageexecutorPtrId` exists. */
  awsEcrImageByContainerimageexecutorPtrIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `containerImageByExecutorId` relation. */
  containerImageByExecutorId?: InputMaybe<ContainerImageFilter>;
  /** A related `containerImageByExecutorId` exists. */
  containerImageByExecutorIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `djangoContentTypeByPolymorphicCtypeId` relation. */
  djangoContentTypeByPolymorphicCtypeId?: InputMaybe<DjangoContentTypeFilter>;
  /** A related `djangoContentTypeByPolymorphicCtypeId` exists. */
  djangoContentTypeByPolymorphicCtypeIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TesContainerimageexecutorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TesContainerimageexecutorFilter>>;
  /** Filter by the object’s `polymorphicCtypeId` field. */
  polymorphicCtypeId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tesMockcontainerimageexecutorByContainerimageexecutorPtrId` relation. */
  tesMockcontainerimageexecutorByContainerimageexecutorPtrId?: InputMaybe<TesMockcontainerimageexecutorFilter>;
  /** A related `tesMockcontainerimageexecutorByContainerimageexecutorPtrId` exists. */
  tesMockcontainerimageexecutorByContainerimageexecutorPtrIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
};

/** An input for mutations affecting `TesContainerimageexecutor` */
export type TesContainerimageexecutorInput = {
  awsEcrImageUsingId?: InputMaybe<AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInverseInput>;
  containerImageUsingId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInverseInput>;
  djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
  tesMockcontainerimageexecutorUsingId?: InputMaybe<TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInverseInput>;
};

/** The fields on `tesContainerimageexecutor` to look up the row to update. */
export type TesContainerimageexecutorOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaUsingTesContainerimageexecutorPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tesContainerimageexecutor` being updated. */
    tesContainerimageexecutorPatch: UpdateTesContainerimageexecutorOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaPatch;
  };

/** The fields on `tesContainerimageexecutor` to look up the row to update. */
export type TesContainerimageexecutorOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingTesContainerimageexecutorPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tesContainerimageexecutor` being updated. */
    tesContainerimageexecutorPatch: UpdateTesContainerimageexecutorOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaPatch;
  };

/** The fields on `tesContainerimageexecutor` to look up the row to update. */
export type TesContainerimageexecutorOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoUsingTesContainerimageexecutorPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tesContainerimageexecutor` being updated. */
    tesContainerimageexecutorPatch: UpdateTesContainerimageexecutorOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoPatch;
  };

/** The fields on `tesContainerimageexecutor` to look up the row to update. */
export type TesContainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaUsingTesContainerimageexecutorPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tesContainerimageexecutor` being updated. */
    tesContainerimageexecutorPatch: UpdateTesContainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaPatch;
  };

/** Represents an update to a `TesContainerimageexecutor`. Fields that are set will be updated. */
export type TesContainerimageexecutorPatch = {
  awsEcrImageUsingId?: InputMaybe<AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInverseInput>;
  containerImageUsingId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInverseInput>;
  djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
  tesMockcontainerimageexecutorUsingId?: InputMaybe<TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInverseInput>;
};

/** The fields on `tesContainerimageexecutor` to look up the row to connect. */
export type TesContainerimageexecutorTesContainerimageexecutorPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `tesContainerimageexecutor` to look up the row to delete. */
export type TesContainerimageexecutorTesContainerimageexecutorPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** A connection to a list of `TesContainerimageexecutor` values. */
export type TesContainerimageexecutorsConnection = {
  __typename: "TesContainerimageexecutorsConnection";
  /** A list of edges which contains the `TesContainerimageexecutor` and cursor to aid in pagination. */
  edges: Array<TesContainerimageexecutorsEdge>;
  /** A list of `TesContainerimageexecutor` objects. */
  nodes: Array<TesContainerimageexecutor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TesContainerimageexecutor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `TesContainerimageexecutor` edge in the connection. */
export type TesContainerimageexecutorsEdge = {
  __typename: "TesContainerimageexecutorsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TesContainerimageexecutor` at the end of the edge. */
  node: TesContainerimageexecutor;
};

/** Methods to use when ordering `TesContainerimageexecutor`. */
export enum TesContainerimageexecutorsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PolymorphicCtypeIdAsc = "POLYMORPHIC_CTYPE_ID_ASC",
  PolymorphicCtypeIdDesc = "POLYMORPHIC_CTYPE_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

/** Input for the nested mutation of `tesContainerimageexecutor` in the `TesMockcontainerimageexecutorInput` mutation. */
export type TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInput = {
  /** The primary key(s) for `tesContainerimageexecutor` for the far side of the relationship. */
  connectById?: InputMaybe<TesContainerimageexecutorTesContainerimageexecutorPkeyConnect>;
  /** A `TesContainerimageexecutorInput` object that will be created and connected to this object. */
  create?: InputMaybe<TesMockcontainerimaContainerimageexecut4Eced924FkTesContaTesContainerimageexecutorCreateInput>;
  /** The primary key(s) for `tesContainerimageexecutor` for the far side of the relationship. */
  deleteById?: InputMaybe<TesContainerimageexecutorTesContainerimageexecutorPkeyDelete>;
  /** The primary key(s) and patch data for `tesContainerimageexecutor` for the far side of the relationship. */
  updateById?: InputMaybe<TesContainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaUsingTesContainerimageexecutorPkeyUpdate>;
};

/** Input for the nested mutation of `tesMockcontainerimageexecutor` in the `TesContainerimageexecutorInput` mutation. */
export type TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInverseInput =
  {
    /** The primary key(s) for `tesMockcontainerimageexecutor` for the far side of the relationship. */
    connectByContainerimageexecutorPtrId?: InputMaybe<TesMockcontainerimageexecutorTesMockcontainerimageexecutorPkeyConnect>;
    /** A `TesMockcontainerimageexecutorInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<TesMockcontainerimaContainerimageexecut4Eced924FkTesContaTesMockcontainerimageexecutorCreateInput>
    >;
    /** The primary key(s) for `tesMockcontainerimageexecutor` for the far side of the relationship. */
    deleteByContainerimageexecutorPtrId?: InputMaybe<TesMockcontainerimageexecutorTesMockcontainerimageexecutorPkeyDelete>;
    /** Flag indicating whether all other `tesMockcontainerimageexecutor` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `tesMockcontainerimageexecutor` for the far side of the relationship. */
    updateByContainerimageexecutorPtrId?: InputMaybe<TesMockcontainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaUsingTesMockcontainerimageexecutorPkeyUpdate>;
  };

/** The `tesContainerimageexecutor` to be created by this mutation. */
export type TesMockcontainerimaContainerimageexecut4Eced924FkTesContaTesContainerimageexecutorCreateInput =
  {
    awsEcrImageUsingId?: InputMaybe<AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInverseInput>;
    containerImageUsingId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
    tesMockcontainerimageexecutorUsingId?: InputMaybe<TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInverseInput>;
  };

/** The `tesMockcontainerimageexecutor` to be created by this mutation. */
export type TesMockcontainerimaContainerimageexecut4Eced924FkTesContaTesMockcontainerimageexecutorCreateInput =
  {
    tesContainerimageexecutorToContainerimageexecutorPtrId?: InputMaybe<TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInput>;
  };

export type TesMockcontainerimageexecutor = {
  __typename: "TesMockcontainerimageexecutor";
  containerimageexecutorPtrId: Scalars["Int"]["output"];
  /** Reads a single `TesContainerimageexecutor` that is related to this `TesMockcontainerimageexecutor`. */
  tesContainerimageexecutorByContainerimageexecutorPtrId: Maybe<TesContainerimageexecutor>;
};

/**
 * A condition to be used against `TesMockcontainerimageexecutor` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type TesMockcontainerimageexecutorCondition = {
  /** Checks for equality with the object’s `containerimageexecutorPtrId` field. */
  containerimageexecutorPtrId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `TesMockcontainerimageexecutor` object types. All fields are combined with a logical ‘and.’ */
export type TesMockcontainerimageexecutorFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TesMockcontainerimageexecutorFilter>>;
  /** Filter by the object’s `containerimageexecutorPtrId` field. */
  containerimageexecutorPtrId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TesMockcontainerimageexecutorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TesMockcontainerimageexecutorFilter>>;
  /** Filter by the object’s `tesContainerimageexecutorByContainerimageexecutorPtrId` relation. */
  tesContainerimageexecutorByContainerimageexecutorPtrId?: InputMaybe<TesContainerimageexecutorFilter>;
};

/** An input for mutations affecting `TesMockcontainerimageexecutor` */
export type TesMockcontainerimageexecutorInput = {
  containerimageexecutorPtrId?: InputMaybe<Scalars["Int"]["input"]>;
  tesContainerimageexecutorToContainerimageexecutorPtrId?: InputMaybe<TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInput>;
};

/** The fields on `tesMockcontainerimageexecutor` to look up the row to update. */
export type TesMockcontainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaUsingTesMockcontainerimageexecutorPkeyUpdate =
  {
    containerimageexecutorPtrId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tesMockcontainerimageexecutor` being updated. */
    tesMockcontainerimageexecutorPatch: UpdateTesMockcontainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaPatch;
  };

/** Represents an update to a `TesMockcontainerimageexecutor`. Fields that are set will be updated. */
export type TesMockcontainerimageexecutorPatch = {
  containerimageexecutorPtrId?: InputMaybe<Scalars["Int"]["input"]>;
  tesContainerimageexecutorToContainerimageexecutorPtrId?: InputMaybe<TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInput>;
};

/** The fields on `tesMockcontainerimageexecutor` to look up the row to connect. */
export type TesMockcontainerimageexecutorTesMockcontainerimageexecutorPkeyConnect =
  {
    containerimageexecutorPtrId: Scalars["Int"]["input"];
  };

/** The fields on `tesMockcontainerimageexecutor` to look up the row to delete. */
export type TesMockcontainerimageexecutorTesMockcontainerimageexecutorPkeyDelete =
  {
    containerimageexecutorPtrId: Scalars["Int"]["input"];
  };

/** A connection to a list of `TesMockcontainerimageexecutor` values. */
export type TesMockcontainerimageexecutorsConnection = {
  __typename: "TesMockcontainerimageexecutorsConnection";
  /** A list of edges which contains the `TesMockcontainerimageexecutor` and cursor to aid in pagination. */
  edges: Array<TesMockcontainerimageexecutorsEdge>;
  /** A list of `TesMockcontainerimageexecutor` objects. */
  nodes: Array<TesMockcontainerimageexecutor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TesMockcontainerimageexecutor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `TesMockcontainerimageexecutor` edge in the connection. */
export type TesMockcontainerimageexecutorsEdge = {
  __typename: "TesMockcontainerimageexecutorsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TesMockcontainerimageexecutor` at the end of the edge. */
  node: TesMockcontainerimageexecutor;
};

/** Methods to use when ordering `TesMockcontainerimageexecutor`. */
export enum TesMockcontainerimageexecutorsOrderBy {
  ContainerimageexecutorPtrIdAsc = "CONTAINERIMAGEEXECUTOR_PTR_ID_ASC",
  ContainerimageexecutorPtrIdDesc = "CONTAINERIMAGEEXECUTOR_PTR_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type TesMocktaskexecutor = {
  __typename: "TesMocktaskexecutor";
  taskexecutorPtrId: Scalars["UUID"]["output"];
  /** Reads a single `TesTaskexecutor` that is related to this `TesMocktaskexecutor`. */
  tesTaskexecutorByTaskexecutorPtrId: Maybe<TesTaskexecutor>;
};

/**
 * A condition to be used against `TesMocktaskexecutor` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type TesMocktaskexecutorCondition = {
  /** Checks for equality with the object’s `taskexecutorPtrId` field. */
  taskexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `TesMocktaskexecutor` object types. All fields are combined with a logical ‘and.’ */
export type TesMocktaskexecutorFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TesMocktaskexecutorFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<TesMocktaskexecutorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TesMocktaskexecutorFilter>>;
  /** Filter by the object’s `taskexecutorPtrId` field. */
  taskexecutorPtrId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tesTaskexecutorByTaskexecutorPtrId` relation. */
  tesTaskexecutorByTaskexecutorPtrId?: InputMaybe<TesTaskexecutorFilter>;
};

/** An input for mutations affecting `TesMocktaskexecutor` */
export type TesMocktaskexecutorInput = {
  taskexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
  tesTaskexecutorToTaskexecutorPtrId?: InputMaybe<TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInput>;
};

/** The fields on `tesMocktaskexecutor` to look up the row to update. */
export type TesMocktaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeUsingTesMocktaskexecutorPkeyUpdate =
  {
    taskexecutorPtrId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `tesMocktaskexecutor` being updated. */
    tesMocktaskexecutorPatch: UpdateTesMocktaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskePatch;
  };

/** Represents an update to a `TesMocktaskexecutor`. Fields that are set will be updated. */
export type TesMocktaskexecutorPatch = {
  taskexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
  tesTaskexecutorToTaskexecutorPtrId?: InputMaybe<TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInput>;
};

/** Input for the nested mutation of `tesTaskexecutor` in the `TesMocktaskexecutorInput` mutation. */
export type TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInput = {
  /** The primary key(s) for `tesTaskexecutor` for the far side of the relationship. */
  connectById?: InputMaybe<TesTaskexecutorTesTaskexecutorPkeyConnect>;
  /** A `TesTaskexecutorInput` object that will be created and connected to this object. */
  create?: InputMaybe<TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeTesTaskexecutorCreateInput>;
  /** The primary key(s) for `tesTaskexecutor` for the far side of the relationship. */
  deleteById?: InputMaybe<TesTaskexecutorTesTaskexecutorPkeyDelete>;
  /** The primary key(s) and patch data for `tesTaskexecutor` for the far side of the relationship. */
  updateById?: InputMaybe<TesTaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeUsingTesTaskexecutorPkeyUpdate>;
};

/** Input for the nested mutation of `tesMocktaskexecutor` in the `TesTaskexecutorInput` mutation. */
export type TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInverseInput =
  {
    /** The primary key(s) for `tesMocktaskexecutor` for the far side of the relationship. */
    connectByTaskexecutorPtrId?: InputMaybe<TesMocktaskexecutorTesMocktaskexecutorPkeyConnect>;
    /** A `TesMocktaskexecutorInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeTesMocktaskexecutorCreateInput>
    >;
    /** The primary key(s) for `tesMocktaskexecutor` for the far side of the relationship. */
    deleteByTaskexecutorPtrId?: InputMaybe<TesMocktaskexecutorTesMocktaskexecutorPkeyDelete>;
    /** Flag indicating whether all other `tesMocktaskexecutor` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `tesMocktaskexecutor` for the far side of the relationship. */
    updateByTaskexecutorPtrId?: InputMaybe<TesMocktaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeUsingTesMocktaskexecutorPkeyUpdate>;
  };

/** The `tesMocktaskexecutor` to be created by this mutation. */
export type TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeTesMocktaskexecutorCreateInput =
  {
    tesTaskexecutorToTaskexecutorPtrId?: InputMaybe<TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInput>;
  };

/** The `tesTaskexecutor` to be created by this mutation. */
export type TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeTesTaskexecutorCreateInput =
  {
    awsBatchTaskUsingId?: InputMaybe<AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInput>;
    id: Scalars["UUID"]["input"];
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
    taskUsingId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInverseInput>;
    tesMocktaskexecutorUsingId?: InputMaybe<TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInverseInput>;
  };

/** The fields on `tesMocktaskexecutor` to look up the row to connect. */
export type TesMocktaskexecutorTesMocktaskexecutorPkeyConnect = {
  taskexecutorPtrId: Scalars["UUID"]["input"];
};

/** The fields on `tesMocktaskexecutor` to look up the row to delete. */
export type TesMocktaskexecutorTesMocktaskexecutorPkeyDelete = {
  taskexecutorPtrId: Scalars["UUID"]["input"];
};

/** A connection to a list of `TesMocktaskexecutor` values. */
export type TesMocktaskexecutorsConnection = {
  __typename: "TesMocktaskexecutorsConnection";
  /** A list of edges which contains the `TesMocktaskexecutor` and cursor to aid in pagination. */
  edges: Array<TesMocktaskexecutorsEdge>;
  /** A list of `TesMocktaskexecutor` objects. */
  nodes: Array<TesMocktaskexecutor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TesMocktaskexecutor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `TesMocktaskexecutor` edge in the connection. */
export type TesMocktaskexecutorsEdge = {
  __typename: "TesMocktaskexecutorsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TesMocktaskexecutor` at the end of the edge. */
  node: TesMocktaskexecutor;
};

/** Methods to use when ordering `TesMocktaskexecutor`. */
export enum TesMocktaskexecutorsOrderBy {
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TaskexecutorPtrIdAsc = "TASKEXECUTOR_PTR_ID_ASC",
  TaskexecutorPtrIdDesc = "TASKEXECUTOR_PTR_ID_DESC",
}

export type TesTaskexecutor = {
  __typename: "TesTaskexecutor";
  /** Reads a single `AwsBatchTask` that is related to this `TesTaskexecutor`. */
  awsBatchTaskByTaskexecutorPtrId: Maybe<AwsBatchTask>;
  /**
   * Reads and enables pagination through a set of `AwsBatchTask`.
   * @deprecated Please use awsBatchTaskByTaskexecutorPtrId instead
   */
  awsBatchTasksByTaskexecutorPtrId: AwsBatchTasksConnection;
  /** Reads a single `DjangoContentType` that is related to this `TesTaskexecutor`. */
  djangoContentTypeByPolymorphicCtypeId: Maybe<DjangoContentType>;
  id: Scalars["UUID"]["output"];
  polymorphicCtypeId: Maybe<Scalars["Int"]["output"]>;
  /** Reads a single `Task` that is related to this `TesTaskexecutor`. */
  taskByExecutorId: Maybe<Task>;
  /**
   * Reads and enables pagination through a set of `Task`.
   * @deprecated Please use taskByExecutorId instead
   */
  tasksByExecutorId: TasksConnection;
  /** Reads a single `TesMocktaskexecutor` that is related to this `TesTaskexecutor`. */
  tesMocktaskexecutorByTaskexecutorPtrId: Maybe<TesMocktaskexecutor>;
  /**
   * Reads and enables pagination through a set of `TesMocktaskexecutor`.
   * @deprecated Please use tesMocktaskexecutorByTaskexecutorPtrId instead
   */
  tesMocktaskexecutorsByTaskexecutorPtrId: TesMocktaskexecutorsConnection;
};

export type TesTaskexecutorAwsBatchTasksByTaskexecutorPtrIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AwsBatchTaskCondition>;
  filter?: InputMaybe<AwsBatchTaskFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AwsBatchTasksOrderBy>>;
};

export type TesTaskexecutorTasksByExecutorIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskCondition>;
  filter?: InputMaybe<TaskFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
};

export type TesTaskexecutorTesMocktaskexecutorsByTaskexecutorPtrIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TesMocktaskexecutorCondition>;
  filter?: InputMaybe<TesMocktaskexecutorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TesMocktaskexecutorsOrderBy>>;
};

/**
 * A condition to be used against `TesTaskexecutor` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TesTaskexecutorCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `polymorphicCtypeId` field. */
  polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `TesTaskexecutor` object types. All fields are combined with a logical ‘and.’ */
export type TesTaskexecutorFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TesTaskexecutorFilter>>;
  /** Filter by the object’s `awsBatchTaskByTaskexecutorPtrId` relation. */
  awsBatchTaskByTaskexecutorPtrId?: InputMaybe<AwsBatchTaskFilter>;
  /** A related `awsBatchTaskByTaskexecutorPtrId` exists. */
  awsBatchTaskByTaskexecutorPtrIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `djangoContentTypeByPolymorphicCtypeId` relation. */
  djangoContentTypeByPolymorphicCtypeId?: InputMaybe<DjangoContentTypeFilter>;
  /** A related `djangoContentTypeByPolymorphicCtypeId` exists. */
  djangoContentTypeByPolymorphicCtypeIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TesTaskexecutorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TesTaskexecutorFilter>>;
  /** Filter by the object’s `polymorphicCtypeId` field. */
  polymorphicCtypeId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `taskByExecutorId` relation. */
  taskByExecutorId?: InputMaybe<TaskFilter>;
  /** A related `taskByExecutorId` exists. */
  taskByExecutorIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tesMocktaskexecutorByTaskexecutorPtrId` relation. */
  tesMocktaskexecutorByTaskexecutorPtrId?: InputMaybe<TesMocktaskexecutorFilter>;
  /** A related `tesMocktaskexecutorByTaskexecutorPtrId` exists. */
  tesMocktaskexecutorByTaskexecutorPtrIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
};

/** An input for mutations affecting `TesTaskexecutor` */
export type TesTaskexecutorInput = {
  awsBatchTaskUsingId?: InputMaybe<AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInverseInput>;
  djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInput>;
  id: Scalars["UUID"]["input"];
  polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
  taskUsingId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInverseInput>;
  tesMocktaskexecutorUsingId?: InputMaybe<TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInverseInput>;
};

/** The fields on `tesTaskexecutor` to look up the row to update. */
export type TesTaskexecutorOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeUsingTesTaskexecutorPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `tesTaskexecutor` being updated. */
    tesTaskexecutorPatch: UpdateTesTaskexecutorOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskePatch;
  };

/** The fields on `tesTaskexecutor` to look up the row to update. */
export type TesTaskexecutorOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdUsingTesTaskexecutorPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `tesTaskexecutor` being updated. */
    tesTaskexecutorPatch: UpdateTesTaskexecutorOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdPatch;
  };

/** The fields on `tesTaskexecutor` to look up the row to update. */
export type TesTaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeUsingTesTaskexecutorPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `tesTaskexecutor` being updated. */
    tesTaskexecutorPatch: UpdateTesTaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskePatch;
  };

/** The fields on `tesTaskexecutor` to look up the row to update. */
export type TesTaskexecutorOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoUsingTesTaskexecutorPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `tesTaskexecutor` being updated. */
    tesTaskexecutorPatch: UpdateTesTaskexecutorOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoPatch;
  };

/** Represents an update to a `TesTaskexecutor`. Fields that are set will be updated. */
export type TesTaskexecutorPatch = {
  awsBatchTaskUsingId?: InputMaybe<AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInverseInput>;
  djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
  taskUsingId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInverseInput>;
  tesMocktaskexecutorUsingId?: InputMaybe<TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInverseInput>;
};

/** The `djangoContentType` to be created by this mutation. */
export type TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoDjangoContentTypeCreateInput =
  {
    appLabel: Scalars["String"]["input"];
    authPermissionsUsingId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput>;
    drsFileexecutorsUsingId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    model: Scalars["String"]["input"];
    tenantUserexecutorsUsingId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput>;
    tesContainerimageexecutorsUsingId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput>;
    tesTaskexecutorsUsingId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput>;
  };

/** Input for the nested mutation of `djangoContentType` in the `TesTaskexecutorInput` mutation. */
export type TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInput = {
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  connectByAppLabelAndModel?: InputMaybe<DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqConnect>;
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  connectById?: InputMaybe<DjangoContentTypeDjangoContentTypePkeyConnect>;
  /** A `DjangoContentTypeInput` object that will be created and connected to this object. */
  create?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoDjangoContentTypeCreateInput>;
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  deleteByAppLabelAndModel?: InputMaybe<DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqDelete>;
  /** The primary key(s) for `djangoContentType` for the far side of the relationship. */
  deleteById?: InputMaybe<DjangoContentTypeDjangoContentTypePkeyDelete>;
  /** The primary key(s) and patch data for `djangoContentType` for the far side of the relationship. */
  updateByAppLabelAndModel?: InputMaybe<DjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate>;
  /** The primary key(s) and patch data for `djangoContentType` for the far side of the relationship. */
  updateById?: InputMaybe<DjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoUsingDjangoContentTypePkeyUpdate>;
};

/** Input for the nested mutation of `tesTaskexecutor` in the `DjangoContentTypeInput` mutation. */
export type TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput = {
  /** The primary key(s) for `tesTaskexecutor` for the far side of the relationship. */
  connectById?: InputMaybe<Array<TesTaskexecutorTesTaskexecutorPkeyConnect>>;
  /** A `TesTaskexecutorInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoTesTaskexecutorCreateInput>
  >;
  /** The primary key(s) for `tesTaskexecutor` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<TesTaskexecutorTesTaskexecutorPkeyDelete>>;
  /** Flag indicating whether all other `tesTaskexecutor` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `tesTaskexecutor` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<TesTaskexecutorOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoUsingTesTaskexecutorPkeyUpdate>
  >;
};

/** The `tesTaskexecutor` to be created by this mutation. */
export type TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoTesTaskexecutorCreateInput =
  {
    awsBatchTaskUsingId?: InputMaybe<AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInput>;
    id: Scalars["UUID"]["input"];
    taskUsingId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInverseInput>;
    tesMocktaskexecutorUsingId?: InputMaybe<TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInverseInput>;
  };

/** The fields on `tesTaskexecutor` to look up the row to connect. */
export type TesTaskexecutorTesTaskexecutorPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `tesTaskexecutor` to look up the row to delete. */
export type TesTaskexecutorTesTaskexecutorPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** A connection to a list of `TesTaskexecutor` values. */
export type TesTaskexecutorsConnection = {
  __typename: "TesTaskexecutorsConnection";
  /** A list of edges which contains the `TesTaskexecutor` and cursor to aid in pagination. */
  edges: Array<TesTaskexecutorsEdge>;
  /** A list of `TesTaskexecutor` objects. */
  nodes: Array<TesTaskexecutor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TesTaskexecutor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `TesTaskexecutor` edge in the connection. */
export type TesTaskexecutorsEdge = {
  __typename: "TesTaskexecutorsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TesTaskexecutor` at the end of the edge. */
  node: TesTaskexecutor;
};

/** Methods to use when ordering `TesTaskexecutor`. */
export enum TesTaskexecutorsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PolymorphicCtypeIdAsc = "POLYMORPHIC_CTYPE_ID_ASC",
  PolymorphicCtypeIdDesc = "POLYMORPHIC_CTYPE_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type Tool = {
  __typename: "Tool";
  /** Reads and enables pagination through a set of `AnalysisTableGroup`. */
  analysisTableGroupsByToolId: AnalysisTableGroupsConnection;
  created: Scalars["Datetime"]["output"];
  helpText: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  searchTerms: Maybe<Scalars["JSON"]["output"]>;
  /** Reads and enables pagination through a set of `ToolDisplayCategory`. */
  toolDisplayCategoriesByToolId: ToolDisplayCategoriesConnection;
  /** Reads and enables pagination through a set of `ToolTenant`. */
  toolTenantsByToolId: ToolTenantsConnection;
  /** Reads and enables pagination through a set of `ToolVersion`. */
  toolVersionsByToolId: ToolVersionsConnection;
};

export type ToolAnalysisTableGroupsByToolIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableGroupCondition>;
  filter?: InputMaybe<AnalysisTableGroupFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTableGroupsOrderBy>>;
};

export type ToolToolDisplayCategoriesByToolIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolDisplayCategoryCondition>;
  filter?: InputMaybe<ToolDisplayCategoryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolDisplayCategoriesOrderBy>>;
};

export type ToolToolTenantsByToolIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolTenantCondition>;
  filter?: InputMaybe<ToolTenantFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolTenantsOrderBy>>;
};

export type ToolToolVersionsByToolIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolVersionCondition>;
  filter?: InputMaybe<ToolVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolVersionsOrderBy>>;
};

/** A connection to a list of `ToolCategory` values. */
export type ToolCategoriesConnection = {
  __typename: "ToolCategoriesConnection";
  /** A list of edges which contains the `ToolCategory` and cursor to aid in pagination. */
  edges: Array<ToolCategoriesEdge>;
  /** A list of `ToolCategory` objects. */
  nodes: Array<ToolCategory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ToolCategory` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ToolCategory` edge in the connection. */
export type ToolCategoriesEdge = {
  __typename: "ToolCategoriesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ToolCategory` at the end of the edge. */
  node: ToolCategory;
};

/** Methods to use when ordering `ToolCategory`. */
export enum ToolCategoriesOrderBy {
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  KeyAsc = "KEY_ASC",
  KeyDesc = "KEY_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrderAsc = "ORDER_ASC",
  OrderDesc = "ORDER_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type ToolCategory = {
  __typename: "ToolCategory";
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  /** Reads and enables pagination through a set of `ToolDisplayCategory`. */
  toolDisplayCategoriesByToolcategoryId: ToolDisplayCategoriesConnection;
};

export type ToolCategoryToolDisplayCategoriesByToolcategoryIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolDisplayCategoryCondition>;
  filter?: InputMaybe<ToolDisplayCategoryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolDisplayCategoriesOrderBy>>;
};

/**
 * A condition to be used against `ToolCategory` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ToolCategoryCondition = {
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `order` field. */
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `ToolCategory` object types. All fields are combined with a logical ‘and.’ */
export type ToolCategoryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ToolCategoryFilter>>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ToolCategoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ToolCategoryFilter>>;
  /** Filter by the object’s `order` field. */
  order?: InputMaybe<IntFilter>;
  /** Filter by the object’s `toolDisplayCategoriesByToolcategoryId` relation. */
  toolDisplayCategoriesByToolcategoryId?: InputMaybe<ToolCategoryToManyToolDisplayCategoryFilter>;
  /** Some related `toolDisplayCategoriesByToolcategoryId` exist. */
  toolDisplayCategoriesByToolcategoryIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
};

/** An input for mutations affecting `ToolCategory` */
export type ToolCategoryInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
  toolDisplayCategoriesUsingId?: InputMaybe<ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInverseInput>;
};

/** The fields on `toolCategory` to look up the row to update. */
export type ToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolCategoryKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `toolCategory` being updated. */
    toolCategoryPatch: UpdateToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCatePatch;
  };

/** The fields on `toolCategory` to look up the row to update. */
export type ToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolCategoryPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolCategory` being updated. */
    toolCategoryPatch: UpdateToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCatePatch;
  };

/** Represents an update to a `ToolCategory`. Fields that are set will be updated. */
export type ToolCategoryPatch = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  toolDisplayCategoriesUsingId?: InputMaybe<ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInverseInput>;
};

/** A filter to be used against many `ToolDisplayCategory` object types. All fields are combined with a logical ‘and.’ */
export type ToolCategoryToManyToolDisplayCategoryFilter = {
  /** Every related `ToolDisplayCategory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ToolDisplayCategoryFilter>;
  /** No related `ToolDisplayCategory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ToolDisplayCategoryFilter>;
  /** Some related `ToolDisplayCategory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ToolDisplayCategoryFilter>;
};

/** The fields on `toolCategory` to look up the row to connect. */
export type ToolCategoryToolCategoryKeyKeyConnect = {
  key: Scalars["String"]["input"];
};

/** The fields on `toolCategory` to look up the row to delete. */
export type ToolCategoryToolCategoryKeyKeyDelete = {
  key: Scalars["String"]["input"];
};

/** The fields on `toolCategory` to look up the row to connect. */
export type ToolCategoryToolCategoryPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolCategory` to look up the row to delete. */
export type ToolCategoryToolCategoryPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** A condition to be used against `Tool` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ToolCondition = {
  /** Checks for equality with the object’s `created` field. */
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `helpText` field. */
  helpText?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `searchTerms` field. */
  searchTerms?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** Input for the nested mutation of `toolCategory` in the `ToolDisplayCategoryInput` mutation. */
export type ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInput = {
  /** The primary key(s) for `toolCategory` for the far side of the relationship. */
  connectById?: InputMaybe<ToolCategoryToolCategoryPkeyConnect>;
  /** The primary key(s) for `toolCategory` for the far side of the relationship. */
  connectByKey?: InputMaybe<ToolCategoryToolCategoryKeyKeyConnect>;
  /** A `ToolCategoryInput` object that will be created and connected to this object. */
  create?: InputMaybe<ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateToolCategoryCreateInput>;
  /** The primary key(s) for `toolCategory` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolCategoryToolCategoryPkeyDelete>;
  /** The primary key(s) for `toolCategory` for the far side of the relationship. */
  deleteByKey?: InputMaybe<ToolCategoryToolCategoryKeyKeyDelete>;
  /** The primary key(s) and patch data for `toolCategory` for the far side of the relationship. */
  updateById?: InputMaybe<ToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolCategoryPkeyUpdate>;
  /** The primary key(s) and patch data for `toolCategory` for the far side of the relationship. */
  updateByKey?: InputMaybe<ToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolCategoryKeyKeyUpdate>;
};

/** Input for the nested mutation of `toolDisplayCategory` in the `ToolCategoryInput` mutation. */
export type ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInverseInput = {
  /** The primary key(s) for `toolDisplayCategory` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ToolDisplayCategoryToolDisplayCategoriesPkeyConnect>
  >;
  /** The primary key(s) for `toolDisplayCategory` for the far side of the relationship. */
  connectByToolIdAndToolcategoryId?: InputMaybe<
    Array<ToolDisplayCategoryToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqConnect>
  >;
  /** A `ToolDisplayCategoryInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateToolDisplayCategoriesCreateInput>
  >;
  /** The primary key(s) for `toolDisplayCategory` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<ToolDisplayCategoryToolDisplayCategoriesPkeyDelete>
  >;
  /** The primary key(s) for `toolDisplayCategory` for the far side of the relationship. */
  deleteByToolIdAndToolcategoryId?: InputMaybe<
    Array<ToolDisplayCategoryToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqDelete>
  >;
  /** Flag indicating whether all other `toolDisplayCategory` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `toolDisplayCategory` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolDisplayCategoriesPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `toolDisplayCategory` for the far side of the relationship. */
  updateByToolIdAndToolcategoryId?: InputMaybe<
    Array<ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqUpdate>
  >;
};

/** The `toolCategory` to be created by this mutation. */
export type ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateToolCategoryCreateInput =
  {
    description?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    key: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    order: Scalars["Int"]["input"];
    toolDisplayCategoriesUsingId?: InputMaybe<ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInverseInput>;
  };

/** The `toolDisplayCategory` to be created by this mutation. */
export type ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateToolDisplayCategoriesCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    toolCategoryToToolcategoryId?: InputMaybe<ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolToToolId?: InputMaybe<ToolDisplayCategoriesToolIdAdc582D3FkToolIdInput>;
  };

/** A connection to a list of `ToolDisplayCategory` values. */
export type ToolDisplayCategoriesConnection = {
  __typename: "ToolDisplayCategoriesConnection";
  /** A list of edges which contains the `ToolDisplayCategory` and cursor to aid in pagination. */
  edges: Array<ToolDisplayCategoriesEdge>;
  /** A list of `ToolDisplayCategory` objects. */
  nodes: Array<ToolDisplayCategory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ToolDisplayCategory` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ToolDisplayCategory` edge in the connection. */
export type ToolDisplayCategoriesEdge = {
  __typename: "ToolDisplayCategoriesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ToolDisplayCategory` at the end of the edge. */
  node: ToolDisplayCategory;
};

/** Methods to use when ordering `ToolDisplayCategory`. */
export enum ToolDisplayCategoriesOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ToolcategoryIdAsc = "TOOLCATEGORY_ID_ASC",
  ToolcategoryIdDesc = "TOOLCATEGORY_ID_DESC",
  ToolIdAsc = "TOOL_ID_ASC",
  ToolIdDesc = "TOOL_ID_DESC",
}

/** Input for the nested mutation of `tool` in the `ToolDisplayCategoryInput` mutation. */
export type ToolDisplayCategoriesToolIdAdc582D3FkToolIdInput = {
  /** The primary key(s) for `tool` for the far side of the relationship. */
  connectById?: InputMaybe<ToolToolPkeyConnect>;
  /** The primary key(s) for `tool` for the far side of the relationship. */
  connectByKey?: InputMaybe<ToolToolKeyKeyConnect>;
  /** A `ToolInput` object that will be created and connected to this object. */
  create?: InputMaybe<ToolDisplayCategoriesToolIdAdc582D3FkToolIdToolCreateInput>;
  /** The primary key(s) for `tool` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolToolPkeyDelete>;
  /** The primary key(s) for `tool` for the far side of the relationship. */
  deleteByKey?: InputMaybe<ToolToolKeyKeyDelete>;
  /** The primary key(s) and patch data for `tool` for the far side of the relationship. */
  updateById?: InputMaybe<ToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolPkeyUpdate>;
  /** The primary key(s) and patch data for `tool` for the far side of the relationship. */
  updateByKey?: InputMaybe<ToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolKeyKeyUpdate>;
};

/** Input for the nested mutation of `toolDisplayCategory` in the `ToolInput` mutation. */
export type ToolDisplayCategoriesToolIdAdc582D3FkToolIdInverseInput = {
  /** The primary key(s) for `toolDisplayCategory` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ToolDisplayCategoryToolDisplayCategoriesPkeyConnect>
  >;
  /** The primary key(s) for `toolDisplayCategory` for the far side of the relationship. */
  connectByToolIdAndToolcategoryId?: InputMaybe<
    Array<ToolDisplayCategoryToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqConnect>
  >;
  /** A `ToolDisplayCategoryInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ToolDisplayCategoriesToolIdAdc582D3FkToolIdToolDisplayCategoriesCreateInput>
  >;
  /** The primary key(s) for `toolDisplayCategory` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<ToolDisplayCategoryToolDisplayCategoriesPkeyDelete>
  >;
  /** The primary key(s) for `toolDisplayCategory` for the far side of the relationship. */
  deleteByToolIdAndToolcategoryId?: InputMaybe<
    Array<ToolDisplayCategoryToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqDelete>
  >;
  /** Flag indicating whether all other `toolDisplayCategory` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `toolDisplayCategory` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolDisplayCategoriesPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `toolDisplayCategory` for the far side of the relationship. */
  updateByToolIdAndToolcategoryId?: InputMaybe<
    Array<ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqUpdate>
  >;
};

/** The `tool` to be created by this mutation. */
export type ToolDisplayCategoriesToolIdAdc582D3FkToolIdToolCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  helpText: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  searchTerms?: InputMaybe<Scalars["JSON"]["input"]>;
  toolDisplayCategoriesUsingId?: InputMaybe<ToolDisplayCategoriesToolIdAdc582D3FkToolIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsToolIdF77Ef6F7FkToolIdInverseInput>;
  toolVersionsUsingId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInverseInput>;
};

/** The `toolDisplayCategory` to be created by this mutation. */
export type ToolDisplayCategoriesToolIdAdc582D3FkToolIdToolDisplayCategoriesCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    toolCategoryToToolcategoryId?: InputMaybe<ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInput>;
    toolToToolId?: InputMaybe<ToolDisplayCategoriesToolIdAdc582D3FkToolIdInput>;
    toolcategoryId?: InputMaybe<Scalars["Int"]["input"]>;
  };

export type ToolDisplayCategory = {
  __typename: "ToolDisplayCategory";
  id: Scalars["Int"]["output"];
  /** Reads a single `Tool` that is related to this `ToolDisplayCategory`. */
  toolByToolId: Maybe<Tool>;
  /** Reads a single `ToolCategory` that is related to this `ToolDisplayCategory`. */
  toolCategoryByToolcategoryId: Maybe<ToolCategory>;
  toolId: Scalars["Int"]["output"];
  toolcategoryId: Scalars["Int"]["output"];
};

/**
 * A condition to be used against `ToolDisplayCategory` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ToolDisplayCategoryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `toolId` field. */
  toolId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `toolcategoryId` field. */
  toolcategoryId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `ToolDisplayCategory` object types. All fields are combined with a logical ‘and.’ */
export type ToolDisplayCategoryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ToolDisplayCategoryFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ToolDisplayCategoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ToolDisplayCategoryFilter>>;
  /** Filter by the object’s `toolByToolId` relation. */
  toolByToolId?: InputMaybe<ToolFilter>;
  /** Filter by the object’s `toolCategoryByToolcategoryId` relation. */
  toolCategoryByToolcategoryId?: InputMaybe<ToolCategoryFilter>;
  /** Filter by the object’s `toolId` field. */
  toolId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `toolcategoryId` field. */
  toolcategoryId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ToolDisplayCategory` */
export type ToolDisplayCategoryInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  toolCategoryToToolcategoryId?: InputMaybe<ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInput>;
  toolId?: InputMaybe<Scalars["Int"]["input"]>;
  toolToToolId?: InputMaybe<ToolDisplayCategoriesToolIdAdc582D3FkToolIdInput>;
  toolcategoryId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `toolDisplayCategory` to look up the row to update. */
export type ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolDisplayCategoriesPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolDisplayCategory` being updated. */
    toolDisplayCategoryPatch: UpdateToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCatePatch;
  };

/** The fields on `toolDisplayCategory` to look up the row to update. */
export type ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqUpdate =
  {
    /** An object where the defined keys will be set on the `toolDisplayCategory` being updated. */
    toolDisplayCategoryPatch: UpdateToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCatePatch;
    toolId: Scalars["Int"]["input"];
    toolcategoryId: Scalars["Int"]["input"];
  };

/** The fields on `toolDisplayCategory` to look up the row to update. */
export type ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolDisplayCategoriesPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolDisplayCategory` being updated. */
    toolDisplayCategoryPatch: UpdateToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdPatch;
  };

/** The fields on `toolDisplayCategory` to look up the row to update. */
export type ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqUpdate =
  {
    /** An object where the defined keys will be set on the `toolDisplayCategory` being updated. */
    toolDisplayCategoryPatch: UpdateToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdPatch;
    toolId: Scalars["Int"]["input"];
    toolcategoryId: Scalars["Int"]["input"];
  };

/** Represents an update to a `ToolDisplayCategory`. Fields that are set will be updated. */
export type ToolDisplayCategoryPatch = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  toolCategoryToToolcategoryId?: InputMaybe<ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInput>;
  toolId?: InputMaybe<Scalars["Int"]["input"]>;
  toolToToolId?: InputMaybe<ToolDisplayCategoriesToolIdAdc582D3FkToolIdInput>;
  toolcategoryId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `toolDisplayCategory` to look up the row to connect. */
export type ToolDisplayCategoryToolDisplayCategoriesPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolDisplayCategory` to look up the row to delete. */
export type ToolDisplayCategoryToolDisplayCategoriesPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolDisplayCategory` to look up the row to connect. */
export type ToolDisplayCategoryToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqConnect =
  {
    toolId: Scalars["Int"]["input"];
    toolcategoryId: Scalars["Int"]["input"];
  };

/** The fields on `toolDisplayCategory` to look up the row to delete. */
export type ToolDisplayCategoryToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqDelete =
  {
    toolId: Scalars["Int"]["input"];
    toolcategoryId: Scalars["Int"]["input"];
  };

/** A filter to be used against `Tool` object types. All fields are combined with a logical ‘and.’ */
export type ToolFilter = {
  /** Filter by the object’s `analysisTableGroupsByToolId` relation. */
  analysisTableGroupsByToolId?: InputMaybe<ToolToManyAnalysisTableGroupFilter>;
  /** Some related `analysisTableGroupsByToolId` exist. */
  analysisTableGroupsByToolIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ToolFilter>>;
  /** Filter by the object’s `created` field. */
  created?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `helpText` field. */
  helpText?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ToolFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ToolFilter>>;
  /** Filter by the object’s `searchTerms` field. */
  searchTerms?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `toolDisplayCategoriesByToolId` relation. */
  toolDisplayCategoriesByToolId?: InputMaybe<ToolToManyToolDisplayCategoryFilter>;
  /** Some related `toolDisplayCategoriesByToolId` exist. */
  toolDisplayCategoriesByToolIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `toolTenantsByToolId` relation. */
  toolTenantsByToolId?: InputMaybe<ToolToManyToolTenantFilter>;
  /** Some related `toolTenantsByToolId` exist. */
  toolTenantsByToolIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `toolVersionsByToolId` relation. */
  toolVersionsByToolId?: InputMaybe<ToolToManyToolVersionFilter>;
  /** Some related `toolVersionsByToolId` exist. */
  toolVersionsByToolIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** An input for mutations affecting `Tool` */
export type ToolInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  helpText: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  searchTerms?: InputMaybe<Scalars["JSON"]["input"]>;
  toolDisplayCategoriesUsingId?: InputMaybe<ToolDisplayCategoriesToolIdAdc582D3FkToolIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsToolIdF77Ef6F7FkToolIdInverseInput>;
  toolVersionsUsingId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInverseInput>;
};

/** The fields on `tool` to look up the row to update. */
export type ToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdUsingToolKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tool` being updated. */
    toolPatch: UpdateToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdPatch;
  };

/** The fields on `tool` to look up the row to update. */
export type ToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdUsingToolPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tool` being updated. */
    toolPatch: UpdateToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdPatch;
  };

/** The fields on `tool` to look up the row to update. */
export type ToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tool` being updated. */
    toolPatch: UpdateToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdPatch;
  };

/** The fields on `tool` to look up the row to update. */
export type ToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tool` being updated. */
    toolPatch: UpdateToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdPatch;
  };

/** The fields on `tool` to look up the row to update. */
export type ToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tool` being updated. */
    toolPatch: UpdateToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdPatch;
  };

/** The fields on `tool` to look up the row to update. */
export type ToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tool` being updated. */
    toolPatch: UpdateToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdPatch;
  };

/** The fields on `tool` to look up the row to update. */
export type ToolOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `tool` being updated. */
    toolPatch: UpdateToolOnToolVersionForToolVersionToolId3B396B22FkToolIdPatch;
  };

/** The fields on `tool` to look up the row to update. */
export type ToolOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `tool` being updated. */
    toolPatch: UpdateToolOnToolVersionForToolVersionToolId3B396B22FkToolIdPatch;
  };

export type ToolParameter = {
  __typename: "ToolParameter";
  default: Maybe<Scalars["JSON"]["output"]>;
  helpText: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  options: Maybe<Scalars["JSON"]["output"]>;
  required: Scalars["Boolean"]["output"];
  /** Reads a single `ToolVersion` that is related to this `ToolParameter`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
  toolVersionId: Maybe<Scalars["Int"]["output"]>;
  type: Scalars["Int"]["output"];
  unit: Maybe<Scalars["String"]["output"]>;
};

/**
 * A condition to be used against `ToolParameter` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ToolParameterCondition = {
  /** Checks for equality with the object’s `default` field. */
  default?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `helpText` field. */
  helpText?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `options` field. */
  options?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `required` field. */
  required?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `toolVersionId` field. */
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `unit` field. */
  unit?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `ToolParameter` object types. All fields are combined with a logical ‘and.’ */
export type ToolParameterFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ToolParameterFilter>>;
  /** Filter by the object’s `default` field. */
  default?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `helpText` field. */
  helpText?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ToolParameterFilter>;
  /** Filter by the object’s `options` field. */
  options?: InputMaybe<JsonFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ToolParameterFilter>>;
  /** Filter by the object’s `required` field. */
  required?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `toolVersionByToolVersionId` relation. */
  toolVersionByToolVersionId?: InputMaybe<ToolVersionFilter>;
  /** A related `toolVersionByToolVersionId` exists. */
  toolVersionByToolVersionIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `toolVersionId` field. */
  toolVersionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<IntFilter>;
  /** Filter by the object’s `unit` field. */
  unit?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `ToolParameter` */
export type ToolParameterInput = {
  default?: InputMaybe<Scalars["JSON"]["input"]>;
  helpText: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  options?: InputMaybe<Scalars["JSON"]["input"]>;
  required: Scalars["Boolean"]["input"];
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInput>;
  type: Scalars["Int"]["input"];
  unit?: InputMaybe<Scalars["String"]["input"]>;
};

/** The fields on `toolParameter` to look up the row to update. */
export type ToolParameterOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdUsingToolParameterPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolParameter` being updated. */
    toolParameterPatch: UpdateToolParameterOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdPatch;
  };

/** Represents an update to a `ToolParameter`. Fields that are set will be updated. */
export type ToolParameterPatch = {
  default?: InputMaybe<Scalars["JSON"]["input"]>;
  helpText?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  options?: InputMaybe<Scalars["JSON"]["input"]>;
  required?: InputMaybe<Scalars["Boolean"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInput>;
  type?: InputMaybe<Scalars["Int"]["input"]>;
  unit?: InputMaybe<Scalars["String"]["input"]>;
};

/** The fields on `toolParameter` to look up the row to connect. */
export type ToolParameterToolParameterPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolParameter` to look up the row to delete. */
export type ToolParameterToolParameterPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** Input for the nested mutation of `toolVersion` in the `ToolParameterInput` mutation. */
export type ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInput = {
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectById?: InputMaybe<ToolVersionToolVersionPkeyConnect>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueConnect>;
  /** A `ToolVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdToolVersionCreateInput>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolVersionToolVersionPkeyDelete>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueDelete>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateById?: InputMaybe<ToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdUsingToolVersionPkeyUpdate>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateByToolIdAndVersion?: InputMaybe<ToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdUsingToolversionToolVersionUniqueUpdate>;
};

/** Input for the nested mutation of `toolParameter` in the `ToolVersionInput` mutation. */
export type ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput = {
  /** The primary key(s) for `toolParameter` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ToolParameterToolParameterPkeyConnect>>;
  /** A `ToolParameterInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdToolParameterCreateInput>
  >;
  /** The primary key(s) for `toolParameter` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ToolParameterToolParameterPkeyDelete>>;
  /** Flag indicating whether all other `toolParameter` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `toolParameter` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ToolParameterOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdUsingToolParameterPkeyUpdate>
  >;
};

/** The `toolParameter` to be created by this mutation. */
export type ToolParameterToolVersionIdC3A0Df75FkToolVersionIdToolParameterCreateInput =
  {
    default?: InputMaybe<Scalars["JSON"]["input"]>;
    helpText: Scalars["String"]["input"];
    id?: InputMaybe<Scalars["Int"]["input"]>;
    key: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    options?: InputMaybe<Scalars["JSON"]["input"]>;
    required: Scalars["Boolean"]["input"];
    toolVersionToToolVersionId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInput>;
    type: Scalars["Int"]["input"];
    unit?: InputMaybe<Scalars["String"]["input"]>;
  };

/** The `toolVersion` to be created by this mutation. */
export type ToolParameterToolVersionIdC3A0Df75FkToolVersionIdToolVersionCreateInput =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command: Scalars["String"]["input"];
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible: Scalars["Boolean"]["input"];
    isUpgradableToNextVersion: Scalars["Boolean"]["input"];
    maturity: Scalars["Int"]["input"];
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version: Scalars["String"]["input"];
    visibility: Scalars["Int"]["input"];
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** A connection to a list of `ToolParameter` values. */
export type ToolParametersConnection = {
  __typename: "ToolParametersConnection";
  /** A list of edges which contains the `ToolParameter` and cursor to aid in pagination. */
  edges: Array<ToolParametersEdge>;
  /** A list of `ToolParameter` objects. */
  nodes: Array<ToolParameter>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ToolParameter` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ToolParameter` edge in the connection. */
export type ToolParametersEdge = {
  __typename: "ToolParametersEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ToolParameter` at the end of the edge. */
  node: ToolParameter;
};

/** Methods to use when ordering `ToolParameter`. */
export enum ToolParametersOrderBy {
  DefaultAsc = "DEFAULT_ASC",
  DefaultDesc = "DEFAULT_DESC",
  HelpTextAsc = "HELP_TEXT_ASC",
  HelpTextDesc = "HELP_TEXT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  KeyAsc = "KEY_ASC",
  KeyDesc = "KEY_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OptionsAsc = "OPTIONS_ASC",
  OptionsDesc = "OPTIONS_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RequiredAsc = "REQUIRED_ASC",
  RequiredDesc = "REQUIRED_DESC",
  ToolVersionIdAsc = "TOOL_VERSION_ID_ASC",
  ToolVersionIdDesc = "TOOL_VERSION_ID_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
  UnitAsc = "UNIT_ASC",
  UnitDesc = "UNIT_DESC",
}

/** Represents an update to a `Tool`. Fields that are set will be updated. */
export type ToolPatch = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  helpText?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  searchTerms?: InputMaybe<Scalars["JSON"]["input"]>;
  toolDisplayCategoriesUsingId?: InputMaybe<ToolDisplayCategoriesToolIdAdc582D3FkToolIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsToolIdF77Ef6F7FkToolIdInverseInput>;
  toolVersionsUsingId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInverseInput>;
};

export type ToolResource = {
  __typename: "ToolResource";
  id: Scalars["Int"]["output"];
  /** Reads a single `ToolVersion` that is related to this `ToolResource`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
  toolVersionId: Scalars["Int"]["output"];
  type: Scalars["Int"]["output"];
  value: Scalars["Int"]["output"];
};

/**
 * A condition to be used against `ToolResource` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ToolResourceCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `toolVersionId` field. */
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `ToolResource` object types. All fields are combined with a logical ‘and.’ */
export type ToolResourceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ToolResourceFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ToolResourceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ToolResourceFilter>>;
  /** Filter by the object’s `toolVersionByToolVersionId` relation. */
  toolVersionByToolVersionId?: InputMaybe<ToolVersionFilter>;
  /** Filter by the object’s `toolVersionId` field. */
  toolVersionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<IntFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ToolResource` */
export type ToolResourceInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInput>;
  type: Scalars["Int"]["input"];
  value: Scalars["Int"]["input"];
};

/** The fields on `toolResource` to look up the row to update. */
export type ToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingReqTypeToolVersionUniqueUpdate =
  {
    /** An object where the defined keys will be set on the `toolResource` being updated. */
    toolResourcePatch: UpdateToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdPatch;
    toolVersionId: Scalars["Int"]["input"];
    type: Scalars["Int"]["input"];
  };

/** The fields on `toolResource` to look up the row to update. */
export type ToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingToolResourcePkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolResource` being updated. */
    toolResourcePatch: UpdateToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdPatch;
  };

/** Represents an update to a `ToolResource`. Fields that are set will be updated. */
export type ToolResourcePatch = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInput>;
  type?: InputMaybe<Scalars["Int"]["input"]>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `toolResource` to look up the row to connect. */
export type ToolResourceReqTypeToolVersionUniqueConnect = {
  toolVersionId: Scalars["Int"]["input"];
  type: Scalars["Int"]["input"];
};

/** The fields on `toolResource` to look up the row to delete. */
export type ToolResourceReqTypeToolVersionUniqueDelete = {
  toolVersionId: Scalars["Int"]["input"];
  type: Scalars["Int"]["input"];
};

/** The fields on `toolResource` to look up the row to connect. */
export type ToolResourceToolResourcePkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolResource` to look up the row to delete. */
export type ToolResourceToolResourcePkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** Input for the nested mutation of `toolVersion` in the `ToolResourceInput` mutation. */
export type ToolResourceToolVersionId5E21979DFkToolVersionIdInput = {
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectById?: InputMaybe<ToolVersionToolVersionPkeyConnect>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueConnect>;
  /** A `ToolVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdToolVersionCreateInput>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolVersionToolVersionPkeyDelete>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueDelete>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateById?: InputMaybe<ToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingToolVersionPkeyUpdate>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateByToolIdAndVersion?: InputMaybe<ToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingToolversionToolVersionUniqueUpdate>;
};

/** Input for the nested mutation of `toolResource` in the `ToolVersionInput` mutation. */
export type ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput = {
  /** The primary key(s) for `toolResource` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ToolResourceToolResourcePkeyConnect>>;
  /** The primary key(s) for `toolResource` for the far side of the relationship. */
  connectByTypeAndToolVersionId?: InputMaybe<
    Array<ToolResourceReqTypeToolVersionUniqueConnect>
  >;
  /** A `ToolResourceInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ToolResourceToolVersionId5E21979DFkToolVersionIdToolResourceCreateInput>
  >;
  /** The primary key(s) for `toolResource` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ToolResourceToolResourcePkeyDelete>>;
  /** The primary key(s) for `toolResource` for the far side of the relationship. */
  deleteByTypeAndToolVersionId?: InputMaybe<
    Array<ToolResourceReqTypeToolVersionUniqueDelete>
  >;
  /** Flag indicating whether all other `toolResource` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `toolResource` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingToolResourcePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `toolResource` for the far side of the relationship. */
  updateByTypeAndToolVersionId?: InputMaybe<
    Array<ToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingReqTypeToolVersionUniqueUpdate>
  >;
};

/** The `toolResource` to be created by this mutation. */
export type ToolResourceToolVersionId5E21979DFkToolVersionIdToolResourceCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInput>;
    type: Scalars["Int"]["input"];
    value: Scalars["Int"]["input"];
  };

/** The `toolVersion` to be created by this mutation. */
export type ToolResourceToolVersionId5E21979DFkToolVersionIdToolVersionCreateInput =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command: Scalars["String"]["input"];
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible: Scalars["Boolean"]["input"];
    isUpgradableToNextVersion: Scalars["Boolean"]["input"];
    maturity: Scalars["Int"]["input"];
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version: Scalars["String"]["input"];
    visibility: Scalars["Int"]["input"];
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** A connection to a list of `ToolResource` values. */
export type ToolResourcesConnection = {
  __typename: "ToolResourcesConnection";
  /** A list of edges which contains the `ToolResource` and cursor to aid in pagination. */
  edges: Array<ToolResourcesEdge>;
  /** A list of `ToolResource` objects. */
  nodes: Array<ToolResource>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ToolResource` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ToolResource` edge in the connection. */
export type ToolResourcesEdge = {
  __typename: "ToolResourcesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ToolResource` at the end of the edge. */
  node: ToolResource;
};

/** Methods to use when ordering `ToolResource`. */
export enum ToolResourcesOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ToolVersionIdAsc = "TOOL_VERSION_ID_ASC",
  ToolVersionIdDesc = "TOOL_VERSION_ID_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
  ValueAsc = "VALUE_ASC",
  ValueDesc = "VALUE_DESC",
}

export type ToolResult = {
  __typename: "ToolResult";
  fileFormats: Maybe<Scalars["JSON"]["output"]>;
  helpText: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  required: Scalars["Boolean"]["output"];
  /** Reads a single `ToolVersion` that is related to this `ToolResult`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
  toolVersionId: Maybe<Scalars["Int"]["output"]>;
};

/**
 * A condition to be used against `ToolResult` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ToolResultCondition = {
  /** Checks for equality with the object’s `fileFormats` field. */
  fileFormats?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `helpText` field. */
  helpText?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `required` field. */
  required?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `toolVersionId` field. */
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `ToolResult` object types. All fields are combined with a logical ‘and.’ */
export type ToolResultFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ToolResultFilter>>;
  /** Filter by the object’s `fileFormats` field. */
  fileFormats?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `helpText` field. */
  helpText?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ToolResultFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ToolResultFilter>>;
  /** Filter by the object’s `required` field. */
  required?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `toolVersionByToolVersionId` relation. */
  toolVersionByToolVersionId?: InputMaybe<ToolVersionFilter>;
  /** A related `toolVersionByToolVersionId` exists. */
  toolVersionByToolVersionIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `toolVersionId` field. */
  toolVersionId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ToolResult` */
export type ToolResultInput = {
  fileFormats?: InputMaybe<Scalars["JSON"]["input"]>;
  helpText: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  required: Scalars["Boolean"]["input"];
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInput>;
};

/** The fields on `toolResult` to look up the row to update. */
export type ToolResultOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdUsingToolResultPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolResult` being updated. */
    toolResultPatch: UpdateToolResultOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdPatch;
  };

/** Represents an update to a `ToolResult`. Fields that are set will be updated. */
export type ToolResultPatch = {
  fileFormats?: InputMaybe<Scalars["JSON"]["input"]>;
  helpText?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  required?: InputMaybe<Scalars["Boolean"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInput>;
};

/** The fields on `toolResult` to look up the row to connect. */
export type ToolResultToolResultPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolResult` to look up the row to delete. */
export type ToolResultToolResultPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** Input for the nested mutation of `toolVersion` in the `ToolResultInput` mutation. */
export type ToolResultToolVersionId41Fb163EFkToolVersionIdInput = {
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectById?: InputMaybe<ToolVersionToolVersionPkeyConnect>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueConnect>;
  /** A `ToolVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdToolVersionCreateInput>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolVersionToolVersionPkeyDelete>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueDelete>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateById?: InputMaybe<ToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdUsingToolVersionPkeyUpdate>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateByToolIdAndVersion?: InputMaybe<ToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdUsingToolversionToolVersionUniqueUpdate>;
};

/** Input for the nested mutation of `toolResult` in the `ToolVersionInput` mutation. */
export type ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput = {
  /** The primary key(s) for `toolResult` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ToolResultToolResultPkeyConnect>>;
  /** A `ToolResultInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ToolResultToolVersionId41Fb163EFkToolVersionIdToolResultCreateInput>
  >;
  /** The primary key(s) for `toolResult` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ToolResultToolResultPkeyDelete>>;
  /** Flag indicating whether all other `toolResult` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `toolResult` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ToolResultOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdUsingToolResultPkeyUpdate>
  >;
};

/** The `toolResult` to be created by this mutation. */
export type ToolResultToolVersionId41Fb163EFkToolVersionIdToolResultCreateInput =
  {
    fileFormats?: InputMaybe<Scalars["JSON"]["input"]>;
    helpText: Scalars["String"]["input"];
    id?: InputMaybe<Scalars["Int"]["input"]>;
    key: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    required: Scalars["Boolean"]["input"];
    toolVersionToToolVersionId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInput>;
  };

/** The `toolVersion` to be created by this mutation. */
export type ToolResultToolVersionId41Fb163EFkToolVersionIdToolVersionCreateInput =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command: Scalars["String"]["input"];
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible: Scalars["Boolean"]["input"];
    isUpgradableToNextVersion: Scalars["Boolean"]["input"];
    maturity: Scalars["Int"]["input"];
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version: Scalars["String"]["input"];
    visibility: Scalars["Int"]["input"];
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** A connection to a list of `ToolResult` values. */
export type ToolResultsConnection = {
  __typename: "ToolResultsConnection";
  /** A list of edges which contains the `ToolResult` and cursor to aid in pagination. */
  edges: Array<ToolResultsEdge>;
  /** A list of `ToolResult` objects. */
  nodes: Array<ToolResult>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ToolResult` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ToolResult` edge in the connection. */
export type ToolResultsEdge = {
  __typename: "ToolResultsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ToolResult` at the end of the edge. */
  node: ToolResult;
};

/** Methods to use when ordering `ToolResult`. */
export enum ToolResultsOrderBy {
  FileFormatsAsc = "FILE_FORMATS_ASC",
  FileFormatsDesc = "FILE_FORMATS_DESC",
  HelpTextAsc = "HELP_TEXT_ASC",
  HelpTextDesc = "HELP_TEXT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  KeyAsc = "KEY_ASC",
  KeyDesc = "KEY_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RequiredAsc = "REQUIRED_ASC",
  RequiredDesc = "REQUIRED_DESC",
  ToolVersionIdAsc = "TOOL_VERSION_ID_ASC",
  ToolVersionIdDesc = "TOOL_VERSION_ID_DESC",
}

export type ToolSecret = {
  __typename: "ToolSecret";
  helpText: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  required: Scalars["Boolean"]["output"];
  /** Reads a single `ToolVersion` that is related to this `ToolSecret`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
  toolVersionId: Maybe<Scalars["Int"]["output"]>;
};

/**
 * A condition to be used against `ToolSecret` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ToolSecretCondition = {
  /** Checks for equality with the object’s `helpText` field. */
  helpText?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `required` field. */
  required?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `toolVersionId` field. */
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `ToolSecret` object types. All fields are combined with a logical ‘and.’ */
export type ToolSecretFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ToolSecretFilter>>;
  /** Filter by the object’s `helpText` field. */
  helpText?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ToolSecretFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ToolSecretFilter>>;
  /** Filter by the object’s `required` field. */
  required?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `toolVersionByToolVersionId` relation. */
  toolVersionByToolVersionId?: InputMaybe<ToolVersionFilter>;
  /** A related `toolVersionByToolVersionId` exists. */
  toolVersionByToolVersionIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `toolVersionId` field. */
  toolVersionId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ToolSecret` */
export type ToolSecretInput = {
  helpText: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  required: Scalars["Boolean"]["input"];
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInput>;
};

/** The fields on `toolSecret` to look up the row to update. */
export type ToolSecretOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdUsingToolSecretPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolSecret` being updated. */
    toolSecretPatch: UpdateToolSecretOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdPatch;
  };

/** Represents an update to a `ToolSecret`. Fields that are set will be updated. */
export type ToolSecretPatch = {
  helpText?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  required?: InputMaybe<Scalars["Boolean"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInput>;
};

/** The fields on `toolSecret` to look up the row to connect. */
export type ToolSecretToolSecretPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolSecret` to look up the row to delete. */
export type ToolSecretToolSecretPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** Input for the nested mutation of `toolVersion` in the `ToolSecretInput` mutation. */
export type ToolSecretToolVersionId92Dc8FadFkToolVersionIdInput = {
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectById?: InputMaybe<ToolVersionToolVersionPkeyConnect>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueConnect>;
  /** A `ToolVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdToolVersionCreateInput>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolVersionToolVersionPkeyDelete>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueDelete>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateById?: InputMaybe<ToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdUsingToolVersionPkeyUpdate>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateByToolIdAndVersion?: InputMaybe<ToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdUsingToolversionToolVersionUniqueUpdate>;
};

/** Input for the nested mutation of `toolSecret` in the `ToolVersionInput` mutation. */
export type ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput = {
  /** The primary key(s) for `toolSecret` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ToolSecretToolSecretPkeyConnect>>;
  /** A `ToolSecretInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ToolSecretToolVersionId92Dc8FadFkToolVersionIdToolSecretCreateInput>
  >;
  /** The primary key(s) for `toolSecret` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ToolSecretToolSecretPkeyDelete>>;
  /** Flag indicating whether all other `toolSecret` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `toolSecret` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ToolSecretOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdUsingToolSecretPkeyUpdate>
  >;
};

/** The `toolSecret` to be created by this mutation. */
export type ToolSecretToolVersionId92Dc8FadFkToolVersionIdToolSecretCreateInput =
  {
    helpText: Scalars["String"]["input"];
    id?: InputMaybe<Scalars["Int"]["input"]>;
    key: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    required: Scalars["Boolean"]["input"];
    toolVersionToToolVersionId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInput>;
  };

/** The `toolVersion` to be created by this mutation. */
export type ToolSecretToolVersionId92Dc8FadFkToolVersionIdToolVersionCreateInput =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command: Scalars["String"]["input"];
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible: Scalars["Boolean"]["input"];
    isUpgradableToNextVersion: Scalars["Boolean"]["input"];
    maturity: Scalars["Int"]["input"];
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version: Scalars["String"]["input"];
    visibility: Scalars["Int"]["input"];
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** A connection to a list of `ToolSecret` values. */
export type ToolSecretsConnection = {
  __typename: "ToolSecretsConnection";
  /** A list of edges which contains the `ToolSecret` and cursor to aid in pagination. */
  edges: Array<ToolSecretsEdge>;
  /** A list of `ToolSecret` objects. */
  nodes: Array<ToolSecret>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ToolSecret` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ToolSecret` edge in the connection. */
export type ToolSecretsEdge = {
  __typename: "ToolSecretsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ToolSecret` at the end of the edge. */
  node: ToolSecret;
};

/** Methods to use when ordering `ToolSecret`. */
export enum ToolSecretsOrderBy {
  HelpTextAsc = "HELP_TEXT_ASC",
  HelpTextDesc = "HELP_TEXT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  KeyAsc = "KEY_ASC",
  KeyDesc = "KEY_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RequiredAsc = "REQUIRED_ASC",
  RequiredDesc = "REQUIRED_DESC",
  ToolVersionIdAsc = "TOOL_VERSION_ID_ASC",
  ToolVersionIdDesc = "TOOL_VERSION_ID_DESC",
}

export type ToolSource = {
  __typename: "ToolSource";
  fileFormats: Maybe<Scalars["JSON"]["output"]>;
  helpText: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  required: Scalars["Boolean"]["output"];
  /** Reads and enables pagination through a set of `TaskSource`. */
  taskSourcesByToolSourceId: TaskSourcesConnection;
  /** Reads a single `ToolVersion` that is related to this `ToolSource`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
  toolVersionId: Maybe<Scalars["Int"]["output"]>;
};

export type ToolSourceTaskSourcesByToolSourceIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskSourceCondition>;
  filter?: InputMaybe<TaskSourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TaskSourcesOrderBy>>;
};

/**
 * A condition to be used against `ToolSource` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ToolSourceCondition = {
  /** Checks for equality with the object’s `fileFormats` field. */
  fileFormats?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `helpText` field. */
  helpText?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `required` field. */
  required?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `toolVersionId` field. */
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `ToolSource` object types. All fields are combined with a logical ‘and.’ */
export type ToolSourceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ToolSourceFilter>>;
  /** Filter by the object’s `fileFormats` field. */
  fileFormats?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `helpText` field. */
  helpText?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ToolSourceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ToolSourceFilter>>;
  /** Filter by the object’s `required` field. */
  required?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `taskSourcesByToolSourceId` relation. */
  taskSourcesByToolSourceId?: InputMaybe<ToolSourceToManyTaskSourceFilter>;
  /** Some related `taskSourcesByToolSourceId` exist. */
  taskSourcesByToolSourceIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `toolVersionByToolVersionId` relation. */
  toolVersionByToolVersionId?: InputMaybe<ToolVersionFilter>;
  /** A related `toolVersionByToolVersionId` exists. */
  toolVersionByToolVersionIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `toolVersionId` field. */
  toolVersionId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ToolSource` */
export type ToolSourceInput = {
  fileFormats?: InputMaybe<Scalars["JSON"]["input"]>;
  helpText: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  required: Scalars["Boolean"]["input"];
  taskSourcesUsingId?: InputMaybe<TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInverseInput>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInput>;
};

/** The fields on `toolSource` to look up the row to update. */
export type ToolSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdUsingToolSourcePkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolSource` being updated. */
    toolSourcePatch: UpdateToolSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdPatch;
  };

/** The fields on `toolSource` to look up the row to update. */
export type ToolSourceOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdUsingToolSourcePkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolSource` being updated. */
    toolSourcePatch: UpdateToolSourceOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdPatch;
  };

/** Represents an update to a `ToolSource`. Fields that are set will be updated. */
export type ToolSourcePatch = {
  fileFormats?: InputMaybe<Scalars["JSON"]["input"]>;
  helpText?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  required?: InputMaybe<Scalars["Boolean"]["input"]>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInverseInput>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInput>;
};

/** A filter to be used against many `TaskSource` object types. All fields are combined with a logical ‘and.’ */
export type ToolSourceToManyTaskSourceFilter = {
  /** Every related `TaskSource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TaskSourceFilter>;
  /** No related `TaskSource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TaskSourceFilter>;
  /** Some related `TaskSource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TaskSourceFilter>;
};

/** The fields on `toolSource` to look up the row to connect. */
export type ToolSourceToolSourcePkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolSource` to look up the row to delete. */
export type ToolSourceToolSourcePkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** Input for the nested mutation of `toolVersion` in the `ToolSourceInput` mutation. */
export type ToolSourceToolVersionId9Ac27A25FkToolVersionIdInput = {
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectById?: InputMaybe<ToolVersionToolVersionPkeyConnect>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueConnect>;
  /** A `ToolVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdToolVersionCreateInput>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolVersionToolVersionPkeyDelete>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueDelete>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateById?: InputMaybe<ToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdUsingToolVersionPkeyUpdate>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateByToolIdAndVersion?: InputMaybe<ToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdUsingToolversionToolVersionUniqueUpdate>;
};

/** Input for the nested mutation of `toolSource` in the `ToolVersionInput` mutation. */
export type ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput = {
  /** The primary key(s) for `toolSource` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ToolSourceToolSourcePkeyConnect>>;
  /** A `ToolSourceInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ToolSourceToolVersionId9Ac27A25FkToolVersionIdToolSourceCreateInput>
  >;
  /** The primary key(s) for `toolSource` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ToolSourceToolSourcePkeyDelete>>;
  /** Flag indicating whether all other `toolSource` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `toolSource` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ToolSourceOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdUsingToolSourcePkeyUpdate>
  >;
};

/** The `toolSource` to be created by this mutation. */
export type ToolSourceToolVersionId9Ac27A25FkToolVersionIdToolSourceCreateInput =
  {
    fileFormats?: InputMaybe<Scalars["JSON"]["input"]>;
    helpText: Scalars["String"]["input"];
    id?: InputMaybe<Scalars["Int"]["input"]>;
    key: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    required: Scalars["Boolean"]["input"];
    taskSourcesUsingId?: InputMaybe<TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInverseInput>;
    toolVersionToToolVersionId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInput>;
  };

/** The `toolVersion` to be created by this mutation. */
export type ToolSourceToolVersionId9Ac27A25FkToolVersionIdToolVersionCreateInput =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command: Scalars["String"]["input"];
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible: Scalars["Boolean"]["input"];
    isUpgradableToNextVersion: Scalars["Boolean"]["input"];
    maturity: Scalars["Int"]["input"];
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version: Scalars["String"]["input"];
    visibility: Scalars["Int"]["input"];
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** A connection to a list of `ToolSource` values. */
export type ToolSourcesConnection = {
  __typename: "ToolSourcesConnection";
  /** A list of edges which contains the `ToolSource` and cursor to aid in pagination. */
  edges: Array<ToolSourcesEdge>;
  /** A list of `ToolSource` objects. */
  nodes: Array<ToolSource>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ToolSource` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ToolSource` edge in the connection. */
export type ToolSourcesEdge = {
  __typename: "ToolSourcesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ToolSource` at the end of the edge. */
  node: ToolSource;
};

/** Methods to use when ordering `ToolSource`. */
export enum ToolSourcesOrderBy {
  FileFormatsAsc = "FILE_FORMATS_ASC",
  FileFormatsDesc = "FILE_FORMATS_DESC",
  HelpTextAsc = "HELP_TEXT_ASC",
  HelpTextDesc = "HELP_TEXT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  KeyAsc = "KEY_ASC",
  KeyDesc = "KEY_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RequiredAsc = "REQUIRED_ASC",
  RequiredDesc = "REQUIRED_DESC",
  ToolVersionIdAsc = "TOOL_VERSION_ID_ASC",
  ToolVersionIdDesc = "TOOL_VERSION_ID_DESC",
}

export type ToolTenant = {
  __typename: "ToolTenant";
  id: Scalars["Int"]["output"];
  /** Reads a single `Tenant` that is related to this `ToolTenant`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  /** Reads a single `Tool` that is related to this `ToolTenant`. */
  toolByToolId: Maybe<Tool>;
  toolId: Scalars["Int"]["output"];
};

/**
 * A condition to be used against `ToolTenant` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ToolTenantCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `toolId` field. */
  toolId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `ToolTenant` object types. All fields are combined with a logical ‘and.’ */
export type ToolTenantFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ToolTenantFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ToolTenantFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ToolTenantFilter>>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `toolByToolId` relation. */
  toolByToolId?: InputMaybe<ToolFilter>;
  /** Filter by the object’s `toolId` field. */
  toolId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ToolTenant` */
export type ToolTenantInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInput>;
  toolId?: InputMaybe<Scalars["Int"]["input"]>;
  toolToToolId?: InputMaybe<ToolTenantsToolIdF77Ef6F7FkToolIdInput>;
};

/** The fields on `toolTenant` to look up the row to update. */
export type ToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingToolTenantsPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolTenant` being updated. */
    toolTenantPatch: UpdateToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdPatch;
  };

/** The fields on `toolTenant` to look up the row to update. */
export type ToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingToolTenantsToolIdTenantId1Ab854FcUniqUpdate =
  {
    tenantId: Scalars["Int"]["input"];
    toolId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolTenant` being updated. */
    toolTenantPatch: UpdateToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdPatch;
  };

/** The fields on `toolTenant` to look up the row to update. */
export type ToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolTenantsPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolTenant` being updated. */
    toolTenantPatch: UpdateToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdPatch;
  };

/** The fields on `toolTenant` to look up the row to update. */
export type ToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolTenantsToolIdTenantId1Ab854FcUniqUpdate =
  {
    tenantId: Scalars["Int"]["input"];
    toolId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolTenant` being updated. */
    toolTenantPatch: UpdateToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdPatch;
  };

/** Represents an update to a `ToolTenant`. Fields that are set will be updated. */
export type ToolTenantPatch = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInput>;
  toolId?: InputMaybe<Scalars["Int"]["input"]>;
  toolToToolId?: InputMaybe<ToolTenantsToolIdF77Ef6F7FkToolIdInput>;
};

/** The fields on `toolTenant` to look up the row to connect. */
export type ToolTenantToolTenantsPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolTenant` to look up the row to delete. */
export type ToolTenantToolTenantsPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolTenant` to look up the row to connect. */
export type ToolTenantToolTenantsToolIdTenantId1Ab854FcUniqConnect = {
  tenantId: Scalars["Int"]["input"];
  toolId: Scalars["Int"]["input"];
};

/** The fields on `toolTenant` to look up the row to delete. */
export type ToolTenantToolTenantsToolIdTenantId1Ab854FcUniqDelete = {
  tenantId: Scalars["Int"]["input"];
  toolId: Scalars["Int"]["input"];
};

/** A connection to a list of `ToolTenant` values. */
export type ToolTenantsConnection = {
  __typename: "ToolTenantsConnection";
  /** A list of edges which contains the `ToolTenant` and cursor to aid in pagination. */
  edges: Array<ToolTenantsEdge>;
  /** A list of `ToolTenant` objects. */
  nodes: Array<ToolTenant>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ToolTenant` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ToolTenant` edge in the connection. */
export type ToolTenantsEdge = {
  __typename: "ToolTenantsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ToolTenant` at the end of the edge. */
  node: ToolTenant;
};

/** Methods to use when ordering `ToolTenant`. */
export enum ToolTenantsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  ToolIdAsc = "TOOL_ID_ASC",
  ToolIdDesc = "TOOL_ID_DESC",
}

/** Input for the nested mutation of `tenant` in the `ToolTenantInput` mutation. */
export type ToolTenantsTenantId9C4A54FeFkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `toolTenant` in the `TenantInput` mutation. */
export type ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput = {
  /** The primary key(s) for `toolTenant` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ToolTenantToolTenantsPkeyConnect>>;
  /** The primary key(s) for `toolTenant` for the far side of the relationship. */
  connectByToolIdAndTenantId?: InputMaybe<
    Array<ToolTenantToolTenantsToolIdTenantId1Ab854FcUniqConnect>
  >;
  /** A `ToolTenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ToolTenantsTenantId9C4A54FeFkTenantIdToolTenantsCreateInput>
  >;
  /** The primary key(s) for `toolTenant` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ToolTenantToolTenantsPkeyDelete>>;
  /** The primary key(s) for `toolTenant` for the far side of the relationship. */
  deleteByToolIdAndTenantId?: InputMaybe<
    Array<ToolTenantToolTenantsToolIdTenantId1Ab854FcUniqDelete>
  >;
  /** Flag indicating whether all other `toolTenant` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `toolTenant` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingToolTenantsPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `toolTenant` for the far side of the relationship. */
  updateByToolIdAndTenantId?: InputMaybe<
    Array<ToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingToolTenantsToolIdTenantId1Ab854FcUniqUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type ToolTenantsTenantId9C4A54FeFkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** The `toolTenant` to be created by this mutation. */
export type ToolTenantsTenantId9C4A54FeFkTenantIdToolTenantsCreateInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInput>;
  toolId?: InputMaybe<Scalars["Int"]["input"]>;
  toolToToolId?: InputMaybe<ToolTenantsToolIdF77Ef6F7FkToolIdInput>;
};

/** Input for the nested mutation of `tool` in the `ToolTenantInput` mutation. */
export type ToolTenantsToolIdF77Ef6F7FkToolIdInput = {
  /** The primary key(s) for `tool` for the far side of the relationship. */
  connectById?: InputMaybe<ToolToolPkeyConnect>;
  /** The primary key(s) for `tool` for the far side of the relationship. */
  connectByKey?: InputMaybe<ToolToolKeyKeyConnect>;
  /** A `ToolInput` object that will be created and connected to this object. */
  create?: InputMaybe<ToolTenantsToolIdF77Ef6F7FkToolIdToolCreateInput>;
  /** The primary key(s) for `tool` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolToolPkeyDelete>;
  /** The primary key(s) for `tool` for the far side of the relationship. */
  deleteByKey?: InputMaybe<ToolToolKeyKeyDelete>;
  /** The primary key(s) and patch data for `tool` for the far side of the relationship. */
  updateById?: InputMaybe<ToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolPkeyUpdate>;
  /** The primary key(s) and patch data for `tool` for the far side of the relationship. */
  updateByKey?: InputMaybe<ToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolKeyKeyUpdate>;
};

/** Input for the nested mutation of `toolTenant` in the `ToolInput` mutation. */
export type ToolTenantsToolIdF77Ef6F7FkToolIdInverseInput = {
  /** The primary key(s) for `toolTenant` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ToolTenantToolTenantsPkeyConnect>>;
  /** The primary key(s) for `toolTenant` for the far side of the relationship. */
  connectByToolIdAndTenantId?: InputMaybe<
    Array<ToolTenantToolTenantsToolIdTenantId1Ab854FcUniqConnect>
  >;
  /** A `ToolTenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ToolTenantsToolIdF77Ef6F7FkToolIdToolTenantsCreateInput>
  >;
  /** The primary key(s) for `toolTenant` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ToolTenantToolTenantsPkeyDelete>>;
  /** The primary key(s) for `toolTenant` for the far side of the relationship. */
  deleteByToolIdAndTenantId?: InputMaybe<
    Array<ToolTenantToolTenantsToolIdTenantId1Ab854FcUniqDelete>
  >;
  /** Flag indicating whether all other `toolTenant` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `toolTenant` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolTenantsPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `toolTenant` for the far side of the relationship. */
  updateByToolIdAndTenantId?: InputMaybe<
    Array<ToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolTenantsToolIdTenantId1Ab854FcUniqUpdate>
  >;
};

/** The `tool` to be created by this mutation. */
export type ToolTenantsToolIdF77Ef6F7FkToolIdToolCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  helpText: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  searchTerms?: InputMaybe<Scalars["JSON"]["input"]>;
  toolDisplayCategoriesUsingId?: InputMaybe<ToolDisplayCategoriesToolIdAdc582D3FkToolIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsToolIdF77Ef6F7FkToolIdInverseInput>;
  toolVersionsUsingId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInverseInput>;
};

/** The `toolTenant` to be created by this mutation. */
export type ToolTenantsToolIdF77Ef6F7FkToolIdToolTenantsCreateInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInput>;
  toolToToolId?: InputMaybe<ToolTenantsToolIdF77Ef6F7FkToolIdInput>;
};

/** A filter to be used against many `AnalysisTableGroup` object types. All fields are combined with a logical ‘and.’ */
export type ToolToManyAnalysisTableGroupFilter = {
  /** Every related `AnalysisTableGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AnalysisTableGroupFilter>;
  /** No related `AnalysisTableGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AnalysisTableGroupFilter>;
  /** Some related `AnalysisTableGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AnalysisTableGroupFilter>;
};

/** A filter to be used against many `ToolDisplayCategory` object types. All fields are combined with a logical ‘and.’ */
export type ToolToManyToolDisplayCategoryFilter = {
  /** Every related `ToolDisplayCategory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ToolDisplayCategoryFilter>;
  /** No related `ToolDisplayCategory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ToolDisplayCategoryFilter>;
  /** Some related `ToolDisplayCategory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ToolDisplayCategoryFilter>;
};

/** A filter to be used against many `ToolTenant` object types. All fields are combined with a logical ‘and.’ */
export type ToolToManyToolTenantFilter = {
  /** Every related `ToolTenant` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ToolTenantFilter>;
  /** No related `ToolTenant` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ToolTenantFilter>;
  /** Some related `ToolTenant` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ToolTenantFilter>;
};

/** A filter to be used against many `ToolVersion` object types. All fields are combined with a logical ‘and.’ */
export type ToolToManyToolVersionFilter = {
  /** Every related `ToolVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ToolVersionFilter>;
  /** No related `ToolVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ToolVersionFilter>;
  /** Some related `ToolVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ToolVersionFilter>;
};

/** The fields on `tool` to look up the row to connect. */
export type ToolToolKeyKeyConnect = {
  key: Scalars["String"]["input"];
};

/** The fields on `tool` to look up the row to delete. */
export type ToolToolKeyKeyDelete = {
  key: Scalars["String"]["input"];
};

/** The fields on `tool` to look up the row to connect. */
export type ToolToolPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `tool` to look up the row to delete. */
export type ToolToolPkeyDelete = {
  id: Scalars["Int"]["input"];
};

export type ToolVersion = {
  __typename: "ToolVersion";
  /** Reads and enables pagination through a set of `AnalysisTableRow`. */
  analysisTableRowsByToolVersionId: AnalysisTableRowsConnection;
  /** Reads and enables pagination through a set of `AnalysisTable`. */
  analysisTablesByToolVersionId: AnalysisTablesConnection;
  command: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `ToolVersion`. */
  compatibleVersions: ToolVersionsConnection;
  created: Scalars["Datetime"]["output"];
  credits: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["Int"]["output"];
  isToolSpecForwardsCompatible: Scalars["Boolean"]["output"];
  isUpgradableToNextVersion: Scalars["Boolean"]["output"];
  latestToolboxVersion: Maybe<ToolboxVersion>;
  maturity: Scalars["Int"]["output"];
  /** Reads and enables pagination through a set of `Task`. */
  tasksByToolVersionId: TasksConnection;
  /** Reads a single `Tool` that is related to this `ToolVersion`. */
  toolByToolId: Maybe<Tool>;
  toolId: Scalars["Int"]["output"];
  /** Reads and enables pagination through a set of `ToolParameter`. */
  toolParametersByToolVersionId: ToolParametersConnection;
  /** Reads and enables pagination through a set of `ToolResource`. */
  toolResourcesByToolVersionId: ToolResourcesConnection;
  /** Reads and enables pagination through a set of `ToolResult`. */
  toolResultsByToolVersionId: ToolResultsConnection;
  /** Reads and enables pagination through a set of `ToolSecret`. */
  toolSecretsByToolVersionId: ToolSecretsConnection;
  /** Reads and enables pagination through a set of `ToolSource`. */
  toolSourcesByToolVersionId: ToolSourcesConnection;
  toolSpec: Maybe<Scalars["JSON"]["output"]>;
  /** Reads and enables pagination through a set of `ToolboxVersionToolVersion`. */
  toolboxVersionToolVersionsByToolversionId: ToolboxVersionToolVersionsConnection;
  version: Scalars["String"]["output"];
  visibility: Scalars["Int"]["output"];
  /** Reads and enables pagination through a set of `WorkflowSectionBlock`. */
  workflowSectionBlocksByToolId: WorkflowSectionBlocksConnection;
};

export type ToolVersionAnalysisTableRowsByToolVersionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableRowCondition>;
  filter?: InputMaybe<AnalysisTableRowFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTableRowsOrderBy>>;
};

export type ToolVersionAnalysisTablesByToolVersionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AnalysisTableCondition>;
  filter?: InputMaybe<AnalysisTableFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AnalysisTablesOrderBy>>;
};

export type ToolVersionCompatibleVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ToolVersionTasksByToolVersionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TaskCondition>;
  filter?: InputMaybe<TaskFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
};

export type ToolVersionToolParametersByToolVersionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolParameterCondition>;
  filter?: InputMaybe<ToolParameterFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolParametersOrderBy>>;
};

export type ToolVersionToolResourcesByToolVersionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolResourceCondition>;
  filter?: InputMaybe<ToolResourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolResourcesOrderBy>>;
};

export type ToolVersionToolResultsByToolVersionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolResultCondition>;
  filter?: InputMaybe<ToolResultFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolResultsOrderBy>>;
};

export type ToolVersionToolSecretsByToolVersionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolSecretCondition>;
  filter?: InputMaybe<ToolSecretFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolSecretsOrderBy>>;
};

export type ToolVersionToolSourcesByToolVersionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolSourceCondition>;
  filter?: InputMaybe<ToolSourceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolSourcesOrderBy>>;
};

export type ToolVersionToolboxVersionToolVersionsByToolversionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxVersionToolVersionCondition>;
  filter?: InputMaybe<ToolboxVersionToolVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxVersionToolVersionsOrderBy>>;
};

export type ToolVersionWorkflowSectionBlocksByToolIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSectionBlockCondition>;
  filter?: InputMaybe<WorkflowSectionBlockFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSectionBlocksOrderBy>>;
};

/**
 * A condition to be used against `ToolVersion` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ToolVersionCondition = {
  /** Checks for equality with the object’s `command` field. */
  command?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `created` field. */
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `credits` field. */
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `isToolSpecForwardsCompatible` field. */
  isToolSpecForwardsCompatible?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isUpgradableToNextVersion` field. */
  isUpgradableToNextVersion?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `maturity` field. */
  maturity?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `toolId` field. */
  toolId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `toolSpec` field. */
  toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `visibility` field. */
  visibility?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `ToolVersion` object types. All fields are combined with a logical ‘and.’ */
export type ToolVersionFilter = {
  /** Filter by the object’s `analysisTableRowsByToolVersionId` relation. */
  analysisTableRowsByToolVersionId?: InputMaybe<ToolVersionToManyAnalysisTableRowFilter>;
  /** Some related `analysisTableRowsByToolVersionId` exist. */
  analysisTableRowsByToolVersionIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `analysisTablesByToolVersionId` relation. */
  analysisTablesByToolVersionId?: InputMaybe<ToolVersionToManyAnalysisTableFilter>;
  /** Some related `analysisTablesByToolVersionId` exist. */
  analysisTablesByToolVersionIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ToolVersionFilter>>;
  /** Filter by the object’s `command` field. */
  command?: InputMaybe<StringFilter>;
  /** Filter by the object’s `created` field. */
  created?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `credits` field. */
  credits?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isToolSpecForwardsCompatible` field. */
  isToolSpecForwardsCompatible?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isUpgradableToNextVersion` field. */
  isUpgradableToNextVersion?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `maturity` field. */
  maturity?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ToolVersionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ToolVersionFilter>>;
  /** Filter by the object’s `tasksByToolVersionId` relation. */
  tasksByToolVersionId?: InputMaybe<ToolVersionToManyTaskFilter>;
  /** Some related `tasksByToolVersionId` exist. */
  tasksByToolVersionIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `toolByToolId` relation. */
  toolByToolId?: InputMaybe<ToolFilter>;
  /** Filter by the object’s `toolId` field. */
  toolId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `toolParametersByToolVersionId` relation. */
  toolParametersByToolVersionId?: InputMaybe<ToolVersionToManyToolParameterFilter>;
  /** Some related `toolParametersByToolVersionId` exist. */
  toolParametersByToolVersionIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `toolResourcesByToolVersionId` relation. */
  toolResourcesByToolVersionId?: InputMaybe<ToolVersionToManyToolResourceFilter>;
  /** Some related `toolResourcesByToolVersionId` exist. */
  toolResourcesByToolVersionIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `toolResultsByToolVersionId` relation. */
  toolResultsByToolVersionId?: InputMaybe<ToolVersionToManyToolResultFilter>;
  /** Some related `toolResultsByToolVersionId` exist. */
  toolResultsByToolVersionIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `toolSecretsByToolVersionId` relation. */
  toolSecretsByToolVersionId?: InputMaybe<ToolVersionToManyToolSecretFilter>;
  /** Some related `toolSecretsByToolVersionId` exist. */
  toolSecretsByToolVersionIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `toolSourcesByToolVersionId` relation. */
  toolSourcesByToolVersionId?: InputMaybe<ToolVersionToManyToolSourceFilter>;
  /** Some related `toolSourcesByToolVersionId` exist. */
  toolSourcesByToolVersionIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `toolSpec` field. */
  toolSpec?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `toolboxVersionToolVersionsByToolversionId` relation. */
  toolboxVersionToolVersionsByToolversionId?: InputMaybe<ToolVersionToManyToolboxVersionToolVersionFilter>;
  /** Some related `toolboxVersionToolVersionsByToolversionId` exist. */
  toolboxVersionToolVersionsByToolversionIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<StringFilter>;
  /** Filter by the object’s `visibility` field. */
  visibility?: InputMaybe<IntFilter>;
  /** Filter by the object’s `workflowSectionBlocksByToolId` relation. */
  workflowSectionBlocksByToolId?: InputMaybe<ToolVersionToManyWorkflowSectionBlockFilter>;
  /** Some related `workflowSectionBlocksByToolId` exist. */
  workflowSectionBlocksByToolIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** An input for mutations affecting `ToolVersion` */
export type ToolVersionInput = {
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
  command: Scalars["String"]["input"];
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  isToolSpecForwardsCompatible: Scalars["Boolean"]["input"];
  isUpgradableToNextVersion: Scalars["Boolean"]["input"];
  maturity: Scalars["Int"]["input"];
  tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
  toolId?: InputMaybe<Scalars["Int"]["input"]>;
  toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
  toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
  toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
  toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
  toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
  toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
  toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
  toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
  version: Scalars["String"]["input"];
  visibility: Scalars["Int"]["input"];
  workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
};

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdUsingToolVersionPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdPatch;
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdUsingToolversionToolVersionUniqueUpdate =
  {
    toolId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdPatch;
    version: Scalars["String"]["input"];
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingToolVersionPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdPatch;
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingToolversionToolVersionUniqueUpdate =
  {
    toolId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdPatch;
    version: Scalars["String"]["input"];
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingToolVersionPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdPatch;
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingToolversionToolVersionUniqueUpdate =
  {
    toolId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdPatch;
    version: Scalars["String"]["input"];
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdUsingToolVersionPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdPatch;
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdUsingToolversionToolVersionUniqueUpdate =
  {
    toolId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdPatch;
    version: Scalars["String"]["input"];
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingToolVersionPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdPatch;
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingToolversionToolVersionUniqueUpdate =
  {
    toolId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdPatch;
    version: Scalars["String"]["input"];
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdUsingToolVersionPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdPatch;
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdUsingToolversionToolVersionUniqueUpdate =
  {
    toolId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdPatch;
    version: Scalars["String"]["input"];
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdUsingToolVersionPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdPatch;
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdUsingToolversionToolVersionUniqueUpdate =
  {
    toolId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdPatch;
    version: Scalars["String"]["input"];
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdUsingToolVersionPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdPatch;
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdUsingToolversionToolVersionUniqueUpdate =
  {
    toolId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdPatch;
    version: Scalars["String"]["input"];
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolVersionPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdPatch;
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolversionToolVersionUniqueUpdate =
  {
    toolId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdPatch;
    version: Scalars["String"]["input"];
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolVersionPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersPatch;
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolversionToolVersionUniqueUpdate =
  {
    toolId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersPatch;
    version: Scalars["String"]["input"];
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdUsingToolVersionPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdPatch;
  };

/** The fields on `toolVersion` to look up the row to update. */
export type ToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdUsingToolversionToolVersionUniqueUpdate =
  {
    toolId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolVersion` being updated. */
    toolVersionPatch: UpdateToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdPatch;
    version: Scalars["String"]["input"];
  };

/** Represents an update to a `ToolVersion`. Fields that are set will be updated. */
export type ToolVersionPatch = {
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
  command?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  isToolSpecForwardsCompatible?: InputMaybe<Scalars["Boolean"]["input"]>;
  isUpgradableToNextVersion?: InputMaybe<Scalars["Boolean"]["input"]>;
  maturity?: InputMaybe<Scalars["Int"]["input"]>;
  tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
  toolId?: InputMaybe<Scalars["Int"]["input"]>;
  toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
  toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
  toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
  toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
  toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
  toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
  toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
  toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
  version?: InputMaybe<Scalars["String"]["input"]>;
  visibility?: InputMaybe<Scalars["Int"]["input"]>;
  workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
};

/** A filter to be used against many `AnalysisTable` object types. All fields are combined with a logical ‘and.’ */
export type ToolVersionToManyAnalysisTableFilter = {
  /** Every related `AnalysisTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AnalysisTableFilter>;
  /** No related `AnalysisTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AnalysisTableFilter>;
  /** Some related `AnalysisTable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AnalysisTableFilter>;
};

/** A filter to be used against many `AnalysisTableRow` object types. All fields are combined with a logical ‘and.’ */
export type ToolVersionToManyAnalysisTableRowFilter = {
  /** Every related `AnalysisTableRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AnalysisTableRowFilter>;
  /** No related `AnalysisTableRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AnalysisTableRowFilter>;
  /** Some related `AnalysisTableRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AnalysisTableRowFilter>;
};

/** A filter to be used against many `Task` object types. All fields are combined with a logical ‘and.’ */
export type ToolVersionToManyTaskFilter = {
  /** Every related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TaskFilter>;
  /** No related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TaskFilter>;
  /** Some related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TaskFilter>;
};

/** A filter to be used against many `ToolParameter` object types. All fields are combined with a logical ‘and.’ */
export type ToolVersionToManyToolParameterFilter = {
  /** Every related `ToolParameter` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ToolParameterFilter>;
  /** No related `ToolParameter` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ToolParameterFilter>;
  /** Some related `ToolParameter` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ToolParameterFilter>;
};

/** A filter to be used against many `ToolResource` object types. All fields are combined with a logical ‘and.’ */
export type ToolVersionToManyToolResourceFilter = {
  /** Every related `ToolResource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ToolResourceFilter>;
  /** No related `ToolResource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ToolResourceFilter>;
  /** Some related `ToolResource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ToolResourceFilter>;
};

/** A filter to be used against many `ToolResult` object types. All fields are combined with a logical ‘and.’ */
export type ToolVersionToManyToolResultFilter = {
  /** Every related `ToolResult` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ToolResultFilter>;
  /** No related `ToolResult` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ToolResultFilter>;
  /** Some related `ToolResult` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ToolResultFilter>;
};

/** A filter to be used against many `ToolSecret` object types. All fields are combined with a logical ‘and.’ */
export type ToolVersionToManyToolSecretFilter = {
  /** Every related `ToolSecret` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ToolSecretFilter>;
  /** No related `ToolSecret` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ToolSecretFilter>;
  /** Some related `ToolSecret` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ToolSecretFilter>;
};

/** A filter to be used against many `ToolSource` object types. All fields are combined with a logical ‘and.’ */
export type ToolVersionToManyToolSourceFilter = {
  /** Every related `ToolSource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ToolSourceFilter>;
  /** No related `ToolSource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ToolSourceFilter>;
  /** Some related `ToolSource` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ToolSourceFilter>;
};

/** A filter to be used against many `ToolboxVersionToolVersion` object types. All fields are combined with a logical ‘and.’ */
export type ToolVersionToManyToolboxVersionToolVersionFilter = {
  /** Every related `ToolboxVersionToolVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ToolboxVersionToolVersionFilter>;
  /** No related `ToolboxVersionToolVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ToolboxVersionToolVersionFilter>;
  /** Some related `ToolboxVersionToolVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ToolboxVersionToolVersionFilter>;
};

/** A filter to be used against many `WorkflowSectionBlock` object types. All fields are combined with a logical ‘and.’ */
export type ToolVersionToManyWorkflowSectionBlockFilter = {
  /** Every related `WorkflowSectionBlock` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowSectionBlockFilter>;
  /** No related `WorkflowSectionBlock` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowSectionBlockFilter>;
  /** Some related `WorkflowSectionBlock` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowSectionBlockFilter>;
};

/** Input for the nested mutation of `tool` in the `ToolVersionInput` mutation. */
export type ToolVersionToolId3B396B22FkToolIdInput = {
  /** The primary key(s) for `tool` for the far side of the relationship. */
  connectById?: InputMaybe<ToolToolPkeyConnect>;
  /** The primary key(s) for `tool` for the far side of the relationship. */
  connectByKey?: InputMaybe<ToolToolKeyKeyConnect>;
  /** A `ToolInput` object that will be created and connected to this object. */
  create?: InputMaybe<ToolVersionToolId3B396B22FkToolIdToolCreateInput>;
  /** The primary key(s) for `tool` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolToolPkeyDelete>;
  /** The primary key(s) for `tool` for the far side of the relationship. */
  deleteByKey?: InputMaybe<ToolToolKeyKeyDelete>;
  /** The primary key(s) and patch data for `tool` for the far side of the relationship. */
  updateById?: InputMaybe<ToolOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolPkeyUpdate>;
  /** The primary key(s) and patch data for `tool` for the far side of the relationship. */
  updateByKey?: InputMaybe<ToolOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolKeyKeyUpdate>;
};

/** Input for the nested mutation of `toolVersion` in the `ToolInput` mutation. */
export type ToolVersionToolId3B396B22FkToolIdInverseInput = {
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ToolVersionToolVersionPkeyConnect>>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectByToolIdAndVersion?: InputMaybe<
    Array<ToolVersionToolversionToolVersionUniqueConnect>
  >;
  /** A `ToolVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ToolVersionToolId3B396B22FkToolIdToolVersionCreateInput>
  >;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ToolVersionToolVersionPkeyDelete>>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteByToolIdAndVersion?: InputMaybe<
    Array<ToolVersionToolversionToolVersionUniqueDelete>
  >;
  /** Flag indicating whether all other `toolVersion` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolVersionPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateByToolIdAndVersion?: InputMaybe<
    Array<ToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolversionToolVersionUniqueUpdate>
  >;
};

/** The `tool` to be created by this mutation. */
export type ToolVersionToolId3B396B22FkToolIdToolCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  helpText: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  searchTerms?: InputMaybe<Scalars["JSON"]["input"]>;
  toolDisplayCategoriesUsingId?: InputMaybe<ToolDisplayCategoriesToolIdAdc582D3FkToolIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsToolIdF77Ef6F7FkToolIdInverseInput>;
  toolVersionsUsingId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInverseInput>;
};

/** The `toolVersion` to be created by this mutation. */
export type ToolVersionToolId3B396B22FkToolIdToolVersionCreateInput = {
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
  command: Scalars["String"]["input"];
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  isToolSpecForwardsCompatible: Scalars["Boolean"]["input"];
  isUpgradableToNextVersion: Scalars["Boolean"]["input"];
  maturity: Scalars["Int"]["input"];
  tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
  toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
  toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
  toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
  toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
  toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
  toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
  toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
  toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
  version: Scalars["String"]["input"];
  visibility: Scalars["Int"]["input"];
  workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
};

/** The fields on `toolVersion` to look up the row to connect. */
export type ToolVersionToolVersionPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolVersion` to look up the row to delete. */
export type ToolVersionToolVersionPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolVersion` to look up the row to connect. */
export type ToolVersionToolversionToolVersionUniqueConnect = {
  toolId: Scalars["Int"]["input"];
  version: Scalars["String"]["input"];
};

/** The fields on `toolVersion` to look up the row to delete. */
export type ToolVersionToolversionToolVersionUniqueDelete = {
  toolId: Scalars["Int"]["input"];
  version: Scalars["String"]["input"];
};

/** A connection to a list of `ToolVersion` values. */
export type ToolVersionsConnection = {
  __typename: "ToolVersionsConnection";
  /** A list of edges which contains the `ToolVersion` and cursor to aid in pagination. */
  edges: Array<ToolVersionsEdge>;
  /** A list of `ToolVersion` objects. */
  nodes: Array<ToolVersion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ToolVersion` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ToolVersion` edge in the connection. */
export type ToolVersionsEdge = {
  __typename: "ToolVersionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ToolVersion` at the end of the edge. */
  node: ToolVersion;
};

/** Methods to use when ordering `ToolVersion`. */
export enum ToolVersionsOrderBy {
  CommandAsc = "COMMAND_ASC",
  CommandDesc = "COMMAND_DESC",
  CreatedAsc = "CREATED_ASC",
  CreatedDesc = "CREATED_DESC",
  CreditsAsc = "CREDITS_ASC",
  CreditsDesc = "CREDITS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsToolSpecForwardsCompatibleAsc = "IS_TOOL_SPEC_FORWARDS_COMPATIBLE_ASC",
  IsToolSpecForwardsCompatibleDesc = "IS_TOOL_SPEC_FORWARDS_COMPATIBLE_DESC",
  IsUpgradableToNextVersionAsc = "IS_UPGRADABLE_TO_NEXT_VERSION_ASC",
  IsUpgradableToNextVersionDesc = "IS_UPGRADABLE_TO_NEXT_VERSION_DESC",
  MaturityAsc = "MATURITY_ASC",
  MaturityDesc = "MATURITY_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ToolIdAsc = "TOOL_ID_ASC",
  ToolIdDesc = "TOOL_ID_DESC",
  ToolSpecAsc = "TOOL_SPEC_ASC",
  ToolSpecDesc = "TOOL_SPEC_DESC",
  VersionAsc = "VERSION_ASC",
  VersionDesc = "VERSION_DESC",
  VisibilityAsc = "VISIBILITY_ASC",
  VisibilityDesc = "VISIBILITY_DESC",
}

export type Toolbox = {
  __typename: "Toolbox";
  created: Scalars["Datetime"]["output"];
  id: Scalars["Int"]["output"];
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `ToolboxTenant`. */
  toolboxTenantsByToolboxId: ToolboxTenantsConnection;
  /** Reads and enables pagination through a set of `ToolboxVersion`. */
  toolboxVersionsByToolboxId: ToolboxVersionsConnection;
};

export type ToolboxToolboxTenantsByToolboxIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxTenantCondition>;
  filter?: InputMaybe<ToolboxTenantFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxTenantsOrderBy>>;
};

export type ToolboxToolboxVersionsByToolboxIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxVersionCondition>;
  filter?: InputMaybe<ToolboxVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxVersionsOrderBy>>;
};

/** A condition to be used against `Toolbox` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ToolboxCondition = {
  /** Checks for equality with the object’s `created` field. */
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type ToolboxExitcode = {
  __typename: "ToolboxExitcode";
  code: Scalars["String"]["output"];
  created: Scalars["Datetime"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  /** Reads and enables pagination through a set of `ToolboxVersionExitcode`. */
  toolboxVersionExitcodesByToolboxexitcodeId: ToolboxVersionExitcodesConnection;
  type: Scalars["Int"]["output"];
};

export type ToolboxExitcodeToolboxVersionExitcodesByToolboxexitcodeIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxVersionExitcodeCondition>;
  filter?: InputMaybe<ToolboxVersionExitcodeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxVersionExitcodesOrderBy>>;
};

/**
 * A condition to be used against `ToolboxExitcode` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ToolboxExitcodeCondition = {
  /** Checks for equality with the object’s `code` field. */
  code?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `created` field. */
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `ToolboxExitcode` object types. All fields are combined with a logical ‘and.’ */
export type ToolboxExitcodeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ToolboxExitcodeFilter>>;
  /** Filter by the object’s `code` field. */
  code?: InputMaybe<StringFilter>;
  /** Filter by the object’s `created` field. */
  created?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ToolboxExitcodeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ToolboxExitcodeFilter>>;
  /** Filter by the object’s `toolboxVersionExitcodesByToolboxexitcodeId` relation. */
  toolboxVersionExitcodesByToolboxexitcodeId?: InputMaybe<ToolboxExitcodeToManyToolboxVersionExitcodeFilter>;
  /** Some related `toolboxVersionExitcodesByToolboxexitcodeId` exist. */
  toolboxVersionExitcodesByToolboxexitcodeIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ToolboxExitcode` */
export type ToolboxExitcodeInput = {
  code: Scalars["String"]["input"];
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  description: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  toolboxVersionExitcodesUsingId?: InputMaybe<ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInverseInput>;
  type: Scalars["Int"]["input"];
};

/** The fields on `toolboxExitcode` to look up the row to update. */
export type ToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxExitcodeCodeKeyUpdate =
  {
    code: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `toolboxExitcode` being updated. */
    toolboxExitcodePatch: UpdateToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEPatch;
  };

/** The fields on `toolboxExitcode` to look up the row to update. */
export type ToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxExitcodePkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolboxExitcode` being updated. */
    toolboxExitcodePatch: UpdateToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEPatch;
  };

/** Represents an update to a `ToolboxExitcode`. Fields that are set will be updated. */
export type ToolboxExitcodePatch = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  toolboxVersionExitcodesUsingId?: InputMaybe<ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInverseInput>;
  type?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against many `ToolboxVersionExitcode` object types. All fields are combined with a logical ‘and.’ */
export type ToolboxExitcodeToManyToolboxVersionExitcodeFilter = {
  /** Every related `ToolboxVersionExitcode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ToolboxVersionExitcodeFilter>;
  /** No related `ToolboxVersionExitcode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ToolboxVersionExitcodeFilter>;
  /** Some related `ToolboxVersionExitcode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ToolboxVersionExitcodeFilter>;
};

/** The fields on `toolboxExitcode` to look up the row to connect. */
export type ToolboxExitcodeToolboxExitcodeCodeKeyConnect = {
  code: Scalars["String"]["input"];
};

/** The fields on `toolboxExitcode` to look up the row to delete. */
export type ToolboxExitcodeToolboxExitcodeCodeKeyDelete = {
  code: Scalars["String"]["input"];
};

/** The fields on `toolboxExitcode` to look up the row to connect. */
export type ToolboxExitcodeToolboxExitcodePkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolboxExitcode` to look up the row to delete. */
export type ToolboxExitcodeToolboxExitcodePkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** A connection to a list of `ToolboxExitcode` values. */
export type ToolboxExitcodesConnection = {
  __typename: "ToolboxExitcodesConnection";
  /** A list of edges which contains the `ToolboxExitcode` and cursor to aid in pagination. */
  edges: Array<ToolboxExitcodesEdge>;
  /** A list of `ToolboxExitcode` objects. */
  nodes: Array<ToolboxExitcode>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ToolboxExitcode` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ToolboxExitcode` edge in the connection. */
export type ToolboxExitcodesEdge = {
  __typename: "ToolboxExitcodesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ToolboxExitcode` at the end of the edge. */
  node: ToolboxExitcode;
};

/** Methods to use when ordering `ToolboxExitcode`. */
export enum ToolboxExitcodesOrderBy {
  CodeAsc = "CODE_ASC",
  CodeDesc = "CODE_DESC",
  CreatedAsc = "CREATED_ASC",
  CreatedDesc = "CREATED_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
}

/** A filter to be used against `Toolbox` object types. All fields are combined with a logical ‘and.’ */
export type ToolboxFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ToolboxFilter>>;
  /** Filter by the object’s `created` field. */
  created?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ToolboxFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ToolboxFilter>>;
  /** Filter by the object’s `toolboxTenantsByToolboxId` relation. */
  toolboxTenantsByToolboxId?: InputMaybe<ToolboxToManyToolboxTenantFilter>;
  /** Some related `toolboxTenantsByToolboxId` exist. */
  toolboxTenantsByToolboxIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `toolboxVersionsByToolboxId` relation. */
  toolboxVersionsByToolboxId?: InputMaybe<ToolboxToManyToolboxVersionFilter>;
  /** Some related `toolboxVersionsByToolboxId` exist. */
  toolboxVersionsByToolboxIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** An input for mutations affecting `Toolbox` */
export type ToolboxInput = {
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsToolboxId9D553881FkToolboxIdInverseInput>;
  toolboxVersionsUsingId?: InputMaybe<ToolboxVersionToolboxId5Cc86066FkToolboxIdInverseInput>;
};

/** The fields on `toolbox` to look up the row to update. */
export type ToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `toolbox` being updated. */
    toolboxPatch: UpdateToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdPatch;
  };

/** The fields on `toolbox` to look up the row to update. */
export type ToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolbox` being updated. */
    toolboxPatch: UpdateToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdPatch;
  };

/** The fields on `toolbox` to look up the row to update. */
export type ToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxKeyKeyUpdate =
  {
    key: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `toolbox` being updated. */
    toolboxPatch: UpdateToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdPatch;
  };

/** The fields on `toolbox` to look up the row to update. */
export type ToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolbox` being updated. */
    toolboxPatch: UpdateToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdPatch;
  };

/** Represents an update to a `Toolbox`. Fields that are set will be updated. */
export type ToolboxPatch = {
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsToolboxId9D553881FkToolboxIdInverseInput>;
  toolboxVersionsUsingId?: InputMaybe<ToolboxVersionToolboxId5Cc86066FkToolboxIdInverseInput>;
};

export type ToolboxTenant = {
  __typename: "ToolboxTenant";
  id: Scalars["Int"]["output"];
  /** Reads a single `Tenant` that is related to this `ToolboxTenant`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  /** Reads a single `Toolbox` that is related to this `ToolboxTenant`. */
  toolboxByToolboxId: Maybe<Toolbox>;
  toolboxId: Scalars["Int"]["output"];
};

/**
 * A condition to be used against `ToolboxTenant` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ToolboxTenantCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `toolboxId` field. */
  toolboxId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `ToolboxTenant` object types. All fields are combined with a logical ‘and.’ */
export type ToolboxTenantFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ToolboxTenantFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ToolboxTenantFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ToolboxTenantFilter>>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `toolboxByToolboxId` relation. */
  toolboxByToolboxId?: InputMaybe<ToolboxFilter>;
  /** Filter by the object’s `toolboxId` field. */
  toolboxId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ToolboxTenant` */
export type ToolboxTenantInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInput>;
  toolboxId?: InputMaybe<Scalars["Int"]["input"]>;
  toolboxToToolboxId?: InputMaybe<ToolboxTenantsToolboxId9D553881FkToolboxIdInput>;
};

/** The fields on `toolboxTenant` to look up the row to update. */
export type ToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingToolboxTenantsPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolboxTenant` being updated. */
    toolboxTenantPatch: UpdateToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdPatch;
  };

/** The fields on `toolboxTenant` to look up the row to update. */
export type ToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingToolboxTenantsToolboxIdTenantId171F711CUniqUpdate =
  {
    tenantId: Scalars["Int"]["input"];
    toolboxId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolboxTenant` being updated. */
    toolboxTenantPatch: UpdateToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdPatch;
  };

/** The fields on `toolboxTenant` to look up the row to update. */
export type ToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxTenantsPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolboxTenant` being updated. */
    toolboxTenantPatch: UpdateToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdPatch;
  };

/** The fields on `toolboxTenant` to look up the row to update. */
export type ToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxTenantsToolboxIdTenantId171F711CUniqUpdate =
  {
    tenantId: Scalars["Int"]["input"];
    toolboxId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolboxTenant` being updated. */
    toolboxTenantPatch: UpdateToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdPatch;
  };

/** Represents an update to a `ToolboxTenant`. Fields that are set will be updated. */
export type ToolboxTenantPatch = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInput>;
  toolboxId?: InputMaybe<Scalars["Int"]["input"]>;
  toolboxToToolboxId?: InputMaybe<ToolboxTenantsToolboxId9D553881FkToolboxIdInput>;
};

/** The fields on `toolboxTenant` to look up the row to connect. */
export type ToolboxTenantToolboxTenantsPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolboxTenant` to look up the row to delete. */
export type ToolboxTenantToolboxTenantsPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolboxTenant` to look up the row to connect. */
export type ToolboxTenantToolboxTenantsToolboxIdTenantId171F711CUniqConnect = {
  tenantId: Scalars["Int"]["input"];
  toolboxId: Scalars["Int"]["input"];
};

/** The fields on `toolboxTenant` to look up the row to delete. */
export type ToolboxTenantToolboxTenantsToolboxIdTenantId171F711CUniqDelete = {
  tenantId: Scalars["Int"]["input"];
  toolboxId: Scalars["Int"]["input"];
};

/** A connection to a list of `ToolboxTenant` values. */
export type ToolboxTenantsConnection = {
  __typename: "ToolboxTenantsConnection";
  /** A list of edges which contains the `ToolboxTenant` and cursor to aid in pagination. */
  edges: Array<ToolboxTenantsEdge>;
  /** A list of `ToolboxTenant` objects. */
  nodes: Array<ToolboxTenant>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ToolboxTenant` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ToolboxTenant` edge in the connection. */
export type ToolboxTenantsEdge = {
  __typename: "ToolboxTenantsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ToolboxTenant` at the end of the edge. */
  node: ToolboxTenant;
};

/** Methods to use when ordering `ToolboxTenant`. */
export enum ToolboxTenantsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  ToolboxIdAsc = "TOOLBOX_ID_ASC",
  ToolboxIdDesc = "TOOLBOX_ID_DESC",
}

/** Input for the nested mutation of `tenant` in the `ToolboxTenantInput` mutation. */
export type ToolboxTenantsTenantId5D0A40E4FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `toolboxTenant` in the `TenantInput` mutation. */
export type ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput = {
  /** The primary key(s) for `toolboxTenant` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ToolboxTenantToolboxTenantsPkeyConnect>>;
  /** The primary key(s) for `toolboxTenant` for the far side of the relationship. */
  connectByToolboxIdAndTenantId?: InputMaybe<
    Array<ToolboxTenantToolboxTenantsToolboxIdTenantId171F711CUniqConnect>
  >;
  /** A `ToolboxTenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ToolboxTenantsTenantId5D0A40E4FkTenantIdToolboxTenantsCreateInput>
  >;
  /** The primary key(s) for `toolboxTenant` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ToolboxTenantToolboxTenantsPkeyDelete>>;
  /** The primary key(s) for `toolboxTenant` for the far side of the relationship. */
  deleteByToolboxIdAndTenantId?: InputMaybe<
    Array<ToolboxTenantToolboxTenantsToolboxIdTenantId171F711CUniqDelete>
  >;
  /** Flag indicating whether all other `toolboxTenant` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `toolboxTenant` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingToolboxTenantsPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `toolboxTenant` for the far side of the relationship. */
  updateByToolboxIdAndTenantId?: InputMaybe<
    Array<ToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingToolboxTenantsToolboxIdTenantId171F711CUniqUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type ToolboxTenantsTenantId5D0A40E4FkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** The `toolboxTenant` to be created by this mutation. */
export type ToolboxTenantsTenantId5D0A40E4FkTenantIdToolboxTenantsCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInput>;
    toolboxId?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxToToolboxId?: InputMaybe<ToolboxTenantsToolboxId9D553881FkToolboxIdInput>;
  };

/** Input for the nested mutation of `toolbox` in the `ToolboxTenantInput` mutation. */
export type ToolboxTenantsToolboxId9D553881FkToolboxIdInput = {
  /** The primary key(s) for `toolbox` for the far side of the relationship. */
  connectById?: InputMaybe<ToolboxToolboxPkeyConnect>;
  /** The primary key(s) for `toolbox` for the far side of the relationship. */
  connectByKey?: InputMaybe<ToolboxToolboxKeyKeyConnect>;
  /** A `ToolboxInput` object that will be created and connected to this object. */
  create?: InputMaybe<ToolboxTenantsToolboxId9D553881FkToolboxIdToolboxCreateInput>;
  /** The primary key(s) for `toolbox` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolboxToolboxPkeyDelete>;
  /** The primary key(s) for `toolbox` for the far side of the relationship. */
  deleteByKey?: InputMaybe<ToolboxToolboxKeyKeyDelete>;
  /** The primary key(s) and patch data for `toolbox` for the far side of the relationship. */
  updateById?: InputMaybe<ToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxPkeyUpdate>;
  /** The primary key(s) and patch data for `toolbox` for the far side of the relationship. */
  updateByKey?: InputMaybe<ToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxKeyKeyUpdate>;
};

/** Input for the nested mutation of `toolboxTenant` in the `ToolboxInput` mutation. */
export type ToolboxTenantsToolboxId9D553881FkToolboxIdInverseInput = {
  /** The primary key(s) for `toolboxTenant` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ToolboxTenantToolboxTenantsPkeyConnect>>;
  /** The primary key(s) for `toolboxTenant` for the far side of the relationship. */
  connectByToolboxIdAndTenantId?: InputMaybe<
    Array<ToolboxTenantToolboxTenantsToolboxIdTenantId171F711CUniqConnect>
  >;
  /** A `ToolboxTenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ToolboxTenantsToolboxId9D553881FkToolboxIdToolboxTenantsCreateInput>
  >;
  /** The primary key(s) for `toolboxTenant` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ToolboxTenantToolboxTenantsPkeyDelete>>;
  /** The primary key(s) for `toolboxTenant` for the far side of the relationship. */
  deleteByToolboxIdAndTenantId?: InputMaybe<
    Array<ToolboxTenantToolboxTenantsToolboxIdTenantId171F711CUniqDelete>
  >;
  /** Flag indicating whether all other `toolboxTenant` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `toolboxTenant` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxTenantsPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `toolboxTenant` for the far side of the relationship. */
  updateByToolboxIdAndTenantId?: InputMaybe<
    Array<ToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxTenantsToolboxIdTenantId171F711CUniqUpdate>
  >;
};

/** The `toolbox` to be created by this mutation. */
export type ToolboxTenantsToolboxId9D553881FkToolboxIdToolboxCreateInput = {
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsToolboxId9D553881FkToolboxIdInverseInput>;
  toolboxVersionsUsingId?: InputMaybe<ToolboxVersionToolboxId5Cc86066FkToolboxIdInverseInput>;
};

/** The `toolboxTenant` to be created by this mutation. */
export type ToolboxTenantsToolboxId9D553881FkToolboxIdToolboxTenantsCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInput>;
    toolboxToToolboxId?: InputMaybe<ToolboxTenantsToolboxId9D553881FkToolboxIdInput>;
  };

/** A filter to be used against many `ToolboxTenant` object types. All fields are combined with a logical ‘and.’ */
export type ToolboxToManyToolboxTenantFilter = {
  /** Every related `ToolboxTenant` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ToolboxTenantFilter>;
  /** No related `ToolboxTenant` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ToolboxTenantFilter>;
  /** Some related `ToolboxTenant` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ToolboxTenantFilter>;
};

/** A filter to be used against many `ToolboxVersion` object types. All fields are combined with a logical ‘and.’ */
export type ToolboxToManyToolboxVersionFilter = {
  /** Every related `ToolboxVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ToolboxVersionFilter>;
  /** No related `ToolboxVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ToolboxVersionFilter>;
  /** Some related `ToolboxVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ToolboxVersionFilter>;
};

/** The fields on `toolbox` to look up the row to connect. */
export type ToolboxToolboxKeyKeyConnect = {
  key: Scalars["String"]["input"];
};

/** The fields on `toolbox` to look up the row to delete. */
export type ToolboxToolboxKeyKeyDelete = {
  key: Scalars["String"]["input"];
};

/** The fields on `toolbox` to look up the row to connect. */
export type ToolboxToolboxPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolbox` to look up the row to delete. */
export type ToolboxToolboxPkeyDelete = {
  id: Scalars["Int"]["input"];
};

export type ToolboxVersion = {
  __typename: "ToolboxVersion";
  /** Reads a single `ContainerImage` that is related to this `ToolboxVersion`. */
  containerImageByToolboxVersionId: Maybe<ContainerImage>;
  /**
   * Reads and enables pagination through a set of `ContainerImage`.
   * @deprecated Please use containerImageByToolboxVersionId instead
   */
  containerImagesByToolboxVersionId: ContainerImagesConnection;
  created: Scalars["Datetime"]["output"];
  id: Scalars["Int"]["output"];
  maturity: Scalars["Int"]["output"];
  /** Reads a single `Toolbox` that is related to this `ToolboxVersion`. */
  toolboxByToolboxId: Maybe<Toolbox>;
  toolboxId: Scalars["Int"]["output"];
  toolboxSpec: Maybe<Scalars["JSON"]["output"]>;
  toolboxSpecV1: Maybe<Scalars["JSON"]["output"]>;
  /** Reads and enables pagination through a set of `ToolboxVersionExitcode`. */
  toolboxVersionExitcodesByToolboxversionId: ToolboxVersionExitcodesConnection;
  /** Reads and enables pagination through a set of `ToolboxVersionToolVersion`. */
  toolboxVersionToolVersionsByToolboxversionId: ToolboxVersionToolVersionsConnection;
  version: Scalars["String"]["output"];
};

export type ToolboxVersionContainerImagesByToolboxVersionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ContainerImageCondition>;
  filter?: InputMaybe<ContainerImageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ContainerImagesOrderBy>>;
};

export type ToolboxVersionToolboxVersionExitcodesByToolboxversionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxVersionExitcodeCondition>;
  filter?: InputMaybe<ToolboxVersionExitcodeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxVersionExitcodesOrderBy>>;
};

export type ToolboxVersionToolboxVersionToolVersionsByToolboxversionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ToolboxVersionToolVersionCondition>;
  filter?: InputMaybe<ToolboxVersionToolVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ToolboxVersionToolVersionsOrderBy>>;
};

/**
 * A condition to be used against `ToolboxVersion` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ToolboxVersionCondition = {
  /** Checks for equality with the object’s `created` field. */
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `maturity` field. */
  maturity?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `toolboxId` field. */
  toolboxId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `toolboxSpec` field. */
  toolboxSpec?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `toolboxSpecV1` field. */
  toolboxSpecV1?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars["String"]["input"]>;
};

/** Input for the nested mutation of `toolboxExitcode` in the `ToolboxVersionExitcodeInput` mutation. */
export type ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInput = {
  /** The primary key(s) for `toolboxExitcode` for the far side of the relationship. */
  connectByCode?: InputMaybe<ToolboxExitcodeToolboxExitcodeCodeKeyConnect>;
  /** The primary key(s) for `toolboxExitcode` for the far side of the relationship. */
  connectById?: InputMaybe<ToolboxExitcodeToolboxExitcodePkeyConnect>;
  /** A `ToolboxExitcodeInput` object that will be created and connected to this object. */
  create?: InputMaybe<ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEToolboxExitcodeCreateInput>;
  /** The primary key(s) for `toolboxExitcode` for the far side of the relationship. */
  deleteByCode?: InputMaybe<ToolboxExitcodeToolboxExitcodeCodeKeyDelete>;
  /** The primary key(s) for `toolboxExitcode` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolboxExitcodeToolboxExitcodePkeyDelete>;
  /** The primary key(s) and patch data for `toolboxExitcode` for the far side of the relationship. */
  updateByCode?: InputMaybe<ToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxExitcodeCodeKeyUpdate>;
  /** The primary key(s) and patch data for `toolboxExitcode` for the far side of the relationship. */
  updateById?: InputMaybe<ToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxExitcodePkeyUpdate>;
};

/** Input for the nested mutation of `toolboxVersionExitcode` in the `ToolboxExitcodeInput` mutation. */
export type ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInverseInput =
  {
    /** The primary key(s) for `toolboxVersionExitcode` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<ToolboxVersionExitcodeToolboxVersionExitcodesPkeyConnect>
    >;
    /** The primary key(s) for `toolboxVersionExitcode` for the far side of the relationship. */
    connectByToolboxversionIdAndToolboxexitcodeId?: InputMaybe<
      Array<ToolboxVersionExitcodeToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqConnect>
    >;
    /** A `ToolboxVersionExitcodeInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEToolboxVersionExitcodesCreateInput>
    >;
    /** The primary key(s) for `toolboxVersionExitcode` for the far side of the relationship. */
    deleteById?: InputMaybe<
      Array<ToolboxVersionExitcodeToolboxVersionExitcodesPkeyDelete>
    >;
    /** The primary key(s) for `toolboxVersionExitcode` for the far side of the relationship. */
    deleteByToolboxversionIdAndToolboxexitcodeId?: InputMaybe<
      Array<ToolboxVersionExitcodeToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqDelete>
    >;
    /** Flag indicating whether all other `toolboxVersionExitcode` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `toolboxVersionExitcode` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxVersionExitcodesPkeyUpdate>
    >;
    /** The primary key(s) and patch data for `toolboxVersionExitcode` for the far side of the relationship. */
    updateByToolboxversionIdAndToolboxexitcodeId?: InputMaybe<
      Array<ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqUpdate>
    >;
  };

/** The `toolboxExitcode` to be created by this mutation. */
export type ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEToolboxExitcodeCreateInput =
  {
    code: Scalars["String"]["input"];
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    description: Scalars["String"]["input"];
    id?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxVersionExitcodesUsingId?: InputMaybe<ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInverseInput>;
    type: Scalars["Int"]["input"];
  };

/** The `toolboxVersionExitcode` to be created by this mutation. */
export type ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEToolboxVersionExitcodesCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxExitcodeToToolboxexitcodeId?: InputMaybe<ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInput>;
    toolboxVersionToToolboxversionId?: InputMaybe<ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInput>;
    toolboxversionId?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** Input for the nested mutation of `toolboxVersion` in the `ToolboxVersionExitcodeInput` mutation. */
export type ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInput = {
  /** The primary key(s) for `toolboxVersion` for the far side of the relationship. */
  connectById?: InputMaybe<ToolboxVersionToolboxVersionPkeyConnect>;
  /** The primary key(s) for `toolboxVersion` for the far side of the relationship. */
  connectByToolboxIdAndVersion?: InputMaybe<ToolboxVersionToolboxversionToolboxVersionUniqueConnect>;
  /** A `ToolboxVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVToolboxVersionCreateInput>;
  /** The primary key(s) for `toolboxVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolboxVersionToolboxVersionPkeyDelete>;
  /** The primary key(s) for `toolboxVersion` for the far side of the relationship. */
  deleteByToolboxIdAndVersion?: InputMaybe<ToolboxVersionToolboxversionToolboxVersionUniqueDelete>;
  /** The primary key(s) and patch data for `toolboxVersion` for the far side of the relationship. */
  updateById?: InputMaybe<ToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxVersionPkeyUpdate>;
  /** The primary key(s) and patch data for `toolboxVersion` for the far side of the relationship. */
  updateByToolboxIdAndVersion?: InputMaybe<ToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxversionToolboxVersionUniqueUpdate>;
};

/** Input for the nested mutation of `toolboxVersionExitcode` in the `ToolboxVersionInput` mutation. */
export type ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInverseInput = {
  /** The primary key(s) for `toolboxVersionExitcode` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ToolboxVersionExitcodeToolboxVersionExitcodesPkeyConnect>
  >;
  /** The primary key(s) for `toolboxVersionExitcode` for the far side of the relationship. */
  connectByToolboxversionIdAndToolboxexitcodeId?: InputMaybe<
    Array<ToolboxVersionExitcodeToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqConnect>
  >;
  /** A `ToolboxVersionExitcodeInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVToolboxVersionExitcodesCreateInput>
  >;
  /** The primary key(s) for `toolboxVersionExitcode` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<ToolboxVersionExitcodeToolboxVersionExitcodesPkeyDelete>
  >;
  /** The primary key(s) for `toolboxVersionExitcode` for the far side of the relationship. */
  deleteByToolboxversionIdAndToolboxexitcodeId?: InputMaybe<
    Array<ToolboxVersionExitcodeToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqDelete>
  >;
  /** Flag indicating whether all other `toolboxVersionExitcode` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `toolboxVersionExitcode` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxVersionExitcodesPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `toolboxVersionExitcode` for the far side of the relationship. */
  updateByToolboxversionIdAndToolboxexitcodeId?: InputMaybe<
    Array<ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqUpdate>
  >;
};

/** The `toolboxVersion` to be created by this mutation. */
export type ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVToolboxVersionCreateInput =
  {
    containerImageUsingId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    maturity: Scalars["Int"]["input"];
    toolboxId?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolboxSpecV1?: InputMaybe<Scalars["JSON"]["input"]>;
    toolboxToToolboxId?: InputMaybe<ToolboxVersionToolboxId5Cc86066FkToolboxIdInput>;
    toolboxVersionExitcodesUsingId?: InputMaybe<ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInverseInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInverseInput>;
    version: Scalars["String"]["input"];
  };

/** The `toolboxVersionExitcode` to be created by this mutation. */
export type ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVToolboxVersionExitcodesCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxExitcodeToToolboxexitcodeId?: InputMaybe<ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInput>;
    toolboxVersionToToolboxversionId?: InputMaybe<ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInput>;
    toolboxexitcodeId?: InputMaybe<Scalars["Int"]["input"]>;
  };

export type ToolboxVersionExitcode = {
  __typename: "ToolboxVersionExitcode";
  id: Scalars["Int"]["output"];
  /** Reads a single `ToolboxExitcode` that is related to this `ToolboxVersionExitcode`. */
  toolboxExitcodeByToolboxexitcodeId: Maybe<ToolboxExitcode>;
  /** Reads a single `ToolboxVersion` that is related to this `ToolboxVersionExitcode`. */
  toolboxVersionByToolboxversionId: Maybe<ToolboxVersion>;
  toolboxexitcodeId: Scalars["Int"]["output"];
  toolboxversionId: Scalars["Int"]["output"];
};

/**
 * A condition to be used against `ToolboxVersionExitcode` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ToolboxVersionExitcodeCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `toolboxexitcodeId` field. */
  toolboxexitcodeId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `toolboxversionId` field. */
  toolboxversionId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `ToolboxVersionExitcode` object types. All fields are combined with a logical ‘and.’ */
export type ToolboxVersionExitcodeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ToolboxVersionExitcodeFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ToolboxVersionExitcodeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ToolboxVersionExitcodeFilter>>;
  /** Filter by the object’s `toolboxExitcodeByToolboxexitcodeId` relation. */
  toolboxExitcodeByToolboxexitcodeId?: InputMaybe<ToolboxExitcodeFilter>;
  /** Filter by the object’s `toolboxVersionByToolboxversionId` relation. */
  toolboxVersionByToolboxversionId?: InputMaybe<ToolboxVersionFilter>;
  /** Filter by the object’s `toolboxexitcodeId` field. */
  toolboxexitcodeId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `toolboxversionId` field. */
  toolboxversionId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ToolboxVersionExitcode` */
export type ToolboxVersionExitcodeInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  toolboxExitcodeToToolboxexitcodeId?: InputMaybe<ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInput>;
  toolboxVersionToToolboxversionId?: InputMaybe<ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInput>;
  toolboxexitcodeId?: InputMaybe<Scalars["Int"]["input"]>;
  toolboxversionId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `toolboxVersionExitcode` to look up the row to update. */
export type ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqUpdate =
  {
    /** An object where the defined keys will be set on the `toolboxVersionExitcode` being updated. */
    toolboxVersionExitcodePatch: UpdateToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEPatch;
    toolboxexitcodeId: Scalars["Int"]["input"];
    toolboxversionId: Scalars["Int"]["input"];
  };

/** The fields on `toolboxVersionExitcode` to look up the row to update. */
export type ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxVersionExitcodesPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolboxVersionExitcode` being updated. */
    toolboxVersionExitcodePatch: UpdateToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEPatch;
  };

/** The fields on `toolboxVersionExitcode` to look up the row to update. */
export type ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqUpdate =
  {
    /** An object where the defined keys will be set on the `toolboxVersionExitcode` being updated. */
    toolboxVersionExitcodePatch: UpdateToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVPatch;
    toolboxexitcodeId: Scalars["Int"]["input"];
    toolboxversionId: Scalars["Int"]["input"];
  };

/** The fields on `toolboxVersionExitcode` to look up the row to update. */
export type ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxVersionExitcodesPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolboxVersionExitcode` being updated. */
    toolboxVersionExitcodePatch: UpdateToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVPatch;
  };

/** Represents an update to a `ToolboxVersionExitcode`. Fields that are set will be updated. */
export type ToolboxVersionExitcodePatch = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  toolboxExitcodeToToolboxexitcodeId?: InputMaybe<ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInput>;
  toolboxVersionToToolboxversionId?: InputMaybe<ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInput>;
  toolboxexitcodeId?: InputMaybe<Scalars["Int"]["input"]>;
  toolboxversionId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `toolboxVersionExitcode` to look up the row to connect. */
export type ToolboxVersionExitcodeToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqConnect =
  {
    toolboxexitcodeId: Scalars["Int"]["input"];
    toolboxversionId: Scalars["Int"]["input"];
  };

/** The fields on `toolboxVersionExitcode` to look up the row to delete. */
export type ToolboxVersionExitcodeToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqDelete =
  {
    toolboxexitcodeId: Scalars["Int"]["input"];
    toolboxversionId: Scalars["Int"]["input"];
  };

/** The fields on `toolboxVersionExitcode` to look up the row to connect. */
export type ToolboxVersionExitcodeToolboxVersionExitcodesPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolboxVersionExitcode` to look up the row to delete. */
export type ToolboxVersionExitcodeToolboxVersionExitcodesPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** A connection to a list of `ToolboxVersionExitcode` values. */
export type ToolboxVersionExitcodesConnection = {
  __typename: "ToolboxVersionExitcodesConnection";
  /** A list of edges which contains the `ToolboxVersionExitcode` and cursor to aid in pagination. */
  edges: Array<ToolboxVersionExitcodesEdge>;
  /** A list of `ToolboxVersionExitcode` objects. */
  nodes: Array<ToolboxVersionExitcode>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ToolboxVersionExitcode` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ToolboxVersionExitcode` edge in the connection. */
export type ToolboxVersionExitcodesEdge = {
  __typename: "ToolboxVersionExitcodesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ToolboxVersionExitcode` at the end of the edge. */
  node: ToolboxVersionExitcode;
};

/** Methods to use when ordering `ToolboxVersionExitcode`. */
export enum ToolboxVersionExitcodesOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ToolboxexitcodeIdAsc = "TOOLBOXEXITCODE_ID_ASC",
  ToolboxexitcodeIdDesc = "TOOLBOXEXITCODE_ID_DESC",
  ToolboxversionIdAsc = "TOOLBOXVERSION_ID_ASC",
  ToolboxversionIdDesc = "TOOLBOXVERSION_ID_DESC",
}

/** A filter to be used against `ToolboxVersion` object types. All fields are combined with a logical ‘and.’ */
export type ToolboxVersionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ToolboxVersionFilter>>;
  /** Filter by the object’s `containerImageByToolboxVersionId` relation. */
  containerImageByToolboxVersionId?: InputMaybe<ContainerImageFilter>;
  /** A related `containerImageByToolboxVersionId` exists. */
  containerImageByToolboxVersionIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `created` field. */
  created?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `maturity` field. */
  maturity?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ToolboxVersionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ToolboxVersionFilter>>;
  /** Filter by the object’s `toolboxByToolboxId` relation. */
  toolboxByToolboxId?: InputMaybe<ToolboxFilter>;
  /** Filter by the object’s `toolboxId` field. */
  toolboxId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `toolboxSpec` field. */
  toolboxSpec?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `toolboxSpecV1` field. */
  toolboxSpecV1?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `toolboxVersionExitcodesByToolboxversionId` relation. */
  toolboxVersionExitcodesByToolboxversionId?: InputMaybe<ToolboxVersionToManyToolboxVersionExitcodeFilter>;
  /** Some related `toolboxVersionExitcodesByToolboxversionId` exist. */
  toolboxVersionExitcodesByToolboxversionIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `toolboxVersionToolVersionsByToolboxversionId` relation. */
  toolboxVersionToolVersionsByToolboxversionId?: InputMaybe<ToolboxVersionToManyToolboxVersionToolVersionFilter>;
  /** Some related `toolboxVersionToolVersionsByToolboxversionId` exist. */
  toolboxVersionToolVersionsByToolboxversionIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `ToolboxVersion` */
export type ToolboxVersionInput = {
  containerImageUsingId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  maturity: Scalars["Int"]["input"];
  toolboxId?: InputMaybe<Scalars["Int"]["input"]>;
  toolboxSpec?: InputMaybe<Scalars["JSON"]["input"]>;
  toolboxSpecV1?: InputMaybe<Scalars["JSON"]["input"]>;
  toolboxToToolboxId?: InputMaybe<ToolboxVersionToolboxId5Cc86066FkToolboxIdInput>;
  toolboxVersionExitcodesUsingId?: InputMaybe<ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInverseInput>;
  toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInverseInput>;
  version: Scalars["String"]["input"];
};

/** The fields on `toolboxVersion` to look up the row to update. */
export type ToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingToolboxVersionPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolboxVersion` being updated. */
    toolboxVersionPatch: UpdateToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVPatch;
  };

/** The fields on `toolboxVersion` to look up the row to update. */
export type ToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingToolboxversionToolboxVersionUniqueUpdate =
  {
    toolboxId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolboxVersion` being updated. */
    toolboxVersionPatch: UpdateToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVPatch;
    version: Scalars["String"]["input"];
  };

/** The fields on `toolboxVersion` to look up the row to update. */
export type ToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxVersionPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolboxVersion` being updated. */
    toolboxVersionPatch: UpdateToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVPatch;
  };

/** The fields on `toolboxVersion` to look up the row to update. */
export type ToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxversionToolboxVersionUniqueUpdate =
  {
    toolboxId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolboxVersion` being updated. */
    toolboxVersionPatch: UpdateToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVPatch;
    version: Scalars["String"]["input"];
  };

/** The fields on `toolboxVersion` to look up the row to update. */
export type ToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxVersionPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolboxVersion` being updated. */
    toolboxVersionPatch: UpdateToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdPatch;
  };

/** The fields on `toolboxVersion` to look up the row to update. */
export type ToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxversionToolboxVersionUniqueUpdate =
  {
    toolboxId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolboxVersion` being updated. */
    toolboxVersionPatch: UpdateToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdPatch;
    version: Scalars["String"]["input"];
  };

/** The fields on `toolboxVersion` to look up the row to update. */
export type ToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxVersionPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolboxVersion` being updated. */
    toolboxVersionPatch: UpdateToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVPatch;
  };

/** The fields on `toolboxVersion` to look up the row to update. */
export type ToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxversionToolboxVersionUniqueUpdate =
  {
    toolboxId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolboxVersion` being updated. */
    toolboxVersionPatch: UpdateToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVPatch;
    version: Scalars["String"]["input"];
  };

/** Represents an update to a `ToolboxVersion`. Fields that are set will be updated. */
export type ToolboxVersionPatch = {
  containerImageUsingId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  maturity?: InputMaybe<Scalars["Int"]["input"]>;
  toolboxId?: InputMaybe<Scalars["Int"]["input"]>;
  toolboxSpec?: InputMaybe<Scalars["JSON"]["input"]>;
  toolboxSpecV1?: InputMaybe<Scalars["JSON"]["input"]>;
  toolboxToToolboxId?: InputMaybe<ToolboxVersionToolboxId5Cc86066FkToolboxIdInput>;
  toolboxVersionExitcodesUsingId?: InputMaybe<ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInverseInput>;
  toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInverseInput>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against many `ToolboxVersionExitcode` object types. All fields are combined with a logical ‘and.’ */
export type ToolboxVersionToManyToolboxVersionExitcodeFilter = {
  /** Every related `ToolboxVersionExitcode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ToolboxVersionExitcodeFilter>;
  /** No related `ToolboxVersionExitcode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ToolboxVersionExitcodeFilter>;
  /** Some related `ToolboxVersionExitcode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ToolboxVersionExitcodeFilter>;
};

/** A filter to be used against many `ToolboxVersionToolVersion` object types. All fields are combined with a logical ‘and.’ */
export type ToolboxVersionToManyToolboxVersionToolVersionFilter = {
  /** Every related `ToolboxVersionToolVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ToolboxVersionToolVersionFilter>;
  /** No related `ToolboxVersionToolVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ToolboxVersionToolVersionFilter>;
  /** Some related `ToolboxVersionToolVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ToolboxVersionToolVersionFilter>;
};

/** Input for the nested mutation of `toolboxVersion` in the `ToolboxVersionToolVersionInput` mutation. */
export type ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInput = {
  /** The primary key(s) for `toolboxVersion` for the far side of the relationship. */
  connectById?: InputMaybe<ToolboxVersionToolboxVersionPkeyConnect>;
  /** The primary key(s) for `toolboxVersion` for the far side of the relationship. */
  connectByToolboxIdAndVersion?: InputMaybe<ToolboxVersionToolboxversionToolboxVersionUniqueConnect>;
  /** A `ToolboxVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<ToolboxVersionToolToolboxversionId25F78C89FkToolboxVToolboxVersionCreateInput>;
  /** The primary key(s) for `toolboxVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolboxVersionToolboxVersionPkeyDelete>;
  /** The primary key(s) for `toolboxVersion` for the far side of the relationship. */
  deleteByToolboxIdAndVersion?: InputMaybe<ToolboxVersionToolboxversionToolboxVersionUniqueDelete>;
  /** The primary key(s) and patch data for `toolboxVersion` for the far side of the relationship. */
  updateById?: InputMaybe<ToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxVersionPkeyUpdate>;
  /** The primary key(s) and patch data for `toolboxVersion` for the far side of the relationship. */
  updateByToolboxIdAndVersion?: InputMaybe<ToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxversionToolboxVersionUniqueUpdate>;
};

/** Input for the nested mutation of `toolboxVersionToolVersion` in the `ToolboxVersionInput` mutation. */
export type ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInverseInput = {
  /** The primary key(s) for `toolboxVersionToolVersion` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ToolboxVersionToolVersionToolboxVersionToolVersionsPkeyConnect>
  >;
  /** The primary key(s) for `toolboxVersionToolVersion` for the far side of the relationship. */
  connectByToolboxversionIdAndToolversionId?: InputMaybe<
    Array<ToolboxVersionToolVersionToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqConnect>
  >;
  /** A `ToolboxVersionToolVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ToolboxVersionToolToolboxversionId25F78C89FkToolboxVToolboxVersionToolVersionsCreateInput>
  >;
  /** The primary key(s) for `toolboxVersionToolVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<ToolboxVersionToolVersionToolboxVersionToolVersionsPkeyDelete>
  >;
  /** The primary key(s) for `toolboxVersionToolVersion` for the far side of the relationship. */
  deleteByToolboxversionIdAndToolversionId?: InputMaybe<
    Array<ToolboxVersionToolVersionToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqDelete>
  >;
  /** Flag indicating whether all other `toolboxVersionToolVersion` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `toolboxVersionToolVersion` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxVersionToolVersionsPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `toolboxVersionToolVersion` for the far side of the relationship. */
  updateByToolboxversionIdAndToolversionId?: InputMaybe<
    Array<ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqUpdate>
  >;
};

/** The `toolboxVersion` to be created by this mutation. */
export type ToolboxVersionToolToolboxversionId25F78C89FkToolboxVToolboxVersionCreateInput =
  {
    containerImageUsingId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    maturity: Scalars["Int"]["input"];
    toolboxId?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolboxSpecV1?: InputMaybe<Scalars["JSON"]["input"]>;
    toolboxToToolboxId?: InputMaybe<ToolboxVersionToolboxId5Cc86066FkToolboxIdInput>;
    toolboxVersionExitcodesUsingId?: InputMaybe<ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInverseInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInverseInput>;
    version: Scalars["String"]["input"];
  };

/** The `toolboxVersionToolVersion` to be created by this mutation. */
export type ToolboxVersionToolToolboxversionId25F78C89FkToolboxVToolboxVersionToolVersionsCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolversionId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInput>;
    toolboxVersionToToolboxversionId?: InputMaybe<ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInput>;
    toolversionId?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** Input for the nested mutation of `toolVersion` in the `ToolboxVersionToolVersionInput` mutation. */
export type ToolboxVersionToolToolversionId4Ed31559FkToolVersInput = {
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectById?: InputMaybe<ToolVersionToolVersionPkeyConnect>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueConnect>;
  /** A `ToolVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersToolVersionCreateInput>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolVersionToolVersionPkeyDelete>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueDelete>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateById?: InputMaybe<ToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolVersionPkeyUpdate>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateByToolIdAndVersion?: InputMaybe<ToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolversionToolVersionUniqueUpdate>;
};

/** Input for the nested mutation of `toolboxVersionToolVersion` in the `ToolVersionInput` mutation. */
export type ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput = {
  /** The primary key(s) for `toolboxVersionToolVersion` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ToolboxVersionToolVersionToolboxVersionToolVersionsPkeyConnect>
  >;
  /** The primary key(s) for `toolboxVersionToolVersion` for the far side of the relationship. */
  connectByToolboxversionIdAndToolversionId?: InputMaybe<
    Array<ToolboxVersionToolVersionToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqConnect>
  >;
  /** A `ToolboxVersionToolVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ToolboxVersionToolToolversionId4Ed31559FkToolVersToolboxVersionToolVersionsCreateInput>
  >;
  /** The primary key(s) for `toolboxVersionToolVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<ToolboxVersionToolVersionToolboxVersionToolVersionsPkeyDelete>
  >;
  /** The primary key(s) for `toolboxVersionToolVersion` for the far side of the relationship. */
  deleteByToolboxversionIdAndToolversionId?: InputMaybe<
    Array<ToolboxVersionToolVersionToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqDelete>
  >;
  /** Flag indicating whether all other `toolboxVersionToolVersion` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `toolboxVersionToolVersion` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolboxVersionToolVersionsPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `toolboxVersionToolVersion` for the far side of the relationship. */
  updateByToolboxversionIdAndToolversionId?: InputMaybe<
    Array<ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqUpdate>
  >;
};

/** The `toolVersion` to be created by this mutation. */
export type ToolboxVersionToolToolversionId4Ed31559FkToolVersToolVersionCreateInput =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command: Scalars["String"]["input"];
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible: Scalars["Boolean"]["input"];
    isUpgradableToNextVersion: Scalars["Boolean"]["input"];
    maturity: Scalars["Int"]["input"];
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version: Scalars["String"]["input"];
    visibility: Scalars["Int"]["input"];
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** The `toolboxVersionToolVersion` to be created by this mutation. */
export type ToolboxVersionToolToolversionId4Ed31559FkToolVersToolboxVersionToolVersionsCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolversionId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInput>;
    toolboxVersionToToolboxversionId?: InputMaybe<ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInput>;
    toolboxversionId?: InputMaybe<Scalars["Int"]["input"]>;
  };

export type ToolboxVersionToolVersion = {
  __typename: "ToolboxVersionToolVersion";
  id: Scalars["Int"]["output"];
  /** Reads a single `ToolVersion` that is related to this `ToolboxVersionToolVersion`. */
  toolVersionByToolversionId: Maybe<ToolVersion>;
  /** Reads a single `ToolboxVersion` that is related to this `ToolboxVersionToolVersion`. */
  toolboxVersionByToolboxversionId: Maybe<ToolboxVersion>;
  toolboxversionId: Scalars["Int"]["output"];
  toolversionId: Scalars["Int"]["output"];
};

/**
 * A condition to be used against `ToolboxVersionToolVersion` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ToolboxVersionToolVersionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `toolboxversionId` field. */
  toolboxversionId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `toolversionId` field. */
  toolversionId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `ToolboxVersionToolVersion` object types. All fields are combined with a logical ‘and.’ */
export type ToolboxVersionToolVersionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ToolboxVersionToolVersionFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ToolboxVersionToolVersionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ToolboxVersionToolVersionFilter>>;
  /** Filter by the object’s `toolVersionByToolversionId` relation. */
  toolVersionByToolversionId?: InputMaybe<ToolVersionFilter>;
  /** Filter by the object’s `toolboxVersionByToolboxversionId` relation. */
  toolboxVersionByToolboxversionId?: InputMaybe<ToolboxVersionFilter>;
  /** Filter by the object’s `toolboxversionId` field. */
  toolboxversionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `toolversionId` field. */
  toolversionId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ToolboxVersionToolVersion` */
export type ToolboxVersionToolVersionInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolversionId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInput>;
  toolboxVersionToToolboxversionId?: InputMaybe<ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInput>;
  toolboxversionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolversionId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `toolboxVersionToolVersion` to look up the row to update. */
export type ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqUpdate =
  {
    /** An object where the defined keys will be set on the `toolboxVersionToolVersion` being updated. */
    toolboxVersionToolVersionPatch: UpdateToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVPatch;
    toolboxversionId: Scalars["Int"]["input"];
    toolversionId: Scalars["Int"]["input"];
  };

/** The fields on `toolboxVersionToolVersion` to look up the row to update. */
export type ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxVersionToolVersionsPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolboxVersionToolVersion` being updated. */
    toolboxVersionToolVersionPatch: UpdateToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVPatch;
  };

/** The fields on `toolboxVersionToolVersion` to look up the row to update. */
export type ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqUpdate =
  {
    /** An object where the defined keys will be set on the `toolboxVersionToolVersion` being updated. */
    toolboxVersionToolVersionPatch: UpdateToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersPatch;
    toolboxversionId: Scalars["Int"]["input"];
    toolversionId: Scalars["Int"]["input"];
  };

/** The fields on `toolboxVersionToolVersion` to look up the row to update. */
export type ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolboxVersionToolVersionsPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `toolboxVersionToolVersion` being updated. */
    toolboxVersionToolVersionPatch: UpdateToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersPatch;
  };

/** Represents an update to a `ToolboxVersionToolVersion`. Fields that are set will be updated. */
export type ToolboxVersionToolVersionPatch = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolversionId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInput>;
  toolboxVersionToToolboxversionId?: InputMaybe<ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInput>;
  toolboxversionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolversionId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The fields on `toolboxVersionToolVersion` to look up the row to connect. */
export type ToolboxVersionToolVersionToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqConnect =
  {
    toolboxversionId: Scalars["Int"]["input"];
    toolversionId: Scalars["Int"]["input"];
  };

/** The fields on `toolboxVersionToolVersion` to look up the row to delete. */
export type ToolboxVersionToolVersionToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqDelete =
  {
    toolboxversionId: Scalars["Int"]["input"];
    toolversionId: Scalars["Int"]["input"];
  };

/** The fields on `toolboxVersionToolVersion` to look up the row to connect. */
export type ToolboxVersionToolVersionToolboxVersionToolVersionsPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolboxVersionToolVersion` to look up the row to delete. */
export type ToolboxVersionToolVersionToolboxVersionToolVersionsPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** A connection to a list of `ToolboxVersionToolVersion` values. */
export type ToolboxVersionToolVersionsConnection = {
  __typename: "ToolboxVersionToolVersionsConnection";
  /** A list of edges which contains the `ToolboxVersionToolVersion` and cursor to aid in pagination. */
  edges: Array<ToolboxVersionToolVersionsEdge>;
  /** A list of `ToolboxVersionToolVersion` objects. */
  nodes: Array<ToolboxVersionToolVersion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ToolboxVersionToolVersion` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ToolboxVersionToolVersion` edge in the connection. */
export type ToolboxVersionToolVersionsEdge = {
  __typename: "ToolboxVersionToolVersionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ToolboxVersionToolVersion` at the end of the edge. */
  node: ToolboxVersionToolVersion;
};

/** Methods to use when ordering `ToolboxVersionToolVersion`. */
export enum ToolboxVersionToolVersionsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ToolboxversionIdAsc = "TOOLBOXVERSION_ID_ASC",
  ToolboxversionIdDesc = "TOOLBOXVERSION_ID_DESC",
  ToolversionIdAsc = "TOOLVERSION_ID_ASC",
  ToolversionIdDesc = "TOOLVERSION_ID_DESC",
}

/** Input for the nested mutation of `toolbox` in the `ToolboxVersionInput` mutation. */
export type ToolboxVersionToolboxId5Cc86066FkToolboxIdInput = {
  /** The primary key(s) for `toolbox` for the far side of the relationship. */
  connectById?: InputMaybe<ToolboxToolboxPkeyConnect>;
  /** The primary key(s) for `toolbox` for the far side of the relationship. */
  connectByKey?: InputMaybe<ToolboxToolboxKeyKeyConnect>;
  /** A `ToolboxInput` object that will be created and connected to this object. */
  create?: InputMaybe<ToolboxVersionToolboxId5Cc86066FkToolboxIdToolboxCreateInput>;
  /** The primary key(s) for `toolbox` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolboxToolboxPkeyDelete>;
  /** The primary key(s) for `toolbox` for the far side of the relationship. */
  deleteByKey?: InputMaybe<ToolboxToolboxKeyKeyDelete>;
  /** The primary key(s) and patch data for `toolbox` for the far side of the relationship. */
  updateById?: InputMaybe<ToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxPkeyUpdate>;
  /** The primary key(s) and patch data for `toolbox` for the far side of the relationship. */
  updateByKey?: InputMaybe<ToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxKeyKeyUpdate>;
};

/** Input for the nested mutation of `toolboxVersion` in the `ToolboxInput` mutation. */
export type ToolboxVersionToolboxId5Cc86066FkToolboxIdInverseInput = {
  /** The primary key(s) for `toolboxVersion` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ToolboxVersionToolboxVersionPkeyConnect>>;
  /** The primary key(s) for `toolboxVersion` for the far side of the relationship. */
  connectByToolboxIdAndVersion?: InputMaybe<
    Array<ToolboxVersionToolboxversionToolboxVersionUniqueConnect>
  >;
  /** A `ToolboxVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ToolboxVersionToolboxId5Cc86066FkToolboxIdToolboxVersionCreateInput>
  >;
  /** The primary key(s) for `toolboxVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ToolboxVersionToolboxVersionPkeyDelete>>;
  /** The primary key(s) for `toolboxVersion` for the far side of the relationship. */
  deleteByToolboxIdAndVersion?: InputMaybe<
    Array<ToolboxVersionToolboxversionToolboxVersionUniqueDelete>
  >;
  /** Flag indicating whether all other `toolboxVersion` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `toolboxVersion` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxVersionPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `toolboxVersion` for the far side of the relationship. */
  updateByToolboxIdAndVersion?: InputMaybe<
    Array<ToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxversionToolboxVersionUniqueUpdate>
  >;
};

/** The `toolbox` to be created by this mutation. */
export type ToolboxVersionToolboxId5Cc86066FkToolboxIdToolboxCreateInput = {
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsToolboxId9D553881FkToolboxIdInverseInput>;
  toolboxVersionsUsingId?: InputMaybe<ToolboxVersionToolboxId5Cc86066FkToolboxIdInverseInput>;
};

/** The `toolboxVersion` to be created by this mutation. */
export type ToolboxVersionToolboxId5Cc86066FkToolboxIdToolboxVersionCreateInput =
  {
    containerImageUsingId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    maturity: Scalars["Int"]["input"];
    toolboxSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolboxSpecV1?: InputMaybe<Scalars["JSON"]["input"]>;
    toolboxToToolboxId?: InputMaybe<ToolboxVersionToolboxId5Cc86066FkToolboxIdInput>;
    toolboxVersionExitcodesUsingId?: InputMaybe<ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInverseInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInverseInput>;
    version: Scalars["String"]["input"];
  };

/** The fields on `toolboxVersion` to look up the row to connect. */
export type ToolboxVersionToolboxVersionPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolboxVersion` to look up the row to delete. */
export type ToolboxVersionToolboxVersionPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** The fields on `toolboxVersion` to look up the row to connect. */
export type ToolboxVersionToolboxversionToolboxVersionUniqueConnect = {
  toolboxId: Scalars["Int"]["input"];
  version: Scalars["String"]["input"];
};

/** The fields on `toolboxVersion` to look up the row to delete. */
export type ToolboxVersionToolboxversionToolboxVersionUniqueDelete = {
  toolboxId: Scalars["Int"]["input"];
  version: Scalars["String"]["input"];
};

/** A connection to a list of `ToolboxVersion` values. */
export type ToolboxVersionsConnection = {
  __typename: "ToolboxVersionsConnection";
  /** A list of edges which contains the `ToolboxVersion` and cursor to aid in pagination. */
  edges: Array<ToolboxVersionsEdge>;
  /** A list of `ToolboxVersion` objects. */
  nodes: Array<ToolboxVersion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ToolboxVersion` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `ToolboxVersion` edge in the connection. */
export type ToolboxVersionsEdge = {
  __typename: "ToolboxVersionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ToolboxVersion` at the end of the edge. */
  node: ToolboxVersion;
};

/** Methods to use when ordering `ToolboxVersion`. */
export enum ToolboxVersionsOrderBy {
  CreatedAsc = "CREATED_ASC",
  CreatedDesc = "CREATED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MaturityAsc = "MATURITY_ASC",
  MaturityDesc = "MATURITY_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ToolboxIdAsc = "TOOLBOX_ID_ASC",
  ToolboxIdDesc = "TOOLBOX_ID_DESC",
  ToolboxSpecAsc = "TOOLBOX_SPEC_ASC",
  ToolboxSpecDesc = "TOOLBOX_SPEC_DESC",
  ToolboxSpecV1Asc = "TOOLBOX_SPEC_V1_ASC",
  ToolboxSpecV1Desc = "TOOLBOX_SPEC_V1_DESC",
  VersionAsc = "VERSION_ASC",
  VersionDesc = "VERSION_DESC",
}

/** A connection to a list of `Toolbox` values. */
export type ToolboxesConnection = {
  __typename: "ToolboxesConnection";
  /** A list of edges which contains the `Toolbox` and cursor to aid in pagination. */
  edges: Array<ToolboxesEdge>;
  /** A list of `Toolbox` objects. */
  nodes: Array<Toolbox>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Toolbox` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Toolbox` edge in the connection. */
export type ToolboxesEdge = {
  __typename: "ToolboxesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Toolbox` at the end of the edge. */
  node: Toolbox;
};

/** Methods to use when ordering `Toolbox`. */
export enum ToolboxesOrderBy {
  CreatedAsc = "CREATED_ASC",
  CreatedDesc = "CREATED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  KeyAsc = "KEY_ASC",
  KeyDesc = "KEY_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

/** A connection to a list of `Tool` values. */
export type ToolsConnection = {
  __typename: "ToolsConnection";
  /** A list of edges which contains the `Tool` and cursor to aid in pagination. */
  edges: Array<ToolsEdge>;
  /** A list of `Tool` objects. */
  nodes: Array<Tool>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Tool` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Tool` edge in the connection. */
export type ToolsEdge = {
  __typename: "ToolsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Tool` at the end of the edge. */
  node: Tool;
};

/** Methods to use when ordering `Tool`. */
export enum ToolsOrderBy {
  CreatedAsc = "CREATED_ASC",
  CreatedDesc = "CREATED_DESC",
  HelpTextAsc = "HELP_TEXT_ASC",
  HelpTextDesc = "HELP_TEXT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  KeyAsc = "KEY_ASC",
  KeyDesc = "KEY_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SearchTermsAsc = "SEARCH_TERMS_ASC",
  SearchTermsDesc = "SEARCH_TERMS_DESC",
}

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
};

/** All input for the `updateAnalysisTableById` mutation. */
export type UpdateAnalysisTableByIdInput = {
  /** An object where the defined keys will be set on the `AnalysisTable` being updated. */
  analysisTablePatch: AnalysisTablePatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `updateAnalysisTableGroupById` mutation. */
export type UpdateAnalysisTableGroupByIdInput = {
  /** An object where the defined keys will be set on the `AnalysisTableGroup` being updated. */
  analysisTableGroupPatch: AnalysisTableGroupPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** The output of our update `AnalysisTableGroup` mutation. */
export type UpdateAnalysisTableGroupPayload = {
  __typename: "UpdateAnalysisTableGroupPayload";
  /** The `AnalysisTableGroup` that was updated by this mutation. */
  analysisTableGroup: Maybe<AnalysisTableGroup>;
  /** An edge for our `AnalysisTableGroup`. May be used by Relay 1. */
  analysisTableGroupEdge: Maybe<AnalysisTableGroupsEdge>;
  /** Reads a single `ApplicationUser` that is related to this `AnalysisTableGroup`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Project` that is related to this `AnalysisTableGroup`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `AnalysisTableGroup`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `Tool` that is related to this `AnalysisTableGroup`. */
  toolByToolId: Maybe<Tool>;
};

/** The output of our update `AnalysisTableGroup` mutation. */
export type UpdateAnalysisTableGroupPayloadAnalysisTableGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<AnalysisTableGroupsOrderBy>>;
};

/** The output of our update `AnalysisTable` mutation. */
export type UpdateAnalysisTablePayload = {
  __typename: "UpdateAnalysisTablePayload";
  /** The `AnalysisTable` that was updated by this mutation. */
  analysisTable: Maybe<AnalysisTable>;
  /** An edge for our `AnalysisTable`. May be used by Relay 1. */
  analysisTableEdge: Maybe<AnalysisTablesEdge>;
  /** Reads a single `AnalysisTableGroup` that is related to this `AnalysisTable`. */
  analysisTableGroupByGroupId: Maybe<AnalysisTableGroup>;
  /** Reads a single `ApplicationUser` that is related to this `AnalysisTable`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Project` that is related to this `AnalysisTable`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `AnalysisTable`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `ToolVersion` that is related to this `AnalysisTable`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our update `AnalysisTable` mutation. */
export type UpdateAnalysisTablePayloadAnalysisTableEdgeArgs = {
  orderBy?: InputMaybe<Array<AnalysisTablesOrderBy>>;
};

/** All input for the `updateAnalysisTableRowAttachResultById` mutation. */
export type UpdateAnalysisTableRowAttachResultByIdInput = {
  /** An object where the defined keys will be set on the `AnalysisTableRowAttachResult` being updated. */
  analysisTableRowAttachResultPatch: AnalysisTableRowAttachResultPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** The output of our update `AnalysisTableRowAttachResult` mutation. */
export type UpdateAnalysisTableRowAttachResultPayload = {
  __typename: "UpdateAnalysisTableRowAttachResultPayload";
  /** The `AnalysisTableRowAttachResult` that was updated by this mutation. */
  analysisTableRowAttachResult: Maybe<AnalysisTableRowAttachResult>;
  /** An edge for our `AnalysisTableRowAttachResult`. May be used by Relay 1. */
  analysisTableRowAttachResultEdge: Maybe<AnalysisTableRowAttachResultsEdge>;
  /** Reads a single `AnalysisTableRow` that is related to this `AnalysisTableRowAttachResult`. */
  analysisTableRowByAnalysisTableRowId: Maybe<AnalysisTableRow>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Project` that is related to this `AnalysisTableRowAttachResult`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `AnalysisTableRowAttachResult` mutation. */
export type UpdateAnalysisTableRowAttachResultPayloadAnalysisTableRowAttachResultEdgeArgs =
  {
    orderBy?: InputMaybe<Array<AnalysisTableRowAttachResultsOrderBy>>;
  };

/** All input for the `updateAnalysisTableRowById` mutation. */
export type UpdateAnalysisTableRowByIdInput = {
  /** An object where the defined keys will be set on the `AnalysisTableRow` being updated. */
  analysisTableRowPatch: AnalysisTableRowPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** All input for the `updateAnalysisTableRowByTaskId` mutation. */
export type UpdateAnalysisTableRowByTaskIdInput = {
  /** An object where the defined keys will be set on the `AnalysisTableRow` being updated. */
  analysisTableRowPatch: AnalysisTableRowPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  taskId: Scalars["UUID"]["input"];
};

/** The output of our update `AnalysisTableRow` mutation. */
export type UpdateAnalysisTableRowPayload = {
  __typename: "UpdateAnalysisTableRowPayload";
  /** Reads a single `AnalysisTable` that is related to this `AnalysisTableRow`. */
  analysisTableByTableId: Maybe<AnalysisTable>;
  /** The `AnalysisTableRow` that was updated by this mutation. */
  analysisTableRow: Maybe<AnalysisTableRow>;
  /** An edge for our `AnalysisTableRow`. May be used by Relay 1. */
  analysisTableRowEdge: Maybe<AnalysisTableRowsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Project` that is related to this `AnalysisTableRow`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Task` that is related to this `AnalysisTableRow`. */
  taskByTaskId: Maybe<Task>;
  /** Reads a single `Tenant` that is related to this `AnalysisTableRow`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `ToolVersion` that is related to this `AnalysisTableRow`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our update `AnalysisTableRow` mutation. */
export type UpdateAnalysisTableRowPayloadAnalysisTableRowEdgeArgs = {
  orderBy?: InputMaybe<Array<AnalysisTableRowsOrderBy>>;
};

/** All input for the `updateApplicationUserByEmail` mutation. */
export type UpdateApplicationUserByEmailInput = {
  /** An object where the defined keys will be set on the `ApplicationUser` being updated. */
  applicationUserPatch: ApplicationUserPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
};

/** All input for the `updateApplicationUserById` mutation. */
export type UpdateApplicationUserByIdInput = {
  /** An object where the defined keys will be set on the `ApplicationUser` being updated. */
  applicationUserPatch: ApplicationUserPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** All input for the `updateApplicationUserByUsername` mutation. */
export type UpdateApplicationUserByUsernameInput = {
  /** An object where the defined keys will be set on the `ApplicationUser` being updated. */
  applicationUserPatch: ApplicationUserPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  username: Scalars["String"]["input"];
};

/** All input for the `updateApplicationUserGroupById` mutation. */
export type UpdateApplicationUserGroupByIdInput = {
  /** An object where the defined keys will be set on the `ApplicationUserGroup` being updated. */
  applicationUserGroupPatch: ApplicationUserGroupPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `updateApplicationUserGroupByUserIdAndGroupId` mutation. */
export type UpdateApplicationUserGroupByUserIdAndGroupIdInput = {
  /** An object where the defined keys will be set on the `ApplicationUserGroup` being updated. */
  applicationUserGroupPatch: ApplicationUserGroupPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  groupId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The output of our update `ApplicationUserGroup` mutation. */
export type UpdateApplicationUserGroupPayload = {
  __typename: "UpdateApplicationUserGroupPayload";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserGroup`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** The `ApplicationUserGroup` that was updated by this mutation. */
  applicationUserGroup: Maybe<ApplicationUserGroup>;
  /** An edge for our `ApplicationUserGroup`. May be used by Relay 1. */
  applicationUserGroupEdge: Maybe<ApplicationUserGroupsEdge>;
  /** Reads a single `AuthGroup` that is related to this `ApplicationUserGroup`. */
  authGroupByGroupId: Maybe<AuthGroup>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `ApplicationUserGroup` mutation. */
export type UpdateApplicationUserGroupPayloadApplicationUserGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<ApplicationUserGroupsOrderBy>>;
};

/** All input for the `updateApplicationUserMembershipById` mutation. */
export type UpdateApplicationUserMembershipByIdInput = {
  /** An object where the defined keys will be set on the `ApplicationUserMembership` being updated. */
  applicationUserMembershipPatch: ApplicationUserMembershipPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `updateApplicationUserMembershipByUserIdAndTenantId` mutation. */
export type UpdateApplicationUserMembershipByUserIdAndTenantIdInput = {
  /** An object where the defined keys will be set on the `ApplicationUserMembership` being updated. */
  applicationUserMembershipPatch: ApplicationUserMembershipPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  tenantId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The output of our update `ApplicationUserMembership` mutation. */
export type UpdateApplicationUserMembershipPayload = {
  __typename: "UpdateApplicationUserMembershipPayload";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserMembership`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** The `ApplicationUserMembership` that was updated by this mutation. */
  applicationUserMembership: Maybe<ApplicationUserMembership>;
  /** An edge for our `ApplicationUserMembership`. May be used by Relay 1. */
  applicationUserMembershipEdge: Maybe<ApplicationUserMembershipsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `ApplicationUserMembership`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `ApplicationUserMembership` mutation. */
export type UpdateApplicationUserMembershipPayloadApplicationUserMembershipEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ApplicationUserMembershipsOrderBy>>;
  };

/** All input for the `updateApplicationUserMetadatumById` mutation. */
export type UpdateApplicationUserMetadatumByIdInput = {
  /** An object where the defined keys will be set on the `ApplicationUserMetadatum` being updated. */
  applicationUserMetadatumPatch: ApplicationUserMetadatumPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `updateApplicationUserMetadatumByUserIdAndKey` mutation. */
export type UpdateApplicationUserMetadatumByUserIdAndKeyInput = {
  /** An object where the defined keys will be set on the `ApplicationUserMetadatum` being updated. */
  applicationUserMetadatumPatch: ApplicationUserMetadatumPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The output of our update `ApplicationUserMetadatum` mutation. */
export type UpdateApplicationUserMetadatumPayload = {
  __typename: "UpdateApplicationUserMetadatumPayload";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserMetadatum`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** The `ApplicationUserMetadatum` that was updated by this mutation. */
  applicationUserMetadatum: Maybe<ApplicationUserMetadatum>;
  /** An edge for our `ApplicationUserMetadatum`. May be used by Relay 1. */
  applicationUserMetadatumEdge: Maybe<ApplicationUserMetadataEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `ApplicationUserMetadatum` mutation. */
export type UpdateApplicationUserMetadatumPayloadApplicationUserMetadatumEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ApplicationUserMetadataOrderBy>>;
  };

/** The output of our update `ApplicationUser` mutation. */
export type UpdateApplicationUserPayload = {
  __typename: "UpdateApplicationUserPayload";
  /** The `ApplicationUser` that was updated by this mutation. */
  applicationUser: Maybe<ApplicationUser>;
  /** An edge for our `ApplicationUser`. May be used by Relay 1. */
  applicationUserEdge: Maybe<ApplicationUsersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `ApplicationUser` mutation. */
export type UpdateApplicationUserPayloadApplicationUserEdgeArgs = {
  orderBy?: InputMaybe<Array<ApplicationUsersOrderBy>>;
};

/** All input for the `updateApplicationUserSessionById` mutation. */
export type UpdateApplicationUserSessionByIdInput = {
  /** An object where the defined keys will be set on the `ApplicationUserSession` being updated. */
  applicationUserSessionPatch: ApplicationUserSessionPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our update `ApplicationUserSession` mutation. */
export type UpdateApplicationUserSessionPayload = {
  __typename: "UpdateApplicationUserSessionPayload";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserSession`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** The `ApplicationUserSession` that was updated by this mutation. */
  applicationUserSession: Maybe<ApplicationUserSession>;
  /** An edge for our `ApplicationUserSession`. May be used by Relay 1. */
  applicationUserSessionEdge: Maybe<ApplicationUserSessionsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `ApplicationUserSession`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `ApplicationUserSession` mutation. */
export type UpdateApplicationUserSessionPayloadApplicationUserSessionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ApplicationUserSessionsOrderBy>>;
  };

/** All input for the `updateApplicationUserUserPermissionById` mutation. */
export type UpdateApplicationUserUserPermissionByIdInput = {
  /** An object where the defined keys will be set on the `ApplicationUserUserPermission` being updated. */
  applicationUserUserPermissionPatch: ApplicationUserUserPermissionPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `updateApplicationUserUserPermissionByUserIdAndPermissionId` mutation. */
export type UpdateApplicationUserUserPermissionByUserIdAndPermissionIdInput = {
  /** An object where the defined keys will be set on the `ApplicationUserUserPermission` being updated. */
  applicationUserUserPermissionPatch: ApplicationUserUserPermissionPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  permissionId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The output of our update `ApplicationUserUserPermission` mutation. */
export type UpdateApplicationUserUserPermissionPayload = {
  __typename: "UpdateApplicationUserUserPermissionPayload";
  /** Reads a single `ApplicationUser` that is related to this `ApplicationUserUserPermission`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** The `ApplicationUserUserPermission` that was updated by this mutation. */
  applicationUserUserPermission: Maybe<ApplicationUserUserPermission>;
  /** An edge for our `ApplicationUserUserPermission`. May be used by Relay 1. */
  applicationUserUserPermissionEdge: Maybe<ApplicationUserUserPermissionsEdge>;
  /** Reads a single `AuthPermission` that is related to this `ApplicationUserUserPermission`. */
  authPermissionByPermissionId: Maybe<AuthPermission>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `ApplicationUserUserPermission` mutation. */
export type UpdateApplicationUserUserPermissionPayloadApplicationUserUserPermissionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ApplicationUserUserPermissionsOrderBy>>;
  };

/** All input for the `updateAuthGroupById` mutation. */
export type UpdateAuthGroupByIdInput = {
  /** An object where the defined keys will be set on the `AuthGroup` being updated. */
  authGroupPatch: AuthGroupPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** All input for the `updateAuthGroupByName` mutation. */
export type UpdateAuthGroupByNameInput = {
  /** An object where the defined keys will be set on the `AuthGroup` being updated. */
  authGroupPatch: AuthGroupPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

/** The output of our update `AuthGroup` mutation. */
export type UpdateAuthGroupPayload = {
  __typename: "UpdateAuthGroupPayload";
  /** The `AuthGroup` that was updated by this mutation. */
  authGroup: Maybe<AuthGroup>;
  /** An edge for our `AuthGroup`. May be used by Relay 1. */
  authGroupEdge: Maybe<AuthGroupsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `AuthGroup` mutation. */
export type UpdateAuthGroupPayloadAuthGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<AuthGroupsOrderBy>>;
};

/** All input for the `updateAuthGroupPermissionByGroupIdAndPermissionId` mutation. */
export type UpdateAuthGroupPermissionByGroupIdAndPermissionIdInput = {
  /** An object where the defined keys will be set on the `AuthGroupPermission` being updated. */
  authGroupPermissionPatch: AuthGroupPermissionPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  groupId: Scalars["Int"]["input"];
  permissionId: Scalars["Int"]["input"];
};

/** All input for the `updateAuthGroupPermissionById` mutation. */
export type UpdateAuthGroupPermissionByIdInput = {
  /** An object where the defined keys will be set on the `AuthGroupPermission` being updated. */
  authGroupPermissionPatch: AuthGroupPermissionPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our update `AuthGroupPermission` mutation. */
export type UpdateAuthGroupPermissionPayload = {
  __typename: "UpdateAuthGroupPermissionPayload";
  /** Reads a single `AuthGroup` that is related to this `AuthGroupPermission`. */
  authGroupByGroupId: Maybe<AuthGroup>;
  /** The `AuthGroupPermission` that was updated by this mutation. */
  authGroupPermission: Maybe<AuthGroupPermission>;
  /** An edge for our `AuthGroupPermission`. May be used by Relay 1. */
  authGroupPermissionEdge: Maybe<AuthGroupPermissionsEdge>;
  /** Reads a single `AuthPermission` that is related to this `AuthGroupPermission`. */
  authPermissionByPermissionId: Maybe<AuthPermission>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `AuthGroupPermission` mutation. */
export type UpdateAuthGroupPermissionPayloadAuthGroupPermissionEdgeArgs = {
  orderBy?: InputMaybe<Array<AuthGroupPermissionsOrderBy>>;
};

/** All input for the `updateAuthPermissionByContentTypeIdAndCodename` mutation. */
export type UpdateAuthPermissionByContentTypeIdAndCodenameInput = {
  /** An object where the defined keys will be set on the `AuthPermission` being updated. */
  authPermissionPatch: AuthPermissionPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  codename: Scalars["String"]["input"];
  contentTypeId: Scalars["Int"]["input"];
};

/** All input for the `updateAuthPermissionById` mutation. */
export type UpdateAuthPermissionByIdInput = {
  /** An object where the defined keys will be set on the `AuthPermission` being updated. */
  authPermissionPatch: AuthPermissionPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
};

/** The output of our update `AuthPermission` mutation. */
export type UpdateAuthPermissionPayload = {
  __typename: "UpdateAuthPermissionPayload";
  /** The `AuthPermission` that was updated by this mutation. */
  authPermission: Maybe<AuthPermission>;
  /** An edge for our `AuthPermission`. May be used by Relay 1. */
  authPermissionEdge: Maybe<AuthPermissionsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DjangoContentType` that is related to this `AuthPermission`. */
  djangoContentTypeByContentTypeId: Maybe<DjangoContentType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `AuthPermission` mutation. */
export type UpdateAuthPermissionPayloadAuthPermissionEdgeArgs = {
  orderBy?: InputMaybe<Array<AuthPermissionsOrderBy>>;
};

/** All input for the `updateAvatarById` mutation. */
export type UpdateAvatarByIdInput = {
  /** An object where the defined keys will be set on the `Avatar` being updated. */
  avatarPatch: AvatarPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
};

/** All input for the `updateAvatarByUserId` mutation. */
export type UpdateAvatarByUserIdInput = {
  /** An object where the defined keys will be set on the `Avatar` being updated. */
  avatarPatch: AvatarPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["UUID"]["input"];
};

/** The output of our update `Avatar` mutation. */
export type UpdateAvatarPayload = {
  __typename: "UpdateAvatarPayload";
  /** Reads a single `ApplicationUser` that is related to this `Avatar`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /** The `Avatar` that was updated by this mutation. */
  avatar: Maybe<Avatar>;
  /** An edge for our `Avatar`. May be used by Relay 1. */
  avatarEdge: Maybe<AvatarsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `Avatar` mutation. */
export type UpdateAvatarPayloadAvatarEdgeArgs = {
  orderBy?: InputMaybe<Array<AvatarsOrderBy>>;
};

/** All input for the `updateAwsBatchTaskByTaskexecutorPtrId` mutation. */
export type UpdateAwsBatchTaskByTaskexecutorPtrIdInput = {
  /** An object where the defined keys will be set on the `AwsBatchTask` being updated. */
  awsBatchTaskPatch: AwsBatchTaskPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  taskexecutorPtrId: Scalars["UUID"]["input"];
};

/** The output of our update `AwsBatchTask` mutation. */
export type UpdateAwsBatchTaskPayload = {
  __typename: "UpdateAwsBatchTaskPayload";
  /** The `AwsBatchTask` that was updated by this mutation. */
  awsBatchTask: Maybe<AwsBatchTask>;
  /** An edge for our `AwsBatchTask`. May be used by Relay 1. */
  awsBatchTaskEdge: Maybe<AwsBatchTasksEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `TesTaskexecutor` that is related to this `AwsBatchTask`. */
  tesTaskexecutorByTaskexecutorPtrId: Maybe<TesTaskexecutor>;
};

/** The output of our update `AwsBatchTask` mutation. */
export type UpdateAwsBatchTaskPayloadAwsBatchTaskEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsBatchTasksOrderBy>>;
};

/** All input for the `updateAwsCognitoUserByUserexecutorPtrId` mutation. */
export type UpdateAwsCognitoUserByUserexecutorPtrIdInput = {
  /** An object where the defined keys will be set on the `AwsCognitoUser` being updated. */
  awsCognitoUserPatch: AwsCognitoUserPatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  userexecutorPtrId: Scalars["Int"]["input"];
};

/** The output of our update `AwsCognitoUser` mutation. */
export type UpdateAwsCognitoUserPayload = {
  __typename: "UpdateAwsCognitoUserPayload";
  /** The `AwsCognitoUser` that was updated by this mutation. */
  awsCognitoUser: Maybe<AwsCognitoUser>;
  /** An edge for our `AwsCognitoUser`. May be used by Relay 1. */
  awsCognitoUserEdge: Maybe<AwsCognitoUsersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `TenantUserexecutor` that is related to this `AwsCognitoUser`. */
  tenantUserexecutorByUserexecutorPtrId: Maybe<TenantUserexecutor>;
};

/** The output of our update `AwsCognitoUser` mutation. */
export type UpdateAwsCognitoUserPayloadAwsCognitoUserEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsCognitoUsersOrderBy>>;
};

/** All input for the `updateAwsEcrImageByContainerimageexecutorPtrId` mutation. */
export type UpdateAwsEcrImageByContainerimageexecutorPtrIdInput = {
  /** An object where the defined keys will be set on the `AwsEcrImage` being updated. */
  awsEcrImagePatch: AwsEcrImagePatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  containerimageexecutorPtrId: Scalars["Int"]["input"];
};

/** The output of our update `AwsEcrImage` mutation. */
export type UpdateAwsEcrImagePayload = {
  __typename: "UpdateAwsEcrImagePayload";
  /** The `AwsEcrImage` that was updated by this mutation. */
  awsEcrImage: Maybe<AwsEcrImage>;
  /** An edge for our `AwsEcrImage`. May be used by Relay 1. */
  awsEcrImageEdge: Maybe<AwsEcrImagesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `TesContainerimageexecutor` that is related to this `AwsEcrImage`. */
  tesContainerimageexecutorByContainerimageexecutorPtrId: Maybe<TesContainerimageexecutor>;
};

/** The output of our update `AwsEcrImage` mutation. */
export type UpdateAwsEcrImagePayloadAwsEcrImageEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsEcrImagesOrderBy>>;
};

/** All input for the `updateAwsMockBatchTaskByAwstaskexecutorPtrId` mutation. */
export type UpdateAwsMockBatchTaskByAwstaskexecutorPtrIdInput = {
  /** An object where the defined keys will be set on the `AwsMockBatchTask` being updated. */
  awsMockBatchTaskPatch: AwsMockBatchTaskPatch;
  awstaskexecutorPtrId: Scalars["UUID"]["input"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our update `AwsMockBatchTask` mutation. */
export type UpdateAwsMockBatchTaskPayload = {
  __typename: "UpdateAwsMockBatchTaskPayload";
  /** Reads a single `AwsBatchTask` that is related to this `AwsMockBatchTask`. */
  awsBatchTaskByAwstaskexecutorPtrId: Maybe<AwsBatchTask>;
  /** The `AwsMockBatchTask` that was updated by this mutation. */
  awsMockBatchTask: Maybe<AwsMockBatchTask>;
  /** An edge for our `AwsMockBatchTask`. May be used by Relay 1. */
  awsMockBatchTaskEdge: Maybe<AwsMockBatchTasksEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `AwsMockBatchTask` mutation. */
export type UpdateAwsMockBatchTaskPayloadAwsMockBatchTaskEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsMockBatchTasksOrderBy>>;
};

/** All input for the `updateAwsMockEcrImageByAwscontainerimageexecutorPtrId` mutation. */
export type UpdateAwsMockEcrImageByAwscontainerimageexecutorPtrIdInput = {
  /** An object where the defined keys will be set on the `AwsMockEcrImage` being updated. */
  awsMockEcrImagePatch: AwsMockEcrImagePatch;
  awscontainerimageexecutorPtrId: Scalars["Int"]["input"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our update `AwsMockEcrImage` mutation. */
export type UpdateAwsMockEcrImagePayload = {
  __typename: "UpdateAwsMockEcrImagePayload";
  /** Reads a single `AwsEcrImage` that is related to this `AwsMockEcrImage`. */
  awsEcrImageByAwscontainerimageexecutorPtrId: Maybe<AwsEcrImage>;
  /** The `AwsMockEcrImage` that was updated by this mutation. */
  awsMockEcrImage: Maybe<AwsMockEcrImage>;
  /** An edge for our `AwsMockEcrImage`. May be used by Relay 1. */
  awsMockEcrImageEdge: Maybe<AwsMockEcrImagesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `AwsMockEcrImage` mutation. */
export type UpdateAwsMockEcrImagePayloadAwsMockEcrImageEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsMockEcrImagesOrderBy>>;
};

/** All input for the `updateAwsMockS3FileByAwsfileexecutorPtrId` mutation. */
export type UpdateAwsMockS3FileByAwsfileexecutorPtrIdInput = {
  /** An object where the defined keys will be set on the `AwsMockS3File` being updated. */
  awsMockS3FilePatch: AwsMockS3FilePatch;
  awsfileexecutorPtrId: Scalars["UUID"]["input"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our update `AwsMockS3File` mutation. */
export type UpdateAwsMockS3FilePayload = {
  __typename: "UpdateAwsMockS3FilePayload";
  /** The `AwsMockS3File` that was updated by this mutation. */
  awsMockS3File: Maybe<AwsMockS3File>;
  /** An edge for our `AwsMockS3File`. May be used by Relay 1. */
  awsMockS3FileEdge: Maybe<AwsMockS3FilesEdge>;
  /** Reads a single `AwsS3File` that is related to this `AwsMockS3File`. */
  awsS3FileByAwsfileexecutorPtrId: Maybe<AwsS3File>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `AwsMockS3File` mutation. */
export type UpdateAwsMockS3FilePayloadAwsMockS3FileEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsMockS3FilesOrderBy>>;
};

/** All input for the `updateAwsS3FileByFileexecutorPtrId` mutation. */
export type UpdateAwsS3FileByFileexecutorPtrIdInput = {
  /** An object where the defined keys will be set on the `AwsS3File` being updated. */
  awsS3FilePatch: AwsS3FilePatch;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  fileexecutorPtrId: Scalars["UUID"]["input"];
};

/** The output of our update `AwsS3File` mutation. */
export type UpdateAwsS3FilePayload = {
  __typename: "UpdateAwsS3FilePayload";
  /** The `AwsS3File` that was updated by this mutation. */
  awsS3File: Maybe<AwsS3File>;
  /** An edge for our `AwsS3File`. May be used by Relay 1. */
  awsS3FileEdge: Maybe<AwsS3FilesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DrsFileexecutor` that is related to this `AwsS3File`. */
  drsFileexecutorByFileexecutorPtrId: Maybe<DrsFileexecutor>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `AwsS3File` mutation. */
export type UpdateAwsS3FilePayloadAwsS3FileEdgeArgs = {
  orderBy?: InputMaybe<Array<AwsS3FilesOrderBy>>;
};

/** All input for the `updateContainerImageByExecutorId` mutation. */
export type UpdateContainerImageByExecutorIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `ContainerImage` being updated. */
  containerImagePatch: ContainerImagePatch;
  executorId: Scalars["Int"]["input"];
};

/** All input for the `updateContainerImageById` mutation. */
export type UpdateContainerImageByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `ContainerImage` being updated. */
  containerImagePatch: ContainerImagePatch;
  id: Scalars["Int"]["input"];
};

/** All input for the `updateContainerImageByNameAndTag` mutation. */
export type UpdateContainerImageByNameAndTagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `ContainerImage` being updated. */
  containerImagePatch: ContainerImagePatch;
  name: Scalars["String"]["input"];
  tag: Scalars["String"]["input"];
};

/** All input for the `updateContainerImageByRepositoryAndTag` mutation. */
export type UpdateContainerImageByRepositoryAndTagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `ContainerImage` being updated. */
  containerImagePatch: ContainerImagePatch;
  repository: Scalars["String"]["input"];
  tag: Scalars["String"]["input"];
};

/** All input for the `updateContainerImageByToolboxVersionId` mutation. */
export type UpdateContainerImageByToolboxVersionIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `ContainerImage` being updated. */
  containerImagePatch: ContainerImagePatch;
  toolboxVersionId: Scalars["Int"]["input"];
};

/** The output of our update `ContainerImage` mutation. */
export type UpdateContainerImagePayload = {
  __typename: "UpdateContainerImagePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `ContainerImage` that was updated by this mutation. */
  containerImage: Maybe<ContainerImage>;
  /** An edge for our `ContainerImage`. May be used by Relay 1. */
  containerImageEdge: Maybe<ContainerImagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `TesContainerimageexecutor` that is related to this `ContainerImage`. */
  tesContainerimageexecutorByExecutorId: Maybe<TesContainerimageexecutor>;
  /** Reads a single `ToolboxVersion` that is related to this `ContainerImage`. */
  toolboxVersionByToolboxVersionId: Maybe<ToolboxVersion>;
};

/** The output of our update `ContainerImage` mutation. */
export type UpdateContainerImagePayloadContainerImageEdgeArgs = {
  orderBy?: InputMaybe<Array<ContainerImagesOrderBy>>;
};

/** All input for the `updateCostById` mutation. */
export type UpdateCostByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `Cost` being updated. */
  costPatch: CostPatch;
  id: Scalars["BigInt"]["input"];
};

/** The output of our update `Cost` mutation. */
export type UpdateCostPayload = {
  __typename: "UpdateCostPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Cost` that was updated by this mutation. */
  cost: Maybe<Cost>;
  /** An edge for our `Cost`. May be used by Relay 1. */
  costEdge: Maybe<CostsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Cost`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `Cost` mutation. */
export type UpdateCostPayloadCostEdgeArgs = {
  orderBy?: InputMaybe<Array<CostsOrderBy>>;
};

/** All input for the `updateDatasetById` mutation. */
export type UpdateDatasetByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `Dataset` being updated. */
  datasetPatch: DatasetPatch;
  id: Scalars["UUID"]["input"];
};

/** All input for the `updateDatasetByProjectIdAndPrefix` mutation. */
export type UpdateDatasetByProjectIdAndPrefixInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `Dataset` being updated. */
  datasetPatch: DatasetPatch;
  prefix: Scalars["String"]["input"];
  projectId: Scalars["UUID"]["input"];
};

/** All input for the `updateDatasetByRecordingsTableId` mutation. */
export type UpdateDatasetByRecordingsTableIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `Dataset` being updated. */
  datasetPatch: DatasetPatch;
  recordingsTableId: Scalars["UUID"]["input"];
};

/** All input for the `updateDatasetByTenantIdAndKey` mutation. */
export type UpdateDatasetByTenantIdAndKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `Dataset` being updated. */
  datasetPatch: DatasetPatch;
  key: Scalars["String"]["input"];
  tenantId: Scalars["Int"]["input"];
};

/** All input for the `updateDatasetMetadatumById` mutation. */
export type UpdateDatasetMetadatumByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `DatasetMetadatum` being updated. */
  datasetMetadatumPatch: DatasetMetadatumPatch;
  id: Scalars["UUID"]["input"];
};

/** The output of our update `DatasetMetadatum` mutation. */
export type UpdateDatasetMetadatumPayload = {
  __typename: "UpdateDatasetMetadatumPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Dataset` that is related to this `DatasetMetadatum`. */
  datasetByDatasetId: Maybe<Dataset>;
  /** The `DatasetMetadatum` that was updated by this mutation. */
  datasetMetadatum: Maybe<DatasetMetadatum>;
  /** An edge for our `DatasetMetadatum`. May be used by Relay 1. */
  datasetMetadatumEdge: Maybe<DatasetMetadataEdge>;
  /** Reads a single `Metadatum` that is related to this `DatasetMetadatum`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `DatasetMetadatum`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `DatasetMetadatum`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `DatasetMetadatum` mutation. */
export type UpdateDatasetMetadatumPayloadDatasetMetadatumEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetMetadataOrderBy>>;
};

/** The output of our update `Dataset` mutation. */
export type UpdateDatasetPayload = {
  __typename: "UpdateDatasetPayload";
  /** Reads a single `ApplicationUser` that is related to this `Dataset`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Dataset` that was updated by this mutation. */
  dataset: Maybe<Dataset>;
  /** An edge for our `Dataset`. May be used by Relay 1. */
  datasetEdge: Maybe<DatasetsEdge>;
  /** Reads a single `DatasetRecordingsTable` that is related to this `Dataset`. */
  datasetRecordingsTableByRecordingsTableId: Maybe<DatasetRecordingsTable>;
  /** Reads a single `Metadatum` that is related to this `Dataset`. */
  metadatumByDescriptionId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `Dataset`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Dataset`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `Dataset` mutation. */
export type UpdateDatasetPayloadDatasetEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetsOrderBy>>;
};

/** All input for the `updateDatasetRecordingsTableById` mutation. */
export type UpdateDatasetRecordingsTableByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `DatasetRecordingsTable` being updated. */
  datasetRecordingsTablePatch: DatasetRecordingsTablePatch;
  id: Scalars["UUID"]["input"];
};

/** All input for the `updateDatasetRecordingsTableColumnById` mutation. */
export type UpdateDatasetRecordingsTableColumnByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `DatasetRecordingsTableColumn` being updated. */
  datasetRecordingsTableColumnPatch: DatasetRecordingsTableColumnPatch;
  id: Scalars["UUID"]["input"];
};

/** All input for the `updateDatasetRecordingsTableColumnOrderById` mutation. */
export type UpdateDatasetRecordingsTableColumnOrderByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `DatasetRecordingsTableColumnOrder` being updated. */
  datasetRecordingsTableColumnOrderPatch: DatasetRecordingsTableColumnOrderPatch;
  id: Scalars["UUID"]["input"];
};

/** The output of our update `DatasetRecordingsTableColumnOrder` mutation. */
export type UpdateDatasetRecordingsTableColumnOrderPayload = {
  __typename: "UpdateDatasetRecordingsTableColumnOrderPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DatasetRecordingsTableColumn` that is related to this `DatasetRecordingsTableColumnOrder`. */
  datasetRecordingsTableColumnByDatasetRecordingsTableColumnId: Maybe<DatasetRecordingsTableColumn>;
  /** The `DatasetRecordingsTableColumnOrder` that was updated by this mutation. */
  datasetRecordingsTableColumnOrder: Maybe<DatasetRecordingsTableColumnOrder>;
  /** An edge for our `DatasetRecordingsTableColumnOrder`. May be used by Relay 1. */
  datasetRecordingsTableColumnOrderEdge: Maybe<DatasetRecordingsTableColumnOrdersEdge>;
  /** Reads a single `Project` that is related to this `DatasetRecordingsTableColumnOrder`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `DatasetRecordingsTableColumnOrder`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `DatasetRecordingsTableColumnOrder` mutation. */
export type UpdateDatasetRecordingsTableColumnOrderPayloadDatasetRecordingsTableColumnOrderEdgeArgs =
  {
    orderBy?: InputMaybe<Array<DatasetRecordingsTableColumnOrdersOrderBy>>;
  };

/** The output of our update `DatasetRecordingsTableColumn` mutation. */
export type UpdateDatasetRecordingsTableColumnPayload = {
  __typename: "UpdateDatasetRecordingsTableColumnPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DatasetRecordingsTable` that is related to this `DatasetRecordingsTableColumn`. */
  datasetRecordingsTableByDatasetRecordingsTableId: Maybe<DatasetRecordingsTable>;
  /** The `DatasetRecordingsTableColumn` that was updated by this mutation. */
  datasetRecordingsTableColumn: Maybe<DatasetRecordingsTableColumn>;
  /** An edge for our `DatasetRecordingsTableColumn`. May be used by Relay 1. */
  datasetRecordingsTableColumnEdge: Maybe<DatasetRecordingsTableColumnsEdge>;
  /** Reads a single `Metadatum` that is related to this `DatasetRecordingsTableColumn`. */
  metadatumByColDefId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `DatasetRecordingsTableColumn`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `DatasetRecordingsTableColumn`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `DatasetRecordingsTableColumn` mutation. */
export type UpdateDatasetRecordingsTableColumnPayloadDatasetRecordingsTableColumnEdgeArgs =
  {
    orderBy?: InputMaybe<Array<DatasetRecordingsTableColumnsOrderBy>>;
  };

/** The output of our update `DatasetRecordingsTable` mutation. */
export type UpdateDatasetRecordingsTablePayload = {
  __typename: "UpdateDatasetRecordingsTablePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `DatasetRecordingsTable` that was updated by this mutation. */
  datasetRecordingsTable: Maybe<DatasetRecordingsTable>;
  /** An edge for our `DatasetRecordingsTable`. May be used by Relay 1. */
  datasetRecordingsTableEdge: Maybe<DatasetRecordingsTablesEdge>;
  /** Reads a single `Project` that is related to this `DatasetRecordingsTable`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `DatasetRecordingsTable`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `DatasetRecordingsTable` mutation. */
export type UpdateDatasetRecordingsTablePayloadDatasetRecordingsTableEdgeArgs =
  {
    orderBy?: InputMaybe<Array<DatasetRecordingsTablesOrderBy>>;
  };

/** All input for the `updateDatasetVersionById` mutation. */
export type UpdateDatasetVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `DatasetVersion` being updated. */
  datasetVersionPatch: DatasetVersionPatch;
  id: Scalars["UUID"]["input"];
};

/** The output of our update `DatasetVersion` mutation. */
export type UpdateDatasetVersionPayload = {
  __typename: "UpdateDatasetVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Dataset` that is related to this `DatasetVersion`. */
  datasetByDatasetId: Maybe<Dataset>;
  /** The `DatasetVersion` that was updated by this mutation. */
  datasetVersion: Maybe<DatasetVersion>;
  /** An edge for our `DatasetVersion`. May be used by Relay 1. */
  datasetVersionEdge: Maybe<DatasetVersionsEdge>;
  /** Reads a single `Project` that is related to this `DatasetVersion`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `DatasetVersion`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `DatasetVersion` mutation. */
export type UpdateDatasetVersionPayloadDatasetVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetVersionsOrderBy>>;
};

/** All input for the `updateDjangoAdminLogById` mutation. */
export type UpdateDjangoAdminLogByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `DjangoAdminLog` being updated. */
  djangoAdminLogPatch: DjangoAdminLogPatch;
  id: Scalars["Int"]["input"];
};

/** The output of our update `DjangoAdminLog` mutation. */
export type UpdateDjangoAdminLogPayload = {
  __typename: "UpdateDjangoAdminLogPayload";
  /** Reads a single `ApplicationUser` that is related to this `DjangoAdminLog`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `DjangoAdminLog` that was updated by this mutation. */
  djangoAdminLog: Maybe<DjangoAdminLog>;
  /** An edge for our `DjangoAdminLog`. May be used by Relay 1. */
  djangoAdminLogEdge: Maybe<DjangoAdminLogsEdge>;
  /** Reads a single `DjangoContentType` that is related to this `DjangoAdminLog`. */
  djangoContentTypeByContentTypeId: Maybe<DjangoContentType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `DjangoAdminLog` mutation. */
export type UpdateDjangoAdminLogPayloadDjangoAdminLogEdgeArgs = {
  orderBy?: InputMaybe<Array<DjangoAdminLogsOrderBy>>;
};

/** All input for the `updateDjangoContentTypeByAppLabelAndModel` mutation. */
export type UpdateDjangoContentTypeByAppLabelAndModelInput = {
  appLabel: Scalars["String"]["input"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `DjangoContentType` being updated. */
  djangoContentTypePatch: DjangoContentTypePatch;
  model: Scalars["String"]["input"];
};

/** All input for the `updateDjangoContentTypeById` mutation. */
export type UpdateDjangoContentTypeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `DjangoContentType` being updated. */
  djangoContentTypePatch: DjangoContentTypePatch;
  id: Scalars["Int"]["input"];
};

/** The output of our update `DjangoContentType` mutation. */
export type UpdateDjangoContentTypePayload = {
  __typename: "UpdateDjangoContentTypePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `DjangoContentType` that was updated by this mutation. */
  djangoContentType: Maybe<DjangoContentType>;
  /** An edge for our `DjangoContentType`. May be used by Relay 1. */
  djangoContentTypeEdge: Maybe<DjangoContentTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `DjangoContentType` mutation. */
export type UpdateDjangoContentTypePayloadDjangoContentTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<DjangoContentTypesOrderBy>>;
};

/** All input for the `updateDjangoMigrationById` mutation. */
export type UpdateDjangoMigrationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `DjangoMigration` being updated. */
  djangoMigrationPatch: DjangoMigrationPatch;
  id: Scalars["Int"]["input"];
};

/** The output of our update `DjangoMigration` mutation. */
export type UpdateDjangoMigrationPayload = {
  __typename: "UpdateDjangoMigrationPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `DjangoMigration` that was updated by this mutation. */
  djangoMigration: Maybe<DjangoMigration>;
  /** An edge for our `DjangoMigration`. May be used by Relay 1. */
  djangoMigrationEdge: Maybe<DjangoMigrationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `DjangoMigration` mutation. */
export type UpdateDjangoMigrationPayloadDjangoMigrationEdgeArgs = {
  orderBy?: InputMaybe<Array<DjangoMigrationsOrderBy>>;
};

/** All input for the `updateDjangoSesSesstatByDate` mutation. */
export type UpdateDjangoSesSesstatByDateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  date: Scalars["Date"]["input"];
  /** An object where the defined keys will be set on the `DjangoSesSesstat` being updated. */
  djangoSesSesstatPatch: DjangoSesSesstatPatch;
};

/** All input for the `updateDjangoSesSesstatById` mutation. */
export type UpdateDjangoSesSesstatByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `DjangoSesSesstat` being updated. */
  djangoSesSesstatPatch: DjangoSesSesstatPatch;
  id: Scalars["Int"]["input"];
};

/** The output of our update `DjangoSesSesstat` mutation. */
export type UpdateDjangoSesSesstatPayload = {
  __typename: "UpdateDjangoSesSesstatPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `DjangoSesSesstat` that was updated by this mutation. */
  djangoSesSesstat: Maybe<DjangoSesSesstat>;
  /** An edge for our `DjangoSesSesstat`. May be used by Relay 1. */
  djangoSesSesstatEdge: Maybe<DjangoSesSesstatsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `DjangoSesSesstat` mutation. */
export type UpdateDjangoSesSesstatPayloadDjangoSesSesstatEdgeArgs = {
  orderBy?: InputMaybe<Array<DjangoSesSesstatsOrderBy>>;
};

/** All input for the `updateDjangoSessionBySessionKey` mutation. */
export type UpdateDjangoSessionBySessionKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `DjangoSession` being updated. */
  djangoSessionPatch: DjangoSessionPatch;
  sessionKey: Scalars["String"]["input"];
};

/** The output of our update `DjangoSession` mutation. */
export type UpdateDjangoSessionPayload = {
  __typename: "UpdateDjangoSessionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `DjangoSession` that was updated by this mutation. */
  djangoSession: Maybe<DjangoSession>;
  /** An edge for our `DjangoSession`. May be used by Relay 1. */
  djangoSessionEdge: Maybe<DjangoSessionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `DjangoSession` mutation. */
export type UpdateDjangoSessionPayloadDjangoSessionEdgeArgs = {
  orderBy?: InputMaybe<Array<DjangoSessionsOrderBy>>;
};

/** All input for the `updateDrsFileexecutorById` mutation. */
export type UpdateDrsFileexecutorByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `DrsFileexecutor` being updated. */
  drsFileexecutorPatch: DrsFileexecutorPatch;
  id: Scalars["UUID"]["input"];
};

/** The output of our update `DrsFileexecutor` mutation. */
export type UpdateDrsFileexecutorPayload = {
  __typename: "UpdateDrsFileexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DjangoContentType` that is related to this `DrsFileexecutor`. */
  djangoContentTypeByPolymorphicCtypeId: Maybe<DjangoContentType>;
  /** The `DrsFileexecutor` that was updated by this mutation. */
  drsFileexecutor: Maybe<DrsFileexecutor>;
  /** An edge for our `DrsFileexecutor`. May be used by Relay 1. */
  drsFileexecutorEdge: Maybe<DrsFileexecutorsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `DrsFileexecutor` mutation. */
export type UpdateDrsFileexecutorPayloadDrsFileexecutorEdgeArgs = {
  orderBy?: InputMaybe<Array<DrsFileexecutorsOrderBy>>;
};

/** All input for the `updateDrsMockfileexecutorByFileexecutorPtrId` mutation. */
export type UpdateDrsMockfileexecutorByFileexecutorPtrIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `DrsMockfileexecutor` being updated. */
  drsMockfileexecutorPatch: DrsMockfileexecutorPatch;
  fileexecutorPtrId: Scalars["UUID"]["input"];
};

/** The output of our update `DrsMockfileexecutor` mutation. */
export type UpdateDrsMockfileexecutorPayload = {
  __typename: "UpdateDrsMockfileexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DrsFileexecutor` that is related to this `DrsMockfileexecutor`. */
  drsFileexecutorByFileexecutorPtrId: Maybe<DrsFileexecutor>;
  /** The `DrsMockfileexecutor` that was updated by this mutation. */
  drsMockfileexecutor: Maybe<DrsMockfileexecutor>;
  /** An edge for our `DrsMockfileexecutor`. May be used by Relay 1. */
  drsMockfileexecutorEdge: Maybe<DrsMockfileexecutorsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `DrsMockfileexecutor` mutation. */
export type UpdateDrsMockfileexecutorPayloadDrsMockfileexecutorEdgeArgs = {
  orderBy?: InputMaybe<Array<DrsMockfileexecutorsOrderBy>>;
};

/** All input for the `updateEulaVersionById` mutation. */
export type UpdateEulaVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `EulaVersion` being updated. */
  eulaVersionPatch: EulaVersionPatch;
  id: Scalars["Int"]["input"];
};

/** All input for the `updateEulaVersionByVersion` mutation. */
export type UpdateEulaVersionByVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `EulaVersion` being updated. */
  eulaVersionPatch: EulaVersionPatch;
  version: Scalars["String"]["input"];
};

/** The output of our update `EulaVersion` mutation. */
export type UpdateEulaVersionPayload = {
  __typename: "UpdateEulaVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `EulaVersion` that was updated by this mutation. */
  eulaVersion: Maybe<EulaVersion>;
  /** An edge for our `EulaVersion`. May be used by Relay 1. */
  eulaVersionEdge: Maybe<EulaVersionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `EulaVersion` mutation. */
export type UpdateEulaVersionPayloadEulaVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<EulaVersionsOrderBy>>;
};

/** All input for the `updateEulaVersionUserAcceptanceById` mutation. */
export type UpdateEulaVersionUserAcceptanceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `EulaVersionUserAcceptance` being updated. */
  eulaVersionUserAcceptancePatch: EulaVersionUserAcceptancePatch;
  id: Scalars["UUID"]["input"];
};

/** All input for the `updateEulaVersionUserAcceptanceByUserIdAndEulaVersionId` mutation. */
export type UpdateEulaVersionUserAcceptanceByUserIdAndEulaVersionIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  eulaVersionId: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `EulaVersionUserAcceptance` being updated. */
  eulaVersionUserAcceptancePatch: EulaVersionUserAcceptancePatch;
  userId: Scalars["UUID"]["input"];
};

/** The output of our update `EulaVersionUserAcceptance` mutation. */
export type UpdateEulaVersionUserAcceptancePayload = {
  __typename: "UpdateEulaVersionUserAcceptancePayload";
  /** Reads a single `ApplicationUser` that is related to this `EulaVersionUserAcceptance`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `EulaVersion` that is related to this `EulaVersionUserAcceptance`. */
  eulaVersionByEulaVersionId: Maybe<EulaVersion>;
  /** The `EulaVersionUserAcceptance` that was updated by this mutation. */
  eulaVersionUserAcceptance: Maybe<EulaVersionUserAcceptance>;
  /** An edge for our `EulaVersionUserAcceptance`. May be used by Relay 1. */
  eulaVersionUserAcceptanceEdge: Maybe<EulaVersionUserAcceptancesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `EulaVersionUserAcceptance` mutation. */
export type UpdateEulaVersionUserAcceptancePayloadEulaVersionUserAcceptanceEdgeArgs =
  {
    orderBy?: InputMaybe<Array<EulaVersionUserAcceptancesOrderBy>>;
  };

/** All input for the `updateExplorerQueryById` mutation. */
export type UpdateExplorerQueryByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `ExplorerQuery` being updated. */
  explorerQueryPatch: ExplorerQueryPatch;
  id: Scalars["Int"]["input"];
};

/** The output of our update `ExplorerQuery` mutation. */
export type UpdateExplorerQueryPayload = {
  __typename: "UpdateExplorerQueryPayload";
  /** Reads a single `ApplicationUser` that is related to this `ExplorerQuery`. */
  applicationUserByCreatedByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `ExplorerQuery` that was updated by this mutation. */
  explorerQuery: Maybe<ExplorerQuery>;
  /** An edge for our `ExplorerQuery`. May be used by Relay 1. */
  explorerQueryEdge: Maybe<ExplorerQueriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `ExplorerQuery` mutation. */
export type UpdateExplorerQueryPayloadExplorerQueryEdgeArgs = {
  orderBy?: InputMaybe<Array<ExplorerQueriesOrderBy>>;
};

/** All input for the `updateExplorerQueryfavoriteById` mutation. */
export type UpdateExplorerQueryfavoriteByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `ExplorerQueryfavorite` being updated. */
  explorerQueryfavoritePatch: ExplorerQueryfavoritePatch;
  id: Scalars["Int"]["input"];
};

/** All input for the `updateExplorerQueryfavoriteByQueryIdAndUserId` mutation. */
export type UpdateExplorerQueryfavoriteByQueryIdAndUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `ExplorerQueryfavorite` being updated. */
  explorerQueryfavoritePatch: ExplorerQueryfavoritePatch;
  queryId: Scalars["Int"]["input"];
  userId: Scalars["UUID"]["input"];
};

/** The output of our update `ExplorerQueryfavorite` mutation. */
export type UpdateExplorerQueryfavoritePayload = {
  __typename: "UpdateExplorerQueryfavoritePayload";
  /** Reads a single `ApplicationUser` that is related to this `ExplorerQueryfavorite`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `ExplorerQuery` that is related to this `ExplorerQueryfavorite`. */
  explorerQueryByQueryId: Maybe<ExplorerQuery>;
  /** The `ExplorerQueryfavorite` that was updated by this mutation. */
  explorerQueryfavorite: Maybe<ExplorerQueryfavorite>;
  /** An edge for our `ExplorerQueryfavorite`. May be used by Relay 1. */
  explorerQueryfavoriteEdge: Maybe<ExplorerQueryfavoritesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `ExplorerQueryfavorite` mutation. */
export type UpdateExplorerQueryfavoritePayloadExplorerQueryfavoriteEdgeArgs = {
  orderBy?: InputMaybe<Array<ExplorerQueryfavoritesOrderBy>>;
};

/** All input for the `updateExplorerQuerylogById` mutation. */
export type UpdateExplorerQuerylogByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `ExplorerQuerylog` being updated. */
  explorerQuerylogPatch: ExplorerQuerylogPatch;
  id: Scalars["Int"]["input"];
};

/** The output of our update `ExplorerQuerylog` mutation. */
export type UpdateExplorerQuerylogPayload = {
  __typename: "UpdateExplorerQuerylogPayload";
  /** Reads a single `ApplicationUser` that is related to this `ExplorerQuerylog`. */
  applicationUserByRunByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `ExplorerQuery` that is related to this `ExplorerQuerylog`. */
  explorerQueryByQueryId: Maybe<ExplorerQuery>;
  /** The `ExplorerQuerylog` that was updated by this mutation. */
  explorerQuerylog: Maybe<ExplorerQuerylog>;
  /** An edge for our `ExplorerQuerylog`. May be used by Relay 1. */
  explorerQuerylogEdge: Maybe<ExplorerQuerylogsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `ExplorerQuerylog` mutation. */
export type UpdateExplorerQuerylogPayloadExplorerQuerylogEdgeArgs = {
  orderBy?: InputMaybe<Array<ExplorerQuerylogsOrderBy>>;
};

/** All input for the `updateFileActivityById` mutation. */
export type UpdateFileActivityByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `FileActivity` being updated. */
  fileActivityPatch: FileActivityPatch;
  id: Scalars["Int"]["input"];
};

/** The output of our update `FileActivity` mutation. */
export type UpdateFileActivityPayload = {
  __typename: "UpdateFileActivityPayload";
  /** Reads a single `ApplicationUser` that is related to this `FileActivity`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Cost` that is related to this `FileActivity`. */
  costByCostId: Maybe<Cost>;
  /** The `FileActivity` that was updated by this mutation. */
  fileActivity: Maybe<FileActivity>;
  /** An edge for our `FileActivity`. May be used by Relay 1. */
  fileActivityEdge: Maybe<FileActivitiesEdge>;
  /** Reads a single `File` that is related to this `FileActivity`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `Project` that is related to this `FileActivity`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `FileActivity`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `FileActivity` mutation. */
export type UpdateFileActivityPayloadFileActivityEdgeArgs = {
  orderBy?: InputMaybe<Array<FileActivitiesOrderBy>>;
};

/** All input for the `updateFileById` mutation. */
export type UpdateFileByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `File` being updated. */
  filePatch: FilePatch;
  id: Scalars["UUID"]["input"];
};

/** All input for the `updateFileMetadatumById` mutation. */
export type UpdateFileMetadatumByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `FileMetadatum` being updated. */
  fileMetadatumPatch: FileMetadatumPatch;
  id: Scalars["UUID"]["input"];
};

/** The output of our update `FileMetadatum` mutation. */
export type UpdateFileMetadatumPayload = {
  __typename: "UpdateFileMetadatumPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `FileMetadatum`. */
  fileByFileId: Maybe<File>;
  /** The `FileMetadatum` that was updated by this mutation. */
  fileMetadatum: Maybe<FileMetadatum>;
  /** An edge for our `FileMetadatum`. May be used by Relay 1. */
  fileMetadatumEdge: Maybe<FileMetadataEdge>;
  /** Reads a single `Metadatum` that is related to this `FileMetadatum`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `FileMetadatum`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `FileMetadatum`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `FileMetadatum` mutation. */
export type UpdateFileMetadatumPayloadFileMetadatumEdgeArgs = {
  orderBy?: InputMaybe<Array<FileMetadataOrderBy>>;
};

/** All input for the `updateFileOutputGroupById` mutation. */
export type UpdateFileOutputGroupByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `FileOutputGroup` being updated. */
  fileOutputGroupPatch: FileOutputGroupPatch;
  id: Scalars["UUID"]["input"];
};

/** All input for the `updateFileOutputGroupByTaskId` mutation. */
export type UpdateFileOutputGroupByTaskIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `FileOutputGroup` being updated. */
  fileOutputGroupPatch: FileOutputGroupPatch;
  taskId: Scalars["UUID"]["input"];
};

/** All input for the `updateFileOutputGroupMetadatumById` mutation. */
export type UpdateFileOutputGroupMetadatumByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `FileOutputGroupMetadatum` being updated. */
  fileOutputGroupMetadatumPatch: FileOutputGroupMetadatumPatch;
  id: Scalars["UUID"]["input"];
};

/** The output of our update `FileOutputGroupMetadatum` mutation. */
export type UpdateFileOutputGroupMetadatumPayload = {
  __typename: "UpdateFileOutputGroupMetadatumPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `FileOutputGroup` that is related to this `FileOutputGroupMetadatum`. */
  fileOutputGroupByFileOutputGroupId: Maybe<FileOutputGroup>;
  /** The `FileOutputGroupMetadatum` that was updated by this mutation. */
  fileOutputGroupMetadatum: Maybe<FileOutputGroupMetadatum>;
  /** An edge for our `FileOutputGroupMetadatum`. May be used by Relay 1. */
  fileOutputGroupMetadatumEdge: Maybe<FileOutputGroupMetadataEdge>;
  /** Reads a single `Metadatum` that is related to this `FileOutputGroupMetadatum`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `FileOutputGroupMetadatum`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `FileOutputGroupMetadatum`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `FileOutputGroupMetadatum` mutation. */
export type UpdateFileOutputGroupMetadatumPayloadFileOutputGroupMetadatumEdgeArgs =
  {
    orderBy?: InputMaybe<Array<FileOutputGroupMetadataOrderBy>>;
  };

/** The output of our update `FileOutputGroup` mutation. */
export type UpdateFileOutputGroupPayload = {
  __typename: "UpdateFileOutputGroupPayload";
  /** Reads a single `ApplicationUser` that is related to this `FileOutputGroup`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `FileOutputGroup` that was updated by this mutation. */
  fileOutputGroup: Maybe<FileOutputGroup>;
  /** An edge for our `FileOutputGroup`. May be used by Relay 1. */
  fileOutputGroupEdge: Maybe<FileOutputGroupsEdge>;
  /** Reads a single `Project` that is related to this `FileOutputGroup`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Task` that is related to this `FileOutputGroup`. */
  taskByTaskId: Maybe<Task>;
  /** Reads a single `Tenant` that is related to this `FileOutputGroup`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `FileOutputGroup` mutation. */
export type UpdateFileOutputGroupPayloadFileOutputGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<FileOutputGroupsOrderBy>>;
};

/** The output of our update `File` mutation. */
export type UpdateFilePayload = {
  __typename: "UpdateFilePayload";
  /** Reads a single `ApplicationUser` that is related to this `File`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Dataset` that is related to this `File`. */
  datasetByDatasetId: Maybe<Dataset>;
  /** The `File` that was updated by this mutation. */
  file: Maybe<File>;
  /** Reads a single `File` that is related to this `File`. */
  fileBySeriesParentId: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge: Maybe<FilesEdge>;
  /** Reads a single `Project` that is related to this `File`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `File`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `File` mutation. */
export type UpdateFilePayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the `updateFileRecordingGroupById` mutation. */
export type UpdateFileRecordingGroupByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `FileRecordingGroup` being updated. */
  fileRecordingGroupPatch: FileRecordingGroupPatch;
  id: Scalars["UUID"]["input"];
};

/** All input for the `updateFileRecordingGroupMetadatumById` mutation. */
export type UpdateFileRecordingGroupMetadatumByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `FileRecordingGroupMetadatum` being updated. */
  fileRecordingGroupMetadatumPatch: FileRecordingGroupMetadatumPatch;
  id: Scalars["UUID"]["input"];
};

/** The output of our update `FileRecordingGroupMetadatum` mutation. */
export type UpdateFileRecordingGroupMetadatumPayload = {
  __typename: "UpdateFileRecordingGroupMetadatumPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `FileRecordingGroup` that is related to this `FileRecordingGroupMetadatum`. */
  fileRecordingGroupByFileRecordingGroupId: Maybe<FileRecordingGroup>;
  /** The `FileRecordingGroupMetadatum` that was updated by this mutation. */
  fileRecordingGroupMetadatum: Maybe<FileRecordingGroupMetadatum>;
  /** An edge for our `FileRecordingGroupMetadatum`. May be used by Relay 1. */
  fileRecordingGroupMetadatumEdge: Maybe<FileRecordingGroupMetadataEdge>;
  /** Reads a single `Metadatum` that is related to this `FileRecordingGroupMetadatum`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `FileRecordingGroupMetadatum`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `FileRecordingGroupMetadatum`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `FileRecordingGroupMetadatum` mutation. */
export type UpdateFileRecordingGroupMetadatumPayloadFileRecordingGroupMetadatumEdgeArgs =
  {
    orderBy?: InputMaybe<Array<FileRecordingGroupMetadataOrderBy>>;
  };

/** The output of our update `FileRecordingGroup` mutation. */
export type UpdateFileRecordingGroupPayload = {
  __typename: "UpdateFileRecordingGroupPayload";
  /** Reads a single `ApplicationUser` that is related to this `FileRecordingGroup`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DatasetRecordingsTable` that is related to this `FileRecordingGroup`. */
  datasetRecordingsTableByDatasetRecordingsTableId: Maybe<DatasetRecordingsTable>;
  /** The `FileRecordingGroup` that was updated by this mutation. */
  fileRecordingGroup: Maybe<FileRecordingGroup>;
  /** An edge for our `FileRecordingGroup`. May be used by Relay 1. */
  fileRecordingGroupEdge: Maybe<FileRecordingGroupsEdge>;
  /** Reads a single `Project` that is related to this `FileRecordingGroup`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `FileRecordingGroup`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `FileRecordingGroup` mutation. */
export type UpdateFileRecordingGroupPayloadFileRecordingGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<FileRecordingGroupsOrderBy>>;
};

/** All input for the `updateMetadataValueById` mutation. */
export type UpdateMetadataValueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `MetadataValue` being updated. */
  metadataValuePatch: MetadataValuePatch;
};

/** The output of our update `MetadataValue` mutation. */
export type UpdateMetadataValuePayload = {
  __typename: "UpdateMetadataValuePayload";
  /** Reads a single `ApplicationUser` that is related to this `MetadataValue`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `MetadataValue` that was updated by this mutation. */
  metadataValue: Maybe<MetadataValue>;
  /** An edge for our `MetadataValue`. May be used by Relay 1. */
  metadataValueEdge: Maybe<MetadataValuesEdge>;
  /** Reads a single `Metadatum` that is related to this `MetadataValue`. */
  metadatumByMetadataId: Maybe<Metadatum>;
  /** Reads a single `Project` that is related to this `MetadataValue`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Task` that is related to this `MetadataValue`. */
  taskByTaskId: Maybe<Task>;
  /** Reads a single `Tenant` that is related to this `MetadataValue`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `MetadataValue` mutation. */
export type UpdateMetadataValuePayloadMetadataValueEdgeArgs = {
  orderBy?: InputMaybe<Array<MetadataValuesOrderBy>>;
};

/** All input for the `updateMetadatumById` mutation. */
export type UpdateMetadatumByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `Metadatum` being updated. */
  metadatumPatch: MetadatumPatch;
};

/** The output of our update `Metadatum` mutation. */
export type UpdateMetadatumPayload = {
  __typename: "UpdateMetadatumPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Metadatum` that was updated by this mutation. */
  metadatum: Maybe<Metadatum>;
  /** An edge for our `Metadatum`. May be used by Relay 1. */
  metadatumEdge: Maybe<MetadataEdge>;
  /** Reads a single `Project` that is related to this `Metadatum`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Metadatum`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `Metadatum` mutation. */
export type UpdateMetadatumPayloadMetadatumEdgeArgs = {
  orderBy?: InputMaybe<Array<MetadataOrderBy>>;
};

/** All input for the `updateNotificationById` mutation. */
export type UpdateNotificationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `Notification` being updated. */
  notificationPatch: NotificationPatch;
};

/** The output of our update `Notification` mutation. */
export type UpdateNotificationPayload = {
  __typename: "UpdateNotificationPayload";
  /** Reads a single `ApplicationUser` that is related to this `Notification`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** The `Notification` that was updated by this mutation. */
  notification: Maybe<Notification>;
  /** An edge for our `Notification`. May be used by Relay 1. */
  notificationEdge: Maybe<NotificationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `Notification` mutation. */
export type UpdateNotificationPayloadNotificationEdgeArgs = {
  orderBy?: InputMaybe<Array<NotificationsOrderBy>>;
};

/** All input for the `updateOutputGroupFileById` mutation. */
export type UpdateOutputGroupFileByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `OutputGroupFile` being updated. */
  outputGroupFilePatch: OutputGroupFilePatch;
};

/** The output of our update `OutputGroupFile` mutation. */
export type UpdateOutputGroupFilePayload = {
  __typename: "UpdateOutputGroupFilePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `OutputGroupFile`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `FileOutputGroup` that is related to this `OutputGroupFile`. */
  fileOutputGroupByFileOutputGroupId: Maybe<FileOutputGroup>;
  /** The `OutputGroupFile` that was updated by this mutation. */
  outputGroupFile: Maybe<OutputGroupFile>;
  /** An edge for our `OutputGroupFile`. May be used by Relay 1. */
  outputGroupFileEdge: Maybe<OutputGroupFilesEdge>;
  /** Reads a single `Project` that is related to this `OutputGroupFile`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our update `OutputGroupFile` mutation. */
export type UpdateOutputGroupFilePayloadOutputGroupFileEdgeArgs = {
  orderBy?: InputMaybe<Array<OutputGroupFilesOrderBy>>;
};

/** All input for the `updateRecordingGroupFileById` mutation. */
export type UpdateRecordingGroupFileByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `RecordingGroupFile` being updated. */
  recordingGroupFilePatch: RecordingGroupFilePatch;
};

/** The output of our update `RecordingGroupFile` mutation. */
export type UpdateRecordingGroupFilePayload = {
  __typename: "UpdateRecordingGroupFilePayload";
  /** Reads a single `ApplicationUser` that is related to this `RecordingGroupFile`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DatasetRecordingsTableColumn` that is related to this `RecordingGroupFile`. */
  datasetRecordingsTableColumnByDatasetRecordingsTableColumnId: Maybe<DatasetRecordingsTableColumn>;
  /** Reads a single `File` that is related to this `RecordingGroupFile`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `FileRecordingGroup` that is related to this `RecordingGroupFile`. */
  fileRecordingGroupByFileRecordingGroupId: Maybe<FileRecordingGroup>;
  /** Reads a single `Project` that is related to this `RecordingGroupFile`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `RecordingGroupFile` that was updated by this mutation. */
  recordingGroupFile: Maybe<RecordingGroupFile>;
  /** An edge for our `RecordingGroupFile`. May be used by Relay 1. */
  recordingGroupFileEdge: Maybe<RecordingGroupFilesEdge>;
  /** Reads a single `Tenant` that is related to this `RecordingGroupFile`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `RecordingGroupFile` mutation. */
export type UpdateRecordingGroupFilePayloadRecordingGroupFileEdgeArgs = {
  orderBy?: InputMaybe<Array<RecordingGroupFilesOrderBy>>;
};

/** All input for the `updateRunById` mutation. */
export type UpdateRunByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `Run` being updated. */
  runPatch: RunPatch;
};

/** The output of our update `Run` mutation. */
export type UpdateRunPayload = {
  __typename: "UpdateRunPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `Run` that was updated by this mutation. */
  run: Maybe<Run>;
  /** An edge for our `Run`. May be used by Relay 1. */
  runEdge: Maybe<RunsEdge>;
  /** Reads a single `Tenant` that is related to this `Run`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `Run` mutation. */
export type UpdateRunPayloadRunEdgeArgs = {
  orderBy?: InputMaybe<Array<RunsOrderBy>>;
};

/** All input for the `updateTagById` mutation. */
export type UpdateTagByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `Tag` being updated. */
  tagPatch: TagPatch;
};

/** The output of our update `Tag` mutation. */
export type UpdateTagPayload = {
  __typename: "UpdateTagPayload";
  /** Reads a single `ApplicationUser` that is related to this `Tag`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `Tag` that was updated by this mutation. */
  tag: Maybe<Tag>;
  /** Reads a single `Tag` that is related to this `Tag`. */
  tagByParentId: Maybe<Tag>;
  /** An edge for our `Tag`. May be used by Relay 1. */
  tagEdge: Maybe<TagsEdge>;
  /** Reads a single `Tenant` that is related to this `Tag`. */
  tenantByTenantId: Maybe<Tenant>;
};

/** The output of our update `Tag` mutation. */
export type UpdateTagPayloadTagEdgeArgs = {
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
};

/** All input for the `updateTaskActivityById` mutation. */
export type UpdateTaskActivityByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `TaskActivity` being updated. */
  taskActivityPatch: TaskActivityPatch;
};

/** All input for the `updateTaskActivityByTaskId` mutation. */
export type UpdateTaskActivityByTaskIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `TaskActivity` being updated. */
  taskActivityPatch: TaskActivityPatch;
  taskId: Scalars["UUID"]["input"];
};

/** The output of our update `TaskActivity` mutation. */
export type UpdateTaskActivityPayload = {
  __typename: "UpdateTaskActivityPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Cost` that is related to this `TaskActivity`. */
  costByCostId: Maybe<Cost>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TaskActivity` that was updated by this mutation. */
  taskActivity: Maybe<TaskActivity>;
  /** An edge for our `TaskActivity`. May be used by Relay 1. */
  taskActivityEdge: Maybe<TaskActivitiesEdge>;
  /** Reads a single `Task` that is related to this `TaskActivity`. */
  taskByTaskId: Maybe<Task>;
};

/** The output of our update `TaskActivity` mutation. */
export type UpdateTaskActivityPayloadTaskActivityEdgeArgs = {
  orderBy?: InputMaybe<Array<TaskActivitiesOrderBy>>;
};

/** All input for the `updateTaskByExecutorId` mutation. */
export type UpdateTaskByExecutorIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  executorId: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `Task` being updated. */
  taskPatch: TaskPatch;
};

/** All input for the `updateTaskById` mutation. */
export type UpdateTaskByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `Task` being updated. */
  taskPatch: TaskPatch;
};

/** The output of our update `Task` mutation. */
export type UpdateTaskPayload = {
  __typename: "UpdateTaskPayload";
  /** Reads a single `ApplicationUser` that is related to this `Task`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `ContainerImage` that is related to this `Task`. */
  containerImageByContainerImageId: Maybe<ContainerImage>;
  /** Reads a single `Dataset` that is related to this `Task`. */
  datasetByDatasetId: Maybe<Dataset>;
  /** Reads a single `Project` that is related to this `Task`. */
  projectByProjectId: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `Task` that was updated by this mutation. */
  task: Maybe<Task>;
  /** An edge for our `Task`. May be used by Relay 1. */
  taskEdge: Maybe<TasksEdge>;
  /** Reads a single `Tenant` that is related to this `Task`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `TesTaskexecutor` that is related to this `Task`. */
  tesTaskexecutorByExecutorId: Maybe<TesTaskexecutor>;
  /** Reads a single `ToolVersion` that is related to this `Task`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our update `Task` mutation. */
export type UpdateTaskPayloadTaskEdgeArgs = {
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
};

/** All input for the `updateTaskResourceById` mutation. */
export type UpdateTaskResourceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `TaskResource` being updated. */
  taskResourcePatch: TaskResourcePatch;
};

/** The output of our update `TaskResource` mutation. */
export type UpdateTaskResourcePayload = {
  __typename: "UpdateTaskResourcePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Task` that is related to this `TaskResource`. */
  taskByTaskId: Maybe<Task>;
  /** The `TaskResource` that was updated by this mutation. */
  taskResource: Maybe<TaskResource>;
  /** An edge for our `TaskResource`. May be used by Relay 1. */
  taskResourceEdge: Maybe<TaskResourcesEdge>;
};

/** The output of our update `TaskResource` mutation. */
export type UpdateTaskResourcePayloadTaskResourceEdgeArgs = {
  orderBy?: InputMaybe<Array<TaskResourcesOrderBy>>;
};

/** All input for the `updateTaskSourceById` mutation. */
export type UpdateTaskSourceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `TaskSource` being updated. */
  taskSourcePatch: TaskSourcePatch;
};

/** The output of our update `TaskSource` mutation. */
export type UpdateTaskSourcePayload = {
  __typename: "UpdateTaskSourcePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `File` that is related to this `TaskSource`. */
  fileByFileId: Maybe<File>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Task` that is related to this `TaskSource`. */
  taskByTaskId: Maybe<Task>;
  /** The `TaskSource` that was updated by this mutation. */
  taskSource: Maybe<TaskSource>;
  /** An edge for our `TaskSource`. May be used by Relay 1. */
  taskSourceEdge: Maybe<TaskSourcesEdge>;
  /** Reads a single `ToolSource` that is related to this `TaskSource`. */
  toolSourceByToolSourceId: Maybe<ToolSource>;
};

/** The output of our update `TaskSource` mutation. */
export type UpdateTaskSourcePayloadTaskSourceEdgeArgs = {
  orderBy?: InputMaybe<Array<TaskSourcesOrderBy>>;
};

/** All input for the `updateTaskStatusLogById` mutation. */
export type UpdateTaskStatusLogByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `TaskStatusLog` being updated. */
  taskStatusLogPatch: TaskStatusLogPatch;
};

/** All input for the `updateTaskStatusLogByTaskIdAndStatus` mutation. */
export type UpdateTaskStatusLogByTaskIdAndStatusInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  status: Scalars["Int"]["input"];
  taskId: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `TaskStatusLog` being updated. */
  taskStatusLogPatch: TaskStatusLogPatch;
};

/** The output of our update `TaskStatusLog` mutation. */
export type UpdateTaskStatusLogPayload = {
  __typename: "UpdateTaskStatusLogPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Task` that is related to this `TaskStatusLog`. */
  taskByTaskId: Maybe<Task>;
  /** The `TaskStatusLog` that was updated by this mutation. */
  taskStatusLog: Maybe<TaskStatusLog>;
  /** An edge for our `TaskStatusLog`. May be used by Relay 1. */
  taskStatusLogEdge: Maybe<TaskStatusLogsEdge>;
};

/** The output of our update `TaskStatusLog` mutation. */
export type UpdateTaskStatusLogPayloadTaskStatusLogEdgeArgs = {
  orderBy?: InputMaybe<Array<TaskStatusLogsOrderBy>>;
};

/** All input for the `updateTenantById` mutation. */
export type UpdateTenantByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `Tenant` being updated. */
  tenantPatch: TenantPatch;
};

/** All input for the `updateTenantByKey` mutation. */
export type UpdateTenantByKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
  /** An object where the defined keys will be set on the `Tenant` being updated. */
  tenantPatch: TenantPatch;
};

/** All input for the `updateTenantByName` mutation. */
export type UpdateTenantByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  /** An object where the defined keys will be set on the `Tenant` being updated. */
  tenantPatch: TenantPatch;
};

/** All input for the `updateTenantContractAllowanceById` mutation. */
export type UpdateTenantContractAllowanceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `TenantContractAllowance` being updated. */
  tenantContractAllowancePatch: TenantContractAllowancePatch;
};

/** All input for the `updateTenantContractAllowanceByTenantContractTransactionIdAndQuotaType` mutation. */
export type UpdateTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
    quotaType: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `TenantContractAllowance` being updated. */
    tenantContractAllowancePatch: TenantContractAllowancePatch;
    tenantContractTransactionId: Scalars["Int"]["input"];
  };

/** The output of our update `TenantContractAllowance` mutation. */
export type UpdateTenantContractAllowancePayload = {
  __typename: "UpdateTenantContractAllowancePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TenantContractAllowance` that was updated by this mutation. */
  tenantContractAllowance: Maybe<TenantContractAllowance>;
  /** An edge for our `TenantContractAllowance`. May be used by Relay 1. */
  tenantContractAllowanceEdge: Maybe<TenantContractAllowancesEdge>;
  /** Reads a single `TenantContractTransaction` that is related to this `TenantContractAllowance`. */
  tenantContractTransactionByTenantContractTransactionId: Maybe<TenantContractTransaction>;
};

/** The output of our update `TenantContractAllowance` mutation. */
export type UpdateTenantContractAllowancePayloadTenantContractAllowanceEdgeArgs =
  {
    orderBy?: InputMaybe<Array<TenantContractAllowancesOrderBy>>;
  };

/** All input for the `updateTenantContractById` mutation. */
export type UpdateTenantContractByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `TenantContract` being updated. */
  tenantContractPatch: TenantContractPatch;
};

/** All input for the `updateTenantContractByName` mutation. */
export type UpdateTenantContractByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  /** An object where the defined keys will be set on the `TenantContract` being updated. */
  tenantContractPatch: TenantContractPatch;
};

/** The output of our update `TenantContract` mutation. */
export type UpdateTenantContractPayload = {
  __typename: "UpdateTenantContractPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `TenantContract`. */
  tenantByTenantId: Maybe<Tenant>;
  /** The `TenantContract` that was updated by this mutation. */
  tenantContract: Maybe<TenantContract>;
  /** An edge for our `TenantContract`. May be used by Relay 1. */
  tenantContractEdge: Maybe<TenantContractsEdge>;
};

/** The output of our update `TenantContract` mutation. */
export type UpdateTenantContractPayloadTenantContractEdgeArgs = {
  orderBy?: InputMaybe<Array<TenantContractsOrderBy>>;
};

/** All input for the `updateTenantContractTransactionById` mutation. */
export type UpdateTenantContractTransactionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `TenantContractTransaction` being updated. */
  tenantContractTransactionPatch: TenantContractTransactionPatch;
};

/** The output of our update `TenantContractTransaction` mutation. */
export type UpdateTenantContractTransactionPayload = {
  __typename: "UpdateTenantContractTransactionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `TenantContract` that is related to this `TenantContractTransaction`. */
  tenantContractByTenantContractId: Maybe<TenantContract>;
  /** The `TenantContractTransaction` that was updated by this mutation. */
  tenantContractTransaction: Maybe<TenantContractTransaction>;
  /** An edge for our `TenantContractTransaction`. May be used by Relay 1. */
  tenantContractTransactionEdge: Maybe<TenantContractTransactionsEdge>;
};

/** The output of our update `TenantContractTransaction` mutation. */
export type UpdateTenantContractTransactionPayloadTenantContractTransactionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<TenantContractTransactionsOrderBy>>;
  };

/** All input for the `updateTenantMockuserexecutorByUserexecutorPtrId` mutation. */
export type UpdateTenantMockuserexecutorByUserexecutorPtrIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `TenantMockuserexecutor` being updated. */
  tenantMockuserexecutorPatch: TenantMockuserexecutorPatch;
  userexecutorPtrId: Scalars["Int"]["input"];
};

/** The output of our update `TenantMockuserexecutor` mutation. */
export type UpdateTenantMockuserexecutorPayload = {
  __typename: "UpdateTenantMockuserexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TenantMockuserexecutor` that was updated by this mutation. */
  tenantMockuserexecutor: Maybe<TenantMockuserexecutor>;
  /** An edge for our `TenantMockuserexecutor`. May be used by Relay 1. */
  tenantMockuserexecutorEdge: Maybe<TenantMockuserexecutorsEdge>;
  /** Reads a single `TenantUserexecutor` that is related to this `TenantMockuserexecutor`. */
  tenantUserexecutorByUserexecutorPtrId: Maybe<TenantUserexecutor>;
};

/** The output of our update `TenantMockuserexecutor` mutation. */
export type UpdateTenantMockuserexecutorPayloadTenantMockuserexecutorEdgeArgs =
  {
    orderBy?: InputMaybe<Array<TenantMockuserexecutorsOrderBy>>;
  };

/** All input for the `updateTenantMonthlyConsumedResourceById` mutation. */
export type UpdateTenantMonthlyConsumedResourceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `TenantMonthlyConsumedResource` being updated. */
  tenantMonthlyConsumedResourcePatch: TenantMonthlyConsumedResourcePatch;
};

/** All input for the `updateTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract` mutation. */
export type UpdateTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
    month: Scalars["Date"]["input"];
    monthInActiveContract: Scalars["Int"]["input"];
    quotaType: Scalars["Int"]["input"];
    tenantContractId: Scalars["Int"]["input"];
    tenantId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `TenantMonthlyConsumedResource` being updated. */
    tenantMonthlyConsumedResourcePatch: TenantMonthlyConsumedResourcePatch;
    userId: Scalars["UUID"]["input"];
  };

/** The output of our update `TenantMonthlyConsumedResource` mutation. */
export type UpdateTenantMonthlyConsumedResourcePayload = {
  __typename: "UpdateTenantMonthlyConsumedResourcePayload";
  /** Reads a single `ApplicationUser` that is related to this `TenantMonthlyConsumedResource`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `TenantMonthlyConsumedResource`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `TenantContract` that is related to this `TenantMonthlyConsumedResource`. */
  tenantContractByTenantContractId: Maybe<TenantContract>;
  /** The `TenantMonthlyConsumedResource` that was updated by this mutation. */
  tenantMonthlyConsumedResource: Maybe<TenantMonthlyConsumedResource>;
  /** An edge for our `TenantMonthlyConsumedResource`. May be used by Relay 1. */
  tenantMonthlyConsumedResourceEdge: Maybe<TenantMonthlyConsumedResourcesEdge>;
};

/** The output of our update `TenantMonthlyConsumedResource` mutation. */
export type UpdateTenantMonthlyConsumedResourcePayloadTenantMonthlyConsumedResourceEdgeArgs =
  {
    orderBy?: InputMaybe<Array<TenantMonthlyConsumedResourcesOrderBy>>;
  };

/** The output of our update `Tenant` mutation. */
export type UpdateTenantPayload = {
  __typename: "UpdateTenantPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `Tenant` that was updated by this mutation. */
  tenant: Maybe<Tenant>;
  /** An edge for our `Tenant`. May be used by Relay 1. */
  tenantEdge: Maybe<TenantsEdge>;
};

/** The output of our update `Tenant` mutation. */
export type UpdateTenantPayloadTenantEdgeArgs = {
  orderBy?: InputMaybe<Array<TenantsOrderBy>>;
};

/** All input for the `updateTenantUserexecutorById` mutation. */
export type UpdateTenantUserexecutorByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `TenantUserexecutor` being updated. */
  tenantUserexecutorPatch: TenantUserexecutorPatch;
};

/** The output of our update `TenantUserexecutor` mutation. */
export type UpdateTenantUserexecutorPayload = {
  __typename: "UpdateTenantUserexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DjangoContentType` that is related to this `TenantUserexecutor`. */
  djangoContentTypeByPolymorphicCtypeId: Maybe<DjangoContentType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TenantUserexecutor` that was updated by this mutation. */
  tenantUserexecutor: Maybe<TenantUserexecutor>;
  /** An edge for our `TenantUserexecutor`. May be used by Relay 1. */
  tenantUserexecutorEdge: Maybe<TenantUserexecutorsEdge>;
};

/** The output of our update `TenantUserexecutor` mutation. */
export type UpdateTenantUserexecutorPayloadTenantUserexecutorEdgeArgs = {
  orderBy?: InputMaybe<Array<TenantUserexecutorsOrderBy>>;
};

/** All input for the `updateTesContainerimageexecutorById` mutation. */
export type UpdateTesContainerimageexecutorByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `TesContainerimageexecutor` being updated. */
  tesContainerimageexecutorPatch: TesContainerimageexecutorPatch;
};

/** The output of our update `TesContainerimageexecutor` mutation. */
export type UpdateTesContainerimageexecutorPayload = {
  __typename: "UpdateTesContainerimageexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DjangoContentType` that is related to this `TesContainerimageexecutor`. */
  djangoContentTypeByPolymorphicCtypeId: Maybe<DjangoContentType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TesContainerimageexecutor` that was updated by this mutation. */
  tesContainerimageexecutor: Maybe<TesContainerimageexecutor>;
  /** An edge for our `TesContainerimageexecutor`. May be used by Relay 1. */
  tesContainerimageexecutorEdge: Maybe<TesContainerimageexecutorsEdge>;
};

/** The output of our update `TesContainerimageexecutor` mutation. */
export type UpdateTesContainerimageexecutorPayloadTesContainerimageexecutorEdgeArgs =
  {
    orderBy?: InputMaybe<Array<TesContainerimageexecutorsOrderBy>>;
  };

/** All input for the `updateTesMockcontainerimageexecutorByContainerimageexecutorPtrId` mutation. */
export type UpdateTesMockcontainerimageexecutorByContainerimageexecutorPtrIdInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
    containerimageexecutorPtrId: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `TesMockcontainerimageexecutor` being updated. */
    tesMockcontainerimageexecutorPatch: TesMockcontainerimageexecutorPatch;
  };

/** The output of our update `TesMockcontainerimageexecutor` mutation. */
export type UpdateTesMockcontainerimageexecutorPayload = {
  __typename: "UpdateTesMockcontainerimageexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `TesContainerimageexecutor` that is related to this `TesMockcontainerimageexecutor`. */
  tesContainerimageexecutorByContainerimageexecutorPtrId: Maybe<TesContainerimageexecutor>;
  /** The `TesMockcontainerimageexecutor` that was updated by this mutation. */
  tesMockcontainerimageexecutor: Maybe<TesMockcontainerimageexecutor>;
  /** An edge for our `TesMockcontainerimageexecutor`. May be used by Relay 1. */
  tesMockcontainerimageexecutorEdge: Maybe<TesMockcontainerimageexecutorsEdge>;
};

/** The output of our update `TesMockcontainerimageexecutor` mutation. */
export type UpdateTesMockcontainerimageexecutorPayloadTesMockcontainerimageexecutorEdgeArgs =
  {
    orderBy?: InputMaybe<Array<TesMockcontainerimageexecutorsOrderBy>>;
  };

/** All input for the `updateTesMocktaskexecutorByTaskexecutorPtrId` mutation. */
export type UpdateTesMocktaskexecutorByTaskexecutorPtrIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  taskexecutorPtrId: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `TesMocktaskexecutor` being updated. */
  tesMocktaskexecutorPatch: TesMocktaskexecutorPatch;
};

/** The output of our update `TesMocktaskexecutor` mutation. */
export type UpdateTesMocktaskexecutorPayload = {
  __typename: "UpdateTesMocktaskexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TesMocktaskexecutor` that was updated by this mutation. */
  tesMocktaskexecutor: Maybe<TesMocktaskexecutor>;
  /** An edge for our `TesMocktaskexecutor`. May be used by Relay 1. */
  tesMocktaskexecutorEdge: Maybe<TesMocktaskexecutorsEdge>;
  /** Reads a single `TesTaskexecutor` that is related to this `TesMocktaskexecutor`. */
  tesTaskexecutorByTaskexecutorPtrId: Maybe<TesTaskexecutor>;
};

/** The output of our update `TesMocktaskexecutor` mutation. */
export type UpdateTesMocktaskexecutorPayloadTesMocktaskexecutorEdgeArgs = {
  orderBy?: InputMaybe<Array<TesMocktaskexecutorsOrderBy>>;
};

/** All input for the `updateTesTaskexecutorById` mutation. */
export type UpdateTesTaskexecutorByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `TesTaskexecutor` being updated. */
  tesTaskexecutorPatch: TesTaskexecutorPatch;
};

/** The output of our update `TesTaskexecutor` mutation. */
export type UpdateTesTaskexecutorPayload = {
  __typename: "UpdateTesTaskexecutorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DjangoContentType` that is related to this `TesTaskexecutor`. */
  djangoContentTypeByPolymorphicCtypeId: Maybe<DjangoContentType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `TesTaskexecutor` that was updated by this mutation. */
  tesTaskexecutor: Maybe<TesTaskexecutor>;
  /** An edge for our `TesTaskexecutor`. May be used by Relay 1. */
  tesTaskexecutorEdge: Maybe<TesTaskexecutorsEdge>;
};

/** The output of our update `TesTaskexecutor` mutation. */
export type UpdateTesTaskexecutorPayloadTesTaskexecutorEdgeArgs = {
  orderBy?: InputMaybe<Array<TesTaskexecutorsOrderBy>>;
};

/** All input for the `updateToolById` mutation. */
export type UpdateToolByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `Tool` being updated. */
  toolPatch: ToolPatch;
};

/** All input for the `updateToolByKey` mutation. */
export type UpdateToolByKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
  /** An object where the defined keys will be set on the `Tool` being updated. */
  toolPatch: ToolPatch;
};

/** All input for the `updateToolCategoryById` mutation. */
export type UpdateToolCategoryByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `ToolCategory` being updated. */
  toolCategoryPatch: ToolCategoryPatch;
};

/** All input for the `updateToolCategoryByKey` mutation. */
export type UpdateToolCategoryByKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
  /** An object where the defined keys will be set on the `ToolCategory` being updated. */
  toolCategoryPatch: ToolCategoryPatch;
};

/** The output of our update `ToolCategory` mutation. */
export type UpdateToolCategoryPayload = {
  __typename: "UpdateToolCategoryPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolCategory` that was updated by this mutation. */
  toolCategory: Maybe<ToolCategory>;
  /** An edge for our `ToolCategory`. May be used by Relay 1. */
  toolCategoryEdge: Maybe<ToolCategoriesEdge>;
};

/** The output of our update `ToolCategory` mutation. */
export type UpdateToolCategoryPayloadToolCategoryEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolCategoriesOrderBy>>;
};

/** All input for the `updateToolDisplayCategoryById` mutation. */
export type UpdateToolDisplayCategoryByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `ToolDisplayCategory` being updated. */
  toolDisplayCategoryPatch: ToolDisplayCategoryPatch;
};

/** All input for the `updateToolDisplayCategoryByToolIdAndToolcategoryId` mutation. */
export type UpdateToolDisplayCategoryByToolIdAndToolcategoryIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `ToolDisplayCategory` being updated. */
  toolDisplayCategoryPatch: ToolDisplayCategoryPatch;
  toolId: Scalars["Int"]["input"];
  toolcategoryId: Scalars["Int"]["input"];
};

/** The output of our update `ToolDisplayCategory` mutation. */
export type UpdateToolDisplayCategoryPayload = {
  __typename: "UpdateToolDisplayCategoryPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tool` that is related to this `ToolDisplayCategory`. */
  toolByToolId: Maybe<Tool>;
  /** Reads a single `ToolCategory` that is related to this `ToolDisplayCategory`. */
  toolCategoryByToolcategoryId: Maybe<ToolCategory>;
  /** The `ToolDisplayCategory` that was updated by this mutation. */
  toolDisplayCategory: Maybe<ToolDisplayCategory>;
  /** An edge for our `ToolDisplayCategory`. May be used by Relay 1. */
  toolDisplayCategoryEdge: Maybe<ToolDisplayCategoriesEdge>;
};

/** The output of our update `ToolDisplayCategory` mutation. */
export type UpdateToolDisplayCategoryPayloadToolDisplayCategoryEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolDisplayCategoriesOrderBy>>;
};

/** All input for the `updateToolParameterById` mutation. */
export type UpdateToolParameterByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `ToolParameter` being updated. */
  toolParameterPatch: ToolParameterPatch;
};

/** The output of our update `ToolParameter` mutation. */
export type UpdateToolParameterPayload = {
  __typename: "UpdateToolParameterPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolParameter` that was updated by this mutation. */
  toolParameter: Maybe<ToolParameter>;
  /** An edge for our `ToolParameter`. May be used by Relay 1. */
  toolParameterEdge: Maybe<ToolParametersEdge>;
  /** Reads a single `ToolVersion` that is related to this `ToolParameter`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our update `ToolParameter` mutation. */
export type UpdateToolParameterPayloadToolParameterEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolParametersOrderBy>>;
};

/** The output of our update `Tool` mutation. */
export type UpdateToolPayload = {
  __typename: "UpdateToolPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `Tool` that was updated by this mutation. */
  tool: Maybe<Tool>;
  /** An edge for our `Tool`. May be used by Relay 1. */
  toolEdge: Maybe<ToolsEdge>;
};

/** The output of our update `Tool` mutation. */
export type UpdateToolPayloadToolEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolsOrderBy>>;
};

/** All input for the `updateToolResourceById` mutation. */
export type UpdateToolResourceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `ToolResource` being updated. */
  toolResourcePatch: ToolResourcePatch;
};

/** All input for the `updateToolResourceByTypeAndToolVersionId` mutation. */
export type UpdateToolResourceByTypeAndToolVersionIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `ToolResource` being updated. */
  toolResourcePatch: ToolResourcePatch;
  toolVersionId: Scalars["Int"]["input"];
  type: Scalars["Int"]["input"];
};

/** The output of our update `ToolResource` mutation. */
export type UpdateToolResourcePayload = {
  __typename: "UpdateToolResourcePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolResource` that was updated by this mutation. */
  toolResource: Maybe<ToolResource>;
  /** An edge for our `ToolResource`. May be used by Relay 1. */
  toolResourceEdge: Maybe<ToolResourcesEdge>;
  /** Reads a single `ToolVersion` that is related to this `ToolResource`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our update `ToolResource` mutation. */
export type UpdateToolResourcePayloadToolResourceEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolResourcesOrderBy>>;
};

/** All input for the `updateToolResultById` mutation. */
export type UpdateToolResultByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `ToolResult` being updated. */
  toolResultPatch: ToolResultPatch;
};

/** The output of our update `ToolResult` mutation. */
export type UpdateToolResultPayload = {
  __typename: "UpdateToolResultPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolResult` that was updated by this mutation. */
  toolResult: Maybe<ToolResult>;
  /** An edge for our `ToolResult`. May be used by Relay 1. */
  toolResultEdge: Maybe<ToolResultsEdge>;
  /** Reads a single `ToolVersion` that is related to this `ToolResult`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our update `ToolResult` mutation. */
export type UpdateToolResultPayloadToolResultEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolResultsOrderBy>>;
};

/** All input for the `updateToolSecretById` mutation. */
export type UpdateToolSecretByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `ToolSecret` being updated. */
  toolSecretPatch: ToolSecretPatch;
};

/** The output of our update `ToolSecret` mutation. */
export type UpdateToolSecretPayload = {
  __typename: "UpdateToolSecretPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolSecret` that was updated by this mutation. */
  toolSecret: Maybe<ToolSecret>;
  /** An edge for our `ToolSecret`. May be used by Relay 1. */
  toolSecretEdge: Maybe<ToolSecretsEdge>;
  /** Reads a single `ToolVersion` that is related to this `ToolSecret`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our update `ToolSecret` mutation. */
export type UpdateToolSecretPayloadToolSecretEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolSecretsOrderBy>>;
};

/** All input for the `updateToolSourceById` mutation. */
export type UpdateToolSourceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `ToolSource` being updated. */
  toolSourcePatch: ToolSourcePatch;
};

/** The output of our update `ToolSource` mutation. */
export type UpdateToolSourcePayload = {
  __typename: "UpdateToolSourcePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolSource` that was updated by this mutation. */
  toolSource: Maybe<ToolSource>;
  /** An edge for our `ToolSource`. May be used by Relay 1. */
  toolSourceEdge: Maybe<ToolSourcesEdge>;
  /** Reads a single `ToolVersion` that is related to this `ToolSource`. */
  toolVersionByToolVersionId: Maybe<ToolVersion>;
};

/** The output of our update `ToolSource` mutation. */
export type UpdateToolSourcePayloadToolSourceEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolSourcesOrderBy>>;
};

/** All input for the `updateToolTenantById` mutation. */
export type UpdateToolTenantByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `ToolTenant` being updated. */
  toolTenantPatch: ToolTenantPatch;
};

/** All input for the `updateToolTenantByToolIdAndTenantId` mutation. */
export type UpdateToolTenantByToolIdAndTenantIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  tenantId: Scalars["Int"]["input"];
  toolId: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `ToolTenant` being updated. */
  toolTenantPatch: ToolTenantPatch;
};

/** The output of our update `ToolTenant` mutation. */
export type UpdateToolTenantPayload = {
  __typename: "UpdateToolTenantPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `ToolTenant`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `Tool` that is related to this `ToolTenant`. */
  toolByToolId: Maybe<Tool>;
  /** The `ToolTenant` that was updated by this mutation. */
  toolTenant: Maybe<ToolTenant>;
  /** An edge for our `ToolTenant`. May be used by Relay 1. */
  toolTenantEdge: Maybe<ToolTenantsEdge>;
};

/** The output of our update `ToolTenant` mutation. */
export type UpdateToolTenantPayloadToolTenantEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolTenantsOrderBy>>;
};

/** All input for the `updateToolVersionById` mutation. */
export type UpdateToolVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `ToolVersion` being updated. */
  toolVersionPatch: ToolVersionPatch;
};

/** All input for the `updateToolVersionByToolIdAndVersion` mutation. */
export type UpdateToolVersionByToolIdAndVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  toolId: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `ToolVersion` being updated. */
  toolVersionPatch: ToolVersionPatch;
  version: Scalars["String"]["input"];
};

/** The output of our update `ToolVersion` mutation. */
export type UpdateToolVersionPayload = {
  __typename: "UpdateToolVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tool` that is related to this `ToolVersion`. */
  toolByToolId: Maybe<Tool>;
  /** The `ToolVersion` that was updated by this mutation. */
  toolVersion: Maybe<ToolVersion>;
  /** An edge for our `ToolVersion`. May be used by Relay 1. */
  toolVersionEdge: Maybe<ToolVersionsEdge>;
};

/** The output of our update `ToolVersion` mutation. */
export type UpdateToolVersionPayloadToolVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolVersionsOrderBy>>;
};

/** All input for the `updateToolboxById` mutation. */
export type UpdateToolboxByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `Toolbox` being updated. */
  toolboxPatch: ToolboxPatch;
};

/** All input for the `updateToolboxByKey` mutation. */
export type UpdateToolboxByKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
  /** An object where the defined keys will be set on the `Toolbox` being updated. */
  toolboxPatch: ToolboxPatch;
};

/** All input for the `updateToolboxExitcodeByCode` mutation. */
export type UpdateToolboxExitcodeByCodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  /** An object where the defined keys will be set on the `ToolboxExitcode` being updated. */
  toolboxExitcodePatch: ToolboxExitcodePatch;
};

/** All input for the `updateToolboxExitcodeById` mutation. */
export type UpdateToolboxExitcodeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `ToolboxExitcode` being updated. */
  toolboxExitcodePatch: ToolboxExitcodePatch;
};

/** The output of our update `ToolboxExitcode` mutation. */
export type UpdateToolboxExitcodePayload = {
  __typename: "UpdateToolboxExitcodePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `ToolboxExitcode` that was updated by this mutation. */
  toolboxExitcode: Maybe<ToolboxExitcode>;
  /** An edge for our `ToolboxExitcode`. May be used by Relay 1. */
  toolboxExitcodeEdge: Maybe<ToolboxExitcodesEdge>;
};

/** The output of our update `ToolboxExitcode` mutation. */
export type UpdateToolboxExitcodePayloadToolboxExitcodeEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolboxExitcodesOrderBy>>;
};

/** The output of our update `Toolbox` mutation. */
export type UpdateToolboxPayload = {
  __typename: "UpdateToolboxPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `Toolbox` that was updated by this mutation. */
  toolbox: Maybe<Toolbox>;
  /** An edge for our `Toolbox`. May be used by Relay 1. */
  toolboxEdge: Maybe<ToolboxesEdge>;
};

/** The output of our update `Toolbox` mutation. */
export type UpdateToolboxPayloadToolboxEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolboxesOrderBy>>;
};

/** All input for the `updateToolboxTenantById` mutation. */
export type UpdateToolboxTenantByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `ToolboxTenant` being updated. */
  toolboxTenantPatch: ToolboxTenantPatch;
};

/** All input for the `updateToolboxTenantByToolboxIdAndTenantId` mutation. */
export type UpdateToolboxTenantByToolboxIdAndTenantIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  tenantId: Scalars["Int"]["input"];
  toolboxId: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `ToolboxTenant` being updated. */
  toolboxTenantPatch: ToolboxTenantPatch;
};

/** The output of our update `ToolboxTenant` mutation. */
export type UpdateToolboxTenantPayload = {
  __typename: "UpdateToolboxTenantPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `ToolboxTenant`. */
  tenantByTenantId: Maybe<Tenant>;
  /** Reads a single `Toolbox` that is related to this `ToolboxTenant`. */
  toolboxByToolboxId: Maybe<Toolbox>;
  /** The `ToolboxTenant` that was updated by this mutation. */
  toolboxTenant: Maybe<ToolboxTenant>;
  /** An edge for our `ToolboxTenant`. May be used by Relay 1. */
  toolboxTenantEdge: Maybe<ToolboxTenantsEdge>;
};

/** The output of our update `ToolboxTenant` mutation. */
export type UpdateToolboxTenantPayloadToolboxTenantEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolboxTenantsOrderBy>>;
};

/** All input for the `updateToolboxVersionById` mutation. */
export type UpdateToolboxVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `ToolboxVersion` being updated. */
  toolboxVersionPatch: ToolboxVersionPatch;
};

/** All input for the `updateToolboxVersionByToolboxIdAndVersion` mutation. */
export type UpdateToolboxVersionByToolboxIdAndVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  toolboxId: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `ToolboxVersion` being updated. */
  toolboxVersionPatch: ToolboxVersionPatch;
  version: Scalars["String"]["input"];
};

/** All input for the `updateToolboxVersionExitcodeById` mutation. */
export type UpdateToolboxVersionExitcodeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `ToolboxVersionExitcode` being updated. */
  toolboxVersionExitcodePatch: ToolboxVersionExitcodePatch;
};

/** All input for the `updateToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeId` mutation. */
export type UpdateToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
    /** An object where the defined keys will be set on the `ToolboxVersionExitcode` being updated. */
    toolboxVersionExitcodePatch: ToolboxVersionExitcodePatch;
    toolboxexitcodeId: Scalars["Int"]["input"];
    toolboxversionId: Scalars["Int"]["input"];
  };

/** The output of our update `ToolboxVersionExitcode` mutation. */
export type UpdateToolboxVersionExitcodePayload = {
  __typename: "UpdateToolboxVersionExitcodePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ToolboxExitcode` that is related to this `ToolboxVersionExitcode`. */
  toolboxExitcodeByToolboxexitcodeId: Maybe<ToolboxExitcode>;
  /** Reads a single `ToolboxVersion` that is related to this `ToolboxVersionExitcode`. */
  toolboxVersionByToolboxversionId: Maybe<ToolboxVersion>;
  /** The `ToolboxVersionExitcode` that was updated by this mutation. */
  toolboxVersionExitcode: Maybe<ToolboxVersionExitcode>;
  /** An edge for our `ToolboxVersionExitcode`. May be used by Relay 1. */
  toolboxVersionExitcodeEdge: Maybe<ToolboxVersionExitcodesEdge>;
};

/** The output of our update `ToolboxVersionExitcode` mutation. */
export type UpdateToolboxVersionExitcodePayloadToolboxVersionExitcodeEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ToolboxVersionExitcodesOrderBy>>;
  };

/** The output of our update `ToolboxVersion` mutation. */
export type UpdateToolboxVersionPayload = {
  __typename: "UpdateToolboxVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Toolbox` that is related to this `ToolboxVersion`. */
  toolboxByToolboxId: Maybe<Toolbox>;
  /** The `ToolboxVersion` that was updated by this mutation. */
  toolboxVersion: Maybe<ToolboxVersion>;
  /** An edge for our `ToolboxVersion`. May be used by Relay 1. */
  toolboxVersionEdge: Maybe<ToolboxVersionsEdge>;
};

/** The output of our update `ToolboxVersion` mutation. */
export type UpdateToolboxVersionPayloadToolboxVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<ToolboxVersionsOrderBy>>;
};

/** All input for the `updateToolboxVersionToolVersionById` mutation. */
export type UpdateToolboxVersionToolVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `ToolboxVersionToolVersion` being updated. */
  toolboxVersionToolVersionPatch: ToolboxVersionToolVersionPatch;
};

/** All input for the `updateToolboxVersionToolVersionByToolboxversionIdAndToolversionId` mutation. */
export type UpdateToolboxVersionToolVersionByToolboxversionIdAndToolversionIdInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
    /** An object where the defined keys will be set on the `ToolboxVersionToolVersion` being updated. */
    toolboxVersionToolVersionPatch: ToolboxVersionToolVersionPatch;
    toolboxversionId: Scalars["Int"]["input"];
    toolversionId: Scalars["Int"]["input"];
  };

/** The output of our update `ToolboxVersionToolVersion` mutation. */
export type UpdateToolboxVersionToolVersionPayload = {
  __typename: "UpdateToolboxVersionToolVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ToolVersion` that is related to this `ToolboxVersionToolVersion`. */
  toolVersionByToolversionId: Maybe<ToolVersion>;
  /** Reads a single `ToolboxVersion` that is related to this `ToolboxVersionToolVersion`. */
  toolboxVersionByToolboxversionId: Maybe<ToolboxVersion>;
  /** The `ToolboxVersionToolVersion` that was updated by this mutation. */
  toolboxVersionToolVersion: Maybe<ToolboxVersionToolVersion>;
  /** An edge for our `ToolboxVersionToolVersion`. May be used by Relay 1. */
  toolboxVersionToolVersionEdge: Maybe<ToolboxVersionToolVersionsEdge>;
};

/** The output of our update `ToolboxVersionToolVersion` mutation. */
export type UpdateToolboxVersionToolVersionPayloadToolboxVersionToolVersionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ToolboxVersionToolVersionsOrderBy>>;
  };

/** All input for the `updateWorkflowById` mutation. */
export type UpdateWorkflowByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `Workflow` being updated. */
  workflowPatch: WorkflowPatch;
};

/** All input for the `updateWorkflowDatumById` mutation. */
export type UpdateWorkflowDatumByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `WorkflowDatum` being updated. */
  workflowDatumPatch: WorkflowDatumPatch;
};

/** The output of our update `WorkflowDatum` mutation. */
export type UpdateWorkflowDatumPayload = {
  __typename: "UpdateWorkflowDatumPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `WorkflowDatum` that was updated by this mutation. */
  workflowDatum: Maybe<WorkflowDatum>;
  /** An edge for our `WorkflowDatum`. May be used by Relay 1. */
  workflowDatumEdge: Maybe<WorkflowDataEdge>;
};

/** The output of our update `WorkflowDatum` mutation. */
export type UpdateWorkflowDatumPayloadWorkflowDatumEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowDataOrderBy>>;
};

/** The output of our update `Workflow` mutation. */
export type UpdateWorkflowPayload = {
  __typename: "UpdateWorkflowPayload";
  /** Reads a single `ApplicationUser` that is related to this `Workflow`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `ContainerImage` that is related to this `Workflow`. */
  containerImageByContainerId: Maybe<ContainerImage>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Workflow`. */
  tenantByTenantId: Maybe<Tenant>;
  /** The `Workflow` that was updated by this mutation. */
  workflow: Maybe<Workflow>;
  /** An edge for our `Workflow`. May be used by Relay 1. */
  workflowEdge: Maybe<WorkflowsEdge>;
};

/** The output of our update `Workflow` mutation. */
export type UpdateWorkflowPayloadWorkflowEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowsOrderBy>>;
};

/** All input for the `updateWorkflowResultById` mutation. */
export type UpdateWorkflowResultByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `WorkflowResult` being updated. */
  workflowResultPatch: WorkflowResultPatch;
};

/** The output of our update `WorkflowResult` mutation. */
export type UpdateWorkflowResultPayload = {
  __typename: "UpdateWorkflowResultPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `WorkflowDatum` that is related to this `WorkflowResult`. */
  workflowDatumByObjectId: Maybe<WorkflowDatum>;
  /** The `WorkflowResult` that was updated by this mutation. */
  workflowResult: Maybe<WorkflowResult>;
  /** An edge for our `WorkflowResult`. May be used by Relay 1. */
  workflowResultEdge: Maybe<WorkflowResultsEdge>;
};

/** The output of our update `WorkflowResult` mutation. */
export type UpdateWorkflowResultPayloadWorkflowResultEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowResultsOrderBy>>;
};

/** All input for the `updateWorkflowResultTagById` mutation. */
export type UpdateWorkflowResultTagByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `WorkflowResultTag` being updated. */
  workflowResultTagPatch: WorkflowResultTagPatch;
};

/** All input for the `updateWorkflowResultTagByResultIdAndTagId` mutation. */
export type UpdateWorkflowResultTagByResultIdAndTagIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  resultId: Scalars["UUID"]["input"];
  tagId: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `WorkflowResultTag` being updated. */
  workflowResultTagPatch: WorkflowResultTagPatch;
};

/** The output of our update `WorkflowResultTag` mutation. */
export type UpdateWorkflowResultTagPayload = {
  __typename: "UpdateWorkflowResultTagPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tag` that is related to this `WorkflowResultTag`. */
  tagByTagId: Maybe<Tag>;
  /** Reads a single `WorkflowResult` that is related to this `WorkflowResultTag`. */
  workflowResultByResultId: Maybe<WorkflowResult>;
  /** The `WorkflowResultTag` that was updated by this mutation. */
  workflowResultTag: Maybe<WorkflowResultTag>;
  /** An edge for our `WorkflowResultTag`. May be used by Relay 1. */
  workflowResultTagEdge: Maybe<WorkflowResultTagsEdge>;
};

/** The output of our update `WorkflowResultTag` mutation. */
export type UpdateWorkflowResultTagPayloadWorkflowResultTagEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowResultTagsOrderBy>>;
};

/** All input for the `updateWorkflowResultWorkflowVersionById` mutation. */
export type UpdateWorkflowResultWorkflowVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `WorkflowResultWorkflowVersion` being updated. */
  workflowResultWorkflowVersionPatch: WorkflowResultWorkflowVersionPatch;
};

/** All input for the `updateWorkflowResultWorkflowVersionByResultIdAndWorkflowversionId` mutation. */
export type UpdateWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
    resultId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `WorkflowResultWorkflowVersion` being updated. */
    workflowResultWorkflowVersionPatch: WorkflowResultWorkflowVersionPatch;
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The output of our update `WorkflowResultWorkflowVersion` mutation. */
export type UpdateWorkflowResultWorkflowVersionPayload = {
  __typename: "UpdateWorkflowResultWorkflowVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `WorkflowResult` that is related to this `WorkflowResultWorkflowVersion`. */
  workflowResultByResultId: Maybe<WorkflowResult>;
  /** The `WorkflowResultWorkflowVersion` that was updated by this mutation. */
  workflowResultWorkflowVersion: Maybe<WorkflowResultWorkflowVersion>;
  /** An edge for our `WorkflowResultWorkflowVersion`. May be used by Relay 1. */
  workflowResultWorkflowVersionEdge: Maybe<WorkflowResultWorkflowVersionsEdge>;
  /** Reads a single `WorkflowVersion` that is related to this `WorkflowResultWorkflowVersion`. */
  workflowVersionByWorkflowversionId: Maybe<WorkflowVersion>;
};

/** The output of our update `WorkflowResultWorkflowVersion` mutation. */
export type UpdateWorkflowResultWorkflowVersionPayloadWorkflowResultWorkflowVersionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<WorkflowResultWorkflowVersionsOrderBy>>;
  };

/** All input for the `updateWorkflowSectionBlockById` mutation. */
export type UpdateWorkflowSectionBlockByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `WorkflowSectionBlock` being updated. */
  workflowSectionBlockPatch: WorkflowSectionBlockPatch;
};

/** The output of our update `WorkflowSectionBlock` mutation. */
export type UpdateWorkflowSectionBlockPayload = {
  __typename: "UpdateWorkflowSectionBlockPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ToolVersion` that is related to this `WorkflowSectionBlock`. */
  toolVersionByToolId: Maybe<ToolVersion>;
  /** Reads a single `WorkflowDatum` that is related to this `WorkflowSectionBlock`. */
  workflowDatumByObjectId: Maybe<WorkflowDatum>;
  /** The `WorkflowSectionBlock` that was updated by this mutation. */
  workflowSectionBlock: Maybe<WorkflowSectionBlock>;
  /** An edge for our `WorkflowSectionBlock`. May be used by Relay 1. */
  workflowSectionBlockEdge: Maybe<WorkflowSectionBlocksEdge>;
};

/** The output of our update `WorkflowSectionBlock` mutation. */
export type UpdateWorkflowSectionBlockPayloadWorkflowSectionBlockEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowSectionBlocksOrderBy>>;
};

/** All input for the `updateWorkflowSectionBlockSectionByBlockIdAndSectionId` mutation. */
export type UpdateWorkflowSectionBlockSectionByBlockIdAndSectionIdInput = {
  blockId: Scalars["UUID"]["input"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  sectionId: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `WorkflowSectionBlockSection` being updated. */
  workflowSectionBlockSectionPatch: WorkflowSectionBlockSectionPatch;
};

/** All input for the `updateWorkflowSectionBlockSectionById` mutation. */
export type UpdateWorkflowSectionBlockSectionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `WorkflowSectionBlockSection` being updated. */
  workflowSectionBlockSectionPatch: WorkflowSectionBlockSectionPatch;
};

/** The output of our update `WorkflowSectionBlockSection` mutation. */
export type UpdateWorkflowSectionBlockSectionPayload = {
  __typename: "UpdateWorkflowSectionBlockSectionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `WorkflowSectionBlock` that is related to this `WorkflowSectionBlockSection`. */
  workflowSectionBlockByBlockId: Maybe<WorkflowSectionBlock>;
  /** The `WorkflowSectionBlockSection` that was updated by this mutation. */
  workflowSectionBlockSection: Maybe<WorkflowSectionBlockSection>;
  /** An edge for our `WorkflowSectionBlockSection`. May be used by Relay 1. */
  workflowSectionBlockSectionEdge: Maybe<WorkflowSectionBlockSectionsEdge>;
  /** Reads a single `WorkflowSection` that is related to this `WorkflowSectionBlockSection`. */
  workflowSectionBySectionId: Maybe<WorkflowSection>;
};

/** The output of our update `WorkflowSectionBlockSection` mutation. */
export type UpdateWorkflowSectionBlockSectionPayloadWorkflowSectionBlockSectionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<WorkflowSectionBlockSectionsOrderBy>>;
  };

/** All input for the `updateWorkflowSectionById` mutation. */
export type UpdateWorkflowSectionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `WorkflowSection` being updated. */
  workflowSectionPatch: WorkflowSectionPatch;
};

/** The output of our update `WorkflowSection` mutation. */
export type UpdateWorkflowSectionPayload = {
  __typename: "UpdateWorkflowSectionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `WorkflowSection` that was updated by this mutation. */
  workflowSection: Maybe<WorkflowSection>;
  /** An edge for our `WorkflowSection`. May be used by Relay 1. */
  workflowSectionEdge: Maybe<WorkflowSectionsEdge>;
};

/** The output of our update `WorkflowSection` mutation. */
export type UpdateWorkflowSectionPayloadWorkflowSectionEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowSectionsOrderBy>>;
};

/** All input for the `updateWorkflowSectionWorkflowVersionById` mutation. */
export type UpdateWorkflowSectionWorkflowVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `WorkflowSectionWorkflowVersion` being updated. */
  workflowSectionWorkflowVersionPatch: WorkflowSectionWorkflowVersionPatch;
};

/** All input for the `updateWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionId` mutation. */
export type UpdateWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
    sectionId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `WorkflowSectionWorkflowVersion` being updated. */
    workflowSectionWorkflowVersionPatch: WorkflowSectionWorkflowVersionPatch;
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The output of our update `WorkflowSectionWorkflowVersion` mutation. */
export type UpdateWorkflowSectionWorkflowVersionPayload = {
  __typename: "UpdateWorkflowSectionWorkflowVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `WorkflowSection` that is related to this `WorkflowSectionWorkflowVersion`. */
  workflowSectionBySectionId: Maybe<WorkflowSection>;
  /** The `WorkflowSectionWorkflowVersion` that was updated by this mutation. */
  workflowSectionWorkflowVersion: Maybe<WorkflowSectionWorkflowVersion>;
  /** An edge for our `WorkflowSectionWorkflowVersion`. May be used by Relay 1. */
  workflowSectionWorkflowVersionEdge: Maybe<WorkflowSectionWorkflowVersionsEdge>;
  /** Reads a single `WorkflowVersion` that is related to this `WorkflowSectionWorkflowVersion`. */
  workflowVersionByWorkflowversionId: Maybe<WorkflowVersion>;
};

/** The output of our update `WorkflowSectionWorkflowVersion` mutation. */
export type UpdateWorkflowSectionWorkflowVersionPayloadWorkflowSectionWorkflowVersionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<WorkflowSectionWorkflowVersionsOrderBy>>;
  };

/** All input for the `updateWorkflowSourceById` mutation. */
export type UpdateWorkflowSourceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `WorkflowSource` being updated. */
  workflowSourcePatch: WorkflowSourcePatch;
};

/** The output of our update `WorkflowSource` mutation. */
export type UpdateWorkflowSourcePayload = {
  __typename: "UpdateWorkflowSourcePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `WorkflowSource` that was updated by this mutation. */
  workflowSource: Maybe<WorkflowSource>;
  /** An edge for our `WorkflowSource`. May be used by Relay 1. */
  workflowSourceEdge: Maybe<WorkflowSourcesEdge>;
};

/** The output of our update `WorkflowSource` mutation. */
export type UpdateWorkflowSourcePayloadWorkflowSourceEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowSourcesOrderBy>>;
};

/** All input for the `updateWorkflowSourceTagById` mutation. */
export type UpdateWorkflowSourceTagByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `WorkflowSourceTag` being updated. */
  workflowSourceTagPatch: WorkflowSourceTagPatch;
};

/** All input for the `updateWorkflowSourceTagBySourceIdAndTagId` mutation. */
export type UpdateWorkflowSourceTagBySourceIdAndTagIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  sourceId: Scalars["UUID"]["input"];
  tagId: Scalars["BigInt"]["input"];
  /** An object where the defined keys will be set on the `WorkflowSourceTag` being updated. */
  workflowSourceTagPatch: WorkflowSourceTagPatch;
};

/** The output of our update `WorkflowSourceTag` mutation. */
export type UpdateWorkflowSourceTagPayload = {
  __typename: "UpdateWorkflowSourceTagPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Tag` that is related to this `WorkflowSourceTag`. */
  tagByTagId: Maybe<Tag>;
  /** Reads a single `WorkflowSource` that is related to this `WorkflowSourceTag`. */
  workflowSourceBySourceId: Maybe<WorkflowSource>;
  /** The `WorkflowSourceTag` that was updated by this mutation. */
  workflowSourceTag: Maybe<WorkflowSourceTag>;
  /** An edge for our `WorkflowSourceTag`. May be used by Relay 1. */
  workflowSourceTagEdge: Maybe<WorkflowSourceTagsEdge>;
};

/** The output of our update `WorkflowSourceTag` mutation. */
export type UpdateWorkflowSourceTagPayloadWorkflowSourceTagEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowSourceTagsOrderBy>>;
};

/** All input for the `updateWorkflowSourceWorkflowVersionById` mutation. */
export type UpdateWorkflowSourceWorkflowVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  /** An object where the defined keys will be set on the `WorkflowSourceWorkflowVersion` being updated. */
  workflowSourceWorkflowVersionPatch: WorkflowSourceWorkflowVersionPatch;
};

/** All input for the `updateWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionId` mutation. */
export type UpdateWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdInput =
  {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
    sourceId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `WorkflowSourceWorkflowVersion` being updated. */
    workflowSourceWorkflowVersionPatch: WorkflowSourceWorkflowVersionPatch;
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The output of our update `WorkflowSourceWorkflowVersion` mutation. */
export type UpdateWorkflowSourceWorkflowVersionPayload = {
  __typename: "UpdateWorkflowSourceWorkflowVersionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `WorkflowSource` that is related to this `WorkflowSourceWorkflowVersion`. */
  workflowSourceBySourceId: Maybe<WorkflowSource>;
  /** The `WorkflowSourceWorkflowVersion` that was updated by this mutation. */
  workflowSourceWorkflowVersion: Maybe<WorkflowSourceWorkflowVersion>;
  /** An edge for our `WorkflowSourceWorkflowVersion`. May be used by Relay 1. */
  workflowSourceWorkflowVersionEdge: Maybe<WorkflowSourceWorkflowVersionsEdge>;
  /** Reads a single `WorkflowVersion` that is related to this `WorkflowSourceWorkflowVersion`. */
  workflowVersionByWorkflowversionId: Maybe<WorkflowVersion>;
};

/** The output of our update `WorkflowSourceWorkflowVersion` mutation. */
export type UpdateWorkflowSourceWorkflowVersionPayloadWorkflowSourceWorkflowVersionEdgeArgs =
  {
    orderBy?: InputMaybe<Array<WorkflowSourceWorkflowVersionsOrderBy>>;
  };

/** All input for the `updateWorkflowVersionById` mutation. */
export type UpdateWorkflowVersionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `WorkflowVersion` being updated. */
  workflowVersionPatch: WorkflowVersionPatch;
};

/** The output of our update `WorkflowVersion` mutation. */
export type UpdateWorkflowVersionPayload = {
  __typename: "UpdateWorkflowVersionPayload";
  /** Reads a single `ApplicationUser` that is related to this `WorkflowVersion`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Workflow` that is related to this `WorkflowVersion`. */
  workflowByWorkflowId: Maybe<Workflow>;
  /** The `WorkflowVersion` that was updated by this mutation. */
  workflowVersion: Maybe<WorkflowVersion>;
  /** An edge for our `WorkflowVersion`. May be used by Relay 1. */
  workflowVersionEdge: Maybe<WorkflowVersionsEdge>;
};

/** The output of our update `WorkflowVersion` mutation. */
export type UpdateWorkflowVersionPayloadWorkflowVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowVersionsOrderBy>>;
};

export type UserAccessToProject = {
  __typename: "UserAccessToProject";
  edit: Maybe<Scalars["Boolean"]["output"]>;
  grantAccess: Maybe<Scalars["Boolean"]["output"]>;
  projectId: Maybe<Scalars["UUID"]["output"]>;
  userId: Maybe<Scalars["UUID"]["output"]>;
  view: Maybe<Scalars["Boolean"]["output"]>;
};

/**
 * A condition to be used against `UserAccessToProject` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type UserAccessToProjectCondition = {
  /** Checks for equality with the object’s `edit` field. */
  edit?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `grantAccess` field. */
  grantAccess?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `view` field. */
  view?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** A filter to be used against `UserAccessToProject` object types. All fields are combined with a logical ‘and.’ */
export type UserAccessToProjectFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserAccessToProjectFilter>>;
  /** Filter by the object’s `edit` field. */
  edit?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `grantAccess` field. */
  grantAccess?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserAccessToProjectFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserAccessToProjectFilter>>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `view` field. */
  view?: InputMaybe<BooleanFilter>;
};

/** A connection to a list of `UserAccessToProject` values. */
export type UserAccessToProjectsConnection = {
  __typename: "UserAccessToProjectsConnection";
  /** A list of edges which contains the `UserAccessToProject` and cursor to aid in pagination. */
  edges: Array<UserAccessToProjectsEdge>;
  /** A list of `UserAccessToProject` objects. */
  nodes: Array<UserAccessToProject>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserAccessToProject` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `UserAccessToProject` edge in the connection. */
export type UserAccessToProjectsEdge = {
  __typename: "UserAccessToProjectsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `UserAccessToProject` at the end of the edge. */
  node: UserAccessToProject;
};

/** Methods to use when ordering `UserAccessToProject`. */
export enum UserAccessToProjectsOrderBy {
  EditAsc = "EDIT_ASC",
  EditDesc = "EDIT_DESC",
  GrantAccessAsc = "GRANT_ACCESS_ASC",
  GrantAccessDesc = "GRANT_ACCESS_DESC",
  Natural = "NATURAL",
  ProjectIdAsc = "PROJECT_ID_ASC",
  ProjectIdDesc = "PROJECT_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
  ViewAsc = "VIEW_ASC",
  ViewDesc = "VIEW_DESC",
}

export type Workflow = {
  __typename: "Workflow";
  /** Reads a single `ApplicationUser` that is related to this `Workflow`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  containerId: Maybe<Scalars["Int"]["output"]>;
  /** Reads a single `ContainerImage` that is related to this `Workflow`. */
  containerImageByContainerId: Maybe<ContainerImage>;
  dateCreated: Scalars["Datetime"]["output"];
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["UUID"]["output"];
  name: Scalars["String"]["output"];
  /** Reads a single `Tenant` that is related to this `Workflow`. */
  tenantByTenantId: Maybe<Tenant>;
  tenantId: Scalars["Int"]["output"];
  userId: Maybe<Scalars["UUID"]["output"]>;
  /** Reads and enables pagination through a set of `WorkflowVersion`. */
  workflowVersionsByWorkflowId: WorkflowVersionsConnection;
};

export type WorkflowWorkflowVersionsByWorkflowIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowVersionCondition>;
  filter?: InputMaybe<WorkflowVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowVersionsOrderBy>>;
};

/**
 * A condition to be used against `Workflow` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type WorkflowCondition = {
  /** Checks for equality with the object’s `containerId` field. */
  containerId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `containerImage` to be created by this mutation. */
export type WorkflowContainerIdE73A96E5FkContainerImageIdContainerImageCreateInput =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name: Scalars["String"]["input"];
    repository: Scalars["String"]["input"];
    tag: Scalars["String"]["input"];
    tasksUsingId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInverseInput>;
    tesContainerimageexecutorToExecutorId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInput>;
    toolboxVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxVersionToToolboxVersionId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInput>;
    workflowsUsingId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInverseInput>;
  };

/** Input for the nested mutation of `containerImage` in the `WorkflowInput` mutation. */
export type WorkflowContainerIdE73A96E5FkContainerImageIdInput = {
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectByExecutorId?: InputMaybe<ContainerImageContainerImageExecutorIdKeyConnect>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectById?: InputMaybe<ContainerImageContainerImagePkeyConnect>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectByNameAndTag?: InputMaybe<ContainerImageContainerImageNameTagUniqueConnect>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectByRepositoryAndTag?: InputMaybe<ContainerImageContainerImageRepositoryTagUniqueConnect>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  connectByToolboxVersionId?: InputMaybe<ContainerImageContainerImageToolboxVersionIdE9Dede5FUniqConnect>;
  /** A `ContainerImageInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdContainerImageCreateInput>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteByExecutorId?: InputMaybe<ContainerImageContainerImageExecutorIdKeyDelete>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteById?: InputMaybe<ContainerImageContainerImagePkeyDelete>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteByNameAndTag?: InputMaybe<ContainerImageContainerImageNameTagUniqueDelete>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteByRepositoryAndTag?: InputMaybe<ContainerImageContainerImageRepositoryTagUniqueDelete>;
  /** The primary key(s) for `containerImage` for the far side of the relationship. */
  deleteByToolboxVersionId?: InputMaybe<ContainerImageContainerImageToolboxVersionIdE9Dede5FUniqDelete>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateByExecutorId?: InputMaybe<ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageExecutorIdKeyUpdate>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateById?: InputMaybe<ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImagePkeyUpdate>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateByNameAndTag?: InputMaybe<ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageNameTagUniqueUpdate>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateByRepositoryAndTag?: InputMaybe<ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageRepositoryTagUniqueUpdate>;
  /** The primary key(s) and patch data for `containerImage` for the far side of the relationship. */
  updateByToolboxVersionId?: InputMaybe<ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate>;
};

/** Input for the nested mutation of `workflow` in the `ContainerImageInput` mutation. */
export type WorkflowContainerIdE73A96E5FkContainerImageIdInverseInput = {
  /** The primary key(s) for `workflow` for the far side of the relationship. */
  connectById?: InputMaybe<Array<WorkflowWorkflowPkeyConnect>>;
  /** A `WorkflowInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<WorkflowContainerIdE73A96E5FkContainerImageIdWorkflowCreateInput>
  >;
  /** The primary key(s) for `workflow` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<WorkflowWorkflowPkeyDelete>>;
  /** Flag indicating whether all other `workflow` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `workflow` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<WorkflowOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingWorkflowPkeyUpdate>
  >;
};

/** The `workflow` to be created by this mutation. */
export type WorkflowContainerIdE73A96E5FkContainerImageIdWorkflowCreateInput = {
  applicationUserToUserId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInput>;
  containerImageToContainerId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  name: Scalars["String"]["input"];
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowVersionsUsingId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInverseInput>;
};

/** A connection to a list of `WorkflowDatum` values. */
export type WorkflowDataConnection = {
  __typename: "WorkflowDataConnection";
  /** A list of edges which contains the `WorkflowDatum` and cursor to aid in pagination. */
  edges: Array<WorkflowDataEdge>;
  /** A list of `WorkflowDatum` objects. */
  nodes: Array<WorkflowDatum>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WorkflowDatum` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `WorkflowDatum` edge in the connection. */
export type WorkflowDataEdge = {
  __typename: "WorkflowDataEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `WorkflowDatum` at the end of the edge. */
  node: WorkflowDatum;
};

/** Methods to use when ordering `WorkflowDatum`. */
export enum WorkflowDataOrderBy {
  FreshAsc = "FRESH_ASC",
  FreshDesc = "FRESH_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type WorkflowDatum = {
  __typename: "WorkflowDatum";
  fresh: Scalars["Boolean"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads and enables pagination through a set of `WorkflowResult`. */
  workflowResultsByObjectId: WorkflowResultsConnection;
  /** Reads and enables pagination through a set of `WorkflowSectionBlock`. */
  workflowSectionBlocksByObjectId: WorkflowSectionBlocksConnection;
};

export type WorkflowDatumWorkflowResultsByObjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowResultCondition>;
  filter?: InputMaybe<WorkflowResultFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowResultsOrderBy>>;
};

export type WorkflowDatumWorkflowSectionBlocksByObjectIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSectionBlockCondition>;
  filter?: InputMaybe<WorkflowSectionBlockFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSectionBlocksOrderBy>>;
};

/**
 * A condition to be used against `WorkflowDatum` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type WorkflowDatumCondition = {
  /** Checks for equality with the object’s `fresh` field. */
  fresh?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `WorkflowDatum` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowDatumFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WorkflowDatumFilter>>;
  /** Filter by the object’s `fresh` field. */
  fresh?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WorkflowDatumFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WorkflowDatumFilter>>;
  /** Filter by the object’s `workflowResultsByObjectId` relation. */
  workflowResultsByObjectId?: InputMaybe<WorkflowDatumToManyWorkflowResultFilter>;
  /** Some related `workflowResultsByObjectId` exist. */
  workflowResultsByObjectIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `workflowSectionBlocksByObjectId` relation. */
  workflowSectionBlocksByObjectId?: InputMaybe<WorkflowDatumToManyWorkflowSectionBlockFilter>;
  /** Some related `workflowSectionBlocksByObjectId` exist. */
  workflowSectionBlocksByObjectIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
};

/** An input for mutations affecting `WorkflowDatum` */
export type WorkflowDatumInput = {
  fresh: Scalars["Boolean"]["input"];
  id: Scalars["UUID"]["input"];
  workflowResultsUsingId?: InputMaybe<WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInverseInput>;
  workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInverseInput>;
};

/** The fields on `workflowDatum` to look up the row to update. */
export type WorkflowDatumOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdUsingWorkflowDataPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowDatum` being updated. */
    workflowDatumPatch: UpdateWorkflowDatumOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdPatch;
  };

/** The fields on `workflowDatum` to look up the row to update. */
export type WorkflowDatumOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdUsingWorkflowDataPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowDatum` being updated. */
    workflowDatumPatch: UpdateWorkflowDatumOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdPatch;
  };

/** Represents an update to a `WorkflowDatum`. Fields that are set will be updated. */
export type WorkflowDatumPatch = {
  fresh?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowResultsUsingId?: InputMaybe<WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInverseInput>;
  workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInverseInput>;
};

/** A filter to be used against many `WorkflowResult` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowDatumToManyWorkflowResultFilter = {
  /** Every related `WorkflowResult` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowResultFilter>;
  /** No related `WorkflowResult` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowResultFilter>;
  /** Some related `WorkflowResult` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowResultFilter>;
};

/** A filter to be used against many `WorkflowSectionBlock` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowDatumToManyWorkflowSectionBlockFilter = {
  /** Every related `WorkflowSectionBlock` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowSectionBlockFilter>;
  /** No related `WorkflowSectionBlock` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowSectionBlockFilter>;
  /** Some related `WorkflowSectionBlock` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowSectionBlockFilter>;
};

/** The fields on `workflowDatum` to look up the row to connect. */
export type WorkflowDatumWorkflowDataPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `workflowDatum` to look up the row to delete. */
export type WorkflowDatumWorkflowDataPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** A filter to be used against `Workflow` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WorkflowFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** A related `applicationUserByUserId` exists. */
  applicationUserByUserIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `containerId` field. */
  containerId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `containerImageByContainerId` relation. */
  containerImageByContainerId?: InputMaybe<ContainerImageFilter>;
  /** A related `containerImageByContainerId` exists. */
  containerImageByContainerIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WorkflowFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WorkflowFilter>>;
  /** Filter by the object’s `tenantByTenantId` relation. */
  tenantByTenantId?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `workflowVersionsByWorkflowId` relation. */
  workflowVersionsByWorkflowId?: InputMaybe<WorkflowToManyWorkflowVersionFilter>;
  /** Some related `workflowVersionsByWorkflowId` exist. */
  workflowVersionsByWorkflowIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** An input for mutations affecting `Workflow` */
export type WorkflowInput = {
  applicationUserToUserId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInput>;
  containerId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  name: Scalars["String"]["input"];
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowVersionsUsingId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInverseInput>;
};

/** The fields on `workflow` to look up the row to update. */
export type WorkflowOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingWorkflowPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflow` being updated. */
    workflowPatch: UpdateWorkflowOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdPatch;
  };

/** The fields on `workflow` to look up the row to update. */
export type WorkflowOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingWorkflowPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflow` being updated. */
    workflowPatch: UpdateWorkflowOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdPatch;
  };

/** The fields on `workflow` to look up the row to update. */
export type WorkflowOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingWorkflowPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflow` being updated. */
    workflowPatch: UpdateWorkflowOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdPatch;
  };

/** The fields on `workflow` to look up the row to update. */
export type WorkflowOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdUsingWorkflowPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflow` being updated. */
    workflowPatch: UpdateWorkflowOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdPatch;
  };

/** Represents an update to a `Workflow`. Fields that are set will be updated. */
export type WorkflowPatch = {
  applicationUserToUserId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInput>;
  containerId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowVersionsUsingId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInverseInput>;
};

export type WorkflowResult = {
  __typename: "WorkflowResult";
  id: Scalars["UUID"]["output"];
  objectId: Scalars["UUID"]["output"];
  /** Reads a single `WorkflowDatum` that is related to this `WorkflowResult`. */
  workflowDatumByObjectId: Maybe<WorkflowDatum>;
  /** Reads and enables pagination through a set of `WorkflowResultTag`. */
  workflowResultTagsByResultId: WorkflowResultTagsConnection;
  /** Reads and enables pagination through a set of `WorkflowResultWorkflowVersion`. */
  workflowResultWorkflowVersionsByResultId: WorkflowResultWorkflowVersionsConnection;
};

export type WorkflowResultWorkflowResultTagsByResultIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowResultTagCondition>;
  filter?: InputMaybe<WorkflowResultTagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowResultTagsOrderBy>>;
};

export type WorkflowResultWorkflowResultWorkflowVersionsByResultIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowResultWorkflowVersionCondition>;
  filter?: InputMaybe<WorkflowResultWorkflowVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowResultWorkflowVersionsOrderBy>>;
};

/**
 * A condition to be used against `WorkflowResult` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type WorkflowResultCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `objectId` field. */
  objectId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `WorkflowResult` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowResultFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WorkflowResultFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WorkflowResultFilter>;
  /** Filter by the object’s `objectId` field. */
  objectId?: InputMaybe<UuidFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WorkflowResultFilter>>;
  /** Filter by the object’s `workflowDatumByObjectId` relation. */
  workflowDatumByObjectId?: InputMaybe<WorkflowDatumFilter>;
  /** Filter by the object’s `workflowResultTagsByResultId` relation. */
  workflowResultTagsByResultId?: InputMaybe<WorkflowResultToManyWorkflowResultTagFilter>;
  /** Some related `workflowResultTagsByResultId` exist. */
  workflowResultTagsByResultIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `workflowResultWorkflowVersionsByResultId` relation. */
  workflowResultWorkflowVersionsByResultId?: InputMaybe<WorkflowResultToManyWorkflowResultWorkflowVersionFilter>;
  /** Some related `workflowResultWorkflowVersionsByResultId` exist. */
  workflowResultWorkflowVersionsByResultIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
};

/** An input for mutations affecting `WorkflowResult` */
export type WorkflowResultInput = {
  id: Scalars["UUID"]["input"];
  objectId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowDatumToObjectId?: InputMaybe<WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInput>;
  workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInverseInput>;
  workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkResultId50F959E2FkWorkflowInverseInput>;
};

/** Input for the nested mutation of `workflowDatum` in the `WorkflowResultInput` mutation. */
export type WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInput = {
  /** The primary key(s) for `workflowDatum` for the far side of the relationship. */
  connectById?: InputMaybe<WorkflowDatumWorkflowDataPkeyConnect>;
  /** A `WorkflowDatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowResultObjectIdC561D1C5FkWorkflowDataIdWorkflowDataCreateInput>;
  /** The primary key(s) for `workflowDatum` for the far side of the relationship. */
  deleteById?: InputMaybe<WorkflowDatumWorkflowDataPkeyDelete>;
  /** The primary key(s) and patch data for `workflowDatum` for the far side of the relationship. */
  updateById?: InputMaybe<WorkflowDatumOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdUsingWorkflowDataPkeyUpdate>;
};

/** Input for the nested mutation of `workflowResult` in the `WorkflowDatumInput` mutation. */
export type WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInverseInput = {
  /** The primary key(s) for `workflowResult` for the far side of the relationship. */
  connectById?: InputMaybe<Array<WorkflowResultWorkflowResultPkeyConnect>>;
  /** A `WorkflowResultInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<WorkflowResultObjectIdC561D1C5FkWorkflowDataIdWorkflowResultCreateInput>
  >;
  /** The primary key(s) for `workflowResult` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<WorkflowResultWorkflowResultPkeyDelete>>;
  /** Flag indicating whether all other `workflowResult` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `workflowResult` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<WorkflowResultOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdUsingWorkflowResultPkeyUpdate>
  >;
};

/** The `workflowDatum` to be created by this mutation. */
export type WorkflowResultObjectIdC561D1C5FkWorkflowDataIdWorkflowDataCreateInput =
  {
    fresh: Scalars["Boolean"]["input"];
    id: Scalars["UUID"]["input"];
    workflowResultsUsingId?: InputMaybe<WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInverseInput>;
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInverseInput>;
  };

/** The `workflowResult` to be created by this mutation. */
export type WorkflowResultObjectIdC561D1C5FkWorkflowDataIdWorkflowResultCreateInput =
  {
    id: Scalars["UUID"]["input"];
    workflowDatumToObjectId?: InputMaybe<WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInput>;
    workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInverseInput>;
    workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkResultId50F959E2FkWorkflowInverseInput>;
  };

/** The fields on `workflowResult` to look up the row to update. */
export type WorkflowResultOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdUsingWorkflowResultPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowResult` being updated. */
    workflowResultPatch: UpdateWorkflowResultOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdPatch;
  };

/** The fields on `workflowResult` to look up the row to update. */
export type WorkflowResultOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdUsingWorkflowResultPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowResult` being updated. */
    workflowResultPatch: UpdateWorkflowResultOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdPatch;
  };

/** The fields on `workflowResult` to look up the row to update. */
export type WorkflowResultOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowUsingWorkflowResultPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowResult` being updated. */
    workflowResultPatch: UpdateWorkflowResultOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowPatch;
  };

/** Represents an update to a `WorkflowResult`. Fields that are set will be updated. */
export type WorkflowResultPatch = {
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  objectId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowDatumToObjectId?: InputMaybe<WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInput>;
  workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInverseInput>;
  workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkResultId50F959E2FkWorkflowInverseInput>;
};

export type WorkflowResultTag = {
  __typename: "WorkflowResultTag";
  id: Scalars["Int"]["output"];
  resultId: Scalars["UUID"]["output"];
  /** Reads a single `Tag` that is related to this `WorkflowResultTag`. */
  tagByTagId: Maybe<Tag>;
  tagId: Scalars["BigInt"]["output"];
  /** Reads a single `WorkflowResult` that is related to this `WorkflowResultTag`. */
  workflowResultByResultId: Maybe<WorkflowResult>;
};

/**
 * A condition to be used against `WorkflowResultTag` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type WorkflowResultTagCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `resultId` field. */
  resultId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tagId` field. */
  tagId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A filter to be used against `WorkflowResultTag` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowResultTagFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WorkflowResultTagFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WorkflowResultTagFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WorkflowResultTagFilter>>;
  /** Filter by the object’s `resultId` field. */
  resultId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tagByTagId` relation. */
  tagByTagId?: InputMaybe<TagFilter>;
  /** Filter by the object’s `tagId` field. */
  tagId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `workflowResultByResultId` relation. */
  workflowResultByResultId?: InputMaybe<WorkflowResultFilter>;
};

/** An input for mutations affecting `WorkflowResultTag` */
export type WorkflowResultTagInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  resultId?: InputMaybe<Scalars["UUID"]["input"]>;
  tagId?: InputMaybe<Scalars["BigInt"]["input"]>;
  tagToTagId?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdInput>;
  workflowResultToResultId?: InputMaybe<WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInput>;
};

/** The fields on `workflowResultTag` to look up the row to update. */
export type WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdUsingWorkflowResultTagsPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `workflowResultTag` being updated. */
    workflowResultTagPatch: UpdateWorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdPatch;
  };

/** The fields on `workflowResultTag` to look up the row to update. */
export type WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdUsingWorkflowResultTagsResultIdTagId960B8A2CUniqUpdate =
  {
    resultId: Scalars["UUID"]["input"];
    tagId: Scalars["BigInt"]["input"];
    /** An object where the defined keys will be set on the `workflowResultTag` being updated. */
    workflowResultTagPatch: UpdateWorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdPatch;
  };

/** The fields on `workflowResultTag` to look up the row to update. */
export type WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdUsingWorkflowResultTagsPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `workflowResultTag` being updated. */
    workflowResultTagPatch: UpdateWorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdPatch;
  };

/** The fields on `workflowResultTag` to look up the row to update. */
export type WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdUsingWorkflowResultTagsResultIdTagId960B8A2CUniqUpdate =
  {
    resultId: Scalars["UUID"]["input"];
    tagId: Scalars["BigInt"]["input"];
    /** An object where the defined keys will be set on the `workflowResultTag` being updated. */
    workflowResultTagPatch: UpdateWorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdPatch;
  };

/** Represents an update to a `WorkflowResultTag`. Fields that are set will be updated. */
export type WorkflowResultTagPatch = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  resultId?: InputMaybe<Scalars["UUID"]["input"]>;
  tagId?: InputMaybe<Scalars["BigInt"]["input"]>;
  tagToTagId?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdInput>;
  workflowResultToResultId?: InputMaybe<WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInput>;
};

/** The fields on `workflowResultTag` to look up the row to connect. */
export type WorkflowResultTagWorkflowResultTagsPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `workflowResultTag` to look up the row to delete. */
export type WorkflowResultTagWorkflowResultTagsPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** The fields on `workflowResultTag` to look up the row to connect. */
export type WorkflowResultTagWorkflowResultTagsResultIdTagId960B8A2CUniqConnect =
  {
    resultId: Scalars["UUID"]["input"];
    tagId: Scalars["BigInt"]["input"];
  };

/** The fields on `workflowResultTag` to look up the row to delete. */
export type WorkflowResultTagWorkflowResultTagsResultIdTagId960B8A2CUniqDelete =
  {
    resultId: Scalars["UUID"]["input"];
    tagId: Scalars["BigInt"]["input"];
  };

/** A connection to a list of `WorkflowResultTag` values. */
export type WorkflowResultTagsConnection = {
  __typename: "WorkflowResultTagsConnection";
  /** A list of edges which contains the `WorkflowResultTag` and cursor to aid in pagination. */
  edges: Array<WorkflowResultTagsEdge>;
  /** A list of `WorkflowResultTag` objects. */
  nodes: Array<WorkflowResultTag>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WorkflowResultTag` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `WorkflowResultTag` edge in the connection. */
export type WorkflowResultTagsEdge = {
  __typename: "WorkflowResultTagsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `WorkflowResultTag` at the end of the edge. */
  node: WorkflowResultTag;
};

/** Methods to use when ordering `WorkflowResultTag`. */
export enum WorkflowResultTagsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ResultIdAsc = "RESULT_ID_ASC",
  ResultIdDesc = "RESULT_ID_DESC",
  TagIdAsc = "TAG_ID_ASC",
  TagIdDesc = "TAG_ID_DESC",
}

/** Input for the nested mutation of `workflowResult` in the `WorkflowResultTagInput` mutation. */
export type WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInput = {
  /** The primary key(s) for `workflowResult` for the far side of the relationship. */
  connectById?: InputMaybe<WorkflowResultWorkflowResultPkeyConnect>;
  /** A `WorkflowResultInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdWorkflowResultCreateInput>;
  /** The primary key(s) for `workflowResult` for the far side of the relationship. */
  deleteById?: InputMaybe<WorkflowResultWorkflowResultPkeyDelete>;
  /** The primary key(s) and patch data for `workflowResult` for the far side of the relationship. */
  updateById?: InputMaybe<WorkflowResultOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdUsingWorkflowResultPkeyUpdate>;
};

/** Input for the nested mutation of `workflowResultTag` in the `WorkflowResultInput` mutation. */
export type WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInverseInput = {
  /** The primary key(s) for `workflowResultTag` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<WorkflowResultTagWorkflowResultTagsPkeyConnect>
  >;
  /** The primary key(s) for `workflowResultTag` for the far side of the relationship. */
  connectByResultIdAndTagId?: InputMaybe<
    Array<WorkflowResultTagWorkflowResultTagsResultIdTagId960B8A2CUniqConnect>
  >;
  /** A `WorkflowResultTagInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdWorkflowResultTagsCreateInput>
  >;
  /** The primary key(s) for `workflowResultTag` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<WorkflowResultTagWorkflowResultTagsPkeyDelete>>;
  /** The primary key(s) for `workflowResultTag` for the far side of the relationship. */
  deleteByResultIdAndTagId?: InputMaybe<
    Array<WorkflowResultTagWorkflowResultTagsResultIdTagId960B8A2CUniqDelete>
  >;
  /** Flag indicating whether all other `workflowResultTag` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `workflowResultTag` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdUsingWorkflowResultTagsPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `workflowResultTag` for the far side of the relationship. */
  updateByResultIdAndTagId?: InputMaybe<
    Array<WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdUsingWorkflowResultTagsResultIdTagId960B8A2CUniqUpdate>
  >;
};

/** The `workflowResult` to be created by this mutation. */
export type WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdWorkflowResultCreateInput =
  {
    id: Scalars["UUID"]["input"];
    objectId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowDatumToObjectId?: InputMaybe<WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInput>;
    workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInverseInput>;
    workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkResultId50F959E2FkWorkflowInverseInput>;
  };

/** The `workflowResultTag` to be created by this mutation. */
export type WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdWorkflowResultTagsCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    tagId?: InputMaybe<Scalars["BigInt"]["input"]>;
    tagToTagId?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdInput>;
    workflowResultToResultId?: InputMaybe<WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInput>;
  };

/** Input for the nested mutation of `tag` in the `WorkflowResultTagInput` mutation. */
export type WorkflowResultTagsTagId4B7994A6FkTagIdInput = {
  /** The primary key(s) for `tag` for the far side of the relationship. */
  connectById?: InputMaybe<TagTagPkeyConnect>;
  /** A `TagInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdTagCreateInput>;
  /** The primary key(s) for `tag` for the far side of the relationship. */
  deleteById?: InputMaybe<TagTagPkeyDelete>;
  /** The primary key(s) and patch data for `tag` for the far side of the relationship. */
  updateById?: InputMaybe<TagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdUsingTagPkeyUpdate>;
};

/** Input for the nested mutation of `workflowResultTag` in the `TagInput` mutation. */
export type WorkflowResultTagsTagId4B7994A6FkTagIdInverseInput = {
  /** The primary key(s) for `workflowResultTag` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<WorkflowResultTagWorkflowResultTagsPkeyConnect>
  >;
  /** The primary key(s) for `workflowResultTag` for the far side of the relationship. */
  connectByResultIdAndTagId?: InputMaybe<
    Array<WorkflowResultTagWorkflowResultTagsResultIdTagId960B8A2CUniqConnect>
  >;
  /** A `WorkflowResultTagInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<WorkflowResultTagsTagId4B7994A6FkTagIdWorkflowResultTagsCreateInput>
  >;
  /** The primary key(s) for `workflowResultTag` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<WorkflowResultTagWorkflowResultTagsPkeyDelete>>;
  /** The primary key(s) for `workflowResultTag` for the far side of the relationship. */
  deleteByResultIdAndTagId?: InputMaybe<
    Array<WorkflowResultTagWorkflowResultTagsResultIdTagId960B8A2CUniqDelete>
  >;
  /** Flag indicating whether all other `workflowResultTag` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `workflowResultTag` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdUsingWorkflowResultTagsPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `workflowResultTag` for the far side of the relationship. */
  updateByResultIdAndTagId?: InputMaybe<
    Array<WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdUsingWorkflowResultTagsResultIdTagId960B8A2CUniqUpdate>
  >;
};

/** The `tag` to be created by this mutation. */
export type WorkflowResultTagsTagId4B7994A6FkTagIdTagCreateInput = {
  applicationUserToUserId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  label: Scalars["String"]["input"];
  parameters?: InputMaybe<Scalars["JSON"]["input"]>;
  parentId?: InputMaybe<Scalars["BigInt"]["input"]>;
  tagToParentId?: InputMaybe<TagParentIdD2A3Ee5EFkTagIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TagTenantId8072B61FFkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdInverseInput>;
  workflowSourceTagsUsingId?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdInverseInput>;
};

/** The `workflowResultTag` to be created by this mutation. */
export type WorkflowResultTagsTagId4B7994A6FkTagIdWorkflowResultTagsCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    resultId?: InputMaybe<Scalars["UUID"]["input"]>;
    tagToTagId?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdInput>;
    workflowResultToResultId?: InputMaybe<WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInput>;
  };

/** A filter to be used against many `WorkflowResultTag` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowResultToManyWorkflowResultTagFilter = {
  /** Every related `WorkflowResultTag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowResultTagFilter>;
  /** No related `WorkflowResultTag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowResultTagFilter>;
  /** Some related `WorkflowResultTag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowResultTagFilter>;
};

/** A filter to be used against many `WorkflowResultWorkflowVersion` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowResultToManyWorkflowResultWorkflowVersionFilter = {
  /** Every related `WorkflowResultWorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowResultWorkflowVersionFilter>;
  /** No related `WorkflowResultWorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowResultWorkflowVersionFilter>;
  /** Some related `WorkflowResultWorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowResultWorkflowVersionFilter>;
};

/** Input for the nested mutation of `workflowResult` in the `WorkflowResultWorkflowVersionInput` mutation. */
export type WorkflowResultWorkResultId50F959E2FkWorkflowInput = {
  /** The primary key(s) for `workflowResult` for the far side of the relationship. */
  connectById?: InputMaybe<WorkflowResultWorkflowResultPkeyConnect>;
  /** A `WorkflowResultInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowResultWorkResultId50F959E2FkWorkflowWorkflowResultCreateInput>;
  /** The primary key(s) for `workflowResult` for the far side of the relationship. */
  deleteById?: InputMaybe<WorkflowResultWorkflowResultPkeyDelete>;
  /** The primary key(s) and patch data for `workflowResult` for the far side of the relationship. */
  updateById?: InputMaybe<WorkflowResultOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowUsingWorkflowResultPkeyUpdate>;
};

/** Input for the nested mutation of `workflowResultWorkflowVersion` in the `WorkflowResultInput` mutation. */
export type WorkflowResultWorkResultId50F959E2FkWorkflowInverseInput = {
  /** The primary key(s) for `workflowResultWorkflowVersion` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<WorkflowResultWorkflowVersionWorkflowResultWorkflowVersionsPkeyConnect>
  >;
  /** The primary key(s) for `workflowResultWorkflowVersion` for the far side of the relationship. */
  connectByResultIdAndWorkflowversionId?: InputMaybe<
    Array<WorkflowResultWorkflowVersionWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqConnect>
  >;
  /** A `WorkflowResultWorkflowVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<WorkflowResultWorkResultId50F959E2FkWorkflowWorkflowResultWorkflowVersionsCreateInput>
  >;
  /** The primary key(s) for `workflowResultWorkflowVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<WorkflowResultWorkflowVersionWorkflowResultWorkflowVersionsPkeyDelete>
  >;
  /** The primary key(s) for `workflowResultWorkflowVersion` for the far side of the relationship. */
  deleteByResultIdAndWorkflowversionId?: InputMaybe<
    Array<WorkflowResultWorkflowVersionWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqDelete>
  >;
  /** Flag indicating whether all other `workflowResultWorkflowVersion` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `workflowResultWorkflowVersion` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowUsingWorkflowResultWorkflowVersionsPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `workflowResultWorkflowVersion` for the far side of the relationship. */
  updateByResultIdAndWorkflowversionId?: InputMaybe<
    Array<WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowUsingWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqUpdate>
  >;
};

/** The `workflowResult` to be created by this mutation. */
export type WorkflowResultWorkResultId50F959E2FkWorkflowWorkflowResultCreateInput =
  {
    id: Scalars["UUID"]["input"];
    objectId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowDatumToObjectId?: InputMaybe<WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInput>;
    workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInverseInput>;
    workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkResultId50F959E2FkWorkflowInverseInput>;
  };

/** The `workflowResultWorkflowVersion` to be created by this mutation. */
export type WorkflowResultWorkResultId50F959E2FkWorkflowWorkflowResultWorkflowVersionsCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    workflowResultToResultId?: InputMaybe<WorkflowResultWorkResultId50F959E2FkWorkflowInput>;
    workflowVersionToWorkflowversionId?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInput>;
    workflowversionId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `workflowVersion` in the `WorkflowResultWorkflowVersionInput` mutation. */
export type WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInput = {
  /** The primary key(s) for `workflowVersion` for the far side of the relationship. */
  connectById?: InputMaybe<WorkflowVersionWorkflowVersionPkeyConnect>;
  /** A `WorkflowVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowWorkflowVersionCreateInput>;
  /** The primary key(s) for `workflowVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<WorkflowVersionWorkflowVersionPkeyDelete>;
  /** The primary key(s) and patch data for `workflowVersion` for the far side of the relationship. */
  updateById?: InputMaybe<WorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowUsingWorkflowVersionPkeyUpdate>;
};

/** Input for the nested mutation of `workflowResultWorkflowVersion` in the `WorkflowVersionInput` mutation. */
export type WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInverseInput =
  {
    /** The primary key(s) for `workflowResultWorkflowVersion` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<WorkflowResultWorkflowVersionWorkflowResultWorkflowVersionsPkeyConnect>
    >;
    /** The primary key(s) for `workflowResultWorkflowVersion` for the far side of the relationship. */
    connectByResultIdAndWorkflowversionId?: InputMaybe<
      Array<WorkflowResultWorkflowVersionWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqConnect>
    >;
    /** A `WorkflowResultWorkflowVersionInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowWorkflowResultWorkflowVersionsCreateInput>
    >;
    /** The primary key(s) for `workflowResultWorkflowVersion` for the far side of the relationship. */
    deleteById?: InputMaybe<
      Array<WorkflowResultWorkflowVersionWorkflowResultWorkflowVersionsPkeyDelete>
    >;
    /** The primary key(s) for `workflowResultWorkflowVersion` for the far side of the relationship. */
    deleteByResultIdAndWorkflowversionId?: InputMaybe<
      Array<WorkflowResultWorkflowVersionWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqDelete>
    >;
    /** Flag indicating whether all other `workflowResultWorkflowVersion` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `workflowResultWorkflowVersion` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowUsingWorkflowResultWorkflowVersionsPkeyUpdate>
    >;
    /** The primary key(s) and patch data for `workflowResultWorkflowVersion` for the far side of the relationship. */
    updateByResultIdAndWorkflowversionId?: InputMaybe<
      Array<WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowUsingWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqUpdate>
    >;
  };

/** The `workflowResultWorkflowVersion` to be created by this mutation. */
export type WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowWorkflowResultWorkflowVersionsCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    resultId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowResultToResultId?: InputMaybe<WorkflowResultWorkResultId50F959E2FkWorkflowInput>;
    workflowVersionToWorkflowversionId?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInput>;
  };

/** The `workflowVersion` to be created by this mutation. */
export type WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowWorkflowVersionCreateInput =
  {
    applicationUserToUserId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInput>;
    autoGenerated: Scalars["Boolean"]["input"];
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    draft: Scalars["Boolean"]["input"];
    id: Scalars["UUID"]["input"];
    name: Scalars["String"]["input"];
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInverseInput>;
    workflowSectionWorkflowVersionsUsingId?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInverseInput>;
    workflowSourceWorkflowVersionsUsingId?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInverseInput>;
    workflowToWorkflowId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInput>;
  };

/** The fields on `workflowResult` to look up the row to connect. */
export type WorkflowResultWorkflowResultPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `workflowResult` to look up the row to delete. */
export type WorkflowResultWorkflowResultPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

export type WorkflowResultWorkflowVersion = {
  __typename: "WorkflowResultWorkflowVersion";
  id: Scalars["Int"]["output"];
  resultId: Scalars["UUID"]["output"];
  /** Reads a single `WorkflowResult` that is related to this `WorkflowResultWorkflowVersion`. */
  workflowResultByResultId: Maybe<WorkflowResult>;
  /** Reads a single `WorkflowVersion` that is related to this `WorkflowResultWorkflowVersion`. */
  workflowVersionByWorkflowversionId: Maybe<WorkflowVersion>;
  workflowversionId: Scalars["UUID"]["output"];
};

/**
 * A condition to be used against `WorkflowResultWorkflowVersion` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type WorkflowResultWorkflowVersionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `resultId` field. */
  resultId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `workflowversionId` field. */
  workflowversionId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `WorkflowResultWorkflowVersion` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowResultWorkflowVersionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WorkflowResultWorkflowVersionFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WorkflowResultWorkflowVersionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WorkflowResultWorkflowVersionFilter>>;
  /** Filter by the object’s `resultId` field. */
  resultId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `workflowResultByResultId` relation. */
  workflowResultByResultId?: InputMaybe<WorkflowResultFilter>;
  /** Filter by the object’s `workflowVersionByWorkflowversionId` relation. */
  workflowVersionByWorkflowversionId?: InputMaybe<WorkflowVersionFilter>;
  /** Filter by the object’s `workflowversionId` field. */
  workflowversionId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `WorkflowResultWorkflowVersion` */
export type WorkflowResultWorkflowVersionInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  resultId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowResultToResultId?: InputMaybe<WorkflowResultWorkResultId50F959E2FkWorkflowInput>;
  workflowVersionToWorkflowversionId?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInput>;
  workflowversionId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `workflowResultWorkflowVersion` to look up the row to update. */
export type WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowUsingWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqUpdate =
  {
    resultId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowResultWorkflowVersion` being updated. */
    workflowResultWorkflowVersionPatch: UpdateWorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowPatch;
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The fields on `workflowResultWorkflowVersion` to look up the row to update. */
export type WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowUsingWorkflowResultWorkflowVersionsPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `workflowResultWorkflowVersion` being updated. */
    workflowResultWorkflowVersionPatch: UpdateWorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowPatch;
  };

/** The fields on `workflowResultWorkflowVersion` to look up the row to update. */
export type WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowUsingWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqUpdate =
  {
    resultId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowResultWorkflowVersion` being updated. */
    workflowResultWorkflowVersionPatch: UpdateWorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowPatch;
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The fields on `workflowResultWorkflowVersion` to look up the row to update. */
export type WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowUsingWorkflowResultWorkflowVersionsPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `workflowResultWorkflowVersion` being updated. */
    workflowResultWorkflowVersionPatch: UpdateWorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowPatch;
  };

/** Represents an update to a `WorkflowResultWorkflowVersion`. Fields that are set will be updated. */
export type WorkflowResultWorkflowVersionPatch = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  resultId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowResultToResultId?: InputMaybe<WorkflowResultWorkResultId50F959E2FkWorkflowInput>;
  workflowVersionToWorkflowversionId?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInput>;
  workflowversionId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `workflowResultWorkflowVersion` to look up the row to connect. */
export type WorkflowResultWorkflowVersionWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqConnect =
  {
    resultId: Scalars["UUID"]["input"];
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The fields on `workflowResultWorkflowVersion` to look up the row to delete. */
export type WorkflowResultWorkflowVersionWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqDelete =
  {
    resultId: Scalars["UUID"]["input"];
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The fields on `workflowResultWorkflowVersion` to look up the row to connect. */
export type WorkflowResultWorkflowVersionWorkflowResultWorkflowVersionsPkeyConnect =
  {
    id: Scalars["Int"]["input"];
  };

/** The fields on `workflowResultWorkflowVersion` to look up the row to delete. */
export type WorkflowResultWorkflowVersionWorkflowResultWorkflowVersionsPkeyDelete =
  {
    id: Scalars["Int"]["input"];
  };

/** A connection to a list of `WorkflowResultWorkflowVersion` values. */
export type WorkflowResultWorkflowVersionsConnection = {
  __typename: "WorkflowResultWorkflowVersionsConnection";
  /** A list of edges which contains the `WorkflowResultWorkflowVersion` and cursor to aid in pagination. */
  edges: Array<WorkflowResultWorkflowVersionsEdge>;
  /** A list of `WorkflowResultWorkflowVersion` objects. */
  nodes: Array<WorkflowResultWorkflowVersion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WorkflowResultWorkflowVersion` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `WorkflowResultWorkflowVersion` edge in the connection. */
export type WorkflowResultWorkflowVersionsEdge = {
  __typename: "WorkflowResultWorkflowVersionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `WorkflowResultWorkflowVersion` at the end of the edge. */
  node: WorkflowResultWorkflowVersion;
};

/** Methods to use when ordering `WorkflowResultWorkflowVersion`. */
export enum WorkflowResultWorkflowVersionsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ResultIdAsc = "RESULT_ID_ASC",
  ResultIdDesc = "RESULT_ID_DESC",
  WorkflowversionIdAsc = "WORKFLOWVERSION_ID_ASC",
  WorkflowversionIdDesc = "WORKFLOWVERSION_ID_DESC",
}

/** A connection to a list of `WorkflowResult` values. */
export type WorkflowResultsConnection = {
  __typename: "WorkflowResultsConnection";
  /** A list of edges which contains the `WorkflowResult` and cursor to aid in pagination. */
  edges: Array<WorkflowResultsEdge>;
  /** A list of `WorkflowResult` objects. */
  nodes: Array<WorkflowResult>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WorkflowResult` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `WorkflowResult` edge in the connection. */
export type WorkflowResultsEdge = {
  __typename: "WorkflowResultsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `WorkflowResult` at the end of the edge. */
  node: WorkflowResult;
};

/** Methods to use when ordering `WorkflowResult`. */
export enum WorkflowResultsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  ObjectIdAsc = "OBJECT_ID_ASC",
  ObjectIdDesc = "OBJECT_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type WorkflowSection = {
  __typename: "WorkflowSection";
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["UUID"]["output"];
  order: Scalars["Int"]["output"];
  title: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `WorkflowSectionBlockSection`. */
  workflowSectionBlockSectionsBySectionId: WorkflowSectionBlockSectionsConnection;
  /** Reads and enables pagination through a set of `WorkflowSectionWorkflowVersion`. */
  workflowSectionWorkflowVersionsBySectionId: WorkflowSectionWorkflowVersionsConnection;
};

export type WorkflowSectionWorkflowSectionBlockSectionsBySectionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSectionBlockSectionCondition>;
  filter?: InputMaybe<WorkflowSectionBlockSectionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSectionBlockSectionsOrderBy>>;
};

export type WorkflowSectionWorkflowSectionWorkflowVersionsBySectionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSectionWorkflowVersionCondition>;
  filter?: InputMaybe<WorkflowSectionWorkflowVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSectionWorkflowVersionsOrderBy>>;
};

/** Input for the nested mutation of `workflowSectionBlock` in the `WorkflowSectionBlockSectionInput` mutation. */
export type WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInput = {
  /** The primary key(s) for `workflowSectionBlock` for the far side of the relationship. */
  connectById?: InputMaybe<WorkflowSectionBlockWorkflowSectionBlockPkeyConnect>;
  /** A `WorkflowSectionBlockInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowWorkflowSectionBlockCreateInput>;
  /** The primary key(s) for `workflowSectionBlock` for the far side of the relationship. */
  deleteById?: InputMaybe<WorkflowSectionBlockWorkflowSectionBlockPkeyDelete>;
  /** The primary key(s) and patch data for `workflowSectionBlock` for the far side of the relationship. */
  updateById?: InputMaybe<WorkflowSectionBlockOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowUsingWorkflowSectionBlockPkeyUpdate>;
};

/** Input for the nested mutation of `workflowSectionBlockSection` in the `WorkflowSectionBlockInput` mutation. */
export type WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInverseInput = {
  /** The primary key(s) for `workflowSectionBlockSection` for the far side of the relationship. */
  connectByBlockIdAndSectionId?: InputMaybe<
    Array<WorkflowSectionBlockSectionWorkflowSectionBlockSBlockIdSectionId2F785D93UniqConnect>
  >;
  /** The primary key(s) for `workflowSectionBlockSection` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<WorkflowSectionBlockSectionWorkflowSectionBlockSectionsPkeyConnect>
  >;
  /** A `WorkflowSectionBlockSectionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowWorkflowSectionBlockSectionsCreateInput>
  >;
  /** The primary key(s) for `workflowSectionBlockSection` for the far side of the relationship. */
  deleteByBlockIdAndSectionId?: InputMaybe<
    Array<WorkflowSectionBlockSectionWorkflowSectionBlockSBlockIdSectionId2F785D93UniqDelete>
  >;
  /** The primary key(s) for `workflowSectionBlockSection` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<WorkflowSectionBlockSectionWorkflowSectionBlockSectionsPkeyDelete>
  >;
  /** Flag indicating whether all other `workflowSectionBlockSection` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `workflowSectionBlockSection` for the far side of the relationship. */
  updateByBlockIdAndSectionId?: InputMaybe<
    Array<WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowUsingWorkflowSectionBlockSBlockIdSectionId2F785D93UniqUpdate>
  >;
  /** The primary key(s) and patch data for `workflowSectionBlockSection` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowUsingWorkflowSectionBlockSectionsPkeyUpdate>
  >;
};

/** The `workflowSectionBlock` to be created by this mutation. */
export type WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowWorkflowSectionBlockCreateInput =
  {
    columns: Scalars["Int"]["input"];
    id: Scalars["UUID"]["input"];
    markdown?: InputMaybe<Scalars["String"]["input"]>;
    objectId?: InputMaybe<Scalars["UUID"]["input"]>;
    order: Scalars["Int"]["input"];
    parameters?: InputMaybe<Scalars["JSON"]["input"]>;
    results?: InputMaybe<Scalars["JSON"]["input"]>;
    sources?: InputMaybe<Scalars["JSON"]["input"]>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInput>;
    workflowDatumToObjectId?: InputMaybe<WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInput>;
    workflowSectionBlockSectionsUsingId?: InputMaybe<WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInverseInput>;
  };

/** The `workflowSectionBlockSection` to be created by this mutation. */
export type WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowWorkflowSectionBlockSectionsCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    sectionId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowSectionBlockToBlockId?: InputMaybe<WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInput>;
    workflowSectionToSectionId?: InputMaybe<WorkflowSectionBloSectionId817C1C77FkWorkflowInput>;
  };

/** Input for the nested mutation of `workflowSection` in the `WorkflowSectionBlockSectionInput` mutation. */
export type WorkflowSectionBloSectionId817C1C77FkWorkflowInput = {
  /** The primary key(s) for `workflowSection` for the far side of the relationship. */
  connectById?: InputMaybe<WorkflowSectionWorkflowSectionPkeyConnect>;
  /** A `WorkflowSectionInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowSectionBloSectionId817C1C77FkWorkflowWorkflowSectionCreateInput>;
  /** The primary key(s) for `workflowSection` for the far side of the relationship. */
  deleteById?: InputMaybe<WorkflowSectionWorkflowSectionPkeyDelete>;
  /** The primary key(s) and patch data for `workflowSection` for the far side of the relationship. */
  updateById?: InputMaybe<WorkflowSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowUsingWorkflowSectionPkeyUpdate>;
};

/** Input for the nested mutation of `workflowSectionBlockSection` in the `WorkflowSectionInput` mutation. */
export type WorkflowSectionBloSectionId817C1C77FkWorkflowInverseInput = {
  /** The primary key(s) for `workflowSectionBlockSection` for the far side of the relationship. */
  connectByBlockIdAndSectionId?: InputMaybe<
    Array<WorkflowSectionBlockSectionWorkflowSectionBlockSBlockIdSectionId2F785D93UniqConnect>
  >;
  /** The primary key(s) for `workflowSectionBlockSection` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<WorkflowSectionBlockSectionWorkflowSectionBlockSectionsPkeyConnect>
  >;
  /** A `WorkflowSectionBlockSectionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<WorkflowSectionBloSectionId817C1C77FkWorkflowWorkflowSectionBlockSectionsCreateInput>
  >;
  /** The primary key(s) for `workflowSectionBlockSection` for the far side of the relationship. */
  deleteByBlockIdAndSectionId?: InputMaybe<
    Array<WorkflowSectionBlockSectionWorkflowSectionBlockSBlockIdSectionId2F785D93UniqDelete>
  >;
  /** The primary key(s) for `workflowSectionBlockSection` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<WorkflowSectionBlockSectionWorkflowSectionBlockSectionsPkeyDelete>
  >;
  /** Flag indicating whether all other `workflowSectionBlockSection` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `workflowSectionBlockSection` for the far side of the relationship. */
  updateByBlockIdAndSectionId?: InputMaybe<
    Array<WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowUsingWorkflowSectionBlockSBlockIdSectionId2F785D93UniqUpdate>
  >;
  /** The primary key(s) and patch data for `workflowSectionBlockSection` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowUsingWorkflowSectionBlockSectionsPkeyUpdate>
  >;
};

/** The `workflowSectionBlockSection` to be created by this mutation. */
export type WorkflowSectionBloSectionId817C1C77FkWorkflowWorkflowSectionBlockSectionsCreateInput =
  {
    blockId?: InputMaybe<Scalars["UUID"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    workflowSectionBlockToBlockId?: InputMaybe<WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInput>;
    workflowSectionToSectionId?: InputMaybe<WorkflowSectionBloSectionId817C1C77FkWorkflowInput>;
  };

/** The `workflowSection` to be created by this mutation. */
export type WorkflowSectionBloSectionId817C1C77FkWorkflowWorkflowSectionCreateInput =
  {
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    order: Scalars["Int"]["input"];
    title?: InputMaybe<Scalars["String"]["input"]>;
    workflowSectionBlockSectionsUsingId?: InputMaybe<WorkflowSectionBloSectionId817C1C77FkWorkflowInverseInput>;
    workflowSectionWorkflowVersionsUsingId?: InputMaybe<WorkflowSectionWorSectionId1D5C2D63FkWorkflowInverseInput>;
  };

export type WorkflowSectionBlock = {
  __typename: "WorkflowSectionBlock";
  columns: Scalars["Int"]["output"];
  id: Scalars["UUID"]["output"];
  markdown: Maybe<Scalars["String"]["output"]>;
  objectId: Maybe<Scalars["UUID"]["output"]>;
  order: Scalars["Int"]["output"];
  parameters: Maybe<Scalars["JSON"]["output"]>;
  results: Maybe<Scalars["JSON"]["output"]>;
  sources: Maybe<Scalars["JSON"]["output"]>;
  toolId: Maybe<Scalars["Int"]["output"]>;
  /** Reads a single `ToolVersion` that is related to this `WorkflowSectionBlock`. */
  toolVersionByToolId: Maybe<ToolVersion>;
  /** Reads a single `WorkflowDatum` that is related to this `WorkflowSectionBlock`. */
  workflowDatumByObjectId: Maybe<WorkflowDatum>;
  /** Reads and enables pagination through a set of `WorkflowSectionBlockSection`. */
  workflowSectionBlockSectionsByBlockId: WorkflowSectionBlockSectionsConnection;
};

export type WorkflowSectionBlockWorkflowSectionBlockSectionsByBlockIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSectionBlockSectionCondition>;
  filter?: InputMaybe<WorkflowSectionBlockSectionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSectionBlockSectionsOrderBy>>;
};

/**
 * A condition to be used against `WorkflowSectionBlock` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type WorkflowSectionBlockCondition = {
  /** Checks for equality with the object’s `columns` field. */
  columns?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `markdown` field. */
  markdown?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `objectId` field. */
  objectId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `order` field. */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `parameters` field. */
  parameters?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `results` field. */
  results?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `sources` field. */
  sources?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `toolId` field. */
  toolId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `WorkflowSectionBlock` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowSectionBlockFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WorkflowSectionBlockFilter>>;
  /** Filter by the object’s `columns` field. */
  columns?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `markdown` field. */
  markdown?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WorkflowSectionBlockFilter>;
  /** Filter by the object’s `objectId` field. */
  objectId?: InputMaybe<UuidFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WorkflowSectionBlockFilter>>;
  /** Filter by the object’s `order` field. */
  order?: InputMaybe<IntFilter>;
  /** Filter by the object’s `parameters` field. */
  parameters?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `results` field. */
  results?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `sources` field. */
  sources?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `toolId` field. */
  toolId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `toolVersionByToolId` relation. */
  toolVersionByToolId?: InputMaybe<ToolVersionFilter>;
  /** A related `toolVersionByToolId` exists. */
  toolVersionByToolIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `workflowDatumByObjectId` relation. */
  workflowDatumByObjectId?: InputMaybe<WorkflowDatumFilter>;
  /** A related `workflowDatumByObjectId` exists. */
  workflowDatumByObjectIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `workflowSectionBlockSectionsByBlockId` relation. */
  workflowSectionBlockSectionsByBlockId?: InputMaybe<WorkflowSectionBlockToManyWorkflowSectionBlockSectionFilter>;
  /** Some related `workflowSectionBlockSectionsByBlockId` exist. */
  workflowSectionBlockSectionsByBlockIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
};

/** An input for mutations affecting `WorkflowSectionBlock` */
export type WorkflowSectionBlockInput = {
  columns: Scalars["Int"]["input"];
  id: Scalars["UUID"]["input"];
  markdown?: InputMaybe<Scalars["String"]["input"]>;
  objectId?: InputMaybe<Scalars["UUID"]["input"]>;
  order: Scalars["Int"]["input"];
  parameters?: InputMaybe<Scalars["JSON"]["input"]>;
  results?: InputMaybe<Scalars["JSON"]["input"]>;
  sources?: InputMaybe<Scalars["JSON"]["input"]>;
  toolId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInput>;
  workflowDatumToObjectId?: InputMaybe<WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInput>;
  workflowSectionBlockSectionsUsingId?: InputMaybe<WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInverseInput>;
};

/** Input for the nested mutation of `workflowDatum` in the `WorkflowSectionBlockInput` mutation. */
export type WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInput = {
  /** The primary key(s) for `workflowDatum` for the far side of the relationship. */
  connectById?: InputMaybe<WorkflowDatumWorkflowDataPkeyConnect>;
  /** A `WorkflowDatumInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdWorkflowDataCreateInput>;
  /** The primary key(s) for `workflowDatum` for the far side of the relationship. */
  deleteById?: InputMaybe<WorkflowDatumWorkflowDataPkeyDelete>;
  /** The primary key(s) and patch data for `workflowDatum` for the far side of the relationship. */
  updateById?: InputMaybe<WorkflowDatumOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdUsingWorkflowDataPkeyUpdate>;
};

/** Input for the nested mutation of `workflowSectionBlock` in the `WorkflowDatumInput` mutation. */
export type WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInverseInput = {
  /** The primary key(s) for `workflowSectionBlock` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<WorkflowSectionBlockWorkflowSectionBlockPkeyConnect>
  >;
  /** A `WorkflowSectionBlockInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdWorkflowSectionBlockCreateInput>
  >;
  /** The primary key(s) for `workflowSectionBlock` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<WorkflowSectionBlockWorkflowSectionBlockPkeyDelete>
  >;
  /** Flag indicating whether all other `workflowSectionBlock` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `workflowSectionBlock` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<WorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdUsingWorkflowSectionBlockPkeyUpdate>
  >;
};

/** The `workflowDatum` to be created by this mutation. */
export type WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdWorkflowDataCreateInput =
  {
    fresh: Scalars["Boolean"]["input"];
    id: Scalars["UUID"]["input"];
    workflowResultsUsingId?: InputMaybe<WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInverseInput>;
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInverseInput>;
  };

/** The `workflowSectionBlock` to be created by this mutation. */
export type WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdWorkflowSectionBlockCreateInput =
  {
    columns: Scalars["Int"]["input"];
    id: Scalars["UUID"]["input"];
    markdown?: InputMaybe<Scalars["String"]["input"]>;
    order: Scalars["Int"]["input"];
    parameters?: InputMaybe<Scalars["JSON"]["input"]>;
    results?: InputMaybe<Scalars["JSON"]["input"]>;
    sources?: InputMaybe<Scalars["JSON"]["input"]>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInput>;
    workflowDatumToObjectId?: InputMaybe<WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInput>;
    workflowSectionBlockSectionsUsingId?: InputMaybe<WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInverseInput>;
  };

/** The fields on `workflowSectionBlock` to look up the row to update. */
export type WorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdUsingWorkflowSectionBlockPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowSectionBlock` being updated. */
    workflowSectionBlockPatch: UpdateWorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdPatch;
  };

/** The fields on `workflowSectionBlock` to look up the row to update. */
export type WorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdUsingWorkflowSectionBlockPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowSectionBlock` being updated. */
    workflowSectionBlockPatch: UpdateWorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdPatch;
  };

/** The fields on `workflowSectionBlock` to look up the row to update. */
export type WorkflowSectionBlockOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowUsingWorkflowSectionBlockPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowSectionBlock` being updated. */
    workflowSectionBlockPatch: UpdateWorkflowSectionBlockOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowPatch;
  };

/** Represents an update to a `WorkflowSectionBlock`. Fields that are set will be updated. */
export type WorkflowSectionBlockPatch = {
  columns?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  markdown?: InputMaybe<Scalars["String"]["input"]>;
  objectId?: InputMaybe<Scalars["UUID"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  parameters?: InputMaybe<Scalars["JSON"]["input"]>;
  results?: InputMaybe<Scalars["JSON"]["input"]>;
  sources?: InputMaybe<Scalars["JSON"]["input"]>;
  toolId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInput>;
  workflowDatumToObjectId?: InputMaybe<WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInput>;
  workflowSectionBlockSectionsUsingId?: InputMaybe<WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInverseInput>;
};

export type WorkflowSectionBlockSection = {
  __typename: "WorkflowSectionBlockSection";
  blockId: Scalars["UUID"]["output"];
  id: Scalars["Int"]["output"];
  sectionId: Scalars["UUID"]["output"];
  /** Reads a single `WorkflowSectionBlock` that is related to this `WorkflowSectionBlockSection`. */
  workflowSectionBlockByBlockId: Maybe<WorkflowSectionBlock>;
  /** Reads a single `WorkflowSection` that is related to this `WorkflowSectionBlockSection`. */
  workflowSectionBySectionId: Maybe<WorkflowSection>;
};

/**
 * A condition to be used against `WorkflowSectionBlockSection` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type WorkflowSectionBlockSectionCondition = {
  /** Checks for equality with the object’s `blockId` field. */
  blockId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `sectionId` field. */
  sectionId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `WorkflowSectionBlockSection` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowSectionBlockSectionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WorkflowSectionBlockSectionFilter>>;
  /** Filter by the object’s `blockId` field. */
  blockId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WorkflowSectionBlockSectionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WorkflowSectionBlockSectionFilter>>;
  /** Filter by the object’s `sectionId` field. */
  sectionId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `workflowSectionBlockByBlockId` relation. */
  workflowSectionBlockByBlockId?: InputMaybe<WorkflowSectionBlockFilter>;
  /** Filter by the object’s `workflowSectionBySectionId` relation. */
  workflowSectionBySectionId?: InputMaybe<WorkflowSectionFilter>;
};

/** An input for mutations affecting `WorkflowSectionBlockSection` */
export type WorkflowSectionBlockSectionInput = {
  blockId?: InputMaybe<Scalars["UUID"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  sectionId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowSectionBlockToBlockId?: InputMaybe<WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInput>;
  workflowSectionToSectionId?: InputMaybe<WorkflowSectionBloSectionId817C1C77FkWorkflowInput>;
};

/** The fields on `workflowSectionBlockSection` to look up the row to update. */
export type WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowUsingWorkflowSectionBlockSBlockIdSectionId2F785D93UniqUpdate =
  {
    blockId: Scalars["UUID"]["input"];
    sectionId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowSectionBlockSection` being updated. */
    workflowSectionBlockSectionPatch: UpdateWorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowPatch;
  };

/** The fields on `workflowSectionBlockSection` to look up the row to update. */
export type WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowUsingWorkflowSectionBlockSectionsPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `workflowSectionBlockSection` being updated. */
    workflowSectionBlockSectionPatch: UpdateWorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowPatch;
  };

/** The fields on `workflowSectionBlockSection` to look up the row to update. */
export type WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowUsingWorkflowSectionBlockSBlockIdSectionId2F785D93UniqUpdate =
  {
    blockId: Scalars["UUID"]["input"];
    sectionId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowSectionBlockSection` being updated. */
    workflowSectionBlockSectionPatch: UpdateWorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowPatch;
  };

/** The fields on `workflowSectionBlockSection` to look up the row to update. */
export type WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowUsingWorkflowSectionBlockSectionsPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `workflowSectionBlockSection` being updated. */
    workflowSectionBlockSectionPatch: UpdateWorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowPatch;
  };

/** Represents an update to a `WorkflowSectionBlockSection`. Fields that are set will be updated. */
export type WorkflowSectionBlockSectionPatch = {
  blockId?: InputMaybe<Scalars["UUID"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  sectionId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowSectionBlockToBlockId?: InputMaybe<WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInput>;
  workflowSectionToSectionId?: InputMaybe<WorkflowSectionBloSectionId817C1C77FkWorkflowInput>;
};

/** The fields on `workflowSectionBlockSection` to look up the row to connect. */
export type WorkflowSectionBlockSectionWorkflowSectionBlockSBlockIdSectionId2F785D93UniqConnect =
  {
    blockId: Scalars["UUID"]["input"];
    sectionId: Scalars["UUID"]["input"];
  };

/** The fields on `workflowSectionBlockSection` to look up the row to delete. */
export type WorkflowSectionBlockSectionWorkflowSectionBlockSBlockIdSectionId2F785D93UniqDelete =
  {
    blockId: Scalars["UUID"]["input"];
    sectionId: Scalars["UUID"]["input"];
  };

/** The fields on `workflowSectionBlockSection` to look up the row to connect. */
export type WorkflowSectionBlockSectionWorkflowSectionBlockSectionsPkeyConnect =
  {
    id: Scalars["Int"]["input"];
  };

/** The fields on `workflowSectionBlockSection` to look up the row to delete. */
export type WorkflowSectionBlockSectionWorkflowSectionBlockSectionsPkeyDelete =
  {
    id: Scalars["Int"]["input"];
  };

/** A connection to a list of `WorkflowSectionBlockSection` values. */
export type WorkflowSectionBlockSectionsConnection = {
  __typename: "WorkflowSectionBlockSectionsConnection";
  /** A list of edges which contains the `WorkflowSectionBlockSection` and cursor to aid in pagination. */
  edges: Array<WorkflowSectionBlockSectionsEdge>;
  /** A list of `WorkflowSectionBlockSection` objects. */
  nodes: Array<WorkflowSectionBlockSection>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WorkflowSectionBlockSection` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `WorkflowSectionBlockSection` edge in the connection. */
export type WorkflowSectionBlockSectionsEdge = {
  __typename: "WorkflowSectionBlockSectionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `WorkflowSectionBlockSection` at the end of the edge. */
  node: WorkflowSectionBlockSection;
};

/** Methods to use when ordering `WorkflowSectionBlockSection`. */
export enum WorkflowSectionBlockSectionsOrderBy {
  BlockIdAsc = "BLOCK_ID_ASC",
  BlockIdDesc = "BLOCK_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SectionIdAsc = "SECTION_ID_ASC",
  SectionIdDesc = "SECTION_ID_DESC",
}

/** A filter to be used against many `WorkflowSectionBlockSection` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowSectionBlockToManyWorkflowSectionBlockSectionFilter = {
  /** Every related `WorkflowSectionBlockSection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowSectionBlockSectionFilter>;
  /** No related `WorkflowSectionBlockSection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowSectionBlockSectionFilter>;
  /** Some related `WorkflowSectionBlockSection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowSectionBlockSectionFilter>;
};

/** Input for the nested mutation of `toolVersion` in the `WorkflowSectionBlockInput` mutation. */
export type WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInput = {
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectById?: InputMaybe<ToolVersionToolVersionPkeyConnect>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  connectByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueConnect>;
  /** A `ToolVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdToolVersionCreateInput>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<ToolVersionToolVersionPkeyDelete>;
  /** The primary key(s) for `toolVersion` for the far side of the relationship. */
  deleteByToolIdAndVersion?: InputMaybe<ToolVersionToolversionToolVersionUniqueDelete>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateById?: InputMaybe<ToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdUsingToolVersionPkeyUpdate>;
  /** The primary key(s) and patch data for `toolVersion` for the far side of the relationship. */
  updateByToolIdAndVersion?: InputMaybe<ToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdUsingToolversionToolVersionUniqueUpdate>;
};

/** Input for the nested mutation of `workflowSectionBlock` in the `ToolVersionInput` mutation. */
export type WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput = {
  /** The primary key(s) for `workflowSectionBlock` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<WorkflowSectionBlockWorkflowSectionBlockPkeyConnect>
  >;
  /** A `WorkflowSectionBlockInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdWorkflowSectionBlockCreateInput>
  >;
  /** The primary key(s) for `workflowSectionBlock` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<WorkflowSectionBlockWorkflowSectionBlockPkeyDelete>
  >;
  /** Flag indicating whether all other `workflowSectionBlock` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `workflowSectionBlock` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<WorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdUsingWorkflowSectionBlockPkeyUpdate>
  >;
};

/** The `toolVersion` to be created by this mutation. */
export type WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdToolVersionCreateInput =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command: Scalars["String"]["input"];
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible: Scalars["Boolean"]["input"];
    isUpgradableToNextVersion: Scalars["Boolean"]["input"];
    maturity: Scalars["Int"]["input"];
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version: Scalars["String"]["input"];
    visibility: Scalars["Int"]["input"];
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** The `workflowSectionBlock` to be created by this mutation. */
export type WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdWorkflowSectionBlockCreateInput =
  {
    columns: Scalars["Int"]["input"];
    id: Scalars["UUID"]["input"];
    markdown?: InputMaybe<Scalars["String"]["input"]>;
    objectId?: InputMaybe<Scalars["UUID"]["input"]>;
    order: Scalars["Int"]["input"];
    parameters?: InputMaybe<Scalars["JSON"]["input"]>;
    results?: InputMaybe<Scalars["JSON"]["input"]>;
    sources?: InputMaybe<Scalars["JSON"]["input"]>;
    toolVersionToToolId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInput>;
    workflowDatumToObjectId?: InputMaybe<WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInput>;
    workflowSectionBlockSectionsUsingId?: InputMaybe<WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInverseInput>;
  };

/** The fields on `workflowSectionBlock` to look up the row to connect. */
export type WorkflowSectionBlockWorkflowSectionBlockPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `workflowSectionBlock` to look up the row to delete. */
export type WorkflowSectionBlockWorkflowSectionBlockPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** A connection to a list of `WorkflowSectionBlock` values. */
export type WorkflowSectionBlocksConnection = {
  __typename: "WorkflowSectionBlocksConnection";
  /** A list of edges which contains the `WorkflowSectionBlock` and cursor to aid in pagination. */
  edges: Array<WorkflowSectionBlocksEdge>;
  /** A list of `WorkflowSectionBlock` objects. */
  nodes: Array<WorkflowSectionBlock>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WorkflowSectionBlock` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `WorkflowSectionBlock` edge in the connection. */
export type WorkflowSectionBlocksEdge = {
  __typename: "WorkflowSectionBlocksEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `WorkflowSectionBlock` at the end of the edge. */
  node: WorkflowSectionBlock;
};

/** Methods to use when ordering `WorkflowSectionBlock`. */
export enum WorkflowSectionBlocksOrderBy {
  ColumnsAsc = "COLUMNS_ASC",
  ColumnsDesc = "COLUMNS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MarkdownAsc = "MARKDOWN_ASC",
  MarkdownDesc = "MARKDOWN_DESC",
  Natural = "NATURAL",
  ObjectIdAsc = "OBJECT_ID_ASC",
  ObjectIdDesc = "OBJECT_ID_DESC",
  OrderAsc = "ORDER_ASC",
  OrderDesc = "ORDER_DESC",
  ParametersAsc = "PARAMETERS_ASC",
  ParametersDesc = "PARAMETERS_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ResultsAsc = "RESULTS_ASC",
  ResultsDesc = "RESULTS_DESC",
  SourcesAsc = "SOURCES_ASC",
  SourcesDesc = "SOURCES_DESC",
  ToolIdAsc = "TOOL_ID_ASC",
  ToolIdDesc = "TOOL_ID_DESC",
}

/**
 * A condition to be used against `WorkflowSection` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type WorkflowSectionCondition = {
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `order` field. */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars["String"]["input"]>;
};

/** A filter to be used against `WorkflowSection` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowSectionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WorkflowSectionFilter>>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WorkflowSectionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WorkflowSectionFilter>>;
  /** Filter by the object’s `order` field. */
  order?: InputMaybe<IntFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `workflowSectionBlockSectionsBySectionId` relation. */
  workflowSectionBlockSectionsBySectionId?: InputMaybe<WorkflowSectionToManyWorkflowSectionBlockSectionFilter>;
  /** Some related `workflowSectionBlockSectionsBySectionId` exist. */
  workflowSectionBlockSectionsBySectionIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `workflowSectionWorkflowVersionsBySectionId` relation. */
  workflowSectionWorkflowVersionsBySectionId?: InputMaybe<WorkflowSectionToManyWorkflowSectionWorkflowVersionFilter>;
  /** Some related `workflowSectionWorkflowVersionsBySectionId` exist. */
  workflowSectionWorkflowVersionsBySectionIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
};

/** An input for mutations affecting `WorkflowSection` */
export type WorkflowSectionInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  order: Scalars["Int"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
  workflowSectionBlockSectionsUsingId?: InputMaybe<WorkflowSectionBloSectionId817C1C77FkWorkflowInverseInput>;
  workflowSectionWorkflowVersionsUsingId?: InputMaybe<WorkflowSectionWorSectionId1D5C2D63FkWorkflowInverseInput>;
};

/** The fields on `workflowSection` to look up the row to update. */
export type WorkflowSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowUsingWorkflowSectionPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowSection` being updated. */
    workflowSectionPatch: UpdateWorkflowSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowPatch;
  };

/** The fields on `workflowSection` to look up the row to update. */
export type WorkflowSectionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowUsingWorkflowSectionPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowSection` being updated. */
    workflowSectionPatch: UpdateWorkflowSectionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowPatch;
  };

/** Represents an update to a `WorkflowSection`. Fields that are set will be updated. */
export type WorkflowSectionPatch = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  workflowSectionBlockSectionsUsingId?: InputMaybe<WorkflowSectionBloSectionId817C1C77FkWorkflowInverseInput>;
  workflowSectionWorkflowVersionsUsingId?: InputMaybe<WorkflowSectionWorSectionId1D5C2D63FkWorkflowInverseInput>;
};

/** A filter to be used against many `WorkflowSectionBlockSection` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowSectionToManyWorkflowSectionBlockSectionFilter = {
  /** Every related `WorkflowSectionBlockSection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowSectionBlockSectionFilter>;
  /** No related `WorkflowSectionBlockSection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowSectionBlockSectionFilter>;
  /** Some related `WorkflowSectionBlockSection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowSectionBlockSectionFilter>;
};

/** A filter to be used against many `WorkflowSectionWorkflowVersion` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowSectionToManyWorkflowSectionWorkflowVersionFilter = {
  /** Every related `WorkflowSectionWorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowSectionWorkflowVersionFilter>;
  /** No related `WorkflowSectionWorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowSectionWorkflowVersionFilter>;
  /** Some related `WorkflowSectionWorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowSectionWorkflowVersionFilter>;
};

/** Input for the nested mutation of `workflowSection` in the `WorkflowSectionWorkflowVersionInput` mutation. */
export type WorkflowSectionWorSectionId1D5C2D63FkWorkflowInput = {
  /** The primary key(s) for `workflowSection` for the far side of the relationship. */
  connectById?: InputMaybe<WorkflowSectionWorkflowSectionPkeyConnect>;
  /** A `WorkflowSectionInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowSectionWorSectionId1D5C2D63FkWorkflowWorkflowSectionCreateInput>;
  /** The primary key(s) for `workflowSection` for the far side of the relationship. */
  deleteById?: InputMaybe<WorkflowSectionWorkflowSectionPkeyDelete>;
  /** The primary key(s) and patch data for `workflowSection` for the far side of the relationship. */
  updateById?: InputMaybe<WorkflowSectionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowUsingWorkflowSectionPkeyUpdate>;
};

/** Input for the nested mutation of `workflowSectionWorkflowVersion` in the `WorkflowSectionInput` mutation. */
export type WorkflowSectionWorSectionId1D5C2D63FkWorkflowInverseInput = {
  /** The primary key(s) for `workflowSectionWorkflowVersion` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<WorkflowSectionWorkflowVersionWorkflowSectionWorkflowVersionsPkeyConnect>
  >;
  /** The primary key(s) for `workflowSectionWorkflowVersion` for the far side of the relationship. */
  connectBySectionIdAndWorkflowversionId?: InputMaybe<
    Array<WorkflowSectionWorkflowVersionWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqConnect>
  >;
  /** A `WorkflowSectionWorkflowVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<WorkflowSectionWorSectionId1D5C2D63FkWorkflowWorkflowSectionWorkflowVersionsCreateInput>
  >;
  /** The primary key(s) for `workflowSectionWorkflowVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<WorkflowSectionWorkflowVersionWorkflowSectionWorkflowVersionsPkeyDelete>
  >;
  /** The primary key(s) for `workflowSectionWorkflowVersion` for the far side of the relationship. */
  deleteBySectionIdAndWorkflowversionId?: InputMaybe<
    Array<WorkflowSectionWorkflowVersionWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqDelete>
  >;
  /** Flag indicating whether all other `workflowSectionWorkflowVersion` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `workflowSectionWorkflowVersion` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowUsingWorkflowSectionWorkflowVersionsPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `workflowSectionWorkflowVersion` for the far side of the relationship. */
  updateBySectionIdAndWorkflowversionId?: InputMaybe<
    Array<WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowUsingWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqUpdate>
  >;
};

/** The `workflowSection` to be created by this mutation. */
export type WorkflowSectionWorSectionId1D5C2D63FkWorkflowWorkflowSectionCreateInput =
  {
    description?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    order: Scalars["Int"]["input"];
    title?: InputMaybe<Scalars["String"]["input"]>;
    workflowSectionBlockSectionsUsingId?: InputMaybe<WorkflowSectionBloSectionId817C1C77FkWorkflowInverseInput>;
    workflowSectionWorkflowVersionsUsingId?: InputMaybe<WorkflowSectionWorSectionId1D5C2D63FkWorkflowInverseInput>;
  };

/** The `workflowSectionWorkflowVersion` to be created by this mutation. */
export type WorkflowSectionWorSectionId1D5C2D63FkWorkflowWorkflowSectionWorkflowVersionsCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    workflowSectionToSectionId?: InputMaybe<WorkflowSectionWorSectionId1D5C2D63FkWorkflowInput>;
    workflowVersionToWorkflowversionId?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInput>;
    workflowversionId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `workflowVersion` in the `WorkflowSectionWorkflowVersionInput` mutation. */
export type WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInput = {
  /** The primary key(s) for `workflowVersion` for the far side of the relationship. */
  connectById?: InputMaybe<WorkflowVersionWorkflowVersionPkeyConnect>;
  /** A `WorkflowVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowWorkflowVersionCreateInput>;
  /** The primary key(s) for `workflowVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<WorkflowVersionWorkflowVersionPkeyDelete>;
  /** The primary key(s) and patch data for `workflowVersion` for the far side of the relationship. */
  updateById?: InputMaybe<WorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowUsingWorkflowVersionPkeyUpdate>;
};

/** Input for the nested mutation of `workflowSectionWorkflowVersion` in the `WorkflowVersionInput` mutation. */
export type WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInverseInput =
  {
    /** The primary key(s) for `workflowSectionWorkflowVersion` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<WorkflowSectionWorkflowVersionWorkflowSectionWorkflowVersionsPkeyConnect>
    >;
    /** The primary key(s) for `workflowSectionWorkflowVersion` for the far side of the relationship. */
    connectBySectionIdAndWorkflowversionId?: InputMaybe<
      Array<WorkflowSectionWorkflowVersionWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqConnect>
    >;
    /** A `WorkflowSectionWorkflowVersionInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowWorkflowSectionWorkflowVersionsCreateInput>
    >;
    /** The primary key(s) for `workflowSectionWorkflowVersion` for the far side of the relationship. */
    deleteById?: InputMaybe<
      Array<WorkflowSectionWorkflowVersionWorkflowSectionWorkflowVersionsPkeyDelete>
    >;
    /** The primary key(s) for `workflowSectionWorkflowVersion` for the far side of the relationship. */
    deleteBySectionIdAndWorkflowversionId?: InputMaybe<
      Array<WorkflowSectionWorkflowVersionWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqDelete>
    >;
    /** Flag indicating whether all other `workflowSectionWorkflowVersion` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `workflowSectionWorkflowVersion` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowUsingWorkflowSectionWorkflowVersionsPkeyUpdate>
    >;
    /** The primary key(s) and patch data for `workflowSectionWorkflowVersion` for the far side of the relationship. */
    updateBySectionIdAndWorkflowversionId?: InputMaybe<
      Array<WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowUsingWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqUpdate>
    >;
  };

/** The `workflowSectionWorkflowVersion` to be created by this mutation. */
export type WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowWorkflowSectionWorkflowVersionsCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    sectionId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowSectionToSectionId?: InputMaybe<WorkflowSectionWorSectionId1D5C2D63FkWorkflowInput>;
    workflowVersionToWorkflowversionId?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInput>;
  };

/** The `workflowVersion` to be created by this mutation. */
export type WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowWorkflowVersionCreateInput =
  {
    applicationUserToUserId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInput>;
    autoGenerated: Scalars["Boolean"]["input"];
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    draft: Scalars["Boolean"]["input"];
    id: Scalars["UUID"]["input"];
    name: Scalars["String"]["input"];
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInverseInput>;
    workflowSectionWorkflowVersionsUsingId?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInverseInput>;
    workflowSourceWorkflowVersionsUsingId?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInverseInput>;
    workflowToWorkflowId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInput>;
  };

/** The fields on `workflowSection` to look up the row to connect. */
export type WorkflowSectionWorkflowSectionPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `workflowSection` to look up the row to delete. */
export type WorkflowSectionWorkflowSectionPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

export type WorkflowSectionWorkflowVersion = {
  __typename: "WorkflowSectionWorkflowVersion";
  id: Scalars["Int"]["output"];
  sectionId: Scalars["UUID"]["output"];
  /** Reads a single `WorkflowSection` that is related to this `WorkflowSectionWorkflowVersion`. */
  workflowSectionBySectionId: Maybe<WorkflowSection>;
  /** Reads a single `WorkflowVersion` that is related to this `WorkflowSectionWorkflowVersion`. */
  workflowVersionByWorkflowversionId: Maybe<WorkflowVersion>;
  workflowversionId: Scalars["UUID"]["output"];
};

/**
 * A condition to be used against `WorkflowSectionWorkflowVersion` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type WorkflowSectionWorkflowVersionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `sectionId` field. */
  sectionId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `workflowversionId` field. */
  workflowversionId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `WorkflowSectionWorkflowVersion` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowSectionWorkflowVersionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WorkflowSectionWorkflowVersionFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WorkflowSectionWorkflowVersionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WorkflowSectionWorkflowVersionFilter>>;
  /** Filter by the object’s `sectionId` field. */
  sectionId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `workflowSectionBySectionId` relation. */
  workflowSectionBySectionId?: InputMaybe<WorkflowSectionFilter>;
  /** Filter by the object’s `workflowVersionByWorkflowversionId` relation. */
  workflowVersionByWorkflowversionId?: InputMaybe<WorkflowVersionFilter>;
  /** Filter by the object’s `workflowversionId` field. */
  workflowversionId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `WorkflowSectionWorkflowVersion` */
export type WorkflowSectionWorkflowVersionInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  sectionId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowSectionToSectionId?: InputMaybe<WorkflowSectionWorSectionId1D5C2D63FkWorkflowInput>;
  workflowVersionToWorkflowversionId?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInput>;
  workflowversionId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `workflowSectionWorkflowVersion` to look up the row to update. */
export type WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowUsingWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqUpdate =
  {
    sectionId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowSectionWorkflowVersion` being updated. */
    workflowSectionWorkflowVersionPatch: UpdateWorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowPatch;
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The fields on `workflowSectionWorkflowVersion` to look up the row to update. */
export type WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowUsingWorkflowSectionWorkflowVersionsPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `workflowSectionWorkflowVersion` being updated. */
    workflowSectionWorkflowVersionPatch: UpdateWorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowPatch;
  };

/** The fields on `workflowSectionWorkflowVersion` to look up the row to update. */
export type WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowUsingWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqUpdate =
  {
    sectionId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowSectionWorkflowVersion` being updated. */
    workflowSectionWorkflowVersionPatch: UpdateWorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowPatch;
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The fields on `workflowSectionWorkflowVersion` to look up the row to update. */
export type WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowUsingWorkflowSectionWorkflowVersionsPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `workflowSectionWorkflowVersion` being updated. */
    workflowSectionWorkflowVersionPatch: UpdateWorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowPatch;
  };

/** Represents an update to a `WorkflowSectionWorkflowVersion`. Fields that are set will be updated. */
export type WorkflowSectionWorkflowVersionPatch = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  sectionId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowSectionToSectionId?: InputMaybe<WorkflowSectionWorSectionId1D5C2D63FkWorkflowInput>;
  workflowVersionToWorkflowversionId?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInput>;
  workflowversionId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `workflowSectionWorkflowVersion` to look up the row to connect. */
export type WorkflowSectionWorkflowVersionWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqConnect =
  {
    sectionId: Scalars["UUID"]["input"];
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The fields on `workflowSectionWorkflowVersion` to look up the row to delete. */
export type WorkflowSectionWorkflowVersionWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqDelete =
  {
    sectionId: Scalars["UUID"]["input"];
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The fields on `workflowSectionWorkflowVersion` to look up the row to connect. */
export type WorkflowSectionWorkflowVersionWorkflowSectionWorkflowVersionsPkeyConnect =
  {
    id: Scalars["Int"]["input"];
  };

/** The fields on `workflowSectionWorkflowVersion` to look up the row to delete. */
export type WorkflowSectionWorkflowVersionWorkflowSectionWorkflowVersionsPkeyDelete =
  {
    id: Scalars["Int"]["input"];
  };

/** A connection to a list of `WorkflowSectionWorkflowVersion` values. */
export type WorkflowSectionWorkflowVersionsConnection = {
  __typename: "WorkflowSectionWorkflowVersionsConnection";
  /** A list of edges which contains the `WorkflowSectionWorkflowVersion` and cursor to aid in pagination. */
  edges: Array<WorkflowSectionWorkflowVersionsEdge>;
  /** A list of `WorkflowSectionWorkflowVersion` objects. */
  nodes: Array<WorkflowSectionWorkflowVersion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WorkflowSectionWorkflowVersion` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `WorkflowSectionWorkflowVersion` edge in the connection. */
export type WorkflowSectionWorkflowVersionsEdge = {
  __typename: "WorkflowSectionWorkflowVersionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `WorkflowSectionWorkflowVersion` at the end of the edge. */
  node: WorkflowSectionWorkflowVersion;
};

/** Methods to use when ordering `WorkflowSectionWorkflowVersion`. */
export enum WorkflowSectionWorkflowVersionsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SectionIdAsc = "SECTION_ID_ASC",
  SectionIdDesc = "SECTION_ID_DESC",
  WorkflowversionIdAsc = "WORKFLOWVERSION_ID_ASC",
  WorkflowversionIdDesc = "WORKFLOWVERSION_ID_DESC",
}

/** A connection to a list of `WorkflowSection` values. */
export type WorkflowSectionsConnection = {
  __typename: "WorkflowSectionsConnection";
  /** A list of edges which contains the `WorkflowSection` and cursor to aid in pagination. */
  edges: Array<WorkflowSectionsEdge>;
  /** A list of `WorkflowSection` objects. */
  nodes: Array<WorkflowSection>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WorkflowSection` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `WorkflowSection` edge in the connection. */
export type WorkflowSectionsEdge = {
  __typename: "WorkflowSectionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `WorkflowSection` at the end of the edge. */
  node: WorkflowSection;
};

/** Methods to use when ordering `WorkflowSection`. */
export enum WorkflowSectionsOrderBy {
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  OrderAsc = "ORDER_ASC",
  OrderDesc = "ORDER_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TitleAsc = "TITLE_ASC",
  TitleDesc = "TITLE_DESC",
}

export type WorkflowSource = {
  __typename: "WorkflowSource";
  id: Scalars["UUID"]["output"];
  /** Reads and enables pagination through a set of `WorkflowSourceTag`. */
  workflowSourceTagsBySourceId: WorkflowSourceTagsConnection;
  /** Reads and enables pagination through a set of `WorkflowSourceWorkflowVersion`. */
  workflowSourceWorkflowVersionsBySourceId: WorkflowSourceWorkflowVersionsConnection;
};

export type WorkflowSourceWorkflowSourceTagsBySourceIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSourceTagCondition>;
  filter?: InputMaybe<WorkflowSourceTagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSourceTagsOrderBy>>;
};

export type WorkflowSourceWorkflowSourceWorkflowVersionsBySourceIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowSourceWorkflowVersionCondition>;
  filter?: InputMaybe<WorkflowSourceWorkflowVersionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowSourceWorkflowVersionsOrderBy>>;
};

/**
 * A condition to be used against `WorkflowSource` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type WorkflowSourceCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `WorkflowSource` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowSourceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WorkflowSourceFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WorkflowSourceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WorkflowSourceFilter>>;
  /** Filter by the object’s `workflowSourceTagsBySourceId` relation. */
  workflowSourceTagsBySourceId?: InputMaybe<WorkflowSourceToManyWorkflowSourceTagFilter>;
  /** Some related `workflowSourceTagsBySourceId` exist. */
  workflowSourceTagsBySourceIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `workflowSourceWorkflowVersionsBySourceId` relation. */
  workflowSourceWorkflowVersionsBySourceId?: InputMaybe<WorkflowSourceToManyWorkflowSourceWorkflowVersionFilter>;
  /** Some related `workflowSourceWorkflowVersionsBySourceId` exist. */
  workflowSourceWorkflowVersionsBySourceIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
};

/** An input for mutations affecting `WorkflowSource` */
export type WorkflowSourceInput = {
  id: Scalars["UUID"]["input"];
  workflowSourceTagsUsingId?: InputMaybe<WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInverseInput>;
  workflowSourceWorkflowVersionsUsingId?: InputMaybe<WorkflowSourceWorkSourceId608A289DFkWorkflowInverseInput>;
};

/** The fields on `workflowSource` to look up the row to update. */
export type WorkflowSourceOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdUsingWorkflowSourcePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowSource` being updated. */
    workflowSourcePatch: UpdateWorkflowSourceOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdPatch;
  };

/** The fields on `workflowSource` to look up the row to update. */
export type WorkflowSourceOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowUsingWorkflowSourcePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowSource` being updated. */
    workflowSourcePatch: UpdateWorkflowSourceOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowPatch;
  };

/** Represents an update to a `WorkflowSource`. Fields that are set will be updated. */
export type WorkflowSourcePatch = {
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowSourceTagsUsingId?: InputMaybe<WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInverseInput>;
  workflowSourceWorkflowVersionsUsingId?: InputMaybe<WorkflowSourceWorkSourceId608A289DFkWorkflowInverseInput>;
};

export type WorkflowSourceTag = {
  __typename: "WorkflowSourceTag";
  id: Scalars["Int"]["output"];
  sourceId: Scalars["UUID"]["output"];
  /** Reads a single `Tag` that is related to this `WorkflowSourceTag`. */
  tagByTagId: Maybe<Tag>;
  tagId: Scalars["BigInt"]["output"];
  /** Reads a single `WorkflowSource` that is related to this `WorkflowSourceTag`. */
  workflowSourceBySourceId: Maybe<WorkflowSource>;
};

/**
 * A condition to be used against `WorkflowSourceTag` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type WorkflowSourceTagCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `sourceId` field. */
  sourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tagId` field. */
  tagId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** A filter to be used against `WorkflowSourceTag` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowSourceTagFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WorkflowSourceTagFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WorkflowSourceTagFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WorkflowSourceTagFilter>>;
  /** Filter by the object’s `sourceId` field. */
  sourceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tagByTagId` relation. */
  tagByTagId?: InputMaybe<TagFilter>;
  /** Filter by the object’s `tagId` field. */
  tagId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `workflowSourceBySourceId` relation. */
  workflowSourceBySourceId?: InputMaybe<WorkflowSourceFilter>;
};

/** An input for mutations affecting `WorkflowSourceTag` */
export type WorkflowSourceTagInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  sourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  tagId?: InputMaybe<Scalars["BigInt"]["input"]>;
  tagToTagId?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdInput>;
  workflowSourceToSourceId?: InputMaybe<WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInput>;
};

/** The fields on `workflowSourceTag` to look up the row to update. */
export type WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdUsingWorkflowSourceTagsPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `workflowSourceTag` being updated. */
    workflowSourceTagPatch: UpdateWorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdPatch;
  };

/** The fields on `workflowSourceTag` to look up the row to update. */
export type WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdUsingWorkflowSourceTagsSourceIdTagId1455Ea80UniqUpdate =
  {
    sourceId: Scalars["UUID"]["input"];
    tagId: Scalars["BigInt"]["input"];
    /** An object where the defined keys will be set on the `workflowSourceTag` being updated. */
    workflowSourceTagPatch: UpdateWorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdPatch;
  };

/** The fields on `workflowSourceTag` to look up the row to update. */
export type WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdUsingWorkflowSourceTagsPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `workflowSourceTag` being updated. */
    workflowSourceTagPatch: UpdateWorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdPatch;
  };

/** The fields on `workflowSourceTag` to look up the row to update. */
export type WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdUsingWorkflowSourceTagsSourceIdTagId1455Ea80UniqUpdate =
  {
    sourceId: Scalars["UUID"]["input"];
    tagId: Scalars["BigInt"]["input"];
    /** An object where the defined keys will be set on the `workflowSourceTag` being updated. */
    workflowSourceTagPatch: UpdateWorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdPatch;
  };

/** Represents an update to a `WorkflowSourceTag`. Fields that are set will be updated. */
export type WorkflowSourceTagPatch = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  sourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  tagId?: InputMaybe<Scalars["BigInt"]["input"]>;
  tagToTagId?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdInput>;
  workflowSourceToSourceId?: InputMaybe<WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInput>;
};

/** The fields on `workflowSourceTag` to look up the row to connect. */
export type WorkflowSourceTagWorkflowSourceTagsPkeyConnect = {
  id: Scalars["Int"]["input"];
};

/** The fields on `workflowSourceTag` to look up the row to delete. */
export type WorkflowSourceTagWorkflowSourceTagsPkeyDelete = {
  id: Scalars["Int"]["input"];
};

/** The fields on `workflowSourceTag` to look up the row to connect. */
export type WorkflowSourceTagWorkflowSourceTagsSourceIdTagId1455Ea80UniqConnect =
  {
    sourceId: Scalars["UUID"]["input"];
    tagId: Scalars["BigInt"]["input"];
  };

/** The fields on `workflowSourceTag` to look up the row to delete. */
export type WorkflowSourceTagWorkflowSourceTagsSourceIdTagId1455Ea80UniqDelete =
  {
    sourceId: Scalars["UUID"]["input"];
    tagId: Scalars["BigInt"]["input"];
  };

/** A connection to a list of `WorkflowSourceTag` values. */
export type WorkflowSourceTagsConnection = {
  __typename: "WorkflowSourceTagsConnection";
  /** A list of edges which contains the `WorkflowSourceTag` and cursor to aid in pagination. */
  edges: Array<WorkflowSourceTagsEdge>;
  /** A list of `WorkflowSourceTag` objects. */
  nodes: Array<WorkflowSourceTag>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WorkflowSourceTag` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `WorkflowSourceTag` edge in the connection. */
export type WorkflowSourceTagsEdge = {
  __typename: "WorkflowSourceTagsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `WorkflowSourceTag` at the end of the edge. */
  node: WorkflowSourceTag;
};

/** Methods to use when ordering `WorkflowSourceTag`. */
export enum WorkflowSourceTagsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SourceIdAsc = "SOURCE_ID_ASC",
  SourceIdDesc = "SOURCE_ID_DESC",
  TagIdAsc = "TAG_ID_ASC",
  TagIdDesc = "TAG_ID_DESC",
}

/** Input for the nested mutation of `workflowSource` in the `WorkflowSourceTagInput` mutation. */
export type WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInput = {
  /** The primary key(s) for `workflowSource` for the far side of the relationship. */
  connectById?: InputMaybe<WorkflowSourceWorkflowSourcePkeyConnect>;
  /** A `WorkflowSourceInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdWorkflowSourceCreateInput>;
  /** The primary key(s) for `workflowSource` for the far side of the relationship. */
  deleteById?: InputMaybe<WorkflowSourceWorkflowSourcePkeyDelete>;
  /** The primary key(s) and patch data for `workflowSource` for the far side of the relationship. */
  updateById?: InputMaybe<WorkflowSourceOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdUsingWorkflowSourcePkeyUpdate>;
};

/** Input for the nested mutation of `workflowSourceTag` in the `WorkflowSourceInput` mutation. */
export type WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInverseInput = {
  /** The primary key(s) for `workflowSourceTag` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<WorkflowSourceTagWorkflowSourceTagsPkeyConnect>
  >;
  /** The primary key(s) for `workflowSourceTag` for the far side of the relationship. */
  connectBySourceIdAndTagId?: InputMaybe<
    Array<WorkflowSourceTagWorkflowSourceTagsSourceIdTagId1455Ea80UniqConnect>
  >;
  /** A `WorkflowSourceTagInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdWorkflowSourceTagsCreateInput>
  >;
  /** The primary key(s) for `workflowSourceTag` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<WorkflowSourceTagWorkflowSourceTagsPkeyDelete>>;
  /** The primary key(s) for `workflowSourceTag` for the far side of the relationship. */
  deleteBySourceIdAndTagId?: InputMaybe<
    Array<WorkflowSourceTagWorkflowSourceTagsSourceIdTagId1455Ea80UniqDelete>
  >;
  /** Flag indicating whether all other `workflowSourceTag` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `workflowSourceTag` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdUsingWorkflowSourceTagsPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `workflowSourceTag` for the far side of the relationship. */
  updateBySourceIdAndTagId?: InputMaybe<
    Array<WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdUsingWorkflowSourceTagsSourceIdTagId1455Ea80UniqUpdate>
  >;
};

/** The `workflowSource` to be created by this mutation. */
export type WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdWorkflowSourceCreateInput =
  {
    id: Scalars["UUID"]["input"];
    workflowSourceTagsUsingId?: InputMaybe<WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInverseInput>;
    workflowSourceWorkflowVersionsUsingId?: InputMaybe<WorkflowSourceWorkSourceId608A289DFkWorkflowInverseInput>;
  };

/** The `workflowSourceTag` to be created by this mutation. */
export type WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdWorkflowSourceTagsCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    tagId?: InputMaybe<Scalars["BigInt"]["input"]>;
    tagToTagId?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdInput>;
    workflowSourceToSourceId?: InputMaybe<WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInput>;
  };

/** Input for the nested mutation of `tag` in the `WorkflowSourceTagInput` mutation. */
export type WorkflowSourceTagsTagId690E6935FkTagIdInput = {
  /** The primary key(s) for `tag` for the far side of the relationship. */
  connectById?: InputMaybe<TagTagPkeyConnect>;
  /** A `TagInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdTagCreateInput>;
  /** The primary key(s) for `tag` for the far side of the relationship. */
  deleteById?: InputMaybe<TagTagPkeyDelete>;
  /** The primary key(s) and patch data for `tag` for the far side of the relationship. */
  updateById?: InputMaybe<TagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdUsingTagPkeyUpdate>;
};

/** Input for the nested mutation of `workflowSourceTag` in the `TagInput` mutation. */
export type WorkflowSourceTagsTagId690E6935FkTagIdInverseInput = {
  /** The primary key(s) for `workflowSourceTag` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<WorkflowSourceTagWorkflowSourceTagsPkeyConnect>
  >;
  /** The primary key(s) for `workflowSourceTag` for the far side of the relationship. */
  connectBySourceIdAndTagId?: InputMaybe<
    Array<WorkflowSourceTagWorkflowSourceTagsSourceIdTagId1455Ea80UniqConnect>
  >;
  /** A `WorkflowSourceTagInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<WorkflowSourceTagsTagId690E6935FkTagIdWorkflowSourceTagsCreateInput>
  >;
  /** The primary key(s) for `workflowSourceTag` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<WorkflowSourceTagWorkflowSourceTagsPkeyDelete>>;
  /** The primary key(s) for `workflowSourceTag` for the far side of the relationship. */
  deleteBySourceIdAndTagId?: InputMaybe<
    Array<WorkflowSourceTagWorkflowSourceTagsSourceIdTagId1455Ea80UniqDelete>
  >;
  /** Flag indicating whether all other `workflowSourceTag` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `workflowSourceTag` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdUsingWorkflowSourceTagsPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `workflowSourceTag` for the far side of the relationship. */
  updateBySourceIdAndTagId?: InputMaybe<
    Array<WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdUsingWorkflowSourceTagsSourceIdTagId1455Ea80UniqUpdate>
  >;
};

/** The `tag` to be created by this mutation. */
export type WorkflowSourceTagsTagId690E6935FkTagIdTagCreateInput = {
  applicationUserToUserId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  label: Scalars["String"]["input"];
  parameters?: InputMaybe<Scalars["JSON"]["input"]>;
  parentId?: InputMaybe<Scalars["BigInt"]["input"]>;
  tagToParentId?: InputMaybe<TagParentIdD2A3Ee5EFkTagIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TagTenantId8072B61FFkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdInverseInput>;
  workflowSourceTagsUsingId?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdInverseInput>;
};

/** The `workflowSourceTag` to be created by this mutation. */
export type WorkflowSourceTagsTagId690E6935FkTagIdWorkflowSourceTagsCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    sourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    tagToTagId?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdInput>;
    workflowSourceToSourceId?: InputMaybe<WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInput>;
  };

/** A filter to be used against many `WorkflowSourceTag` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowSourceToManyWorkflowSourceTagFilter = {
  /** Every related `WorkflowSourceTag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowSourceTagFilter>;
  /** No related `WorkflowSourceTag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowSourceTagFilter>;
  /** Some related `WorkflowSourceTag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowSourceTagFilter>;
};

/** A filter to be used against many `WorkflowSourceWorkflowVersion` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowSourceToManyWorkflowSourceWorkflowVersionFilter = {
  /** Every related `WorkflowSourceWorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowSourceWorkflowVersionFilter>;
  /** No related `WorkflowSourceWorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowSourceWorkflowVersionFilter>;
  /** Some related `WorkflowSourceWorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowSourceWorkflowVersionFilter>;
};

/** Input for the nested mutation of `workflowSource` in the `WorkflowSourceWorkflowVersionInput` mutation. */
export type WorkflowSourceWorkSourceId608A289DFkWorkflowInput = {
  /** The primary key(s) for `workflowSource` for the far side of the relationship. */
  connectById?: InputMaybe<WorkflowSourceWorkflowSourcePkeyConnect>;
  /** A `WorkflowSourceInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowSourceWorkSourceId608A289DFkWorkflowWorkflowSourceCreateInput>;
  /** The primary key(s) for `workflowSource` for the far side of the relationship. */
  deleteById?: InputMaybe<WorkflowSourceWorkflowSourcePkeyDelete>;
  /** The primary key(s) and patch data for `workflowSource` for the far side of the relationship. */
  updateById?: InputMaybe<WorkflowSourceOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowUsingWorkflowSourcePkeyUpdate>;
};

/** Input for the nested mutation of `workflowSourceWorkflowVersion` in the `WorkflowSourceInput` mutation. */
export type WorkflowSourceWorkSourceId608A289DFkWorkflowInverseInput = {
  /** The primary key(s) for `workflowSourceWorkflowVersion` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<WorkflowSourceWorkflowVersionWorkflowSourceWorkflowVersionsPkeyConnect>
  >;
  /** The primary key(s) for `workflowSourceWorkflowVersion` for the far side of the relationship. */
  connectBySourceIdAndWorkflowversionId?: InputMaybe<
    Array<WorkflowSourceWorkflowVersionWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqConnect>
  >;
  /** A `WorkflowSourceWorkflowVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<WorkflowSourceWorkSourceId608A289DFkWorkflowWorkflowSourceWorkflowVersionsCreateInput>
  >;
  /** The primary key(s) for `workflowSourceWorkflowVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<
    Array<WorkflowSourceWorkflowVersionWorkflowSourceWorkflowVersionsPkeyDelete>
  >;
  /** The primary key(s) for `workflowSourceWorkflowVersion` for the far side of the relationship. */
  deleteBySourceIdAndWorkflowversionId?: InputMaybe<
    Array<WorkflowSourceWorkflowVersionWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqDelete>
  >;
  /** Flag indicating whether all other `workflowSourceWorkflowVersion` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `workflowSourceWorkflowVersion` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowUsingWorkflowSourceWorkflowVersionsPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `workflowSourceWorkflowVersion` for the far side of the relationship. */
  updateBySourceIdAndWorkflowversionId?: InputMaybe<
    Array<WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowUsingWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqUpdate>
  >;
};

/** The `workflowSource` to be created by this mutation. */
export type WorkflowSourceWorkSourceId608A289DFkWorkflowWorkflowSourceCreateInput =
  {
    id: Scalars["UUID"]["input"];
    workflowSourceTagsUsingId?: InputMaybe<WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInverseInput>;
    workflowSourceWorkflowVersionsUsingId?: InputMaybe<WorkflowSourceWorkSourceId608A289DFkWorkflowInverseInput>;
  };

/** The `workflowSourceWorkflowVersion` to be created by this mutation. */
export type WorkflowSourceWorkSourceId608A289DFkWorkflowWorkflowSourceWorkflowVersionsCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    workflowSourceToSourceId?: InputMaybe<WorkflowSourceWorkSourceId608A289DFkWorkflowInput>;
    workflowVersionToWorkflowversionId?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInput>;
    workflowversionId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `workflowVersion` in the `WorkflowSourceWorkflowVersionInput` mutation. */
export type WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInput = {
  /** The primary key(s) for `workflowVersion` for the far side of the relationship. */
  connectById?: InputMaybe<WorkflowVersionWorkflowVersionPkeyConnect>;
  /** A `WorkflowVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowWorkflowVersionCreateInput>;
  /** The primary key(s) for `workflowVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<WorkflowVersionWorkflowVersionPkeyDelete>;
  /** The primary key(s) and patch data for `workflowVersion` for the far side of the relationship. */
  updateById?: InputMaybe<WorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowUsingWorkflowVersionPkeyUpdate>;
};

/** Input for the nested mutation of `workflowSourceWorkflowVersion` in the `WorkflowVersionInput` mutation. */
export type WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInverseInput =
  {
    /** The primary key(s) for `workflowSourceWorkflowVersion` for the far side of the relationship. */
    connectById?: InputMaybe<
      Array<WorkflowSourceWorkflowVersionWorkflowSourceWorkflowVersionsPkeyConnect>
    >;
    /** The primary key(s) for `workflowSourceWorkflowVersion` for the far side of the relationship. */
    connectBySourceIdAndWorkflowversionId?: InputMaybe<
      Array<WorkflowSourceWorkflowVersionWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqConnect>
    >;
    /** A `WorkflowSourceWorkflowVersionInput` object that will be created and connected to this object. */
    create?: InputMaybe<
      Array<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowWorkflowSourceWorkflowVersionsCreateInput>
    >;
    /** The primary key(s) for `workflowSourceWorkflowVersion` for the far side of the relationship. */
    deleteById?: InputMaybe<
      Array<WorkflowSourceWorkflowVersionWorkflowSourceWorkflowVersionsPkeyDelete>
    >;
    /** The primary key(s) for `workflowSourceWorkflowVersion` for the far side of the relationship. */
    deleteBySourceIdAndWorkflowversionId?: InputMaybe<
      Array<WorkflowSourceWorkflowVersionWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqDelete>
    >;
    /** Flag indicating whether all other `workflowSourceWorkflowVersion` records that match this relationship should be removed. */
    deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The primary key(s) and patch data for `workflowSourceWorkflowVersion` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowUsingWorkflowSourceWorkflowVersionsPkeyUpdate>
    >;
    /** The primary key(s) and patch data for `workflowSourceWorkflowVersion` for the far side of the relationship. */
    updateBySourceIdAndWorkflowversionId?: InputMaybe<
      Array<WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowUsingWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqUpdate>
    >;
  };

/** The `workflowSourceWorkflowVersion` to be created by this mutation. */
export type WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowWorkflowSourceWorkflowVersionsCreateInput =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    sourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowSourceToSourceId?: InputMaybe<WorkflowSourceWorkSourceId608A289DFkWorkflowInput>;
    workflowVersionToWorkflowversionId?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInput>;
  };

/** The `workflowVersion` to be created by this mutation. */
export type WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowWorkflowVersionCreateInput =
  {
    applicationUserToUserId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInput>;
    autoGenerated: Scalars["Boolean"]["input"];
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    draft: Scalars["Boolean"]["input"];
    id: Scalars["UUID"]["input"];
    name: Scalars["String"]["input"];
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInverseInput>;
    workflowSectionWorkflowVersionsUsingId?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInverseInput>;
    workflowSourceWorkflowVersionsUsingId?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInverseInput>;
    workflowToWorkflowId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInput>;
  };

/** The fields on `workflowSource` to look up the row to connect. */
export type WorkflowSourceWorkflowSourcePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `workflowSource` to look up the row to delete. */
export type WorkflowSourceWorkflowSourcePkeyDelete = {
  id: Scalars["UUID"]["input"];
};

export type WorkflowSourceWorkflowVersion = {
  __typename: "WorkflowSourceWorkflowVersion";
  id: Scalars["Int"]["output"];
  sourceId: Scalars["UUID"]["output"];
  /** Reads a single `WorkflowSource` that is related to this `WorkflowSourceWorkflowVersion`. */
  workflowSourceBySourceId: Maybe<WorkflowSource>;
  /** Reads a single `WorkflowVersion` that is related to this `WorkflowSourceWorkflowVersion`. */
  workflowVersionByWorkflowversionId: Maybe<WorkflowVersion>;
  workflowversionId: Scalars["UUID"]["output"];
};

/**
 * A condition to be used against `WorkflowSourceWorkflowVersion` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type WorkflowSourceWorkflowVersionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `sourceId` field. */
  sourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `workflowversionId` field. */
  workflowversionId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `WorkflowSourceWorkflowVersion` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowSourceWorkflowVersionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WorkflowSourceWorkflowVersionFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WorkflowSourceWorkflowVersionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WorkflowSourceWorkflowVersionFilter>>;
  /** Filter by the object’s `sourceId` field. */
  sourceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `workflowSourceBySourceId` relation. */
  workflowSourceBySourceId?: InputMaybe<WorkflowSourceFilter>;
  /** Filter by the object’s `workflowVersionByWorkflowversionId` relation. */
  workflowVersionByWorkflowversionId?: InputMaybe<WorkflowVersionFilter>;
  /** Filter by the object’s `workflowversionId` field. */
  workflowversionId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `WorkflowSourceWorkflowVersion` */
export type WorkflowSourceWorkflowVersionInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  sourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowSourceToSourceId?: InputMaybe<WorkflowSourceWorkSourceId608A289DFkWorkflowInput>;
  workflowVersionToWorkflowversionId?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInput>;
  workflowversionId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `workflowSourceWorkflowVersion` to look up the row to update. */
export type WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowUsingWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqUpdate =
  {
    sourceId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowSourceWorkflowVersion` being updated. */
    workflowSourceWorkflowVersionPatch: UpdateWorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowPatch;
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The fields on `workflowSourceWorkflowVersion` to look up the row to update. */
export type WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowUsingWorkflowSourceWorkflowVersionsPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `workflowSourceWorkflowVersion` being updated. */
    workflowSourceWorkflowVersionPatch: UpdateWorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowPatch;
  };

/** The fields on `workflowSourceWorkflowVersion` to look up the row to update. */
export type WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowUsingWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqUpdate =
  {
    sourceId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowSourceWorkflowVersion` being updated. */
    workflowSourceWorkflowVersionPatch: UpdateWorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowPatch;
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The fields on `workflowSourceWorkflowVersion` to look up the row to update. */
export type WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowUsingWorkflowSourceWorkflowVersionsPkeyUpdate =
  {
    id: Scalars["Int"]["input"];
    /** An object where the defined keys will be set on the `workflowSourceWorkflowVersion` being updated. */
    workflowSourceWorkflowVersionPatch: UpdateWorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowPatch;
  };

/** Represents an update to a `WorkflowSourceWorkflowVersion`. Fields that are set will be updated. */
export type WorkflowSourceWorkflowVersionPatch = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  sourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowSourceToSourceId?: InputMaybe<WorkflowSourceWorkSourceId608A289DFkWorkflowInput>;
  workflowVersionToWorkflowversionId?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInput>;
  workflowversionId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `workflowSourceWorkflowVersion` to look up the row to connect. */
export type WorkflowSourceWorkflowVersionWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqConnect =
  {
    sourceId: Scalars["UUID"]["input"];
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The fields on `workflowSourceWorkflowVersion` to look up the row to delete. */
export type WorkflowSourceWorkflowVersionWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqDelete =
  {
    sourceId: Scalars["UUID"]["input"];
    workflowversionId: Scalars["UUID"]["input"];
  };

/** The fields on `workflowSourceWorkflowVersion` to look up the row to connect. */
export type WorkflowSourceWorkflowVersionWorkflowSourceWorkflowVersionsPkeyConnect =
  {
    id: Scalars["Int"]["input"];
  };

/** The fields on `workflowSourceWorkflowVersion` to look up the row to delete. */
export type WorkflowSourceWorkflowVersionWorkflowSourceWorkflowVersionsPkeyDelete =
  {
    id: Scalars["Int"]["input"];
  };

/** A connection to a list of `WorkflowSourceWorkflowVersion` values. */
export type WorkflowSourceWorkflowVersionsConnection = {
  __typename: "WorkflowSourceWorkflowVersionsConnection";
  /** A list of edges which contains the `WorkflowSourceWorkflowVersion` and cursor to aid in pagination. */
  edges: Array<WorkflowSourceWorkflowVersionsEdge>;
  /** A list of `WorkflowSourceWorkflowVersion` objects. */
  nodes: Array<WorkflowSourceWorkflowVersion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WorkflowSourceWorkflowVersion` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `WorkflowSourceWorkflowVersion` edge in the connection. */
export type WorkflowSourceWorkflowVersionsEdge = {
  __typename: "WorkflowSourceWorkflowVersionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `WorkflowSourceWorkflowVersion` at the end of the edge. */
  node: WorkflowSourceWorkflowVersion;
};

/** Methods to use when ordering `WorkflowSourceWorkflowVersion`. */
export enum WorkflowSourceWorkflowVersionsOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SourceIdAsc = "SOURCE_ID_ASC",
  SourceIdDesc = "SOURCE_ID_DESC",
  WorkflowversionIdAsc = "WORKFLOWVERSION_ID_ASC",
  WorkflowversionIdDesc = "WORKFLOWVERSION_ID_DESC",
}

/** A connection to a list of `WorkflowSource` values. */
export type WorkflowSourcesConnection = {
  __typename: "WorkflowSourcesConnection";
  /** A list of edges which contains the `WorkflowSource` and cursor to aid in pagination. */
  edges: Array<WorkflowSourcesEdge>;
  /** A list of `WorkflowSource` objects. */
  nodes: Array<WorkflowSource>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WorkflowSource` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `WorkflowSource` edge in the connection. */
export type WorkflowSourcesEdge = {
  __typename: "WorkflowSourcesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `WorkflowSource` at the end of the edge. */
  node: WorkflowSource;
};

/** Methods to use when ordering `WorkflowSource`. */
export enum WorkflowSourcesOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

/** Input for the nested mutation of `tenant` in the `WorkflowInput` mutation. */
export type WorkflowTenantId9469Ff84FkTenantIdInput = {
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectById?: InputMaybe<TenantTenantPkeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByKey?: InputMaybe<TenantTenantKeyKeyConnect>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  connectByName?: InputMaybe<TenantTenantNameKeyConnect>;
  /** A `TenantInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdTenantCreateInput>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteById?: InputMaybe<TenantTenantPkeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByKey?: InputMaybe<TenantTenantKeyKeyDelete>;
  /** The primary key(s) for `tenant` for the far side of the relationship. */
  deleteByName?: InputMaybe<TenantTenantNameKeyDelete>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateById?: InputMaybe<TenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingTenantPkeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByKey?: InputMaybe<TenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingTenantKeyKeyUpdate>;
  /** The primary key(s) and patch data for `tenant` for the far side of the relationship. */
  updateByName?: InputMaybe<TenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingTenantNameKeyUpdate>;
};

/** Input for the nested mutation of `workflow` in the `TenantInput` mutation. */
export type WorkflowTenantId9469Ff84FkTenantIdInverseInput = {
  /** The primary key(s) for `workflow` for the far side of the relationship. */
  connectById?: InputMaybe<Array<WorkflowWorkflowPkeyConnect>>;
  /** A `WorkflowInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<WorkflowTenantId9469Ff84FkTenantIdWorkflowCreateInput>
  >;
  /** The primary key(s) for `workflow` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<WorkflowWorkflowPkeyDelete>>;
  /** Flag indicating whether all other `workflow` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `workflow` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<WorkflowOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingWorkflowPkeyUpdate>
  >;
};

/** The `tenant` to be created by this mutation. */
export type WorkflowTenantId9469Ff84FkTenantIdTenantCreateInput = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name: Scalars["String"]["input"];
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status: Scalars["Int"]["input"];
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType: Scalars["Int"]["input"];
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** The `workflow` to be created by this mutation. */
export type WorkflowTenantId9469Ff84FkTenantIdWorkflowCreateInput = {
  applicationUserToUserId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInput>;
  containerId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  name: Scalars["String"]["input"];
  tenantToTenantId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowVersionsUsingId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInverseInput>;
};

/** A filter to be used against many `WorkflowVersion` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowToManyWorkflowVersionFilter = {
  /** Every related `WorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowVersionFilter>;
  /** No related `WorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowVersionFilter>;
  /** Some related `WorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowVersionFilter>;
};

/** The `applicationUser` to be created by this mutation. */
export type WorkflowUserId121C1967FkApplicationUserIdApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `WorkflowInput` mutation. */
export type WorkflowUserId121C1967FkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `workflow` in the `ApplicationUserInput` mutation. */
export type WorkflowUserId121C1967FkApplicationUserIdInverseInput = {
  /** The primary key(s) for `workflow` for the far side of the relationship. */
  connectById?: InputMaybe<Array<WorkflowWorkflowPkeyConnect>>;
  /** A `WorkflowInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<WorkflowUserId121C1967FkApplicationUserIdWorkflowCreateInput>
  >;
  /** The primary key(s) for `workflow` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<WorkflowWorkflowPkeyDelete>>;
  /** Flag indicating whether all other `workflow` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `workflow` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<WorkflowOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingWorkflowPkeyUpdate>
  >;
};

/** The `workflow` to be created by this mutation. */
export type WorkflowUserId121C1967FkApplicationUserIdWorkflowCreateInput = {
  applicationUserToUserId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInput>;
  containerId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  name: Scalars["String"]["input"];
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInput>;
  workflowVersionsUsingId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInverseInput>;
};

export type WorkflowVersion = {
  __typename: "WorkflowVersion";
  /** Reads a single `ApplicationUser` that is related to this `WorkflowVersion`. */
  applicationUserByUserId: Maybe<ApplicationUser>;
  autoGenerated: Scalars["Boolean"]["output"];
  dateCreated: Scalars["Datetime"]["output"];
  description: Maybe<Scalars["String"]["output"]>;
  draft: Scalars["Boolean"]["output"];
  id: Scalars["UUID"]["output"];
  name: Scalars["String"]["output"];
  userId: Maybe<Scalars["UUID"]["output"]>;
  /** Reads a single `Workflow` that is related to this `WorkflowVersion`. */
  workflowByWorkflowId: Maybe<Workflow>;
  workflowId: Scalars["UUID"]["output"];
  /** Reads and enables pagination through a set of `WorkflowResultWorkflowVersion`. */
  workflowResultWorkflowVersionsByWorkflowversionId: WorkflowResultWorkflowVersionsConnection;
  /** Reads and enables pagination through a set of `WorkflowSectionWorkflowVersion`. */
  workflowSectionWorkflowVersionsByWorkflowversionId: WorkflowSectionWorkflowVersionsConnection;
  /** Reads and enables pagination through a set of `WorkflowSourceWorkflowVersion`. */
  workflowSourceWorkflowVersionsByWorkflowversionId: WorkflowSourceWorkflowVersionsConnection;
};

export type WorkflowVersionWorkflowResultWorkflowVersionsByWorkflowversionIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<WorkflowResultWorkflowVersionCondition>;
    filter?: InputMaybe<WorkflowResultWorkflowVersionFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<WorkflowResultWorkflowVersionsOrderBy>>;
  };

export type WorkflowVersionWorkflowSectionWorkflowVersionsByWorkflowversionIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<WorkflowSectionWorkflowVersionCondition>;
    filter?: InputMaybe<WorkflowSectionWorkflowVersionFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<WorkflowSectionWorkflowVersionsOrderBy>>;
  };

export type WorkflowVersionWorkflowSourceWorkflowVersionsByWorkflowversionIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<WorkflowSourceWorkflowVersionCondition>;
    filter?: InputMaybe<WorkflowSourceWorkflowVersionFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<WorkflowSourceWorkflowVersionsOrderBy>>;
  };

/**
 * A condition to be used against `WorkflowVersion` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type WorkflowVersionCondition = {
  /** Checks for equality with the object’s `autoGenerated` field. */
  autoGenerated?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `draft` field. */
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `workflowId` field. */
  workflowId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A filter to be used against `WorkflowVersion` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowVersionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WorkflowVersionFilter>>;
  /** Filter by the object’s `applicationUserByUserId` relation. */
  applicationUserByUserId?: InputMaybe<ApplicationUserFilter>;
  /** A related `applicationUserByUserId` exists. */
  applicationUserByUserIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `autoGenerated` field. */
  autoGenerated?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `draft` field. */
  draft?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WorkflowVersionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WorkflowVersionFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `workflowByWorkflowId` relation. */
  workflowByWorkflowId?: InputMaybe<WorkflowFilter>;
  /** Filter by the object’s `workflowId` field. */
  workflowId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `workflowResultWorkflowVersionsByWorkflowversionId` relation. */
  workflowResultWorkflowVersionsByWorkflowversionId?: InputMaybe<WorkflowVersionToManyWorkflowResultWorkflowVersionFilter>;
  /** Some related `workflowResultWorkflowVersionsByWorkflowversionId` exist. */
  workflowResultWorkflowVersionsByWorkflowversionIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `workflowSectionWorkflowVersionsByWorkflowversionId` relation. */
  workflowSectionWorkflowVersionsByWorkflowversionId?: InputMaybe<WorkflowVersionToManyWorkflowSectionWorkflowVersionFilter>;
  /** Some related `workflowSectionWorkflowVersionsByWorkflowversionId` exist. */
  workflowSectionWorkflowVersionsByWorkflowversionIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `workflowSourceWorkflowVersionsByWorkflowversionId` relation. */
  workflowSourceWorkflowVersionsByWorkflowversionId?: InputMaybe<WorkflowVersionToManyWorkflowSourceWorkflowVersionFilter>;
  /** Some related `workflowSourceWorkflowVersionsByWorkflowversionId` exist. */
  workflowSourceWorkflowVersionsByWorkflowversionIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
};

/** An input for mutations affecting `WorkflowVersion` */
export type WorkflowVersionInput = {
  applicationUserToUserId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInput>;
  autoGenerated: Scalars["Boolean"]["input"];
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  draft: Scalars["Boolean"]["input"];
  id: Scalars["UUID"]["input"];
  name: Scalars["String"]["input"];
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInverseInput>;
  workflowSectionWorkflowVersionsUsingId?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInverseInput>;
  workflowSourceWorkflowVersionsUsingId?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInverseInput>;
  workflowToWorkflowId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInput>;
};

/** The fields on `workflowVersion` to look up the row to update. */
export type WorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowUsingWorkflowVersionPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowVersion` being updated. */
    workflowVersionPatch: UpdateWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowPatch;
  };

/** The fields on `workflowVersion` to look up the row to update. */
export type WorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowUsingWorkflowVersionPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowVersion` being updated. */
    workflowVersionPatch: UpdateWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowPatch;
  };

/** The fields on `workflowVersion` to look up the row to update. */
export type WorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowUsingWorkflowVersionPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowVersion` being updated. */
    workflowVersionPatch: UpdateWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowPatch;
  };

/** The fields on `workflowVersion` to look up the row to update. */
export type WorkflowVersionOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingWorkflowVersionPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowVersion` being updated. */
    workflowVersionPatch: UpdateWorkflowVersionOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdPatch;
  };

/** The fields on `workflowVersion` to look up the row to update. */
export type WorkflowVersionOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdUsingWorkflowVersionPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowVersion` being updated. */
    workflowVersionPatch: UpdateWorkflowVersionOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdPatch;
  };

/** Represents an update to a `WorkflowVersion`. Fields that are set will be updated. */
export type WorkflowVersionPatch = {
  applicationUserToUserId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInput>;
  autoGenerated?: InputMaybe<Scalars["Boolean"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInverseInput>;
  workflowSectionWorkflowVersionsUsingId?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInverseInput>;
  workflowSourceWorkflowVersionsUsingId?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInverseInput>;
  workflowToWorkflowId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInput>;
};

/** A filter to be used against many `WorkflowResultWorkflowVersion` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowVersionToManyWorkflowResultWorkflowVersionFilter = {
  /** Every related `WorkflowResultWorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowResultWorkflowVersionFilter>;
  /** No related `WorkflowResultWorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowResultWorkflowVersionFilter>;
  /** Some related `WorkflowResultWorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowResultWorkflowVersionFilter>;
};

/** A filter to be used against many `WorkflowSectionWorkflowVersion` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowVersionToManyWorkflowSectionWorkflowVersionFilter = {
  /** Every related `WorkflowSectionWorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowSectionWorkflowVersionFilter>;
  /** No related `WorkflowSectionWorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowSectionWorkflowVersionFilter>;
  /** Some related `WorkflowSectionWorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowSectionWorkflowVersionFilter>;
};

/** A filter to be used against many `WorkflowSourceWorkflowVersion` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowVersionToManyWorkflowSourceWorkflowVersionFilter = {
  /** Every related `WorkflowSourceWorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowSourceWorkflowVersionFilter>;
  /** No related `WorkflowSourceWorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowSourceWorkflowVersionFilter>;
  /** Some related `WorkflowSourceWorkflowVersion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowSourceWorkflowVersionFilter>;
};

/** The `applicationUser` to be created by this mutation. */
export type WorkflowVersionUserId00C0F62FFkApplicationUserIdApplicationUserCreateInput =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email: Scalars["String"]["input"];
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName: Scalars["String"]["input"];
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    internal: Scalars["Boolean"]["input"];
    isActive: Scalars["Boolean"]["input"];
    isStaff: Scalars["Boolean"]["input"];
    isSuperuser: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username: Scalars["String"]["input"];
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** Input for the nested mutation of `applicationUser` in the `WorkflowVersionInput` mutation. */
export type WorkflowVersionUserId00C0F62FFkApplicationUserIdInput = {
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectById?: InputMaybe<ApplicationUserApplicationUserPkeyConnect>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  connectByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyConnect>;
  /** A `ApplicationUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdApplicationUserCreateInput>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<ApplicationUserApplicationUserEmailKeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteById?: InputMaybe<ApplicationUserApplicationUserPkeyDelete>;
  /** The primary key(s) for `applicationUser` for the far side of the relationship. */
  deleteByUsername?: InputMaybe<ApplicationUserApplicationUserUsernameKeyDelete>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<ApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingApplicationUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateById?: InputMaybe<ApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingApplicationUserPkeyUpdate>;
  /** The primary key(s) and patch data for `applicationUser` for the far side of the relationship. */
  updateByUsername?: InputMaybe<ApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate>;
};

/** Input for the nested mutation of `workflowVersion` in the `ApplicationUserInput` mutation. */
export type WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput = {
  /** The primary key(s) for `workflowVersion` for the far side of the relationship. */
  connectById?: InputMaybe<Array<WorkflowVersionWorkflowVersionPkeyConnect>>;
  /** A `WorkflowVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<WorkflowVersionUserId00C0F62FFkApplicationUserIdWorkflowVersionCreateInput>
  >;
  /** The primary key(s) for `workflowVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<WorkflowVersionWorkflowVersionPkeyDelete>>;
  /** Flag indicating whether all other `workflowVersion` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `workflowVersion` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<WorkflowVersionOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingWorkflowVersionPkeyUpdate>
  >;
};

/** The `workflowVersion` to be created by this mutation. */
export type WorkflowVersionUserId00C0F62FFkApplicationUserIdWorkflowVersionCreateInput =
  {
    applicationUserToUserId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInput>;
    autoGenerated: Scalars["Boolean"]["input"];
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    draft: Scalars["Boolean"]["input"];
    id: Scalars["UUID"]["input"];
    name: Scalars["String"]["input"];
    workflowId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInverseInput>;
    workflowSectionWorkflowVersionsUsingId?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInverseInput>;
    workflowSourceWorkflowVersionsUsingId?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInverseInput>;
    workflowToWorkflowId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInput>;
  };

/** Input for the nested mutation of `workflow` in the `WorkflowVersionInput` mutation. */
export type WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInput = {
  /** The primary key(s) for `workflow` for the far side of the relationship. */
  connectById?: InputMaybe<WorkflowWorkflowPkeyConnect>;
  /** A `WorkflowInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdWorkflowCreateInput>;
  /** The primary key(s) for `workflow` for the far side of the relationship. */
  deleteById?: InputMaybe<WorkflowWorkflowPkeyDelete>;
  /** The primary key(s) and patch data for `workflow` for the far side of the relationship. */
  updateById?: InputMaybe<WorkflowOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdUsingWorkflowPkeyUpdate>;
};

/** Input for the nested mutation of `workflowVersion` in the `WorkflowInput` mutation. */
export type WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInverseInput = {
  /** The primary key(s) for `workflowVersion` for the far side of the relationship. */
  connectById?: InputMaybe<Array<WorkflowVersionWorkflowVersionPkeyConnect>>;
  /** A `WorkflowVersionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdWorkflowVersionCreateInput>
  >;
  /** The primary key(s) for `workflowVersion` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<WorkflowVersionWorkflowVersionPkeyDelete>>;
  /** Flag indicating whether all other `workflowVersion` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `workflowVersion` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<WorkflowVersionOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdUsingWorkflowVersionPkeyUpdate>
  >;
};

/** The `workflow` to be created by this mutation. */
export type WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdWorkflowCreateInput = {
  applicationUserToUserId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInput>;
  containerId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  name: Scalars["String"]["input"];
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowVersionsUsingId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInverseInput>;
};

/** The `workflowVersion` to be created by this mutation. */
export type WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdWorkflowVersionCreateInput =
  {
    applicationUserToUserId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInput>;
    autoGenerated: Scalars["Boolean"]["input"];
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    draft: Scalars["Boolean"]["input"];
    id: Scalars["UUID"]["input"];
    name: Scalars["String"]["input"];
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInverseInput>;
    workflowSectionWorkflowVersionsUsingId?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInverseInput>;
    workflowSourceWorkflowVersionsUsingId?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInverseInput>;
    workflowToWorkflowId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInput>;
  };

/** The fields on `workflowVersion` to look up the row to connect. */
export type WorkflowVersionWorkflowVersionPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `workflowVersion` to look up the row to delete. */
export type WorkflowVersionWorkflowVersionPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** A connection to a list of `WorkflowVersion` values. */
export type WorkflowVersionsConnection = {
  __typename: "WorkflowVersionsConnection";
  /** A list of edges which contains the `WorkflowVersion` and cursor to aid in pagination. */
  edges: Array<WorkflowVersionsEdge>;
  /** A list of `WorkflowVersion` objects. */
  nodes: Array<WorkflowVersion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WorkflowVersion` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `WorkflowVersion` edge in the connection. */
export type WorkflowVersionsEdge = {
  __typename: "WorkflowVersionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `WorkflowVersion` at the end of the edge. */
  node: WorkflowVersion;
};

/** Methods to use when ordering `WorkflowVersion`. */
export enum WorkflowVersionsOrderBy {
  AutoGeneratedAsc = "AUTO_GENERATED_ASC",
  AutoGeneratedDesc = "AUTO_GENERATED_DESC",
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  DraftAsc = "DRAFT_ASC",
  DraftDesc = "DRAFT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
  WorkflowIdAsc = "WORKFLOW_ID_ASC",
  WorkflowIdDesc = "WORKFLOW_ID_DESC",
}

/** The fields on `workflow` to look up the row to connect. */
export type WorkflowWorkflowPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `workflow` to look up the row to delete. */
export type WorkflowWorkflowPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** A connection to a list of `Workflow` values. */
export type WorkflowsConnection = {
  __typename: "WorkflowsConnection";
  /** A list of edges which contains the `Workflow` and cursor to aid in pagination. */
  edges: Array<WorkflowsEdge>;
  /** A list of `Workflow` objects. */
  nodes: Array<Workflow>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Workflow` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `Workflow` edge in the connection. */
export type WorkflowsEdge = {
  __typename: "WorkflowsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Workflow` at the end of the edge. */
  node: Workflow;
};

/** Methods to use when ordering `Workflow`. */
export enum WorkflowsOrderBy {
  ContainerIdAsc = "CONTAINER_ID_ASC",
  ContainerIdDesc = "CONTAINER_ID_DESC",
  DateCreatedAsc = "DATE_CREATED_ASC",
  DateCreatedDesc = "DATE_CREATED_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TenantIdAsc = "TENANT_ID_ASC",
  TenantIdDesc = "TENANT_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** An object where the defined keys will be set on the `analysisTableGroup` being updated. */
export type UpdateAnalysisTableGroupOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdPatch =
  {
    analysisTablesUsingId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolToToolId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `analysisTableGroup` being updated. */
export type UpdateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdPatch =
  {
    analysisTablesUsingId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolToToolId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `analysisTableGroup` being updated. */
export type UpdateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdPatch =
  {
    analysisTablesUsingId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInput>;
    tenantToTenantId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolToToolId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `analysisTableGroup` being updated. */
export type UpdateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdPatch =
  {
    analysisTablesUsingId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInput>;
    toolToToolId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `analysisTableGroup` being updated. */
export type UpdateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdPatch =
  {
    analysisTablesUsingId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolToToolId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInput>;
  };

/** An object where the defined keys will be set on the `analysisTable` being updated. */
export type UpdateAnalysisTableOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdPatch =
  {
    analysisTableGroupToGroupId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    identifiers?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `analysisTable` being updated. */
export type UpdateAnalysisTableOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdPatch =
  {
    analysisTableGroupToGroupId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    groupId?: InputMaybe<Scalars["BigInt"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    identifiers?: InputMaybe<Scalars["JSON"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `analysisTable` being updated. */
export type UpdateAnalysisTableOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdPatch =
  {
    analysisTableGroupToGroupId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    groupId?: InputMaybe<Scalars["BigInt"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    identifiers?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInput>;
    tenantToTenantId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `analysisTable` being updated. */
export type UpdateAnalysisTableOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdPatch =
  {
    analysisTableGroupToGroupId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    groupId?: InputMaybe<Scalars["BigInt"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    identifiers?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInput>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `analysisTable` being updated. */
export type UpdateAnalysisTableOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdPatch =
  {
    analysisTableGroupToGroupId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    groupId?: InputMaybe<Scalars["BigInt"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    identifiers?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput>;
  };

/** An object where the defined keys will be set on the `analysisTable` being updated. */
export type UpdateAnalysisTableOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdPatch =
  {
    analysisTableGroupToGroupId?: InputMaybe<AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput>;
    applicationUserToUserId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    groupId?: InputMaybe<Scalars["BigInt"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    identifiers?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `analysisTableRowAttachResult` being updated. */
export type UpdateAnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisPatch =
  {
    analysisTableRowToAnalysisTableRowId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInput>;
    attachResults?: InputMaybe<Scalars["Boolean"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInput>;
  };

/** An object where the defined keys will be set on the `analysisTableRowAttachResult` being updated. */
export type UpdateAnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIPatch =
  {
    analysisTableRowId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableRowToAnalysisTableRowId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInput>;
    attachResults?: InputMaybe<Scalars["Boolean"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInput>;
  };

/** An object where the defined keys will be set on the `analysisTableRow` being updated. */
export type UpdateAnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisPatch =
  {
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput>;
    analysisTableToTableId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput>;
    attachments?: InputMaybe<Scalars["JSON"]["input"]>;
    autofillMap?: InputMaybe<Scalars["JSON"]["input"]>;
    data?: InputMaybe<Scalars["JSON"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    groups?: InputMaybe<Scalars["JSON"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumReferences?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInput>;
    selections?: InputMaybe<Scalars["JSON"]["input"]>;
    tableId?: InputMaybe<Scalars["BigInt"]["input"]>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput>;
  };

/** An object where the defined keys will be set on the `analysisTableRow` being updated. */
export type UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdPatch =
  {
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput>;
    analysisTableToTableId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput>;
    attachments?: InputMaybe<Scalars["JSON"]["input"]>;
    autofillMap?: InputMaybe<Scalars["JSON"]["input"]>;
    data?: InputMaybe<Scalars["JSON"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    groups?: InputMaybe<Scalars["JSON"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumReferences?: InputMaybe<Scalars["JSON"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInput>;
    selections?: InputMaybe<Scalars["JSON"]["input"]>;
    tableId?: InputMaybe<Scalars["BigInt"]["input"]>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput>;
  };

/** An object where the defined keys will be set on the `analysisTableRow` being updated. */
export type UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdPatch =
  {
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput>;
    analysisTableToTableId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput>;
    attachments?: InputMaybe<Scalars["JSON"]["input"]>;
    autofillMap?: InputMaybe<Scalars["JSON"]["input"]>;
    data?: InputMaybe<Scalars["JSON"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    groups?: InputMaybe<Scalars["JSON"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumReferences?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInput>;
    selections?: InputMaybe<Scalars["JSON"]["input"]>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput>;
  };

/** An object where the defined keys will be set on the `analysisTableRow` being updated. */
export type UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdPatch =
  {
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput>;
    analysisTableToTableId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput>;
    attachments?: InputMaybe<Scalars["JSON"]["input"]>;
    autofillMap?: InputMaybe<Scalars["JSON"]["input"]>;
    data?: InputMaybe<Scalars["JSON"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    groups?: InputMaybe<Scalars["JSON"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumReferences?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInput>;
    selections?: InputMaybe<Scalars["JSON"]["input"]>;
    tableId?: InputMaybe<Scalars["BigInt"]["input"]>;
    taskToTaskId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput>;
  };

/** An object where the defined keys will be set on the `analysisTableRow` being updated. */
export type UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdPatch =
  {
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput>;
    analysisTableToTableId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput>;
    attachments?: InputMaybe<Scalars["JSON"]["input"]>;
    autofillMap?: InputMaybe<Scalars["JSON"]["input"]>;
    data?: InputMaybe<Scalars["JSON"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    groups?: InputMaybe<Scalars["JSON"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumReferences?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInput>;
    selections?: InputMaybe<Scalars["JSON"]["input"]>;
    tableId?: InputMaybe<Scalars["BigInt"]["input"]>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInput>;
    tenantToTenantId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput>;
  };

/** An object where the defined keys will be set on the `analysisTableRow` being updated. */
export type UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdPatch =
  {
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput>;
    analysisTableToTableId?: InputMaybe<AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput>;
    attachments?: InputMaybe<Scalars["JSON"]["input"]>;
    autofillMap?: InputMaybe<Scalars["JSON"]["input"]>;
    data?: InputMaybe<Scalars["JSON"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    groups?: InputMaybe<Scalars["JSON"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumReferences?: InputMaybe<Scalars["JSON"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInput>;
    selections?: InputMaybe<Scalars["JSON"]["input"]>;
    tableId?: InputMaybe<Scalars["BigInt"]["input"]>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInput>;
    toolVersionToToolVersionId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput>;
  };

/** An object where the defined keys will be set on the `applicationUserGroup` being updated. */
export type UpdateApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdPatch =
  {
    applicationUserToUserId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInput>;
    authGroupToGroupId?: InputMaybe<ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `applicationUserGroup` being updated. */
export type UpdateApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdPatch =
  {
    applicationUserToUserId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInput>;
    authGroupToGroupId?: InputMaybe<ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInput>;
    groupId?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `applicationUserMembership` being updated. */
export type UpdateApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiPatch =
  {
    applicationUserToUserId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    role?: InputMaybe<Scalars["Int"]["input"]>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `applicationUserMembership` being updated. */
export type UpdateApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdPatch =
  {
    applicationUserToUserId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    role?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `applicationUserMetadatum` being updated. */
export type UpdateApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiPatch =
  {
    applicationUserToUserId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    value?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUser` being updated. */
export type UpdateApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput>;
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput>;
    applicationUserMetadataUsingId?: InputMaybe<ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput>;
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInverseInput>;
    avatarUsingId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInverseInput>;
    cognitoTenantKey?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetsUsingId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    explorerQueriesUsingId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput>;
    filesUsingId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInverseInput>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    globalUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    homeRegion?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
    isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput>;
    notificationsUsingId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInverseInput>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput>;
    projectSharedNotificationsToSharerIdUsingId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput>;
    projectSharedNotificationsToUserIdUsingId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput>;
    projectsUsingId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput>;
    tagsUsingId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToInviterIdUsingId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInverseInput>;
    tenantUserMembershipNotificationsToUserIdUsingId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput>;
    tenantUserexecutorToExecutorId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput>;
    username?: InputMaybe<Scalars["String"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInverseInput>;
  };

/** An object where the defined keys will be set on the `applicationUserSession` being updated. */
export type UpdateApplicationUserSessionOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiPatch =
  {
    active?: InputMaybe<Scalars["Boolean"]["input"]>;
    applicationUserToUserId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInput>;
    datetimeLastActive?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    lastPath?: InputMaybe<Scalars["String"]["input"]>;
    state?: InputMaybe<Scalars["JSON"]["input"]>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `applicationUserSession` being updated. */
export type UpdateApplicationUserSessionOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdPatch =
  {
    active?: InputMaybe<Scalars["Boolean"]["input"]>;
    applicationUserToUserId?: InputMaybe<ApplicationUserSesUserId2Fb30D0CFkApplicatiInput>;
    datetimeLastActive?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    lastPath?: InputMaybe<Scalars["String"]["input"]>;
    state?: InputMaybe<Scalars["JSON"]["input"]>;
    tenantToTenantId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `applicationUserUserPermission` being updated. */
export type UpdateApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermPatch =
  {
    applicationUserToUserId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInput>;
    authPermissionToPermissionId?: InputMaybe<ApplicationUserUsePermissionIdDbf9693DFkAuthPermInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `applicationUserUserPermission` being updated. */
export type UpdateApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiPatch =
  {
    applicationUserToUserId?: InputMaybe<ApplicationUserUseUserIdE643F548FkApplicatiInput>;
    authPermissionToPermissionId?: InputMaybe<ApplicationUserUsePermissionIdDbf9693DFkAuthPermInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    permissionId?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `authGroup` being updated. */
export type UpdateAuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdPatch =
  {
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInverseInput>;
    authGroupPermissionsUsingId?: InputMaybe<AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `authGroup` being updated. */
export type UpdateAuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdPatch =
  {
    applicationUserGroupsUsingId?: InputMaybe<ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInverseInput>;
    authGroupPermissionsUsingId?: InputMaybe<AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `authGroupPermission` being updated. */
export type UpdateAuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermPatch =
  {
    authGroupToGroupId?: InputMaybe<AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInput>;
    authPermissionToPermissionId?: InputMaybe<AuthGroupPermissioPermissionId84C5C92EFkAuthPermInput>;
    groupId?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `authGroupPermission` being updated. */
export type UpdateAuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdPatch =
  {
    authGroupToGroupId?: InputMaybe<AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInput>;
    authPermissionToPermissionId?: InputMaybe<AuthGroupPermissioPermissionId84C5C92EFkAuthPermInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    permissionId?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `authPermission` being updated. */
export type UpdateAuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermPatch =
  {
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUsePermissionIdDbf9693DFkAuthPermInverseInput>;
    authGroupPermissionsUsingId?: InputMaybe<AuthGroupPermissioPermissionId84C5C92EFkAuthPermInverseInput>;
    codename?: InputMaybe<Scalars["String"]["input"]>;
    contentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
    djangoContentTypeToContentTypeId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `authPermission` being updated. */
export type UpdateAuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermPatch =
  {
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUsePermissionIdDbf9693DFkAuthPermInverseInput>;
    authGroupPermissionsUsingId?: InputMaybe<AuthGroupPermissioPermissionId84C5C92EFkAuthPermInverseInput>;
    codename?: InputMaybe<Scalars["String"]["input"]>;
    contentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
    djangoContentTypeToContentTypeId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `authPermission` being updated. */
export type UpdateAuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoPatch =
  {
    applicationUserUserPermissionsUsingId?: InputMaybe<ApplicationUserUsePermissionIdDbf9693DFkAuthPermInverseInput>;
    authGroupPermissionsUsingId?: InputMaybe<AuthGroupPermissioPermissionId84C5C92EFkAuthPermInverseInput>;
    codename?: InputMaybe<Scalars["String"]["input"]>;
    djangoContentTypeToContentTypeId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `avatar` being updated. */
export type UpdateAvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdPatch =
  {
    applicationUserToUserId?: InputMaybe<AvatarUserId771332F2FkApplicationUserIdInput>;
    color?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    imageBase64?: InputMaybe<Scalars["String"]["input"]>;
    initials?: InputMaybe<Scalars["String"]["input"]>;
    useImage?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

/** An object where the defined keys will be set on the `awsBatchTask` being updated. */
export type UpdateAwsBatchTaskOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskePatch =
  {
    awsDataBucketName?: InputMaybe<Scalars["String"]["input"]>;
    awsInstanceType?: InputMaybe<Scalars["String"]["input"]>;
    awsJobId?: InputMaybe<Scalars["String"]["input"]>;
    awsJobQueue?: InputMaybe<Scalars["String"]["input"]>;
    awsJobStatus?: InputMaybe<Scalars["Int"]["input"]>;
    awsJobStatusReason?: InputMaybe<Scalars["String"]["input"]>;
    awsMockBatchTaskUsingTaskexecutorPtrId?: InputMaybe<AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInverseInput>;
    awsRegion?: InputMaybe<Scalars["String"]["input"]>;
    awsTaskPath?: InputMaybe<Scalars["String"]["input"]>;
    exitcode?: InputMaybe<Scalars["Int"]["input"]>;
    resultsProcessed?: InputMaybe<Scalars["Boolean"]["input"]>;
    tesTaskexecutorToTaskexecutorPtrId?: InputMaybe<AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInput>;
  };

/** An object where the defined keys will be set on the `awsBatchTask` being updated. */
export type UpdateAwsBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchPatch =
  {
    awsDataBucketName?: InputMaybe<Scalars["String"]["input"]>;
    awsInstanceType?: InputMaybe<Scalars["String"]["input"]>;
    awsJobId?: InputMaybe<Scalars["String"]["input"]>;
    awsJobQueue?: InputMaybe<Scalars["String"]["input"]>;
    awsJobStatus?: InputMaybe<Scalars["Int"]["input"]>;
    awsJobStatusReason?: InputMaybe<Scalars["String"]["input"]>;
    awsMockBatchTaskUsingTaskexecutorPtrId?: InputMaybe<AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInverseInput>;
    awsRegion?: InputMaybe<Scalars["String"]["input"]>;
    awsTaskPath?: InputMaybe<Scalars["String"]["input"]>;
    exitcode?: InputMaybe<Scalars["Int"]["input"]>;
    resultsProcessed?: InputMaybe<Scalars["Boolean"]["input"]>;
    taskexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
    tesTaskexecutorToTaskexecutorPtrId?: InputMaybe<AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInput>;
  };

/** An object where the defined keys will be set on the `awsCognitoUser` being updated. */
export type UpdateAwsCognitoUserOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsPatch =
  {
    tenantUserexecutorToUserexecutorPtrId?: InputMaybe<AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInput>;
  };

/** An object where the defined keys will be set on the `awsEcrImage` being updated. */
export type UpdateAwsEcrImageOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaPatch =
  {
    awsMockEcrImageUsingContainerimageexecutorPtrId?: InputMaybe<AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInverseInput>;
    jobDefinitionName?: InputMaybe<Scalars["String"]["input"]>;
    jobDefinitionRevision?: InputMaybe<Scalars["Int"]["input"]>;
    repositoryArn?: InputMaybe<Scalars["String"]["input"]>;
    tesContainerimageexecutorToContainerimageexecutorPtrId?: InputMaybe<AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInput>;
  };

/** An object where the defined keys will be set on the `awsEcrImage` being updated. */
export type UpdateAwsEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIPatch =
  {
    awsMockEcrImageUsingContainerimageexecutorPtrId?: InputMaybe<AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInverseInput>;
    containerimageexecutorPtrId?: InputMaybe<Scalars["Int"]["input"]>;
    jobDefinitionName?: InputMaybe<Scalars["String"]["input"]>;
    jobDefinitionRevision?: InputMaybe<Scalars["Int"]["input"]>;
    repositoryArn?: InputMaybe<Scalars["String"]["input"]>;
    tesContainerimageexecutorToContainerimageexecutorPtrId?: InputMaybe<AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInput>;
  };

/** An object where the defined keys will be set on the `awsMockBatchTask` being updated. */
export type UpdateAwsMockBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchPatch =
  {
    awsBatchTaskToAwstaskexecutorPtrId?: InputMaybe<AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInput>;
    containerName?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `awsMockEcrImage` being updated. */
export type UpdateAwsMockEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIPatch =
  {
    awsEcrImageToAwscontainerimageexecutorPtrId?: InputMaybe<AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInput>;
  };

/** An object where the defined keys will be set on the `awsMockS3File` being updated. */
export type UpdateAwsMockS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiPatch =
  {
    awsS3FileToAwsfileexecutorPtrId?: InputMaybe<AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInput>;
  };

/** An object where the defined keys will be set on the `awsS3File` being updated. */
export type UpdateAwsS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiPatch =
  {
    awsMockS3FileUsingFileexecutorPtrId?: InputMaybe<AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInverseInput>;
    awsUploadPath?: InputMaybe<Scalars["String"]["input"]>;
    drsFileexecutorToFileexecutorPtrId?: InputMaybe<AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInput>;
    fileexecutorPtrId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `awsS3File` being updated. */
export type UpdateAwsS3FileOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdPatch =
  {
    awsMockS3FileUsingFileexecutorPtrId?: InputMaybe<AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInverseInput>;
    awsUploadPath?: InputMaybe<Scalars["String"]["input"]>;
    drsFileexecutorToFileexecutorPtrId?: InputMaybe<AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInput>;
  };

/** An object where the defined keys will be set on the `containerImage` being updated. */
export type UpdateContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaPatch =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    repository?: InputMaybe<Scalars["String"]["input"]>;
    tag?: InputMaybe<Scalars["String"]["input"]>;
    tasksUsingId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInverseInput>;
    tesContainerimageexecutorToExecutorId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInput>;
    toolboxVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxVersionToToolboxVersionId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInput>;
    workflowsUsingId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInverseInput>;
  };

/** An object where the defined keys will be set on the `containerImage` being updated. */
export type UpdateContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVPatch =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    repository?: InputMaybe<Scalars["String"]["input"]>;
    tag?: InputMaybe<Scalars["String"]["input"]>;
    tasksUsingId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInverseInput>;
    tesContainerimageexecutorToExecutorId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInput>;
    toolboxVersionToToolboxVersionId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInput>;
    workflowsUsingId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInverseInput>;
  };

/** An object where the defined keys will be set on the `containerImage` being updated. */
export type UpdateContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdPatch =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    repository?: InputMaybe<Scalars["String"]["input"]>;
    tag?: InputMaybe<Scalars["String"]["input"]>;
    tasksUsingId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInverseInput>;
    tesContainerimageexecutorToExecutorId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInput>;
    toolboxVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxVersionToToolboxVersionId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInput>;
    workflowsUsingId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInverseInput>;
  };

/** An object where the defined keys will be set on the `containerImage` being updated. */
export type UpdateContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdPatch =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    executorId?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    repository?: InputMaybe<Scalars["String"]["input"]>;
    tag?: InputMaybe<Scalars["String"]["input"]>;
    tasksUsingId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInverseInput>;
    tesContainerimageexecutorToExecutorId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInput>;
    toolboxVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxVersionToToolboxVersionId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInput>;
    workflowsUsingId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInverseInput>;
  };

/** An object where the defined keys will be set on the `cost` being updated. */
export type UpdateCostOnCostForCostTenantId7Dcd58A7FkTenantIdPatch = {
  costType?: InputMaybe<Scalars["Int"]["input"]>;
  currency?: InputMaybe<Scalars["Int"]["input"]>;
  date?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInverseInput>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  platform?: InputMaybe<Scalars["Int"]["input"]>;
  rateCode?: InputMaybe<Scalars["String"]["input"]>;
  taskActivitiesUsingId?: InputMaybe<TaskActivityCostId49E66058FkCostIdInverseInput>;
  tenantToTenantId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInput>;
  value?: InputMaybe<Scalars["BigFloat"]["input"]>;
};

/** An object where the defined keys will be set on the `cost` being updated. */
export type UpdateCostOnFileActivityForFileActivityCostId2A88D90AFkCostIdPatch =
  {
    costType?: InputMaybe<Scalars["Int"]["input"]>;
    currency?: InputMaybe<Scalars["Int"]["input"]>;
    date?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInverseInput>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    platform?: InputMaybe<Scalars["Int"]["input"]>;
    rateCode?: InputMaybe<Scalars["String"]["input"]>;
    taskActivitiesUsingId?: InputMaybe<TaskActivityCostId49E66058FkCostIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInput>;
    value?: InputMaybe<Scalars["BigFloat"]["input"]>;
  };

/** An object where the defined keys will be set on the `cost` being updated. */
export type UpdateCostOnTaskActivityForTaskActivityCostId49E66058FkCostIdPatch =
  {
    costType?: InputMaybe<Scalars["Int"]["input"]>;
    currency?: InputMaybe<Scalars["Int"]["input"]>;
    date?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInverseInput>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    platform?: InputMaybe<Scalars["Int"]["input"]>;
    rateCode?: InputMaybe<Scalars["String"]["input"]>;
    taskActivitiesUsingId?: InputMaybe<TaskActivityCostId49E66058FkCostIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInput>;
    value?: InputMaybe<Scalars["BigFloat"]["input"]>;
  };

/** An object where the defined keys will be set on the `datasetMetadatum` being updated. */
export type UpdateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdPatch =
  {
    datasetToDatasetId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `datasetMetadatum` being updated. */
export type UpdateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdPatch =
  {
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `datasetMetadatum` being updated. */
export type UpdateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdPatch =
  {
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInput>;
    projectToProjectId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `datasetMetadatum` being updated. */
export type UpdateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdPatch =
  {
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInput>;
    tenantToTenantId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `dataset` being updated. */
export type UpdateDatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdPatch =
  {
    applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
    datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
    recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `dataset` being updated. */
export type UpdateDatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdPatch =
  {
    applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
    datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
    filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
    recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `dataset` being updated. */
export type UpdateDatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRPatch =
  {
    applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
    datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
    filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `dataset` being updated. */
export type UpdateDatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdPatch =
  {
    applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
    datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
    filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
    recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
    tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `dataset` being updated. */
export type UpdateDatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdPatch =
  {
    applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
    datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
    filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
    recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `dataset` being updated. */
export type UpdateDatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdPatch =
  {
    applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
    datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
    filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
    recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `dataset` being updated. */
export type UpdateDatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdPatch =
  {
    applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
    datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
    filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
    recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `dataset` being updated. */
export type UpdateDatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdPatch = {
  applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
  datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
  filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  prefix?: InputMaybe<Scalars["String"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
  recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** An object where the defined keys will be set on the `dataset` being updated. */
export type UpdateDatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdPatch = {
  applicationUserToUserId?: InputMaybe<DatasetV4UserId5B9A2303FkApplicationUserIdInput>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput>;
  datasetRecordingsTableToRecordingsTableId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  descriptionId?: InputMaybe<Scalars["UUID"]["input"]>;
  filesUsingId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  metadatumToDescriptionId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  prefix?: InputMaybe<Scalars["String"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInput>;
  recordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  tasksUsingId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** An object where the defined keys will be set on the `datasetRecordingsTableColumn` being updated. */
export type UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataPatch =
  {
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    identifierPosition?: InputMaybe<Scalars["Int"]["input"]>;
    metadatumToColDefId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInput>;
    pinned?: InputMaybe<Scalars["Boolean"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput>;
    width?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `datasetRecordingsTableColumn` being updated. */
export type UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRPatch =
  {
    colDefId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    identifierPosition?: InputMaybe<Scalars["Int"]["input"]>;
    metadatumToColDefId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInput>;
    pinned?: InputMaybe<Scalars["Boolean"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput>;
    width?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `datasetRecordingsTableColumn` being updated. */
export type UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIPatch =
  {
    colDefId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    identifierPosition?: InputMaybe<Scalars["Int"]["input"]>;
    metadatumToColDefId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInput>;
    pinned?: InputMaybe<Scalars["Boolean"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput>;
    width?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `datasetRecordingsTableColumn` being updated. */
export type UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdPatch =
  {
    colDefId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    identifierPosition?: InputMaybe<Scalars["Int"]["input"]>;
    metadatumToColDefId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInput>;
    pinned?: InputMaybe<Scalars["Boolean"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput>;
    width?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `datasetRecordingsTableColumn` being updated. */
export type UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRPatch =
  {
    colDefId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    identifierPosition?: InputMaybe<Scalars["Int"]["input"]>;
    metadatumToColDefId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInput>;
    pinned?: InputMaybe<Scalars["Boolean"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput>;
    width?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `datasetRecordingsTableColumn` being updated. */
export type UpdateDatasetRecordingsTableColumnOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRPatch =
  {
    colDefId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    identifierPosition?: InputMaybe<Scalars["Int"]["input"]>;
    metadatumToColDefId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInput>;
    pinned?: InputMaybe<Scalars["Boolean"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput>;
    width?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `datasetRecordingsTableColumnOrder` being updated. */
export type UpdateDatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRPatch =
  {
    datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInput>;
    value?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `datasetRecordingsTableColumnOrder` being updated. */
export type UpdateDatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIPatch =
  {
    datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInput>;
    value?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `datasetRecordingsTableColumnOrder` being updated. */
export type UpdateDatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdPatch =
  {
    datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInput>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInput>;
    value?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `datasetRecordingsTable` being updated. */
export type UpdateDatasetRecordingsTableOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRPatch =
  {
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInverseInput>;
    datasetUsingId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInput>;
    views?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** An object where the defined keys will be set on the `datasetRecordingsTable` being updated. */
export type UpdateDatasetRecordingsTableOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRPatch =
  {
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInverseInput>;
    datasetUsingId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInput>;
    views?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** An object where the defined keys will be set on the `datasetRecordingsTable` being updated. */
export type UpdateDatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdPatch =
  {
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInverseInput>;
    datasetUsingId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInput>;
    views?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** An object where the defined keys will be set on the `datasetRecordingsTable` being updated. */
export type UpdateDatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdPatch =
  {
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInverseInput>;
    datasetUsingId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInput>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInput>;
    views?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** An object where the defined keys will be set on the `datasetRecordingsTable` being updated. */
export type UpdateDatasetRecordingsTableOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRPatch =
  {
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInverseInput>;
    datasetUsingId?: InputMaybe<DatasetV4RecordingsTableIdD72E44AeFkDatasetRInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInput>;
    views?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** An object where the defined keys will be set on the `datasetVersion` being updated. */
export type UpdateDatasetVersionOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdPatch =
  {
    datasetToDatasetId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInput>;
    validationState?: InputMaybe<Scalars["JSON"]["input"]>;
    views?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** An object where the defined keys will be set on the `datasetVersion` being updated. */
export type UpdateDatasetVersionOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdPatch =
  {
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInput>;
    validationState?: InputMaybe<Scalars["JSON"]["input"]>;
    views?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** An object where the defined keys will be set on the `datasetVersion` being updated. */
export type UpdateDatasetVersionOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdPatch =
  {
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInput>;
    tenantToTenantId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInput>;
    validationState?: InputMaybe<Scalars["JSON"]["input"]>;
    views?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** An object where the defined keys will be set on the `djangoAdminLog` being updated. */
export type UpdateDjangoAdminLogOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoPatch =
  {
    actionFlag?: InputMaybe<Scalars["Int"]["input"]>;
    actionTime?: InputMaybe<Scalars["Datetime"]["input"]>;
    applicationUserToUserId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInput>;
    changeMessage?: InputMaybe<Scalars["String"]["input"]>;
    djangoContentTypeToContentTypeId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    objectId?: InputMaybe<Scalars["String"]["input"]>;
    objectRepr?: InputMaybe<Scalars["String"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `djangoAdminLog` being updated. */
export type UpdateDjangoAdminLogOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdPatch =
  {
    actionFlag?: InputMaybe<Scalars["Int"]["input"]>;
    actionTime?: InputMaybe<Scalars["Datetime"]["input"]>;
    applicationUserToUserId?: InputMaybe<DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInput>;
    changeMessage?: InputMaybe<Scalars["String"]["input"]>;
    contentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
    djangoContentTypeToContentTypeId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    objectId?: InputMaybe<Scalars["String"]["input"]>;
    objectRepr?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `djangoContentType` being updated. */
export type UpdateDjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoPatch =
  {
    appLabel?: InputMaybe<Scalars["String"]["input"]>;
    authPermissionsUsingId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput>;
    drsFileexecutorsUsingId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    model?: InputMaybe<Scalars["String"]["input"]>;
    tenantUserexecutorsUsingId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput>;
    tesContainerimageexecutorsUsingId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput>;
    tesTaskexecutorsUsingId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput>;
  };

/** An object where the defined keys will be set on the `djangoContentType` being updated. */
export type UpdateDjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoPatch =
  {
    appLabel?: InputMaybe<Scalars["String"]["input"]>;
    authPermissionsUsingId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput>;
    drsFileexecutorsUsingId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    model?: InputMaybe<Scalars["String"]["input"]>;
    tenantUserexecutorsUsingId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput>;
    tesContainerimageexecutorsUsingId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput>;
    tesTaskexecutorsUsingId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput>;
  };

/** An object where the defined keys will be set on the `djangoContentType` being updated. */
export type UpdateDjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoPatch =
  {
    appLabel?: InputMaybe<Scalars["String"]["input"]>;
    authPermissionsUsingId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput>;
    drsFileexecutorsUsingId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    model?: InputMaybe<Scalars["String"]["input"]>;
    tenantUserexecutorsUsingId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput>;
    tesContainerimageexecutorsUsingId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput>;
    tesTaskexecutorsUsingId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput>;
  };

/** An object where the defined keys will be set on the `djangoContentType` being updated. */
export type UpdateDjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoPatch =
  {
    appLabel?: InputMaybe<Scalars["String"]["input"]>;
    authPermissionsUsingId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput>;
    drsFileexecutorsUsingId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    model?: InputMaybe<Scalars["String"]["input"]>;
    tenantUserexecutorsUsingId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput>;
    tesContainerimageexecutorsUsingId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput>;
    tesTaskexecutorsUsingId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput>;
  };

/** An object where the defined keys will be set on the `djangoContentType` being updated. */
export type UpdateDjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoPatch =
  {
    appLabel?: InputMaybe<Scalars["String"]["input"]>;
    authPermissionsUsingId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput>;
    drsFileexecutorsUsingId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    model?: InputMaybe<Scalars["String"]["input"]>;
    tenantUserexecutorsUsingId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput>;
    tesContainerimageexecutorsUsingId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput>;
    tesTaskexecutorsUsingId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput>;
  };

/** An object where the defined keys will be set on the `djangoContentType` being updated. */
export type UpdateDjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoPatch =
  {
    appLabel?: InputMaybe<Scalars["String"]["input"]>;
    authPermissionsUsingId?: InputMaybe<AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput>;
    djangoAdminLogsUsingId?: InputMaybe<DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput>;
    drsFileexecutorsUsingId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    model?: InputMaybe<Scalars["String"]["input"]>;
    tenantUserexecutorsUsingId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput>;
    tesContainerimageexecutorsUsingId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput>;
    tesTaskexecutorsUsingId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput>;
  };

/** An object where the defined keys will be set on the `drsFileexecutor` being updated. */
export type UpdateDrsFileexecutorOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdPatch =
  {
    awsS3FileUsingId?: InputMaybe<AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInput>;
    drsMockfileexecutorUsingId?: InputMaybe<DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInverseInput>;
    filesUsingId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `drsFileexecutor` being updated. */
export type UpdateDrsFileexecutorOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoPatch =
  {
    awsS3FileUsingId?: InputMaybe<AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInput>;
    drsMockfileexecutorUsingId?: InputMaybe<DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInverseInput>;
    filesUsingId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `drsFileexecutor` being updated. */
export type UpdateDrsFileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileePatch =
  {
    awsS3FileUsingId?: InputMaybe<AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInput>;
    drsMockfileexecutorUsingId?: InputMaybe<DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInverseInput>;
    filesUsingId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `drsMockfileexecutor` being updated. */
export type UpdateDrsMockfileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileePatch =
  {
    drsFileexecutorToFileexecutorPtrId?: InputMaybe<DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInput>;
  };

/** An object where the defined keys will be set on the `eulaVersion` being updated. */
export type UpdateEulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersPatch =
  {
    content?: InputMaybe<Scalars["String"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateVersion?: InputMaybe<Scalars["Datetime"]["input"]>;
    eulaVersionUserAcceptancesUsingId?: InputMaybe<EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    version?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `eulaVersionUserAcceptance` being updated. */
export type UpdateEulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersPatch =
  {
    accepted?: InputMaybe<Scalars["Boolean"]["input"]>;
    applicationUserToUserId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInput>;
    dateReviewed?: InputMaybe<Scalars["Datetime"]["input"]>;
    eulaVersionToEulaVersionId?: InputMaybe<EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `eulaVersionUserAcceptance` being updated. */
export type UpdateEulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiPatch =
  {
    accepted?: InputMaybe<Scalars["Boolean"]["input"]>;
    applicationUserToUserId?: InputMaybe<EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInput>;
    dateReviewed?: InputMaybe<Scalars["Datetime"]["input"]>;
    eulaVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    eulaVersionToEulaVersionId?: InputMaybe<EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `explorerQuery` being updated. */
export type UpdateExplorerQueryOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiPatch =
  {
    applicationUserToCreatedByUserId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInput>;
    connection?: InputMaybe<Scalars["String"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    lastRunDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    snapshot?: InputMaybe<Scalars["Boolean"]["input"]>;
    sql?: InputMaybe<Scalars["String"]["input"]>;
    title?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `explorerQuery` being updated. */
export type UpdateExplorerQueryOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdPatch =
  {
    applicationUserToCreatedByUserId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInput>;
    connection?: InputMaybe<Scalars["String"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    createdByUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    lastRunDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    snapshot?: InputMaybe<Scalars["Boolean"]["input"]>;
    sql?: InputMaybe<Scalars["String"]["input"]>;
    title?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `explorerQuery` being updated. */
export type UpdateExplorerQueryOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdPatch =
  {
    applicationUserToCreatedByUserId?: InputMaybe<ExplorerQueryCreatedByUserId182Dd868FkApplicatiInput>;
    connection?: InputMaybe<Scalars["String"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    createdByUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    explorerQueryfavoritesUsingId?: InputMaybe<ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInverseInput>;
    explorerQuerylogsUsingId?: InputMaybe<ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    lastRunDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    snapshot?: InputMaybe<Scalars["Boolean"]["input"]>;
    sql?: InputMaybe<Scalars["String"]["input"]>;
    title?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `explorerQueryfavorite` being updated. */
export type UpdateExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdPatch =
  {
    applicationUserToUserId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInput>;
    explorerQueryToQueryId?: InputMaybe<ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `explorerQueryfavorite` being updated. */
export type UpdateExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdPatch =
  {
    applicationUserToUserId?: InputMaybe<ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInput>;
    explorerQueryToQueryId?: InputMaybe<ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    queryId?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `explorerQuerylog` being updated. */
export type UpdateExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdPatch =
  {
    applicationUserToRunByUserId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInput>;
    connection?: InputMaybe<Scalars["String"]["input"]>;
    duration?: InputMaybe<Scalars["Float"]["input"]>;
    explorerQueryToQueryId?: InputMaybe<ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    runAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    runByUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    sql?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `explorerQuerylog` being updated. */
export type UpdateExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiPatch =
  {
    applicationUserToRunByUserId?: InputMaybe<ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInput>;
    connection?: InputMaybe<Scalars["String"]["input"]>;
    duration?: InputMaybe<Scalars["Float"]["input"]>;
    explorerQueryToQueryId?: InputMaybe<ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    queryId?: InputMaybe<Scalars["Int"]["input"]>;
    runAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    sql?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `fileActivity` being updated. */
export type UpdateFileActivityOnFileActivityForFileActivityCostId2A88D90AFkCostIdPatch =
  {
    activityType?: InputMaybe<Scalars["Int"]["input"]>;
    applicationUserToUserId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInput>;
    bytes?: InputMaybe<Scalars["BigInt"]["input"]>;
    comment?: InputMaybe<Scalars["String"]["input"]>;
    costToCostId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileToFileId?: InputMaybe<FileActivityFileId015763A9FkFileIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `fileActivity` being updated. */
export type UpdateFileActivityOnFileActivityForFileActivityFileId015763A9FkFileIdPatch =
  {
    activityType?: InputMaybe<Scalars["Int"]["input"]>;
    applicationUserToUserId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInput>;
    bytes?: InputMaybe<Scalars["BigInt"]["input"]>;
    comment?: InputMaybe<Scalars["String"]["input"]>;
    costId?: InputMaybe<Scalars["BigInt"]["input"]>;
    costToCostId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileToFileId?: InputMaybe<FileActivityFileId015763A9FkFileIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `fileActivity` being updated. */
export type UpdateFileActivityOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdPatch =
  {
    activityType?: InputMaybe<Scalars["Int"]["input"]>;
    applicationUserToUserId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInput>;
    bytes?: InputMaybe<Scalars["BigInt"]["input"]>;
    comment?: InputMaybe<Scalars["String"]["input"]>;
    costId?: InputMaybe<Scalars["BigInt"]["input"]>;
    costToCostId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileToFileId?: InputMaybe<FileActivityFileId015763A9FkFileIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    projectToProjectId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `fileActivity` being updated. */
export type UpdateFileActivityOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdPatch =
  {
    activityType?: InputMaybe<Scalars["Int"]["input"]>;
    applicationUserToUserId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInput>;
    bytes?: InputMaybe<Scalars["BigInt"]["input"]>;
    comment?: InputMaybe<Scalars["String"]["input"]>;
    costId?: InputMaybe<Scalars["BigInt"]["input"]>;
    costToCostId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileToFileId?: InputMaybe<FileActivityFileId015763A9FkFileIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInput>;
    tenantToTenantId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `fileActivity` being updated. */
export type UpdateFileActivityOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdPatch =
  {
    activityType?: InputMaybe<Scalars["Int"]["input"]>;
    applicationUserToUserId?: InputMaybe<FileActivityUserId868Ad8A9FkApplicationUserIdInput>;
    bytes?: InputMaybe<Scalars["BigInt"]["input"]>;
    comment?: InputMaybe<Scalars["String"]["input"]>;
    costId?: InputMaybe<Scalars["BigInt"]["input"]>;
    costToCostId?: InputMaybe<FileActivityCostId2A88D90AFkCostIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileToFileId?: InputMaybe<FileActivityFileId015763A9FkFileIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `fileMetadatum` being updated. */
export type UpdateFileMetadatumOnFileMetadatumForFileMetadataFileId78256936FkFileIdPatch =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileToFileId?: InputMaybe<FileMetadataFileId78256936FkFileIdInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `fileMetadatum` being updated. */
export type UpdateFileMetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdPatch =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileToFileId?: InputMaybe<FileMetadataFileId78256936FkFileIdInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `fileMetadatum` being updated. */
export type UpdateFileMetadatumOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdPatch =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileToFileId?: InputMaybe<FileMetadataFileId78256936FkFileIdInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInput>;
    projectToProjectId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `fileMetadatum` being updated. */
export type UpdateFileMetadatumOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdPatch =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileToFileId?: InputMaybe<FileMetadataFileId78256936FkFileIdInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInput>;
    tenantToTenantId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnFileActivityForFileActivityFileId015763A9FkFileIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
    executorId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
    fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
    fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
    fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
    fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
    fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
    fileType?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    isSeries?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    location?: InputMaybe<Scalars["String"]["input"]>;
    multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    originalName?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
    parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
    partSize?: InputMaybe<Scalars["Int"]["input"]>;
    preview?: InputMaybe<Scalars["JSON"]["input"]>;
    processingStatus?: InputMaybe<Scalars["Int"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
    seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
    seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
    signal?: InputMaybe<Scalars["Int"]["input"]>;
    size?: InputMaybe<Scalars["BigInt"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
    uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnFileForFileDatasetIdC0A4045AFkDatasetV4IdPatch = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  isSeries?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  originalName?: InputMaybe<Scalars["String"]["input"]>;
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus?: InputMaybe<Scalars["Int"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnFileForFileProjectId3Ecca975FkProjectIdPatch = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  isSeries?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  originalName?: InputMaybe<Scalars["String"]["input"]>;
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus?: InputMaybe<Scalars["Int"]["input"]>;
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnFileForFileSeriesParentIdF7A07E20FkFileIdPatch = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  isSeries?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  originalName?: InputMaybe<Scalars["String"]["input"]>;
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus?: InputMaybe<Scalars["Int"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnFileForFileTenantId61625016FkTenantIdPatch = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  isSeries?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  originalName?: InputMaybe<Scalars["String"]["input"]>;
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus?: InputMaybe<Scalars["Int"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnFileForFileUserId01D90B53FkApplicationUserIdPatch = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  isSeries?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  originalName?: InputMaybe<Scalars["String"]["input"]>;
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus?: InputMaybe<Scalars["Int"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
};

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnFileMetadatumForFileMetadataFileId78256936FkFileIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
    executorId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
    fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
    fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
    fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
    fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
    fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
    fileType?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    isSeries?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    location?: InputMaybe<Scalars["String"]["input"]>;
    multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    originalName?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
    parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
    partSize?: InputMaybe<Scalars["Int"]["input"]>;
    preview?: InputMaybe<Scalars["JSON"]["input"]>;
    processingStatus?: InputMaybe<Scalars["Int"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
    seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
    seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
    signal?: InputMaybe<Scalars["Int"]["input"]>;
    size?: InputMaybe<Scalars["BigInt"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
    uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
    executorId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
    fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
    fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
    fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
    fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
    fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
    fileType?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    isSeries?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    location?: InputMaybe<Scalars["String"]["input"]>;
    multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    originalName?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
    parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
    partSize?: InputMaybe<Scalars["Int"]["input"]>;
    preview?: InputMaybe<Scalars["JSON"]["input"]>;
    processingStatus?: InputMaybe<Scalars["Int"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
    seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
    seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
    signal?: InputMaybe<Scalars["Int"]["input"]>;
    size?: InputMaybe<Scalars["BigInt"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
    uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
    executorId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
    fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
    fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
    fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
    fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
    fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
    fileType?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    isSeries?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    location?: InputMaybe<Scalars["String"]["input"]>;
    multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    originalName?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
    parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
    partSize?: InputMaybe<Scalars["Int"]["input"]>;
    preview?: InputMaybe<Scalars["JSON"]["input"]>;
    processingStatus?: InputMaybe<Scalars["Int"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
    seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
    seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
    signal?: InputMaybe<Scalars["Int"]["input"]>;
    size?: InputMaybe<Scalars["BigInt"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
    uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
    executorId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
    fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
    fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
    fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
    fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
    fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
    fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
    fileType?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    isSeries?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    location?: InputMaybe<Scalars["String"]["input"]>;
    multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    originalName?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
    parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
    partSize?: InputMaybe<Scalars["Int"]["input"]>;
    preview?: InputMaybe<Scalars["JSON"]["input"]>;
    processingStatus?: InputMaybe<Scalars["Int"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
    seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
    seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
    signal?: InputMaybe<Scalars["Int"]["input"]>;
    size?: InputMaybe<Scalars["BigInt"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
    uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `file` being updated. */
export type UpdateFileOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdPatch = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  applicationUserToUserId?: InputMaybe<FileUserId01D90B53FkApplicationUserIdInput>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<FileDatasetIdC0A4045AFkDatasetV4IdInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  drsFileexecutorToExecutorId?: InputMaybe<FileExecutorId819Da590FkDrsFileexecutorIdInput>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityFileId015763A9FkFileIdInverseInput>;
  fileFormat?: InputMaybe<Scalars["Int"]["input"]>;
  fileMetadataUsingId?: InputMaybe<FileMetadataFileId78256936FkFileIdInverseInput>;
  fileProcessingNotificationsUsingId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInverseInput>;
  fileStructure?: InputMaybe<Scalars["Int"]["input"]>;
  fileToCloneSourceId?: InputMaybe<FileCloneSourceIdBef488AeFkFileIdInput>;
  fileToSeriesParentId?: InputMaybe<FileSeriesParentIdF7A07E20FkFileIdInput>;
  fileType?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  isSeries?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  multipartUploadId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  originalName?: InputMaybe<Scalars["String"]["input"]>;
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput>;
  parentIds?: InputMaybe<Scalars["JSON"]["input"]>;
  partSize?: InputMaybe<Scalars["Int"]["input"]>;
  preview?: InputMaybe<Scalars["JSON"]["input"]>;
  processingStatus?: InputMaybe<Scalars["Int"]["input"]>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput>;
  seriesOrder?: InputMaybe<Scalars["Int"]["input"]>;
  seriesParentId?: InputMaybe<Scalars["UUID"]["input"]>;
  signal?: InputMaybe<Scalars["Int"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<FileTenantId61625016FkTenantIdInput>;
  uploadStatus?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** An object where the defined keys will be set on the `fileOutputGroupMetadatum` being updated. */
export type UpdateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpPatch =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileOutputGroupToFileOutputGroupId?: InputMaybe<FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `fileOutputGroupMetadatum` being updated. */
export type UpdateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataPatch =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileOutputGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupToFileOutputGroupId?: InputMaybe<FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `fileOutputGroupMetadatum` being updated. */
export type UpdateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdPatch =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileOutputGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupToFileOutputGroupId?: InputMaybe<FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInput>;
    projectToProjectId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `fileOutputGroupMetadatum` being updated. */
export type UpdateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdPatch =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileOutputGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupToFileOutputGroupId?: InputMaybe<FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInput>;
    tenantToTenantId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `fileOutputGroup` being updated. */
export type UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdPatch =
  {
    applicationUserToUserId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInverseInput>;
    groupType?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInverseInput>;
    projectToProjectId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInput>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `fileOutputGroup` being updated. */
export type UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdPatch =
  {
    applicationUserToUserId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInverseInput>;
    groupType?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInput>;
    taskToTaskId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `fileOutputGroup` being updated. */
export type UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdPatch =
  {
    applicationUserToUserId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInverseInput>;
    groupType?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInput>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInput>;
    tenantToTenantId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `fileOutputGroup` being updated. */
export type UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdPatch =
  {
    applicationUserToUserId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInverseInput>;
    groupType?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInput>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `fileOutputGroup` being updated. */
export type UpdateFileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpPatch =
  {
    applicationUserToUserId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInverseInput>;
    groupType?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInput>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `fileOutputGroup` being updated. */
export type UpdateFileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpPatch =
  {
    applicationUserToUserId?: InputMaybe<FileOutputGroupUserIdB22Ec436FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInverseInput>;
    groupType?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInput>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `fileProcessingNotification` being updated. */
export type UpdateFileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiPatch =
  {
    applicationUserToUserId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInput>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileToFileId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    notificationToId?: InputMaybe<FileProcessingNotificationId630E6495FkNotificationIdInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `fileProcessingNotification` being updated. */
export type UpdateFileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdPatch =
  {
    applicationUserToUserId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInput>;
    fileToFileId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    notificationToId?: InputMaybe<FileProcessingNotificationId630E6495FkNotificationIdInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `fileProcessingNotification` being updated. */
export type UpdateFileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdPatch =
  {
    applicationUserToUserId?: InputMaybe<FileProcessingNotiUserId08265156FkApplicatiInput>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileToFileId?: InputMaybe<FileProcessingNotificationFileId717B514EFkFileIdInput>;
    notificationToId?: InputMaybe<FileProcessingNotificationId630E6495FkNotificationIdInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `fileRecordingGroupMetadatum` being updated. */
export type UpdateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoPatch =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `fileRecordingGroupMetadatum` being updated. */
export type UpdateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataPatch =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `fileRecordingGroupMetadatum` being updated. */
export type UpdateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdPatch =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInput>;
    projectToProjectId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `fileRecordingGroupMetadatum` being updated. */
export type UpdateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdPatch =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInput>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `fileRecordingGroup` being updated. */
export type UpdateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRPatch =
  {
    applicationUserToUserId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInput>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `fileRecordingGroup` being updated. */
export type UpdateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdPatch =
  {
    applicationUserToUserId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `fileRecordingGroup` being updated. */
export type UpdateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdPatch =
  {
    applicationUserToUserId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `fileRecordingGroup` being updated. */
export type UpdateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdPatch =
  {
    applicationUserToUserId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `fileRecordingGroup` being updated. */
export type UpdateFileRecordingGroupOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoPatch =
  {
    applicationUserToUserId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `fileRecordingGroup` being updated. */
export type UpdateFileRecordingGroupOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoPatch =
  {
    applicationUserToUserId?: InputMaybe<FileRecordingGroupUserId421A5CddFkApplicationUserIdInput>;
    datasetRecordingsTableId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableToDatasetRecordingsTableId?: InputMaybe<FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `metadataValue` being updated. */
export type UpdateMetadataValueOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdPatch =
  {
    applicationUserToUserId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInput>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    value?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** An object where the defined keys will be set on the `metadataValue` being updated. */
export type UpdateMetadataValueOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdPatch =
  {
    applicationUserToUserId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInput>;
    projectToProjectId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInput>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    value?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** An object where the defined keys will be set on the `metadataValue` being updated. */
export type UpdateMetadataValueOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdPatch =
  {
    applicationUserToUserId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInput>;
    taskToTaskId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    value?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** An object where the defined keys will be set on the `metadataValue` being updated. */
export type UpdateMetadataValueOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdPatch =
  {
    applicationUserToUserId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInput>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInput>;
    tenantToTenantId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    value?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** An object where the defined keys will be set on the `metadataValue` being updated. */
export type UpdateMetadataValueOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdPatch =
  {
    applicationUserToUserId?: InputMaybe<MetadataValueUserIdB2Bb45AeFkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    metadataId?: InputMaybe<Scalars["UUID"]["input"]>;
    metadatumToMetadataId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInput>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInput>;
    value?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** An object where the defined keys will be set on the `metadatum` being updated. */
export type UpdateMetadatumOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdPatch =
  {
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
    displayName?: InputMaybe<Scalars["String"]["input"]>;
    fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `metadatum` being updated. */
export type UpdateMetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdPatch =
  {
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
    displayName?: InputMaybe<Scalars["String"]["input"]>;
    fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `metadatum` being updated. */
export type UpdateMetadatumOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataPatch =
  {
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
    displayName?: InputMaybe<Scalars["String"]["input"]>;
    fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `metadatum` being updated. */
export type UpdateMetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdPatch =
  {
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
    displayName?: InputMaybe<Scalars["String"]["input"]>;
    fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `metadatum` being updated. */
export type UpdateMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataPatch =
  {
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
    displayName?: InputMaybe<Scalars["String"]["input"]>;
    fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `metadatum` being updated. */
export type UpdateMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataPatch =
  {
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
    displayName?: InputMaybe<Scalars["String"]["input"]>;
    fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `metadatum` being updated. */
export type UpdateMetadatumOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdPatch =
  {
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
    displayName?: InputMaybe<Scalars["String"]["input"]>;
    fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `metadatum` being updated. */
export type UpdateMetadatumOnMetadatumForMetadataProjectId3333C2AdFkProjectIdPatch =
  {
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
    displayName?: InputMaybe<Scalars["String"]["input"]>;
    fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
    projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `metadatum` being updated. */
export type UpdateMetadatumOnMetadatumForMetadataV4TenantId163B6569FkTenantIdPatch =
  {
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTColDefId837663C9FkMetadataInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput>;
    displayName?: InputMaybe<Scalars["String"]["input"]>;
    fileMetadataUsingId?: InputMaybe<FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInput>;
    tenantToTenantId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `notification` being updated. */
export type UpdateNotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdPatch =
  {
    applicationUserToUserId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileProcessingNotificationUsingId?: InputMaybe<FileProcessingNotificationId630E6495FkNotificationIdInverseInput>;
    hasSeen?: InputMaybe<Scalars["Boolean"]["input"]>;
    projectClonedNotificationUsingId?: InputMaybe<ProjectClonedNotificationIdD235A018FkNotificationIdInverseInput>;
    projectSharedNotificationUsingId?: InputMaybe<ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInverseInput>;
    roleChangeNotificationUsingId?: InputMaybe<RoleChangeNotificationIdD576A528FkNotificationIdInverseInput>;
    tenantUserMembershipNotificationUsingId?: InputMaybe<TenantUserMembershId10Affa8DFkNotificatInverseInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `notification` being updated. */
export type UpdateNotificationOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdPatch =
  {
    applicationUserToUserId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileProcessingNotificationUsingId?: InputMaybe<FileProcessingNotificationId630E6495FkNotificationIdInverseInput>;
    hasSeen?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectClonedNotificationUsingId?: InputMaybe<ProjectClonedNotificationIdD235A018FkNotificationIdInverseInput>;
    projectSharedNotificationUsingId?: InputMaybe<ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInverseInput>;
    roleChangeNotificationUsingId?: InputMaybe<RoleChangeNotificationIdD576A528FkNotificationIdInverseInput>;
    tenantUserMembershipNotificationUsingId?: InputMaybe<TenantUserMembershId10Affa8DFkNotificatInverseInput>;
  };

/** An object where the defined keys will be set on the `notification` being updated. */
export type UpdateNotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdPatch =
  {
    applicationUserToUserId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileProcessingNotificationUsingId?: InputMaybe<FileProcessingNotificationId630E6495FkNotificationIdInverseInput>;
    hasSeen?: InputMaybe<Scalars["Boolean"]["input"]>;
    projectClonedNotificationUsingId?: InputMaybe<ProjectClonedNotificationIdD235A018FkNotificationIdInverseInput>;
    projectSharedNotificationUsingId?: InputMaybe<ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInverseInput>;
    roleChangeNotificationUsingId?: InputMaybe<RoleChangeNotificationIdD576A528FkNotificationIdInverseInput>;
    tenantUserMembershipNotificationUsingId?: InputMaybe<TenantUserMembershId10Affa8DFkNotificatInverseInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `notification` being updated. */
export type UpdateNotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdPatch =
  {
    applicationUserToUserId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileProcessingNotificationUsingId?: InputMaybe<FileProcessingNotificationId630E6495FkNotificationIdInverseInput>;
    hasSeen?: InputMaybe<Scalars["Boolean"]["input"]>;
    projectClonedNotificationUsingId?: InputMaybe<ProjectClonedNotificationIdD235A018FkNotificationIdInverseInput>;
    projectSharedNotificationUsingId?: InputMaybe<ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInverseInput>;
    roleChangeNotificationUsingId?: InputMaybe<RoleChangeNotificationIdD576A528FkNotificationIdInverseInput>;
    tenantUserMembershipNotificationUsingId?: InputMaybe<TenantUserMembershId10Affa8DFkNotificatInverseInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `notification` being updated. */
export type UpdateNotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdPatch =
  {
    applicationUserToUserId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileProcessingNotificationUsingId?: InputMaybe<FileProcessingNotificationId630E6495FkNotificationIdInverseInput>;
    hasSeen?: InputMaybe<Scalars["Boolean"]["input"]>;
    projectClonedNotificationUsingId?: InputMaybe<ProjectClonedNotificationIdD235A018FkNotificationIdInverseInput>;
    projectSharedNotificationUsingId?: InputMaybe<ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInverseInput>;
    roleChangeNotificationUsingId?: InputMaybe<RoleChangeNotificationIdD576A528FkNotificationIdInverseInput>;
    tenantUserMembershipNotificationUsingId?: InputMaybe<TenantUserMembershId10Affa8DFkNotificatInverseInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `notification` being updated. */
export type UpdateNotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatPatch =
  {
    applicationUserToUserId?: InputMaybe<NotificationUserId1002Fc38FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileProcessingNotificationUsingId?: InputMaybe<FileProcessingNotificationId630E6495FkNotificationIdInverseInput>;
    hasSeen?: InputMaybe<Scalars["Boolean"]["input"]>;
    projectClonedNotificationUsingId?: InputMaybe<ProjectClonedNotificationIdD235A018FkNotificationIdInverseInput>;
    projectSharedNotificationUsingId?: InputMaybe<ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInverseInput>;
    roleChangeNotificationUsingId?: InputMaybe<RoleChangeNotificationIdD576A528FkNotificationIdInverseInput>;
    tenantUserMembershipNotificationUsingId?: InputMaybe<TenantUserMembershId10Affa8DFkNotificatInverseInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `outputGroupFile` being updated. */
export type UpdateOutputGroupFileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdPatch =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileOutputGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupToFileOutputGroupId?: InputMaybe<OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInput>;
    fileToFileId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInput>;
  };

/** An object where the defined keys will be set on the `outputGroupFile` being updated. */
export type UpdateOutputGroupFileOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpPatch =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupToFileOutputGroupId?: InputMaybe<OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInput>;
    fileToFileId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInput>;
  };

/** An object where the defined keys will be set on the `outputGroupFile` being updated. */
export type UpdateOutputGroupFileOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdPatch =
  {
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupToFileOutputGroupId?: InputMaybe<OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInput>;
    fileToFileId?: InputMaybe<OutputGroupFilesFileIdEdeabb23FkFileIdInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInput>;
  };

/** An object where the defined keys will be set on the `projectClonedNotification` being updated. */
export type UpdateProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiPatch =
  {
    applicationUserToUserId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    notificationToId?: InputMaybe<ProjectClonedNotificationIdD235A018FkNotificationIdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInput>;
  };

/** An object where the defined keys will be set on the `projectClonedNotification` being updated. */
export type UpdateProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdPatch =
  {
    applicationUserToUserId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInput>;
    notificationToId?: InputMaybe<ProjectClonedNotificationIdD235A018FkNotificationIdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `projectClonedNotification` being updated. */
export type UpdateProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdPatch =
  {
    applicationUserToUserId?: InputMaybe<ProjectClonedNotifUserId4Fd693D7FkApplicatiInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    notificationToId?: InputMaybe<ProjectClonedNotificationIdD235A018FkNotificationIdInput>;
    projectToProjectId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnFileForFileProjectId3Ecca975FkProjectIdPatch = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
  analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
  applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
  filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
  icon?: InputMaybe<Scalars["String"]["input"]>;
  iconColor?: InputMaybe<Scalars["String"]["input"]>;
  iconText?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
  prefix?: InputMaybe<Scalars["String"]["input"]>;
  projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
  projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
  projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdPatch = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
  analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
  applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
  filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
  icon?: InputMaybe<Scalars["String"]["input"]>;
  iconColor?: InputMaybe<Scalars["String"]["input"]>;
  iconText?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
  prefix?: InputMaybe<Scalars["String"]["input"]>;
  projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
  projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
  projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
  tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdPatch =
  {
    _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
    analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
    applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
    dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
    defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
    filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
    icon?: InputMaybe<Scalars["String"]["input"]>;
    iconColor?: InputMaybe<Scalars["String"]["input"]>;
    iconText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
    projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
    projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnTaskForTaskProjectId963D6354FkProjectIdPatch = {
  _cloneSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput>;
  analysisTableRowAttachResultsUsingId?: InputMaybe<AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableProjectId709Aebc7FkProjectIdInverseInput>;
  applicationUserToUserId?: InputMaybe<ProjectUserIdB5Fbb914FkApplicationUserIdInput>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionProjectId9E468C2EFkProjectIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4ProjectId98907276FkProjectIdInverseInput>;
  dateArchived?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDataDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateDeleted?: InputMaybe<Scalars["Datetime"]["input"]>;
  defaultUserAccessLevel?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  fileActivitiesUsingId?: InputMaybe<FileActivityProjectId6F35B8CcFkProjectIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataProjectId3474994EFkProjectIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput>;
  filesUsingId?: InputMaybe<FileProjectId3Ecca975FkProjectIdInverseInput>;
  icon?: InputMaybe<Scalars["String"]["input"]>;
  iconColor?: InputMaybe<Scalars["String"]["input"]>;
  iconText?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  metadataUsingId?: InputMaybe<MetadataProjectId3333C2AdFkProjectIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueProjectId87227D96FkProjectIdInverseInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  outputGroupFilesUsingId?: InputMaybe<OutputGroupFilesProjectId8709B561FkProjectIdInverseInput>;
  prefix?: InputMaybe<Scalars["String"]["input"]>;
  projectClonedNotificationsUsingId?: InputMaybe<ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput>;
  projectSharedNotificationsUsingId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput>;
  projectToCloneSourceId?: InputMaybe<ProjectCloneSourceId5F0B62C5FkProjectIdInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  tasksUsingId?: InputMaybe<TaskProjectId963D6354FkProjectIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** An object where the defined keys will be set on the `projectSharedNotification` being updated. */
export type UpdateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiPatch =
  {
    accessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    applicationUserToSharerId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInput>;
    applicationUserToUserId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    notificationToId?: InputMaybe<ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `projectSharedNotification` being updated. */
export type UpdateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiPatch =
  {
    accessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    applicationUserToSharerId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInput>;
    applicationUserToUserId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    notificationToId?: InputMaybe<ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInput>;
    sharerId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `projectSharedNotification` being updated. */
export type UpdateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdPatch =
  {
    accessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    applicationUserToSharerId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInput>;
    applicationUserToUserId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInput>;
    notificationToId?: InputMaybe<ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInput>;
    sharerId?: InputMaybe<Scalars["UUID"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `projectSharedNotification` being updated. */
export type UpdateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdPatch =
  {
    accessLevel?: InputMaybe<Scalars["Int"]["input"]>;
    applicationUserToSharerId?: InputMaybe<ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInput>;
    applicationUserToUserId?: InputMaybe<ProjectSharedNotifUserId9A19122FFkApplicatiInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    notificationToId?: InputMaybe<ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInput>;
    projectToProjectId?: InputMaybe<ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInput>;
    sharerId?: InputMaybe<Scalars["UUID"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `recordingGroupFile` being updated. */
export type UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRPatch =
  {
    applicationUserToUserId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInput>;
    datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput>;
    fileToFileId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `recordingGroupFile` being updated. */
export type UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoPatch =
  {
    applicationUserToUserId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInput>;
    datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput>;
    fileToFileId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `recordingGroupFile` being updated. */
export type UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdPatch =
  {
    applicationUserToUserId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInput>;
    datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput>;
    fileToFileId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `recordingGroupFile` being updated. */
export type UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdPatch =
  {
    applicationUserToUserId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInput>;
    datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput>;
    fileToFileId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `recordingGroupFile` being updated. */
export type UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdPatch =
  {
    applicationUserToUserId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInput>;
    datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput>;
    fileToFileId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput>;
    tenantToTenantId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `recordingGroupFile` being updated. */
export type UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdPatch =
  {
    applicationUserToUserId?: InputMaybe<RecordingGroupFilesUserId99A36997FkApplicationUserIdInput>;
    datasetRecordingsTableColumnId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetRecordingsTableColumnToDatasetRecordingsTableColumnId?: InputMaybe<RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput>;
    dateAssignmentEnded?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileRecordingGroupToFileRecordingGroupId?: InputMaybe<RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput>;
    fileToFileId?: InputMaybe<RecordingGroupFilesFileIdD657E9EdFkFileIdInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `roleChangeNotification` being updated. */
export type UpdateRoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiPatch =
  {
    applicationUserToUserId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    notificationToId?: InputMaybe<RoleChangeNotificationIdD576A528FkNotificationIdInput>;
    role?: InputMaybe<Scalars["Int"]["input"]>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `roleChangeNotification` being updated. */
export type UpdateRoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdPatch =
  {
    applicationUserToUserId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInput>;
    notificationToId?: InputMaybe<RoleChangeNotificationIdD576A528FkNotificationIdInput>;
    role?: InputMaybe<Scalars["Int"]["input"]>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `roleChangeNotification` being updated. */
export type UpdateRoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdPatch =
  {
    applicationUserToUserId?: InputMaybe<RoleChangeNotificaUserId879Bd49AFkApplicatiInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    notificationToId?: InputMaybe<RoleChangeNotificationIdD576A528FkNotificationIdInput>;
    role?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `run` being updated. */
export type UpdateRunOnRunForRunTenantId73Fbd8D9FkTenantIdPatch = {
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInput>;
};

/** An object where the defined keys will be set on the `tag` being updated. */
export type UpdateTagOnTagForTagParentIdD2A3Ee5EFkTagIdPatch = {
  applicationUserToUserId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  parameters?: InputMaybe<Scalars["JSON"]["input"]>;
  tagToParentId?: InputMaybe<TagParentIdD2A3Ee5EFkTagIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TagTenantId8072B61FFkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdInverseInput>;
  workflowSourceTagsUsingId?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdInverseInput>;
};

/** An object where the defined keys will be set on the `tag` being updated. */
export type UpdateTagOnTagForTagTenantId8072B61FFkTenantIdPatch = {
  applicationUserToUserId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  parameters?: InputMaybe<Scalars["JSON"]["input"]>;
  parentId?: InputMaybe<Scalars["BigInt"]["input"]>;
  tagToParentId?: InputMaybe<TagParentIdD2A3Ee5EFkTagIdInput>;
  tenantToTenantId?: InputMaybe<TagTenantId8072B61FFkTenantIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdInverseInput>;
  workflowSourceTagsUsingId?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdInverseInput>;
};

/** An object where the defined keys will be set on the `tag` being updated. */
export type UpdateTagOnTagForTagUserIdB3B7Fb50FkApplicationUserIdPatch = {
  applicationUserToUserId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInput>;
  dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  parameters?: InputMaybe<Scalars["JSON"]["input"]>;
  parentId?: InputMaybe<Scalars["BigInt"]["input"]>;
  tagToParentId?: InputMaybe<TagParentIdD2A3Ee5EFkTagIdInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TagTenantId8072B61FFkTenantIdInput>;
  workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdInverseInput>;
  workflowSourceTagsUsingId?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdInverseInput>;
};

/** An object where the defined keys will be set on the `tag` being updated. */
export type UpdateTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdPatch =
  {
    applicationUserToUserId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    label?: InputMaybe<Scalars["String"]["input"]>;
    parameters?: InputMaybe<Scalars["JSON"]["input"]>;
    parentId?: InputMaybe<Scalars["BigInt"]["input"]>;
    tagToParentId?: InputMaybe<TagParentIdD2A3Ee5EFkTagIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<TagTenantId8072B61FFkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdInverseInput>;
    workflowSourceTagsUsingId?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tag` being updated. */
export type UpdateTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdPatch =
  {
    applicationUserToUserId?: InputMaybe<TagUserIdB3B7Fb50FkApplicationUserIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["BigInt"]["input"]>;
    label?: InputMaybe<Scalars["String"]["input"]>;
    parameters?: InputMaybe<Scalars["JSON"]["input"]>;
    parentId?: InputMaybe<Scalars["BigInt"]["input"]>;
    tagToParentId?: InputMaybe<TagParentIdD2A3Ee5EFkTagIdInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<TagTenantId8072B61FFkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdInverseInput>;
    workflowSourceTagsUsingId?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdInverseInput>;
  };

/** An object where the defined keys will be set on the `taskActivity` being updated. */
export type UpdateTaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdPatch =
  {
    cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
    costToCostId?: InputMaybe<TaskActivityCostId49E66058FkCostIdInput>;
    date?: InputMaybe<Scalars["Datetime"]["input"]>;
    duration?: InputMaybe<IntervalInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    instanceType?: InputMaybe<Scalars["String"]["input"]>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInput>;
  };

/** An object where the defined keys will be set on the `taskActivity` being updated. */
export type UpdateTaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdPatch =
  {
    cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
    costId?: InputMaybe<Scalars["BigInt"]["input"]>;
    costToCostId?: InputMaybe<TaskActivityCostId49E66058FkCostIdInput>;
    date?: InputMaybe<Scalars["Datetime"]["input"]>;
    duration?: InputMaybe<IntervalInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    instanceType?: InputMaybe<Scalars["String"]["input"]>;
    taskToTaskId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInput>;
  };

/** An object where the defined keys will be set on the `task` being updated. */
export type UpdateTaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdPatch =
  {
    analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
    applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
    cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
    containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
    containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
    errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
    executorId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    inputsHash?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
    taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
    taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
    taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
    taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
    tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
    toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
    toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `task` being updated. */
export type UpdateTaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdPatch =
  {
    analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
    applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
    cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
    containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
    containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
    errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
    executorId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    inputsHash?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
    taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
    taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
    taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
    taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
    tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
    toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
    toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `task` being updated. */
export type UpdateTaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdPatch =
  {
    analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
    applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
    cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
    containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
    containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
    errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
    executorId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    inputsHash?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
    taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
    taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
    taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
    taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
    tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
    toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
    toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `task` being updated. */
export type UpdateTaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdPatch =
  {
    analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
    applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
    cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
    containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
    containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
    errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
    executorId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    inputsHash?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
    taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
    taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
    taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
    taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
    tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
    toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
    toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `task` being updated. */
export type UpdateTaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdPatch =
  {
    analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
    applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
    cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
    containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
    errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
    executorId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    inputsHash?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
    taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
    taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
    taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
    taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
    tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
    toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
    toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `task` being updated. */
export type UpdateTaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdPatch = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  inputsHash?: InputMaybe<Scalars["String"]["input"]>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** An object where the defined keys will be set on the `task` being updated. */
export type UpdateTaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdPatch =
  {
    analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
    applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
    cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
    containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
    containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
    errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
    fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    inputsHash?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
    taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
    taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
    taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
    taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
    tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
    toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
    toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `task` being updated. */
export type UpdateTaskOnTaskForTaskProjectId963D6354FkProjectIdPatch = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  inputsHash?: InputMaybe<Scalars["String"]["input"]>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** An object where the defined keys will be set on the `task` being updated. */
export type UpdateTaskOnTaskForTaskTenantId148E90DaFkTenantIdPatch = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  inputsHash?: InputMaybe<Scalars["String"]["input"]>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** An object where the defined keys will be set on the `task` being updated. */
export type UpdateTaskOnTaskForTaskToolVersionId14506837FkToolVersionIdPatch = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  inputsHash?: InputMaybe<Scalars["String"]["input"]>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** An object where the defined keys will be set on the `task` being updated. */
export type UpdateTaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdPatch = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  inputsHash?: InputMaybe<Scalars["String"]["input"]>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
};

/** An object where the defined keys will be set on the `task` being updated. */
export type UpdateTaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdPatch =
  {
    analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
    applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
    cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
    containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
    containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
    errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
    executorId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    inputsHash?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
    taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
    taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
    taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
    taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
    tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
    toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
    toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `task` being updated. */
export type UpdateTaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdPatch = {
  analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
  applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
  cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
  containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
  containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  credits?: InputMaybe<Scalars["Float"]["input"]>;
  datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
  datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
  errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  executorId?: InputMaybe<Scalars["UUID"]["input"]>;
  fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  inputsHash?: InputMaybe<Scalars["String"]["input"]>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
  projectId?: InputMaybe<Scalars["UUID"]["input"]>;
  projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
  taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
  taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
  taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
  taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
  tenantId?: InputMaybe<Scalars["Int"]["input"]>;
  tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
  tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
  toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
  toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
  toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
  toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** An object where the defined keys will be set on the `task` being updated. */
export type UpdateTaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdPatch =
  {
    analysisTableRowUsingId?: InputMaybe<AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput>;
    applicationUserToUserId?: InputMaybe<TaskUserId270D0Bb2FkApplicationUserIdInput>;
    cloned?: InputMaybe<Scalars["Boolean"]["input"]>;
    containerImageId?: InputMaybe<Scalars["Int"]["input"]>;
    containerImageToContainerImageId?: InputMaybe<TaskContainerImageId92Ed2DaeFkContainerImageIdInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    datasetId?: InputMaybe<Scalars["UUID"]["input"]>;
    datasetToDatasetId?: InputMaybe<TaskDatasetIdE15Afa5AFkDatasetIdInput>;
    errorDescription?: InputMaybe<Scalars["JSON"]["input"]>;
    executorId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileOutputGroupUsingId?: InputMaybe<FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    inputsHash?: InputMaybe<Scalars["String"]["input"]>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTaskId3D37D135FkTaskIdInverseInput>;
    projectId?: InputMaybe<Scalars["UUID"]["input"]>;
    projectToProjectId?: InputMaybe<TaskProjectId963D6354FkProjectIdInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    taskActivityUsingId?: InputMaybe<TaskActivityTaskIdC451Ae92FkTaskIdInverseInput>;
    taskParameters?: InputMaybe<Scalars["JSON"]["input"]>;
    taskResourcesUsingId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInverseInput>;
    taskSourcesUsingId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput>;
    taskStatusLogsUsingId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInput>;
    tesTaskexecutorToExecutorId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInput>;
    toolExitcode?: InputMaybe<Scalars["String"]["input"]>;
    toolExitcodeDescription?: InputMaybe<Scalars["JSON"]["input"]>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `taskResource` being updated. */
export type UpdateTaskResourceOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    taskToTaskId?: InputMaybe<TaskResourceTaskId73Fcabc0FkTaskIdInput>;
    type?: InputMaybe<Scalars["Int"]["input"]>;
    value?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `taskSource` being updated. */
export type UpdateTaskSourceOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdPatch =
  {
    fileToFileId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInput>;
    toolSourceId?: InputMaybe<Scalars["Int"]["input"]>;
    toolSourceToToolSourceId?: InputMaybe<TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInput>;
  };

/** An object where the defined keys will be set on the `taskSource` being updated. */
export type UpdateTaskSourceOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdPatch =
  {
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileToFileId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    taskToTaskId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInput>;
    toolSourceId?: InputMaybe<Scalars["Int"]["input"]>;
    toolSourceToToolSourceId?: InputMaybe<TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInput>;
  };

/** An object where the defined keys will be set on the `taskSource` being updated. */
export type UpdateTaskSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdPatch =
  {
    fileId?: InputMaybe<Scalars["UUID"]["input"]>;
    fileToFileId?: InputMaybe<TaskSourceV4FileId3A4723EfFkFileIdInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    taskId?: InputMaybe<Scalars["UUID"]["input"]>;
    taskToTaskId?: InputMaybe<TaskSourceV4TaskId059F1B6BFkTaskIdInput>;
    toolSourceToToolSourceId?: InputMaybe<TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInput>;
  };

/** An object where the defined keys will be set on the `taskStatusLog` being updated. */
export type UpdateTaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdPatch =
  {
    date?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    taskToTaskId?: InputMaybe<TaskStatusLogTaskIdFe1B9D0BFkTaskIdInput>;
  };

/** An object where the defined keys will be set on the `tenantContractAllowance` being updated. */
export type UpdateTenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    quotaType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantContractTransactionToTenantContractTransactionId?: InputMaybe<TenantContractAlloTenantContractTran29C19B1CFkTenantCoInput>;
    value?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `tenantContract` being updated. */
export type UpdateTenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdPatch =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    endDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    startDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    tenantContractTransactionsUsingId?: InputMaybe<TenantContractTranTenantContractId1E374993FkTenantCoInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantContractId681267CeFkTenantCoInverseInput>;
    tenantToTenantId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `tenantContract` being updated. */
export type UpdateTenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoPatch =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    endDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    startDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    tenantContractTransactionsUsingId?: InputMaybe<TenantContractTranTenantContractId1E374993FkTenantCoInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantContractId681267CeFkTenantCoInverseInput>;
    tenantToTenantId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `tenantContract` being updated. */
export type UpdateTenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoPatch =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    endDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    startDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    tenantContractTransactionsUsingId?: InputMaybe<TenantContractTranTenantContractId1E374993FkTenantCoInverseInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantContractId681267CeFkTenantCoInverseInput>;
    tenantToTenantId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `tenantContractTransaction` being updated. */
export type UpdateTenantContractTransactionOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoPatch =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    currency?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    price?: InputMaybe<Scalars["BigFloat"]["input"]>;
    tenantContractAllowancesUsingId?: InputMaybe<TenantContractAlloTenantContractTran29C19B1CFkTenantCoInverseInput>;
    tenantContractId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantContractToTenantContractId?: InputMaybe<TenantContractTranTenantContractId1E374993FkTenantCoInput>;
  };

/** An object where the defined keys will be set on the `tenantContractTransaction` being updated. */
export type UpdateTenantContractTransactionOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoPatch =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    currency?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    price?: InputMaybe<Scalars["BigFloat"]["input"]>;
    tenantContractAllowancesUsingId?: InputMaybe<TenantContractAlloTenantContractTran29C19B1CFkTenantCoInverseInput>;
    tenantContractToTenantContractId?: InputMaybe<TenantContractTranTenantContractId1E374993FkTenantCoInput>;
  };

/** An object where the defined keys will be set on the `tenantMockuserexecutor` being updated. */
export type UpdateTenantMockuserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsPatch =
  {
    tenantUserexecutorToUserexecutorPtrId?: InputMaybe<TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInput>;
  };

/** An object where the defined keys will be set on the `tenantMonthlyConsumedResource` being updated. */
export type UpdateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoPatch =
  {
    applicationUserToUserId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    month?: InputMaybe<Scalars["Date"]["input"]>;
    monthInActiveContract?: InputMaybe<Scalars["Int"]["input"]>;
    percentage?: InputMaybe<Scalars["Float"]["input"]>;
    quotaType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantContractToTenantContractId?: InputMaybe<TenantMonthlyConsuTenantContractId681267CeFkTenantCoInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    value?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `tenantMonthlyConsumedResource` being updated. */
export type UpdateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdPatch =
  {
    applicationUserToUserId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    month?: InputMaybe<Scalars["Date"]["input"]>;
    monthInActiveContract?: InputMaybe<Scalars["Int"]["input"]>;
    percentage?: InputMaybe<Scalars["Float"]["input"]>;
    quotaType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantContractId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantContractToTenantContractId?: InputMaybe<TenantMonthlyConsuTenantContractId681267CeFkTenantCoInput>;
    tenantToTenantId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    value?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `tenantMonthlyConsumedResource` being updated. */
export type UpdateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiPatch =
  {
    applicationUserToUserId?: InputMaybe<TenantMonthlyConsuUserIdF7210F55FkApplicatiInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    month?: InputMaybe<Scalars["Date"]["input"]>;
    monthInActiveContract?: InputMaybe<Scalars["Int"]["input"]>;
    percentage?: InputMaybe<Scalars["Float"]["input"]>;
    quotaType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantContractId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantContractToTenantContractId?: InputMaybe<TenantMonthlyConsuTenantContractId681267CeFkTenantCoInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInput>;
    value?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnCostForCostTenantId7Dcd58A7FkTenantIdPatch = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType?: InputMaybe<Scalars["Int"]["input"]>;
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdPatch = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType?: InputMaybe<Scalars["Int"]["input"]>;
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnFileForFileTenantId61625016FkTenantIdPatch = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType?: InputMaybe<Scalars["Int"]["input"]>;
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnProjectForProjectTenantId03F0Ca53FkTenantIdPatch = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType?: InputMaybe<Scalars["Int"]["input"]>;
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnRunForRunTenantId73Fbd8D9FkTenantIdPatch = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType?: InputMaybe<Scalars["Int"]["input"]>;
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnTagForTagTenantId8072B61FFkTenantIdPatch = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType?: InputMaybe<Scalars["Int"]["input"]>;
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnTaskForTaskTenantId148E90DaFkTenantIdPatch = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType?: InputMaybe<Scalars["Int"]["input"]>;
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
    applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
    applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
    costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
    datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
    datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
    datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
    datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
    datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
    fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
    fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
    fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
    fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
    fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
    fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
    filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    internal?: InputMaybe<Scalars["Boolean"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
    metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
    recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
    roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
    runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
    status?: InputMaybe<Scalars["Int"]["input"]>;
    tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
    tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
    tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
    tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
    tenantType?: InputMaybe<Scalars["Int"]["input"]>;
    tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
    workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tenant` being updated. */
export type UpdateTenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdPatch = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput>;
  analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput>;
  analysisTablesUsingId?: InputMaybe<AnalysisTableTenantIdF297C671FkTenantIdInverseInput>;
  applicationUserMembershipsUsingId?: InputMaybe<ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput>;
  applicationUserSessionsUsingId?: InputMaybe<ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput>;
  costsUsingId?: InputMaybe<CostTenantId7Dcd58A7FkTenantIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  datasetMetadataUsingId?: InputMaybe<DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput>;
  datasetRecordingsTableColumnOrdersUsingId?: InputMaybe<DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput>;
  datasetRecordingsTableColumnsUsingId?: InputMaybe<DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput>;
  datasetRecordingsTablesUsingId?: InputMaybe<DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput>;
  datasetVersionsUsingId?: InputMaybe<DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput>;
  datasetsUsingId?: InputMaybe<DatasetV4TenantId7055D1F4FkTenantIdInverseInput>;
  fileActivitiesUsingId?: InputMaybe<FileActivityTenantIdC46A4F01FkTenantIdInverseInput>;
  fileMetadataUsingId?: InputMaybe<FileMetadataTenantId79Fe438BFkTenantIdInverseInput>;
  fileOutputGroupMetadataUsingId?: InputMaybe<FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput>;
  fileOutputGroupsUsingId?: InputMaybe<FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput>;
  fileRecordingGroupMetadataUsingId?: InputMaybe<FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput>;
  fileRecordingGroupsUsingId?: InputMaybe<FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput>;
  filesUsingId?: InputMaybe<FileTenantId61625016FkTenantIdInverseInput>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  internal?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  metadataUsingId?: InputMaybe<MetadataV4TenantId163B6569FkTenantIdInverseInput>;
  metadataValuesUsingId?: InputMaybe<MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  projectsUsingId?: InputMaybe<ProjectTenantId03F0Ca53FkTenantIdInverseInput>;
  recordingGroupFilesUsingId?: InputMaybe<RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput>;
  roleChangeNotificationsUsingId?: InputMaybe<RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput>;
  runsUsingId?: InputMaybe<RunTenantId73Fbd8D9FkTenantIdInverseInput>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  tagsUsingId?: InputMaybe<TagTenantId8072B61FFkTenantIdInverseInput>;
  tasksUsingId?: InputMaybe<TaskTenantId148E90DaFkTenantIdInverseInput>;
  tenantContractsUsingId?: InputMaybe<TenantContractTenantIdBa8Ac167FkTenantIdInverseInput>;
  tenantMonthlyConsumedResourcesUsingId?: InputMaybe<TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput>;
  tenantType?: InputMaybe<Scalars["Int"]["input"]>;
  tenantUserMembershipNotificationsUsingId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput>;
  toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput>;
  workflowsUsingId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInverseInput>;
};

/** An object where the defined keys will be set on the `tenantUserMembershipNotification` being updated. */
export type UpdateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatPatch =
  {
    applicationUserToInviterId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInput>;
    applicationUserToUserId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInput>;
    inviterId?: InputMaybe<Scalars["UUID"]["input"]>;
    notificationToId?: InputMaybe<TenantUserMembershId10Affa8DFkNotificatInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `tenantUserMembershipNotification` being updated. */
export type UpdateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiPatch =
  {
    applicationUserToInviterId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInput>;
    applicationUserToUserId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    notificationToId?: InputMaybe<TenantUserMembershId10Affa8DFkNotificatInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `tenantUserMembershipNotification` being updated. */
export type UpdateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdPatch =
  {
    applicationUserToInviterId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInput>;
    applicationUserToUserId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    inviterId?: InputMaybe<Scalars["UUID"]["input"]>;
    notificationToId?: InputMaybe<TenantUserMembershId10Affa8DFkNotificatInput>;
    tenantToTenantId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `tenantUserMembershipNotification` being updated. */
export type UpdateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiPatch =
  {
    applicationUserToInviterId?: InputMaybe<TenantUserMembershInviterId29476E5EFkApplicatiInput>;
    applicationUserToUserId?: InputMaybe<TenantUserMembershUserId2B78E8A0FkApplicatiInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    inviterId?: InputMaybe<Scalars["UUID"]["input"]>;
    notificationToId?: InputMaybe<TenantUserMembershId10Affa8DFkNotificatInput>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<TenantUserMembershTenantIdC67818C5FkTenantIdInput>;
  };

/** An object where the defined keys will be set on the `tenantUserexecutor` being updated. */
export type UpdateTenantUserexecutorOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsPatch =
  {
    applicationUserUsingId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInverseInput>;
    awsCognitoUserUsingId?: InputMaybe<AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantMockuserexecutorUsingId?: InputMaybe<TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInverseInput>;
  };

/** An object where the defined keys will be set on the `tenantUserexecutor` being updated. */
export type UpdateTenantUserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsPatch =
  {
    applicationUserUsingId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInverseInput>;
    awsCognitoUserUsingId?: InputMaybe<AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantMockuserexecutorUsingId?: InputMaybe<TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInverseInput>;
  };

/** An object where the defined keys will be set on the `tenantUserexecutor` being updated. */
export type UpdateTenantUserexecutorOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoPatch =
  {
    applicationUserUsingId?: InputMaybe<ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInverseInput>;
    awsCognitoUserUsingId?: InputMaybe<AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    tenantMockuserexecutorUsingId?: InputMaybe<TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInverseInput>;
  };

/** An object where the defined keys will be set on the `tesContainerimageexecutor` being updated. */
export type UpdateTesContainerimageexecutorOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaPatch =
  {
    awsEcrImageUsingId?: InputMaybe<AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInverseInput>;
    containerImageUsingId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
    tesMockcontainerimageexecutorUsingId?: InputMaybe<TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInverseInput>;
  };

/** An object where the defined keys will be set on the `tesContainerimageexecutor` being updated. */
export type UpdateTesContainerimageexecutorOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaPatch =
  {
    awsEcrImageUsingId?: InputMaybe<AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInverseInput>;
    containerImageUsingId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
    tesMockcontainerimageexecutorUsingId?: InputMaybe<TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInverseInput>;
  };

/** An object where the defined keys will be set on the `tesContainerimageexecutor` being updated. */
export type UpdateTesContainerimageexecutorOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoPatch =
  {
    awsEcrImageUsingId?: InputMaybe<AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInverseInput>;
    containerImageUsingId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    tesMockcontainerimageexecutorUsingId?: InputMaybe<TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInverseInput>;
  };

/** An object where the defined keys will be set on the `tesContainerimageexecutor` being updated. */
export type UpdateTesContainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaPatch =
  {
    awsEcrImageUsingId?: InputMaybe<AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInverseInput>;
    containerImageUsingId?: InputMaybe<ContainerImageExecutorId68979Fb6FkTesContaInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInput>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
    tesMockcontainerimageexecutorUsingId?: InputMaybe<TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInverseInput>;
  };

/** An object where the defined keys will be set on the `tesMockcontainerimageexecutor` being updated. */
export type UpdateTesMockcontainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaPatch =
  {
    tesContainerimageexecutorToContainerimageexecutorPtrId?: InputMaybe<TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInput>;
  };

/** An object where the defined keys will be set on the `tesMocktaskexecutor` being updated. */
export type UpdateTesMocktaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskePatch =
  {
    tesTaskexecutorToTaskexecutorPtrId?: InputMaybe<TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInput>;
  };

/** An object where the defined keys will be set on the `tesTaskexecutor` being updated. */
export type UpdateTesTaskexecutorOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskePatch =
  {
    awsBatchTaskUsingId?: InputMaybe<AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
    taskUsingId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInverseInput>;
    tesMocktaskexecutorUsingId?: InputMaybe<TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInverseInput>;
  };

/** An object where the defined keys will be set on the `tesTaskexecutor` being updated. */
export type UpdateTesTaskexecutorOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdPatch =
  {
    awsBatchTaskUsingId?: InputMaybe<AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
    taskUsingId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInverseInput>;
    tesMocktaskexecutorUsingId?: InputMaybe<TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInverseInput>;
  };

/** An object where the defined keys will be set on the `tesTaskexecutor` being updated. */
export type UpdateTesTaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskePatch =
  {
    awsBatchTaskUsingId?: InputMaybe<AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    polymorphicCtypeId?: InputMaybe<Scalars["Int"]["input"]>;
    taskUsingId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInverseInput>;
    tesMocktaskexecutorUsingId?: InputMaybe<TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInverseInput>;
  };

/** An object where the defined keys will be set on the `tesTaskexecutor` being updated. */
export type UpdateTesTaskexecutorOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoPatch =
  {
    awsBatchTaskUsingId?: InputMaybe<AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInverseInput>;
    djangoContentTypeToPolymorphicCtypeId?: InputMaybe<TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    taskUsingId?: InputMaybe<TaskExecutorId24A67329FkTesTaskexecutorIdInverseInput>;
    tesMocktaskexecutorUsingId?: InputMaybe<TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInverseInput>;
  };

/** An object where the defined keys will be set on the `toolCategory` being updated. */
export type UpdateToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCatePatch =
  {
    description?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<Scalars["Int"]["input"]>;
    toolDisplayCategoriesUsingId?: InputMaybe<ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInverseInput>;
  };

/** An object where the defined keys will be set on the `toolDisplayCategory` being updated. */
export type UpdateToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCatePatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    toolCategoryToToolcategoryId?: InputMaybe<ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolToToolId?: InputMaybe<ToolDisplayCategoriesToolIdAdc582D3FkToolIdInput>;
  };

/** An object where the defined keys will be set on the `toolDisplayCategory` being updated. */
export type UpdateToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    toolCategoryToToolcategoryId?: InputMaybe<ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInput>;
    toolToToolId?: InputMaybe<ToolDisplayCategoriesToolIdAdc582D3FkToolIdInput>;
    toolcategoryId?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `tool` being updated. */
export type UpdateToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    helpText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    searchTerms?: InputMaybe<Scalars["JSON"]["input"]>;
    toolDisplayCategoriesUsingId?: InputMaybe<ToolDisplayCategoriesToolIdAdc582D3FkToolIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsToolIdF77Ef6F7FkToolIdInverseInput>;
    toolVersionsUsingId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tool` being updated. */
export type UpdateToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdPatch =
  {
    analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    helpText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    searchTerms?: InputMaybe<Scalars["JSON"]["input"]>;
    toolDisplayCategoriesUsingId?: InputMaybe<ToolDisplayCategoriesToolIdAdc582D3FkToolIdInverseInput>;
    toolTenantsUsingId?: InputMaybe<ToolTenantsToolIdF77Ef6F7FkToolIdInverseInput>;
    toolVersionsUsingId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInverseInput>;
  };

/** An object where the defined keys will be set on the `tool` being updated. */
export type UpdateToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdPatch = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  helpText?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  searchTerms?: InputMaybe<Scalars["JSON"]["input"]>;
  toolDisplayCategoriesUsingId?: InputMaybe<ToolDisplayCategoriesToolIdAdc582D3FkToolIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsToolIdF77Ef6F7FkToolIdInverseInput>;
  toolVersionsUsingId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInverseInput>;
};

/** An object where the defined keys will be set on the `tool` being updated. */
export type UpdateToolOnToolVersionForToolVersionToolId3B396B22FkToolIdPatch = {
  analysisTableGroupsUsingId?: InputMaybe<AnalysisTableGroupToolIdB122F1CcFkToolIdInverseInput>;
  created?: InputMaybe<Scalars["Datetime"]["input"]>;
  helpText?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  searchTerms?: InputMaybe<Scalars["JSON"]["input"]>;
  toolDisplayCategoriesUsingId?: InputMaybe<ToolDisplayCategoriesToolIdAdc582D3FkToolIdInverseInput>;
  toolTenantsUsingId?: InputMaybe<ToolTenantsToolIdF77Ef6F7FkToolIdInverseInput>;
  toolVersionsUsingId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInverseInput>;
};

/** An object where the defined keys will be set on the `toolParameter` being updated. */
export type UpdateToolParameterOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdPatch =
  {
    default?: InputMaybe<Scalars["JSON"]["input"]>;
    helpText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    options?: InputMaybe<Scalars["JSON"]["input"]>;
    required?: InputMaybe<Scalars["Boolean"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInput>;
    type?: InputMaybe<Scalars["Int"]["input"]>;
    unit?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `toolResource` being updated. */
export type UpdateToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInput>;
    type?: InputMaybe<Scalars["Int"]["input"]>;
    value?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `toolResult` being updated. */
export type UpdateToolResultOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdPatch =
  {
    fileFormats?: InputMaybe<Scalars["JSON"]["input"]>;
    helpText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    required?: InputMaybe<Scalars["Boolean"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInput>;
  };

/** An object where the defined keys will be set on the `toolSecret` being updated. */
export type UpdateToolSecretOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdPatch =
  {
    helpText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    required?: InputMaybe<Scalars["Boolean"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInput>;
  };

/** An object where the defined keys will be set on the `toolSource` being updated. */
export type UpdateToolSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdPatch =
  {
    fileFormats?: InputMaybe<Scalars["JSON"]["input"]>;
    helpText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    required?: InputMaybe<Scalars["Boolean"]["input"]>;
    taskSourcesUsingId?: InputMaybe<TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInverseInput>;
    toolVersionId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolVersionId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInput>;
  };

/** An object where the defined keys will be set on the `toolSource` being updated. */
export type UpdateToolSourceOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdPatch =
  {
    fileFormats?: InputMaybe<Scalars["JSON"]["input"]>;
    helpText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    required?: InputMaybe<Scalars["Boolean"]["input"]>;
    taskSourcesUsingId?: InputMaybe<TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInverseInput>;
    toolVersionToToolVersionId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInput>;
  };

/** An object where the defined keys will be set on the `toolTenant` being updated. */
export type UpdateToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolToToolId?: InputMaybe<ToolTenantsToolIdF77Ef6F7FkToolIdInput>;
  };

/** An object where the defined keys will be set on the `toolTenant` being updated. */
export type UpdateToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ToolTenantsTenantId9C4A54FeFkTenantIdInput>;
    toolToToolId?: InputMaybe<ToolTenantsToolIdF77Ef6F7FkToolIdInput>;
  };

/** An object where the defined keys will be set on the `toolVersion` being updated. */
export type UpdateToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdPatch =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible?: InputMaybe<Scalars["Boolean"]["input"]>;
    isUpgradableToNextVersion?: InputMaybe<Scalars["Boolean"]["input"]>;
    maturity?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version?: InputMaybe<Scalars["String"]["input"]>;
    visibility?: InputMaybe<Scalars["Int"]["input"]>;
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** An object where the defined keys will be set on the `toolVersion` being updated. */
export type UpdateToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdPatch =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible?: InputMaybe<Scalars["Boolean"]["input"]>;
    isUpgradableToNextVersion?: InputMaybe<Scalars["Boolean"]["input"]>;
    maturity?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version?: InputMaybe<Scalars["String"]["input"]>;
    visibility?: InputMaybe<Scalars["Int"]["input"]>;
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** An object where the defined keys will be set on the `toolVersion` being updated. */
export type UpdateToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdPatch =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible?: InputMaybe<Scalars["Boolean"]["input"]>;
    isUpgradableToNextVersion?: InputMaybe<Scalars["Boolean"]["input"]>;
    maturity?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version?: InputMaybe<Scalars["String"]["input"]>;
    visibility?: InputMaybe<Scalars["Int"]["input"]>;
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** An object where the defined keys will be set on the `toolVersion` being updated. */
export type UpdateToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdPatch =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible?: InputMaybe<Scalars["Boolean"]["input"]>;
    isUpgradableToNextVersion?: InputMaybe<Scalars["Boolean"]["input"]>;
    maturity?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version?: InputMaybe<Scalars["String"]["input"]>;
    visibility?: InputMaybe<Scalars["Int"]["input"]>;
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** An object where the defined keys will be set on the `toolVersion` being updated. */
export type UpdateToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdPatch =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible?: InputMaybe<Scalars["Boolean"]["input"]>;
    isUpgradableToNextVersion?: InputMaybe<Scalars["Boolean"]["input"]>;
    maturity?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version?: InputMaybe<Scalars["String"]["input"]>;
    visibility?: InputMaybe<Scalars["Int"]["input"]>;
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** An object where the defined keys will be set on the `toolVersion` being updated. */
export type UpdateToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdPatch =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible?: InputMaybe<Scalars["Boolean"]["input"]>;
    isUpgradableToNextVersion?: InputMaybe<Scalars["Boolean"]["input"]>;
    maturity?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version?: InputMaybe<Scalars["String"]["input"]>;
    visibility?: InputMaybe<Scalars["Int"]["input"]>;
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** An object where the defined keys will be set on the `toolVersion` being updated. */
export type UpdateToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdPatch =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible?: InputMaybe<Scalars["Boolean"]["input"]>;
    isUpgradableToNextVersion?: InputMaybe<Scalars["Boolean"]["input"]>;
    maturity?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version?: InputMaybe<Scalars["String"]["input"]>;
    visibility?: InputMaybe<Scalars["Int"]["input"]>;
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** An object where the defined keys will be set on the `toolVersion` being updated. */
export type UpdateToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdPatch =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible?: InputMaybe<Scalars["Boolean"]["input"]>;
    isUpgradableToNextVersion?: InputMaybe<Scalars["Boolean"]["input"]>;
    maturity?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version?: InputMaybe<Scalars["String"]["input"]>;
    visibility?: InputMaybe<Scalars["Int"]["input"]>;
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** An object where the defined keys will be set on the `toolVersion` being updated. */
export type UpdateToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdPatch =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible?: InputMaybe<Scalars["Boolean"]["input"]>;
    isUpgradableToNextVersion?: InputMaybe<Scalars["Boolean"]["input"]>;
    maturity?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version?: InputMaybe<Scalars["String"]["input"]>;
    visibility?: InputMaybe<Scalars["Int"]["input"]>;
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** An object where the defined keys will be set on the `toolVersion` being updated. */
export type UpdateToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersPatch =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible?: InputMaybe<Scalars["Boolean"]["input"]>;
    isUpgradableToNextVersion?: InputMaybe<Scalars["Boolean"]["input"]>;
    maturity?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version?: InputMaybe<Scalars["String"]["input"]>;
    visibility?: InputMaybe<Scalars["Int"]["input"]>;
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** An object where the defined keys will be set on the `toolVersion` being updated. */
export type UpdateToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdPatch =
  {
    analysisTableRowsUsingId?: InputMaybe<AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput>;
    analysisTablesUsingId?: InputMaybe<AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput>;
    command?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    credits?: InputMaybe<Scalars["Float"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    isToolSpecForwardsCompatible?: InputMaybe<Scalars["Boolean"]["input"]>;
    isUpgradableToNextVersion?: InputMaybe<Scalars["Boolean"]["input"]>;
    maturity?: InputMaybe<Scalars["Int"]["input"]>;
    tasksUsingId?: InputMaybe<TaskToolVersionId14506837FkToolVersionIdInverseInput>;
    toolParametersUsingId?: InputMaybe<ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput>;
    toolResourcesUsingId?: InputMaybe<ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput>;
    toolResultsUsingId?: InputMaybe<ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput>;
    toolSecretsUsingId?: InputMaybe<ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput>;
    toolSourcesUsingId?: InputMaybe<ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput>;
    toolSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolToToolId?: InputMaybe<ToolVersionToolId3B396B22FkToolIdInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput>;
    version?: InputMaybe<Scalars["String"]["input"]>;
    visibility?: InputMaybe<Scalars["Int"]["input"]>;
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput>;
  };

/** An object where the defined keys will be set on the `toolboxExitcode` being updated. */
export type UpdateToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEPatch =
  {
    code?: InputMaybe<Scalars["String"]["input"]>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxVersionExitcodesUsingId?: InputMaybe<ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInverseInput>;
    type?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `toolbox` being updated. */
export type UpdateToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdPatch =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsToolboxId9D553881FkToolboxIdInverseInput>;
    toolboxVersionsUsingId?: InputMaybe<ToolboxVersionToolboxId5Cc86066FkToolboxIdInverseInput>;
  };

/** An object where the defined keys will be set on the `toolbox` being updated. */
export type UpdateToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdPatch =
  {
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    key?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    toolboxTenantsUsingId?: InputMaybe<ToolboxTenantsToolboxId9D553881FkToolboxIdInverseInput>;
    toolboxVersionsUsingId?: InputMaybe<ToolboxVersionToolboxId5Cc86066FkToolboxIdInverseInput>;
  };

/** An object where the defined keys will be set on the `toolboxTenant` being updated. */
export type UpdateToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInput>;
    toolboxId?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxToToolboxId?: InputMaybe<ToolboxTenantsToolboxId9D553881FkToolboxIdInput>;
  };

/** An object where the defined keys will be set on the `toolboxTenant` being updated. */
export type UpdateToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<ToolboxTenantsTenantId5D0A40E4FkTenantIdInput>;
    toolboxToToolboxId?: InputMaybe<ToolboxTenantsToolboxId9D553881FkToolboxIdInput>;
  };

/** An object where the defined keys will be set on the `toolboxVersionExitcode` being updated. */
export type UpdateToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxExitcodeToToolboxexitcodeId?: InputMaybe<ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInput>;
    toolboxVersionToToolboxversionId?: InputMaybe<ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInput>;
    toolboxversionId?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `toolboxVersionExitcode` being updated. */
export type UpdateToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxExitcodeToToolboxexitcodeId?: InputMaybe<ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInput>;
    toolboxVersionToToolboxversionId?: InputMaybe<ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInput>;
    toolboxexitcodeId?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `toolboxVersion` being updated. */
export type UpdateToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVPatch =
  {
    containerImageUsingId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    maturity?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxId?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolboxSpecV1?: InputMaybe<Scalars["JSON"]["input"]>;
    toolboxToToolboxId?: InputMaybe<ToolboxVersionToolboxId5Cc86066FkToolboxIdInput>;
    toolboxVersionExitcodesUsingId?: InputMaybe<ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInverseInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInverseInput>;
    version?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `toolboxVersion` being updated. */
export type UpdateToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVPatch =
  {
    containerImageUsingId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    maturity?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxId?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolboxSpecV1?: InputMaybe<Scalars["JSON"]["input"]>;
    toolboxToToolboxId?: InputMaybe<ToolboxVersionToolboxId5Cc86066FkToolboxIdInput>;
    toolboxVersionExitcodesUsingId?: InputMaybe<ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInverseInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInverseInput>;
    version?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `toolboxVersion` being updated. */
export type UpdateToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdPatch =
  {
    containerImageUsingId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    maturity?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolboxSpecV1?: InputMaybe<Scalars["JSON"]["input"]>;
    toolboxToToolboxId?: InputMaybe<ToolboxVersionToolboxId5Cc86066FkToolboxIdInput>;
    toolboxVersionExitcodesUsingId?: InputMaybe<ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInverseInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInverseInput>;
    version?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `toolboxVersion` being updated. */
export type UpdateToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVPatch =
  {
    containerImageUsingId?: InputMaybe<ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInverseInput>;
    created?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    maturity?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxId?: InputMaybe<Scalars["Int"]["input"]>;
    toolboxSpec?: InputMaybe<Scalars["JSON"]["input"]>;
    toolboxSpecV1?: InputMaybe<Scalars["JSON"]["input"]>;
    toolboxToToolboxId?: InputMaybe<ToolboxVersionToolboxId5Cc86066FkToolboxIdInput>;
    toolboxVersionExitcodesUsingId?: InputMaybe<ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInverseInput>;
    toolboxVersionToolVersionsUsingId?: InputMaybe<ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInverseInput>;
    version?: InputMaybe<Scalars["String"]["input"]>;
  };

/** An object where the defined keys will be set on the `toolboxVersionToolVersion` being updated. */
export type UpdateToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolversionId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInput>;
    toolboxVersionToToolboxversionId?: InputMaybe<ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInput>;
    toolversionId?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `toolboxVersionToolVersion` being updated. */
export type UpdateToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolversionId?: InputMaybe<ToolboxVersionToolToolversionId4Ed31559FkToolVersInput>;
    toolboxVersionToToolboxversionId?: InputMaybe<ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInput>;
    toolboxversionId?: InputMaybe<Scalars["Int"]["input"]>;
  };

/** An object where the defined keys will be set on the `workflowDatum` being updated. */
export type UpdateWorkflowDatumOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdPatch =
  {
    fresh?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowResultsUsingId?: InputMaybe<WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInverseInput>;
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInverseInput>;
  };

/** An object where the defined keys will be set on the `workflowDatum` being updated. */
export type UpdateWorkflowDatumOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdPatch =
  {
    fresh?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowResultsUsingId?: InputMaybe<WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInverseInput>;
    workflowSectionBlocksUsingId?: InputMaybe<WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInverseInput>;
  };

/** An object where the defined keys will be set on the `workflow` being updated. */
export type UpdateWorkflowOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdPatch =
  {
    applicationUserToUserId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInput>;
    containerImageToContainerId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInverseInput>;
  };

/** An object where the defined keys will be set on the `workflow` being updated. */
export type UpdateWorkflowOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdPatch =
  {
    applicationUserToUserId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInput>;
    containerId?: InputMaybe<Scalars["Int"]["input"]>;
    containerImageToContainerId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    tenantToTenantId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInverseInput>;
  };

/** An object where the defined keys will be set on the `workflow` being updated. */
export type UpdateWorkflowOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdPatch =
  {
    applicationUserToUserId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInput>;
    containerId?: InputMaybe<Scalars["Int"]["input"]>;
    containerImageToContainerId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInput>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInverseInput>;
  };

/** An object where the defined keys will be set on the `workflow` being updated. */
export type UpdateWorkflowOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdPatch =
  {
    applicationUserToUserId?: InputMaybe<WorkflowUserId121C1967FkApplicationUserIdInput>;
    containerId?: InputMaybe<Scalars["Int"]["input"]>;
    containerImageToContainerId?: InputMaybe<WorkflowContainerIdE73A96E5FkContainerImageIdInput>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    tenantId?: InputMaybe<Scalars["Int"]["input"]>;
    tenantToTenantId?: InputMaybe<WorkflowTenantId9469Ff84FkTenantIdInput>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowVersionsUsingId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInverseInput>;
  };

/** An object where the defined keys will be set on the `workflowResult` being updated. */
export type UpdateWorkflowResultOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdPatch =
  {
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowDatumToObjectId?: InputMaybe<WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInput>;
    workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInverseInput>;
    workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkResultId50F959E2FkWorkflowInverseInput>;
  };

/** An object where the defined keys will be set on the `workflowResult` being updated. */
export type UpdateWorkflowResultOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdPatch =
  {
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    objectId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowDatumToObjectId?: InputMaybe<WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInput>;
    workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInverseInput>;
    workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkResultId50F959E2FkWorkflowInverseInput>;
  };

/** An object where the defined keys will be set on the `workflowResult` being updated. */
export type UpdateWorkflowResultOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowPatch =
  {
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    objectId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowDatumToObjectId?: InputMaybe<WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInput>;
    workflowResultTagsUsingId?: InputMaybe<WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInverseInput>;
    workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkResultId50F959E2FkWorkflowInverseInput>;
  };

/** An object where the defined keys will be set on the `workflowResultTag` being updated. */
export type UpdateWorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    tagId?: InputMaybe<Scalars["BigInt"]["input"]>;
    tagToTagId?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdInput>;
    workflowResultToResultId?: InputMaybe<WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInput>;
  };

/** An object where the defined keys will be set on the `workflowResultTag` being updated. */
export type UpdateWorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    resultId?: InputMaybe<Scalars["UUID"]["input"]>;
    tagToTagId?: InputMaybe<WorkflowResultTagsTagId4B7994A6FkTagIdInput>;
    workflowResultToResultId?: InputMaybe<WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInput>;
  };

/** An object where the defined keys will be set on the `workflowResultWorkflowVersion` being updated. */
export type UpdateWorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    workflowResultToResultId?: InputMaybe<WorkflowResultWorkResultId50F959E2FkWorkflowInput>;
    workflowVersionToWorkflowversionId?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInput>;
    workflowversionId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `workflowResultWorkflowVersion` being updated. */
export type UpdateWorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    resultId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowResultToResultId?: InputMaybe<WorkflowResultWorkResultId50F959E2FkWorkflowInput>;
    workflowVersionToWorkflowversionId?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInput>;
  };

/** An object where the defined keys will be set on the `workflowSectionBlock` being updated. */
export type UpdateWorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdPatch =
  {
    columns?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    markdown?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<Scalars["Int"]["input"]>;
    parameters?: InputMaybe<Scalars["JSON"]["input"]>;
    results?: InputMaybe<Scalars["JSON"]["input"]>;
    sources?: InputMaybe<Scalars["JSON"]["input"]>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInput>;
    workflowDatumToObjectId?: InputMaybe<WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInput>;
    workflowSectionBlockSectionsUsingId?: InputMaybe<WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInverseInput>;
  };

/** An object where the defined keys will be set on the `workflowSectionBlock` being updated. */
export type UpdateWorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdPatch =
  {
    columns?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    markdown?: InputMaybe<Scalars["String"]["input"]>;
    objectId?: InputMaybe<Scalars["UUID"]["input"]>;
    order?: InputMaybe<Scalars["Int"]["input"]>;
    parameters?: InputMaybe<Scalars["JSON"]["input"]>;
    results?: InputMaybe<Scalars["JSON"]["input"]>;
    sources?: InputMaybe<Scalars["JSON"]["input"]>;
    toolVersionToToolId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInput>;
    workflowDatumToObjectId?: InputMaybe<WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInput>;
    workflowSectionBlockSectionsUsingId?: InputMaybe<WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInverseInput>;
  };

/** An object where the defined keys will be set on the `workflowSectionBlock` being updated. */
export type UpdateWorkflowSectionBlockOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowPatch =
  {
    columns?: InputMaybe<Scalars["Int"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    markdown?: InputMaybe<Scalars["String"]["input"]>;
    objectId?: InputMaybe<Scalars["UUID"]["input"]>;
    order?: InputMaybe<Scalars["Int"]["input"]>;
    parameters?: InputMaybe<Scalars["JSON"]["input"]>;
    results?: InputMaybe<Scalars["JSON"]["input"]>;
    sources?: InputMaybe<Scalars["JSON"]["input"]>;
    toolId?: InputMaybe<Scalars["Int"]["input"]>;
    toolVersionToToolId?: InputMaybe<WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInput>;
    workflowDatumToObjectId?: InputMaybe<WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInput>;
    workflowSectionBlockSectionsUsingId?: InputMaybe<WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInverseInput>;
  };

/** An object where the defined keys will be set on the `workflowSectionBlockSection` being updated. */
export type UpdateWorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    sectionId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowSectionBlockToBlockId?: InputMaybe<WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInput>;
    workflowSectionToSectionId?: InputMaybe<WorkflowSectionBloSectionId817C1C77FkWorkflowInput>;
  };

/** An object where the defined keys will be set on the `workflowSectionBlockSection` being updated. */
export type UpdateWorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowPatch =
  {
    blockId?: InputMaybe<Scalars["UUID"]["input"]>;
    id?: InputMaybe<Scalars["Int"]["input"]>;
    workflowSectionBlockToBlockId?: InputMaybe<WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInput>;
    workflowSectionToSectionId?: InputMaybe<WorkflowSectionBloSectionId817C1C77FkWorkflowInput>;
  };

/** An object where the defined keys will be set on the `workflowSection` being updated. */
export type UpdateWorkflowSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowPatch =
  {
    description?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    order?: InputMaybe<Scalars["Int"]["input"]>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    workflowSectionBlockSectionsUsingId?: InputMaybe<WorkflowSectionBloSectionId817C1C77FkWorkflowInverseInput>;
    workflowSectionWorkflowVersionsUsingId?: InputMaybe<WorkflowSectionWorSectionId1D5C2D63FkWorkflowInverseInput>;
  };

/** An object where the defined keys will be set on the `workflowSection` being updated. */
export type UpdateWorkflowSectionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowPatch =
  {
    description?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    order?: InputMaybe<Scalars["Int"]["input"]>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    workflowSectionBlockSectionsUsingId?: InputMaybe<WorkflowSectionBloSectionId817C1C77FkWorkflowInverseInput>;
    workflowSectionWorkflowVersionsUsingId?: InputMaybe<WorkflowSectionWorSectionId1D5C2D63FkWorkflowInverseInput>;
  };

/** An object where the defined keys will be set on the `workflowSectionWorkflowVersion` being updated. */
export type UpdateWorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    workflowSectionToSectionId?: InputMaybe<WorkflowSectionWorSectionId1D5C2D63FkWorkflowInput>;
    workflowVersionToWorkflowversionId?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInput>;
    workflowversionId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `workflowSectionWorkflowVersion` being updated. */
export type UpdateWorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    sectionId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowSectionToSectionId?: InputMaybe<WorkflowSectionWorSectionId1D5C2D63FkWorkflowInput>;
    workflowVersionToWorkflowversionId?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInput>;
  };

/** An object where the defined keys will be set on the `workflowSource` being updated. */
export type UpdateWorkflowSourceOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdPatch =
  {
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowSourceTagsUsingId?: InputMaybe<WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInverseInput>;
    workflowSourceWorkflowVersionsUsingId?: InputMaybe<WorkflowSourceWorkSourceId608A289DFkWorkflowInverseInput>;
  };

/** An object where the defined keys will be set on the `workflowSource` being updated. */
export type UpdateWorkflowSourceOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowPatch =
  {
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowSourceTagsUsingId?: InputMaybe<WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInverseInput>;
    workflowSourceWorkflowVersionsUsingId?: InputMaybe<WorkflowSourceWorkSourceId608A289DFkWorkflowInverseInput>;
  };

/** An object where the defined keys will be set on the `workflowSourceTag` being updated. */
export type UpdateWorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    tagId?: InputMaybe<Scalars["BigInt"]["input"]>;
    tagToTagId?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdInput>;
    workflowSourceToSourceId?: InputMaybe<WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInput>;
  };

/** An object where the defined keys will be set on the `workflowSourceTag` being updated. */
export type UpdateWorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    sourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    tagToTagId?: InputMaybe<WorkflowSourceTagsTagId690E6935FkTagIdInput>;
    workflowSourceToSourceId?: InputMaybe<WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInput>;
  };

/** An object where the defined keys will be set on the `workflowSourceWorkflowVersion` being updated. */
export type UpdateWorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    workflowSourceToSourceId?: InputMaybe<WorkflowSourceWorkSourceId608A289DFkWorkflowInput>;
    workflowVersionToWorkflowversionId?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInput>;
    workflowversionId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `workflowSourceWorkflowVersion` being updated. */
export type UpdateWorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowPatch =
  {
    id?: InputMaybe<Scalars["Int"]["input"]>;
    sourceId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowSourceToSourceId?: InputMaybe<WorkflowSourceWorkSourceId608A289DFkWorkflowInput>;
    workflowVersionToWorkflowversionId?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInput>;
  };

/** An object where the defined keys will be set on the `workflowVersion` being updated. */
export type UpdateWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowPatch =
  {
    applicationUserToUserId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInput>;
    autoGenerated?: InputMaybe<Scalars["Boolean"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    draft?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInverseInput>;
    workflowSectionWorkflowVersionsUsingId?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInverseInput>;
    workflowSourceWorkflowVersionsUsingId?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInverseInput>;
    workflowToWorkflowId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInput>;
  };

/** An object where the defined keys will be set on the `workflowVersion` being updated. */
export type UpdateWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowPatch =
  {
    applicationUserToUserId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInput>;
    autoGenerated?: InputMaybe<Scalars["Boolean"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    draft?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInverseInput>;
    workflowSectionWorkflowVersionsUsingId?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInverseInput>;
    workflowSourceWorkflowVersionsUsingId?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInverseInput>;
    workflowToWorkflowId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInput>;
  };

/** An object where the defined keys will be set on the `workflowVersion` being updated. */
export type UpdateWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowPatch =
  {
    applicationUserToUserId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInput>;
    autoGenerated?: InputMaybe<Scalars["Boolean"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    draft?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInverseInput>;
    workflowSectionWorkflowVersionsUsingId?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInverseInput>;
    workflowSourceWorkflowVersionsUsingId?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInverseInput>;
    workflowToWorkflowId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInput>;
  };

/** An object where the defined keys will be set on the `workflowVersion` being updated. */
export type UpdateWorkflowVersionOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdPatch =
  {
    applicationUserToUserId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInput>;
    autoGenerated?: InputMaybe<Scalars["Boolean"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    draft?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    workflowId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInverseInput>;
    workflowSectionWorkflowVersionsUsingId?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInverseInput>;
    workflowSourceWorkflowVersionsUsingId?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInverseInput>;
    workflowToWorkflowId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInput>;
  };

/** An object where the defined keys will be set on the `workflowVersion` being updated. */
export type UpdateWorkflowVersionOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdPatch =
  {
    applicationUserToUserId?: InputMaybe<WorkflowVersionUserId00C0F62FFkApplicationUserIdInput>;
    autoGenerated?: InputMaybe<Scalars["Boolean"]["input"]>;
    dateCreated?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    draft?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    userId?: InputMaybe<Scalars["UUID"]["input"]>;
    workflowResultWorkflowVersionsUsingId?: InputMaybe<WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInverseInput>;
    workflowSectionWorkflowVersionsUsingId?: InputMaybe<WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInverseInput>;
    workflowSourceWorkflowVersionsUsingId?: InputMaybe<WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInverseInput>;
    workflowToWorkflowId?: InputMaybe<WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInput>;
  };

export type AnalysisTableTaskCountsQueryVariables = Exact<{
  projectId: Scalars["UUID"]["input"];
}>;

export type AnalysisTableTaskCountsQuery = { __typename: "Query" } & {
  projectById: Maybe<
    { __typename: "Project" } & Pick<Project, "id"> & {
        analysisTables: { __typename: "AnalysisTablesConnection" } & {
          nodes: Array<
            { __typename: "AnalysisTable" } & Pick<
              AnalysisTable,
              | "groupId"
              | "id"
              | "name"
              | "dateCreated"
              | "projectId"
              | "tenantId"
              | "toolVersionId"
              | "userId"
              | "identifiers"
            > & {
                rows: { __typename: "AnalysisTableRowsConnection" } & {
                  nodes: Array<
                    { __typename: "AnalysisTableRow" } & Pick<
                      AnalysisTableRow,
                      | "id"
                      | "data"
                      | "groups"
                      | "dateCreated"
                      | "selections"
                      | "tableId"
                      | "taskId"
                      | "tenantId"
                      | "attachments"
                    > & {
                        task: Maybe<
                          { __typename: "Task" } & Pick<
                            Task,
                            | "id"
                            | "taskParameters"
                            | "toolExitcode"
                            | "toolExitcodeDescription"
                            | "created"
                            | "tenantId"
                            | "toolVersionId"
                            | "userId"
                            | "status"
                            | "datasetId"
                            | "credits"
                            | "cloned"
                          >
                        >;
                      }
                  >;
                };
              }
          >;
        };
      }
  >;
};

export type ButtonDatasetExportsQueryVariables = Exact<{
  datasetId: Scalars["UUID"]["input"];
  first: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
}>;

export type ButtonDatasetExportsQuery = { __typename: "Query" } & {
  tasks: Maybe<
    { __typename: "TasksConnection" } & Pick<TasksConnection, "totalCount"> & {
        nodes: Array<
          { __typename: "Task" } & Pick<
            Task,
            "id" | "status" | "created" | "userId" | "taskParameters"
          > & {
              taskSources: { __typename: "TaskSourcesConnection" } & {
                nodes: Array<
                  { __typename: "TaskSource" } & Pick<
                    TaskSource,
                    "id" | "fileId"
                  >
                >;
              };
              toolVersion: Maybe<
                { __typename: "ToolVersion" } & Pick<ToolVersion, "id"> & {
                    toolParameters: {
                      __typename: "ToolParametersConnection";
                    } & {
                      nodes: Array<
                        { __typename: "ToolParameter" } & Pick<
                          ToolParameter,
                          "id" | "key"
                        >
                      >;
                    };
                  }
              >;
            }
        >;
      }
  >;
};

export type CreateEulaVersionUserAcceptanceMutationVariables = Exact<{
  input: CreateEulaVersionUserAcceptanceInput;
}>;

export type CreateEulaVersionUserAcceptanceMutation = {
  __typename: "Mutation";
} & {
  createEulaVersionUserAcceptance: Maybe<
    { __typename: "CreateEulaVersionUserAcceptancePayload" } & {
      applicationUserByUserId: Maybe<
        { __typename: "ApplicationUser" } & Pick<ApplicationUser, "id">
      >;
    }
  >;
};

export type GetAllEulaVersionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllEulaVersionsQuery = { __typename: "Query" } & {
  allEulaVersions: Maybe<
    { __typename: "EulaVersionsConnection" } & {
      nodes: Array<
        { __typename: "EulaVersion" } & Pick<EulaVersion, "id" | "content">
      >;
    }
  >;
};

export type FileBrowserDetailRendererAnalysisResultQueryVariables = Exact<{
  drsFileId: Scalars["UUID"]["input"];
}>;

export type FileBrowserDetailRendererAnalysisResultQuery = {
  __typename: "Query";
} & {
  drsFile: Maybe<
    { __typename: "File" } & Pick<File, "id"> & {
        dataset: Maybe<{ __typename: "Dataset" } & Pick<Dataset, "id">>;
        project: Maybe<{ __typename: "Project" } & Pick<Project, "id" | "key">>;
        outputGroupFiles: { __typename: "OutputGroupFilesConnection" } & {
          nodes: Array<
            { __typename: "OutputGroupFile" } & Pick<OutputGroupFile, "id"> & {
                fileOutputGroup: Maybe<
                  { __typename: "FileOutputGroup" } & Pick<
                    FileOutputGroup,
                    "id"
                  > & {
                      task: Maybe<
                        { __typename: "Task" } & Pick<
                          Task,
                          "id" | "status" | "created"
                        > & {
                            analysisTableRow: Maybe<
                              { __typename: "AnalysisTableRow" } & Pick<
                                AnalysisTableRow,
                                "id"
                              > & {
                                  analysisTable: Maybe<
                                    { __typename: "AnalysisTable" } & Pick<
                                      AnalysisTable,
                                      "id" | "name"
                                    > & {
                                        analysisTableGroup: Maybe<
                                          {
                                            __typename: "AnalysisTableGroup";
                                          } & Pick<
                                            AnalysisTableGroup,
                                            "id" | "name"
                                          >
                                        >;
                                      }
                                  >;
                                }
                            >;
                          }
                      >;
                    }
                >;
              }
          >;
        };
      }
  >;
};

export type GetToolboxSpecByAnalysisTableIdQueryVariables = Exact<{
  analysisTableId: Scalars["BigInt"]["input"];
}>;

export type GetToolboxSpecByAnalysisTableIdQuery = { __typename: "Query" } & {
  analysisTable: Maybe<
    { __typename: "AnalysisTable" } & Pick<
      AnalysisTable,
      | "id"
      | "name"
      | "dateCreated"
      | "projectId"
      | "tenantId"
      | "toolVersionId"
      | "userId"
      | "identifiers"
    > & {
        toolVersion: Maybe<
          { __typename: "ToolVersion" } & Pick<
            ToolVersion,
            "toolSpec" | "id" | "version" | "created" | "maturity" | "credits"
          > & {
              tool: Maybe<
                { __typename: "Tool" } & Pick<
                  Tool,
                  "id" | "name" | "key" | "helpText" | "created"
                >
              >;
            }
        >;
      }
  >;
};

export type FlyoutDrsFileInfoQueryVariables = Exact<{
  drsFileId: Scalars["UUID"]["input"];
}>;

export type FlyoutDrsFileInfoQuery = { __typename: "Query" } & {
  drsFile: Maybe<
    { __typename: "File" } & Pick<
      File,
      | "preview"
      | "id"
      | "dateArchived"
      | "dateCreated"
      | "dateDataDeleted"
      | "dateDeleted"
      | "fileType"
      | "fileFormat"
      | "fileStructure"
      | "key"
      | "location"
      | "multipartUploadId"
      | "name"
      | "parentIds"
      | "partSize"
      | "projectId"
      | "signal"
      | "size"
      | "status"
      | "source"
      | "tenantId"
      | "uploadStatus"
      | "userId"
      | "originalName"
      | "seriesParentId"
      | "datasetId"
      | "isSeries"
      | "seriesOrder"
      | "processingStatus"
    > & {
        seriesFiles: { __typename: "FilesConnection" } & {
          nodes: Array<
            { __typename: "File" } & Pick<
              File,
              | "preview"
              | "id"
              | "dateArchived"
              | "dateCreated"
              | "dateDataDeleted"
              | "dateDeleted"
              | "fileType"
              | "fileFormat"
              | "fileStructure"
              | "key"
              | "location"
              | "multipartUploadId"
              | "name"
              | "parentIds"
              | "partSize"
              | "projectId"
              | "signal"
              | "size"
              | "status"
              | "source"
              | "tenantId"
              | "uploadStatus"
              | "userId"
              | "originalName"
              | "seriesParentId"
              | "datasetId"
              | "isSeries"
              | "seriesOrder"
              | "processingStatus"
            >
          >;
        };
        outputGroupFiles: { __typename: "OutputGroupFilesConnection" } & {
          nodes: Array<
            { __typename: "OutputGroupFile" } & Pick<OutputGroupFile, "id"> & {
                outputGroup: Maybe<
                  { __typename: "FileOutputGroup" } & Pick<
                    FileOutputGroup,
                    "id"
                  > & {
                      task: Maybe<
                        { __typename: "Task" } & Pick<
                          Task,
                          "id" | "status" | "created"
                        > & {
                            analysisTableRow: Maybe<
                              { __typename: "AnalysisTableRow" } & Pick<
                                AnalysisTableRow,
                                "id"
                              > & {
                                  analysisTable: Maybe<
                                    { __typename: "AnalysisTable" } & Pick<
                                      AnalysisTable,
                                      "id" | "name"
                                    > & {
                                        analysisTableGroup: Maybe<
                                          {
                                            __typename: "AnalysisTableGroup";
                                          } & Pick<
                                            AnalysisTableGroup,
                                            "id" | "name"
                                          >
                                        >;
                                      }
                                  >;
                                }
                            >;
                          }
                      >;
                    }
                >;
              }
          >;
        };
      }
  >;
};

export type NotificationsFeedUnreadCountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type NotificationsFeedUnreadCountQuery = { __typename: "Query" } & {
  notifications: Maybe<
    { __typename: "NotificationsConnection" } & Pick<
      NotificationsConnection,
      "totalCount"
    >
  >;
};

export type NotificationsFeedBodyQueryVariables = Exact<{
  condition?: InputMaybe<NotificationCondition>;
}>;

export type NotificationsFeedBodyQuery = { __typename: "Query" } & {
  notifications: Maybe<
    { __typename: "NotificationsConnection" } & {
      nodes: Array<
        { __typename: "Notification" } & Pick<
          Notification,
          "id" | "dateCreated" | "hasSeen"
        > & {
            projectSharedNotification: Maybe<
              { __typename: "ProjectSharedNotification" } & Pick<
                ProjectSharedNotification,
                "id" | "accessLevel"
              > & {
                  project: Maybe<
                    { __typename: "Project" } & Pick<
                      Project,
                      "id" | "name" | "key"
                    > & {
                        tenant: Maybe<
                          { __typename: "Tenant" } & Pick<Tenant, "id" | "key">
                        >;
                      }
                  >;
                  sharer: Maybe<
                    { __typename: "ApplicationUser" } & Pick<
                      ApplicationUser,
                      "id" | "firstName" | "lastName"
                    >
                  >;
                }
            >;
            fileProcessingNotification: Maybe<
              { __typename: "FileProcessingNotification" } & Pick<
                FileProcessingNotification,
                "id" | "status" | "fileId" | "userId"
              > & {
                  file: Maybe<
                    { __typename: "File" } & Pick<
                      File,
                      | "id"
                      | "name"
                      | "status"
                      | "fileType"
                      | "isSeries"
                      | "source"
                      | "processingStatus"
                      | "seriesParentId"
                    > & {
                        project: Maybe<
                          { __typename: "Project" } & Pick<
                            Project,
                            "id" | "name" | "key"
                          > & {
                              tenant: Maybe<
                                { __typename: "Tenant" } & Pick<
                                  Tenant,
                                  "id" | "key"
                                >
                              >;
                            }
                        >;
                      }
                  >;
                  user: Maybe<
                    { __typename: "ApplicationUser" } & Pick<
                      ApplicationUser,
                      "id"
                    >
                  >;
                }
            >;
            projectClonedNotification: Maybe<
              { __typename: "ProjectClonedNotification" } & Pick<
                ProjectClonedNotification,
                "id"
              > & {
                  project: Maybe<
                    { __typename: "Project" } & Pick<
                      Project,
                      "id" | "name" | "key" | "status"
                    > & {
                        tenant: Maybe<
                          { __typename: "Tenant" } & Pick<Tenant, "id" | "key">
                        >;
                      }
                  >;
                }
            >;
            tenantUserMembershipNotification: Maybe<
              { __typename: "TenantUserMembershipNotification" } & Pick<
                TenantUserMembershipNotification,
                "id"
              > & {
                  inviter: Maybe<
                    { __typename: "ApplicationUser" } & Pick<
                      ApplicationUser,
                      "id" | "firstName" | "lastName"
                    >
                  >;
                  tenant: Maybe<
                    { __typename: "Tenant" } & Pick<
                      Tenant,
                      "id" | "key" | "name"
                    >
                  >;
                }
            >;
            roleChangeNotification: Maybe<
              { __typename: "RoleChangeNotification" } & Pick<
                RoleChangeNotification,
                "id" | "role"
              > & {
                  tenant: Maybe<
                    { __typename: "Tenant" } & Pick<
                      Tenant,
                      "id" | "key" | "name"
                    >
                  >;
                  user: Maybe<
                    { __typename: "ApplicationUser" } & Pick<
                      ApplicationUser,
                      "id"
                    >
                  >;
                }
            >;
          }
      >;
    }
  >;
};

export type PopoverFileUploadFragment = { __typename: "File" } & Pick<
  File,
  | "id"
  | "name"
  | "status"
  | "fileType"
  | "source"
  | "processingStatus"
  | "seriesParentId"
>;

export type RecordingIdentifierBadgeRecordingsQueryVariables = Exact<{
  recordingIds: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
}>;

export type RecordingIdentifierBadgeRecordingsQuery = {
  __typename: "Query";
} & {
  recordings: Maybe<
    { __typename: "FileRecordingGroupsConnection" } & {
      nodes: Array<
        { __typename: "FileRecordingGroup" } & Pick<
          FileRecordingGroup,
          "id" | "number"
        > & {
            recordingsTable: Maybe<
              { __typename: "DatasetRecordingsTable" } & Pick<
                DatasetRecordingsTable,
                "id"
              > & {
                  columns: {
                    __typename: "DatasetRecordingsTableColumnsConnection";
                  } & {
                    nodes: Array<
                      { __typename: "DatasetRecordingsTableColumn" } & Pick<
                        DatasetRecordingsTableColumn,
                        "id" | "identifierPosition"
                      > & {
                          colDef: DatasetRecordingsTableColumn["activeColDef"];
                        }
                    >;
                  };
                  dataset: Maybe<
                    { __typename: "Dataset" } & Pick<Dataset, "id" | "prefix">
                  >;
                }
            >;
          }
      >;
    }
  >;
};

export type RecordingIdentifierBadgeMetadataQueryVariables = Exact<{
  withFileMetadata: Scalars["Boolean"]["input"];
  fileMetadataFilter: FileMetadatumFilter;
  withRecordingMetadata: Scalars["Boolean"]["input"];
  recordingMetadataFilter: FileRecordingGroupMetadatumFilter;
}>;

export type RecordingIdentifierBadgeMetadataQuery = { __typename: "Query" } & {
  recordingMetadata: Maybe<
    { __typename: "FileRecordingGroupMetadataConnection" } & {
      nodes: Array<
        { __typename: "FileRecordingGroupMetadatum" } & Pick<
          FileRecordingGroupMetadatum,
          "id"
        > & {
            recordingId: FileRecordingGroupMetadatum["fileRecordingGroupId"];
          } & {
            metadatum: Maybe<
              { __typename: "Metadatum" } & Pick<Metadatum, "id" | "key"> & {
                  value: Metadatum["activeValue"];
                }
            >;
          }
      >;
    }
  >;
  fileMetadata: Maybe<
    { __typename: "FileMetadataConnection" } & {
      nodes: Array<
        { __typename: "FileMetadatum" } & Pick<
          FileMetadatum,
          "id" | "fileId"
        > & {
            metadatum: Maybe<
              { __typename: "Metadatum" } & Pick<Metadatum, "id" | "key"> & {
                  value: Metadatum["activeValue"];
                }
            >;
          }
      >;
    }
  >;
};

export type AutofillQueryVariables = Exact<{
  projectId: Scalars["UUID"]["input"];
}>;

export type AutofillQuery = { __typename: "Query" } & {
  project: Maybe<
    { __typename: "Project" } & Pick<Project, "id"> & {
        datasets: { __typename: "DatasetsConnection" } & {
          nodes: Array<
            { __typename: "Dataset" } & Pick<Dataset, "id" | "name"> & {
                recordingsTable: Maybe<
                  { __typename: "DatasetRecordingsTable" } & Pick<
                    DatasetRecordingsTable,
                    "id"
                  > & {
                      recordingGroups: {
                        __typename: "FileRecordingGroupsConnection";
                      } & {
                        nodes: Array<
                          { __typename: "FileRecordingGroup" } & Pick<
                            FileRecordingGroup,
                            "number" | "id" | "dateCreated"
                          >
                        >;
                      };
                      activeColumns: {
                        __typename: "DatasetRecordingsTableColumnsConnection";
                      } & {
                        nodes: Array<
                          { __typename: "DatasetRecordingsTableColumn" } & Pick<
                            DatasetRecordingsTableColumn,
                            | "id"
                            | "activeColDef"
                            | "dateCreated"
                            | "dateAssignmentEnded"
                            | "tenantId"
                            | "pinned"
                            | "width"
                            | "colDefId"
                            | "identifierPosition"
                          > & {
                              orders: {
                                __typename: "DatasetRecordingsTableColumnOrdersConnection";
                              } & {
                                nodes: Array<
                                  {
                                    __typename: "DatasetRecordingsTableColumnOrder";
                                  } & Pick<
                                    DatasetRecordingsTableColumnOrder,
                                    "id" | "value"
                                  >
                                >;
                              };
                            }
                        >;
                      };
                    }
                >;
              }
          >;
        };
      }
  >;
};

export type DataSelectorQueryVariables = Exact<{
  projectId: Scalars["UUID"]["input"];
}>;

export type DataSelectorQuery = { __typename: "Query" } & {
  project: Maybe<
    { __typename: "Project" } & Pick<Project, "id"> & {
        datasets: { __typename: "DatasetsConnection" } & {
          nodes: Array<
            { __typename: "Dataset" } & Pick<
              Dataset,
              "id" | "prefix" | "name"
            > & {
                recordingsTable: Maybe<
                  { __typename: "DatasetRecordingsTable" } & Pick<
                    DatasetRecordingsTable,
                    "id"
                  > & {
                      recordingGroups: {
                        __typename: "FileRecordingGroupsConnection";
                      } & {
                        nodes: Array<
                          { __typename: "FileRecordingGroup" } & Pick<
                            FileRecordingGroup,
                            "number" | "id" | "dateCreated"
                          >
                        >;
                      };
                      activeColumns: {
                        __typename: "DatasetRecordingsTableColumnsConnection";
                      } & {
                        nodes: Array<
                          { __typename: "DatasetRecordingsTableColumn" } & Pick<
                            DatasetRecordingsTableColumn,
                            | "id"
                            | "activeColDef"
                            | "dateCreated"
                            | "dateAssignmentEnded"
                            | "tenantId"
                            | "pinned"
                            | "width"
                            | "colDefId"
                            | "identifierPosition"
                          > & {
                              orders: {
                                __typename: "DatasetRecordingsTableColumnOrdersConnection";
                              } & {
                                nodes: Array<
                                  {
                                    __typename: "DatasetRecordingsTableColumnOrder";
                                  } & Pick<
                                    DatasetRecordingsTableColumnOrder,
                                    "id" | "value"
                                  >
                                >;
                              };
                            }
                        >;
                      };
                    }
                >;
              }
          >;
        };
        analysisTables: { __typename: "AnalysisTablesConnection" } & {
          nodes: Array<
            { __typename: "AnalysisTable" } & Pick<
              AnalysisTable,
              | "id"
              | "name"
              | "dateCreated"
              | "projectId"
              | "tenantId"
              | "toolVersionId"
              | "userId"
              | "identifiers"
            > & {
                group: Maybe<
                  { __typename: "AnalysisTableGroup" } & Pick<
                    AnalysisTableGroup,
                    "id" | "name"
                  >
                >;
                rows: { __typename: "AnalysisTableRowsConnection" } & {
                  nodes: Array<
                    { __typename: "AnalysisTableRow" } & Pick<
                      AnalysisTableRow,
                      | "id"
                      | "data"
                      | "groups"
                      | "dateCreated"
                      | "selections"
                      | "tableId"
                      | "taskId"
                      | "tenantId"
                      | "attachments"
                    > & {
                        task: Maybe<
                          { __typename: "Task" } & Pick<
                            Task,
                            | "id"
                            | "taskParameters"
                            | "toolExitcode"
                            | "toolExitcodeDescription"
                            | "created"
                            | "tenantId"
                            | "toolVersionId"
                            | "userId"
                            | "status"
                            | "datasetId"
                            | "credits"
                            | "cloned"
                          >
                        >;
                      }
                  >;
                };
                toolVersion: Maybe<
                  { __typename: "ToolVersion" } & Pick<
                    ToolVersion,
                    | "toolSpec"
                    | "id"
                    | "version"
                    | "created"
                    | "maturity"
                    | "credits"
                  > & {
                      tool: Maybe<
                        { __typename: "Tool" } & Pick<
                          Tool,
                          "id" | "name" | "key" | "helpText" | "created"
                        >
                      >;
                    }
                >;
              }
          >;
        };
      }
  >;
};

export type MetadataTableFileQueryVariables = Exact<{
  fileIds?: InputMaybe<
    Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"]
  >;
  metadatumKeys?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type MetadataTableFileQuery = { __typename: "Query" } & {
  fileMetadata: Maybe<
    { __typename: "FileMetadataConnection" } & {
      nodes: Array<
        { __typename: "FileMetadatum" } & Pick<FileMetadatum, "id"> & {
            file: Maybe<{ __typename: "File" } & Pick<File, "id" | "fileType">>;
            metadatum: Maybe<
              { __typename: "Metadatum" } & Pick<
                Metadatum,
                "id" | "key" | "activeValue"
              >
            >;
          }
      >;
    }
  >;
};

export type MetadataTableRecordingQueryVariables = Exact<{
  recordingIds?: InputMaybe<
    Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"]
  >;
}>;

export type MetadataTableRecordingQuery = { __typename: "Query" } & {
  recordingMetadata: Maybe<
    { __typename: "FileRecordingGroupMetadataConnection" } & {
      nodes: Array<
        { __typename: "FileRecordingGroupMetadatum" } & Pick<
          FileRecordingGroupMetadatum,
          "id"
        > & {
            recordingId: FileRecordingGroupMetadatum["fileRecordingGroupId"];
          } & {
            metadatum: Maybe<
              { __typename: "Metadatum" } & Pick<
                Metadatum,
                "id" | "key" | "activeValue"
              >
            >;
          }
      >;
    }
  >;
  recordings: Maybe<
    { __typename: "FileRecordingGroupsConnection" } & {
      nodes: Array<
        { __typename: "FileRecordingGroup" } & Pick<
          FileRecordingGroup,
          "id"
        > & {
            recordingsTable: Maybe<
              { __typename: "DatasetRecordingsTable" } & Pick<
                DatasetRecordingsTable,
                "id"
              > & {
                  columns: {
                    __typename: "DatasetRecordingsTableColumnsConnection";
                  } & {
                    nodes: Array<
                      { __typename: "DatasetRecordingsTableColumn" } & Pick<
                        DatasetRecordingsTableColumn,
                        "id"
                      > & {
                          colDef: DatasetRecordingsTableColumn["activeColDef"];
                        }
                    >;
                  };
                }
            >;
          }
      >;
    }
  >;
};

export type UserAvatarFieldsFragment = { __typename: "ApplicationUser" } & Pick<
  ApplicationUser,
  "id" | "firstName" | "lastName"
> & {
    avatar: Maybe<
      { __typename: "Avatar" } & Pick<
        Avatar,
        "id" | "imageBase64" | "initials" | "useImage" | "color"
      >
    >;
  };

export type UserAvatarQueryVariables = Exact<{
  userId: Scalars["UUID"]["input"];
}>;

export type UserAvatarQuery = { __typename: "Query" } & {
  user: Maybe<
    { __typename: "ApplicationUser" } & Pick<
      ApplicationUser,
      "id" | "firstName" | "lastName"
    > & {
        avatar: Maybe<
          { __typename: "Avatar" } & Pick<
            Avatar,
            "id" | "imageBase64" | "initials" | "useImage" | "color"
          >
        >;
      }
  >;
};

export type AnalysisTableCacheFragment = { __typename: "AnalysisTable" } & Pick<
  AnalysisTable,
  | "dateCreated"
  | "id"
  | "name"
  | "identifiers"
  | "projectId"
  | "tenantId"
  | "toolVersionId"
  | "userId"
  | "groupId"
> & {
    analysisTableRows: { __typename: "AnalysisTableRowsConnection" } & {
      nodes: Array<
        { __typename: "AnalysisTableRow" } & Pick<AnalysisTableRow, "id">
      >;
    };
    user: Maybe<
      { __typename: "ApplicationUser" } & Pick<ApplicationUser, "id">
    >;
    project: Maybe<{ __typename: "Project" } & Pick<Project, "id">>;
    tenant: Maybe<{ __typename: "Tenant" } & Pick<Tenant, "id">>;
    toolVersion: Maybe<{ __typename: "ToolVersion" } & Pick<ToolVersion, "id">>;
    group: Maybe<
      { __typename: "AnalysisTableGroup" } & Pick<AnalysisTableGroup, "id">
    >;
  };

export type AnalysisTableRowCacheFragment = {
  __typename: "AnalysisTableRow";
} & Pick<
  AnalysisTableRow,
  | "activeAttachResults"
  | "attachments"
  | "data"
  | "dateCreated"
  | "groups"
  | "id"
  | "locked"
  | "selections"
  | "tableId"
  | "taskId"
  | "tenantId"
  | "projectId"
  | "deleted"
  | "autofillMap"
  | "metadatumReferences"
  | "toolVersionId"
> & {
    analysisTableByTableId: Maybe<
      { __typename: "AnalysisTable" } & Pick<AnalysisTable, "id">
    >;
    analysisTableRowAttachResultsByAnalysisTableRowId: {
      __typename: "AnalysisTableRowAttachResultsConnection";
    } & {
      nodes: Array<
        { __typename: "AnalysisTableRowAttachResult" } & Pick<
          AnalysisTableRowAttachResult,
          "id"
        >
      >;
    };
    taskByTaskId: Maybe<{ __typename: "Task" } & Pick<Task, "id">>;
    tenantByTenantId: Maybe<{ __typename: "Tenant" } & Pick<Tenant, "id">>;
    project: Maybe<{ __typename: "Project" } & Pick<Project, "id">>;
    toolVersion: Maybe<{ __typename: "ToolVersion" } & Pick<ToolVersion, "id">>;
  };

export type ApplicationUserMembershipCacheFragment = {
  __typename: "ApplicationUserMembership";
} & Pick<ApplicationUserMembership, "id" | "role" | "tenantId" | "userId"> & {
    user: Maybe<
      { __typename: "ApplicationUser" } & Pick<ApplicationUser, "id">
    >;
    tenant: Maybe<{ __typename: "Tenant" } & Pick<Tenant, "id">>;
  };

export type DatasetCacheFragment = { __typename: "Dataset" } & Pick<
  Dataset,
  | "activeDescription"
  | "dateArchived"
  | "dateCreated"
  | "dateDataDeleted"
  | "dateDeleted"
  | "descriptionId"
  | "id"
  | "key"
  | "prefix"
  | "name"
  | "projectId"
  | "recordingsTableId"
  | "status"
  | "tenantId"
  | "userId"
> & {
    datasetRecordingsTable: Maybe<
      { __typename: "DatasetRecordingsTable" } & Pick<
        DatasetRecordingsTable,
        "id"
      >
    >;
    datasetVersions: { __typename: "DatasetVersionsConnection" } & {
      nodes: Array<
        { __typename: "DatasetVersion" } & Pick<DatasetVersion, "id">
      >;
    };
    user: Maybe<
      { __typename: "ApplicationUser" } & Pick<ApplicationUser, "id">
    >;
    files: { __typename: "FilesConnection" } & {
      nodes: Array<{ __typename: "File" } & Pick<File, "id">>;
    };
    project: Maybe<{ __typename: "Project" } & Pick<Project, "id">>;
    datasetMetadata: { __typename: "DatasetMetadataConnection" } & {
      nodes: Array<
        { __typename: "DatasetMetadatum" } & Pick<DatasetMetadatum, "id">
      >;
    };
    description: Maybe<{ __typename: "Metadatum" } & Pick<Metadatum, "id">>;
    tasks: { __typename: "TasksConnection" } & {
      nodes: Array<{ __typename: "Task" } & Pick<Task, "id">>;
    };
    tenant: Maybe<{ __typename: "Tenant" } & Pick<Tenant, "id">>;
  };

export type DatasetRecordingsTableCacheFragment = {
  __typename: "DatasetRecordingsTable";
} & Pick<
  DatasetRecordingsTable,
  "id" | "name" | "views" | "tenantId" | "projectId"
> & {
    activeColumns: { __typename: "DatasetRecordingsTableColumnsConnection" } & {
      nodes: Array<
        { __typename: "DatasetRecordingsTableColumn" } & Pick<
          DatasetRecordingsTableColumn,
          "id"
        >
      >;
    };
    columns: { __typename: "DatasetRecordingsTableColumnsConnection" } & {
      nodes: Array<
        { __typename: "DatasetRecordingsTableColumn" } & Pick<
          DatasetRecordingsTableColumn,
          "id"
        >
      >;
    };
    dataset: Maybe<{ __typename: "Dataset" } & Pick<Dataset, "id">>;
    fileRecordings: { __typename: "FileRecordingGroupsConnection" } & {
      nodes: Array<
        { __typename: "FileRecordingGroup" } & Pick<FileRecordingGroup, "id">
      >;
    };
    tenant: Maybe<{ __typename: "Tenant" } & Pick<Tenant, "id">>;
    datasets: { __typename: "DatasetsConnection" } & {
      nodes: Array<{ __typename: "Dataset" } & Pick<Dataset, "id">>;
    };
    project: Maybe<{ __typename: "Project" } & Pick<Project, "id">>;
  };

export type DatasetRecordingsTableColumnCacheFragment = {
  __typename: "DatasetRecordingsTableColumn";
} & Pick<
  DatasetRecordingsTableColumn,
  | "id"
  | "activeColDef"
  | "activeOrder"
  | "colDefId"
  | "datasetRecordingsTableId"
  | "dateAssignmentEnded"
  | "dateCreated"
  | "pinned"
  | "tenantId"
  | "width"
  | "identifierPosition"
  | "projectId"
> & {
    recordingsGroupFiles: { __typename: "RecordingGroupFilesConnection" } & {
      nodes: Array<
        { __typename: "RecordingGroupFile" } & Pick<RecordingGroupFile, "id">
      >;
    };
    datasetRecordingsTable: Maybe<
      { __typename: "DatasetRecordingsTable" } & Pick<
        DatasetRecordingsTable,
        "id"
      >
    >;
    metadata: Maybe<{ __typename: "Metadatum" } & Pick<Metadatum, "id">>;
    orders: { __typename: "DatasetRecordingsTableColumnOrdersConnection" } & {
      nodes: Array<
        { __typename: "DatasetRecordingsTableColumnOrder" } & Pick<
          DatasetRecordingsTableColumnOrder,
          "id"
        >
      >;
    };
    tenant: Maybe<{ __typename: "Tenant" } & Pick<Tenant, "id">>;
    project: Maybe<{ __typename: "Project" } & Pick<Project, "id">>;
  };

export type DatasetRecordingsTableColumnOrderCacheFragment = {
  __typename: "DatasetRecordingsTableColumnOrder";
} & Pick<
  DatasetRecordingsTableColumnOrder,
  | "id"
  | "datasetRecordingsTableColumnId"
  | "dateCreated"
  | "tenantId"
  | "value"
  | "projectId"
> & {
    column: Maybe<
      { __typename: "DatasetRecordingsTableColumn" } & Pick<
        DatasetRecordingsTableColumn,
        "id"
      >
    >;
    tenant: Maybe<{ __typename: "Tenant" } & Pick<Tenant, "id">>;
    project: Maybe<{ __typename: "Project" } & Pick<Project, "id">>;
  };

export type FileCacheFragment = { __typename: "File" } & Pick<
  File,
  | "id"
  | "datasetId"
  | "dateArchived"
  | "dateCreated"
  | "dateDataDeleted"
  | "dateDeleted"
  | "fileFormat"
  | "fileStructure"
  | "fileType"
  | "key"
  | "isSeries"
  | "location"
  | "multipartUploadId"
  | "name"
  | "parentIds"
  | "partSize"
  | "preview"
  | "projectId"
  | "signal"
  | "size"
  | "status"
  | "source"
  | "tenantId"
  | "uploadStatus"
  | "userId"
  | "originalName"
  | "seriesParentId"
  | "seriesOrder"
  | "processingStatus"
> & {
    fileBySeriesParentId: Maybe<{ __typename: "File" } & Pick<File, "id">>;
    seriesFiles: { __typename: "FilesConnection" } & {
      nodes: Array<{ __typename: "File" } & Pick<File, "id">>;
    };
    fileMetadata: { __typename: "FileMetadataConnection" } & {
      nodes: Array<{ __typename: "FileMetadatum" } & Pick<FileMetadatum, "id">>;
    };
    taskSources: { __typename: "TaskSourcesConnection" } & {
      nodes: Array<{ __typename: "TaskSource" } & Pick<TaskSource, "id">>;
    };
    tenant: Maybe<{ __typename: "Tenant" } & Pick<Tenant, "id">>;
    dataset: Maybe<{ __typename: "Dataset" } & Pick<Dataset, "id">>;
    user: Maybe<
      { __typename: "ApplicationUser" } & Pick<ApplicationUser, "id">
    >;
    fileActivities: { __typename: "FileActivitiesConnection" } & {
      nodes: Array<{ __typename: "FileActivity" } & Pick<FileActivity, "id">>;
    };
    project: Maybe<{ __typename: "Project" } & Pick<Project, "id">>;
    outputGroupFiles: { __typename: "OutputGroupFilesConnection" } & {
      nodes: Array<
        { __typename: "OutputGroupFile" } & Pick<OutputGroupFile, "id">
      >;
    };
    assignment: Maybe<
      { __typename: "RecordingGroupFile" } & Pick<RecordingGroupFile, "id">
    >;
    recordingGroupFiles: { __typename: "RecordingGroupFilesConnection" } & {
      nodes: Array<
        { __typename: "RecordingGroupFile" } & Pick<RecordingGroupFile, "id">
      >;
    };
    fileProcessingNotifications: {
      __typename: "FileProcessingNotificationsConnection";
    } & {
      nodes: Array<
        { __typename: "FileProcessingNotification" } & Pick<
          FileProcessingNotification,
          "id"
        >
      >;
    };
  };

export type MetadatumCacheFragment = { __typename: "Metadatum" } & Pick<
  Metadatum,
  "id" | "activeValue" | "displayName" | "key" | "projectId" | "tenantId"
> & {
    datasetMetadata: { __typename: "DatasetMetadataConnection" } & {
      nodes: Array<
        { __typename: "DatasetMetadatum" } & Pick<DatasetMetadatum, "id">
      >;
    };
    datasetRecordingsTableColumns: {
      __typename: "DatasetRecordingsTableColumnsConnection";
    } & {
      nodes: Array<
        { __typename: "DatasetRecordingsTableColumn" } & Pick<
          DatasetRecordingsTableColumn,
          "id"
        >
      >;
    };
    datasets: { __typename: "DatasetsConnection" } & {
      nodes: Array<{ __typename: "Dataset" } & Pick<Dataset, "id">>;
    };
    fileMetadata: { __typename: "FileMetadataConnection" } & {
      nodes: Array<{ __typename: "FileMetadatum" } & Pick<FileMetadatum, "id">>;
    };
    fileOutputGroupMetadata: {
      __typename: "FileOutputGroupMetadataConnection";
    } & {
      nodes: Array<
        { __typename: "FileOutputGroupMetadatum" } & Pick<
          FileOutputGroupMetadatum,
          "id"
        >
      >;
    };
    fileRecordingGroupMetadata: {
      __typename: "FileRecordingGroupMetadataConnection";
    } & {
      nodes: Array<
        { __typename: "FileRecordingGroupMetadatum" } & Pick<
          FileRecordingGroupMetadatum,
          "id"
        >
      >;
    };
    metadataValues: { __typename: "MetadataValuesConnection" } & {
      nodes: Array<{ __typename: "MetadataValue" } & Pick<MetadataValue, "id">>;
    };
    project: Maybe<{ __typename: "Project" } & Pick<Project, "id">>;
    tenant: Maybe<{ __typename: "Tenant" } & Pick<Tenant, "id">>;
  };

export type NotificationCacheFragment = { __typename: "Notification" } & Pick<
  Notification,
  "id" | "dateCreated" | "hasSeen" | "userId"
> & {
    user: Maybe<
      { __typename: "ApplicationUser" } & Pick<ApplicationUser, "id">
    >;
    projectSharedNotification: Maybe<
      { __typename: "ProjectSharedNotification" } & Pick<
        ProjectSharedNotification,
        "id"
      >
    >;
    projectSharedNotifications: {
      __typename: "ProjectSharedNotificationsConnection";
    } & {
      nodes: Array<
        { __typename: "ProjectSharedNotification" } & Pick<
          ProjectSharedNotification,
          "id"
        >
      >;
    };
    tenantMembershipNotification: Maybe<
      { __typename: "TenantUserMembershipNotification" } & Pick<
        TenantUserMembershipNotification,
        "id"
      >
    >;
    tenantMembershipNotifications: {
      __typename: "TenantUserMembershipNotificationsConnection";
    } & {
      nodes: Array<
        { __typename: "TenantUserMembershipNotification" } & Pick<
          TenantUserMembershipNotification,
          "id"
        >
      >;
    };
    fileProcessingNotifications: {
      __typename: "FileProcessingNotificationsConnection";
    } & {
      nodes: Array<
        { __typename: "FileProcessingNotification" } & Pick<
          FileProcessingNotification,
          "id"
        >
      >;
    };
    fileProcessingNotification: Maybe<
      { __typename: "FileProcessingNotification" } & Pick<
        FileProcessingNotification,
        "id"
      >
    >;
    roleChangeNotifications: {
      __typename: "RoleChangeNotificationsConnection";
    } & {
      nodes: Array<
        { __typename: "RoleChangeNotification" } & Pick<
          RoleChangeNotification,
          "id"
        >
      >;
    };
    roleChangeNotification: Maybe<
      { __typename: "RoleChangeNotification" } & Pick<
        RoleChangeNotification,
        "id"
      >
    >;
    projectClonedNotifications: {
      __typename: "ProjectClonedNotificationsConnection";
    } & {
      nodes: Array<
        { __typename: "ProjectClonedNotification" } & Pick<
          ProjectClonedNotification,
          "id"
        >
      >;
    };
    projectClonedNotification: Maybe<
      { __typename: "ProjectClonedNotification" } & Pick<
        ProjectClonedNotification,
        "id"
      >
    >;
  };

export type ProjectCacheFragment = { __typename: "Project" } & Pick<
  Project,
  | "id"
  | "key"
  | "name"
  | "shortDescription"
  | "status"
  | "iconText"
  | "iconColor"
  | "icon"
  | "description"
  | "dateDeleted"
  | "dateDataDeleted"
  | "dateCreated"
  | "dateArchived"
  | "archivedStorageSize"
  | "activeStorageSize"
  | "tenantId"
  | "prefix"
  | "userId"
  | "defaultUserAccessLevel"
  | "usedCredits"
> & {
    tenant: Maybe<{ __typename: "Tenant" } & Pick<Tenant, "id">>;
    activeFiles: { __typename: "FilesConnection" } & {
      nodes: Array<{ __typename: "File" } & Pick<File, "id">>;
    };
    analysisTables: { __typename: "AnalysisTablesConnection" } & {
      nodes: Array<{ __typename: "AnalysisTable" } & Pick<AnalysisTable, "id">>;
    };
    user: Maybe<
      { __typename: "ApplicationUser" } & Pick<ApplicationUser, "id">
    >;
    datasets: { __typename: "DatasetsConnection" } & {
      nodes: Array<{ __typename: "Dataset" } & Pick<Dataset, "id">>;
    };
    files: { __typename: "FilesConnection" } & {
      nodes: Array<{ __typename: "File" } & Pick<File, "id">>;
    };
    tasks: { __typename: "TasksConnection" } & {
      nodes: Array<{ __typename: "Task" } & Pick<Task, "id">>;
    };
    analysisTableRowAttachResults: {
      __typename: "AnalysisTableRowAttachResultsConnection";
    } & {
      nodes: Array<
        { __typename: "AnalysisTableRowAttachResult" } & Pick<
          AnalysisTableRowAttachResult,
          "id"
        >
      >;
    };
    analysisTableRows: { __typename: "AnalysisTableRowsConnection" } & {
      nodes: Array<
        { __typename: "AnalysisTableRow" } & Pick<AnalysisTableRow, "id">
      >;
    };
    datasetMetadata: { __typename: "DatasetMetadataConnection" } & {
      nodes: Array<
        { __typename: "DatasetMetadatum" } & Pick<DatasetMetadatum, "id">
      >;
    };
    datasetRecordingsTableColumnOrders: {
      __typename: "DatasetRecordingsTableColumnOrdersConnection";
    } & {
      nodes: Array<
        { __typename: "DatasetRecordingsTableColumnOrder" } & Pick<
          DatasetRecordingsTableColumnOrder,
          "id"
        >
      >;
    };
    datasetRecordingsTableColumns: {
      __typename: "DatasetRecordingsTableColumnsConnection";
    } & {
      nodes: Array<
        { __typename: "DatasetRecordingsTableColumn" } & Pick<
          DatasetRecordingsTableColumn,
          "id"
        >
      >;
    };
    datasetRecordingsTables: {
      __typename: "DatasetRecordingsTablesConnection";
    } & {
      nodes: Array<
        { __typename: "DatasetRecordingsTable" } & Pick<
          DatasetRecordingsTable,
          "id"
        >
      >;
    };
    datasetVersions: { __typename: "DatasetVersionsConnection" } & {
      nodes: Array<
        { __typename: "DatasetVersion" } & Pick<DatasetVersion, "id">
      >;
    };
    fileActivities: { __typename: "FileActivitiesConnection" } & {
      nodes: Array<{ __typename: "FileActivity" } & Pick<FileActivity, "id">>;
    };
    fileMetadata: { __typename: "FileMetadataConnection" } & {
      nodes: Array<{ __typename: "FileMetadatum" } & Pick<FileMetadatum, "id">>;
    };
    fileOutputGroupMetadata: {
      __typename: "FileOutputGroupMetadataConnection";
    } & {
      nodes: Array<
        { __typename: "FileOutputGroupMetadatum" } & Pick<
          FileOutputGroupMetadatum,
          "id"
        >
      >;
    };
    fileOutputGroups: { __typename: "FileOutputGroupsConnection" } & {
      nodes: Array<
        { __typename: "FileOutputGroup" } & Pick<FileOutputGroup, "id">
      >;
    };
    fileRecordingGroupMetadata: {
      __typename: "FileRecordingGroupMetadataConnection";
    } & {
      nodes: Array<
        { __typename: "FileRecordingGroupMetadatum" } & Pick<
          FileRecordingGroupMetadatum,
          "id"
        >
      >;
    };
    fileRecordingGroups: { __typename: "FileRecordingGroupsConnection" } & {
      nodes: Array<
        { __typename: "FileRecordingGroup" } & Pick<FileRecordingGroup, "id">
      >;
    };
    metadata: { __typename: "MetadataConnection" } & {
      nodes: Array<{ __typename: "Metadatum" } & Pick<Metadatum, "id">>;
    };
    metadataValues: { __typename: "MetadataValuesConnection" } & {
      nodes: Array<{ __typename: "MetadataValue" } & Pick<MetadataValue, "id">>;
    };
    outputGroupFiles: { __typename: "OutputGroupFilesConnection" } & {
      nodes: Array<
        { __typename: "OutputGroupFile" } & Pick<OutputGroupFile, "id">
      >;
    };
    recordingGroupFiles: { __typename: "RecordingGroupFilesConnection" } & {
      nodes: Array<
        { __typename: "RecordingGroupFile" } & Pick<RecordingGroupFile, "id">
      >;
    };
    projectSharedNotifications: {
      __typename: "ProjectSharedNotificationsConnection";
    } & {
      nodes: Array<
        { __typename: "ProjectSharedNotification" } & Pick<
          ProjectSharedNotification,
          "id"
        >
      >;
    };
    analysisTableGroups: { __typename: "AnalysisTableGroupsConnection" } & {
      nodes: Array<
        { __typename: "AnalysisTableGroup" } & Pick<AnalysisTableGroup, "id">
      >;
    };
    projectClonedNotifications: {
      __typename: "ProjectClonedNotificationsConnection";
    } & {
      nodes: Array<
        { __typename: "ProjectClonedNotification" } & Pick<
          ProjectClonedNotification,
          "id"
        >
      >;
    };
  };

export type FileRecordingGroupCacheFragment = {
  __typename: "FileRecordingGroup";
} & Pick<
  FileRecordingGroup,
  | "id"
  | "datasetRecordingsTableId"
  | "dateAssignmentEnded"
  | "dateCreated"
  | "tenantId"
  | "userId"
  | "number"
  | "projectId"
> & {
    user: Maybe<
      { __typename: "ApplicationUser" } & Pick<ApplicationUser, "id">
    >;
    datasetRecordingsTable: Maybe<
      { __typename: "DatasetRecordingsTable" } & Pick<
        DatasetRecordingsTable,
        "id"
      >
    >;
    assignments: { __typename: "RecordingGroupFilesConnection" } & {
      nodes: Array<
        { __typename: "RecordingGroupFile" } & Pick<RecordingGroupFile, "id">
      >;
    };
    recordingGroupMetadata: {
      __typename: "FileRecordingGroupMetadataConnection";
    } & {
      nodes: Array<
        { __typename: "FileRecordingGroupMetadatum" } & Pick<
          FileRecordingGroupMetadatum,
          "id"
        >
      >;
    };
    tenant: Maybe<{ __typename: "Tenant" } & Pick<Tenant, "id">>;
    project: Maybe<{ __typename: "Project" } & Pick<Project, "id">>;
  };

export type RecordingGroupFileCacheFragment = {
  __typename: "RecordingGroupFile";
} & Pick<
  RecordingGroupFile,
  | "id"
  | "fileId"
  | "dateCreated"
  | "dateAssignmentEnded"
  | "datasetRecordingsTableColumnId"
  | "userId"
  | "tenantId"
  | "fileRecordingGroupId"
  | "projectId"
> & {
    tenant: Maybe<{ __typename: "Tenant" } & Pick<Tenant, "id">>;
    user: Maybe<
      { __typename: "ApplicationUser" } & Pick<ApplicationUser, "id">
    >;
    recording: Maybe<
      { __typename: "FileRecordingGroup" } & Pick<FileRecordingGroup, "id">
    >;
    file: Maybe<{ __typename: "File" } & Pick<File, "id">>;
    column: Maybe<
      { __typename: "DatasetRecordingsTableColumn" } & Pick<
        DatasetRecordingsTableColumn,
        "id"
      >
    >;
    project: Maybe<{ __typename: "Project" } & Pick<Project, "id">>;
  };

export type AnalysisTableFieldsFragment = {
  __typename: "AnalysisTable";
} & Pick<
  AnalysisTable,
  | "id"
  | "name"
  | "dateCreated"
  | "projectId"
  | "tenantId"
  | "toolVersionId"
  | "userId"
  | "identifiers"
>;

export type AnalysisTableRowAttachResultFieldsFragment = {
  __typename: "AnalysisTableRowAttachResult";
} & Pick<
  AnalysisTableRowAttachResult,
  "id" | "dateCreated" | "analysisTableRowId" | "attachResults"
>;

export type AnalysisTableRowFieldsFragment = {
  __typename: "AnalysisTableRow";
} & Pick<
  AnalysisTableRow,
  | "id"
  | "data"
  | "groups"
  | "dateCreated"
  | "selections"
  | "tableId"
  | "taskId"
  | "tenantId"
  | "attachments"
>;

export type AvatarFieldsFragment = { __typename: "Avatar" } & Pick<
  Avatar,
  "id" | "color" | "initials" | "useImage" | "imageBase64"
>;

export type DatasetFieldsFragment = { __typename: "Dataset" } & Pick<
  Dataset,
  | "id"
  | "dateArchived"
  | "dateCreated"
  | "dateDeleted"
  | "dateDataDeleted"
  | "key"
  | "prefix"
  | "name"
  | "tenantId"
  | "userId"
  | "recordingsTableId"
  | "projectId"
  | "status"
  | "descriptionId"
>;

export type DatasetRecordingsTableColumnFieldsFragment = {
  __typename: "DatasetRecordingsTableColumn";
} & Pick<
  DatasetRecordingsTableColumn,
  | "id"
  | "dateCreated"
  | "dateAssignmentEnded"
  | "tenantId"
  | "pinned"
  | "width"
  | "colDefId"
  | "identifierPosition"
>;

export type DatasetRecordingsTableFieldsFragment = {
  __typename: "DatasetRecordingsTable";
} & Pick<DatasetRecordingsTable, "id" | "name" | "views" | "tenantId">;

export type DatasetVersionFieldsFragment = {
  __typename: "DatasetVersion";
} & Pick<
  DatasetVersion,
  | "id"
  | "datasetId"
  | "dateCreated"
  | "name"
  | "description"
  | "tenantId"
  | "validationState"
  | "views"
>;

export type FileFieldsFragment = { __typename: "File" } & Pick<
  File,
  | "id"
  | "dateArchived"
  | "dateCreated"
  | "dateDataDeleted"
  | "dateDeleted"
  | "fileType"
  | "fileFormat"
  | "fileStructure"
  | "key"
  | "location"
  | "multipartUploadId"
  | "name"
  | "parentIds"
  | "partSize"
  | "projectId"
  | "signal"
  | "size"
  | "status"
  | "source"
  | "tenantId"
  | "uploadStatus"
  | "userId"
  | "originalName"
  | "seriesParentId"
  | "datasetId"
  | "isSeries"
  | "seriesOrder"
  | "processingStatus"
>;

export type MetadataValueFieldsFragment = {
  __typename: "MetadataValue";
} & Pick<
  MetadataValue,
  | "id"
  | "value"
  | "dateCreated"
  | "metadataId"
  | "taskId"
  | "userId"
  | "tenantId"
  | "projectId"
>;

export type MetadatumFieldsFragment = { __typename: "Metadatum" } & Pick<
  Metadatum,
  "id" | "key" | "displayName" | "tenantId"
>;

export type FileOutputGroupFieldsFragment = {
  __typename: "FileOutputGroup";
} & Pick<
  FileOutputGroup,
  "id" | "dateCreated" | "taskId" | "tenantId" | "userId" | "groupType"
>;

export type ProjectFieldsFragment = { __typename: "Project" } & Pick<
  Project,
  | "id"
  | "key"
  | "name"
  | "shortDescription"
  | "description"
  | "icon"
  | "iconColor"
  | "iconText"
  | "dateCreated"
  | "tenantId"
  | "userId"
  | "dateArchived"
  | "dateDeleted"
  | "dateDataDeleted"
  | "status"
  | "activeStorageSize"
  | "archivedStorageSize"
  | "defaultUserAccessLevel"
>;

export type RecordingGroupFileFieldsFragment = {
  __typename: "RecordingGroupFile";
} & Pick<
  RecordingGroupFile,
  | "id"
  | "datasetRecordingsTableColumnId"
  | "fileRecordingGroupId"
  | "fileId"
  | "tenantId"
  | "userId"
  | "dateCreated"
  | "dateAssignmentEnded"
>;

export type FileRecordingGroupFieldsFragment = {
  __typename: "FileRecordingGroup";
} & Pick<
  FileRecordingGroup,
  "id" | "dateCreated" | "dateAssignmentEnded" | "tenantId" | "userId"
>;

export type FileRecordingGroupMetadatumFieldsFragment = {
  __typename: "FileRecordingGroupMetadatum";
} & Pick<
  FileRecordingGroupMetadatum,
  "id" | "fileRecordingGroupId" | "metadataId" | "dateCreated" | "tenantId"
>;

export type TagFieldsFragment = { __typename: "Tag" } & Pick<
  Tag,
  "id" | "label" | "parentId" | "parameters" | "dateCreated" | "userId"
>;

export type TaskFieldsFragment = { __typename: "Task" } & Pick<
  Task,
  | "id"
  | "taskParameters"
  | "toolExitcode"
  | "toolExitcodeDescription"
  | "created"
  | "tenantId"
  | "toolVersionId"
  | "userId"
  | "status"
  | "datasetId"
  | "credits"
  | "cloned"
>;

export type TenantContractAllowanceFieldsFragment = {
  __typename: "TenantContractAllowance";
} & Pick<TenantContractAllowance, "id" | "quotaType" | "value">;

export type TenantContractFieldsFragment = {
  __typename: "TenantContract";
} & Pick<TenantContract, "id" | "name" | "created" | "startDate" | "endDate">;

export type TenantContractTransactionFieldsFragment = {
  __typename: "TenantContractTransaction";
} & Pick<TenantContractTransaction, "id" | "created" | "currency" | "price">;

export type TenantFieldsFragment = { __typename: "Tenant" } & Pick<
  Tenant,
  "id" | "name" | "key"
>;

export type ToolCategoryFieldsFragment = { __typename: "ToolCategory" } & Pick<
  ToolCategory,
  "id" | "key" | "name" | "order" | "description"
>;

export type ToolFieldsFragment = { __typename: "Tool" } & Pick<
  Tool,
  "id" | "name" | "key" | "helpText" | "created"
>;

export type ToolParameterFieldsFragment = {
  __typename: "ToolParameter";
} & Pick<
  ToolParameter,
  | "id"
  | "name"
  | "type"
  | "toolVersionId"
  | "helpText"
  | "default"
  | "options"
  | "unit"
  | "key"
>;

export type ToolResultFieldsFragment = { __typename: "ToolResult" } & Pick<
  ToolResult,
  "id" | "key" | "name" | "toolVersionId" | "helpText"
>;

export type ToolSourceFieldsFragment = { __typename: "ToolSource" } & Pick<
  ToolSource,
  "id" | "key" | "name" | "toolVersionId" | "required" | "helpText"
>;

export type ToolVersionFieldsFragment = { __typename: "ToolVersion" } & Pick<
  ToolVersion,
  "id" | "version" | "created" | "maturity" | "credits"
> & {
    tool: Maybe<
      { __typename: "Tool" } & Pick<
        Tool,
        "id" | "name" | "key" | "helpText" | "created"
      >
    >;
  };

export type UserFieldsFragment = { __typename: "ApplicationUser" } & Pick<
  ApplicationUser,
  | "id"
  | "username"
  | "firstName"
  | "lastName"
  | "email"
  | "internal"
  | "isActive"
>;

export type UserMetadatumFieldsFragment = {
  __typename: "ApplicationUserMetadatum";
} & Pick<ApplicationUserMetadatum, "id" | "userId" | "key" | "value">;

export type UserSessionFieldsFragment = {
  __typename: "ApplicationUserSession";
} & Pick<
  ApplicationUserSession,
  | "id"
  | "userId"
  | "tenantId"
  | "active"
  | "lastPath"
  | "state"
  | "datetimeLastActive"
>;

export type CreateAnalysisTableMutationVariables = Exact<{
  input: CreateAnalysisTableInput;
}>;

export type CreateAnalysisTableMutation = { __typename: "Mutation" } & {
  createAnalysisTable: Maybe<
    { __typename: "CreateAnalysisTablePayload" } & Pick<
      CreateAnalysisTablePayload,
      "clientMutationId"
    > & {
        analysisTable: Maybe<
          { __typename: "AnalysisTable" } & Pick<
            AnalysisTable,
            | "id"
            | "name"
            | "dateCreated"
            | "projectId"
            | "tenantId"
            | "toolVersionId"
            | "userId"
            | "identifiers"
          > & {
              toolVersion: Maybe<
                { __typename: "ToolVersion" } & Pick<
                  ToolVersion,
                  | "toolSpec"
                  | "id"
                  | "version"
                  | "created"
                  | "maturity"
                  | "credits"
                > & {
                    tool: Maybe<
                      { __typename: "Tool" } & Pick<
                        Tool,
                        "id" | "name" | "key" | "helpText" | "created"
                      >
                    >;
                  }
              >;
            }
        >;
      }
  >;
};

export type CreateAnalysisTableGroupMutationVariables = Exact<{
  input: CreateAnalysisTableGroupInput;
}>;

export type CreateAnalysisTableGroupMutation = { __typename: "Mutation" } & {
  createAnalysisTableGroup: Maybe<
    { __typename: "CreateAnalysisTableGroupPayload" } & Pick<
      CreateAnalysisTableGroupPayload,
      "clientMutationId"
    > & {
        analysisTableGroup: Maybe<
          { __typename: "AnalysisTableGroup" } & Pick<
            AnalysisTableGroup,
            | "id"
            | "dateCreated"
            | "description"
            | "hidden"
            | "name"
            | "projectId"
            | "tenantId"
            | "toolId"
            | "userId"
          > & {
              project: Maybe<{ __typename: "Project" } & Pick<Project, "id">>;
              user: Maybe<
                { __typename: "ApplicationUser" } & Pick<ApplicationUser, "id">
              >;
              analysisTables: { __typename: "AnalysisTablesConnection" } & {
                nodes: Array<
                  { __typename: "AnalysisTable" } & Pick<AnalysisTable, "id">
                >;
              };
              tool: Maybe<{ __typename: "Tool" } & Pick<Tool, "id" | "key">>;
              tenant: Maybe<{ __typename: "Tenant" } & Pick<Tenant, "id">>;
            }
        >;
      }
  >;
};

export type CreateAnalysisTableRowAttachResultMutationVariables = Exact<{
  input: CreateAnalysisTableRowAttachResultInput;
}>;

export type CreateAnalysisTableRowAttachResultMutation = {
  __typename: "Mutation";
} & {
  createAnalysisTableRowAttachResult: Maybe<
    { __typename: "CreateAnalysisTableRowAttachResultPayload" } & Pick<
      CreateAnalysisTableRowAttachResultPayload,
      "clientMutationId"
    > & {
        analysisTableRowAttachResult: Maybe<
          { __typename: "AnalysisTableRowAttachResult" } & Pick<
            AnalysisTableRowAttachResult,
            "id" | "dateCreated" | "analysisTableRowId" | "attachResults"
          >
        >;
      }
  >;
};

export type CreateDatasetMutationVariables = Exact<{
  dataset: DatasetInput;
}>;

export type CreateDatasetMutation = { __typename: "Mutation" } & {
  createDataset: Maybe<
    { __typename: "CreateDatasetPayload" } & Pick<
      CreateDatasetPayload,
      "clientMutationId"
    > & {
        dataset: Maybe<
          { __typename: "Dataset" } & Pick<
            Dataset,
            | "id"
            | "dateArchived"
            | "dateCreated"
            | "dateDeleted"
            | "dateDataDeleted"
            | "key"
            | "prefix"
            | "name"
            | "tenantId"
            | "userId"
            | "recordingsTableId"
            | "projectId"
            | "status"
            | "descriptionId"
          > & {
              user: Maybe<
                { __typename: "ApplicationUser" } & Pick<
                  ApplicationUser,
                  | "id"
                  | "username"
                  | "firstName"
                  | "lastName"
                  | "email"
                  | "internal"
                  | "isActive"
                >
              >;
            }
        >;
      }
  >;
};

export type CreateMetadataValueMutationVariables = Exact<{
  metadataValue: MetadataValueInput;
}>;

export type CreateMetadataValueMutation = { __typename: "Mutation" } & {
  createMetadataValue: Maybe<
    { __typename: "CreateMetadataValuePayload" } & Pick<
      CreateMetadataValuePayload,
      "clientMutationId"
    > & {
        metadataValue: Maybe<
          { __typename: "MetadataValue" } & Pick<
            MetadataValue,
            | "id"
            | "value"
            | "dateCreated"
            | "metadataId"
            | "taskId"
            | "userId"
            | "tenantId"
            | "projectId"
          >
        >;
      }
  >;
};

export type CreateMetadatumMutationVariables = Exact<{
  metadata: MetadatumInput;
}>;

export type CreateMetadatumMutation = { __typename: "Mutation" } & {
  createMetadata: Maybe<
    { __typename: "CreateMetadatumPayload" } & Pick<
      CreateMetadatumPayload,
      "clientMutationId"
    > & {
        metadata: Maybe<
          { __typename: "Metadatum" } & Pick<
            Metadatum,
            "id" | "key" | "displayName" | "tenantId"
          >
        >;
      }
  >;
};

export type CreateUserMetadatumMutationVariables = Exact<{
  userMetadatum: ApplicationUserMetadatumInput;
}>;

export type CreateUserMetadatumMutation = { __typename: "Mutation" } & {
  createUserMetadatum: Maybe<
    { __typename: "CreateApplicationUserMetadatumPayload" } & Pick<
      CreateApplicationUserMetadatumPayload,
      "clientMutationId"
    > & {
        userMetadatum: Maybe<
          { __typename: "ApplicationUserMetadatum" } & Pick<
            ApplicationUserMetadatum,
            "id" | "userId" | "key" | "value"
          >
        >;
      }
  >;
};

export type CreateUserSessionMutationVariables = Exact<{
  id: Scalars["UUID"]["input"];
  userid: Scalars["UUID"]["input"];
  tenant?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CreateUserSessionMutation = { __typename: "Mutation" } & {
  createUserSession: Maybe<
    { __typename: "CreateSessionPayload" } & Pick<
      CreateSessionPayload,
      "clientMutationId"
    > & {
        userSession: Maybe<
          { __typename: "ApplicationUserSession" } & Pick<
            ApplicationUserSession,
            | "id"
            | "userId"
            | "tenantId"
            | "active"
            | "lastPath"
            | "state"
            | "datetimeLastActive"
          >
        >;
      }
  >;
};

export type UpdateAnalysisTableByIdMutationVariables = Exact<{
  id: Scalars["BigInt"]["input"];
  patch: AnalysisTablePatch;
}>;

export type UpdateAnalysisTableByIdMutation = { __typename: "Mutation" } & {
  updateAnalysisTableById: Maybe<
    { __typename: "UpdateAnalysisTablePayload" } & Pick<
      UpdateAnalysisTablePayload,
      "clientMutationId"
    > & {
        analysisTable: Maybe<
          { __typename: "AnalysisTable" } & Pick<
            AnalysisTable,
            | "id"
            | "name"
            | "dateCreated"
            | "projectId"
            | "tenantId"
            | "toolVersionId"
            | "userId"
            | "identifiers"
          > & {
              toolVersion: Maybe<
                { __typename: "ToolVersion" } & Pick<
                  ToolVersion,
                  "id" | "version" | "created" | "maturity" | "credits"
                > & {
                    tool: Maybe<
                      { __typename: "Tool" } & Pick<
                        Tool,
                        "id" | "name" | "key" | "helpText" | "created"
                      >
                    >;
                  }
              >;
              analysisTableRows: {
                __typename: "AnalysisTableRowsConnection";
              } & {
                nodes: Array<
                  { __typename: "AnalysisTableRow" } & Pick<
                    AnalysisTableRow,
                    | "id"
                    | "data"
                    | "groups"
                    | "dateCreated"
                    | "selections"
                    | "tableId"
                    | "taskId"
                    | "tenantId"
                    | "attachments"
                  > & {
                      task: Maybe<
                        { __typename: "Task" } & Pick<
                          Task,
                          | "id"
                          | "taskParameters"
                          | "toolExitcode"
                          | "toolExitcodeDescription"
                          | "created"
                          | "tenantId"
                          | "toolVersionId"
                          | "userId"
                          | "status"
                          | "datasetId"
                          | "credits"
                          | "cloned"
                        >
                      >;
                    }
                >;
              };
            }
        >;
      }
  >;
};

export type UpdateAnalysisTableGroupByIdMutationVariables = Exact<{
  id: Scalars["BigInt"]["input"];
  patch: AnalysisTableGroupPatch;
}>;

export type UpdateAnalysisTableGroupByIdMutation = {
  __typename: "Mutation";
} & {
  updateAnalysisTableGroupById: Maybe<
    { __typename: "UpdateAnalysisTableGroupPayload" } & Pick<
      UpdateAnalysisTableGroupPayload,
      "clientMutationId"
    > & {
        analysisTableGroup: Maybe<
          { __typename: "AnalysisTableGroup" } & Pick<
            AnalysisTableGroup,
            "id" | "hidden" | "name"
          >
        >;
      }
  >;
};

export type UpdateApplicationUserByIdMutationVariables = Exact<{
  id: Scalars["UUID"]["input"];
  patch: ApplicationUserPatch;
}>;

export type UpdateApplicationUserByIdMutation = { __typename: "Mutation" } & {
  updateApplicationUserById: Maybe<
    { __typename: "UpdateApplicationUserPayload" } & Pick<
      UpdateApplicationUserPayload,
      "clientMutationId"
    > & {
        applicationUser: Maybe<
          { __typename: "ApplicationUser" } & Pick<
            ApplicationUser,
            | "id"
            | "username"
            | "firstName"
            | "lastName"
            | "email"
            | "internal"
            | "isActive"
          > & {
              metadata: { __typename: "ApplicationUserMetadataConnection" } & {
                nodes: Array<
                  { __typename: "ApplicationUserMetadatum" } & Pick<
                    ApplicationUserMetadatum,
                    "id" | "userId" | "key" | "value"
                  >
                >;
              };
            }
        >;
      }
  >;
};

export type UpdateAvatarByIdMutationVariables = Exact<{
  id: Scalars["BigInt"]["input"];
  patch: AvatarPatch;
}>;

export type UpdateAvatarByIdMutation = { __typename: "Mutation" } & {
  updateAvatarById: Maybe<
    { __typename: "UpdateAvatarPayload" } & Pick<
      UpdateAvatarPayload,
      "clientMutationId"
    > & {
        avatar: Maybe<
          { __typename: "Avatar" } & Pick<
            Avatar,
            "id" | "color" | "initials" | "useImage" | "imageBase64"
          >
        >;
      }
  >;
};

export type UpdateDatasetByIdMutationVariables = Exact<{
  id: Scalars["UUID"]["input"];
  patch: DatasetPatch;
}>;

export type UpdateDatasetByIdMutation = { __typename: "Mutation" } & {
  updateDataset: Maybe<
    { __typename: "UpdateDatasetPayload" } & Pick<
      UpdateDatasetPayload,
      "clientMutationId"
    > & {
        dataset: Maybe<
          { __typename: "Dataset" } & Pick<
            Dataset,
            | "id"
            | "dateArchived"
            | "dateCreated"
            | "dateDeleted"
            | "dateDataDeleted"
            | "key"
            | "prefix"
            | "name"
            | "tenantId"
            | "userId"
            | "recordingsTableId"
            | "projectId"
            | "status"
            | "descriptionId"
          >
        >;
      }
  >;
};

export type UpdateUserMetadatumByIdMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  patch: ApplicationUserMetadatumPatch;
}>;

export type UpdateUserMetadatumByIdMutation = { __typename: "Mutation" } & {
  updateUserMetadatumById: Maybe<
    { __typename: "UpdateApplicationUserMetadatumPayload" } & Pick<
      UpdateApplicationUserMetadatumPayload,
      "clientMutationId"
    > & {
        userMetadatum: Maybe<
          { __typename: "ApplicationUserMetadatum" } & Pick<
            ApplicationUserMetadatum,
            "id" | "userId" | "key" | "value"
          >
        >;
      }
  >;
};

export type GetAllDatasetRecordingsTableColumnsQueryVariables = Exact<{
  datasetRecordingsTableId: Scalars["UUID"]["input"];
}>;

export type GetAllDatasetRecordingsTableColumnsQuery = {
  __typename: "Query";
} & {
  datasetRecordingsTableColumns: Maybe<
    { __typename: "DatasetRecordingsTableColumnsConnection" } & {
      nodes: Array<
        { __typename: "DatasetRecordingsTableColumn" } & Pick<
          DatasetRecordingsTableColumn,
          | "id"
          | "dateCreated"
          | "dateAssignmentEnded"
          | "tenantId"
          | "pinned"
          | "width"
          | "colDefId"
          | "identifierPosition"
        > & {
            colDef: Maybe<
              { __typename: "Metadatum" } & Pick<
                Metadatum,
                "id" | "key" | "displayName" | "tenantId"
              > & {
                  metadataValues: { __typename: "MetadataValuesConnection" } & {
                    nodes: Array<
                      { __typename: "MetadataValue" } & Pick<
                        MetadataValue,
                        | "id"
                        | "value"
                        | "dateCreated"
                        | "metadataId"
                        | "taskId"
                        | "userId"
                        | "tenantId"
                        | "projectId"
                      >
                    >;
                  };
                }
            >;
          }
      >;
    }
  >;
};

export type GetAllDatasetsQueryVariables = Exact<{
  filter?: InputMaybe<DatasetFilter>;
  orderBy?: InputMaybe<Array<DatasetsOrderBy> | DatasetsOrderBy>;
}>;

export type GetAllDatasetsQuery = { __typename: "Query" } & {
  datasets: Maybe<
    { __typename: "DatasetsConnection" } & {
      nodes: Array<
        { __typename: "Dataset" } & Pick<
          Dataset,
          | "id"
          | "dateArchived"
          | "dateCreated"
          | "dateDeleted"
          | "dateDataDeleted"
          | "key"
          | "prefix"
          | "name"
          | "tenantId"
          | "userId"
          | "recordingsTableId"
          | "projectId"
          | "status"
          | "descriptionId"
        > & {
            user: Maybe<
              { __typename: "ApplicationUser" } & Pick<
                ApplicationUser,
                | "id"
                | "username"
                | "firstName"
                | "lastName"
                | "email"
                | "internal"
                | "isActive"
              >
            >;
          }
      >;
    }
  >;
};

export type GetAllProjectsQueryVariables = Exact<{
  filter?: InputMaybe<ProjectFilter>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy> | ProjectsOrderBy>;
}>;

export type GetAllProjectsQuery = { __typename: "Query" } & {
  projects: Maybe<
    { __typename: "ProjectsConnection" } & {
      nodes: Array<
        { __typename: "Project" } & Pick<
          Project,
          | "id"
          | "key"
          | "name"
          | "shortDescription"
          | "description"
          | "icon"
          | "iconColor"
          | "iconText"
          | "dateCreated"
          | "tenantId"
          | "userId"
          | "dateArchived"
          | "dateDeleted"
          | "dateDataDeleted"
          | "status"
          | "activeStorageSize"
          | "archivedStorageSize"
          | "defaultUserAccessLevel"
        > & {
            user: Maybe<
              { __typename: "ApplicationUser" } & Pick<
                ApplicationUser,
                | "id"
                | "username"
                | "firstName"
                | "lastName"
                | "email"
                | "internal"
                | "isActive"
              >
            >;
            tenantByTenantId: Maybe<
              { __typename: "Tenant" } & Pick<Tenant, "id" | "name" | "key">
            >;
          }
      >;
    }
  >;
};

export type GetAllFileRecordingGroupsQueryVariables = Exact<{
  filter?: InputMaybe<FileRecordingGroupFilter>;
}>;

export type GetAllFileRecordingGroupsQuery = { __typename: "Query" } & {
  recordingGroups: Maybe<
    { __typename: "FileRecordingGroupsConnection" } & {
      nodes: Array<
        { __typename: "FileRecordingGroup" } & Pick<
          FileRecordingGroup,
          "id" | "dateCreated" | "dateAssignmentEnded" | "tenantId" | "userId"
        > & {
            recordingGroupDrsFiles: {
              __typename: "RecordingGroupFilesConnection";
            } & {
              nodes: Array<
                { __typename: "RecordingGroupFile" } & Pick<
                  RecordingGroupFile,
                  | "id"
                  | "datasetRecordingsTableColumnId"
                  | "fileRecordingGroupId"
                  | "fileId"
                  | "tenantId"
                  | "userId"
                  | "dateCreated"
                  | "dateAssignmentEnded"
                > & {
                    drsFile: Maybe<
                      { __typename: "File" } & Pick<
                        File,
                        | "id"
                        | "dateArchived"
                        | "dateCreated"
                        | "dateDataDeleted"
                        | "dateDeleted"
                        | "fileType"
                        | "fileFormat"
                        | "fileStructure"
                        | "key"
                        | "location"
                        | "multipartUploadId"
                        | "name"
                        | "parentIds"
                        | "partSize"
                        | "projectId"
                        | "signal"
                        | "size"
                        | "status"
                        | "source"
                        | "tenantId"
                        | "uploadStatus"
                        | "userId"
                        | "originalName"
                        | "seriesParentId"
                        | "datasetId"
                        | "isSeries"
                        | "seriesOrder"
                        | "processingStatus"
                      >
                    >;
                  }
              >;
            };
          }
      >;
    }
  >;
};

export type GetAllTasksQueryVariables = Exact<{
  condition?: InputMaybe<TaskCondition>;
  filter?: InputMaybe<TaskFilter>;
}>;

export type GetAllTasksQuery = { __typename: "Query" } & {
  tasks: Maybe<
    { __typename: "TasksConnection" } & {
      nodes: Array<
        { __typename: "Task" } & Pick<
          Task,
          | "id"
          | "taskParameters"
          | "toolExitcode"
          | "toolExitcodeDescription"
          | "created"
          | "tenantId"
          | "toolVersionId"
          | "userId"
          | "status"
          | "datasetId"
          | "credits"
          | "cloned"
        > & {
            dataset: Maybe<
              { __typename: "Dataset" } & Pick<
                Dataset,
                | "id"
                | "dateArchived"
                | "dateCreated"
                | "dateDeleted"
                | "dateDataDeleted"
                | "key"
                | "prefix"
                | "name"
                | "tenantId"
                | "userId"
                | "recordingsTableId"
                | "projectId"
                | "status"
                | "descriptionId"
              >
            >;
            toolVersion: Maybe<
              { __typename: "ToolVersion" } & Pick<
                ToolVersion,
                "id" | "version" | "created" | "maturity" | "credits"
              > & {
                  tool: Maybe<
                    { __typename: "Tool" } & Pick<
                      Tool,
                      "id" | "name" | "key" | "helpText" | "created"
                    >
                  >;
                }
            >;
            outputGroup: Maybe<
              { __typename: "FileOutputGroup" } & Pick<
                FileOutputGroup,
                | "id"
                | "dateCreated"
                | "taskId"
                | "tenantId"
                | "userId"
                | "groupType"
              > & {
                  task: Maybe<
                    { __typename: "Task" } & Pick<
                      Task,
                      "id" | "status" | "created"
                    > & {
                        analysisTableRow: Maybe<
                          { __typename: "AnalysisTableRow" } & Pick<
                            AnalysisTableRow,
                            "id" | "attachments" | "activeAttachResults"
                          > & { analysisTableId: AnalysisTableRow["tableId"] }
                        >;
                      }
                  >;
                  outputGroupFiles: {
                    __typename: "OutputGroupFilesConnection";
                  } & {
                    nodes: Array<
                      { __typename: "OutputGroupFile" } & Pick<
                        OutputGroupFile,
                        "id"
                      > & {
                          drsFile: Maybe<
                            { __typename: "File" } & Pick<
                              File,
                              | "id"
                              | "datasetId"
                              | "dateArchived"
                              | "dateCreated"
                              | "dateDataDeleted"
                              | "dateDeleted"
                              | "fileFormat"
                              | "fileStructure"
                              | "fileType"
                              | "key"
                              | "isSeries"
                              | "location"
                              | "multipartUploadId"
                              | "name"
                              | "parentIds"
                              | "partSize"
                              | "preview"
                              | "projectId"
                              | "signal"
                              | "size"
                              | "status"
                              | "source"
                              | "tenantId"
                              | "uploadStatus"
                              | "userId"
                              | "originalName"
                              | "seriesParentId"
                              | "seriesOrder"
                              | "processingStatus"
                            > & {
                                fileMetadata: {
                                  __typename: "FileMetadataConnection";
                                } & {
                                  nodes: Array<
                                    { __typename: "FileMetadatum" } & Pick<
                                      FileMetadatum,
                                      "id"
                                    > & {
                                        metadata: Maybe<
                                          { __typename: "Metadatum" } & Pick<
                                            Metadatum,
                                            "id" | "key" | "activeValue"
                                          >
                                        >;
                                      }
                                  >;
                                };
                                fileBySeriesParentId: Maybe<
                                  { __typename: "File" } & Pick<File, "id">
                                >;
                                seriesFiles: {
                                  __typename: "FilesConnection";
                                } & {
                                  nodes: Array<
                                    { __typename: "File" } & Pick<File, "id">
                                  >;
                                };
                                taskSources: {
                                  __typename: "TaskSourcesConnection";
                                } & {
                                  nodes: Array<
                                    { __typename: "TaskSource" } & Pick<
                                      TaskSource,
                                      "id"
                                    >
                                  >;
                                };
                                tenant: Maybe<
                                  { __typename: "Tenant" } & Pick<Tenant, "id">
                                >;
                                dataset: Maybe<
                                  { __typename: "Dataset" } & Pick<
                                    Dataset,
                                    "id"
                                  >
                                >;
                                user: Maybe<
                                  { __typename: "ApplicationUser" } & Pick<
                                    ApplicationUser,
                                    "id"
                                  >
                                >;
                                fileActivities: {
                                  __typename: "FileActivitiesConnection";
                                } & {
                                  nodes: Array<
                                    { __typename: "FileActivity" } & Pick<
                                      FileActivity,
                                      "id"
                                    >
                                  >;
                                };
                                project: Maybe<
                                  { __typename: "Project" } & Pick<
                                    Project,
                                    "id"
                                  >
                                >;
                                outputGroupFiles: {
                                  __typename: "OutputGroupFilesConnection";
                                } & {
                                  nodes: Array<
                                    { __typename: "OutputGroupFile" } & Pick<
                                      OutputGroupFile,
                                      "id"
                                    >
                                  >;
                                };
                                assignment: Maybe<
                                  { __typename: "RecordingGroupFile" } & Pick<
                                    RecordingGroupFile,
                                    "id"
                                  >
                                >;
                                recordingGroupFiles: {
                                  __typename: "RecordingGroupFilesConnection";
                                } & {
                                  nodes: Array<
                                    { __typename: "RecordingGroupFile" } & Pick<
                                      RecordingGroupFile,
                                      "id"
                                    >
                                  >;
                                };
                                fileProcessingNotifications: {
                                  __typename: "FileProcessingNotificationsConnection";
                                } & {
                                  nodes: Array<
                                    {
                                      __typename: "FileProcessingNotification";
                                    } & Pick<FileProcessingNotification, "id">
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                  metadata: {
                    __typename: "FileOutputGroupMetadataConnection";
                  } & {
                    nodes: Array<
                      { __typename: "FileOutputGroupMetadatum" } & Pick<
                        FileOutputGroupMetadatum,
                        "id"
                      > & {
                          metadatum: Maybe<
                            { __typename: "Metadatum" } & Pick<
                              Metadatum,
                              "id" | "key" | "displayName" | "tenantId"
                            > & {
                                values: {
                                  __typename: "MetadataValuesConnection";
                                } & {
                                  nodes: Array<
                                    { __typename: "MetadataValue" } & Pick<
                                      MetadataValue,
                                      | "id"
                                      | "value"
                                      | "dateCreated"
                                      | "metadataId"
                                      | "taskId"
                                      | "userId"
                                      | "tenantId"
                                      | "projectId"
                                    >
                                  >;
                                };
                              }
                          >;
                        }
                    >;
                  };
                }
            >;
            user: Maybe<
              { __typename: "ApplicationUser" } & Pick<
                ApplicationUser,
                | "id"
                | "username"
                | "firstName"
                | "lastName"
                | "email"
                | "internal"
                | "isActive"
              >
            >;
            taskActivityByTaskId: Maybe<
              { __typename: "TaskActivity" } & Pick<TaskActivity, "id"> & {
                  duration: { __typename: "Interval" } & Pick<
                    Interval,
                    | "hours"
                    | "seconds"
                    | "minutes"
                    | "days"
                    | "years"
                    | "months"
                  >;
                }
            >;
            taskResourcesByTaskId: { __typename: "TaskResourcesConnection" } & {
              nodes: Array<
                { __typename: "TaskResource" } & Pick<
                  TaskResource,
                  "id" | "type" | "value"
                >
              >;
            };
          }
      >;
    }
  >;
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = { __typename: "Query" } & {
  currentUser: Maybe<
    { __typename: "ApplicationUser" } & Pick<
      ApplicationUser,
      | "id"
      | "username"
      | "firstName"
      | "lastName"
      | "email"
      | "internal"
      | "isActive"
    > & {
        metadata: { __typename: "ApplicationUserMetadataConnection" } & {
          nodes: Array<
            { __typename: "ApplicationUserMetadatum" } & Pick<
              ApplicationUserMetadatum,
              "id" | "userId" | "key" | "value"
            >
          >;
        };
        memberships: { __typename: "ApplicationUserMembershipsConnection" } & {
          nodes: Array<
            { __typename: "ApplicationUserMembership" } & Pick<
              ApplicationUserMembership,
              "id"
            > & {
                tenant: Maybe<
                  { __typename: "Tenant" } & Pick<Tenant, "id" | "name" | "key">
                >;
              }
          >;
        };
      }
  >;
};

export type GetFileMetadataByFileIdQueryVariables = Exact<{
  drsFileId: Scalars["UUID"]["input"];
  metadataFilter?: InputMaybe<FileMetadatumFilter>;
}>;

export type GetFileMetadataByFileIdQuery = { __typename: "Query" } & {
  drsFile: Maybe<
    { __typename: "File" } & Pick<File, "id"> & {
        metadata: { __typename: "FileMetadataConnection" } & {
          nodes: Array<
            { __typename: "FileMetadatum" } & Pick<FileMetadatum, "id"> & {
                metadatum: Maybe<
                  { __typename: "Metadatum" } & Pick<
                    Metadatum,
                    "activeValue" | "id" | "key" | "displayName" | "tenantId"
                  >
                >;
              }
          >;
        };
        seriesParentFile: Maybe<
          { __typename: "File" } & Pick<File, "id"> & {
              metadata: { __typename: "FileMetadataConnection" } & {
                nodes: Array<
                  { __typename: "FileMetadatum" } & Pick<
                    FileMetadatum,
                    "id"
                  > & {
                      metadatum: Maybe<
                        { __typename: "Metadatum" } & Pick<
                          Metadatum,
                          | "activeValue"
                          | "id"
                          | "key"
                          | "displayName"
                          | "tenantId"
                        >
                      >;
                    }
                >;
              };
            }
        >;
      }
  >;
};

export type GetProjectByIdQueryVariables = Exact<{
  id: Scalars["UUID"]["input"];
}>;

export type GetProjectByIdQuery = { __typename: "Query" } & {
  projectById: Maybe<
    { __typename: "Project" } & Pick<
      Project,
      | "id"
      | "key"
      | "name"
      | "shortDescription"
      | "description"
      | "icon"
      | "iconColor"
      | "iconText"
      | "dateCreated"
      | "tenantId"
      | "userId"
      | "dateArchived"
      | "dateDeleted"
      | "dateDataDeleted"
      | "status"
      | "activeStorageSize"
      | "archivedStorageSize"
      | "defaultUserAccessLevel"
    >
  >;
};

export type GetTaskByIdQueryVariables = Exact<{
  id: Scalars["UUID"]["input"];
}>;

export type GetTaskByIdQuery = { __typename: "Query" } & {
  task: Maybe<
    { __typename: "Task" } & Pick<
      Task,
      | "id"
      | "taskParameters"
      | "toolExitcode"
      | "toolExitcodeDescription"
      | "created"
      | "tenantId"
      | "toolVersionId"
      | "userId"
      | "status"
      | "datasetId"
      | "credits"
      | "cloned"
    >
  >;
};

export type GetToolByKeyQueryVariables = Exact<{
  key: Scalars["String"]["input"];
}>;

export type GetToolByKeyQuery = { __typename: "Query" } & {
  tool: Maybe<
    { __typename: "Tool" } & Pick<
      Tool,
      "id" | "name" | "key" | "helpText" | "created"
    > & {
        versions: { __typename: "ToolVersionsConnection" } & {
          nodes: Array<
            { __typename: "ToolVersion" } & Pick<
              ToolVersion,
              "id" | "version" | "created" | "maturity" | "credits"
            > & {
                sources: { __typename: "ToolSourcesConnection" } & {
                  nodes: Array<
                    { __typename: "ToolSource" } & Pick<
                      ToolSource,
                      | "id"
                      | "key"
                      | "name"
                      | "toolVersionId"
                      | "required"
                      | "helpText"
                    >
                  >;
                };
                results: { __typename: "ToolResultsConnection" } & {
                  nodes: Array<
                    { __typename: "ToolResult" } & Pick<
                      ToolResult,
                      "id" | "key" | "name" | "toolVersionId" | "helpText"
                    >
                  >;
                };
                parameters: { __typename: "ToolParametersConnection" } & {
                  nodes: Array<
                    { __typename: "ToolParameter" } & Pick<
                      ToolParameter,
                      | "id"
                      | "name"
                      | "type"
                      | "toolVersionId"
                      | "helpText"
                      | "default"
                      | "options"
                      | "unit"
                      | "key"
                    >
                  >;
                };
                tool: Maybe<
                  { __typename: "Tool" } & Pick<
                    Tool,
                    "id" | "name" | "key" | "helpText" | "created"
                  >
                >;
              }
          >;
        };
        categories: { __typename: "ToolDisplayCategoriesConnection" } & {
          nodes: Array<
            { __typename: "ToolDisplayCategory" } & Pick<
              ToolDisplayCategory,
              "id"
            > & {
                category: Maybe<
                  { __typename: "ToolCategory" } & Pick<
                    ToolCategory,
                    "id" | "key" | "name" | "order" | "description"
                  >
                >;
              }
          >;
        };
      }
  >;
};

export type GetToolVersionsByToolIdAndVersionsQueryVariables = Exact<{
  toolId: Scalars["Int"]["input"];
  versions: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type GetToolVersionsByToolIdAndVersionsQuery = {
  __typename: "Query";
} & {
  toolVersions: Maybe<
    { __typename: "ToolVersionsConnection" } & {
      nodes: Array<
        { __typename: "ToolVersion" } & Pick<
          ToolVersion,
          "id" | "version" | "created" | "maturity" | "credits"
        > & {
            toolSources: { __typename: "ToolSourcesConnection" } & {
              nodes: Array<
                { __typename: "ToolSource" } & Pick<
                  ToolSource,
                  | "id"
                  | "key"
                  | "name"
                  | "toolVersionId"
                  | "required"
                  | "helpText"
                >
              >;
            };
            toolParameters: { __typename: "ToolParametersConnection" } & {
              nodes: Array<
                { __typename: "ToolParameter" } & Pick<
                  ToolParameter,
                  | "id"
                  | "name"
                  | "type"
                  | "toolVersionId"
                  | "helpText"
                  | "default"
                  | "options"
                  | "unit"
                  | "key"
                >
              >;
            };
            toolResults: { __typename: "ToolResultsConnection" } & {
              nodes: Array<
                { __typename: "ToolResult" } & Pick<
                  ToolResult,
                  "id" | "key" | "name" | "toolVersionId" | "helpText"
                >
              >;
            };
            tool: Maybe<
              { __typename: "Tool" } & Pick<
                Tool,
                "id" | "name" | "key" | "helpText" | "created"
              >
            >;
          }
      >;
    }
  >;
};

export type GetUserSessionByIdQueryVariables = Exact<{
  id: Scalars["UUID"]["input"];
}>;

export type GetUserSessionByIdQuery = { __typename: "Query" } & {
  userSession: Maybe<
    { __typename: "ApplicationUserSession" } & Pick<
      ApplicationUserSession,
      | "id"
      | "userId"
      | "tenantId"
      | "active"
      | "lastPath"
      | "state"
      | "datetimeLastActive"
    >
  >;
};

export type ActionDeleteRecordingFragment = { __typename: "File" } & Pick<
  File,
  "id"
> & {
    assignment: Maybe<
      { __typename: "RecordingGroupFile" } & Pick<RecordingGroupFile, "id"> & {
          recordingId: RecordingGroupFile["fileRecordingGroupId"];
        } & {
          recording: Maybe<
            { __typename: "FileRecordingGroup" } & Pick<
              FileRecordingGroup,
              "id"
            >
          >;
        }
    >;
  };

export type ExportToDandiToolInfoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ExportToDandiToolInfoQuery = { __typename: "Query" } & {
  tool: Maybe<
    { __typename: "Tool" } & Pick<Tool, "id"> & {
        toolVersions: { __typename: "ToolVersionsConnection" } & {
          nodes: Array<
            { __typename: "ToolVersion" } & Pick<ToolVersion, "id"> & {
                toolSources: { __typename: "ToolSourcesConnection" } & {
                  nodes: Array<
                    { __typename: "ToolSource" } & Pick<
                      ToolSource,
                      "id" | "key"
                    >
                  >;
                };
                toolParameters: { __typename: "ToolParametersConnection" } & {
                  nodes: Array<
                    { __typename: "ToolParameter" } & Pick<
                      ToolParameter,
                      "id" | "key"
                    >
                  >;
                };
                toolSecrets: { __typename: "ToolSecretsConnection" } & {
                  nodes: Array<
                    { __typename: "ToolSecret" } & Pick<
                      ToolSecret,
                      "id" | "key"
                    >
                  >;
                };
              }
          >;
        };
      }
  >;
};

export type GetProcessingStatusQueryVariables = Exact<{
  drsFileId: Scalars["UUID"]["input"];
}>;

export type GetProcessingStatusQuery = { __typename: "Query" } & {
  file: Maybe<
    { __typename: "File" } & Pick<File, "id" | "processingStatus"> & {
        taskSources: { __typename: "TaskSourcesConnection" } & {
          nodes: Array<
            { __typename: "TaskSource" } & Pick<TaskSource, "id"> & {
                task: Maybe<
                  { __typename: "Task" } & Pick<
                    Task,
                    "id" | "status" | "errorDescription"
                  > & {
                      toolVersion: Maybe<
                        { __typename: "ToolVersion" } & Pick<
                          ToolVersion,
                          "id"
                        > & {
                            tool: Maybe<
                              { __typename: "Tool" } & Pick<
                                Tool,
                                "id" | "name" | "key"
                              >
                            >;
                          }
                      >;
                    }
                >;
              }
          >;
        };
      }
  >;
};

export type GetExternalTenantsQueryVariables = Exact<{ [key: string]: never }>;

export type GetExternalTenantsQuery = { __typename: "Query" } & {
  currentUser: Maybe<
    { __typename: "ApplicationUser" } & Pick<ApplicationUser, "id"> & {
        memberships: { __typename: "ApplicationUserMembershipsConnection" } & {
          nodes: Array<
            { __typename: "ApplicationUserMembership" } & Pick<
              ApplicationUserMembership,
              "id"
            > & {
                tenant: Maybe<
                  { __typename: "Tenant" } & Pick<Tenant, "id" | "key" | "name">
                >;
              }
          >;
        };
      }
  >;
};

export type PageProjectQueryVariables = Exact<{
  projectKey: Scalars["String"]["input"];
  tenantId: Scalars["Int"]["input"];
}>;

export type PageProjectQuery = { __typename: "Query" } & {
  project: Maybe<
    { __typename: "Project" } & Pick<
      Project,
      | "id"
      | "key"
      | "name"
      | "shortDescription"
      | "description"
      | "icon"
      | "iconColor"
      | "iconText"
      | "dateCreated"
      | "tenantId"
      | "userId"
      | "dateArchived"
      | "dateDeleted"
      | "dateDataDeleted"
      | "status"
      | "activeStorageSize"
      | "archivedStorageSize"
      | "defaultUserAccessLevel"
    > & {
        datasets: { __typename: "DatasetsConnection" } & {
          nodes: Array<
            { __typename: "Dataset" } & Pick<
              Dataset,
              | "id"
              | "dateArchived"
              | "dateCreated"
              | "dateDeleted"
              | "dateDataDeleted"
              | "key"
              | "prefix"
              | "name"
              | "tenantId"
              | "userId"
              | "recordingsTableId"
              | "projectId"
              | "status"
              | "descriptionId"
            > & {
                datasetVersions: { __typename: "DatasetVersionsConnection" } & {
                  nodes: Array<
                    { __typename: "DatasetVersion" } & Pick<
                      DatasetVersion,
                      | "id"
                      | "datasetId"
                      | "dateCreated"
                      | "name"
                      | "description"
                      | "tenantId"
                      | "validationState"
                      | "views"
                    >
                  >;
                };
                description: Maybe<
                  { __typename: "Metadatum" } & Pick<
                    Metadatum,
                    "id" | "key" | "displayName" | "tenantId"
                  > & {
                      metadataValuesByMetadataId: {
                        __typename: "MetadataValuesConnection";
                      } & {
                        nodes: Array<
                          { __typename: "MetadataValue" } & Pick<
                            MetadataValue,
                            | "id"
                            | "value"
                            | "dateCreated"
                            | "metadataId"
                            | "taskId"
                            | "userId"
                            | "tenantId"
                            | "projectId"
                          >
                        >;
                      };
                    }
                >;
              }
          >;
        };
        analysisTableGroups: { __typename: "AnalysisTableGroupsConnection" } & {
          nodes: Array<
            { __typename: "AnalysisTableGroup" } & Pick<
              AnalysisTableGroup,
              "id" | "name" | "hidden" | "projectId" | "dateCreated" | "toolId"
            > & {
                tool: Maybe<
                  { __typename: "Tool" } & Pick<Tool, "id" | "key" | "name">
                >;
                analysisTables: { __typename: "AnalysisTablesConnection" } & {
                  nodes: Array<
                    { __typename: "AnalysisTable" } & Pick<
                      AnalysisTable,
                      | "id"
                      | "name"
                      | "dateCreated"
                      | "projectId"
                      | "tenantId"
                      | "toolVersionId"
                      | "userId"
                      | "identifiers"
                    > & {
                        toolVersion: Maybe<
                          { __typename: "ToolVersion" } & Pick<
                            ToolVersion,
                            | "toolSpec"
                            | "id"
                            | "version"
                            | "created"
                            | "maturity"
                            | "credits"
                          > & {
                              tool: Maybe<
                                { __typename: "Tool" } & Pick<
                                  Tool,
                                  "id" | "name" | "key" | "helpText" | "created"
                                >
                              >;
                            }
                        >;
                        rows: { __typename: "AnalysisTableRowsConnection" } & {
                          nodes: Array<
                            { __typename: "AnalysisTableRow" } & Pick<
                              AnalysisTableRow,
                              | "activeAttachResults"
                              | "id"
                              | "data"
                              | "groups"
                              | "dateCreated"
                              | "selections"
                              | "tableId"
                              | "taskId"
                              | "tenantId"
                              | "attachments"
                            > & {
                                task: Maybe<
                                  { __typename: "Task" } & Pick<
                                    Task,
                                    | "id"
                                    | "taskParameters"
                                    | "toolExitcode"
                                    | "toolExitcodeDescription"
                                    | "created"
                                    | "tenantId"
                                    | "toolVersionId"
                                    | "userId"
                                    | "status"
                                    | "datasetId"
                                    | "credits"
                                    | "cloned"
                                  > & {
                                      outputGroup: Maybe<
                                        {
                                          __typename: "FileOutputGroup";
                                        } & Pick<
                                          FileOutputGroup,
                                          | "id"
                                          | "dateCreated"
                                          | "taskId"
                                          | "tenantId"
                                          | "userId"
                                          | "groupType"
                                        > & {
                                            outputGroupFiles: {
                                              __typename: "OutputGroupFilesConnection";
                                            } & {
                                              nodes: Array<
                                                {
                                                  __typename: "OutputGroupFile";
                                                } & Pick<
                                                  OutputGroupFile,
                                                  "id"
                                                > & {
                                                    drsFile: Maybe<
                                                      {
                                                        __typename: "File";
                                                      } & Pick<
                                                        File,
                                                        | "id"
                                                        | "dateArchived"
                                                        | "dateCreated"
                                                        | "dateDataDeleted"
                                                        | "dateDeleted"
                                                        | "fileType"
                                                        | "fileFormat"
                                                        | "fileStructure"
                                                        | "key"
                                                        | "location"
                                                        | "multipartUploadId"
                                                        | "name"
                                                        | "parentIds"
                                                        | "partSize"
                                                        | "projectId"
                                                        | "signal"
                                                        | "size"
                                                        | "status"
                                                        | "source"
                                                        | "tenantId"
                                                        | "uploadStatus"
                                                        | "userId"
                                                        | "originalName"
                                                        | "seriesParentId"
                                                        | "datasetId"
                                                        | "isSeries"
                                                        | "seriesOrder"
                                                        | "processingStatus"
                                                      >
                                                    >;
                                                  }
                                              >;
                                            };
                                          }
                                      >;
                                    }
                                >;
                              }
                          >;
                        };
                      }
                  >;
                };
              }
          >;
        };
      }
  >;
};

export type PageProjectAnalysisQueryVariables = Exact<{
  analysisTableGroupId: Scalars["BigInt"]["input"];
  recordingGroupFileFilter?: InputMaybe<RecordingGroupFileFilter>;
}>;

export type PageProjectAnalysisQuery = { __typename: "Query" } & {
  analysisTableGroup: Maybe<
    { __typename: "AnalysisTableGroup" } & Pick<
      AnalysisTableGroup,
      | "id"
      | "dateCreated"
      | "name"
      | "description"
      | "projectId"
      | "tenantId"
      | "userId"
      | "hidden"
      | "toolId"
    > & {
        tool: Maybe<{ __typename: "Tool" } & Pick<Tool, "id" | "key" | "name">>;
        analysisTables: { __typename: "AnalysisTablesConnection" } & {
          nodes: Array<
            { __typename: "AnalysisTable" } & Pick<
              AnalysisTable,
              | "groupId"
              | "id"
              | "name"
              | "dateCreated"
              | "projectId"
              | "tenantId"
              | "toolVersionId"
              | "userId"
              | "identifiers"
            > & {
                analysisTableRows: {
                  __typename: "AnalysisTableRowsConnection";
                } & {
                  nodes: Array<
                    { __typename: "AnalysisTableRow" } & Pick<
                      AnalysisTableRow,
                      | "activeAttachResults"
                      | "metadatumReferences"
                      | "id"
                      | "data"
                      | "groups"
                      | "dateCreated"
                      | "selections"
                      | "tableId"
                      | "taskId"
                      | "tenantId"
                      | "attachments"
                    > & {
                        toolVersion: Maybe<
                          { __typename: "ToolVersion" } & Pick<
                            ToolVersion,
                            "id" | "version"
                          >
                        >;
                        task: Maybe<
                          { __typename: "Task" } & Pick<
                            Task,
                            | "id"
                            | "taskParameters"
                            | "toolExitcode"
                            | "toolExitcodeDescription"
                            | "created"
                            | "tenantId"
                            | "toolVersionId"
                            | "userId"
                            | "status"
                            | "datasetId"
                            | "credits"
                            | "cloned"
                          > & {
                              taskActivityByTaskId: Maybe<
                                { __typename: "TaskActivity" } & Pick<
                                  TaskActivity,
                                  "id"
                                > & {
                                    duration: { __typename: "Interval" } & Pick<
                                      Interval,
                                      | "hours"
                                      | "seconds"
                                      | "minutes"
                                      | "days"
                                      | "years"
                                      | "months"
                                    >;
                                  }
                              >;
                              taskResourcesByTaskId: {
                                __typename: "TaskResourcesConnection";
                              } & {
                                nodes: Array<
                                  { __typename: "TaskResource" } & Pick<
                                    TaskResource,
                                    "id" | "type" | "value"
                                  >
                                >;
                              };
                              outputGroup: Maybe<
                                { __typename: "FileOutputGroup" } & Pick<
                                  FileOutputGroup,
                                  | "id"
                                  | "dateCreated"
                                  | "taskId"
                                  | "tenantId"
                                  | "userId"
                                  | "groupType"
                                > & {
                                    outputGroupFiles: {
                                      __typename: "OutputGroupFilesConnection";
                                    } & {
                                      nodes: Array<
                                        {
                                          __typename: "OutputGroupFile";
                                        } & Pick<OutputGroupFile, "id"> & {
                                            drsFile: Maybe<
                                              { __typename: "File" } & Pick<
                                                File,
                                                | "id"
                                                | "dateArchived"
                                                | "dateCreated"
                                                | "dateDataDeleted"
                                                | "dateDeleted"
                                                | "fileType"
                                                | "fileFormat"
                                                | "fileStructure"
                                                | "key"
                                                | "location"
                                                | "multipartUploadId"
                                                | "name"
                                                | "parentIds"
                                                | "partSize"
                                                | "projectId"
                                                | "signal"
                                                | "size"
                                                | "status"
                                                | "source"
                                                | "tenantId"
                                                | "uploadStatus"
                                                | "userId"
                                                | "originalName"
                                                | "seriesParentId"
                                                | "datasetId"
                                                | "isSeries"
                                                | "seriesOrder"
                                                | "processingStatus"
                                              >
                                            >;
                                          }
                                      >;
                                    };
                                  }
                              >;
                            }
                        >;
                      }
                  >;
                };
                project: Maybe<
                  { __typename: "Project" } & Pick<
                    Project,
                    | "id"
                    | "key"
                    | "name"
                    | "shortDescription"
                    | "description"
                    | "icon"
                    | "iconColor"
                    | "iconText"
                    | "dateCreated"
                    | "tenantId"
                    | "userId"
                    | "dateArchived"
                    | "dateDeleted"
                    | "dateDataDeleted"
                    | "status"
                    | "activeStorageSize"
                    | "archivedStorageSize"
                    | "defaultUserAccessLevel"
                  > & {
                      datasets: { __typename: "DatasetsConnection" } & {
                        nodes: Array<
                          { __typename: "Dataset" } & Pick<
                            Dataset,
                            | "id"
                            | "dateArchived"
                            | "dateCreated"
                            | "dateDeleted"
                            | "dateDataDeleted"
                            | "key"
                            | "prefix"
                            | "name"
                            | "tenantId"
                            | "userId"
                            | "recordingsTableId"
                            | "projectId"
                            | "status"
                            | "descriptionId"
                          > & {
                              datasetRecordingsTable: Maybe<
                                { __typename: "DatasetRecordingsTable" } & Pick<
                                  DatasetRecordingsTable,
                                  "id" | "name" | "views" | "tenantId"
                                > & {
                                    recordingGroups: {
                                      __typename: "FileRecordingGroupsConnection";
                                    } & {
                                      nodes: Array<
                                        {
                                          __typename: "FileRecordingGroup";
                                        } & Pick<
                                          FileRecordingGroup,
                                          | "number"
                                          | "id"
                                          | "dateCreated"
                                          | "dateAssignmentEnded"
                                          | "tenantId"
                                          | "userId"
                                        > & {
                                            recordingGroupFiles: {
                                              __typename: "RecordingGroupFilesConnection";
                                            } & {
                                              nodes: Array<
                                                {
                                                  __typename: "RecordingGroupFile";
                                                } & Pick<
                                                  RecordingGroupFile,
                                                  | "id"
                                                  | "datasetRecordingsTableColumnId"
                                                  | "fileRecordingGroupId"
                                                  | "fileId"
                                                  | "tenantId"
                                                  | "userId"
                                                  | "dateCreated"
                                                  | "dateAssignmentEnded"
                                                > & {
                                                    drsFiles: Maybe<
                                                      {
                                                        __typename: "File";
                                                      } & Pick<
                                                        File,
                                                        | "id"
                                                        | "dateArchived"
                                                        | "dateCreated"
                                                        | "dateDataDeleted"
                                                        | "dateDeleted"
                                                        | "fileType"
                                                        | "fileFormat"
                                                        | "fileStructure"
                                                        | "key"
                                                        | "location"
                                                        | "multipartUploadId"
                                                        | "name"
                                                        | "parentIds"
                                                        | "partSize"
                                                        | "projectId"
                                                        | "signal"
                                                        | "size"
                                                        | "status"
                                                        | "source"
                                                        | "tenantId"
                                                        | "uploadStatus"
                                                        | "userId"
                                                        | "originalName"
                                                        | "seriesParentId"
                                                        | "datasetId"
                                                        | "isSeries"
                                                        | "seriesOrder"
                                                        | "processingStatus"
                                                      >
                                                    >;
                                                  }
                                              >;
                                            };
                                          }
                                      >;
                                    };
                                  }
                              >;
                            }
                        >;
                      };
                    }
                >;
                toolVersion: Maybe<
                  { __typename: "ToolVersion" } & Pick<
                    ToolVersion,
                    "id" | "toolSpec" | "version" | "maturity" | "credits"
                  > & {
                      compatibleVersions: {
                        __typename: "ToolVersionsConnection";
                      } & {
                        nodes: Array<
                          { __typename: "ToolVersion" } & Pick<
                            ToolVersion,
                            | "id"
                            | "toolSpec"
                            | "version"
                            | "maturity"
                            | "credits"
                          > & {
                              tool: Maybe<
                                { __typename: "Tool" } & Pick<
                                  Tool,
                                  "id" | "key" | "name"
                                >
                              >;
                            }
                        >;
                      };
                    }
                >;
              }
          >;
        };
      }
  >;
};

export type GetDrsFilesQueryVariables = Exact<{
  ids: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
}>;

export type GetDrsFilesQuery = { __typename: "Query" } & {
  drsFiles: Maybe<
    { __typename: "FilesConnection" } & {
      nodes: Array<
        { __typename: "File" } & Pick<
          File,
          | "id"
          | "datasetId"
          | "dateArchived"
          | "dateCreated"
          | "dateDataDeleted"
          | "dateDeleted"
          | "fileFormat"
          | "fileStructure"
          | "fileType"
          | "key"
          | "isSeries"
          | "location"
          | "multipartUploadId"
          | "name"
          | "parentIds"
          | "partSize"
          | "preview"
          | "projectId"
          | "signal"
          | "size"
          | "status"
          | "source"
          | "tenantId"
          | "uploadStatus"
          | "userId"
          | "originalName"
          | "seriesParentId"
          | "seriesOrder"
          | "processingStatus"
        > & {
            fileBySeriesParentId: Maybe<
              { __typename: "File" } & Pick<File, "id">
            >;
            seriesFiles: { __typename: "FilesConnection" } & {
              nodes: Array<{ __typename: "File" } & Pick<File, "id">>;
            };
            fileMetadata: { __typename: "FileMetadataConnection" } & {
              nodes: Array<
                { __typename: "FileMetadatum" } & Pick<FileMetadatum, "id">
              >;
            };
            taskSources: { __typename: "TaskSourcesConnection" } & {
              nodes: Array<
                { __typename: "TaskSource" } & Pick<TaskSource, "id">
              >;
            };
            tenant: Maybe<{ __typename: "Tenant" } & Pick<Tenant, "id">>;
            dataset: Maybe<{ __typename: "Dataset" } & Pick<Dataset, "id">>;
            user: Maybe<
              { __typename: "ApplicationUser" } & Pick<ApplicationUser, "id">
            >;
            fileActivities: { __typename: "FileActivitiesConnection" } & {
              nodes: Array<
                { __typename: "FileActivity" } & Pick<FileActivity, "id">
              >;
            };
            project: Maybe<{ __typename: "Project" } & Pick<Project, "id">>;
            outputGroupFiles: { __typename: "OutputGroupFilesConnection" } & {
              nodes: Array<
                { __typename: "OutputGroupFile" } & Pick<OutputGroupFile, "id">
              >;
            };
            assignment: Maybe<
              { __typename: "RecordingGroupFile" } & Pick<
                RecordingGroupFile,
                "id"
              >
            >;
            recordingGroupFiles: {
              __typename: "RecordingGroupFilesConnection";
            } & {
              nodes: Array<
                { __typename: "RecordingGroupFile" } & Pick<
                  RecordingGroupFile,
                  "id"
                >
              >;
            };
            fileProcessingNotifications: {
              __typename: "FileProcessingNotificationsConnection";
            } & {
              nodes: Array<
                { __typename: "FileProcessingNotification" } & Pick<
                  FileProcessingNotification,
                  "id"
                >
              >;
            };
          }
      >;
    }
  >;
};

export type PageDatasetQueryVariables = Exact<{
  datasetId: Scalars["UUID"]["input"];
  cutoffTime?: InputMaybe<Scalars["Datetime"]["input"]>;
}>;

export type PageDatasetQuery = { __typename: "Query" } & {
  dataset: Maybe<
    { __typename: "Dataset" } & Pick<
      Dataset,
      | "id"
      | "dateArchived"
      | "dateCreated"
      | "dateDeleted"
      | "dateDataDeleted"
      | "key"
      | "prefix"
      | "name"
      | "tenantId"
      | "userId"
      | "recordingsTableId"
      | "projectId"
      | "status"
      | "descriptionId"
    > & {
        description: Maybe<
          { __typename: "Metadatum" } & Pick<
            Metadatum,
            "id" | "key" | "displayName" | "tenantId"
          > & {
              metadataValuesByMetadataId: {
                __typename: "MetadataValuesConnection";
              } & {
                nodes: Array<
                  { __typename: "MetadataValue" } & Pick<
                    MetadataValue,
                    | "id"
                    | "value"
                    | "dateCreated"
                    | "metadataId"
                    | "taskId"
                    | "userId"
                    | "tenantId"
                    | "projectId"
                  >
                >;
              };
            }
        >;
        datasetVersions: { __typename: "DatasetVersionsConnection" } & {
          nodes: Array<
            { __typename: "DatasetVersion" } & Pick<
              DatasetVersion,
              | "id"
              | "datasetId"
              | "dateCreated"
              | "name"
              | "description"
              | "tenantId"
              | "validationState"
              | "views"
            >
          >;
        };
        datasetRecordingsTable: Maybe<
          { __typename: "DatasetRecordingsTable" } & Pick<
            DatasetRecordingsTable,
            "id" | "name" | "views" | "tenantId"
          > & {
              datasetRecordingsTableColumns: {
                __typename: "DatasetRecordingsTableColumnsConnection";
              } & {
                nodes: Array<
                  { __typename: "DatasetRecordingsTableColumn" } & Pick<
                    DatasetRecordingsTableColumn,
                    | "activeColDef"
                    | "activeOrder"
                    | "id"
                    | "dateCreated"
                    | "dateAssignmentEnded"
                    | "tenantId"
                    | "pinned"
                    | "width"
                    | "colDefId"
                    | "identifierPosition"
                  >
                >;
              };
              fileRecordingGroups: {
                __typename: "FileRecordingGroupsConnection";
              } & {
                nodes: Array<
                  { __typename: "FileRecordingGroup" } & Pick<
                    FileRecordingGroup,
                    | "number"
                    | "id"
                    | "dateCreated"
                    | "dateAssignmentEnded"
                    | "tenantId"
                    | "userId"
                  > & {
                      recordingGroupFiles: {
                        __typename: "RecordingGroupFilesConnection";
                      } & {
                        nodes: Array<
                          { __typename: "RecordingGroupFile" } & Pick<
                            RecordingGroupFile,
                            | "id"
                            | "datasetRecordingsTableColumnId"
                            | "fileRecordingGroupId"
                            | "fileId"
                            | "tenantId"
                            | "userId"
                            | "dateCreated"
                            | "dateAssignmentEnded"
                          >
                        >;
                      };
                      metadata: {
                        __typename: "FileRecordingGroupMetadataConnection";
                      } & {
                        nodes: Array<
                          { __typename: "FileRecordingGroupMetadatum" } & Pick<
                            FileRecordingGroupMetadatum,
                            | "id"
                            | "fileRecordingGroupId"
                            | "metadataId"
                            | "dateCreated"
                            | "tenantId"
                          > & {
                              metadatum: Maybe<
                                { __typename: "Metadatum" } & Pick<
                                  Metadatum,
                                  "id" | "key" | "displayName" | "tenantId"
                                > & {
                                    values: {
                                      __typename: "MetadataValuesConnection";
                                    } & {
                                      nodes: Array<
                                        { __typename: "MetadataValue" } & Pick<
                                          MetadataValue,
                                          | "id"
                                          | "value"
                                          | "dateCreated"
                                          | "metadataId"
                                          | "taskId"
                                          | "userId"
                                          | "tenantId"
                                          | "projectId"
                                        >
                                      >;
                                    };
                                  }
                              >;
                            }
                        >;
                      };
                    }
                >;
              };
            }
        >;
        metadata: { __typename: "DatasetMetadataConnection" } & {
          nodes: Array<
            { __typename: "DatasetMetadatum" } & Pick<
              DatasetMetadatum,
              "id"
            > & {
                metadatum: Maybe<
                  { __typename: "Metadatum" } & Pick<
                    Metadatum,
                    "id" | "key" | "displayName" | "tenantId"
                  > & {
                      values: { __typename: "MetadataValuesConnection" } & {
                        nodes: Array<
                          { __typename: "MetadataValue" } & Pick<
                            MetadataValue,
                            | "id"
                            | "value"
                            | "dateCreated"
                            | "metadataId"
                            | "taskId"
                            | "userId"
                            | "tenantId"
                            | "projectId"
                          >
                        >;
                      };
                    }
                >;
              }
          >;
        };
        project: Maybe<
          { __typename: "Project" } & Pick<
            Project,
            | "id"
            | "key"
            | "name"
            | "shortDescription"
            | "description"
            | "icon"
            | "iconColor"
            | "iconText"
            | "dateCreated"
            | "tenantId"
            | "userId"
            | "dateArchived"
            | "dateDeleted"
            | "dateDataDeleted"
            | "status"
            | "activeStorageSize"
            | "archivedStorageSize"
            | "defaultUserAccessLevel"
          >
        >;
      }
  >;
};

export type TasksTableQueryVariables = Exact<{
  condition?: InputMaybe<AnalysisTableRowCondition>;
  filter?: InputMaybe<AnalysisTableRowFilter>;
}>;

export type TasksTableQuery = { __typename: "Query" } & {
  analysisTableRows: Maybe<
    { __typename: "AnalysisTableRowsConnection" } & {
      nodes: Array<
        { __typename: "AnalysisTableRow" } & Pick<
          AnalysisTableRow,
          "id" | "data" | "deleted"
        > & {
            table: Maybe<
              { __typename: "AnalysisTable" } & Pick<
                AnalysisTable,
                "id" | "name" | "identifiers"
              > & {
                  group: Maybe<
                    { __typename: "AnalysisTableGroup" } & Pick<
                      AnalysisTableGroup,
                      "id" | "name"
                    >
                  >;
                  toolVersion: Maybe<
                    { __typename: "ToolVersion" } & Pick<
                      ToolVersion,
                      "id" | "toolSpec"
                    > & {
                        tool: Maybe<
                          { __typename: "Tool" } & Pick<Tool, "id" | "key">
                        >;
                      }
                  >;
                }
            >;
            task: Maybe<
              { __typename: "Task" } & Pick<
                Task,
                "id" | "credits" | "cloned" | "status" | "created"
              > & {
                  taskActivityByTaskId: Maybe<
                    { __typename: "TaskActivity" } & Pick<
                      TaskActivity,
                      "id"
                    > & {
                        duration: { __typename: "Interval" } & Pick<
                          Interval,
                          | "hours"
                          | "seconds"
                          | "minutes"
                          | "days"
                          | "years"
                          | "months"
                        >;
                      }
                  >;
                  user: Maybe<
                    { __typename: "ApplicationUser" } & Pick<
                      ApplicationUser,
                      "id" | "firstName" | "lastName"
                    >
                  >;
                  outputGroup: Maybe<
                    { __typename: "FileOutputGroup" } & Pick<
                      FileOutputGroup,
                      "id"
                    > & {
                        outputGroupFiles: {
                          __typename: "OutputGroupFilesConnection";
                        } & {
                          nodes: Array<
                            { __typename: "OutputGroupFile" } & Pick<
                              OutputGroupFile,
                              "id"
                            > & {
                                drsFile: Maybe<
                                  { __typename: "File" } & Pick<
                                    File,
                                    | "id"
                                    | "name"
                                    | "isSeries"
                                    | "status"
                                    | "key"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                }
            >;
          }
      >;
    }
  >;
};

export type TenantProviderQueryVariables = Exact<{
  tenantKey: Scalars["String"]["input"];
}>;

export type TenantProviderQuery = { __typename: "Query" } & {
  tenant: Maybe<
    { __typename: "Tenant" } & Pick<Tenant, "id" | "name" | "key"> & {
        memberships: { __typename: "ApplicationUserMembershipsConnection" } & {
          nodes: Array<
            { __typename: "ApplicationUserMembership" } & Pick<
              ApplicationUserMembership,
              "id" | "role"
            > & {
                user: Maybe<
                  { __typename: "ApplicationUser" } & Pick<
                    ApplicationUser,
                    | "id"
                    | "firstName"
                    | "lastName"
                    | "email"
                    | "username"
                    | "internal"
                    | "isActive"
                  > & {
                      avatar: Maybe<
                        { __typename: "Avatar" } & Pick<
                          Avatar,
                          | "id"
                          | "color"
                          | "initials"
                          | "useImage"
                          | "imageBase64"
                        >
                      >;
                      metadata: {
                        __typename: "ApplicationUserMetadataConnection";
                      } & {
                        nodes: Array<
                          { __typename: "ApplicationUserMetadatum" } & Pick<
                            ApplicationUserMetadatum,
                            "id" | "key" | "value"
                          >
                        >;
                      };
                    }
                >;
              }
          >;
        };
      }
  >;
};

export type ToolSpecProviderQueryVariables = Exact<{
  tenantId: Scalars["Int"]["input"];
}>;

export type ToolSpecProviderQuery = { __typename: "Query" } & {
  tools: Maybe<
    { __typename: "ToolsConnection" } & {
      nodes: Array<
        { __typename: "Tool" } & Pick<
          Tool,
          "id" | "key" | "name" | "created"
        > & {
            toolVersions: { __typename: "ToolVersionsConnection" } & {
              nodes: Array<
                { __typename: "ToolVersion" } & Pick<
                  ToolVersion,
                  | "id"
                  | "version"
                  | "maturity"
                  | "toolSpec"
                  | "toolId"
                  | "credits"
                  | "created"
                >
              >;
            };
          }
      >;
    }
  >;
};

export type UserProviderQueryVariables = Exact<{ [key: string]: never }>;

export type UserProviderQuery = { __typename: "Query" } & {
  currentUser: Maybe<
    { __typename: "ApplicationUser" } & Pick<
      ApplicationUser,
      | "id"
      | "firstName"
      | "lastName"
      | "email"
      | "username"
      | "internal"
      | "isActive"
    > & {
        metadata: { __typename: "ApplicationUserMetadataConnection" } & {
          nodes: Array<
            { __typename: "ApplicationUserMetadatum" } & Pick<
              ApplicationUserMetadatum,
              "id" | "key" | "value"
            >
          >;
        };
        eula: { __typename: "EulaVersionUserAcceptancesConnection" } & {
          nodes: Array<
            { __typename: "EulaVersionUserAcceptance" } & Pick<
              EulaVersionUserAcceptance,
              "id" | "accepted"
            >
          >;
        };
        memberships: { __typename: "ApplicationUserMembershipsConnection" } & {
          nodes: Array<
            { __typename: "ApplicationUserMembership" } & Pick<
              ApplicationUserMembership,
              "id" | "role"
            > & {
                tenant: Maybe<
                  { __typename: "Tenant" } & Pick<Tenant, "id" | "key" | "name">
                >;
              }
          >;
        };
        avatar: Maybe<
          { __typename: "Avatar" } & Pick<
            Avatar,
            "id" | "imageBase64" | "initials" | "useImage" | "color"
          >
        >;
      }
  >;
};

export type ProjectFilesManagerQueryVariables = Exact<{
  projectId: Scalars["UUID"]["input"];
  cutoffTime?: InputMaybe<Scalars["Datetime"]["input"]>;
  metadataKeys: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type ProjectFilesManagerQuery = { __typename: "Query" } & {
  project: Maybe<
    { __typename: "Project" } & Pick<Project, "id"> & {
        datasets: { __typename: "DatasetsConnection" } & {
          nodes: Array<
            { __typename: "Dataset" } & Pick<
              Dataset,
              "id" | "prefix" | "name"
            > & {
                recordingsTable: Maybe<
                  { __typename: "DatasetRecordingsTable" } & Pick<
                    DatasetRecordingsTable,
                    "id"
                  > & {
                      columns: {
                        __typename: "DatasetRecordingsTableColumnsConnection";
                      } & {
                        nodes: Array<
                          { __typename: "DatasetRecordingsTableColumn" } & Pick<
                            DatasetRecordingsTableColumn,
                            "id"
                          > & {
                              colDef: DatasetRecordingsTableColumn["activeColDef"];
                            }
                        >;
                      };
                      recordings: {
                        __typename: "FileRecordingGroupsConnection";
                      } & {
                        nodes: Array<
                          { __typename: "FileRecordingGroup" } & Pick<
                            FileRecordingGroup,
                            "id" | "number"
                          >
                        >;
                      };
                    }
                >;
              }
          >;
        };
        activeFiles: { __typename: "FilesConnection" } & {
          nodes: Array<
            { __typename: "File" } & Pick<
              File,
              | "id"
              | "key"
              | "datasetId"
              | "dateCreated"
              | "name"
              | "status"
              | "isSeries"
              | "uploadStatus"
              | "projectId"
              | "seriesParentId"
              | "fileType"
              | "fileStructure"
              | "fileFormat"
              | "size"
              | "source"
              | "processingStatus"
              | "originalName"
            > & {
                assignment: Maybe<
                  { __typename: "RecordingGroupFile" } & Pick<
                    RecordingGroupFile,
                    "id"
                  > & {
                      recording: Maybe<
                        { __typename: "FileRecordingGroup" } & Pick<
                          FileRecordingGroup,
                          "id" | "number"
                        >
                      >;
                      column: Maybe<
                        { __typename: "DatasetRecordingsTableColumn" } & Pick<
                          DatasetRecordingsTableColumn,
                          "id"
                        > & {
                            colDef: DatasetRecordingsTableColumn["activeColDef"];
                          }
                      >;
                    }
                >;
                dataset: Maybe<
                  { __typename: "Dataset" } & Pick<
                    Dataset,
                    "id" | "prefix" | "name"
                  >
                >;
                user: Maybe<
                  { __typename: "ApplicationUser" } & Pick<
                    ApplicationUser,
                    "id" | "firstName" | "lastName"
                  >
                >;
                outputGroupFiles: {
                  __typename: "OutputGroupFilesConnection";
                } & {
                  nodes: Array<
                    { __typename: "OutputGroupFile" } & Pick<
                      OutputGroupFile,
                      "id"
                    > & {
                        outputGroup: Maybe<
                          { __typename: "FileOutputGroup" } & Pick<
                            FileOutputGroup,
                            "id"
                          > & {
                              task: Maybe<
                                { __typename: "Task" } & Pick<Task, "id"> & {
                                    analysisTableRow: Maybe<
                                      { __typename: "AnalysisTableRow" } & Pick<
                                        AnalysisTableRow,
                                        | "id"
                                        | "attachments"
                                        | "activeAttachResults"
                                      > & {
                                          analysisTableId: AnalysisTableRow["tableId"];
                                        }
                                    >;
                                  }
                              >;
                            }
                        >;
                      }
                  >;
                };
                fileMetadata: { __typename: "FileMetadataConnection" } & {
                  nodes: Array<
                    { __typename: "FileMetadatum" } & Pick<
                      FileMetadatum,
                      "id"
                    > & {
                        metadata: Maybe<
                          { __typename: "Metadatum" } & Pick<
                            Metadatum,
                            "id" | "key" | "activeValue"
                          >
                        >;
                      }
                  >;
                };
                seriesFiles: { __typename: "FilesConnection" } & {
                  nodes: Array<
                    { __typename: "File" } & Pick<
                      File,
                      | "id"
                      | "key"
                      | "datasetId"
                      | "dateCreated"
                      | "name"
                      | "status"
                      | "isSeries"
                      | "uploadStatus"
                      | "projectId"
                      | "seriesParentId"
                      | "fileStructure"
                      | "fileFormat"
                      | "fileType"
                      | "size"
                      | "source"
                      | "processingStatus"
                      | "originalName"
                    > & {
                        fileMetadata: {
                          __typename: "FileMetadataConnection";
                        } & {
                          nodes: Array<
                            { __typename: "FileMetadatum" } & Pick<
                              FileMetadatum,
                              "id"
                            > & {
                                metadata: Maybe<
                                  { __typename: "Metadatum" } & Pick<
                                    Metadatum,
                                    "id" | "key" | "activeValue"
                                  >
                                >;
                              }
                          >;
                        };
                      }
                  >;
                };
              }
          >;
        };
      }
  >;
};

export const PopoverFileUploadFragmentDoc = gql`
  fragment PopoverFileUpload on File {
    id
    name
    status
    fileType
    source
    processingStatus
    seriesParentId
  }
`;
export const UserAvatarFieldsFragmentDoc = gql`
  fragment UserAvatarFields on ApplicationUser {
    id
    firstName
    lastName
    avatar: avatarByUserId {
      id
      imageBase64
      initials
      useImage
      color
    }
  }
`;
export const AnalysisTableCacheFragmentDoc = gql`
  fragment AnalysisTableCache on AnalysisTable {
    analysisTableRows: analysisTableRowsByTableId(orderBy: [DATE_CREATED_ASC]) {
      nodes {
        id
      }
    }
    user: applicationUserByUserId {
      id
    }
    dateCreated
    id
    name
    identifiers
    project: projectByProjectId {
      id
    }
    projectId
    tenant: tenantByTenantId {
      id
    }
    tenantId
    toolVersion: toolVersionByToolVersionId {
      id
    }
    toolVersionId
    userId
    groupId
    group: analysisTableGroupByGroupId {
      id
    }
  }
`;
export const AnalysisTableRowCacheFragmentDoc = gql`
  fragment AnalysisTableRowCache on AnalysisTableRow {
    activeAttachResults
    analysisTableByTableId {
      id
    }
    analysisTableRowAttachResultsByAnalysisTableRowId {
      nodes {
        id
      }
    }
    attachments
    data
    dateCreated
    groups
    id
    locked
    selections
    tableId
    taskByTaskId {
      id
    }
    taskId
    tenantByTenantId {
      id
    }
    tenantId
    project: projectByProjectId {
      id
    }
    projectId
    deleted
    autofillMap
    metadatumReferences
    toolVersionId
    toolVersion: toolVersionByToolVersionId {
      id
    }
  }
`;
export const ApplicationUserMembershipCacheFragmentDoc = gql`
  fragment ApplicationUserMembershipCache on ApplicationUserMembership {
    id
    role
    tenantId
    userId
    user: applicationUserByUserId {
      id
    }
    tenant: tenantByTenantId {
      id
    }
  }
`;
export const DatasetCacheFragmentDoc = gql`
  fragment DatasetCache on Dataset {
    activeDescription
    dateArchived
    dateCreated
    dateDataDeleted
    dateDeleted
    descriptionId
    id
    key
    prefix
    name
    projectId
    recordingsTableId
    status
    tenantId
    userId
    datasetRecordingsTable: datasetRecordingsTableByRecordingsTableId {
      id
    }
    datasetVersions: datasetVersionsByDatasetId {
      nodes {
        id
      }
    }
    user: applicationUserByUserId {
      id
    }
    files: filesByDatasetId {
      nodes {
        id
      }
    }
    project: projectByProjectId {
      id
    }
    datasetMetadata: datasetMetadataByDatasetId {
      nodes {
        id
      }
    }
    description: metadatumByDescriptionId {
      id
    }
    tasks: tasksByDatasetId {
      nodes {
        id
      }
    }
    tenant: tenantByTenantId {
      id
    }
  }
`;
export const DatasetRecordingsTableCacheFragmentDoc = gql`
  fragment DatasetRecordingsTableCache on DatasetRecordingsTable {
    id
    name
    views
    tenantId
    activeColumns {
      nodes {
        id
      }
    }
    columns: datasetRecordingsTableColumnsByDatasetRecordingsTableId {
      nodes {
        id
      }
    }
    dataset: datasetByRecordingsTableId {
      id
    }
    fileRecordings: fileRecordingGroupsByDatasetRecordingsTableId {
      nodes {
        id
      }
    }
    tenant: tenantByTenantId {
      id
    }
    datasets: datasetsByRecordingsTableId {
      nodes {
        id
      }
    }
    project: projectByProjectId {
      id
    }
    projectId
  }
`;
export const DatasetRecordingsTableColumnCacheFragmentDoc = gql`
  fragment DatasetRecordingsTableColumnCache on DatasetRecordingsTableColumn {
    id
    activeColDef
    activeOrder
    colDefId
    datasetRecordingsTableId
    dateAssignmentEnded
    dateCreated
    pinned
    tenantId
    width
    identifierPosition
    recordingsGroupFiles: recordingGroupFilesByDatasetRecordingsTableColumnId {
      nodes {
        id
      }
    }
    datasetRecordingsTable: datasetRecordingsTableByDatasetRecordingsTableId {
      id
    }
    metadata: metadatumByColDefId {
      id
    }
    orders: datasetRecordingsTableColumnOrdersByDatasetRecordingsTableColumnId {
      nodes {
        id
      }
    }
    tenant: tenantByTenantId {
      id
    }
    project: projectByProjectId {
      id
    }
    projectId
  }
`;
export const DatasetRecordingsTableColumnOrderCacheFragmentDoc = gql`
  fragment DatasetRecordingsTableColumnOrderCache on DatasetRecordingsTableColumnOrder {
    id
    datasetRecordingsTableColumnId
    dateCreated
    tenantId
    value
    column: datasetRecordingsTableColumnByDatasetRecordingsTableColumnId {
      id
    }
    tenant: tenantByTenantId {
      id
    }
    project: projectByProjectId {
      id
    }
    projectId
  }
`;
export const FileCacheFragmentDoc = gql`
  fragment FileCache on File {
    id
    datasetId
    dateArchived
    dateCreated
    dateDataDeleted
    dateDeleted
    fileFormat
    fileStructure
    fileType
    key
    isSeries
    location
    multipartUploadId
    name
    parentIds
    partSize
    preview
    projectId
    signal
    size
    status
    source
    tenantId
    uploadStatus
    userId
    originalName
    seriesParentId
    seriesOrder
    processingStatus
    fileBySeriesParentId {
      id
    }
    seriesFiles: filesBySeriesParentId {
      nodes {
        id
      }
    }
    fileMetadata: fileMetadataByFileId {
      nodes {
        id
      }
    }
    taskSources: taskSourcesByFileId {
      nodes {
        id
      }
    }
    tenant: tenantByTenantId {
      id
    }
    dataset: datasetByDatasetId {
      id
    }
    user: applicationUserByUserId {
      id
    }
    fileActivities: fileActivitiesByFileId {
      nodes {
        id
      }
    }
    project: projectByProjectId {
      id
    }
    seriesFiles: filesBySeriesParentId {
      nodes {
        id
      }
    }
    user: applicationUserByUserId {
      id
    }
    dataset: datasetByDatasetId {
      id
    }
    outputGroupFiles: outputGroupFilesByFileId {
      nodes {
        id
      }
    }
    assignment: activeAssignment {
      id
    }
    fileMetadata: fileMetadataByFileId {
      nodes {
        id
      }
    }
    recordingGroupFiles: recordingGroupFilesByFileId {
      nodes {
        id
      }
    }
    taskSources: taskSourcesByFileId {
      nodes {
        id
      }
    }
    fileProcessingNotifications: fileProcessingNotificationsByFileId {
      nodes {
        id
      }
    }
  }
`;
export const MetadatumCacheFragmentDoc = gql`
  fragment MetadatumCache on Metadatum {
    id
    activeValue
    datasetMetadata: datasetMetadataByMetadataId {
      nodes {
        id
      }
    }
    datasetRecordingsTableColumns: datasetRecordingsTableColumnsByColDefId {
      nodes {
        id
      }
    }
    datasets: datasetsByDescriptionId {
      nodes {
        id
      }
    }
    displayName
    fileMetadata: fileMetadataByMetadataId {
      nodes {
        id
      }
    }
    fileOutputGroupMetadata: fileOutputGroupMetadataByMetadataId {
      nodes {
        id
      }
    }
    fileRecordingGroupMetadata: fileRecordingGroupMetadataByMetadataId {
      nodes {
        id
      }
    }
    id
    key
    metadataValues: metadataValuesByMetadataId {
      nodes {
        id
      }
    }
    project: projectByProjectId {
      id
    }
    projectId
    tenant: tenantByTenantId {
      id
    }
    tenantId
  }
`;
export const NotificationCacheFragmentDoc = gql`
  fragment NotificationCache on Notification {
    id
    dateCreated
    hasSeen
    userId
    user: applicationUserByUserId {
      id
    }
    projectSharedNotification: projectSharedNotificationById {
      id
    }
    projectSharedNotifications: projectSharedNotificationsById {
      nodes {
        id
      }
    }
    tenantMembershipNotification: tenantUserMembershipNotificationById {
      id
    }
    tenantMembershipNotifications: tenantUserMembershipNotificationsById {
      nodes {
        id
      }
    }
    fileProcessingNotifications: fileProcessingNotificationsById {
      nodes {
        id
      }
    }
    fileProcessingNotification: fileProcessingNotificationById {
      id
    }
    roleChangeNotifications: roleChangeNotificationsById {
      nodes {
        id
      }
    }
    roleChangeNotification: roleChangeNotificationById {
      id
    }
    projectClonedNotifications: projectClonedNotificationsById {
      nodes {
        id
      }
    }
    projectClonedNotification: projectClonedNotificationById {
      id
    }
  }
`;
export const ProjectCacheFragmentDoc = gql`
  fragment ProjectCache on Project {
    id
    key
    name
    shortDescription
    status
    iconText
    iconColor
    icon
    description
    dateDeleted
    dateDataDeleted
    dateCreated
    dateArchived
    archivedStorageSize
    activeStorageSize
    tenantId
    prefix
    userId
    defaultUserAccessLevel
    usedCredits
    tenant: tenantByTenantId {
      id
    }
    activeFiles {
      nodes {
        id
      }
    }
    analysisTables: analysisTablesByProjectId {
      nodes {
        id
      }
    }
    user: applicationUserByUserId {
      id
    }
    datasets: datasetsByProjectId {
      nodes {
        id
      }
    }
    files: filesByProjectId {
      nodes {
        id
      }
    }
    tasks: tasksByProjectId {
      nodes {
        id
      }
    }
    analysisTableRowAttachResults: analysisTableRowAttachResultsByProjectId {
      nodes {
        id
      }
    }
    analysisTableRows: analysisTableRowsByProjectId {
      nodes {
        id
      }
    }
    datasetMetadata: datasetMetadataByProjectId {
      nodes {
        id
      }
    }
    datasetRecordingsTableColumnOrders: datasetRecordingsTableColumnOrdersByProjectId {
      nodes {
        id
      }
    }
    datasetRecordingsTableColumns: datasetRecordingsTableColumnsByProjectId {
      nodes {
        id
      }
    }
    datasetRecordingsTables: datasetRecordingsTablesByProjectId {
      nodes {
        id
      }
    }
    datasetVersions: datasetVersionsByProjectId {
      nodes {
        id
      }
    }
    fileActivities: fileActivitiesByProjectId {
      nodes {
        id
      }
    }
    fileMetadata: fileMetadataByProjectId {
      nodes {
        id
      }
    }
    fileOutputGroupMetadata: fileOutputGroupMetadataByProjectId {
      nodes {
        id
      }
    }
    fileOutputGroups: fileOutputGroupsByProjectId {
      nodes {
        id
      }
    }
    fileRecordingGroupMetadata: fileRecordingGroupMetadataByProjectId {
      nodes {
        id
      }
    }
    fileRecordingGroups: fileRecordingGroupsByProjectId {
      nodes {
        id
      }
    }
    metadata: metadataByProjectId {
      nodes {
        id
      }
    }
    metadataValues: metadataValuesByProjectId {
      nodes {
        id
      }
    }
    outputGroupFiles: outputGroupFilesByProjectId {
      nodes {
        id
      }
    }
    recordingGroupFiles: recordingGroupFilesByProjectId {
      nodes {
        id
      }
    }
    projectSharedNotifications: projectSharedNotificationsByProjectId {
      nodes {
        id
      }
    }
    analysisTableGroups: analysisTableGroupsByProjectId {
      nodes {
        id
      }
    }
    projectClonedNotifications: projectClonedNotificationsByProjectId {
      nodes {
        id
      }
    }
  }
`;
export const FileRecordingGroupCacheFragmentDoc = gql`
  fragment FileRecordingGroupCache on FileRecordingGroup {
    id
    datasetRecordingsTableId
    dateAssignmentEnded
    dateCreated
    tenantId
    userId
    number
    user: applicationUserByUserId {
      id
    }
    datasetRecordingsTable: datasetRecordingsTableByDatasetRecordingsTableId {
      id
    }
    assignments: recordingGroupFilesByFileRecordingGroupId {
      nodes {
        id
      }
    }
    recordingGroupMetadata: fileRecordingGroupMetadataByFileRecordingGroupId {
      nodes {
        id
      }
    }
    tenant: tenantByTenantId {
      id
    }
    project: projectByProjectId {
      id
    }
    projectId
  }
`;
export const RecordingGroupFileCacheFragmentDoc = gql`
  fragment RecordingGroupFileCache on RecordingGroupFile {
    id
    fileId
    dateCreated
    dateAssignmentEnded
    datasetRecordingsTableColumnId
    userId
    tenantId
    fileRecordingGroupId
    tenant: tenantByTenantId {
      id
    }
    user: applicationUserByUserId {
      id
    }
    recording: fileRecordingGroupByFileRecordingGroupId {
      id
    }
    file: fileByFileId {
      id
    }
    column: datasetRecordingsTableColumnByDatasetRecordingsTableColumnId {
      id
    }
    project: projectByProjectId {
      id
    }
    projectId
  }
`;
export const AnalysisTableFieldsFragmentDoc = gql`
  fragment AnalysisTableFields on AnalysisTable {
    id
    name
    dateCreated
    projectId
    tenantId
    toolVersionId
    userId
    identifiers
  }
`;
export const AnalysisTableRowAttachResultFieldsFragmentDoc = gql`
  fragment AnalysisTableRowAttachResultFields on AnalysisTableRowAttachResult {
    id
    dateCreated
    analysisTableRowId
    attachResults
  }
`;
export const AnalysisTableRowFieldsFragmentDoc = gql`
  fragment AnalysisTableRowFields on AnalysisTableRow {
    id
    data
    groups
    dateCreated
    selections
    tableId
    taskId
    tenantId
    attachments
  }
`;
export const AvatarFieldsFragmentDoc = gql`
  fragment AvatarFields on Avatar {
    id
    color
    initials
    useImage
    imageBase64
  }
`;
export const DatasetFieldsFragmentDoc = gql`
  fragment DatasetFields on Dataset {
    id
    dateArchived
    dateCreated
    dateDeleted
    dateDataDeleted
    key
    prefix
    name
    tenantId
    userId
    recordingsTableId
    projectId
    status
    descriptionId
  }
`;
export const DatasetRecordingsTableColumnFieldsFragmentDoc = gql`
  fragment DatasetRecordingsTableColumnFields on DatasetRecordingsTableColumn {
    id
    dateCreated
    dateAssignmentEnded
    tenantId
    pinned
    width
    colDefId
    identifierPosition
  }
`;
export const DatasetRecordingsTableFieldsFragmentDoc = gql`
  fragment DatasetRecordingsTableFields on DatasetRecordingsTable {
    id
    name
    views
    tenantId
  }
`;
export const DatasetVersionFieldsFragmentDoc = gql`
  fragment DatasetVersionFields on DatasetVersion {
    id
    datasetId
    dateCreated
    name
    description
    tenantId
    validationState
    views
  }
`;
export const FileFieldsFragmentDoc = gql`
  fragment FileFields on File {
    id
    dateArchived
    dateCreated
    dateDataDeleted
    dateDeleted
    fileType
    fileFormat
    fileStructure
    key
    location
    multipartUploadId
    name
    parentIds
    partSize
    projectId
    signal
    size
    status
    source
    tenantId
    uploadStatus
    userId
    originalName
    seriesParentId
    datasetId
    isSeries
    seriesOrder
    processingStatus
  }
`;
export const MetadataValueFieldsFragmentDoc = gql`
  fragment MetadataValueFields on MetadataValue {
    id
    value
    dateCreated
    metadataId
    taskId
    userId
    tenantId
    projectId
  }
`;
export const MetadatumFieldsFragmentDoc = gql`
  fragment MetadatumFields on Metadatum {
    id
    key
    displayName
    tenantId
  }
`;
export const FileOutputGroupFieldsFragmentDoc = gql`
  fragment FileOutputGroupFields on FileOutputGroup {
    id
    dateCreated
    taskId
    tenantId
    userId
    groupType
  }
`;
export const ProjectFieldsFragmentDoc = gql`
  fragment ProjectFields on Project {
    id
    key
    name
    shortDescription
    description
    icon
    iconColor
    iconText
    dateCreated
    tenantId
    userId
    dateArchived
    dateDeleted
    dateDataDeleted
    status
    activeStorageSize
    archivedStorageSize
    defaultUserAccessLevel
  }
`;
export const RecordingGroupFileFieldsFragmentDoc = gql`
  fragment RecordingGroupFileFields on RecordingGroupFile {
    id
    datasetRecordingsTableColumnId
    fileRecordingGroupId
    fileId
    tenantId
    userId
    dateCreated
    dateAssignmentEnded
  }
`;
export const FileRecordingGroupFieldsFragmentDoc = gql`
  fragment FileRecordingGroupFields on FileRecordingGroup {
    id
    dateCreated
    dateAssignmentEnded
    tenantId
    userId
  }
`;
export const FileRecordingGroupMetadatumFieldsFragmentDoc = gql`
  fragment FileRecordingGroupMetadatumFields on FileRecordingGroupMetadatum {
    id
    fileRecordingGroupId
    metadataId
    dateCreated
    tenantId
  }
`;
export const TagFieldsFragmentDoc = gql`
  fragment TagFields on Tag {
    id
    label
    parentId
    parameters
    dateCreated
    userId
  }
`;
export const TaskFieldsFragmentDoc = gql`
  fragment TaskFields on Task {
    id
    taskParameters
    toolExitcode
    toolExitcodeDescription
    created
    tenantId
    toolVersionId
    userId
    status
    datasetId
    credits
    cloned
  }
`;
export const TenantContractAllowanceFieldsFragmentDoc = gql`
  fragment TenantContractAllowanceFields on TenantContractAllowance {
    id
    quotaType
    value
  }
`;
export const TenantContractFieldsFragmentDoc = gql`
  fragment TenantContractFields on TenantContract {
    id
    name
    created
    startDate
    endDate
  }
`;
export const TenantContractTransactionFieldsFragmentDoc = gql`
  fragment TenantContractTransactionFields on TenantContractTransaction {
    id
    created
    currency
    price
  }
`;
export const TenantFieldsFragmentDoc = gql`
  fragment TenantFields on Tenant {
    id
    name
    key
  }
`;
export const ToolCategoryFieldsFragmentDoc = gql`
  fragment ToolCategoryFields on ToolCategory {
    id
    key
    name
    order
    description
  }
`;
export const ToolParameterFieldsFragmentDoc = gql`
  fragment ToolParameterFields on ToolParameter {
    id
    name
    type
    toolVersionId
    helpText
    default
    options
    unit
    key
  }
`;
export const ToolResultFieldsFragmentDoc = gql`
  fragment ToolResultFields on ToolResult {
    id
    key
    name
    toolVersionId
    helpText
  }
`;
export const ToolSourceFieldsFragmentDoc = gql`
  fragment ToolSourceFields on ToolSource {
    id
    key
    name
    toolVersionId
    required
    helpText
  }
`;
export const ToolFieldsFragmentDoc = gql`
  fragment ToolFields on Tool {
    id
    name
    key
    helpText
    created
  }
`;
export const ToolVersionFieldsFragmentDoc = gql`
  fragment ToolVersionFields on ToolVersion {
    id
    version
    created
    maturity
    credits
    tool: toolByToolId {
      ...ToolFields
    }
  }
  ${ToolFieldsFragmentDoc}
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on ApplicationUser {
    id
    username
    firstName
    lastName
    email
    internal
    isActive
  }
`;
export const UserMetadatumFieldsFragmentDoc = gql`
  fragment UserMetadatumFields on ApplicationUserMetadatum {
    id
    userId
    key
    value
  }
`;
export const UserSessionFieldsFragmentDoc = gql`
  fragment UserSessionFields on ApplicationUserSession {
    id
    userId
    tenantId
    active
    lastPath
    state
    datetimeLastActive
  }
`;
export const ActionDeleteRecordingFragmentDoc = gql`
  fragment ActionDeleteRecording on File {
    id
    assignment: activeAssignment {
      id
      recordingId: fileRecordingGroupId
      recording: fileRecordingGroupByFileRecordingGroupId {
        id
      }
    }
  }
`;
export const AnalysisTableTaskCountsDocument = gql`
  query AnalysisTableTaskCounts($projectId: UUID!) {
    projectById(id: $projectId) {
      id
      analysisTables: analysisTablesByProjectId {
        nodes {
          ...AnalysisTableFields
          groupId
          rows: analysisTableRowsByTableId(
            filter: { deleted: { notEqualTo: true } }
          ) {
            nodes {
              ...AnalysisTableRowFields
              task: taskByTaskId {
                ...TaskFields
              }
            }
          }
        }
      }
    }
  }
  ${AnalysisTableFieldsFragmentDoc}
  ${AnalysisTableRowFieldsFragmentDoc}
  ${TaskFieldsFragmentDoc}
`;

/**
 * __useAnalysisTableTaskCountsQuery__
 *
 * To run a query within a React component, call `useAnalysisTableTaskCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalysisTableTaskCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalysisTableTaskCountsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useAnalysisTableTaskCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalysisTableTaskCountsQuery,
    AnalysisTableTaskCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AnalysisTableTaskCountsQuery,
    AnalysisTableTaskCountsQueryVariables
  >(AnalysisTableTaskCountsDocument, options);
}
export function useAnalysisTableTaskCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalysisTableTaskCountsQuery,
    AnalysisTableTaskCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalysisTableTaskCountsQuery,
    AnalysisTableTaskCountsQueryVariables
  >(AnalysisTableTaskCountsDocument, options);
}
export type AnalysisTableTaskCountsQueryHookResult = ReturnType<
  typeof useAnalysisTableTaskCountsQuery
>;
export type AnalysisTableTaskCountsLazyQueryHookResult = ReturnType<
  typeof useAnalysisTableTaskCountsLazyQuery
>;
export type AnalysisTableTaskCountsQueryResult = Apollo.QueryResult<
  AnalysisTableTaskCountsQuery,
  AnalysisTableTaskCountsQueryVariables
>;
export const ButtonDatasetExportsDocument = gql`
  query ButtonDatasetExports($datasetId: UUID!, $first: Int!, $offset: Int!) {
    tasks: allTasks(
      filter: {
        toolVersionByToolVersionId: {
          toolByToolId: { key: { equalTo: "export_to_dandi" } }
        }
        datasetId: { equalTo: $datasetId }
      }
      orderBy: CREATED_DESC
      first: $first
      offset: $offset
    ) {
      totalCount
      nodes {
        id
        status
        created
        userId
        taskSources: taskSourcesByTaskId {
          nodes {
            id
            fileId
          }
        }
        taskParameters
        toolVersion: toolVersionByToolVersionId {
          id
          toolParameters: toolParametersByToolVersionId {
            nodes {
              id
              key
            }
          }
        }
      }
    }
  }
`;

/**
 * __useButtonDatasetExportsQuery__
 *
 * To run a query within a React component, call `useButtonDatasetExportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useButtonDatasetExportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useButtonDatasetExportsQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useButtonDatasetExportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ButtonDatasetExportsQuery,
    ButtonDatasetExportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ButtonDatasetExportsQuery,
    ButtonDatasetExportsQueryVariables
  >(ButtonDatasetExportsDocument, options);
}
export function useButtonDatasetExportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ButtonDatasetExportsQuery,
    ButtonDatasetExportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ButtonDatasetExportsQuery,
    ButtonDatasetExportsQueryVariables
  >(ButtonDatasetExportsDocument, options);
}
export type ButtonDatasetExportsQueryHookResult = ReturnType<
  typeof useButtonDatasetExportsQuery
>;
export type ButtonDatasetExportsLazyQueryHookResult = ReturnType<
  typeof useButtonDatasetExportsLazyQuery
>;
export type ButtonDatasetExportsQueryResult = Apollo.QueryResult<
  ButtonDatasetExportsQuery,
  ButtonDatasetExportsQueryVariables
>;
export const CreateEulaVersionUserAcceptanceDocument = gql`
  mutation CreateEulaVersionUserAcceptance(
    $input: CreateEulaVersionUserAcceptanceInput!
  ) {
    createEulaVersionUserAcceptance(input: $input) {
      applicationUserByUserId {
        id
      }
    }
  }
`;
export type CreateEulaVersionUserAcceptanceMutationFn = Apollo.MutationFunction<
  CreateEulaVersionUserAcceptanceMutation,
  CreateEulaVersionUserAcceptanceMutationVariables
>;

/**
 * __useCreateEulaVersionUserAcceptanceMutation__
 *
 * To run a mutation, you first call `useCreateEulaVersionUserAcceptanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEulaVersionUserAcceptanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEulaVersionUserAcceptanceMutation, { data, loading, error }] = useCreateEulaVersionUserAcceptanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEulaVersionUserAcceptanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEulaVersionUserAcceptanceMutation,
    CreateEulaVersionUserAcceptanceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEulaVersionUserAcceptanceMutation,
    CreateEulaVersionUserAcceptanceMutationVariables
  >(CreateEulaVersionUserAcceptanceDocument, options);
}
export type CreateEulaVersionUserAcceptanceMutationHookResult = ReturnType<
  typeof useCreateEulaVersionUserAcceptanceMutation
>;
export type CreateEulaVersionUserAcceptanceMutationResult =
  Apollo.MutationResult<CreateEulaVersionUserAcceptanceMutation>;
export type CreateEulaVersionUserAcceptanceMutationOptions =
  Apollo.BaseMutationOptions<
    CreateEulaVersionUserAcceptanceMutation,
    CreateEulaVersionUserAcceptanceMutationVariables
  >;
export const GetAllEulaVersionsDocument = gql`
  query GetAllEulaVersions {
    allEulaVersions(first: 1, orderBy: VERSION_DESC) {
      nodes {
        id
        content
      }
    }
  }
`;

/**
 * __useGetAllEulaVersionsQuery__
 *
 * To run a query within a React component, call `useGetAllEulaVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEulaVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEulaVersionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEulaVersionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllEulaVersionsQuery,
    GetAllEulaVersionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllEulaVersionsQuery,
    GetAllEulaVersionsQueryVariables
  >(GetAllEulaVersionsDocument, options);
}
export function useGetAllEulaVersionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllEulaVersionsQuery,
    GetAllEulaVersionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllEulaVersionsQuery,
    GetAllEulaVersionsQueryVariables
  >(GetAllEulaVersionsDocument, options);
}
export type GetAllEulaVersionsQueryHookResult = ReturnType<
  typeof useGetAllEulaVersionsQuery
>;
export type GetAllEulaVersionsLazyQueryHookResult = ReturnType<
  typeof useGetAllEulaVersionsLazyQuery
>;
export type GetAllEulaVersionsQueryResult = Apollo.QueryResult<
  GetAllEulaVersionsQuery,
  GetAllEulaVersionsQueryVariables
>;
export const FileBrowserDetailRendererAnalysisResultDocument = gql`
  query FileBrowserDetailRendererAnalysisResult($drsFileId: UUID!) {
    drsFile: fileById(id: $drsFileId) {
      id
      dataset: datasetByDatasetId {
        id
      }
      project: projectByProjectId {
        id
        key
      }
      outputGroupFiles: outputGroupFilesByFileId {
        nodes {
          id
          fileOutputGroup: fileOutputGroupByFileOutputGroupId {
            id
            task: taskByTaskId {
              id
              status
              created
              analysisTableRow: analysisTableRowByTaskId {
                id
                analysisTable: analysisTableByTableId {
                  id
                  name
                  analysisTableGroup: analysisTableGroupByGroupId {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useFileBrowserDetailRendererAnalysisResultQuery__
 *
 * To run a query within a React component, call `useFileBrowserDetailRendererAnalysisResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileBrowserDetailRendererAnalysisResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileBrowserDetailRendererAnalysisResultQuery({
 *   variables: {
 *      drsFileId: // value for 'drsFileId'
 *   },
 * });
 */
export function useFileBrowserDetailRendererAnalysisResultQuery(
  baseOptions: Apollo.QueryHookOptions<
    FileBrowserDetailRendererAnalysisResultQuery,
    FileBrowserDetailRendererAnalysisResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FileBrowserDetailRendererAnalysisResultQuery,
    FileBrowserDetailRendererAnalysisResultQueryVariables
  >(FileBrowserDetailRendererAnalysisResultDocument, options);
}
export function useFileBrowserDetailRendererAnalysisResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FileBrowserDetailRendererAnalysisResultQuery,
    FileBrowserDetailRendererAnalysisResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FileBrowserDetailRendererAnalysisResultQuery,
    FileBrowserDetailRendererAnalysisResultQueryVariables
  >(FileBrowserDetailRendererAnalysisResultDocument, options);
}
export type FileBrowserDetailRendererAnalysisResultQueryHookResult = ReturnType<
  typeof useFileBrowserDetailRendererAnalysisResultQuery
>;
export type FileBrowserDetailRendererAnalysisResultLazyQueryHookResult =
  ReturnType<typeof useFileBrowserDetailRendererAnalysisResultLazyQuery>;
export type FileBrowserDetailRendererAnalysisResultQueryResult =
  Apollo.QueryResult<
    FileBrowserDetailRendererAnalysisResultQuery,
    FileBrowserDetailRendererAnalysisResultQueryVariables
  >;
export const GetToolboxSpecByAnalysisTableIdDocument = gql`
  query GetToolboxSpecByAnalysisTableId($analysisTableId: BigInt!) {
    analysisTable: analysisTableById(id: $analysisTableId) {
      ...AnalysisTableFields
      toolVersion: toolVersionByToolVersionId {
        ...ToolVersionFields
        tool: toolByToolId {
          ...ToolFields
        }
        toolSpec
      }
    }
  }
  ${AnalysisTableFieldsFragmentDoc}
  ${ToolVersionFieldsFragmentDoc}
  ${ToolFieldsFragmentDoc}
`;

/**
 * __useGetToolboxSpecByAnalysisTableIdQuery__
 *
 * To run a query within a React component, call `useGetToolboxSpecByAnalysisTableIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToolboxSpecByAnalysisTableIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToolboxSpecByAnalysisTableIdQuery({
 *   variables: {
 *      analysisTableId: // value for 'analysisTableId'
 *   },
 * });
 */
export function useGetToolboxSpecByAnalysisTableIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetToolboxSpecByAnalysisTableIdQuery,
    GetToolboxSpecByAnalysisTableIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetToolboxSpecByAnalysisTableIdQuery,
    GetToolboxSpecByAnalysisTableIdQueryVariables
  >(GetToolboxSpecByAnalysisTableIdDocument, options);
}
export function useGetToolboxSpecByAnalysisTableIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetToolboxSpecByAnalysisTableIdQuery,
    GetToolboxSpecByAnalysisTableIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetToolboxSpecByAnalysisTableIdQuery,
    GetToolboxSpecByAnalysisTableIdQueryVariables
  >(GetToolboxSpecByAnalysisTableIdDocument, options);
}
export type GetToolboxSpecByAnalysisTableIdQueryHookResult = ReturnType<
  typeof useGetToolboxSpecByAnalysisTableIdQuery
>;
export type GetToolboxSpecByAnalysisTableIdLazyQueryHookResult = ReturnType<
  typeof useGetToolboxSpecByAnalysisTableIdLazyQuery
>;
export type GetToolboxSpecByAnalysisTableIdQueryResult = Apollo.QueryResult<
  GetToolboxSpecByAnalysisTableIdQuery,
  GetToolboxSpecByAnalysisTableIdQueryVariables
>;
export const FlyoutDrsFileInfoDocument = gql`
  query FlyoutDrsFileInfo($drsFileId: UUID!) {
    drsFile: fileById(id: $drsFileId) {
      ...FileFields
      preview
      seriesFiles: filesBySeriesParentId(orderBy: NAME_ASC) {
        nodes {
          ...FileFields
          preview
        }
      }
      outputGroupFiles: outputGroupFilesByFileId {
        nodes {
          id
          outputGroup: fileOutputGroupByFileOutputGroupId {
            id
            task: taskByTaskId {
              id
              status
              created
              analysisTableRow: analysisTableRowByTaskId {
                id
                analysisTable: analysisTableByTableId {
                  id
                  name
                  analysisTableGroup: analysisTableGroupByGroupId {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${FileFieldsFragmentDoc}
`;

/**
 * __useFlyoutDrsFileInfoQuery__
 *
 * To run a query within a React component, call `useFlyoutDrsFileInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlyoutDrsFileInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlyoutDrsFileInfoQuery({
 *   variables: {
 *      drsFileId: // value for 'drsFileId'
 *   },
 * });
 */
export function useFlyoutDrsFileInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    FlyoutDrsFileInfoQuery,
    FlyoutDrsFileInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FlyoutDrsFileInfoQuery,
    FlyoutDrsFileInfoQueryVariables
  >(FlyoutDrsFileInfoDocument, options);
}
export function useFlyoutDrsFileInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FlyoutDrsFileInfoQuery,
    FlyoutDrsFileInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FlyoutDrsFileInfoQuery,
    FlyoutDrsFileInfoQueryVariables
  >(FlyoutDrsFileInfoDocument, options);
}
export type FlyoutDrsFileInfoQueryHookResult = ReturnType<
  typeof useFlyoutDrsFileInfoQuery
>;
export type FlyoutDrsFileInfoLazyQueryHookResult = ReturnType<
  typeof useFlyoutDrsFileInfoLazyQuery
>;
export type FlyoutDrsFileInfoQueryResult = Apollo.QueryResult<
  FlyoutDrsFileInfoQuery,
  FlyoutDrsFileInfoQueryVariables
>;
export const NotificationsFeedUnreadCountDocument = gql`
  query NotificationsFeedUnreadCount {
    notifications: allNotifications(condition: { hasSeen: false }) {
      totalCount
    }
  }
`;

/**
 * __useNotificationsFeedUnreadCountQuery__
 *
 * To run a query within a React component, call `useNotificationsFeedUnreadCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsFeedUnreadCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsFeedUnreadCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsFeedUnreadCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationsFeedUnreadCountQuery,
    NotificationsFeedUnreadCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NotificationsFeedUnreadCountQuery,
    NotificationsFeedUnreadCountQueryVariables
  >(NotificationsFeedUnreadCountDocument, options);
}
export function useNotificationsFeedUnreadCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsFeedUnreadCountQuery,
    NotificationsFeedUnreadCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NotificationsFeedUnreadCountQuery,
    NotificationsFeedUnreadCountQueryVariables
  >(NotificationsFeedUnreadCountDocument, options);
}
export type NotificationsFeedUnreadCountQueryHookResult = ReturnType<
  typeof useNotificationsFeedUnreadCountQuery
>;
export type NotificationsFeedUnreadCountLazyQueryHookResult = ReturnType<
  typeof useNotificationsFeedUnreadCountLazyQuery
>;
export type NotificationsFeedUnreadCountQueryResult = Apollo.QueryResult<
  NotificationsFeedUnreadCountQuery,
  NotificationsFeedUnreadCountQueryVariables
>;
export const NotificationsFeedBodyDocument = gql`
  query NotificationsFeedBody($condition: NotificationCondition) {
    notifications: allNotifications(
      orderBy: DATE_CREATED_DESC
      condition: $condition
    ) {
      nodes {
        id
        dateCreated
        hasSeen
        projectSharedNotification: projectSharedNotificationById {
          id
          project: projectByProjectId {
            id
            name
            key
            tenant: tenantByTenantId {
              id
              key
            }
          }
          sharer: applicationUserBySharerId {
            id
            firstName
            lastName
          }
          accessLevel
        }
        fileProcessingNotification: fileProcessingNotificationById {
          id
          status
          fileId
          userId
          file: fileByFileId {
            id
            name
            status
            fileType
            isSeries
            source
            processingStatus
            seriesParentId
            project: projectByProjectId {
              id
              name
              key
              tenant: tenantByTenantId {
                id
                key
              }
            }
          }
          user: applicationUserByUserId {
            id
          }
        }
        projectClonedNotification: projectClonedNotificationById {
          id
          project: projectByProjectId {
            id
            name
            key
            status
            tenant: tenantByTenantId {
              id
              key
            }
          }
        }
        tenantUserMembershipNotification: tenantUserMembershipNotificationById {
          id
          inviter: applicationUserByInviterId {
            id
            firstName
            lastName
          }
          tenant: tenantByTenantId {
            id
            key
            name
          }
        }
        roleChangeNotification: roleChangeNotificationById {
          id
          role
          tenant: tenantByTenantId {
            id
            key
            name
          }
          user: applicationUserByUserId {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useNotificationsFeedBodyQuery__
 *
 * To run a query within a React component, call `useNotificationsFeedBodyQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsFeedBodyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsFeedBodyQuery({
 *   variables: {
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useNotificationsFeedBodyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationsFeedBodyQuery,
    NotificationsFeedBodyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NotificationsFeedBodyQuery,
    NotificationsFeedBodyQueryVariables
  >(NotificationsFeedBodyDocument, options);
}
export function useNotificationsFeedBodyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsFeedBodyQuery,
    NotificationsFeedBodyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NotificationsFeedBodyQuery,
    NotificationsFeedBodyQueryVariables
  >(NotificationsFeedBodyDocument, options);
}
export type NotificationsFeedBodyQueryHookResult = ReturnType<
  typeof useNotificationsFeedBodyQuery
>;
export type NotificationsFeedBodyLazyQueryHookResult = ReturnType<
  typeof useNotificationsFeedBodyLazyQuery
>;
export type NotificationsFeedBodyQueryResult = Apollo.QueryResult<
  NotificationsFeedBodyQuery,
  NotificationsFeedBodyQueryVariables
>;
export const RecordingIdentifierBadgeRecordingsDocument = gql`
  query RecordingIdentifierBadgeRecordings($recordingIds: [UUID!]!) {
    recordings: allFileRecordingGroups(filter: { id: { in: $recordingIds } }) {
      nodes {
        id
        number
        recordingsTable: datasetRecordingsTableByDatasetRecordingsTableId {
          id
          columns: activeColumns {
            nodes {
              id
              identifierPosition
              colDef: activeColDef
            }
          }
          dataset: datasetByRecordingsTableId {
            id
            prefix
          }
        }
      }
    }
  }
`;

/**
 * __useRecordingIdentifierBadgeRecordingsQuery__
 *
 * To run a query within a React component, call `useRecordingIdentifierBadgeRecordingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordingIdentifierBadgeRecordingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordingIdentifierBadgeRecordingsQuery({
 *   variables: {
 *      recordingIds: // value for 'recordingIds'
 *   },
 * });
 */
export function useRecordingIdentifierBadgeRecordingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecordingIdentifierBadgeRecordingsQuery,
    RecordingIdentifierBadgeRecordingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecordingIdentifierBadgeRecordingsQuery,
    RecordingIdentifierBadgeRecordingsQueryVariables
  >(RecordingIdentifierBadgeRecordingsDocument, options);
}
export function useRecordingIdentifierBadgeRecordingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecordingIdentifierBadgeRecordingsQuery,
    RecordingIdentifierBadgeRecordingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecordingIdentifierBadgeRecordingsQuery,
    RecordingIdentifierBadgeRecordingsQueryVariables
  >(RecordingIdentifierBadgeRecordingsDocument, options);
}
export type RecordingIdentifierBadgeRecordingsQueryHookResult = ReturnType<
  typeof useRecordingIdentifierBadgeRecordingsQuery
>;
export type RecordingIdentifierBadgeRecordingsLazyQueryHookResult = ReturnType<
  typeof useRecordingIdentifierBadgeRecordingsLazyQuery
>;
export type RecordingIdentifierBadgeRecordingsQueryResult = Apollo.QueryResult<
  RecordingIdentifierBadgeRecordingsQuery,
  RecordingIdentifierBadgeRecordingsQueryVariables
>;
export const RecordingIdentifierBadgeMetadataDocument = gql`
  query RecordingIdentifierBadgeMetadata(
    $withFileMetadata: Boolean!
    $fileMetadataFilter: FileMetadatumFilter!
    $withRecordingMetadata: Boolean!
    $recordingMetadataFilter: FileRecordingGroupMetadatumFilter!
  ) {
    recordingMetadata: allFileRecordingGroupMetadata(
      filter: $recordingMetadataFilter
    ) @include(if: $withRecordingMetadata) {
      nodes {
        id
        recordingId: fileRecordingGroupId
        metadatum: metadatumByMetadataId {
          id
          key
          value: activeValue
        }
      }
    }
    fileMetadata: allFileMetadata(filter: $fileMetadataFilter)
      @include(if: $withFileMetadata) {
      nodes {
        id
        fileId
        metadatum: metadatumByMetadataId {
          id
          key
          value: activeValue
        }
      }
    }
  }
`;

/**
 * __useRecordingIdentifierBadgeMetadataQuery__
 *
 * To run a query within a React component, call `useRecordingIdentifierBadgeMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordingIdentifierBadgeMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordingIdentifierBadgeMetadataQuery({
 *   variables: {
 *      withFileMetadata: // value for 'withFileMetadata'
 *      fileMetadataFilter: // value for 'fileMetadataFilter'
 *      withRecordingMetadata: // value for 'withRecordingMetadata'
 *      recordingMetadataFilter: // value for 'recordingMetadataFilter'
 *   },
 * });
 */
export function useRecordingIdentifierBadgeMetadataQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecordingIdentifierBadgeMetadataQuery,
    RecordingIdentifierBadgeMetadataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecordingIdentifierBadgeMetadataQuery,
    RecordingIdentifierBadgeMetadataQueryVariables
  >(RecordingIdentifierBadgeMetadataDocument, options);
}
export function useRecordingIdentifierBadgeMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecordingIdentifierBadgeMetadataQuery,
    RecordingIdentifierBadgeMetadataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecordingIdentifierBadgeMetadataQuery,
    RecordingIdentifierBadgeMetadataQueryVariables
  >(RecordingIdentifierBadgeMetadataDocument, options);
}
export type RecordingIdentifierBadgeMetadataQueryHookResult = ReturnType<
  typeof useRecordingIdentifierBadgeMetadataQuery
>;
export type RecordingIdentifierBadgeMetadataLazyQueryHookResult = ReturnType<
  typeof useRecordingIdentifierBadgeMetadataLazyQuery
>;
export type RecordingIdentifierBadgeMetadataQueryResult = Apollo.QueryResult<
  RecordingIdentifierBadgeMetadataQuery,
  RecordingIdentifierBadgeMetadataQueryVariables
>;
export const AutofillDocument = gql`
  query Autofill($projectId: UUID!) {
    project: projectById(id: $projectId) {
      id
      datasets: datasetsByProjectId(orderBy: DATE_CREATED_ASC) {
        nodes {
          id
          name
          recordingsTable: datasetRecordingsTableByRecordingsTableId {
            id
            recordingGroups: fileRecordingGroupsByDatasetRecordingsTableId(
              orderBy: DATE_CREATED_ASC
              filter: { dateAssignmentEnded: { isNull: true } }
            ) {
              nodes {
                number
                id
                dateCreated
              }
            }
            activeColumns {
              nodes {
                id
                activeColDef
                ...DatasetRecordingsTableColumnFields
                orders: datasetRecordingsTableColumnOrdersByDatasetRecordingsTableColumnId(
                  orderBy: DATE_CREATED_ASC
                  last: 1
                ) {
                  nodes {
                    id
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${DatasetRecordingsTableColumnFieldsFragmentDoc}
`;

/**
 * __useAutofillQuery__
 *
 * To run a query within a React component, call `useAutofillQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutofillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutofillQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useAutofillQuery(
  baseOptions: Apollo.QueryHookOptions<AutofillQuery, AutofillQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AutofillQuery, AutofillQueryVariables>(
    AutofillDocument,
    options,
  );
}
export function useAutofillLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AutofillQuery,
    AutofillQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AutofillQuery, AutofillQueryVariables>(
    AutofillDocument,
    options,
  );
}
export type AutofillQueryHookResult = ReturnType<typeof useAutofillQuery>;
export type AutofillLazyQueryHookResult = ReturnType<
  typeof useAutofillLazyQuery
>;
export type AutofillQueryResult = Apollo.QueryResult<
  AutofillQuery,
  AutofillQueryVariables
>;
export const DataSelectorDocument = gql`
  query DataSelector($projectId: UUID!) {
    project: projectById(id: $projectId) {
      id
      datasets: datasetsByProjectId {
        nodes {
          id
          prefix
          name
          recordingsTable: datasetRecordingsTableByRecordingsTableId {
            id
            recordingGroups: fileRecordingGroupsByDatasetRecordingsTableId(
              orderBy: DATE_CREATED_ASC
              filter: { dateAssignmentEnded: { isNull: true } }
            ) {
              nodes {
                number
                id
                dateCreated
              }
            }
            activeColumns {
              nodes {
                id
                activeColDef
                ...DatasetRecordingsTableColumnFields
                orders: datasetRecordingsTableColumnOrdersByDatasetRecordingsTableColumnId(
                  orderBy: DATE_CREATED_ASC
                  last: 1
                ) {
                  nodes {
                    id
                    value
                  }
                }
              }
            }
          }
        }
      }
      analysisTables: analysisTablesByProjectId(orderBy: DATE_CREATED_DESC) {
        nodes {
          ...AnalysisTableFields
          group: analysisTableGroupByGroupId {
            id
            name
          }
          rows: analysisTableRowsByTableId(orderBy: DATE_CREATED_DESC) {
            nodes {
              ...AnalysisTableRowFields
              task: taskByTaskId {
                ...TaskFields
              }
            }
          }
          toolVersion: toolVersionByToolVersionId {
            ...ToolVersionFields
            toolSpec
            tool: toolByToolId {
              ...ToolFields
            }
          }
        }
      }
    }
  }
  ${DatasetRecordingsTableColumnFieldsFragmentDoc}
  ${AnalysisTableFieldsFragmentDoc}
  ${AnalysisTableRowFieldsFragmentDoc}
  ${TaskFieldsFragmentDoc}
  ${ToolVersionFieldsFragmentDoc}
  ${ToolFieldsFragmentDoc}
`;

/**
 * __useDataSelectorQuery__
 *
 * To run a query within a React component, call `useDataSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataSelectorQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDataSelectorQuery(
  baseOptions: Apollo.QueryHookOptions<
    DataSelectorQuery,
    DataSelectorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DataSelectorQuery, DataSelectorQueryVariables>(
    DataSelectorDocument,
    options,
  );
}
export function useDataSelectorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DataSelectorQuery,
    DataSelectorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DataSelectorQuery, DataSelectorQueryVariables>(
    DataSelectorDocument,
    options,
  );
}
export type DataSelectorQueryHookResult = ReturnType<
  typeof useDataSelectorQuery
>;
export type DataSelectorLazyQueryHookResult = ReturnType<
  typeof useDataSelectorLazyQuery
>;
export type DataSelectorQueryResult = Apollo.QueryResult<
  DataSelectorQuery,
  DataSelectorQueryVariables
>;
export const MetadataTableFileDocument = gql`
  query MetadataTableFile($fileIds: [UUID!], $metadatumKeys: [String!]) {
    fileMetadata: allFileMetadata(
      filter: {
        fileId: { in: $fileIds }
        metadatumByMetadataId: { key: { in: $metadatumKeys } }
      }
    ) {
      nodes {
        id
        file: fileByFileId {
          id
          fileType
        }
        metadatum: metadatumByMetadataId {
          id
          key
          activeValue
        }
      }
    }
  }
`;

/**
 * __useMetadataTableFileQuery__
 *
 * To run a query within a React component, call `useMetadataTableFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetadataTableFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetadataTableFileQuery({
 *   variables: {
 *      fileIds: // value for 'fileIds'
 *      metadatumKeys: // value for 'metadatumKeys'
 *   },
 * });
 */
export function useMetadataTableFileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MetadataTableFileQuery,
    MetadataTableFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MetadataTableFileQuery,
    MetadataTableFileQueryVariables
  >(MetadataTableFileDocument, options);
}
export function useMetadataTableFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MetadataTableFileQuery,
    MetadataTableFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MetadataTableFileQuery,
    MetadataTableFileQueryVariables
  >(MetadataTableFileDocument, options);
}
export type MetadataTableFileQueryHookResult = ReturnType<
  typeof useMetadataTableFileQuery
>;
export type MetadataTableFileLazyQueryHookResult = ReturnType<
  typeof useMetadataTableFileLazyQuery
>;
export type MetadataTableFileQueryResult = Apollo.QueryResult<
  MetadataTableFileQuery,
  MetadataTableFileQueryVariables
>;
export const MetadataTableRecordingDocument = gql`
  query MetadataTableRecording($recordingIds: [UUID!]) {
    recordingMetadata: allFileRecordingGroupMetadata(
      filter: { fileRecordingGroupId: { in: $recordingIds } }
    ) {
      nodes {
        id
        recordingId: fileRecordingGroupId
        metadatum: metadatumByMetadataId {
          id
          key
          activeValue
        }
      }
    }
    recordings: allFileRecordingGroups(filter: { id: { in: $recordingIds } }) {
      nodes {
        id
        recordingsTable: datasetRecordingsTableByDatasetRecordingsTableId {
          id
          columns: activeColumns {
            nodes {
              id
              colDef: activeColDef
            }
          }
        }
      }
    }
  }
`;

/**
 * __useMetadataTableRecordingQuery__
 *
 * To run a query within a React component, call `useMetadataTableRecordingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetadataTableRecordingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetadataTableRecordingQuery({
 *   variables: {
 *      recordingIds: // value for 'recordingIds'
 *   },
 * });
 */
export function useMetadataTableRecordingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MetadataTableRecordingQuery,
    MetadataTableRecordingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MetadataTableRecordingQuery,
    MetadataTableRecordingQueryVariables
  >(MetadataTableRecordingDocument, options);
}
export function useMetadataTableRecordingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MetadataTableRecordingQuery,
    MetadataTableRecordingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MetadataTableRecordingQuery,
    MetadataTableRecordingQueryVariables
  >(MetadataTableRecordingDocument, options);
}
export type MetadataTableRecordingQueryHookResult = ReturnType<
  typeof useMetadataTableRecordingQuery
>;
export type MetadataTableRecordingLazyQueryHookResult = ReturnType<
  typeof useMetadataTableRecordingLazyQuery
>;
export type MetadataTableRecordingQueryResult = Apollo.QueryResult<
  MetadataTableRecordingQuery,
  MetadataTableRecordingQueryVariables
>;
export const UserAvatarDocument = gql`
  query UserAvatar($userId: UUID!) {
    user: applicationUserById(id: $userId) {
      ...UserAvatarFields
    }
  }
  ${UserAvatarFieldsFragmentDoc}
`;

/**
 * __useUserAvatarQuery__
 *
 * To run a query within a React component, call `useUserAvatarQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAvatarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAvatarQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserAvatarQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserAvatarQuery,
    UserAvatarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserAvatarQuery, UserAvatarQueryVariables>(
    UserAvatarDocument,
    options,
  );
}
export function useUserAvatarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAvatarQuery,
    UserAvatarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserAvatarQuery, UserAvatarQueryVariables>(
    UserAvatarDocument,
    options,
  );
}
export type UserAvatarQueryHookResult = ReturnType<typeof useUserAvatarQuery>;
export type UserAvatarLazyQueryHookResult = ReturnType<
  typeof useUserAvatarLazyQuery
>;
export type UserAvatarQueryResult = Apollo.QueryResult<
  UserAvatarQuery,
  UserAvatarQueryVariables
>;
export const CreateAnalysisTableDocument = gql`
  mutation CreateAnalysisTable($input: CreateAnalysisTableInput!) {
    createAnalysisTable(input: $input) {
      clientMutationId
      analysisTable {
        ...AnalysisTableFields
        toolVersion: toolVersionByToolVersionId {
          ...ToolVersionFields
          toolSpec
          tool: toolByToolId {
            ...ToolFields
          }
        }
      }
    }
  }
  ${AnalysisTableFieldsFragmentDoc}
  ${ToolVersionFieldsFragmentDoc}
  ${ToolFieldsFragmentDoc}
`;
export type CreateAnalysisTableMutationFn = Apollo.MutationFunction<
  CreateAnalysisTableMutation,
  CreateAnalysisTableMutationVariables
>;

/**
 * __useCreateAnalysisTableMutation__
 *
 * To run a mutation, you first call `useCreateAnalysisTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnalysisTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnalysisTableMutation, { data, loading, error }] = useCreateAnalysisTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAnalysisTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnalysisTableMutation,
    CreateAnalysisTableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAnalysisTableMutation,
    CreateAnalysisTableMutationVariables
  >(CreateAnalysisTableDocument, options);
}
export type CreateAnalysisTableMutationHookResult = ReturnType<
  typeof useCreateAnalysisTableMutation
>;
export type CreateAnalysisTableMutationResult =
  Apollo.MutationResult<CreateAnalysisTableMutation>;
export type CreateAnalysisTableMutationOptions = Apollo.BaseMutationOptions<
  CreateAnalysisTableMutation,
  CreateAnalysisTableMutationVariables
>;
export const CreateAnalysisTableGroupDocument = gql`
  mutation CreateAnalysisTableGroup($input: CreateAnalysisTableGroupInput!) {
    createAnalysisTableGroup(input: $input) {
      clientMutationId
      analysisTableGroup {
        id
        dateCreated
        description
        hidden
        name
        projectId
        tenantId
        toolId
        userId
        project: projectByProjectId {
          id
        }
        user: applicationUserByUserId {
          id
        }
        analysisTables: analysisTablesByGroupId {
          nodes {
            id
          }
        }
        tool: toolByToolId {
          id
          key
        }
        tenant: tenantByTenantId {
          id
        }
      }
    }
  }
`;
export type CreateAnalysisTableGroupMutationFn = Apollo.MutationFunction<
  CreateAnalysisTableGroupMutation,
  CreateAnalysisTableGroupMutationVariables
>;

/**
 * __useCreateAnalysisTableGroupMutation__
 *
 * To run a mutation, you first call `useCreateAnalysisTableGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnalysisTableGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnalysisTableGroupMutation, { data, loading, error }] = useCreateAnalysisTableGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAnalysisTableGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnalysisTableGroupMutation,
    CreateAnalysisTableGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAnalysisTableGroupMutation,
    CreateAnalysisTableGroupMutationVariables
  >(CreateAnalysisTableGroupDocument, options);
}
export type CreateAnalysisTableGroupMutationHookResult = ReturnType<
  typeof useCreateAnalysisTableGroupMutation
>;
export type CreateAnalysisTableGroupMutationResult =
  Apollo.MutationResult<CreateAnalysisTableGroupMutation>;
export type CreateAnalysisTableGroupMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAnalysisTableGroupMutation,
    CreateAnalysisTableGroupMutationVariables
  >;
export const CreateAnalysisTableRowAttachResultDocument = gql`
  mutation CreateAnalysisTableRowAttachResult(
    $input: CreateAnalysisTableRowAttachResultInput!
  ) {
    createAnalysisTableRowAttachResult(input: $input) {
      clientMutationId
      analysisTableRowAttachResult {
        ...AnalysisTableRowAttachResultFields
      }
    }
  }
  ${AnalysisTableRowAttachResultFieldsFragmentDoc}
`;
export type CreateAnalysisTableRowAttachResultMutationFn =
  Apollo.MutationFunction<
    CreateAnalysisTableRowAttachResultMutation,
    CreateAnalysisTableRowAttachResultMutationVariables
  >;

/**
 * __useCreateAnalysisTableRowAttachResultMutation__
 *
 * To run a mutation, you first call `useCreateAnalysisTableRowAttachResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnalysisTableRowAttachResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnalysisTableRowAttachResultMutation, { data, loading, error }] = useCreateAnalysisTableRowAttachResultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAnalysisTableRowAttachResultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnalysisTableRowAttachResultMutation,
    CreateAnalysisTableRowAttachResultMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAnalysisTableRowAttachResultMutation,
    CreateAnalysisTableRowAttachResultMutationVariables
  >(CreateAnalysisTableRowAttachResultDocument, options);
}
export type CreateAnalysisTableRowAttachResultMutationHookResult = ReturnType<
  typeof useCreateAnalysisTableRowAttachResultMutation
>;
export type CreateAnalysisTableRowAttachResultMutationResult =
  Apollo.MutationResult<CreateAnalysisTableRowAttachResultMutation>;
export type CreateAnalysisTableRowAttachResultMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAnalysisTableRowAttachResultMutation,
    CreateAnalysisTableRowAttachResultMutationVariables
  >;
export const CreateDatasetDocument = gql`
  mutation CreateDataset($dataset: DatasetInput!) {
    createDataset: createDataset(input: { dataset: $dataset }) {
      clientMutationId
      dataset: dataset {
        ...DatasetFields
        user: applicationUserByUserId {
          ...UserFields
        }
      }
    }
  }
  ${DatasetFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export type CreateDatasetMutationFn = Apollo.MutationFunction<
  CreateDatasetMutation,
  CreateDatasetMutationVariables
>;

/**
 * __useCreateDatasetMutation__
 *
 * To run a mutation, you first call `useCreateDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDatasetMutation, { data, loading, error }] = useCreateDatasetMutation({
 *   variables: {
 *      dataset: // value for 'dataset'
 *   },
 * });
 */
export function useCreateDatasetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDatasetMutation,
    CreateDatasetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDatasetMutation,
    CreateDatasetMutationVariables
  >(CreateDatasetDocument, options);
}
export type CreateDatasetMutationHookResult = ReturnType<
  typeof useCreateDatasetMutation
>;
export type CreateDatasetMutationResult =
  Apollo.MutationResult<CreateDatasetMutation>;
export type CreateDatasetMutationOptions = Apollo.BaseMutationOptions<
  CreateDatasetMutation,
  CreateDatasetMutationVariables
>;
export const CreateMetadataValueDocument = gql`
  mutation CreateMetadataValue($metadataValue: MetadataValueInput!) {
    createMetadataValue(input: { metadataValue: $metadataValue }) {
      clientMutationId
      metadataValue {
        ...MetadataValueFields
      }
    }
  }
  ${MetadataValueFieldsFragmentDoc}
`;
export type CreateMetadataValueMutationFn = Apollo.MutationFunction<
  CreateMetadataValueMutation,
  CreateMetadataValueMutationVariables
>;

/**
 * __useCreateMetadataValueMutation__
 *
 * To run a mutation, you first call `useCreateMetadataValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMetadataValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMetadataValueMutation, { data, loading, error }] = useCreateMetadataValueMutation({
 *   variables: {
 *      metadataValue: // value for 'metadataValue'
 *   },
 * });
 */
export function useCreateMetadataValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMetadataValueMutation,
    CreateMetadataValueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMetadataValueMutation,
    CreateMetadataValueMutationVariables
  >(CreateMetadataValueDocument, options);
}
export type CreateMetadataValueMutationHookResult = ReturnType<
  typeof useCreateMetadataValueMutation
>;
export type CreateMetadataValueMutationResult =
  Apollo.MutationResult<CreateMetadataValueMutation>;
export type CreateMetadataValueMutationOptions = Apollo.BaseMutationOptions<
  CreateMetadataValueMutation,
  CreateMetadataValueMutationVariables
>;
export const CreateMetadatumDocument = gql`
  mutation CreateMetadatum($metadata: MetadatumInput!) {
    createMetadata: createMetadatum(input: { metadatum: $metadata }) {
      clientMutationId
      metadata: metadatum {
        ...MetadatumFields
      }
    }
  }
  ${MetadatumFieldsFragmentDoc}
`;
export type CreateMetadatumMutationFn = Apollo.MutationFunction<
  CreateMetadatumMutation,
  CreateMetadatumMutationVariables
>;

/**
 * __useCreateMetadatumMutation__
 *
 * To run a mutation, you first call `useCreateMetadatumMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMetadatumMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMetadatumMutation, { data, loading, error }] = useCreateMetadatumMutation({
 *   variables: {
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useCreateMetadatumMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMetadatumMutation,
    CreateMetadatumMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMetadatumMutation,
    CreateMetadatumMutationVariables
  >(CreateMetadatumDocument, options);
}
export type CreateMetadatumMutationHookResult = ReturnType<
  typeof useCreateMetadatumMutation
>;
export type CreateMetadatumMutationResult =
  Apollo.MutationResult<CreateMetadatumMutation>;
export type CreateMetadatumMutationOptions = Apollo.BaseMutationOptions<
  CreateMetadatumMutation,
  CreateMetadatumMutationVariables
>;
export const CreateUserMetadatumDocument = gql`
  mutation CreateUserMetadatum($userMetadatum: ApplicationUserMetadatumInput!) {
    createUserMetadatum: createApplicationUserMetadatum(
      input: { applicationUserMetadatum: $userMetadatum }
    ) {
      clientMutationId
      userMetadatum: applicationUserMetadatum {
        ...UserMetadatumFields
      }
    }
  }
  ${UserMetadatumFieldsFragmentDoc}
`;
export type CreateUserMetadatumMutationFn = Apollo.MutationFunction<
  CreateUserMetadatumMutation,
  CreateUserMetadatumMutationVariables
>;

/**
 * __useCreateUserMetadatumMutation__
 *
 * To run a mutation, you first call `useCreateUserMetadatumMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMetadatumMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMetadatumMutation, { data, loading, error }] = useCreateUserMetadatumMutation({
 *   variables: {
 *      userMetadatum: // value for 'userMetadatum'
 *   },
 * });
 */
export function useCreateUserMetadatumMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMetadatumMutation,
    CreateUserMetadatumMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserMetadatumMutation,
    CreateUserMetadatumMutationVariables
  >(CreateUserMetadatumDocument, options);
}
export type CreateUserMetadatumMutationHookResult = ReturnType<
  typeof useCreateUserMetadatumMutation
>;
export type CreateUserMetadatumMutationResult =
  Apollo.MutationResult<CreateUserMetadatumMutation>;
export type CreateUserMetadatumMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMetadatumMutation,
  CreateUserMetadatumMutationVariables
>;
export const CreateUserSessionDocument = gql`
  mutation CreateUserSession($id: UUID!, $userid: UUID!, $tenant: Int) {
    createUserSession: createSession(
      input: { id: $id, userid: $userid, tenant: $tenant }
    ) {
      clientMutationId
      userSession: applicationUserSession {
        ...UserSessionFields
      }
    }
  }
  ${UserSessionFieldsFragmentDoc}
`;
export type CreateUserSessionMutationFn = Apollo.MutationFunction<
  CreateUserSessionMutation,
  CreateUserSessionMutationVariables
>;

/**
 * __useCreateUserSessionMutation__
 *
 * To run a mutation, you first call `useCreateUserSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSessionMutation, { data, loading, error }] = useCreateUserSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userid: // value for 'userid'
 *      tenant: // value for 'tenant'
 *   },
 * });
 */
export function useCreateUserSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserSessionMutation,
    CreateUserSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserSessionMutation,
    CreateUserSessionMutationVariables
  >(CreateUserSessionDocument, options);
}
export type CreateUserSessionMutationHookResult = ReturnType<
  typeof useCreateUserSessionMutation
>;
export type CreateUserSessionMutationResult =
  Apollo.MutationResult<CreateUserSessionMutation>;
export type CreateUserSessionMutationOptions = Apollo.BaseMutationOptions<
  CreateUserSessionMutation,
  CreateUserSessionMutationVariables
>;
export const UpdateAnalysisTableByIdDocument = gql`
  mutation UpdateAnalysisTableById($id: BigInt!, $patch: AnalysisTablePatch!) {
    updateAnalysisTableById(input: { id: $id, analysisTablePatch: $patch }) {
      clientMutationId
      analysisTable {
        ...AnalysisTableFields
        toolVersion: toolVersionByToolVersionId {
          ...ToolVersionFields
        }
        analysisTableRows: analysisTableRowsByTableId {
          nodes {
            ...AnalysisTableRowFields
            task: taskByTaskId {
              ...TaskFields
            }
          }
        }
      }
    }
  }
  ${AnalysisTableFieldsFragmentDoc}
  ${ToolVersionFieldsFragmentDoc}
  ${AnalysisTableRowFieldsFragmentDoc}
  ${TaskFieldsFragmentDoc}
`;
export type UpdateAnalysisTableByIdMutationFn = Apollo.MutationFunction<
  UpdateAnalysisTableByIdMutation,
  UpdateAnalysisTableByIdMutationVariables
>;

/**
 * __useUpdateAnalysisTableByIdMutation__
 *
 * To run a mutation, you first call `useUpdateAnalysisTableByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnalysisTableByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnalysisTableByIdMutation, { data, loading, error }] = useUpdateAnalysisTableByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateAnalysisTableByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAnalysisTableByIdMutation,
    UpdateAnalysisTableByIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAnalysisTableByIdMutation,
    UpdateAnalysisTableByIdMutationVariables
  >(UpdateAnalysisTableByIdDocument, options);
}
export type UpdateAnalysisTableByIdMutationHookResult = ReturnType<
  typeof useUpdateAnalysisTableByIdMutation
>;
export type UpdateAnalysisTableByIdMutationResult =
  Apollo.MutationResult<UpdateAnalysisTableByIdMutation>;
export type UpdateAnalysisTableByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateAnalysisTableByIdMutation,
  UpdateAnalysisTableByIdMutationVariables
>;
export const UpdateAnalysisTableGroupByIdDocument = gql`
  mutation UpdateAnalysisTableGroupById(
    $id: BigInt!
    $patch: AnalysisTableGroupPatch!
  ) {
    updateAnalysisTableGroupById(
      input: { id: $id, analysisTableGroupPatch: $patch }
    ) {
      clientMutationId
      analysisTableGroup {
        id
        hidden
        name
      }
    }
  }
`;
export type UpdateAnalysisTableGroupByIdMutationFn = Apollo.MutationFunction<
  UpdateAnalysisTableGroupByIdMutation,
  UpdateAnalysisTableGroupByIdMutationVariables
>;

/**
 * __useUpdateAnalysisTableGroupByIdMutation__
 *
 * To run a mutation, you first call `useUpdateAnalysisTableGroupByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnalysisTableGroupByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnalysisTableGroupByIdMutation, { data, loading, error }] = useUpdateAnalysisTableGroupByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateAnalysisTableGroupByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAnalysisTableGroupByIdMutation,
    UpdateAnalysisTableGroupByIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAnalysisTableGroupByIdMutation,
    UpdateAnalysisTableGroupByIdMutationVariables
  >(UpdateAnalysisTableGroupByIdDocument, options);
}
export type UpdateAnalysisTableGroupByIdMutationHookResult = ReturnType<
  typeof useUpdateAnalysisTableGroupByIdMutation
>;
export type UpdateAnalysisTableGroupByIdMutationResult =
  Apollo.MutationResult<UpdateAnalysisTableGroupByIdMutation>;
export type UpdateAnalysisTableGroupByIdMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAnalysisTableGroupByIdMutation,
    UpdateAnalysisTableGroupByIdMutationVariables
  >;
export const UpdateApplicationUserByIdDocument = gql`
  mutation UpdateApplicationUserById(
    $id: UUID!
    $patch: ApplicationUserPatch!
  ) {
    updateApplicationUserById(
      input: { id: $id, applicationUserPatch: $patch }
    ) {
      clientMutationId
      applicationUser {
        ...UserFields
        metadata: applicationUserMetadataByUserId {
          nodes {
            ...UserMetadatumFields
          }
        }
      }
    }
  }
  ${UserFieldsFragmentDoc}
  ${UserMetadatumFieldsFragmentDoc}
`;
export type UpdateApplicationUserByIdMutationFn = Apollo.MutationFunction<
  UpdateApplicationUserByIdMutation,
  UpdateApplicationUserByIdMutationVariables
>;

/**
 * __useUpdateApplicationUserByIdMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationUserByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationUserByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationUserByIdMutation, { data, loading, error }] = useUpdateApplicationUserByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateApplicationUserByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationUserByIdMutation,
    UpdateApplicationUserByIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationUserByIdMutation,
    UpdateApplicationUserByIdMutationVariables
  >(UpdateApplicationUserByIdDocument, options);
}
export type UpdateApplicationUserByIdMutationHookResult = ReturnType<
  typeof useUpdateApplicationUserByIdMutation
>;
export type UpdateApplicationUserByIdMutationResult =
  Apollo.MutationResult<UpdateApplicationUserByIdMutation>;
export type UpdateApplicationUserByIdMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateApplicationUserByIdMutation,
    UpdateApplicationUserByIdMutationVariables
  >;
export const UpdateAvatarByIdDocument = gql`
  mutation UpdateAvatarById($id: BigInt!, $patch: AvatarPatch!) {
    updateAvatarById(input: { id: $id, avatarPatch: $patch }) {
      clientMutationId
      avatar {
        ...AvatarFields
      }
    }
  }
  ${AvatarFieldsFragmentDoc}
`;
export type UpdateAvatarByIdMutationFn = Apollo.MutationFunction<
  UpdateAvatarByIdMutation,
  UpdateAvatarByIdMutationVariables
>;

/**
 * __useUpdateAvatarByIdMutation__
 *
 * To run a mutation, you first call `useUpdateAvatarByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAvatarByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAvatarByIdMutation, { data, loading, error }] = useUpdateAvatarByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateAvatarByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAvatarByIdMutation,
    UpdateAvatarByIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAvatarByIdMutation,
    UpdateAvatarByIdMutationVariables
  >(UpdateAvatarByIdDocument, options);
}
export type UpdateAvatarByIdMutationHookResult = ReturnType<
  typeof useUpdateAvatarByIdMutation
>;
export type UpdateAvatarByIdMutationResult =
  Apollo.MutationResult<UpdateAvatarByIdMutation>;
export type UpdateAvatarByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateAvatarByIdMutation,
  UpdateAvatarByIdMutationVariables
>;
export const UpdateDatasetByIdDocument = gql`
  mutation UpdateDatasetById($id: UUID!, $patch: DatasetPatch!) {
    updateDataset: updateDatasetById(input: { id: $id, datasetPatch: $patch }) {
      clientMutationId
      dataset: dataset {
        ...DatasetFields
      }
    }
  }
  ${DatasetFieldsFragmentDoc}
`;
export type UpdateDatasetByIdMutationFn = Apollo.MutationFunction<
  UpdateDatasetByIdMutation,
  UpdateDatasetByIdMutationVariables
>;

/**
 * __useUpdateDatasetByIdMutation__
 *
 * To run a mutation, you first call `useUpdateDatasetByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDatasetByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDatasetByIdMutation, { data, loading, error }] = useUpdateDatasetByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateDatasetByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDatasetByIdMutation,
    UpdateDatasetByIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDatasetByIdMutation,
    UpdateDatasetByIdMutationVariables
  >(UpdateDatasetByIdDocument, options);
}
export type UpdateDatasetByIdMutationHookResult = ReturnType<
  typeof useUpdateDatasetByIdMutation
>;
export type UpdateDatasetByIdMutationResult =
  Apollo.MutationResult<UpdateDatasetByIdMutation>;
export type UpdateDatasetByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateDatasetByIdMutation,
  UpdateDatasetByIdMutationVariables
>;
export const UpdateUserMetadatumByIdDocument = gql`
  mutation UpdateUserMetadatumById(
    $id: Int!
    $patch: ApplicationUserMetadatumPatch!
  ) {
    updateUserMetadatumById: updateApplicationUserMetadatumById(
      input: { id: $id, applicationUserMetadatumPatch: $patch }
    ) {
      clientMutationId
      userMetadatum: applicationUserMetadatum {
        ...UserMetadatumFields
      }
    }
  }
  ${UserMetadatumFieldsFragmentDoc}
`;
export type UpdateUserMetadatumByIdMutationFn = Apollo.MutationFunction<
  UpdateUserMetadatumByIdMutation,
  UpdateUserMetadatumByIdMutationVariables
>;

/**
 * __useUpdateUserMetadatumByIdMutation__
 *
 * To run a mutation, you first call `useUpdateUserMetadatumByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMetadatumByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMetadatumByIdMutation, { data, loading, error }] = useUpdateUserMetadatumByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateUserMetadatumByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMetadatumByIdMutation,
    UpdateUserMetadatumByIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserMetadatumByIdMutation,
    UpdateUserMetadatumByIdMutationVariables
  >(UpdateUserMetadatumByIdDocument, options);
}
export type UpdateUserMetadatumByIdMutationHookResult = ReturnType<
  typeof useUpdateUserMetadatumByIdMutation
>;
export type UpdateUserMetadatumByIdMutationResult =
  Apollo.MutationResult<UpdateUserMetadatumByIdMutation>;
export type UpdateUserMetadatumByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMetadatumByIdMutation,
  UpdateUserMetadatumByIdMutationVariables
>;
export const GetAllDatasetRecordingsTableColumnsDocument = gql`
  query GetAllDatasetRecordingsTableColumns($datasetRecordingsTableId: UUID!) {
    datasetRecordingsTableColumns: allDatasetRecordingsTableColumns(
      condition: {
        datasetRecordingsTableId: $datasetRecordingsTableId
        dateAssignmentEnded: null
      }
    ) {
      nodes {
        ...DatasetRecordingsTableColumnFields
        colDef: metadatumByColDefId {
          ...MetadatumFields
          metadataValues: metadataValuesByMetadataId(
            orderBy: DATE_CREATED_ASC
            last: 1
          ) {
            nodes {
              ...MetadataValueFields
            }
          }
        }
      }
    }
  }
  ${DatasetRecordingsTableColumnFieldsFragmentDoc}
  ${MetadatumFieldsFragmentDoc}
  ${MetadataValueFieldsFragmentDoc}
`;

/**
 * __useGetAllDatasetRecordingsTableColumnsQuery__
 *
 * To run a query within a React component, call `useGetAllDatasetRecordingsTableColumnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDatasetRecordingsTableColumnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDatasetRecordingsTableColumnsQuery({
 *   variables: {
 *      datasetRecordingsTableId: // value for 'datasetRecordingsTableId'
 *   },
 * });
 */
export function useGetAllDatasetRecordingsTableColumnsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllDatasetRecordingsTableColumnsQuery,
    GetAllDatasetRecordingsTableColumnsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllDatasetRecordingsTableColumnsQuery,
    GetAllDatasetRecordingsTableColumnsQueryVariables
  >(GetAllDatasetRecordingsTableColumnsDocument, options);
}
export function useGetAllDatasetRecordingsTableColumnsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllDatasetRecordingsTableColumnsQuery,
    GetAllDatasetRecordingsTableColumnsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllDatasetRecordingsTableColumnsQuery,
    GetAllDatasetRecordingsTableColumnsQueryVariables
  >(GetAllDatasetRecordingsTableColumnsDocument, options);
}
export type GetAllDatasetRecordingsTableColumnsQueryHookResult = ReturnType<
  typeof useGetAllDatasetRecordingsTableColumnsQuery
>;
export type GetAllDatasetRecordingsTableColumnsLazyQueryHookResult = ReturnType<
  typeof useGetAllDatasetRecordingsTableColumnsLazyQuery
>;
export type GetAllDatasetRecordingsTableColumnsQueryResult = Apollo.QueryResult<
  GetAllDatasetRecordingsTableColumnsQuery,
  GetAllDatasetRecordingsTableColumnsQueryVariables
>;
export const GetAllDatasetsDocument = gql`
  query GetAllDatasets($filter: DatasetFilter, $orderBy: [DatasetsOrderBy!]) {
    datasets: allDatasets(filter: $filter, orderBy: $orderBy) {
      nodes {
        ...DatasetFields
        user: applicationUserByUserId {
          ...UserFields
        }
      }
    }
  }
  ${DatasetFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;

/**
 * __useGetAllDatasetsQuery__
 *
 * To run a query within a React component, call `useGetAllDatasetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDatasetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDatasetsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllDatasetsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllDatasetsQuery,
    GetAllDatasetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllDatasetsQuery, GetAllDatasetsQueryVariables>(
    GetAllDatasetsDocument,
    options,
  );
}
export function useGetAllDatasetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllDatasetsQuery,
    GetAllDatasetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllDatasetsQuery, GetAllDatasetsQueryVariables>(
    GetAllDatasetsDocument,
    options,
  );
}
export type GetAllDatasetsQueryHookResult = ReturnType<
  typeof useGetAllDatasetsQuery
>;
export type GetAllDatasetsLazyQueryHookResult = ReturnType<
  typeof useGetAllDatasetsLazyQuery
>;
export type GetAllDatasetsQueryResult = Apollo.QueryResult<
  GetAllDatasetsQuery,
  GetAllDatasetsQueryVariables
>;
export const GetAllProjectsDocument = gql`
  query GetAllProjects($filter: ProjectFilter, $orderBy: [ProjectsOrderBy!]) {
    projects: allProjects(filter: $filter, orderBy: $orderBy) {
      nodes {
        ...ProjectFields
        user: applicationUserByUserId {
          ...UserFields
        }
        tenantByTenantId {
          id
          name
          key
        }
      }
    }
  }
  ${ProjectFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;

/**
 * __useGetAllProjectsQuery__
 *
 * To run a query within a React component, call `useGetAllProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProjectsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllProjectsQuery,
    GetAllProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllProjectsQuery, GetAllProjectsQueryVariables>(
    GetAllProjectsDocument,
    options,
  );
}
export function useGetAllProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllProjectsQuery,
    GetAllProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllProjectsQuery, GetAllProjectsQueryVariables>(
    GetAllProjectsDocument,
    options,
  );
}
export type GetAllProjectsQueryHookResult = ReturnType<
  typeof useGetAllProjectsQuery
>;
export type GetAllProjectsLazyQueryHookResult = ReturnType<
  typeof useGetAllProjectsLazyQuery
>;
export type GetAllProjectsQueryResult = Apollo.QueryResult<
  GetAllProjectsQuery,
  GetAllProjectsQueryVariables
>;
export const GetAllFileRecordingGroupsDocument = gql`
  query GetAllFileRecordingGroups($filter: FileRecordingGroupFilter) {
    recordingGroups: allFileRecordingGroups(
      condition: { dateAssignmentEnded: null }
      filter: $filter
    ) {
      nodes {
        ...FileRecordingGroupFields
        recordingGroupDrsFiles: recordingGroupFilesByFileRecordingGroupId(
          condition: { dateAssignmentEnded: null }
        ) {
          nodes {
            ...RecordingGroupFileFields
            drsFile: fileByFileId {
              ...FileFields
            }
          }
        }
      }
    }
  }
  ${FileRecordingGroupFieldsFragmentDoc}
  ${RecordingGroupFileFieldsFragmentDoc}
  ${FileFieldsFragmentDoc}
`;

/**
 * __useGetAllFileRecordingGroupsQuery__
 *
 * To run a query within a React component, call `useGetAllFileRecordingGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFileRecordingGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFileRecordingGroupsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAllFileRecordingGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllFileRecordingGroupsQuery,
    GetAllFileRecordingGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllFileRecordingGroupsQuery,
    GetAllFileRecordingGroupsQueryVariables
  >(GetAllFileRecordingGroupsDocument, options);
}
export function useGetAllFileRecordingGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllFileRecordingGroupsQuery,
    GetAllFileRecordingGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllFileRecordingGroupsQuery,
    GetAllFileRecordingGroupsQueryVariables
  >(GetAllFileRecordingGroupsDocument, options);
}
export type GetAllFileRecordingGroupsQueryHookResult = ReturnType<
  typeof useGetAllFileRecordingGroupsQuery
>;
export type GetAllFileRecordingGroupsLazyQueryHookResult = ReturnType<
  typeof useGetAllFileRecordingGroupsLazyQuery
>;
export type GetAllFileRecordingGroupsQueryResult = Apollo.QueryResult<
  GetAllFileRecordingGroupsQuery,
  GetAllFileRecordingGroupsQueryVariables
>;
export const GetAllTasksDocument = gql`
  query GetAllTasks($condition: TaskCondition, $filter: TaskFilter) {
    tasks: allTasks(
      condition: $condition
      filter: $filter
      orderBy: CREATED_DESC
    ) {
      nodes {
        id
        dataset: datasetByDatasetId {
          ...DatasetFields
        }
        toolVersion: toolVersionByToolVersionId {
          ...ToolVersionFields
        }
        outputGroup: fileOutputGroupByTaskId {
          ...FileOutputGroupFields
          task: taskByTaskId {
            id
            status
            created
            analysisTableRow: analysisTableRowByTaskId {
              id
              analysisTableId: tableId
              attachments
              activeAttachResults
            }
          }
          outputGroupFiles: outputGroupFilesByFileOutputGroupId {
            nodes {
              id
              drsFile: fileByFileId {
                ...FileCache
                fileMetadata: fileMetadataByFileId {
                  nodes {
                    id
                    metadata: metadatumByMetadataId {
                      id
                      key
                      activeValue
                    }
                  }
                }
              }
            }
          }
          metadata: fileOutputGroupMetadataByFileOutputGroupId {
            nodes {
              id
              metadatum: metadatumByMetadataId {
                ...MetadatumFields
                values: metadataValuesByMetadataId(
                  orderBy: DATE_CREATED_ASC
                  last: 1
                ) {
                  nodes {
                    ...MetadataValueFields
                  }
                }
              }
            }
          }
        }
        user: applicationUserByUserId {
          ...UserFields
        }
        ...TaskFields
        taskActivityByTaskId {
          id
          duration {
            hours
            seconds
            minutes
            days
            years
            months
          }
        }
        taskResourcesByTaskId {
          nodes {
            id
            type
            value
          }
        }
      }
    }
  }
  ${DatasetFieldsFragmentDoc}
  ${ToolVersionFieldsFragmentDoc}
  ${FileOutputGroupFieldsFragmentDoc}
  ${FileCacheFragmentDoc}
  ${MetadatumFieldsFragmentDoc}
  ${MetadataValueFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${TaskFieldsFragmentDoc}
`;

/**
 * __useGetAllTasksQuery__
 *
 * To run a query within a React component, call `useGetAllTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTasksQuery({
 *   variables: {
 *      condition: // value for 'condition'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAllTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllTasksQuery,
    GetAllTasksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllTasksQuery, GetAllTasksQueryVariables>(
    GetAllTasksDocument,
    options,
  );
}
export function useGetAllTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllTasksQuery,
    GetAllTasksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllTasksQuery, GetAllTasksQueryVariables>(
    GetAllTasksDocument,
    options,
  );
}
export type GetAllTasksQueryHookResult = ReturnType<typeof useGetAllTasksQuery>;
export type GetAllTasksLazyQueryHookResult = ReturnType<
  typeof useGetAllTasksLazyQuery
>;
export type GetAllTasksQueryResult = Apollo.QueryResult<
  GetAllTasksQuery,
  GetAllTasksQueryVariables
>;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    currentUser: getCurrentUser {
      ...UserFields
      metadata: applicationUserMetadataByUserId {
        nodes {
          ...UserMetadatumFields
        }
      }
      memberships: applicationUserMembershipsByUserId {
        nodes {
          id
          tenant: tenantByTenantId {
            ...TenantFields
          }
        }
      }
    }
  }
  ${UserFieldsFragmentDoc}
  ${UserMetadatumFieldsFragmentDoc}
  ${TenantFieldsFragmentDoc}
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const GetFileMetadataByFileIdDocument = gql`
  query GetFileMetadataByFileId(
    $drsFileId: UUID!
    $metadataFilter: FileMetadatumFilter
  ) {
    drsFile: fileById(id: $drsFileId) {
      id
      metadata: fileMetadataByFileId(
        orderBy: ID_DESC
        filter: $metadataFilter
      ) {
        nodes {
          id
          metadatum: metadatumByMetadataId {
            ...MetadatumFields
            activeValue
          }
        }
      }
      seriesParentFile: fileBySeriesParentId {
        id
        metadata: fileMetadataByFileId(
          orderBy: ID_DESC
          filter: $metadataFilter
        ) {
          nodes {
            id
            metadatum: metadatumByMetadataId {
              ...MetadatumFields
              activeValue
            }
          }
        }
      }
    }
  }
  ${MetadatumFieldsFragmentDoc}
`;

/**
 * __useGetFileMetadataByFileIdQuery__
 *
 * To run a query within a React component, call `useGetFileMetadataByFileIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileMetadataByFileIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileMetadataByFileIdQuery({
 *   variables: {
 *      drsFileId: // value for 'drsFileId'
 *      metadataFilter: // value for 'metadataFilter'
 *   },
 * });
 */
export function useGetFileMetadataByFileIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFileMetadataByFileIdQuery,
    GetFileMetadataByFileIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFileMetadataByFileIdQuery,
    GetFileMetadataByFileIdQueryVariables
  >(GetFileMetadataByFileIdDocument, options);
}
export function useGetFileMetadataByFileIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFileMetadataByFileIdQuery,
    GetFileMetadataByFileIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFileMetadataByFileIdQuery,
    GetFileMetadataByFileIdQueryVariables
  >(GetFileMetadataByFileIdDocument, options);
}
export type GetFileMetadataByFileIdQueryHookResult = ReturnType<
  typeof useGetFileMetadataByFileIdQuery
>;
export type GetFileMetadataByFileIdLazyQueryHookResult = ReturnType<
  typeof useGetFileMetadataByFileIdLazyQuery
>;
export type GetFileMetadataByFileIdQueryResult = Apollo.QueryResult<
  GetFileMetadataByFileIdQuery,
  GetFileMetadataByFileIdQueryVariables
>;
export const GetProjectByIdDocument = gql`
  query GetProjectById($id: UUID!) {
    projectById(id: $id) {
      ...ProjectFields
    }
  }
  ${ProjectFieldsFragmentDoc}
`;

/**
 * __useGetProjectByIdQuery__
 *
 * To run a query within a React component, call `useGetProjectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectByIdQuery,
    GetProjectByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectByIdQuery, GetProjectByIdQueryVariables>(
    GetProjectByIdDocument,
    options,
  );
}
export function useGetProjectByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectByIdQuery,
    GetProjectByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectByIdQuery, GetProjectByIdQueryVariables>(
    GetProjectByIdDocument,
    options,
  );
}
export type GetProjectByIdQueryHookResult = ReturnType<
  typeof useGetProjectByIdQuery
>;
export type GetProjectByIdLazyQueryHookResult = ReturnType<
  typeof useGetProjectByIdLazyQuery
>;
export type GetProjectByIdQueryResult = Apollo.QueryResult<
  GetProjectByIdQuery,
  GetProjectByIdQueryVariables
>;
export const GetTaskByIdDocument = gql`
  query GetTaskById($id: UUID!) {
    task: taskById(id: $id) {
      ...TaskFields
    }
  }
  ${TaskFieldsFragmentDoc}
`;

/**
 * __useGetTaskByIdQuery__
 *
 * To run a query within a React component, call `useGetTaskByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTaskByIdQuery,
    GetTaskByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTaskByIdQuery, GetTaskByIdQueryVariables>(
    GetTaskByIdDocument,
    options,
  );
}
export function useGetTaskByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTaskByIdQuery,
    GetTaskByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTaskByIdQuery, GetTaskByIdQueryVariables>(
    GetTaskByIdDocument,
    options,
  );
}
export type GetTaskByIdQueryHookResult = ReturnType<typeof useGetTaskByIdQuery>;
export type GetTaskByIdLazyQueryHookResult = ReturnType<
  typeof useGetTaskByIdLazyQuery
>;
export type GetTaskByIdQueryResult = Apollo.QueryResult<
  GetTaskByIdQuery,
  GetTaskByIdQueryVariables
>;
export const GetToolByKeyDocument = gql`
  query GetToolByKey($key: String!) {
    tool: toolByKey(key: $key) {
      ...ToolFields
      versions: toolVersionsByToolId {
        nodes {
          ...ToolVersionFields
          sources: toolSourcesByToolVersionId(orderBy: ID_ASC) {
            nodes {
              ...ToolSourceFields
            }
          }
          results: toolResultsByToolVersionId(orderBy: ID_ASC) {
            nodes {
              ...ToolResultFields
            }
          }
          parameters: toolParametersByToolVersionId(orderBy: ID_ASC) {
            nodes {
              ...ToolParameterFields
            }
          }
        }
      }
      categories: toolDisplayCategoriesByToolId {
        nodes {
          id
          category: toolCategoryByToolcategoryId {
            ...ToolCategoryFields
          }
        }
      }
    }
  }
  ${ToolFieldsFragmentDoc}
  ${ToolVersionFieldsFragmentDoc}
  ${ToolSourceFieldsFragmentDoc}
  ${ToolResultFieldsFragmentDoc}
  ${ToolParameterFieldsFragmentDoc}
  ${ToolCategoryFieldsFragmentDoc}
`;

/**
 * __useGetToolByKeyQuery__
 *
 * To run a query within a React component, call `useGetToolByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToolByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToolByKeyQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetToolByKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetToolByKeyQuery,
    GetToolByKeyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetToolByKeyQuery, GetToolByKeyQueryVariables>(
    GetToolByKeyDocument,
    options,
  );
}
export function useGetToolByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetToolByKeyQuery,
    GetToolByKeyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetToolByKeyQuery, GetToolByKeyQueryVariables>(
    GetToolByKeyDocument,
    options,
  );
}
export type GetToolByKeyQueryHookResult = ReturnType<
  typeof useGetToolByKeyQuery
>;
export type GetToolByKeyLazyQueryHookResult = ReturnType<
  typeof useGetToolByKeyLazyQuery
>;
export type GetToolByKeyQueryResult = Apollo.QueryResult<
  GetToolByKeyQuery,
  GetToolByKeyQueryVariables
>;
export const GetToolVersionsByToolIdAndVersionsDocument = gql`
  query GetToolVersionsByToolIdAndVersions(
    $toolId: Int!
    $versions: [String!]!
  ) {
    toolVersions: allToolVersions(
      filter: {
        and: [{ toolId: { equalTo: $toolId } }, { version: { in: $versions } }]
      }
    ) {
      nodes {
        ...ToolVersionFields
        toolSources: toolSourcesByToolVersionId {
          nodes {
            ...ToolSourceFields
          }
        }
        toolParameters: toolParametersByToolVersionId {
          nodes {
            ...ToolParameterFields
          }
        }
        toolResults: toolResultsByToolVersionId {
          nodes {
            ...ToolResultFields
          }
        }
      }
    }
  }
  ${ToolVersionFieldsFragmentDoc}
  ${ToolSourceFieldsFragmentDoc}
  ${ToolParameterFieldsFragmentDoc}
  ${ToolResultFieldsFragmentDoc}
`;

/**
 * __useGetToolVersionsByToolIdAndVersionsQuery__
 *
 * To run a query within a React component, call `useGetToolVersionsByToolIdAndVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToolVersionsByToolIdAndVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToolVersionsByToolIdAndVersionsQuery({
 *   variables: {
 *      toolId: // value for 'toolId'
 *      versions: // value for 'versions'
 *   },
 * });
 */
export function useGetToolVersionsByToolIdAndVersionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetToolVersionsByToolIdAndVersionsQuery,
    GetToolVersionsByToolIdAndVersionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetToolVersionsByToolIdAndVersionsQuery,
    GetToolVersionsByToolIdAndVersionsQueryVariables
  >(GetToolVersionsByToolIdAndVersionsDocument, options);
}
export function useGetToolVersionsByToolIdAndVersionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetToolVersionsByToolIdAndVersionsQuery,
    GetToolVersionsByToolIdAndVersionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetToolVersionsByToolIdAndVersionsQuery,
    GetToolVersionsByToolIdAndVersionsQueryVariables
  >(GetToolVersionsByToolIdAndVersionsDocument, options);
}
export type GetToolVersionsByToolIdAndVersionsQueryHookResult = ReturnType<
  typeof useGetToolVersionsByToolIdAndVersionsQuery
>;
export type GetToolVersionsByToolIdAndVersionsLazyQueryHookResult = ReturnType<
  typeof useGetToolVersionsByToolIdAndVersionsLazyQuery
>;
export type GetToolVersionsByToolIdAndVersionsQueryResult = Apollo.QueryResult<
  GetToolVersionsByToolIdAndVersionsQuery,
  GetToolVersionsByToolIdAndVersionsQueryVariables
>;
export const GetUserSessionByIdDocument = gql`
  query GetUserSessionById($id: UUID!) {
    userSession: applicationUserSessionById(id: $id) {
      ...UserSessionFields
    }
  }
  ${UserSessionFieldsFragmentDoc}
`;

/**
 * __useGetUserSessionByIdQuery__
 *
 * To run a query within a React component, call `useGetUserSessionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSessionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSessionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserSessionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserSessionByIdQuery,
    GetUserSessionByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserSessionByIdQuery,
    GetUserSessionByIdQueryVariables
  >(GetUserSessionByIdDocument, options);
}
export function useGetUserSessionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserSessionByIdQuery,
    GetUserSessionByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserSessionByIdQuery,
    GetUserSessionByIdQueryVariables
  >(GetUserSessionByIdDocument, options);
}
export type GetUserSessionByIdQueryHookResult = ReturnType<
  typeof useGetUserSessionByIdQuery
>;
export type GetUserSessionByIdLazyQueryHookResult = ReturnType<
  typeof useGetUserSessionByIdLazyQuery
>;
export type GetUserSessionByIdQueryResult = Apollo.QueryResult<
  GetUserSessionByIdQuery,
  GetUserSessionByIdQueryVariables
>;
export const ExportToDandiToolInfoDocument = gql`
  query ExportToDandiToolInfo {
    tool: toolByKey(key: "export_to_dandi") {
      id
      toolVersions: toolVersionsByToolId(orderBy: VERSION_ASC, last: 1) {
        nodes {
          id
          toolSources: toolSourcesByToolVersionId {
            nodes {
              id
              key
            }
          }
          toolParameters: toolParametersByToolVersionId {
            nodes {
              id
              key
            }
          }
          toolSecrets: toolSecretsByToolVersionId {
            nodes {
              id
              key
            }
          }
        }
      }
    }
  }
`;

/**
 * __useExportToDandiToolInfoQuery__
 *
 * To run a query within a React component, call `useExportToDandiToolInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportToDandiToolInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportToDandiToolInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportToDandiToolInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ExportToDandiToolInfoQuery,
    ExportToDandiToolInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExportToDandiToolInfoQuery,
    ExportToDandiToolInfoQueryVariables
  >(ExportToDandiToolInfoDocument, options);
}
export function useExportToDandiToolInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportToDandiToolInfoQuery,
    ExportToDandiToolInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExportToDandiToolInfoQuery,
    ExportToDandiToolInfoQueryVariables
  >(ExportToDandiToolInfoDocument, options);
}
export type ExportToDandiToolInfoQueryHookResult = ReturnType<
  typeof useExportToDandiToolInfoQuery
>;
export type ExportToDandiToolInfoLazyQueryHookResult = ReturnType<
  typeof useExportToDandiToolInfoLazyQuery
>;
export type ExportToDandiToolInfoQueryResult = Apollo.QueryResult<
  ExportToDandiToolInfoQuery,
  ExportToDandiToolInfoQueryVariables
>;
export const GetProcessingStatusDocument = gql`
  query GetProcessingStatus($drsFileId: UUID!) {
    file: fileById(id: $drsFileId) {
      id
      processingStatus
      taskSources: taskSourcesByFileId {
        nodes {
          id
          task: taskByTaskId {
            id
            status
            errorDescription
            toolVersion: toolVersionByToolVersionId {
              id
              tool: toolByToolId {
                id
                name
                key
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetProcessingStatusQuery__
 *
 * To run a query within a React component, call `useGetProcessingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProcessingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProcessingStatusQuery({
 *   variables: {
 *      drsFileId: // value for 'drsFileId'
 *   },
 * });
 */
export function useGetProcessingStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProcessingStatusQuery,
    GetProcessingStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProcessingStatusQuery,
    GetProcessingStatusQueryVariables
  >(GetProcessingStatusDocument, options);
}
export function useGetProcessingStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProcessingStatusQuery,
    GetProcessingStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProcessingStatusQuery,
    GetProcessingStatusQueryVariables
  >(GetProcessingStatusDocument, options);
}
export type GetProcessingStatusQueryHookResult = ReturnType<
  typeof useGetProcessingStatusQuery
>;
export type GetProcessingStatusLazyQueryHookResult = ReturnType<
  typeof useGetProcessingStatusLazyQuery
>;
export type GetProcessingStatusQueryResult = Apollo.QueryResult<
  GetProcessingStatusQuery,
  GetProcessingStatusQueryVariables
>;
export const GetExternalTenantsDocument = gql`
  query GetExternalTenants {
    currentUser: getCurrentUser {
      id
      memberships: applicationUserMembershipsByUserId {
        nodes {
          id
          tenant: tenantByTenantId {
            id
            key
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetExternalTenantsQuery__
 *
 * To run a query within a React component, call `useGetExternalTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExternalTenantsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetExternalTenantsQuery,
    GetExternalTenantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetExternalTenantsQuery,
    GetExternalTenantsQueryVariables
  >(GetExternalTenantsDocument, options);
}
export function useGetExternalTenantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExternalTenantsQuery,
    GetExternalTenantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExternalTenantsQuery,
    GetExternalTenantsQueryVariables
  >(GetExternalTenantsDocument, options);
}
export type GetExternalTenantsQueryHookResult = ReturnType<
  typeof useGetExternalTenantsQuery
>;
export type GetExternalTenantsLazyQueryHookResult = ReturnType<
  typeof useGetExternalTenantsLazyQuery
>;
export type GetExternalTenantsQueryResult = Apollo.QueryResult<
  GetExternalTenantsQuery,
  GetExternalTenantsQueryVariables
>;
export const PageProjectDocument = gql`
  query PageProject($projectKey: String!, $tenantId: Int!) {
    project: projectByKeyAndTenantId(key: $projectKey, tenantId: $tenantId) {
      ...ProjectFields
      datasets: datasetsByProjectId {
        nodes {
          ...DatasetFields
          datasetVersions: datasetVersionsByDatasetId {
            nodes {
              ...DatasetVersionFields
            }
          }
          description: metadatumByDescriptionId {
            ...MetadatumFields
            metadataValuesByMetadataId(orderBy: DATE_CREATED_ASC, last: 1) {
              nodes {
                ...MetadataValueFields
              }
            }
          }
        }
      }
      analysisTableGroups: analysisTableGroupsByProjectId {
        nodes {
          id
          name
          hidden
          projectId
          dateCreated
          toolId
          tool: toolByToolId {
            id
            key
            name
          }
          analysisTables: analysisTablesByGroupId {
            nodes {
              ...AnalysisTableFields
              toolVersion: toolVersionByToolVersionId {
                ...ToolVersionFields
                toolSpec
                tool: toolByToolId {
                  ...ToolFields
                }
              }
              rows: analysisTableRowsByTableId {
                nodes {
                  ...AnalysisTableRowFields
                  activeAttachResults
                  task: taskByTaskId {
                    ...TaskFields
                    outputGroup: fileOutputGroupByTaskId {
                      ...FileOutputGroupFields
                      outputGroupFiles: outputGroupFilesByFileOutputGroupId {
                        nodes {
                          id
                          drsFile: fileByFileId {
                            ...FileFields
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${ProjectFieldsFragmentDoc}
  ${DatasetFieldsFragmentDoc}
  ${DatasetVersionFieldsFragmentDoc}
  ${MetadatumFieldsFragmentDoc}
  ${MetadataValueFieldsFragmentDoc}
  ${AnalysisTableFieldsFragmentDoc}
  ${ToolVersionFieldsFragmentDoc}
  ${ToolFieldsFragmentDoc}
  ${AnalysisTableRowFieldsFragmentDoc}
  ${TaskFieldsFragmentDoc}
  ${FileOutputGroupFieldsFragmentDoc}
  ${FileFieldsFragmentDoc}
`;

/**
 * __usePageProjectQuery__
 *
 * To run a query within a React component, call `usePageProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageProjectQuery({
 *   variables: {
 *      projectKey: // value for 'projectKey'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function usePageProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    PageProjectQuery,
    PageProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PageProjectQuery, PageProjectQueryVariables>(
    PageProjectDocument,
    options,
  );
}
export function usePageProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PageProjectQuery,
    PageProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PageProjectQuery, PageProjectQueryVariables>(
    PageProjectDocument,
    options,
  );
}
export type PageProjectQueryHookResult = ReturnType<typeof usePageProjectQuery>;
export type PageProjectLazyQueryHookResult = ReturnType<
  typeof usePageProjectLazyQuery
>;
export type PageProjectQueryResult = Apollo.QueryResult<
  PageProjectQuery,
  PageProjectQueryVariables
>;
export const PageProjectAnalysisDocument = gql`
  query PageProjectAnalysis(
    $analysisTableGroupId: BigInt!
    $recordingGroupFileFilter: RecordingGroupFileFilter
  ) {
    analysisTableGroup: analysisTableGroupById(id: $analysisTableGroupId) {
      id
      dateCreated
      name
      description
      projectId
      tenantId
      userId
      hidden
      tool: toolByToolId {
        id
        key
        name
      }
      toolId
      userId
      hidden
      analysisTables: analysisTablesByGroupId {
        nodes {
          ...AnalysisTableFields
          groupId
          analysisTableRows: analysisTableRowsByTableId(
            orderBy: [DATE_CREATED_ASC]
            filter: { deleted: { notEqualTo: true } }
          ) {
            nodes {
              ...AnalysisTableRowFields
              toolVersion: toolVersionByToolVersionId {
                id
                version
              }
              activeAttachResults
              metadatumReferences
              task: taskByTaskId {
                ...TaskFields
                taskActivityByTaskId {
                  id
                  duration {
                    hours
                    seconds
                    minutes
                    days
                    years
                    months
                  }
                }
                taskResourcesByTaskId {
                  nodes {
                    id
                    type
                    value
                  }
                }
                outputGroup: fileOutputGroupByTaskId {
                  ...FileOutputGroupFields
                  outputGroupFiles: outputGroupFilesByFileOutputGroupId {
                    nodes {
                      id
                      drsFile: fileByFileId {
                        ...FileFields
                      }
                    }
                  }
                }
              }
            }
          }
          project: projectByProjectId {
            ...ProjectFields
            datasets: datasetsByProjectId {
              nodes {
                ...DatasetFields
                datasetRecordingsTable: datasetRecordingsTableByRecordingsTableId {
                  ...DatasetRecordingsTableFields
                  recordingGroups: fileRecordingGroupsByDatasetRecordingsTableId(
                    orderBy: DATE_CREATED_ASC
                  ) {
                    nodes {
                      ...FileRecordingGroupFields
                      number
                      recordingGroupFiles: recordingGroupFilesByFileRecordingGroupId(
                        filter: $recordingGroupFileFilter
                      ) {
                        nodes {
                          ...RecordingGroupFileFields
                          drsFiles: fileByFileId {
                            ...FileFields
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          toolVersion: toolVersionByToolVersionId {
            id
            toolSpec
            version
            maturity
            credits
            compatibleVersions {
              nodes {
                id
                toolSpec
                version
                maturity
                credits
                tool: toolByToolId {
                  id
                  key
                  name
                }
              }
            }
          }
        }
      }
    }
  }
  ${AnalysisTableFieldsFragmentDoc}
  ${AnalysisTableRowFieldsFragmentDoc}
  ${TaskFieldsFragmentDoc}
  ${FileOutputGroupFieldsFragmentDoc}
  ${FileFieldsFragmentDoc}
  ${ProjectFieldsFragmentDoc}
  ${DatasetFieldsFragmentDoc}
  ${DatasetRecordingsTableFieldsFragmentDoc}
  ${FileRecordingGroupFieldsFragmentDoc}
  ${RecordingGroupFileFieldsFragmentDoc}
`;

/**
 * __usePageProjectAnalysisQuery__
 *
 * To run a query within a React component, call `usePageProjectAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageProjectAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageProjectAnalysisQuery({
 *   variables: {
 *      analysisTableGroupId: // value for 'analysisTableGroupId'
 *      recordingGroupFileFilter: // value for 'recordingGroupFileFilter'
 *   },
 * });
 */
export function usePageProjectAnalysisQuery(
  baseOptions: Apollo.QueryHookOptions<
    PageProjectAnalysisQuery,
    PageProjectAnalysisQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PageProjectAnalysisQuery,
    PageProjectAnalysisQueryVariables
  >(PageProjectAnalysisDocument, options);
}
export function usePageProjectAnalysisLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PageProjectAnalysisQuery,
    PageProjectAnalysisQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PageProjectAnalysisQuery,
    PageProjectAnalysisQueryVariables
  >(PageProjectAnalysisDocument, options);
}
export type PageProjectAnalysisQueryHookResult = ReturnType<
  typeof usePageProjectAnalysisQuery
>;
export type PageProjectAnalysisLazyQueryHookResult = ReturnType<
  typeof usePageProjectAnalysisLazyQuery
>;
export type PageProjectAnalysisQueryResult = Apollo.QueryResult<
  PageProjectAnalysisQuery,
  PageProjectAnalysisQueryVariables
>;
export const GetDrsFilesDocument = gql`
  query GetDrsFiles($ids: [UUID!]!) {
    drsFiles: allFiles(filter: { id: { in: $ids } }) {
      nodes {
        ...FileCache
      }
    }
  }
  ${FileCacheFragmentDoc}
`;

/**
 * __useGetDrsFilesQuery__
 *
 * To run a query within a React component, call `useGetDrsFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDrsFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDrsFilesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetDrsFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDrsFilesQuery,
    GetDrsFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDrsFilesQuery, GetDrsFilesQueryVariables>(
    GetDrsFilesDocument,
    options,
  );
}
export function useGetDrsFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDrsFilesQuery,
    GetDrsFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDrsFilesQuery, GetDrsFilesQueryVariables>(
    GetDrsFilesDocument,
    options,
  );
}
export type GetDrsFilesQueryHookResult = ReturnType<typeof useGetDrsFilesQuery>;
export type GetDrsFilesLazyQueryHookResult = ReturnType<
  typeof useGetDrsFilesLazyQuery
>;
export type GetDrsFilesQueryResult = Apollo.QueryResult<
  GetDrsFilesQuery,
  GetDrsFilesQueryVariables
>;
export const PageDatasetDocument = gql`
  query PageDataset(
    $datasetId: UUID!
    $cutoffTime: Datetime = "3000-01-01T00:00:00.000Z"
  ) {
    dataset: datasetById(id: $datasetId) {
      ...DatasetFields
      description: metadatumByDescriptionId {
        ...MetadatumFields
        metadataValuesByMetadataId(
          orderBy: DATE_CREATED_ASC
          last: 1
          filter: { dateCreated: { lessThanOrEqualTo: $cutoffTime } }
        ) {
          nodes {
            ...MetadataValueFields
          }
        }
      }
      datasetVersions: datasetVersionsByDatasetId(orderBy: DATE_CREATED_DESC) {
        nodes {
          ...DatasetVersionFields
        }
      }
      datasetRecordingsTable: datasetRecordingsTableByRecordingsTableId {
        ...DatasetRecordingsTableFields
        datasetRecordingsTableColumns: activeColumns(at: $cutoffTime) {
          nodes {
            ...DatasetRecordingsTableColumnFields
            activeColDef(at: $cutoffTime)
            activeOrder(at: $cutoffTime)
          }
        }
        fileRecordingGroups: fileRecordingGroupsByDatasetRecordingsTableId(
          orderBy: DATE_CREATED_ASC
          filter: {
            and: [
              { dateCreated: { lessThanOrEqualTo: $cutoffTime } }
              {
                or: [
                  { dateAssignmentEnded: { isNull: true } }
                  { dateAssignmentEnded: { greaterThanOrEqualTo: $cutoffTime } }
                ]
              }
            ]
          }
        ) {
          nodes {
            ...FileRecordingGroupFields
            number
            recordingGroupFiles: recordingGroupFilesByFileRecordingGroupId(
              filter: {
                and: [
                  { dateCreated: { lessThanOrEqualTo: $cutoffTime } }
                  {
                    or: [
                      { dateAssignmentEnded: { isNull: true } }
                      {
                        dateAssignmentEnded: {
                          greaterThanOrEqualTo: $cutoffTime
                        }
                      }
                    ]
                  }
                ]
              }
            ) {
              nodes {
                ...RecordingGroupFileFields
              }
            }
            metadata: fileRecordingGroupMetadataByFileRecordingGroupId {
              nodes {
                ...FileRecordingGroupMetadatumFields
                metadatum: metadatumByMetadataId {
                  ...MetadatumFields
                  values: metadataValuesByMetadataId(
                    filter: { dateCreated: { lessThanOrEqualTo: $cutoffTime } }
                    orderBy: DATE_CREATED_ASC
                    last: 1
                  ) {
                    nodes {
                      ...MetadataValueFields
                    }
                  }
                }
              }
            }
          }
        }
      }
      metadata: datasetMetadataByDatasetId {
        nodes {
          id
          metadatum: metadatumByMetadataId {
            ...MetadatumFields
            values: metadataValuesByMetadataId(orderBy: DATE_CREATED_DESC) {
              nodes {
                ...MetadataValueFields
              }
            }
          }
        }
      }
      project: projectByProjectId {
        ...ProjectFields
      }
    }
  }
  ${DatasetFieldsFragmentDoc}
  ${MetadatumFieldsFragmentDoc}
  ${MetadataValueFieldsFragmentDoc}
  ${DatasetVersionFieldsFragmentDoc}
  ${DatasetRecordingsTableFieldsFragmentDoc}
  ${DatasetRecordingsTableColumnFieldsFragmentDoc}
  ${FileRecordingGroupFieldsFragmentDoc}
  ${RecordingGroupFileFieldsFragmentDoc}
  ${FileRecordingGroupMetadatumFieldsFragmentDoc}
  ${ProjectFieldsFragmentDoc}
`;

/**
 * __usePageDatasetQuery__
 *
 * To run a query within a React component, call `usePageDatasetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageDatasetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageDatasetQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      cutoffTime: // value for 'cutoffTime'
 *   },
 * });
 */
export function usePageDatasetQuery(
  baseOptions: Apollo.QueryHookOptions<
    PageDatasetQuery,
    PageDatasetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PageDatasetQuery, PageDatasetQueryVariables>(
    PageDatasetDocument,
    options,
  );
}
export function usePageDatasetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PageDatasetQuery,
    PageDatasetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PageDatasetQuery, PageDatasetQueryVariables>(
    PageDatasetDocument,
    options,
  );
}
export type PageDatasetQueryHookResult = ReturnType<typeof usePageDatasetQuery>;
export type PageDatasetLazyQueryHookResult = ReturnType<
  typeof usePageDatasetLazyQuery
>;
export type PageDatasetQueryResult = Apollo.QueryResult<
  PageDatasetQuery,
  PageDatasetQueryVariables
>;
export const TasksTableDocument = gql`
  query TasksTable(
    $condition: AnalysisTableRowCondition
    $filter: AnalysisTableRowFilter
  ) {
    analysisTableRows: allAnalysisTableRows(
      condition: $condition
      filter: $filter
    ) {
      nodes {
        id
        data
        deleted
        table: analysisTableByTableId {
          id
          name
          identifiers
          group: analysisTableGroupByGroupId {
            id
            name
          }
          toolVersion: toolVersionByToolVersionId {
            id
            tool: toolByToolId {
              id
              key
            }
            toolSpec
          }
        }
        task: taskByTaskId {
          id
          credits
          cloned
          status
          created
          taskActivityByTaskId {
            id
            duration {
              hours
              seconds
              minutes
              days
              years
              months
            }
          }
          user: applicationUserByUserId {
            id
            firstName
            lastName
          }
          outputGroup: fileOutputGroupByTaskId {
            id
            outputGroupFiles: outputGroupFilesByFileOutputGroupId {
              nodes {
                id
                drsFile: fileByFileId {
                  id
                  name
                  isSeries
                  status
                  key
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTasksTableQuery__
 *
 * To run a query within a React component, call `useTasksTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksTableQuery({
 *   variables: {
 *      condition: // value for 'condition'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTasksTableQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TasksTableQuery,
    TasksTableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TasksTableQuery, TasksTableQueryVariables>(
    TasksTableDocument,
    options,
  );
}
export function useTasksTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TasksTableQuery,
    TasksTableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TasksTableQuery, TasksTableQueryVariables>(
    TasksTableDocument,
    options,
  );
}
export type TasksTableQueryHookResult = ReturnType<typeof useTasksTableQuery>;
export type TasksTableLazyQueryHookResult = ReturnType<
  typeof useTasksTableLazyQuery
>;
export type TasksTableQueryResult = Apollo.QueryResult<
  TasksTableQuery,
  TasksTableQueryVariables
>;
export const TenantProviderDocument = gql`
  query TenantProvider($tenantKey: String!) {
    tenant: tenantByKey(key: $tenantKey) {
      id
      name
      key
      memberships: applicationUserMembershipsByTenantId {
        nodes {
          id
          role
          user: applicationUserByUserId {
            id
            firstName
            lastName
            email
            username
            internal
            isActive
            avatar: avatarByUserId {
              id
              color
              initials
              useImage
              imageBase64
            }
            metadata: applicationUserMetadataByUserId {
              nodes {
                id
                key
                value
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTenantProviderQuery__
 *
 * To run a query within a React component, call `useTenantProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantProviderQuery({
 *   variables: {
 *      tenantKey: // value for 'tenantKey'
 *   },
 * });
 */
export function useTenantProviderQuery(
  baseOptions: Apollo.QueryHookOptions<
    TenantProviderQuery,
    TenantProviderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TenantProviderQuery, TenantProviderQueryVariables>(
    TenantProviderDocument,
    options,
  );
}
export function useTenantProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TenantProviderQuery,
    TenantProviderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TenantProviderQuery, TenantProviderQueryVariables>(
    TenantProviderDocument,
    options,
  );
}
export type TenantProviderQueryHookResult = ReturnType<
  typeof useTenantProviderQuery
>;
export type TenantProviderLazyQueryHookResult = ReturnType<
  typeof useTenantProviderLazyQuery
>;
export type TenantProviderQueryResult = Apollo.QueryResult<
  TenantProviderQuery,
  TenantProviderQueryVariables
>;
export const ToolSpecProviderDocument = gql`
  query ToolSpecProvider($tenantId: Int!) {
    tools: allTools(
      filter: {
        toolTenantsByToolId: { some: { tenantId: { equalTo: $tenantId } } }
      }
    ) {
      nodes {
        id
        key
        name
        created
        toolVersions: toolVersionsByToolId(orderBy: VERSION_ASC, last: 1) {
          nodes {
            id
            version
            maturity
            toolSpec
            toolId
            credits
            created
          }
        }
      }
    }
  }
`;

/**
 * __useToolSpecProviderQuery__
 *
 * To run a query within a React component, call `useToolSpecProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useToolSpecProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useToolSpecProviderQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useToolSpecProviderQuery(
  baseOptions: Apollo.QueryHookOptions<
    ToolSpecProviderQuery,
    ToolSpecProviderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ToolSpecProviderQuery, ToolSpecProviderQueryVariables>(
    ToolSpecProviderDocument,
    options,
  );
}
export function useToolSpecProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ToolSpecProviderQuery,
    ToolSpecProviderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ToolSpecProviderQuery,
    ToolSpecProviderQueryVariables
  >(ToolSpecProviderDocument, options);
}
export type ToolSpecProviderQueryHookResult = ReturnType<
  typeof useToolSpecProviderQuery
>;
export type ToolSpecProviderLazyQueryHookResult = ReturnType<
  typeof useToolSpecProviderLazyQuery
>;
export type ToolSpecProviderQueryResult = Apollo.QueryResult<
  ToolSpecProviderQuery,
  ToolSpecProviderQueryVariables
>;
export const UserProviderDocument = gql`
  query UserProvider {
    currentUser: getCurrentUser {
      id
      firstName
      lastName
      email
      username
      internal
      isActive
      metadata: applicationUserMetadataByUserId {
        nodes {
          id
          key
          value
        }
      }
      eula: eulaVersionUserAcceptancesByUserId {
        nodes {
          id
          accepted
        }
      }
      ...UserAvatarFields
      memberships: applicationUserMembershipsByUserId {
        nodes {
          id
          role
          tenant: tenantByTenantId {
            id
            key
            name
          }
        }
      }
    }
  }
  ${UserAvatarFieldsFragmentDoc}
`;

/**
 * __useUserProviderQuery__
 *
 * To run a query within a React component, call `useUserProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProviderQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProviderQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserProviderQuery,
    UserProviderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserProviderQuery, UserProviderQueryVariables>(
    UserProviderDocument,
    options,
  );
}
export function useUserProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserProviderQuery,
    UserProviderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserProviderQuery, UserProviderQueryVariables>(
    UserProviderDocument,
    options,
  );
}
export type UserProviderQueryHookResult = ReturnType<
  typeof useUserProviderQuery
>;
export type UserProviderLazyQueryHookResult = ReturnType<
  typeof useUserProviderLazyQuery
>;
export type UserProviderQueryResult = Apollo.QueryResult<
  UserProviderQuery,
  UserProviderQueryVariables
>;
export const ProjectFilesManagerDocument = gql`
  query ProjectFilesManager(
    $projectId: UUID!
    $cutoffTime: Datetime
    $metadataKeys: [String!]!
  ) {
    project: projectById(id: $projectId) {
      id
      datasets: datasetsByProjectId {
        nodes {
          id
          prefix
          name
          recordingsTable: datasetRecordingsTableByRecordingsTableId {
            id
            columns: activeColumns(at: $cutoffTime) {
              nodes {
                id
                colDef: activeColDef(at: $cutoffTime)
              }
            }
            recordings: fileRecordingGroupsByDatasetRecordingsTableId {
              nodes {
                id
                number
              }
            }
          }
        }
      }
      activeFiles(at: $cutoffTime) {
        nodes {
          id
          key
          datasetId
          dateCreated
          name
          status
          isSeries
          uploadStatus
          projectId
          seriesParentId
          fileType
          fileStructure
          fileFormat
          size
          source
          processingStatus
          originalName
          assignment: activeAssignment(at: $cutoffTime) {
            id
            recording: fileRecordingGroupByFileRecordingGroupId {
              id
              number
            }
            column: datasetRecordingsTableColumnByDatasetRecordingsTableColumnId {
              id
              colDef: activeColDef(at: $cutoffTime)
            }
          }
          dataset: datasetByDatasetId {
            id
            prefix
            name
          }
          user: applicationUserByUserId {
            id
            firstName
            lastName
          }
          outputGroupFiles: outputGroupFilesByFileId {
            nodes {
              id
              outputGroup: fileOutputGroupByFileOutputGroupId {
                id
                task: taskByTaskId {
                  id
                  analysisTableRow: analysisTableRowByTaskId {
                    id
                    analysisTableId: tableId
                    attachments
                    activeAttachResults(at: $cutoffTime)
                  }
                }
              }
            }
          }
          fileMetadata: fileMetadataByFileId(
            filter: { metadatumByMetadataId: { key: { in: $metadataKeys } } }
          ) {
            nodes {
              id
              metadata: metadatumByMetadataId {
                id
                key
                activeValue(at: $cutoffTime)
              }
            }
          }
          seriesFiles: filesBySeriesParentId {
            nodes {
              id
              key
              datasetId
              dateCreated
              name
              status
              isSeries
              uploadStatus
              projectId
              seriesParentId
              fileStructure
              fileFormat
              fileType
              size
              source
              processingStatus
              originalName
              fileMetadata: fileMetadataByFileId(
                filter: {
                  metadatumByMetadataId: { key: { in: $metadataKeys } }
                }
              ) {
                nodes {
                  id
                  metadata: metadatumByMetadataId {
                    id
                    key
                    activeValue(at: $cutoffTime)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useProjectFilesManagerQuery__
 *
 * To run a query within a React component, call `useProjectFilesManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectFilesManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectFilesManagerQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      cutoffTime: // value for 'cutoffTime'
 *      metadataKeys: // value for 'metadataKeys'
 *   },
 * });
 */
export function useProjectFilesManagerQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectFilesManagerQuery,
    ProjectFilesManagerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectFilesManagerQuery,
    ProjectFilesManagerQueryVariables
  >(ProjectFilesManagerDocument, options);
}
export function useProjectFilesManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectFilesManagerQuery,
    ProjectFilesManagerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectFilesManagerQuery,
    ProjectFilesManagerQueryVariables
  >(ProjectFilesManagerDocument, options);
}
export type ProjectFilesManagerQueryHookResult = ReturnType<
  typeof useProjectFilesManagerQuery
>;
export type ProjectFilesManagerLazyQueryHookResult = ReturnType<
  typeof useProjectFilesManagerLazyQuery
>;
export type ProjectFilesManagerQueryResult = Apollo.QueryResult<
  ProjectFilesManagerQuery,
  ProjectFilesManagerQueryVariables
>;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {},
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AnalysisTable: ResolverTypeWrapper<AnalysisTable>;
  AnalysisTableAnalysisTablePkeyConnect: AnalysisTableAnalysisTablePkeyConnect;
  AnalysisTableAnalysisTablePkeyDelete: AnalysisTableAnalysisTablePkeyDelete;
  AnalysisTableCondition: AnalysisTableCondition;
  AnalysisTableFilter: AnalysisTableFilter;
  AnalysisTableGroup: ResolverTypeWrapper<AnalysisTableGroup>;
  AnalysisTableGroupAnalysisTableGroupPkeyConnect: AnalysisTableGroupAnalysisTableGroupPkeyConnect;
  AnalysisTableGroupAnalysisTableGroupPkeyDelete: AnalysisTableGroupAnalysisTableGroupPkeyDelete;
  AnalysisTableGroupCondition: AnalysisTableGroupCondition;
  AnalysisTableGroupFilter: AnalysisTableGroupFilter;
  AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdAnalysisTableCreateInput: AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdAnalysisTableCreateInput;
  AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdAnalysisTableGroupCreateInput: AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdAnalysisTableGroupCreateInput;
  AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput: AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput;
  AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInverseInput: AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInverseInput;
  AnalysisTableGroupInput: AnalysisTableGroupInput;
  AnalysisTableGroupOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdUsingAnalysisTableGroupPkeyUpdate: AnalysisTableGroupOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdUsingAnalysisTableGroupPkeyUpdate;
  AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdUsingAnalysisTableGroupPkeyUpdate: AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdUsingAnalysisTableGroupPkeyUpdate;
  AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingAnalysisTableGroupPkeyUpdate: AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingAnalysisTableGroupPkeyUpdate;
  AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdUsingAnalysisTableGroupPkeyUpdate: AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdUsingAnalysisTableGroupPkeyUpdate;
  AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingAnalysisTableGroupPkeyUpdate: AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingAnalysisTableGroupPkeyUpdate;
  AnalysisTableGroupPatch: AnalysisTableGroupPatch;
  AnalysisTableGroupProjectId870Aec99FkProjectIdAnalysisTableGroupCreateInput: AnalysisTableGroupProjectId870Aec99FkProjectIdAnalysisTableGroupCreateInput;
  AnalysisTableGroupProjectId870Aec99FkProjectIdInput: AnalysisTableGroupProjectId870Aec99FkProjectIdInput;
  AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput: AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput;
  AnalysisTableGroupTenantId3786Ab7AFkTenantIdAnalysisTableGroupCreateInput: AnalysisTableGroupTenantId3786Ab7AFkTenantIdAnalysisTableGroupCreateInput;
  AnalysisTableGroupTenantId3786Ab7AFkTenantIdInput: AnalysisTableGroupTenantId3786Ab7AFkTenantIdInput;
  AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput: AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput;
  AnalysisTableGroupTenantId3786Ab7AFkTenantIdTenantCreateInput: AnalysisTableGroupTenantId3786Ab7AFkTenantIdTenantCreateInput;
  AnalysisTableGroupToManyAnalysisTableFilter: AnalysisTableGroupToManyAnalysisTableFilter;
  AnalysisTableGroupToolIdB122F1CcFkToolIdAnalysisTableGroupCreateInput: AnalysisTableGroupToolIdB122F1CcFkToolIdAnalysisTableGroupCreateInput;
  AnalysisTableGroupToolIdB122F1CcFkToolIdInput: AnalysisTableGroupToolIdB122F1CcFkToolIdInput;
  AnalysisTableGroupToolIdB122F1CcFkToolIdInverseInput: AnalysisTableGroupToolIdB122F1CcFkToolIdInverseInput;
  AnalysisTableGroupToolIdB122F1CcFkToolIdToolCreateInput: AnalysisTableGroupToolIdB122F1CcFkToolIdToolCreateInput;
  AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdAnalysisTableGroupCreateInput: AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdAnalysisTableGroupCreateInput;
  AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdApplicationUserCreateInput: AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdApplicationUserCreateInput;
  AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInput: AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInput;
  AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput: AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput;
  AnalysisTableGroupsConnection: ResolverTypeWrapper<AnalysisTableGroupsConnection>;
  AnalysisTableGroupsEdge: ResolverTypeWrapper<AnalysisTableGroupsEdge>;
  AnalysisTableGroupsOrderBy: AnalysisTableGroupsOrderBy;
  AnalysisTableInput: AnalysisTableInput;
  AnalysisTableOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdUsingAnalysisTablePkeyUpdate: AnalysisTableOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdUsingAnalysisTablePkeyUpdate;
  AnalysisTableOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdUsingAnalysisTablePkeyUpdate: AnalysisTableOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdUsingAnalysisTablePkeyUpdate;
  AnalysisTableOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingAnalysisTablePkeyUpdate: AnalysisTableOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingAnalysisTablePkeyUpdate;
  AnalysisTableOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdUsingAnalysisTablePkeyUpdate: AnalysisTableOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdUsingAnalysisTablePkeyUpdate;
  AnalysisTableOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingAnalysisTablePkeyUpdate: AnalysisTableOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingAnalysisTablePkeyUpdate;
  AnalysisTableOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdUsingAnalysisTablePkeyUpdate: AnalysisTableOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdUsingAnalysisTablePkeyUpdate;
  AnalysisTablePatch: AnalysisTablePatch;
  AnalysisTableProjectId709Aebc7FkProjectIdAnalysisTableCreateInput: AnalysisTableProjectId709Aebc7FkProjectIdAnalysisTableCreateInput;
  AnalysisTableProjectId709Aebc7FkProjectIdInput: AnalysisTableProjectId709Aebc7FkProjectIdInput;
  AnalysisTableProjectId709Aebc7FkProjectIdInverseInput: AnalysisTableProjectId709Aebc7FkProjectIdInverseInput;
  AnalysisTableRow: ResolverTypeWrapper<AnalysisTableRow>;
  AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisAnalysisTableRowAttachResultsCreateInput: AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisAnalysisTableRowAttachResultsCreateInput;
  AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisAnalysisTableRowCreateInput: AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisAnalysisTableRowCreateInput;
  AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInput: AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInput;
  AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput: AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput;
  AnalysisTableRowAProjectIdE3E030E2FkProjectIAnalysisTableRowAttachResultsCreateInput: AnalysisTableRowAProjectIdE3E030E2FkProjectIAnalysisTableRowAttachResultsCreateInput;
  AnalysisTableRowAProjectIdE3E030E2FkProjectIInput: AnalysisTableRowAProjectIdE3E030E2FkProjectIInput;
  AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput: AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput;
  AnalysisTableRowAnalysisTableRowPkeyConnect: AnalysisTableRowAnalysisTableRowPkeyConnect;
  AnalysisTableRowAnalysisTableRowPkeyDelete: AnalysisTableRowAnalysisTableRowPkeyDelete;
  AnalysisTableRowAnalysisTableRowTaskIdKeyConnect: AnalysisTableRowAnalysisTableRowTaskIdKeyConnect;
  AnalysisTableRowAnalysisTableRowTaskIdKeyDelete: AnalysisTableRowAnalysisTableRowTaskIdKeyDelete;
  AnalysisTableRowAttachResult: ResolverTypeWrapper<AnalysisTableRowAttachResult>;
  AnalysisTableRowAttachResultAnalysisTableRowAttachResultsPkeyConnect: AnalysisTableRowAttachResultAnalysisTableRowAttachResultsPkeyConnect;
  AnalysisTableRowAttachResultAnalysisTableRowAttachResultsPkeyDelete: AnalysisTableRowAttachResultAnalysisTableRowAttachResultsPkeyDelete;
  AnalysisTableRowAttachResultCondition: AnalysisTableRowAttachResultCondition;
  AnalysisTableRowAttachResultFilter: AnalysisTableRowAttachResultFilter;
  AnalysisTableRowAttachResultInput: AnalysisTableRowAttachResultInput;
  AnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisUsingAnalysisTableRowAttachResultsPkeyUpdate: AnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisUsingAnalysisTableRowAttachResultsPkeyUpdate;
  AnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIUsingAnalysisTableRowAttachResultsPkeyUpdate: AnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIUsingAnalysisTableRowAttachResultsPkeyUpdate;
  AnalysisTableRowAttachResultPatch: AnalysisTableRowAttachResultPatch;
  AnalysisTableRowAttachResultsConnection: ResolverTypeWrapper<AnalysisTableRowAttachResultsConnection>;
  AnalysisTableRowAttachResultsEdge: ResolverTypeWrapper<AnalysisTableRowAttachResultsEdge>;
  AnalysisTableRowAttachResultsOrderBy: AnalysisTableRowAttachResultsOrderBy;
  AnalysisTableRowCondition: AnalysisTableRowCondition;
  AnalysisTableRowFilter: AnalysisTableRowFilter;
  AnalysisTableRowInput: AnalysisTableRowInput;
  AnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisUsingAnalysisTableRowPkeyUpdate: AnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisUsingAnalysisTableRowPkeyUpdate;
  AnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisUsingAnalysisTableRowTaskIdKeyUpdate: AnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisUsingAnalysisTableRowTaskIdKeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingAnalysisTableRowPkeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingAnalysisTableRowPkeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingAnalysisTableRowTaskIdKeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingAnalysisTableRowTaskIdKeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdUsingAnalysisTableRowPkeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdUsingAnalysisTableRowPkeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdUsingAnalysisTableRowTaskIdKeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdUsingAnalysisTableRowTaskIdKeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingAnalysisTableRowPkeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingAnalysisTableRowPkeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingAnalysisTableRowTaskIdKeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingAnalysisTableRowTaskIdKeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingAnalysisTableRowPkeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingAnalysisTableRowPkeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingAnalysisTableRowTaskIdKeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingAnalysisTableRowTaskIdKeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingAnalysisTableRowPkeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingAnalysisTableRowPkeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingAnalysisTableRowTaskIdKeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingAnalysisTableRowTaskIdKeyUpdate;
  AnalysisTableRowPatch: AnalysisTableRowPatch;
  AnalysisTableRowProjectIdCd370B28FkProjectIdAnalysisTableRowCreateInput: AnalysisTableRowProjectIdCd370B28FkProjectIdAnalysisTableRowCreateInput;
  AnalysisTableRowProjectIdCd370B28FkProjectIdInput: AnalysisTableRowProjectIdCd370B28FkProjectIdInput;
  AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput: AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput;
  AnalysisTableRowTableIdB1C24185FkAnalysisTableIdAnalysisTableCreateInput: AnalysisTableRowTableIdB1C24185FkAnalysisTableIdAnalysisTableCreateInput;
  AnalysisTableRowTableIdB1C24185FkAnalysisTableIdAnalysisTableRowCreateInput: AnalysisTableRowTableIdB1C24185FkAnalysisTableIdAnalysisTableRowCreateInput;
  AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput: AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput;
  AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput: AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput;
  AnalysisTableRowTaskId742Ff0EdFkTaskIdAnalysisTableRowCreateInput: AnalysisTableRowTaskId742Ff0EdFkTaskIdAnalysisTableRowCreateInput;
  AnalysisTableRowTaskId742Ff0EdFkTaskIdInput: AnalysisTableRowTaskId742Ff0EdFkTaskIdInput;
  AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput: AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput;
  AnalysisTableRowTaskId742Ff0EdFkTaskIdTaskCreateInput: AnalysisTableRowTaskId742Ff0EdFkTaskIdTaskCreateInput;
  AnalysisTableRowTenantIdDb778B87FkTenantIdAnalysisTableRowCreateInput: AnalysisTableRowTenantIdDb778B87FkTenantIdAnalysisTableRowCreateInput;
  AnalysisTableRowTenantIdDb778B87FkTenantIdInput: AnalysisTableRowTenantIdDb778B87FkTenantIdInput;
  AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput: AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput;
  AnalysisTableRowTenantIdDb778B87FkTenantIdTenantCreateInput: AnalysisTableRowTenantIdDb778B87FkTenantIdTenantCreateInput;
  AnalysisTableRowToManyAnalysisTableRowAttachResultFilter: AnalysisTableRowToManyAnalysisTableRowAttachResultFilter;
  AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdAnalysisTableRowCreateInput: AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdAnalysisTableRowCreateInput;
  AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput: AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput;
  AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput: AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput;
  AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdToolVersionCreateInput: AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdToolVersionCreateInput;
  AnalysisTableRowsConnection: ResolverTypeWrapper<AnalysisTableRowsConnection>;
  AnalysisTableRowsEdge: ResolverTypeWrapper<AnalysisTableRowsEdge>;
  AnalysisTableRowsOrderBy: AnalysisTableRowsOrderBy;
  AnalysisTableTenantIdF297C671FkTenantIdAnalysisTableCreateInput: AnalysisTableTenantIdF297C671FkTenantIdAnalysisTableCreateInput;
  AnalysisTableTenantIdF297C671FkTenantIdInput: AnalysisTableTenantIdF297C671FkTenantIdInput;
  AnalysisTableTenantIdF297C671FkTenantIdInverseInput: AnalysisTableTenantIdF297C671FkTenantIdInverseInput;
  AnalysisTableTenantIdF297C671FkTenantIdTenantCreateInput: AnalysisTableTenantIdF297C671FkTenantIdTenantCreateInput;
  AnalysisTableToManyAnalysisTableRowFilter: AnalysisTableToManyAnalysisTableRowFilter;
  AnalysisTableToolVersionId8E4A2317FkToolVersionIdAnalysisTableCreateInput: AnalysisTableToolVersionId8E4A2317FkToolVersionIdAnalysisTableCreateInput;
  AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput: AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput;
  AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput: AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput;
  AnalysisTableToolVersionId8E4A2317FkToolVersionIdToolVersionCreateInput: AnalysisTableToolVersionId8E4A2317FkToolVersionIdToolVersionCreateInput;
  AnalysisTableUserIdD5D6De41FkApplicationUserIdAnalysisTableCreateInput: AnalysisTableUserIdD5D6De41FkApplicationUserIdAnalysisTableCreateInput;
  AnalysisTableUserIdD5D6De41FkApplicationUserIdApplicationUserCreateInput: AnalysisTableUserIdD5D6De41FkApplicationUserIdApplicationUserCreateInput;
  AnalysisTableUserIdD5D6De41FkApplicationUserIdInput: AnalysisTableUserIdD5D6De41FkApplicationUserIdInput;
  AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput: AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput;
  AnalysisTablesConnection: ResolverTypeWrapper<AnalysisTablesConnection>;
  AnalysisTablesEdge: ResolverTypeWrapper<AnalysisTablesEdge>;
  AnalysisTablesOrderBy: AnalysisTablesOrderBy;
  ApplicationUser: ResolverTypeWrapper<ApplicationUser>;
  ApplicationUserApplicationUserEmailKeyConnect: ApplicationUserApplicationUserEmailKeyConnect;
  ApplicationUserApplicationUserEmailKeyDelete: ApplicationUserApplicationUserEmailKeyDelete;
  ApplicationUserApplicationUserPkeyConnect: ApplicationUserApplicationUserPkeyConnect;
  ApplicationUserApplicationUserPkeyDelete: ApplicationUserApplicationUserPkeyDelete;
  ApplicationUserApplicationUserUsernameKeyConnect: ApplicationUserApplicationUserUsernameKeyConnect;
  ApplicationUserApplicationUserUsernameKeyDelete: ApplicationUserApplicationUserUsernameKeyDelete;
  ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput: ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput;
  ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInverseInput: ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInverseInput;
  ApplicationUserFilter: ApplicationUserFilter;
  ApplicationUserGroup: ResolverTypeWrapper<ApplicationUserGroup>;
  ApplicationUserGroupApplicationUserGroupsPkeyConnect: ApplicationUserGroupApplicationUserGroupsPkeyConnect;
  ApplicationUserGroupApplicationUserGroupsPkeyDelete: ApplicationUserGroupApplicationUserGroupsPkeyDelete;
  ApplicationUserGroupApplicationUserGroupsUserIdGroupId3A20Ace4UniqConnect: ApplicationUserGroupApplicationUserGroupsUserIdGroupId3A20Ace4UniqConnect;
  ApplicationUserGroupApplicationUserGroupsUserIdGroupId3A20Ace4UniqDelete: ApplicationUserGroupApplicationUserGroupsUserIdGroupId3A20Ace4UniqDelete;
  ApplicationUserGroupCondition: ApplicationUserGroupCondition;
  ApplicationUserGroupFilter: ApplicationUserGroupFilter;
  ApplicationUserGroupInput: ApplicationUserGroupInput;
  ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingApplicationUserGroupsPkeyUpdate: ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingApplicationUserGroupsPkeyUpdate;
  ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingApplicationUserGroupsUserIdGroupId3A20Ace4UniqUpdate: ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingApplicationUserGroupsUserIdGroupId3A20Ace4UniqUpdate;
  ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserGroupsPkeyUpdate: ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserGroupsPkeyUpdate;
  ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserGroupsUserIdGroupId3A20Ace4UniqUpdate: ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserGroupsUserIdGroupId3A20Ace4UniqUpdate;
  ApplicationUserGroupPatch: ApplicationUserGroupPatch;
  ApplicationUserGroupsConnection: ResolverTypeWrapper<ApplicationUserGroupsConnection>;
  ApplicationUserGroupsEdge: ResolverTypeWrapper<ApplicationUserGroupsEdge>;
  ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdApplicationUserGroupsCreateInput: ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdApplicationUserGroupsCreateInput;
  ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdAuthGroupCreateInput: ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdAuthGroupCreateInput;
  ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInput: ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInput;
  ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInverseInput: ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInverseInput;
  ApplicationUserGroupsOrderBy: ApplicationUserGroupsOrderBy;
  ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdApplicationUserCreateInput: ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdApplicationUserCreateInput;
  ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdApplicationUserGroupsCreateInput: ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdApplicationUserGroupsCreateInput;
  ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInput: ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInput;
  ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput: ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput;
  ApplicationUserInput: ApplicationUserInput;
  ApplicationUserMemUserId631Fddc5FkApplicatiApplicationUserCreateInput: ApplicationUserMemUserId631Fddc5FkApplicatiApplicationUserCreateInput;
  ApplicationUserMemUserId631Fddc5FkApplicatiApplicationUserMembershipCreateInput: ApplicationUserMemUserId631Fddc5FkApplicatiApplicationUserMembershipCreateInput;
  ApplicationUserMemUserId631Fddc5FkApplicatiInput: ApplicationUserMemUserId631Fddc5FkApplicatiInput;
  ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput: ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput;
  ApplicationUserMembership: ResolverTypeWrapper<ApplicationUserMembership>;
  ApplicationUserMembershipApplicationUserMembershipPkeyConnect: ApplicationUserMembershipApplicationUserMembershipPkeyConnect;
  ApplicationUserMembershipApplicationUserMembershipPkeyDelete: ApplicationUserMembershipApplicationUserMembershipPkeyDelete;
  ApplicationUserMembershipCondition: ApplicationUserMembershipCondition;
  ApplicationUserMembershipFilter: ApplicationUserMembershipFilter;
  ApplicationUserMembershipInput: ApplicationUserMembershipInput;
  ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserMembershipPkeyUpdate: ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserMembershipPkeyUpdate;
  ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingUserTenantUniqueUpdate: ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingUserTenantUniqueUpdate;
  ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingApplicationUserMembershipPkeyUpdate: ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingApplicationUserMembershipPkeyUpdate;
  ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingUserTenantUniqueUpdate: ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingUserTenantUniqueUpdate;
  ApplicationUserMembershipPatch: ApplicationUserMembershipPatch;
  ApplicationUserMembershipTenantId5E54Da7AFkTenantIdApplicationUserMembershipCreateInput: ApplicationUserMembershipTenantId5E54Da7AFkTenantIdApplicationUserMembershipCreateInput;
  ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInput: ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInput;
  ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput: ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput;
  ApplicationUserMembershipTenantId5E54Da7AFkTenantIdTenantCreateInput: ApplicationUserMembershipTenantId5E54Da7AFkTenantIdTenantCreateInput;
  ApplicationUserMembershipUserTenantUniqueConnect: ApplicationUserMembershipUserTenantUniqueConnect;
  ApplicationUserMembershipUserTenantUniqueDelete: ApplicationUserMembershipUserTenantUniqueDelete;
  ApplicationUserMembershipsConnection: ResolverTypeWrapper<ApplicationUserMembershipsConnection>;
  ApplicationUserMembershipsEdge: ResolverTypeWrapper<ApplicationUserMembershipsEdge>;
  ApplicationUserMembershipsOrderBy: ApplicationUserMembershipsOrderBy;
  ApplicationUserMetUserId897Fbde1FkApplicatiApplicationUserCreateInput: ApplicationUserMetUserId897Fbde1FkApplicatiApplicationUserCreateInput;
  ApplicationUserMetUserId897Fbde1FkApplicatiApplicationUserMetadataCreateInput: ApplicationUserMetUserId897Fbde1FkApplicatiApplicationUserMetadataCreateInput;
  ApplicationUserMetUserId897Fbde1FkApplicatiInput: ApplicationUserMetUserId897Fbde1FkApplicatiInput;
  ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput: ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput;
  ApplicationUserMetadataConnection: ResolverTypeWrapper<ApplicationUserMetadataConnection>;
  ApplicationUserMetadataEdge: ResolverTypeWrapper<ApplicationUserMetadataEdge>;
  ApplicationUserMetadataOrderBy: ApplicationUserMetadataOrderBy;
  ApplicationUserMetadatum: ResolverTypeWrapper<ApplicationUserMetadatum>;
  ApplicationUserMetadatumApplicationUserMetadataPkeyConnect: ApplicationUserMetadatumApplicationUserMetadataPkeyConnect;
  ApplicationUserMetadatumApplicationUserMetadataPkeyDelete: ApplicationUserMetadatumApplicationUserMetadataPkeyDelete;
  ApplicationUserMetadatumCondition: ApplicationUserMetadatumCondition;
  ApplicationUserMetadatumFilter: ApplicationUserMetadatumFilter;
  ApplicationUserMetadatumInput: ApplicationUserMetadatumInput;
  ApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserMetadataPkeyUpdate: ApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserMetadataPkeyUpdate;
  ApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingUserKeyUpdate: ApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingUserKeyUpdate;
  ApplicationUserMetadatumPatch: ApplicationUserMetadatumPatch;
  ApplicationUserMetadatumUserKeyConnect: ApplicationUserMetadatumUserKeyConnect;
  ApplicationUserMetadatumUserKeyDelete: ApplicationUserMetadatumUserKeyDelete;
  ApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserPatch: ApplicationUserPatch;
  ApplicationUserSesUserId2Fb30D0CFkApplicatiApplicationUserCreateInput: ApplicationUserSesUserId2Fb30D0CFkApplicatiApplicationUserCreateInput;
  ApplicationUserSesUserId2Fb30D0CFkApplicatiApplicationUserSessionCreateInput: ApplicationUserSesUserId2Fb30D0CFkApplicatiApplicationUserSessionCreateInput;
  ApplicationUserSesUserId2Fb30D0CFkApplicatiInput: ApplicationUserSesUserId2Fb30D0CFkApplicatiInput;
  ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput: ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput;
  ApplicationUserSession: ResolverTypeWrapper<ApplicationUserSession>;
  ApplicationUserSessionApplicationUserSessionPkeyConnect: ApplicationUserSessionApplicationUserSessionPkeyConnect;
  ApplicationUserSessionApplicationUserSessionPkeyDelete: ApplicationUserSessionApplicationUserSessionPkeyDelete;
  ApplicationUserSessionCondition: ApplicationUserSessionCondition;
  ApplicationUserSessionFilter: ApplicationUserSessionFilter;
  ApplicationUserSessionInput: ApplicationUserSessionInput;
  ApplicationUserSessionOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserSessionPkeyUpdate: ApplicationUserSessionOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserSessionPkeyUpdate;
  ApplicationUserSessionOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingApplicationUserSessionPkeyUpdate: ApplicationUserSessionOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingApplicationUserSessionPkeyUpdate;
  ApplicationUserSessionPatch: ApplicationUserSessionPatch;
  ApplicationUserSessionTenantId00A81Cc5FkTenantIdApplicationUserSessionCreateInput: ApplicationUserSessionTenantId00A81Cc5FkTenantIdApplicationUserSessionCreateInput;
  ApplicationUserSessionTenantId00A81Cc5FkTenantIdInput: ApplicationUserSessionTenantId00A81Cc5FkTenantIdInput;
  ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput: ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput;
  ApplicationUserSessionTenantId00A81Cc5FkTenantIdTenantCreateInput: ApplicationUserSessionTenantId00A81Cc5FkTenantIdTenantCreateInput;
  ApplicationUserSessionsConnection: ResolverTypeWrapper<ApplicationUserSessionsConnection>;
  ApplicationUserSessionsEdge: ResolverTypeWrapper<ApplicationUserSessionsEdge>;
  ApplicationUserSessionsOrderBy: ApplicationUserSessionsOrderBy;
  ApplicationUserToManyAnalysisTableFilter: ApplicationUserToManyAnalysisTableFilter;
  ApplicationUserToManyAnalysisTableGroupFilter: ApplicationUserToManyAnalysisTableGroupFilter;
  ApplicationUserToManyApplicationUserGroupFilter: ApplicationUserToManyApplicationUserGroupFilter;
  ApplicationUserToManyApplicationUserMembershipFilter: ApplicationUserToManyApplicationUserMembershipFilter;
  ApplicationUserToManyApplicationUserMetadatumFilter: ApplicationUserToManyApplicationUserMetadatumFilter;
  ApplicationUserToManyApplicationUserSessionFilter: ApplicationUserToManyApplicationUserSessionFilter;
  ApplicationUserToManyApplicationUserUserPermissionFilter: ApplicationUserToManyApplicationUserUserPermissionFilter;
  ApplicationUserToManyDatasetFilter: ApplicationUserToManyDatasetFilter;
  ApplicationUserToManyDjangoAdminLogFilter: ApplicationUserToManyDjangoAdminLogFilter;
  ApplicationUserToManyEulaVersionUserAcceptanceFilter: ApplicationUserToManyEulaVersionUserAcceptanceFilter;
  ApplicationUserToManyExplorerQueryFilter: ApplicationUserToManyExplorerQueryFilter;
  ApplicationUserToManyExplorerQueryfavoriteFilter: ApplicationUserToManyExplorerQueryfavoriteFilter;
  ApplicationUserToManyExplorerQuerylogFilter: ApplicationUserToManyExplorerQuerylogFilter;
  ApplicationUserToManyFileActivityFilter: ApplicationUserToManyFileActivityFilter;
  ApplicationUserToManyFileFilter: ApplicationUserToManyFileFilter;
  ApplicationUserToManyFileOutputGroupFilter: ApplicationUserToManyFileOutputGroupFilter;
  ApplicationUserToManyFileProcessingNotificationFilter: ApplicationUserToManyFileProcessingNotificationFilter;
  ApplicationUserToManyFileRecordingGroupFilter: ApplicationUserToManyFileRecordingGroupFilter;
  ApplicationUserToManyMetadataValueFilter: ApplicationUserToManyMetadataValueFilter;
  ApplicationUserToManyNotificationFilter: ApplicationUserToManyNotificationFilter;
  ApplicationUserToManyProjectClonedNotificationFilter: ApplicationUserToManyProjectClonedNotificationFilter;
  ApplicationUserToManyProjectFilter: ApplicationUserToManyProjectFilter;
  ApplicationUserToManyProjectSharedNotificationFilter: ApplicationUserToManyProjectSharedNotificationFilter;
  ApplicationUserToManyRecordingGroupFileFilter: ApplicationUserToManyRecordingGroupFileFilter;
  ApplicationUserToManyRoleChangeNotificationFilter: ApplicationUserToManyRoleChangeNotificationFilter;
  ApplicationUserToManyTagFilter: ApplicationUserToManyTagFilter;
  ApplicationUserToManyTaskFilter: ApplicationUserToManyTaskFilter;
  ApplicationUserToManyTenantMonthlyConsumedResourceFilter: ApplicationUserToManyTenantMonthlyConsumedResourceFilter;
  ApplicationUserToManyTenantUserMembershipNotificationFilter: ApplicationUserToManyTenantUserMembershipNotificationFilter;
  ApplicationUserToManyWorkflowFilter: ApplicationUserToManyWorkflowFilter;
  ApplicationUserToManyWorkflowVersionFilter: ApplicationUserToManyWorkflowVersionFilter;
  ApplicationUserUsePermissionIdDbf9693DFkAuthPermApplicationUserUserPermissionsCreateInput: ApplicationUserUsePermissionIdDbf9693DFkAuthPermApplicationUserUserPermissionsCreateInput;
  ApplicationUserUsePermissionIdDbf9693DFkAuthPermAuthPermissionCreateInput: ApplicationUserUsePermissionIdDbf9693DFkAuthPermAuthPermissionCreateInput;
  ApplicationUserUsePermissionIdDbf9693DFkAuthPermInput: ApplicationUserUsePermissionIdDbf9693DFkAuthPermInput;
  ApplicationUserUsePermissionIdDbf9693DFkAuthPermInverseInput: ApplicationUserUsePermissionIdDbf9693DFkAuthPermInverseInput;
  ApplicationUserUseUserIdE643F548FkApplicatiApplicationUserCreateInput: ApplicationUserUseUserIdE643F548FkApplicatiApplicationUserCreateInput;
  ApplicationUserUseUserIdE643F548FkApplicatiApplicationUserUserPermissionsCreateInput: ApplicationUserUseUserIdE643F548FkApplicatiApplicationUserUserPermissionsCreateInput;
  ApplicationUserUseUserIdE643F548FkApplicatiInput: ApplicationUserUseUserIdE643F548FkApplicatiInput;
  ApplicationUserUseUserIdE643F548FkApplicatiInverseInput: ApplicationUserUseUserIdE643F548FkApplicatiInverseInput;
  ApplicationUserUserPermission: ResolverTypeWrapper<ApplicationUserUserPermission>;
  ApplicationUserUserPermissionApplicationUserUserPeUserIdPermissionId52D58A70UniqConnect: ApplicationUserUserPermissionApplicationUserUserPeUserIdPermissionId52D58A70UniqConnect;
  ApplicationUserUserPermissionApplicationUserUserPeUserIdPermissionId52D58A70UniqDelete: ApplicationUserUserPermissionApplicationUserUserPeUserIdPermissionId52D58A70UniqDelete;
  ApplicationUserUserPermissionApplicationUserUserPermissionsPkeyConnect: ApplicationUserUserPermissionApplicationUserUserPermissionsPkeyConnect;
  ApplicationUserUserPermissionApplicationUserUserPermissionsPkeyDelete: ApplicationUserUserPermissionApplicationUserUserPermissionsPkeyDelete;
  ApplicationUserUserPermissionCondition: ApplicationUserUserPermissionCondition;
  ApplicationUserUserPermissionFilter: ApplicationUserUserPermissionFilter;
  ApplicationUserUserPermissionInput: ApplicationUserUserPermissionInput;
  ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingApplicationUserUserPeUserIdPermissionId52D58A70UniqUpdate: ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingApplicationUserUserPeUserIdPermissionId52D58A70UniqUpdate;
  ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingApplicationUserUserPermissionsPkeyUpdate: ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingApplicationUserUserPermissionsPkeyUpdate;
  ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserUserPeUserIdPermissionId52D58A70UniqUpdate: ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserUserPeUserIdPermissionId52D58A70UniqUpdate;
  ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserUserPermissionsPkeyUpdate: ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserUserPermissionsPkeyUpdate;
  ApplicationUserUserPermissionPatch: ApplicationUserUserPermissionPatch;
  ApplicationUserUserPermissionsConnection: ResolverTypeWrapper<ApplicationUserUserPermissionsConnection>;
  ApplicationUserUserPermissionsEdge: ResolverTypeWrapper<ApplicationUserUserPermissionsEdge>;
  ApplicationUserUserPermissionsOrderBy: ApplicationUserUserPermissionsOrderBy;
  ApplicationUsersEdge: ResolverTypeWrapper<ApplicationUsersEdge>;
  ApplicationUsersOrderBy: ApplicationUsersOrderBy;
  AuthGroup: ResolverTypeWrapper<AuthGroup>;
  AuthGroupAuthGroupNameKeyConnect: AuthGroupAuthGroupNameKeyConnect;
  AuthGroupAuthGroupNameKeyDelete: AuthGroupAuthGroupNameKeyDelete;
  AuthGroupAuthGroupPkeyConnect: AuthGroupAuthGroupPkeyConnect;
  AuthGroupAuthGroupPkeyDelete: AuthGroupAuthGroupPkeyDelete;
  AuthGroupCondition: AuthGroupCondition;
  AuthGroupFilter: AuthGroupFilter;
  AuthGroupInput: AuthGroupInput;
  AuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingAuthGroupNameKeyUpdate: AuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingAuthGroupNameKeyUpdate;
  AuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingAuthGroupPkeyUpdate: AuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingAuthGroupPkeyUpdate;
  AuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupNameKeyUpdate: AuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupNameKeyUpdate;
  AuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupPkeyUpdate: AuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupPkeyUpdate;
  AuthGroupPatch: AuthGroupPatch;
  AuthGroupPermissioPermissionId84C5C92EFkAuthPermAuthGroupPermissionsCreateInput: AuthGroupPermissioPermissionId84C5C92EFkAuthPermAuthGroupPermissionsCreateInput;
  AuthGroupPermissioPermissionId84C5C92EFkAuthPermAuthPermissionCreateInput: AuthGroupPermissioPermissionId84C5C92EFkAuthPermAuthPermissionCreateInput;
  AuthGroupPermissioPermissionId84C5C92EFkAuthPermInput: AuthGroupPermissioPermissionId84C5C92EFkAuthPermInput;
  AuthGroupPermissioPermissionId84C5C92EFkAuthPermInverseInput: AuthGroupPermissioPermissionId84C5C92EFkAuthPermInverseInput;
  AuthGroupPermission: ResolverTypeWrapper<AuthGroupPermission>;
  AuthGroupPermissionAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqConnect: AuthGroupPermissionAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqConnect;
  AuthGroupPermissionAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqDelete: AuthGroupPermissionAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqDelete;
  AuthGroupPermissionAuthGroupPermissionsPkeyConnect: AuthGroupPermissionAuthGroupPermissionsPkeyConnect;
  AuthGroupPermissionAuthGroupPermissionsPkeyDelete: AuthGroupPermissionAuthGroupPermissionsPkeyDelete;
  AuthGroupPermissionCondition: AuthGroupPermissionCondition;
  AuthGroupPermissionFilter: AuthGroupPermissionFilter;
  AuthGroupPermissionInput: AuthGroupPermissionInput;
  AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqUpdate: AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqUpdate;
  AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthGroupPermissionsPkeyUpdate: AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthGroupPermissionsPkeyUpdate;
  AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqUpdate: AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqUpdate;
  AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupPermissionsPkeyUpdate: AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupPermissionsPkeyUpdate;
  AuthGroupPermissionPatch: AuthGroupPermissionPatch;
  AuthGroupPermissionsConnection: ResolverTypeWrapper<AuthGroupPermissionsConnection>;
  AuthGroupPermissionsEdge: ResolverTypeWrapper<AuthGroupPermissionsEdge>;
  AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdAuthGroupCreateInput: AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdAuthGroupCreateInput;
  AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdAuthGroupPermissionsCreateInput: AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdAuthGroupPermissionsCreateInput;
  AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInput: AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInput;
  AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInverseInput: AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInverseInput;
  AuthGroupPermissionsOrderBy: AuthGroupPermissionsOrderBy;
  AuthGroupToManyApplicationUserGroupFilter: AuthGroupToManyApplicationUserGroupFilter;
  AuthGroupToManyAuthGroupPermissionFilter: AuthGroupToManyAuthGroupPermissionFilter;
  AuthGroupsConnection: ResolverTypeWrapper<AuthGroupsConnection>;
  AuthGroupsEdge: ResolverTypeWrapper<AuthGroupsEdge>;
  AuthGroupsOrderBy: AuthGroupsOrderBy;
  AuthPermission: ResolverTypeWrapper<AuthPermission>;
  AuthPermissionAuthPermissionContentTypeIdCodename01Ab375AUniqConnect: AuthPermissionAuthPermissionContentTypeIdCodename01Ab375AUniqConnect;
  AuthPermissionAuthPermissionContentTypeIdCodename01Ab375AUniqDelete: AuthPermissionAuthPermissionContentTypeIdCodename01Ab375AUniqDelete;
  AuthPermissionAuthPermissionPkeyConnect: AuthPermissionAuthPermissionPkeyConnect;
  AuthPermissionAuthPermissionPkeyDelete: AuthPermissionAuthPermissionPkeyDelete;
  AuthPermissionCondition: AuthPermissionCondition;
  AuthPermissionContentTypeId2F476E4BFkDjangoCoAuthPermissionCreateInput: AuthPermissionContentTypeId2F476E4BFkDjangoCoAuthPermissionCreateInput;
  AuthPermissionContentTypeId2F476E4BFkDjangoCoDjangoContentTypeCreateInput: AuthPermissionContentTypeId2F476E4BFkDjangoCoDjangoContentTypeCreateInput;
  AuthPermissionContentTypeId2F476E4BFkDjangoCoInput: AuthPermissionContentTypeId2F476E4BFkDjangoCoInput;
  AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput: AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput;
  AuthPermissionFilter: AuthPermissionFilter;
  AuthPermissionInput: AuthPermissionInput;
  AuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingAuthPermissionContentTypeIdCodename01Ab375AUniqUpdate: AuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingAuthPermissionContentTypeIdCodename01Ab375AUniqUpdate;
  AuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingAuthPermissionPkeyUpdate: AuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingAuthPermissionPkeyUpdate;
  AuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthPermissionContentTypeIdCodename01Ab375AUniqUpdate: AuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthPermissionContentTypeIdCodename01Ab375AUniqUpdate;
  AuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthPermissionPkeyUpdate: AuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthPermissionPkeyUpdate;
  AuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingAuthPermissionContentTypeIdCodename01Ab375AUniqUpdate: AuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingAuthPermissionContentTypeIdCodename01Ab375AUniqUpdate;
  AuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingAuthPermissionPkeyUpdate: AuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingAuthPermissionPkeyUpdate;
  AuthPermissionPatch: AuthPermissionPatch;
  AuthPermissionToManyApplicationUserUserPermissionFilter: AuthPermissionToManyApplicationUserUserPermissionFilter;
  AuthPermissionToManyAuthGroupPermissionFilter: AuthPermissionToManyAuthGroupPermissionFilter;
  AuthPermissionsConnection: ResolverTypeWrapper<AuthPermissionsConnection>;
  AuthPermissionsEdge: ResolverTypeWrapper<AuthPermissionsEdge>;
  AuthPermissionsOrderBy: AuthPermissionsOrderBy;
  AvailableNonRestrictedProject: ResolverTypeWrapper<AvailableNonRestrictedProject>;
  AvailableNonRestrictedProjectCondition: AvailableNonRestrictedProjectCondition;
  AvailableNonRestrictedProjectFilter: AvailableNonRestrictedProjectFilter;
  AvailableNonRestrictedProjectInput: AvailableNonRestrictedProjectInput;
  AvailableNonRestrictedProjectsConnection: ResolverTypeWrapper<AvailableNonRestrictedProjectsConnection>;
  AvailableNonRestrictedProjectsEdge: ResolverTypeWrapper<AvailableNonRestrictedProjectsEdge>;
  AvailableNonRestrictedProjectsOrderBy: AvailableNonRestrictedProjectsOrderBy;
  Avatar: ResolverTypeWrapper<Avatar>;
  AvatarAvatarPkeyConnect: AvatarAvatarPkeyConnect;
  AvatarAvatarPkeyDelete: AvatarAvatarPkeyDelete;
  AvatarAvatarUserIdKeyConnect: AvatarAvatarUserIdKeyConnect;
  AvatarAvatarUserIdKeyDelete: AvatarAvatarUserIdKeyDelete;
  AvatarCondition: AvatarCondition;
  AvatarFilter: AvatarFilter;
  AvatarInput: AvatarInput;
  AvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingAvatarPkeyUpdate: AvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingAvatarPkeyUpdate;
  AvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingAvatarUserIdKeyUpdate: AvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingAvatarUserIdKeyUpdate;
  AvatarPatch: AvatarPatch;
  AvatarUserId771332F2FkApplicationUserIdApplicationUserCreateInput: AvatarUserId771332F2FkApplicationUserIdApplicationUserCreateInput;
  AvatarUserId771332F2FkApplicationUserIdAvatarCreateInput: AvatarUserId771332F2FkApplicationUserIdAvatarCreateInput;
  AvatarUserId771332F2FkApplicationUserIdInput: AvatarUserId771332F2FkApplicationUserIdInput;
  AvatarUserId771332F2FkApplicationUserIdInverseInput: AvatarUserId771332F2FkApplicationUserIdInverseInput;
  AvatarsConnection: ResolverTypeWrapper<AvatarsConnection>;
  AvatarsEdge: ResolverTypeWrapper<AvatarsEdge>;
  AvatarsOrderBy: AvatarsOrderBy;
  AwsBatchTask: ResolverTypeWrapper<AwsBatchTask>;
  AwsBatchTaskAwsBatchTaskPkeyConnect: AwsBatchTaskAwsBatchTaskPkeyConnect;
  AwsBatchTaskAwsBatchTaskPkeyDelete: AwsBatchTaskAwsBatchTaskPkeyDelete;
  AwsBatchTaskCondition: AwsBatchTaskCondition;
  AwsBatchTaskFilter: AwsBatchTaskFilter;
  AwsBatchTaskInput: AwsBatchTaskInput;
  AwsBatchTaskOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeUsingAwsBatchTaskPkeyUpdate: AwsBatchTaskOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeUsingAwsBatchTaskPkeyUpdate;
  AwsBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchUsingAwsBatchTaskPkeyUpdate: AwsBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchUsingAwsBatchTaskPkeyUpdate;
  AwsBatchTaskPatch: AwsBatchTaskPatch;
  AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeAwsBatchTaskCreateInput: AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeAwsBatchTaskCreateInput;
  AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInput: AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInput;
  AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInverseInput: AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInverseInput;
  AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeTesTaskexecutorCreateInput: AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeTesTaskexecutorCreateInput;
  AwsBatchTasksConnection: ResolverTypeWrapper<AwsBatchTasksConnection>;
  AwsBatchTasksEdge: ResolverTypeWrapper<AwsBatchTasksEdge>;
  AwsBatchTasksOrderBy: AwsBatchTasksOrderBy;
  AwsCognitoUser: ResolverTypeWrapper<AwsCognitoUser>;
  AwsCognitoUserAwsCognitoUserPkeyConnect: AwsCognitoUserAwsCognitoUserPkeyConnect;
  AwsCognitoUserAwsCognitoUserPkeyDelete: AwsCognitoUserAwsCognitoUserPkeyDelete;
  AwsCognitoUserCondition: AwsCognitoUserCondition;
  AwsCognitoUserFilter: AwsCognitoUserFilter;
  AwsCognitoUserInput: AwsCognitoUserInput;
  AwsCognitoUserOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsUsingAwsCognitoUserPkeyUpdate: AwsCognitoUserOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsUsingAwsCognitoUserPkeyUpdate;
  AwsCognitoUserPatch: AwsCognitoUserPatch;
  AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsAwsCognitoUserCreateInput: AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsAwsCognitoUserCreateInput;
  AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInput: AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInput;
  AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInverseInput: AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInverseInput;
  AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsTenantUserexecutorCreateInput: AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsTenantUserexecutorCreateInput;
  AwsCognitoUsersConnection: ResolverTypeWrapper<AwsCognitoUsersConnection>;
  AwsCognitoUsersEdge: ResolverTypeWrapper<AwsCognitoUsersEdge>;
  AwsCognitoUsersOrderBy: AwsCognitoUsersOrderBy;
  AwsEcrImage: ResolverTypeWrapper<AwsEcrImage>;
  AwsEcrImageAwsEcrImagePkeyConnect: AwsEcrImageAwsEcrImagePkeyConnect;
  AwsEcrImageAwsEcrImagePkeyDelete: AwsEcrImageAwsEcrImagePkeyDelete;
  AwsEcrImageCondition: AwsEcrImageCondition;
  AwsEcrImageContainerimageexecutAabe4FdbFkTesContaAwsEcrImageCreateInput: AwsEcrImageContainerimageexecutAabe4FdbFkTesContaAwsEcrImageCreateInput;
  AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInput: AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInput;
  AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInverseInput: AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInverseInput;
  AwsEcrImageContainerimageexecutAabe4FdbFkTesContaTesContainerimageexecutorCreateInput: AwsEcrImageContainerimageexecutAabe4FdbFkTesContaTesContainerimageexecutorCreateInput;
  AwsEcrImageFilter: AwsEcrImageFilter;
  AwsEcrImageInput: AwsEcrImageInput;
  AwsEcrImageOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaUsingAwsEcrImagePkeyUpdate: AwsEcrImageOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaUsingAwsEcrImagePkeyUpdate;
  AwsEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIUsingAwsEcrImagePkeyUpdate: AwsEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIUsingAwsEcrImagePkeyUpdate;
  AwsEcrImagePatch: AwsEcrImagePatch;
  AwsEcrImagesConnection: ResolverTypeWrapper<AwsEcrImagesConnection>;
  AwsEcrImagesEdge: ResolverTypeWrapper<AwsEcrImagesEdge>;
  AwsEcrImagesOrderBy: AwsEcrImagesOrderBy;
  AwsMockBatchTask: ResolverTypeWrapper<AwsMockBatchTask>;
  AwsMockBatchTaskAwsMockBatchTaskPkeyConnect: AwsMockBatchTaskAwsMockBatchTaskPkeyConnect;
  AwsMockBatchTaskAwsMockBatchTaskPkeyDelete: AwsMockBatchTaskAwsMockBatchTaskPkeyDelete;
  AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchAwsBatchTaskCreateInput: AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchAwsBatchTaskCreateInput;
  AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchAwsMockBatchTaskCreateInput: AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchAwsMockBatchTaskCreateInput;
  AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInput: AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInput;
  AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInverseInput: AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInverseInput;
  AwsMockBatchTaskCondition: AwsMockBatchTaskCondition;
  AwsMockBatchTaskFilter: AwsMockBatchTaskFilter;
  AwsMockBatchTaskInput: AwsMockBatchTaskInput;
  AwsMockBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchUsingAwsMockBatchTaskPkeyUpdate: AwsMockBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchUsingAwsMockBatchTaskPkeyUpdate;
  AwsMockBatchTaskPatch: AwsMockBatchTaskPatch;
  AwsMockBatchTasksConnection: ResolverTypeWrapper<AwsMockBatchTasksConnection>;
  AwsMockBatchTasksEdge: ResolverTypeWrapper<AwsMockBatchTasksEdge>;
  AwsMockBatchTasksOrderBy: AwsMockBatchTasksOrderBy;
  AwsMockEcrImage: ResolverTypeWrapper<AwsMockEcrImage>;
  AwsMockEcrImageAwsMockEcrImagePkeyConnect: AwsMockEcrImageAwsMockEcrImagePkeyConnect;
  AwsMockEcrImageAwsMockEcrImagePkeyDelete: AwsMockEcrImageAwsMockEcrImagePkeyDelete;
  AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIAwsEcrImageCreateInput: AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIAwsEcrImageCreateInput;
  AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIAwsMockEcrImageCreateInput: AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIAwsMockEcrImageCreateInput;
  AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInput: AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInput;
  AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInverseInput: AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInverseInput;
  AwsMockEcrImageCondition: AwsMockEcrImageCondition;
  AwsMockEcrImageFilter: AwsMockEcrImageFilter;
  AwsMockEcrImageInput: AwsMockEcrImageInput;
  AwsMockEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIUsingAwsMockEcrImagePkeyUpdate: AwsMockEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIUsingAwsMockEcrImagePkeyUpdate;
  AwsMockEcrImagePatch: AwsMockEcrImagePatch;
  AwsMockEcrImagesConnection: ResolverTypeWrapper<AwsMockEcrImagesConnection>;
  AwsMockEcrImagesEdge: ResolverTypeWrapper<AwsMockEcrImagesEdge>;
  AwsMockEcrImagesOrderBy: AwsMockEcrImagesOrderBy;
  AwsMockS3File: ResolverTypeWrapper<AwsMockS3File>;
  AwsMockS3FileAwsMockS3FilePkeyConnect: AwsMockS3FileAwsMockS3FilePkeyConnect;
  AwsMockS3FileAwsMockS3FilePkeyDelete: AwsMockS3FileAwsMockS3FilePkeyDelete;
  AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiAwsMockS3FileCreateInput: AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiAwsMockS3FileCreateInput;
  AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiAwsS3FileCreateInput: AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiAwsS3FileCreateInput;
  AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInput: AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInput;
  AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInverseInput: AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInverseInput;
  AwsMockS3FileCondition: AwsMockS3FileCondition;
  AwsMockS3FileFilter: AwsMockS3FileFilter;
  AwsMockS3FileInput: AwsMockS3FileInput;
  AwsMockS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiUsingAwsMockS3FilePkeyUpdate: AwsMockS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiUsingAwsMockS3FilePkeyUpdate;
  AwsMockS3FilePatch: AwsMockS3FilePatch;
  AwsMockS3FilesConnection: ResolverTypeWrapper<AwsMockS3FilesConnection>;
  AwsMockS3FilesEdge: ResolverTypeWrapper<AwsMockS3FilesEdge>;
  AwsMockS3FilesOrderBy: AwsMockS3FilesOrderBy;
  AwsS3File: ResolverTypeWrapper<AwsS3File>;
  AwsS3FileAwsS3FilePkeyConnect: AwsS3FileAwsS3FilePkeyConnect;
  AwsS3FileAwsS3FilePkeyDelete: AwsS3FileAwsS3FilePkeyDelete;
  AwsS3FileCondition: AwsS3FileCondition;
  AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdAwsS3FileCreateInput: AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdAwsS3FileCreateInput;
  AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdDrsFileexecutorCreateInput: AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdDrsFileexecutorCreateInput;
  AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInput: AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInput;
  AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInverseInput: AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInverseInput;
  AwsS3FileFilter: AwsS3FileFilter;
  AwsS3FileInput: AwsS3FileInput;
  AwsS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiUsingAwsS3FilePkeyUpdate: AwsS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiUsingAwsS3FilePkeyUpdate;
  AwsS3FileOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdUsingAwsS3FilePkeyUpdate: AwsS3FileOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdUsingAwsS3FilePkeyUpdate;
  AwsS3FilePatch: AwsS3FilePatch;
  AwsS3FilesConnection: ResolverTypeWrapper<AwsS3FilesConnection>;
  AwsS3FilesEdge: ResolverTypeWrapper<AwsS3FilesEdge>;
  AwsS3FilesOrderBy: AwsS3FilesOrderBy;
  BigFloat: ResolverTypeWrapper<Scalars["BigFloat"]["output"]>;
  BigFloatFilter: BigFloatFilter;
  BigInt: ResolverTypeWrapper<Scalars["BigInt"]["output"]>;
  BigIntFilter: BigIntFilter;
  Boolean: ResolverTypeWrapper<Scalars["Boolean"]["output"]>;
  BooleanFilter: BooleanFilter;
  ChangeUserSessionInput: ChangeUserSessionInput;
  ChangeUserSessionPayload: ResolverTypeWrapper<ChangeUserSessionPayload>;
  ContainerImage: ResolverTypeWrapper<ContainerImage>;
  ContainerImageCondition: ContainerImageCondition;
  ContainerImageContainerImageExecutorIdKeyConnect: ContainerImageContainerImageExecutorIdKeyConnect;
  ContainerImageContainerImageExecutorIdKeyDelete: ContainerImageContainerImageExecutorIdKeyDelete;
  ContainerImageContainerImageNameTagUniqueConnect: ContainerImageContainerImageNameTagUniqueConnect;
  ContainerImageContainerImageNameTagUniqueDelete: ContainerImageContainerImageNameTagUniqueDelete;
  ContainerImageContainerImagePkeyConnect: ContainerImageContainerImagePkeyConnect;
  ContainerImageContainerImagePkeyDelete: ContainerImageContainerImagePkeyDelete;
  ContainerImageContainerImageRepositoryTagUniqueConnect: ContainerImageContainerImageRepositoryTagUniqueConnect;
  ContainerImageContainerImageRepositoryTagUniqueDelete: ContainerImageContainerImageRepositoryTagUniqueDelete;
  ContainerImageContainerImageToolboxVersionIdE9Dede5FUniqConnect: ContainerImageContainerImageToolboxVersionIdE9Dede5FUniqConnect;
  ContainerImageContainerImageToolboxVersionIdE9Dede5FUniqDelete: ContainerImageContainerImageToolboxVersionIdE9Dede5FUniqDelete;
  ContainerImageExecutorId68979Fb6FkTesContaContainerImageCreateInput: ContainerImageExecutorId68979Fb6FkTesContaContainerImageCreateInput;
  ContainerImageExecutorId68979Fb6FkTesContaInput: ContainerImageExecutorId68979Fb6FkTesContaInput;
  ContainerImageExecutorId68979Fb6FkTesContaInverseInput: ContainerImageExecutorId68979Fb6FkTesContaInverseInput;
  ContainerImageExecutorId68979Fb6FkTesContaTesContainerimageexecutorCreateInput: ContainerImageExecutorId68979Fb6FkTesContaTesContainerimageexecutorCreateInput;
  ContainerImageFilter: ContainerImageFilter;
  ContainerImageInput: ContainerImageInput;
  ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageExecutorIdKeyUpdate: ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageExecutorIdKeyUpdate;
  ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageNameTagUniqueUpdate: ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageNameTagUniqueUpdate;
  ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImagePkeyUpdate: ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImagePkeyUpdate;
  ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageRepositoryTagUniqueUpdate: ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageRepositoryTagUniqueUpdate;
  ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate: ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate;
  ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageExecutorIdKeyUpdate: ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageExecutorIdKeyUpdate;
  ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageNameTagUniqueUpdate: ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageNameTagUniqueUpdate;
  ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImagePkeyUpdate: ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImagePkeyUpdate;
  ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageRepositoryTagUniqueUpdate: ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageRepositoryTagUniqueUpdate;
  ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate: ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate;
  ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageExecutorIdKeyUpdate: ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageExecutorIdKeyUpdate;
  ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageNameTagUniqueUpdate: ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageNameTagUniqueUpdate;
  ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImagePkeyUpdate: ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImagePkeyUpdate;
  ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageRepositoryTagUniqueUpdate: ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageRepositoryTagUniqueUpdate;
  ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate: ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate;
  ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageExecutorIdKeyUpdate: ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageExecutorIdKeyUpdate;
  ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageNameTagUniqueUpdate: ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageNameTagUniqueUpdate;
  ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImagePkeyUpdate: ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImagePkeyUpdate;
  ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageRepositoryTagUniqueUpdate: ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageRepositoryTagUniqueUpdate;
  ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate: ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate;
  ContainerImagePatch: ContainerImagePatch;
  ContainerImageToManyTaskFilter: ContainerImageToManyTaskFilter;
  ContainerImageToManyWorkflowFilter: ContainerImageToManyWorkflowFilter;
  ContainerImageToolboxVersionIdE9Dede5FFkToolboxVContainerImageCreateInput: ContainerImageToolboxVersionIdE9Dede5FFkToolboxVContainerImageCreateInput;
  ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInput: ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInput;
  ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInverseInput: ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInverseInput;
  ContainerImageToolboxVersionIdE9Dede5FFkToolboxVToolboxVersionCreateInput: ContainerImageToolboxVersionIdE9Dede5FFkToolboxVToolboxVersionCreateInput;
  ContainerImagesConnection: ResolverTypeWrapper<ContainerImagesConnection>;
  ContainerImagesEdge: ResolverTypeWrapper<ContainerImagesEdge>;
  ContainerImagesOrderBy: ContainerImagesOrderBy;
  Cost: ResolverTypeWrapper<Cost>;
  CostCondition: CostCondition;
  CostCostPkeyConnect: CostCostPkeyConnect;
  CostCostPkeyDelete: CostCostPkeyDelete;
  CostFilter: CostFilter;
  CostInput: CostInput;
  CostOnCostForCostTenantId7Dcd58A7FkTenantIdUsingCostPkeyUpdate: CostOnCostForCostTenantId7Dcd58A7FkTenantIdUsingCostPkeyUpdate;
  CostOnFileActivityForFileActivityCostId2A88D90AFkCostIdUsingCostPkeyUpdate: CostOnFileActivityForFileActivityCostId2A88D90AFkCostIdUsingCostPkeyUpdate;
  CostOnTaskActivityForTaskActivityCostId49E66058FkCostIdUsingCostPkeyUpdate: CostOnTaskActivityForTaskActivityCostId49E66058FkCostIdUsingCostPkeyUpdate;
  CostPatch: CostPatch;
  CostTenantId7Dcd58A7FkTenantIdCostCreateInput: CostTenantId7Dcd58A7FkTenantIdCostCreateInput;
  CostTenantId7Dcd58A7FkTenantIdInput: CostTenantId7Dcd58A7FkTenantIdInput;
  CostTenantId7Dcd58A7FkTenantIdInverseInput: CostTenantId7Dcd58A7FkTenantIdInverseInput;
  CostTenantId7Dcd58A7FkTenantIdTenantCreateInput: CostTenantId7Dcd58A7FkTenantIdTenantCreateInput;
  CostToManyFileActivityFilter: CostToManyFileActivityFilter;
  CostToManyTaskActivityFilter: CostToManyTaskActivityFilter;
  CostsConnection: ResolverTypeWrapper<CostsConnection>;
  CostsEdge: ResolverTypeWrapper<CostsEdge>;
  CostsOrderBy: CostsOrderBy;
  CreateAnalysisTableGroupInput: CreateAnalysisTableGroupInput;
  CreateAnalysisTableGroupPayload: ResolverTypeWrapper<CreateAnalysisTableGroupPayload>;
  CreateAnalysisTableInput: CreateAnalysisTableInput;
  CreateAnalysisTablePayload: ResolverTypeWrapper<CreateAnalysisTablePayload>;
  CreateAnalysisTableRowAttachResultInput: CreateAnalysisTableRowAttachResultInput;
  CreateAnalysisTableRowAttachResultPayload: ResolverTypeWrapper<CreateAnalysisTableRowAttachResultPayload>;
  CreateAnalysisTableRowInput: CreateAnalysisTableRowInput;
  CreateAnalysisTableRowPayload: ResolverTypeWrapper<CreateAnalysisTableRowPayload>;
  CreateApplicationUserGroupInput: CreateApplicationUserGroupInput;
  CreateApplicationUserGroupPayload: ResolverTypeWrapper<CreateApplicationUserGroupPayload>;
  CreateApplicationUserInput: CreateApplicationUserInput;
  CreateApplicationUserMembershipInput: CreateApplicationUserMembershipInput;
  CreateApplicationUserMembershipPayload: ResolverTypeWrapper<CreateApplicationUserMembershipPayload>;
  CreateApplicationUserMetadatumInput: CreateApplicationUserMetadatumInput;
  CreateApplicationUserMetadatumPayload: ResolverTypeWrapper<CreateApplicationUserMetadatumPayload>;
  CreateApplicationUserPayload: ResolverTypeWrapper<CreateApplicationUserPayload>;
  CreateApplicationUserSessionInput: CreateApplicationUserSessionInput;
  CreateApplicationUserSessionPayload: ResolverTypeWrapper<CreateApplicationUserSessionPayload>;
  CreateApplicationUserUserPermissionInput: CreateApplicationUserUserPermissionInput;
  CreateApplicationUserUserPermissionPayload: ResolverTypeWrapper<CreateApplicationUserUserPermissionPayload>;
  CreateAuthGroupInput: CreateAuthGroupInput;
  CreateAuthGroupPayload: ResolverTypeWrapper<CreateAuthGroupPayload>;
  CreateAuthGroupPermissionInput: CreateAuthGroupPermissionInput;
  CreateAuthGroupPermissionPayload: ResolverTypeWrapper<CreateAuthGroupPermissionPayload>;
  CreateAuthPermissionInput: CreateAuthPermissionInput;
  CreateAuthPermissionPayload: ResolverTypeWrapper<CreateAuthPermissionPayload>;
  CreateAvailableNonRestrictedProjectInput: CreateAvailableNonRestrictedProjectInput;
  CreateAvailableNonRestrictedProjectPayload: ResolverTypeWrapper<CreateAvailableNonRestrictedProjectPayload>;
  CreateAvatarInput: CreateAvatarInput;
  CreateAvatarPayload: ResolverTypeWrapper<CreateAvatarPayload>;
  CreateAwsBatchTaskInput: CreateAwsBatchTaskInput;
  CreateAwsBatchTaskPayload: ResolverTypeWrapper<CreateAwsBatchTaskPayload>;
  CreateAwsCognitoUserInput: CreateAwsCognitoUserInput;
  CreateAwsCognitoUserPayload: ResolverTypeWrapper<CreateAwsCognitoUserPayload>;
  CreateAwsEcrImageInput: CreateAwsEcrImageInput;
  CreateAwsEcrImagePayload: ResolverTypeWrapper<CreateAwsEcrImagePayload>;
  CreateAwsMockBatchTaskInput: CreateAwsMockBatchTaskInput;
  CreateAwsMockBatchTaskPayload: ResolverTypeWrapper<CreateAwsMockBatchTaskPayload>;
  CreateAwsMockEcrImageInput: CreateAwsMockEcrImageInput;
  CreateAwsMockEcrImagePayload: ResolverTypeWrapper<CreateAwsMockEcrImagePayload>;
  CreateAwsMockS3FileInput: CreateAwsMockS3FileInput;
  CreateAwsMockS3FilePayload: ResolverTypeWrapper<CreateAwsMockS3FilePayload>;
  CreateAwsS3FileInput: CreateAwsS3FileInput;
  CreateAwsS3FilePayload: ResolverTypeWrapper<CreateAwsS3FilePayload>;
  CreateContainerImageInput: CreateContainerImageInput;
  CreateContainerImagePayload: ResolverTypeWrapper<CreateContainerImagePayload>;
  CreateCostInput: CreateCostInput;
  CreateCostPayload: ResolverTypeWrapper<CreateCostPayload>;
  CreateCurrentApplicationUserInput: CreateCurrentApplicationUserInput;
  CreateCurrentApplicationUserPayload: ResolverTypeWrapper<CreateCurrentApplicationUserPayload>;
  CreateCurrentUserMembershipInput: CreateCurrentUserMembershipInput;
  CreateCurrentUserMembershipPayload: ResolverTypeWrapper<CreateCurrentUserMembershipPayload>;
  CreateDatasetInput: CreateDatasetInput;
  CreateDatasetMetadatumInput: CreateDatasetMetadatumInput;
  CreateDatasetMetadatumPayload: ResolverTypeWrapper<CreateDatasetMetadatumPayload>;
  CreateDatasetPayload: ResolverTypeWrapper<CreateDatasetPayload>;
  CreateDatasetRecordingsTableColumnInput: CreateDatasetRecordingsTableColumnInput;
  CreateDatasetRecordingsTableColumnOrderInput: CreateDatasetRecordingsTableColumnOrderInput;
  CreateDatasetRecordingsTableColumnOrderPayload: ResolverTypeWrapper<CreateDatasetRecordingsTableColumnOrderPayload>;
  CreateDatasetRecordingsTableColumnPayload: ResolverTypeWrapper<CreateDatasetRecordingsTableColumnPayload>;
  CreateDatasetRecordingsTableInput: CreateDatasetRecordingsTableInput;
  CreateDatasetRecordingsTablePayload: ResolverTypeWrapper<CreateDatasetRecordingsTablePayload>;
  CreateDatasetVersionInput: CreateDatasetVersionInput;
  CreateDatasetVersionPayload: ResolverTypeWrapper<CreateDatasetVersionPayload>;
  CreateDjangoAdminLogInput: CreateDjangoAdminLogInput;
  CreateDjangoAdminLogPayload: ResolverTypeWrapper<CreateDjangoAdminLogPayload>;
  CreateDjangoContentTypeInput: CreateDjangoContentTypeInput;
  CreateDjangoContentTypePayload: ResolverTypeWrapper<CreateDjangoContentTypePayload>;
  CreateDjangoMigrationInput: CreateDjangoMigrationInput;
  CreateDjangoMigrationPayload: ResolverTypeWrapper<CreateDjangoMigrationPayload>;
  CreateDjangoSesSesstatInput: CreateDjangoSesSesstatInput;
  CreateDjangoSesSesstatPayload: ResolverTypeWrapper<CreateDjangoSesSesstatPayload>;
  CreateDjangoSessionInput: CreateDjangoSessionInput;
  CreateDjangoSessionPayload: ResolverTypeWrapper<CreateDjangoSessionPayload>;
  CreateDrsFileexecutorInput: CreateDrsFileexecutorInput;
  CreateDrsFileexecutorPayload: ResolverTypeWrapper<CreateDrsFileexecutorPayload>;
  CreateDrsMockfileexecutorInput: CreateDrsMockfileexecutorInput;
  CreateDrsMockfileexecutorPayload: ResolverTypeWrapper<CreateDrsMockfileexecutorPayload>;
  CreateEulaVersionInput: CreateEulaVersionInput;
  CreateEulaVersionPayload: ResolverTypeWrapper<CreateEulaVersionPayload>;
  CreateEulaVersionUserAcceptanceInput: CreateEulaVersionUserAcceptanceInput;
  CreateEulaVersionUserAcceptancePayload: ResolverTypeWrapper<CreateEulaVersionUserAcceptancePayload>;
  CreateExplorerQueryInput: CreateExplorerQueryInput;
  CreateExplorerQueryPayload: ResolverTypeWrapper<CreateExplorerQueryPayload>;
  CreateExplorerQueryfavoriteInput: CreateExplorerQueryfavoriteInput;
  CreateExplorerQueryfavoritePayload: ResolverTypeWrapper<CreateExplorerQueryfavoritePayload>;
  CreateExplorerQuerylogInput: CreateExplorerQuerylogInput;
  CreateExplorerQuerylogPayload: ResolverTypeWrapper<CreateExplorerQuerylogPayload>;
  CreateFileActivityInput: CreateFileActivityInput;
  CreateFileActivityPayload: ResolverTypeWrapper<CreateFileActivityPayload>;
  CreateFileInput: CreateFileInput;
  CreateFileMetadatumInput: CreateFileMetadatumInput;
  CreateFileMetadatumPayload: ResolverTypeWrapper<CreateFileMetadatumPayload>;
  CreateFileOutputGroupMetadatumInput: CreateFileOutputGroupMetadatumInput;
  CreateFileOutputGroupMetadatumPayload: ResolverTypeWrapper<CreateFileOutputGroupMetadatumPayload>;
  CreateFilePayload: ResolverTypeWrapper<CreateFilePayload>;
  CreateFileRecordingGroupInput: CreateFileRecordingGroupInput;
  CreateFileRecordingGroupMetadatumInput: CreateFileRecordingGroupMetadatumInput;
  CreateFileRecordingGroupMetadatumPayload: ResolverTypeWrapper<CreateFileRecordingGroupMetadatumPayload>;
  CreateFileRecordingGroupPayload: ResolverTypeWrapper<CreateFileRecordingGroupPayload>;
  CreateMetadataValueInput: CreateMetadataValueInput;
  CreateMetadataValuePayload: ResolverTypeWrapper<CreateMetadataValuePayload>;
  CreateMetadatumInput: CreateMetadatumInput;
  CreateMetadatumPayload: ResolverTypeWrapper<CreateMetadatumPayload>;
  CreateOutputGroupFileInput: CreateOutputGroupFileInput;
  CreateOutputGroupFilePayload: ResolverTypeWrapper<CreateOutputGroupFilePayload>;
  CreateRecordingGroupFileInput: CreateRecordingGroupFileInput;
  CreateRecordingGroupFilePayload: ResolverTypeWrapper<CreateRecordingGroupFilePayload>;
  CreateRoleInput: CreateRoleInput;
  CreateRolePayload: ResolverTypeWrapper<CreateRolePayload>;
  CreateRunInput: CreateRunInput;
  CreateRunPayload: ResolverTypeWrapper<CreateRunPayload>;
  CreateSessionInput: CreateSessionInput;
  CreateSessionPayload: ResolverTypeWrapper<CreateSessionPayload>;
  CreateTagInput: CreateTagInput;
  CreateTagPayload: ResolverTypeWrapper<CreateTagPayload>;
  CreateTaskActivityInput: CreateTaskActivityInput;
  CreateTaskActivityPayload: ResolverTypeWrapper<CreateTaskActivityPayload>;
  CreateTaskInput: CreateTaskInput;
  CreateTaskPayload: ResolverTypeWrapper<CreateTaskPayload>;
  CreateTaskResourceInput: CreateTaskResourceInput;
  CreateTaskResourcePayload: ResolverTypeWrapper<CreateTaskResourcePayload>;
  CreateTaskSourceInput: CreateTaskSourceInput;
  CreateTaskSourcePayload: ResolverTypeWrapper<CreateTaskSourcePayload>;
  CreateTaskStatusLogInput: CreateTaskStatusLogInput;
  CreateTaskStatusLogPayload: ResolverTypeWrapper<CreateTaskStatusLogPayload>;
  CreateTenantContractAllowanceInput: CreateTenantContractAllowanceInput;
  CreateTenantContractAllowancePayload: ResolverTypeWrapper<CreateTenantContractAllowancePayload>;
  CreateTenantContractInput: CreateTenantContractInput;
  CreateTenantContractPayload: ResolverTypeWrapper<CreateTenantContractPayload>;
  CreateTenantContractTransactionInput: CreateTenantContractTransactionInput;
  CreateTenantContractTransactionPayload: ResolverTypeWrapper<CreateTenantContractTransactionPayload>;
  CreateTenantInput: CreateTenantInput;
  CreateTenantMockuserexecutorInput: CreateTenantMockuserexecutorInput;
  CreateTenantMockuserexecutorPayload: ResolverTypeWrapper<CreateTenantMockuserexecutorPayload>;
  CreateTenantMonthlyConsumedResourceInput: CreateTenantMonthlyConsumedResourceInput;
  CreateTenantMonthlyConsumedResourcePayload: ResolverTypeWrapper<CreateTenantMonthlyConsumedResourcePayload>;
  CreateTenantPayload: ResolverTypeWrapper<CreateTenantPayload>;
  CreateTenantUserexecutorInput: CreateTenantUserexecutorInput;
  CreateTenantUserexecutorPayload: ResolverTypeWrapper<CreateTenantUserexecutorPayload>;
  CreateTesContainerimageexecutorInput: CreateTesContainerimageexecutorInput;
  CreateTesContainerimageexecutorPayload: ResolverTypeWrapper<CreateTesContainerimageexecutorPayload>;
  CreateTesMockcontainerimageexecutorInput: CreateTesMockcontainerimageexecutorInput;
  CreateTesMockcontainerimageexecutorPayload: ResolverTypeWrapper<CreateTesMockcontainerimageexecutorPayload>;
  CreateTesMocktaskexecutorInput: CreateTesMocktaskexecutorInput;
  CreateTesMocktaskexecutorPayload: ResolverTypeWrapper<CreateTesMocktaskexecutorPayload>;
  CreateTesTaskexecutorInput: CreateTesTaskexecutorInput;
  CreateTesTaskexecutorPayload: ResolverTypeWrapper<CreateTesTaskexecutorPayload>;
  CreateToolCategoryInput: CreateToolCategoryInput;
  CreateToolCategoryPayload: ResolverTypeWrapper<CreateToolCategoryPayload>;
  CreateToolDisplayCategoryInput: CreateToolDisplayCategoryInput;
  CreateToolDisplayCategoryPayload: ResolverTypeWrapper<CreateToolDisplayCategoryPayload>;
  CreateToolInput: CreateToolInput;
  CreateToolParameterInput: CreateToolParameterInput;
  CreateToolParameterPayload: ResolverTypeWrapper<CreateToolParameterPayload>;
  CreateToolPayload: ResolverTypeWrapper<CreateToolPayload>;
  CreateToolResourceInput: CreateToolResourceInput;
  CreateToolResourcePayload: ResolverTypeWrapper<CreateToolResourcePayload>;
  CreateToolResultInput: CreateToolResultInput;
  CreateToolResultPayload: ResolverTypeWrapper<CreateToolResultPayload>;
  CreateToolSecretInput: CreateToolSecretInput;
  CreateToolSecretPayload: ResolverTypeWrapper<CreateToolSecretPayload>;
  CreateToolSourceInput: CreateToolSourceInput;
  CreateToolSourcePayload: ResolverTypeWrapper<CreateToolSourcePayload>;
  CreateToolTenantInput: CreateToolTenantInput;
  CreateToolTenantPayload: ResolverTypeWrapper<CreateToolTenantPayload>;
  CreateToolVersionInput: CreateToolVersionInput;
  CreateToolVersionPayload: ResolverTypeWrapper<CreateToolVersionPayload>;
  CreateToolboxExitcodeInput: CreateToolboxExitcodeInput;
  CreateToolboxExitcodePayload: ResolverTypeWrapper<CreateToolboxExitcodePayload>;
  CreateToolboxInput: CreateToolboxInput;
  CreateToolboxPayload: ResolverTypeWrapper<CreateToolboxPayload>;
  CreateToolboxTenantInput: CreateToolboxTenantInput;
  CreateToolboxTenantPayload: ResolverTypeWrapper<CreateToolboxTenantPayload>;
  CreateToolboxVersionExitcodeInput: CreateToolboxVersionExitcodeInput;
  CreateToolboxVersionExitcodePayload: ResolverTypeWrapper<CreateToolboxVersionExitcodePayload>;
  CreateToolboxVersionInput: CreateToolboxVersionInput;
  CreateToolboxVersionPayload: ResolverTypeWrapper<CreateToolboxVersionPayload>;
  CreateToolboxVersionToolVersionInput: CreateToolboxVersionToolVersionInput;
  CreateToolboxVersionToolVersionPayload: ResolverTypeWrapper<CreateToolboxVersionToolVersionPayload>;
  CreateWorkflowDatumInput: CreateWorkflowDatumInput;
  CreateWorkflowDatumPayload: ResolverTypeWrapper<CreateWorkflowDatumPayload>;
  CreateWorkflowInput: CreateWorkflowInput;
  CreateWorkflowPayload: ResolverTypeWrapper<CreateWorkflowPayload>;
  CreateWorkflowResultInput: CreateWorkflowResultInput;
  CreateWorkflowResultPayload: ResolverTypeWrapper<CreateWorkflowResultPayload>;
  CreateWorkflowResultTagInput: CreateWorkflowResultTagInput;
  CreateWorkflowResultTagPayload: ResolverTypeWrapper<CreateWorkflowResultTagPayload>;
  CreateWorkflowResultWorkflowVersionInput: CreateWorkflowResultWorkflowVersionInput;
  CreateWorkflowResultWorkflowVersionPayload: ResolverTypeWrapper<CreateWorkflowResultWorkflowVersionPayload>;
  CreateWorkflowSectionBlockInput: CreateWorkflowSectionBlockInput;
  CreateWorkflowSectionBlockPayload: ResolverTypeWrapper<CreateWorkflowSectionBlockPayload>;
  CreateWorkflowSectionBlockSectionInput: CreateWorkflowSectionBlockSectionInput;
  CreateWorkflowSectionBlockSectionPayload: ResolverTypeWrapper<CreateWorkflowSectionBlockSectionPayload>;
  CreateWorkflowSectionInput: CreateWorkflowSectionInput;
  CreateWorkflowSectionPayload: ResolverTypeWrapper<CreateWorkflowSectionPayload>;
  CreateWorkflowSectionWorkflowVersionInput: CreateWorkflowSectionWorkflowVersionInput;
  CreateWorkflowSectionWorkflowVersionPayload: ResolverTypeWrapper<CreateWorkflowSectionWorkflowVersionPayload>;
  CreateWorkflowSourceInput: CreateWorkflowSourceInput;
  CreateWorkflowSourcePayload: ResolverTypeWrapper<CreateWorkflowSourcePayload>;
  CreateWorkflowSourceTagInput: CreateWorkflowSourceTagInput;
  CreateWorkflowSourceTagPayload: ResolverTypeWrapper<CreateWorkflowSourceTagPayload>;
  CreateWorkflowSourceWorkflowVersionInput: CreateWorkflowSourceWorkflowVersionInput;
  CreateWorkflowSourceWorkflowVersionPayload: ResolverTypeWrapper<CreateWorkflowSourceWorkflowVersionPayload>;
  CreateWorkflowVersionInput: CreateWorkflowVersionInput;
  CreateWorkflowVersionPayload: ResolverTypeWrapper<CreateWorkflowVersionPayload>;
  CurrentApplicationUser: ResolverTypeWrapper<CurrentApplicationUser>;
  CurrentApplicationUserCondition: CurrentApplicationUserCondition;
  CurrentApplicationUserFilter: CurrentApplicationUserFilter;
  CurrentApplicationUserInput: CurrentApplicationUserInput;
  CurrentApplicationUsersConnection: ResolverTypeWrapper<CurrentApplicationUsersConnection>;
  CurrentApplicationUsersEdge: ResolverTypeWrapper<CurrentApplicationUsersEdge>;
  CurrentApplicationUsersOrderBy: CurrentApplicationUsersOrderBy;
  CurrentUserMembership: ResolverTypeWrapper<CurrentUserMembership>;
  CurrentUserMembershipCondition: CurrentUserMembershipCondition;
  CurrentUserMembershipFilter: CurrentUserMembershipFilter;
  CurrentUserMembershipInput: CurrentUserMembershipInput;
  CurrentUserMembershipsConnection: ResolverTypeWrapper<CurrentUserMembershipsConnection>;
  CurrentUserMembershipsEdge: ResolverTypeWrapper<CurrentUserMembershipsEdge>;
  CurrentUserMembershipsOrderBy: CurrentUserMembershipsOrderBy;
  Cursor: ResolverTypeWrapper<Scalars["Cursor"]["output"]>;
  Dataset: ResolverTypeWrapper<Dataset>;
  DatasetCondition: DatasetCondition;
  DatasetDatasetKeyTenantUniqueConnect: DatasetDatasetKeyTenantUniqueConnect;
  DatasetDatasetKeyTenantUniqueDelete: DatasetDatasetKeyTenantUniqueDelete;
  DatasetDatasetPrefixProjectUniqueConnect: DatasetDatasetPrefixProjectUniqueConnect;
  DatasetDatasetPrefixProjectUniqueDelete: DatasetDatasetPrefixProjectUniqueDelete;
  DatasetDatasetV4PkeyConnect: DatasetDatasetV4PkeyConnect;
  DatasetDatasetV4PkeyDelete: DatasetDatasetV4PkeyDelete;
  DatasetDatasetV4RecordingsTableIdKeyConnect: DatasetDatasetV4RecordingsTableIdKeyConnect;
  DatasetDatasetV4RecordingsTableIdKeyDelete: DatasetDatasetV4RecordingsTableIdKeyDelete;
  DatasetDescriptionId4043Bd78FkMetadataV4IdDatasetCreateInput: DatasetDescriptionId4043Bd78FkMetadataV4IdDatasetCreateInput;
  DatasetDescriptionId4043Bd78FkMetadataV4IdInput: DatasetDescriptionId4043Bd78FkMetadataV4IdInput;
  DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput: DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput;
  DatasetDescriptionId4043Bd78FkMetadataV4IdMetadataCreateInput: DatasetDescriptionId4043Bd78FkMetadataV4IdMetadataCreateInput;
  DatasetFilter: DatasetFilter;
  DatasetInput: DatasetInput;
  DatasetMetadataConnection: ResolverTypeWrapper<DatasetMetadataConnection>;
  DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdDatasetCreateInput: DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdDatasetCreateInput;
  DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdDatasetMetadataCreateInput: DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdDatasetMetadataCreateInput;
  DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInput: DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInput;
  DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput: DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput;
  DatasetMetadataEdge: ResolverTypeWrapper<DatasetMetadataEdge>;
  DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdDatasetMetadataCreateInput: DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdDatasetMetadataCreateInput;
  DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInput: DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInput;
  DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput: DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput;
  DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdMetadataCreateInput: DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdMetadataCreateInput;
  DatasetMetadataOrderBy: DatasetMetadataOrderBy;
  DatasetMetadataProjectIdB921B40CFkProjectIdDatasetMetadataCreateInput: DatasetMetadataProjectIdB921B40CFkProjectIdDatasetMetadataCreateInput;
  DatasetMetadataProjectIdB921B40CFkProjectIdInput: DatasetMetadataProjectIdB921B40CFkProjectIdInput;
  DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput: DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput;
  DatasetMetadataTenantIdD74Eb48BFkTenantIdDatasetMetadataCreateInput: DatasetMetadataTenantIdD74Eb48BFkTenantIdDatasetMetadataCreateInput;
  DatasetMetadataTenantIdD74Eb48BFkTenantIdInput: DatasetMetadataTenantIdD74Eb48BFkTenantIdInput;
  DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput: DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput;
  DatasetMetadataTenantIdD74Eb48BFkTenantIdTenantCreateInput: DatasetMetadataTenantIdD74Eb48BFkTenantIdTenantCreateInput;
  DatasetMetadatum: ResolverTypeWrapper<DatasetMetadatum>;
  DatasetMetadatumCondition: DatasetMetadatumCondition;
  DatasetMetadatumDatasetMetadataPkeyConnect: DatasetMetadatumDatasetMetadataPkeyConnect;
  DatasetMetadatumDatasetMetadataPkeyDelete: DatasetMetadatumDatasetMetadataPkeyDelete;
  DatasetMetadatumFilter: DatasetMetadatumFilter;
  DatasetMetadatumInput: DatasetMetadatumInput;
  DatasetMetadatumOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetMetadataPkeyUpdate: DatasetMetadatumOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetMetadataPkeyUpdate;
  DatasetMetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdUsingDatasetMetadataPkeyUpdate: DatasetMetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdUsingDatasetMetadataPkeyUpdate;
  DatasetMetadatumOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdUsingDatasetMetadataPkeyUpdate: DatasetMetadatumOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdUsingDatasetMetadataPkeyUpdate;
  DatasetMetadatumOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingDatasetMetadataPkeyUpdate: DatasetMetadatumOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingDatasetMetadataPkeyUpdate;
  DatasetMetadatumPatch: DatasetMetadatumPatch;
  DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetKeyTenantUniqueUpdate: DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetKeyTenantUniqueUpdate;
  DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetPrefixProjectUniqueUpdate: DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetPrefixProjectUniqueUpdate;
  DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetV4PkeyUpdate: DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetV4PkeyUpdate;
  DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetV4RecordingsTableIdKeyUpdate: DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetV4RecordingsTableIdKeyUpdate;
  DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetKeyTenantUniqueUpdate: DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetKeyTenantUniqueUpdate;
  DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetPrefixProjectUniqueUpdate: DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetPrefixProjectUniqueUpdate;
  DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetV4PkeyUpdate: DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetV4PkeyUpdate;
  DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetV4RecordingsTableIdKeyUpdate: DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetV4RecordingsTableIdKeyUpdate;
  DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetKeyTenantUniqueUpdate: DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetKeyTenantUniqueUpdate;
  DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetPrefixProjectUniqueUpdate: DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetPrefixProjectUniqueUpdate;
  DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetV4PkeyUpdate: DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetV4PkeyUpdate;
  DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetV4RecordingsTableIdKeyUpdate: DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetV4RecordingsTableIdKeyUpdate;
  DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetKeyTenantUniqueUpdate: DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetKeyTenantUniqueUpdate;
  DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetPrefixProjectUniqueUpdate: DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetPrefixProjectUniqueUpdate;
  DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetV4PkeyUpdate: DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetV4PkeyUpdate;
  DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetV4RecordingsTableIdKeyUpdate: DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetV4RecordingsTableIdKeyUpdate;
  DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetKeyTenantUniqueUpdate: DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetKeyTenantUniqueUpdate;
  DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetPrefixProjectUniqueUpdate: DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetPrefixProjectUniqueUpdate;
  DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetV4PkeyUpdate: DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetV4PkeyUpdate;
  DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetV4RecordingsTableIdKeyUpdate: DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetV4RecordingsTableIdKeyUpdate;
  DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetKeyTenantUniqueUpdate: DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetKeyTenantUniqueUpdate;
  DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetPrefixProjectUniqueUpdate: DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetPrefixProjectUniqueUpdate;
  DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetV4PkeyUpdate: DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetV4PkeyUpdate;
  DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetV4RecordingsTableIdKeyUpdate: DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetV4RecordingsTableIdKeyUpdate;
  DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetKeyTenantUniqueUpdate: DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetKeyTenantUniqueUpdate;
  DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetPrefixProjectUniqueUpdate: DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetPrefixProjectUniqueUpdate;
  DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetV4PkeyUpdate: DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetV4PkeyUpdate;
  DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetV4RecordingsTableIdKeyUpdate: DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetV4RecordingsTableIdKeyUpdate;
  DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetKeyTenantUniqueUpdate: DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetKeyTenantUniqueUpdate;
  DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetPrefixProjectUniqueUpdate: DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetPrefixProjectUniqueUpdate;
  DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetV4PkeyUpdate: DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetV4PkeyUpdate;
  DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetV4RecordingsTableIdKeyUpdate: DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetV4RecordingsTableIdKeyUpdate;
  DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetKeyTenantUniqueUpdate: DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetKeyTenantUniqueUpdate;
  DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetPrefixProjectUniqueUpdate: DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetPrefixProjectUniqueUpdate;
  DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetV4PkeyUpdate: DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetV4PkeyUpdate;
  DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetV4RecordingsTableIdKeyUpdate: DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetV4RecordingsTableIdKeyUpdate;
  DatasetPatch: DatasetPatch;
  DatasetRecordingsTColDefId837663C9FkMetadataDatasetRecordingsTableColumnCreateInput: DatasetRecordingsTColDefId837663C9FkMetadataDatasetRecordingsTableColumnCreateInput;
  DatasetRecordingsTColDefId837663C9FkMetadataInput: DatasetRecordingsTColDefId837663C9FkMetadataInput;
  DatasetRecordingsTColDefId837663C9FkMetadataInverseInput: DatasetRecordingsTColDefId837663C9FkMetadataInverseInput;
  DatasetRecordingsTColDefId837663C9FkMetadataMetadataCreateInput: DatasetRecordingsTColDefId837663C9FkMetadataMetadataCreateInput;
  DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRDatasetRecordingsTableColumnCreateInput: DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRDatasetRecordingsTableColumnCreateInput;
  DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRDatasetRecordingsTableColumnOrderCreateInput: DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRDatasetRecordingsTableColumnOrderCreateInput;
  DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInput: DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInput;
  DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput: DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput;
  DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRDatasetRecordingsTableColumnCreateInput: DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRDatasetRecordingsTableColumnCreateInput;
  DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRDatasetRecordingsTableCreateInput: DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRDatasetRecordingsTableCreateInput;
  DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput: DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput;
  DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInverseInput: DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInverseInput;
  DatasetRecordingsTProjectId8E1D93E1FkProjectIDatasetRecordingsTableColumnOrderCreateInput: DatasetRecordingsTProjectId8E1D93E1FkProjectIDatasetRecordingsTableColumnOrderCreateInput;
  DatasetRecordingsTProjectId8E1D93E1FkProjectIInput: DatasetRecordingsTProjectId8E1D93E1FkProjectIInput;
  DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput: DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput;
  DatasetRecordingsTProjectId40Dba6D7FkProjectIDatasetRecordingsTableColumnCreateInput: DatasetRecordingsTProjectId40Dba6D7FkProjectIDatasetRecordingsTableColumnCreateInput;
  DatasetRecordingsTProjectId40Dba6D7FkProjectIInput: DatasetRecordingsTProjectId40Dba6D7FkProjectIInput;
  DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput: DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput;
  DatasetRecordingsTTenantId43Dd9FaaFkTenantIdDatasetRecordingsTableColumnOrderCreateInput: DatasetRecordingsTTenantId43Dd9FaaFkTenantIdDatasetRecordingsTableColumnOrderCreateInput;
  DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInput: DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInput;
  DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput: DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput;
  DatasetRecordingsTTenantId43Dd9FaaFkTenantIdTenantCreateInput: DatasetRecordingsTTenantId43Dd9FaaFkTenantIdTenantCreateInput;
  DatasetRecordingsTable: ResolverTypeWrapper<DatasetRecordingsTable>;
  DatasetRecordingsTableColumn: ResolverTypeWrapper<DatasetRecordingsTableColumn>;
  DatasetRecordingsTableColumnCondition: DatasetRecordingsTableColumnCondition;
  DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyConnect: DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyConnect;
  DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyDelete: DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyDelete;
  DatasetRecordingsTableColumnFilter: DatasetRecordingsTableColumnFilter;
  DatasetRecordingsTableColumnInput: DatasetRecordingsTableColumnInput;
  DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataUsingDatasetRecordingsTableColumnPkeyUpdate: DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataUsingDatasetRecordingsTableColumnPkeyUpdate;
  DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRUsingDatasetRecordingsTableColumnPkeyUpdate: DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRUsingDatasetRecordingsTableColumnPkeyUpdate;
  DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIUsingDatasetRecordingsTableColumnPkeyUpdate: DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIUsingDatasetRecordingsTableColumnPkeyUpdate;
  DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingDatasetRecordingsTableColumnPkeyUpdate: DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingDatasetRecordingsTableColumnPkeyUpdate;
  DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRUsingDatasetRecordingsTableColumnPkeyUpdate: DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRUsingDatasetRecordingsTableColumnPkeyUpdate;
  DatasetRecordingsTableColumnOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRUsingDatasetRecordingsTableColumnPkeyUpdate: DatasetRecordingsTableColumnOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRUsingDatasetRecordingsTableColumnPkeyUpdate;
  DatasetRecordingsTableColumnOrder: ResolverTypeWrapper<DatasetRecordingsTableColumnOrder>;
  DatasetRecordingsTableColumnOrderCondition: DatasetRecordingsTableColumnOrderCondition;
  DatasetRecordingsTableColumnOrderDatasetRecordingsTableColumnOrderPkeyConnect: DatasetRecordingsTableColumnOrderDatasetRecordingsTableColumnOrderPkeyConnect;
  DatasetRecordingsTableColumnOrderDatasetRecordingsTableColumnOrderPkeyDelete: DatasetRecordingsTableColumnOrderDatasetRecordingsTableColumnOrderPkeyDelete;
  DatasetRecordingsTableColumnOrderFilter: DatasetRecordingsTableColumnOrderFilter;
  DatasetRecordingsTableColumnOrderInput: DatasetRecordingsTableColumnOrderInput;
  DatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRUsingDatasetRecordingsTableColumnOrderPkeyUpdate: DatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRUsingDatasetRecordingsTableColumnOrderPkeyUpdate;
  DatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIUsingDatasetRecordingsTableColumnOrderPkeyUpdate: DatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIUsingDatasetRecordingsTableColumnOrderPkeyUpdate;
  DatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingDatasetRecordingsTableColumnOrderPkeyUpdate: DatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingDatasetRecordingsTableColumnOrderPkeyUpdate;
  DatasetRecordingsTableColumnOrderPatch: DatasetRecordingsTableColumnOrderPatch;
  DatasetRecordingsTableColumnOrdersConnection: ResolverTypeWrapper<DatasetRecordingsTableColumnOrdersConnection>;
  DatasetRecordingsTableColumnOrdersEdge: ResolverTypeWrapper<DatasetRecordingsTableColumnOrdersEdge>;
  DatasetRecordingsTableColumnOrdersOrderBy: DatasetRecordingsTableColumnOrdersOrderBy;
  DatasetRecordingsTableColumnPatch: DatasetRecordingsTableColumnPatch;
  DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdDatasetRecordingsTableColumnCreateInput: DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdDatasetRecordingsTableColumnCreateInput;
  DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput: DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput;
  DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput: DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput;
  DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdTenantCreateInput: DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdTenantCreateInput;
  DatasetRecordingsTableColumnToManyDatasetRecordingsTableColumnOrderFilter: DatasetRecordingsTableColumnToManyDatasetRecordingsTableColumnOrderFilter;
  DatasetRecordingsTableColumnToManyRecordingGroupFileFilter: DatasetRecordingsTableColumnToManyRecordingGroupFileFilter;
  DatasetRecordingsTableColumnsConnection: ResolverTypeWrapper<DatasetRecordingsTableColumnsConnection>;
  DatasetRecordingsTableColumnsEdge: ResolverTypeWrapper<DatasetRecordingsTableColumnsEdge>;
  DatasetRecordingsTableColumnsOrderBy: DatasetRecordingsTableColumnsOrderBy;
  DatasetRecordingsTableCondition: DatasetRecordingsTableCondition;
  DatasetRecordingsTableDatasetRecordingsTablePkeyConnect: DatasetRecordingsTableDatasetRecordingsTablePkeyConnect;
  DatasetRecordingsTableDatasetRecordingsTablePkeyDelete: DatasetRecordingsTableDatasetRecordingsTablePkeyDelete;
  DatasetRecordingsTableFilter: DatasetRecordingsTableFilter;
  DatasetRecordingsTableInput: DatasetRecordingsTableInput;
  DatasetRecordingsTableOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetRecordingsTablePkeyUpdate: DatasetRecordingsTableOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetRecordingsTablePkeyUpdate;
  DatasetRecordingsTableOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRUsingDatasetRecordingsTablePkeyUpdate: DatasetRecordingsTableOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRUsingDatasetRecordingsTablePkeyUpdate;
  DatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdUsingDatasetRecordingsTablePkeyUpdate: DatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdUsingDatasetRecordingsTablePkeyUpdate;
  DatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingDatasetRecordingsTablePkeyUpdate: DatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingDatasetRecordingsTablePkeyUpdate;
  DatasetRecordingsTableOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTB91F3991FkDatasetRUsingDatasetRecordingsTablePkeyUpdate: DatasetRecordingsTableOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRUsingDatasetRecordingsTablePkeyUpdate;
  DatasetRecordingsTablePatch: DatasetRecordingsTablePatch;
  DatasetRecordingsTableProjectId710D9Eb2FkProjectIdDatasetRecordingsTableCreateInput: DatasetRecordingsTableProjectId710D9Eb2FkProjectIdDatasetRecordingsTableCreateInput;
  DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInput: DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInput;
  DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput: DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput;
  DatasetRecordingsTableTenantId94Ac7342FkTenantIdDatasetRecordingsTableCreateInput: DatasetRecordingsTableTenantId94Ac7342FkTenantIdDatasetRecordingsTableCreateInput;
  DatasetRecordingsTableTenantId94Ac7342FkTenantIdInput: DatasetRecordingsTableTenantId94Ac7342FkTenantIdInput;
  DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput: DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput;
  DatasetRecordingsTableTenantId94Ac7342FkTenantIdTenantCreateInput: DatasetRecordingsTableTenantId94Ac7342FkTenantIdTenantCreateInput;
  DatasetRecordingsTableToManyDatasetRecordingsTableColumnFilter: DatasetRecordingsTableToManyDatasetRecordingsTableColumnFilter;
  DatasetRecordingsTableToManyFileRecordingGroupFilter: DatasetRecordingsTableToManyFileRecordingGroupFilter;
  DatasetRecordingsTablesConnection: ResolverTypeWrapper<DatasetRecordingsTablesConnection>;
  DatasetRecordingsTablesEdge: ResolverTypeWrapper<DatasetRecordingsTablesEdge>;
  DatasetRecordingsTablesOrderBy: DatasetRecordingsTablesOrderBy;
  DatasetToManyDatasetMetadatumFilter: DatasetToManyDatasetMetadatumFilter;
  DatasetToManyDatasetVersionFilter: DatasetToManyDatasetVersionFilter;
  DatasetToManyFileFilter: DatasetToManyFileFilter;
  DatasetToManyTaskFilter: DatasetToManyTaskFilter;
  DatasetV4ProjectId98907276FkProjectIdDatasetCreateInput: DatasetV4ProjectId98907276FkProjectIdDatasetCreateInput;
  DatasetV4ProjectId98907276FkProjectIdInput: DatasetV4ProjectId98907276FkProjectIdInput;
  DatasetV4ProjectId98907276FkProjectIdInverseInput: DatasetV4ProjectId98907276FkProjectIdInverseInput;
  DatasetV4RecordingsTableIdD72E44AeFkDatasetRDatasetCreateInput: DatasetV4RecordingsTableIdD72E44AeFkDatasetRDatasetCreateInput;
  DatasetV4RecordingsTableIdD72E44AeFkDatasetRDatasetRecordingsTableCreateInput: DatasetV4RecordingsTableIdD72E44AeFkDatasetRDatasetRecordingsTableCreateInput;
  DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput: DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput;
  DatasetV4RecordingsTableIdD72E44AeFkDatasetRInverseInput: DatasetV4RecordingsTableIdD72E44AeFkDatasetRInverseInput;
  DatasetV4TenantId7055D1F4FkTenantIdDatasetCreateInput: DatasetV4TenantId7055D1F4FkTenantIdDatasetCreateInput;
  DatasetV4TenantId7055D1F4FkTenantIdInput: DatasetV4TenantId7055D1F4FkTenantIdInput;
  DatasetV4TenantId7055D1F4FkTenantIdInverseInput: DatasetV4TenantId7055D1F4FkTenantIdInverseInput;
  DatasetV4TenantId7055D1F4FkTenantIdTenantCreateInput: DatasetV4TenantId7055D1F4FkTenantIdTenantCreateInput;
  DatasetV4UserId5B9A2303FkApplicationUserIdApplicationUserCreateInput: DatasetV4UserId5B9A2303FkApplicationUserIdApplicationUserCreateInput;
  DatasetV4UserId5B9A2303FkApplicationUserIdDatasetCreateInput: DatasetV4UserId5B9A2303FkApplicationUserIdDatasetCreateInput;
  DatasetV4UserId5B9A2303FkApplicationUserIdInput: DatasetV4UserId5B9A2303FkApplicationUserIdInput;
  DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput: DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput;
  DatasetVersion: ResolverTypeWrapper<DatasetVersion>;
  DatasetVersionCondition: DatasetVersionCondition;
  DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdDatasetCreateInput: DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdDatasetCreateInput;
  DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdDatasetVersionCreateInput: DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdDatasetVersionCreateInput;
  DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInput: DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInput;
  DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput: DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput;
  DatasetVersionDatasetVersionPkeyConnect: DatasetVersionDatasetVersionPkeyConnect;
  DatasetVersionDatasetVersionPkeyDelete: DatasetVersionDatasetVersionPkeyDelete;
  DatasetVersionFilter: DatasetVersionFilter;
  DatasetVersionInput: DatasetVersionInput;
  DatasetVersionOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetVersionPkeyUpdate: DatasetVersionOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetVersionPkeyUpdate;
  DatasetVersionOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdUsingDatasetVersionPkeyUpdate: DatasetVersionOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdUsingDatasetVersionPkeyUpdate;
  DatasetVersionOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingDatasetVersionPkeyUpdate: DatasetVersionOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingDatasetVersionPkeyUpdate;
  DatasetVersionPatch: DatasetVersionPatch;
  DatasetVersionProjectId9E468C2EFkProjectIdDatasetVersionCreateInput: DatasetVersionProjectId9E468C2EFkProjectIdDatasetVersionCreateInput;
  DatasetVersionProjectId9E468C2EFkProjectIdInput: DatasetVersionProjectId9E468C2EFkProjectIdInput;
  DatasetVersionProjectId9E468C2EFkProjectIdInverseInput: DatasetVersionProjectId9E468C2EFkProjectIdInverseInput;
  DatasetVersionTenantId7Eb1908AFkTenantIdDatasetVersionCreateInput: DatasetVersionTenantId7Eb1908AFkTenantIdDatasetVersionCreateInput;
  DatasetVersionTenantId7Eb1908AFkTenantIdInput: DatasetVersionTenantId7Eb1908AFkTenantIdInput;
  DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput: DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput;
  DatasetVersionTenantId7Eb1908AFkTenantIdTenantCreateInput: DatasetVersionTenantId7Eb1908AFkTenantIdTenantCreateInput;
  DatasetVersionsConnection: ResolverTypeWrapper<DatasetVersionsConnection>;
  DatasetVersionsEdge: ResolverTypeWrapper<DatasetVersionsEdge>;
  DatasetVersionsOrderBy: DatasetVersionsOrderBy;
  DatasetsConnection: ResolverTypeWrapper<DatasetsConnection>;
  DatasetsEdge: ResolverTypeWrapper<DatasetsEdge>;
  DatasetsOrderBy: DatasetsOrderBy;
  Date: ResolverTypeWrapper<Scalars["Date"]["output"]>;
  DateFilter: DateFilter;
  Datetime: ResolverTypeWrapper<Scalars["Datetime"]["output"]>;
  DatetimeFilter: DatetimeFilter;
  DeleteAnalysisTableByIdInput: DeleteAnalysisTableByIdInput;
  DeleteAnalysisTableGroupByIdInput: DeleteAnalysisTableGroupByIdInput;
  DeleteAnalysisTableGroupPayload: ResolverTypeWrapper<DeleteAnalysisTableGroupPayload>;
  DeleteAnalysisTablePayload: ResolverTypeWrapper<DeleteAnalysisTablePayload>;
  DeleteAnalysisTableRowAttachResultByIdInput: DeleteAnalysisTableRowAttachResultByIdInput;
  DeleteAnalysisTableRowAttachResultPayload: ResolverTypeWrapper<DeleteAnalysisTableRowAttachResultPayload>;
  DeleteAnalysisTableRowByIdInput: DeleteAnalysisTableRowByIdInput;
  DeleteAnalysisTableRowByTaskIdInput: DeleteAnalysisTableRowByTaskIdInput;
  DeleteAnalysisTableRowPayload: ResolverTypeWrapper<DeleteAnalysisTableRowPayload>;
  DeleteApplicationUserByEmailInput: DeleteApplicationUserByEmailInput;
  DeleteApplicationUserByIdInput: DeleteApplicationUserByIdInput;
  DeleteApplicationUserByUsernameInput: DeleteApplicationUserByUsernameInput;
  DeleteApplicationUserGroupByIdInput: DeleteApplicationUserGroupByIdInput;
  DeleteApplicationUserGroupByUserIdAndGroupIdInput: DeleteApplicationUserGroupByUserIdAndGroupIdInput;
  DeleteApplicationUserGroupPayload: ResolverTypeWrapper<DeleteApplicationUserGroupPayload>;
  DeleteApplicationUserMembershipByIdInput: DeleteApplicationUserMembershipByIdInput;
  DeleteApplicationUserMembershipByUserIdAndTenantIdInput: DeleteApplicationUserMembershipByUserIdAndTenantIdInput;
  DeleteApplicationUserMembershipPayload: ResolverTypeWrapper<DeleteApplicationUserMembershipPayload>;
  DeleteApplicationUserMetadatumByIdInput: DeleteApplicationUserMetadatumByIdInput;
  DeleteApplicationUserMetadatumByUserIdAndKeyInput: DeleteApplicationUserMetadatumByUserIdAndKeyInput;
  DeleteApplicationUserMetadatumPayload: ResolverTypeWrapper<DeleteApplicationUserMetadatumPayload>;
  DeleteApplicationUserPayload: ResolverTypeWrapper<DeleteApplicationUserPayload>;
  DeleteApplicationUserSessionByIdInput: DeleteApplicationUserSessionByIdInput;
  DeleteApplicationUserSessionPayload: ResolverTypeWrapper<DeleteApplicationUserSessionPayload>;
  DeleteApplicationUserUserPermissionByIdInput: DeleteApplicationUserUserPermissionByIdInput;
  DeleteApplicationUserUserPermissionByUserIdAndPermissionIdInput: DeleteApplicationUserUserPermissionByUserIdAndPermissionIdInput;
  DeleteApplicationUserUserPermissionPayload: ResolverTypeWrapper<DeleteApplicationUserUserPermissionPayload>;
  DeleteAuthGroupByIdInput: DeleteAuthGroupByIdInput;
  DeleteAuthGroupByNameInput: DeleteAuthGroupByNameInput;
  DeleteAuthGroupPayload: ResolverTypeWrapper<DeleteAuthGroupPayload>;
  DeleteAuthGroupPermissionByGroupIdAndPermissionIdInput: DeleteAuthGroupPermissionByGroupIdAndPermissionIdInput;
  DeleteAuthGroupPermissionByIdInput: DeleteAuthGroupPermissionByIdInput;
  DeleteAuthGroupPermissionPayload: ResolverTypeWrapper<DeleteAuthGroupPermissionPayload>;
  DeleteAuthPermissionByContentTypeIdAndCodenameInput: DeleteAuthPermissionByContentTypeIdAndCodenameInput;
  DeleteAuthPermissionByIdInput: DeleteAuthPermissionByIdInput;
  DeleteAuthPermissionPayload: ResolverTypeWrapper<DeleteAuthPermissionPayload>;
  DeleteAvatarByIdInput: DeleteAvatarByIdInput;
  DeleteAvatarByUserIdInput: DeleteAvatarByUserIdInput;
  DeleteAvatarPayload: ResolverTypeWrapper<DeleteAvatarPayload>;
  DeleteAwsBatchTaskByTaskexecutorPtrIdInput: DeleteAwsBatchTaskByTaskexecutorPtrIdInput;
  DeleteAwsBatchTaskPayload: ResolverTypeWrapper<DeleteAwsBatchTaskPayload>;
  DeleteAwsCognitoUserByUserexecutorPtrIdInput: DeleteAwsCognitoUserByUserexecutorPtrIdInput;
  DeleteAwsCognitoUserPayload: ResolverTypeWrapper<DeleteAwsCognitoUserPayload>;
  DeleteAwsEcrImageByContainerimageexecutorPtrIdInput: DeleteAwsEcrImageByContainerimageexecutorPtrIdInput;
  DeleteAwsEcrImagePayload: ResolverTypeWrapper<DeleteAwsEcrImagePayload>;
  DeleteAwsMockBatchTaskByAwstaskexecutorPtrIdInput: DeleteAwsMockBatchTaskByAwstaskexecutorPtrIdInput;
  DeleteAwsMockBatchTaskPayload: ResolverTypeWrapper<DeleteAwsMockBatchTaskPayload>;
  DeleteAwsMockEcrImageByAwscontainerimageexecutorPtrIdInput: DeleteAwsMockEcrImageByAwscontainerimageexecutorPtrIdInput;
  DeleteAwsMockEcrImagePayload: ResolverTypeWrapper<DeleteAwsMockEcrImagePayload>;
  DeleteAwsMockS3FileByAwsfileexecutorPtrIdInput: DeleteAwsMockS3FileByAwsfileexecutorPtrIdInput;
  DeleteAwsMockS3FilePayload: ResolverTypeWrapper<DeleteAwsMockS3FilePayload>;
  DeleteAwsS3FileByFileexecutorPtrIdInput: DeleteAwsS3FileByFileexecutorPtrIdInput;
  DeleteAwsS3FilePayload: ResolverTypeWrapper<DeleteAwsS3FilePayload>;
  DeleteContainerImageByExecutorIdInput: DeleteContainerImageByExecutorIdInput;
  DeleteContainerImageByIdInput: DeleteContainerImageByIdInput;
  DeleteContainerImageByNameAndTagInput: DeleteContainerImageByNameAndTagInput;
  DeleteContainerImageByRepositoryAndTagInput: DeleteContainerImageByRepositoryAndTagInput;
  DeleteContainerImageByToolboxVersionIdInput: DeleteContainerImageByToolboxVersionIdInput;
  DeleteContainerImagePayload: ResolverTypeWrapper<DeleteContainerImagePayload>;
  DeleteCostByIdInput: DeleteCostByIdInput;
  DeleteCostPayload: ResolverTypeWrapper<DeleteCostPayload>;
  DeleteDatasetByIdInput: DeleteDatasetByIdInput;
  DeleteDatasetByProjectIdAndPrefixInput: DeleteDatasetByProjectIdAndPrefixInput;
  DeleteDatasetByRecordingsTableIdInput: DeleteDatasetByRecordingsTableIdInput;
  DeleteDatasetByTenantIdAndKeyInput: DeleteDatasetByTenantIdAndKeyInput;
  DeleteDatasetMetadatumByIdInput: DeleteDatasetMetadatumByIdInput;
  DeleteDatasetMetadatumPayload: ResolverTypeWrapper<DeleteDatasetMetadatumPayload>;
  DeleteDatasetPayload: ResolverTypeWrapper<DeleteDatasetPayload>;
  DeleteDatasetRecordingsTableByIdInput: DeleteDatasetRecordingsTableByIdInput;
  DeleteDatasetRecordingsTableColumnByIdInput: DeleteDatasetRecordingsTableColumnByIdInput;
  DeleteDatasetRecordingsTableColumnOrderByIdInput: DeleteDatasetRecordingsTableColumnOrderByIdInput;
  DeleteDatasetRecordingsTableColumnOrderPayload: ResolverTypeWrapper<DeleteDatasetRecordingsTableColumnOrderPayload>;
  DeleteDatasetRecordingsTableColumnPayload: ResolverTypeWrapper<DeleteDatasetRecordingsTableColumnPayload>;
  DeleteDatasetRecordingsTablePayload: ResolverTypeWrapper<DeleteDatasetRecordingsTablePayload>;
  DeleteDatasetVersionByIdInput: DeleteDatasetVersionByIdInput;
  DeleteDatasetVersionPayload: ResolverTypeWrapper<DeleteDatasetVersionPayload>;
  DeleteDjangoAdminLogByIdInput: DeleteDjangoAdminLogByIdInput;
  DeleteDjangoAdminLogPayload: ResolverTypeWrapper<DeleteDjangoAdminLogPayload>;
  DeleteDjangoContentTypeByAppLabelAndModelInput: DeleteDjangoContentTypeByAppLabelAndModelInput;
  DeleteDjangoContentTypeByIdInput: DeleteDjangoContentTypeByIdInput;
  DeleteDjangoContentTypePayload: ResolverTypeWrapper<DeleteDjangoContentTypePayload>;
  DeleteDjangoMigrationByIdInput: DeleteDjangoMigrationByIdInput;
  DeleteDjangoMigrationPayload: ResolverTypeWrapper<DeleteDjangoMigrationPayload>;
  DeleteDjangoSesSesstatByDateInput: DeleteDjangoSesSesstatByDateInput;
  DeleteDjangoSesSesstatByIdInput: DeleteDjangoSesSesstatByIdInput;
  DeleteDjangoSesSesstatPayload: ResolverTypeWrapper<DeleteDjangoSesSesstatPayload>;
  DeleteDjangoSessionBySessionKeyInput: DeleteDjangoSessionBySessionKeyInput;
  DeleteDjangoSessionPayload: ResolverTypeWrapper<DeleteDjangoSessionPayload>;
  DeleteDrsFileexecutorByIdInput: DeleteDrsFileexecutorByIdInput;
  DeleteDrsFileexecutorPayload: ResolverTypeWrapper<DeleteDrsFileexecutorPayload>;
  DeleteDrsMockfileexecutorByFileexecutorPtrIdInput: DeleteDrsMockfileexecutorByFileexecutorPtrIdInput;
  DeleteDrsMockfileexecutorPayload: ResolverTypeWrapper<DeleteDrsMockfileexecutorPayload>;
  DeleteEulaVersionByIdInput: DeleteEulaVersionByIdInput;
  DeleteEulaVersionByVersionInput: DeleteEulaVersionByVersionInput;
  DeleteEulaVersionPayload: ResolverTypeWrapper<DeleteEulaVersionPayload>;
  DeleteEulaVersionUserAcceptanceByIdInput: DeleteEulaVersionUserAcceptanceByIdInput;
  DeleteEulaVersionUserAcceptanceByUserIdAndEulaVersionIdInput: DeleteEulaVersionUserAcceptanceByUserIdAndEulaVersionIdInput;
  DeleteEulaVersionUserAcceptancePayload: ResolverTypeWrapper<DeleteEulaVersionUserAcceptancePayload>;
  DeleteExplorerQueryByIdInput: DeleteExplorerQueryByIdInput;
  DeleteExplorerQueryPayload: ResolverTypeWrapper<DeleteExplorerQueryPayload>;
  DeleteExplorerQueryfavoriteByIdInput: DeleteExplorerQueryfavoriteByIdInput;
  DeleteExplorerQueryfavoriteByQueryIdAndUserIdInput: DeleteExplorerQueryfavoriteByQueryIdAndUserIdInput;
  DeleteExplorerQueryfavoritePayload: ResolverTypeWrapper<DeleteExplorerQueryfavoritePayload>;
  DeleteExplorerQuerylogByIdInput: DeleteExplorerQuerylogByIdInput;
  DeleteExplorerQuerylogPayload: ResolverTypeWrapper<DeleteExplorerQuerylogPayload>;
  DeleteFileActivityByIdInput: DeleteFileActivityByIdInput;
  DeleteFileActivityPayload: ResolverTypeWrapper<DeleteFileActivityPayload>;
  DeleteFileByIdInput: DeleteFileByIdInput;
  DeleteFileMetadatumByIdInput: DeleteFileMetadatumByIdInput;
  DeleteFileMetadatumPayload: ResolverTypeWrapper<DeleteFileMetadatumPayload>;
  DeleteFileOutputGroupByIdInput: DeleteFileOutputGroupByIdInput;
  DeleteFileOutputGroupByTaskIdInput: DeleteFileOutputGroupByTaskIdInput;
  DeleteFileOutputGroupMetadatumByIdInput: DeleteFileOutputGroupMetadatumByIdInput;
  DeleteFileOutputGroupMetadatumPayload: ResolverTypeWrapper<DeleteFileOutputGroupMetadatumPayload>;
  DeleteFileOutputGroupPayload: ResolverTypeWrapper<DeleteFileOutputGroupPayload>;
  DeleteFilePayload: ResolverTypeWrapper<DeleteFilePayload>;
  DeleteFileRecordingGroupByIdInput: DeleteFileRecordingGroupByIdInput;
  DeleteFileRecordingGroupMetadatumByIdInput: DeleteFileRecordingGroupMetadatumByIdInput;
  DeleteFileRecordingGroupMetadatumPayload: ResolverTypeWrapper<DeleteFileRecordingGroupMetadatumPayload>;
  DeleteFileRecordingGroupPayload: ResolverTypeWrapper<DeleteFileRecordingGroupPayload>;
  DeleteMetadataValueByIdInput: DeleteMetadataValueByIdInput;
  DeleteMetadataValuePayload: ResolverTypeWrapper<DeleteMetadataValuePayload>;
  DeleteMetadatumByIdInput: DeleteMetadatumByIdInput;
  DeleteMetadatumPayload: ResolverTypeWrapper<DeleteMetadatumPayload>;
  DeleteOutputGroupFileByIdInput: DeleteOutputGroupFileByIdInput;
  DeleteOutputGroupFilePayload: ResolverTypeWrapper<DeleteOutputGroupFilePayload>;
  DeleteRecordingGroupFileByIdInput: DeleteRecordingGroupFileByIdInput;
  DeleteRecordingGroupFilePayload: ResolverTypeWrapper<DeleteRecordingGroupFilePayload>;
  DeleteRunByIdInput: DeleteRunByIdInput;
  DeleteRunPayload: ResolverTypeWrapper<DeleteRunPayload>;
  DeleteSessionInput: DeleteSessionInput;
  DeleteSessionPayload: ResolverTypeWrapper<DeleteSessionPayload>;
  DeleteTagByIdInput: DeleteTagByIdInput;
  DeleteTagPayload: ResolverTypeWrapper<DeleteTagPayload>;
  DeleteTaskActivityByIdInput: DeleteTaskActivityByIdInput;
  DeleteTaskActivityByTaskIdInput: DeleteTaskActivityByTaskIdInput;
  DeleteTaskActivityPayload: ResolverTypeWrapper<DeleteTaskActivityPayload>;
  DeleteTaskByExecutorIdInput: DeleteTaskByExecutorIdInput;
  DeleteTaskByIdInput: DeleteTaskByIdInput;
  DeleteTaskPayload: ResolverTypeWrapper<DeleteTaskPayload>;
  DeleteTaskResourceByIdInput: DeleteTaskResourceByIdInput;
  DeleteTaskResourcePayload: ResolverTypeWrapper<DeleteTaskResourcePayload>;
  DeleteTaskSourceByIdInput: DeleteTaskSourceByIdInput;
  DeleteTaskSourcePayload: ResolverTypeWrapper<DeleteTaskSourcePayload>;
  DeleteTaskStatusLogByIdInput: DeleteTaskStatusLogByIdInput;
  DeleteTaskStatusLogByTaskIdAndStatusInput: DeleteTaskStatusLogByTaskIdAndStatusInput;
  DeleteTaskStatusLogPayload: ResolverTypeWrapper<DeleteTaskStatusLogPayload>;
  DeleteTenantByIdInput: DeleteTenantByIdInput;
  DeleteTenantByKeyInput: DeleteTenantByKeyInput;
  DeleteTenantByNameInput: DeleteTenantByNameInput;
  DeleteTenantContractAllowanceByIdInput: DeleteTenantContractAllowanceByIdInput;
  DeleteTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeInput: DeleteTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeInput;
  DeleteTenantContractAllowancePayload: ResolverTypeWrapper<DeleteTenantContractAllowancePayload>;
  DeleteTenantContractByIdInput: DeleteTenantContractByIdInput;
  DeleteTenantContractByNameInput: DeleteTenantContractByNameInput;
  DeleteTenantContractPayload: ResolverTypeWrapper<DeleteTenantContractPayload>;
  DeleteTenantContractTransactionByIdInput: DeleteTenantContractTransactionByIdInput;
  DeleteTenantContractTransactionPayload: ResolverTypeWrapper<DeleteTenantContractTransactionPayload>;
  DeleteTenantMockuserexecutorByUserexecutorPtrIdInput: DeleteTenantMockuserexecutorByUserexecutorPtrIdInput;
  DeleteTenantMockuserexecutorPayload: ResolverTypeWrapper<DeleteTenantMockuserexecutorPayload>;
  DeleteTenantMonthlyConsumedResourceByIdInput: DeleteTenantMonthlyConsumedResourceByIdInput;
  DeleteTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractInput: DeleteTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractInput;
  DeleteTenantMonthlyConsumedResourcePayload: ResolverTypeWrapper<DeleteTenantMonthlyConsumedResourcePayload>;
  DeleteTenantPayload: ResolverTypeWrapper<DeleteTenantPayload>;
  DeleteTenantUserexecutorByIdInput: DeleteTenantUserexecutorByIdInput;
  DeleteTenantUserexecutorPayload: ResolverTypeWrapper<DeleteTenantUserexecutorPayload>;
  DeleteTesContainerimageexecutorByIdInput: DeleteTesContainerimageexecutorByIdInput;
  DeleteTesContainerimageexecutorPayload: ResolverTypeWrapper<DeleteTesContainerimageexecutorPayload>;
  DeleteTesMockcontainerimageexecutorByContainerimageexecutorPtrIdInput: DeleteTesMockcontainerimageexecutorByContainerimageexecutorPtrIdInput;
  DeleteTesMockcontainerimageexecutorPayload: ResolverTypeWrapper<DeleteTesMockcontainerimageexecutorPayload>;
  DeleteTesMocktaskexecutorByTaskexecutorPtrIdInput: DeleteTesMocktaskexecutorByTaskexecutorPtrIdInput;
  DeleteTesMocktaskexecutorPayload: ResolverTypeWrapper<DeleteTesMocktaskexecutorPayload>;
  DeleteTesTaskexecutorByIdInput: DeleteTesTaskexecutorByIdInput;
  DeleteTesTaskexecutorPayload: ResolverTypeWrapper<DeleteTesTaskexecutorPayload>;
  DeleteToolByIdInput: DeleteToolByIdInput;
  DeleteToolByKeyInput: DeleteToolByKeyInput;
  DeleteToolCategoryByIdInput: DeleteToolCategoryByIdInput;
  DeleteToolCategoryByKeyInput: DeleteToolCategoryByKeyInput;
  DeleteToolCategoryPayload: ResolverTypeWrapper<DeleteToolCategoryPayload>;
  DeleteToolDisplayCategoryByIdInput: DeleteToolDisplayCategoryByIdInput;
  DeleteToolDisplayCategoryByToolIdAndToolcategoryIdInput: DeleteToolDisplayCategoryByToolIdAndToolcategoryIdInput;
  DeleteToolDisplayCategoryPayload: ResolverTypeWrapper<DeleteToolDisplayCategoryPayload>;
  DeleteToolParameterByIdInput: DeleteToolParameterByIdInput;
  DeleteToolParameterPayload: ResolverTypeWrapper<DeleteToolParameterPayload>;
  DeleteToolPayload: ResolverTypeWrapper<DeleteToolPayload>;
  DeleteToolResourceByIdInput: DeleteToolResourceByIdInput;
  DeleteToolResourceByTypeAndToolVersionIdInput: DeleteToolResourceByTypeAndToolVersionIdInput;
  DeleteToolResourcePayload: ResolverTypeWrapper<DeleteToolResourcePayload>;
  DeleteToolResultByIdInput: DeleteToolResultByIdInput;
  DeleteToolResultPayload: ResolverTypeWrapper<DeleteToolResultPayload>;
  DeleteToolSecretByIdInput: DeleteToolSecretByIdInput;
  DeleteToolSecretPayload: ResolverTypeWrapper<DeleteToolSecretPayload>;
  DeleteToolSourceByIdInput: DeleteToolSourceByIdInput;
  DeleteToolSourcePayload: ResolverTypeWrapper<DeleteToolSourcePayload>;
  DeleteToolTenantByIdInput: DeleteToolTenantByIdInput;
  DeleteToolTenantByToolIdAndTenantIdInput: DeleteToolTenantByToolIdAndTenantIdInput;
  DeleteToolTenantPayload: ResolverTypeWrapper<DeleteToolTenantPayload>;
  DeleteToolVersionByIdInput: DeleteToolVersionByIdInput;
  DeleteToolVersionByToolIdAndVersionInput: DeleteToolVersionByToolIdAndVersionInput;
  DeleteToolVersionPayload: ResolverTypeWrapper<DeleteToolVersionPayload>;
  DeleteToolboxByIdInput: DeleteToolboxByIdInput;
  DeleteToolboxByKeyInput: DeleteToolboxByKeyInput;
  DeleteToolboxExitcodeByCodeInput: DeleteToolboxExitcodeByCodeInput;
  DeleteToolboxExitcodeByIdInput: DeleteToolboxExitcodeByIdInput;
  DeleteToolboxExitcodePayload: ResolverTypeWrapper<DeleteToolboxExitcodePayload>;
  DeleteToolboxPayload: ResolverTypeWrapper<DeleteToolboxPayload>;
  DeleteToolboxTenantByIdInput: DeleteToolboxTenantByIdInput;
  DeleteToolboxTenantByToolboxIdAndTenantIdInput: DeleteToolboxTenantByToolboxIdAndTenantIdInput;
  DeleteToolboxTenantPayload: ResolverTypeWrapper<DeleteToolboxTenantPayload>;
  DeleteToolboxVersionByIdInput: DeleteToolboxVersionByIdInput;
  DeleteToolboxVersionByToolboxIdAndVersionInput: DeleteToolboxVersionByToolboxIdAndVersionInput;
  DeleteToolboxVersionExitcodeByIdInput: DeleteToolboxVersionExitcodeByIdInput;
  DeleteToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdInput: DeleteToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdInput;
  DeleteToolboxVersionExitcodePayload: ResolverTypeWrapper<DeleteToolboxVersionExitcodePayload>;
  DeleteToolboxVersionPayload: ResolverTypeWrapper<DeleteToolboxVersionPayload>;
  DeleteToolboxVersionToolVersionByIdInput: DeleteToolboxVersionToolVersionByIdInput;
  DeleteToolboxVersionToolVersionByToolboxversionIdAndToolversionIdInput: DeleteToolboxVersionToolVersionByToolboxversionIdAndToolversionIdInput;
  DeleteToolboxVersionToolVersionPayload: ResolverTypeWrapper<DeleteToolboxVersionToolVersionPayload>;
  DeleteWorkflowByIdInput: DeleteWorkflowByIdInput;
  DeleteWorkflowDatumByIdInput: DeleteWorkflowDatumByIdInput;
  DeleteWorkflowDatumPayload: ResolverTypeWrapper<DeleteWorkflowDatumPayload>;
  DeleteWorkflowPayload: ResolverTypeWrapper<DeleteWorkflowPayload>;
  DeleteWorkflowResultByIdInput: DeleteWorkflowResultByIdInput;
  DeleteWorkflowResultPayload: ResolverTypeWrapper<DeleteWorkflowResultPayload>;
  DeleteWorkflowResultTagByIdInput: DeleteWorkflowResultTagByIdInput;
  DeleteWorkflowResultTagByResultIdAndTagIdInput: DeleteWorkflowResultTagByResultIdAndTagIdInput;
  DeleteWorkflowResultTagPayload: ResolverTypeWrapper<DeleteWorkflowResultTagPayload>;
  DeleteWorkflowResultWorkflowVersionByIdInput: DeleteWorkflowResultWorkflowVersionByIdInput;
  DeleteWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdInput: DeleteWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdInput;
  DeleteWorkflowResultWorkflowVersionPayload: ResolverTypeWrapper<DeleteWorkflowResultWorkflowVersionPayload>;
  DeleteWorkflowSectionBlockByIdInput: DeleteWorkflowSectionBlockByIdInput;
  DeleteWorkflowSectionBlockPayload: ResolverTypeWrapper<DeleteWorkflowSectionBlockPayload>;
  DeleteWorkflowSectionBlockSectionByBlockIdAndSectionIdInput: DeleteWorkflowSectionBlockSectionByBlockIdAndSectionIdInput;
  DeleteWorkflowSectionBlockSectionByIdInput: DeleteWorkflowSectionBlockSectionByIdInput;
  DeleteWorkflowSectionBlockSectionPayload: ResolverTypeWrapper<DeleteWorkflowSectionBlockSectionPayload>;
  DeleteWorkflowSectionByIdInput: DeleteWorkflowSectionByIdInput;
  DeleteWorkflowSectionPayload: ResolverTypeWrapper<DeleteWorkflowSectionPayload>;
  DeleteWorkflowSectionWorkflowVersionByIdInput: DeleteWorkflowSectionWorkflowVersionByIdInput;
  DeleteWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdInput: DeleteWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdInput;
  DeleteWorkflowSectionWorkflowVersionPayload: ResolverTypeWrapper<DeleteWorkflowSectionWorkflowVersionPayload>;
  DeleteWorkflowSourceByIdInput: DeleteWorkflowSourceByIdInput;
  DeleteWorkflowSourcePayload: ResolverTypeWrapper<DeleteWorkflowSourcePayload>;
  DeleteWorkflowSourceTagByIdInput: DeleteWorkflowSourceTagByIdInput;
  DeleteWorkflowSourceTagBySourceIdAndTagIdInput: DeleteWorkflowSourceTagBySourceIdAndTagIdInput;
  DeleteWorkflowSourceTagPayload: ResolverTypeWrapper<DeleteWorkflowSourceTagPayload>;
  DeleteWorkflowSourceWorkflowVersionByIdInput: DeleteWorkflowSourceWorkflowVersionByIdInput;
  DeleteWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdInput: DeleteWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdInput;
  DeleteWorkflowSourceWorkflowVersionPayload: ResolverTypeWrapper<DeleteWorkflowSourceWorkflowVersionPayload>;
  DeleteWorkflowVersionByIdInput: DeleteWorkflowVersionByIdInput;
  DeleteWorkflowVersionPayload: ResolverTypeWrapper<DeleteWorkflowVersionPayload>;
  DjangoAdminLog: ResolverTypeWrapper<DjangoAdminLog>;
  DjangoAdminLogCondition: DjangoAdminLogCondition;
  DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoDjangoAdminLogCreateInput: DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoDjangoAdminLogCreateInput;
  DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoDjangoContentTypeCreateInput: DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoDjangoContentTypeCreateInput;
  DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInput: DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInput;
  DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput: DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput;
  DjangoAdminLogDjangoAdminLogPkeyConnect: DjangoAdminLogDjangoAdminLogPkeyConnect;
  DjangoAdminLogDjangoAdminLogPkeyDelete: DjangoAdminLogDjangoAdminLogPkeyDelete;
  DjangoAdminLogFilter: DjangoAdminLogFilter;
  DjangoAdminLogInput: DjangoAdminLogInput;
  DjangoAdminLogOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoUsingDjangoAdminLogPkeyUpdate: DjangoAdminLogOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoUsingDjangoAdminLogPkeyUpdate;
  DjangoAdminLogOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingDjangoAdminLogPkeyUpdate: DjangoAdminLogOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingDjangoAdminLogPkeyUpdate;
  DjangoAdminLogPatch: DjangoAdminLogPatch;
  DjangoAdminLogUserIdC564Eba6FkApplicationUserIdApplicationUserCreateInput: DjangoAdminLogUserIdC564Eba6FkApplicationUserIdApplicationUserCreateInput;
  DjangoAdminLogUserIdC564Eba6FkApplicationUserIdDjangoAdminLogCreateInput: DjangoAdminLogUserIdC564Eba6FkApplicationUserIdDjangoAdminLogCreateInput;
  DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInput: DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInput;
  DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput: DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput;
  DjangoAdminLogsConnection: ResolverTypeWrapper<DjangoAdminLogsConnection>;
  DjangoAdminLogsEdge: ResolverTypeWrapper<DjangoAdminLogsEdge>;
  DjangoAdminLogsOrderBy: DjangoAdminLogsOrderBy;
  DjangoContentType: ResolverTypeWrapper<DjangoContentType>;
  DjangoContentTypeCondition: DjangoContentTypeCondition;
  DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqConnect: DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqConnect;
  DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqDelete: DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqDelete;
  DjangoContentTypeDjangoContentTypePkeyConnect: DjangoContentTypeDjangoContentTypePkeyConnect;
  DjangoContentTypeDjangoContentTypePkeyDelete: DjangoContentTypeDjangoContentTypePkeyDelete;
  DjangoContentTypeFilter: DjangoContentTypeFilter;
  DjangoContentTypeInput: DjangoContentTypeInput;
  DjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate: DjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate;
  DjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingDjangoContentTypePkeyUpdate: DjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingDjangoContentTypePkeyUpdate;
  DjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate: DjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate;
  DjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoUsingDjangoContentTypePkeyUpdate: DjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoUsingDjangoContentTypePkeyUpdate;
  DjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate: DjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate;
  DjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoUsingDjangoContentTypePkeyUpdate: DjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoUsingDjangoContentTypePkeyUpdate;
  DjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate: DjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate;
  DjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoUsingDjangoContentTypePkeyUpdate: DjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoUsingDjangoContentTypePkeyUpdate;
  DjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate: DjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate;
  DjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoUsingDjangoContentTypePkeyUpdate: DjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoUsingDjangoContentTypePkeyUpdate;
  DjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate: DjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate;
  DjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoUsingDjangoContentTypePkeyUpdate: DjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoUsingDjangoContentTypePkeyUpdate;
  DjangoContentTypePatch: DjangoContentTypePatch;
  DjangoContentTypeToManyAuthPermissionFilter: DjangoContentTypeToManyAuthPermissionFilter;
  DjangoContentTypeToManyDjangoAdminLogFilter: DjangoContentTypeToManyDjangoAdminLogFilter;
  DjangoContentTypeToManyDrsFileexecutorFilter: DjangoContentTypeToManyDrsFileexecutorFilter;
  DjangoContentTypeToManyTenantUserexecutorFilter: DjangoContentTypeToManyTenantUserexecutorFilter;
  DjangoContentTypeToManyTesContainerimageexecutorFilter: DjangoContentTypeToManyTesContainerimageexecutorFilter;
  DjangoContentTypeToManyTesTaskexecutorFilter: DjangoContentTypeToManyTesTaskexecutorFilter;
  DjangoContentTypesConnection: ResolverTypeWrapper<DjangoContentTypesConnection>;
  DjangoContentTypesEdge: ResolverTypeWrapper<DjangoContentTypesEdge>;
  DjangoContentTypesOrderBy: DjangoContentTypesOrderBy;
  DjangoMigration: ResolverTypeWrapper<DjangoMigration>;
  DjangoMigrationCondition: DjangoMigrationCondition;
  DjangoMigrationFilter: DjangoMigrationFilter;
  DjangoMigrationInput: DjangoMigrationInput;
  DjangoMigrationPatch: DjangoMigrationPatch;
  DjangoMigrationsConnection: ResolverTypeWrapper<DjangoMigrationsConnection>;
  DjangoMigrationsEdge: ResolverTypeWrapper<DjangoMigrationsEdge>;
  DjangoMigrationsOrderBy: DjangoMigrationsOrderBy;
  DjangoSesSesstat: ResolverTypeWrapper<DjangoSesSesstat>;
  DjangoSesSesstatCondition: DjangoSesSesstatCondition;
  DjangoSesSesstatFilter: DjangoSesSesstatFilter;
  DjangoSesSesstatInput: DjangoSesSesstatInput;
  DjangoSesSesstatPatch: DjangoSesSesstatPatch;
  DjangoSesSesstatsConnection: ResolverTypeWrapper<DjangoSesSesstatsConnection>;
  DjangoSesSesstatsEdge: ResolverTypeWrapper<DjangoSesSesstatsEdge>;
  DjangoSesSesstatsOrderBy: DjangoSesSesstatsOrderBy;
  DjangoSession: ResolverTypeWrapper<DjangoSession>;
  DjangoSessionCondition: DjangoSessionCondition;
  DjangoSessionFilter: DjangoSessionFilter;
  DjangoSessionInput: DjangoSessionInput;
  DjangoSessionPatch: DjangoSessionPatch;
  DjangoSessionsConnection: ResolverTypeWrapper<DjangoSessionsConnection>;
  DjangoSessionsEdge: ResolverTypeWrapper<DjangoSessionsEdge>;
  DjangoSessionsOrderBy: DjangoSessionsOrderBy;
  DrsFileexecutor: ResolverTypeWrapper<DrsFileexecutor>;
  DrsFileexecutorCondition: DrsFileexecutorCondition;
  DrsFileexecutorDrsFileexecutorPkeyConnect: DrsFileexecutorDrsFileexecutorPkeyConnect;
  DrsFileexecutorDrsFileexecutorPkeyDelete: DrsFileexecutorDrsFileexecutorPkeyDelete;
  DrsFileexecutorFilter: DrsFileexecutorFilter;
  DrsFileexecutorInput: DrsFileexecutorInput;
  DrsFileexecutorOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdUsingDrsFileexecutorPkeyUpdate: DrsFileexecutorOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdUsingDrsFileexecutorPkeyUpdate;
  DrsFileexecutorOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoUsingDrsFileexecutorPkeyUpdate: DrsFileexecutorOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoUsingDrsFileexecutorPkeyUpdate;
  DrsFileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeUsingDrsFileexecutorPkeyUpdate: DrsFileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeUsingDrsFileexecutorPkeyUpdate;
  DrsFileexecutorPatch: DrsFileexecutorPatch;
  DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoDjangoContentTypeCreateInput: DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoDjangoContentTypeCreateInput;
  DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoDrsFileexecutorCreateInput: DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoDrsFileexecutorCreateInput;
  DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInput: DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInput;
  DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput: DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput;
  DrsFileexecutorsConnection: ResolverTypeWrapper<DrsFileexecutorsConnection>;
  DrsFileexecutorsEdge: ResolverTypeWrapper<DrsFileexecutorsEdge>;
  DrsFileexecutorsOrderBy: DrsFileexecutorsOrderBy;
  DrsMockfileexecutor: ResolverTypeWrapper<DrsMockfileexecutor>;
  DrsMockfileexecutorCondition: DrsMockfileexecutorCondition;
  DrsMockfileexecutorDrsMockfileexecutorPkeyConnect: DrsMockfileexecutorDrsMockfileexecutorPkeyConnect;
  DrsMockfileexecutorDrsMockfileexecutorPkeyDelete: DrsMockfileexecutorDrsMockfileexecutorPkeyDelete;
  DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeDrsFileexecutorCreateInput: DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeDrsFileexecutorCreateInput;
  DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeDrsMockfileexecutorCreateInput: DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeDrsMockfileexecutorCreateInput;
  DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInput: DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInput;
  DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInverseInput: DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInverseInput;
  DrsMockfileexecutorFilter: DrsMockfileexecutorFilter;
  DrsMockfileexecutorInput: DrsMockfileexecutorInput;
  DrsMockfileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeUsingDrsMockfileexecutorPkeyUpdate: DrsMockfileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeUsingDrsMockfileexecutorPkeyUpdate;
  DrsMockfileexecutorPatch: DrsMockfileexecutorPatch;
  DrsMockfileexecutorsConnection: ResolverTypeWrapper<DrsMockfileexecutorsConnection>;
  DrsMockfileexecutorsEdge: ResolverTypeWrapper<DrsMockfileexecutorsEdge>;
  DrsMockfileexecutorsOrderBy: DrsMockfileexecutorsOrderBy;
  EulaVersion: ResolverTypeWrapper<EulaVersion>;
  EulaVersionCondition: EulaVersionCondition;
  EulaVersionEulaVersionPkeyConnect: EulaVersionEulaVersionPkeyConnect;
  EulaVersionEulaVersionPkeyDelete: EulaVersionEulaVersionPkeyDelete;
  EulaVersionEulaVersionVersionKeyConnect: EulaVersionEulaVersionVersionKeyConnect;
  EulaVersionEulaVersionVersionKeyDelete: EulaVersionEulaVersionVersionKeyDelete;
  EulaVersionFilter: EulaVersionFilter;
  EulaVersionInput: EulaVersionInput;
  EulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionPkeyUpdate: EulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionPkeyUpdate;
  EulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionVersionKeyUpdate: EulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionVersionKeyUpdate;
  EulaVersionPatch: EulaVersionPatch;
  EulaVersionToManyEulaVersionUserAcceptanceFilter: EulaVersionToManyEulaVersionUserAcceptanceFilter;
  EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersEulaVersionCreateInput: EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersEulaVersionCreateInput;
  EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersEulaVersionUserAcceptanceCreateInput: EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersEulaVersionUserAcceptanceCreateInput;
  EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInput: EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInput;
  EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInverseInput: EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInverseInput;
  EulaVersionUserAcUserIdB72Ed9C5FkApplicatiApplicationUserCreateInput: EulaVersionUserAcUserIdB72Ed9C5FkApplicatiApplicationUserCreateInput;
  EulaVersionUserAcUserIdB72Ed9C5FkApplicatiEulaVersionUserAcceptanceCreateInput: EulaVersionUserAcUserIdB72Ed9C5FkApplicatiEulaVersionUserAcceptanceCreateInput;
  EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInput: EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInput;
  EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput: EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput;
  EulaVersionUserAcceptance: ResolverTypeWrapper<EulaVersionUserAcceptance>;
  EulaVersionUserAcceptanceCondition: EulaVersionUserAcceptanceCondition;
  EulaVersionUserAcceptanceEulaVersionUserAcceptancePkeyConnect: EulaVersionUserAcceptanceEulaVersionUserAcceptancePkeyConnect;
  EulaVersionUserAcceptanceEulaVersionUserAcceptancePkeyDelete: EulaVersionUserAcceptanceEulaVersionUserAcceptancePkeyDelete;
  EulaVersionUserAcceptanceEulaVersionUserAcceptanceUniqueConnect: EulaVersionUserAcceptanceEulaVersionUserAcceptanceUniqueConnect;
  EulaVersionUserAcceptanceEulaVersionUserAcceptanceUniqueDelete: EulaVersionUserAcceptanceEulaVersionUserAcceptanceUniqueDelete;
  EulaVersionUserAcceptanceFilter: EulaVersionUserAcceptanceFilter;
  EulaVersionUserAcceptanceInput: EulaVersionUserAcceptanceInput;
  EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionUserAcceptancePkeyUpdate: EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionUserAcceptancePkeyUpdate;
  EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionUserAcceptanceUniqueUpdate: EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionUserAcceptanceUniqueUpdate;
  EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingEulaVersionUserAcceptancePkeyUpdate: EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingEulaVersionUserAcceptancePkeyUpdate;
  EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingEulaVersionUserAcceptanceUniqueUpdate: EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingEulaVersionUserAcceptanceUniqueUpdate;
  EulaVersionUserAcceptancePatch: EulaVersionUserAcceptancePatch;
  EulaVersionUserAcceptancesConnection: ResolverTypeWrapper<EulaVersionUserAcceptancesConnection>;
  EulaVersionUserAcceptancesEdge: ResolverTypeWrapper<EulaVersionUserAcceptancesEdge>;
  EulaVersionUserAcceptancesOrderBy: EulaVersionUserAcceptancesOrderBy;
  EulaVersionsConnection: ResolverTypeWrapper<EulaVersionsConnection>;
  EulaVersionsEdge: ResolverTypeWrapper<EulaVersionsEdge>;
  EulaVersionsOrderBy: EulaVersionsOrderBy;
  ExplorerQueriesConnection: ResolverTypeWrapper<ExplorerQueriesConnection>;
  ExplorerQueriesEdge: ResolverTypeWrapper<ExplorerQueriesEdge>;
  ExplorerQueriesOrderBy: ExplorerQueriesOrderBy;
  ExplorerQuery: ResolverTypeWrapper<ExplorerQuery>;
  ExplorerQueryCondition: ExplorerQueryCondition;
  ExplorerQueryCreatedByUserId182Dd868FkApplicatiApplicationUserCreateInput: ExplorerQueryCreatedByUserId182Dd868FkApplicatiApplicationUserCreateInput;
  ExplorerQueryCreatedByUserId182Dd868FkApplicatiExplorerQueryCreateInput: ExplorerQueryCreatedByUserId182Dd868FkApplicatiExplorerQueryCreateInput;
  ExplorerQueryCreatedByUserId182Dd868FkApplicatiInput: ExplorerQueryCreatedByUserId182Dd868FkApplicatiInput;
  ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput: ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput;
  ExplorerQueryExplorerQueryPkeyConnect: ExplorerQueryExplorerQueryPkeyConnect;
  ExplorerQueryExplorerQueryPkeyDelete: ExplorerQueryExplorerQueryPkeyDelete;
  ExplorerQueryFilter: ExplorerQueryFilter;
  ExplorerQueryInput: ExplorerQueryInput;
  ExplorerQueryOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingExplorerQueryPkeyUpdate: ExplorerQueryOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingExplorerQueryPkeyUpdate;
  ExplorerQueryOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdUsingExplorerQueryPkeyUpdate: ExplorerQueryOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdUsingExplorerQueryPkeyUpdate;
  ExplorerQueryOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdUsingExplorerQueryPkeyUpdate: ExplorerQueryOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdUsingExplorerQueryPkeyUpdate;
  ExplorerQueryPatch: ExplorerQueryPatch;
  ExplorerQueryToManyExplorerQueryfavoriteFilter: ExplorerQueryToManyExplorerQueryfavoriteFilter;
  ExplorerQueryToManyExplorerQuerylogFilter: ExplorerQueryToManyExplorerQuerylogFilter;
  ExplorerQueryfavorite: ResolverTypeWrapper<ExplorerQueryfavorite>;
  ExplorerQueryfavoriteCondition: ExplorerQueryfavoriteCondition;
  ExplorerQueryfavoriteExplorerQueryfavoritePkeyConnect: ExplorerQueryfavoriteExplorerQueryfavoritePkeyConnect;
  ExplorerQueryfavoriteExplorerQueryfavoritePkeyDelete: ExplorerQueryfavoriteExplorerQueryfavoritePkeyDelete;
  ExplorerQueryfavoriteExplorerQueryfavoriteQueryIdUserId602B82DeUniqConnect: ExplorerQueryfavoriteExplorerQueryfavoriteQueryIdUserId602B82DeUniqConnect;
  ExplorerQueryfavoriteExplorerQueryfavoriteQueryIdUserId602B82DeUniqDelete: ExplorerQueryfavoriteExplorerQueryfavoriteQueryIdUserId602B82DeUniqDelete;
  ExplorerQueryfavoriteFilter: ExplorerQueryfavoriteFilter;
  ExplorerQueryfavoriteInput: ExplorerQueryfavoriteInput;
  ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdUsingExplorerQueryfavoritePkeyUpdate: ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdUsingExplorerQueryfavoritePkeyUpdate;
  ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdUsingExplorerQueryfavoriteQueryIdUserId602B82DeUniqUpdate: ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdUsingExplorerQueryfavoriteQueryIdUserId602B82DeUniqUpdate;
  ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingExplorerQueryfavoritePkeyUpdate: ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingExplorerQueryfavoritePkeyUpdate;
  ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingExplorerQueryfavoriteQueryIdUserId602B82DeUniqUpdate: ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingExplorerQueryfavoriteQueryIdUserId602B82DeUniqUpdate;
  ExplorerQueryfavoritePatch: ExplorerQueryfavoritePatch;
  ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdExplorerQueryCreateInput: ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdExplorerQueryCreateInput;
  ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdExplorerQueryfavoriteCreateInput: ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdExplorerQueryfavoriteCreateInput;
  ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInput: ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInput;
  ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInverseInput: ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInverseInput;
  ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdApplicationUserCreateInput: ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdApplicationUserCreateInput;
  ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdExplorerQueryfavoriteCreateInput: ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdExplorerQueryfavoriteCreateInput;
  ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInput: ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInput;
  ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput: ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput;
  ExplorerQueryfavoritesConnection: ResolverTypeWrapper<ExplorerQueryfavoritesConnection>;
  ExplorerQueryfavoritesEdge: ResolverTypeWrapper<ExplorerQueryfavoritesEdge>;
  ExplorerQueryfavoritesOrderBy: ExplorerQueryfavoritesOrderBy;
  ExplorerQuerylog: ResolverTypeWrapper<ExplorerQuerylog>;
  ExplorerQuerylogCondition: ExplorerQuerylogCondition;
  ExplorerQuerylogExplorerQuerylogPkeyConnect: ExplorerQuerylogExplorerQuerylogPkeyConnect;
  ExplorerQuerylogExplorerQuerylogPkeyDelete: ExplorerQuerylogExplorerQuerylogPkeyDelete;
  ExplorerQuerylogFilter: ExplorerQuerylogFilter;
  ExplorerQuerylogInput: ExplorerQuerylogInput;
  ExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdUsingExplorerQuerylogPkeyUpdate: ExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdUsingExplorerQuerylogPkeyUpdate;
  ExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingExplorerQuerylogPkeyUpdate: ExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingExplorerQuerylogPkeyUpdate;
  ExplorerQuerylogPatch: ExplorerQuerylogPatch;
  ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdExplorerQueryCreateInput: ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdExplorerQueryCreateInput;
  ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdExplorerQuerylogCreateInput: ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdExplorerQuerylogCreateInput;
  ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInput: ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInput;
  ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInverseInput: ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInverseInput;
  ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiApplicationUserCreateInput: ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiApplicationUserCreateInput;
  ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiExplorerQuerylogCreateInput: ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiExplorerQuerylogCreateInput;
  ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInput: ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInput;
  ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput: ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput;
  ExplorerQuerylogsConnection: ResolverTypeWrapper<ExplorerQuerylogsConnection>;
  ExplorerQuerylogsEdge: ResolverTypeWrapper<ExplorerQuerylogsEdge>;
  ExplorerQuerylogsOrderBy: ExplorerQuerylogsOrderBy;
  File: ResolverTypeWrapper<File>;
  FileActivitiesConnection: ResolverTypeWrapper<FileActivitiesConnection>;
  FileActivitiesEdge: ResolverTypeWrapper<FileActivitiesEdge>;
  FileActivitiesOrderBy: FileActivitiesOrderBy;
  FileActivity: ResolverTypeWrapper<FileActivity>;
  FileActivityCondition: FileActivityCondition;
  FileActivityCostId2A88D90AFkCostIdCostCreateInput: FileActivityCostId2A88D90AFkCostIdCostCreateInput;
  FileActivityCostId2A88D90AFkCostIdFileActivityCreateInput: FileActivityCostId2A88D90AFkCostIdFileActivityCreateInput;
  FileActivityCostId2A88D90AFkCostIdInput: FileActivityCostId2A88D90AFkCostIdInput;
  FileActivityCostId2A88D90AFkCostIdInverseInput: FileActivityCostId2A88D90AFkCostIdInverseInput;
  FileActivityFileActivityPkeyConnect: FileActivityFileActivityPkeyConnect;
  FileActivityFileActivityPkeyDelete: FileActivityFileActivityPkeyDelete;
  FileActivityFileId015763A9FkFileIdFileActivityCreateInput: FileActivityFileId015763A9FkFileIdFileActivityCreateInput;
  FileActivityFileId015763A9FkFileIdFileCreateInput: FileActivityFileId015763A9FkFileIdFileCreateInput;
  FileActivityFileId015763A9FkFileIdInput: FileActivityFileId015763A9FkFileIdInput;
  FileActivityFileId015763A9FkFileIdInverseInput: FileActivityFileId015763A9FkFileIdInverseInput;
  FileActivityFilter: FileActivityFilter;
  FileActivityInput: FileActivityInput;
  FileActivityOnFileActivityForFileActivityCostId2A88D90AFkCostIdUsingFileActivityPkeyUpdate: FileActivityOnFileActivityForFileActivityCostId2A88D90AFkCostIdUsingFileActivityPkeyUpdate;
  FileActivityOnFileActivityForFileActivityFileId015763A9FkFileIdUsingFileActivityPkeyUpdate: FileActivityOnFileActivityForFileActivityFileId015763A9FkFileIdUsingFileActivityPkeyUpdate;
  FileActivityOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdUsingFileActivityPkeyUpdate: FileActivityOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdUsingFileActivityPkeyUpdate;
  FileActivityOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingFileActivityPkeyUpdate: FileActivityOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingFileActivityPkeyUpdate;
  FileActivityOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingFileActivityPkeyUpdate: FileActivityOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingFileActivityPkeyUpdate;
  FileActivityPatch: FileActivityPatch;
  FileActivityProjectId6F35B8CcFkProjectIdFileActivityCreateInput: FileActivityProjectId6F35B8CcFkProjectIdFileActivityCreateInput;
  FileActivityProjectId6F35B8CcFkProjectIdInput: FileActivityProjectId6F35B8CcFkProjectIdInput;
  FileActivityProjectId6F35B8CcFkProjectIdInverseInput: FileActivityProjectId6F35B8CcFkProjectIdInverseInput;
  FileActivityTenantIdC46A4F01FkTenantIdFileActivityCreateInput: FileActivityTenantIdC46A4F01FkTenantIdFileActivityCreateInput;
  FileActivityTenantIdC46A4F01FkTenantIdInput: FileActivityTenantIdC46A4F01FkTenantIdInput;
  FileActivityTenantIdC46A4F01FkTenantIdInverseInput: FileActivityTenantIdC46A4F01FkTenantIdInverseInput;
  FileActivityTenantIdC46A4F01FkTenantIdTenantCreateInput: FileActivityTenantIdC46A4F01FkTenantIdTenantCreateInput;
  FileActivityUserId868Ad8A9FkApplicationUserIdApplicationUserCreateInput: FileActivityUserId868Ad8A9FkApplicationUserIdApplicationUserCreateInput;
  FileActivityUserId868Ad8A9FkApplicationUserIdFileActivityCreateInput: FileActivityUserId868Ad8A9FkApplicationUserIdFileActivityCreateInput;
  FileActivityUserId868Ad8A9FkApplicationUserIdInput: FileActivityUserId868Ad8A9FkApplicationUserIdInput;
  FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput: FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput;
  FileCloneSourceIdBef488AeFkFileIdInput: FileCloneSourceIdBef488AeFkFileIdInput;
  FileCondition: FileCondition;
  FileDatasetIdC0A4045AFkDatasetV4IdDatasetCreateInput: FileDatasetIdC0A4045AFkDatasetV4IdDatasetCreateInput;
  FileDatasetIdC0A4045AFkDatasetV4IdFileCreateInput: FileDatasetIdC0A4045AFkDatasetV4IdFileCreateInput;
  FileDatasetIdC0A4045AFkDatasetV4IdInput: FileDatasetIdC0A4045AFkDatasetV4IdInput;
  FileDatasetIdC0A4045AFkDatasetV4IdInverseInput: FileDatasetIdC0A4045AFkDatasetV4IdInverseInput;
  FileExecutorId819Da590FkDrsFileexecutorIdInput: FileExecutorId819Da590FkDrsFileexecutorIdInput;
  FileExecutorId819Da590FkDrsFileexecutorIdInverseInput: FileExecutorId819Da590FkDrsFileexecutorIdInverseInput;
  FileFilePkeyConnect: FileFilePkeyConnect;
  FileFilePkeyDelete: FileFilePkeyDelete;
  FileFilter: FileFilter;
  FileInput: FileInput;
  FileMetadataConnection: ResolverTypeWrapper<FileMetadataConnection>;
  FileMetadataEdge: ResolverTypeWrapper<FileMetadataEdge>;
  FileMetadataFileId78256936FkFileIdFileCreateInput: FileMetadataFileId78256936FkFileIdFileCreateInput;
  FileMetadataFileId78256936FkFileIdFileMetadataCreateInput: FileMetadataFileId78256936FkFileIdFileMetadataCreateInput;
  FileMetadataFileId78256936FkFileIdInput: FileMetadataFileId78256936FkFileIdInput;
  FileMetadataFileId78256936FkFileIdInverseInput: FileMetadataFileId78256936FkFileIdInverseInput;
  FileMetadataMetadataIdFefc0061FkMetadataV4IdFileMetadataCreateInput: FileMetadataMetadataIdFefc0061FkMetadataV4IdFileMetadataCreateInput;
  FileMetadataMetadataIdFefc0061FkMetadataV4IdInput: FileMetadataMetadataIdFefc0061FkMetadataV4IdInput;
  FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput: FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput;
  FileMetadataMetadataIdFefc0061FkMetadataV4IdMetadataCreateInput: FileMetadataMetadataIdFefc0061FkMetadataV4IdMetadataCreateInput;
  FileMetadataOrderBy: FileMetadataOrderBy;
  FileMetadataProjectId3474994EFkProjectIdFileMetadataCreateInput: FileMetadataProjectId3474994EFkProjectIdFileMetadataCreateInput;
  FileMetadataProjectId3474994EFkProjectIdInput: FileMetadataProjectId3474994EFkProjectIdInput;
  FileMetadataProjectId3474994EFkProjectIdInverseInput: FileMetadataProjectId3474994EFkProjectIdInverseInput;
  FileMetadataTenantId79Fe438BFkTenantIdFileMetadataCreateInput: FileMetadataTenantId79Fe438BFkTenantIdFileMetadataCreateInput;
  FileMetadataTenantId79Fe438BFkTenantIdInput: FileMetadataTenantId79Fe438BFkTenantIdInput;
  FileMetadataTenantId79Fe438BFkTenantIdInverseInput: FileMetadataTenantId79Fe438BFkTenantIdInverseInput;
  FileMetadataTenantId79Fe438BFkTenantIdTenantCreateInput: FileMetadataTenantId79Fe438BFkTenantIdTenantCreateInput;
  FileMetadatum: ResolverTypeWrapper<FileMetadatum>;
  FileMetadatumCondition: FileMetadatumCondition;
  FileMetadatumFileMetadataPkeyConnect: FileMetadatumFileMetadataPkeyConnect;
  FileMetadatumFileMetadataPkeyDelete: FileMetadatumFileMetadataPkeyDelete;
  FileMetadatumFilter: FileMetadatumFilter;
  FileMetadatumInput: FileMetadatumInput;
  FileMetadatumOnFileMetadatumForFileMetadataFileId78256936FkFileIdUsingFileMetadataPkeyUpdate: FileMetadatumOnFileMetadatumForFileMetadataFileId78256936FkFileIdUsingFileMetadataPkeyUpdate;
  FileMetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdUsingFileMetadataPkeyUpdate: FileMetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdUsingFileMetadataPkeyUpdate;
  FileMetadatumOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdUsingFileMetadataPkeyUpdate: FileMetadatumOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdUsingFileMetadataPkeyUpdate;
  FileMetadatumOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingFileMetadataPkeyUpdate: FileMetadatumOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingFileMetadataPkeyUpdate;
  FileMetadatumPatch: FileMetadatumPatch;
  FileOnFileActivityForFileActivityFileId015763A9FkFileIdUsingFilePkeyUpdate: FileOnFileActivityForFileActivityFileId015763A9FkFileIdUsingFilePkeyUpdate;
  FileOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingFilePkeyUpdate: FileOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingFilePkeyUpdate;
  FileOnFileForFileProjectId3Ecca975FkProjectIdUsingFilePkeyUpdate: FileOnFileForFileProjectId3Ecca975FkProjectIdUsingFilePkeyUpdate;
  FileOnFileForFileSeriesParentIdF7A07E20FkFileIdUsingFilePkeyUpdate: FileOnFileForFileSeriesParentIdF7A07E20FkFileIdUsingFilePkeyUpdate;
  FileOnFileForFileTenantId61625016FkTenantIdUsingFilePkeyUpdate: FileOnFileForFileTenantId61625016FkTenantIdUsingFilePkeyUpdate;
  FileOnFileForFileUserId01D90B53FkApplicationUserIdUsingFilePkeyUpdate: FileOnFileForFileUserId01D90B53FkApplicationUserIdUsingFilePkeyUpdate;
  FileOnFileMetadatumForFileMetadataFileId78256936FkFileIdUsingFilePkeyUpdate: FileOnFileMetadatumForFileMetadataFileId78256936FkFileIdUsingFilePkeyUpdate;
  FileOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdUsingFilePkeyUpdate: FileOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdUsingFilePkeyUpdate;
  FileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdUsingFilePkeyUpdate: FileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdUsingFilePkeyUpdate;
  FileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdUsingFilePkeyUpdate: FileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdUsingFilePkeyUpdate;
  FileOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdUsingFilePkeyUpdate: FileOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdUsingFilePkeyUpdate;
  FileOutputGroup: ResolverTypeWrapper<FileOutputGroup>;
  FileOutputGroupCondition: FileOutputGroupCondition;
  FileOutputGroupFileOutputGroupPkeyConnect: FileOutputGroupFileOutputGroupPkeyConnect;
  FileOutputGroupFileOutputGroupPkeyDelete: FileOutputGroupFileOutputGroupPkeyDelete;
  FileOutputGroupFileOutputGroupTaskIdKeyConnect: FileOutputGroupFileOutputGroupTaskIdKeyConnect;
  FileOutputGroupFileOutputGroupTaskIdKeyDelete: FileOutputGroupFileOutputGroupTaskIdKeyDelete;
  FileOutputGroupFilter: FileOutputGroupFilter;
  FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpFileOutputGroupMetadataCreateInput: FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpFileOutputGroupMetadataCreateInput;
  FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInput: FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInput;
  FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInverseInput: FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInverseInput;
  FileOutputGroupMeMetadataId3A08Fd87FkMetadataFileOutputGroupMetadataCreateInput: FileOutputGroupMeMetadataId3A08Fd87FkMetadataFileOutputGroupMetadataCreateInput;
  FileOutputGroupMeMetadataId3A08Fd87FkMetadataInput: FileOutputGroupMeMetadataId3A08Fd87FkMetadataInput;
  FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput: FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput;
  FileOutputGroupMeMetadataId3A08Fd87FkMetadataMetadataCreateInput: FileOutputGroupMeMetadataId3A08Fd87FkMetadataMetadataCreateInput;
  FileOutputGroupMetadataConnection: ResolverTypeWrapper<FileOutputGroupMetadataConnection>;
  FileOutputGroupMetadataEdge: ResolverTypeWrapper<FileOutputGroupMetadataEdge>;
  FileOutputGroupMetadataOrderBy: FileOutputGroupMetadataOrderBy;
  FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdFileOutputGroupMetadataCreateInput: FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdFileOutputGroupMetadataCreateInput;
  FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInput: FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInput;
  FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput: FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput;
  FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdFileOutputGroupMetadataCreateInput: FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdFileOutputGroupMetadataCreateInput;
  FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInput: FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInput;
  FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput: FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput;
  FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdTenantCreateInput: FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdTenantCreateInput;
  FileOutputGroupMetadatum: ResolverTypeWrapper<FileOutputGroupMetadatum>;
  FileOutputGroupMetadatumCondition: FileOutputGroupMetadatumCondition;
  FileOutputGroupMetadatumFileOutputGroupMetadataPkeyConnect: FileOutputGroupMetadatumFileOutputGroupMetadataPkeyConnect;
  FileOutputGroupMetadatumFileOutputGroupMetadataPkeyDelete: FileOutputGroupMetadatumFileOutputGroupMetadataPkeyDelete;
  FileOutputGroupMetadatumFilter: FileOutputGroupMetadatumFilter;
  FileOutputGroupMetadatumInput: FileOutputGroupMetadatumInput;
  FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpUsingFileOutputGroupMetadataPkeyUpdate: FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpUsingFileOutputGroupMetadataPkeyUpdate;
  FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataUsingFileOutputGroupMetadataPkeyUpdate: FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataUsingFileOutputGroupMetadataPkeyUpdate;
  FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdUsingFileOutputGroupMetadataPkeyUpdate: FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdUsingFileOutputGroupMetadataPkeyUpdate;
  FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingFileOutputGroupMetadataPkeyUpdate: FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingFileOutputGroupMetadataPkeyUpdate;
  FileOutputGroupMetadatumPatch: FileOutputGroupMetadatumPatch;
  FileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingFileOutputGroupPkeyUpdate: FileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingFileOutputGroupPkeyUpdate;
  FileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingFileOutputGroupTaskIdKeyUpdate: FileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingFileOutputGroupTaskIdKeyUpdate;
  FileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingFileOutputGroupPkeyUpdate: FileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingFileOutputGroupPkeyUpdate;
  FileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingFileOutputGroupTaskIdKeyUpdate: FileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingFileOutputGroupTaskIdKeyUpdate;
  FileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingFileOutputGroupPkeyUpdate: FileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingFileOutputGroupPkeyUpdate;
  FileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingFileOutputGroupTaskIdKeyUpdate: FileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingFileOutputGroupTaskIdKeyUpdate;
  FileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingFileOutputGroupPkeyUpdate: FileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingFileOutputGroupPkeyUpdate;
  FileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingFileOutputGroupTaskIdKeyUpdate: FileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingFileOutputGroupTaskIdKeyUpdate;
  FileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpUsingFileOutputGroupPkeyUpdate: FileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpUsingFileOutputGroupPkeyUpdate;
  FileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpUsingFileOutputGroupTaskIdKeyUpdate: FileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpUsingFileOutputGroupTaskIdKeyUpdate;
  FileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpUsingFileOutputGroupPkeyUpdate: FileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpUsingFileOutputGroupPkeyUpdate;
  FileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpUsingFileOutputGroupTaskIdKeyUpdate: FileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpUsingFileOutputGroupTaskIdKeyUpdate;
  FileOutputGroupPatch: FileOutputGroupPatch;
  FileOutputGroupProjectId908Fb9FfFkProjectIdInput: FileOutputGroupProjectId908Fb9FfFkProjectIdInput;
  FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput: FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput;
  FileOutputGroupTaskId2B1Df6A1FkTaskIdInput: FileOutputGroupTaskId2B1Df6A1FkTaskIdInput;
  FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput: FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput;
  FileOutputGroupTaskId2B1Df6A1FkTaskIdTaskCreateInput: FileOutputGroupTaskId2B1Df6A1FkTaskIdTaskCreateInput;
  FileOutputGroupTenantId3Fa46D44FkTenantIdInput: FileOutputGroupTenantId3Fa46D44FkTenantIdInput;
  FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput: FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput;
  FileOutputGroupTenantId3Fa46D44FkTenantIdTenantCreateInput: FileOutputGroupTenantId3Fa46D44FkTenantIdTenantCreateInput;
  FileOutputGroupToManyFileOutputGroupMetadatumFilter: FileOutputGroupToManyFileOutputGroupMetadatumFilter;
  FileOutputGroupToManyOutputGroupFileFilter: FileOutputGroupToManyOutputGroupFileFilter;
  FileOutputGroupUserIdB22Ec436FkApplicationUserIdApplicationUserCreateInput: FileOutputGroupUserIdB22Ec436FkApplicationUserIdApplicationUserCreateInput;
  FileOutputGroupUserIdB22Ec436FkApplicationUserIdInput: FileOutputGroupUserIdB22Ec436FkApplicationUserIdInput;
  FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput: FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput;
  FileOutputGroupsConnection: ResolverTypeWrapper<FileOutputGroupsConnection>;
  FileOutputGroupsEdge: ResolverTypeWrapper<FileOutputGroupsEdge>;
  FileOutputGroupsOrderBy: FileOutputGroupsOrderBy;
  FilePatch: FilePatch;
  FileProcessingNotiUserId08265156FkApplicatiApplicationUserCreateInput: FileProcessingNotiUserId08265156FkApplicatiApplicationUserCreateInput;
  FileProcessingNotiUserId08265156FkApplicatiInput: FileProcessingNotiUserId08265156FkApplicatiInput;
  FileProcessingNotiUserId08265156FkApplicatiInverseInput: FileProcessingNotiUserId08265156FkApplicatiInverseInput;
  FileProcessingNotification: ResolverTypeWrapper<FileProcessingNotification>;
  FileProcessingNotificationCondition: FileProcessingNotificationCondition;
  FileProcessingNotificationFileId717B514EFkFileIdFileCreateInput: FileProcessingNotificationFileId717B514EFkFileIdFileCreateInput;
  FileProcessingNotificationFileId717B514EFkFileIdInput: FileProcessingNotificationFileId717B514EFkFileIdInput;
  FileProcessingNotificationFileId717B514EFkFileIdInverseInput: FileProcessingNotificationFileId717B514EFkFileIdInverseInput;
  FileProcessingNotificationFileProcessingNotificationPkeyConnect: FileProcessingNotificationFileProcessingNotificationPkeyConnect;
  FileProcessingNotificationFilter: FileProcessingNotificationFilter;
  FileProcessingNotificationId630E6495FkNotificationIdInput: FileProcessingNotificationId630E6495FkNotificationIdInput;
  FileProcessingNotificationId630E6495FkNotificationIdInverseInput: FileProcessingNotificationId630E6495FkNotificationIdInverseInput;
  FileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingFileProcessingNotificationPkeyUpdate: FileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingFileProcessingNotificationPkeyUpdate;
  FileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdUsingFileProcessingNotificationPkeyUpdate: FileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdUsingFileProcessingNotificationPkeyUpdate;
  FileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdUsingFileProcessingNotificationPkeyUpdate: FileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdUsingFileProcessingNotificationPkeyUpdate;
  FileProcessingNotificationsConnection: ResolverTypeWrapper<FileProcessingNotificationsConnection>;
  FileProcessingNotificationsEdge: ResolverTypeWrapper<FileProcessingNotificationsEdge>;
  FileProcessingNotificationsOrderBy: FileProcessingNotificationsOrderBy;
  FileProjectId3Ecca975FkProjectIdFileCreateInput: FileProjectId3Ecca975FkProjectIdFileCreateInput;
  FileProjectId3Ecca975FkProjectIdInput: FileProjectId3Ecca975FkProjectIdInput;
  FileProjectId3Ecca975FkProjectIdInverseInput: FileProjectId3Ecca975FkProjectIdInverseInput;
  FileRecordingGroup: ResolverTypeWrapper<FileRecordingGroup>;
  FileRecordingGroupCondition: FileRecordingGroupCondition;
  FileRecordingGroupDatasetRecordingsTB91F3991FkDatasetRDatasetRecordingsTableCreateInput: FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRDatasetRecordingsTableCreateInput;
  FileRecordingGroupDatasetRecordingsTB91F3991FkDatasetRFileRecordingGroupCreateInput: FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRFileRecordingGroupCreateInput;
  FileRecordingGroupDatasetRecordingsTB91F3991FkDatasetRInput: FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInput;
  FileRecordingGroupDatasetRecordingsTB91F3991FkDatasetRInverseInput: FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInverseInput;
  FileRecordingGroupFileRecordingGroup60583376FkFileRecoFileRecordingGroupCreateInput: FileRecordingGroupFileRecordingGroup60583376FkFileRecoFileRecordingGroupCreateInput;
  FileRecordingGroupFileRecordingGroup60583376FkFileRecoFileRecordingGroupMetadataCreateInput: FileRecordingGroupFileRecordingGroup60583376FkFileRecoFileRecordingGroupMetadataCreateInput;
  FileRecordingGroupFileRecordingGroup60583376FkFileRecoInput: FileRecordingGroupFileRecordingGroup60583376FkFileRecoInput;
  FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput: FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput;
  FileRecordingGroupFileRecordingGroupPkeyConnect: FileRecordingGroupFileRecordingGroupPkeyConnect;
  FileRecordingGroupFileRecordingGroupPkeyDelete: FileRecordingGroupFileRecordingGroupPkeyDelete;
  FileRecordingGroupFilter: FileRecordingGroupFilter;
  FileRecordingGroupInput: FileRecordingGroupInput;
  FileRecordingGroupMetadataConnection: ResolverTypeWrapper<FileRecordingGroupMetadataConnection>;
  FileRecordingGroupMetadataEdge: ResolverTypeWrapper<FileRecordingGroupMetadataEdge>;
  FileRecordingGroupMetadataIdF054E41AFkMetadataFileRecordingGroupMetadataCreateInput: FileRecordingGroupMetadataIdF054E41AFkMetadataFileRecordingGroupMetadataCreateInput;
  FileRecordingGroupMetadataIdF054E41AFkMetadataInput: FileRecordingGroupMetadataIdF054E41AFkMetadataInput;
  FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput: FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput;
  FileRecordingGroupMetadataIdF054E41AFkMetadataMetadataCreateInput: FileRecordingGroupMetadataIdF054E41AFkMetadataMetadataCreateInput;
  FileRecordingGroupMetadataOrderBy: FileRecordingGroupMetadataOrderBy;
  FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdFileRecordingGroupMetadataCreateInput: FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdFileRecordingGroupMetadataCreateInput;
  FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInput: FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInput;
  FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput: FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput;
  FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdFileRecordingGroupMetadataCreateInput: FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdFileRecordingGroupMetadataCreateInput;
  FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInput: FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInput;
  FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput: FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput;
  FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdTenantCreateInput: FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdTenantCreateInput;
  FileRecordingGroupMetadatum: ResolverTypeWrapper<FileRecordingGroupMetadatum>;
  FileRecordingGroupMetadatumCondition: FileRecordingGroupMetadatumCondition;
  FileRecordingGroupMetadatumFileRecordingGroupMetadataPkeyConnect: FileRecordingGroupMetadatumFileRecordingGroupMetadataPkeyConnect;
  FileRecordingGroupMetadatumFileRecordingGroupMetadataPkeyDelete: FileRecordingGroupMetadatumFileRecordingGroupMetadataPkeyDelete;
  FileRecordingGroupMetadatumFilter: FileRecordingGroupMetadatumFilter;
  FileRecordingGroupMetadatumInput: FileRecordingGroupMetadatumInput;
  FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoUsingFileRecordingGroupMetadataPkeyUpdate: FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoUsingFileRecordingGroupMetadataPkeyUpdate;
  FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataUsingFileRecordingGroupMetadataPkeyUpdate: FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataUsingFileRecordingGroupMetadataPkeyUpdate;
  FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdUsingFileRecordingGroupMetadataPkeyUpdate: FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdUsingFileRecordingGroupMetadataPkeyUpdate;
  FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingFileRecordingGroupMetadataPkeyUpdate: FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingFileRecordingGroupMetadataPkeyUpdate;
  FileRecordingGroupMetadatumPatch: FileRecordingGroupMetadatumPatch;
  FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTB91F3991FkDatasetRUsingFileRecordingGroupPkeyUpdate: FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRUsingFileRecordingGroupPkeyUpdate;
  FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdUsingFileRecordingGroupPkeyUpdate: FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdUsingFileRecordingGroupPkeyUpdate;
  FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingFileRecordingGroupPkeyUpdate: FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingFileRecordingGroupPkeyUpdate;
  FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingFileRecordingGroupPkeyUpdate: FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingFileRecordingGroupPkeyUpdate;
  FileRecordingGroupOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoUsingFileRecordingGroupPkeyUpdate: FileRecordingGroupOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoUsingFileRecordingGroupPkeyUpdate;
  FileRecordingGroupOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoUsingFileRecordingGroupPkeyUpdate: FileRecordingGroupOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoUsingFileRecordingGroupPkeyUpdate;
  FileRecordingGroupPatch: FileRecordingGroupPatch;
  FileRecordingGroupProjectIdB14Ba577FkProjectIdFileRecordingGroupCreateInput: FileRecordingGroupProjectIdB14Ba577FkProjectIdFileRecordingGroupCreateInput;
  FileRecordingGroupProjectIdB14Ba577FkProjectIdInput: FileRecordingGroupProjectIdB14Ba577FkProjectIdInput;
  FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput: FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput;
  FileRecordingGroupTenantIdCbdec6BcFkTenantIdFileRecordingGroupCreateInput: FileRecordingGroupTenantIdCbdec6BcFkTenantIdFileRecordingGroupCreateInput;
  FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput: FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput;
  FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput: FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput;
  FileRecordingGroupTenantIdCbdec6BcFkTenantIdTenantCreateInput: FileRecordingGroupTenantIdCbdec6BcFkTenantIdTenantCreateInput;
  FileRecordingGroupToManyFileRecordingGroupMetadatumFilter: FileRecordingGroupToManyFileRecordingGroupMetadatumFilter;
  FileRecordingGroupToManyRecordingGroupFileFilter: FileRecordingGroupToManyRecordingGroupFileFilter;
  FileRecordingGroupUserId421A5CddFkApplicationUserIdApplicationUserCreateInput: FileRecordingGroupUserId421A5CddFkApplicationUserIdApplicationUserCreateInput;
  FileRecordingGroupUserId421A5CddFkApplicationUserIdFileRecordingGroupCreateInput: FileRecordingGroupUserId421A5CddFkApplicationUserIdFileRecordingGroupCreateInput;
  FileRecordingGroupUserId421A5CddFkApplicationUserIdInput: FileRecordingGroupUserId421A5CddFkApplicationUserIdInput;
  FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput: FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput;
  FileRecordingGroupsConnection: ResolverTypeWrapper<FileRecordingGroupsConnection>;
  FileRecordingGroupsEdge: ResolverTypeWrapper<FileRecordingGroupsEdge>;
  FileRecordingGroupsOrderBy: FileRecordingGroupsOrderBy;
  FileSeriesParentIdF7A07E20FkFileIdFileCreateInput: FileSeriesParentIdF7A07E20FkFileIdFileCreateInput;
  FileSeriesParentIdF7A07E20FkFileIdInput: FileSeriesParentIdF7A07E20FkFileIdInput;
  FileSource: FileSource;
  FileTenantId61625016FkTenantIdFileCreateInput: FileTenantId61625016FkTenantIdFileCreateInput;
  FileTenantId61625016FkTenantIdInput: FileTenantId61625016FkTenantIdInput;
  FileTenantId61625016FkTenantIdInverseInput: FileTenantId61625016FkTenantIdInverseInput;
  FileTenantId61625016FkTenantIdTenantCreateInput: FileTenantId61625016FkTenantIdTenantCreateInput;
  FileToManyFileActivityFilter: FileToManyFileActivityFilter;
  FileToManyFileFilter: FileToManyFileFilter;
  FileToManyFileMetadatumFilter: FileToManyFileMetadatumFilter;
  FileToManyFileProcessingNotificationFilter: FileToManyFileProcessingNotificationFilter;
  FileToManyOutputGroupFileFilter: FileToManyOutputGroupFileFilter;
  FileToManyRecordingGroupFileFilter: FileToManyRecordingGroupFileFilter;
  FileToManyTaskSourceFilter: FileToManyTaskSourceFilter;
  FileUserId01D90B53FkApplicationUserIdApplicationUserCreateInput: FileUserId01D90B53FkApplicationUserIdApplicationUserCreateInput;
  FileUserId01D90B53FkApplicationUserIdFileCreateInput: FileUserId01D90B53FkApplicationUserIdFileCreateInput;
  FileUserId01D90B53FkApplicationUserIdInput: FileUserId01D90B53FkApplicationUserIdInput;
  FileUserId01D90B53FkApplicationUserIdInverseInput: FileUserId01D90B53FkApplicationUserIdInverseInput;
  FilesConnection: ResolverTypeWrapper<FilesConnection>;
  FilesEdge: ResolverTypeWrapper<FilesEdge>;
  FilesOrderBy: FilesOrderBy;
  Float: ResolverTypeWrapper<Scalars["Float"]["output"]>;
  FloatFilter: FloatFilter;
  ID: ResolverTypeWrapper<Scalars["ID"]["output"]>;
  Int: ResolverTypeWrapper<Scalars["Int"]["output"]>;
  IntFilter: IntFilter;
  Interval: ResolverTypeWrapper<Interval>;
  IntervalFilter: IntervalFilter;
  IntervalInput: IntervalInput;
  JSON: ResolverTypeWrapper<Scalars["JSON"]["output"]>;
  JSONFilter: JsonFilter;
  MetadataConnection: ResolverTypeWrapper<MetadataConnection>;
  MetadataEdge: ResolverTypeWrapper<MetadataEdge>;
  MetadataOrderBy: MetadataOrderBy;
  MetadataProjectId3333C2AdFkProjectIdInput: MetadataProjectId3333C2AdFkProjectIdInput;
  MetadataProjectId3333C2AdFkProjectIdInverseInput: MetadataProjectId3333C2AdFkProjectIdInverseInput;
  MetadataProjectId3333C2AdFkProjectIdMetadataCreateInput: MetadataProjectId3333C2AdFkProjectIdMetadataCreateInput;
  MetadataV4TenantId163B6569FkTenantIdInput: MetadataV4TenantId163B6569FkTenantIdInput;
  MetadataV4TenantId163B6569FkTenantIdInverseInput: MetadataV4TenantId163B6569FkTenantIdInverseInput;
  MetadataV4TenantId163B6569FkTenantIdMetadataCreateInput: MetadataV4TenantId163B6569FkTenantIdMetadataCreateInput;
  MetadataV4TenantId163B6569FkTenantIdTenantCreateInput: MetadataV4TenantId163B6569FkTenantIdTenantCreateInput;
  MetadataValue: ResolverTypeWrapper<MetadataValue>;
  MetadataValueCondition: MetadataValueCondition;
  MetadataValueFilter: MetadataValueFilter;
  MetadataValueInput: MetadataValueInput;
  MetadataValueMetadataId063A53B6FkMetadataV4IdInput: MetadataValueMetadataId063A53B6FkMetadataV4IdInput;
  MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput: MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput;
  MetadataValueMetadataId063A53B6FkMetadataV4IdMetadataCreateInput: MetadataValueMetadataId063A53B6FkMetadataV4IdMetadataCreateInput;
  MetadataValueMetadataId063A53B6FkMetadataV4IdMetadataValueCreateInput: MetadataValueMetadataId063A53B6FkMetadataV4IdMetadataValueCreateInput;
  MetadataValueMetadataValuePkeyConnect: MetadataValueMetadataValuePkeyConnect;
  MetadataValueMetadataValuePkeyDelete: MetadataValueMetadataValuePkeyDelete;
  MetadataValueOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdUsingMetadataValuePkeyUpdate: MetadataValueOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdUsingMetadataValuePkeyUpdate;
  MetadataValueOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdUsingMetadataValuePkeyUpdate: MetadataValueOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdUsingMetadataValuePkeyUpdate;
  MetadataValueOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdUsingMetadataValuePkeyUpdate: MetadataValueOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdUsingMetadataValuePkeyUpdate;
  MetadataValueOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingMetadataValuePkeyUpdate: MetadataValueOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingMetadataValuePkeyUpdate;
  MetadataValueOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingMetadataValuePkeyUpdate: MetadataValueOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingMetadataValuePkeyUpdate;
  MetadataValuePatch: MetadataValuePatch;
  MetadataValueProjectId87227D96FkProjectIdInput: MetadataValueProjectId87227D96FkProjectIdInput;
  MetadataValueProjectId87227D96FkProjectIdInverseInput: MetadataValueProjectId87227D96FkProjectIdInverseInput;
  MetadataValueProjectId87227D96FkProjectIdMetadataValueCreateInput: MetadataValueProjectId87227D96FkProjectIdMetadataValueCreateInput;
  MetadataValueTaskId3D37D135FkTaskIdInput: MetadataValueTaskId3D37D135FkTaskIdInput;
  MetadataValueTaskId3D37D135FkTaskIdInverseInput: MetadataValueTaskId3D37D135FkTaskIdInverseInput;
  MetadataValueTaskId3D37D135FkTaskIdMetadataValueCreateInput: MetadataValueTaskId3D37D135FkTaskIdMetadataValueCreateInput;
  MetadataValueTaskId3D37D135FkTaskIdTaskCreateInput: MetadataValueTaskId3D37D135FkTaskIdTaskCreateInput;
  MetadataValueTenantIdB4C2D5D6FkTenantIdInput: MetadataValueTenantIdB4C2D5D6FkTenantIdInput;
  MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput: MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput;
  MetadataValueTenantIdB4C2D5D6FkTenantIdMetadataValueCreateInput: MetadataValueTenantIdB4C2D5D6FkTenantIdMetadataValueCreateInput;
  MetadataValueTenantIdB4C2D5D6FkTenantIdTenantCreateInput: MetadataValueTenantIdB4C2D5D6FkTenantIdTenantCreateInput;
  MetadataValueUserIdB2Bb45AeFkApplicationUserIdApplicationUserCreateInput: MetadataValueUserIdB2Bb45AeFkApplicationUserIdApplicationUserCreateInput;
  MetadataValueUserIdB2Bb45AeFkApplicationUserIdInput: MetadataValueUserIdB2Bb45AeFkApplicationUserIdInput;
  MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput: MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput;
  MetadataValueUserIdB2Bb45AeFkApplicationUserIdMetadataValueCreateInput: MetadataValueUserIdB2Bb45AeFkApplicationUserIdMetadataValueCreateInput;
  MetadataValuesConnection: ResolverTypeWrapper<MetadataValuesConnection>;
  MetadataValuesEdge: ResolverTypeWrapper<MetadataValuesEdge>;
  MetadataValuesOrderBy: MetadataValuesOrderBy;
  Metadatum: ResolverTypeWrapper<Metadatum>;
  MetadatumCondition: MetadatumCondition;
  MetadatumFilter: MetadatumFilter;
  MetadatumInput: MetadatumInput;
  MetadatumMetadataV4PkeyConnect: MetadatumMetadataV4PkeyConnect;
  MetadatumMetadataV4PkeyDelete: MetadatumMetadataV4PkeyDelete;
  MetadatumOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingMetadataV4PkeyUpdate: MetadatumOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingMetadataV4PkeyUpdate;
  MetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdUsingMetadataV4PkeyUpdate: MetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdUsingMetadataV4PkeyUpdate;
  MetadatumOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataUsingMetadataV4PkeyUpdate: MetadatumOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataUsingMetadataV4PkeyUpdate;
  MetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdUsingMetadataV4PkeyUpdate: MetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdUsingMetadataV4PkeyUpdate;
  MetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataUsingMetadataV4PkeyUpdate: MetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataUsingMetadataV4PkeyUpdate;
  MetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataUsingMetadataV4PkeyUpdate: MetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataUsingMetadataV4PkeyUpdate;
  MetadatumOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdUsingMetadataV4PkeyUpdate: MetadatumOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdUsingMetadataV4PkeyUpdate;
  MetadatumOnMetadatumForMetadataProjectId3333C2AdFkProjectIdUsingMetadataV4PkeyUpdate: MetadatumOnMetadatumForMetadataProjectId3333C2AdFkProjectIdUsingMetadataV4PkeyUpdate;
  MetadatumOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingMetadataV4PkeyUpdate: MetadatumOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingMetadataV4PkeyUpdate;
  MetadatumPatch: MetadatumPatch;
  MetadatumToManyDatasetFilter: MetadatumToManyDatasetFilter;
  MetadatumToManyDatasetMetadatumFilter: MetadatumToManyDatasetMetadatumFilter;
  MetadatumToManyDatasetRecordingsTableColumnFilter: MetadatumToManyDatasetRecordingsTableColumnFilter;
  MetadatumToManyFileMetadatumFilter: MetadatumToManyFileMetadatumFilter;
  MetadatumToManyFileOutputGroupMetadatumFilter: MetadatumToManyFileOutputGroupMetadatumFilter;
  MetadatumToManyFileRecordingGroupMetadatumFilter: MetadatumToManyFileRecordingGroupMetadatumFilter;
  MetadatumToManyMetadataValueFilter: MetadatumToManyMetadataValueFilter;
  Mutation: ResolverTypeWrapper<{}>;
  Notification: ResolverTypeWrapper<Notification>;
  NotificationCondition: NotificationCondition;
  NotificationFilter: NotificationFilter;
  NotificationNotificationPkeyConnect: NotificationNotificationPkeyConnect;
  NotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdUsingNotificationPkeyUpdate: NotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdUsingNotificationPkeyUpdate;
  NotificationOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingNotificationPkeyUpdate: NotificationOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingNotificationPkeyUpdate;
  NotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdUsingNotificationPkeyUpdate: NotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdUsingNotificationPkeyUpdate;
  NotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdUsingNotificationPkeyUpdate: NotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdUsingNotificationPkeyUpdate;
  NotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdUsingNotificationPkeyUpdate: NotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdUsingNotificationPkeyUpdate;
  NotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatUsingNotificationPkeyUpdate: NotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatUsingNotificationPkeyUpdate;
  NotificationPatch: NotificationPatch;
  NotificationUserId1002Fc38FkApplicationUserIdApplicationUserCreateInput: NotificationUserId1002Fc38FkApplicationUserIdApplicationUserCreateInput;
  NotificationUserId1002Fc38FkApplicationUserIdInput: NotificationUserId1002Fc38FkApplicationUserIdInput;
  NotificationUserId1002Fc38FkApplicationUserIdInverseInput: NotificationUserId1002Fc38FkApplicationUserIdInverseInput;
  NotificationsConnection: ResolverTypeWrapper<NotificationsConnection>;
  NotificationsEdge: ResolverTypeWrapper<NotificationsEdge>;
  NotificationsOrderBy: NotificationsOrderBy;
  OutputGroupFile: ResolverTypeWrapper<OutputGroupFile>;
  OutputGroupFileCondition: OutputGroupFileCondition;
  OutputGroupFileFilter: OutputGroupFileFilter;
  OutputGroupFileInput: OutputGroupFileInput;
  OutputGroupFileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdUsingOutputGroupFilesPkeyUpdate: OutputGroupFileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdUsingOutputGroupFilesPkeyUpdate;
  OutputGroupFileOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpUsingOutputGroupFilesPkeyUpdate: OutputGroupFileOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpUsingOutputGroupFilesPkeyUpdate;
  OutputGroupFileOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdUsingOutputGroupFilesPkeyUpdate: OutputGroupFileOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdUsingOutputGroupFilesPkeyUpdate;
  OutputGroupFileOutputGroupFilesPkeyConnect: OutputGroupFileOutputGroupFilesPkeyConnect;
  OutputGroupFileOutputGroupFilesPkeyDelete: OutputGroupFileOutputGroupFilesPkeyDelete;
  OutputGroupFilePatch: OutputGroupFilePatch;
  OutputGroupFilesConnection: ResolverTypeWrapper<OutputGroupFilesConnection>;
  OutputGroupFilesEdge: ResolverTypeWrapper<OutputGroupFilesEdge>;
  OutputGroupFilesFileIdEdeabb23FkFileIdFileCreateInput: OutputGroupFilesFileIdEdeabb23FkFileIdFileCreateInput;
  OutputGroupFilesFileIdEdeabb23FkFileIdInput: OutputGroupFilesFileIdEdeabb23FkFileIdInput;
  OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput: OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput;
  OutputGroupFilesFileIdEdeabb23FkFileIdOutputGroupFilesCreateInput: OutputGroupFilesFileIdEdeabb23FkFileIdOutputGroupFilesCreateInput;
  OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInput: OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInput;
  OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInverseInput: OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInverseInput;
  OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpOutputGroupFilesCreateInput: OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpOutputGroupFilesCreateInput;
  OutputGroupFilesOrderBy: OutputGroupFilesOrderBy;
  OutputGroupFilesProjectId8709B561FkProjectIdInput: OutputGroupFilesProjectId8709B561FkProjectIdInput;
  OutputGroupFilesProjectId8709B561FkProjectIdInverseInput: OutputGroupFilesProjectId8709B561FkProjectIdInverseInput;
  OutputGroupFilesProjectId8709B561FkProjectIdOutputGroupFilesCreateInput: OutputGroupFilesProjectId8709B561FkProjectIdOutputGroupFilesCreateInput;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  Project: ResolverTypeWrapper<Project>;
  ProjectCloneSourceId5F0B62C5FkProjectIdInput: ProjectCloneSourceId5F0B62C5FkProjectIdInput;
  ProjectClonedNotifUserId4Fd693D7FkApplicatiApplicationUserCreateInput: ProjectClonedNotifUserId4Fd693D7FkApplicatiApplicationUserCreateInput;
  ProjectClonedNotifUserId4Fd693D7FkApplicatiInput: ProjectClonedNotifUserId4Fd693D7FkApplicatiInput;
  ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput: ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput;
  ProjectClonedNotification: ResolverTypeWrapper<ProjectClonedNotification>;
  ProjectClonedNotificationCondition: ProjectClonedNotificationCondition;
  ProjectClonedNotificationFilter: ProjectClonedNotificationFilter;
  ProjectClonedNotificationIdD235A018FkNotificationIdInput: ProjectClonedNotificationIdD235A018FkNotificationIdInput;
  ProjectClonedNotificationIdD235A018FkNotificationIdInverseInput: ProjectClonedNotificationIdD235A018FkNotificationIdInverseInput;
  ProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingProjectClonedNotificationPkeyUpdate: ProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingProjectClonedNotificationPkeyUpdate;
  ProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdUsingProjectClonedNotificationPkeyUpdate: ProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdUsingProjectClonedNotificationPkeyUpdate;
  ProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdUsingProjectClonedNotificationPkeyUpdate: ProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdUsingProjectClonedNotificationPkeyUpdate;
  ProjectClonedNotificationProjectClonedNotificationPkeyConnect: ProjectClonedNotificationProjectClonedNotificationPkeyConnect;
  ProjectClonedNotificationProjectIdF6267374FkProjectIdInput: ProjectClonedNotificationProjectIdF6267374FkProjectIdInput;
  ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput: ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput;
  ProjectClonedNotificationsConnection: ResolverTypeWrapper<ProjectClonedNotificationsConnection>;
  ProjectClonedNotificationsEdge: ResolverTypeWrapper<ProjectClonedNotificationsEdge>;
  ProjectClonedNotificationsOrderBy: ProjectClonedNotificationsOrderBy;
  ProjectCondition: ProjectCondition;
  ProjectFilter: ProjectFilter;
  ProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdUsingProjectPkeyUpdate: ProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdUsingProjectPkeyUpdate: ProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIUsingProjectKeyTenantUniqueUpdate: ProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIUsingProjectKeyTenantUniqueUpdate;
  ProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIUsingProjectPkeyUpdate: ProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIUsingProjectPkeyUpdate;
  ProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingProjectPkeyUpdate: ProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingProjectPkeyUpdate: ProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdUsingProjectPkeyUpdate: ProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdUsingProjectPkeyUpdate;
  ProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIUsingProjectKeyTenantUniqueUpdate: ProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIUsingProjectKeyTenantUniqueUpdate;
  ProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIUsingProjectPkeyUpdate: ProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIUsingProjectPkeyUpdate;
  ProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIUsingProjectKeyTenantUniqueUpdate: ProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIUsingProjectKeyTenantUniqueUpdate;
  ProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIUsingProjectPkeyUpdate: ProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIUsingProjectPkeyUpdate;
  ProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdUsingProjectPkeyUpdate: ProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdUsingProjectPkeyUpdate: ProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdUsingProjectPkeyUpdate;
  ProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdUsingProjectPkeyUpdate: ProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdUsingProjectPkeyUpdate;
  ProjectOnFileForFileProjectId3Ecca975FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnFileForFileProjectId3Ecca975FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnFileForFileProjectId3Ecca975FkProjectIdUsingProjectPkeyUpdate: ProjectOnFileForFileProjectId3Ecca975FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdUsingProjectPkeyUpdate: ProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdUsingProjectPkeyUpdate;
  ProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingProjectPkeyUpdate: ProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingProjectPkeyUpdate;
  ProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdUsingProjectPkeyUpdate: ProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdUsingProjectPkeyUpdate: ProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdUsingProjectPkeyUpdate: ProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdUsingProjectPkeyUpdate: ProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdUsingProjectPkeyUpdate: ProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdUsingProjectPkeyUpdate;
  ProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdUsingProjectPkeyUpdate: ProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdUsingProjectPkeyUpdate: ProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingProjectKeyTenantUniqueUpdate: ProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingProjectPkeyUpdate: ProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingProjectPkeyUpdate;
  ProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingProjectKeyTenantUniqueUpdate: ProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingProjectPkeyUpdate: ProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingProjectPkeyUpdate;
  ProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdUsingProjectPkeyUpdate: ProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdUsingProjectPkeyUpdate;
  ProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdUsingProjectPkeyUpdate: ProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnTaskForTaskProjectId963D6354FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnTaskForTaskProjectId963D6354FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnTaskForTaskProjectId963D6354FkProjectIdUsingProjectPkeyUpdate: ProjectOnTaskForTaskProjectId963D6354FkProjectIdUsingProjectPkeyUpdate;
  ProjectProjectKeyTenantUniqueConnect: ProjectProjectKeyTenantUniqueConnect;
  ProjectProjectPkeyConnect: ProjectProjectPkeyConnect;
  ProjectSharedNotifSharerIdAbe16C5BFkApplicatiApplicationUserCreateInput: ProjectSharedNotifSharerIdAbe16C5BFkApplicatiApplicationUserCreateInput;
  ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInput: ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInput;
  ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput: ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput;
  ProjectSharedNotifUserId9A19122FFkApplicatiApplicationUserCreateInput: ProjectSharedNotifUserId9A19122FFkApplicatiApplicationUserCreateInput;
  ProjectSharedNotifUserId9A19122FFkApplicatiInput: ProjectSharedNotifUserId9A19122FFkApplicatiInput;
  ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput: ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput;
  ProjectSharedNotification: ResolverTypeWrapper<ProjectSharedNotification>;
  ProjectSharedNotificationCondition: ProjectSharedNotificationCondition;
  ProjectSharedNotificationFilter: ProjectSharedNotificationFilter;
  ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInput: ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInput;
  ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInverseInput: ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInverseInput;
  ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingProjectSharedNotificationPkeyUpdate: ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingProjectSharedNotificationPkeyUpdate;
  ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingProjectSharedNotificationPkeyUpdate: ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingProjectSharedNotificationPkeyUpdate;
  ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdUsingProjectSharedNotificationPkeyUpdate: ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdUsingProjectSharedNotificationPkeyUpdate;
  ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdUsingProjectSharedNotificationPkeyUpdate: ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdUsingProjectSharedNotificationPkeyUpdate;
  ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInput: ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInput;
  ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput: ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput;
  ProjectSharedNotificationProjectSharedNotificationPkeyConnect: ProjectSharedNotificationProjectSharedNotificationPkeyConnect;
  ProjectSharedNotificationsConnection: ResolverTypeWrapper<ProjectSharedNotificationsConnection>;
  ProjectSharedNotificationsEdge: ResolverTypeWrapper<ProjectSharedNotificationsEdge>;
  ProjectSharedNotificationsOrderBy: ProjectSharedNotificationsOrderBy;
  ProjectTenantId03F0Ca53FkTenantIdInput: ProjectTenantId03F0Ca53FkTenantIdInput;
  ProjectTenantId03F0Ca53FkTenantIdInverseInput: ProjectTenantId03F0Ca53FkTenantIdInverseInput;
  ProjectTenantId03F0Ca53FkTenantIdTenantCreateInput: ProjectTenantId03F0Ca53FkTenantIdTenantCreateInput;
  ProjectToManyAnalysisTableFilter: ProjectToManyAnalysisTableFilter;
  ProjectToManyAnalysisTableGroupFilter: ProjectToManyAnalysisTableGroupFilter;
  ProjectToManyAnalysisTableRowAttachResultFilter: ProjectToManyAnalysisTableRowAttachResultFilter;
  ProjectToManyAnalysisTableRowFilter: ProjectToManyAnalysisTableRowFilter;
  ProjectToManyDatasetFilter: ProjectToManyDatasetFilter;
  ProjectToManyDatasetMetadatumFilter: ProjectToManyDatasetMetadatumFilter;
  ProjectToManyDatasetRecordingsTableColumnFilter: ProjectToManyDatasetRecordingsTableColumnFilter;
  ProjectToManyDatasetRecordingsTableColumnOrderFilter: ProjectToManyDatasetRecordingsTableColumnOrderFilter;
  ProjectToManyDatasetRecordingsTableFilter: ProjectToManyDatasetRecordingsTableFilter;
  ProjectToManyDatasetVersionFilter: ProjectToManyDatasetVersionFilter;
  ProjectToManyFileActivityFilter: ProjectToManyFileActivityFilter;
  ProjectToManyFileFilter: ProjectToManyFileFilter;
  ProjectToManyFileMetadatumFilter: ProjectToManyFileMetadatumFilter;
  ProjectToManyFileOutputGroupFilter: ProjectToManyFileOutputGroupFilter;
  ProjectToManyFileOutputGroupMetadatumFilter: ProjectToManyFileOutputGroupMetadatumFilter;
  ProjectToManyFileRecordingGroupFilter: ProjectToManyFileRecordingGroupFilter;
  ProjectToManyFileRecordingGroupMetadatumFilter: ProjectToManyFileRecordingGroupMetadatumFilter;
  ProjectToManyMetadataValueFilter: ProjectToManyMetadataValueFilter;
  ProjectToManyMetadatumFilter: ProjectToManyMetadatumFilter;
  ProjectToManyOutputGroupFileFilter: ProjectToManyOutputGroupFileFilter;
  ProjectToManyProjectClonedNotificationFilter: ProjectToManyProjectClonedNotificationFilter;
  ProjectToManyProjectSharedNotificationFilter: ProjectToManyProjectSharedNotificationFilter;
  ProjectToManyRecordingGroupFileFilter: ProjectToManyRecordingGroupFileFilter;
  ProjectToManyTaskFilter: ProjectToManyTaskFilter;
  ProjectUserIdB5Fbb914FkApplicationUserIdApplicationUserCreateInput: ProjectUserIdB5Fbb914FkApplicationUserIdApplicationUserCreateInput;
  ProjectUserIdB5Fbb914FkApplicationUserIdInput: ProjectUserIdB5Fbb914FkApplicationUserIdInput;
  ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput: ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput;
  ProjectsConnection: ResolverTypeWrapper<ProjectsConnection>;
  ProjectsEdge: ResolverTypeWrapper<ProjectsEdge>;
  ProjectsOrderBy: ProjectsOrderBy;
  Query: ResolverTypeWrapper<{}>;
  RecordingGroupFile: ResolverTypeWrapper<RecordingGroupFile>;
  RecordingGroupFileCondition: RecordingGroupFileCondition;
  RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRDatasetRecordingsTableColumnCreateInput: RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRDatasetRecordingsTableColumnCreateInput;
  RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput: RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput;
  RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput: RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput;
  RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRRecordingGroupFilesCreateInput: RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRRecordingGroupFilesCreateInput;
  RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoFileRecordingGroupCreateInput: RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoFileRecordingGroupCreateInput;
  RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput: RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput;
  RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput: RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput;
  RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoRecordingGroupFilesCreateInput: RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoRecordingGroupFilesCreateInput;
  RecordingGroupFileFilter: RecordingGroupFileFilter;
  RecordingGroupFileInput: RecordingGroupFileInput;
  RecordingGroupFileOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRUsingRecordingGroupFilesPkeyUpdate: RecordingGroupFileOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRUsingRecordingGroupFilesPkeyUpdate;
  RecordingGroupFileOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoUsingRecordingGroupFilesPkeyUpdate: RecordingGroupFileOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoUsingRecordingGroupFilesPkeyUpdate;
  RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdUsingRecordingGroupFilesPkeyUpdate: RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdUsingRecordingGroupFilesPkeyUpdate;
  RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdUsingRecordingGroupFilesPkeyUpdate: RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdUsingRecordingGroupFilesPkeyUpdate;
  RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingRecordingGroupFilesPkeyUpdate: RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingRecordingGroupFilesPkeyUpdate;
  RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingRecordingGroupFilesPkeyUpdate: RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingRecordingGroupFilesPkeyUpdate;
  RecordingGroupFilePatch: RecordingGroupFilePatch;
  RecordingGroupFileRecordingGroupFilesPkeyConnect: RecordingGroupFileRecordingGroupFilesPkeyConnect;
  RecordingGroupFileRecordingGroupFilesPkeyDelete: RecordingGroupFileRecordingGroupFilesPkeyDelete;
  RecordingGroupFilesConnection: ResolverTypeWrapper<RecordingGroupFilesConnection>;
  RecordingGroupFilesEdge: ResolverTypeWrapper<RecordingGroupFilesEdge>;
  RecordingGroupFilesFileIdD657E9EdFkFileIdFileCreateInput: RecordingGroupFilesFileIdD657E9EdFkFileIdFileCreateInput;
  RecordingGroupFilesFileIdD657E9EdFkFileIdInput: RecordingGroupFilesFileIdD657E9EdFkFileIdInput;
  RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput: RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput;
  RecordingGroupFilesFileIdD657E9EdFkFileIdRecordingGroupFilesCreateInput: RecordingGroupFilesFileIdD657E9EdFkFileIdRecordingGroupFilesCreateInput;
  RecordingGroupFilesOrderBy: RecordingGroupFilesOrderBy;
  RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput: RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput;
  RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput: RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput;
  RecordingGroupFilesProjectId3B6B53B3FkProjectIdRecordingGroupFilesCreateInput: RecordingGroupFilesProjectId3B6B53B3FkProjectIdRecordingGroupFilesCreateInput;
  RecordingGroupFilesTenantId637C3013FkTenantIdInput: RecordingGroupFilesTenantId637C3013FkTenantIdInput;
  RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput: RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput;
  RecordingGroupFilesTenantId637C3013FkTenantIdRecordingGroupFilesCreateInput: RecordingGroupFilesTenantId637C3013FkTenantIdRecordingGroupFilesCreateInput;
  RecordingGroupFilesTenantId637C3013FkTenantIdTenantCreateInput: RecordingGroupFilesTenantId637C3013FkTenantIdTenantCreateInput;
  RecordingGroupFilesUserId99A36997FkApplicationUserIdApplicationUserCreateInput: RecordingGroupFilesUserId99A36997FkApplicationUserIdApplicationUserCreateInput;
  RecordingGroupFilesUserId99A36997FkApplicationUserIdInput: RecordingGroupFilesUserId99A36997FkApplicationUserIdInput;
  RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput: RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput;
  RecordingGroupFilesUserId99A36997FkApplicationUserIdRecordingGroupFilesCreateInput: RecordingGroupFilesUserId99A36997FkApplicationUserIdRecordingGroupFilesCreateInput;
  RoleChangeNotificaUserId879Bd49AFkApplicatiApplicationUserCreateInput: RoleChangeNotificaUserId879Bd49AFkApplicatiApplicationUserCreateInput;
  RoleChangeNotificaUserId879Bd49AFkApplicatiInput: RoleChangeNotificaUserId879Bd49AFkApplicatiInput;
  RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput: RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput;
  RoleChangeNotification: ResolverTypeWrapper<RoleChangeNotification>;
  RoleChangeNotificationCondition: RoleChangeNotificationCondition;
  RoleChangeNotificationFilter: RoleChangeNotificationFilter;
  RoleChangeNotificationIdD576A528FkNotificationIdInput: RoleChangeNotificationIdD576A528FkNotificationIdInput;
  RoleChangeNotificationIdD576A528FkNotificationIdInverseInput: RoleChangeNotificationIdD576A528FkNotificationIdInverseInput;
  RoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingRoleChangeNotificationPkeyUpdate: RoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingRoleChangeNotificationPkeyUpdate;
  RoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdUsingRoleChangeNotificationPkeyUpdate: RoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdUsingRoleChangeNotificationPkeyUpdate;
  RoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingRoleChangeNotificationPkeyUpdate: RoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingRoleChangeNotificationPkeyUpdate;
  RoleChangeNotificationRoleChangeNotificationPkeyConnect: RoleChangeNotificationRoleChangeNotificationPkeyConnect;
  RoleChangeNotificationTenantId36852Ce9FkTenantIdInput: RoleChangeNotificationTenantId36852Ce9FkTenantIdInput;
  RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput: RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput;
  RoleChangeNotificationTenantId36852Ce9FkTenantIdTenantCreateInput: RoleChangeNotificationTenantId36852Ce9FkTenantIdTenantCreateInput;
  RoleChangeNotificationsConnection: ResolverTypeWrapper<RoleChangeNotificationsConnection>;
  RoleChangeNotificationsEdge: ResolverTypeWrapper<RoleChangeNotificationsEdge>;
  RoleChangeNotificationsOrderBy: RoleChangeNotificationsOrderBy;
  Run: ResolverTypeWrapper<Run>;
  RunCondition: RunCondition;
  RunFilter: RunFilter;
  RunInput: RunInput;
  RunOnRunForRunTenantId73Fbd8D9FkTenantIdUsingRunPkeyUpdate: RunOnRunForRunTenantId73Fbd8D9FkTenantIdUsingRunPkeyUpdate;
  RunPatch: RunPatch;
  RunRunPkeyConnect: RunRunPkeyConnect;
  RunRunPkeyDelete: RunRunPkeyDelete;
  RunTenantId73Fbd8D9FkTenantIdInput: RunTenantId73Fbd8D9FkTenantIdInput;
  RunTenantId73Fbd8D9FkTenantIdInverseInput: RunTenantId73Fbd8D9FkTenantIdInverseInput;
  RunTenantId73Fbd8D9FkTenantIdRunCreateInput: RunTenantId73Fbd8D9FkTenantIdRunCreateInput;
  RunTenantId73Fbd8D9FkTenantIdTenantCreateInput: RunTenantId73Fbd8D9FkTenantIdTenantCreateInput;
  RunsConnection: ResolverTypeWrapper<RunsConnection>;
  RunsEdge: ResolverTypeWrapper<RunsEdge>;
  RunsOrderBy: RunsOrderBy;
  String: ResolverTypeWrapper<Scalars["String"]["output"]>;
  StringFilter: StringFilter;
  Subscription: ResolverTypeWrapper<{}>;
  Tag: ResolverTypeWrapper<Tag>;
  TagCondition: TagCondition;
  TagFilter: TagFilter;
  TagInput: TagInput;
  TagOnTagForTagParentIdD2A3Ee5EFkTagIdUsingTagPkeyUpdate: TagOnTagForTagParentIdD2A3Ee5EFkTagIdUsingTagPkeyUpdate;
  TagOnTagForTagTenantId8072B61FFkTenantIdUsingTagPkeyUpdate: TagOnTagForTagTenantId8072B61FFkTenantIdUsingTagPkeyUpdate;
  TagOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingTagPkeyUpdate: TagOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingTagPkeyUpdate;
  TagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdUsingTagPkeyUpdate: TagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdUsingTagPkeyUpdate;
  TagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdUsingTagPkeyUpdate: TagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdUsingTagPkeyUpdate;
  TagParentIdD2A3Ee5EFkTagIdInput: TagParentIdD2A3Ee5EFkTagIdInput;
  TagParentIdD2A3Ee5EFkTagIdTagCreateInput: TagParentIdD2A3Ee5EFkTagIdTagCreateInput;
  TagPatch: TagPatch;
  TagTagPkeyConnect: TagTagPkeyConnect;
  TagTagPkeyDelete: TagTagPkeyDelete;
  TagTenantId8072B61FFkTenantIdInput: TagTenantId8072B61FFkTenantIdInput;
  TagTenantId8072B61FFkTenantIdInverseInput: TagTenantId8072B61FFkTenantIdInverseInput;
  TagTenantId8072B61FFkTenantIdTagCreateInput: TagTenantId8072B61FFkTenantIdTagCreateInput;
  TagTenantId8072B61FFkTenantIdTenantCreateInput: TagTenantId8072B61FFkTenantIdTenantCreateInput;
  TagToManyTagFilter: TagToManyTagFilter;
  TagToManyWorkflowResultTagFilter: TagToManyWorkflowResultTagFilter;
  TagToManyWorkflowSourceTagFilter: TagToManyWorkflowSourceTagFilter;
  TagUserIdB3B7Fb50FkApplicationUserIdApplicationUserCreateInput: TagUserIdB3B7Fb50FkApplicationUserIdApplicationUserCreateInput;
  TagUserIdB3B7Fb50FkApplicationUserIdInput: TagUserIdB3B7Fb50FkApplicationUserIdInput;
  TagUserIdB3B7Fb50FkApplicationUserIdInverseInput: TagUserIdB3B7Fb50FkApplicationUserIdInverseInput;
  TagUserIdB3B7Fb50FkApplicationUserIdTagCreateInput: TagUserIdB3B7Fb50FkApplicationUserIdTagCreateInput;
  TagsConnection: ResolverTypeWrapper<TagsConnection>;
  TagsEdge: ResolverTypeWrapper<TagsEdge>;
  TagsOrderBy: TagsOrderBy;
  Task: ResolverTypeWrapper<Task>;
  TaskActivitiesConnection: ResolverTypeWrapper<TaskActivitiesConnection>;
  TaskActivitiesEdge: ResolverTypeWrapper<TaskActivitiesEdge>;
  TaskActivitiesOrderBy: TaskActivitiesOrderBy;
  TaskActivity: ResolverTypeWrapper<TaskActivity>;
  TaskActivityCondition: TaskActivityCondition;
  TaskActivityCostId49E66058FkCostIdCostCreateInput: TaskActivityCostId49E66058FkCostIdCostCreateInput;
  TaskActivityCostId49E66058FkCostIdInput: TaskActivityCostId49E66058FkCostIdInput;
  TaskActivityCostId49E66058FkCostIdInverseInput: TaskActivityCostId49E66058FkCostIdInverseInput;
  TaskActivityCostId49E66058FkCostIdTaskActivityCreateInput: TaskActivityCostId49E66058FkCostIdTaskActivityCreateInput;
  TaskActivityFilter: TaskActivityFilter;
  TaskActivityInput: TaskActivityInput;
  TaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdUsingTaskActivityPkeyUpdate: TaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdUsingTaskActivityPkeyUpdate;
  TaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdUsingTaskActivityTaskIdKeyUpdate: TaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdUsingTaskActivityTaskIdKeyUpdate;
  TaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskActivityPkeyUpdate: TaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskActivityPkeyUpdate;
  TaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskActivityTaskIdKeyUpdate: TaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskActivityTaskIdKeyUpdate;
  TaskActivityPatch: TaskActivityPatch;
  TaskActivityTaskActivityPkeyConnect: TaskActivityTaskActivityPkeyConnect;
  TaskActivityTaskActivityPkeyDelete: TaskActivityTaskActivityPkeyDelete;
  TaskActivityTaskActivityTaskIdKeyConnect: TaskActivityTaskActivityTaskIdKeyConnect;
  TaskActivityTaskActivityTaskIdKeyDelete: TaskActivityTaskActivityTaskIdKeyDelete;
  TaskActivityTaskIdC451Ae92FkTaskIdInput: TaskActivityTaskIdC451Ae92FkTaskIdInput;
  TaskActivityTaskIdC451Ae92FkTaskIdInverseInput: TaskActivityTaskIdC451Ae92FkTaskIdInverseInput;
  TaskActivityTaskIdC451Ae92FkTaskIdTaskActivityCreateInput: TaskActivityTaskIdC451Ae92FkTaskIdTaskActivityCreateInput;
  TaskActivityTaskIdC451Ae92FkTaskIdTaskCreateInput: TaskActivityTaskIdC451Ae92FkTaskIdTaskCreateInput;
  TaskCondition: TaskCondition;
  TaskContainerImageId92Ed2DaeFkContainerImageIdContainerImageCreateInput: TaskContainerImageId92Ed2DaeFkContainerImageIdContainerImageCreateInput;
  TaskContainerImageId92Ed2DaeFkContainerImageIdInput: TaskContainerImageId92Ed2DaeFkContainerImageIdInput;
  TaskContainerImageId92Ed2DaeFkContainerImageIdInverseInput: TaskContainerImageId92Ed2DaeFkContainerImageIdInverseInput;
  TaskContainerImageId92Ed2DaeFkContainerImageIdTaskCreateInput: TaskContainerImageId92Ed2DaeFkContainerImageIdTaskCreateInput;
  TaskDatasetIdE15Afa5AFkDatasetIdDatasetCreateInput: TaskDatasetIdE15Afa5AFkDatasetIdDatasetCreateInput;
  TaskDatasetIdE15Afa5AFkDatasetIdInput: TaskDatasetIdE15Afa5AFkDatasetIdInput;
  TaskDatasetIdE15Afa5AFkDatasetIdInverseInput: TaskDatasetIdE15Afa5AFkDatasetIdInverseInput;
  TaskDatasetIdE15Afa5AFkDatasetIdTaskCreateInput: TaskDatasetIdE15Afa5AFkDatasetIdTaskCreateInput;
  TaskExecutorId24A67329FkTesTaskexecutorIdInput: TaskExecutorId24A67329FkTesTaskexecutorIdInput;
  TaskExecutorId24A67329FkTesTaskexecutorIdInverseInput: TaskExecutorId24A67329FkTesTaskexecutorIdInverseInput;
  TaskExecutorId24A67329FkTesTaskexecutorIdTaskCreateInput: TaskExecutorId24A67329FkTesTaskexecutorIdTaskCreateInput;
  TaskExecutorId24A67329FkTesTaskexecutorIdTesTaskexecutorCreateInput: TaskExecutorId24A67329FkTesTaskexecutorIdTesTaskexecutorCreateInput;
  TaskFilter: TaskFilter;
  TaskInput: TaskInput;
  TaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingTaskExecutorIdKeyUpdate: TaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingTaskExecutorIdKeyUpdate;
  TaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingTaskPkeyUpdate: TaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingTaskPkeyUpdate;
  TaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingTaskExecutorIdKeyUpdate: TaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingTaskExecutorIdKeyUpdate;
  TaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingTaskPkeyUpdate: TaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingTaskPkeyUpdate;
  TaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdUsingTaskExecutorIdKeyUpdate: TaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdUsingTaskExecutorIdKeyUpdate;
  TaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdUsingTaskPkeyUpdate: TaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdUsingTaskPkeyUpdate;
  TaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskPkeyUpdate: TaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskPkeyUpdate;
  TaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingTaskPkeyUpdate: TaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingTaskPkeyUpdate;
  TaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingTaskPkeyUpdate: TaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingTaskPkeyUpdate;
  TaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdUsingTaskPkeyUpdate: TaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdUsingTaskPkeyUpdate;
  TaskOnTaskForTaskProjectId963D6354FkProjectIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskForTaskProjectId963D6354FkProjectIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskForTaskProjectId963D6354FkProjectIdUsingTaskPkeyUpdate: TaskOnTaskForTaskProjectId963D6354FkProjectIdUsingTaskPkeyUpdate;
  TaskOnTaskForTaskTenantId148E90DaFkTenantIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskForTaskTenantId148E90DaFkTenantIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskForTaskTenantId148E90DaFkTenantIdUsingTaskPkeyUpdate: TaskOnTaskForTaskTenantId148E90DaFkTenantIdUsingTaskPkeyUpdate;
  TaskOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingTaskPkeyUpdate: TaskOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingTaskPkeyUpdate;
  TaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingTaskPkeyUpdate: TaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingTaskPkeyUpdate;
  TaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdUsingTaskPkeyUpdate: TaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdUsingTaskPkeyUpdate;
  TaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdUsingTaskPkeyUpdate: TaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdUsingTaskPkeyUpdate;
  TaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskPkeyUpdate: TaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskPkeyUpdate;
  TaskPatch: TaskPatch;
  TaskProjectId963D6354FkProjectIdInput: TaskProjectId963D6354FkProjectIdInput;
  TaskProjectId963D6354FkProjectIdInverseInput: TaskProjectId963D6354FkProjectIdInverseInput;
  TaskProjectId963D6354FkProjectIdTaskCreateInput: TaskProjectId963D6354FkProjectIdTaskCreateInput;
  TaskResource: ResolverTypeWrapper<TaskResource>;
  TaskResourceCondition: TaskResourceCondition;
  TaskResourceFilter: TaskResourceFilter;
  TaskResourceInput: TaskResourceInput;
  TaskResourceOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdUsingTaskResourcePkeyUpdate: TaskResourceOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdUsingTaskResourcePkeyUpdate;
  TaskResourcePatch: TaskResourcePatch;
  TaskResourceTaskId73Fcabc0FkTaskIdInput: TaskResourceTaskId73Fcabc0FkTaskIdInput;
  TaskResourceTaskId73Fcabc0FkTaskIdInverseInput: TaskResourceTaskId73Fcabc0FkTaskIdInverseInput;
  TaskResourceTaskId73Fcabc0FkTaskIdTaskCreateInput: TaskResourceTaskId73Fcabc0FkTaskIdTaskCreateInput;
  TaskResourceTaskId73Fcabc0FkTaskIdTaskResourceCreateInput: TaskResourceTaskId73Fcabc0FkTaskIdTaskResourceCreateInput;
  TaskResourceTaskResourcePkeyConnect: TaskResourceTaskResourcePkeyConnect;
  TaskResourceTaskResourcePkeyDelete: TaskResourceTaskResourcePkeyDelete;
  TaskResourcesConnection: ResolverTypeWrapper<TaskResourcesConnection>;
  TaskResourcesEdge: ResolverTypeWrapper<TaskResourcesEdge>;
  TaskResourcesOrderBy: TaskResourcesOrderBy;
  TaskSource: ResolverTypeWrapper<TaskSource>;
  TaskSourceCondition: TaskSourceCondition;
  TaskSourceFilter: TaskSourceFilter;
  TaskSourceInput: TaskSourceInput;
  TaskSourceOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdUsingTaskSourceV4PkeyUpdate: TaskSourceOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdUsingTaskSourceV4PkeyUpdate;
  TaskSourceOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdUsingTaskSourceV4PkeyUpdate: TaskSourceOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdUsingTaskSourceV4PkeyUpdate;
  TaskSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdUsingTaskSourceV4PkeyUpdate: TaskSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdUsingTaskSourceV4PkeyUpdate;
  TaskSourcePatch: TaskSourcePatch;
  TaskSourceTaskSourceV4PkeyConnect: TaskSourceTaskSourceV4PkeyConnect;
  TaskSourceTaskSourceV4PkeyDelete: TaskSourceTaskSourceV4PkeyDelete;
  TaskSourceV4FileId3A4723EfFkFileIdFileCreateInput: TaskSourceV4FileId3A4723EfFkFileIdFileCreateInput;
  TaskSourceV4FileId3A4723EfFkFileIdInput: TaskSourceV4FileId3A4723EfFkFileIdInput;
  TaskSourceV4FileId3A4723EfFkFileIdInverseInput: TaskSourceV4FileId3A4723EfFkFileIdInverseInput;
  TaskSourceV4FileId3A4723EfFkFileIdTaskSourceCreateInput: TaskSourceV4FileId3A4723EfFkFileIdTaskSourceCreateInput;
  TaskSourceV4TaskId059F1B6BFkTaskIdInput: TaskSourceV4TaskId059F1B6BFkTaskIdInput;
  TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput: TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput;
  TaskSourceV4TaskId059F1B6BFkTaskIdTaskCreateInput: TaskSourceV4TaskId059F1B6BFkTaskIdTaskCreateInput;
  TaskSourceV4TaskId059F1B6BFkTaskIdTaskSourceCreateInput: TaskSourceV4TaskId059F1B6BFkTaskIdTaskSourceCreateInput;
  TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInput: TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInput;
  TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInverseInput: TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInverseInput;
  TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdTaskSourceCreateInput: TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdTaskSourceCreateInput;
  TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdToolSourceCreateInput: TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdToolSourceCreateInput;
  TaskSourcesConnection: ResolverTypeWrapper<TaskSourcesConnection>;
  TaskSourcesEdge: ResolverTypeWrapper<TaskSourcesEdge>;
  TaskSourcesOrderBy: TaskSourcesOrderBy;
  TaskStatusLog: ResolverTypeWrapper<TaskStatusLog>;
  TaskStatusLogCondition: TaskStatusLogCondition;
  TaskStatusLogFilter: TaskStatusLogFilter;
  TaskStatusLogInput: TaskStatusLogInput;
  TaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskStatusLogPkeyUpdate: TaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskStatusLogPkeyUpdate;
  TaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskStatusLogUniqueStatusPerTaskUpdate: TaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskStatusLogUniqueStatusPerTaskUpdate;
  TaskStatusLogPatch: TaskStatusLogPatch;
  TaskStatusLogTaskIdFe1B9D0BFkTaskIdInput: TaskStatusLogTaskIdFe1B9D0BFkTaskIdInput;
  TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput: TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput;
  TaskStatusLogTaskIdFe1B9D0BFkTaskIdTaskCreateInput: TaskStatusLogTaskIdFe1B9D0BFkTaskIdTaskCreateInput;
  TaskStatusLogTaskIdFe1B9D0BFkTaskIdTaskStatusLogCreateInput: TaskStatusLogTaskIdFe1B9D0BFkTaskIdTaskStatusLogCreateInput;
  TaskStatusLogTaskStatusLogPkeyConnect: TaskStatusLogTaskStatusLogPkeyConnect;
  TaskStatusLogTaskStatusLogPkeyDelete: TaskStatusLogTaskStatusLogPkeyDelete;
  TaskStatusLogTaskStatusLogUniqueStatusPerTaskConnect: TaskStatusLogTaskStatusLogUniqueStatusPerTaskConnect;
  TaskStatusLogTaskStatusLogUniqueStatusPerTaskDelete: TaskStatusLogTaskStatusLogUniqueStatusPerTaskDelete;
  TaskStatusLogsConnection: ResolverTypeWrapper<TaskStatusLogsConnection>;
  TaskStatusLogsEdge: ResolverTypeWrapper<TaskStatusLogsEdge>;
  TaskStatusLogsOrderBy: TaskStatusLogsOrderBy;
  TaskTaskExecutorIdKeyConnect: TaskTaskExecutorIdKeyConnect;
  TaskTaskExecutorIdKeyDelete: TaskTaskExecutorIdKeyDelete;
  TaskTaskPkeyConnect: TaskTaskPkeyConnect;
  TaskTaskPkeyDelete: TaskTaskPkeyDelete;
  TaskTenantId148E90DaFkTenantIdInput: TaskTenantId148E90DaFkTenantIdInput;
  TaskTenantId148E90DaFkTenantIdInverseInput: TaskTenantId148E90DaFkTenantIdInverseInput;
  TaskTenantId148E90DaFkTenantIdTaskCreateInput: TaskTenantId148E90DaFkTenantIdTaskCreateInput;
  TaskTenantId148E90DaFkTenantIdTenantCreateInput: TaskTenantId148E90DaFkTenantIdTenantCreateInput;
  TaskToManyMetadataValueFilter: TaskToManyMetadataValueFilter;
  TaskToManyTaskResourceFilter: TaskToManyTaskResourceFilter;
  TaskToManyTaskSourceFilter: TaskToManyTaskSourceFilter;
  TaskToManyTaskStatusLogFilter: TaskToManyTaskStatusLogFilter;
  TaskToolVersionId14506837FkToolVersionIdInput: TaskToolVersionId14506837FkToolVersionIdInput;
  TaskToolVersionId14506837FkToolVersionIdInverseInput: TaskToolVersionId14506837FkToolVersionIdInverseInput;
  TaskToolVersionId14506837FkToolVersionIdTaskCreateInput: TaskToolVersionId14506837FkToolVersionIdTaskCreateInput;
  TaskToolVersionId14506837FkToolVersionIdToolVersionCreateInput: TaskToolVersionId14506837FkToolVersionIdToolVersionCreateInput;
  TaskUserId270D0Bb2FkApplicationUserIdApplicationUserCreateInput: TaskUserId270D0Bb2FkApplicationUserIdApplicationUserCreateInput;
  TaskUserId270D0Bb2FkApplicationUserIdInput: TaskUserId270D0Bb2FkApplicationUserIdInput;
  TaskUserId270D0Bb2FkApplicationUserIdInverseInput: TaskUserId270D0Bb2FkApplicationUserIdInverseInput;
  TaskUserId270D0Bb2FkApplicationUserIdTaskCreateInput: TaskUserId270D0Bb2FkApplicationUserIdTaskCreateInput;
  TasksConnection: ResolverTypeWrapper<TasksConnection>;
  TasksEdge: ResolverTypeWrapper<TasksEdge>;
  TasksOrderBy: TasksOrderBy;
  Tenant: ResolverTypeWrapper<Tenant>;
  TenantContract: ResolverTypeWrapper<TenantContract>;
  TenantContractAlloTenantContractTran29C19B1CFkTenantCoInput: TenantContractAlloTenantContractTran29C19B1CFkTenantCoInput;
  TenantContractAlloTenantContractTran29C19B1CFkTenantCoInverseInput: TenantContractAlloTenantContractTran29C19B1CFkTenantCoInverseInput;
  TenantContractAlloTenantContractTran29C19B1CFkTenantCoTenantContractAllowanceCreateInput: TenantContractAlloTenantContractTran29C19B1CFkTenantCoTenantContractAllowanceCreateInput;
  TenantContractAlloTenantContractTran29C19B1CFkTenantCoTenantContractTransactionCreateInput: TenantContractAlloTenantContractTran29C19B1CFkTenantCoTenantContractTransactionCreateInput;
  TenantContractAllowance: ResolverTypeWrapper<TenantContractAllowance>;
  TenantContractAllowanceCondition: TenantContractAllowanceCondition;
  TenantContractAllowanceFilter: TenantContractAllowanceFilter;
  TenantContractAllowanceInput: TenantContractAllowanceInput;
  TenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoUsingTenantContractAllowancePkeyUpdate: TenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoUsingTenantContractAllowancePkeyUpdate;
  TenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoUsingTenantContractTransactionQuotaTypeUniqueUpdate: TenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoUsingTenantContractTransactionQuotaTypeUniqueUpdate;
  TenantContractAllowancePatch: TenantContractAllowancePatch;
  TenantContractAllowanceTenantContractAllowancePkeyConnect: TenantContractAllowanceTenantContractAllowancePkeyConnect;
  TenantContractAllowanceTenantContractAllowancePkeyDelete: TenantContractAllowanceTenantContractAllowancePkeyDelete;
  TenantContractAllowanceTenantContractTransactionQuotaTypeUniqueConnect: TenantContractAllowanceTenantContractTransactionQuotaTypeUniqueConnect;
  TenantContractAllowanceTenantContractTransactionQuotaTypeUniqueDelete: TenantContractAllowanceTenantContractTransactionQuotaTypeUniqueDelete;
  TenantContractAllowancesConnection: ResolverTypeWrapper<TenantContractAllowancesConnection>;
  TenantContractAllowancesEdge: ResolverTypeWrapper<TenantContractAllowancesEdge>;
  TenantContractAllowancesOrderBy: TenantContractAllowancesOrderBy;
  TenantContractCondition: TenantContractCondition;
  TenantContractFilter: TenantContractFilter;
  TenantContractInput: TenantContractInput;
  TenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantContractNameKeyUpdate: TenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantContractNameKeyUpdate;
  TenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantContractPkeyUpdate: TenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantContractPkeyUpdate;
  TenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoUsingTenantContractNameKeyUpdate: TenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoUsingTenantContractNameKeyUpdate;
  TenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoUsingTenantContractPkeyUpdate: TenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoUsingTenantContractPkeyUpdate;
  TenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantContractNameKeyUpdate: TenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantContractNameKeyUpdate;
  TenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantContractPkeyUpdate: TenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantContractPkeyUpdate;
  TenantContractPatch: TenantContractPatch;
  TenantContractTenantContractNameKeyConnect: TenantContractTenantContractNameKeyConnect;
  TenantContractTenantContractNameKeyDelete: TenantContractTenantContractNameKeyDelete;
  TenantContractTenantContractPkeyConnect: TenantContractTenantContractPkeyConnect;
  TenantContractTenantContractPkeyDelete: TenantContractTenantContractPkeyDelete;
  TenantContractTenantIdBa8Ac167FkTenantIdInput: TenantContractTenantIdBa8Ac167FkTenantIdInput;
  TenantContractTenantIdBa8Ac167FkTenantIdInverseInput: TenantContractTenantIdBa8Ac167FkTenantIdInverseInput;
  TenantContractTenantIdBa8Ac167FkTenantIdTenantContractCreateInput: TenantContractTenantIdBa8Ac167FkTenantIdTenantContractCreateInput;
  TenantContractTenantIdBa8Ac167FkTenantIdTenantCreateInput: TenantContractTenantIdBa8Ac167FkTenantIdTenantCreateInput;
  TenantContractToManyTenantContractTransactionFilter: TenantContractToManyTenantContractTransactionFilter;
  TenantContractToManyTenantMonthlyConsumedResourceFilter: TenantContractToManyTenantMonthlyConsumedResourceFilter;
  TenantContractTranTenantContractId1E374993FkTenantCoInput: TenantContractTranTenantContractId1E374993FkTenantCoInput;
  TenantContractTranTenantContractId1E374993FkTenantCoInverseInput: TenantContractTranTenantContractId1E374993FkTenantCoInverseInput;
  TenantContractTranTenantContractId1E374993FkTenantCoTenantContractCreateInput: TenantContractTranTenantContractId1E374993FkTenantCoTenantContractCreateInput;
  TenantContractTranTenantContractId1E374993FkTenantCoTenantContractTransactionCreateInput: TenantContractTranTenantContractId1E374993FkTenantCoTenantContractTransactionCreateInput;
  TenantContractTransaction: ResolverTypeWrapper<TenantContractTransaction>;
  TenantContractTransactionCondition: TenantContractTransactionCondition;
  TenantContractTransactionFilter: TenantContractTransactionFilter;
  TenantContractTransactionInput: TenantContractTransactionInput;
  TenantContractTransactionOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoUsingTenantContractTransactionPkeyUpdate: TenantContractTransactionOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoUsingTenantContractTransactionPkeyUpdate;
  TenantContractTransactionOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoUsingTenantContractTransactionPkeyUpdate: TenantContractTransactionOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoUsingTenantContractTransactionPkeyUpdate;
  TenantContractTransactionPatch: TenantContractTransactionPatch;
  TenantContractTransactionTenantContractTransactionPkeyConnect: TenantContractTransactionTenantContractTransactionPkeyConnect;
  TenantContractTransactionTenantContractTransactionPkeyDelete: TenantContractTransactionTenantContractTransactionPkeyDelete;
  TenantContractTransactionToManyTenantContractAllowanceFilter: TenantContractTransactionToManyTenantContractAllowanceFilter;
  TenantContractTransactionsConnection: ResolverTypeWrapper<TenantContractTransactionsConnection>;
  TenantContractTransactionsEdge: ResolverTypeWrapper<TenantContractTransactionsEdge>;
  TenantContractTransactionsOrderBy: TenantContractTransactionsOrderBy;
  TenantContractsConnection: ResolverTypeWrapper<TenantContractsConnection>;
  TenantContractsEdge: ResolverTypeWrapper<TenantContractsEdge>;
  TenantContractsOrderBy: TenantContractsOrderBy;
  TenantFilter: TenantFilter;
  TenantInput: TenantInput;
  TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInput: TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInput;
  TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInverseInput: TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInverseInput;
  TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsTenantMockuserexecutorCreateInput: TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsTenantMockuserexecutorCreateInput;
  TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsTenantUserexecutorCreateInput: TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsTenantUserexecutorCreateInput;
  TenantMockuserexecutor: ResolverTypeWrapper<TenantMockuserexecutor>;
  TenantMockuserexecutorCondition: TenantMockuserexecutorCondition;
  TenantMockuserexecutorFilter: TenantMockuserexecutorFilter;
  TenantMockuserexecutorInput: TenantMockuserexecutorInput;
  TenantMockuserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsUsingTenantMockuserexecutorPkeyUpdate: TenantMockuserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsUsingTenantMockuserexecutorPkeyUpdate;
  TenantMockuserexecutorPatch: TenantMockuserexecutorPatch;
  TenantMockuserexecutorTenantMockuserexecutorPkeyConnect: TenantMockuserexecutorTenantMockuserexecutorPkeyConnect;
  TenantMockuserexecutorTenantMockuserexecutorPkeyDelete: TenantMockuserexecutorTenantMockuserexecutorPkeyDelete;
  TenantMockuserexecutorsConnection: ResolverTypeWrapper<TenantMockuserexecutorsConnection>;
  TenantMockuserexecutorsEdge: ResolverTypeWrapper<TenantMockuserexecutorsEdge>;
  TenantMockuserexecutorsOrderBy: TenantMockuserexecutorsOrderBy;
  TenantMonthlyConsuTenantContractId681267CeFkTenantCoInput: TenantMonthlyConsuTenantContractId681267CeFkTenantCoInput;
  TenantMonthlyConsuTenantContractId681267CeFkTenantCoInverseInput: TenantMonthlyConsuTenantContractId681267CeFkTenantCoInverseInput;
  TenantMonthlyConsuTenantContractId681267CeFkTenantCoTenantContractCreateInput: TenantMonthlyConsuTenantContractId681267CeFkTenantCoTenantContractCreateInput;
  TenantMonthlyConsuTenantContractId681267CeFkTenantCoTenantMonthlyConsumedResourcesCreateInput: TenantMonthlyConsuTenantContractId681267CeFkTenantCoTenantMonthlyConsumedResourcesCreateInput;
  TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInput: TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInput;
  TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput: TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput;
  TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdTenantCreateInput: TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdTenantCreateInput;
  TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdTenantMonthlyConsumedResourcesCreateInput: TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdTenantMonthlyConsumedResourcesCreateInput;
  TenantMonthlyConsuUserIdF7210F55FkApplicatiApplicationUserCreateInput: TenantMonthlyConsuUserIdF7210F55FkApplicatiApplicationUserCreateInput;
  TenantMonthlyConsuUserIdF7210F55FkApplicatiInput: TenantMonthlyConsuUserIdF7210F55FkApplicatiInput;
  TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput: TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput;
  TenantMonthlyConsuUserIdF7210F55FkApplicatiTenantMonthlyConsumedResourcesCreateInput: TenantMonthlyConsuUserIdF7210F55FkApplicatiTenantMonthlyConsumedResourcesCreateInput;
  TenantMonthlyConsumedResource: ResolverTypeWrapper<TenantMonthlyConsumedResource>;
  TenantMonthlyConsumedResourceCondition: TenantMonthlyConsumedResourceCondition;
  TenantMonthlyConsumedResourceFilter: TenantMonthlyConsumedResourceFilter;
  TenantMonthlyConsumedResourceInput: TenantMonthlyConsumedResourceInput;
  TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantMonthlyConsumedResourcesPkeyUpdate: TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantMonthlyConsumedResourcesPkeyUpdate;
  TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantMonthlyConsumedResourcesUniqueUpdate: TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantMonthlyConsumedResourcesUniqueUpdate;
  TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantMonthlyConsumedResourcesPkeyUpdate: TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantMonthlyConsumedResourcesPkeyUpdate;
  TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantMonthlyConsumedResourcesUniqueUpdate: TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantMonthlyConsumedResourcesUniqueUpdate;
  TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingTenantMonthlyConsumedResourcesPkeyUpdate: TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingTenantMonthlyConsumedResourcesPkeyUpdate;
  TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingTenantMonthlyConsumedResourcesUniqueUpdate: TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingTenantMonthlyConsumedResourcesUniqueUpdate;
  TenantMonthlyConsumedResourcePatch: TenantMonthlyConsumedResourcePatch;
  TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesPkeyConnect: TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesPkeyConnect;
  TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesPkeyDelete: TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesPkeyDelete;
  TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesUniqueConnect: TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesUniqueConnect;
  TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesUniqueDelete: TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesUniqueDelete;
  TenantMonthlyConsumedResourcesConnection: ResolverTypeWrapper<TenantMonthlyConsumedResourcesConnection>;
  TenantMonthlyConsumedResourcesEdge: ResolverTypeWrapper<TenantMonthlyConsumedResourcesEdge>;
  TenantMonthlyConsumedResourcesOrderBy: TenantMonthlyConsumedResourcesOrderBy;
  TenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingTenantKeyKeyUpdate: TenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingTenantNameKeyUpdate: TenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingTenantPkeyUpdate: TenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingTenantPkeyUpdate;
  TenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingTenantKeyKeyUpdate: TenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingTenantNameKeyUpdate: TenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingTenantPkeyUpdate: TenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingTenantPkeyUpdate;
  TenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingTenantKeyKeyUpdate: TenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingTenantNameKeyUpdate: TenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingTenantPkeyUpdate: TenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingTenantPkeyUpdate;
  TenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingTenantKeyKeyUpdate: TenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingTenantNameKeyUpdate: TenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingTenantPkeyUpdate: TenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingTenantPkeyUpdate;
  TenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingTenantKeyKeyUpdate: TenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingTenantNameKeyUpdate: TenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingTenantPkeyUpdate: TenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingTenantPkeyUpdate;
  TenantOnCostForCostTenantId7Dcd58A7FkTenantIdUsingTenantKeyKeyUpdate: TenantOnCostForCostTenantId7Dcd58A7FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnCostForCostTenantId7Dcd58A7FkTenantIdUsingTenantNameKeyUpdate: TenantOnCostForCostTenantId7Dcd58A7FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnCostForCostTenantId7Dcd58A7FkTenantIdUsingTenantPkeyUpdate: TenantOnCostForCostTenantId7Dcd58A7FkTenantIdUsingTenantPkeyUpdate;
  TenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingTenantKeyKeyUpdate: TenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingTenantNameKeyUpdate: TenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingTenantPkeyUpdate: TenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingTenantPkeyUpdate;
  TenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingTenantKeyKeyUpdate: TenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingTenantNameKeyUpdate: TenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingTenantPkeyUpdate: TenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingTenantPkeyUpdate;
  TenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingTenantKeyKeyUpdate: TenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingTenantNameKeyUpdate: TenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingTenantPkeyUpdate: TenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingTenantPkeyUpdate;
  TenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingTenantKeyKeyUpdate: TenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingTenantNameKeyUpdate: TenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingTenantPkeyUpdate: TenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingTenantPkeyUpdate;
  TenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingTenantKeyKeyUpdate: TenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingTenantNameKeyUpdate: TenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingTenantPkeyUpdate: TenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingTenantPkeyUpdate;
  TenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingTenantKeyKeyUpdate: TenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingTenantNameKeyUpdate: TenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingTenantPkeyUpdate: TenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingTenantPkeyUpdate;
  TenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingTenantKeyKeyUpdate: TenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingTenantNameKeyUpdate: TenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingTenantPkeyUpdate: TenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingTenantPkeyUpdate;
  TenantOnFileForFileTenantId61625016FkTenantIdUsingTenantKeyKeyUpdate: TenantOnFileForFileTenantId61625016FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnFileForFileTenantId61625016FkTenantIdUsingTenantNameKeyUpdate: TenantOnFileForFileTenantId61625016FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnFileForFileTenantId61625016FkTenantIdUsingTenantPkeyUpdate: TenantOnFileForFileTenantId61625016FkTenantIdUsingTenantPkeyUpdate;
  TenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingTenantKeyKeyUpdate: TenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingTenantNameKeyUpdate: TenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingTenantPkeyUpdate: TenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingTenantPkeyUpdate;
  TenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingTenantKeyKeyUpdate: TenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingTenantNameKeyUpdate: TenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingTenantPkeyUpdate: TenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingTenantPkeyUpdate;
  TenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingTenantKeyKeyUpdate: TenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingTenantNameKeyUpdate: TenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingTenantPkeyUpdate: TenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingTenantPkeyUpdate;
  TenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingTenantKeyKeyUpdate: TenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingTenantNameKeyUpdate: TenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingTenantPkeyUpdate: TenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingTenantPkeyUpdate;
  TenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingTenantKeyKeyUpdate: TenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingTenantNameKeyUpdate: TenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingTenantPkeyUpdate: TenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingTenantPkeyUpdate;
  TenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingTenantKeyKeyUpdate: TenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingTenantNameKeyUpdate: TenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingTenantPkeyUpdate: TenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingTenantPkeyUpdate;
  TenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingTenantKeyKeyUpdate: TenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingTenantNameKeyUpdate: TenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingTenantPkeyUpdate: TenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingTenantPkeyUpdate;
  TenantOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingTenantKeyKeyUpdate: TenantOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingTenantNameKeyUpdate: TenantOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingTenantPkeyUpdate: TenantOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingTenantPkeyUpdate;
  TenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingTenantKeyKeyUpdate: TenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingTenantNameKeyUpdate: TenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingTenantPkeyUpdate: TenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingTenantPkeyUpdate;
  TenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingTenantKeyKeyUpdate: TenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingTenantNameKeyUpdate: TenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingTenantPkeyUpdate: TenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingTenantPkeyUpdate;
  TenantOnRunForRunTenantId73Fbd8D9FkTenantIdUsingTenantKeyKeyUpdate: TenantOnRunForRunTenantId73Fbd8D9FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnRunForRunTenantId73Fbd8D9FkTenantIdUsingTenantNameKeyUpdate: TenantOnRunForRunTenantId73Fbd8D9FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnRunForRunTenantId73Fbd8D9FkTenantIdUsingTenantPkeyUpdate: TenantOnRunForRunTenantId73Fbd8D9FkTenantIdUsingTenantPkeyUpdate;
  TenantOnTagForTagTenantId8072B61FFkTenantIdUsingTenantKeyKeyUpdate: TenantOnTagForTagTenantId8072B61FFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnTagForTagTenantId8072B61FFkTenantIdUsingTenantNameKeyUpdate: TenantOnTagForTagTenantId8072B61FFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnTagForTagTenantId8072B61FFkTenantIdUsingTenantPkeyUpdate: TenantOnTagForTagTenantId8072B61FFkTenantIdUsingTenantPkeyUpdate;
  TenantOnTaskForTaskTenantId148E90DaFkTenantIdUsingTenantKeyKeyUpdate: TenantOnTaskForTaskTenantId148E90DaFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnTaskForTaskTenantId148E90DaFkTenantIdUsingTenantNameKeyUpdate: TenantOnTaskForTaskTenantId148E90DaFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnTaskForTaskTenantId148E90DaFkTenantIdUsingTenantPkeyUpdate: TenantOnTaskForTaskTenantId148E90DaFkTenantIdUsingTenantPkeyUpdate;
  TenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantKeyKeyUpdate: TenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantNameKeyUpdate: TenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantPkeyUpdate: TenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantPkeyUpdate;
  TenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantKeyKeyUpdate: TenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantNameKeyUpdate: TenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantPkeyUpdate: TenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantPkeyUpdate;
  TenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantKeyKeyUpdate: TenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantNameKeyUpdate: TenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantPkeyUpdate: TenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantPkeyUpdate;
  TenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingTenantKeyKeyUpdate: TenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingTenantNameKeyUpdate: TenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingTenantPkeyUpdate: TenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingTenantPkeyUpdate;
  TenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingTenantKeyKeyUpdate: TenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingTenantNameKeyUpdate: TenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingTenantPkeyUpdate: TenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingTenantPkeyUpdate;
  TenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingTenantKeyKeyUpdate: TenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingTenantNameKeyUpdate: TenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingTenantPkeyUpdate: TenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingTenantPkeyUpdate;
  TenantPatch: TenantPatch;
  TenantTenantKeyKeyConnect: TenantTenantKeyKeyConnect;
  TenantTenantKeyKeyDelete: TenantTenantKeyKeyDelete;
  TenantTenantNameKeyConnect: TenantTenantNameKeyConnect;
  TenantTenantNameKeyDelete: TenantTenantNameKeyDelete;
  TenantTenantPkeyConnect: TenantTenantPkeyConnect;
  TenantTenantPkeyDelete: TenantTenantPkeyDelete;
  TenantToManyAnalysisTableFilter: TenantToManyAnalysisTableFilter;
  TenantToManyAnalysisTableGroupFilter: TenantToManyAnalysisTableGroupFilter;
  TenantToManyAnalysisTableRowFilter: TenantToManyAnalysisTableRowFilter;
  TenantToManyApplicationUserMembershipFilter: TenantToManyApplicationUserMembershipFilter;
  TenantToManyApplicationUserSessionFilter: TenantToManyApplicationUserSessionFilter;
  TenantToManyCostFilter: TenantToManyCostFilter;
  TenantToManyDatasetFilter: TenantToManyDatasetFilter;
  TenantToManyDatasetMetadatumFilter: TenantToManyDatasetMetadatumFilter;
  TenantToManyDatasetRecordingsTableColumnFilter: TenantToManyDatasetRecordingsTableColumnFilter;
  TenantToManyDatasetRecordingsTableColumnOrderFilter: TenantToManyDatasetRecordingsTableColumnOrderFilter;
  TenantToManyDatasetRecordingsTableFilter: TenantToManyDatasetRecordingsTableFilter;
  TenantToManyDatasetVersionFilter: TenantToManyDatasetVersionFilter;
  TenantToManyFileActivityFilter: TenantToManyFileActivityFilter;
  TenantToManyFileFilter: TenantToManyFileFilter;
  TenantToManyFileMetadatumFilter: TenantToManyFileMetadatumFilter;
  TenantToManyFileOutputGroupFilter: TenantToManyFileOutputGroupFilter;
  TenantToManyFileOutputGroupMetadatumFilter: TenantToManyFileOutputGroupMetadatumFilter;
  TenantToManyFileRecordingGroupFilter: TenantToManyFileRecordingGroupFilter;
  TenantToManyFileRecordingGroupMetadatumFilter: TenantToManyFileRecordingGroupMetadatumFilter;
  TenantToManyMetadataValueFilter: TenantToManyMetadataValueFilter;
  TenantToManyMetadatumFilter: TenantToManyMetadatumFilter;
  TenantToManyProjectFilter: TenantToManyProjectFilter;
  TenantToManyRecordingGroupFileFilter: TenantToManyRecordingGroupFileFilter;
  TenantToManyRoleChangeNotificationFilter: TenantToManyRoleChangeNotificationFilter;
  TenantToManyRunFilter: TenantToManyRunFilter;
  TenantToManyTagFilter: TenantToManyTagFilter;
  TenantToManyTaskFilter: TenantToManyTaskFilter;
  TenantToManyTenantContractFilter: TenantToManyTenantContractFilter;
  TenantToManyTenantMonthlyConsumedResourceFilter: TenantToManyTenantMonthlyConsumedResourceFilter;
  TenantToManyTenantUserMembershipNotificationFilter: TenantToManyTenantUserMembershipNotificationFilter;
  TenantToManyToolTenantFilter: TenantToManyToolTenantFilter;
  TenantToManyToolboxTenantFilter: TenantToManyToolboxTenantFilter;
  TenantToManyWorkflowFilter: TenantToManyWorkflowFilter;
  TenantUserMembershId10Affa8DFkNotificatInput: TenantUserMembershId10Affa8DFkNotificatInput;
  TenantUserMembershId10Affa8DFkNotificatInverseInput: TenantUserMembershId10Affa8DFkNotificatInverseInput;
  TenantUserMembershInviterId29476E5EFkApplicatiApplicationUserCreateInput: TenantUserMembershInviterId29476E5EFkApplicatiApplicationUserCreateInput;
  TenantUserMembershInviterId29476E5EFkApplicatiInput: TenantUserMembershInviterId29476E5EFkApplicatiInput;
  TenantUserMembershInviterId29476E5EFkApplicatiInverseInput: TenantUserMembershInviterId29476E5EFkApplicatiInverseInput;
  TenantUserMembershTenantIdC67818C5FkTenantIdInput: TenantUserMembershTenantIdC67818C5FkTenantIdInput;
  TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput: TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput;
  TenantUserMembershTenantIdC67818C5FkTenantIdTenantCreateInput: TenantUserMembershTenantIdC67818C5FkTenantIdTenantCreateInput;
  TenantUserMembershUserId2B78E8A0FkApplicatiApplicationUserCreateInput: TenantUserMembershUserId2B78E8A0FkApplicatiApplicationUserCreateInput;
  TenantUserMembershUserId2B78E8A0FkApplicatiInput: TenantUserMembershUserId2B78E8A0FkApplicatiInput;
  TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput: TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput;
  TenantUserMembershipNotification: ResolverTypeWrapper<TenantUserMembershipNotification>;
  TenantUserMembershipNotificationCondition: TenantUserMembershipNotificationCondition;
  TenantUserMembershipNotificationFilter: TenantUserMembershipNotificationFilter;
  TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatUsingTenantUserMembershipNotificationPkeyUpdate: TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatUsingTenantUserMembershipNotificationPkeyUpdate;
  TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingTenantUserMembershipNotificationPkeyUpdate: TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingTenantUserMembershipNotificationPkeyUpdate;
  TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantUserMembershipNotificationPkeyUpdate: TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantUserMembershipNotificationPkeyUpdate;
  TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingTenantUserMembershipNotificationPkeyUpdate: TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingTenantUserMembershipNotificationPkeyUpdate;
  TenantUserMembershipNotificationTenantUserMembershipNotificationPkeyConnect: TenantUserMembershipNotificationTenantUserMembershipNotificationPkeyConnect;
  TenantUserMembershipNotificationsConnection: ResolverTypeWrapper<TenantUserMembershipNotificationsConnection>;
  TenantUserMembershipNotificationsEdge: ResolverTypeWrapper<TenantUserMembershipNotificationsEdge>;
  TenantUserMembershipNotificationsOrderBy: TenantUserMembershipNotificationsOrderBy;
  TenantUserexecutor: ResolverTypeWrapper<TenantUserexecutor>;
  TenantUserexecutorCondition: TenantUserexecutorCondition;
  TenantUserexecutorFilter: TenantUserexecutorFilter;
  TenantUserexecutorInput: TenantUserexecutorInput;
  TenantUserexecutorOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsUsingTenantUserexecutorPkeyUpdate: TenantUserexecutorOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsUsingTenantUserexecutorPkeyUpdate;
  TenantUserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsUsingTenantUserexecutorPkeyUpdate: TenantUserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsUsingTenantUserexecutorPkeyUpdate;
  TenantUserexecutorOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoUsingTenantUserexecutorPkeyUpdate: TenantUserexecutorOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoUsingTenantUserexecutorPkeyUpdate;
  TenantUserexecutorPatch: TenantUserexecutorPatch;
  TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoDjangoContentTypeCreateInput: TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoDjangoContentTypeCreateInput;
  TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInput: TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInput;
  TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput: TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput;
  TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoTenantUserexecutorCreateInput: TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoTenantUserexecutorCreateInput;
  TenantUserexecutorTenantUserexecutorPkeyConnect: TenantUserexecutorTenantUserexecutorPkeyConnect;
  TenantUserexecutorTenantUserexecutorPkeyDelete: TenantUserexecutorTenantUserexecutorPkeyDelete;
  TenantUserexecutorsConnection: ResolverTypeWrapper<TenantUserexecutorsConnection>;
  TenantUserexecutorsEdge: ResolverTypeWrapper<TenantUserexecutorsEdge>;
  TenantUserexecutorsOrderBy: TenantUserexecutorsOrderBy;
  TenantsEdge: ResolverTypeWrapper<TenantsEdge>;
  TenantsOrderBy: TenantsOrderBy;
  TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoDjangoContentTypeCreateInput: TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoDjangoContentTypeCreateInput;
  TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInput: TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInput;
  TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput: TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput;
  TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoTesContainerimageexecutorCreateInput: TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoTesContainerimageexecutorCreateInput;
  TesContainerimageexecutor: ResolverTypeWrapper<TesContainerimageexecutor>;
  TesContainerimageexecutorCondition: TesContainerimageexecutorCondition;
  TesContainerimageexecutorFilter: TesContainerimageexecutorFilter;
  TesContainerimageexecutorInput: TesContainerimageexecutorInput;
  TesContainerimageexecutorOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaUsingTesContainerimageexecutorPkeyUpdate: TesContainerimageexecutorOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaUsingTesContainerimageexecutorPkeyUpdate;
  TesContainerimageexecutorOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingTesContainerimageexecutorPkeyUpdate: TesContainerimageexecutorOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingTesContainerimageexecutorPkeyUpdate;
  TesContainerimageexecutorOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoUsingTesContainerimageexecutorPkeyUpdate: TesContainerimageexecutorOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoUsingTesContainerimageexecutorPkeyUpdate;
  TesContainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaUsingTesContainerimageexecutorPkeyUpdate: TesContainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaUsingTesContainerimageexecutorPkeyUpdate;
  TesContainerimageexecutorPatch: TesContainerimageexecutorPatch;
  TesContainerimageexecutorTesContainerimageexecutorPkeyConnect: TesContainerimageexecutorTesContainerimageexecutorPkeyConnect;
  TesContainerimageexecutorTesContainerimageexecutorPkeyDelete: TesContainerimageexecutorTesContainerimageexecutorPkeyDelete;
  TesContainerimageexecutorsConnection: ResolverTypeWrapper<TesContainerimageexecutorsConnection>;
  TesContainerimageexecutorsEdge: ResolverTypeWrapper<TesContainerimageexecutorsEdge>;
  TesContainerimageexecutorsOrderBy: TesContainerimageexecutorsOrderBy;
  TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInput: TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInput;
  TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInverseInput: TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInverseInput;
  TesMockcontainerimaContainerimageexecut4Eced924FkTesContaTesContainerimageexecutorCreateInput: TesMockcontainerimaContainerimageexecut4Eced924FkTesContaTesContainerimageexecutorCreateInput;
  TesMockcontainerimaContainerimageexecut4Eced924FkTesContaTesMockcontainerimageexecutorCreateInput: TesMockcontainerimaContainerimageexecut4Eced924FkTesContaTesMockcontainerimageexecutorCreateInput;
  TesMockcontainerimageexecutor: ResolverTypeWrapper<TesMockcontainerimageexecutor>;
  TesMockcontainerimageexecutorCondition: TesMockcontainerimageexecutorCondition;
  TesMockcontainerimageexecutorFilter: TesMockcontainerimageexecutorFilter;
  TesMockcontainerimageexecutorInput: TesMockcontainerimageexecutorInput;
  TesMockcontainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaUsingTesMockcontainerimageexecutorPkeyUpdate: TesMockcontainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaUsingTesMockcontainerimageexecutorPkeyUpdate;
  TesMockcontainerimageexecutorPatch: TesMockcontainerimageexecutorPatch;
  TesMockcontainerimageexecutorTesMockcontainerimageexecutorPkeyConnect: TesMockcontainerimageexecutorTesMockcontainerimageexecutorPkeyConnect;
  TesMockcontainerimageexecutorTesMockcontainerimageexecutorPkeyDelete: TesMockcontainerimageexecutorTesMockcontainerimageexecutorPkeyDelete;
  TesMockcontainerimageexecutorsConnection: ResolverTypeWrapper<TesMockcontainerimageexecutorsConnection>;
  TesMockcontainerimageexecutorsEdge: ResolverTypeWrapper<TesMockcontainerimageexecutorsEdge>;
  TesMockcontainerimageexecutorsOrderBy: TesMockcontainerimageexecutorsOrderBy;
  TesMocktaskexecutor: ResolverTypeWrapper<TesMocktaskexecutor>;
  TesMocktaskexecutorCondition: TesMocktaskexecutorCondition;
  TesMocktaskexecutorFilter: TesMocktaskexecutorFilter;
  TesMocktaskexecutorInput: TesMocktaskexecutorInput;
  TesMocktaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeUsingTesMocktaskexecutorPkeyUpdate: TesMocktaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeUsingTesMocktaskexecutorPkeyUpdate;
  TesMocktaskexecutorPatch: TesMocktaskexecutorPatch;
  TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInput: TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInput;
  TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInverseInput: TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInverseInput;
  TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeTesMocktaskexecutorCreateInput: TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeTesMocktaskexecutorCreateInput;
  TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeTesTaskexecutorCreateInput: TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeTesTaskexecutorCreateInput;
  TesMocktaskexecutorTesMocktaskexecutorPkeyConnect: TesMocktaskexecutorTesMocktaskexecutorPkeyConnect;
  TesMocktaskexecutorTesMocktaskexecutorPkeyDelete: TesMocktaskexecutorTesMocktaskexecutorPkeyDelete;
  TesMocktaskexecutorsConnection: ResolverTypeWrapper<TesMocktaskexecutorsConnection>;
  TesMocktaskexecutorsEdge: ResolverTypeWrapper<TesMocktaskexecutorsEdge>;
  TesMocktaskexecutorsOrderBy: TesMocktaskexecutorsOrderBy;
  TesTaskexecutor: ResolverTypeWrapper<TesTaskexecutor>;
  TesTaskexecutorCondition: TesTaskexecutorCondition;
  TesTaskexecutorFilter: TesTaskexecutorFilter;
  TesTaskexecutorInput: TesTaskexecutorInput;
  TesTaskexecutorOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeUsingTesTaskexecutorPkeyUpdate: TesTaskexecutorOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeUsingTesTaskexecutorPkeyUpdate;
  TesTaskexecutorOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdUsingTesTaskexecutorPkeyUpdate: TesTaskexecutorOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdUsingTesTaskexecutorPkeyUpdate;
  TesTaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeUsingTesTaskexecutorPkeyUpdate: TesTaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeUsingTesTaskexecutorPkeyUpdate;
  TesTaskexecutorOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoUsingTesTaskexecutorPkeyUpdate: TesTaskexecutorOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoUsingTesTaskexecutorPkeyUpdate;
  TesTaskexecutorPatch: TesTaskexecutorPatch;
  TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoDjangoContentTypeCreateInput: TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoDjangoContentTypeCreateInput;
  TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInput: TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInput;
  TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput: TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput;
  TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoTesTaskexecutorCreateInput: TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoTesTaskexecutorCreateInput;
  TesTaskexecutorTesTaskexecutorPkeyConnect: TesTaskexecutorTesTaskexecutorPkeyConnect;
  TesTaskexecutorTesTaskexecutorPkeyDelete: TesTaskexecutorTesTaskexecutorPkeyDelete;
  TesTaskexecutorsConnection: ResolverTypeWrapper<TesTaskexecutorsConnection>;
  TesTaskexecutorsEdge: ResolverTypeWrapper<TesTaskexecutorsEdge>;
  TesTaskexecutorsOrderBy: TesTaskexecutorsOrderBy;
  Tool: ResolverTypeWrapper<Tool>;
  ToolCategoriesConnection: ResolverTypeWrapper<ToolCategoriesConnection>;
  ToolCategoriesEdge: ResolverTypeWrapper<ToolCategoriesEdge>;
  ToolCategoriesOrderBy: ToolCategoriesOrderBy;
  ToolCategory: ResolverTypeWrapper<ToolCategory>;
  ToolCategoryCondition: ToolCategoryCondition;
  ToolCategoryFilter: ToolCategoryFilter;
  ToolCategoryInput: ToolCategoryInput;
  ToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolCategoryKeyKeyUpdate: ToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolCategoryKeyKeyUpdate;
  ToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolCategoryPkeyUpdate: ToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolCategoryPkeyUpdate;
  ToolCategoryPatch: ToolCategoryPatch;
  ToolCategoryToManyToolDisplayCategoryFilter: ToolCategoryToManyToolDisplayCategoryFilter;
  ToolCategoryToolCategoryKeyKeyConnect: ToolCategoryToolCategoryKeyKeyConnect;
  ToolCategoryToolCategoryKeyKeyDelete: ToolCategoryToolCategoryKeyKeyDelete;
  ToolCategoryToolCategoryPkeyConnect: ToolCategoryToolCategoryPkeyConnect;
  ToolCategoryToolCategoryPkeyDelete: ToolCategoryToolCategoryPkeyDelete;
  ToolCondition: ToolCondition;
  ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInput: ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInput;
  ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInverseInput: ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInverseInput;
  ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateToolCategoryCreateInput: ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateToolCategoryCreateInput;
  ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateToolDisplayCategoriesCreateInput: ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateToolDisplayCategoriesCreateInput;
  ToolDisplayCategoriesConnection: ResolverTypeWrapper<ToolDisplayCategoriesConnection>;
  ToolDisplayCategoriesEdge: ResolverTypeWrapper<ToolDisplayCategoriesEdge>;
  ToolDisplayCategoriesOrderBy: ToolDisplayCategoriesOrderBy;
  ToolDisplayCategoriesToolIdAdc582D3FkToolIdInput: ToolDisplayCategoriesToolIdAdc582D3FkToolIdInput;
  ToolDisplayCategoriesToolIdAdc582D3FkToolIdInverseInput: ToolDisplayCategoriesToolIdAdc582D3FkToolIdInverseInput;
  ToolDisplayCategoriesToolIdAdc582D3FkToolIdToolCreateInput: ToolDisplayCategoriesToolIdAdc582D3FkToolIdToolCreateInput;
  ToolDisplayCategoriesToolIdAdc582D3FkToolIdToolDisplayCategoriesCreateInput: ToolDisplayCategoriesToolIdAdc582D3FkToolIdToolDisplayCategoriesCreateInput;
  ToolDisplayCategory: ResolverTypeWrapper<ToolDisplayCategory>;
  ToolDisplayCategoryCondition: ToolDisplayCategoryCondition;
  ToolDisplayCategoryFilter: ToolDisplayCategoryFilter;
  ToolDisplayCategoryInput: ToolDisplayCategoryInput;
  ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolDisplayCategoriesPkeyUpdate: ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolDisplayCategoriesPkeyUpdate;
  ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqUpdate: ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqUpdate;
  ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolDisplayCategoriesPkeyUpdate: ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolDisplayCategoriesPkeyUpdate;
  ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqUpdate: ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqUpdate;
  ToolDisplayCategoryPatch: ToolDisplayCategoryPatch;
  ToolDisplayCategoryToolDisplayCategoriesPkeyConnect: ToolDisplayCategoryToolDisplayCategoriesPkeyConnect;
  ToolDisplayCategoryToolDisplayCategoriesPkeyDelete: ToolDisplayCategoryToolDisplayCategoriesPkeyDelete;
  ToolDisplayCategoryToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqConnect: ToolDisplayCategoryToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqConnect;
  ToolDisplayCategoryToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqDelete: ToolDisplayCategoryToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqDelete;
  ToolFilter: ToolFilter;
  ToolInput: ToolInput;
  ToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdUsingToolKeyKeyUpdate: ToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdUsingToolKeyKeyUpdate;
  ToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdUsingToolPkeyUpdate: ToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdUsingToolPkeyUpdate;
  ToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolKeyKeyUpdate: ToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolKeyKeyUpdate;
  ToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolPkeyUpdate: ToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolPkeyUpdate;
  ToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolKeyKeyUpdate: ToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolKeyKeyUpdate;
  ToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolPkeyUpdate: ToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolPkeyUpdate;
  ToolOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolKeyKeyUpdate: ToolOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolKeyKeyUpdate;
  ToolOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolPkeyUpdate: ToolOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolPkeyUpdate;
  ToolParameter: ResolverTypeWrapper<ToolParameter>;
  ToolParameterCondition: ToolParameterCondition;
  ToolParameterFilter: ToolParameterFilter;
  ToolParameterInput: ToolParameterInput;
  ToolParameterOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdUsingToolParameterPkeyUpdate: ToolParameterOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdUsingToolParameterPkeyUpdate;
  ToolParameterPatch: ToolParameterPatch;
  ToolParameterToolParameterPkeyConnect: ToolParameterToolParameterPkeyConnect;
  ToolParameterToolParameterPkeyDelete: ToolParameterToolParameterPkeyDelete;
  ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInput: ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInput;
  ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput: ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput;
  ToolParameterToolVersionIdC3A0Df75FkToolVersionIdToolParameterCreateInput: ToolParameterToolVersionIdC3A0Df75FkToolVersionIdToolParameterCreateInput;
  ToolParameterToolVersionIdC3A0Df75FkToolVersionIdToolVersionCreateInput: ToolParameterToolVersionIdC3A0Df75FkToolVersionIdToolVersionCreateInput;
  ToolParametersConnection: ResolverTypeWrapper<ToolParametersConnection>;
  ToolParametersEdge: ResolverTypeWrapper<ToolParametersEdge>;
  ToolParametersOrderBy: ToolParametersOrderBy;
  ToolPatch: ToolPatch;
  ToolResource: ResolverTypeWrapper<ToolResource>;
  ToolResourceCondition: ToolResourceCondition;
  ToolResourceFilter: ToolResourceFilter;
  ToolResourceInput: ToolResourceInput;
  ToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingReqTypeToolVersionUniqueUpdate: ToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingReqTypeToolVersionUniqueUpdate;
  ToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingToolResourcePkeyUpdate: ToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingToolResourcePkeyUpdate;
  ToolResourcePatch: ToolResourcePatch;
  ToolResourceReqTypeToolVersionUniqueConnect: ToolResourceReqTypeToolVersionUniqueConnect;
  ToolResourceReqTypeToolVersionUniqueDelete: ToolResourceReqTypeToolVersionUniqueDelete;
  ToolResourceToolResourcePkeyConnect: ToolResourceToolResourcePkeyConnect;
  ToolResourceToolResourcePkeyDelete: ToolResourceToolResourcePkeyDelete;
  ToolResourceToolVersionId5E21979DFkToolVersionIdInput: ToolResourceToolVersionId5E21979DFkToolVersionIdInput;
  ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput: ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput;
  ToolResourceToolVersionId5E21979DFkToolVersionIdToolResourceCreateInput: ToolResourceToolVersionId5E21979DFkToolVersionIdToolResourceCreateInput;
  ToolResourceToolVersionId5E21979DFkToolVersionIdToolVersionCreateInput: ToolResourceToolVersionId5E21979DFkToolVersionIdToolVersionCreateInput;
  ToolResourcesConnection: ResolverTypeWrapper<ToolResourcesConnection>;
  ToolResourcesEdge: ResolverTypeWrapper<ToolResourcesEdge>;
  ToolResourcesOrderBy: ToolResourcesOrderBy;
  ToolResult: ResolverTypeWrapper<ToolResult>;
  ToolResultCondition: ToolResultCondition;
  ToolResultFilter: ToolResultFilter;
  ToolResultInput: ToolResultInput;
  ToolResultOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdUsingToolResultPkeyUpdate: ToolResultOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdUsingToolResultPkeyUpdate;
  ToolResultPatch: ToolResultPatch;
  ToolResultToolResultPkeyConnect: ToolResultToolResultPkeyConnect;
  ToolResultToolResultPkeyDelete: ToolResultToolResultPkeyDelete;
  ToolResultToolVersionId41Fb163EFkToolVersionIdInput: ToolResultToolVersionId41Fb163EFkToolVersionIdInput;
  ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput: ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput;
  ToolResultToolVersionId41Fb163EFkToolVersionIdToolResultCreateInput: ToolResultToolVersionId41Fb163EFkToolVersionIdToolResultCreateInput;
  ToolResultToolVersionId41Fb163EFkToolVersionIdToolVersionCreateInput: ToolResultToolVersionId41Fb163EFkToolVersionIdToolVersionCreateInput;
  ToolResultsConnection: ResolverTypeWrapper<ToolResultsConnection>;
  ToolResultsEdge: ResolverTypeWrapper<ToolResultsEdge>;
  ToolResultsOrderBy: ToolResultsOrderBy;
  ToolSecret: ResolverTypeWrapper<ToolSecret>;
  ToolSecretCondition: ToolSecretCondition;
  ToolSecretFilter: ToolSecretFilter;
  ToolSecretInput: ToolSecretInput;
  ToolSecretOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdUsingToolSecretPkeyUpdate: ToolSecretOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdUsingToolSecretPkeyUpdate;
  ToolSecretPatch: ToolSecretPatch;
  ToolSecretToolSecretPkeyConnect: ToolSecretToolSecretPkeyConnect;
  ToolSecretToolSecretPkeyDelete: ToolSecretToolSecretPkeyDelete;
  ToolSecretToolVersionId92Dc8FadFkToolVersionIdInput: ToolSecretToolVersionId92Dc8FadFkToolVersionIdInput;
  ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput: ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput;
  ToolSecretToolVersionId92Dc8FadFkToolVersionIdToolSecretCreateInput: ToolSecretToolVersionId92Dc8FadFkToolVersionIdToolSecretCreateInput;
  ToolSecretToolVersionId92Dc8FadFkToolVersionIdToolVersionCreateInput: ToolSecretToolVersionId92Dc8FadFkToolVersionIdToolVersionCreateInput;
  ToolSecretsConnection: ResolverTypeWrapper<ToolSecretsConnection>;
  ToolSecretsEdge: ResolverTypeWrapper<ToolSecretsEdge>;
  ToolSecretsOrderBy: ToolSecretsOrderBy;
  ToolSource: ResolverTypeWrapper<ToolSource>;
  ToolSourceCondition: ToolSourceCondition;
  ToolSourceFilter: ToolSourceFilter;
  ToolSourceInput: ToolSourceInput;
  ToolSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdUsingToolSourcePkeyUpdate: ToolSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdUsingToolSourcePkeyUpdate;
  ToolSourceOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdUsingToolSourcePkeyUpdate: ToolSourceOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdUsingToolSourcePkeyUpdate;
  ToolSourcePatch: ToolSourcePatch;
  ToolSourceToManyTaskSourceFilter: ToolSourceToManyTaskSourceFilter;
  ToolSourceToolSourcePkeyConnect: ToolSourceToolSourcePkeyConnect;
  ToolSourceToolSourcePkeyDelete: ToolSourceToolSourcePkeyDelete;
  ToolSourceToolVersionId9Ac27A25FkToolVersionIdInput: ToolSourceToolVersionId9Ac27A25FkToolVersionIdInput;
  ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput: ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput;
  ToolSourceToolVersionId9Ac27A25FkToolVersionIdToolSourceCreateInput: ToolSourceToolVersionId9Ac27A25FkToolVersionIdToolSourceCreateInput;
  ToolSourceToolVersionId9Ac27A25FkToolVersionIdToolVersionCreateInput: ToolSourceToolVersionId9Ac27A25FkToolVersionIdToolVersionCreateInput;
  ToolSourcesConnection: ResolverTypeWrapper<ToolSourcesConnection>;
  ToolSourcesEdge: ResolverTypeWrapper<ToolSourcesEdge>;
  ToolSourcesOrderBy: ToolSourcesOrderBy;
  ToolTenant: ResolverTypeWrapper<ToolTenant>;
  ToolTenantCondition: ToolTenantCondition;
  ToolTenantFilter: ToolTenantFilter;
  ToolTenantInput: ToolTenantInput;
  ToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingToolTenantsPkeyUpdate: ToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingToolTenantsPkeyUpdate;
  ToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingToolTenantsToolIdTenantId1Ab854FcUniqUpdate: ToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingToolTenantsToolIdTenantId1Ab854FcUniqUpdate;
  ToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolTenantsPkeyUpdate: ToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolTenantsPkeyUpdate;
  ToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolTenantsToolIdTenantId1Ab854FcUniqUpdate: ToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolTenantsToolIdTenantId1Ab854FcUniqUpdate;
  ToolTenantPatch: ToolTenantPatch;
  ToolTenantToolTenantsPkeyConnect: ToolTenantToolTenantsPkeyConnect;
  ToolTenantToolTenantsPkeyDelete: ToolTenantToolTenantsPkeyDelete;
  ToolTenantToolTenantsToolIdTenantId1Ab854FcUniqConnect: ToolTenantToolTenantsToolIdTenantId1Ab854FcUniqConnect;
  ToolTenantToolTenantsToolIdTenantId1Ab854FcUniqDelete: ToolTenantToolTenantsToolIdTenantId1Ab854FcUniqDelete;
  ToolTenantsConnection: ResolverTypeWrapper<ToolTenantsConnection>;
  ToolTenantsEdge: ResolverTypeWrapper<ToolTenantsEdge>;
  ToolTenantsOrderBy: ToolTenantsOrderBy;
  ToolTenantsTenantId9C4A54FeFkTenantIdInput: ToolTenantsTenantId9C4A54FeFkTenantIdInput;
  ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput: ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput;
  ToolTenantsTenantId9C4A54FeFkTenantIdTenantCreateInput: ToolTenantsTenantId9C4A54FeFkTenantIdTenantCreateInput;
  ToolTenantsTenantId9C4A54FeFkTenantIdToolTenantsCreateInput: ToolTenantsTenantId9C4A54FeFkTenantIdToolTenantsCreateInput;
  ToolTenantsToolIdF77Ef6F7FkToolIdInput: ToolTenantsToolIdF77Ef6F7FkToolIdInput;
  ToolTenantsToolIdF77Ef6F7FkToolIdInverseInput: ToolTenantsToolIdF77Ef6F7FkToolIdInverseInput;
  ToolTenantsToolIdF77Ef6F7FkToolIdToolCreateInput: ToolTenantsToolIdF77Ef6F7FkToolIdToolCreateInput;
  ToolTenantsToolIdF77Ef6F7FkToolIdToolTenantsCreateInput: ToolTenantsToolIdF77Ef6F7FkToolIdToolTenantsCreateInput;
  ToolToManyAnalysisTableGroupFilter: ToolToManyAnalysisTableGroupFilter;
  ToolToManyToolDisplayCategoryFilter: ToolToManyToolDisplayCategoryFilter;
  ToolToManyToolTenantFilter: ToolToManyToolTenantFilter;
  ToolToManyToolVersionFilter: ToolToManyToolVersionFilter;
  ToolToolKeyKeyConnect: ToolToolKeyKeyConnect;
  ToolToolKeyKeyDelete: ToolToolKeyKeyDelete;
  ToolToolPkeyConnect: ToolToolPkeyConnect;
  ToolToolPkeyDelete: ToolToolPkeyDelete;
  ToolVersion: ResolverTypeWrapper<ToolVersion>;
  ToolVersionCondition: ToolVersionCondition;
  ToolVersionFilter: ToolVersionFilter;
  ToolVersionInput: ToolVersionInput;
  ToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdUsingToolVersionPkeyUpdate: ToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdUsingToolVersionPkeyUpdate;
  ToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingToolVersionPkeyUpdate: ToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingToolVersionPkeyUpdate;
  ToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingToolVersionPkeyUpdate: ToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingToolVersionPkeyUpdate;
  ToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdUsingToolVersionPkeyUpdate: ToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdUsingToolVersionPkeyUpdate;
  ToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingToolVersionPkeyUpdate: ToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingToolVersionPkeyUpdate;
  ToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdUsingToolVersionPkeyUpdate: ToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdUsingToolVersionPkeyUpdate;
  ToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdUsingToolVersionPkeyUpdate: ToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdUsingToolVersionPkeyUpdate;
  ToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdUsingToolVersionPkeyUpdate: ToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdUsingToolVersionPkeyUpdate;
  ToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolVersionPkeyUpdate: ToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolVersionPkeyUpdate;
  ToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolVersionPkeyUpdate: ToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolVersionPkeyUpdate;
  ToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolversionToolVersionUniqueUpdate: ToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdUsingToolVersionPkeyUpdate: ToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdUsingToolVersionPkeyUpdate;
  ToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionPatch: ToolVersionPatch;
  ToolVersionToManyAnalysisTableFilter: ToolVersionToManyAnalysisTableFilter;
  ToolVersionToManyAnalysisTableRowFilter: ToolVersionToManyAnalysisTableRowFilter;
  ToolVersionToManyTaskFilter: ToolVersionToManyTaskFilter;
  ToolVersionToManyToolParameterFilter: ToolVersionToManyToolParameterFilter;
  ToolVersionToManyToolResourceFilter: ToolVersionToManyToolResourceFilter;
  ToolVersionToManyToolResultFilter: ToolVersionToManyToolResultFilter;
  ToolVersionToManyToolSecretFilter: ToolVersionToManyToolSecretFilter;
  ToolVersionToManyToolSourceFilter: ToolVersionToManyToolSourceFilter;
  ToolVersionToManyToolboxVersionToolVersionFilter: ToolVersionToManyToolboxVersionToolVersionFilter;
  ToolVersionToManyWorkflowSectionBlockFilter: ToolVersionToManyWorkflowSectionBlockFilter;
  ToolVersionToolId3B396B22FkToolIdInput: ToolVersionToolId3B396B22FkToolIdInput;
  ToolVersionToolId3B396B22FkToolIdInverseInput: ToolVersionToolId3B396B22FkToolIdInverseInput;
  ToolVersionToolId3B396B22FkToolIdToolCreateInput: ToolVersionToolId3B396B22FkToolIdToolCreateInput;
  ToolVersionToolId3B396B22FkToolIdToolVersionCreateInput: ToolVersionToolId3B396B22FkToolIdToolVersionCreateInput;
  ToolVersionToolVersionPkeyConnect: ToolVersionToolVersionPkeyConnect;
  ToolVersionToolVersionPkeyDelete: ToolVersionToolVersionPkeyDelete;
  ToolVersionToolversionToolVersionUniqueConnect: ToolVersionToolversionToolVersionUniqueConnect;
  ToolVersionToolversionToolVersionUniqueDelete: ToolVersionToolversionToolVersionUniqueDelete;
  ToolVersionsConnection: ResolverTypeWrapper<ToolVersionsConnection>;
  ToolVersionsEdge: ResolverTypeWrapper<ToolVersionsEdge>;
  ToolVersionsOrderBy: ToolVersionsOrderBy;
  Toolbox: ResolverTypeWrapper<Toolbox>;
  ToolboxCondition: ToolboxCondition;
  ToolboxExitcode: ResolverTypeWrapper<ToolboxExitcode>;
  ToolboxExitcodeCondition: ToolboxExitcodeCondition;
  ToolboxExitcodeFilter: ToolboxExitcodeFilter;
  ToolboxExitcodeInput: ToolboxExitcodeInput;
  ToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxExitcodeCodeKeyUpdate: ToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxExitcodeCodeKeyUpdate;
  ToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxExitcodePkeyUpdate: ToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxExitcodePkeyUpdate;
  ToolboxExitcodePatch: ToolboxExitcodePatch;
  ToolboxExitcodeToManyToolboxVersionExitcodeFilter: ToolboxExitcodeToManyToolboxVersionExitcodeFilter;
  ToolboxExitcodeToolboxExitcodeCodeKeyConnect: ToolboxExitcodeToolboxExitcodeCodeKeyConnect;
  ToolboxExitcodeToolboxExitcodeCodeKeyDelete: ToolboxExitcodeToolboxExitcodeCodeKeyDelete;
  ToolboxExitcodeToolboxExitcodePkeyConnect: ToolboxExitcodeToolboxExitcodePkeyConnect;
  ToolboxExitcodeToolboxExitcodePkeyDelete: ToolboxExitcodeToolboxExitcodePkeyDelete;
  ToolboxExitcodesConnection: ResolverTypeWrapper<ToolboxExitcodesConnection>;
  ToolboxExitcodesEdge: ResolverTypeWrapper<ToolboxExitcodesEdge>;
  ToolboxExitcodesOrderBy: ToolboxExitcodesOrderBy;
  ToolboxFilter: ToolboxFilter;
  ToolboxInput: ToolboxInput;
  ToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxKeyKeyUpdate: ToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxKeyKeyUpdate;
  ToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxPkeyUpdate: ToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxPkeyUpdate;
  ToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxKeyKeyUpdate: ToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxKeyKeyUpdate;
  ToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxPkeyUpdate: ToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxPkeyUpdate;
  ToolboxPatch: ToolboxPatch;
  ToolboxTenant: ResolverTypeWrapper<ToolboxTenant>;
  ToolboxTenantCondition: ToolboxTenantCondition;
  ToolboxTenantFilter: ToolboxTenantFilter;
  ToolboxTenantInput: ToolboxTenantInput;
  ToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingToolboxTenantsPkeyUpdate: ToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingToolboxTenantsPkeyUpdate;
  ToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingToolboxTenantsToolboxIdTenantId171F711CUniqUpdate: ToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingToolboxTenantsToolboxIdTenantId171F711CUniqUpdate;
  ToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxTenantsPkeyUpdate: ToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxTenantsPkeyUpdate;
  ToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxTenantsToolboxIdTenantId171F711CUniqUpdate: ToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxTenantsToolboxIdTenantId171F711CUniqUpdate;
  ToolboxTenantPatch: ToolboxTenantPatch;
  ToolboxTenantToolboxTenantsPkeyConnect: ToolboxTenantToolboxTenantsPkeyConnect;
  ToolboxTenantToolboxTenantsPkeyDelete: ToolboxTenantToolboxTenantsPkeyDelete;
  ToolboxTenantToolboxTenantsToolboxIdTenantId171F711CUniqConnect: ToolboxTenantToolboxTenantsToolboxIdTenantId171F711CUniqConnect;
  ToolboxTenantToolboxTenantsToolboxIdTenantId171F711CUniqDelete: ToolboxTenantToolboxTenantsToolboxIdTenantId171F711CUniqDelete;
  ToolboxTenantsConnection: ResolverTypeWrapper<ToolboxTenantsConnection>;
  ToolboxTenantsEdge: ResolverTypeWrapper<ToolboxTenantsEdge>;
  ToolboxTenantsOrderBy: ToolboxTenantsOrderBy;
  ToolboxTenantsTenantId5D0A40E4FkTenantIdInput: ToolboxTenantsTenantId5D0A40E4FkTenantIdInput;
  ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput: ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput;
  ToolboxTenantsTenantId5D0A40E4FkTenantIdTenantCreateInput: ToolboxTenantsTenantId5D0A40E4FkTenantIdTenantCreateInput;
  ToolboxTenantsTenantId5D0A40E4FkTenantIdToolboxTenantsCreateInput: ToolboxTenantsTenantId5D0A40E4FkTenantIdToolboxTenantsCreateInput;
  ToolboxTenantsToolboxId9D553881FkToolboxIdInput: ToolboxTenantsToolboxId9D553881FkToolboxIdInput;
  ToolboxTenantsToolboxId9D553881FkToolboxIdInverseInput: ToolboxTenantsToolboxId9D553881FkToolboxIdInverseInput;
  ToolboxTenantsToolboxId9D553881FkToolboxIdToolboxCreateInput: ToolboxTenantsToolboxId9D553881FkToolboxIdToolboxCreateInput;
  ToolboxTenantsToolboxId9D553881FkToolboxIdToolboxTenantsCreateInput: ToolboxTenantsToolboxId9D553881FkToolboxIdToolboxTenantsCreateInput;
  ToolboxToManyToolboxTenantFilter: ToolboxToManyToolboxTenantFilter;
  ToolboxToManyToolboxVersionFilter: ToolboxToManyToolboxVersionFilter;
  ToolboxToolboxKeyKeyConnect: ToolboxToolboxKeyKeyConnect;
  ToolboxToolboxKeyKeyDelete: ToolboxToolboxKeyKeyDelete;
  ToolboxToolboxPkeyConnect: ToolboxToolboxPkeyConnect;
  ToolboxToolboxPkeyDelete: ToolboxToolboxPkeyDelete;
  ToolboxVersion: ResolverTypeWrapper<ToolboxVersion>;
  ToolboxVersionCondition: ToolboxVersionCondition;
  ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInput: ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInput;
  ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInverseInput: ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInverseInput;
  ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEToolboxExitcodeCreateInput: ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEToolboxExitcodeCreateInput;
  ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEToolboxVersionExitcodesCreateInput: ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEToolboxVersionExitcodesCreateInput;
  ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInput: ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInput;
  ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInverseInput: ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInverseInput;
  ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVToolboxVersionCreateInput: ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVToolboxVersionCreateInput;
  ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVToolboxVersionExitcodesCreateInput: ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVToolboxVersionExitcodesCreateInput;
  ToolboxVersionExitcode: ResolverTypeWrapper<ToolboxVersionExitcode>;
  ToolboxVersionExitcodeCondition: ToolboxVersionExitcodeCondition;
  ToolboxVersionExitcodeFilter: ToolboxVersionExitcodeFilter;
  ToolboxVersionExitcodeInput: ToolboxVersionExitcodeInput;
  ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqUpdate: ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqUpdate;
  ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxVersionExitcodesPkeyUpdate: ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxVersionExitcodesPkeyUpdate;
  ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqUpdate: ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqUpdate;
  ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxVersionExitcodesPkeyUpdate: ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxVersionExitcodesPkeyUpdate;
  ToolboxVersionExitcodePatch: ToolboxVersionExitcodePatch;
  ToolboxVersionExitcodeToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqConnect: ToolboxVersionExitcodeToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqConnect;
  ToolboxVersionExitcodeToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqDelete: ToolboxVersionExitcodeToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqDelete;
  ToolboxVersionExitcodeToolboxVersionExitcodesPkeyConnect: ToolboxVersionExitcodeToolboxVersionExitcodesPkeyConnect;
  ToolboxVersionExitcodeToolboxVersionExitcodesPkeyDelete: ToolboxVersionExitcodeToolboxVersionExitcodesPkeyDelete;
  ToolboxVersionExitcodesConnection: ResolverTypeWrapper<ToolboxVersionExitcodesConnection>;
  ToolboxVersionExitcodesEdge: ResolverTypeWrapper<ToolboxVersionExitcodesEdge>;
  ToolboxVersionExitcodesOrderBy: ToolboxVersionExitcodesOrderBy;
  ToolboxVersionFilter: ToolboxVersionFilter;
  ToolboxVersionInput: ToolboxVersionInput;
  ToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingToolboxVersionPkeyUpdate: ToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingToolboxVersionPkeyUpdate;
  ToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingToolboxversionToolboxVersionUniqueUpdate: ToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingToolboxversionToolboxVersionUniqueUpdate;
  ToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxVersionPkeyUpdate: ToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxVersionPkeyUpdate;
  ToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxversionToolboxVersionUniqueUpdate: ToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxversionToolboxVersionUniqueUpdate;
  ToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxVersionPkeyUpdate: ToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxVersionPkeyUpdate;
  ToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxversionToolboxVersionUniqueUpdate: ToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxversionToolboxVersionUniqueUpdate;
  ToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxVersionPkeyUpdate: ToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxVersionPkeyUpdate;
  ToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxversionToolboxVersionUniqueUpdate: ToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxversionToolboxVersionUniqueUpdate;
  ToolboxVersionPatch: ToolboxVersionPatch;
  ToolboxVersionToManyToolboxVersionExitcodeFilter: ToolboxVersionToManyToolboxVersionExitcodeFilter;
  ToolboxVersionToManyToolboxVersionToolVersionFilter: ToolboxVersionToManyToolboxVersionToolVersionFilter;
  ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInput: ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInput;
  ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInverseInput: ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInverseInput;
  ToolboxVersionToolToolboxversionId25F78C89FkToolboxVToolboxVersionCreateInput: ToolboxVersionToolToolboxversionId25F78C89FkToolboxVToolboxVersionCreateInput;
  ToolboxVersionToolToolboxversionId25F78C89FkToolboxVToolboxVersionToolVersionsCreateInput: ToolboxVersionToolToolboxversionId25F78C89FkToolboxVToolboxVersionToolVersionsCreateInput;
  ToolboxVersionToolToolversionId4Ed31559FkToolVersInput: ToolboxVersionToolToolversionId4Ed31559FkToolVersInput;
  ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput: ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput;
  ToolboxVersionToolToolversionId4Ed31559FkToolVersToolVersionCreateInput: ToolboxVersionToolToolversionId4Ed31559FkToolVersToolVersionCreateInput;
  ToolboxVersionToolToolversionId4Ed31559FkToolVersToolboxVersionToolVersionsCreateInput: ToolboxVersionToolToolversionId4Ed31559FkToolVersToolboxVersionToolVersionsCreateInput;
  ToolboxVersionToolVersion: ResolverTypeWrapper<ToolboxVersionToolVersion>;
  ToolboxVersionToolVersionCondition: ToolboxVersionToolVersionCondition;
  ToolboxVersionToolVersionFilter: ToolboxVersionToolVersionFilter;
  ToolboxVersionToolVersionInput: ToolboxVersionToolVersionInput;
  ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqUpdate: ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqUpdate;
  ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxVersionToolVersionsPkeyUpdate: ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxVersionToolVersionsPkeyUpdate;
  ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqUpdate: ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqUpdate;
  ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolboxVersionToolVersionsPkeyUpdate: ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolboxVersionToolVersionsPkeyUpdate;
  ToolboxVersionToolVersionPatch: ToolboxVersionToolVersionPatch;
  ToolboxVersionToolVersionToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqConnect: ToolboxVersionToolVersionToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqConnect;
  ToolboxVersionToolVersionToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqDelete: ToolboxVersionToolVersionToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqDelete;
  ToolboxVersionToolVersionToolboxVersionToolVersionsPkeyConnect: ToolboxVersionToolVersionToolboxVersionToolVersionsPkeyConnect;
  ToolboxVersionToolVersionToolboxVersionToolVersionsPkeyDelete: ToolboxVersionToolVersionToolboxVersionToolVersionsPkeyDelete;
  ToolboxVersionToolVersionsConnection: ResolverTypeWrapper<ToolboxVersionToolVersionsConnection>;
  ToolboxVersionToolVersionsEdge: ResolverTypeWrapper<ToolboxVersionToolVersionsEdge>;
  ToolboxVersionToolVersionsOrderBy: ToolboxVersionToolVersionsOrderBy;
  ToolboxVersionToolboxId5Cc86066FkToolboxIdInput: ToolboxVersionToolboxId5Cc86066FkToolboxIdInput;
  ToolboxVersionToolboxId5Cc86066FkToolboxIdInverseInput: ToolboxVersionToolboxId5Cc86066FkToolboxIdInverseInput;
  ToolboxVersionToolboxId5Cc86066FkToolboxIdToolboxCreateInput: ToolboxVersionToolboxId5Cc86066FkToolboxIdToolboxCreateInput;
  ToolboxVersionToolboxId5Cc86066FkToolboxIdToolboxVersionCreateInput: ToolboxVersionToolboxId5Cc86066FkToolboxIdToolboxVersionCreateInput;
  ToolboxVersionToolboxVersionPkeyConnect: ToolboxVersionToolboxVersionPkeyConnect;
  ToolboxVersionToolboxVersionPkeyDelete: ToolboxVersionToolboxVersionPkeyDelete;
  ToolboxVersionToolboxversionToolboxVersionUniqueConnect: ToolboxVersionToolboxversionToolboxVersionUniqueConnect;
  ToolboxVersionToolboxversionToolboxVersionUniqueDelete: ToolboxVersionToolboxversionToolboxVersionUniqueDelete;
  ToolboxVersionsConnection: ResolverTypeWrapper<ToolboxVersionsConnection>;
  ToolboxVersionsEdge: ResolverTypeWrapper<ToolboxVersionsEdge>;
  ToolboxVersionsOrderBy: ToolboxVersionsOrderBy;
  ToolboxesConnection: ResolverTypeWrapper<ToolboxesConnection>;
  ToolboxesEdge: ResolverTypeWrapper<ToolboxesEdge>;
  ToolboxesOrderBy: ToolboxesOrderBy;
  ToolsConnection: ResolverTypeWrapper<ToolsConnection>;
  ToolsEdge: ResolverTypeWrapper<ToolsEdge>;
  ToolsOrderBy: ToolsOrderBy;
  UUID: ResolverTypeWrapper<Scalars["UUID"]["output"]>;
  UUIDFilter: UuidFilter;
  UpdateAnalysisTableByIdInput: UpdateAnalysisTableByIdInput;
  UpdateAnalysisTableGroupByIdInput: UpdateAnalysisTableGroupByIdInput;
  UpdateAnalysisTableGroupPayload: ResolverTypeWrapper<UpdateAnalysisTableGroupPayload>;
  UpdateAnalysisTablePayload: ResolverTypeWrapper<UpdateAnalysisTablePayload>;
  UpdateAnalysisTableRowAttachResultByIdInput: UpdateAnalysisTableRowAttachResultByIdInput;
  UpdateAnalysisTableRowAttachResultPayload: ResolverTypeWrapper<UpdateAnalysisTableRowAttachResultPayload>;
  UpdateAnalysisTableRowByIdInput: UpdateAnalysisTableRowByIdInput;
  UpdateAnalysisTableRowByTaskIdInput: UpdateAnalysisTableRowByTaskIdInput;
  UpdateAnalysisTableRowPayload: ResolverTypeWrapper<UpdateAnalysisTableRowPayload>;
  UpdateApplicationUserByEmailInput: UpdateApplicationUserByEmailInput;
  UpdateApplicationUserByIdInput: UpdateApplicationUserByIdInput;
  UpdateApplicationUserByUsernameInput: UpdateApplicationUserByUsernameInput;
  UpdateApplicationUserGroupByIdInput: UpdateApplicationUserGroupByIdInput;
  UpdateApplicationUserGroupByUserIdAndGroupIdInput: UpdateApplicationUserGroupByUserIdAndGroupIdInput;
  UpdateApplicationUserGroupPayload: ResolverTypeWrapper<UpdateApplicationUserGroupPayload>;
  UpdateApplicationUserMembershipByIdInput: UpdateApplicationUserMembershipByIdInput;
  UpdateApplicationUserMembershipByUserIdAndTenantIdInput: UpdateApplicationUserMembershipByUserIdAndTenantIdInput;
  UpdateApplicationUserMembershipPayload: ResolverTypeWrapper<UpdateApplicationUserMembershipPayload>;
  UpdateApplicationUserMetadatumByIdInput: UpdateApplicationUserMetadatumByIdInput;
  UpdateApplicationUserMetadatumByUserIdAndKeyInput: UpdateApplicationUserMetadatumByUserIdAndKeyInput;
  UpdateApplicationUserMetadatumPayload: ResolverTypeWrapper<UpdateApplicationUserMetadatumPayload>;
  UpdateApplicationUserPayload: ResolverTypeWrapper<UpdateApplicationUserPayload>;
  UpdateApplicationUserSessionByIdInput: UpdateApplicationUserSessionByIdInput;
  UpdateApplicationUserSessionPayload: ResolverTypeWrapper<UpdateApplicationUserSessionPayload>;
  UpdateApplicationUserUserPermissionByIdInput: UpdateApplicationUserUserPermissionByIdInput;
  UpdateApplicationUserUserPermissionByUserIdAndPermissionIdInput: UpdateApplicationUserUserPermissionByUserIdAndPermissionIdInput;
  UpdateApplicationUserUserPermissionPayload: ResolverTypeWrapper<UpdateApplicationUserUserPermissionPayload>;
  UpdateAuthGroupByIdInput: UpdateAuthGroupByIdInput;
  UpdateAuthGroupByNameInput: UpdateAuthGroupByNameInput;
  UpdateAuthGroupPayload: ResolverTypeWrapper<UpdateAuthGroupPayload>;
  UpdateAuthGroupPermissionByGroupIdAndPermissionIdInput: UpdateAuthGroupPermissionByGroupIdAndPermissionIdInput;
  UpdateAuthGroupPermissionByIdInput: UpdateAuthGroupPermissionByIdInput;
  UpdateAuthGroupPermissionPayload: ResolverTypeWrapper<UpdateAuthGroupPermissionPayload>;
  UpdateAuthPermissionByContentTypeIdAndCodenameInput: UpdateAuthPermissionByContentTypeIdAndCodenameInput;
  UpdateAuthPermissionByIdInput: UpdateAuthPermissionByIdInput;
  UpdateAuthPermissionPayload: ResolverTypeWrapper<UpdateAuthPermissionPayload>;
  UpdateAvatarByIdInput: UpdateAvatarByIdInput;
  UpdateAvatarByUserIdInput: UpdateAvatarByUserIdInput;
  UpdateAvatarPayload: ResolverTypeWrapper<UpdateAvatarPayload>;
  UpdateAwsBatchTaskByTaskexecutorPtrIdInput: UpdateAwsBatchTaskByTaskexecutorPtrIdInput;
  UpdateAwsBatchTaskPayload: ResolverTypeWrapper<UpdateAwsBatchTaskPayload>;
  UpdateAwsCognitoUserByUserexecutorPtrIdInput: UpdateAwsCognitoUserByUserexecutorPtrIdInput;
  UpdateAwsCognitoUserPayload: ResolverTypeWrapper<UpdateAwsCognitoUserPayload>;
  UpdateAwsEcrImageByContainerimageexecutorPtrIdInput: UpdateAwsEcrImageByContainerimageexecutorPtrIdInput;
  UpdateAwsEcrImagePayload: ResolverTypeWrapper<UpdateAwsEcrImagePayload>;
  UpdateAwsMockBatchTaskByAwstaskexecutorPtrIdInput: UpdateAwsMockBatchTaskByAwstaskexecutorPtrIdInput;
  UpdateAwsMockBatchTaskPayload: ResolverTypeWrapper<UpdateAwsMockBatchTaskPayload>;
  UpdateAwsMockEcrImageByAwscontainerimageexecutorPtrIdInput: UpdateAwsMockEcrImageByAwscontainerimageexecutorPtrIdInput;
  UpdateAwsMockEcrImagePayload: ResolverTypeWrapper<UpdateAwsMockEcrImagePayload>;
  UpdateAwsMockS3FileByAwsfileexecutorPtrIdInput: UpdateAwsMockS3FileByAwsfileexecutorPtrIdInput;
  UpdateAwsMockS3FilePayload: ResolverTypeWrapper<UpdateAwsMockS3FilePayload>;
  UpdateAwsS3FileByFileexecutorPtrIdInput: UpdateAwsS3FileByFileexecutorPtrIdInput;
  UpdateAwsS3FilePayload: ResolverTypeWrapper<UpdateAwsS3FilePayload>;
  UpdateContainerImageByExecutorIdInput: UpdateContainerImageByExecutorIdInput;
  UpdateContainerImageByIdInput: UpdateContainerImageByIdInput;
  UpdateContainerImageByNameAndTagInput: UpdateContainerImageByNameAndTagInput;
  UpdateContainerImageByRepositoryAndTagInput: UpdateContainerImageByRepositoryAndTagInput;
  UpdateContainerImageByToolboxVersionIdInput: UpdateContainerImageByToolboxVersionIdInput;
  UpdateContainerImagePayload: ResolverTypeWrapper<UpdateContainerImagePayload>;
  UpdateCostByIdInput: UpdateCostByIdInput;
  UpdateCostPayload: ResolverTypeWrapper<UpdateCostPayload>;
  UpdateDatasetByIdInput: UpdateDatasetByIdInput;
  UpdateDatasetByProjectIdAndPrefixInput: UpdateDatasetByProjectIdAndPrefixInput;
  UpdateDatasetByRecordingsTableIdInput: UpdateDatasetByRecordingsTableIdInput;
  UpdateDatasetByTenantIdAndKeyInput: UpdateDatasetByTenantIdAndKeyInput;
  UpdateDatasetMetadatumByIdInput: UpdateDatasetMetadatumByIdInput;
  UpdateDatasetMetadatumPayload: ResolverTypeWrapper<UpdateDatasetMetadatumPayload>;
  UpdateDatasetPayload: ResolverTypeWrapper<UpdateDatasetPayload>;
  UpdateDatasetRecordingsTableByIdInput: UpdateDatasetRecordingsTableByIdInput;
  UpdateDatasetRecordingsTableColumnByIdInput: UpdateDatasetRecordingsTableColumnByIdInput;
  UpdateDatasetRecordingsTableColumnOrderByIdInput: UpdateDatasetRecordingsTableColumnOrderByIdInput;
  UpdateDatasetRecordingsTableColumnOrderPayload: ResolverTypeWrapper<UpdateDatasetRecordingsTableColumnOrderPayload>;
  UpdateDatasetRecordingsTableColumnPayload: ResolverTypeWrapper<UpdateDatasetRecordingsTableColumnPayload>;
  UpdateDatasetRecordingsTablePayload: ResolverTypeWrapper<UpdateDatasetRecordingsTablePayload>;
  UpdateDatasetVersionByIdInput: UpdateDatasetVersionByIdInput;
  UpdateDatasetVersionPayload: ResolverTypeWrapper<UpdateDatasetVersionPayload>;
  UpdateDjangoAdminLogByIdInput: UpdateDjangoAdminLogByIdInput;
  UpdateDjangoAdminLogPayload: ResolverTypeWrapper<UpdateDjangoAdminLogPayload>;
  UpdateDjangoContentTypeByAppLabelAndModelInput: UpdateDjangoContentTypeByAppLabelAndModelInput;
  UpdateDjangoContentTypeByIdInput: UpdateDjangoContentTypeByIdInput;
  UpdateDjangoContentTypePayload: ResolverTypeWrapper<UpdateDjangoContentTypePayload>;
  UpdateDjangoMigrationByIdInput: UpdateDjangoMigrationByIdInput;
  UpdateDjangoMigrationPayload: ResolverTypeWrapper<UpdateDjangoMigrationPayload>;
  UpdateDjangoSesSesstatByDateInput: UpdateDjangoSesSesstatByDateInput;
  UpdateDjangoSesSesstatByIdInput: UpdateDjangoSesSesstatByIdInput;
  UpdateDjangoSesSesstatPayload: ResolverTypeWrapper<UpdateDjangoSesSesstatPayload>;
  UpdateDjangoSessionBySessionKeyInput: UpdateDjangoSessionBySessionKeyInput;
  UpdateDjangoSessionPayload: ResolverTypeWrapper<UpdateDjangoSessionPayload>;
  UpdateDrsFileexecutorByIdInput: UpdateDrsFileexecutorByIdInput;
  UpdateDrsFileexecutorPayload: ResolverTypeWrapper<UpdateDrsFileexecutorPayload>;
  UpdateDrsMockfileexecutorByFileexecutorPtrIdInput: UpdateDrsMockfileexecutorByFileexecutorPtrIdInput;
  UpdateDrsMockfileexecutorPayload: ResolverTypeWrapper<UpdateDrsMockfileexecutorPayload>;
  UpdateEulaVersionByIdInput: UpdateEulaVersionByIdInput;
  UpdateEulaVersionByVersionInput: UpdateEulaVersionByVersionInput;
  UpdateEulaVersionPayload: ResolverTypeWrapper<UpdateEulaVersionPayload>;
  UpdateEulaVersionUserAcceptanceByIdInput: UpdateEulaVersionUserAcceptanceByIdInput;
  UpdateEulaVersionUserAcceptanceByUserIdAndEulaVersionIdInput: UpdateEulaVersionUserAcceptanceByUserIdAndEulaVersionIdInput;
  UpdateEulaVersionUserAcceptancePayload: ResolverTypeWrapper<UpdateEulaVersionUserAcceptancePayload>;
  UpdateExplorerQueryByIdInput: UpdateExplorerQueryByIdInput;
  UpdateExplorerQueryPayload: ResolverTypeWrapper<UpdateExplorerQueryPayload>;
  UpdateExplorerQueryfavoriteByIdInput: UpdateExplorerQueryfavoriteByIdInput;
  UpdateExplorerQueryfavoriteByQueryIdAndUserIdInput: UpdateExplorerQueryfavoriteByQueryIdAndUserIdInput;
  UpdateExplorerQueryfavoritePayload: ResolverTypeWrapper<UpdateExplorerQueryfavoritePayload>;
  UpdateExplorerQuerylogByIdInput: UpdateExplorerQuerylogByIdInput;
  UpdateExplorerQuerylogPayload: ResolverTypeWrapper<UpdateExplorerQuerylogPayload>;
  UpdateFileActivityByIdInput: UpdateFileActivityByIdInput;
  UpdateFileActivityPayload: ResolverTypeWrapper<UpdateFileActivityPayload>;
  UpdateFileByIdInput: UpdateFileByIdInput;
  UpdateFileMetadatumByIdInput: UpdateFileMetadatumByIdInput;
  UpdateFileMetadatumPayload: ResolverTypeWrapper<UpdateFileMetadatumPayload>;
  UpdateFileOutputGroupByIdInput: UpdateFileOutputGroupByIdInput;
  UpdateFileOutputGroupByTaskIdInput: UpdateFileOutputGroupByTaskIdInput;
  UpdateFileOutputGroupMetadatumByIdInput: UpdateFileOutputGroupMetadatumByIdInput;
  UpdateFileOutputGroupMetadatumPayload: ResolverTypeWrapper<UpdateFileOutputGroupMetadatumPayload>;
  UpdateFileOutputGroupPayload: ResolverTypeWrapper<UpdateFileOutputGroupPayload>;
  UpdateFilePayload: ResolverTypeWrapper<UpdateFilePayload>;
  UpdateFileRecordingGroupByIdInput: UpdateFileRecordingGroupByIdInput;
  UpdateFileRecordingGroupMetadatumByIdInput: UpdateFileRecordingGroupMetadatumByIdInput;
  UpdateFileRecordingGroupMetadatumPayload: ResolverTypeWrapper<UpdateFileRecordingGroupMetadatumPayload>;
  UpdateFileRecordingGroupPayload: ResolverTypeWrapper<UpdateFileRecordingGroupPayload>;
  UpdateMetadataValueByIdInput: UpdateMetadataValueByIdInput;
  UpdateMetadataValuePayload: ResolverTypeWrapper<UpdateMetadataValuePayload>;
  UpdateMetadatumByIdInput: UpdateMetadatumByIdInput;
  UpdateMetadatumPayload: ResolverTypeWrapper<UpdateMetadatumPayload>;
  UpdateNotificationByIdInput: UpdateNotificationByIdInput;
  UpdateNotificationPayload: ResolverTypeWrapper<UpdateNotificationPayload>;
  UpdateOutputGroupFileByIdInput: UpdateOutputGroupFileByIdInput;
  UpdateOutputGroupFilePayload: ResolverTypeWrapper<UpdateOutputGroupFilePayload>;
  UpdateRecordingGroupFileByIdInput: UpdateRecordingGroupFileByIdInput;
  UpdateRecordingGroupFilePayload: ResolverTypeWrapper<UpdateRecordingGroupFilePayload>;
  UpdateRunByIdInput: UpdateRunByIdInput;
  UpdateRunPayload: ResolverTypeWrapper<UpdateRunPayload>;
  UpdateTagByIdInput: UpdateTagByIdInput;
  UpdateTagPayload: ResolverTypeWrapper<UpdateTagPayload>;
  UpdateTaskActivityByIdInput: UpdateTaskActivityByIdInput;
  UpdateTaskActivityByTaskIdInput: UpdateTaskActivityByTaskIdInput;
  UpdateTaskActivityPayload: ResolverTypeWrapper<UpdateTaskActivityPayload>;
  UpdateTaskByExecutorIdInput: UpdateTaskByExecutorIdInput;
  UpdateTaskByIdInput: UpdateTaskByIdInput;
  UpdateTaskPayload: ResolverTypeWrapper<UpdateTaskPayload>;
  UpdateTaskResourceByIdInput: UpdateTaskResourceByIdInput;
  UpdateTaskResourcePayload: ResolverTypeWrapper<UpdateTaskResourcePayload>;
  UpdateTaskSourceByIdInput: UpdateTaskSourceByIdInput;
  UpdateTaskSourcePayload: ResolverTypeWrapper<UpdateTaskSourcePayload>;
  UpdateTaskStatusLogByIdInput: UpdateTaskStatusLogByIdInput;
  UpdateTaskStatusLogByTaskIdAndStatusInput: UpdateTaskStatusLogByTaskIdAndStatusInput;
  UpdateTaskStatusLogPayload: ResolverTypeWrapper<UpdateTaskStatusLogPayload>;
  UpdateTenantByIdInput: UpdateTenantByIdInput;
  UpdateTenantByKeyInput: UpdateTenantByKeyInput;
  UpdateTenantByNameInput: UpdateTenantByNameInput;
  UpdateTenantContractAllowanceByIdInput: UpdateTenantContractAllowanceByIdInput;
  UpdateTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeInput: UpdateTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeInput;
  UpdateTenantContractAllowancePayload: ResolverTypeWrapper<UpdateTenantContractAllowancePayload>;
  UpdateTenantContractByIdInput: UpdateTenantContractByIdInput;
  UpdateTenantContractByNameInput: UpdateTenantContractByNameInput;
  UpdateTenantContractPayload: ResolverTypeWrapper<UpdateTenantContractPayload>;
  UpdateTenantContractTransactionByIdInput: UpdateTenantContractTransactionByIdInput;
  UpdateTenantContractTransactionPayload: ResolverTypeWrapper<UpdateTenantContractTransactionPayload>;
  UpdateTenantMockuserexecutorByUserexecutorPtrIdInput: UpdateTenantMockuserexecutorByUserexecutorPtrIdInput;
  UpdateTenantMockuserexecutorPayload: ResolverTypeWrapper<UpdateTenantMockuserexecutorPayload>;
  UpdateTenantMonthlyConsumedResourceByIdInput: UpdateTenantMonthlyConsumedResourceByIdInput;
  UpdateTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractInput: UpdateTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractInput;
  UpdateTenantMonthlyConsumedResourcePayload: ResolverTypeWrapper<UpdateTenantMonthlyConsumedResourcePayload>;
  UpdateTenantPayload: ResolverTypeWrapper<UpdateTenantPayload>;
  UpdateTenantUserexecutorByIdInput: UpdateTenantUserexecutorByIdInput;
  UpdateTenantUserexecutorPayload: ResolverTypeWrapper<UpdateTenantUserexecutorPayload>;
  UpdateTesContainerimageexecutorByIdInput: UpdateTesContainerimageexecutorByIdInput;
  UpdateTesContainerimageexecutorPayload: ResolverTypeWrapper<UpdateTesContainerimageexecutorPayload>;
  UpdateTesMockcontainerimageexecutorByContainerimageexecutorPtrIdInput: UpdateTesMockcontainerimageexecutorByContainerimageexecutorPtrIdInput;
  UpdateTesMockcontainerimageexecutorPayload: ResolverTypeWrapper<UpdateTesMockcontainerimageexecutorPayload>;
  UpdateTesMocktaskexecutorByTaskexecutorPtrIdInput: UpdateTesMocktaskexecutorByTaskexecutorPtrIdInput;
  UpdateTesMocktaskexecutorPayload: ResolverTypeWrapper<UpdateTesMocktaskexecutorPayload>;
  UpdateTesTaskexecutorByIdInput: UpdateTesTaskexecutorByIdInput;
  UpdateTesTaskexecutorPayload: ResolverTypeWrapper<UpdateTesTaskexecutorPayload>;
  UpdateToolByIdInput: UpdateToolByIdInput;
  UpdateToolByKeyInput: UpdateToolByKeyInput;
  UpdateToolCategoryByIdInput: UpdateToolCategoryByIdInput;
  UpdateToolCategoryByKeyInput: UpdateToolCategoryByKeyInput;
  UpdateToolCategoryPayload: ResolverTypeWrapper<UpdateToolCategoryPayload>;
  UpdateToolDisplayCategoryByIdInput: UpdateToolDisplayCategoryByIdInput;
  UpdateToolDisplayCategoryByToolIdAndToolcategoryIdInput: UpdateToolDisplayCategoryByToolIdAndToolcategoryIdInput;
  UpdateToolDisplayCategoryPayload: ResolverTypeWrapper<UpdateToolDisplayCategoryPayload>;
  UpdateToolParameterByIdInput: UpdateToolParameterByIdInput;
  UpdateToolParameterPayload: ResolverTypeWrapper<UpdateToolParameterPayload>;
  UpdateToolPayload: ResolverTypeWrapper<UpdateToolPayload>;
  UpdateToolResourceByIdInput: UpdateToolResourceByIdInput;
  UpdateToolResourceByTypeAndToolVersionIdInput: UpdateToolResourceByTypeAndToolVersionIdInput;
  UpdateToolResourcePayload: ResolverTypeWrapper<UpdateToolResourcePayload>;
  UpdateToolResultByIdInput: UpdateToolResultByIdInput;
  UpdateToolResultPayload: ResolverTypeWrapper<UpdateToolResultPayload>;
  UpdateToolSecretByIdInput: UpdateToolSecretByIdInput;
  UpdateToolSecretPayload: ResolverTypeWrapper<UpdateToolSecretPayload>;
  UpdateToolSourceByIdInput: UpdateToolSourceByIdInput;
  UpdateToolSourcePayload: ResolverTypeWrapper<UpdateToolSourcePayload>;
  UpdateToolTenantByIdInput: UpdateToolTenantByIdInput;
  UpdateToolTenantByToolIdAndTenantIdInput: UpdateToolTenantByToolIdAndTenantIdInput;
  UpdateToolTenantPayload: ResolverTypeWrapper<UpdateToolTenantPayload>;
  UpdateToolVersionByIdInput: UpdateToolVersionByIdInput;
  UpdateToolVersionByToolIdAndVersionInput: UpdateToolVersionByToolIdAndVersionInput;
  UpdateToolVersionPayload: ResolverTypeWrapper<UpdateToolVersionPayload>;
  UpdateToolboxByIdInput: UpdateToolboxByIdInput;
  UpdateToolboxByKeyInput: UpdateToolboxByKeyInput;
  UpdateToolboxExitcodeByCodeInput: UpdateToolboxExitcodeByCodeInput;
  UpdateToolboxExitcodeByIdInput: UpdateToolboxExitcodeByIdInput;
  UpdateToolboxExitcodePayload: ResolverTypeWrapper<UpdateToolboxExitcodePayload>;
  UpdateToolboxPayload: ResolverTypeWrapper<UpdateToolboxPayload>;
  UpdateToolboxTenantByIdInput: UpdateToolboxTenantByIdInput;
  UpdateToolboxTenantByToolboxIdAndTenantIdInput: UpdateToolboxTenantByToolboxIdAndTenantIdInput;
  UpdateToolboxTenantPayload: ResolverTypeWrapper<UpdateToolboxTenantPayload>;
  UpdateToolboxVersionByIdInput: UpdateToolboxVersionByIdInput;
  UpdateToolboxVersionByToolboxIdAndVersionInput: UpdateToolboxVersionByToolboxIdAndVersionInput;
  UpdateToolboxVersionExitcodeByIdInput: UpdateToolboxVersionExitcodeByIdInput;
  UpdateToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdInput: UpdateToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdInput;
  UpdateToolboxVersionExitcodePayload: ResolverTypeWrapper<UpdateToolboxVersionExitcodePayload>;
  UpdateToolboxVersionPayload: ResolverTypeWrapper<UpdateToolboxVersionPayload>;
  UpdateToolboxVersionToolVersionByIdInput: UpdateToolboxVersionToolVersionByIdInput;
  UpdateToolboxVersionToolVersionByToolboxversionIdAndToolversionIdInput: UpdateToolboxVersionToolVersionByToolboxversionIdAndToolversionIdInput;
  UpdateToolboxVersionToolVersionPayload: ResolverTypeWrapper<UpdateToolboxVersionToolVersionPayload>;
  UpdateWorkflowByIdInput: UpdateWorkflowByIdInput;
  UpdateWorkflowDatumByIdInput: UpdateWorkflowDatumByIdInput;
  UpdateWorkflowDatumPayload: ResolverTypeWrapper<UpdateWorkflowDatumPayload>;
  UpdateWorkflowPayload: ResolverTypeWrapper<UpdateWorkflowPayload>;
  UpdateWorkflowResultByIdInput: UpdateWorkflowResultByIdInput;
  UpdateWorkflowResultPayload: ResolverTypeWrapper<UpdateWorkflowResultPayload>;
  UpdateWorkflowResultTagByIdInput: UpdateWorkflowResultTagByIdInput;
  UpdateWorkflowResultTagByResultIdAndTagIdInput: UpdateWorkflowResultTagByResultIdAndTagIdInput;
  UpdateWorkflowResultTagPayload: ResolverTypeWrapper<UpdateWorkflowResultTagPayload>;
  UpdateWorkflowResultWorkflowVersionByIdInput: UpdateWorkflowResultWorkflowVersionByIdInput;
  UpdateWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdInput: UpdateWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdInput;
  UpdateWorkflowResultWorkflowVersionPayload: ResolverTypeWrapper<UpdateWorkflowResultWorkflowVersionPayload>;
  UpdateWorkflowSectionBlockByIdInput: UpdateWorkflowSectionBlockByIdInput;
  UpdateWorkflowSectionBlockPayload: ResolverTypeWrapper<UpdateWorkflowSectionBlockPayload>;
  UpdateWorkflowSectionBlockSectionByBlockIdAndSectionIdInput: UpdateWorkflowSectionBlockSectionByBlockIdAndSectionIdInput;
  UpdateWorkflowSectionBlockSectionByIdInput: UpdateWorkflowSectionBlockSectionByIdInput;
  UpdateWorkflowSectionBlockSectionPayload: ResolverTypeWrapper<UpdateWorkflowSectionBlockSectionPayload>;
  UpdateWorkflowSectionByIdInput: UpdateWorkflowSectionByIdInput;
  UpdateWorkflowSectionPayload: ResolverTypeWrapper<UpdateWorkflowSectionPayload>;
  UpdateWorkflowSectionWorkflowVersionByIdInput: UpdateWorkflowSectionWorkflowVersionByIdInput;
  UpdateWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdInput: UpdateWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdInput;
  UpdateWorkflowSectionWorkflowVersionPayload: ResolverTypeWrapper<UpdateWorkflowSectionWorkflowVersionPayload>;
  UpdateWorkflowSourceByIdInput: UpdateWorkflowSourceByIdInput;
  UpdateWorkflowSourcePayload: ResolverTypeWrapper<UpdateWorkflowSourcePayload>;
  UpdateWorkflowSourceTagByIdInput: UpdateWorkflowSourceTagByIdInput;
  UpdateWorkflowSourceTagBySourceIdAndTagIdInput: UpdateWorkflowSourceTagBySourceIdAndTagIdInput;
  UpdateWorkflowSourceTagPayload: ResolverTypeWrapper<UpdateWorkflowSourceTagPayload>;
  UpdateWorkflowSourceWorkflowVersionByIdInput: UpdateWorkflowSourceWorkflowVersionByIdInput;
  UpdateWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdInput: UpdateWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdInput;
  UpdateWorkflowSourceWorkflowVersionPayload: ResolverTypeWrapper<UpdateWorkflowSourceWorkflowVersionPayload>;
  UpdateWorkflowVersionByIdInput: UpdateWorkflowVersionByIdInput;
  UpdateWorkflowVersionPayload: ResolverTypeWrapper<UpdateWorkflowVersionPayload>;
  UserAccessToProject: ResolverTypeWrapper<UserAccessToProject>;
  UserAccessToProjectCondition: UserAccessToProjectCondition;
  UserAccessToProjectFilter: UserAccessToProjectFilter;
  UserAccessToProjectsConnection: ResolverTypeWrapper<UserAccessToProjectsConnection>;
  UserAccessToProjectsEdge: ResolverTypeWrapper<UserAccessToProjectsEdge>;
  UserAccessToProjectsOrderBy: UserAccessToProjectsOrderBy;
  Workflow: ResolverTypeWrapper<Workflow>;
  WorkflowCondition: WorkflowCondition;
  WorkflowContainerIdE73A96E5FkContainerImageIdContainerImageCreateInput: WorkflowContainerIdE73A96E5FkContainerImageIdContainerImageCreateInput;
  WorkflowContainerIdE73A96E5FkContainerImageIdInput: WorkflowContainerIdE73A96E5FkContainerImageIdInput;
  WorkflowContainerIdE73A96E5FkContainerImageIdInverseInput: WorkflowContainerIdE73A96E5FkContainerImageIdInverseInput;
  WorkflowContainerIdE73A96E5FkContainerImageIdWorkflowCreateInput: WorkflowContainerIdE73A96E5FkContainerImageIdWorkflowCreateInput;
  WorkflowDataConnection: ResolverTypeWrapper<WorkflowDataConnection>;
  WorkflowDataEdge: ResolverTypeWrapper<WorkflowDataEdge>;
  WorkflowDataOrderBy: WorkflowDataOrderBy;
  WorkflowDatum: ResolverTypeWrapper<WorkflowDatum>;
  WorkflowDatumCondition: WorkflowDatumCondition;
  WorkflowDatumFilter: WorkflowDatumFilter;
  WorkflowDatumInput: WorkflowDatumInput;
  WorkflowDatumOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdUsingWorkflowDataPkeyUpdate: WorkflowDatumOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdUsingWorkflowDataPkeyUpdate;
  WorkflowDatumOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdUsingWorkflowDataPkeyUpdate: WorkflowDatumOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdUsingWorkflowDataPkeyUpdate;
  WorkflowDatumPatch: WorkflowDatumPatch;
  WorkflowDatumToManyWorkflowResultFilter: WorkflowDatumToManyWorkflowResultFilter;
  WorkflowDatumToManyWorkflowSectionBlockFilter: WorkflowDatumToManyWorkflowSectionBlockFilter;
  WorkflowDatumWorkflowDataPkeyConnect: WorkflowDatumWorkflowDataPkeyConnect;
  WorkflowDatumWorkflowDataPkeyDelete: WorkflowDatumWorkflowDataPkeyDelete;
  WorkflowFilter: WorkflowFilter;
  WorkflowInput: WorkflowInput;
  WorkflowOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingWorkflowPkeyUpdate: WorkflowOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingWorkflowPkeyUpdate;
  WorkflowOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingWorkflowPkeyUpdate: WorkflowOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingWorkflowPkeyUpdate;
  WorkflowOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingWorkflowPkeyUpdate: WorkflowOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingWorkflowPkeyUpdate;
  WorkflowOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdUsingWorkflowPkeyUpdate: WorkflowOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdUsingWorkflowPkeyUpdate;
  WorkflowPatch: WorkflowPatch;
  WorkflowResult: ResolverTypeWrapper<WorkflowResult>;
  WorkflowResultCondition: WorkflowResultCondition;
  WorkflowResultFilter: WorkflowResultFilter;
  WorkflowResultInput: WorkflowResultInput;
  WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInput: WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInput;
  WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInverseInput: WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInverseInput;
  WorkflowResultObjectIdC561D1C5FkWorkflowDataIdWorkflowDataCreateInput: WorkflowResultObjectIdC561D1C5FkWorkflowDataIdWorkflowDataCreateInput;
  WorkflowResultObjectIdC561D1C5FkWorkflowDataIdWorkflowResultCreateInput: WorkflowResultObjectIdC561D1C5FkWorkflowDataIdWorkflowResultCreateInput;
  WorkflowResultOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdUsingWorkflowResultPkeyUpdate: WorkflowResultOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdUsingWorkflowResultPkeyUpdate;
  WorkflowResultOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdUsingWorkflowResultPkeyUpdate: WorkflowResultOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdUsingWorkflowResultPkeyUpdate;
  WorkflowResultOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowUsingWorkflowResultPkeyUpdate: WorkflowResultOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowUsingWorkflowResultPkeyUpdate;
  WorkflowResultPatch: WorkflowResultPatch;
  WorkflowResultTag: ResolverTypeWrapper<WorkflowResultTag>;
  WorkflowResultTagCondition: WorkflowResultTagCondition;
  WorkflowResultTagFilter: WorkflowResultTagFilter;
  WorkflowResultTagInput: WorkflowResultTagInput;
  WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdUsingWorkflowResultTagsPkeyUpdate: WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdUsingWorkflowResultTagsPkeyUpdate;
  WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdUsingWorkflowResultTagsResultIdTagId960B8A2CUniqUpdate: WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdUsingWorkflowResultTagsResultIdTagId960B8A2CUniqUpdate;
  WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdUsingWorkflowResultTagsPkeyUpdate: WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdUsingWorkflowResultTagsPkeyUpdate;
  WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdUsingWorkflowResultTagsResultIdTagId960B8A2CUniqUpdate: WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdUsingWorkflowResultTagsResultIdTagId960B8A2CUniqUpdate;
  WorkflowResultTagPatch: WorkflowResultTagPatch;
  WorkflowResultTagWorkflowResultTagsPkeyConnect: WorkflowResultTagWorkflowResultTagsPkeyConnect;
  WorkflowResultTagWorkflowResultTagsPkeyDelete: WorkflowResultTagWorkflowResultTagsPkeyDelete;
  WorkflowResultTagWorkflowResultTagsResultIdTagId960B8A2CUniqConnect: WorkflowResultTagWorkflowResultTagsResultIdTagId960B8A2CUniqConnect;
  WorkflowResultTagWorkflowResultTagsResultIdTagId960B8A2CUniqDelete: WorkflowResultTagWorkflowResultTagsResultIdTagId960B8A2CUniqDelete;
  WorkflowResultTagsConnection: ResolverTypeWrapper<WorkflowResultTagsConnection>;
  WorkflowResultTagsEdge: ResolverTypeWrapper<WorkflowResultTagsEdge>;
  WorkflowResultTagsOrderBy: WorkflowResultTagsOrderBy;
  WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInput: WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInput;
  WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInverseInput: WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInverseInput;
  WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdWorkflowResultCreateInput: WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdWorkflowResultCreateInput;
  WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdWorkflowResultTagsCreateInput: WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdWorkflowResultTagsCreateInput;
  WorkflowResultTagsTagId4B7994A6FkTagIdInput: WorkflowResultTagsTagId4B7994A6FkTagIdInput;
  WorkflowResultTagsTagId4B7994A6FkTagIdInverseInput: WorkflowResultTagsTagId4B7994A6FkTagIdInverseInput;
  WorkflowResultTagsTagId4B7994A6FkTagIdTagCreateInput: WorkflowResultTagsTagId4B7994A6FkTagIdTagCreateInput;
  WorkflowResultTagsTagId4B7994A6FkTagIdWorkflowResultTagsCreateInput: WorkflowResultTagsTagId4B7994A6FkTagIdWorkflowResultTagsCreateInput;
  WorkflowResultToManyWorkflowResultTagFilter: WorkflowResultToManyWorkflowResultTagFilter;
  WorkflowResultToManyWorkflowResultWorkflowVersionFilter: WorkflowResultToManyWorkflowResultWorkflowVersionFilter;
  WorkflowResultWorkResultId50F959E2FkWorkflowInput: WorkflowResultWorkResultId50F959E2FkWorkflowInput;
  WorkflowResultWorkResultId50F959E2FkWorkflowInverseInput: WorkflowResultWorkResultId50F959E2FkWorkflowInverseInput;
  WorkflowResultWorkResultId50F959E2FkWorkflowWorkflowResultCreateInput: WorkflowResultWorkResultId50F959E2FkWorkflowWorkflowResultCreateInput;
  WorkflowResultWorkResultId50F959E2FkWorkflowWorkflowResultWorkflowVersionsCreateInput: WorkflowResultWorkResultId50F959E2FkWorkflowWorkflowResultWorkflowVersionsCreateInput;
  WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInput: WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInput;
  WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInverseInput: WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInverseInput;
  WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowWorkflowResultWorkflowVersionsCreateInput: WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowWorkflowResultWorkflowVersionsCreateInput;
  WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowWorkflowVersionCreateInput: WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowWorkflowVersionCreateInput;
  WorkflowResultWorkflowResultPkeyConnect: WorkflowResultWorkflowResultPkeyConnect;
  WorkflowResultWorkflowResultPkeyDelete: WorkflowResultWorkflowResultPkeyDelete;
  WorkflowResultWorkflowVersion: ResolverTypeWrapper<WorkflowResultWorkflowVersion>;
  WorkflowResultWorkflowVersionCondition: WorkflowResultWorkflowVersionCondition;
  WorkflowResultWorkflowVersionFilter: WorkflowResultWorkflowVersionFilter;
  WorkflowResultWorkflowVersionInput: WorkflowResultWorkflowVersionInput;
  WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowUsingWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqUpdate: WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowUsingWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqUpdate;
  WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowUsingWorkflowResultWorkflowVersionsPkeyUpdate: WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowUsingWorkflowResultWorkflowVersionsPkeyUpdate;
  WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowUsingWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqUpdate: WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowUsingWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqUpdate;
  WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowUsingWorkflowResultWorkflowVersionsPkeyUpdate: WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowUsingWorkflowResultWorkflowVersionsPkeyUpdate;
  WorkflowResultWorkflowVersionPatch: WorkflowResultWorkflowVersionPatch;
  WorkflowResultWorkflowVersionWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqConnect: WorkflowResultWorkflowVersionWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqConnect;
  WorkflowResultWorkflowVersionWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqDelete: WorkflowResultWorkflowVersionWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqDelete;
  WorkflowResultWorkflowVersionWorkflowResultWorkflowVersionsPkeyConnect: WorkflowResultWorkflowVersionWorkflowResultWorkflowVersionsPkeyConnect;
  WorkflowResultWorkflowVersionWorkflowResultWorkflowVersionsPkeyDelete: WorkflowResultWorkflowVersionWorkflowResultWorkflowVersionsPkeyDelete;
  WorkflowResultWorkflowVersionsConnection: ResolverTypeWrapper<WorkflowResultWorkflowVersionsConnection>;
  WorkflowResultWorkflowVersionsEdge: ResolverTypeWrapper<WorkflowResultWorkflowVersionsEdge>;
  WorkflowResultWorkflowVersionsOrderBy: WorkflowResultWorkflowVersionsOrderBy;
  WorkflowResultsConnection: ResolverTypeWrapper<WorkflowResultsConnection>;
  WorkflowResultsEdge: ResolverTypeWrapper<WorkflowResultsEdge>;
  WorkflowResultsOrderBy: WorkflowResultsOrderBy;
  WorkflowSection: ResolverTypeWrapper<WorkflowSection>;
  WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInput: WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInput;
  WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInverseInput: WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInverseInput;
  WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowWorkflowSectionBlockCreateInput: WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowWorkflowSectionBlockCreateInput;
  WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowWorkflowSectionBlockSectionsCreateInput: WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowWorkflowSectionBlockSectionsCreateInput;
  WorkflowSectionBloSectionId817C1C77FkWorkflowInput: WorkflowSectionBloSectionId817C1C77FkWorkflowInput;
  WorkflowSectionBloSectionId817C1C77FkWorkflowInverseInput: WorkflowSectionBloSectionId817C1C77FkWorkflowInverseInput;
  WorkflowSectionBloSectionId817C1C77FkWorkflowWorkflowSectionBlockSectionsCreateInput: WorkflowSectionBloSectionId817C1C77FkWorkflowWorkflowSectionBlockSectionsCreateInput;
  WorkflowSectionBloSectionId817C1C77FkWorkflowWorkflowSectionCreateInput: WorkflowSectionBloSectionId817C1C77FkWorkflowWorkflowSectionCreateInput;
  WorkflowSectionBlock: ResolverTypeWrapper<WorkflowSectionBlock>;
  WorkflowSectionBlockCondition: WorkflowSectionBlockCondition;
  WorkflowSectionBlockFilter: WorkflowSectionBlockFilter;
  WorkflowSectionBlockInput: WorkflowSectionBlockInput;
  WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInput: WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInput;
  WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInverseInput: WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInverseInput;
  WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdWorkflowDataCreateInput: WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdWorkflowDataCreateInput;
  WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdWorkflowSectionBlockCreateInput: WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdWorkflowSectionBlockCreateInput;
  WorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdUsingWorkflowSectionBlockPkeyUpdate: WorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdUsingWorkflowSectionBlockPkeyUpdate;
  WorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdUsingWorkflowSectionBlockPkeyUpdate: WorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdUsingWorkflowSectionBlockPkeyUpdate;
  WorkflowSectionBlockOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowUsingWorkflowSectionBlockPkeyUpdate: WorkflowSectionBlockOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowUsingWorkflowSectionBlockPkeyUpdate;
  WorkflowSectionBlockPatch: WorkflowSectionBlockPatch;
  WorkflowSectionBlockSection: ResolverTypeWrapper<WorkflowSectionBlockSection>;
  WorkflowSectionBlockSectionCondition: WorkflowSectionBlockSectionCondition;
  WorkflowSectionBlockSectionFilter: WorkflowSectionBlockSectionFilter;
  WorkflowSectionBlockSectionInput: WorkflowSectionBlockSectionInput;
  WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowUsingWorkflowSectionBlockSBlockIdSectionId2F785D93UniqUpdate: WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowUsingWorkflowSectionBlockSBlockIdSectionId2F785D93UniqUpdate;
  WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowUsingWorkflowSectionBlockSectionsPkeyUpdate: WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowUsingWorkflowSectionBlockSectionsPkeyUpdate;
  WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowUsingWorkflowSectionBlockSBlockIdSectionId2F785D93UniqUpdate: WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowUsingWorkflowSectionBlockSBlockIdSectionId2F785D93UniqUpdate;
  WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowUsingWorkflowSectionBlockSectionsPkeyUpdate: WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowUsingWorkflowSectionBlockSectionsPkeyUpdate;
  WorkflowSectionBlockSectionPatch: WorkflowSectionBlockSectionPatch;
  WorkflowSectionBlockSectionWorkflowSectionBlockSBlockIdSectionId2F785D93UniqConnect: WorkflowSectionBlockSectionWorkflowSectionBlockSBlockIdSectionId2F785D93UniqConnect;
  WorkflowSectionBlockSectionWorkflowSectionBlockSBlockIdSectionId2F785D93UniqDelete: WorkflowSectionBlockSectionWorkflowSectionBlockSBlockIdSectionId2F785D93UniqDelete;
  WorkflowSectionBlockSectionWorkflowSectionBlockSectionsPkeyConnect: WorkflowSectionBlockSectionWorkflowSectionBlockSectionsPkeyConnect;
  WorkflowSectionBlockSectionWorkflowSectionBlockSectionsPkeyDelete: WorkflowSectionBlockSectionWorkflowSectionBlockSectionsPkeyDelete;
  WorkflowSectionBlockSectionsConnection: ResolverTypeWrapper<WorkflowSectionBlockSectionsConnection>;
  WorkflowSectionBlockSectionsEdge: ResolverTypeWrapper<WorkflowSectionBlockSectionsEdge>;
  WorkflowSectionBlockSectionsOrderBy: WorkflowSectionBlockSectionsOrderBy;
  WorkflowSectionBlockToManyWorkflowSectionBlockSectionFilter: WorkflowSectionBlockToManyWorkflowSectionBlockSectionFilter;
  WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInput: WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInput;
  WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput: WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput;
  WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdToolVersionCreateInput: WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdToolVersionCreateInput;
  WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdWorkflowSectionBlockCreateInput: WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdWorkflowSectionBlockCreateInput;
  WorkflowSectionBlockWorkflowSectionBlockPkeyConnect: WorkflowSectionBlockWorkflowSectionBlockPkeyConnect;
  WorkflowSectionBlockWorkflowSectionBlockPkeyDelete: WorkflowSectionBlockWorkflowSectionBlockPkeyDelete;
  WorkflowSectionBlocksConnection: ResolverTypeWrapper<WorkflowSectionBlocksConnection>;
  WorkflowSectionBlocksEdge: ResolverTypeWrapper<WorkflowSectionBlocksEdge>;
  WorkflowSectionBlocksOrderBy: WorkflowSectionBlocksOrderBy;
  WorkflowSectionCondition: WorkflowSectionCondition;
  WorkflowSectionFilter: WorkflowSectionFilter;
  WorkflowSectionInput: WorkflowSectionInput;
  WorkflowSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowUsingWorkflowSectionPkeyUpdate: WorkflowSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowUsingWorkflowSectionPkeyUpdate;
  WorkflowSectionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowUsingWorkflowSectionPkeyUpdate: WorkflowSectionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowUsingWorkflowSectionPkeyUpdate;
  WorkflowSectionPatch: WorkflowSectionPatch;
  WorkflowSectionToManyWorkflowSectionBlockSectionFilter: WorkflowSectionToManyWorkflowSectionBlockSectionFilter;
  WorkflowSectionToManyWorkflowSectionWorkflowVersionFilter: WorkflowSectionToManyWorkflowSectionWorkflowVersionFilter;
  WorkflowSectionWorSectionId1D5C2D63FkWorkflowInput: WorkflowSectionWorSectionId1D5C2D63FkWorkflowInput;
  WorkflowSectionWorSectionId1D5C2D63FkWorkflowInverseInput: WorkflowSectionWorSectionId1D5C2D63FkWorkflowInverseInput;
  WorkflowSectionWorSectionId1D5C2D63FkWorkflowWorkflowSectionCreateInput: WorkflowSectionWorSectionId1D5C2D63FkWorkflowWorkflowSectionCreateInput;
  WorkflowSectionWorSectionId1D5C2D63FkWorkflowWorkflowSectionWorkflowVersionsCreateInput: WorkflowSectionWorSectionId1D5C2D63FkWorkflowWorkflowSectionWorkflowVersionsCreateInput;
  WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInput: WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInput;
  WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInverseInput: WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInverseInput;
  WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowWorkflowSectionWorkflowVersionsCreateInput: WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowWorkflowSectionWorkflowVersionsCreateInput;
  WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowWorkflowVersionCreateInput: WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowWorkflowVersionCreateInput;
  WorkflowSectionWorkflowSectionPkeyConnect: WorkflowSectionWorkflowSectionPkeyConnect;
  WorkflowSectionWorkflowSectionPkeyDelete: WorkflowSectionWorkflowSectionPkeyDelete;
  WorkflowSectionWorkflowVersion: ResolverTypeWrapper<WorkflowSectionWorkflowVersion>;
  WorkflowSectionWorkflowVersionCondition: WorkflowSectionWorkflowVersionCondition;
  WorkflowSectionWorkflowVersionFilter: WorkflowSectionWorkflowVersionFilter;
  WorkflowSectionWorkflowVersionInput: WorkflowSectionWorkflowVersionInput;
  WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowUsingWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqUpdate: WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowUsingWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqUpdate;
  WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowUsingWorkflowSectionWorkflowVersionsPkeyUpdate: WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowUsingWorkflowSectionWorkflowVersionsPkeyUpdate;
  WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowUsingWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqUpdate: WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowUsingWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqUpdate;
  WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowUsingWorkflowSectionWorkflowVersionsPkeyUpdate: WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowUsingWorkflowSectionWorkflowVersionsPkeyUpdate;
  WorkflowSectionWorkflowVersionPatch: WorkflowSectionWorkflowVersionPatch;
  WorkflowSectionWorkflowVersionWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqConnect: WorkflowSectionWorkflowVersionWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqConnect;
  WorkflowSectionWorkflowVersionWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqDelete: WorkflowSectionWorkflowVersionWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqDelete;
  WorkflowSectionWorkflowVersionWorkflowSectionWorkflowVersionsPkeyConnect: WorkflowSectionWorkflowVersionWorkflowSectionWorkflowVersionsPkeyConnect;
  WorkflowSectionWorkflowVersionWorkflowSectionWorkflowVersionsPkeyDelete: WorkflowSectionWorkflowVersionWorkflowSectionWorkflowVersionsPkeyDelete;
  WorkflowSectionWorkflowVersionsConnection: ResolverTypeWrapper<WorkflowSectionWorkflowVersionsConnection>;
  WorkflowSectionWorkflowVersionsEdge: ResolverTypeWrapper<WorkflowSectionWorkflowVersionsEdge>;
  WorkflowSectionWorkflowVersionsOrderBy: WorkflowSectionWorkflowVersionsOrderBy;
  WorkflowSectionsConnection: ResolverTypeWrapper<WorkflowSectionsConnection>;
  WorkflowSectionsEdge: ResolverTypeWrapper<WorkflowSectionsEdge>;
  WorkflowSectionsOrderBy: WorkflowSectionsOrderBy;
  WorkflowSource: ResolverTypeWrapper<WorkflowSource>;
  WorkflowSourceCondition: WorkflowSourceCondition;
  WorkflowSourceFilter: WorkflowSourceFilter;
  WorkflowSourceInput: WorkflowSourceInput;
  WorkflowSourceOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdUsingWorkflowSourcePkeyUpdate: WorkflowSourceOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdUsingWorkflowSourcePkeyUpdate;
  WorkflowSourceOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowUsingWorkflowSourcePkeyUpdate: WorkflowSourceOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowUsingWorkflowSourcePkeyUpdate;
  WorkflowSourcePatch: WorkflowSourcePatch;
  WorkflowSourceTag: ResolverTypeWrapper<WorkflowSourceTag>;
  WorkflowSourceTagCondition: WorkflowSourceTagCondition;
  WorkflowSourceTagFilter: WorkflowSourceTagFilter;
  WorkflowSourceTagInput: WorkflowSourceTagInput;
  WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdUsingWorkflowSourceTagsPkeyUpdate: WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdUsingWorkflowSourceTagsPkeyUpdate;
  WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdUsingWorkflowSourceTagsSourceIdTagId1455Ea80UniqUpdate: WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdUsingWorkflowSourceTagsSourceIdTagId1455Ea80UniqUpdate;
  WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdUsingWorkflowSourceTagsPkeyUpdate: WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdUsingWorkflowSourceTagsPkeyUpdate;
  WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdUsingWorkflowSourceTagsSourceIdTagId1455Ea80UniqUpdate: WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdUsingWorkflowSourceTagsSourceIdTagId1455Ea80UniqUpdate;
  WorkflowSourceTagPatch: WorkflowSourceTagPatch;
  WorkflowSourceTagWorkflowSourceTagsPkeyConnect: WorkflowSourceTagWorkflowSourceTagsPkeyConnect;
  WorkflowSourceTagWorkflowSourceTagsPkeyDelete: WorkflowSourceTagWorkflowSourceTagsPkeyDelete;
  WorkflowSourceTagWorkflowSourceTagsSourceIdTagId1455Ea80UniqConnect: WorkflowSourceTagWorkflowSourceTagsSourceIdTagId1455Ea80UniqConnect;
  WorkflowSourceTagWorkflowSourceTagsSourceIdTagId1455Ea80UniqDelete: WorkflowSourceTagWorkflowSourceTagsSourceIdTagId1455Ea80UniqDelete;
  WorkflowSourceTagsConnection: ResolverTypeWrapper<WorkflowSourceTagsConnection>;
  WorkflowSourceTagsEdge: ResolverTypeWrapper<WorkflowSourceTagsEdge>;
  WorkflowSourceTagsOrderBy: WorkflowSourceTagsOrderBy;
  WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInput: WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInput;
  WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInverseInput: WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInverseInput;
  WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdWorkflowSourceCreateInput: WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdWorkflowSourceCreateInput;
  WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdWorkflowSourceTagsCreateInput: WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdWorkflowSourceTagsCreateInput;
  WorkflowSourceTagsTagId690E6935FkTagIdInput: WorkflowSourceTagsTagId690E6935FkTagIdInput;
  WorkflowSourceTagsTagId690E6935FkTagIdInverseInput: WorkflowSourceTagsTagId690E6935FkTagIdInverseInput;
  WorkflowSourceTagsTagId690E6935FkTagIdTagCreateInput: WorkflowSourceTagsTagId690E6935FkTagIdTagCreateInput;
  WorkflowSourceTagsTagId690E6935FkTagIdWorkflowSourceTagsCreateInput: WorkflowSourceTagsTagId690E6935FkTagIdWorkflowSourceTagsCreateInput;
  WorkflowSourceToManyWorkflowSourceTagFilter: WorkflowSourceToManyWorkflowSourceTagFilter;
  WorkflowSourceToManyWorkflowSourceWorkflowVersionFilter: WorkflowSourceToManyWorkflowSourceWorkflowVersionFilter;
  WorkflowSourceWorkSourceId608A289DFkWorkflowInput: WorkflowSourceWorkSourceId608A289DFkWorkflowInput;
  WorkflowSourceWorkSourceId608A289DFkWorkflowInverseInput: WorkflowSourceWorkSourceId608A289DFkWorkflowInverseInput;
  WorkflowSourceWorkSourceId608A289DFkWorkflowWorkflowSourceCreateInput: WorkflowSourceWorkSourceId608A289DFkWorkflowWorkflowSourceCreateInput;
  WorkflowSourceWorkSourceId608A289DFkWorkflowWorkflowSourceWorkflowVersionsCreateInput: WorkflowSourceWorkSourceId608A289DFkWorkflowWorkflowSourceWorkflowVersionsCreateInput;
  WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInput: WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInput;
  WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInverseInput: WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInverseInput;
  WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowWorkflowSourceWorkflowVersionsCreateInput: WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowWorkflowSourceWorkflowVersionsCreateInput;
  WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowWorkflowVersionCreateInput: WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowWorkflowVersionCreateInput;
  WorkflowSourceWorkflowSourcePkeyConnect: WorkflowSourceWorkflowSourcePkeyConnect;
  WorkflowSourceWorkflowSourcePkeyDelete: WorkflowSourceWorkflowSourcePkeyDelete;
  WorkflowSourceWorkflowVersion: ResolverTypeWrapper<WorkflowSourceWorkflowVersion>;
  WorkflowSourceWorkflowVersionCondition: WorkflowSourceWorkflowVersionCondition;
  WorkflowSourceWorkflowVersionFilter: WorkflowSourceWorkflowVersionFilter;
  WorkflowSourceWorkflowVersionInput: WorkflowSourceWorkflowVersionInput;
  WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowUsingWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqUpdate: WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowUsingWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqUpdate;
  WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowUsingWorkflowSourceWorkflowVersionsPkeyUpdate: WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowUsingWorkflowSourceWorkflowVersionsPkeyUpdate;
  WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowUsingWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqUpdate: WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowUsingWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqUpdate;
  WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowUsingWorkflowSourceWorkflowVersionsPkeyUpdate: WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowUsingWorkflowSourceWorkflowVersionsPkeyUpdate;
  WorkflowSourceWorkflowVersionPatch: WorkflowSourceWorkflowVersionPatch;
  WorkflowSourceWorkflowVersionWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqConnect: WorkflowSourceWorkflowVersionWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqConnect;
  WorkflowSourceWorkflowVersionWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqDelete: WorkflowSourceWorkflowVersionWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqDelete;
  WorkflowSourceWorkflowVersionWorkflowSourceWorkflowVersionsPkeyConnect: WorkflowSourceWorkflowVersionWorkflowSourceWorkflowVersionsPkeyConnect;
  WorkflowSourceWorkflowVersionWorkflowSourceWorkflowVersionsPkeyDelete: WorkflowSourceWorkflowVersionWorkflowSourceWorkflowVersionsPkeyDelete;
  WorkflowSourceWorkflowVersionsConnection: ResolverTypeWrapper<WorkflowSourceWorkflowVersionsConnection>;
  WorkflowSourceWorkflowVersionsEdge: ResolverTypeWrapper<WorkflowSourceWorkflowVersionsEdge>;
  WorkflowSourceWorkflowVersionsOrderBy: WorkflowSourceWorkflowVersionsOrderBy;
  WorkflowSourcesConnection: ResolverTypeWrapper<WorkflowSourcesConnection>;
  WorkflowSourcesEdge: ResolverTypeWrapper<WorkflowSourcesEdge>;
  WorkflowSourcesOrderBy: WorkflowSourcesOrderBy;
  WorkflowTenantId9469Ff84FkTenantIdInput: WorkflowTenantId9469Ff84FkTenantIdInput;
  WorkflowTenantId9469Ff84FkTenantIdInverseInput: WorkflowTenantId9469Ff84FkTenantIdInverseInput;
  WorkflowTenantId9469Ff84FkTenantIdTenantCreateInput: WorkflowTenantId9469Ff84FkTenantIdTenantCreateInput;
  WorkflowTenantId9469Ff84FkTenantIdWorkflowCreateInput: WorkflowTenantId9469Ff84FkTenantIdWorkflowCreateInput;
  WorkflowToManyWorkflowVersionFilter: WorkflowToManyWorkflowVersionFilter;
  WorkflowUserId121C1967FkApplicationUserIdApplicationUserCreateInput: WorkflowUserId121C1967FkApplicationUserIdApplicationUserCreateInput;
  WorkflowUserId121C1967FkApplicationUserIdInput: WorkflowUserId121C1967FkApplicationUserIdInput;
  WorkflowUserId121C1967FkApplicationUserIdInverseInput: WorkflowUserId121C1967FkApplicationUserIdInverseInput;
  WorkflowUserId121C1967FkApplicationUserIdWorkflowCreateInput: WorkflowUserId121C1967FkApplicationUserIdWorkflowCreateInput;
  WorkflowVersion: ResolverTypeWrapper<WorkflowVersion>;
  WorkflowVersionCondition: WorkflowVersionCondition;
  WorkflowVersionFilter: WorkflowVersionFilter;
  WorkflowVersionInput: WorkflowVersionInput;
  WorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowUsingWorkflowVersionPkeyUpdate: WorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowUsingWorkflowVersionPkeyUpdate;
  WorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowUsingWorkflowVersionPkeyUpdate: WorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowUsingWorkflowVersionPkeyUpdate;
  WorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowUsingWorkflowVersionPkeyUpdate: WorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowUsingWorkflowVersionPkeyUpdate;
  WorkflowVersionOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingWorkflowVersionPkeyUpdate: WorkflowVersionOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingWorkflowVersionPkeyUpdate;
  WorkflowVersionOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdUsingWorkflowVersionPkeyUpdate: WorkflowVersionOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdUsingWorkflowVersionPkeyUpdate;
  WorkflowVersionPatch: WorkflowVersionPatch;
  WorkflowVersionToManyWorkflowResultWorkflowVersionFilter: WorkflowVersionToManyWorkflowResultWorkflowVersionFilter;
  WorkflowVersionToManyWorkflowSectionWorkflowVersionFilter: WorkflowVersionToManyWorkflowSectionWorkflowVersionFilter;
  WorkflowVersionToManyWorkflowSourceWorkflowVersionFilter: WorkflowVersionToManyWorkflowSourceWorkflowVersionFilter;
  WorkflowVersionUserId00C0F62FFkApplicationUserIdApplicationUserCreateInput: WorkflowVersionUserId00C0F62FFkApplicationUserIdApplicationUserCreateInput;
  WorkflowVersionUserId00C0F62FFkApplicationUserIdInput: WorkflowVersionUserId00C0F62FFkApplicationUserIdInput;
  WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput: WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput;
  WorkflowVersionUserId00C0F62FFkApplicationUserIdWorkflowVersionCreateInput: WorkflowVersionUserId00C0F62FFkApplicationUserIdWorkflowVersionCreateInput;
  WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInput: WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInput;
  WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInverseInput: WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInverseInput;
  WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdWorkflowCreateInput: WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdWorkflowCreateInput;
  WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdWorkflowVersionCreateInput: WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdWorkflowVersionCreateInput;
  WorkflowVersionWorkflowVersionPkeyConnect: WorkflowVersionWorkflowVersionPkeyConnect;
  WorkflowVersionWorkflowVersionPkeyDelete: WorkflowVersionWorkflowVersionPkeyDelete;
  WorkflowVersionsConnection: ResolverTypeWrapper<WorkflowVersionsConnection>;
  WorkflowVersionsEdge: ResolverTypeWrapper<WorkflowVersionsEdge>;
  WorkflowVersionsOrderBy: WorkflowVersionsOrderBy;
  WorkflowWorkflowPkeyConnect: WorkflowWorkflowPkeyConnect;
  WorkflowWorkflowPkeyDelete: WorkflowWorkflowPkeyDelete;
  WorkflowsConnection: ResolverTypeWrapper<WorkflowsConnection>;
  WorkflowsEdge: ResolverTypeWrapper<WorkflowsEdge>;
  WorkflowsOrderBy: WorkflowsOrderBy;
  updateAnalysisTableGroupOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdPatch: UpdateAnalysisTableGroupOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdPatch;
  updateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdPatch: UpdateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdPatch;
  updateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdPatch: UpdateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdPatch;
  updateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdPatch: UpdateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdPatch;
  updateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdPatch: UpdateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdPatch;
  updateAnalysisTableOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdPatch: UpdateAnalysisTableOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdPatch;
  updateAnalysisTableOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdPatch: UpdateAnalysisTableOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdPatch;
  updateAnalysisTableOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdPatch: UpdateAnalysisTableOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdPatch;
  updateAnalysisTableOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdPatch: UpdateAnalysisTableOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdPatch;
  updateAnalysisTableOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdPatch: UpdateAnalysisTableOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdPatch;
  updateAnalysisTableOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdPatch: UpdateAnalysisTableOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdPatch;
  updateAnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisPatch: UpdateAnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisPatch;
  updateAnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIPatch: UpdateAnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIPatch;
  updateAnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisPatch: UpdateAnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisPatch;
  updateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdPatch;
  updateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdPatch;
  updateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdPatch;
  updateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdPatch;
  updateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdPatch;
  updateApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdPatch: UpdateApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdPatch;
  updateApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdPatch: UpdateApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdPatch;
  updateApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiPatch: UpdateApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiPatch;
  updateApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdPatch: UpdateApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdPatch;
  updateApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiPatch: UpdateApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiPatch;
  updateApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdPatch: UpdateApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdPatch;
  updateApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdPatch: UpdateApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdPatch;
  updateApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdPatch: UpdateApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdPatch;
  updateApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiPatch: UpdateApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiPatch;
  updateApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiPatch: UpdateApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiPatch;
  updateApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiPatch: UpdateApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiPatch;
  updateApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiPatch: UpdateApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiPatch;
  updateApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdPatch: UpdateApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdPatch;
  updateApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdPatch: UpdateApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdPatch;
  updateApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdPatch: UpdateApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdPatch;
  updateApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiPatch: UpdateApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiPatch;
  updateApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiPatch: UpdateApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiPatch;
  updateApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdPatch: UpdateApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdPatch;
  updateApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiPatch: UpdateApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiPatch;
  updateApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdPatch: UpdateApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdPatch;
  updateApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdPatch: UpdateApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdPatch;
  updateApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdPatch: UpdateApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdPatch;
  updateApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiPatch: UpdateApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiPatch;
  updateApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdPatch: UpdateApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdPatch;
  updateApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdPatch: UpdateApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdPatch;
  updateApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdPatch: UpdateApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdPatch;
  updateApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiPatch: UpdateApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiPatch;
  updateApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdPatch: UpdateApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdPatch;
  updateApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiPatch: UpdateApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiPatch;
  updateApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiPatch: UpdateApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiPatch;
  updateApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdPatch: UpdateApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdPatch;
  updateApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiPatch: UpdateApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiPatch;
  updateApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdPatch: UpdateApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdPatch;
  updateApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdPatch: UpdateApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdPatch;
  updateApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiPatch: UpdateApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiPatch;
  updateApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiPatch: UpdateApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiPatch;
  updateApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiPatch: UpdateApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiPatch;
  updateApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdPatch: UpdateApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdPatch;
  updateApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdPatch: UpdateApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdPatch;
  updateApplicationUserSessionOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiPatch: UpdateApplicationUserSessionOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiPatch;
  updateApplicationUserSessionOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdPatch: UpdateApplicationUserSessionOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdPatch;
  updateApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermPatch: UpdateApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermPatch;
  updateApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiPatch: UpdateApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiPatch;
  updateAuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdPatch: UpdateAuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdPatch;
  updateAuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdPatch: UpdateAuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdPatch;
  updateAuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermPatch: UpdateAuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermPatch;
  updateAuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdPatch: UpdateAuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdPatch;
  updateAuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermPatch: UpdateAuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermPatch;
  updateAuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermPatch: UpdateAuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermPatch;
  updateAuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoPatch: UpdateAuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoPatch;
  updateAvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdPatch: UpdateAvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdPatch;
  updateAwsBatchTaskOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskePatch: UpdateAwsBatchTaskOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskePatch;
  updateAwsBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchPatch: UpdateAwsBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchPatch;
  updateAwsCognitoUserOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsPatch: UpdateAwsCognitoUserOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsPatch;
  updateAwsEcrImageOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaPatch: UpdateAwsEcrImageOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaPatch;
  updateAwsEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIPatch: UpdateAwsEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIPatch;
  updateAwsMockBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchPatch: UpdateAwsMockBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchPatch;
  updateAwsMockEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIPatch: UpdateAwsMockEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIPatch;
  updateAwsMockS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiPatch: UpdateAwsMockS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiPatch;
  updateAwsS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiPatch: UpdateAwsS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiPatch;
  updateAwsS3FileOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdPatch: UpdateAwsS3FileOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdPatch;
  updateContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaPatch: UpdateContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaPatch;
  updateContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVPatch: UpdateContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVPatch;
  updateContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdPatch: UpdateContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdPatch;
  updateContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdPatch: UpdateContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdPatch;
  updateCostOnCostForCostTenantId7Dcd58A7FkTenantIdPatch: UpdateCostOnCostForCostTenantId7Dcd58A7FkTenantIdPatch;
  updateCostOnFileActivityForFileActivityCostId2A88D90AFkCostIdPatch: UpdateCostOnFileActivityForFileActivityCostId2A88D90AFkCostIdPatch;
  updateCostOnTaskActivityForTaskActivityCostId49E66058FkCostIdPatch: UpdateCostOnTaskActivityForTaskActivityCostId49E66058FkCostIdPatch;
  updateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdPatch: UpdateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdPatch;
  updateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdPatch: UpdateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdPatch;
  updateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdPatch: UpdateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdPatch;
  updateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdPatch: UpdateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdPatch;
  updateDatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdPatch: UpdateDatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdPatch;
  updateDatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdPatch: UpdateDatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdPatch;
  updateDatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRPatch: UpdateDatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRPatch;
  updateDatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdPatch: UpdateDatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdPatch;
  updateDatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdPatch: UpdateDatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdPatch;
  updateDatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdPatch: UpdateDatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdPatch;
  updateDatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdPatch: UpdateDatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdPatch;
  updateDatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdPatch: UpdateDatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdPatch;
  updateDatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdPatch: UpdateDatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdPatch;
  updateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataPatch: UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataPatch;
  updateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRPatch: UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRPatch;
  updateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIPatch: UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIPatch;
  updateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdPatch: UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdPatch;
  updateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRPatch: UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRPatch;
  updateDatasetRecordingsTableColumnOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRPatch: UpdateDatasetRecordingsTableColumnOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRPatch;
  updateDatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRPatch: UpdateDatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRPatch;
  updateDatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIPatch: UpdateDatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIPatch;
  updateDatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdPatch: UpdateDatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdPatch;
  updateDatasetRecordingsTableOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRPatch: UpdateDatasetRecordingsTableOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRPatch;
  updateDatasetRecordingsTableOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRPatch: UpdateDatasetRecordingsTableOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRPatch;
  updateDatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdPatch: UpdateDatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdPatch;
  updateDatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdPatch: UpdateDatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdPatch;
  updateDatasetRecordingsTableOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTB91F3991FkDatasetRPatch: UpdateDatasetRecordingsTableOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRPatch;
  updateDatasetVersionOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdPatch: UpdateDatasetVersionOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdPatch;
  updateDatasetVersionOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdPatch: UpdateDatasetVersionOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdPatch;
  updateDatasetVersionOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdPatch: UpdateDatasetVersionOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdPatch;
  updateDjangoAdminLogOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoPatch: UpdateDjangoAdminLogOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoPatch;
  updateDjangoAdminLogOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdPatch: UpdateDjangoAdminLogOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdPatch;
  updateDjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoPatch: UpdateDjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoPatch;
  updateDjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoPatch: UpdateDjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoPatch;
  updateDjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoPatch: UpdateDjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoPatch;
  updateDjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoPatch: UpdateDjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoPatch;
  updateDjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoPatch: UpdateDjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoPatch;
  updateDjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoPatch: UpdateDjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoPatch;
  updateDrsFileexecutorOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdPatch: UpdateDrsFileexecutorOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdPatch;
  updateDrsFileexecutorOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoPatch: UpdateDrsFileexecutorOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoPatch;
  updateDrsFileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileePatch: UpdateDrsFileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileePatch;
  updateDrsMockfileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileePatch: UpdateDrsMockfileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileePatch;
  updateEulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersPatch: UpdateEulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersPatch;
  updateEulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersPatch: UpdateEulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersPatch;
  updateEulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiPatch: UpdateEulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiPatch;
  updateExplorerQueryOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiPatch: UpdateExplorerQueryOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiPatch;
  updateExplorerQueryOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdPatch: UpdateExplorerQueryOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdPatch;
  updateExplorerQueryOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdPatch: UpdateExplorerQueryOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdPatch;
  updateExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdPatch: UpdateExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdPatch;
  updateExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdPatch: UpdateExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdPatch;
  updateExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdPatch: UpdateExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdPatch;
  updateExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiPatch: UpdateExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiPatch;
  updateFileActivityOnFileActivityForFileActivityCostId2A88D90AFkCostIdPatch: UpdateFileActivityOnFileActivityForFileActivityCostId2A88D90AFkCostIdPatch;
  updateFileActivityOnFileActivityForFileActivityFileId015763A9FkFileIdPatch: UpdateFileActivityOnFileActivityForFileActivityFileId015763A9FkFileIdPatch;
  updateFileActivityOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdPatch: UpdateFileActivityOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdPatch;
  updateFileActivityOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdPatch: UpdateFileActivityOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdPatch;
  updateFileActivityOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdPatch: UpdateFileActivityOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdPatch;
  updateFileMetadatumOnFileMetadatumForFileMetadataFileId78256936FkFileIdPatch: UpdateFileMetadatumOnFileMetadatumForFileMetadataFileId78256936FkFileIdPatch;
  updateFileMetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdPatch: UpdateFileMetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdPatch;
  updateFileMetadatumOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdPatch: UpdateFileMetadatumOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdPatch;
  updateFileMetadatumOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdPatch: UpdateFileMetadatumOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdPatch;
  updateFileOnFileActivityForFileActivityFileId015763A9FkFileIdPatch: UpdateFileOnFileActivityForFileActivityFileId015763A9FkFileIdPatch;
  updateFileOnFileForFileDatasetIdC0A4045AFkDatasetV4IdPatch: UpdateFileOnFileForFileDatasetIdC0A4045AFkDatasetV4IdPatch;
  updateFileOnFileForFileProjectId3Ecca975FkProjectIdPatch: UpdateFileOnFileForFileProjectId3Ecca975FkProjectIdPatch;
  updateFileOnFileForFileSeriesParentIdF7A07E20FkFileIdPatch: UpdateFileOnFileForFileSeriesParentIdF7A07E20FkFileIdPatch;
  updateFileOnFileForFileTenantId61625016FkTenantIdPatch: UpdateFileOnFileForFileTenantId61625016FkTenantIdPatch;
  updateFileOnFileForFileUserId01D90B53FkApplicationUserIdPatch: UpdateFileOnFileForFileUserId01D90B53FkApplicationUserIdPatch;
  updateFileOnFileMetadatumForFileMetadataFileId78256936FkFileIdPatch: UpdateFileOnFileMetadatumForFileMetadataFileId78256936FkFileIdPatch;
  updateFileOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdPatch: UpdateFileOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdPatch;
  updateFileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdPatch: UpdateFileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdPatch;
  updateFileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdPatch: UpdateFileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdPatch;
  updateFileOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdPatch: UpdateFileOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdPatch;
  updateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpPatch: UpdateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpPatch;
  updateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataPatch: UpdateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataPatch;
  updateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdPatch: UpdateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdPatch;
  updateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdPatch: UpdateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdPatch;
  updateFileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdPatch: UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdPatch;
  updateFileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdPatch: UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdPatch;
  updateFileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdPatch: UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdPatch;
  updateFileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdPatch: UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdPatch;
  updateFileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpPatch: UpdateFileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpPatch;
  updateFileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpPatch: UpdateFileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpPatch;
  updateFileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiPatch: UpdateFileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiPatch;
  updateFileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdPatch: UpdateFileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdPatch;
  updateFileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdPatch: UpdateFileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdPatch;
  updateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoPatch: UpdateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoPatch;
  updateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataPatch: UpdateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataPatch;
  updateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdPatch: UpdateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdPatch;
  updateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdPatch: UpdateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdPatch;
  updateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTB91F3991FkDatasetRPatch: UpdateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRPatch;
  updateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdPatch: UpdateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdPatch;
  updateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdPatch: UpdateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdPatch;
  updateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdPatch: UpdateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdPatch;
  updateFileRecordingGroupOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoPatch: UpdateFileRecordingGroupOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoPatch;
  updateFileRecordingGroupOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoPatch: UpdateFileRecordingGroupOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoPatch;
  updateMetadataValueOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdPatch: UpdateMetadataValueOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdPatch;
  updateMetadataValueOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdPatch: UpdateMetadataValueOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdPatch;
  updateMetadataValueOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdPatch: UpdateMetadataValueOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdPatch;
  updateMetadataValueOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdPatch: UpdateMetadataValueOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdPatch;
  updateMetadataValueOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdPatch: UpdateMetadataValueOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdPatch;
  updateMetadatumOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdPatch: UpdateMetadatumOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdPatch;
  updateMetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdPatch: UpdateMetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdPatch;
  updateMetadatumOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataPatch: UpdateMetadatumOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataPatch;
  updateMetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdPatch: UpdateMetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdPatch;
  updateMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataPatch: UpdateMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataPatch;
  updateMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataPatch: UpdateMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataPatch;
  updateMetadatumOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdPatch: UpdateMetadatumOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdPatch;
  updateMetadatumOnMetadatumForMetadataProjectId3333C2AdFkProjectIdPatch: UpdateMetadatumOnMetadatumForMetadataProjectId3333C2AdFkProjectIdPatch;
  updateMetadatumOnMetadatumForMetadataV4TenantId163B6569FkTenantIdPatch: UpdateMetadatumOnMetadatumForMetadataV4TenantId163B6569FkTenantIdPatch;
  updateNotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdPatch: UpdateNotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdPatch;
  updateNotificationOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdPatch: UpdateNotificationOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdPatch;
  updateNotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdPatch: UpdateNotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdPatch;
  updateNotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdPatch: UpdateNotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdPatch;
  updateNotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdPatch: UpdateNotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdPatch;
  updateNotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatPatch: UpdateNotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatPatch;
  updateOutputGroupFileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdPatch: UpdateOutputGroupFileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdPatch;
  updateOutputGroupFileOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpPatch: UpdateOutputGroupFileOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpPatch;
  updateOutputGroupFileOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdPatch: UpdateOutputGroupFileOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdPatch;
  updateProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiPatch: UpdateProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiPatch;
  updateProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdPatch: UpdateProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdPatch;
  updateProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdPatch: UpdateProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdPatch;
  updateProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdPatch: UpdateProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdPatch;
  updateProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdPatch: UpdateProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdPatch;
  updateProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIPatch: UpdateProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIPatch;
  updateProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdPatch: UpdateProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdPatch;
  updateProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdPatch: UpdateProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdPatch;
  updateProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdPatch: UpdateProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdPatch;
  updateProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIPatch: UpdateProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIPatch;
  updateProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIPatch: UpdateProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIPatch;
  updateProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdPatch: UpdateProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdPatch;
  updateProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdPatch: UpdateProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdPatch;
  updateProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdPatch: UpdateProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdPatch;
  updateProjectOnFileForFileProjectId3Ecca975FkProjectIdPatch: UpdateProjectOnFileForFileProjectId3Ecca975FkProjectIdPatch;
  updateProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdPatch: UpdateProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdPatch;
  updateProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdPatch: UpdateProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdPatch;
  updateProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdPatch: UpdateProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdPatch;
  updateProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdPatch: UpdateProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdPatch;
  updateProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdPatch: UpdateProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdPatch;
  updateProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdPatch: UpdateProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdPatch;
  updateProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdPatch: UpdateProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdPatch;
  updateProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdPatch: UpdateProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdPatch;
  updateProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdPatch: UpdateProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdPatch;
  updateProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdPatch: UpdateProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdPatch;
  updateProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdPatch: UpdateProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdPatch;
  updateProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdPatch: UpdateProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdPatch;
  updateProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdPatch: UpdateProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdPatch;
  updateProjectOnTaskForTaskProjectId963D6354FkProjectIdPatch: UpdateProjectOnTaskForTaskProjectId963D6354FkProjectIdPatch;
  updateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiPatch: UpdateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiPatch;
  updateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiPatch: UpdateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiPatch;
  updateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdPatch: UpdateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdPatch;
  updateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdPatch: UpdateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdPatch;
  updateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRPatch: UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRPatch;
  updateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoPatch: UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoPatch;
  updateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdPatch: UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdPatch;
  updateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdPatch: UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdPatch;
  updateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdPatch: UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdPatch;
  updateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdPatch: UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdPatch;
  updateRoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiPatch: UpdateRoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiPatch;
  updateRoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdPatch: UpdateRoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdPatch;
  updateRoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdPatch: UpdateRoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdPatch;
  updateRunOnRunForRunTenantId73Fbd8D9FkTenantIdPatch: UpdateRunOnRunForRunTenantId73Fbd8D9FkTenantIdPatch;
  updateTagOnTagForTagParentIdD2A3Ee5EFkTagIdPatch: UpdateTagOnTagForTagParentIdD2A3Ee5EFkTagIdPatch;
  updateTagOnTagForTagTenantId8072B61FFkTenantIdPatch: UpdateTagOnTagForTagTenantId8072B61FFkTenantIdPatch;
  updateTagOnTagForTagUserIdB3B7Fb50FkApplicationUserIdPatch: UpdateTagOnTagForTagUserIdB3B7Fb50FkApplicationUserIdPatch;
  updateTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdPatch: UpdateTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdPatch;
  updateTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdPatch: UpdateTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdPatch;
  updateTaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdPatch: UpdateTaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdPatch;
  updateTaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdPatch: UpdateTaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdPatch;
  updateTaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdPatch: UpdateTaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdPatch;
  updateTaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdPatch: UpdateTaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdPatch;
  updateTaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdPatch: UpdateTaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdPatch;
  updateTaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdPatch: UpdateTaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdPatch;
  updateTaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdPatch: UpdateTaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdPatch;
  updateTaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdPatch: UpdateTaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdPatch;
  updateTaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdPatch: UpdateTaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdPatch;
  updateTaskOnTaskForTaskProjectId963D6354FkProjectIdPatch: UpdateTaskOnTaskForTaskProjectId963D6354FkProjectIdPatch;
  updateTaskOnTaskForTaskTenantId148E90DaFkTenantIdPatch: UpdateTaskOnTaskForTaskTenantId148E90DaFkTenantIdPatch;
  updateTaskOnTaskForTaskToolVersionId14506837FkToolVersionIdPatch: UpdateTaskOnTaskForTaskToolVersionId14506837FkToolVersionIdPatch;
  updateTaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdPatch: UpdateTaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdPatch;
  updateTaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdPatch: UpdateTaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdPatch;
  updateTaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdPatch: UpdateTaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdPatch;
  updateTaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdPatch: UpdateTaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdPatch;
  updateTaskResourceOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdPatch: UpdateTaskResourceOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdPatch;
  updateTaskSourceOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdPatch: UpdateTaskSourceOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdPatch;
  updateTaskSourceOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdPatch: UpdateTaskSourceOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdPatch;
  updateTaskSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdPatch: UpdateTaskSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdPatch;
  updateTaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdPatch: UpdateTaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdPatch;
  updateTenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoPatch: UpdateTenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoPatch;
  updateTenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdPatch: UpdateTenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdPatch;
  updateTenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoPatch: UpdateTenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoPatch;
  updateTenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoPatch: UpdateTenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoPatch;
  updateTenantContractTransactionOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoPatch: UpdateTenantContractTransactionOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoPatch;
  updateTenantContractTransactionOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoPatch: UpdateTenantContractTransactionOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoPatch;
  updateTenantMockuserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsPatch: UpdateTenantMockuserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsPatch;
  updateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoPatch: UpdateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoPatch;
  updateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdPatch: UpdateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdPatch;
  updateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiPatch: UpdateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiPatch;
  updateTenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdPatch: UpdateTenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdPatch;
  updateTenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdPatch: UpdateTenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdPatch;
  updateTenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdPatch: UpdateTenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdPatch;
  updateTenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdPatch: UpdateTenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdPatch;
  updateTenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdPatch: UpdateTenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdPatch;
  updateTenantOnCostForCostTenantId7Dcd58A7FkTenantIdPatch: UpdateTenantOnCostForCostTenantId7Dcd58A7FkTenantIdPatch;
  updateTenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdPatch: UpdateTenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdPatch;
  updateTenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdPatch: UpdateTenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdPatch;
  updateTenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdPatch: UpdateTenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdPatch;
  updateTenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdPatch: UpdateTenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdPatch;
  updateTenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdPatch: UpdateTenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdPatch;
  updateTenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdPatch: UpdateTenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdPatch;
  updateTenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdPatch: UpdateTenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdPatch;
  updateTenantOnFileForFileTenantId61625016FkTenantIdPatch: UpdateTenantOnFileForFileTenantId61625016FkTenantIdPatch;
  updateTenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdPatch: UpdateTenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdPatch;
  updateTenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdPatch: UpdateTenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdPatch;
  updateTenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdPatch: UpdateTenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdPatch;
  updateTenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdPatch: UpdateTenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdPatch;
  updateTenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdPatch: UpdateTenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdPatch;
  updateTenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdPatch: UpdateTenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdPatch;
  updateTenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdPatch: UpdateTenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdPatch;
  updateTenantOnProjectForProjectTenantId03F0Ca53FkTenantIdPatch: UpdateTenantOnProjectForProjectTenantId03F0Ca53FkTenantIdPatch;
  updateTenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdPatch: UpdateTenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdPatch;
  updateTenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdPatch: UpdateTenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdPatch;
  updateTenantOnRunForRunTenantId73Fbd8D9FkTenantIdPatch: UpdateTenantOnRunForRunTenantId73Fbd8D9FkTenantIdPatch;
  updateTenantOnTagForTagTenantId8072B61FFkTenantIdPatch: UpdateTenantOnTagForTagTenantId8072B61FFkTenantIdPatch;
  updateTenantOnTaskForTaskTenantId148E90DaFkTenantIdPatch: UpdateTenantOnTaskForTaskTenantId148E90DaFkTenantIdPatch;
  updateTenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdPatch: UpdateTenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdPatch;
  updateTenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdPatch: UpdateTenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdPatch;
  updateTenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdPatch: UpdateTenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdPatch;
  updateTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdPatch: UpdateTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdPatch;
  updateTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdPatch: UpdateTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdPatch;
  updateTenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdPatch: UpdateTenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdPatch;
  updateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatPatch: UpdateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatPatch;
  updateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiPatch: UpdateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiPatch;
  updateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdPatch: UpdateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdPatch;
  updateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiPatch: UpdateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiPatch;
  updateTenantUserexecutorOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsPatch: UpdateTenantUserexecutorOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsPatch;
  updateTenantUserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsPatch: UpdateTenantUserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsPatch;
  updateTenantUserexecutorOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoPatch: UpdateTenantUserexecutorOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoPatch;
  updateTesContainerimageexecutorOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaPatch: UpdateTesContainerimageexecutorOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaPatch;
  updateTesContainerimageexecutorOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaPatch: UpdateTesContainerimageexecutorOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaPatch;
  updateTesContainerimageexecutorOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoPatch: UpdateTesContainerimageexecutorOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoPatch;
  updateTesContainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaPatch: UpdateTesContainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaPatch;
  updateTesMockcontainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaPatch: UpdateTesMockcontainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaPatch;
  updateTesMocktaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskePatch: UpdateTesMocktaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskePatch;
  updateTesTaskexecutorOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskePatch: UpdateTesTaskexecutorOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskePatch;
  updateTesTaskexecutorOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdPatch: UpdateTesTaskexecutorOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdPatch;
  updateTesTaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskePatch: UpdateTesTaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskePatch;
  updateTesTaskexecutorOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoPatch: UpdateTesTaskexecutorOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoPatch;
  updateToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCatePatch: UpdateToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCatePatch;
  updateToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCatePatch: UpdateToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCatePatch;
  updateToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdPatch: UpdateToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdPatch;
  updateToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdPatch: UpdateToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdPatch;
  updateToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdPatch: UpdateToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdPatch;
  updateToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdPatch: UpdateToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdPatch;
  updateToolOnToolVersionForToolVersionToolId3B396B22FkToolIdPatch: UpdateToolOnToolVersionForToolVersionToolId3B396B22FkToolIdPatch;
  updateToolParameterOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdPatch: UpdateToolParameterOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdPatch;
  updateToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdPatch: UpdateToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdPatch;
  updateToolResultOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdPatch: UpdateToolResultOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdPatch;
  updateToolSecretOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdPatch: UpdateToolSecretOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdPatch;
  updateToolSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdPatch: UpdateToolSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdPatch;
  updateToolSourceOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdPatch: UpdateToolSourceOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdPatch;
  updateToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdPatch: UpdateToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdPatch;
  updateToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdPatch: UpdateToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdPatch;
  updateToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdPatch: UpdateToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdPatch;
  updateToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdPatch: UpdateToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdPatch;
  updateToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdPatch: UpdateToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdPatch;
  updateToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdPatch: UpdateToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdPatch;
  updateToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdPatch: UpdateToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdPatch;
  updateToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdPatch: UpdateToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdPatch;
  updateToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdPatch: UpdateToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdPatch;
  updateToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdPatch: UpdateToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdPatch;
  updateToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdPatch: UpdateToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdPatch;
  updateToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersPatch: UpdateToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersPatch;
  updateToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdPatch: UpdateToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdPatch;
  updateToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEPatch: UpdateToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEPatch;
  updateToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdPatch: UpdateToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdPatch;
  updateToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdPatch: UpdateToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdPatch;
  updateToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdPatch: UpdateToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdPatch;
  updateToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdPatch: UpdateToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdPatch;
  updateToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEPatch: UpdateToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEPatch;
  updateToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVPatch: UpdateToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVPatch;
  updateToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVPatch: UpdateToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVPatch;
  updateToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVPatch: UpdateToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVPatch;
  updateToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdPatch: UpdateToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdPatch;
  updateToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVPatch: UpdateToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVPatch;
  updateToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVPatch: UpdateToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVPatch;
  updateToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersPatch: UpdateToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersPatch;
  updateWorkflowDatumOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdPatch: UpdateWorkflowDatumOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdPatch;
  updateWorkflowDatumOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdPatch: UpdateWorkflowDatumOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdPatch;
  updateWorkflowOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdPatch: UpdateWorkflowOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdPatch;
  updateWorkflowOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdPatch: UpdateWorkflowOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdPatch;
  updateWorkflowOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdPatch: UpdateWorkflowOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdPatch;
  updateWorkflowOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdPatch: UpdateWorkflowOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdPatch;
  updateWorkflowResultOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdPatch: UpdateWorkflowResultOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdPatch;
  updateWorkflowResultOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdPatch: UpdateWorkflowResultOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdPatch;
  updateWorkflowResultOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowPatch: UpdateWorkflowResultOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowPatch;
  updateWorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdPatch: UpdateWorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdPatch;
  updateWorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdPatch: UpdateWorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdPatch;
  updateWorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowPatch: UpdateWorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowPatch;
  updateWorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowPatch: UpdateWorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowPatch;
  updateWorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdPatch: UpdateWorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdPatch;
  updateWorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdPatch: UpdateWorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdPatch;
  updateWorkflowSectionBlockOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowPatch: UpdateWorkflowSectionBlockOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowPatch;
  updateWorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowPatch: UpdateWorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowPatch;
  updateWorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowPatch: UpdateWorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowPatch;
  updateWorkflowSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowPatch: UpdateWorkflowSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowPatch;
  updateWorkflowSectionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowPatch: UpdateWorkflowSectionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowPatch;
  updateWorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowPatch: UpdateWorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowPatch;
  updateWorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowPatch: UpdateWorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowPatch;
  updateWorkflowSourceOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdPatch: UpdateWorkflowSourceOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdPatch;
  updateWorkflowSourceOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowPatch: UpdateWorkflowSourceOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowPatch;
  updateWorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdPatch: UpdateWorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdPatch;
  updateWorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdPatch: UpdateWorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdPatch;
  updateWorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowPatch: UpdateWorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowPatch;
  updateWorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowPatch: UpdateWorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowPatch;
  updateWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowPatch: UpdateWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowPatch;
  updateWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowPatch: UpdateWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowPatch;
  updateWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowPatch: UpdateWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowPatch;
  updateWorkflowVersionOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdPatch: UpdateWorkflowVersionOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdPatch;
  updateWorkflowVersionOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdPatch: UpdateWorkflowVersionOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdPatch;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AnalysisTable: AnalysisTable;
  AnalysisTableAnalysisTablePkeyConnect: AnalysisTableAnalysisTablePkeyConnect;
  AnalysisTableAnalysisTablePkeyDelete: AnalysisTableAnalysisTablePkeyDelete;
  AnalysisTableCondition: AnalysisTableCondition;
  AnalysisTableFilter: AnalysisTableFilter;
  AnalysisTableGroup: AnalysisTableGroup;
  AnalysisTableGroupAnalysisTableGroupPkeyConnect: AnalysisTableGroupAnalysisTableGroupPkeyConnect;
  AnalysisTableGroupAnalysisTableGroupPkeyDelete: AnalysisTableGroupAnalysisTableGroupPkeyDelete;
  AnalysisTableGroupCondition: AnalysisTableGroupCondition;
  AnalysisTableGroupFilter: AnalysisTableGroupFilter;
  AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdAnalysisTableCreateInput: AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdAnalysisTableCreateInput;
  AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdAnalysisTableGroupCreateInput: AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdAnalysisTableGroupCreateInput;
  AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput: AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInput;
  AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInverseInput: AnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdInverseInput;
  AnalysisTableGroupInput: AnalysisTableGroupInput;
  AnalysisTableGroupOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdUsingAnalysisTableGroupPkeyUpdate: AnalysisTableGroupOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdUsingAnalysisTableGroupPkeyUpdate;
  AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdUsingAnalysisTableGroupPkeyUpdate: AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdUsingAnalysisTableGroupPkeyUpdate;
  AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingAnalysisTableGroupPkeyUpdate: AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingAnalysisTableGroupPkeyUpdate;
  AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdUsingAnalysisTableGroupPkeyUpdate: AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdUsingAnalysisTableGroupPkeyUpdate;
  AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingAnalysisTableGroupPkeyUpdate: AnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingAnalysisTableGroupPkeyUpdate;
  AnalysisTableGroupPatch: AnalysisTableGroupPatch;
  AnalysisTableGroupProjectId870Aec99FkProjectIdAnalysisTableGroupCreateInput: AnalysisTableGroupProjectId870Aec99FkProjectIdAnalysisTableGroupCreateInput;
  AnalysisTableGroupProjectId870Aec99FkProjectIdInput: AnalysisTableGroupProjectId870Aec99FkProjectIdInput;
  AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput: AnalysisTableGroupProjectId870Aec99FkProjectIdInverseInput;
  AnalysisTableGroupTenantId3786Ab7AFkTenantIdAnalysisTableGroupCreateInput: AnalysisTableGroupTenantId3786Ab7AFkTenantIdAnalysisTableGroupCreateInput;
  AnalysisTableGroupTenantId3786Ab7AFkTenantIdInput: AnalysisTableGroupTenantId3786Ab7AFkTenantIdInput;
  AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput: AnalysisTableGroupTenantId3786Ab7AFkTenantIdInverseInput;
  AnalysisTableGroupTenantId3786Ab7AFkTenantIdTenantCreateInput: AnalysisTableGroupTenantId3786Ab7AFkTenantIdTenantCreateInput;
  AnalysisTableGroupToManyAnalysisTableFilter: AnalysisTableGroupToManyAnalysisTableFilter;
  AnalysisTableGroupToolIdB122F1CcFkToolIdAnalysisTableGroupCreateInput: AnalysisTableGroupToolIdB122F1CcFkToolIdAnalysisTableGroupCreateInput;
  AnalysisTableGroupToolIdB122F1CcFkToolIdInput: AnalysisTableGroupToolIdB122F1CcFkToolIdInput;
  AnalysisTableGroupToolIdB122F1CcFkToolIdInverseInput: AnalysisTableGroupToolIdB122F1CcFkToolIdInverseInput;
  AnalysisTableGroupToolIdB122F1CcFkToolIdToolCreateInput: AnalysisTableGroupToolIdB122F1CcFkToolIdToolCreateInput;
  AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdAnalysisTableGroupCreateInput: AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdAnalysisTableGroupCreateInput;
  AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdApplicationUserCreateInput: AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdApplicationUserCreateInput;
  AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInput: AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInput;
  AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput: AnalysisTableGroupUserId4C7Ff352FkApplicationUserIdInverseInput;
  AnalysisTableGroupsConnection: AnalysisTableGroupsConnection;
  AnalysisTableGroupsEdge: AnalysisTableGroupsEdge;
  AnalysisTableInput: AnalysisTableInput;
  AnalysisTableOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdUsingAnalysisTablePkeyUpdate: AnalysisTableOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdUsingAnalysisTablePkeyUpdate;
  AnalysisTableOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdUsingAnalysisTablePkeyUpdate: AnalysisTableOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdUsingAnalysisTablePkeyUpdate;
  AnalysisTableOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingAnalysisTablePkeyUpdate: AnalysisTableOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingAnalysisTablePkeyUpdate;
  AnalysisTableOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdUsingAnalysisTablePkeyUpdate: AnalysisTableOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdUsingAnalysisTablePkeyUpdate;
  AnalysisTableOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingAnalysisTablePkeyUpdate: AnalysisTableOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingAnalysisTablePkeyUpdate;
  AnalysisTableOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdUsingAnalysisTablePkeyUpdate: AnalysisTableOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdUsingAnalysisTablePkeyUpdate;
  AnalysisTablePatch: AnalysisTablePatch;
  AnalysisTableProjectId709Aebc7FkProjectIdAnalysisTableCreateInput: AnalysisTableProjectId709Aebc7FkProjectIdAnalysisTableCreateInput;
  AnalysisTableProjectId709Aebc7FkProjectIdInput: AnalysisTableProjectId709Aebc7FkProjectIdInput;
  AnalysisTableProjectId709Aebc7FkProjectIdInverseInput: AnalysisTableProjectId709Aebc7FkProjectIdInverseInput;
  AnalysisTableRow: AnalysisTableRow;
  AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisAnalysisTableRowAttachResultsCreateInput: AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisAnalysisTableRowAttachResultsCreateInput;
  AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisAnalysisTableRowCreateInput: AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisAnalysisTableRowCreateInput;
  AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInput: AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInput;
  AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput: AnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisInverseInput;
  AnalysisTableRowAProjectIdE3E030E2FkProjectIAnalysisTableRowAttachResultsCreateInput: AnalysisTableRowAProjectIdE3E030E2FkProjectIAnalysisTableRowAttachResultsCreateInput;
  AnalysisTableRowAProjectIdE3E030E2FkProjectIInput: AnalysisTableRowAProjectIdE3E030E2FkProjectIInput;
  AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput: AnalysisTableRowAProjectIdE3E030E2FkProjectIInverseInput;
  AnalysisTableRowAnalysisTableRowPkeyConnect: AnalysisTableRowAnalysisTableRowPkeyConnect;
  AnalysisTableRowAnalysisTableRowPkeyDelete: AnalysisTableRowAnalysisTableRowPkeyDelete;
  AnalysisTableRowAnalysisTableRowTaskIdKeyConnect: AnalysisTableRowAnalysisTableRowTaskIdKeyConnect;
  AnalysisTableRowAnalysisTableRowTaskIdKeyDelete: AnalysisTableRowAnalysisTableRowTaskIdKeyDelete;
  AnalysisTableRowAttachResult: AnalysisTableRowAttachResult;
  AnalysisTableRowAttachResultAnalysisTableRowAttachResultsPkeyConnect: AnalysisTableRowAttachResultAnalysisTableRowAttachResultsPkeyConnect;
  AnalysisTableRowAttachResultAnalysisTableRowAttachResultsPkeyDelete: AnalysisTableRowAttachResultAnalysisTableRowAttachResultsPkeyDelete;
  AnalysisTableRowAttachResultCondition: AnalysisTableRowAttachResultCondition;
  AnalysisTableRowAttachResultFilter: AnalysisTableRowAttachResultFilter;
  AnalysisTableRowAttachResultInput: AnalysisTableRowAttachResultInput;
  AnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisUsingAnalysisTableRowAttachResultsPkeyUpdate: AnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisUsingAnalysisTableRowAttachResultsPkeyUpdate;
  AnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIUsingAnalysisTableRowAttachResultsPkeyUpdate: AnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIUsingAnalysisTableRowAttachResultsPkeyUpdate;
  AnalysisTableRowAttachResultPatch: AnalysisTableRowAttachResultPatch;
  AnalysisTableRowAttachResultsConnection: AnalysisTableRowAttachResultsConnection;
  AnalysisTableRowAttachResultsEdge: AnalysisTableRowAttachResultsEdge;
  AnalysisTableRowCondition: AnalysisTableRowCondition;
  AnalysisTableRowFilter: AnalysisTableRowFilter;
  AnalysisTableRowInput: AnalysisTableRowInput;
  AnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisUsingAnalysisTableRowPkeyUpdate: AnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisUsingAnalysisTableRowPkeyUpdate;
  AnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisUsingAnalysisTableRowTaskIdKeyUpdate: AnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisUsingAnalysisTableRowTaskIdKeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingAnalysisTableRowPkeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingAnalysisTableRowPkeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingAnalysisTableRowTaskIdKeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingAnalysisTableRowTaskIdKeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdUsingAnalysisTableRowPkeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdUsingAnalysisTableRowPkeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdUsingAnalysisTableRowTaskIdKeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdUsingAnalysisTableRowTaskIdKeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingAnalysisTableRowPkeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingAnalysisTableRowPkeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingAnalysisTableRowTaskIdKeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingAnalysisTableRowTaskIdKeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingAnalysisTableRowPkeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingAnalysisTableRowPkeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingAnalysisTableRowTaskIdKeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingAnalysisTableRowTaskIdKeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingAnalysisTableRowPkeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingAnalysisTableRowPkeyUpdate;
  AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingAnalysisTableRowTaskIdKeyUpdate: AnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingAnalysisTableRowTaskIdKeyUpdate;
  AnalysisTableRowPatch: AnalysisTableRowPatch;
  AnalysisTableRowProjectIdCd370B28FkProjectIdAnalysisTableRowCreateInput: AnalysisTableRowProjectIdCd370B28FkProjectIdAnalysisTableRowCreateInput;
  AnalysisTableRowProjectIdCd370B28FkProjectIdInput: AnalysisTableRowProjectIdCd370B28FkProjectIdInput;
  AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput: AnalysisTableRowProjectIdCd370B28FkProjectIdInverseInput;
  AnalysisTableRowTableIdB1C24185FkAnalysisTableIdAnalysisTableCreateInput: AnalysisTableRowTableIdB1C24185FkAnalysisTableIdAnalysisTableCreateInput;
  AnalysisTableRowTableIdB1C24185FkAnalysisTableIdAnalysisTableRowCreateInput: AnalysisTableRowTableIdB1C24185FkAnalysisTableIdAnalysisTableRowCreateInput;
  AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput: AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInput;
  AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput: AnalysisTableRowTableIdB1C24185FkAnalysisTableIdInverseInput;
  AnalysisTableRowTaskId742Ff0EdFkTaskIdAnalysisTableRowCreateInput: AnalysisTableRowTaskId742Ff0EdFkTaskIdAnalysisTableRowCreateInput;
  AnalysisTableRowTaskId742Ff0EdFkTaskIdInput: AnalysisTableRowTaskId742Ff0EdFkTaskIdInput;
  AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput: AnalysisTableRowTaskId742Ff0EdFkTaskIdInverseInput;
  AnalysisTableRowTaskId742Ff0EdFkTaskIdTaskCreateInput: AnalysisTableRowTaskId742Ff0EdFkTaskIdTaskCreateInput;
  AnalysisTableRowTenantIdDb778B87FkTenantIdAnalysisTableRowCreateInput: AnalysisTableRowTenantIdDb778B87FkTenantIdAnalysisTableRowCreateInput;
  AnalysisTableRowTenantIdDb778B87FkTenantIdInput: AnalysisTableRowTenantIdDb778B87FkTenantIdInput;
  AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput: AnalysisTableRowTenantIdDb778B87FkTenantIdInverseInput;
  AnalysisTableRowTenantIdDb778B87FkTenantIdTenantCreateInput: AnalysisTableRowTenantIdDb778B87FkTenantIdTenantCreateInput;
  AnalysisTableRowToManyAnalysisTableRowAttachResultFilter: AnalysisTableRowToManyAnalysisTableRowAttachResultFilter;
  AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdAnalysisTableRowCreateInput: AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdAnalysisTableRowCreateInput;
  AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput: AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInput;
  AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput: AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdInverseInput;
  AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdToolVersionCreateInput: AnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdToolVersionCreateInput;
  AnalysisTableRowsConnection: AnalysisTableRowsConnection;
  AnalysisTableRowsEdge: AnalysisTableRowsEdge;
  AnalysisTableTenantIdF297C671FkTenantIdAnalysisTableCreateInput: AnalysisTableTenantIdF297C671FkTenantIdAnalysisTableCreateInput;
  AnalysisTableTenantIdF297C671FkTenantIdInput: AnalysisTableTenantIdF297C671FkTenantIdInput;
  AnalysisTableTenantIdF297C671FkTenantIdInverseInput: AnalysisTableTenantIdF297C671FkTenantIdInverseInput;
  AnalysisTableTenantIdF297C671FkTenantIdTenantCreateInput: AnalysisTableTenantIdF297C671FkTenantIdTenantCreateInput;
  AnalysisTableToManyAnalysisTableRowFilter: AnalysisTableToManyAnalysisTableRowFilter;
  AnalysisTableToolVersionId8E4A2317FkToolVersionIdAnalysisTableCreateInput: AnalysisTableToolVersionId8E4A2317FkToolVersionIdAnalysisTableCreateInput;
  AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput: AnalysisTableToolVersionId8E4A2317FkToolVersionIdInput;
  AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput: AnalysisTableToolVersionId8E4A2317FkToolVersionIdInverseInput;
  AnalysisTableToolVersionId8E4A2317FkToolVersionIdToolVersionCreateInput: AnalysisTableToolVersionId8E4A2317FkToolVersionIdToolVersionCreateInput;
  AnalysisTableUserIdD5D6De41FkApplicationUserIdAnalysisTableCreateInput: AnalysisTableUserIdD5D6De41FkApplicationUserIdAnalysisTableCreateInput;
  AnalysisTableUserIdD5D6De41FkApplicationUserIdApplicationUserCreateInput: AnalysisTableUserIdD5D6De41FkApplicationUserIdApplicationUserCreateInput;
  AnalysisTableUserIdD5D6De41FkApplicationUserIdInput: AnalysisTableUserIdD5D6De41FkApplicationUserIdInput;
  AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput: AnalysisTableUserIdD5D6De41FkApplicationUserIdInverseInput;
  AnalysisTablesConnection: AnalysisTablesConnection;
  AnalysisTablesEdge: AnalysisTablesEdge;
  ApplicationUser: ApplicationUser;
  ApplicationUserApplicationUserEmailKeyConnect: ApplicationUserApplicationUserEmailKeyConnect;
  ApplicationUserApplicationUserEmailKeyDelete: ApplicationUserApplicationUserEmailKeyDelete;
  ApplicationUserApplicationUserPkeyConnect: ApplicationUserApplicationUserPkeyConnect;
  ApplicationUserApplicationUserPkeyDelete: ApplicationUserApplicationUserPkeyDelete;
  ApplicationUserApplicationUserUsernameKeyConnect: ApplicationUserApplicationUserUsernameKeyConnect;
  ApplicationUserApplicationUserUsernameKeyDelete: ApplicationUserApplicationUserUsernameKeyDelete;
  ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput: ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInput;
  ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInverseInput: ApplicationUserExecutorId69B7Cb94FkTenantUserexecutorIdInverseInput;
  ApplicationUserFilter: ApplicationUserFilter;
  ApplicationUserGroup: ApplicationUserGroup;
  ApplicationUserGroupApplicationUserGroupsPkeyConnect: ApplicationUserGroupApplicationUserGroupsPkeyConnect;
  ApplicationUserGroupApplicationUserGroupsPkeyDelete: ApplicationUserGroupApplicationUserGroupsPkeyDelete;
  ApplicationUserGroupApplicationUserGroupsUserIdGroupId3A20Ace4UniqConnect: ApplicationUserGroupApplicationUserGroupsUserIdGroupId3A20Ace4UniqConnect;
  ApplicationUserGroupApplicationUserGroupsUserIdGroupId3A20Ace4UniqDelete: ApplicationUserGroupApplicationUserGroupsUserIdGroupId3A20Ace4UniqDelete;
  ApplicationUserGroupCondition: ApplicationUserGroupCondition;
  ApplicationUserGroupFilter: ApplicationUserGroupFilter;
  ApplicationUserGroupInput: ApplicationUserGroupInput;
  ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingApplicationUserGroupsPkeyUpdate: ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingApplicationUserGroupsPkeyUpdate;
  ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingApplicationUserGroupsUserIdGroupId3A20Ace4UniqUpdate: ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingApplicationUserGroupsUserIdGroupId3A20Ace4UniqUpdate;
  ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserGroupsPkeyUpdate: ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserGroupsPkeyUpdate;
  ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserGroupsUserIdGroupId3A20Ace4UniqUpdate: ApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserGroupsUserIdGroupId3A20Ace4UniqUpdate;
  ApplicationUserGroupPatch: ApplicationUserGroupPatch;
  ApplicationUserGroupsConnection: ApplicationUserGroupsConnection;
  ApplicationUserGroupsEdge: ApplicationUserGroupsEdge;
  ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdApplicationUserGroupsCreateInput: ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdApplicationUserGroupsCreateInput;
  ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdAuthGroupCreateInput: ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdAuthGroupCreateInput;
  ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInput: ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInput;
  ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInverseInput: ApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdInverseInput;
  ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdApplicationUserCreateInput: ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdApplicationUserCreateInput;
  ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdApplicationUserGroupsCreateInput: ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdApplicationUserGroupsCreateInput;
  ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInput: ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInput;
  ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput: ApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdInverseInput;
  ApplicationUserInput: ApplicationUserInput;
  ApplicationUserMemUserId631Fddc5FkApplicatiApplicationUserCreateInput: ApplicationUserMemUserId631Fddc5FkApplicatiApplicationUserCreateInput;
  ApplicationUserMemUserId631Fddc5FkApplicatiApplicationUserMembershipCreateInput: ApplicationUserMemUserId631Fddc5FkApplicatiApplicationUserMembershipCreateInput;
  ApplicationUserMemUserId631Fddc5FkApplicatiInput: ApplicationUserMemUserId631Fddc5FkApplicatiInput;
  ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput: ApplicationUserMemUserId631Fddc5FkApplicatiInverseInput;
  ApplicationUserMembership: ApplicationUserMembership;
  ApplicationUserMembershipApplicationUserMembershipPkeyConnect: ApplicationUserMembershipApplicationUserMembershipPkeyConnect;
  ApplicationUserMembershipApplicationUserMembershipPkeyDelete: ApplicationUserMembershipApplicationUserMembershipPkeyDelete;
  ApplicationUserMembershipCondition: ApplicationUserMembershipCondition;
  ApplicationUserMembershipFilter: ApplicationUserMembershipFilter;
  ApplicationUserMembershipInput: ApplicationUserMembershipInput;
  ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserMembershipPkeyUpdate: ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserMembershipPkeyUpdate;
  ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingUserTenantUniqueUpdate: ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingUserTenantUniqueUpdate;
  ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingApplicationUserMembershipPkeyUpdate: ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingApplicationUserMembershipPkeyUpdate;
  ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingUserTenantUniqueUpdate: ApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingUserTenantUniqueUpdate;
  ApplicationUserMembershipPatch: ApplicationUserMembershipPatch;
  ApplicationUserMembershipTenantId5E54Da7AFkTenantIdApplicationUserMembershipCreateInput: ApplicationUserMembershipTenantId5E54Da7AFkTenantIdApplicationUserMembershipCreateInput;
  ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInput: ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInput;
  ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput: ApplicationUserMembershipTenantId5E54Da7AFkTenantIdInverseInput;
  ApplicationUserMembershipTenantId5E54Da7AFkTenantIdTenantCreateInput: ApplicationUserMembershipTenantId5E54Da7AFkTenantIdTenantCreateInput;
  ApplicationUserMembershipUserTenantUniqueConnect: ApplicationUserMembershipUserTenantUniqueConnect;
  ApplicationUserMembershipUserTenantUniqueDelete: ApplicationUserMembershipUserTenantUniqueDelete;
  ApplicationUserMembershipsConnection: ApplicationUserMembershipsConnection;
  ApplicationUserMembershipsEdge: ApplicationUserMembershipsEdge;
  ApplicationUserMetUserId897Fbde1FkApplicatiApplicationUserCreateInput: ApplicationUserMetUserId897Fbde1FkApplicatiApplicationUserCreateInput;
  ApplicationUserMetUserId897Fbde1FkApplicatiApplicationUserMetadataCreateInput: ApplicationUserMetUserId897Fbde1FkApplicatiApplicationUserMetadataCreateInput;
  ApplicationUserMetUserId897Fbde1FkApplicatiInput: ApplicationUserMetUserId897Fbde1FkApplicatiInput;
  ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput: ApplicationUserMetUserId897Fbde1FkApplicatiInverseInput;
  ApplicationUserMetadataConnection: ApplicationUserMetadataConnection;
  ApplicationUserMetadataEdge: ApplicationUserMetadataEdge;
  ApplicationUserMetadatum: ApplicationUserMetadatum;
  ApplicationUserMetadatumApplicationUserMetadataPkeyConnect: ApplicationUserMetadatumApplicationUserMetadataPkeyConnect;
  ApplicationUserMetadatumApplicationUserMetadataPkeyDelete: ApplicationUserMetadatumApplicationUserMetadataPkeyDelete;
  ApplicationUserMetadatumCondition: ApplicationUserMetadatumCondition;
  ApplicationUserMetadatumFilter: ApplicationUserMetadatumFilter;
  ApplicationUserMetadatumInput: ApplicationUserMetadatumInput;
  ApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserMetadataPkeyUpdate: ApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserMetadataPkeyUpdate;
  ApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingUserKeyUpdate: ApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingUserKeyUpdate;
  ApplicationUserMetadatumPatch: ApplicationUserMetadatumPatch;
  ApplicationUserMetadatumUserKeyConnect: ApplicationUserMetadatumUserKeyConnect;
  ApplicationUserMetadatumUserKeyDelete: ApplicationUserMetadatumUserKeyDelete;
  ApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingApplicationUserEmailKeyUpdate: ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingApplicationUserPkeyUpdate: ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingApplicationUserPkeyUpdate;
  ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingApplicationUserEmailKeyUpdate: ApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingApplicationUserEmailKeyUpdate;
  ApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingApplicationUserPkeyUpdate: ApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingApplicationUserPkeyUpdate;
  ApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate: ApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingApplicationUserUsernameKeyUpdate;
  ApplicationUserPatch: ApplicationUserPatch;
  ApplicationUserSesUserId2Fb30D0CFkApplicatiApplicationUserCreateInput: ApplicationUserSesUserId2Fb30D0CFkApplicatiApplicationUserCreateInput;
  ApplicationUserSesUserId2Fb30D0CFkApplicatiApplicationUserSessionCreateInput: ApplicationUserSesUserId2Fb30D0CFkApplicatiApplicationUserSessionCreateInput;
  ApplicationUserSesUserId2Fb30D0CFkApplicatiInput: ApplicationUserSesUserId2Fb30D0CFkApplicatiInput;
  ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput: ApplicationUserSesUserId2Fb30D0CFkApplicatiInverseInput;
  ApplicationUserSession: ApplicationUserSession;
  ApplicationUserSessionApplicationUserSessionPkeyConnect: ApplicationUserSessionApplicationUserSessionPkeyConnect;
  ApplicationUserSessionApplicationUserSessionPkeyDelete: ApplicationUserSessionApplicationUserSessionPkeyDelete;
  ApplicationUserSessionCondition: ApplicationUserSessionCondition;
  ApplicationUserSessionFilter: ApplicationUserSessionFilter;
  ApplicationUserSessionInput: ApplicationUserSessionInput;
  ApplicationUserSessionOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserSessionPkeyUpdate: ApplicationUserSessionOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiUsingApplicationUserSessionPkeyUpdate;
  ApplicationUserSessionOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingApplicationUserSessionPkeyUpdate: ApplicationUserSessionOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingApplicationUserSessionPkeyUpdate;
  ApplicationUserSessionPatch: ApplicationUserSessionPatch;
  ApplicationUserSessionTenantId00A81Cc5FkTenantIdApplicationUserSessionCreateInput: ApplicationUserSessionTenantId00A81Cc5FkTenantIdApplicationUserSessionCreateInput;
  ApplicationUserSessionTenantId00A81Cc5FkTenantIdInput: ApplicationUserSessionTenantId00A81Cc5FkTenantIdInput;
  ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput: ApplicationUserSessionTenantId00A81Cc5FkTenantIdInverseInput;
  ApplicationUserSessionTenantId00A81Cc5FkTenantIdTenantCreateInput: ApplicationUserSessionTenantId00A81Cc5FkTenantIdTenantCreateInput;
  ApplicationUserSessionsConnection: ApplicationUserSessionsConnection;
  ApplicationUserSessionsEdge: ApplicationUserSessionsEdge;
  ApplicationUserToManyAnalysisTableFilter: ApplicationUserToManyAnalysisTableFilter;
  ApplicationUserToManyAnalysisTableGroupFilter: ApplicationUserToManyAnalysisTableGroupFilter;
  ApplicationUserToManyApplicationUserGroupFilter: ApplicationUserToManyApplicationUserGroupFilter;
  ApplicationUserToManyApplicationUserMembershipFilter: ApplicationUserToManyApplicationUserMembershipFilter;
  ApplicationUserToManyApplicationUserMetadatumFilter: ApplicationUserToManyApplicationUserMetadatumFilter;
  ApplicationUserToManyApplicationUserSessionFilter: ApplicationUserToManyApplicationUserSessionFilter;
  ApplicationUserToManyApplicationUserUserPermissionFilter: ApplicationUserToManyApplicationUserUserPermissionFilter;
  ApplicationUserToManyDatasetFilter: ApplicationUserToManyDatasetFilter;
  ApplicationUserToManyDjangoAdminLogFilter: ApplicationUserToManyDjangoAdminLogFilter;
  ApplicationUserToManyEulaVersionUserAcceptanceFilter: ApplicationUserToManyEulaVersionUserAcceptanceFilter;
  ApplicationUserToManyExplorerQueryFilter: ApplicationUserToManyExplorerQueryFilter;
  ApplicationUserToManyExplorerQueryfavoriteFilter: ApplicationUserToManyExplorerQueryfavoriteFilter;
  ApplicationUserToManyExplorerQuerylogFilter: ApplicationUserToManyExplorerQuerylogFilter;
  ApplicationUserToManyFileActivityFilter: ApplicationUserToManyFileActivityFilter;
  ApplicationUserToManyFileFilter: ApplicationUserToManyFileFilter;
  ApplicationUserToManyFileOutputGroupFilter: ApplicationUserToManyFileOutputGroupFilter;
  ApplicationUserToManyFileProcessingNotificationFilter: ApplicationUserToManyFileProcessingNotificationFilter;
  ApplicationUserToManyFileRecordingGroupFilter: ApplicationUserToManyFileRecordingGroupFilter;
  ApplicationUserToManyMetadataValueFilter: ApplicationUserToManyMetadataValueFilter;
  ApplicationUserToManyNotificationFilter: ApplicationUserToManyNotificationFilter;
  ApplicationUserToManyProjectClonedNotificationFilter: ApplicationUserToManyProjectClonedNotificationFilter;
  ApplicationUserToManyProjectFilter: ApplicationUserToManyProjectFilter;
  ApplicationUserToManyProjectSharedNotificationFilter: ApplicationUserToManyProjectSharedNotificationFilter;
  ApplicationUserToManyRecordingGroupFileFilter: ApplicationUserToManyRecordingGroupFileFilter;
  ApplicationUserToManyRoleChangeNotificationFilter: ApplicationUserToManyRoleChangeNotificationFilter;
  ApplicationUserToManyTagFilter: ApplicationUserToManyTagFilter;
  ApplicationUserToManyTaskFilter: ApplicationUserToManyTaskFilter;
  ApplicationUserToManyTenantMonthlyConsumedResourceFilter: ApplicationUserToManyTenantMonthlyConsumedResourceFilter;
  ApplicationUserToManyTenantUserMembershipNotificationFilter: ApplicationUserToManyTenantUserMembershipNotificationFilter;
  ApplicationUserToManyWorkflowFilter: ApplicationUserToManyWorkflowFilter;
  ApplicationUserToManyWorkflowVersionFilter: ApplicationUserToManyWorkflowVersionFilter;
  ApplicationUserUsePermissionIdDbf9693DFkAuthPermApplicationUserUserPermissionsCreateInput: ApplicationUserUsePermissionIdDbf9693DFkAuthPermApplicationUserUserPermissionsCreateInput;
  ApplicationUserUsePermissionIdDbf9693DFkAuthPermAuthPermissionCreateInput: ApplicationUserUsePermissionIdDbf9693DFkAuthPermAuthPermissionCreateInput;
  ApplicationUserUsePermissionIdDbf9693DFkAuthPermInput: ApplicationUserUsePermissionIdDbf9693DFkAuthPermInput;
  ApplicationUserUsePermissionIdDbf9693DFkAuthPermInverseInput: ApplicationUserUsePermissionIdDbf9693DFkAuthPermInverseInput;
  ApplicationUserUseUserIdE643F548FkApplicatiApplicationUserCreateInput: ApplicationUserUseUserIdE643F548FkApplicatiApplicationUserCreateInput;
  ApplicationUserUseUserIdE643F548FkApplicatiApplicationUserUserPermissionsCreateInput: ApplicationUserUseUserIdE643F548FkApplicatiApplicationUserUserPermissionsCreateInput;
  ApplicationUserUseUserIdE643F548FkApplicatiInput: ApplicationUserUseUserIdE643F548FkApplicatiInput;
  ApplicationUserUseUserIdE643F548FkApplicatiInverseInput: ApplicationUserUseUserIdE643F548FkApplicatiInverseInput;
  ApplicationUserUserPermission: ApplicationUserUserPermission;
  ApplicationUserUserPermissionApplicationUserUserPeUserIdPermissionId52D58A70UniqConnect: ApplicationUserUserPermissionApplicationUserUserPeUserIdPermissionId52D58A70UniqConnect;
  ApplicationUserUserPermissionApplicationUserUserPeUserIdPermissionId52D58A70UniqDelete: ApplicationUserUserPermissionApplicationUserUserPeUserIdPermissionId52D58A70UniqDelete;
  ApplicationUserUserPermissionApplicationUserUserPermissionsPkeyConnect: ApplicationUserUserPermissionApplicationUserUserPermissionsPkeyConnect;
  ApplicationUserUserPermissionApplicationUserUserPermissionsPkeyDelete: ApplicationUserUserPermissionApplicationUserUserPermissionsPkeyDelete;
  ApplicationUserUserPermissionCondition: ApplicationUserUserPermissionCondition;
  ApplicationUserUserPermissionFilter: ApplicationUserUserPermissionFilter;
  ApplicationUserUserPermissionInput: ApplicationUserUserPermissionInput;
  ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingApplicationUserUserPeUserIdPermissionId52D58A70UniqUpdate: ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingApplicationUserUserPeUserIdPermissionId52D58A70UniqUpdate;
  ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingApplicationUserUserPermissionsPkeyUpdate: ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingApplicationUserUserPermissionsPkeyUpdate;
  ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserUserPeUserIdPermissionId52D58A70UniqUpdate: ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserUserPeUserIdPermissionId52D58A70UniqUpdate;
  ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserUserPermissionsPkeyUpdate: ApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiUsingApplicationUserUserPermissionsPkeyUpdate;
  ApplicationUserUserPermissionPatch: ApplicationUserUserPermissionPatch;
  ApplicationUserUserPermissionsConnection: ApplicationUserUserPermissionsConnection;
  ApplicationUserUserPermissionsEdge: ApplicationUserUserPermissionsEdge;
  ApplicationUsersEdge: ApplicationUsersEdge;
  AuthGroup: AuthGroup;
  AuthGroupAuthGroupNameKeyConnect: AuthGroupAuthGroupNameKeyConnect;
  AuthGroupAuthGroupNameKeyDelete: AuthGroupAuthGroupNameKeyDelete;
  AuthGroupAuthGroupPkeyConnect: AuthGroupAuthGroupPkeyConnect;
  AuthGroupAuthGroupPkeyDelete: AuthGroupAuthGroupPkeyDelete;
  AuthGroupCondition: AuthGroupCondition;
  AuthGroupFilter: AuthGroupFilter;
  AuthGroupInput: AuthGroupInput;
  AuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingAuthGroupNameKeyUpdate: AuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingAuthGroupNameKeyUpdate;
  AuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingAuthGroupPkeyUpdate: AuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdUsingAuthGroupPkeyUpdate;
  AuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupNameKeyUpdate: AuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupNameKeyUpdate;
  AuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupPkeyUpdate: AuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupPkeyUpdate;
  AuthGroupPatch: AuthGroupPatch;
  AuthGroupPermissioPermissionId84C5C92EFkAuthPermAuthGroupPermissionsCreateInput: AuthGroupPermissioPermissionId84C5C92EFkAuthPermAuthGroupPermissionsCreateInput;
  AuthGroupPermissioPermissionId84C5C92EFkAuthPermAuthPermissionCreateInput: AuthGroupPermissioPermissionId84C5C92EFkAuthPermAuthPermissionCreateInput;
  AuthGroupPermissioPermissionId84C5C92EFkAuthPermInput: AuthGroupPermissioPermissionId84C5C92EFkAuthPermInput;
  AuthGroupPermissioPermissionId84C5C92EFkAuthPermInverseInput: AuthGroupPermissioPermissionId84C5C92EFkAuthPermInverseInput;
  AuthGroupPermission: AuthGroupPermission;
  AuthGroupPermissionAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqConnect: AuthGroupPermissionAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqConnect;
  AuthGroupPermissionAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqDelete: AuthGroupPermissionAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqDelete;
  AuthGroupPermissionAuthGroupPermissionsPkeyConnect: AuthGroupPermissionAuthGroupPermissionsPkeyConnect;
  AuthGroupPermissionAuthGroupPermissionsPkeyDelete: AuthGroupPermissionAuthGroupPermissionsPkeyDelete;
  AuthGroupPermissionCondition: AuthGroupPermissionCondition;
  AuthGroupPermissionFilter: AuthGroupPermissionFilter;
  AuthGroupPermissionInput: AuthGroupPermissionInput;
  AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqUpdate: AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqUpdate;
  AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthGroupPermissionsPkeyUpdate: AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthGroupPermissionsPkeyUpdate;
  AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqUpdate: AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupPermissionsGroupIdPermissionId0Cd325B0UniqUpdate;
  AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupPermissionsPkeyUpdate: AuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdUsingAuthGroupPermissionsPkeyUpdate;
  AuthGroupPermissionPatch: AuthGroupPermissionPatch;
  AuthGroupPermissionsConnection: AuthGroupPermissionsConnection;
  AuthGroupPermissionsEdge: AuthGroupPermissionsEdge;
  AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdAuthGroupCreateInput: AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdAuthGroupCreateInput;
  AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdAuthGroupPermissionsCreateInput: AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdAuthGroupPermissionsCreateInput;
  AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInput: AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInput;
  AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInverseInput: AuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdInverseInput;
  AuthGroupToManyApplicationUserGroupFilter: AuthGroupToManyApplicationUserGroupFilter;
  AuthGroupToManyAuthGroupPermissionFilter: AuthGroupToManyAuthGroupPermissionFilter;
  AuthGroupsConnection: AuthGroupsConnection;
  AuthGroupsEdge: AuthGroupsEdge;
  AuthPermission: AuthPermission;
  AuthPermissionAuthPermissionContentTypeIdCodename01Ab375AUniqConnect: AuthPermissionAuthPermissionContentTypeIdCodename01Ab375AUniqConnect;
  AuthPermissionAuthPermissionContentTypeIdCodename01Ab375AUniqDelete: AuthPermissionAuthPermissionContentTypeIdCodename01Ab375AUniqDelete;
  AuthPermissionAuthPermissionPkeyConnect: AuthPermissionAuthPermissionPkeyConnect;
  AuthPermissionAuthPermissionPkeyDelete: AuthPermissionAuthPermissionPkeyDelete;
  AuthPermissionCondition: AuthPermissionCondition;
  AuthPermissionContentTypeId2F476E4BFkDjangoCoAuthPermissionCreateInput: AuthPermissionContentTypeId2F476E4BFkDjangoCoAuthPermissionCreateInput;
  AuthPermissionContentTypeId2F476E4BFkDjangoCoDjangoContentTypeCreateInput: AuthPermissionContentTypeId2F476E4BFkDjangoCoDjangoContentTypeCreateInput;
  AuthPermissionContentTypeId2F476E4BFkDjangoCoInput: AuthPermissionContentTypeId2F476E4BFkDjangoCoInput;
  AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput: AuthPermissionContentTypeId2F476E4BFkDjangoCoInverseInput;
  AuthPermissionFilter: AuthPermissionFilter;
  AuthPermissionInput: AuthPermissionInput;
  AuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingAuthPermissionContentTypeIdCodename01Ab375AUniqUpdate: AuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingAuthPermissionContentTypeIdCodename01Ab375AUniqUpdate;
  AuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingAuthPermissionPkeyUpdate: AuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermUsingAuthPermissionPkeyUpdate;
  AuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthPermissionContentTypeIdCodename01Ab375AUniqUpdate: AuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthPermissionContentTypeIdCodename01Ab375AUniqUpdate;
  AuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthPermissionPkeyUpdate: AuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermUsingAuthPermissionPkeyUpdate;
  AuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingAuthPermissionContentTypeIdCodename01Ab375AUniqUpdate: AuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingAuthPermissionContentTypeIdCodename01Ab375AUniqUpdate;
  AuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingAuthPermissionPkeyUpdate: AuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingAuthPermissionPkeyUpdate;
  AuthPermissionPatch: AuthPermissionPatch;
  AuthPermissionToManyApplicationUserUserPermissionFilter: AuthPermissionToManyApplicationUserUserPermissionFilter;
  AuthPermissionToManyAuthGroupPermissionFilter: AuthPermissionToManyAuthGroupPermissionFilter;
  AuthPermissionsConnection: AuthPermissionsConnection;
  AuthPermissionsEdge: AuthPermissionsEdge;
  AvailableNonRestrictedProject: AvailableNonRestrictedProject;
  AvailableNonRestrictedProjectCondition: AvailableNonRestrictedProjectCondition;
  AvailableNonRestrictedProjectFilter: AvailableNonRestrictedProjectFilter;
  AvailableNonRestrictedProjectInput: AvailableNonRestrictedProjectInput;
  AvailableNonRestrictedProjectsConnection: AvailableNonRestrictedProjectsConnection;
  AvailableNonRestrictedProjectsEdge: AvailableNonRestrictedProjectsEdge;
  Avatar: Avatar;
  AvatarAvatarPkeyConnect: AvatarAvatarPkeyConnect;
  AvatarAvatarPkeyDelete: AvatarAvatarPkeyDelete;
  AvatarAvatarUserIdKeyConnect: AvatarAvatarUserIdKeyConnect;
  AvatarAvatarUserIdKeyDelete: AvatarAvatarUserIdKeyDelete;
  AvatarCondition: AvatarCondition;
  AvatarFilter: AvatarFilter;
  AvatarInput: AvatarInput;
  AvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingAvatarPkeyUpdate: AvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingAvatarPkeyUpdate;
  AvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingAvatarUserIdKeyUpdate: AvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdUsingAvatarUserIdKeyUpdate;
  AvatarPatch: AvatarPatch;
  AvatarUserId771332F2FkApplicationUserIdApplicationUserCreateInput: AvatarUserId771332F2FkApplicationUserIdApplicationUserCreateInput;
  AvatarUserId771332F2FkApplicationUserIdAvatarCreateInput: AvatarUserId771332F2FkApplicationUserIdAvatarCreateInput;
  AvatarUserId771332F2FkApplicationUserIdInput: AvatarUserId771332F2FkApplicationUserIdInput;
  AvatarUserId771332F2FkApplicationUserIdInverseInput: AvatarUserId771332F2FkApplicationUserIdInverseInput;
  AvatarsConnection: AvatarsConnection;
  AvatarsEdge: AvatarsEdge;
  AwsBatchTask: AwsBatchTask;
  AwsBatchTaskAwsBatchTaskPkeyConnect: AwsBatchTaskAwsBatchTaskPkeyConnect;
  AwsBatchTaskAwsBatchTaskPkeyDelete: AwsBatchTaskAwsBatchTaskPkeyDelete;
  AwsBatchTaskCondition: AwsBatchTaskCondition;
  AwsBatchTaskFilter: AwsBatchTaskFilter;
  AwsBatchTaskInput: AwsBatchTaskInput;
  AwsBatchTaskOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeUsingAwsBatchTaskPkeyUpdate: AwsBatchTaskOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeUsingAwsBatchTaskPkeyUpdate;
  AwsBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchUsingAwsBatchTaskPkeyUpdate: AwsBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchUsingAwsBatchTaskPkeyUpdate;
  AwsBatchTaskPatch: AwsBatchTaskPatch;
  AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeAwsBatchTaskCreateInput: AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeAwsBatchTaskCreateInput;
  AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInput: AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInput;
  AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInverseInput: AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeInverseInput;
  AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeTesTaskexecutorCreateInput: AwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeTesTaskexecutorCreateInput;
  AwsBatchTasksConnection: AwsBatchTasksConnection;
  AwsBatchTasksEdge: AwsBatchTasksEdge;
  AwsCognitoUser: AwsCognitoUser;
  AwsCognitoUserAwsCognitoUserPkeyConnect: AwsCognitoUserAwsCognitoUserPkeyConnect;
  AwsCognitoUserAwsCognitoUserPkeyDelete: AwsCognitoUserAwsCognitoUserPkeyDelete;
  AwsCognitoUserCondition: AwsCognitoUserCondition;
  AwsCognitoUserFilter: AwsCognitoUserFilter;
  AwsCognitoUserInput: AwsCognitoUserInput;
  AwsCognitoUserOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsUsingAwsCognitoUserPkeyUpdate: AwsCognitoUserOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsUsingAwsCognitoUserPkeyUpdate;
  AwsCognitoUserPatch: AwsCognitoUserPatch;
  AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsAwsCognitoUserCreateInput: AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsAwsCognitoUserCreateInput;
  AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInput: AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInput;
  AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInverseInput: AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsInverseInput;
  AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsTenantUserexecutorCreateInput: AwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsTenantUserexecutorCreateInput;
  AwsCognitoUsersConnection: AwsCognitoUsersConnection;
  AwsCognitoUsersEdge: AwsCognitoUsersEdge;
  AwsEcrImage: AwsEcrImage;
  AwsEcrImageAwsEcrImagePkeyConnect: AwsEcrImageAwsEcrImagePkeyConnect;
  AwsEcrImageAwsEcrImagePkeyDelete: AwsEcrImageAwsEcrImagePkeyDelete;
  AwsEcrImageCondition: AwsEcrImageCondition;
  AwsEcrImageContainerimageexecutAabe4FdbFkTesContaAwsEcrImageCreateInput: AwsEcrImageContainerimageexecutAabe4FdbFkTesContaAwsEcrImageCreateInput;
  AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInput: AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInput;
  AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInverseInput: AwsEcrImageContainerimageexecutAabe4FdbFkTesContaInverseInput;
  AwsEcrImageContainerimageexecutAabe4FdbFkTesContaTesContainerimageexecutorCreateInput: AwsEcrImageContainerimageexecutAabe4FdbFkTesContaTesContainerimageexecutorCreateInput;
  AwsEcrImageFilter: AwsEcrImageFilter;
  AwsEcrImageInput: AwsEcrImageInput;
  AwsEcrImageOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaUsingAwsEcrImagePkeyUpdate: AwsEcrImageOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaUsingAwsEcrImagePkeyUpdate;
  AwsEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIUsingAwsEcrImagePkeyUpdate: AwsEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIUsingAwsEcrImagePkeyUpdate;
  AwsEcrImagePatch: AwsEcrImagePatch;
  AwsEcrImagesConnection: AwsEcrImagesConnection;
  AwsEcrImagesEdge: AwsEcrImagesEdge;
  AwsMockBatchTask: AwsMockBatchTask;
  AwsMockBatchTaskAwsMockBatchTaskPkeyConnect: AwsMockBatchTaskAwsMockBatchTaskPkeyConnect;
  AwsMockBatchTaskAwsMockBatchTaskPkeyDelete: AwsMockBatchTaskAwsMockBatchTaskPkeyDelete;
  AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchAwsBatchTaskCreateInput: AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchAwsBatchTaskCreateInput;
  AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchAwsMockBatchTaskCreateInput: AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchAwsMockBatchTaskCreateInput;
  AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInput: AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInput;
  AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInverseInput: AwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchInverseInput;
  AwsMockBatchTaskCondition: AwsMockBatchTaskCondition;
  AwsMockBatchTaskFilter: AwsMockBatchTaskFilter;
  AwsMockBatchTaskInput: AwsMockBatchTaskInput;
  AwsMockBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchUsingAwsMockBatchTaskPkeyUpdate: AwsMockBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchUsingAwsMockBatchTaskPkeyUpdate;
  AwsMockBatchTaskPatch: AwsMockBatchTaskPatch;
  AwsMockBatchTasksConnection: AwsMockBatchTasksConnection;
  AwsMockBatchTasksEdge: AwsMockBatchTasksEdge;
  AwsMockEcrImage: AwsMockEcrImage;
  AwsMockEcrImageAwsMockEcrImagePkeyConnect: AwsMockEcrImageAwsMockEcrImagePkeyConnect;
  AwsMockEcrImageAwsMockEcrImagePkeyDelete: AwsMockEcrImageAwsMockEcrImagePkeyDelete;
  AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIAwsEcrImageCreateInput: AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIAwsEcrImageCreateInput;
  AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIAwsMockEcrImageCreateInput: AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIAwsMockEcrImageCreateInput;
  AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInput: AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInput;
  AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInverseInput: AwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIInverseInput;
  AwsMockEcrImageCondition: AwsMockEcrImageCondition;
  AwsMockEcrImageFilter: AwsMockEcrImageFilter;
  AwsMockEcrImageInput: AwsMockEcrImageInput;
  AwsMockEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIUsingAwsMockEcrImagePkeyUpdate: AwsMockEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIUsingAwsMockEcrImagePkeyUpdate;
  AwsMockEcrImagePatch: AwsMockEcrImagePatch;
  AwsMockEcrImagesConnection: AwsMockEcrImagesConnection;
  AwsMockEcrImagesEdge: AwsMockEcrImagesEdge;
  AwsMockS3File: AwsMockS3File;
  AwsMockS3FileAwsMockS3FilePkeyConnect: AwsMockS3FileAwsMockS3FilePkeyConnect;
  AwsMockS3FileAwsMockS3FilePkeyDelete: AwsMockS3FileAwsMockS3FilePkeyDelete;
  AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiAwsMockS3FileCreateInput: AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiAwsMockS3FileCreateInput;
  AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiAwsS3FileCreateInput: AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiAwsS3FileCreateInput;
  AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInput: AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInput;
  AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInverseInput: AwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiInverseInput;
  AwsMockS3FileCondition: AwsMockS3FileCondition;
  AwsMockS3FileFilter: AwsMockS3FileFilter;
  AwsMockS3FileInput: AwsMockS3FileInput;
  AwsMockS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiUsingAwsMockS3FilePkeyUpdate: AwsMockS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiUsingAwsMockS3FilePkeyUpdate;
  AwsMockS3FilePatch: AwsMockS3FilePatch;
  AwsMockS3FilesConnection: AwsMockS3FilesConnection;
  AwsMockS3FilesEdge: AwsMockS3FilesEdge;
  AwsS3File: AwsS3File;
  AwsS3FileAwsS3FilePkeyConnect: AwsS3FileAwsS3FilePkeyConnect;
  AwsS3FileAwsS3FilePkeyDelete: AwsS3FileAwsS3FilePkeyDelete;
  AwsS3FileCondition: AwsS3FileCondition;
  AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdAwsS3FileCreateInput: AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdAwsS3FileCreateInput;
  AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdDrsFileexecutorCreateInput: AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdDrsFileexecutorCreateInput;
  AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInput: AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInput;
  AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInverseInput: AwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdInverseInput;
  AwsS3FileFilter: AwsS3FileFilter;
  AwsS3FileInput: AwsS3FileInput;
  AwsS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiUsingAwsS3FilePkeyUpdate: AwsS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiUsingAwsS3FilePkeyUpdate;
  AwsS3FileOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdUsingAwsS3FilePkeyUpdate: AwsS3FileOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdUsingAwsS3FilePkeyUpdate;
  AwsS3FilePatch: AwsS3FilePatch;
  AwsS3FilesConnection: AwsS3FilesConnection;
  AwsS3FilesEdge: AwsS3FilesEdge;
  BigFloat: Scalars["BigFloat"]["output"];
  BigFloatFilter: BigFloatFilter;
  BigInt: Scalars["BigInt"]["output"];
  BigIntFilter: BigIntFilter;
  Boolean: Scalars["Boolean"]["output"];
  BooleanFilter: BooleanFilter;
  ChangeUserSessionInput: ChangeUserSessionInput;
  ChangeUserSessionPayload: ChangeUserSessionPayload;
  ContainerImage: ContainerImage;
  ContainerImageCondition: ContainerImageCondition;
  ContainerImageContainerImageExecutorIdKeyConnect: ContainerImageContainerImageExecutorIdKeyConnect;
  ContainerImageContainerImageExecutorIdKeyDelete: ContainerImageContainerImageExecutorIdKeyDelete;
  ContainerImageContainerImageNameTagUniqueConnect: ContainerImageContainerImageNameTagUniqueConnect;
  ContainerImageContainerImageNameTagUniqueDelete: ContainerImageContainerImageNameTagUniqueDelete;
  ContainerImageContainerImagePkeyConnect: ContainerImageContainerImagePkeyConnect;
  ContainerImageContainerImagePkeyDelete: ContainerImageContainerImagePkeyDelete;
  ContainerImageContainerImageRepositoryTagUniqueConnect: ContainerImageContainerImageRepositoryTagUniqueConnect;
  ContainerImageContainerImageRepositoryTagUniqueDelete: ContainerImageContainerImageRepositoryTagUniqueDelete;
  ContainerImageContainerImageToolboxVersionIdE9Dede5FUniqConnect: ContainerImageContainerImageToolboxVersionIdE9Dede5FUniqConnect;
  ContainerImageContainerImageToolboxVersionIdE9Dede5FUniqDelete: ContainerImageContainerImageToolboxVersionIdE9Dede5FUniqDelete;
  ContainerImageExecutorId68979Fb6FkTesContaContainerImageCreateInput: ContainerImageExecutorId68979Fb6FkTesContaContainerImageCreateInput;
  ContainerImageExecutorId68979Fb6FkTesContaInput: ContainerImageExecutorId68979Fb6FkTesContaInput;
  ContainerImageExecutorId68979Fb6FkTesContaInverseInput: ContainerImageExecutorId68979Fb6FkTesContaInverseInput;
  ContainerImageExecutorId68979Fb6FkTesContaTesContainerimageexecutorCreateInput: ContainerImageExecutorId68979Fb6FkTesContaTesContainerimageexecutorCreateInput;
  ContainerImageFilter: ContainerImageFilter;
  ContainerImageInput: ContainerImageInput;
  ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageExecutorIdKeyUpdate: ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageExecutorIdKeyUpdate;
  ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageNameTagUniqueUpdate: ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageNameTagUniqueUpdate;
  ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImagePkeyUpdate: ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImagePkeyUpdate;
  ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageRepositoryTagUniqueUpdate: ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageRepositoryTagUniqueUpdate;
  ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate: ContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate;
  ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageExecutorIdKeyUpdate: ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageExecutorIdKeyUpdate;
  ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageNameTagUniqueUpdate: ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageNameTagUniqueUpdate;
  ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImagePkeyUpdate: ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImagePkeyUpdate;
  ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageRepositoryTagUniqueUpdate: ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageRepositoryTagUniqueUpdate;
  ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate: ContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate;
  ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageExecutorIdKeyUpdate: ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageExecutorIdKeyUpdate;
  ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageNameTagUniqueUpdate: ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageNameTagUniqueUpdate;
  ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImagePkeyUpdate: ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImagePkeyUpdate;
  ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageRepositoryTagUniqueUpdate: ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageRepositoryTagUniqueUpdate;
  ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate: ContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate;
  ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageExecutorIdKeyUpdate: ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageExecutorIdKeyUpdate;
  ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageNameTagUniqueUpdate: ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageNameTagUniqueUpdate;
  ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImagePkeyUpdate: ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImagePkeyUpdate;
  ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageRepositoryTagUniqueUpdate: ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageRepositoryTagUniqueUpdate;
  ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate: ContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingContainerImageToolboxVersionIdE9Dede5FUniqUpdate;
  ContainerImagePatch: ContainerImagePatch;
  ContainerImageToManyTaskFilter: ContainerImageToManyTaskFilter;
  ContainerImageToManyWorkflowFilter: ContainerImageToManyWorkflowFilter;
  ContainerImageToolboxVersionIdE9Dede5FFkToolboxVContainerImageCreateInput: ContainerImageToolboxVersionIdE9Dede5FFkToolboxVContainerImageCreateInput;
  ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInput: ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInput;
  ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInverseInput: ContainerImageToolboxVersionIdE9Dede5FFkToolboxVInverseInput;
  ContainerImageToolboxVersionIdE9Dede5FFkToolboxVToolboxVersionCreateInput: ContainerImageToolboxVersionIdE9Dede5FFkToolboxVToolboxVersionCreateInput;
  ContainerImagesConnection: ContainerImagesConnection;
  ContainerImagesEdge: ContainerImagesEdge;
  Cost: Cost;
  CostCondition: CostCondition;
  CostCostPkeyConnect: CostCostPkeyConnect;
  CostCostPkeyDelete: CostCostPkeyDelete;
  CostFilter: CostFilter;
  CostInput: CostInput;
  CostOnCostForCostTenantId7Dcd58A7FkTenantIdUsingCostPkeyUpdate: CostOnCostForCostTenantId7Dcd58A7FkTenantIdUsingCostPkeyUpdate;
  CostOnFileActivityForFileActivityCostId2A88D90AFkCostIdUsingCostPkeyUpdate: CostOnFileActivityForFileActivityCostId2A88D90AFkCostIdUsingCostPkeyUpdate;
  CostOnTaskActivityForTaskActivityCostId49E66058FkCostIdUsingCostPkeyUpdate: CostOnTaskActivityForTaskActivityCostId49E66058FkCostIdUsingCostPkeyUpdate;
  CostPatch: CostPatch;
  CostTenantId7Dcd58A7FkTenantIdCostCreateInput: CostTenantId7Dcd58A7FkTenantIdCostCreateInput;
  CostTenantId7Dcd58A7FkTenantIdInput: CostTenantId7Dcd58A7FkTenantIdInput;
  CostTenantId7Dcd58A7FkTenantIdInverseInput: CostTenantId7Dcd58A7FkTenantIdInverseInput;
  CostTenantId7Dcd58A7FkTenantIdTenantCreateInput: CostTenantId7Dcd58A7FkTenantIdTenantCreateInput;
  CostToManyFileActivityFilter: CostToManyFileActivityFilter;
  CostToManyTaskActivityFilter: CostToManyTaskActivityFilter;
  CostsConnection: CostsConnection;
  CostsEdge: CostsEdge;
  CreateAnalysisTableGroupInput: CreateAnalysisTableGroupInput;
  CreateAnalysisTableGroupPayload: CreateAnalysisTableGroupPayload;
  CreateAnalysisTableInput: CreateAnalysisTableInput;
  CreateAnalysisTablePayload: CreateAnalysisTablePayload;
  CreateAnalysisTableRowAttachResultInput: CreateAnalysisTableRowAttachResultInput;
  CreateAnalysisTableRowAttachResultPayload: CreateAnalysisTableRowAttachResultPayload;
  CreateAnalysisTableRowInput: CreateAnalysisTableRowInput;
  CreateAnalysisTableRowPayload: CreateAnalysisTableRowPayload;
  CreateApplicationUserGroupInput: CreateApplicationUserGroupInput;
  CreateApplicationUserGroupPayload: CreateApplicationUserGroupPayload;
  CreateApplicationUserInput: CreateApplicationUserInput;
  CreateApplicationUserMembershipInput: CreateApplicationUserMembershipInput;
  CreateApplicationUserMembershipPayload: CreateApplicationUserMembershipPayload;
  CreateApplicationUserMetadatumInput: CreateApplicationUserMetadatumInput;
  CreateApplicationUserMetadatumPayload: CreateApplicationUserMetadatumPayload;
  CreateApplicationUserPayload: CreateApplicationUserPayload;
  CreateApplicationUserSessionInput: CreateApplicationUserSessionInput;
  CreateApplicationUserSessionPayload: CreateApplicationUserSessionPayload;
  CreateApplicationUserUserPermissionInput: CreateApplicationUserUserPermissionInput;
  CreateApplicationUserUserPermissionPayload: CreateApplicationUserUserPermissionPayload;
  CreateAuthGroupInput: CreateAuthGroupInput;
  CreateAuthGroupPayload: CreateAuthGroupPayload;
  CreateAuthGroupPermissionInput: CreateAuthGroupPermissionInput;
  CreateAuthGroupPermissionPayload: CreateAuthGroupPermissionPayload;
  CreateAuthPermissionInput: CreateAuthPermissionInput;
  CreateAuthPermissionPayload: CreateAuthPermissionPayload;
  CreateAvailableNonRestrictedProjectInput: CreateAvailableNonRestrictedProjectInput;
  CreateAvailableNonRestrictedProjectPayload: CreateAvailableNonRestrictedProjectPayload;
  CreateAvatarInput: CreateAvatarInput;
  CreateAvatarPayload: CreateAvatarPayload;
  CreateAwsBatchTaskInput: CreateAwsBatchTaskInput;
  CreateAwsBatchTaskPayload: CreateAwsBatchTaskPayload;
  CreateAwsCognitoUserInput: CreateAwsCognitoUserInput;
  CreateAwsCognitoUserPayload: CreateAwsCognitoUserPayload;
  CreateAwsEcrImageInput: CreateAwsEcrImageInput;
  CreateAwsEcrImagePayload: CreateAwsEcrImagePayload;
  CreateAwsMockBatchTaskInput: CreateAwsMockBatchTaskInput;
  CreateAwsMockBatchTaskPayload: CreateAwsMockBatchTaskPayload;
  CreateAwsMockEcrImageInput: CreateAwsMockEcrImageInput;
  CreateAwsMockEcrImagePayload: CreateAwsMockEcrImagePayload;
  CreateAwsMockS3FileInput: CreateAwsMockS3FileInput;
  CreateAwsMockS3FilePayload: CreateAwsMockS3FilePayload;
  CreateAwsS3FileInput: CreateAwsS3FileInput;
  CreateAwsS3FilePayload: CreateAwsS3FilePayload;
  CreateContainerImageInput: CreateContainerImageInput;
  CreateContainerImagePayload: CreateContainerImagePayload;
  CreateCostInput: CreateCostInput;
  CreateCostPayload: CreateCostPayload;
  CreateCurrentApplicationUserInput: CreateCurrentApplicationUserInput;
  CreateCurrentApplicationUserPayload: CreateCurrentApplicationUserPayload;
  CreateCurrentUserMembershipInput: CreateCurrentUserMembershipInput;
  CreateCurrentUserMembershipPayload: CreateCurrentUserMembershipPayload;
  CreateDatasetInput: CreateDatasetInput;
  CreateDatasetMetadatumInput: CreateDatasetMetadatumInput;
  CreateDatasetMetadatumPayload: CreateDatasetMetadatumPayload;
  CreateDatasetPayload: CreateDatasetPayload;
  CreateDatasetRecordingsTableColumnInput: CreateDatasetRecordingsTableColumnInput;
  CreateDatasetRecordingsTableColumnOrderInput: CreateDatasetRecordingsTableColumnOrderInput;
  CreateDatasetRecordingsTableColumnOrderPayload: CreateDatasetRecordingsTableColumnOrderPayload;
  CreateDatasetRecordingsTableColumnPayload: CreateDatasetRecordingsTableColumnPayload;
  CreateDatasetRecordingsTableInput: CreateDatasetRecordingsTableInput;
  CreateDatasetRecordingsTablePayload: CreateDatasetRecordingsTablePayload;
  CreateDatasetVersionInput: CreateDatasetVersionInput;
  CreateDatasetVersionPayload: CreateDatasetVersionPayload;
  CreateDjangoAdminLogInput: CreateDjangoAdminLogInput;
  CreateDjangoAdminLogPayload: CreateDjangoAdminLogPayload;
  CreateDjangoContentTypeInput: CreateDjangoContentTypeInput;
  CreateDjangoContentTypePayload: CreateDjangoContentTypePayload;
  CreateDjangoMigrationInput: CreateDjangoMigrationInput;
  CreateDjangoMigrationPayload: CreateDjangoMigrationPayload;
  CreateDjangoSesSesstatInput: CreateDjangoSesSesstatInput;
  CreateDjangoSesSesstatPayload: CreateDjangoSesSesstatPayload;
  CreateDjangoSessionInput: CreateDjangoSessionInput;
  CreateDjangoSessionPayload: CreateDjangoSessionPayload;
  CreateDrsFileexecutorInput: CreateDrsFileexecutorInput;
  CreateDrsFileexecutorPayload: CreateDrsFileexecutorPayload;
  CreateDrsMockfileexecutorInput: CreateDrsMockfileexecutorInput;
  CreateDrsMockfileexecutorPayload: CreateDrsMockfileexecutorPayload;
  CreateEulaVersionInput: CreateEulaVersionInput;
  CreateEulaVersionPayload: CreateEulaVersionPayload;
  CreateEulaVersionUserAcceptanceInput: CreateEulaVersionUserAcceptanceInput;
  CreateEulaVersionUserAcceptancePayload: CreateEulaVersionUserAcceptancePayload;
  CreateExplorerQueryInput: CreateExplorerQueryInput;
  CreateExplorerQueryPayload: CreateExplorerQueryPayload;
  CreateExplorerQueryfavoriteInput: CreateExplorerQueryfavoriteInput;
  CreateExplorerQueryfavoritePayload: CreateExplorerQueryfavoritePayload;
  CreateExplorerQuerylogInput: CreateExplorerQuerylogInput;
  CreateExplorerQuerylogPayload: CreateExplorerQuerylogPayload;
  CreateFileActivityInput: CreateFileActivityInput;
  CreateFileActivityPayload: CreateFileActivityPayload;
  CreateFileInput: CreateFileInput;
  CreateFileMetadatumInput: CreateFileMetadatumInput;
  CreateFileMetadatumPayload: CreateFileMetadatumPayload;
  CreateFileOutputGroupMetadatumInput: CreateFileOutputGroupMetadatumInput;
  CreateFileOutputGroupMetadatumPayload: CreateFileOutputGroupMetadatumPayload;
  CreateFilePayload: CreateFilePayload;
  CreateFileRecordingGroupInput: CreateFileRecordingGroupInput;
  CreateFileRecordingGroupMetadatumInput: CreateFileRecordingGroupMetadatumInput;
  CreateFileRecordingGroupMetadatumPayload: CreateFileRecordingGroupMetadatumPayload;
  CreateFileRecordingGroupPayload: CreateFileRecordingGroupPayload;
  CreateMetadataValueInput: CreateMetadataValueInput;
  CreateMetadataValuePayload: CreateMetadataValuePayload;
  CreateMetadatumInput: CreateMetadatumInput;
  CreateMetadatumPayload: CreateMetadatumPayload;
  CreateOutputGroupFileInput: CreateOutputGroupFileInput;
  CreateOutputGroupFilePayload: CreateOutputGroupFilePayload;
  CreateRecordingGroupFileInput: CreateRecordingGroupFileInput;
  CreateRecordingGroupFilePayload: CreateRecordingGroupFilePayload;
  CreateRoleInput: CreateRoleInput;
  CreateRolePayload: CreateRolePayload;
  CreateRunInput: CreateRunInput;
  CreateRunPayload: CreateRunPayload;
  CreateSessionInput: CreateSessionInput;
  CreateSessionPayload: CreateSessionPayload;
  CreateTagInput: CreateTagInput;
  CreateTagPayload: CreateTagPayload;
  CreateTaskActivityInput: CreateTaskActivityInput;
  CreateTaskActivityPayload: CreateTaskActivityPayload;
  CreateTaskInput: CreateTaskInput;
  CreateTaskPayload: CreateTaskPayload;
  CreateTaskResourceInput: CreateTaskResourceInput;
  CreateTaskResourcePayload: CreateTaskResourcePayload;
  CreateTaskSourceInput: CreateTaskSourceInput;
  CreateTaskSourcePayload: CreateTaskSourcePayload;
  CreateTaskStatusLogInput: CreateTaskStatusLogInput;
  CreateTaskStatusLogPayload: CreateTaskStatusLogPayload;
  CreateTenantContractAllowanceInput: CreateTenantContractAllowanceInput;
  CreateTenantContractAllowancePayload: CreateTenantContractAllowancePayload;
  CreateTenantContractInput: CreateTenantContractInput;
  CreateTenantContractPayload: CreateTenantContractPayload;
  CreateTenantContractTransactionInput: CreateTenantContractTransactionInput;
  CreateTenantContractTransactionPayload: CreateTenantContractTransactionPayload;
  CreateTenantInput: CreateTenantInput;
  CreateTenantMockuserexecutorInput: CreateTenantMockuserexecutorInput;
  CreateTenantMockuserexecutorPayload: CreateTenantMockuserexecutorPayload;
  CreateTenantMonthlyConsumedResourceInput: CreateTenantMonthlyConsumedResourceInput;
  CreateTenantMonthlyConsumedResourcePayload: CreateTenantMonthlyConsumedResourcePayload;
  CreateTenantPayload: CreateTenantPayload;
  CreateTenantUserexecutorInput: CreateTenantUserexecutorInput;
  CreateTenantUserexecutorPayload: CreateTenantUserexecutorPayload;
  CreateTesContainerimageexecutorInput: CreateTesContainerimageexecutorInput;
  CreateTesContainerimageexecutorPayload: CreateTesContainerimageexecutorPayload;
  CreateTesMockcontainerimageexecutorInput: CreateTesMockcontainerimageexecutorInput;
  CreateTesMockcontainerimageexecutorPayload: CreateTesMockcontainerimageexecutorPayload;
  CreateTesMocktaskexecutorInput: CreateTesMocktaskexecutorInput;
  CreateTesMocktaskexecutorPayload: CreateTesMocktaskexecutorPayload;
  CreateTesTaskexecutorInput: CreateTesTaskexecutorInput;
  CreateTesTaskexecutorPayload: CreateTesTaskexecutorPayload;
  CreateToolCategoryInput: CreateToolCategoryInput;
  CreateToolCategoryPayload: CreateToolCategoryPayload;
  CreateToolDisplayCategoryInput: CreateToolDisplayCategoryInput;
  CreateToolDisplayCategoryPayload: CreateToolDisplayCategoryPayload;
  CreateToolInput: CreateToolInput;
  CreateToolParameterInput: CreateToolParameterInput;
  CreateToolParameterPayload: CreateToolParameterPayload;
  CreateToolPayload: CreateToolPayload;
  CreateToolResourceInput: CreateToolResourceInput;
  CreateToolResourcePayload: CreateToolResourcePayload;
  CreateToolResultInput: CreateToolResultInput;
  CreateToolResultPayload: CreateToolResultPayload;
  CreateToolSecretInput: CreateToolSecretInput;
  CreateToolSecretPayload: CreateToolSecretPayload;
  CreateToolSourceInput: CreateToolSourceInput;
  CreateToolSourcePayload: CreateToolSourcePayload;
  CreateToolTenantInput: CreateToolTenantInput;
  CreateToolTenantPayload: CreateToolTenantPayload;
  CreateToolVersionInput: CreateToolVersionInput;
  CreateToolVersionPayload: CreateToolVersionPayload;
  CreateToolboxExitcodeInput: CreateToolboxExitcodeInput;
  CreateToolboxExitcodePayload: CreateToolboxExitcodePayload;
  CreateToolboxInput: CreateToolboxInput;
  CreateToolboxPayload: CreateToolboxPayload;
  CreateToolboxTenantInput: CreateToolboxTenantInput;
  CreateToolboxTenantPayload: CreateToolboxTenantPayload;
  CreateToolboxVersionExitcodeInput: CreateToolboxVersionExitcodeInput;
  CreateToolboxVersionExitcodePayload: CreateToolboxVersionExitcodePayload;
  CreateToolboxVersionInput: CreateToolboxVersionInput;
  CreateToolboxVersionPayload: CreateToolboxVersionPayload;
  CreateToolboxVersionToolVersionInput: CreateToolboxVersionToolVersionInput;
  CreateToolboxVersionToolVersionPayload: CreateToolboxVersionToolVersionPayload;
  CreateWorkflowDatumInput: CreateWorkflowDatumInput;
  CreateWorkflowDatumPayload: CreateWorkflowDatumPayload;
  CreateWorkflowInput: CreateWorkflowInput;
  CreateWorkflowPayload: CreateWorkflowPayload;
  CreateWorkflowResultInput: CreateWorkflowResultInput;
  CreateWorkflowResultPayload: CreateWorkflowResultPayload;
  CreateWorkflowResultTagInput: CreateWorkflowResultTagInput;
  CreateWorkflowResultTagPayload: CreateWorkflowResultTagPayload;
  CreateWorkflowResultWorkflowVersionInput: CreateWorkflowResultWorkflowVersionInput;
  CreateWorkflowResultWorkflowVersionPayload: CreateWorkflowResultWorkflowVersionPayload;
  CreateWorkflowSectionBlockInput: CreateWorkflowSectionBlockInput;
  CreateWorkflowSectionBlockPayload: CreateWorkflowSectionBlockPayload;
  CreateWorkflowSectionBlockSectionInput: CreateWorkflowSectionBlockSectionInput;
  CreateWorkflowSectionBlockSectionPayload: CreateWorkflowSectionBlockSectionPayload;
  CreateWorkflowSectionInput: CreateWorkflowSectionInput;
  CreateWorkflowSectionPayload: CreateWorkflowSectionPayload;
  CreateWorkflowSectionWorkflowVersionInput: CreateWorkflowSectionWorkflowVersionInput;
  CreateWorkflowSectionWorkflowVersionPayload: CreateWorkflowSectionWorkflowVersionPayload;
  CreateWorkflowSourceInput: CreateWorkflowSourceInput;
  CreateWorkflowSourcePayload: CreateWorkflowSourcePayload;
  CreateWorkflowSourceTagInput: CreateWorkflowSourceTagInput;
  CreateWorkflowSourceTagPayload: CreateWorkflowSourceTagPayload;
  CreateWorkflowSourceWorkflowVersionInput: CreateWorkflowSourceWorkflowVersionInput;
  CreateWorkflowSourceWorkflowVersionPayload: CreateWorkflowSourceWorkflowVersionPayload;
  CreateWorkflowVersionInput: CreateWorkflowVersionInput;
  CreateWorkflowVersionPayload: CreateWorkflowVersionPayload;
  CurrentApplicationUser: CurrentApplicationUser;
  CurrentApplicationUserCondition: CurrentApplicationUserCondition;
  CurrentApplicationUserFilter: CurrentApplicationUserFilter;
  CurrentApplicationUserInput: CurrentApplicationUserInput;
  CurrentApplicationUsersConnection: CurrentApplicationUsersConnection;
  CurrentApplicationUsersEdge: CurrentApplicationUsersEdge;
  CurrentUserMembership: CurrentUserMembership;
  CurrentUserMembershipCondition: CurrentUserMembershipCondition;
  CurrentUserMembershipFilter: CurrentUserMembershipFilter;
  CurrentUserMembershipInput: CurrentUserMembershipInput;
  CurrentUserMembershipsConnection: CurrentUserMembershipsConnection;
  CurrentUserMembershipsEdge: CurrentUserMembershipsEdge;
  Cursor: Scalars["Cursor"]["output"];
  Dataset: Dataset;
  DatasetCondition: DatasetCondition;
  DatasetDatasetKeyTenantUniqueConnect: DatasetDatasetKeyTenantUniqueConnect;
  DatasetDatasetKeyTenantUniqueDelete: DatasetDatasetKeyTenantUniqueDelete;
  DatasetDatasetPrefixProjectUniqueConnect: DatasetDatasetPrefixProjectUniqueConnect;
  DatasetDatasetPrefixProjectUniqueDelete: DatasetDatasetPrefixProjectUniqueDelete;
  DatasetDatasetV4PkeyConnect: DatasetDatasetV4PkeyConnect;
  DatasetDatasetV4PkeyDelete: DatasetDatasetV4PkeyDelete;
  DatasetDatasetV4RecordingsTableIdKeyConnect: DatasetDatasetV4RecordingsTableIdKeyConnect;
  DatasetDatasetV4RecordingsTableIdKeyDelete: DatasetDatasetV4RecordingsTableIdKeyDelete;
  DatasetDescriptionId4043Bd78FkMetadataV4IdDatasetCreateInput: DatasetDescriptionId4043Bd78FkMetadataV4IdDatasetCreateInput;
  DatasetDescriptionId4043Bd78FkMetadataV4IdInput: DatasetDescriptionId4043Bd78FkMetadataV4IdInput;
  DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput: DatasetDescriptionId4043Bd78FkMetadataV4IdInverseInput;
  DatasetDescriptionId4043Bd78FkMetadataV4IdMetadataCreateInput: DatasetDescriptionId4043Bd78FkMetadataV4IdMetadataCreateInput;
  DatasetFilter: DatasetFilter;
  DatasetInput: DatasetInput;
  DatasetMetadataConnection: DatasetMetadataConnection;
  DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdDatasetCreateInput: DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdDatasetCreateInput;
  DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdDatasetMetadataCreateInput: DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdDatasetMetadataCreateInput;
  DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInput: DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInput;
  DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput: DatasetMetadataDatasetIdC504A1DdFkDatasetV4IdInverseInput;
  DatasetMetadataEdge: DatasetMetadataEdge;
  DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdDatasetMetadataCreateInput: DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdDatasetMetadataCreateInput;
  DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInput: DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInput;
  DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput: DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdInverseInput;
  DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdMetadataCreateInput: DatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdMetadataCreateInput;
  DatasetMetadataProjectIdB921B40CFkProjectIdDatasetMetadataCreateInput: DatasetMetadataProjectIdB921B40CFkProjectIdDatasetMetadataCreateInput;
  DatasetMetadataProjectIdB921B40CFkProjectIdInput: DatasetMetadataProjectIdB921B40CFkProjectIdInput;
  DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput: DatasetMetadataProjectIdB921B40CFkProjectIdInverseInput;
  DatasetMetadataTenantIdD74Eb48BFkTenantIdDatasetMetadataCreateInput: DatasetMetadataTenantIdD74Eb48BFkTenantIdDatasetMetadataCreateInput;
  DatasetMetadataTenantIdD74Eb48BFkTenantIdInput: DatasetMetadataTenantIdD74Eb48BFkTenantIdInput;
  DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput: DatasetMetadataTenantIdD74Eb48BFkTenantIdInverseInput;
  DatasetMetadataTenantIdD74Eb48BFkTenantIdTenantCreateInput: DatasetMetadataTenantIdD74Eb48BFkTenantIdTenantCreateInput;
  DatasetMetadatum: DatasetMetadatum;
  DatasetMetadatumCondition: DatasetMetadatumCondition;
  DatasetMetadatumDatasetMetadataPkeyConnect: DatasetMetadatumDatasetMetadataPkeyConnect;
  DatasetMetadatumDatasetMetadataPkeyDelete: DatasetMetadatumDatasetMetadataPkeyDelete;
  DatasetMetadatumFilter: DatasetMetadatumFilter;
  DatasetMetadatumInput: DatasetMetadatumInput;
  DatasetMetadatumOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetMetadataPkeyUpdate: DatasetMetadatumOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetMetadataPkeyUpdate;
  DatasetMetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdUsingDatasetMetadataPkeyUpdate: DatasetMetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdUsingDatasetMetadataPkeyUpdate;
  DatasetMetadatumOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdUsingDatasetMetadataPkeyUpdate: DatasetMetadatumOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdUsingDatasetMetadataPkeyUpdate;
  DatasetMetadatumOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingDatasetMetadataPkeyUpdate: DatasetMetadatumOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingDatasetMetadataPkeyUpdate;
  DatasetMetadatumPatch: DatasetMetadatumPatch;
  DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetKeyTenantUniqueUpdate: DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetKeyTenantUniqueUpdate;
  DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetPrefixProjectUniqueUpdate: DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetPrefixProjectUniqueUpdate;
  DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetV4PkeyUpdate: DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetV4PkeyUpdate;
  DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetV4RecordingsTableIdKeyUpdate: DatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingDatasetV4RecordingsTableIdKeyUpdate;
  DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetKeyTenantUniqueUpdate: DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetKeyTenantUniqueUpdate;
  DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetPrefixProjectUniqueUpdate: DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetPrefixProjectUniqueUpdate;
  DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetV4PkeyUpdate: DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetV4PkeyUpdate;
  DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetV4RecordingsTableIdKeyUpdate: DatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingDatasetV4RecordingsTableIdKeyUpdate;
  DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetKeyTenantUniqueUpdate: DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetKeyTenantUniqueUpdate;
  DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetPrefixProjectUniqueUpdate: DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetPrefixProjectUniqueUpdate;
  DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetV4PkeyUpdate: DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetV4PkeyUpdate;
  DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetV4RecordingsTableIdKeyUpdate: DatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetV4RecordingsTableIdKeyUpdate;
  DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetKeyTenantUniqueUpdate: DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetKeyTenantUniqueUpdate;
  DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetPrefixProjectUniqueUpdate: DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetPrefixProjectUniqueUpdate;
  DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetV4PkeyUpdate: DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetV4PkeyUpdate;
  DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetV4RecordingsTableIdKeyUpdate: DatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingDatasetV4RecordingsTableIdKeyUpdate;
  DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetKeyTenantUniqueUpdate: DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetKeyTenantUniqueUpdate;
  DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetPrefixProjectUniqueUpdate: DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetPrefixProjectUniqueUpdate;
  DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetV4PkeyUpdate: DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetV4PkeyUpdate;
  DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetV4RecordingsTableIdKeyUpdate: DatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdUsingDatasetV4RecordingsTableIdKeyUpdate;
  DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetKeyTenantUniqueUpdate: DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetKeyTenantUniqueUpdate;
  DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetPrefixProjectUniqueUpdate: DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetPrefixProjectUniqueUpdate;
  DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetV4PkeyUpdate: DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetV4PkeyUpdate;
  DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetV4RecordingsTableIdKeyUpdate: DatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdUsingDatasetV4RecordingsTableIdKeyUpdate;
  DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetKeyTenantUniqueUpdate: DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetKeyTenantUniqueUpdate;
  DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetPrefixProjectUniqueUpdate: DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetPrefixProjectUniqueUpdate;
  DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetV4PkeyUpdate: DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetV4PkeyUpdate;
  DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetV4RecordingsTableIdKeyUpdate: DatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetV4RecordingsTableIdKeyUpdate;
  DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetKeyTenantUniqueUpdate: DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetKeyTenantUniqueUpdate;
  DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetPrefixProjectUniqueUpdate: DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetPrefixProjectUniqueUpdate;
  DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetV4PkeyUpdate: DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetV4PkeyUpdate;
  DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetV4RecordingsTableIdKeyUpdate: DatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingDatasetV4RecordingsTableIdKeyUpdate;
  DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetKeyTenantUniqueUpdate: DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetKeyTenantUniqueUpdate;
  DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetPrefixProjectUniqueUpdate: DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetPrefixProjectUniqueUpdate;
  DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetV4PkeyUpdate: DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetV4PkeyUpdate;
  DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetV4RecordingsTableIdKeyUpdate: DatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingDatasetV4RecordingsTableIdKeyUpdate;
  DatasetPatch: DatasetPatch;
  DatasetRecordingsTColDefId837663C9FkMetadataDatasetRecordingsTableColumnCreateInput: DatasetRecordingsTColDefId837663C9FkMetadataDatasetRecordingsTableColumnCreateInput;
  DatasetRecordingsTColDefId837663C9FkMetadataInput: DatasetRecordingsTColDefId837663C9FkMetadataInput;
  DatasetRecordingsTColDefId837663C9FkMetadataInverseInput: DatasetRecordingsTColDefId837663C9FkMetadataInverseInput;
  DatasetRecordingsTColDefId837663C9FkMetadataMetadataCreateInput: DatasetRecordingsTColDefId837663C9FkMetadataMetadataCreateInput;
  DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRDatasetRecordingsTableColumnCreateInput: DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRDatasetRecordingsTableColumnCreateInput;
  DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRDatasetRecordingsTableColumnOrderCreateInput: DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRDatasetRecordingsTableColumnOrderCreateInput;
  DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInput: DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInput;
  DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput: DatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRInverseInput;
  DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRDatasetRecordingsTableColumnCreateInput: DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRDatasetRecordingsTableColumnCreateInput;
  DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRDatasetRecordingsTableCreateInput: DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRDatasetRecordingsTableCreateInput;
  DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput: DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInput;
  DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInverseInput: DatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRInverseInput;
  DatasetRecordingsTProjectId8E1D93E1FkProjectIDatasetRecordingsTableColumnOrderCreateInput: DatasetRecordingsTProjectId8E1D93E1FkProjectIDatasetRecordingsTableColumnOrderCreateInput;
  DatasetRecordingsTProjectId8E1D93E1FkProjectIInput: DatasetRecordingsTProjectId8E1D93E1FkProjectIInput;
  DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput: DatasetRecordingsTProjectId8E1D93E1FkProjectIInverseInput;
  DatasetRecordingsTProjectId40Dba6D7FkProjectIDatasetRecordingsTableColumnCreateInput: DatasetRecordingsTProjectId40Dba6D7FkProjectIDatasetRecordingsTableColumnCreateInput;
  DatasetRecordingsTProjectId40Dba6D7FkProjectIInput: DatasetRecordingsTProjectId40Dba6D7FkProjectIInput;
  DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput: DatasetRecordingsTProjectId40Dba6D7FkProjectIInverseInput;
  DatasetRecordingsTTenantId43Dd9FaaFkTenantIdDatasetRecordingsTableColumnOrderCreateInput: DatasetRecordingsTTenantId43Dd9FaaFkTenantIdDatasetRecordingsTableColumnOrderCreateInput;
  DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInput: DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInput;
  DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput: DatasetRecordingsTTenantId43Dd9FaaFkTenantIdInverseInput;
  DatasetRecordingsTTenantId43Dd9FaaFkTenantIdTenantCreateInput: DatasetRecordingsTTenantId43Dd9FaaFkTenantIdTenantCreateInput;
  DatasetRecordingsTable: DatasetRecordingsTable;
  DatasetRecordingsTableColumn: DatasetRecordingsTableColumn;
  DatasetRecordingsTableColumnCondition: DatasetRecordingsTableColumnCondition;
  DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyConnect: DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyConnect;
  DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyDelete: DatasetRecordingsTableColumnDatasetRecordingsTableColumnPkeyDelete;
  DatasetRecordingsTableColumnFilter: DatasetRecordingsTableColumnFilter;
  DatasetRecordingsTableColumnInput: DatasetRecordingsTableColumnInput;
  DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataUsingDatasetRecordingsTableColumnPkeyUpdate: DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataUsingDatasetRecordingsTableColumnPkeyUpdate;
  DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRUsingDatasetRecordingsTableColumnPkeyUpdate: DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRUsingDatasetRecordingsTableColumnPkeyUpdate;
  DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIUsingDatasetRecordingsTableColumnPkeyUpdate: DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIUsingDatasetRecordingsTableColumnPkeyUpdate;
  DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingDatasetRecordingsTableColumnPkeyUpdate: DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingDatasetRecordingsTableColumnPkeyUpdate;
  DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRUsingDatasetRecordingsTableColumnPkeyUpdate: DatasetRecordingsTableColumnOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRUsingDatasetRecordingsTableColumnPkeyUpdate;
  DatasetRecordingsTableColumnOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRUsingDatasetRecordingsTableColumnPkeyUpdate: DatasetRecordingsTableColumnOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRUsingDatasetRecordingsTableColumnPkeyUpdate;
  DatasetRecordingsTableColumnOrder: DatasetRecordingsTableColumnOrder;
  DatasetRecordingsTableColumnOrderCondition: DatasetRecordingsTableColumnOrderCondition;
  DatasetRecordingsTableColumnOrderDatasetRecordingsTableColumnOrderPkeyConnect: DatasetRecordingsTableColumnOrderDatasetRecordingsTableColumnOrderPkeyConnect;
  DatasetRecordingsTableColumnOrderDatasetRecordingsTableColumnOrderPkeyDelete: DatasetRecordingsTableColumnOrderDatasetRecordingsTableColumnOrderPkeyDelete;
  DatasetRecordingsTableColumnOrderFilter: DatasetRecordingsTableColumnOrderFilter;
  DatasetRecordingsTableColumnOrderInput: DatasetRecordingsTableColumnOrderInput;
  DatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRUsingDatasetRecordingsTableColumnOrderPkeyUpdate: DatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRUsingDatasetRecordingsTableColumnOrderPkeyUpdate;
  DatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIUsingDatasetRecordingsTableColumnOrderPkeyUpdate: DatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIUsingDatasetRecordingsTableColumnOrderPkeyUpdate;
  DatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingDatasetRecordingsTableColumnOrderPkeyUpdate: DatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingDatasetRecordingsTableColumnOrderPkeyUpdate;
  DatasetRecordingsTableColumnOrderPatch: DatasetRecordingsTableColumnOrderPatch;
  DatasetRecordingsTableColumnOrdersConnection: DatasetRecordingsTableColumnOrdersConnection;
  DatasetRecordingsTableColumnOrdersEdge: DatasetRecordingsTableColumnOrdersEdge;
  DatasetRecordingsTableColumnPatch: DatasetRecordingsTableColumnPatch;
  DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdDatasetRecordingsTableColumnCreateInput: DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdDatasetRecordingsTableColumnCreateInput;
  DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput: DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInput;
  DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput: DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdInverseInput;
  DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdTenantCreateInput: DatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdTenantCreateInput;
  DatasetRecordingsTableColumnToManyDatasetRecordingsTableColumnOrderFilter: DatasetRecordingsTableColumnToManyDatasetRecordingsTableColumnOrderFilter;
  DatasetRecordingsTableColumnToManyRecordingGroupFileFilter: DatasetRecordingsTableColumnToManyRecordingGroupFileFilter;
  DatasetRecordingsTableColumnsConnection: DatasetRecordingsTableColumnsConnection;
  DatasetRecordingsTableColumnsEdge: DatasetRecordingsTableColumnsEdge;
  DatasetRecordingsTableCondition: DatasetRecordingsTableCondition;
  DatasetRecordingsTableDatasetRecordingsTablePkeyConnect: DatasetRecordingsTableDatasetRecordingsTablePkeyConnect;
  DatasetRecordingsTableDatasetRecordingsTablePkeyDelete: DatasetRecordingsTableDatasetRecordingsTablePkeyDelete;
  DatasetRecordingsTableFilter: DatasetRecordingsTableFilter;
  DatasetRecordingsTableInput: DatasetRecordingsTableInput;
  DatasetRecordingsTableOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetRecordingsTablePkeyUpdate: DatasetRecordingsTableOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRUsingDatasetRecordingsTablePkeyUpdate;
  DatasetRecordingsTableOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRUsingDatasetRecordingsTablePkeyUpdate: DatasetRecordingsTableOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRUsingDatasetRecordingsTablePkeyUpdate;
  DatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdUsingDatasetRecordingsTablePkeyUpdate: DatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdUsingDatasetRecordingsTablePkeyUpdate;
  DatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingDatasetRecordingsTablePkeyUpdate: DatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingDatasetRecordingsTablePkeyUpdate;
  DatasetRecordingsTableOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTB91F3991FkDatasetRUsingDatasetRecordingsTablePkeyUpdate: DatasetRecordingsTableOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRUsingDatasetRecordingsTablePkeyUpdate;
  DatasetRecordingsTablePatch: DatasetRecordingsTablePatch;
  DatasetRecordingsTableProjectId710D9Eb2FkProjectIdDatasetRecordingsTableCreateInput: DatasetRecordingsTableProjectId710D9Eb2FkProjectIdDatasetRecordingsTableCreateInput;
  DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInput: DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInput;
  DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput: DatasetRecordingsTableProjectId710D9Eb2FkProjectIdInverseInput;
  DatasetRecordingsTableTenantId94Ac7342FkTenantIdDatasetRecordingsTableCreateInput: DatasetRecordingsTableTenantId94Ac7342FkTenantIdDatasetRecordingsTableCreateInput;
  DatasetRecordingsTableTenantId94Ac7342FkTenantIdInput: DatasetRecordingsTableTenantId94Ac7342FkTenantIdInput;
  DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput: DatasetRecordingsTableTenantId94Ac7342FkTenantIdInverseInput;
  DatasetRecordingsTableTenantId94Ac7342FkTenantIdTenantCreateInput: DatasetRecordingsTableTenantId94Ac7342FkTenantIdTenantCreateInput;
  DatasetRecordingsTableToManyDatasetRecordingsTableColumnFilter: DatasetRecordingsTableToManyDatasetRecordingsTableColumnFilter;
  DatasetRecordingsTableToManyFileRecordingGroupFilter: DatasetRecordingsTableToManyFileRecordingGroupFilter;
  DatasetRecordingsTablesConnection: DatasetRecordingsTablesConnection;
  DatasetRecordingsTablesEdge: DatasetRecordingsTablesEdge;
  DatasetToManyDatasetMetadatumFilter: DatasetToManyDatasetMetadatumFilter;
  DatasetToManyDatasetVersionFilter: DatasetToManyDatasetVersionFilter;
  DatasetToManyFileFilter: DatasetToManyFileFilter;
  DatasetToManyTaskFilter: DatasetToManyTaskFilter;
  DatasetV4ProjectId98907276FkProjectIdDatasetCreateInput: DatasetV4ProjectId98907276FkProjectIdDatasetCreateInput;
  DatasetV4ProjectId98907276FkProjectIdInput: DatasetV4ProjectId98907276FkProjectIdInput;
  DatasetV4ProjectId98907276FkProjectIdInverseInput: DatasetV4ProjectId98907276FkProjectIdInverseInput;
  DatasetV4RecordingsTableIdD72E44AeFkDatasetRDatasetCreateInput: DatasetV4RecordingsTableIdD72E44AeFkDatasetRDatasetCreateInput;
  DatasetV4RecordingsTableIdD72E44AeFkDatasetRDatasetRecordingsTableCreateInput: DatasetV4RecordingsTableIdD72E44AeFkDatasetRDatasetRecordingsTableCreateInput;
  DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput: DatasetV4RecordingsTableIdD72E44AeFkDatasetRInput;
  DatasetV4RecordingsTableIdD72E44AeFkDatasetRInverseInput: DatasetV4RecordingsTableIdD72E44AeFkDatasetRInverseInput;
  DatasetV4TenantId7055D1F4FkTenantIdDatasetCreateInput: DatasetV4TenantId7055D1F4FkTenantIdDatasetCreateInput;
  DatasetV4TenantId7055D1F4FkTenantIdInput: DatasetV4TenantId7055D1F4FkTenantIdInput;
  DatasetV4TenantId7055D1F4FkTenantIdInverseInput: DatasetV4TenantId7055D1F4FkTenantIdInverseInput;
  DatasetV4TenantId7055D1F4FkTenantIdTenantCreateInput: DatasetV4TenantId7055D1F4FkTenantIdTenantCreateInput;
  DatasetV4UserId5B9A2303FkApplicationUserIdApplicationUserCreateInput: DatasetV4UserId5B9A2303FkApplicationUserIdApplicationUserCreateInput;
  DatasetV4UserId5B9A2303FkApplicationUserIdDatasetCreateInput: DatasetV4UserId5B9A2303FkApplicationUserIdDatasetCreateInput;
  DatasetV4UserId5B9A2303FkApplicationUserIdInput: DatasetV4UserId5B9A2303FkApplicationUserIdInput;
  DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput: DatasetV4UserId5B9A2303FkApplicationUserIdInverseInput;
  DatasetVersion: DatasetVersion;
  DatasetVersionCondition: DatasetVersionCondition;
  DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdDatasetCreateInput: DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdDatasetCreateInput;
  DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdDatasetVersionCreateInput: DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdDatasetVersionCreateInput;
  DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInput: DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInput;
  DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput: DatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdInverseInput;
  DatasetVersionDatasetVersionPkeyConnect: DatasetVersionDatasetVersionPkeyConnect;
  DatasetVersionDatasetVersionPkeyDelete: DatasetVersionDatasetVersionPkeyDelete;
  DatasetVersionFilter: DatasetVersionFilter;
  DatasetVersionInput: DatasetVersionInput;
  DatasetVersionOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetVersionPkeyUpdate: DatasetVersionOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdUsingDatasetVersionPkeyUpdate;
  DatasetVersionOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdUsingDatasetVersionPkeyUpdate: DatasetVersionOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdUsingDatasetVersionPkeyUpdate;
  DatasetVersionOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingDatasetVersionPkeyUpdate: DatasetVersionOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingDatasetVersionPkeyUpdate;
  DatasetVersionPatch: DatasetVersionPatch;
  DatasetVersionProjectId9E468C2EFkProjectIdDatasetVersionCreateInput: DatasetVersionProjectId9E468C2EFkProjectIdDatasetVersionCreateInput;
  DatasetVersionProjectId9E468C2EFkProjectIdInput: DatasetVersionProjectId9E468C2EFkProjectIdInput;
  DatasetVersionProjectId9E468C2EFkProjectIdInverseInput: DatasetVersionProjectId9E468C2EFkProjectIdInverseInput;
  DatasetVersionTenantId7Eb1908AFkTenantIdDatasetVersionCreateInput: DatasetVersionTenantId7Eb1908AFkTenantIdDatasetVersionCreateInput;
  DatasetVersionTenantId7Eb1908AFkTenantIdInput: DatasetVersionTenantId7Eb1908AFkTenantIdInput;
  DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput: DatasetVersionTenantId7Eb1908AFkTenantIdInverseInput;
  DatasetVersionTenantId7Eb1908AFkTenantIdTenantCreateInput: DatasetVersionTenantId7Eb1908AFkTenantIdTenantCreateInput;
  DatasetVersionsConnection: DatasetVersionsConnection;
  DatasetVersionsEdge: DatasetVersionsEdge;
  DatasetsConnection: DatasetsConnection;
  DatasetsEdge: DatasetsEdge;
  Date: Scalars["Date"]["output"];
  DateFilter: DateFilter;
  Datetime: Scalars["Datetime"]["output"];
  DatetimeFilter: DatetimeFilter;
  DeleteAnalysisTableByIdInput: DeleteAnalysisTableByIdInput;
  DeleteAnalysisTableGroupByIdInput: DeleteAnalysisTableGroupByIdInput;
  DeleteAnalysisTableGroupPayload: DeleteAnalysisTableGroupPayload;
  DeleteAnalysisTablePayload: DeleteAnalysisTablePayload;
  DeleteAnalysisTableRowAttachResultByIdInput: DeleteAnalysisTableRowAttachResultByIdInput;
  DeleteAnalysisTableRowAttachResultPayload: DeleteAnalysisTableRowAttachResultPayload;
  DeleteAnalysisTableRowByIdInput: DeleteAnalysisTableRowByIdInput;
  DeleteAnalysisTableRowByTaskIdInput: DeleteAnalysisTableRowByTaskIdInput;
  DeleteAnalysisTableRowPayload: DeleteAnalysisTableRowPayload;
  DeleteApplicationUserByEmailInput: DeleteApplicationUserByEmailInput;
  DeleteApplicationUserByIdInput: DeleteApplicationUserByIdInput;
  DeleteApplicationUserByUsernameInput: DeleteApplicationUserByUsernameInput;
  DeleteApplicationUserGroupByIdInput: DeleteApplicationUserGroupByIdInput;
  DeleteApplicationUserGroupByUserIdAndGroupIdInput: DeleteApplicationUserGroupByUserIdAndGroupIdInput;
  DeleteApplicationUserGroupPayload: DeleteApplicationUserGroupPayload;
  DeleteApplicationUserMembershipByIdInput: DeleteApplicationUserMembershipByIdInput;
  DeleteApplicationUserMembershipByUserIdAndTenantIdInput: DeleteApplicationUserMembershipByUserIdAndTenantIdInput;
  DeleteApplicationUserMembershipPayload: DeleteApplicationUserMembershipPayload;
  DeleteApplicationUserMetadatumByIdInput: DeleteApplicationUserMetadatumByIdInput;
  DeleteApplicationUserMetadatumByUserIdAndKeyInput: DeleteApplicationUserMetadatumByUserIdAndKeyInput;
  DeleteApplicationUserMetadatumPayload: DeleteApplicationUserMetadatumPayload;
  DeleteApplicationUserPayload: DeleteApplicationUserPayload;
  DeleteApplicationUserSessionByIdInput: DeleteApplicationUserSessionByIdInput;
  DeleteApplicationUserSessionPayload: DeleteApplicationUserSessionPayload;
  DeleteApplicationUserUserPermissionByIdInput: DeleteApplicationUserUserPermissionByIdInput;
  DeleteApplicationUserUserPermissionByUserIdAndPermissionIdInput: DeleteApplicationUserUserPermissionByUserIdAndPermissionIdInput;
  DeleteApplicationUserUserPermissionPayload: DeleteApplicationUserUserPermissionPayload;
  DeleteAuthGroupByIdInput: DeleteAuthGroupByIdInput;
  DeleteAuthGroupByNameInput: DeleteAuthGroupByNameInput;
  DeleteAuthGroupPayload: DeleteAuthGroupPayload;
  DeleteAuthGroupPermissionByGroupIdAndPermissionIdInput: DeleteAuthGroupPermissionByGroupIdAndPermissionIdInput;
  DeleteAuthGroupPermissionByIdInput: DeleteAuthGroupPermissionByIdInput;
  DeleteAuthGroupPermissionPayload: DeleteAuthGroupPermissionPayload;
  DeleteAuthPermissionByContentTypeIdAndCodenameInput: DeleteAuthPermissionByContentTypeIdAndCodenameInput;
  DeleteAuthPermissionByIdInput: DeleteAuthPermissionByIdInput;
  DeleteAuthPermissionPayload: DeleteAuthPermissionPayload;
  DeleteAvatarByIdInput: DeleteAvatarByIdInput;
  DeleteAvatarByUserIdInput: DeleteAvatarByUserIdInput;
  DeleteAvatarPayload: DeleteAvatarPayload;
  DeleteAwsBatchTaskByTaskexecutorPtrIdInput: DeleteAwsBatchTaskByTaskexecutorPtrIdInput;
  DeleteAwsBatchTaskPayload: DeleteAwsBatchTaskPayload;
  DeleteAwsCognitoUserByUserexecutorPtrIdInput: DeleteAwsCognitoUserByUserexecutorPtrIdInput;
  DeleteAwsCognitoUserPayload: DeleteAwsCognitoUserPayload;
  DeleteAwsEcrImageByContainerimageexecutorPtrIdInput: DeleteAwsEcrImageByContainerimageexecutorPtrIdInput;
  DeleteAwsEcrImagePayload: DeleteAwsEcrImagePayload;
  DeleteAwsMockBatchTaskByAwstaskexecutorPtrIdInput: DeleteAwsMockBatchTaskByAwstaskexecutorPtrIdInput;
  DeleteAwsMockBatchTaskPayload: DeleteAwsMockBatchTaskPayload;
  DeleteAwsMockEcrImageByAwscontainerimageexecutorPtrIdInput: DeleteAwsMockEcrImageByAwscontainerimageexecutorPtrIdInput;
  DeleteAwsMockEcrImagePayload: DeleteAwsMockEcrImagePayload;
  DeleteAwsMockS3FileByAwsfileexecutorPtrIdInput: DeleteAwsMockS3FileByAwsfileexecutorPtrIdInput;
  DeleteAwsMockS3FilePayload: DeleteAwsMockS3FilePayload;
  DeleteAwsS3FileByFileexecutorPtrIdInput: DeleteAwsS3FileByFileexecutorPtrIdInput;
  DeleteAwsS3FilePayload: DeleteAwsS3FilePayload;
  DeleteContainerImageByExecutorIdInput: DeleteContainerImageByExecutorIdInput;
  DeleteContainerImageByIdInput: DeleteContainerImageByIdInput;
  DeleteContainerImageByNameAndTagInput: DeleteContainerImageByNameAndTagInput;
  DeleteContainerImageByRepositoryAndTagInput: DeleteContainerImageByRepositoryAndTagInput;
  DeleteContainerImageByToolboxVersionIdInput: DeleteContainerImageByToolboxVersionIdInput;
  DeleteContainerImagePayload: DeleteContainerImagePayload;
  DeleteCostByIdInput: DeleteCostByIdInput;
  DeleteCostPayload: DeleteCostPayload;
  DeleteDatasetByIdInput: DeleteDatasetByIdInput;
  DeleteDatasetByProjectIdAndPrefixInput: DeleteDatasetByProjectIdAndPrefixInput;
  DeleteDatasetByRecordingsTableIdInput: DeleteDatasetByRecordingsTableIdInput;
  DeleteDatasetByTenantIdAndKeyInput: DeleteDatasetByTenantIdAndKeyInput;
  DeleteDatasetMetadatumByIdInput: DeleteDatasetMetadatumByIdInput;
  DeleteDatasetMetadatumPayload: DeleteDatasetMetadatumPayload;
  DeleteDatasetPayload: DeleteDatasetPayload;
  DeleteDatasetRecordingsTableByIdInput: DeleteDatasetRecordingsTableByIdInput;
  DeleteDatasetRecordingsTableColumnByIdInput: DeleteDatasetRecordingsTableColumnByIdInput;
  DeleteDatasetRecordingsTableColumnOrderByIdInput: DeleteDatasetRecordingsTableColumnOrderByIdInput;
  DeleteDatasetRecordingsTableColumnOrderPayload: DeleteDatasetRecordingsTableColumnOrderPayload;
  DeleteDatasetRecordingsTableColumnPayload: DeleteDatasetRecordingsTableColumnPayload;
  DeleteDatasetRecordingsTablePayload: DeleteDatasetRecordingsTablePayload;
  DeleteDatasetVersionByIdInput: DeleteDatasetVersionByIdInput;
  DeleteDatasetVersionPayload: DeleteDatasetVersionPayload;
  DeleteDjangoAdminLogByIdInput: DeleteDjangoAdminLogByIdInput;
  DeleteDjangoAdminLogPayload: DeleteDjangoAdminLogPayload;
  DeleteDjangoContentTypeByAppLabelAndModelInput: DeleteDjangoContentTypeByAppLabelAndModelInput;
  DeleteDjangoContentTypeByIdInput: DeleteDjangoContentTypeByIdInput;
  DeleteDjangoContentTypePayload: DeleteDjangoContentTypePayload;
  DeleteDjangoMigrationByIdInput: DeleteDjangoMigrationByIdInput;
  DeleteDjangoMigrationPayload: DeleteDjangoMigrationPayload;
  DeleteDjangoSesSesstatByDateInput: DeleteDjangoSesSesstatByDateInput;
  DeleteDjangoSesSesstatByIdInput: DeleteDjangoSesSesstatByIdInput;
  DeleteDjangoSesSesstatPayload: DeleteDjangoSesSesstatPayload;
  DeleteDjangoSessionBySessionKeyInput: DeleteDjangoSessionBySessionKeyInput;
  DeleteDjangoSessionPayload: DeleteDjangoSessionPayload;
  DeleteDrsFileexecutorByIdInput: DeleteDrsFileexecutorByIdInput;
  DeleteDrsFileexecutorPayload: DeleteDrsFileexecutorPayload;
  DeleteDrsMockfileexecutorByFileexecutorPtrIdInput: DeleteDrsMockfileexecutorByFileexecutorPtrIdInput;
  DeleteDrsMockfileexecutorPayload: DeleteDrsMockfileexecutorPayload;
  DeleteEulaVersionByIdInput: DeleteEulaVersionByIdInput;
  DeleteEulaVersionByVersionInput: DeleteEulaVersionByVersionInput;
  DeleteEulaVersionPayload: DeleteEulaVersionPayload;
  DeleteEulaVersionUserAcceptanceByIdInput: DeleteEulaVersionUserAcceptanceByIdInput;
  DeleteEulaVersionUserAcceptanceByUserIdAndEulaVersionIdInput: DeleteEulaVersionUserAcceptanceByUserIdAndEulaVersionIdInput;
  DeleteEulaVersionUserAcceptancePayload: DeleteEulaVersionUserAcceptancePayload;
  DeleteExplorerQueryByIdInput: DeleteExplorerQueryByIdInput;
  DeleteExplorerQueryPayload: DeleteExplorerQueryPayload;
  DeleteExplorerQueryfavoriteByIdInput: DeleteExplorerQueryfavoriteByIdInput;
  DeleteExplorerQueryfavoriteByQueryIdAndUserIdInput: DeleteExplorerQueryfavoriteByQueryIdAndUserIdInput;
  DeleteExplorerQueryfavoritePayload: DeleteExplorerQueryfavoritePayload;
  DeleteExplorerQuerylogByIdInput: DeleteExplorerQuerylogByIdInput;
  DeleteExplorerQuerylogPayload: DeleteExplorerQuerylogPayload;
  DeleteFileActivityByIdInput: DeleteFileActivityByIdInput;
  DeleteFileActivityPayload: DeleteFileActivityPayload;
  DeleteFileByIdInput: DeleteFileByIdInput;
  DeleteFileMetadatumByIdInput: DeleteFileMetadatumByIdInput;
  DeleteFileMetadatumPayload: DeleteFileMetadatumPayload;
  DeleteFileOutputGroupByIdInput: DeleteFileOutputGroupByIdInput;
  DeleteFileOutputGroupByTaskIdInput: DeleteFileOutputGroupByTaskIdInput;
  DeleteFileOutputGroupMetadatumByIdInput: DeleteFileOutputGroupMetadatumByIdInput;
  DeleteFileOutputGroupMetadatumPayload: DeleteFileOutputGroupMetadatumPayload;
  DeleteFileOutputGroupPayload: DeleteFileOutputGroupPayload;
  DeleteFilePayload: DeleteFilePayload;
  DeleteFileRecordingGroupByIdInput: DeleteFileRecordingGroupByIdInput;
  DeleteFileRecordingGroupMetadatumByIdInput: DeleteFileRecordingGroupMetadatumByIdInput;
  DeleteFileRecordingGroupMetadatumPayload: DeleteFileRecordingGroupMetadatumPayload;
  DeleteFileRecordingGroupPayload: DeleteFileRecordingGroupPayload;
  DeleteMetadataValueByIdInput: DeleteMetadataValueByIdInput;
  DeleteMetadataValuePayload: DeleteMetadataValuePayload;
  DeleteMetadatumByIdInput: DeleteMetadatumByIdInput;
  DeleteMetadatumPayload: DeleteMetadatumPayload;
  DeleteOutputGroupFileByIdInput: DeleteOutputGroupFileByIdInput;
  DeleteOutputGroupFilePayload: DeleteOutputGroupFilePayload;
  DeleteRecordingGroupFileByIdInput: DeleteRecordingGroupFileByIdInput;
  DeleteRecordingGroupFilePayload: DeleteRecordingGroupFilePayload;
  DeleteRunByIdInput: DeleteRunByIdInput;
  DeleteRunPayload: DeleteRunPayload;
  DeleteSessionInput: DeleteSessionInput;
  DeleteSessionPayload: DeleteSessionPayload;
  DeleteTagByIdInput: DeleteTagByIdInput;
  DeleteTagPayload: DeleteTagPayload;
  DeleteTaskActivityByIdInput: DeleteTaskActivityByIdInput;
  DeleteTaskActivityByTaskIdInput: DeleteTaskActivityByTaskIdInput;
  DeleteTaskActivityPayload: DeleteTaskActivityPayload;
  DeleteTaskByExecutorIdInput: DeleteTaskByExecutorIdInput;
  DeleteTaskByIdInput: DeleteTaskByIdInput;
  DeleteTaskPayload: DeleteTaskPayload;
  DeleteTaskResourceByIdInput: DeleteTaskResourceByIdInput;
  DeleteTaskResourcePayload: DeleteTaskResourcePayload;
  DeleteTaskSourceByIdInput: DeleteTaskSourceByIdInput;
  DeleteTaskSourcePayload: DeleteTaskSourcePayload;
  DeleteTaskStatusLogByIdInput: DeleteTaskStatusLogByIdInput;
  DeleteTaskStatusLogByTaskIdAndStatusInput: DeleteTaskStatusLogByTaskIdAndStatusInput;
  DeleteTaskStatusLogPayload: DeleteTaskStatusLogPayload;
  DeleteTenantByIdInput: DeleteTenantByIdInput;
  DeleteTenantByKeyInput: DeleteTenantByKeyInput;
  DeleteTenantByNameInput: DeleteTenantByNameInput;
  DeleteTenantContractAllowanceByIdInput: DeleteTenantContractAllowanceByIdInput;
  DeleteTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeInput: DeleteTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeInput;
  DeleteTenantContractAllowancePayload: DeleteTenantContractAllowancePayload;
  DeleteTenantContractByIdInput: DeleteTenantContractByIdInput;
  DeleteTenantContractByNameInput: DeleteTenantContractByNameInput;
  DeleteTenantContractPayload: DeleteTenantContractPayload;
  DeleteTenantContractTransactionByIdInput: DeleteTenantContractTransactionByIdInput;
  DeleteTenantContractTransactionPayload: DeleteTenantContractTransactionPayload;
  DeleteTenantMockuserexecutorByUserexecutorPtrIdInput: DeleteTenantMockuserexecutorByUserexecutorPtrIdInput;
  DeleteTenantMockuserexecutorPayload: DeleteTenantMockuserexecutorPayload;
  DeleteTenantMonthlyConsumedResourceByIdInput: DeleteTenantMonthlyConsumedResourceByIdInput;
  DeleteTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractInput: DeleteTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractInput;
  DeleteTenantMonthlyConsumedResourcePayload: DeleteTenantMonthlyConsumedResourcePayload;
  DeleteTenantPayload: DeleteTenantPayload;
  DeleteTenantUserexecutorByIdInput: DeleteTenantUserexecutorByIdInput;
  DeleteTenantUserexecutorPayload: DeleteTenantUserexecutorPayload;
  DeleteTesContainerimageexecutorByIdInput: DeleteTesContainerimageexecutorByIdInput;
  DeleteTesContainerimageexecutorPayload: DeleteTesContainerimageexecutorPayload;
  DeleteTesMockcontainerimageexecutorByContainerimageexecutorPtrIdInput: DeleteTesMockcontainerimageexecutorByContainerimageexecutorPtrIdInput;
  DeleteTesMockcontainerimageexecutorPayload: DeleteTesMockcontainerimageexecutorPayload;
  DeleteTesMocktaskexecutorByTaskexecutorPtrIdInput: DeleteTesMocktaskexecutorByTaskexecutorPtrIdInput;
  DeleteTesMocktaskexecutorPayload: DeleteTesMocktaskexecutorPayload;
  DeleteTesTaskexecutorByIdInput: DeleteTesTaskexecutorByIdInput;
  DeleteTesTaskexecutorPayload: DeleteTesTaskexecutorPayload;
  DeleteToolByIdInput: DeleteToolByIdInput;
  DeleteToolByKeyInput: DeleteToolByKeyInput;
  DeleteToolCategoryByIdInput: DeleteToolCategoryByIdInput;
  DeleteToolCategoryByKeyInput: DeleteToolCategoryByKeyInput;
  DeleteToolCategoryPayload: DeleteToolCategoryPayload;
  DeleteToolDisplayCategoryByIdInput: DeleteToolDisplayCategoryByIdInput;
  DeleteToolDisplayCategoryByToolIdAndToolcategoryIdInput: DeleteToolDisplayCategoryByToolIdAndToolcategoryIdInput;
  DeleteToolDisplayCategoryPayload: DeleteToolDisplayCategoryPayload;
  DeleteToolParameterByIdInput: DeleteToolParameterByIdInput;
  DeleteToolParameterPayload: DeleteToolParameterPayload;
  DeleteToolPayload: DeleteToolPayload;
  DeleteToolResourceByIdInput: DeleteToolResourceByIdInput;
  DeleteToolResourceByTypeAndToolVersionIdInput: DeleteToolResourceByTypeAndToolVersionIdInput;
  DeleteToolResourcePayload: DeleteToolResourcePayload;
  DeleteToolResultByIdInput: DeleteToolResultByIdInput;
  DeleteToolResultPayload: DeleteToolResultPayload;
  DeleteToolSecretByIdInput: DeleteToolSecretByIdInput;
  DeleteToolSecretPayload: DeleteToolSecretPayload;
  DeleteToolSourceByIdInput: DeleteToolSourceByIdInput;
  DeleteToolSourcePayload: DeleteToolSourcePayload;
  DeleteToolTenantByIdInput: DeleteToolTenantByIdInput;
  DeleteToolTenantByToolIdAndTenantIdInput: DeleteToolTenantByToolIdAndTenantIdInput;
  DeleteToolTenantPayload: DeleteToolTenantPayload;
  DeleteToolVersionByIdInput: DeleteToolVersionByIdInput;
  DeleteToolVersionByToolIdAndVersionInput: DeleteToolVersionByToolIdAndVersionInput;
  DeleteToolVersionPayload: DeleteToolVersionPayload;
  DeleteToolboxByIdInput: DeleteToolboxByIdInput;
  DeleteToolboxByKeyInput: DeleteToolboxByKeyInput;
  DeleteToolboxExitcodeByCodeInput: DeleteToolboxExitcodeByCodeInput;
  DeleteToolboxExitcodeByIdInput: DeleteToolboxExitcodeByIdInput;
  DeleteToolboxExitcodePayload: DeleteToolboxExitcodePayload;
  DeleteToolboxPayload: DeleteToolboxPayload;
  DeleteToolboxTenantByIdInput: DeleteToolboxTenantByIdInput;
  DeleteToolboxTenantByToolboxIdAndTenantIdInput: DeleteToolboxTenantByToolboxIdAndTenantIdInput;
  DeleteToolboxTenantPayload: DeleteToolboxTenantPayload;
  DeleteToolboxVersionByIdInput: DeleteToolboxVersionByIdInput;
  DeleteToolboxVersionByToolboxIdAndVersionInput: DeleteToolboxVersionByToolboxIdAndVersionInput;
  DeleteToolboxVersionExitcodeByIdInput: DeleteToolboxVersionExitcodeByIdInput;
  DeleteToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdInput: DeleteToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdInput;
  DeleteToolboxVersionExitcodePayload: DeleteToolboxVersionExitcodePayload;
  DeleteToolboxVersionPayload: DeleteToolboxVersionPayload;
  DeleteToolboxVersionToolVersionByIdInput: DeleteToolboxVersionToolVersionByIdInput;
  DeleteToolboxVersionToolVersionByToolboxversionIdAndToolversionIdInput: DeleteToolboxVersionToolVersionByToolboxversionIdAndToolversionIdInput;
  DeleteToolboxVersionToolVersionPayload: DeleteToolboxVersionToolVersionPayload;
  DeleteWorkflowByIdInput: DeleteWorkflowByIdInput;
  DeleteWorkflowDatumByIdInput: DeleteWorkflowDatumByIdInput;
  DeleteWorkflowDatumPayload: DeleteWorkflowDatumPayload;
  DeleteWorkflowPayload: DeleteWorkflowPayload;
  DeleteWorkflowResultByIdInput: DeleteWorkflowResultByIdInput;
  DeleteWorkflowResultPayload: DeleteWorkflowResultPayload;
  DeleteWorkflowResultTagByIdInput: DeleteWorkflowResultTagByIdInput;
  DeleteWorkflowResultTagByResultIdAndTagIdInput: DeleteWorkflowResultTagByResultIdAndTagIdInput;
  DeleteWorkflowResultTagPayload: DeleteWorkflowResultTagPayload;
  DeleteWorkflowResultWorkflowVersionByIdInput: DeleteWorkflowResultWorkflowVersionByIdInput;
  DeleteWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdInput: DeleteWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdInput;
  DeleteWorkflowResultWorkflowVersionPayload: DeleteWorkflowResultWorkflowVersionPayload;
  DeleteWorkflowSectionBlockByIdInput: DeleteWorkflowSectionBlockByIdInput;
  DeleteWorkflowSectionBlockPayload: DeleteWorkflowSectionBlockPayload;
  DeleteWorkflowSectionBlockSectionByBlockIdAndSectionIdInput: DeleteWorkflowSectionBlockSectionByBlockIdAndSectionIdInput;
  DeleteWorkflowSectionBlockSectionByIdInput: DeleteWorkflowSectionBlockSectionByIdInput;
  DeleteWorkflowSectionBlockSectionPayload: DeleteWorkflowSectionBlockSectionPayload;
  DeleteWorkflowSectionByIdInput: DeleteWorkflowSectionByIdInput;
  DeleteWorkflowSectionPayload: DeleteWorkflowSectionPayload;
  DeleteWorkflowSectionWorkflowVersionByIdInput: DeleteWorkflowSectionWorkflowVersionByIdInput;
  DeleteWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdInput: DeleteWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdInput;
  DeleteWorkflowSectionWorkflowVersionPayload: DeleteWorkflowSectionWorkflowVersionPayload;
  DeleteWorkflowSourceByIdInput: DeleteWorkflowSourceByIdInput;
  DeleteWorkflowSourcePayload: DeleteWorkflowSourcePayload;
  DeleteWorkflowSourceTagByIdInput: DeleteWorkflowSourceTagByIdInput;
  DeleteWorkflowSourceTagBySourceIdAndTagIdInput: DeleteWorkflowSourceTagBySourceIdAndTagIdInput;
  DeleteWorkflowSourceTagPayload: DeleteWorkflowSourceTagPayload;
  DeleteWorkflowSourceWorkflowVersionByIdInput: DeleteWorkflowSourceWorkflowVersionByIdInput;
  DeleteWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdInput: DeleteWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdInput;
  DeleteWorkflowSourceWorkflowVersionPayload: DeleteWorkflowSourceWorkflowVersionPayload;
  DeleteWorkflowVersionByIdInput: DeleteWorkflowVersionByIdInput;
  DeleteWorkflowVersionPayload: DeleteWorkflowVersionPayload;
  DjangoAdminLog: DjangoAdminLog;
  DjangoAdminLogCondition: DjangoAdminLogCondition;
  DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoDjangoAdminLogCreateInput: DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoDjangoAdminLogCreateInput;
  DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoDjangoContentTypeCreateInput: DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoDjangoContentTypeCreateInput;
  DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInput: DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInput;
  DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput: DjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoInverseInput;
  DjangoAdminLogDjangoAdminLogPkeyConnect: DjangoAdminLogDjangoAdminLogPkeyConnect;
  DjangoAdminLogDjangoAdminLogPkeyDelete: DjangoAdminLogDjangoAdminLogPkeyDelete;
  DjangoAdminLogFilter: DjangoAdminLogFilter;
  DjangoAdminLogInput: DjangoAdminLogInput;
  DjangoAdminLogOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoUsingDjangoAdminLogPkeyUpdate: DjangoAdminLogOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoUsingDjangoAdminLogPkeyUpdate;
  DjangoAdminLogOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingDjangoAdminLogPkeyUpdate: DjangoAdminLogOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdUsingDjangoAdminLogPkeyUpdate;
  DjangoAdminLogPatch: DjangoAdminLogPatch;
  DjangoAdminLogUserIdC564Eba6FkApplicationUserIdApplicationUserCreateInput: DjangoAdminLogUserIdC564Eba6FkApplicationUserIdApplicationUserCreateInput;
  DjangoAdminLogUserIdC564Eba6FkApplicationUserIdDjangoAdminLogCreateInput: DjangoAdminLogUserIdC564Eba6FkApplicationUserIdDjangoAdminLogCreateInput;
  DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInput: DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInput;
  DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput: DjangoAdminLogUserIdC564Eba6FkApplicationUserIdInverseInput;
  DjangoAdminLogsConnection: DjangoAdminLogsConnection;
  DjangoAdminLogsEdge: DjangoAdminLogsEdge;
  DjangoContentType: DjangoContentType;
  DjangoContentTypeCondition: DjangoContentTypeCondition;
  DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqConnect: DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqConnect;
  DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqDelete: DjangoContentTypeDjangoContentTypeAppLabelModel76Bd3D3BUniqDelete;
  DjangoContentTypeDjangoContentTypePkeyConnect: DjangoContentTypeDjangoContentTypePkeyConnect;
  DjangoContentTypeDjangoContentTypePkeyDelete: DjangoContentTypeDjangoContentTypePkeyDelete;
  DjangoContentTypeFilter: DjangoContentTypeFilter;
  DjangoContentTypeInput: DjangoContentTypeInput;
  DjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate: DjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate;
  DjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingDjangoContentTypePkeyUpdate: DjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoUsingDjangoContentTypePkeyUpdate;
  DjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate: DjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate;
  DjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoUsingDjangoContentTypePkeyUpdate: DjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoUsingDjangoContentTypePkeyUpdate;
  DjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate: DjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate;
  DjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoUsingDjangoContentTypePkeyUpdate: DjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoUsingDjangoContentTypePkeyUpdate;
  DjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate: DjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate;
  DjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoUsingDjangoContentTypePkeyUpdate: DjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoUsingDjangoContentTypePkeyUpdate;
  DjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate: DjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate;
  DjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoUsingDjangoContentTypePkeyUpdate: DjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoUsingDjangoContentTypePkeyUpdate;
  DjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate: DjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoUsingDjangoContentTypeAppLabelModel76Bd3D3BUniqUpdate;
  DjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoUsingDjangoContentTypePkeyUpdate: DjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoUsingDjangoContentTypePkeyUpdate;
  DjangoContentTypePatch: DjangoContentTypePatch;
  DjangoContentTypeToManyAuthPermissionFilter: DjangoContentTypeToManyAuthPermissionFilter;
  DjangoContentTypeToManyDjangoAdminLogFilter: DjangoContentTypeToManyDjangoAdminLogFilter;
  DjangoContentTypeToManyDrsFileexecutorFilter: DjangoContentTypeToManyDrsFileexecutorFilter;
  DjangoContentTypeToManyTenantUserexecutorFilter: DjangoContentTypeToManyTenantUserexecutorFilter;
  DjangoContentTypeToManyTesContainerimageexecutorFilter: DjangoContentTypeToManyTesContainerimageexecutorFilter;
  DjangoContentTypeToManyTesTaskexecutorFilter: DjangoContentTypeToManyTesTaskexecutorFilter;
  DjangoContentTypesConnection: DjangoContentTypesConnection;
  DjangoContentTypesEdge: DjangoContentTypesEdge;
  DjangoMigration: DjangoMigration;
  DjangoMigrationCondition: DjangoMigrationCondition;
  DjangoMigrationFilter: DjangoMigrationFilter;
  DjangoMigrationInput: DjangoMigrationInput;
  DjangoMigrationPatch: DjangoMigrationPatch;
  DjangoMigrationsConnection: DjangoMigrationsConnection;
  DjangoMigrationsEdge: DjangoMigrationsEdge;
  DjangoSesSesstat: DjangoSesSesstat;
  DjangoSesSesstatCondition: DjangoSesSesstatCondition;
  DjangoSesSesstatFilter: DjangoSesSesstatFilter;
  DjangoSesSesstatInput: DjangoSesSesstatInput;
  DjangoSesSesstatPatch: DjangoSesSesstatPatch;
  DjangoSesSesstatsConnection: DjangoSesSesstatsConnection;
  DjangoSesSesstatsEdge: DjangoSesSesstatsEdge;
  DjangoSession: DjangoSession;
  DjangoSessionCondition: DjangoSessionCondition;
  DjangoSessionFilter: DjangoSessionFilter;
  DjangoSessionInput: DjangoSessionInput;
  DjangoSessionPatch: DjangoSessionPatch;
  DjangoSessionsConnection: DjangoSessionsConnection;
  DjangoSessionsEdge: DjangoSessionsEdge;
  DrsFileexecutor: DrsFileexecutor;
  DrsFileexecutorCondition: DrsFileexecutorCondition;
  DrsFileexecutorDrsFileexecutorPkeyConnect: DrsFileexecutorDrsFileexecutorPkeyConnect;
  DrsFileexecutorDrsFileexecutorPkeyDelete: DrsFileexecutorDrsFileexecutorPkeyDelete;
  DrsFileexecutorFilter: DrsFileexecutorFilter;
  DrsFileexecutorInput: DrsFileexecutorInput;
  DrsFileexecutorOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdUsingDrsFileexecutorPkeyUpdate: DrsFileexecutorOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdUsingDrsFileexecutorPkeyUpdate;
  DrsFileexecutorOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoUsingDrsFileexecutorPkeyUpdate: DrsFileexecutorOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoUsingDrsFileexecutorPkeyUpdate;
  DrsFileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeUsingDrsFileexecutorPkeyUpdate: DrsFileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeUsingDrsFileexecutorPkeyUpdate;
  DrsFileexecutorPatch: DrsFileexecutorPatch;
  DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoDjangoContentTypeCreateInput: DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoDjangoContentTypeCreateInput;
  DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoDrsFileexecutorCreateInput: DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoDrsFileexecutorCreateInput;
  DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInput: DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInput;
  DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput: DrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoInverseInput;
  DrsFileexecutorsConnection: DrsFileexecutorsConnection;
  DrsFileexecutorsEdge: DrsFileexecutorsEdge;
  DrsMockfileexecutor: DrsMockfileexecutor;
  DrsMockfileexecutorCondition: DrsMockfileexecutorCondition;
  DrsMockfileexecutorDrsMockfileexecutorPkeyConnect: DrsMockfileexecutorDrsMockfileexecutorPkeyConnect;
  DrsMockfileexecutorDrsMockfileexecutorPkeyDelete: DrsMockfileexecutorDrsMockfileexecutorPkeyDelete;
  DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeDrsFileexecutorCreateInput: DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeDrsFileexecutorCreateInput;
  DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeDrsMockfileexecutorCreateInput: DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeDrsMockfileexecutorCreateInput;
  DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInput: DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInput;
  DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInverseInput: DrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeInverseInput;
  DrsMockfileexecutorFilter: DrsMockfileexecutorFilter;
  DrsMockfileexecutorInput: DrsMockfileexecutorInput;
  DrsMockfileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeUsingDrsMockfileexecutorPkeyUpdate: DrsMockfileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileeUsingDrsMockfileexecutorPkeyUpdate;
  DrsMockfileexecutorPatch: DrsMockfileexecutorPatch;
  DrsMockfileexecutorsConnection: DrsMockfileexecutorsConnection;
  DrsMockfileexecutorsEdge: DrsMockfileexecutorsEdge;
  EulaVersion: EulaVersion;
  EulaVersionCondition: EulaVersionCondition;
  EulaVersionEulaVersionPkeyConnect: EulaVersionEulaVersionPkeyConnect;
  EulaVersionEulaVersionPkeyDelete: EulaVersionEulaVersionPkeyDelete;
  EulaVersionEulaVersionVersionKeyConnect: EulaVersionEulaVersionVersionKeyConnect;
  EulaVersionEulaVersionVersionKeyDelete: EulaVersionEulaVersionVersionKeyDelete;
  EulaVersionFilter: EulaVersionFilter;
  EulaVersionInput: EulaVersionInput;
  EulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionPkeyUpdate: EulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionPkeyUpdate;
  EulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionVersionKeyUpdate: EulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionVersionKeyUpdate;
  EulaVersionPatch: EulaVersionPatch;
  EulaVersionToManyEulaVersionUserAcceptanceFilter: EulaVersionToManyEulaVersionUserAcceptanceFilter;
  EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersEulaVersionCreateInput: EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersEulaVersionCreateInput;
  EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersEulaVersionUserAcceptanceCreateInput: EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersEulaVersionUserAcceptanceCreateInput;
  EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInput: EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInput;
  EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInverseInput: EulaVersionUserAcEulaVersionIdD2635C45FkEulaVersInverseInput;
  EulaVersionUserAcUserIdB72Ed9C5FkApplicatiApplicationUserCreateInput: EulaVersionUserAcUserIdB72Ed9C5FkApplicatiApplicationUserCreateInput;
  EulaVersionUserAcUserIdB72Ed9C5FkApplicatiEulaVersionUserAcceptanceCreateInput: EulaVersionUserAcUserIdB72Ed9C5FkApplicatiEulaVersionUserAcceptanceCreateInput;
  EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInput: EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInput;
  EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput: EulaVersionUserAcUserIdB72Ed9C5FkApplicatiInverseInput;
  EulaVersionUserAcceptance: EulaVersionUserAcceptance;
  EulaVersionUserAcceptanceCondition: EulaVersionUserAcceptanceCondition;
  EulaVersionUserAcceptanceEulaVersionUserAcceptancePkeyConnect: EulaVersionUserAcceptanceEulaVersionUserAcceptancePkeyConnect;
  EulaVersionUserAcceptanceEulaVersionUserAcceptancePkeyDelete: EulaVersionUserAcceptanceEulaVersionUserAcceptancePkeyDelete;
  EulaVersionUserAcceptanceEulaVersionUserAcceptanceUniqueConnect: EulaVersionUserAcceptanceEulaVersionUserAcceptanceUniqueConnect;
  EulaVersionUserAcceptanceEulaVersionUserAcceptanceUniqueDelete: EulaVersionUserAcceptanceEulaVersionUserAcceptanceUniqueDelete;
  EulaVersionUserAcceptanceFilter: EulaVersionUserAcceptanceFilter;
  EulaVersionUserAcceptanceInput: EulaVersionUserAcceptanceInput;
  EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionUserAcceptancePkeyUpdate: EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionUserAcceptancePkeyUpdate;
  EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionUserAcceptanceUniqueUpdate: EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersUsingEulaVersionUserAcceptanceUniqueUpdate;
  EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingEulaVersionUserAcceptancePkeyUpdate: EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingEulaVersionUserAcceptancePkeyUpdate;
  EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingEulaVersionUserAcceptanceUniqueUpdate: EulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiUsingEulaVersionUserAcceptanceUniqueUpdate;
  EulaVersionUserAcceptancePatch: EulaVersionUserAcceptancePatch;
  EulaVersionUserAcceptancesConnection: EulaVersionUserAcceptancesConnection;
  EulaVersionUserAcceptancesEdge: EulaVersionUserAcceptancesEdge;
  EulaVersionsConnection: EulaVersionsConnection;
  EulaVersionsEdge: EulaVersionsEdge;
  ExplorerQueriesConnection: ExplorerQueriesConnection;
  ExplorerQueriesEdge: ExplorerQueriesEdge;
  ExplorerQuery: ExplorerQuery;
  ExplorerQueryCondition: ExplorerQueryCondition;
  ExplorerQueryCreatedByUserId182Dd868FkApplicatiApplicationUserCreateInput: ExplorerQueryCreatedByUserId182Dd868FkApplicatiApplicationUserCreateInput;
  ExplorerQueryCreatedByUserId182Dd868FkApplicatiExplorerQueryCreateInput: ExplorerQueryCreatedByUserId182Dd868FkApplicatiExplorerQueryCreateInput;
  ExplorerQueryCreatedByUserId182Dd868FkApplicatiInput: ExplorerQueryCreatedByUserId182Dd868FkApplicatiInput;
  ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput: ExplorerQueryCreatedByUserId182Dd868FkApplicatiInverseInput;
  ExplorerQueryExplorerQueryPkeyConnect: ExplorerQueryExplorerQueryPkeyConnect;
  ExplorerQueryExplorerQueryPkeyDelete: ExplorerQueryExplorerQueryPkeyDelete;
  ExplorerQueryFilter: ExplorerQueryFilter;
  ExplorerQueryInput: ExplorerQueryInput;
  ExplorerQueryOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingExplorerQueryPkeyUpdate: ExplorerQueryOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiUsingExplorerQueryPkeyUpdate;
  ExplorerQueryOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdUsingExplorerQueryPkeyUpdate: ExplorerQueryOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdUsingExplorerQueryPkeyUpdate;
  ExplorerQueryOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdUsingExplorerQueryPkeyUpdate: ExplorerQueryOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdUsingExplorerQueryPkeyUpdate;
  ExplorerQueryPatch: ExplorerQueryPatch;
  ExplorerQueryToManyExplorerQueryfavoriteFilter: ExplorerQueryToManyExplorerQueryfavoriteFilter;
  ExplorerQueryToManyExplorerQuerylogFilter: ExplorerQueryToManyExplorerQuerylogFilter;
  ExplorerQueryfavorite: ExplorerQueryfavorite;
  ExplorerQueryfavoriteCondition: ExplorerQueryfavoriteCondition;
  ExplorerQueryfavoriteExplorerQueryfavoritePkeyConnect: ExplorerQueryfavoriteExplorerQueryfavoritePkeyConnect;
  ExplorerQueryfavoriteExplorerQueryfavoritePkeyDelete: ExplorerQueryfavoriteExplorerQueryfavoritePkeyDelete;
  ExplorerQueryfavoriteExplorerQueryfavoriteQueryIdUserId602B82DeUniqConnect: ExplorerQueryfavoriteExplorerQueryfavoriteQueryIdUserId602B82DeUniqConnect;
  ExplorerQueryfavoriteExplorerQueryfavoriteQueryIdUserId602B82DeUniqDelete: ExplorerQueryfavoriteExplorerQueryfavoriteQueryIdUserId602B82DeUniqDelete;
  ExplorerQueryfavoriteFilter: ExplorerQueryfavoriteFilter;
  ExplorerQueryfavoriteInput: ExplorerQueryfavoriteInput;
  ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdUsingExplorerQueryfavoritePkeyUpdate: ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdUsingExplorerQueryfavoritePkeyUpdate;
  ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdUsingExplorerQueryfavoriteQueryIdUserId602B82DeUniqUpdate: ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdUsingExplorerQueryfavoriteQueryIdUserId602B82DeUniqUpdate;
  ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingExplorerQueryfavoritePkeyUpdate: ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingExplorerQueryfavoritePkeyUpdate;
  ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingExplorerQueryfavoriteQueryIdUserId602B82DeUniqUpdate: ExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdUsingExplorerQueryfavoriteQueryIdUserId602B82DeUniqUpdate;
  ExplorerQueryfavoritePatch: ExplorerQueryfavoritePatch;
  ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdExplorerQueryCreateInput: ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdExplorerQueryCreateInput;
  ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdExplorerQueryfavoriteCreateInput: ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdExplorerQueryfavoriteCreateInput;
  ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInput: ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInput;
  ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInverseInput: ExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdInverseInput;
  ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdApplicationUserCreateInput: ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdApplicationUserCreateInput;
  ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdExplorerQueryfavoriteCreateInput: ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdExplorerQueryfavoriteCreateInput;
  ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInput: ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInput;
  ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput: ExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdInverseInput;
  ExplorerQueryfavoritesConnection: ExplorerQueryfavoritesConnection;
  ExplorerQueryfavoritesEdge: ExplorerQueryfavoritesEdge;
  ExplorerQuerylog: ExplorerQuerylog;
  ExplorerQuerylogCondition: ExplorerQuerylogCondition;
  ExplorerQuerylogExplorerQuerylogPkeyConnect: ExplorerQuerylogExplorerQuerylogPkeyConnect;
  ExplorerQuerylogExplorerQuerylogPkeyDelete: ExplorerQuerylogExplorerQuerylogPkeyDelete;
  ExplorerQuerylogFilter: ExplorerQuerylogFilter;
  ExplorerQuerylogInput: ExplorerQuerylogInput;
  ExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdUsingExplorerQuerylogPkeyUpdate: ExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdUsingExplorerQuerylogPkeyUpdate;
  ExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingExplorerQuerylogPkeyUpdate: ExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiUsingExplorerQuerylogPkeyUpdate;
  ExplorerQuerylogPatch: ExplorerQuerylogPatch;
  ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdExplorerQueryCreateInput: ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdExplorerQueryCreateInput;
  ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdExplorerQuerylogCreateInput: ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdExplorerQuerylogCreateInput;
  ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInput: ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInput;
  ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInverseInput: ExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdInverseInput;
  ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiApplicationUserCreateInput: ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiApplicationUserCreateInput;
  ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiExplorerQuerylogCreateInput: ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiExplorerQuerylogCreateInput;
  ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInput: ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInput;
  ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput: ExplorerQuerylogRunByUserIdCf26A49FFkApplicatiInverseInput;
  ExplorerQuerylogsConnection: ExplorerQuerylogsConnection;
  ExplorerQuerylogsEdge: ExplorerQuerylogsEdge;
  File: File;
  FileActivitiesConnection: FileActivitiesConnection;
  FileActivitiesEdge: FileActivitiesEdge;
  FileActivity: FileActivity;
  FileActivityCondition: FileActivityCondition;
  FileActivityCostId2A88D90AFkCostIdCostCreateInput: FileActivityCostId2A88D90AFkCostIdCostCreateInput;
  FileActivityCostId2A88D90AFkCostIdFileActivityCreateInput: FileActivityCostId2A88D90AFkCostIdFileActivityCreateInput;
  FileActivityCostId2A88D90AFkCostIdInput: FileActivityCostId2A88D90AFkCostIdInput;
  FileActivityCostId2A88D90AFkCostIdInverseInput: FileActivityCostId2A88D90AFkCostIdInverseInput;
  FileActivityFileActivityPkeyConnect: FileActivityFileActivityPkeyConnect;
  FileActivityFileActivityPkeyDelete: FileActivityFileActivityPkeyDelete;
  FileActivityFileId015763A9FkFileIdFileActivityCreateInput: FileActivityFileId015763A9FkFileIdFileActivityCreateInput;
  FileActivityFileId015763A9FkFileIdFileCreateInput: FileActivityFileId015763A9FkFileIdFileCreateInput;
  FileActivityFileId015763A9FkFileIdInput: FileActivityFileId015763A9FkFileIdInput;
  FileActivityFileId015763A9FkFileIdInverseInput: FileActivityFileId015763A9FkFileIdInverseInput;
  FileActivityFilter: FileActivityFilter;
  FileActivityInput: FileActivityInput;
  FileActivityOnFileActivityForFileActivityCostId2A88D90AFkCostIdUsingFileActivityPkeyUpdate: FileActivityOnFileActivityForFileActivityCostId2A88D90AFkCostIdUsingFileActivityPkeyUpdate;
  FileActivityOnFileActivityForFileActivityFileId015763A9FkFileIdUsingFileActivityPkeyUpdate: FileActivityOnFileActivityForFileActivityFileId015763A9FkFileIdUsingFileActivityPkeyUpdate;
  FileActivityOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdUsingFileActivityPkeyUpdate: FileActivityOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdUsingFileActivityPkeyUpdate;
  FileActivityOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingFileActivityPkeyUpdate: FileActivityOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingFileActivityPkeyUpdate;
  FileActivityOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingFileActivityPkeyUpdate: FileActivityOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdUsingFileActivityPkeyUpdate;
  FileActivityPatch: FileActivityPatch;
  FileActivityProjectId6F35B8CcFkProjectIdFileActivityCreateInput: FileActivityProjectId6F35B8CcFkProjectIdFileActivityCreateInput;
  FileActivityProjectId6F35B8CcFkProjectIdInput: FileActivityProjectId6F35B8CcFkProjectIdInput;
  FileActivityProjectId6F35B8CcFkProjectIdInverseInput: FileActivityProjectId6F35B8CcFkProjectIdInverseInput;
  FileActivityTenantIdC46A4F01FkTenantIdFileActivityCreateInput: FileActivityTenantIdC46A4F01FkTenantIdFileActivityCreateInput;
  FileActivityTenantIdC46A4F01FkTenantIdInput: FileActivityTenantIdC46A4F01FkTenantIdInput;
  FileActivityTenantIdC46A4F01FkTenantIdInverseInput: FileActivityTenantIdC46A4F01FkTenantIdInverseInput;
  FileActivityTenantIdC46A4F01FkTenantIdTenantCreateInput: FileActivityTenantIdC46A4F01FkTenantIdTenantCreateInput;
  FileActivityUserId868Ad8A9FkApplicationUserIdApplicationUserCreateInput: FileActivityUserId868Ad8A9FkApplicationUserIdApplicationUserCreateInput;
  FileActivityUserId868Ad8A9FkApplicationUserIdFileActivityCreateInput: FileActivityUserId868Ad8A9FkApplicationUserIdFileActivityCreateInput;
  FileActivityUserId868Ad8A9FkApplicationUserIdInput: FileActivityUserId868Ad8A9FkApplicationUserIdInput;
  FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput: FileActivityUserId868Ad8A9FkApplicationUserIdInverseInput;
  FileCloneSourceIdBef488AeFkFileIdInput: FileCloneSourceIdBef488AeFkFileIdInput;
  FileCondition: FileCondition;
  FileDatasetIdC0A4045AFkDatasetV4IdDatasetCreateInput: FileDatasetIdC0A4045AFkDatasetV4IdDatasetCreateInput;
  FileDatasetIdC0A4045AFkDatasetV4IdFileCreateInput: FileDatasetIdC0A4045AFkDatasetV4IdFileCreateInput;
  FileDatasetIdC0A4045AFkDatasetV4IdInput: FileDatasetIdC0A4045AFkDatasetV4IdInput;
  FileDatasetIdC0A4045AFkDatasetV4IdInverseInput: FileDatasetIdC0A4045AFkDatasetV4IdInverseInput;
  FileExecutorId819Da590FkDrsFileexecutorIdInput: FileExecutorId819Da590FkDrsFileexecutorIdInput;
  FileExecutorId819Da590FkDrsFileexecutorIdInverseInput: FileExecutorId819Da590FkDrsFileexecutorIdInverseInput;
  FileFilePkeyConnect: FileFilePkeyConnect;
  FileFilePkeyDelete: FileFilePkeyDelete;
  FileFilter: FileFilter;
  FileInput: FileInput;
  FileMetadataConnection: FileMetadataConnection;
  FileMetadataEdge: FileMetadataEdge;
  FileMetadataFileId78256936FkFileIdFileCreateInput: FileMetadataFileId78256936FkFileIdFileCreateInput;
  FileMetadataFileId78256936FkFileIdFileMetadataCreateInput: FileMetadataFileId78256936FkFileIdFileMetadataCreateInput;
  FileMetadataFileId78256936FkFileIdInput: FileMetadataFileId78256936FkFileIdInput;
  FileMetadataFileId78256936FkFileIdInverseInput: FileMetadataFileId78256936FkFileIdInverseInput;
  FileMetadataMetadataIdFefc0061FkMetadataV4IdFileMetadataCreateInput: FileMetadataMetadataIdFefc0061FkMetadataV4IdFileMetadataCreateInput;
  FileMetadataMetadataIdFefc0061FkMetadataV4IdInput: FileMetadataMetadataIdFefc0061FkMetadataV4IdInput;
  FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput: FileMetadataMetadataIdFefc0061FkMetadataV4IdInverseInput;
  FileMetadataMetadataIdFefc0061FkMetadataV4IdMetadataCreateInput: FileMetadataMetadataIdFefc0061FkMetadataV4IdMetadataCreateInput;
  FileMetadataProjectId3474994EFkProjectIdFileMetadataCreateInput: FileMetadataProjectId3474994EFkProjectIdFileMetadataCreateInput;
  FileMetadataProjectId3474994EFkProjectIdInput: FileMetadataProjectId3474994EFkProjectIdInput;
  FileMetadataProjectId3474994EFkProjectIdInverseInput: FileMetadataProjectId3474994EFkProjectIdInverseInput;
  FileMetadataTenantId79Fe438BFkTenantIdFileMetadataCreateInput: FileMetadataTenantId79Fe438BFkTenantIdFileMetadataCreateInput;
  FileMetadataTenantId79Fe438BFkTenantIdInput: FileMetadataTenantId79Fe438BFkTenantIdInput;
  FileMetadataTenantId79Fe438BFkTenantIdInverseInput: FileMetadataTenantId79Fe438BFkTenantIdInverseInput;
  FileMetadataTenantId79Fe438BFkTenantIdTenantCreateInput: FileMetadataTenantId79Fe438BFkTenantIdTenantCreateInput;
  FileMetadatum: FileMetadatum;
  FileMetadatumCondition: FileMetadatumCondition;
  FileMetadatumFileMetadataPkeyConnect: FileMetadatumFileMetadataPkeyConnect;
  FileMetadatumFileMetadataPkeyDelete: FileMetadatumFileMetadataPkeyDelete;
  FileMetadatumFilter: FileMetadatumFilter;
  FileMetadatumInput: FileMetadatumInput;
  FileMetadatumOnFileMetadatumForFileMetadataFileId78256936FkFileIdUsingFileMetadataPkeyUpdate: FileMetadatumOnFileMetadatumForFileMetadataFileId78256936FkFileIdUsingFileMetadataPkeyUpdate;
  FileMetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdUsingFileMetadataPkeyUpdate: FileMetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdUsingFileMetadataPkeyUpdate;
  FileMetadatumOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdUsingFileMetadataPkeyUpdate: FileMetadatumOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdUsingFileMetadataPkeyUpdate;
  FileMetadatumOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingFileMetadataPkeyUpdate: FileMetadatumOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingFileMetadataPkeyUpdate;
  FileMetadatumPatch: FileMetadatumPatch;
  FileOnFileActivityForFileActivityFileId015763A9FkFileIdUsingFilePkeyUpdate: FileOnFileActivityForFileActivityFileId015763A9FkFileIdUsingFilePkeyUpdate;
  FileOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingFilePkeyUpdate: FileOnFileForFileDatasetIdC0A4045AFkDatasetV4IdUsingFilePkeyUpdate;
  FileOnFileForFileProjectId3Ecca975FkProjectIdUsingFilePkeyUpdate: FileOnFileForFileProjectId3Ecca975FkProjectIdUsingFilePkeyUpdate;
  FileOnFileForFileSeriesParentIdF7A07E20FkFileIdUsingFilePkeyUpdate: FileOnFileForFileSeriesParentIdF7A07E20FkFileIdUsingFilePkeyUpdate;
  FileOnFileForFileTenantId61625016FkTenantIdUsingFilePkeyUpdate: FileOnFileForFileTenantId61625016FkTenantIdUsingFilePkeyUpdate;
  FileOnFileForFileUserId01D90B53FkApplicationUserIdUsingFilePkeyUpdate: FileOnFileForFileUserId01D90B53FkApplicationUserIdUsingFilePkeyUpdate;
  FileOnFileMetadatumForFileMetadataFileId78256936FkFileIdUsingFilePkeyUpdate: FileOnFileMetadatumForFileMetadataFileId78256936FkFileIdUsingFilePkeyUpdate;
  FileOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdUsingFilePkeyUpdate: FileOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdUsingFilePkeyUpdate;
  FileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdUsingFilePkeyUpdate: FileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdUsingFilePkeyUpdate;
  FileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdUsingFilePkeyUpdate: FileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdUsingFilePkeyUpdate;
  FileOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdUsingFilePkeyUpdate: FileOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdUsingFilePkeyUpdate;
  FileOutputGroup: FileOutputGroup;
  FileOutputGroupCondition: FileOutputGroupCondition;
  FileOutputGroupFileOutputGroupPkeyConnect: FileOutputGroupFileOutputGroupPkeyConnect;
  FileOutputGroupFileOutputGroupPkeyDelete: FileOutputGroupFileOutputGroupPkeyDelete;
  FileOutputGroupFileOutputGroupTaskIdKeyConnect: FileOutputGroupFileOutputGroupTaskIdKeyConnect;
  FileOutputGroupFileOutputGroupTaskIdKeyDelete: FileOutputGroupFileOutputGroupTaskIdKeyDelete;
  FileOutputGroupFilter: FileOutputGroupFilter;
  FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpFileOutputGroupMetadataCreateInput: FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpFileOutputGroupMetadataCreateInput;
  FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInput: FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInput;
  FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInverseInput: FileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpInverseInput;
  FileOutputGroupMeMetadataId3A08Fd87FkMetadataFileOutputGroupMetadataCreateInput: FileOutputGroupMeMetadataId3A08Fd87FkMetadataFileOutputGroupMetadataCreateInput;
  FileOutputGroupMeMetadataId3A08Fd87FkMetadataInput: FileOutputGroupMeMetadataId3A08Fd87FkMetadataInput;
  FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput: FileOutputGroupMeMetadataId3A08Fd87FkMetadataInverseInput;
  FileOutputGroupMeMetadataId3A08Fd87FkMetadataMetadataCreateInput: FileOutputGroupMeMetadataId3A08Fd87FkMetadataMetadataCreateInput;
  FileOutputGroupMetadataConnection: FileOutputGroupMetadataConnection;
  FileOutputGroupMetadataEdge: FileOutputGroupMetadataEdge;
  FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdFileOutputGroupMetadataCreateInput: FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdFileOutputGroupMetadataCreateInput;
  FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInput: FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInput;
  FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput: FileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdInverseInput;
  FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdFileOutputGroupMetadataCreateInput: FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdFileOutputGroupMetadataCreateInput;
  FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInput: FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInput;
  FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput: FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdInverseInput;
  FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdTenantCreateInput: FileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdTenantCreateInput;
  FileOutputGroupMetadatum: FileOutputGroupMetadatum;
  FileOutputGroupMetadatumCondition: FileOutputGroupMetadatumCondition;
  FileOutputGroupMetadatumFileOutputGroupMetadataPkeyConnect: FileOutputGroupMetadatumFileOutputGroupMetadataPkeyConnect;
  FileOutputGroupMetadatumFileOutputGroupMetadataPkeyDelete: FileOutputGroupMetadatumFileOutputGroupMetadataPkeyDelete;
  FileOutputGroupMetadatumFilter: FileOutputGroupMetadatumFilter;
  FileOutputGroupMetadatumInput: FileOutputGroupMetadatumInput;
  FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpUsingFileOutputGroupMetadataPkeyUpdate: FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpUsingFileOutputGroupMetadataPkeyUpdate;
  FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataUsingFileOutputGroupMetadataPkeyUpdate: FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataUsingFileOutputGroupMetadataPkeyUpdate;
  FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdUsingFileOutputGroupMetadataPkeyUpdate: FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdUsingFileOutputGroupMetadataPkeyUpdate;
  FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingFileOutputGroupMetadataPkeyUpdate: FileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingFileOutputGroupMetadataPkeyUpdate;
  FileOutputGroupMetadatumPatch: FileOutputGroupMetadatumPatch;
  FileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingFileOutputGroupPkeyUpdate: FileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingFileOutputGroupPkeyUpdate;
  FileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingFileOutputGroupTaskIdKeyUpdate: FileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingFileOutputGroupTaskIdKeyUpdate;
  FileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingFileOutputGroupPkeyUpdate: FileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingFileOutputGroupPkeyUpdate;
  FileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingFileOutputGroupTaskIdKeyUpdate: FileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingFileOutputGroupTaskIdKeyUpdate;
  FileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingFileOutputGroupPkeyUpdate: FileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingFileOutputGroupPkeyUpdate;
  FileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingFileOutputGroupTaskIdKeyUpdate: FileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingFileOutputGroupTaskIdKeyUpdate;
  FileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingFileOutputGroupPkeyUpdate: FileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingFileOutputGroupPkeyUpdate;
  FileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingFileOutputGroupTaskIdKeyUpdate: FileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdUsingFileOutputGroupTaskIdKeyUpdate;
  FileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpUsingFileOutputGroupPkeyUpdate: FileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpUsingFileOutputGroupPkeyUpdate;
  FileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpUsingFileOutputGroupTaskIdKeyUpdate: FileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpUsingFileOutputGroupTaskIdKeyUpdate;
  FileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpUsingFileOutputGroupPkeyUpdate: FileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpUsingFileOutputGroupPkeyUpdate;
  FileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpUsingFileOutputGroupTaskIdKeyUpdate: FileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpUsingFileOutputGroupTaskIdKeyUpdate;
  FileOutputGroupPatch: FileOutputGroupPatch;
  FileOutputGroupProjectId908Fb9FfFkProjectIdInput: FileOutputGroupProjectId908Fb9FfFkProjectIdInput;
  FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput: FileOutputGroupProjectId908Fb9FfFkProjectIdInverseInput;
  FileOutputGroupTaskId2B1Df6A1FkTaskIdInput: FileOutputGroupTaskId2B1Df6A1FkTaskIdInput;
  FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput: FileOutputGroupTaskId2B1Df6A1FkTaskIdInverseInput;
  FileOutputGroupTaskId2B1Df6A1FkTaskIdTaskCreateInput: FileOutputGroupTaskId2B1Df6A1FkTaskIdTaskCreateInput;
  FileOutputGroupTenantId3Fa46D44FkTenantIdInput: FileOutputGroupTenantId3Fa46D44FkTenantIdInput;
  FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput: FileOutputGroupTenantId3Fa46D44FkTenantIdInverseInput;
  FileOutputGroupTenantId3Fa46D44FkTenantIdTenantCreateInput: FileOutputGroupTenantId3Fa46D44FkTenantIdTenantCreateInput;
  FileOutputGroupToManyFileOutputGroupMetadatumFilter: FileOutputGroupToManyFileOutputGroupMetadatumFilter;
  FileOutputGroupToManyOutputGroupFileFilter: FileOutputGroupToManyOutputGroupFileFilter;
  FileOutputGroupUserIdB22Ec436FkApplicationUserIdApplicationUserCreateInput: FileOutputGroupUserIdB22Ec436FkApplicationUserIdApplicationUserCreateInput;
  FileOutputGroupUserIdB22Ec436FkApplicationUserIdInput: FileOutputGroupUserIdB22Ec436FkApplicationUserIdInput;
  FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput: FileOutputGroupUserIdB22Ec436FkApplicationUserIdInverseInput;
  FileOutputGroupsConnection: FileOutputGroupsConnection;
  FileOutputGroupsEdge: FileOutputGroupsEdge;
  FilePatch: FilePatch;
  FileProcessingNotiUserId08265156FkApplicatiApplicationUserCreateInput: FileProcessingNotiUserId08265156FkApplicatiApplicationUserCreateInput;
  FileProcessingNotiUserId08265156FkApplicatiInput: FileProcessingNotiUserId08265156FkApplicatiInput;
  FileProcessingNotiUserId08265156FkApplicatiInverseInput: FileProcessingNotiUserId08265156FkApplicatiInverseInput;
  FileProcessingNotification: FileProcessingNotification;
  FileProcessingNotificationCondition: FileProcessingNotificationCondition;
  FileProcessingNotificationFileId717B514EFkFileIdFileCreateInput: FileProcessingNotificationFileId717B514EFkFileIdFileCreateInput;
  FileProcessingNotificationFileId717B514EFkFileIdInput: FileProcessingNotificationFileId717B514EFkFileIdInput;
  FileProcessingNotificationFileId717B514EFkFileIdInverseInput: FileProcessingNotificationFileId717B514EFkFileIdInverseInput;
  FileProcessingNotificationFileProcessingNotificationPkeyConnect: FileProcessingNotificationFileProcessingNotificationPkeyConnect;
  FileProcessingNotificationFilter: FileProcessingNotificationFilter;
  FileProcessingNotificationId630E6495FkNotificationIdInput: FileProcessingNotificationId630E6495FkNotificationIdInput;
  FileProcessingNotificationId630E6495FkNotificationIdInverseInput: FileProcessingNotificationId630E6495FkNotificationIdInverseInput;
  FileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingFileProcessingNotificationPkeyUpdate: FileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiUsingFileProcessingNotificationPkeyUpdate;
  FileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdUsingFileProcessingNotificationPkeyUpdate: FileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdUsingFileProcessingNotificationPkeyUpdate;
  FileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdUsingFileProcessingNotificationPkeyUpdate: FileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdUsingFileProcessingNotificationPkeyUpdate;
  FileProcessingNotificationsConnection: FileProcessingNotificationsConnection;
  FileProcessingNotificationsEdge: FileProcessingNotificationsEdge;
  FileProjectId3Ecca975FkProjectIdFileCreateInput: FileProjectId3Ecca975FkProjectIdFileCreateInput;
  FileProjectId3Ecca975FkProjectIdInput: FileProjectId3Ecca975FkProjectIdInput;
  FileProjectId3Ecca975FkProjectIdInverseInput: FileProjectId3Ecca975FkProjectIdInverseInput;
  FileRecordingGroup: FileRecordingGroup;
  FileRecordingGroupCondition: FileRecordingGroupCondition;
  FileRecordingGroupDatasetRecordingsTB91F3991FkDatasetRDatasetRecordingsTableCreateInput: FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRDatasetRecordingsTableCreateInput;
  FileRecordingGroupDatasetRecordingsTB91F3991FkDatasetRFileRecordingGroupCreateInput: FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRFileRecordingGroupCreateInput;
  FileRecordingGroupDatasetRecordingsTB91F3991FkDatasetRInput: FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInput;
  FileRecordingGroupDatasetRecordingsTB91F3991FkDatasetRInverseInput: FileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRInverseInput;
  FileRecordingGroupFileRecordingGroup60583376FkFileRecoFileRecordingGroupCreateInput: FileRecordingGroupFileRecordingGroup60583376FkFileRecoFileRecordingGroupCreateInput;
  FileRecordingGroupFileRecordingGroup60583376FkFileRecoFileRecordingGroupMetadataCreateInput: FileRecordingGroupFileRecordingGroup60583376FkFileRecoFileRecordingGroupMetadataCreateInput;
  FileRecordingGroupFileRecordingGroup60583376FkFileRecoInput: FileRecordingGroupFileRecordingGroup60583376FkFileRecoInput;
  FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput: FileRecordingGroupFileRecordingGroup60583376FkFileRecoInverseInput;
  FileRecordingGroupFileRecordingGroupPkeyConnect: FileRecordingGroupFileRecordingGroupPkeyConnect;
  FileRecordingGroupFileRecordingGroupPkeyDelete: FileRecordingGroupFileRecordingGroupPkeyDelete;
  FileRecordingGroupFilter: FileRecordingGroupFilter;
  FileRecordingGroupInput: FileRecordingGroupInput;
  FileRecordingGroupMetadataConnection: FileRecordingGroupMetadataConnection;
  FileRecordingGroupMetadataEdge: FileRecordingGroupMetadataEdge;
  FileRecordingGroupMetadataIdF054E41AFkMetadataFileRecordingGroupMetadataCreateInput: FileRecordingGroupMetadataIdF054E41AFkMetadataFileRecordingGroupMetadataCreateInput;
  FileRecordingGroupMetadataIdF054E41AFkMetadataInput: FileRecordingGroupMetadataIdF054E41AFkMetadataInput;
  FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput: FileRecordingGroupMetadataIdF054E41AFkMetadataInverseInput;
  FileRecordingGroupMetadataIdF054E41AFkMetadataMetadataCreateInput: FileRecordingGroupMetadataIdF054E41AFkMetadataMetadataCreateInput;
  FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdFileRecordingGroupMetadataCreateInput: FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdFileRecordingGroupMetadataCreateInput;
  FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInput: FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInput;
  FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput: FileRecordingGroupMetadataProjectId7574Cf75FkProjectIdInverseInput;
  FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdFileRecordingGroupMetadataCreateInput: FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdFileRecordingGroupMetadataCreateInput;
  FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInput: FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInput;
  FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput: FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdInverseInput;
  FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdTenantCreateInput: FileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdTenantCreateInput;
  FileRecordingGroupMetadatum: FileRecordingGroupMetadatum;
  FileRecordingGroupMetadatumCondition: FileRecordingGroupMetadatumCondition;
  FileRecordingGroupMetadatumFileRecordingGroupMetadataPkeyConnect: FileRecordingGroupMetadatumFileRecordingGroupMetadataPkeyConnect;
  FileRecordingGroupMetadatumFileRecordingGroupMetadataPkeyDelete: FileRecordingGroupMetadatumFileRecordingGroupMetadataPkeyDelete;
  FileRecordingGroupMetadatumFilter: FileRecordingGroupMetadatumFilter;
  FileRecordingGroupMetadatumInput: FileRecordingGroupMetadatumInput;
  FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoUsingFileRecordingGroupMetadataPkeyUpdate: FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoUsingFileRecordingGroupMetadataPkeyUpdate;
  FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataUsingFileRecordingGroupMetadataPkeyUpdate: FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataUsingFileRecordingGroupMetadataPkeyUpdate;
  FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdUsingFileRecordingGroupMetadataPkeyUpdate: FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdUsingFileRecordingGroupMetadataPkeyUpdate;
  FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingFileRecordingGroupMetadataPkeyUpdate: FileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingFileRecordingGroupMetadataPkeyUpdate;
  FileRecordingGroupMetadatumPatch: FileRecordingGroupMetadatumPatch;
  FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTB91F3991FkDatasetRUsingFileRecordingGroupPkeyUpdate: FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRUsingFileRecordingGroupPkeyUpdate;
  FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdUsingFileRecordingGroupPkeyUpdate: FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdUsingFileRecordingGroupPkeyUpdate;
  FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingFileRecordingGroupPkeyUpdate: FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingFileRecordingGroupPkeyUpdate;
  FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingFileRecordingGroupPkeyUpdate: FileRecordingGroupOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdUsingFileRecordingGroupPkeyUpdate;
  FileRecordingGroupOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoUsingFileRecordingGroupPkeyUpdate: FileRecordingGroupOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoUsingFileRecordingGroupPkeyUpdate;
  FileRecordingGroupOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoUsingFileRecordingGroupPkeyUpdate: FileRecordingGroupOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoUsingFileRecordingGroupPkeyUpdate;
  FileRecordingGroupPatch: FileRecordingGroupPatch;
  FileRecordingGroupProjectIdB14Ba577FkProjectIdFileRecordingGroupCreateInput: FileRecordingGroupProjectIdB14Ba577FkProjectIdFileRecordingGroupCreateInput;
  FileRecordingGroupProjectIdB14Ba577FkProjectIdInput: FileRecordingGroupProjectIdB14Ba577FkProjectIdInput;
  FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput: FileRecordingGroupProjectIdB14Ba577FkProjectIdInverseInput;
  FileRecordingGroupTenantIdCbdec6BcFkTenantIdFileRecordingGroupCreateInput: FileRecordingGroupTenantIdCbdec6BcFkTenantIdFileRecordingGroupCreateInput;
  FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput: FileRecordingGroupTenantIdCbdec6BcFkTenantIdInput;
  FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput: FileRecordingGroupTenantIdCbdec6BcFkTenantIdInverseInput;
  FileRecordingGroupTenantIdCbdec6BcFkTenantIdTenantCreateInput: FileRecordingGroupTenantIdCbdec6BcFkTenantIdTenantCreateInput;
  FileRecordingGroupToManyFileRecordingGroupMetadatumFilter: FileRecordingGroupToManyFileRecordingGroupMetadatumFilter;
  FileRecordingGroupToManyRecordingGroupFileFilter: FileRecordingGroupToManyRecordingGroupFileFilter;
  FileRecordingGroupUserId421A5CddFkApplicationUserIdApplicationUserCreateInput: FileRecordingGroupUserId421A5CddFkApplicationUserIdApplicationUserCreateInput;
  FileRecordingGroupUserId421A5CddFkApplicationUserIdFileRecordingGroupCreateInput: FileRecordingGroupUserId421A5CddFkApplicationUserIdFileRecordingGroupCreateInput;
  FileRecordingGroupUserId421A5CddFkApplicationUserIdInput: FileRecordingGroupUserId421A5CddFkApplicationUserIdInput;
  FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput: FileRecordingGroupUserId421A5CddFkApplicationUserIdInverseInput;
  FileRecordingGroupsConnection: FileRecordingGroupsConnection;
  FileRecordingGroupsEdge: FileRecordingGroupsEdge;
  FileSeriesParentIdF7A07E20FkFileIdFileCreateInput: FileSeriesParentIdF7A07E20FkFileIdFileCreateInput;
  FileSeriesParentIdF7A07E20FkFileIdInput: FileSeriesParentIdF7A07E20FkFileIdInput;
  FileTenantId61625016FkTenantIdFileCreateInput: FileTenantId61625016FkTenantIdFileCreateInput;
  FileTenantId61625016FkTenantIdInput: FileTenantId61625016FkTenantIdInput;
  FileTenantId61625016FkTenantIdInverseInput: FileTenantId61625016FkTenantIdInverseInput;
  FileTenantId61625016FkTenantIdTenantCreateInput: FileTenantId61625016FkTenantIdTenantCreateInput;
  FileToManyFileActivityFilter: FileToManyFileActivityFilter;
  FileToManyFileFilter: FileToManyFileFilter;
  FileToManyFileMetadatumFilter: FileToManyFileMetadatumFilter;
  FileToManyFileProcessingNotificationFilter: FileToManyFileProcessingNotificationFilter;
  FileToManyOutputGroupFileFilter: FileToManyOutputGroupFileFilter;
  FileToManyRecordingGroupFileFilter: FileToManyRecordingGroupFileFilter;
  FileToManyTaskSourceFilter: FileToManyTaskSourceFilter;
  FileUserId01D90B53FkApplicationUserIdApplicationUserCreateInput: FileUserId01D90B53FkApplicationUserIdApplicationUserCreateInput;
  FileUserId01D90B53FkApplicationUserIdFileCreateInput: FileUserId01D90B53FkApplicationUserIdFileCreateInput;
  FileUserId01D90B53FkApplicationUserIdInput: FileUserId01D90B53FkApplicationUserIdInput;
  FileUserId01D90B53FkApplicationUserIdInverseInput: FileUserId01D90B53FkApplicationUserIdInverseInput;
  FilesConnection: FilesConnection;
  FilesEdge: FilesEdge;
  Float: Scalars["Float"]["output"];
  FloatFilter: FloatFilter;
  ID: Scalars["ID"]["output"];
  Int: Scalars["Int"]["output"];
  IntFilter: IntFilter;
  Interval: Interval;
  IntervalFilter: IntervalFilter;
  IntervalInput: IntervalInput;
  JSON: Scalars["JSON"]["output"];
  JSONFilter: JsonFilter;
  MetadataConnection: MetadataConnection;
  MetadataEdge: MetadataEdge;
  MetadataProjectId3333C2AdFkProjectIdInput: MetadataProjectId3333C2AdFkProjectIdInput;
  MetadataProjectId3333C2AdFkProjectIdInverseInput: MetadataProjectId3333C2AdFkProjectIdInverseInput;
  MetadataProjectId3333C2AdFkProjectIdMetadataCreateInput: MetadataProjectId3333C2AdFkProjectIdMetadataCreateInput;
  MetadataV4TenantId163B6569FkTenantIdInput: MetadataV4TenantId163B6569FkTenantIdInput;
  MetadataV4TenantId163B6569FkTenantIdInverseInput: MetadataV4TenantId163B6569FkTenantIdInverseInput;
  MetadataV4TenantId163B6569FkTenantIdMetadataCreateInput: MetadataV4TenantId163B6569FkTenantIdMetadataCreateInput;
  MetadataV4TenantId163B6569FkTenantIdTenantCreateInput: MetadataV4TenantId163B6569FkTenantIdTenantCreateInput;
  MetadataValue: MetadataValue;
  MetadataValueCondition: MetadataValueCondition;
  MetadataValueFilter: MetadataValueFilter;
  MetadataValueInput: MetadataValueInput;
  MetadataValueMetadataId063A53B6FkMetadataV4IdInput: MetadataValueMetadataId063A53B6FkMetadataV4IdInput;
  MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput: MetadataValueMetadataId063A53B6FkMetadataV4IdInverseInput;
  MetadataValueMetadataId063A53B6FkMetadataV4IdMetadataCreateInput: MetadataValueMetadataId063A53B6FkMetadataV4IdMetadataCreateInput;
  MetadataValueMetadataId063A53B6FkMetadataV4IdMetadataValueCreateInput: MetadataValueMetadataId063A53B6FkMetadataV4IdMetadataValueCreateInput;
  MetadataValueMetadataValuePkeyConnect: MetadataValueMetadataValuePkeyConnect;
  MetadataValueMetadataValuePkeyDelete: MetadataValueMetadataValuePkeyDelete;
  MetadataValueOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdUsingMetadataValuePkeyUpdate: MetadataValueOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdUsingMetadataValuePkeyUpdate;
  MetadataValueOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdUsingMetadataValuePkeyUpdate: MetadataValueOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdUsingMetadataValuePkeyUpdate;
  MetadataValueOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdUsingMetadataValuePkeyUpdate: MetadataValueOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdUsingMetadataValuePkeyUpdate;
  MetadataValueOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingMetadataValuePkeyUpdate: MetadataValueOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingMetadataValuePkeyUpdate;
  MetadataValueOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingMetadataValuePkeyUpdate: MetadataValueOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdUsingMetadataValuePkeyUpdate;
  MetadataValuePatch: MetadataValuePatch;
  MetadataValueProjectId87227D96FkProjectIdInput: MetadataValueProjectId87227D96FkProjectIdInput;
  MetadataValueProjectId87227D96FkProjectIdInverseInput: MetadataValueProjectId87227D96FkProjectIdInverseInput;
  MetadataValueProjectId87227D96FkProjectIdMetadataValueCreateInput: MetadataValueProjectId87227D96FkProjectIdMetadataValueCreateInput;
  MetadataValueTaskId3D37D135FkTaskIdInput: MetadataValueTaskId3D37D135FkTaskIdInput;
  MetadataValueTaskId3D37D135FkTaskIdInverseInput: MetadataValueTaskId3D37D135FkTaskIdInverseInput;
  MetadataValueTaskId3D37D135FkTaskIdMetadataValueCreateInput: MetadataValueTaskId3D37D135FkTaskIdMetadataValueCreateInput;
  MetadataValueTaskId3D37D135FkTaskIdTaskCreateInput: MetadataValueTaskId3D37D135FkTaskIdTaskCreateInput;
  MetadataValueTenantIdB4C2D5D6FkTenantIdInput: MetadataValueTenantIdB4C2D5D6FkTenantIdInput;
  MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput: MetadataValueTenantIdB4C2D5D6FkTenantIdInverseInput;
  MetadataValueTenantIdB4C2D5D6FkTenantIdMetadataValueCreateInput: MetadataValueTenantIdB4C2D5D6FkTenantIdMetadataValueCreateInput;
  MetadataValueTenantIdB4C2D5D6FkTenantIdTenantCreateInput: MetadataValueTenantIdB4C2D5D6FkTenantIdTenantCreateInput;
  MetadataValueUserIdB2Bb45AeFkApplicationUserIdApplicationUserCreateInput: MetadataValueUserIdB2Bb45AeFkApplicationUserIdApplicationUserCreateInput;
  MetadataValueUserIdB2Bb45AeFkApplicationUserIdInput: MetadataValueUserIdB2Bb45AeFkApplicationUserIdInput;
  MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput: MetadataValueUserIdB2Bb45AeFkApplicationUserIdInverseInput;
  MetadataValueUserIdB2Bb45AeFkApplicationUserIdMetadataValueCreateInput: MetadataValueUserIdB2Bb45AeFkApplicationUserIdMetadataValueCreateInput;
  MetadataValuesConnection: MetadataValuesConnection;
  MetadataValuesEdge: MetadataValuesEdge;
  Metadatum: Metadatum;
  MetadatumCondition: MetadatumCondition;
  MetadatumFilter: MetadatumFilter;
  MetadatumInput: MetadatumInput;
  MetadatumMetadataV4PkeyConnect: MetadatumMetadataV4PkeyConnect;
  MetadatumMetadataV4PkeyDelete: MetadatumMetadataV4PkeyDelete;
  MetadatumOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingMetadataV4PkeyUpdate: MetadatumOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdUsingMetadataV4PkeyUpdate;
  MetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdUsingMetadataV4PkeyUpdate: MetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdUsingMetadataV4PkeyUpdate;
  MetadatumOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataUsingMetadataV4PkeyUpdate: MetadatumOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataUsingMetadataV4PkeyUpdate;
  MetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdUsingMetadataV4PkeyUpdate: MetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdUsingMetadataV4PkeyUpdate;
  MetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataUsingMetadataV4PkeyUpdate: MetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataUsingMetadataV4PkeyUpdate;
  MetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataUsingMetadataV4PkeyUpdate: MetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataUsingMetadataV4PkeyUpdate;
  MetadatumOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdUsingMetadataV4PkeyUpdate: MetadatumOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdUsingMetadataV4PkeyUpdate;
  MetadatumOnMetadatumForMetadataProjectId3333C2AdFkProjectIdUsingMetadataV4PkeyUpdate: MetadatumOnMetadatumForMetadataProjectId3333C2AdFkProjectIdUsingMetadataV4PkeyUpdate;
  MetadatumOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingMetadataV4PkeyUpdate: MetadatumOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingMetadataV4PkeyUpdate;
  MetadatumPatch: MetadatumPatch;
  MetadatumToManyDatasetFilter: MetadatumToManyDatasetFilter;
  MetadatumToManyDatasetMetadatumFilter: MetadatumToManyDatasetMetadatumFilter;
  MetadatumToManyDatasetRecordingsTableColumnFilter: MetadatumToManyDatasetRecordingsTableColumnFilter;
  MetadatumToManyFileMetadatumFilter: MetadatumToManyFileMetadatumFilter;
  MetadatumToManyFileOutputGroupMetadatumFilter: MetadatumToManyFileOutputGroupMetadatumFilter;
  MetadatumToManyFileRecordingGroupMetadatumFilter: MetadatumToManyFileRecordingGroupMetadatumFilter;
  MetadatumToManyMetadataValueFilter: MetadatumToManyMetadataValueFilter;
  Mutation: {};
  Notification: Notification;
  NotificationCondition: NotificationCondition;
  NotificationFilter: NotificationFilter;
  NotificationNotificationPkeyConnect: NotificationNotificationPkeyConnect;
  NotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdUsingNotificationPkeyUpdate: NotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdUsingNotificationPkeyUpdate;
  NotificationOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingNotificationPkeyUpdate: NotificationOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdUsingNotificationPkeyUpdate;
  NotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdUsingNotificationPkeyUpdate: NotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdUsingNotificationPkeyUpdate;
  NotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdUsingNotificationPkeyUpdate: NotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdUsingNotificationPkeyUpdate;
  NotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdUsingNotificationPkeyUpdate: NotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdUsingNotificationPkeyUpdate;
  NotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatUsingNotificationPkeyUpdate: NotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatUsingNotificationPkeyUpdate;
  NotificationPatch: NotificationPatch;
  NotificationUserId1002Fc38FkApplicationUserIdApplicationUserCreateInput: NotificationUserId1002Fc38FkApplicationUserIdApplicationUserCreateInput;
  NotificationUserId1002Fc38FkApplicationUserIdInput: NotificationUserId1002Fc38FkApplicationUserIdInput;
  NotificationUserId1002Fc38FkApplicationUserIdInverseInput: NotificationUserId1002Fc38FkApplicationUserIdInverseInput;
  NotificationsConnection: NotificationsConnection;
  NotificationsEdge: NotificationsEdge;
  OutputGroupFile: OutputGroupFile;
  OutputGroupFileCondition: OutputGroupFileCondition;
  OutputGroupFileFilter: OutputGroupFileFilter;
  OutputGroupFileInput: OutputGroupFileInput;
  OutputGroupFileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdUsingOutputGroupFilesPkeyUpdate: OutputGroupFileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdUsingOutputGroupFilesPkeyUpdate;
  OutputGroupFileOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpUsingOutputGroupFilesPkeyUpdate: OutputGroupFileOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpUsingOutputGroupFilesPkeyUpdate;
  OutputGroupFileOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdUsingOutputGroupFilesPkeyUpdate: OutputGroupFileOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdUsingOutputGroupFilesPkeyUpdate;
  OutputGroupFileOutputGroupFilesPkeyConnect: OutputGroupFileOutputGroupFilesPkeyConnect;
  OutputGroupFileOutputGroupFilesPkeyDelete: OutputGroupFileOutputGroupFilesPkeyDelete;
  OutputGroupFilePatch: OutputGroupFilePatch;
  OutputGroupFilesConnection: OutputGroupFilesConnection;
  OutputGroupFilesEdge: OutputGroupFilesEdge;
  OutputGroupFilesFileIdEdeabb23FkFileIdFileCreateInput: OutputGroupFilesFileIdEdeabb23FkFileIdFileCreateInput;
  OutputGroupFilesFileIdEdeabb23FkFileIdInput: OutputGroupFilesFileIdEdeabb23FkFileIdInput;
  OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput: OutputGroupFilesFileIdEdeabb23FkFileIdInverseInput;
  OutputGroupFilesFileIdEdeabb23FkFileIdOutputGroupFilesCreateInput: OutputGroupFilesFileIdEdeabb23FkFileIdOutputGroupFilesCreateInput;
  OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInput: OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInput;
  OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInverseInput: OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpInverseInput;
  OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpOutputGroupFilesCreateInput: OutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpOutputGroupFilesCreateInput;
  OutputGroupFilesProjectId8709B561FkProjectIdInput: OutputGroupFilesProjectId8709B561FkProjectIdInput;
  OutputGroupFilesProjectId8709B561FkProjectIdInverseInput: OutputGroupFilesProjectId8709B561FkProjectIdInverseInput;
  OutputGroupFilesProjectId8709B561FkProjectIdOutputGroupFilesCreateInput: OutputGroupFilesProjectId8709B561FkProjectIdOutputGroupFilesCreateInput;
  PageInfo: PageInfo;
  Project: Project;
  ProjectCloneSourceId5F0B62C5FkProjectIdInput: ProjectCloneSourceId5F0B62C5FkProjectIdInput;
  ProjectClonedNotifUserId4Fd693D7FkApplicatiApplicationUserCreateInput: ProjectClonedNotifUserId4Fd693D7FkApplicatiApplicationUserCreateInput;
  ProjectClonedNotifUserId4Fd693D7FkApplicatiInput: ProjectClonedNotifUserId4Fd693D7FkApplicatiInput;
  ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput: ProjectClonedNotifUserId4Fd693D7FkApplicatiInverseInput;
  ProjectClonedNotification: ProjectClonedNotification;
  ProjectClonedNotificationCondition: ProjectClonedNotificationCondition;
  ProjectClonedNotificationFilter: ProjectClonedNotificationFilter;
  ProjectClonedNotificationIdD235A018FkNotificationIdInput: ProjectClonedNotificationIdD235A018FkNotificationIdInput;
  ProjectClonedNotificationIdD235A018FkNotificationIdInverseInput: ProjectClonedNotificationIdD235A018FkNotificationIdInverseInput;
  ProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingProjectClonedNotificationPkeyUpdate: ProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiUsingProjectClonedNotificationPkeyUpdate;
  ProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdUsingProjectClonedNotificationPkeyUpdate: ProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdUsingProjectClonedNotificationPkeyUpdate;
  ProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdUsingProjectClonedNotificationPkeyUpdate: ProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdUsingProjectClonedNotificationPkeyUpdate;
  ProjectClonedNotificationProjectClonedNotificationPkeyConnect: ProjectClonedNotificationProjectClonedNotificationPkeyConnect;
  ProjectClonedNotificationProjectIdF6267374FkProjectIdInput: ProjectClonedNotificationProjectIdF6267374FkProjectIdInput;
  ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput: ProjectClonedNotificationProjectIdF6267374FkProjectIdInverseInput;
  ProjectClonedNotificationsConnection: ProjectClonedNotificationsConnection;
  ProjectClonedNotificationsEdge: ProjectClonedNotificationsEdge;
  ProjectCondition: ProjectCondition;
  ProjectFilter: ProjectFilter;
  ProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdUsingProjectPkeyUpdate: ProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdUsingProjectPkeyUpdate: ProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIUsingProjectKeyTenantUniqueUpdate: ProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIUsingProjectKeyTenantUniqueUpdate;
  ProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIUsingProjectPkeyUpdate: ProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIUsingProjectPkeyUpdate;
  ProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingProjectPkeyUpdate: ProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingProjectPkeyUpdate: ProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdUsingProjectPkeyUpdate: ProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdUsingProjectPkeyUpdate;
  ProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIUsingProjectKeyTenantUniqueUpdate: ProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIUsingProjectKeyTenantUniqueUpdate;
  ProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIUsingProjectPkeyUpdate: ProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIUsingProjectPkeyUpdate;
  ProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIUsingProjectKeyTenantUniqueUpdate: ProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIUsingProjectKeyTenantUniqueUpdate;
  ProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIUsingProjectPkeyUpdate: ProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIUsingProjectPkeyUpdate;
  ProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdUsingProjectPkeyUpdate: ProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdUsingProjectPkeyUpdate: ProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdUsingProjectPkeyUpdate;
  ProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdUsingProjectPkeyUpdate: ProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdUsingProjectPkeyUpdate;
  ProjectOnFileForFileProjectId3Ecca975FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnFileForFileProjectId3Ecca975FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnFileForFileProjectId3Ecca975FkProjectIdUsingProjectPkeyUpdate: ProjectOnFileForFileProjectId3Ecca975FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdUsingProjectPkeyUpdate: ProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdUsingProjectPkeyUpdate;
  ProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingProjectPkeyUpdate: ProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdUsingProjectPkeyUpdate;
  ProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdUsingProjectPkeyUpdate: ProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdUsingProjectPkeyUpdate: ProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdUsingProjectPkeyUpdate: ProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdUsingProjectPkeyUpdate: ProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdUsingProjectPkeyUpdate: ProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdUsingProjectPkeyUpdate;
  ProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdUsingProjectPkeyUpdate: ProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdUsingProjectPkeyUpdate: ProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingProjectKeyTenantUniqueUpdate: ProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingProjectPkeyUpdate: ProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingProjectPkeyUpdate;
  ProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingProjectKeyTenantUniqueUpdate: ProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingProjectPkeyUpdate: ProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdUsingProjectPkeyUpdate;
  ProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdUsingProjectPkeyUpdate: ProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdUsingProjectPkeyUpdate;
  ProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdUsingProjectPkeyUpdate: ProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdUsingProjectPkeyUpdate;
  ProjectOnTaskForTaskProjectId963D6354FkProjectIdUsingProjectKeyTenantUniqueUpdate: ProjectOnTaskForTaskProjectId963D6354FkProjectIdUsingProjectKeyTenantUniqueUpdate;
  ProjectOnTaskForTaskProjectId963D6354FkProjectIdUsingProjectPkeyUpdate: ProjectOnTaskForTaskProjectId963D6354FkProjectIdUsingProjectPkeyUpdate;
  ProjectProjectKeyTenantUniqueConnect: ProjectProjectKeyTenantUniqueConnect;
  ProjectProjectPkeyConnect: ProjectProjectPkeyConnect;
  ProjectSharedNotifSharerIdAbe16C5BFkApplicatiApplicationUserCreateInput: ProjectSharedNotifSharerIdAbe16C5BFkApplicatiApplicationUserCreateInput;
  ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInput: ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInput;
  ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput: ProjectSharedNotifSharerIdAbe16C5BFkApplicatiInverseInput;
  ProjectSharedNotifUserId9A19122FFkApplicatiApplicationUserCreateInput: ProjectSharedNotifUserId9A19122FFkApplicatiApplicationUserCreateInput;
  ProjectSharedNotifUserId9A19122FFkApplicatiInput: ProjectSharedNotifUserId9A19122FFkApplicatiInput;
  ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput: ProjectSharedNotifUserId9A19122FFkApplicatiInverseInput;
  ProjectSharedNotification: ProjectSharedNotification;
  ProjectSharedNotificationCondition: ProjectSharedNotificationCondition;
  ProjectSharedNotificationFilter: ProjectSharedNotificationFilter;
  ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInput: ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInput;
  ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInverseInput: ProjectSharedNotificationIdD5Cd7E61FkNotificationIdInverseInput;
  ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingProjectSharedNotificationPkeyUpdate: ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiUsingProjectSharedNotificationPkeyUpdate;
  ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingProjectSharedNotificationPkeyUpdate: ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiUsingProjectSharedNotificationPkeyUpdate;
  ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdUsingProjectSharedNotificationPkeyUpdate: ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdUsingProjectSharedNotificationPkeyUpdate;
  ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdUsingProjectSharedNotificationPkeyUpdate: ProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdUsingProjectSharedNotificationPkeyUpdate;
  ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInput: ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInput;
  ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput: ProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdInverseInput;
  ProjectSharedNotificationProjectSharedNotificationPkeyConnect: ProjectSharedNotificationProjectSharedNotificationPkeyConnect;
  ProjectSharedNotificationsConnection: ProjectSharedNotificationsConnection;
  ProjectSharedNotificationsEdge: ProjectSharedNotificationsEdge;
  ProjectTenantId03F0Ca53FkTenantIdInput: ProjectTenantId03F0Ca53FkTenantIdInput;
  ProjectTenantId03F0Ca53FkTenantIdInverseInput: ProjectTenantId03F0Ca53FkTenantIdInverseInput;
  ProjectTenantId03F0Ca53FkTenantIdTenantCreateInput: ProjectTenantId03F0Ca53FkTenantIdTenantCreateInput;
  ProjectToManyAnalysisTableFilter: ProjectToManyAnalysisTableFilter;
  ProjectToManyAnalysisTableGroupFilter: ProjectToManyAnalysisTableGroupFilter;
  ProjectToManyAnalysisTableRowAttachResultFilter: ProjectToManyAnalysisTableRowAttachResultFilter;
  ProjectToManyAnalysisTableRowFilter: ProjectToManyAnalysisTableRowFilter;
  ProjectToManyDatasetFilter: ProjectToManyDatasetFilter;
  ProjectToManyDatasetMetadatumFilter: ProjectToManyDatasetMetadatumFilter;
  ProjectToManyDatasetRecordingsTableColumnFilter: ProjectToManyDatasetRecordingsTableColumnFilter;
  ProjectToManyDatasetRecordingsTableColumnOrderFilter: ProjectToManyDatasetRecordingsTableColumnOrderFilter;
  ProjectToManyDatasetRecordingsTableFilter: ProjectToManyDatasetRecordingsTableFilter;
  ProjectToManyDatasetVersionFilter: ProjectToManyDatasetVersionFilter;
  ProjectToManyFileActivityFilter: ProjectToManyFileActivityFilter;
  ProjectToManyFileFilter: ProjectToManyFileFilter;
  ProjectToManyFileMetadatumFilter: ProjectToManyFileMetadatumFilter;
  ProjectToManyFileOutputGroupFilter: ProjectToManyFileOutputGroupFilter;
  ProjectToManyFileOutputGroupMetadatumFilter: ProjectToManyFileOutputGroupMetadatumFilter;
  ProjectToManyFileRecordingGroupFilter: ProjectToManyFileRecordingGroupFilter;
  ProjectToManyFileRecordingGroupMetadatumFilter: ProjectToManyFileRecordingGroupMetadatumFilter;
  ProjectToManyMetadataValueFilter: ProjectToManyMetadataValueFilter;
  ProjectToManyMetadatumFilter: ProjectToManyMetadatumFilter;
  ProjectToManyOutputGroupFileFilter: ProjectToManyOutputGroupFileFilter;
  ProjectToManyProjectClonedNotificationFilter: ProjectToManyProjectClonedNotificationFilter;
  ProjectToManyProjectSharedNotificationFilter: ProjectToManyProjectSharedNotificationFilter;
  ProjectToManyRecordingGroupFileFilter: ProjectToManyRecordingGroupFileFilter;
  ProjectToManyTaskFilter: ProjectToManyTaskFilter;
  ProjectUserIdB5Fbb914FkApplicationUserIdApplicationUserCreateInput: ProjectUserIdB5Fbb914FkApplicationUserIdApplicationUserCreateInput;
  ProjectUserIdB5Fbb914FkApplicationUserIdInput: ProjectUserIdB5Fbb914FkApplicationUserIdInput;
  ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput: ProjectUserIdB5Fbb914FkApplicationUserIdInverseInput;
  ProjectsConnection: ProjectsConnection;
  ProjectsEdge: ProjectsEdge;
  Query: {};
  RecordingGroupFile: RecordingGroupFile;
  RecordingGroupFileCondition: RecordingGroupFileCondition;
  RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRDatasetRecordingsTableColumnCreateInput: RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRDatasetRecordingsTableColumnCreateInput;
  RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput: RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInput;
  RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput: RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRInverseInput;
  RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRRecordingGroupFilesCreateInput: RecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRRecordingGroupFilesCreateInput;
  RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoFileRecordingGroupCreateInput: RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoFileRecordingGroupCreateInput;
  RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput: RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInput;
  RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput: RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoInverseInput;
  RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoRecordingGroupFilesCreateInput: RecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoRecordingGroupFilesCreateInput;
  RecordingGroupFileFilter: RecordingGroupFileFilter;
  RecordingGroupFileInput: RecordingGroupFileInput;
  RecordingGroupFileOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRUsingRecordingGroupFilesPkeyUpdate: RecordingGroupFileOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRUsingRecordingGroupFilesPkeyUpdate;
  RecordingGroupFileOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoUsingRecordingGroupFilesPkeyUpdate: RecordingGroupFileOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoUsingRecordingGroupFilesPkeyUpdate;
  RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdUsingRecordingGroupFilesPkeyUpdate: RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdUsingRecordingGroupFilesPkeyUpdate;
  RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdUsingRecordingGroupFilesPkeyUpdate: RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdUsingRecordingGroupFilesPkeyUpdate;
  RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingRecordingGroupFilesPkeyUpdate: RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingRecordingGroupFilesPkeyUpdate;
  RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingRecordingGroupFilesPkeyUpdate: RecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdUsingRecordingGroupFilesPkeyUpdate;
  RecordingGroupFilePatch: RecordingGroupFilePatch;
  RecordingGroupFileRecordingGroupFilesPkeyConnect: RecordingGroupFileRecordingGroupFilesPkeyConnect;
  RecordingGroupFileRecordingGroupFilesPkeyDelete: RecordingGroupFileRecordingGroupFilesPkeyDelete;
  RecordingGroupFilesConnection: RecordingGroupFilesConnection;
  RecordingGroupFilesEdge: RecordingGroupFilesEdge;
  RecordingGroupFilesFileIdD657E9EdFkFileIdFileCreateInput: RecordingGroupFilesFileIdD657E9EdFkFileIdFileCreateInput;
  RecordingGroupFilesFileIdD657E9EdFkFileIdInput: RecordingGroupFilesFileIdD657E9EdFkFileIdInput;
  RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput: RecordingGroupFilesFileIdD657E9EdFkFileIdInverseInput;
  RecordingGroupFilesFileIdD657E9EdFkFileIdRecordingGroupFilesCreateInput: RecordingGroupFilesFileIdD657E9EdFkFileIdRecordingGroupFilesCreateInput;
  RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput: RecordingGroupFilesProjectId3B6B53B3FkProjectIdInput;
  RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput: RecordingGroupFilesProjectId3B6B53B3FkProjectIdInverseInput;
  RecordingGroupFilesProjectId3B6B53B3FkProjectIdRecordingGroupFilesCreateInput: RecordingGroupFilesProjectId3B6B53B3FkProjectIdRecordingGroupFilesCreateInput;
  RecordingGroupFilesTenantId637C3013FkTenantIdInput: RecordingGroupFilesTenantId637C3013FkTenantIdInput;
  RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput: RecordingGroupFilesTenantId637C3013FkTenantIdInverseInput;
  RecordingGroupFilesTenantId637C3013FkTenantIdRecordingGroupFilesCreateInput: RecordingGroupFilesTenantId637C3013FkTenantIdRecordingGroupFilesCreateInput;
  RecordingGroupFilesTenantId637C3013FkTenantIdTenantCreateInput: RecordingGroupFilesTenantId637C3013FkTenantIdTenantCreateInput;
  RecordingGroupFilesUserId99A36997FkApplicationUserIdApplicationUserCreateInput: RecordingGroupFilesUserId99A36997FkApplicationUserIdApplicationUserCreateInput;
  RecordingGroupFilesUserId99A36997FkApplicationUserIdInput: RecordingGroupFilesUserId99A36997FkApplicationUserIdInput;
  RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput: RecordingGroupFilesUserId99A36997FkApplicationUserIdInverseInput;
  RecordingGroupFilesUserId99A36997FkApplicationUserIdRecordingGroupFilesCreateInput: RecordingGroupFilesUserId99A36997FkApplicationUserIdRecordingGroupFilesCreateInput;
  RoleChangeNotificaUserId879Bd49AFkApplicatiApplicationUserCreateInput: RoleChangeNotificaUserId879Bd49AFkApplicatiApplicationUserCreateInput;
  RoleChangeNotificaUserId879Bd49AFkApplicatiInput: RoleChangeNotificaUserId879Bd49AFkApplicatiInput;
  RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput: RoleChangeNotificaUserId879Bd49AFkApplicatiInverseInput;
  RoleChangeNotification: RoleChangeNotification;
  RoleChangeNotificationCondition: RoleChangeNotificationCondition;
  RoleChangeNotificationFilter: RoleChangeNotificationFilter;
  RoleChangeNotificationIdD576A528FkNotificationIdInput: RoleChangeNotificationIdD576A528FkNotificationIdInput;
  RoleChangeNotificationIdD576A528FkNotificationIdInverseInput: RoleChangeNotificationIdD576A528FkNotificationIdInverseInput;
  RoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingRoleChangeNotificationPkeyUpdate: RoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiUsingRoleChangeNotificationPkeyUpdate;
  RoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdUsingRoleChangeNotificationPkeyUpdate: RoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdUsingRoleChangeNotificationPkeyUpdate;
  RoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingRoleChangeNotificationPkeyUpdate: RoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingRoleChangeNotificationPkeyUpdate;
  RoleChangeNotificationRoleChangeNotificationPkeyConnect: RoleChangeNotificationRoleChangeNotificationPkeyConnect;
  RoleChangeNotificationTenantId36852Ce9FkTenantIdInput: RoleChangeNotificationTenantId36852Ce9FkTenantIdInput;
  RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput: RoleChangeNotificationTenantId36852Ce9FkTenantIdInverseInput;
  RoleChangeNotificationTenantId36852Ce9FkTenantIdTenantCreateInput: RoleChangeNotificationTenantId36852Ce9FkTenantIdTenantCreateInput;
  RoleChangeNotificationsConnection: RoleChangeNotificationsConnection;
  RoleChangeNotificationsEdge: RoleChangeNotificationsEdge;
  Run: Run;
  RunCondition: RunCondition;
  RunFilter: RunFilter;
  RunInput: RunInput;
  RunOnRunForRunTenantId73Fbd8D9FkTenantIdUsingRunPkeyUpdate: RunOnRunForRunTenantId73Fbd8D9FkTenantIdUsingRunPkeyUpdate;
  RunPatch: RunPatch;
  RunRunPkeyConnect: RunRunPkeyConnect;
  RunRunPkeyDelete: RunRunPkeyDelete;
  RunTenantId73Fbd8D9FkTenantIdInput: RunTenantId73Fbd8D9FkTenantIdInput;
  RunTenantId73Fbd8D9FkTenantIdInverseInput: RunTenantId73Fbd8D9FkTenantIdInverseInput;
  RunTenantId73Fbd8D9FkTenantIdRunCreateInput: RunTenantId73Fbd8D9FkTenantIdRunCreateInput;
  RunTenantId73Fbd8D9FkTenantIdTenantCreateInput: RunTenantId73Fbd8D9FkTenantIdTenantCreateInput;
  RunsConnection: RunsConnection;
  RunsEdge: RunsEdge;
  String: Scalars["String"]["output"];
  StringFilter: StringFilter;
  Subscription: {};
  Tag: Tag;
  TagCondition: TagCondition;
  TagFilter: TagFilter;
  TagInput: TagInput;
  TagOnTagForTagParentIdD2A3Ee5EFkTagIdUsingTagPkeyUpdate: TagOnTagForTagParentIdD2A3Ee5EFkTagIdUsingTagPkeyUpdate;
  TagOnTagForTagTenantId8072B61FFkTenantIdUsingTagPkeyUpdate: TagOnTagForTagTenantId8072B61FFkTenantIdUsingTagPkeyUpdate;
  TagOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingTagPkeyUpdate: TagOnTagForTagUserIdB3B7Fb50FkApplicationUserIdUsingTagPkeyUpdate;
  TagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdUsingTagPkeyUpdate: TagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdUsingTagPkeyUpdate;
  TagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdUsingTagPkeyUpdate: TagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdUsingTagPkeyUpdate;
  TagParentIdD2A3Ee5EFkTagIdInput: TagParentIdD2A3Ee5EFkTagIdInput;
  TagParentIdD2A3Ee5EFkTagIdTagCreateInput: TagParentIdD2A3Ee5EFkTagIdTagCreateInput;
  TagPatch: TagPatch;
  TagTagPkeyConnect: TagTagPkeyConnect;
  TagTagPkeyDelete: TagTagPkeyDelete;
  TagTenantId8072B61FFkTenantIdInput: TagTenantId8072B61FFkTenantIdInput;
  TagTenantId8072B61FFkTenantIdInverseInput: TagTenantId8072B61FFkTenantIdInverseInput;
  TagTenantId8072B61FFkTenantIdTagCreateInput: TagTenantId8072B61FFkTenantIdTagCreateInput;
  TagTenantId8072B61FFkTenantIdTenantCreateInput: TagTenantId8072B61FFkTenantIdTenantCreateInput;
  TagToManyTagFilter: TagToManyTagFilter;
  TagToManyWorkflowResultTagFilter: TagToManyWorkflowResultTagFilter;
  TagToManyWorkflowSourceTagFilter: TagToManyWorkflowSourceTagFilter;
  TagUserIdB3B7Fb50FkApplicationUserIdApplicationUserCreateInput: TagUserIdB3B7Fb50FkApplicationUserIdApplicationUserCreateInput;
  TagUserIdB3B7Fb50FkApplicationUserIdInput: TagUserIdB3B7Fb50FkApplicationUserIdInput;
  TagUserIdB3B7Fb50FkApplicationUserIdInverseInput: TagUserIdB3B7Fb50FkApplicationUserIdInverseInput;
  TagUserIdB3B7Fb50FkApplicationUserIdTagCreateInput: TagUserIdB3B7Fb50FkApplicationUserIdTagCreateInput;
  TagsConnection: TagsConnection;
  TagsEdge: TagsEdge;
  Task: Task;
  TaskActivitiesConnection: TaskActivitiesConnection;
  TaskActivitiesEdge: TaskActivitiesEdge;
  TaskActivity: TaskActivity;
  TaskActivityCondition: TaskActivityCondition;
  TaskActivityCostId49E66058FkCostIdCostCreateInput: TaskActivityCostId49E66058FkCostIdCostCreateInput;
  TaskActivityCostId49E66058FkCostIdInput: TaskActivityCostId49E66058FkCostIdInput;
  TaskActivityCostId49E66058FkCostIdInverseInput: TaskActivityCostId49E66058FkCostIdInverseInput;
  TaskActivityCostId49E66058FkCostIdTaskActivityCreateInput: TaskActivityCostId49E66058FkCostIdTaskActivityCreateInput;
  TaskActivityFilter: TaskActivityFilter;
  TaskActivityInput: TaskActivityInput;
  TaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdUsingTaskActivityPkeyUpdate: TaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdUsingTaskActivityPkeyUpdate;
  TaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdUsingTaskActivityTaskIdKeyUpdate: TaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdUsingTaskActivityTaskIdKeyUpdate;
  TaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskActivityPkeyUpdate: TaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskActivityPkeyUpdate;
  TaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskActivityTaskIdKeyUpdate: TaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskActivityTaskIdKeyUpdate;
  TaskActivityPatch: TaskActivityPatch;
  TaskActivityTaskActivityPkeyConnect: TaskActivityTaskActivityPkeyConnect;
  TaskActivityTaskActivityPkeyDelete: TaskActivityTaskActivityPkeyDelete;
  TaskActivityTaskActivityTaskIdKeyConnect: TaskActivityTaskActivityTaskIdKeyConnect;
  TaskActivityTaskActivityTaskIdKeyDelete: TaskActivityTaskActivityTaskIdKeyDelete;
  TaskActivityTaskIdC451Ae92FkTaskIdInput: TaskActivityTaskIdC451Ae92FkTaskIdInput;
  TaskActivityTaskIdC451Ae92FkTaskIdInverseInput: TaskActivityTaskIdC451Ae92FkTaskIdInverseInput;
  TaskActivityTaskIdC451Ae92FkTaskIdTaskActivityCreateInput: TaskActivityTaskIdC451Ae92FkTaskIdTaskActivityCreateInput;
  TaskActivityTaskIdC451Ae92FkTaskIdTaskCreateInput: TaskActivityTaskIdC451Ae92FkTaskIdTaskCreateInput;
  TaskCondition: TaskCondition;
  TaskContainerImageId92Ed2DaeFkContainerImageIdContainerImageCreateInput: TaskContainerImageId92Ed2DaeFkContainerImageIdContainerImageCreateInput;
  TaskContainerImageId92Ed2DaeFkContainerImageIdInput: TaskContainerImageId92Ed2DaeFkContainerImageIdInput;
  TaskContainerImageId92Ed2DaeFkContainerImageIdInverseInput: TaskContainerImageId92Ed2DaeFkContainerImageIdInverseInput;
  TaskContainerImageId92Ed2DaeFkContainerImageIdTaskCreateInput: TaskContainerImageId92Ed2DaeFkContainerImageIdTaskCreateInput;
  TaskDatasetIdE15Afa5AFkDatasetIdDatasetCreateInput: TaskDatasetIdE15Afa5AFkDatasetIdDatasetCreateInput;
  TaskDatasetIdE15Afa5AFkDatasetIdInput: TaskDatasetIdE15Afa5AFkDatasetIdInput;
  TaskDatasetIdE15Afa5AFkDatasetIdInverseInput: TaskDatasetIdE15Afa5AFkDatasetIdInverseInput;
  TaskDatasetIdE15Afa5AFkDatasetIdTaskCreateInput: TaskDatasetIdE15Afa5AFkDatasetIdTaskCreateInput;
  TaskExecutorId24A67329FkTesTaskexecutorIdInput: TaskExecutorId24A67329FkTesTaskexecutorIdInput;
  TaskExecutorId24A67329FkTesTaskexecutorIdInverseInput: TaskExecutorId24A67329FkTesTaskexecutorIdInverseInput;
  TaskExecutorId24A67329FkTesTaskexecutorIdTaskCreateInput: TaskExecutorId24A67329FkTesTaskexecutorIdTaskCreateInput;
  TaskExecutorId24A67329FkTesTaskexecutorIdTesTaskexecutorCreateInput: TaskExecutorId24A67329FkTesTaskexecutorIdTesTaskexecutorCreateInput;
  TaskFilter: TaskFilter;
  TaskInput: TaskInput;
  TaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingTaskExecutorIdKeyUpdate: TaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingTaskExecutorIdKeyUpdate;
  TaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingTaskPkeyUpdate: TaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdUsingTaskPkeyUpdate;
  TaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingTaskExecutorIdKeyUpdate: TaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingTaskExecutorIdKeyUpdate;
  TaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingTaskPkeyUpdate: TaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdUsingTaskPkeyUpdate;
  TaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdUsingTaskExecutorIdKeyUpdate: TaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdUsingTaskExecutorIdKeyUpdate;
  TaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdUsingTaskPkeyUpdate: TaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdUsingTaskPkeyUpdate;
  TaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskPkeyUpdate: TaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdUsingTaskPkeyUpdate;
  TaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingTaskPkeyUpdate: TaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdUsingTaskPkeyUpdate;
  TaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingTaskPkeyUpdate: TaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdUsingTaskPkeyUpdate;
  TaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdUsingTaskPkeyUpdate: TaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdUsingTaskPkeyUpdate;
  TaskOnTaskForTaskProjectId963D6354FkProjectIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskForTaskProjectId963D6354FkProjectIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskForTaskProjectId963D6354FkProjectIdUsingTaskPkeyUpdate: TaskOnTaskForTaskProjectId963D6354FkProjectIdUsingTaskPkeyUpdate;
  TaskOnTaskForTaskTenantId148E90DaFkTenantIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskForTaskTenantId148E90DaFkTenantIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskForTaskTenantId148E90DaFkTenantIdUsingTaskPkeyUpdate: TaskOnTaskForTaskTenantId148E90DaFkTenantIdUsingTaskPkeyUpdate;
  TaskOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingTaskPkeyUpdate: TaskOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingTaskPkeyUpdate;
  TaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingTaskPkeyUpdate: TaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdUsingTaskPkeyUpdate;
  TaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdUsingTaskPkeyUpdate: TaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdUsingTaskPkeyUpdate;
  TaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdUsingTaskPkeyUpdate: TaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdUsingTaskPkeyUpdate;
  TaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskExecutorIdKeyUpdate: TaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskExecutorIdKeyUpdate;
  TaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskPkeyUpdate: TaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskPkeyUpdate;
  TaskPatch: TaskPatch;
  TaskProjectId963D6354FkProjectIdInput: TaskProjectId963D6354FkProjectIdInput;
  TaskProjectId963D6354FkProjectIdInverseInput: TaskProjectId963D6354FkProjectIdInverseInput;
  TaskProjectId963D6354FkProjectIdTaskCreateInput: TaskProjectId963D6354FkProjectIdTaskCreateInput;
  TaskResource: TaskResource;
  TaskResourceCondition: TaskResourceCondition;
  TaskResourceFilter: TaskResourceFilter;
  TaskResourceInput: TaskResourceInput;
  TaskResourceOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdUsingTaskResourcePkeyUpdate: TaskResourceOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdUsingTaskResourcePkeyUpdate;
  TaskResourcePatch: TaskResourcePatch;
  TaskResourceTaskId73Fcabc0FkTaskIdInput: TaskResourceTaskId73Fcabc0FkTaskIdInput;
  TaskResourceTaskId73Fcabc0FkTaskIdInverseInput: TaskResourceTaskId73Fcabc0FkTaskIdInverseInput;
  TaskResourceTaskId73Fcabc0FkTaskIdTaskCreateInput: TaskResourceTaskId73Fcabc0FkTaskIdTaskCreateInput;
  TaskResourceTaskId73Fcabc0FkTaskIdTaskResourceCreateInput: TaskResourceTaskId73Fcabc0FkTaskIdTaskResourceCreateInput;
  TaskResourceTaskResourcePkeyConnect: TaskResourceTaskResourcePkeyConnect;
  TaskResourceTaskResourcePkeyDelete: TaskResourceTaskResourcePkeyDelete;
  TaskResourcesConnection: TaskResourcesConnection;
  TaskResourcesEdge: TaskResourcesEdge;
  TaskSource: TaskSource;
  TaskSourceCondition: TaskSourceCondition;
  TaskSourceFilter: TaskSourceFilter;
  TaskSourceInput: TaskSourceInput;
  TaskSourceOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdUsingTaskSourceV4PkeyUpdate: TaskSourceOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdUsingTaskSourceV4PkeyUpdate;
  TaskSourceOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdUsingTaskSourceV4PkeyUpdate: TaskSourceOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdUsingTaskSourceV4PkeyUpdate;
  TaskSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdUsingTaskSourceV4PkeyUpdate: TaskSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdUsingTaskSourceV4PkeyUpdate;
  TaskSourcePatch: TaskSourcePatch;
  TaskSourceTaskSourceV4PkeyConnect: TaskSourceTaskSourceV4PkeyConnect;
  TaskSourceTaskSourceV4PkeyDelete: TaskSourceTaskSourceV4PkeyDelete;
  TaskSourceV4FileId3A4723EfFkFileIdFileCreateInput: TaskSourceV4FileId3A4723EfFkFileIdFileCreateInput;
  TaskSourceV4FileId3A4723EfFkFileIdInput: TaskSourceV4FileId3A4723EfFkFileIdInput;
  TaskSourceV4FileId3A4723EfFkFileIdInverseInput: TaskSourceV4FileId3A4723EfFkFileIdInverseInput;
  TaskSourceV4FileId3A4723EfFkFileIdTaskSourceCreateInput: TaskSourceV4FileId3A4723EfFkFileIdTaskSourceCreateInput;
  TaskSourceV4TaskId059F1B6BFkTaskIdInput: TaskSourceV4TaskId059F1B6BFkTaskIdInput;
  TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput: TaskSourceV4TaskId059F1B6BFkTaskIdInverseInput;
  TaskSourceV4TaskId059F1B6BFkTaskIdTaskCreateInput: TaskSourceV4TaskId059F1B6BFkTaskIdTaskCreateInput;
  TaskSourceV4TaskId059F1B6BFkTaskIdTaskSourceCreateInput: TaskSourceV4TaskId059F1B6BFkTaskIdTaskSourceCreateInput;
  TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInput: TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInput;
  TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInverseInput: TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdInverseInput;
  TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdTaskSourceCreateInput: TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdTaskSourceCreateInput;
  TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdToolSourceCreateInput: TaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdToolSourceCreateInput;
  TaskSourcesConnection: TaskSourcesConnection;
  TaskSourcesEdge: TaskSourcesEdge;
  TaskStatusLog: TaskStatusLog;
  TaskStatusLogCondition: TaskStatusLogCondition;
  TaskStatusLogFilter: TaskStatusLogFilter;
  TaskStatusLogInput: TaskStatusLogInput;
  TaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskStatusLogPkeyUpdate: TaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskStatusLogPkeyUpdate;
  TaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskStatusLogUniqueStatusPerTaskUpdate: TaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdUsingTaskStatusLogUniqueStatusPerTaskUpdate;
  TaskStatusLogPatch: TaskStatusLogPatch;
  TaskStatusLogTaskIdFe1B9D0BFkTaskIdInput: TaskStatusLogTaskIdFe1B9D0BFkTaskIdInput;
  TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput: TaskStatusLogTaskIdFe1B9D0BFkTaskIdInverseInput;
  TaskStatusLogTaskIdFe1B9D0BFkTaskIdTaskCreateInput: TaskStatusLogTaskIdFe1B9D0BFkTaskIdTaskCreateInput;
  TaskStatusLogTaskIdFe1B9D0BFkTaskIdTaskStatusLogCreateInput: TaskStatusLogTaskIdFe1B9D0BFkTaskIdTaskStatusLogCreateInput;
  TaskStatusLogTaskStatusLogPkeyConnect: TaskStatusLogTaskStatusLogPkeyConnect;
  TaskStatusLogTaskStatusLogPkeyDelete: TaskStatusLogTaskStatusLogPkeyDelete;
  TaskStatusLogTaskStatusLogUniqueStatusPerTaskConnect: TaskStatusLogTaskStatusLogUniqueStatusPerTaskConnect;
  TaskStatusLogTaskStatusLogUniqueStatusPerTaskDelete: TaskStatusLogTaskStatusLogUniqueStatusPerTaskDelete;
  TaskStatusLogsConnection: TaskStatusLogsConnection;
  TaskStatusLogsEdge: TaskStatusLogsEdge;
  TaskTaskExecutorIdKeyConnect: TaskTaskExecutorIdKeyConnect;
  TaskTaskExecutorIdKeyDelete: TaskTaskExecutorIdKeyDelete;
  TaskTaskPkeyConnect: TaskTaskPkeyConnect;
  TaskTaskPkeyDelete: TaskTaskPkeyDelete;
  TaskTenantId148E90DaFkTenantIdInput: TaskTenantId148E90DaFkTenantIdInput;
  TaskTenantId148E90DaFkTenantIdInverseInput: TaskTenantId148E90DaFkTenantIdInverseInput;
  TaskTenantId148E90DaFkTenantIdTaskCreateInput: TaskTenantId148E90DaFkTenantIdTaskCreateInput;
  TaskTenantId148E90DaFkTenantIdTenantCreateInput: TaskTenantId148E90DaFkTenantIdTenantCreateInput;
  TaskToManyMetadataValueFilter: TaskToManyMetadataValueFilter;
  TaskToManyTaskResourceFilter: TaskToManyTaskResourceFilter;
  TaskToManyTaskSourceFilter: TaskToManyTaskSourceFilter;
  TaskToManyTaskStatusLogFilter: TaskToManyTaskStatusLogFilter;
  TaskToolVersionId14506837FkToolVersionIdInput: TaskToolVersionId14506837FkToolVersionIdInput;
  TaskToolVersionId14506837FkToolVersionIdInverseInput: TaskToolVersionId14506837FkToolVersionIdInverseInput;
  TaskToolVersionId14506837FkToolVersionIdTaskCreateInput: TaskToolVersionId14506837FkToolVersionIdTaskCreateInput;
  TaskToolVersionId14506837FkToolVersionIdToolVersionCreateInput: TaskToolVersionId14506837FkToolVersionIdToolVersionCreateInput;
  TaskUserId270D0Bb2FkApplicationUserIdApplicationUserCreateInput: TaskUserId270D0Bb2FkApplicationUserIdApplicationUserCreateInput;
  TaskUserId270D0Bb2FkApplicationUserIdInput: TaskUserId270D0Bb2FkApplicationUserIdInput;
  TaskUserId270D0Bb2FkApplicationUserIdInverseInput: TaskUserId270D0Bb2FkApplicationUserIdInverseInput;
  TaskUserId270D0Bb2FkApplicationUserIdTaskCreateInput: TaskUserId270D0Bb2FkApplicationUserIdTaskCreateInput;
  TasksConnection: TasksConnection;
  TasksEdge: TasksEdge;
  Tenant: Tenant;
  TenantContract: TenantContract;
  TenantContractAlloTenantContractTran29C19B1CFkTenantCoInput: TenantContractAlloTenantContractTran29C19B1CFkTenantCoInput;
  TenantContractAlloTenantContractTran29C19B1CFkTenantCoInverseInput: TenantContractAlloTenantContractTran29C19B1CFkTenantCoInverseInput;
  TenantContractAlloTenantContractTran29C19B1CFkTenantCoTenantContractAllowanceCreateInput: TenantContractAlloTenantContractTran29C19B1CFkTenantCoTenantContractAllowanceCreateInput;
  TenantContractAlloTenantContractTran29C19B1CFkTenantCoTenantContractTransactionCreateInput: TenantContractAlloTenantContractTran29C19B1CFkTenantCoTenantContractTransactionCreateInput;
  TenantContractAllowance: TenantContractAllowance;
  TenantContractAllowanceCondition: TenantContractAllowanceCondition;
  TenantContractAllowanceFilter: TenantContractAllowanceFilter;
  TenantContractAllowanceInput: TenantContractAllowanceInput;
  TenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoUsingTenantContractAllowancePkeyUpdate: TenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoUsingTenantContractAllowancePkeyUpdate;
  TenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoUsingTenantContractTransactionQuotaTypeUniqueUpdate: TenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoUsingTenantContractTransactionQuotaTypeUniqueUpdate;
  TenantContractAllowancePatch: TenantContractAllowancePatch;
  TenantContractAllowanceTenantContractAllowancePkeyConnect: TenantContractAllowanceTenantContractAllowancePkeyConnect;
  TenantContractAllowanceTenantContractAllowancePkeyDelete: TenantContractAllowanceTenantContractAllowancePkeyDelete;
  TenantContractAllowanceTenantContractTransactionQuotaTypeUniqueConnect: TenantContractAllowanceTenantContractTransactionQuotaTypeUniqueConnect;
  TenantContractAllowanceTenantContractTransactionQuotaTypeUniqueDelete: TenantContractAllowanceTenantContractTransactionQuotaTypeUniqueDelete;
  TenantContractAllowancesConnection: TenantContractAllowancesConnection;
  TenantContractAllowancesEdge: TenantContractAllowancesEdge;
  TenantContractCondition: TenantContractCondition;
  TenantContractFilter: TenantContractFilter;
  TenantContractInput: TenantContractInput;
  TenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantContractNameKeyUpdate: TenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantContractNameKeyUpdate;
  TenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantContractPkeyUpdate: TenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantContractPkeyUpdate;
  TenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoUsingTenantContractNameKeyUpdate: TenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoUsingTenantContractNameKeyUpdate;
  TenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoUsingTenantContractPkeyUpdate: TenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoUsingTenantContractPkeyUpdate;
  TenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantContractNameKeyUpdate: TenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantContractNameKeyUpdate;
  TenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantContractPkeyUpdate: TenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantContractPkeyUpdate;
  TenantContractPatch: TenantContractPatch;
  TenantContractTenantContractNameKeyConnect: TenantContractTenantContractNameKeyConnect;
  TenantContractTenantContractNameKeyDelete: TenantContractTenantContractNameKeyDelete;
  TenantContractTenantContractPkeyConnect: TenantContractTenantContractPkeyConnect;
  TenantContractTenantContractPkeyDelete: TenantContractTenantContractPkeyDelete;
  TenantContractTenantIdBa8Ac167FkTenantIdInput: TenantContractTenantIdBa8Ac167FkTenantIdInput;
  TenantContractTenantIdBa8Ac167FkTenantIdInverseInput: TenantContractTenantIdBa8Ac167FkTenantIdInverseInput;
  TenantContractTenantIdBa8Ac167FkTenantIdTenantContractCreateInput: TenantContractTenantIdBa8Ac167FkTenantIdTenantContractCreateInput;
  TenantContractTenantIdBa8Ac167FkTenantIdTenantCreateInput: TenantContractTenantIdBa8Ac167FkTenantIdTenantCreateInput;
  TenantContractToManyTenantContractTransactionFilter: TenantContractToManyTenantContractTransactionFilter;
  TenantContractToManyTenantMonthlyConsumedResourceFilter: TenantContractToManyTenantMonthlyConsumedResourceFilter;
  TenantContractTranTenantContractId1E374993FkTenantCoInput: TenantContractTranTenantContractId1E374993FkTenantCoInput;
  TenantContractTranTenantContractId1E374993FkTenantCoInverseInput: TenantContractTranTenantContractId1E374993FkTenantCoInverseInput;
  TenantContractTranTenantContractId1E374993FkTenantCoTenantContractCreateInput: TenantContractTranTenantContractId1E374993FkTenantCoTenantContractCreateInput;
  TenantContractTranTenantContractId1E374993FkTenantCoTenantContractTransactionCreateInput: TenantContractTranTenantContractId1E374993FkTenantCoTenantContractTransactionCreateInput;
  TenantContractTransaction: TenantContractTransaction;
  TenantContractTransactionCondition: TenantContractTransactionCondition;
  TenantContractTransactionFilter: TenantContractTransactionFilter;
  TenantContractTransactionInput: TenantContractTransactionInput;
  TenantContractTransactionOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoUsingTenantContractTransactionPkeyUpdate: TenantContractTransactionOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoUsingTenantContractTransactionPkeyUpdate;
  TenantContractTransactionOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoUsingTenantContractTransactionPkeyUpdate: TenantContractTransactionOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoUsingTenantContractTransactionPkeyUpdate;
  TenantContractTransactionPatch: TenantContractTransactionPatch;
  TenantContractTransactionTenantContractTransactionPkeyConnect: TenantContractTransactionTenantContractTransactionPkeyConnect;
  TenantContractTransactionTenantContractTransactionPkeyDelete: TenantContractTransactionTenantContractTransactionPkeyDelete;
  TenantContractTransactionToManyTenantContractAllowanceFilter: TenantContractTransactionToManyTenantContractAllowanceFilter;
  TenantContractTransactionsConnection: TenantContractTransactionsConnection;
  TenantContractTransactionsEdge: TenantContractTransactionsEdge;
  TenantContractsConnection: TenantContractsConnection;
  TenantContractsEdge: TenantContractsEdge;
  TenantFilter: TenantFilter;
  TenantInput: TenantInput;
  TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInput: TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInput;
  TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInverseInput: TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsInverseInput;
  TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsTenantMockuserexecutorCreateInput: TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsTenantMockuserexecutorCreateInput;
  TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsTenantUserexecutorCreateInput: TenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsTenantUserexecutorCreateInput;
  TenantMockuserexecutor: TenantMockuserexecutor;
  TenantMockuserexecutorCondition: TenantMockuserexecutorCondition;
  TenantMockuserexecutorFilter: TenantMockuserexecutorFilter;
  TenantMockuserexecutorInput: TenantMockuserexecutorInput;
  TenantMockuserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsUsingTenantMockuserexecutorPkeyUpdate: TenantMockuserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsUsingTenantMockuserexecutorPkeyUpdate;
  TenantMockuserexecutorPatch: TenantMockuserexecutorPatch;
  TenantMockuserexecutorTenantMockuserexecutorPkeyConnect: TenantMockuserexecutorTenantMockuserexecutorPkeyConnect;
  TenantMockuserexecutorTenantMockuserexecutorPkeyDelete: TenantMockuserexecutorTenantMockuserexecutorPkeyDelete;
  TenantMockuserexecutorsConnection: TenantMockuserexecutorsConnection;
  TenantMockuserexecutorsEdge: TenantMockuserexecutorsEdge;
  TenantMonthlyConsuTenantContractId681267CeFkTenantCoInput: TenantMonthlyConsuTenantContractId681267CeFkTenantCoInput;
  TenantMonthlyConsuTenantContractId681267CeFkTenantCoInverseInput: TenantMonthlyConsuTenantContractId681267CeFkTenantCoInverseInput;
  TenantMonthlyConsuTenantContractId681267CeFkTenantCoTenantContractCreateInput: TenantMonthlyConsuTenantContractId681267CeFkTenantCoTenantContractCreateInput;
  TenantMonthlyConsuTenantContractId681267CeFkTenantCoTenantMonthlyConsumedResourcesCreateInput: TenantMonthlyConsuTenantContractId681267CeFkTenantCoTenantMonthlyConsumedResourcesCreateInput;
  TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInput: TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInput;
  TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput: TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdInverseInput;
  TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdTenantCreateInput: TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdTenantCreateInput;
  TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdTenantMonthlyConsumedResourcesCreateInput: TenantMonthlyConsuTenantIdCb1B80FcFkTenantIdTenantMonthlyConsumedResourcesCreateInput;
  TenantMonthlyConsuUserIdF7210F55FkApplicatiApplicationUserCreateInput: TenantMonthlyConsuUserIdF7210F55FkApplicatiApplicationUserCreateInput;
  TenantMonthlyConsuUserIdF7210F55FkApplicatiInput: TenantMonthlyConsuUserIdF7210F55FkApplicatiInput;
  TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput: TenantMonthlyConsuUserIdF7210F55FkApplicatiInverseInput;
  TenantMonthlyConsuUserIdF7210F55FkApplicatiTenantMonthlyConsumedResourcesCreateInput: TenantMonthlyConsuUserIdF7210F55FkApplicatiTenantMonthlyConsumedResourcesCreateInput;
  TenantMonthlyConsumedResource: TenantMonthlyConsumedResource;
  TenantMonthlyConsumedResourceCondition: TenantMonthlyConsumedResourceCondition;
  TenantMonthlyConsumedResourceFilter: TenantMonthlyConsumedResourceFilter;
  TenantMonthlyConsumedResourceInput: TenantMonthlyConsumedResourceInput;
  TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantMonthlyConsumedResourcesPkeyUpdate: TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantMonthlyConsumedResourcesPkeyUpdate;
  TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantMonthlyConsumedResourcesUniqueUpdate: TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoUsingTenantMonthlyConsumedResourcesUniqueUpdate;
  TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantMonthlyConsumedResourcesPkeyUpdate: TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantMonthlyConsumedResourcesPkeyUpdate;
  TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantMonthlyConsumedResourcesUniqueUpdate: TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantMonthlyConsumedResourcesUniqueUpdate;
  TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingTenantMonthlyConsumedResourcesPkeyUpdate: TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingTenantMonthlyConsumedResourcesPkeyUpdate;
  TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingTenantMonthlyConsumedResourcesUniqueUpdate: TenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiUsingTenantMonthlyConsumedResourcesUniqueUpdate;
  TenantMonthlyConsumedResourcePatch: TenantMonthlyConsumedResourcePatch;
  TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesPkeyConnect: TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesPkeyConnect;
  TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesPkeyDelete: TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesPkeyDelete;
  TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesUniqueConnect: TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesUniqueConnect;
  TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesUniqueDelete: TenantMonthlyConsumedResourceTenantMonthlyConsumedResourcesUniqueDelete;
  TenantMonthlyConsumedResourcesConnection: TenantMonthlyConsumedResourcesConnection;
  TenantMonthlyConsumedResourcesEdge: TenantMonthlyConsumedResourcesEdge;
  TenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingTenantKeyKeyUpdate: TenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingTenantNameKeyUpdate: TenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingTenantPkeyUpdate: TenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdUsingTenantPkeyUpdate;
  TenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingTenantKeyKeyUpdate: TenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingTenantNameKeyUpdate: TenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingTenantPkeyUpdate: TenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdUsingTenantPkeyUpdate;
  TenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingTenantKeyKeyUpdate: TenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingTenantNameKeyUpdate: TenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingTenantPkeyUpdate: TenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdUsingTenantPkeyUpdate;
  TenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingTenantKeyKeyUpdate: TenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingTenantNameKeyUpdate: TenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingTenantPkeyUpdate: TenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdUsingTenantPkeyUpdate;
  TenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingTenantKeyKeyUpdate: TenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingTenantNameKeyUpdate: TenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingTenantPkeyUpdate: TenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdUsingTenantPkeyUpdate;
  TenantOnCostForCostTenantId7Dcd58A7FkTenantIdUsingTenantKeyKeyUpdate: TenantOnCostForCostTenantId7Dcd58A7FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnCostForCostTenantId7Dcd58A7FkTenantIdUsingTenantNameKeyUpdate: TenantOnCostForCostTenantId7Dcd58A7FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnCostForCostTenantId7Dcd58A7FkTenantIdUsingTenantPkeyUpdate: TenantOnCostForCostTenantId7Dcd58A7FkTenantIdUsingTenantPkeyUpdate;
  TenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingTenantKeyKeyUpdate: TenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingTenantNameKeyUpdate: TenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingTenantPkeyUpdate: TenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdUsingTenantPkeyUpdate;
  TenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingTenantKeyKeyUpdate: TenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingTenantNameKeyUpdate: TenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingTenantPkeyUpdate: TenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdUsingTenantPkeyUpdate;
  TenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingTenantKeyKeyUpdate: TenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingTenantNameKeyUpdate: TenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingTenantPkeyUpdate: TenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdUsingTenantPkeyUpdate;
  TenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingTenantKeyKeyUpdate: TenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingTenantNameKeyUpdate: TenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingTenantPkeyUpdate: TenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdUsingTenantPkeyUpdate;
  TenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingTenantKeyKeyUpdate: TenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingTenantNameKeyUpdate: TenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingTenantPkeyUpdate: TenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdUsingTenantPkeyUpdate;
  TenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingTenantKeyKeyUpdate: TenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingTenantNameKeyUpdate: TenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingTenantPkeyUpdate: TenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdUsingTenantPkeyUpdate;
  TenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingTenantKeyKeyUpdate: TenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingTenantNameKeyUpdate: TenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingTenantPkeyUpdate: TenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdUsingTenantPkeyUpdate;
  TenantOnFileForFileTenantId61625016FkTenantIdUsingTenantKeyKeyUpdate: TenantOnFileForFileTenantId61625016FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnFileForFileTenantId61625016FkTenantIdUsingTenantNameKeyUpdate: TenantOnFileForFileTenantId61625016FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnFileForFileTenantId61625016FkTenantIdUsingTenantPkeyUpdate: TenantOnFileForFileTenantId61625016FkTenantIdUsingTenantPkeyUpdate;
  TenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingTenantKeyKeyUpdate: TenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingTenantNameKeyUpdate: TenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingTenantPkeyUpdate: TenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdUsingTenantPkeyUpdate;
  TenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingTenantKeyKeyUpdate: TenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingTenantNameKeyUpdate: TenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingTenantPkeyUpdate: TenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdUsingTenantPkeyUpdate;
  TenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingTenantKeyKeyUpdate: TenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingTenantNameKeyUpdate: TenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingTenantPkeyUpdate: TenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdUsingTenantPkeyUpdate;
  TenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingTenantKeyKeyUpdate: TenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingTenantNameKeyUpdate: TenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingTenantPkeyUpdate: TenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdUsingTenantPkeyUpdate;
  TenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingTenantKeyKeyUpdate: TenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingTenantNameKeyUpdate: TenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingTenantPkeyUpdate: TenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdUsingTenantPkeyUpdate;
  TenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingTenantKeyKeyUpdate: TenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingTenantNameKeyUpdate: TenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingTenantPkeyUpdate: TenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdUsingTenantPkeyUpdate;
  TenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingTenantKeyKeyUpdate: TenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingTenantNameKeyUpdate: TenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingTenantPkeyUpdate: TenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdUsingTenantPkeyUpdate;
  TenantOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingTenantKeyKeyUpdate: TenantOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingTenantNameKeyUpdate: TenantOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingTenantPkeyUpdate: TenantOnProjectForProjectTenantId03F0Ca53FkTenantIdUsingTenantPkeyUpdate;
  TenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingTenantKeyKeyUpdate: TenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingTenantNameKeyUpdate: TenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingTenantPkeyUpdate: TenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdUsingTenantPkeyUpdate;
  TenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingTenantKeyKeyUpdate: TenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingTenantNameKeyUpdate: TenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingTenantPkeyUpdate: TenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdUsingTenantPkeyUpdate;
  TenantOnRunForRunTenantId73Fbd8D9FkTenantIdUsingTenantKeyKeyUpdate: TenantOnRunForRunTenantId73Fbd8D9FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnRunForRunTenantId73Fbd8D9FkTenantIdUsingTenantNameKeyUpdate: TenantOnRunForRunTenantId73Fbd8D9FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnRunForRunTenantId73Fbd8D9FkTenantIdUsingTenantPkeyUpdate: TenantOnRunForRunTenantId73Fbd8D9FkTenantIdUsingTenantPkeyUpdate;
  TenantOnTagForTagTenantId8072B61FFkTenantIdUsingTenantKeyKeyUpdate: TenantOnTagForTagTenantId8072B61FFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnTagForTagTenantId8072B61FFkTenantIdUsingTenantNameKeyUpdate: TenantOnTagForTagTenantId8072B61FFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnTagForTagTenantId8072B61FFkTenantIdUsingTenantPkeyUpdate: TenantOnTagForTagTenantId8072B61FFkTenantIdUsingTenantPkeyUpdate;
  TenantOnTaskForTaskTenantId148E90DaFkTenantIdUsingTenantKeyKeyUpdate: TenantOnTaskForTaskTenantId148E90DaFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnTaskForTaskTenantId148E90DaFkTenantIdUsingTenantNameKeyUpdate: TenantOnTaskForTaskTenantId148E90DaFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnTaskForTaskTenantId148E90DaFkTenantIdUsingTenantPkeyUpdate: TenantOnTaskForTaskTenantId148E90DaFkTenantIdUsingTenantPkeyUpdate;
  TenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantKeyKeyUpdate: TenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantNameKeyUpdate: TenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantPkeyUpdate: TenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdUsingTenantPkeyUpdate;
  TenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantKeyKeyUpdate: TenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantNameKeyUpdate: TenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantPkeyUpdate: TenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdUsingTenantPkeyUpdate;
  TenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantKeyKeyUpdate: TenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantNameKeyUpdate: TenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantPkeyUpdate: TenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantPkeyUpdate;
  TenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingTenantKeyKeyUpdate: TenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingTenantNameKeyUpdate: TenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingTenantNameKeyUpdate;
  TenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingTenantPkeyUpdate: TenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingTenantPkeyUpdate;
  TenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingTenantKeyKeyUpdate: TenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingTenantNameKeyUpdate: TenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingTenantPkeyUpdate: TenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingTenantPkeyUpdate;
  TenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingTenantKeyKeyUpdate: TenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingTenantKeyKeyUpdate;
  TenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingTenantNameKeyUpdate: TenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingTenantNameKeyUpdate;
  TenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingTenantPkeyUpdate: TenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingTenantPkeyUpdate;
  TenantPatch: TenantPatch;
  TenantTenantKeyKeyConnect: TenantTenantKeyKeyConnect;
  TenantTenantKeyKeyDelete: TenantTenantKeyKeyDelete;
  TenantTenantNameKeyConnect: TenantTenantNameKeyConnect;
  TenantTenantNameKeyDelete: TenantTenantNameKeyDelete;
  TenantTenantPkeyConnect: TenantTenantPkeyConnect;
  TenantTenantPkeyDelete: TenantTenantPkeyDelete;
  TenantToManyAnalysisTableFilter: TenantToManyAnalysisTableFilter;
  TenantToManyAnalysisTableGroupFilter: TenantToManyAnalysisTableGroupFilter;
  TenantToManyAnalysisTableRowFilter: TenantToManyAnalysisTableRowFilter;
  TenantToManyApplicationUserMembershipFilter: TenantToManyApplicationUserMembershipFilter;
  TenantToManyApplicationUserSessionFilter: TenantToManyApplicationUserSessionFilter;
  TenantToManyCostFilter: TenantToManyCostFilter;
  TenantToManyDatasetFilter: TenantToManyDatasetFilter;
  TenantToManyDatasetMetadatumFilter: TenantToManyDatasetMetadatumFilter;
  TenantToManyDatasetRecordingsTableColumnFilter: TenantToManyDatasetRecordingsTableColumnFilter;
  TenantToManyDatasetRecordingsTableColumnOrderFilter: TenantToManyDatasetRecordingsTableColumnOrderFilter;
  TenantToManyDatasetRecordingsTableFilter: TenantToManyDatasetRecordingsTableFilter;
  TenantToManyDatasetVersionFilter: TenantToManyDatasetVersionFilter;
  TenantToManyFileActivityFilter: TenantToManyFileActivityFilter;
  TenantToManyFileFilter: TenantToManyFileFilter;
  TenantToManyFileMetadatumFilter: TenantToManyFileMetadatumFilter;
  TenantToManyFileOutputGroupFilter: TenantToManyFileOutputGroupFilter;
  TenantToManyFileOutputGroupMetadatumFilter: TenantToManyFileOutputGroupMetadatumFilter;
  TenantToManyFileRecordingGroupFilter: TenantToManyFileRecordingGroupFilter;
  TenantToManyFileRecordingGroupMetadatumFilter: TenantToManyFileRecordingGroupMetadatumFilter;
  TenantToManyMetadataValueFilter: TenantToManyMetadataValueFilter;
  TenantToManyMetadatumFilter: TenantToManyMetadatumFilter;
  TenantToManyProjectFilter: TenantToManyProjectFilter;
  TenantToManyRecordingGroupFileFilter: TenantToManyRecordingGroupFileFilter;
  TenantToManyRoleChangeNotificationFilter: TenantToManyRoleChangeNotificationFilter;
  TenantToManyRunFilter: TenantToManyRunFilter;
  TenantToManyTagFilter: TenantToManyTagFilter;
  TenantToManyTaskFilter: TenantToManyTaskFilter;
  TenantToManyTenantContractFilter: TenantToManyTenantContractFilter;
  TenantToManyTenantMonthlyConsumedResourceFilter: TenantToManyTenantMonthlyConsumedResourceFilter;
  TenantToManyTenantUserMembershipNotificationFilter: TenantToManyTenantUserMembershipNotificationFilter;
  TenantToManyToolTenantFilter: TenantToManyToolTenantFilter;
  TenantToManyToolboxTenantFilter: TenantToManyToolboxTenantFilter;
  TenantToManyWorkflowFilter: TenantToManyWorkflowFilter;
  TenantUserMembershId10Affa8DFkNotificatInput: TenantUserMembershId10Affa8DFkNotificatInput;
  TenantUserMembershId10Affa8DFkNotificatInverseInput: TenantUserMembershId10Affa8DFkNotificatInverseInput;
  TenantUserMembershInviterId29476E5EFkApplicatiApplicationUserCreateInput: TenantUserMembershInviterId29476E5EFkApplicatiApplicationUserCreateInput;
  TenantUserMembershInviterId29476E5EFkApplicatiInput: TenantUserMembershInviterId29476E5EFkApplicatiInput;
  TenantUserMembershInviterId29476E5EFkApplicatiInverseInput: TenantUserMembershInviterId29476E5EFkApplicatiInverseInput;
  TenantUserMembershTenantIdC67818C5FkTenantIdInput: TenantUserMembershTenantIdC67818C5FkTenantIdInput;
  TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput: TenantUserMembershTenantIdC67818C5FkTenantIdInverseInput;
  TenantUserMembershTenantIdC67818C5FkTenantIdTenantCreateInput: TenantUserMembershTenantIdC67818C5FkTenantIdTenantCreateInput;
  TenantUserMembershUserId2B78E8A0FkApplicatiApplicationUserCreateInput: TenantUserMembershUserId2B78E8A0FkApplicatiApplicationUserCreateInput;
  TenantUserMembershUserId2B78E8A0FkApplicatiInput: TenantUserMembershUserId2B78E8A0FkApplicatiInput;
  TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput: TenantUserMembershUserId2B78E8A0FkApplicatiInverseInput;
  TenantUserMembershipNotification: TenantUserMembershipNotification;
  TenantUserMembershipNotificationCondition: TenantUserMembershipNotificationCondition;
  TenantUserMembershipNotificationFilter: TenantUserMembershipNotificationFilter;
  TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatUsingTenantUserMembershipNotificationPkeyUpdate: TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatUsingTenantUserMembershipNotificationPkeyUpdate;
  TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingTenantUserMembershipNotificationPkeyUpdate: TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiUsingTenantUserMembershipNotificationPkeyUpdate;
  TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantUserMembershipNotificationPkeyUpdate: TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdUsingTenantUserMembershipNotificationPkeyUpdate;
  TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingTenantUserMembershipNotificationPkeyUpdate: TenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiUsingTenantUserMembershipNotificationPkeyUpdate;
  TenantUserMembershipNotificationTenantUserMembershipNotificationPkeyConnect: TenantUserMembershipNotificationTenantUserMembershipNotificationPkeyConnect;
  TenantUserMembershipNotificationsConnection: TenantUserMembershipNotificationsConnection;
  TenantUserMembershipNotificationsEdge: TenantUserMembershipNotificationsEdge;
  TenantUserexecutor: TenantUserexecutor;
  TenantUserexecutorCondition: TenantUserexecutorCondition;
  TenantUserexecutorFilter: TenantUserexecutorFilter;
  TenantUserexecutorInput: TenantUserexecutorInput;
  TenantUserexecutorOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsUsingTenantUserexecutorPkeyUpdate: TenantUserexecutorOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsUsingTenantUserexecutorPkeyUpdate;
  TenantUserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsUsingTenantUserexecutorPkeyUpdate: TenantUserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsUsingTenantUserexecutorPkeyUpdate;
  TenantUserexecutorOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoUsingTenantUserexecutorPkeyUpdate: TenantUserexecutorOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoUsingTenantUserexecutorPkeyUpdate;
  TenantUserexecutorPatch: TenantUserexecutorPatch;
  TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoDjangoContentTypeCreateInput: TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoDjangoContentTypeCreateInput;
  TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInput: TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInput;
  TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput: TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoInverseInput;
  TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoTenantUserexecutorCreateInput: TenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoTenantUserexecutorCreateInput;
  TenantUserexecutorTenantUserexecutorPkeyConnect: TenantUserexecutorTenantUserexecutorPkeyConnect;
  TenantUserexecutorTenantUserexecutorPkeyDelete: TenantUserexecutorTenantUserexecutorPkeyDelete;
  TenantUserexecutorsConnection: TenantUserexecutorsConnection;
  TenantUserexecutorsEdge: TenantUserexecutorsEdge;
  TenantsEdge: TenantsEdge;
  TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoDjangoContentTypeCreateInput: TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoDjangoContentTypeCreateInput;
  TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInput: TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInput;
  TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput: TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoInverseInput;
  TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoTesContainerimageexecutorCreateInput: TesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoTesContainerimageexecutorCreateInput;
  TesContainerimageexecutor: TesContainerimageexecutor;
  TesContainerimageexecutorCondition: TesContainerimageexecutorCondition;
  TesContainerimageexecutorFilter: TesContainerimageexecutorFilter;
  TesContainerimageexecutorInput: TesContainerimageexecutorInput;
  TesContainerimageexecutorOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaUsingTesContainerimageexecutorPkeyUpdate: TesContainerimageexecutorOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaUsingTesContainerimageexecutorPkeyUpdate;
  TesContainerimageexecutorOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingTesContainerimageexecutorPkeyUpdate: TesContainerimageexecutorOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaUsingTesContainerimageexecutorPkeyUpdate;
  TesContainerimageexecutorOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoUsingTesContainerimageexecutorPkeyUpdate: TesContainerimageexecutorOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoUsingTesContainerimageexecutorPkeyUpdate;
  TesContainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaUsingTesContainerimageexecutorPkeyUpdate: TesContainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaUsingTesContainerimageexecutorPkeyUpdate;
  TesContainerimageexecutorPatch: TesContainerimageexecutorPatch;
  TesContainerimageexecutorTesContainerimageexecutorPkeyConnect: TesContainerimageexecutorTesContainerimageexecutorPkeyConnect;
  TesContainerimageexecutorTesContainerimageexecutorPkeyDelete: TesContainerimageexecutorTesContainerimageexecutorPkeyDelete;
  TesContainerimageexecutorsConnection: TesContainerimageexecutorsConnection;
  TesContainerimageexecutorsEdge: TesContainerimageexecutorsEdge;
  TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInput: TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInput;
  TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInverseInput: TesMockcontainerimaContainerimageexecut4Eced924FkTesContaInverseInput;
  TesMockcontainerimaContainerimageexecut4Eced924FkTesContaTesContainerimageexecutorCreateInput: TesMockcontainerimaContainerimageexecut4Eced924FkTesContaTesContainerimageexecutorCreateInput;
  TesMockcontainerimaContainerimageexecut4Eced924FkTesContaTesMockcontainerimageexecutorCreateInput: TesMockcontainerimaContainerimageexecut4Eced924FkTesContaTesMockcontainerimageexecutorCreateInput;
  TesMockcontainerimageexecutor: TesMockcontainerimageexecutor;
  TesMockcontainerimageexecutorCondition: TesMockcontainerimageexecutorCondition;
  TesMockcontainerimageexecutorFilter: TesMockcontainerimageexecutorFilter;
  TesMockcontainerimageexecutorInput: TesMockcontainerimageexecutorInput;
  TesMockcontainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaUsingTesMockcontainerimageexecutorPkeyUpdate: TesMockcontainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaUsingTesMockcontainerimageexecutorPkeyUpdate;
  TesMockcontainerimageexecutorPatch: TesMockcontainerimageexecutorPatch;
  TesMockcontainerimageexecutorTesMockcontainerimageexecutorPkeyConnect: TesMockcontainerimageexecutorTesMockcontainerimageexecutorPkeyConnect;
  TesMockcontainerimageexecutorTesMockcontainerimageexecutorPkeyDelete: TesMockcontainerimageexecutorTesMockcontainerimageexecutorPkeyDelete;
  TesMockcontainerimageexecutorsConnection: TesMockcontainerimageexecutorsConnection;
  TesMockcontainerimageexecutorsEdge: TesMockcontainerimageexecutorsEdge;
  TesMocktaskexecutor: TesMocktaskexecutor;
  TesMocktaskexecutorCondition: TesMocktaskexecutorCondition;
  TesMocktaskexecutorFilter: TesMocktaskexecutorFilter;
  TesMocktaskexecutorInput: TesMocktaskexecutorInput;
  TesMocktaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeUsingTesMocktaskexecutorPkeyUpdate: TesMocktaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeUsingTesMocktaskexecutorPkeyUpdate;
  TesMocktaskexecutorPatch: TesMocktaskexecutorPatch;
  TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInput: TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInput;
  TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInverseInput: TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeInverseInput;
  TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeTesMocktaskexecutorCreateInput: TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeTesMocktaskexecutorCreateInput;
  TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeTesTaskexecutorCreateInput: TesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeTesTaskexecutorCreateInput;
  TesMocktaskexecutorTesMocktaskexecutorPkeyConnect: TesMocktaskexecutorTesMocktaskexecutorPkeyConnect;
  TesMocktaskexecutorTesMocktaskexecutorPkeyDelete: TesMocktaskexecutorTesMocktaskexecutorPkeyDelete;
  TesMocktaskexecutorsConnection: TesMocktaskexecutorsConnection;
  TesMocktaskexecutorsEdge: TesMocktaskexecutorsEdge;
  TesTaskexecutor: TesTaskexecutor;
  TesTaskexecutorCondition: TesTaskexecutorCondition;
  TesTaskexecutorFilter: TesTaskexecutorFilter;
  TesTaskexecutorInput: TesTaskexecutorInput;
  TesTaskexecutorOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeUsingTesTaskexecutorPkeyUpdate: TesTaskexecutorOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskeUsingTesTaskexecutorPkeyUpdate;
  TesTaskexecutorOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdUsingTesTaskexecutorPkeyUpdate: TesTaskexecutorOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdUsingTesTaskexecutorPkeyUpdate;
  TesTaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeUsingTesTaskexecutorPkeyUpdate: TesTaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskeUsingTesTaskexecutorPkeyUpdate;
  TesTaskexecutorOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoUsingTesTaskexecutorPkeyUpdate: TesTaskexecutorOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoUsingTesTaskexecutorPkeyUpdate;
  TesTaskexecutorPatch: TesTaskexecutorPatch;
  TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoDjangoContentTypeCreateInput: TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoDjangoContentTypeCreateInput;
  TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInput: TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInput;
  TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput: TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoInverseInput;
  TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoTesTaskexecutorCreateInput: TesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoTesTaskexecutorCreateInput;
  TesTaskexecutorTesTaskexecutorPkeyConnect: TesTaskexecutorTesTaskexecutorPkeyConnect;
  TesTaskexecutorTesTaskexecutorPkeyDelete: TesTaskexecutorTesTaskexecutorPkeyDelete;
  TesTaskexecutorsConnection: TesTaskexecutorsConnection;
  TesTaskexecutorsEdge: TesTaskexecutorsEdge;
  Tool: Tool;
  ToolCategoriesConnection: ToolCategoriesConnection;
  ToolCategoriesEdge: ToolCategoriesEdge;
  ToolCategory: ToolCategory;
  ToolCategoryCondition: ToolCategoryCondition;
  ToolCategoryFilter: ToolCategoryFilter;
  ToolCategoryInput: ToolCategoryInput;
  ToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolCategoryKeyKeyUpdate: ToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolCategoryKeyKeyUpdate;
  ToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolCategoryPkeyUpdate: ToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolCategoryPkeyUpdate;
  ToolCategoryPatch: ToolCategoryPatch;
  ToolCategoryToManyToolDisplayCategoryFilter: ToolCategoryToManyToolDisplayCategoryFilter;
  ToolCategoryToolCategoryKeyKeyConnect: ToolCategoryToolCategoryKeyKeyConnect;
  ToolCategoryToolCategoryKeyKeyDelete: ToolCategoryToolCategoryKeyKeyDelete;
  ToolCategoryToolCategoryPkeyConnect: ToolCategoryToolCategoryPkeyConnect;
  ToolCategoryToolCategoryPkeyDelete: ToolCategoryToolCategoryPkeyDelete;
  ToolCondition: ToolCondition;
  ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInput: ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInput;
  ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInverseInput: ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateInverseInput;
  ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateToolCategoryCreateInput: ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateToolCategoryCreateInput;
  ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateToolDisplayCategoriesCreateInput: ToolDisplayCategorToolcategoryIdD78D6E82FkToolCateToolDisplayCategoriesCreateInput;
  ToolDisplayCategoriesConnection: ToolDisplayCategoriesConnection;
  ToolDisplayCategoriesEdge: ToolDisplayCategoriesEdge;
  ToolDisplayCategoriesToolIdAdc582D3FkToolIdInput: ToolDisplayCategoriesToolIdAdc582D3FkToolIdInput;
  ToolDisplayCategoriesToolIdAdc582D3FkToolIdInverseInput: ToolDisplayCategoriesToolIdAdc582D3FkToolIdInverseInput;
  ToolDisplayCategoriesToolIdAdc582D3FkToolIdToolCreateInput: ToolDisplayCategoriesToolIdAdc582D3FkToolIdToolCreateInput;
  ToolDisplayCategoriesToolIdAdc582D3FkToolIdToolDisplayCategoriesCreateInput: ToolDisplayCategoriesToolIdAdc582D3FkToolIdToolDisplayCategoriesCreateInput;
  ToolDisplayCategory: ToolDisplayCategory;
  ToolDisplayCategoryCondition: ToolDisplayCategoryCondition;
  ToolDisplayCategoryFilter: ToolDisplayCategoryFilter;
  ToolDisplayCategoryInput: ToolDisplayCategoryInput;
  ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolDisplayCategoriesPkeyUpdate: ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolDisplayCategoriesPkeyUpdate;
  ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqUpdate: ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCateUsingToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqUpdate;
  ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolDisplayCategoriesPkeyUpdate: ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolDisplayCategoriesPkeyUpdate;
  ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqUpdate: ToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqUpdate;
  ToolDisplayCategoryPatch: ToolDisplayCategoryPatch;
  ToolDisplayCategoryToolDisplayCategoriesPkeyConnect: ToolDisplayCategoryToolDisplayCategoriesPkeyConnect;
  ToolDisplayCategoryToolDisplayCategoriesPkeyDelete: ToolDisplayCategoryToolDisplayCategoriesPkeyDelete;
  ToolDisplayCategoryToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqConnect: ToolDisplayCategoryToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqConnect;
  ToolDisplayCategoryToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqDelete: ToolDisplayCategoryToolDisplayCategoriesToolIdToolcategoryId50E43E18UniqDelete;
  ToolFilter: ToolFilter;
  ToolInput: ToolInput;
  ToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdUsingToolKeyKeyUpdate: ToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdUsingToolKeyKeyUpdate;
  ToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdUsingToolPkeyUpdate: ToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdUsingToolPkeyUpdate;
  ToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolKeyKeyUpdate: ToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolKeyKeyUpdate;
  ToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolPkeyUpdate: ToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdUsingToolPkeyUpdate;
  ToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolKeyKeyUpdate: ToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolKeyKeyUpdate;
  ToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolPkeyUpdate: ToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolPkeyUpdate;
  ToolOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolKeyKeyUpdate: ToolOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolKeyKeyUpdate;
  ToolOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolPkeyUpdate: ToolOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolPkeyUpdate;
  ToolParameter: ToolParameter;
  ToolParameterCondition: ToolParameterCondition;
  ToolParameterFilter: ToolParameterFilter;
  ToolParameterInput: ToolParameterInput;
  ToolParameterOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdUsingToolParameterPkeyUpdate: ToolParameterOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdUsingToolParameterPkeyUpdate;
  ToolParameterPatch: ToolParameterPatch;
  ToolParameterToolParameterPkeyConnect: ToolParameterToolParameterPkeyConnect;
  ToolParameterToolParameterPkeyDelete: ToolParameterToolParameterPkeyDelete;
  ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInput: ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInput;
  ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput: ToolParameterToolVersionIdC3A0Df75FkToolVersionIdInverseInput;
  ToolParameterToolVersionIdC3A0Df75FkToolVersionIdToolParameterCreateInput: ToolParameterToolVersionIdC3A0Df75FkToolVersionIdToolParameterCreateInput;
  ToolParameterToolVersionIdC3A0Df75FkToolVersionIdToolVersionCreateInput: ToolParameterToolVersionIdC3A0Df75FkToolVersionIdToolVersionCreateInput;
  ToolParametersConnection: ToolParametersConnection;
  ToolParametersEdge: ToolParametersEdge;
  ToolPatch: ToolPatch;
  ToolResource: ToolResource;
  ToolResourceCondition: ToolResourceCondition;
  ToolResourceFilter: ToolResourceFilter;
  ToolResourceInput: ToolResourceInput;
  ToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingReqTypeToolVersionUniqueUpdate: ToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingReqTypeToolVersionUniqueUpdate;
  ToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingToolResourcePkeyUpdate: ToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingToolResourcePkeyUpdate;
  ToolResourcePatch: ToolResourcePatch;
  ToolResourceReqTypeToolVersionUniqueConnect: ToolResourceReqTypeToolVersionUniqueConnect;
  ToolResourceReqTypeToolVersionUniqueDelete: ToolResourceReqTypeToolVersionUniqueDelete;
  ToolResourceToolResourcePkeyConnect: ToolResourceToolResourcePkeyConnect;
  ToolResourceToolResourcePkeyDelete: ToolResourceToolResourcePkeyDelete;
  ToolResourceToolVersionId5E21979DFkToolVersionIdInput: ToolResourceToolVersionId5E21979DFkToolVersionIdInput;
  ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput: ToolResourceToolVersionId5E21979DFkToolVersionIdInverseInput;
  ToolResourceToolVersionId5E21979DFkToolVersionIdToolResourceCreateInput: ToolResourceToolVersionId5E21979DFkToolVersionIdToolResourceCreateInput;
  ToolResourceToolVersionId5E21979DFkToolVersionIdToolVersionCreateInput: ToolResourceToolVersionId5E21979DFkToolVersionIdToolVersionCreateInput;
  ToolResourcesConnection: ToolResourcesConnection;
  ToolResourcesEdge: ToolResourcesEdge;
  ToolResult: ToolResult;
  ToolResultCondition: ToolResultCondition;
  ToolResultFilter: ToolResultFilter;
  ToolResultInput: ToolResultInput;
  ToolResultOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdUsingToolResultPkeyUpdate: ToolResultOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdUsingToolResultPkeyUpdate;
  ToolResultPatch: ToolResultPatch;
  ToolResultToolResultPkeyConnect: ToolResultToolResultPkeyConnect;
  ToolResultToolResultPkeyDelete: ToolResultToolResultPkeyDelete;
  ToolResultToolVersionId41Fb163EFkToolVersionIdInput: ToolResultToolVersionId41Fb163EFkToolVersionIdInput;
  ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput: ToolResultToolVersionId41Fb163EFkToolVersionIdInverseInput;
  ToolResultToolVersionId41Fb163EFkToolVersionIdToolResultCreateInput: ToolResultToolVersionId41Fb163EFkToolVersionIdToolResultCreateInput;
  ToolResultToolVersionId41Fb163EFkToolVersionIdToolVersionCreateInput: ToolResultToolVersionId41Fb163EFkToolVersionIdToolVersionCreateInput;
  ToolResultsConnection: ToolResultsConnection;
  ToolResultsEdge: ToolResultsEdge;
  ToolSecret: ToolSecret;
  ToolSecretCondition: ToolSecretCondition;
  ToolSecretFilter: ToolSecretFilter;
  ToolSecretInput: ToolSecretInput;
  ToolSecretOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdUsingToolSecretPkeyUpdate: ToolSecretOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdUsingToolSecretPkeyUpdate;
  ToolSecretPatch: ToolSecretPatch;
  ToolSecretToolSecretPkeyConnect: ToolSecretToolSecretPkeyConnect;
  ToolSecretToolSecretPkeyDelete: ToolSecretToolSecretPkeyDelete;
  ToolSecretToolVersionId92Dc8FadFkToolVersionIdInput: ToolSecretToolVersionId92Dc8FadFkToolVersionIdInput;
  ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput: ToolSecretToolVersionId92Dc8FadFkToolVersionIdInverseInput;
  ToolSecretToolVersionId92Dc8FadFkToolVersionIdToolSecretCreateInput: ToolSecretToolVersionId92Dc8FadFkToolVersionIdToolSecretCreateInput;
  ToolSecretToolVersionId92Dc8FadFkToolVersionIdToolVersionCreateInput: ToolSecretToolVersionId92Dc8FadFkToolVersionIdToolVersionCreateInput;
  ToolSecretsConnection: ToolSecretsConnection;
  ToolSecretsEdge: ToolSecretsEdge;
  ToolSource: ToolSource;
  ToolSourceCondition: ToolSourceCondition;
  ToolSourceFilter: ToolSourceFilter;
  ToolSourceInput: ToolSourceInput;
  ToolSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdUsingToolSourcePkeyUpdate: ToolSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdUsingToolSourcePkeyUpdate;
  ToolSourceOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdUsingToolSourcePkeyUpdate: ToolSourceOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdUsingToolSourcePkeyUpdate;
  ToolSourcePatch: ToolSourcePatch;
  ToolSourceToManyTaskSourceFilter: ToolSourceToManyTaskSourceFilter;
  ToolSourceToolSourcePkeyConnect: ToolSourceToolSourcePkeyConnect;
  ToolSourceToolSourcePkeyDelete: ToolSourceToolSourcePkeyDelete;
  ToolSourceToolVersionId9Ac27A25FkToolVersionIdInput: ToolSourceToolVersionId9Ac27A25FkToolVersionIdInput;
  ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput: ToolSourceToolVersionId9Ac27A25FkToolVersionIdInverseInput;
  ToolSourceToolVersionId9Ac27A25FkToolVersionIdToolSourceCreateInput: ToolSourceToolVersionId9Ac27A25FkToolVersionIdToolSourceCreateInput;
  ToolSourceToolVersionId9Ac27A25FkToolVersionIdToolVersionCreateInput: ToolSourceToolVersionId9Ac27A25FkToolVersionIdToolVersionCreateInput;
  ToolSourcesConnection: ToolSourcesConnection;
  ToolSourcesEdge: ToolSourcesEdge;
  ToolTenant: ToolTenant;
  ToolTenantCondition: ToolTenantCondition;
  ToolTenantFilter: ToolTenantFilter;
  ToolTenantInput: ToolTenantInput;
  ToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingToolTenantsPkeyUpdate: ToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingToolTenantsPkeyUpdate;
  ToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingToolTenantsToolIdTenantId1Ab854FcUniqUpdate: ToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdUsingToolTenantsToolIdTenantId1Ab854FcUniqUpdate;
  ToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolTenantsPkeyUpdate: ToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolTenantsPkeyUpdate;
  ToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolTenantsToolIdTenantId1Ab854FcUniqUpdate: ToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdUsingToolTenantsToolIdTenantId1Ab854FcUniqUpdate;
  ToolTenantPatch: ToolTenantPatch;
  ToolTenantToolTenantsPkeyConnect: ToolTenantToolTenantsPkeyConnect;
  ToolTenantToolTenantsPkeyDelete: ToolTenantToolTenantsPkeyDelete;
  ToolTenantToolTenantsToolIdTenantId1Ab854FcUniqConnect: ToolTenantToolTenantsToolIdTenantId1Ab854FcUniqConnect;
  ToolTenantToolTenantsToolIdTenantId1Ab854FcUniqDelete: ToolTenantToolTenantsToolIdTenantId1Ab854FcUniqDelete;
  ToolTenantsConnection: ToolTenantsConnection;
  ToolTenantsEdge: ToolTenantsEdge;
  ToolTenantsTenantId9C4A54FeFkTenantIdInput: ToolTenantsTenantId9C4A54FeFkTenantIdInput;
  ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput: ToolTenantsTenantId9C4A54FeFkTenantIdInverseInput;
  ToolTenantsTenantId9C4A54FeFkTenantIdTenantCreateInput: ToolTenantsTenantId9C4A54FeFkTenantIdTenantCreateInput;
  ToolTenantsTenantId9C4A54FeFkTenantIdToolTenantsCreateInput: ToolTenantsTenantId9C4A54FeFkTenantIdToolTenantsCreateInput;
  ToolTenantsToolIdF77Ef6F7FkToolIdInput: ToolTenantsToolIdF77Ef6F7FkToolIdInput;
  ToolTenantsToolIdF77Ef6F7FkToolIdInverseInput: ToolTenantsToolIdF77Ef6F7FkToolIdInverseInput;
  ToolTenantsToolIdF77Ef6F7FkToolIdToolCreateInput: ToolTenantsToolIdF77Ef6F7FkToolIdToolCreateInput;
  ToolTenantsToolIdF77Ef6F7FkToolIdToolTenantsCreateInput: ToolTenantsToolIdF77Ef6F7FkToolIdToolTenantsCreateInput;
  ToolToManyAnalysisTableGroupFilter: ToolToManyAnalysisTableGroupFilter;
  ToolToManyToolDisplayCategoryFilter: ToolToManyToolDisplayCategoryFilter;
  ToolToManyToolTenantFilter: ToolToManyToolTenantFilter;
  ToolToManyToolVersionFilter: ToolToManyToolVersionFilter;
  ToolToolKeyKeyConnect: ToolToolKeyKeyConnect;
  ToolToolKeyKeyDelete: ToolToolKeyKeyDelete;
  ToolToolPkeyConnect: ToolToolPkeyConnect;
  ToolToolPkeyDelete: ToolToolPkeyDelete;
  ToolVersion: ToolVersion;
  ToolVersionCondition: ToolVersionCondition;
  ToolVersionFilter: ToolVersionFilter;
  ToolVersionInput: ToolVersionInput;
  ToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdUsingToolVersionPkeyUpdate: ToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdUsingToolVersionPkeyUpdate;
  ToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingToolVersionPkeyUpdate: ToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingToolVersionPkeyUpdate;
  ToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingToolVersionPkeyUpdate: ToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingToolVersionPkeyUpdate;
  ToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdUsingToolVersionPkeyUpdate: ToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdUsingToolVersionPkeyUpdate;
  ToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingToolVersionPkeyUpdate: ToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingToolVersionPkeyUpdate;
  ToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdUsingToolVersionPkeyUpdate: ToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdUsingToolVersionPkeyUpdate;
  ToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdUsingToolVersionPkeyUpdate: ToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdUsingToolVersionPkeyUpdate;
  ToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdUsingToolVersionPkeyUpdate: ToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdUsingToolVersionPkeyUpdate;
  ToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolVersionPkeyUpdate: ToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolVersionPkeyUpdate;
  ToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolVersionPkeyUpdate: ToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolVersionPkeyUpdate;
  ToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolversionToolVersionUniqueUpdate: ToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolversionToolVersionUniqueUpdate;
  ToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdUsingToolVersionPkeyUpdate: ToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdUsingToolVersionPkeyUpdate;
  ToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdUsingToolversionToolVersionUniqueUpdate: ToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdUsingToolversionToolVersionUniqueUpdate;
  ToolVersionPatch: ToolVersionPatch;
  ToolVersionToManyAnalysisTableFilter: ToolVersionToManyAnalysisTableFilter;
  ToolVersionToManyAnalysisTableRowFilter: ToolVersionToManyAnalysisTableRowFilter;
  ToolVersionToManyTaskFilter: ToolVersionToManyTaskFilter;
  ToolVersionToManyToolParameterFilter: ToolVersionToManyToolParameterFilter;
  ToolVersionToManyToolResourceFilter: ToolVersionToManyToolResourceFilter;
  ToolVersionToManyToolResultFilter: ToolVersionToManyToolResultFilter;
  ToolVersionToManyToolSecretFilter: ToolVersionToManyToolSecretFilter;
  ToolVersionToManyToolSourceFilter: ToolVersionToManyToolSourceFilter;
  ToolVersionToManyToolboxVersionToolVersionFilter: ToolVersionToManyToolboxVersionToolVersionFilter;
  ToolVersionToManyWorkflowSectionBlockFilter: ToolVersionToManyWorkflowSectionBlockFilter;
  ToolVersionToolId3B396B22FkToolIdInput: ToolVersionToolId3B396B22FkToolIdInput;
  ToolVersionToolId3B396B22FkToolIdInverseInput: ToolVersionToolId3B396B22FkToolIdInverseInput;
  ToolVersionToolId3B396B22FkToolIdToolCreateInput: ToolVersionToolId3B396B22FkToolIdToolCreateInput;
  ToolVersionToolId3B396B22FkToolIdToolVersionCreateInput: ToolVersionToolId3B396B22FkToolIdToolVersionCreateInput;
  ToolVersionToolVersionPkeyConnect: ToolVersionToolVersionPkeyConnect;
  ToolVersionToolVersionPkeyDelete: ToolVersionToolVersionPkeyDelete;
  ToolVersionToolversionToolVersionUniqueConnect: ToolVersionToolversionToolVersionUniqueConnect;
  ToolVersionToolversionToolVersionUniqueDelete: ToolVersionToolversionToolVersionUniqueDelete;
  ToolVersionsConnection: ToolVersionsConnection;
  ToolVersionsEdge: ToolVersionsEdge;
  Toolbox: Toolbox;
  ToolboxCondition: ToolboxCondition;
  ToolboxExitcode: ToolboxExitcode;
  ToolboxExitcodeCondition: ToolboxExitcodeCondition;
  ToolboxExitcodeFilter: ToolboxExitcodeFilter;
  ToolboxExitcodeInput: ToolboxExitcodeInput;
  ToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxExitcodeCodeKeyUpdate: ToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxExitcodeCodeKeyUpdate;
  ToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxExitcodePkeyUpdate: ToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxExitcodePkeyUpdate;
  ToolboxExitcodePatch: ToolboxExitcodePatch;
  ToolboxExitcodeToManyToolboxVersionExitcodeFilter: ToolboxExitcodeToManyToolboxVersionExitcodeFilter;
  ToolboxExitcodeToolboxExitcodeCodeKeyConnect: ToolboxExitcodeToolboxExitcodeCodeKeyConnect;
  ToolboxExitcodeToolboxExitcodeCodeKeyDelete: ToolboxExitcodeToolboxExitcodeCodeKeyDelete;
  ToolboxExitcodeToolboxExitcodePkeyConnect: ToolboxExitcodeToolboxExitcodePkeyConnect;
  ToolboxExitcodeToolboxExitcodePkeyDelete: ToolboxExitcodeToolboxExitcodePkeyDelete;
  ToolboxExitcodesConnection: ToolboxExitcodesConnection;
  ToolboxExitcodesEdge: ToolboxExitcodesEdge;
  ToolboxFilter: ToolboxFilter;
  ToolboxInput: ToolboxInput;
  ToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxKeyKeyUpdate: ToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxKeyKeyUpdate;
  ToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxPkeyUpdate: ToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxPkeyUpdate;
  ToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxKeyKeyUpdate: ToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxKeyKeyUpdate;
  ToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxPkeyUpdate: ToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxPkeyUpdate;
  ToolboxPatch: ToolboxPatch;
  ToolboxTenant: ToolboxTenant;
  ToolboxTenantCondition: ToolboxTenantCondition;
  ToolboxTenantFilter: ToolboxTenantFilter;
  ToolboxTenantInput: ToolboxTenantInput;
  ToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingToolboxTenantsPkeyUpdate: ToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingToolboxTenantsPkeyUpdate;
  ToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingToolboxTenantsToolboxIdTenantId171F711CUniqUpdate: ToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdUsingToolboxTenantsToolboxIdTenantId171F711CUniqUpdate;
  ToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxTenantsPkeyUpdate: ToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxTenantsPkeyUpdate;
  ToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxTenantsToolboxIdTenantId171F711CUniqUpdate: ToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdUsingToolboxTenantsToolboxIdTenantId171F711CUniqUpdate;
  ToolboxTenantPatch: ToolboxTenantPatch;
  ToolboxTenantToolboxTenantsPkeyConnect: ToolboxTenantToolboxTenantsPkeyConnect;
  ToolboxTenantToolboxTenantsPkeyDelete: ToolboxTenantToolboxTenantsPkeyDelete;
  ToolboxTenantToolboxTenantsToolboxIdTenantId171F711CUniqConnect: ToolboxTenantToolboxTenantsToolboxIdTenantId171F711CUniqConnect;
  ToolboxTenantToolboxTenantsToolboxIdTenantId171F711CUniqDelete: ToolboxTenantToolboxTenantsToolboxIdTenantId171F711CUniqDelete;
  ToolboxTenantsConnection: ToolboxTenantsConnection;
  ToolboxTenantsEdge: ToolboxTenantsEdge;
  ToolboxTenantsTenantId5D0A40E4FkTenantIdInput: ToolboxTenantsTenantId5D0A40E4FkTenantIdInput;
  ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput: ToolboxTenantsTenantId5D0A40E4FkTenantIdInverseInput;
  ToolboxTenantsTenantId5D0A40E4FkTenantIdTenantCreateInput: ToolboxTenantsTenantId5D0A40E4FkTenantIdTenantCreateInput;
  ToolboxTenantsTenantId5D0A40E4FkTenantIdToolboxTenantsCreateInput: ToolboxTenantsTenantId5D0A40E4FkTenantIdToolboxTenantsCreateInput;
  ToolboxTenantsToolboxId9D553881FkToolboxIdInput: ToolboxTenantsToolboxId9D553881FkToolboxIdInput;
  ToolboxTenantsToolboxId9D553881FkToolboxIdInverseInput: ToolboxTenantsToolboxId9D553881FkToolboxIdInverseInput;
  ToolboxTenantsToolboxId9D553881FkToolboxIdToolboxCreateInput: ToolboxTenantsToolboxId9D553881FkToolboxIdToolboxCreateInput;
  ToolboxTenantsToolboxId9D553881FkToolboxIdToolboxTenantsCreateInput: ToolboxTenantsToolboxId9D553881FkToolboxIdToolboxTenantsCreateInput;
  ToolboxToManyToolboxTenantFilter: ToolboxToManyToolboxTenantFilter;
  ToolboxToManyToolboxVersionFilter: ToolboxToManyToolboxVersionFilter;
  ToolboxToolboxKeyKeyConnect: ToolboxToolboxKeyKeyConnect;
  ToolboxToolboxKeyKeyDelete: ToolboxToolboxKeyKeyDelete;
  ToolboxToolboxPkeyConnect: ToolboxToolboxPkeyConnect;
  ToolboxToolboxPkeyDelete: ToolboxToolboxPkeyDelete;
  ToolboxVersion: ToolboxVersion;
  ToolboxVersionCondition: ToolboxVersionCondition;
  ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInput: ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInput;
  ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInverseInput: ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEInverseInput;
  ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEToolboxExitcodeCreateInput: ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEToolboxExitcodeCreateInput;
  ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEToolboxVersionExitcodesCreateInput: ToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEToolboxVersionExitcodesCreateInput;
  ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInput: ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInput;
  ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInverseInput: ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVInverseInput;
  ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVToolboxVersionCreateInput: ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVToolboxVersionCreateInput;
  ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVToolboxVersionExitcodesCreateInput: ToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVToolboxVersionExitcodesCreateInput;
  ToolboxVersionExitcode: ToolboxVersionExitcode;
  ToolboxVersionExitcodeCondition: ToolboxVersionExitcodeCondition;
  ToolboxVersionExitcodeFilter: ToolboxVersionExitcodeFilter;
  ToolboxVersionExitcodeInput: ToolboxVersionExitcodeInput;
  ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqUpdate: ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqUpdate;
  ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxVersionExitcodesPkeyUpdate: ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEUsingToolboxVersionExitcodesPkeyUpdate;
  ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqUpdate: ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqUpdate;
  ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxVersionExitcodesPkeyUpdate: ToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxVersionExitcodesPkeyUpdate;
  ToolboxVersionExitcodePatch: ToolboxVersionExitcodePatch;
  ToolboxVersionExitcodeToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqConnect: ToolboxVersionExitcodeToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqConnect;
  ToolboxVersionExitcodeToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqDelete: ToolboxVersionExitcodeToolboxVersionExitcodeToolboxversionIdToolboBab336C9UniqDelete;
  ToolboxVersionExitcodeToolboxVersionExitcodesPkeyConnect: ToolboxVersionExitcodeToolboxVersionExitcodesPkeyConnect;
  ToolboxVersionExitcodeToolboxVersionExitcodesPkeyDelete: ToolboxVersionExitcodeToolboxVersionExitcodesPkeyDelete;
  ToolboxVersionExitcodesConnection: ToolboxVersionExitcodesConnection;
  ToolboxVersionExitcodesEdge: ToolboxVersionExitcodesEdge;
  ToolboxVersionFilter: ToolboxVersionFilter;
  ToolboxVersionInput: ToolboxVersionInput;
  ToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingToolboxVersionPkeyUpdate: ToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingToolboxVersionPkeyUpdate;
  ToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingToolboxversionToolboxVersionUniqueUpdate: ToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVUsingToolboxversionToolboxVersionUniqueUpdate;
  ToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxVersionPkeyUpdate: ToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxVersionPkeyUpdate;
  ToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxversionToolboxVersionUniqueUpdate: ToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVUsingToolboxversionToolboxVersionUniqueUpdate;
  ToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxVersionPkeyUpdate: ToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxVersionPkeyUpdate;
  ToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxversionToolboxVersionUniqueUpdate: ToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdUsingToolboxversionToolboxVersionUniqueUpdate;
  ToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxVersionPkeyUpdate: ToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxVersionPkeyUpdate;
  ToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxversionToolboxVersionUniqueUpdate: ToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxversionToolboxVersionUniqueUpdate;
  ToolboxVersionPatch: ToolboxVersionPatch;
  ToolboxVersionToManyToolboxVersionExitcodeFilter: ToolboxVersionToManyToolboxVersionExitcodeFilter;
  ToolboxVersionToManyToolboxVersionToolVersionFilter: ToolboxVersionToManyToolboxVersionToolVersionFilter;
  ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInput: ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInput;
  ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInverseInput: ToolboxVersionToolToolboxversionId25F78C89FkToolboxVInverseInput;
  ToolboxVersionToolToolboxversionId25F78C89FkToolboxVToolboxVersionCreateInput: ToolboxVersionToolToolboxversionId25F78C89FkToolboxVToolboxVersionCreateInput;
  ToolboxVersionToolToolboxversionId25F78C89FkToolboxVToolboxVersionToolVersionsCreateInput: ToolboxVersionToolToolboxversionId25F78C89FkToolboxVToolboxVersionToolVersionsCreateInput;
  ToolboxVersionToolToolversionId4Ed31559FkToolVersInput: ToolboxVersionToolToolversionId4Ed31559FkToolVersInput;
  ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput: ToolboxVersionToolToolversionId4Ed31559FkToolVersInverseInput;
  ToolboxVersionToolToolversionId4Ed31559FkToolVersToolVersionCreateInput: ToolboxVersionToolToolversionId4Ed31559FkToolVersToolVersionCreateInput;
  ToolboxVersionToolToolversionId4Ed31559FkToolVersToolboxVersionToolVersionsCreateInput: ToolboxVersionToolToolversionId4Ed31559FkToolVersToolboxVersionToolVersionsCreateInput;
  ToolboxVersionToolVersion: ToolboxVersionToolVersion;
  ToolboxVersionToolVersionCondition: ToolboxVersionToolVersionCondition;
  ToolboxVersionToolVersionFilter: ToolboxVersionToolVersionFilter;
  ToolboxVersionToolVersionInput: ToolboxVersionToolVersionInput;
  ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqUpdate: ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqUpdate;
  ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxVersionToolVersionsPkeyUpdate: ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVUsingToolboxVersionToolVersionsPkeyUpdate;
  ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqUpdate: ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqUpdate;
  ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolboxVersionToolVersionsPkeyUpdate: ToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersUsingToolboxVersionToolVersionsPkeyUpdate;
  ToolboxVersionToolVersionPatch: ToolboxVersionToolVersionPatch;
  ToolboxVersionToolVersionToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqConnect: ToolboxVersionToolVersionToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqConnect;
  ToolboxVersionToolVersionToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqDelete: ToolboxVersionToolVersionToolboxVersionToolVerToolboxversionIdToolve08C1B09DUniqDelete;
  ToolboxVersionToolVersionToolboxVersionToolVersionsPkeyConnect: ToolboxVersionToolVersionToolboxVersionToolVersionsPkeyConnect;
  ToolboxVersionToolVersionToolboxVersionToolVersionsPkeyDelete: ToolboxVersionToolVersionToolboxVersionToolVersionsPkeyDelete;
  ToolboxVersionToolVersionsConnection: ToolboxVersionToolVersionsConnection;
  ToolboxVersionToolVersionsEdge: ToolboxVersionToolVersionsEdge;
  ToolboxVersionToolboxId5Cc86066FkToolboxIdInput: ToolboxVersionToolboxId5Cc86066FkToolboxIdInput;
  ToolboxVersionToolboxId5Cc86066FkToolboxIdInverseInput: ToolboxVersionToolboxId5Cc86066FkToolboxIdInverseInput;
  ToolboxVersionToolboxId5Cc86066FkToolboxIdToolboxCreateInput: ToolboxVersionToolboxId5Cc86066FkToolboxIdToolboxCreateInput;
  ToolboxVersionToolboxId5Cc86066FkToolboxIdToolboxVersionCreateInput: ToolboxVersionToolboxId5Cc86066FkToolboxIdToolboxVersionCreateInput;
  ToolboxVersionToolboxVersionPkeyConnect: ToolboxVersionToolboxVersionPkeyConnect;
  ToolboxVersionToolboxVersionPkeyDelete: ToolboxVersionToolboxVersionPkeyDelete;
  ToolboxVersionToolboxversionToolboxVersionUniqueConnect: ToolboxVersionToolboxversionToolboxVersionUniqueConnect;
  ToolboxVersionToolboxversionToolboxVersionUniqueDelete: ToolboxVersionToolboxversionToolboxVersionUniqueDelete;
  ToolboxVersionsConnection: ToolboxVersionsConnection;
  ToolboxVersionsEdge: ToolboxVersionsEdge;
  ToolboxesConnection: ToolboxesConnection;
  ToolboxesEdge: ToolboxesEdge;
  ToolsConnection: ToolsConnection;
  ToolsEdge: ToolsEdge;
  UUID: Scalars["UUID"]["output"];
  UUIDFilter: UuidFilter;
  UpdateAnalysisTableByIdInput: UpdateAnalysisTableByIdInput;
  UpdateAnalysisTableGroupByIdInput: UpdateAnalysisTableGroupByIdInput;
  UpdateAnalysisTableGroupPayload: UpdateAnalysisTableGroupPayload;
  UpdateAnalysisTablePayload: UpdateAnalysisTablePayload;
  UpdateAnalysisTableRowAttachResultByIdInput: UpdateAnalysisTableRowAttachResultByIdInput;
  UpdateAnalysisTableRowAttachResultPayload: UpdateAnalysisTableRowAttachResultPayload;
  UpdateAnalysisTableRowByIdInput: UpdateAnalysisTableRowByIdInput;
  UpdateAnalysisTableRowByTaskIdInput: UpdateAnalysisTableRowByTaskIdInput;
  UpdateAnalysisTableRowPayload: UpdateAnalysisTableRowPayload;
  UpdateApplicationUserByEmailInput: UpdateApplicationUserByEmailInput;
  UpdateApplicationUserByIdInput: UpdateApplicationUserByIdInput;
  UpdateApplicationUserByUsernameInput: UpdateApplicationUserByUsernameInput;
  UpdateApplicationUserGroupByIdInput: UpdateApplicationUserGroupByIdInput;
  UpdateApplicationUserGroupByUserIdAndGroupIdInput: UpdateApplicationUserGroupByUserIdAndGroupIdInput;
  UpdateApplicationUserGroupPayload: UpdateApplicationUserGroupPayload;
  UpdateApplicationUserMembershipByIdInput: UpdateApplicationUserMembershipByIdInput;
  UpdateApplicationUserMembershipByUserIdAndTenantIdInput: UpdateApplicationUserMembershipByUserIdAndTenantIdInput;
  UpdateApplicationUserMembershipPayload: UpdateApplicationUserMembershipPayload;
  UpdateApplicationUserMetadatumByIdInput: UpdateApplicationUserMetadatumByIdInput;
  UpdateApplicationUserMetadatumByUserIdAndKeyInput: UpdateApplicationUserMetadatumByUserIdAndKeyInput;
  UpdateApplicationUserMetadatumPayload: UpdateApplicationUserMetadatumPayload;
  UpdateApplicationUserPayload: UpdateApplicationUserPayload;
  UpdateApplicationUserSessionByIdInput: UpdateApplicationUserSessionByIdInput;
  UpdateApplicationUserSessionPayload: UpdateApplicationUserSessionPayload;
  UpdateApplicationUserUserPermissionByIdInput: UpdateApplicationUserUserPermissionByIdInput;
  UpdateApplicationUserUserPermissionByUserIdAndPermissionIdInput: UpdateApplicationUserUserPermissionByUserIdAndPermissionIdInput;
  UpdateApplicationUserUserPermissionPayload: UpdateApplicationUserUserPermissionPayload;
  UpdateAuthGroupByIdInput: UpdateAuthGroupByIdInput;
  UpdateAuthGroupByNameInput: UpdateAuthGroupByNameInput;
  UpdateAuthGroupPayload: UpdateAuthGroupPayload;
  UpdateAuthGroupPermissionByGroupIdAndPermissionIdInput: UpdateAuthGroupPermissionByGroupIdAndPermissionIdInput;
  UpdateAuthGroupPermissionByIdInput: UpdateAuthGroupPermissionByIdInput;
  UpdateAuthGroupPermissionPayload: UpdateAuthGroupPermissionPayload;
  UpdateAuthPermissionByContentTypeIdAndCodenameInput: UpdateAuthPermissionByContentTypeIdAndCodenameInput;
  UpdateAuthPermissionByIdInput: UpdateAuthPermissionByIdInput;
  UpdateAuthPermissionPayload: UpdateAuthPermissionPayload;
  UpdateAvatarByIdInput: UpdateAvatarByIdInput;
  UpdateAvatarByUserIdInput: UpdateAvatarByUserIdInput;
  UpdateAvatarPayload: UpdateAvatarPayload;
  UpdateAwsBatchTaskByTaskexecutorPtrIdInput: UpdateAwsBatchTaskByTaskexecutorPtrIdInput;
  UpdateAwsBatchTaskPayload: UpdateAwsBatchTaskPayload;
  UpdateAwsCognitoUserByUserexecutorPtrIdInput: UpdateAwsCognitoUserByUserexecutorPtrIdInput;
  UpdateAwsCognitoUserPayload: UpdateAwsCognitoUserPayload;
  UpdateAwsEcrImageByContainerimageexecutorPtrIdInput: UpdateAwsEcrImageByContainerimageexecutorPtrIdInput;
  UpdateAwsEcrImagePayload: UpdateAwsEcrImagePayload;
  UpdateAwsMockBatchTaskByAwstaskexecutorPtrIdInput: UpdateAwsMockBatchTaskByAwstaskexecutorPtrIdInput;
  UpdateAwsMockBatchTaskPayload: UpdateAwsMockBatchTaskPayload;
  UpdateAwsMockEcrImageByAwscontainerimageexecutorPtrIdInput: UpdateAwsMockEcrImageByAwscontainerimageexecutorPtrIdInput;
  UpdateAwsMockEcrImagePayload: UpdateAwsMockEcrImagePayload;
  UpdateAwsMockS3FileByAwsfileexecutorPtrIdInput: UpdateAwsMockS3FileByAwsfileexecutorPtrIdInput;
  UpdateAwsMockS3FilePayload: UpdateAwsMockS3FilePayload;
  UpdateAwsS3FileByFileexecutorPtrIdInput: UpdateAwsS3FileByFileexecutorPtrIdInput;
  UpdateAwsS3FilePayload: UpdateAwsS3FilePayload;
  UpdateContainerImageByExecutorIdInput: UpdateContainerImageByExecutorIdInput;
  UpdateContainerImageByIdInput: UpdateContainerImageByIdInput;
  UpdateContainerImageByNameAndTagInput: UpdateContainerImageByNameAndTagInput;
  UpdateContainerImageByRepositoryAndTagInput: UpdateContainerImageByRepositoryAndTagInput;
  UpdateContainerImageByToolboxVersionIdInput: UpdateContainerImageByToolboxVersionIdInput;
  UpdateContainerImagePayload: UpdateContainerImagePayload;
  UpdateCostByIdInput: UpdateCostByIdInput;
  UpdateCostPayload: UpdateCostPayload;
  UpdateDatasetByIdInput: UpdateDatasetByIdInput;
  UpdateDatasetByProjectIdAndPrefixInput: UpdateDatasetByProjectIdAndPrefixInput;
  UpdateDatasetByRecordingsTableIdInput: UpdateDatasetByRecordingsTableIdInput;
  UpdateDatasetByTenantIdAndKeyInput: UpdateDatasetByTenantIdAndKeyInput;
  UpdateDatasetMetadatumByIdInput: UpdateDatasetMetadatumByIdInput;
  UpdateDatasetMetadatumPayload: UpdateDatasetMetadatumPayload;
  UpdateDatasetPayload: UpdateDatasetPayload;
  UpdateDatasetRecordingsTableByIdInput: UpdateDatasetRecordingsTableByIdInput;
  UpdateDatasetRecordingsTableColumnByIdInput: UpdateDatasetRecordingsTableColumnByIdInput;
  UpdateDatasetRecordingsTableColumnOrderByIdInput: UpdateDatasetRecordingsTableColumnOrderByIdInput;
  UpdateDatasetRecordingsTableColumnOrderPayload: UpdateDatasetRecordingsTableColumnOrderPayload;
  UpdateDatasetRecordingsTableColumnPayload: UpdateDatasetRecordingsTableColumnPayload;
  UpdateDatasetRecordingsTablePayload: UpdateDatasetRecordingsTablePayload;
  UpdateDatasetVersionByIdInput: UpdateDatasetVersionByIdInput;
  UpdateDatasetVersionPayload: UpdateDatasetVersionPayload;
  UpdateDjangoAdminLogByIdInput: UpdateDjangoAdminLogByIdInput;
  UpdateDjangoAdminLogPayload: UpdateDjangoAdminLogPayload;
  UpdateDjangoContentTypeByAppLabelAndModelInput: UpdateDjangoContentTypeByAppLabelAndModelInput;
  UpdateDjangoContentTypeByIdInput: UpdateDjangoContentTypeByIdInput;
  UpdateDjangoContentTypePayload: UpdateDjangoContentTypePayload;
  UpdateDjangoMigrationByIdInput: UpdateDjangoMigrationByIdInput;
  UpdateDjangoMigrationPayload: UpdateDjangoMigrationPayload;
  UpdateDjangoSesSesstatByDateInput: UpdateDjangoSesSesstatByDateInput;
  UpdateDjangoSesSesstatByIdInput: UpdateDjangoSesSesstatByIdInput;
  UpdateDjangoSesSesstatPayload: UpdateDjangoSesSesstatPayload;
  UpdateDjangoSessionBySessionKeyInput: UpdateDjangoSessionBySessionKeyInput;
  UpdateDjangoSessionPayload: UpdateDjangoSessionPayload;
  UpdateDrsFileexecutorByIdInput: UpdateDrsFileexecutorByIdInput;
  UpdateDrsFileexecutorPayload: UpdateDrsFileexecutorPayload;
  UpdateDrsMockfileexecutorByFileexecutorPtrIdInput: UpdateDrsMockfileexecutorByFileexecutorPtrIdInput;
  UpdateDrsMockfileexecutorPayload: UpdateDrsMockfileexecutorPayload;
  UpdateEulaVersionByIdInput: UpdateEulaVersionByIdInput;
  UpdateEulaVersionByVersionInput: UpdateEulaVersionByVersionInput;
  UpdateEulaVersionPayload: UpdateEulaVersionPayload;
  UpdateEulaVersionUserAcceptanceByIdInput: UpdateEulaVersionUserAcceptanceByIdInput;
  UpdateEulaVersionUserAcceptanceByUserIdAndEulaVersionIdInput: UpdateEulaVersionUserAcceptanceByUserIdAndEulaVersionIdInput;
  UpdateEulaVersionUserAcceptancePayload: UpdateEulaVersionUserAcceptancePayload;
  UpdateExplorerQueryByIdInput: UpdateExplorerQueryByIdInput;
  UpdateExplorerQueryPayload: UpdateExplorerQueryPayload;
  UpdateExplorerQueryfavoriteByIdInput: UpdateExplorerQueryfavoriteByIdInput;
  UpdateExplorerQueryfavoriteByQueryIdAndUserIdInput: UpdateExplorerQueryfavoriteByQueryIdAndUserIdInput;
  UpdateExplorerQueryfavoritePayload: UpdateExplorerQueryfavoritePayload;
  UpdateExplorerQuerylogByIdInput: UpdateExplorerQuerylogByIdInput;
  UpdateExplorerQuerylogPayload: UpdateExplorerQuerylogPayload;
  UpdateFileActivityByIdInput: UpdateFileActivityByIdInput;
  UpdateFileActivityPayload: UpdateFileActivityPayload;
  UpdateFileByIdInput: UpdateFileByIdInput;
  UpdateFileMetadatumByIdInput: UpdateFileMetadatumByIdInput;
  UpdateFileMetadatumPayload: UpdateFileMetadatumPayload;
  UpdateFileOutputGroupByIdInput: UpdateFileOutputGroupByIdInput;
  UpdateFileOutputGroupByTaskIdInput: UpdateFileOutputGroupByTaskIdInput;
  UpdateFileOutputGroupMetadatumByIdInput: UpdateFileOutputGroupMetadatumByIdInput;
  UpdateFileOutputGroupMetadatumPayload: UpdateFileOutputGroupMetadatumPayload;
  UpdateFileOutputGroupPayload: UpdateFileOutputGroupPayload;
  UpdateFilePayload: UpdateFilePayload;
  UpdateFileRecordingGroupByIdInput: UpdateFileRecordingGroupByIdInput;
  UpdateFileRecordingGroupMetadatumByIdInput: UpdateFileRecordingGroupMetadatumByIdInput;
  UpdateFileRecordingGroupMetadatumPayload: UpdateFileRecordingGroupMetadatumPayload;
  UpdateFileRecordingGroupPayload: UpdateFileRecordingGroupPayload;
  UpdateMetadataValueByIdInput: UpdateMetadataValueByIdInput;
  UpdateMetadataValuePayload: UpdateMetadataValuePayload;
  UpdateMetadatumByIdInput: UpdateMetadatumByIdInput;
  UpdateMetadatumPayload: UpdateMetadatumPayload;
  UpdateNotificationByIdInput: UpdateNotificationByIdInput;
  UpdateNotificationPayload: UpdateNotificationPayload;
  UpdateOutputGroupFileByIdInput: UpdateOutputGroupFileByIdInput;
  UpdateOutputGroupFilePayload: UpdateOutputGroupFilePayload;
  UpdateRecordingGroupFileByIdInput: UpdateRecordingGroupFileByIdInput;
  UpdateRecordingGroupFilePayload: UpdateRecordingGroupFilePayload;
  UpdateRunByIdInput: UpdateRunByIdInput;
  UpdateRunPayload: UpdateRunPayload;
  UpdateTagByIdInput: UpdateTagByIdInput;
  UpdateTagPayload: UpdateTagPayload;
  UpdateTaskActivityByIdInput: UpdateTaskActivityByIdInput;
  UpdateTaskActivityByTaskIdInput: UpdateTaskActivityByTaskIdInput;
  UpdateTaskActivityPayload: UpdateTaskActivityPayload;
  UpdateTaskByExecutorIdInput: UpdateTaskByExecutorIdInput;
  UpdateTaskByIdInput: UpdateTaskByIdInput;
  UpdateTaskPayload: UpdateTaskPayload;
  UpdateTaskResourceByIdInput: UpdateTaskResourceByIdInput;
  UpdateTaskResourcePayload: UpdateTaskResourcePayload;
  UpdateTaskSourceByIdInput: UpdateTaskSourceByIdInput;
  UpdateTaskSourcePayload: UpdateTaskSourcePayload;
  UpdateTaskStatusLogByIdInput: UpdateTaskStatusLogByIdInput;
  UpdateTaskStatusLogByTaskIdAndStatusInput: UpdateTaskStatusLogByTaskIdAndStatusInput;
  UpdateTaskStatusLogPayload: UpdateTaskStatusLogPayload;
  UpdateTenantByIdInput: UpdateTenantByIdInput;
  UpdateTenantByKeyInput: UpdateTenantByKeyInput;
  UpdateTenantByNameInput: UpdateTenantByNameInput;
  UpdateTenantContractAllowanceByIdInput: UpdateTenantContractAllowanceByIdInput;
  UpdateTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeInput: UpdateTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeInput;
  UpdateTenantContractAllowancePayload: UpdateTenantContractAllowancePayload;
  UpdateTenantContractByIdInput: UpdateTenantContractByIdInput;
  UpdateTenantContractByNameInput: UpdateTenantContractByNameInput;
  UpdateTenantContractPayload: UpdateTenantContractPayload;
  UpdateTenantContractTransactionByIdInput: UpdateTenantContractTransactionByIdInput;
  UpdateTenantContractTransactionPayload: UpdateTenantContractTransactionPayload;
  UpdateTenantMockuserexecutorByUserexecutorPtrIdInput: UpdateTenantMockuserexecutorByUserexecutorPtrIdInput;
  UpdateTenantMockuserexecutorPayload: UpdateTenantMockuserexecutorPayload;
  UpdateTenantMonthlyConsumedResourceByIdInput: UpdateTenantMonthlyConsumedResourceByIdInput;
  UpdateTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractInput: UpdateTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractInput;
  UpdateTenantMonthlyConsumedResourcePayload: UpdateTenantMonthlyConsumedResourcePayload;
  UpdateTenantPayload: UpdateTenantPayload;
  UpdateTenantUserexecutorByIdInput: UpdateTenantUserexecutorByIdInput;
  UpdateTenantUserexecutorPayload: UpdateTenantUserexecutorPayload;
  UpdateTesContainerimageexecutorByIdInput: UpdateTesContainerimageexecutorByIdInput;
  UpdateTesContainerimageexecutorPayload: UpdateTesContainerimageexecutorPayload;
  UpdateTesMockcontainerimageexecutorByContainerimageexecutorPtrIdInput: UpdateTesMockcontainerimageexecutorByContainerimageexecutorPtrIdInput;
  UpdateTesMockcontainerimageexecutorPayload: UpdateTesMockcontainerimageexecutorPayload;
  UpdateTesMocktaskexecutorByTaskexecutorPtrIdInput: UpdateTesMocktaskexecutorByTaskexecutorPtrIdInput;
  UpdateTesMocktaskexecutorPayload: UpdateTesMocktaskexecutorPayload;
  UpdateTesTaskexecutorByIdInput: UpdateTesTaskexecutorByIdInput;
  UpdateTesTaskexecutorPayload: UpdateTesTaskexecutorPayload;
  UpdateToolByIdInput: UpdateToolByIdInput;
  UpdateToolByKeyInput: UpdateToolByKeyInput;
  UpdateToolCategoryByIdInput: UpdateToolCategoryByIdInput;
  UpdateToolCategoryByKeyInput: UpdateToolCategoryByKeyInput;
  UpdateToolCategoryPayload: UpdateToolCategoryPayload;
  UpdateToolDisplayCategoryByIdInput: UpdateToolDisplayCategoryByIdInput;
  UpdateToolDisplayCategoryByToolIdAndToolcategoryIdInput: UpdateToolDisplayCategoryByToolIdAndToolcategoryIdInput;
  UpdateToolDisplayCategoryPayload: UpdateToolDisplayCategoryPayload;
  UpdateToolParameterByIdInput: UpdateToolParameterByIdInput;
  UpdateToolParameterPayload: UpdateToolParameterPayload;
  UpdateToolPayload: UpdateToolPayload;
  UpdateToolResourceByIdInput: UpdateToolResourceByIdInput;
  UpdateToolResourceByTypeAndToolVersionIdInput: UpdateToolResourceByTypeAndToolVersionIdInput;
  UpdateToolResourcePayload: UpdateToolResourcePayload;
  UpdateToolResultByIdInput: UpdateToolResultByIdInput;
  UpdateToolResultPayload: UpdateToolResultPayload;
  UpdateToolSecretByIdInput: UpdateToolSecretByIdInput;
  UpdateToolSecretPayload: UpdateToolSecretPayload;
  UpdateToolSourceByIdInput: UpdateToolSourceByIdInput;
  UpdateToolSourcePayload: UpdateToolSourcePayload;
  UpdateToolTenantByIdInput: UpdateToolTenantByIdInput;
  UpdateToolTenantByToolIdAndTenantIdInput: UpdateToolTenantByToolIdAndTenantIdInput;
  UpdateToolTenantPayload: UpdateToolTenantPayload;
  UpdateToolVersionByIdInput: UpdateToolVersionByIdInput;
  UpdateToolVersionByToolIdAndVersionInput: UpdateToolVersionByToolIdAndVersionInput;
  UpdateToolVersionPayload: UpdateToolVersionPayload;
  UpdateToolboxByIdInput: UpdateToolboxByIdInput;
  UpdateToolboxByKeyInput: UpdateToolboxByKeyInput;
  UpdateToolboxExitcodeByCodeInput: UpdateToolboxExitcodeByCodeInput;
  UpdateToolboxExitcodeByIdInput: UpdateToolboxExitcodeByIdInput;
  UpdateToolboxExitcodePayload: UpdateToolboxExitcodePayload;
  UpdateToolboxPayload: UpdateToolboxPayload;
  UpdateToolboxTenantByIdInput: UpdateToolboxTenantByIdInput;
  UpdateToolboxTenantByToolboxIdAndTenantIdInput: UpdateToolboxTenantByToolboxIdAndTenantIdInput;
  UpdateToolboxTenantPayload: UpdateToolboxTenantPayload;
  UpdateToolboxVersionByIdInput: UpdateToolboxVersionByIdInput;
  UpdateToolboxVersionByToolboxIdAndVersionInput: UpdateToolboxVersionByToolboxIdAndVersionInput;
  UpdateToolboxVersionExitcodeByIdInput: UpdateToolboxVersionExitcodeByIdInput;
  UpdateToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdInput: UpdateToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdInput;
  UpdateToolboxVersionExitcodePayload: UpdateToolboxVersionExitcodePayload;
  UpdateToolboxVersionPayload: UpdateToolboxVersionPayload;
  UpdateToolboxVersionToolVersionByIdInput: UpdateToolboxVersionToolVersionByIdInput;
  UpdateToolboxVersionToolVersionByToolboxversionIdAndToolversionIdInput: UpdateToolboxVersionToolVersionByToolboxversionIdAndToolversionIdInput;
  UpdateToolboxVersionToolVersionPayload: UpdateToolboxVersionToolVersionPayload;
  UpdateWorkflowByIdInput: UpdateWorkflowByIdInput;
  UpdateWorkflowDatumByIdInput: UpdateWorkflowDatumByIdInput;
  UpdateWorkflowDatumPayload: UpdateWorkflowDatumPayload;
  UpdateWorkflowPayload: UpdateWorkflowPayload;
  UpdateWorkflowResultByIdInput: UpdateWorkflowResultByIdInput;
  UpdateWorkflowResultPayload: UpdateWorkflowResultPayload;
  UpdateWorkflowResultTagByIdInput: UpdateWorkflowResultTagByIdInput;
  UpdateWorkflowResultTagByResultIdAndTagIdInput: UpdateWorkflowResultTagByResultIdAndTagIdInput;
  UpdateWorkflowResultTagPayload: UpdateWorkflowResultTagPayload;
  UpdateWorkflowResultWorkflowVersionByIdInput: UpdateWorkflowResultWorkflowVersionByIdInput;
  UpdateWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdInput: UpdateWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdInput;
  UpdateWorkflowResultWorkflowVersionPayload: UpdateWorkflowResultWorkflowVersionPayload;
  UpdateWorkflowSectionBlockByIdInput: UpdateWorkflowSectionBlockByIdInput;
  UpdateWorkflowSectionBlockPayload: UpdateWorkflowSectionBlockPayload;
  UpdateWorkflowSectionBlockSectionByBlockIdAndSectionIdInput: UpdateWorkflowSectionBlockSectionByBlockIdAndSectionIdInput;
  UpdateWorkflowSectionBlockSectionByIdInput: UpdateWorkflowSectionBlockSectionByIdInput;
  UpdateWorkflowSectionBlockSectionPayload: UpdateWorkflowSectionBlockSectionPayload;
  UpdateWorkflowSectionByIdInput: UpdateWorkflowSectionByIdInput;
  UpdateWorkflowSectionPayload: UpdateWorkflowSectionPayload;
  UpdateWorkflowSectionWorkflowVersionByIdInput: UpdateWorkflowSectionWorkflowVersionByIdInput;
  UpdateWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdInput: UpdateWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdInput;
  UpdateWorkflowSectionWorkflowVersionPayload: UpdateWorkflowSectionWorkflowVersionPayload;
  UpdateWorkflowSourceByIdInput: UpdateWorkflowSourceByIdInput;
  UpdateWorkflowSourcePayload: UpdateWorkflowSourcePayload;
  UpdateWorkflowSourceTagByIdInput: UpdateWorkflowSourceTagByIdInput;
  UpdateWorkflowSourceTagBySourceIdAndTagIdInput: UpdateWorkflowSourceTagBySourceIdAndTagIdInput;
  UpdateWorkflowSourceTagPayload: UpdateWorkflowSourceTagPayload;
  UpdateWorkflowSourceWorkflowVersionByIdInput: UpdateWorkflowSourceWorkflowVersionByIdInput;
  UpdateWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdInput: UpdateWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdInput;
  UpdateWorkflowSourceWorkflowVersionPayload: UpdateWorkflowSourceWorkflowVersionPayload;
  UpdateWorkflowVersionByIdInput: UpdateWorkflowVersionByIdInput;
  UpdateWorkflowVersionPayload: UpdateWorkflowVersionPayload;
  UserAccessToProject: UserAccessToProject;
  UserAccessToProjectCondition: UserAccessToProjectCondition;
  UserAccessToProjectFilter: UserAccessToProjectFilter;
  UserAccessToProjectsConnection: UserAccessToProjectsConnection;
  UserAccessToProjectsEdge: UserAccessToProjectsEdge;
  Workflow: Workflow;
  WorkflowCondition: WorkflowCondition;
  WorkflowContainerIdE73A96E5FkContainerImageIdContainerImageCreateInput: WorkflowContainerIdE73A96E5FkContainerImageIdContainerImageCreateInput;
  WorkflowContainerIdE73A96E5FkContainerImageIdInput: WorkflowContainerIdE73A96E5FkContainerImageIdInput;
  WorkflowContainerIdE73A96E5FkContainerImageIdInverseInput: WorkflowContainerIdE73A96E5FkContainerImageIdInverseInput;
  WorkflowContainerIdE73A96E5FkContainerImageIdWorkflowCreateInput: WorkflowContainerIdE73A96E5FkContainerImageIdWorkflowCreateInput;
  WorkflowDataConnection: WorkflowDataConnection;
  WorkflowDataEdge: WorkflowDataEdge;
  WorkflowDatum: WorkflowDatum;
  WorkflowDatumCondition: WorkflowDatumCondition;
  WorkflowDatumFilter: WorkflowDatumFilter;
  WorkflowDatumInput: WorkflowDatumInput;
  WorkflowDatumOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdUsingWorkflowDataPkeyUpdate: WorkflowDatumOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdUsingWorkflowDataPkeyUpdate;
  WorkflowDatumOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdUsingWorkflowDataPkeyUpdate: WorkflowDatumOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdUsingWorkflowDataPkeyUpdate;
  WorkflowDatumPatch: WorkflowDatumPatch;
  WorkflowDatumToManyWorkflowResultFilter: WorkflowDatumToManyWorkflowResultFilter;
  WorkflowDatumToManyWorkflowSectionBlockFilter: WorkflowDatumToManyWorkflowSectionBlockFilter;
  WorkflowDatumWorkflowDataPkeyConnect: WorkflowDatumWorkflowDataPkeyConnect;
  WorkflowDatumWorkflowDataPkeyDelete: WorkflowDatumWorkflowDataPkeyDelete;
  WorkflowFilter: WorkflowFilter;
  WorkflowInput: WorkflowInput;
  WorkflowOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingWorkflowPkeyUpdate: WorkflowOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdUsingWorkflowPkeyUpdate;
  WorkflowOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingWorkflowPkeyUpdate: WorkflowOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdUsingWorkflowPkeyUpdate;
  WorkflowOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingWorkflowPkeyUpdate: WorkflowOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdUsingWorkflowPkeyUpdate;
  WorkflowOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdUsingWorkflowPkeyUpdate: WorkflowOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdUsingWorkflowPkeyUpdate;
  WorkflowPatch: WorkflowPatch;
  WorkflowResult: WorkflowResult;
  WorkflowResultCondition: WorkflowResultCondition;
  WorkflowResultFilter: WorkflowResultFilter;
  WorkflowResultInput: WorkflowResultInput;
  WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInput: WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInput;
  WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInverseInput: WorkflowResultObjectIdC561D1C5FkWorkflowDataIdInverseInput;
  WorkflowResultObjectIdC561D1C5FkWorkflowDataIdWorkflowDataCreateInput: WorkflowResultObjectIdC561D1C5FkWorkflowDataIdWorkflowDataCreateInput;
  WorkflowResultObjectIdC561D1C5FkWorkflowDataIdWorkflowResultCreateInput: WorkflowResultObjectIdC561D1C5FkWorkflowDataIdWorkflowResultCreateInput;
  WorkflowResultOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdUsingWorkflowResultPkeyUpdate: WorkflowResultOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdUsingWorkflowResultPkeyUpdate;
  WorkflowResultOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdUsingWorkflowResultPkeyUpdate: WorkflowResultOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdUsingWorkflowResultPkeyUpdate;
  WorkflowResultOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowUsingWorkflowResultPkeyUpdate: WorkflowResultOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowUsingWorkflowResultPkeyUpdate;
  WorkflowResultPatch: WorkflowResultPatch;
  WorkflowResultTag: WorkflowResultTag;
  WorkflowResultTagCondition: WorkflowResultTagCondition;
  WorkflowResultTagFilter: WorkflowResultTagFilter;
  WorkflowResultTagInput: WorkflowResultTagInput;
  WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdUsingWorkflowResultTagsPkeyUpdate: WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdUsingWorkflowResultTagsPkeyUpdate;
  WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdUsingWorkflowResultTagsResultIdTagId960B8A2CUniqUpdate: WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdUsingWorkflowResultTagsResultIdTagId960B8A2CUniqUpdate;
  WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdUsingWorkflowResultTagsPkeyUpdate: WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdUsingWorkflowResultTagsPkeyUpdate;
  WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdUsingWorkflowResultTagsResultIdTagId960B8A2CUniqUpdate: WorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdUsingWorkflowResultTagsResultIdTagId960B8A2CUniqUpdate;
  WorkflowResultTagPatch: WorkflowResultTagPatch;
  WorkflowResultTagWorkflowResultTagsPkeyConnect: WorkflowResultTagWorkflowResultTagsPkeyConnect;
  WorkflowResultTagWorkflowResultTagsPkeyDelete: WorkflowResultTagWorkflowResultTagsPkeyDelete;
  WorkflowResultTagWorkflowResultTagsResultIdTagId960B8A2CUniqConnect: WorkflowResultTagWorkflowResultTagsResultIdTagId960B8A2CUniqConnect;
  WorkflowResultTagWorkflowResultTagsResultIdTagId960B8A2CUniqDelete: WorkflowResultTagWorkflowResultTagsResultIdTagId960B8A2CUniqDelete;
  WorkflowResultTagsConnection: WorkflowResultTagsConnection;
  WorkflowResultTagsEdge: WorkflowResultTagsEdge;
  WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInput: WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInput;
  WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInverseInput: WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdInverseInput;
  WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdWorkflowResultCreateInput: WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdWorkflowResultCreateInput;
  WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdWorkflowResultTagsCreateInput: WorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdWorkflowResultTagsCreateInput;
  WorkflowResultTagsTagId4B7994A6FkTagIdInput: WorkflowResultTagsTagId4B7994A6FkTagIdInput;
  WorkflowResultTagsTagId4B7994A6FkTagIdInverseInput: WorkflowResultTagsTagId4B7994A6FkTagIdInverseInput;
  WorkflowResultTagsTagId4B7994A6FkTagIdTagCreateInput: WorkflowResultTagsTagId4B7994A6FkTagIdTagCreateInput;
  WorkflowResultTagsTagId4B7994A6FkTagIdWorkflowResultTagsCreateInput: WorkflowResultTagsTagId4B7994A6FkTagIdWorkflowResultTagsCreateInput;
  WorkflowResultToManyWorkflowResultTagFilter: WorkflowResultToManyWorkflowResultTagFilter;
  WorkflowResultToManyWorkflowResultWorkflowVersionFilter: WorkflowResultToManyWorkflowResultWorkflowVersionFilter;
  WorkflowResultWorkResultId50F959E2FkWorkflowInput: WorkflowResultWorkResultId50F959E2FkWorkflowInput;
  WorkflowResultWorkResultId50F959E2FkWorkflowInverseInput: WorkflowResultWorkResultId50F959E2FkWorkflowInverseInput;
  WorkflowResultWorkResultId50F959E2FkWorkflowWorkflowResultCreateInput: WorkflowResultWorkResultId50F959E2FkWorkflowWorkflowResultCreateInput;
  WorkflowResultWorkResultId50F959E2FkWorkflowWorkflowResultWorkflowVersionsCreateInput: WorkflowResultWorkResultId50F959E2FkWorkflowWorkflowResultWorkflowVersionsCreateInput;
  WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInput: WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInput;
  WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInverseInput: WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowInverseInput;
  WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowWorkflowResultWorkflowVersionsCreateInput: WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowWorkflowResultWorkflowVersionsCreateInput;
  WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowWorkflowVersionCreateInput: WorkflowResultWorkWorkflowversionIdB9123570FkWorkflowWorkflowVersionCreateInput;
  WorkflowResultWorkflowResultPkeyConnect: WorkflowResultWorkflowResultPkeyConnect;
  WorkflowResultWorkflowResultPkeyDelete: WorkflowResultWorkflowResultPkeyDelete;
  WorkflowResultWorkflowVersion: WorkflowResultWorkflowVersion;
  WorkflowResultWorkflowVersionCondition: WorkflowResultWorkflowVersionCondition;
  WorkflowResultWorkflowVersionFilter: WorkflowResultWorkflowVersionFilter;
  WorkflowResultWorkflowVersionInput: WorkflowResultWorkflowVersionInput;
  WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowUsingWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqUpdate: WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowUsingWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqUpdate;
  WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowUsingWorkflowResultWorkflowVersionsPkeyUpdate: WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowUsingWorkflowResultWorkflowVersionsPkeyUpdate;
  WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowUsingWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqUpdate: WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowUsingWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqUpdate;
  WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowUsingWorkflowResultWorkflowVersionsPkeyUpdate: WorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowUsingWorkflowResultWorkflowVersionsPkeyUpdate;
  WorkflowResultWorkflowVersionPatch: WorkflowResultWorkflowVersionPatch;
  WorkflowResultWorkflowVersionWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqConnect: WorkflowResultWorkflowVersionWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqConnect;
  WorkflowResultWorkflowVersionWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqDelete: WorkflowResultWorkflowVersionWorkflowResultWorkflowResultIdWorkflowversioF1Af219DUniqDelete;
  WorkflowResultWorkflowVersionWorkflowResultWorkflowVersionsPkeyConnect: WorkflowResultWorkflowVersionWorkflowResultWorkflowVersionsPkeyConnect;
  WorkflowResultWorkflowVersionWorkflowResultWorkflowVersionsPkeyDelete: WorkflowResultWorkflowVersionWorkflowResultWorkflowVersionsPkeyDelete;
  WorkflowResultWorkflowVersionsConnection: WorkflowResultWorkflowVersionsConnection;
  WorkflowResultWorkflowVersionsEdge: WorkflowResultWorkflowVersionsEdge;
  WorkflowResultsConnection: WorkflowResultsConnection;
  WorkflowResultsEdge: WorkflowResultsEdge;
  WorkflowSection: WorkflowSection;
  WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInput: WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInput;
  WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInverseInput: WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowInverseInput;
  WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowWorkflowSectionBlockCreateInput: WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowWorkflowSectionBlockCreateInput;
  WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowWorkflowSectionBlockSectionsCreateInput: WorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowWorkflowSectionBlockSectionsCreateInput;
  WorkflowSectionBloSectionId817C1C77FkWorkflowInput: WorkflowSectionBloSectionId817C1C77FkWorkflowInput;
  WorkflowSectionBloSectionId817C1C77FkWorkflowInverseInput: WorkflowSectionBloSectionId817C1C77FkWorkflowInverseInput;
  WorkflowSectionBloSectionId817C1C77FkWorkflowWorkflowSectionBlockSectionsCreateInput: WorkflowSectionBloSectionId817C1C77FkWorkflowWorkflowSectionBlockSectionsCreateInput;
  WorkflowSectionBloSectionId817C1C77FkWorkflowWorkflowSectionCreateInput: WorkflowSectionBloSectionId817C1C77FkWorkflowWorkflowSectionCreateInput;
  WorkflowSectionBlock: WorkflowSectionBlock;
  WorkflowSectionBlockCondition: WorkflowSectionBlockCondition;
  WorkflowSectionBlockFilter: WorkflowSectionBlockFilter;
  WorkflowSectionBlockInput: WorkflowSectionBlockInput;
  WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInput: WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInput;
  WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInverseInput: WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdInverseInput;
  WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdWorkflowDataCreateInput: WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdWorkflowDataCreateInput;
  WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdWorkflowSectionBlockCreateInput: WorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdWorkflowSectionBlockCreateInput;
  WorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdUsingWorkflowSectionBlockPkeyUpdate: WorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdUsingWorkflowSectionBlockPkeyUpdate;
  WorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdUsingWorkflowSectionBlockPkeyUpdate: WorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdUsingWorkflowSectionBlockPkeyUpdate;
  WorkflowSectionBlockOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowUsingWorkflowSectionBlockPkeyUpdate: WorkflowSectionBlockOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowUsingWorkflowSectionBlockPkeyUpdate;
  WorkflowSectionBlockPatch: WorkflowSectionBlockPatch;
  WorkflowSectionBlockSection: WorkflowSectionBlockSection;
  WorkflowSectionBlockSectionCondition: WorkflowSectionBlockSectionCondition;
  WorkflowSectionBlockSectionFilter: WorkflowSectionBlockSectionFilter;
  WorkflowSectionBlockSectionInput: WorkflowSectionBlockSectionInput;
  WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowUsingWorkflowSectionBlockSBlockIdSectionId2F785D93UniqUpdate: WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowUsingWorkflowSectionBlockSBlockIdSectionId2F785D93UniqUpdate;
  WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowUsingWorkflowSectionBlockSectionsPkeyUpdate: WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowUsingWorkflowSectionBlockSectionsPkeyUpdate;
  WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowUsingWorkflowSectionBlockSBlockIdSectionId2F785D93UniqUpdate: WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowUsingWorkflowSectionBlockSBlockIdSectionId2F785D93UniqUpdate;
  WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowUsingWorkflowSectionBlockSectionsPkeyUpdate: WorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowUsingWorkflowSectionBlockSectionsPkeyUpdate;
  WorkflowSectionBlockSectionPatch: WorkflowSectionBlockSectionPatch;
  WorkflowSectionBlockSectionWorkflowSectionBlockSBlockIdSectionId2F785D93UniqConnect: WorkflowSectionBlockSectionWorkflowSectionBlockSBlockIdSectionId2F785D93UniqConnect;
  WorkflowSectionBlockSectionWorkflowSectionBlockSBlockIdSectionId2F785D93UniqDelete: WorkflowSectionBlockSectionWorkflowSectionBlockSBlockIdSectionId2F785D93UniqDelete;
  WorkflowSectionBlockSectionWorkflowSectionBlockSectionsPkeyConnect: WorkflowSectionBlockSectionWorkflowSectionBlockSectionsPkeyConnect;
  WorkflowSectionBlockSectionWorkflowSectionBlockSectionsPkeyDelete: WorkflowSectionBlockSectionWorkflowSectionBlockSectionsPkeyDelete;
  WorkflowSectionBlockSectionsConnection: WorkflowSectionBlockSectionsConnection;
  WorkflowSectionBlockSectionsEdge: WorkflowSectionBlockSectionsEdge;
  WorkflowSectionBlockToManyWorkflowSectionBlockSectionFilter: WorkflowSectionBlockToManyWorkflowSectionBlockSectionFilter;
  WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInput: WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInput;
  WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput: WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdInverseInput;
  WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdToolVersionCreateInput: WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdToolVersionCreateInput;
  WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdWorkflowSectionBlockCreateInput: WorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdWorkflowSectionBlockCreateInput;
  WorkflowSectionBlockWorkflowSectionBlockPkeyConnect: WorkflowSectionBlockWorkflowSectionBlockPkeyConnect;
  WorkflowSectionBlockWorkflowSectionBlockPkeyDelete: WorkflowSectionBlockWorkflowSectionBlockPkeyDelete;
  WorkflowSectionBlocksConnection: WorkflowSectionBlocksConnection;
  WorkflowSectionBlocksEdge: WorkflowSectionBlocksEdge;
  WorkflowSectionCondition: WorkflowSectionCondition;
  WorkflowSectionFilter: WorkflowSectionFilter;
  WorkflowSectionInput: WorkflowSectionInput;
  WorkflowSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowUsingWorkflowSectionPkeyUpdate: WorkflowSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowUsingWorkflowSectionPkeyUpdate;
  WorkflowSectionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowUsingWorkflowSectionPkeyUpdate: WorkflowSectionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowUsingWorkflowSectionPkeyUpdate;
  WorkflowSectionPatch: WorkflowSectionPatch;
  WorkflowSectionToManyWorkflowSectionBlockSectionFilter: WorkflowSectionToManyWorkflowSectionBlockSectionFilter;
  WorkflowSectionToManyWorkflowSectionWorkflowVersionFilter: WorkflowSectionToManyWorkflowSectionWorkflowVersionFilter;
  WorkflowSectionWorSectionId1D5C2D63FkWorkflowInput: WorkflowSectionWorSectionId1D5C2D63FkWorkflowInput;
  WorkflowSectionWorSectionId1D5C2D63FkWorkflowInverseInput: WorkflowSectionWorSectionId1D5C2D63FkWorkflowInverseInput;
  WorkflowSectionWorSectionId1D5C2D63FkWorkflowWorkflowSectionCreateInput: WorkflowSectionWorSectionId1D5C2D63FkWorkflowWorkflowSectionCreateInput;
  WorkflowSectionWorSectionId1D5C2D63FkWorkflowWorkflowSectionWorkflowVersionsCreateInput: WorkflowSectionWorSectionId1D5C2D63FkWorkflowWorkflowSectionWorkflowVersionsCreateInput;
  WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInput: WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInput;
  WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInverseInput: WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowInverseInput;
  WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowWorkflowSectionWorkflowVersionsCreateInput: WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowWorkflowSectionWorkflowVersionsCreateInput;
  WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowWorkflowVersionCreateInput: WorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowWorkflowVersionCreateInput;
  WorkflowSectionWorkflowSectionPkeyConnect: WorkflowSectionWorkflowSectionPkeyConnect;
  WorkflowSectionWorkflowSectionPkeyDelete: WorkflowSectionWorkflowSectionPkeyDelete;
  WorkflowSectionWorkflowVersion: WorkflowSectionWorkflowVersion;
  WorkflowSectionWorkflowVersionCondition: WorkflowSectionWorkflowVersionCondition;
  WorkflowSectionWorkflowVersionFilter: WorkflowSectionWorkflowVersionFilter;
  WorkflowSectionWorkflowVersionInput: WorkflowSectionWorkflowVersionInput;
  WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowUsingWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqUpdate: WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowUsingWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqUpdate;
  WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowUsingWorkflowSectionWorkflowVersionsPkeyUpdate: WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowUsingWorkflowSectionWorkflowVersionsPkeyUpdate;
  WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowUsingWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqUpdate: WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowUsingWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqUpdate;
  WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowUsingWorkflowSectionWorkflowVersionsPkeyUpdate: WorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowUsingWorkflowSectionWorkflowVersionsPkeyUpdate;
  WorkflowSectionWorkflowVersionPatch: WorkflowSectionWorkflowVersionPatch;
  WorkflowSectionWorkflowVersionWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqConnect: WorkflowSectionWorkflowVersionWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqConnect;
  WorkflowSectionWorkflowVersionWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqDelete: WorkflowSectionWorkflowVersionWorkflowSectionWorkfloSectionIdWorkflowversi9Bd5E089UniqDelete;
  WorkflowSectionWorkflowVersionWorkflowSectionWorkflowVersionsPkeyConnect: WorkflowSectionWorkflowVersionWorkflowSectionWorkflowVersionsPkeyConnect;
  WorkflowSectionWorkflowVersionWorkflowSectionWorkflowVersionsPkeyDelete: WorkflowSectionWorkflowVersionWorkflowSectionWorkflowVersionsPkeyDelete;
  WorkflowSectionWorkflowVersionsConnection: WorkflowSectionWorkflowVersionsConnection;
  WorkflowSectionWorkflowVersionsEdge: WorkflowSectionWorkflowVersionsEdge;
  WorkflowSectionsConnection: WorkflowSectionsConnection;
  WorkflowSectionsEdge: WorkflowSectionsEdge;
  WorkflowSource: WorkflowSource;
  WorkflowSourceCondition: WorkflowSourceCondition;
  WorkflowSourceFilter: WorkflowSourceFilter;
  WorkflowSourceInput: WorkflowSourceInput;
  WorkflowSourceOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdUsingWorkflowSourcePkeyUpdate: WorkflowSourceOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdUsingWorkflowSourcePkeyUpdate;
  WorkflowSourceOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowUsingWorkflowSourcePkeyUpdate: WorkflowSourceOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowUsingWorkflowSourcePkeyUpdate;
  WorkflowSourcePatch: WorkflowSourcePatch;
  WorkflowSourceTag: WorkflowSourceTag;
  WorkflowSourceTagCondition: WorkflowSourceTagCondition;
  WorkflowSourceTagFilter: WorkflowSourceTagFilter;
  WorkflowSourceTagInput: WorkflowSourceTagInput;
  WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdUsingWorkflowSourceTagsPkeyUpdate: WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdUsingWorkflowSourceTagsPkeyUpdate;
  WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdUsingWorkflowSourceTagsSourceIdTagId1455Ea80UniqUpdate: WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdUsingWorkflowSourceTagsSourceIdTagId1455Ea80UniqUpdate;
  WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdUsingWorkflowSourceTagsPkeyUpdate: WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdUsingWorkflowSourceTagsPkeyUpdate;
  WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdUsingWorkflowSourceTagsSourceIdTagId1455Ea80UniqUpdate: WorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdUsingWorkflowSourceTagsSourceIdTagId1455Ea80UniqUpdate;
  WorkflowSourceTagPatch: WorkflowSourceTagPatch;
  WorkflowSourceTagWorkflowSourceTagsPkeyConnect: WorkflowSourceTagWorkflowSourceTagsPkeyConnect;
  WorkflowSourceTagWorkflowSourceTagsPkeyDelete: WorkflowSourceTagWorkflowSourceTagsPkeyDelete;
  WorkflowSourceTagWorkflowSourceTagsSourceIdTagId1455Ea80UniqConnect: WorkflowSourceTagWorkflowSourceTagsSourceIdTagId1455Ea80UniqConnect;
  WorkflowSourceTagWorkflowSourceTagsSourceIdTagId1455Ea80UniqDelete: WorkflowSourceTagWorkflowSourceTagsSourceIdTagId1455Ea80UniqDelete;
  WorkflowSourceTagsConnection: WorkflowSourceTagsConnection;
  WorkflowSourceTagsEdge: WorkflowSourceTagsEdge;
  WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInput: WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInput;
  WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInverseInput: WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdInverseInput;
  WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdWorkflowSourceCreateInput: WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdWorkflowSourceCreateInput;
  WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdWorkflowSourceTagsCreateInput: WorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdWorkflowSourceTagsCreateInput;
  WorkflowSourceTagsTagId690E6935FkTagIdInput: WorkflowSourceTagsTagId690E6935FkTagIdInput;
  WorkflowSourceTagsTagId690E6935FkTagIdInverseInput: WorkflowSourceTagsTagId690E6935FkTagIdInverseInput;
  WorkflowSourceTagsTagId690E6935FkTagIdTagCreateInput: WorkflowSourceTagsTagId690E6935FkTagIdTagCreateInput;
  WorkflowSourceTagsTagId690E6935FkTagIdWorkflowSourceTagsCreateInput: WorkflowSourceTagsTagId690E6935FkTagIdWorkflowSourceTagsCreateInput;
  WorkflowSourceToManyWorkflowSourceTagFilter: WorkflowSourceToManyWorkflowSourceTagFilter;
  WorkflowSourceToManyWorkflowSourceWorkflowVersionFilter: WorkflowSourceToManyWorkflowSourceWorkflowVersionFilter;
  WorkflowSourceWorkSourceId608A289DFkWorkflowInput: WorkflowSourceWorkSourceId608A289DFkWorkflowInput;
  WorkflowSourceWorkSourceId608A289DFkWorkflowInverseInput: WorkflowSourceWorkSourceId608A289DFkWorkflowInverseInput;
  WorkflowSourceWorkSourceId608A289DFkWorkflowWorkflowSourceCreateInput: WorkflowSourceWorkSourceId608A289DFkWorkflowWorkflowSourceCreateInput;
  WorkflowSourceWorkSourceId608A289DFkWorkflowWorkflowSourceWorkflowVersionsCreateInput: WorkflowSourceWorkSourceId608A289DFkWorkflowWorkflowSourceWorkflowVersionsCreateInput;
  WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInput: WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInput;
  WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInverseInput: WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowInverseInput;
  WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowWorkflowSourceWorkflowVersionsCreateInput: WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowWorkflowSourceWorkflowVersionsCreateInput;
  WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowWorkflowVersionCreateInput: WorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowWorkflowVersionCreateInput;
  WorkflowSourceWorkflowSourcePkeyConnect: WorkflowSourceWorkflowSourcePkeyConnect;
  WorkflowSourceWorkflowSourcePkeyDelete: WorkflowSourceWorkflowSourcePkeyDelete;
  WorkflowSourceWorkflowVersion: WorkflowSourceWorkflowVersion;
  WorkflowSourceWorkflowVersionCondition: WorkflowSourceWorkflowVersionCondition;
  WorkflowSourceWorkflowVersionFilter: WorkflowSourceWorkflowVersionFilter;
  WorkflowSourceWorkflowVersionInput: WorkflowSourceWorkflowVersionInput;
  WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowUsingWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqUpdate: WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowUsingWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqUpdate;
  WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowUsingWorkflowSourceWorkflowVersionsPkeyUpdate: WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowUsingWorkflowSourceWorkflowVersionsPkeyUpdate;
  WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowUsingWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqUpdate: WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowUsingWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqUpdate;
  WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowUsingWorkflowSourceWorkflowVersionsPkeyUpdate: WorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowUsingWorkflowSourceWorkflowVersionsPkeyUpdate;
  WorkflowSourceWorkflowVersionPatch: WorkflowSourceWorkflowVersionPatch;
  WorkflowSourceWorkflowVersionWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqConnect: WorkflowSourceWorkflowVersionWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqConnect;
  WorkflowSourceWorkflowVersionWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqDelete: WorkflowSourceWorkflowVersionWorkflowSourceWorkflowSourceIdWorkflowversio20446Ce3UniqDelete;
  WorkflowSourceWorkflowVersionWorkflowSourceWorkflowVersionsPkeyConnect: WorkflowSourceWorkflowVersionWorkflowSourceWorkflowVersionsPkeyConnect;
  WorkflowSourceWorkflowVersionWorkflowSourceWorkflowVersionsPkeyDelete: WorkflowSourceWorkflowVersionWorkflowSourceWorkflowVersionsPkeyDelete;
  WorkflowSourceWorkflowVersionsConnection: WorkflowSourceWorkflowVersionsConnection;
  WorkflowSourceWorkflowVersionsEdge: WorkflowSourceWorkflowVersionsEdge;
  WorkflowSourcesConnection: WorkflowSourcesConnection;
  WorkflowSourcesEdge: WorkflowSourcesEdge;
  WorkflowTenantId9469Ff84FkTenantIdInput: WorkflowTenantId9469Ff84FkTenantIdInput;
  WorkflowTenantId9469Ff84FkTenantIdInverseInput: WorkflowTenantId9469Ff84FkTenantIdInverseInput;
  WorkflowTenantId9469Ff84FkTenantIdTenantCreateInput: WorkflowTenantId9469Ff84FkTenantIdTenantCreateInput;
  WorkflowTenantId9469Ff84FkTenantIdWorkflowCreateInput: WorkflowTenantId9469Ff84FkTenantIdWorkflowCreateInput;
  WorkflowToManyWorkflowVersionFilter: WorkflowToManyWorkflowVersionFilter;
  WorkflowUserId121C1967FkApplicationUserIdApplicationUserCreateInput: WorkflowUserId121C1967FkApplicationUserIdApplicationUserCreateInput;
  WorkflowUserId121C1967FkApplicationUserIdInput: WorkflowUserId121C1967FkApplicationUserIdInput;
  WorkflowUserId121C1967FkApplicationUserIdInverseInput: WorkflowUserId121C1967FkApplicationUserIdInverseInput;
  WorkflowUserId121C1967FkApplicationUserIdWorkflowCreateInput: WorkflowUserId121C1967FkApplicationUserIdWorkflowCreateInput;
  WorkflowVersion: WorkflowVersion;
  WorkflowVersionCondition: WorkflowVersionCondition;
  WorkflowVersionFilter: WorkflowVersionFilter;
  WorkflowVersionInput: WorkflowVersionInput;
  WorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowUsingWorkflowVersionPkeyUpdate: WorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowUsingWorkflowVersionPkeyUpdate;
  WorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowUsingWorkflowVersionPkeyUpdate: WorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowUsingWorkflowVersionPkeyUpdate;
  WorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowUsingWorkflowVersionPkeyUpdate: WorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowUsingWorkflowVersionPkeyUpdate;
  WorkflowVersionOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingWorkflowVersionPkeyUpdate: WorkflowVersionOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdUsingWorkflowVersionPkeyUpdate;
  WorkflowVersionOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdUsingWorkflowVersionPkeyUpdate: WorkflowVersionOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdUsingWorkflowVersionPkeyUpdate;
  WorkflowVersionPatch: WorkflowVersionPatch;
  WorkflowVersionToManyWorkflowResultWorkflowVersionFilter: WorkflowVersionToManyWorkflowResultWorkflowVersionFilter;
  WorkflowVersionToManyWorkflowSectionWorkflowVersionFilter: WorkflowVersionToManyWorkflowSectionWorkflowVersionFilter;
  WorkflowVersionToManyWorkflowSourceWorkflowVersionFilter: WorkflowVersionToManyWorkflowSourceWorkflowVersionFilter;
  WorkflowVersionUserId00C0F62FFkApplicationUserIdApplicationUserCreateInput: WorkflowVersionUserId00C0F62FFkApplicationUserIdApplicationUserCreateInput;
  WorkflowVersionUserId00C0F62FFkApplicationUserIdInput: WorkflowVersionUserId00C0F62FFkApplicationUserIdInput;
  WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput: WorkflowVersionUserId00C0F62FFkApplicationUserIdInverseInput;
  WorkflowVersionUserId00C0F62FFkApplicationUserIdWorkflowVersionCreateInput: WorkflowVersionUserId00C0F62FFkApplicationUserIdWorkflowVersionCreateInput;
  WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInput: WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInput;
  WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInverseInput: WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdInverseInput;
  WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdWorkflowCreateInput: WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdWorkflowCreateInput;
  WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdWorkflowVersionCreateInput: WorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdWorkflowVersionCreateInput;
  WorkflowVersionWorkflowVersionPkeyConnect: WorkflowVersionWorkflowVersionPkeyConnect;
  WorkflowVersionWorkflowVersionPkeyDelete: WorkflowVersionWorkflowVersionPkeyDelete;
  WorkflowVersionsConnection: WorkflowVersionsConnection;
  WorkflowVersionsEdge: WorkflowVersionsEdge;
  WorkflowWorkflowPkeyConnect: WorkflowWorkflowPkeyConnect;
  WorkflowWorkflowPkeyDelete: WorkflowWorkflowPkeyDelete;
  WorkflowsConnection: WorkflowsConnection;
  WorkflowsEdge: WorkflowsEdge;
  updateAnalysisTableGroupOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdPatch: UpdateAnalysisTableGroupOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdPatch;
  updateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdPatch: UpdateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdPatch;
  updateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdPatch: UpdateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdPatch;
  updateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdPatch: UpdateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdPatch;
  updateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdPatch: UpdateAnalysisTableGroupOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdPatch;
  updateAnalysisTableOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdPatch: UpdateAnalysisTableOnAnalysisTableForAnalysisTableGroupId5Defc0D1FkAnalysisTableGroupIdPatch;
  updateAnalysisTableOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdPatch: UpdateAnalysisTableOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdPatch;
  updateAnalysisTableOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdPatch: UpdateAnalysisTableOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdPatch;
  updateAnalysisTableOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdPatch: UpdateAnalysisTableOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdPatch;
  updateAnalysisTableOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdPatch: UpdateAnalysisTableOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdPatch;
  updateAnalysisTableOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdPatch: UpdateAnalysisTableOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdPatch;
  updateAnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisPatch: UpdateAnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisPatch;
  updateAnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIPatch: UpdateAnalysisTableRowAttachResultOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIPatch;
  updateAnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisPatch: UpdateAnalysisTableRowOnAnalysisTableRowAttachResultForAnalysisTableRowAAnalysisTableRowI94B76888FkAnalysisPatch;
  updateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdPatch;
  updateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTableIdB1C24185FkAnalysisTableIdPatch;
  updateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdPatch;
  updateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdPatch;
  updateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdPatch: UpdateAnalysisTableRowOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdPatch;
  updateApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdPatch: UpdateApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdPatch;
  updateApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdPatch: UpdateApplicationUserGroupOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdPatch;
  updateApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiPatch: UpdateApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiPatch;
  updateApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdPatch: UpdateApplicationUserMembershipOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdPatch;
  updateApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiPatch: UpdateApplicationUserMetadatumOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiPatch;
  updateApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdPatch: UpdateApplicationUserOnAnalysisTableForAnalysisTableUserIdD5D6De41FkApplicationUserIdPatch;
  updateApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdPatch: UpdateApplicationUserOnAnalysisTableGroupForAnalysisTableGroupUserId4C7Ff352FkApplicationUserIdPatch;
  updateApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdPatch: UpdateApplicationUserOnApplicationUserGroupForApplicationUserGroupsUserIdE5A7130FFkApplicationUserIdPatch;
  updateApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiPatch: UpdateApplicationUserOnApplicationUserMembershipForApplicationUserMemUserId631Fddc5FkApplicatiPatch;
  updateApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiPatch: UpdateApplicationUserOnApplicationUserMetadatumForApplicationUserMetUserId897Fbde1FkApplicatiPatch;
  updateApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiPatch: UpdateApplicationUserOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiPatch;
  updateApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiPatch: UpdateApplicationUserOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiPatch;
  updateApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdPatch: UpdateApplicationUserOnAvatarForAvatarUserId771332F2FkApplicationUserIdPatch;
  updateApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdPatch: UpdateApplicationUserOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdPatch;
  updateApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdPatch: UpdateApplicationUserOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdPatch;
  updateApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiPatch: UpdateApplicationUserOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiPatch;
  updateApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiPatch: UpdateApplicationUserOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiPatch;
  updateApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdPatch: UpdateApplicationUserOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdPatch;
  updateApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiPatch: UpdateApplicationUserOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiPatch;
  updateApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdPatch: UpdateApplicationUserOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdPatch;
  updateApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdPatch: UpdateApplicationUserOnFileForFileUserId01D90B53FkApplicationUserIdPatch;
  updateApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdPatch: UpdateApplicationUserOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdPatch;
  updateApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiPatch: UpdateApplicationUserOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiPatch;
  updateApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdPatch: UpdateApplicationUserOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdPatch;
  updateApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdPatch: UpdateApplicationUserOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdPatch;
  updateApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdPatch: UpdateApplicationUserOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdPatch;
  updateApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiPatch: UpdateApplicationUserOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiPatch;
  updateApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdPatch: UpdateApplicationUserOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdPatch;
  updateApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiPatch: UpdateApplicationUserOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiPatch;
  updateApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiPatch: UpdateApplicationUserOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiPatch;
  updateApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdPatch: UpdateApplicationUserOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdPatch;
  updateApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiPatch: UpdateApplicationUserOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiPatch;
  updateApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdPatch: UpdateApplicationUserOnTagForTagUserIdB3B7Fb50FkApplicationUserIdPatch;
  updateApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdPatch: UpdateApplicationUserOnTaskForTaskUserId270D0Bb2FkApplicationUserIdPatch;
  updateApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiPatch: UpdateApplicationUserOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiPatch;
  updateApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiPatch: UpdateApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiPatch;
  updateApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiPatch: UpdateApplicationUserOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiPatch;
  updateApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdPatch: UpdateApplicationUserOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdPatch;
  updateApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdPatch: UpdateApplicationUserOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdPatch;
  updateApplicationUserSessionOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiPatch: UpdateApplicationUserSessionOnApplicationUserSessionForApplicationUserSesUserId2Fb30D0CFkApplicatiPatch;
  updateApplicationUserSessionOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdPatch: UpdateApplicationUserSessionOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdPatch;
  updateApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermPatch: UpdateApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermPatch;
  updateApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiPatch: UpdateApplicationUserUserPermissionOnApplicationUserUserPermissionForApplicationUserUseUserIdE643F548FkApplicatiPatch;
  updateAuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdPatch: UpdateAuthGroupOnApplicationUserGroupForApplicationUserGroupsGroupId7157B7AcFkAuthGroupIdPatch;
  updateAuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdPatch: UpdateAuthGroupOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdPatch;
  updateAuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermPatch: UpdateAuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermPatch;
  updateAuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdPatch: UpdateAuthGroupPermissionOnAuthGroupPermissionForAuthGroupPermissionsGroupIdB120Cbf9FkAuthGroupIdPatch;
  updateAuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermPatch: UpdateAuthPermissionOnApplicationUserUserPermissionForApplicationUserUsePermissionIdDbf9693DFkAuthPermPatch;
  updateAuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermPatch: UpdateAuthPermissionOnAuthGroupPermissionForAuthGroupPermissioPermissionId84C5C92EFkAuthPermPatch;
  updateAuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoPatch: UpdateAuthPermissionOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoPatch;
  updateAvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdPatch: UpdateAvatarOnAvatarForAvatarUserId771332F2FkApplicationUserIdPatch;
  updateAwsBatchTaskOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskePatch: UpdateAwsBatchTaskOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskePatch;
  updateAwsBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchPatch: UpdateAwsBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchPatch;
  updateAwsCognitoUserOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsPatch: UpdateAwsCognitoUserOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsPatch;
  updateAwsEcrImageOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaPatch: UpdateAwsEcrImageOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaPatch;
  updateAwsEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIPatch: UpdateAwsEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIPatch;
  updateAwsMockBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchPatch: UpdateAwsMockBatchTaskOnAwsMockBatchTaskForAwsMockBatchTaskAwstaskexecutorPtrFfae22F7FkAwsBatchPatch;
  updateAwsMockEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIPatch: UpdateAwsMockEcrImageOnAwsMockEcrImageForAwsMockEcrImageAwscontainerimageexe31C2A1A9FkAwsEcrIPatch;
  updateAwsMockS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiPatch: UpdateAwsMockS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiPatch;
  updateAwsS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiPatch: UpdateAwsS3FileOnAwsMockS3FileForAwsMockS3FileAwsfileexecutorPtrC1Bbee03FkAwsS3FiPatch;
  updateAwsS3FileOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdPatch: UpdateAwsS3FileOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdPatch;
  updateContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaPatch: UpdateContainerImageOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaPatch;
  updateContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVPatch: UpdateContainerImageOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVPatch;
  updateContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdPatch: UpdateContainerImageOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdPatch;
  updateContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdPatch: UpdateContainerImageOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdPatch;
  updateCostOnCostForCostTenantId7Dcd58A7FkTenantIdPatch: UpdateCostOnCostForCostTenantId7Dcd58A7FkTenantIdPatch;
  updateCostOnFileActivityForFileActivityCostId2A88D90AFkCostIdPatch: UpdateCostOnFileActivityForFileActivityCostId2A88D90AFkCostIdPatch;
  updateCostOnTaskActivityForTaskActivityCostId49E66058FkCostIdPatch: UpdateCostOnTaskActivityForTaskActivityCostId49E66058FkCostIdPatch;
  updateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdPatch: UpdateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdPatch;
  updateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdPatch: UpdateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdPatch;
  updateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdPatch: UpdateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdPatch;
  updateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdPatch: UpdateDatasetMetadatumOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdPatch;
  updateDatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdPatch: UpdateDatasetOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdPatch;
  updateDatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdPatch: UpdateDatasetOnDatasetForDatasetV4ProjectId98907276FkProjectIdPatch;
  updateDatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRPatch: UpdateDatasetOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRPatch;
  updateDatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdPatch: UpdateDatasetOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdPatch;
  updateDatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdPatch: UpdateDatasetOnDatasetForDatasetV4UserId5B9A2303FkApplicationUserIdPatch;
  updateDatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdPatch: UpdateDatasetOnDatasetMetadatumForDatasetMetadataDatasetIdC504A1DdFkDatasetV4IdPatch;
  updateDatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdPatch: UpdateDatasetOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdPatch;
  updateDatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdPatch: UpdateDatasetOnFileForFileDatasetIdC0A4045AFkDatasetV4IdPatch;
  updateDatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdPatch: UpdateDatasetOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdPatch;
  updateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataPatch: UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataPatch;
  updateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRPatch: UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRPatch;
  updateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIPatch: UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIPatch;
  updateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdPatch: UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdPatch;
  updateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRPatch: UpdateDatasetRecordingsTableColumnOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRPatch;
  updateDatasetRecordingsTableColumnOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRPatch: UpdateDatasetRecordingsTableColumnOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRPatch;
  updateDatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRPatch: UpdateDatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTDatasetRecordingsT4831B740FkDatasetRPatch;
  updateDatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIPatch: UpdateDatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIPatch;
  updateDatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdPatch: UpdateDatasetRecordingsTableColumnOrderOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdPatch;
  updateDatasetRecordingsTableOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRPatch: UpdateDatasetRecordingsTableOnDatasetForDatasetV4RecordingsTableIdD72E44AeFkDatasetRPatch;
  updateDatasetRecordingsTableOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRPatch: UpdateDatasetRecordingsTableOnDatasetRecordingsTableColumnForDatasetRecordingsTDatasetRecordingsTDffa98C2FkDatasetRPatch;
  updateDatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdPatch: UpdateDatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdPatch;
  updateDatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdPatch: UpdateDatasetRecordingsTableOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdPatch;
  updateDatasetRecordingsTableOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTB91F3991FkDatasetRPatch: UpdateDatasetRecordingsTableOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRPatch;
  updateDatasetVersionOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdPatch: UpdateDatasetVersionOnDatasetVersionForDatasetVersionDatasetId2F9Ad2A3FkDatasetV4IdPatch;
  updateDatasetVersionOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdPatch: UpdateDatasetVersionOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdPatch;
  updateDatasetVersionOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdPatch: UpdateDatasetVersionOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdPatch;
  updateDjangoAdminLogOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoPatch: UpdateDjangoAdminLogOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoPatch;
  updateDjangoAdminLogOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdPatch: UpdateDjangoAdminLogOnDjangoAdminLogForDjangoAdminLogUserIdC564Eba6FkApplicationUserIdPatch;
  updateDjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoPatch: UpdateDjangoContentTypeOnAuthPermissionForAuthPermissionContentTypeId2F476E4BFkDjangoCoPatch;
  updateDjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoPatch: UpdateDjangoContentTypeOnDjangoAdminLogForDjangoAdminLogContentTypeIdC4Bce8EbFkDjangoCoPatch;
  updateDjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoPatch: UpdateDjangoContentTypeOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoPatch;
  updateDjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoPatch: UpdateDjangoContentTypeOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoPatch;
  updateDjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoPatch: UpdateDjangoContentTypeOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoPatch;
  updateDjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoPatch: UpdateDjangoContentTypeOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoPatch;
  updateDrsFileexecutorOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdPatch: UpdateDrsFileexecutorOnAwsS3FileForAwsS3FileFileexecutorPtrId9869C5B4FkDrsFileexecutorIdPatch;
  updateDrsFileexecutorOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoPatch: UpdateDrsFileexecutorOnDrsFileexecutorForDrsFileexecutorPolymorphicCtypeId2Aecbaf1FkDjangoCoPatch;
  updateDrsFileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileePatch: UpdateDrsFileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileePatch;
  updateDrsMockfileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileePatch: UpdateDrsMockfileexecutorOnDrsMockfileexecutorForDrsMockfileexecutorFileexecutorPtrIdF4025193FkDrsFileePatch;
  updateEulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersPatch: UpdateEulaVersionOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersPatch;
  updateEulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersPatch: UpdateEulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcEulaVersionIdD2635C45FkEulaVersPatch;
  updateEulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiPatch: UpdateEulaVersionUserAcceptanceOnEulaVersionUserAcceptanceForEulaVersionUserAcUserIdB72Ed9C5FkApplicatiPatch;
  updateExplorerQueryOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiPatch: UpdateExplorerQueryOnExplorerQueryForExplorerQueryCreatedByUserId182Dd868FkApplicatiPatch;
  updateExplorerQueryOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdPatch: UpdateExplorerQueryOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdPatch;
  updateExplorerQueryOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdPatch: UpdateExplorerQueryOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdPatch;
  updateExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdPatch: UpdateExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteQueryId1F4E3Cf7FkExplorerQueryIdPatch;
  updateExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdPatch: UpdateExplorerQueryfavoriteOnExplorerQueryfavoriteForExplorerQueryfavoriteUserId5Ef2792CFkApplicationUserIdPatch;
  updateExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdPatch: UpdateExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogQueryId1635A6B4FkExplorerQueryIdPatch;
  updateExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiPatch: UpdateExplorerQuerylogOnExplorerQuerylogForExplorerQuerylogRunByUserIdCf26A49FFkApplicatiPatch;
  updateFileActivityOnFileActivityForFileActivityCostId2A88D90AFkCostIdPatch: UpdateFileActivityOnFileActivityForFileActivityCostId2A88D90AFkCostIdPatch;
  updateFileActivityOnFileActivityForFileActivityFileId015763A9FkFileIdPatch: UpdateFileActivityOnFileActivityForFileActivityFileId015763A9FkFileIdPatch;
  updateFileActivityOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdPatch: UpdateFileActivityOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdPatch;
  updateFileActivityOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdPatch: UpdateFileActivityOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdPatch;
  updateFileActivityOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdPatch: UpdateFileActivityOnFileActivityForFileActivityUserId868Ad8A9FkApplicationUserIdPatch;
  updateFileMetadatumOnFileMetadatumForFileMetadataFileId78256936FkFileIdPatch: UpdateFileMetadatumOnFileMetadatumForFileMetadataFileId78256936FkFileIdPatch;
  updateFileMetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdPatch: UpdateFileMetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdPatch;
  updateFileMetadatumOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdPatch: UpdateFileMetadatumOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdPatch;
  updateFileMetadatumOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdPatch: UpdateFileMetadatumOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdPatch;
  updateFileOnFileActivityForFileActivityFileId015763A9FkFileIdPatch: UpdateFileOnFileActivityForFileActivityFileId015763A9FkFileIdPatch;
  updateFileOnFileForFileDatasetIdC0A4045AFkDatasetV4IdPatch: UpdateFileOnFileForFileDatasetIdC0A4045AFkDatasetV4IdPatch;
  updateFileOnFileForFileProjectId3Ecca975FkProjectIdPatch: UpdateFileOnFileForFileProjectId3Ecca975FkProjectIdPatch;
  updateFileOnFileForFileSeriesParentIdF7A07E20FkFileIdPatch: UpdateFileOnFileForFileSeriesParentIdF7A07E20FkFileIdPatch;
  updateFileOnFileForFileTenantId61625016FkTenantIdPatch: UpdateFileOnFileForFileTenantId61625016FkTenantIdPatch;
  updateFileOnFileForFileUserId01D90B53FkApplicationUserIdPatch: UpdateFileOnFileForFileUserId01D90B53FkApplicationUserIdPatch;
  updateFileOnFileMetadatumForFileMetadataFileId78256936FkFileIdPatch: UpdateFileOnFileMetadatumForFileMetadataFileId78256936FkFileIdPatch;
  updateFileOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdPatch: UpdateFileOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdPatch;
  updateFileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdPatch: UpdateFileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdPatch;
  updateFileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdPatch: UpdateFileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdPatch;
  updateFileOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdPatch: UpdateFileOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdPatch;
  updateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpPatch: UpdateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpPatch;
  updateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataPatch: UpdateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataPatch;
  updateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdPatch: UpdateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdPatch;
  updateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdPatch: UpdateFileOutputGroupMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdPatch;
  updateFileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdPatch: UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdPatch;
  updateFileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdPatch: UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdPatch;
  updateFileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdPatch: UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdPatch;
  updateFileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdPatch: UpdateFileOutputGroupOnFileOutputGroupForFileOutputGroupUserIdB22Ec436FkApplicationUserIdPatch;
  updateFileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpPatch: UpdateFileOutputGroupOnFileOutputGroupMetadatumForFileOutputGroupMeFileOutputGroupIdE3798458FkFileOutpPatch;
  updateFileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpPatch: UpdateFileOutputGroupOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpPatch;
  updateFileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiPatch: UpdateFileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotiUserId08265156FkApplicatiPatch;
  updateFileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdPatch: UpdateFileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationFileId717B514EFkFileIdPatch;
  updateFileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdPatch: UpdateFileProcessingNotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdPatch;
  updateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoPatch: UpdateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoPatch;
  updateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataPatch: UpdateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataPatch;
  updateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdPatch: UpdateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdPatch;
  updateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdPatch: UpdateFileRecordingGroupMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdPatch;
  updateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTB91F3991FkDatasetRPatch: UpdateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupDatasetRecordingsTb91F3991FkDatasetRPatch;
  updateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdPatch: UpdateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdPatch;
  updateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdPatch: UpdateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdPatch;
  updateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdPatch: UpdateFileRecordingGroupOnFileRecordingGroupForFileRecordingGroupUserId421A5CddFkApplicationUserIdPatch;
  updateFileRecordingGroupOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoPatch: UpdateFileRecordingGroupOnFileRecordingGroupMetadatumForFileRecordingGroupFileRecordingGroup60583376FkFileRecoPatch;
  updateFileRecordingGroupOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoPatch: UpdateFileRecordingGroupOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoPatch;
  updateMetadataValueOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdPatch: UpdateMetadataValueOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdPatch;
  updateMetadataValueOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdPatch: UpdateMetadataValueOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdPatch;
  updateMetadataValueOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdPatch: UpdateMetadataValueOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdPatch;
  updateMetadataValueOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdPatch: UpdateMetadataValueOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdPatch;
  updateMetadataValueOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdPatch: UpdateMetadataValueOnMetadataValueForMetadataValueUserIdB2Bb45AeFkApplicationUserIdPatch;
  updateMetadatumOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdPatch: UpdateMetadatumOnDatasetForDatasetDescriptionId4043Bd78FkMetadataV4IdPatch;
  updateMetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdPatch: UpdateMetadatumOnDatasetMetadatumForDatasetMetadataMetadataIdE68Bb45EFkMetadataV4IdPatch;
  updateMetadatumOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataPatch: UpdateMetadatumOnDatasetRecordingsTableColumnForDatasetRecordingsTColDefId837663C9FkMetadataPatch;
  updateMetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdPatch: UpdateMetadatumOnFileMetadatumForFileMetadataMetadataIdFefc0061FkMetadataV4IdPatch;
  updateMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataPatch: UpdateMetadatumOnFileOutputGroupMetadatumForFileOutputGroupMeMetadataId3A08Fd87FkMetadataPatch;
  updateMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataPatch: UpdateMetadatumOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataIdF054E41AFkMetadataPatch;
  updateMetadatumOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdPatch: UpdateMetadatumOnMetadataValueForMetadataValueMetadataId063A53B6FkMetadataV4IdPatch;
  updateMetadatumOnMetadatumForMetadataProjectId3333C2AdFkProjectIdPatch: UpdateMetadatumOnMetadatumForMetadataProjectId3333C2AdFkProjectIdPatch;
  updateMetadatumOnMetadatumForMetadataV4TenantId163B6569FkTenantIdPatch: UpdateMetadatumOnMetadatumForMetadataV4TenantId163B6569FkTenantIdPatch;
  updateNotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdPatch: UpdateNotificationOnFileProcessingNotificationForFileProcessingNotificationId630E6495FkNotificationIdPatch;
  updateNotificationOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdPatch: UpdateNotificationOnNotificationForNotificationUserId1002Fc38FkApplicationUserIdPatch;
  updateNotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdPatch: UpdateNotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdPatch;
  updateNotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdPatch: UpdateNotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdPatch;
  updateNotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdPatch: UpdateNotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdPatch;
  updateNotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatPatch: UpdateNotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatPatch;
  updateOutputGroupFileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdPatch: UpdateOutputGroupFileOnOutputGroupFileForOutputGroupFilesFileIdEdeabb23FkFileIdPatch;
  updateOutputGroupFileOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpPatch: UpdateOutputGroupFileOnOutputGroupFileForOutputGroupFilesFileOutputGroupIdF5Ba9324FkFileOutpPatch;
  updateOutputGroupFileOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdPatch: UpdateOutputGroupFileOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdPatch;
  updateProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiPatch: UpdateProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotifUserId4Fd693D7FkApplicatiPatch;
  updateProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdPatch: UpdateProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationIdD235A018FkNotificationIdPatch;
  updateProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdPatch: UpdateProjectClonedNotificationOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdPatch;
  updateProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdPatch: UpdateProjectOnAnalysisTableForAnalysisTableProjectId709Aebc7FkProjectIdPatch;
  updateProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdPatch: UpdateProjectOnAnalysisTableGroupForAnalysisTableGroupProjectId870Aec99FkProjectIdPatch;
  updateProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIPatch: UpdateProjectOnAnalysisTableRowAttachResultForAnalysisTableRowAProjectIdE3E030E2FkProjectIPatch;
  updateProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdPatch: UpdateProjectOnAnalysisTableRowForAnalysisTableRowProjectIdCd370B28FkProjectIdPatch;
  updateProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdPatch: UpdateProjectOnDatasetForDatasetV4ProjectId98907276FkProjectIdPatch;
  updateProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdPatch: UpdateProjectOnDatasetMetadatumForDatasetMetadataProjectIdB921B40CFkProjectIdPatch;
  updateProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIPatch: UpdateProjectOnDatasetRecordingsTableColumnForDatasetRecordingsTProjectId40Dba6D7FkProjectIPatch;
  updateProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIPatch: UpdateProjectOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTProjectId8E1D93E1FkProjectIPatch;
  updateProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdPatch: UpdateProjectOnDatasetRecordingsTableForDatasetRecordingsTableProjectId710D9Eb2FkProjectIdPatch;
  updateProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdPatch: UpdateProjectOnDatasetVersionForDatasetVersionProjectId9E468C2EFkProjectIdPatch;
  updateProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdPatch: UpdateProjectOnFileActivityForFileActivityProjectId6F35B8CcFkProjectIdPatch;
  updateProjectOnFileForFileProjectId3Ecca975FkProjectIdPatch: UpdateProjectOnFileForFileProjectId3Ecca975FkProjectIdPatch;
  updateProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdPatch: UpdateProjectOnFileMetadatumForFileMetadataProjectId3474994EFkProjectIdPatch;
  updateProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdPatch: UpdateProjectOnFileOutputGroupForFileOutputGroupProjectId908Fb9FfFkProjectIdPatch;
  updateProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdPatch: UpdateProjectOnFileOutputGroupMetadatumForFileOutputGroupMetadataProjectIdD7463Dc3FkProjectIdPatch;
  updateProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdPatch: UpdateProjectOnFileRecordingGroupForFileRecordingGroupProjectIdB14Ba577FkProjectIdPatch;
  updateProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdPatch: UpdateProjectOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataProjectId7574Cf75FkProjectIdPatch;
  updateProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdPatch: UpdateProjectOnMetadataValueForMetadataValueProjectId87227D96FkProjectIdPatch;
  updateProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdPatch: UpdateProjectOnMetadatumForMetadataProjectId3333C2AdFkProjectIdPatch;
  updateProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdPatch: UpdateProjectOnOutputGroupFileForOutputGroupFilesProjectId8709B561FkProjectIdPatch;
  updateProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdPatch: UpdateProjectOnProjectClonedNotificationForProjectClonedNotificationProjectIdF6267374FkProjectIdPatch;
  updateProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdPatch: UpdateProjectOnProjectForProjectTenantId03F0Ca53FkTenantIdPatch;
  updateProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdPatch: UpdateProjectOnProjectForProjectUserIdB5Fbb914FkApplicationUserIdPatch;
  updateProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdPatch: UpdateProjectOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdPatch;
  updateProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdPatch: UpdateProjectOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdPatch;
  updateProjectOnTaskForTaskProjectId963D6354FkProjectIdPatch: UpdateProjectOnTaskForTaskProjectId963D6354FkProjectIdPatch;
  updateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiPatch: UpdateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifSharerIdAbe16C5BFkApplicatiPatch;
  updateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiPatch: UpdateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotifUserId9A19122FFkApplicatiPatch;
  updateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdPatch: UpdateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationIdD5Cd7E61FkNotificationIdPatch;
  updateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdPatch: UpdateProjectSharedNotificationOnProjectSharedNotificationForProjectSharedNotificationProjectIdCf7Edf4AFkProjectIdPatch;
  updateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRPatch: UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFileDatasetRecordingsT1C9Cb92DFkDatasetRPatch;
  updateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoPatch: UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFileFileRecordingGroup03Ba5182FkFileRecoPatch;
  updateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdPatch: UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesFileIdD657E9EdFkFileIdPatch;
  updateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdPatch: UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesProjectId3B6B53B3FkProjectIdPatch;
  updateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdPatch: UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdPatch;
  updateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdPatch: UpdateRecordingGroupFileOnRecordingGroupFileForRecordingGroupFilesUserId99A36997FkApplicationUserIdPatch;
  updateRoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiPatch: UpdateRoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificaUserId879Bd49AFkApplicatiPatch;
  updateRoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdPatch: UpdateRoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationIdD576A528FkNotificationIdPatch;
  updateRoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdPatch: UpdateRoleChangeNotificationOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdPatch;
  updateRunOnRunForRunTenantId73Fbd8D9FkTenantIdPatch: UpdateRunOnRunForRunTenantId73Fbd8D9FkTenantIdPatch;
  updateTagOnTagForTagParentIdD2A3Ee5EFkTagIdPatch: UpdateTagOnTagForTagParentIdD2A3Ee5EFkTagIdPatch;
  updateTagOnTagForTagTenantId8072B61FFkTenantIdPatch: UpdateTagOnTagForTagTenantId8072B61FFkTenantIdPatch;
  updateTagOnTagForTagUserIdB3B7Fb50FkApplicationUserIdPatch: UpdateTagOnTagForTagUserIdB3B7Fb50FkApplicationUserIdPatch;
  updateTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdPatch: UpdateTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdPatch;
  updateTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdPatch: UpdateTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdPatch;
  updateTaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdPatch: UpdateTaskActivityOnTaskActivityForTaskActivityCostId49E66058FkCostIdPatch;
  updateTaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdPatch: UpdateTaskActivityOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdPatch;
  updateTaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdPatch: UpdateTaskOnAnalysisTableRowForAnalysisTableRowTaskId742Ff0EdFkTaskIdPatch;
  updateTaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdPatch: UpdateTaskOnFileOutputGroupForFileOutputGroupTaskId2B1Df6A1FkTaskIdPatch;
  updateTaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdPatch: UpdateTaskOnMetadataValueForMetadataValueTaskId3D37D135FkTaskIdPatch;
  updateTaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdPatch: UpdateTaskOnTaskActivityForTaskActivityTaskIdC451Ae92FkTaskIdPatch;
  updateTaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdPatch: UpdateTaskOnTaskForTaskContainerImageId92Ed2DaeFkContainerImageIdPatch;
  updateTaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdPatch: UpdateTaskOnTaskForTaskDatasetIdE15Afa5AFkDatasetIdPatch;
  updateTaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdPatch: UpdateTaskOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdPatch;
  updateTaskOnTaskForTaskProjectId963D6354FkProjectIdPatch: UpdateTaskOnTaskForTaskProjectId963D6354FkProjectIdPatch;
  updateTaskOnTaskForTaskTenantId148E90DaFkTenantIdPatch: UpdateTaskOnTaskForTaskTenantId148E90DaFkTenantIdPatch;
  updateTaskOnTaskForTaskToolVersionId14506837FkToolVersionIdPatch: UpdateTaskOnTaskForTaskToolVersionId14506837FkToolVersionIdPatch;
  updateTaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdPatch: UpdateTaskOnTaskForTaskUserId270D0Bb2FkApplicationUserIdPatch;
  updateTaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdPatch: UpdateTaskOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdPatch;
  updateTaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdPatch: UpdateTaskOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdPatch;
  updateTaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdPatch: UpdateTaskOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdPatch;
  updateTaskResourceOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdPatch: UpdateTaskResourceOnTaskResourceForTaskResourceTaskId73Fcabc0FkTaskIdPatch;
  updateTaskSourceOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdPatch: UpdateTaskSourceOnTaskSourceForTaskSourceV4FileId3A4723EfFkFileIdPatch;
  updateTaskSourceOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdPatch: UpdateTaskSourceOnTaskSourceForTaskSourceV4TaskId059F1B6BFkTaskIdPatch;
  updateTaskSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdPatch: UpdateTaskSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdPatch;
  updateTaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdPatch: UpdateTaskStatusLogOnTaskStatusLogForTaskStatusLogTaskIdFe1B9D0BFkTaskIdPatch;
  updateTenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoPatch: UpdateTenantContractAllowanceOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoPatch;
  updateTenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdPatch: UpdateTenantContractOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdPatch;
  updateTenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoPatch: UpdateTenantContractOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoPatch;
  updateTenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoPatch: UpdateTenantContractOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoPatch;
  updateTenantContractTransactionOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoPatch: UpdateTenantContractTransactionOnTenantContractAllowanceForTenantContractAlloTenantContractTran29C19B1CFkTenantCoPatch;
  updateTenantContractTransactionOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoPatch: UpdateTenantContractTransactionOnTenantContractTransactionForTenantContractTranTenantContractId1E374993FkTenantCoPatch;
  updateTenantMockuserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsPatch: UpdateTenantMockuserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsPatch;
  updateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoPatch: UpdateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantContractId681267CeFkTenantCoPatch;
  updateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdPatch: UpdateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdPatch;
  updateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiPatch: UpdateTenantMonthlyConsumedResourceOnTenantMonthlyConsumedResourceForTenantMonthlyConsuUserIdF7210F55FkApplicatiPatch;
  updateTenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdPatch: UpdateTenantOnAnalysisTableForAnalysisTableTenantIdF297C671FkTenantIdPatch;
  updateTenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdPatch: UpdateTenantOnAnalysisTableGroupForAnalysisTableGroupTenantId3786Ab7AFkTenantIdPatch;
  updateTenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdPatch: UpdateTenantOnAnalysisTableRowForAnalysisTableRowTenantIdDb778B87FkTenantIdPatch;
  updateTenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdPatch: UpdateTenantOnApplicationUserMembershipForApplicationUserMembershipTenantId5E54Da7AFkTenantIdPatch;
  updateTenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdPatch: UpdateTenantOnApplicationUserSessionForApplicationUserSessionTenantId00A81Cc5FkTenantIdPatch;
  updateTenantOnCostForCostTenantId7Dcd58A7FkTenantIdPatch: UpdateTenantOnCostForCostTenantId7Dcd58A7FkTenantIdPatch;
  updateTenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdPatch: UpdateTenantOnDatasetForDatasetV4TenantId7055D1F4FkTenantIdPatch;
  updateTenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdPatch: UpdateTenantOnDatasetMetadatumForDatasetMetadataTenantIdD74Eb48BFkTenantIdPatch;
  updateTenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdPatch: UpdateTenantOnDatasetRecordingsTableColumnForDatasetRecordingsTableColumnTenantId5Bb0Dbe2FkTenantIdPatch;
  updateTenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdPatch: UpdateTenantOnDatasetRecordingsTableColumnOrderForDatasetRecordingsTTenantId43Dd9FaaFkTenantIdPatch;
  updateTenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdPatch: UpdateTenantOnDatasetRecordingsTableForDatasetRecordingsTableTenantId94Ac7342FkTenantIdPatch;
  updateTenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdPatch: UpdateTenantOnDatasetVersionForDatasetVersionTenantId7Eb1908AFkTenantIdPatch;
  updateTenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdPatch: UpdateTenantOnFileActivityForFileActivityTenantIdC46A4F01FkTenantIdPatch;
  updateTenantOnFileForFileTenantId61625016FkTenantIdPatch: UpdateTenantOnFileForFileTenantId61625016FkTenantIdPatch;
  updateTenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdPatch: UpdateTenantOnFileMetadatumForFileMetadataTenantId79Fe438BFkTenantIdPatch;
  updateTenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdPatch: UpdateTenantOnFileOutputGroupForFileOutputGroupTenantId3Fa46D44FkTenantIdPatch;
  updateTenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdPatch: UpdateTenantOnFileOutputGroupMetadatumForFileOutputGroupMetadataTenantIdE6Ba41EbFkTenantIdPatch;
  updateTenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdPatch: UpdateTenantOnFileRecordingGroupForFileRecordingGroupTenantIdCbdec6BcFkTenantIdPatch;
  updateTenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdPatch: UpdateTenantOnFileRecordingGroupMetadatumForFileRecordingGroupMetadataTenantIdF1B3584CFkTenantIdPatch;
  updateTenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdPatch: UpdateTenantOnMetadataValueForMetadataValueTenantIdB4C2D5D6FkTenantIdPatch;
  updateTenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdPatch: UpdateTenantOnMetadatumForMetadataV4TenantId163B6569FkTenantIdPatch;
  updateTenantOnProjectForProjectTenantId03F0Ca53FkTenantIdPatch: UpdateTenantOnProjectForProjectTenantId03F0Ca53FkTenantIdPatch;
  updateTenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdPatch: UpdateTenantOnRecordingGroupFileForRecordingGroupFilesTenantId637C3013FkTenantIdPatch;
  updateTenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdPatch: UpdateTenantOnRoleChangeNotificationForRoleChangeNotificationTenantId36852Ce9FkTenantIdPatch;
  updateTenantOnRunForRunTenantId73Fbd8D9FkTenantIdPatch: UpdateTenantOnRunForRunTenantId73Fbd8D9FkTenantIdPatch;
  updateTenantOnTagForTagTenantId8072B61FFkTenantIdPatch: UpdateTenantOnTagForTagTenantId8072B61FFkTenantIdPatch;
  updateTenantOnTaskForTaskTenantId148E90DaFkTenantIdPatch: UpdateTenantOnTaskForTaskTenantId148E90DaFkTenantIdPatch;
  updateTenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdPatch: UpdateTenantOnTenantContractForTenantContractTenantIdBa8Ac167FkTenantIdPatch;
  updateTenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdPatch: UpdateTenantOnTenantMonthlyConsumedResourceForTenantMonthlyConsuTenantIdCb1B80FcFkTenantIdPatch;
  updateTenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdPatch: UpdateTenantOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdPatch;
  updateTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdPatch: UpdateTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdPatch;
  updateTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdPatch: UpdateTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdPatch;
  updateTenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdPatch: UpdateTenantOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdPatch;
  updateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatPatch: UpdateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershId10Affa8DFkNotificatPatch;
  updateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiPatch: UpdateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershInviterId29476E5EFkApplicatiPatch;
  updateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdPatch: UpdateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershTenantIdC67818C5FkTenantIdPatch;
  updateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiPatch: UpdateTenantUserMembershipNotificationOnTenantUserMembershipNotificationForTenantUserMembershUserId2B78E8A0FkApplicatiPatch;
  updateTenantUserexecutorOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsPatch: UpdateTenantUserexecutorOnAwsCognitoUserForAwsCognitoUserUserexecutorPtrId23B14Ef0FkTenantUsPatch;
  updateTenantUserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsPatch: UpdateTenantUserexecutorOnTenantMockuserexecutorForTenantMockuserexecuUserexecutorPtrId757654E7FkTenantUsPatch;
  updateTenantUserexecutorOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoPatch: UpdateTenantUserexecutorOnTenantUserexecutorForTenantUserexecutorPolymorphicCtypeIdF45E3068FkDjangoCoPatch;
  updateTesContainerimageexecutorOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaPatch: UpdateTesContainerimageexecutorOnAwsEcrImageForAwsEcrImageContainerimageexecutAabe4FdbFkTesContaPatch;
  updateTesContainerimageexecutorOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaPatch: UpdateTesContainerimageexecutorOnContainerImageForContainerImageExecutorId68979Fb6FkTesContaPatch;
  updateTesContainerimageexecutorOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoPatch: UpdateTesContainerimageexecutorOnTesContainerimageexecutorForTesContainerimageexPolymorphicCtypeId60Cd6000FkDjangoCoPatch;
  updateTesContainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaPatch: UpdateTesContainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaPatch;
  updateTesMockcontainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaPatch: UpdateTesMockcontainerimageexecutorOnTesMockcontainerimageexecutorForTesMockcontainerimaContainerimageexecut4Eced924FkTesContaPatch;
  updateTesMocktaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskePatch: UpdateTesMocktaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskePatch;
  updateTesTaskexecutorOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskePatch: UpdateTesTaskexecutorOnAwsBatchTaskForAwsBatchTaskTaskexecutorPtrIdA5A06Eb9FkTesTaskePatch;
  updateTesTaskexecutorOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdPatch: UpdateTesTaskexecutorOnTaskForTaskExecutorId24A67329FkTesTaskexecutorIdPatch;
  updateTesTaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskePatch: UpdateTesTaskexecutorOnTesMocktaskexecutorForTesMocktaskexecutorTaskexecutorPtrIdEf30C727FkTesTaskePatch;
  updateTesTaskexecutorOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoPatch: UpdateTesTaskexecutorOnTesTaskexecutorForTesTaskexecutorPolymorphicCtypeIdFd457Dc0FkDjangoCoPatch;
  updateToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCatePatch: UpdateToolCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCatePatch;
  updateToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCatePatch: UpdateToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategorToolcategoryIdD78D6E82FkToolCatePatch;
  updateToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdPatch: UpdateToolDisplayCategoryOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdPatch;
  updateToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdPatch: UpdateToolOnAnalysisTableGroupForAnalysisTableGroupToolIdB122F1CcFkToolIdPatch;
  updateToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdPatch: UpdateToolOnToolDisplayCategoryForToolDisplayCategoriesToolIdAdc582D3FkToolIdPatch;
  updateToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdPatch: UpdateToolOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdPatch;
  updateToolOnToolVersionForToolVersionToolId3B396B22FkToolIdPatch: UpdateToolOnToolVersionForToolVersionToolId3B396B22FkToolIdPatch;
  updateToolParameterOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdPatch: UpdateToolParameterOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdPatch;
  updateToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdPatch: UpdateToolResourceOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdPatch;
  updateToolResultOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdPatch: UpdateToolResultOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdPatch;
  updateToolSecretOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdPatch: UpdateToolSecretOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdPatch;
  updateToolSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdPatch: UpdateToolSourceOnTaskSourceForTaskSourceV4ToolSourceIdC20B8Dc8FkToolSourceIdPatch;
  updateToolSourceOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdPatch: UpdateToolSourceOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdPatch;
  updateToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdPatch: UpdateToolTenantOnToolTenantForToolTenantsTenantId9C4A54FeFkTenantIdPatch;
  updateToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdPatch: UpdateToolTenantOnToolTenantForToolTenantsToolIdF77Ef6F7FkToolIdPatch;
  updateToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdPatch: UpdateToolVersionOnAnalysisTableForAnalysisTableToolVersionId8E4A2317FkToolVersionIdPatch;
  updateToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdPatch: UpdateToolVersionOnAnalysisTableRowForAnalysisTableRowToolVersionIdE14Bd2A7FkToolVersionIdPatch;
  updateToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdPatch: UpdateToolVersionOnTaskForTaskToolVersionId14506837FkToolVersionIdPatch;
  updateToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdPatch: UpdateToolVersionOnToolParameterForToolParameterToolVersionIdC3A0Df75FkToolVersionIdPatch;
  updateToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdPatch: UpdateToolVersionOnToolResourceForToolResourceToolVersionId5E21979DFkToolVersionIdPatch;
  updateToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdPatch: UpdateToolVersionOnToolResultForToolResultToolVersionId41Fb163EFkToolVersionIdPatch;
  updateToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdPatch: UpdateToolVersionOnToolSecretForToolSecretToolVersionId92Dc8FadFkToolVersionIdPatch;
  updateToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdPatch: UpdateToolVersionOnToolSourceForToolSourceToolVersionId9Ac27A25FkToolVersionIdPatch;
  updateToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdPatch: UpdateToolVersionOnToolVersionForToolVersionToolId3B396B22FkToolIdPatch;
  updateToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersPatch: UpdateToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersPatch;
  updateToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdPatch: UpdateToolVersionOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdPatch;
  updateToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEPatch: UpdateToolboxExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEPatch;
  updateToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdPatch: UpdateToolboxOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdPatch;
  updateToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdPatch: UpdateToolboxOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdPatch;
  updateToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdPatch: UpdateToolboxTenantOnToolboxTenantForToolboxTenantsTenantId5D0A40E4FkTenantIdPatch;
  updateToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdPatch: UpdateToolboxTenantOnToolboxTenantForToolboxTenantsToolboxId9D553881FkToolboxIdPatch;
  updateToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEPatch: UpdateToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxexitcodeId20316F51FkToolboxEPatch;
  updateToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVPatch: UpdateToolboxVersionExitcodeOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVPatch;
  updateToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVPatch: UpdateToolboxVersionOnContainerImageForContainerImageToolboxVersionIdE9Dede5FFkToolboxVPatch;
  updateToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVPatch: UpdateToolboxVersionOnToolboxVersionExitcodeForToolboxVersionExitToolboxversionIdB36A2BfdFkToolboxVPatch;
  updateToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdPatch: UpdateToolboxVersionOnToolboxVersionForToolboxVersionToolboxId5Cc86066FkToolboxIdPatch;
  updateToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVPatch: UpdateToolboxVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVPatch;
  updateToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVPatch: UpdateToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolboxversionId25F78C89FkToolboxVPatch;
  updateToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersPatch: UpdateToolboxVersionToolVersionOnToolboxVersionToolVersionForToolboxVersionToolToolversionId4Ed31559FkToolVersPatch;
  updateWorkflowDatumOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdPatch: UpdateWorkflowDatumOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdPatch;
  updateWorkflowDatumOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdPatch: UpdateWorkflowDatumOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdPatch;
  updateWorkflowOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdPatch: UpdateWorkflowOnWorkflowForWorkflowContainerIdE73A96E5FkContainerImageIdPatch;
  updateWorkflowOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdPatch: UpdateWorkflowOnWorkflowForWorkflowTenantId9469Ff84FkTenantIdPatch;
  updateWorkflowOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdPatch: UpdateWorkflowOnWorkflowForWorkflowUserId121C1967FkApplicationUserIdPatch;
  updateWorkflowOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdPatch: UpdateWorkflowOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdPatch;
  updateWorkflowResultOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdPatch: UpdateWorkflowResultOnWorkflowResultForWorkflowResultObjectIdC561D1C5FkWorkflowDataIdPatch;
  updateWorkflowResultOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdPatch: UpdateWorkflowResultOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdPatch;
  updateWorkflowResultOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowPatch: UpdateWorkflowResultOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowPatch;
  updateWorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdPatch: UpdateWorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsResultIdC8B0Eb61FkWorkflowResultIdPatch;
  updateWorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdPatch: UpdateWorkflowResultTagOnWorkflowResultTagForWorkflowResultTagsTagId4B7994A6FkTagIdPatch;
  updateWorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowPatch: UpdateWorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkResultId50F959E2FkWorkflowPatch;
  updateWorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowPatch: UpdateWorkflowResultWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowPatch;
  updateWorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdPatch: UpdateWorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockObjectId13E0Dfc9FkWorkflowDataIdPatch;
  updateWorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdPatch: UpdateWorkflowSectionBlockOnWorkflowSectionBlockForWorkflowSectionBlockToolId0E3B9Fc6FkToolVersionIdPatch;
  updateWorkflowSectionBlockOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowPatch: UpdateWorkflowSectionBlockOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowPatch;
  updateWorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowPatch: UpdateWorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloBlockIdF2Fa4B4DFkWorkflowPatch;
  updateWorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowPatch: UpdateWorkflowSectionBlockSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowPatch;
  updateWorkflowSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowPatch: UpdateWorkflowSectionOnWorkflowSectionBlockSectionForWorkflowSectionBloSectionId817C1C77FkWorkflowPatch;
  updateWorkflowSectionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowPatch: UpdateWorkflowSectionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowPatch;
  updateWorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowPatch: UpdateWorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorSectionId1D5C2D63FkWorkflowPatch;
  updateWorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowPatch: UpdateWorkflowSectionWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowPatch;
  updateWorkflowSourceOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdPatch: UpdateWorkflowSourceOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdPatch;
  updateWorkflowSourceOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowPatch: UpdateWorkflowSourceOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowPatch;
  updateWorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdPatch: UpdateWorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsSourceId1F808712FkWorkflowSourceIdPatch;
  updateWorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdPatch: UpdateWorkflowSourceTagOnWorkflowSourceTagForWorkflowSourceTagsTagId690E6935FkTagIdPatch;
  updateWorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowPatch: UpdateWorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkSourceId608A289DFkWorkflowPatch;
  updateWorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowPatch: UpdateWorkflowSourceWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowPatch;
  updateWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowPatch: UpdateWorkflowVersionOnWorkflowResultWorkflowVersionForWorkflowResultWorkWorkflowversionIdB9123570FkWorkflowPatch;
  updateWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowPatch: UpdateWorkflowVersionOnWorkflowSectionWorkflowVersionForWorkflowSectionWorWorkflowversionIdFe181CfcFkWorkflowPatch;
  updateWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowPatch: UpdateWorkflowVersionOnWorkflowSourceWorkflowVersionForWorkflowSourceWorkWorkflowversionIdDe9FcfaaFkWorkflowPatch;
  updateWorkflowVersionOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdPatch: UpdateWorkflowVersionOnWorkflowVersionForWorkflowVersionUserId00C0F62FFkApplicationUserIdPatch;
  updateWorkflowVersionOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdPatch: UpdateWorkflowVersionOnWorkflowVersionForWorkflowVersionWorkflowIdC2B7Bb13FkWorkflowIdPatch;
};

export type AnalysisTableResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AnalysisTable"] = ResolversParentTypes["AnalysisTable"],
> = {
  analysisTableGroupByGroupId?: Resolver<
    Maybe<ResolversTypes["AnalysisTableGroup"]>,
    ParentType,
    ContextType
  >;
  analysisTableRowsByTableId?: Resolver<
    ResolversTypes["AnalysisTableRowsConnection"],
    ParentType,
    ContextType,
    RequireFields<AnalysisTableAnalysisTableRowsByTableIdArgs, "orderBy">
  >;
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  groupId?: Resolver<ResolversTypes["BigInt"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["BigInt"], ParentType, ContextType>;
  identifiers?: Resolver<
    Maybe<ResolversTypes["JSON"]>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  toolVersionId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalysisTableGroupResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AnalysisTableGroup"] = ResolversParentTypes["AnalysisTableGroup"],
> = {
  analysisTablesByGroupId?: Resolver<
    ResolversTypes["AnalysisTablesConnection"],
    ParentType,
    ContextType,
    RequireFields<AnalysisTableGroupAnalysisTablesByGroupIdArgs, "orderBy">
  >;
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  description?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  hidden?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["BigInt"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  toolByToolId?: Resolver<
    Maybe<ResolversTypes["Tool"]>,
    ParentType,
    ContextType
  >;
  toolId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalysisTableGroupsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AnalysisTableGroupsConnection"] = ResolversParentTypes["AnalysisTableGroupsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["AnalysisTableGroupsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["AnalysisTableGroup"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalysisTableGroupsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AnalysisTableGroupsEdge"] = ResolversParentTypes["AnalysisTableGroupsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["AnalysisTableGroup"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalysisTableRowResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AnalysisTableRow"] = ResolversParentTypes["AnalysisTableRow"],
> = {
  activeAttachResults?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType,
    Partial<AnalysisTableRowActiveAttachResultsArgs>
  >;
  analysisTableByTableId?: Resolver<
    Maybe<ResolversTypes["AnalysisTable"]>,
    ParentType,
    ContextType
  >;
  analysisTableRowAttachResultsByAnalysisTableRowId?: Resolver<
    ResolversTypes["AnalysisTableRowAttachResultsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      AnalysisTableRowAnalysisTableRowAttachResultsByAnalysisTableRowIdArgs,
      "orderBy"
    >
  >;
  attachments?: Resolver<
    Maybe<ResolversTypes["JSON"]>,
    ParentType,
    ContextType
  >;
  autofillMap?: Resolver<
    Maybe<ResolversTypes["JSON"]>,
    ParentType,
    ContextType
  >;
  data?: Resolver<Maybe<ResolversTypes["JSON"]>, ParentType, ContextType>;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
  groups?: Resolver<Maybe<ResolversTypes["JSON"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  locked?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
  metadatumReferences?: Resolver<
    Maybe<ResolversTypes["JSON"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  selections?: Resolver<Maybe<ResolversTypes["JSON"]>, ParentType, ContextType>;
  tableId?: Resolver<ResolversTypes["BigInt"], ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  taskId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  toolVersionId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalysisTableRowAttachResultResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AnalysisTableRowAttachResult"] = ResolversParentTypes["AnalysisTableRowAttachResult"],
> = {
  analysisTableRowByAnalysisTableRowId?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRow"]>,
    ParentType,
    ContextType
  >;
  analysisTableRowId?: Resolver<
    ResolversTypes["UUID"],
    ParentType,
    ContextType
  >;
  attachResults?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["BigInt"], ParentType, ContextType>;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalysisTableRowAttachResultsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AnalysisTableRowAttachResultsConnection"] = ResolversParentTypes["AnalysisTableRowAttachResultsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["AnalysisTableRowAttachResultsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["AnalysisTableRowAttachResult"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalysisTableRowAttachResultsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AnalysisTableRowAttachResultsEdge"] = ResolversParentTypes["AnalysisTableRowAttachResultsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["AnalysisTableRowAttachResult"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalysisTableRowsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AnalysisTableRowsConnection"] = ResolversParentTypes["AnalysisTableRowsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["AnalysisTableRowsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["AnalysisTableRow"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalysisTableRowsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AnalysisTableRowsEdge"] = ResolversParentTypes["AnalysisTableRowsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["AnalysisTableRow"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalysisTablesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AnalysisTablesConnection"] = ResolversParentTypes["AnalysisTablesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["AnalysisTablesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["AnalysisTable"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalysisTablesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AnalysisTablesEdge"] = ResolversParentTypes["AnalysisTablesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["AnalysisTable"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationUserResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ApplicationUser"] = ResolversParentTypes["ApplicationUser"],
> = {
  analysisTableGroupsByUserId?: Resolver<
    ResolversTypes["AnalysisTableGroupsConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserAnalysisTableGroupsByUserIdArgs, "orderBy">
  >;
  analysisTablesByUserId?: Resolver<
    ResolversTypes["AnalysisTablesConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserAnalysisTablesByUserIdArgs, "orderBy">
  >;
  applicationUserGroupsByUserId?: Resolver<
    ResolversTypes["ApplicationUserGroupsConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserApplicationUserGroupsByUserIdArgs, "orderBy">
  >;
  applicationUserMembershipsByUserId?: Resolver<
    ResolversTypes["ApplicationUserMembershipsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ApplicationUserApplicationUserMembershipsByUserIdArgs,
      "orderBy"
    >
  >;
  applicationUserMetadataByUserId?: Resolver<
    ResolversTypes["ApplicationUserMetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserApplicationUserMetadataByUserIdArgs, "orderBy">
  >;
  applicationUserSessionsByUserId?: Resolver<
    ResolversTypes["ApplicationUserSessionsConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserApplicationUserSessionsByUserIdArgs, "orderBy">
  >;
  applicationUserUserPermissionsByUserId?: Resolver<
    ResolversTypes["ApplicationUserUserPermissionsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ApplicationUserApplicationUserUserPermissionsByUserIdArgs,
      "orderBy"
    >
  >;
  avatarByUserId?: Resolver<
    Maybe<ResolversTypes["Avatar"]>,
    ParentType,
    ContextType
  >;
  avatarsByUserId?: Resolver<
    ResolversTypes["AvatarsConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserAvatarsByUserIdArgs, "orderBy">
  >;
  cognitoTenantKey?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  created?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  datasetsByUserId?: Resolver<
    ResolversTypes["DatasetsConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserDatasetsByUserIdArgs, "orderBy">
  >;
  djangoAdminLogsByUserId?: Resolver<
    ResolversTypes["DjangoAdminLogsConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserDjangoAdminLogsByUserIdArgs, "orderBy">
  >;
  email?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  eulaVersionUserAcceptancesByUserId?: Resolver<
    ResolversTypes["EulaVersionUserAcceptancesConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ApplicationUserEulaVersionUserAcceptancesByUserIdArgs,
      "orderBy"
    >
  >;
  explorerQueriesByCreatedByUserId?: Resolver<
    ResolversTypes["ExplorerQueriesConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ApplicationUserExplorerQueriesByCreatedByUserIdArgs,
      "orderBy"
    >
  >;
  explorerQueryfavoritesByUserId?: Resolver<
    ResolversTypes["ExplorerQueryfavoritesConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserExplorerQueryfavoritesByUserIdArgs, "orderBy">
  >;
  explorerQuerylogsByRunByUserId?: Resolver<
    ResolversTypes["ExplorerQuerylogsConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserExplorerQuerylogsByRunByUserIdArgs, "orderBy">
  >;
  fileActivitiesByUserId?: Resolver<
    ResolversTypes["FileActivitiesConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserFileActivitiesByUserIdArgs, "orderBy">
  >;
  fileOutputGroupsByUserId?: Resolver<
    ResolversTypes["FileOutputGroupsConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserFileOutputGroupsByUserIdArgs, "orderBy">
  >;
  fileProcessingNotificationsByUserId?: Resolver<
    ResolversTypes["FileProcessingNotificationsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ApplicationUserFileProcessingNotificationsByUserIdArgs,
      "orderBy"
    >
  >;
  fileRecordingGroupsByUserId?: Resolver<
    ResolversTypes["FileRecordingGroupsConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserFileRecordingGroupsByUserIdArgs, "orderBy">
  >;
  filesByUserId?: Resolver<
    ResolversTypes["FilesConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserFilesByUserIdArgs, "orderBy">
  >;
  firstName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  globalUserId?: Resolver<
    Maybe<ResolversTypes["UUID"]>,
    ParentType,
    ContextType
  >;
  homeRegion?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  internal?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  isStaff?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  isSuperuser?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  metadataValuesByUserId?: Resolver<
    ResolversTypes["MetadataValuesConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserMetadataValuesByUserIdArgs, "orderBy">
  >;
  notificationsByUserId?: Resolver<
    ResolversTypes["NotificationsConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserNotificationsByUserIdArgs, "orderBy">
  >;
  projectClonedNotificationsByUserId?: Resolver<
    ResolversTypes["ProjectClonedNotificationsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ApplicationUserProjectClonedNotificationsByUserIdArgs,
      "orderBy"
    >
  >;
  projectSharedNotificationsBySharerId?: Resolver<
    ResolversTypes["ProjectSharedNotificationsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ApplicationUserProjectSharedNotificationsBySharerIdArgs,
      "orderBy"
    >
  >;
  projectSharedNotificationsByUserId?: Resolver<
    ResolversTypes["ProjectSharedNotificationsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ApplicationUserProjectSharedNotificationsByUserIdArgs,
      "orderBy"
    >
  >;
  projectsByUserId?: Resolver<
    ResolversTypes["ProjectsConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserProjectsByUserIdArgs, "orderBy">
  >;
  recordingGroupFilesByUserId?: Resolver<
    ResolversTypes["RecordingGroupFilesConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserRecordingGroupFilesByUserIdArgs, "orderBy">
  >;
  roleChangeNotificationsByUserId?: Resolver<
    ResolversTypes["RoleChangeNotificationsConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserRoleChangeNotificationsByUserIdArgs, "orderBy">
  >;
  tagsByUserId?: Resolver<
    ResolversTypes["TagsConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserTagsByUserIdArgs, "orderBy">
  >;
  tasksByUserId?: Resolver<
    ResolversTypes["TasksConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserTasksByUserIdArgs, "orderBy">
  >;
  tenantMonthlyConsumedResourcesByUserId?: Resolver<
    ResolversTypes["TenantMonthlyConsumedResourcesConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ApplicationUserTenantMonthlyConsumedResourcesByUserIdArgs,
      "orderBy"
    >
  >;
  tenantUserMembershipNotificationsByInviterId?: Resolver<
    ResolversTypes["TenantUserMembershipNotificationsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ApplicationUserTenantUserMembershipNotificationsByInviterIdArgs,
      "orderBy"
    >
  >;
  tenantUserMembershipNotificationsByUserId?: Resolver<
    ResolversTypes["TenantUserMembershipNotificationsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ApplicationUserTenantUserMembershipNotificationsByUserIdArgs,
      "orderBy"
    >
  >;
  username?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  workflowVersionsByUserId?: Resolver<
    ResolversTypes["WorkflowVersionsConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserWorkflowVersionsByUserIdArgs, "orderBy">
  >;
  workflowsByUserId?: Resolver<
    ResolversTypes["WorkflowsConnection"],
    ParentType,
    ContextType,
    RequireFields<ApplicationUserWorkflowsByUserIdArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationUserGroupResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ApplicationUserGroup"] = ResolversParentTypes["ApplicationUserGroup"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  authGroupByGroupId?: Resolver<
    Maybe<ResolversTypes["AuthGroup"]>,
    ParentType,
    ContextType
  >;
  groupId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationUserGroupsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ApplicationUserGroupsConnection"] = ResolversParentTypes["ApplicationUserGroupsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ApplicationUserGroupsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ApplicationUserGroup"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationUserGroupsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ApplicationUserGroupsEdge"] = ResolversParentTypes["ApplicationUserGroupsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["ApplicationUserGroup"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationUserMembershipResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ApplicationUserMembership"] = ResolversParentTypes["ApplicationUserMembership"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  role?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationUserMembershipsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ApplicationUserMembershipsConnection"] = ResolversParentTypes["ApplicationUserMembershipsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ApplicationUserMembershipsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ApplicationUserMembership"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationUserMembershipsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ApplicationUserMembershipsEdge"] = ResolversParentTypes["ApplicationUserMembershipsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["ApplicationUserMembership"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationUserMetadataConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ApplicationUserMetadataConnection"] = ResolversParentTypes["ApplicationUserMetadataConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ApplicationUserMetadataEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ApplicationUserMetadatum"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationUserMetadataEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ApplicationUserMetadataEdge"] = ResolversParentTypes["ApplicationUserMetadataEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["ApplicationUserMetadatum"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationUserMetadatumResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ApplicationUserMetadatum"] = ResolversParentTypes["ApplicationUserMetadatum"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  key?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  value?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationUserSessionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ApplicationUserSession"] = ResolversParentTypes["ApplicationUserSession"],
> = {
  active?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  datetimeLastActive?: Resolver<
    ResolversTypes["Datetime"],
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  lastPath?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes["JSON"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationUserSessionsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ApplicationUserSessionsConnection"] = ResolversParentTypes["ApplicationUserSessionsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ApplicationUserSessionsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ApplicationUserSession"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationUserSessionsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ApplicationUserSessionsEdge"] = ResolversParentTypes["ApplicationUserSessionsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["ApplicationUserSession"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationUserUserPermissionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ApplicationUserUserPermission"] = ResolversParentTypes["ApplicationUserUserPermission"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  authPermissionByPermissionId?: Resolver<
    Maybe<ResolversTypes["AuthPermission"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  permissionId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationUserUserPermissionsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ApplicationUserUserPermissionsConnection"] = ResolversParentTypes["ApplicationUserUserPermissionsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ApplicationUserUserPermissionsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ApplicationUserUserPermission"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationUserUserPermissionsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ApplicationUserUserPermissionsEdge"] = ResolversParentTypes["ApplicationUserUserPermissionsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["ApplicationUserUserPermission"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationUsersEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ApplicationUsersEdge"] = ResolversParentTypes["ApplicationUsersEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["ApplicationUser"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthGroupResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AuthGroup"] = ResolversParentTypes["AuthGroup"],
> = {
  applicationUserGroupsByGroupId?: Resolver<
    ResolversTypes["ApplicationUserGroupsConnection"],
    ParentType,
    ContextType,
    RequireFields<AuthGroupApplicationUserGroupsByGroupIdArgs, "orderBy">
  >;
  authGroupPermissionsByGroupId?: Resolver<
    ResolversTypes["AuthGroupPermissionsConnection"],
    ParentType,
    ContextType,
    RequireFields<AuthGroupAuthGroupPermissionsByGroupIdArgs, "orderBy">
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthGroupPermissionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AuthGroupPermission"] = ResolversParentTypes["AuthGroupPermission"],
> = {
  authGroupByGroupId?: Resolver<
    Maybe<ResolversTypes["AuthGroup"]>,
    ParentType,
    ContextType
  >;
  authPermissionByPermissionId?: Resolver<
    Maybe<ResolversTypes["AuthPermission"]>,
    ParentType,
    ContextType
  >;
  groupId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  permissionId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthGroupPermissionsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AuthGroupPermissionsConnection"] = ResolversParentTypes["AuthGroupPermissionsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["AuthGroupPermissionsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["AuthGroupPermission"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthGroupPermissionsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AuthGroupPermissionsEdge"] = ResolversParentTypes["AuthGroupPermissionsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["AuthGroupPermission"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthGroupsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AuthGroupsConnection"] = ResolversParentTypes["AuthGroupsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["AuthGroupsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes["AuthGroup"]>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthGroupsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AuthGroupsEdge"] = ResolversParentTypes["AuthGroupsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["AuthGroup"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthPermissionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AuthPermission"] = ResolversParentTypes["AuthPermission"],
> = {
  applicationUserUserPermissionsByPermissionId?: Resolver<
    ResolversTypes["ApplicationUserUserPermissionsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      AuthPermissionApplicationUserUserPermissionsByPermissionIdArgs,
      "orderBy"
    >
  >;
  authGroupPermissionsByPermissionId?: Resolver<
    ResolversTypes["AuthGroupPermissionsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      AuthPermissionAuthGroupPermissionsByPermissionIdArgs,
      "orderBy"
    >
  >;
  codename?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  contentTypeId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  djangoContentTypeByContentTypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthPermissionsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AuthPermissionsConnection"] = ResolversParentTypes["AuthPermissionsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["AuthPermissionsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["AuthPermission"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthPermissionsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AuthPermissionsEdge"] = ResolversParentTypes["AuthPermissionsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["AuthPermission"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvailableNonRestrictedProjectResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AvailableNonRestrictedProject"] = ResolversParentTypes["AvailableNonRestrictedProject"],
> = {
  defaultUserAccessLevel?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  tenantId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvailableNonRestrictedProjectsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AvailableNonRestrictedProjectsConnection"] = ResolversParentTypes["AvailableNonRestrictedProjectsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["AvailableNonRestrictedProjectsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["AvailableNonRestrictedProject"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvailableNonRestrictedProjectsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AvailableNonRestrictedProjectsEdge"] = ResolversParentTypes["AvailableNonRestrictedProjectsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["AvailableNonRestrictedProject"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvatarResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["Avatar"] = ResolversParentTypes["Avatar"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  color?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["BigInt"], ParentType, ContextType>;
  imageBase64?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  initials?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  useImage?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvatarsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AvatarsConnection"] = ResolversParentTypes["AvatarsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["AvatarsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes["Avatar"]>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvatarsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AvatarsEdge"] = ResolversParentTypes["AvatarsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["Avatar"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsBatchTaskResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsBatchTask"] = ResolversParentTypes["AwsBatchTask"],
> = {
  awsDataBucketName?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  awsInstanceType?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  awsJobId?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  awsJobQueue?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  awsJobStatus?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  awsJobStatusReason?: Resolver<
    ResolversTypes["String"],
    ParentType,
    ContextType
  >;
  awsMockBatchTaskByAwstaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsMockBatchTask"]>,
    ParentType,
    ContextType
  >;
  awsMockBatchTasksByAwstaskexecutorPtrId?: Resolver<
    ResolversTypes["AwsMockBatchTasksConnection"],
    ParentType,
    ContextType,
    RequireFields<
      AwsBatchTaskAwsMockBatchTasksByAwstaskexecutorPtrIdArgs,
      "orderBy"
    >
  >;
  awsRegion?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  awsTaskPath?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  exitcode?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  resultsProcessed?: Resolver<
    ResolversTypes["Boolean"],
    ParentType,
    ContextType
  >;
  taskexecutorPtrId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  tesTaskexecutorByTaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsBatchTasksConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsBatchTasksConnection"] = ResolversParentTypes["AwsBatchTasksConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["AwsBatchTasksEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["AwsBatchTask"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsBatchTasksEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsBatchTasksEdge"] = ResolversParentTypes["AwsBatchTasksEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["AwsBatchTask"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsCognitoUserResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsCognitoUser"] = ResolversParentTypes["AwsCognitoUser"],
> = {
  tenantUserexecutorByUserexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TenantUserexecutor"]>,
    ParentType,
    ContextType
  >;
  userexecutorPtrId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsCognitoUsersConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsCognitoUsersConnection"] = ResolversParentTypes["AwsCognitoUsersConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["AwsCognitoUsersEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["AwsCognitoUser"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsCognitoUsersEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsCognitoUsersEdge"] = ResolversParentTypes["AwsCognitoUsersEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["AwsCognitoUser"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsEcrImageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsEcrImage"] = ResolversParentTypes["AwsEcrImage"],
> = {
  awsMockEcrImageByAwscontainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsMockEcrImage"]>,
    ParentType,
    ContextType
  >;
  awsMockEcrImagesByAwscontainerimageexecutorPtrId?: Resolver<
    ResolversTypes["AwsMockEcrImagesConnection"],
    ParentType,
    ContextType,
    RequireFields<
      AwsEcrImageAwsMockEcrImagesByAwscontainerimageexecutorPtrIdArgs,
      "orderBy"
    >
  >;
  containerimageexecutorPtrId?: Resolver<
    ResolversTypes["Int"],
    ParentType,
    ContextType
  >;
  jobDefinitionName?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  jobDefinitionRevision?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  repositoryArn?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  tesContainerimageexecutorByContainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsEcrImagesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsEcrImagesConnection"] = ResolversParentTypes["AwsEcrImagesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["AwsEcrImagesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["AwsEcrImage"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsEcrImagesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsEcrImagesEdge"] = ResolversParentTypes["AwsEcrImagesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["AwsEcrImage"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsMockBatchTaskResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsMockBatchTask"] = ResolversParentTypes["AwsMockBatchTask"],
> = {
  awsBatchTaskByAwstaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsBatchTask"]>,
    ParentType,
    ContextType
  >;
  awstaskexecutorPtrId?: Resolver<
    ResolversTypes["UUID"],
    ParentType,
    ContextType
  >;
  containerName?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsMockBatchTasksConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsMockBatchTasksConnection"] = ResolversParentTypes["AwsMockBatchTasksConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["AwsMockBatchTasksEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["AwsMockBatchTask"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsMockBatchTasksEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsMockBatchTasksEdge"] = ResolversParentTypes["AwsMockBatchTasksEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["AwsMockBatchTask"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsMockEcrImageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsMockEcrImage"] = ResolversParentTypes["AwsMockEcrImage"],
> = {
  awsEcrImageByAwscontainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsEcrImage"]>,
    ParentType,
    ContextType
  >;
  awscontainerimageexecutorPtrId?: Resolver<
    ResolversTypes["Int"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsMockEcrImagesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsMockEcrImagesConnection"] = ResolversParentTypes["AwsMockEcrImagesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["AwsMockEcrImagesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["AwsMockEcrImage"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsMockEcrImagesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsMockEcrImagesEdge"] = ResolversParentTypes["AwsMockEcrImagesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["AwsMockEcrImage"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsMockS3FileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsMockS3File"] = ResolversParentTypes["AwsMockS3File"],
> = {
  awsS3FileByAwsfileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsS3File"]>,
    ParentType,
    ContextType
  >;
  awsfileexecutorPtrId?: Resolver<
    ResolversTypes["UUID"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsMockS3FilesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsMockS3FilesConnection"] = ResolversParentTypes["AwsMockS3FilesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["AwsMockS3FilesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["AwsMockS3File"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsMockS3FilesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsMockS3FilesEdge"] = ResolversParentTypes["AwsMockS3FilesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["AwsMockS3File"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsS3FileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsS3File"] = ResolversParentTypes["AwsS3File"],
> = {
  awsMockS3FileByAwsfileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsMockS3File"]>,
    ParentType,
    ContextType
  >;
  awsMockS3FilesByAwsfileexecutorPtrId?: Resolver<
    ResolversTypes["AwsMockS3FilesConnection"],
    ParentType,
    ContextType,
    RequireFields<AwsS3FileAwsMockS3FilesByAwsfileexecutorPtrIdArgs, "orderBy">
  >;
  awsUploadPath?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  drsFileexecutorByFileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DrsFileexecutor"]>,
    ParentType,
    ContextType
  >;
  fileexecutorPtrId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsS3FilesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsS3FilesConnection"] = ResolversParentTypes["AwsS3FilesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["AwsS3FilesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes["AwsS3File"]>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsS3FilesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["AwsS3FilesEdge"] = ResolversParentTypes["AwsS3FilesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["AwsS3File"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface BigFloatScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes["BigFloat"], any> {
  name: "BigFloat";
}

export interface BigIntScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes["BigInt"], any> {
  name: "BigInt";
}

export type ChangeUserSessionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ChangeUserSessionPayload"] = ResolversParentTypes["ChangeUserSessionPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserSession?: Resolver<
    Maybe<ResolversTypes["ApplicationUserSession"]>,
    ParentType,
    ContextType
  >;
  applicationUserSessionEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUserSessionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      ChangeUserSessionPayloadApplicationUserSessionEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContainerImageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ContainerImage"] = ResolversParentTypes["ContainerImage"],
> = {
  created?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  executorId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  repository?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  tag?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  tasksByContainerImageId?: Resolver<
    ResolversTypes["TasksConnection"],
    ParentType,
    ContextType,
    RequireFields<ContainerImageTasksByContainerImageIdArgs, "orderBy">
  >;
  tesContainerimageexecutorByExecutorId?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionByToolboxVersionId?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  workflowsByContainerId?: Resolver<
    ResolversTypes["WorkflowsConnection"],
    ParentType,
    ContextType,
    RequireFields<ContainerImageWorkflowsByContainerIdArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContainerImagesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ContainerImagesConnection"] = ResolversParentTypes["ContainerImagesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ContainerImagesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContainerImagesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ContainerImagesEdge"] = ResolversParentTypes["ContainerImagesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["ContainerImage"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["Cost"] = ResolversParentTypes["Cost"],
> = {
  costType?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  date?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  fileActivitiesByCostId?: Resolver<
    ResolversTypes["FileActivitiesConnection"],
    ParentType,
    ContextType,
    RequireFields<CostFileActivitiesByCostIdArgs, "orderBy">
  >;
  id?: Resolver<ResolversTypes["BigInt"], ParentType, ContextType>;
  platform?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  rateCode?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  taskActivitiesByCostId?: Resolver<
    ResolversTypes["TaskActivitiesConnection"],
    ParentType,
    ContextType,
    RequireFields<CostTaskActivitiesByCostIdArgs, "orderBy">
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  value?: Resolver<ResolversTypes["BigFloat"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CostsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CostsConnection"] = ResolversParentTypes["CostsConnection"],
> = {
  edges?: Resolver<Array<ResolversTypes["CostsEdge"]>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes["Cost"]>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CostsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CostsEdge"] = ResolversParentTypes["CostsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["Cost"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAnalysisTableGroupPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateAnalysisTableGroupPayload"] = ResolversParentTypes["CreateAnalysisTableGroupPayload"],
> = {
  analysisTableGroup?: Resolver<
    Maybe<ResolversTypes["AnalysisTableGroup"]>,
    ParentType,
    ContextType
  >;
  analysisTableGroupEdge?: Resolver<
    Maybe<ResolversTypes["AnalysisTableGroupsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateAnalysisTableGroupPayloadAnalysisTableGroupEdgeArgs,
      "orderBy"
    >
  >;
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  toolByToolId?: Resolver<
    Maybe<ResolversTypes["Tool"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAnalysisTablePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateAnalysisTablePayload"] = ResolversParentTypes["CreateAnalysisTablePayload"],
> = {
  analysisTable?: Resolver<
    Maybe<ResolversTypes["AnalysisTable"]>,
    ParentType,
    ContextType
  >;
  analysisTableEdge?: Resolver<
    Maybe<ResolversTypes["AnalysisTablesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateAnalysisTablePayloadAnalysisTableEdgeArgs, "orderBy">
  >;
  analysisTableGroupByGroupId?: Resolver<
    Maybe<ResolversTypes["AnalysisTableGroup"]>,
    ParentType,
    ContextType
  >;
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAnalysisTableRowAttachResultPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateAnalysisTableRowAttachResultPayload"] = ResolversParentTypes["CreateAnalysisTableRowAttachResultPayload"],
> = {
  analysisTableRowAttachResult?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRowAttachResult"]>,
    ParentType,
    ContextType
  >;
  analysisTableRowAttachResultEdge?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRowAttachResultsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateAnalysisTableRowAttachResultPayloadAnalysisTableRowAttachResultEdgeArgs,
      "orderBy"
    >
  >;
  analysisTableRowByAnalysisTableRowId?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRow"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAnalysisTableRowPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateAnalysisTableRowPayload"] = ResolversParentTypes["CreateAnalysisTableRowPayload"],
> = {
  analysisTableByTableId?: Resolver<
    Maybe<ResolversTypes["AnalysisTable"]>,
    ParentType,
    ContextType
  >;
  analysisTableRow?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRow"]>,
    ParentType,
    ContextType
  >;
  analysisTableRowEdge?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRowsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateAnalysisTableRowPayloadAnalysisTableRowEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateApplicationUserGroupPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateApplicationUserGroupPayload"] = ResolversParentTypes["CreateApplicationUserGroupPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserGroup?: Resolver<
    Maybe<ResolversTypes["ApplicationUserGroup"]>,
    ParentType,
    ContextType
  >;
  applicationUserGroupEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUserGroupsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateApplicationUserGroupPayloadApplicationUserGroupEdgeArgs,
      "orderBy"
    >
  >;
  authGroupByGroupId?: Resolver<
    Maybe<ResolversTypes["AuthGroup"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateApplicationUserMembershipPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateApplicationUserMembershipPayload"] = ResolversParentTypes["CreateApplicationUserMembershipPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserMembership?: Resolver<
    Maybe<ResolversTypes["ApplicationUserMembership"]>,
    ParentType,
    ContextType
  >;
  applicationUserMembershipEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUserMembershipsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateApplicationUserMembershipPayloadApplicationUserMembershipEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateApplicationUserMetadatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateApplicationUserMetadatumPayload"] = ResolversParentTypes["CreateApplicationUserMetadatumPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserMetadatum?: Resolver<
    Maybe<ResolversTypes["ApplicationUserMetadatum"]>,
    ParentType,
    ContextType
  >;
  applicationUserMetadatumEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUserMetadataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateApplicationUserMetadatumPayloadApplicationUserMetadatumEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateApplicationUserPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateApplicationUserPayload"] = ResolversParentTypes["CreateApplicationUserPayload"],
> = {
  applicationUser?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUsersEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateApplicationUserPayloadApplicationUserEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateApplicationUserSessionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateApplicationUserSessionPayload"] = ResolversParentTypes["CreateApplicationUserSessionPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserSession?: Resolver<
    Maybe<ResolversTypes["ApplicationUserSession"]>,
    ParentType,
    ContextType
  >;
  applicationUserSessionEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUserSessionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateApplicationUserSessionPayloadApplicationUserSessionEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateApplicationUserUserPermissionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateApplicationUserUserPermissionPayload"] = ResolversParentTypes["CreateApplicationUserUserPermissionPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserUserPermission?: Resolver<
    Maybe<ResolversTypes["ApplicationUserUserPermission"]>,
    ParentType,
    ContextType
  >;
  applicationUserUserPermissionEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUserUserPermissionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateApplicationUserUserPermissionPayloadApplicationUserUserPermissionEdgeArgs,
      "orderBy"
    >
  >;
  authPermissionByPermissionId?: Resolver<
    Maybe<ResolversTypes["AuthPermission"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAuthGroupPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateAuthGroupPayload"] = ResolversParentTypes["CreateAuthGroupPayload"],
> = {
  authGroup?: Resolver<
    Maybe<ResolversTypes["AuthGroup"]>,
    ParentType,
    ContextType
  >;
  authGroupEdge?: Resolver<
    Maybe<ResolversTypes["AuthGroupsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateAuthGroupPayloadAuthGroupEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAuthGroupPermissionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateAuthGroupPermissionPayload"] = ResolversParentTypes["CreateAuthGroupPermissionPayload"],
> = {
  authGroupByGroupId?: Resolver<
    Maybe<ResolversTypes["AuthGroup"]>,
    ParentType,
    ContextType
  >;
  authGroupPermission?: Resolver<
    Maybe<ResolversTypes["AuthGroupPermission"]>,
    ParentType,
    ContextType
  >;
  authGroupPermissionEdge?: Resolver<
    Maybe<ResolversTypes["AuthGroupPermissionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateAuthGroupPermissionPayloadAuthGroupPermissionEdgeArgs,
      "orderBy"
    >
  >;
  authPermissionByPermissionId?: Resolver<
    Maybe<ResolversTypes["AuthPermission"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAuthPermissionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateAuthPermissionPayload"] = ResolversParentTypes["CreateAuthPermissionPayload"],
> = {
  authPermission?: Resolver<
    Maybe<ResolversTypes["AuthPermission"]>,
    ParentType,
    ContextType
  >;
  authPermissionEdge?: Resolver<
    Maybe<ResolversTypes["AuthPermissionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateAuthPermissionPayloadAuthPermissionEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeByContentTypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAvailableNonRestrictedProjectPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateAvailableNonRestrictedProjectPayload"] = ResolversParentTypes["CreateAvailableNonRestrictedProjectPayload"],
> = {
  availableNonRestrictedProject?: Resolver<
    Maybe<ResolversTypes["AvailableNonRestrictedProject"]>,
    ParentType,
    ContextType
  >;
  availableNonRestrictedProjectEdge?: Resolver<
    Maybe<ResolversTypes["AvailableNonRestrictedProjectsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateAvailableNonRestrictedProjectPayloadAvailableNonRestrictedProjectEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAvatarPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateAvatarPayload"] = ResolversParentTypes["CreateAvatarPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  avatar?: Resolver<Maybe<ResolversTypes["Avatar"]>, ParentType, ContextType>;
  avatarEdge?: Resolver<
    Maybe<ResolversTypes["AvatarsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateAvatarPayloadAvatarEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAwsBatchTaskPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateAwsBatchTaskPayload"] = ResolversParentTypes["CreateAwsBatchTaskPayload"],
> = {
  awsBatchTask?: Resolver<
    Maybe<ResolversTypes["AwsBatchTask"]>,
    ParentType,
    ContextType
  >;
  awsBatchTaskEdge?: Resolver<
    Maybe<ResolversTypes["AwsBatchTasksEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateAwsBatchTaskPayloadAwsBatchTaskEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesTaskexecutorByTaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAwsCognitoUserPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateAwsCognitoUserPayload"] = ResolversParentTypes["CreateAwsCognitoUserPayload"],
> = {
  awsCognitoUser?: Resolver<
    Maybe<ResolversTypes["AwsCognitoUser"]>,
    ParentType,
    ContextType
  >;
  awsCognitoUserEdge?: Resolver<
    Maybe<ResolversTypes["AwsCognitoUsersEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateAwsCognitoUserPayloadAwsCognitoUserEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantUserexecutorByUserexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TenantUserexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAwsEcrImagePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateAwsEcrImagePayload"] = ResolversParentTypes["CreateAwsEcrImagePayload"],
> = {
  awsEcrImage?: Resolver<
    Maybe<ResolversTypes["AwsEcrImage"]>,
    ParentType,
    ContextType
  >;
  awsEcrImageEdge?: Resolver<
    Maybe<ResolversTypes["AwsEcrImagesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateAwsEcrImagePayloadAwsEcrImageEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesContainerimageexecutorByContainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAwsMockBatchTaskPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateAwsMockBatchTaskPayload"] = ResolversParentTypes["CreateAwsMockBatchTaskPayload"],
> = {
  awsBatchTaskByAwstaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsBatchTask"]>,
    ParentType,
    ContextType
  >;
  awsMockBatchTask?: Resolver<
    Maybe<ResolversTypes["AwsMockBatchTask"]>,
    ParentType,
    ContextType
  >;
  awsMockBatchTaskEdge?: Resolver<
    Maybe<ResolversTypes["AwsMockBatchTasksEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateAwsMockBatchTaskPayloadAwsMockBatchTaskEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAwsMockEcrImagePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateAwsMockEcrImagePayload"] = ResolversParentTypes["CreateAwsMockEcrImagePayload"],
> = {
  awsEcrImageByAwscontainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsEcrImage"]>,
    ParentType,
    ContextType
  >;
  awsMockEcrImage?: Resolver<
    Maybe<ResolversTypes["AwsMockEcrImage"]>,
    ParentType,
    ContextType
  >;
  awsMockEcrImageEdge?: Resolver<
    Maybe<ResolversTypes["AwsMockEcrImagesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateAwsMockEcrImagePayloadAwsMockEcrImageEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAwsMockS3FilePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateAwsMockS3FilePayload"] = ResolversParentTypes["CreateAwsMockS3FilePayload"],
> = {
  awsMockS3File?: Resolver<
    Maybe<ResolversTypes["AwsMockS3File"]>,
    ParentType,
    ContextType
  >;
  awsMockS3FileEdge?: Resolver<
    Maybe<ResolversTypes["AwsMockS3FilesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateAwsMockS3FilePayloadAwsMockS3FileEdgeArgs, "orderBy">
  >;
  awsS3FileByAwsfileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsS3File"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAwsS3FilePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateAwsS3FilePayload"] = ResolversParentTypes["CreateAwsS3FilePayload"],
> = {
  awsS3File?: Resolver<
    Maybe<ResolversTypes["AwsS3File"]>,
    ParentType,
    ContextType
  >;
  awsS3FileEdge?: Resolver<
    Maybe<ResolversTypes["AwsS3FilesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateAwsS3FilePayloadAwsS3FileEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  drsFileexecutorByFileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DrsFileexecutor"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateContainerImagePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateContainerImagePayload"] = ResolversParentTypes["CreateContainerImagePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  containerImage?: Resolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType
  >;
  containerImageEdge?: Resolver<
    Maybe<ResolversTypes["ContainerImagesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateContainerImagePayloadContainerImageEdgeArgs, "orderBy">
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesContainerimageexecutorByExecutorId?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionByToolboxVersionId?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateCostPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateCostPayload"] = ResolversParentTypes["CreateCostPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  cost?: Resolver<Maybe<ResolversTypes["Cost"]>, ParentType, ContextType>;
  costEdge?: Resolver<
    Maybe<ResolversTypes["CostsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateCostPayloadCostEdgeArgs, "orderBy">
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateCurrentApplicationUserPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateCurrentApplicationUserPayload"] = ResolversParentTypes["CreateCurrentApplicationUserPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  currentApplicationUser?: Resolver<
    Maybe<ResolversTypes["CurrentApplicationUser"]>,
    ParentType,
    ContextType
  >;
  currentApplicationUserEdge?: Resolver<
    Maybe<ResolversTypes["CurrentApplicationUsersEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateCurrentApplicationUserPayloadCurrentApplicationUserEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateCurrentUserMembershipPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateCurrentUserMembershipPayload"] = ResolversParentTypes["CreateCurrentUserMembershipPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  currentUserMembership?: Resolver<
    Maybe<ResolversTypes["CurrentUserMembership"]>,
    ParentType,
    ContextType
  >;
  currentUserMembershipEdge?: Resolver<
    Maybe<ResolversTypes["CurrentUserMembershipsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateCurrentUserMembershipPayloadCurrentUserMembershipEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateDatasetMetadatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateDatasetMetadatumPayload"] = ResolversParentTypes["CreateDatasetMetadatumPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetByDatasetId?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType
  >;
  datasetMetadatum?: Resolver<
    Maybe<ResolversTypes["DatasetMetadatum"]>,
    ParentType,
    ContextType
  >;
  datasetMetadatumEdge?: Resolver<
    Maybe<ResolversTypes["DatasetMetadataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateDatasetMetadatumPayloadDatasetMetadatumEdgeArgs,
      "orderBy"
    >
  >;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateDatasetPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateDatasetPayload"] = ResolversParentTypes["CreateDatasetPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  dataset?: Resolver<Maybe<ResolversTypes["Dataset"]>, ParentType, ContextType>;
  datasetEdge?: Resolver<
    Maybe<ResolversTypes["DatasetsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateDatasetPayloadDatasetEdgeArgs, "orderBy">
  >;
  datasetRecordingsTableByRecordingsTableId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTable"]>,
    ParentType,
    ContextType
  >;
  metadatumByDescriptionId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateDatasetRecordingsTableColumnOrderPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateDatasetRecordingsTableColumnOrderPayload"] = ResolversParentTypes["CreateDatasetRecordingsTableColumnOrderPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnByDatasetRecordingsTableColumnId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumn"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnOrder?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumnOrder"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnOrderEdge?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumnOrdersEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateDatasetRecordingsTableColumnOrderPayloadDatasetRecordingsTableColumnOrderEdgeArgs,
      "orderBy"
    >
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateDatasetRecordingsTableColumnPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateDatasetRecordingsTableColumnPayload"] = ResolversParentTypes["CreateDatasetRecordingsTableColumnPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableByDatasetRecordingsTableId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTable"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumn?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumn"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnEdge?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumnsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateDatasetRecordingsTableColumnPayloadDatasetRecordingsTableColumnEdgeArgs,
      "orderBy"
    >
  >;
  metadatumByColDefId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateDatasetRecordingsTablePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateDatasetRecordingsTablePayload"] = ResolversParentTypes["CreateDatasetRecordingsTablePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTable?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTable"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableEdge?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTablesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateDatasetRecordingsTablePayloadDatasetRecordingsTableEdgeArgs,
      "orderBy"
    >
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateDatasetVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateDatasetVersionPayload"] = ResolversParentTypes["CreateDatasetVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetByDatasetId?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType
  >;
  datasetVersion?: Resolver<
    Maybe<ResolversTypes["DatasetVersion"]>,
    ParentType,
    ContextType
  >;
  datasetVersionEdge?: Resolver<
    Maybe<ResolversTypes["DatasetVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateDatasetVersionPayloadDatasetVersionEdgeArgs, "orderBy">
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateDjangoAdminLogPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateDjangoAdminLogPayload"] = ResolversParentTypes["CreateDjangoAdminLogPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoAdminLog?: Resolver<
    Maybe<ResolversTypes["DjangoAdminLog"]>,
    ParentType,
    ContextType
  >;
  djangoAdminLogEdge?: Resolver<
    Maybe<ResolversTypes["DjangoAdminLogsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateDjangoAdminLogPayloadDjangoAdminLogEdgeArgs, "orderBy">
  >;
  djangoContentTypeByContentTypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateDjangoContentTypePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateDjangoContentTypePayload"] = ResolversParentTypes["CreateDjangoContentTypePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoContentType?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeEdge?: Resolver<
    Maybe<ResolversTypes["DjangoContentTypesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateDjangoContentTypePayloadDjangoContentTypeEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateDjangoMigrationPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateDjangoMigrationPayload"] = ResolversParentTypes["CreateDjangoMigrationPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoMigration?: Resolver<
    Maybe<ResolversTypes["DjangoMigration"]>,
    ParentType,
    ContextType
  >;
  djangoMigrationEdge?: Resolver<
    Maybe<ResolversTypes["DjangoMigrationsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateDjangoMigrationPayloadDjangoMigrationEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateDjangoSesSesstatPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateDjangoSesSesstatPayload"] = ResolversParentTypes["CreateDjangoSesSesstatPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoSesSesstat?: Resolver<
    Maybe<ResolversTypes["DjangoSesSesstat"]>,
    ParentType,
    ContextType
  >;
  djangoSesSesstatEdge?: Resolver<
    Maybe<ResolversTypes["DjangoSesSesstatsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateDjangoSesSesstatPayloadDjangoSesSesstatEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateDjangoSessionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateDjangoSessionPayload"] = ResolversParentTypes["CreateDjangoSessionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoSession?: Resolver<
    Maybe<ResolversTypes["DjangoSession"]>,
    ParentType,
    ContextType
  >;
  djangoSessionEdge?: Resolver<
    Maybe<ResolversTypes["DjangoSessionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateDjangoSessionPayloadDjangoSessionEdgeArgs, "orderBy">
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateDrsFileexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateDrsFileexecutorPayload"] = ResolversParentTypes["CreateDrsFileexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeByPolymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  drsFileexecutor?: Resolver<
    Maybe<ResolversTypes["DrsFileexecutor"]>,
    ParentType,
    ContextType
  >;
  drsFileexecutorEdge?: Resolver<
    Maybe<ResolversTypes["DrsFileexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateDrsFileexecutorPayloadDrsFileexecutorEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateDrsMockfileexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateDrsMockfileexecutorPayload"] = ResolversParentTypes["CreateDrsMockfileexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  drsFileexecutorByFileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DrsFileexecutor"]>,
    ParentType,
    ContextType
  >;
  drsMockfileexecutor?: Resolver<
    Maybe<ResolversTypes["DrsMockfileexecutor"]>,
    ParentType,
    ContextType
  >;
  drsMockfileexecutorEdge?: Resolver<
    Maybe<ResolversTypes["DrsMockfileexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateDrsMockfileexecutorPayloadDrsMockfileexecutorEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateEulaVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateEulaVersionPayload"] = ResolversParentTypes["CreateEulaVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  eulaVersion?: Resolver<
    Maybe<ResolversTypes["EulaVersion"]>,
    ParentType,
    ContextType
  >;
  eulaVersionEdge?: Resolver<
    Maybe<ResolversTypes["EulaVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateEulaVersionPayloadEulaVersionEdgeArgs, "orderBy">
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateEulaVersionUserAcceptancePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateEulaVersionUserAcceptancePayload"] = ResolversParentTypes["CreateEulaVersionUserAcceptancePayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  eulaVersionByEulaVersionId?: Resolver<
    Maybe<ResolversTypes["EulaVersion"]>,
    ParentType,
    ContextType
  >;
  eulaVersionUserAcceptance?: Resolver<
    Maybe<ResolversTypes["EulaVersionUserAcceptance"]>,
    ParentType,
    ContextType
  >;
  eulaVersionUserAcceptanceEdge?: Resolver<
    Maybe<ResolversTypes["EulaVersionUserAcceptancesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateEulaVersionUserAcceptancePayloadEulaVersionUserAcceptanceEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateExplorerQueryPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateExplorerQueryPayload"] = ResolversParentTypes["CreateExplorerQueryPayload"],
> = {
  applicationUserByCreatedByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  explorerQuery?: Resolver<
    Maybe<ResolversTypes["ExplorerQuery"]>,
    ParentType,
    ContextType
  >;
  explorerQueryEdge?: Resolver<
    Maybe<ResolversTypes["ExplorerQueriesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateExplorerQueryPayloadExplorerQueryEdgeArgs, "orderBy">
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateExplorerQueryfavoritePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateExplorerQueryfavoritePayload"] = ResolversParentTypes["CreateExplorerQueryfavoritePayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  explorerQueryByQueryId?: Resolver<
    Maybe<ResolversTypes["ExplorerQuery"]>,
    ParentType,
    ContextType
  >;
  explorerQueryfavorite?: Resolver<
    Maybe<ResolversTypes["ExplorerQueryfavorite"]>,
    ParentType,
    ContextType
  >;
  explorerQueryfavoriteEdge?: Resolver<
    Maybe<ResolversTypes["ExplorerQueryfavoritesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateExplorerQueryfavoritePayloadExplorerQueryfavoriteEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateExplorerQuerylogPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateExplorerQuerylogPayload"] = ResolversParentTypes["CreateExplorerQuerylogPayload"],
> = {
  applicationUserByRunByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  explorerQueryByQueryId?: Resolver<
    Maybe<ResolversTypes["ExplorerQuery"]>,
    ParentType,
    ContextType
  >;
  explorerQuerylog?: Resolver<
    Maybe<ResolversTypes["ExplorerQuerylog"]>,
    ParentType,
    ContextType
  >;
  explorerQuerylogEdge?: Resolver<
    Maybe<ResolversTypes["ExplorerQuerylogsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateExplorerQuerylogPayloadExplorerQuerylogEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateFileActivityPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateFileActivityPayload"] = ResolversParentTypes["CreateFileActivityPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  costByCostId?: Resolver<
    Maybe<ResolversTypes["Cost"]>,
    ParentType,
    ContextType
  >;
  fileActivity?: Resolver<
    Maybe<ResolversTypes["FileActivity"]>,
    ParentType,
    ContextType
  >;
  fileActivityEdge?: Resolver<
    Maybe<ResolversTypes["FileActivitiesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateFileActivityPayloadFileActivityEdgeArgs, "orderBy">
  >;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateFileMetadatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateFileMetadatumPayload"] = ResolversParentTypes["CreateFileMetadatumPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileMetadatum?: Resolver<
    Maybe<ResolversTypes["FileMetadatum"]>,
    ParentType,
    ContextType
  >;
  fileMetadatumEdge?: Resolver<
    Maybe<ResolversTypes["FileMetadataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateFileMetadatumPayloadFileMetadatumEdgeArgs, "orderBy">
  >;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateFileOutputGroupMetadatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateFileOutputGroupMetadatumPayload"] = ResolversParentTypes["CreateFileOutputGroupMetadatumPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroupByFileOutputGroupId?: Resolver<
    Maybe<ResolversTypes["FileOutputGroup"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroupMetadatum?: Resolver<
    Maybe<ResolversTypes["FileOutputGroupMetadatum"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroupMetadatumEdge?: Resolver<
    Maybe<ResolversTypes["FileOutputGroupMetadataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateFileOutputGroupMetadatumPayloadFileOutputGroupMetadatumEdgeArgs,
      "orderBy"
    >
  >;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateFilePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateFilePayload"] = ResolversParentTypes["CreateFilePayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetByDatasetId?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType
  >;
  file?: Resolver<Maybe<ResolversTypes["File"]>, ParentType, ContextType>;
  fileBySeriesParentId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileEdge?: Resolver<
    Maybe<ResolversTypes["FilesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateFilePayloadFileEdgeArgs, "orderBy">
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateFileRecordingGroupMetadatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateFileRecordingGroupMetadatumPayload"] = ResolversParentTypes["CreateFileRecordingGroupMetadatumPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroupByFileRecordingGroupId?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroup"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroupMetadatum?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroupMetadatum"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroupMetadatumEdge?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroupMetadataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateFileRecordingGroupMetadatumPayloadFileRecordingGroupMetadatumEdgeArgs,
      "orderBy"
    >
  >;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateFileRecordingGroupPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateFileRecordingGroupPayload"] = ResolversParentTypes["CreateFileRecordingGroupPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableByDatasetRecordingsTableId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTable"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroup?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroup"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroupEdge?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroupsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateFileRecordingGroupPayloadFileRecordingGroupEdgeArgs,
      "orderBy"
    >
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateMetadataValuePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateMetadataValuePayload"] = ResolversParentTypes["CreateMetadataValuePayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  metadataValue?: Resolver<
    Maybe<ResolversTypes["MetadataValue"]>,
    ParentType,
    ContextType
  >;
  metadataValueEdge?: Resolver<
    Maybe<ResolversTypes["MetadataValuesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateMetadataValuePayloadMetadataValueEdgeArgs, "orderBy">
  >;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateMetadatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateMetadatumPayload"] = ResolversParentTypes["CreateMetadatumPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  metadatum?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  metadatumEdge?: Resolver<
    Maybe<ResolversTypes["MetadataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateMetadatumPayloadMetadatumEdgeArgs, "orderBy">
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOutputGroupFilePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateOutputGroupFilePayload"] = ResolversParentTypes["CreateOutputGroupFilePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroupByFileOutputGroupId?: Resolver<
    Maybe<ResolversTypes["FileOutputGroup"]>,
    ParentType,
    ContextType
  >;
  outputGroupFile?: Resolver<
    Maybe<ResolversTypes["OutputGroupFile"]>,
    ParentType,
    ContextType
  >;
  outputGroupFileEdge?: Resolver<
    Maybe<ResolversTypes["OutputGroupFilesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateOutputGroupFilePayloadOutputGroupFileEdgeArgs,
      "orderBy"
    >
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateRecordingGroupFilePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateRecordingGroupFilePayload"] = ResolversParentTypes["CreateRecordingGroupFilePayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnByDatasetRecordingsTableColumnId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumn"]>,
    ParentType,
    ContextType
  >;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroupByFileRecordingGroupId?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroup"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  recordingGroupFile?: Resolver<
    Maybe<ResolversTypes["RecordingGroupFile"]>,
    ParentType,
    ContextType
  >;
  recordingGroupFileEdge?: Resolver<
    Maybe<ResolversTypes["RecordingGroupFilesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateRecordingGroupFilePayloadRecordingGroupFileEdgeArgs,
      "orderBy"
    >
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateRolePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateRolePayload"] = ResolversParentTypes["CreateRolePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateRunPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateRunPayload"] = ResolversParentTypes["CreateRunPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  run?: Resolver<Maybe<ResolversTypes["Run"]>, ParentType, ContextType>;
  runEdge?: Resolver<
    Maybe<ResolversTypes["RunsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateRunPayloadRunEdgeArgs, "orderBy">
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateSessionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateSessionPayload"] = ResolversParentTypes["CreateSessionPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserSession?: Resolver<
    Maybe<ResolversTypes["ApplicationUserSession"]>,
    ParentType,
    ContextType
  >;
  applicationUserSessionEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUserSessionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateSessionPayloadApplicationUserSessionEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTagPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateTagPayload"] = ResolversParentTypes["CreateTagPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tag?: Resolver<Maybe<ResolversTypes["Tag"]>, ParentType, ContextType>;
  tagByParentId?: Resolver<
    Maybe<ResolversTypes["Tag"]>,
    ParentType,
    ContextType
  >;
  tagEdge?: Resolver<
    Maybe<ResolversTypes["TagsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateTagPayloadTagEdgeArgs, "orderBy">
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTaskActivityPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateTaskActivityPayload"] = ResolversParentTypes["CreateTaskActivityPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  costByCostId?: Resolver<
    Maybe<ResolversTypes["Cost"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskActivity?: Resolver<
    Maybe<ResolversTypes["TaskActivity"]>,
    ParentType,
    ContextType
  >;
  taskActivityEdge?: Resolver<
    Maybe<ResolversTypes["TaskActivitiesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateTaskActivityPayloadTaskActivityEdgeArgs, "orderBy">
  >;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTaskPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateTaskPayload"] = ResolversParentTypes["CreateTaskPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  containerImageByContainerImageId?: Resolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType
  >;
  datasetByDatasetId?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  task?: Resolver<Maybe<ResolversTypes["Task"]>, ParentType, ContextType>;
  taskEdge?: Resolver<
    Maybe<ResolversTypes["TasksEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateTaskPayloadTaskEdgeArgs, "orderBy">
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tesTaskexecutorByExecutorId?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutor"]>,
    ParentType,
    ContextType
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTaskResourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateTaskResourcePayload"] = ResolversParentTypes["CreateTaskResourcePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  taskResource?: Resolver<
    Maybe<ResolversTypes["TaskResource"]>,
    ParentType,
    ContextType
  >;
  taskResourceEdge?: Resolver<
    Maybe<ResolversTypes["TaskResourcesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateTaskResourcePayloadTaskResourceEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTaskSourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateTaskSourcePayload"] = ResolversParentTypes["CreateTaskSourcePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  taskSource?: Resolver<
    Maybe<ResolversTypes["TaskSource"]>,
    ParentType,
    ContextType
  >;
  taskSourceEdge?: Resolver<
    Maybe<ResolversTypes["TaskSourcesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateTaskSourcePayloadTaskSourceEdgeArgs, "orderBy">
  >;
  toolSourceByToolSourceId?: Resolver<
    Maybe<ResolversTypes["ToolSource"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTaskStatusLogPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateTaskStatusLogPayload"] = ResolversParentTypes["CreateTaskStatusLogPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  taskStatusLog?: Resolver<
    Maybe<ResolversTypes["TaskStatusLog"]>,
    ParentType,
    ContextType
  >;
  taskStatusLogEdge?: Resolver<
    Maybe<ResolversTypes["TaskStatusLogsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateTaskStatusLogPayloadTaskStatusLogEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTenantContractAllowancePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateTenantContractAllowancePayload"] = ResolversParentTypes["CreateTenantContractAllowancePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantContractAllowance?: Resolver<
    Maybe<ResolversTypes["TenantContractAllowance"]>,
    ParentType,
    ContextType
  >;
  tenantContractAllowanceEdge?: Resolver<
    Maybe<ResolversTypes["TenantContractAllowancesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateTenantContractAllowancePayloadTenantContractAllowanceEdgeArgs,
      "orderBy"
    >
  >;
  tenantContractTransactionByTenantContractTransactionId?: Resolver<
    Maybe<ResolversTypes["TenantContractTransaction"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTenantContractPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateTenantContractPayload"] = ResolversParentTypes["CreateTenantContractPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantContract?: Resolver<
    Maybe<ResolversTypes["TenantContract"]>,
    ParentType,
    ContextType
  >;
  tenantContractEdge?: Resolver<
    Maybe<ResolversTypes["TenantContractsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateTenantContractPayloadTenantContractEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTenantContractTransactionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateTenantContractTransactionPayload"] = ResolversParentTypes["CreateTenantContractTransactionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantContractByTenantContractId?: Resolver<
    Maybe<ResolversTypes["TenantContract"]>,
    ParentType,
    ContextType
  >;
  tenantContractTransaction?: Resolver<
    Maybe<ResolversTypes["TenantContractTransaction"]>,
    ParentType,
    ContextType
  >;
  tenantContractTransactionEdge?: Resolver<
    Maybe<ResolversTypes["TenantContractTransactionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateTenantContractTransactionPayloadTenantContractTransactionEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTenantMockuserexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateTenantMockuserexecutorPayload"] = ResolversParentTypes["CreateTenantMockuserexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantMockuserexecutor?: Resolver<
    Maybe<ResolversTypes["TenantMockuserexecutor"]>,
    ParentType,
    ContextType
  >;
  tenantMockuserexecutorEdge?: Resolver<
    Maybe<ResolversTypes["TenantMockuserexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateTenantMockuserexecutorPayloadTenantMockuserexecutorEdgeArgs,
      "orderBy"
    >
  >;
  tenantUserexecutorByUserexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TenantUserexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTenantMonthlyConsumedResourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateTenantMonthlyConsumedResourcePayload"] = ResolversParentTypes["CreateTenantMonthlyConsumedResourcePayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantContractByTenantContractId?: Resolver<
    Maybe<ResolversTypes["TenantContract"]>,
    ParentType,
    ContextType
  >;
  tenantMonthlyConsumedResource?: Resolver<
    Maybe<ResolversTypes["TenantMonthlyConsumedResource"]>,
    ParentType,
    ContextType
  >;
  tenantMonthlyConsumedResourceEdge?: Resolver<
    Maybe<ResolversTypes["TenantMonthlyConsumedResourcesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateTenantMonthlyConsumedResourcePayloadTenantMonthlyConsumedResourceEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTenantPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateTenantPayload"] = ResolversParentTypes["CreateTenantPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenant?: Resolver<Maybe<ResolversTypes["Tenant"]>, ParentType, ContextType>;
  tenantEdge?: Resolver<
    Maybe<ResolversTypes["TenantsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateTenantPayloadTenantEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTenantUserexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateTenantUserexecutorPayload"] = ResolversParentTypes["CreateTenantUserexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeByPolymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantUserexecutor?: Resolver<
    Maybe<ResolversTypes["TenantUserexecutor"]>,
    ParentType,
    ContextType
  >;
  tenantUserexecutorEdge?: Resolver<
    Maybe<ResolversTypes["TenantUserexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateTenantUserexecutorPayloadTenantUserexecutorEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTesContainerimageexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateTesContainerimageexecutorPayload"] = ResolversParentTypes["CreateTesContainerimageexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeByPolymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesContainerimageexecutor?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  tesContainerimageexecutorEdge?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateTesContainerimageexecutorPayloadTesContainerimageexecutorEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTesMockcontainerimageexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateTesMockcontainerimageexecutorPayload"] = ResolversParentTypes["CreateTesMockcontainerimageexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesContainerimageexecutorByContainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  tesMockcontainerimageexecutor?: Resolver<
    Maybe<ResolversTypes["TesMockcontainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  tesMockcontainerimageexecutorEdge?: Resolver<
    Maybe<ResolversTypes["TesMockcontainerimageexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateTesMockcontainerimageexecutorPayloadTesMockcontainerimageexecutorEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTesMocktaskexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateTesMocktaskexecutorPayload"] = ResolversParentTypes["CreateTesMocktaskexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesMocktaskexecutor?: Resolver<
    Maybe<ResolversTypes["TesMocktaskexecutor"]>,
    ParentType,
    ContextType
  >;
  tesMocktaskexecutorEdge?: Resolver<
    Maybe<ResolversTypes["TesMocktaskexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateTesMocktaskexecutorPayloadTesMocktaskexecutorEdgeArgs,
      "orderBy"
    >
  >;
  tesTaskexecutorByTaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTesTaskexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateTesTaskexecutorPayload"] = ResolversParentTypes["CreateTesTaskexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeByPolymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesTaskexecutor?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutor"]>,
    ParentType,
    ContextType
  >;
  tesTaskexecutorEdge?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateTesTaskexecutorPayloadTesTaskexecutorEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateToolCategoryPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateToolCategoryPayload"] = ResolversParentTypes["CreateToolCategoryPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolCategory?: Resolver<
    Maybe<ResolversTypes["ToolCategory"]>,
    ParentType,
    ContextType
  >;
  toolCategoryEdge?: Resolver<
    Maybe<ResolversTypes["ToolCategoriesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateToolCategoryPayloadToolCategoryEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateToolDisplayCategoryPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateToolDisplayCategoryPayload"] = ResolversParentTypes["CreateToolDisplayCategoryPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolByToolId?: Resolver<
    Maybe<ResolversTypes["Tool"]>,
    ParentType,
    ContextType
  >;
  toolCategoryByToolcategoryId?: Resolver<
    Maybe<ResolversTypes["ToolCategory"]>,
    ParentType,
    ContextType
  >;
  toolDisplayCategory?: Resolver<
    Maybe<ResolversTypes["ToolDisplayCategory"]>,
    ParentType,
    ContextType
  >;
  toolDisplayCategoryEdge?: Resolver<
    Maybe<ResolversTypes["ToolDisplayCategoriesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateToolDisplayCategoryPayloadToolDisplayCategoryEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateToolParameterPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateToolParameterPayload"] = ResolversParentTypes["CreateToolParameterPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolParameter?: Resolver<
    Maybe<ResolversTypes["ToolParameter"]>,
    ParentType,
    ContextType
  >;
  toolParameterEdge?: Resolver<
    Maybe<ResolversTypes["ToolParametersEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateToolParameterPayloadToolParameterEdgeArgs, "orderBy">
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateToolPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateToolPayload"] = ResolversParentTypes["CreateToolPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tool?: Resolver<Maybe<ResolversTypes["Tool"]>, ParentType, ContextType>;
  toolEdge?: Resolver<
    Maybe<ResolversTypes["ToolsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateToolPayloadToolEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateToolResourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateToolResourcePayload"] = ResolversParentTypes["CreateToolResourcePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolResource?: Resolver<
    Maybe<ResolversTypes["ToolResource"]>,
    ParentType,
    ContextType
  >;
  toolResourceEdge?: Resolver<
    Maybe<ResolversTypes["ToolResourcesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateToolResourcePayloadToolResourceEdgeArgs, "orderBy">
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateToolResultPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateToolResultPayload"] = ResolversParentTypes["CreateToolResultPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolResult?: Resolver<
    Maybe<ResolversTypes["ToolResult"]>,
    ParentType,
    ContextType
  >;
  toolResultEdge?: Resolver<
    Maybe<ResolversTypes["ToolResultsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateToolResultPayloadToolResultEdgeArgs, "orderBy">
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateToolSecretPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateToolSecretPayload"] = ResolversParentTypes["CreateToolSecretPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolSecret?: Resolver<
    Maybe<ResolversTypes["ToolSecret"]>,
    ParentType,
    ContextType
  >;
  toolSecretEdge?: Resolver<
    Maybe<ResolversTypes["ToolSecretsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateToolSecretPayloadToolSecretEdgeArgs, "orderBy">
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateToolSourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateToolSourcePayload"] = ResolversParentTypes["CreateToolSourcePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolSource?: Resolver<
    Maybe<ResolversTypes["ToolSource"]>,
    ParentType,
    ContextType
  >;
  toolSourceEdge?: Resolver<
    Maybe<ResolversTypes["ToolSourcesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateToolSourcePayloadToolSourceEdgeArgs, "orderBy">
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateToolTenantPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateToolTenantPayload"] = ResolversParentTypes["CreateToolTenantPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  toolByToolId?: Resolver<
    Maybe<ResolversTypes["Tool"]>,
    ParentType,
    ContextType
  >;
  toolTenant?: Resolver<
    Maybe<ResolversTypes["ToolTenant"]>,
    ParentType,
    ContextType
  >;
  toolTenantEdge?: Resolver<
    Maybe<ResolversTypes["ToolTenantsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateToolTenantPayloadToolTenantEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateToolVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateToolVersionPayload"] = ResolversParentTypes["CreateToolVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolByToolId?: Resolver<
    Maybe<ResolversTypes["Tool"]>,
    ParentType,
    ContextType
  >;
  toolVersion?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  toolVersionEdge?: Resolver<
    Maybe<ResolversTypes["ToolVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateToolVersionPayloadToolVersionEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateToolboxExitcodePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateToolboxExitcodePayload"] = ResolversParentTypes["CreateToolboxExitcodePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolboxExitcode?: Resolver<
    Maybe<ResolversTypes["ToolboxExitcode"]>,
    ParentType,
    ContextType
  >;
  toolboxExitcodeEdge?: Resolver<
    Maybe<ResolversTypes["ToolboxExitcodesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateToolboxExitcodePayloadToolboxExitcodeEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateToolboxPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateToolboxPayload"] = ResolversParentTypes["CreateToolboxPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolbox?: Resolver<Maybe<ResolversTypes["Toolbox"]>, ParentType, ContextType>;
  toolboxEdge?: Resolver<
    Maybe<ResolversTypes["ToolboxesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateToolboxPayloadToolboxEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateToolboxTenantPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateToolboxTenantPayload"] = ResolversParentTypes["CreateToolboxTenantPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  toolboxByToolboxId?: Resolver<
    Maybe<ResolversTypes["Toolbox"]>,
    ParentType,
    ContextType
  >;
  toolboxTenant?: Resolver<
    Maybe<ResolversTypes["ToolboxTenant"]>,
    ParentType,
    ContextType
  >;
  toolboxTenantEdge?: Resolver<
    Maybe<ResolversTypes["ToolboxTenantsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateToolboxTenantPayloadToolboxTenantEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateToolboxVersionExitcodePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateToolboxVersionExitcodePayload"] = ResolversParentTypes["CreateToolboxVersionExitcodePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolboxExitcodeByToolboxexitcodeId?: Resolver<
    Maybe<ResolversTypes["ToolboxExitcode"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionByToolboxversionId?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionExitcode?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionExitcode"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionExitcodeEdge?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionExitcodesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateToolboxVersionExitcodePayloadToolboxVersionExitcodeEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateToolboxVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateToolboxVersionPayload"] = ResolversParentTypes["CreateToolboxVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolboxByToolboxId?: Resolver<
    Maybe<ResolversTypes["Toolbox"]>,
    ParentType,
    ContextType
  >;
  toolboxVersion?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionEdge?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateToolboxVersionPayloadToolboxVersionEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateToolboxVersionToolVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateToolboxVersionToolVersionPayload"] = ResolversParentTypes["CreateToolboxVersionToolVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolVersionByToolversionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionByToolboxversionId?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionToolVersion?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionToolVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionToolVersionEdge?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionToolVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateToolboxVersionToolVersionPayloadToolboxVersionToolVersionEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateWorkflowDatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateWorkflowDatumPayload"] = ResolversParentTypes["CreateWorkflowDatumPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowDatum?: Resolver<
    Maybe<ResolversTypes["WorkflowDatum"]>,
    ParentType,
    ContextType
  >;
  workflowDatumEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowDataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateWorkflowDatumPayloadWorkflowDatumEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateWorkflowPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateWorkflowPayload"] = ResolversParentTypes["CreateWorkflowPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  containerImageByContainerId?: Resolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  workflow?: Resolver<
    Maybe<ResolversTypes["Workflow"]>,
    ParentType,
    ContextType
  >;
  workflowEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateWorkflowPayloadWorkflowEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateWorkflowResultPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateWorkflowResultPayload"] = ResolversParentTypes["CreateWorkflowResultPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowDatumByObjectId?: Resolver<
    Maybe<ResolversTypes["WorkflowDatum"]>,
    ParentType,
    ContextType
  >;
  workflowResult?: Resolver<
    Maybe<ResolversTypes["WorkflowResult"]>,
    ParentType,
    ContextType
  >;
  workflowResultEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowResultsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateWorkflowResultPayloadWorkflowResultEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateWorkflowResultTagPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateWorkflowResultTagPayload"] = ResolversParentTypes["CreateWorkflowResultTagPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tagByTagId?: Resolver<Maybe<ResolversTypes["Tag"]>, ParentType, ContextType>;
  workflowResultByResultId?: Resolver<
    Maybe<ResolversTypes["WorkflowResult"]>,
    ParentType,
    ContextType
  >;
  workflowResultTag?: Resolver<
    Maybe<ResolversTypes["WorkflowResultTag"]>,
    ParentType,
    ContextType
  >;
  workflowResultTagEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowResultTagsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateWorkflowResultTagPayloadWorkflowResultTagEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateWorkflowResultWorkflowVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateWorkflowResultWorkflowVersionPayload"] = ResolversParentTypes["CreateWorkflowResultWorkflowVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowResultByResultId?: Resolver<
    Maybe<ResolversTypes["WorkflowResult"]>,
    ParentType,
    ContextType
  >;
  workflowResultWorkflowVersion?: Resolver<
    Maybe<ResolversTypes["WorkflowResultWorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  workflowResultWorkflowVersionEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowResultWorkflowVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateWorkflowResultWorkflowVersionPayloadWorkflowResultWorkflowVersionEdgeArgs,
      "orderBy"
    >
  >;
  workflowVersionByWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["WorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateWorkflowSectionBlockPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateWorkflowSectionBlockPayload"] = ResolversParentTypes["CreateWorkflowSectionBlockPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolVersionByToolId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  workflowDatumByObjectId?: Resolver<
    Maybe<ResolversTypes["WorkflowDatum"]>,
    ParentType,
    ContextType
  >;
  workflowSectionBlock?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlock"]>,
    ParentType,
    ContextType
  >;
  workflowSectionBlockEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlocksEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateWorkflowSectionBlockPayloadWorkflowSectionBlockEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateWorkflowSectionBlockSectionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateWorkflowSectionBlockSectionPayload"] = ResolversParentTypes["CreateWorkflowSectionBlockSectionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowSectionBlockByBlockId?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlock"]>,
    ParentType,
    ContextType
  >;
  workflowSectionBlockSection?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlockSection"]>,
    ParentType,
    ContextType
  >;
  workflowSectionBlockSectionEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlockSectionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateWorkflowSectionBlockSectionPayloadWorkflowSectionBlockSectionEdgeArgs,
      "orderBy"
    >
  >;
  workflowSectionBySectionId?: Resolver<
    Maybe<ResolversTypes["WorkflowSection"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateWorkflowSectionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateWorkflowSectionPayload"] = ResolversParentTypes["CreateWorkflowSectionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowSection?: Resolver<
    Maybe<ResolversTypes["WorkflowSection"]>,
    ParentType,
    ContextType
  >;
  workflowSectionEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateWorkflowSectionPayloadWorkflowSectionEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateWorkflowSectionWorkflowVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateWorkflowSectionWorkflowVersionPayload"] = ResolversParentTypes["CreateWorkflowSectionWorkflowVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowSectionBySectionId?: Resolver<
    Maybe<ResolversTypes["WorkflowSection"]>,
    ParentType,
    ContextType
  >;
  workflowSectionWorkflowVersion?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionWorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  workflowSectionWorkflowVersionEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionWorkflowVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateWorkflowSectionWorkflowVersionPayloadWorkflowSectionWorkflowVersionEdgeArgs,
      "orderBy"
    >
  >;
  workflowVersionByWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["WorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateWorkflowSourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateWorkflowSourcePayload"] = ResolversParentTypes["CreateWorkflowSourcePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowSource?: Resolver<
    Maybe<ResolversTypes["WorkflowSource"]>,
    ParentType,
    ContextType
  >;
  workflowSourceEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSourcesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<CreateWorkflowSourcePayloadWorkflowSourceEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateWorkflowSourceTagPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateWorkflowSourceTagPayload"] = ResolversParentTypes["CreateWorkflowSourceTagPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tagByTagId?: Resolver<Maybe<ResolversTypes["Tag"]>, ParentType, ContextType>;
  workflowSourceBySourceId?: Resolver<
    Maybe<ResolversTypes["WorkflowSource"]>,
    ParentType,
    ContextType
  >;
  workflowSourceTag?: Resolver<
    Maybe<ResolversTypes["WorkflowSourceTag"]>,
    ParentType,
    ContextType
  >;
  workflowSourceTagEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSourceTagsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateWorkflowSourceTagPayloadWorkflowSourceTagEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateWorkflowSourceWorkflowVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateWorkflowSourceWorkflowVersionPayload"] = ResolversParentTypes["CreateWorkflowSourceWorkflowVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowSourceBySourceId?: Resolver<
    Maybe<ResolversTypes["WorkflowSource"]>,
    ParentType,
    ContextType
  >;
  workflowSourceWorkflowVersion?: Resolver<
    Maybe<ResolversTypes["WorkflowSourceWorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  workflowSourceWorkflowVersionEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSourceWorkflowVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateWorkflowSourceWorkflowVersionPayloadWorkflowSourceWorkflowVersionEdgeArgs,
      "orderBy"
    >
  >;
  workflowVersionByWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["WorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateWorkflowVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CreateWorkflowVersionPayload"] = ResolversParentTypes["CreateWorkflowVersionPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowByWorkflowId?: Resolver<
    Maybe<ResolversTypes["Workflow"]>,
    ParentType,
    ContextType
  >;
  workflowVersion?: Resolver<
    Maybe<ResolversTypes["WorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  workflowVersionEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      CreateWorkflowVersionPayloadWorkflowVersionEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrentApplicationUserResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CurrentApplicationUser"] = ResolversParentTypes["CurrentApplicationUser"],
> = {
  email?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  globalUserId?: Resolver<
    Maybe<ResolversTypes["UUID"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  isActive?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType
  >;
  username?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrentApplicationUsersConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CurrentApplicationUsersConnection"] = ResolversParentTypes["CurrentApplicationUsersConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["CurrentApplicationUsersEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["CurrentApplicationUser"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrentApplicationUsersEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CurrentApplicationUsersEdge"] = ResolversParentTypes["CurrentApplicationUsersEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["CurrentApplicationUser"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrentUserMembershipResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CurrentUserMembership"] = ResolversParentTypes["CurrentUserMembership"],
> = {
  role?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  tenantId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrentUserMembershipsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CurrentUserMembershipsConnection"] = ResolversParentTypes["CurrentUserMembershipsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["CurrentUserMembershipsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["CurrentUserMembership"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrentUserMembershipsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["CurrentUserMembershipsEdge"] = ResolversParentTypes["CurrentUserMembershipsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["CurrentUserMembership"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CursorScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes["Cursor"], any> {
  name: "Cursor";
}

export type DatasetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["Dataset"] = ResolversParentTypes["Dataset"],
> = {
  activeDescription?: Resolver<
    Maybe<ResolversTypes["JSON"]>,
    ParentType,
    ContextType,
    Partial<DatasetActiveDescriptionArgs>
  >;
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  datasetMetadataByDatasetId?: Resolver<
    ResolversTypes["DatasetMetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<DatasetDatasetMetadataByDatasetIdArgs, "orderBy">
  >;
  datasetRecordingsTableByRecordingsTableId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTable"]>,
    ParentType,
    ContextType
  >;
  datasetVersionsByDatasetId?: Resolver<
    ResolversTypes["DatasetVersionsConnection"],
    ParentType,
    ContextType,
    RequireFields<DatasetDatasetVersionsByDatasetIdArgs, "orderBy">
  >;
  dateArchived?: Resolver<
    Maybe<ResolversTypes["Datetime"]>,
    ParentType,
    ContextType
  >;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  dateDataDeleted?: Resolver<
    Maybe<ResolversTypes["Datetime"]>,
    ParentType,
    ContextType
  >;
  dateDeleted?: Resolver<
    Maybe<ResolversTypes["Datetime"]>,
    ParentType,
    ContextType
  >;
  descriptionId?: Resolver<
    Maybe<ResolversTypes["UUID"]>,
    ParentType,
    ContextType
  >;
  filesByDatasetId?: Resolver<
    ResolversTypes["FilesConnection"],
    ParentType,
    ContextType,
    RequireFields<DatasetFilesByDatasetIdArgs, "orderBy">
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  key?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  metadatumByDescriptionId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  prefix?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  recordingsTableId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  status?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  tasksByDatasetId?: Resolver<
    ResolversTypes["TasksConnection"],
    ParentType,
    ContextType,
    RequireFields<DatasetTasksByDatasetIdArgs, "orderBy">
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetMetadataConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DatasetMetadataConnection"] = ResolversParentTypes["DatasetMetadataConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["DatasetMetadataEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["DatasetMetadatum"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetMetadataEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DatasetMetadataEdge"] = ResolversParentTypes["DatasetMetadataEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["DatasetMetadatum"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetMetadatumResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DatasetMetadatum"] = ResolversParentTypes["DatasetMetadatum"],
> = {
  datasetByDatasetId?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType
  >;
  datasetId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  metadataId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetRecordingsTableResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DatasetRecordingsTable"] = ResolversParentTypes["DatasetRecordingsTable"],
> = {
  activeColumns?: Resolver<
    ResolversTypes["DatasetRecordingsTableColumnsConnection"],
    ParentType,
    ContextType,
    Partial<DatasetRecordingsTableActiveColumnsArgs>
  >;
  datasetByRecordingsTableId?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnsByDatasetRecordingsTableId?: Resolver<
    ResolversTypes["DatasetRecordingsTableColumnsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      DatasetRecordingsTableDatasetRecordingsTableColumnsByDatasetRecordingsTableIdArgs,
      "orderBy"
    >
  >;
  datasetsByRecordingsTableId?: Resolver<
    ResolversTypes["DatasetsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      DatasetRecordingsTableDatasetsByRecordingsTableIdArgs,
      "orderBy"
    >
  >;
  fileRecordingGroupsByDatasetRecordingsTableId?: Resolver<
    ResolversTypes["FileRecordingGroupsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      DatasetRecordingsTableFileRecordingGroupsByDatasetRecordingsTableIdArgs,
      "orderBy"
    >
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes["JSON"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetRecordingsTableColumnResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DatasetRecordingsTableColumn"] = ResolversParentTypes["DatasetRecordingsTableColumn"],
> = {
  activeColDef?: Resolver<
    Maybe<ResolversTypes["JSON"]>,
    ParentType,
    ContextType,
    Partial<DatasetRecordingsTableColumnActiveColDefArgs>
  >;
  activeOrder?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType,
    Partial<DatasetRecordingsTableColumnActiveOrderArgs>
  >;
  colDefId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  datasetRecordingsTableByDatasetRecordingsTableId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTable"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnOrdersByDatasetRecordingsTableColumnId?: Resolver<
    ResolversTypes["DatasetRecordingsTableColumnOrdersConnection"],
    ParentType,
    ContextType,
    RequireFields<
      DatasetRecordingsTableColumnDatasetRecordingsTableColumnOrdersByDatasetRecordingsTableColumnIdArgs,
      "orderBy"
    >
  >;
  datasetRecordingsTableId?: Resolver<
    ResolversTypes["UUID"],
    ParentType,
    ContextType
  >;
  dateAssignmentEnded?: Resolver<
    Maybe<ResolversTypes["Datetime"]>,
    ParentType,
    ContextType
  >;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  identifierPosition?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  metadatumByColDefId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  pinned?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  recordingGroupFilesByDatasetRecordingsTableColumnId?: Resolver<
    ResolversTypes["RecordingGroupFilesConnection"],
    ParentType,
    ContextType,
    RequireFields<
      DatasetRecordingsTableColumnRecordingGroupFilesByDatasetRecordingsTableColumnIdArgs,
      "orderBy"
    >
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  width?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetRecordingsTableColumnOrderResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DatasetRecordingsTableColumnOrder"] = ResolversParentTypes["DatasetRecordingsTableColumnOrder"],
> = {
  datasetRecordingsTableColumnByDatasetRecordingsTableColumnId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumn"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnId?: Resolver<
    ResolversTypes["UUID"],
    ParentType,
    ContextType
  >;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  value?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetRecordingsTableColumnOrdersConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DatasetRecordingsTableColumnOrdersConnection"] = ResolversParentTypes["DatasetRecordingsTableColumnOrdersConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["DatasetRecordingsTableColumnOrdersEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["DatasetRecordingsTableColumnOrder"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetRecordingsTableColumnOrdersEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DatasetRecordingsTableColumnOrdersEdge"] = ResolversParentTypes["DatasetRecordingsTableColumnOrdersEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["DatasetRecordingsTableColumnOrder"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetRecordingsTableColumnsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DatasetRecordingsTableColumnsConnection"] = ResolversParentTypes["DatasetRecordingsTableColumnsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["DatasetRecordingsTableColumnsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["DatasetRecordingsTableColumn"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetRecordingsTableColumnsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DatasetRecordingsTableColumnsEdge"] = ResolversParentTypes["DatasetRecordingsTableColumnsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["DatasetRecordingsTableColumn"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetRecordingsTablesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DatasetRecordingsTablesConnection"] = ResolversParentTypes["DatasetRecordingsTablesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["DatasetRecordingsTablesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["DatasetRecordingsTable"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetRecordingsTablesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DatasetRecordingsTablesEdge"] = ResolversParentTypes["DatasetRecordingsTablesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["DatasetRecordingsTable"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetVersionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DatasetVersion"] = ResolversParentTypes["DatasetVersion"],
> = {
  datasetByDatasetId?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType
  >;
  datasetId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  validationState?: Resolver<ResolversTypes["JSON"], ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes["JSON"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetVersionsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DatasetVersionsConnection"] = ResolversParentTypes["DatasetVersionsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["DatasetVersionsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["DatasetVersion"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetVersionsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DatasetVersionsEdge"] = ResolversParentTypes["DatasetVersionsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["DatasetVersion"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DatasetsConnection"] = ResolversParentTypes["DatasetsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["DatasetsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes["Dataset"]>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DatasetsEdge"] = ResolversParentTypes["DatasetsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["Dataset"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes["Date"], any> {
  name: "Date";
}

export interface DatetimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes["Datetime"], any> {
  name: "Datetime";
}

export type DeleteAnalysisTableGroupPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteAnalysisTableGroupPayload"] = ResolversParentTypes["DeleteAnalysisTableGroupPayload"],
> = {
  analysisTableGroup?: Resolver<
    Maybe<ResolversTypes["AnalysisTableGroup"]>,
    ParentType,
    ContextType
  >;
  analysisTableGroupEdge?: Resolver<
    Maybe<ResolversTypes["AnalysisTableGroupsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteAnalysisTableGroupPayloadAnalysisTableGroupEdgeArgs,
      "orderBy"
    >
  >;
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedAnalysisTableGroupId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  toolByToolId?: Resolver<
    Maybe<ResolversTypes["Tool"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteAnalysisTablePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteAnalysisTablePayload"] = ResolversParentTypes["DeleteAnalysisTablePayload"],
> = {
  analysisTable?: Resolver<
    Maybe<ResolversTypes["AnalysisTable"]>,
    ParentType,
    ContextType
  >;
  analysisTableEdge?: Resolver<
    Maybe<ResolversTypes["AnalysisTablesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteAnalysisTablePayloadAnalysisTableEdgeArgs, "orderBy">
  >;
  analysisTableGroupByGroupId?: Resolver<
    Maybe<ResolversTypes["AnalysisTableGroup"]>,
    ParentType,
    ContextType
  >;
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedAnalysisTableId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteAnalysisTableRowAttachResultPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteAnalysisTableRowAttachResultPayload"] = ResolversParentTypes["DeleteAnalysisTableRowAttachResultPayload"],
> = {
  analysisTableRowAttachResult?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRowAttachResult"]>,
    ParentType,
    ContextType
  >;
  analysisTableRowAttachResultEdge?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRowAttachResultsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteAnalysisTableRowAttachResultPayloadAnalysisTableRowAttachResultEdgeArgs,
      "orderBy"
    >
  >;
  analysisTableRowByAnalysisTableRowId?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRow"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedAnalysisTableRowAttachResultId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteAnalysisTableRowPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteAnalysisTableRowPayload"] = ResolversParentTypes["DeleteAnalysisTableRowPayload"],
> = {
  analysisTableByTableId?: Resolver<
    Maybe<ResolversTypes["AnalysisTable"]>,
    ParentType,
    ContextType
  >;
  analysisTableRow?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRow"]>,
    ParentType,
    ContextType
  >;
  analysisTableRowEdge?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRowsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteAnalysisTableRowPayloadAnalysisTableRowEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedAnalysisTableRowId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteApplicationUserGroupPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteApplicationUserGroupPayload"] = ResolversParentTypes["DeleteApplicationUserGroupPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserGroup?: Resolver<
    Maybe<ResolversTypes["ApplicationUserGroup"]>,
    ParentType,
    ContextType
  >;
  applicationUserGroupEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUserGroupsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteApplicationUserGroupPayloadApplicationUserGroupEdgeArgs,
      "orderBy"
    >
  >;
  authGroupByGroupId?: Resolver<
    Maybe<ResolversTypes["AuthGroup"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedApplicationUserGroupId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteApplicationUserMembershipPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteApplicationUserMembershipPayload"] = ResolversParentTypes["DeleteApplicationUserMembershipPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserMembership?: Resolver<
    Maybe<ResolversTypes["ApplicationUserMembership"]>,
    ParentType,
    ContextType
  >;
  applicationUserMembershipEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUserMembershipsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteApplicationUserMembershipPayloadApplicationUserMembershipEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedApplicationUserMembershipId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteApplicationUserMetadatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteApplicationUserMetadatumPayload"] = ResolversParentTypes["DeleteApplicationUserMetadatumPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserMetadatum?: Resolver<
    Maybe<ResolversTypes["ApplicationUserMetadatum"]>,
    ParentType,
    ContextType
  >;
  applicationUserMetadatumEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUserMetadataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteApplicationUserMetadatumPayloadApplicationUserMetadatumEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedApplicationUserMetadatumId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteApplicationUserPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteApplicationUserPayload"] = ResolversParentTypes["DeleteApplicationUserPayload"],
> = {
  applicationUser?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUsersEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteApplicationUserPayloadApplicationUserEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedApplicationUserId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteApplicationUserSessionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteApplicationUserSessionPayload"] = ResolversParentTypes["DeleteApplicationUserSessionPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserSession?: Resolver<
    Maybe<ResolversTypes["ApplicationUserSession"]>,
    ParentType,
    ContextType
  >;
  applicationUserSessionEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUserSessionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteApplicationUserSessionPayloadApplicationUserSessionEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedApplicationUserSessionId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteApplicationUserUserPermissionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteApplicationUserUserPermissionPayload"] = ResolversParentTypes["DeleteApplicationUserUserPermissionPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserUserPermission?: Resolver<
    Maybe<ResolversTypes["ApplicationUserUserPermission"]>,
    ParentType,
    ContextType
  >;
  applicationUserUserPermissionEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUserUserPermissionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteApplicationUserUserPermissionPayloadApplicationUserUserPermissionEdgeArgs,
      "orderBy"
    >
  >;
  authPermissionByPermissionId?: Resolver<
    Maybe<ResolversTypes["AuthPermission"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedApplicationUserUserPermissionId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteAuthGroupPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteAuthGroupPayload"] = ResolversParentTypes["DeleteAuthGroupPayload"],
> = {
  authGroup?: Resolver<
    Maybe<ResolversTypes["AuthGroup"]>,
    ParentType,
    ContextType
  >;
  authGroupEdge?: Resolver<
    Maybe<ResolversTypes["AuthGroupsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteAuthGroupPayloadAuthGroupEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedAuthGroupId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteAuthGroupPermissionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteAuthGroupPermissionPayload"] = ResolversParentTypes["DeleteAuthGroupPermissionPayload"],
> = {
  authGroupByGroupId?: Resolver<
    Maybe<ResolversTypes["AuthGroup"]>,
    ParentType,
    ContextType
  >;
  authGroupPermission?: Resolver<
    Maybe<ResolversTypes["AuthGroupPermission"]>,
    ParentType,
    ContextType
  >;
  authGroupPermissionEdge?: Resolver<
    Maybe<ResolversTypes["AuthGroupPermissionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteAuthGroupPermissionPayloadAuthGroupPermissionEdgeArgs,
      "orderBy"
    >
  >;
  authPermissionByPermissionId?: Resolver<
    Maybe<ResolversTypes["AuthPermission"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedAuthGroupPermissionId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteAuthPermissionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteAuthPermissionPayload"] = ResolversParentTypes["DeleteAuthPermissionPayload"],
> = {
  authPermission?: Resolver<
    Maybe<ResolversTypes["AuthPermission"]>,
    ParentType,
    ContextType
  >;
  authPermissionEdge?: Resolver<
    Maybe<ResolversTypes["AuthPermissionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteAuthPermissionPayloadAuthPermissionEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedAuthPermissionId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeByContentTypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteAvatarPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteAvatarPayload"] = ResolversParentTypes["DeleteAvatarPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  avatar?: Resolver<Maybe<ResolversTypes["Avatar"]>, ParentType, ContextType>;
  avatarEdge?: Resolver<
    Maybe<ResolversTypes["AvatarsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteAvatarPayloadAvatarEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedAvatarId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteAwsBatchTaskPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteAwsBatchTaskPayload"] = ResolversParentTypes["DeleteAwsBatchTaskPayload"],
> = {
  awsBatchTask?: Resolver<
    Maybe<ResolversTypes["AwsBatchTask"]>,
    ParentType,
    ContextType
  >;
  awsBatchTaskEdge?: Resolver<
    Maybe<ResolversTypes["AwsBatchTasksEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteAwsBatchTaskPayloadAwsBatchTaskEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedAwsBatchTaskId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesTaskexecutorByTaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteAwsCognitoUserPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteAwsCognitoUserPayload"] = ResolversParentTypes["DeleteAwsCognitoUserPayload"],
> = {
  awsCognitoUser?: Resolver<
    Maybe<ResolversTypes["AwsCognitoUser"]>,
    ParentType,
    ContextType
  >;
  awsCognitoUserEdge?: Resolver<
    Maybe<ResolversTypes["AwsCognitoUsersEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteAwsCognitoUserPayloadAwsCognitoUserEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedAwsCognitoUserId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantUserexecutorByUserexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TenantUserexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteAwsEcrImagePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteAwsEcrImagePayload"] = ResolversParentTypes["DeleteAwsEcrImagePayload"],
> = {
  awsEcrImage?: Resolver<
    Maybe<ResolversTypes["AwsEcrImage"]>,
    ParentType,
    ContextType
  >;
  awsEcrImageEdge?: Resolver<
    Maybe<ResolversTypes["AwsEcrImagesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteAwsEcrImagePayloadAwsEcrImageEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedAwsEcrImageId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesContainerimageexecutorByContainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteAwsMockBatchTaskPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteAwsMockBatchTaskPayload"] = ResolversParentTypes["DeleteAwsMockBatchTaskPayload"],
> = {
  awsBatchTaskByAwstaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsBatchTask"]>,
    ParentType,
    ContextType
  >;
  awsMockBatchTask?: Resolver<
    Maybe<ResolversTypes["AwsMockBatchTask"]>,
    ParentType,
    ContextType
  >;
  awsMockBatchTaskEdge?: Resolver<
    Maybe<ResolversTypes["AwsMockBatchTasksEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteAwsMockBatchTaskPayloadAwsMockBatchTaskEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedAwsMockBatchTaskId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteAwsMockEcrImagePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteAwsMockEcrImagePayload"] = ResolversParentTypes["DeleteAwsMockEcrImagePayload"],
> = {
  awsEcrImageByAwscontainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsEcrImage"]>,
    ParentType,
    ContextType
  >;
  awsMockEcrImage?: Resolver<
    Maybe<ResolversTypes["AwsMockEcrImage"]>,
    ParentType,
    ContextType
  >;
  awsMockEcrImageEdge?: Resolver<
    Maybe<ResolversTypes["AwsMockEcrImagesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteAwsMockEcrImagePayloadAwsMockEcrImageEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedAwsMockEcrImageId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteAwsMockS3FilePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteAwsMockS3FilePayload"] = ResolversParentTypes["DeleteAwsMockS3FilePayload"],
> = {
  awsMockS3File?: Resolver<
    Maybe<ResolversTypes["AwsMockS3File"]>,
    ParentType,
    ContextType
  >;
  awsMockS3FileEdge?: Resolver<
    Maybe<ResolversTypes["AwsMockS3FilesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteAwsMockS3FilePayloadAwsMockS3FileEdgeArgs, "orderBy">
  >;
  awsS3FileByAwsfileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsS3File"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedAwsMockS3FileId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteAwsS3FilePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteAwsS3FilePayload"] = ResolversParentTypes["DeleteAwsS3FilePayload"],
> = {
  awsS3File?: Resolver<
    Maybe<ResolversTypes["AwsS3File"]>,
    ParentType,
    ContextType
  >;
  awsS3FileEdge?: Resolver<
    Maybe<ResolversTypes["AwsS3FilesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteAwsS3FilePayloadAwsS3FileEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedAwsS3FileId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  drsFileexecutorByFileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DrsFileexecutor"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteContainerImagePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteContainerImagePayload"] = ResolversParentTypes["DeleteContainerImagePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  containerImage?: Resolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType
  >;
  containerImageEdge?: Resolver<
    Maybe<ResolversTypes["ContainerImagesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteContainerImagePayloadContainerImageEdgeArgs, "orderBy">
  >;
  deletedContainerImageId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesContainerimageexecutorByExecutorId?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionByToolboxVersionId?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteCostPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteCostPayload"] = ResolversParentTypes["DeleteCostPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  cost?: Resolver<Maybe<ResolversTypes["Cost"]>, ParentType, ContextType>;
  costEdge?: Resolver<
    Maybe<ResolversTypes["CostsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteCostPayloadCostEdgeArgs, "orderBy">
  >;
  deletedCostId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteDatasetMetadatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteDatasetMetadatumPayload"] = ResolversParentTypes["DeleteDatasetMetadatumPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetByDatasetId?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType
  >;
  datasetMetadatum?: Resolver<
    Maybe<ResolversTypes["DatasetMetadatum"]>,
    ParentType,
    ContextType
  >;
  datasetMetadatumEdge?: Resolver<
    Maybe<ResolversTypes["DatasetMetadataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteDatasetMetadatumPayloadDatasetMetadatumEdgeArgs,
      "orderBy"
    >
  >;
  deletedDatasetMetadatumId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteDatasetPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteDatasetPayload"] = ResolversParentTypes["DeleteDatasetPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  dataset?: Resolver<Maybe<ResolversTypes["Dataset"]>, ParentType, ContextType>;
  datasetEdge?: Resolver<
    Maybe<ResolversTypes["DatasetsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteDatasetPayloadDatasetEdgeArgs, "orderBy">
  >;
  datasetRecordingsTableByRecordingsTableId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTable"]>,
    ParentType,
    ContextType
  >;
  deletedDatasetId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  metadatumByDescriptionId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteDatasetRecordingsTableColumnOrderPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteDatasetRecordingsTableColumnOrderPayload"] = ResolversParentTypes["DeleteDatasetRecordingsTableColumnOrderPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnByDatasetRecordingsTableColumnId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumn"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnOrder?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumnOrder"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnOrderEdge?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumnOrdersEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteDatasetRecordingsTableColumnOrderPayloadDatasetRecordingsTableColumnOrderEdgeArgs,
      "orderBy"
    >
  >;
  deletedDatasetRecordingsTableColumnOrderId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteDatasetRecordingsTableColumnPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteDatasetRecordingsTableColumnPayload"] = ResolversParentTypes["DeleteDatasetRecordingsTableColumnPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableByDatasetRecordingsTableId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTable"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumn?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumn"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnEdge?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumnsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteDatasetRecordingsTableColumnPayloadDatasetRecordingsTableColumnEdgeArgs,
      "orderBy"
    >
  >;
  deletedDatasetRecordingsTableColumnId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  metadatumByColDefId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteDatasetRecordingsTablePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteDatasetRecordingsTablePayload"] = ResolversParentTypes["DeleteDatasetRecordingsTablePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTable?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTable"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableEdge?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTablesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteDatasetRecordingsTablePayloadDatasetRecordingsTableEdgeArgs,
      "orderBy"
    >
  >;
  deletedDatasetRecordingsTableId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteDatasetVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteDatasetVersionPayload"] = ResolversParentTypes["DeleteDatasetVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetByDatasetId?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType
  >;
  datasetVersion?: Resolver<
    Maybe<ResolversTypes["DatasetVersion"]>,
    ParentType,
    ContextType
  >;
  datasetVersionEdge?: Resolver<
    Maybe<ResolversTypes["DatasetVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteDatasetVersionPayloadDatasetVersionEdgeArgs, "orderBy">
  >;
  deletedDatasetVersionId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteDjangoAdminLogPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteDjangoAdminLogPayload"] = ResolversParentTypes["DeleteDjangoAdminLogPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedDjangoAdminLogId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  djangoAdminLog?: Resolver<
    Maybe<ResolversTypes["DjangoAdminLog"]>,
    ParentType,
    ContextType
  >;
  djangoAdminLogEdge?: Resolver<
    Maybe<ResolversTypes["DjangoAdminLogsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteDjangoAdminLogPayloadDjangoAdminLogEdgeArgs, "orderBy">
  >;
  djangoContentTypeByContentTypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteDjangoContentTypePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteDjangoContentTypePayload"] = ResolversParentTypes["DeleteDjangoContentTypePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedDjangoContentTypeId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  djangoContentType?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeEdge?: Resolver<
    Maybe<ResolversTypes["DjangoContentTypesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteDjangoContentTypePayloadDjangoContentTypeEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteDjangoMigrationPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteDjangoMigrationPayload"] = ResolversParentTypes["DeleteDjangoMigrationPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedDjangoMigrationId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  djangoMigration?: Resolver<
    Maybe<ResolversTypes["DjangoMigration"]>,
    ParentType,
    ContextType
  >;
  djangoMigrationEdge?: Resolver<
    Maybe<ResolversTypes["DjangoMigrationsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteDjangoMigrationPayloadDjangoMigrationEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteDjangoSesSesstatPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteDjangoSesSesstatPayload"] = ResolversParentTypes["DeleteDjangoSesSesstatPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedDjangoSesSesstatId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  djangoSesSesstat?: Resolver<
    Maybe<ResolversTypes["DjangoSesSesstat"]>,
    ParentType,
    ContextType
  >;
  djangoSesSesstatEdge?: Resolver<
    Maybe<ResolversTypes["DjangoSesSesstatsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteDjangoSesSesstatPayloadDjangoSesSesstatEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteDjangoSessionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteDjangoSessionPayload"] = ResolversParentTypes["DeleteDjangoSessionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedDjangoSessionId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  djangoSession?: Resolver<
    Maybe<ResolversTypes["DjangoSession"]>,
    ParentType,
    ContextType
  >;
  djangoSessionEdge?: Resolver<
    Maybe<ResolversTypes["DjangoSessionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteDjangoSessionPayloadDjangoSessionEdgeArgs, "orderBy">
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteDrsFileexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteDrsFileexecutorPayload"] = ResolversParentTypes["DeleteDrsFileexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedDrsFileexecutorId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeByPolymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  drsFileexecutor?: Resolver<
    Maybe<ResolversTypes["DrsFileexecutor"]>,
    ParentType,
    ContextType
  >;
  drsFileexecutorEdge?: Resolver<
    Maybe<ResolversTypes["DrsFileexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteDrsFileexecutorPayloadDrsFileexecutorEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteDrsMockfileexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteDrsMockfileexecutorPayload"] = ResolversParentTypes["DeleteDrsMockfileexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedDrsMockfileexecutorId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  drsFileexecutorByFileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DrsFileexecutor"]>,
    ParentType,
    ContextType
  >;
  drsMockfileexecutor?: Resolver<
    Maybe<ResolversTypes["DrsMockfileexecutor"]>,
    ParentType,
    ContextType
  >;
  drsMockfileexecutorEdge?: Resolver<
    Maybe<ResolversTypes["DrsMockfileexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteDrsMockfileexecutorPayloadDrsMockfileexecutorEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteEulaVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteEulaVersionPayload"] = ResolversParentTypes["DeleteEulaVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedEulaVersionId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  eulaVersion?: Resolver<
    Maybe<ResolversTypes["EulaVersion"]>,
    ParentType,
    ContextType
  >;
  eulaVersionEdge?: Resolver<
    Maybe<ResolversTypes["EulaVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteEulaVersionPayloadEulaVersionEdgeArgs, "orderBy">
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteEulaVersionUserAcceptancePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteEulaVersionUserAcceptancePayload"] = ResolversParentTypes["DeleteEulaVersionUserAcceptancePayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedEulaVersionUserAcceptanceId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  eulaVersionByEulaVersionId?: Resolver<
    Maybe<ResolversTypes["EulaVersion"]>,
    ParentType,
    ContextType
  >;
  eulaVersionUserAcceptance?: Resolver<
    Maybe<ResolversTypes["EulaVersionUserAcceptance"]>,
    ParentType,
    ContextType
  >;
  eulaVersionUserAcceptanceEdge?: Resolver<
    Maybe<ResolversTypes["EulaVersionUserAcceptancesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteEulaVersionUserAcceptancePayloadEulaVersionUserAcceptanceEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteExplorerQueryPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteExplorerQueryPayload"] = ResolversParentTypes["DeleteExplorerQueryPayload"],
> = {
  applicationUserByCreatedByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedExplorerQueryId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  explorerQuery?: Resolver<
    Maybe<ResolversTypes["ExplorerQuery"]>,
    ParentType,
    ContextType
  >;
  explorerQueryEdge?: Resolver<
    Maybe<ResolversTypes["ExplorerQueriesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteExplorerQueryPayloadExplorerQueryEdgeArgs, "orderBy">
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteExplorerQueryfavoritePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteExplorerQueryfavoritePayload"] = ResolversParentTypes["DeleteExplorerQueryfavoritePayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedExplorerQueryfavoriteId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  explorerQueryByQueryId?: Resolver<
    Maybe<ResolversTypes["ExplorerQuery"]>,
    ParentType,
    ContextType
  >;
  explorerQueryfavorite?: Resolver<
    Maybe<ResolversTypes["ExplorerQueryfavorite"]>,
    ParentType,
    ContextType
  >;
  explorerQueryfavoriteEdge?: Resolver<
    Maybe<ResolversTypes["ExplorerQueryfavoritesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteExplorerQueryfavoritePayloadExplorerQueryfavoriteEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteExplorerQuerylogPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteExplorerQuerylogPayload"] = ResolversParentTypes["DeleteExplorerQuerylogPayload"],
> = {
  applicationUserByRunByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedExplorerQuerylogId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  explorerQueryByQueryId?: Resolver<
    Maybe<ResolversTypes["ExplorerQuery"]>,
    ParentType,
    ContextType
  >;
  explorerQuerylog?: Resolver<
    Maybe<ResolversTypes["ExplorerQuerylog"]>,
    ParentType,
    ContextType
  >;
  explorerQuerylogEdge?: Resolver<
    Maybe<ResolversTypes["ExplorerQuerylogsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteExplorerQuerylogPayloadExplorerQuerylogEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteFileActivityPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteFileActivityPayload"] = ResolversParentTypes["DeleteFileActivityPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  costByCostId?: Resolver<
    Maybe<ResolversTypes["Cost"]>,
    ParentType,
    ContextType
  >;
  deletedFileActivityId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  fileActivity?: Resolver<
    Maybe<ResolversTypes["FileActivity"]>,
    ParentType,
    ContextType
  >;
  fileActivityEdge?: Resolver<
    Maybe<ResolversTypes["FileActivitiesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteFileActivityPayloadFileActivityEdgeArgs, "orderBy">
  >;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteFileMetadatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteFileMetadatumPayload"] = ResolversParentTypes["DeleteFileMetadatumPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedFileMetadatumId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileMetadatum?: Resolver<
    Maybe<ResolversTypes["FileMetadatum"]>,
    ParentType,
    ContextType
  >;
  fileMetadatumEdge?: Resolver<
    Maybe<ResolversTypes["FileMetadataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteFileMetadatumPayloadFileMetadatumEdgeArgs, "orderBy">
  >;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteFileOutputGroupMetadatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteFileOutputGroupMetadatumPayload"] = ResolversParentTypes["DeleteFileOutputGroupMetadatumPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedFileOutputGroupMetadatumId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroupByFileOutputGroupId?: Resolver<
    Maybe<ResolversTypes["FileOutputGroup"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroupMetadatum?: Resolver<
    Maybe<ResolversTypes["FileOutputGroupMetadatum"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroupMetadatumEdge?: Resolver<
    Maybe<ResolversTypes["FileOutputGroupMetadataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteFileOutputGroupMetadatumPayloadFileOutputGroupMetadatumEdgeArgs,
      "orderBy"
    >
  >;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteFileOutputGroupPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteFileOutputGroupPayload"] = ResolversParentTypes["DeleteFileOutputGroupPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedFileOutputGroupId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroup?: Resolver<
    Maybe<ResolversTypes["FileOutputGroup"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroupEdge?: Resolver<
    Maybe<ResolversTypes["FileOutputGroupsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteFileOutputGroupPayloadFileOutputGroupEdgeArgs,
      "orderBy"
    >
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteFilePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteFilePayload"] = ResolversParentTypes["DeleteFilePayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetByDatasetId?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType
  >;
  deletedFileId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  file?: Resolver<Maybe<ResolversTypes["File"]>, ParentType, ContextType>;
  fileBySeriesParentId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileEdge?: Resolver<
    Maybe<ResolversTypes["FilesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteFilePayloadFileEdgeArgs, "orderBy">
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteFileRecordingGroupMetadatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteFileRecordingGroupMetadatumPayload"] = ResolversParentTypes["DeleteFileRecordingGroupMetadatumPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedFileRecordingGroupMetadatumId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroupByFileRecordingGroupId?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroup"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroupMetadatum?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroupMetadatum"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroupMetadatumEdge?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroupMetadataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteFileRecordingGroupMetadatumPayloadFileRecordingGroupMetadatumEdgeArgs,
      "orderBy"
    >
  >;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteFileRecordingGroupPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteFileRecordingGroupPayload"] = ResolversParentTypes["DeleteFileRecordingGroupPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableByDatasetRecordingsTableId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTable"]>,
    ParentType,
    ContextType
  >;
  deletedFileRecordingGroupId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroup?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroup"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroupEdge?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroupsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteFileRecordingGroupPayloadFileRecordingGroupEdgeArgs,
      "orderBy"
    >
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteMetadataValuePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteMetadataValuePayload"] = ResolversParentTypes["DeleteMetadataValuePayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedMetadataValueId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  metadataValue?: Resolver<
    Maybe<ResolversTypes["MetadataValue"]>,
    ParentType,
    ContextType
  >;
  metadataValueEdge?: Resolver<
    Maybe<ResolversTypes["MetadataValuesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteMetadataValuePayloadMetadataValueEdgeArgs, "orderBy">
  >;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteMetadatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteMetadatumPayload"] = ResolversParentTypes["DeleteMetadatumPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedMetadatumId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  metadatum?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  metadatumEdge?: Resolver<
    Maybe<ResolversTypes["MetadataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteMetadatumPayloadMetadatumEdgeArgs, "orderBy">
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteOutputGroupFilePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteOutputGroupFilePayload"] = ResolversParentTypes["DeleteOutputGroupFilePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedOutputGroupFileId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroupByFileOutputGroupId?: Resolver<
    Maybe<ResolversTypes["FileOutputGroup"]>,
    ParentType,
    ContextType
  >;
  outputGroupFile?: Resolver<
    Maybe<ResolversTypes["OutputGroupFile"]>,
    ParentType,
    ContextType
  >;
  outputGroupFileEdge?: Resolver<
    Maybe<ResolversTypes["OutputGroupFilesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteOutputGroupFilePayloadOutputGroupFileEdgeArgs,
      "orderBy"
    >
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteRecordingGroupFilePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteRecordingGroupFilePayload"] = ResolversParentTypes["DeleteRecordingGroupFilePayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnByDatasetRecordingsTableColumnId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumn"]>,
    ParentType,
    ContextType
  >;
  deletedRecordingGroupFileId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroupByFileRecordingGroupId?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroup"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  recordingGroupFile?: Resolver<
    Maybe<ResolversTypes["RecordingGroupFile"]>,
    ParentType,
    ContextType
  >;
  recordingGroupFileEdge?: Resolver<
    Maybe<ResolversTypes["RecordingGroupFilesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteRecordingGroupFilePayloadRecordingGroupFileEdgeArgs,
      "orderBy"
    >
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteRunPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteRunPayload"] = ResolversParentTypes["DeleteRunPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedRunId?: Resolver<Maybe<ResolversTypes["ID"]>, ParentType, ContextType>;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  run?: Resolver<Maybe<ResolversTypes["Run"]>, ParentType, ContextType>;
  runEdge?: Resolver<
    Maybe<ResolversTypes["RunsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteRunPayloadRunEdgeArgs, "orderBy">
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteSessionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteSessionPayload"] = ResolversParentTypes["DeleteSessionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTagPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteTagPayload"] = ResolversParentTypes["DeleteTagPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedTagId?: Resolver<Maybe<ResolversTypes["ID"]>, ParentType, ContextType>;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tag?: Resolver<Maybe<ResolversTypes["Tag"]>, ParentType, ContextType>;
  tagByParentId?: Resolver<
    Maybe<ResolversTypes["Tag"]>,
    ParentType,
    ContextType
  >;
  tagEdge?: Resolver<
    Maybe<ResolversTypes["TagsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteTagPayloadTagEdgeArgs, "orderBy">
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTaskActivityPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteTaskActivityPayload"] = ResolversParentTypes["DeleteTaskActivityPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  costByCostId?: Resolver<
    Maybe<ResolversTypes["Cost"]>,
    ParentType,
    ContextType
  >;
  deletedTaskActivityId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskActivity?: Resolver<
    Maybe<ResolversTypes["TaskActivity"]>,
    ParentType,
    ContextType
  >;
  taskActivityEdge?: Resolver<
    Maybe<ResolversTypes["TaskActivitiesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteTaskActivityPayloadTaskActivityEdgeArgs, "orderBy">
  >;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTaskPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteTaskPayload"] = ResolversParentTypes["DeleteTaskPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  containerImageByContainerImageId?: Resolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType
  >;
  datasetByDatasetId?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType
  >;
  deletedTaskId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  task?: Resolver<Maybe<ResolversTypes["Task"]>, ParentType, ContextType>;
  taskEdge?: Resolver<
    Maybe<ResolversTypes["TasksEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteTaskPayloadTaskEdgeArgs, "orderBy">
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tesTaskexecutorByExecutorId?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutor"]>,
    ParentType,
    ContextType
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTaskResourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteTaskResourcePayload"] = ResolversParentTypes["DeleteTaskResourcePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedTaskResourceId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  taskResource?: Resolver<
    Maybe<ResolversTypes["TaskResource"]>,
    ParentType,
    ContextType
  >;
  taskResourceEdge?: Resolver<
    Maybe<ResolversTypes["TaskResourcesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteTaskResourcePayloadTaskResourceEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTaskSourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteTaskSourcePayload"] = ResolversParentTypes["DeleteTaskSourcePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedTaskSourceId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  taskSource?: Resolver<
    Maybe<ResolversTypes["TaskSource"]>,
    ParentType,
    ContextType
  >;
  taskSourceEdge?: Resolver<
    Maybe<ResolversTypes["TaskSourcesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteTaskSourcePayloadTaskSourceEdgeArgs, "orderBy">
  >;
  toolSourceByToolSourceId?: Resolver<
    Maybe<ResolversTypes["ToolSource"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTaskStatusLogPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteTaskStatusLogPayload"] = ResolversParentTypes["DeleteTaskStatusLogPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedTaskStatusLogId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  taskStatusLog?: Resolver<
    Maybe<ResolversTypes["TaskStatusLog"]>,
    ParentType,
    ContextType
  >;
  taskStatusLogEdge?: Resolver<
    Maybe<ResolversTypes["TaskStatusLogsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteTaskStatusLogPayloadTaskStatusLogEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTenantContractAllowancePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteTenantContractAllowancePayload"] = ResolversParentTypes["DeleteTenantContractAllowancePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedTenantContractAllowanceId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantContractAllowance?: Resolver<
    Maybe<ResolversTypes["TenantContractAllowance"]>,
    ParentType,
    ContextType
  >;
  tenantContractAllowanceEdge?: Resolver<
    Maybe<ResolversTypes["TenantContractAllowancesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteTenantContractAllowancePayloadTenantContractAllowanceEdgeArgs,
      "orderBy"
    >
  >;
  tenantContractTransactionByTenantContractTransactionId?: Resolver<
    Maybe<ResolversTypes["TenantContractTransaction"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTenantContractPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteTenantContractPayload"] = ResolversParentTypes["DeleteTenantContractPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedTenantContractId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantContract?: Resolver<
    Maybe<ResolversTypes["TenantContract"]>,
    ParentType,
    ContextType
  >;
  tenantContractEdge?: Resolver<
    Maybe<ResolversTypes["TenantContractsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteTenantContractPayloadTenantContractEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTenantContractTransactionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteTenantContractTransactionPayload"] = ResolversParentTypes["DeleteTenantContractTransactionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedTenantContractTransactionId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantContractByTenantContractId?: Resolver<
    Maybe<ResolversTypes["TenantContract"]>,
    ParentType,
    ContextType
  >;
  tenantContractTransaction?: Resolver<
    Maybe<ResolversTypes["TenantContractTransaction"]>,
    ParentType,
    ContextType
  >;
  tenantContractTransactionEdge?: Resolver<
    Maybe<ResolversTypes["TenantContractTransactionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteTenantContractTransactionPayloadTenantContractTransactionEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTenantMockuserexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteTenantMockuserexecutorPayload"] = ResolversParentTypes["DeleteTenantMockuserexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedTenantMockuserexecutorId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantMockuserexecutor?: Resolver<
    Maybe<ResolversTypes["TenantMockuserexecutor"]>,
    ParentType,
    ContextType
  >;
  tenantMockuserexecutorEdge?: Resolver<
    Maybe<ResolversTypes["TenantMockuserexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteTenantMockuserexecutorPayloadTenantMockuserexecutorEdgeArgs,
      "orderBy"
    >
  >;
  tenantUserexecutorByUserexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TenantUserexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTenantMonthlyConsumedResourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteTenantMonthlyConsumedResourcePayload"] = ResolversParentTypes["DeleteTenantMonthlyConsumedResourcePayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedTenantMonthlyConsumedResourceId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantContractByTenantContractId?: Resolver<
    Maybe<ResolversTypes["TenantContract"]>,
    ParentType,
    ContextType
  >;
  tenantMonthlyConsumedResource?: Resolver<
    Maybe<ResolversTypes["TenantMonthlyConsumedResource"]>,
    ParentType,
    ContextType
  >;
  tenantMonthlyConsumedResourceEdge?: Resolver<
    Maybe<ResolversTypes["TenantMonthlyConsumedResourcesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteTenantMonthlyConsumedResourcePayloadTenantMonthlyConsumedResourceEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTenantPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteTenantPayload"] = ResolversParentTypes["DeleteTenantPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedTenantId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenant?: Resolver<Maybe<ResolversTypes["Tenant"]>, ParentType, ContextType>;
  tenantEdge?: Resolver<
    Maybe<ResolversTypes["TenantsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteTenantPayloadTenantEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTenantUserexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteTenantUserexecutorPayload"] = ResolversParentTypes["DeleteTenantUserexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedTenantUserexecutorId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeByPolymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantUserexecutor?: Resolver<
    Maybe<ResolversTypes["TenantUserexecutor"]>,
    ParentType,
    ContextType
  >;
  tenantUserexecutorEdge?: Resolver<
    Maybe<ResolversTypes["TenantUserexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteTenantUserexecutorPayloadTenantUserexecutorEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTesContainerimageexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteTesContainerimageexecutorPayload"] = ResolversParentTypes["DeleteTesContainerimageexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedTesContainerimageexecutorId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeByPolymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesContainerimageexecutor?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  tesContainerimageexecutorEdge?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteTesContainerimageexecutorPayloadTesContainerimageexecutorEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTesMockcontainerimageexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteTesMockcontainerimageexecutorPayload"] = ResolversParentTypes["DeleteTesMockcontainerimageexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedTesMockcontainerimageexecutorId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesContainerimageexecutorByContainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  tesMockcontainerimageexecutor?: Resolver<
    Maybe<ResolversTypes["TesMockcontainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  tesMockcontainerimageexecutorEdge?: Resolver<
    Maybe<ResolversTypes["TesMockcontainerimageexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteTesMockcontainerimageexecutorPayloadTesMockcontainerimageexecutorEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTesMocktaskexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteTesMocktaskexecutorPayload"] = ResolversParentTypes["DeleteTesMocktaskexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedTesMocktaskexecutorId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesMocktaskexecutor?: Resolver<
    Maybe<ResolversTypes["TesMocktaskexecutor"]>,
    ParentType,
    ContextType
  >;
  tesMocktaskexecutorEdge?: Resolver<
    Maybe<ResolversTypes["TesMocktaskexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteTesMocktaskexecutorPayloadTesMocktaskexecutorEdgeArgs,
      "orderBy"
    >
  >;
  tesTaskexecutorByTaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTesTaskexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteTesTaskexecutorPayload"] = ResolversParentTypes["DeleteTesTaskexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedTesTaskexecutorId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeByPolymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesTaskexecutor?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutor"]>,
    ParentType,
    ContextType
  >;
  tesTaskexecutorEdge?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteTesTaskexecutorPayloadTesTaskexecutorEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteToolCategoryPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteToolCategoryPayload"] = ResolversParentTypes["DeleteToolCategoryPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedToolCategoryId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolCategory?: Resolver<
    Maybe<ResolversTypes["ToolCategory"]>,
    ParentType,
    ContextType
  >;
  toolCategoryEdge?: Resolver<
    Maybe<ResolversTypes["ToolCategoriesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteToolCategoryPayloadToolCategoryEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteToolDisplayCategoryPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteToolDisplayCategoryPayload"] = ResolversParentTypes["DeleteToolDisplayCategoryPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedToolDisplayCategoryId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolByToolId?: Resolver<
    Maybe<ResolversTypes["Tool"]>,
    ParentType,
    ContextType
  >;
  toolCategoryByToolcategoryId?: Resolver<
    Maybe<ResolversTypes["ToolCategory"]>,
    ParentType,
    ContextType
  >;
  toolDisplayCategory?: Resolver<
    Maybe<ResolversTypes["ToolDisplayCategory"]>,
    ParentType,
    ContextType
  >;
  toolDisplayCategoryEdge?: Resolver<
    Maybe<ResolversTypes["ToolDisplayCategoriesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteToolDisplayCategoryPayloadToolDisplayCategoryEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteToolParameterPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteToolParameterPayload"] = ResolversParentTypes["DeleteToolParameterPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedToolParameterId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolParameter?: Resolver<
    Maybe<ResolversTypes["ToolParameter"]>,
    ParentType,
    ContextType
  >;
  toolParameterEdge?: Resolver<
    Maybe<ResolversTypes["ToolParametersEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteToolParameterPayloadToolParameterEdgeArgs, "orderBy">
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteToolPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteToolPayload"] = ResolversParentTypes["DeleteToolPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedToolId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tool?: Resolver<Maybe<ResolversTypes["Tool"]>, ParentType, ContextType>;
  toolEdge?: Resolver<
    Maybe<ResolversTypes["ToolsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteToolPayloadToolEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteToolResourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteToolResourcePayload"] = ResolversParentTypes["DeleteToolResourcePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedToolResourceId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolResource?: Resolver<
    Maybe<ResolversTypes["ToolResource"]>,
    ParentType,
    ContextType
  >;
  toolResourceEdge?: Resolver<
    Maybe<ResolversTypes["ToolResourcesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteToolResourcePayloadToolResourceEdgeArgs, "orderBy">
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteToolResultPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteToolResultPayload"] = ResolversParentTypes["DeleteToolResultPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedToolResultId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolResult?: Resolver<
    Maybe<ResolversTypes["ToolResult"]>,
    ParentType,
    ContextType
  >;
  toolResultEdge?: Resolver<
    Maybe<ResolversTypes["ToolResultsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteToolResultPayloadToolResultEdgeArgs, "orderBy">
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteToolSecretPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteToolSecretPayload"] = ResolversParentTypes["DeleteToolSecretPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedToolSecretId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolSecret?: Resolver<
    Maybe<ResolversTypes["ToolSecret"]>,
    ParentType,
    ContextType
  >;
  toolSecretEdge?: Resolver<
    Maybe<ResolversTypes["ToolSecretsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteToolSecretPayloadToolSecretEdgeArgs, "orderBy">
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteToolSourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteToolSourcePayload"] = ResolversParentTypes["DeleteToolSourcePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedToolSourceId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolSource?: Resolver<
    Maybe<ResolversTypes["ToolSource"]>,
    ParentType,
    ContextType
  >;
  toolSourceEdge?: Resolver<
    Maybe<ResolversTypes["ToolSourcesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteToolSourcePayloadToolSourceEdgeArgs, "orderBy">
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteToolTenantPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteToolTenantPayload"] = ResolversParentTypes["DeleteToolTenantPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedToolTenantId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  toolByToolId?: Resolver<
    Maybe<ResolversTypes["Tool"]>,
    ParentType,
    ContextType
  >;
  toolTenant?: Resolver<
    Maybe<ResolversTypes["ToolTenant"]>,
    ParentType,
    ContextType
  >;
  toolTenantEdge?: Resolver<
    Maybe<ResolversTypes["ToolTenantsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteToolTenantPayloadToolTenantEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteToolVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteToolVersionPayload"] = ResolversParentTypes["DeleteToolVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedToolVersionId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolByToolId?: Resolver<
    Maybe<ResolversTypes["Tool"]>,
    ParentType,
    ContextType
  >;
  toolVersion?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  toolVersionEdge?: Resolver<
    Maybe<ResolversTypes["ToolVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteToolVersionPayloadToolVersionEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteToolboxExitcodePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteToolboxExitcodePayload"] = ResolversParentTypes["DeleteToolboxExitcodePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedToolboxExitcodeId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolboxExitcode?: Resolver<
    Maybe<ResolversTypes["ToolboxExitcode"]>,
    ParentType,
    ContextType
  >;
  toolboxExitcodeEdge?: Resolver<
    Maybe<ResolversTypes["ToolboxExitcodesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteToolboxExitcodePayloadToolboxExitcodeEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteToolboxPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteToolboxPayload"] = ResolversParentTypes["DeleteToolboxPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedToolboxId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolbox?: Resolver<Maybe<ResolversTypes["Toolbox"]>, ParentType, ContextType>;
  toolboxEdge?: Resolver<
    Maybe<ResolversTypes["ToolboxesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteToolboxPayloadToolboxEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteToolboxTenantPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteToolboxTenantPayload"] = ResolversParentTypes["DeleteToolboxTenantPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedToolboxTenantId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  toolboxByToolboxId?: Resolver<
    Maybe<ResolversTypes["Toolbox"]>,
    ParentType,
    ContextType
  >;
  toolboxTenant?: Resolver<
    Maybe<ResolversTypes["ToolboxTenant"]>,
    ParentType,
    ContextType
  >;
  toolboxTenantEdge?: Resolver<
    Maybe<ResolversTypes["ToolboxTenantsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteToolboxTenantPayloadToolboxTenantEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteToolboxVersionExitcodePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteToolboxVersionExitcodePayload"] = ResolversParentTypes["DeleteToolboxVersionExitcodePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedToolboxVersionExitcodeId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolboxExitcodeByToolboxexitcodeId?: Resolver<
    Maybe<ResolversTypes["ToolboxExitcode"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionByToolboxversionId?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionExitcode?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionExitcode"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionExitcodeEdge?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionExitcodesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteToolboxVersionExitcodePayloadToolboxVersionExitcodeEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteToolboxVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteToolboxVersionPayload"] = ResolversParentTypes["DeleteToolboxVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedToolboxVersionId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolboxByToolboxId?: Resolver<
    Maybe<ResolversTypes["Toolbox"]>,
    ParentType,
    ContextType
  >;
  toolboxVersion?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionEdge?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteToolboxVersionPayloadToolboxVersionEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteToolboxVersionToolVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteToolboxVersionToolVersionPayload"] = ResolversParentTypes["DeleteToolboxVersionToolVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedToolboxVersionToolVersionId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolVersionByToolversionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionByToolboxversionId?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionToolVersion?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionToolVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionToolVersionEdge?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionToolVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteToolboxVersionToolVersionPayloadToolboxVersionToolVersionEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteWorkflowDatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteWorkflowDatumPayload"] = ResolversParentTypes["DeleteWorkflowDatumPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedWorkflowDatumId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowDatum?: Resolver<
    Maybe<ResolversTypes["WorkflowDatum"]>,
    ParentType,
    ContextType
  >;
  workflowDatumEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowDataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteWorkflowDatumPayloadWorkflowDatumEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteWorkflowPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteWorkflowPayload"] = ResolversParentTypes["DeleteWorkflowPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  containerImageByContainerId?: Resolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType
  >;
  deletedWorkflowId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  workflow?: Resolver<
    Maybe<ResolversTypes["Workflow"]>,
    ParentType,
    ContextType
  >;
  workflowEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteWorkflowPayloadWorkflowEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteWorkflowResultPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteWorkflowResultPayload"] = ResolversParentTypes["DeleteWorkflowResultPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedWorkflowResultId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowDatumByObjectId?: Resolver<
    Maybe<ResolversTypes["WorkflowDatum"]>,
    ParentType,
    ContextType
  >;
  workflowResult?: Resolver<
    Maybe<ResolversTypes["WorkflowResult"]>,
    ParentType,
    ContextType
  >;
  workflowResultEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowResultsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteWorkflowResultPayloadWorkflowResultEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteWorkflowResultTagPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteWorkflowResultTagPayload"] = ResolversParentTypes["DeleteWorkflowResultTagPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedWorkflowResultTagId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tagByTagId?: Resolver<Maybe<ResolversTypes["Tag"]>, ParentType, ContextType>;
  workflowResultByResultId?: Resolver<
    Maybe<ResolversTypes["WorkflowResult"]>,
    ParentType,
    ContextType
  >;
  workflowResultTag?: Resolver<
    Maybe<ResolversTypes["WorkflowResultTag"]>,
    ParentType,
    ContextType
  >;
  workflowResultTagEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowResultTagsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteWorkflowResultTagPayloadWorkflowResultTagEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteWorkflowResultWorkflowVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteWorkflowResultWorkflowVersionPayload"] = ResolversParentTypes["DeleteWorkflowResultWorkflowVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedWorkflowResultWorkflowVersionId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowResultByResultId?: Resolver<
    Maybe<ResolversTypes["WorkflowResult"]>,
    ParentType,
    ContextType
  >;
  workflowResultWorkflowVersion?: Resolver<
    Maybe<ResolversTypes["WorkflowResultWorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  workflowResultWorkflowVersionEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowResultWorkflowVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteWorkflowResultWorkflowVersionPayloadWorkflowResultWorkflowVersionEdgeArgs,
      "orderBy"
    >
  >;
  workflowVersionByWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["WorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteWorkflowSectionBlockPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteWorkflowSectionBlockPayload"] = ResolversParentTypes["DeleteWorkflowSectionBlockPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedWorkflowSectionBlockId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolVersionByToolId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  workflowDatumByObjectId?: Resolver<
    Maybe<ResolversTypes["WorkflowDatum"]>,
    ParentType,
    ContextType
  >;
  workflowSectionBlock?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlock"]>,
    ParentType,
    ContextType
  >;
  workflowSectionBlockEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlocksEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteWorkflowSectionBlockPayloadWorkflowSectionBlockEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteWorkflowSectionBlockSectionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteWorkflowSectionBlockSectionPayload"] = ResolversParentTypes["DeleteWorkflowSectionBlockSectionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedWorkflowSectionBlockSectionId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowSectionBlockByBlockId?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlock"]>,
    ParentType,
    ContextType
  >;
  workflowSectionBlockSection?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlockSection"]>,
    ParentType,
    ContextType
  >;
  workflowSectionBlockSectionEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlockSectionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteWorkflowSectionBlockSectionPayloadWorkflowSectionBlockSectionEdgeArgs,
      "orderBy"
    >
  >;
  workflowSectionBySectionId?: Resolver<
    Maybe<ResolversTypes["WorkflowSection"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteWorkflowSectionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteWorkflowSectionPayload"] = ResolversParentTypes["DeleteWorkflowSectionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedWorkflowSectionId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowSection?: Resolver<
    Maybe<ResolversTypes["WorkflowSection"]>,
    ParentType,
    ContextType
  >;
  workflowSectionEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteWorkflowSectionPayloadWorkflowSectionEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteWorkflowSectionWorkflowVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteWorkflowSectionWorkflowVersionPayload"] = ResolversParentTypes["DeleteWorkflowSectionWorkflowVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedWorkflowSectionWorkflowVersionId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowSectionBySectionId?: Resolver<
    Maybe<ResolversTypes["WorkflowSection"]>,
    ParentType,
    ContextType
  >;
  workflowSectionWorkflowVersion?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionWorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  workflowSectionWorkflowVersionEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionWorkflowVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteWorkflowSectionWorkflowVersionPayloadWorkflowSectionWorkflowVersionEdgeArgs,
      "orderBy"
    >
  >;
  workflowVersionByWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["WorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteWorkflowSourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteWorkflowSourcePayload"] = ResolversParentTypes["DeleteWorkflowSourcePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedWorkflowSourceId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowSource?: Resolver<
    Maybe<ResolversTypes["WorkflowSource"]>,
    ParentType,
    ContextType
  >;
  workflowSourceEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSourcesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<DeleteWorkflowSourcePayloadWorkflowSourceEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteWorkflowSourceTagPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteWorkflowSourceTagPayload"] = ResolversParentTypes["DeleteWorkflowSourceTagPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedWorkflowSourceTagId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tagByTagId?: Resolver<Maybe<ResolversTypes["Tag"]>, ParentType, ContextType>;
  workflowSourceBySourceId?: Resolver<
    Maybe<ResolversTypes["WorkflowSource"]>,
    ParentType,
    ContextType
  >;
  workflowSourceTag?: Resolver<
    Maybe<ResolversTypes["WorkflowSourceTag"]>,
    ParentType,
    ContextType
  >;
  workflowSourceTagEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSourceTagsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteWorkflowSourceTagPayloadWorkflowSourceTagEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteWorkflowSourceWorkflowVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteWorkflowSourceWorkflowVersionPayload"] = ResolversParentTypes["DeleteWorkflowSourceWorkflowVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedWorkflowSourceWorkflowVersionId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowSourceBySourceId?: Resolver<
    Maybe<ResolversTypes["WorkflowSource"]>,
    ParentType,
    ContextType
  >;
  workflowSourceWorkflowVersion?: Resolver<
    Maybe<ResolversTypes["WorkflowSourceWorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  workflowSourceWorkflowVersionEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSourceWorkflowVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteWorkflowSourceWorkflowVersionPayloadWorkflowSourceWorkflowVersionEdgeArgs,
      "orderBy"
    >
  >;
  workflowVersionByWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["WorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteWorkflowVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DeleteWorkflowVersionPayload"] = ResolversParentTypes["DeleteWorkflowVersionPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  deletedWorkflowVersionId?: Resolver<
    Maybe<ResolversTypes["ID"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowByWorkflowId?: Resolver<
    Maybe<ResolversTypes["Workflow"]>,
    ParentType,
    ContextType
  >;
  workflowVersion?: Resolver<
    Maybe<ResolversTypes["WorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  workflowVersionEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      DeleteWorkflowVersionPayloadWorkflowVersionEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DjangoAdminLogResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DjangoAdminLog"] = ResolversParentTypes["DjangoAdminLog"],
> = {
  actionFlag?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  actionTime?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  changeMessage?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  contentTypeId?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeByContentTypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  objectId?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  objectRepr?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DjangoAdminLogsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DjangoAdminLogsConnection"] = ResolversParentTypes["DjangoAdminLogsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["DjangoAdminLogsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["DjangoAdminLog"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DjangoAdminLogsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DjangoAdminLogsEdge"] = ResolversParentTypes["DjangoAdminLogsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["DjangoAdminLog"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DjangoContentTypeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DjangoContentType"] = ResolversParentTypes["DjangoContentType"],
> = {
  appLabel?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  authPermissionsByContentTypeId?: Resolver<
    ResolversTypes["AuthPermissionsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      DjangoContentTypeAuthPermissionsByContentTypeIdArgs,
      "orderBy"
    >
  >;
  djangoAdminLogsByContentTypeId?: Resolver<
    ResolversTypes["DjangoAdminLogsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      DjangoContentTypeDjangoAdminLogsByContentTypeIdArgs,
      "orderBy"
    >
  >;
  drsFileexecutorsByPolymorphicCtypeId?: Resolver<
    ResolversTypes["DrsFileexecutorsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      DjangoContentTypeDrsFileexecutorsByPolymorphicCtypeIdArgs,
      "orderBy"
    >
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  model?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  tenantUserexecutorsByPolymorphicCtypeId?: Resolver<
    ResolversTypes["TenantUserexecutorsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      DjangoContentTypeTenantUserexecutorsByPolymorphicCtypeIdArgs,
      "orderBy"
    >
  >;
  tesContainerimageexecutorsByPolymorphicCtypeId?: Resolver<
    ResolversTypes["TesContainerimageexecutorsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      DjangoContentTypeTesContainerimageexecutorsByPolymorphicCtypeIdArgs,
      "orderBy"
    >
  >;
  tesTaskexecutorsByPolymorphicCtypeId?: Resolver<
    ResolversTypes["TesTaskexecutorsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      DjangoContentTypeTesTaskexecutorsByPolymorphicCtypeIdArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DjangoContentTypesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DjangoContentTypesConnection"] = ResolversParentTypes["DjangoContentTypesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["DjangoContentTypesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DjangoContentTypesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DjangoContentTypesEdge"] = ResolversParentTypes["DjangoContentTypesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["DjangoContentType"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DjangoMigrationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DjangoMigration"] = ResolversParentTypes["DjangoMigration"],
> = {
  app?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  applied?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DjangoMigrationsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DjangoMigrationsConnection"] = ResolversParentTypes["DjangoMigrationsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["DjangoMigrationsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["DjangoMigration"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DjangoMigrationsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DjangoMigrationsEdge"] = ResolversParentTypes["DjangoMigrationsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["DjangoMigration"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DjangoSesSesstatResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DjangoSesSesstat"] = ResolversParentTypes["DjangoSesSesstat"],
> = {
  bounces?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  complaints?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  date?: Resolver<ResolversTypes["Date"], ParentType, ContextType>;
  deliveryAttempts?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  rejects?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DjangoSesSesstatsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DjangoSesSesstatsConnection"] = ResolversParentTypes["DjangoSesSesstatsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["DjangoSesSesstatsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["DjangoSesSesstat"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DjangoSesSesstatsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DjangoSesSesstatsEdge"] = ResolversParentTypes["DjangoSesSesstatsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["DjangoSesSesstat"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DjangoSessionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DjangoSession"] = ResolversParentTypes["DjangoSession"],
> = {
  expireDate?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  sessionData?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  sessionKey?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DjangoSessionsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DjangoSessionsConnection"] = ResolversParentTypes["DjangoSessionsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["DjangoSessionsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["DjangoSession"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DjangoSessionsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DjangoSessionsEdge"] = ResolversParentTypes["DjangoSessionsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["DjangoSession"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrsFileexecutorResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DrsFileexecutor"] = ResolversParentTypes["DrsFileexecutor"],
> = {
  awsS3FileByFileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsS3File"]>,
    ParentType,
    ContextType
  >;
  awsS3FilesByFileexecutorPtrId?: Resolver<
    ResolversTypes["AwsS3FilesConnection"],
    ParentType,
    ContextType,
    RequireFields<DrsFileexecutorAwsS3FilesByFileexecutorPtrIdArgs, "orderBy">
  >;
  djangoContentTypeByPolymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  drsMockfileexecutorByFileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DrsMockfileexecutor"]>,
    ParentType,
    ContextType
  >;
  drsMockfileexecutorsByFileexecutorPtrId?: Resolver<
    ResolversTypes["DrsMockfileexecutorsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      DrsFileexecutorDrsMockfileexecutorsByFileexecutorPtrIdArgs,
      "orderBy"
    >
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  polymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrsFileexecutorsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DrsFileexecutorsConnection"] = ResolversParentTypes["DrsFileexecutorsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["DrsFileexecutorsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["DrsFileexecutor"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrsFileexecutorsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DrsFileexecutorsEdge"] = ResolversParentTypes["DrsFileexecutorsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["DrsFileexecutor"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrsMockfileexecutorResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DrsMockfileexecutor"] = ResolversParentTypes["DrsMockfileexecutor"],
> = {
  drsFileexecutorByFileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DrsFileexecutor"]>,
    ParentType,
    ContextType
  >;
  fileexecutorPtrId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrsMockfileexecutorsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DrsMockfileexecutorsConnection"] = ResolversParentTypes["DrsMockfileexecutorsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["DrsMockfileexecutorsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["DrsMockfileexecutor"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrsMockfileexecutorsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["DrsMockfileexecutorsEdge"] = ResolversParentTypes["DrsMockfileexecutorsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["DrsMockfileexecutor"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EulaVersionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["EulaVersion"] = ResolversParentTypes["EulaVersion"],
> = {
  content?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  dateVersion?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  eulaVersionUserAcceptancesByEulaVersionId?: Resolver<
    ResolversTypes["EulaVersionUserAcceptancesConnection"],
    ParentType,
    ContextType,
    RequireFields<
      EulaVersionEulaVersionUserAcceptancesByEulaVersionIdArgs,
      "orderBy"
    >
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  version?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EulaVersionUserAcceptanceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["EulaVersionUserAcceptance"] = ResolversParentTypes["EulaVersionUserAcceptance"],
> = {
  accepted?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  dateReviewed?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  eulaVersionByEulaVersionId?: Resolver<
    Maybe<ResolversTypes["EulaVersion"]>,
    ParentType,
    ContextType
  >;
  eulaVersionId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EulaVersionUserAcceptancesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["EulaVersionUserAcceptancesConnection"] = ResolversParentTypes["EulaVersionUserAcceptancesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["EulaVersionUserAcceptancesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["EulaVersionUserAcceptance"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EulaVersionUserAcceptancesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["EulaVersionUserAcceptancesEdge"] = ResolversParentTypes["EulaVersionUserAcceptancesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["EulaVersionUserAcceptance"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EulaVersionsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["EulaVersionsConnection"] = ResolversParentTypes["EulaVersionsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["EulaVersionsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["EulaVersion"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EulaVersionsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["EulaVersionsEdge"] = ResolversParentTypes["EulaVersionsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["EulaVersion"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExplorerQueriesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ExplorerQueriesConnection"] = ResolversParentTypes["ExplorerQueriesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ExplorerQueriesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ExplorerQuery"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExplorerQueriesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ExplorerQueriesEdge"] = ResolversParentTypes["ExplorerQueriesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["ExplorerQuery"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExplorerQueryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ExplorerQuery"] = ResolversParentTypes["ExplorerQuery"],
> = {
  applicationUserByCreatedByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  connection?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  createdByUserId?: Resolver<
    Maybe<ResolversTypes["UUID"]>,
    ParentType,
    ContextType
  >;
  description?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  explorerQueryfavoritesByQueryId?: Resolver<
    ResolversTypes["ExplorerQueryfavoritesConnection"],
    ParentType,
    ContextType,
    RequireFields<ExplorerQueryExplorerQueryfavoritesByQueryIdArgs, "orderBy">
  >;
  explorerQuerylogsByQueryId?: Resolver<
    ResolversTypes["ExplorerQuerylogsConnection"],
    ParentType,
    ContextType,
    RequireFields<ExplorerQueryExplorerQuerylogsByQueryIdArgs, "orderBy">
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  lastRunDate?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  snapshot?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  sql?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  title?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExplorerQueryfavoriteResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ExplorerQueryfavorite"] = ResolversParentTypes["ExplorerQueryfavorite"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  explorerQueryByQueryId?: Resolver<
    Maybe<ResolversTypes["ExplorerQuery"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  queryId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExplorerQueryfavoritesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ExplorerQueryfavoritesConnection"] = ResolversParentTypes["ExplorerQueryfavoritesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ExplorerQueryfavoritesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ExplorerQueryfavorite"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExplorerQueryfavoritesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ExplorerQueryfavoritesEdge"] = ResolversParentTypes["ExplorerQueryfavoritesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["ExplorerQueryfavorite"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExplorerQuerylogResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ExplorerQuerylog"] = ResolversParentTypes["ExplorerQuerylog"],
> = {
  applicationUserByRunByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  connection?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  explorerQueryByQueryId?: Resolver<
    Maybe<ResolversTypes["ExplorerQuery"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  queryId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  runAt?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  runByUserId?: Resolver<
    Maybe<ResolversTypes["UUID"]>,
    ParentType,
    ContextType
  >;
  sql?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExplorerQuerylogsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ExplorerQuerylogsConnection"] = ResolversParentTypes["ExplorerQuerylogsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ExplorerQuerylogsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ExplorerQuerylog"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExplorerQuerylogsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ExplorerQuerylogsEdge"] = ResolversParentTypes["ExplorerQuerylogsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["ExplorerQuerylog"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["File"] = ResolversParentTypes["File"],
> = {
  activeAssignment?: Resolver<
    Maybe<ResolversTypes["RecordingGroupFile"]>,
    ParentType,
    ContextType,
    Partial<FileActiveAssignmentArgs>
  >;
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  datasetByDatasetId?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType
  >;
  datasetId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  dateArchived?: Resolver<
    Maybe<ResolversTypes["Datetime"]>,
    ParentType,
    ContextType
  >;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  dateDataDeleted?: Resolver<
    Maybe<ResolversTypes["Datetime"]>,
    ParentType,
    ContextType
  >;
  dateDeleted?: Resolver<
    Maybe<ResolversTypes["Datetime"]>,
    ParentType,
    ContextType
  >;
  fileActivitiesByFileId?: Resolver<
    ResolversTypes["FileActivitiesConnection"],
    ParentType,
    ContextType,
    RequireFields<FileFileActivitiesByFileIdArgs, "orderBy">
  >;
  fileBySeriesParentId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileFormat?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  fileMetadataByFileId?: Resolver<
    ResolversTypes["FileMetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<FileFileMetadataByFileIdArgs, "orderBy">
  >;
  fileProcessingNotificationsByFileId?: Resolver<
    ResolversTypes["FileProcessingNotificationsConnection"],
    ParentType,
    ContextType,
    RequireFields<FileFileProcessingNotificationsByFileIdArgs, "orderBy">
  >;
  fileStructure?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  fileType?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  filesBySeriesParentId?: Resolver<
    ResolversTypes["FilesConnection"],
    ParentType,
    ContextType,
    RequireFields<FileFilesBySeriesParentIdArgs, "orderBy">
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  isSeries?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  multipartUploadId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  originalName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  outputGroupFilesByFileId?: Resolver<
    ResolversTypes["OutputGroupFilesConnection"],
    ParentType,
    ContextType,
    RequireFields<FileOutputGroupFilesByFileIdArgs, "orderBy">
  >;
  parentIds?: Resolver<Maybe<ResolversTypes["JSON"]>, ParentType, ContextType>;
  partSize?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  preview?: Resolver<Maybe<ResolversTypes["JSON"]>, ParentType, ContextType>;
  processingStatus?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  recordingGroupFilesByFileId?: Resolver<
    ResolversTypes["RecordingGroupFilesConnection"],
    ParentType,
    ContextType,
    RequireFields<FileRecordingGroupFilesByFileIdArgs, "orderBy">
  >;
  seriesOrder?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  seriesParentId?: Resolver<
    Maybe<ResolversTypes["UUID"]>,
    ParentType,
    ContextType
  >;
  signal?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes["BigInt"]>, ParentType, ContextType>;
  source?: Resolver<
    Maybe<ResolversTypes["FileSource"]>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  taskSourcesByFileId?: Resolver<
    ResolversTypes["TaskSourcesConnection"],
    ParentType,
    ContextType,
    RequireFields<FileTaskSourcesByFileIdArgs, "orderBy">
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  uploadStatus?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileActivitiesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileActivitiesConnection"] = ResolversParentTypes["FileActivitiesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["FileActivitiesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["FileActivity"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileActivitiesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileActivitiesEdge"] = ResolversParentTypes["FileActivitiesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["FileActivity"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileActivityResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileActivity"] = ResolversParentTypes["FileActivity"],
> = {
  activityType?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  bytes?: Resolver<Maybe<ResolversTypes["BigInt"]>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  costByCostId?: Resolver<
    Maybe<ResolversTypes["Cost"]>,
    ParentType,
    ContextType
  >;
  costId?: Resolver<Maybe<ResolversTypes["BigInt"]>, ParentType, ContextType>;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileMetadataConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileMetadataConnection"] = ResolversParentTypes["FileMetadataConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["FileMetadataEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["FileMetadatum"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileMetadataEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileMetadataEdge"] = ResolversParentTypes["FileMetadataEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["FileMetadatum"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileMetadatumResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileMetadatum"] = ResolversParentTypes["FileMetadatum"],
> = {
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  metadataId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileOutputGroupResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileOutputGroup"] = ResolversParentTypes["FileOutputGroup"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  fileOutputGroupMetadataByFileOutputGroupId?: Resolver<
    ResolversTypes["FileOutputGroupMetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<
      FileOutputGroupFileOutputGroupMetadataByFileOutputGroupIdArgs,
      "orderBy"
    >
  >;
  groupType?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  outputGroupFilesByFileOutputGroupId?: Resolver<
    ResolversTypes["OutputGroupFilesConnection"],
    ParentType,
    ContextType,
    RequireFields<
      FileOutputGroupOutputGroupFilesByFileOutputGroupIdArgs,
      "orderBy"
    >
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  taskId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileOutputGroupMetadataConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileOutputGroupMetadataConnection"] = ResolversParentTypes["FileOutputGroupMetadataConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["FileOutputGroupMetadataEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["FileOutputGroupMetadatum"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileOutputGroupMetadataEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileOutputGroupMetadataEdge"] = ResolversParentTypes["FileOutputGroupMetadataEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["FileOutputGroupMetadatum"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileOutputGroupMetadatumResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileOutputGroupMetadatum"] = ResolversParentTypes["FileOutputGroupMetadatum"],
> = {
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  fileOutputGroupByFileOutputGroupId?: Resolver<
    Maybe<ResolversTypes["FileOutputGroup"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroupId?: Resolver<
    Maybe<ResolversTypes["UUID"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  metadataId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileOutputGroupsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileOutputGroupsConnection"] = ResolversParentTypes["FileOutputGroupsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["FileOutputGroupsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["FileOutputGroup"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileOutputGroupsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileOutputGroupsEdge"] = ResolversParentTypes["FileOutputGroupsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["FileOutputGroup"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileProcessingNotificationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileProcessingNotification"] = ResolversParentTypes["FileProcessingNotification"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  notificationById?: Resolver<
    Maybe<ResolversTypes["Notification"]>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileProcessingNotificationsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileProcessingNotificationsConnection"] = ResolversParentTypes["FileProcessingNotificationsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["FileProcessingNotificationsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["FileProcessingNotification"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileProcessingNotificationsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileProcessingNotificationsEdge"] = ResolversParentTypes["FileProcessingNotificationsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["FileProcessingNotification"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileRecordingGroupResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileRecordingGroup"] = ResolversParentTypes["FileRecordingGroup"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableByDatasetRecordingsTableId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTable"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableId?: Resolver<
    ResolversTypes["UUID"],
    ParentType,
    ContextType
  >;
  dateAssignmentEnded?: Resolver<
    Maybe<ResolversTypes["Datetime"]>,
    ParentType,
    ContextType
  >;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  fileRecordingGroupMetadataByFileRecordingGroupId?: Resolver<
    ResolversTypes["FileRecordingGroupMetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<
      FileRecordingGroupFileRecordingGroupMetadataByFileRecordingGroupIdArgs,
      "orderBy"
    >
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes["BigInt"]>, ParentType, ContextType>;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  recordingGroupFilesByFileRecordingGroupId?: Resolver<
    ResolversTypes["RecordingGroupFilesConnection"],
    ParentType,
    ContextType,
    RequireFields<
      FileRecordingGroupRecordingGroupFilesByFileRecordingGroupIdArgs,
      "orderBy"
    >
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileRecordingGroupMetadataConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileRecordingGroupMetadataConnection"] = ResolversParentTypes["FileRecordingGroupMetadataConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["FileRecordingGroupMetadataEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["FileRecordingGroupMetadatum"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileRecordingGroupMetadataEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileRecordingGroupMetadataEdge"] = ResolversParentTypes["FileRecordingGroupMetadataEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["FileRecordingGroupMetadatum"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileRecordingGroupMetadatumResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileRecordingGroupMetadatum"] = ResolversParentTypes["FileRecordingGroupMetadatum"],
> = {
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  fileRecordingGroupByFileRecordingGroupId?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroup"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroupId?: Resolver<
    Maybe<ResolversTypes["UUID"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  metadataId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileRecordingGroupsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileRecordingGroupsConnection"] = ResolversParentTypes["FileRecordingGroupsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["FileRecordingGroupsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["FileRecordingGroup"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileRecordingGroupsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FileRecordingGroupsEdge"] = ResolversParentTypes["FileRecordingGroupsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["FileRecordingGroup"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FilesConnection"] = ResolversParentTypes["FilesConnection"],
> = {
  edges?: Resolver<Array<ResolversTypes["FilesEdge"]>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes["File"]>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["FilesEdge"] = ResolversParentTypes["FilesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["File"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntervalResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["Interval"] = ResolversParentTypes["Interval"],
> = {
  days?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  hours?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  minutes?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  months?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  seconds?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  years?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes["JSON"], any> {
  name: "JSON";
}

export type MetadataConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["MetadataConnection"] = ResolversParentTypes["MetadataConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["MetadataEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes["Metadatum"]>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetadataEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["MetadataEdge"] = ResolversParentTypes["MetadataEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["Metadatum"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetadataValueResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["MetadataValue"] = ResolversParentTypes["MetadataValue"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  metadataId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  taskId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes["JSON"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetadataValuesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["MetadataValuesConnection"] = ResolversParentTypes["MetadataValuesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["MetadataValuesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["MetadataValue"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetadataValuesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["MetadataValuesEdge"] = ResolversParentTypes["MetadataValuesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["MetadataValue"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetadatumResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["Metadatum"] = ResolversParentTypes["Metadatum"],
> = {
  activeValue?: Resolver<
    Maybe<ResolversTypes["JSON"]>,
    ParentType,
    ContextType,
    Partial<MetadatumActiveValueArgs>
  >;
  datasetMetadataByMetadataId?: Resolver<
    ResolversTypes["DatasetMetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<MetadatumDatasetMetadataByMetadataIdArgs, "orderBy">
  >;
  datasetRecordingsTableColumnsByColDefId?: Resolver<
    ResolversTypes["DatasetRecordingsTableColumnsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      MetadatumDatasetRecordingsTableColumnsByColDefIdArgs,
      "orderBy"
    >
  >;
  datasetsByDescriptionId?: Resolver<
    ResolversTypes["DatasetsConnection"],
    ParentType,
    ContextType,
    RequireFields<MetadatumDatasetsByDescriptionIdArgs, "orderBy">
  >;
  displayName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  fileMetadataByMetadataId?: Resolver<
    ResolversTypes["FileMetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<MetadatumFileMetadataByMetadataIdArgs, "orderBy">
  >;
  fileOutputGroupMetadataByMetadataId?: Resolver<
    ResolversTypes["FileOutputGroupMetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<MetadatumFileOutputGroupMetadataByMetadataIdArgs, "orderBy">
  >;
  fileRecordingGroupMetadataByMetadataId?: Resolver<
    ResolversTypes["FileRecordingGroupMetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<
      MetadatumFileRecordingGroupMetadataByMetadataIdArgs,
      "orderBy"
    >
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  key?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  metadataValuesByMetadataId?: Resolver<
    ResolversTypes["MetadataValuesConnection"],
    ParentType,
    ContextType,
    RequireFields<MetadatumMetadataValuesByMetadataIdArgs, "orderBy">
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["Mutation"] = ResolversParentTypes["Mutation"],
> = {
  changeUserSession?: Resolver<
    Maybe<ResolversTypes["ChangeUserSessionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationChangeUserSessionArgs, "input">
  >;
  createAnalysisTable?: Resolver<
    Maybe<ResolversTypes["CreateAnalysisTablePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAnalysisTableArgs, "input">
  >;
  createAnalysisTableGroup?: Resolver<
    Maybe<ResolversTypes["CreateAnalysisTableGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAnalysisTableGroupArgs, "input">
  >;
  createAnalysisTableRow?: Resolver<
    Maybe<ResolversTypes["CreateAnalysisTableRowPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAnalysisTableRowArgs, "input">
  >;
  createAnalysisTableRowAttachResult?: Resolver<
    Maybe<ResolversTypes["CreateAnalysisTableRowAttachResultPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAnalysisTableRowAttachResultArgs, "input">
  >;
  createApplicationUser?: Resolver<
    Maybe<ResolversTypes["CreateApplicationUserPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateApplicationUserArgs, "input">
  >;
  createApplicationUserGroup?: Resolver<
    Maybe<ResolversTypes["CreateApplicationUserGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateApplicationUserGroupArgs, "input">
  >;
  createApplicationUserMembership?: Resolver<
    Maybe<ResolversTypes["CreateApplicationUserMembershipPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateApplicationUserMembershipArgs, "input">
  >;
  createApplicationUserMetadatum?: Resolver<
    Maybe<ResolversTypes["CreateApplicationUserMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateApplicationUserMetadatumArgs, "input">
  >;
  createApplicationUserSession?: Resolver<
    Maybe<ResolversTypes["CreateApplicationUserSessionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateApplicationUserSessionArgs, "input">
  >;
  createApplicationUserUserPermission?: Resolver<
    Maybe<ResolversTypes["CreateApplicationUserUserPermissionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateApplicationUserUserPermissionArgs, "input">
  >;
  createAuthGroup?: Resolver<
    Maybe<ResolversTypes["CreateAuthGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAuthGroupArgs, "input">
  >;
  createAuthGroupPermission?: Resolver<
    Maybe<ResolversTypes["CreateAuthGroupPermissionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAuthGroupPermissionArgs, "input">
  >;
  createAuthPermission?: Resolver<
    Maybe<ResolversTypes["CreateAuthPermissionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAuthPermissionArgs, "input">
  >;
  createAvailableNonRestrictedProject?: Resolver<
    Maybe<ResolversTypes["CreateAvailableNonRestrictedProjectPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAvailableNonRestrictedProjectArgs, "input">
  >;
  createAvatar?: Resolver<
    Maybe<ResolversTypes["CreateAvatarPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAvatarArgs, "input">
  >;
  createAwsBatchTask?: Resolver<
    Maybe<ResolversTypes["CreateAwsBatchTaskPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAwsBatchTaskArgs, "input">
  >;
  createAwsCognitoUser?: Resolver<
    Maybe<ResolversTypes["CreateAwsCognitoUserPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAwsCognitoUserArgs, "input">
  >;
  createAwsEcrImage?: Resolver<
    Maybe<ResolversTypes["CreateAwsEcrImagePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAwsEcrImageArgs, "input">
  >;
  createAwsMockBatchTask?: Resolver<
    Maybe<ResolversTypes["CreateAwsMockBatchTaskPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAwsMockBatchTaskArgs, "input">
  >;
  createAwsMockEcrImage?: Resolver<
    Maybe<ResolversTypes["CreateAwsMockEcrImagePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAwsMockEcrImageArgs, "input">
  >;
  createAwsMockS3File?: Resolver<
    Maybe<ResolversTypes["CreateAwsMockS3FilePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAwsMockS3FileArgs, "input">
  >;
  createAwsS3File?: Resolver<
    Maybe<ResolversTypes["CreateAwsS3FilePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAwsS3FileArgs, "input">
  >;
  createContainerImage?: Resolver<
    Maybe<ResolversTypes["CreateContainerImagePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateContainerImageArgs, "input">
  >;
  createCost?: Resolver<
    Maybe<ResolversTypes["CreateCostPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateCostArgs, "input">
  >;
  createCurrentApplicationUser?: Resolver<
    Maybe<ResolversTypes["CreateCurrentApplicationUserPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateCurrentApplicationUserArgs, "input">
  >;
  createCurrentUserMembership?: Resolver<
    Maybe<ResolversTypes["CreateCurrentUserMembershipPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateCurrentUserMembershipArgs, "input">
  >;
  createDataset?: Resolver<
    Maybe<ResolversTypes["CreateDatasetPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateDatasetArgs, "input">
  >;
  createDatasetMetadatum?: Resolver<
    Maybe<ResolversTypes["CreateDatasetMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateDatasetMetadatumArgs, "input">
  >;
  createDatasetRecordingsTable?: Resolver<
    Maybe<ResolversTypes["CreateDatasetRecordingsTablePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateDatasetRecordingsTableArgs, "input">
  >;
  createDatasetRecordingsTableColumn?: Resolver<
    Maybe<ResolversTypes["CreateDatasetRecordingsTableColumnPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateDatasetRecordingsTableColumnArgs, "input">
  >;
  createDatasetRecordingsTableColumnOrder?: Resolver<
    Maybe<ResolversTypes["CreateDatasetRecordingsTableColumnOrderPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateDatasetRecordingsTableColumnOrderArgs, "input">
  >;
  createDatasetVersion?: Resolver<
    Maybe<ResolversTypes["CreateDatasetVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateDatasetVersionArgs, "input">
  >;
  createDjangoAdminLog?: Resolver<
    Maybe<ResolversTypes["CreateDjangoAdminLogPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateDjangoAdminLogArgs, "input">
  >;
  createDjangoContentType?: Resolver<
    Maybe<ResolversTypes["CreateDjangoContentTypePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateDjangoContentTypeArgs, "input">
  >;
  createDjangoMigration?: Resolver<
    Maybe<ResolversTypes["CreateDjangoMigrationPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateDjangoMigrationArgs, "input">
  >;
  createDjangoSesSesstat?: Resolver<
    Maybe<ResolversTypes["CreateDjangoSesSesstatPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateDjangoSesSesstatArgs, "input">
  >;
  createDjangoSession?: Resolver<
    Maybe<ResolversTypes["CreateDjangoSessionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateDjangoSessionArgs, "input">
  >;
  createDrsFileexecutor?: Resolver<
    Maybe<ResolversTypes["CreateDrsFileexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateDrsFileexecutorArgs, "input">
  >;
  createDrsMockfileexecutor?: Resolver<
    Maybe<ResolversTypes["CreateDrsMockfileexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateDrsMockfileexecutorArgs, "input">
  >;
  createEulaVersion?: Resolver<
    Maybe<ResolversTypes["CreateEulaVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateEulaVersionArgs, "input">
  >;
  createEulaVersionUserAcceptance?: Resolver<
    Maybe<ResolversTypes["CreateEulaVersionUserAcceptancePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateEulaVersionUserAcceptanceArgs, "input">
  >;
  createExplorerQuery?: Resolver<
    Maybe<ResolversTypes["CreateExplorerQueryPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateExplorerQueryArgs, "input">
  >;
  createExplorerQueryfavorite?: Resolver<
    Maybe<ResolversTypes["CreateExplorerQueryfavoritePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateExplorerQueryfavoriteArgs, "input">
  >;
  createExplorerQuerylog?: Resolver<
    Maybe<ResolversTypes["CreateExplorerQuerylogPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateExplorerQuerylogArgs, "input">
  >;
  createFile?: Resolver<
    Maybe<ResolversTypes["CreateFilePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateFileArgs, "input">
  >;
  createFileActivity?: Resolver<
    Maybe<ResolversTypes["CreateFileActivityPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateFileActivityArgs, "input">
  >;
  createFileMetadatum?: Resolver<
    Maybe<ResolversTypes["CreateFileMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateFileMetadatumArgs, "input">
  >;
  createFileOutputGroupMetadatum?: Resolver<
    Maybe<ResolversTypes["CreateFileOutputGroupMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateFileOutputGroupMetadatumArgs, "input">
  >;
  createFileRecordingGroup?: Resolver<
    Maybe<ResolversTypes["CreateFileRecordingGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateFileRecordingGroupArgs, "input">
  >;
  createFileRecordingGroupMetadatum?: Resolver<
    Maybe<ResolversTypes["CreateFileRecordingGroupMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateFileRecordingGroupMetadatumArgs, "input">
  >;
  createMetadataValue?: Resolver<
    Maybe<ResolversTypes["CreateMetadataValuePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateMetadataValueArgs, "input">
  >;
  createMetadatum?: Resolver<
    Maybe<ResolversTypes["CreateMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateMetadatumArgs, "input">
  >;
  createOutputGroupFile?: Resolver<
    Maybe<ResolversTypes["CreateOutputGroupFilePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateOutputGroupFileArgs, "input">
  >;
  createRecordingGroupFile?: Resolver<
    Maybe<ResolversTypes["CreateRecordingGroupFilePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateRecordingGroupFileArgs, "input">
  >;
  createRole?: Resolver<
    Maybe<ResolversTypes["CreateRolePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateRoleArgs, "input">
  >;
  createRun?: Resolver<
    Maybe<ResolversTypes["CreateRunPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateRunArgs, "input">
  >;
  createSession?: Resolver<
    Maybe<ResolversTypes["CreateSessionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateSessionArgs, "input">
  >;
  createTag?: Resolver<
    Maybe<ResolversTypes["CreateTagPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTagArgs, "input">
  >;
  createTask?: Resolver<
    Maybe<ResolversTypes["CreateTaskPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTaskArgs, "input">
  >;
  createTaskActivity?: Resolver<
    Maybe<ResolversTypes["CreateTaskActivityPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTaskActivityArgs, "input">
  >;
  createTaskResource?: Resolver<
    Maybe<ResolversTypes["CreateTaskResourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTaskResourceArgs, "input">
  >;
  createTaskSource?: Resolver<
    Maybe<ResolversTypes["CreateTaskSourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTaskSourceArgs, "input">
  >;
  createTaskStatusLog?: Resolver<
    Maybe<ResolversTypes["CreateTaskStatusLogPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTaskStatusLogArgs, "input">
  >;
  createTenant?: Resolver<
    Maybe<ResolversTypes["CreateTenantPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTenantArgs, "input">
  >;
  createTenantContract?: Resolver<
    Maybe<ResolversTypes["CreateTenantContractPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTenantContractArgs, "input">
  >;
  createTenantContractAllowance?: Resolver<
    Maybe<ResolversTypes["CreateTenantContractAllowancePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTenantContractAllowanceArgs, "input">
  >;
  createTenantContractTransaction?: Resolver<
    Maybe<ResolversTypes["CreateTenantContractTransactionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTenantContractTransactionArgs, "input">
  >;
  createTenantMockuserexecutor?: Resolver<
    Maybe<ResolversTypes["CreateTenantMockuserexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTenantMockuserexecutorArgs, "input">
  >;
  createTenantMonthlyConsumedResource?: Resolver<
    Maybe<ResolversTypes["CreateTenantMonthlyConsumedResourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTenantMonthlyConsumedResourceArgs, "input">
  >;
  createTenantUserexecutor?: Resolver<
    Maybe<ResolversTypes["CreateTenantUserexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTenantUserexecutorArgs, "input">
  >;
  createTesContainerimageexecutor?: Resolver<
    Maybe<ResolversTypes["CreateTesContainerimageexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTesContainerimageexecutorArgs, "input">
  >;
  createTesMockcontainerimageexecutor?: Resolver<
    Maybe<ResolversTypes["CreateTesMockcontainerimageexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTesMockcontainerimageexecutorArgs, "input">
  >;
  createTesMocktaskexecutor?: Resolver<
    Maybe<ResolversTypes["CreateTesMocktaskexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTesMocktaskexecutorArgs, "input">
  >;
  createTesTaskexecutor?: Resolver<
    Maybe<ResolversTypes["CreateTesTaskexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTesTaskexecutorArgs, "input">
  >;
  createTool?: Resolver<
    Maybe<ResolversTypes["CreateToolPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateToolArgs, "input">
  >;
  createToolCategory?: Resolver<
    Maybe<ResolversTypes["CreateToolCategoryPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateToolCategoryArgs, "input">
  >;
  createToolDisplayCategory?: Resolver<
    Maybe<ResolversTypes["CreateToolDisplayCategoryPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateToolDisplayCategoryArgs, "input">
  >;
  createToolParameter?: Resolver<
    Maybe<ResolversTypes["CreateToolParameterPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateToolParameterArgs, "input">
  >;
  createToolResource?: Resolver<
    Maybe<ResolversTypes["CreateToolResourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateToolResourceArgs, "input">
  >;
  createToolResult?: Resolver<
    Maybe<ResolversTypes["CreateToolResultPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateToolResultArgs, "input">
  >;
  createToolSecret?: Resolver<
    Maybe<ResolversTypes["CreateToolSecretPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateToolSecretArgs, "input">
  >;
  createToolSource?: Resolver<
    Maybe<ResolversTypes["CreateToolSourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateToolSourceArgs, "input">
  >;
  createToolTenant?: Resolver<
    Maybe<ResolversTypes["CreateToolTenantPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateToolTenantArgs, "input">
  >;
  createToolVersion?: Resolver<
    Maybe<ResolversTypes["CreateToolVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateToolVersionArgs, "input">
  >;
  createToolbox?: Resolver<
    Maybe<ResolversTypes["CreateToolboxPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateToolboxArgs, "input">
  >;
  createToolboxExitcode?: Resolver<
    Maybe<ResolversTypes["CreateToolboxExitcodePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateToolboxExitcodeArgs, "input">
  >;
  createToolboxTenant?: Resolver<
    Maybe<ResolversTypes["CreateToolboxTenantPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateToolboxTenantArgs, "input">
  >;
  createToolboxVersion?: Resolver<
    Maybe<ResolversTypes["CreateToolboxVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateToolboxVersionArgs, "input">
  >;
  createToolboxVersionExitcode?: Resolver<
    Maybe<ResolversTypes["CreateToolboxVersionExitcodePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateToolboxVersionExitcodeArgs, "input">
  >;
  createToolboxVersionToolVersion?: Resolver<
    Maybe<ResolversTypes["CreateToolboxVersionToolVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateToolboxVersionToolVersionArgs, "input">
  >;
  createWorkflow?: Resolver<
    Maybe<ResolversTypes["CreateWorkflowPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateWorkflowArgs, "input">
  >;
  createWorkflowDatum?: Resolver<
    Maybe<ResolversTypes["CreateWorkflowDatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateWorkflowDatumArgs, "input">
  >;
  createWorkflowResult?: Resolver<
    Maybe<ResolversTypes["CreateWorkflowResultPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateWorkflowResultArgs, "input">
  >;
  createWorkflowResultTag?: Resolver<
    Maybe<ResolversTypes["CreateWorkflowResultTagPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateWorkflowResultTagArgs, "input">
  >;
  createWorkflowResultWorkflowVersion?: Resolver<
    Maybe<ResolversTypes["CreateWorkflowResultWorkflowVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateWorkflowResultWorkflowVersionArgs, "input">
  >;
  createWorkflowSection?: Resolver<
    Maybe<ResolversTypes["CreateWorkflowSectionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateWorkflowSectionArgs, "input">
  >;
  createWorkflowSectionBlock?: Resolver<
    Maybe<ResolversTypes["CreateWorkflowSectionBlockPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateWorkflowSectionBlockArgs, "input">
  >;
  createWorkflowSectionBlockSection?: Resolver<
    Maybe<ResolversTypes["CreateWorkflowSectionBlockSectionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateWorkflowSectionBlockSectionArgs, "input">
  >;
  createWorkflowSectionWorkflowVersion?: Resolver<
    Maybe<ResolversTypes["CreateWorkflowSectionWorkflowVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateWorkflowSectionWorkflowVersionArgs, "input">
  >;
  createWorkflowSource?: Resolver<
    Maybe<ResolversTypes["CreateWorkflowSourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateWorkflowSourceArgs, "input">
  >;
  createWorkflowSourceTag?: Resolver<
    Maybe<ResolversTypes["CreateWorkflowSourceTagPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateWorkflowSourceTagArgs, "input">
  >;
  createWorkflowSourceWorkflowVersion?: Resolver<
    Maybe<ResolversTypes["CreateWorkflowSourceWorkflowVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateWorkflowSourceWorkflowVersionArgs, "input">
  >;
  createWorkflowVersion?: Resolver<
    Maybe<ResolversTypes["CreateWorkflowVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateWorkflowVersionArgs, "input">
  >;
  deleteAnalysisTableById?: Resolver<
    Maybe<ResolversTypes["DeleteAnalysisTablePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAnalysisTableByIdArgs, "input">
  >;
  deleteAnalysisTableGroupById?: Resolver<
    Maybe<ResolversTypes["DeleteAnalysisTableGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAnalysisTableGroupByIdArgs, "input">
  >;
  deleteAnalysisTableRowAttachResultById?: Resolver<
    Maybe<ResolversTypes["DeleteAnalysisTableRowAttachResultPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAnalysisTableRowAttachResultByIdArgs, "input">
  >;
  deleteAnalysisTableRowById?: Resolver<
    Maybe<ResolversTypes["DeleteAnalysisTableRowPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAnalysisTableRowByIdArgs, "input">
  >;
  deleteAnalysisTableRowByTaskId?: Resolver<
    Maybe<ResolversTypes["DeleteAnalysisTableRowPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAnalysisTableRowByTaskIdArgs, "input">
  >;
  deleteApplicationUserByEmail?: Resolver<
    Maybe<ResolversTypes["DeleteApplicationUserPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteApplicationUserByEmailArgs, "input">
  >;
  deleteApplicationUserById?: Resolver<
    Maybe<ResolversTypes["DeleteApplicationUserPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteApplicationUserByIdArgs, "input">
  >;
  deleteApplicationUserByUsername?: Resolver<
    Maybe<ResolversTypes["DeleteApplicationUserPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteApplicationUserByUsernameArgs, "input">
  >;
  deleteApplicationUserGroupById?: Resolver<
    Maybe<ResolversTypes["DeleteApplicationUserGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteApplicationUserGroupByIdArgs, "input">
  >;
  deleteApplicationUserGroupByUserIdAndGroupId?: Resolver<
    Maybe<ResolversTypes["DeleteApplicationUserGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteApplicationUserGroupByUserIdAndGroupIdArgs,
      "input"
    >
  >;
  deleteApplicationUserMembershipById?: Resolver<
    Maybe<ResolversTypes["DeleteApplicationUserMembershipPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteApplicationUserMembershipByIdArgs, "input">
  >;
  deleteApplicationUserMembershipByUserIdAndTenantId?: Resolver<
    Maybe<ResolversTypes["DeleteApplicationUserMembershipPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteApplicationUserMembershipByUserIdAndTenantIdArgs,
      "input"
    >
  >;
  deleteApplicationUserMetadatumById?: Resolver<
    Maybe<ResolversTypes["DeleteApplicationUserMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteApplicationUserMetadatumByIdArgs, "input">
  >;
  deleteApplicationUserMetadatumByUserIdAndKey?: Resolver<
    Maybe<ResolversTypes["DeleteApplicationUserMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteApplicationUserMetadatumByUserIdAndKeyArgs,
      "input"
    >
  >;
  deleteApplicationUserSessionById?: Resolver<
    Maybe<ResolversTypes["DeleteApplicationUserSessionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteApplicationUserSessionByIdArgs, "input">
  >;
  deleteApplicationUserUserPermissionById?: Resolver<
    Maybe<ResolversTypes["DeleteApplicationUserUserPermissionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteApplicationUserUserPermissionByIdArgs, "input">
  >;
  deleteApplicationUserUserPermissionByUserIdAndPermissionId?: Resolver<
    Maybe<ResolversTypes["DeleteApplicationUserUserPermissionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteApplicationUserUserPermissionByUserIdAndPermissionIdArgs,
      "input"
    >
  >;
  deleteAuthGroupById?: Resolver<
    Maybe<ResolversTypes["DeleteAuthGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAuthGroupByIdArgs, "input">
  >;
  deleteAuthGroupByName?: Resolver<
    Maybe<ResolversTypes["DeleteAuthGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAuthGroupByNameArgs, "input">
  >;
  deleteAuthGroupPermissionByGroupIdAndPermissionId?: Resolver<
    Maybe<ResolversTypes["DeleteAuthGroupPermissionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteAuthGroupPermissionByGroupIdAndPermissionIdArgs,
      "input"
    >
  >;
  deleteAuthGroupPermissionById?: Resolver<
    Maybe<ResolversTypes["DeleteAuthGroupPermissionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAuthGroupPermissionByIdArgs, "input">
  >;
  deleteAuthPermissionByContentTypeIdAndCodename?: Resolver<
    Maybe<ResolversTypes["DeleteAuthPermissionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteAuthPermissionByContentTypeIdAndCodenameArgs,
      "input"
    >
  >;
  deleteAuthPermissionById?: Resolver<
    Maybe<ResolversTypes["DeleteAuthPermissionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAuthPermissionByIdArgs, "input">
  >;
  deleteAvatarById?: Resolver<
    Maybe<ResolversTypes["DeleteAvatarPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAvatarByIdArgs, "input">
  >;
  deleteAvatarByUserId?: Resolver<
    Maybe<ResolversTypes["DeleteAvatarPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAvatarByUserIdArgs, "input">
  >;
  deleteAwsBatchTaskByTaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DeleteAwsBatchTaskPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAwsBatchTaskByTaskexecutorPtrIdArgs, "input">
  >;
  deleteAwsCognitoUserByUserexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DeleteAwsCognitoUserPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAwsCognitoUserByUserexecutorPtrIdArgs, "input">
  >;
  deleteAwsEcrImageByContainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DeleteAwsEcrImagePayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteAwsEcrImageByContainerimageexecutorPtrIdArgs,
      "input"
    >
  >;
  deleteAwsMockBatchTaskByAwstaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DeleteAwsMockBatchTaskPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteAwsMockBatchTaskByAwstaskexecutorPtrIdArgs,
      "input"
    >
  >;
  deleteAwsMockEcrImageByAwscontainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DeleteAwsMockEcrImagePayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteAwsMockEcrImageByAwscontainerimageexecutorPtrIdArgs,
      "input"
    >
  >;
  deleteAwsMockS3FileByAwsfileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DeleteAwsMockS3FilePayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteAwsMockS3FileByAwsfileexecutorPtrIdArgs,
      "input"
    >
  >;
  deleteAwsS3FileByFileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DeleteAwsS3FilePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAwsS3FileByFileexecutorPtrIdArgs, "input">
  >;
  deleteContainerImageByExecutorId?: Resolver<
    Maybe<ResolversTypes["DeleteContainerImagePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteContainerImageByExecutorIdArgs, "input">
  >;
  deleteContainerImageById?: Resolver<
    Maybe<ResolversTypes["DeleteContainerImagePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteContainerImageByIdArgs, "input">
  >;
  deleteContainerImageByNameAndTag?: Resolver<
    Maybe<ResolversTypes["DeleteContainerImagePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteContainerImageByNameAndTagArgs, "input">
  >;
  deleteContainerImageByRepositoryAndTag?: Resolver<
    Maybe<ResolversTypes["DeleteContainerImagePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteContainerImageByRepositoryAndTagArgs, "input">
  >;
  deleteContainerImageByToolboxVersionId?: Resolver<
    Maybe<ResolversTypes["DeleteContainerImagePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteContainerImageByToolboxVersionIdArgs, "input">
  >;
  deleteCostById?: Resolver<
    Maybe<ResolversTypes["DeleteCostPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteCostByIdArgs, "input">
  >;
  deleteDatasetById?: Resolver<
    Maybe<ResolversTypes["DeleteDatasetPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDatasetByIdArgs, "input">
  >;
  deleteDatasetByProjectIdAndPrefix?: Resolver<
    Maybe<ResolversTypes["DeleteDatasetPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDatasetByProjectIdAndPrefixArgs, "input">
  >;
  deleteDatasetByRecordingsTableId?: Resolver<
    Maybe<ResolversTypes["DeleteDatasetPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDatasetByRecordingsTableIdArgs, "input">
  >;
  deleteDatasetByTenantIdAndKey?: Resolver<
    Maybe<ResolversTypes["DeleteDatasetPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDatasetByTenantIdAndKeyArgs, "input">
  >;
  deleteDatasetMetadatumById?: Resolver<
    Maybe<ResolversTypes["DeleteDatasetMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDatasetMetadatumByIdArgs, "input">
  >;
  deleteDatasetRecordingsTableById?: Resolver<
    Maybe<ResolversTypes["DeleteDatasetRecordingsTablePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDatasetRecordingsTableByIdArgs, "input">
  >;
  deleteDatasetRecordingsTableColumnById?: Resolver<
    Maybe<ResolversTypes["DeleteDatasetRecordingsTableColumnPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDatasetRecordingsTableColumnByIdArgs, "input">
  >;
  deleteDatasetRecordingsTableColumnOrderById?: Resolver<
    Maybe<ResolversTypes["DeleteDatasetRecordingsTableColumnOrderPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteDatasetRecordingsTableColumnOrderByIdArgs,
      "input"
    >
  >;
  deleteDatasetVersionById?: Resolver<
    Maybe<ResolversTypes["DeleteDatasetVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDatasetVersionByIdArgs, "input">
  >;
  deleteDjangoAdminLogById?: Resolver<
    Maybe<ResolversTypes["DeleteDjangoAdminLogPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDjangoAdminLogByIdArgs, "input">
  >;
  deleteDjangoContentTypeByAppLabelAndModel?: Resolver<
    Maybe<ResolversTypes["DeleteDjangoContentTypePayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteDjangoContentTypeByAppLabelAndModelArgs,
      "input"
    >
  >;
  deleteDjangoContentTypeById?: Resolver<
    Maybe<ResolversTypes["DeleteDjangoContentTypePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDjangoContentTypeByIdArgs, "input">
  >;
  deleteDjangoMigrationById?: Resolver<
    Maybe<ResolversTypes["DeleteDjangoMigrationPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDjangoMigrationByIdArgs, "input">
  >;
  deleteDjangoSesSesstatByDate?: Resolver<
    Maybe<ResolversTypes["DeleteDjangoSesSesstatPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDjangoSesSesstatByDateArgs, "input">
  >;
  deleteDjangoSesSesstatById?: Resolver<
    Maybe<ResolversTypes["DeleteDjangoSesSesstatPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDjangoSesSesstatByIdArgs, "input">
  >;
  deleteDjangoSessionBySessionKey?: Resolver<
    Maybe<ResolversTypes["DeleteDjangoSessionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDjangoSessionBySessionKeyArgs, "input">
  >;
  deleteDrsFileexecutorById?: Resolver<
    Maybe<ResolversTypes["DeleteDrsFileexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDrsFileexecutorByIdArgs, "input">
  >;
  deleteDrsMockfileexecutorByFileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DeleteDrsMockfileexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteDrsMockfileexecutorByFileexecutorPtrIdArgs,
      "input"
    >
  >;
  deleteEulaVersionById?: Resolver<
    Maybe<ResolversTypes["DeleteEulaVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteEulaVersionByIdArgs, "input">
  >;
  deleteEulaVersionByVersion?: Resolver<
    Maybe<ResolversTypes["DeleteEulaVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteEulaVersionByVersionArgs, "input">
  >;
  deleteEulaVersionUserAcceptanceById?: Resolver<
    Maybe<ResolversTypes["DeleteEulaVersionUserAcceptancePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteEulaVersionUserAcceptanceByIdArgs, "input">
  >;
  deleteEulaVersionUserAcceptanceByUserIdAndEulaVersionId?: Resolver<
    Maybe<ResolversTypes["DeleteEulaVersionUserAcceptancePayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteEulaVersionUserAcceptanceByUserIdAndEulaVersionIdArgs,
      "input"
    >
  >;
  deleteExplorerQueryById?: Resolver<
    Maybe<ResolversTypes["DeleteExplorerQueryPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteExplorerQueryByIdArgs, "input">
  >;
  deleteExplorerQueryfavoriteById?: Resolver<
    Maybe<ResolversTypes["DeleteExplorerQueryfavoritePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteExplorerQueryfavoriteByIdArgs, "input">
  >;
  deleteExplorerQueryfavoriteByQueryIdAndUserId?: Resolver<
    Maybe<ResolversTypes["DeleteExplorerQueryfavoritePayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteExplorerQueryfavoriteByQueryIdAndUserIdArgs,
      "input"
    >
  >;
  deleteExplorerQuerylogById?: Resolver<
    Maybe<ResolversTypes["DeleteExplorerQuerylogPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteExplorerQuerylogByIdArgs, "input">
  >;
  deleteFileActivityById?: Resolver<
    Maybe<ResolversTypes["DeleteFileActivityPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteFileActivityByIdArgs, "input">
  >;
  deleteFileById?: Resolver<
    Maybe<ResolversTypes["DeleteFilePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteFileByIdArgs, "input">
  >;
  deleteFileMetadatumById?: Resolver<
    Maybe<ResolversTypes["DeleteFileMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteFileMetadatumByIdArgs, "input">
  >;
  deleteFileOutputGroupById?: Resolver<
    Maybe<ResolversTypes["DeleteFileOutputGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteFileOutputGroupByIdArgs, "input">
  >;
  deleteFileOutputGroupByTaskId?: Resolver<
    Maybe<ResolversTypes["DeleteFileOutputGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteFileOutputGroupByTaskIdArgs, "input">
  >;
  deleteFileOutputGroupMetadatumById?: Resolver<
    Maybe<ResolversTypes["DeleteFileOutputGroupMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteFileOutputGroupMetadatumByIdArgs, "input">
  >;
  deleteFileRecordingGroupById?: Resolver<
    Maybe<ResolversTypes["DeleteFileRecordingGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteFileRecordingGroupByIdArgs, "input">
  >;
  deleteFileRecordingGroupMetadatumById?: Resolver<
    Maybe<ResolversTypes["DeleteFileRecordingGroupMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteFileRecordingGroupMetadatumByIdArgs, "input">
  >;
  deleteMetadataValueById?: Resolver<
    Maybe<ResolversTypes["DeleteMetadataValuePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteMetadataValueByIdArgs, "input">
  >;
  deleteMetadatumById?: Resolver<
    Maybe<ResolversTypes["DeleteMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteMetadatumByIdArgs, "input">
  >;
  deleteOutputGroupFileById?: Resolver<
    Maybe<ResolversTypes["DeleteOutputGroupFilePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteOutputGroupFileByIdArgs, "input">
  >;
  deleteRecordingGroupFileById?: Resolver<
    Maybe<ResolversTypes["DeleteRecordingGroupFilePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteRecordingGroupFileByIdArgs, "input">
  >;
  deleteRunById?: Resolver<
    Maybe<ResolversTypes["DeleteRunPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteRunByIdArgs, "input">
  >;
  deleteSession?: Resolver<
    Maybe<ResolversTypes["DeleteSessionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteSessionArgs, "input">
  >;
  deleteTagById?: Resolver<
    Maybe<ResolversTypes["DeleteTagPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTagByIdArgs, "input">
  >;
  deleteTaskActivityById?: Resolver<
    Maybe<ResolversTypes["DeleteTaskActivityPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTaskActivityByIdArgs, "input">
  >;
  deleteTaskActivityByTaskId?: Resolver<
    Maybe<ResolversTypes["DeleteTaskActivityPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTaskActivityByTaskIdArgs, "input">
  >;
  deleteTaskByExecutorId?: Resolver<
    Maybe<ResolversTypes["DeleteTaskPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTaskByExecutorIdArgs, "input">
  >;
  deleteTaskById?: Resolver<
    Maybe<ResolversTypes["DeleteTaskPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTaskByIdArgs, "input">
  >;
  deleteTaskResourceById?: Resolver<
    Maybe<ResolversTypes["DeleteTaskResourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTaskResourceByIdArgs, "input">
  >;
  deleteTaskSourceById?: Resolver<
    Maybe<ResolversTypes["DeleteTaskSourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTaskSourceByIdArgs, "input">
  >;
  deleteTaskStatusLogById?: Resolver<
    Maybe<ResolversTypes["DeleteTaskStatusLogPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTaskStatusLogByIdArgs, "input">
  >;
  deleteTaskStatusLogByTaskIdAndStatus?: Resolver<
    Maybe<ResolversTypes["DeleteTaskStatusLogPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTaskStatusLogByTaskIdAndStatusArgs, "input">
  >;
  deleteTenantById?: Resolver<
    Maybe<ResolversTypes["DeleteTenantPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTenantByIdArgs, "input">
  >;
  deleteTenantByKey?: Resolver<
    Maybe<ResolversTypes["DeleteTenantPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTenantByKeyArgs, "input">
  >;
  deleteTenantByName?: Resolver<
    Maybe<ResolversTypes["DeleteTenantPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTenantByNameArgs, "input">
  >;
  deleteTenantContractAllowanceById?: Resolver<
    Maybe<ResolversTypes["DeleteTenantContractAllowancePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTenantContractAllowanceByIdArgs, "input">
  >;
  deleteTenantContractAllowanceByTenantContractTransactionIdAndQuotaType?: Resolver<
    Maybe<ResolversTypes["DeleteTenantContractAllowancePayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeArgs,
      "input"
    >
  >;
  deleteTenantContractById?: Resolver<
    Maybe<ResolversTypes["DeleteTenantContractPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTenantContractByIdArgs, "input">
  >;
  deleteTenantContractByName?: Resolver<
    Maybe<ResolversTypes["DeleteTenantContractPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTenantContractByNameArgs, "input">
  >;
  deleteTenantContractTransactionById?: Resolver<
    Maybe<ResolversTypes["DeleteTenantContractTransactionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTenantContractTransactionByIdArgs, "input">
  >;
  deleteTenantMockuserexecutorByUserexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DeleteTenantMockuserexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteTenantMockuserexecutorByUserexecutorPtrIdArgs,
      "input"
    >
  >;
  deleteTenantMonthlyConsumedResourceById?: Resolver<
    Maybe<ResolversTypes["DeleteTenantMonthlyConsumedResourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTenantMonthlyConsumedResourceByIdArgs, "input">
  >;
  deleteTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract?: Resolver<
    Maybe<ResolversTypes["DeleteTenantMonthlyConsumedResourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractArgs,
      "input"
    >
  >;
  deleteTenantUserexecutorById?: Resolver<
    Maybe<ResolversTypes["DeleteTenantUserexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTenantUserexecutorByIdArgs, "input">
  >;
  deleteTesContainerimageexecutorById?: Resolver<
    Maybe<ResolversTypes["DeleteTesContainerimageexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTesContainerimageexecutorByIdArgs, "input">
  >;
  deleteTesMockcontainerimageexecutorByContainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DeleteTesMockcontainerimageexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteTesMockcontainerimageexecutorByContainerimageexecutorPtrIdArgs,
      "input"
    >
  >;
  deleteTesMocktaskexecutorByTaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DeleteTesMocktaskexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteTesMocktaskexecutorByTaskexecutorPtrIdArgs,
      "input"
    >
  >;
  deleteTesTaskexecutorById?: Resolver<
    Maybe<ResolversTypes["DeleteTesTaskexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTesTaskexecutorByIdArgs, "input">
  >;
  deleteToolById?: Resolver<
    Maybe<ResolversTypes["DeleteToolPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolByIdArgs, "input">
  >;
  deleteToolByKey?: Resolver<
    Maybe<ResolversTypes["DeleteToolPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolByKeyArgs, "input">
  >;
  deleteToolCategoryById?: Resolver<
    Maybe<ResolversTypes["DeleteToolCategoryPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolCategoryByIdArgs, "input">
  >;
  deleteToolCategoryByKey?: Resolver<
    Maybe<ResolversTypes["DeleteToolCategoryPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolCategoryByKeyArgs, "input">
  >;
  deleteToolDisplayCategoryById?: Resolver<
    Maybe<ResolversTypes["DeleteToolDisplayCategoryPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolDisplayCategoryByIdArgs, "input">
  >;
  deleteToolDisplayCategoryByToolIdAndToolcategoryId?: Resolver<
    Maybe<ResolversTypes["DeleteToolDisplayCategoryPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteToolDisplayCategoryByToolIdAndToolcategoryIdArgs,
      "input"
    >
  >;
  deleteToolParameterById?: Resolver<
    Maybe<ResolversTypes["DeleteToolParameterPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolParameterByIdArgs, "input">
  >;
  deleteToolResourceById?: Resolver<
    Maybe<ResolversTypes["DeleteToolResourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolResourceByIdArgs, "input">
  >;
  deleteToolResourceByTypeAndToolVersionId?: Resolver<
    Maybe<ResolversTypes["DeleteToolResourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolResourceByTypeAndToolVersionIdArgs, "input">
  >;
  deleteToolResultById?: Resolver<
    Maybe<ResolversTypes["DeleteToolResultPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolResultByIdArgs, "input">
  >;
  deleteToolSecretById?: Resolver<
    Maybe<ResolversTypes["DeleteToolSecretPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolSecretByIdArgs, "input">
  >;
  deleteToolSourceById?: Resolver<
    Maybe<ResolversTypes["DeleteToolSourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolSourceByIdArgs, "input">
  >;
  deleteToolTenantById?: Resolver<
    Maybe<ResolversTypes["DeleteToolTenantPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolTenantByIdArgs, "input">
  >;
  deleteToolTenantByToolIdAndTenantId?: Resolver<
    Maybe<ResolversTypes["DeleteToolTenantPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolTenantByToolIdAndTenantIdArgs, "input">
  >;
  deleteToolVersionById?: Resolver<
    Maybe<ResolversTypes["DeleteToolVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolVersionByIdArgs, "input">
  >;
  deleteToolVersionByToolIdAndVersion?: Resolver<
    Maybe<ResolversTypes["DeleteToolVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolVersionByToolIdAndVersionArgs, "input">
  >;
  deleteToolboxById?: Resolver<
    Maybe<ResolversTypes["DeleteToolboxPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolboxByIdArgs, "input">
  >;
  deleteToolboxByKey?: Resolver<
    Maybe<ResolversTypes["DeleteToolboxPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolboxByKeyArgs, "input">
  >;
  deleteToolboxExitcodeByCode?: Resolver<
    Maybe<ResolversTypes["DeleteToolboxExitcodePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolboxExitcodeByCodeArgs, "input">
  >;
  deleteToolboxExitcodeById?: Resolver<
    Maybe<ResolversTypes["DeleteToolboxExitcodePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolboxExitcodeByIdArgs, "input">
  >;
  deleteToolboxTenantById?: Resolver<
    Maybe<ResolversTypes["DeleteToolboxTenantPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolboxTenantByIdArgs, "input">
  >;
  deleteToolboxTenantByToolboxIdAndTenantId?: Resolver<
    Maybe<ResolversTypes["DeleteToolboxTenantPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteToolboxTenantByToolboxIdAndTenantIdArgs,
      "input"
    >
  >;
  deleteToolboxVersionById?: Resolver<
    Maybe<ResolversTypes["DeleteToolboxVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolboxVersionByIdArgs, "input">
  >;
  deleteToolboxVersionByToolboxIdAndVersion?: Resolver<
    Maybe<ResolversTypes["DeleteToolboxVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteToolboxVersionByToolboxIdAndVersionArgs,
      "input"
    >
  >;
  deleteToolboxVersionExitcodeById?: Resolver<
    Maybe<ResolversTypes["DeleteToolboxVersionExitcodePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolboxVersionExitcodeByIdArgs, "input">
  >;
  deleteToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeId?: Resolver<
    Maybe<ResolversTypes["DeleteToolboxVersionExitcodePayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdArgs,
      "input"
    >
  >;
  deleteToolboxVersionToolVersionById?: Resolver<
    Maybe<ResolversTypes["DeleteToolboxVersionToolVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteToolboxVersionToolVersionByIdArgs, "input">
  >;
  deleteToolboxVersionToolVersionByToolboxversionIdAndToolversionId?: Resolver<
    Maybe<ResolversTypes["DeleteToolboxVersionToolVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteToolboxVersionToolVersionByToolboxversionIdAndToolversionIdArgs,
      "input"
    >
  >;
  deleteWorkflowById?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteWorkflowByIdArgs, "input">
  >;
  deleteWorkflowDatumById?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowDatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteWorkflowDatumByIdArgs, "input">
  >;
  deleteWorkflowResultById?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowResultPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteWorkflowResultByIdArgs, "input">
  >;
  deleteWorkflowResultTagById?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowResultTagPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteWorkflowResultTagByIdArgs, "input">
  >;
  deleteWorkflowResultTagByResultIdAndTagId?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowResultTagPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteWorkflowResultTagByResultIdAndTagIdArgs,
      "input"
    >
  >;
  deleteWorkflowResultWorkflowVersionById?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowResultWorkflowVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteWorkflowResultWorkflowVersionByIdArgs, "input">
  >;
  deleteWorkflowResultWorkflowVersionByResultIdAndWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowResultWorkflowVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdArgs,
      "input"
    >
  >;
  deleteWorkflowSectionBlockById?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowSectionBlockPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteWorkflowSectionBlockByIdArgs, "input">
  >;
  deleteWorkflowSectionBlockSectionByBlockIdAndSectionId?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowSectionBlockSectionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteWorkflowSectionBlockSectionByBlockIdAndSectionIdArgs,
      "input"
    >
  >;
  deleteWorkflowSectionBlockSectionById?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowSectionBlockSectionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteWorkflowSectionBlockSectionByIdArgs, "input">
  >;
  deleteWorkflowSectionById?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowSectionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteWorkflowSectionByIdArgs, "input">
  >;
  deleteWorkflowSectionWorkflowVersionById?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowSectionWorkflowVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteWorkflowSectionWorkflowVersionByIdArgs, "input">
  >;
  deleteWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowSectionWorkflowVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdArgs,
      "input"
    >
  >;
  deleteWorkflowSourceById?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowSourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteWorkflowSourceByIdArgs, "input">
  >;
  deleteWorkflowSourceTagById?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowSourceTagPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteWorkflowSourceTagByIdArgs, "input">
  >;
  deleteWorkflowSourceTagBySourceIdAndTagId?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowSourceTagPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteWorkflowSourceTagBySourceIdAndTagIdArgs,
      "input"
    >
  >;
  deleteWorkflowSourceWorkflowVersionById?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowSourceWorkflowVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteWorkflowSourceWorkflowVersionByIdArgs, "input">
  >;
  deleteWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowSourceWorkflowVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationDeleteWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdArgs,
      "input"
    >
  >;
  deleteWorkflowVersionById?: Resolver<
    Maybe<ResolversTypes["DeleteWorkflowVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteWorkflowVersionByIdArgs, "input">
  >;
  updateAnalysisTableById?: Resolver<
    Maybe<ResolversTypes["UpdateAnalysisTablePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAnalysisTableByIdArgs, "input">
  >;
  updateAnalysisTableGroupById?: Resolver<
    Maybe<ResolversTypes["UpdateAnalysisTableGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAnalysisTableGroupByIdArgs, "input">
  >;
  updateAnalysisTableRowAttachResultById?: Resolver<
    Maybe<ResolversTypes["UpdateAnalysisTableRowAttachResultPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAnalysisTableRowAttachResultByIdArgs, "input">
  >;
  updateAnalysisTableRowById?: Resolver<
    Maybe<ResolversTypes["UpdateAnalysisTableRowPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAnalysisTableRowByIdArgs, "input">
  >;
  updateAnalysisTableRowByTaskId?: Resolver<
    Maybe<ResolversTypes["UpdateAnalysisTableRowPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAnalysisTableRowByTaskIdArgs, "input">
  >;
  updateApplicationUserByEmail?: Resolver<
    Maybe<ResolversTypes["UpdateApplicationUserPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateApplicationUserByEmailArgs, "input">
  >;
  updateApplicationUserById?: Resolver<
    Maybe<ResolversTypes["UpdateApplicationUserPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateApplicationUserByIdArgs, "input">
  >;
  updateApplicationUserByUsername?: Resolver<
    Maybe<ResolversTypes["UpdateApplicationUserPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateApplicationUserByUsernameArgs, "input">
  >;
  updateApplicationUserGroupById?: Resolver<
    Maybe<ResolversTypes["UpdateApplicationUserGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateApplicationUserGroupByIdArgs, "input">
  >;
  updateApplicationUserGroupByUserIdAndGroupId?: Resolver<
    Maybe<ResolversTypes["UpdateApplicationUserGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateApplicationUserGroupByUserIdAndGroupIdArgs,
      "input"
    >
  >;
  updateApplicationUserMembershipById?: Resolver<
    Maybe<ResolversTypes["UpdateApplicationUserMembershipPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateApplicationUserMembershipByIdArgs, "input">
  >;
  updateApplicationUserMembershipByUserIdAndTenantId?: Resolver<
    Maybe<ResolversTypes["UpdateApplicationUserMembershipPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateApplicationUserMembershipByUserIdAndTenantIdArgs,
      "input"
    >
  >;
  updateApplicationUserMetadatumById?: Resolver<
    Maybe<ResolversTypes["UpdateApplicationUserMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateApplicationUserMetadatumByIdArgs, "input">
  >;
  updateApplicationUserMetadatumByUserIdAndKey?: Resolver<
    Maybe<ResolversTypes["UpdateApplicationUserMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateApplicationUserMetadatumByUserIdAndKeyArgs,
      "input"
    >
  >;
  updateApplicationUserSessionById?: Resolver<
    Maybe<ResolversTypes["UpdateApplicationUserSessionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateApplicationUserSessionByIdArgs, "input">
  >;
  updateApplicationUserUserPermissionById?: Resolver<
    Maybe<ResolversTypes["UpdateApplicationUserUserPermissionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateApplicationUserUserPermissionByIdArgs, "input">
  >;
  updateApplicationUserUserPermissionByUserIdAndPermissionId?: Resolver<
    Maybe<ResolversTypes["UpdateApplicationUserUserPermissionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateApplicationUserUserPermissionByUserIdAndPermissionIdArgs,
      "input"
    >
  >;
  updateAuthGroupById?: Resolver<
    Maybe<ResolversTypes["UpdateAuthGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAuthGroupByIdArgs, "input">
  >;
  updateAuthGroupByName?: Resolver<
    Maybe<ResolversTypes["UpdateAuthGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAuthGroupByNameArgs, "input">
  >;
  updateAuthGroupPermissionByGroupIdAndPermissionId?: Resolver<
    Maybe<ResolversTypes["UpdateAuthGroupPermissionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateAuthGroupPermissionByGroupIdAndPermissionIdArgs,
      "input"
    >
  >;
  updateAuthGroupPermissionById?: Resolver<
    Maybe<ResolversTypes["UpdateAuthGroupPermissionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAuthGroupPermissionByIdArgs, "input">
  >;
  updateAuthPermissionByContentTypeIdAndCodename?: Resolver<
    Maybe<ResolversTypes["UpdateAuthPermissionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateAuthPermissionByContentTypeIdAndCodenameArgs,
      "input"
    >
  >;
  updateAuthPermissionById?: Resolver<
    Maybe<ResolversTypes["UpdateAuthPermissionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAuthPermissionByIdArgs, "input">
  >;
  updateAvatarById?: Resolver<
    Maybe<ResolversTypes["UpdateAvatarPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAvatarByIdArgs, "input">
  >;
  updateAvatarByUserId?: Resolver<
    Maybe<ResolversTypes["UpdateAvatarPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAvatarByUserIdArgs, "input">
  >;
  updateAwsBatchTaskByTaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["UpdateAwsBatchTaskPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAwsBatchTaskByTaskexecutorPtrIdArgs, "input">
  >;
  updateAwsCognitoUserByUserexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["UpdateAwsCognitoUserPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAwsCognitoUserByUserexecutorPtrIdArgs, "input">
  >;
  updateAwsEcrImageByContainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["UpdateAwsEcrImagePayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateAwsEcrImageByContainerimageexecutorPtrIdArgs,
      "input"
    >
  >;
  updateAwsMockBatchTaskByAwstaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["UpdateAwsMockBatchTaskPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateAwsMockBatchTaskByAwstaskexecutorPtrIdArgs,
      "input"
    >
  >;
  updateAwsMockEcrImageByAwscontainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["UpdateAwsMockEcrImagePayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateAwsMockEcrImageByAwscontainerimageexecutorPtrIdArgs,
      "input"
    >
  >;
  updateAwsMockS3FileByAwsfileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["UpdateAwsMockS3FilePayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateAwsMockS3FileByAwsfileexecutorPtrIdArgs,
      "input"
    >
  >;
  updateAwsS3FileByFileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["UpdateAwsS3FilePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAwsS3FileByFileexecutorPtrIdArgs, "input">
  >;
  updateContainerImageByExecutorId?: Resolver<
    Maybe<ResolversTypes["UpdateContainerImagePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateContainerImageByExecutorIdArgs, "input">
  >;
  updateContainerImageById?: Resolver<
    Maybe<ResolversTypes["UpdateContainerImagePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateContainerImageByIdArgs, "input">
  >;
  updateContainerImageByNameAndTag?: Resolver<
    Maybe<ResolversTypes["UpdateContainerImagePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateContainerImageByNameAndTagArgs, "input">
  >;
  updateContainerImageByRepositoryAndTag?: Resolver<
    Maybe<ResolversTypes["UpdateContainerImagePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateContainerImageByRepositoryAndTagArgs, "input">
  >;
  updateContainerImageByToolboxVersionId?: Resolver<
    Maybe<ResolversTypes["UpdateContainerImagePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateContainerImageByToolboxVersionIdArgs, "input">
  >;
  updateCostById?: Resolver<
    Maybe<ResolversTypes["UpdateCostPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCostByIdArgs, "input">
  >;
  updateDatasetById?: Resolver<
    Maybe<ResolversTypes["UpdateDatasetPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDatasetByIdArgs, "input">
  >;
  updateDatasetByProjectIdAndPrefix?: Resolver<
    Maybe<ResolversTypes["UpdateDatasetPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDatasetByProjectIdAndPrefixArgs, "input">
  >;
  updateDatasetByRecordingsTableId?: Resolver<
    Maybe<ResolversTypes["UpdateDatasetPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDatasetByRecordingsTableIdArgs, "input">
  >;
  updateDatasetByTenantIdAndKey?: Resolver<
    Maybe<ResolversTypes["UpdateDatasetPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDatasetByTenantIdAndKeyArgs, "input">
  >;
  updateDatasetMetadatumById?: Resolver<
    Maybe<ResolversTypes["UpdateDatasetMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDatasetMetadatumByIdArgs, "input">
  >;
  updateDatasetRecordingsTableById?: Resolver<
    Maybe<ResolversTypes["UpdateDatasetRecordingsTablePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDatasetRecordingsTableByIdArgs, "input">
  >;
  updateDatasetRecordingsTableColumnById?: Resolver<
    Maybe<ResolversTypes["UpdateDatasetRecordingsTableColumnPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDatasetRecordingsTableColumnByIdArgs, "input">
  >;
  updateDatasetRecordingsTableColumnOrderById?: Resolver<
    Maybe<ResolversTypes["UpdateDatasetRecordingsTableColumnOrderPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateDatasetRecordingsTableColumnOrderByIdArgs,
      "input"
    >
  >;
  updateDatasetVersionById?: Resolver<
    Maybe<ResolversTypes["UpdateDatasetVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDatasetVersionByIdArgs, "input">
  >;
  updateDjangoAdminLogById?: Resolver<
    Maybe<ResolversTypes["UpdateDjangoAdminLogPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDjangoAdminLogByIdArgs, "input">
  >;
  updateDjangoContentTypeByAppLabelAndModel?: Resolver<
    Maybe<ResolversTypes["UpdateDjangoContentTypePayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateDjangoContentTypeByAppLabelAndModelArgs,
      "input"
    >
  >;
  updateDjangoContentTypeById?: Resolver<
    Maybe<ResolversTypes["UpdateDjangoContentTypePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDjangoContentTypeByIdArgs, "input">
  >;
  updateDjangoMigrationById?: Resolver<
    Maybe<ResolversTypes["UpdateDjangoMigrationPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDjangoMigrationByIdArgs, "input">
  >;
  updateDjangoSesSesstatByDate?: Resolver<
    Maybe<ResolversTypes["UpdateDjangoSesSesstatPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDjangoSesSesstatByDateArgs, "input">
  >;
  updateDjangoSesSesstatById?: Resolver<
    Maybe<ResolversTypes["UpdateDjangoSesSesstatPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDjangoSesSesstatByIdArgs, "input">
  >;
  updateDjangoSessionBySessionKey?: Resolver<
    Maybe<ResolversTypes["UpdateDjangoSessionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDjangoSessionBySessionKeyArgs, "input">
  >;
  updateDrsFileexecutorById?: Resolver<
    Maybe<ResolversTypes["UpdateDrsFileexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDrsFileexecutorByIdArgs, "input">
  >;
  updateDrsMockfileexecutorByFileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["UpdateDrsMockfileexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateDrsMockfileexecutorByFileexecutorPtrIdArgs,
      "input"
    >
  >;
  updateEulaVersionById?: Resolver<
    Maybe<ResolversTypes["UpdateEulaVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateEulaVersionByIdArgs, "input">
  >;
  updateEulaVersionByVersion?: Resolver<
    Maybe<ResolversTypes["UpdateEulaVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateEulaVersionByVersionArgs, "input">
  >;
  updateEulaVersionUserAcceptanceById?: Resolver<
    Maybe<ResolversTypes["UpdateEulaVersionUserAcceptancePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateEulaVersionUserAcceptanceByIdArgs, "input">
  >;
  updateEulaVersionUserAcceptanceByUserIdAndEulaVersionId?: Resolver<
    Maybe<ResolversTypes["UpdateEulaVersionUserAcceptancePayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateEulaVersionUserAcceptanceByUserIdAndEulaVersionIdArgs,
      "input"
    >
  >;
  updateExplorerQueryById?: Resolver<
    Maybe<ResolversTypes["UpdateExplorerQueryPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateExplorerQueryByIdArgs, "input">
  >;
  updateExplorerQueryfavoriteById?: Resolver<
    Maybe<ResolversTypes["UpdateExplorerQueryfavoritePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateExplorerQueryfavoriteByIdArgs, "input">
  >;
  updateExplorerQueryfavoriteByQueryIdAndUserId?: Resolver<
    Maybe<ResolversTypes["UpdateExplorerQueryfavoritePayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateExplorerQueryfavoriteByQueryIdAndUserIdArgs,
      "input"
    >
  >;
  updateExplorerQuerylogById?: Resolver<
    Maybe<ResolversTypes["UpdateExplorerQuerylogPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateExplorerQuerylogByIdArgs, "input">
  >;
  updateFileActivityById?: Resolver<
    Maybe<ResolversTypes["UpdateFileActivityPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateFileActivityByIdArgs, "input">
  >;
  updateFileById?: Resolver<
    Maybe<ResolversTypes["UpdateFilePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateFileByIdArgs, "input">
  >;
  updateFileMetadatumById?: Resolver<
    Maybe<ResolversTypes["UpdateFileMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateFileMetadatumByIdArgs, "input">
  >;
  updateFileOutputGroupById?: Resolver<
    Maybe<ResolversTypes["UpdateFileOutputGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateFileOutputGroupByIdArgs, "input">
  >;
  updateFileOutputGroupByTaskId?: Resolver<
    Maybe<ResolversTypes["UpdateFileOutputGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateFileOutputGroupByTaskIdArgs, "input">
  >;
  updateFileOutputGroupMetadatumById?: Resolver<
    Maybe<ResolversTypes["UpdateFileOutputGroupMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateFileOutputGroupMetadatumByIdArgs, "input">
  >;
  updateFileRecordingGroupById?: Resolver<
    Maybe<ResolversTypes["UpdateFileRecordingGroupPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateFileRecordingGroupByIdArgs, "input">
  >;
  updateFileRecordingGroupMetadatumById?: Resolver<
    Maybe<ResolversTypes["UpdateFileRecordingGroupMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateFileRecordingGroupMetadatumByIdArgs, "input">
  >;
  updateMetadataValueById?: Resolver<
    Maybe<ResolversTypes["UpdateMetadataValuePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateMetadataValueByIdArgs, "input">
  >;
  updateMetadatumById?: Resolver<
    Maybe<ResolversTypes["UpdateMetadatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateMetadatumByIdArgs, "input">
  >;
  updateNotificationById?: Resolver<
    Maybe<ResolversTypes["UpdateNotificationPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateNotificationByIdArgs, "input">
  >;
  updateOutputGroupFileById?: Resolver<
    Maybe<ResolversTypes["UpdateOutputGroupFilePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateOutputGroupFileByIdArgs, "input">
  >;
  updateRecordingGroupFileById?: Resolver<
    Maybe<ResolversTypes["UpdateRecordingGroupFilePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateRecordingGroupFileByIdArgs, "input">
  >;
  updateRunById?: Resolver<
    Maybe<ResolversTypes["UpdateRunPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateRunByIdArgs, "input">
  >;
  updateTagById?: Resolver<
    Maybe<ResolversTypes["UpdateTagPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTagByIdArgs, "input">
  >;
  updateTaskActivityById?: Resolver<
    Maybe<ResolversTypes["UpdateTaskActivityPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTaskActivityByIdArgs, "input">
  >;
  updateTaskActivityByTaskId?: Resolver<
    Maybe<ResolversTypes["UpdateTaskActivityPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTaskActivityByTaskIdArgs, "input">
  >;
  updateTaskByExecutorId?: Resolver<
    Maybe<ResolversTypes["UpdateTaskPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTaskByExecutorIdArgs, "input">
  >;
  updateTaskById?: Resolver<
    Maybe<ResolversTypes["UpdateTaskPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTaskByIdArgs, "input">
  >;
  updateTaskResourceById?: Resolver<
    Maybe<ResolversTypes["UpdateTaskResourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTaskResourceByIdArgs, "input">
  >;
  updateTaskSourceById?: Resolver<
    Maybe<ResolversTypes["UpdateTaskSourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTaskSourceByIdArgs, "input">
  >;
  updateTaskStatusLogById?: Resolver<
    Maybe<ResolversTypes["UpdateTaskStatusLogPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTaskStatusLogByIdArgs, "input">
  >;
  updateTaskStatusLogByTaskIdAndStatus?: Resolver<
    Maybe<ResolversTypes["UpdateTaskStatusLogPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTaskStatusLogByTaskIdAndStatusArgs, "input">
  >;
  updateTenantById?: Resolver<
    Maybe<ResolversTypes["UpdateTenantPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTenantByIdArgs, "input">
  >;
  updateTenantByKey?: Resolver<
    Maybe<ResolversTypes["UpdateTenantPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTenantByKeyArgs, "input">
  >;
  updateTenantByName?: Resolver<
    Maybe<ResolversTypes["UpdateTenantPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTenantByNameArgs, "input">
  >;
  updateTenantContractAllowanceById?: Resolver<
    Maybe<ResolversTypes["UpdateTenantContractAllowancePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTenantContractAllowanceByIdArgs, "input">
  >;
  updateTenantContractAllowanceByTenantContractTransactionIdAndQuotaType?: Resolver<
    Maybe<ResolversTypes["UpdateTenantContractAllowancePayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeArgs,
      "input"
    >
  >;
  updateTenantContractById?: Resolver<
    Maybe<ResolversTypes["UpdateTenantContractPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTenantContractByIdArgs, "input">
  >;
  updateTenantContractByName?: Resolver<
    Maybe<ResolversTypes["UpdateTenantContractPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTenantContractByNameArgs, "input">
  >;
  updateTenantContractTransactionById?: Resolver<
    Maybe<ResolversTypes["UpdateTenantContractTransactionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTenantContractTransactionByIdArgs, "input">
  >;
  updateTenantMockuserexecutorByUserexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["UpdateTenantMockuserexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateTenantMockuserexecutorByUserexecutorPtrIdArgs,
      "input"
    >
  >;
  updateTenantMonthlyConsumedResourceById?: Resolver<
    Maybe<ResolversTypes["UpdateTenantMonthlyConsumedResourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTenantMonthlyConsumedResourceByIdArgs, "input">
  >;
  updateTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract?: Resolver<
    Maybe<ResolversTypes["UpdateTenantMonthlyConsumedResourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractArgs,
      "input"
    >
  >;
  updateTenantUserexecutorById?: Resolver<
    Maybe<ResolversTypes["UpdateTenantUserexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTenantUserexecutorByIdArgs, "input">
  >;
  updateTesContainerimageexecutorById?: Resolver<
    Maybe<ResolversTypes["UpdateTesContainerimageexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTesContainerimageexecutorByIdArgs, "input">
  >;
  updateTesMockcontainerimageexecutorByContainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["UpdateTesMockcontainerimageexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateTesMockcontainerimageexecutorByContainerimageexecutorPtrIdArgs,
      "input"
    >
  >;
  updateTesMocktaskexecutorByTaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["UpdateTesMocktaskexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateTesMocktaskexecutorByTaskexecutorPtrIdArgs,
      "input"
    >
  >;
  updateTesTaskexecutorById?: Resolver<
    Maybe<ResolversTypes["UpdateTesTaskexecutorPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTesTaskexecutorByIdArgs, "input">
  >;
  updateToolById?: Resolver<
    Maybe<ResolversTypes["UpdateToolPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolByIdArgs, "input">
  >;
  updateToolByKey?: Resolver<
    Maybe<ResolversTypes["UpdateToolPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolByKeyArgs, "input">
  >;
  updateToolCategoryById?: Resolver<
    Maybe<ResolversTypes["UpdateToolCategoryPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolCategoryByIdArgs, "input">
  >;
  updateToolCategoryByKey?: Resolver<
    Maybe<ResolversTypes["UpdateToolCategoryPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolCategoryByKeyArgs, "input">
  >;
  updateToolDisplayCategoryById?: Resolver<
    Maybe<ResolversTypes["UpdateToolDisplayCategoryPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolDisplayCategoryByIdArgs, "input">
  >;
  updateToolDisplayCategoryByToolIdAndToolcategoryId?: Resolver<
    Maybe<ResolversTypes["UpdateToolDisplayCategoryPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateToolDisplayCategoryByToolIdAndToolcategoryIdArgs,
      "input"
    >
  >;
  updateToolParameterById?: Resolver<
    Maybe<ResolversTypes["UpdateToolParameterPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolParameterByIdArgs, "input">
  >;
  updateToolResourceById?: Resolver<
    Maybe<ResolversTypes["UpdateToolResourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolResourceByIdArgs, "input">
  >;
  updateToolResourceByTypeAndToolVersionId?: Resolver<
    Maybe<ResolversTypes["UpdateToolResourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolResourceByTypeAndToolVersionIdArgs, "input">
  >;
  updateToolResultById?: Resolver<
    Maybe<ResolversTypes["UpdateToolResultPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolResultByIdArgs, "input">
  >;
  updateToolSecretById?: Resolver<
    Maybe<ResolversTypes["UpdateToolSecretPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolSecretByIdArgs, "input">
  >;
  updateToolSourceById?: Resolver<
    Maybe<ResolversTypes["UpdateToolSourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolSourceByIdArgs, "input">
  >;
  updateToolTenantById?: Resolver<
    Maybe<ResolversTypes["UpdateToolTenantPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolTenantByIdArgs, "input">
  >;
  updateToolTenantByToolIdAndTenantId?: Resolver<
    Maybe<ResolversTypes["UpdateToolTenantPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolTenantByToolIdAndTenantIdArgs, "input">
  >;
  updateToolVersionById?: Resolver<
    Maybe<ResolversTypes["UpdateToolVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolVersionByIdArgs, "input">
  >;
  updateToolVersionByToolIdAndVersion?: Resolver<
    Maybe<ResolversTypes["UpdateToolVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolVersionByToolIdAndVersionArgs, "input">
  >;
  updateToolboxById?: Resolver<
    Maybe<ResolversTypes["UpdateToolboxPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolboxByIdArgs, "input">
  >;
  updateToolboxByKey?: Resolver<
    Maybe<ResolversTypes["UpdateToolboxPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolboxByKeyArgs, "input">
  >;
  updateToolboxExitcodeByCode?: Resolver<
    Maybe<ResolversTypes["UpdateToolboxExitcodePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolboxExitcodeByCodeArgs, "input">
  >;
  updateToolboxExitcodeById?: Resolver<
    Maybe<ResolversTypes["UpdateToolboxExitcodePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolboxExitcodeByIdArgs, "input">
  >;
  updateToolboxTenantById?: Resolver<
    Maybe<ResolversTypes["UpdateToolboxTenantPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolboxTenantByIdArgs, "input">
  >;
  updateToolboxTenantByToolboxIdAndTenantId?: Resolver<
    Maybe<ResolversTypes["UpdateToolboxTenantPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateToolboxTenantByToolboxIdAndTenantIdArgs,
      "input"
    >
  >;
  updateToolboxVersionById?: Resolver<
    Maybe<ResolversTypes["UpdateToolboxVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolboxVersionByIdArgs, "input">
  >;
  updateToolboxVersionByToolboxIdAndVersion?: Resolver<
    Maybe<ResolversTypes["UpdateToolboxVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateToolboxVersionByToolboxIdAndVersionArgs,
      "input"
    >
  >;
  updateToolboxVersionExitcodeById?: Resolver<
    Maybe<ResolversTypes["UpdateToolboxVersionExitcodePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolboxVersionExitcodeByIdArgs, "input">
  >;
  updateToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeId?: Resolver<
    Maybe<ResolversTypes["UpdateToolboxVersionExitcodePayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdArgs,
      "input"
    >
  >;
  updateToolboxVersionToolVersionById?: Resolver<
    Maybe<ResolversTypes["UpdateToolboxVersionToolVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateToolboxVersionToolVersionByIdArgs, "input">
  >;
  updateToolboxVersionToolVersionByToolboxversionIdAndToolversionId?: Resolver<
    Maybe<ResolversTypes["UpdateToolboxVersionToolVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateToolboxVersionToolVersionByToolboxversionIdAndToolversionIdArgs,
      "input"
    >
  >;
  updateWorkflowById?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWorkflowByIdArgs, "input">
  >;
  updateWorkflowDatumById?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowDatumPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWorkflowDatumByIdArgs, "input">
  >;
  updateWorkflowResultById?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowResultPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWorkflowResultByIdArgs, "input">
  >;
  updateWorkflowResultTagById?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowResultTagPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWorkflowResultTagByIdArgs, "input">
  >;
  updateWorkflowResultTagByResultIdAndTagId?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowResultTagPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateWorkflowResultTagByResultIdAndTagIdArgs,
      "input"
    >
  >;
  updateWorkflowResultWorkflowVersionById?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowResultWorkflowVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWorkflowResultWorkflowVersionByIdArgs, "input">
  >;
  updateWorkflowResultWorkflowVersionByResultIdAndWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowResultWorkflowVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdArgs,
      "input"
    >
  >;
  updateWorkflowSectionBlockById?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowSectionBlockPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWorkflowSectionBlockByIdArgs, "input">
  >;
  updateWorkflowSectionBlockSectionByBlockIdAndSectionId?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowSectionBlockSectionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateWorkflowSectionBlockSectionByBlockIdAndSectionIdArgs,
      "input"
    >
  >;
  updateWorkflowSectionBlockSectionById?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowSectionBlockSectionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWorkflowSectionBlockSectionByIdArgs, "input">
  >;
  updateWorkflowSectionById?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowSectionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWorkflowSectionByIdArgs, "input">
  >;
  updateWorkflowSectionWorkflowVersionById?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowSectionWorkflowVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWorkflowSectionWorkflowVersionByIdArgs, "input">
  >;
  updateWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowSectionWorkflowVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdArgs,
      "input"
    >
  >;
  updateWorkflowSourceById?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowSourcePayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWorkflowSourceByIdArgs, "input">
  >;
  updateWorkflowSourceTagById?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowSourceTagPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWorkflowSourceTagByIdArgs, "input">
  >;
  updateWorkflowSourceTagBySourceIdAndTagId?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowSourceTagPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateWorkflowSourceTagBySourceIdAndTagIdArgs,
      "input"
    >
  >;
  updateWorkflowSourceWorkflowVersionById?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowSourceWorkflowVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWorkflowSourceWorkflowVersionByIdArgs, "input">
  >;
  updateWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowSourceWorkflowVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdArgs,
      "input"
    >
  >;
  updateWorkflowVersionById?: Resolver<
    Maybe<ResolversTypes["UpdateWorkflowVersionPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWorkflowVersionByIdArgs, "input">
  >;
};

export type NotificationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["Notification"] = ResolversParentTypes["Notification"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  fileProcessingNotificationById?: Resolver<
    Maybe<ResolversTypes["FileProcessingNotification"]>,
    ParentType,
    ContextType
  >;
  fileProcessingNotificationsById?: Resolver<
    ResolversTypes["FileProcessingNotificationsConnection"],
    ParentType,
    ContextType,
    RequireFields<NotificationFileProcessingNotificationsByIdArgs, "orderBy">
  >;
  hasSeen?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  projectClonedNotificationById?: Resolver<
    Maybe<ResolversTypes["ProjectClonedNotification"]>,
    ParentType,
    ContextType
  >;
  projectClonedNotificationsById?: Resolver<
    ResolversTypes["ProjectClonedNotificationsConnection"],
    ParentType,
    ContextType,
    RequireFields<NotificationProjectClonedNotificationsByIdArgs, "orderBy">
  >;
  projectSharedNotificationById?: Resolver<
    Maybe<ResolversTypes["ProjectSharedNotification"]>,
    ParentType,
    ContextType
  >;
  projectSharedNotificationsById?: Resolver<
    ResolversTypes["ProjectSharedNotificationsConnection"],
    ParentType,
    ContextType,
    RequireFields<NotificationProjectSharedNotificationsByIdArgs, "orderBy">
  >;
  roleChangeNotificationById?: Resolver<
    Maybe<ResolversTypes["RoleChangeNotification"]>,
    ParentType,
    ContextType
  >;
  roleChangeNotificationsById?: Resolver<
    ResolversTypes["RoleChangeNotificationsConnection"],
    ParentType,
    ContextType,
    RequireFields<NotificationRoleChangeNotificationsByIdArgs, "orderBy">
  >;
  tenantUserMembershipNotificationById?: Resolver<
    Maybe<ResolversTypes["TenantUserMembershipNotification"]>,
    ParentType,
    ContextType
  >;
  tenantUserMembershipNotificationsById?: Resolver<
    ResolversTypes["TenantUserMembershipNotificationsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      NotificationTenantUserMembershipNotificationsByIdArgs,
      "orderBy"
    >
  >;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["NotificationsConnection"] = ResolversParentTypes["NotificationsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["NotificationsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["Notification"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["NotificationsEdge"] = ResolversParentTypes["NotificationsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["Notification"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OutputGroupFileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["OutputGroupFile"] = ResolversParentTypes["OutputGroupFile"],
> = {
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  fileOutputGroupByFileOutputGroupId?: Resolver<
    Maybe<ResolversTypes["FileOutputGroup"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroupId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OutputGroupFilesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["OutputGroupFilesConnection"] = ResolversParentTypes["OutputGroupFilesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["OutputGroupFilesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["OutputGroupFile"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OutputGroupFilesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["OutputGroupFilesEdge"] = ResolversParentTypes["OutputGroupFilesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["OutputGroupFile"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["PageInfo"] = ResolversParentTypes["PageInfo"],
> = {
  endCursor?: Resolver<
    Maybe<ResolversTypes["Cursor"]>,
    ParentType,
    ContextType
  >;
  hasNextPage?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  hasPreviousPage?: Resolver<
    ResolversTypes["Boolean"],
    ParentType,
    ContextType
  >;
  startCursor?: Resolver<
    Maybe<ResolversTypes["Cursor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["Project"] = ResolversParentTypes["Project"],
> = {
  activeFiles?: Resolver<
    ResolversTypes["FilesConnection"],
    ParentType,
    ContextType,
    Partial<ProjectActiveFilesArgs>
  >;
  activeStorageSize?: Resolver<
    Maybe<ResolversTypes["BigInt"]>,
    ParentType,
    ContextType
  >;
  analysisTableGroupsByProjectId?: Resolver<
    ResolversTypes["AnalysisTableGroupsConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectAnalysisTableGroupsByProjectIdArgs, "orderBy">
  >;
  analysisTableRowAttachResultsByProjectId?: Resolver<
    ResolversTypes["AnalysisTableRowAttachResultsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ProjectAnalysisTableRowAttachResultsByProjectIdArgs,
      "orderBy"
    >
  >;
  analysisTableRowsByProjectId?: Resolver<
    ResolversTypes["AnalysisTableRowsConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectAnalysisTableRowsByProjectIdArgs, "orderBy">
  >;
  analysisTablesByProjectId?: Resolver<
    ResolversTypes["AnalysisTablesConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectAnalysisTablesByProjectIdArgs, "orderBy">
  >;
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  archivedStorageSize?: Resolver<
    Maybe<ResolversTypes["BigInt"]>,
    ParentType,
    ContextType
  >;
  datasetMetadataByProjectId?: Resolver<
    ResolversTypes["DatasetMetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectDatasetMetadataByProjectIdArgs, "orderBy">
  >;
  datasetRecordingsTableColumnOrdersByProjectId?: Resolver<
    ResolversTypes["DatasetRecordingsTableColumnOrdersConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ProjectDatasetRecordingsTableColumnOrdersByProjectIdArgs,
      "orderBy"
    >
  >;
  datasetRecordingsTableColumnsByProjectId?: Resolver<
    ResolversTypes["DatasetRecordingsTableColumnsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ProjectDatasetRecordingsTableColumnsByProjectIdArgs,
      "orderBy"
    >
  >;
  datasetRecordingsTablesByProjectId?: Resolver<
    ResolversTypes["DatasetRecordingsTablesConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectDatasetRecordingsTablesByProjectIdArgs, "orderBy">
  >;
  datasetVersionsByProjectId?: Resolver<
    ResolversTypes["DatasetVersionsConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectDatasetVersionsByProjectIdArgs, "orderBy">
  >;
  datasetsByProjectId?: Resolver<
    ResolversTypes["DatasetsConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectDatasetsByProjectIdArgs, "orderBy">
  >;
  dateArchived?: Resolver<
    Maybe<ResolversTypes["Datetime"]>,
    ParentType,
    ContextType
  >;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  dateDataDeleted?: Resolver<
    Maybe<ResolversTypes["Datetime"]>,
    ParentType,
    ContextType
  >;
  dateDeleted?: Resolver<
    Maybe<ResolversTypes["Datetime"]>,
    ParentType,
    ContextType
  >;
  defaultUserAccessLevel?: Resolver<
    ResolversTypes["Int"],
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  fileActivitiesByProjectId?: Resolver<
    ResolversTypes["FileActivitiesConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectFileActivitiesByProjectIdArgs, "orderBy">
  >;
  fileMetadataByProjectId?: Resolver<
    ResolversTypes["FileMetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectFileMetadataByProjectIdArgs, "orderBy">
  >;
  fileOutputGroupMetadataByProjectId?: Resolver<
    ResolversTypes["FileOutputGroupMetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectFileOutputGroupMetadataByProjectIdArgs, "orderBy">
  >;
  fileOutputGroupsByProjectId?: Resolver<
    ResolversTypes["FileOutputGroupsConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectFileOutputGroupsByProjectIdArgs, "orderBy">
  >;
  fileRecordingGroupMetadataByProjectId?: Resolver<
    ResolversTypes["FileRecordingGroupMetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectFileRecordingGroupMetadataByProjectIdArgs, "orderBy">
  >;
  fileRecordingGroupsByProjectId?: Resolver<
    ResolversTypes["FileRecordingGroupsConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectFileRecordingGroupsByProjectIdArgs, "orderBy">
  >;
  filesByProjectId?: Resolver<
    ResolversTypes["FilesConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectFilesByProjectIdArgs, "orderBy">
  >;
  icon?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  iconColor?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  iconText?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  key?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  metadataByProjectId?: Resolver<
    ResolversTypes["MetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectMetadataByProjectIdArgs, "orderBy">
  >;
  metadataValuesByProjectId?: Resolver<
    ResolversTypes["MetadataValuesConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectMetadataValuesByProjectIdArgs, "orderBy">
  >;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  outputGroupFilesByProjectId?: Resolver<
    ResolversTypes["OutputGroupFilesConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectOutputGroupFilesByProjectIdArgs, "orderBy">
  >;
  prefix?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  projectClonedNotificationsByProjectId?: Resolver<
    ResolversTypes["ProjectClonedNotificationsConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectProjectClonedNotificationsByProjectIdArgs, "orderBy">
  >;
  projectSharedNotificationsByProjectId?: Resolver<
    ResolversTypes["ProjectSharedNotificationsConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectProjectSharedNotificationsByProjectIdArgs, "orderBy">
  >;
  recordingGroupFilesByProjectId?: Resolver<
    ResolversTypes["RecordingGroupFilesConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectRecordingGroupFilesByProjectIdArgs, "orderBy">
  >;
  shortDescription?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  tasksByProjectId?: Resolver<
    ResolversTypes["TasksConnection"],
    ParentType,
    ContextType,
    RequireFields<ProjectTasksByProjectIdArgs, "orderBy">
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  usedCredits?: Resolver<
    Maybe<ResolversTypes["Float"]>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectClonedNotificationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ProjectClonedNotification"] = ResolversParentTypes["ProjectClonedNotification"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  notificationById?: Resolver<
    Maybe<ResolversTypes["Notification"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectClonedNotificationsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ProjectClonedNotificationsConnection"] = ResolversParentTypes["ProjectClonedNotificationsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ProjectClonedNotificationsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ProjectClonedNotification"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectClonedNotificationsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ProjectClonedNotificationsEdge"] = ResolversParentTypes["ProjectClonedNotificationsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["ProjectClonedNotification"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectSharedNotificationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ProjectSharedNotification"] = ResolversParentTypes["ProjectSharedNotification"],
> = {
  accessLevel?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  applicationUserBySharerId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  notificationById?: Resolver<
    Maybe<ResolversTypes["Notification"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  sharerId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectSharedNotificationsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ProjectSharedNotificationsConnection"] = ResolversParentTypes["ProjectSharedNotificationsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ProjectSharedNotificationsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ProjectSharedNotification"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectSharedNotificationsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ProjectSharedNotificationsEdge"] = ResolversParentTypes["ProjectSharedNotificationsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["ProjectSharedNotification"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ProjectsConnection"] = ResolversParentTypes["ProjectsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ProjectsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes["Project"]>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ProjectsEdge"] = ResolversParentTypes["ProjectsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["Project"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["Query"] = ResolversParentTypes["Query"],
> = {
  allAnalysisTableGroups?: Resolver<
    Maybe<ResolversTypes["AnalysisTableGroupsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllAnalysisTableGroupsArgs, "orderBy">
  >;
  allAnalysisTableRowAttachResults?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRowAttachResultsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllAnalysisTableRowAttachResultsArgs, "orderBy">
  >;
  allAnalysisTableRows?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRowsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllAnalysisTableRowsArgs, "orderBy">
  >;
  allAnalysisTables?: Resolver<
    Maybe<ResolversTypes["AnalysisTablesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllAnalysisTablesArgs, "orderBy">
  >;
  allApplicationUserGroups?: Resolver<
    Maybe<ResolversTypes["ApplicationUserGroupsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllApplicationUserGroupsArgs, "orderBy">
  >;
  allApplicationUserMemberships?: Resolver<
    Maybe<ResolversTypes["ApplicationUserMembershipsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllApplicationUserMembershipsArgs, "orderBy">
  >;
  allApplicationUserMetadata?: Resolver<
    Maybe<ResolversTypes["ApplicationUserMetadataConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllApplicationUserMetadataArgs, "orderBy">
  >;
  allApplicationUserSessions?: Resolver<
    Maybe<ResolversTypes["ApplicationUserSessionsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllApplicationUserSessionsArgs, "orderBy">
  >;
  allApplicationUserUserPermissions?: Resolver<
    Maybe<ResolversTypes["ApplicationUserUserPermissionsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllApplicationUserUserPermissionsArgs, "orderBy">
  >;
  allAuthGroupPermissions?: Resolver<
    Maybe<ResolversTypes["AuthGroupPermissionsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllAuthGroupPermissionsArgs, "orderBy">
  >;
  allAuthGroups?: Resolver<
    Maybe<ResolversTypes["AuthGroupsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllAuthGroupsArgs, "orderBy">
  >;
  allAuthPermissions?: Resolver<
    Maybe<ResolversTypes["AuthPermissionsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllAuthPermissionsArgs, "orderBy">
  >;
  allAvailableNonRestrictedProjects?: Resolver<
    Maybe<ResolversTypes["AvailableNonRestrictedProjectsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllAvailableNonRestrictedProjectsArgs, "orderBy">
  >;
  allAvatars?: Resolver<
    Maybe<ResolversTypes["AvatarsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllAvatarsArgs, "orderBy">
  >;
  allAwsBatchTasks?: Resolver<
    Maybe<ResolversTypes["AwsBatchTasksConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllAwsBatchTasksArgs, "orderBy">
  >;
  allAwsCognitoUsers?: Resolver<
    Maybe<ResolversTypes["AwsCognitoUsersConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllAwsCognitoUsersArgs, "orderBy">
  >;
  allAwsEcrImages?: Resolver<
    Maybe<ResolversTypes["AwsEcrImagesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllAwsEcrImagesArgs, "orderBy">
  >;
  allAwsMockBatchTasks?: Resolver<
    Maybe<ResolversTypes["AwsMockBatchTasksConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllAwsMockBatchTasksArgs, "orderBy">
  >;
  allAwsMockEcrImages?: Resolver<
    Maybe<ResolversTypes["AwsMockEcrImagesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllAwsMockEcrImagesArgs, "orderBy">
  >;
  allAwsMockS3Files?: Resolver<
    Maybe<ResolversTypes["AwsMockS3FilesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllAwsMockS3FilesArgs, "orderBy">
  >;
  allAwsS3Files?: Resolver<
    Maybe<ResolversTypes["AwsS3FilesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllAwsS3FilesArgs, "orderBy">
  >;
  allContainerImages?: Resolver<
    Maybe<ResolversTypes["ContainerImagesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllContainerImagesArgs, "orderBy">
  >;
  allCosts?: Resolver<
    Maybe<ResolversTypes["CostsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllCostsArgs, "orderBy">
  >;
  allCurrentApplicationUsers?: Resolver<
    Maybe<ResolversTypes["CurrentApplicationUsersConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllCurrentApplicationUsersArgs, "orderBy">
  >;
  allCurrentUserMemberships?: Resolver<
    Maybe<ResolversTypes["CurrentUserMembershipsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllCurrentUserMembershipsArgs, "orderBy">
  >;
  allDatasetMetadata?: Resolver<
    Maybe<ResolversTypes["DatasetMetadataConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllDatasetMetadataArgs, "orderBy">
  >;
  allDatasetRecordingsTableColumnOrders?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumnOrdersConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllDatasetRecordingsTableColumnOrdersArgs, "orderBy">
  >;
  allDatasetRecordingsTableColumns?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumnsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllDatasetRecordingsTableColumnsArgs, "orderBy">
  >;
  allDatasetRecordingsTables?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTablesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllDatasetRecordingsTablesArgs, "orderBy">
  >;
  allDatasetVersions?: Resolver<
    Maybe<ResolversTypes["DatasetVersionsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllDatasetVersionsArgs, "orderBy">
  >;
  allDatasets?: Resolver<
    Maybe<ResolversTypes["DatasetsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllDatasetsArgs, "orderBy">
  >;
  allDjangoAdminLogs?: Resolver<
    Maybe<ResolversTypes["DjangoAdminLogsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllDjangoAdminLogsArgs, "orderBy">
  >;
  allDjangoContentTypes?: Resolver<
    Maybe<ResolversTypes["DjangoContentTypesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllDjangoContentTypesArgs, "orderBy">
  >;
  allDjangoMigrations?: Resolver<
    Maybe<ResolversTypes["DjangoMigrationsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllDjangoMigrationsArgs, "orderBy">
  >;
  allDjangoSesSesstats?: Resolver<
    Maybe<ResolversTypes["DjangoSesSesstatsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllDjangoSesSesstatsArgs, "orderBy">
  >;
  allDjangoSessions?: Resolver<
    Maybe<ResolversTypes["DjangoSessionsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllDjangoSessionsArgs, "orderBy">
  >;
  allDrsFileexecutors?: Resolver<
    Maybe<ResolversTypes["DrsFileexecutorsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllDrsFileexecutorsArgs, "orderBy">
  >;
  allDrsMockfileexecutors?: Resolver<
    Maybe<ResolversTypes["DrsMockfileexecutorsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllDrsMockfileexecutorsArgs, "orderBy">
  >;
  allEulaVersionUserAcceptances?: Resolver<
    Maybe<ResolversTypes["EulaVersionUserAcceptancesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllEulaVersionUserAcceptancesArgs, "orderBy">
  >;
  allEulaVersions?: Resolver<
    Maybe<ResolversTypes["EulaVersionsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllEulaVersionsArgs, "orderBy">
  >;
  allExplorerQueries?: Resolver<
    Maybe<ResolversTypes["ExplorerQueriesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllExplorerQueriesArgs, "orderBy">
  >;
  allExplorerQueryfavorites?: Resolver<
    Maybe<ResolversTypes["ExplorerQueryfavoritesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllExplorerQueryfavoritesArgs, "orderBy">
  >;
  allExplorerQuerylogs?: Resolver<
    Maybe<ResolversTypes["ExplorerQuerylogsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllExplorerQuerylogsArgs, "orderBy">
  >;
  allFileActivities?: Resolver<
    Maybe<ResolversTypes["FileActivitiesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllFileActivitiesArgs, "orderBy">
  >;
  allFileMetadata?: Resolver<
    Maybe<ResolversTypes["FileMetadataConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllFileMetadataArgs, "orderBy">
  >;
  allFileOutputGroupMetadata?: Resolver<
    Maybe<ResolversTypes["FileOutputGroupMetadataConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllFileOutputGroupMetadataArgs, "orderBy">
  >;
  allFileOutputGroups?: Resolver<
    Maybe<ResolversTypes["FileOutputGroupsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllFileOutputGroupsArgs, "orderBy">
  >;
  allFileProcessingNotifications?: Resolver<
    Maybe<ResolversTypes["FileProcessingNotificationsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllFileProcessingNotificationsArgs, "orderBy">
  >;
  allFileRecordingGroupMetadata?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroupMetadataConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllFileRecordingGroupMetadataArgs, "orderBy">
  >;
  allFileRecordingGroups?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroupsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllFileRecordingGroupsArgs, "orderBy">
  >;
  allFiles?: Resolver<
    Maybe<ResolversTypes["FilesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllFilesArgs, "orderBy">
  >;
  allNotifications?: Resolver<
    Maybe<ResolversTypes["NotificationsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllNotificationsArgs, "orderBy">
  >;
  allOutputGroupFiles?: Resolver<
    Maybe<ResolversTypes["OutputGroupFilesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllOutputGroupFilesArgs, "orderBy">
  >;
  allProjectClonedNotifications?: Resolver<
    Maybe<ResolversTypes["ProjectClonedNotificationsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllProjectClonedNotificationsArgs, "orderBy">
  >;
  allProjectSharedNotifications?: Resolver<
    Maybe<ResolversTypes["ProjectSharedNotificationsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllProjectSharedNotificationsArgs, "orderBy">
  >;
  allProjects?: Resolver<
    Maybe<ResolversTypes["ProjectsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllProjectsArgs, "orderBy">
  >;
  allRecordingGroupFiles?: Resolver<
    Maybe<ResolversTypes["RecordingGroupFilesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllRecordingGroupFilesArgs, "orderBy">
  >;
  allRoleChangeNotifications?: Resolver<
    Maybe<ResolversTypes["RoleChangeNotificationsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllRoleChangeNotificationsArgs, "orderBy">
  >;
  allRuns?: Resolver<
    Maybe<ResolversTypes["RunsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllRunsArgs, "orderBy">
  >;
  allTags?: Resolver<
    Maybe<ResolversTypes["TagsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllTagsArgs, "orderBy">
  >;
  allTaskActivities?: Resolver<
    Maybe<ResolversTypes["TaskActivitiesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllTaskActivitiesArgs, "orderBy">
  >;
  allTaskResources?: Resolver<
    Maybe<ResolversTypes["TaskResourcesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllTaskResourcesArgs, "orderBy">
  >;
  allTaskSources?: Resolver<
    Maybe<ResolversTypes["TaskSourcesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllTaskSourcesArgs, "orderBy">
  >;
  allTaskStatusLogs?: Resolver<
    Maybe<ResolversTypes["TaskStatusLogsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllTaskStatusLogsArgs, "orderBy">
  >;
  allTasks?: Resolver<
    Maybe<ResolversTypes["TasksConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllTasksArgs, "orderBy">
  >;
  allTenantContractAllowances?: Resolver<
    Maybe<ResolversTypes["TenantContractAllowancesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllTenantContractAllowancesArgs, "orderBy">
  >;
  allTenantContractTransactions?: Resolver<
    Maybe<ResolversTypes["TenantContractTransactionsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllTenantContractTransactionsArgs, "orderBy">
  >;
  allTenantContracts?: Resolver<
    Maybe<ResolversTypes["TenantContractsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllTenantContractsArgs, "orderBy">
  >;
  allTenantMockuserexecutors?: Resolver<
    Maybe<ResolversTypes["TenantMockuserexecutorsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllTenantMockuserexecutorsArgs, "orderBy">
  >;
  allTenantMonthlyConsumedResources?: Resolver<
    Maybe<ResolversTypes["TenantMonthlyConsumedResourcesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllTenantMonthlyConsumedResourcesArgs, "orderBy">
  >;
  allTenantUserMembershipNotifications?: Resolver<
    Maybe<ResolversTypes["TenantUserMembershipNotificationsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllTenantUserMembershipNotificationsArgs, "orderBy">
  >;
  allTenantUserexecutors?: Resolver<
    Maybe<ResolversTypes["TenantUserexecutorsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllTenantUserexecutorsArgs, "orderBy">
  >;
  allTesContainerimageexecutors?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutorsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllTesContainerimageexecutorsArgs, "orderBy">
  >;
  allTesMockcontainerimageexecutors?: Resolver<
    Maybe<ResolversTypes["TesMockcontainerimageexecutorsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllTesMockcontainerimageexecutorsArgs, "orderBy">
  >;
  allTesMocktaskexecutors?: Resolver<
    Maybe<ResolversTypes["TesMocktaskexecutorsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllTesMocktaskexecutorsArgs, "orderBy">
  >;
  allTesTaskexecutors?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutorsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllTesTaskexecutorsArgs, "orderBy">
  >;
  allToolCategories?: Resolver<
    Maybe<ResolversTypes["ToolCategoriesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllToolCategoriesArgs, "orderBy">
  >;
  allToolDisplayCategories?: Resolver<
    Maybe<ResolversTypes["ToolDisplayCategoriesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllToolDisplayCategoriesArgs, "orderBy">
  >;
  allToolParameters?: Resolver<
    Maybe<ResolversTypes["ToolParametersConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllToolParametersArgs, "orderBy">
  >;
  allToolResources?: Resolver<
    Maybe<ResolversTypes["ToolResourcesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllToolResourcesArgs, "orderBy">
  >;
  allToolResults?: Resolver<
    Maybe<ResolversTypes["ToolResultsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllToolResultsArgs, "orderBy">
  >;
  allToolSecrets?: Resolver<
    Maybe<ResolversTypes["ToolSecretsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllToolSecretsArgs, "orderBy">
  >;
  allToolSources?: Resolver<
    Maybe<ResolversTypes["ToolSourcesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllToolSourcesArgs, "orderBy">
  >;
  allToolTenants?: Resolver<
    Maybe<ResolversTypes["ToolTenantsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllToolTenantsArgs, "orderBy">
  >;
  allToolVersions?: Resolver<
    Maybe<ResolversTypes["ToolVersionsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllToolVersionsArgs, "orderBy">
  >;
  allToolboxExitcodes?: Resolver<
    Maybe<ResolversTypes["ToolboxExitcodesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllToolboxExitcodesArgs, "orderBy">
  >;
  allToolboxTenants?: Resolver<
    Maybe<ResolversTypes["ToolboxTenantsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllToolboxTenantsArgs, "orderBy">
  >;
  allToolboxVersionExitcodes?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionExitcodesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllToolboxVersionExitcodesArgs, "orderBy">
  >;
  allToolboxVersionToolVersions?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionToolVersionsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllToolboxVersionToolVersionsArgs, "orderBy">
  >;
  allToolboxVersions?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllToolboxVersionsArgs, "orderBy">
  >;
  allToolboxes?: Resolver<
    Maybe<ResolversTypes["ToolboxesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllToolboxesArgs, "orderBy">
  >;
  allTools?: Resolver<
    Maybe<ResolversTypes["ToolsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllToolsArgs, "orderBy">
  >;
  allUserAccessToProjects?: Resolver<
    Maybe<ResolversTypes["UserAccessToProjectsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllUserAccessToProjectsArgs, "orderBy">
  >;
  allWorkflowData?: Resolver<
    Maybe<ResolversTypes["WorkflowDataConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllWorkflowDataArgs, "orderBy">
  >;
  allWorkflowResultTags?: Resolver<
    Maybe<ResolversTypes["WorkflowResultTagsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllWorkflowResultTagsArgs, "orderBy">
  >;
  allWorkflowResultWorkflowVersions?: Resolver<
    Maybe<ResolversTypes["WorkflowResultWorkflowVersionsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllWorkflowResultWorkflowVersionsArgs, "orderBy">
  >;
  allWorkflowResults?: Resolver<
    Maybe<ResolversTypes["WorkflowResultsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllWorkflowResultsArgs, "orderBy">
  >;
  allWorkflowSectionBlockSections?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlockSectionsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllWorkflowSectionBlockSectionsArgs, "orderBy">
  >;
  allWorkflowSectionBlocks?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlocksConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllWorkflowSectionBlocksArgs, "orderBy">
  >;
  allWorkflowSectionWorkflowVersions?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionWorkflowVersionsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllWorkflowSectionWorkflowVersionsArgs, "orderBy">
  >;
  allWorkflowSections?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllWorkflowSectionsArgs, "orderBy">
  >;
  allWorkflowSourceTags?: Resolver<
    Maybe<ResolversTypes["WorkflowSourceTagsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllWorkflowSourceTagsArgs, "orderBy">
  >;
  allWorkflowSourceWorkflowVersions?: Resolver<
    Maybe<ResolversTypes["WorkflowSourceWorkflowVersionsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllWorkflowSourceWorkflowVersionsArgs, "orderBy">
  >;
  allWorkflowSources?: Resolver<
    Maybe<ResolversTypes["WorkflowSourcesConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllWorkflowSourcesArgs, "orderBy">
  >;
  allWorkflowVersions?: Resolver<
    Maybe<ResolversTypes["WorkflowVersionsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllWorkflowVersionsArgs, "orderBy">
  >;
  allWorkflows?: Resolver<
    Maybe<ResolversTypes["WorkflowsConnection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAllWorkflowsArgs, "orderBy">
  >;
  analysisTableById?: Resolver<
    Maybe<ResolversTypes["AnalysisTable"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAnalysisTableByIdArgs, "id">
  >;
  analysisTableGroupById?: Resolver<
    Maybe<ResolversTypes["AnalysisTableGroup"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAnalysisTableGroupByIdArgs, "id">
  >;
  analysisTableRowAttachResultById?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRowAttachResult"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAnalysisTableRowAttachResultByIdArgs, "id">
  >;
  analysisTableRowById?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRow"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAnalysisTableRowByIdArgs, "id">
  >;
  analysisTableRowByTaskId?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRow"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAnalysisTableRowByTaskIdArgs, "taskId">
  >;
  applicationUserByEmail?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType,
    RequireFields<QueryApplicationUserByEmailArgs, "email">
  >;
  applicationUserById?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType,
    RequireFields<QueryApplicationUserByIdArgs, "id">
  >;
  applicationUserByUsername?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType,
    RequireFields<QueryApplicationUserByUsernameArgs, "username">
  >;
  applicationUserGroupById?: Resolver<
    Maybe<ResolversTypes["ApplicationUserGroup"]>,
    ParentType,
    ContextType,
    RequireFields<QueryApplicationUserGroupByIdArgs, "id">
  >;
  applicationUserGroupByUserIdAndGroupId?: Resolver<
    Maybe<ResolversTypes["ApplicationUserGroup"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryApplicationUserGroupByUserIdAndGroupIdArgs,
      "groupId" | "userId"
    >
  >;
  applicationUserMembershipById?: Resolver<
    Maybe<ResolversTypes["ApplicationUserMembership"]>,
    ParentType,
    ContextType,
    RequireFields<QueryApplicationUserMembershipByIdArgs, "id">
  >;
  applicationUserMembershipByUserIdAndTenantId?: Resolver<
    Maybe<ResolversTypes["ApplicationUserMembership"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryApplicationUserMembershipByUserIdAndTenantIdArgs,
      "tenantId" | "userId"
    >
  >;
  applicationUserMetadatumById?: Resolver<
    Maybe<ResolversTypes["ApplicationUserMetadatum"]>,
    ParentType,
    ContextType,
    RequireFields<QueryApplicationUserMetadatumByIdArgs, "id">
  >;
  applicationUserMetadatumByUserIdAndKey?: Resolver<
    Maybe<ResolversTypes["ApplicationUserMetadatum"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryApplicationUserMetadatumByUserIdAndKeyArgs,
      "key" | "userId"
    >
  >;
  applicationUserSessionById?: Resolver<
    Maybe<ResolversTypes["ApplicationUserSession"]>,
    ParentType,
    ContextType,
    RequireFields<QueryApplicationUserSessionByIdArgs, "id">
  >;
  applicationUserUserPermissionById?: Resolver<
    Maybe<ResolversTypes["ApplicationUserUserPermission"]>,
    ParentType,
    ContextType,
    RequireFields<QueryApplicationUserUserPermissionByIdArgs, "id">
  >;
  applicationUserUserPermissionByUserIdAndPermissionId?: Resolver<
    Maybe<ResolversTypes["ApplicationUserUserPermission"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryApplicationUserUserPermissionByUserIdAndPermissionIdArgs,
      "permissionId" | "userId"
    >
  >;
  assertUserAcceptedEula?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType
  >;
  assertUserCanSeeTenantInfo?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType,
    Partial<QueryAssertUserCanSeeTenantInfoArgs>
  >;
  assertUserCanSeeUserInfo?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType,
    Partial<QueryAssertUserCanSeeUserInfoArgs>
  >;
  assertUserHasProjectSharedByTenant?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType,
    Partial<QueryAssertUserHasProjectSharedByTenantArgs>
  >;
  assertUserHasTenantOfId?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType,
    Partial<QueryAssertUserHasTenantOfIdArgs>
  >;
  assertUserIsInvitedToTenant?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType,
    Partial<QueryAssertUserIsInvitedToTenantArgs>
  >;
  authGroupById?: Resolver<
    Maybe<ResolversTypes["AuthGroup"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAuthGroupByIdArgs, "id">
  >;
  authGroupByName?: Resolver<
    Maybe<ResolversTypes["AuthGroup"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAuthGroupByNameArgs, "name">
  >;
  authGroupPermissionByGroupIdAndPermissionId?: Resolver<
    Maybe<ResolversTypes["AuthGroupPermission"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryAuthGroupPermissionByGroupIdAndPermissionIdArgs,
      "groupId" | "permissionId"
    >
  >;
  authGroupPermissionById?: Resolver<
    Maybe<ResolversTypes["AuthGroupPermission"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAuthGroupPermissionByIdArgs, "id">
  >;
  authPermissionByContentTypeIdAndCodename?: Resolver<
    Maybe<ResolversTypes["AuthPermission"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryAuthPermissionByContentTypeIdAndCodenameArgs,
      "codename" | "contentTypeId"
    >
  >;
  authPermissionById?: Resolver<
    Maybe<ResolversTypes["AuthPermission"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAuthPermissionByIdArgs, "id">
  >;
  avatarById?: Resolver<
    Maybe<ResolversTypes["Avatar"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAvatarByIdArgs, "id">
  >;
  avatarByUserId?: Resolver<
    Maybe<ResolversTypes["Avatar"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAvatarByUserIdArgs, "userId">
  >;
  awsBatchTaskByTaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsBatchTask"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAwsBatchTaskByTaskexecutorPtrIdArgs, "taskexecutorPtrId">
  >;
  awsCognitoUserByUserexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsCognitoUser"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryAwsCognitoUserByUserexecutorPtrIdArgs,
      "userexecutorPtrId"
    >
  >;
  awsEcrImageByContainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsEcrImage"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryAwsEcrImageByContainerimageexecutorPtrIdArgs,
      "containerimageexecutorPtrId"
    >
  >;
  awsMockBatchTaskByAwstaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsMockBatchTask"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryAwsMockBatchTaskByAwstaskexecutorPtrIdArgs,
      "awstaskexecutorPtrId"
    >
  >;
  awsMockEcrImageByAwscontainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsMockEcrImage"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryAwsMockEcrImageByAwscontainerimageexecutorPtrIdArgs,
      "awscontainerimageexecutorPtrId"
    >
  >;
  awsMockS3FileByAwsfileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsMockS3File"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryAwsMockS3FileByAwsfileexecutorPtrIdArgs,
      "awsfileexecutorPtrId"
    >
  >;
  awsS3FileByFileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsS3File"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAwsS3FileByFileexecutorPtrIdArgs, "fileexecutorPtrId">
  >;
  containerImageByExecutorId?: Resolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType,
    RequireFields<QueryContainerImageByExecutorIdArgs, "executorId">
  >;
  containerImageById?: Resolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType,
    RequireFields<QueryContainerImageByIdArgs, "id">
  >;
  containerImageByNameAndTag?: Resolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType,
    RequireFields<QueryContainerImageByNameAndTagArgs, "name" | "tag">
  >;
  containerImageByRepositoryAndTag?: Resolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryContainerImageByRepositoryAndTagArgs,
      "repository" | "tag"
    >
  >;
  containerImageByToolboxVersionId?: Resolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType,
    RequireFields<QueryContainerImageByToolboxVersionIdArgs, "toolboxVersionId">
  >;
  costById?: Resolver<
    Maybe<ResolversTypes["Cost"]>,
    ParentType,
    ContextType,
    RequireFields<QueryCostByIdArgs, "id">
  >;
  datasetById?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType,
    RequireFields<QueryDatasetByIdArgs, "id">
  >;
  datasetByProjectIdAndPrefix?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType,
    RequireFields<QueryDatasetByProjectIdAndPrefixArgs, "prefix" | "projectId">
  >;
  datasetByRecordingsTableId?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType,
    RequireFields<QueryDatasetByRecordingsTableIdArgs, "recordingsTableId">
  >;
  datasetByTenantIdAndKey?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType,
    RequireFields<QueryDatasetByTenantIdAndKeyArgs, "key" | "tenantId">
  >;
  datasetMetadatumById?: Resolver<
    Maybe<ResolversTypes["DatasetMetadatum"]>,
    ParentType,
    ContextType,
    RequireFields<QueryDatasetMetadatumByIdArgs, "id">
  >;
  datasetRecordingsTableById?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTable"]>,
    ParentType,
    ContextType,
    RequireFields<QueryDatasetRecordingsTableByIdArgs, "id">
  >;
  datasetRecordingsTableColumnById?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumn"]>,
    ParentType,
    ContextType,
    RequireFields<QueryDatasetRecordingsTableColumnByIdArgs, "id">
  >;
  datasetRecordingsTableColumnOrderById?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumnOrder"]>,
    ParentType,
    ContextType,
    RequireFields<QueryDatasetRecordingsTableColumnOrderByIdArgs, "id">
  >;
  datasetVersionById?: Resolver<
    Maybe<ResolversTypes["DatasetVersion"]>,
    ParentType,
    ContextType,
    RequireFields<QueryDatasetVersionByIdArgs, "id">
  >;
  djangoAdminLogById?: Resolver<
    Maybe<ResolversTypes["DjangoAdminLog"]>,
    ParentType,
    ContextType,
    RequireFields<QueryDjangoAdminLogByIdArgs, "id">
  >;
  djangoContentTypeByAppLabelAndModel?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryDjangoContentTypeByAppLabelAndModelArgs,
      "appLabel" | "model"
    >
  >;
  djangoContentTypeById?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType,
    RequireFields<QueryDjangoContentTypeByIdArgs, "id">
  >;
  djangoMigrationById?: Resolver<
    Maybe<ResolversTypes["DjangoMigration"]>,
    ParentType,
    ContextType,
    RequireFields<QueryDjangoMigrationByIdArgs, "id">
  >;
  djangoSesSesstatByDate?: Resolver<
    Maybe<ResolversTypes["DjangoSesSesstat"]>,
    ParentType,
    ContextType,
    RequireFields<QueryDjangoSesSesstatByDateArgs, "date">
  >;
  djangoSesSesstatById?: Resolver<
    Maybe<ResolversTypes["DjangoSesSesstat"]>,
    ParentType,
    ContextType,
    RequireFields<QueryDjangoSesSesstatByIdArgs, "id">
  >;
  djangoSessionBySessionKey?: Resolver<
    Maybe<ResolversTypes["DjangoSession"]>,
    ParentType,
    ContextType,
    RequireFields<QueryDjangoSessionBySessionKeyArgs, "sessionKey">
  >;
  drsFileexecutorById?: Resolver<
    Maybe<ResolversTypes["DrsFileexecutor"]>,
    ParentType,
    ContextType,
    RequireFields<QueryDrsFileexecutorByIdArgs, "id">
  >;
  drsMockfileexecutorByFileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DrsMockfileexecutor"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryDrsMockfileexecutorByFileexecutorPtrIdArgs,
      "fileexecutorPtrId"
    >
  >;
  eulaVersionById?: Resolver<
    Maybe<ResolversTypes["EulaVersion"]>,
    ParentType,
    ContextType,
    RequireFields<QueryEulaVersionByIdArgs, "id">
  >;
  eulaVersionByVersion?: Resolver<
    Maybe<ResolversTypes["EulaVersion"]>,
    ParentType,
    ContextType,
    RequireFields<QueryEulaVersionByVersionArgs, "version">
  >;
  eulaVersionUserAcceptanceById?: Resolver<
    Maybe<ResolversTypes["EulaVersionUserAcceptance"]>,
    ParentType,
    ContextType,
    RequireFields<QueryEulaVersionUserAcceptanceByIdArgs, "id">
  >;
  eulaVersionUserAcceptanceByUserIdAndEulaVersionId?: Resolver<
    Maybe<ResolversTypes["EulaVersionUserAcceptance"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryEulaVersionUserAcceptanceByUserIdAndEulaVersionIdArgs,
      "eulaVersionId" | "userId"
    >
  >;
  explorerQueryById?: Resolver<
    Maybe<ResolversTypes["ExplorerQuery"]>,
    ParentType,
    ContextType,
    RequireFields<QueryExplorerQueryByIdArgs, "id">
  >;
  explorerQueryfavoriteById?: Resolver<
    Maybe<ResolversTypes["ExplorerQueryfavorite"]>,
    ParentType,
    ContextType,
    RequireFields<QueryExplorerQueryfavoriteByIdArgs, "id">
  >;
  explorerQueryfavoriteByQueryIdAndUserId?: Resolver<
    Maybe<ResolversTypes["ExplorerQueryfavorite"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryExplorerQueryfavoriteByQueryIdAndUserIdArgs,
      "queryId" | "userId"
    >
  >;
  explorerQuerylogById?: Resolver<
    Maybe<ResolversTypes["ExplorerQuerylog"]>,
    ParentType,
    ContextType,
    RequireFields<QueryExplorerQuerylogByIdArgs, "id">
  >;
  fileActivityById?: Resolver<
    Maybe<ResolversTypes["FileActivity"]>,
    ParentType,
    ContextType,
    RequireFields<QueryFileActivityByIdArgs, "id">
  >;
  fileById?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType,
    RequireFields<QueryFileByIdArgs, "id">
  >;
  fileMetadatumById?: Resolver<
    Maybe<ResolversTypes["FileMetadatum"]>,
    ParentType,
    ContextType,
    RequireFields<QueryFileMetadatumByIdArgs, "id">
  >;
  fileOutputGroupById?: Resolver<
    Maybe<ResolversTypes["FileOutputGroup"]>,
    ParentType,
    ContextType,
    RequireFields<QueryFileOutputGroupByIdArgs, "id">
  >;
  fileOutputGroupByTaskId?: Resolver<
    Maybe<ResolversTypes["FileOutputGroup"]>,
    ParentType,
    ContextType,
    RequireFields<QueryFileOutputGroupByTaskIdArgs, "taskId">
  >;
  fileOutputGroupMetadatumById?: Resolver<
    Maybe<ResolversTypes["FileOutputGroupMetadatum"]>,
    ParentType,
    ContextType,
    RequireFields<QueryFileOutputGroupMetadatumByIdArgs, "id">
  >;
  fileProcessingNotificationById?: Resolver<
    Maybe<ResolversTypes["FileProcessingNotification"]>,
    ParentType,
    ContextType,
    RequireFields<QueryFileProcessingNotificationByIdArgs, "id">
  >;
  fileRecordingGroupById?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroup"]>,
    ParentType,
    ContextType,
    RequireFields<QueryFileRecordingGroupByIdArgs, "id">
  >;
  fileRecordingGroupMetadatumById?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroupMetadatum"]>,
    ParentType,
    ContextType,
    RequireFields<QueryFileRecordingGroupMetadatumByIdArgs, "id">
  >;
  getCurrentUser?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  getCurrentUserId?: Resolver<
    Maybe<ResolversTypes["UUID"]>,
    ParentType,
    ContextType
  >;
  getTenantFromApplicationUser?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  getUserActiveSession?: Resolver<
    Maybe<ResolversTypes["ApplicationUserSession"]>,
    ParentType,
    ContextType,
    RequireFields<QueryGetUserActiveSessionArgs, "userid">
  >;
  metadataValueById?: Resolver<
    Maybe<ResolversTypes["MetadataValue"]>,
    ParentType,
    ContextType,
    RequireFields<QueryMetadataValueByIdArgs, "id">
  >;
  metadatumById?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType,
    RequireFields<QueryMetadatumByIdArgs, "id">
  >;
  notificationById?: Resolver<
    Maybe<ResolversTypes["Notification"]>,
    ParentType,
    ContextType,
    RequireFields<QueryNotificationByIdArgs, "id">
  >;
  outputGroupFileById?: Resolver<
    Maybe<ResolversTypes["OutputGroupFile"]>,
    ParentType,
    ContextType,
    RequireFields<QueryOutputGroupFileByIdArgs, "id">
  >;
  projectById?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType,
    RequireFields<QueryProjectByIdArgs, "id">
  >;
  projectByKeyAndTenantId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType,
    RequireFields<QueryProjectByKeyAndTenantIdArgs, "key" | "tenantId">
  >;
  projectClonedNotificationById?: Resolver<
    Maybe<ResolversTypes["ProjectClonedNotification"]>,
    ParentType,
    ContextType,
    RequireFields<QueryProjectClonedNotificationByIdArgs, "id">
  >;
  projectSharedNotificationById?: Resolver<
    Maybe<ResolversTypes["ProjectSharedNotification"]>,
    ParentType,
    ContextType,
    RequireFields<QueryProjectSharedNotificationByIdArgs, "id">
  >;
  query?: Resolver<ResolversTypes["Query"], ParentType, ContextType>;
  recordingGroupFileById?: Resolver<
    Maybe<ResolversTypes["RecordingGroupFile"]>,
    ParentType,
    ContextType,
    RequireFields<QueryRecordingGroupFileByIdArgs, "id">
  >;
  roleChangeNotificationById?: Resolver<
    Maybe<ResolversTypes["RoleChangeNotification"]>,
    ParentType,
    ContextType,
    RequireFields<QueryRoleChangeNotificationByIdArgs, "id">
  >;
  runById?: Resolver<
    Maybe<ResolversTypes["Run"]>,
    ParentType,
    ContextType,
    RequireFields<QueryRunByIdArgs, "id">
  >;
  sessionIsActive?: Resolver<
    ResolversTypes["Boolean"],
    ParentType,
    ContextType
  >;
  sessionIsActiveSession?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType,
    RequireFields<QuerySessionIsActiveSessionArgs, "id">
  >;
  tagById?: Resolver<
    Maybe<ResolversTypes["Tag"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTagByIdArgs, "id">
  >;
  taskActivityById?: Resolver<
    Maybe<ResolversTypes["TaskActivity"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTaskActivityByIdArgs, "id">
  >;
  taskActivityByTaskId?: Resolver<
    Maybe<ResolversTypes["TaskActivity"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTaskActivityByTaskIdArgs, "taskId">
  >;
  taskByExecutorId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTaskByExecutorIdArgs, "executorId">
  >;
  taskById?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTaskByIdArgs, "id">
  >;
  taskResourceById?: Resolver<
    Maybe<ResolversTypes["TaskResource"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTaskResourceByIdArgs, "id">
  >;
  taskSourceById?: Resolver<
    Maybe<ResolversTypes["TaskSource"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTaskSourceByIdArgs, "id">
  >;
  taskStatusLogById?: Resolver<
    Maybe<ResolversTypes["TaskStatusLog"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTaskStatusLogByIdArgs, "id">
  >;
  taskStatusLogByTaskIdAndStatus?: Resolver<
    Maybe<ResolversTypes["TaskStatusLog"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTaskStatusLogByTaskIdAndStatusArgs, "status" | "taskId">
  >;
  tenantById?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTenantByIdArgs, "id">
  >;
  tenantByKey?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTenantByKeyArgs, "key">
  >;
  tenantByName?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTenantByNameArgs, "name">
  >;
  tenantContractAllowanceById?: Resolver<
    Maybe<ResolversTypes["TenantContractAllowance"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTenantContractAllowanceByIdArgs, "id">
  >;
  tenantContractAllowanceByTenantContractTransactionIdAndQuotaType?: Resolver<
    Maybe<ResolversTypes["TenantContractAllowance"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeArgs,
      "quotaType" | "tenantContractTransactionId"
    >
  >;
  tenantContractById?: Resolver<
    Maybe<ResolversTypes["TenantContract"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTenantContractByIdArgs, "id">
  >;
  tenantContractByName?: Resolver<
    Maybe<ResolversTypes["TenantContract"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTenantContractByNameArgs, "name">
  >;
  tenantContractTransactionById?: Resolver<
    Maybe<ResolversTypes["TenantContractTransaction"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTenantContractTransactionByIdArgs, "id">
  >;
  tenantMockuserexecutorByUserexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TenantMockuserexecutor"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryTenantMockuserexecutorByUserexecutorPtrIdArgs,
      "userexecutorPtrId"
    >
  >;
  tenantMonthlyConsumedResourceById?: Resolver<
    Maybe<ResolversTypes["TenantMonthlyConsumedResource"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTenantMonthlyConsumedResourceByIdArgs, "id">
  >;
  tenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract?: Resolver<
    Maybe<ResolversTypes["TenantMonthlyConsumedResource"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractArgs,
      | "month"
      | "monthInActiveContract"
      | "quotaType"
      | "tenantContractId"
      | "tenantId"
      | "userId"
    >
  >;
  tenantUserMembershipNotificationById?: Resolver<
    Maybe<ResolversTypes["TenantUserMembershipNotification"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTenantUserMembershipNotificationByIdArgs, "id">
  >;
  tenantUserexecutorById?: Resolver<
    Maybe<ResolversTypes["TenantUserexecutor"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTenantUserexecutorByIdArgs, "id">
  >;
  tesContainerimageexecutorById?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutor"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTesContainerimageexecutorByIdArgs, "id">
  >;
  tesMockcontainerimageexecutorByContainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesMockcontainerimageexecutor"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryTesMockcontainerimageexecutorByContainerimageexecutorPtrIdArgs,
      "containerimageexecutorPtrId"
    >
  >;
  tesMocktaskexecutorByTaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesMocktaskexecutor"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryTesMocktaskexecutorByTaskexecutorPtrIdArgs,
      "taskexecutorPtrId"
    >
  >;
  tesTaskexecutorById?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutor"]>,
    ParentType,
    ContextType,
    RequireFields<QueryTesTaskexecutorByIdArgs, "id">
  >;
  toolById?: Resolver<
    Maybe<ResolversTypes["Tool"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolByIdArgs, "id">
  >;
  toolByKey?: Resolver<
    Maybe<ResolversTypes["Tool"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolByKeyArgs, "key">
  >;
  toolCategoryById?: Resolver<
    Maybe<ResolversTypes["ToolCategory"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolCategoryByIdArgs, "id">
  >;
  toolCategoryByKey?: Resolver<
    Maybe<ResolversTypes["ToolCategory"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolCategoryByKeyArgs, "key">
  >;
  toolDisplayCategoryById?: Resolver<
    Maybe<ResolversTypes["ToolDisplayCategory"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolDisplayCategoryByIdArgs, "id">
  >;
  toolDisplayCategoryByToolIdAndToolcategoryId?: Resolver<
    Maybe<ResolversTypes["ToolDisplayCategory"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryToolDisplayCategoryByToolIdAndToolcategoryIdArgs,
      "toolId" | "toolcategoryId"
    >
  >;
  toolParameterById?: Resolver<
    Maybe<ResolversTypes["ToolParameter"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolParameterByIdArgs, "id">
  >;
  toolResourceById?: Resolver<
    Maybe<ResolversTypes["ToolResource"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolResourceByIdArgs, "id">
  >;
  toolResourceByTypeAndToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolResource"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryToolResourceByTypeAndToolVersionIdArgs,
      "toolVersionId" | "type"
    >
  >;
  toolResultById?: Resolver<
    Maybe<ResolversTypes["ToolResult"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolResultByIdArgs, "id">
  >;
  toolSecretById?: Resolver<
    Maybe<ResolversTypes["ToolSecret"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolSecretByIdArgs, "id">
  >;
  toolSourceById?: Resolver<
    Maybe<ResolversTypes["ToolSource"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolSourceByIdArgs, "id">
  >;
  toolTenantById?: Resolver<
    Maybe<ResolversTypes["ToolTenant"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolTenantByIdArgs, "id">
  >;
  toolTenantByToolIdAndTenantId?: Resolver<
    Maybe<ResolversTypes["ToolTenant"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolTenantByToolIdAndTenantIdArgs, "tenantId" | "toolId">
  >;
  toolVersionById?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolVersionByIdArgs, "id">
  >;
  toolVersionByToolIdAndVersion?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolVersionByToolIdAndVersionArgs, "toolId" | "version">
  >;
  toolboxById?: Resolver<
    Maybe<ResolversTypes["Toolbox"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolboxByIdArgs, "id">
  >;
  toolboxByKey?: Resolver<
    Maybe<ResolversTypes["Toolbox"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolboxByKeyArgs, "key">
  >;
  toolboxExitcodeByCode?: Resolver<
    Maybe<ResolversTypes["ToolboxExitcode"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolboxExitcodeByCodeArgs, "code">
  >;
  toolboxExitcodeById?: Resolver<
    Maybe<ResolversTypes["ToolboxExitcode"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolboxExitcodeByIdArgs, "id">
  >;
  toolboxTenantById?: Resolver<
    Maybe<ResolversTypes["ToolboxTenant"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolboxTenantByIdArgs, "id">
  >;
  toolboxTenantByToolboxIdAndTenantId?: Resolver<
    Maybe<ResolversTypes["ToolboxTenant"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryToolboxTenantByToolboxIdAndTenantIdArgs,
      "tenantId" | "toolboxId"
    >
  >;
  toolboxVersionById?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolboxVersionByIdArgs, "id">
  >;
  toolboxVersionByToolKeyAndToolVersionVersion?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType,
    Partial<QueryToolboxVersionByToolKeyAndToolVersionVersionArgs>
  >;
  toolboxVersionByToolboxIdAndVersion?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryToolboxVersionByToolboxIdAndVersionArgs,
      "toolboxId" | "version"
    >
  >;
  toolboxVersionExitcodeById?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionExitcode"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolboxVersionExitcodeByIdArgs, "id">
  >;
  toolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeId?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionExitcode"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdArgs,
      "toolboxexitcodeId" | "toolboxversionId"
    >
  >;
  toolboxVersionToolVersionById?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionToolVersion"]>,
    ParentType,
    ContextType,
    RequireFields<QueryToolboxVersionToolVersionByIdArgs, "id">
  >;
  toolboxVersionToolVersionByToolboxversionIdAndToolversionId?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionToolVersion"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryToolboxVersionToolVersionByToolboxversionIdAndToolversionIdArgs,
      "toolboxversionId" | "toolversionId"
    >
  >;
  userExists?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType
  >;
  userHasActiveSession?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType,
    RequireFields<QueryUserHasActiveSessionArgs, "userid">
  >;
  userIdIsCurrentUser?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType,
    Partial<QueryUserIdIsCurrentUserArgs>
  >;
  userIdIsSettingsGlobalUserId?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType,
    Partial<QueryUserIdIsSettingsGlobalUserIdArgs>
  >;
  workflowById?: Resolver<
    Maybe<ResolversTypes["Workflow"]>,
    ParentType,
    ContextType,
    RequireFields<QueryWorkflowByIdArgs, "id">
  >;
  workflowDatumById?: Resolver<
    Maybe<ResolversTypes["WorkflowDatum"]>,
    ParentType,
    ContextType,
    RequireFields<QueryWorkflowDatumByIdArgs, "id">
  >;
  workflowResultById?: Resolver<
    Maybe<ResolversTypes["WorkflowResult"]>,
    ParentType,
    ContextType,
    RequireFields<QueryWorkflowResultByIdArgs, "id">
  >;
  workflowResultTagById?: Resolver<
    Maybe<ResolversTypes["WorkflowResultTag"]>,
    ParentType,
    ContextType,
    RequireFields<QueryWorkflowResultTagByIdArgs, "id">
  >;
  workflowResultTagByResultIdAndTagId?: Resolver<
    Maybe<ResolversTypes["WorkflowResultTag"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryWorkflowResultTagByResultIdAndTagIdArgs,
      "resultId" | "tagId"
    >
  >;
  workflowResultWorkflowVersionById?: Resolver<
    Maybe<ResolversTypes["WorkflowResultWorkflowVersion"]>,
    ParentType,
    ContextType,
    RequireFields<QueryWorkflowResultWorkflowVersionByIdArgs, "id">
  >;
  workflowResultWorkflowVersionByResultIdAndWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["WorkflowResultWorkflowVersion"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdArgs,
      "resultId" | "workflowversionId"
    >
  >;
  workflowSectionBlockById?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlock"]>,
    ParentType,
    ContextType,
    RequireFields<QueryWorkflowSectionBlockByIdArgs, "id">
  >;
  workflowSectionBlockSectionByBlockIdAndSectionId?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlockSection"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryWorkflowSectionBlockSectionByBlockIdAndSectionIdArgs,
      "blockId" | "sectionId"
    >
  >;
  workflowSectionBlockSectionById?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlockSection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryWorkflowSectionBlockSectionByIdArgs, "id">
  >;
  workflowSectionById?: Resolver<
    Maybe<ResolversTypes["WorkflowSection"]>,
    ParentType,
    ContextType,
    RequireFields<QueryWorkflowSectionByIdArgs, "id">
  >;
  workflowSectionWorkflowVersionById?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionWorkflowVersion"]>,
    ParentType,
    ContextType,
    RequireFields<QueryWorkflowSectionWorkflowVersionByIdArgs, "id">
  >;
  workflowSectionWorkflowVersionBySectionIdAndWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionWorkflowVersion"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdArgs,
      "sectionId" | "workflowversionId"
    >
  >;
  workflowSourceById?: Resolver<
    Maybe<ResolversTypes["WorkflowSource"]>,
    ParentType,
    ContextType,
    RequireFields<QueryWorkflowSourceByIdArgs, "id">
  >;
  workflowSourceTagById?: Resolver<
    Maybe<ResolversTypes["WorkflowSourceTag"]>,
    ParentType,
    ContextType,
    RequireFields<QueryWorkflowSourceTagByIdArgs, "id">
  >;
  workflowSourceTagBySourceIdAndTagId?: Resolver<
    Maybe<ResolversTypes["WorkflowSourceTag"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryWorkflowSourceTagBySourceIdAndTagIdArgs,
      "sourceId" | "tagId"
    >
  >;
  workflowSourceWorkflowVersionById?: Resolver<
    Maybe<ResolversTypes["WorkflowSourceWorkflowVersion"]>,
    ParentType,
    ContextType,
    RequireFields<QueryWorkflowSourceWorkflowVersionByIdArgs, "id">
  >;
  workflowSourceWorkflowVersionBySourceIdAndWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["WorkflowSourceWorkflowVersion"]>,
    ParentType,
    ContextType,
    RequireFields<
      QueryWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdArgs,
      "sourceId" | "workflowversionId"
    >
  >;
  workflowVersionById?: Resolver<
    Maybe<ResolversTypes["WorkflowVersion"]>,
    ParentType,
    ContextType,
    RequireFields<QueryWorkflowVersionByIdArgs, "id">
  >;
};

export type RecordingGroupFileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["RecordingGroupFile"] = ResolversParentTypes["RecordingGroupFile"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnByDatasetRecordingsTableColumnId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumn"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnId?: Resolver<
    ResolversTypes["UUID"],
    ParentType,
    ContextType
  >;
  dateAssignmentEnded?: Resolver<
    Maybe<ResolversTypes["Datetime"]>,
    ParentType,
    ContextType
  >;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  fileRecordingGroupByFileRecordingGroupId?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroup"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroupId?: Resolver<
    ResolversTypes["UUID"],
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingGroupFilesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["RecordingGroupFilesConnection"] = ResolversParentTypes["RecordingGroupFilesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["RecordingGroupFilesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["RecordingGroupFile"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingGroupFilesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["RecordingGroupFilesEdge"] = ResolversParentTypes["RecordingGroupFilesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["RecordingGroupFile"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleChangeNotificationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["RoleChangeNotification"] = ResolversParentTypes["RoleChangeNotification"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  notificationById?: Resolver<
    Maybe<ResolversTypes["Notification"]>,
    ParentType,
    ContextType
  >;
  role?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleChangeNotificationsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["RoleChangeNotificationsConnection"] = ResolversParentTypes["RoleChangeNotificationsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["RoleChangeNotificationsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["RoleChangeNotification"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleChangeNotificationsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["RoleChangeNotificationsEdge"] = ResolversParentTypes["RoleChangeNotificationsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["RoleChangeNotification"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RunResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Run"] = ResolversParentTypes["Run"],
> = {
  created?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RunsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["RunsConnection"] = ResolversParentTypes["RunsConnection"],
> = {
  edges?: Resolver<Array<ResolversTypes["RunsEdge"]>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes["Run"]>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RunsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["RunsEdge"] = ResolversParentTypes["RunsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["Run"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["Subscription"] = ResolversParentTypes["Subscription"],
> = {
  allAnalysisTableGroups?: SubscriptionResolver<
    Maybe<ResolversTypes["AnalysisTableGroupsConnection"]>,
    "allAnalysisTableGroups",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllAnalysisTableGroupsArgs, "orderBy">
  >;
  allAnalysisTableRowAttachResults?: SubscriptionResolver<
    Maybe<ResolversTypes["AnalysisTableRowAttachResultsConnection"]>,
    "allAnalysisTableRowAttachResults",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllAnalysisTableRowAttachResultsArgs, "orderBy">
  >;
  allAnalysisTableRows?: SubscriptionResolver<
    Maybe<ResolversTypes["AnalysisTableRowsConnection"]>,
    "allAnalysisTableRows",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllAnalysisTableRowsArgs, "orderBy">
  >;
  allAnalysisTables?: SubscriptionResolver<
    Maybe<ResolversTypes["AnalysisTablesConnection"]>,
    "allAnalysisTables",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllAnalysisTablesArgs, "orderBy">
  >;
  allApplicationUserGroups?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUserGroupsConnection"]>,
    "allApplicationUserGroups",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllApplicationUserGroupsArgs, "orderBy">
  >;
  allApplicationUserMemberships?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUserMembershipsConnection"]>,
    "allApplicationUserMemberships",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllApplicationUserMembershipsArgs, "orderBy">
  >;
  allApplicationUserMetadata?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUserMetadataConnection"]>,
    "allApplicationUserMetadata",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllApplicationUserMetadataArgs, "orderBy">
  >;
  allApplicationUserSessions?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUserSessionsConnection"]>,
    "allApplicationUserSessions",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllApplicationUserSessionsArgs, "orderBy">
  >;
  allApplicationUserUserPermissions?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUserUserPermissionsConnection"]>,
    "allApplicationUserUserPermissions",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllApplicationUserUserPermissionsArgs, "orderBy">
  >;
  allAuthGroupPermissions?: SubscriptionResolver<
    Maybe<ResolversTypes["AuthGroupPermissionsConnection"]>,
    "allAuthGroupPermissions",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllAuthGroupPermissionsArgs, "orderBy">
  >;
  allAuthGroups?: SubscriptionResolver<
    Maybe<ResolversTypes["AuthGroupsConnection"]>,
    "allAuthGroups",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllAuthGroupsArgs, "orderBy">
  >;
  allAuthPermissions?: SubscriptionResolver<
    Maybe<ResolversTypes["AuthPermissionsConnection"]>,
    "allAuthPermissions",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllAuthPermissionsArgs, "orderBy">
  >;
  allAvailableNonRestrictedProjects?: SubscriptionResolver<
    Maybe<ResolversTypes["AvailableNonRestrictedProjectsConnection"]>,
    "allAvailableNonRestrictedProjects",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllAvailableNonRestrictedProjectsArgs, "orderBy">
  >;
  allAvatars?: SubscriptionResolver<
    Maybe<ResolversTypes["AvatarsConnection"]>,
    "allAvatars",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllAvatarsArgs, "orderBy">
  >;
  allAwsBatchTasks?: SubscriptionResolver<
    Maybe<ResolversTypes["AwsBatchTasksConnection"]>,
    "allAwsBatchTasks",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllAwsBatchTasksArgs, "orderBy">
  >;
  allAwsCognitoUsers?: SubscriptionResolver<
    Maybe<ResolversTypes["AwsCognitoUsersConnection"]>,
    "allAwsCognitoUsers",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllAwsCognitoUsersArgs, "orderBy">
  >;
  allAwsEcrImages?: SubscriptionResolver<
    Maybe<ResolversTypes["AwsEcrImagesConnection"]>,
    "allAwsEcrImages",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllAwsEcrImagesArgs, "orderBy">
  >;
  allAwsMockBatchTasks?: SubscriptionResolver<
    Maybe<ResolversTypes["AwsMockBatchTasksConnection"]>,
    "allAwsMockBatchTasks",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllAwsMockBatchTasksArgs, "orderBy">
  >;
  allAwsMockEcrImages?: SubscriptionResolver<
    Maybe<ResolversTypes["AwsMockEcrImagesConnection"]>,
    "allAwsMockEcrImages",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllAwsMockEcrImagesArgs, "orderBy">
  >;
  allAwsMockS3Files?: SubscriptionResolver<
    Maybe<ResolversTypes["AwsMockS3FilesConnection"]>,
    "allAwsMockS3Files",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllAwsMockS3FilesArgs, "orderBy">
  >;
  allAwsS3Files?: SubscriptionResolver<
    Maybe<ResolversTypes["AwsS3FilesConnection"]>,
    "allAwsS3Files",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllAwsS3FilesArgs, "orderBy">
  >;
  allContainerImages?: SubscriptionResolver<
    Maybe<ResolversTypes["ContainerImagesConnection"]>,
    "allContainerImages",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllContainerImagesArgs, "orderBy">
  >;
  allCosts?: SubscriptionResolver<
    Maybe<ResolversTypes["CostsConnection"]>,
    "allCosts",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllCostsArgs, "orderBy">
  >;
  allCurrentApplicationUsers?: SubscriptionResolver<
    Maybe<ResolversTypes["CurrentApplicationUsersConnection"]>,
    "allCurrentApplicationUsers",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllCurrentApplicationUsersArgs, "orderBy">
  >;
  allCurrentUserMemberships?: SubscriptionResolver<
    Maybe<ResolversTypes["CurrentUserMembershipsConnection"]>,
    "allCurrentUserMemberships",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllCurrentUserMembershipsArgs, "orderBy">
  >;
  allDatasetMetadata?: SubscriptionResolver<
    Maybe<ResolversTypes["DatasetMetadataConnection"]>,
    "allDatasetMetadata",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllDatasetMetadataArgs, "orderBy">
  >;
  allDatasetRecordingsTableColumnOrders?: SubscriptionResolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumnOrdersConnection"]>,
    "allDatasetRecordingsTableColumnOrders",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionAllDatasetRecordingsTableColumnOrdersArgs,
      "orderBy"
    >
  >;
  allDatasetRecordingsTableColumns?: SubscriptionResolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumnsConnection"]>,
    "allDatasetRecordingsTableColumns",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllDatasetRecordingsTableColumnsArgs, "orderBy">
  >;
  allDatasetRecordingsTables?: SubscriptionResolver<
    Maybe<ResolversTypes["DatasetRecordingsTablesConnection"]>,
    "allDatasetRecordingsTables",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllDatasetRecordingsTablesArgs, "orderBy">
  >;
  allDatasetVersions?: SubscriptionResolver<
    Maybe<ResolversTypes["DatasetVersionsConnection"]>,
    "allDatasetVersions",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllDatasetVersionsArgs, "orderBy">
  >;
  allDatasets?: SubscriptionResolver<
    Maybe<ResolversTypes["DatasetsConnection"]>,
    "allDatasets",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllDatasetsArgs, "orderBy">
  >;
  allDjangoAdminLogs?: SubscriptionResolver<
    Maybe<ResolversTypes["DjangoAdminLogsConnection"]>,
    "allDjangoAdminLogs",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllDjangoAdminLogsArgs, "orderBy">
  >;
  allDjangoContentTypes?: SubscriptionResolver<
    Maybe<ResolversTypes["DjangoContentTypesConnection"]>,
    "allDjangoContentTypes",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllDjangoContentTypesArgs, "orderBy">
  >;
  allDjangoMigrations?: SubscriptionResolver<
    Maybe<ResolversTypes["DjangoMigrationsConnection"]>,
    "allDjangoMigrations",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllDjangoMigrationsArgs, "orderBy">
  >;
  allDjangoSesSesstats?: SubscriptionResolver<
    Maybe<ResolversTypes["DjangoSesSesstatsConnection"]>,
    "allDjangoSesSesstats",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllDjangoSesSesstatsArgs, "orderBy">
  >;
  allDjangoSessions?: SubscriptionResolver<
    Maybe<ResolversTypes["DjangoSessionsConnection"]>,
    "allDjangoSessions",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllDjangoSessionsArgs, "orderBy">
  >;
  allDrsFileexecutors?: SubscriptionResolver<
    Maybe<ResolversTypes["DrsFileexecutorsConnection"]>,
    "allDrsFileexecutors",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllDrsFileexecutorsArgs, "orderBy">
  >;
  allDrsMockfileexecutors?: SubscriptionResolver<
    Maybe<ResolversTypes["DrsMockfileexecutorsConnection"]>,
    "allDrsMockfileexecutors",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllDrsMockfileexecutorsArgs, "orderBy">
  >;
  allEulaVersionUserAcceptances?: SubscriptionResolver<
    Maybe<ResolversTypes["EulaVersionUserAcceptancesConnection"]>,
    "allEulaVersionUserAcceptances",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllEulaVersionUserAcceptancesArgs, "orderBy">
  >;
  allEulaVersions?: SubscriptionResolver<
    Maybe<ResolversTypes["EulaVersionsConnection"]>,
    "allEulaVersions",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllEulaVersionsArgs, "orderBy">
  >;
  allExplorerQueries?: SubscriptionResolver<
    Maybe<ResolversTypes["ExplorerQueriesConnection"]>,
    "allExplorerQueries",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllExplorerQueriesArgs, "orderBy">
  >;
  allExplorerQueryfavorites?: SubscriptionResolver<
    Maybe<ResolversTypes["ExplorerQueryfavoritesConnection"]>,
    "allExplorerQueryfavorites",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllExplorerQueryfavoritesArgs, "orderBy">
  >;
  allExplorerQuerylogs?: SubscriptionResolver<
    Maybe<ResolversTypes["ExplorerQuerylogsConnection"]>,
    "allExplorerQuerylogs",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllExplorerQuerylogsArgs, "orderBy">
  >;
  allFileActivities?: SubscriptionResolver<
    Maybe<ResolversTypes["FileActivitiesConnection"]>,
    "allFileActivities",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllFileActivitiesArgs, "orderBy">
  >;
  allFileMetadata?: SubscriptionResolver<
    Maybe<ResolversTypes["FileMetadataConnection"]>,
    "allFileMetadata",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllFileMetadataArgs, "orderBy">
  >;
  allFileOutputGroupMetadata?: SubscriptionResolver<
    Maybe<ResolversTypes["FileOutputGroupMetadataConnection"]>,
    "allFileOutputGroupMetadata",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllFileOutputGroupMetadataArgs, "orderBy">
  >;
  allFileOutputGroups?: SubscriptionResolver<
    Maybe<ResolversTypes["FileOutputGroupsConnection"]>,
    "allFileOutputGroups",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllFileOutputGroupsArgs, "orderBy">
  >;
  allFileProcessingNotifications?: SubscriptionResolver<
    Maybe<ResolversTypes["FileProcessingNotificationsConnection"]>,
    "allFileProcessingNotifications",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllFileProcessingNotificationsArgs, "orderBy">
  >;
  allFileRecordingGroupMetadata?: SubscriptionResolver<
    Maybe<ResolversTypes["FileRecordingGroupMetadataConnection"]>,
    "allFileRecordingGroupMetadata",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllFileRecordingGroupMetadataArgs, "orderBy">
  >;
  allFileRecordingGroups?: SubscriptionResolver<
    Maybe<ResolversTypes["FileRecordingGroupsConnection"]>,
    "allFileRecordingGroups",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllFileRecordingGroupsArgs, "orderBy">
  >;
  allFiles?: SubscriptionResolver<
    Maybe<ResolversTypes["FilesConnection"]>,
    "allFiles",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllFilesArgs, "orderBy">
  >;
  allNotifications?: SubscriptionResolver<
    Maybe<ResolversTypes["NotificationsConnection"]>,
    "allNotifications",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllNotificationsArgs, "orderBy">
  >;
  allOutputGroupFiles?: SubscriptionResolver<
    Maybe<ResolversTypes["OutputGroupFilesConnection"]>,
    "allOutputGroupFiles",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllOutputGroupFilesArgs, "orderBy">
  >;
  allProjectClonedNotifications?: SubscriptionResolver<
    Maybe<ResolversTypes["ProjectClonedNotificationsConnection"]>,
    "allProjectClonedNotifications",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllProjectClonedNotificationsArgs, "orderBy">
  >;
  allProjectSharedNotifications?: SubscriptionResolver<
    Maybe<ResolversTypes["ProjectSharedNotificationsConnection"]>,
    "allProjectSharedNotifications",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllProjectSharedNotificationsArgs, "orderBy">
  >;
  allProjects?: SubscriptionResolver<
    Maybe<ResolversTypes["ProjectsConnection"]>,
    "allProjects",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllProjectsArgs, "orderBy">
  >;
  allRecordingGroupFiles?: SubscriptionResolver<
    Maybe<ResolversTypes["RecordingGroupFilesConnection"]>,
    "allRecordingGroupFiles",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllRecordingGroupFilesArgs, "orderBy">
  >;
  allRoleChangeNotifications?: SubscriptionResolver<
    Maybe<ResolversTypes["RoleChangeNotificationsConnection"]>,
    "allRoleChangeNotifications",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllRoleChangeNotificationsArgs, "orderBy">
  >;
  allRuns?: SubscriptionResolver<
    Maybe<ResolversTypes["RunsConnection"]>,
    "allRuns",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllRunsArgs, "orderBy">
  >;
  allTags?: SubscriptionResolver<
    Maybe<ResolversTypes["TagsConnection"]>,
    "allTags",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllTagsArgs, "orderBy">
  >;
  allTaskActivities?: SubscriptionResolver<
    Maybe<ResolversTypes["TaskActivitiesConnection"]>,
    "allTaskActivities",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllTaskActivitiesArgs, "orderBy">
  >;
  allTaskResources?: SubscriptionResolver<
    Maybe<ResolversTypes["TaskResourcesConnection"]>,
    "allTaskResources",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllTaskResourcesArgs, "orderBy">
  >;
  allTaskSources?: SubscriptionResolver<
    Maybe<ResolversTypes["TaskSourcesConnection"]>,
    "allTaskSources",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllTaskSourcesArgs, "orderBy">
  >;
  allTaskStatusLogs?: SubscriptionResolver<
    Maybe<ResolversTypes["TaskStatusLogsConnection"]>,
    "allTaskStatusLogs",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllTaskStatusLogsArgs, "orderBy">
  >;
  allTasks?: SubscriptionResolver<
    Maybe<ResolversTypes["TasksConnection"]>,
    "allTasks",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllTasksArgs, "orderBy">
  >;
  allTenantContractAllowances?: SubscriptionResolver<
    Maybe<ResolversTypes["TenantContractAllowancesConnection"]>,
    "allTenantContractAllowances",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllTenantContractAllowancesArgs, "orderBy">
  >;
  allTenantContractTransactions?: SubscriptionResolver<
    Maybe<ResolversTypes["TenantContractTransactionsConnection"]>,
    "allTenantContractTransactions",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllTenantContractTransactionsArgs, "orderBy">
  >;
  allTenantContracts?: SubscriptionResolver<
    Maybe<ResolversTypes["TenantContractsConnection"]>,
    "allTenantContracts",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllTenantContractsArgs, "orderBy">
  >;
  allTenantMockuserexecutors?: SubscriptionResolver<
    Maybe<ResolversTypes["TenantMockuserexecutorsConnection"]>,
    "allTenantMockuserexecutors",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllTenantMockuserexecutorsArgs, "orderBy">
  >;
  allTenantMonthlyConsumedResources?: SubscriptionResolver<
    Maybe<ResolversTypes["TenantMonthlyConsumedResourcesConnection"]>,
    "allTenantMonthlyConsumedResources",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllTenantMonthlyConsumedResourcesArgs, "orderBy">
  >;
  allTenantUserMembershipNotifications?: SubscriptionResolver<
    Maybe<ResolversTypes["TenantUserMembershipNotificationsConnection"]>,
    "allTenantUserMembershipNotifications",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionAllTenantUserMembershipNotificationsArgs,
      "orderBy"
    >
  >;
  allTenantUserexecutors?: SubscriptionResolver<
    Maybe<ResolversTypes["TenantUserexecutorsConnection"]>,
    "allTenantUserexecutors",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllTenantUserexecutorsArgs, "orderBy">
  >;
  allTesContainerimageexecutors?: SubscriptionResolver<
    Maybe<ResolversTypes["TesContainerimageexecutorsConnection"]>,
    "allTesContainerimageexecutors",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllTesContainerimageexecutorsArgs, "orderBy">
  >;
  allTesMockcontainerimageexecutors?: SubscriptionResolver<
    Maybe<ResolversTypes["TesMockcontainerimageexecutorsConnection"]>,
    "allTesMockcontainerimageexecutors",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllTesMockcontainerimageexecutorsArgs, "orderBy">
  >;
  allTesMocktaskexecutors?: SubscriptionResolver<
    Maybe<ResolversTypes["TesMocktaskexecutorsConnection"]>,
    "allTesMocktaskexecutors",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllTesMocktaskexecutorsArgs, "orderBy">
  >;
  allTesTaskexecutors?: SubscriptionResolver<
    Maybe<ResolversTypes["TesTaskexecutorsConnection"]>,
    "allTesTaskexecutors",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllTesTaskexecutorsArgs, "orderBy">
  >;
  allToolCategories?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolCategoriesConnection"]>,
    "allToolCategories",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllToolCategoriesArgs, "orderBy">
  >;
  allToolDisplayCategories?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolDisplayCategoriesConnection"]>,
    "allToolDisplayCategories",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllToolDisplayCategoriesArgs, "orderBy">
  >;
  allToolParameters?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolParametersConnection"]>,
    "allToolParameters",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllToolParametersArgs, "orderBy">
  >;
  allToolResources?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolResourcesConnection"]>,
    "allToolResources",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllToolResourcesArgs, "orderBy">
  >;
  allToolResults?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolResultsConnection"]>,
    "allToolResults",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllToolResultsArgs, "orderBy">
  >;
  allToolSecrets?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolSecretsConnection"]>,
    "allToolSecrets",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllToolSecretsArgs, "orderBy">
  >;
  allToolSources?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolSourcesConnection"]>,
    "allToolSources",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllToolSourcesArgs, "orderBy">
  >;
  allToolTenants?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolTenantsConnection"]>,
    "allToolTenants",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllToolTenantsArgs, "orderBy">
  >;
  allToolVersions?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolVersionsConnection"]>,
    "allToolVersions",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllToolVersionsArgs, "orderBy">
  >;
  allToolboxExitcodes?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolboxExitcodesConnection"]>,
    "allToolboxExitcodes",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllToolboxExitcodesArgs, "orderBy">
  >;
  allToolboxTenants?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolboxTenantsConnection"]>,
    "allToolboxTenants",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllToolboxTenantsArgs, "orderBy">
  >;
  allToolboxVersionExitcodes?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolboxVersionExitcodesConnection"]>,
    "allToolboxVersionExitcodes",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllToolboxVersionExitcodesArgs, "orderBy">
  >;
  allToolboxVersionToolVersions?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolboxVersionToolVersionsConnection"]>,
    "allToolboxVersionToolVersions",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllToolboxVersionToolVersionsArgs, "orderBy">
  >;
  allToolboxVersions?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolboxVersionsConnection"]>,
    "allToolboxVersions",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllToolboxVersionsArgs, "orderBy">
  >;
  allToolboxes?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolboxesConnection"]>,
    "allToolboxes",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllToolboxesArgs, "orderBy">
  >;
  allTools?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolsConnection"]>,
    "allTools",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllToolsArgs, "orderBy">
  >;
  allUserAccessToProjects?: SubscriptionResolver<
    Maybe<ResolversTypes["UserAccessToProjectsConnection"]>,
    "allUserAccessToProjects",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllUserAccessToProjectsArgs, "orderBy">
  >;
  allWorkflowData?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowDataConnection"]>,
    "allWorkflowData",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllWorkflowDataArgs, "orderBy">
  >;
  allWorkflowResultTags?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowResultTagsConnection"]>,
    "allWorkflowResultTags",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllWorkflowResultTagsArgs, "orderBy">
  >;
  allWorkflowResultWorkflowVersions?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowResultWorkflowVersionsConnection"]>,
    "allWorkflowResultWorkflowVersions",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllWorkflowResultWorkflowVersionsArgs, "orderBy">
  >;
  allWorkflowResults?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowResultsConnection"]>,
    "allWorkflowResults",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllWorkflowResultsArgs, "orderBy">
  >;
  allWorkflowSectionBlockSections?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowSectionBlockSectionsConnection"]>,
    "allWorkflowSectionBlockSections",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllWorkflowSectionBlockSectionsArgs, "orderBy">
  >;
  allWorkflowSectionBlocks?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowSectionBlocksConnection"]>,
    "allWorkflowSectionBlocks",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllWorkflowSectionBlocksArgs, "orderBy">
  >;
  allWorkflowSectionWorkflowVersions?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowSectionWorkflowVersionsConnection"]>,
    "allWorkflowSectionWorkflowVersions",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllWorkflowSectionWorkflowVersionsArgs, "orderBy">
  >;
  allWorkflowSections?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowSectionsConnection"]>,
    "allWorkflowSections",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllWorkflowSectionsArgs, "orderBy">
  >;
  allWorkflowSourceTags?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowSourceTagsConnection"]>,
    "allWorkflowSourceTags",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllWorkflowSourceTagsArgs, "orderBy">
  >;
  allWorkflowSourceWorkflowVersions?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowSourceWorkflowVersionsConnection"]>,
    "allWorkflowSourceWorkflowVersions",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllWorkflowSourceWorkflowVersionsArgs, "orderBy">
  >;
  allWorkflowSources?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowSourcesConnection"]>,
    "allWorkflowSources",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllWorkflowSourcesArgs, "orderBy">
  >;
  allWorkflowVersions?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowVersionsConnection"]>,
    "allWorkflowVersions",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllWorkflowVersionsArgs, "orderBy">
  >;
  allWorkflows?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowsConnection"]>,
    "allWorkflows",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAllWorkflowsArgs, "orderBy">
  >;
  analysisTableById?: SubscriptionResolver<
    Maybe<ResolversTypes["AnalysisTable"]>,
    "analysisTableById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAnalysisTableByIdArgs, "id">
  >;
  analysisTableGroupById?: SubscriptionResolver<
    Maybe<ResolversTypes["AnalysisTableGroup"]>,
    "analysisTableGroupById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAnalysisTableGroupByIdArgs, "id">
  >;
  analysisTableRowAttachResultById?: SubscriptionResolver<
    Maybe<ResolversTypes["AnalysisTableRowAttachResult"]>,
    "analysisTableRowAttachResultById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAnalysisTableRowAttachResultByIdArgs, "id">
  >;
  analysisTableRowById?: SubscriptionResolver<
    Maybe<ResolversTypes["AnalysisTableRow"]>,
    "analysisTableRowById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAnalysisTableRowByIdArgs, "id">
  >;
  analysisTableRowByTaskId?: SubscriptionResolver<
    Maybe<ResolversTypes["AnalysisTableRow"]>,
    "analysisTableRowByTaskId",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAnalysisTableRowByTaskIdArgs, "taskId">
  >;
  applicationUserByEmail?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    "applicationUserByEmail",
    ParentType,
    ContextType,
    RequireFields<SubscriptionApplicationUserByEmailArgs, "email">
  >;
  applicationUserById?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    "applicationUserById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionApplicationUserByIdArgs, "id">
  >;
  applicationUserByUsername?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    "applicationUserByUsername",
    ParentType,
    ContextType,
    RequireFields<SubscriptionApplicationUserByUsernameArgs, "username">
  >;
  applicationUserGroupById?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUserGroup"]>,
    "applicationUserGroupById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionApplicationUserGroupByIdArgs, "id">
  >;
  applicationUserGroupByUserIdAndGroupId?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUserGroup"]>,
    "applicationUserGroupByUserIdAndGroupId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionApplicationUserGroupByUserIdAndGroupIdArgs,
      "groupId" | "userId"
    >
  >;
  applicationUserMembershipById?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUserMembership"]>,
    "applicationUserMembershipById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionApplicationUserMembershipByIdArgs, "id">
  >;
  applicationUserMembershipByUserIdAndTenantId?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUserMembership"]>,
    "applicationUserMembershipByUserIdAndTenantId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionApplicationUserMembershipByUserIdAndTenantIdArgs,
      "tenantId" | "userId"
    >
  >;
  applicationUserMetadatumById?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUserMetadatum"]>,
    "applicationUserMetadatumById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionApplicationUserMetadatumByIdArgs, "id">
  >;
  applicationUserMetadatumByUserIdAndKey?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUserMetadatum"]>,
    "applicationUserMetadatumByUserIdAndKey",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionApplicationUserMetadatumByUserIdAndKeyArgs,
      "key" | "userId"
    >
  >;
  applicationUserSessionById?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUserSession"]>,
    "applicationUserSessionById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionApplicationUserSessionByIdArgs, "id">
  >;
  applicationUserUserPermissionById?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUserUserPermission"]>,
    "applicationUserUserPermissionById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionApplicationUserUserPermissionByIdArgs, "id">
  >;
  applicationUserUserPermissionByUserIdAndPermissionId?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUserUserPermission"]>,
    "applicationUserUserPermissionByUserIdAndPermissionId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionApplicationUserUserPermissionByUserIdAndPermissionIdArgs,
      "permissionId" | "userId"
    >
  >;
  assertUserAcceptedEula?: SubscriptionResolver<
    Maybe<ResolversTypes["Boolean"]>,
    "assertUserAcceptedEula",
    ParentType,
    ContextType
  >;
  assertUserCanSeeTenantInfo?: SubscriptionResolver<
    Maybe<ResolversTypes["Boolean"]>,
    "assertUserCanSeeTenantInfo",
    ParentType,
    ContextType,
    Partial<SubscriptionAssertUserCanSeeTenantInfoArgs>
  >;
  assertUserCanSeeUserInfo?: SubscriptionResolver<
    Maybe<ResolversTypes["Boolean"]>,
    "assertUserCanSeeUserInfo",
    ParentType,
    ContextType,
    Partial<SubscriptionAssertUserCanSeeUserInfoArgs>
  >;
  assertUserHasProjectSharedByTenant?: SubscriptionResolver<
    Maybe<ResolversTypes["Boolean"]>,
    "assertUserHasProjectSharedByTenant",
    ParentType,
    ContextType,
    Partial<SubscriptionAssertUserHasProjectSharedByTenantArgs>
  >;
  assertUserHasTenantOfId?: SubscriptionResolver<
    Maybe<ResolversTypes["Boolean"]>,
    "assertUserHasTenantOfId",
    ParentType,
    ContextType,
    Partial<SubscriptionAssertUserHasTenantOfIdArgs>
  >;
  assertUserIsInvitedToTenant?: SubscriptionResolver<
    Maybe<ResolversTypes["Boolean"]>,
    "assertUserIsInvitedToTenant",
    ParentType,
    ContextType,
    Partial<SubscriptionAssertUserIsInvitedToTenantArgs>
  >;
  authGroupById?: SubscriptionResolver<
    Maybe<ResolversTypes["AuthGroup"]>,
    "authGroupById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAuthGroupByIdArgs, "id">
  >;
  authGroupByName?: SubscriptionResolver<
    Maybe<ResolversTypes["AuthGroup"]>,
    "authGroupByName",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAuthGroupByNameArgs, "name">
  >;
  authGroupPermissionByGroupIdAndPermissionId?: SubscriptionResolver<
    Maybe<ResolversTypes["AuthGroupPermission"]>,
    "authGroupPermissionByGroupIdAndPermissionId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionAuthGroupPermissionByGroupIdAndPermissionIdArgs,
      "groupId" | "permissionId"
    >
  >;
  authGroupPermissionById?: SubscriptionResolver<
    Maybe<ResolversTypes["AuthGroupPermission"]>,
    "authGroupPermissionById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAuthGroupPermissionByIdArgs, "id">
  >;
  authPermissionByContentTypeIdAndCodename?: SubscriptionResolver<
    Maybe<ResolversTypes["AuthPermission"]>,
    "authPermissionByContentTypeIdAndCodename",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionAuthPermissionByContentTypeIdAndCodenameArgs,
      "codename" | "contentTypeId"
    >
  >;
  authPermissionById?: SubscriptionResolver<
    Maybe<ResolversTypes["AuthPermission"]>,
    "authPermissionById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAuthPermissionByIdArgs, "id">
  >;
  avatarById?: SubscriptionResolver<
    Maybe<ResolversTypes["Avatar"]>,
    "avatarById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAvatarByIdArgs, "id">
  >;
  avatarByUserId?: SubscriptionResolver<
    Maybe<ResolversTypes["Avatar"]>,
    "avatarByUserId",
    ParentType,
    ContextType,
    RequireFields<SubscriptionAvatarByUserIdArgs, "userId">
  >;
  awsBatchTaskByTaskexecutorPtrId?: SubscriptionResolver<
    Maybe<ResolversTypes["AwsBatchTask"]>,
    "awsBatchTaskByTaskexecutorPtrId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionAwsBatchTaskByTaskexecutorPtrIdArgs,
      "taskexecutorPtrId"
    >
  >;
  awsCognitoUserByUserexecutorPtrId?: SubscriptionResolver<
    Maybe<ResolversTypes["AwsCognitoUser"]>,
    "awsCognitoUserByUserexecutorPtrId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionAwsCognitoUserByUserexecutorPtrIdArgs,
      "userexecutorPtrId"
    >
  >;
  awsEcrImageByContainerimageexecutorPtrId?: SubscriptionResolver<
    Maybe<ResolversTypes["AwsEcrImage"]>,
    "awsEcrImageByContainerimageexecutorPtrId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionAwsEcrImageByContainerimageexecutorPtrIdArgs,
      "containerimageexecutorPtrId"
    >
  >;
  awsMockBatchTaskByAwstaskexecutorPtrId?: SubscriptionResolver<
    Maybe<ResolversTypes["AwsMockBatchTask"]>,
    "awsMockBatchTaskByAwstaskexecutorPtrId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionAwsMockBatchTaskByAwstaskexecutorPtrIdArgs,
      "awstaskexecutorPtrId"
    >
  >;
  awsMockEcrImageByAwscontainerimageexecutorPtrId?: SubscriptionResolver<
    Maybe<ResolversTypes["AwsMockEcrImage"]>,
    "awsMockEcrImageByAwscontainerimageexecutorPtrId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionAwsMockEcrImageByAwscontainerimageexecutorPtrIdArgs,
      "awscontainerimageexecutorPtrId"
    >
  >;
  awsMockS3FileByAwsfileexecutorPtrId?: SubscriptionResolver<
    Maybe<ResolversTypes["AwsMockS3File"]>,
    "awsMockS3FileByAwsfileexecutorPtrId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionAwsMockS3FileByAwsfileexecutorPtrIdArgs,
      "awsfileexecutorPtrId"
    >
  >;
  awsS3FileByFileexecutorPtrId?: SubscriptionResolver<
    Maybe<ResolversTypes["AwsS3File"]>,
    "awsS3FileByFileexecutorPtrId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionAwsS3FileByFileexecutorPtrIdArgs,
      "fileexecutorPtrId"
    >
  >;
  containerImageByExecutorId?: SubscriptionResolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    "containerImageByExecutorId",
    ParentType,
    ContextType,
    RequireFields<SubscriptionContainerImageByExecutorIdArgs, "executorId">
  >;
  containerImageById?: SubscriptionResolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    "containerImageById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionContainerImageByIdArgs, "id">
  >;
  containerImageByNameAndTag?: SubscriptionResolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    "containerImageByNameAndTag",
    ParentType,
    ContextType,
    RequireFields<SubscriptionContainerImageByNameAndTagArgs, "name" | "tag">
  >;
  containerImageByRepositoryAndTag?: SubscriptionResolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    "containerImageByRepositoryAndTag",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionContainerImageByRepositoryAndTagArgs,
      "repository" | "tag"
    >
  >;
  containerImageByToolboxVersionId?: SubscriptionResolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    "containerImageByToolboxVersionId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionContainerImageByToolboxVersionIdArgs,
      "toolboxVersionId"
    >
  >;
  costById?: SubscriptionResolver<
    Maybe<ResolversTypes["Cost"]>,
    "costById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionCostByIdArgs, "id">
  >;
  datasetById?: SubscriptionResolver<
    Maybe<ResolversTypes["Dataset"]>,
    "datasetById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionDatasetByIdArgs, "id">
  >;
  datasetByProjectIdAndPrefix?: SubscriptionResolver<
    Maybe<ResolversTypes["Dataset"]>,
    "datasetByProjectIdAndPrefix",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionDatasetByProjectIdAndPrefixArgs,
      "prefix" | "projectId"
    >
  >;
  datasetByRecordingsTableId?: SubscriptionResolver<
    Maybe<ResolversTypes["Dataset"]>,
    "datasetByRecordingsTableId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionDatasetByRecordingsTableIdArgs,
      "recordingsTableId"
    >
  >;
  datasetByTenantIdAndKey?: SubscriptionResolver<
    Maybe<ResolversTypes["Dataset"]>,
    "datasetByTenantIdAndKey",
    ParentType,
    ContextType,
    RequireFields<SubscriptionDatasetByTenantIdAndKeyArgs, "key" | "tenantId">
  >;
  datasetMetadatumById?: SubscriptionResolver<
    Maybe<ResolversTypes["DatasetMetadatum"]>,
    "datasetMetadatumById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionDatasetMetadatumByIdArgs, "id">
  >;
  datasetRecordingsTableById?: SubscriptionResolver<
    Maybe<ResolversTypes["DatasetRecordingsTable"]>,
    "datasetRecordingsTableById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionDatasetRecordingsTableByIdArgs, "id">
  >;
  datasetRecordingsTableColumnById?: SubscriptionResolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumn"]>,
    "datasetRecordingsTableColumnById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionDatasetRecordingsTableColumnByIdArgs, "id">
  >;
  datasetRecordingsTableColumnOrderById?: SubscriptionResolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumnOrder"]>,
    "datasetRecordingsTableColumnOrderById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionDatasetRecordingsTableColumnOrderByIdArgs, "id">
  >;
  datasetVersionById?: SubscriptionResolver<
    Maybe<ResolversTypes["DatasetVersion"]>,
    "datasetVersionById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionDatasetVersionByIdArgs, "id">
  >;
  djangoAdminLogById?: SubscriptionResolver<
    Maybe<ResolversTypes["DjangoAdminLog"]>,
    "djangoAdminLogById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionDjangoAdminLogByIdArgs, "id">
  >;
  djangoContentTypeByAppLabelAndModel?: SubscriptionResolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    "djangoContentTypeByAppLabelAndModel",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionDjangoContentTypeByAppLabelAndModelArgs,
      "appLabel" | "model"
    >
  >;
  djangoContentTypeById?: SubscriptionResolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    "djangoContentTypeById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionDjangoContentTypeByIdArgs, "id">
  >;
  djangoMigrationById?: SubscriptionResolver<
    Maybe<ResolversTypes["DjangoMigration"]>,
    "djangoMigrationById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionDjangoMigrationByIdArgs, "id">
  >;
  djangoSesSesstatByDate?: SubscriptionResolver<
    Maybe<ResolversTypes["DjangoSesSesstat"]>,
    "djangoSesSesstatByDate",
    ParentType,
    ContextType,
    RequireFields<SubscriptionDjangoSesSesstatByDateArgs, "date">
  >;
  djangoSesSesstatById?: SubscriptionResolver<
    Maybe<ResolversTypes["DjangoSesSesstat"]>,
    "djangoSesSesstatById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionDjangoSesSesstatByIdArgs, "id">
  >;
  djangoSessionBySessionKey?: SubscriptionResolver<
    Maybe<ResolversTypes["DjangoSession"]>,
    "djangoSessionBySessionKey",
    ParentType,
    ContextType,
    RequireFields<SubscriptionDjangoSessionBySessionKeyArgs, "sessionKey">
  >;
  drsFileexecutorById?: SubscriptionResolver<
    Maybe<ResolversTypes["DrsFileexecutor"]>,
    "drsFileexecutorById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionDrsFileexecutorByIdArgs, "id">
  >;
  drsMockfileexecutorByFileexecutorPtrId?: SubscriptionResolver<
    Maybe<ResolversTypes["DrsMockfileexecutor"]>,
    "drsMockfileexecutorByFileexecutorPtrId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionDrsMockfileexecutorByFileexecutorPtrIdArgs,
      "fileexecutorPtrId"
    >
  >;
  eulaVersionById?: SubscriptionResolver<
    Maybe<ResolversTypes["EulaVersion"]>,
    "eulaVersionById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionEulaVersionByIdArgs, "id">
  >;
  eulaVersionByVersion?: SubscriptionResolver<
    Maybe<ResolversTypes["EulaVersion"]>,
    "eulaVersionByVersion",
    ParentType,
    ContextType,
    RequireFields<SubscriptionEulaVersionByVersionArgs, "version">
  >;
  eulaVersionUserAcceptanceById?: SubscriptionResolver<
    Maybe<ResolversTypes["EulaVersionUserAcceptance"]>,
    "eulaVersionUserAcceptanceById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionEulaVersionUserAcceptanceByIdArgs, "id">
  >;
  eulaVersionUserAcceptanceByUserIdAndEulaVersionId?: SubscriptionResolver<
    Maybe<ResolversTypes["EulaVersionUserAcceptance"]>,
    "eulaVersionUserAcceptanceByUserIdAndEulaVersionId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionEulaVersionUserAcceptanceByUserIdAndEulaVersionIdArgs,
      "eulaVersionId" | "userId"
    >
  >;
  explorerQueryById?: SubscriptionResolver<
    Maybe<ResolversTypes["ExplorerQuery"]>,
    "explorerQueryById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionExplorerQueryByIdArgs, "id">
  >;
  explorerQueryfavoriteById?: SubscriptionResolver<
    Maybe<ResolversTypes["ExplorerQueryfavorite"]>,
    "explorerQueryfavoriteById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionExplorerQueryfavoriteByIdArgs, "id">
  >;
  explorerQueryfavoriteByQueryIdAndUserId?: SubscriptionResolver<
    Maybe<ResolversTypes["ExplorerQueryfavorite"]>,
    "explorerQueryfavoriteByQueryIdAndUserId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionExplorerQueryfavoriteByQueryIdAndUserIdArgs,
      "queryId" | "userId"
    >
  >;
  explorerQuerylogById?: SubscriptionResolver<
    Maybe<ResolversTypes["ExplorerQuerylog"]>,
    "explorerQuerylogById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionExplorerQuerylogByIdArgs, "id">
  >;
  fileActivityById?: SubscriptionResolver<
    Maybe<ResolversTypes["FileActivity"]>,
    "fileActivityById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionFileActivityByIdArgs, "id">
  >;
  fileById?: SubscriptionResolver<
    Maybe<ResolversTypes["File"]>,
    "fileById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionFileByIdArgs, "id">
  >;
  fileMetadatumById?: SubscriptionResolver<
    Maybe<ResolversTypes["FileMetadatum"]>,
    "fileMetadatumById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionFileMetadatumByIdArgs, "id">
  >;
  fileOutputGroupById?: SubscriptionResolver<
    Maybe<ResolversTypes["FileOutputGroup"]>,
    "fileOutputGroupById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionFileOutputGroupByIdArgs, "id">
  >;
  fileOutputGroupByTaskId?: SubscriptionResolver<
    Maybe<ResolversTypes["FileOutputGroup"]>,
    "fileOutputGroupByTaskId",
    ParentType,
    ContextType,
    RequireFields<SubscriptionFileOutputGroupByTaskIdArgs, "taskId">
  >;
  fileOutputGroupMetadatumById?: SubscriptionResolver<
    Maybe<ResolversTypes["FileOutputGroupMetadatum"]>,
    "fileOutputGroupMetadatumById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionFileOutputGroupMetadatumByIdArgs, "id">
  >;
  fileProcessingNotificationById?: SubscriptionResolver<
    Maybe<ResolversTypes["FileProcessingNotification"]>,
    "fileProcessingNotificationById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionFileProcessingNotificationByIdArgs, "id">
  >;
  fileRecordingGroupById?: SubscriptionResolver<
    Maybe<ResolversTypes["FileRecordingGroup"]>,
    "fileRecordingGroupById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionFileRecordingGroupByIdArgs, "id">
  >;
  fileRecordingGroupMetadatumById?: SubscriptionResolver<
    Maybe<ResolversTypes["FileRecordingGroupMetadatum"]>,
    "fileRecordingGroupMetadatumById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionFileRecordingGroupMetadatumByIdArgs, "id">
  >;
  getCurrentUser?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    "getCurrentUser",
    ParentType,
    ContextType
  >;
  getCurrentUserId?: SubscriptionResolver<
    Maybe<ResolversTypes["UUID"]>,
    "getCurrentUserId",
    ParentType,
    ContextType
  >;
  getTenantFromApplicationUser?: SubscriptionResolver<
    Maybe<ResolversTypes["Int"]>,
    "getTenantFromApplicationUser",
    ParentType,
    ContextType
  >;
  getUserActiveSession?: SubscriptionResolver<
    Maybe<ResolversTypes["ApplicationUserSession"]>,
    "getUserActiveSession",
    ParentType,
    ContextType,
    RequireFields<SubscriptionGetUserActiveSessionArgs, "userid">
  >;
  metadataValueById?: SubscriptionResolver<
    Maybe<ResolversTypes["MetadataValue"]>,
    "metadataValueById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionMetadataValueByIdArgs, "id">
  >;
  metadatumById?: SubscriptionResolver<
    Maybe<ResolversTypes["Metadatum"]>,
    "metadatumById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionMetadatumByIdArgs, "id">
  >;
  notificationById?: SubscriptionResolver<
    Maybe<ResolversTypes["Notification"]>,
    "notificationById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionNotificationByIdArgs, "id">
  >;
  outputGroupFileById?: SubscriptionResolver<
    Maybe<ResolversTypes["OutputGroupFile"]>,
    "outputGroupFileById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionOutputGroupFileByIdArgs, "id">
  >;
  projectById?: SubscriptionResolver<
    Maybe<ResolversTypes["Project"]>,
    "projectById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionProjectByIdArgs, "id">
  >;
  projectByKeyAndTenantId?: SubscriptionResolver<
    Maybe<ResolversTypes["Project"]>,
    "projectByKeyAndTenantId",
    ParentType,
    ContextType,
    RequireFields<SubscriptionProjectByKeyAndTenantIdArgs, "key" | "tenantId">
  >;
  projectClonedNotificationById?: SubscriptionResolver<
    Maybe<ResolversTypes["ProjectClonedNotification"]>,
    "projectClonedNotificationById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionProjectClonedNotificationByIdArgs, "id">
  >;
  projectSharedNotificationById?: SubscriptionResolver<
    Maybe<ResolversTypes["ProjectSharedNotification"]>,
    "projectSharedNotificationById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionProjectSharedNotificationByIdArgs, "id">
  >;
  query?: SubscriptionResolver<
    ResolversTypes["Query"],
    "query",
    ParentType,
    ContextType
  >;
  recordingGroupFileById?: SubscriptionResolver<
    Maybe<ResolversTypes["RecordingGroupFile"]>,
    "recordingGroupFileById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionRecordingGroupFileByIdArgs, "id">
  >;
  roleChangeNotificationById?: SubscriptionResolver<
    Maybe<ResolversTypes["RoleChangeNotification"]>,
    "roleChangeNotificationById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionRoleChangeNotificationByIdArgs, "id">
  >;
  runById?: SubscriptionResolver<
    Maybe<ResolversTypes["Run"]>,
    "runById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionRunByIdArgs, "id">
  >;
  sessionIsActiveSession?: SubscriptionResolver<
    Maybe<ResolversTypes["Boolean"]>,
    "sessionIsActiveSession",
    ParentType,
    ContextType,
    RequireFields<SubscriptionSessionIsActiveSessionArgs, "id">
  >;
  tagById?: SubscriptionResolver<
    Maybe<ResolversTypes["Tag"]>,
    "tagById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTagByIdArgs, "id">
  >;
  taskActivityById?: SubscriptionResolver<
    Maybe<ResolversTypes["TaskActivity"]>,
    "taskActivityById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTaskActivityByIdArgs, "id">
  >;
  taskActivityByTaskId?: SubscriptionResolver<
    Maybe<ResolversTypes["TaskActivity"]>,
    "taskActivityByTaskId",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTaskActivityByTaskIdArgs, "taskId">
  >;
  taskByExecutorId?: SubscriptionResolver<
    Maybe<ResolversTypes["Task"]>,
    "taskByExecutorId",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTaskByExecutorIdArgs, "executorId">
  >;
  taskById?: SubscriptionResolver<
    Maybe<ResolversTypes["Task"]>,
    "taskById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTaskByIdArgs, "id">
  >;
  taskResourceById?: SubscriptionResolver<
    Maybe<ResolversTypes["TaskResource"]>,
    "taskResourceById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTaskResourceByIdArgs, "id">
  >;
  taskSourceById?: SubscriptionResolver<
    Maybe<ResolversTypes["TaskSource"]>,
    "taskSourceById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTaskSourceByIdArgs, "id">
  >;
  taskStatusLogById?: SubscriptionResolver<
    Maybe<ResolversTypes["TaskStatusLog"]>,
    "taskStatusLogById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTaskStatusLogByIdArgs, "id">
  >;
  taskStatusLogByTaskIdAndStatus?: SubscriptionResolver<
    Maybe<ResolversTypes["TaskStatusLog"]>,
    "taskStatusLogByTaskIdAndStatus",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionTaskStatusLogByTaskIdAndStatusArgs,
      "status" | "taskId"
    >
  >;
  tenantById?: SubscriptionResolver<
    Maybe<ResolversTypes["Tenant"]>,
    "tenantById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTenantByIdArgs, "id">
  >;
  tenantByKey?: SubscriptionResolver<
    Maybe<ResolversTypes["Tenant"]>,
    "tenantByKey",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTenantByKeyArgs, "key">
  >;
  tenantByName?: SubscriptionResolver<
    Maybe<ResolversTypes["Tenant"]>,
    "tenantByName",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTenantByNameArgs, "name">
  >;
  tenantContractAllowanceById?: SubscriptionResolver<
    Maybe<ResolversTypes["TenantContractAllowance"]>,
    "tenantContractAllowanceById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTenantContractAllowanceByIdArgs, "id">
  >;
  tenantContractAllowanceByTenantContractTransactionIdAndQuotaType?: SubscriptionResolver<
    Maybe<ResolversTypes["TenantContractAllowance"]>,
    "tenantContractAllowanceByTenantContractTransactionIdAndQuotaType",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionTenantContractAllowanceByTenantContractTransactionIdAndQuotaTypeArgs,
      "quotaType" | "tenantContractTransactionId"
    >
  >;
  tenantContractById?: SubscriptionResolver<
    Maybe<ResolversTypes["TenantContract"]>,
    "tenantContractById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTenantContractByIdArgs, "id">
  >;
  tenantContractByName?: SubscriptionResolver<
    Maybe<ResolversTypes["TenantContract"]>,
    "tenantContractByName",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTenantContractByNameArgs, "name">
  >;
  tenantContractTransactionById?: SubscriptionResolver<
    Maybe<ResolversTypes["TenantContractTransaction"]>,
    "tenantContractTransactionById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTenantContractTransactionByIdArgs, "id">
  >;
  tenantMockuserexecutorByUserexecutorPtrId?: SubscriptionResolver<
    Maybe<ResolversTypes["TenantMockuserexecutor"]>,
    "tenantMockuserexecutorByUserexecutorPtrId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionTenantMockuserexecutorByUserexecutorPtrIdArgs,
      "userexecutorPtrId"
    >
  >;
  tenantMonthlyConsumedResourceById?: SubscriptionResolver<
    Maybe<ResolversTypes["TenantMonthlyConsumedResource"]>,
    "tenantMonthlyConsumedResourceById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTenantMonthlyConsumedResourceByIdArgs, "id">
  >;
  tenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract?: SubscriptionResolver<
    Maybe<ResolversTypes["TenantMonthlyConsumedResource"]>,
    "tenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContract",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionTenantMonthlyConsumedResourceByUserIdAndTenantIdAndMonthAndQuotaTypeAndTenantContractIdAndMonthInActiveContractArgs,
      | "month"
      | "monthInActiveContract"
      | "quotaType"
      | "tenantContractId"
      | "tenantId"
      | "userId"
    >
  >;
  tenantUserMembershipNotificationById?: SubscriptionResolver<
    Maybe<ResolversTypes["TenantUserMembershipNotification"]>,
    "tenantUserMembershipNotificationById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTenantUserMembershipNotificationByIdArgs, "id">
  >;
  tenantUserexecutorById?: SubscriptionResolver<
    Maybe<ResolversTypes["TenantUserexecutor"]>,
    "tenantUserexecutorById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTenantUserexecutorByIdArgs, "id">
  >;
  tesContainerimageexecutorById?: SubscriptionResolver<
    Maybe<ResolversTypes["TesContainerimageexecutor"]>,
    "tesContainerimageexecutorById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTesContainerimageexecutorByIdArgs, "id">
  >;
  tesMockcontainerimageexecutorByContainerimageexecutorPtrId?: SubscriptionResolver<
    Maybe<ResolversTypes["TesMockcontainerimageexecutor"]>,
    "tesMockcontainerimageexecutorByContainerimageexecutorPtrId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionTesMockcontainerimageexecutorByContainerimageexecutorPtrIdArgs,
      "containerimageexecutorPtrId"
    >
  >;
  tesMocktaskexecutorByTaskexecutorPtrId?: SubscriptionResolver<
    Maybe<ResolversTypes["TesMocktaskexecutor"]>,
    "tesMocktaskexecutorByTaskexecutorPtrId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionTesMocktaskexecutorByTaskexecutorPtrIdArgs,
      "taskexecutorPtrId"
    >
  >;
  tesTaskexecutorById?: SubscriptionResolver<
    Maybe<ResolversTypes["TesTaskexecutor"]>,
    "tesTaskexecutorById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionTesTaskexecutorByIdArgs, "id">
  >;
  toolById?: SubscriptionResolver<
    Maybe<ResolversTypes["Tool"]>,
    "toolById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolByIdArgs, "id">
  >;
  toolByKey?: SubscriptionResolver<
    Maybe<ResolversTypes["Tool"]>,
    "toolByKey",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolByKeyArgs, "key">
  >;
  toolCategoryById?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolCategory"]>,
    "toolCategoryById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolCategoryByIdArgs, "id">
  >;
  toolCategoryByKey?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolCategory"]>,
    "toolCategoryByKey",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolCategoryByKeyArgs, "key">
  >;
  toolDisplayCategoryById?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolDisplayCategory"]>,
    "toolDisplayCategoryById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolDisplayCategoryByIdArgs, "id">
  >;
  toolDisplayCategoryByToolIdAndToolcategoryId?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolDisplayCategory"]>,
    "toolDisplayCategoryByToolIdAndToolcategoryId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionToolDisplayCategoryByToolIdAndToolcategoryIdArgs,
      "toolId" | "toolcategoryId"
    >
  >;
  toolParameterById?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolParameter"]>,
    "toolParameterById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolParameterByIdArgs, "id">
  >;
  toolResourceById?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolResource"]>,
    "toolResourceById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolResourceByIdArgs, "id">
  >;
  toolResourceByTypeAndToolVersionId?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolResource"]>,
    "toolResourceByTypeAndToolVersionId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionToolResourceByTypeAndToolVersionIdArgs,
      "toolVersionId" | "type"
    >
  >;
  toolResultById?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolResult"]>,
    "toolResultById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolResultByIdArgs, "id">
  >;
  toolSecretById?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolSecret"]>,
    "toolSecretById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolSecretByIdArgs, "id">
  >;
  toolSourceById?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolSource"]>,
    "toolSourceById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolSourceByIdArgs, "id">
  >;
  toolTenantById?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolTenant"]>,
    "toolTenantById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolTenantByIdArgs, "id">
  >;
  toolTenantByToolIdAndTenantId?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolTenant"]>,
    "toolTenantByToolIdAndTenantId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionToolTenantByToolIdAndTenantIdArgs,
      "tenantId" | "toolId"
    >
  >;
  toolVersionById?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    "toolVersionById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolVersionByIdArgs, "id">
  >;
  toolVersionByToolIdAndVersion?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    "toolVersionByToolIdAndVersion",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionToolVersionByToolIdAndVersionArgs,
      "toolId" | "version"
    >
  >;
  toolboxById?: SubscriptionResolver<
    Maybe<ResolversTypes["Toolbox"]>,
    "toolboxById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolboxByIdArgs, "id">
  >;
  toolboxByKey?: SubscriptionResolver<
    Maybe<ResolversTypes["Toolbox"]>,
    "toolboxByKey",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolboxByKeyArgs, "key">
  >;
  toolboxExitcodeByCode?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolboxExitcode"]>,
    "toolboxExitcodeByCode",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolboxExitcodeByCodeArgs, "code">
  >;
  toolboxExitcodeById?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolboxExitcode"]>,
    "toolboxExitcodeById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolboxExitcodeByIdArgs, "id">
  >;
  toolboxTenantById?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolboxTenant"]>,
    "toolboxTenantById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolboxTenantByIdArgs, "id">
  >;
  toolboxTenantByToolboxIdAndTenantId?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolboxTenant"]>,
    "toolboxTenantByToolboxIdAndTenantId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionToolboxTenantByToolboxIdAndTenantIdArgs,
      "tenantId" | "toolboxId"
    >
  >;
  toolboxVersionById?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    "toolboxVersionById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolboxVersionByIdArgs, "id">
  >;
  toolboxVersionByToolKeyAndToolVersionVersion?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    "toolboxVersionByToolKeyAndToolVersionVersion",
    ParentType,
    ContextType,
    Partial<SubscriptionToolboxVersionByToolKeyAndToolVersionVersionArgs>
  >;
  toolboxVersionByToolboxIdAndVersion?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    "toolboxVersionByToolboxIdAndVersion",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionToolboxVersionByToolboxIdAndVersionArgs,
      "toolboxId" | "version"
    >
  >;
  toolboxVersionExitcodeById?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolboxVersionExitcode"]>,
    "toolboxVersionExitcodeById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolboxVersionExitcodeByIdArgs, "id">
  >;
  toolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeId?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolboxVersionExitcode"]>,
    "toolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionToolboxVersionExitcodeByToolboxversionIdAndToolboxexitcodeIdArgs,
      "toolboxexitcodeId" | "toolboxversionId"
    >
  >;
  toolboxVersionToolVersionById?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolboxVersionToolVersion"]>,
    "toolboxVersionToolVersionById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionToolboxVersionToolVersionByIdArgs, "id">
  >;
  toolboxVersionToolVersionByToolboxversionIdAndToolversionId?: SubscriptionResolver<
    Maybe<ResolversTypes["ToolboxVersionToolVersion"]>,
    "toolboxVersionToolVersionByToolboxversionIdAndToolversionId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionToolboxVersionToolVersionByToolboxversionIdAndToolversionIdArgs,
      "toolboxversionId" | "toolversionId"
    >
  >;
  userExists?: SubscriptionResolver<
    Maybe<ResolversTypes["Boolean"]>,
    "userExists",
    ParentType,
    ContextType
  >;
  userHasActiveSession?: SubscriptionResolver<
    Maybe<ResolversTypes["Boolean"]>,
    "userHasActiveSession",
    ParentType,
    ContextType,
    RequireFields<SubscriptionUserHasActiveSessionArgs, "userid">
  >;
  userIdIsCurrentUser?: SubscriptionResolver<
    Maybe<ResolversTypes["Boolean"]>,
    "userIdIsCurrentUser",
    ParentType,
    ContextType,
    Partial<SubscriptionUserIdIsCurrentUserArgs>
  >;
  userIdIsSettingsGlobalUserId?: SubscriptionResolver<
    Maybe<ResolversTypes["Boolean"]>,
    "userIdIsSettingsGlobalUserId",
    ParentType,
    ContextType,
    Partial<SubscriptionUserIdIsSettingsGlobalUserIdArgs>
  >;
  workflowById?: SubscriptionResolver<
    Maybe<ResolversTypes["Workflow"]>,
    "workflowById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionWorkflowByIdArgs, "id">
  >;
  workflowDatumById?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowDatum"]>,
    "workflowDatumById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionWorkflowDatumByIdArgs, "id">
  >;
  workflowResultById?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowResult"]>,
    "workflowResultById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionWorkflowResultByIdArgs, "id">
  >;
  workflowResultTagById?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowResultTag"]>,
    "workflowResultTagById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionWorkflowResultTagByIdArgs, "id">
  >;
  workflowResultTagByResultIdAndTagId?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowResultTag"]>,
    "workflowResultTagByResultIdAndTagId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionWorkflowResultTagByResultIdAndTagIdArgs,
      "resultId" | "tagId"
    >
  >;
  workflowResultWorkflowVersionById?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowResultWorkflowVersion"]>,
    "workflowResultWorkflowVersionById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionWorkflowResultWorkflowVersionByIdArgs, "id">
  >;
  workflowResultWorkflowVersionByResultIdAndWorkflowversionId?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowResultWorkflowVersion"]>,
    "workflowResultWorkflowVersionByResultIdAndWorkflowversionId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionWorkflowResultWorkflowVersionByResultIdAndWorkflowversionIdArgs,
      "resultId" | "workflowversionId"
    >
  >;
  workflowSectionBlockById?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowSectionBlock"]>,
    "workflowSectionBlockById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionWorkflowSectionBlockByIdArgs, "id">
  >;
  workflowSectionBlockSectionByBlockIdAndSectionId?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowSectionBlockSection"]>,
    "workflowSectionBlockSectionByBlockIdAndSectionId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionWorkflowSectionBlockSectionByBlockIdAndSectionIdArgs,
      "blockId" | "sectionId"
    >
  >;
  workflowSectionBlockSectionById?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowSectionBlockSection"]>,
    "workflowSectionBlockSectionById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionWorkflowSectionBlockSectionByIdArgs, "id">
  >;
  workflowSectionById?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowSection"]>,
    "workflowSectionById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionWorkflowSectionByIdArgs, "id">
  >;
  workflowSectionWorkflowVersionById?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowSectionWorkflowVersion"]>,
    "workflowSectionWorkflowVersionById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionWorkflowSectionWorkflowVersionByIdArgs, "id">
  >;
  workflowSectionWorkflowVersionBySectionIdAndWorkflowversionId?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowSectionWorkflowVersion"]>,
    "workflowSectionWorkflowVersionBySectionIdAndWorkflowversionId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionWorkflowSectionWorkflowVersionBySectionIdAndWorkflowversionIdArgs,
      "sectionId" | "workflowversionId"
    >
  >;
  workflowSourceById?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowSource"]>,
    "workflowSourceById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionWorkflowSourceByIdArgs, "id">
  >;
  workflowSourceTagById?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowSourceTag"]>,
    "workflowSourceTagById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionWorkflowSourceTagByIdArgs, "id">
  >;
  workflowSourceTagBySourceIdAndTagId?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowSourceTag"]>,
    "workflowSourceTagBySourceIdAndTagId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionWorkflowSourceTagBySourceIdAndTagIdArgs,
      "sourceId" | "tagId"
    >
  >;
  workflowSourceWorkflowVersionById?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowSourceWorkflowVersion"]>,
    "workflowSourceWorkflowVersionById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionWorkflowSourceWorkflowVersionByIdArgs, "id">
  >;
  workflowSourceWorkflowVersionBySourceIdAndWorkflowversionId?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowSourceWorkflowVersion"]>,
    "workflowSourceWorkflowVersionBySourceIdAndWorkflowversionId",
    ParentType,
    ContextType,
    RequireFields<
      SubscriptionWorkflowSourceWorkflowVersionBySourceIdAndWorkflowversionIdArgs,
      "sourceId" | "workflowversionId"
    >
  >;
  workflowVersionById?: SubscriptionResolver<
    Maybe<ResolversTypes["WorkflowVersion"]>,
    "workflowVersionById",
    ParentType,
    ContextType,
    RequireFields<SubscriptionWorkflowVersionByIdArgs, "id">
  >;
};

export type TagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Tag"] = ResolversParentTypes["Tag"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["BigInt"], ParentType, ContextType>;
  label?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  parameters?: Resolver<Maybe<ResolversTypes["JSON"]>, ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes["BigInt"]>, ParentType, ContextType>;
  tagByParentId?: Resolver<
    Maybe<ResolversTypes["Tag"]>,
    ParentType,
    ContextType
  >;
  tagsByParentId?: Resolver<
    ResolversTypes["TagsConnection"],
    ParentType,
    ContextType,
    RequireFields<TagTagsByParentIdArgs, "orderBy">
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  workflowResultTagsByTagId?: Resolver<
    ResolversTypes["WorkflowResultTagsConnection"],
    ParentType,
    ContextType,
    RequireFields<TagWorkflowResultTagsByTagIdArgs, "orderBy">
  >;
  workflowSourceTagsByTagId?: Resolver<
    ResolversTypes["WorkflowSourceTagsConnection"],
    ParentType,
    ContextType,
    RequireFields<TagWorkflowSourceTagsByTagIdArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TagsConnection"] = ResolversParentTypes["TagsConnection"],
> = {
  edges?: Resolver<Array<ResolversTypes["TagsEdge"]>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes["Tag"]>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TagsEdge"] = ResolversParentTypes["TagsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["Tag"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["Task"] = ResolversParentTypes["Task"],
> = {
  analysisTableRowByTaskId?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRow"]>,
    ParentType,
    ContextType
  >;
  analysisTableRowsByTaskId?: Resolver<
    ResolversTypes["AnalysisTableRowsConnection"],
    ParentType,
    ContextType,
    RequireFields<TaskAnalysisTableRowsByTaskIdArgs, "orderBy">
  >;
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  cloned?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  containerImageByContainerImageId?: Resolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType
  >;
  containerImageId?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  created?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  credits?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  datasetByDatasetId?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType
  >;
  datasetId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  errorDescription?: Resolver<
    Maybe<ResolversTypes["JSON"]>,
    ParentType,
    ContextType
  >;
  executorId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  fileOutputGroupByTaskId?: Resolver<
    Maybe<ResolversTypes["FileOutputGroup"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroupsByTaskId?: Resolver<
    ResolversTypes["FileOutputGroupsConnection"],
    ParentType,
    ContextType,
    RequireFields<TaskFileOutputGroupsByTaskIdArgs, "orderBy">
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  inputsHash?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  metadataValuesByTaskId?: Resolver<
    ResolversTypes["MetadataValuesConnection"],
    ParentType,
    ContextType,
    RequireFields<TaskMetadataValuesByTaskIdArgs, "orderBy">
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  taskActivitiesByTaskId?: Resolver<
    ResolversTypes["TaskActivitiesConnection"],
    ParentType,
    ContextType,
    RequireFields<TaskTaskActivitiesByTaskIdArgs, "orderBy">
  >;
  taskActivityByTaskId?: Resolver<
    Maybe<ResolversTypes["TaskActivity"]>,
    ParentType,
    ContextType
  >;
  taskParameters?: Resolver<
    Maybe<ResolversTypes["JSON"]>,
    ParentType,
    ContextType
  >;
  taskResourcesByTaskId?: Resolver<
    ResolversTypes["TaskResourcesConnection"],
    ParentType,
    ContextType,
    RequireFields<TaskTaskResourcesByTaskIdArgs, "orderBy">
  >;
  taskSourcesByTaskId?: Resolver<
    ResolversTypes["TaskSourcesConnection"],
    ParentType,
    ContextType,
    RequireFields<TaskTaskSourcesByTaskIdArgs, "orderBy">
  >;
  taskStatusLogsByTaskId?: Resolver<
    ResolversTypes["TaskStatusLogsConnection"],
    ParentType,
    ContextType,
    RequireFields<TaskTaskStatusLogsByTaskIdArgs, "orderBy">
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  tesTaskexecutorByExecutorId?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutor"]>,
    ParentType,
    ContextType
  >;
  toolExitcode?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  toolExitcodeDescription?: Resolver<
    Maybe<ResolversTypes["JSON"]>,
    ParentType,
    ContextType
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  toolVersionId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskActivitiesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TaskActivitiesConnection"] = ResolversParentTypes["TaskActivitiesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["TaskActivitiesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["TaskActivity"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskActivitiesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TaskActivitiesEdge"] = ResolversParentTypes["TaskActivitiesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["TaskActivity"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskActivityResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TaskActivity"] = ResolversParentTypes["TaskActivity"],
> = {
  cloned?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  costByCostId?: Resolver<
    Maybe<ResolversTypes["Cost"]>,
    ParentType,
    ContextType
  >;
  costId?: Resolver<Maybe<ResolversTypes["BigInt"]>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes["Interval"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  instanceType?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  taskId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskResourceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TaskResource"] = ResolversParentTypes["TaskResource"],
> = {
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  taskId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  type?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  value?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskResourcesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TaskResourcesConnection"] = ResolversParentTypes["TaskResourcesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["TaskResourcesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["TaskResource"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskResourcesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TaskResourcesEdge"] = ResolversParentTypes["TaskResourcesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["TaskResource"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskSourceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TaskSource"] = ResolversParentTypes["TaskSource"],
> = {
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  taskId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  toolSourceByToolSourceId?: Resolver<
    Maybe<ResolversTypes["ToolSource"]>,
    ParentType,
    ContextType
  >;
  toolSourceId?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskSourcesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TaskSourcesConnection"] = ResolversParentTypes["TaskSourcesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["TaskSourcesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["TaskSource"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskSourcesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TaskSourcesEdge"] = ResolversParentTypes["TaskSourcesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["TaskSource"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskStatusLogResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TaskStatusLog"] = ResolversParentTypes["TaskStatusLog"],
> = {
  date?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  status?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  taskId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskStatusLogsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TaskStatusLogsConnection"] = ResolversParentTypes["TaskStatusLogsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["TaskStatusLogsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["TaskStatusLog"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskStatusLogsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TaskStatusLogsEdge"] = ResolversParentTypes["TaskStatusLogsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["TaskStatusLog"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TasksConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TasksConnection"] = ResolversParentTypes["TasksConnection"],
> = {
  edges?: Resolver<Array<ResolversTypes["TasksEdge"]>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes["Task"]>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TasksEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TasksEdge"] = ResolversParentTypes["TasksEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["Task"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["Tenant"] = ResolversParentTypes["Tenant"],
> = {
  activeStorageSize?: Resolver<
    Maybe<ResolversTypes["BigInt"]>,
    ParentType,
    ContextType
  >;
  analysisTableGroupsByTenantId?: Resolver<
    ResolversTypes["AnalysisTableGroupsConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantAnalysisTableGroupsByTenantIdArgs, "orderBy">
  >;
  analysisTableRowsByTenantId?: Resolver<
    ResolversTypes["AnalysisTableRowsConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantAnalysisTableRowsByTenantIdArgs, "orderBy">
  >;
  analysisTablesByTenantId?: Resolver<
    ResolversTypes["AnalysisTablesConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantAnalysisTablesByTenantIdArgs, "orderBy">
  >;
  applicationUserMembershipsByTenantId?: Resolver<
    ResolversTypes["ApplicationUserMembershipsConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantApplicationUserMembershipsByTenantIdArgs, "orderBy">
  >;
  applicationUserSessionsByTenantId?: Resolver<
    ResolversTypes["ApplicationUserSessionsConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantApplicationUserSessionsByTenantIdArgs, "orderBy">
  >;
  archivedStorageSize?: Resolver<
    Maybe<ResolversTypes["BigInt"]>,
    ParentType,
    ContextType
  >;
  costsByTenantId?: Resolver<
    ResolversTypes["CostsConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantCostsByTenantIdArgs, "orderBy">
  >;
  created?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  datasetMetadataByTenantId?: Resolver<
    ResolversTypes["DatasetMetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantDatasetMetadataByTenantIdArgs, "orderBy">
  >;
  datasetRecordingsTableColumnOrdersByTenantId?: Resolver<
    ResolversTypes["DatasetRecordingsTableColumnOrdersConnection"],
    ParentType,
    ContextType,
    RequireFields<
      TenantDatasetRecordingsTableColumnOrdersByTenantIdArgs,
      "orderBy"
    >
  >;
  datasetRecordingsTableColumnsByTenantId?: Resolver<
    ResolversTypes["DatasetRecordingsTableColumnsConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantDatasetRecordingsTableColumnsByTenantIdArgs, "orderBy">
  >;
  datasetRecordingsTablesByTenantId?: Resolver<
    ResolversTypes["DatasetRecordingsTablesConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantDatasetRecordingsTablesByTenantIdArgs, "orderBy">
  >;
  datasetVersionsByTenantId?: Resolver<
    ResolversTypes["DatasetVersionsConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantDatasetVersionsByTenantIdArgs, "orderBy">
  >;
  datasetsByTenantId?: Resolver<
    ResolversTypes["DatasetsConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantDatasetsByTenantIdArgs, "orderBy">
  >;
  fileActivitiesByTenantId?: Resolver<
    ResolversTypes["FileActivitiesConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantFileActivitiesByTenantIdArgs, "orderBy">
  >;
  fileMetadataByTenantId?: Resolver<
    ResolversTypes["FileMetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantFileMetadataByTenantIdArgs, "orderBy">
  >;
  fileOutputGroupMetadataByTenantId?: Resolver<
    ResolversTypes["FileOutputGroupMetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantFileOutputGroupMetadataByTenantIdArgs, "orderBy">
  >;
  fileOutputGroupsByTenantId?: Resolver<
    ResolversTypes["FileOutputGroupsConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantFileOutputGroupsByTenantIdArgs, "orderBy">
  >;
  fileRecordingGroupMetadataByTenantId?: Resolver<
    ResolversTypes["FileRecordingGroupMetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantFileRecordingGroupMetadataByTenantIdArgs, "orderBy">
  >;
  fileRecordingGroupsByTenantId?: Resolver<
    ResolversTypes["FileRecordingGroupsConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantFileRecordingGroupsByTenantIdArgs, "orderBy">
  >;
  filesByTenantId?: Resolver<
    ResolversTypes["FilesConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantFilesByTenantIdArgs, "orderBy">
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  internal?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  key?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  metadataByTenantId?: Resolver<
    ResolversTypes["MetadataConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantMetadataByTenantIdArgs, "orderBy">
  >;
  metadataValuesByTenantId?: Resolver<
    ResolversTypes["MetadataValuesConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantMetadataValuesByTenantIdArgs, "orderBy">
  >;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  projectsByTenantId?: Resolver<
    ResolversTypes["ProjectsConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantProjectsByTenantIdArgs, "orderBy">
  >;
  recordingGroupFilesByTenantId?: Resolver<
    ResolversTypes["RecordingGroupFilesConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantRecordingGroupFilesByTenantIdArgs, "orderBy">
  >;
  roleChangeNotificationsByTenantId?: Resolver<
    ResolversTypes["RoleChangeNotificationsConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantRoleChangeNotificationsByTenantIdArgs, "orderBy">
  >;
  runsByTenantId?: Resolver<
    ResolversTypes["RunsConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantRunsByTenantIdArgs, "orderBy">
  >;
  status?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  tagsByTenantId?: Resolver<
    ResolversTypes["TagsConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantTagsByTenantIdArgs, "orderBy">
  >;
  tasksByTenantId?: Resolver<
    ResolversTypes["TasksConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantTasksByTenantIdArgs, "orderBy">
  >;
  tenantContractsByTenantId?: Resolver<
    ResolversTypes["TenantContractsConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantTenantContractsByTenantIdArgs, "orderBy">
  >;
  tenantMonthlyConsumedResourcesByTenantId?: Resolver<
    ResolversTypes["TenantMonthlyConsumedResourcesConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantTenantMonthlyConsumedResourcesByTenantIdArgs, "orderBy">
  >;
  tenantType?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  tenantUserMembershipNotificationsByTenantId?: Resolver<
    ResolversTypes["TenantUserMembershipNotificationsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      TenantTenantUserMembershipNotificationsByTenantIdArgs,
      "orderBy"
    >
  >;
  toolTenantsByTenantId?: Resolver<
    ResolversTypes["ToolTenantsConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantToolTenantsByTenantIdArgs, "orderBy">
  >;
  toolboxTenantsByTenantId?: Resolver<
    ResolversTypes["ToolboxTenantsConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantToolboxTenantsByTenantIdArgs, "orderBy">
  >;
  workflowsByTenantId?: Resolver<
    ResolversTypes["WorkflowsConnection"],
    ParentType,
    ContextType,
    RequireFields<TenantWorkflowsByTenantIdArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantContractResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantContract"] = ResolversParentTypes["TenantContract"],
> = {
  created?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantContractTransactionsByTenantContractId?: Resolver<
    ResolversTypes["TenantContractTransactionsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      TenantContractTenantContractTransactionsByTenantContractIdArgs,
      "orderBy"
    >
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  tenantMonthlyConsumedResourcesByTenantContractId?: Resolver<
    ResolversTypes["TenantMonthlyConsumedResourcesConnection"],
    ParentType,
    ContextType,
    RequireFields<
      TenantContractTenantMonthlyConsumedResourcesByTenantContractIdArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantContractAllowanceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantContractAllowance"] = ResolversParentTypes["TenantContractAllowance"],
> = {
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  quotaType?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  tenantContractTransactionByTenantContractTransactionId?: Resolver<
    Maybe<ResolversTypes["TenantContractTransaction"]>,
    ParentType,
    ContextType
  >;
  tenantContractTransactionId?: Resolver<
    ResolversTypes["Int"],
    ParentType,
    ContextType
  >;
  value?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantContractAllowancesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantContractAllowancesConnection"] = ResolversParentTypes["TenantContractAllowancesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["TenantContractAllowancesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["TenantContractAllowance"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantContractAllowancesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantContractAllowancesEdge"] = ResolversParentTypes["TenantContractAllowancesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["TenantContractAllowance"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantContractTransactionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantContractTransaction"] = ResolversParentTypes["TenantContractTransaction"],
> = {
  created?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  price?: Resolver<ResolversTypes["BigFloat"], ParentType, ContextType>;
  tenantContractAllowancesByTenantContractTransactionId?: Resolver<
    ResolversTypes["TenantContractAllowancesConnection"],
    ParentType,
    ContextType,
    RequireFields<
      TenantContractTransactionTenantContractAllowancesByTenantContractTransactionIdArgs,
      "orderBy"
    >
  >;
  tenantContractByTenantContractId?: Resolver<
    Maybe<ResolversTypes["TenantContract"]>,
    ParentType,
    ContextType
  >;
  tenantContractId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantContractTransactionsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantContractTransactionsConnection"] = ResolversParentTypes["TenantContractTransactionsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["TenantContractTransactionsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["TenantContractTransaction"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantContractTransactionsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantContractTransactionsEdge"] = ResolversParentTypes["TenantContractTransactionsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["TenantContractTransaction"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantContractsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantContractsConnection"] = ResolversParentTypes["TenantContractsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["TenantContractsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["TenantContract"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantContractsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantContractsEdge"] = ResolversParentTypes["TenantContractsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["TenantContract"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantMockuserexecutorResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantMockuserexecutor"] = ResolversParentTypes["TenantMockuserexecutor"],
> = {
  tenantUserexecutorByUserexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TenantUserexecutor"]>,
    ParentType,
    ContextType
  >;
  userexecutorPtrId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantMockuserexecutorsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantMockuserexecutorsConnection"] = ResolversParentTypes["TenantMockuserexecutorsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["TenantMockuserexecutorsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["TenantMockuserexecutor"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantMockuserexecutorsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantMockuserexecutorsEdge"] = ResolversParentTypes["TenantMockuserexecutorsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["TenantMockuserexecutor"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantMonthlyConsumedResourceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantMonthlyConsumedResource"] = ResolversParentTypes["TenantMonthlyConsumedResource"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  month?: Resolver<ResolversTypes["Date"], ParentType, ContextType>;
  monthInActiveContract?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  percentage?: Resolver<
    Maybe<ResolversTypes["Float"]>,
    ParentType,
    ContextType
  >;
  quotaType?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantContractByTenantContractId?: Resolver<
    Maybe<ResolversTypes["TenantContract"]>,
    ParentType,
    ContextType
  >;
  tenantContractId?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantMonthlyConsumedResourcesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantMonthlyConsumedResourcesConnection"] = ResolversParentTypes["TenantMonthlyConsumedResourcesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["TenantMonthlyConsumedResourcesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["TenantMonthlyConsumedResource"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantMonthlyConsumedResourcesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantMonthlyConsumedResourcesEdge"] = ResolversParentTypes["TenantMonthlyConsumedResourcesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["TenantMonthlyConsumedResource"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantUserMembershipNotificationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantUserMembershipNotification"] = ResolversParentTypes["TenantUserMembershipNotification"],
> = {
  applicationUserByInviterId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  inviterId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  notificationById?: Resolver<
    Maybe<ResolversTypes["Notification"]>,
    ParentType,
    ContextType
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantUserMembershipNotificationsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantUserMembershipNotificationsConnection"] = ResolversParentTypes["TenantUserMembershipNotificationsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["TenantUserMembershipNotificationsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["TenantUserMembershipNotification"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantUserMembershipNotificationsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantUserMembershipNotificationsEdge"] = ResolversParentTypes["TenantUserMembershipNotificationsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["TenantUserMembershipNotification"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantUserexecutorResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantUserexecutor"] = ResolversParentTypes["TenantUserexecutor"],
> = {
  awsCognitoUserByUserexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsCognitoUser"]>,
    ParentType,
    ContextType
  >;
  awsCognitoUsersByUserexecutorPtrId?: Resolver<
    ResolversTypes["AwsCognitoUsersConnection"],
    ParentType,
    ContextType,
    RequireFields<
      TenantUserexecutorAwsCognitoUsersByUserexecutorPtrIdArgs,
      "orderBy"
    >
  >;
  djangoContentTypeByPolymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  polymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  tenantMockuserexecutorByUserexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TenantMockuserexecutor"]>,
    ParentType,
    ContextType
  >;
  tenantMockuserexecutorsByUserexecutorPtrId?: Resolver<
    ResolversTypes["TenantMockuserexecutorsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      TenantUserexecutorTenantMockuserexecutorsByUserexecutorPtrIdArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantUserexecutorsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantUserexecutorsConnection"] = ResolversParentTypes["TenantUserexecutorsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["TenantUserexecutorsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["TenantUserexecutor"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantUserexecutorsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantUserexecutorsEdge"] = ResolversParentTypes["TenantUserexecutorsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["TenantUserexecutor"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TenantsEdge"] = ResolversParentTypes["TenantsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["Tenant"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TesContainerimageexecutorResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TesContainerimageexecutor"] = ResolversParentTypes["TesContainerimageexecutor"],
> = {
  awsEcrImageByContainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsEcrImage"]>,
    ParentType,
    ContextType
  >;
  awsEcrImagesByContainerimageexecutorPtrId?: Resolver<
    ResolversTypes["AwsEcrImagesConnection"],
    ParentType,
    ContextType,
    RequireFields<
      TesContainerimageexecutorAwsEcrImagesByContainerimageexecutorPtrIdArgs,
      "orderBy"
    >
  >;
  containerImageByExecutorId?: Resolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType
  >;
  containerImagesByExecutorId?: Resolver<
    ResolversTypes["ContainerImagesConnection"],
    ParentType,
    ContextType,
    RequireFields<
      TesContainerimageexecutorContainerImagesByExecutorIdArgs,
      "orderBy"
    >
  >;
  djangoContentTypeByPolymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  polymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  tesMockcontainerimageexecutorByContainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesMockcontainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  tesMockcontainerimageexecutorsByContainerimageexecutorPtrId?: Resolver<
    ResolversTypes["TesMockcontainerimageexecutorsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      TesContainerimageexecutorTesMockcontainerimageexecutorsByContainerimageexecutorPtrIdArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TesContainerimageexecutorsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TesContainerimageexecutorsConnection"] = ResolversParentTypes["TesContainerimageexecutorsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["TesContainerimageexecutorsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["TesContainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TesContainerimageexecutorsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TesContainerimageexecutorsEdge"] = ResolversParentTypes["TesContainerimageexecutorsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["TesContainerimageexecutor"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TesMockcontainerimageexecutorResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TesMockcontainerimageexecutor"] = ResolversParentTypes["TesMockcontainerimageexecutor"],
> = {
  containerimageexecutorPtrId?: Resolver<
    ResolversTypes["Int"],
    ParentType,
    ContextType
  >;
  tesContainerimageexecutorByContainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TesMockcontainerimageexecutorsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TesMockcontainerimageexecutorsConnection"] = ResolversParentTypes["TesMockcontainerimageexecutorsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["TesMockcontainerimageexecutorsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["TesMockcontainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TesMockcontainerimageexecutorsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TesMockcontainerimageexecutorsEdge"] = ResolversParentTypes["TesMockcontainerimageexecutorsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["TesMockcontainerimageexecutor"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TesMocktaskexecutorResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TesMocktaskexecutor"] = ResolversParentTypes["TesMocktaskexecutor"],
> = {
  taskexecutorPtrId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  tesTaskexecutorByTaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TesMocktaskexecutorsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TesMocktaskexecutorsConnection"] = ResolversParentTypes["TesMocktaskexecutorsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["TesMocktaskexecutorsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["TesMocktaskexecutor"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TesMocktaskexecutorsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TesMocktaskexecutorsEdge"] = ResolversParentTypes["TesMocktaskexecutorsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["TesMocktaskexecutor"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TesTaskexecutorResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TesTaskexecutor"] = ResolversParentTypes["TesTaskexecutor"],
> = {
  awsBatchTaskByTaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsBatchTask"]>,
    ParentType,
    ContextType
  >;
  awsBatchTasksByTaskexecutorPtrId?: Resolver<
    ResolversTypes["AwsBatchTasksConnection"],
    ParentType,
    ContextType,
    RequireFields<
      TesTaskexecutorAwsBatchTasksByTaskexecutorPtrIdArgs,
      "orderBy"
    >
  >;
  djangoContentTypeByPolymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  polymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  taskByExecutorId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  tasksByExecutorId?: Resolver<
    ResolversTypes["TasksConnection"],
    ParentType,
    ContextType,
    RequireFields<TesTaskexecutorTasksByExecutorIdArgs, "orderBy">
  >;
  tesMocktaskexecutorByTaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesMocktaskexecutor"]>,
    ParentType,
    ContextType
  >;
  tesMocktaskexecutorsByTaskexecutorPtrId?: Resolver<
    ResolversTypes["TesMocktaskexecutorsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      TesTaskexecutorTesMocktaskexecutorsByTaskexecutorPtrIdArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TesTaskexecutorsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TesTaskexecutorsConnection"] = ResolversParentTypes["TesTaskexecutorsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["TesTaskexecutorsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["TesTaskexecutor"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TesTaskexecutorsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["TesTaskexecutorsEdge"] = ResolversParentTypes["TesTaskexecutorsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["TesTaskexecutor"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["Tool"] = ResolversParentTypes["Tool"],
> = {
  analysisTableGroupsByToolId?: Resolver<
    ResolversTypes["AnalysisTableGroupsConnection"],
    ParentType,
    ContextType,
    RequireFields<ToolAnalysisTableGroupsByToolIdArgs, "orderBy">
  >;
  created?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  helpText?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  key?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  searchTerms?: Resolver<
    Maybe<ResolversTypes["JSON"]>,
    ParentType,
    ContextType
  >;
  toolDisplayCategoriesByToolId?: Resolver<
    ResolversTypes["ToolDisplayCategoriesConnection"],
    ParentType,
    ContextType,
    RequireFields<ToolToolDisplayCategoriesByToolIdArgs, "orderBy">
  >;
  toolTenantsByToolId?: Resolver<
    ResolversTypes["ToolTenantsConnection"],
    ParentType,
    ContextType,
    RequireFields<ToolToolTenantsByToolIdArgs, "orderBy">
  >;
  toolVersionsByToolId?: Resolver<
    ResolversTypes["ToolVersionsConnection"],
    ParentType,
    ContextType,
    RequireFields<ToolToolVersionsByToolIdArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolCategoriesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolCategoriesConnection"] = ResolversParentTypes["ToolCategoriesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ToolCategoriesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ToolCategory"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolCategoriesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolCategoriesEdge"] = ResolversParentTypes["ToolCategoriesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["ToolCategory"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolCategoryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolCategory"] = ResolversParentTypes["ToolCategory"],
> = {
  description?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  key?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  order?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  toolDisplayCategoriesByToolcategoryId?: Resolver<
    ResolversTypes["ToolDisplayCategoriesConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ToolCategoryToolDisplayCategoriesByToolcategoryIdArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolDisplayCategoriesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolDisplayCategoriesConnection"] = ResolversParentTypes["ToolDisplayCategoriesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ToolDisplayCategoriesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ToolDisplayCategory"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolDisplayCategoriesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolDisplayCategoriesEdge"] = ResolversParentTypes["ToolDisplayCategoriesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["ToolDisplayCategory"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolDisplayCategoryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolDisplayCategory"] = ResolversParentTypes["ToolDisplayCategory"],
> = {
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  toolByToolId?: Resolver<
    Maybe<ResolversTypes["Tool"]>,
    ParentType,
    ContextType
  >;
  toolCategoryByToolcategoryId?: Resolver<
    Maybe<ResolversTypes["ToolCategory"]>,
    ParentType,
    ContextType
  >;
  toolId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  toolcategoryId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolParameterResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolParameter"] = ResolversParentTypes["ToolParameter"],
> = {
  default?: Resolver<Maybe<ResolversTypes["JSON"]>, ParentType, ContextType>;
  helpText?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  key?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  options?: Resolver<Maybe<ResolversTypes["JSON"]>, ParentType, ContextType>;
  required?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  toolVersionId?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  type?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  unit?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolParametersConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolParametersConnection"] = ResolversParentTypes["ToolParametersConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ToolParametersEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ToolParameter"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolParametersEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolParametersEdge"] = ResolversParentTypes["ToolParametersEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["ToolParameter"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolResourceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolResource"] = ResolversParentTypes["ToolResource"],
> = {
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  toolVersionId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  type?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  value?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolResourcesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolResourcesConnection"] = ResolversParentTypes["ToolResourcesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ToolResourcesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ToolResource"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolResourcesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolResourcesEdge"] = ResolversParentTypes["ToolResourcesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["ToolResource"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolResultResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolResult"] = ResolversParentTypes["ToolResult"],
> = {
  fileFormats?: Resolver<
    Maybe<ResolversTypes["JSON"]>,
    ParentType,
    ContextType
  >;
  helpText?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  key?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  required?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  toolVersionId?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolResultsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolResultsConnection"] = ResolversParentTypes["ToolResultsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ToolResultsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ToolResult"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolResultsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolResultsEdge"] = ResolversParentTypes["ToolResultsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["ToolResult"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolSecretResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolSecret"] = ResolversParentTypes["ToolSecret"],
> = {
  helpText?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  key?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  required?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  toolVersionId?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolSecretsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolSecretsConnection"] = ResolversParentTypes["ToolSecretsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ToolSecretsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ToolSecret"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolSecretsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolSecretsEdge"] = ResolversParentTypes["ToolSecretsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["ToolSecret"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolSourceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolSource"] = ResolversParentTypes["ToolSource"],
> = {
  fileFormats?: Resolver<
    Maybe<ResolversTypes["JSON"]>,
    ParentType,
    ContextType
  >;
  helpText?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  key?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  required?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  taskSourcesByToolSourceId?: Resolver<
    ResolversTypes["TaskSourcesConnection"],
    ParentType,
    ContextType,
    RequireFields<ToolSourceTaskSourcesByToolSourceIdArgs, "orderBy">
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  toolVersionId?: Resolver<
    Maybe<ResolversTypes["Int"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolSourcesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolSourcesConnection"] = ResolversParentTypes["ToolSourcesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ToolSourcesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ToolSource"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolSourcesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolSourcesEdge"] = ResolversParentTypes["ToolSourcesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["ToolSource"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolTenantResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolTenant"] = ResolversParentTypes["ToolTenant"],
> = {
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  toolByToolId?: Resolver<
    Maybe<ResolversTypes["Tool"]>,
    ParentType,
    ContextType
  >;
  toolId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolTenantsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolTenantsConnection"] = ResolversParentTypes["ToolTenantsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ToolTenantsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ToolTenant"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolTenantsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolTenantsEdge"] = ResolversParentTypes["ToolTenantsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["ToolTenant"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolVersionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolVersion"] = ResolversParentTypes["ToolVersion"],
> = {
  analysisTableRowsByToolVersionId?: Resolver<
    ResolversTypes["AnalysisTableRowsConnection"],
    ParentType,
    ContextType,
    RequireFields<ToolVersionAnalysisTableRowsByToolVersionIdArgs, "orderBy">
  >;
  analysisTablesByToolVersionId?: Resolver<
    ResolversTypes["AnalysisTablesConnection"],
    ParentType,
    ContextType,
    RequireFields<ToolVersionAnalysisTablesByToolVersionIdArgs, "orderBy">
  >;
  command?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  compatibleVersions?: Resolver<
    ResolversTypes["ToolVersionsConnection"],
    ParentType,
    ContextType,
    Partial<ToolVersionCompatibleVersionsArgs>
  >;
  created?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  credits?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  isToolSpecForwardsCompatible?: Resolver<
    ResolversTypes["Boolean"],
    ParentType,
    ContextType
  >;
  isUpgradableToNextVersion?: Resolver<
    ResolversTypes["Boolean"],
    ParentType,
    ContextType
  >;
  latestToolboxVersion?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType
  >;
  maturity?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  tasksByToolVersionId?: Resolver<
    ResolversTypes["TasksConnection"],
    ParentType,
    ContextType,
    RequireFields<ToolVersionTasksByToolVersionIdArgs, "orderBy">
  >;
  toolByToolId?: Resolver<
    Maybe<ResolversTypes["Tool"]>,
    ParentType,
    ContextType
  >;
  toolId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  toolParametersByToolVersionId?: Resolver<
    ResolversTypes["ToolParametersConnection"],
    ParentType,
    ContextType,
    RequireFields<ToolVersionToolParametersByToolVersionIdArgs, "orderBy">
  >;
  toolResourcesByToolVersionId?: Resolver<
    ResolversTypes["ToolResourcesConnection"],
    ParentType,
    ContextType,
    RequireFields<ToolVersionToolResourcesByToolVersionIdArgs, "orderBy">
  >;
  toolResultsByToolVersionId?: Resolver<
    ResolversTypes["ToolResultsConnection"],
    ParentType,
    ContextType,
    RequireFields<ToolVersionToolResultsByToolVersionIdArgs, "orderBy">
  >;
  toolSecretsByToolVersionId?: Resolver<
    ResolversTypes["ToolSecretsConnection"],
    ParentType,
    ContextType,
    RequireFields<ToolVersionToolSecretsByToolVersionIdArgs, "orderBy">
  >;
  toolSourcesByToolVersionId?: Resolver<
    ResolversTypes["ToolSourcesConnection"],
    ParentType,
    ContextType,
    RequireFields<ToolVersionToolSourcesByToolVersionIdArgs, "orderBy">
  >;
  toolSpec?: Resolver<Maybe<ResolversTypes["JSON"]>, ParentType, ContextType>;
  toolboxVersionToolVersionsByToolversionId?: Resolver<
    ResolversTypes["ToolboxVersionToolVersionsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ToolVersionToolboxVersionToolVersionsByToolversionIdArgs,
      "orderBy"
    >
  >;
  version?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  visibility?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  workflowSectionBlocksByToolId?: Resolver<
    ResolversTypes["WorkflowSectionBlocksConnection"],
    ParentType,
    ContextType,
    RequireFields<ToolVersionWorkflowSectionBlocksByToolIdArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolVersionsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolVersionsConnection"] = ResolversParentTypes["ToolVersionsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ToolVersionsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolVersionsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolVersionsEdge"] = ResolversParentTypes["ToolVersionsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["ToolVersion"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolboxResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["Toolbox"] = ResolversParentTypes["Toolbox"],
> = {
  created?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  key?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  toolboxTenantsByToolboxId?: Resolver<
    ResolversTypes["ToolboxTenantsConnection"],
    ParentType,
    ContextType,
    RequireFields<ToolboxToolboxTenantsByToolboxIdArgs, "orderBy">
  >;
  toolboxVersionsByToolboxId?: Resolver<
    ResolversTypes["ToolboxVersionsConnection"],
    ParentType,
    ContextType,
    RequireFields<ToolboxToolboxVersionsByToolboxIdArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolboxExitcodeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolboxExitcode"] = ResolversParentTypes["ToolboxExitcode"],
> = {
  code?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  created?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  description?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  toolboxVersionExitcodesByToolboxexitcodeId?: Resolver<
    ResolversTypes["ToolboxVersionExitcodesConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ToolboxExitcodeToolboxVersionExitcodesByToolboxexitcodeIdArgs,
      "orderBy"
    >
  >;
  type?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolboxExitcodesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolboxExitcodesConnection"] = ResolversParentTypes["ToolboxExitcodesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ToolboxExitcodesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ToolboxExitcode"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolboxExitcodesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolboxExitcodesEdge"] = ResolversParentTypes["ToolboxExitcodesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["ToolboxExitcode"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolboxTenantResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolboxTenant"] = ResolversParentTypes["ToolboxTenant"],
> = {
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  toolboxByToolboxId?: Resolver<
    Maybe<ResolversTypes["Toolbox"]>,
    ParentType,
    ContextType
  >;
  toolboxId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolboxTenantsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolboxTenantsConnection"] = ResolversParentTypes["ToolboxTenantsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ToolboxTenantsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ToolboxTenant"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolboxTenantsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolboxTenantsEdge"] = ResolversParentTypes["ToolboxTenantsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["ToolboxTenant"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolboxVersionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolboxVersion"] = ResolversParentTypes["ToolboxVersion"],
> = {
  containerImageByToolboxVersionId?: Resolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType
  >;
  containerImagesByToolboxVersionId?: Resolver<
    ResolversTypes["ContainerImagesConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ToolboxVersionContainerImagesByToolboxVersionIdArgs,
      "orderBy"
    >
  >;
  created?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  maturity?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  toolboxByToolboxId?: Resolver<
    Maybe<ResolversTypes["Toolbox"]>,
    ParentType,
    ContextType
  >;
  toolboxId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  toolboxSpec?: Resolver<
    Maybe<ResolversTypes["JSON"]>,
    ParentType,
    ContextType
  >;
  toolboxSpecV1?: Resolver<
    Maybe<ResolversTypes["JSON"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionExitcodesByToolboxversionId?: Resolver<
    ResolversTypes["ToolboxVersionExitcodesConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ToolboxVersionToolboxVersionExitcodesByToolboxversionIdArgs,
      "orderBy"
    >
  >;
  toolboxVersionToolVersionsByToolboxversionId?: Resolver<
    ResolversTypes["ToolboxVersionToolVersionsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      ToolboxVersionToolboxVersionToolVersionsByToolboxversionIdArgs,
      "orderBy"
    >
  >;
  version?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolboxVersionExitcodeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolboxVersionExitcode"] = ResolversParentTypes["ToolboxVersionExitcode"],
> = {
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  toolboxExitcodeByToolboxexitcodeId?: Resolver<
    Maybe<ResolversTypes["ToolboxExitcode"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionByToolboxversionId?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxexitcodeId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  toolboxversionId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolboxVersionExitcodesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolboxVersionExitcodesConnection"] = ResolversParentTypes["ToolboxVersionExitcodesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ToolboxVersionExitcodesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ToolboxVersionExitcode"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolboxVersionExitcodesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolboxVersionExitcodesEdge"] = ResolversParentTypes["ToolboxVersionExitcodesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["ToolboxVersionExitcode"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolboxVersionToolVersionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolboxVersionToolVersion"] = ResolversParentTypes["ToolboxVersionToolVersion"],
> = {
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  toolVersionByToolversionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionByToolboxversionId?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxversionId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  toolversionId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolboxVersionToolVersionsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolboxVersionToolVersionsConnection"] = ResolversParentTypes["ToolboxVersionToolVersionsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ToolboxVersionToolVersionsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ToolboxVersionToolVersion"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolboxVersionToolVersionsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolboxVersionToolVersionsEdge"] = ResolversParentTypes["ToolboxVersionToolVersionsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["ToolboxVersionToolVersion"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolboxVersionsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolboxVersionsConnection"] = ResolversParentTypes["ToolboxVersionsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ToolboxVersionsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolboxVersionsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolboxVersionsEdge"] = ResolversParentTypes["ToolboxVersionsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["ToolboxVersion"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolboxesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolboxesConnection"] = ResolversParentTypes["ToolboxesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["ToolboxesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes["Toolbox"]>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolboxesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolboxesEdge"] = ResolversParentTypes["ToolboxesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["Toolbox"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolsConnection"] = ResolversParentTypes["ToolsConnection"],
> = {
  edges?: Resolver<Array<ResolversTypes["ToolsEdge"]>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes["Tool"]>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["ToolsEdge"] = ResolversParentTypes["ToolsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["Tool"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UuidScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes["UUID"], any> {
  name: "UUID";
}

export type UpdateAnalysisTableGroupPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateAnalysisTableGroupPayload"] = ResolversParentTypes["UpdateAnalysisTableGroupPayload"],
> = {
  analysisTableGroup?: Resolver<
    Maybe<ResolversTypes["AnalysisTableGroup"]>,
    ParentType,
    ContextType
  >;
  analysisTableGroupEdge?: Resolver<
    Maybe<ResolversTypes["AnalysisTableGroupsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateAnalysisTableGroupPayloadAnalysisTableGroupEdgeArgs,
      "orderBy"
    >
  >;
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  toolByToolId?: Resolver<
    Maybe<ResolversTypes["Tool"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateAnalysisTablePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateAnalysisTablePayload"] = ResolversParentTypes["UpdateAnalysisTablePayload"],
> = {
  analysisTable?: Resolver<
    Maybe<ResolversTypes["AnalysisTable"]>,
    ParentType,
    ContextType
  >;
  analysisTableEdge?: Resolver<
    Maybe<ResolversTypes["AnalysisTablesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateAnalysisTablePayloadAnalysisTableEdgeArgs, "orderBy">
  >;
  analysisTableGroupByGroupId?: Resolver<
    Maybe<ResolversTypes["AnalysisTableGroup"]>,
    ParentType,
    ContextType
  >;
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateAnalysisTableRowAttachResultPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateAnalysisTableRowAttachResultPayload"] = ResolversParentTypes["UpdateAnalysisTableRowAttachResultPayload"],
> = {
  analysisTableRowAttachResult?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRowAttachResult"]>,
    ParentType,
    ContextType
  >;
  analysisTableRowAttachResultEdge?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRowAttachResultsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateAnalysisTableRowAttachResultPayloadAnalysisTableRowAttachResultEdgeArgs,
      "orderBy"
    >
  >;
  analysisTableRowByAnalysisTableRowId?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRow"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateAnalysisTableRowPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateAnalysisTableRowPayload"] = ResolversParentTypes["UpdateAnalysisTableRowPayload"],
> = {
  analysisTableByTableId?: Resolver<
    Maybe<ResolversTypes["AnalysisTable"]>,
    ParentType,
    ContextType
  >;
  analysisTableRow?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRow"]>,
    ParentType,
    ContextType
  >;
  analysisTableRowEdge?: Resolver<
    Maybe<ResolversTypes["AnalysisTableRowsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateAnalysisTableRowPayloadAnalysisTableRowEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateApplicationUserGroupPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateApplicationUserGroupPayload"] = ResolversParentTypes["UpdateApplicationUserGroupPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserGroup?: Resolver<
    Maybe<ResolversTypes["ApplicationUserGroup"]>,
    ParentType,
    ContextType
  >;
  applicationUserGroupEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUserGroupsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateApplicationUserGroupPayloadApplicationUserGroupEdgeArgs,
      "orderBy"
    >
  >;
  authGroupByGroupId?: Resolver<
    Maybe<ResolversTypes["AuthGroup"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateApplicationUserMembershipPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateApplicationUserMembershipPayload"] = ResolversParentTypes["UpdateApplicationUserMembershipPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserMembership?: Resolver<
    Maybe<ResolversTypes["ApplicationUserMembership"]>,
    ParentType,
    ContextType
  >;
  applicationUserMembershipEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUserMembershipsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateApplicationUserMembershipPayloadApplicationUserMembershipEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateApplicationUserMetadatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateApplicationUserMetadatumPayload"] = ResolversParentTypes["UpdateApplicationUserMetadatumPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserMetadatum?: Resolver<
    Maybe<ResolversTypes["ApplicationUserMetadatum"]>,
    ParentType,
    ContextType
  >;
  applicationUserMetadatumEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUserMetadataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateApplicationUserMetadatumPayloadApplicationUserMetadatumEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateApplicationUserPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateApplicationUserPayload"] = ResolversParentTypes["UpdateApplicationUserPayload"],
> = {
  applicationUser?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUsersEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateApplicationUserPayloadApplicationUserEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateApplicationUserSessionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateApplicationUserSessionPayload"] = ResolversParentTypes["UpdateApplicationUserSessionPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserSession?: Resolver<
    Maybe<ResolversTypes["ApplicationUserSession"]>,
    ParentType,
    ContextType
  >;
  applicationUserSessionEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUserSessionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateApplicationUserSessionPayloadApplicationUserSessionEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateApplicationUserUserPermissionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateApplicationUserUserPermissionPayload"] = ResolversParentTypes["UpdateApplicationUserUserPermissionPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  applicationUserUserPermission?: Resolver<
    Maybe<ResolversTypes["ApplicationUserUserPermission"]>,
    ParentType,
    ContextType
  >;
  applicationUserUserPermissionEdge?: Resolver<
    Maybe<ResolversTypes["ApplicationUserUserPermissionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateApplicationUserUserPermissionPayloadApplicationUserUserPermissionEdgeArgs,
      "orderBy"
    >
  >;
  authPermissionByPermissionId?: Resolver<
    Maybe<ResolversTypes["AuthPermission"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateAuthGroupPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateAuthGroupPayload"] = ResolversParentTypes["UpdateAuthGroupPayload"],
> = {
  authGroup?: Resolver<
    Maybe<ResolversTypes["AuthGroup"]>,
    ParentType,
    ContextType
  >;
  authGroupEdge?: Resolver<
    Maybe<ResolversTypes["AuthGroupsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateAuthGroupPayloadAuthGroupEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateAuthGroupPermissionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateAuthGroupPermissionPayload"] = ResolversParentTypes["UpdateAuthGroupPermissionPayload"],
> = {
  authGroupByGroupId?: Resolver<
    Maybe<ResolversTypes["AuthGroup"]>,
    ParentType,
    ContextType
  >;
  authGroupPermission?: Resolver<
    Maybe<ResolversTypes["AuthGroupPermission"]>,
    ParentType,
    ContextType
  >;
  authGroupPermissionEdge?: Resolver<
    Maybe<ResolversTypes["AuthGroupPermissionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateAuthGroupPermissionPayloadAuthGroupPermissionEdgeArgs,
      "orderBy"
    >
  >;
  authPermissionByPermissionId?: Resolver<
    Maybe<ResolversTypes["AuthPermission"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateAuthPermissionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateAuthPermissionPayload"] = ResolversParentTypes["UpdateAuthPermissionPayload"],
> = {
  authPermission?: Resolver<
    Maybe<ResolversTypes["AuthPermission"]>,
    ParentType,
    ContextType
  >;
  authPermissionEdge?: Resolver<
    Maybe<ResolversTypes["AuthPermissionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateAuthPermissionPayloadAuthPermissionEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeByContentTypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateAvatarPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateAvatarPayload"] = ResolversParentTypes["UpdateAvatarPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  avatar?: Resolver<Maybe<ResolversTypes["Avatar"]>, ParentType, ContextType>;
  avatarEdge?: Resolver<
    Maybe<ResolversTypes["AvatarsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateAvatarPayloadAvatarEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateAwsBatchTaskPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateAwsBatchTaskPayload"] = ResolversParentTypes["UpdateAwsBatchTaskPayload"],
> = {
  awsBatchTask?: Resolver<
    Maybe<ResolversTypes["AwsBatchTask"]>,
    ParentType,
    ContextType
  >;
  awsBatchTaskEdge?: Resolver<
    Maybe<ResolversTypes["AwsBatchTasksEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateAwsBatchTaskPayloadAwsBatchTaskEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesTaskexecutorByTaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateAwsCognitoUserPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateAwsCognitoUserPayload"] = ResolversParentTypes["UpdateAwsCognitoUserPayload"],
> = {
  awsCognitoUser?: Resolver<
    Maybe<ResolversTypes["AwsCognitoUser"]>,
    ParentType,
    ContextType
  >;
  awsCognitoUserEdge?: Resolver<
    Maybe<ResolversTypes["AwsCognitoUsersEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateAwsCognitoUserPayloadAwsCognitoUserEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantUserexecutorByUserexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TenantUserexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateAwsEcrImagePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateAwsEcrImagePayload"] = ResolversParentTypes["UpdateAwsEcrImagePayload"],
> = {
  awsEcrImage?: Resolver<
    Maybe<ResolversTypes["AwsEcrImage"]>,
    ParentType,
    ContextType
  >;
  awsEcrImageEdge?: Resolver<
    Maybe<ResolversTypes["AwsEcrImagesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateAwsEcrImagePayloadAwsEcrImageEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesContainerimageexecutorByContainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateAwsMockBatchTaskPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateAwsMockBatchTaskPayload"] = ResolversParentTypes["UpdateAwsMockBatchTaskPayload"],
> = {
  awsBatchTaskByAwstaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsBatchTask"]>,
    ParentType,
    ContextType
  >;
  awsMockBatchTask?: Resolver<
    Maybe<ResolversTypes["AwsMockBatchTask"]>,
    ParentType,
    ContextType
  >;
  awsMockBatchTaskEdge?: Resolver<
    Maybe<ResolversTypes["AwsMockBatchTasksEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateAwsMockBatchTaskPayloadAwsMockBatchTaskEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateAwsMockEcrImagePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateAwsMockEcrImagePayload"] = ResolversParentTypes["UpdateAwsMockEcrImagePayload"],
> = {
  awsEcrImageByAwscontainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsEcrImage"]>,
    ParentType,
    ContextType
  >;
  awsMockEcrImage?: Resolver<
    Maybe<ResolversTypes["AwsMockEcrImage"]>,
    ParentType,
    ContextType
  >;
  awsMockEcrImageEdge?: Resolver<
    Maybe<ResolversTypes["AwsMockEcrImagesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateAwsMockEcrImagePayloadAwsMockEcrImageEdgeArgs,
      "orderBy"
    >
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateAwsMockS3FilePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateAwsMockS3FilePayload"] = ResolversParentTypes["UpdateAwsMockS3FilePayload"],
> = {
  awsMockS3File?: Resolver<
    Maybe<ResolversTypes["AwsMockS3File"]>,
    ParentType,
    ContextType
  >;
  awsMockS3FileEdge?: Resolver<
    Maybe<ResolversTypes["AwsMockS3FilesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateAwsMockS3FilePayloadAwsMockS3FileEdgeArgs, "orderBy">
  >;
  awsS3FileByAwsfileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["AwsS3File"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateAwsS3FilePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateAwsS3FilePayload"] = ResolversParentTypes["UpdateAwsS3FilePayload"],
> = {
  awsS3File?: Resolver<
    Maybe<ResolversTypes["AwsS3File"]>,
    ParentType,
    ContextType
  >;
  awsS3FileEdge?: Resolver<
    Maybe<ResolversTypes["AwsS3FilesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateAwsS3FilePayloadAwsS3FileEdgeArgs, "orderBy">
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  drsFileexecutorByFileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DrsFileexecutor"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateContainerImagePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateContainerImagePayload"] = ResolversParentTypes["UpdateContainerImagePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  containerImage?: Resolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType
  >;
  containerImageEdge?: Resolver<
    Maybe<ResolversTypes["ContainerImagesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateContainerImagePayloadContainerImageEdgeArgs, "orderBy">
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesContainerimageexecutorByExecutorId?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionByToolboxVersionId?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateCostPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateCostPayload"] = ResolversParentTypes["UpdateCostPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  cost?: Resolver<Maybe<ResolversTypes["Cost"]>, ParentType, ContextType>;
  costEdge?: Resolver<
    Maybe<ResolversTypes["CostsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateCostPayloadCostEdgeArgs, "orderBy">
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDatasetMetadatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateDatasetMetadatumPayload"] = ResolversParentTypes["UpdateDatasetMetadatumPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetByDatasetId?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType
  >;
  datasetMetadatum?: Resolver<
    Maybe<ResolversTypes["DatasetMetadatum"]>,
    ParentType,
    ContextType
  >;
  datasetMetadatumEdge?: Resolver<
    Maybe<ResolversTypes["DatasetMetadataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateDatasetMetadatumPayloadDatasetMetadatumEdgeArgs,
      "orderBy"
    >
  >;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDatasetPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateDatasetPayload"] = ResolversParentTypes["UpdateDatasetPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  dataset?: Resolver<Maybe<ResolversTypes["Dataset"]>, ParentType, ContextType>;
  datasetEdge?: Resolver<
    Maybe<ResolversTypes["DatasetsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateDatasetPayloadDatasetEdgeArgs, "orderBy">
  >;
  datasetRecordingsTableByRecordingsTableId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTable"]>,
    ParentType,
    ContextType
  >;
  metadatumByDescriptionId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDatasetRecordingsTableColumnOrderPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateDatasetRecordingsTableColumnOrderPayload"] = ResolversParentTypes["UpdateDatasetRecordingsTableColumnOrderPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnByDatasetRecordingsTableColumnId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumn"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnOrder?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumnOrder"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnOrderEdge?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumnOrdersEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateDatasetRecordingsTableColumnOrderPayloadDatasetRecordingsTableColumnOrderEdgeArgs,
      "orderBy"
    >
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDatasetRecordingsTableColumnPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateDatasetRecordingsTableColumnPayload"] = ResolversParentTypes["UpdateDatasetRecordingsTableColumnPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableByDatasetRecordingsTableId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTable"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumn?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumn"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnEdge?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumnsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateDatasetRecordingsTableColumnPayloadDatasetRecordingsTableColumnEdgeArgs,
      "orderBy"
    >
  >;
  metadatumByColDefId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDatasetRecordingsTablePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateDatasetRecordingsTablePayload"] = ResolversParentTypes["UpdateDatasetRecordingsTablePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTable?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTable"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableEdge?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTablesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateDatasetRecordingsTablePayloadDatasetRecordingsTableEdgeArgs,
      "orderBy"
    >
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDatasetVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateDatasetVersionPayload"] = ResolversParentTypes["UpdateDatasetVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetByDatasetId?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType
  >;
  datasetVersion?: Resolver<
    Maybe<ResolversTypes["DatasetVersion"]>,
    ParentType,
    ContextType
  >;
  datasetVersionEdge?: Resolver<
    Maybe<ResolversTypes["DatasetVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateDatasetVersionPayloadDatasetVersionEdgeArgs, "orderBy">
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDjangoAdminLogPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateDjangoAdminLogPayload"] = ResolversParentTypes["UpdateDjangoAdminLogPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoAdminLog?: Resolver<
    Maybe<ResolversTypes["DjangoAdminLog"]>,
    ParentType,
    ContextType
  >;
  djangoAdminLogEdge?: Resolver<
    Maybe<ResolversTypes["DjangoAdminLogsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateDjangoAdminLogPayloadDjangoAdminLogEdgeArgs, "orderBy">
  >;
  djangoContentTypeByContentTypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDjangoContentTypePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateDjangoContentTypePayload"] = ResolversParentTypes["UpdateDjangoContentTypePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoContentType?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeEdge?: Resolver<
    Maybe<ResolversTypes["DjangoContentTypesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateDjangoContentTypePayloadDjangoContentTypeEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDjangoMigrationPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateDjangoMigrationPayload"] = ResolversParentTypes["UpdateDjangoMigrationPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoMigration?: Resolver<
    Maybe<ResolversTypes["DjangoMigration"]>,
    ParentType,
    ContextType
  >;
  djangoMigrationEdge?: Resolver<
    Maybe<ResolversTypes["DjangoMigrationsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateDjangoMigrationPayloadDjangoMigrationEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDjangoSesSesstatPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateDjangoSesSesstatPayload"] = ResolversParentTypes["UpdateDjangoSesSesstatPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoSesSesstat?: Resolver<
    Maybe<ResolversTypes["DjangoSesSesstat"]>,
    ParentType,
    ContextType
  >;
  djangoSesSesstatEdge?: Resolver<
    Maybe<ResolversTypes["DjangoSesSesstatsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateDjangoSesSesstatPayloadDjangoSesSesstatEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDjangoSessionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateDjangoSessionPayload"] = ResolversParentTypes["UpdateDjangoSessionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoSession?: Resolver<
    Maybe<ResolversTypes["DjangoSession"]>,
    ParentType,
    ContextType
  >;
  djangoSessionEdge?: Resolver<
    Maybe<ResolversTypes["DjangoSessionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateDjangoSessionPayloadDjangoSessionEdgeArgs, "orderBy">
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDrsFileexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateDrsFileexecutorPayload"] = ResolversParentTypes["UpdateDrsFileexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeByPolymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  drsFileexecutor?: Resolver<
    Maybe<ResolversTypes["DrsFileexecutor"]>,
    ParentType,
    ContextType
  >;
  drsFileexecutorEdge?: Resolver<
    Maybe<ResolversTypes["DrsFileexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateDrsFileexecutorPayloadDrsFileexecutorEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDrsMockfileexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateDrsMockfileexecutorPayload"] = ResolversParentTypes["UpdateDrsMockfileexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  drsFileexecutorByFileexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["DrsFileexecutor"]>,
    ParentType,
    ContextType
  >;
  drsMockfileexecutor?: Resolver<
    Maybe<ResolversTypes["DrsMockfileexecutor"]>,
    ParentType,
    ContextType
  >;
  drsMockfileexecutorEdge?: Resolver<
    Maybe<ResolversTypes["DrsMockfileexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateDrsMockfileexecutorPayloadDrsMockfileexecutorEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateEulaVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateEulaVersionPayload"] = ResolversParentTypes["UpdateEulaVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  eulaVersion?: Resolver<
    Maybe<ResolversTypes["EulaVersion"]>,
    ParentType,
    ContextType
  >;
  eulaVersionEdge?: Resolver<
    Maybe<ResolversTypes["EulaVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateEulaVersionPayloadEulaVersionEdgeArgs, "orderBy">
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateEulaVersionUserAcceptancePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateEulaVersionUserAcceptancePayload"] = ResolversParentTypes["UpdateEulaVersionUserAcceptancePayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  eulaVersionByEulaVersionId?: Resolver<
    Maybe<ResolversTypes["EulaVersion"]>,
    ParentType,
    ContextType
  >;
  eulaVersionUserAcceptance?: Resolver<
    Maybe<ResolversTypes["EulaVersionUserAcceptance"]>,
    ParentType,
    ContextType
  >;
  eulaVersionUserAcceptanceEdge?: Resolver<
    Maybe<ResolversTypes["EulaVersionUserAcceptancesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateEulaVersionUserAcceptancePayloadEulaVersionUserAcceptanceEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateExplorerQueryPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateExplorerQueryPayload"] = ResolversParentTypes["UpdateExplorerQueryPayload"],
> = {
  applicationUserByCreatedByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  explorerQuery?: Resolver<
    Maybe<ResolversTypes["ExplorerQuery"]>,
    ParentType,
    ContextType
  >;
  explorerQueryEdge?: Resolver<
    Maybe<ResolversTypes["ExplorerQueriesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateExplorerQueryPayloadExplorerQueryEdgeArgs, "orderBy">
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateExplorerQueryfavoritePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateExplorerQueryfavoritePayload"] = ResolversParentTypes["UpdateExplorerQueryfavoritePayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  explorerQueryByQueryId?: Resolver<
    Maybe<ResolversTypes["ExplorerQuery"]>,
    ParentType,
    ContextType
  >;
  explorerQueryfavorite?: Resolver<
    Maybe<ResolversTypes["ExplorerQueryfavorite"]>,
    ParentType,
    ContextType
  >;
  explorerQueryfavoriteEdge?: Resolver<
    Maybe<ResolversTypes["ExplorerQueryfavoritesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateExplorerQueryfavoritePayloadExplorerQueryfavoriteEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateExplorerQuerylogPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateExplorerQuerylogPayload"] = ResolversParentTypes["UpdateExplorerQuerylogPayload"],
> = {
  applicationUserByRunByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  explorerQueryByQueryId?: Resolver<
    Maybe<ResolversTypes["ExplorerQuery"]>,
    ParentType,
    ContextType
  >;
  explorerQuerylog?: Resolver<
    Maybe<ResolversTypes["ExplorerQuerylog"]>,
    ParentType,
    ContextType
  >;
  explorerQuerylogEdge?: Resolver<
    Maybe<ResolversTypes["ExplorerQuerylogsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateExplorerQuerylogPayloadExplorerQuerylogEdgeArgs,
      "orderBy"
    >
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateFileActivityPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateFileActivityPayload"] = ResolversParentTypes["UpdateFileActivityPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  costByCostId?: Resolver<
    Maybe<ResolversTypes["Cost"]>,
    ParentType,
    ContextType
  >;
  fileActivity?: Resolver<
    Maybe<ResolversTypes["FileActivity"]>,
    ParentType,
    ContextType
  >;
  fileActivityEdge?: Resolver<
    Maybe<ResolversTypes["FileActivitiesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateFileActivityPayloadFileActivityEdgeArgs, "orderBy">
  >;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateFileMetadatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateFileMetadatumPayload"] = ResolversParentTypes["UpdateFileMetadatumPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileMetadatum?: Resolver<
    Maybe<ResolversTypes["FileMetadatum"]>,
    ParentType,
    ContextType
  >;
  fileMetadatumEdge?: Resolver<
    Maybe<ResolversTypes["FileMetadataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateFileMetadatumPayloadFileMetadatumEdgeArgs, "orderBy">
  >;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateFileOutputGroupMetadatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateFileOutputGroupMetadatumPayload"] = ResolversParentTypes["UpdateFileOutputGroupMetadatumPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroupByFileOutputGroupId?: Resolver<
    Maybe<ResolversTypes["FileOutputGroup"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroupMetadatum?: Resolver<
    Maybe<ResolversTypes["FileOutputGroupMetadatum"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroupMetadatumEdge?: Resolver<
    Maybe<ResolversTypes["FileOutputGroupMetadataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateFileOutputGroupMetadatumPayloadFileOutputGroupMetadatumEdgeArgs,
      "orderBy"
    >
  >;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateFileOutputGroupPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateFileOutputGroupPayload"] = ResolversParentTypes["UpdateFileOutputGroupPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroup?: Resolver<
    Maybe<ResolversTypes["FileOutputGroup"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroupEdge?: Resolver<
    Maybe<ResolversTypes["FileOutputGroupsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateFileOutputGroupPayloadFileOutputGroupEdgeArgs,
      "orderBy"
    >
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateFilePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateFilePayload"] = ResolversParentTypes["UpdateFilePayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetByDatasetId?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType
  >;
  file?: Resolver<Maybe<ResolversTypes["File"]>, ParentType, ContextType>;
  fileBySeriesParentId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileEdge?: Resolver<
    Maybe<ResolversTypes["FilesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateFilePayloadFileEdgeArgs, "orderBy">
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateFileRecordingGroupMetadatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateFileRecordingGroupMetadatumPayload"] = ResolversParentTypes["UpdateFileRecordingGroupMetadatumPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroupByFileRecordingGroupId?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroup"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroupMetadatum?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroupMetadatum"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroupMetadatumEdge?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroupMetadataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateFileRecordingGroupMetadatumPayloadFileRecordingGroupMetadatumEdgeArgs,
      "orderBy"
    >
  >;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateFileRecordingGroupPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateFileRecordingGroupPayload"] = ResolversParentTypes["UpdateFileRecordingGroupPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableByDatasetRecordingsTableId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTable"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroup?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroup"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroupEdge?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroupsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateFileRecordingGroupPayloadFileRecordingGroupEdgeArgs,
      "orderBy"
    >
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateMetadataValuePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateMetadataValuePayload"] = ResolversParentTypes["UpdateMetadataValuePayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  metadataValue?: Resolver<
    Maybe<ResolversTypes["MetadataValue"]>,
    ParentType,
    ContextType
  >;
  metadataValueEdge?: Resolver<
    Maybe<ResolversTypes["MetadataValuesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateMetadataValuePayloadMetadataValueEdgeArgs, "orderBy">
  >;
  metadatumByMetadataId?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateMetadatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateMetadatumPayload"] = ResolversParentTypes["UpdateMetadatumPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  metadatum?: Resolver<
    Maybe<ResolversTypes["Metadatum"]>,
    ParentType,
    ContextType
  >;
  metadatumEdge?: Resolver<
    Maybe<ResolversTypes["MetadataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateMetadatumPayloadMetadatumEdgeArgs, "orderBy">
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateNotificationPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateNotificationPayload"] = ResolversParentTypes["UpdateNotificationPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  notification?: Resolver<
    Maybe<ResolversTypes["Notification"]>,
    ParentType,
    ContextType
  >;
  notificationEdge?: Resolver<
    Maybe<ResolversTypes["NotificationsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateNotificationPayloadNotificationEdgeArgs, "orderBy">
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateOutputGroupFilePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateOutputGroupFilePayload"] = ResolversParentTypes["UpdateOutputGroupFilePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileOutputGroupByFileOutputGroupId?: Resolver<
    Maybe<ResolversTypes["FileOutputGroup"]>,
    ParentType,
    ContextType
  >;
  outputGroupFile?: Resolver<
    Maybe<ResolversTypes["OutputGroupFile"]>,
    ParentType,
    ContextType
  >;
  outputGroupFileEdge?: Resolver<
    Maybe<ResolversTypes["OutputGroupFilesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateOutputGroupFilePayloadOutputGroupFileEdgeArgs,
      "orderBy"
    >
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateRecordingGroupFilePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateRecordingGroupFilePayload"] = ResolversParentTypes["UpdateRecordingGroupFilePayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  datasetRecordingsTableColumnByDatasetRecordingsTableColumnId?: Resolver<
    Maybe<ResolversTypes["DatasetRecordingsTableColumn"]>,
    ParentType,
    ContextType
  >;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  fileRecordingGroupByFileRecordingGroupId?: Resolver<
    Maybe<ResolversTypes["FileRecordingGroup"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  recordingGroupFile?: Resolver<
    Maybe<ResolversTypes["RecordingGroupFile"]>,
    ParentType,
    ContextType
  >;
  recordingGroupFileEdge?: Resolver<
    Maybe<ResolversTypes["RecordingGroupFilesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateRecordingGroupFilePayloadRecordingGroupFileEdgeArgs,
      "orderBy"
    >
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateRunPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateRunPayload"] = ResolversParentTypes["UpdateRunPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  run?: Resolver<Maybe<ResolversTypes["Run"]>, ParentType, ContextType>;
  runEdge?: Resolver<
    Maybe<ResolversTypes["RunsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateRunPayloadRunEdgeArgs, "orderBy">
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTagPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateTagPayload"] = ResolversParentTypes["UpdateTagPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tag?: Resolver<Maybe<ResolversTypes["Tag"]>, ParentType, ContextType>;
  tagByParentId?: Resolver<
    Maybe<ResolversTypes["Tag"]>,
    ParentType,
    ContextType
  >;
  tagEdge?: Resolver<
    Maybe<ResolversTypes["TagsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateTagPayloadTagEdgeArgs, "orderBy">
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTaskActivityPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateTaskActivityPayload"] = ResolversParentTypes["UpdateTaskActivityPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  costByCostId?: Resolver<
    Maybe<ResolversTypes["Cost"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskActivity?: Resolver<
    Maybe<ResolversTypes["TaskActivity"]>,
    ParentType,
    ContextType
  >;
  taskActivityEdge?: Resolver<
    Maybe<ResolversTypes["TaskActivitiesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateTaskActivityPayloadTaskActivityEdgeArgs, "orderBy">
  >;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTaskPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateTaskPayload"] = ResolversParentTypes["UpdateTaskPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  containerImageByContainerImageId?: Resolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType
  >;
  datasetByDatasetId?: Resolver<
    Maybe<ResolversTypes["Dataset"]>,
    ParentType,
    ContextType
  >;
  projectByProjectId?: Resolver<
    Maybe<ResolversTypes["Project"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  task?: Resolver<Maybe<ResolversTypes["Task"]>, ParentType, ContextType>;
  taskEdge?: Resolver<
    Maybe<ResolversTypes["TasksEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateTaskPayloadTaskEdgeArgs, "orderBy">
  >;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tesTaskexecutorByExecutorId?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutor"]>,
    ParentType,
    ContextType
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTaskResourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateTaskResourcePayload"] = ResolversParentTypes["UpdateTaskResourcePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  taskResource?: Resolver<
    Maybe<ResolversTypes["TaskResource"]>,
    ParentType,
    ContextType
  >;
  taskResourceEdge?: Resolver<
    Maybe<ResolversTypes["TaskResourcesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateTaskResourcePayloadTaskResourceEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTaskSourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateTaskSourcePayload"] = ResolversParentTypes["UpdateTaskSourcePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  fileByFileId?: Resolver<
    Maybe<ResolversTypes["File"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  taskSource?: Resolver<
    Maybe<ResolversTypes["TaskSource"]>,
    ParentType,
    ContextType
  >;
  taskSourceEdge?: Resolver<
    Maybe<ResolversTypes["TaskSourcesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateTaskSourcePayloadTaskSourceEdgeArgs, "orderBy">
  >;
  toolSourceByToolSourceId?: Resolver<
    Maybe<ResolversTypes["ToolSource"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTaskStatusLogPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateTaskStatusLogPayload"] = ResolversParentTypes["UpdateTaskStatusLogPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  taskByTaskId?: Resolver<
    Maybe<ResolversTypes["Task"]>,
    ParentType,
    ContextType
  >;
  taskStatusLog?: Resolver<
    Maybe<ResolversTypes["TaskStatusLog"]>,
    ParentType,
    ContextType
  >;
  taskStatusLogEdge?: Resolver<
    Maybe<ResolversTypes["TaskStatusLogsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateTaskStatusLogPayloadTaskStatusLogEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTenantContractAllowancePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateTenantContractAllowancePayload"] = ResolversParentTypes["UpdateTenantContractAllowancePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantContractAllowance?: Resolver<
    Maybe<ResolversTypes["TenantContractAllowance"]>,
    ParentType,
    ContextType
  >;
  tenantContractAllowanceEdge?: Resolver<
    Maybe<ResolversTypes["TenantContractAllowancesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateTenantContractAllowancePayloadTenantContractAllowanceEdgeArgs,
      "orderBy"
    >
  >;
  tenantContractTransactionByTenantContractTransactionId?: Resolver<
    Maybe<ResolversTypes["TenantContractTransaction"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTenantContractPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateTenantContractPayload"] = ResolversParentTypes["UpdateTenantContractPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantContract?: Resolver<
    Maybe<ResolversTypes["TenantContract"]>,
    ParentType,
    ContextType
  >;
  tenantContractEdge?: Resolver<
    Maybe<ResolversTypes["TenantContractsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateTenantContractPayloadTenantContractEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTenantContractTransactionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateTenantContractTransactionPayload"] = ResolversParentTypes["UpdateTenantContractTransactionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantContractByTenantContractId?: Resolver<
    Maybe<ResolversTypes["TenantContract"]>,
    ParentType,
    ContextType
  >;
  tenantContractTransaction?: Resolver<
    Maybe<ResolversTypes["TenantContractTransaction"]>,
    ParentType,
    ContextType
  >;
  tenantContractTransactionEdge?: Resolver<
    Maybe<ResolversTypes["TenantContractTransactionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateTenantContractTransactionPayloadTenantContractTransactionEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTenantMockuserexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateTenantMockuserexecutorPayload"] = ResolversParentTypes["UpdateTenantMockuserexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantMockuserexecutor?: Resolver<
    Maybe<ResolversTypes["TenantMockuserexecutor"]>,
    ParentType,
    ContextType
  >;
  tenantMockuserexecutorEdge?: Resolver<
    Maybe<ResolversTypes["TenantMockuserexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateTenantMockuserexecutorPayloadTenantMockuserexecutorEdgeArgs,
      "orderBy"
    >
  >;
  tenantUserexecutorByUserexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TenantUserexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTenantMonthlyConsumedResourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateTenantMonthlyConsumedResourcePayload"] = ResolversParentTypes["UpdateTenantMonthlyConsumedResourcePayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantContractByTenantContractId?: Resolver<
    Maybe<ResolversTypes["TenantContract"]>,
    ParentType,
    ContextType
  >;
  tenantMonthlyConsumedResource?: Resolver<
    Maybe<ResolversTypes["TenantMonthlyConsumedResource"]>,
    ParentType,
    ContextType
  >;
  tenantMonthlyConsumedResourceEdge?: Resolver<
    Maybe<ResolversTypes["TenantMonthlyConsumedResourcesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateTenantMonthlyConsumedResourcePayloadTenantMonthlyConsumedResourceEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTenantPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateTenantPayload"] = ResolversParentTypes["UpdateTenantPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenant?: Resolver<Maybe<ResolversTypes["Tenant"]>, ParentType, ContextType>;
  tenantEdge?: Resolver<
    Maybe<ResolversTypes["TenantsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateTenantPayloadTenantEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTenantUserexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateTenantUserexecutorPayload"] = ResolversParentTypes["UpdateTenantUserexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeByPolymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantUserexecutor?: Resolver<
    Maybe<ResolversTypes["TenantUserexecutor"]>,
    ParentType,
    ContextType
  >;
  tenantUserexecutorEdge?: Resolver<
    Maybe<ResolversTypes["TenantUserexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateTenantUserexecutorPayloadTenantUserexecutorEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTesContainerimageexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateTesContainerimageexecutorPayload"] = ResolversParentTypes["UpdateTesContainerimageexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeByPolymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesContainerimageexecutor?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  tesContainerimageexecutorEdge?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateTesContainerimageexecutorPayloadTesContainerimageexecutorEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTesMockcontainerimageexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateTesMockcontainerimageexecutorPayload"] = ResolversParentTypes["UpdateTesMockcontainerimageexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesContainerimageexecutorByContainerimageexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesContainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  tesMockcontainerimageexecutor?: Resolver<
    Maybe<ResolversTypes["TesMockcontainerimageexecutor"]>,
    ParentType,
    ContextType
  >;
  tesMockcontainerimageexecutorEdge?: Resolver<
    Maybe<ResolversTypes["TesMockcontainerimageexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateTesMockcontainerimageexecutorPayloadTesMockcontainerimageexecutorEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTesMocktaskexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateTesMocktaskexecutorPayload"] = ResolversParentTypes["UpdateTesMocktaskexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesMocktaskexecutor?: Resolver<
    Maybe<ResolversTypes["TesMocktaskexecutor"]>,
    ParentType,
    ContextType
  >;
  tesMocktaskexecutorEdge?: Resolver<
    Maybe<ResolversTypes["TesMocktaskexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateTesMocktaskexecutorPayloadTesMocktaskexecutorEdgeArgs,
      "orderBy"
    >
  >;
  tesTaskexecutorByTaskexecutorPtrId?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutor"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTesTaskexecutorPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateTesTaskexecutorPayload"] = ResolversParentTypes["UpdateTesTaskexecutorPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  djangoContentTypeByPolymorphicCtypeId?: Resolver<
    Maybe<ResolversTypes["DjangoContentType"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tesTaskexecutor?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutor"]>,
    ParentType,
    ContextType
  >;
  tesTaskexecutorEdge?: Resolver<
    Maybe<ResolversTypes["TesTaskexecutorsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateTesTaskexecutorPayloadTesTaskexecutorEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateToolCategoryPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateToolCategoryPayload"] = ResolversParentTypes["UpdateToolCategoryPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolCategory?: Resolver<
    Maybe<ResolversTypes["ToolCategory"]>,
    ParentType,
    ContextType
  >;
  toolCategoryEdge?: Resolver<
    Maybe<ResolversTypes["ToolCategoriesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateToolCategoryPayloadToolCategoryEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateToolDisplayCategoryPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateToolDisplayCategoryPayload"] = ResolversParentTypes["UpdateToolDisplayCategoryPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolByToolId?: Resolver<
    Maybe<ResolversTypes["Tool"]>,
    ParentType,
    ContextType
  >;
  toolCategoryByToolcategoryId?: Resolver<
    Maybe<ResolversTypes["ToolCategory"]>,
    ParentType,
    ContextType
  >;
  toolDisplayCategory?: Resolver<
    Maybe<ResolversTypes["ToolDisplayCategory"]>,
    ParentType,
    ContextType
  >;
  toolDisplayCategoryEdge?: Resolver<
    Maybe<ResolversTypes["ToolDisplayCategoriesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateToolDisplayCategoryPayloadToolDisplayCategoryEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateToolParameterPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateToolParameterPayload"] = ResolversParentTypes["UpdateToolParameterPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolParameter?: Resolver<
    Maybe<ResolversTypes["ToolParameter"]>,
    ParentType,
    ContextType
  >;
  toolParameterEdge?: Resolver<
    Maybe<ResolversTypes["ToolParametersEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateToolParameterPayloadToolParameterEdgeArgs, "orderBy">
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateToolPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateToolPayload"] = ResolversParentTypes["UpdateToolPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tool?: Resolver<Maybe<ResolversTypes["Tool"]>, ParentType, ContextType>;
  toolEdge?: Resolver<
    Maybe<ResolversTypes["ToolsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateToolPayloadToolEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateToolResourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateToolResourcePayload"] = ResolversParentTypes["UpdateToolResourcePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolResource?: Resolver<
    Maybe<ResolversTypes["ToolResource"]>,
    ParentType,
    ContextType
  >;
  toolResourceEdge?: Resolver<
    Maybe<ResolversTypes["ToolResourcesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateToolResourcePayloadToolResourceEdgeArgs, "orderBy">
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateToolResultPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateToolResultPayload"] = ResolversParentTypes["UpdateToolResultPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolResult?: Resolver<
    Maybe<ResolversTypes["ToolResult"]>,
    ParentType,
    ContextType
  >;
  toolResultEdge?: Resolver<
    Maybe<ResolversTypes["ToolResultsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateToolResultPayloadToolResultEdgeArgs, "orderBy">
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateToolSecretPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateToolSecretPayload"] = ResolversParentTypes["UpdateToolSecretPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolSecret?: Resolver<
    Maybe<ResolversTypes["ToolSecret"]>,
    ParentType,
    ContextType
  >;
  toolSecretEdge?: Resolver<
    Maybe<ResolversTypes["ToolSecretsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateToolSecretPayloadToolSecretEdgeArgs, "orderBy">
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateToolSourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateToolSourcePayload"] = ResolversParentTypes["UpdateToolSourcePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolSource?: Resolver<
    Maybe<ResolversTypes["ToolSource"]>,
    ParentType,
    ContextType
  >;
  toolSourceEdge?: Resolver<
    Maybe<ResolversTypes["ToolSourcesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateToolSourcePayloadToolSourceEdgeArgs, "orderBy">
  >;
  toolVersionByToolVersionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateToolTenantPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateToolTenantPayload"] = ResolversParentTypes["UpdateToolTenantPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  toolByToolId?: Resolver<
    Maybe<ResolversTypes["Tool"]>,
    ParentType,
    ContextType
  >;
  toolTenant?: Resolver<
    Maybe<ResolversTypes["ToolTenant"]>,
    ParentType,
    ContextType
  >;
  toolTenantEdge?: Resolver<
    Maybe<ResolversTypes["ToolTenantsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateToolTenantPayloadToolTenantEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateToolVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateToolVersionPayload"] = ResolversParentTypes["UpdateToolVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolByToolId?: Resolver<
    Maybe<ResolversTypes["Tool"]>,
    ParentType,
    ContextType
  >;
  toolVersion?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  toolVersionEdge?: Resolver<
    Maybe<ResolversTypes["ToolVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateToolVersionPayloadToolVersionEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateToolboxExitcodePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateToolboxExitcodePayload"] = ResolversParentTypes["UpdateToolboxExitcodePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolboxExitcode?: Resolver<
    Maybe<ResolversTypes["ToolboxExitcode"]>,
    ParentType,
    ContextType
  >;
  toolboxExitcodeEdge?: Resolver<
    Maybe<ResolversTypes["ToolboxExitcodesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateToolboxExitcodePayloadToolboxExitcodeEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateToolboxPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateToolboxPayload"] = ResolversParentTypes["UpdateToolboxPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolbox?: Resolver<Maybe<ResolversTypes["Toolbox"]>, ParentType, ContextType>;
  toolboxEdge?: Resolver<
    Maybe<ResolversTypes["ToolboxesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateToolboxPayloadToolboxEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateToolboxTenantPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateToolboxTenantPayload"] = ResolversParentTypes["UpdateToolboxTenantPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  toolboxByToolboxId?: Resolver<
    Maybe<ResolversTypes["Toolbox"]>,
    ParentType,
    ContextType
  >;
  toolboxTenant?: Resolver<
    Maybe<ResolversTypes["ToolboxTenant"]>,
    ParentType,
    ContextType
  >;
  toolboxTenantEdge?: Resolver<
    Maybe<ResolversTypes["ToolboxTenantsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateToolboxTenantPayloadToolboxTenantEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateToolboxVersionExitcodePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateToolboxVersionExitcodePayload"] = ResolversParentTypes["UpdateToolboxVersionExitcodePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolboxExitcodeByToolboxexitcodeId?: Resolver<
    Maybe<ResolversTypes["ToolboxExitcode"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionByToolboxversionId?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionExitcode?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionExitcode"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionExitcodeEdge?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionExitcodesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateToolboxVersionExitcodePayloadToolboxVersionExitcodeEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateToolboxVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateToolboxVersionPayload"] = ResolversParentTypes["UpdateToolboxVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolboxByToolboxId?: Resolver<
    Maybe<ResolversTypes["Toolbox"]>,
    ParentType,
    ContextType
  >;
  toolboxVersion?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionEdge?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateToolboxVersionPayloadToolboxVersionEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateToolboxVersionToolVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateToolboxVersionToolVersionPayload"] = ResolversParentTypes["UpdateToolboxVersionToolVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolVersionByToolversionId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionByToolboxversionId?: Resolver<
    Maybe<ResolversTypes["ToolboxVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionToolVersion?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionToolVersion"]>,
    ParentType,
    ContextType
  >;
  toolboxVersionToolVersionEdge?: Resolver<
    Maybe<ResolversTypes["ToolboxVersionToolVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateToolboxVersionToolVersionPayloadToolboxVersionToolVersionEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateWorkflowDatumPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateWorkflowDatumPayload"] = ResolversParentTypes["UpdateWorkflowDatumPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowDatum?: Resolver<
    Maybe<ResolversTypes["WorkflowDatum"]>,
    ParentType,
    ContextType
  >;
  workflowDatumEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowDataEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateWorkflowDatumPayloadWorkflowDatumEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateWorkflowPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateWorkflowPayload"] = ResolversParentTypes["UpdateWorkflowPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  containerImageByContainerId?: Resolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  workflow?: Resolver<
    Maybe<ResolversTypes["Workflow"]>,
    ParentType,
    ContextType
  >;
  workflowEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateWorkflowPayloadWorkflowEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateWorkflowResultPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateWorkflowResultPayload"] = ResolversParentTypes["UpdateWorkflowResultPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowDatumByObjectId?: Resolver<
    Maybe<ResolversTypes["WorkflowDatum"]>,
    ParentType,
    ContextType
  >;
  workflowResult?: Resolver<
    Maybe<ResolversTypes["WorkflowResult"]>,
    ParentType,
    ContextType
  >;
  workflowResultEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowResultsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateWorkflowResultPayloadWorkflowResultEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateWorkflowResultTagPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateWorkflowResultTagPayload"] = ResolversParentTypes["UpdateWorkflowResultTagPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tagByTagId?: Resolver<Maybe<ResolversTypes["Tag"]>, ParentType, ContextType>;
  workflowResultByResultId?: Resolver<
    Maybe<ResolversTypes["WorkflowResult"]>,
    ParentType,
    ContextType
  >;
  workflowResultTag?: Resolver<
    Maybe<ResolversTypes["WorkflowResultTag"]>,
    ParentType,
    ContextType
  >;
  workflowResultTagEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowResultTagsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateWorkflowResultTagPayloadWorkflowResultTagEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateWorkflowResultWorkflowVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateWorkflowResultWorkflowVersionPayload"] = ResolversParentTypes["UpdateWorkflowResultWorkflowVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowResultByResultId?: Resolver<
    Maybe<ResolversTypes["WorkflowResult"]>,
    ParentType,
    ContextType
  >;
  workflowResultWorkflowVersion?: Resolver<
    Maybe<ResolversTypes["WorkflowResultWorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  workflowResultWorkflowVersionEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowResultWorkflowVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateWorkflowResultWorkflowVersionPayloadWorkflowResultWorkflowVersionEdgeArgs,
      "orderBy"
    >
  >;
  workflowVersionByWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["WorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateWorkflowSectionBlockPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateWorkflowSectionBlockPayload"] = ResolversParentTypes["UpdateWorkflowSectionBlockPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  toolVersionByToolId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  workflowDatumByObjectId?: Resolver<
    Maybe<ResolversTypes["WorkflowDatum"]>,
    ParentType,
    ContextType
  >;
  workflowSectionBlock?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlock"]>,
    ParentType,
    ContextType
  >;
  workflowSectionBlockEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlocksEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateWorkflowSectionBlockPayloadWorkflowSectionBlockEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateWorkflowSectionBlockSectionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateWorkflowSectionBlockSectionPayload"] = ResolversParentTypes["UpdateWorkflowSectionBlockSectionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowSectionBlockByBlockId?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlock"]>,
    ParentType,
    ContextType
  >;
  workflowSectionBlockSection?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlockSection"]>,
    ParentType,
    ContextType
  >;
  workflowSectionBlockSectionEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlockSectionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateWorkflowSectionBlockSectionPayloadWorkflowSectionBlockSectionEdgeArgs,
      "orderBy"
    >
  >;
  workflowSectionBySectionId?: Resolver<
    Maybe<ResolversTypes["WorkflowSection"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateWorkflowSectionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateWorkflowSectionPayload"] = ResolversParentTypes["UpdateWorkflowSectionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowSection?: Resolver<
    Maybe<ResolversTypes["WorkflowSection"]>,
    ParentType,
    ContextType
  >;
  workflowSectionEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateWorkflowSectionPayloadWorkflowSectionEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateWorkflowSectionWorkflowVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateWorkflowSectionWorkflowVersionPayload"] = ResolversParentTypes["UpdateWorkflowSectionWorkflowVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowSectionBySectionId?: Resolver<
    Maybe<ResolversTypes["WorkflowSection"]>,
    ParentType,
    ContextType
  >;
  workflowSectionWorkflowVersion?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionWorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  workflowSectionWorkflowVersionEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionWorkflowVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateWorkflowSectionWorkflowVersionPayloadWorkflowSectionWorkflowVersionEdgeArgs,
      "orderBy"
    >
  >;
  workflowVersionByWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["WorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateWorkflowSourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateWorkflowSourcePayload"] = ResolversParentTypes["UpdateWorkflowSourcePayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowSource?: Resolver<
    Maybe<ResolversTypes["WorkflowSource"]>,
    ParentType,
    ContextType
  >;
  workflowSourceEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSourcesEdge"]>,
    ParentType,
    ContextType,
    RequireFields<UpdateWorkflowSourcePayloadWorkflowSourceEdgeArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateWorkflowSourceTagPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateWorkflowSourceTagPayload"] = ResolversParentTypes["UpdateWorkflowSourceTagPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  tagByTagId?: Resolver<Maybe<ResolversTypes["Tag"]>, ParentType, ContextType>;
  workflowSourceBySourceId?: Resolver<
    Maybe<ResolversTypes["WorkflowSource"]>,
    ParentType,
    ContextType
  >;
  workflowSourceTag?: Resolver<
    Maybe<ResolversTypes["WorkflowSourceTag"]>,
    ParentType,
    ContextType
  >;
  workflowSourceTagEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSourceTagsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateWorkflowSourceTagPayloadWorkflowSourceTagEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateWorkflowSourceWorkflowVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateWorkflowSourceWorkflowVersionPayload"] = ResolversParentTypes["UpdateWorkflowSourceWorkflowVersionPayload"],
> = {
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowSourceBySourceId?: Resolver<
    Maybe<ResolversTypes["WorkflowSource"]>,
    ParentType,
    ContextType
  >;
  workflowSourceWorkflowVersion?: Resolver<
    Maybe<ResolversTypes["WorkflowSourceWorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  workflowSourceWorkflowVersionEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowSourceWorkflowVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateWorkflowSourceWorkflowVersionPayloadWorkflowSourceWorkflowVersionEdgeArgs,
      "orderBy"
    >
  >;
  workflowVersionByWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["WorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateWorkflowVersionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UpdateWorkflowVersionPayload"] = ResolversParentTypes["UpdateWorkflowVersionPayload"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  clientMutationId?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  query?: Resolver<Maybe<ResolversTypes["Query"]>, ParentType, ContextType>;
  workflowByWorkflowId?: Resolver<
    Maybe<ResolversTypes["Workflow"]>,
    ParentType,
    ContextType
  >;
  workflowVersion?: Resolver<
    Maybe<ResolversTypes["WorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  workflowVersionEdge?: Resolver<
    Maybe<ResolversTypes["WorkflowVersionsEdge"]>,
    ParentType,
    ContextType,
    RequireFields<
      UpdateWorkflowVersionPayloadWorkflowVersionEdgeArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAccessToProjectResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UserAccessToProject"] = ResolversParentTypes["UserAccessToProject"],
> = {
  edit?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
  grantAccess?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType
  >;
  projectId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  view?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAccessToProjectsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UserAccessToProjectsConnection"] = ResolversParentTypes["UserAccessToProjectsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["UserAccessToProjectsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["UserAccessToProject"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAccessToProjectsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["UserAccessToProjectsEdge"] = ResolversParentTypes["UserAccessToProjectsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["UserAccessToProject"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["Workflow"] = ResolversParentTypes["Workflow"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  containerId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  containerImageByContainerId?: Resolver<
    Maybe<ResolversTypes["ContainerImage"]>,
    ParentType,
    ContextType
  >;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  tenantByTenantId?: Resolver<
    Maybe<ResolversTypes["Tenant"]>,
    ParentType,
    ContextType
  >;
  tenantId?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  workflowVersionsByWorkflowId?: Resolver<
    ResolversTypes["WorkflowVersionsConnection"],
    ParentType,
    ContextType,
    RequireFields<WorkflowWorkflowVersionsByWorkflowIdArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowDataConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowDataConnection"] = ResolversParentTypes["WorkflowDataConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["WorkflowDataEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["WorkflowDatum"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowDataEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowDataEdge"] = ResolversParentTypes["WorkflowDataEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["WorkflowDatum"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowDatumResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowDatum"] = ResolversParentTypes["WorkflowDatum"],
> = {
  fresh?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  workflowResultsByObjectId?: Resolver<
    ResolversTypes["WorkflowResultsConnection"],
    ParentType,
    ContextType,
    RequireFields<WorkflowDatumWorkflowResultsByObjectIdArgs, "orderBy">
  >;
  workflowSectionBlocksByObjectId?: Resolver<
    ResolversTypes["WorkflowSectionBlocksConnection"],
    ParentType,
    ContextType,
    RequireFields<WorkflowDatumWorkflowSectionBlocksByObjectIdArgs, "orderBy">
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowResultResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowResult"] = ResolversParentTypes["WorkflowResult"],
> = {
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  objectId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  workflowDatumByObjectId?: Resolver<
    Maybe<ResolversTypes["WorkflowDatum"]>,
    ParentType,
    ContextType
  >;
  workflowResultTagsByResultId?: Resolver<
    ResolversTypes["WorkflowResultTagsConnection"],
    ParentType,
    ContextType,
    RequireFields<WorkflowResultWorkflowResultTagsByResultIdArgs, "orderBy">
  >;
  workflowResultWorkflowVersionsByResultId?: Resolver<
    ResolversTypes["WorkflowResultWorkflowVersionsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      WorkflowResultWorkflowResultWorkflowVersionsByResultIdArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowResultTagResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowResultTag"] = ResolversParentTypes["WorkflowResultTag"],
> = {
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  resultId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  tagByTagId?: Resolver<Maybe<ResolversTypes["Tag"]>, ParentType, ContextType>;
  tagId?: Resolver<ResolversTypes["BigInt"], ParentType, ContextType>;
  workflowResultByResultId?: Resolver<
    Maybe<ResolversTypes["WorkflowResult"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowResultTagsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowResultTagsConnection"] = ResolversParentTypes["WorkflowResultTagsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["WorkflowResultTagsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["WorkflowResultTag"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowResultTagsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowResultTagsEdge"] = ResolversParentTypes["WorkflowResultTagsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["WorkflowResultTag"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowResultWorkflowVersionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowResultWorkflowVersion"] = ResolversParentTypes["WorkflowResultWorkflowVersion"],
> = {
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  resultId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  workflowResultByResultId?: Resolver<
    Maybe<ResolversTypes["WorkflowResult"]>,
    ParentType,
    ContextType
  >;
  workflowVersionByWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["WorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  workflowversionId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowResultWorkflowVersionsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowResultWorkflowVersionsConnection"] = ResolversParentTypes["WorkflowResultWorkflowVersionsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["WorkflowResultWorkflowVersionsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["WorkflowResultWorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowResultWorkflowVersionsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowResultWorkflowVersionsEdge"] = ResolversParentTypes["WorkflowResultWorkflowVersionsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["WorkflowResultWorkflowVersion"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowResultsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowResultsConnection"] = ResolversParentTypes["WorkflowResultsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["WorkflowResultsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["WorkflowResult"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowResultsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowResultsEdge"] = ResolversParentTypes["WorkflowResultsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["WorkflowResult"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSection"] = ResolversParentTypes["WorkflowSection"],
> = {
  description?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  order?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  workflowSectionBlockSectionsBySectionId?: Resolver<
    ResolversTypes["WorkflowSectionBlockSectionsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      WorkflowSectionWorkflowSectionBlockSectionsBySectionIdArgs,
      "orderBy"
    >
  >;
  workflowSectionWorkflowVersionsBySectionId?: Resolver<
    ResolversTypes["WorkflowSectionWorkflowVersionsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      WorkflowSectionWorkflowSectionWorkflowVersionsBySectionIdArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSectionBlockResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSectionBlock"] = ResolversParentTypes["WorkflowSectionBlock"],
> = {
  columns?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  markdown?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  objectId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  order?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  parameters?: Resolver<Maybe<ResolversTypes["JSON"]>, ParentType, ContextType>;
  results?: Resolver<Maybe<ResolversTypes["JSON"]>, ParentType, ContextType>;
  sources?: Resolver<Maybe<ResolversTypes["JSON"]>, ParentType, ContextType>;
  toolId?: Resolver<Maybe<ResolversTypes["Int"]>, ParentType, ContextType>;
  toolVersionByToolId?: Resolver<
    Maybe<ResolversTypes["ToolVersion"]>,
    ParentType,
    ContextType
  >;
  workflowDatumByObjectId?: Resolver<
    Maybe<ResolversTypes["WorkflowDatum"]>,
    ParentType,
    ContextType
  >;
  workflowSectionBlockSectionsByBlockId?: Resolver<
    ResolversTypes["WorkflowSectionBlockSectionsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      WorkflowSectionBlockWorkflowSectionBlockSectionsByBlockIdArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSectionBlockSectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSectionBlockSection"] = ResolversParentTypes["WorkflowSectionBlockSection"],
> = {
  blockId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  sectionId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  workflowSectionBlockByBlockId?: Resolver<
    Maybe<ResolversTypes["WorkflowSectionBlock"]>,
    ParentType,
    ContextType
  >;
  workflowSectionBySectionId?: Resolver<
    Maybe<ResolversTypes["WorkflowSection"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSectionBlockSectionsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSectionBlockSectionsConnection"] = ResolversParentTypes["WorkflowSectionBlockSectionsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["WorkflowSectionBlockSectionsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["WorkflowSectionBlockSection"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSectionBlockSectionsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSectionBlockSectionsEdge"] = ResolversParentTypes["WorkflowSectionBlockSectionsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["WorkflowSectionBlockSection"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSectionBlocksConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSectionBlocksConnection"] = ResolversParentTypes["WorkflowSectionBlocksConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["WorkflowSectionBlocksEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["WorkflowSectionBlock"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSectionBlocksEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSectionBlocksEdge"] = ResolversParentTypes["WorkflowSectionBlocksEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["WorkflowSectionBlock"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSectionWorkflowVersionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSectionWorkflowVersion"] = ResolversParentTypes["WorkflowSectionWorkflowVersion"],
> = {
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  sectionId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  workflowSectionBySectionId?: Resolver<
    Maybe<ResolversTypes["WorkflowSection"]>,
    ParentType,
    ContextType
  >;
  workflowVersionByWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["WorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  workflowversionId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSectionWorkflowVersionsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSectionWorkflowVersionsConnection"] = ResolversParentTypes["WorkflowSectionWorkflowVersionsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["WorkflowSectionWorkflowVersionsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["WorkflowSectionWorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSectionWorkflowVersionsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSectionWorkflowVersionsEdge"] = ResolversParentTypes["WorkflowSectionWorkflowVersionsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["WorkflowSectionWorkflowVersion"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSectionsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSectionsConnection"] = ResolversParentTypes["WorkflowSectionsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["WorkflowSectionsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["WorkflowSection"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSectionsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSectionsEdge"] = ResolversParentTypes["WorkflowSectionsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["WorkflowSection"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSourceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSource"] = ResolversParentTypes["WorkflowSource"],
> = {
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  workflowSourceTagsBySourceId?: Resolver<
    ResolversTypes["WorkflowSourceTagsConnection"],
    ParentType,
    ContextType,
    RequireFields<WorkflowSourceWorkflowSourceTagsBySourceIdArgs, "orderBy">
  >;
  workflowSourceWorkflowVersionsBySourceId?: Resolver<
    ResolversTypes["WorkflowSourceWorkflowVersionsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      WorkflowSourceWorkflowSourceWorkflowVersionsBySourceIdArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSourceTagResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSourceTag"] = ResolversParentTypes["WorkflowSourceTag"],
> = {
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  sourceId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  tagByTagId?: Resolver<Maybe<ResolversTypes["Tag"]>, ParentType, ContextType>;
  tagId?: Resolver<ResolversTypes["BigInt"], ParentType, ContextType>;
  workflowSourceBySourceId?: Resolver<
    Maybe<ResolversTypes["WorkflowSource"]>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSourceTagsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSourceTagsConnection"] = ResolversParentTypes["WorkflowSourceTagsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["WorkflowSourceTagsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["WorkflowSourceTag"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSourceTagsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSourceTagsEdge"] = ResolversParentTypes["WorkflowSourceTagsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["WorkflowSourceTag"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSourceWorkflowVersionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSourceWorkflowVersion"] = ResolversParentTypes["WorkflowSourceWorkflowVersion"],
> = {
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  sourceId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  workflowSourceBySourceId?: Resolver<
    Maybe<ResolversTypes["WorkflowSource"]>,
    ParentType,
    ContextType
  >;
  workflowVersionByWorkflowversionId?: Resolver<
    Maybe<ResolversTypes["WorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  workflowversionId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSourceWorkflowVersionsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSourceWorkflowVersionsConnection"] = ResolversParentTypes["WorkflowSourceWorkflowVersionsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["WorkflowSourceWorkflowVersionsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["WorkflowSourceWorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSourceWorkflowVersionsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSourceWorkflowVersionsEdge"] = ResolversParentTypes["WorkflowSourceWorkflowVersionsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes["WorkflowSourceWorkflowVersion"],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSourcesConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSourcesConnection"] = ResolversParentTypes["WorkflowSourcesConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["WorkflowSourcesEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["WorkflowSource"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowSourcesEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowSourcesEdge"] = ResolversParentTypes["WorkflowSourcesEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["WorkflowSource"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowVersionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowVersion"] = ResolversParentTypes["WorkflowVersion"],
> = {
  applicationUserByUserId?: Resolver<
    Maybe<ResolversTypes["ApplicationUser"]>,
    ParentType,
    ContextType
  >;
  autoGenerated?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  dateCreated?: Resolver<ResolversTypes["Datetime"], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType
  >;
  draft?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes["UUID"]>, ParentType, ContextType>;
  workflowByWorkflowId?: Resolver<
    Maybe<ResolversTypes["Workflow"]>,
    ParentType,
    ContextType
  >;
  workflowId?: Resolver<ResolversTypes["UUID"], ParentType, ContextType>;
  workflowResultWorkflowVersionsByWorkflowversionId?: Resolver<
    ResolversTypes["WorkflowResultWorkflowVersionsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      WorkflowVersionWorkflowResultWorkflowVersionsByWorkflowversionIdArgs,
      "orderBy"
    >
  >;
  workflowSectionWorkflowVersionsByWorkflowversionId?: Resolver<
    ResolversTypes["WorkflowSectionWorkflowVersionsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      WorkflowVersionWorkflowSectionWorkflowVersionsByWorkflowversionIdArgs,
      "orderBy"
    >
  >;
  workflowSourceWorkflowVersionsByWorkflowversionId?: Resolver<
    ResolversTypes["WorkflowSourceWorkflowVersionsConnection"],
    ParentType,
    ContextType,
    RequireFields<
      WorkflowVersionWorkflowSourceWorkflowVersionsByWorkflowversionIdArgs,
      "orderBy"
    >
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowVersionsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowVersionsConnection"] = ResolversParentTypes["WorkflowVersionsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["WorkflowVersionsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes["WorkflowVersion"]>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowVersionsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowVersionsEdge"] = ResolversParentTypes["WorkflowVersionsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["WorkflowVersion"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowsConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowsConnection"] = ResolversParentTypes["WorkflowsConnection"],
> = {
  edges?: Resolver<
    Array<ResolversTypes["WorkflowsEdge"]>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes["Workflow"]>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes["PageInfo"], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowsEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes["WorkflowsEdge"] = ResolversParentTypes["WorkflowsEdge"],
> = {
  cursor?: Resolver<Maybe<ResolversTypes["Cursor"]>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes["Workflow"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AnalysisTable?: AnalysisTableResolvers<ContextType>;
  AnalysisTableGroup?: AnalysisTableGroupResolvers<ContextType>;
  AnalysisTableGroupsConnection?: AnalysisTableGroupsConnectionResolvers<ContextType>;
  AnalysisTableGroupsEdge?: AnalysisTableGroupsEdgeResolvers<ContextType>;
  AnalysisTableRow?: AnalysisTableRowResolvers<ContextType>;
  AnalysisTableRowAttachResult?: AnalysisTableRowAttachResultResolvers<ContextType>;
  AnalysisTableRowAttachResultsConnection?: AnalysisTableRowAttachResultsConnectionResolvers<ContextType>;
  AnalysisTableRowAttachResultsEdge?: AnalysisTableRowAttachResultsEdgeResolvers<ContextType>;
  AnalysisTableRowsConnection?: AnalysisTableRowsConnectionResolvers<ContextType>;
  AnalysisTableRowsEdge?: AnalysisTableRowsEdgeResolvers<ContextType>;
  AnalysisTablesConnection?: AnalysisTablesConnectionResolvers<ContextType>;
  AnalysisTablesEdge?: AnalysisTablesEdgeResolvers<ContextType>;
  ApplicationUser?: ApplicationUserResolvers<ContextType>;
  ApplicationUserGroup?: ApplicationUserGroupResolvers<ContextType>;
  ApplicationUserGroupsConnection?: ApplicationUserGroupsConnectionResolvers<ContextType>;
  ApplicationUserGroupsEdge?: ApplicationUserGroupsEdgeResolvers<ContextType>;
  ApplicationUserMembership?: ApplicationUserMembershipResolvers<ContextType>;
  ApplicationUserMembershipsConnection?: ApplicationUserMembershipsConnectionResolvers<ContextType>;
  ApplicationUserMembershipsEdge?: ApplicationUserMembershipsEdgeResolvers<ContextType>;
  ApplicationUserMetadataConnection?: ApplicationUserMetadataConnectionResolvers<ContextType>;
  ApplicationUserMetadataEdge?: ApplicationUserMetadataEdgeResolvers<ContextType>;
  ApplicationUserMetadatum?: ApplicationUserMetadatumResolvers<ContextType>;
  ApplicationUserSession?: ApplicationUserSessionResolvers<ContextType>;
  ApplicationUserSessionsConnection?: ApplicationUserSessionsConnectionResolvers<ContextType>;
  ApplicationUserSessionsEdge?: ApplicationUserSessionsEdgeResolvers<ContextType>;
  ApplicationUserUserPermission?: ApplicationUserUserPermissionResolvers<ContextType>;
  ApplicationUserUserPermissionsConnection?: ApplicationUserUserPermissionsConnectionResolvers<ContextType>;
  ApplicationUserUserPermissionsEdge?: ApplicationUserUserPermissionsEdgeResolvers<ContextType>;
  ApplicationUsersEdge?: ApplicationUsersEdgeResolvers<ContextType>;
  AuthGroup?: AuthGroupResolvers<ContextType>;
  AuthGroupPermission?: AuthGroupPermissionResolvers<ContextType>;
  AuthGroupPermissionsConnection?: AuthGroupPermissionsConnectionResolvers<ContextType>;
  AuthGroupPermissionsEdge?: AuthGroupPermissionsEdgeResolvers<ContextType>;
  AuthGroupsConnection?: AuthGroupsConnectionResolvers<ContextType>;
  AuthGroupsEdge?: AuthGroupsEdgeResolvers<ContextType>;
  AuthPermission?: AuthPermissionResolvers<ContextType>;
  AuthPermissionsConnection?: AuthPermissionsConnectionResolvers<ContextType>;
  AuthPermissionsEdge?: AuthPermissionsEdgeResolvers<ContextType>;
  AvailableNonRestrictedProject?: AvailableNonRestrictedProjectResolvers<ContextType>;
  AvailableNonRestrictedProjectsConnection?: AvailableNonRestrictedProjectsConnectionResolvers<ContextType>;
  AvailableNonRestrictedProjectsEdge?: AvailableNonRestrictedProjectsEdgeResolvers<ContextType>;
  Avatar?: AvatarResolvers<ContextType>;
  AvatarsConnection?: AvatarsConnectionResolvers<ContextType>;
  AvatarsEdge?: AvatarsEdgeResolvers<ContextType>;
  AwsBatchTask?: AwsBatchTaskResolvers<ContextType>;
  AwsBatchTasksConnection?: AwsBatchTasksConnectionResolvers<ContextType>;
  AwsBatchTasksEdge?: AwsBatchTasksEdgeResolvers<ContextType>;
  AwsCognitoUser?: AwsCognitoUserResolvers<ContextType>;
  AwsCognitoUsersConnection?: AwsCognitoUsersConnectionResolvers<ContextType>;
  AwsCognitoUsersEdge?: AwsCognitoUsersEdgeResolvers<ContextType>;
  AwsEcrImage?: AwsEcrImageResolvers<ContextType>;
  AwsEcrImagesConnection?: AwsEcrImagesConnectionResolvers<ContextType>;
  AwsEcrImagesEdge?: AwsEcrImagesEdgeResolvers<ContextType>;
  AwsMockBatchTask?: AwsMockBatchTaskResolvers<ContextType>;
  AwsMockBatchTasksConnection?: AwsMockBatchTasksConnectionResolvers<ContextType>;
  AwsMockBatchTasksEdge?: AwsMockBatchTasksEdgeResolvers<ContextType>;
  AwsMockEcrImage?: AwsMockEcrImageResolvers<ContextType>;
  AwsMockEcrImagesConnection?: AwsMockEcrImagesConnectionResolvers<ContextType>;
  AwsMockEcrImagesEdge?: AwsMockEcrImagesEdgeResolvers<ContextType>;
  AwsMockS3File?: AwsMockS3FileResolvers<ContextType>;
  AwsMockS3FilesConnection?: AwsMockS3FilesConnectionResolvers<ContextType>;
  AwsMockS3FilesEdge?: AwsMockS3FilesEdgeResolvers<ContextType>;
  AwsS3File?: AwsS3FileResolvers<ContextType>;
  AwsS3FilesConnection?: AwsS3FilesConnectionResolvers<ContextType>;
  AwsS3FilesEdge?: AwsS3FilesEdgeResolvers<ContextType>;
  BigFloat?: GraphQLScalarType;
  BigInt?: GraphQLScalarType;
  ChangeUserSessionPayload?: ChangeUserSessionPayloadResolvers<ContextType>;
  ContainerImage?: ContainerImageResolvers<ContextType>;
  ContainerImagesConnection?: ContainerImagesConnectionResolvers<ContextType>;
  ContainerImagesEdge?: ContainerImagesEdgeResolvers<ContextType>;
  Cost?: CostResolvers<ContextType>;
  CostsConnection?: CostsConnectionResolvers<ContextType>;
  CostsEdge?: CostsEdgeResolvers<ContextType>;
  CreateAnalysisTableGroupPayload?: CreateAnalysisTableGroupPayloadResolvers<ContextType>;
  CreateAnalysisTablePayload?: CreateAnalysisTablePayloadResolvers<ContextType>;
  CreateAnalysisTableRowAttachResultPayload?: CreateAnalysisTableRowAttachResultPayloadResolvers<ContextType>;
  CreateAnalysisTableRowPayload?: CreateAnalysisTableRowPayloadResolvers<ContextType>;
  CreateApplicationUserGroupPayload?: CreateApplicationUserGroupPayloadResolvers<ContextType>;
  CreateApplicationUserMembershipPayload?: CreateApplicationUserMembershipPayloadResolvers<ContextType>;
  CreateApplicationUserMetadatumPayload?: CreateApplicationUserMetadatumPayloadResolvers<ContextType>;
  CreateApplicationUserPayload?: CreateApplicationUserPayloadResolvers<ContextType>;
  CreateApplicationUserSessionPayload?: CreateApplicationUserSessionPayloadResolvers<ContextType>;
  CreateApplicationUserUserPermissionPayload?: CreateApplicationUserUserPermissionPayloadResolvers<ContextType>;
  CreateAuthGroupPayload?: CreateAuthGroupPayloadResolvers<ContextType>;
  CreateAuthGroupPermissionPayload?: CreateAuthGroupPermissionPayloadResolvers<ContextType>;
  CreateAuthPermissionPayload?: CreateAuthPermissionPayloadResolvers<ContextType>;
  CreateAvailableNonRestrictedProjectPayload?: CreateAvailableNonRestrictedProjectPayloadResolvers<ContextType>;
  CreateAvatarPayload?: CreateAvatarPayloadResolvers<ContextType>;
  CreateAwsBatchTaskPayload?: CreateAwsBatchTaskPayloadResolvers<ContextType>;
  CreateAwsCognitoUserPayload?: CreateAwsCognitoUserPayloadResolvers<ContextType>;
  CreateAwsEcrImagePayload?: CreateAwsEcrImagePayloadResolvers<ContextType>;
  CreateAwsMockBatchTaskPayload?: CreateAwsMockBatchTaskPayloadResolvers<ContextType>;
  CreateAwsMockEcrImagePayload?: CreateAwsMockEcrImagePayloadResolvers<ContextType>;
  CreateAwsMockS3FilePayload?: CreateAwsMockS3FilePayloadResolvers<ContextType>;
  CreateAwsS3FilePayload?: CreateAwsS3FilePayloadResolvers<ContextType>;
  CreateContainerImagePayload?: CreateContainerImagePayloadResolvers<ContextType>;
  CreateCostPayload?: CreateCostPayloadResolvers<ContextType>;
  CreateCurrentApplicationUserPayload?: CreateCurrentApplicationUserPayloadResolvers<ContextType>;
  CreateCurrentUserMembershipPayload?: CreateCurrentUserMembershipPayloadResolvers<ContextType>;
  CreateDatasetMetadatumPayload?: CreateDatasetMetadatumPayloadResolvers<ContextType>;
  CreateDatasetPayload?: CreateDatasetPayloadResolvers<ContextType>;
  CreateDatasetRecordingsTableColumnOrderPayload?: CreateDatasetRecordingsTableColumnOrderPayloadResolvers<ContextType>;
  CreateDatasetRecordingsTableColumnPayload?: CreateDatasetRecordingsTableColumnPayloadResolvers<ContextType>;
  CreateDatasetRecordingsTablePayload?: CreateDatasetRecordingsTablePayloadResolvers<ContextType>;
  CreateDatasetVersionPayload?: CreateDatasetVersionPayloadResolvers<ContextType>;
  CreateDjangoAdminLogPayload?: CreateDjangoAdminLogPayloadResolvers<ContextType>;
  CreateDjangoContentTypePayload?: CreateDjangoContentTypePayloadResolvers<ContextType>;
  CreateDjangoMigrationPayload?: CreateDjangoMigrationPayloadResolvers<ContextType>;
  CreateDjangoSesSesstatPayload?: CreateDjangoSesSesstatPayloadResolvers<ContextType>;
  CreateDjangoSessionPayload?: CreateDjangoSessionPayloadResolvers<ContextType>;
  CreateDrsFileexecutorPayload?: CreateDrsFileexecutorPayloadResolvers<ContextType>;
  CreateDrsMockfileexecutorPayload?: CreateDrsMockfileexecutorPayloadResolvers<ContextType>;
  CreateEulaVersionPayload?: CreateEulaVersionPayloadResolvers<ContextType>;
  CreateEulaVersionUserAcceptancePayload?: CreateEulaVersionUserAcceptancePayloadResolvers<ContextType>;
  CreateExplorerQueryPayload?: CreateExplorerQueryPayloadResolvers<ContextType>;
  CreateExplorerQueryfavoritePayload?: CreateExplorerQueryfavoritePayloadResolvers<ContextType>;
  CreateExplorerQuerylogPayload?: CreateExplorerQuerylogPayloadResolvers<ContextType>;
  CreateFileActivityPayload?: CreateFileActivityPayloadResolvers<ContextType>;
  CreateFileMetadatumPayload?: CreateFileMetadatumPayloadResolvers<ContextType>;
  CreateFileOutputGroupMetadatumPayload?: CreateFileOutputGroupMetadatumPayloadResolvers<ContextType>;
  CreateFilePayload?: CreateFilePayloadResolvers<ContextType>;
  CreateFileRecordingGroupMetadatumPayload?: CreateFileRecordingGroupMetadatumPayloadResolvers<ContextType>;
  CreateFileRecordingGroupPayload?: CreateFileRecordingGroupPayloadResolvers<ContextType>;
  CreateMetadataValuePayload?: CreateMetadataValuePayloadResolvers<ContextType>;
  CreateMetadatumPayload?: CreateMetadatumPayloadResolvers<ContextType>;
  CreateOutputGroupFilePayload?: CreateOutputGroupFilePayloadResolvers<ContextType>;
  CreateRecordingGroupFilePayload?: CreateRecordingGroupFilePayloadResolvers<ContextType>;
  CreateRolePayload?: CreateRolePayloadResolvers<ContextType>;
  CreateRunPayload?: CreateRunPayloadResolvers<ContextType>;
  CreateSessionPayload?: CreateSessionPayloadResolvers<ContextType>;
  CreateTagPayload?: CreateTagPayloadResolvers<ContextType>;
  CreateTaskActivityPayload?: CreateTaskActivityPayloadResolvers<ContextType>;
  CreateTaskPayload?: CreateTaskPayloadResolvers<ContextType>;
  CreateTaskResourcePayload?: CreateTaskResourcePayloadResolvers<ContextType>;
  CreateTaskSourcePayload?: CreateTaskSourcePayloadResolvers<ContextType>;
  CreateTaskStatusLogPayload?: CreateTaskStatusLogPayloadResolvers<ContextType>;
  CreateTenantContractAllowancePayload?: CreateTenantContractAllowancePayloadResolvers<ContextType>;
  CreateTenantContractPayload?: CreateTenantContractPayloadResolvers<ContextType>;
  CreateTenantContractTransactionPayload?: CreateTenantContractTransactionPayloadResolvers<ContextType>;
  CreateTenantMockuserexecutorPayload?: CreateTenantMockuserexecutorPayloadResolvers<ContextType>;
  CreateTenantMonthlyConsumedResourcePayload?: CreateTenantMonthlyConsumedResourcePayloadResolvers<ContextType>;
  CreateTenantPayload?: CreateTenantPayloadResolvers<ContextType>;
  CreateTenantUserexecutorPayload?: CreateTenantUserexecutorPayloadResolvers<ContextType>;
  CreateTesContainerimageexecutorPayload?: CreateTesContainerimageexecutorPayloadResolvers<ContextType>;
  CreateTesMockcontainerimageexecutorPayload?: CreateTesMockcontainerimageexecutorPayloadResolvers<ContextType>;
  CreateTesMocktaskexecutorPayload?: CreateTesMocktaskexecutorPayloadResolvers<ContextType>;
  CreateTesTaskexecutorPayload?: CreateTesTaskexecutorPayloadResolvers<ContextType>;
  CreateToolCategoryPayload?: CreateToolCategoryPayloadResolvers<ContextType>;
  CreateToolDisplayCategoryPayload?: CreateToolDisplayCategoryPayloadResolvers<ContextType>;
  CreateToolParameterPayload?: CreateToolParameterPayloadResolvers<ContextType>;
  CreateToolPayload?: CreateToolPayloadResolvers<ContextType>;
  CreateToolResourcePayload?: CreateToolResourcePayloadResolvers<ContextType>;
  CreateToolResultPayload?: CreateToolResultPayloadResolvers<ContextType>;
  CreateToolSecretPayload?: CreateToolSecretPayloadResolvers<ContextType>;
  CreateToolSourcePayload?: CreateToolSourcePayloadResolvers<ContextType>;
  CreateToolTenantPayload?: CreateToolTenantPayloadResolvers<ContextType>;
  CreateToolVersionPayload?: CreateToolVersionPayloadResolvers<ContextType>;
  CreateToolboxExitcodePayload?: CreateToolboxExitcodePayloadResolvers<ContextType>;
  CreateToolboxPayload?: CreateToolboxPayloadResolvers<ContextType>;
  CreateToolboxTenantPayload?: CreateToolboxTenantPayloadResolvers<ContextType>;
  CreateToolboxVersionExitcodePayload?: CreateToolboxVersionExitcodePayloadResolvers<ContextType>;
  CreateToolboxVersionPayload?: CreateToolboxVersionPayloadResolvers<ContextType>;
  CreateToolboxVersionToolVersionPayload?: CreateToolboxVersionToolVersionPayloadResolvers<ContextType>;
  CreateWorkflowDatumPayload?: CreateWorkflowDatumPayloadResolvers<ContextType>;
  CreateWorkflowPayload?: CreateWorkflowPayloadResolvers<ContextType>;
  CreateWorkflowResultPayload?: CreateWorkflowResultPayloadResolvers<ContextType>;
  CreateWorkflowResultTagPayload?: CreateWorkflowResultTagPayloadResolvers<ContextType>;
  CreateWorkflowResultWorkflowVersionPayload?: CreateWorkflowResultWorkflowVersionPayloadResolvers<ContextType>;
  CreateWorkflowSectionBlockPayload?: CreateWorkflowSectionBlockPayloadResolvers<ContextType>;
  CreateWorkflowSectionBlockSectionPayload?: CreateWorkflowSectionBlockSectionPayloadResolvers<ContextType>;
  CreateWorkflowSectionPayload?: CreateWorkflowSectionPayloadResolvers<ContextType>;
  CreateWorkflowSectionWorkflowVersionPayload?: CreateWorkflowSectionWorkflowVersionPayloadResolvers<ContextType>;
  CreateWorkflowSourcePayload?: CreateWorkflowSourcePayloadResolvers<ContextType>;
  CreateWorkflowSourceTagPayload?: CreateWorkflowSourceTagPayloadResolvers<ContextType>;
  CreateWorkflowSourceWorkflowVersionPayload?: CreateWorkflowSourceWorkflowVersionPayloadResolvers<ContextType>;
  CreateWorkflowVersionPayload?: CreateWorkflowVersionPayloadResolvers<ContextType>;
  CurrentApplicationUser?: CurrentApplicationUserResolvers<ContextType>;
  CurrentApplicationUsersConnection?: CurrentApplicationUsersConnectionResolvers<ContextType>;
  CurrentApplicationUsersEdge?: CurrentApplicationUsersEdgeResolvers<ContextType>;
  CurrentUserMembership?: CurrentUserMembershipResolvers<ContextType>;
  CurrentUserMembershipsConnection?: CurrentUserMembershipsConnectionResolvers<ContextType>;
  CurrentUserMembershipsEdge?: CurrentUserMembershipsEdgeResolvers<ContextType>;
  Cursor?: GraphQLScalarType;
  Dataset?: DatasetResolvers<ContextType>;
  DatasetMetadataConnection?: DatasetMetadataConnectionResolvers<ContextType>;
  DatasetMetadataEdge?: DatasetMetadataEdgeResolvers<ContextType>;
  DatasetMetadatum?: DatasetMetadatumResolvers<ContextType>;
  DatasetRecordingsTable?: DatasetRecordingsTableResolvers<ContextType>;
  DatasetRecordingsTableColumn?: DatasetRecordingsTableColumnResolvers<ContextType>;
  DatasetRecordingsTableColumnOrder?: DatasetRecordingsTableColumnOrderResolvers<ContextType>;
  DatasetRecordingsTableColumnOrdersConnection?: DatasetRecordingsTableColumnOrdersConnectionResolvers<ContextType>;
  DatasetRecordingsTableColumnOrdersEdge?: DatasetRecordingsTableColumnOrdersEdgeResolvers<ContextType>;
  DatasetRecordingsTableColumnsConnection?: DatasetRecordingsTableColumnsConnectionResolvers<ContextType>;
  DatasetRecordingsTableColumnsEdge?: DatasetRecordingsTableColumnsEdgeResolvers<ContextType>;
  DatasetRecordingsTablesConnection?: DatasetRecordingsTablesConnectionResolvers<ContextType>;
  DatasetRecordingsTablesEdge?: DatasetRecordingsTablesEdgeResolvers<ContextType>;
  DatasetVersion?: DatasetVersionResolvers<ContextType>;
  DatasetVersionsConnection?: DatasetVersionsConnectionResolvers<ContextType>;
  DatasetVersionsEdge?: DatasetVersionsEdgeResolvers<ContextType>;
  DatasetsConnection?: DatasetsConnectionResolvers<ContextType>;
  DatasetsEdge?: DatasetsEdgeResolvers<ContextType>;
  Date?: GraphQLScalarType;
  Datetime?: GraphQLScalarType;
  DeleteAnalysisTableGroupPayload?: DeleteAnalysisTableGroupPayloadResolvers<ContextType>;
  DeleteAnalysisTablePayload?: DeleteAnalysisTablePayloadResolvers<ContextType>;
  DeleteAnalysisTableRowAttachResultPayload?: DeleteAnalysisTableRowAttachResultPayloadResolvers<ContextType>;
  DeleteAnalysisTableRowPayload?: DeleteAnalysisTableRowPayloadResolvers<ContextType>;
  DeleteApplicationUserGroupPayload?: DeleteApplicationUserGroupPayloadResolvers<ContextType>;
  DeleteApplicationUserMembershipPayload?: DeleteApplicationUserMembershipPayloadResolvers<ContextType>;
  DeleteApplicationUserMetadatumPayload?: DeleteApplicationUserMetadatumPayloadResolvers<ContextType>;
  DeleteApplicationUserPayload?: DeleteApplicationUserPayloadResolvers<ContextType>;
  DeleteApplicationUserSessionPayload?: DeleteApplicationUserSessionPayloadResolvers<ContextType>;
  DeleteApplicationUserUserPermissionPayload?: DeleteApplicationUserUserPermissionPayloadResolvers<ContextType>;
  DeleteAuthGroupPayload?: DeleteAuthGroupPayloadResolvers<ContextType>;
  DeleteAuthGroupPermissionPayload?: DeleteAuthGroupPermissionPayloadResolvers<ContextType>;
  DeleteAuthPermissionPayload?: DeleteAuthPermissionPayloadResolvers<ContextType>;
  DeleteAvatarPayload?: DeleteAvatarPayloadResolvers<ContextType>;
  DeleteAwsBatchTaskPayload?: DeleteAwsBatchTaskPayloadResolvers<ContextType>;
  DeleteAwsCognitoUserPayload?: DeleteAwsCognitoUserPayloadResolvers<ContextType>;
  DeleteAwsEcrImagePayload?: DeleteAwsEcrImagePayloadResolvers<ContextType>;
  DeleteAwsMockBatchTaskPayload?: DeleteAwsMockBatchTaskPayloadResolvers<ContextType>;
  DeleteAwsMockEcrImagePayload?: DeleteAwsMockEcrImagePayloadResolvers<ContextType>;
  DeleteAwsMockS3FilePayload?: DeleteAwsMockS3FilePayloadResolvers<ContextType>;
  DeleteAwsS3FilePayload?: DeleteAwsS3FilePayloadResolvers<ContextType>;
  DeleteContainerImagePayload?: DeleteContainerImagePayloadResolvers<ContextType>;
  DeleteCostPayload?: DeleteCostPayloadResolvers<ContextType>;
  DeleteDatasetMetadatumPayload?: DeleteDatasetMetadatumPayloadResolvers<ContextType>;
  DeleteDatasetPayload?: DeleteDatasetPayloadResolvers<ContextType>;
  DeleteDatasetRecordingsTableColumnOrderPayload?: DeleteDatasetRecordingsTableColumnOrderPayloadResolvers<ContextType>;
  DeleteDatasetRecordingsTableColumnPayload?: DeleteDatasetRecordingsTableColumnPayloadResolvers<ContextType>;
  DeleteDatasetRecordingsTablePayload?: DeleteDatasetRecordingsTablePayloadResolvers<ContextType>;
  DeleteDatasetVersionPayload?: DeleteDatasetVersionPayloadResolvers<ContextType>;
  DeleteDjangoAdminLogPayload?: DeleteDjangoAdminLogPayloadResolvers<ContextType>;
  DeleteDjangoContentTypePayload?: DeleteDjangoContentTypePayloadResolvers<ContextType>;
  DeleteDjangoMigrationPayload?: DeleteDjangoMigrationPayloadResolvers<ContextType>;
  DeleteDjangoSesSesstatPayload?: DeleteDjangoSesSesstatPayloadResolvers<ContextType>;
  DeleteDjangoSessionPayload?: DeleteDjangoSessionPayloadResolvers<ContextType>;
  DeleteDrsFileexecutorPayload?: DeleteDrsFileexecutorPayloadResolvers<ContextType>;
  DeleteDrsMockfileexecutorPayload?: DeleteDrsMockfileexecutorPayloadResolvers<ContextType>;
  DeleteEulaVersionPayload?: DeleteEulaVersionPayloadResolvers<ContextType>;
  DeleteEulaVersionUserAcceptancePayload?: DeleteEulaVersionUserAcceptancePayloadResolvers<ContextType>;
  DeleteExplorerQueryPayload?: DeleteExplorerQueryPayloadResolvers<ContextType>;
  DeleteExplorerQueryfavoritePayload?: DeleteExplorerQueryfavoritePayloadResolvers<ContextType>;
  DeleteExplorerQuerylogPayload?: DeleteExplorerQuerylogPayloadResolvers<ContextType>;
  DeleteFileActivityPayload?: DeleteFileActivityPayloadResolvers<ContextType>;
  DeleteFileMetadatumPayload?: DeleteFileMetadatumPayloadResolvers<ContextType>;
  DeleteFileOutputGroupMetadatumPayload?: DeleteFileOutputGroupMetadatumPayloadResolvers<ContextType>;
  DeleteFileOutputGroupPayload?: DeleteFileOutputGroupPayloadResolvers<ContextType>;
  DeleteFilePayload?: DeleteFilePayloadResolvers<ContextType>;
  DeleteFileRecordingGroupMetadatumPayload?: DeleteFileRecordingGroupMetadatumPayloadResolvers<ContextType>;
  DeleteFileRecordingGroupPayload?: DeleteFileRecordingGroupPayloadResolvers<ContextType>;
  DeleteMetadataValuePayload?: DeleteMetadataValuePayloadResolvers<ContextType>;
  DeleteMetadatumPayload?: DeleteMetadatumPayloadResolvers<ContextType>;
  DeleteOutputGroupFilePayload?: DeleteOutputGroupFilePayloadResolvers<ContextType>;
  DeleteRecordingGroupFilePayload?: DeleteRecordingGroupFilePayloadResolvers<ContextType>;
  DeleteRunPayload?: DeleteRunPayloadResolvers<ContextType>;
  DeleteSessionPayload?: DeleteSessionPayloadResolvers<ContextType>;
  DeleteTagPayload?: DeleteTagPayloadResolvers<ContextType>;
  DeleteTaskActivityPayload?: DeleteTaskActivityPayloadResolvers<ContextType>;
  DeleteTaskPayload?: DeleteTaskPayloadResolvers<ContextType>;
  DeleteTaskResourcePayload?: DeleteTaskResourcePayloadResolvers<ContextType>;
  DeleteTaskSourcePayload?: DeleteTaskSourcePayloadResolvers<ContextType>;
  DeleteTaskStatusLogPayload?: DeleteTaskStatusLogPayloadResolvers<ContextType>;
  DeleteTenantContractAllowancePayload?: DeleteTenantContractAllowancePayloadResolvers<ContextType>;
  DeleteTenantContractPayload?: DeleteTenantContractPayloadResolvers<ContextType>;
  DeleteTenantContractTransactionPayload?: DeleteTenantContractTransactionPayloadResolvers<ContextType>;
  DeleteTenantMockuserexecutorPayload?: DeleteTenantMockuserexecutorPayloadResolvers<ContextType>;
  DeleteTenantMonthlyConsumedResourcePayload?: DeleteTenantMonthlyConsumedResourcePayloadResolvers<ContextType>;
  DeleteTenantPayload?: DeleteTenantPayloadResolvers<ContextType>;
  DeleteTenantUserexecutorPayload?: DeleteTenantUserexecutorPayloadResolvers<ContextType>;
  DeleteTesContainerimageexecutorPayload?: DeleteTesContainerimageexecutorPayloadResolvers<ContextType>;
  DeleteTesMockcontainerimageexecutorPayload?: DeleteTesMockcontainerimageexecutorPayloadResolvers<ContextType>;
  DeleteTesMocktaskexecutorPayload?: DeleteTesMocktaskexecutorPayloadResolvers<ContextType>;
  DeleteTesTaskexecutorPayload?: DeleteTesTaskexecutorPayloadResolvers<ContextType>;
  DeleteToolCategoryPayload?: DeleteToolCategoryPayloadResolvers<ContextType>;
  DeleteToolDisplayCategoryPayload?: DeleteToolDisplayCategoryPayloadResolvers<ContextType>;
  DeleteToolParameterPayload?: DeleteToolParameterPayloadResolvers<ContextType>;
  DeleteToolPayload?: DeleteToolPayloadResolvers<ContextType>;
  DeleteToolResourcePayload?: DeleteToolResourcePayloadResolvers<ContextType>;
  DeleteToolResultPayload?: DeleteToolResultPayloadResolvers<ContextType>;
  DeleteToolSecretPayload?: DeleteToolSecretPayloadResolvers<ContextType>;
  DeleteToolSourcePayload?: DeleteToolSourcePayloadResolvers<ContextType>;
  DeleteToolTenantPayload?: DeleteToolTenantPayloadResolvers<ContextType>;
  DeleteToolVersionPayload?: DeleteToolVersionPayloadResolvers<ContextType>;
  DeleteToolboxExitcodePayload?: DeleteToolboxExitcodePayloadResolvers<ContextType>;
  DeleteToolboxPayload?: DeleteToolboxPayloadResolvers<ContextType>;
  DeleteToolboxTenantPayload?: DeleteToolboxTenantPayloadResolvers<ContextType>;
  DeleteToolboxVersionExitcodePayload?: DeleteToolboxVersionExitcodePayloadResolvers<ContextType>;
  DeleteToolboxVersionPayload?: DeleteToolboxVersionPayloadResolvers<ContextType>;
  DeleteToolboxVersionToolVersionPayload?: DeleteToolboxVersionToolVersionPayloadResolvers<ContextType>;
  DeleteWorkflowDatumPayload?: DeleteWorkflowDatumPayloadResolvers<ContextType>;
  DeleteWorkflowPayload?: DeleteWorkflowPayloadResolvers<ContextType>;
  DeleteWorkflowResultPayload?: DeleteWorkflowResultPayloadResolvers<ContextType>;
  DeleteWorkflowResultTagPayload?: DeleteWorkflowResultTagPayloadResolvers<ContextType>;
  DeleteWorkflowResultWorkflowVersionPayload?: DeleteWorkflowResultWorkflowVersionPayloadResolvers<ContextType>;
  DeleteWorkflowSectionBlockPayload?: DeleteWorkflowSectionBlockPayloadResolvers<ContextType>;
  DeleteWorkflowSectionBlockSectionPayload?: DeleteWorkflowSectionBlockSectionPayloadResolvers<ContextType>;
  DeleteWorkflowSectionPayload?: DeleteWorkflowSectionPayloadResolvers<ContextType>;
  DeleteWorkflowSectionWorkflowVersionPayload?: DeleteWorkflowSectionWorkflowVersionPayloadResolvers<ContextType>;
  DeleteWorkflowSourcePayload?: DeleteWorkflowSourcePayloadResolvers<ContextType>;
  DeleteWorkflowSourceTagPayload?: DeleteWorkflowSourceTagPayloadResolvers<ContextType>;
  DeleteWorkflowSourceWorkflowVersionPayload?: DeleteWorkflowSourceWorkflowVersionPayloadResolvers<ContextType>;
  DeleteWorkflowVersionPayload?: DeleteWorkflowVersionPayloadResolvers<ContextType>;
  DjangoAdminLog?: DjangoAdminLogResolvers<ContextType>;
  DjangoAdminLogsConnection?: DjangoAdminLogsConnectionResolvers<ContextType>;
  DjangoAdminLogsEdge?: DjangoAdminLogsEdgeResolvers<ContextType>;
  DjangoContentType?: DjangoContentTypeResolvers<ContextType>;
  DjangoContentTypesConnection?: DjangoContentTypesConnectionResolvers<ContextType>;
  DjangoContentTypesEdge?: DjangoContentTypesEdgeResolvers<ContextType>;
  DjangoMigration?: DjangoMigrationResolvers<ContextType>;
  DjangoMigrationsConnection?: DjangoMigrationsConnectionResolvers<ContextType>;
  DjangoMigrationsEdge?: DjangoMigrationsEdgeResolvers<ContextType>;
  DjangoSesSesstat?: DjangoSesSesstatResolvers<ContextType>;
  DjangoSesSesstatsConnection?: DjangoSesSesstatsConnectionResolvers<ContextType>;
  DjangoSesSesstatsEdge?: DjangoSesSesstatsEdgeResolvers<ContextType>;
  DjangoSession?: DjangoSessionResolvers<ContextType>;
  DjangoSessionsConnection?: DjangoSessionsConnectionResolvers<ContextType>;
  DjangoSessionsEdge?: DjangoSessionsEdgeResolvers<ContextType>;
  DrsFileexecutor?: DrsFileexecutorResolvers<ContextType>;
  DrsFileexecutorsConnection?: DrsFileexecutorsConnectionResolvers<ContextType>;
  DrsFileexecutorsEdge?: DrsFileexecutorsEdgeResolvers<ContextType>;
  DrsMockfileexecutor?: DrsMockfileexecutorResolvers<ContextType>;
  DrsMockfileexecutorsConnection?: DrsMockfileexecutorsConnectionResolvers<ContextType>;
  DrsMockfileexecutorsEdge?: DrsMockfileexecutorsEdgeResolvers<ContextType>;
  EulaVersion?: EulaVersionResolvers<ContextType>;
  EulaVersionUserAcceptance?: EulaVersionUserAcceptanceResolvers<ContextType>;
  EulaVersionUserAcceptancesConnection?: EulaVersionUserAcceptancesConnectionResolvers<ContextType>;
  EulaVersionUserAcceptancesEdge?: EulaVersionUserAcceptancesEdgeResolvers<ContextType>;
  EulaVersionsConnection?: EulaVersionsConnectionResolvers<ContextType>;
  EulaVersionsEdge?: EulaVersionsEdgeResolvers<ContextType>;
  ExplorerQueriesConnection?: ExplorerQueriesConnectionResolvers<ContextType>;
  ExplorerQueriesEdge?: ExplorerQueriesEdgeResolvers<ContextType>;
  ExplorerQuery?: ExplorerQueryResolvers<ContextType>;
  ExplorerQueryfavorite?: ExplorerQueryfavoriteResolvers<ContextType>;
  ExplorerQueryfavoritesConnection?: ExplorerQueryfavoritesConnectionResolvers<ContextType>;
  ExplorerQueryfavoritesEdge?: ExplorerQueryfavoritesEdgeResolvers<ContextType>;
  ExplorerQuerylog?: ExplorerQuerylogResolvers<ContextType>;
  ExplorerQuerylogsConnection?: ExplorerQuerylogsConnectionResolvers<ContextType>;
  ExplorerQuerylogsEdge?: ExplorerQuerylogsEdgeResolvers<ContextType>;
  File?: FileResolvers<ContextType>;
  FileActivitiesConnection?: FileActivitiesConnectionResolvers<ContextType>;
  FileActivitiesEdge?: FileActivitiesEdgeResolvers<ContextType>;
  FileActivity?: FileActivityResolvers<ContextType>;
  FileMetadataConnection?: FileMetadataConnectionResolvers<ContextType>;
  FileMetadataEdge?: FileMetadataEdgeResolvers<ContextType>;
  FileMetadatum?: FileMetadatumResolvers<ContextType>;
  FileOutputGroup?: FileOutputGroupResolvers<ContextType>;
  FileOutputGroupMetadataConnection?: FileOutputGroupMetadataConnectionResolvers<ContextType>;
  FileOutputGroupMetadataEdge?: FileOutputGroupMetadataEdgeResolvers<ContextType>;
  FileOutputGroupMetadatum?: FileOutputGroupMetadatumResolvers<ContextType>;
  FileOutputGroupsConnection?: FileOutputGroupsConnectionResolvers<ContextType>;
  FileOutputGroupsEdge?: FileOutputGroupsEdgeResolvers<ContextType>;
  FileProcessingNotification?: FileProcessingNotificationResolvers<ContextType>;
  FileProcessingNotificationsConnection?: FileProcessingNotificationsConnectionResolvers<ContextType>;
  FileProcessingNotificationsEdge?: FileProcessingNotificationsEdgeResolvers<ContextType>;
  FileRecordingGroup?: FileRecordingGroupResolvers<ContextType>;
  FileRecordingGroupMetadataConnection?: FileRecordingGroupMetadataConnectionResolvers<ContextType>;
  FileRecordingGroupMetadataEdge?: FileRecordingGroupMetadataEdgeResolvers<ContextType>;
  FileRecordingGroupMetadatum?: FileRecordingGroupMetadatumResolvers<ContextType>;
  FileRecordingGroupsConnection?: FileRecordingGroupsConnectionResolvers<ContextType>;
  FileRecordingGroupsEdge?: FileRecordingGroupsEdgeResolvers<ContextType>;
  FilesConnection?: FilesConnectionResolvers<ContextType>;
  FilesEdge?: FilesEdgeResolvers<ContextType>;
  Interval?: IntervalResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  MetadataConnection?: MetadataConnectionResolvers<ContextType>;
  MetadataEdge?: MetadataEdgeResolvers<ContextType>;
  MetadataValue?: MetadataValueResolvers<ContextType>;
  MetadataValuesConnection?: MetadataValuesConnectionResolvers<ContextType>;
  MetadataValuesEdge?: MetadataValuesEdgeResolvers<ContextType>;
  Metadatum?: MetadatumResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  NotificationsConnection?: NotificationsConnectionResolvers<ContextType>;
  NotificationsEdge?: NotificationsEdgeResolvers<ContextType>;
  OutputGroupFile?: OutputGroupFileResolvers<ContextType>;
  OutputGroupFilesConnection?: OutputGroupFilesConnectionResolvers<ContextType>;
  OutputGroupFilesEdge?: OutputGroupFilesEdgeResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  Project?: ProjectResolvers<ContextType>;
  ProjectClonedNotification?: ProjectClonedNotificationResolvers<ContextType>;
  ProjectClonedNotificationsConnection?: ProjectClonedNotificationsConnectionResolvers<ContextType>;
  ProjectClonedNotificationsEdge?: ProjectClonedNotificationsEdgeResolvers<ContextType>;
  ProjectSharedNotification?: ProjectSharedNotificationResolvers<ContextType>;
  ProjectSharedNotificationsConnection?: ProjectSharedNotificationsConnectionResolvers<ContextType>;
  ProjectSharedNotificationsEdge?: ProjectSharedNotificationsEdgeResolvers<ContextType>;
  ProjectsConnection?: ProjectsConnectionResolvers<ContextType>;
  ProjectsEdge?: ProjectsEdgeResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RecordingGroupFile?: RecordingGroupFileResolvers<ContextType>;
  RecordingGroupFilesConnection?: RecordingGroupFilesConnectionResolvers<ContextType>;
  RecordingGroupFilesEdge?: RecordingGroupFilesEdgeResolvers<ContextType>;
  RoleChangeNotification?: RoleChangeNotificationResolvers<ContextType>;
  RoleChangeNotificationsConnection?: RoleChangeNotificationsConnectionResolvers<ContextType>;
  RoleChangeNotificationsEdge?: RoleChangeNotificationsEdgeResolvers<ContextType>;
  Run?: RunResolvers<ContextType>;
  RunsConnection?: RunsConnectionResolvers<ContextType>;
  RunsEdge?: RunsEdgeResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  Tag?: TagResolvers<ContextType>;
  TagsConnection?: TagsConnectionResolvers<ContextType>;
  TagsEdge?: TagsEdgeResolvers<ContextType>;
  Task?: TaskResolvers<ContextType>;
  TaskActivitiesConnection?: TaskActivitiesConnectionResolvers<ContextType>;
  TaskActivitiesEdge?: TaskActivitiesEdgeResolvers<ContextType>;
  TaskActivity?: TaskActivityResolvers<ContextType>;
  TaskResource?: TaskResourceResolvers<ContextType>;
  TaskResourcesConnection?: TaskResourcesConnectionResolvers<ContextType>;
  TaskResourcesEdge?: TaskResourcesEdgeResolvers<ContextType>;
  TaskSource?: TaskSourceResolvers<ContextType>;
  TaskSourcesConnection?: TaskSourcesConnectionResolvers<ContextType>;
  TaskSourcesEdge?: TaskSourcesEdgeResolvers<ContextType>;
  TaskStatusLog?: TaskStatusLogResolvers<ContextType>;
  TaskStatusLogsConnection?: TaskStatusLogsConnectionResolvers<ContextType>;
  TaskStatusLogsEdge?: TaskStatusLogsEdgeResolvers<ContextType>;
  TasksConnection?: TasksConnectionResolvers<ContextType>;
  TasksEdge?: TasksEdgeResolvers<ContextType>;
  Tenant?: TenantResolvers<ContextType>;
  TenantContract?: TenantContractResolvers<ContextType>;
  TenantContractAllowance?: TenantContractAllowanceResolvers<ContextType>;
  TenantContractAllowancesConnection?: TenantContractAllowancesConnectionResolvers<ContextType>;
  TenantContractAllowancesEdge?: TenantContractAllowancesEdgeResolvers<ContextType>;
  TenantContractTransaction?: TenantContractTransactionResolvers<ContextType>;
  TenantContractTransactionsConnection?: TenantContractTransactionsConnectionResolvers<ContextType>;
  TenantContractTransactionsEdge?: TenantContractTransactionsEdgeResolvers<ContextType>;
  TenantContractsConnection?: TenantContractsConnectionResolvers<ContextType>;
  TenantContractsEdge?: TenantContractsEdgeResolvers<ContextType>;
  TenantMockuserexecutor?: TenantMockuserexecutorResolvers<ContextType>;
  TenantMockuserexecutorsConnection?: TenantMockuserexecutorsConnectionResolvers<ContextType>;
  TenantMockuserexecutorsEdge?: TenantMockuserexecutorsEdgeResolvers<ContextType>;
  TenantMonthlyConsumedResource?: TenantMonthlyConsumedResourceResolvers<ContextType>;
  TenantMonthlyConsumedResourcesConnection?: TenantMonthlyConsumedResourcesConnectionResolvers<ContextType>;
  TenantMonthlyConsumedResourcesEdge?: TenantMonthlyConsumedResourcesEdgeResolvers<ContextType>;
  TenantUserMembershipNotification?: TenantUserMembershipNotificationResolvers<ContextType>;
  TenantUserMembershipNotificationsConnection?: TenantUserMembershipNotificationsConnectionResolvers<ContextType>;
  TenantUserMembershipNotificationsEdge?: TenantUserMembershipNotificationsEdgeResolvers<ContextType>;
  TenantUserexecutor?: TenantUserexecutorResolvers<ContextType>;
  TenantUserexecutorsConnection?: TenantUserexecutorsConnectionResolvers<ContextType>;
  TenantUserexecutorsEdge?: TenantUserexecutorsEdgeResolvers<ContextType>;
  TenantsEdge?: TenantsEdgeResolvers<ContextType>;
  TesContainerimageexecutor?: TesContainerimageexecutorResolvers<ContextType>;
  TesContainerimageexecutorsConnection?: TesContainerimageexecutorsConnectionResolvers<ContextType>;
  TesContainerimageexecutorsEdge?: TesContainerimageexecutorsEdgeResolvers<ContextType>;
  TesMockcontainerimageexecutor?: TesMockcontainerimageexecutorResolvers<ContextType>;
  TesMockcontainerimageexecutorsConnection?: TesMockcontainerimageexecutorsConnectionResolvers<ContextType>;
  TesMockcontainerimageexecutorsEdge?: TesMockcontainerimageexecutorsEdgeResolvers<ContextType>;
  TesMocktaskexecutor?: TesMocktaskexecutorResolvers<ContextType>;
  TesMocktaskexecutorsConnection?: TesMocktaskexecutorsConnectionResolvers<ContextType>;
  TesMocktaskexecutorsEdge?: TesMocktaskexecutorsEdgeResolvers<ContextType>;
  TesTaskexecutor?: TesTaskexecutorResolvers<ContextType>;
  TesTaskexecutorsConnection?: TesTaskexecutorsConnectionResolvers<ContextType>;
  TesTaskexecutorsEdge?: TesTaskexecutorsEdgeResolvers<ContextType>;
  Tool?: ToolResolvers<ContextType>;
  ToolCategoriesConnection?: ToolCategoriesConnectionResolvers<ContextType>;
  ToolCategoriesEdge?: ToolCategoriesEdgeResolvers<ContextType>;
  ToolCategory?: ToolCategoryResolvers<ContextType>;
  ToolDisplayCategoriesConnection?: ToolDisplayCategoriesConnectionResolvers<ContextType>;
  ToolDisplayCategoriesEdge?: ToolDisplayCategoriesEdgeResolvers<ContextType>;
  ToolDisplayCategory?: ToolDisplayCategoryResolvers<ContextType>;
  ToolParameter?: ToolParameterResolvers<ContextType>;
  ToolParametersConnection?: ToolParametersConnectionResolvers<ContextType>;
  ToolParametersEdge?: ToolParametersEdgeResolvers<ContextType>;
  ToolResource?: ToolResourceResolvers<ContextType>;
  ToolResourcesConnection?: ToolResourcesConnectionResolvers<ContextType>;
  ToolResourcesEdge?: ToolResourcesEdgeResolvers<ContextType>;
  ToolResult?: ToolResultResolvers<ContextType>;
  ToolResultsConnection?: ToolResultsConnectionResolvers<ContextType>;
  ToolResultsEdge?: ToolResultsEdgeResolvers<ContextType>;
  ToolSecret?: ToolSecretResolvers<ContextType>;
  ToolSecretsConnection?: ToolSecretsConnectionResolvers<ContextType>;
  ToolSecretsEdge?: ToolSecretsEdgeResolvers<ContextType>;
  ToolSource?: ToolSourceResolvers<ContextType>;
  ToolSourcesConnection?: ToolSourcesConnectionResolvers<ContextType>;
  ToolSourcesEdge?: ToolSourcesEdgeResolvers<ContextType>;
  ToolTenant?: ToolTenantResolvers<ContextType>;
  ToolTenantsConnection?: ToolTenantsConnectionResolvers<ContextType>;
  ToolTenantsEdge?: ToolTenantsEdgeResolvers<ContextType>;
  ToolVersion?: ToolVersionResolvers<ContextType>;
  ToolVersionsConnection?: ToolVersionsConnectionResolvers<ContextType>;
  ToolVersionsEdge?: ToolVersionsEdgeResolvers<ContextType>;
  Toolbox?: ToolboxResolvers<ContextType>;
  ToolboxExitcode?: ToolboxExitcodeResolvers<ContextType>;
  ToolboxExitcodesConnection?: ToolboxExitcodesConnectionResolvers<ContextType>;
  ToolboxExitcodesEdge?: ToolboxExitcodesEdgeResolvers<ContextType>;
  ToolboxTenant?: ToolboxTenantResolvers<ContextType>;
  ToolboxTenantsConnection?: ToolboxTenantsConnectionResolvers<ContextType>;
  ToolboxTenantsEdge?: ToolboxTenantsEdgeResolvers<ContextType>;
  ToolboxVersion?: ToolboxVersionResolvers<ContextType>;
  ToolboxVersionExitcode?: ToolboxVersionExitcodeResolvers<ContextType>;
  ToolboxVersionExitcodesConnection?: ToolboxVersionExitcodesConnectionResolvers<ContextType>;
  ToolboxVersionExitcodesEdge?: ToolboxVersionExitcodesEdgeResolvers<ContextType>;
  ToolboxVersionToolVersion?: ToolboxVersionToolVersionResolvers<ContextType>;
  ToolboxVersionToolVersionsConnection?: ToolboxVersionToolVersionsConnectionResolvers<ContextType>;
  ToolboxVersionToolVersionsEdge?: ToolboxVersionToolVersionsEdgeResolvers<ContextType>;
  ToolboxVersionsConnection?: ToolboxVersionsConnectionResolvers<ContextType>;
  ToolboxVersionsEdge?: ToolboxVersionsEdgeResolvers<ContextType>;
  ToolboxesConnection?: ToolboxesConnectionResolvers<ContextType>;
  ToolboxesEdge?: ToolboxesEdgeResolvers<ContextType>;
  ToolsConnection?: ToolsConnectionResolvers<ContextType>;
  ToolsEdge?: ToolsEdgeResolvers<ContextType>;
  UUID?: GraphQLScalarType;
  UpdateAnalysisTableGroupPayload?: UpdateAnalysisTableGroupPayloadResolvers<ContextType>;
  UpdateAnalysisTablePayload?: UpdateAnalysisTablePayloadResolvers<ContextType>;
  UpdateAnalysisTableRowAttachResultPayload?: UpdateAnalysisTableRowAttachResultPayloadResolvers<ContextType>;
  UpdateAnalysisTableRowPayload?: UpdateAnalysisTableRowPayloadResolvers<ContextType>;
  UpdateApplicationUserGroupPayload?: UpdateApplicationUserGroupPayloadResolvers<ContextType>;
  UpdateApplicationUserMembershipPayload?: UpdateApplicationUserMembershipPayloadResolvers<ContextType>;
  UpdateApplicationUserMetadatumPayload?: UpdateApplicationUserMetadatumPayloadResolvers<ContextType>;
  UpdateApplicationUserPayload?: UpdateApplicationUserPayloadResolvers<ContextType>;
  UpdateApplicationUserSessionPayload?: UpdateApplicationUserSessionPayloadResolvers<ContextType>;
  UpdateApplicationUserUserPermissionPayload?: UpdateApplicationUserUserPermissionPayloadResolvers<ContextType>;
  UpdateAuthGroupPayload?: UpdateAuthGroupPayloadResolvers<ContextType>;
  UpdateAuthGroupPermissionPayload?: UpdateAuthGroupPermissionPayloadResolvers<ContextType>;
  UpdateAuthPermissionPayload?: UpdateAuthPermissionPayloadResolvers<ContextType>;
  UpdateAvatarPayload?: UpdateAvatarPayloadResolvers<ContextType>;
  UpdateAwsBatchTaskPayload?: UpdateAwsBatchTaskPayloadResolvers<ContextType>;
  UpdateAwsCognitoUserPayload?: UpdateAwsCognitoUserPayloadResolvers<ContextType>;
  UpdateAwsEcrImagePayload?: UpdateAwsEcrImagePayloadResolvers<ContextType>;
  UpdateAwsMockBatchTaskPayload?: UpdateAwsMockBatchTaskPayloadResolvers<ContextType>;
  UpdateAwsMockEcrImagePayload?: UpdateAwsMockEcrImagePayloadResolvers<ContextType>;
  UpdateAwsMockS3FilePayload?: UpdateAwsMockS3FilePayloadResolvers<ContextType>;
  UpdateAwsS3FilePayload?: UpdateAwsS3FilePayloadResolvers<ContextType>;
  UpdateContainerImagePayload?: UpdateContainerImagePayloadResolvers<ContextType>;
  UpdateCostPayload?: UpdateCostPayloadResolvers<ContextType>;
  UpdateDatasetMetadatumPayload?: UpdateDatasetMetadatumPayloadResolvers<ContextType>;
  UpdateDatasetPayload?: UpdateDatasetPayloadResolvers<ContextType>;
  UpdateDatasetRecordingsTableColumnOrderPayload?: UpdateDatasetRecordingsTableColumnOrderPayloadResolvers<ContextType>;
  UpdateDatasetRecordingsTableColumnPayload?: UpdateDatasetRecordingsTableColumnPayloadResolvers<ContextType>;
  UpdateDatasetRecordingsTablePayload?: UpdateDatasetRecordingsTablePayloadResolvers<ContextType>;
  UpdateDatasetVersionPayload?: UpdateDatasetVersionPayloadResolvers<ContextType>;
  UpdateDjangoAdminLogPayload?: UpdateDjangoAdminLogPayloadResolvers<ContextType>;
  UpdateDjangoContentTypePayload?: UpdateDjangoContentTypePayloadResolvers<ContextType>;
  UpdateDjangoMigrationPayload?: UpdateDjangoMigrationPayloadResolvers<ContextType>;
  UpdateDjangoSesSesstatPayload?: UpdateDjangoSesSesstatPayloadResolvers<ContextType>;
  UpdateDjangoSessionPayload?: UpdateDjangoSessionPayloadResolvers<ContextType>;
  UpdateDrsFileexecutorPayload?: UpdateDrsFileexecutorPayloadResolvers<ContextType>;
  UpdateDrsMockfileexecutorPayload?: UpdateDrsMockfileexecutorPayloadResolvers<ContextType>;
  UpdateEulaVersionPayload?: UpdateEulaVersionPayloadResolvers<ContextType>;
  UpdateEulaVersionUserAcceptancePayload?: UpdateEulaVersionUserAcceptancePayloadResolvers<ContextType>;
  UpdateExplorerQueryPayload?: UpdateExplorerQueryPayloadResolvers<ContextType>;
  UpdateExplorerQueryfavoritePayload?: UpdateExplorerQueryfavoritePayloadResolvers<ContextType>;
  UpdateExplorerQuerylogPayload?: UpdateExplorerQuerylogPayloadResolvers<ContextType>;
  UpdateFileActivityPayload?: UpdateFileActivityPayloadResolvers<ContextType>;
  UpdateFileMetadatumPayload?: UpdateFileMetadatumPayloadResolvers<ContextType>;
  UpdateFileOutputGroupMetadatumPayload?: UpdateFileOutputGroupMetadatumPayloadResolvers<ContextType>;
  UpdateFileOutputGroupPayload?: UpdateFileOutputGroupPayloadResolvers<ContextType>;
  UpdateFilePayload?: UpdateFilePayloadResolvers<ContextType>;
  UpdateFileRecordingGroupMetadatumPayload?: UpdateFileRecordingGroupMetadatumPayloadResolvers<ContextType>;
  UpdateFileRecordingGroupPayload?: UpdateFileRecordingGroupPayloadResolvers<ContextType>;
  UpdateMetadataValuePayload?: UpdateMetadataValuePayloadResolvers<ContextType>;
  UpdateMetadatumPayload?: UpdateMetadatumPayloadResolvers<ContextType>;
  UpdateNotificationPayload?: UpdateNotificationPayloadResolvers<ContextType>;
  UpdateOutputGroupFilePayload?: UpdateOutputGroupFilePayloadResolvers<ContextType>;
  UpdateRecordingGroupFilePayload?: UpdateRecordingGroupFilePayloadResolvers<ContextType>;
  UpdateRunPayload?: UpdateRunPayloadResolvers<ContextType>;
  UpdateTagPayload?: UpdateTagPayloadResolvers<ContextType>;
  UpdateTaskActivityPayload?: UpdateTaskActivityPayloadResolvers<ContextType>;
  UpdateTaskPayload?: UpdateTaskPayloadResolvers<ContextType>;
  UpdateTaskResourcePayload?: UpdateTaskResourcePayloadResolvers<ContextType>;
  UpdateTaskSourcePayload?: UpdateTaskSourcePayloadResolvers<ContextType>;
  UpdateTaskStatusLogPayload?: UpdateTaskStatusLogPayloadResolvers<ContextType>;
  UpdateTenantContractAllowancePayload?: UpdateTenantContractAllowancePayloadResolvers<ContextType>;
  UpdateTenantContractPayload?: UpdateTenantContractPayloadResolvers<ContextType>;
  UpdateTenantContractTransactionPayload?: UpdateTenantContractTransactionPayloadResolvers<ContextType>;
  UpdateTenantMockuserexecutorPayload?: UpdateTenantMockuserexecutorPayloadResolvers<ContextType>;
  UpdateTenantMonthlyConsumedResourcePayload?: UpdateTenantMonthlyConsumedResourcePayloadResolvers<ContextType>;
  UpdateTenantPayload?: UpdateTenantPayloadResolvers<ContextType>;
  UpdateTenantUserexecutorPayload?: UpdateTenantUserexecutorPayloadResolvers<ContextType>;
  UpdateTesContainerimageexecutorPayload?: UpdateTesContainerimageexecutorPayloadResolvers<ContextType>;
  UpdateTesMockcontainerimageexecutorPayload?: UpdateTesMockcontainerimageexecutorPayloadResolvers<ContextType>;
  UpdateTesMocktaskexecutorPayload?: UpdateTesMocktaskexecutorPayloadResolvers<ContextType>;
  UpdateTesTaskexecutorPayload?: UpdateTesTaskexecutorPayloadResolvers<ContextType>;
  UpdateToolCategoryPayload?: UpdateToolCategoryPayloadResolvers<ContextType>;
  UpdateToolDisplayCategoryPayload?: UpdateToolDisplayCategoryPayloadResolvers<ContextType>;
  UpdateToolParameterPayload?: UpdateToolParameterPayloadResolvers<ContextType>;
  UpdateToolPayload?: UpdateToolPayloadResolvers<ContextType>;
  UpdateToolResourcePayload?: UpdateToolResourcePayloadResolvers<ContextType>;
  UpdateToolResultPayload?: UpdateToolResultPayloadResolvers<ContextType>;
  UpdateToolSecretPayload?: UpdateToolSecretPayloadResolvers<ContextType>;
  UpdateToolSourcePayload?: UpdateToolSourcePayloadResolvers<ContextType>;
  UpdateToolTenantPayload?: UpdateToolTenantPayloadResolvers<ContextType>;
  UpdateToolVersionPayload?: UpdateToolVersionPayloadResolvers<ContextType>;
  UpdateToolboxExitcodePayload?: UpdateToolboxExitcodePayloadResolvers<ContextType>;
  UpdateToolboxPayload?: UpdateToolboxPayloadResolvers<ContextType>;
  UpdateToolboxTenantPayload?: UpdateToolboxTenantPayloadResolvers<ContextType>;
  UpdateToolboxVersionExitcodePayload?: UpdateToolboxVersionExitcodePayloadResolvers<ContextType>;
  UpdateToolboxVersionPayload?: UpdateToolboxVersionPayloadResolvers<ContextType>;
  UpdateToolboxVersionToolVersionPayload?: UpdateToolboxVersionToolVersionPayloadResolvers<ContextType>;
  UpdateWorkflowDatumPayload?: UpdateWorkflowDatumPayloadResolvers<ContextType>;
  UpdateWorkflowPayload?: UpdateWorkflowPayloadResolvers<ContextType>;
  UpdateWorkflowResultPayload?: UpdateWorkflowResultPayloadResolvers<ContextType>;
  UpdateWorkflowResultTagPayload?: UpdateWorkflowResultTagPayloadResolvers<ContextType>;
  UpdateWorkflowResultWorkflowVersionPayload?: UpdateWorkflowResultWorkflowVersionPayloadResolvers<ContextType>;
  UpdateWorkflowSectionBlockPayload?: UpdateWorkflowSectionBlockPayloadResolvers<ContextType>;
  UpdateWorkflowSectionBlockSectionPayload?: UpdateWorkflowSectionBlockSectionPayloadResolvers<ContextType>;
  UpdateWorkflowSectionPayload?: UpdateWorkflowSectionPayloadResolvers<ContextType>;
  UpdateWorkflowSectionWorkflowVersionPayload?: UpdateWorkflowSectionWorkflowVersionPayloadResolvers<ContextType>;
  UpdateWorkflowSourcePayload?: UpdateWorkflowSourcePayloadResolvers<ContextType>;
  UpdateWorkflowSourceTagPayload?: UpdateWorkflowSourceTagPayloadResolvers<ContextType>;
  UpdateWorkflowSourceWorkflowVersionPayload?: UpdateWorkflowSourceWorkflowVersionPayloadResolvers<ContextType>;
  UpdateWorkflowVersionPayload?: UpdateWorkflowVersionPayloadResolvers<ContextType>;
  UserAccessToProject?: UserAccessToProjectResolvers<ContextType>;
  UserAccessToProjectsConnection?: UserAccessToProjectsConnectionResolvers<ContextType>;
  UserAccessToProjectsEdge?: UserAccessToProjectsEdgeResolvers<ContextType>;
  Workflow?: WorkflowResolvers<ContextType>;
  WorkflowDataConnection?: WorkflowDataConnectionResolvers<ContextType>;
  WorkflowDataEdge?: WorkflowDataEdgeResolvers<ContextType>;
  WorkflowDatum?: WorkflowDatumResolvers<ContextType>;
  WorkflowResult?: WorkflowResultResolvers<ContextType>;
  WorkflowResultTag?: WorkflowResultTagResolvers<ContextType>;
  WorkflowResultTagsConnection?: WorkflowResultTagsConnectionResolvers<ContextType>;
  WorkflowResultTagsEdge?: WorkflowResultTagsEdgeResolvers<ContextType>;
  WorkflowResultWorkflowVersion?: WorkflowResultWorkflowVersionResolvers<ContextType>;
  WorkflowResultWorkflowVersionsConnection?: WorkflowResultWorkflowVersionsConnectionResolvers<ContextType>;
  WorkflowResultWorkflowVersionsEdge?: WorkflowResultWorkflowVersionsEdgeResolvers<ContextType>;
  WorkflowResultsConnection?: WorkflowResultsConnectionResolvers<ContextType>;
  WorkflowResultsEdge?: WorkflowResultsEdgeResolvers<ContextType>;
  WorkflowSection?: WorkflowSectionResolvers<ContextType>;
  WorkflowSectionBlock?: WorkflowSectionBlockResolvers<ContextType>;
  WorkflowSectionBlockSection?: WorkflowSectionBlockSectionResolvers<ContextType>;
  WorkflowSectionBlockSectionsConnection?: WorkflowSectionBlockSectionsConnectionResolvers<ContextType>;
  WorkflowSectionBlockSectionsEdge?: WorkflowSectionBlockSectionsEdgeResolvers<ContextType>;
  WorkflowSectionBlocksConnection?: WorkflowSectionBlocksConnectionResolvers<ContextType>;
  WorkflowSectionBlocksEdge?: WorkflowSectionBlocksEdgeResolvers<ContextType>;
  WorkflowSectionWorkflowVersion?: WorkflowSectionWorkflowVersionResolvers<ContextType>;
  WorkflowSectionWorkflowVersionsConnection?: WorkflowSectionWorkflowVersionsConnectionResolvers<ContextType>;
  WorkflowSectionWorkflowVersionsEdge?: WorkflowSectionWorkflowVersionsEdgeResolvers<ContextType>;
  WorkflowSectionsConnection?: WorkflowSectionsConnectionResolvers<ContextType>;
  WorkflowSectionsEdge?: WorkflowSectionsEdgeResolvers<ContextType>;
  WorkflowSource?: WorkflowSourceResolvers<ContextType>;
  WorkflowSourceTag?: WorkflowSourceTagResolvers<ContextType>;
  WorkflowSourceTagsConnection?: WorkflowSourceTagsConnectionResolvers<ContextType>;
  WorkflowSourceTagsEdge?: WorkflowSourceTagsEdgeResolvers<ContextType>;
  WorkflowSourceWorkflowVersion?: WorkflowSourceWorkflowVersionResolvers<ContextType>;
  WorkflowSourceWorkflowVersionsConnection?: WorkflowSourceWorkflowVersionsConnectionResolvers<ContextType>;
  WorkflowSourceWorkflowVersionsEdge?: WorkflowSourceWorkflowVersionsEdgeResolvers<ContextType>;
  WorkflowSourcesConnection?: WorkflowSourcesConnectionResolvers<ContextType>;
  WorkflowSourcesEdge?: WorkflowSourcesEdgeResolvers<ContextType>;
  WorkflowVersion?: WorkflowVersionResolvers<ContextType>;
  WorkflowVersionsConnection?: WorkflowVersionsConnectionResolvers<ContextType>;
  WorkflowVersionsEdge?: WorkflowVersionsEdgeResolvers<ContextType>;
  WorkflowsConnection?: WorkflowsConnectionResolvers<ContextType>;
  WorkflowsEdge?: WorkflowsEdgeResolvers<ContextType>;
};
