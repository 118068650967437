import { EuiButtonEmpty } from "@inscopix/ideas-eui";
import { ModalDeleteColumn } from "pages/gdt/modals/ModelDeleteColumn";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";

interface ColumnHeaderActionRemoveColumnProps {
  columnId: string;
  onClick: () => void;
  isDisabled?: boolean;
}

export const ColumnHeaderActionRemoveColumn = ({
  columnId,
  onClick,
  isDisabled = false,
}: ColumnHeaderActionRemoveColumnProps) => {
  const setModal = useDataTableContext((s) => s.setModal);

  return (
    <EuiButtonEmpty
      aria-label="Remove column"
      onClick={() => {
        setModal(<ModalDeleteColumn columnId={columnId} />);
        onClick();
      }}
      iconType="trash"
      color="text"
      size="s"
      disabled={isDisabled}
    >
      Remove column
    </EuiButtonEmpty>
  );
};
