import { FileBadge } from "components/FileBadge/FileBadge";
import { FileSource } from "graphql/_Types";
import { IdeasFile } from "@inscopix/ideas-hyperformula";
import { useDataTableContext } from "../store/DataTableProvider";
import { useCallback } from "react";
import { ProcessingStatus } from "types/constants";

interface CellValueRendererFileProps {
  tableId: string;
  columnId: string;
  rowId: string;
  file: IdeasFile;
  isCompact?: boolean;
}

/** Component that renders an `IdeasFile` cell value` */
export const CellValueRendererFile = ({
  tableId,
  columnId,
  rowId,
  file,
  isCompact = false,
}: CellValueRendererFileProps) => {
  const deleteFile = useDataTableContext((s) => s.deleteFile);
  const setSelectedFileId = useDataTableContext((s) => s.setSelectedFileId);
  const refetchFile = useDataTableContext((s) => s.refetchFile);
  const isSelected = useDataTableContext(
    (s) => s.selectedFileId === file.attrs.id,
  );
  const table = useDataTableContext((s) =>
    s.tables.find(({ id }) => id === tableId),
  );

  const handleProcessingComplete = useCallback(() => {
    const isProcessed = [
      ProcessingStatus.COMPLETE,
      ProcessingStatus.FAILED,
      ProcessingStatus.SKIPPED,
    ].includes(file.attrs.processingStatus);

    if (!isProcessed) {
      void refetchFile({
        fileId: file.attrs.id,
      });
    }
  }, [file.attrs.id, file.attrs.processingStatus, refetchFile]);

  return (
    <FileBadge
      drsFile={{
        id: file.attrs.id,
        name: file.attrs.name,
        status: file.attrs.status,
        fileType: file.attrs.fileType,
        isSeries: file.attrs.isSeries,
        source: FileSource.Uploaded,
        processingStatus: file.attrs.processingStatus,
        seriesParentId: file.attrs.seriesParentId,
      }}
      isSelected={isSelected}
      onClick={() => setSelectedFileId(file.attrs.id)}
      onProcessingComplete={handleProcessingComplete}
      compact={isCompact}
      onDelete={
        table?.kind === "data"
          ? () => {
              void deleteFile({
                tableId,
                columnId,
                rowId,
                fileId: file.attrs.id,
              });
            }
          : undefined
      }
    />
  );
};
