import { isDefined } from "utils/isDefined";
import { useDataTableContext } from "../store/DataTableProvider";
import { FlyoutDrsFileInfo } from "components/FlyoutDrsFileInfo/FlyoutDrsFileInfo";

export const DataTableFlyout = () => {
  const setSelectedFile = useDataTableContext((s) => s.setSelectedFileId);
  const selectedFile = useDataTableContext((s) => {
    const { selectedFileId, fileMap } = s;
    return isDefined(selectedFileId) ? fileMap[selectedFileId] : undefined;
  });

  if (selectedFile === undefined || selectedFile instanceof Error) {
    return null;
  }

  return (
    <FlyoutDrsFileInfo
      drsFile={{ id: selectedFile.id, name: selectedFile.name }}
      onClose={() => setSelectedFile(undefined)}
    />
  );
};
