/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiLoadingSpinner,
  EuiStat,
  EuiText,
  EuiToolTip,
  useEuiTheme,
} from "@inscopix/ideas-eui";
import ProjectIcon from "components/ProjectIcon/ProjectIcon";
import { ProjectsCardActions } from "components/ProjectsCards/ProjectsCardActions";
import { Project, Tenant } from "graphql/_Types";
import { ProjectStatus } from "types/constants";
import { filesize } from "utils/filesize";
import { UserAccessLevel } from "types/UserAccessLevels";
import { getUserRole } from "utils/permissions";
import { RoleBadge } from "components/RoleBadge/RoleBadge";
import { useFetchAndSetProjectAccess } from "hooks/useFetchAndSetProjectAccess";
import assert from "assert";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { find, isUndefined } from "lodash";
import { TRegion } from "providers/RegionsProvider";
import { useRoutes } from "hooks/useRoutes";
import { RegionBadge } from "components/RegionBadge/RegionBadge";
import { isDefined } from "utils/isDefined";

const getStyles = (euiTheme: ReturnType<typeof useEuiTheme>["euiTheme"]) => ({
  card: css`
    border: 1px solid ${euiTheme.colors.lightShade};
    border-radius: 5px;
    padding: ${euiTheme.size.m};
  `,
  name: css`
    font-size: ${euiTheme.size.l};
  `,
  linkText: css`
    word-break: break-word;
  `,
});

export interface ProjectCardProps {
  project: Pick<
    Project,
    | "id"
    | "name"
    | "key"
    | "icon"
    | "iconColor"
    | "iconText"
    | "shortDescription"
    | "userId"
    | "activeStorageSize"
    | "archivedStorageSize"
    | "defaultUserAccessLevel"
    | "status"
  > & {
    tenantKey?: Tenant["key"];
    tenantName?: Tenant["name"];
    tenantId?: Tenant["id"];
    region?: TRegion;
  };
}

export const ProjectCard = ({ project }: ProjectCardProps) => {
  const { euiTheme } = useEuiTheme();
  const { routeMap } = useRoutes();
  const styles = getStyles(euiTheme);
  const currentUser = useUserContext((s) => s.currentUser);
  const currentUserTenants = useUserContext((s) => s.tenants);

  const {
    id,
    name,
    shortDescription,
    userId,
    activeStorageSize,
    archivedStorageSize,
    defaultUserAccessLevel,
    tenantKey,
    tenantName,
  } = project;

  /* Get the user role*/
  const { permissions } = useFetchAndSetProjectAccess();

  assert(tenantKey, `Tenant key is not found for the project ${name}`);

  const currentUserPermission = {
    ...permissions?.find(
      (permission) =>
        permission.user === currentUser.id && permission.project === id,
    ),
    userId: currentUser.id,
  };

  const membership = find(currentUserTenants, { key: tenantKey });

  const userRole = getUserRole(
    currentUserPermission,
    defaultUserAccessLevel as UserAccessLevel["id"],
    userId, // Project owner's id
    membership?.role,
  );
  /* End get the user role*/

  const hasAdminControls = userRole === "admin" || userRole === "owner";

  const projectPath = routeMap["PROJECT"].dynamicPath({
    tenantKey: tenantKey,
    projectKey: project.key,
  }).path;

  const projectLink = () => {
    if (project.status === ProjectStatus.CLONING) {
      return (
        <EuiLink css={styles.name} disabled={true}>
          {name}
        </EuiLink>
      );
    }
    return isUndefined(project.region) ? (
      <EuiLink css={styles.name} to={projectPath}>
        {name}
      </EuiLink>
    ) : (
      <EuiLink
        css={styles.name}
        href={`${project.region.url}${projectPath}`}
        target="_blank"
      >
        {name}
      </EuiLink>
    );
  };

  return (
    <div css={styles.card}>
      <EuiFlexGroup direction="column">
        <EuiFlexGroup alignItems="center" responsive={false}>
          <EuiFlexItem grow={false}>
            <ProjectIcon size="l" project={project} tenantKey={tenantKey} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText css={styles.linkText}>{projectLink()}</EuiText>
          </EuiFlexItem>
          {project.status === ProjectStatus.CLONING && (
            <EuiToolTip
              content="This project is being cloned and will be available shortly"
              position="bottom"
            >
              <EuiBadge color="hollow" title="">
                <EuiFlexGroup gutterSize="xs" alignItems="center">
                  <EuiFlexItem grow={false}>
                    <EuiLoadingSpinner size="s" />
                  </EuiFlexItem>
                  <EuiFlexItem>CLONING</EuiFlexItem>
                </EuiFlexGroup>
              </EuiBadge>
            </EuiToolTip>
          )}

          {/* spacer on header */}
          <EuiFlexItem />

          <EuiFlexItem grow={false}>
            <EuiFlexGroup
              alignItems="center"
              responsive={false}
              gutterSize="xs"
            >
              <EuiFlexItem grow={false}>
                <RoleBadge role={userRole} />
              </EuiFlexItem>
              {isUndefined(project.region) && (
                <EuiFlexItem grow={false}>
                  <ProjectsCardActions
                    projectId={id}
                    readOnly={!hasAdminControls}
                    disabled={project.status === ProjectStatus.CLONING}
                  />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexItem>
          <EuiText>{shortDescription}</EuiText>
        </EuiFlexItem>
        <EuiFlexGroup responsive={false}>
          <EuiFlexItem>
            <EuiFlexGroup alignItems="flexStart" responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiStat
                  titleSize="xs"
                  title={filesize(activeStorageSize)}
                  description="Active storage"
                  reverse
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiStat
                  titleSize="xs"
                  title={filesize(archivedStorageSize)}
                  description="Archived storage"
                  reverse
                  titleColor="subdued"
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ paddingRight: 10 }}>
            <EuiText>
              {isUndefined(project.region) ? (
                <EuiLink
                  to={
                    routeMap["ORGANIZATION"].dynamicPath({
                      tenantKey: tenantKey,
                    }).path
                  }
                >
                  {tenantName}
                </EuiLink>
              ) : (
                <EuiLink
                  href={`${project.region.url}${
                    routeMap["ORGANIZATION"].dynamicPath({
                      tenantKey: tenantKey,
                    }).path
                  }`}
                  target="_blank"
                >
                  {tenantName}{" "}
                  {isDefined(project.region) && (
                    <RegionBadge regionKey={project.region.key} />
                  )}
                </EuiLink>
              )}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexGroup>
    </div>
  );
};
