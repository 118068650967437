import { EuiBadge, EuiSkeletonRectangle } from "@inscopix/ideas-eui";
import { FileRecordingGroup } from "graphql/_Types";
import { useEffect, useState } from "react";
import { getRecordingIdentifier } from "./RecordingIdentifierBadge.helpers";
import { captureException } from "@sentry/react";
import { Tooltip } from "components/Tooltip/Tooltip";

interface RecordingIdentifierBadgeProps {
  showShortIdOnly?: boolean;
  recordingId: FileRecordingGroup["id"];
}

/**
 * Component that displays a badge with the identifier of a given recording.
 * It handles loading states, errors and content display.
 */
export const RecordingIdentifierBadge = ({
  showShortIdOnly = false,
  recordingId,
}: RecordingIdentifierBadgeProps) => {
  const [shortId, setShortId] = useState<string>();
  const [cellValues, setCellValues] = useState<
    { columnName: string; cellValue: string }[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const fetchIdentifier = async () => {
      try {
        setIsLoading(true);
        const identifier = await getRecordingIdentifier(recordingId);
        setShortId(identifier.shortId);
        setCellValues(identifier.cellValues);
      } catch (error) {
        captureException(error);
        setError(error as Error);
      } finally {
        setIsLoading(false);
      }
    };
    void fetchIdentifier();
  }, [recordingId]);

  if (isLoading) {
    return (
      <EuiSkeletonRectangle
        borderRadius="s"
        isLoading
        // Height should match EuiBadge
        height={20}
        width={50}
      />
    );
  }

  if (error !== undefined) {
    return <EuiBadge color="danger">ERROR</EuiBadge>;
  }

  const tooltipParams = cellValues.map(({ columnName, cellValue }) => {
    return (
      <div key={columnName}>
        <strong>{columnName}</strong>: {cellValue}
      </div>
    );
  });

  const tooltipContent = [
    <div key={"id"}>
      <strong>Recording Session ID</strong>:{" "}
      <EuiBadge style={{ fontFamily: "monospace" }}>{shortId}</EuiBadge>
    </div>,
    ...tooltipParams,
  ];

  return (
    <div>
      <EuiBadge
        style={{ fontFamily: "monospace", marginRight: 5 }}
        title={undefined} // Remove default tooltip from the badge
      >
        {shortId}
      </EuiBadge>
      {!showShortIdOnly && cellValues.length > 0 && (
        <Tooltip content={tooltipContent}>
          <EuiBadge
            color="hollow"
            style={{ fontFamily: "monospace" }}
            title={undefined} // Remove default tooltip from the badge
          >
            {cellValues.map(({ cellValue }) => cellValue).join(" | ")}
          </EuiBadge>
        </Tooltip>
      )}
    </div>
  );
};
