import { EuiButtonIcon, EuiPageTemplate, EuiSpacer } from "@inscopix/ideas-eui";
import { PanelTenantUsage } from "components/PanelTenantUsage/PanelTenantUsage";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { Helmet } from "react-helmet-async";
import { PanelTenantAccess } from "components/PanelTenantAccess/PanelTenantAccess";
import { useState } from "react";
import { ModalEditOrganization } from "components/ModalEditOrganization/ModalEditOrganization";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { TENANT_MEMBERSHIP_ROLES_BY_KEY } from "types/TenantMembershipRole";
import { Tooltip } from "components/Tooltip/Tooltip";

export const PageOrganizationManage = () => {
  const currentUser = useUserContext((s) => s.currentUser);
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const currentUserRole = useTenantContext((s) => {
    const defaultRole = TENANT_MEMBERSHIP_ROLES_BY_KEY["member"];
    const user = s.usersById.get(currentUser.id);
    return user?.role ?? defaultRole;
  });

  return (
    <>
      {isModalVisible && (
        <ModalEditOrganization onClose={() => setIsModalVisible(false)} />
      )}
      <Helmet>
        <title>{currentTenant.name}: Manage</title>
      </Helmet>
      <EuiPageTemplate
        restrictWidth={false}
        style={{ maxWidth: 1000, margin: "auto" }}
      >
        <EuiPageTemplate.Header
          alignItems="center"
          pageTitle={currentTenant.name}
          rightSideItems={[
            currentUserRole.key === "admin" && (
              <Tooltip content={"Edit organization name"}>
                <EuiButtonIcon
                  key="edit"
                  aria-label="Edit organization name"
                  iconType="pencil"
                  onClick={() => setIsModalVisible(true)}
                />
              </Tooltip>
            ),
          ]}
        />
        <EuiPageTemplate.Section color={"transparent"}>
          <PanelTenantUsage />
          <EuiSpacer />
          <PanelTenantAccess />
        </EuiPageTemplate.Section>
      </EuiPageTemplate>
    </>
  );
};
