import {
  EuiFormRow,
  EuiSuperSelect,
  EuiSuperSelectOption,
} from "@inscopix/ideas-eui";
import { FILE_TYPES_BY_ID, FileType } from "types/FileTypes";
import { metadataMap, UNSELECTABLE_METADATA_KEYS } from "types/MetadataMap";
import { isDefined } from "utils/isDefined";

interface FieldFileMetadatumProps {
  autoFocus?: boolean;
  disabled?: boolean;
  fileType: FileType["id"] | undefined;
  value: string | undefined;
  onChange: (field: { value: string | undefined; isValid: boolean }) => void;
}

export const FieldFileMetadatum = ({
  autoFocus,
  disabled = false,
  fileType,
  value,
  onChange,
}: FieldFileMetadatumProps) => {
  const options: EuiSuperSelectOption<string>[] = isDefined(fileType)
    ? Object.entries(metadataMap[FILE_TYPES_BY_ID[fileType].key] ?? {})
        .map(([key, value]) => ({
          value: key,
          inputDisplay: value,
        }))
        // TODO: ID-2041 remove filter once DLC movies have their own type
        .filter((option) => !UNSELECTABLE_METADATA_KEYS.includes(option.value))
    : [];

  const handleChange = (newValue: string) => {
    if (newValue === value) {
      onChange({
        value: undefined,
        isValid: false,
      });
    } else {
      onChange({
        value: newValue,
        isValid: true,
      });
    }
  };

  return (
    <EuiFormRow
      label="Metadatum"
      helpText="Choose the metadatum value you wish to display."
    >
      <EuiSuperSelect
        disabled={disabled}
        options={options}
        valueOfSelected={value}
        onChange={handleChange}
        autoFocus={autoFocus}
        placeholder="Select a metadatum value..."
      />
    </EuiFormRow>
  );
};
