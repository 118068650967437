/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { CellValue } from "@inscopix/ideas-hyperformula";
import {
  EuiListGroup,
  EuiListGroupItem,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { CellValueRendererBase } from "../cell-value-renderers/CellValueRendererBase";
import { DataTableColumnDefinition } from "../store/DataTableProvider.types";

const styles = {
  popover: css`
    & {
      height: 100%;
      width: 100%;
      .PopoverAnchor {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      }
    }
  `,
  panel: css`
    max-height: max(200px, 50vh);
    overflow-y: scroll;
  `,
};

interface CellEditorChoiceProps {
  tableId: string;
  columnId: string;
  rowId: string;
  initialValue: CellValue;
  columnDefinition: Extract<DataTableColumnDefinition, { kind: "choice" }>;
  onChange: (newFormula: string | null) => void;
  stopEditing: () => void;
}

/** Cell editor for cells in choice columns */
export const CellEditorChoice = ({
  tableId,
  columnId,
  rowId,
  initialValue,
  columnDefinition,
  onChange,
  stopEditing,
}: CellEditorChoiceProps) => {
  const [newValue, setNewValue] = useState(initialValue);

  const items = columnDefinition.choices.map((choice) => (
    <EuiListGroupItem
      id={choice.value.toString()}
      key={choice.value}
      iconType={newValue === choice.value ? "check" : "empty"}
      label={choice.label}
      onClick={() => {
        if (newValue === choice.value) {
          onChange("");
          setNewValue(null);
        } else {
          onChange(choice.value.toString());
          setNewValue(choice.value);
        }
      }}
    />
  ));

  return (
    <EuiPopover
      anchorPosition="downCenter"
      button={
        <CellValueRendererBase
          tableId={tableId}
          columnId={columnId}
          rowId={rowId}
          value={newValue}
        />
      }
      className="Popover"
      anchorClassName="PopoverAnchor"
      isOpen
      panelPaddingSize="none"
      css={styles.popover}
      closePopover={stopEditing}
      display="block"
      repositionOnScroll={false}
    >
      <EuiListGroup css={styles.panel} maxWidth="100%">
        {items}
      </EuiListGroup>
    </EuiPopover>
  );
};
