/**
 * Definitions of application-wide constants (alphabetized)
 */

/** FIXME: manually copied from ideas-backend (can become stale) */
export enum DatasetStatus {
  AVAILABLE = 1,
  ARCHIVED = 2,
  SCHEDULE_DELETE = 3,
  SCHEDULE_DATA_DELETE = 4,
  DATA_DELETED = 5,
}

/** FIXME: manually copied from ideas-backend (can become stale) */
export enum FileStatus {
  CREATED = 1,
  UPLOADING = 2,
  AVAILABLE = 3,
  ARCHIVED = 4,
  SCHEDULE_DELETE = 5,
  UNARCHIVED = 6,
  SCHEDULE_DATA_DELETE = 7,
  DATA_DELETED = 8,
  UPLOAD_CANCELLED = 9,
}

/** FIXME: manually copied from ideas-backend (can become stale) */
export enum ProcessingStatus {
  UNPROCESSED = 1,
  PENDING = 2,
  PROCESSING = 3,
  COMPLETE = 4,
  FAILED = 5,
  SKIPPED = 6,
}

/** FIXME: manually copied from ideas-backend (can become stale) */
export enum FileUploadStatus {
  STARTED = 1,
  PAUSED = 2,
  COMPLETE = 3,
  ERROR = 4,
}

/** FIXME: manually copied from ideas-backend (can become stale) */
export enum ProjectStatus {
  AVAILABLE = 1,
  ARCHIVED = 2,
  SCHEDULE_DELETE = 3,
  SCHEDULE_DATA_DELETE = 4,
  DATA_DELETED = 5,
  // Transient statuses used for asynchronous deletion of projects in the expire script
  PENDING_SCHEDULE_DELETE = 6,
  PENDING_SCHEDULE_DATA_DELETE = 7,
  CLONING = 8,
}

/** FIXME: manually copied from ideas-backend (can become stale) */
export enum TaskStatus {
  NEW = 1,
  QUEUED = 2,
  INITIALIZING = 3,
  RUNNING = 4,
  PAUSED = 5,
  COMPLETE = 6,
  FAILED = 7,
  ERROR = 8,
  CANCELED = 9,
}

/** FIXME: manually copied from ideas-backend (can become stale) */
export enum RunStatus {
  NEW = 1,
  RUNNING = 2,
  FAILED = 3,
  COMPLETE = 4,
  CANCELED = 5,
}

/** FIXME: manually copied from ideas-backend (can become stale) */
export enum TaskResource {
  CPU = 1,
  GPU = 2,
  MEMORY = 3,
  STORAGE = 4,
  NETWORK = 5,
}

// https://github.com/inscopix/ideas-commons/blob/develop/ideas_commons/constants/tools.py
export enum ToolMaturity {
  DEPRECATED = 1,
  PRODUCTION = 2,
  TESTING = 3,
  DEVELOPMENT = 4,
}

// https://github.com/inscopix/ideas-backend/blob/0de7928f91892e33d92c6afae071856b9fa49f5a/web/library/models/base.py#L566
export enum ProjectSharingInviteStatus {
  OPEN = 1,
  ACCEPTED = 2,
  REVOKED = 3,
}

/** FIXME: manually copied from ideas-backend (can become stale) */
export enum InviteStatus {
  OPEN = 1,
  ACCEPTED = 2,
  REVOKED = 3,
}
