/** @jsxImportSource @emotion/react */

import {
  EuiContextMenuItem,
  EuiContextMenuItemIcon,
  EuiContextMenuPanel,
  EuiHorizontalRule,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { useMenuBarStyles } from "./useMenuBarStyles";

interface MenuBarMenuItem {
  type: "item";
  disabled?: boolean;
  icon: EuiContextMenuItemIcon;
  label: string;
  onClick: () => void;
  // TODO: Add back after project page is restored
  // requiredPermission: UserPermission;
}

interface MenuBarMenuSeparator {
  key: string;
  type: "separator";
}

interface MenuBarMenuCustomItem {
  type: "custom";
  content: React.ReactNode;
}

export interface MenuBarMenuProps {
  enableHoverToOpen: boolean;
  isOpen: boolean;
  items: (MenuBarMenuItem | MenuBarMenuSeparator | MenuBarMenuCustomItem)[];
  label: string;
  onClose: () => void;
  onOpen: () => void;
}

export const MenuBarMenu = ({
  enableHoverToOpen,
  isOpen,
  items,
  label,
  onClose,
  onOpen,
}: MenuBarMenuProps) => {
  const { menuBarMenuButtonStyle } = useMenuBarStyles();

  const handleMouseEnter = () => {
    if (enableHoverToOpen) {
      onOpen();
    }
  };

  const toggleOpen = () => (isOpen ? onClose() : onOpen());

  const handleClickItem = (item: MenuBarMenuItem) => {
    onClose();
    item.onClick();
  };

  return (
    <EuiPopover
      anchorPosition="downLeft"
      buffer={0}
      button={
        <button
          css={menuBarMenuButtonStyle(isOpen)}
          onClick={toggleOpen}
          onMouseEnter={handleMouseEnter}
        >
          {label}
        </button>
      }
      closePopover={onClose}
      hasArrow={false}
      isOpen={isOpen}
      panelPaddingSize="none"
    >
      <EuiContextMenuPanel
        items={items.map((item) => {
          if (item.type === "item") {
            return (
              <EuiContextMenuItem
                key={item.label}
                disabled={item.disabled}
                icon={item.icon}
                onClick={() => handleClickItem(item)}
                style={{ padding: `6px 8px` }}
                // requiredPermission={item.requiredPermission}
              >
                {item.label}
              </EuiContextMenuItem>
            );
          } else if (item.type === "separator") {
            return <EuiHorizontalRule key={item.key} margin="none" />;
          } else if (item.type === "custom") return item.content;
          return null;
        })}
        size="s"
      />
    </EuiPopover>
  );
};
