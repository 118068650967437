import { EuiCard, EuiFlexGroup, EuiFlexItem } from "@inscopix/ideas-eui";
import { Handle, NodeProps, Position } from "@xyflow/react";

interface SourceResultNodeProps extends NodeProps {
  data: {
    type: "source" | "result";
    title: string;
    description: string;
    isRequired: boolean;
  };
}

export const SourceResultNode = ({ id, data }: SourceResultNodeProps) => {
  return (
    <>
      <EuiCard
        layout="horizontal"
        titleSize="xs"
        title={
          <EuiFlexGroup>
            <EuiFlexItem>
              <span>
                {data["title"]}
                {!data["isRequired"] && (
                  <span style={{ color: "grey" }}> (optional)</span>
                )}
              </span>
            </EuiFlexItem>
          </EuiFlexGroup>
        }
        description={data["description"]}
        hasBorder
        style={{
          height: 80,
          width: 400,
          borderColor: data.type === "source" ? "#07C" : "#F04E98",
          borderWidth: 2,
        }}
      />

      {data.type === "source" && (
        <Handle
          type="target"
          position={Position.Top}
          style={{
            height: 15,
            width: 15,
            backgroundColor: "white",
            border: "2px solid #bbb",
          }}
        />
      )}

      {data.type === "result" && (
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            height: 15,
            width: 15,
            backgroundColor: "white",
            border: "2px solid #bbb",
          }}
        />
      )}
    </>
  );
};
