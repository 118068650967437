/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { EuiButtonIcon } from "@inscopix/ideas-eui";
import { Tooltip } from "components/Tooltip/Tooltip";
import { memo, useState } from "react";
import { useDataTableContext } from "../store/DataTableProvider";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { getValidationErrors } from "./CellRendererBase.helpers";
import { sleep } from "utils/sleep";
import { RunStatus } from "types/constants";

interface ActionRunRowProps {
  tableId: string;
  rowId: string;
  hidden: boolean;
  disabled: boolean;
}

const ActionRunRow = ({
  tableId,
  rowId,
  hidden,
  disabled,
}: ActionRunRowProps) => {
  const executeAnalysisTableRow = useDataTableContext(
    (s) => s.executeAnalysisTableRow,
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    const { error } = await executeAnalysisTableRow({
      tableId,
      rowId,
    });
    setIsLoading(false);

    if (error === undefined) {
      addUtilityToastSuccess("Row running...");
    } else {
      addUtilityToastFailure("Failed to run row");
    }
  };

  return (
    <Tooltip content="Run row">
      <EuiButtonIcon
        aria-label="Run row"
        color="primary"
        iconType="playFilled"
        onClick={() => void handleClick()}
        css={[...(hidden ? [styles.hidden] : [])]}
        isLoading={isLoading}
        disabled={disabled}
      />
    </Tooltip>
  );
};

interface ActionCancelWorkflowRunProps {
  tableId: string;
  rowId: string;
  hidden: boolean;
}

const ActionCancelWorkflowRun = ({
  tableId,
  rowId,
  hidden,
}: ActionCancelWorkflowRunProps) => {
  const cancelWorkflowRun = useDataTableContext((s) => s.cancelWorkflowRun);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    await sleep(1000);
    const { error } = await cancelWorkflowRun({ tableId, rowId });
    setIsLoading(false);

    if (error === undefined) {
      addUtilityToastSuccess("Workflow run canceled");
    } else {
      addUtilityToastFailure("Failed to cancel workflow run");
    }
  };

  return (
    <Tooltip content="Cancel workflow run">
      <EuiButtonIcon
        aria-label="Cancel run"
        color="warning"
        iconType="cross"
        onClick={() => void handleClick()}
        css={[...(hidden ? [styles.hidden] : [])]}
        isLoading={isLoading}
      />
    </Tooltip>
  );
};

interface ActionDeleteRowProps {
  tableId: string;
  rowId: string;
}

const ActionDeleteRow = ({ tableId, rowId }: ActionDeleteRowProps) => {
  const deleteRows = useDataTableContext((s) => s.deleteRows);

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    const { error } = await deleteRows({
      tableId,
      rowIds: [rowId],
    });
    setIsLoading(false);

    if (error === undefined) {
      addUtilityToastSuccess("Row deleted");
    } else {
      addUtilityToastFailure("Failed to delete row");
    }
  };

  return (
    <Tooltip content="Delete row">
      <EuiButtonIcon
        aria-label="Delete row"
        color="danger"
        iconType="minusInCircle"
        onClick={() => void handleClick()}
        isLoading={isLoading}
      />
    </Tooltip>
  );
};

const styles = {
  root: css`
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0px 11px;
    background-color: inherit;
    gap: 5px;
  `,
  hidden: css`
    visibility: hidden;
  `,
};

interface CellRendererRowControlsProps {
  rowId: string;
}

/**
 * Cell renderer that renders any cell value
 */
export const CellRendererRowControls = ({
  rowId,
}: CellRendererRowControlsProps) => {
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const row = useDataTableContext((s) => {
    const table = s.tables.find((table) => table.id === s.selectedTableId);
    return table?.rows.find((row) => row.id === rowId);
  });
  const columns = useDataTableContext((s) => {
    const table = s.tables.find((table) => table.id === s.selectedTableId);
    return table?.columns;
  });

  if (
    selectedTableId === undefined ||
    columns === undefined ||
    row === undefined
  ) {
    return null;
  }

  const hasValidationErrors = columns.some((column, colIndex) => {
    const cellValue = row.cells[colIndex].value;
    const errors = getValidationErrors({ column, value: cellValue });
    return errors.length > 0;
  });

  return (
    <span css={styles.root}>
      {row.run !== null ? (
        <ActionCancelWorkflowRun
          tableId={selectedTableId}
          rowId={row.id}
          hidden={
            row.run.status !== RunStatus.NEW &&
            row.run.status !== RunStatus.RUNNING
          }
        />
      ) : (
        <ActionRunRow
          tableId={selectedTableId}
          rowId={row.id}
          hidden={row.task !== null || row.run !== null}
          disabled={hasValidationErrors}
        />
      )}

      <ActionDeleteRow tableId={selectedTableId} rowId={row.id} />
    </span>
  );
};

export const CellRendererRowControlsMemo = memo(CellRendererRowControls);
