import { EuiSkeletonText } from "@inscopix/ideas-eui";
import { CallOutError } from "components/CallOutError/CallOutError";
import { Flyout, FlyoutProps } from "components/Flyout/Flyout";
import { File as DrsFile, useFlyoutDrsFileInfoQuery } from "graphql/_Types";
import { isUndefined } from "lodash";
import { isDefined } from "utils/isDefined";
import { captureException } from "@sentry/react";
import { isNullish } from "@apollo/client/cache/inmemory/helpers";
import { DrsFileInfoAnalysisTableProps } from "./DrsFileInfoAnalysisTable";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { FlyoutFileInfoBody } from "./FlyoutFileInfoBody";

export interface FlyoutDrsFileInfoProps
  extends Pick<FlyoutProps, "headerControls"> {
  additionalControls?: React.ReactNode;
  drsFile: Pick<DrsFile, "id" | "name">;
  onClose: () => void;
}

export const flyoutDrsFileInfoStyles = {
  descriptionListStyles: {
    dl: {
      display: "grid",
      gridTemplateColumns: "max-content auto",
    },
    dt: {
      gridColumnStart: "1",
      fontWeight: "bold",
      marginRight: "0.5rem",
      padding: "1px 0",
    },
    dd: { gridColumnStart: "2" },
  },
};

export const FlyoutDrsFileInfo = ({
  drsFile,
  onClose,
  // TODO: https://inscopix.atlassian.net/browse/ID-1700
  // This should be converted to a globally available delete button
  // Users should be able to delete any file
  ...props
}: FlyoutDrsFileInfoProps) => {
  const { data, error, loading } = useFlyoutDrsFileInfoQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      drsFileId: drsFile.id,
    },
    onError: (err) => captureException(err),
  });

  const recordings =
    useProjectFilesStore(
      (s) => s.files.find((file) => file.id === drsFile.id)?.recordings,
    ) ?? [];

  if (loading) {
    return (
      <Flyout title={drsFile.name} onClose={onClose}>
        <EuiSkeletonText lines={3} />
      </Flyout>
    );
  }
  const fetchedDrsFileInfo = data?.drsFile;

  if (isUndefined(data) || isNullish(fetchedDrsFileInfo) || isDefined(error)) {
    return (
      <Flyout title={drsFile.name} onClose={onClose}>
        <CallOutError />
      </Flyout>
    );
  }

  const drsFileAnalysisTableInfo: DrsFileInfoAnalysisTableProps | undefined =
    (() => {
      // Getting the corresponding analysis table
      const task =
        fetchedDrsFileInfo.outputGroupFiles.nodes[0]?.outputGroup?.task;
      const analysisTable = task?.analysisTableRow?.analysisTable;
      const analysisTableGroup = analysisTable?.analysisTableGroup;

      if (
        isNullish(task) ||
        isNullish(analysisTable) ||
        isNullish(analysisTableGroup)
      ) {
        return undefined;
      }

      return { task, analysisTable, analysisTableGroup, project: { key: "" } };
    })();

  // const isDownloadPermitted = getDrsFileModifyPermissionByDrsFileAndAction(
  //   fetchedDrsFileInfo,
  //   "DOWNLOAD",
  // ).isPermitted;

  return (
    <Flyout
      title={<div>{fetchedDrsFileInfo.name}</div>}
      onClose={onClose}
      titleSpacerSize="s"
      {...props}
    >
      {/* <EuiFlexGroup alignItems="flexStart" gutterSize="xs">
        <ButtonRenameDrsFile drsFile={fetchedDrsFileInfo} />
        {getDrsFileModifyPermissionByDrsFileAndAction(
          fetchedDrsFileInfo,
          "IDENTIFY",
        ).isPermitted && <ButtonIdentifyFile file={fetchedDrsFileInfo} />}

        {fetchedDrsFileInfo.status === FileStatus["ARCHIVED"] ? (
          <ButtonUnarchiveDrsFile drsFile={fetchedDrsFileInfo} />
        ) : (
          <ButtonArchiveDrsFile drsFile={fetchedDrsFileInfo} />
        )}
        <ButtonDeleteDrsFile drsFile={fetchedDrsFileInfo} />
        {!fetchedDrsFileInfo.isSeries && isDownloadPermitted && (
          <ButtonDownloadFile file={fetchedDrsFileInfo} />
        )}
      </EuiFlexGroup>
      <EuiSpacer size="s" /> */}
      <FlyoutFileInfoBody
        activeFile={fetchedDrsFileInfo}
        recordings={recordings}
        drsFileAnalysisTableInfo={drsFileAnalysisTableInfo}
      />
    </Flyout>
  );
};
