enum PARAM_ACTIONS {
  OPEN_MODAL = "openModal",
}

const MODAL_SHARE = {
  value: "share",
  action: PARAM_ACTIONS.OPEN_MODAL,
} as const;

export const SEARCH_PARAMS = {
  MODAL_SHARE,
} as const;

// export base type
export type SearchParams = (typeof SEARCH_PARAMS)[keyof typeof SEARCH_PARAMS];
