/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { CellValue } from "@inscopix/ideas-hyperformula";

const styles = {
  root: css`
    border: none;
    background-color: transparent;
    width: 100%;
    &:focus {
      outline: none;
    }
  `,
};

interface CellEditorTextProps {
  initialValue: CellValue;
  onChange: (newFormula: string | null) => void;
  stopEditing: () => void;
}

/** Cell editor for cells in text columns */
export const CellEditorText = ({
  initialValue,
  onChange,
  stopEditing,
}: CellEditorTextProps) => {
  const [value, setValue] = useState(initialValue?.toString());

  useEffect(() => {
    if (value === undefined) {
      onChange(null);
    } else {
      // Escape '=' characters to prevent formula from being calculated
      const newFormula = value.toString().replace("=", "'=");
      onChange(newFormula);
    }
  }, [onChange, value]);

  return (
    <input
      autoFocus
      type="text"
      step={0.1}
      css={styles.root}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={stopEditing}
    />
  );
};
