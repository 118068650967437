import { EuiBadge } from "@inscopix/ideas-eui";
import { Tooltip } from "components/Tooltip/Tooltip";
import { RoiFrame } from "@inscopix/ideas-hyperformula";
import { chain } from "lodash";
import { ShapeJson } from "types/ToolRoiFrameParamValue/ToolRoiFrameParamValue";

interface CellValueRendererRoiFrameProps {
  roiFrame: RoiFrame;
}

/** Component that renders an `RoiFrame` cell value` */
export const CellValueRendererRoiFrame = ({
  roiFrame,
}: CellValueRendererRoiFrameProps) => {
  const { shapes } = roiFrame;
  const boundingBox = shapes.find(
    (shape): shape is Extract<ShapeJson, { type: "boundingBox" }> =>
      shape.type === "boundingBox",
  );
  const roiCount = shapes.filter(
    (shape) => shape.type !== "boundingBox",
  ).length;

  return (
    <>
      {boundingBox && (
        <Tooltip
          content={
            <div>
              <div>{boundingBox.name}</div>
              <div>left: {Math.round(boundingBox.left)}</div>
              <div>top: {Math.round(boundingBox.top)}</div>
              <div>width: {Math.round(boundingBox.width)}</div>
              <div>height: {Math.round(boundingBox.height)}</div>
            </div>
          }
        >
          <EuiBadge title="">
            {Math.round(boundingBox.left)},{Math.round(boundingBox.top)},
            {Math.round(boundingBox.width)},{Math.round(boundingBox.height)}
          </EuiBadge>
        </Tooltip>
      )}

      {roiCount > 0 && (
        <Tooltip
          content={
            <div>
              {chain(shapes)
                .groupBy((shape) => shape.groupKey)
                .entries()
                .map(([groupKey, shapes]) => {
                  return (
                    // TODO: Display group name when column definitions are implemented
                    <div key={groupKey}>
                      {groupKey}: {shapes.length} ROIs
                    </div>
                  );
                })
                .value()}
            </div>
          }
        >
          <EuiBadge title="">{roiCount} ROIs</EuiBadge>
        </Tooltip>
      )}
    </>
  );
};
