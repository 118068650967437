import assert from "assert";
import axios, { AxiosResponse } from "axios";
import { Project } from "graphql/_Types";
import { getEnvVar } from "ideas.env";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { isDefined } from "utils/isDefined";
import {
  CellAddress,
  DataTableColumnDefinition,
} from "./DataTableProvider.types";
import { chain } from "lodash";
import {
  FileStatus,
  ProcessingStatus,
  RunStatus,
  TaskStatus,
} from "types/constants";
import { FileType } from "types/FileTypes";
import { File as DrsFile } from "graphql/_Types";
import { Except, JsonValue } from "type-fest";
import pDebounce from "p-debounce";
import { Alpha } from "../layout/scratch";

/**
 * Represents the options passed to {@link getProjectId}
 */
type GetProjectIdOptions = {
  projectKey: Project["key"];
};

type GetProjectIdResponse = {
  results: {
    id: string;
  }[];
};

/**
 * Gets the ID of a project with a specified key within the current tenant.
 * @param options
 * @returns The project ID.
 */
export const getProjectId = async ({ projectKey }: GetProjectIdOptions) => {
  const url = getEnvVar("URL_LIBRARY_PROJECT");
  const headers = await getRequestHeaders();
  const params = { key: projectKey };
  const { data } = await axios.get<GetProjectIdResponse>(url, {
    headers,
    params,
  });
  const project = data.results[0];
  assert(isDefined(project));
  return project.id;
};

/**
 * Represents the options passed to {@link getTableData}
 */
type GetTableDataOptions = {
  projectId: string;
};

/**
 * Represents the response from the server when reading data for data tables
 * and analysis tables within a project.
 */
export type GetTableDataResponse = [
  {
    id: string;
    kind: "data";
    key: string;
    name: string;
    column_groups: {
      id: string;
      name: string;
    }[];
    columns: {
      id: string;
      name: string;
      pinned: boolean;
      order: number;
      width: number;
      editable: boolean;
      deletable: boolean;
      identifier_position: number | null;
      default_formula: string | null;
      required: boolean;
      definition: DataTableColumnDefinition;
      group: string | null;
    }[];
    rows: {
      id: string;
      index: number;
      editable: boolean;
      formulas: (string | null)[];
      task?: {
        id: string;
        status: TaskStatus;
        date_created: string;
        credits: number | null;
        duration: string;
      };
      run?: {
        id: string;
        status: RunStatus;
        date_created: string;
        credits: number | null;
        duration: string;
      };
    }[];
  },
];

/**
 * Get the table data for every data table cell within a project.
 * @param options
 * @returns The table data organized by tables, columns and rows.
 */
export const getTableData = async ({ projectId }: GetTableDataOptions) => {
  const baseUrl = getEnvVar("URL_LIBRARY_PROJECT");
  const url = `${baseUrl}formulas/${projectId}/`;
  const headers = await getRequestHeaders();
  const { data } = await axios.get<GetTableDataResponse>(url, { headers });
  return data;
};

/**
 * Represents the options passed to {@link createDataTable}
 */
type CreateDataTableOptions = {
  name: string;
  key: string;
  projectId: string;
};

/**
 * Represents the response returned by the server when creating a data table
 */
type CreateDataTableResponse = {
  id: string;
  column_groups: GetTableDataResponse[number]["column_groups"];
  columns: GetTableDataResponse[number]["columns"];
  rows: Except<GetTableDataResponse[number]["rows"][number], "formulas">[];
};

/**
 * Creates a new data table within a project.
 * @param options
 * @returns The table's ID.
 */
export const createDataTable = async ({
  name,
  key,
  projectId,
}: CreateDataTableOptions) => {
  const url = getEnvVar("URL_LIBRARY_DATA_TABLE");
  const body = {
    name,
    key,
    project: projectId,
  };
  const headers = await getRequestHeaders();
  const { data } = await axios.post<CreateDataTableResponse>(url, body, {
    headers,
  });
  return data;
};

/**
 * Represents the options passed to {@link createAnalysisTable}
 */
type CreateAnalysisTableOptions = {
  toolVersionId: number;
  name: string;
  key: string;
  projectId: string;
};

/**
 * Represents the response returned by the server when creating an analysis table
 */
type CreateAnalysisTableResponse = {
  id: string;
  column_groups: GetTableDataResponse[number]["column_groups"];
  columns: GetTableDataResponse[number]["columns"];
  rows: Except<GetTableDataResponse[number]["rows"][number], "formulas">[];
};

/**
 * Creates a new analysis table within a project.
 * @param options
 * @returns The table's ID.
 */
export const createAnalysisTable = async ({
  toolVersionId,
  name,
  key,
  projectId,
}: CreateAnalysisTableOptions) => {
  const url = getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE");
  const body = {
    tool_version: toolVersionId,
    name,
    key,
    project: projectId,
  };
  const headers = await getRequestHeaders();
  const { data } = await axios.post<CreateAnalysisTableResponse>(url, body, {
    headers,
  });
  return data;
};

/**
 * Represents the options passed to {@link updateDataTable}
 */
type UpdateDataTableOptions = {
  tableId: string;
  name?: string;
  key?: string;
};

/**
 * Represents the response returned by the server when updating a data table
 */
type UpdateDataTableResponse = {
  id: string;
};

/**
 * Updated a data table.
 * @param options
 * @returns The table's ID.
 */
export const updateDataTable = async ({
  tableId,
  name,
  key,
}: UpdateDataTableOptions) => {
  const baseUrl = getEnvVar("URL_LIBRARY_DATA_TABLE");
  const url = `${baseUrl}${tableId}/`;
  const body = {
    ...(isDefined(name) ? { name } : {}),
    ...(isDefined(key) ? { key } : {}),
  };
  const headers = await getRequestHeaders();
  const { data } = await axios.patch<UpdateDataTableResponse>(url, body, {
    headers,
  });
  return data.id;
};

/**
 * Represents the options passed to {@link updateAnalysisTable}
 */
type UpdateAnalysisTableOptions = {
  tableId: string;
  name?: string;
  key?: string;
};

/**
 * Represents the response returned by the server when updating an analysis table
 */
type UpdateAnalysisTableResponse = {
  id: string;
};

/**
 * Updated an analysis table.
 * @param options
 * @returns The table's ID.
 */
export const updateAnalysisTable = async ({
  tableId,
  name,
  key,
}: UpdateAnalysisTableOptions) => {
  const baseUrl = getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE");
  const url = `${baseUrl}${tableId}/`;
  const body = {
    ...(isDefined(name) ? { name } : {}),
    ...(isDefined(key) ? { key } : {}),
  };
  const headers = await getRequestHeaders();
  const { data } = await axios.patch<UpdateAnalysisTableResponse>(url, body, {
    headers,
  });
  return data.id;
};

/**
 * Represents the options passed to {@link updateColumn}
 */
type UpdateColumnOptions = {
  tableKind: "data" | "analysis";
  columnId: string;
  name?: string;
  width?: number;
};

/**
 * Represents the response returned by the server when updating a column
 */
type UpdateColumnResponse = {
  id: string;
};

/**
 * Updates a column.
 * @param options
 * @returns The column's ID.
 */
export const updateColumn = async ({
  tableKind,
  columnId,
  name,
  width,
}: UpdateColumnOptions) => {
  const baseUrl =
    tableKind === "data"
      ? getEnvVar("URL_LIBRARY_DATA_TABLE_COLUMN")
      : getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE_COLUMN");
  const url = `${baseUrl}${columnId}/`;
  const body = {
    ...(isDefined(name) ? { name } : {}),
    ...(isDefined(width) ? { width } : {}),
  };
  const headers = await getRequestHeaders();
  const { data } = await axios.patch<UpdateColumnResponse>(url, body, {
    headers,
  });
  return data.id;
};

/**
 * Represents the options passed to {@link createDataTableColumn}
 */
type CreateColumnOptions = {
  tableId: string;
  name: string;
  editable: boolean;
  defaultFormula: string | undefined;
  definition: DataTableColumnDefinition;
};

/**
 * Represents the response returned by the server when creating a data table
 * column
 */
type CreateColumnResponse = {
  id: string;
  definition: DataTableColumnDefinition;
  name: string;
  date_created: string;
  date_deleted: null;
  pinned: boolean;
  order: number;
  width: number;
  editable: boolean;
  deletable: boolean;
  identifier_position: number | null;
  default_formula: string | null;
  user: string;
  table: string;
  group: null;
  required: boolean;
};

/**
 * Creates a new data table column.
 * @param options
 * @returns The new column.
 */
export const createDataTableColumn = async ({
  tableId,
  name,
  editable,
  defaultFormula,
  definition,
}: CreateColumnOptions) => {
  const url = getEnvVar("URL_LIBRARY_DATA_TABLE_COLUMN");
  const body = {
    table: tableId,
    name,
    default_formula: defaultFormula,
    definition,
    editable,
  };
  const headers = await getRequestHeaders();
  const res = await axios.post<CreateColumnResponse>(url, body, { headers });
  return res.data;
};

/**
 * Represents the options passed to {@link createRowsBulk}
 */
type CreateRowsBulkOptions = {
  tableId: string;
  numRows: number;
  tableKind: "data" | "analysis";
};

/**
 * Represents the response returned by the server when creating table rows
 */
type CreateRowsBulkResponse = {
  id: string;
  index: number;
  editable: boolean;
  date_created: string;
  date_deleted: null;
  user: string;
  table: string;
  task: null;
}[];

/**
 * Creates multiple rows in a specified table
 * @param options
 * @returns The new rows
 */
export const createRowsBulk = async ({
  tableId,
  numRows,
  tableKind,
}: CreateRowsBulkOptions) => {
  const baseUrl =
    tableKind === "data"
      ? getEnvVar("URL_LIBRARY_DATA_TABLE_ROW")
      : getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE_ROW");
  const url = `${baseUrl}bulk/`;
  const body = new Array(numRows).fill({ table: tableId });
  const headers = await getRequestHeaders();
  const res = await axios.post<CreateRowsBulkResponse>(url, body, { headers });
  return res.data;
};

/**
 * Represents the options passed to {@link updateCellFormula}
 */
type UpdateCellFormulaOptions = {
  address: CellAddress;
  formula: string;
  tableKind: "data" | "analysis";
};

/**
 * Represents the response returned by the server when updating a cell formula
 */
type UpdateCellFormulaResponse = {
  id: string;
  formula: string;
  tenant: number;
  column: string;
  row: string;
};

/**
 * Get the formulas for every data table cell within a project.
 * @param options
 * @returns The cell formulas organized by columns and rows.
 */
export const updateCellFormula = async ({
  address,
  formula,
  tableKind,
}: UpdateCellFormulaOptions) => {
  const baseUrl =
    tableKind === "data"
      ? getEnvVar("URL_LIBRARY_DATA_TABLE_CELL")
      : getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE_CELL");
  const url = `${baseUrl}set_formula/`;
  const body = {
    table: address.tableId,
    column: address.columnId,
    row: address.rowId,
    formula,
  };
  const headers = await getRequestHeaders();
  const { data } = await axios.post<UpdateCellFormulaResponse>(url, body, {
    headers,
  });
  return data.formula;
};

/**
 * Represents the options passed to {@link getProjectFile}
 */
type GetProjectFileOptions = {
  fileId: string;
};

/**
 * Represents the response returned by the server when fetching files
 */
export type GetProjectFileResponse = {
  results: {
    id: string;
    name: string;
    processing_status: ProcessingStatus;
    file_type: FileType["id"] | null;
    status: FileStatus;
    is_series: boolean;
    series_parent_id: string | null;
  }[];
};

/**
 * Gets all files stored in the project.
 * @param options
 * @returns The project files.
 */
export const getProjectFile = (() => {
  // Accumulator that stores all arguments passed during the debounce interval
  const allArgs: GetProjectFileOptions[] = [];

  // Debounced function that fetches all files requested in an interval
  const callback = pDebounce(async () => {
    // Copy the accumulator and reset it. Since this happens in one step, there
    // is no risk of concurrency issues when pushing to the array.
    const args = allArgs.splice(0);

    const url = getEnvVar("URL_DRS_FILE_CREATE");
    const params = {
      id__in: chain(args)
        .map((arg) => arg.fileId)
        .uniq()
        .join(",")
        .value(),
    };
    const headers = await getRequestHeaders();
    const res = await axios.get<GetProjectFileResponse>(url, {
      headers,
      params,
    });

    return res.data.results;
  }, 10);

  return async (options: GetProjectFileOptions) => {
    // Add arguments to the accumulator
    allArgs.push(options);

    // Call the debounced callback. This will resolve after the debounce
    // interval has elapsed.
    const files = await callback();

    // Return the metadatum matching the provided arguments
    return files.find(
      (result) =>
        result.id === options.fileId &&
        ![
          FileStatus.DATA_DELETED,
          FileStatus.SCHEDULE_DATA_DELETE,
          FileStatus.SCHEDULE_DELETE,
        ].includes(result.status),
    );
  };
})();

/**
 * Represents the options passed to {@link getFileMetadatumValue}
 */
type GetFileMetadatumValueOptions = {
  fileId: string;
  metadatumKey: string;
};

/**
 * Represents the response returned by the server when fetching file metadatum
 * values
 */
type GetFileMetadatumValueResponse = {
  results: {
    date_created: string;
    file: string;
    id: string;
    key: string;
    tenant: number;
    values: [
      {
        date_created: string;
        value: JsonValue;
      },
    ];
  }[];
};

/**
 * Gets the current value of a specified file metadatum.
 * @param options
 * @returns The metadatum value.
 */
export const getFileMetadatumValue = (() => {
  // Accumulator that stores all arguments passed during the debounce interval
  const allArgs: GetFileMetadatumValueOptions[] = [];

  // Debounced function that fetches all file metadata requested in an interval
  const callback = pDebounce(async () => {
    // Copy the accumulator and reset it. Since this happens in one step, there
    // is no risk of concurrency issues when pushing to the array.
    const args = allArgs.splice(0);

    const url = getEnvVar("URL_METADATA_FILE_METADATA");
    const params = {
      file__in: chain(args)
        .map((arg) => arg.fileId)
        .uniq()
        .join(",")
        .value(),
      metadata__key__in: chain(args)
        .map((arg) => arg.metadatumKey)
        .uniq()
        .join(",")
        .value(),
    };
    const headers = await getRequestHeaders();
    const res = await axios.get<GetFileMetadatumValueResponse>(url, {
      headers,
      params,
    });

    return res.data.results;
  }, 10);

  return async (options: GetFileMetadatumValueOptions) => {
    // Add arguments to the accumulator
    allArgs.push(options);

    // Call the debounced callback. This will resolve after the debounce
    // interval has elapsed.
    const results = await callback();

    // Find the metadatum matching the provided arguments
    const metadatum = results.find(
      (result) =>
        result.file === options.fileId && result.key === options.metadatumKey,
    );

    if (metadatum === undefined) {
      return null;
    }

    // Return the latest value for the metadatum
    return chain(metadatum.values)
      .sortBy((value) => value.date_created)
      .last()
      .value().value;
  };
})();

/**
 * Represents the options passed to {@link deleteRows}
 */
type DeleteTableOptions = {
  tableKind: "data" | "analysis";
  tableId: string;
};

/**
 * Represents the response returned by the server when deleting tables
 */
type DeleteTableResponse = {
  id: string;
};

/**
 * Deletes a specified table
 * @param options
 * @returns The ID of the deleted table.
 */
export const deleteTable = async ({
  tableKind,
  tableId,
}: DeleteTableOptions) => {
  const baseUrl =
    tableKind === "data"
      ? getEnvVar("URL_LIBRARY_DATA_TABLE")
      : getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE");

  const url = `${baseUrl}${tableId}/`;
  const headers = await getRequestHeaders();
  const res = await axios.delete<DeleteTableResponse>(url, { headers });
  return res.data.id;
};

/**
 * Represents the options passed to {@link deleteDataTableColumn}
 */
type DeleteColumnOptions = {
  columnId: string;
};

/**
 * Represents the response returned by the server when deleting data table columns
 */
type DeleteColumnResponse = {
  id: string;
};

/**
 * Deletes a data table column.
 * @param options
 * @returns The ID of the deleted column.
 */
export const deleteDataTableColumn = async ({
  columnId,
}: DeleteColumnOptions) => {
  const baseUrl = getEnvVar("URL_LIBRARY_DATA_TABLE_COLUMN");
  const url = `${baseUrl}${columnId}/`;
  const headers = await getRequestHeaders();
  const res = await axios.delete<DeleteColumnResponse>(url, { headers });
  return res.data.id;
};

/**
 * Represents the options passed to {@link deleteRows}
 */
type DeleteRowsOptions = {
  rowIds: string[];
  tableKind: "data" | "analysis";
};

/**
 * Represents the response returned by the server when deleting table rows
 */
type DeleteRowsResponse = {
  id: string;
  index: number;
  editable: boolean;
  date_created: string;
  date_deleted: null;
  user: string;
  table: string;
  task: null;
}[];

/**
 * Deletes multiple rows.
 * @param options
 * @returns The IDs of the deleted rows.
 */
export const deleteRows = async ({ rowIds, tableKind }: DeleteRowsOptions) => {
  const baseUrl =
    tableKind === "data"
      ? getEnvVar("URL_LIBRARY_DATA_TABLE_ROW")
      : getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE_ROW");

  const promises = rowIds.map(async (rowId) => {
    const url = `${baseUrl}${rowId}/`;
    const headers = await getRequestHeaders();
    const res = await axios.delete<DeleteRowsResponse>(url, { headers });
    return res.data;
  });

  await Promise.all(promises);
  return rowIds;
};

type CreateFileInput = Pick<DrsFile, "projectId" | "name" | "tenantId"> & {
  size: number;
  // processingStatus: ProcessingStatus;
  // fileType: NonNullable<DrsFile["fileType"]>;
  // fileFormat: NonNullable<DrsFile["fileFormat"]>;
};

type CreateFileBody = {
  // id: DrsFile["id"];
  // dataset: DrsFile["datasetId"];
  name: DrsFile["name"];
  part_size: DrsFile["partSize"];
  size: DrsFile["size"];
  date_created: DrsFile["dateCreated"];
  project: DrsFile["projectId"];
  uploaded: true;
  tenant: number;
  // processing_status: ProcessingStatus;
  // file_type: DrsFile["fileType"];
  // file_format: DrsFile["fileFormat"];
};

type CreateFileResponse = AxiosResponse<{
  date_created: DrsFile["dateCreated"];
  id: DrsFile["id"];
  name: DrsFile["name"];
  project: DrsFile["projectId"];
  part_size: NonNullable<DrsFile["partSize"]>;
  size: DrsFile["size"];
  status: FileStatus;
  user: DrsFile["userId"];
  tenant: DrsFile["tenantId"];
  processing_status: ProcessingStatus;
  file_type: FileType["id"];
  file_format: DrsFile["fileFormat"];
  is_series: boolean;
  series_parent_id: string | null;
}>;

/**
 * Calculates a multipart upload part size for a minimum part size of 100 MB
 * (AWS guideline) and maximum 10,000 parts (AWS limit). Part sizes will only
 * increase from the minimum for extremely large (1TB+) files.
 * @param fileSize file size in bytes
 * @returns A multipart upload part size in bytes
 */
const getPartSize = (fileSize: number) => {
  const minPartSize = 52_428_800; // 50 MB
  const maxNumParts = 10_000;
  const factor = Math.ceil(fileSize / (minPartSize * maxNumParts));
  return factor * minPartSize;
};

/**
 * Formats a filename to be compatible with the backend
 * @param filename
 * @returns The formatted filename
 */
const formatFilename = (filename: string) => {
  /*
      A valid filename may contain the following characters:
      - Letters (A-Za-z)
      - Numbers (\d)
      - Periods (.)
      - Hyphens (-)
      - Underscores (_)
    */
  const invalidCharRegExp = /[^A-Za-z\d.-_]/g;
  const replaceValue = "-";
  return filename.replace(invalidCharRegExp, replaceValue);
};

export const createFile = async (input: CreateFileInput) => {
  const url = getEnvVar("URL_DRS_FILE_CREATE");

  const body: CreateFileBody = {
    // id: input.id,
    // dataset: input.datasetId,
    name: formatFilename(input.name),
    size: input.size.toString(),
    part_size: getPartSize(input.size),
    date_created: new Date().toISOString(),
    project: input.projectId,
    uploaded: true,
    tenant: input.tenantId,
    // processing_status: input.processingStatus,
    // file_format: input.fileFormat,
    // file_type: input.fileType,
  };

  const headers = await getRequestHeaders();

  const { data } = await axios.post<CreateFileBody, CreateFileResponse>(
    url,
    body,
    { headers },
  );

  return data;
};

/**
 * Represents the options passed to {@link executeAnalysisTableRow}
 */
type ExecuteAnalysisTableRowOptions = {
  rowId: string;
  cellValues: {
    columnId: string;
    staticFormula: string | null;
    value: Alpha;
  }[];
};

/**
 * Represents the response returned by the server when executing analysis table
 * rows
 */
type ExecuteAnalysisTableRowResponse = {
  task: string | null;
  run: string | null;
};

/**
 * Deletes multiple rows.
 * @param options
 * @returns The IDs of the deleted rows.
 */
export const executeAnalysisTableRow = async ({
  rowId,
  cellValues,
}: ExecuteAnalysisTableRowOptions) => {
  const baseUrl = getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE_ROW");
  const url = `${baseUrl}execute/${rowId}/`;
  const body = {
    cell_values: cellValues.map((cell) => ({
      column: cell.columnId,
      static_formula: cell.staticFormula,
      value: cell.value,
    })),
  };
  const headers = await getRequestHeaders();
  const res = await axios.post<ExecuteAnalysisTableRowResponse>(url, body, {
    headers,
  });
  return res.data;
};

/**
 * Represents the options passed to {@link getTask}
 */
type GetTaskOptions = {
  taskId: string;
};

/**
 * Represents the response returned by the server when retrieving tasks
 */
type GetTaskResponse = {
  id: string;
  status: TaskStatus;
  date_created: string;
  credits: number | null;
  duration: string;
};

export const getTask = async ({ taskId }: GetTaskOptions) => {
  const baseUrl = getEnvVar("URL_TES_TASK_CREATE");
  const url = `${baseUrl}${taskId}/`;
  const headers = await getRequestHeaders();
  const res = await axios.get<GetTaskResponse>(url, { headers });
  return res.data;
};

/**
 * Represents the options passed to {@link getRun}
 */
type GetRunOptions = {
  runId: string;
};

/**
 * Represents the response returned by the server when retrieving runs
 */
type GetRunResponse = {
  id: string;
  status: RunStatus;
  date_created: string;
  credits: number | null;
  duration: string;
};

export const getRun = async ({ runId }: GetRunOptions) => {
  const baseUrl = getEnvVar("URL_WES_RUN");
  const url = `${baseUrl}${runId}/`;
  const headers = await getRequestHeaders();
  const res = await axios.get<GetRunResponse>(url, { headers });
  return res.data;
};

/**
 * Represents the options passed to {@link getAnalysisTableRows}
 */
type GetAnalysisTableRowsOptions = {
  rowIds: string[];
};

/**
 * Represents the response returned by the server when retrieving analysis
 * table rows
 */
type GetAnalysisTableRowsResponse = {
  results: {
    id: string;
    index: number;
    editable: boolean;
    formulas: (string | null)[];
    task?: {
      id: string;
      status: TaskStatus;
      date_created: string;
      credits: number | null;
      duration: string;
    };
    run?: {
      id: string;
      status: RunStatus;
      date_created: string;
      credits: number | null;
      duration: string;
    };
  }[];
};

export const getAnalysisTableRows = async ({
  rowIds,
}: GetAnalysisTableRowsOptions) => {
  const url = getEnvVar("URL_LIBRARY_GDT_ANALYSIS_TABLE_ROW");
  const params = { id__in: rowIds.join(",") };
  const headers = await getRequestHeaders();
  const res = await axios.get<GetAnalysisTableRowsResponse>(url, {
    headers,
    params,
  });
  return res.data.results;
};

export type DataDeleteDrsFileInput = {
  id: string;
  date_data_deleted: DrsFile["dateDeleted"];
};

export type DataDeleteDrsFileDjangoResponse = {
  data: {
    id: DrsFile["id"];
    date_data_deleted: DrsFile["dateDeleted"];
    status: DrsFile["status"];
    project: DrsFile["projectId"];
  };
};

export const deleteFile = async (input: DataDeleteDrsFileInput) => {
  const url = `${getEnvVar("URL_DRS_FILE_SCHEDULE_DATA_DELETE")}${input.id}/`;
  const headers = await getRequestHeaders();
  const res = await axios.patch<
    DataDeleteDrsFileInput,
    DataDeleteDrsFileDjangoResponse
  >(url, input, { headers });
  return res.data.id;
};

type CreateWorkflowTableOptions = {
  id: string;
  project: string;
  name: string;
  key: string;
};

export const createWorkflowTable = async (body: CreateWorkflowTableOptions) => {
  const baseUrl = getEnvVar("URL_WRS_WORKFLOW");
  const url = `${baseUrl}${body.id}/create_workflow_table/`;
  const headers = await getRequestHeaders();
  const res = await axios.post<CreateAnalysisTableResponse>(url, body, {
    headers,
  });
  return res.data;
};

type CancelWorkflowRunOptions = {
  id: string;
};

export const cancelWorkflowRun = async (body: CancelWorkflowRunOptions) => {
  const baseUrl = getEnvVar("URL_WES_RUN");
  const url = `${baseUrl}${body.id}/cancel/`;
  const headers = await getRequestHeaders();
  const res = await axios.patch<CreateAnalysisTableResponse>(url, undefined, {
    headers,
  });
  return res.data;
};
