/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import {
  CellValue,
  IdeasFile,
  RowIdentifier,
} from "@inscopix/ideas-hyperformula";
import {
  EuiButtonEmpty,
  EuiCheckbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  EuiPopoverFooter,
  EuiPopoverTitle,
  EuiSelectable,
  EuiSelectableOption,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { CellValueRendererBase } from "../cell-value-renderers/CellValueRendererBase";
import { useDataSelectorStyles } from "components/ToolParamsGrid/DataSelector/useDataSelectorStyles";
import { isDefined } from "utils/isDefined";
import { useDataTableContext } from "../store/DataTableProvider";
import { CellValueRendererRowIdentifier } from "../cell-value-renderers/CellValueRendererRowIdentifier";
import { FileBadge } from "components/FileBadge/FileBadge";
import { FileSource } from "graphql/_Types";

const styles2 = {
  popover: css`
    & {
      height: 100%;
      width: 100%;
      .PopoverAnchor {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      }
    }
  `,
  panel: css`
    max-height: max(200px, 50vh);
    overflow-y: scroll;
  `,
};

interface CellEditorFileProps {
  tableId: string;
  columnId: string;
  rowId: string;
  initialValue: CellValue;
  onChange: (newFormula: string | null) => void;
  stopEditing: () => void;
}

/** Cell editor for cells in file columns */
export const CellEditorFile = ({
  tableId: parentTableId,
  columnId: parentColumnId,
  rowId: parentRowId,
  initialValue,
  onChange,
  stopEditing,
}: CellEditorFileProps) => {
  const styles = useDataSelectorStyles();
  const dataTables = useDataTableContext((s) => {
    return s.tables.filter((table) => table.kind === "data");
  });

  const analysisTables = useDataTableContext((s) => {
    return s.tables.filter((table) => table.kind === "analysis");
  });

  const [newValue, setNewValue] = useState(initialValue);
  const [tableId, setTableId] = useState<string>();
  const [rowId, setRowId] = useState<string>();
  const [columnId, setColumnId] = useState<string>();

  const table = useDataTableContext((s) =>
    s.tables.find((table) => table.id === tableId),
  );

  const tablesOptions: EuiSelectableOption[] = [
    // {
    //   label: "All Files",
    // },
    {
      label: "Data Tables",
      isGroupLabel: true,
    },
    ...dataTables.map((table) => ({
      key: table.id,
      label: table.name,
    })),
    {
      label: "Analysis Tables",
      isGroupLabel: true,
    },
    ...analysisTables.map((table) => ({
      key: table.id,
      label: table.name,
    })),
  ];

  const rowOptions: EuiSelectableOption[] | undefined = table?.rows.map(
    (row) => ({
      key: row.id,
      label: row.id,
      data: { tableKey: table.key, rowIndex: row.index },
    }),
  );

  const columnOptions: EuiSelectableOption[] | undefined = table?.columns.map(
    (column) => ({
      key: column.id,
      label: column.name,
    }),
  );

  const fileOptions: EuiSelectableOption[] | undefined = (() => {
    if (table === undefined || rowId === undefined || columnId === undefined) {
      return [];
    }

    const columnIndex = table.columns.findIndex(({ id }) => id === columnId);
    const row = table.rows.find(({ id }) => id === rowId);
    const cell = row?.cells[columnIndex];

    return isDefined(cell) && cell.value instanceof IdeasFile
      ? [
          {
            key: htmlIdGenerator()(),
            label: cell.value.attrs.name,
            data: { file: cell.value },
          },
        ]
      : [];
  })();

  return (
    <EuiPopover
      css={styles2.popover}
      anchorPosition="downCenter"
      anchorClassName="PopoverAnchor"
      button={
        <CellValueRendererBase
          tableId={parentTableId}
          columnId={parentColumnId}
          rowId={parentRowId}
          value={newValue}
        />
      }
      className="Popover"
      closePopover={stopEditing}
      display="block"
      isOpen
      panelPaddingSize="xs"
      repositionOnScroll={false}
    >
      <EuiPopoverTitle>
        {/* <EuiFlexGroup>
          <EuiFlexItem grow={false}>Selected:</EuiFlexItem>
        </EuiFlexGroup> */}
      </EuiPopoverTitle>
      <div css={styles.columnSelector}>
        <div css={styles.column}>
          <EuiSelectable
            searchable
            singleSelection={true}
            options={tablesOptions}
            onChange={(options) => {
              const checkedOption = options.find((o) => o.checked === "on");
              setTableId(checkedOption?.key);
              setRowId(undefined);
              setColumnId(undefined);
              setNewValue(null);
            }}
            height={"full"}
            searchProps={{
              placeholder: "",
              compressed: true,
              isClearable: true,
            }}
            listProps={{
              showIcons: false,
            }}
          >
            {(list, search) => (
              <>
                {search}
                {list}
              </>
            )}
          </EuiSelectable>
        </div>

        {isDefined(tableId) && (
          <div css={styles.column}>
            <EuiSelectable
              searchable
              singleSelection={true}
              options={rowOptions}
              onChange={(options) => {
                const checkedOption = options.find((o) => o.checked === "on");
                setRowId(checkedOption?.key);
                setColumnId(undefined);
                setNewValue(null);
              }}
              height={"full"}
              searchProps={{
                placeholder: "",
                compressed: true,
                isClearable: true,
              }}
              listProps={{
                showIcons: false,
              }}
              renderOption={(option) => {
                const data = option as unknown as {
                  tableKey: string;
                  rowIndex: number;
                };
                return (
                  <CellValueRendererRowIdentifier
                    rowIdentifier={
                      new RowIdentifier(data.tableKey, data.rowIndex)
                    }
                  />
                );
              }}
            >
              {(list, search) => (
                <>
                  {search}
                  {list}
                </>
              )}
            </EuiSelectable>
          </div>
        )}

        {isDefined(rowId) && (
          <div css={styles.column}>
            <EuiSelectable
              searchable
              singleSelection={true}
              options={columnOptions}
              onChange={(options) => {
                const checkedOption = options.find((o) => o.checked === "on");
                setColumnId(checkedOption?.key);
                setNewValue(null);
              }}
              height={"full"}
              searchProps={{
                placeholder: "",
                compressed: true,
                isClearable: true,
              }}
              listProps={{
                showIcons: false,
              }}
            >
              {(list, search) => (
                <>
                  {search}
                  {list}
                </>
              )}
            </EuiSelectable>
          </div>
        )}

        {isDefined(columnId) && (
          <div css={styles.column}>
            <EuiSelectable
              searchable
              singleSelection={true}
              options={fileOptions}
              onChange={(options) => {
                const checkedOption = options.find((o) => o.checked === "on");
                const better = checkedOption as unknown as
                  | {
                      data: { file: IdeasFile };
                    }
                  | undefined;

                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                setNewValue(better?.data.file ?? null);
                onChange(
                  isDefined(better)
                    ? `=FILE("${better.data.file.attrs.id}")`
                    : null,
                );
              }}
              height={"full"}
              searchProps={{
                placeholder: "",
                compressed: true,
                isClearable: true,
              }}
              listProps={{
                showIcons: false,
              }}
              renderOption={(option) => {
                const data = option as unknown as {
                  file: IdeasFile;
                };
                return (
                  <FileBadge
                    drsFile={{
                      id: data.file.attrs.id,
                      name: data.file.attrs.name,
                      status: data.file.attrs.status,
                      fileType: data.file.attrs.fileType,
                      isSeries: data.file.attrs.isSeries,
                      source: FileSource.Uploaded,
                      processingStatus: data.file.attrs.processingStatus,
                      seriesParentId: data.file.attrs.seriesParentId,
                    }}
                  />
                );
              }}
            >
              {(list, search) => (
                <>
                  {search}
                  {list}
                </>
              )}
            </EuiSelectable>
          </div>
        )}
      </div>
      <EuiPopoverFooter>
        <EuiFlexGroup alignItems={"center"}>
          <EuiFlexItem>
            <EuiCheckbox
              id={"allVisibleToggle"}
              label="Hide options that do not contain a matching file"
              checked={false}
              onChange={(e) => undefined}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty
              color={"text"}
              iconType={"cross"}
              size="s"
              onClick={stopEditing}
            >
              Close
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPopoverFooter>
    </EuiPopover>
  );
};
