import { createStore } from "zustand";
import { DataTableStoreState } from "./DataTableProvider.types";
import { immer } from "zustand/middleware/immer";
import { Project } from "graphql/_Types";
import * as DjangoClient from "./DataTableProvider.api";
import { first } from "lodash";
import { initSpreadsheetEngine } from "./engine";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { createProjectActionQueue } from "./queue";
import { QueuedFile } from "stores/upload/useFileUploadStore";
import {
  HyperFormula,
  IdeasFile,
  RawCellContent,
  SimpleCellAddress,
} from "@inscopix/ideas-hyperformula";
import {
  convertCellValueToStaticFormula,
  convertCellValueToToolInput,
} from "../layout/scratch";
import { RunStatus, TaskStatus } from "types/constants";

/**
 * Create a new Zustand store for storing data table and analysis table data
 * and actions for updating the stored data.
 * @param projectKey
 * @returns The Zustand store.
 */
export const createDataTableStore = async (
  projectKey: Project["key"],
  enqueueFile: (file: QueuedFile) => void,
) => {
  // Fetch cell formulas from server
  const projectId = await DjangoClient.getProjectId({ projectKey });
  const data = await DjangoClient.getTableData({ projectId });

  // Initialize data and define actions
  return createStore<DataTableStoreState>()(
    immer((set, get) => {
      // Initialize a new empty HyperFormula engine instance
      let engine = HyperFormula.buildEmpty();

      // Wait until the store is initialized to register the sheets with the engine.
      // This is necessary because the formula context, uses `get` which will not
      // return data until after the store has been created.
      setTimeout(function registerSheets() {
        const isStoreInitialized = get() !== undefined;

        if (!isStoreInitialized) {
          setTimeout(registerSheets, 1);
          return;
        }

        engine = initSpreadsheetEngine(data, set, get);
      }, 1);

      // Format store data using the cell values computed by the engine
      const tables = data.map((table) => ({
        ...table,
        columnGroups: table.column_groups,
        columns: table.columns,
        rows: table.rows.map((row) => ({
          id: row.id,
          index: row.index,
          editable: row.editable,
          task: row.task ?? null,
          run: row.run ?? null,
          cells: row.formulas.map((formula) => ({
            value: null,
            formula,
          })),
        })),
      }));

      const syncFormulaChanges = () => {
        const sheets = engine.getAllSheetsSerialized();
        get().tables.forEach((table) => {
          const formulas = sheets[table.key];
          table.rows.forEach((row, rowIndex) => {
            row.cells.forEach((cell, colIndex) => {
              const newFormula = formulas[rowIndex][colIndex];
              if (
                typeof newFormula === "string" &&
                cell.formula !== newFormula
              ) {
                void get().setCellFormula(
                  {
                    tableId: table.id,
                    columnId: table.columns[colIndex].id,
                    rowId: row.id,
                  },
                  newFormula,
                );
              }
            });
          });
        });
      };

      // Initialize a new action queue
      const { enqueueAction } = createProjectActionQueue(set, get);

      return {
        projectId,

        tables,

        modal: null,

        selectedTableId: first(tables)?.id,

        selectedFileId: undefined,

        selectedRowIds: [],

        isSyncing: false,

        syncError: undefined,

        isAdvancedMode: false,

        fileMap: {},

        metadatumMap: {},

        /**
         * Gets a file from the cache. If the file has not been previously
         * fetched, retrieve it from the server and notify the caller through a
         * callback.
         * @param options
         * @returns The cached file.
         */
        getFile: ({ fileId, onChange }) => {
          const file = get().fileMap[fileId];

          if (file === undefined) {
            void DjangoClient.getProjectFile({ fileId })
              .then((attrs) => {
                if (attrs === undefined) {
                  const error = new Error("File not found");
                  set((state) => {
                    state.fileMap[fileId] = error;
                  });
                  onChange(error);
                } else {
                  set((state) => {
                    state.fileMap[fileId] = attrs;
                  });
                  onChange(attrs);
                }
              })
              .catch((error) => {
                set((state) => {
                  state.fileMap[fileId] = error as Error;
                });
                onChange(error as Error);
              });
          }

          return file;
        },

        /**
         * Gets a file metadatum value from the cache. If the value has not
         * been previously fetched, retrieve it from the server and notify
         * the caller through a callback.
         * @param options
         * @returns The cached metadatum value.
         */
        getFileMetadatum: ({ fileId, metadatumKey, onChange }) => {
          const metadatumValue = get().metadatumMap[fileId]?.[metadatumKey];

          if (metadatumValue === undefined) {
            void DjangoClient.getFileMetadatumValue({ fileId, metadatumKey })
              .then((metadatumValue) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                set((state: any) => {
                  const metadatumMap =
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    state.metadatumMap as DataTableStoreState["metadatumMap"];
                  metadatumMap[fileId] = {
                    ...metadatumMap[fileId],
                    ...{ [metadatumKey]: metadatumValue },
                  };
                });
                onChange(metadatumValue);
              })
              .catch((error) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                set((state: any) => {
                  const metadatumMap =
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    state.metadatumMap as DataTableStoreState["metadatumMap"];
                  metadatumMap[fileId] = {
                    ...metadatumMap[fileId],
                    ...{ [metadatumKey]: error as Error },
                  };
                });
                onChange(error as Error);
              });
          }

          return metadatumValue;
        },

        /**
         * Sets the currently visible modal.
         * @param modal
         */
        setModal: (modal) => {
          set((state) => {
            state.modal = modal;
          });
        },

        /**
         * Sets the selected table.
         * @param tableId
         */
        setSelectedTableId: (tableId) => {
          set((state) => {
            state.selectedTableId = tableId;
            state.selectedRowIds = [];
          });
        },

        /**
         * Sets the selected file.
         * @param fileId
         */
        setSelectedFileId: (fileId) => {
          set((state) => {
            state.selectedFileId = fileId;
          });
        },

        setIsAdvancedMode: (isAdvancedMode) => {
          set((state) => {
            state.isAdvancedMode = isAdvancedMode;
          });
        },

        /**
         * Sets the selected rows.
         * @param rowIds
         */
        setSelectedRowIds: (rowIds) => {
          set((state) => {
            state.selectedRowIds = rowIds;
          });
        },

        /**
         * Sets the formula for a cell at a specified address.
         * @param address
         * @param newFormula
         * Creates a new data table.
         * @param options.name
         * @param options.key
         * @returns The table ID.
         */
        createDataTable: ({ name, key }) => {
          return enqueueAction({
            // We can't update state optimistically for this action
            onEnqueue: () => undefined,

            onDequeue: async () => {
              // Persist changes to the server
              const table = await DjangoClient.createDataTable({
                name,
                key,
                projectId,
              });

              const formattedTable = {
                id: table.id,
                kind: "data" as const,
                key,
                name,
                columnGroups: [],
                columns: table.columns,
                rows: table.rows.map((row) => ({
                  id: row.id,
                  index: row.index,
                  editable: row.editable,
                  task: null,
                  run: null,
                  cells: table.columns.map((column) => ({
                    value: null,
                    formula: column.default_formula,
                  })),
                })),
              };

              // Update store state
              set((state) => {
                state.tables.push(formattedTable);
                state.selectedTableId = table.id;
              });

              // Register new table, columns and rows with engine
              engine.addSheet(key);
              const sheetId = engine.getSheetId(key);
              assert(isDefined(sheetId));
              engine.addRows(sheetId, [0, table.rows.length]);
              engine.addColumns(sheetId, [0, table.columns.length]);
              engine.setSheetContent(
                sheetId,
                formattedTable.rows.map((row) =>
                  row.cells.map((cell) => cell.formula),
                ),
              );

              // Update cell values
              set((state) => {
                const table = state.tables.find(
                  ({ id }) => id === formattedTable.id,
                );
                assert(isDefined(table));
                table.rows.forEach((row, rowIndex) => {
                  row.cells.forEach((cell, colIndex) => {
                    cell.value = engine.getCellValue({
                      sheet: sheetId,
                      col: colIndex,
                      row: rowIndex,
                    });
                  });
                });
              });

              return table.id;
            },
          });
        },

        /**
         * Updates a data table.
         * @param options
         * @returns The table ID.
         */
        updateDataTable: ({ tableId, name, key, identifierColumnIds }) => {
          return enqueueAction({
            onEnqueue: () => {
              // Parse table ID
              const table = get().tables.find(({ id }) => id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));

              // Update store state
              set((state) => {
                const table = state.tables.find(({ id }) => id === tableId);
                assert(isDefined(table));

                if (isDefined(name)) {
                  table.name = name;
                }

                if (isDefined(key)) {
                  table.key = key;
                }

                if (isDefined(identifierColumnIds)) {
                  table.columns.forEach((column) => {
                    const identifierPosition = identifierColumnIds.findIndex(
                      (id) => id === column.id,
                    );
                    column.identifier_position =
                      identifierPosition !== -1 ? identifierPosition : null;
                  });
                }
              });

              // Register new table key with engine
              if (isDefined(key)) {
                engine.renameSheet(sheetId, key);
              }
            },

            onDequeue: async () => {
              // Persist changes to the server
              await DjangoClient.updateDataTable({
                tableId,
                name,
                key,
              });
              return tableId;
            },
          });
        },

        /**
         * Updates a analysis table.
         * @param options.tableId
         * @param options.name
         * @param options.key
         * @returns The table ID.
         */
        updateAnalysisTable: ({ tableId, name, key }) => {
          return enqueueAction({
            onEnqueue: () => {
              // Parse table ID
              const table = get().tables.find(({ id }) => id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));

              // Update store state
              set((state) => {
                const table = state.tables.find(({ id }) => id === tableId);
                assert(isDefined(table));

                if (isDefined(name)) {
                  table.name = name;
                }

                if (isDefined(key)) {
                  table.key = key;
                }
              });

              // Register new table key with engine
              if (isDefined(key)) {
                engine.renameSheet(sheetId, key);
              }
            },

            onDequeue: async () => {
              // Persist changes to the server
              await DjangoClient.updateAnalysisTable({
                tableId,
                name,
                key,
              });
              return tableId;
            },
          });
        },

        /**
         * Creates a new analysis table.
         * @param options.toolVersionId
         * @param options.name
         * @param options.key
         * @returns The table ID.
         */
        createAnalysisTable: ({ toolVersionId, name, key }) => {
          return enqueueAction({
            // We can't update state optimistically for this action
            onEnqueue: () => undefined,

            onDequeue: async () => {
              // Persist changes to the server
              const table = await DjangoClient.createAnalysisTable({
                toolVersionId,
                name,
                key,
                projectId,
              });

              const formattedTable = {
                id: table.id,
                kind: "analysis" as const,
                key,
                name,
                columnGroups: table.column_groups,
                columns: table.columns,
                rows: table.rows.map((row) => ({
                  id: row.id,
                  index: row.index,
                  editable: row.editable,
                  task: null,
                  run: null,
                  cells: table.columns.map((column) => ({
                    value: null,
                    formula: column.default_formula,
                  })),
                })),
              };

              // Update store state
              set((state) => {
                state.tables.push(formattedTable);
                state.selectedTableId = table.id;
              });

              // Register new table, columns and rows with engine
              engine.addSheet(key);
              const sheetId = engine.getSheetId(key);
              assert(isDefined(sheetId));
              engine.addRows(sheetId, [0, table.rows.length]);
              engine.addColumns(sheetId, [0, table.columns.length]);
              engine.setSheetContent(
                sheetId,
                formattedTable.rows.map((row) =>
                  row.cells.map((cell) => cell.formula),
                ),
              );

              // Update cell values
              set((state) => {
                const table = state.tables.find(
                  ({ id }) => id === formattedTable.id,
                );
                assert(isDefined(table));
                table.rows.forEach((row, rowIndex) => {
                  row.cells.forEach((cell, colIndex) => {
                    cell.value = engine.getCellValue({
                      sheet: sheetId,
                      col: colIndex,
                      row: rowIndex,
                    });
                  });
                });
              });

              return table.id;
            },
          });
        },

        /**
         * Creates a new workflow table.
         * @param options.workflowId
         * @param options.name
         * @param options.key
         * @returns The table ID.
         */
        createWorkflowTable: ({ workflowId, name, key }) => {
          return enqueueAction({
            // We can't update state optimistically for this action
            onEnqueue: () => undefined,

            onDequeue: async () => {
              // Persist changes to the server
              const table = await DjangoClient.createWorkflowTable({
                id: workflowId,
                name,
                key,
                project: projectId,
              });

              const formattedTable = {
                id: table.id,
                kind: "analysis" as const,
                key,
                name,
                columnGroups: table.column_groups,
                columns: table.columns,
                rows: table.rows.map((row) => ({
                  id: row.id,
                  index: row.index,
                  editable: row.editable,
                  task: null,
                  run: null,
                  cells: table.columns.map((column) => ({
                    value: null,
                    formula: column.default_formula,
                  })),
                })),
              };

              // Update store state
              set((state) => {
                state.tables.push(formattedTable);
                state.selectedTableId = table.id;
              });

              // Register new table, columns and rows with engine
              engine.addSheet(key);
              const sheetId = engine.getSheetId(key);
              assert(isDefined(sheetId));
              engine.addRows(sheetId, [0, table.rows.length]);
              engine.addColumns(sheetId, [0, table.columns.length]);
              engine.setSheetContent(
                sheetId,
                formattedTable.rows.map((row) =>
                  row.cells.map((cell) => cell.formula),
                ),
              );

              // Update cell values
              set((state) => {
                const table = state.tables.find(
                  ({ id }) => id === formattedTable.id,
                );
                assert(isDefined(table));
                table.rows.forEach((row, rowIndex) => {
                  row.cells.forEach((cell, colIndex) => {
                    cell.value = engine.getCellValue({
                      sheet: sheetId,
                      col: colIndex,
                      row: rowIndex,
                    });
                  });
                });
              });

              return table.id;
            },
          });
        },

        /**
         * Creates a new data table column.
         * @param options
         * @returns The column ID.
         */
        createColumn: ({
          tableId,
          name,
          editable,
          defaultFormula,
          definition,
        }) => {
          return enqueueAction({
            // We can't update state optimistically for this action
            onEnqueue: () => undefined,

            onDequeue: async () => {
              // Parse table ID
              const table = get().tables.find(({ id }) => id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));

              // Persist changes to the server
              const column = await DjangoClient.createDataTableColumn({
                tableId,
                name,
                editable,
                defaultFormula,
                definition,
              });

              // Update store state
              set((state) => {
                const table = state.tables.find(({ id }) => id === tableId);
                assert(isDefined(table));
                table.columns.push(column);
                table.rows.forEach((row) => {
                  row.cells.push({
                    formula: defaultFormula ?? null,
                    value: null,
                  });
                });
              });

              // Register new column with engine
              const colIndex = table.columns.length;
              engine.addColumns(sheetId, [colIndex, 1]);
              engine.setCellContents(
                { sheet: sheetId, col: colIndex, row: 0 },
                new Array(table.rows.length).fill([defaultFormula]),
              );

              return column.id;
            },
          });
        },

        /**
         * Adds a specified number of rows to a table.
         * @param options.tableId
         * @param options.numRows
         * @returns An array of row IDs.
         */
        createRows: ({ tableId, numRows }) => {
          return enqueueAction({
            // We can't update state optimistically for this action
            onEnqueue: () => undefined,

            onDequeue: async () => {
              // Parse table ID
              const table = get().tables.find(({ id }) => id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));
              const defaultFormulas = table.columns.map(
                (column) => column.default_formula,
              );

              // Persist changes to the server
              const rows = await DjangoClient.createRowsBulk({
                tableId,
                numRows,
                tableKind: table.kind,
              });

              // Update store state
              set((state) => {
                const table = state.tables.find(({ id }) => id === tableId);
                assert(isDefined(table));
                rows.forEach((row) => {
                  table.rows.push({
                    ...row,
                    task: null,
                    run: null,
                    cells: defaultFormulas.map((formula) => ({
                      formula,
                      value: null,
                    })),
                  });
                });
              });

              // Register new rows with engine
              const rowIndex = table.rows.length;
              engine.addRows(sheetId, [rowIndex, numRows]);
              engine.setCellContents(
                { sheet: sheetId, col: 0, row: rowIndex },
                new Array(numRows).fill(defaultFormulas),
              );

              return rows.map((row) => row.id);
            },
          });
        },

        uploadFile: ({ cellAddress, file }) => {
          return enqueueAction({
            // We can't update state optimistically for this action
            onEnqueue: () => undefined,

            onDequeue: async () => {
              // Parse cell address
              const { tableId } = cellAddress;
              const table = get().tables.find(({ id }) => id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));

              // Persist changes to the server
              const newFile = await DjangoClient.createFile({
                name: file.name,
                projectId,
                size: file.size,
                tenantId: 1,
              });

              // Update store state
              set((state) => {
                state.fileMap[newFile.id] = newFile;
              });
              void get().setCellFormula(cellAddress, `=FILE("${newFile.id}")`);

              // Queue upload
              void enqueueFile({
                drsFile: {
                  id: newFile.id,
                  partSize: newFile.part_size,
                },
                project: {
                  id: projectId,
                  name: "",
                  key: projectKey,
                  tenantId: 1,
                },
                blob: file,
                tenantId: 1,
              });

              return newFile.id;
            },
          });
        },

        /**
         * Sets the formula for a cell at a specified address.
         * @param address
         * @param newFormula
         */
        setCellFormula: async (address, newFormula) => {
          await enqueueAction({
            onEnqueue: () => {
              // Parse cell address
              const { tableId, columnId, rowId } = address;
              const table = get().tables.find(({ id }) => id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));
              const colIndex = table.columns.findIndex(
                ({ id }) => id === columnId,
              );
              const rowIndex = table.rows.findIndex(({ id }) => id === rowId);

              // Update store state
              set((state) => {
                const table = state.tables.find(({ id }) => id === tableId);
                assert(isDefined(table));
                const cell = table.rows[rowIndex].cells[colIndex];
                cell.formula = newFormula;
              });

              // Trigger engine to recalculate cell values
              engine.setCellContents(
                { sheet: sheetId, col: colIndex, row: rowIndex },
                newFormula,
              );
            },

            onDequeue: () => {
              const { tableId } = address;
              const table = get().tables.find(({ id }) => id === tableId);
              assert(isDefined(table));

              return DjangoClient.updateCellFormula({
                address,
                formula: newFormula,
                tableKind: table.kind,
              });
            },
          });
        },

        deleteTable: ({ tableId }) => {
          const table = get().tables.find((table) => table.id === tableId);
          return enqueueAction({
            onEnqueue: () => {
              // Parse table ID
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));

              // Update store state
              set((state) => {
                state.tables = state.tables.filter(
                  (table) => table.id !== tableId,
                );
                state.selectedTableId = first(state.tables)?.id;
                state.selectedRowIds = [];
              });

              // Notify engine of the removed table
              engine.removeSheet(sheetId);
            },

            onDequeue: () => {
              // Persist changes to server
              assert(isDefined(table));
              return DjangoClient.deleteTable({
                tableKind: table.kind,
                tableId: table.id,
              });
            },
          });
        },

        deleteDataTableColumn: ({ tableId, columnId }) => {
          return enqueueAction({
            onEnqueue: () => {
              // Parse table ID
              const table = get().tables.find((table) => table.id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));
              const columnIndex = table.columns.findIndex(
                (column) => column.id === columnId,
              );

              // Update store state
              set((state) => {
                const table = state.tables.find(({ id }) => id === tableId);
                assert(isDefined(table));
                table.columns = table.columns.filter(
                  (column) => column.id !== columnId,
                );
                table.rows.forEach((row) => {
                  row.cells.splice(columnIndex, 1);
                });
              });

              // Notify engine of the removed column
              engine.removeColumns(sheetId, [columnIndex, 1]);
              syncFormulaChanges();
            },

            onDequeue: () => {
              // Persist changes to server
              return DjangoClient.deleteDataTableColumn({ columnId });
            },
          });
        },

        deleteRows: ({ tableId, rowIds }) => {
          return enqueueAction({
            onEnqueue: () => {
              // Parse table ID
              const table = get().tables.find((table) => table.id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));
              const rowIndices = rowIds.map((rowId) => {
                return table.rows.findIndex((row) => row.id === rowId);
              });

              // Update store state
              set((state) => {
                const table = state.tables.find(({ id }) => id === tableId);
                assert(isDefined(table));
                table.rows = table.rows.filter(
                  (row) => !rowIds.includes(row.id),
                );
              });

              // Notify engine of removed rows
              engine.removeRows(
                sheetId,
                ...rowIndices.map(
                  (rowIndex) => [rowIndex, 1] satisfies [number, number],
                ),
              );
            },

            onDequeue: () => {
              // Persist changes to server
              const table = get().tables.find((table) => table.id === tableId);
              assert(isDefined(table));
              return DjangoClient.deleteRows({
                tableKind: table.kind,
                rowIds,
              });
            },
          });
        },

        renameColumn: ({ tableId, columnId, newName }) => {
          return enqueueAction({
            onEnqueue: () => {
              // Update store state
              set((state) => {
                const table = state.tables.find(
                  (table) => table.id === tableId,
                );
                const column = table?.columns.find(
                  (column) => column.id === columnId,
                );

                if (isDefined(column)) {
                  column.name = newName;
                }
              });
            },

            onDequeue: async () => {
              // Persist changes to server
              const table = get().tables.find((table) => table.id === tableId);
              assert(isDefined(table));
              await DjangoClient.updateColumn({
                tableKind: table.kind,
                columnId,
                name: newName,
              });
              return newName;
            },
          });
        },

        resizeColumn: ({ tableId, columnId, newWidth }) => {
          return enqueueAction({
            onEnqueue: () => {
              // Update store state
              set((state) => {
                const table = state.tables.find(
                  (table) => table.id === tableId,
                );
                const column = table?.columns.find(
                  (column) => column.id === columnId,
                );

                if (isDefined(column)) {
                  column.width = newWidth;
                }
              });
            },

            onDequeue: async () => {
              // Persist changes to server
              const table = get().tables.find((table) => table.id === tableId);
              assert(isDefined(table));
              await DjangoClient.updateColumn({
                tableKind: table.kind,
                columnId,
                width: newWidth,
              });
              return newWidth;
            },
          });
        },

        refetchFile: ({ fileId }) => {
          return enqueueAction({
            // We can't update state optimistically for this action
            onEnqueue: () => undefined,

            onDequeue: async () => {
              // Get file from server
              const file = await DjangoClient.getProjectFile({ fileId });

              // Update store state
              if (isDefined(file)) {
                set((state) => {
                  // Update file map
                  state.fileMap[file.id] = file;

                  // Update cell values
                  state.tables.forEach((table) => {
                    table.rows.forEach((row) => {
                      row.cells.forEach((cell) => {
                        if (
                          cell.value instanceof IdeasFile &&
                          cell.value.attrs.id === fileId
                        ) {
                          cell.value = new IdeasFile({
                            id: file.id,
                            name: file.name,
                            status: file.status,
                            fileType: file.file_type,
                            isSeries: file.is_series,
                            source: "uploaded",
                            processingStatus: file.processing_status,
                            seriesParentId: file.series_parent_id,
                          });
                        }
                      });
                    });
                  });
                });
              }
            },
          });
        },

        executeAnalysisTableRow: ({ tableId, rowId }) => {
          return enqueueAction({
            // We can't update state optimistically for this action
            onEnqueue: () => undefined,

            onDequeue: async () => {
              // Format cell values
              const table = get().tables.find((table) => table.id === tableId);
              assert(isDefined(table));
              const row = table.rows.find((row) => row.id === rowId);
              assert(isDefined(row));
              const cellValues = row.cells.map((cell, colIndex) => ({
                columnId: table.columns[colIndex].id,
                staticFormula: convertCellValueToStaticFormula(cell.value),
                value: convertCellValueToToolInput(cell.value),
              }));

              // Execute analysis on the server
              const { task: taskId, run: runId } =
                await DjangoClient.executeAnalysisTableRow({
                  rowId,
                  cellValues,
                });

              if (taskId !== null) {
                const task = await DjangoClient.getTask({ taskId });

                // Update store state
                set((state) => {
                  const table = state.tables.find(({ id }) => id === tableId);
                  assert(isDefined(table));
                  const row = table.rows.find((row) => row.id === rowId);
                  assert(isDefined(row));
                  row.task = task;
                  row.editable = false;
                });
              }

              if (runId !== null) {
                const run = await DjangoClient.getRun({ runId });

                // Update store state
                set((state) => {
                  const table = state.tables.find(({ id }) => id === tableId);
                  assert(isDefined(table));
                  const row = table.rows.find((row) => row.id === rowId);
                  assert(isDefined(row));
                  row.run = run;
                  row.editable = false;
                });
              }

              return taskId ?? runId ?? "";
            },
          });
        },

        cancelWorkflowRun: ({ tableId, rowId }) => {
          return enqueueAction({
            // We can't update state optimistically for this action
            onEnqueue: () => undefined,

            onDequeue: async () => {
              // Parse workflow run
              const table = get().tables.find((table) => table.id === tableId);
              assert(isDefined(table));
              const row = table.rows.find((row) => row.id === rowId);
              assert(isDefined(row));
              assert(row.run !== null);

              // Cancel workflow run on the server
              await DjangoClient.cancelWorkflowRun({
                id: row.run.id,
              });

              // Update store state
              set((state) => {
                const table = state.tables.find(({ id }) => id === tableId);
                assert(isDefined(table));
                const row = table.rows.find((row) => row.id === rowId);
                assert(isDefined(row));
                assert(row.run !== null);
                row.run.status = RunStatus.CANCELED;
              });

              return row.run.id;
            },
          });
        },

        refetchPendingAnalysisTableRows: async () => {
          const pendingRows = get().tables.flatMap((table) => {
            const sheetId = engine.getSheetId(table.key);
            assert(isDefined(sheetId));
            return table.rows
              .filter((row) => {
                return (
                  (row.task !== null &&
                    ![
                      TaskStatus.CANCELED,
                      TaskStatus.COMPLETE,
                      TaskStatus.ERROR,
                      TaskStatus.FAILED,
                    ].includes(row.task.status)) ||
                  (row.run !== null &&
                    ![RunStatus.COMPLETE, RunStatus.FAILED].includes(
                      row.run.status,
                    ))
                );
              })
              .map((row) => ({ tableId: table.id, sheetId, rowId: row.id }));
          });

          // Fetch row from server
          const updatedRows = await DjangoClient.getAnalysisTableRows({
            rowIds: pendingRows.map((row) => row.rowId),
          });

          const engineUpdates: [SimpleCellAddress, RawCellContent[][]][] = [];

          // Update store state
          set((state) => {
            pendingRows.forEach(({ tableId, rowId }) => {
              const table = state.tables.find(({ id }) => id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));
              const rowIndex = table.rows.findIndex((row) => row.id === rowId);
              const row = table.rows[rowIndex];
              assert(isDefined(row));

              const updatedRow = updatedRows.find((row) => row.id === rowId);
              if (isDefined(updatedRow)) {
                row.task = updatedRow.task ?? null;
                row.run = updatedRow.run ?? null;
                row.cells.forEach((cell, cellIdx) => {
                  if (cell.formula !== updatedRow.formulas[cellIdx]) {
                    cell.formula = updatedRow.formulas[cellIdx];
                    engineUpdates.push([
                      { sheet: sheetId, col: cellIdx, row: rowIndex },
                      [[updatedRow.formulas[cellIdx]]],
                    ]);
                  }
                });
              }
            });
          });

          // Notify engine
          engine.batch(() => {
            engineUpdates.forEach(([address, contents]) => {
              engine.setCellContents(address, contents);
            });
          });
        },

        deleteFile: ({ tableId, columnId, rowId, fileId }) => {
          void get().setCellFormula({ tableId, columnId, rowId }, "");

          return enqueueAction({
            onEnqueue: () => {
              // Update store state
              set((state) => {
                delete state.fileMap[fileId];
              });
            },

            onDequeue: async () => {
              await DjangoClient.deleteFile({
                id: fileId,
                date_data_deleted: new Date().toISOString(),
              });
            },
          });
        },
      } satisfies DataTableStoreState;
    }),
  );
};
