import { useEffect, useMemo, useState } from "react";

interface usePaginationParams<T> {
  allItems: T[];
  itemsPerPage: number;
}

/**
 * Hook to manage pagination state
 * @param options { allItems: array, ItemsPerPage: number } array of items you want to paginate, and item count per page
 * @returns { activePage, setActivePage, activePageItems, pageCount }
 */

function usePagination<T>({ allItems, itemsPerPage }: usePaginationParams<T>) {
  const [activePage, setActivePage] = useState(0);

  const onPageChange = (page: number) => {
    setActivePage(page);
  };

  const activePageItems = useMemo(() => {
    return allItems.slice(
      activePage * itemsPerPage,
      activePage * itemsPerPage + itemsPerPage,
    );
  }, [allItems, itemsPerPage, activePage]);

  const pageCount = useMemo(
    () => Math.ceil(allItems.length / itemsPerPage),
    [allItems.length, itemsPerPage],
  );

  // clamp active page in bounds of page range
  useEffect(() => {
    if (activePage !== 0 && activePage >= pageCount) {
      setActivePage(pageCount - 1);
    } else if (activePage < 0) {
      setActivePage(0);
    }
  }, [activePage, pageCount]);

  return { activePage, onPageChange, activePageItems, pageCount };
}

export default usePagination;
