import {
  EuiAccordion,
  EuiCallOut,
  EuiCard,
  EuiLoadingChart,
  EuiTitle,
} from "@inscopix/ideas-eui";
import { useGetDrsFileProcessingStatus } from "hooks/useGetDrsFileProcessingStatus";
import { File as DrsFile } from "graphql/_Types";
import { isDefined } from "utils/isDefined";
import { isNonNullish } from "utils/isNonNullish";
import { FILE_TYPES_BY_ID } from "types/FileTypes";
import { metadataMap } from "types/MetadataMap";
import { FlyoutFileInfoBodyMetadataTree } from "./FlyoutFileInfoBodyMetadataTree";
import { FlyoutFileInfoBodyMetadataTable } from "./FlyoutFileInfoBodyMetadataTable";

type FlyoutFileInfoBodyMetadataProps = {
  activeFile: Pick<DrsFile, "id" | "status" | "fileType">;
};

export const FlyoutFileInfoBodyMetadata = ({
  activeFile,
}: FlyoutFileInfoBodyMetadataProps) => {
  const { processingStatus } = useGetDrsFileProcessingStatus(activeFile);

  const fileTypeKey = isNonNullish(activeFile.fileType)
    ? FILE_TYPES_BY_ID[activeFile.fileType]?.key
    : undefined;

  // show metadata tree if the file type does not have a defined metadata map value
  const showMetadataTree =
    isDefined(fileTypeKey) && !isDefined(metadataMap[fileTypeKey]);

  return (
    <EuiAccordion
      id={"drsFlyoutMetadata"}
      buttonContent={
        <div>
          <EuiTitle size="xs">
            <h3>Metadata</h3>
          </EuiTitle>
        </div>
      }
      paddingSize="l"
      initialIsOpen={true}
    >
      <div style={{ marginTop: "-16px" }}>
        {(() => {
          switch (processingStatus) {
            case "complete":
              if (showMetadataTree) {
                return <FlyoutFileInfoBodyMetadataTree drsFile={activeFile} />;
              }
              return <FlyoutFileInfoBodyMetadataTable drsFile={activeFile} />;
            case "pending":
              return (
                <EuiCard
                  title={
                    <>
                      <EuiLoadingChart size="l" mono />
                      &nbsp; Generating Metadata...
                    </>
                  }
                  description="Metadata is being generated. Please wait."
                  display="plain"
                  hasBorder
                />
              );
            case "error":
              return (
                <EuiCallOut title="Metadata not available" color="warning">
                  <p>There was a problem reading the file.</p>
                </EuiCallOut>
              );
          }
        })()}
      </div>
    </EuiAccordion>
  );
};
