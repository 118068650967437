import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiSuperSelect,
  EuiSuperSelectOption,
} from "@inscopix/ideas-eui";
import { FILE_TYPES_BY_ID, FILE_TYPES_BY_KEY, FileType } from "types/FileTypes";

const options: EuiSuperSelectOption<FileType["key"]>[] = [
  // TODO FILE TYPE MIGRATION
  { ...FILE_TYPES_BY_KEY["unknown"], name: "Any" },
  // common types ordered by most expected to be used
  FILE_TYPES_BY_KEY["miniscope_movie"],
  FILE_TYPES_BY_KEY["nvision_movie"],
  FILE_TYPES_BY_KEY["movie"],
  FILE_TYPES_BY_KEY["cell_set"],
  FILE_TYPES_BY_KEY["vessel_set"],
  FILE_TYPES_BY_KEY["neural_events"],
  FILE_TYPES_BY_KEY["timestamp_events"],
  FILE_TYPES_BY_KEY["gpio_data"],
  FILE_TYPES_BY_KEY["imu_data"],
  FILE_TYPES_BY_KEY["miniscope_image"],
  FILE_TYPES_BY_KEY["image"],
  FILE_TYPES_BY_KEY["event_aligned_neural_data"],
  FILE_TYPES_BY_KEY["experiment_annotations"],
  FILE_TYPES_BY_KEY["nwb_data"],
  // new file types added for manual identification - less expected to be used
  // ordered alphabetically
  FILE_TYPES_BY_KEY["behavior_annotations"],
  FILE_TYPES_BY_KEY["bruker2p_movie"],
  FILE_TYPES_BY_KEY["bundle"],
  FILE_TYPES_BY_KEY["calcium_image_qc_data"],
  FILE_TYPES_BY_KEY["config"],
  FILE_TYPES_BY_KEY["dlc_model"],
  FILE_TYPES_BY_KEY["dlc_model_predictions_data"],
  FILE_TYPES_BY_KEY["combined_correlation_data"],
  FILE_TYPES_BY_KEY["combined_peri_event_statistics"],
  FILE_TYPES_BY_KEY["combined_peri_event_traces"],
  FILE_TYPES_BY_KEY["combined_population_activity_data"],
  FILE_TYPES_BY_KEY["correlation_data"],
  FILE_TYPES_BY_KEY["correlation_comparison_data"],
  FILE_TYPES_BY_KEY["locomotion_comparison_data"],
  FILE_TYPES_BY_KEY["movie_zones"],
  FILE_TYPES_BY_KEY["modulation_data"],
  FILE_TYPES_BY_KEY["peri_event_comparison_data"],
  FILE_TYPES_BY_KEY["population_activity_comparison_data"],
  FILE_TYPES_BY_KEY["statistics"],
  FILE_TYPES_BY_KEY["suite2p_data"],
  FILE_TYPES_BY_KEY["zone_occupancy_analysis"],
].map((fileType) => ({
  value: fileType.key,
  inputDisplay: (
    <EuiFlexGroup alignItems="center" gutterSize="s">
      <EuiFlexItem grow={false}>
        <EuiIcon type={fileType.icon} />
      </EuiFlexItem>
      <EuiFlexItem>
        <span>{fileType.name}</span>
      </EuiFlexItem>
    </EuiFlexGroup>
  ),
}));

interface FieldFileTypeProps {
  autoFocus?: boolean;
  value: FileType["id"] | undefined;
  onChange: (field: {
    value: FileType["id"] | undefined;
    isValid: boolean;
  }) => void;
}

export const FieldFileType = ({
  autoFocus,
  value,
  onChange,
}: FieldFileTypeProps) => {
  const handleChange = (newValue: FileType["key"]) => {
    const fileType = FILE_TYPES_BY_KEY[newValue].id;
    if (value === fileType) {
      onChange({
        value: undefined,
        isValid: false,
      });
    } else {
      onChange({
        value: fileType,
        isValid: true,
      });
    }
  };

  return (
    <EuiFormRow
      label="Data type"
      helpText="Choosing a data type restricts the column to storing a specific kind of data. This keeps your table organized and allows data to be used in analyses."
    >
      <EuiSuperSelect
        options={options}
        valueOfSelected={
          value !== undefined ? FILE_TYPES_BY_ID[value].key : undefined
        }
        onChange={handleChange}
        autoFocus={autoFocus}
        placeholder="Select a file type..."
      />
    </EuiFormRow>
  );
};
