import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiSpacer,
} from "@inscopix/ideas-eui";
import { useState } from "react";
import { FieldColumnName } from "../../fields/FieldColumnName";
import { FileType } from "types/FileTypes";
import { FieldFileType } from "../../fields/FieldFileType";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import assert from "assert";
import { isDefined } from "utils/isDefined";

type Fields = {
  name: {
    value: string;
    isValid: boolean;
  };
  file_type: {
    value: FileType["id"] | undefined;
    isValid: boolean;
  };
};

interface PanelCreateColumnFileProps {
  onClose: () => void;
}

export const PanelCreateColumnFile = ({
  onClose,
}: PanelCreateColumnFileProps) => {
  const createColumn = useDataTableContext((s) => s.createColumn);
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState<Fields>({
    name: {
      value: "",
      isValid: false,
    },
    file_type: {
      value: undefined,
      isValid: false,
    },
  });

  const handleFieldChange = <T extends keyof Fields>(
    fieldName: T,
    field: Fields[T],
  ) => {
    setFields((prevFields) => ({
      ...prevFields,
      [fieldName]: field,
    }));
  };

  const handleSubmit = async () => {
    const fileType = fields.file_type.value;
    assert(isDefined(fileType));

    setIsLoading(true);
    const { error } = await createColumn({
      tableId: selectedTableId ?? "",
      name: fields.name.value,
      defaultFormula: undefined,
      definition: {
        kind: "file",
        file_type: fileType,
      },
      editable: true,
    });
    setIsLoading(false);

    if (error === undefined) {
      addUtilityToastSuccess("Column created");
    } else {
      addUtilityToastFailure("Failed to create column");
    }

    onClose();
  };

  return (
    <EuiForm style={{ padding: 16 }}>
      <FieldColumnName
        autoFocus
        value={fields.name.value}
        onChange={(field) => handleFieldChange("name", field)}
      />

      <FieldFileType
        autoFocus
        value={fields.file_type.value}
        onChange={(field) => handleFieldChange("file_type", field)}
      />

      <EuiSpacer />

      <EuiFlexGroup justifyContent="flexEnd">
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiButton
            isLoading={isLoading}
            fill
            onClick={() => void handleSubmit()}
            disabled={Object.values(fields).some((field) => !field.isValid)}
          >
            Insert
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiForm>
  );
};
