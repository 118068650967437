import {
  EuiCard,
  EuiFlexGroup,
  EuiSpacer,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { NodeProps } from "@xyflow/react";

interface StepNodeProps extends NodeProps {
  data: {
    title: string;
    toolVersion: string;
    description: string;
    numChildren: number;
  };
}

export const StepNode = ({ data }: StepNodeProps) => {
  return (
    <EuiCard
      layout="horizontal"
      titleSize="xs"
      title={
        <span>
          {data["title"]}{" "}
          <span style={{ color: "grey" }}>({data["toolVersion"]})</span>
        </span>
      }
      description={data["description"]}
      style={{
        border: "2px solid #bbb",
      }}
    >
      {data["numChildren"] > 0 && (
        <>
          <EuiSpacer size="s" />
          <EuiFlexGroup gutterSize="m">
            {Array.from({ length: data["numChildren"] }).map(() => (
              <div
                key={htmlIdGenerator()()}
                style={{
                  height: 80,
                  width: 400,
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  borderRadius: 4,
                }}
              />
            ))}
          </EuiFlexGroup>
        </>
      )}
    </EuiCard>
  );
};
